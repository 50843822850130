(() => {
  angular.module('app').component('commonLayoutFooter', {
    template: require('./footer.eager.html').default,
    controller: FooterController,
    controllerAs: 'vm',
    bindings: {},
  });

  FooterController.$inject = ['appSession', 'moment'];

  function FooterController(appSession, moment) {
    const vm = this;

    vm.application = appSession.application;

    vm.getAppVersion = getAppVersion;
    vm.getAppReleaseDate = getAppReleaseDate;
    vm.getServerTime = getServerTime;

    init();

    function init() {
      vm.fullYear = new Date().getFullYear();
    }

    function getAppVersion() {
      return appSession.application ? appSession.application.version : '';
    }

    function getAppReleaseDate() {
      return appSession.application ? appSession.application.releaseDate : moment();
    }

    function getServerTime() {
      return App.serverTime ? App.serverTime.format('L LTS Z') : `${App.localize('Syncing')  }...`;
    }
  }
})();
