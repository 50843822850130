(function () {
    'use strict'

    angular.module('app')
        .controller('host.views.inpatientGuaranteeLetters.details.query.viewQuery', ['$scope', '$stateParams',
            'abp.services.app.serviceRequestQuery', controller]);

    function controller($scope, $stateParams, serviceRequestQuerySvc) {
        var vm = this;

        vm.loading = 0;
        vm.saving = 0;
        vm.guaranteeLetterNumber = $stateParams.letterNumber;
        vm.queryNumber = $stateParams.queryNumber;
        vm.claimNumber = $stateParams.claimNumber;
        vm.timeZoneId = $stateParams.timeZoneId;

        vm.resolve = resolve;
        vm.getDateTimeString = getDateTimeString;

        init();

        /* Functions */

        function getQuery() {
            vm.loading++;
            serviceRequestQuerySvc.getQuery({ id: vm.queryNumber })
                .success(function (data) {
                    vm.query = data;
                }).finally(function () {
                    vm.loading--;
                });
        }

        function resolve() {
            vm.loading++;

            serviceRequestQuerySvc.resolveQuery({ id: vm.query.id, attachments: vm.query.repliedAttachments })
                .success(function () {
                    abp.notify.success(App.localize('SuccessfullySaved'));
                    getQuery();
                }).finally(function () {
                    vm.loading--;
                });
        }

        function getDateTimeString(date, timeZoneId) {
            return App.getDateTimeString(date, timeZoneId);
        }

        function init() {
            getQuery();
        }
    }
})();
