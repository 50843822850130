(() => {
  angular
    .module('app')
    .filter('paymentMode', paymentMode)
    // For backward compatibility only. Filter name don't need "Filter" suffix.
    // AngularJS automatically appends "Filter" to name for DI.
    .filter('paymentModeFilter', paymentMode);

  paymentMode.$inject = [];

  function paymentMode() {
    return paymentModeFilter;

    function paymentModeFilter(value) {
      switch (value) {
        case 0:
          return App.localize('PaymentMode_Lip');
        case 1:
          return App.localize('PaymentMode_Lgp');
        case 2:
          return App.localize('PaymentMode_Lsp');
        case 3:
          return App.localize('PaymentMode_Lbc');
        case 4:
          return App.localize('PaymentMode_Merchantrade');
        case 5:
          return App.localize('PaymentMode_Oct');
        case 6:
          return App.localize('PaymentMode_Plg');
        case 7:
          return App.localize('PaymentMode_InterbankTransfer');
        default:
          return value;
      }
    }
  }
})();
