(() => {
  angular.module('app').component('hostOperationsDashboardInsuranceClaimsListingComponent', {
    templateUrl: require('./insuranceClaimsListing.component.html'),
    controller: ControllerController,
    controllerAs: 'vm',
    bindings: {
      insuranceClaimsRequestParams: '<',
    },
  });

  ControllerController.$inject = [
    '$scope',
    '$state',
    '$uibModal',
    'abp.services.app.insurerClaim',
    'abp.services.app.memberUtilization',
    'Hms.InsurerClaims.InsurerClaimEnums.InsurerClaimStatus',
    'Hms.Services.ServiceType',
    'Hms.BillingStatus',
    'Hms.InsurerClaims.InsurerClaimEnums.InsurerClaimSubCategory',
    'Hms.Employees.EmployeeState',
    'Hms.PatientTicketBillings.BillStatus',
    'appConstants',
    'moment'
  ];

  function ControllerController(
    $scope,
    $state,
    $modal,
    insurerClaimSvc,
    memberUtilizationSvc,
    enumInsurerClaimStatus,
    enumServiceType,
    enumBillingStatus,
    enumInsurerClaimSubCategory,
    enumEmployeeState,
    enumFinanceBillStatus,
    appConstants,
    moment
  ) {
    const vm = this;

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.hasAccessFinanceTransactionData = abp.setting.getBoolean(
      'Hms.Feature.AccessFinanceTransactionData'
    );
    vm.insuranceClaimsRequestParams = this.insuranceClaimsRequestParams;
    vm.loading = 0;
    vm.enums = {};
    vm.enums.insurerClaimStatus = enumInsurerClaimStatus;
    vm.enums.serviceType = enumServiceType;
    vm.enums.billingStatus = enumBillingStatus;
    vm.enums.insurerClaimSubCategory = enumInsurerClaimSubCategory;
    vm.enums.employeeState = enumEmployeeState;
    vm.enums.financeBillStatus = enumFinanceBillStatus;
    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSizes,
      paginationPageSize: 10,
      useExternalPagination: true,
      useExternalSorting: true,
      columnDefs: [
        {
          name: 'Actions',
          displayName: 'Actions',
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'insurerClaimActionTemplate',
        },
        {
          displayName: App.localize('BatchNumber'),
          field: 'batchNumber',
          enableSorting: false,
          width: 100,
        },
        {
          displayName: App.localize('ClaimStatus'),
          field: 'insurerClaimStatus',
          enableSorting: false,
          cellTemplate: 'insurerClaimStatusTemplate',
          width: 100,
        },
        {
          displayName: App.localize('TicketNumber'),
          field: 'ticketNumber',
          enableSorting: false,
          width: 100,
        },
        {
          displayName: App.localize('TicketDate'),
          field: 'ticketCreationDate',
          enableSorting: false,
          cellTemplate: 'ticketCreationDateTemplate',
          width: 100,
        },
        {
          displayName: App.localize('GuaranteeLetterNumber'),
          field: 'guaranteeLetterNumber',
          enableSorting: false,
          width: 100,
        },
        {
          displayName: App.localize('Patient'),
          field: 'patient',
          cellTemplate: 'patientTemplate',
          enableSorting: false,
          width: 100,
        },
        {
          displayName: App.localize('PanelLocation'),
          field: 'panelLocationName',
          enableSorting: false,
          width: 100,
        },
        {
          displayName: App.localize('ServiceType'),
          field: 'serviceType',
          cellTemplate: 'serviceTypeTemplate',
          enableSorting: false,
          width: 100,
        },
        {
          displayName: App.localize('InsurerClaimSubCategory'),
          field: 'insurerClaimSubCategory',
          cellTemplate: 'insurerClaimSubCategoryTemplate',
          enableSorting: false,
          width: 100,
        },
        {
          displayName: App.localize('Insurer'),
          field: 'insurerName',
          enableSorting: false,
          width: 100,
        },
        {
          displayName: App.localize('AmountToInvoice'),
          field: 'amountToInvoice',
          cellFilter: 'currencyFormat: grid.appScope.currencyCode',
          enableSorting: false,
          width: 100,
        },
        {
          displayName: App.localize('InsurerInvoiceNumber'),
          field: 'insurerInvoiceNumber',
          cellTemplate: 'financeInsurerInvoiceNumberTemplate',
          enableSorting: false,
          width: 200,
          visible: vm.hasAccessFinanceTransactionData,
        },
        {
          displayName: App.localize('InsurerInvoiceNumber'),
          field: 'insurerInvoiceNumber',
          cellTemplate: 'insurerInvoiceNumberTemplate',
          enableSorting: false,
          width: 200,
          visible: !vm.hasAccessFinanceTransactionData,
        },
        {
          displayName: App.localize('CorporateCreditNoteNumber'),
          field: 'corporateCreditNoteNumber',
          cellTemplate: 'financeCorporateCreditNoteNumberTemplate',
          enableSorting: false,
          width: 200,
          visible: vm.hasAccessFinanceTransactionData,
        },
        {
          displayName: App.localize('CorporateCreditNoteNumber'),
          field: 'corporateCreditNoteNumber',
          cellTemplate: 'corporateCreditNoteNumberTemplate',
          enableSorting: false,
          width: 200,
          visible: !vm.hasAccessFinanceTransactionData,
        },
        {
          displayName: App.localize('MemberInvoiceNumber'),
          field: 'memberInvoiceNumber',
          cellTemplate: 'financeMemberInvoiceNumberTemplate',
          enableSorting: false,
          width: 200,
          visible: vm.hasAccessFinanceTransactionData,
        },
        {
          displayName: App.localize('MemberInvoiceNumber'),
          field: 'memberInvoiceNumber',
          cellTemplate: 'memberInvoiceNumberTemplate',
          enableSorting: false,
          width: 200,
          visible: !vm.hasAccessFinanceTransactionData,
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (_scope, callback) => {
          vm.insuranceClaimsRequestParams.sorting =
            callback.length && callback[0].field
              ? `${callback[0].field} ${callback[0].sort.direction}`
              : null;
          vm.getInsuranceClaims();
        });
        gridApi.pagination.on.paginationChanged($scope, (pageNumber, pageSize) => {
          vm.insuranceClaimsRequestParams.skipCount = (pageNumber - 1) * pageSize;
          vm.insuranceClaimsRequestParams.maxResultCount = pageSize;
          vm.getInsuranceClaims();
        });
      },
      data: [],
    };
    vm.viewInsuranceClaim = {
      // TODO: Implement view insurance claim redirection.
    };

    vm.$onInit = init;
    vm.$onChanges = $onChanges;
    vm.getInsuranceClaims = getInsuranceClaims;
    vm.viewPatientTicket = viewPatientTicket;
    vm.dismissInsuranceClaim = dismissInsuranceClaim;
    vm.confirmInsuranceUtilization = confirmInsuranceUtilization;
    vm.isWithinGracePeriod = isWithinGracePeriod;
    vm.getDateString = getDateString;

    init();
    $scope.$on('search', () => {
      $scope.gridApi.pagination.seek(1);
    });

    function init() {
      getGracePeriodSetting();
      vm.getInsuranceClaims();
    }

    function $onChanges(changes) {
      // Update list if data change.

      if (changes.insuranceClaimsRequestParams.currentValue) {
        // Hack: To bypass multiple api call when exporting to excel.

        if (vm.insuranceClaimsRequestParams.isExport) return;

        vm.getInsuranceClaims();
      }
    }

    function getInsuranceClaims() {
      vm.loading += 1;
      insurerClaimSvc
        .getInsurerClaimsListing(vm.insuranceClaimsRequestParams)
        .success((data) => {
          vm.gridOptions.totalItems = data.totalCount;
          vm.gridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function viewPatientTicket(rowData) {
      if (rowData.serviceType === 7 || rowData.serviceType === 8 || rowData.serviceType === 251) {
        // Hospitalization / Maternity / IhmAso

        $state.go('inpatientTicketDetail', {
          ticketNumber: rowData.ticketNumber,
        });
      } else if (rowData.serviceType === 6) {
        // Pharmacy

        $state.go('patientTicketDetailPharmacy', {
          ticketNumber: rowData.ticketNumber,
        });
      } else {
        // Others

        $state.go('patientTicketDetail', {
          ticketNumber: rowData.ticketNumber,
        });
      }
    }

    function dismissInsuranceClaim(insuranceClaim) {
      abp.message.confirm(
        App.localize('InsuranceClaimWillBeNoLongerAvailable'),
        App.localize('AreYouSure'),
        (result) => {
          if (result) {
            insurerClaimSvc
              .dismissPrePostInsuranceClaim({
                id: insuranceClaim.id,
              })
              .success(() => {
                vm.getInsuranceClaims();
                abp.notify.success(App.localize('SuccessfullyDeleted'));
              });
          }
        }
      );
    }

    function confirmInsuranceUtilization(rowData) {
      $modal
        .open({
          templateUrl: require('./confirmInsuranceUtilization/editOrViewInsuranceUtilizationModal.html'),
          controller:
            'host.views.operationsDashboard.insuranceClaims.insuranceClaimsListing.confirmInsuranceUtilization.editOrViewInsuranceUtilizationModal as vm',
          backdrop: 'static',
          resolve: {
            insurerClaimId() {
              return rowData.id;
            },
          },
        })
        .result.then((paramsForConfirmUtilization) => {
          $modal
            .open({
              templateUrl: require('./confirmInsuranceUtilization/confirmInsuranceUtilization.modal.html'),
              controller:
                'host.views.operationsDashboard.insuranceClaims.insuranceClaimsListing.confirmInsuranceUtilization.confirmInsuranceUtilizationModal as vm',
              backdrop: 'static',
              resolve: {
                title() {
                  return paramsForConfirmUtilization.hospitalisationInsuranceUtilisationAmount > 0
                    ? App.localize('ApproveInsurerClaim')
                    : App.localize('RejectInsurerClaim');
                },
                specialistOrGP() {
                  if (paramsForConfirmUtilization.insurerClaimServiceType === 1) {
                    return App.localize('GpUtilizationAmount');
                  }
                  if (paramsForConfirmUtilization.insurerClaimServiceType === 5) {
                    return App.localize('SpecialistUtilisationAmount');
                  }
                  return null;
                },
                insurerClaimCategory: paramsForConfirmUtilization.insurerClaimCategory,
                insurerCreditNoteAmount: paramsForConfirmUtilization.insurerCreditNoteAmount,
                companyCreditNoteAmount: paramsForConfirmUtilization.companyCreditNoteAmount,
                memberInvoiceAmount: paramsForConfirmUtilization.memberInvoiceAmount,
                normalUtilizationAmount: paramsForConfirmUtilization.normalUtilizationAmount,
                hospitalisationInsuranceUtilizationAmount:
                  paramsForConfirmUtilization.hospitalisationInsuranceUtilisationAmount,
              },
            })
            .result.then((isConfirmed) => {
              if (!isConfirmed) return;

              // Update the confirmed utilization's service type
              // and amount for the following ticket.

              memberUtilizationSvc
                .updateUtilizationUsage({
                  panelClaimId: paramsForConfirmUtilization.panelClaimId,
                  relatedAdmissionPanelClaimId:
                    paramsForConfirmUtilization.relatedAdmissionPanelClaimId,
                  editUtilizationTypeText: null,
                  remarks: paramsForConfirmUtilization.remarks,
                  memberUtilizations: paramsForConfirmUtilization.memberUtilizations,
                  overwritePreviousBenefitGroupWithCurrent:
                    paramsForConfirmUtilization.useCurrentInsuranceBenefitGroup,
                })
                .success(() => {
                  insurerClaimSvc
                    .generateTransactionsForInsurerClaim({
                      insurerId: paramsForConfirmUtilization.insurerId,
                      ticketNumber: paramsForConfirmUtilization.panelTicketNumber,
                      confirmedInsurerCreditNoteAmount:
                        paramsForConfirmUtilization.insurerCreditNoteAmount,
                      remark: paramsForConfirmUtilization.remarks,
                      insurerClaimId: paramsForConfirmUtilization.insurerClaimId,
                      memberInvoiceAmount: paramsForConfirmUtilization.memberInvoiceAmount,
                      memberPaymentAccountId: paramsForConfirmUtilization.memberPaymentAccountId,
                      memberPaymentEmailAddresses:
                        paramsForConfirmUtilization.memberPaymentEmailAddress,
                      organizationCreditNoteAmount:
                        paramsForConfirmUtilization.companyCreditNoteAmount,
                      insuranceUtilizedAmount:
                        paramsForConfirmUtilization.hospitalisationInsuranceUtilisationAmount,
                    })
                    .success(() => {
                      abp.notify.success(App.localize('InsuranceUtilisationIsConfirmed'));
                      vm.getInsuranceClaims();
                    });
                });
            });
        });
    }

    function getGracePeriodSetting() {
      vm.loading += 1;
      insurerClaimSvc
        .getGracePeriodSetting()
        .success((data) => {
          vm.gracePeriodSetting = data.gracePeriodSetting;
          vm.gracePeriodTooltip = App.localize(
            'InsuranceClaimWithinAdjustmentPeriodNarrative',
            vm.gracePeriodSetting
          );
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function isWithinGracePeriod(ticketCreationDate) {
      // Check if the ticket is within the grace period set from creation.
      const currentTime = moment.utc();
      const creationTime = moment.utc(ticketCreationDate);

      const gracePeriodEndTime = creationTime.add(vm.gracePeriodSetting, 'days');

      return currentTime.isBefore(gracePeriodEndTime);
    }

    function getDateString(date) {
      return App.getDateString(moment.utc(date));
    }
  }
})();
