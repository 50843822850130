import SmsCounter from 'sms-counter';

(() => {
  angular.module('sms').controller('sms.views.smsSender.page', SmsSenderPageController);
  SmsSenderPageController.$inject = ['$scope', 'smsService'];
  function SmsSenderPageController($scope, smsSvc) {
    const vm = this;
    vm.loading = 0;
    vm.saving = 0;
    vm.logs = [];
    vm.requestParams = {
      recipients: null,
      message: null,
    };
    vm.counter = {};
    vm.sendSms = sendSms;
    vm.updateCounter = updateCounter;
    $scope.$on('$viewContentLoaded', () => {
      App.initAjax();
    });
    function updateCounter() {
      const msg = vm.requestParams.message || '';
      const counter = SmsCounter.count(`RM0.00 HealthMetrics.com ${msg}`);
      counter.currenLength = counter.length - (counter.messages - 1) * counter.per_message;
      vm.counter = counter;
    }

    function sendSms() {
      if (!vm.requestParams.recipients || !vm.requestParams.message) {
        return false;
      }

      vm.saving += 1;
      smsSvc
        .sendSms(vm.requestParams)
        .success(() => {
          abp.notify.success('Successfully sent!');
        })
        .finally(() => {
          vm.saving -= 1;
        });

      return true;
    }

    function init() {
      vm.updateCounter();
    }
    init();
  }
})();
