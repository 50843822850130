(() => {
  angular.module('app').component('timeRangePicker', {
    templateUrl: require('./timeRangePicker.component.html'),
    controller: ControllerController,
    controllerAs: 'vm',
    bindings: {
      hours: '=',
      disabled: '=',
    },
  });

  ControllerController.$inject = ['moment'];

  function ControllerController(moment) {
    const vm = this;

    vm.deleteHour = deleteHour;
    vm.pushHourItem = pushHourItem;
    vm.validateHour = validateHour;

    init();

    function init() {
      // Add default empty hour

      if (vm.hours.length === 0) pushHourItem();

      validateHour();
    }

    function pushHourItem() {
      vm.hours.push({
        openTime: moment('08:00:00', 'hh:mm:ss').toDate(),
        closeTime: moment('18:00:00', 'hh:mm:ss').toDate(),
      });
      validateHour();
    }

    function deleteHour(index) {
      if (index < vm.hours.length) vm.hours = _.without(vm.hours, vm.hours[index]);
      validateHour();
    }

    function validateHour() {
      vm.hours.error = _.some(
        vm.hours,
        (hour) => hour.openTime.getTime() === hour.closeTime.getTime()
      );
    }
  }
})();
