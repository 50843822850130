(function () {
    var controllerId = 'host.views.publicUsers.resetPasswordModel';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$uibModalInstance', 'abp.services.app.publicUser', 'publicUser',
        function ($uibModalInstance, publicUserSvc, publicUser) {
            function init() {
            }

            var vm = this;
            vm.loading = 0;
            vm.saving = false;
            vm.autoGeneratePassword = true;
            vm.shouldChangePasswordOnNextLogin = false;
            vm.publicUser = publicUser;

            vm.save = function () {
                if (vm.autoGeneratePassword) {
                    vm.password = null;
                }

                vm.saving = true;
                publicUserSvc.resetPassword({
                    id: publicUser.id,
                    autoGeneratePassword: vm.autoGeneratePassword,
                    shouldChangePasswordOnNextLogin: vm.shouldChangePasswordOnNextLogin,
                    password: vm.password
                }).success(function (data) {
                    abp.notify.info(App.localize('SuccessfullySaved'));
                    if (data) {
                        abp.message.success(App.localize('PasswordForUser', publicUser.name), data);
                    }
                    $uibModalInstance.close();
                }).finally(function () {
                    vm.saving = false;
                });
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            init();
        }
    ]);
})();
