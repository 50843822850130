(() => {
  angular
    .module('finance')
    .controller(
      'finance.views.manualTransactionRequests.index',
      ManualTransactionRequestsController
    );

  ManualTransactionRequestsController.$inject = [
    '$scope',
    'moment',
    '$state',
    '$stateParams',
    'financeConstants',
    'abp.services.finance.commonLookup',
    'abp.services.finance.manualTransactionRequests',
    'Hms.Finance.ManualTransactionType',
    'Hms.Finance.ManualTransactionRequestStatus',
  ];

  function ManualTransactionRequestsController(
    $scope,
    moment,
    $state,
    $stateParams,
    financeConsts,
    commonLookup,
    manualTransactionRequestsSvc,
    enumTransactionType,
    enumManualTransactionRequestStatus
  ) {
    const vm = this;

    vm.loading = 0;
    vm.dateRangeModel = new DateRangeModel(
      $stateParams.startDate,
      $stateParams.endDate,
      getManualTransactionRequests
    );
    vm.requestParams = new RequestParamsModel($stateParams);

    vm.enums = {
      transactionType: enumTransactionType,
      manualTransactionRequestStatus: enumManualTransactionRequestStatus,
    };

    vm.manualTransactionRequestsGridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: financeConsts.grid.defaultPageSizes,
      paginationPageSize: financeConsts.grid.defaultPageSize,
      paginationCurrentPage: 1,
      useExternalPagination: true,
      useExternalSorting: true,
      columnDefs: [
        {
          name: App.localize('Actions'),
          displayName: 'Actions',
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
          allowCellFocus: false,
        },
        {
          displayName: App.localize('ManualTransactionRequestNumber'),
          field: 'requestNumber',
          minWidth: 120,
        },
        {
          displayName: App.localize('TransactionNumber'),
          field: 'transactionNumber',
          cellTemplate: 'transactionNumberTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('TransactionType'),
          field: 'transactionType',
          minWidth: 100,
          cellTemplate: 'transactionTypeTemplate',
        },
        {
          displayName: App.localize('Amount'),
          field: 'amount',
          minWidth: 100,
          cellFilter: 'currencyFormat: row.entity.currencyCode',
        },
        {
          displayName: App.localize('Status'),
          field: 'status',
          minWidth: 100,
          cellTemplate: 'statusTemplate',
        },
        {
          displayName: App.localize('PostingDate'),
          field: 'postingDate',
          minWidth: 130,
          cellTemplate: 'postingDateTemplate',
        },
        {
          displayName: App.localize('TransactionCategory'),
          field: 'categoryDisplayName',
          minWidht: 120,
        },
        {
          displayName: App.localize('CreatedBy'),
          field: 'createdBy',
          minWidth: 100,
        },
        {
          displayName: App.localize('LastModificationTime'),
          field: 'lastModificationTime',
          minWidth: 130,
          cellTemplate: 'lastModificationTimeTemplate',
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          vm.requestParams.sorting =
            callback.length && callback[0].field
              ? `${callback[0].field} ${callback[0].sort.direction}`
              : null;
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, getManualTransactionRequests)
        );
      },
      data: [],
    };

    vm.getManualTransactionRequests = getManualTransactionRequests;
    vm.localize = localize;
    vm.getDateTimeString = getDateTimeString;
    vm.createManualTransactionRequest = createManualTransactionRequest;
    vm.delete = deleteManualTransactionRequest;

    init();

    function init() {
      vm.loading += 1;
      commonLookup
        .getCommonSettings()
        .success((data) => {
          vm.manualTransactionGenerator = data.manualTransactionGenerator;
          if (vm.manualTransactionGenerator) {
            getTransactionCategories();
            getManualTransactionRequests();
          } else {
            abp.message.warn('Cannot load because manual transaction generator is not enabled.');
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getTransactionCategories() {
      vm.loading += 1;
      commonLookup
        .getTransactionCategories()
        .success((data) => {
          vm.transactionCategories = _.filter(data, (k) => !k.isSystem);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getManualTransactionRequests() {
      registerStateParams();
      const input = vm.requestParams.toInputDto();
      input.postingDate = input.startDate;

      vm.loading += 1;
      manualTransactionRequestsSvc
        .getManualTransactionRequests(input)
        .success((data) => {
          vm.manualTransactionRequestsGridOptions.totalItems = data.totalCount;
          vm.manualTransactionRequestsGridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function registerStateParams() {
      $state.transitionTo($state.current, vm.requestParams.toStateParams(), {
        notify: false,
      });
    }

    function localize(text) {
      return App.localize(text);
    }

    function getDateTimeString(date, timeZoneId) {
      return App.getDateTimeString(date, timeZoneId);
    }

    function DateRangeModel(startDate, endDate, eventCallback) {
      this.startDate = startDate ? moment(startDate) : null;
      this.endDate = endDate ? moment(endDate) : null;
      this.options = App.createDateRangePickerOptions();
      this.options.eventHandlers = {
        'apply.daterangepicker': () => {
          eventCallback();
        },
      };
    }

    function RequestParamsModel(params) {
      this.requestNumber = params.requestNumber;
      this.transactionNumber = params.transactionNumber;
      this.status = params.status;
      this.transactionType = params.transactionType;
      this.transactionCategory = params.transactionCategory;

      this.skipCount = params.skipCount || 0;
      this.maxResultCount = params.maxResultCount || financeConsts.grid.defaultPageSize;
      this.sorting = params.sorting;

      this.toInputDto = () => {
        const input = $.extend({}, this);

        if (vm.dateRangeModel.startDate) {
          input.startDate = moment(vm.dateRangeModel.startDate).toJSON();
        }

        if (vm.dateRangeModel.endDate) {
          input.endDate = moment(vm.dateRangeModel.endDate).toJSON();
        }

        return input;
      };

      this.toStateParams = () => {
        const startDate = vm.dateRangeModel.startDate
          ? vm.dateRangeModel.startDate.format('YYYY-MM-DD')
          : null;
        const endDate = vm.dateRangeModel.endDate
          ? vm.dateRangeModel.endDate.format('YYYY-MM-DD')
          : null;

        const stateParams = {
          requestNumber: this.requestNumber,
          transactionNumber: this.transactionNumber,
          status: this.status,
          transactionType: this.transactionType,
          transactionCategory: this.transactionCategory,
          startDate,
          endDate,
          skipCount: this.skipCount,
          maxResultCount: this.maxResultCount,
          sorting: this.sorting,
        };
        return stateParams;
      };
    }

    function createManualTransactionRequest() {
      $state.go('finance.createEditManualTransactionRequest', { isCreate: true });
    }

    function deleteManualTransactionRequest(requestId) {
      vm.saving += 1;

      const message = 'This manual transaction request will be deleted';

      abp.message.confirm(message, App.localize('AreYouSure'), (d) => {
        if (d) {
          manualTransactionRequestsSvc
          .deleteManualTransactionRequest({id: requestId})
          .success(() => {
            abp.notify.info(App.localize('SuccessfullyDeleted'));
          })
          .finally(() => {
            vm.saving -= 1;
            getManualTransactionRequests();
          })
        } else {
          vm.saving -= 1;
        }
      });
    }
  }
})();
