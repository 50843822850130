(() => {
  angular
    .module('app')
    .controller('common.views.saleTransactions.changeSignature', ChangeSignatureController);

  ChangeSignatureController.$inject = [
    '$uibModalInstance',
    'abp.services.app.saleTransaction',
    'requestId',
    'type',
  ];

  function ChangeSignatureController($uibModalInstance, saleTransactionSvc, requestId, type) {
    const vm = this;

    vm.saving = 0;
    vm.type = type;

    vm.save = save;
    vm.cancel = cancel;

    init();

    function init() {}

    function save() {
      const signature = vm.accept();
      if (signature.isEmpty || !signature.dataUrl) {
        abp.message.warn(App.localize('MissingSignature'));
        return;
      }
      vm.saving += 1;

      let endpoint;
      if (type === 'verify') {
        endpoint = saleTransactionSvc.verifyPaymentInstructionRequest;
      } else if (type === 'approve') {
        endpoint = saleTransactionSvc.approvePaymentInstructionRequest;
      }

      endpoint({
        id: requestId,
        dataUrl: signature.dataUrl,
      })
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          $uibModalInstance.close();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
