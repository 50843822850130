(function () {
  var componentId = 'revenueCorporatesContainer';
  var module = angular.module('revenue');



  function controller($state, $stateParams, $timeout) {
    const vm = this;
    vm.corporate = null;
    vm.refreshTimeline = null;
    vm.requestParams = {
      view: $stateParams.view,
      filter: $stateParams.filter,
      agentId: $stateParams.agentId,
      corporateId: $stateParams.corporateId,
      section: $stateParams.section,
    };

    vm.hasSubscriptionManagementEnhancement = abp.setting.getBoolean(
      'Hms.Feature.SubscriptionManagementEnhancement'
    );

    vm.$onInit = () => toggleFullLayout(true);
    vm.$onDestroy = () => toggleFullLayout(false);

    vm.replaceCorporate = replaceCorporate;
    vm.registerStateParams = registerStateParams;

    function registerStateParams() {
      $state.transitionTo($state.current, vm.requestParams, { notify: false });
    }

    function replaceCorporate(corporate) {
      if (corporate && vm.corporate) {
        _.assignIn(vm.corporate, corporate);
      } else {
        vm.corporate = corporate;
      }
    }

    function toggleFullLayout(value) {
      if (value) {
        $('body').addClass('full-height-layout');
      } else {
        $('body').removeClass('full-height-layout');
      }
    }

    vm.handleTabChanged = function () {
      $timeout(function () {
        registerStateParams();
      }, 0);
    };
  }

  module.component(componentId, {
    templateUrl: require('./widget.html'),
    controller: ['$state', '$stateParams', '$timeout', controller],
    controllerAs: 'vm',
  });
})();
