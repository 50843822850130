(function () {
    'use strict';

    angular
        .module('app')
        .controller('host.views.hospitalizationExclusions.exclusionEditor',
            ['$stateParams', '$scope', '$timeout', '$state', 'abp.services.app.hospitalizationExclusion', 'moment', controller]);

    function controller($stateParams, $scope, $timeout, $state, hospitalizationExclusionSvc, moment) {
        function init() {
            getExclusions();
        }

        var vm = this;
        vm.loading = 0;
        vm.saving = 0;
        vm.isEditing = false;
        vm.serviceType = $stateParams.serviceType;
        vm.data = null;
        vm.exclusionsForDelete = [];

        vm.permissions = {
          exclusionListEdit: abp.auth.isGranted('Host.Treatments.ExclusionList.Edit')
        };

        // Save all changes on the form.

        vm.saveAll = function () {
            if (vm.saving || vm.loading) return;

            if (!vm.validateForm()) {
                abp.notify.error(App.localize('GeneralInvalidFormInputError'));
                return;
            }
            createOrUpdateExclusions();

            if (vm.exclusionsForDelete.length) {
                deleteSelectedExclusions(vm.exclusionsForDelete);
            }
        };

        vm.validateForm = function () {
            var form = $scope.exclusionClausesEditorForm;
            App.touchFormErrors(form);
            return form.$valid;
        };

        vm.isDirty = function () {
            return $scope.exclusionClausesEditorForm && $scope.exclusionClausesEditorForm.$dirty;
        };

        vm.toggleEdit = function (flag) {
            vm.isEditing = flag;
        };

        // Get exclusions.

        vm.getExclusions = getExclusions();

        function getExclusions() {
            vm.loading++;
            hospitalizationExclusionSvc.getServiceExclusionsForEdit({
                serviceType: vm.serviceType
            }).success(function (record) {
                vm.data = record;
                vm.data.defaultExclusions = vm.data.defaultExclusions || [];
                vm.data.optionalExclusions = vm.data.optionalExclusions || [];
                vm.data.iglImportantNotes = vm.data.iglImportantNotes || [];
                vm.data.addonImportantNotes = vm.data.addonImportantNotes || [];
                vm.data.policyImportantNotes = vm.data.policyImportantNotes || [];
                vm.data.fglImportantNotes = vm.data.fglImportantNotes || [];
            }).finally(function () {
                vm.loading--;
            });
        }

        // Create or update exclusions.

        function createOrUpdateExclusions() {
            var lists = _.unionWith(
                vm.data.policyImportantNotes,
                vm.data.iglImportantNotes,
                vm.data.addonImportantNotes,
                vm.data.defaultExclusions,
                vm.data.optionalExclusions,
                vm.data.fglImportantNotes);

            var updatedList = _.chain(lists)
                .map(function (p) {
                    return _.isNumber(p.id)
                        ? _.extend({}, p, { id: null })
                        : p;
                })
                .value()

            vm.saving++;
            hospitalizationExclusionSvc.createOrUpdateServiceExclusions({
                serviceType: vm.serviceType,
                exclusions: updatedList
            }).success(function () {
                abp.notify.info(App.localize('SuccessfullySaved'));
                $state.reload();
            }).finally(function () {
                vm.saving--;
            });
        }

        // Cancel creation or edit.

        vm.cancel = function () {
            if ((vm.validateForm() && !vm.isDirty()) && !vm.exclusionsForDelete.length) {
                vm.toggleEdit(false);
            }
            else {
                abp.message.confirm(App.localize('UnsavedChangesConfirmation'), App.localize('AreYouSure'), function (d) {
                    if (d) {
                        $state.reload();
                    }
                });
            }
        };

        vm.addExclusion = function (exclusionType) {
            vm.loading++;

            var exclusionList = vm.getExclusionList(exclusionType);
            if (exclusionList === null) return;

            var dtNow = new Date().getTime();
            var newId = -Math.abs(dtNow);

            exclusionList.push({
                id: newId,
                clause: '',
                effectiveDate: moment().format(),
                type: exclusionType,
                isNewRecord: true,
                creationTime: moment()
            });

            // Hack for summernote destroy/reset issue.
            // To retain previous other data and re-initialize the instance upon removing the record.

            resetExclusions(exclusionList);
        };

        vm.getExclusionList = function (type) {
            switch (type) {
                case 0:
                    return vm.data.defaultExclusions;
                case 1:
                    return vm.data.optionalExclusions;
                case 2:
                    return vm.data.iglImportantNotes;
                case 3:
                    return vm.data.addonImportantNotes;
                case 4:
                    return vm.data.policyImportantNotes;
                case 5:
                    return vm.data.fglImportantNotes;
                default:
                    return null;
            }
        };

        vm.remove = function (id, type) {
            if (vm.saving || vm.loading) return;

            vm.loading++;

            var exclusionList = vm.getExclusionList(type);
            if (exclusionList === null) return;

            var index = _.findIndex(exclusionList, { 'id': id });
            if (index === -1) return;

            $('#content-' + type + '-' + id + '').summernote('destroy');
            exclusionList.splice(index, 1);

            vm.exclusionsForDelete.push(id);

            // Hack for summernote destroy/reset issue.
            // To retain previous other data and re-initialize the instance upon removing the record.

            resetExclusions(exclusionList);
        }

        function deleteSelectedExclusions(exclusionsForDelete) {
            _.forEach(exclusionsForDelete, function (id) {
                hospitalizationExclusionSvc.deleteExclusions({ ExclusionId: id })
            })
        }

        function resetExclusions(exclusions) {
            // Clone other previous data except the deleted exclusion.

            var clonedItems = _.map(exclusions, function (p) {
                return {
                    clonedType: p.type,
                    clonedId: p.id,
                    clonedClause: p.clause
                };
            });

            $timeout(function () {
                _.each(clonedItems, function (p) {
                    $('#content-' + p.clonedType + '-' + p.clonedId + '').summernote('code', p.clonedClause);
                });
                vm.loading--;
            }, 1000);
        };

        init();
    }
})();
