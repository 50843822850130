import swal from 'sweetalert';

(() => {
  angular.module('app').component('commonPatientTicketsDetailPostHospitalizationTreatmentSection', {
    templateUrl: require('./postHospitalizationTreatmentSection.component.html'),
    controller: PostHospitalizationTreatmentSectionController,
    controllerAs: 'vm',
    bindings: {
      isReadOnly: '<',
      save: '<',
      onDirtyEvent: '<',
      restoreOriginal: '<',
      isHost: '<',
      ticket: '<',
      hasInsurerClaim: '<',
      hasAlerts: '<',
      coPayAmount: '<',
      onEditAmount: '&',
      onSuccessSave: '&',
      onFinishWithoutSaving: '&',
    },
  });

  PostHospitalizationTreatmentSectionController.$inject = [
    '$uibModal',
    '$scope',
    'Hms.PatientTickets.PatientTicketItem',
  ];

  function PostHospitalizationTreatmentSectionController($modal, $scope, constsPatientTicketItem) {
    const vm = this;

    vm.constsPatientTicketItem = constsPatientTicketItem;

    vm.tempSections = null;
    vm.isHealthScreeningTicket = false;

    vm.$onInit = $onInit;
    vm.$onChanges = onChanges;
    vm.checkInput = checkInput;
    vm.onDirtyEventHandler = onDirtyEventHandler;
    vm.isTotalBillAmountValid = isTotalBillAmountValid;
    vm.areAmountFieldsValid = areAmountFieldsValid;

    init();

    function init() {}

    function $onInit() {
      vm.tempSections = angular.copy(vm.ticket.sections);
      vm.onEditAmount();

      vm.isPostHospitalizationTicket = vm.ticket.serviceType === 10;
    }

    function onChanges(changes) {
      if (changes.isReadOnly && !changes.isReadOnly.currentValue) {
        vm.tempSections = angular.copy(vm.ticket.sections);
      }

      if (changes.onDirtyEvent && changes.onDirtyEvent.currentValue) {
        vm.onDirtyEvent.handler = vm.onDirtyEventHandler;
      }

      if (changes.save && !changes.save.isFirstChange()) {
        if (!App.isFormValid($scope.specialistAmountForm) || !vm.isTotalBillAmountValid()) {
          abp.notify.error(App.localize('GeneralInvalidFormInputError'));
          return;
        }

        saveDetails();
      }

      if (changes.restoreOriginal && !changes.restoreOriginal.isFirstChange()) {
        finishWithoutSaving();
      }
    }

    function onDirtyEventHandler() {
      return $scope.specialistAmountForm && $scope.specialistAmountForm.$dirty;
    }

    function finishWithoutSaving() {
      swal(
        {
          title: App.localize('PatientTicketSpecialistDetail_FinishWithoutSavingNarrative'),
          showCancelButton: true,
          closeOnConfirm: false,
          confirmButtonColor: '#1ab394',
        },
        () => {
          vm.ticket.sections = angular.copy(vm.tempSections);
          vm.tempSections = null;
          vm.onEditAmount();
          vm.onFinishWithoutSaving();
          $scope.specialistAmountForm.$setPristine();
          swal.close();
        }
      );
    }

    function saveDetails() {
      $modal
        .open({
          templateUrl: require('../editSpecialistAmount/editSpecialistAmount.modal.html'),
          controller: 'common.views.patientTickets.detail.editSpecialistAmountModal as vm',
          backdrop: 'static',
          resolve: {
            ticket() {
              return vm.ticket;
            },
            totalClaimableAmount() {
              return vm.ticket.totalClaimableAmount;
            },
            coPayAmount() {
              return vm.coPayAmount;
            },
            hasInsurerClaim: vm.hasInsurerClaim,
          },
        })
        .result.then(() => {
          vm.onSuccessSave();
        });
    }

    function checkInput() {
      vm.onEditAmount();
    }

    function isTotalBillAmountValid() {
      let totalBillAmount = 0;
      _.forEach(vm.ticket.sections, (item) => {
        _.forEach(item.treatmentCategories, (d) => {
          totalBillAmount += (d.coveredAmount || 0) + (d.uncoveredAmount || 0);
        });
      });

      return vm.areAmountFieldsValid()
        ? vm.ticket.amount === App.roundAmount(totalBillAmount)
        : false;
    }

    function areAmountFieldsValid() {
      let isValid = true;

      _.forEach(vm.ticket.sections, (item) => {
        _.forEach(item.treatmentCategories, (d) => {
          if (
            typeof d.coveredAmount === 'undefined' ||
            d.amount === null ||
            typeof d.uncoveredAmount === 'undefined' ||
            d.uncoveredAmount === null
          ) {
            isValid = false;
          }
        });
      });
      return isValid;
    }
  }
})();
