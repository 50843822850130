(function () {
    var controllerId = 'host.views.insuranceClaims.batchDraftsModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$state', '$uibModalInstance', 'insuranceClaimsBatchDrafts', 'abp.services.app.insurerClaim',
        function ($scope, $state, $uibModalInstance, insuranceClaimsBatchDrafts, insurerClaimSvc) {
            function init() {
                processBatches();
            }

            var vm = this;
            vm.loading = 0;
            vm.insuranceClaimsBatchDrafts = insuranceClaimsBatchDrafts;
            vm.batches = Object.values(vm.insuranceClaimsBatchDrafts);
            vm.processedBatches = [];
            vm.chosenTicketNumbers = [];
            vm.batchNumberFilter = '';
            vm.completeInsuranceClaimDraft = completeInsuranceClaimDraft;
            vm.deleteInsuranceClaimDraft = deleteInsuranceClaimDraft;

            function processBatches() {
                _.forEach(vm.batches, function (batch) {
                    _.forEach(batch, function (claim) {
                        if (!vm.processedBatches.filter(x => x.insurerBatchId === claim.insurerBatchId).length > 0) {
                            vm.processedBatches.push(claim);
                        }
                    });
                });
            }

            function completeInsuranceClaimDraft(insurerBatchId) {
                var chosenBatch = vm.insuranceClaimsBatchDrafts[insurerBatchId];
                _.forEach(chosenBatch, function (claim) {
                    vm.chosenTicketNumbers.push(claim.ticketNumber);
                });
                $state.go('host.insuranceClaimsBatchAction', {
                    activeTab: 0,
                    insurerBatchId: insurerBatchId,
                    chosenTicketNumbers: vm.chosenTicketNumbers,
                    completeDraft: true
                });
                vm.close();
            }

            function deleteInsuranceClaimDraft(batch) {
                abp.message.confirm(App.localize('DeleteSelectedInsurerClaimBatchDraft', batch.batchNumber), App.localize('AreYouSure'), function (option) {
                    if (option) {
                        vm.loading++;
                        insurerClaimSvc.deleteInsuranceClaimsBatchDraft({
                            insurerBatchId: batch.insurerBatchId
                        }).success(function () {
                            abp.notify.info(App.localize('SuccessfullyDeleted'));
                        }).finally(function () {
                            vm.loading--;
                            vm.close();
                        });
                    }
                });
            }

            vm.close = function () {
                $uibModalInstance.close();
            }

            init();
        }
    ]);
})();
