(function () {
    'use strict';

    var app = angular.module('app');
    app.controller('corporate.views.insurancePlans',
            ['$state', '$stateParams', '$scope', '$timeout', 'abp.services.app.benefitGroup', controller]);

    function controller($state, $stateParams, $scope, $timeout, benefitGroupSvc) {
        function init() {
            vm.loading = true;
            $timeout(function () {
                vm.insurancePlanGridOptions.paginationCurrentPage = Math.floor(vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1);
            });
        }

        var vm = this;
        vm.loading = false;

        vm.requestParams = {
            skipCount: $stateParams.skipCount,
            maxResultCount: $stateParams.maxResultCount,
            sorting: $stateParams.sorting
        };

        function registerStateParams() {
            $state.transitionTo($state.current,
                {
                    skipCount: vm.requestParams.skipCount,
                    maxResultCount: vm.requestParams.maxResultCount,
                    sorting: vm.requestParams.sorting
                }, {
                notify: false
            });
        }

        vm.insurancePlanGridOptions = {
            appScopeProvider: vm,
            paginationPageSizes: app.consts.grid.defaultPageSizes,
            paginationPageSize: app.consts.grid.defaultPageSize,
            paginationCurrentPage: 0,
            useExternalPagination: true,
            useExternalSorting: true,
            columnDefs: [
                {
                    displayName: App.localize('PlanName'),
                    field: 'name',
                    minWidth: 120,
                    cellTemplate: 'nameTemplate'
                }, {
                    displayName: App.localize('PlanType'),
                    field: 'serviceType',
                    minWidth: 80,
                    cellTemplate: 'planTypeTemplate'
                }, {
                    displayName: App.localize('MasterPolicy'),
                    field: 'masterPolicyNumber',
                    minWidth: 100,
                }, {
                    displayName: App.localize('ServiceType'),
                    field: 'serviceType',
                    minWidth: 80,
                    cellTemplate: 'serviceTypeTemplate'
                }, {
                    displayName: App.localize('Insurer'),
                    field: 'insurerName',
                    minWidth: 120,
                    cellTemplate: 'insurerTemplate'
                }, {
                    displayName: App.localize('EffectivePeriod'),
                    field: 'effectiveDatePeriod',
                    minWidth: 80,
                    enableSorting: false
                }
            ],
            onRegisterApi: function (gridApi) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function (scope, callback) {
                    var sorting = '';
                    for (var i = 0; i < callback.length; i++) {
                        if (i) sorting += ', ';
                        sorting += callback[i].field + ' ' + callback[i].sort.direction;
                    }
                    vm.requestParams.sorting = sorting;
                    vm.getInsurancePlans();
                });
                gridApi.pagination.on.paginationChanged($scope, App.setPagination(vm.requestParams, vm.getInsurancePlans));
            },
            data: []
        };

        vm.getInsurancePlans = function () {
            vm.loading = true;

            registerStateParams();

            var input = {
                skipCount: vm.requestParams.skipCount,
                maxResultCount: vm.requestParams.maxResultCount,
                sorting: vm.requestParams.sorting
            };

            benefitGroupSvc.getInsurancePlans(input).success(function (data) {
                vm.insurancePlanGridOptions.totalItems = data.totalCount;
                vm.insurancePlanGridOptions.data = data.items;
            }).finally(function (e) {
                vm.loading = false;
            });
        };

        init();
    }
})();
