(function () {
    var controllerId = 'host.view.claimSubmissionTemplate.index';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', '$uibModal', '$timeout', 'abp.services.app.insuranceClaimSubmission',
        function ($scope, $state, $stateParams, $modal, $timeout, insuranceClaimSubmissionSvc) {
            var vm = this;
            vm.canEditClaimSubmissionTemplate = abp.auth.isGranted('Host.ClaimSubmissionTemplate.Edit');
            vm.canDeleteClaimSubmissionTemplate = abp.auth.isGranted('Host.ClaimSubmissionTemplate.Delete');
            vm.canCreateClaimSubmissionTemplate = abp.auth.isGranted('Host.ClaimSubmissionTemplate.Create');

            function init() {
                vm.getClaimSubmissionTemplates();
                $timeout(function () {
                    vm.gridOptions.paginationCurrentPage = Math.floor(vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1);
                });
            }

            vm.loading = false;

            vm.requestParams = {
                skipCount: $stateParams.skipCount || 0,
                maxResultCount: $stateParams.maxResultCount || app.consts.grid.defaultPageSize,
                sorting: $stateParams.sorting,
            }

            // #region Define function

            // Define the grid option.

            vm.gridOptions = {
                appScopeProvider: vm,
                paginationPageSizes: app.consts.grid.defaultPageSizes,
                paginationPageSize: app.consts.grid.defaultPageSize,
                paginationCurrentPage: 0,
                useExternalPagination: true,
                useExternalSorting: true,
                enableRowSelection: true,
                enableSelectAll: false,
                enableFullRowSelect: true,
                enableRowHeaderSelection: false,
                multiSelect: false,
                columnDefs: [{
                    enableSorting: false,
                    name: App.localize('Actions'),
                    width: 100,
                    headerCellTemplate: "<span></span>",
                    cellTemplate: 'actionTemplate'
                }, {
                    displayName: App.localize('Insurer'),
                    field: 'insurerId',
                    minWidth: 150,
                    cellTemplate: "nameTemplate"
                }, {
                    enableSorting: false,
                    displayName: App.localize('LastUpdate'),
                    field: 'lastUpdateDate',
                    cellFilter: 'momentFormat: \'L\'',
                    minWidth: 150,
                }],
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                    $scope.gridApi.core.on.sortChanged($scope, function (scope, callback) {
                        var sorting = '';
                        for (var i = 0; i < callback.length; i++) {
                            if (i) sorting += ', ';
                            sorting += callback[i].field + ' ' + callback[i].sort.direction;
                        }
                        vm.requestParams.sorting = sorting;
                        vm.getClaimSubmissionTemplates();
                    });
                    gridApi.pagination.on.paginationChanged($scope, App.setPagination(vm.requestParams, vm.getClaimSubmissionTemplates));
                },
                data: []
            };

            // To get the input for fetching templates.

            function getRequestInput() {
                registerStateParams();
                return vm.requestParams;
            }

            // To update the state param (URL param).

            function registerStateParams() {
                $state.transitionTo($state.current, {
                    sorting: vm.requestParams.sorting,
                    skipCount: vm.requestParams.skipCount,
                    maxResultCount: vm.requestParams.maxResultCount,
                }, {
                    notify: false
                });
            }

            // To fetch all created claim submission templates.

            vm.getClaimSubmissionTemplates = function () {
                if (vm.loading) return;

                var input = getRequestInput();

                vm.loading = true;
                insuranceClaimSubmissionSvc.getInsurerTemplates(input).success(function (data) {
                    vm.gridOptions.data = data.items;
                    vm.gridOptions.totalItems = data.totalCount;
                }).finally(function () {
                    vm.loading = false;
                });
            }

            // #region Action CRUD

            vm.create = function () {
                $state.go('host.createClaimSubmissionTemplate')
            }

            vm.edit = function (template) {
                $state.go('host.editClaimSubmissionTemplate', { 'templateId': template.id })
            };

            vm.view = function (template) {
                $modal.open({
                    templateUrl: require('/App/host/views/claimSubmissionTemplate/view/view.modal.html'),
                    controller: "host.views.claimSubmissionTemplate.viewModal as vm",
                    backdrop: "static",
                    resolve: {
                        templateId: function () {
                            return template.id;
                        },
                        insurerName: function () {
                            return template.insurerName;
                        },
                    }
                }).result.then(function () {
                    vm.getClaimSubmissionTemplates();
                });
            }

            vm.delete = function (template) {
                abp.message.confirm(App.localize('TemplateDeleteWarningMessage', template.insurerName), App.localize('AreYouSure'), function (result) {
                    if (result) {
                        insuranceClaimSubmissionSvc.deleteInsuranceClaimSubmissionTemplate({
                            id: template.id
                        }).success(function (data) {
                            vm.getClaimSubmissionTemplates();
                            abp.notify.success(App.localize('SuccessfullyDeleted'));
                        });
                    }
                });
            }

            // #endregion Action CRUD

            // #endregion Define function

            init();
        }
    ]);
})();
