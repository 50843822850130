(function () {
    var controllerId = 'clinic.views.hospital.fglRequest';
    var app = angular.module('app');
    app.controller(controllerId, ['$scope', '$stateParams', '$state', 'abp.services.app.hospitalPanelServiceRequest', 'abp.services.app.commonLookup', 'moment', controller]);

    function controller($scope, $stateParams, $state, hospitalPanelServiceRequestSvc, commonLookupSvc, moment) {
        var vm = this;
        vm.loading = 0;
        vm.saving = 0;
        vm.exporting = 0;
        vm.letterNumber = $stateParams.letterNumber;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.hasInpatientRegionalExpansion = abp.setting.getBoolean(
            'Hms.Feature.RegionalExpansionForInpatient');

        vm.activeTab = 0;
        vm.prevTab = prevTab;
        vm.nextTab = nextTab;
        vm.setActiveTab = setActiveTab;
        vm.submit = submit;
        vm.downloadDischargeForm = downloadDischargeForm;

        vm.newAttachments = [];
        vm.attachments = [];
        var fileExtensions = '|' + app.consts.fileUpload.imageExtensions.concat(app.consts.fileUpload.pdfExtensions).join('|') + '|';
        vm.uploadConfig = {
            objectType: 'InpatientFglRequestAttachment',
            itemsLimit: 5,
            accept: 'image/*,application/pdf',
            filters: [{
                name: 'fileFilter',
                fn: function (n) {
                    var t = '|' + n.type.slice(n.type.lastIndexOf('/') + 1) + '|';
                    if (fileExtensions.indexOf(t) === -1) {
                        abp.message.warn(App.localize('File_Warn_FileType'));
                        return false;
                    }
                    return true;
                }
            }, {
                name: 'sizeFilter',
                fn: function (n) {
                    var sizeLimit = 52428800;
                    if (n.size > sizeLimit) {
                        abp.message.warn(App.localize('File_Warn_XSizeLimit', sizeLimit / 1024 / 1024));
                        return false;
                    }
                    return true;
                }
            }]
        };
        var maxDate = moment([moment().year() + 5, 1, 1]);

        function init() {
            getPatientDischargeDetails();
            if (vm.hasInpatientRegionalExpansion) {
                getCurrency();
                getLanguage();
            }
            
        }

        function getPatientDischargeDetails() {
            vm.loading++;
            hospitalPanelServiceRequestSvc.getDischargePatientDetails({
                letterNumber: vm.letterNumber
            }).success(function (data) {
                vm.letter = data;

                _.each(vm.letter.documentChecklistItems, function (item) {
                    item.required = item.isMandatory;
                    item.isChecked = item.isSelected;
                });
            }).finally(function () {
                vm.loading--;
            });
        }

        function getCurrency() {
            vm.loading += 1;
                commonLookupSvc
                    .getTenantCurrencyCode()
                    .success((data) => { vm.currencyCode = data })
                    .finally(() => { vm.loading -= 1 });
        }

        function getLanguage() {
            vm.loading += 1;
            commonLookupSvc
                .getDefaultLanguageByCountryCode()
                .success((data) => { vm.language = data })
                .finally(() => { vm.loading -= 1 });
        }


        function submit() {
            if (vm.saving)
                return;

            var checklistItems = [];
            _.each(vm.letter.documentChecklistItems, function (item) {
                checklistItems.push({
                    text: item.text,
                    isSelected: item.isChecked,
                    isMandatory: item.required
                });
            });

            var attachments = [];
            _.each(vm.attachments, function (attachment) {
                if (!attachment.isDelete)
                    attachments.push(attachment.id);
            });

            var input = $.extend(vm.letter, {
                dischargeDate: vm.letter.dischargeDate,
                documentChecklistItems: checklistItems,
                newAttachments: vm.newAttachments
            });

            vm.saving = true;
            hospitalPanelServiceRequestSvc.requestFinalGuaranteeLetter(input)
                .success(function () {
                    abp.notify.info(App.localize("SuccessfullySubmitted"));
                    $state.go('clinic.inpatientDashboard');
                }).finally(function () {
                    vm.saving = false;
                });
        }

        function downloadDischargeForm(language) {
            vm.exporting++;

            hospitalPanelServiceRequestSvc.getPatientDischargeFormPdf({
                letterNumber: vm.letterNumber,
                doctorName: vm.letter.specialistOrPhysicianName,
                languageCode: language
            }).success(function (data) {
                App.downloadTempFile(data);
            }).finally(function () {
                vm.exporting--;
            });
        }

        /* Navigation */

        function prevTab() {
            setActiveTab(vm.activeTab - 1);
        }

        function nextTab() {
            if (vm.activeTab === 2) {
                return;
            }

            else if (vm.activeTab === 0 && !App.isFormValid($scope.forms.finalDiagnosisDetails)) {
                return;
            }

            else if (vm.activeTab === 1) {
                vm.invalidAttachment = vm.newAttachments.length < 1;
                vm.invalidChecklist = !App.isFormValid($scope.forms.uploadDocuments) && _.some(vm.letter.documentChecklistItems, function (d) { return d.required && !d.isChecked; });

                if (vm.invalidAttachment || vm.invalidChecklist) {
                    return;
                }
            }

            setActiveTab(vm.activeTab + 1);
        }

        function setActiveTab(tab) {
            if (tab < 0 || tab > 3) return; // Prevent tab out of range.

            vm.activeTab = tab;
        }

        /* End of Navigation */

        init();
    }
})();
