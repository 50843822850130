(() => {
  angular.module('app').component('commonInpatientTicketsDetailSubcalculationSectionsComponent', {
    templateUrl: require('./subcalculationSections.component.html'),
    controller: SubcalculationSectionsController,
    controllerAs: 'vm',
    bindings: {
      currencyCode: '<',
      totalCoveredAmount: '<',
      totalUncoveredAmount: '<',
      subtotalAmount: '<',
      isTaxInclusive: '<',
      taxDescription: '<',
      taxPercentage: '<',
      coveredTaxAmount: '<',
      uncoveredTaxAmount: '<',
    },
  });

  SubcalculationSectionsController.$inject = [];

  function SubcalculationSectionsController() {
    const vm = this;

    vm.$onInit = init;

    function init() {}
  }
})();
