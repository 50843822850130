(() => {
  angular
    .module('app')
    .component('postHospitalizationGuaranteeLettersProcessingRequestDetailsComponent', {
      templateUrl: require('./requestDetails.component.html'),
      controller: RequestDetailsComponentController,
      controllerAs: 'vm',
      bindings: {
        guaranteeLetterNumber: '<',
        serviceRequestId: '<',
        onEdit: '&',
        onSave: '&',
        newGuaranteeAmount: '=',
        currencyCode: '<',
      },
    });

  RequestDetailsComponentController.$inject = [
    'abp.services.app.hostServiceRequest',
    'moment',
  ];

  function RequestDetailsComponentController(hostServiceRequestSvc, moment) {
    const vm = this;

    vm.$onChanges = onChanges;
    vm.edit = edit;
    vm.save = save;
    vm.cancel = cancel;

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.isEdit = false;
    vm.loading = 0;
    vm.diagnosis = null;
    vm.documents = [];
    vm.filteredDoctors = [];
    vm.admissionTypes = [];
    vm.request = null;

    let preEditRequest = null;
    let preEditDocuments = null;


    /* Gurantee Letter (GL) attachments */

    vm.newAttachments = [];
    vm.uploadConfig = {
      objectType: 'LetterAttachment',
    };

    vm.isInvalidAttachment = function () {
      return (
        vm.employee.requireReferral &&
        !vm.isDisciplineInReferralExceptions &&
        !vm.newAttachments.length
      );
    };

    vm.$onInit = init;

    function init() {}
    vm.updateNewGuaranteeAmount = updateNewGuaranteeAmount;

    function onChanges(changes) {
      if (changes.serviceRequestId && changes.serviceRequestId.currentValue) {
        getRequestDetails(vm.serviceRequestId);
      }
    }

    function getRequestDetails(serviceRequestId) {
      vm.loading += 1;
      hostServiceRequestSvc
        .getPostHospitalizationIglRequestDetails({
          id: serviceRequestId,
        })
        .success((data) => {
          vm.request = {
            requestType: data.requestType,
            requestTime: data.requestTime,
            requestedBy: data.requestedBy,
            guaranteeLetterNumber: data.guaranteeLetterNumber,
            appointmentDate: data.appointmentDate,
            doctorNames: data.doctorNames,
            newGuaranteeAmount: data.newGuaranteeAmount,
          };

          vm.documents = _.map(data.supportingDocuments, (d) => ({
            externalObjectId: d.externalObjectId,
            url: d.url,
            name: d.name,
            uploader: `${d.uploader} ${moment(d.uploadedTime).format('L, LT')}`,
            applied: d.isApplied,
            state: d.state,
            isEditable: true,
          }));
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function edit() {
      backupInitialStates();
      vm.isEdit = true;
      vm.onEdit({ isEdit: true });
    }

    function save() {
      if (App.isFormValid(vm.requestDetailsForm)) {
        vm.loading += 1;

        vm.request.id = vm.serviceRequestId;
        vm.request.newGuaranteeAmount = App.roundAmount(vm.request.newGuaranteeAmount);

        let supportingDocuments = [];
        if (vm.documents) {
          supportingDocuments = _.map(vm.documents, (d) => ({
            externalObjectId: d.externalObjectId,
            isApplied: d.applied,
          }));
        }

        const input = $.extend({}, vm.request, { supportingDocuments });

        hostServiceRequestSvc
          .updatePostHospitalizationIglRequestDetails(input)
          .success(() => {
            abp.notify.info(App.localize('SuccessfullySaved'));
            getRequestDetails(vm.serviceRequestId);
            vm.isEdit = false;
            vm.onEdit({ isEdit: false });
            vm.onSave();
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    }

    function cancel() {
      restoreInitialStates();
      vm.isEdit = false;
      vm.onEdit({ isEdit: false });
    }

    function backupInitialStates() {
      preEditRequest = angular.copy(vm.request);
      preEditDocuments = angular.copy(vm.documents);
    }

    function restoreInitialStates() {
      vm.request = preEditRequest;
      vm.documents = preEditDocuments;
    }

    function updateNewGuaranteeAmount() {
      if (vm.request.newGuaranteeAmount) vm.newGuaranteeAmount = vm.request.newGuaranteeAmount;
      else vm.newGuaranteeAmount = 0;
    }
  }
})();
