(() => {
  angular
    .module('revenue')
    .controller('revenue.views.corporateSubscriptions.create', CreateSubscriptionsController);

  CreateSubscriptionsController.$inject = [
    '$state',
    '$scope',
    '$stateParams',
    'abp.services.revenue.subscription',
    'abp.services.revenue.subscriptionPlan',
    'abp.services.revenue.common',
    '$uibModal',
    'moment',
    'HmsRevenueConsts.SubscriptionPlan',
    'Hms.Revenue.AgentType',
    'Hms.Revenue.SubscriptionPlanCategory',
    'Hms.Revenue.SubscriptionPlanPricingMode',
    'Hms.Revenue.SubscriptionPlanChargeMode',
    'Hms.Revenue.OutpatientServiceFeatures',
    'Hms.Revenue.InpatientServiceFeatures',
  ];

  function CreateSubscriptionsController(
    $state,
    $scope,
    $stateParams,
    subscriptionSvc,
    subscriptionPlanSvc,
    revenueCommonSvc,
    $uibModal,
    moment,
    constsSubscriptionPlan,
    agentTypeEnum,
    enumSubscriptionPlanCategory,
    enumSubscriptionPlanPricingMode,
    enumSubscriptionPlanChargeMode,
    enumOutpatientServiceFeatures,
    enumInpatientServiceFeatures
  ) {
    const vm = this;

    vm.loading = 0;
    vm.corporateId = $stateParams.corporateId;
    vm.enums = {};
    vm.constsSubscriptionPlan = constsSubscriptionPlan;
    vm.categoryCharges = [];
    vm.currencyCode = 'MYR';
    vm.subsidiaries = [];
    vm.subscriptionPlans = [];
    vm.subscriptionPlanId = null;
    vm.disablePrepaid = false;
    vm.enums.SubscriptionPlanCategory = enumSubscriptionPlanCategory;
    vm.enums.subscriptionPlanPricingMode = enumSubscriptionPlanPricingMode;
    vm.enums.subscriptionPlanChargeMode = enumSubscriptionPlanChargeMode;
    vm.enums.outpatientServiceFeatures = enumOutpatientServiceFeatures;
    vm.enums.inpatientServiceFeatures = enumInpatientServiceFeatures;
    vm.subscriptions = [];

    vm.serviceFeatures = _.union(
      vm.enums.outpatientServiceFeatures.values,
      vm.enums.inpatientServiceFeatures.values
    );

    vm.subscription = {
      id: null,
      name: '',
      corporateId: $stateParams.corporateId,
      scope: null,
      subsidiaryIds: [],
      subsidiaryNames: [],
      isTargetAccount: null,
      billingAccountId: null,
      billingAccountName: '',
      effectiveStartDate: null,
      effectiveEndDate: null,
      subscriptionPlanId: null,
      billingFrequency: null,
      chargeMode: 0,
      pricingMode: 0,
      salesPersonId: null,
      salesPersonName: '',
      introducerId: null,
      introducerName: '',
      categoryCharges: [],
    };

    vm.subscriptionPlan = {
      planName: null,
      planCategory: null,
      pricingMode: null,
      chargeMode: null,
      effectiveDate: null,
    };

    vm.salesPersonLookup = {
      cachedResults: [],
      activeResults: [],
      lastSearchKey: '',
      lookup(search) {
        this.lastSearchKey = (search || '').trim().toLowerCase();
        const cachedCopy = this.cachedResults[this.lastSearchKey];
        if (cachedCopy) {
          this.activeResults = cachedCopy;
        } else {
          const mgr = this;
          const salesType = [];

          salesType.push(agentTypeEnum.SalesPerson.id);
          salesType.push(agentTypeEnum.CustomerSuccess.id);

          revenueCommonSvc
            .findAgents({
              filter: this.lastSearchKey,
              maxResultCount: 100,
              types: salesType,
              activeOnly: true,
            })
            .success((data) => {
              const dataKey = (data.filter || '').trim().toLowerCase();
              mgr.cachedResults[dataKey] = data.items;
              if (dataKey === mgr.lastSearchKey) {
                mgr.activeResults = data.items;
              }
            });
        }
      },
    };

    vm.introducerLookup = {
      cachedResults: [],
      activeResults: [],
      lastSearchKey: '',
      lookup(search) {
        this.lastSearchKey = (search || '').trim().toLowerCase();
        const cachedCopy = this.cachedResults[this.lastSearchKey];
        if (cachedCopy) {
          this.activeResults = cachedCopy;
        } else {
          const mgr = this;
          const salesType = [];

          salesType.push(agentTypeEnum.Introducer.id);

          revenueCommonSvc
            .findAgents({
              filter: this.lastSearchKey,
              maxResultCount: 100,
              types: salesType,
              activeOnly: true,
            })
            .success((data) => {
              const dataKey = (data.filter || '').trim().toLowerCase();
              mgr.cachedResults[dataKey] = data.items;
              if (dataKey === mgr.lastSearchKey) {
                mgr.activeResults = data.items;
              }
            });
        }
      },
    };

    vm.getSubscriptionPlan = getSubscriptionPlan;
    vm.getServiceFeaturesAsString = getServiceFeaturesAsString;
    vm.getSubscriptionPlanCategory = getSubscriptionPlanCategory;
    vm.getChargeMode = getChargeMode;
    vm.getPricingMode = getPricingMode;
    vm.refreshFields = refreshFields;
    vm.save = save;

    init();

    function init() {
      loadSubscriptionOptions();
      getSubscriptionPlansDropdown();
      vm.salesPersonLookup.lookup();
      vm.introducerLookup.lookup();
    }

    function getSubscriptionPlansDropdown() {
      subscriptionPlanSvc
        .getSubscriptionPlansDropdown({
          corporateId: vm.corporateId,
        })
        .success((data) => {
          vm.subscriptionPlans = data.items;
        });
    }

    function getSubscriptionPlan() {
      vm.loading += 1;
      if (vm.subscriptionPlanId) {
        subscriptionPlanSvc
          .getSubscriptionPlan({
            id: vm.subscriptionPlanId,
          })
          .success((data) => {
            vm.subscriptionPlan = data;
            vm.currencyCode = vm.subscriptionPlan.currencyCode;
            vm.categoryCharges = vm.subscriptionPlan.categoryCharges;

            // Disable prepaid for per ticket and per case basis.

            vm.disablePrepaid =
              vm.subscriptionPlan.pricingMode === 2 || vm.subscriptionPlan.pricingMode === 3;
          })
          .finally(() => {
            vm.loading -= 1;
          });
      } else {
        vm.subscriptionPlan = null;
        vm.currencyCode = '';
        vm.categoryCharges = [];
        vm.loading -= 1;
      }
    }

    function loadSubscriptionOptions() {
      vm.loading += 1;
      subscriptionSvc
        .getCreateSubscriptionOptions({
          corporateId: vm.corporateId,
        })
        .success((data) => {
          vm.corporate = {
            code: data.corporateCode,
            name: data.corporateName,
          };
          vm.subsidiaries = data.subsidiaries;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function validateForm() {
      const form = $scope.editForm;
      return App.isFormValid(form);
    }

    function save() {
      if (vm.loading) return;

      if (vm.subscription.effectiveStartDate > vm.subscription.effectiveEndDate) {
        abp.message.error(App.localize('SubscriptionEffectiveEndDateEarlierThanStartDate'));
        return;
      }

      if (!validateForm()) {
        abp.message.warn(App.localize('OneOrMoreInputValueIsInvalid'));
        return;
      }

      vm.subscriptions.length = 0;

      if (vm.subscription.salesPersonId) {
        vm.subscription.salesPersonName = vm.salesPersonLookup.activeResults.find(
          (x) => x.value === vm.subscription.salesPersonId
        ).name;
      }

      if (vm.subscription.introducerId) {
        vm.subscription.introducerName = vm.introducerLookup.activeResults.find(
          (x) => x.value === vm.subscription.introducerId
        ).name;
      }

      if (
        vm.subscription.subsidiaryIds.length > 0 &&
        vm.subscription.scope === 1 &&
        vm.subscription.billTo === 1
      ) {
        let index = 0;

        vm.subscription.subsidiaryIds.forEach((subsidiaryId) => {
          let subscription = JSON.parse(JSON.stringify(vm.subscription));

          subscription.pricingMode = vm.subscriptionPlan.pricingMode;
          subscription.isTargetAccount = vm.subscription.billTo === 0;
          subscription.subscriptionPlanId = vm.subscriptionPlanId;
          subscription.id = index + 1;
          subscription.name = `Subscription ${index + 1}`;
          subscription.subsidiaryNames = [
            vm.subsidiaries.find((x) => x.value === subsidiaryId).name,
          ];
          subscription.subsidiaryIds = [subsidiaryId];

          // Taking subsidiaryId for now until finance billing account is fully implemented.

          subscription.billingAccountId = subsidiaryId;
          subscription.billingAccountName = vm.subsidiaries.find(
            (x) => x.value === subsidiaryId
          ).name;

          vm.subscriptions.push(subscription);
          index += 1;
          subscription = {};
        });
      } else {
        const subscription = JSON.parse(JSON.stringify(vm.subscription));

        subscription.pricingMode = vm.subscriptionPlan.pricingMode;
        subscription.isTargetAccount = vm.subscription.billTo === 0;
        subscription.subscriptionPlanId = vm.subscriptionPlanId;
        subscription.id = 1;
        subscription.name = `Subscription 1`;
        subscription.billingAccountId = vm.subscription.billingAccountId;
        subscription.billingAccountName = vm.subsidiaries.find(
          (x) => x.value === vm.subscription.billingAccountId
        ).name;

        if (vm.subscription.scope === 1 && vm.subscription.billTo === 0) {
          vm.subscription.subsidiaryIds.forEach((subsidiaryId) => {
            subscription.subsidiaryNames.push(
              vm.subsidiaries.find((x) => x.value === subsidiaryId).name
            );
          });
        }

        vm.subscriptions.push(subscription);
      }

      _.forEach(vm.categoryCharges, (item) => {
        item.subscriptionPlanTierId = item.latestRetailPrice.subscriptionPlanTierId;
        item.retailPrice = item.latestRetailPrice.chargeAmount;
      });

      showConfirmationModal();
    }

    function showConfirmationModal() {
      const modal = $uibModal.open({
        templateUrl: require('../confirmation/confirmation.modal.html'),
        controller: 'revenue.views.subscriptions.create.confirmationModal as vm',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          subscriptions() {
            return vm.subscriptions;
          },
          subscriptionPlan() {
            return vm.subscriptionPlan;
          },
          categoryCharges() {
            return vm.categoryCharges;
          },
          currencyCode() {
            return vm.currencyCode;
          },
        },
      });
      modal.result.then(() => {
        vm.loading += 1;
        subscriptionSvc
          .createSubscriptions({
            subscriptions: vm.subscriptions,
            categoryCharges: vm.categoryCharges,
            currencyCode: vm.currencyCode,
          })
          .success(() => {
            abp.notify.info(App.localize('SuccessfullySaved'));
            $state.go('revenue.corporates', {
              corporateId: vm.corporateId,
              section: 'newSubscriptions',
            });
          })
          .finally(() => {
            vm.loading -= 1;
          });
      });
    }

    function getServiceFeaturesAsString(serviceFeaturesArray) {
      const values = [];
      _.forEach(serviceFeaturesArray, (item) => {
        const serviceFeature = _.find(vm.serviceFeatures, (i) => i.id === item);
        values.push(serviceFeature.name);
      });
      return values.join(', ');
    }

    function getSubscriptionPlanCategory(planCategory) {
      if (planCategory === vm.enums.SubscriptionPlanCategory.Outpatient.id) {
        return App.localize('OutpatientServices');
      }
      if (planCategory === vm.enums.SubscriptionPlanCategory.Inpatient.id) {
        return App.localize('InpatientServices');
      }
      if (planCategory === vm.enums.SubscriptionPlanCategory.Adhoc.id) {
        return App.localize('Adhoc');
      }
      return '';
    }

    function getChargeMode(chargeMode) {
      if (chargeMode === vm.enums.subscriptionPlanChargeMode.Monthly.id) {
        return App.localize('Monthly');
      }
      if (chargeMode === vm.enums.subscriptionPlanChargeMode.Yearly.id) {
        return App.localize('Yearly');
      }
      return '';
    }

    function getPricingMode(pricingMode) {
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.Fixed.id) {
        return App.localize('Fixed');
      }
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.PerMember.id) {
        return App.localize('PerMember');
      }
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.PerTicketBasis.id) {
        return App.localize('PerTicketBasis');
      }
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.PerCaseBasis.id) {
        return App.localize('PerCaseBasis');
      }
      return '';
    }

    function refreshFields() {
      if (vm.subscription.scope === 0) {
        vm.subscription.subsidiaryIds = [];
        vm.subscription.billTo = null;
        vm.subscription.billingAccountId = null;
      }
    }
  }
})();
