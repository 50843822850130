(() => {
  angular.module('app').factory('appSession', AppSessionService);

  AppSessionService.$inject = [];

  function AppSessionService() {
    const service = {
      application: null,
      user: null,
      tenant: null,
    };

    init();

    return service;

    function init() {
      // Start this initialisation only when the current user is logged in.

      getCurrentLoginInformations();
    }

    function getCurrentLoginInformations() {
      // Waiting for the function call callback before setting up app session factory,
      // thus we will have to disable the async calling.

      abp
        .ajax({
          url: `${abp.appPath}api/services/app/Session/GetCurrentLoginInformations`,
          type: 'POST',
          async: false,
        })
        .done((result) => {
          service.application = result.application;
          service.user = result.user;
          service.tenant = result.tenant;
        });
    }
  }
})();
