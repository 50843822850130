(() => {
  angular.module('app').controller('common.views.auditLogs.index', AuditLogPageController);

  AuditLogPageController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$uibModal',
    'abp.services.app.auditLog',
    'moment',
    'appConstants',
  ];

  function AuditLogPageController(
    $scope,
    $state,
    $stateParams,
    $uibModal,
    auditLogSvc,
    moment,
    appConstants
  ) {
    const vm = this;
    vm.exporting = 0;
    vm.loading = 0;
    vm.serviceNames = [];
    vm.requestParams = {
      userName: $stateParams.userName || null,
      serviceName: $stateParams.serviceName || null,
      minExecutionDuration: parseFloat($stateParams.minExecutionDuration) || null,
      maxExecutionDuration: parseFloat($stateParams.maxExecutionDuration) || null,
      methodName: $stateParams.methodName || null,
      hasException: $stateParams.hasException || null,
      browserInfo: $stateParams.browserInfo || null,
      skipCount: $stateParams.skipCount || 0,
      maxResultCount: $stateParams.maxResultCount || appConstants.grid.defaultPageSize,
    };
    vm.advancedFiltersAreShown =
      vm.requestParams.minExecutionDuration !== null ||
      vm.requestParams.maxExecutionDuration !== null ||
      vm.requestParams.methodName ||
      vm.requestParams.browserInfo ||
      vm.requestParams.hasException !== null;

    vm.dateRangeOptions = angular.extend({}, App.createDateRangePickerOptions(), {
      timePicker: true,
    });
    vm.dateRangeOptions.locale.format = 'L LT';
    vm.dateRangeModel = {
      startDate: moment().startOf('day'),
      endDate: moment().endOf('day'),
    };
    if ($stateParams.startDate)
      vm.dateRangeModel.startDate = moment(decodeURIComponent($stateParams.startDate));
    if ($stateParams.endDate)
      vm.dateRangeModel.endDate = moment(decodeURIComponent($stateParams.endDate));

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSizes,
      paginationPageSize: appConstants.grid.defaultPageSize,
      useExternalPagination: true,
      columnDefs: [
        {
          name: 'Actions',
          enableSorting: false,
          width: 75,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
        },
        {
          field: 'exception',
          enableSorting: false,
          width: 30,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'exceptionTemplate',
        },
        {
          enableSorting: false,
          displayName: App.localize('Time'),
          field: 'executionTime',
          cellFilter: "momentFormat: 'L LTS'",
          minWidth: 150,
        },
        {
          enableSorting: false,
          displayName: App.localize('UserName'),
          field: 'userName',
          minWidth: 100,
        },
        {
          enableSorting: false,
          displayName: App.localize('Service'),
          field: 'serviceName',
          minWidth: 100,
        },
        {
          enableSorting: false,
          displayName: App.localize('Action'),
          field: 'methodName',
          minWidth: 100,
        },
        {
          enableSorting: false,
          displayName: App.localize('ExecutionDuration'),
          field: 'executionDuration',
          minWidth: 70,
          cellTemplate: `<div class="ui-grid-cell-contents">${App.localize(
            'Xms',
            '{{COL_FIELD CUSTOM_FILTERS}}'
          )}</div>`,
        },
        {
          enableSorting: false,
          displayName: App.localize('IpAddress'),
          field: 'clientIpAddress',
          minWidth: 100,
        },
        {
          enableSorting: false,
          displayName: App.localize('ClientTerminal'),
          field: 'clientName',
          minWidth: 100,
        },
        {
          enableSorting: false,
          displayName: App.localize('Browser'),
          field: 'browserInfo',
          minWidth: 100,
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, vm.getAuditLogs)
        );
      },
      data: [],
    };

    vm.getAuditLogs = getAuditLogs;
    vm.exportToExcel = exportToExcel;
    vm.showDetails = showDetails;

    init();

    function init() {
      auditLogSvc
        .getIndexFilterOptions(
          {},
          {
            abpHandleError: false,
          }
        )
        .success((data) => {
          vm.userNames = data.userNames;
          vm.serviceNames = data.serviceNames;
        });

      getAuditLogs();
    }

    function getAuditLogs() {
      registerStateParams();
      vm.loading += 1;

      auditLogSvc
        .getAuditLogs($.extend({}, vm.requestParams, vm.dateRangeModel))
        .success((data) => {
          vm.gridOptions.totalItems = data.totalCount;
          vm.gridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function registerStateParams() {
      let startDate = null;
      let endDate = null;
      if (vm.dateRangeModel.startDate) startDate = vm.dateRangeModel.startDate.format();
      if (vm.dateRangeModel.endDate) endDate = vm.dateRangeModel.endDate.format();

      $state.transitionTo(
        $state.current,
        {
          userName: vm.requestParams.userName,
          serviceName: vm.requestParams.serviceName,
          minExecutionDuration: vm.requestParams.minExecutionDuration,
          maxExecutionDuration: vm.requestParams.maxExecutionDuration,
          methodName: vm.requestParams.methodName,
          hasException: vm.requestParams.hasException,
          browserInfo: vm.requestParams.browserInfo,
          startDate: encodeURIComponent(startDate),
          endDate: encodeURIComponent(endDate),
          skipCount: vm.requestParams.skipCount,
          maxResultCount: vm.requestParams.maxResultCount,
        },
        {
          notify: false,
        }
      );
    }

    function exportToExcel() {
      const option = $.extend({}, vm.requestParams, vm.dateRangeModel);
      option.maxResultCount = appConstants.maxResultCount;
      vm.exporting += 1;
      auditLogSvc
        .getAuditLogsToExcel(option)
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.exporting -= 1;
        });
    }

    function showDetails(auditLog) {
      $uibModal.open({
        templateUrl: require('./detailModal.html'),
        controller: 'common.views.auditLogs.detailModal as vm',
        backdrop: 'static',
        resolve: {
          auditLog: () => auditLog,
        },
      });
    }
  }
})();
