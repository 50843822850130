(() => {
  angular.module('app').component('hostInpatientGuaranteeLettersCommonActionButtonGroupComponent', {
    templateUrl: require('./actionButtonGroup.component.html'),
    controller: ActionButtonGroupController,
    controllerAs: 'vm',
    bindings: {
      onApprove: '&',
      onReject: '&',
      onQuery: '&',
      onDelegate: '&',
      onRecommend: '&',
      onPreview: '&',
      onSaveDraft: '&',
      isOnHold: '<',
      onRejectGl: '&',
      hasUnresolvedQuery: '<',
      onEscalate: '&',
    },
  });

  ActionButtonGroupController.$inject = ['$attrs'];

  function ActionButtonGroupController($attrs) {
    const vm = this;
    vm.attrs = $attrs;
    vm.hasInpatientClaimProcessingEnhancement = abp.setting.getBoolean(
      'Hms.Feature.InpatientClaimProcessingEnhancement'
    );

    vm.$onInit = init;

    function init() {}
  }
})();
