(() => {
  angular.module('app').filter('modifierType', modifierType);

  modifierType.$inject = [];

  function modifierType() {
    return modifierTypeFilter;

    function modifierTypeFilter(value, showCategoryOnly) {
      if (showCategoryOnly) {
        switch (value) {
          case 0:
          case 2:
            return App.localize('Budget');
          case 1:
          case 3:
            return App.localize('Visit');
          default:
            return value;
        }
      }

      switch (value) {
        case 0:
          return App.localize('BudgetLimit');
        case 1:
          return App.localize('VisitLimit');
        case 2:
          return App.localize('BudgetOverdraft');
        case 3:
          return App.localize('VisitOverdraft');
        default:
          return value;
      }
    }
  }
})();
