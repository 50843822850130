import swal from 'sweetalert';

(() => {
  angular.module('app').component('commonGuaranteeLettersDetailsLetterDetailWidget', {
    templateUrl: require('./widget.html'),
    controller: LetterDetailWidgetController,
    controllerAs: 'vm',
    bindings: {
      memberId: '=',
    },
  });

  LetterDetailWidgetController.$inject = [
    '$state',
    '$stateParams',
    '$uibModal',
    'abp.services.app.guaranteeLetter',
    'Hms.Employees.EmployeeState',
    'Hms.GuaranteeLetters.GuaranteeLetterStatus',
    'Hms.GuaranteeLetters.GuaranteeLetterSource',
    'moment',
  ];

  function LetterDetailWidgetController(
    $state,
    $stateParams,
    $modal,
    guaranteeLetterSvc,
    enumEmployeeState,
    enumGuaranteeLetterStatus,
    enumsGuaranteeLetterSource,
    moment
  ) {
    const vm = this;

    const letterStatus = {
      pendingApproval: 0,
      approved: 1,
      utilized: 2,
      rejected: 3,
      expired: 4,
      cancelled: 5,
    };

    vm.loading = 0;
    vm.isHost = App.isHost();
    vm.isCorporate = App.isCorporate();
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.daysMessage = '';
    vm.isFutureDate = false;
    vm.letterNumber = $stateParams.letterNumber;

    vm.enums = {
      employeeState: enumEmployeeState,
      guaranteeLetterStatus: enumGuaranteeLetterStatus,
      guaranteeLetterSource: enumsGuaranteeLetterSource,
    };

    vm.permissions = {
      approve: vm.isHost
        ? abp.auth.isGranted('GuaranteeLetters.Host.Approve')
        : abp.auth.isGranted('GuaranteeLetters.Corporate.Approve'),
      reject: vm.isHost
        ? abp.auth.isGranted('GuaranteeLetters.Host.Reject')
        : abp.auth.isGranted('GuaranteeLetters.Corporate.Reject'),
      update: abp.auth.isGranted('GuaranteeLetters.Host.Update'),
      withdraw: vm.isHost
        ? abp.auth.isGranted('GuaranteeLetters.Host.Withdraw')
        : abp.auth.isGranted('GuaranteeLetters.Corporate.Withdraw'),
      topUp: abp.auth.isGranted('GuaranteeLetters.Host.TopUp'),
      extend: abp.auth.isGranted('GuaranteeLetters.Host.Extend'),
    };

    vm.approveChecklistItems = [
      {
        text: 'Sufficient corporate limit',
      },
      {
        text: "Sufficient employee's limit",
      },
      {
        text: 'Discipline match/accessible treatment correct',
      },
    ];

    vm.approveOrReject = approveOrReject;
    vm.disableApprove = disableApprove;
    vm.editGuaranteedAmount = editGuaranteedAmount;

    vm.extend = extend;
    vm.getGuaranteeLetterSourceLocalizedString = getGuaranteeLetterSourceLocalizedString;
    vm.appointmentValidity = appointmentValidity;
    vm.referralValidity = referralValidity;
    vm.topUp = topUp;
    vm.withdraw = confirmWithdraw;
    vm.edit = edit;
    vm.uploadAttachments = uploadAttachments;
    vm.getDateTimeString = getDateTimeString;

    init();

    function init() {
      getLetterDetail(true);
    }

    function getLetterDetail(isInit) {
      vm.loading += 1;
      guaranteeLetterSvc
        .getLetterDetail({
          id: vm.letterNumber,
        })
        .success((data) => {
          const d = data;
          d.disciplineNames = _.join(
            _.map(d.disciplines, (discipline) => discipline.name),
            ', '
          );
          vm.letter = d;
          vm.letter.ianaTimeZoneId = $stateParams.timeZoneId;

          vm.canApprove = vm.permissions.approve && d && d.status === letterStatus.pendingApproval;
          vm.canReject = vm.permissions.reject && d && d.status === letterStatus.pendingApproval;
          if (vm.permissions.withdraw && d) {
            if (vm.isHost) {
              vm.canWithdraw = d.status === letterStatus.approved && !d.hasCheckedIn;
            } else {
              vm.canWithdraw =
                !d.hasCheckedIn &&
                (d.status === letterStatus.approved ||
                  (d.status === letterStatus.pendingApproval && d.source === 1));
            }
          } else {
            vm.canWithdraw = false;
          }

          vm.canExtend =
            vm.permissions.extend &&
            vm.letter.hasCheckedIn &&
            vm.letter.approvalTime &&
            (vm.letter.status === 4 || vm.letter.status === 6);

          if (vm.letter.requireReferral && isInit) {
            if (vm.letter.referralValidityDays !== 0) {
              vm.approveChecklistItems.push({
                text: 'Valid referral letter/appointment card/vaccination schedule/prescription list (dated not more than validity days limit)',
              });
            }
          }
          vm.letter.canEdit =
            vm.permissions.update &&
            (vm.letter.status === 0 || vm.letter.status === 1 || vm.letter.status === 2) &&
            !vm.letter.isFinalized;
          vm.letter.canTopUp =
            vm.permissions.topUp &&
            (vm.letter.status === 1 || vm.letter.status === 2) &&
            vm.letter.hasCheckedIn &&
            !vm.letter.isFinalized;

          // For host claim policy component use.
          vm.memberId = vm.letter.patient.hasClaimPolicy ? vm.letter.patient.id : null;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function edit() {
      $modal
        .open({
          templateUrl: require('./updateModal.html'),
          controller: 'common.views.guaranteeLetters.details.letterDetail.updateModal as vm',
          backdrop: 'static',
          resolve: {
            letter() {
              return $.extend(vm.letter, {
                letterNumber: vm.letterNumber,
              });
            },
          },
        })
        .result.then(() => {
          showSuccessModal(false);
        });
    }

    function confirmWithdraw() {
      $modal
        .open({
          templateUrl: require('./withdrawModal.html'),
          controller: 'common.views.guaranteeLetters.details.letterDetail.withdrawModal as vm',
          backdrop: 'static',
          resolve: {
            letterNumber() {
              return vm.letterNumber;
            },
          },
        })
        .result.then((remarks) => {
          withdraw(remarks);
        });
    }

    function withdraw(remarks) {
      vm.loading += 1;
      guaranteeLetterSvc
        .withdrawGuaranteeLetter({
          id: vm.letter.id,
          remarks,
        })
        .success(() => {
          abp.message.success(App.localize('SuccessfullyWithdrew'));
          getLetterDetail(false);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function topUp() {
      $modal
        .open({
          templateUrl: require('./topUpModal.html'),
          controller: 'common.views.guaranteeLetters.details.letterDetail.topUpModal as vm',
          backdrop: 'static',
          resolve: {
            letterNumber() {
              return vm.letterNumber;
            },
          },
        })
        .result.then((result) => {
          if (result) getLetterDetail(false);
        });
    }

    /* Approval Functions */

    function referralValidity() {
      if (vm.letter.referralValidityDays !== 0 && vm.referralDate) {
        const daysValidity = moment().startOf('day').diff(moment(vm.referralDate), 'days');
        vm.daysMessage = daysValidity >= 0 ? App.localize('XDaysAgo', daysValidity) : '';
        vm.isFutureDate = !!(daysValidity < 0);
        return daysValidity <= vm.letter.referralValidityDays && daysValidity >= 0;
      }

      return false;
    }

    function appointmentValidity() {
      if (vm.letter.appointmentCardValidityDays !== 0 && vm.appointmentDate) {
        const daysAppointmentValidity = moment()
          .startOf('day')
          .diff(moment(vm.appointmentDate), 'days');
        vm.daysMessageAppointment =
          daysAppointmentValidity >= 0 ? App.localize('XDaysAgo', daysAppointmentValidity) : '';
        vm.isAppointmentFutureDate = !!(daysAppointmentValidity < 0);
        return (
          daysAppointmentValidity <= vm.letter.appointmentCardValidityDays &&
          daysAppointmentValidity >= 0
        );
      }

      return false;
    }
    function approveOrReject(isReject) {
      if (isReject) showRejectModal();
      else toggleApprove();
    }

    /* Approve Functions */

    function toggleApprove() {
      if (!vm.letter.isTreatmentDisciplinesCovered) {
        abp.message.error(
          App.localize('UncoveredDisciplineWarningMessage'),
          App.localize('UncoveredDisciplineWarningTitle')
        );
        return;
      }

      if (vm.isHost) approve();
      else showApproveModal();
    }

    /* Approve Checklist */

    function disableApprove() {
      return !vm.canApprove || (vm.isHost && _.some(vm.approveChecklistItems, (d) => !d.isChecked));
    }

    /* End of Approve Checklist */

    function showApproveModal() {
      $modal
        .open({
          templateUrl: require('./approveModal.html'),
          controller: 'common.views.guaranteeLetters.details.letterDetail.approveModal as vm',
          backdrop: 'static',
          resolve: {
            letter() {
              return $.extend(vm.letter, {
                letterNumber: vm.letterNumber,
              });
            },
          },
        })
        .result.then((signatureProfileId) => {
          approve(signatureProfileId);
        });
    }

    function editGuaranteedAmount() {
      $modal
        .open({
          templateUrl: require('./editGuaranteedAmountModal.html'),
          controller:
            'common.views.guaranteeLetters.details.letterDetail.editGuaranteedAmountModal as vm',
          backdrop: 'static',
          resolve: {
            letterNumber() {
              return vm.letterNumber;
            },
            guaranteedAmount() {
              return vm.letter.guaranteedAmount;
            },
          },
        })
        .result.then((result) => {
          if (result) getLetterDetail(false);
        });
    }

    function extend() {
      swal(
        {
          title: App.localize('ExtendForClaimSubmission'),
          text: App.localize('ExtendForClaimSubmissionConfirmation', vm.letterNumber),
          type: 'input',
          showCancelButton: true,
          closeOnConfirm: false,
          confirmButtonColor: '#1ab394',
          inputPlaceholder: App.localize('PleaseExplain'),
        },
        (inputValue) => {
          if (inputValue === false) return false;
          if ($.trim(inputValue || '') === '') {
            swal.showInputError(App.localize('InvalidInput'));
            return false;
          }

          vm.loading += 1;
          guaranteeLetterSvc
            .extendExpiredGuaranteeLetter({
              id: vm.letter.id,
              remarks: $.trim(inputValue),
            })
            .success(() => {
              swal.close();
              abp.notify.success(App.localize('SuccessfullyExtended'));
              getLetterDetail(false);
            })
            .finally(() => {
              vm.loading -= 1;
            });

          return true;
        }
      );
    }

    function approve(signatureProfileId) {
      abp.message.confirm(
        App.localize('ApproveXConfirmMessage', vm.letterNumber),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            vm.loading += 1;
            guaranteeLetterSvc
              .approveGuaranteeLetter({
                id: vm.letter.id,
                signatureProfileId,
                remarks: vm.approveRemarks,
              })
              .success((data) => {
                vm.letter.guaranteedAmount = data.guaranteedAmount;
                abp.notify.success(App.localize('SuccessfullyApproved'));
                if (vm.isHost) showSuccessModal(true);
                else $state.go('guaranteeLetters');
              })
              .finally(() => {
                vm.loading -= 1;
              });
          }
        }
      );
    }

    /* End of approve functions */

    /* Reject Functions */

    function showRejectModal() {
      $modal
        .open({
          templateUrl: require('./rejectModal.html'),
          controller: 'common.views.guaranteeLetters.details.letterDetail.rejectModal as vm',
          backdrop: 'static',
        })
        .result.then((input) => {
          abp.message.confirm(
            App.localize('RejectXConfirmMessage', vm.letterNumber),
            App.localize('AreYouSure'),
            (d) => {
              if (d) {
                reject(input);
              }
            }
          );
        });
    }

    function reject(input) {
      const i = input;
      i.id = vm.letter.id;

      vm.loading += 1;
      guaranteeLetterSvc
        .rejectGuaranteeLetter(i)
        .success(() => {
          getLetterDetail(false);
          abp.notify.success(App.localize('SuccessfullyRejected'));
        })
        .finally(() => {
          $state.reload();
          vm.loading -= 1;
        });
    }

    /* End of Reject Functions */

    /* End of Approval Functions */

    /* Functions */

    function showSuccessModal(navigateNextOnSuccess) {
      $modal
        .open({
          templateUrl: require('./successModal.html'),
          controller: 'common.views.guaranteeLetters.details.letterDetail.successModal as vm',
          backdrop: 'static',
          keyboard: false,
          resolve: {
            letter() {
              return $.extend(vm.letter, {
                letterNumber: vm.letterNumber,
              });
            },
          },
        })
        .result.then(() => {
          if (navigateNextOnSuccess) {
            navigateNext();
          } else {
            getLetterDetail(false);
          }
        });
    }

    function navigateNext() {
      guaranteeLetterSvc
        .getNextGuaranteeLetter({
          id: vm.letterNumber,
          type: 2,
        })
        .success((data) => {
          if (data) {
            $state.go('guaranteeLetterDetail', {
              letterNumber: data.letterNumber,
            });
          } else {
            $state.go('guaranteeLetters');
          }
        });
    }

    function uploadAttachments() {
      $modal
        .open({
          templateUrl: require('./uploadAttachments.modal.html'),
          controller:
            'common.views.guaranteeLetters.details.letterDetail.uploadAttachments.modal as vm',
          backdrop: 'static',
          resolve: {
            letterNumber() {
              return $stateParams.letterNumber;
            },
          },
        })
        .result.then(() => {
          getLetterDetail(false);
        });
    }
    /* End of functions */

    function getGuaranteeLetterSourceLocalizedString(guaranteeLetterSource) {
      const enumMap = vm.enums.guaranteeLetterSource.values.find(
        (x) => x.id === guaranteeLetterSource
      );
      if (!enumMap) {
        return '';
      }
      return enumMap.name;
    }

    function getDateTimeString(date, timeZoneId) {
      return App.getDateTimeString(date, timeZoneId);
    }
  }
})();
