(() => {
  angular
    .module('app')
    .controller('common.views.saleTransactions.applyCreditModal', ApplyCreditModalController);

  ApplyCreditModalController.$inject = ['invoiceNo', '$uibModalInstance'];

  function ApplyCreditModalController(invoiceNo, $uibModalInstance) {
    const vm = this;

    vm.invoiceNo = invoiceNo;
    vm.invoiceBalance = Math.round(Math.random() * 5000 * 100) / 100;
    vm.currencyCode = 'MYR';
    vm.creditAmount = 0;
    vm.credits = [];

    vm.cancel = cancel;
    vm.apply = apply;
    vm.updatePaymentAmount = updatePaymentAmount;

    init();

    function init() {
      getCredits();
    }

    function getCredits() {
      // TODO: To be updated when API is ready.
      // Dummy data.

      for (let i = 0; i < 3; i += 1) {
        const testAmount = Math.round(Math.random() * 5000 * 100) / 100;
        vm.credits.push({
          type: App.localize('CreditMemo'),
          transactionNumber: 'CR-3131-1234566-MY',
          date: new Date(),
          amount: testAmount,
          balance: testAmount,
          appliedAmount: 0,
        });
      }

      for (let i = 0; i < 2; i += 1) {
        const testAmount = Math.round(Math.random() * 5000 * 100) / 100;
        vm.credits.push({
          type: App.localize('Payment'),
          transactionNumber: 'PY-3131-1234566-MY',
          date: new Date(),
          amount: testAmount,
          balance: testAmount,
          appliedAmount: 0,
        });
      }
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function apply() {
      // TODO: To be updated when API is ready.

      console.log('%cApply credit', 'color: lightblue;', 'Method call.');
    }

    function updatePaymentAmount() {
      vm.creditAmount = 0;
      vm.credits.forEach((credit) => {
        if (credit.appliedAmount) {
          vm.creditAmount += credit.appliedAmount;
        }
      });
    }
  }
})();
