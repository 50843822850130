import swal from 'sweetalert';

(() => {
  angular.module('app').controller('clinic.views.specialistQuery.query', SpecialistQueryController);

  SpecialistQueryController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'abp.services.app.serviceRequestQuery',
    'appConstants',
    'Hms.Services.ServiceType',
  ];

  function SpecialistQueryController(
    $scope,
    $state,
    $stateParams,
    serviceRequestQuerySvc,
    appConstants,
    enumServiceType
  ) {
    const vm = this;
    vm.loading = 0;
    vm.requestParams = {
      letterNumber: $stateParams.letterNumber,
      claimNumber: $stateParams.claimNumber,
      queryNumber: $stateParams.queryNumber,
      timeZoneId: $stateParams.timeZoneId,
      serviceType: $stateParams.serviceType,
    };
    vm.enums = {
      serviceType: enumServiceType,
    };
    vm.saving = 0;
    vm.checklistItems = [];

    vm.submit = submit;
    vm.getDateTimeString = getDateTimeString;

    // Attachment.

    const fileExtensions = `|${appConstants.fileUpload.imageExtensions
      .concat(appConstants.fileUpload.pdfExtensions)
      .join('|')}|`;

    vm.uploadConfig = {
      objectType: 'QueryAttachment',
      accept: 'image/*,application/pdf',
      filters: [
        {
          name: 'fileFilter',
          fn(n) {
            const t = `|${n.type.slice(n.type.lastIndexOf('/') + 1)}|`;
            if (fileExtensions.indexOf(t) === -1) {
              abp.message.warn(App.localize('File_Warn_FileType'));
              return false;
            }
            return true;
          },
        },
        {
          name: 'sizeFilter',
          fn(n) {
            const sizeLimit = 52428800;
            if (n.size > sizeLimit) {
              abp.message.warn(App.localize('File_Warn_XSizeLimit', sizeLimit / 1024 / 1024));
              return false;
            }
            return true;
          },
        },
      ],
    };

    // End attachment.

    init();

    // Functions

    function init() {
      getQuery();
    }

    function getQuery() {
      vm.loading += 1;
      serviceRequestQuerySvc
        .getQuery({
          id: vm.requestParams.queryNumber,
        })
        .success((data) => {
          vm.query = data;
          vm.query.newRepliedAttachments = [];
          AddChecklistItems(vm.query.serviceRequestQueryItems);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function AddChecklistItems(items) {
      // To check if there is medical query
      // Only add once for all medical queries

      if (_.some(items, (item) => item.type === 1)) {
        vm.checklistItems.push({
          text: App.localize('CompletedMedicalQueryForm'),
          required: true,
        });
      }

      // To add missing document names

      _.forEach(items, (item) => {
        if (item.type === 2) {
          _.forEach(item.questions, (question) => {
            if (question.documentName) {
              vm.checklistItems.push({
                text: question.documentName,
                required: true,
              });
            }
          });
        }
      });
    }

    function submit() {
      if (!validateForm()) {
        return;
      }

      swal(
        {
          title: App.localize('ConfirmReplyQuerySubmission'),
          text: App.localize('ConfirmQueryReplySubmissionNarrative'),
          type: 'warning',
          confirmButtonColor: '#23c6c8',
          confirmButtonText: App.localize('Yes'),
          cancelButtonText: App.localize('No'),
          showCancelButton: true,
          closeOnConfirm: false,
        },
        (isConfirm) => {
          if (isConfirm) {
            replyQuery();
          }
          swal.close();
        }
      );
    }

    function replyQuery() {
      vm.saving += 1;
      serviceRequestQuerySvc
        .replyQuery({
          id: vm.query.id,
          newRepliedAttachments: vm.query.newRepliedAttachments,
        })
        .success(() => {
          const message = [];
          message.push(App.localize('SpecialistReplyQuerySuccessNarrative1'));
          message.push(App.localize('SpecialistReplyQuerySuccessNarrative2'));

          swal(
            {
              title: App.localize('SuccessfullySubmitted'),
              text: message.join(' '),
              html: true,
              type: 'success',
              confirmButtonColor: '#23c6c8',
              confirmButtonText: App.localize('ViewQuery'),
              cancelButtonText: App.localize('ReturnToClaimList'),
              showCancelButton: true,
              closeOnConfirm: false,
            },
            // eslint-disable-next-line prefer-arrow-callback, func-names
            function (isConfirm) {
              swal.close();

              if (isConfirm) {
                if (vm.requestParams.serviceType === vm.enums.serviceType.Specialist.id) {
                  $state.go('viewSpecialistQuery', {
                    letterNumber: vm.guaranteeLetterNumber,
                    claimNumber: vm.requestParams.claimNumber,
                    queryNumber: vm.requestParams.queryNumber,
                    timeZoneId: vm.requestParams.timeZoneId,
                  });
                } else if (
                  vm.requestParams.serviceType === vm.enums.serviceType.PostHospitalization.id
                ) {
                  if (vm.requestParams.claimNumber) {
                    $state.go('viewPostHospitalizationQuery', {
                      claimNumber: vm.requestParams.claimNumber,
                      queryNumber: vm.requestParams.queryNumber,
                      timeZoneId: vm.requestParams.timeZoneId,
                    });
                  }
                }
              } else {
                $state.go('specialistClaims');
              }
            }
          );
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function validateForm() {
      vm.invalidAttachment = vm.query.newRepliedAttachments.length === 0;
      vm.invalidChecklist = !App.isFormValid($scope.forms.uploadDocuments);
      return !(vm.invalidAttachment || vm.invalidChecklist);
    }

    function getDateTimeString(date, timeZoneId) {
      return App.getDateTimeString(date, timeZoneId);
    }
  }
})();
