(function () {
    var componentId = 'hostOperationsDashboardExpiringCheckInsComponent';
    var app = angular.module('app');

    function controller($scope, $interval, operationsDashboardSvc, moment) {
        function init() {
            vm.refresh();

            var refreshTicketTimeInterval = $interval(getCheckInTimeRemaining, 1000);
            $scope.$on('$destroy', function () {
                if (angular.isDefined(refreshTicketTimeInterval)) {
                    $interval.cancel(refreshTicketTimeInterval);
                    refreshTicketTimeInterval = undefined;
                }
            });
        }

        var vm = this;
        vm.widgetName = componentId;
        vm.loading = 0;
        vm.countryCode = null;

        vm.$onInit = function () {
            vm.onAddWidget({ widget: this });
        };

        function getCheckInTimeRemaining() {
            _.each(vm.data, function (d) {
                var totalSeconds = moment(d.checkOutTime).diff(moment(), 's');
                var duration = moment.duration(totalSeconds, 's');
                if (totalSeconds <= 0) {
                    d.timeRemaining = App.localize('ExpiredCheckIn');
                    return;
                }
                var seconds = duration.seconds();
                var minutes = duration.minutes();
                var hours = duration.hours();
                var days = duration.days();
                var str = '';
                if (days > 0) str += days + 'd ';
                if (hours > 0) str += _.padStart('' + hours, 2, '0') + 'h ';
                str += _.padStart('' + minutes, 2, '0') + 'm ' + _.padStart('' + seconds, 2, '0') + 's';
                d.timeRemaining = str;
            });
        }

        function setTotalCount() {
            var item = _.find(vm.widgets, function (data) {
                return data.id === componentId;
            });
            item.totalCount = vm.totalCount;
            item.loading = false;
        }

        function getExpiringCheckIns(skipCount) {
            var input = {
                skipCount: skipCount,
                maxResultCount: app.consts.maxResultCount,
                countryCode: vm.countryCode
            };

            vm.loading++;
            operationsDashboardSvc.getExpiringCheckIns(input)
                .success(function (data) {
                    vm.totalCount = data.totalCount;
                    vm.data = vm.data.concat(data.items);
                    vm.toggleShowData();
                    setTotalCount();
                }).finally(function () {
                    vm.loading--;
                });
        }

        vm.refresh = function () {
            vm.data = [];
            vm.showAll = true;
            getExpiringCheckIns(0);
        };

        vm.toggleShowData = function () {
            vm.showAll = !vm.showAll;
            vm.records = vm.showAll ? vm.data : vm.data.slice(0, 5);
        };

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/operationsDashboard/expiringCheckIns/expiringCheckIns.component.html'),
        controller: ['$scope', '$interval', 'abp.services.app.operationsDashboard', 'moment', controller],
        controllerAs: 'vm',
        bindings: {
            widgets: '<',
            onAddWidget: '&',
            countryCode: '=?'
        }
    });
})();
