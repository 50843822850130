(function () {
    var controllerId = 'host.views.mimsClass.createOrEditModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$uibModalInstance', 'abp.services.app.mimsClass', 'mimsClassId',
        function ($uibModalInstance, mimsClassSvc, mimsClassId) {
            function init() {
                vm.loading++;
                mimsClassSvc.getMimsClassForEdit({
                    id: mimsClassId
                }).success(function (data) {
                    vm.mimsClass = data;
                }).finally(function myfunction() {
                    vm.loading--;
                })
            }

            var vm = this;
            vm.loading = 0;
            vm.saving = 0;
            vm.mimsClass = null;

            vm.save = function () {
                vm.saving++;
                mimsClassSvc.createOrUpdateMimsClass(vm.mimsClass).success(function (data) {
                    abp.notify.info(App.localize("SuccessfullySaved"));
                    $uibModalInstance.close(data);
                }).finally(function () {
                    vm.saving--;
                }); 
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            init();
        }
    ]);
})();
