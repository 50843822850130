(function () {
    'use strict';

    angular.module('app').component('hostCorporatesSupportPlansCreateEditScheduleOfBenefitDoctorChargesComponent', {
        templateUrl: require('/App/host/views/corporates/supportPlans/createEdit/scheduleOfBenefit/doctorCharges/doctorCharges.component.html'),
        controller: ['$scope', controller],
        controllerAs: 'vm',
        bindings: {
            scheduleOfBenefit: '<',
            currencyCode: '<'
        }
    });

    function controller($scope) {
        var vm = this;
        vm.inHospitalText = App.localize('InHospitalDoctorVisit');
        vm.dailyText = App.localize('PerDay');
        vm.yearlyText = App.localize('PerYear');
        vm.limitedText = App.localize('Limited');
        vm.asCharged13thSchedule = App.localize('AsCharged13thSchedule');
    }
})();
