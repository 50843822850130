(function () {
    var controllerId = 'host.views.treatments.medicalFeeSchedules.UpdateMcsCodeResultModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$uibModalInstance', 'errors',
        function ($uibModalInstance, errors) {

            var vm = this;
            vm.errorCountText = App.localize("UpdateMcsCodeNotFoundNarrative", errors.length);
            errors.shift();
            vm.errorText = errors.join("\n")

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            }
        }
    ]);
})();
