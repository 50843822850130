(() => {
  angular.module('app').component('commonClaimsDetailsClaimSummaryAdmissionRecordWidget', {
    templateUrl: require('./widget.html'),
    controller: AdmissionRecordWidgetController,
    controllerAs: 'vm',
    bindings: {
      claim: '<'
    },
  });

  AdmissionRecordWidgetController.$inject = [];

  function AdmissionRecordWidgetController() {
    const vm = this;
    vm.loading = 0;
    vm.saving = false;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.isHost = App.isHost();
  }
})();
