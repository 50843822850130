(function () {
    var controllerId = 'host.views.employees.viewHistory';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$stateParams', 'abp.services.app.hostEmployee', 'moment',
        function ($stateParams, hostEmployeeSvc, moment) {
            function init() {
                vm.loading++;
                hostEmployeeSvc.getEmployeeHistory({
                    id: $stateParams.id
                }).success(function (data) {

                    _.each(data.items, function (historyItem, i) {
                        historyItem.snapshotTime = moment(historyItem.snapshotTime);

                        var isLastItem = i === data.items.length - 1;
                        var origJson = isLastItem ? '' : data.items[i + 1].snapshotItemJson;
                        historyItem.showDiff = historyItem.snapshotItemJson !== origJson;

                        if (historyItem.showDiff) {
                            data.items[i].codeMirrorOptions = $.extend({}, codeMirrorOptions, { orig: origJson });
                        }
                    });

                    vm.historyItems = data.items;
                }).finally(function () {
                    vm.loading--;
                });
            }

            var vm = this;
            vm.loading = 0;
            vm.employeeName = $stateParams.name;

            var codeMirrorOptions = {
                lineNumbers: false,
                readOnly: true,
                mode: { name: "javascript", json: true },
                highlightDifferences: true,
                connect: true,
                collapseIdentical: false,
                revertButtons: false,
                autoRefresh: true
            };

            init();
        }
    ]);
})();
