(function () {
    var controllerId = 'host.views.employees.getGLHistoryModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'abp.services.app.hostEmployee', 'employeeId', 'Hms.GuaranteeLetters.GuaranteeLetterStatus',
        function ($scope, $uibModalInstance, hostEmployeeSvc, employeeId, enumGuaranteeLetterStatus) {
            function init() {
                getGuaranteeLetters(0);
                getEmployeeBenefitInfo();
            }
            var vm = this;
            vm.loading = 0;
            vm.letters = [];
            vm.glRequirements = {};

            vm.enums = {};
            vm.enums.guaranteeLetterStatus = enumGuaranteeLetterStatus;

            function getGuaranteeLetters(skipCount) {
                vm.loading++;
                hostEmployeeSvc.getGuaranteeLetters({
                    filter: employeeId,
                    skipCount: skipCount,
                    maxResultCount: 10,
                }).success(function (data) {
                    vm.totalCount = data.totalCount;
                    vm.letters = vm.letters.concat(data.items);
                    _.each(vm.letters, function (letter) {
                        letter.disciplineNames = _.join(_.map(letter.disciplines, function (d) { return d.name; }), ', ')
                    });
                    vm.hasMoreLetters = data.totalCount > vm.letters.length;
                }).finally(function () {
                    vm.loading--;
                });
            }

            vm.loadMore = function () {
                getGuaranteeLetters(vm.letters.length);
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            function getEmployeeBenefitInfo() {
                vm.loading++;
                hostEmployeeSvc.getEmployeeBenefitInfo({
                    id: employeeId
                }).success(function (data) {
                    vm.glRequirements = data;
                }).finally(function () {
                    vm.loading--;
                });
            }

            init();
        }
    ]);
})();
