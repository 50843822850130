(() => {
  angular.module('app').component('commonInpatientNprClaimsDetailsInsuranceDetailsComponent', {
    templateUrl: require('./insuranceDetails.component.html'),
    controller: InsuranceDetailsController,
    controllerAs: 'vm',
    bindings: {},
  });

  InsuranceDetailsController.$inject = [
    '$stateParams',
    'abp.services.app.nprClaim',
    'abp.services.app.masterPolicy',
  ];

  function InsuranceDetailsController($stateParams, nprClaimSvc, masterPolicySvc) {
    const vm = this;

    vm.claimNumber = $stateParams.claimNumber;
    vm.isMaternity = false;
    vm.employeeId = null;

    vm.getMasterPolicy = getMasterPolicy;

    init();

    function init() {
      getClaim();
    }

    function getClaim() {
      vm.loading += 1;
      nprClaimSvc
        .getClaimDetail({
          id: $stateParams.claimNumber,
        })
        .success((data) => {
          vm.claim = data;
          vm.employeeId = data.employeeId;
          vm.claimType = vm.claim.claimType;
          if (
            vm.claimType === 12 ||
            vm.claimType === 13 ||
            vm.claimType === 14 ||
            vm.claimType === 15
          ) {
            vm.isMaternity = true;
          }
          vm.getMasterPolicy();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getMasterPolicy() {
      vm.loading += 1;
      masterPolicySvc
        .getMasterPolicyDetailByPatientId({
          id: vm.employeeId,
        })
        .success((data) => {
          if (!data) return;

          vm.data = data.masterPolicy;
          if (vm.data.jacketPolicyAttachments.isImage) {
            vm.isPdf = false;
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
