(() => {
  angular.module('app').factory('lookupModal', LookupModalFactory);

  LookupModalFactory.$inject = ['$uibModal'];

  function LookupModalFactory($uibModal) {
    const service = {
      open,
    };

    return service;

    function open(options) {
      $uibModal.open({
        templateUrl: require('../views/common/lookupModal.html'),
        controller: 'common.views.common.lookupModal as vm',
        backdrop: 'static',
        resolve: {
          lookupOptions() {
            return options;
          },
        },
      });
    }
  }
})();
