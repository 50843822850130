(() => {
  angular
    .module('app')
    .controller('common.views.patientTickets.createEditSuccessModal', CreateEditSuccessModal);

  CreateEditSuccessModal.$inject = [
    '$uibModalInstance',
    'ticket',
    'Hms.PatientTickets.PatientTicketType',
  ];

  function CreateEditSuccessModal($uibModalInstance, ticket, enumPatientTicketType) {
    const vm = this;

    vm.ticket = ticket;

    vm.isHospitalTicket = vm.ticket.clinicType === 7;
    vm.isMaternityTicket = vm.ticket.clinicType === 8;
    vm.isCtTicket = vm.ticket.clinicType >= 200;
    vm.hasMemberBenefitUtilizationEnabled = abp.setting.getBoolean(
      'Hms.Feature.MemberBenefitUtilization'
    );
    vm.isSgPreEmploymentTicket = vm.ticket.countryCode === 'SG' && vm.ticket.ticketType === 3;

    vm.getTitle = getTitle;
    vm.showDetails = showDetails;
    vm.showEditUtilization = showEditUtilization;
    vm.close = close;
    vm.isHost = App.isHost();

    vm.enums = {
      patientTicketType: enumPatientTicketType,
    };

    init();

    function init() {}

    function getTitle() {
      return App.localize('ClaimXIssued', vm.ticket.ticketNumber);
    }

    function showDetails() {
      if (!vm.isCtTicket) {
        if (vm.isSgPreEmploymentTicket) {
          ticket.callback({
            stateName: 'patientTicketSgDetail',
            stateData: {
              ticketNumber: vm.ticket.ticketNumber,
            },
          });
        } else {
          ticket.callback({
            stateName: 'patientTicketDetail',
            stateData: {
              ticketNumber: vm.ticket.ticketNumber,
            },
          });
        }
      } else if (vm.isHospitalTicket || vm.isMaternityTicket) {
        ticket.callback({
          stateName: 'inpatientTicketDetail',
          stateData: {
            ticketNumber: vm.ticket.ticketNumber,
          },
        });
      } else {
        ticket.callback({
          stateName: 'ctTicketDetail',
          stateData: {
            ticketNumber: vm.ticket.ticketNumber,
          },
        });
      }

      $uibModalInstance.close();
    }

    function showEditUtilization() {
      ticket.callback({
        stateName: 'ticketEditMemberUtilization',
        stateData: {
          ticketNumber: vm.ticket.ticketNumber,
        },
      });
      $uibModalInstance.close();
    }

    function close() {
      $uibModalInstance.close();
    }
  }
})();
