(() => {
  angular.module('app').controller('host.views.banks.createOrEditModal', BankEditModalController);

  BankEditModalController.$inject = [
    '$uibModalInstance',
    'abp.services.app.bank',
    'bankId',
    'abp.services.app.commonLookup',
    'Hms.Banks.Bank',
  ];

  function BankEditModalController(
    $uibModalInstance,
    bankSvc,
    bankId,
    commonLookupSvc,
    constsBank
  ) {
    const vm = this;

    vm.constsBank = constsBank;
    vm.loading = 0;
    vm.saving = 0;
    vm.bank = null;
    vm.isEdit = bankId;
    vm.countries = [];
    vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');
    vm.hasIndonesiaAndThailandRegionalExpansion = abp.setting.getBoolean(
      'Hms.Feature.IndonesiaAndThailandRegionalExpansion'
    );
    vm.accountNumberLengths = [];
    vm.save = save;
    vm.cancel = cancel;

    init();

    function init() {
      if (vm.hasRegionalSystemSupport) getEligibleCountries();

      vm.loading += 1;
      bankSvc
        .getBankForEdit({
          id: bankId,
        })
        .success((data) => {
          vm.bank = data;
          vm.accountNumberLengths = data.accountNumberLengths?.map((x) => ({ text: x.toString() }));
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getEligibleCountries() {
      vm.loading += 1;
      commonLookupSvc
        .getEligibleCountries()
        .success((data) => {
          vm.countries = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function save() {
      vm.bank.accountNumberLengths = vm.accountNumberLengths?.map((x) => Number(x.text));
      if (vm.bank.id) {
        vm.saving += 1;
        bankSvc
          .createOrUpdateBank(vm.bank)
          .success((data) => {
            abp.notify.info(App.localize('SuccessfullySaved'));
            $uibModalInstance.close(data);
          })
          .finally(() => {
            vm.saving -= 1;
          });
      } else {
        vm.saving += 1;
        bankSvc
          .createOrUpdateBank(vm.bank)
          .success((data) => {
            abp.notify.info(App.localize('SuccessfullySaved'));
            $uibModalInstance.close(data);
          })
          .finally(() => {
            vm.saving -= 1;
          });
      }
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
