(function () {
    angular
        .module('app')
        .controller('host.views.inpatientGuaranteeLetters.details.page', ['$stateParams', '$rootScope',
            'abp.services.app.inpatientGuaranteeLetter', 'moment', controller]);

    function controller($stateParams, $rootScope, inpatientGuaranteeLetterSvc, moment) {
        var vm = this;
        vm.loading = 0;
        vm.letterNumber = $stateParams.letterNumber;
        vm.activeTab = $stateParams.tab ? $stateParams.tab : 0;
        vm.memberId = null;
        vm.newRepliedQueriesCount = 0;
        vm.isDetailPage = true;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.timeZoneId = $stateParams.timeZoneId;
        vm.serviceType = $stateParams.serviceType;
    }
})();
