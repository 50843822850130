(() => {
  angular.module('app').component('history', {
    templateUrl: require('./history.html'),
    controller: HistoryController,
    controllerAs: 'vm',
    bindings: {
      items: '=',
    },
  });

  HistoryController.$inject = [];

  function HistoryController() {
    // const vm = this;
  }
})();
