(function () {
    var controllerId = 'host.views.drugs.createEdit';
    var app = angular.module('app');
    app.controller(controllerId, [
        'countries', '$scope', '$state', '$stateParams', '$timeout', 'abp.services.app.drug', 'abp.services.app.commonLookup', 'Hms.Drugs.Drug', 'Hms.MultiTenancy.Tenant', 
        function (countries, $scope, $state, $stateParams, $timeout, drugSvc, commonLookupSvc, constsDrug, constsTenant) {
            function init() {
                getDrug();
                getAccessibleCountries();
            }

            var vm = this;
            vm.constsDrug = constsDrug;
            vm.constsTenant = constsTenant;
            vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');
            vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
            vm.drugId = parseInt($stateParams.drugId);
            vm.isCreate = !vm.drugId;
            vm.loading = 0;
            vm.saving = 0;
            vm.viewDosage = false;
            vm.viewIndication = false;
            vm.saving = false;
            vm.active = 0;
            vm.mimsClasses = [];
            vm.quantityUnits = [];
            vm.strengthUnits = [];
            vm.dosageUnits = [];
            vm.treatmentExceptions = null;
            vm.corporateExceptions = null;
            vm.countries = [];
            vm.selectedCountry = 'MY';
            vm.drugIngredients = [];

            vm.updateCurrencyCode = updateCurrencyCode;

            function updateCurrencyCode(countryCode) {
                vm.currencyCode = _.find(countries, ['code', countryCode]).currencyCode;
            }

            vm.getStrengthUnits = function (search) {
                var strengthUnits = vm.strengthUnits.slice();
                if (search && strengthUnits.indexOf(search) === -1) {
                    strengthUnits.unshift(search);
                }
                return strengthUnits;
            };

            vm.getQuantityUnits = function (search) {
                var quantityUnits = vm.quantityUnits.slice();
                if (search && quantityUnits.indexOf(search) === -1) {
                    quantityUnits.unshift(search);
                }
                return quantityUnits;
            };

            vm.getDosageUnits = function (search) {
                var dosageUnits = vm.dosageUnits.slice();
                if (search && dosageUnits.indexOf(search) === -1) {
                    dosageUnits.unshift(search);
                }
                return dosageUnits;
            };

            /* Navigation*/

            vm.triggerTab = function (input) {
                switch (input) {
                    case 0:
                        vm.active = 0;
                        break;
                    case 1:
                        vm.active = 1;
                        break;
                    case 2:
                        vm.active = 2;
                        break;
                    case 3:
                        vm.active = 3;
                        break;
                }
            };

            vm.triggerNext = function (input) {
                var nextBtnName = 'btnBasic';
                switch (input) {
                    case 0:
                        nextBtnName = 'btnDosage';
                        vm.active++;
                        break;
                    case 1:
                        vm.active++;
                        nextBtnName = 'btnIndication';
                        break;
                    case 2:
                        vm.active++;
                        nextBtnName = 'btnException';
                        break;
                    default: break;
                }
                $timeout(function () {
                    angular.element('#' + nextBtnName).click();
                }, 0);
            };

            vm.triggerPrev = function (input) {
                var prevBtnName = 'btnBasic';
                switch (input) {
                    case 0:
                        vm.active--;
                        prevBtnName = 'btnBasic';
                        break;
                    case 1:
                        vm.active--;
                        prevBtnName = 'btnDosage';
                        break;
                    case 2:
                        vm.active--;
                        prevBtnName = 'btnIndication';
                        break;
                    default: break;
                }
                $timeout(function () {
                    angular.element('#' + prevBtnName).click();
                }, 0);
            };

            vm.disableNext = function () {
                var isBasicFormInvalid = $scope.forms.basic.$invalid;
                vm.viewDosage = !isBasicFormInvalid;
                vm.viewIndication = !isBasicFormInvalid;

                switch (vm.active) {
                    case 0:
                    case 1:
                    case 2:
                        return isBasicFormInvalid;
                    default:
                        return true;
                }
            };

            /* End of Navigation */

            vm.save = function () {
                vm.resetDrugIngredient();
                var input = $.extend({}, vm.drug, {
                    ingredients: vm.drugIngredients,
                    treatmentExclusions: vm.treatmentExceptions ? vm.treatmentExceptions.getFilteredRecords() : null,
                    corporateExclusions: vm.corporateExceptions ? vm.corporateExceptions.getFilteredRecords() : null,
                    countryCode: vm.selectedCountry
                });

                vm.saving++;
                drugSvc.createOrUpdateDrug(input).success(function (result) {
                    var msg = vm.isCreate ? App.localize('SuccessfullySubmitted') : App.localize('SuccessfullyUpdated');
                    abp.message.success(msg);
                    $state.go('host.drugs');
                }).finally(function () {
                    vm.saving--;
                });
            };

            vm.addItem = function () {
                vm.drugIngredients.push({ id: 0 });
            };

            vm.deleteItem = function (index) {
                if (index < vm.drugIngredients.length) {
                    vm.drugIngredients.splice(index, 1);
                    refreshActiveIngredients();
                }
            };

            vm.ingredientHasValue = function (ingredient) {
                return ingredient.name || ingredient.strength || ingredient.strengthUnit;
            };

            vm.clearIngredients = function () {
                vm.drugIngredients = [];
                refreshActiveIngredients();
            };

            vm.resetDrugIngredient = function () {
                vm.drugIngredients = _.filter(vm.drugIngredients, function (v) {
                    return v.name;
                });
            };

            function refreshActiveIngredients() {
                var hasBlank = _.some(vm.drugIngredients, function (t) {
                    return !vm.ingredientHasValue(t);
                });

                while (!hasBlank || vm.drugIngredients.length < 2) {
                    vm.drugIngredients.push({});
                    hasBlank = true;
                }
            }

            function getDrug() {
                vm.loading++;
                drugSvc.getDrugForEdit({
                    id: vm.drugId
                }).success(function (data) {
                    vm.mimsClasses = data.mimsClasses.items;
                    vm.quantityUnits = data.quantityUnits;
                    vm.strengthUnits = data.strengthUnits;
                    vm.dosageUnits = data.dosageUnits;

                    vm.treatmentExceptions = data.drug
                        ? new Exceptions(drugSvc.findTreatments, data.drug.treatmentExclusions)
                        : new Exceptions(drugSvc.findTreatments);

                    vm.corporateExceptions = data.drug
                        ? new Exceptions(drugSvc.findCorporates, data.drug.corporateExclusions)
                        : new Exceptions(drugSvc.findCorporates);

                    // Skip for creation.

                    if (!vm.drugId) return;

                    vm.drug = data.drug;
                    if (vm.hasRegionalSystemSupport) {
                        vm.selectedCountry = data.drug.countryCode;
                        updateCurrencyCode(vm.selectedCountry);
                    }
                    vm.drugIngredients = data.drugIngredients;
                }).finally(function () {
                    vm.loading--;
                    refreshActiveIngredients();
                });
            }

            /* Exceptions */

            function Exceptions(lookupFunc, initialRecords) {
                var _this = this;

                this.records = initialRecords || [];
                this.exceptionsCount = _.isArray(initialRecords) ? initialRecords.length : 0;
                this.searchResult = [];
                this.selectedId = null;

                function updateExceptionsCountText() {
                    return _this.exceptionsCountText = App.localize('XExceptionRecords', _this.exceptionsCount);
                }
                function findRecordsIndex(array, id) {
                    return _.findIndex(array, function (d) { return d.id === id; });
                }

                this.lookup = function (search) {
                    if (search) {
                        var excludedIds = _.map(_this.records, function (r) { return r.id; });
                        lookupFunc({
                            filter: search,
                            maxResultCount: 50,
                            excludedIds: excludedIds
                        }).success(function (data) {
                            _this.searchResult = _.filter(data.items, function (d) {
                                var idx = _.findIndex(_this.records, function (r) {
                                    return r.id === d.id;
                                });
                                return idx === -1;
                            });
                        }).finally(function () {
                            if (!_this.searchResult.length)
                                _this.selected = null;
                        });
                    }
                };
                this.add = function () {
                    _this.records.unshift(_this.searchResult[findRecordsIndex(_this.searchResult, _this.selectedId)]);
                    _this.exceptionsCount++;
                    updateExceptionsCountText();
                    _this.selectedId = null;
                    _this.searchResult = [];
                };
                this.delete = function (index) {
                    _this.records[index].isRemove = true;
                    _this.exceptionsCount--;
                    updateExceptionsCountText();
                };
                this.undoDelete = function (index) {
                    _this.records[index].isRemove = false;
                    _this.exceptionsCount++;
                    updateExceptionsCountText();
                };
                this.getFilteredRecords = function () {
                    return _.filter(_this.records, function (r) { return !r.isRemove; });
                };

                this.exceptionsCountText = updateExceptionsCountText();
            }

            function getAccessibleCountries() {
                vm.loading++;
                commonLookupSvc.getEligibleCountries().success(function (data) {
                    var eligibleCountryCodes = data.map(o => o.code)
                    vm.countries = _.filter(countries, function (c) {
                        return eligibleCountryCodes.includes(c.code)
                    });
                }).finally(function (e) {
                    vm.loading--;
                });
            }

            init();
        }
    ]);
})();
