(() => {
  angular.module('app').component('commonPanelRequestsCorporateRequesterTagsInputComponent', {
    templateUrl: require('./corporateRequesterTagsInput.component.html'),
    controller: CorporateRequesterTagsInputController,
    controllerAs: 'vm',
    bindings: {
      ngModel: '=',
      masterCorporates: '<',
      isDisabled: '<',
      required: '=',
    },
  });

  CorporateRequesterTagsInputController.$inject = [];

  function CorporateRequesterTagsInputController() {
    const vm = this;

    // counter for new non-existing master corporate as requester. Don't touch.
    let counter = 0;

    // A list of master corporates with their normalized name as key for lookup.
    let normalizedCorporates = {};

    vm.onTagAdding = onTagAdding;
    vm.onTagRemoved = onTagRemoved;
    vm.search = search;
    vm.$onChanges = onChanges;

    function onChanges(changes) {
      if (changes.masterCorporates) {
        normalizedCorporates = _.keyBy(vm.masterCorporates, 'normalizedName');
      }
    }

    function onTagAdding(tag) {
      const t = tag;
      if(t.value) return true;

      const normalizedName = normalizeName(t.name);

      // Don't allow if the name is already exists.

      if (normalizedCorporates[normalizedName]) return false;

      // Generate new unique new master corp's temp Id by decrementing counter.
      counter -= 1;
      t.value = counter;

      t.normalizedName = normalizedName;
      normalizedCorporates[normalizedName] = t;
      return true;
    }

    function onTagRemoved(tag) {
      // Remove user's new master corporate if untagged.

      if (tag.value <= 0) {
        delete normalizedCorporates[tag.normalizedName];
      }
    }

    function search(query) {
      const q = query.toLowerCase(); // normalize query text.
      return _.filter(
        vm.masterCorporates,
        (corporate) => corporate.name.toLowerCase().indexOf(q) !== -1
      );
    }

    function normalizeName(name) {
      if (name) {
        return name.toUpperCase().replace(/[^A-Z0-9]+/g, ''); // Based off TextHelper.Normalize()
      }
      return null;
    }
  }
})();
