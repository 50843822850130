(function () {
    'use strict';

    angular.module('app').component('hostCorporatesSupportPlansCreateEditScheduleOfBenefitSelfPayHospitalizationComponent', {
        templateUrl: require('/App/host/views/corporates/supportPlans/createEdit/scheduleOfBenefit/selfPayHospitalization/selfPayHospitalization.component.html'),
        controller: controller,
        controllerAs: 'vm',
        bindings: {
            scheduleOfBenefit: '<',
            currencyCode: '<'
        }
    });

    function controller() {
        var vm = this;
        vm.medicalReportAmountType = null;

        vm.medicalReportText = App.localize('MedicalReport');
        vm.asChargedText = App.localize('AsCharged') + ' (' + vm.currencyCode + ')';

        vm.toggleSelfPayHospitalization = function () {
            vm.minMedicalReportAmount = vm.medicalReportAmountType === 1 ? 1 : 0;

            setMedicalReportAmountType()
        };

        function init() {
            vm.medicalReportAmountType = vm.scheduleOfBenefit.medicalReport.isAsCharged ? 0 : 1;

            vm.toggleSelfPayHospitalization();
        }

        function setMedicalReportAmountType() {
            switch (vm.medicalReportAmountType) {
                case 0:
                    vm.scheduleOfBenefit.medicalReport.isAsCharged = true;
                    break;

                case 1:
                    vm.scheduleOfBenefit.medicalReport.isAsCharged = false;
                    break;

                default:
                    break;
            }
        }

        angular.element(document).ready(function () {
            init();
        });
    }
})();
