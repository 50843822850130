(function () {
    'use strict';

    angular.module('app').component('hostCorporatesSupportPlansCreateEditScheduleOfBenefitNeoNatalComponent', {
        templateUrl: require('/App/host/views/corporates/supportPlans/createEdit/scheduleOfBenefit/neoNatal/neoNatal.component.html'),
        controller: controller,
        controllerAs: 'vm',
        bindings: {
            scheduleOfBenefit: '<',
            currencyCode: '<'
        }
    });

    function controller() {
        var vm = this;

        vm.newBornNICUHDUText = App.localize('NewBornNICUHDU')
        vm.newBornIncubationText = App.localize('NewBornIncubation')
        vm.newBornPhototherapyTreatmentText = App.localize('NewBornPhototherapyTreatment')
        vm.daysText = App.localize('Days');
        vm.perAnnumText = App.localize('PerAnnum');
        vm.dailyText = App.localize('PerDay');

        vm.toggleNeoNatal = function () {
            vm.minNeoNatalDays = vm.scheduleOfBenefit.neoNatal.isCovered === 1 ? 1 : 0;
        };

        function init() {
            vm.toggleNeoNatal();
        }

        angular.element(document).ready(function () {
            init();
        });
    }
})();
