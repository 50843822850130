(function () {
    var componentId = 'hostClinicsComponentsMap';
    var app = angular.module('app');

    function controller($scope, $timeout) {
        function init() {
            vm.map.init();
        }

        var vm = this;

        vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');

        var placeFields = ['geometry'];
        if (Array.isArray($scope.placeFields)) {
            _.union(placeFields, vm.placeFields);
        }

        function getLatLngForMap() {
            if (vm.latLng) return vm.latLng;
            return {
                lat: 3.031647,
                lng: 101.617452
            };
        }

        function convertLatLng(latLng) {
            return {
                latitude: latLng.lat,
                longitude: latLng.lng
            };
        }

        vm.map = {
            isInit: false,
            hasLatLng: true,
            marker: {
                id: 1,
                coords: convertLatLng(getLatLngForMap()),
                options: { draggable: true },
                events: {
                    dragend: function (marker, eventName, args) {
                        vm.latLng = {
                            lat: marker.getPosition().lat(),
                            lng: marker.getPosition().lng()
                        };
                    }
                }
            },
            searchbox: {
                options: {
                    autocomplete: true,
                    componentRestrictions: {
                        country: vm.hasRegionalSystemSupport
                            ? vm.eligibleCountryCodes
                            : abp.setting.get('Hms.General.DefaultCountryCode')
                    },
                    fields: placeFields //Example: ['formatted_phone_number', 'geometry', 'international_phone_number', 'name']
                },
                events: {
                    place_changed: function (searchBox) {
                        var place = searchBox.getPlace();

                        vm.latLng = {
                            lat: place.geometry.location.lat(),
                            lng: place.geometry.location.lng()
                        };

                        vm.map.center = convertLatLng(vm.latLng);
                        vm.latLngUpdated();

                        if (_.isFunction(vm.placeChanged)) {
                            vm.placeChanged({ place: place });
                        }

                        // TODO: To be removed after testing then implementing this component into createOrEditLocationModal
                        //vm.location.name = vm.location.name || place.name || '';
                        //vm.location.contactNumber = vm.location.contactNumber || place.international_phone_number || place.formatted_phone_number || '';
                    }
                }
            },
            options: App.createMapOptions(),
            init: function () {
                if (vm.map.isInit) {
                    return;
                }
                vm.map.isInit = true;

                var latLng = getLatLngForMap();
                vm.map.center = convertLatLng(latLng);
                vm.map.marker.coords = convertLatLng(latLng);
                vm.map.hasLatLng = true;
                if (!vm.latLng) {
                    $timeout(function () {
                        vm.map.hasLatLng = false;
                    }, 250);
                }
            }
        };

        vm.latLngUpdated = function () {
            if (!vm.map.isInit) return;
            var latLng = getLatLngForMap();
            vm.map.center = convertLatLng(latLng);
            vm.map.marker.coords = convertLatLng(latLng);
            vm.map.hasLatLng = vm.latLng;
        };

        init();
    }

    app.component(componentId, {
        bindings: {
            latLng: '=', // Two-way binding reason: To manipulate the coordinate model. A quick hack for now.
            placeChanged: '&', // Callback function on place_changed.
            placeFields: '<', // Google Map API Place Fields. An array of string.
            eligibleCountryCodes: '<'
        },
        templateUrl: require('/App/host/views/clinics/components/map/index.html'),
        controller: ['$scope', '$timeout', controller],
        controllerAs: 'vm'
    });
})();
