(function () {
    'use strict';

    angular.module('app').component('hostCorporatesSupportPlansCreateEditScheduleOfBenefitComponent', {
        templateUrl: require('/App/host/views/corporates/supportPlans/createEdit/scheduleOfBenefit/scheduleOfBenefit.component.html'),
        controller: ['$timeout', controller],
        controllerAs: 'vm',
        bindings: {
            supportPlan: '<',
            isHospitalization: '<',
            currencyCode: '<',
            countryCode: '<'
        }
    });

    function controller($timeout) {
        var vm = this;

        function init() {
            if (vm.isHospitalization) {
                vm.tabType = vm.hospitalizationPrincipalDefaultTab;
            }
            else {
                vm.tabType = vm.maternityDefaultTab;
            }

            vm.dependentTabType = vm.hospitalizationDependentDefaultTab;

            vm.showDependentSettings();
        }

        vm.isCreate = !vm.supportPlan.id;
        vm.hasScheduleOfBenefitEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');
        vm.hasTxtFileSupport = abp.setting.getBoolean('Hms.Feature.TxtFileSupport');
        var isOpen = false;

        // #region Handle tab

        vm.tabType = null;
        vm.dependentTabType = null;
        vm.hospitalizationPrincipalDefaultTab = 'PrincipalHospitalizationInpatientMedical'
        vm.hospitalizationDependentDefaultTab = 'DependentHospitalizationInpatientMedical'
        vm.maternityDefaultTab = 'MaternityDelivery'

        vm.setTabType = function (tabName) {
            if (tabName) {
                vm.tabType = tabName;
            }
        }

        vm.setDependentTabType = function (tabName) {
            if (tabName) {
                vm.dependentTabType = tabName;
            }
        }

        // #endregion Handle tab

        vm.showDependentSettings = function () {
            // To not show dependent setting if it is not hospitalisation benefit group.

            if (!vm.isHospitalization) {
                return false;
            }

            var showDependentTab = (vm.isCreate && vm.supportPlan.hospitalizationSetting.hasDependentCovered === 1 &&
                vm.supportPlan.hospitalizationSetting.enableDependentSetting === 1) ||
                (!vm.isCreate && vm.supportPlan.hospitalizationSetting.hasDependentCovered === 1);

            if (isOpen && !vm.supportPlan.hospitalizationSetting.dependentScheduleOfBenefit) {
                isOpen = false;
            }

            if (!isOpen && showDependentTab) {
                $timeout(function () {
                    vm.dependentTabType = vm.hospitalizationDependentDefaultTab;
                });
                isOpen = true;
            }
            return showDependentTab;
        };

        vm.toggleDependentSetting = function () {
            if (vm.supportPlan.hospitalizationSetting.enableDependentSetting === 1) {
                isOpen = false;
            }
            vm.showDependentSettings();
        }

        init();
    }
})();
