(() => {
  angular.module('finance').component('invoiceDetailInvoiceBankDetailComponent', {
    templateUrl: require('./invoiceBankDetail.component.html'),
    controller: InvoiceDetailInvoiceBankDetailComponent,
    controllerAs: 'vm',
    bindings: {
      invoice: '<',
      message: '<'
    },
  });

  InvoiceDetailInvoiceBankDetailComponent.$inject = [];

  function InvoiceDetailInvoiceBankDetailComponent() {
    const vm = this;

    vm.loading = 0;

    init();

    function init() {}
  }
})();
