(function () {
    var controllerId = 'host.views.userAgreements.list';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', '$timeout', 'abp.services.app.userAgreementVersion', 'Hms.UserAgreements.UserAgreementVersionCategory',
        function ($scope, $state, $stateParams, $timeout, versionSvc, enumUserAgreementVersionCategory) {
            function init() {
                $timeout(function () {
                    vm.gridOptions.paginationCurrentPage = Math.floor(vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1);
                });
            }

            var vm = this;
            vm.loading = 0;

            vm.enums = {};
            vm.enums.userAgreementVersionCategory = enumUserAgreementVersionCategory;
            vm.hasIndonesiaAndThailandRegionalExpansion = abp.setting.getBoolean(
                'Hms.Feature.IndonesiaAndThailandRegionalExpansion'
              );

            $scope.$on("$viewContentLoaded", function () {
                App.initAjax();
            });

            vm.permissions = {
                createOrEdit: abp.auth.isGranted('Host.UserAgreementVersions.CreateAndEdit'),
                deleteDraft: abp.auth.isGranted('Host.UserAgreementVersions.DeleteDraft'),
                publishOrDeprecate: abp.auth.isGranted('Host.UserAgreementVersions.PublishAndDeprecate')
            };

            vm.requestParams = {
                filter: $stateParams.filterText || '',
                skipCount: $stateParams.skipCount,
                maxResultCount: $stateParams.maxResultCount || app.consts.grid.defaultPageSize,
                sorting: $stateParams.sorting
            };

            vm.gridOptions = {
                appScopeProvider: vm,
                paginationPageSizes: app.consts.grid.defaultPageSize,
                paginationPageSize: app.consts.grid.defaultPageSize,
                paginationCurrentPage: 0,
                useExternalPagination: true,
                useExternalSorting: true,
                columnDefs: [{
                    enableSorting: false,
                    name: App.localize('Actions'),
                    width: 100,
                    headerCellTemplate: "<span></span>",
                    cellTemplate: 'actionTemplate'
                }, {
                    displayName: App.localize('Title'),
                    field: 'title',
                    minWidth: 120
                }, {
                    displayName: App.localize('Date'),
                    field: 'date',
                    cellFilter: 'momentFormat: \'L\'',
                    minWidth: 100
                }, {
                    displayName: App.localize('Status'),
                    field: 'status',
                    cellTemplate: 'statusTemplate',
                    minWidth: 100
                },{
                    displayName: App.localize('Category'),
                    field: 'category',
                    cellTemplate: 'categoryTemplate',
                    minWidth: 100
                }, {
                    displayName: App.localize('Tags'),
                    field: 'tags',
                    minWidth: 150
                }, {
                    displayName: App.localize('IsOptional'),
                    field: 'isOptional',
                    cellTemplate: 'isOptionalTemplate',
                    minWidth: 100
                }, {
                    displayName: App.localize('AllowPersonalDataProcessing'),
                    field: 'allowPersonalDataProcessingTemplate',
                    cellTemplate: 'allowPersonalDataProcessingTemplate',
                    minWidth: 100
                }, {
                    displayName: App.localize('Languages'),
                    field: 'languagesName',
                    minWidth: 220,
                    visible: vm.hasIndonesiaAndThailandRegionalExpansion
                }],
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                    $scope.gridApi.core.on.sortChanged($scope, function (scope, callback) {
                        var sorting = "";
                        for (var i = 0; i < callback.length; i++) {
                            if (i) sorting += ", ";
                            sorting += callback[i].field + " " + callback[i].sort.direction;
                        }
                        vm.requestParams.sorting = sorting;
                        vm.getVersions();
                    });
                    gridApi.pagination.on.paginationChanged($scope, App.setPagination(vm.requestParams, vm.getVersions));
                },
                data: []
            };

            // Shift out action column if there's no edit actions

            vm.permissions.createOrEdit || vm.permissions.deleteDraft || vm.permissions.publishOrDeprecate || vm.gridOptions.columnDefs.shift();

            function registerStateParams() {
                $state.transitionTo($state.current, {
                    skipCount: vm.requestParams.skipCount,
                    maxResultCount: vm.requestParams.maxResultCount,
                    sorting: vm.requestParams.sorting,
                    filterText: vm.requestParams.filter
                }, {
                    notify: false
                });
            }

            vm.getVersions = function () {
                registerStateParams();

                vm.loading++;
                versionSvc.getVersionList(vm.requestParams).success(function (data) {
                    vm.gridOptions.totalItems = data.totalCount;
                    vm.gridOptions.data = data.items;
                }).finally(function () {
                    vm.loading--;
                });
            };

            vm.delete = function (entity) {
                abp.message.confirm(App.localize('AgreementDeleteWarningMessage', entity.title), App.localize('AreYouSure'), function (result) {
                    if (result) {
                        versionSvc.deleteAsync({
                            id: entity.id
                        }).success(function () {
                            vm.getVersions();
                            abp.notify.success(App.localize("SuccessfullyDeleted"));
                        });
                    }
                });
            };

            vm.deprecate = function (entity) {
                abp.message.confirm(App.localize('AgreementDeprecateWarningMessage', entity.title), App.localize('AreYouSure'), function (result) {
                    if (result) {
                        versionSvc.deprecateAsync({
                            id: entity.id
                        }).success(function () {
                            vm.getVersions();
                            abp.notify.success(App.localize("SuccessfullyDeprecated"));
                        });
                    }
                });
            };

            vm.publish = function (entity) {
                abp.message.confirm(App.localize('AgreementPublishWarningMessage', entity.title), App.localize('AreYouSure'), function (result) {
                    if (result) {
                        versionSvc.publishAsync({
                            id: entity.id
                        }).success(function () {
                            vm.getVersions();
                            abp.notify.success(App.localize("SuccessfullyPublished"));
                        });
                    }
                });
            };

            init();
        }
    ]);
})();
