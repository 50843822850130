(() => {
  angular.module('app').controller('common.views.saleTransactions.statement', StatementController);

  StatementController.$inject = ['$stateParams', 'abp.services.app.saleTransaction'];

  function StatementController($stateParams, saleTransactionSvc) {
    const vm = this;

    vm.loading = 0;
    vm.input = null;
    vm.stopInvoiceGeneration = abp.setting.getBoolean('Hms.Feature.StopPortalInvoiceGeneration');

    vm.getDateTimeString = getDateTimeString;

    init();

    function init() {
      vm.loading += 1;
      saleTransactionSvc
        .getStatement(
          (vm.input = {
            tenantId: $stateParams.tenantId,
            subsidiaryId: $stateParams.subsidiaryId,
            type: $stateParams.type,
            startDate: $stateParams.startDate,
            endDate: $stateParams.endDate,
          })
        )
        .success((data) => {
          vm.statement = data;
          vm.currencyCode = data.currencyCode;
        })
        .finally(() => {
          vm.loading -= 1;
        });

      switch ($stateParams.type) {
        case 1:
          vm.title = App.localize('StatementOfAccounts');
          break;
        case 2:
          vm.title = App.localize('OpenItem');
          break;
        case 3:
          vm.title = App.localize('TransactionStatement');
          break;
        default:
          vm.title = App.localize('Statement');
      }
    }

    function getDateTimeString(date, timezone) {
      return App.getDateTimeString(date, timezone);
    }
  }
})();
