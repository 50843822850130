(() => {
  angular
    .module('app')
    .controller('common.views.users.permissionsModal', PermissionsModalController);

  PermissionsModalController.$inject = ['$uibModalInstance', 'abp.services.app.user', 'user'];

  function PermissionsModalController($uibModalInstance, userSvc, user) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;
    vm.resettingPermissions = 0;
    vm.isHost = App.isHost();
    vm.user = user;
    vm.permissionEditData = null;
    const hasFinanceService = abp.setting.getBoolean('Hms.Feature.FinanceService');
    const hasAccessFinanceTransactionData =
      vm.isHost || abp.setting.getBoolean('Hms.Feature.AccessFinanceTransactionData');

    vm.resetPermissions = resetPermissions;
    vm.save = save;
    vm.cancel = cancel;
    vm.close = close;

    init();

    function init() {
      getUserPermissionsForEdit();
    }

    function getUserPermissionsForEdit() {
      vm.loading += 1;
      userSvc
        .getUserPermissionsForEdit({
          id: vm.user.id,
        })
        .success((data) => {
          // Removes permissions from the permission tree if feature flag is not on.

          if (!hasFinanceService || !hasAccessFinanceTransactionData) {
            _.remove(data.grantedPermissionNames, (item) => item.includes('HealthMetrics.Finance'));
            _.remove(data.permissions, (item) =>
              item.name ? item.name.includes('HealthMetrics.Finance') : null
            );
          } else if (!vm.isHost) {
            // Remove old transactions permission from the permission tree.

            const regex = /^SaleTransactions/g;
            _.remove(data.grantedPermissionNames, (item) => item.match(regex));
            _.remove(data.permissions, (item) => (item.name ? item.name.match(regex) : false));
          }

          vm.permissionEditData = {
            permissions: data.permissions,
            grantedPermissionNames: data.grantedPermissionNames,
          };
          vm.isAdminOrHmsPersonnel = data.isAdminOrHmsPersonnel;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function resetPermissions() {
      vm.resettingPermissions += 1;
      userSvc
        .resetUserSpecificPermissions({
          id: vm.user.id,
        })
        .success(() => {
          abp.notify.info(App.localize('ResetSuccessfully'));
          getUserPermissionsForEdit();
        })
        .finally(() => {
          vm.resettingPermissions -= 1;
        });
    }

    function save() {
      vm.saving += 1;
      userSvc
        .updateUserPermissions({
          id: vm.user.id,
          grantedPermissionNames: vm.permissionEditData.grantedPermissionNames,
        })
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          vm.close();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function close() {
      $uibModalInstance.close();
    }
  }
})();
