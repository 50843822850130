(function () {
    var controllerId = 'clinic.views.healthScreeningApplyGuaranteeLetterModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'abp.services.app.commonLookup', 'abp.services.app.healthScreeningGuaranteeLetter',
        'clinic', 'employee',
        function ($scope, $uibModalInstance, commonLookupSvc, healthScreeningGuaranteeLetterSvc, clinic, employee) {
            function init() {
                getCurrencyCode();
            }

            var vm = this;
            vm.saving = 0;
            vm.loading = 0;
            vm.employee = employee;
            vm.clinic = clinic;
            vm.disciplineId = null;
            vm.isPatientInHospital = null;
            vm.hasValidGLAmount = false;

            vm.applyGuaranteeLetter = function () {
                vm.saving++;
                healthScreeningGuaranteeLetterSvc.applyGuaranteeLetter({
                    employeeId: vm.employee.id,
                    clinicLocationId: vm.clinic.clinicLocationId
                }).success(function (letter) {
                    $uibModalInstance.close(letter);
                }).finally(function () {
                    vm.saving--;
                });
            };

            function getCurrencyCode() {
                vm.loading++;
                commonLookupSvc.getTenantCurrencyCode()
                    .success(function (currencyCode) {
                        vm.currencyCode = currencyCode;
                        GetGuaranteeAmount();
                    }).finally(function () {
                        vm.loading--;
                    });
            }

            function GetGuaranteeAmount() {
                vm.loading++;
                healthScreeningGuaranteeLetterSvc.getHealthScreeningGuaranteeAmount({ employeeId: vm.employee.id })
                    .success(function (guaranteeAmount) {
                        vm.guaranteeAmount = guaranteeAmount;
                        vm.hasValidGLAmount = guaranteeAmount > 0;
                    }).finally(function () {
                        vm.loading--;
                    });
            }

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            init();
        }
    ]);
})();
