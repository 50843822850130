(() => {
  const appModule = angular.module('app');

  /**
   * pageTitle - Directive for set Page title - mata title
   */
  function pageTitle($rootScope, $timeout) {
    return {
      link(scope, element) {
        const listener = function (_event, toState) {
          // Default title - load on Dashboard 1
          let title = 'INSPINIA | Responsive Admin Theme';
          // Create your own title pattern
          if (toState.data && toState.data.pageTitle)
            title = `INSPINIA | ${toState.data.pageTitle}`;
          $timeout(() => {
            element.text(title);
          });
        };
        $rootScope.$on('$stateChangeStart', listener);
      },
    };
  }

  /**
   * sideNavigation - Directive for run metsiMenu on sidebar navigation
   */
  function sideNavigation($timeout) {
    return {
      restrict: 'A',
      link(scope, element) {
        // Call the metsiMenu plugin and plug it to sidebar navigation
        $timeout(() => {
          element.metisMenu();
        });

        // Colapse menu in mobile mode after click on element
        const menuElement = $('#side-menu a:not([href$="\\#"])');
        menuElement.click(() => {
          if ($(window).width() < 769) {
            $('body').toggleClass('mini-navbar');
          }
        });

        // Enable initial fixed sidebar
        if ($('body').hasClass('fixed-sidebar')) {
          const sidebar = element.parent();
          sidebar.slimScroll({
            height: '100%',
            railOpacity: 0.9,
          });
        }
      },
    };
  }

  /**
   * responsibleVideo - Directive for responsive video
   */
  function responsiveVideo() {
    return {
      restrict: 'A',
      link(scope, element) {
        const figure = element;
        const video = element.children();
        video
          .attr('data-aspectRatio', video.height() / video.width())
          .removeAttr('height')
          .removeAttr('width');

        // We can use $watch on $window.innerWidth also.
        $(window)
          .resize(() => {
            const newWidth = figure.width();
            video.width(newWidth).height(newWidth * video.attr('data-aspectRatio'));
          })
          .resize();
      },
    };
  }

  /**
   * iboxTools - Directive for iBox tools elements in right corner of ibox
   */
  function iboxTools($timeout) {
    return {
      restrict: 'A',
      scope: true,
      templateUrl: require('../views/layout/ibox_tools.html'),
      controller: [
        '$scope',
        '$element',
        function ($scope, $element) {
          // Function for collapse ibox
          $scope.showhide = function () {
            const ibox = $element.closest('div.ibox');
            const icon = $element.find('i:first');
            const content = ibox.children('.ibox-content');
            content.slideToggle(200);
            // Toggle icon from up to down
            icon.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
            ibox.toggleClass('').toggleClass('border-bottom');
            $timeout(() => {
              ibox.resize();
              ibox.find('[id^=map-]').resize();
            }, 50);
          };
          // Function for close ibox
          $scope.closebox = function () {
            const ibox = $element.closest('div.ibox');
            ibox.remove();
          };
        },
      ],
    };
  }

  /**
   * iboxTools with full screen - Directive for iBox tools elements in right corner of ibox with full screen option
   */
  function iboxToolsFullScreen($timeout) {
    return {
      restrict: 'A',
      scope: true,
      templateUrl: require('../views/layout/ibox_tools_full_screen.html'),
      controller: [
        '$scope',
        '$element',
        function ($scope, $element) {
          // Function for collapse ibox
          $scope.showhide = function () {
            const ibox = $element.closest('div.ibox');
            const icon = $element.find('i:first');
            const content = ibox.children('.ibox-content');
            content.slideToggle(200);
            // Toggle icon from up to down
            icon.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
            ibox.toggleClass('').toggleClass('border-bottom');
            $timeout(() => {
              ibox.resize();
              ibox.find('[id^=map-]').resize();
            }, 50);
          };
          // Function for close ibox
          $scope.closebox = function () {
            const ibox = $element.closest('div.ibox');
            ibox.remove();
          };
          // Function for full screen
          $scope.fullscreen = function () {
            const ibox = $element.closest('div.ibox');
            const button = $element.find('i.fa-expand');
            $('body').toggleClass('fullscreen-ibox-mode');
            button.toggleClass('fa-expand').toggleClass('fa-compress');
            ibox.toggleClass('fullscreen');
            setTimeout(() => {
              $(window).trigger('resize');
            }, 100);
          };
        },
      ],
    };
  }

  /**
   * minimalizaSidebar - Directive for minimalize sidebar
   */
  function minimalizaSidebar($timeout) {
    return {
      restrict: 'A',
      template:
        '<a class="navbar-minimalize minimalize-styl-2 btn btn-primary " href="" ng-click="minimalize()"><i class="fa fa-bars"></i></a>',
      controller: [
        '$scope',
        '$element',
        function ($scope) {
          $scope.minimalize = function () {
            $('body').toggleClass('mini-navbar');
            if (!$('body').hasClass('mini-navbar') || $('body').hasClass('body-small')) {
              // Hide menu in order to smoothly turn on when maximize menu
              $('#side-menu').hide();
              // For smoothly turn on menu
              $timeout(() => {
                $('#side-menu').fadeIn(400);
              }, 200);
            } else if ($('body').hasClass('fixed-sidebar')) {
              $('#side-menu').hide();
              $timeout(() => {
                $('#side-menu').fadeIn(400);
              }, 100);
            } else {
              // Remove all inline style from jquery fadeIn function to reset menu state
              $('#side-menu').removeAttr('style');
            }
          };
        },
      ],
    };
  }

  function closeOffCanvas() {
    return {
      restrict: 'A',
      template:
        '<a class="close-canvas-menu" ng-click="closeOffCanvas()"><i class="fa fa-times"></i></a>',
      controller: [
        '$scope',
        '$element',
        function ($scope) {
          $scope.closeOffCanvas = function () {
            $('body').toggleClass('mini-navbar');
          };
        },
      ],
    };
  }

  /**
   * vectorMap - Directive for Vector map plugin
   */
  function vectorMap() {
    return {
      restrict: 'A',
      scope: {
        myMapData: '=',
      },
      link(scope, element) {
        element.vectorMap({
          map: 'world_mill_en',
          backgroundColor: 'transparent',
          regionStyle: {
            initial: {
              fill: '#e4e4e4',
              'fill-opacity': 0.9,
              stroke: 'none',
              'stroke-width': 0,
              'stroke-opacity': 0,
            },
          },
          series: {
            regions: [
              {
                values: scope.myMapData,
                scale: ['#1ab394', '#22d6b1'],
                normalizeFunction: 'polynomial',
              },
            ],
          },
        });
        const destroyMap = function () {
          element.remove();
        };
        scope.$on('$destroy', () => {
          destroyMap();
        });
      },
    };
  }

  /**
   * sparkline - Directive for Sparkline chart
   */
  function sparkline() {
    return {
      restrict: 'A',
      scope: {
        sparkData: '=',
        sparkOptions: '=',
      },
      link(scope, element) {
        scope.$watch(scope.sparkData, () => {
          render();
        });
        scope.$watch(scope.sparkOptions, () => {
          render();
        });
        function render() {
          $(element).sparkline(scope.sparkData, scope.sparkOptions);
        }
      },
    };
  }

  /**
   * icheck - Directive for custom checkbox icheck
   */
  function icheck($timeout) {
    return {
      restrict: 'A',
      require: 'ngModel',
      scope: {
        click: '&ngClick',
      },
      link($scope, element, $attrs, ngModel) {
        return $timeout(() => {
          const { value } = $attrs;

          $scope.$parent.$watch($attrs.ngModel, () => {
            $(element).iCheck('update');
          });

          $scope.$parent.$watch($attrs.ngDisabled, (newValue) => {
            $(element).iCheck(newValue ? 'disable' : 'enable');
            $(element).iCheck('update');
          });

          return $(element)
            .iCheck({
              checkboxClass: 'icheckbox_square-green',
              radioClass: 'iradio_square-green',
            })
            .on('ifChanged', (event) => {
              if ($(element).attr('type') === 'checkbox' && $attrs.ngModel) {
                $scope.$parent.$apply(() => {
                  const returnVal = ngModel.$setViewValue(event.target.checked);
                  $scope.click({ $value: event.target.checked });
                  return returnVal;
                });
              }
              if ($(element).attr('type') === 'radio' && $attrs.ngModel) {
                return $scope.$parent.$apply(() => {
                  const returnVal = ngModel.$setViewValue(value);
                  $scope.click({ $value: value });
                  return returnVal;
                });
              }

              return undefined;
            });
        });
      },
    };
  }

  /**
   * ionRangeSlider - Directive for Ion Range Slider
   */
  function ionRangeSlider() {
    return {
      restrict: 'A',
      scope: {
        rangeOptions: '=',
      },
      link(scope, elem) {
        elem.ionRangeSlider(scope.rangeOptions);
      },
    };
  }

  /**
   * dropZone - Directive for Drag and drop zone file upload plugin
   */
  function dropZone() {
    return {
      restrict: 'C',
      link(scope, element) {
        const config = {
          url: '/upload',
          maxFilesize: 100,
          paramName: 'uploadfile',
          maxThumbnailFilesize: 10,
          parallelUploads: 1,
          autoProcessQueue: false,
        };

        const eventHandlers = {
          addedfile(file) {
            scope.file = file;
            if (this.files[1] !== null) {
              this.removeFile(this.files[0]);
            }
            scope.$apply(() => {
              scope.fileAdded = true;
            });
          },

          success(file, response) {},
        };

        scope.processDropzone = processDropzone;
        scope.resetDropzone = resetDropzone;

        const dropzone = new Dropzone(element[0], config);

        init();

        function init() {
          angular.forEach(eventHandlers, (handler, event) => {
            dropzone.on(event, handler);
          });
        }

        function processDropzone() {
          dropzone.processQueue();
        }

        function resetDropzone() {
          dropzone.removeAllFiles();
        }
      },
    };
  }

  /**
   * chatSlimScroll - Directive for slim scroll for small chat
   */
  function chatSlimScroll($timeout) {
    return {
      restrict: 'A',
      link(scope, element) {
        $timeout(() => {
          element.slimscroll({
            height: '234px',
            railOpacity: 0.4,
          });
        });
      },
    };
  }

  /**
   * customValid - Directive for custom validation example
   */
  function customValid() {
    return {
      require: 'ngModel',
      link(scope, ele, attrs, c) {
        scope.$watch(attrs.ngModel, () => {
          // You can call a $http method here
          // Or create custom validation

          const validText = 'Inspinia';

          if (scope.extras === validText) {
            c.$setValidity('cvalid', true);
          } else {
            c.$setValidity('cvalid', false);
          }
        });
      },
    };
  }

  /**
   * fullScroll - Directive for slimScroll with 100%
   */
  function fullScroll($timeout) {
    return {
      restrict: 'A',
      scope: {
        fullScroll: '=',
      },
      link(scope, element) {
        let config = {
          height: '100%',
          railOpacity: 0.9,
        };
        config = angular.extend(config, scope.fullScroll);

        $timeout(() => {
          element.slimscroll(config);
        });
      },
    };
  }

  /**
   * slimScroll - Directive for slimScroll with custom height
   */
  function slimScroll($timeout) {
    return {
      restrict: 'A',
      scope: {
        boxHeight: '@',
      },
      link(scope, element) {
        $timeout(() => {
          element.slimscroll({
            height: scope.boxHeight,
            railOpacity: 0.9,
          });
        });
      },
    };
  }

  /**
   * clockPicker - Directive for clock picker plugin
   */
  function clockPicker() {
    return {
      restrict: 'A',
      link(scope, element) {
        element.clockpicker();
      },
    };
  }

  /**
   * fitHeight - Directive for set height fit to window height
   */
  function fitHeight() {
    return {
      restrict: 'A',
      link(scope, element) {
        element.css('height', `${$(window).height()}px`);
        element.css('min-height', `${$(window).height()}px`);
      },
    };
  }

  function buttonBusy() {
    return {
      restrict: 'A',
      scope: {
        buttonBusy: '=',
      },
      link(scope, element, attrs) {
        let processing = false;
        const btnElement = $(element);
        const spanElement = btnElement.find('span');
        let spanElementHtml = null;
        const attributes = btnElement.find('attrs');
        let attributesClass = null;
        scope.$watch('buttonBusy', () => {
          if (scope.buttonBusy) {
            btnElement.attr('disabled', 'disabled');

            if (attributes.length) {
              attributesClass = attributes.attr('class');
              attributes.removeClass();
              attributes.addClass('fa fa-spin fa-spinner');
            }

            if (attrs.busyText && spanElement.length) {
              spanElementHtml = spanElement.html();
              spanElement.html(attrs.busyText);
            }

            processing = !0;
          } else {
            if (!processing) {
              return;
            }
            btnElement.removeAttr('disabled');
            if (attributes.length && attributesClass) {
              attributes.removeClass();
              attributes.addClass(attributesClass);
            }
            if (spanElement.length && spanElementHtml) {
              spanElement.html(spanElementHtml);
            }
          }
        });
      },
    };
  }

  function busyIf() {
    return {
      restrict: 'A',
      scope: {
        busyIf: '=',
      },
      link(scope, element) {
        scope.$watch('busyIf', () => {
          if (scope.busyIf) {
            abp.ui.setBusy($(element));
          } else {
            abp.ui.clearBusy($(element));
          }
        });
      },
    };
  }

  function enterKey() {
    return (scope, element, attrs) => {
      element.bind('keydown keypress', (e) => {
        if (e.which === 13) {
          scope.$apply(() => {
            scope.$eval(attrs.enterKey);
          });
          e.preventDefault();
        }
      });
    };
  }

  function featureTree() {
    return {
      restrict: 'E',
      template: '<div class="feature-tree"></div>',
      scope: {
        editData: '=',
      },
      link: function (n, t) {
        function c() {
          o && i.jstree('destroy');
          o = !0;
          var t = _.map(n.editData.features, function (n) {
            return {
              id: n.name,
              parent: n.parentName ? n.parentName : '#',
              text: n.displayName,
              state: {
                opened: !0,
                selected: y(n.name),
              },
            };
          });
          i.on('ready.jstree', function () {
            u();
          })
            .on('redraw.jstree', function () {
              u();
            })
            .on('after_open.jstree', function () {
              u();
            })
            .on('create_node.jstree', function () {
              u();
            })
            .on('changed.jstree', function (n, t) {
              var h, u, c, o, a;
              t.node &&
                ((h = e),
                h || (e = !0),
                t.node.state.selected
                  ? (s(i.jstree('get_parent', t.node)),
                    (u = $.makeArray(i.jstree('get_children_dom', t.node))),
                    i.jstree('select_node', u))
                  : ((u = $.makeArray(i.jstree('get_children_dom', t.node))),
                    i.jstree('deselect_node', u)),
                h ||
                  ((c = l(t.node.id)),
                  (o = r(t.node.id)),
                  o &&
                    (!o.inputType || o.inputType.name == 'CHECKBOX') &&
                    ((a = i.jstree('is_checked', c) ? 'true' : 'false'), f(t.node.id, a)),
                  (e = !1)));
            })
            .jstree({
              core: {
                data: t,
              },
              types: {
                default: {
                  icon: 'fa fa-folder tree-item-icon-color icon-lg',
                },
                file: {
                  icon: 'fa fa-file tree-item-icon-color icon-lg',
                },
              },
              checkbox: {
                keep_selected_style: !1,
                three_state: !1,
                cascade: '',
              },
              plugins: ['checkbox', 'types'],
            });
        }

        function u() {
          i.find('.jstree-node').each(function () {
            var i = $(this),
              s = i.find('.jstree-anchor'),
              u = i.attr('id'),
              n = r(u),
              c = h(u) || '',
              e,
              t,
              o;
            if (n && n.inputType && n.inputType.name != 'CHECKBOX')
              if (n.inputType.name == 'SINGLE_LINE_STRING') {
                if (!i.find('.feature-tree-textbox').length) {
                  s.find('.jstree-checkbox').hide();
                  e = 'text';
                  n.inputType.validator &&
                    n.inputType.validator.name == 'NUMERIC' &&
                    (e = 'number');
                  t = $('<input class="feature-tree-textbox" type="' + e + '" />').val(c);
                  e == 'number'
                    ? (t.attr('min', n.inputType.validator.minValue),
                      t.attr('max', n.inputType.validator.maxValue))
                    : n.inputType.validator &&
                      n.inputType.validator.name == 'STRING' &&
                      (n.inputType.validator.maxLength > 0 &&
                        t.attr('maxlength', n.inputType.validator.maxLength),
                      n.inputType.validator.minLength > 0 && t.attr('required', 'required'),
                      n.inputType.validator.regularExpression &&
                        t.attr('pattern', n.inputType.validator.regularExpression));
                  t.on('input propertychange paste', function () {
                    a(u, t.val())
                      ? (f(u, t.val()), t.removeClass('feature-tree-textbox-invalid'))
                      : t.addClass('feature-tree-textbox-invalid');
                  });
                  t.appendTo(i);
                }
              } else
                n.inputType.name == 'COMBOBOX' &&
                  (i.find('.feature-tree-combobox').length ||
                    (s.find('.jstree-checkbox').hide(),
                    (o = $('<select class="feature-tree-combobox" />')),
                    _.each(n.inputType.itemSource.items, function (n) {
                      $('<option></option>').attr('value', n.value).text(n.displayText).appendTo(o);
                    }),
                    o
                      .val(c)
                      .on('change', function () {
                        f(u, o.val());
                      })
                      .appendTo(i)));
          });
        }

        function l(n) {
          return $('#' + n.replace('.', '\\.'));
        }

        function s(n) {
          i.jstree('select_node', n, !0);
          var t = i.jstree('get_parent', n);
          t && s(t);
        }

        function r(t) {
          var i = _.find(n.editData.features, function (n) {
            return n.name == t;
          });
          return i || abp.log.warn('Could not find a feature by name: ' + t), i;
        }

        function h(t) {
          var u = r(t),
            i;
          return u
            ? ((i = _.find(n.editData.featureValues, function (n) {
                return n.name == t;
              })),
              !i)
              ? u.defaultValue
              : i.value
            : '';
        }

        function a(n, t) {
          var u = r(n),
            i,
            f,
            o,
            e;
          if (!u || !u.inputType || !u.inputType.validator) return !0;
          if (((i = u.inputType.validator), i.name == 'STRING')) {
            if (t == undefined || t == null) return i.allowNull;
            if (
              typeof t != 'string' ||
              (i.minLength > 0 && t.length < i.minLength) ||
              (i.maxLength > 0 && t.length > i.maxLength)
            )
              return !1;
            if (i.regularExpression) return new RegExp(i.regularExpression).test(t);
          } else if (
            i.name == 'NUMERIC' &&
            (((f = parseInt(t)), isNaN(f)) ||
              ((o = i.minValue), o > f) ||
              ((e = i.maxValue), e > 0 && f > e))
          )
            return !1;
          return !0;
        }

        function v() {
          return (
            i.find('.jstree-node').each(function () {
              var t = $(this),
                u = t.attr('id'),
                n = r(u),
                e;
              n &&
                (!n.inputType || n.inputType.name == 'CHECKBOX') &&
                ((e = i.jstree('is_checked', t) ? 'true' : 'false'), f(u, e));
            }),
            i.find('.feature-tree-textbox-invalid').length <= 0
          );
        }

        function f(t, i) {
          var r = _.find(n.editData.featureValues, function (n) {
            return n.name == t;
          });
          r &&
            n.$root.safeApply(function () {
              r.value = i;
            });
        }

        function y(n) {
          var t = h(n);
          return t.toLowerCase() == 'true';
        }
        var i = $(t).find('.feature-tree'),
          o = !1,
          e = !1;
        n.$watch('editData', function () {
          n.editData &&
            ((n.editData.isValid = function () {
              return v();
            }),
            c());
        });
      },
    };
  }

  function permissionTree() {
    return {
      restrict: 'E',
      templateUrl: require('./permissionTreeTemplate.html'),
      scope: {
        editData: '=',
        checkboxDisabled: '<?',
      },
      link(scope, element) {
        const tree = $(element).find('.permission-tree');
        const searchField = $(element).find('.permission-tree-search');
        let initialized = false;
        let isUpdating = false;

        scope.expandAll = expandAll;
        scope.collapseAll = collapseAll;

        init();

        function init() {
          scope.$watch('editData', () => {
            if (scope.editData) {
              initialize();
            }
          });
        }

        function initialize() {
          if (initialized) {
            tree.jstree('destroy');
          } else {
            let to = false;
            searchField.on('keyup', () => {
              if (to) clearTimeout(to);
              to = setTimeout(() => {
                const v = searchField.val();
                tree.jstree(true).search(v);
              }, 250);
            });
          }
          const permissionRecords = _.map(scope.editData.permissions, (record) => ({
            id: record.name,
            parent: record.parentName ? record.parentName : '#',
            text: record.displayName,
            state: {
              opened: true,
              selected: _.indexOf(scope.editData.grantedPermissionNames, record.name) !== -1,
              checkbox_disabled: scope.checkboxDisabled,
            },
          }));
          tree.jstree({
            core: {
              data: permissionRecords,
            },
            types: {
              default: {
                icon: 'fa fa-folder',
              },
              file: {
                icon: 'fa fa-file',
              },
            },
            checkbox: {
              keep_selected_style: false,
              three_state: false,
              cascade: '',
            },
            search: {
              fuzzy: false,
              show_only_matches: true,
              show_only_matches_children: true,
            },
            conditionalselect() {
              return !scope.checkboxDisabled;
            },
            plugins: ['checkbox', 'types', 'search', 'conditionalselect'],
          });
          initialized = true;
          tree.on('changed.jstree', (node, action) => {
            let isNestedUpdate;
            let childNodes;
            if (action.node) {
              isNestedUpdate = isUpdating;
              if (!isNestedUpdate) {
                isUpdating = true;
              }
              if (action.node.state.selected) {
                selectNode(tree.jstree('get_parent', action.node));
                childNodes = $.makeArray(tree.jstree('get_children_dom', action.node));
                tree.jstree('select_node', childNodes);
              } else {
                childNodes = $.makeArray(tree.jstree('get_children_dom', action.node));
                tree.jstree('deselect_node', childNodes);
              }
              if (!isNestedUpdate) {
                scope.$apply(() => {
                  scope.editData.grantedPermissionNames = getSelectedPermissions();
                });
                isUpdating = false;
              }
            }
          });
        }

        function selectNode(node) {
          tree.jstree('select_node', node, true);
          const nodeParent = tree.jstree('get_parent', node);
          if (nodeParent) {
            selectNode(nodeParent);
          }
        }

        function getSelectedPermissions() {
          const selectedId = [];
          const records = tree.jstree('get_selected', true);
          for (let i = 0; i < records.length; i += 1) {
            selectedId.push(records[i].original.id);
          }
          return selectedId;
        }

        function expandAll() {
          tree.jstree(true).open_all();
        }

        function collapseAll() {
          tree.jstree(true).close_all();
        }
      },
    };
  }

  /**
   *
   * Pass all functions into module
   */
  appModule
    .directive('pageTitle', ['$rootScope', '$timeout', pageTitle])
    .directive('sideNavigation', ['$timeout', sideNavigation])
    .directive('iboxTools', ['$timeout', iboxTools])
    .directive('minimalizaSidebar', ['$timeout', minimalizaSidebar])
    .directive('vectorMap', vectorMap)
    .directive('sparkline', sparkline)
    .directive('icheck', ['$timeout', icheck])
    .directive('ionRangeSlider', ionRangeSlider)
    .directive('dropZone', dropZone)
    .directive('responsiveVideo', responsiveVideo)
    .directive('chatSlimScroll', ['$timeout', chatSlimScroll])
    .directive('customValid', customValid)
    .directive('fullScroll', ['$timeout', fullScroll])
    .directive('closeOffCanvas', closeOffCanvas)
    .directive('clockPicker', clockPicker)
    .directive('fitHeight', fitHeight)
    .directive('iboxToolsFullScreen', ['$timeout', iboxToolsFullScreen])
    .directive('slimScroll', ['$timeout', slimScroll])
    .directive('busyIf', busyIf)
    .directive('enterKey', enterKey)
    .directive('buttonBusy', buttonBusy)
    .directive('permissionTree', permissionTree)
    .directive('featureTree', featureTree);
})();
