(function () {
    'use strict';

    angular
        .module('app')
        .controller('host.views.doraemon.iglProcessing', ['$state', '$stateParams', '$uibModal', 'abp.services.app.doraemon', controller]);

    function controller($state, $stateParams, $modal, doraemonSvc) {
        var vm = this;

        vm.openApprovalModal = openApprovalModal;
        vm.openIssueNonGLModal = openIssueNonGLModal;
        vm.query = query;
        vm.preview = preview;
        vm.checkEditMode = checkEditMode;
        vm.isOnHold = true;
        vm.requestType = "Initial";
        vm.requestStatus = "Pending approval";

        vm.patientTimelineItems = [];
        vm.memberId = $stateParams.employeeId;
        vm.serviceRequestId = $stateParams.serviceRequestId;

        vm.loading = 0;

        function init() {
            if (vm.serviceRequestId == null || vm.serviceRequestId == "") {
                vm.isEdit = true;
            }
            else {
                vm.isEdit = false;
                getRequestDetails(vm.serviceRequestId);
            }
        }

        function approve(input) {
            vm.loading++;
            doraemonSvc.approveIglRequest(
                {
                    id: vm.serviceRequestId,
                    remarks: input.remarks
                }
            ).success(function (data) {
                vm.loading--;
                if (input.printGl)
                    window.open(data.printUrl);
                navigateToListing();
            }).finally(function () {
                vm.loading--;
            });
        }

        function reject(input) {
            doraemonSvc.rejectIglRequest(
                {
                    id: vm.serviceRequestId,
                    remarks: input.remark
                }
            ).success(function (data) {
                if (input.printGl === 1) {
                    window.open(data.hospitalRejectionPrintUrl);
                }
                else if (input.printGl === 2) {
                    window.open(data.patientRejectionPrintUrl);
                }

                navigateToListing();
            }).finally(function () {
                vm.loading--;
            });
        }

        function query() {
            if (!vm.isEdit) {
                $state.go('host.doraemonCreateQuery', {
                    letterNumber: vm.guaranteeLetterNumber,
                    serviceRequestId: vm.serviceRequestId
                });
            } else {
                promptEditModeWarning();
            }
        }

        function preview() {
            window.open(vm.printUrl);
        }

        function openApprovalModal() {
            if (!vm.isEdit) {
                $modal.open({
                    templateUrl: require('/App/host/views/doraemon/common/remarks/remarks.modal.html'),
                    controller: 'host.views.doraemon.common.remarksModal as vm',
                    backdrop: 'static',
                    resolve: {
                        title: function () {
                            return App.localize('ApprovalConfirmation');
                        },
                    }
                }).result.then(function (input) {
                    approve(input);
                });
            } else {
                promptEditModeWarning();
            }
        }

        function openIssueNonGLModal() {
            if (!vm.isEdit) {
                $modal.open({
                    templateUrl: require('/App/host/views/doraemon/common/remarks/remarks.modal.html'),
                    controller: 'host.views.doraemon.common.remarksModal as vm',
                    backdrop: 'static',
                    resolve: {
                        title: function () {
                            return App.localize('RejectionConfirmation');
                        },
                    }
                }).result.then(function (input) {
                    reject(input);
                });
            } else {
                promptEditModeWarning();
            }
        }

        function checkEditMode(isEdit) {
            vm.isEdit = isEdit;
        }

        function promptEditModeWarning() {
            abp.message.error(App.localize('SaveOrCancelChangesNarrative'));
        }

        function navigateToListing() {
            $state.go('host.doraemonGl');
        }

        function getRequestDetails(serviceRequestId) {
            vm.loading++;
            doraemonSvc.getIglRequestDetails({
                id: serviceRequestId
            }).success(function (data) {
                vm.guaranteeLetterNumber = data.guaranteeLetterNumber;
                vm.printUrl = data.printUrl
            }).finally(function (e) {
                vm.loading--;
            });
        }

        init();
    }
})();
