(() => {
  angular.module('revenue').component('revenueCorporatesSubscriptions', {
    templateUrl: require('./widget.html'),
    controller: RevenueCorporatesSubscriptionsController,
    controllerAs: 'vm',
    require: {
      container: '^revenueCorporatesContainer',
    },
    bindings: {
      corporate: '<',
      newSubscriptionView: '<',
    },
  });

  RevenueCorporatesSubscriptionsController.$inject = [
    'abp.services.revenue.subscription',
    '$state',
  ];

  function RevenueCorporatesSubscriptionsController(subscriptionSvc, $state) {
    const vm = this;

    vm.view = 'Active';
    vm.loading = 0;
    vm.status = null;
    vm.subscriptions = [];
    vm.permissions = {
      manageSubscriptions: abp.auth.isGranted('Hms.Revenue.Corporate.ManageSubscriptions'),
    };
    vm.hasSubscriptionManagementEnhancement = abp.setting.getBoolean(
      'Hms.Feature.SubscriptionManagementEnhancement'
    );

    vm.$onChanges = $onChanges;
    vm.refreshSubscriptions = refreshSubscriptions;
    vm.viewNewSubscriptionDetails = viewNewSubscriptionDetails;
    vm.viewOldSubscriptionDetails = viewOldSubscriptionDetails;

    function $onChanges(changesObj) {
      if (changesObj.corporate) {
        const corporate = changesObj.corporate.currentValue;
        refreshSubscriptions(corporate);
      }
    }

    function refreshSubscriptions(corporate) {
      vm.subscriptions = [];
      if (corporate) {
        if (vm.newSubscriptionView) {
          vm.loading += 1;
          subscriptionSvc
            .getSubscriptionListing({
              corporateCode: corporate.code,
              status: vm.status,
            })
            .success((data) => {
              vm.subscriptions = data.items;
            })
            .finally(() => {
              vm.loading -= 1;
            });
        } else {
          vm.loading += 1;
          subscriptionSvc
            .getSubscriptions({
              corporateId: corporate.id,
              view: vm.view,
            })
            .success((data) => {
              vm.subscriptions = _.map(data.items, (d) => {
                d.subsidiaryNames =
                  d.scope === 0
                    ? App.localize('All')
                    : _.map(d.subsidiaries, 'subsidiaryName').join(', ');
                return d;
              });
            })
            .finally(() => {
              vm.loading -= 1;
            });
        }
      }
    }

    function viewOldSubscriptionDetails(subscriptionId) {
      $state.go('oldSubscriptionDetails', {
        subscriptionId,
      });
    }

    function viewNewSubscriptionDetails(subscriptionId) {
      $state.go('revenue.subscriptions.detail', {
        subscriptionId,
      });
    }
  }
})();
