(() => {
  angular.module('finance').component('creditMemoDetailedChargesComponent', {
    templateUrl: require('./detailedCharges.component.html'),
    controller: CreditMemoDetailedChargesComponent,
    controllerAs: 'vm',
    bindings: {
      creditMemo: '<',
      getDateString: '<'
    },
  });

  CreditMemoDetailedChargesComponent.$inject = [];

  function CreditMemoDetailedChargesComponent() {
    const vm = this;

    vm.loading = 0;

    init();

    function init() {}
  }
})();
