(function () {
    var controllerId = 'corporate.views.employees.updateStatusModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModal', '$uibModalInstance', 'abp.services.app.employee', 'abp.services.app.commonLookup',
        function ($scope, $modal, $uibModalInstance, employeeSvc, commonLookupSvc) {
            var vm = this;
            vm.saving = 0;
            vm.statusSaved = false;

            vm.cancel = function () {
                $uibModalInstance.close();
            };

            vm.permissions = {
                edit: abp.auth.isGranted('Corporate.Employees.Edit'),
                'delete': abp.auth.isGranted('Corporate.Employees.Delete')
            };

            vm.save = function (action) {
                var message, title;
                title = App.localize('AreYouSure');

                switch (action) {
                    case 0:
                        message = App.localize('ActivateEmployeesConfirmMessage');
                        break;
                    case 1:
                        message = App.localize('DeactivateEmployeesConfirmMessage');
                        break;
                    case 2:
                        title = App.localize('DeleteConfirmation');
                        message = App.localize('DeleteEmployeesConfirmMessage');
                        break;
                }
                abp.message.confirm(message, title, function (d) {
                    if (d) {
                        vm.saving++;
                        employeeSvc.batchStatusUpdate({
                            nationalIds: vm.nationalIds,
                            action: action
                        }).success(function (data) {
                            vm.successCountText = App.localize('XNationalIdsHaveBeenUpdated', data.updatedNationalIds.length);
                            vm.failureCountText = App.localize('BatchStatusUpdateInputHasXIssues', data.missingNationalIds.length);
                            vm.goodNationalIds = data.updatedNationalIds.join('\n');
                            vm.badNationalIds = data.missingNationalIds.join('\n');
                            if (vm.goodNationalIds)
                                abp.notify.success(App.localize('SuccessfullySaved'));
                        }).finally(function () {
                            vm.statusSaved = true;
                            vm.saving--;
                        });
                    }
                });
            };
        }
    ]);
})();
