(() => {
  angular.module('app').component('commonComponentsPreviousAdmissionsComponent', {
    templateUrl: require('./previousAdmissions.component.html'),
    controller: PreviousAdmissionsController,
    controllerAs: 'vm',
    bindings: {},
  });

  PreviousAdmissionsController.$inject = [
    '$scope',
    '$stateParams',
    '$uibModal',
    'abp.services.app.inpatientPatientTicket',
    'moment'
  ];

  function PreviousAdmissionsController(
    $scope,
    $stateParams,
    $uibModal,
    inpatientPatientTicketSvc,
    moment
  ) {
    const vm = this;

    vm.claimNumber = $stateParams.claimNumber;
    vm.loading = 0;
    vm.previousAdmissions = [];
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.hasHospitalizationWaitingPeriodEnhancement = abp.setting.getBoolean(
      'Hms.Feature.HospitalisationWaitingPeriodEnhancement'
    );

    vm.viewExclusionList = viewExclusionList;
    vm.viewScheduleOfBenefits = viewScheduleOfBenefits;
    vm.getDateTimeString = getDateTimeString;

    $scope.$on('employeeId', (evt, data) => {
      getPreviousAdmissions(data);
    });

    init();

    function init() {}

    function getPreviousAdmissions(employeeId) {
      vm.loading += 1;
      inpatientPatientTicketSvc
        .getPreviousAdmissions({
          id: employeeId,
        })
        .success((data) => {
          vm.previousAdmissions = data;
          setDetails();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function viewExclusionList(inpatientSettings) {
      $uibModal.open({
        templateUrl: require('../../../../host/views/employees/exclusionList/exclusionList.modal.html'),
        controller: 'host.views.employees.exclusionListModal as vm',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          inpatientSettings,
        },
      });
    }

    function viewScheduleOfBenefits(serviceType, settings, employeeType) {
      $uibModal.open({
        templateUrl: require('../../../../host/views/employees/scheduleOfBenefits/scheduleOfBenefits.modal.html'),
        controller: 'host.views.employees.scheduleOfBenefitsModal as vm',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          serviceType,
          hospitalizationSetting: settings,
          maternitySetting: settings,
          employeeType,
          countryInfo: {
            countryCode: null,
            currencyCode: null
          }
        },
      });
    }

    function setDetails() {
      vm.isOpen = true;
      _.each(vm.previousAdmissions, (item) => {
        const i = item;

        if (i) {
          i.benefit.isMaternity = i.ticket.serviceType === 8;
          if (i.ticket.serviceType === 7) {
            i.benefit.settings = i.benefit.hospitalizationSetting;
          } else {
            i.benefit.settings = i.benefit.maternitySetting;
          }
        }
      });
    }

    function getDateTimeString(date, timeZoneId) {
      const format = 'D/M/YYYY h:mm:ss A Z';
      if (!timeZoneId) 
        return moment(date).format(format);

      return moment(date).tz(timeZoneId).format(format);
    }
  }
})();
