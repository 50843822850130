(function () {
    'use strict';

    angular.module('app').component('hostInpatientGuaranteeLettersCommonProvisionalDiagnosisComponent', {
        templateUrl: require('/App/host/views/inpatientGuaranteeLetters/common/provisionalDiagnosis/provisionalDiagnosis.component.html'),
        controller: controller,
        controllerAs: 'vm',
        bindings: {
            staticDiagnosis: '<',
            diagnosis: '<',
            isEdit: '<'
        }
    });

    function controller() {
        var vm = this;

        vm.$onChanges = onChanges;
        vm.addDiagnosis = addDiagnosis;
        vm.clearDiagnosis = clearDiagnosis;
        vm.lookupIcd = lookupIcd;
        vm.updateDiagnosis = updateDiagnosis;

        vm.filteredIcds = [];

        var limitSize = 30;

        function onChanges(changes) {
            if (changes.isEdit && changes.isEdit.currentValue) {
                if (vm.diagnosis.selected.length < 1) {
                    addDiagnosis();
                }
            }

            // To add code to description for static diagnosis

            if (changes.staticDiagnosis && changes.staticDiagnosis.currentValue) {
                _.each(vm.staticDiagnosis, function (d) {
                    if (d.code)
                        d.description = "(" + d.code + ") " + d.description;
                })
            }

            if (changes.diagnosis && changes.diagnosis.currentValue) {
                _.each(vm.diagnosis.selected, function (diagnosis) {
                    initDiagnosis(diagnosis);
                });
            }
        }

        function addDiagnosis() {
            vm.diagnosis.selected.push({ date: new Date() });
        }

        function clearDiagnosis() {
            vm.diagnosis.selected = [{ date: new Date() }];
        }

        function initDiagnosis(diagnosis) {
            if (diagnosis.description && _.isNil(diagnosis.code)) {
                diagnosis.code = generateId();
            } else {
                var selectedDiagnosis = _.find(vm.diagnosis.icd, { code: diagnosis.code });
                if (selectedDiagnosis) {
                    diagnosis.id = selectedDiagnosis.id;
                    diagnosis.code = selectedDiagnosis.code;
                    diagnosis.description = selectedDiagnosis.description;
                }
            }
        }

        function updateDiagnosis(diagnosis) {
            var customDiagnosis = _.find(vm.filteredIcds, { code: diagnosis.code });
            if (customDiagnosis) {
                if (diagnosis.code < 0) {
                    diagnosis.code = generateId();
                } else {
                    diagnosis.code = customDiagnosis.code;
                }

                diagnosis.description = customDiagnosis.description;
            }
            else {
                for (let i = 0; i < vm.diagnosis.selected.length; i++) {
                    if (vm.diagnosis.selected[i].code === undefined) {
                        vm.diagnosis.selected[i] = { date: new Date() };
                    }
                }


            }
            getDiagnoses();
        }

        function generateId() {
            // Set custom diagnosis id to negative number of current time.
            // To resolve ng-change where unable to change custom diagnosis,
            // due to diagnosis listing set to -1 for all custom diagnosis

            return -Math.abs(new Date().getTime());
        }

        function lookupIcd(filter) {
            var keyword = _.trim(filter || '');
            if (keyword) {
                // Include free-text entry in filtered ICD set.

                var filteredIcds = [{
                    item: {
                        description: "",
                    },
                    score: 0 // Set free-text entry with high negative score so that it stays as first choice.
                }];

                var regexes = _.chain(keyword.split(' '))
                    .map(function (k) {
                        return _.trim(k.toUpperCase());
                    })
                    .filter(function (k) {
                        return k;
                    })
                    .uniq()
                    .map(function (k) {
                        _.escapeRegExp(k);
                        return {
                            token: k,
                            len: k.length,
                            pattern: new RegExp('\\b' + k, 'i')
                        };
                    })
                    .value();

                _.forEach(vm.diagnosis.icd, function (icd) {
                    var score = 0;

                    // Exclude selected icd.

                    var isSelected = _.find(vm.diagnosis.selected, function (s) {
                        return s.code === icd.code;
                    });

                    if (!isSelected) {
                        _.forEach(regexes, function (r) {
                            if (r.pattern.test(icd.description)) {
                                score -= r.len;
                            }
                        });
                    }

                    if (score) {
                        filteredIcds.push({
                            score: score,
                            item: icd
                        });
                    }
                });

                vm.filteredIcds = _.chain(filteredIcds)
                    .sortBy(['score'])
                    .take(30)
                    .map(function (k) {
                        return k.item;
                    })
                    .uniqBy(function (s) {
                        return s.code;
                    })
                    .value();
            }
            else {
                getDiagnoses();
            }
        }

        function getDiagnoses() {
            var tempIcd = [];
            _.forEach(vm.diagnosis.icd, function (diagnosis) {
                var selectedDiagnosis = _.find(vm.diagnosis.selected, { code: diagnosis.code });
                var staticDiagnosis = _.find(vm.staticDiagnosis, { code: diagnosis.code });

                if (!selectedDiagnosis && !staticDiagnosis) {
                    tempIcd.push(diagnosis);
                }
            });

            tempIcd = _.sortBy(tempIcd, x => x.code);
            vm.filteredIcds = _.take(tempIcd, limitSize);
        }
    }
})();
