(function () {
    var controllerId = 'corporate.views.employeesReport.index';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', '$uibModal', 'abp.services.app.report', 'abp.services.app.commonLookup', 'appSession', 'Hms.Services.ServiceType', 'Hms.BenefitGroups.ModifierType',
        'moment',
        function ($scope, $state, $stateParams, $uibModal, reportSvc, commonLookupSvc, appSession, enumServiceType, enumModifierType, moment) {
            var vm = this;

            function init() {
                initServiceTypes();

                // TODO: Replace currency code after enabling cross-country.
                getTenantCurrencyCode();

                lookupBenefitGroups();
                lookupSubsidiaries();
                lookupCTs();
                findEmployeeFields();
                vm.filterByStates = [{
                    name: 'Active',
                    value: 1
                }, {
                    name: 'Suspended',
                    value: 2
                }, {
                    name: 'Terminated',
                    value: 3
                }]
            }

            var hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');

            if (!hasRegionalSystemSupport) {
                vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
            }
            else {
                commonLookupSvc.getTenantCurrencyCode()
                    .success(function (data) {
                        vm.currencyCode = data;
                    });
            }

            vm.dataExportRecordName = "EmployeesReport";
            vm.generateButtonType = App.localize('ExportToExcel');

            vm.hasSpecialistModule = abp.features.isEnabled('Specialist.Enabled');
            vm.isSubsidiary = appSession.user.subsidiaryId;
            vm.isSubsidiaryDepartment = appSession.user.subsidiaryDepartmentId;
            vm.financialYears = _.range(appSession.tenant.currentFinancialYear, appSession.tenant.firstFinancialYear - 1, -1);
            vm.isDataRender = false;
            vm.loading = 0;
            vm.enqueuing = 0;
            vm.reportSorting = null;
            vm.hasSpecialistModule = abp.features.isEnabled('Specialist.Enabled');
            vm.hasPharmacyModule = abp.features.isEnabled('Pharmacy.Enabled');
            vm.hasInpatientModule = abp.features.isEnabled('HasInpatientModule');
            vm.applicationHasMemberBenefitUtilization = abp.setting.getBoolean('Hms.Feature.MemberBenefitUtilization');
            vm.hasInsuranceModule = abp.setting.getBoolean('Hms.Feature.InsuranceModule')
                && abp.features.isEnabled('HasInsurance');

            vm.enums = {};
            vm.enums.serviceType = enumServiceType;
            vm.enums.modifierType = enumModifierType;

            vm.filteredStates = [];

            $scope.$on("$viewContentLoaded", function () {
                App.initAjax();
            });

            vm.benefitGroups = [];
            function lookupBenefitGroups() {
                commonLookupSvc.findBenefitGroups({
                    maxResultCount: 999
                }).success(function (data) {
                    vm.benefitGroups = data.items;
                });
            }

            vm.subsidiaries = [];

            function lookupSubsidiaries() {
                commonLookupSvc.getCorporateSubsidiaries({ getDeletedSubsidiariesOrDepartment: true }).success(function (data) {
                    vm.subsidiaries = data.items;
                });
            }

            vm.departments = [];
            var allDepartments = [];
            vm.getCorporateSubsidiaryDepartments = function () {
                if (!allDepartments.length) {
                    commonLookupSvc.getCorporateSubsidiaryDepartments({ getDeletedSubsidiariesOrDepartment: true }).success(function (data) {
                        allDepartments = data.items;
                    });
                }
            };

            vm.getDepartments = function (search) {
                var subsidiaryIds = vm.isSubsidiary
                    ? appSession.user.subsidiaryId
                    : vm.requestParams.subsidiaryIds;

                if (subsidiaryIds.length > 0) {
                    vm.departments = [];

                    // Change department filter list according to selected subsidiaries.

                    _.forEach(subsidiaryIds, function (id) {
                        var d = _.filter(allDepartments, { 'subsidiaryId': id });
                        vm.departments = _.concat(vm.departments, d);
                    });
                } else {
                    vm.departments = allDepartments;
                }

                if (search) {
                    vm.departments = _.filter(vm.departments, function (d) {
                        return _.includes(d.name.toLowerCase(), search.toLowerCase())
                    });

                    // Only return top 10 in the department list for display.

                    vm.departments = _.slice(vm.departments, 0, 10);
                } else {
                    vm.departments = [];
                }
            };

            vm.resetDepartments = function () {
                vm.requestParams.departmentIds = [];

                vm.departments = [];
            };

            vm.clinicLocations = [];
            vm.lookupClinicLocations = function (search, id) {
                if (search || id) {
                    commonLookupSvc.findPatientTicketClinicLocations({
                        clinic: vm.requestParams.clinic,
                        id: id,
                        filter: search,
                        maxResultCount: 5
                    }).success(function (data) {
                        vm.clinicLocations = data.items;
                    }).finally(function () {
                        if (!vm.clinicLocations.length)
                            vm.requestParams.clinicLocation = null;
                    });
                }
            };

            vm.isClinic = App.isClinic();

            vm.employeeFields = [];
            function findEmployeeFields() {
                commonLookupSvc.findEmployeeFields().success(function (data) {
                    if (data.items) {
                        vm.employeeFields = data.items;
                    }
                });
            }

            vm.cts = [];
            function lookupCTs() {
                reportSvc.findCTs().success(function (data) {
                    vm.cts = data.items;
                });
            }

            vm.serviceTypes = [];
            function initServiceTypes() {
                reportSvc.getServiceTypes().success(function (data) {
                    vm.serviceTypes = data;
                });
            }

            vm.requestParams = {
                ticketType: $stateParams.ticketType,
                ticketCount: $stateParams.ticketCount,
                ticketCountMin: $stateParams.ticketCountMin,
                ticketCountMax: $stateParams.ticketCountMax,
                mcTakenMin: $stateParams.mcTakenMin,
                mcTakenMax: $stateParams.mcTakenMax,
                coveredAmountMin: $stateParams.coveredAmountMin,
                coveredAmountMax: $stateParams.coveredAmountMax,
                employeeType: $stateParams.employeeType,
                employee: $stateParams.employee,
                nationalId: $stateParams.nationalId,
                employeeNumber: $stateParams.employeeNumber,
                benefitGroupIds: $stateParams.benefitGroupIds,
                subsidiaryIds: $stateParams.subsidiaryIds || [],
                departmentIds: $stateParams.departmentIds || [],
                clinicLocation: $stateParams.clinicLocation,
                clinicType: $stateParams.clinicType,
                financialYear: $stateParams.financialYear || appSession.tenant.currentFinancialYear,
                startDate: $stateParams.startDate,
                endDate: $stateParams.endDate,
                employeePortionMin: $stateParams.employeePortionMin,
                employeePortionMax: $stateParams.employeePortionMax,
                isActive: $stateParams.isActive,
                serviceType: $stateParams.serviceType,
                employeeState: $stateParams.employeeState,
            };
            vm.advancedFiltersAreShown = vm.requestParams.employee || vm.requestParams.ticketCountMin || vm.requestParams.ticketCountMax
                || vm.requestParams.nationalId || vm.requestParams.employeeNumber || vm.requestParams.benefitGroupIds
                || vm.requestParams.subsidiaryIds == [] || vm.requestParams.departmentIds == [];

            vm.dateRangeOptions = _.map(vm.financialYears, function (d) {
                var opt = App.createDateRangePickerOptions();
                var minDate = moment([d, appSession.tenant.firstFinancialMonth - 1, 1]);
                var maxDate = moment.min(moment([d + 1, appSession.tenant.firstFinancialMonth - 1, 1]).subtract(1, 'days'), moment(opt.maxDate));
                opt.minDate = moment(minDate);
                opt.min = moment(minDate);
                opt.maxDate = moment(maxDate);
                opt.max = moment(maxDate);
                opt.financialYear = d;
                return opt;
            });

            vm.dateRangeModel = {};

            vm.fyChanged = function () {
                if (!_.isNil(vm.requestParams.financialYear)) {
                    var opt = _.find(vm.dateRangeOptions, { 'financialYear': vm.requestParams.financialYear });
                    vm.dateRangeModel.startDate = moment(opt.minDate);
                    vm.dateRangeModel.endDate = moment(opt.maxDate);
                }
            };

            vm.fyChanged();

            vm.localizeCycle = function (cycle, interval) {
                return App.localize(cycle, interval);
            };

            if ($stateParams.startDate || $stateParams.endDate) {
                var opt = _.find(vm.dateRangeOptions, { 'financialYear': vm.requestParams.financialYear });
                if ($stateParams.startDate) {
                    vm.dateRangeModel.startDate = moment.max(moment.min(moment($stateParams.startDate), opt.maxDate), opt.minDate);
                }
                if ($stateParams.endDate) {
                    vm.dateRangeModel.endDate = moment.min(moment.max(moment($stateParams.endDate), opt.minDate), opt.maxDate);
                }
            }

            vm.generate = function () {
                registerStateParams();
                vm.loading++;
                reportSvc.getEmployeesReportEmployeeCount(getRequestInput()).success(function (data) {
                    data < 51 ? vm.getEmployeesReport() : vm.exportToExcel();
                    vm.isDataRender = data < 51;
                }).finally(function (e) {
                    vm.loading--;
                });
            };

            var reportTemplateName = '';
            var reportFields = [];
            vm.getEmployeesReport = function () {
                vm.loading++;
                reportSvc.getEmployeesReport(getRequestInput()).success(function (data) {
                    vm.data = data.items;
                    _.each(vm.data, function (d) {
                        var extendedFields = _.map(d.extendedFields, function (f) { return f.value; });
                        var fieldInfo = _.concat(d.subsidiaryName, d.subsidiaryDepartmentName, extendedFields, d.benefitGroupName);
                        d.fieldInfo = _.join(_.filter(fieldInfo, function (fi) { return fi; }), ' | ');
                    });
                    reportTemplateName = data.reportTemplateName;
                    reportFields = data.reportFields;
                }).finally(function (e) {
                    vm.loading--;
                });
            };

            function getEmployeeFieldFilters() {
                var employeeFieldFilters = [];
                _.each(vm.employeeFields, function (f) {
                    if (f.filter) {
                        var filter = _.trim(f.filter);
                        if (filter) {
                            employeeFieldFilters.push({
                                name: filter,
                                value: f.value
                            });
                        }
                    }
                });
                return { employeeFields: employeeFieldFilters };
            }

            function getRequestInput(selectedFields) {
                var input = $.extend({}, vm.requestParams, vm.dateRangeModel, getEmployeeFieldFilters(), { reportFields: selectedFields });
                input.startDate = vm.dateRangeModel.startDate.format('YYYY-MM-DD');
                input.endDate = vm.dateRangeModel.endDate.format('YYYY-MM-DD');
                input.sorting = vm.reportSorting;
                input.states = vm.filteredStates;
                return _.omitBy(input, _.isNil);
            }

            function registerStateParams() {
                var startDate = null;
                var endDate = null;
                if (vm.dateRangeModel.startDate)
                    startDate = vm.dateRangeModel.startDate.format('YYYY-MM-DD');
                if (vm.dateRangeModel.endDate)
                    endDate = vm.dateRangeModel.endDate.format('YYYY-MM-DD');

                $state.transitionTo($state.current,
                    {
                        ticketType: vm.requestParams.ticketType,
                        ticketCount: vm.requestParams.ticketCount,
                        ticketCountMin: vm.requestParams.ticketCountMin,
                        ticketCountMax: vm.requestParams.ticketCountMax,
                        mcTakenMin: vm.requestParams.mcTakenMin,
                        mcTakenMax: vm.requestParams.mcTakenMax,
                        coveredAmountMin: vm.requestParams.coveredAmountMin,
                        coveredAmountMax: vm.requestParams.coveredAmountMax,
                        employeeType: vm.requestParams.employeeType,
                        clinicLocation: vm.requestParams.clinicLocation,
                        employee: vm.requestParams.employee,
                        nationalId: vm.requestParams.nationalId,
                        employeeNumber: vm.requestParams.employeeNumber,
                        benefitGroupIds: vm.requestParams.benefitGroupIds,
                        subsidiaryIds: vm.requestParams.subsidiaryIds,
                        departmentIds: vm.requestParams.departmentIds,
                        clinicType: vm.requestParams.clinicType,
                        financialYear: vm.requestParams.financialYear,
                        startDate: startDate,
                        endDate: endDate,
                        employeePortionMin: vm.requestParams.employeePortionMin,
                        employeePortionMax: vm.requestParams.employeePortionMax,
                        isActive: vm.requestParams.isActive,
                        serviceType: vm.requestParams.serviceType
                    }, {
                    notify: false
                });
            }

            vm.exportToExcel = function (option) {
                option = option || getRequestInput();

                vm.enqueuing++;
                reportSvc.enqueueGenerateEmployeesExcelReportJob(option).success(function (data) {
                    vm.refresh();
                }).finally(function () {
                    vm.enqueuing--;
                });
            };

            vm.export = function (type) {
                $uibModal.open({
                    templateUrl: require('/App/common/views/common/selectionModal.html'),
                    controller: "common.views.common.selectionModal as vm",
                    backdrop: "static",
                    resolve: {
                        templateName: function () {
                            return reportTemplateName;
                        },
                        fields: function () {
                            return reportFields;
                        },
                        modalTitle: function () {
                            return App.localize('SelectReportFields');
                        }
                    }
                }).result.then(function (selectedFields) {
                    var option = getRequestInput(selectedFields);

                    if (type === 0)
                        window.open(App.formatUrl('Print/EmployeesReport?', option));
                    else
                        vm.exportToExcel(option);
                });
            };

            function getTenantCurrencyCode() {
                vm.loading++;
                commonLookupSvc.getTenantCurrencyCode()
                    .success(function (data) {
                        vm.currencyCode = data;
                    }).finally(function () {
                        vm.loading--;
                    })
            }

            init();
        }
    ]);
})();
