(function () {
    var controllerId = 'host.views.corporates.documentModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'abp.services.app.corporate', 'corporateId',
        function ($scope, $uibModalInstance, corporateSvc, corporateId) {
            var vm = this;
            vm.submitting = 0;
            vm.saving = false;
            vm.save = function () {
                vm.saving = true;
                vm.submitting++;
                corporateSvc.uploadDocument({
                    id: corporateId,
                    newAttachments: vm.newAttachments
                }).then(function () {
                    abp.notify.info(App.localize("SuccessfullySaved"));
                    $uibModalInstance.close();
                }).finally(function () {
                    vm.saving = false;
                    vm.submitting--;
                });
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            vm.newAttachments = [];
            vm.uploadConfig = {
                objectType: 'CorporateDocument'
            };

            vm.isInvalidDocument = function () {
                return !vm.newAttachments.length;
            };
        }
    ]);
})();