(function () {
    'use strict';

    var app = angular.module('app').component('hostOperationsDashboardLateSubmissionsPerformanceComponent', {
        templateUrl: require('/App/host/views/operationsDashboard/lateSubmissions/performance/performance.component.html'),
        controller: ['$timeout', '$element', 'abp.services.app.operationsDashboard', 'moment', controller],
        controllerAs: 'vm',
        bindings: {
            isOpen: '<',
            isRefresh: '<',
            countryCode: '=?'
        }
    });

    function controller($timeout, $element, operationsDashboardSvc, moment) {
        function init() {
            getLateSubmissionPerformancePeriod();
        }

        var vm = this;

        vm.$onChanges = onChanges;
        vm.periodChanged = periodChanged;
        vm.chart = null;
        vm.periods = [];
        vm.selectedPeriod = null;
        vm.periodStats = {
            weekdays: 0,
            weekends: 0
        };
        vm.countryCode = null;

        function onChanges(changes) {
            if (changes.isOpen && changes.isOpen.currentValue) {
                $timeout(function () {
                    if (vm.chart) {
                        vm.chart.resize();
                    }
                });
            }
            if (changes.isRefresh) {
                vm.periodChanged();
            }
        }

        function getLateSubmissionPerformance() {
            vm.loading++;
            operationsDashboardSvc.getLateSubmissionPerformance({
                monthYear: vm.selectedPeriod,
                countryCode: vm.countryCode
            }).success(function (data) {
                vm.dataset = data;
                generateGraph();
            }).finally(function () {
                vm.loading--;
            });
        }

        function getLateSubmissionPerformancePeriod() {
            vm.loading++;
            operationsDashboardSvc.getLateSubmissionPerformancePeriod(
            ).success(function (data) {
                // If there is no period, use current month as default.

                if (_.isEmpty(data.period)) {
                    vm.periods.push({ name: moment().format('MMM YYYY'), value: moment().toISOString() });
                } else {
                    _.each(data.period, function (date) {
                        vm.periods.push({ name: moment(date).format('MMM YYYY'), value: moment(date).toISOString() });
                    });
                }

                vm.selectedPeriod = vm.periods[0].value;
                periodChanged();
            }).finally(function () {
                vm.loading--;
            });
        }

        function generateGraph() {
            var labels = ['x'];
            var unresolvedCount = ['Unresolved'];
            var approvedCount = ['Approved'];
            var rejectedCount = ['Rejected'];
            var delegatedCount = ['Delegated'];
            var totalCount = ['Total'];
            var total = 0;
            _.each(vm.dataset.figures, function (f) {
                labels.push(moment(f.date).format('YYYY-MM-DD'));
                _.each(f.figures, function (fx) {
                    var ss = '';
                });
                unresolvedCount.push(f.figures.pendingApproval);
                approvedCount.push(f.figures.approved);
                rejectedCount.push(f.figures.rejected);
                delegatedCount.push(f.figures.delegated);

                total += f.figures.pendingApproval;
                total += f.figures.approved;
                total += f.figures.rejected;
                total += f.figures.delegated;
                totalCount.push(total);
            });

            vm.chart = c3.generate({
                bindto: $element.find('.chart-container')[0],
                size: {
                    height: 280
                },
                data: {
                    x: 'x',
                    columns: [
                        labels, unresolvedCount, rejectedCount, delegatedCount, approvedCount, totalCount
                    ],
                    names: {
                        x: 'Month',
                        unresolvedCount: 'Unresolved',
                        approvedCount: 'Approved',
                        rejectedCount: 'Rejected',
                        delegatedCount: 'Delegated',
                        totalCount: 'Total'
                    },
                    types: {
                        Unresolved: 'bar',
                        Approved: 'bar',
                        Rejected: 'bar',
                        Delegated: 'bar',
                        Total: 'spline'
                    },
                    colors: {
                        Unresolved: '#1a7bb9',
                        Approved: '#1ab394',
                        Rejected: '#ed5565',
                        Delegated: '#ffd000',
                        Total: '#444444'
                    },
                    groups: [
                        ['Approved', 'Rejected', 'Delegated']
                    ],
                    order: null
                },
                axis: {
                    x: {
                        type: 'timeseries',
                        tick: {
                            format: function (x) { return moment(x).format('D (dd)'); }
                        }
                    }
                }
            });
        }

        function periodChanged() {
            if (!_.isNil(vm.selectedPeriod)) {
                var weekdays = 0, weekends = 0;
                var dtCur = moment(vm.selectedPeriod);
                var dtNext = moment(dtCur).add(1, 'M');
                while (dtCur < dtNext) {
                    var wd = dtCur.isoWeekday();
                    if (wd === 6 || wd === 7) weekends++;
                    else weekdays++;
                    dtCur.add(1, 'd');
                }
                vm.periodStats.weekdays = weekdays;
                vm.periodStats.weekends = weekends;
                getLateSubmissionPerformance();
            }
        }

        init();
    }
})();
