(function () {
    var controllerId = 'host.views.corporates.masterPolicies.masterPolicy.editInsurerTeam.modal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$uibModalInstance', '$state', 'masterPolicy', 'abp.services.app.commonLookup', 'abp.services.app.masterPolicy',
        function ($uibModalInstance, $state, masterPolicy, commonLookupSvc, masterPolicySvc) {
            function init() {
                // Insurer team options
                vm.loading++;
                commonLookupSvc.getInsurerTeamsBasedOnInsurer(masterPolicy.insurerId)
                    .success(function (data) {
                        vm.insurerTeams = data;
                    }).finally(function () {
                        vm.loading--;
                    });
            }

            var vm = this;
            vm.loading = 0;
            vm.saving = false;
            vm.masterPolicyNumber = masterPolicy.masterPolicyNumber;
            vm.insurerTeamId = masterPolicy.insurerTeamId;

            vm.save = function () {
                vm.saving = true;
                masterPolicySvc.editMasterPolicy({
                    masterPolicyId: masterPolicy.id,
                    insurerTeamId: vm.insurerTeamId
                }).then(function () {
                    abp.notify.info(App.localize("SuccessfullySaved"));
                    $uibModalInstance.close();
                }).finally(function () {
                    vm.saving = false;
                    $state.reload();
                });
            }

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            init();
        }
    ]);
})();
