(() => {
  angular
    .module('app')
    .controller('common.views.modals.notifyRecipientModal', NotifyRecipientModalController);

  NotifyRecipientModalController.$inject = ['$uibModalInstance', 'title', 'users', 'message'];

  function NotifyRecipientModalController($uibModalInstance, title, users, message) {
    const vm = this;

    vm.remarks = null;
    vm.title = title;
    vm.users = users;
    vm.recipient = null;
    vm.message = message;

    vm.cancel = cancel;
    vm.submit = submit;

    init();

    function init() {}

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function submit() {
      if (App.isFormValid(vm.modalForm)) {
        $uibModalInstance.close({
          recipient: vm.recipient,
          remarks: vm.remarks,
        });
      }
    }
  }
})();
