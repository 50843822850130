(() => {
  angular
    .module('app')
    .controller('host.views.corporates.subsidiaries', HostCorporateSubsidiariesController);

  HostCorporateSubsidiariesController.$inject = [
    '$state',
    '$stateParams',
    '$uibModal',
    'abp.services.app.corporate',
    'abp.services.app.subsidiary',
    'abp.services.app.saleTransaction',
    'moment',
    'Hms.Finance.TransactionTenantType',
  ];

  function HostCorporateSubsidiariesController(
    $state,
    $stateParams,
    $modal,
    corporateSvc,
    subsidiarySvc,
    saleTransactionSvc,
    moment,
    enumTransactionTenantType
  ) {
    const vm = this;
    vm.loading = false;
    vm.corporateId = $stateParams.id;
    vm.corporateTenancyName = $stateParams.tenancyName;
    vm.virtualAccountGenerationMode = $stateParams.virtualAccountGenerationMode;
    vm.corporateFullName = '';
    vm.hidePageHeading = $state.is('host.corporates.subsidiaries');
    vm.permissions = {
      saleTransactions: abp.auth.isGranted('SaleTransactions'),
      manage: abp.auth.isGranted('Host.Corporates.Subsidiaries.Manage'),
      viewSubscriptions: abp.auth.isGranted('Host.Corporates.Subsidiaries.Subscriptions'),
      createManualTransaction: abp.auth.isGranted('HealthMetrics.Finance.ManualTransactionRequests.Create'),
    };
    vm.enums = {
      transactionTenantType: enumTransactionTenantType,
    };

    const zendeskSubdomain = abp.setting.get('Hms.Integration.Zendesk.Subdomain');
    vm.hasZendeskIntegration = zendeskSubdomain && zendeskSubdomain !== '';

    vm.subsidiaryGridOptions = {
      appScopeProvider: vm,
      columnDefs: [
        {
          enableSorting: false,
          name: App.localize('Actions'),
          width: 120,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'subsidiaries.actionTemplate',
        },
        {
          displayName: App.localize('Name'),
          field: 'name',
          minWidth: 120,
          cellTemplate: 'subsidiaries.nameTemplate',
        },
        {
          displayName: App.localize('IsActive'),
          field: 'isActive',
          cellTemplate: 'subsidiaries.activeTemplate',
          minWidth: 65,
        },
        {
          displayName: App.localize('Subscription'),
          field: 'subscription',
          cellTemplate: 'subsidiaries.subscriptionPlanTemplate',
          minWidth: 65,
        },
        {
          displayName: App.localize('VirtualAccount'),
          field: 'subsidiaryVirtualAccountNumber',
          minWidth: 130,
        },
        {
          displayName: App.localize('CreationDate'),
          field: 'creationTime',
          cellFilter: "momentFormat: 'L'",
          minWidth: 100,
        },
        {
          displayName: App.localize('BlanketConsented'),
          field: 'hasEmployeeDataConsented',
          minWidth: 100,
          cellTemplate: 'subsidiaries.blanketConsentedTemplate',
        },
        {
          displayName: App.localize('ConsentedEmployees'),
          field: 'totalConsentedEmployee',
          minWidth: 100,
          cellTemplate: 'subsidiaries.consentedEmployeeTemplate',
        },
      ],
      data: [],
    };

    vm.getSubsidiaries = getSubsidiaries;
    vm.createSubsidiary = createSubsidiary;
    vm.editSubsidiary = editSubsidiary;
    vm.updateLogo = updateLogo;
    vm.sendOpenItemStatement = sendOpenItemStatement;
    vm.generateStatement = generateStatement;
    vm.initializeZendeskOrganization = initializeZendeskOrganization;
    vm.checkSubsidiaryOutstandingTicketAndInvoices = checkSubsidiaryOutstandingTicketAndInvoices;
    vm.deleteSubsidiary = deleteSubsidiary;
    vm.createManualTransactionRequest = createManualTransactionRequest;

    init();

    function init() {
      if (vm.corporateId) {
        vm.loading = true;
        corporateSvc
          .getCorporateForEdit({
            id: vm.corporateId,
          })
          .success((n) => {
            vm.corporateFullName = `${n.properties.name} (${n.properties.tenancyName})`;
          })
          .finally(() => {
            vm.loading = false;
          });
        getSubsidiaries();
      } else {
        $state.go('host.corporates');
      }
    }

    function getSubsidiaries() {
      vm.loading = true;
      subsidiarySvc
        .getSubsidiaries({
          id: vm.corporateId,
        })
        .success((data) => {
          _.forEach(data.items, (d) => {
            d.subscription = _.chain(d.subscriptions)
              .map((s) => {
                let desc = s.name;
                if (s.startDate && s.endDate) {
                  desc += `, ${moment(s.startDate).format('L')} - ${moment(s.endDate).format('L')}`;
                } else if (s.startDate) {
                  desc += `, ${App.localize('FromX', moment(s.startDate).format('L'))}`;
                }
                return desc;
              })
              .join(' | ')
              .value();
          });
          vm.subsidiaryGridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading = false;
        });
    }

    function createSubsidiary() {
      showEditModal();
    }

    function editSubsidiary(subsidiary) {
      showEditModal(subsidiary.id);
    }

    function showEditModal(id) {
      const modal = $modal.open({
        templateUrl: require('./createOrEditSubsidiaryModal.html'),
        controller: 'host.views.corporates.createOrEditSubsidiaryModal as vm',
        backdrop: 'static',
        resolve: {
          corporateId() {
            return vm.corporateId;
          },
          subsidiaryId() {
            return id;
          },
        },
      });
      modal.result.then(() => {
        getSubsidiaries();
      });
    }

    function updateLogo(subsidiary) {
      $modal
        .open({
          templateUrl: require('./updateLogoModal.html'),
          controller: 'host.views.corporates.updateLogoModal as vm',
          backdrop: 'static',
          resolve: {
            corporateId() {
              return subsidiary.tenantId;
            },
            subsidiaryId() {
              return subsidiary.id;
            },
          },
        })
        .result.then(() => {
          vm.getSubsidiaries();
        });
    }

    function sendOpenItemStatement(subsidiary) {
      saleTransactionSvc
        .sendSubsidiaryOpenItemStatement({
          tenantId: subsidiary.tenantId,
          subsidiaryId: subsidiary.id,
          inverseSymbol: true,
          isSubsidiary: true,
        })
        .success(() => {
          abp.notify.success(App.localize('SuccessfullySent'));
        })
        .finally(() => {
          vm.getSubsidiaries();
        });
    }

    function generateStatement(subsidiary) {
      const modal = $modal.open({
        templateUrl: require('../../../common/views/saleTransactions/createStatementModal.html'),
        controller: 'common.views.saleTransactions.createStatementModal as vm',
        backdrop: 'static',
        resolve: {
          tenantId() {
            return parseInt(vm.corporateId, 10);
          },
          subsidiaryId() {
            return subsidiary.id;
          },
        },
      });
      modal.result.then((input) => {
        $state.go('statement', input);
      });
    }

    function initializeZendeskOrganization(subsidiary) {
      abp.message.confirm(
        App.localize('InitializeZendeskOrganizationWarningMessage', subsidiary.name),
        App.localize('AreYouSure'),
        (result) => {
          if (result) {
            subsidiarySvc
              .initializeZendeskOrganization({
                id: subsidiary.id,
              })
              .success(() => {
                vm.getSubsidiaries();
                abp.notify.success(App.localize('InitializeZendeskOrganizationRequestSent'));
              });
          }
        }
      );
    }

    function checkSubsidiaryOutstandingTicketAndInvoices(subsidiary) {
      // Check if subsidiary has unbilled tickets/outstanding invoices

      subsidiarySvc.checkSubsidiaryOutstandingTicketsAndInvoices(subsidiary.id).success((data) => {
        if (data === true) {
          abp.message.warn(App.localize('SubsidiaryHasOutstandingTicketsOrInvoicesWarning'));
        } else {
          deleteSubsidiary(subsidiary.id, subsidiary.name);
        }
      });
    }

    function deleteSubsidiary(subsidiaryId, subsidiaryName) {
      abp.message.confirm(
        App.localize('SubsidiaryDeleteWarningMessage', subsidiaryName),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            subsidiarySvc
              .deleteSubsidiary({
                id: subsidiaryId,
              })
              .success(() => {
                vm.getSubsidiaries();
                abp.notify.success(App.localize('SuccessfullyDeleted'));
              });
          }
        }
      );
    }

    function createManualTransactionRequest(subsidiaryId) {
      $state.go('finance.createEditManualTransactionRequest', {
        isCreate: true,
        tenancyName: vm.corporateTenancyName,
        transactionTenantType: vm.enums.transactionTenantType.Organization.id,
        isBilledPerSubsidiary: vm.virtualAccountGenerationMode === 2,
        subsidiaryId,
      });
    }
  }
})();
