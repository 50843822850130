(() => {
  angular.module('app').directive('convertToBoolean', convertToBooleanDirective);

  convertToBooleanDirective.$inject = [];

  function convertToBooleanDirective() {
    const directive = {
      link,
      restrict: 'EA',
      require: 'ngModel',
    };
    return directive;

    function link(_scope, _element, _attrs, ngModel) {
      ngModel.$parsers.push((val) => {
        if (val == null || val === '') return null;
        return val === 'true';
      });
      ngModel.$formatters.push((val) => (val == null ? '' : `${val}`));
    }
  }
})();
