(() => {
  angular
    .module('app')
    .controller('common.views.patientAdmissionTimeline.requestDetailsModal', RequestDetailsModalController);

  RequestDetailsModalController.$inject = [
    '$uibModalInstance',
    'abp.services.app.hostServiceRequest',
    'serviceRequestId',
    'moment',
    'currencyCode',
    'ianaTimeZoneId'
  ];

  function RequestDetailsModalController(
    $uibModalInstance,
    hostServiceRequestSvc,
    serviceRequestId,
    moment,
    currencyCode,
    ianaTimeZoneId
  ) {
    const vm = this;

    vm.loading = 0;
    vm.currencyCode = currencyCode;
    vm.timeZoneId = ianaTimeZoneId;

    vm.close = close;
    vm.getDateTimeString = getDateTimeString;

    init();

    function init() {
      getRequestInfo();
    }

    function getRequestInfo() {
      vm.loading += 1;
      hostServiceRequestSvc
        .getServiceRequestInfo({
          id: serviceRequestId,
        })
        .success((data) => {
          vm.requestInfo = data;
          vm.documents = _.map(data.supportingDocuments, (d) => ({
            externalObjectId: d.externalObjectId,
            url: d.url,
            name: d.name,
            uploader: `${d.uploader} ${vm.getDateTimeString(d.uploadedTime, vm.timeZoneId)}`,
            applied: d.isApplied,
            state: d.state,
            isEditable: false,
          }));
          vm.diagnosis = _.map(data.provisionalDiagnosis, (p) => ({
            code: p.code,
            description: p.description,
            displayDate: p.displayDiagnosisDate,
            date: p.diagnosisDate,
          }));
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function close() {
      $uibModalInstance.close();
    }

    function getDateTimeString(date, timeZoneId) {
      return App.getDateTimeString(date, timeZoneId);
    }
  }
})();
