(function () {
    'use strict';

    angular.module('app').component('hostCorporatesSupportPlansCreateEditScheduleOfBenefitHospitalChargesComponent', {
        templateUrl: require('/App/host/views/corporates/supportPlans/createEdit/scheduleOfBenefit/hospitalCharges/hospitalCharges.component.html'),
        controller: controller,
        controllerAs: 'vm',
        bindings: {
            scheduleOfBenefit: '<',
            currencyCode: '<'
        }
    });

    function controller() {
        var vm = this;

        vm.asCharged13thScheduleText = App.localize('AsCharged13thSchedule');
        vm.asChargedText = App.localize('AsCharged');
        vm.inHospitalText = App.localize('InHospitalPhysicianVisit');
        vm.admissionChargesText = App.localize('AdmissionChargesNarrative');
        vm.ambulanceFeeText = App.localize('AmbulanceFee');
        vm.organTransplantText = App.localize('OrganTransplant');
        vm.hasScheduleOfBenefitEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');
        vm.surgicalFeeAmountType = null;
        vm.anaesthetistFeeAmountType = null;
        vm.operatingTheatreAmountType = null;
        vm.hospitalSuppliesAndServicesAmountType = null;
        vm.ambulanceFeeAmountType = null;
        vm.organTransplantAmountType = null;
        vm.organTransplantMaxDaysTimesType = null;
        vm.isFixedLimit = null;

        vm.toggleAdmissionCharges = function () {
            var isFixedLimit = vm.scheduleOfBenefit.hospitalCharges.isFixedLimit;
            vm.minFixedLimitAmount = isFixedLimit ? 1 : 0;
            vm.minSurgicalFeeAmount = !isFixedLimit && vm.surgicalFeeAmountType === 1 ? 1 : 0;
            vm.minAnaesthetistFeeAmount = !isFixedLimit && vm.anaesthetistFeeAmountType === 1 ? 1 : 0;
            vm.minOperatingTheatreAmount = !isFixedLimit && vm.operatingTheatreAmountType === 1 ? 1 : 0;
            vm.minHospitalSuppliesAndServicesAmount = !isFixedLimit && vm.hospitalSuppliesAndServicesAmountType === 1 ? 1 : 0;
            vm.minAmbulanceFeeAmount = vm.ambulanceFeeAmountType === 1 ? 1 : 0;
            vm.minOrganTransplantAmount = vm.organTransplantAmountType === 1 ? 1 : 0;
            vm.minOrganTransplantCoveredTimes = vm.organTransplantAmountType === 2
                || (vm.organTransplantAmountType !== 2 && vm.organTransplantMaxDaysTimesType === 1) ? 0 : 1;
            vm.scheduleOfBenefit.hospitalCharges.isFixedLimit = vm.isFixedLimit === 1 ? true : false;

            setSurgicalFeeAmountType();
            setAnaesthetistFeeAmountType();
            setOperatingTheatreAmountType();
            setHospitalSuppliesAndServicesAmountType();
            setAmbulanceFeeAmountType();
            setOrganTransplantAmountType();
            setOrganTransplantMaxDaysTimesType();
        };

        function init() {
            initializeAmountTypes()
            vm.toggleAdmissionCharges();
        }

        function initializeAmountTypes() {
            vm.isFixedLimit = vm.scheduleOfBenefit.hospitalCharges.isFixedLimit ? 1 : 0;
            vm.surgicalFeeAmountType = vm.scheduleOfBenefit.hospitalCharges.surgicalFee.isAsCharged ? 0 : 1;
            vm.anaesthetistFeeAmountType = vm.scheduleOfBenefit.hospitalCharges.anaesthetistFee.isAsCharged ? 0 : 1;
            vm.operatingTheatreAmountType = vm.scheduleOfBenefit.hospitalCharges.operatingTheatre.isAsCharged ? 0 : 1;
            vm.hospitalSuppliesAndServicesAmountType = vm.scheduleOfBenefit.hospitalCharges.hospitalSuppliesAndServices.isAsCharged ? 0 : 1;

            // Ambulance Fee
            if (!vm.scheduleOfBenefit.ambulanceFee.isCovered)
                vm.ambulanceFeeAmountType = 2;
            else if (vm.scheduleOfBenefit.ambulanceFee.isAsCharged)
                vm.ambulanceFeeAmountType = 0;
            else if (!vm.scheduleOfBenefit.ambulanceFee.isAsCharged)
                vm.ambulanceFeeAmountType = 1;

            // Organ Transplant
            if (!vm.scheduleOfBenefit.organTransplant.isCovered)
                vm.organTransplantAmountType = 2;
            else if (vm.scheduleOfBenefit.organTransplant.isAsCharged)
                vm.organTransplantAmountType = 0;
            else if (!vm.scheduleOfBenefit.organTransplant.isAsCharged)
                vm.organTransplantAmountType = 1;

            vm.organTransplantMaxDaysTimesType = vm.scheduleOfBenefit.organTransplant.isUnlimited ? 1 : 0;
        }

        function setSurgicalFeeAmountType() {
            switch (vm.surgicalFeeAmountType) {
                case 0:
                    vm.scheduleOfBenefit.hospitalCharges.surgicalFee.isAsCharged = true;
                    break;

                case 1:
                    vm.scheduleOfBenefit.hospitalCharges.surgicalFee.isAsCharged = false;
                    break;

                default:
                    break;
            }
        }

        function setAnaesthetistFeeAmountType() {
            switch (vm.anaesthetistFeeAmountType) {
                case 0:
                    vm.scheduleOfBenefit.hospitalCharges.anaesthetistFee.isAsCharged = true;
                    break;

                case 1:
                    vm.scheduleOfBenefit.hospitalCharges.anaesthetistFee.isAsCharged = false;
                    break;

                default:
                    break;
            }
        }

        function setOperatingTheatreAmountType() {
            switch (vm.operatingTheatreAmountType) {
                case 0:
                    vm.scheduleOfBenefit.hospitalCharges.operatingTheatre.isAsCharged = true;
                    break;

                case 1:
                    vm.scheduleOfBenefit.hospitalCharges.operatingTheatre.isAsCharged = false;
                    break;

                default:
                    break;
            }
        }

        function setHospitalSuppliesAndServicesAmountType() {
            switch (vm.hospitalSuppliesAndServicesAmountType) {
                case 0:
                    vm.scheduleOfBenefit.hospitalCharges.hospitalSuppliesAndServices.isAsCharged = true;
                    break;

                case 1:
                    vm.scheduleOfBenefit.hospitalCharges.hospitalSuppliesAndServices.isAsCharged = false;
                    break;

                default:
                    break;
            }
        }

        function setAmbulanceFeeAmountType() {
            switch (vm.ambulanceFeeAmountType) {
                case 0:
                    vm.scheduleOfBenefit.ambulanceFee.isAsCharged = true;
                    vm.scheduleOfBenefit.ambulanceFee.isCovered = true;
                    break;

                case 1:
                    vm.scheduleOfBenefit.ambulanceFee.isAsCharged = false;
                    vm.scheduleOfBenefit.ambulanceFee.isCovered = true;
                    break;

                case 2:
                    vm.scheduleOfBenefit.ambulanceFee.isAsCharged = false;
                    vm.scheduleOfBenefit.ambulanceFee.isCovered = false;

                default:
                    break;
            }
        }

        function setOrganTransplantAmountType() {
            switch (vm.organTransplantAmountType) {
                case 0:
                    vm.scheduleOfBenefit.organTransplant.isAsCharged = true;
                    vm.scheduleOfBenefit.organTransplant.isCovered = true;
                    break;

                case 1:
                    vm.scheduleOfBenefit.organTransplant.isAsCharged = false;
                    vm.scheduleOfBenefit.organTransplant.isCovered = true;
                    break;

                case 2:
                    vm.scheduleOfBenefit.organTransplant.isAsCharged = false;
                    vm.scheduleOfBenefit.organTransplant.isCovered = false;

                default:
                    break;
            }
        }

        function setOrganTransplantMaxDaysTimesType() {
            switch (vm.organTransplantMaxDaysTimesType) {
                case 0:
                    vm.scheduleOfBenefit.organTransplant.isUnlimited = false;
                    break;

                case 1:
                    vm.scheduleOfBenefit.organTransplant.isUnlimited = true;
                    break;

                default:
                    break;
            }
        }

        angular.element(document).ready(function () {
            init();
        });
    }
})();
