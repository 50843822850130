(() => {
  angular.module('app').component('commonGuaranteeLettersDetailsRecentPatientTicketsWidget', {
    templateUrl: require('./widget.html'),
    controller: RecentPatientTicketsWidgetController,
    controllerAs: 'vm',
    bindings: {},
  });

  RecentPatientTicketsWidgetController.$inject = [
    '$scope',
    '$stateParams',
    'abp.services.app.guaranteeLetter',
  ];

  function RecentPatientTicketsWidgetController($scope, $stateParams, guaranteeLetterSvc) {
    const vm = this;

    vm.loading = 0;

    init();

    function init() {
      getRecentPatientTickets();
    }

    function getRecentPatientTickets() {
      vm.loading+=1;
      guaranteeLetterSvc
        .getRecentPatientTickets({
          id: $stateParams.letterNumber,
        })
        .success((data) => {
          vm.tickets = data.items;
          vm.ticketCount = vm.tickets.length;
        })
        .finally(() => {
          vm.loading-=1;
        });
    }
  }
})();
