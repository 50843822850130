(function () {
  'use strict';

  const app = angular.module('app');
    app.controller('host.views.inpatientGuaranteeLetters.topupAddonProcessing', ['$state', '$stateParams', '$uibModal',
      'abp.services.app.hostServiceRequest', 'abp.services.app.commonLookup', controller
    ]);

  function controller($state, $stateParams, $modal, hostServiceRequestSvc, commonLookupSvc) {
    var vm = this;

    vm.openApprovalModal = openApprovalModal;
    vm.openRejectionModal = openRejectionModal;
    vm.openDelegationModal = openDelegationModal;
    vm.openRecommendationModal = openRecommendationModal;
    vm.openGlRejectionModal = openGlRejectionModal;
    vm.query = query;
    vm.preview = preview;
    vm.checkEditMode = checkEditMode;
    vm.checkSaveTrigger = checkSaveTrigger;
    vm.openEscalationModal = openEscalationModal;

    vm.loading = 0;
    vm.isEdit = false;
    vm.letterNumber = $stateParams.letterNumber;
    vm.requestId = $stateParams.requestId;
    vm.memberId = $stateParams.memberId;
    vm.isOnHold = true;
    vm.requestType = "";
    vm.requestStatus = "";
    vm.requestDetailsTrigger = 0;
    vm.newGuaranteeAmount = 0;
    vm.isInsuranceBenefitGroup = false;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

    var delegateUsers = [];
    var recommendUsers = [];

    function init() {
      hostServiceRequestSvc.getServiceRequestInfo({
        id: vm.requestId
      }).success(function (data) {
        vm.isOnHold = data.isOnHold;
        vm.requestType = data.type;
        vm.requestStatus = data.status;
        vm.printUrl = data.printUrl;
        vm.hasUnresolvedQuery = data.hasUnresolvedQuery;
        vm.isInsuranceBenefitGroup = data.isInsuranceBenefitGroup;
        vm.escalationRemarks = data.escalationRemarks;
        vm.currencyCode = data.currencyCode;
        vm.timeZoneId = data.ianaTimeZoneId;
      });
    }

    function approve(remarks) {
      vm.loading++;
      hostServiceRequestSvc.approveTopupAddonRequest({
        id: vm.requestId,
        remarks: remarks
      }).success(function (data) {
        abp.notify.success(App.localize('Approved'));
        navigateToListing();
      }).error(function (data) {
        /* Error code 1 == Insufficient allowance */
        if (data.code == 1) {
          /* Insufficient employee balance for request amount */
          swal({
            title: App.localize('InvalidAmount'),
            text: App.localize('InsufficientEmployeeBalanceNarrative') + '\n' +
              App.localize('RefreshThePageAndEditTheAmount'),
            confirmButtonColor: '#1AB394',
            confirmButtonText: App.localize('RefreshNow')
          }, function (isConfirm) {
            if (isConfirm) {
              location.reload();
            }
          })
        }
      }).finally(function () {
        vm.loading--;
      });
    }

    function reject(remarks) {
      vm.loading++;
      hostServiceRequestSvc.rejectRequest({
        id: vm.requestId,
        remarks: remarks
      }).success(function (data) {
        abp.notify.success(App.localize('Rejected'));
        navigateToListing();
      }).finally(function () {
        vm.loading--;
      });
    }

    function rejectGl(remarks) {
      vm.loading++;
      hostServiceRequestSvc.rejectGuaranteeLetterServiceRequest({
        letterNumber: vm.letterNumber,
        rejectionReason: remarks
      }).success(function (data) {
        abp.notify.success(App.localize('Rejected'));
        navigateToListing();
      }).finally(function () {
        vm.loading--;
      });
    }

    function query() {
      if (vm.hasUnresolvedQuery) {
        $state.go('inpatientGuaranteeLettersDetails', {
          letterNumber: vm.letterNumber,
          timeZoneId: vm.timeZoneId,
          tab: 3
        });
      } else {
        $state.go('createQuery', {
          letterNumber: vm.letterNumber,
          timeZoneId: vm.timeZoneId
        });
      }
    }

    function delegate(recipient, remarks) {
      vm.loading++;
      hostServiceRequestSvc.delegateRequest({
        processorUserId: recipient.operatorUserId,
        serviceRequestId: vm.requestId,
        letterNumber: vm.letterNumber,
        emailAddress: recipient.operatorEmailAddress,
        remarks: remarks
      }).success(function (data) {
        abp.notify.success(App.localize('ServiceRequestDelegated'));
        navigateToListing();
      }).finally(function () {
        vm.loading--;
      });
    }

    function recommend(recipient, remarks) {
      vm.loading++;
      hostServiceRequestSvc.recommendRequest({
        processorUserId: recipient.operatorUserId,
        serviceRequestId: vm.requestId,
        letterNumber: vm.letterNumber,
        emailAddress: recipient.operatorEmailAddress,
        remarks: remarks
      }).success(function (data) {
        abp.notify.success(App.localize('ServiceRequestRecommended'));
        navigateToListing();
      }).finally(function () {
        vm.loading--;
      });
    }

    function preview() {
      window.open(vm.printUrl);
    }

    function openApprovalModal() {
      /* Insufficient employee balance for request amount */
      if (!vm.isEdit) {
        $modal.open({
          templateUrl: require('/App/common/views/modals/remarks/remarks.modal.html'),
          controller: 'common.views.modals.remarksModal as vm',
          backdrop: 'static',
          resolve: {
            title: function () {
              return App.localize('ApprovalConfirmation');
            },
            existingRemarks: function () {
                return vm.escalationRemarks;
            },
            remarksMaxLength: function () {
                return app.consts.serviceRequestMaxLength;
            }
          }
        }).result.then(function (remarks) {
          approve(remarks);
        });
      } else {
        promptEditModeWarning();
      }
    }

    function openRejectionModal() {
      if (!vm.isEdit) {
        $modal.open({
          templateUrl: require('/App/common/views/modals/remarks/remarks.modal.html'),
          controller: 'common.views.modals.remarksModal as vm',
          backdrop: 'static',
          resolve: {
            title: function () {
              return App.localize('RejectionConfirmation');
            },
            existingRemarks: function () {
                return vm.escalationRemarks;
            },
            remarksMaxLength: function () {
                return app.consts.serviceRequestMaxLength;
            }
          }
        }).result.then(function (remarks) {
          reject(remarks);
        });
      } else {
        promptEditModeWarning();
      }
    }

    function openGlRejectionModal() {
      if (!vm.isEdit) {
        $modal.open({
          templateUrl: require('/App/common/views/modals/remarks/remarks.modal.html'),
          controller: 'common.views.modals.remarksModal as vm',
          backdrop: 'static',
          resolve: {
            title: function () {
              return App.localize('RejectionConfirmation');
            },
            existingRemarks: function () {
                return null;
            },
            remarksMaxLength: function () {
                return app.consts.serviceRequestMaxLength;
            }
          }
        }).result.then(function (remarks) {
          rejectGl(remarks);
        });
      } else {
        promptEditModeWarning();
      }
    }

    function openDelegationModal() {
      getServiceRequestClaimProcessors(function () {
        if (!vm.isEdit) {
          $modal.open({
            templateUrl: require('/App/common/views/modals/notifyRecipient/notifyRecipient.modal.html'),
            controller: 'common.views.modals.notifyRecipientModal as vm',
            backdrop: 'static',
            resolve: {
              users: function () {
                return delegateUsers;
              },
              title: function () {
                return App.localize('DelegationConfirmation');
              },
              message: function () {
                return App.localize('DelegationWarningMessage')
              }
            }
          }).result.then(function (result) {
            delegate(result.recipient, result.remarks);
          });
        } else {
          promptEditModeWarning();
        }
      });
    }

    function openRecommendationModal() {
      getServiceRequestOverridingApprovers(function () {
        if (!vm.isEdit) {
          $modal.open({
            templateUrl: require('/App/common/views/modals/notifyRecipient/notifyRecipient.modal.html'),
            controller: 'common.views.modals.notifyRecipientModal as vm',
            backdrop: 'static',
            resolve: {
              users: function () {
                return recommendUsers;
              },
              title: function () {
                return App.localize('RecommendationConfirmation');
              },
              message: function () {
                return App.localize('RecommendationWarningMessage')
              }
            }
          }).result.then(function (result) {
            recommend(result.recipient, result.remarks);
          });
        } else {
          promptEditModeWarning();
        }
      })
    }

    function checkEditMode(isEdit) {
      vm.isEdit = isEdit;
    }

    function checkSaveTrigger() {
      vm.requestDetailsTrigger++;
    }

    function promptEditModeWarning() {
      abp.message.error(App.localize('SaveOrCancelChangesNarrative'));
    }

    function navigateToListing() {
      $state.go('host.inpatientGuaranteeLetters');
    }

    function getServiceRequestClaimProcessors(_callback) {
      vm.loading++

      commonLookupSvc.getServiceRequestClaimProcessor()
        .success(function (data) {
          // Filter away current user
          delegateUsers = _.remove(data.items, function (n) {
            return n.value.operatorUserId !== abp.session.userId;
          });
        }).finally(function () {
          vm.loading--;
          _callback();
        })
    }

    function getServiceRequestOverridingApprovers(_callback) {
      vm.loading++

      commonLookupSvc.getServiceRequestOverridingApprover()
        .success(function (data) {
          // Filter away current user
          recommendUsers = _.remove(data.items, function (n) {
            return n.value.operatorUserId !== abp.session.userId;
          });
        }).finally(function () {
          vm.loading--;
          _callback();
        })
    }

    function openEscalationModal() {
      if (!vm.isEdit) {
          $modal.open({
              templateUrl: require('/App/common/views/modals/remarks/remarks.modal.html'),
              controller: 'common.views.modals.remarksModal as vm',
              backdrop: 'static',
              resolve: {
                  title: function () {
                      return App.localize('EscalationConfirmation');
                  },
                  existingRemarks: function () {
                      return vm.escalationRemarks;
                  },
                  remarksMaxLength: function () {
                      return app.consts.serviceRequestMaxLength;
                  }
              }
          }).result.then(function (remarks) {
              escalate(remarks);
          });
      } else {
          promptEditModeWarning();
      }
    }

    function escalate(remarks) {
      vm.loading++;
      hostServiceRequestSvc.escalateGuaranteeLetterRequest({
          id: vm.requestId,
          isResolve: true,
          escalationRemark: remarks
      }).success(function () {
          abp.notify.success(App.localize('GlEscalated'));
          navigateToListing();
      }).finally(function () {
          vm.loading--;
      });
    }

    init();
  }
})();
