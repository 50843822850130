(function () {
    var controllerId = 'host.views.escrowReport.index';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', '$uibModal', 'abp.services.app.report',
        function ($scope, $state, $stateParams, $uibModal, reportSvc) {
            function init() {
                vm.getEscrowReport();
            }

            var vm = this;

            $scope.$on("$viewContentLoaded", function () {
                App.initAjax();
            });

            var defaultMaxBalancePercentage = parseInt(abp.setting.get("Hms.CompanySale.LowBalanceNotificationThreshold"), 10);
            if (isNaN(defaultMaxBalancePercentage) || defaultMaxBalancePercentage > 100)
                defaultMaxBalancePercentage = 100;
            else if (defaultMaxBalancePercentage < 0)
                defaultMaxBalancePercentage = 0;

            vm.loading = false;
            vm.requestParams = {
                minBalancePercentage: $stateParams.minBalancePercentage,
                maxBalancePercentage: $stateParams.maxBalancePercentage || defaultMaxBalancePercentage
            };
            vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

            vm.data = {};

            vm.balanceSliderOption = {
                min: 0,
                max: 100,
                step: 1,
                type: 'double',
                postfix: '%',
                prettify: false,
                hasGrid: true,
                from: vm.requestParams.minBalancePercentage,
                to: vm.requestParams.maxBalancePercentage,
                onFinish: function (d) {
                    vm.requestParams.minBalancePercentage = d.fromNumber;
                    vm.requestParams.maxBalancePercentage = d.toNumber;
                    vm.getEscrowReport();
                }
            };

            vm.getEscrowReport = function () {
                registerStateParams();
                vm.loading = true;

                reportSvc.getEscrowReport(vm.requestParams)
                    .success(function (data) {
                        vm.data = data.items;
                    }).finally(function (e) {
                        vm.loading = false;
                    });
            };

            function registerStateParams() {
                $state.transitionTo($state.current, {
                    minBalancePercentage: vm.requestParams.minBalancePercentage,
                    maxBalancePercentage: vm.requestParams.maxBalancePercentage
                }, {
                    notify: false
                });
            }

            init();
        }
    ]);
})();