(function () {
    var controllerId = 'host.views.corporates.supportPlans';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', '$uibModal', 'abp.services.app.supportPlan',
        function ($scope, $state, $stateParams, $modal, supportPlanSvc) {
            function init() {
                vm.loading = true;
                supportPlanSvc.getTenant({
                    id: vm.organizationId
                }).success(function (n) {
                    vm.organizationFullName = n.name + ' (' + n.tenancyName + ')';
                }).finally(function () {
                    vm.loading = false;
                });
            }

            var vm = this;
            vm.loading = false;
            vm.organizationId = $stateParams.id;
            vm.organizationFullName = '';

            vm.supportPlanGridOptions = {
                appScopeProvider: vm,
                columnDefs: [{
                    enableSorting: false,
                    name: App.localize('Actions'),
                    width: 120,
                    headerCellTemplate: '<span></span>',
                    cellTemplate: 'supportPlans.actionTemplate'
                }, {
                    displayName: App.localize('PlanName'),
                    field: 'planName',
                    minWidth: 120
                }, {
                    displayName: App.localize('DisplayName'),
                    field: 'displayName',
                    minWidth: 120
                }],
                data: []
            };

            vm.getSupportPlans = function () {
                vm.loading = true;
                supportPlanSvc.getSupportPlans({
                    organizationId: vm.organizationId
                }).success(function (data) {
                    vm.supportPlanGridOptions.data = _.map(data.items, function (d) {
                        return $.extend(d, {
                            organizationId: vm.organizationId
                        });
                    });
                }).finally(function (e) {
                    vm.loading = false;
                });
            };

            vm.deleteSupportPlan = function (supportPlan) {
                abp.message.confirm(App.localize('SupportPlanDeleteWarningMessage', supportPlan.planName), App.localize('AreYouSure'), function (d) {
                    if (d) {
                        supportPlanSvc.deleteSupportPlan({
                            id: supportPlan.id
                        }).success(function (data) {
                            vm.getSupportPlans();
                            abp.notify.success(App.localize('SuccessfullyDeleted'));
                        });
                    }
                });
            };

            if (vm.organizationId) {
                init();
                vm.getSupportPlans();
            } else {
                $state.go('host.corporates');
            }
        }
    ]);
})();
