(() => {
  angular
    .module('app')
    .controller('host.views.clinics.createOrEditLocationModal', PanelLocationEditModalController);

  PanelLocationEditModalController.$inject = [
    '$uibModalInstance',
    '$timeout',
    'abp.services.app.clinicLocation',
    'abp.services.app.commonLookup',
    'locationId',
    'clinicId',
    'clinicType',
    'requestNumber',
    'abp.services.app.panelRequest',
    'Hms.MultiTenancy.TenantClinicType',
    'Hms.MultiTenancy.Tenant',
    'Abp.Configuration.Setting',
    'moment',
  ];

  function PanelLocationEditModalController(
    $uibModalInstance,
    $timeout,
    clinicLocationSvc,
    commonLookupSvc,
    locationId,
    clinicId,
    clinicType,
    requestNumber,
    panelRequestSvc,
    enumTenantClinicType,
    constsTenant,
    constsAbpConfig,
    moment
  ) {
    const vm = this;

    vm.constsTenant = constsTenant;
    vm.constsAbpConfig = constsAbpConfig;
    vm.loading = 0;
    vm.saving = 0;
    vm.location = null;
    vm.clinicType = clinicType;
    vm.daysOfWeek = [];
    vm.operatingHours = [];
    vm.isCreate = !locationId;
    vm.isEditing = false;
    vm.isGP = vm.clinicType === 1;
    vm.selectedServices = [];
    vm.eligibleCountryCodes = [];
    vm.enums = {};
    vm.enums.tenantClinicType = enumTenantClinicType;
    vm.fromPanelRequest = false;
    vm.doctor = null;
    vm.specialistDiscipline = null;
    vm.doctors = [];
    vm.clinicLanguages = [];
    vm.timeZones = null;

    vm.permissions = {
      manage: abp.auth.isGranted('Host.Clinics.Locations.Manage'),
    };

    vm.map = {
      isInit: false,
      hasLatLng: true,
      marker: {
        id: 1,
        coords: convertLatLng(getLatLngForMap()),
        options: { draggable: true },
        events: {
          dragend: (marker) => {
            vm.location.latLng = {
              lat: marker.getPosition().lat(),
              lng: marker.getPosition().lng(),
            };
          },
        },
      },
      searchbox: {
        options: {
          autocomplete: true,
          fields: ['formatted_phone_number', 'geometry', 'international_phone_number', 'name'],
        },
        events: {
          place_changed: (searchBox) => {
            const place = searchBox.getPlace();

            vm.location.latLng = {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            };
            vm.map.center = convertLatLng(vm.location.latLng);
            vm.latLngUpdated();

            vm.location.name = vm.location.name || place.name || '';
            vm.location.contactNumber =
              vm.location.contactNumber ||
              place.international_phone_number ||
              place.formatted_phone_number ||
              '';
          },
        },
      },
      options: App.createMapOptions(),
      init: () => {
        if (vm.map.isInit) {
          return;
        }
        vm.map.isInit = true;

        const latLng = getLatLngForMap();
        vm.map.center = convertLatLng(latLng);
        vm.map.marker.coords = convertLatLng(latLng);
        vm.map.hasLatLng = true;
        if (!vm.location.latLng) {
          $timeout(() => {
            vm.map.hasLatLng = false;
          }, 250);
        }
      },
    };

    vm.lookupDoctors = lookupDoctors;
    vm.addDoctor = addDoctor;
    vm.isExistingDoctor = isExistingDoctor;
    vm.lookupClinicLanguages = lookupClinicLanguages;
    vm.addSpecialistDiscipline = addSpecialistDiscipline;
    vm.toggleEdit = toggleEdit;
    vm.checkIfPreviousServiceWasEnabled = checkIfPreviousServiceWasEnabled;
    vm.latLngUpdated = latLngUpdated;
    vm.save = save;
    vm.cancel = cancel;
    vm.getDefaultPanelService = getDefaultPanelService;

    init();

    function init() {
      getApplicablePanelService();
      getEligibleCountries();
      getTimeZones();

      vm.hasCompletedTrainingDate = false;

      let i;
      for (i = 0; i < 7; i += 1) {
        vm.daysOfWeek[i] = {
          id: i,
          date: moment().day(i),
        };
      }

      vm.loading += 1;
      clinicLocationSvc
        .getClinicLocationForEdit({
          id: locationId,
          clinicId,
        })
        .success((n) => {
          vm.hasActiveSessions = n.hasActiveSessions;
          vm.location = n;
          vm.specialistDisciplines = vm.location.specialistDisciplines;
          vm.location.doctors = vm.location.doctors || [];
          if (n.latLng) vm.map.center = convertLatLng(n.latLng);
          vm.latLngUpdated();

          if (n.operatingHours) {
            vm.operatingHours = _.map(n.operatingHours, (operatingHours) => ({
              id: operatingHours.id,
              days: operatingHours.days,
              hours: _.map(operatingHours.hours, (h) => ({
                openTime: moment(h.openTime).toDate(),
                closeTime: moment(h.closeTime).toDate(),
              })),
              isHours: operatingHours.isHours,
              is24Hours: operatingHours.is24Hours,
              isOpen: operatingHours.isOpen,
            }));
          }

          for (let j = vm.location.doctors.length - 1; j >= 0; j -= 1) {
            vm.location.doctors[j].exist = true;
          }

          _.map(vm.location.services, (serviceValue) => {
            vm.selectedServices[serviceValue] = true;
          });

          vm.clinicLanguages = vm.location.initialEditLanguages || [];
          vm.location.initialEditLanguages = null;

          vm.originalStatus = vm.location.isActive;
          vm.location.doNotifyStatusChange = true;

          vm.location.specialistDisciplines = [];
          _.each(vm.location.assignedDisciplineIds, (id) => {
            const discipline = _.find(vm.specialistDisciplines, (d) => d.id === id);

            if (discipline) {
              vm.location.specialistDisciplines.push({
                id: discipline.id,
                displayName: discipline.displayName,
                isChecked: true,
              });
            }
          });

          prefill(requestNumber);

          if (vm.location.trainingDate) {
            vm.hasCompletedTrainingDate = true;
          }

          getEligibleCountries();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function prefill(reqNumber) {
      // Ensure there is data, request number and is creation.

      if (!vm.location || !reqNumber || locationId) return;

      vm.fromPanelRequest = true;

      vm.loading += 1;
      panelRequestSvc
        .getPanelRequest({
          id: reqNumber,
        })
        .success((request) => {
          vm.location.panelRequestId = request.id;
          vm.location.name = request.panelName;
          vm.location.contactNumber = request.contactNumber;
          vm.location.latLng = request.latLng;
          vm.location.countryCode = request.countryCode;
          vm.location.postcode = request.postcode;
          vm.location.state = request.state;
          vm.location.city = request.city;
          vm.location.street = request.street;
          vm.location.isVisibleOnLocator = false;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function lookupDoctors(search) {
      if (search) {
        clinicLocationSvc
          .findClinicDoctors({
            id: clinicId,
            filter: search,
            maxResultCount: 5,
          })
          .success((data) => {
            vm.doctors = data.items;
          })
          .finally(() => {
            if (!vm.doctors.length)
              vm.doctors = [
                {
                  name: search,
                  value: '0',
                  new: true,
                },
              ];
          });
      }
    }

    function addDoctor() {
      if (vm.doctor && !vm.isExistingDoctor()) {
        vm.location.doctors.push({
          id: parseInt(vm.doctor.value, 10),
          name: vm.doctor.name.trim(),
          new: vm.doctor.new,
          exist: true,
        });
      }
      vm.doctor = null;
    }

    function isExistingDoctor() {
      const doctorId = parseInt(vm.doctor.value, 10);
      const doctorName = vm.doctor.name.trim();

      for (let i = vm.location.doctors.length - 1; i >= 0; i -= 1) {
        if (doctorId === 0) {
          if (doctorName === vm.location.doctors[i].name) return true;
        } else if (vm.location.doctors[i].id === doctorId) {
          return true;
        }
      }

      return false;
    }

    function lookupClinicLanguages(search) {
      commonLookupSvc
        .findSpokenLanguages({
          filter: search,
          maxResultCount: 50,
        })
        .success((data) => {
          vm.clinicLanguages = data.items;
        });
    }

    function addSpecialistDiscipline() {
      const isAdded = _.some(
        vm.location.specialistDisciplines,
        (d) => d.id === vm.specialistDiscipline.id
      );
      if (!isAdded) {
        vm.location.specialistDisciplines.push({
          id: vm.specialistDiscipline.id,
          displayName: vm.specialistDiscipline.displayName,
          isChecked: true,
        });
      }
      vm.specialistDiscipline = null;
    }

    function toggleEdit(flag) {
      vm.isEditing = flag;
    }

    // Check if the service was previously enabled before edit.

    function checkIfPreviousServiceWasEnabled(panelServiceId) {
      return _.some(vm.location.services, (serviceId) => serviceId === panelServiceId);
    }

    /* Google map */

    function getLatLngForMap() {
      if (vm.location && vm.location.latLng) return vm.location.latLng;
      return {
        lat: 3.031647,
        lng: 101.617452,
      };
    }

    function convertLatLng(latLng) {
      return {
        latitude: latLng.lat,
        longitude: latLng.lng,
      };
    }

    function latLngUpdated() {
      if (!vm.map.isInit) return;
      const latLng = getLatLngForMap();
      vm.map.center = convertLatLng(latLng);
      vm.map.marker.coords = convertLatLng(latLng);
      vm.map.hasLatLng = vm.location.latLng;
    }

    /* End of Google map */

    function createOrUpdateClinicLocation() {
      for (let i = vm.location.doctors.length - 1; i >= 0; i -= 1) {
        if (vm.location.doctors[i].exist === false) vm.location.doctors.splice(i, 1);
      }

      vm.location.operatingHours = vm.operatingHours;

      vm.location.assignedDisciplineIds = [];
      _.each(vm.location.specialistDisciplines, (d) => {
        if (d.isChecked) {
          vm.location.assignedDisciplineIds.push(d.id);
        }
      });

      vm.location.services = _.reduce(
        vm.selectedServices,
        (result, value, index) => {
          if (value) {
            result.push(index);
          }
          return result;
        },
        []
      );

      clinicLocationSvc
        .createOrUpdateClinicLocation(vm.location)
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          $uibModalInstance.close({
            tenantId: vm.location.tenantId,
          });

          vm.toggleEdit(false);
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function save() {
      if (vm.operatingHours && vm.operatingHours.error) {
        abp.notify.error(App.localize('DuplicateDaysNaration'));
        return;
      }

      vm.saving += 1;

      if (vm.isCreate) {
        abp.message.confirm(
          App.localize('TimeZoneConfirmMessage'),
          App.localize('AreYouSure'),
          (d) => {
            if (d) {
              createOrUpdateClinicLocation();
            } else {
              vm.saving -= 1;
            }
          });
      }

      if (vm.isEditing) createOrUpdateClinicLocation();
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function getApplicablePanelService() {
      commonLookupSvc.getApplicablePanelServices().success((result) => {
        vm.applicablePanelServices = result;
      });
    }

    function getDefaultPanelService(panelType) {
      // Return default service type given panel type.

      switch (panelType) {
        case 1: // GP
          return 1;

        case 2: // Dental
          return 2;

        case 4: // Optical
          return 3;

        case 5: // Specialist
          return 5;

        case 6: // Pharmacy
          return 6;

        case 7: // Hospital
          return 7;

        default:
          return 0;
      }
    }

    function getEligibleCountries() {
      commonLookupSvc
        .getEligibleCountries()
        .success((data) => {
          vm.eligibleCountryCodes = data.map((o) => o.code);
        })
        .finally(() => {
          vm.map.searchbox.options.componentRestrictions = {
            country: vm.eligibleCountryCodes,
          };
        });
    }

    function getTimeZones() {
      vm.loading += 1;
      commonLookupSvc
        .getTimeZones()
        .success((data) => {
          vm.timeZones = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
