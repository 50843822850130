(function () {
  var controllerId = 'clinic.views.hospital.requestIgl.page';
  var app = angular.module('app');

  app.controller(controllerId, [
    '$scope',
    '$stateParams',
    '$state',
    '$uibModal',
    'abp.services.app.hospitalPanelServiceRequest',
    'abp.services.app.commonLookup',
    'moment',
    function (
      $scope,
      $stateParams,
      $state,
      $uibModal,
      hospitalPanelServiceRequestSvc,
      commonLookupSvc,
      moment
    ) {
      var vm = this;

      vm.createDoctor = createDoctor;
      vm.refreshDoctors = refreshDoctors;
      vm.updateDoctor = updateDoctor;
      vm.prevTab = prevTab;
      vm.nextTab = nextTab;
      vm.setActiveTab = setActiveTab;
      vm.save = save;
      vm.saveDraft = saveDraft;
      vm.downloadPatientConsentForm = downloadPatientConsentForm;
      vm.downloadDoctorAdmissionForm = downloadDoctorAdmissionForm;
      vm.dateValidation = dateValidation;
      vm.admissionDateValidation = admissionDateValidation;

      vm.loading = 0;
      vm.letter = null;
      vm.employeeId = $stateParams.employeeId;
      vm.panelLocationId = $stateParams.panelLocationId;
      vm.requestId = $stateParams.requestId;
      vm.activeTab = 0;
      vm.doctors = [];
      vm.patient = {};
      vm.newAttachments = [];
      vm.attachments = [];
      var fileExtensions =
        '|' +
        app.consts.fileUpload.imageExtensions
          .concat(app.consts.fileUpload.pdfExtensions)
          .join('|') +
        '|';
      vm.uploadConfig = {
        objectType: 'InpatientIglRequestAttachment',
        itemsLimit: 3,
        accept: 'image/*,application/pdf',
        filters: [
          {
            name: 'fileFilter',
            fn: function (n) {
              var t = '|' + n.type.slice(n.type.lastIndexOf('/') + 1) + '|';
              if (fileExtensions.indexOf(t) === -1) {
                abp.message.warn(App.localize('File_Warn_FileType'));
                return false;
              }
              return true;
            },
          },
          {
            name: 'sizeFilter',
            fn: function (n) {
              var sizeLimit = 52428800;
              if (n.size > sizeLimit) {
                abp.message.warn(App.localize('File_Warn_XSizeLimit', sizeLimit / 1024 / 1024));
                return false;
              }
              return true;
            },
          },
        ],
      };

            vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
            vm.permissions = {
                createDoctor: abp.auth.isGranted('Clinic.Doctors.Create')
            };
            vm.hasInpatientRegionalExpansion = abp.setting.getBoolean(
                'Hms.Feature.RegionalExpansionForInpatient'
            );

      function init() {
        if (vm.hasInpatientRegionalExpansion) {
          getLanguage();
          getCurrency();
        }
        getRequestInitialGuaranteeLetter();
      }

      function getLanguage() {
          vm.loading += 1;
          commonLookupSvc
              .getDefaultLanguageByCountryCode()
              .success((data) => { vm.language = data })
              .finally(() => { vm.loading -= 1 });
      }

      function getCurrency() {
        vm.loading += 1;
        commonLookupSvc
          .getTenantCurrencyCode()
          .success((data) => {
            if (data) vm.currencyCode = data;
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }

      function getRequestInitialGuaranteeLetter() {
        vm.loading++;
        hospitalPanelServiceRequestSvc
          .getRequestGuaranteeLetter({
            employeeId: vm.employeeId,
            panelLocationId: vm.panelLocationId,
            requestId: vm.requestId,
          })
          .success(function (data) {
            vm.letter = data.letter;
            vm.patient = data.patient;
            vm.panel = data.panel;
            vm.doctors = data.doctors;
            vm.admissionTypes = data.availableAdmissionTypes;
            vm.attachments = vm.letter.attachments;
            vm.newAttachments = [];

            _.each(vm.letter.documentChecklistItems, function (item) {
              item.required = item.isMandatory;
              item.isChecked = item.isSelected;
            });
            updateDoctor();
          })
          .finally(function () {
            vm.loading--;
          });
      }

      function prevTab() {
        setActiveTab(vm.activeTab - 1);
      }

      function nextTab() {
        if (vm.activeTab === 2) {
          return;
        } else if (vm.activeTab === 0 && !App.isFormValid($scope.forms.admissionDetails)) {
          return;
        } else if (vm.activeTab === 1) {
          vm.invalidAttachment = vm.newAttachments.length < 1 && vm.attachments.length < 1;
          vm.invalidChecklist =
            !App.isFormValid($scope.forms.uploadDocuments) &&
            _.some(vm.letter.documentChecklistItems, function (d) {
              return d.required && !d.isChecked;
            });

          if (vm.invalidAttachment || vm.invalidChecklist) {
            return;
          }
        }

        setActiveTab(vm.activeTab + 1);
      }

      function setActiveTab(tab) {
        if (tab < 0 || tab > 3) return; // Prevent tab out of range.

        vm.activeTab = tab;
      }

      function getFirstInvalidTab(tab) {
        var tabForms = [$scope.forms.admissionDetails, $scope.forms.uploadDocuments, null];
        var invalidTab = -1; // -1 for no invalid tabs.
        var maxTab = tab >= 0 ? tab : tabForms.length - 1;

        for (var i = 0; i <= maxTab; i++) {
          var tabForm = tabForms[i];
          vm.invalidChecklist = i === 1 && !App.isFormValid(tabForm);
          vm.invalidAttachment = i === 1 && vm.newAttachments.length === 0;

          if ((tabForm && !App.isFormValid(tabForm)) || vm.invalidAttachment) {
            invalidTab = i;
            break;
          }
        }

        return invalidTab;
      }

      function updateDoctor() {
        vm.admittingDoctor = vm.doctors.find((d) => d.id === vm.letter.doctorId);
      }

      function save() {
        if (vm.saving) return;

        var checklistItems = [];
        _.each(vm.letter.documentChecklistItems, function (item) {
          checklistItems.push({
            text: item.text,
            isSelected: item.isChecked,
            isMandatory: item.required,
          });
        });

        var attachments = [];
        _.each(vm.attachments, function (attachment) {
          if (!attachment.isDelete) attachments.push(attachment.id);
        });

        var input = $.extend(vm.letter, {
          serviceRequestId: vm.requestId,
          employeeId: vm.patient.id,
          panelLocationId: vm.panel.id,
          documentChecklistItems: checklistItems,
          attachments: attachments,
          newAttachments: vm.newAttachments,
        });

        vm.saving = true;
        hospitalPanelServiceRequestSvc
          .requestInitialGuaranteeLetter(input)
          .success(function () {
            abp.notify.info(App.localize('SuccessfullySubmitted'));
            $state.go('clinic.inpatientDashboard');
          })
          .finally(function () {
            vm.saving = false;
          });
      }

      function saveDraft() {
        if (vm.saving) return;

        var checklistItems = [];
        _.each(vm.letter.documentChecklistItems, function (item) {
          checklistItems.push({
            text: item.text,
            isSelected: item.isChecked,
            isMandatory: item.required,
          });
        });

        var attachments = [];
        _.each(vm.attachments, function (attachment) {
          if (!attachment.isDelete) attachments.push(attachment.id);
        });

        var input = $.extend(vm.letter, {
          serviceRequestId: vm.requestId,
          employeeId: vm.patient.id,
          panelLocationId: vm.panel.id,
          documentChecklistItems: checklistItems,
          attachments: attachments,
          newAttachments: vm.newAttachments,
        });

        vm.saving = true;
        hospitalPanelServiceRequestSvc
          .requestDraftGuaranteeLetter(input)
          .success(function (requestId) {
            vm.requestId = requestId;
            abp.notify.info(App.localize('SuccessfullySaved'));
          })
          .finally(function () {
            vm.saving = false;
            $state.reload({ requestId: vm.requestId });
          });
      }

            function downloadPatientConsentForm(language) {
                var option = {
                    employeeId: vm.employeeId,
                    panelLocationId: vm.panelLocationId,
                    languageCode: language
                };
                window.open(App.formatUrl('Print/PatientConsent?', option));
            }

            function downloadDoctorAdmissionForm(language) {
                var option = {
                    employeeId: vm.employeeId,
                    panelLocationId: vm.panelLocationId,
                    languageCode: language
                };
                window.open(App.formatUrl('Print/AttendingPhysicianStatement?', option));
            }

      function createDoctor() {
        $uibModal
          .open({
            templateUrl: require('/App/clinic/views/doctors/createOrEditModal.html'),
            controller: 'clinic.views.doctors.createOrEditModal as vm',
            backdrop: 'static',
            resolve: {
              doctorId: null,
              clinicLocationId: vm.panelLocationId,
            },
          })
          .result.then(function (result) {
            vm.refreshDoctors(result.doctorId);
          });
      }

      function refreshDoctors(doctorId) {
        hospitalPanelServiceRequestSvc
          .getPanelLocationDoctors({
            id: vm.panelLocationId,
          })
          .success(function (data) {
            vm.doctors = data.items;
          })
          .finally(function () {
            if (!vm.doctors.length) {
              vm.createDoctor();
            }
            vm.letter.doctorId = parseInt(doctorId, 10);
            updateDoctor();
          });
      }

      function dateValidation(value, otherDate) {
        return moment(value).diff(moment(otherDate), 'days') > -1;
      }

      function admissionDateValidation(value) {
        // For admission type of accident only.
        var check = moment(value).diff(moment(Date.now()), 'days') < 0;
        return check;
      }

      init();
    },
  ]);
})();
