(() => {
  angular.module('app').controller('host.views.operationsDashboard', OperationsDashboardController);

  OperationsDashboardController.$inject = [];

  function OperationsDashboardController() {
    const vm = this;

    init();

    function init() {}
  }
})();
