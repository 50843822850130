(function () {
    var componentId = 'hostOperationsDashboardAgreementExpiriesComponent';
    var app = angular.module('app');

    function controller($state, operationsDashboard) {
        function init() {
            vm.refresh();
        }

        var vm = this;
        vm.widgetName = componentId;
        vm.loading = 0;
        vm.countryCode = null;
        vm.requestParam = {
            countryCode: vm.countryCode
        }

        vm.permissions = {
            editCorporate: abp.auth.isGranted('Host.Corporates.Edit'),
            editPanel: abp.auth.isGranted('Host.Clinics.Edit')
        };

        vm.$onInit = function () {
            vm.onAddWidget({ widget: this });
        };

        function setTotalCount() {
            var item = _.find(vm.widgets, function (data) {
                return data.id === componentId;
            });
            item.totalCount = vm.totalCount;
            item.loading = false;
        }

        function getAgreementExpiries() {
            vm.loading++;
            operationsDashboard.getAgreementExpiries(vm.requestParam)
                .success(function (data) {
                    vm.data = data.items;
                    vm.totalCount = vm.data.length;
                    showData();
                    setTotalCount();
                }).finally(function () {
                    vm.loading--;
                });
        }

        vm.refresh = function () {
            vm.data = [];
            vm.showAll = false;
            vm.requestParam.countryCode = vm.countryCode;
            getAgreementExpiries();
        };

        vm.toggleShowData = function () {
            vm.showAll = !vm.showAll;
            showData();
        };

        function showData() {
            vm.displayedRecords = vm.showAll ? vm.data : vm.data.slice(0, 5);
        }

        vm.edit = function (tenant) {
            switch (tenant.tenantType) {
                case 1:
                    $state.go('host.createEditCorporate', {
                        corporateId: tenant.id
                    });
                    break;
                case 2:
                    $state.go('host.createEditPanel', {
                        panelId: tenant.id
                    });
                    break;
            }
        };

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/operationsDashboard/agreementExpiries/agreementExpiries.component.html'),
        controller: ['$state', 'abp.services.app.operationsDashboard', controller],
        controllerAs: 'vm',
        bindings: {
            widgets: '<',
            onAddWidget: '&',
            countryCode: '=?'
        }
    });
})();
