(function () {
  var componentId = 'revenueCorporatesOverview';
  var module = angular.module('revenue');

  function controller($uibModal, corporateSvc, corporateCommentSvc) {
    function mapValue(comment) {
      comment.typeInfo = {
        title: comment.type,
        iconClass: '',
        bgClass: ''
      };

      if (comment.type === 'UserComment') {
        comment.typeInfo.title = '';
        comment.typeInfo.iconClass = 'far fa-comments';
        comment.typeInfo.bgClass = 'bg-info';
      } else if (comment.type.startsWith('Corporate.')) {
        comment.typeInfo.iconClass = 'far fa-briefcase';
      } else if (comment.type.startsWith('Subsidiary.')) {
        comment.typeInfo.title = comment.subsidiaryName + ' - ' + comment.type;
        comment.typeInfo.iconClass = 'far fa-briefcase';
      } else if (comment.type.startsWith('Subscription.')) {
        comment.typeInfo.title = comment.subscriptionNumber + ' - ' + comment.type;
        comment.typeInfo.iconClass = 'far fa-book';
      } else if (comment.type.startsWith('SalesAgreement.')) {
        comment.typeInfo.title = comment.agreementNumber + ' - ' + comment.type;
        comment.typeInfo.iconClass = 'far fa-file-contract';
      }

      if (comment.type.endsWith('.Deleted')) {
        comment.typeInfo.iconClass = 'far fa-trash';
        comment.typeInfo.bgClass = 'bg-danger';
      }

      return comment;
    }

    function editAgreement(agreement) {
      $uibModal.open({
        templateUrl: require('/App/modules/revenue/views/corporates/createEditAgreement/createEditAgreementModal.html'),
        controller: "revenue.views.corporates.createEditAgreement.createEditAgreementModal as vm",
        backdrop: "static",
        resolve: {
          corporate: function () {
            return vm.corporate;
          },
          corporateSalesAgreementId: function () {
            return agreement ? agreement.id : null;
          }
        }
      }).result.then(function () {
        refreshCorporate();
      });
    }

    function refreshTimeline(corporate) {
      vm.comments = [];
      if (corporate) {
        vm.loading++;
        corporateCommentSvc.getComments({
          corporateId: corporate.id
        }).success(function (data) {
          vm.comments = _.chain(data.items).map(mapValue).reverse().value();
        }).finally(function () {
          vm.loading--;
        });
      }
    }

    function refreshSubsidiaries(corporate) {
      if (!corporate) return;

      vm.subsidiaries = [];
      vm.loading++;
      corporateSvc.getSubsidiaries({
        id: corporate.id
      }).success(function (data) {
        vm.subsidiaries = data.items;
      }).finally(function () {
        vm.loading--;
      });
    }

    function refreshAgreements(corporate) {
      if (!corporate) return;

      vm.agreements = [];
      vm.loading++;
      corporateSvc.getSalesAgreements({
        id: corporate.id
      }).success(function (data) {
        vm.agreements = _.map(data.items, function (d) {
          d.agents = _.join(d.agentNames, ', ');
          return d;
        });
      }).finally(function () {
        vm.loading--;
      });
    }

    function refreshCorporate() {
      if (!vm.corporate) return;

      vm.loading++;
      corporateSvc.getCorporate({
        id: vm.corporate.id
      }).success(function (data) {
        vm.onCorporateUpdated({
          corporate: data
        });
        refreshAgreements(data);
        refreshTimeline(data);
      }).finally(function () {
        vm.loading--;
      });
    }

    const vm = this;
    vm.loading = 0;
    vm.comments = [];
    vm.subsidiaries = [];
    vm.agreements = [];

    vm.permissions = {
      assignAgent: abp.auth.isGranted('Hms.Revenue.Corporate.AssignAgent'),
      manageSalesAgreements: abp.auth.isGranted('Hms.Revenue.Corporate.ManageSalesAgreements'),
    };

    vm.$onInit = function () {
      vm.onOverviewInit({
        refreshTimeline: vm.externalTimelineRefresh
      });
    };

    vm.$onChanges = function (changesObj) {
      if (changesObj.corporate) {
        const corporate = changesObj.corporate.currentValue;
        refreshTimeline(corporate);
        refreshSubsidiaries(corporate);
        refreshAgreements(corporate);
      }
    };

    vm.assignAgent = function () {
      $uibModal.open({
        templateUrl: require('/App/modules/revenue/views/corporates/assignCorporateAgent/assignCorporateAgentModal.html'),
        controller: "revenue.views.corporates.assignCorporateAgent.assignCorporateAgentModal as vm",
        backdrop: "static",
        resolve: {
          corporate: function () {
            return vm.corporate;
          }
        }
      }).result.then(function () {
        refreshCorporate();
      });
    };

    vm.editDisplayName = function () {
      swal({
        title: App.localize('Edit'),
        type: 'input',
        showCancelButton: true,
        closeOnConfirm: false,
        confirmButtonColor: '#1ab394',
        inputPlaceholder: App.localize('DisplayName'),
        inputValue: vm.corporate.displayName
      }, function (inputValue) {
        if (inputValue === false) {
          return false;
        }
        if ($.trim(inputValue || '') === '') {
          swal.showInputError(App.localize('InvalidDisplayName'));
          return false;
        }

        swal.close();
        vm.loading++;
        corporateSvc.updateCorporate({
          id: vm.corporate.id,
          displayName: inputValue
        }).success(function () {
          abp.notify.success(App.localize('SuccessfullyUpdated'));
          refreshCorporate();
        }).finally(function () {
          vm.loading--;
        });
      });
    };

    vm.externalTimelineRefresh = function () {
      refreshTimeline(vm.corporate);
    };

    vm.editAgreement = editAgreement;
    vm.createAgreement = function () {
      editAgreement(null);
    };
  }

  module.component(componentId, {
    require: {
      container: '^revenueCorporatesContainer'
    },
    bindings: {
      corporate: '<',
      onCorporateUpdated: '&',
      onOverviewInit: '&'
    },
    templateUrl: require('/App/modules/revenue/views/corporates/overview/widget.html'),
    controller: [
      '$uibModal', 'abp.services.revenue.corporate', 'abp.services.revenue.corporateComment', controller
    ],
    controllerAs: 'vm'
  });
})();
