(function () {
    var componentId = 'hostDoraemonCommonInpatientTicketsDetailTicketInfoComponent';
    angular
        .module('app')
        .component(componentId, {
            templateUrl: require('/App/host/views/doraemon/common/inpatientTickets/detail/ticketInfo/ticketInfo.component.html'),
            controller: ['$scope', 'abp.services.app.doraemon', 'moment', controller],
            controllerAs: 'vm',
            bindings: {
                isClaim: '<',
                claimNo: '<',
                letterNumber: '<',
                requestInfo: '<', // For processing FGL and Adjustment purpose.
                isEdit: '=',
                coPay: '<',
                roomAndBoardCharges: '<',
                hospitalizationBenefits: '<',
                coPayTypeName: '<'
            }
        });

    function controller($scope, doraemonSvc, moment) {
        var vm = this;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.$onChanges = onChanges;
        vm.loading = 0;
        vm.savedTrigger = 0;
        vm.isHost = App.isHost();
        vm.isCorporate = App.isCorporate();
        vm.isInPublicHoliday = false;
        vm.hasState = true;

        vm.edit = edit;
        vm.save = save;
        vm.cancel = cancel;
        vm.lookupDoctor = lookupDoctor;
        vm.getPublicHoliday = getPublicHoliday;
        vm.getTotalBillAmount = getTotalBillAmount;
        vm.getCoveredAmount = getCoveredAmount;
        vm.getUncoveredAmount = getUncoveredAmount;
        vm.getCollectCash = getCollectCash;
        vm.getCoPayAmount = getCoPayAmount;
        vm.getExceededAmount = getExceededAmount;

        vm.filteredDoctors = [];
        vm.roomCategories = [];
        vm.procedureCategories = [];
        vm.doctorProcedureCategories = [];
        vm.hospitalChargesCategories = [];
        vm.otherCategories = [];
        vm.roomBenefits = [];
        vm.procedureBenefits = [];
        vm.doctorProcedureBenefits = [];
        vm.hospitalChargesBenefits = [];
        vm.fixedLimitBalance = 0;

        vm.hasSobEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');
        vm.hasTxtFileSupport = abp.setting.getBoolean('Hms.Feature.TxtFileSupport');

        var limitSize = 100;

        function onChanges(changes) {
            if (changes.requestInfo && changes.requestInfo.currentValue) {
                vm.request = vm.requestInfo.request;
                vm.patient = vm.requestInfo.patient;
                vm.panel = vm.requestInfo.panel || {};
                vm.doctors = vm.requestInfo.doctors || [];
                vm.panelLocationDoctors = vm.request.requestType.id === 6 ? vm.doctors : vm.requestInfo.panelLocationDoctors
                vm.categories = vm.requestInfo.categories || [];
                vm.diseaseClassifications = vm.requestInfo.diseaseClassifications || [];
                vm.diseaseClassifications = _.map(vm.diseaseClassifications, function (d) {
                    return {
                        id: d.id,
                        code: d.code,
                        description: '(' + d.code + ') ' + d.description
                    }
                });

                vm.categories = _.map(vm.categories, function (d) {
                    return _.extend({}, d, {
                        id: d.category
                    })
                });

                if (vm.hasSobEnhancement) {
                    var accomodationItems = [
                        'RoomAndBoard',
                        'IcuRoomAndBoard',
                        'ChildGuardianBenefit'
                    ];

                    var doctorProcedureItems = [
                        'SurgicalFee',
                        'AnaesthetistFee',
                        'OrganTransplant',
                        'DaySurgery'
                    ];

                    var hospitalChargesItems = [
                        'OperatingTheatre',
                        'HospitalSuppliesAndServices',
                        'AmbulanceFee',
                        'MedicalReport'
                    ];

                    vm.roomCategories = _.filter(vm.categories, function (x) {
                        return accomodationItems.includes(x.category);
                    });

                    vm.procedureCategories = _.filter(vm.categories, function (x) {
                        return x.category === "InHospitalDoctorVisit"
                    });

                    vm.doctorProcedureCategories = _.filter(vm.categories, function (x) {
                        return doctorProcedureItems.includes(x.category);
                    });

                    vm.hospitalChargesCategories = _.filter(vm.categories, function (x) {
                        return hospitalChargesItems.includes(x.category);
                    });

                    // SOB benefits

                    vm.roomBenefits = _.filter(vm.hospitalizationBenefits, function (x) {
                        return accomodationItems.includes(x.category);
                    });

                    vm.procedureBenefits = _.filter(vm.hospitalizationBenefits, function (x) {
                        return x.category === "InHospitalDoctorVisit";
                    });

                    vm.doctorProcedureBenefits = _.filter(vm.hospitalizationBenefits, function (x) {
                        return doctorProcedureItems.includes(x.category);
                    });

                    vm.hospitalChargesBenefits = _.filter(vm.hospitalizationBenefits, function (x) {
                        return hospitalChargesItems.includes(x.category);
                    });
                } else {
                    vm.roomCategories = _.filter(vm.categories, function (x) {
                        return x.category === "1" || x.category === "2" || x.category === "3";
                    });

                    vm.procedureCategories = _.filter(vm.categories, function (x) {
                        return x.category === "4";
                    });

                    vm.otherCategories = _.filter(vm.categories, function (x) {
                        return x.category === "9" || x.category === "10" || x.category === "11" || x.category === "12" || x.category === "13";
                    });
                }
            }
        }

        function getPublicHoliday() {
            vm.loading++;
            doraemonSvc.hasPublicHolidayAsync({
                letterNumber: vm.letterNumber,
                dischargeTime: vm.request.dischargeDate
            }).success(function (data) {
                vm.isInPublicHoliday = data;
            }).finally(function () {
                vm.loading--;
            });
        }

        $scope.$watch('vm.request.dischargeDate', function () {
            getPublicHoliday();
        });

        function edit() {
            backupInitialStates();
            vm.isEdit = true;
            vm.onEdit({ isEdit: true });
        }

        function save() {
            if (App.isFormValid(vm.ticketEditForm)) {
                vm.isEdit = false;
                vm.onEdit({ isEdit: false });
                vm.savedTrigger++;
            }
        }

        function cancel() {
            restoreInitialStates();
            vm.isEdit = false;
            vm.onEdit({ isEdit: false });
        }

        function backupInitialStates() {
            vm.preEditData = {
                preEditDoctor: angular.copy(vm.doctorName),
                preEditRoomName: angular.copy(vm.roomName),
                preEditProcedureName: angular.copy(vm.procedureName),
                preEditDiagnosis: angular.copy(vm.diagnoses),
                preEditRooms: angular.copy(vm.rooms),
                preEditProcedures: angular.copy(vm.procedures),
                preEditOthers: angular.copy(vm.others),
                preEditMC: angular.copy(vm.mc),
                preEditDocuments: angular.copy(vm.documents)
            }
        }

        function restoreInitialStates() {
            vm.doctorName = vm.preEditData.preEditDoctor;
            vm.roomName = vm.preEditData.preEditRoomName;
            vm.procedureName = vm.preEditData.preEditProcedureName;
            vm.diagnoses = vm.preEditData.preEditDiagnosis;
            vm.rooms = vm.preEditData.preEditRooms;
            vm.procedures = vm.preEditData.preEditProcedures;
            vm.others = vm.preEditData.preEditOthers;
            vm.mc = vm.preEditData.preEditMC;
            vm.documents = vm.preEditData.preEditDocuments;
        }

        function lookupDoctor(filter) {
            var keyword = _.trim(filter || '');
            if (keyword) {
                var filteredDoctors = [];

                var regexes = _.chain(keyword.split(' '))
                    .map(function (k) {
                        return _.trim(k.toUpperCase());
                    })
                    .filter(function (k) {
                        return k;
                    })
                    .uniq()
                    .map(function (k) {
                        _.escapeRegExp(k);
                        return {
                            token: k,
                            len: k.length,
                            pattern: new RegExp('\\b' + k, 'i')
                        };
                    })
                    .value();

                _.forEach(vm.doctors, function (doctor) {
                    var score = 0;
                    _.forEach(regexes, function (r) {
                        if (r.pattern.test(doctor.name)) {
                            score -= r.len;
                        }
                    });

                    if (score) {
                        filteredDoctors.push({
                            score: score,
                            item: doctor
                        });
                    }
                });

                vm.filteredDoctors = _.chain(filteredDoctors)
                    .sortBy(['score'])
                    .take(30)
                    .map(function (k) {
                        return k.item;
                    })
                    .uniqBy(function (s) {
                        return s.name;
                    })
                    .value();
            } else {
                vm.filteredDoctors = _.take(vm.doctors, limitSize);
            }
        }

        // Total calculation region (To be updated with more calculations)

        function getAmount() {
            //#region HM-3564 and HM-3000 calculation
            var totalBillAmount = 0;
            var coveredAmount = 0;
            var uncoveredAmount = 0;
            var exceededAmount = 0;
            var coPayAmount = 0;
            var totalRoomAndBoardAmount = 0;
            var totalRoomAndBoardDuration = 0;
            var collectCashAmount = 0;
            var totalExcludedItemAmount = 0;
            var allowance = vm.request.allowance;
            var dailyRoomAndBoardAmounts = [];

            if (!vm.coPay) {
                vm.coPay = {
                    isRoomAndBoardExceedEntitlement: vm.request.isRoomAndBoardExceedEntitlement,
                    isPercentage: vm.request.isCoPayPercentage,
                    isCoInsurance: vm.request.isCoInsurance,
                    coPayExclusions: vm.request.coPayExclusions,
                    value: vm.request.coPayValue,
                }
            }

            _.each(vm.request.rooms, function (x) {
                _.each(x.ticketItems, function (p) {
                    if (p.amount) {
                        coveredAmount += p.amount;
                        totalBillAmount += p.amount;

                        if (vm.hasTxtFileSupport && _.some(vm.coPay.coPayExclusions, function (c) { return c === x.category; }))
                            totalExcludedItemAmount += p.amount;
                    }

                    if (p.uncoveredAmount) {
                        uncoveredAmount += p.uncoveredAmount;
                        totalBillAmount += p.uncoveredAmount;
                    }

                    // For room and board only.
                    if ((x.category === "1" || x.category === "RoomAndBoard") && p.totalCharges && p.roomChargeDateRangeModel) {
                        var startDate = p.roomChargeDateRangeModel
                            ? p.roomChargeDateRangeModel.startDate
                            : p.startDate;
                        var endDate = p.roomChargeDateRangeModel
                            ? p.roomChargeDateRangeModel.endDate
                            : p.endDate;

                        var startDateMoment = moment(startDate);
                        var endDateMoment = moment(endDate);

                        totalRoomAndBoardAmount += p.totalCharges;
                        totalRoomAndBoardDuration += endDateMoment.diff(startDateMoment, 'days') + 1;

                        if (vm.hasTxtFileSupport)
                            dailyRoomAndBoardAmounts.push(p.dailyCharges);
                    }
                });
            });

            var updateAmount = (x) => {
                if (x.amount) {
                    coveredAmount += x.amount;
                    totalBillAmount += x.amount;

                    if (vm.hasTxtFileSupport && _.some(vm.coPay.coPayExclusions, function (c) { return c === x.category; }))
                        totalExcludedItemAmount += x.amount;
                }

                if (x.uncoveredAmount) {
                    uncoveredAmount += x.uncoveredAmount;
                    totalBillAmount += x.uncoveredAmount;
                }
            }

            function accumulateAmount(itemContainer) {
                _.each(itemContainer.ticketItems, updateAmount);
            }

            _.forEach(vm.request.procedures, accumulateAmount);

            if (vm.hasSobEnhancement) {
                _.forEach(vm.request.doctorProcedures, accumulateAmount);
                _.forEach(vm.request.hospitalCharges, accumulateAmount);
            } else {
                _.forEach(vm.request.others, updateAmount);
            }

            if (vm.hasTxtFileSupport)
                coPayAmount = calculateCoPayV2(uncoveredAmount, totalBillAmount, totalExcludedItemAmount, dailyRoomAndBoardAmounts);
            else
                coPayAmount = calculateCoPay(uncoveredAmount, totalBillAmount, totalRoomAndBoardAmount, totalRoomAndBoardDuration);

            coveredAmount = coveredAmount - coPayAmount;

            // Check against allowance

            if (vm.isClaim && coveredAmount > allowance) {
                exceededAmount = coveredAmount - allowance;
                coveredAmount = allowance;
            }

            collectCashAmount = uncoveredAmount + coPayAmount + exceededAmount;

            // for Adjustment, the adjustedFinalBillAmount is the totalBillAmount of current adjustment

            if (vm.request.requestType.id === vm.request.adjustmentServiceRequestType.id) {
                vm.request.adjustedFinalBillAmount = App.roundAmount(totalBillAmount);
            }
            // for Final, the FinalBillAmount is the totalBIll of current final service request

            if (vm.request.requestType.id === vm.request.finalServiceRequestType.id) {
                vm.request.finalBillAmount = App.roundAmount(totalBillAmount);
            }

            return {
                totalBillAmount: App.roundAmount(totalBillAmount),
                coveredAmount: App.roundAmount(coveredAmount),
                uncoveredAmount: App.roundAmount(uncoveredAmount),
                collectCash: App.roundAmount(collectCashAmount),
                coPayAmount: App.roundAmount(coPayAmount),
                exceededAmount: App.roundAmount(exceededAmount)
            };
            //#endregion HM-3564 and HM-3000 calculation
        }

        function getTotalBillAmount() {
            return getAmount().totalBillAmount > 0 ? getAmount().totalBillAmount : 0;
        }

        function getCoveredAmount() {
            return getAmount().coveredAmount > 0 ? getAmount().coveredAmount : 0;
        }

        function getUncoveredAmount() {
            return getAmount().uncoveredAmount > 0 ? getAmount().uncoveredAmount : 0;
        }

        function getCollectCash() {
            return getAmount().collectCash > 0 ? getAmount().collectCash : 0;
        }

        function getCoPayAmount() {
            return getAmount().coPayAmount > 0 ? getAmount().coPayAmount : 0;
        }

        function getExceededAmount() {
            return getAmount().exceededAmount > 0 ? getAmount().exceededAmount : 0;
        }

        function calculateCoPayV2(uncoveredAmount, billAmount, totalExcludedItemAmount, dailyRoomAndBoardAmounts) {
            if (!vm.coPay || vm.coPay.value === 0) {
                return 0;
            }

            var coveredAmount = billAmount - uncoveredAmount;
            var costShareableAmount = coveredAmount - totalExcludedItemAmount;
            var hasExceededEntitlement = _.some(dailyRoomAndBoardAmounts, function (d) { return d > vm.roomAndBoardCharges; });

            if (vm.coPay.isPercentage) {
                if (vm.coPay.isRoomAndBoardExceedEntitlement)
                    return hasExceededEntitlement
                        ? costShareableAmount * (vm.coPay.value / 100)
                        : 0;
                else
                    return costShareableAmount * (vm.coPay.value / 100);
            } else {
                return costShareableAmount > 0
                    ? vm.coPay.value
                    : 0;
            }
        }

        /**
         * CoPayment front end calculation
         * @param {any} uncoveredAmount
         * @param {any} billAmount
         * @param {any} totalRoomAndBoardAmount
         * @param {any} roomAndBoardDuration
         */
        function calculateCoPay(uncoveredAmount, billAmount, totalRoomAndBoardAmount, roomAndBoardDuration) {
            if (vm.coPay.value === 0) {
                return 0;
            }

            var totalRoomAndBoardAllowance = 0;
            var exceededRoomAndBoardAmount = 0;

            if (vm.roomAndBoardCharges > 0 && roomAndBoardDuration > 0) {
                totalRoomAndBoardAllowance = vm.roomAndBoardCharges * roomAndBoardDuration;
            }

            if (totalRoomAndBoardAmount > 0) {
                exceededRoomAndBoardAmount = totalRoomAndBoardAmount - totalRoomAndBoardAllowance;
            }

            var coveredAmount = billAmount - uncoveredAmount;

            if (vm.coPay.isPercentage && vm.coPay.isCoInsurance) {
                return coveredAmount * (vm.coPay.value / 100);
            } else if (vm.coPay.isPercentage && !vm.coPay.isCoInsurance) {
                return exceededRoomAndBoardAmount > 0
                    ? (coveredAmount - totalRoomAndBoardAllowance) * (vm.coPay.value / 100)
                    : 0;
            } else {
                var copayableAmount = vm.coPay.isCoInsurance
                    ? coveredAmount
                    : coveredAmount - totalRoomAndBoardAllowance;

                return copayableAmount > 0
                    ? vm.coPay.value
                    : 0;
            }
        }
    }
})();
