import swal from 'sweetalert';

(() => {
  angular
    .module('finance')
    .controller('finance.views.payments.financePaymentDetail', FinancePaymentDetailController);

  FinancePaymentDetailController.$inject = [
    '$stateParams',
    '$state',
    'Hms.Payments.PaymentMethod',
    'abp.services.finance.payments',
    'abp.services.app.financeCommon',
  ];

  function FinancePaymentDetailController(
    $stateParams,
    $state,
    enumPaymentMethod,
    paymentsSvc,
    portalFinanceCommonSvc
  ) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;
    vm.invoices = [];
    vm.bankAccountDeposit = null;
    vm.isFromTransactionPage = $stateParams.isFromTransactionPage;

    vm.enums = {
      paymentMethod: enumPaymentMethod,
    };

    vm.permissions = {
      editPayment: abp.auth.isGranted('HealthMetrics.Finance.Payments.Edit'),
      deletePayment: abp.auth.isGranted('HealthMetrics.Finance.Payments.Delete'),
    };

    vm.sendEmail = sendEmail;
    vm.deletePayment = deletePayment;

    init();

    function init() {
      getPaymentForView();
    }

    function sendEmail() {
      portalFinanceCommonSvc
        .getEmailAddresses({
          billingAccountKey: vm.billingAccount.key,
        })
        .success((result) => {
          swal(
            {
              title: App.localize('SendEmailForX', vm.payment.paymentNumber),
              text: App.localize('EmailAddresses'),
              type: 'input',
              showCancelButton: true,
              closeOnConfirm: false,
              confirmButtonColor: '#1ab394',
              inputPlaceholder: App.localize('EmailAddressesNarrative'),
              inputValue: result.emailAddresses,
              showLoaderOnConfirm: true,
            },
            (inputValue) => {
              if (inputValue === false) return false;
              if (inputValue === '') {
                swal.showInputError(App.localize('InvalidEmailAddress'));
                return false;
              }

              portalFinanceCommonSvc
                .sendPaymentEmail({
                  emailAddresses: inputValue,
                  billingAccountName: vm.billingAccount.name,
                  paymentNumber: vm.payment.paymentNumber,
                  paymentDate: vm.payment.date,
                  currencyCode: vm.currencyCode,
                  amount: vm.payment.receivedAmount,
                  accountOwnerId: vm.payment.accountOwnerId,
                })
                .success(() => {
                  abp.notify.info(App.localize('EmailSent'));
                  swal.close();
                });
              return false;
            }
          );
        });
    }

    function getPaymentForView() {
      vm.loading += 1;
      paymentsSvc
        .getPaymentForView({
          paymentNumber: $stateParams.paymentNumber,
        })
        .success((data) => {
          vm.payment = data.payment;
          vm.billingAccount = data.billingAccount;
          vm.currencyCode = vm.billingAccount.currencyCode;
          vm.invoices = data.invoices;
          vm.bankAccountDeposit = data.bankAccountDeposit;
          vm.isPayable = data.isPayable;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function deletePayment() {
      vm.loading += 1;

      const message = App.localize('PaymentDeleteWarningMessage', vm.invoices.length);

      abp.message.confirm(message, App.localize('AreYouSure'), (d) => {
        if (d) {
          paymentsSvc
            .deletePayment({
              paymentId: vm.payment.id,
            })
            .success(() => {
              abp.notify.info(App.localize('SuccessfullyDeleted'));
            })
            .finally(() => {
              vm.loading -= 1;
              $state.go('finance.saleTransactions', { isFromPayment: true });
            });
        } else {
          vm.loading -= 1;
        }
      });
    }
  }
})();
