(function () {
    angular.module('app')
        .controller('host.views.inpatientGuaranteeLetters.updateIssueNumber.modal', [
            '$scope', '$uibModalInstance', controller]);

    function controller($scope, $uibModalInstance) {
        var vm = this;

        vm.cancel = cancel;
        vm.confirm = confirm;
        vm.issueNumber = '';
        vm.normalizeIssueNumber = normalizeIssueNumber;

        function cancel() {
            $uibModalInstance.dismiss();
        }

        function confirm() {
            if (App.isFormValid($scope.updateIssueNumberModalForm)) {
                var issueNumber = _.trim(vm.issueNumber);

                $uibModalInstance.close(issueNumber);
            };
        }

        function normalizeIssueNumber() {
            if (!vm.issueNumber)
                return false;

            var issueNumber = _.trim(vm.issueNumber);

            return issueNumber ? issueNumber : false;
        }
    }
})();
