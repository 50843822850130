(() => {
  angular.module('finance').component('invoiceDetailInvoiceSenderComponent', {
    templateUrl: require('./invoiceSender.component.html'),
    controller: InvoiceDetailInvoiceSenderComponent,
    controllerAs: 'vm',
    bindings: {
      invoice: '<',
      invoiceFrom: '<',
      hasProfilePhoto: '<'
    },
  });

  InvoiceDetailInvoiceSenderComponent.$inject = [];

  function InvoiceDetailInvoiceSenderComponent() {
    const vm = this;

    vm.loading = 0;

    init();

    function init() {}
  }
})();