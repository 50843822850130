(() => {
  angular
    .module('revenue')
    .controller('revenue.views.subscriptions.detail', SubscriptionDetailsController);

  SubscriptionDetailsController.$inject = [
    '$stateParams',
    'abp.services.revenue.subscription',
    'Hms.Revenue.SubscriptionPlanPricingMode',
    'Hms.Revenue.SubscriptionPlanChargeMode',
    'Hms.Revenue.OutpatientServiceFeatures',
    'Hms.Revenue.InpatientServiceFeatures',
    '$state',
    '$uibModal',
  ];

  function SubscriptionDetailsController(
    $stateParams,
    subscriptionSvc,
    enumSubscriptionPlanPricingMode,
    enumSubscriptionPlanChargeMode,
    enumOutpatientServiceFeatures,
    enumInpatientServiceFeatures,
    $state,
    $uibModal
  ) {
    const vm = this;

    vm.loading = 0;
    vm.permissions = {
      manageSubscriptions: abp.auth.isGranted('Hms.Revenue.Corporate.ManageSubscriptions'),
    };
    vm.subsidiaryNames = '';
    vm.assignments = [];
    vm.selectedTab = 1;
    vm.enums = {
      subscriptionPlanPricingMode: enumSubscriptionPlanPricingMode,
      subscriptionPlanChargeMode: enumSubscriptionPlanChargeMode,
      outpatientServiceFeatures: enumOutpatientServiceFeatures,
      inpatientServiceFeatures: enumInpatientServiceFeatures,
    };

    vm.serviceFeatures = _.union(
      vm.enums.outpatientServiceFeatures.values,
      vm.enums.inpatientServiceFeatures.values
    );

    vm.editSubscription = editSubscription;
    vm.cancelSubscription = cancelSubscription;
    vm.terminateSubscription = terminateSubscription;
    vm.tabSelected = tabSelected;
    vm.getServiceFeaturesAsString = getServiceFeaturesAsString;
    vm.getChargeMode = getChargeMode;
    vm.getPricingMode = getPricingMode;
    vm.cancelTermination = cancelTermination;

    init();

    function init() {
      getSubscription();
    }

    function getSubscription() {
      vm.loading += 1;
      subscriptionSvc
        .getSubscriptionById({
          id: $stateParams.subscriptionId,
        })
        .success((data) => {
          vm.subscription = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function editSubscription() {
      if (!vm.subscription) return;
      if (vm.loading) return;

      $state.transitionTo('revenue.subscriptions.edit', {
        subscriptionId: $stateParams.subscriptionId,
      });
    }

    function cancelSubscription() {
      if (!vm.subscription) return;
      if (vm.loading) return;

      abp.message.confirm(
        App.localize('ConfirmCancelSubscriptionWarning'),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            vm.loading += 1;
            subscriptionSvc
              .cancelSubscription({
                id: vm.subscription.id,
              })
              .success(() => {
                abp.notify.success(App.localize('SuccessfullyCancelled'));
                $state.go('revenue.corporates', {
                  corporateId: vm.subscription.corporateId,
                  section: 'newSubscriptions',
                });
              })
              .finally(() => {
                vm.loading -= 1;
              });
          }
        }
      );
    }

    function terminateSubscription() {
      const modal = $uibModal.open({
        templateUrl: require('./terminate/terminate.modal.html'),
        controller: 'revenue.views.subscriptions.detail.terminationModal as vm',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          subscription() {
            return vm.subscription;
          },
        },
      });
      modal.result.then((input) => {
        vm.loading += 1;
        subscriptionSvc
          .scheduleTermination({
            subscriptionId: input.subscriptionId,
            terminationDate: input.terminationDate,
            reconciliationMode: input.reconciliationMode,
            terminationReason: input.terminationReason,
            newSubscriptionNumber: input.newSubscriptionNumber,
          })
          .success(() => {
            abp.notify.info(App.localize('SuccessfullySaved'));
            $state.go('revenue.corporates', {
              corporateId: vm.subscription.corporateId,
              section: 'newSubscriptions',
            });
          })
          .finally(() => {
            vm.loading -= 1;
          });
      });
    }

    function cancelTermination() {
      if (!vm.subscription) return;
      if (vm.loading) return;

      abp.message.confirm(
        App.localize('ConfirmCancelTerminationWarning'),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            vm.loading += 1;
            subscriptionSvc
              .cancelTermination({
                subscriptionId: vm.subscription.id,
              })
              .success(() => {
                abp.notify.success(App.localize('SuccessfullyCancelled'));
                $state.go('revenue.corporates', {
                  corporateId: vm.subscription.corporateId,
                  section: 'subscriptions',
                });
              })
              .finally(() => {
                vm.loading -= 1;
              });
          }
        }
      );
    }

    function tabSelected(i) {
      vm.selectedTab = i;
    }

    function getServiceFeaturesAsString(serviceFeaturesArray) {
      const values = [];
      _.forEach(serviceFeaturesArray, (item) => {
        const serviceFeature = _.find(vm.serviceFeatures, (i) => i.id === item);
        values.push(serviceFeature.name);
      });
      return values.join(', ');
    }

    function getChargeMode(chargeMode) {
      if (chargeMode === vm.enums.subscriptionPlanChargeMode.Monthly.id) {
        return App.localize('Monthly');
      }
      if (chargeMode === vm.enums.subscriptionPlanChargeMode.Yearly.id) {
        return App.localize('Yearly');
      }
      return '';
    }

    function getPricingMode(pricingMode) {
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.Fixed.id) {
        return App.localize('Fixed');
      }
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.PerMember.id) {
        return App.localize('PerMember');
      }
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.PerTicketBasis.id) {
        return App.localize('PerTicketBasis');
      }
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.PerCaseBasis.id) {
        return App.localize('PerCaseBasis');
      }
      return '';
    }
  }
})();
