(() => {
  angular
    .module('finance')
    .controller(
      'manualTransactionSetupCreateEditTaxCode',
      ManualTransactionSetupCreateEditTaxCodeController
    );

  ManualTransactionSetupCreateEditTaxCodeController.$inject = [
    'isCreate',
    'accountOwnerTaxId',
    'accountOwnerId',
    '$scope',
    '$uibModalInstance',
    'abp.services.finance.tax',
    'Hms.Finance.TaxFormatType',
    'Tax',
  ];

  function ManualTransactionSetupCreateEditTaxCodeController(
    isCreate,
    accountOwnerTaxId,
    accountOwnerId,
    $scope,
    $uibModalInstance,
    taxSvc,
    enumTaxFormatType,
    constsTax
  ) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;
    vm.isCreate = isCreate;
    vm.accountOwnerTaxId = accountOwnerTaxId;
    vm.accountOwnerId = accountOwnerId;
    vm.constsTax = constsTax;
    vm.taxCodeDetails = null;

    vm.enums = {
      taxFormatType: enumTaxFormatType,
    };

    vm.save = save;
    vm.cancel = cancel;

    init();

    function init() {
      if (!isCreate) {
        vm.loading += 1;
        taxSvc
          .getAccountOwnerTax({ id: vm.accountOwnerTaxId })
          .success((data) => {
            vm.taxCodeDetails = data;
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    }

    function save() {
      const isValid = App.isFormValid($scope.createEditTaxCode);
      if (isValid) {
        vm.saving += 1;

        const input = {
          id: vm.taxCodeDetails.id,
          accountOwnerId: vm.accountOwnerId,
          taxCode: vm.taxCodeDetails.taxCode,
          name: vm.taxCodeDetails.name,
          percentage: vm.taxCodeDetails.percentage,
          description: vm.taxCodeDetails.description,
          shortDescription: vm.taxCodeDetails.shortDescription,
          taxFormat: vm.taxCodeDetails.taxFormat,
        };

        if (vm.isCreate) {
          vm.loading += 1;
          taxSvc
            .createAccountOwnerTax(input)
            .success(() => {
              abp.notify.info(App.localize('SuccessfullySaved'));
            })
            .finally(() => {
              vm.saving -= 1;
              $uibModalInstance.close();
            });
        } else {
          taxSvc
            .updateAccountOwnerTax(input)
            .success(() => {
              abp.notify.info(App.localize('SuccessfullySaved'));
            })
            .finally(() => {
              vm.saving -= 1;
              $uibModalInstance.close();
            });
        }
      }
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
