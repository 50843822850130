(() => {
  angular.module('app').component('clinicSpecialistDashboardPatientListingContainerComponent', {
    templateUrl: require('./patientListingContainer.component.html'),
    controller: PatientListingComponentController,
    controllerAs: 'vm',
    bindings: {
      items: '<',
      headerText: '@',
      isRedContainer: '<',
      isPendingSubmissionComponent: '<',
      isOutstandingComponent: '<',
      isLateSubmissionComponent: '<',
      isGlPendingApprovalComponent: '<',
      isSubmittedComponent: '<',
      isRejectedComponent: '<',
      viewType: '@', // Grid or tab
      claimStatus: '<', // If < 0, redirect to Specialist claim page without any filter.
      redirectToSpecialistGl: '<',
      viewMoreFunction: '&',
      itemsTotalCount: '<',
      hasMore: '<',
      timeZoneId: '<',
    },
  });

  PatientListingComponentController.$inject = [];

  function PatientListingComponentController() {
    const vm = this;

    vm.loading = 0;

    vm.sortPatientListing = sortPatientListing;

    vm.$onInit = init;

    function init() {}

    // Sort function

    function sortPatientListing(isAscending) {
      if (isAscending)
        vm.items = _.orderBy(vm.items, ['lastModificationTime', 'expiryTime'], ['asc']);
      else vm.items = _.orderBy(vm.items, ['lastModificationTime', 'expiryTime'], ['desc']);
    }
  }
})();
