(function () {
    var controllerId = 'corporate.views.employees.batchUpdateModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'countries', 'abp.services.app.employee', 'abp.services.app.commonLookup', 'abp.services.app.dashboard', 'Hms.Employees.EmployeeState',
        function ($scope, $uibModalInstance, countries, employeeSvc, commonLookupSvc, dashboardSvc, enumEmployeeState) {
            var vm = this;
            vm.loading = 0;
            vm.saving = 0;
            vm.employee = null;
            vm.chosenEmployee = null;
            vm.dependents = null;
            vm.employeeId = null;
            vm.nationalIds = [];
            vm.employees = [];
            vm.selectedEmployees = [];
            vm.notUpdated = [];
            vm.updatedEmployees = [];
            vm.bulkInsert = false;
            vm.isSuspend = false;
            vm.isUnsuspend = false;
            vm.isTerminate = false;
            vm.isDelete = false;
            vm.actionChosen = false;
            vm.displayResults = false;
            var duplicateSelection = false;
            var processedEmployees = 0;
            var today = new Date();
            var yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 1);

            vm.enums = {};
            vm.enums.employeeState = enumEmployeeState;

            vm.permissions = {
                edit: abp.auth.isGranted('Corporate.Employees.Edit'),
                'delete': abp.auth.isGranted('Corporate.Employees.Delete')
            };

            vm.lookupEmployees = function (search, id) {
                if (search || id) {
                    dashboardSvc.findEmployees({
                        employeeId: id,
                        filter: search,
                        maxResultCount: 10
                    }).success(function (data) {
                        vm.employees = data.items;
                        for (var i = vm.employees.length - 1; i >= 0; i--) {
                            if (vm.employees[i].state == 4 || vm.employees[i].principalId != null) {
                                vm.employees.splice(i, 1);
                            }
                        }
                        if (!vm.employees.length)
                            vm.employeeId = null;
                    });
                }
                else {
                    vm.employees = [];
                }
            };

            vm.loadIds = function () {
                employeeSvc.loadEmployeeIds({
                    nationalIds: vm.nationalIds
                }).success(function (data) {
                    vm.selectedEmployees = data;
                });
            }

            vm.selectEmployee = function () {
                checkDuplicateEmployee(vm.chosenEmployee.value);
                if (!duplicateSelection) {
                    vm.selectedEmployees.push(vm.chosenEmployee);
                }
            }

            function checkDuplicateEmployee(employeeId) {
                duplicateSelection = false;
                for (let employee of vm.selectedEmployees) {
                    if (employee.value == employeeId) {
                        duplicateSelection = true;
                        break;
                    }
                }
            }

            function updateEmployee(employee) {
                vm.loading++;
                employeeSvc.getEmployeeForEdit({
                    id: employee.value
                }).success(function (data) {
                    vm.employee = data.employee;
                    vm.dependents = data.dependents || [];

                    switch (true) {
                        case vm.isSuspend:
                            vm.employee.isSuspended = true;
                            updateEmployeeState(employee);
                            break;
                        case vm.isUnsuspend:
                            vm.employee.isSuspended = false;
                            updateEmployeeState(employee);
                            break;
                        case vm.isTerminate:
                            terminateEmployee(employee);
                            break;
                        case vm.isDelete:
                            deleteEmployee(vm.employee.id, employee);
                            break;
                    }
                }).finally(function () {
                    vm.loading--;
                });
            }

            function updateEmployeeState(employee) {
                vm.saving++;
                employeeSvc.createOrUpdateEmployee({
                    employee: vm.employee,
                    dependents: vm.dependents
                }).success(function () {
                    if (vm.isSuspend) {
                        employee.state = 2;
                    }
                    else {
                        employee.state = 1;
                    }
                    vm.updatedEmployees.push(employee);
                }).finally(function () {
                    processedEmployees++;
                    if (vm.selectedEmployees.length == processedEmployees) { displayResults(); }
                    vm.saving--;
                });
            }

            function terminateEmployee(employee) {
                vm.saving++;
                employeeSvc.batchTerminateEmployee({
                    Id: employee.value
                }).success(function () {
                    employee.state = 3;
                    vm.updatedEmployees.push(employee);
                }).finally(function () {
                    processedEmployees++;
                    if (vm.selectedEmployees.length == processedEmployees) { displayResults(); }
                    vm.saving--;
                });
            }

            function deleteEmployee(employeeId, employee) {
                vm.saving++;
                employeeSvc.delete({
                    id: employeeId
                }).success(function () {
                    employee.state = 4;
                    vm.updatedEmployees.push(employee);
                }).finally(function () {
                    processedEmployees++;
                    if (vm.selectedEmployees.length == processedEmployees) { displayResults(); }
                    vm.saving--;
                });
            }

            vm.setAction = function (state) {
                vm.actionChosen = true;
                switch (state) {
                    case 1:
                        vm.isSuspend = true;
                        vm.isUnsuspend = vm.isTerminate = vm.isDelete = false;
                        break;
                    case 2:
                        vm.isUnsuspend = true;
                        vm.isSuspend = vm.isTerminate = vm.isDelete = false;
                        break;
                    case 3:
                        vm.isTerminate = true;
                        vm.isSuspend = vm.isUnsuspend = vm.isDelete = false;
                        vm.terminationNarrative = App.localize('TerminationNarrative', yesterday.toDateString());
                        break;
                    case 4:
                        vm.isDelete = true;
                        vm.isSuspend = vm.isUnsuspend = vm.isTerminate = false;
                        break;
                }
            }

            function displayResults() {
                if (vm.updatedEmployees.length != vm.selectedEmployees.length) {
                    getNotUpdatedEmployees();
                }
                vm.successText = App.localize('XEmployeesUpdated', vm.updatedEmployees.length);
                if (vm.notUpdated.length) {
                    vm.failText = App.localize('XEmployeesNotUpdated', vm.notUpdated.length);
                }
                vm.displayResults = true;
            }

            function getNotUpdatedEmployees() {
                _.forEach(vm.selectedEmployees, function (employee) {
                    if (!vm.updatedEmployees.includes(employee)) {
                        vm.notUpdated.push(employee);
                    }
                });
            }

            vm.removeEmployee = function (index) {
                if (index < vm.selectedEmployees.length) {
                    vm.selectedEmployees.splice(index, 1);
                }
            };

            vm.save = function () {
                vm.saving++;
                if (!vm.selectedEmployees.length) {
                    vm.saving--;
                }
                for (let employee of vm.selectedEmployees) {
                    if (employee.state == 3 && !vm.isDelete) {
                        processedEmployees++;
                        if (vm.selectedEmployees.length == processedEmployees) { displayResults(); }
                    }
                    else {
                        updateEmployee(employee);
                    }
                }
                vm.saving--;
            }

            vm.close = function () {
                $uibModalInstance.close();
            };
        }
    ]);
})();
