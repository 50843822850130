import Prompt from './prompt';

/**
 * A queue for Prompt to be executed one-by-one in first-in-first-out order.
 *
 * This is built for sweetalert 1.1.3 and abp.sweetalert.js.
 */
class PromptQueue {
  #queue = [];

  /**
   * Create a new PromptQueue.
   * @param {Function} finalCallback The callback to be executed after all Prompts executed.
   */
  constructor(finalCallback) {
    this.finalCallback = finalCallback;
  }

  /**
   * Add a new Prompt.
   * @param {Function} callee The method to call.
   * @param  {...any} args The original arguments to be passed to callee during call.
   * @returns The newly created Prompt.
   */
  add(callee, ...args) {
    const prompt = new Prompt(callee, ...args);
    this.#enqueue(prompt);
    return prompt;
  }

  /**
   * Run all prompts in queue.
   *
   * This function will run all prompts in the queue.
   * The first prompt in queue will be executed and the next prompt will be executed
   * after the previous prompt is dismissed. Interruption from prompt will clear and end the queue.
   *
   * When the queue ended, the final callback will be executed.
   *
   * @param {Function=} finalCallback Replace the queue's final callback.
   * This parameter is for backward compatibility.
   */
  run(finalCallback) {
    if (finalCallback) this.finalCallback = finalCallback;

    const prompt = this.#dequeue();
    if (!prompt) {
      if (this.finalCallback) this.finalCallback();
      return;
    }

    window.setTimeout(() => {
      const newArgs = this.#wrapCallback(prompt.arguments);
      prompt.callee(...newArgs);
    }, 1000);
  }

  /* Basic queue features */

  /**
   * Add a Prompt to end of queue.
   * @param {Prompt} prompt A prompt to be added to queue.
   */
  #enqueue(prompt) {
    if (prompt instanceof Prompt) this.#queue.push(prompt);
  }

  /**
   * Remove the first item from queue.
   * @returns The item removed from queue. `undefined` if queue is empty.
   */
  #dequeue() {
    return this.#queue.splice(0, 1)[0];
  }

  /**
   * Empty the queue.
   */
  #clear() {
    this.#queue = [];
  }

  /* End of basic queue features */

  /**
   * Wrap the callback function in argument list.
   *
   * This function assume the last function in arguments is a callback.
   * Compatible with sweetalert 1.1.3 and abp.sweet-alert.js.
   * @param {Array} promptArgs The prompt's arguments.
   * @returns A cloned arguments list with callback wrapped.
   */
  #wrapCallback(promptArgs) {
    const newArgs = [...promptArgs];

    // Find callback function to replace.
    // Assume the last function in arguments is a callback function.

    const callbackIndex = _.findLastIndex(newArgs, e => _.isFunction(e)); // index or -1.
    const callback = callbackIndex !== -1 ? newArgs[callbackIndex] : undefined;

    // Wrap callback.

    const wrappedCallback = (result) => {
      let interrupt = false;

      if (callback) interrupt = callback(result) === false;
      if (interrupt) this.#clear();

      this.run();
    };

    // Replace wrapped callback into arguments.
    // If no callback function was found previously,
    // append to arguments because callbackIndex is -1.

    newArgs.splice(callbackIndex, 1, wrappedCallback);

    return newArgs;
  }
}

export default PromptQueue;
