(function () {
    'use strict';

    angular.module('app').component('corporateBenefitGroupsCreateEditMobileAppComponent', {
        templateUrl: require('/App/corporate/views/benefitGroups/createEdit/mobileApp/mobileApp.component.html'),
        controller: controller,
        controllerAs: 'vm',
        bindings: {
            corporateHasMobileNpr: '<',
            benefitGroup: '<',
            isReadOnly: '<'
        }
    });

    function controller() {
        var vm = this;
    }
})();

