(function () {
    'use strict';
    var controllerId = 'clinic.views.hospital.inpatientDashboard';
    var app = angular.module('app');

    app.controller(controllerId, ['abp.services.app.commonLookup', 'lookupModal', 'appSession', 'moment', controller]);

    function controller(commonLookupSvc, lookupModal, appSession, moment) {
        var vm = this;
        vm.selectLocation = selectLocation;

        vm.panelLocationId = 0;
        vm.locations = [];
        vm.refresh = 0;

        var storageKey = 'inpatientDashboard_user';
        vm.cachePanelUser = App.getStorageJsonObj(storageKey);

        function init() {
            getClinicLocations();

            if (!vm.cachePanelUser
                || vm.cachePanelUser.expiryTime === null
                || (vm.cachePanelUser.userId !== appSession.user.id
                    || moment().format() > vm.cachePanelUser.expiryTime)) {
                getDefaultClinicLocation();
            } else {
                vm.panelLocationId = vm.cachePanelUser.defaultLocationId;
            }
        }

        function setLocation(location) {
            vm.panelLocationId = parseInt(location.value, 10) || 0;
        }

        function selectLocation(locationId) {
            vm.panelLocationId = locationId;
            setLocalStorage();
            vm.refresh++;
        }

        function getClinicLocations() {
            commonLookupSvc.findClinicLocations({
                skipCount: 0,
                maxResultCount: 999
            }).success(function (data) {
                vm.locations = data.items;
            });
        }

        function getDefaultClinicLocation() {
            commonLookupSvc.getDefaultClinicLocation()
                .success(function (data) {
                    setLocation(data);
                })
                .finally(function () {
                    if (!vm.panelLocationId) {
                        openLocationModal();
                    } else {
                        setLocalStorage();
                    }
                });
        }

        function openLocationModal() {
            lookupModal.open({
                title: App.localize('SelectPanelLocation'),
                serviceMethod: commonLookupSvc.findClinicLocations,
                callback: function (location) {
                    vm.panelLocationId = location.value;
                    selectLocation(location.value);
                }
            });
        }

        function setLocalStorage() {
            var panelUser = {
                userId: appSession.user.id,
                defaultLocationId: vm.panelLocationId,
                expiryTime: moment().add(1, 'days').format()
            };
            App.saveAsJsonToStorage(storageKey, panelUser);
        }

        init();
    }
})();
