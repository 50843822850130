(function () {
    'use strict';

    angular.module('app').component('hostInpatientGuaranteeLettersCommonBalanceOverviewComponent', {
        templateUrl: require('/App/host/views/inpatientGuaranteeLetters/common/balanceOverview/balanceOverview.component.html'),
        controller: ['abp.services.app.hostServiceRequest', controller],
        controllerAs: 'vm',
        bindings: {
            serviceRequestId: '<',
            refreshTrigger: '<',
            newGuaranteeAmount: '=?',
            requestAmount: '=?',
            employeeAllowance: '=?',
            currencyCode: '<'
        }
    });

    function controller(hostServiceRequestSvc) {
        var vm = this;

        vm.$onChanges = onChanges;
        vm.getOrganizationNewBalance = getOrganizationNewBalance;
        vm.getMemberNewBalance = getMemberNewBalance;
        vm.getOrganizationNewGuaranteeAndCurrentLockedAmountChanges = getOrganizationNewGuaranteeAndCurrentLockedAmountChanges;
        vm.getMemberNewGuaranteeAndCurrentLockedAmountChanges = getMemberNewGuaranteeAndCurrentLockedAmountChanges;
        vm.defaultMaxAllowance = parseFloat(abp.setting.get("Hms.General.MaxAllowanceAmountLimit"));
        vm.loading = 0;
        vm.employeeAllowance = 0;
        vm.organization = null;
        vm.member = null;

        function onChanges(changes) {
            if (changes.serviceRequestId && changes.serviceRequestId.currentValue) {
                getBalanceOverview(vm.serviceRequestId);
            }

            if (changes.refreshTrigger && changes.refreshTrigger.currentValue && vm.serviceRequestId) {
                getBalanceOverview(vm.serviceRequestId);
            }
        }

        function getBalanceOverview(serviceRequestId) {
            vm.loading++;
            hostServiceRequestSvc.getBalanceOverview({
                id: serviceRequestId
            }).success(function (data) {
                vm.requestAmount = data.requestAmount;
                vm.newGuaranteeAmount = data.newGuaranteeAmount;
                vm.organization = {
                    currentAvailableAmount: data.organizationBalance.currentAvailableAmount,
                    currentLockedAmount: data.organizationBalance.currentLockedAmount
                };
                vm.member = {
                    currentAvailableAmount: Math.min(data.memberBalance.currentAvailableAmount, vm.defaultMaxAllowance),
                    currentLockedAmount: data.memberBalance.currentLockedAmount,
                };

                vm.employeeAllowance = vm.member.currentAvailableAmount + vm.member.currentLockedAmount;
            }).finally(function () {
                vm.loading--;
            });
        }

        function getOrganizationNewBalance() {
            if (vm.organization && !_.isNil(vm.organization.currentAvailableAmount)) {
                if (getOrganizationNewGuaranteeAndCurrentLockedAmountChanges() === 0) {
                    return vm.organization.currentAvailableAmount;
                }
                else {
                    return (vm.organization.currentAvailableAmount + vm.organization.currentLockedAmount) - vm.newGuaranteeAmount;
                }
            }
        }

        function getMemberNewBalance() {
            if (vm.member && !_.isNil(vm.member.currentAvailableAmount)) {
                return (vm.member.currentAvailableAmount + vm.member.currentLockedAmount) - vm.newGuaranteeAmount;
            }
        }

        function getOrganizationNewGuaranteeAndCurrentLockedAmountChanges() {
            if (vm.organization && !_.isNil(vm.organization.currentLockedAmount)) {
                if (vm.organization.currentLockedAmount == 0)
                    return 0;

                var amountDifference = vm.newGuaranteeAmount - vm.organization.currentLockedAmount;
                return amountDifference > 0 ? amountDifference : 0;
            }
        }

        function getMemberNewGuaranteeAndCurrentLockedAmountChanges() {
            if (vm.member && !_.isNil(vm.member.currentLockedAmount)) {
                if (vm.member.currentLockedAmount == 0)
                    return 0;

                var amountDifference = vm.newGuaranteeAmount - vm.member.currentLockedAmount;
                return amountDifference > 0 ? amountDifference : 0;
            }
        }
    }
})();
