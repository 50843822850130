(() => {
  angular
    .module('app')
    .controller(
      'common.views.saleTransactions.quickSelectTransferAdvice',
      QuickSelectTransferAdviceController
    );

  QuickSelectTransferAdviceController.$inject = ['$uibModalInstance'];

  function QuickSelectTransferAdviceController($uibModalInstance) {
    const vm = this;

    vm.saving = 0;

    vm.cancel = cancel;
    vm.save = save;

    init();

    function init() {}

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function save() {
      const output = [];
      const textLines = vm.text.split('\n');

      _.each(textLines, (val) => {
        output.push(val.trim());
      });

      $uibModalInstance.close(output);
    }
  }
})();
