(() => {
  angular
    .module('app')
    .controller(
      'common.views.specialistClaim.processClaim.claimPolicyModal',
      ClaimPolicyModalController
    );

  ClaimPolicyModalController.$inject = ['$uibModalInstance', 'memberId'];

  function ClaimPolicyModalController($uibModalInstance, memberId) {
    const vm = this;

    vm.memberId = memberId;

    vm.cancel = cancel;

    init();

    function init() {}

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
