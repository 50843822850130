(function () {
    var componenetId = 'hostOperationsDashboardMonthlyManualTicketStatisticsDetail';
    var app = angular.module('app');

    function controller() {
        var vm = this;
        vm.corporate = vm.resolve.corporate;

        vm.close = function () {
            vm.modalInstance.dismiss();
        };
    }

    app.component(componenetId, {
        templateUrl: require('/App/host/views/operationsDashboard/monthlyManualTicketStatistics/detailModal.html'),
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: [controller],
        controllerAs: 'vm'
    })
})();
