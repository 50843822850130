(function () {
  'use strict';

  const app = angular.module('app');
    app.controller('host.views.claims.inpatientClaims.adjustmentRequest', [
      '$scope', '$state', '$uibModal', '$stateParams',
      'abp.services.app.hostServiceRequest', 'abp.services.app.commonLookup', 'moment',
      controller
    ]);

  function controller($scope, $state, $modal, $stateParams, hostServiceRequestSvc, commonLookupSvc, moment) {
    var vm = this;
    vm.letterNumber = $stateParams.letterNumber;
    vm.isHost = App.isHost();
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.isEditRequestDetail = false;
    vm.isEditClaimDetail = false;
    vm.isClaim = true;
    vm.checkEditMode = checkEditMode;
    vm.isOnHold = false;
    vm.requestDetailsTrigger = 0;
    vm.checkSaveTrigger = checkSaveTrigger;
    vm.checkFormFilled = checkFormFilled;

    vm.openApprovalModal = openApprovalModal;
    vm.openRejectionModal = openRejectionModal;
    vm.openDelegationModal = openDelegationModal;
    vm.openRecommendationModal = openRecommendationModal;
    vm.openEscalationModal = openEscalationModal;
    vm.query = query;
    vm.preview = preview;
    vm.isTallied = true;
    vm.isFinalBillTallied = true;
    vm.isFormFilled = false;
    vm.requestAmount = null;
    vm.totalCoveredAmount = null;
    vm.claimNumber = $stateParams.claimNumber;
    vm.timeZoneId = $stateParams.timeZoneId;
    vm.isInsuranceBenefitGroup = false;

    var delegateUsers = [];
    var recommendUsers = [];
    vm.coPay = {
      isRoomAndBoardExceedEntitlement: false,
      isCoInsurance: false,
      isPercentage: false,
      coPayExclusions: [],
      value: 0
    };
    vm.roomAndBoardCharges = 0;

    vm.requestStatus = 'Pending approval';
    vm.isMaternity = false;

    vm.hasSobEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');
    vm.hasTxtFileSupport = abp.setting.getBoolean('Hms.Feature.TxtFileSupport');

    function init() {
      vm.loading++;
      hostServiceRequestSvc.getAdjustmentRequestDetails({
        id: vm.letterNumber
      }).success(function (data) {
        vm.requestInfo = data;
        vm.isOnHold = data.request.isOnHold;
        vm.requestInfo.request.letterNumber = vm.letterNumber;
        vm.printUrl = data.printUrl;
        vm.hasUnresolvedQuery = data.request.hasUnresolvedQuery;
        vm.isMaternity = data.request.serviceType === 8;
        vm.isInsuranceBenefitGroup = data.patient.isInsuranceBenefitGroup;
        vm.currencyCode = data.request.currencyCode;
        vm.countryCode = data.request.countryCode;
      }).finally(function (e) {
        vm.loading--;
      });
    }

    // watch for changes to selectedDoctorIds and update availableDischargingDoctor list accordingly

    $scope.$watch('vm.requestInfo.request.selectedDoctorIds', function () {
      if (vm.requestInfo) {
        //compare and add doctors from selected add-on doctors that are not in the available discharging doctor list
        vm.loading++;
        hostServiceRequestSvc.updateSelectableDischargingDoctors({
          selectedAddOnDoctorIds: vm.requestInfo.request.selectedDoctorIds,
          availableDischargeDoctors: vm.requestInfo.request.availableDischargeDoctors
        }).success(function (data) {
          vm.requestInfo.request.availableDischargeDoctors = data;
          $scope.$broadcast("availableDischargeDoctorsChanged", data);
        }).finally(function () {
          vm.loading--;
        });
      }
    });

    function checkEditMode(isEdit) {
      vm.isEdit = isEdit;
    }

    function checkSaveTrigger() {
      vm.requestDetailsTrigger++;
    }

    function approve(remarks) {
      vm.loading++;
      var rooms = [];
      var procedures = [];
      var others = [];
      var doctorProcedures = [];
      var hospitalCharges = [];

      var request = vm.requestInfo.request;

      _.each(vm.requestInfo.request.rooms, function (x) {
        _.each(x.ticketItems, function (p) {
          if (!p.roomChargeDateRangeModel) {
            p.roomChargeDateRangeModel = {
              startDate: p.startDate ? p.startDate.utc() : null,
              endDate: p.endDate ? p.endDate.utc() : null
            };
          }
          p.startDate = p.roomChargeDateRangeModel.startDate;
          p.endDate = p.roomChargeDateRangeModel.endDate;

          if (typeof p.category !== 'undefined' && p.category !== null) {
            rooms.push(p);
          }
        });
      });

      if (vm.hasSobEnhancement && !vm.isMaternity) {
        _.each(vm.requestInfo.request.procedures, function (x) {
          _.each(x.ticketItems, function (p) {
            p.startDate = p.visitDate ? moment(p.visitDate).utc() : null;
            p.endDate = p.visitDate ? moment(p.visitDate).utc() : null;

            // Total visit is always 1.

            if (!p.totalVisit || p.totalVisit !== 1) {
              p.totalVisit = 1;
            }

            if ((typeof p.doctorId !== 'undefined' &&
                p.doctorId !== null) ||
              vm.hasTxtFileSupport) {
              procedures.push(p);
            }
          });
        });

        _.each(vm.requestInfo.request.doctorProcedures, function (x) {
          _.each(x.ticketItems, function (p) {
            if (typeof p.category !== 'undefined' && p.category !== null) {
              doctorProcedures.push(p);
            }
          });
        });

        _.each(vm.requestInfo.request.hospitalCharges, function (x) {
          _.each(x.ticketItems, function (p) {
            if (typeof p.category !== 'undefined' && p.category !== null) {
              hospitalCharges.push(p);
            }
          });
        });
      } else {
        _.each(vm.requestInfo.request.procedures, function (x) {
          _.each(x.ticketItems, function (p) {
            if (!p.procedureChargeDateRangeModel) {
              p.procedureChargeDateRangeModel = {
                startDate: p.startDate ? p.startDate.utc() : null,
                endDate: p.endDate ? p.endDate.utc() : null
              };
            }
            p.startDate = p.procedureChargeDateRangeModel.startDate;
            p.endDate = p.procedureChargeDateRangeModel.endDate;

            if (typeof p.category !== 'undefined' && p.category !== null) {
              procedures.push(p);
            }
          });
        });

        _.each(vm.requestInfo.request.others, function (x) {
          if (typeof x.category !== 'undefined' && x.category !== null) {
            others.push(x);
          }
        });
      }

      request.rooms = rooms;
      request.procedures = procedures;

      if (vm.hasSobEnhancement && !vm.isMaternity) {
        request.doctorProcedures = doctorProcedures;
        request.hospitalCharges = hospitalCharges;
      } else {
        request.others = others;
      }

      var input = $.extend(request, {
        remarks: remarks,
        currentAllowance: vm.employeeAllowance
      });

      input.diagnoses = _.map(request.diagnoses, function (d) {
        // Free-text entry has null diagnosis code.

        return {
          id: d.id,
          code: _.isNumber(d.code) ? null : d.code,
          description: d.description,
          diagnosisDate: d.diagnosisDate
        }
      });

      hostServiceRequestSvc.approveAdjustmentRequest(input)
        .success(function (data) {
          abp.notify.success(App.localize('Approved'));
          navigateToListing();
        }).error(function (data) {
          /* Error code 1 == Insufficient allowance */
          if (data.code == 1) {
            /* Insufficient employee balance for request amount */
            swal({
              title: App.localize('InvalidAmount'),
              text: App.localize('InsufficientEmployeeBalanceNarrative') + '\n' +
                App.localize('RefreshThePageAndEditTheAmount'),
              confirmButtonColor: '#1AB394',
              confirmButtonText: App.localize('RefreshNow')
            }, function (isConfirm) {
              if (isConfirm) {
                location.reload();
              }
            })
          }
        }).finally(function () {
          vm.loading--;
        });
    }

    function reject(remarks) {
      vm.loading++;
      hostServiceRequestSvc.rejectAdjustmentRequest({
        id: vm.requestInfo.request.id,
        remarks: remarks
      }).success(function (data) {
        abp.notify.success(App.localize('Rejected'));
        navigateToListing();
      }).finally(function () {
        vm.loading--;
      });
    }

    function delegate(recipient, remarks) {
      vm.loading++;
      hostServiceRequestSvc.delegateRequest({
        processorUserId: recipient.operatorUserId,
        serviceRequestId: vm.requestInfo.request.id,
        letterNumber: vm.letterNumber,
        emailAddress: recipient.operatorEmailAddress,
        remarks: remarks
      }).success(function (data) {
        abp.notify.success(App.localize('ServiceRequestDelegated'));
        navigateToListing();
      }).finally(function () {
        vm.loading--;
      });
    }

    function recommend(recipient, remarks) {
      vm.loading++;
      hostServiceRequestSvc.recommendRequest({
        processorUserId: recipient.operatorUserId,
        serviceRequestId: vm.requestInfo.request.id,
        letterNumber: vm.letterNumber,
        emailAddress: recipient.operatorEmailAddress,
        remarks: remarks
      }).success(function (data) {
        abp.notify.success(App.localize('ServiceRequestRecommended'));
        navigateToListing();
      }).finally(function () {
        vm.loading--;
      });
    }

    function query() {
      if (vm.hasUnresolvedQuery) {
        $state.go('inpatientGuaranteeLettersDetails', {
          letterNumber: vm.letterNumber,
          timeZoneId: vm.timeZoneId,
          tab: 3
        });
      } else {
        $state.go('createQuery', {
          letterNumber: vm.letterNumber,
          timeZoneId: vm.timeZoneId
        });
      }
    }

    function preview() {
      window.open(vm.printUrl);
    }

    function checkFormFilled(data) {
      vm.isFormFilled = data;
    }

    function openApprovalModal() {
      $scope.$broadcast('validateProcessingInput');

      if (vm.isFormFilled) {
        if (!vm.isTallied) {
          vm.isFinalBillTallied = false;
          abp.message.error(App.localize("FinalBillNotTallied"));
          return;
        } else {
          vm.isFinalBillTallied = true;
        }

        if (!vm.isEdit) {
          $modal.open({
            templateUrl: require('/App/common/views/modals/remarks/remarks.modal.html'),
            controller: 'common.views.modals.remarksModal as vm',
            backdrop: 'static',
            resolve: {
              title: function () {
                return App.localize('ApprovalConfirmation');
              },
              existingRemarks: function () {
                  return null;
              },
              remarksMaxLength: function () {
                  return app.consts.serviceRequestMaxLength;
              }
            }
          }).result.then(function (remarks) {
            approve(remarks);
          });
        } else {
          promptEditModeWarning();
        }
      } else {
        abp.message.error(App.localize('OneOrMoreInputValueIsInvalid'));
        return;
      }
    }

    function openRejectionModal() {
      if (!vm.isEdit) {
        $modal.open({
          templateUrl: require('/App/common/views/modals/remarks/remarks.modal.html'),
          controller: 'common.views.modals.remarksModal as vm',
          backdrop: 'static',
          resolve: {
            title: function () {
              return App.localize('RejectionConfirmation');
            },
            existingRemarks: function () {
                return null;
            },
            remarksMaxLength: function () {
                return app.consts.serviceRequestMaxLength;
            }
          }
        }).result.then(function (remarks) {
          reject(remarks);
        });
      } else {
        promptEditModeWarning();
      }
    }

    function openDelegationModal() {
      getServiceRequestClaimProcessors(function () {
        if (!vm.isEdit) {
          $modal.open({
            templateUrl: require('/App/common/views/modals/notifyRecipient/notifyRecipient.modal.html'),
            controller: 'common.views.modals.notifyRecipientModal as vm',
            backdrop: 'static',
            resolve: {
              users: function () {
                return delegateUsers;
              },
              title: function () {
                return App.localize('DelegationConfirmation');
              },
              message: function () {
                return App.localize('DelegationWarningMessage')
              }
            }
          }).result.then(function (result) {
            delegate(result.recipient, result.remarks);
          });
        } else {
          promptEditModeWarning();
        }
      })
    }

    function openRecommendationModal() {
      getServiceRequestOverridingApprovers(function () {
        if (!vm.isEdit) {
          $modal.open({
            templateUrl: require('/App/common/views/modals/notifyRecipient/notifyRecipient.modal.html'),
            controller: 'common.views.modals.notifyRecipientModal as vm',
            backdrop: 'static',
            resolve: {
              users: function () {
                return recommendUsers;
              },
              title: function () {
                return App.localize('RecommendationConfirmation');
              },
              message: function () {
                return App.localize('RecommendationWarningMessage')
              }
            }
          }).result.then(function (result) {
            recommend(result.recipient, result.remarks);
          });
        } else {
          promptEditModeWarning();
        }
      })
    }

    function promptEditModeWarning() {
      abp.message.error(App.localize('SaveOrCancelChangesNarrative'));
    }

    function navigateToListing() {
      $state.go('host.inpatientGuaranteeLetters');
    }

    function getServiceRequestClaimProcessors(_callback) {
      vm.loading++

      commonLookupSvc.getServiceRequestClaimProcessor()
        .success(function (data) {
          // Filter away current user
          delegateUsers = _.remove(data.items, function (n) {
            return n.value.operatorUserId !== abp.session.userId;
          });
        }).finally(function () {
          vm.loading--;
          _callback();
        })
    }

    function getServiceRequestOverridingApprovers(_callback) {
      vm.loading++

      commonLookupSvc.getServiceRequestOverridingApprover()
        .success(function (data) {
          recommendUsers = _.remove(data.items, function (n) {
            return n.value.operatorUserId !== abp.session.userId;
          });
        }).finally(function () {
          vm.loading--;
          _callback();
        })
    }

    function openEscalationModal() {
      if (!vm.isEdit) {
          $modal.open({
              templateUrl: require('/App/common/views/modals/remarks/remarks.modal.html'),
              controller: 'common.views.modals.remarksModal as vm',
              backdrop: 'static',
              resolve: {
                  title: function () {
                      return App.localize('EscalationConfirmation');
                  },
                  existingRemarks: function () {
                      return vm.requestInfo.request.escalationRemarks;
                  },
                  remarksMaxLength: function () {
                      return app.consts.serviceRequestMaxLength;
                  }
              }
          }).result.then(function (remarks) {
              escalate(remarks);
          });
      } else {
          promptEditModeWarning();
      }
    }

    function escalate(remarks) {
          vm.loading++;
          hostServiceRequestSvc.escalateGuaranteeLetterRequest({
              id: vm.requestInfo.request.id,
              isResolve: true,
              escalationRemark: remarks
          }).success(function () {
              abp.notify.success(App.localize('GlEscalated'));
              navigateToListing();
          }).finally(function () {
              vm.loading--;
          });
    }

    init();
  }
})();
