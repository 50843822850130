(() => {
  angular.module('app').controller('common.views.modals.remarksModal', RemarksModalController);

  RemarksModalController.$inject = [
    '$uibModalInstance',
    'title',
    'existingRemarks',
    'remarksMaxLength',
  ];

  function RemarksModalController($uibModalInstance, title, existingRemarks, remarksMaxLength) {
    const vm = this;

    vm.remarks = _.isNull(existingRemarks) ? null : existingRemarks;
    vm.title = title;
    vm.remarksMaxLength = remarksMaxLength;
    vm.remarksTooLongWarning = App.localize('RemarksTooLongWarning', remarksMaxLength);

    vm.cancel = cancel;
    vm.submit = submit;

    init();

    function init() {
      vm.reject = App.localize('RejectionConfirmation') === vm.title;
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function submit() {
      if (App.isFormValid(vm.modalForm)) {
        $uibModalInstance.close(vm.remarks);
      }
    }
  }
})();
