(function () {
    var controllerId = 'host.views.masterOperators.createEditMasterOperator';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', 'abp.services.app.masterOperator', 'Hms.Users.User', 
        function ($scope, $state, $stateParams, masterOperatorSvc, constsUser) {
            function init() {
                getMasterOperator();
            }

            var vm = this;
		        vm.constsUser = constsUser;
            vm.operatorId = $stateParams.masterOperatorId || 0;
            vm.loading = 0;
            vm.saving = 0;
            vm.masterOperator = null;
            vm.users = null;

            // TODO: Remove inpatient module flag

            vm.hasInpatientModule = abp.setting.getBoolean('Hms.Feature.InpatientModule');

            vm.permissions = {
                edit: abp.auth.isGranted('Host.MasterOperators.Edit'),
                'delete': abp.auth.isGranted('Host.MasterOperators.Delete')
            };

            function getMasterOperator() {
                vm.loading++;
                masterOperatorSvc.getMasterOperatorForEdit({
                    id: vm.operatorId
                }).success(function (data) {
                    vm.users = data.users;
                    vm.masterOperator = data.masterOperator;
                }).finally(function (e) {
                    vm.loading--;
                });
            }

            vm.showEmail = function () {
                if (vm.masterOperator !== null) {
                    var selected = _.find(vm.users, function (data) {
                        return data.id === vm.masterOperator.userId;
                    });
                    vm.masterOperator.userEmailAddress = selected.emailAddress;
                }
            };

            vm.submit = function () {
                vm.saving++;
                masterOperatorSvc.createOrUpdateMasterOperator(vm.masterOperator)
                    .success(function () {
                        abp.notify.info(App.localize('SuccessfullySaved'));
                        $state.go('host.masterOperators');
                    }).finally(function () {
                        vm.saving--;
                    });
            };

            init();
        }
    ]);
})();
