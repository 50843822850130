(() => {
  angular
    .module('app')
    .controller('common.views.saleTransactions.transferAdvice', TransferAdviceController);

  TransferAdviceController.$inject = [
    '$state',
    '$scope',
    '$stateParams',
    '$uibModal',
    'abp.services.app.saleTransaction',
    'abp.services.app.commonLookup',
    'countries',
    'Hms.Payments.Payment',
  ];

  function TransferAdviceController(
    $state,
    $scope,
    $stateParams,
    $uibModal,
    saleTransactionSvc,
    commonLookupSvc,
    countries,
    constsPayment
  ) {
    const vm = this;
    const maxResultCountOptions = [100, 200, 500];

    vm.constsPayment = constsPayment;
    vm.loading = 0;
    vm.saving = 0;
    vm.maxResultCountOptions = maxResultCountOptions;
    vm.requestParams = {
      transactionNumber: $stateParams.transactionNumber,
      requestId: $stateParams.requestId,
      skipCount: $stateParams.skipCount,
      maxResultCount: $stateParams.maxResultCount,
    };
    vm.checkAll = false;
    vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');
    vm.currencyCode = null;
    vm.stopInvoiceGeneration = abp.setting.getBoolean('Hms.Feature.StopPortalInvoiceGeneration');

    vm.quickSelect = quickSelect;
    vm.refreshSelectedFigures = refreshSelectedFigures;
    vm.send = send;
    vm.refresh = refresh;

    class PagedData {
      // Properties

      constructor() {
        this.totalDescription = '';
        this.currentPage = 1;
        this.maxPages = 0;

        this.totalCount = 0;
        this.records = [];

        this.requestParams = {
          transactionNumber: $stateParams.transactionNumber,
          requestId: $stateParams.requestId,
          skipCount: $stateParams.skipCount,
          maxResultCount: $stateParams.maxResultCount,
        };

        this.initialLoad();
      }

      // Page navigations

      goToPage(page) {
        let p = page;

        if (!p || p < 1) {
          p = 1;
        } else if (p > this.maxPages) {
          p = this.maxPages;
        }

        this.currentPage = p;
        const skipCount = (this.currentPage - 1) * this.requestParams.maxResultCount;
        this.requestParams.skipCount = skipCount;
        this.getTransferAdvices();
      }

      nextPage() {
        this.goToPage(this.currentPage + 1);
      }

      prevPage() {
        this.goToPage(this.currentPage - 1);
      }

      refreshPage() {
        this.goToPage(this.currentPage);
      }

      // Set table configurations.

      setItemsPerPage() {
        this.maxPages = Math.ceil(this.totalCount / this.requestParams.maxResultCount);

        // To reset the current page number.

        if (this.maxPages === 0) {
          this.currentPage = 0;
        } else if (this.maxPages !== 0) {
          this.currentPage = (this.requestParams.skipCount / this.requestParams.maxResultCount) + 1;
        }
      }

      // Refresh table.

      initialLoad() {
        this.getTransferAdvices();
      }

      // Retrieve data export record.

      getTransferAdvices() {
        this.registerStateParams();
        vm.loading += 1;
        saleTransactionSvc
          .getTransferAdvices(this.requestParams)
          .success((data) => {
            vm.advices = data.transferAdvices;
            const country = _.find(countries, ['code', data.countryCode]);
            vm.currencyCode = country.currencyCode;

            _.each(vm.advices, (advice) => {
              const a = advice;
              a.isMarkedForEmail = !a.hasSent;
              a.currencyCode = country.currencyCode;
            });

            vm.checkAll = _.every(vm.advices, 'isMarkedForEmail');
            vm.refreshSelectedFigures();
            
            this.totalCount = data.totalCount;
            this.totalDescription = App.localize('TotalXItems', this.totalCount);
            this.setItemsPerPage();
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }

      registerStateParams() {
        $state.transitionTo(
          $state.current,
          {
            transactionNumber: this.requestParams.transactionNumber,
            requestId: this.requestParams.requestId,
            skipCount: this.requestParams.skipCount,
            maxResultCount: this.requestParams.maxResultCount,
          },
          {
            notify: false,
          }
        );
      }
    }

    init();

    function init() {
      vm.table = new PagedData();

      $scope.$watch('vm.checkAll', () => {
        _.each(vm.advices, (advice) => {
          const a = advice;
          a.isMarkedForEmail = vm.checkAll;
        });
        vm.refreshSelectedFigures();
      });

      vm.refresh();
      if (vm.hasRegionalSystemSupport) getEligibleCountries();
    }

    function quickSelect() {
      $uibModal
        .open({
          templateUrl: require('./quickSelectTransferAdvice.html'),
          controller: 'common.views.saleTransactions.quickSelectTransferAdvice as vm',
          backdrop: 'static',
        })
        .result.then((piNumbers) => {
          _.each(vm.advices, (advice) => {
            const a = advice;
            a.isMarkedForEmail = _.some(piNumbers, (piNumber) => a.piNumber === piNumber);
          });
          vm.refreshSelectedFigures();
        });
    }

    function refreshSelectedFigures() {
      let amt = 0;
      let cnt = 0;
      _.each(vm.advices, (advice) => {
        if (advice.isMarkedForEmail) {
          amt += advice.totalAmount;
          cnt += 1;
        }
      });
      vm.selectedCount = cnt;
      vm.selectedAmount = amt;
    }

    function refresh() {
      vm.table.getTransferAdvices();
    }

    function send() {
      const params = _(vm.advices)
        .filter('isMarkedForEmail')
        .map((advice) => ({
          paymentInstructionId: advice.id,
          emailAddresses: advice.emailAddresses,
        }))
        .value();

      vm.saving += 1;
      saleTransactionSvc
        .sendTransferAdvices({
          paymentDate: vm.paymentDate,
          referenceNumber: vm.referenceNumber,
          params,
        })
        .success(() => {
          abp.notify.success(App.localize('SuccessfullySent'));
          vm.referenceNumber = null;
          vm.refresh();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function getEligibleCountries() {
      vm.loading += 1;
      commonLookupSvc
        .getEligibleCountries()
        .success((data) => {
          vm.countries = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
