(() => {
  angular
    .module('app')
    .controller(
      'common.views.guaranteeLetters.details.letterDetail.editGuaranteedAmountModal',
      EditGuaranteedAmountModalController
    );

  EditGuaranteedAmountModalController.$inject = [
    '$uibModalInstance',
    'abp.services.app.guaranteeLetter',
    'letterNumber',
    'guaranteedAmount',
  ];

  function EditGuaranteedAmountModalController(
    $uibModalInstance,
    guaranteeLetterSvc,
    letterNumber,
    guaranteedAmount
  ) {
    const vm = this;

    vm.letterNumber = letterNumber;
    vm.oldAmount = guaranteedAmount;

    vm.saving = 0;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

    vm.submit = submit;
    vm.cancel = cancel;

    init();

    function init() {}

    function submit() {
      vm.saving += 1;
      guaranteeLetterSvc
        .updateIndicativeGuaranteedAmount({
          id: vm.letterNumber,
          newAmount: vm.newAmount,
        })
        .success(() => {
          $uibModalInstance.close(true);
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
