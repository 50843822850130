/* global __SMS_ENDPOINT__ */

(() => {
  const smsModule = angular.module('sms');
  // View SMS logs
  smsModule.factory('smsService', [
    '$http',
    ($http) =>
      new (function () {
        this.getSmsLogs = (input, httpParams) => {
          // Format start date and end date
          input.startDate = new Date(input.startDate);
          input.endDate = new Date(input.endDate);
          return $http(
            angular.extend(
              {
                url: `${__SMS_ENDPOINT__}/sms/logs`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };
        this.sendSms = (input, httpParams) =>
          $http(
            angular.extend(
              {
                url: `${__SMS_ENDPOINT__}/sms/send`,
                method: 'POST',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );

        this.getSmsProvider = (input, httpParams) =>
          $http(
            angular.extend(
              {
                url: `${__SMS_ENDPOINT__}/sms/provider`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );

        this.createSmsProvider = (input, httpParams) =>
          $http(
            angular.extend(
              {
                url: `${__SMS_ENDPOINT__}/sms/provider`,
                method: 'POST',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );

        this.updateSmsProvider = (input, httpParams) =>
          $http(
            angular.extend(
              {
                url: `${__SMS_ENDPOINT__}/sms/provider`,
                method: 'PUT',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );

        this.deleteSmsProvider = (input, httpParams) =>
          $http(
            angular.extend(
              {
                url: `${__SMS_ENDPOINT__}/sms/provider`,
                method: 'DELETE',
                params: input,
              },
              httpParams
            )
          );

        this.getSmsProviders = (input, httpParams) =>
          $http(
            angular.extend(
              {
                url: `${__SMS_ENDPOINT__}/sms/providers`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );

        this.getCountries = (input, httpParams) =>
          $http(
            angular.extend(
              {
                url: `${__SMS_ENDPOINT__}/sms/countries`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
      })(),
  ]);
})();
