(() => {
  angular
    .module('app')
    .component('commonClaimsProcessClaimDetailSpecialistTreatmentsDiagnosisComponent', {
      templateUrl: require('./specialistTreatmentsDiagnosis.component.html'),
      controller: SpecialistTreatmentsDiagnosisController,
      controllerAs: 'vm',
      bindings: {
        diagnoses: '=',
      },
    });

  SpecialistTreatmentsDiagnosisController.$inject = [];

  function SpecialistTreatmentsDiagnosisController() {
    const vm = this;

    vm.filteredDiagnoses = [];
    const limitSize = 30;

    vm.lookupDiagnoses = lookupDiagnoses;
    vm.tidyDiagnosis = tidyDiagnosis;

    init();

    function init() {}

    function lookupDiagnoses(filter) {
      const keyword = _.trim(filter || '');
      if (keyword) {
        // Include free-text entry in filtered data set.

        const filteredDiagnoses = [
          {
            item: {
              description: keyword,
              displayName: keyword,
              // Default to -1 because free-text entry has no corresponding
              // disease classification ID.
              code: -1,
            },

            // Set free-text entry with high negative score so that it stays as first choice.
            score: -100,
          },
        ];

        const regexes = _.chain(keyword.split(' '))
          .map((k) => _.trim(k.toUpperCase()))
          .filter((k) => k)
          .uniq()
          .map((k) => {
            _.escapeRegExp(k);
            return {
              token: k,
              len: k.length,
              pattern: new RegExp(`\\b${k}`, 'i'),
            };
          })
          .value();

        _.forEach(vm.diagnoses.data, (data) => {
          let score = 0;

          // Exclude selected data.

          const isSelected = _.find(vm.diagnoses.selected, (s) => s.code === data.code);

          if (!isSelected) {
            _.forEach(regexes, (r) => {
              if (r.pattern.test(data.displayName)) {
                score -= r.len;
              }
            });
          }

          if (score) {
            filteredDiagnoses.push({
              score,
              item: data,
            });
          }
        });

        vm.filteredDiagnoses = _.chain(filteredDiagnoses)
          .sortBy(['score'])
          .take(30)
          .map((k) => k.item)
          .uniqBy((s) => s.code)
          .value();
      } else {
        getDiagnoses();
      }
    }

    function getDiagnoses() {
      let tempDiagnoses = [];
      _.forEach(vm.diagnoses.data, (diagnosis) => {
        const selectedDiagnosis = _.find(vm.diagnoses.selected, { code: diagnosis.code });

        if (!selectedDiagnosis) {
          tempDiagnoses.push(diagnosis);
        }
      });

      tempDiagnoses = _.sortBy(tempDiagnoses, (x) => x.code);
      vm.filteredDiagnoses = _.take(tempDiagnoses, limitSize);
    }

    function tidyDiagnosis(diagnosis) {
      return {
        code: diagnosis.code < 0 ? null : diagnosis.code,
        description: diagnosis.description,
      };
    }
  }
})();
