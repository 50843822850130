(function () {
    var controllerId = 'corporate.views.employees.dataConsent';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$stateParams',
        function ($stateParams) {
            var vm = this;
            vm.memberName = $stateParams.name;
            vm.loading = 0;
            vm.memberDisplayName = null;
            vm.canDelegateConsent = false;
            vm.hasDelegateDataConsentGranted = false;
        }
    ]);
})();
