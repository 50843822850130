(function () {
    var controllerId = 'host.views.corporates.masterPolicies.masterPolicies.page';
    var app = angular.module('app');
    app.controller(controllerId, ['abp.services.app.corporate', '$stateParams',
        function (corporateSvc, $stateParams) {
            var vm = this;
            vm.organizationId = $stateParams.organizationId;
            vm.loading = 0;

            vm.loading++;
            corporateSvc.getOrganizationName({ id: vm.organizationId })
                .success(function (data) {
                    vm.organizationName = data.name;
                })
                .finally(function () {
                    vm.loading--;
                })
        }
    ]);
})();
