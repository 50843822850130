(function () {
    'use strict';

    angular.module('app').component('corporateBenefitGroupsInpatientBenefitDetailEligibilityComponent', {
        templateUrl: require('/App/corporate/views/benefitGroups/inpatient/benefitDetail/eligibility/eligibility.component.html'),
        controller: [controller],
        controllerAs: 'vm',
        bindings: {
            masterPolicy: '<'
        }
    });

    function controller() {
        var vm = this;

        function init() {
            _.map(vm.masterPolicy.enrolmentRules, function (r) {
                r.countryNames = r.nationalityCodes.join(', ');
            });
        }

        init();
    }
})();
