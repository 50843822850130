(() => {
  angular
    .module('app')
    .controller(
      'common.views.saleTransactions.generateFinanceReportsModal',
      GenerateFinanceReportsModalController
    );

  GenerateFinanceReportsModalController.$inject = [
    '$uibModalInstance',
    'abp.services.app.saleTransaction',
    'abp.services.app.commonLookup',
    'moment',
  ];

  function GenerateFinanceReportsModalController(
    $uibModalInstance,
    saleTransactionSvc,
    commonLookupSvc,
    moment
  ) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;
    vm.today = moment().startOf('day');
    vm.hasInsurerFinanceReportEnabled = abp.setting.getBoolean('Hms.Feature.InsurerFinanceReport');
    vm.stopInvoiceGeneration = abp.setting.getBoolean('Hms.Feature.StopPortalInvoiceGeneration');

    vm.requestParams = {
      category: null,
      type: 1,
      accountOwnerId: null,
    };

    vm.categories = [];
    vm.categories.push({ name: App.localize('All'), value: null });
    vm.categories.push({ name: App.localize('Panel'), value: 1 });
    vm.categories.push({ name: App.localize('Corporate'), value: 2 });
    vm.categories.push({ name: App.localize('Service'), value: 3 });
    if (abp.auth.isGranted('SaleTransactions.Host.Commissions')) {
      vm.categories.push({ name: App.localize('Introducer'), value: 4 });
    }
    vm.categories.push({ name: App.localize('Reimbursement'), value: 6 });
    vm.categories.push({ name: App.localize('Subscription'), value: 12 });
    if (vm.hasInsurerFinanceReportEnabled) {
      vm.categories.push({ name: App.localize('Insurer'), value: 13 });
    }

    vm.dateRangeOptions = App.createDateRangePickerOptions();
    vm.dateRangeModel = {
      startDate: moment().startOf('month'),
      endDate: moment().endOf('day'),
    };

    vm.save = save;
    vm.categorySelected = categorySelected;
    vm.cancel = cancel;

    init();

    function init() {
      getAccountOwners();
      fillTypesByCategory();
    }

    // Get the list of account owners to be displayed as options.

    function getAccountOwners() {
      vm.loading += 1;
      commonLookupSvc
        .getAccountOwners()
        .success((data) => {
          vm.accountOwners = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
    function save() {
      const input = $.extend({}, vm.requestParams, vm.dateRangeModel);
      input.countryCode = vm.countryCode;

      vm.saving += 1;
      saleTransactionSvc
        .generateFinanceReport(input)
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function categorySelected() {
      fillTypesByCategory(vm.requestParams.type);
    }

    function fillTypesByCategory() {
      vm.types = [];
      vm.types.push({ name: 'AR INVOICE', value: 1 });
      vm.types.push({ name: 'AR PAYMENT', value: 2 });
      vm.types.push({ name: 'AR CN', value: 3 });
      vm.types.push({ name: 'AP INVOICE', value: 4 });
      vm.types.push({ name: 'AP PAYMENT', value: 5 });
      vm.types.push({ name: 'AP CN', value: 6 });
      vm.types.push({ name: 'NPR', value: 7 });

      if (vm.requestParams.category === 12) {
        const subscriptionFilteredTypes = vm.types.filter((x) => x.value < 4);
        vm.types = subscriptionFilteredTypes;
        vm.requestParams.type = 1;
      }

      if (vm.requestParams.category === 13) {
        const insurerFilteredTypes = vm.types.filter((x) => x.value < 6 || x.value === 7);
        vm.types = insurerFilteredTypes;
        vm.requestParams.type = 1;
      }
    }
  }
})();
