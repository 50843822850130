(() => {
  angular.module('app').controller('revenue.views.settings.index', ControllerController);

  ControllerController.$inject = ['abp.services.revenue.settings', 'Abp.Configuration.Setting'];

  function ControllerController(settingsSvc, constsAbpConfig) {
    const vm = this;
    vm.constsAbpConfig = constsAbpConfig;
    vm.loading = false;
    vm.saving = false;
    vm.settings = null;

    vm.saveAll = saveAll;

    init();

    function init() {
      loadSettings();
    }

    function loadSettings() {
      if (vm.loading) return;
      vm.loading = true;
      settingsSvc
        .getSettings()
        .success((data) => {
          vm.settings = data;
        })
        .finally(() => {
          vm.loading = false;
        });
    }
    
    function saveAll() {
      if (vm.saving) return;
      vm.saving = true;
      settingsSvc
        .updateSettings(vm.settings)
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          // Prevent disabling subscription management enhancement.
          vm.preventDisableSubscriptionManagementEnhancement =
            vm.settings && vm.settings.feature.subscriptionManagementEnhancement;
        })
        .finally(() => {
          vm.saving = false;
          loadSettings();
        });
    }
  }
})();
