(() => {
  angular.module('app').component('corporateEditContact', {
    templateUrl: require('./widget.html'),
    controller: CorporateEditContactController,
    controllerAs: 'vm',
    require: {
      container: '^corporateEditContainer',
    },
    bindings: {
      data: '<',
      countryCode: '<'
    },
  });

  CorporateEditContactController.$inject = [
    '$scope',
    'Hms.Employees.Employee',
    'Hms.Users.User',
    'Hms.MultiTenancy.Tenant',
  ];

  function CorporateEditContactController($scope, constsEmployee, constsUser, constsTenant) {
    const vm = this;
    vm.constsEmployee = constsEmployee;
    vm.constsUser = constsUser;
    vm.constsTenant = constsTenant;

    vm.validateForm = validateForm;
    vm.getPayload = getPayload;
    vm.$onChanges = changes;
    vm.$onInit = init;

    function init() {
      vm.container.addWidget(this);
      convertToViewModel();
    }

    function convertToViewModel() {
      // To convert DTO received from server for addressInput component.

      if (!vm.data) return;

      vm.data.correspondenceAddress = {
        street: vm.data.correspondenceStreet,
        city: vm.data.correspondenceCity,
        state: vm.data.correspondenceState,
        countrySubdivisionId: vm.data.correspondenceCountrySubdivisionId,
        postcode: vm.data.correspondencePostcode,
        countryCode: vm.data.correspondenceCountryCode,
      };
    }

    function convertToDto() {
      // To convert addressInput data model for input for server.

      if (!vm.data || !vm.data.correspondenceAddress) return;

      vm.data.correspondenceStreet = vm.data.correspondenceAddress.street;
      vm.data.correspondenceCity = vm.data.correspondenceAddress.city;
      vm.data.correspondenceState = vm.data.correspondenceAddress.state;
      vm.data.correspondencePostcode = vm.data.correspondenceAddress.postcode;
      vm.data.correspondenceCountryCode = vm.data.correspondenceAddress.countryCode;
      vm.data.correspondenceCountrySubdivisionId =
        vm.data.correspondenceAddress.countrySubdivisionId;
    }

    // Force validation on this form.
    // True if valid, otherwise, false.

    function validateForm(error) {
      const form = $scope.corporateEditContactForm;
      App.touchFormErrors(form);

      vm.invalidDataPostcode = vm.data.postcode ? vm.invalidDataPostcode : false;

      vm.invalidCorrespondencePostcode = vm.data.correspondenceAddress.postcode
        ? vm.invalidCorrespondencePostcode
        : false;

      error = !(form.$valid && !vm.invalidDataPostcode && !vm.invalidCorrespondencePostcode);
    }
    
    // Generate the payload from this form to send to API.

    function getPayload() {
      convertToDto();
    }

    function changes() {
      convertToViewModel();
    }
  }
})();
