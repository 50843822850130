(() => {
  angular
    .module('app')
    .controller(
      'common.views.patientTickets.detail.approveLateSubmissionModal',
      ApproveLateSubmissionModalController
    );

  ApproveLateSubmissionModalController.$inject = ['$uibModalInstance'];

  function ApproveLateSubmissionModalController($uibModalInstance) {
    const vm = this;

    vm.remarks = '';

    vm.cancel = cancel;
    vm.confirm = confirm;

    init();

    function init() {}

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function confirm() {
      $uibModalInstance.close(vm.remarks);
    }
  }
})();
