(() => {
  angular.module('app').directive('summernoteWithHint', summernoteWithHintDirective);

  summernoteWithHintDirective.$inject = ['$timeout'];

  function summernoteWithHintDirective($timeout) {
    const directive = {
      link,
      restrict: 'A',

      require: 'ngModel',
      scope: {
        hintKeywords: '=',
        height: '@',
        characterLimit: '@',
        firstObjectKey: '@',
        secondObjectKey: '@',
        onMaxCallback: '&',
      },
    };
    return directive;

    function link(scope, element, attrs, ngModel) {
      let isInit = false;

      function init() {
        if (isInit) {
          element.summernote('destroy');
        }

        function updateNgModel() {
          let newValue = element.summernote('code');
          if (element.summernote('isEmpty')) {
            newValue = '';
          }

          const hasLimit =
            typeof scope.characterLimit !== 'undefined' && scope.characterLimit !== null;
          const isExclusionCallback =
            typeof scope.onMaxCallback === 'function' &&
            scope.firstObjectKey !== 'undefined' &&
            scope.firstObjectKey !== null &&
            scope.secondObjectKey !== 'undefined' &&
            scope.secondObjectKey !== null;

          if (ngModel && ngModel.$viewValue !== newValue) {
            $timeout(() => {
              ngModel.$setViewValue(newValue);

              if (hasLimit) {
                if (isExclusionCallback) {
                  scope.onMaxCallback({
                    exclusionId: scope.firstObjectKey,
                    exclusionType: scope.secondObjectKey,
                    max: newValue.length,
                  });
                }
              }
            }, 0);
          } else if (hasLimit) {
            if (typeof scope.onMaxCallback === 'function') {
              scope.onMaxCallback({
                exclusionId: scope.firstObjectKey,
                exclusionType: scope.secondObjectKey,
                max: ngModel.$viewValue.length,
              });
            }
          }
        }

        const config = {
          placeholder: App.localize('RichTextEditorPlaceholder'),
          disableDragAndDrop: true,
          height: scope.height ? scope.height : 400,
          focus: true,
          toolbar: [
            ['edit', ['undo', 'redo']],
            ['headline', ['style']],
            [
              'style',
              ['bold', 'italic', 'underline', 'superscript', 'subscript', 'strikethrough', 'clear'],
            ],
            ['fontface', ['fontname']],
            ['textsize', ['fontsize']],
            ['fontclr', ['color']],
            ['alignment', ['ul', 'ol', 'paragraph', 'lineheight']],
            ['height', ['height']],
            ['table', ['table']],
          ],
          hint: {
            mentions: scope.hintKeywords,
            match: /\B#(\w*)$/,
            search(keyword, callback) {
              callback(
                $.grep(this.mentions, (item) => _.toUpper(item).indexOf(_.toUpper(keyword)) > -1)
              );
            },
            content(item) {
              return `#${item}#`;
            },
          },
          callbacks: {
            onChange(contents) {
              $timeout(() => {
                if (element.summernote('isEmpty')) {
                  contents = '';
                }
                updateNgModel();
              }, 0);
              if (angular.isDefined(attrs.onChange)) {
                $scope.change({ contents, editable: $scope.editable });
              }
            },
            onKeydown(e) {
              if (scope.characterLimit) {
                const max = parseInt(scope.characterLimit, 10);
                const textValue = element.summernote('code');
                if (textValue.length >= max) {
                  if (e.keyCode !== 8) e.preventDefault();
                }
              }
            },
          },
        };

        element.summernote(config);
        isInit = false;
      }

      scope.$watch('hintKeywords', () => {
        if (scope.hintKeywords) {
          init();
        }
      });
    }
  }
})();
