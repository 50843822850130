import swal from 'sweetalert';

(() => {
  angular.module('app').component('commonLayoutTopnavbar', {
    template: require('./topnavbar.eager.html').default,
    controller: TopNavBarController,
    controllerAs: 'vm',
    bindings: {},
  });

  TopNavBarController.$inject = [
    'authentication',
    '$window',
    'appSession',
    'common.integrations.announceKit',
    '$rootScope',
  ];

  function TopNavBarController(authentication, $window, appSession, announceKitSvc, $root) {
    const vm = this;

    // Only allow English and Malay for portal.

    vm.hasIndonesiaAndThailandRegionalExpansion = abp.setting.getBoolean(
      'Hms.Feature.IndonesiaAndThailandRegionalExpansion'
    );
    let availableLanguages = ['ms', 'en-MY'];

    // HM-7420: Hide Thai language, currently focusing on Indonesia.

    // TODO: HM-7242
    // Finalise proper implementation of introduction of Thai language and datetime format.
    // Thai language is not currently translated and supported at the time of this comment.

    if (vm.hasIndonesiaAndThailandRegionalExpansion)
      // availableLanguages = availableLanguages.concat(['id', 'th']);
      availableLanguages = availableLanguages.concat(['id']);

    vm.languages = _.filter(abp.localization.languages, (l) =>
      _.includes(availableLanguages, l.name)
    );

    vm.currentLanguage = abp.localization.currentLanguage;
    vm.user = appSession.user;
    // vm.isValidUser = false;
    // vm.notifications = [];
    // vm.unreadNotificationCount = 0;

    // vm.loadNotifications = loadNotifications;
    // vm.setAllNotificationsAsRead = setAllNotificationsAsRead;
    // vm.setNotificationAsRead = setNotificationAsRead;
    // vm.openNotificationSettingsModal = openNotificationSettingsModal;

    vm.changeLanguage = changeLanguage;
    vm.logout = logout;

    init();

    function init() {
      // vm.loadNotifications();
    }

    function logout() {
      authentication.logout();
    }

    function changeLanguage(languageName) {
      abp
        .ajax({
          url: $root.resolveAppPath(`AbpLocalization/ChangeCulture?cultureName=${languageName}`),
        })
        .done(() => {
          // Store culture in cookies.

          const textEnglish =
            "We're actually in the process of upgrading our language packages to give you an even better experience. Don't worry, you'll be able to switch back to your preferred language once we're all done with the upgrades. Thanks for your patience!";
          const textMalay =
            'Kami sedang mengemaskini pakej bahasa untuk perkhidmatan yang lebih baik kepada anda. Jangan risau, anda akan dapat menukar bahasa semula selepas kerja mengemaskini selesai.';

          swal(
            {
              title: '',
              type: 'warning',
              text: `<p>${textEnglish}</p><br /><br /><p><i>${textMalay}</i></p>`,
              showConfirmButton: true,
              confirmButtonText: 'OK',
              html: true,
            },
            () => {
              abp.utils.setCookieValue('Abp.Localization.CultureName', languageName);
              $window.location.reload();
            }
          );
        });
    }

    // Notification system. Disabled due to performance hog.

    // function loadNotifications() {
    //    notificationSvc.getUserNotifications({
    //        maxResultCount: 3
    //    }).success(function (data) {
    //        vm.unreadNotificationCount = data.unreadCount;
    //        vm.notifications = [];
    //        $.each(data.items, function (i, v) {
    //            vm.notifications.push(notificationHelper.format(v));
    //        })
    //    })
    // }

    // function setAllNotificationsAsRead() {
    //    notificationHelper.setAllAsRead();
    // }

    // function setNotificationAsRead(n) {
    //    notificationHelper.setAsRead(n.userNotificationId);
    // }

    // function openNotificationSettingsModal() {
    //    notificationHelper.openSettingsModal();
    // };
    // abp.event.on('abp.notifications.refresh', function () {
    //    vm.loadNotifications();
    // });
    // abp.event.on('abp.notifications.received', function (n) {
    //    notificationHelper.show(n);
    //    vm.loadNotifications();
    // });
    // abp.event.on('abp.notifications.read', function (id) {
    //    for (var i = 0; i < vm.notifications.length; i+= 1) {
    //        if (vm.notifications[i].userNotificationId == id) {
    //            vm.notifications[i].state = 'READ';
    //        }
    //    }
    //    vm.unreadNotificationCount -= 1;
    // });
    // }
  }
})();
