(function () {
    var controllerId = 'host.views.clinics.locations';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', '$uibModal', 'abp.services.app.clinic', 'abp.services.app.clinicLocation',
        function ($scope, $state, $stateParams, $modal, clinicSvc, clinicLocationSvc) {
            function init() {
                vm.loading++;
                clinicSvc.getPanel({
                    id: vm.clinicId
                }).success(function (n) {
                    vm.clinicFullName = n.name + ' (' + n.tenancyName + ')';
                    clinicType = n.panelType;
                }).finally(function () {
                    vm.loading--;
                });
            }

            function showEditModal(id) {
                $modal.open({
                    templateUrl: require('/App/host/views/clinics/createOrEditLocationModal.html'),
                    controller: 'host.views.clinics.createOrEditLocationModal as vm',
                    backdrop: 'static',
                    resolve: {
                        clinicId: function () {
                            return vm.clinicId;
                        },
                        locationId: function () {
                            return id;
                        },
                        clinicType: function () {
                            return clinicType;
                        },
                        requestNumber: function () {
                            return null;
                        }
                    }
                }).result.then(function () {
                    vm.getLocations();
                    $state.reload();
                });
            }

            var vm = this;
            vm.loading = 0;
            vm.exporting = 0;
            vm.clinicId = $stateParams.id;
            vm.clinicFullName = '';
            vm.hidePageHeading = $state.is('host.clinics.locations');
            vm.locationFilterText = '';

            // Check for inpatient module enable for the application.

            vm.applicationHasInpatientModule = abp.setting.getBoolean('Hms.Feature.InpatientModule');

            var clinicType = null;

            vm.permissions = {
                createPanelRequest: abp.auth.isGranted('PanelRequests.Host.Create'),
                mergePanelRequest: abp.auth.isGranted('PanelRequests.Host.Merge'),
                manageLocations: abp.auth.isGranted("Host.Clinics.Locations.Manage"),
                createOverride: abp.auth.isGranted('Host.Clinics.CreateOverride'),
            };

            var zendeskSubdomain = abp.setting.get('Hms.Integration.Zendesk.Subdomain');
            vm.hasZendeskIntegration = zendeskSubdomain && zendeskSubdomain !== '';

            vm.locationGridOptions = {
                appScopeProvider: vm,
                paginationPageSizes: app.consts.grid.defaultPageSizes,
                paginationPageSize: app.consts.grid.defaultPageSize,
                columnDefs: [{
                    enableSorting: false,
                    name: App.localize('Actions'),
                    width: 100,
                    headerCellTemplate: '<span></span>',
                    cellTemplate: 'locations.actionTemplate'
                }, {
                    displayName: App.localize('Warning'),
                    field: 'warnings',
                    width: 25,
                    cellTemplate: 'locations.warningsTemplate',
                    sortingAlgorithm: function (a, b) {
                        if (!Array.isArray(a))
                            a = [];
                        if (!Array.isArray(b))
                            b = [];

                        if (a.length < b.length)
                            return -1;
                        else if (a.length === b.length)
                            return 0;
                        return 1;
                    }
                }, {
                    displayName: App.localize('Name'),
                    field: 'name',
                    minWidth: 120
                }, {
                    displayName: App.localize('Services'),
                    field: 'services',
                    visible: vm.applicationHasInpatientModule,
                    cellTemplate: 'serviceTemplate',
                    width: 100
                }, {
                    displayName: App.localize('ContactNumber'),
                    field: 'contactNumber',
                    minWidth: 80
                }, {
                    displayName: App.localize('Address'),
                    field: 'address',
                    minWidth: 120
                }, {
                    displayName: App.localize('MapCoordinate'),
                    field: 'latLng',
                    minWidth: 100,
                    cellTemplate: 'locations.mapTemplate'
                }, {
                    displayName: App.localize('PriceCategory'),
                    field: 'priceRating',
                    minWidth: 100,
                    cellTemplate: 'locations.categoryTemplate'
                }, {
                    displayName: App.localize('MidnightSurcharge'),
                    field: 'midnightSurcharge',
                    minWidth: 100,
                    cellTemplate: 'locations.midnightTemplate'
                }, {
                    displayName: App.localize('HasFemaleDoctor'),
                    field: 'hasFemaleDoctor',
                    minWidth: 100,
                    cellTemplate: 'locations.femaleDocTemplate'
                }, {
                    displayName: App.localize('IsActive'),
                    field: 'isActive',
                    cellTemplate: 'locations.activeTemplate',
                    minWidth: 65
                }, {
                    displayName: App.localize('StartDate'),
                    field: 'startDate',
                    cellFilter: 'momentFormat: \'L\'',
                    minWidth: 100
                }, {
                    displayName: App.localize('CreationDate'),
                    field: 'creationTime',
                    cellFilter: 'momentFormat: \'L\'',
                    minWidth: 100
                }, {
                    displayName: App.localize('Remarks'),
                    field: 'remarks',
                    minWidth: 100
                }],
                data: []
            };

            vm.getLocations = function () {
                vm.loading++;
                clinicLocationSvc.getClinicLocations({
                    panelId: vm.clinicId,
                    filter: vm.locationFilterText
                }).success(function (data) {
                    vm.locationGridOptions.data = data.items;
                }).finally(function (e) {
                    vm.loading--;
                });
            };

            vm.getLocationsWithPanelFilter = function () {
                vm.locationFilterText = $stateParams.filterText || '';;
                vm.getLocations();
            };

            vm.removeFilter = function () {
                vm.locationFilterText = '';
                vm.getLocations();
            };

            vm.createLocation = function () {
                showEditModal();
            };

            vm.viewLocation = function (location) {
                showEditModal(location.id);
            };

            vm.deleteLocation = function (location) {
                abp.message.confirm(App.localize('PanelLocationDeleteWarningMessage', location.name), App.localize('AreYouSure'), function (d) {
                    if (d) {
                        clinicLocationSvc.deleteClinicLocation({
                            id: location.id
                        }).success(function (data) {
                            vm.getLocations();
                            abp.notify.success(App.localize('SuccessfullyDeleted'));
                        });
                    }
                });
            };

            vm.exportToExcel = function (clinicId) {
                vm.exporting++;
                clinicLocationSvc.getClinicLocationsToExcel({ panelId: clinicId, filter: vm.locationFilterText }).success(function (data) {
                    App.downloadTempFile(data);
                }).finally(function () {
                    vm.exporting--;
                });
            };

            vm.mergePanelRequest = function (location) {
                $modal.open({
                    templateUrl: require('/App/host/views/panelRecruitment/mergePanelRequests/mergePanelRequests.modal.html'),
                    controller: 'host.views.panelRecruitment.mergePanelRequestsModal as vm',
                    backdrop: 'static',
                    resolve: {
                        requestNumber: function () {
                            return location.requestNumber;
                        }
                    }
                }).result.then(function () {
                    vm.getLocations();
                });
            };

            vm.createPanelRequest = function (location) {
                abp.message.confirm(App.localize('ConfirmExpressCreatePanelRequestMessage'), App.localize('AreYouSure'), function (d) {
                    if (d) {
                        vm.loading++;
                        clinicLocationSvc.expressCreatePanelRequest({
                            panelLocationId: location.id,
                            tenantRequestId: location.tenantRequestId
                        }).success(function () {
                            abp.notify.info(App.localize('PanelRequestWillBeCreated'));
                        }).finally(function () {
                            vm.loading--;
                        });
                    }
                });
            };

            vm.initializeZendeskOrganization = function (location) {
                abp.message.confirm(App.localize('InitializeZendeskOrganizationWarningMessage', location.name), App.localize('AreYouSure'), function (result) {
                    if (result) {
                        clinicLocationSvc.initializeZendeskOrganization({
                            id: location.id
                        }).success(function () {
                            vm.getLocations();
                            abp.notify.success(App.localize('InitializeZendeskOrganizationRequestSent'));
                        });
                    }
                });
            };

            if (vm.clinicId) {
                init();
                vm.getLocations();
            } else {
                $state.go('host.clinics');
            }
        }
    ]);
})();
