(() => {
  angular.module('app').component('commonHealthScreeningGuaranteeLettersDetailsCoveredTreatments', {
    templateUrl: require('./coveredTreatments.component.html'),
    controller: CoveredTreatmentsController,
    controllerAs: 'vm',
    bindings: {
      letterNumber: '<?',
    },
  });

  CoveredTreatmentsController.$inject = [
    '$stateParams',
    'abp.services.app.healthScreeningGuaranteeLetter',
  ];

  function CoveredTreatmentsController($stateParams, healthScreeningGuaranteeLetterSvc) {
    const vm = this;

    vm.loading = 0;
    vm.filter = null;
    vm.searchTreatmentPlaceholder = App.localize('TypeTreatmentKeywordsPlaceholder');
    vm.elementId = `coveredTreatmentsTree_${Math.floor(Date.now())}`;

    vm.searchRelatedTreatment = searchRelatedTreatment;

    init();

    function init() {
      $(document).ready(() => {
        $('.dd').nestable();
        buildTree();
      });

      /* Get covered health screening treatments. */

      vm.loading += 1;
      healthScreeningGuaranteeLetterSvc
        .getCoveredHealthScreeningTreatments({
          id: $stateParams.letterNumber,
        })
        .success((data) => {
          vm.coveredHealthScreeeningTreatments = data;

          const flattenRelatedTreatments = _.uniqBy(
            _.flattenDeep(vm.coveredHealthScreeeningTreatments, (treatment) => treatment),
            'id'
          );

          const childTreatments = _.filter(
            flattenRelatedTreatments,
            (treatment) => treatment.parentId !== null
          );

          _.each(childTreatments, (treatment) => {
            const parent = _.find(flattenRelatedTreatments, (t) => t.id === treatment.parentId);

            if (typeof parent.children === 'undefined') parent.children = [];
            parent.children.push(treatment);
          });

          vm.flattenRelatedTreatments = flattenRelatedTreatments;
          buildTree();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function buildTree() {
      const element = $(`#${vm.elementId}`);
      const ref = $.jstree.reference(element);
      if (ref) {
        ref.destroy();
      }

      const uncoveredNodeIcon = '<span class="fas fa-times text-danger"></span> ';
      const coveredNodeIcon = '<span class="fas fa-check text-navy"></span> ';
      function getNodeText(data) {
        if (data.isCovered) return coveredNodeIcon + data.displayName;
        return uncoveredNodeIcon + data.displayName;
      }

      const treatments = _.map(vm.flattenRelatedTreatments, (record) => ({
        id: record.id,
        parent: record.parentId ? record.parentId : '#',
        displayName: record.displayName,
        text: getNodeText(record),
        state: {
          opened: record.hasUncoveredChild,
        },
      }));

      element
        .jstree({
          core: {
            data: treatments,
          },
          types: {
            default: {
              icon: 'fas fa-folder tree-item-icon-color icon-lg',
            },
            file: {
              icon: 'fas fa-file tree-item-icon-color icon-lg',
            },
          },
          search: {
            fuzzy: false,
            show_only_matches: true,
            show_only_matches_children: true,
          },
          sort(itemA, itemB) {
            const nodeA = this.get_node(itemA);
            const nodeB = this.get_node(itemB);
            if (nodeB.original.id === -1) {
              return 1;
            }
            if (nodeA.children_d.length > 0 && nodeB.children_d.length === 0) {
              return -1;
            }

            return nodeB.original.displayName < nodeA.original.displayName ? 1 : -1;
          },
          plugins: ['types', 'search', 'sort'],
        })
        .bind('select_node.jstree', (e, data) => {
          const { href } = data.node.a_attr;
          if (href === '#') return '';

          window.open(href);
          return '';
        });
    }

    function searchRelatedTreatment() {
      const element = $(`#${vm.elementId}`);
      element.jstree('search', _.trim(vm.filter));
    }
  }
})();
