((function () {
    angular
        .module('app')
        .filter('birthDate', birthDate)
        .filter('birthDateFilter', birthDate);

    birthDate.$inject = [];

    function birthDate() {
        return birthDateFilter;

        function birthDateFilter(value) {
            const _date = new Date(value);
            return `${_date.getFullYear()}-${_date.getMonth() + 1}-${_date.getDate()}`;
        }
    }
})());