/**
 * landingScrollspy - Directive for scrollspy in landing page
 */

(() => {
  angular.module('app').directive('landingScrollspy', landingScrollspyDirective);

  landingScrollspyDirective.$inject = [];

  function landingScrollspyDirective() {
    const directive = {
      link,
      restrict: 'A',
    };
    return directive;

    function link(_scope, element) {
      element.scrollspy({
        target: '.navbar-fixed-top',
        offset: 80,
      });
    }
  }
})();
