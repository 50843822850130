(function () {
    var controllerId = 'corporate.views.claimPolicies.compare';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$stateParams', 'abp.services.app.claimPolicy',
        function ($scope, $stateParams, claimPolicySvc) {
            var vm = this;
            vm.loading = 0;
            var claimPolicyId = $stateParams.id;
            vm.snapshots = null;
            vm.compareIdLeft = null;
            vm.compareIdRight = null;
            vm.snapshotLeft = null;
            vm.snapshotRight = null;

            function getSnapshots() {
                vm.loading++;
                claimPolicySvc.getAllSnapshotByClaimPolicy({ id: claimPolicyId })
                    .success(function (data) {
                        vm.snapshots = data;
                    }).finally(function (e) {
                        vm.loading--;
                    });
            }

            vm.getSnapshot = function (side) {
                vm.loading++;
                var id = side ? vm.compareIdRight : vm.compareIdLeft;
                claimPolicySvc.getSnapshot({ id: id })
                    .success(function (data) {
                        if (side) {
                            vm.snapshotRight = JSON.parse(data);
                            $('#snapshotRightContent').html(vm.snapshotRight.Content);
                        }
                        else {
                            vm.snapshotLeft = JSON.parse(data);
                            $('#snapshotLeftContent').html(vm.snapshotLeft.Content);
                        }
                    }).finally(function (e) {
                        vm.loading--;
                    });
            };

            getSnapshots();
        }
    ]);
})();
