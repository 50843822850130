(function () {
    var controllerId = 'host.views.settings.oneSignalNotificationCategory.oneSignalNotificationCategory.modal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'abp.services.app.hostSettings',
        function ($scope, $uibModalInstance, hostSettingsSvc) {
            var vm = this;
            vm.loading = 0;

            vm.cancel = cancel;
            vm.confirm = confirm;

            function init() {
                vm.loading++;
                hostSettingsSvc.getNotificationCategorySettings().success(function (data) {
                    vm.categories = [];

                    if (data.length === 0) {
                        vm.categories.push({
                            categoryId: "",
                            notificationTypes: []
                        });
                    }
                    else {
                        vm.categories = data;
                    }
                }).finally(function () {
                    vm.loading--;
                });

                getUserNotificationTypes();
            }

            function cancel() {
                $uibModalInstance.dismiss();
            }

            function confirm() {
                vm.loading++;
                hostSettingsSvc.createOrUpdateNotificationCategory(vm.categories)
                    .success(function () {
                        abp.notify.info(App.localize('SuccessfullyConfigured'));
                        $uibModalInstance.close();
                    })
                    .finally(function () {
                        vm.loading--;
                    });
            }

            function getUserNotificationTypes() {
                hostSettingsSvc.getUserNotificationTypes()
                    .success(function (data) {
                        vm.userNotificationTypes = data;
                    });
            }

            init();
        }
    ]);
})();
