(function () {
  var controllerId = 'host.views.corporates.masterPolicy.InsurancePlans.createEdit';
  var app = angular.module('app');
  app.controller(controllerId, [
    '$scope',
    '$state',
    '$stateParams',
    '$filter',
    'abp.services.app.benefitGroup',
    'abp.services.app.corporate',
    'abp.services.app.commonLookup',
    function (
      $scope,
      $state,
      $stateParams,
      $filter,
      benefitGroupSvc,
      corporateSvc,
      commonLookupSvc
    ) {
      function init() {
        vm.loading++;
        corporateSvc
          .getOrganizationName({ id: vm.organizationId })
          .success(function (data) {
            vm.organizationName = data.name;
          })
          .finally(function () {
            vm.loading--;
          });
        if (vm.hasInpatientRegionalExpansion) getCountryAndCurrency();
        getSupportPlan();
      }

      function getCountryAndCurrency() {
        vm.loading += 1;
        commonLookupSvc
          .getCountryAndCurrencyByTenantId({ tenantId: vm.organizationId })
          .success((data) => {
            if (data.currency.code) vm.currencyCode = data.currency.code;
            if (data.countryCode) vm.countryCode = data.countryCode;
            vm.costSharingExample = App.localize('CostSharingExample', vm.currencyCode);
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }

      function getSupportPlan() {
        vm.loading++;
        benefitGroupSvc
          .getInpatientBenefitGroupForEdit({
            id: vm.inpatientBenefitGroupId,
            organizationId: $stateParams.organizationId,
            masterPolicyId: vm.masterPolicyId,
            serviceType: 7,
          })
          .success(function (data) {
            vm.optionalExclusions = data.optionalExclusions;
            vm.isHospitalization = data.benefitGroup.serviceType === 7;
            vm.supportPlan = new BenefitGroupModel(data.benefitGroup);
            vm.categories = data.categories;
            vm.insurer = data.newInsurer;
            if (vm.isHospitalization) {
              vm.enableCostSharingHospitalization =
                vm.supportPlan.hospitalizationSetting.isCoInsurance == null ? 0 : 1;
              vm.dependentSettingPlaceHolder = angular.copy(
                vm.supportPlan.hospitalizationSetting.dependentScheduleOfBenefit
              );

              if (vm.hasTxtFileSupport) {
                // Filter SOB claim items

                var hospitalizationItems = [
                  'RoomAndBoard',
                  'IcuRoomAndBoard',
                  'ChildGuardianBenefit',
                  'InHospitalDoctorVisit',
                  'SurgicalFee',
                  'AnaesthetistFee',
                  'OrganTransplant',
                  'DaySurgery',
                  'OperatingTheatre',
                  'HospitalSuppliesAndServices',
                  'AmbulanceFee',
                  'MedicalReport',
                ];

                vm.categories = _.filter(vm.categories, function (x) {
                  return hospitalizationItems.includes(x.value);
                });

                if (vm.enableCostSharingHospitalization === 1) {
                  // Copy coPayExclusions to coPayExclusionCategories

                  var clonedHospitalizationCoPayExclusions =
                    vm.supportPlan.hospitalizationSetting.coPayExclusions.slice(0);
                  vm.supportPlan.hospitalizationSetting.coPayExclusionCategories = [];
                  _.each(clonedHospitalizationCoPayExclusions, function (x) {
                    var category = _.find(vm.categories, function (c) {
                      return c.value === x;
                    });
                    vm.supportPlan.hospitalizationSetting.coPayExclusionCategories.push(category);
                  });

                  // Set cost-sharing type if editing previous copay setting.

                  if (
                    !_.isNil(vm.supportPlan.hospitalizationSetting.isCoInsurance) &&
                    _.isNil(vm.supportPlan.hospitalizationSetting.coPayType)
                  )
                    vm.supportPlan.hospitalizationSetting.coPayType = vm.supportPlan
                      .hospitalizationSetting.isCoInsurance
                      ? 1
                      : 2;
                }
              }
            } else {
              vm.enableCostSharingMaternity =
                vm.supportPlan.maternitySetting.isCoInsurance == null ? 0 : 1;
            }
            vm.supportPlan.refreshResult();
            vm.supportPlan.hasMultipleVersion = data.hasMultipleVersion;
          })
          .finally(function () {
            vm.loading--;
          });
      }

      var ccyFilter = $filter('currencyFormat');
      var hospitalizationDetails = [
        new TreatmentModel(7, App.localize('Hospitalization'), 'fas fa-bed', true),
      ];
      var maternityDetails = [new TreatmentModel(8, App.localize('Maternity'), 'fas fa-bed', true)];

      var vm = this;
      vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
      vm.countryCode = null;
      vm.inpatientBenefitGroupId = $stateParams.insurancePlanId;
      vm.organizationId = $stateParams.organizationId;
      vm.masterPolicyId = $stateParams.masterPolicyId;
      vm.serviceType = 7;
      vm.isHospitalization = true;
      vm.loading = 0;
      vm.saving = 0;
      vm.isCreate = !vm.inpatientBenefitGroupId;
      vm.buttonLabel = vm.isCreate ? App.localize('SaveAll') : App.localize('Update');
      vm.optionalExclusions = [];
      vm.categories = [];
      vm.hasScheduleOfBenefitEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');
      vm.hasTxtFileSupport = abp.setting.getBoolean('Hms.Feature.TxtFileSupport');
      vm.hasInpatientRegionalExpansion = abp.setting.getBoolean(
        'Hms.Feature.RegionalExpansionForInpatient'
      );
      vm.costSharingExample = App.localize('CostSharingExample', vm.currencyCode);
      vm.showCostSharingExample = false;

      vm.submit = function () {
        if (!App.isFormValid($scope.supportPlanCreateEditForm)) {
          abp.notify.error(App.localize('GeneralInvalidFormInputError'));
          return;
        }

        if (vm.enableCostSharingHospitalization === 0) {
          vm.supportPlan.hospitalizationSetting.isCoInsurance = null;
          vm.supportPlan.hospitalizationSetting.isRoomAndBoardExceedEntitlement = null;
          vm.supportPlan.hospitalizationSetting.coPays = [];
          vm.supportPlan.hospitalizationSetting.coPayExclusions = [];
          vm.supportPlan.hospitalizationSetting.coPayType = null;
        } else if (vm.hasTxtFileSupport && vm.enableCostSharingHospitalization === 1) {
          // Copy coPayExclusionCategories to coPayExclusions

          var clonedHospitalizationCoPayExclusionCategories =
            vm.supportPlan.hospitalizationSetting.coPayExclusionCategories.slice(0);
          vm.supportPlan.hospitalizationSetting.coPayExclusions = [];
          vm.supportPlan.hospitalizationSetting.coPayExclusions = _.map(
            clonedHospitalizationCoPayExclusionCategories,
            function (x) {
              return x.value;
            }
          );

          // Set value for isRoomAndBoardExceedEntitlement if null.

          if (vm.supportPlan.hospitalizationSetting.isRoomAndBoardExceedEntitlement == null)
            vm.supportPlan.hospitalizationSetting.isRoomAndBoardExceedEntitlement = false;
        }

        if (vm.enableCostSharingMaternity === 0) {
          vm.supportPlan.maternitySetting.isCoInsurance = null;
          vm.supportPlan.maternitySetting.coPays = [];
        }

        vm.saving = true;
        benefitGroupSvc
          .createOrUpdateInpatientBenefitGroup(vm.supportPlan.toDto())
          .success(function () {
            abp.notify.info(App.localize('SuccessfullySaved'));
            $state.go('host.corporateMasterPolicies.insurancePlans', {
              organizationId: vm.organizationId,
              masterPolicyId: vm.masterPolicyId,
            });
          })
          .finally(function () {
            vm.saving = false;
          });
      };

      function automap(source, target) {
        _.forIn(source, function (v, k) {
          target[k] = source[k];
        });
      }

      function BenefitGroupModel(dto) {
        var _this = this;

        // Basic properties

        _this.id = 0;
        _this.organizationId = vm.organizationId;
        _this.masterPolicyId = vm.masterPolicyId;
        _this.name = '';
        _this.displayName = '';
        _this.insurerId = '';
        _this.insurer = {};

        // Benefits

        _this.principal = vm.isHospitalization ? hospitalizationDetails : maternityDetails;
        _this.dependent = vm.isHospitalization ? hospitalizationDetails : maternityDetails;

        // Benefit Modifiers

        _this.modifiers = [];

        // Benefit summaries

        _this.principalSummaries = [];
        _this.showPrincipalFootnote = false;
        _this.dependentSummaries = [];
        _this.showDependentFootnote = false;

        // Functions

        _this.addModifier = function () {
          var modifier = new Modifier(_this);
          if (vm.isHospitalization) {
            vm.supportPlan.hospitalizationSetting.modifiers.push(modifier);
          } else {
            vm.supportPlan.maternitySetting.modifiers.push(modifier);
          }
        };

        _this.removeModifier = function (index) {
          abp.message.confirm(
            App.localize('DeleteBenefitModifierWarningMessage'),
            App.localize('AreYouSure'),
            function (d) {
              if (d) {
                $scope.$apply(function () {
                  if (vm.isHospitalization) {
                    vm.supportPlan.hospitalizationSetting.modifiers.splice(index, 1);
                  } else {
                    vm.supportPlan.maternitySetting.modifiers.splice(index, 1);
                  }
                  _this.refreshResult();
                });
                return true;
              } else {
                return false;
              }
            }
          );
        };

        _this.addCoPay = function () {
          var coPay = new CoPay(_this);
          if (vm.isHospitalization) {
            if (_this.hospitalizationSetting.coPays == null)
              _this.hospitalizationSetting.coPays = [];

            _this.hospitalizationSetting.coPays.push(coPay);
          } else {
            if (_this.maternitySetting.coPays == null) _this.maternitySetting.coPays = [];

            _this.maternitySetting.coPays.push(coPay);
          }
        };

        _this.removeCoPay = function (index) {
          abp.message.confirm(
            App.localize('DeleteBenefitModifierWarningMessage'),
            App.localize('AreYouSure'),
            function (d) {
              if (d) {
                $scope.$apply(function () {
                  if (vm.isHospitalization) {
                    _this.hospitalizationSetting.coPays.splice(index, 1);
                    vm.hospitalizationCopayForm.$setDirty();
                  } else {
                    _this.maternitySetting.coPays.splice(index, 1);
                    vm.maternityCopayForm.$setDirty();
                  }
                  _this.refreshResult();
                });
                return true;
              } else return false;
            }
          );
        };

        _this.refreshResult = function () {
          var principalSummaries = [],
            dependentSummaries = [],
            showPrincipalFootnote = false,
            showDependentFootnote = false;

          var coverDependent =
            (_this.hospitalizationSetting && !!_this.hospitalizationSetting.hasDependentCovered) ||
            (_this.maternitySetting && !!_this.maternitySetting.isSpouseCovered);
          var principal = _.map(_.filter(_this.principal, 'valid'), function (x) {
            return new BenefitResult(x, true, true);
          });
          var dependent = coverDependent
            ? _.map(_.filter(_this.dependent, 'valid'), function (x) {
                return new BenefitResult(x, true, true);
              })
            : [];
          if (!dependent.length) {
            coverDependent = false;
          }
          var coverPrincipal = principal.length > 0;

          if (vm.isHospitalization) {
            if (coverDependent) {
              if (vm.dependentSettingPlaceHolder) {
                _this.hospitalizationSetting.dependentScheduleOfBenefit = angular.copy(
                  vm.dependentSettingPlaceHolder
                );
              } else if (
                vm.isCreate ||
                (!vm.isCreate && !_this.hospitalizationSetting.dependentScheduleOfBenefit)
              ) {
                _this.hospitalizationSetting.dependentScheduleOfBenefit = angular.copy(
                  _this.hospitalizationSetting.principalScheduleOfBenefit
                );
              }
            } else {
              if (_this.hospitalizationSetting.dependentScheduleOfBenefit) {
                vm.dependentSettingPlaceHolder = angular.copy(
                  _this.hospitalizationSetting.dependentScheduleOfBenefit
                );
              }

              _this.hospitalizationSetting.enableDependentSetting = 0;
              _this.hospitalizationSetting.dependentScheduleOfBenefit = null;
            }
          }

          // Group result into stacks

          var modifiers = [];
          if (typeof vm.isHospitalization !== 'undefined' && vm.isHospitalization !== null) {
            modifiers = vm.isHospitalization
              ? _this.hospitalizationSetting.modifiers
              : _this.maternitySetting.modifiers;
          }

          _.each(modifiers, function (mod) {
            if (mod.cycle === 4 && mod.type !== 0) {
              mod.cycle = 3;
            }
          });

          _.each(
            _.groupBy(modifiers, function (x) {
              var interval = x.cycle === 0 ? x.interval : 1;
              return x.type + ':' + interval + ':' + x.cycle;
            }),
            function (values) {
              _.each(values, function (modifier) {
                var principalPortion = [];
                var dependentPortion = [];

                var beneficiary = coverDependent ? modifier.beneficiary : 1;
                var poolMode = coverDependent ? modifier.poolMode : 2;

                _.each(modifier.treatments, function (t) {
                  var benefit;
                  if (coverPrincipal && (beneficiary & 1) > 0) {
                    benefit = _.find(principal, function (x) {
                      return x.treatment.id === t.treatment.id;
                    });
                    if (benefit) {
                      if (modifier.type === 0) {
                        benefit.unlimitedBudget = false;
                      } else if (modifier.type === 1) {
                        benefit.unlimitedVisit = false;
                      }
                      principalPortion.push(t.treatment);
                    }
                  }
                  if (coverDependent && (beneficiary & 2) > 0) {
                    benefit = _.find(dependent, function (x) {
                      return x.treatment.id === t.treatment.id;
                    });
                    if (benefit) {
                      if (modifier.type === 0) {
                        benefit.unlimitedBudget = false;
                      } else if (modifier.type === 1) {
                        benefit.unlimitedVisit = false;
                      }
                      dependentPortion.push(t.treatment);
                    }
                  }
                });

                if (principalPortion.length || dependentPortion.length) {
                  var uniquePortion = _.uniqBy(_.concat(principalPortion, dependentPortion), 'id'),
                    modifierTreatmentNames = _.join(_.map(uniquePortion, 'name'), '+'),
                    principalTreatmentNames = _.join(_.map(principalPortion, 'name'), '+'),
                    dependentTreatmentNames = _.join(_.map(dependentPortion, 'name'), '+');

                  if (poolMode === 0 && (!principalPortion.length || !dependentPortion.length)) {
                    poolMode = 2;
                  }

                  var principalSummaryRecord = null;
                  if (principalTreatmentNames) {
                    principalSummaryRecord = _.find(principalSummaries, function (x) {
                      return x.key === principalTreatmentNames;
                    });

                    if (!principalSummaryRecord) {
                      principalSummaryRecord = new BenefitSummary(principalTreatmentNames);
                      principalSummaries.push(principalSummaryRecord);
                    }
                  }

                  var dependentSummaryRecord = null;
                  if (dependentTreatmentNames) {
                    dependentSummaryRecord = _.find(dependentSummaries, function (x) {
                      return x.key === dependentTreatmentNames;
                    });

                    if (!dependentSummaryRecord) {
                      dependentSummaryRecord = new BenefitSummary(dependentTreatmentNames);
                      dependentSummaries.push(dependentSummaryRecord);
                    }
                  }

                  // Get modifier description

                  var str = '';
                  var strValue = _.isNil(modifier.value) ? '?' : modifier.value;
                  switch (modifier.type) {
                    case 0:
                      str += ' ' + ccyFilter(modifier.value, vm.currencyCode);
                      break;
                    case 1:
                      str += ' ' + strValue + ' ' + App.localize('VisitLimit').toLowerCase();
                      break;
                    case 2:
                      str +=
                        ' ' +
                        ccyFilter(modifier.value, vm.currencyCode) +
                        ' (' +
                        App.localize('BudgetOverdraft').toLowerCase() +
                        ')';
                      break;
                    case 3:
                      str += ' ' + strValue + App.localize('VisitOverdraft').toLowerCase();
                      break;
                  }

                  var cycle = '';
                  switch (modifier.cycle) {
                    case 0:
                      cycle = App.localize('XYears', modifier.interval).toLowerCase();
                      break;
                    case 1:
                      cycle = App.localize('Yearly').toLowerCase();
                      break;
                    case 2:
                      cycle = App.localize('PerEmploymentContract').toLowerCase();
                      break;
                  }
                  str += ' ' + cycle;

                  var principalStr, dependentStr;

                  if (poolMode === 0) {
                    if (principalSummaryRecord) {
                      principalSummaryRecord.addItem(
                        modifier.type,
                        cycle,
                        '[1]',
                        modifier.value || 0
                      );
                      showPrincipalFootnote = true;
                    }
                    if (dependentSummaryRecord) {
                      dependentSummaryRecord.addItem(
                        modifier.type,
                        cycle,
                        '[1]',
                        modifier.value || 0
                      );
                      showDependentFootnote = true;
                    }
                  } else if (poolMode === 1) {
                    if (principalSummaryRecord) {
                      principalSummaryRecord.addItem(modifier.type, cycle, '', modifier.value || 0);
                    }
                    if (dependentSummaryRecord) {
                      dependentSummaryRecord.addItem(modifier.type, cycle, '', modifier.value || 0);
                    }
                  } else if (poolMode === 2) {
                    if (principalSummaryRecord) {
                      principalSummaryRecord.addItem(modifier.type, cycle, '', modifier.value || 0);
                    }
                    if (dependentSummaryRecord) {
                      dependentSummaryRecord.addItem(
                        modifier.type,
                        cycle,
                        '[2]',
                        modifier.value || 0
                      );
                      showDependentFootnote = true;
                    }
                  }
                }
              });
            }
          );

          // Print summary

          principal.forEach(function (x) {
            if (x.unlimitedVisit || x.unlimitedBudget) {
              var summary = _.find(principalSummaries, function (y) {
                return y.key === x.treatment.name;
              });
              if (!summary) {
                principalSummaries.push((summary = new BenefitSummary(x.treatment.name)));
              }

              if (x.unlimitedBudget && x.unlimitedVisit) {
                summary.addText(App.localize('Unlimited'));
              } else {
                if (x.unlimitedBudget) {
                  summary.addText(App.localize('UnlimitedBudget'));
                }
                if (x.unlimitedVisit) {
                  summary.addText(App.localize('UnlimitedVisit'));
                }
              }
            }
          });
          _this.principalSummaries = principalSummaries;
          _this.showPrincipalFootnote = showPrincipalFootnote;

          dependent.forEach(function (x) {
            if (x.unlimitedVisit || x.unlimitedBudget) {
              var summary = _.find(dependentSummaries, function (y) {
                return y.key === x.treatment.name;
              });
              if (!summary) {
                dependentSummaries.push((summary = new BenefitSummary(x.treatment.name)));
              }
              if (x.unlimitedBudget && x.unlimitedVisit) {
                summary.addText(App.localize('Unlimited'));
              } else {
                if (x.unlimitedBudget) {
                  summary.addText(App.localize('UnlimitedBudget'));
                }
                if (x.unlimitedVisit) {
                  summary.addText(App.localize('UnlimitedVisit'));
                }
              }
            }
          });
          _this.dependentSummaries = dependentSummaries;
          _this.showDependentFootnote = showDependentFootnote;
        };

        // Initialization

        if (dto) {
          automap(dto, _this);
          if (!_this.insurerId) _this.insurerId = '';

          // Inpatient or maternity settings.

          if (_this.serviceType === 7) {
            // Basics

            _this.hospitalizationSetting.hasDependentCovered = dto.hospitalizationSetting
              .hasDependentCovered
              ? 1
              : 0;
            _this.hospitalizationSetting.isAccidentAdmission =
              dto.hospitalizationSetting.admissionWaitingAccidentDays > 0 ? 1 : 0;
            _this.hospitalizationSetting.isIllnessAdmission =
              dto.hospitalizationSetting.admissionWaitingIllnessDays > 0 ? 1 : 0;
            _this.hospitalizationSetting.isPreExisting =
              dto.hospitalizationSetting.admissionWaitingPreExistingDays > 0 ? 1 : 0;
            _this.hospitalizationSetting.isNonAccidentalIllness =
              dto.hospitalizationSetting.admissionWaitingNonAccidentalIllnessDays > 0 ? 1 : 0;
            _this.hospitalizationSetting.isDefaultExclusionsCovered = dto.hospitalizationSetting
              .isDefaultExclusionsCovered
              ? 1
              : 0;

            _this.hospitalizationSetting.coveredOptionalExclusions = buildExclusionTypeList(
              vm.optionalExclusions,
              dto.hospitalizationSetting.coveredOptionalExclusions
            );

            // Benefits

            var clonedHospitalizationModifiers = dto.hospitalizationSetting.modifiers.slice(0);
            _this.hospitalizationSetting.modifiers = [];
            _.each(clonedHospitalizationModifiers, function (x) {
              _this.hospitalizationSetting.modifiers.push(new Modifier(_this, x));
            });

            // Cost sharing
            _this.hospitalizationSetting.isCoInsurance = dto.hospitalizationSetting.isCoInsurance;
            _this.hospitalizationSetting.isRoomAndBoardExceedEntitlement =
              dto.hospitalizationSetting.isRoomAndBoardExceedEntitlement;
            _this.hospitalizationSetting.coPayExclusions =
              dto.hospitalizationSetting.coPayExclusions;
            _this.hospitalizationSetting.coPayType = dto.hospitalizationSetting.coPayType;
            _this.hospitalizationSetting.coPayExclusionCategories = [];

            if (dto.hospitalizationSetting.coPays) {
              var clonedHospitalizationCoPays = dto.hospitalizationSetting.coPays.slice(0);
              _this.hospitalizationSetting.coPays = [];
              _.each(clonedHospitalizationCoPays, function (x) {
                _this.hospitalizationSetting.coPays.push(new CoPay(_this, x));
              });
            }

            // Schedule of benefit

            _this.hospitalizationSetting.enableDependentSetting = 0;

            _this.hospitalizationSetting.principalScheduleOfBenefit =
              dto.hospitalizationSetting.principalScheduleOfBenefit;
            _this.hospitalizationSetting.dependentScheduleOfBenefit =
              dto.hospitalizationSetting.dependentScheduleOfBenefit;
          } else {
            // Basics

            _this.maternitySetting.isSpouseCovered = dto.maternitySetting.isSpouseCovered ? 1 : 0;
            _this.maternitySetting.isHasMotherAgeLimit =
              dto.maternitySetting.motherMaxAge > 0 ? 1 : 0;
            _this.maternitySetting.isMaternitybenefits =
              dto.maternitySetting.admissionWaitingDays > 0 ? 1 : 0;
            _this.maternitySetting.isMaternitybenefits =
              dto.maternitySetting.admissionWaitingDays > 0 ? 1 : 0;
            _this.maternitySetting.isUnlimitedDelivery =
              dto.maternitySetting.deliveryLimit === 0 ? 1 : 0;

            // Benefits

            var clonedMaternityModifiers = dto.maternitySetting.modifiers.slice(0);
            _this.maternitySetting.modifiers = [];
            _.each(clonedMaternityModifiers, function (x) {
              _this.maternitySetting.modifiers.push(new Modifier(_this, x));
            });

            // Cost sharing

            _this.maternitySetting.isCoInsurance = dto.maternitySetting.isCoInsurance;

            if (dto.maternitySetting.coPays) {
              var clonedMaternityCoPays = dto.maternitySetting.coPays.slice(0);
              _this.maternitySetting.coPays = [];
              _.each(clonedMaternityCoPays, function (x) {
                _this.maternitySetting.coPays.push(new CoPay(_this, x));
              });
            }

            // Normal delivery

            _this.maternitySetting.normalDelivery.amountType = dto.maternitySetting.normalDelivery
              .isAsCharged
              ? 0
              : 1;
            if (!_this.maternitySetting.normalDelivery.isCovered) {
              _this.maternitySetting.normalDelivery.amountType = 2;
            }
            _this.maternitySetting.normalDelivery.maxDaysTimesType = dto.maternitySetting
              .normalDelivery.isUnlimited
              ? 1
              : 0;

            // Caesarean delivery

            _this.maternitySetting.caesareanDelivery.amountType = dto.maternitySetting
              .caesareanDelivery.isAsCharged
              ? 0
              : 1;
            if (!_this.maternitySetting.caesareanDelivery.isCovered) {
              _this.maternitySetting.caesareanDelivery.amountType = 2;
            }
            _this.maternitySetting.caesareanDelivery.maxDaysTimesType = dto.maternitySetting
              .caesareanDelivery.isUnlimited
              ? 1
              : 0;

            // Normal delivery for twins and above

            _this.maternitySetting.normalDeliveryTwinsAndAbove.amountType = dto.maternitySetting
              .normalDeliveryTwinsAndAbove.isAsCharged
              ? 0
              : 1;
            if (!_this.maternitySetting.normalDeliveryTwinsAndAbove.isCovered) {
              _this.maternitySetting.normalDeliveryTwinsAndAbove.amountType = 2;
            }
            _this.maternitySetting.normalDeliveryTwinsAndAbove.maxDaysTimesType = dto
              .maternitySetting.normalDeliveryTwinsAndAbove.isUnlimited
              ? 1
              : 0;

            // Caesarean delivery for twins and above

            _this.maternitySetting.caesareanDeliveryTwinsAndAbove.amountType = dto.maternitySetting
              .caesareanDeliveryTwinsAndAbove.isAsCharged
              ? 0
              : 1;
            if (!_this.maternitySetting.caesareanDeliveryTwinsAndAbove.isCovered) {
              _this.maternitySetting.caesareanDeliveryTwinsAndAbove.amountType = 2;
            }
            _this.maternitySetting.caesareanDeliveryTwinsAndAbove.maxDaysTimesType = dto
              .maternitySetting.caesareanDeliveryTwinsAndAbove.isUnlimited
              ? 1
              : 0;

            // Pre-natal check-up

            _this.maternitySetting.preNatalCheckUp.isCovered = dto.maternitySetting.preNatalCheckUp
              .isCovered
              ? 1
              : 0;
            _this.maternitySetting.preNatalCheckUp.amountType = dto.maternitySetting.preNatalCheckUp
              .isAsCharged
              ? 0
              : 1;
            _this.maternitySetting.preNatalCheckUp.maxDaysTimesType = dto.maternitySetting
              .preNatalCheckUp.isUnlimited
              ? 1
              : 0;

            // Post-natal follow-up

            _this.maternitySetting.postNatalFollowUp.isCovered = dto.maternitySetting
              .postNatalFollowUp.isCovered
              ? 1
              : 0;
            _this.maternitySetting.postNatalFollowUp.amountType = dto.maternitySetting
              .postNatalFollowUp.isAsCharged
              ? 0
              : 1;
            _this.maternitySetting.postNatalFollowUp.maxDaysTimesType = dto.maternitySetting
              .postNatalFollowUp.isUnlimited
              ? 1
              : 0;

            // Miscarriage

            _this.maternitySetting.miscarriage.amountType = dto.maternitySetting.miscarriage
              .isAsCharged
              ? 0
              : 1;
            _this.maternitySetting.miscarriage.maxDaysTimesType = dto.maternitySetting.miscarriage
              .isUnlimited
              ? 1
              : 0;

            // Covers pregnancy related complications

            _this.maternitySetting.isOptionalExclusionsCovered =
              dto.maternitySetting.coveredOptionalExclusions &&
              dto.maternitySetting.coveredOptionalExclusions.length > 0
                ? 1
                : 0;
            _this.maternitySetting.coveredOptionalExclusions = buildExclusionTypeList(
              vm.optionalExclusions,
              dto.maternitySetting.coveredOptionalExclusions
            );

            // Neo-natal

            _this.maternitySetting.neoNatal.isCovered = dto.maternitySetting.neoNatal.isCovered
              ? 1
              : 0;
          }
        }

        _this.refreshResult();

        //Post method

        _this.toDto = function () {
          function getOptionalExclusionIds(exclusions) {
            return _(exclusions).filter('valid').map('id').value();
          }

          function getModifierDto(modifiers) {
            return _(modifiers)
              .map(function (m) {
                return m.toDto();
              })
              .value();
          }

          function getCoPayDto(coPays) {
            return _(coPays)
              .map(function (m) {
                return m.toDto();
              })
              .value();
          }

          function getHospitalizationBenefitSettingsDto(settings, isCreate) {
            var dependentSettings =
              settings.hasDependentCovered === 0 ? null : settings.dependentScheduleOfBenefit;
            if (
              vm.isCreate &&
              settings.hasDependentCovered === 1 &&
              settings.enableDependentSetting === 0
            ) {
              dependentSettings = settings.principalScheduleOfBenefit;
            }

            return {
              remarks: settings.remarks,
              hasDependentCovered:
                settings.hasDependentCovered === 1 ? settings.hasDependentCovered : 0,
              admissionWaitingAccidentDays:
                settings.isAccidentAdmission === 1 ? settings.admissionWaitingAccidentDays : 0,
              admissionWaitingIllnessDays:
                settings.isIllnessAdmission === 1 ? settings.admissionWaitingIllnessDays : 0,
              admissionWaitingPreExistingDays:
                settings.isPreExisting === 1 ? settings.admissionWaitingPreExistingDays : 0,
              admissionWaitingNonAccidentalIllnessDays:
                settings.isNonAccidentalIllness === 1
                  ? settings.admissionWaitingNonAccidentalIllnessDays
                  : 0,
              exclusionVersionId: settings.exclusionVersionId,
              isDefaultExclusionsCovered: settings.isDefaultExclusionsCovered === 1,
              coveredOptionalExclusions: getOptionalExclusionIds(
                settings.coveredOptionalExclusions
              ),
              isCoInsurance: settings.isCoInsurance,
              isRoomAndBoardExceedEntitlement: settings.isRoomAndBoardExceedEntitlement,
              copays: getCoPayDto(settings.coPays),
              coPayExclusions: settings.coPayExclusions,
              coPayType: settings.coPayType,
              modifiers: getModifierDto(settings.modifiers),
              principalScheduleOfBenefit: settings.principalScheduleOfBenefit,
              dependentScheduleOfBenefit: dependentSettings,
            };
          }

          var hospitalizationSetting = null;
          var maternitySettings = null;
          if (vm.isHospitalization) {
            hospitalizationSetting = getHospitalizationBenefitSettingsDto(
              _this.hospitalizationSetting,
              vm.isCreate
            );
          } else {
            maternitySettings = getMaternityBenefitSettingsDto(_this.maternitySetting);
          }

          return {
            id: _this.id,
            tenantId: _this.tenantId,
            serviceType: _this.serviceType,
            name: _this.name,
            displayName: _this.displayName,
            insurerId: _this.insurerId || null,
            masterPolicyId: vm.masterPolicyId,
            hospitalizationSetting: hospitalizationSetting,
            maternitySetting: maternitySettings,
          };
        };
      }

      function ExclusionModel(id, name, valid, isLatestVersion) {
        this.id = id;
        this.name = name;
        this.valid = valid;
        this.isLatestVersion = isLatestVersion;
      }

      function buildExclusionTypeList(optionalExclusions, coveredOptionalExclusions) {
        var exclusions = [];
        if (!coveredOptionalExclusions) coveredOptionalExclusions = [];

        _.each(optionalExclusions, function (exc) {
          exclusions.push(
            new ExclusionModel(
              exc.value,
              exc.name,
              _.indexOf(coveredOptionalExclusions, exc.value) >= 0,
              exc.isLatestVersion
            )
          );
        });

        return exclusions;
      }

      function TreatmentModel(id, name, icon, valid) {
        this.id = id;
        this.name = name;
        this.icon = icon;
        this.valid = valid;
      }

      function TreatmentApplyModel(treatment, applicable) {
        this.treatment = treatment;
        this.applicable = applicable;
      }

      function BenefitResult(treatment, unlimitedBudget, unlimitedVisit) {
        this.treatment = treatment;
        this.unlimitedBudget = unlimitedBudget;
        this.unlimitedVisit = unlimitedVisit;
      }

      function BenefitSummaryItem(type, cycle, poolMode, value) {
        this.type = type;
        this.cycle = cycle;
        this.poolMode = poolMode;
        this.value = value;

        this.toString = function () {
          var str = '';
          var strValue = _.isNil(this.value) ? '?' : this.value;
          switch (this.type) {
            case 0:
              str += vm.currencyCode + strValue;
              break;
            case 1:
              str += strValue + ' ' + (this.value > 1 ? 'visits' : 'visit');
              break;
            case 2:
              str += vm.currencyCode + strValue + ' overdraft';
              break;
            case 3:
              str += strValue + ' overdraft ' + (this.value > 1 ? 'visits' : 'visit');
              break;
          }

          str += ' ' + this.cycle + ' ' + this.poolMode;
          return str;
        };
      }

      function BenefitSummary(key, value) {
        this.items = [];
        this.texts = [];

        this.key = key;
        this.value = value;

        this.addItem = function (type, cycle, poolMode, value) {
          var item = _.find(this.items, function (x) {
            return x.type === type && x.cycle === cycle && x.poolMode === poolMode;
          });

          if (!item) {
            this.items.push(new BenefitSummaryItem(type, cycle, poolMode, value));
          } else {
            item.value += value;
          }
        };

        this.addText = function (text) {
          this.texts.push(text);
        };

        this.toString = function () {
          return _.concat(
            _.map(this.items, function (x) {
              return x.toString();
            }),
            this.texts
          ).join(', ');
        };
      }

      function Modifier(bgModel, dto) {
        var _this = this;

        _this.parent = bgModel;

        // Basic properties

        _this.type = 0;
        _this.cycle = 1;
        _this.beneficiary = 3;
        _this.poolMode = 0;
        _this.value = null;
        _this.interval = 2;

        // View properties

        _this.show = true;
        _this.description = '';

        // Treatment type mapping

        _this.principalMap = _.keyBy(bgModel.principal, 'id');
        _this.dependentMap = _.keyBy(bgModel.dependent, 'id');

        // Applicable treatment types

        var treatments = [];
        _.each(_this.parent.principal, function (e) {
          var isValid = checkValid(e.id);
          treatments.push(
            new TreatmentApplyModel(new TreatmentModel(e.id, e.name, e.icon, isValid), isValid)
          );
        });
        _this.treatments = treatments;

        // Private functions

        function checkValid(id) {
          switch (_this.beneficiary) {
            case 3:
              return (
                _this.principalMap[id].valid ||
                (_this.parent.hasDependentCovered === 1 && _this.dependentMap[id].valid)
              );
            case 1:
              return _this.principalMap[id].valid;
            case 2:
              return _this.dependentMap[id].valid;
            default:
              return false;
          }
        }

        function validateModifierRules() {
          // If modifier is not budget limit, cycle cannot be Per Visit.
          // Default to annually.

          if (_this.type !== 0 && _this.cycle === 4) {
            _this.cycle = 1;
          }

          // If beneficiary is principal only, there is no pool to share.
          // If beneficiary is dependant only, split pool cannot be selected.
          // Default to individual pool.

          if (_this.beneficiary === 1 || (_this.beneficiary === 2 && _this.poolMode === 2)) {
            _this.poolMode = 1;
          }
        }

        // Public functions

        _this.changed = function () {
          validateModifierRules();
          _this.refreshTreatmentValidity();
          _this.refreshDescription();
        };

        _this.refreshTreatmentValidity = function () {
          _.each(_this.treatments, function (e) {
            e.treatment.valid = checkValid(e.treatment.id);
          });
        };

        //Post method

        _this.toDto = function () {
          return {
            type: _this.type,
            cycle: _this.cycle,
            beneficiary: _this.beneficiary,
            poolMode: _this.poolMode,
            value: _this.value,
            interval: _this.interval,
          };
        };

        _this.refreshDescription = function () {
          var type = _this.type,
            cycle = _this.cycle,
            interval = _this.interval,
            beneficiary = _this.beneficiary,
            poolMode = _this.poolMode,
            value = _this.value,
            treatmentTypes = _this.treatmentTypes,
            desc = '',
            ttNames = [];

          switch (cycle) {
            case 0:
              desc += 'Every ' + interval + ' year(s)';
              break;
            case 1:
              desc += 'Every year';
              break;
            case 2:
              desc += 'Every month';
              break;
            case 3:
              desc += 'Everyday';
              break;
            case 4:
              desc += 'Every visit';
              break;
          }

          switch (type) {
            case 0:
            case 1:
              desc += ' limits ';
              break;
            case 2:
            case 3:
              desc += ' allows overdraft of ';
          }

          switch (type) {
            case 0:
            case 2:
              desc += vm.currencyCode + ' ' + value;
              break;
            case 1:
            case 3:
              desc += value + ' visits';
              break;
          }

          switch (beneficiary) {
            case 1:
              desc += ' for principal';
              break;
            case 2:
              desc += ' for dependants';
              break;
            case 3:
              desc += ' for principal & dependants';
              break;
          }

          switch (poolMode) {
            case 0:
              desc += ' where everyone share';
              break;
            case 1:
              desc += ' individually';
              break;
            case 2:
              desc += ' where dependents share';
              break;
          }

          if (treatmentTypes) {
            desc += ' on ';

            _.each(treatmentTypes, function (isApplied, idStr) {
              var id = parseInt(idStr);
              if (isApplied) {
                ttNames.push(
                  _.find(vm.treatmentTypes, function (a) {
                    return a.id === id;
                  }).name
                );
              }
            });

            desc += ttNames.join(', ');
          }

          _this.description = ttNames.length > 0 ? desc : 'Not applicable';
        };

        // Initialization

        if (dto) {
          automap(dto, _this);
          _this.refreshTreatmentValidity();
        }

        _this.refreshDescription();
      }

      function CoPay(bgModel, dto) {
        var _this = this;

        _this.parent = bgModel;

        // Basic properties

        _this.beneficiary = 3;
        _this.isPercentage = true;
        _this.value = null;

        // View properties

        _this.show = true;

        // Treatment type mapping

        _this.principalMap = _.keyBy(bgModel.principalBenefits, 'id');
        _this.dependentMap = _.keyBy(bgModel.dependentBenefits, 'id');

        // Applicable treatment types

        var treatments = [];
        _.each(_this.parent.principalBenefits, function (e) {
          var isValid = checkValid(e.id);
          treatments.push(
            new TreatmentApplyModel(new TreatmentModel(e.id, e.name, e.icon, isValid), isValid)
          );
        });
        _this.treatments = treatments;

        // Private functions

        function checkValid(id) {
          switch (_this.beneficiary) {
            case 3:
              return (
                _this.principalMap[id].valid ||
                (_this.parent.hasDependentCovered === 1 && _this.dependentMap[id].valid)
              );
            case 1:
              return _this.principalMap[id].valid;
            case 2:
              return _this.dependentMap[id].valid;
            default:
              return false;
          }
        }

        // Public functions

        _this.changed = function () {
          _this.refreshTreatmentValidity();
        };

        _this.refreshTreatmentValidity = function () {
          _.each(_this.treatments, function (e) {
            e.treatment.valid = checkValid(e.treatment.id);
          });
        };

        _this.toDto = function () {
          if (vm.isHospitalization) {
            return {
              beneficiary: _this.beneficiary,
              isPercentage: _this.isPercentage,
              value: _this.value,
              isAffectingAdmission: _this.isAffectingAdmission,
              isAffectingPreHospitalization: _this.isAffectingPreHospitalization,
              isAffectingPostHospitalization: _this.isAffectingPostHospitalization,
              isAffectingSelfPayHospitalization: _this.isAffectingSelfPayHospitalization,
            };
          } else {
            return {
              beneficiary: _this.beneficiary,
              isPercentage: _this.isPercentage,
              value: _this.value,
              isAffectingNormalDelivery: _this.isAffectingNormalDelivery,
              isAffectingCaesareanDelivery: _this.isAffectingCaesareanDelivery,
              isAffectingPreNatalCheckUp: _this.isAffectingPreNatalCheckUp,
              isAffectingPostNatalFollowUp: _this.isAffectingPostNatalFollowUp,
              isAffectingNeoNatal: _this.isAffectingNeoNatal,
            };
          }
        };

        // Initialization

        if (dto) {
          automap(dto, _this);
          _this.refreshTreatmentValidity();
        }
      }

      /* End of Types */

      vm.beneficiary = {
        principal: 1,
        dependent: 2,
      };

      init();
    },
  ]);
})();