(function () {
    angular.module('app').component('hostDoraemonFglProcessingRequestDetailsComponent', {
        templateUrl: require('/App/host/views/doraemon/fglProcessing/requestDetails/requestDetails.component.html'),
        controller: [controller],
        controllerAs: 'vm',
        bindings: {
            guaranteeLetterNumber: '<',
            request: '<',
            isEdit: '='
        }
    });

    var app = angular.module('app');

    function controller() {
        var vm = this;

        vm.$onInit = onInit;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.loading = 0;
        vm.isCorporate = App.isCorporate();
        vm.hasState = false;

        function onInit() {
        }

        var fileExtensions = '|' + app.consts.fileUpload.imageExtensions.concat(app.consts.fileUpload.pdfExtensions).join('|') + '|';
        vm.uploadConfig = {
            objectType: 'InpatientFglRequestAttachment',
            itemsLimit: 3,
            accept: 'image/*,application/pdf',
            filters: [{
                name: 'fileFilter',
                fn: function (n) {
                    vm.invalidAttachment = false;
                    var t = '|' + n.type.slice(n.type.lastIndexOf('/') + 1) + '|';
                    if (fileExtensions.indexOf(t) === -1) {
                        vm.invalidAttachment = true;
                        return false;
                    }
                    return true;
                }
            }, {
                name: 'sizeFilter',
                fn: function (n) {
                    vm.invalidAttachmentSize = false;
                    var sizeLimit = 52428800;
                    if (n.size > sizeLimit) {
                        vm.invalidAttachmentSize = true;
                        return false;
                    }
                    return true;
                }
            }]
        };
    }
})();
