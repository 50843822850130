(function () {
    var controllerId = 'host.views.accountOwners';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', 'abp.services.app.accountOwner',
        function ($scope, accountOwnerSvc) {
            function init() {
                getAccountOwners();
            }

            var vm = this;
            vm.loading = false;

            vm.accountOwnerGridOptions = {
                appScopeProvider: vm,
                columnDefs: [{
                    enableSorting: false,
                    name: App.localize('Actions'),
                    width: 100,
                    headerCellTemplate: "<span></span>",
                    cellTemplate: 'actionTemplate'
                }, {
                    displayName: App.localize('AccountOwner'),
                    field: 'name',
                    minWidth: 120
                }, {
                    displayName: App.localize('Country'),
                    field: 'countryCode',
                    minWidth: 25,
                    cellTemplate: 'countryCodeTemplate'
                }, {
                    displayName: App.localize('BusinessRegistrationNumber'),
                    field: 'businessRegistrationNumber',
                    minWidth: 100
                }],
                data: []
            };

            vm.deleteAccountOwner = deleteAccountOwner;

            function getAccountOwners() {
                vm.saving++;
                accountOwnerSvc.getAccountOwners().success(function (data) {
                    vm.accountOwnerGridOptions.data = data.items;
                }).finally(function () {
                    vm.saving--;
                });
            }

            function deleteAccountOwner(accountOwner) {
                abp.message.confirm(App.localize('AccountOwnerDeleteWarningMessage', accountOwner.name), App.localize('AreYouSure'), function (d) {
                    if (d) {
                        accountOwnerSvc.deleteAccountOwner({
                            id: accountOwner.id
                        }).success(function (data) {
                            getAccountOwners();
                            abp.notify.success(App.localize('SuccessfullyDeleted'));
                        });
                    }
                });
            }

            init();
        }
    ]);
})();
