(() => {
  angular.module('app').component('preEmploymentQuestionnaireV191018', {
    templateUrl: require('./preEmploymentQuestionnaireV191018.component.html'),
    controller: PreEmploymentQuestionnaireV191018Controller,
    controllerAs: 'vm',
    bindings: {
      report: '=',
      countryCode: '<',
    },
  });

  PreEmploymentQuestionnaireV191018Controller.$inject = ['$scope'];

  function PreEmploymentQuestionnaireV191018Controller($scope) {
    const vm = this;

    vm.nullError = false;

    init();

    function init() {
      $scope.$on('validateForm', validateForm);
    }

    function validateForm() {
      vm.nullError = false;
      switch (vm.countryCode) {
        case 'MY':
          if (
            !vm.report.measurement ||
            !vm.report.measurement.vision ||
            !vm.report.measurement.auditoryAcuity ||
            !vm.report.measurement.bloodPressure ||
            vm.report.measurement.pulseRhythm == null ||
            !vm.report.skin ||
            !vm.report.respiratory ||
            !vm.report.circulatory ||
            !vm.report.abdomen ||
            !vm.report.nervous ||
            !vm.report.musculoskeletal ||
            !vm.report.hernial ||
            !vm.report.mental ||
            !vm.report.drugAlcohol ||
            !vm.report.urine ||
            !vm.report.pregnant ||
            !vm.report.xRay ||
            !vm.report.electrocardiogram ||
            !vm.report.prescribedMedication ||
            !vm.report.allergy ||
            !vm.report.history ||
            !vm.report.affectAbility ||
            !vm.report.fitForEmployment
          ) {
            vm.nullError = true;
            abp.message.error(App.localize('PreEmploymentReportIncomplete'));
          }

          if (
            (vm.report.measurement.vision.value === 1 &&
              vm.report.measurement.vision.remark === null) ||
            (vm.report.measurement.auditoryAcuity.value === 1 &&
              vm.report.measurement.auditoryAcuity.remark === null) ||
            vm.report.measurement.bloodPressure.remark === null ||
            (vm.report.skin.value === 1 && vm.report.skin.remark === null) ||
            (vm.report.respiratory.value === 1 && vm.report.respiratory.remark === null) ||
            (vm.report.circulatory.value === 1 && vm.report.circulatory.remark === null) ||
            (vm.report.abdomen.value === 1 && vm.report.abdomen.remark === null) ||
            (vm.report.nervous.value === 1 && vm.report.nervous.remark === null) ||
            (vm.report.musculoskeletal.value === 1 && vm.report.musculoskeletal.remark === null) ||
            (vm.report.hernial.value === 1 && vm.report.hernial.remark === null) ||
            (vm.report.mental.value === 1 && vm.report.mental.remark === null) ||
            (vm.report.drugAlcohol.value === 1 && vm.report.drugAlcohol.remark === null) ||
            (vm.report.urine.value === 1 && vm.report.urine.remark === null) ||
            (vm.report.xRay.value === 1 && vm.report.xRay.remark === null) ||
            (vm.report.electrocardiogram.value === 1 &&
              vm.report.electrocardiogram.remark === null) ||
            (vm.report.prescribedMedication.value === 0 &&
              vm.report.prescribedMedication.remark === null) ||
            (vm.report.allergy.value === 0 && vm.report.allergy.remark === null) ||
            (vm.report.history.value === 0 && vm.report.history.remark === null) ||
            (vm.report.affectAbility.value === 0 && vm.report.affectAbility.remark === null) ||
            (vm.report.fitForEmployment.value === 1 && vm.report.fitForEmployment.remark === null)
          )
            abp.message.error(App.localize('PreEmploymentReportIncomplete'));

          if (vm.report.measurement.vision.value !== 1) vm.report.measurement.vision.remark = null;

          if (vm.report.measurement.auditoryAcuity.value !== 1)
            vm.report.measurement.auditoryAcuity.remark = null;

          if (vm.report.skin.value !== 1) vm.report.skin.remark = null;

          if (vm.report.respiratory.value !== 1) vm.report.respiratory.remark = null;

          if (vm.report.circulatory.value !== 1) vm.report.circulatory.remark = null;

          if (vm.report.abdomen.value !== 1) vm.report.abdomen.remark = null;

          if (vm.report.nervous.value !== 1) vm.report.nervous.remark = null;

          if (vm.report.musculoskeletal.value !== 1) vm.report.musculoskeletal.remark = null;

          if (vm.report.hernial.value !== 1) vm.report.hernial.remark = null;

          if (vm.report.mental.value !== 1) vm.report.mental.remark = null;

          if (vm.report.drugAlcohol.value !== 1) vm.report.drugAlcohol.remark = null;

          if (vm.report.urine.value !== 1) vm.report.urine.remark = null;

          if (vm.report.xRay.value !== 1) vm.report.xRay.remark = null;

          if (vm.report.electrocardiogram.value !== 1) vm.report.electrocardiogram.remark = null;

          if (vm.report.prescribedMedication.value !== 0)
            vm.report.prescribedMedication.remark = null;

          if (vm.report.allergy.value !== 0) vm.report.allergy.remark = null;

          if (vm.report.history.value !== 0) vm.report.history.remark = null;

          if (vm.report.affectAbility.value !== 0) vm.report.affectAbility.remark = null;

          if (vm.report.fitForEmployment.value !== 1) vm.report.fitForEmployment.remark = null;

          break;

        case 'SG':
          break;

        // no default
      }
    }
  }
})();
