import _ from 'lodash'

(() => {
  const enumsData = require('./portalEnums.json');
  const app = angular.module('app');

  enumsData.forEach((enumObj) => {
    const enumName = enumObj.name;
    const enumValues = enumObj.values;

    /*
    * Convert enum data to an object with following structure:
      {
        EnumKey1: {
          name: 'EnumKey1',
          id: 0,
        },
        EnumKey2: {
          name: 'EnumKey2',
          id: 1,
        },
        values: [{
            name: 'EnumKey1',
            id: 0,
          },
          {
            name: 'EnumKey2',
            id: 1,
          }
        ]
      }
    */

    const constantObj = { values: [] };

    _.forIn(enumValues, (value, key) => {
      const valueObj = {
        name: key,
        id: value,
      };

      constantObj[key] = valueObj;
      constantObj.values.push(valueObj);
    });

    // Save the enum into AngularJS constants.

    app.constant(enumName, Object.freeze(constantObj));
  });
})();
