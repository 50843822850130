(function init() {
  angular.module('app').controller('host.views.debugTools.updateOpaBundle.modal', [
    '$uibModalInstance',
    '$http',
    function controller($uibModalInstance, $http) {
      const vm = this;
      vm.saving = 0;

      vm.cancel = cancel;
      vm.confirm = confirm;

      vm.userId = 0;
      vm.roleId = 0;
      vm.userBatchSize = 10000;
      vm.serviceNames = [
        {
          value: 'Hms.Revenue',
          name: App.localize('Revenue'),
        },
        {
          value: 'HealthMetrics.Finance',
          name: App.localize('Finance')
        },
        {
          value: 'HealthMetrics.Sms',
          name: App.localize('Sms'),
        },
        {
          value: 'HealthMetrics.Mailing',
          name: App.localize('Mailing'),
        },
      ];
      vm.serviceName = 'Hms.Revenue';
      vm.useDevelopmentBundle = true;

      function cancel() {
        $uibModalInstance.dismiss();
      }

      function confirm() {
        vm.saving += 1;
        let url = '';

        if (vm.useDevelopmentBundle) {
          url = `${vm.baseUrl}/api/UpdateDevelopmentBundleByHttpRequest`;
        } else {
          url = `${vm.baseUrl}/api/UpdateBundleByHttpRequest`;
        }

        const input = {
          userId: vm.userId,
          roleId: vm.roleId,
          serviceName: vm.serviceName,
          userBatchSize: vm.userBatchSize,
        };

        $http({
          method: 'POST',
          url,
          data: JSON.stringify(input),
          headers: {
            accept: 'application/json',
            'x-functions-key': vm.subscriptionKey,
          },
        })
          .then(
            () => {
              $uibModalInstance.dismiss();
              abp.notify.info('Bundle update successfully');
            },
            () => {
              abp.notify.error('Bundle update fail');
            }
          )
          .then(() => {
            vm.saving -= 1;
          });
      }
    },
  ]);
})();
