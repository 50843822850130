(() => {
  angular
    .module('app')
    .component(
      'commonPostHospitalizationGuaranteeLettersDetailsClaimHistoryRecentGuaranteeLettersComponent',
      {
        templateUrl: require('./recentGuaranteeLetters.component.html'),
        controller: RecentGuaranteeLettersComponentController,
        controllerAs: 'vm',
        bindings: {},
      }
    );

  RecentGuaranteeLettersComponentController.$inject = [
    '$stateParams',
    'abp.services.app.postHospitalizationGuaranteeLetter',
    'Hms.GuaranteeLetters.GuaranteeLetterStatus',
  ];

  function RecentGuaranteeLettersComponentController(
    $stateParams,
    postHospitalizationGuaranteeLetterSvc,
    enumGuaranteeLetterStatus
  ) {
    const vm = this;

    vm.loading = 0;
    vm.letters = [];

    vm.enums = {
      guaranteeLetterStatus: enumGuaranteeLetterStatus,
    };

    vm.loadMore = loadMore;
    vm.getDateTimeString = getDateTimeString;

    init();

    function init() {
      getRecentPostHospitalizationGuaranteeLetters(0);
    }

    function getRecentPostHospitalizationGuaranteeLetters(skipCount) {
      vm.loading += 1;
      postHospitalizationGuaranteeLetterSvc
        .getRecentPostHospitalizationGuaranteeLetters({
          letterNumber: $stateParams.letterNumber,
          skipCount,
          maxResultCount: 10,
        })
        .success((data) => {
          vm.totalCount = data.totalCount;
          vm.letters = vm.letters.concat(data.items);
          _.each(vm.letters, (letter) => {
            const l = letter;
            l.disciplineNames = _.join(
              _.map(l.disciplines, (d) => d.name),
              ', '
            );
          });
          vm.hasMoreLetters = data.totalCount > vm.letters.length;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function loadMore() {
      getRecentPostHospitalizationGuaranteeLetters(vm.letters.length);
    }

    function getDateTimeString(date, timeZoneId) {
      return App.getDateTimeString(date, timeZoneId);
    }
  }
})();
