(function () {
  var componentId = 'hostClinicsPanelBillingEditorComponent';
  var app = angular.module('app');

  function controller($scope, enumTenantClinicType) {
    var vm = this;
    vm.loading = 0;
    vm.saving = 0;
    vm.isCreate = false;
    vm.isEditing = false;
    vm.registeredTax = 0;

    vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');

    vm.enums = {};
    vm.enums.tenantClinicType = enumTenantClinicType;

    vm.permissions = {
      changeServiceChargeRate: abp.auth.isGranted('Host.Clinics.Edit.ChangeServiceChargeRate'),
    };

    vm.$onInit = function () {
      vm.container.addWidget(this);

      // Check if account owner is sg account owner on init.

      vm.isSgAccountOwner = vm.accountOwnerCountryCode === 'SG';

      updateTaxDisplayName();
    };

    vm.$onChanges = function (changes) {
      vm.isCreate = vm.data ? !vm.data.id : false;
      if (vm.data && vm.data.taxRegistrationNumber != null) vm.registeredTax = 1;
      if (changes.panelRequest) prefill(vm.panelRequest);

      // Check if account owner is sg account owner on changes.

      vm.isSgAccountOwner = vm.accountOwnerCountryCode === 'SG';

      updateTaxDisplayName();

      // If not sg account owner, force tax inclusive option to false as only supported
      // for sg account owners.

      if (vm.data && !vm.isSgAccountOwner) {
        vm.data.isTaxInclusive = false;
      }
    };

    vm.isDirty = function () {
      return $scope.panelCreateEditBillingForm && $scope.panelCreateEditBillingForm.$dirty;
    };

    vm.setPristine = function () {
      $scope.panelCreateEditBillingForm.$setPristine();
    };

    function prefill(request) {
      if (!request || !vm.data || !vm.isCreate) return;

      vm.data.street = request.street;
      vm.data.city = request.city;
      vm.data.state = request.state;
      vm.data.postcode = request.postcode;
      vm.data.countryCode = request.countryCode;
    }

    function updateTaxDisplayName() {
      vm.taxDisplayName = 'GST';

      const countryCodesForVat = ['ID', 'TH'];

      if (countryCodesForVat.includes(vm.accountOwnerCountryCode)) vm.taxDisplayName = 'VAT';
    }

    vm.onRegisteredTax = function () {
      if (vm.isCreate) vm.data.isTaxInclusive = true;

      if (vm.registeredTax == 0) {
        vm.data.taxRegistrationNumber = null;
        vm.data.isTaxInclusive = false;
      }
    };

    // Force validation on this form.
    // True if valid, otherwise, false.

    vm.validateForm = function (error) {
      var form = $scope.panelCreateEditBillingForm;
      App.touchFormErrors(form);
      error = !(form.$valid && !vm.invalidPostcode);
    };

    // Generate the payload from this form to send to ClinicAppService API.

    vm.getPayload = function (settings) {
      _.extend(settings, {
        billing: vm.data,
      });
    };

    // Ask container to save this widget.

    vm.save = function () {
      vm.container.saveWidget(vm);
    };
  }

  app.component(componentId, {
    require: {
      container: '^hostClinicsPanelEditorContainerComponent',
    },
    bindings: {
      data: '<',
      panelType: '<',
      panelRequest: '<', // For prefilling.
      accountOwnerCountryCode: '<',
    },
    templateUrl: require('/App/host/views/clinics/panelEditor/panelBillingEditor/panelBillingEditor.component.html'),
    controller: ['$scope', 'Hms.MultiTenancy.TenantClinicType', controller],
    controllerAs: 'vm',
  });
})();
