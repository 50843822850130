(function () {
    angular.module('app').component('clinicPatientTicketTreatment', {
        templateUrl: require('/App/clinic/views/patient/ticket/treatment/widget.html'),
        controller: ['$filter', 'abp.services.app.drugSearch', controller],
        controllerAs: 'vm',
        bindings: {
            isDisabled: '<',
            isExpanded: '<',
            isCompleted: '<',
            onDirtyEvent: '<',
            onSetPristineEvent: '<',
            quantity: '<',
            strength: '<',
            dosage: '<',
            treatments: '<',
            ticket: '<',
            pharmacy: '<',
            corporateId: '<',
            onSyncExpansion: '&',
            onValidateTreatment: '&',
            onComplete: '&',
            isCreate: '<',
            currencyCode: '<',
            countryCode: '<'
        }
    });

    function controller($filter, drugSearchSvc) {
        var unitValueFilter = $filter('unitValue');
        var vm = this;
        vm.checkExclusionsForPrescription = checkExclusionsForPrescription;

        vm.$onChanges = function (changes) {
            vm.newTreatments = _.filter(vm.treatments, function (t) {
                return t.displayName !== 'Consultation';
            });

            tidyTreatments();

            if (changes.isExpanded) {
                vm.isOpen = vm.isExpanded;
                updatePostHeader();
            }

            if (changes.onDirtyEvent && changes.onDirtyEvent.currentValue) {
                vm.onDirtyEvent.handler = vm.onDirtyEventHandler;
            }

            if (changes.onSetPristineEvent && changes.onSetPristineEvent.currentValue) {
                vm.onSetPristineEvent.handler = vm.onSetPristineEventHandler;
            }
        };

        vm.$doCheck = function () {
            if (vm.isOpen !== vm.isOpenPreviousValue) {
                // Component expansion must be in sync with parent component's isExpanded state to work as intended.
                // uib-accordion toggles isOpen by itself when user clicks on it.

                vm.onSyncExpansion({ isExpanded: vm.isOpen });
                vm.isOpenPreviousValue = vm.isOpen;
            }
        };

        vm.isOpen = false;
        vm.isOpenPreviousValue = false;
        vm.summaryMessage = '';
        vm.treatmentsIsValid = false;
        vm.filteredDrugs = [];
        var isOnNext = false;

        vm.onDirtyEventHandler = function () {
            return vm.treatmentForm.$dirty;
        };

        vm.onSetPristineEventHandler = function () {
            vm.treatmentForm.$setPristine();
        };

        // To retrieve drug listing including the manual entry (drug suggestion) added by clinic user.

        vm.next = function () {
            if (App.isFormValid(vm.treatmentForm) && vm.areFieldsValid()) {
                _.each(vm.ticket.treatments, function (t) {
                    t.amount = 0;
                    _.each(t.prescriptions, function (p) {
                        if (t.treatment && p.amount) {
                            t.amount += p.amount;
                        }
                    });
                });

                vm.onComplete();
            } else {
                isOnNext = true;
            }
        };

        vm.areFieldsValid = function () {
            // Allow next when patient has pharmacy benefit and there is no treatment.

            if (vm.pharmacy && !vm.hasTreatment()) return true;

            var isValid = true;
            var isTreatmentValid = vm.onValidateTreatment();

            if (!vm.ticket.consultation && !isTreatmentValid) isValid = false;

            return isValid;
        };

        vm.hasTreatment = function () {
            return _.some(vm.ticket.treatments, function (t) {
                return t.treatmentId || t.amount;
            });
        };

        vm.getTreatmentTotalAmount = function () {
            var amount = 0;
            _.each(vm.ticket.treatments, function (t) {
                if (t.treatmentId) {
                    _.each(t.prescriptions, function (p) {
                        amount += (p.amount || 0);
                    });
                }
            });
            return amount;
        };

        vm.checkDuplicateTreatment = function (treatment) {
            return _.some(vm.ticket.treatments, { 'treatmentId': treatment.id });
        };

        vm.updateTreatment = function (ticketTreatment) {
            // Validate whether treatment is covered.

            if (ticketTreatment.treatmentId) {
                var treatment = _.find(vm.treatments, { 'id': ticketTreatment.treatmentId });
                ticketTreatment.isUncovered = treatment.notCovered;
            }

            tidyTreatments();
        };

        var priceRangeMargin = parseFloat(abp.setting.get('Hms.General.GpAveragePriceRangeMargin'));
        vm.tidyTreatmentPrescriptions = function (treatment) {
            // Initialise prescriptions array if necessary.

            if (!treatment.prescriptions) treatment.prescriptions = [];

            // Set drug id to less than -1 for new drug (drug suggestion).

            var prescriptionCnt = -1;
            treatment.amount = 0;
            _.forEach(treatment.prescriptions, function (p) {
                if (p.drugId === null && p.name) {
                    p.drugId = prescriptionCnt -= 1;
                }

                // Calculate treatment amount.

                if (treatment.treatment && p.amount) {
                    treatment.amount += p.amount;
                }
            });

            // Calculate prescription.

            var filledCnt = 0;
            var blankCnt = 0;
            _.each(treatment.prescriptions, function (p) {
                if (p.drugId) filledCnt++;
                else blankCnt++;
            });
            treatment.prescriptionCount = filledCnt;

            // Remove superfluos empty prescriptions.

            while (blankCnt > 1) {
                for (var i = treatment.prescriptions.length - 1; i >= 0; i--) {
                    if (!treatment.prescriptions[i].drugId) {
                        treatment.prescriptions.splice(i, 1);
                        blankCnt--;
                    }
                }
            }

            // Ensure there is always a blank prescription line.

            if (blankCnt < 1) treatment.prescriptions.push({});

            // Update treatment error and alert message.

            var errorMessage = '';
            var alertMessages = [];
            if (treatment.treatmentId) {
                _.each(vm.ticket.treatments, function (t) {
                    if (t === treatment) return false;
                    if (t.treatmentId === treatment.treatmentId) {
                        errorMessage = App.localize('DuplicateTreatmentValidationErrorMessage');
                        return false;
                    }
                });

                if (!errorMessage && !filledCnt && isOnNext) {
                    errorMessage = App.localize('KeyInOnePrescriptionProcedure');
                }

                if (!errorMessage && filledCnt) {
                    _.each(treatment.prescriptions, function (p) {
                        if (!p.drugId) return;

                        // Always show overcharge prescription if prescription amount more than average prescription amount.

                        p.isExceedAveragePrice = false;
                        if (p.quantity
                            && p.drugId > 0
                            && p.pricePerUnit
                            && p.amount > 0
                            && p.preferredQuantityUnit === p.quantity.unit
                            && vm.countryCode == 'MY') {
                            var averageTotalAmount = p.pricePerUnit * p.quantity.value;

                            // Calculate price range based on ±margin of price per unit.
                            // Round up to 1 decimal point only.

                            var minAmount = Math.round(averageTotalAmount * (1 - priceRangeMargin) * 10) / 10;
                            var maxAmount = Math.round(averageTotalAmount * (1 + priceRangeMargin) * 10) / 10;

                            if (p.amount > maxAmount) {
                                p.isExceedAveragePrice = true;
                                alertMessages.push(
                                    App.localize('OverchargePrescriptionForXOfY',
                                        p.quantity.text,
                                        p.name,
                                        $filter('currencyFormat')(minAmount, vm.currencyCode),
                                        $filter('currencyFormat')(maxAmount, vm.currencyCode))
                                );
                            }
                        }

                        if (!p.quantity && isOnNext) {
                            errorMessage = App.localize('MissingQuantityForX', p.name);
                            return false;
                        }

                        if (
                            (p.amount === null
                                || !isFinite(p.amount)
                                || p.amount < 0)
                            && isOnNext) {
                            errorMessage = App.localize('MissingOrInvalidAmountForX', p.name);
                            return false;
                        }
                    });
                }
            } else if (!vm.ticket.consultation) {
                var hasTreatment = _.some(vm.ticket.treatments, 'treatmentId');
                if (!hasTreatment) errorMessage = App.localize('EmptyTreatmentValidationErrorMessage');
            }
            treatment.errorMessage = errorMessage;
            treatment.alertMessages = alertMessages;
        };

        vm.treatmentCount = 0;
        function tidyTreatments() {
            // Check that ticket is defined.

            if (!vm.ticket) return;

            // Initialise treatment array if necessary.

            if (!vm.ticket.treatments) vm.ticket.treatments = [];

            var filledCnt = 0;
            var blankCnt = 0;
            _.forEach(vm.ticket.treatments, function (t) {
                // Calculate blank treatments.

                if (t.treatmentId) filledCnt++;
                else blankCnt++;

                // Create treatment object.

                t.treatment = _.find(vm.treatments, ['id', t.treatmentId]);
            });
            vm.treatmentCount = filledCnt;

            // Remove superfluos empty treatments.

            while (blankCnt > 1) {
                for (var i = vm.ticket.treatments.length - 1; i >= 0; i--) {
                    if (!(+vm.ticket.treatments[i].treatmentId)) {
                        vm.ticket.treatments.splice(i, 1);
                        blankCnt--;
                    }
                }
            }

            // Ensure there is always a blank treatment line.

            if (blankCnt < 1) vm.ticket.treatments.push({});

            // For each treatment, tidy their prescriptions.

            _.forEach(vm.ticket.treatments, function (t) {
                vm.tidyTreatmentPrescriptions(t);
            });
        }

        vm.updatePrescription = function (prescription) {
            if (prescription.drugId) {
                var selectedDrug = _.find(vm.filteredDrugs, { id: prescription.drugId });
                if (selectedDrug) {
                    if (
                        selectedDrug.strength !== null
                        && isFinite(selectedDrug.strength)
                        && selectedDrug.strengthUnit) {
                        prescription.strength = {
                            value: selectedDrug.strength,
                            unit: selectedDrug.strengthUnit
                        };
                        prescription.strength.text = unitValueFilter(prescription.strength);
                    } else {
                        prescription.strength = null;
                    }

                    if (prescription.drugId < 0 && selectedDrug.id < 0) {
                        // Set new drug id to negative number of current time.
                        // To resolve ng-change where unable to change new drug,
                        // due to drug listing set to -1 for all new drug (drug suggestion).

                        var newId = new Date().getTime();
                        prescription.drugId = -Math.abs(newId);
                    }

                    prescription.preferredQuantityUnit = selectedDrug.quantityUnit || '';
                    prescription.preferredDosageUnit = selectedDrug.dosageUnit || '';
                    prescription.name = selectedDrug.productName;
                    prescription.pricePerUnit = selectedDrug.pricePerUnit;
                }
            } else {
                prescription.name = null;
            }
            tidyTreatments();
        };

        var lastFilter = '';
        var cachedResults = {};

        function cleanUserEntry(entry) {
            return _.map(_.split(_.toLower(entry), ' '), function (word) {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }).join(' ');
        }

        function searchDrugs(userEntry) {
            var cleanedUserEntry = cleanUserEntry(userEntry || '');
            filter = _.toLower(cleanedUserEntry);
            lastFilter = filter;

            if (filter) {
                var cachedDrugs = cachedResults[filter];

                if (cachedDrugs) {
                    vm.filteredDrugs = postProcessDrugList(cachedDrugs, filter, cleanedUserEntry);
                } else {
                    drugSearchSvc.searchDrugs({
                        keyword: filter,
                        countryCode: vm.countryCode
                    }).success(function (d) {
                        cachedResults[filter] = d.items;
                        if (filter === lastFilter) {
                            vm.filteredDrugs = postProcessDrugList(d.items, filter, cleanedUserEntry);
                        }
                    });
                }
            } else {
                vm.filteredDrugs = [];
            }

            function postProcessDrugList(drugList, filter, cleanedUserEntry) {
                var clonedItems = drugList.slice(0);
                var idx = _.findIndex(clonedItems, function (d) {
                    return _.toLower(_.trim(d.productName || '')) === filter;
                });
                if (idx > 0) {
                    var items = clonedItems.splice(idx, 1);
                    clonedItems.splice(0, 0, items[0]);
                } else if (idx === -1) {
                    clonedItems.splice(0, 0, {
                        id: -1,
                        productName: cleanedUserEntry
                    });
                }
                return clonedItems;
            }
        }

        vm.lookupDrugs = function (filter) {
            searchDrugs(filter);
        };

        function updatePostHeader() {
            // Calculate number of treatments, prescriptions, and uncovered treatments

            var treatmentCount = 0;
            var prescriptionCount = 0;
            var uncoveredTreatmentCount = 0;

            _.forEach(vm.ticket.treatments, function (t) {
                if (!t.treatmentId) return;

                treatmentCount++;

                var treatment = _.find(vm.newTreatments, { 'id': t.treatmentId });
                if (treatment.notCovered) uncoveredTreatmentCount++;

                _.forEach(t.prescriptions, function (p) {
                    if (p.drugId) prescriptionCount++;
                });
            });

            // Construct header message.

            var message = [];
            if (prescriptionCount) {
                if (uncoveredTreatmentCount) {
                    message.push(App.localize('TreatmentWithUncoveredAndPrescriptionSummaryNarrative',
                        treatmentCount, uncoveredTreatmentCount, prescriptionCount));
                } else {
                    message.push(App.localize('TreatmentAndPrescriptionSummaryNarrative',
                        treatmentCount, prescriptionCount));
                }
                message.push(vm.currencyCode + vm.getTreatmentTotalAmount());
            } else {
                message.push(App.localize('NoTreatmentAndPrescriptionSummaryNarrative'));
            }

            // Assign view model values.

            vm.uncoveredTreatmentLength = uncoveredTreatmentCount;
            vm.summaryMessage = message.join('. ');
        }

        function checkExclusionsForPrescription(treatment, prescription) {
            return (
                checkCorporateExclusion(prescription) ||
                checkTreatmentExclusion(treatment, prescription)
            );
        }

        function checkTreatmentExclusion(treatment, prescription) {
            return prescription.hasTreatmentExclusion =
                _.some(prescription.treatmentExclusions, function (t) {
                    return t === treatment.treatmentId;
                });
        }

        function checkCorporateExclusion(prescription) {
            return prescription.hasCorporateExclusion =
                _.some(prescription.corporateExclusions, function (c) {
                    return c === vm.corporateId;
                });
        }
    }
})();
