(() => {
  angular.module('app').component('commonGuaranteeLettersDetailsBenefitCoverageWidget', {
    templateUrl: require('./widget.html'),
    controller: BenefitCoverageWidgetController,
    controllerAs: 'vm',
    bindings: {},
  });

  BenefitCoverageWidgetController.$inject = [
    '$scope',
    '$stateParams',
    'abp.services.app.guaranteeLetter',
  ];

  function BenefitCoverageWidgetController($scope, $stateParams, guaranteeLetterSvc) {
    const vm = this;

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.defaultMaxAllowance = parseFloat(abp.setting.get('Hms.General.MaxAllowanceAmountLimit'));
    vm.loading = 0;

    init();

    function init() {
      getBenefitCoverage();
    }

    function getBenefitCoverage() {
      vm.loading += 1;
      guaranteeLetterSvc
        .getBenefitCoverage({
          id: $stateParams.letterNumber,
        })
        .success((d) => {
          const data = d;
          vm.corporateBalance = data.corporateBalance;
          vm.employeeBalance = Math.min(data.employeeBalance, vm.defaultMaxAllowance);
          vm.medicationAllowance = data.medicationAllowance;
          vm.benefitGroupName = data.benefitGroupName;
          const { balanceThreshold } = data;

          if (data.pools) {
            data.benefits = [];
            if (data.pools && data.pools.length) {
              _.each(data.pools, (pool) => {
                let title = _.join(
                  _.map(pool.benefitTypes, (bt) => {
                    if (bt.isCustom) {
                      let strCt = `CT${bt.id}`;
                      const idx = _.findIndex(data.ctNames, (ctn) => ctn.value === bt.id);
                      if (idx !== -1) strCt = data.ctNames[idx].name;
                      return strCt;
                    } if (bt.id === 251) {
                      return App.localize('Hospitalization');
                    }
                      return App.localize(bt.name);

                  }),
                  '/'
                );

                const isBudget = pool.modifierType === 0 || pool.modifierType === 2;
                let cycle = '';
                if (!pool.isUnlimited) {
                  switch (pool.modifierCycle) {
                    case 0:
                      cycle = `every ${pool.interval} years `;
                      break;
                    case 1:
                      cycle = 'annual ';
                      break;
                    case 2:
                      cycle = 'monthly ';
                      break;
                    case 3:
                      cycle = 'daily ';
                      break;
                    case 4:
                      cycle = 'per visit ';
                      break;

                    // no default
                  }
                  if (pool.modifierType === 2 || pool.modifierType === 3) cycle += 'overdraft ';
                }
                title += ` (${cycle}${isBudget ? 'budget' : 'visit'})`;

                const isEmptyExcess = pool.isExcess && !pool.employeePortion;
                if (!isEmptyExcess) {
                  data.benefits.push({
                    title,
                    isUnlimited: pool.isUnlimited,
                    isExcess: pool.isExcess,
                    isBudget,
                    limit: pool.limit,
                    used: pool.used,
                    locked: pool.locked,
                    balance: pool.balance,
                  });
                }
              });
            }
          }
          vm.benefits = data.benefits;
          vm.isCorporateBalanceSufficient = vm.corporateBalance >= balanceThreshold;
          vm.isEmployeeBalanceSufficient = vm.employeeBalance >= balanceThreshold;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
