(function () {
    'use strict';

    angular.module('app').component('hostHealthScreeningClaimsCommonRequestDetailsComponent', {
        templateUrl: require('/App/host/views/healthScreeningClaims/common/requestDetails/requestDetails.component.html'),
        controller: ['abp.services.app.patientTicket', controller],
        controllerAs: 'vm',
        bindings: {
            ticket: '<',
            finalRequest: '<',
            panel: '<',
            patient: '<',
            request: '<',
            isView: '<?'
        }
    });

    function controller(patientTicketSvc) {
        var vm = this;
        vm.isView = !!vm.isView;
        vm.doctors = [];

        function refreshDoctors() {
            vm.loading++;
            patientTicketSvc.getLocationDoctors({
                id: vm.panel.panelLocationId
            }).success(function (data) {
                vm.doctors = data.items;
            }).finally(function () {
                vm.loading--;
            });
        }

        refreshDoctors();
    }
})();
