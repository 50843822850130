(() => {
  angular.module('app').component('corporateDashboardCorporateStatistic', {
    templateUrl: require('./corporateStatistic.html'),
    controller: CorporateStatisticController,
    controllerAs: 'vm',
  });

  CorporateStatisticController.$inject = [
    'abp.services.app.dashboard',
    '$sce',
    '$filter',
    'abp.services.app.tenant',
  ];

  function CorporateStatisticController(dashboardSvc, $sce, $filter, tenantSvc) {
    const vm = this;
    vm.statistic = null;
    vm.creditFacility = null;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.feature = {
      hasMobileApp: abp.features.isEnabled('HasMobileApp'),
      isLowBalance: false,
      hasSpecialistModule: abp.features.isEnabled('Specialist.Enabled'),
    };
    vm.feature.hasMobileNpr = vm.feature.hasMobileApp && abp.features.isEnabled('HasMobileNpr');
    vm.activeServices = [];

    vm.widgetCss = {
      availableBalance: 'widget-green',
      openBalance: 'widget-grey',
      unbilledAmount: 'widget-grey',
      unbilledClaimAmount: 'widget-grey',
      onHoldAmount: 'widget-grey',
    };

    vm.isOpen = false;

    const currencyFormat = $filter('currencyFormat');
    const gpLockedAmount = currencyFormat(80, vm.currencyCode);
    const dentalAndOpticalLockedAmount = currencyFormat(200, vm.currencyCode);
    const specialistLockedAmount = currencyFormat(400, vm.currencyCode);

    const notAvalaibleLabel = App.localize('NotAvailableShort');

    const panelLockedAmountNarrative =
      `${App.localize('OnHoldAmountInfo')}</br>` +
      `</br><span>${App.localize('OnHoldAmountInfoNarrative1', gpLockedAmount)}</span>` +
      `</br><span>${App.localize(
        'OnHoldAmountInfoNarrative2',
        dentalAndOpticalLockedAmount
      )}</span>` +
      `</br><span>${App.localize('OnHoldAmountInfoNarrative3', specialistLockedAmount)}</span>`;

    vm.onHoldAmountNarrative = $sce.trustAsHtml(panelLockedAmountNarrative);
    vm.checkIfServiceActive = checkIfServiceActive;
    vm.$onInit = init;

    function init() {
      getTenantActiveServices();

      dashboardSvc.getStatistic().success((data) => {
        vm.statistic = data.statistic;
        vm.creditFacility = data.creditFacility;
        styleWidgets();
      });
    }

    function getPreAuthValue(category, benefitType, valueName) {
      if (!vm.creditFacility) return null;

      const categoryUsage = vm.creditFacility.usages[category];
      if (!categoryUsage) return null;

      const preAuthDetail = categoryUsage.preAuthorizationByServiceType[benefitType];
      if (!preAuthDetail) return null;

      return preAuthDetail[valueName];
    }

    // Style the widgets so that the colors conform to the values that they represent

    function styleWidgets() {
      const widgetColor = {
        green: 'widget-green',
        yellow: 'widget-yellow',
        red: 'widget-red',
        grey: 'widget-grey',
      };

      if (vm.creditFacility) {
        const escrowAmount = vm.creditFacility.collateralAmount;
        const availableBalance = vm.creditFacility.availableCredit;

        // Available balance

        const escrowAmount30Pct = escrowAmount * 0.3;
        const escrowAmount10Pct = escrowAmount * 0.1;

        if (availableBalance > escrowAmount30Pct) {
          vm.widgetCss.availableBalance = widgetColor.green;
        } else if (availableBalance > escrowAmount10Pct) {
          vm.widgetCss.availableBalance = widgetColor.yellow;
          vm.feature.isLowBalance = true;
        } else {
          vm.widgetCss.availableBalance = widgetColor.red;
          vm.feature.isLowBalance = true;
        }

        const openBalance = -vm.creditFacility.outstandingBilled.total;

        // Open balance

        vm.widgetCss.openBalance = openBalance > 0 ? widgetColor.red : widgetColor.grey;

        // Unbilled amount

        vm.widgetCss.unbilledAmount = widgetColor.grey;

        const gpPreAuthCount = getPreAuthValue('general', '1', 'count') || 0;
        const preEmploymentPreAuthCount = getPreAuthValue('general', '4', 'count') || 0;
        const dentalPreAuthCount = getPreAuthValue('general', '2', 'count') || 0;
        const opticalPreAuthCount = getPreAuthValue('general', '3', 'count') || 0;
        const specialistPreAuthCount = getPreAuthValue('general', '5', 'count') || 0;

        vm.gpActiveCheckIns = gpPreAuthCount
          ? App.localize('XNumberofActiveCheckIns', gpPreAuthCount)
          : notAvalaibleLabel;
        vm.preEmploymentActiveCheckIns = preEmploymentPreAuthCount
          ? App.localize('XNumberofActiveCheckIns', preEmploymentPreAuthCount)
          : notAvalaibleLabel;
        vm.dentalActiveCheckIns = dentalPreAuthCount
          ? App.localize('XNumberofActiveCheckIns', dentalPreAuthCount)
          : notAvalaibleLabel;
        vm.opticalActiveCheckIns = opticalPreAuthCount
          ? App.localize('XNumberofActiveCheckIns', opticalPreAuthCount)
          : notAvalaibleLabel;
        vm.specialistActiveCheckIns = specialistPreAuthCount
          ? App.localize('XNumberofPendingReview', specialistPreAuthCount)
          : notAvalaibleLabel;

        vm.gpPreAuthAmount = getPreAuthValue('general', '1', 'amount') || 0;
        vm.preEmploymentPreAuthAmount = getPreAuthValue('general', '4', 'amount') || 0;
        vm.dentalPreAuthAmount = getPreAuthValue('general', '2', 'amount') || 0;
        vm.opticalPreAuthAmount = getPreAuthValue('general', '3', 'amount') || 0;
        vm.specialistPreAuthAmount = getPreAuthValue('general', '5', 'amount') || 0;
      }
    }

    // EndRegion: Widgets

    function getTenantActiveServices() {
      vm.activeServices = [];

      vm.loading += 1;
      tenantSvc
        .getTenantActiveServices({})
        .success((data) => {
          vm.activeServices = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function checkIfServiceActive(serviceId) {
      return vm.activeServices.some((x) => serviceId === x.value);
    }
  }
})();
