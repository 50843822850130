(() => {
  angular.module('app').component('corporateDashboardBillingOverviewStatisticsComponent', {
    templateUrl: require('./billingOverviewStatistics.component.html'),
    controller: BillingOverviewStatisticsController,
    controllerAs: 'vm',
    bindings: {
      creditFacility: '<',
      currencyCode: '<',
      hasInsuranceModule: '<',
      hasAccessFinanceTransactionData: '<',
    },
  });

  function BillingOverviewStatisticsController() {
    const vm = this;

    vm.$onInit = init;

    function init() {
      vm.organizationHasInpatientModule = abp.features.isEnabled('HasInpatientModule');
      styleWidgets();
    }

    vm.loading = false;
    vm.organizationHasInpatientModule = abp.features.isEnabled('HasInpatientModule');

    vm.widgetCss = {
      openBalance: 'widget-grey',
      unbilledAmount: 'widget-grey',
      openBalanceContainer: 'widget-white-smoke',
      unbilledAmountContainer: 'widget-white-smoke',
    };

    // Style the widgets so that the colors conform to the values that they represent

    function styleWidgets() {
      const widgetColor = {
        red: 'widget-red',
        grey: 'widget-grey',
        whiteSmoke: 'widget-white-smoke',
        lavenderBlush: 'widget-lavender-blush',
      };

      // Open balance

      vm.widgetCss.openBalance =
        vm.creditFacility.outstandingBilled.total > 0 ? widgetColor.red : widgetColor.grey;
      vm.widgetCss.openBalanceContainer =
        vm.creditFacility.outstandingBilled.total > 0
          ? widgetColor.lavenderBlush
          : widgetColor.whiteSmoke;

      // Unbilled amount

      vm.widgetCss.unbilledAmount = widgetColor.grey;
      vm.widgetCss.unbilledAmountContainer = widgetColor.whiteSmoke;
    }

    // EndRegion: Widgets
  }
})();
