(() => {
  angular.module('app').component('commonGuaranteeLettersDetailsClaimsWidget', {
    templateUrl: require('./widget.html'),
    controller: ClaimsWidgetController,
    controllerAs: 'vm',
    bindings: {},
  });

  ClaimsWidgetController.$inject = [
    '$stateParams',
    'abp.services.app.guaranteeLetter',
    'Hms.SpecialistClaims.SpecialistClaimStatus',
  ];

  function ClaimsWidgetController($stateParams, guaranteeLetterSvc, enumSpecialistClaimStatus) {
    const vm = this;

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.letterNumber = $stateParams.letterNumber;
    vm.timeZoneId = $stateParams.timeZoneId;
    vm.loading = 0;

    vm.enums = {
      specialistClaimStatus: enumSpecialistClaimStatus,
    };

    init();

    function init() {
      getClaims();
    }

    function getClaims() {
      vm.loading += 1;
      guaranteeLetterSvc
        .getRecentClaims({
          letterNumber: vm.letterNumber,
        })
        .success((data) => {
          vm.totalCount = data.totalCount;
          vm.claims = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
