(function () {
    'use strict';

    angular.module('app').component('hostDoraemonCommonBalanceOverviewComponent', {
        templateUrl: require('/App/host/views/doraemon/common/balanceOverview/balanceOverview.component.html'),
        controller: ['abp.services.app.doraemon', controller],
        controllerAs: 'vm',
        bindings: {
            memberId: '<',
            serviceRequestId: '<',
            letterNumber: '<'
        }
    });

    function controller(doraemonSvc) {
        var vm = this;
        vm.$onInit = onInit;
        vm.$onChanges = onChanges;
        vm.getNewCorporateBalance = getNewCorporateBalance;
        vm.getNewEmployeeBalance = getNewEmployeeBalance;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.loading = false;
        vm.requestAmount = 0;
        vm.newGuaranteeAmount = 0;
        vm.organization = null;
        vm.member = null;
        vm.defaultMaxAllowance = parseFloat(abp.setting.get("Hms.General.MaxAllowanceAmountLimit"));

        function onChanges(changes) {
            if (changes.memberId && changes.memberId.currentValue) {
                getBalanceOverviewByMemberId(vm.memberId);
            }
            if (changes.serviceRequestId && changes.serviceRequestId.currentValue) {
                getBalanceOverviewByServiceRequestId(vm.serviceRequestId);
            }
        }

        function getBalanceOverviewByMemberId(memberId) {
            if (memberId < 1) return;
            vm.loading = true;
            doraemonSvc.getBalanceOverviewByPatientMemberId({
                id: memberId
            }).success(function (data) {
                vm.requestAmount = data.requestAmount;
                vm.newGuaranteeAmount = data.newGuaranteeAmount;
                vm.organization = {
                    currentAvailableAmount: data.organizationBalance.currentAvailableAmount,
                    currentLockedAmount: data.organizationBalance.currentLockedAmount
                };
                vm.member = {
                    currentAvailableAmount: Math.min(data.memberBalance.currentAvailableAmount, vm.defaultMaxAllowance),
                    currentLockedAmount: data.memberBalance.currentLockedAmount
                };
                getNewCorporateBalance();
                getNewEmployeeBalance();
            }).finally(function () {
                vm.loading = false;
            });
        }

        function getBalanceOverviewByServiceRequestId(serviceRequestId) {
            if (!serviceRequestId) return;
            vm.loading = true;
            doraemonSvc.getBalanceOverviewByServiceRequestId({
                id: serviceRequestId
            }).success(function (data) {
                vm.requestAmount = data.requestAmount;
                vm.newGuaranteeAmount = data.newGuaranteeAmount;
                vm.organization = {
                    currentAvailableAmount: data.organizationBalance.currentAvailableAmount,
                    currentLockedAmount: data.organizationBalance.currentLockedAmount
                };
                vm.member = {
                    currentAvailableAmount: Math.min(data.memberBalance.currentAvailableAmount, vm.defaultMaxAllowance),
                    currentLockedAmount: data.memberBalance.currentLockedAmount
                };
                getNewCorporateBalance();
                getNewEmployeeBalance();
            }).finally(function () {
                vm.loading = false;
            });
        }

        function getBalanceOverviewByGuaranteeLetterNumber(letterNumber) {
            vm.loading = true;
            doraemonSvc.getBalanceOverviewByGuaranteeLetterNumber({
                id: letterNumber
            }).success(function (data) {
                vm.requestAmount = data.requestAmount;
                vm.newGuaranteeAmount = data.newGuaranteeAmount;
                vm.organization = {
                    currentAvailableAmount: data.organizationBalance.currentAvailableAmount,
                    currentLockedAmount: data.organizationBalance.currentLockedAmount
                };
                vm.member = {
                    currentAvailableAmount: Math.min(data.memberBalance.currentAvailableAmount, vm.defaultMaxAllowance),
                    currentLockedAmount: data.memberBalance.currentLockedAmount
                };
                getNewCorporateBalance();
                getNewEmployeeBalance();
            }).finally(function () {
                vm.loading = false;
            });
        }

        function getNewCorporateBalance() {
            if (vm.organization) {
                return (vm.organization.currentAvailableAmount + vm.organization.currentLockedAmount) - vm.newGuaranteeAmount;
            }
        }

        function getNewEmployeeBalance() {
            if (vm.member) {
                return (vm.member.currentAvailableAmount + vm.member.currentLockedAmount) - vm.newGuaranteeAmount;
            }
        }

        function onInit() {
            if (vm.memberId !== null && vm.memberId !== undefined) {
                getBalanceOverviewByMemberId(vm.memberId);
            }
            else if (vm.serviceRequestId !== null && vm.memberId !== undefined) {
                getBalanceOverviewByServiceRequestId(vm.serviceRequestId);
            }
            else {
                getBalanceOverviewByGuaranteeLetterNumber(vm.letterNumber);
            }
        }
    }
})();
