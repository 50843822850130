(() => {
    angular.module('finance').component('manualCreditMemoDetailComponent', {
      templateUrl: require('./manualCreditMemoDetail.component.html'),
      controller: ManualCreditMemoDetailComponent,
      controllerAs: 'vm',
      bindings: {
        currencyCode: '<',
        creditMemo: '<',
        creditMemoFrom: '<',
        creditMemoTo: '<',
        isAddressCreditMemoFrom: '<',
        isAddressCreditMemoTo: '<',
        message: '<',
        getDateString: '<'
      },
    });
  
    ManualCreditMemoDetailComponent.$inject = [];
  
    function ManualCreditMemoDetailComponent() {
      const vm = this;
      vm.decimalPlaces = 2;
    }
  })();