(() => {
    angular
      .module('app')
      .controller(
        'common.views.postHospitalization.guaranteeLetters.index',
        GuaranteeLetterPageController
      );
  
    GuaranteeLetterPageController.$inject = [
      '$scope',
      '$stateParams',
      '$state',
      '$timeout',
      'abp.services.app.postHospitalizationGuaranteeLetter',
      'abp.services.app.commonLookup',
      'Hms.GuaranteeLetters.GuaranteeLetterStatus',
      'Hms.GuaranteeLetters.GuaranteeLetterSource',
      'Hms.ServiceRequests.ServiceRequestStatus',
      'Hms.ServiceRequests.ServiceRequestQueryStatus',
      'moment',
      'appConstants',
    ];
  
    function GuaranteeLetterPageController(
      $scope,
      $stateParams,
      $state,
      $timeout,
      guaranteeLetterSvc,
      commonLookupSvc,
      enumGuaranteeLetterStatus,
      enumGuaranteeLetterSource,
      enumServiceRequestStatus,
      enumServiceRequestQueryStatus,
      moment,
      appConstants
    ) {
      const vm = this;
  
      // #region Variable declaration
  
      vm.loading = 0;
      vm.exporting = 0;
      vm.breadcrumb = [];
      vm.pageTitle = '';
      vm.clinicLocations = [];
      vm.isPanel = App.isClinic();
      vm.isHost = App.isHost();
      vm.advancedFiltersAreShown = false;
  
      vm.enums = {
        guaranteeLetterStatus: enumGuaranteeLetterStatus,
        guaranteeLetterSource: enumGuaranteeLetterSource,
        serviceRequestStatus: enumServiceRequestStatus,
        serviceRequestQueryStatus: enumServiceRequestQueryStatus,
      };
  
      vm.requestParams = {
        filter: $stateParams.filter,
        status: $stateParams.status,
        letterNumber: $stateParams.letterNumber,
        serviceType: $stateParams.serviceType,
        claimNumber: $stateParams.claimNumber,
        ticketNumber: $stateParams.ticketNumber,
        employee: $stateParams.employee,
        company: $stateParams.company,
        panelLocationId: $stateParams.panelLocation,
        skipCount: $stateParams.skipCount || 0,
        maxResultCount: $stateParams.maxResultCount || appConstants.grid.defaultPageSize,
        sorting: $stateParams.sorting,
        latestRequestType: $stateParams.latestRequestType,
        latestRequestStatus: $stateParams.latestRequestStatus,
        queryStatus: $stateParams.queryStatus
      };
  
      vm.approvalDateRangeOptions = App.createDateRangePickerOptions();
      vm.approvalDateRangeModel = {
        startDate: $stateParams.letterStartDate ? moment($stateParams.letterStartDate) : null,
        endDate: $stateParams.letterEndDate ? moment($stateParams.letterEndDate) : null,
        };

        vm.appliedDateRangeOptions = App.createDateRangePickerOptions();
        vm.appliedDateRangeModel = {
          startDate: $stateParams.appliedStartDate ? moment($stateParams.appliedStartDate) : null,
          endDate: $stateParams.appliedEndDate ? moment($stateParams.appliedEndDate) : null,
        };
  
      vm.gridOptions = {
        appScopeProvider: vm,
        paginationPageSizes: appConstants.grid.defaultPageSizes,
        paginationPageSize: appConstants.grid.defaultPageSize,
        paginationCurrentPage: 0,
        useExternalPagination: true,
        useExternalSorting: true,
        resizable: true,
        columnDefs: [
          {
            name: 'Actions',
            displayName: 'Actions',
            enableSorting: false,
            minwidth: 70,
            width: 100,
            headerCellTemplate: '<span></span>',
            cellTemplate: 'actionTemplate',
          },
          {
            displayName: App.localize('AdmissionGuaranteeLetterNumber'),
            field: 'admissionGuaranteeLetterNumber',
            visible: !vm.isPanel,
            minWidth: 100,
            width: 150,
            cellTemplate: 'admissionGuaranteeLetterNumberTemplate',
          },
          {
            displayName: App.localize('GuaranteeLetterNumber'),
            field: 'letterNumber',
            visible: !vm.isPanel,
            minWidth: 100,
            width: 150,
          },
          {
            displayName: App.localize('AppliedBy'),
            enableSorting: false,
            field: 'source',
            visible: vm.isHost,
            minWidth: 100,
            cellTemplate: 'appliedByTemplate',
          },
          {
            displayName: App.localize('Aging'),
            field: 'aging',
            minWidth: 70,
          },
          {
            displayName: App.localize('ApprovalDate'),
            field: 'approvalDate',
            minWidth: 120,
            cellTemplate: 'approvalDateTemplate',
          },
          {
            displayName: App.localize('ClaimNumber'),
            field: 'claimNumber',
            visible: vm.isHost,
            minWidth: 100,
            width: 150,
          },
          {
            displayName: App.localize('TicketNumber'),
            field: 'patientTicketTicketNumber',
            minWidth: 100,
            width: 150,
            cellTemplate: 'ticketNumberTemplate',
          },
          {
            displayName: App.localize('PanelLocation'),
            enableSorting: false,
            field: 'panelLocationName',
            visible: !vm.isPanel,
            cellTemplate: 'panelLocationTemplate',
            minWidth: 120,
          },
          {
            displayName: App.localize('Patient'),
            enableSorting: false,
            field: 'employeeName',
            visible: !vm.isPanel,
            cellTemplate: 'patientTemplate',
            minWidth: 120,
          },
          {
            displayName: App.localize('GlStatus'),
            field: 'status',
            width: 120,
            minWidth: 120,
            cellTemplate: 'statusTemplate'
          }, 
          {
            displayName: App.localize('LatestRequestType'),
            field: 'latestRequestDescription',
            minWidth: 100,
            cellTemplate: 'latestRequestType',
            width: 220
          }, 
          {
            displayName: App.localize('QueryStatus'),
            field: 'queryStatus',
            minWidth: 100,
            cellTemplate: 'queryStatusTemplate'
          },
          {
            displayName: App.localize('Principal'),
            enableSorting: false,
            field: 'principalName',
            visible: !vm.isPanel,
            cellTemplate: 'principalTemplate',
            minWidth: 120,
          },
          {
            displayName: App.localize('Insurer'),
            field: 'insurerTeamName',
            minWidth: 100,
            cellTemplate: 'insurerTemplate'
          },
          {
            displayName: App.localize('PolicyNo'),
            field: 'masterPolicyNumber',
            minWidth: 100,
            cellTemplate: 'policyNoTemplate'
          },
          {
            displayName: App.localize('Company'),
            enableSorting: false,
            field: 'corporateName',
            visible: vm.isHost,
            minWidth: 120,
            cellTemplate: 'companyTemplate',
          },
          {
            displayName: App.localize('Subsidiary'),
            enableSorting: false,
            field: 'employeeSubsidiaryName',
            minWidth: 120,
          },
          {
            displayName: App.localize('AppliedDate'),
            enableSorting: false,
            field: 'creationTime',
            minWidth: 120,
            cellTemplate: 'appliedDateTemplate'
          },
        ],
        onRegisterApi(gridApi) {
          $scope.gridApi = gridApi;
          $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
            vm.requestParams.sorting =
              callback.length && callback[0].field
                ? `${callback[0].field} ${callback[0].sort.direction}`
                : null;
            vm.getGuaranteeLetters();
          });
          gridApi.pagination.on.paginationChanged($scope, (newPage, pageSize) => {
            vm.requestParams.skipCount = (newPage - 1) * pageSize;
            vm.requestParams.maxResultCount = pageSize;
            vm.getGuaranteeLetters();
          });
        },
        data: [],
      };
  
      vm.getGuaranteeLetters = getGuaranteeLetters;
      vm.getGuaranteeLetterSourceLocalizedString = getGuaranteeLetterSourceLocalizedString;
      vm.getDateTimeString = getDateTimeString;
      vm.processRequest = processRequest;
  
      // #endregion Variable declaration
  
      init();
  
      function init() {
        initBreadcrumb();
        loadClinicLocationsFilter();
        getListingFilters();
  
        $timeout(() => {
          vm.gridOptions.paginationCurrentPage = Math.floor(
            vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1
          );
        });
      }
  
      // #region Retrieve breadcrumb details from URL.
  
      function initBreadcrumb() {
        if (vm.isPanel) {
          vm.breadcrumb.push({ name: App.localize('PostHospitalization') });
          vm.breadcrumb.push({ name: App.localize('GuaranteeLetters') });
        } else {
          vm.breadcrumb.push({ name: App.localize('GuaranteeLetters') });
          vm.breadcrumb.push({ name: App.localize('PostHospitalization') });
        }
  
        // Fallback for the breadcrumb title.
  
        vm.pageTitle = vm.breadcrumb.length > 0 ? vm.breadcrumb.slice(-1)[0].name : '';
      }
  
      // #endregion Retrieve breadcrumb details from URL.
  
      // #region Define function
  
      function loadClinicLocationsFilter() {
        vm.loading += 1;
        commonLookupSvc
          .findClinicLocations({})
          .success((data) => {
            vm.clinicLocations = data.items;
          })
          .finally(() => {
            if (!vm.clinicLocations.length) {
              vm.requestParams.panelLocationId = null;
            }
            vm.loading -= 1;
          });
      }
  
      function getRequestInput() {
        const input = $.extend({}, vm.requestParams);
        input.approvalStartDate = vm.approvalDateRangeModel.startDate;
        input.approvalEndDate = vm.approvalDateRangeModel.endDate;
        input.appliedStartDate = vm.appliedDateRangeModel.startDate;
        input.appliedEndDate = vm.appliedDateRangeModel.endDate;

        registerStateParams();
  
        return input;
      }
  
      function getGuaranteeLetters() {
        const input = getRequestInput();
  
        vm.loading += 1;
        guaranteeLetterSvc
          .getGuaranteeLetters(input)
          .success((data) => {
            vm.gridOptions.totalItems = data.totalCount;
            vm.gridOptions.data = _.map(data.items, (d) =>
              $.extend(d, {
                isActive: !(d.status === 3 || d.status === 4 || d.status === 5),
              })
            );
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
      function registerStateParams() {
        const approvalStartDate = vm.approvalDateRangeModel.startDate
          ? vm.approvalDateRangeModel.startDate.format('YYYY-MM-DD')
          : null;
        const approvalEndDate = vm.approvalDateRangeModel.endDate
          ? vm.approvalDateRangeModel.endDate.format('YYYY-MM-DD')
          : null;
        const appliedStartDate = vm.appliedDateRangeModel.startDate
            ? vm.appliedDateRangeModel.startDate.format('YYYY-MM-DD')
            : null;
        const appliedEndDate = vm.appliedDateRangeModel.endDate
            ? vm.appliedDateRangeModel.endDate.format('YYYY-MM-DD')
            : null;

        $state.transitionTo(
          $state.current,
          {
            filter: vm.requestParams.filter,
            status: vm.requestParams.status,
            letterNumber: vm.requestParams.letterNumber,
            serviceType: vm.requestParams.serviceType,
            employee: vm.requestParams.employee,
            panelLocation: vm.requestParams.panelLocationId,
            claimNumber: vm.requestParams.claimNumber,
            ticketNumber: vm.requestParams.ticketNumber,
            company: vm.requestParams.company,
            approvalStartDate,
            approvalEndDate,
            appliedStartDate,
            appliedEndDate,
            skipCount: vm.requestParams.skipCount,
            maxResultCount: vm.requestParams.maxResultCount,
            sorting: vm.requestParams.sorting,
            latestRequestType: vm.requestParams.latestRequestType,
            latestRequestStatus: vm.requestParams.latestRequestStatus,
            queryStatus: vm.requestParams.queryStatus
          },
          {
            notify: false,
          }
        );
      }

      function getGuaranteeLetterSourceLocalizedString(guaranteeLetterSource) {
        const enumMap = vm.enums.guaranteeLetterSource.values.find(
          (x) => x.id === guaranteeLetterSource
        );
        if (!enumMap) {
          return '';
        }
        return enumMap.name;
      }

      function getDateTimeString(date, timeZoneId) {
        return App.getDateTimeString(date, timeZoneId);
      }

      function getListingFilters() {
        guaranteeLetterSvc.getListingFilters()
            .success((data) => {
                vm.admissionTypes = data.admissionTypes;
                vm.letterStatuses = data.letterStatuses;
                vm.serviceRequestStatuses = data.requestStatuses;
                vm.serviceRequestTypes = data.requestTypes;
                vm.queryStatuses = data.queryStatuses;
                vm.serviceTypes = data.serviceTypes;
            });
      }

      function processRequest(guaranteeLetter) {
        if (guaranteeLetter.latestRequestType === 'Initial') {
          $state.go('postHospitalizationIglProcess', {
            letterNumber: guaranteeLetter.letterNumber,
            requestId: guaranteeLetter.latestRequestId,
            memberId: guaranteeLetter.employeeId,
            serviceType: guaranteeLetter.serviceType,
          });
        }
        //  else if (guaranteeLetter.latestRequestType === 'CheckIn') {
        //   $state.go('host.checkInRequest', {
        //     letterNumber: guaranteeLetter.letterNumber,
        //     requestId: guaranteeLetter.latestRequestId,
        //     memberId: guaranteeLetter.patientMemberId,
        //   });
        // } else if (guaranteeLetter.latestRequestType === 'TopUpAddOn') {
        //   $state.go('host.topupAddonRequest', {
        //     letterNumber: guaranteeLetter.letterNumber,
        //     requestId: guaranteeLetter.latestRequestId,
        //     memberId: guaranteeLetter.patientMemberId,
        //   });
        // }
      }
  
      // #endregion Define function
    }
  })();
  