(function () {
    var componentId = 'hostPanelRecruitmentScheduleTrainingComponent';
    var app = angular.module('app');

    function controller($scope, $stateParams, panelRequestSvc) {
        function init() {
        }

        var vm = this;
        vm.saving = 0;

        vm.$onChanges = function (changes) {
            if (changes.panelRequest) {
                prefillForm(vm.panelRequest);
            }
        };

        function prefillForm(request) {
            if (request) {
                vm.formData.requestNumber = request.requestNumber;
                vm.formData.nextAssigneeId = request.masterOpsId;
            }
        }

        // See PanelRequestAppService.ScheduleTraining's input.
        vm.formData = {
            requestNumber: null,
            nextAssigneeId: null,
            trainingDate: null,
            remarks: null
        };

        vm.cancel = function () {
            vm.onCancel();
        };

        vm.save = function () {
            if (!App.isFormValid($scope.scheduleTrainingForm) || vm.saving) return;

            vm.saving++;
            panelRequestSvc.scheduleTraining(vm.formData)
                .success(function () {
                    abp.notify.info(App.localize('SuccessfullySaved'));
                    vm.onSaved();
                }).finally(function () {
                    vm.saving--;
                });
        };

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/panelRecruitment/recruitmentTimeline/scheduleTraining/scheduleTraining.component.html'),
        controller: ['$scope', '$stateParams', 'abp.services.app.panelRequest', controller],
        controllerAs: 'vm',
        bindings: {
            onSaved: '&', // Called when schedule training successful.
            onCancel: '&', // Called when user click cancel.
            panelRequest: '<', // The current panel request for pre-filling.
            masterOperators: '<' // A list of master operators for choosing next assignee.
        }
    });
})();
