(function () {
    var componentId = 'hostClinicsPanelLocationEditorComponent';
    var app = angular.module('app');

    function controller($scope, commonLookupSvc, enumTenantClinicType, enumPriceRating, constsTenant, moment) {
        function init() {
            getApplicablePanelService();

            vm.loading++;
            commonLookupSvc.getEligibleCountries().success(function (data) {
                vm.eligibleCountryCodes = data.map(o => o.code)
            }).finally(function (e) {
                vm.loading--;
            });
        }

        var vm = this;
        vm.constsTenant = constsTenant;
        vm.loading = 0;
        vm.saving = 0;
        vm.isCreate = false;
        vm.useBillingAddress = true;
        vm.fromPanelRequest = false;
        vm.data = {
            operatingHours: [],
            countryCode: abp.setting.get('Hms.General.DefaultCountryCode'),
            startDate: moment().startOf('day').add(45, 'days'),
            priceRating: 2 // default to "Preferred" price rating.
        };
        vm.isGP = false;
        vm.getDefaultPanelService = getDefaultPanelService;
        vm.selectedServices = [];

        vm.enums = {};
        vm.enums.tenantClinicType = enumTenantClinicType;
        vm.enums.priceRating = enumPriceRating;

        // Check for inpatient module enable for the application.

        vm.applicationHasInpatientModule = abp.setting.getBoolean('Hms.Feature.InpatientModule');

        vm.$onInit = function () {
            vm.container.addWidget(this);
        };

        vm.$onChanges = function (changes) {
            vm.isCreate = vm.data ? !vm.data.id : false;

            if (changes.panelRequest) prefill(vm.panelRequest);

            // 191023: Currently, bell-curved price rating is just apply for GP.
            // During creation, if panel type is not GP, set price rating to default value, "Preferred".
            if (changes.panelType)
                vm.isGP = changes.panelType.currentValue === 1;

            if (vm.isCreate && !vm.isGP) {
                vm.data.priceRating = 2;
            }

            selectDefaultPanelService();
        };

        vm.isDirty = function () {
            return $scope.panelCreateEditLocationForm && $scope.panelCreateEditLocationForm.$dirty;
        };

        vm.setPristine = function () {
            $scope.panelCreateEditLocationForm.$setPristine();
        };

        function prefill(request) {
            if (!request || !vm.data || !vm.isCreate) return;

            vm.data.latLng = request.latLng;
            vm.fromPanelRequest = true;
        }

        vm.clinicLanguages = [];
        vm.lookupClinicLanguages = function (search) {
            commonLookupSvc.findSpokenLanguages({
                filter: search,
                maxResultCount: 50
            }).success(function (data) {
                vm.clinicLanguages = data.items;
            });
        };

        // Force validation on this form.
        // True if valid, otherwise, false.

        vm.validateForm = function (error) {
            var form = $scope.panelCreateEditLocationForm;
            App.touchFormErrors(form);
            error = !(form.$valid && !vm.invalidPostcode);
        };

        // Generate the payload from this form to send to ClinicAppService API.

        vm.getPayload = function (settings) {
            // Copy address and contact number if user choose to.

            if (vm.useBillingAddress) {
                if (!vm.billingData || !vm.propertiesData) {
                    console.error('Missing billing data or properties data.');
                }

                vm.data.street = vm.billingData.street;
                vm.data.city = vm.billingData.city;
                vm.data.postcode = vm.billingData.postcode;
                vm.data.state = vm.billingData.state;
                vm.data.countryCode = vm.billingData.countryCode;
                vm.data.countrySubdivisionId = vm.billingData.countrySubdivisionId;
                vm.data.contactNumber = vm.propertiesData.contactNumber;
            }

            // Process selected service

            vm.data.services = _.reduce(vm.selectedServices, function (result, value, index) {
                if (value) {
                    result.push(index);
                }
                return result;
            }, []);

            _.extend(settings, {
                location: vm.data
            });
        };

        // Ask container to save this widget.

        vm.save = function () {
            vm.container.saveWidget(vm);
        };

        init();

        function getApplicablePanelService() {
            commonLookupSvc.getApplicablePanelServices()
                .success(function (result) {
                    vm.applicablePanelServices = result;
                });
        }

        function selectDefaultPanelService() {
            vm.selectedServices = [];
            vm.selectedServices[vm.getDefaultPanelService(vm.panelType)] = true;
        }

        function getDefaultPanelService(panelType) {
            // Return default service type given panel type.

            switch (panelType) {
                case 1: // GP
                    return 1;

                case 2: // Dental
                    return 2;

                case 4: // Optical
                    return 3;

                case 5: // Specialist
                    return 5;

                case 6: // Pharmacy
                    return 6;

                case 7: // Hospital
                    return 7;

                default:
                    return 0;
            }
        }
    }

    app.component(componentId, {
        require: {
            container: '^hostClinicsPanelEditorContainerComponent'
        },
        bindings: {
            panelType: '<',
            billingData: '<', // Only for copying billing address to location. Don't manipulate data in this value.
            propertiesData: '<', // Only for copying contact number to location. Don't manipulate data in this value.
            panelRequest: '<' // For prefilling
        },
        templateUrl: require('/App/host/views/clinics/panelEditor/panelLocationEditor/panelLocationEditor.component.html'),
        controller: ['$scope', 'abp.services.app.commonLookup', 'Hms.MultiTenancy.TenantClinicType', 'Hms.PanelLocationPriceRatings.PriceRating', 'Tenant', 'moment', controller],
        controllerAs: 'vm'
    });
})();
