(() => {
  angular
    .module('finance')
    .controller('finance.views.signPaymentInstructionModal', SignPaymentInstructionModalController);

  SignPaymentInstructionModalController.$inject = [
    '$uibModalInstance',
    'abp.services.finance.paymentInstruction',
    'batchNumber',
    'batchId',
    'type'
  ];

  function SignPaymentInstructionModalController(
    $uibModalInstance,
    paymentInstructionSvc,
    batchNumber,
    batchId,
    type) {
    const vm = this;
    
    vm.saving = 0;
    vm.type = type;
    vm.batchNumber = batchNumber;

    vm.save = save;
    vm.cancel = cancel;

    init();

    function init() { }

    function save() {
      const signature = vm.accept();
      if (signature.isEmpty || !signature.dataUrl) {
        abp.message.warn(App.localize('MissingSignature'));
        return;
      }
      vm.saving += 1;

      let endpoint;
      if (type === 'verify') {
        endpoint = paymentInstructionSvc.verifyPaymentInstructions;
      } else if (type === 'approve') {
        endpoint = paymentInstructionSvc.approvePaymentInstructions;
      }

      endpoint({
        // eslint-disable-next-line object-shorthand
        batchId: batchId,
        dataUrl: signature.dataUrl,
      })
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          $uibModalInstance.close();
        })
        .finally(() => {
          vm.saving -= 1;
        });

        if (vm.saving)
          vm.saving -= 1;
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

  }
})();
