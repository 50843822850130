(function () {
    var componentId = 'hostPanelRecruitmentPanelRequestEditorComponent';
    var app = angular.module('app');

    function controller($timeout, $scope, $modal, panelRequestSvc, enumTenantClinicType, enumPanelRequestSource, enumPanelRequestPriority, constsTenant, constsPanelRequest) {
        var vm = this;
		    vm.constsTenant = constsTenant;
		    vm.constsPanelRequest = constsPanelRequest;

        vm.loading = 0;
        vm.saving = 0;
        vm.isEdit = false;
        vm.showMapSearch = false;
        vm.request = null;
        vm.requestBackup = null;
        vm.isRequestAssignee = false;
        vm.masterCorporates = null;
        vm.filteredMasterCorporates = null;

        vm.enums = {};
        vm.enums.tenantClinicType = enumTenantClinicType;
        vm.enums.panelRequestSource = enumPanelRequestSource;
        vm.enums.panelRequestPriority = enumPanelRequestPriority;

        vm.$onChanges = function (changes) {
            if (changes.panelRequestEditModel) {
                vm.getPanelRequest(changes.panelRequestEditModel.currentValue);
            }
        };

        vm.permissions = {
            edit: abp.auth.isGranted('PanelRequests.Host.Edit'),
            override: abp.auth.isGranted('PanelRequests.Host.Override'),
            merge: abp.auth.isGranted('PanelRequests.Host.Merge')
        };

        vm.editRequest = function () {
            toggleEditMode(true, true);
        };

        vm.cancelEdit = function () {
            toggleEditMode(true, false);
            vm.showMapSearch = false;
        };

        function toggleEditMode(doRestoreOriginal, isEditToBe) {
            isEditToBe = isEditToBe || !vm.isEdit; // optional.

            if (!isEditToBe && doRestoreOriginal) {
                vm.request = vm.requestBackup; // Restore backup.
            } else if (isEditToBe) {
                vm.requestBackup = angular.copy(vm.request); // Backup snapshot
            }

            vm.isEdit = isEditToBe;
        }

        // Panel Request Actions

        vm.getPanelRequest = function (data) {
            if (!data) return;

            vm.request = data.request;

            vm.isRequestAssignee = data.request.masterOpsUserId === abp.session.userId;
            vm.masterCorporates = data.masterCorporates;
            vm.filterMasterCorporates();

            if (vm.request.latLng) {
                vm.map.center = convertLatLng(vm.request.latLng);
            }
            vm.latLngUpdated();

            vm.map.init();
        };

        // Force validation on this form.
        // Return true if valid, otherwise, false.

        vm.validateForm = function () {
            var form = $scope.requestInformation;
            App.touchFormErrors(form);
            return form.$valid && !vm.invalidPostcode;
        };

        vm.updateRequest = function () {
            if (!vm.validateForm()) {
                abp.notify.error(App.localize('GeneralInvalidFormInputError'));
                return;
            }

            if (_.isEmpty(vm.request.email)) {
                vm.request.email = null;
            }

            vm.saving++;
            panelRequestSvc.editPanelRequest(vm.request)
                .success(function () {
                    abp.notify.info(App.localize('SuccessfullySaved'));
                    toggleEditMode(true, false);
                }).finally(function () {
                    vm.saving--;
                    vm.onSaved();
                });
        };

        vm.mergeRequest = function () {
            $modal.open({
                templateUrl: require('/App/host/views/panelRecruitment/mergePanelRequests/mergePanelRequests.modal.html'),
                controller: 'host.views.panelRecruitment.mergePanelRequestsModal as vm',
                backdrop: 'static',
                resolve: {
                    requestNumber: function () {
                        return vm.request.requestNumber;
                    }
                }
            }).result.then(function () {
                vm.onSaved();
            });
        };

        // Map

        function getLatLngForMap() {
            if (vm.request && vm.request.latLng) return vm.request.latLng;
            return {
                lat: 3.031647,
                lng: 101.617452
            };
        }

        function convertLatLng(latLng) {
            return {
                latitude: latLng.lat,
                longitude: latLng.lng
            };
        }

        vm.map = {
            isInit: false,
            hasLatLng: true,
            marker: {
                id: 1,
                coords: convertLatLng(getLatLngForMap()),
                options: { draggable: true },
                events: {
                    dragend: function (marker, eventName, args) {
                        vm.request.latLng = {
                            lat: marker.getPosition().lat(),
                            lng: marker.getPosition().lng()
                        };
                    }
                }
            },
            searchbox: {
                options: {
                    autocomplete: true,
                    componentRestrictions: { country: abp.setting.get('Hms.General.DefaultCountryCode') }
                },
                events: {
                    place_changed: function (searchBox) {
                        var place = searchBox.getPlace();

                        vm.request.latLng = {
                            lat: place.geometry.location.lat(),
                            lng: place.geometry.location.lng()
                        };
                        vm.map.center = convertLatLng(vm.request.latLng);
                        vm.latLngUpdated();
                    }
                }
            },
            options: App.createMapOptions(),
            init: function () {
                if (vm.map.isInit) {
                    return;
                }
                vm.map.isInit = true;

                var latLng = getLatLngForMap();
                vm.map.center = convertLatLng(latLng);
                vm.map.marker.coords = convertLatLng(latLng);
                vm.map.hasLatLng = true;
                if (!vm.request.latLng) {
                    $timeout(function () {
                        vm.map.hasLatLng = false;
                    }, 250);
                }
            }
        };

        vm.latLngUpdated = function () {
            if (!vm.map.isInit) return;

            var latLng = getLatLngForMap();
            vm.map.center = convertLatLng(latLng);
            vm.map.marker.coords = convertLatLng(latLng);
            vm.map.hasLatLng = vm.request.latLng;
        };

        vm.filterMasterCorporates = function () {
            vm.filteredMasterCorporates = _.filter(vm.masterCorporates, function (m) {
                return m.accountOwnerCountryCode === vm.request.countryCode || m.accountOwnerCountryCode === null;
            })
        };
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/panelRecruitment/panelRequestEditor/panelRequestEditor.component.html'),
        controller: ['$timeout', '$scope', '$uibModal', 'abp.services.app.panelRequest', 'Hms.MultiTenancy.TenantClinicType', 'Hms.PanelRequests.PanelRequestSource', 'Hms.PanelRequests.PanelRequestPriority', 'Tenant', 'Hms.PanelRequests.PanelRequest', controller],
        controllerAs: 'vm',
        bindings: {
            panelRequestEditModel: '<',
            onSaved: '&',
            isEdit: '='
        }
    });
})();
