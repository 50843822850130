(() => {
  angular
    .module('app')
    .controller(
      'host.views.operationsDashboard.insuranceClaims.batchAction.page',
      BatchActionPageController
    );

  BatchActionPageController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$uibModal',
    'abp.services.app.insurerClaim',
    'abp.services.app.commonLookup',
    'Hms.InsurerClaims.InsurerClaimEnums.InsurerClaimSubCategory',
    'Hms.Services.ServiceType',
    'moment',
    'appConstants',
  ];

  function BatchActionPageController(
    $scope,
    $state,
    $stateParams,
    $uibModal,
    insurerClaimSvc,
    commonLookupSvc,
    enumInsurerClaimSubCategory,
    enumServiceType,
    moment,
    appConstants
  ) {
    const vm = this;

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.loading = 0;
    vm.saving = 0;
    vm.activeTab = 0;
    vm.batchedDraftTickets = [];
    vm.exporting = 0;
    vm.hasBatchSubmitted = false;
    vm.insurerName = '';
    vm.insurerNameDict = {};
    vm.batchNumber = '';
    vm.batchFolderName = '';
    vm.selectedRows = [];

    vm.hasInsuranceClaimsBatchingEnhancement = abp.setting.getBoolean(
      'Hms.Feature.InsuranceClaimsBatchingEnhancement'
    );

    vm.enums = {};
    vm.enums.insurerClaimSubCategory = enumInsurerClaimSubCategory;
    vm.enums.serviceType = enumServiceType;

    // Declare file saving parameters.

    const fileExtensions = `|${appConstants.fileUpload.imageExtensions
      .concat(appConstants.fileUpload.pdfExtensions)
      .join('|')}|`;
    vm.uploadConfig = {
      objectType: 'InsuranceClaimBatchActionAttachment',
      itemsLimit: 10,
      accept: 'image/*,application/pdf',
      filters: [
        {
          name: 'fileFilter',
          fn(n) {
            const t = `|${n.type.slice(n.type.lastIndexOf('/') + 1)}|`;
            if (fileExtensions.indexOf(t) === -1) {
              abp.message.warn(App.localize('File_Warn_FileType'));
              return false;
            }
            return true;
          },
        },
        {
          name: 'sizeFilter',
          fn(n) {
            const sizeLimit = 52428800;
            if (n.size > sizeLimit) {
              abp.message.warn(App.localize('File_Warn_XSizeLimit', sizeLimit / 1024 / 1024));
              return false;
            }
            return true;
          },
        },
      ],
    };

    // Declare request params.

    vm.requestParams = {
      insurerTeamId: null,
      organizationId: null,
      policyNumber: null,
      activeTab: $stateParams.activeTab,
      insurerBatchId: $stateParams.insurerBatchId,
      ticketNumbers: $stateParams.chosenTicketNumbers,
      completeDraft: $stateParams.completeDraft,
      patient: null,
      ticketNumber: null
    };

    // Initialize processed date range and model.

    vm.processedDateRangeOptions = App.createDateRangePickerOptions();

    vm.processedDateRangeModel = {
      startDate: null,
      endDate: null,
    };

    // Overwrite default ranges set for this year, month and week.

    vm.processedDateRangeOptions.ranges[App.localize('ThisYear')] = [
      moment().startOf('year'),
      moment().endOf('year'),
    ];
    vm.processedDateRangeOptions.ranges[App.localize('ThisQuarter')] = [
      moment().startOf('quarter'),
      moment().endOf('quarter'),
    ];
    vm.processedDateRangeOptions.ranges[App.localize('ThisMonth')] = [
      moment().startOf('month'),
      moment().endOf('month'),
    ];
    vm.processedDateRangeOptions.ranges[App.localize('ThisWeek')] = [
      moment().startOf('week'),
      moment().endOf('week'),
    ];

    // Overwrite default max date to infinite.

    vm.processedDateRangeOptions.max = moment([9999, 0, 1]);
    vm.processedDateRangeOptions.maxDate = moment([9999, 0, 1]);

    vm.gridOptions = {
      appScopeProvider: vm,
      enableSelectAll: true,
      multiSelect: true,
      isRowSelectable: () => true,
      columnDefs: [
        {
          displayName: App.localize('TicketNumber'),
          field: 'ticketNumber',
          cellTemplate: 'ticketNumberTemplate',
          enableSorting: false,
          width: 200,
        },
        {
          displayName: App.localize('TicketDate'),
          field: 'ticketCreationDate',
          cellFilter: "momentFormat: 'L LT'",
          enableSorting: false,
          width: 200,
        },
        {
          displayName: App.localize('GuaranteeLetterNumber'),
          field: 'guaranteeLetterNumber',
          enableSorting: false,
          width: 200,
        },
        {
          displayName: App.localize('Patient'),
          field: 'patientName',
          enableSorting: false,
          width: 200,
        },
        {
          displayName: App.localize('PanelLocation'),
          field: 'panelLocationName',
          enableSorting: false,
          width: 200,
        },
        {
          displayName: App.localize('ServiceType'),
          field: 'serviceType',
          cellTemplate: 'serviceTypeTemplate',
          enableSorting: false,
          width: 200,
        },
        {
          displayName: App.localize('PolicyNumber'),
          field: 'policyNumber',
          enableSorting: false,
          width: 200,
        },
        {
          displayName: App.localize('AmountToInvoice'),
          field: 'amountToInvoice',
          cellFilter: 'currencyFormat: grid.appScope.currencyCode',
          enableSorting: false,
          width: 200,
        },
        {
          displayName: App.localize('ClaimSubCategory'),
          field: 'insurerClaimSubCategory',
          cellTemplate: 'insurerClaimSubCategoryTemplate',
          enableSorting: false,
          minWidth: 200,
        },
      ],
      onRegisterApi: (gridApi) => {
        $scope.gridApi = gridApi;
        gridApi.selection.on.rowSelectionChanged($scope, () => {
          vm.selectedRows = gridApi.selection.getSelectedRows();
        });
        gridApi.selection.on.rowSelectionChangedBatch($scope, () => {
          vm.selectedRows = gridApi.selection.getSelectedRows();
        });
      },
      data: [],
    };

    vm.save = save;
    vm.saveDraft = saveDraft;
    vm.prevTab = prevTab;
    vm.nextTab = nextTab;
    vm.setActiveTab = setActiveTab;
    vm.getPatientTicketsForInsuranceClaimsBatchAction =
      getPatientTicketsForInsuranceClaimsBatchAction;
    vm.removePatientTickets = removePatientTickets;
    vm.downloadSubmissionListing = downloadSubmissionListing;
    vm.cancel = cancel;
    vm.getJoinedAttachments = getJoinedAttachments;
    vm.getAttachmentName = getAttachmentName;
    vm.getAttachmentUrl = getAttachmentUrl;
    vm.getMasterPolicyNumbers = getMasterPolicyNumbers;

    init();

    function init() {
      getListingFilterOptions();
      if (vm.requestParams.completeDraft) {
        getBatchDraftTickets(vm.requestParams.insurerBatchId);
      }
    }

    // Function for previous tab button.

    function prevTab() {
      setActiveTab(vm.activeTab - 1);
    }

    // Function for next tab button.

    function nextTab() {
      // Validate form inputs.

      const form = $scope.batchActionForm;
      if (!App.isFormValid(form)) {
        return;
      }

      setActiveTab(vm.activeTab + 1);
    }

    // Function to check conditions and set the active tab.

    function setActiveTab(tab) {
      if (tab < 0 || tab > 3) return;

      // Check form validation.

      if (tab === 0) {
        const form = $scope.batchActionForm;
        if (!App.isFormValid(form)) {
          return;
        }
      }

      // Handle conditions for switching to batching details(tab 1)
      // If switch from confirm claims details (tab 0), get patient ticket details for batching.

      if (tab === 1) {
        // Check patient ticket listing selection is not empty.

        if (vm.gridOptions.data.length < 1) {
          abp.message.warn(App.localize('MustHaveSelectedPatientTicketsWarningMessage'));
          return;
        }
        if (vm.activeTab === 0 && !vm.batchedDraftTickets.length) {
          getSelectedPatientTicketDetailsForBatching(vm.gridOptions.data);
        } else if (vm.activeTab === 0 && vm.batchedDraftTickets.length) {
          vm.selectedDraftedPatientTicketNumbers = [];
          _.forEach(vm.batchedDraftTickets, (ticket) => {
            _.forEach(vm.gridOptions.data, (selectedTicket) => {
              if (ticket.ticketNumber !== selectedTicket.ticketNumber) {
                vm.selectedDraftedPatientTicketNumbers.push(selectedTicket.ticketNumber);
              }
            });
            vm.selectedDraftedPatientTicketNumbers.push(ticket.ticketNumber);
          });
          getBatchDraftDetails(vm.selectedDraftedPatientTicketNumbers);
        }
      }

      vm.activeTab = tab;
    }

    function getSelectedPatientTicketDetailsForBatching(patientTickets) {
      // Select all ticket number.

      vm.selectedPatientTicketNumbers = [];
      _.forEach(patientTickets, (item) => {
        if (item.ticketNumber != null) {
          vm.selectedPatientTicketNumbers.push(item.ticketNumber);
        }
      });

      // Get the batch details for view for first time creation.

      vm.loading += 1;
      insurerClaimSvc
        .getBatchDetailsForInsuranceClaimsBatchCreation({
          patientTicketNumbers: vm.selectedPatientTicketNumbers,
        })
        .success((data) => {
          vm.batchDetails = data;
          if (vm.hasInsuranceClaimsBatchingEnhancement && !vm.requestParams.completeDraft) {
            vm.batchDetails.batchNumber = vm.batchNumber;
            vm.batchDetails.batchFolderName = vm.batchFolderName;
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // Get listing filter options.

    function getListingFilterOptions() {
      vm.loading += 1;
      commonLookupSvc
        .getInsurerTeamsWithInsurer()
        .success((data) => {
          vm.insurerTeams = data;
          if (vm.hasInsuranceClaimsBatchingEnhancement && !vm.requestParams.completeDraft) {
            _.forEach(vm.insurerTeams, (team) => {
              vm.insurerNameDict[team.id] = team.insurerName;
            });
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });

      // Get company/corporate/organization.

      vm.loading += 1;
      commonLookupSvc
        .getCorporates({})
        .success((data) => {
          vm.organizations = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // Get request inputs from UI.

    function getRequestInput() {
      const input = $.extend({}, vm.requestParams);
      input.processedStartDate = vm.processedDateRangeModel.startDate;
      input.processedEndDate = vm.processedDateRangeModel.endDate;
      return input;
    }

    function getPatientTicketsForInsuranceClaimsBatchAction() {
      if (vm.loading) return;

      // Validate form inputs.

      const form = $scope.batchActionForm;
      if (!App.isFormValid(form)) {
        return;
      }

      vm.selectedRows = [];

      const input = getRequestInput();
      vm.loading += 1;
      insurerClaimSvc
        .getPatientTicketsForInsuranceClaimsBatchAction(input)
        .success((data) => {
          vm.gridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function removePatientTickets() {
      abp.message.confirm(
        App.localize('RemoveSelectedPatientTicketWarningMessage', vm.selectedRows.length),
        App.localize('AreYouSure'),
        (option) => {
          if (option) {
            vm.gridOptions.data = vm.gridOptions.data.filter(
              (item) => !(vm.selectedRows.some(x => x.ticketNumber === item.ticketNumber))
            );

            if (vm.batchedDraftTickets.length) {
              vm.batchedDraftTickets = vm.batchedDraftTickets.filter(
                (item) => !(vm.selectedRows.some(x => x.ticketNumber === item.ticketNumber))
              );
            }
          }
        }
      );
    }

    // A function to download the submission listing excel of the batches.

    function downloadSubmissionListing() {
      if (vm.saving || vm.loading) return;

      formatUnlimitedBalanceToReadableInteger();

      vm.exporting += 1;
      insurerClaimSvc
        .getSubmissionListingToExcel({
          insurerTeamId: vm.requestParams.insurerTeamId,
          insuranceClaimsBatchDetails: vm.batchDetails,
        })
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.exporting -= 1;
        });
    }

    function showBatchingCompletedModal(downloadUrl) {
      $uibModal.open({
        templateUrl: require('./batchingSuccessModal/batchingSuccessModal.html'),
        controller:
          'host.views.operationsDashboard.insuranceClaims.batchAction.batchingSuccessModal as vm',
        backdrop: 'static',
        size: 'md',
        resolve: {
          downloadUrl,
        },
      });
    }

    // Get list of attachments that will be tied to the patient ticket batch detail.

    function getJoinedAttachments(patientTicketBatchDetail) {
      // Get the non-deleted existing attachments only.

      const nonDeletedExistingAttachments = patientTicketBatchDetail.attachments.filter(
        (item) => item.isDelete !== true
      );

      // Return non-deleted existing attachments with the new attachments.
      // The list contains different formatted dtos.

      return nonDeletedExistingAttachments.concat(patientTicketBatchDetail.newAttachments);
    }

    // Get attachment url.

    function getAttachmentUrl(attachment) {
      if (attachment.fullUrl) {
        // Existing attachment full url.

        return attachment.fullUrl;
      }
      // New attachment resource url.

      return attachment.resourceUrl;
    }

    // Get attachment name.

    function getAttachmentName(attachment) {
      if (attachment.fullUrl) {
        // Existing attachment name.

        return attachment.fileName;
      }
      // New attachment original name.

      return attachment.originalFileName;
    }

    // Save full insurance claim batch.

    function save() {
      // Validate form inputs.

      const form = $scope.batchActionForm;
      if (!App.isFormValid(form)) {
        return;
      }

      if (vm.saving || vm.loading) return;

      vm.saving += 1;
      vm.batchDetails.submitBatch = true;

      if (vm.requestParams.insurerBatchId) {
        vm.batchDetails.batchId = vm.requestParams.insurerBatchId;
      }

      formatUnlimitedBalanceToReadableInteger();

      insurerClaimSvc
        .createInsuranceClaimsBatch(vm.batchDetails)
        .success((data) => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          App.downloadTempFile(data.claimBatchDownloadToken);

          // Show batching success modal.

          showBatchingCompletedModal(data.claimBatchDownloadToken);
          vm.hasBatchSubmitted = data.hasBatchSubmitted;
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function saveDraft() {
      // Validate form inputs.

      const form = $scope.batchActionForm;
      if (!App.isFormValid(form)) {
        return;
      }

      if (vm.saving || vm.loading) return;

      // Check if batch number has been filled.

      if (vm.batchDetails.batchNumber == null || vm.batchDetails.batchNumber.length < 1) {
        abp.message.warn(App.localize('BatchNumberMustBeFilledWarningMessage'));
        return;
      }

      // Save batch draft logic.

      vm.saving += 1;
      vm.batchDetails.submitBatch = false;

      if (vm.requestParams.insurerBatchId) {
        vm.batchDetails.batchId = vm.requestParams.insurerBatchId;

        // Passes the current count of tickets present in the current batch draft.
        // If the count is less than the original batch draft,
        // it will remove the required tickets from the draft.

        if (vm.selectedDraftedPatientTicketNumbers.length)
          vm.batchDetails.currentDraftTicketCount = vm.selectedDraftedPatientTicketNumbers.length;
      }

      formatUnlimitedBalanceToReadableInteger();

      insurerClaimSvc
        .createInsuranceClaimsBatch(vm.batchDetails)
        .success((data) => {
          abp.notify.info(App.localize('SuccessfullySaved'));

          $stateParams.completeDraft = true;
          $stateParams.insurerBatchId = data.insurerBatchId;
          $state.reload();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function getBatchDraftTickets(batchId) {
      vm.loading += 1;
      insurerClaimSvc
        .getPatientTicketsToResumeInsuranceClaimsBatchDraftProcessing({
          insurerBatchId: batchId,
        })
        .success((data) => {
          vm.gridOptions.data = data.items;
          vm.batchedDraftTickets = data.items;
          vm.requestParams.insurerTeamId = data.items[0].insurerTeamId;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getBatchDraftDetails(ticketNumbers) {
      vm.loading += 1;
      insurerClaimSvc
        .getBatchDetailsForInsuranceClaimsBatchCreation({
          patientTicketNumbers: ticketNumbers,
          insurerBatchId: vm.requestParams.insurerBatchId,
          completeDraft: vm.requestParams.completeDraft,
        })
        .success((data) => {
          vm.batchDetails = data;
        })
        .finally(() => {
          vm.activeTab = 1;
          vm.loading -= 1;
        });
    }

    function getMasterPolicyNumbers(insurerTeamId, organizationId) {
      vm.loading += 1;
      vm.requestParams.policyNumber = null;
      commonLookupSvc
        .getMasterPolicyNumbersByInsurerTeamOrOrganization({
          insurerTeamId,
          organizationId,
        })
        .success((data) => {
          vm.policyNumbers = data;
        })
        .finally(() => {
          vm.loading -= 1;
          if (vm.hasInsuranceClaimsBatchingEnhancement && !vm.requestParams.completeDraft) {
            generateBatchNumberAndBatchFolderName();
          }
        });
    }

    function generateBatchNumberAndBatchFolderName() {
      const d = new Date();
      const year = d.getFullYear().toString();
      let month = (d.getMonth() + 1).toString();
      let day = d.getDate().toString();
      vm.insurerName = vm.insurerNameDict[vm.requestParams.insurerTeamId];
      vm.insurerName = vm.insurerName.replace(/ /g, '_');

      if (month.length === 1) month = '0'.concat(month);

      if (day.length === 1) day = '0'.concat(month);

      vm.batchNumber = vm.insurerName.concat('_', year, month, day);
      vm.batchFolderName = 'HMS_ClaimSubmission'.concat('_', vm.insurerName, '_', year, month, day);
    }

    function cancel() {
      $state.go('host.operationsDashboard', {
        targetWidget: 'insuranceClaims',
      });

    }

    function formatUnlimitedBalanceToReadableInteger() {
      // If balance is unlimited and in exponential format,
      // force format it to max safe integer before passing into API to prevent validation error.

      _.forEach(vm.batchDetails.patientBatchDetails, (patientBatch) => {
        if (patientBatch.availableHospitalizationBalanceIsUnlimited) {
          patientBatch.availableHospitalizationBalance = Number.MAX_SAFE_INTEGER;
        }
      });
    }
  }
})();
