(() => {
  angular
    .module('app')
    .controller('common.views.languages.createOrEditModal', LanguageEditController);

  LanguageEditController.$inject = ['$uibModalInstance', 'abp.services.app.language', 'languageId'];

  function LanguageEditController($uibModalInstance, languageSvc, languageId) {
    const vm = this;

    vm.saving = 0;
    vm.language = null;
    vm.languages = [];
    vm.flags = [];
    vm.loading = 0;

    vm.languageChanged = languageChanged;
    vm.cancel = cancel;
    vm.save = save;

    init();

    function init() {
      vm.loading += 1;
      languageSvc
        .getLanguageForEdit({
          id: languageId,
        })
        .success((data) => {
          vm.language = data.language;
          vm.languages = data.languages;
          vm.flags = data.flags;
          vm.loading -= 1;
        });
    }

    function languageChanged() {
      if (vm.language) {
        let countryCode = vm.language.name.toUpperCase();
        const idx = countryCode.indexOf('-');
        if (idx >= 0) {
          countryCode = countryCode.substr(idx + 1);
        }
        for (let i = 0; i < vm.flags.length; i += 1) {
          if (countryCode === vm.flags[i].displayText.toUpperCase()) {
            vm.language.icon = vm.flags[i].value;
          }
        }
      }
    }

    function save() {
      vm.saving += 1;
      languageSvc
        .createOrUpdateLanguage({
          language: vm.language,
        })
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          $uibModalInstance.close();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
