(function () {
    var controllerId = 'host.views.debugTools.dataExportTester.index';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$window', '$state', '$stateParams', '$uibModal', 'abp.services.app.debugTool',
        function ($scope, $window, $state, $stateParams, $modal, debugToolSvc) {
            var vm = this;
            vm.dataExportRecordName = "DataExportTesterDocument";

            vm.saving = 0;

            vm.generateButtonType = App.localize('Generate');

            vm.generate = function () {
                vm.saving++;

                debugToolSvc.testDataExportJob({
                    raiseException: vm.raiseException,
                    message: vm.message
                }).success(function () {
                    abp.notify.success('Successfully sent!');
                    vm.refresh();
                }).finally(function () {
                    vm.saving--;
                });
            }

            vm.message = "This is a test Excel sheet generated from HMS debug tool";
            vm.raiseException = 0;
        }
    ]);
})();
