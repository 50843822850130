(function () {
    var app = angular.module('app');
    app.component('hostDoraemonAddOnPreviousRequestDetailsComponent', {
        templateUrl: require('/App/host/views/doraemon/addOn/previousRequestDetails/previousRequestDetails.component.html'),
        controller: ['abp.services.app.doraemon', 'Hms.ServiceRequests.ServiceRequestType', 'moment', controller],
        controllerAs: 'vm',
        bindings: {
            letterNumber: '<',
            serviceRequestId: '<',
        }
    });

    function controller(doraemonSvc, enumServiceRequestType, moment) {
        var vm = this;

        vm.$onChanges = onChanges;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.isEdit = false;
        vm.loading = 0;
        vm.savedTrigger = 0;
        vm.diagnosis = null;
        vm.viewMore = false;

        vm.enums = {};
        vm.enums.serviceRequestType = enumServiceRequestType;

        function onChanges(changes) {
            if (changes.serviceRequestId && changes.serviceRequestId.currentValue) {
                vm.loading++;
                doraemonSvc.getServiceRequestDetails(
                    {
                        letterNumber: vm.letterNumber,
                        id: vm.serviceRequestId,
                    }
                ).success(function (data) {
                    vm.request = {
                        id: data.serviceRequestId,
                        issueNumber: data.issueNumber,
                        hospitalName: data.hospitalName,
                        hospitalContactNumber: data.hospitalContactNumber,
                        admissionNumber: data.admissionNumber,
                        requestType: data.requestType,
                        requestTime: data.requestTime,
                        requestedBy: data.requestedBy,
                        letterNumber: data.letterNumber,
                        scheduledAdmissionTime: data.scheduledAdmissionTime,
                        admissionType: data.admissionType,
                        selectedDoctorIds: data.selectedDoctorIds,
                        interimBillAmount: data.interimBillAmount,
                        newGuaranteeAmount: data.newGuaranteeAmount,
                        glRemarks: data.glRemarks,
                    };
                    vm.selectedDoctorsNames = data.selectedDoctorsNames;
                    vm.admissionTypeName = data.admissionTypeName;
                    vm.availableDoctors = data.availableDoctors;

                    vm.documents = _.map(data.supportingDocuments, function (d) {
                        return {
                            externalObjectId: d.externalObjectId,
                            url: d.url,
                            name: d.name,
                            uploader: d.uploader + ' ' + moment(d.uploadedTime).format('L, LT'),
                            applied: d.isApplied
                        };
                    });

                    vm.diagnosis = {
                        selected: _.map(data.provisionalDiagnosis, function (p) {
                            return {
                                code: p.code,
                                description: p.description,
                                date: p.diagnosisDate
                            };
                        }),
                        icd: _.map(data.diseaseClassifications, function (d) {
                            return {
                                id: d.id,
                                code: d.code,
                                description: '(' + d.code + ') ' + d.description
                            };
                        })
                    };
                }).finally(function () {
                    vm.loading--;
                });;
            }
        }
    }
})();
