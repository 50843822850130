(() => {
  angular
    .module('app')
    .controller(
      'host.views.debugTools.createZendeskOrganizationsByCountry.modal',
      CreateZendeskOrganizationsByCountryModalController
    );

  CreateZendeskOrganizationsByCountryModalController.$inject = [
    '$uibModalInstance',
    'abp.services.app.commonLookup',
    'abp.services.app.debugTool',
  ];

  function CreateZendeskOrganizationsByCountryModalController(
    $uibModalInstance,
    commonLookupSvc,
    debugToolSvc
  ) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;

    vm.cancel = cancel;
    vm.confirm = confirm;

    init();

    function init() {
      vm.loading += 1;
      commonLookupSvc
        .getEligibleCountries()
        .success((data) => {
          vm.countries = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function confirm() {
      if (!vm.countryCode || vm.saving) return;

      vm.saving += 1;
      debugToolSvc
        .createZendeskOrganizationsByCountry(vm.countryCode)
        .success(() => {
          $uibModalInstance.dismiss();
          abp.notify.info('Creation of Zendesk organisations is successfully queued.');
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }
  }
})();
