(() => {
  angular
    .module('app')
    .component('commonInpatientNprClaimsDetailsClaimSummaryAdmissionRecordComponent', {
      templateUrl: require('./admissionRecord.component.html'),
      controller: AdmissionRecordController,
      controllerAs: 'vm',
      bindings: {
        claim: '<',
      },
    });

  AdmissionRecordController.$inject = [];

  function AdmissionRecordController() {
    // const vm = this;
  }
})();
