(function () {
    var componentId = 'hostInpatientGuaranteeLettersDetailsQueryComponent';
    var app = angular
        .module('app')
        .component(componentId, {
            templateUrl: require('/App/host/views/inpatientGuaranteeLetters/details/query/query.component.html'),
            controller: ['$scope', '$rootScope', '$timeout', 'abp.services.app.serviceRequestQuery', controller],
            controllerAs: 'vm',
            bindings: {
                letterNumber: '<',
                newRepliedQueriesCount: '=',
                claimNumber: '<',
                timeZoneId: "<"
            }
        });

    function controller($scope, $rootScope, $timeout, serviceRequestQuerySvc) {
        var vm = this;

        vm.loading = 0;
        vm.saving = 0;

        // TODO: Update permission

        vm.permissions = {
            update: abp.auth.isGranted('GuaranteeLetters.Host.Update'),
            topUp: abp.auth.isGranted('GuaranteeLetters.Host.TopUp'),
            extend: abp.auth.isGranted('GuaranteeLetters.Host.Approve'),
            advance: abp.auth.isGranted('GuaranteeLetters.Corporate.Advance')
        };

        var viewOptions = {
            maxResultCount: app.consts.grid.defaultPageSize,
            skipCount: 0,
            sorting: null
        };

        vm.getDateTimeString = getDateTimeString;

        function init() {
            $timeout(function () {
                vm.queryComponentGridOptions.paginationCurrentPage = Math.floor(viewOptions.skipCount / viewOptions.maxResultCount + 1);
            });
        }

        vm.queryComponentGridOptions = {
            appScopeProvider: vm,
            paginationPageSizes: app.consts.grid.defaultPageSizes,
            paginationPageSize: app.consts.grid.defaultPageSize,
            paginationCurrentPage: 0,
            useExternalPagination: true,
            useExternalSorting: true,
            resizable: true,
            columnDefs: [{
                name: 'Actions',
                displayName: 'Actions',
                enableSorting: false,
                width: 75,
                headerCellTemplate: '<span></span>',
                cellTemplate: 'queryComponentViewTemplate'
            }, {
                displayName: App.localize('QueryNumber'),
                field: 'queryNumber',
                minWidth: 100,
                width: 150
            }, {
                displayName: App.localize('CreationTime'),
                enableSorting: false,
                field: 'creationTime',
                minWidth: 120,
                cellTemplate: 'creationTimeTemplate'
            }, {
                displayName: App.localize('RepliedBy'),
                enableSorting: false,
                field: 'repliedUserName',
                minWidth: 120
            }, {
                displayName: App.localize('ReplyTime'),
                enableSorting: false,
                field: 'repliedTime',
                minWidth: 120,
                cellTemplate: 'repliedTimeTemplate'
            }, {
                displayName: App.localize('ResolvedBy'),
                enableSorting: false,
                field: 'resolvedUserName',
                minWidth: 120,
            }, {
                displayName: App.localize('ResolvedAt'),
                enableSorting: false,
                field: 'resolvedTime',
                minWidth: 120,
                cellTemplate: 'resolvedTimeTemplate'
            }, {
                displayName: App.localize('CreatorUserName'),
                enableSorting: false,
                field: 'creatorUserName',
                minWidth: 120,
            }],
            onRegisterApi: function (gridApi) {
                $scope.gridApi = gridApi;

                $scope.gridApi.core.on.sortChanged($scope, function (scope, callback) {
                    viewOptions.sorting = callback.length && callback[0].field ? callback[0].field + ' ' + callback[0].sort.direction : null;
                    getQueries();
                });
                gridApi.pagination.on.paginationChanged($scope, App.setPagination(viewOptions, getQueries));
            },
            data: []
        };

        function getQueries() {
            vm.loading++;
            serviceRequestQuerySvc.getQueries({
                guaranteeLetterNumber: vm.letterNumber,
                skipCount: viewOptions.skipCount,
                maxResultCount: viewOptions.maxResultCount
            }).success(function (data) {
                vm.queryComponentGridOptions.totalItems = data.queries.totalCount;
                vm.queryComponentGridOptions.data = data.queries.items;
                vm.canCreateQuery = data.canCreateQuery;
                vm.newRepliedQueriesCount = data.newRepliedQueriesCount;
            }).finally(function () {
                vm.loading--;
            });
        }

        function getDateTimeString(date, timeZoneId) {
            return App.getDateTimeString(date, timeZoneId);
        }

        init();
    }
})();
