(function () {
    'use strict';

    angular.module('app').component('corporateBenefitGroupsCreateEditBasicsComponent', {
        templateUrl: require('/App/corporate/views/benefitGroups/createEdit/basics/basics.component.html'),
        controller: ['Hms.BenefitGroups.BenefitGroup', controller],
        controllerAs: 'vm',
        bindings: {
            currencyCode: '<',
            hasSupportPlans: '<',
            isPreEmployment: '<',
            hasDependentsOnlyModifier: '<',
            benefitGroup: '<',
            isReadOnly: '<',
            packages: '<',
            supportPlans: '<',
            countryCode: '<'
        }
    });

    function controller(constsBenefitGroup) {
        var vm = this;
		    vm.constsBenefitGroup = constsBenefitGroup;
        vm.getPackageTotalAmount = getPackageTotalAmount;

        function getPackageTotalAmount() {
            if (!vm.benefitGroup.preEmploymentPackage) return 0;

            var amount = vm.benefitGroup.preEmploymentPackage.amount;
            _.each(vm.benefitGroup.preEmploymentPackage.treatments, function (t) {
                if (t.isCovered) {
                    amount += t.amount;
                }
            });
            return amount;
        }
    }
})();
