(function () {
    var controllerId = 'host.views.debugTools.clearCache.index';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$window', '$state', '$stateParams', '$uibModal', 'abp.services.app.debugTool',
        function ($scope, $window, $state, $stateParams, $modal, debugToolSvc) {
            function init() {
                getCaches();
            }

            var vm = this;
            vm.loading = 0;
            vm.saving = 0;
            vm.savingCache = {};

            $scope.$on("$viewContentLoaded", function () {
                App.initAjax();
            });

            function getCaches() {
                vm.loading++;
                debugToolSvc.getCacheList()
                    .success(function (d) {
                        vm.caches = d.cacheNames;
                        _.each(vm.caches, function (cache) {
                            vm.savingCache[cache] = 0;
                        });
                    })
                    .finally(function () {
                        vm.loading--;
                    });
            }

            /* KONAMI CODE */

            vm.crasher = false;
            var konamiCode = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65];
            var keyCodes = [];
            function konami(e) {
                var keyCode = e.keyCode || e.which;
                var callback = function () {
                    vm.crasher = !vm.crasher;
                };

                if (konamiCode.length > keyCodes.push(keyCode)) {
                    return;
                }

                if (konamiCode.length < keyCodes.length) {
                    keyCodes.shift();
                }

                if (konamiCode.toString() === keyCodes.toString()) {
                    $scope.$apply(callback);
                }
            }
            $(window).on('keydown', konami);

            $scope.$on('$destroy', function () {
                $(window).off('keydown', konami);
            });

            /* END OF KONAMI CODE */

            vm.refreshCaches = function () {
                getCaches();
            };

            vm.throwException = function () {
                debugToolSvc.throwException({ id: vm.exceptionCode });
            };

            function clearCaches(cacheNames) {
                return debugToolSvc.clearCaches({
                    cacheNames: cacheNames
                });
            }

            vm.clearAllCaches = function () {
                vm.saving++;
                clearCaches(vm.caches)
                    .success(function () {
                        abp.notify.info(App.localize("AllCachesSuccessfullyReset"));
                    })
                    .finally(function () {
                        vm.saving--;
                    });
            };

            vm.clearCache = function (cacheName) {
                vm.savingCache[cacheName]++;
                clearCaches([cacheName])
                    .success(function () {
                        abp.notify.info(App.localize("XCacheSuccessfullyReset", cacheName));
                    })
                    .finally(function () {
                        vm.savingCache[cacheName]--;
                    });
            };

            init();
        }
    ]);
})();
