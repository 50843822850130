/* global google */

(() => {
  angular
    .module('app')
    .controller('common.views.panelRequests.createPanelRequest', CreatePanelRequestController);

  CreatePanelRequestController.$inject = [
    '$scope',
    '$state',
    '$timeout',
    '$stateParams',
    'abp.services.app.panelRequest',
    'abp.services.app.commonLookup',
    'Hms.MultiTenancy.TenantClinicType',
    'Hms.PanelRequests.PanelRequestPriority',
    'Tenant',
    'Hms.PanelRequests.PanelRequest',
    'Hms.PanelRequests.PanelRequestStatus'
  ];

  function CreatePanelRequestController(
    $scope,
    $state,
    $timeout,
    $stateParams,
    panelRequestSvc,
    commonLookupSvc,
    enumTenantClinicType,
    enumPanelRequestPriority,
    constsTenant,
    constsPanelRequest,
    enumPanelRequestStatus,
  ) {
    const vm = this;

    vm.constsTenant = constsTenant;
    vm.constsPanelRequest = constsPanelRequest;
    vm.loading = 0;
    vm.saving = 0;
    vm.isHost = App.isHost();

    vm.location = {
      corporateRequesters: [],
    };

    vm.cachePanelLocations = [];
    vm.panelExists = false;
    vm.panelRequestSubmitted = false;
    vm.markers = [];
    vm.results = [];
    vm.agents = [];
    vm.hasNearbyPanel = false;
    vm.showNearby = false;
    vm.isFirstVisit = true;
    vm.showCorporateRequester = false;
    vm.isLocationFound = true;
    vm.masterCorporates = null;
    vm.filteredMasterCorporates = null;

    vm.panelsWithinRadiusMessage = App.localize('PanelsWithinXm', 2000);

    vm.enums = {
      tenantClinicType: enumTenantClinicType,
      panelRequestPriority: enumPanelRequestPriority,
      panelRequestStatus: enumPanelRequestStatus
    };

    if (vm.isHost) {
      vm.eligibleCountries = $stateParams.eligibleCountries;
    } else {
      vm.hasInpatientModule = abp.features.isEnabled('HasInpatientModule');
      vm.eligibleCountries = $stateParams.accountOwnerCountry;
      vm.isMalaysiaCorporate = vm.eligibleCountries.includes('MY');
    }

    vm.permissions = {
      create:
        abp.auth.isGranted('PanelRequests.Host.Create') ||
        abp.auth.isGranted('PanelRequests.Corporate.Create'),
      view:
        abp.auth.isGranted('PanelRequests.Host') || abp.auth.isGranted('PanelRequests.Corporate'),
      dashboard: abp.auth.isGranted('PanelRequests.Host.RecruitmentDashboard'),
      viewRequestAsHost: abp.auth.isGranted('PanelRequests.Host'),
      viewPanel: abp.auth.isGranted('Host.Clinics'),
    };

    vm.map = {
      isInit: false,
      hasLatLng: true,
      marker: {
        id: 1,
        coords: convertLatLng(getLatLngForMap()),
        options: { draggable: true },
        events: {
          dragend(marker) {
            vm.location.latLng = {
              lat: +marker.getPosition().lat().toFixed(7),
              lng: +marker.getPosition().lng().toFixed(7),
            };

            vm.latLngUpdated();
          },
        },
      },
      markersEvents: {
        mouseout(gMarker, eventName, model) {
          const m = model;
          m.show = false;
        },
        mouseover(gMarker, eventName, model) {
          const m = model;
          m.show = true;
        },
      },
      searchbox: {
        options: {
          autocomplete: true,
          componentRestrictions: {
            country: vm.eligibleCountries
              ? vm.eligibleCountries
              : abp.setting.get('Hms.General.DefaultCountryCode'),
          },
          fields: ['geometry', 'address_components', 'name'],
        },
        events: {
          place_changed(searchBox) {
            resetMap();
            const place = searchBox.getPlace();
            if (vm.location) {
              vm.location.latLng = {
                lat: +place.geometry.location.lat().toFixed(7),
                lng: +place.geometry.location.lng().toFixed(7),
              };
            } else if (place.geometry) {
              vm.location = {
                latLng: {
                  lat: +place.geometry.location.lat().toFixed(7),
                  lng: +place.geometry.location.lng().toFixed(7),
                },
              };
            } else {
              if (place.name) vm.isLocationFound = false;

              vm.location = {};
            }

            // prefill panelName and address

            vm.location.panelName = place.name;

            const street = [];

            if (_.find(place.address_components, { types: ['floor'] })) {
              street.push(_.find(place.address_components, { types: ['floor'] }).short_name);
            }
            if (_.find(place.address_components, { types: ['street_number'] })) {
              street.push(
                _.find(place.address_components, { types: ['street_number'] }).short_name
              );
            }
            if (_.find(place.address_components, { types: ['route'] })) {
              street.push(_.find(place.address_components, { types: ['route'] }).short_name);
            }
            if (_.find(place.address_components, { types: ['sublocality'] })) {
              street.push(_.find(place.address_components, { types: ['sublocality'] }).short_name);
            }

            const checkValue = (input) => {
              if (input) return input.short_name;
              return '';
            };

            vm.address = {
              street: _.join(street, ', '),
              postcode: checkValue(_.find(place.address_components, { types: ['postal_code'] })),
              countryCode: checkValue(_.find(place.address_components, { types: ['country'] })),
            };

            if (!vm.address.countryCode) {
              vm.address.countryCode = 'MY'; // Default to 'Malaysia'
            }

            vm.map.center = convertLatLng(getLatLngForMap());
            vm.latLngUpdated();
          },
        },
      },
      center: convertLatLng(getLatLngForMap()),
      options: App.createMapOptions(),
      init() {
        if (vm.map.isInit) {
          return;
        }
        vm.map.isInit = true;
        const latLng = getLatLngForMap();
        vm.map.center = convertLatLng(latLng);
        vm.map.marker.coords = convertLatLng(latLng);
        vm.map.hasLatLng = true;
        if (!vm.location || !vm.location.latLng) {
          $timeout(() => {
            vm.map.hasLatLng = false;
          }, 250);
        }
      },
    };

    vm.activeTab = 0;

    vm.save = save;
    vm.prevTab = prevTab;
    vm.nextTab = nextTab;
    vm.setActiveTab = setActiveTab;
    vm.followRecruitmentProgress = followRecruitmentProgress;
    vm.filterMasterCorporates = filterMasterCorporates;
    vm.latLngUpdated = latLngUpdated;
    vm.showNearbyPanels = showNearbyPanels;
    vm.loadRequesters = loadRequesters;
    vm.checkLocation = checkLocation;
    vm.checkAvailability = checkAvailability;
    vm.panelTypeChanged = panelTypeChanged;
    vm.getDistance = getDistance;
    vm.getContactNumber = getContactNumber;

    init();

    function init() {
      if (vm.isHost) {
        getMasterCorporates();
      }

      vm.map.init();
    }

    function showNearbyPanels() {
      vm.showNearby = true;
    }

    function getMasterCorporates() {
      vm.loading += 1;
      commonLookupSvc
        .getMasterCorporates()
        .success((data) => {
          vm.masterCorporates = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function loadRequesters($query) {
      return _.filter(
        vm.masterCorporates,
        (corporate) => corporate.name.toLowerCase().indexOf($query.toLowerCase()) !== -1
      );
    }

    function checkLocation() {
      // Reset map if the map coordinate is empty

      if (typeof vm.location.latLng === 'undefined') resetMap();
      else if (vm.location.latLng === null) vm.isLocationFound = false;
      else {
        vm.isLocationFound = true;
        vm.address = { countryCode: 'MY' }; // Default to 'Malaysia'

        vm.latLngUpdated();
      }
    }

    function checkAvailability(panels) {
      vm.panelRequestSubmitted = false;
      vm.panelExists = false;

      // Check if panel exists or panel request exists.

      const cachePanel = _.find(
        panels,
        (data) =>
          data.latLng.lat.toFixed(7) === vm.map.marker.coords.latitude.toFixed(7) &&
          data.latLng.lng.toFixed(7) === vm.map.marker.coords.longitude.toFixed(7)
      );

      // register markers

      const panelIcon = {
        url: '../../../images/pin-active.png',
        size: new google.maps.Size(47, 53),
        anchor: new google.maps.Point(19, 49),
      };
      const requestIcon = {
        url: '../../../images/pin-default.png',
        size: new google.maps.Size(47, 53),
        anchor: new google.maps.Point(19, 49),
      };

      vm.markers = _.map(panels, (d, idx) => {
        // 7 is deactivated status
        let status = null;
        if (d.isRequestLocation) status = d.request.status;
        else status = d.isActive ? null : 7;

        return {
          id: d.isRequestLocation ? `R${d.request.id}` : d.id,
          requestId: d.request.id,
          name: d.panelName,
          type: d.panelType,
          fullAddress: d.fullAddress,
          hasFemaleDoctor: d.hasFemaleDoctor,
          hasXray: d.hasXray,
          hasUltrasound: d.hasUltrasound,
          isPreEmploymentClinic: d.isPreEmploymentClinic,
          markerPrefix: d.isRequestLocation
            ? 'Recruitment in Progress: '
            : 'HealthMetrics Panels: ',
          coords: {
            latitude: d.latLng.lat,
            longitude: d.latLng.lng,
          },
          dist: d.latLng.dist,
          icon: d.isRequestLocation ? requestIcon : panelIcon,
          options: {
            labelContent: `${0 + idx + 1}`,
            labelClass: 'font-bold',
            labelStyle: { color: '#000', 'font-size': '13px' },
            labelAnchor: `${Math.floor(Math.log(0 + idx + 1) / Math.LN10) * 4 + 3} 42`,
          },
          languages: d.languages,
          isAccessible: true,
          isRequestLocation: d.isRequestLocation,
          status,
          rejectedReasonString: d.request.rejectionReason,
        };
      });

      // Assign location if found.

      if (cachePanel) {
        vm.location = cachePanel;
        vm.location.status = cachePanel.request.status;
        vm.location.corporateRequesters = [];

        // insert status
        if (cachePanel.isRequestLocation) {
          vm.panelRequestSubmitted = cachePanel.isRequestLocation;
        } else if (!cachePanel.isRequestLocation) {
          vm.panelExists = !cachePanel.isRequestLocation;
        }
      } else {
        // update vm.location as map center.
        vm.location.latLng.lat = vm.map.marker.coords.latitude.toFixed(7);
        vm.location.latLng.lng = vm.map.marker.coords.longitude.toFixed(7);
      }

      // insert other location markers.
      vm.results = vm.results.concat(panels);

      vm.hasNearbyPanel = vm.markers.length > 0;

      if (
        typeof cachePanel !== 'undefined' &&
        (cachePanel.isRequestLocation || cachePanel.isCreated)
      ) {
        const matched = _.find(
          vm.markers,
          (i) =>
            i.coords.latitude === cachePanel.latLng.lat &&
            i.coords.longitude === cachePanel.latLng.lng
        );
        matched.show = true;
      }
    }

    function getPanelLocations(latLng) {
      vm.loading += 1;

      panelRequestSvc
        .findPanelLocations({
          lat: latLng.lat,
          lng: latLng.lng,
          radius: 2,
          panelType: vm.panelType,
        })
        .success((data) => {
          vm.cachePanelLocations = data.items;
          vm.location.panelType = vm.panelType;

          if (!vm.location.isRequestLocation) {
            vm.checkAvailability(data.items);
          }

          // default priority to medium
          vm.location.priority = 2;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    /* Google Map */

    function getLatLngForMap() {
      if (vm.location && vm.location.latLng) return vm.location.latLng;
      return {
        lat: 3.031647,
        lng: 101.617452,
      };
    }

    function convertLatLng(latLng) {
      return {
        latitude: +latLng.lat.toFixed(7),
        longitude: +latLng.lng.toFixed(7),
      };
    }

    function latLngUpdated() {
      if (!vm.map.isInit) return;
      const latLng = getLatLngForMap();
      vm.map.center = convertLatLng(latLng);
      vm.map.marker.coords = convertLatLng(latLng);
      vm.map.hasLatLng = vm.location.latLng;

      if (vm.map.hasLatLng) getPanelLocations(latLng);
    }

    function panelTypeChanged() {
      if (!vm.map.isInit) return;

      if (vm.map.hasLatLng) getPanelLocations(getLatLngForMap());
    }

    function resetMap() {
      vm.location = null;
      vm.map.hasLatLng = false;
      vm.markers = [];
      vm.results = [];
      vm.hasNearbyPanel = false;
      vm.showNearby = false;
      vm.panelExists = false;
      vm.panelRequestSubmitted = false;
      vm.isLocationFound = true;
    }

    function getDistance(r) {
      if (r.dist < 1) {
        return `${(r.dist * 1000).toFixed(0)}m`;
      }
      return `${r.dist.toFixed(1)}km`;
    }

    function getContactNumber(r) {
      return r.contactNumber.replace(/[ -]/g, '');
    }

    /* End of Google Map */

    /* Navigation */

    function prevTab() {
      vm.setActiveTab(vm.activeTab - 1);
    }

    function nextTab() {
      if (getFirstInvalidTab(vm.activeTab) > -1) {
        abp.notify.error(App.localize('GeneralInvalidFormInputError'));
      } else {
        vm.setActiveTab(vm.activeTab + 1);
      }
    }

    function setActiveTab(tab) {
      if (tab < 0 || tab > 3) return; // Prevent tab out of range.

      // Validate all forms and force user back to first invalid tab.

      const invalidTab = getFirstInvalidTab(tab);

      vm.activeTab = invalidTab < 0 ? tab : invalidTab;
    }

    function getFirstInvalidTab(tab) {
      const tabForms = [$scope.forms.map, $scope.forms.details, $scope.forms.properties, null];
      let invalidTab = -1; // -1 for no invalid tabs.
      const maxTab = tab >= 0 ? tab : tabForms.length - 1;
      for (let i = 0; i <= maxTab; i += 1) {
        const tabForm = tabForms[i];
        if ((tabForm && !App.isFormValid(tabForm)) || (i === 2 && vm.invalidPostcode)) {
          invalidTab = i;
          break;
        }
      }
      return invalidTab;
    }

    /* End of Navigation */

    function save() {
      if (vm.saving) return;

      // Validate forms and force user back to first invalid tab if any.

      const invalidTab = getFirstInvalidTab();
      if (invalidTab >= 0) {
        vm.setActiveTab(invalidTab);
        return;
      }

      vm.saving += 1;
      abp.message.confirm(
        App.localize('ConfirmCreatePanelRequest'),
        App.localize('AreYouSure'),
        (d) => {
          vm.saving -= 1;

          if (d) {
            vm.saving += 1;
            if (vm.location.email === '') vm.location.email = null;
            panelRequestSvc
              .createPanelRequest($.extend({}, vm.address, vm.location))
              .success(() => {
                abp.notify.info(App.localize('SuccessfullySaved'));
                if (vm.isHost) {
                  $state.go('host.panelRecruitment');
                } else {
                  $state.go('corporate.panelRequests');
                }
              })
              .finally(() => {
                vm.saving -= 1;
              });
          }
        }
      );
    }

    function followRecruitmentProgress(requestId) {
      vm.saving += 1;
      panelRequestSvc
        .tagCorporateToPanelRequest({
          id: requestId,
        })
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          $state.go('corporate.panelRequests');
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function filterMasterCorporates() {
      vm.filteredMasterCorporates = _.filter(
        vm.masterCorporates,
        (m) =>
          m.accountOwnerCountryCode === vm.address.countryCode || m.accountOwnerCountryCode === null
      );
    }
  }
})();
