(() => {
  angular.module('app').factory('videoModal', VideoModalFactory);

  VideoModalFactory.$inject = ['$uibModal'];

  function VideoModalFactory($uibModal) {
    const service = {
      open,
    };

    return service;

    function open(options) {
      $uibModal.open({
        templateUrl: require('../views/common/videoModal.html'),
        controller: 'common.views.common.videoModal as vm',
        backdrop: 'static',
        resolve: {
          videoOptions () {
            return options;
          },
        },
      });
    }
  }
})();
