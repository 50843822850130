(() => {
  angular.module('app').component('commonInpatientTicketsDetailAccommodationChargesComponent', {
    templateUrl: require('./accommodationCharges.component.html'),
    controller: AccomodationChargesController,
    controllerAs: 'vm',
    bindings: {
      ticket: '<',
      isEdit: '<',
      isSaved: '<',
      categories: '<',
      benefits: '<',
      admissionDate: '=',
      dischargeDate: '=',
      isCreate: '<',
      currencyCode: '<',
      isClaim: '<'
    },
  });

  AccomodationChargesController.$inject = [
    '$scope',
    'abp.services.app.commonLookup',
    'moment',
    'Hms.ServiceRequests.ServiceRequestType',
  ];

  function AccomodationChargesController(
    $scope,
    commonLookupSvc,
    moment,
    enumServiceRequestType,
  ) {
    const vm = this;

    vm.hasTxtFileSupport = abp.setting.getBoolean('Hms.Feature.TxtFileSupport');
    vm.hasInsuranceEnhancementModule = abp.setting.getBoolean(
      'Hms.Feature.InsuranceEnhancementModule'
    );
    vm.subtotalAmount = 0;

    vm.tempPlaceHolder = null;
    vm.totalDays = 0;
    vm.isAdjustment = false;
    vm.remarkOptions = [
      { id: 1, displayName: App.localize('Consultation') },
      { id: 2, displayName: App.localize('Visitation') },
      { id: 3, displayName: App.localize('ConsultationAndVisitation') },
    ];
    vm.formattedCategories = [];

    const defaultTicketItem = {
      category: '',
      isManualEdit: 0,
      ticketItems: [
        {
          subTreatments: [],
          procedures: [],
        },
      ],
    };
    vm.isInpatientTicketV2 = vm.hasInsuranceEnhancementModule && vm.ticket.version === 'V2';

    vm.enums = {
      serviceRequestType: enumServiceRequestType
    };

    vm.$onChanges = onChanges;
    vm.addCategory = addCategory;
    vm.addRooms = addRooms;
    vm.clearRooms = clearRooms;
    vm.deleteItem = deleteItem;
    vm.updateRooms = updateRooms;
    vm.calculateTotal = calculateTotal;
    vm.calculateLengthOfStay = calculateLengthOfStay;
    vm.calculateAmount = calculateAmount;
    vm.getAmount = getAmount;
    vm.addLengthOfStay = addLengthOfStay;
    vm.deductLengthOfStay = deductLengthOfStay;
    vm.manualEdit = manualEdit;
    vm.cancelManualEdit = cancelManualEdit;
    vm.saveManualEdit = saveManualEdit;
    vm.lookupRemark = lookupRemark;
    vm.updateCategory = updateCategory;
    vm.updateSubCategory = updateSubCategory;
    vm.fulfillV2ViewCondition = fulfillV2ViewCondition;
    vm.getSubtotalAmount = getSubtotalAmount;
    vm.getTotalCoveredAmount = getTotalCoveredAmount;
    vm.getTotalUncoveredAmount = getTotalUncoveredAmount;

    init();

    function init() {
      $scope.$watch('vm.dischargeDate', () => {
        setDateRangeOptions();
      });

      setDateRangeOptions();

      // To retrieve master billing code.

      if (vm.hasTxtFileSupport) getMasterBillingCode();

      vm.ticket.rooms = _.chain(vm.ticket.rooms)
        .groupBy('category')
        .map((value, key) => ({
          category: key,
          ticketItems: value,
          name: _.first(value).categoryName,
          isManualEdit: 0,
        }))
        .value();

      if (vm.ticket.rooms.length === 0) {
        if (vm.isEdit) addCategory();
      } else {
        _.each(vm.ticket.rooms, (x) => {
          _.each(x.ticketItems, (item) => {
            const p = item;
            p.totalCharges = p.amount + p.uncoveredAmount;
            p.minLengthOfStay = 0;
            p.maxLengthOfStay = 0;
            p.isOverride = 0;
          });
        });
      }

      if (vm.ticket.requestType === 'Adjustment') {
        _.each(vm.ticket.rooms, (x) => {
          _.each(x.ticketItems, (p) => {
            vm.totalDays += p.lengthOfStay;
          });
        });

        vm.isAdjustment = true;
      }

      updateDates();

      if (vm.hasInsuranceEnhancementModule) formatCategories();

      if (vm.isInpatientTicketV2) getTicketItems();
    }

    function setDateRangeOptions() {
      vm.roomChargeDateRangeOptions = App.createCustomDateRangePickerOptions(
        vm.admissionDate,
        vm.dischargeDate
      );
      vm.roomChargeDateRangeOptions.eventHandlers = {
        'apply.daterangepicker': () => {
          vm.calculateLengthOfStay();
        },
      };
    }

    function onChanges(changes) {
      if (changes.ticket && changes.ticket.currentValue) {
        if (vm.ticket.rooms.length === 0) {
          if (vm.isEdit) addCategory();
        } else {
          _.each(vm.ticket.rooms, (x) => {
            _.each(x.ticketItems, (item) => {
              const p = item;
              p.totalCharges = p.amount + p.uncoveredAmount;
              p.subTreatment = p.subCategory;
            });
          });
        }
      }

      if (changes.isSaved && changes.isSaved.currentValue) {
        tidyRooms();
      }

      if (changes.isEdit && changes.isEdit.currentValue) {
        if (vm.ticket && vm.ticket.rooms.length < 1) {
          addCategory('RoomAndBoard');
        }
      }

      updateDates();
    }

    function addCategory(type) {
      const categoryItem = vm.hasInsuranceEnhancementModule
        ? JSON.parse(JSON.stringify(defaultTicketItem))
        : {
            category: type || null,
            ticketItems: [
              {
                category: type || null,
                categoryName: type ? getCategoryName(type) : null,
                minLengthOfStay: 0,
                maxLengthOfStay: 0,
                isOverride: 0,
              },
            ],
            isManualEdit: 0,
          };
      vm.ticket.rooms.push(categoryItem);
      updateDates();
    }

    function addRooms(record) {
      if (vm.hasInsuranceEnhancementModule) {
        const newTicketItem = JSON.parse(JSON.stringify(defaultTicketItem.ticketItems[0]));

        // Format new ticket item.

        newTicketItem.category = record.category;

        // Add sub treatments items.

        if (record.category) {
          const category = _.filter(
            vm.formattedCategories,
            (f) => f.mainTreatmentCategoryName === record.category
          );

          newTicketItem.subTreatments = category[0].subTreatmentCategories.map(
            (s) => s.subTreatmentItemName
          );
          newTicketItem.section = category[0].sectionType;
        }

        // Add new ticket item.

        record.ticketItems.push(newTicketItem);
      } else {
        const r = record;

        if (typeof r.category === 'undefined' || r.category === null) return;

        if (!r.ticketItems) {
          r.ticketItems = [];
        }

        const defaultMasterBillingCode = r.roomTypes ? r.roomTypes[0].code : null;
        r.ticketItems.push({
          category: r.category,
          categoryName: getCategoryName(r.category),
          isOverride: 0,
          masterBillingCode: defaultMasterBillingCode,
        });
      }

      updateDates();
    }

    function clearRooms(category) {
      const c = category;
      c.ticketItems = [];
      addRooms(c);
    }

    function tidyRooms() {
      vm.ticket.rooms = _.filter(vm.ticket.rooms, (item) =>
        _.filter(item.ticketItems, (k) => !_.isNil(k.amount))
      );
    }

    function updateDates() {
      if (vm.admissionDate) {
        vm.roomChargeDateRangeOptions.min = moment(vm.admissionDate);
        vm.roomChargeDateRangeOptions.minDate = moment(vm.admissionDate);
      }

      if (vm.dischargeDate) {
        vm.roomChargeDateRangeOptions.max = moment(vm.dischargeDate);
        vm.roomChargeDateRangeOptions.maxDate = moment(vm.dischargeDate);
      }

      if (vm.ticket && vm.ticket.rooms) {
        _.each(vm.ticket.rooms, (x) => {
          _.each(x.ticketItems, (item) => {
            const p = item;
            if (!p.roomChargeDateRangeModel) {
              const defaultDaysDifference = App.getDaysDifference(p.startDate, p.endDate);

              p.roomChargeDateRangeModel = {
                startDate: p.startDate ? moment(p.startDate) : null,
                endDate: p.endDate ? moment(p.endDate) : null,
              };

              p.lengthOfStay = p.lengthOfStay ? p.lengthOfStay : defaultDaysDifference;
              p.minLengthOfStay = defaultDaysDifference;
              p.maxLengthOfStay = defaultDaysDifference + 1;
            }
          });
        });
      }
    }

    function updateRooms(index, item) {
      if (vm.hasTxtFileSupport && vm.isEdit) {
        getRoomTypes(item);
      }

      if (typeof item.category === 'undefined' || item.category === null) {
        if (vm.ticket.rooms.length > 1) {
          vm.ticket.rooms.splice(index, 1);
        } else {
          vm.ticket.rooms = [];
          addCategory();
        }
      } else if (item.ticketItems) {
        _.each(item.ticketItems, (ticketItem) => {
          const x = ticketItem;
          x.category = item.category;
          x.categoryName = getCategoryName(item.category);

          // Set master billing code

          if (vm.hasTxtFileSupport)
            x.masterBillingCode =
              item.category === 'IcuRoomAndBoard' ? item.roomTypes[0].code : null;
        });
      }
    }

    function deleteItem(index, item) {
      const i = item;
      if (i.ticketItems.length > 1) {
        i.ticketItems.splice(index, 1);
      } else {
        i.ticketItems = [];
        addRooms(i);
      }
    }

    function calculateTotal(item) {
      const i = item;

      let uncoveredAmount = 0;
      let amount = 0;
      let lengthOfStay = 0;

      if (i.amount > 0) {
        amount = i.amount;
      }
      if (i.uncoveredAmount > 0) {
        uncoveredAmount = i.uncoveredAmount;
      }
      const totalCharges = amount + uncoveredAmount;
      i.totalCharges = totalCharges;

      if (i.lengthOfStay > 0) {
        lengthOfStay = i.lengthOfStay;
      }
      const dailyCharges = App.roundAmount(totalCharges / lengthOfStay);
      i.dailyCharges = dailyCharges;
    }

    function calculateLengthOfStay() {
      if (vm.admissionDate) {
        vm.roomChargeDateRangeOptions.min = moment(vm.admissionDate);
        vm.roomChargeDateRangeOptions.minDate = moment(vm.admissionDate);
      }

      if (vm.dischargeDate) {
        vm.roomChargeDateRangeOptions.max = moment(vm.dischargeDate);
        vm.roomChargeDateRangeOptions.maxDate = moment(vm.dischargeDate);
      }

      _.each(vm.ticket.rooms, (x) => {
        _.each(x.ticketItems, (item) => {
          const p = item;
          if (p.roomChargeDateRangeModel) {
            const startDate = moment(p.roomChargeDateRangeModel.startDate).utc();
            const endDate = moment(p.roomChargeDateRangeModel.endDate).utc();
            const defaultDaysDifference = App.getDaysDifference(startDate, endDate);

            p.minLengthOfStay = defaultDaysDifference;
            p.maxLengthOfStay = defaultDaysDifference + 1;
            p.lengthOfStay =
              p.lengthOfStay &&
              p.lengthOfStay >= p.minLengthOfStay &&
              p.lengthOfStay <= p.maxLengthOfStay
                ? p.lengthOfStay
                : defaultDaysDifference;
          }
        });
      });
    }

    function calculateAmount(item, index, parentIndex) {
      const i = item;

      vm.ticket.rooms[parentIndex].ticketItems[index].isOverride = 0;

      i.hasExceeded = false;
      i.isUncovered = false;

      validateAccommodationCharges();
    }

    function validateAccommodationCharges() {
      const categories = [
        'Room & Board',
        'ICU/CCU/HDU',
        "Insured child's daily guardian benefit (lodging/bed)",
      ];

      // Will use this two variables if accommodationDays is combined.

      let remainingMasterBalanceDays = getBalanceDaysByCategory('RoomAndBoard');
      let remainingIcuBalanceDays = getBalanceDaysByCategory('IcuRoomAndBoard');

      _.each(vm.ticket.rooms, (x) => {
        // Will use this two variables if accommodationDays is NOT combined.
        let balanceDays = 0;
        let remainingBalanceDays = 0;

        if (x.category && x.category !== 'ChildGuardianBenefit') {
          balanceDays = getBalanceDaysByCategory(matchCategoryNames(x.category));
          remainingBalanceDays = balanceDays;
        }

        _.each(x.ticketItems, (item) => {
          const p = item;
          if (p.lengthOfStay < 0 || p.dailyCharges < 0) return;

          p.totalCharges = p.lengthOfStay * p.dailyCharges;

          if (vm.hasInsuranceEnhancementModule && !categories.includes(item.category)) {
            p.amount = p.dailyCharges * p.lengthOfStay;
            p.uncoveredAmount = 0;
            return;
          }

          // Categories naming for their respective old category names.

          const oldCategory = matchCategoryNames(p.category);

          const benefitByLimit = _.first(
            _.filter(vm.benefits, (b) => b.category === oldCategory && b.type === 0)
          );

          if (p.isOverride === 1) return;

          // Validate amount.

          const maxCharges = benefitByLimit ? benefitByLimit.limitAmount || 0 : 0;
          const isAsCharged = benefitByLimit ? benefitByLimit.isAsCharged || false : false;

          let isCovered;
          let isLimited;

          switch (p.category) {
            case 'Room & Board':
            case 'RoomAndBoard':
              if (p.dailyCharges > maxCharges && maxCharges > 0) {
                p.amount = maxCharges * p.lengthOfStay;
                p.uncoveredAmount = (p.dailyCharges - maxCharges) * p.lengthOfStay;
                p.hasExceeded = true;
              } else {
                p.amount = p.dailyCharges * p.lengthOfStay;
                p.uncoveredAmount = 0;
              }

              break;

            case 'ICU/CCU/HDU':
            case 'IcuRoomAndBoard':
              if (!isAsCharged && p.dailyCharges > maxCharges && maxCharges > 0) {
                p.amount = maxCharges * p.lengthOfStay;
                p.uncoveredAmount = (p.dailyCharges - maxCharges) * p.lengthOfStay;
                p.hasExceeded = true;
              } else {
                p.amount = p.dailyCharges * p.lengthOfStay;
                p.uncoveredAmount = 0;
              }

              break;
            case 'ChildGuardianBenefit':
            case "Insured child's daily guardian benefit (lodging/bed)":
              isCovered = !_.isNil(benefitByLimit);
              isLimited = isCovered && !isAsCharged;

              if (!isCovered) {
                p.amount = 0;
                p.uncoveredAmount = p.dailyCharges * p.lengthOfStay;
                p.isUncovered = true;
              } else if (isLimited && p.dailyCharges > maxCharges && maxCharges > 0) {
                p.amount = maxCharges * p.lengthOfStay;
                p.uncoveredAmount = (p.dailyCharges - maxCharges) * p.lengthOfStay;
                p.hasExceeded = true;
              } else {
                p.amount = p.dailyCharges * p.lengthOfStay;
                p.uncoveredAmount = 0;
              }
              break;

            default:
              break;
          }

          // Validate visit days.

          if (p.category === 'ChildGuardianBenefit') return;

          const charge =
            maxCharges > 0 && p.dailyCharges > maxCharges ? maxCharges : p.dailyCharges;

          let coveredDays = p.lengthOfStay;

          if (isAccommodationDaysCombined()) {
            switch (p.category) {
              case 'Room & Board':
              case 'RoomAndBoard':
                if (p.lengthOfStay > remainingMasterBalanceDays) {
                  updateItemAmounts(p, remainingMasterBalanceDays, charge);
                  coveredDays = remainingMasterBalanceDays;
                }
                remainingMasterBalanceDays -= coveredDays;
                break;
              case 'ICU/CCU/HDU':
              case 'IcuRoomAndBoard':
                if (remainingIcuBalanceDays > remainingMasterBalanceDays) {
                  remainingIcuBalanceDays = remainingMasterBalanceDays;
                }

                if (p.lengthOfStay > remainingIcuBalanceDays) {
                  updateItemAmounts(p, remainingIcuBalanceDays, charge);
                  coveredDays = remainingIcuBalanceDays;
                }
                remainingIcuBalanceDays -= coveredDays;
                remainingMasterBalanceDays -= coveredDays;
                break;
              default:
                break;
            }
          } else {
            if (p.lengthOfStay > remainingBalanceDays) {
              updateItemAmounts(p, remainingBalanceDays, charge);
              coveredDays = remainingBalanceDays;
            }
            remainingBalanceDays -= coveredDays;
          }
        });
      });
    }

    function isAccommodationDaysCombined() {
      const icuBenefit = _.first(
        _.filter(vm.benefits, (b) => b.category === 'IcuRoomAndBoard' && b.type === 1)
      );

      return icuBenefit.isAccomodationDaysCombined;
    }

    function getBalanceDaysByCategory(category) {
      let balanceDays = 0;

      const benefitByVisit = _.first(
        _.filter(vm.benefits, (b) => b.category === category && b.type === 1)
      );

      if (benefitByVisit) {
        balanceDays = vm.isAdjustment
          ? benefitByVisit.balanceDays + vm.totalDays
          : benefitByVisit.balanceDays;
      }

      return balanceDays || 0;
    }

    // This function update the ticket item's covered and uncovered amount
    // when it exceeds the 'visit days per year' limit.
    function updateItemAmounts(item, remainingBalanceDays, charge) {
      const i = item;
      const uncoveredAmount = (i.lengthOfStay - remainingBalanceDays) * charge;
      i.amount -= uncoveredAmount;
      i.uncoveredAmount += uncoveredAmount;
      i.hasExceeded = true;
    }

    function getAmount() {
      let amount = 0;
      let uncoveredAmount = 0;

      _.each(vm.ticket.rooms, (room) => {
        const x = room;
        x.showExceededMessage = false;
        x.showUncoveredMessage = false;

        _.each(x.ticketItems, (p) => {
          if (p.amount) amount += p.amount;

          if (p.uncoveredAmount) uncoveredAmount += p.uncoveredAmount;

          if (p.hasExceeded) x.showExceededMessage = true;

          if (p.isUncovered) x.showUncoveredMessage = true;
        });
      });

      return {
        subtotalAmount: App.roundAmount(amount + uncoveredAmount),
        totalCoveredAmount: App.roundAmount(amount),
        totalUncoveredAmount: App.roundAmount(uncoveredAmount),
      };
    }

    function addLengthOfStay(item, index, parentIndex) {
      const i = item;
      if (i.lengthOfStay < i.maxLengthOfStay) {
        i.lengthOfStay += 1;
      }

      calculateAmount(i, index, parentIndex);
    }

    function deductLengthOfStay(item, index, parentIndex) {
      const i = item;
      if (i.lengthOfStay > i.minLengthOfStay) {
        i.lengthOfStay -= 1;
      }

      calculateAmount(i, index, parentIndex);
    }

    function lookupRemark(filter) {
      searchRemark(filter);
    }

    function cleanUserEntry(entry) {
      return _.map(
        _.split(_.toLower(entry), ' '),
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      ).join(' ');
    }

    function searchRemark(userEntry) {
      const cleanedUserEntry = cleanUserEntry(userEntry || '');
      const filter = _.toLower(cleanedUserEntry);

      if (filter) {
        vm.filteredRemark = postProcessRemarkList(vm.remarkOptions);
      } else {
        vm.filteredRemark = [];
      }

      function postProcessRemarkList(remarks) {
        const clonedItems = remarks.slice(0);
        const idx = _.findIndex(
          clonedItems,
          (d) => _.toLower(_.trim(d.displayName || '')) === filter
        );
        if (idx > 0) {
          const items = clonedItems.splice(idx, 1);
          clonedItems.splice(0, 0, items[0]);
        } else if (idx === -1) {
          clonedItems.splice(0, 0, {
            id: -1,
            displayName: cleanedUserEntry,
          });
        }
        return clonedItems;
      }
    }

    function manualEdit(index) {
      vm.tempPlaceHolder = angular.copy(vm.ticket.rooms[index]);
      vm.ticket.rooms[index].isManualEdit = 1;
    }

    function cancelManualEdit(index) {
      vm.ticket.rooms[index] = angular.copy(vm.tempPlaceHolder);
      vm.ticket.rooms[index].isManualEdit = 0;
      vm.tempPlaceHolder = null;
    }

    function saveManualEdit(index) {
      vm.ticket.rooms[index].isManualEdit = 0;

      _.each(vm.ticket.rooms[index].ticketItems, (item) => {
        const p = item;
        p.isOverride = 1;
      });

      vm.tempPlaceHolder = null;
    }

    function getCategoryName(category) {
      const selectedCategory = _.find(vm.categories, { id: category });
      return selectedCategory ? selectedCategory.displayName : null;
    }

    function getMasterBillingCode() {
      const input = ['RoomAndBoard', 'ChildGuardianBenefit', 'IcuRoomAndBoard'];

      vm.loading += 1;
      commonLookupSvc
        .getMasterBillingCodesByCodeList(input)
        .success((data) => {
          vm.codes = data;

          // To get master billing code description

          if (vm.ticket.rooms.length > 0 && vm.ticket.rooms[0].category) {
            _.each(vm.ticket.rooms, (room) => {
              getRoomTypes(room);
              _.each(room.ticketItems, (item) => {
                const i = item;
                const roomItem = _.find(vm.codes, (x) => x.code === i.masterBillingCode);

                if (!roomItem) {
                  i.unmatchedMasterBillingCode = i.masterBillingCode;
                  i.masterBillingCode = null;
                }

                if (roomItem) {
                  i.description = roomItem.description;
                }
              });
            });
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getRoomTypes(item) {
      const i = item;
      if (i.category === 'Room & Board' || i.category === 'RoomAndBoard') {
        i.roomTypes = _.filter(vm.codes, (x) => x.parentCode === 'RoomAndBoard').sort((a, b) =>
          a.description > b.description ? 1 : -1
        );
      } else if (i.category === 'ChildGuardianBenefit') {
        i.roomTypes = _.filter(vm.codes, (x) => x.parentCode === 'ChildGuardianBenefit').sort(
          (a, b) => (a.description > b.description ? 1 : -1)
        );
      } else if (i.category === 'ICU/CCU/HDU' || i.category === 'IcuRoomAndBoard') {
        i.roomTypes = _.filter(vm.codes, (x) => x.code === 'IcuRoomAndBoard');
      }

      if (i.roomTypes && i.roomTypes.length === 1) {
        _.each(i.ticketItems, (ticketItem) => {
          const x = ticketItem;
          x.masterBillingCode = i.roomTypes[0].code;
        });
      }
    }

    function formatCategories() {
      // Get main categories.

      _.forEach(vm.categories, (x) => {
        if (
          vm.formattedCategories
            .map((e) => e.mainTreatmentCategoryName)
            .indexOf(x.mainTreatmentCategoryName) < 0
        ) {
          vm.formattedCategories.push({
            mainTreatmentCategoryName: x.mainTreatmentCategoryName,
            sectionType: x.sectionType,
            subTreatmentCategories: [],
          });
        }
      });

      // Get sub categories.

      _.forEach(vm.formattedCategories, (x) => {
        const subTreatments = _.filter(
          vm.categories,
          (c) => c.mainTreatmentCategoryName === x.mainTreatmentCategoryName
        );

        _.forEach(subTreatments, (s) => {
          if (
            x.subTreatmentCategories
              .map((e) => e.subTreatmentItemName)
              .indexOf(s.subTreatmentItemName) < 0
          ) {
            x.subTreatmentCategories.push({
              subTreatmentItemName: !s.subTreatmentItemName
                ? x.mainTreatmentCategoryName
                : s.subTreatmentItemName,
              procedures: [],
            });
          }
        });
      });

      // Get procedures.

      _.forEach(vm.formattedCategories, (x) => {
        _.forEach(x.subTreatmentCategories, (s) => {
          const procedures = _.filter(
            vm.categories,
            (c) => c.subTreatmentItemName === s.subTreatmentItemName
          );

          _.forEach(procedures, (p) => {
            if (p.procedures) {
              s.procedures.push(p.procedures);
            }
          });
        });
      });
    }

    function updateCategory(index, item) {
      if (item.category) {
        // Clear sub categories & procedures.

        _.forEach(item.ticketItems, (x) => {
          x.subTreatment = '';
          x.procedure = '';
          x.uncoveredProcedure = '';
          x.subTreatments = [];
          x.procedures = [];
        });

        // Update each ticketItem in item.

        const category = _.filter(
          vm.formattedCategories,
          (f) => f.mainTreatmentCategoryName === item.category
        );

        _.forEach(item.ticketItems, (x) => {
          x.category = item.category;
          x.section = category[0].sectionType;

          // Add sub treatments items.

          x.subTreatments = category[0].subTreatmentCategories.map((s) => s.subTreatmentItemName);
        });
      } else {
        clearRooms(item);
      }
    }

    function updateSubCategory(index, item, ticketItem) {
      // Clear procedures.

      ticketItem.procedure = '';
      ticketItem.uncoveredProcedure = '';
      ticketItem.procedures = [];

      // Add procedure items.

      const category = _.filter(
        vm.formattedCategories,
        (f) => f.mainTreatmentCategoryName === item.category
      );

      const subTreatment = _.filter(
        category[0].subTreatmentCategories,
        (s) => s.subTreatmentItemName === ticketItem.subTreatment
      );

      ticketItem.procedures = subTreatment[0].procedures;
    }

    function getTicketItems() {
      const ticketItems = _.filter(
        vm.ticket.ticketItems,
        (item) => item.category === 'Accommodation charges'
      );

      formatTicketItems(ticketItems);
    }

    function formatTicketItems(items) {
      const mainTreatments = [];

      // Get main treatments.

      _.forEach(items, (item) => {
        if (_.indexOf(mainTreatments, item.categoryName) < 0) {
          mainTreatments.push(item.categoryName);
        }
      });

      // Get ticket items per main treatment.

      _.forEach(mainTreatments, (main) => {
        const ticketItems = _.filter(items, (x) => x.categoryName === main);
        const newCategory = JSON.parse(JSON.stringify(defaultTicketItem));

        newCategory.category = main;
        newCategory.ticketItems = [];

        // Get sub categories and procedures.

        const mainTreatment = _.filter(
          vm.formattedCategories,
          (f) => f.mainTreatmentCategoryName === main
        );

        _.forEach(ticketItems, (x) => {
          const newTicketItem = JSON.parse(JSON.stringify(defaultTicketItem.ticketItems[0]));
          const subTreatment = _.filter(
            mainTreatment[0].subTreatmentCategories,
            (s) => s.subTreatmentItemName === x.subCategory
          );

          newTicketItem.subTreatments = mainTreatment[0].subTreatmentCategories.map(
            (s) => s.subTreatmentItemName
          );

          newTicketItem.procedures = subTreatment[0].procedures;

          newTicketItem.amount = x.amount;
          newTicketItem.uncoveredAmount = x.uncoveredAmount;
          newTicketItem.section = x.category;
          newTicketItem.category = x.categoryName;
          newTicketItem.subTreatment = x.subCategory;
          newTicketItem.procedure = x.procedure;
          newTicketItem.uncoveredProcedure = x.uncoveredProcedure;
          newTicketItem.remark = x.remark;
          newTicketItem.uncoveredRemark = x.uncoveredRemark;
          newTicketItem.totalCharges = x.amount + x.uncoveredAmount;
          newTicketItem.lengthOfStay = x.lengthOfStay;
          newTicketItem.dailyCharges = x.dailyCharges;
          newTicketItem.startDate = x.startDate;
          newTicketItem.endDate = x.endDate;

          newCategory.ticketItems.push(newTicketItem);
        });

        vm.ticket.rooms.push(newCategory);
      });
    }

    // Show V2 UI during create or edit when condition fulfilled.

    function fulfillV2ViewCondition() {
      const ticketVersion = !vm.isClaim ? vm.ticket.version : vm.ticket.ticketVersion;

      const isCreate = vm.isClaim ?
        vm.ticket.requestType !== vm.enums.serviceRequestType.Adjustment.name
        : vm.isCreate;

      return (vm.hasInsuranceEnhancementModule && (isCreate || ticketVersion === 'V2'));
    }

    function getSubtotalAmount() {
      return getAmount().subtotalAmount;
    }

    function getTotalCoveredAmount() {
      return getAmount().totalCoveredAmount;
    }

    function getTotalUncoveredAmount() {
      return getAmount().totalUncoveredAmount;
    }

    function matchCategoryNames(category){
      switch (category) {
        case 'Room & Board':
        case 'RoomAndBoard':
          return 'RoomAndBoard';
        case 'ICU/CCU/HDU':
        case 'IcuRoomAndBoard':
          return 'IcuRoomAndBoard';
        case "Insured child's daily guardian benefit (lodging/bed)":
        case 'ChildGuardianBenefit':
          return 'ChildGuardianBenefit';
        default:
          return category;
      }
    }
  }
})();
