(() => {
    const componentId = 'financeManualTransactionRequestsCreateEditManualTransactionRequestCreateManualTransactionRequestComponent';
    const app = angular.module('app');

    app.component(componentId, {
        templateUrl: require('./createManualTransactionRequest.component.html'),
        controller: CreateManualTransactionRequestController,
        controllerAs: 'vm',
        bindings: {

        },
    });

    CreateManualTransactionRequestController.$inject = [
        '$stateParams',
        '$scope',
    ];

    function CreateManualTransactionRequestController($stateParams,$scope){
        const vm = this;

        
    }
  })();