import swal from 'sweetalert';

(() => {
  angular
    .module('app')
    .controller(
      'common.views.saleTransactions.subscriptionCreditMemo.page',
      SubscriptionCreditMemoPageController
    );

  SubscriptionCreditMemoPageController.$inject = [
    '$stateParams',
    'currencies',
    'numberFilter',
    'abp.services.app.saleTransaction',
    'abp.services.app.report',
  ];

  function SubscriptionCreditMemoPageController(
    $stateParams,
    currencies,
    numberFilter,
    saleTransactionSvc
  ) {
    const vm = this;

    vm.transactionNumber = $stateParams.transactionNumber;
    vm.creditMemo = null;
    vm.creditMemoFrom = null;
    vm.creditMemoTo = null;
    vm.payments = [];
    vm.isInpatient = App.isInpatient();
    vm.message = null;

    vm.getPaymentLabel = getPaymentLabel;
    vm.getOriginalPaymentLabel = getOriginalPaymentLabel;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.loading = 0;

    const currency = _.find(currencies, { code: vm.currencyCode });
    vm.decimalPlaces = currency ? currency.decimalPlaces : 2;

    vm.getPaymentLabel = getPaymentLabel;
    vm.getOriginalPaymentLabel = getOriginalPaymentLabel;
    vm.send = send;

    init();

    function init() {
      vm.loading += 1;
      saleTransactionSvc
        .getSubscriptionCreditMemo({
          id: vm.transactionNumber,
        })
        .success((data) => {
          vm.creditMemo = data.creditMemo;
          vm.creditMemo.isAgentCreditMemo = _.includes([4, 5, 7], vm.creditMemo.category);
          vm.creditMemoFrom = data.creditMemoFrom;
          vm.creditMemoTo = data.creditMemoTo;
          vm.payments = data.payments;
          vm.saleTransactionItems = data.creditMemo.subscriptions.saleTransactionItems;
          vm.saleTransactionDetailedCharges =
            data.creditMemo.subscriptions.saleTransactionDetailedCharges;
          vm.hasDetailedCharges = vm.saleTransactionDetailedCharges.length > 0;
          vm.originalPaymentForExcessPayment = data.excessPaymentOriginalPayment;
          vm.currencyCode = data.currencyCode;

          if (data.currencyCode) vm.currencyCode = data.currencyCode;

          vm.taxSummaries = _.chain(vm.saleTransactionItems)
            .groupBy((d) => (d.taxDescription || d.taxCode || '').trim().toUpperCase())
            .map((v, k) => {
              const dto = {
                taxCode: k,
                preTaxAmount: _.sumBy(v, 'preTaxAmount'),
                taxAmount: _.sumBy(v, 'taxAmount'),
              };

              dto.description = App.localize(
                'TaxCodeXOnY',
                dto.taxCode,
                numberFilter(dto.preTaxAmount, vm.decimalPlaces)
              );

              return dto;
            })
            .filter('taxCode')
            .sortBy('taxCode')
            .value();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getPaymentLabel() {
      return App.localize('XPaymentsMade', vm.payments.length);
    }

    function getOriginalPaymentLabel() {
      return App.localize(
        'ExcessPaymentOriginalPaymentMessage',
        vm.originalPaymentForExcessPayment.transactionNumber
      );
    }

    function send() {
      saleTransactionSvc
        .getFinanceEmails({
          tenantId: vm.creditMemo.tenantId,
          subsidiaryId: vm.creditMemo.subsidiaryId,
        })
        .success((data) => {
          swal(
            {
              title: App.localize('SendEmailForX', vm.creditMemo.transactionNumber),
              text: App.localize('EmailAddresses'),
              type: 'input',
              showCancelButton: true,
              closeOnConfirm: false,
              confirmButtonColor: '#1ab394',
              inputPlaceholder: App.localize('EmailAddressesNarrative'),
              inputValue: data.emailAddresses,
              showLoaderOnConfirm: true,
            },
            (inputValue) => {
              if (inputValue === false) return false;
              if (inputValue === '') {
                swal.showInputError(App.localize('InvalidEmailAddress'));
                return false;
              }
              saleTransactionSvc
                .sendEmail({
                  transactionNumber: vm.creditMemo.transactionNumber,
                  emailAddresses: inputValue,
                })
                .success(() => {
                  abp.notify.info(App.localize('EmailSent'));
                  swal.close();
                });
              return false;
            }
          );
        });
    }
  }
})();
