(() => {
  angular
    .module('finance')
    .controller('finance.views.billingAccounts.index', BillingAccountsPageController);

  BillingAccountsPageController.$inject = [];

  function BillingAccountsPageController() {
    // Empty
  }
})();
