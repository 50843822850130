import swal from 'sweetalert';

(() => {
  angular.module('app').component('commonInpatientNprClaimsDetailsClaimSummaryComponent', {
    templateUrl: require('./claimSummary.component.html'),
    controller: ClaimSummaryController,
    controllerAs: 'vm',
    bindings: {},
  });

  ClaimSummaryController.$inject = [
    '$rootScope',
    '$state',
    '$stateParams',
    'abp.services.app.nprClaim',
    'Hms.Claims.ClaimStatus',
    'Hms.Employees.EmployeeState',
  ];

  function ClaimSummaryController(
    $rootScope,
    $state,
    $stateParams,
    nprClaimSvc,
    enumClaimStatus,
    enumEmployeeState
  ) {
    const vm = this;

    vm.loading = 0;
    vm.saving = false;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.isHost = App.isHost();
    vm.claim = null;
    vm.claimNumber = $stateParams.claimNumber;

    vm.enums = {};
    vm.enums.claimStatus = enumClaimStatus;
    vm.enums.employeeState = enumEmployeeState;

    vm.canManageInpatientClaim = abp.auth.isGranted('Host.Claims.Hospital.Manage');
    vm.permissions = {
      manage: vm.canManageInpatientClaim,
      process: vm.canManageInpatientClaim,
      reject: vm.canManageInpatientClaim,
      viewTicket: abp.auth.isGranted('PatientTickets'),
    };
    vm.hasInsuranceEnhancementModule = abp.setting.getBoolean(
      'Hms.Feature.InsuranceEnhancementModule'
    );

    vm.processClaim = processClaim;
    vm.rejectClaim = rejectClaim;
    vm.sendEmail = sendEmail;

    init();

    function init() {
      getClaim();
    }

    function getClaim() {
      vm.loading += 1;
      nprClaimSvc
        .getClaimDetail({
          id: $stateParams.claimNumber,
        })
        .success((data) => {
          vm.claim = data;
          vm.claim.canManage = vm.claim.status === 0 || vm.claim.status === 6;
          vm.employeeId = vm.claim.employeeId;
          vm.claimType = vm.claim.claimType;
        })
        .finally(() => {
          vm.loading -= 1;
          $rootScope.$broadcast('employeeId', vm.employeeId);
        });
    }

    function processClaim() {
      if (!vm.claim || vm.saving) return;

      vm.saving = true;
      if (vm.claim.isPrePostHospitalizationClaim) {
        $state.go('inpatientNprProcessPrePostClaim', {
          claimNumber: vm.claimNumber,
          employeeId: vm.employeeId,
          claimType: vm.claimType,
        });
      } else {
        $state.go('inpatientNprProcessClaim', {
          claimNumber: vm.claimNumber,
        });
      }
    }

    function rejectClaim() {
      if (!vm.claim || vm.saving) return;

      swal(
        {
          title: App.localize('RejectClaim'),
          text: App.localize('RejectClaimWarningMessage', vm.claim.claimNumber),
          type: 'input',
          showCancelButton: true,
          closeOnConfirm: false,
          confirmButtonColor: '#1ab394',
          inputPlaceholder: App.localize('PleaseExplain'),
        },
        (inputValue) => {
          if (inputValue === false) {
            return false;
          }
          if ($.trim(inputValue || '') === '') {
            swal.showInputError(App.localize('PleaseExplain'));
            return false;
          }

          swal.close();
          vm.saving = true;
          nprClaimSvc
            .rejectClaim({
              id: vm.claim.claimNumber,
              remarks: inputValue,
            })
            .success(() => {
              abp.notify.success(App.localize('SuccessfullyRejected'));
              nprClaimSvc
                .getNextClaim({
                  id: null,
                })
                .success((data) => {
                  if (data.id) {
                    $state.go('inpatientNprClaimDetail', {
                      claimNumber: data.id,
                    });
                  } else {
                    abp.notify.info(App.localize('NoMoreUnprocessedClaims'));
                    $state.go('inpatientNpr');
                  }
                });
            })
            .finally(() => {
              vm.saving = false;
            });

          return true;
        }
      );
    }

    function sendEmail() {
      swal(
        {
          title: App.localize('SendEmailForX', vm.claim.claimNumber),
          text: App.localize('EmailAddresses'),
          type: 'input',
          showCancelButton: true,
          closeOnConfirm: false,
          confirmButtonColor: '#1ab394',
          inputPlaceholder: App.localize('EmailAddressesNarrative'),
          inputValue: '',
          showLoaderOnConfirm: true,
        },
        (inputValue) => {
          if (inputValue === false) return false;
          if (inputValue === '') {
            swal.showInputError(App.localize('InvalidEmailAddress'));
            return false;
          }
          nprClaimSvc
            .sendEmail({
              claimNumber: vm.claim.claimNumber,
              emailAddresses: inputValue,
            })
            .success(() => {
              abp.notify.info(App.localize('EmailSent'));
              swal.close();
            });
          return false;
        }
      );
    }
  }
})();
