(function () {
    var controllerId = 'corporate.views.employees.selectedPanelsModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'employee', 'abp.services.app.benefitGroup', 'abp.services.app.commonLookup', '$rootScope',
        function ($scope, $uibModalInstance, employee, benefitGroupSvc, commonLookUpSvc, $root) {
            var vm = this;
            vm.loading = 0;
            vm.employee = employee;
            vm.panelSearch = "";
            vm.whitelistedPanels = null;
            vm.filteredPanels = [];
            vm.preEmploymentPackageId = 0;
            vm.maxSelected = 10;
            vm.numberOfSelectedPanels = 0;
            vm.selectedPanelIds = [];
            vm.countryCode = null;
            vm.currencyCode = null;
            vm.benefitGroup = null;
            vm.totalPackageAmount = 0;

            function init() {
                vm.getCorporateCountryAndCurrencyCode();
            }

            // Retrieves benefit group.

            vm.getBenefitGroup = function () {
                vm.loading++;
                benefitGroupSvc.getBenefitGroupForEdit({
                    id: employee.benefitGroupId,
                    isPreEmploymentBenefitGroup: true,
                    countryCode: vm.countryCode
                }).success(function (data) {
                    vm.preEmploymentPackage = data.benefitGroup.preEmploymentPackage;
                    vm.benefitGroup = data.benefitGroup;
                    vm.getPreEmployementWhitelistedPanels();
                    vm.getTotalPackageAmount();
                }).finally(function () {
                    vm.loading--;
                });
            };

            // Get the package's total amount. Inclusive of optional treatments selected.

            vm.getTotalPackageAmount = function () {
                vm.totalPackageAmount = vm.preEmploymentPackage.amount;
                _.each(vm.preEmploymentPackage.treatments, function (t) {
                    if (t.isCovered) {
                        vm.totalPackageAmount += t.amount;
                    }
                });
            }

            // Retrieve pre-employment package and panels.

            vm.getPreEmployementWhitelistedPanels = function () {
                benefitGroupSvc.getPreEmploymentPackageWhitelistPanels({
                    id: vm.preEmploymentPackage.id,
                }).success(function (data) {
                    vm.whitelistedPanels = data;
                    vm.filteredPanels = data;
                    vm.checkPanels();
                }).finally();
            };

            vm.openPrintPage = function () {
                const url = $root.resolveAppPath(`/Print/PreEmploymentLetter?employeeId=${vm.employee.id}&whitelistedPanelIds=${vm.selectedPanelIds.toString()}`);
                window.open(url);
                $uibModalInstance.close();
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            // Filter the panels in the search bar.

            vm.filter = function () {
                vm.filteredPanels = [];

                var keyword = _.trim(vm.panelSearch || '');

                if (keyword) {
                    var regexes = _.chain(keyword.split(' '))
                        .map(function (k) {
                            return _.trim(k.toUpperCase());
                        })
                        .filter(function (k) {
                            return k;
                        })
                        .uniq()
                        .map(function (k) {
                            _.escapeRegExp(k);
                            return {
                                token: k,
                                len: k.length,
                                pattern: new RegExp('\\b' + k, 'i')
                            };
                        })
                        .value();

                    _.forEach(vm.whitelistedPanels, function (panel) {
                        var score = 0;

                        _.forEach(regexes, function (r) {
                            if (r.pattern.test(panel.name) || r.pattern.test(panel.street)
                                || r.pattern.test(panel.postcode)) {
                                score -= r.len;
                            }
                        });

                        if (score) {
                            vm.filteredPanels.push(panel)
                        }
                    });
                } else {
                    vm.filteredPanels = vm.whitelistedPanels;
                }
            };

            vm.checkPanels = function (isSelected) {
                vm.numberOfSelectedPanels = _.filter(vm.whitelistedPanels, function (x) {
                    return x.isSelected;
                }).length;

                getSelectedPanels();

                return vm.numberOfSelectedPanels == vm.maxSelected && !isSelected
            };

            function getSelectedPanels() {
                vm.selectedPanelIds = [];

                _.forEach(vm.whitelistedPanels, function (x) {
                    if (x.isSelected)
                        vm.selectedPanelIds.push(x.id);
                });
            }

            vm.getCorporateCountryAndCurrencyCode = function () {
                commonLookUpSvc.getTenantCountryCode()
                    .success(function (data) {
                        vm.countryCode = data;
                        vm.getBenefitGroup();
                    })
                    .finally();

                commonLookUpSvc.getTenantCurrencyCode()
                    .success(function (data) {
                        vm.currencyCode = data;
                    }).finally();
            };

            init();
        }
    ]);
})();
