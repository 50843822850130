(function () {
    angular.module('app').controller('corporate.views.benefitGroups.createEdit.confirmationModal', [
        '$uibModalInstance', 'originalBenefitGroup', 'changedBenefitGroup', 'currencyCode', 'hasSupportPlans', 'supportPlans',
        'isPreEmployment', 'hasDependentsOnlyModifier', 'dependentHasSpecialist', 'originalSpecialistTreatmentEditData',
        'changedSpecialistTreatmentEditData', 'coPayExample', 'showCoPayExample', 'originalTreatmentEditData', 'changedTreatmentEditData',
        'originalPharmacyTreatmentEditData', 'changedPharmacyTreatmentEditData', 'corporateHasMobileNpr',
        'hasBasicsChanged', 'hasBenefitsChanged', 'hasClaimPolicyChanged', 'hasCopayChanged', 'hasMobileAppChanged',
        'hasPharmacyChanged', 'hasSpecialistChanged', 'hasAccessibleTreatmentsChanged', 'hasHealthScreeningChanged',
        'dependentHasHealthScreening', 'changedHealthScreeningTreatmentEditData', 'originalHealthScreeningTreatmentEditData',
        function (
            $uibModalInstance, originalBenefitGroup, changedBenefitGroup, currencyCode, hasSupportPlans, supportPlans,
            isPreEmployment, hasDependentsOnlyModifier, dependentHasSpecialist, originalSpecialistTreatmentEditData,
            changedSpecialistTreatmentEditData, coPayExample, showCoPayExample, originalTreatmentEditData, changedTreatmentEditData,
            originalPharmacyTreatmentEditData, changedPharmacyTreatmentEditData, corporateHasMobileNpr,
            hasBasicsChanged, hasBenefitsChanged, hasClaimPolicyChanged, hasCopayChanged, hasMobileAppChanged,
            hasPharmacyChanged, hasSpecialistChanged, hasAccessibleTreatmentsChanged, hasHealthScreeningChanged,
            dependentHasHealthScreening, changedHealthScreeningTreatmentEditData, originalHealthScreeningTreatmentEditData) {
            var vm = this;

            vm.cancel = cancel;
            vm.confirm = confirm;

            vm.originalBenefitGroup = originalBenefitGroup;
            vm.changedBenefitGroup = changedBenefitGroup;
            vm.currencyCode = currencyCode;
            vm.hasSupportPlans = hasSupportPlans;
            vm.supportPlans = supportPlans;
            vm.isPreEmployment = isPreEmployment;
            vm.hasDependentsOnlyModifier = hasDependentsOnlyModifier;
            vm.dependentHasSpecialist = dependentHasSpecialist;
            vm.originalSpecialistTreatmentEditData = originalSpecialistTreatmentEditData;
            vm.changedSpecialistTreatmentEditData = changedSpecialistTreatmentEditData;
            vm.originalPharmacyTreatmentEditData = originalPharmacyTreatmentEditData;
            vm.changedPharmacyTreatmentEditData = changedPharmacyTreatmentEditData;
            vm.coPayExample = coPayExample;
            vm.showCoPayExample = showCoPayExample;
            vm.originalTreatmentEditData = originalTreatmentEditData;
            vm.changedTreatmentEditData = changedTreatmentEditData;
            vm.corporateHasMobileNpr = corporateHasMobileNpr;

            vm.hasBasicsChanged = hasBasicsChanged;
            vm.hasBenefitsChanged = hasBenefitsChanged;
            vm.hasClaimPolicyChanged = hasClaimPolicyChanged;
            vm.hasCopayChanged = hasCopayChanged;
            vm.hasMobileAppChanged = hasMobileAppChanged;
            vm.hasPharmacyChanged = hasPharmacyChanged;
            vm.hasSpecialistChanged = hasSpecialistChanged;
            vm.hasAccessibleTreatmentsChanged = hasAccessibleTreatmentsChanged;

            vm.hasHealthScreeningChanged = hasHealthScreeningChanged;
            vm.originalHealthScreeningTreatmentEditData = originalHealthScreeningTreatmentEditData;
            vm.dependentHasHealthScreening = dependentHasHealthScreening;
            vm.changedHealthScreeningTreatmentEditData = changedHealthScreeningTreatmentEditData;

            vm.hasChanges = hasBasicsChanged
                || hasBenefitsChanged
                || hasClaimPolicyChanged
                || hasCopayChanged
                || hasMobileAppChanged
                || hasPharmacyChanged
                || hasSpecialistChanged
                || hasAccessibleTreatmentsChanged;

            function cancel() {
                $uibModalInstance.dismiss();
            }

            function confirm() {
                $uibModalInstance.close();
            }
        }
    ]);
})();
