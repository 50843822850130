(() => {
  angular.module('app').controller('host.views.corporates.resetAdminPasswordModel', ResetCorporateAdminPasswordController);

  ResetCorporateAdminPasswordController.$inject = [
    '$uibModalInstance',
    'abp.services.app.corporate',
    'organizationId',
    'tenancyName',
  ];

  function ResetCorporateAdminPasswordController(
    $uibModalInstance,
    corporateSvc,
    organizationId,
    tenancyName
  ) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;
    vm.autoGeneratePassword = true;
    vm.shouldChangePasswordOnNextLogin = true;
    vm.tenancyName = tenancyName;

    vm.save = save;
    vm.cancel = cancel;

    function save() {
      if(vm.saving) return;

      if (vm.autoGeneratePassword) {
        vm.password = null;
      }

      vm.loading += 1;
      corporateSvc
        .resetAdminPassword({
          id: organizationId,
          autoGeneratePassword: vm.autoGeneratePassword,
          shouldChangePasswordOnNextLogin: vm.shouldChangePasswordOnNextLogin,
          password: vm.password,
        })
        .success((data) => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          if (data) {
            abp.message.success(App.localize('PasswordForAdmin'), data);
          }
          $uibModalInstance.close();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
