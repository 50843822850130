import _ from 'lodash';

(() => {
  angular
    .module('finance')
    .controller('finance.views.paymentInstruction', PaymentInstructionPageController);

  PaymentInstructionPageController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$timeout',
    '$uibModal',
    'moment',
    'abp.services.finance.paymentInstruction',
    'financeConstants',
    'abp.services.app.commonLookup',
  ];

  function PaymentInstructionPageController(
    scope,
    $state,
    $stateParams,
    $timeout,
    $modal,
    moment,
    paymentInstructionSvc,
    financeConsts,
    commonLookupSvc
  ) {
    const vm = this;

    vm.loading = 0;

    vm.requestParams = {
      piNumber: $stateParams.paymentInstructionNumber,
      batchNumber: $stateParams.batchNumber,
    };

    vm.now = moment();

    init();

    function init() {
      vm.loading += 1;
      paymentInstructionSvc
        .getPaymentInstructionsByBatchNumber(vm.requestParams)
        .success((data) => {
          vm.hostInfo = data.accountOwner;
          vm.paymentInstructions = data.instructions;
          vm.piBatchNumber = data.batchNumber;

          loadAccountOwnerInformations(vm.hostInfo.id);
          cleanupAddresses();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function loadAccountOwnerInformations(accountOwnerId) {
      commonLookupSvc.getAccountOwnerById({id: accountOwnerId}).success((accOwner) => {
        vm.hostInfo.logo = accOwner.dataUri;
        vm.hostInfo.contactNumber = accOwner.accountOwner.contactNumber;
      });
    }

    function cleanupAddresses() {
      _.forEach(vm.paymentInstructions, (paymentInstruction) => {
        paymentInstruction.to.hasAddress =
          paymentInstruction.to.stringifiedAddress.replaceAll(',', '').trim() !== '';
      });
    }
  }
})();
