(() => {
  angular
    .module('app')
    .filter('admissionType', admissionType)
    // For backward compatibility only. Filter name don't need "Filter" suffix.
    // AngularJS automatically appends "Filter" to name for DI.
    .filter('admissionTypeFilter', admissionType);

  admissionType.$inject = [];

  function admissionType() {
    return admissionTypeFilter;

    function admissionTypeFilter(value) {
      switch (value) {
        case 1:
          return App.localize('Surgical');
        case 2:
          return App.localize('Medical');
        case 3:
          return App.localize('Accident');
        case 4:
          return App.localize('Delivery');
        default:
          return value;
      }
    }
  }
})();
