(() => {
  angular
    .module('app')
    .controller('host.views.operationsDashboard.txtFiles.generateResultModal', GenerateResultModalController);

  GenerateResultModalController.$inject = [
    '$uibModalInstance',
    'errors',
  ];

  function GenerateResultModalController(
    $uibModalInstance,
    errors,
  ) {
    const vm = this;

    vm.errorText = errors;

    vm.cancel = cancel;

    init();

    function init() {}

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
