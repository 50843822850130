(function () {
    var controllerId = 'host.views.publicUsers.notificationLogs.page';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$state', '$timeout', '$stateParams', 'abp.services.app.publicUser',
        function ($scope, $state, $timeout, $stateParams, publicUserSvc) {
            function init() {
                vm.getNotificationLogs();

                $timeout(function () {
                    vm.notificatioLogsGridOptions.paginationCurrentPage = Math.floor(vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1);
                });
            }

            var vm = this;
            vm.loading = 0;
            vm.requestParams = {
                userId: $stateParams.userId,
                skipCount: $stateParams.skipCount,
                maxResultCount: $stateParams.maxResultCount
            };

            vm.notificatioLogsGridOptions = {
                appScopeProvider: vm,
                paginationPageSizes: app.consts.grid.defaultPageSizes,
                paginationPageSize: app.consts.grid.defaultPageSize,
                paginationCurrentPage: 0,
                useExternalPagination: true,
                columnDefs: [{
                    displayName: App.localize('Subject'),
                    field: 'title',
                    enableSorting: false,
                    minWidth: 80
                }, {
                    displayName: App.localize('Timestamp'),
                    field: 'creationTime',
                    cellFilter: 'momentFormat: \'L LTS\'',
                    enableSorting: false,
                    minWidth: 80
                }, {
                    displayName: App.localize('Status'),
                    field: 'isRead',
                    minWidth: 80,
                    enableSorting: false,
                    cellTemplate: 'statusTemplate'
                }, {
                    displayName: App.localize('Content'),
                    field: 'body',
                    enableSorting: false,
                    minWidth: 80
                }],
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                    gridApi.pagination.on.paginationChanged($scope, App.setPagination(vm.requestParams, vm.getNotificationLogs));
                },
                data: []
            };

            function registerStateParams() {
                $state.transitionTo($state.current, {
                    userId: $stateParams.userId,
                    skipCount: vm.requestParams.skipCount,
                    maxResultCount: vm.requestParams.maxResultCount,
                }, {
                    notify: false
                });
            }

            vm.getNotificationLogs = function () {
                registerStateParams();
                vm.loading++;

                publicUserSvc.getNotificationLogs(vm.requestParams)
                    .success(function (data) {
                        vm.notificatioLogsGridOptions.data = data.items;
                        vm.notificatioLogsGridOptions.totalItems = data.totalCount;
                    }).finally(function (e) {
                        vm.loading--;
                    });
            };

            init();
        }
    ]);
})();
