(function () {
    'use strict';
    var controllerId = 'host.views.treatments.medicalFeeSchedules';
    var app = angular.module('app');

    app.controller(controllerId, ['$state', '$stateParams', '$scope', '$timeout', '$uibModal',
        'abp.services.app.medicalFeeSchedule', 'abp.services.app.commonLookup', 'Hms.MedicalFeeSchedules.MedicalFeeSchedulePart',
        'Hms.MedicalFeeSchedules.MedicalFeeScheduleSection', controller]);

    function controller($state, $stateParams, $scope, $timeout, $modal, medicalFeeScheduleSvc, commonLookupSvc, enumMedicalFeeSchedulePart, enumMedicalFeeScheduleSection) {
        var vm = this;
        vm.getMedicalFeeSchedules = getMedicalFeeSchedules;
        vm.bulkImport = bulkImport;
        vm.updateMcsCode = updateMcsCode;

        vm.loading = 0;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

        vm.enums = {};
        vm.enums.medicalFeeSchedulePart = enumMedicalFeeSchedulePart;
        vm.enums.medicalFeeScheduleSection = enumMedicalFeeScheduleSection;

        vm.permissions = {
            import: abp.auth.isGranted('Host.Treatments.MedicalFeeSchedules.Import')
        };

        function init() {
            vm.loading++;
            commonLookupSvc.getSpecialistDisciplines().success(function (data) {
                vm.gridOptions.totalItems = data.totalCount;
                vm.disciplines = data.items;
            }).finally(function (e) {
                vm.loading--;
            });

            vm.loading++;
            commonLookupSvc.getBodyParts().success(function (data) {
                vm.gridOptions.totalItems = data.totalCount;
                vm.bodyParts = data.items;
            }).finally(function (e) {
                vm.loading--;
            });

            $timeout(function () {
                vm.gridOptions.paginationCurrentPage = Math.floor(vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1);
            });
        }

        vm.requestParams = {
            filter: $stateParams.filter,
            skipCount: $stateParams.skipCount || 0,
            maxResultCount: $stateParams.maxResultCount || app.consts.grid.defaultPageSize,
            sorting: $stateParams.sorting,
            specialistDisciplineId: $stateParams.specialistDisciplineId,
            part: $stateParams.part,
            section: $stateParams.section,
            chapter: $stateParams.chapter,
            category: $stateParams.category,
            subCategory: $stateParams.subCategory,
            item: $stateParams.item,
            bodyPartId: $stateParams.bodyPartId
        };

        vm.gridOptions = {
            appScopeProvider: vm,
            paginationPageSizes: app.consts.grid.defaultPageSizes,
            paginationPageSize: app.consts.grid.defaultPageSize,
            paginationCurrentPage: 0,
            enableSorting: false,
            useExternalPagination: true,
            resizable: true,
            columnDefs: [{
                displayName: App.localize('FeeSchedulePart'),
                field: 'part',
                minWidth: 100,
                cellTemplate: 'partTemplate'
            }, {
                displayName: App.localize('FeeScheduleSection'),
                field: 'section',
                minWidth: 130,
                cellTemplate: 'sectionTemplate'
            }, {
                displayName: App.localize('FeeScheduleChapter'),
                field: 'chapter',
                minWidth: 120
            }, {
                displayName: App.localize('FeeScheduleCategory'),
                field: 'category',
                minWidth: 120
            }, {
                displayName: App.localize('FeeScheduleSubCategory'),
                field: 'subCategory',
                minWidth: 120
            }, {
                displayName: App.localize('FeeScheduleSpecialistDiscipline'),
                field: 'specialistDisciplineId',
                minWidth: 60
            }, {
                displayName: App.localize('FeeScheduleItem'),
                field: 'item',
                minWidth: 120
            }, {
                displayName: App.localize('FeeScheduleBodyPart'),
                field: 'bodyPartId',
                minWidth: 60
            }, {
                displayName: App.localize('FeeScheduleNotes'),
                field: 'notes',
                minWidth: 120
            }, {
                displayName: App.localize('FeeScheduleMinTotal'),
                field: 'minimumTotalFee',
                cellFilter: 'currencyFormat: grid.appScope.currencyCode',
                minWidth: 100
            }, {
                displayName: App.localize('FeeScheduleMaxTotal'),
                field: 'maximumTotalFee',
                cellFilter: 'currencyFormat: grid.appScope.currencyCode',
                minWidth: 100
            }, {
                displayName: App.localize('FeeScheduleMinSurgeon'),
                field: 'minimumSurgeonFee',
                cellFilter: 'currencyFormat: grid.appScope.currencyCode',
                minWidth: 100
            }, {
                displayName: App.localize('FeeScheduleMaxSurgeon'),
                field: 'maximumSurgeonFee',
                cellFilter: 'currencyFormat: grid.appScope.currencyCode',
                minWidth: 100
            }, {
                displayName: App.localize('FeeScheduleMinAnaesthetist'),
                field: 'minimumAnaesthetistFee',
                cellFilter: 'currencyFormat: grid.appScope.currencyCode',
                minWidth: 100
            }, {
                displayName: App.localize('FeeScheduleMaxAnaesthetist'),
                field: 'maximumAnaesthetistFee',
                cellFilter: 'currencyFormat: grid.appScope.currencyCode',
                minWidth: 100,
            }, {
                displayName: App.localize('FeeScheduleRadiologist'),
                field: 'radiologistFeePercentage',
                minWidth: 100
            }, {
                displayName: App.localize('McsCode'),
                field: 'mcsCode',
                minWidth: 100
            }],
            onRegisterApi: function (gridApi) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function (scope, callback) {
                    vm.requestParams.sorting = callback.length && callback[0].field ? callback[0].field + ' ' + callback[0].sort.direction : null;
                    vm.getMedicalFeeSchedules();
                });
                gridApi.pagination.on.paginationChanged($scope, App.setPagination(vm.requestParams, vm.getMedicalFeeSchedules));
            },
            data: []
        };

        function getInput() {
            var input = {
                skipCount: vm.requestParams.skipCount,
                maxResultCount: vm.requestParams.maxResultCount,
                sorting: vm.requestParams.sorting,
                part: vm.requestParams.part,
                section: vm.requestParams.section,
                chapter: vm.requestParams.chapter,
                category: vm.requestParams.category,
                subCategory: vm.requestParams.subCategory,
                specialistDisciplineId: vm.requestParams.specialistDisciplineId,
                item: vm.requestParams.item,
                bodyPartId: vm.requestParams.bodyPartId,
                filter: vm.requestParams.filter
            };

            return input;
        }

        function registerStateParams(input) {
            input = input || getInput();
            $state.transitionTo($state.current, input, { notify: false });
        }

        function getMedicalFeeSchedules() {
            var input = getInput();
            registerStateParams(input);

            vm.loading++;
            medicalFeeScheduleSvc.getMedicalFeeSchedules(input).success(function (data) {
                vm.gridOptions.totalItems = data.totalCount;

                _.forEach(data.items, function (item) {
                    if (item.specialistDisciplineId !== null && item.specialistDisciplineId !== '') {
                        item.specialistDisciplineId = _.transform(item.specialistDisciplineId, function (result, disciplineId) {
                            _(vm.disciplines).forEach(function (specialistIdName) {
                                if (disciplineId == specialistIdName.value) {
                                    return result.push(specialistIdName.name);
                                }
                            });
                        }).join(', ');
                    }

                    if (item.bodyPartId !== null && item.bodyPartId !== '') {
                        item.bodyPartId = _.transform(item.bodyPartId, function (result, bPartId) {
                            _(vm.bodyParts).forEach(function (bodyPartIdName) {
                                if (bPartId == bodyPartIdName.value) {
                                    return result.push(bodyPartIdName.name);
                                }
                            });
                        }).join(', ');
                    }
                });

                vm.gridOptions.data = data.items;
            }).finally(function (e) {
                vm.loading--;
            });
        }

        function bulkImport() {
            var modal = $modal.open({
                templateUrl: require('/App/host/views/treatments/medicalFeeSchedules/import/import.modal.html'),
                controller: 'host.views.treatments.medicalFeeSchedules.importModal as vm',
                backdrop: 'static',
                size: 'lg'
            });
            modal.result.then(function () {
                vm.getMedicalFeeSchedules();
            });
        }

        function updateMcsCode() {
            var modal = $modal.open({
                templateUrl: require('/App/host/views/treatments/medicalFeeSchedules/updateMcsCode/updateMcsCode.modal.html'),
                controller: 'host.views.treatments.medicalFeeSchedules.updateMcsCodeModal as vm',
                backdrop: 'static',
                size: 'lg'
            });
            modal.result.then(function () {
                vm.getMedicalFeeSchedules();
            });
        }

        init();
    }
})();
