/* global zE */

(() => {
  angular
    .module('app')
    .controller('common.views.healthScreening.claims.details', ClaimsDetailsPageController);

  ClaimsDetailsPageController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$interval',
    'abp.services.app.healthScreeningClaim',
    'moment',
  ];

  function ClaimsDetailsPageController(
    $scope,
    $state,
    $stateParams,
    $interval,
    healthScreeningClaimSvc,
    moment
  ) {
    const vm = this;

    // #region Variable declaration

    vm.loading = 0;
    vm.saving = 0;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.claimNumber = $stateParams.claimNumber;
    vm.timeZoneId = $stateParams.timeZoneId;
    vm.letterNumber = '';
    vm.coveredAmount = 0;
    vm.collectAmount = 0;
    vm.newRepliedQueriesCount = 0;
    vm.showPendingApprovalView = null;
    vm.isPanel = App.isClinic();
    vm.isHost = App.isHost();
    vm.activeTab = $stateParams.activeTab;
    const ticketAdjustmentLocalization = App.localize('ClaimAdjustment');

    vm.claim = null;

    vm.breadcrumb = [];
    vm.pageTitle = '';

    vm.permissions = {
      canProcessClaim: abp.auth.isGranted('Claims.HealthScreening.Host.Edit'),
    };
    let isSilentLoading = 0;

    vm.getExceededAmount = getExceededAmount;
    vm.processClaim = processClaim;
    vm.getBillingDetailHistoryHeading = getBillingDetailHistoryHeading;
    vm.printLetter = printLetter;
    vm.viewTicket = viewTicket;
    vm.getDateTimeString = getDateTimeString;
    vm.getFullDateTimeString = getFullDateTimeString;

    // #endregion Variable declaration

    init();

    function init() {
      initBreadcrumb();

      getFinalRequestDetails();
      getTimeline();

      if ($stateParams.showZen && typeof zE !== 'undefined') {
        zE('webWidget', 'open');
      }

      let tickerInterval = $interval(silentLoad, 10000);
      $scope.$on('$destroy', () => {
        if (angular.isDefined(tickerInterval)) {
          $interval.cancel(tickerInterval);
          tickerInterval = undefined;
        }
      });
    }

    // #region Retrieve breadcrumb details from URL.

    function initBreadcrumb() {
      if (vm.isPanel) {
        vm.breadcrumb.push({ name: App.localize('HealthScreening') });
        vm.breadcrumb.push({ name: App.localize('Claims'), state: 'healthScreeningClaims' });
      } else {
        vm.breadcrumb.push({ name: App.localize('Claims') });
        vm.breadcrumb.push({
          name: App.localize('HealthScreening'),
          state: 'healthScreeningClaims',
        });
      }

      vm.breadcrumb.push({ name: `${App.localize('Claim')} ${vm.claimNumber}` });

      // Fallback for the breadcrumb title.

      vm.pageTitle = vm.breadcrumb.length > 0 ? vm.breadcrumb.slice(-1)[0].name : '';
    }

    // #endregion Retrieve breadcrumb details from URL.

    // #region Define function

    function silentLoad() {
      if (!vm.claim || vm.claim.status === 3 || !vm.claimNumber || vm.loading || isSilentLoading)
        return;

      isSilentLoading = 2;
      healthScreeningClaimSvc
        .getFinalRequestDetails(
          {
            id: vm.claimNumber,
          },
          {
            abpHandleError: false,
          }
        )
        .success((data) => {
          vm.currencyCode = data.currencyCode;

          processGetClaimDetailOutput(data);
        })
        .finally(() => {
          isSilentLoading -= 1;
        });

      healthScreeningClaimSvc
        .getRequestTimeline({
          id: vm.claimNumber,
        })
        .success((data) => {
          vm.timelineItems = data.items;
          _.each(vm.timelineItems, (item) => {
            const d = item;
            switch (d.subject) {
              case App.localize('Submitted'):
                d.icon = 'fas fa-upload text-warning';
                break;
              case App.localize('FullApproval'):
                d.icon = 'far fa-clipboard text-navy';
                break;
              case App.localize('UpdateClaim'):
                d.icon = 'fas fa-edit text-warning';
                break;
              case App.localize('PendingApproval_LateSubmission'):
                d.icon = 'far fa-calendar-exclamation text-danger';
                break;
              case App.localize('Rejected'):
                d.icon = 'fas fa-times-circle text-danger';
                break;
              case App.localize('AdjustmentRequestSubmitted'):
                d.icon = 'fas fa-pencil text-warning';
                break;
              case App.localize('AdjustmentRequestApproved'):
                d.icon = 'fas fa-pencil text-navy';
                break;
              case App.localize('AdjustmentRequestRejected'):
              case App.localize('AdjustmentRequestCancelled'):
                d.icon = 'fas fa-pencil text-danger';
                break;
              case App.localize('MedicalQueryFormSent'):
              case App.localize('MedicalQueryFormReceived'):
                d.icon = 'fas fa-comment text-warning';
                break;
              case App.localize('MedicalQueryFormReplied'):
                d.icon = 'fas fa-comments text-warning';
                break;
              case App.localize('MedicalQueryFormResolved'):
                d.icon = 'fas fa-comment-check text-navy';
                break;

              // no default
            }
          });
        })
        .finally(() => {
          isSilentLoading -= 1;
        });
    }

    function processGetClaimDetailOutput(data) {
      vm.claim = data;
      vm.claim.isLateSubmission = vm.claim.requestType === 11;

      vm.uploadConsentClaimFormAttachmentsOnly =
        !vm.isHost && vm.claim.status === 5 && vm.claim.hasPatientTicket;

      vm.showPendingApprovalView =
        !vm.isHost &&
        !vm.claim.hasPatientTicket &&
        vm.claim.status === 1 &&
        vm.claim.requestType === 5 &&
        !vm.claim.hasReviewed &&
        vm.claim.latestQueryStatus !== 1;

      vm.coveredAmount = vm.claim.guaranteeLetter.guaranteedAmount;
      vm.collectAmount = Math.max(
        Math.max(vm.claim.totalClaimableAmount - vm.claim.guaranteeLetter.guaranteedAmount, 0)
      );
    }

    function getFinalRequestDetails() {
      vm.loading += 1;
      healthScreeningClaimSvc
        .getFinalRequestDetails(
          {id: vm.claimNumber}
        )
        .success((data) => {
          vm.currencyCode = data.currencyCode;
          vm.letterNumber = data.guaranteeLetter.letterNumber;

          processGetClaimDetailOutput(data);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getTimeline() {
      if (!vm.claimNumber) return;

      vm.loading += 1;
      healthScreeningClaimSvc
        .getRequestTimeline({
          id: vm.claimNumber,
        })
        .success((data) => {
          vm.timelineItems = data.items;
          _.each(vm.timelineItems, (item) => {
            const d = item;
            switch (d.subject) {
              case App.localize('Submitted'):
                d.icon = 'fas fa-upload text-warning';
                break;
              case App.localize('FullApproval'):
                d.icon = 'far fa-clipboard text-navy';
                break;
              case App.localize('UpdateClaim'):
                d.icon = 'fas fa-edit text-warning';
                break;
              case App.localize('PendingApproval_LateSubmission'):
                d.icon = 'far fa-calendar-exclamation text-danger';
                break;
              case App.localize('Rejected'):
                d.icon = 'fas fa-times-circle text-danger';
                break;
              case App.localize('AdjustmentRequestSubmitted'):
                d.icon = 'fas fa-pencil text-warning';
                break;
              case App.localize('AdjustmentRequestApproved'):
                d.icon = 'fas fa-pencil text-navy';
                break;
              case App.localize('AdjustmentRequestRejected'):
              case App.localize('AdjustmentRequestCancelled'):
                d.icon = 'fas fa-pencil text-danger';
                break;
              case App.localize('MedicalQueryFormSent'):
              case App.localize('MedicalQueryFormReceived'):
                d.icon = 'fas fa-comment text-warning';
                break;
              case App.localize('MedicalQueryFormReplied'):
                d.icon = 'fas fa-comments text-warning';
                break;
              case App.localize('MedicalQueryFormResolved'):
                d.icon = 'fas fa-comment-check text-navy';
                break;

              // no default
            }
          });
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getExceededAmount() {
      if (!vm.claim) return 0;
      const amt = vm.claim.totalClaimableAmount || 0;
      return Math.max(amt - vm.claim.guaranteeLetter.guaranteedAmount, 0);
    }

    function processClaim() {
      $state.go('processFinalHealthScreeningRequest', {
        claimNumber: vm.claimNumber,
        letterNumber: vm.letterNumber,
        timeZoneId: vm.timeZoneId
      });
    }

    function getBillingDetailHistoryHeading(billingDetail) {
      return (
        `${ticketAdjustmentLocalization
        }: ${
        moment(billingDetail.creationTime).format('D MMMM YYYY, h:mm a')}`
      );
    }

    function printLetter(letterLink) {
      window.open(letterLink);
    }

    function viewTicket() {
      $state.go('patientTicketDetail', {
        ticketNumber: vm.claim.ticketNumber,
      });
    }

    // #endregion Define function

    function getDateTimeString(date, timeZoneId) {
      return App.getDateTimeString(date, timeZoneId);
    }

    function getFullDateTimeString(date, timeZoneId) {
      return App.getFullDateTimeString(date, timeZoneId);
    }
  }
})();
