(function () {
    'use strict';

    angular.module('app').component('corporateBenefitGroupsCreateEditPharmacyComponent', {
        templateUrl: require('/App/corporate/views/benefitGroups/createEdit/pharmacy/pharmacy.component.html'),
        controller: controller,
        controllerAs: 'vm',
        bindings: {
            treatmentEditData: '<',
            isTreatmentTreeDirty: '=',
            isReadOnly: '<'
        }
    });

    function controller() {
        var vm = this;
    }
})();

