(() => {
  angular
    .module('app')
    .controller('common.views.saleTransactions.insurerInvoice.page', InsurerInvoicePageController);

  InsurerInvoicePageController.$inject = [
    '$stateParams',
    'currencies',
    'abp.services.app.saleTransaction',
  ];

  function InsurerInvoicePageController($stateParams, currencies, saleTransactionSvc) {
    const vm = this;

    vm.stopInvoiceGeneration = abp.setting.getBoolean(
      'Hms.Feature.StopPortalInvoiceGeneration'
    );

    vm.transactionNumber = $stateParams.transactionNumber;
    vm.invoice = null;
    vm.invoiceFrom = null;
    vm.invoiceTo = null;
    vm.message = null;
    vm.payments = null;
    vm.loading = 0;

    vm.getPaymentLabel = getPaymentLabel;

    init();

    function init() {
      vm.loading += 1;
      saleTransactionSvc
        .getInsurerInvoice({
          id: vm.transactionNumber,
        })
        .success((data) => {
          vm.invoice = data.invoice;
          vm.invoiceFrom = data.invoiceFrom;
          vm.invoiceTo = data.invoiceTo;
          vm.saleTransactionItems = data.invoice.insurerClaims.saleTransactionItems;
          vm.message = data.message;
          vm.insurerClaim = data.invoice.insurerClaims.insurerClaim;
          vm.currencyCode = data.currencyCode;
          vm.payments = data.payments;
          const currency = _.find(currencies, { code: vm.currencyCode });
          vm.decimalPlaces = currency ? currency.decimalPlaces : 2;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getPaymentLabel() {
      return App.localize('XPaymentsMade', vm.payments.length);
    }
  }
})();
