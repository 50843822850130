(function () {
    angular.module('app').component('hostDoraemonCommon13thScheduleSearchComponent', {
        templateUrl: require('/App/host/views/doraemon/common/13thScheduleSearch/13thScheduleSearch.component.html'),
        controller: ['abp.services.app.doraemon', controller],
        controllerAs: 'vm'
    });

    function controller(doraemonSvc) {
        var vm = this;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

        vm.input = {
            filter: '',
            maxResultCount: 5
        };

        vm.getSchedules = function () {
            vm.schedules = [];
            if (vm.input.filter) {
                doraemonSvc.getMedicalFeeSchedules(vm.input).success(function (data) {
                    _.forEach(data.items, function (item) {
                        vm.schedules.push({
                            name: item.item ? item.item : null,
                            category: !_.isNull(item.category) ? item.category : null,
                            subCategory: !_.isNull(item.subCategory) ? item.subCategory : null,
                            minTotalFee: !_.isNull(item.minimumTotalFee) ? item.minimumTotalFee : null,
                            maxTotalFee: !_.isNull(item.maximumTotalFee) ? item.maximumTotalFee : null,
                            minSurgeonFee: !_.isNull(item.minimumSurgeonFee) ? item.minimumSurgeonFee : null,
                            maxSurgeonFee: !_.isNull(item.maximumSurgeonFee) ? item.maximumSurgeonFee : null,
                            minAnaestheticFee: !_.isNull(item.minimumAnaesthetistFee) ? item.minimumAnaesthetistFee : null,
                            maxAnaestheticFee: !_.isNull(item.maximumAnaesthetistFee) ? item.maximumAnaesthetistFee : null,
                            radiologistFeePercentage: !_.isNull(item.radiologistFeePercentage) ?
                                item.radiologistFeePercentage : null
                        });
                    })
                })
            }
        }
    }
})();
