(function () {
    'use strict';

    function controller($filter) {
        var vm = this;
        function init() {
            checkModifiers();
        }
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.coPaySettings = {
            principal: {},
            dependent: {}
        };

        var currencyFilter = $filter('currencyFormat');

        vm.principalAdmission, vm.principalPreHospitalization, vm.principalPostHospitalization, vm.principalSelfPayHospitalization = null;
        vm.dependentAdmission, vm.dependentPreHospitalization, vm.dependentPostHospitalization, vm.dependentSelfPayHospitalization = null;
        vm.showPrincipalPanel = false;
        vm.showDependentPanel = false;

        function checkModifiers() {
            for (var modifiers = 0; modifiers < vm.benefitType.hospitalizationSetting.coPays.length; modifiers++) {
                var currentModifier = vm.benefitType.hospitalizationSetting.coPays[modifiers];

                var copayDescription = currentModifier.isPercentage
                    ? App.localize('CopayPercentageInfo', currentModifier.value)
                    : currencyFilter(currentModifier.value, vm.currencyCode);

                var modifierKeyToBeneficiaryObjKeyMap = {
                    'isAffectingAdmission': 'admission',
                    'isAffectingPreHospitalization': 'preHospitalization',
                    'isAffectingPostHospitalization': 'postHospitalization',
                    'isAffectingSelfPayHospitalization': 'selfPay'
                };

                var beneficiaryObjects = [];
                var suffix = '';
                switch (currentModifier.beneficiary) {
                    case 1:
                        beneficiaryObjects.push(vm.coPaySettings.principal);
                        vm.showPrincipalPanel = true;
                        break;

                    case 2:
                        beneficiaryObjects.push(vm.coPaySettings.dependent);
                        suffix = ' [2]';
                        vm.showDependentPanel = true;
                        break;

                    case 3:
                        beneficiaryObjects.push(vm.coPaySettings.principal);
                        beneficiaryObjects.push(vm.coPaySettings.dependent);
                        suffix = ' [1]';
                        vm.showPrincipalPanel = true;
                        vm.showDependentPanel = true;
                        break;
                }

                _.forEach(beneficiaryObjects, function (beneficiaryObj) {
                    _.forIn(modifierKeyToBeneficiaryObjKeyMap, function (beneficiaryObjKey, modifierKey) {
                        if (currentModifier[modifierKey]) {
                            beneficiaryObj[beneficiaryObjKey] = copayDescription + suffix;
                        }
                    });
                });
            }
        }

        init();
    }

    angular.module('app').component('corporateBenefitGroupsInpatientBenefitDetailCopaymentComponent', {
        templateUrl: require('/App/corporate/views/benefitGroups/inpatient/benefitDetail/copayment/copayment.component.html'),
        controller: ['$filter', controller],
        controllerAs: 'vm',
        bindings: {
            benefitType: '<'
        }
    });
})();
