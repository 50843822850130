(() => {
  angular
    .module('app')
    .controller('common.views.specialistClaims.processClaim.successModal', SuccessModalController);

  SuccessModalController.$inject = ['$uibModalInstance', 'claimOutput', 'claimNumber'];

  function SuccessModalController($uibModalInstance, claimOutput, claimNumber) {
    const vm = this;

    vm.claimNumber = claimNumber;
    vm.finalClaimableAmount = claimOutput.finalClaimableAmount;
    vm.finalGuaranteedAmount = claimOutput.finalGuaranteedAmount;
    vm.coveredAmount = claimOutput.coveredAmount;
    vm.coPayAmount = claimOutput.coPayAmount;

    vm.close = close;

    init();

    function init() {
      if (!_.isNil(claimOutput.topUpResult)) {
        vm.initialGuaranteeAmount = claimOutput.topUpResult.initialGuaranteeAmount;
        vm.requestTopUpAmount = claimOutput.topUpResult.requestTopUpAmount;
        vm.approvedTopUpAmount = claimOutput.topUpResult.approvedTopUpAmount;
        vm.topUpRequestStatus = claimOutput.topUpResult.status;
        vm.remarks = claimOutput.topUpResult.topUpRejectionRemarks;
      }
    }

    function close() {
      $uibModalInstance.close();
    }
  }
})();
