(function () {
    var controllerId = 'corporate.views.mcDaysReport.index';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', '$uibModal', 'abp.services.app.report', 'abp.services.app.commonLookup', 'appSession', 'Hms.PatientTickets.PatientTicketType',
        'moment',
        function ($scope, $state, $stateParams, $uibModal, reportSvc, commonLookupSvc, appSession, enumPatientTicketType, moment) {
            function init() {
                initServiceTypes();
                findEmployeeFields();
                vm.lookupClinics(null, $stateParams.clinic);
                vm.lookupClinicLocations(null, $stateParams.clinicLocation);
                lookupBenefitGroups();
                lookupSubsidiaries();
                vm.getMcDaysReport();
            }

            var vm = this;
            vm.hasPreEmploymentModule = abp.features.isEnabled('HasPreEmploymentModule');
            vm.hasSpecialistModule = abp.features.isEnabled('Specialist.Enabled');
            vm.hasPharmacyModule = abp.features.isEnabled('Pharmacy.Enabled');
            vm.hasInpatientModule = abp.features.isEnabled('HasInpatientModule');

            vm.isSubsidiary = appSession.user.subsidiaryId;
            vm.isSubsidiaryDepartment = appSession.user.subsidiaryDepartmentId;
            vm.financialYears = _.range(appSession.tenant.currentFinancialYear, appSession.tenant.firstFinancialYear - 1, -1);
            vm.exporting = 0;
            vm.hasInsuranceModule = abp.setting.getBoolean('Hms.Feature.InsuranceModule')
                && abp.features.isEnabled('HasInsurance');

            vm.enums = {};
            vm.enums.patientTicketType = enumPatientTicketType;

            $scope.$on('$viewContentLoaded', function () {
                App.initAjax();
            });

            vm.employeeFields = [];
            function findEmployeeFields() {
                commonLookupSvc.findEmployeeFields().success(function (data) {
                    if (data.items) {
                        vm.employeeFields = data.items;
                    }
                });
            }

            vm.clinics = [];
            vm.lookupClinics = function (search, id) {
                if (search || id) {
                    commonLookupSvc.findPatientTicketClinics({
                        id: id,
                        filter: search,
                        maxResultCount: 5
                    }).success(function (data) {
                        vm.clinics = data.items;
                    }).finally(function () {
                        if (!vm.clinics.length)
                            vm.requestParams.clinic = null;
                    });
                }
            };

            vm.clinicLocations = [];
            vm.lookupClinicLocations = function (search, id) {
                if (search || id) {
                    commonLookupSvc.findPatientTicketClinicLocations({
                        clinic: vm.requestParams.clinic,
                        id: id,
                        filter: search,
                        maxResultCount: 5
                    }).success(function (data) {
                        vm.clinicLocations = data.items;
                    }).finally(function () {
                        if (!vm.clinicLocations.length)
                            vm.requestParams.clinicLocation = null;
                    });
                }
            };

            vm.benefitGroups = [];
            function lookupBenefitGroups() {
                commonLookupSvc.findBenefitGroups({
                    maxResultCount: 999
                }).success(function (data) {
                    vm.benefitGroups = data.items;
                });
            }

            vm.subsidiaries = [];

            function lookupSubsidiaries() {
                commonLookupSvc.getCorporateSubsidiaries({ getDeletedSubsidiariesOrDepartment: true }).success(function (data) {
                    vm.subsidiaries = data.items;
                });
            }

            vm.departments = [];
            var allDepartments = [];
            vm.getCorporateSubsidiaryDepartments = function () {
                if (!allDepartments.length) {
                    commonLookupSvc.getCorporateSubsidiaryDepartments({ getDeletedSubsidiariesOrDepartment: true }).success(function (data) {
                        allDepartments = data.items;
                    });
                }
            };

            vm.getDepartments = function (search) {
                var subsidiaryIds = vm.isSubsidiary
                    ? appSession.user.subsidiaryId
                    : vm.requestParams.subsidiaryIds;

                if (subsidiaryIds.length > 0) {
                    vm.departments = [];

                    // Change department filter list according to selected subsidiaries.

                    _.forEach(subsidiaryIds, function (id) {
                        var d = _.filter(allDepartments, { 'subsidiaryId': id });
                        vm.departments = _.concat(vm.departments, d);
                    });
                } else {
                    vm.departments = allDepartments;
                }

                if (search) {
                    vm.departments = _.filter(vm.departments, function (d) {
                        return _.includes(d.name.toLowerCase(), search.toLowerCase())
                    });

                    // Only return top 10 in the department list for display.

                    vm.departments = _.slice(vm.departments, 0, 10);
                } else {
                    vm.departments = [];
                }
            };

            vm.resetDepartments = function () {
                vm.requestParams.departmentIds = [];

                vm.departments = [];
            };

            vm.serviceTypes = [];
            function initServiceTypes() {
                reportSvc.getServiceTypes().success(function (data) {
                    vm.serviceTypes = _.filter(data, function (d) {
                        // Hack: To filter out Hospitalization (Service type 7) and Maternity (service type 8).
                        return d.value !== 7 && d.value !== 8;
                    });
                });
            }

            vm.isClinic = App.isClinic();

            vm.loading = 0;
            vm.requestParams = {
                ticketType: $stateParams.ticketType,
                employeeType: $stateParams.employeeType,
                type: $stateParams.type,
                leaveType: $stateParams.leaveType || 0,
                clinicLocation: $stateParams.clinicLocation,
                serviceType: $stateParams.serviceType,
                employee: $stateParams.employee,
                nationalId: $stateParams.nationalId,
                employeeNumber: $stateParams.employeeNumber,
                benefitGroupIds: $stateParams.benefitGroupIds,
                subsidiaryIds: $stateParams.subsidiaryIds || [],
                departmentIds: $stateParams.departmentIds || [],
                gender: $stateParams.gender,
                financialYear: $stateParams.financialYear || appSession.tenant.currentFinancialYear,
                startDate: $stateParams.startDate,
                endDate: $stateParams.endDate
            };

            vm.advancedFiltersAreShown = vm.requestParams.clinicLocation || vm.requestParams.employee || vm.requestParams.nationalId
                || vm.requestParams.employeeNumber || vm.requestParams.benefitGroupIds || vm.requestParams.subsidiaryIds == []
                || vm.requestParams.departmentIds == [] || vm.requestParams.gender;

            vm.dateRangeOptions = _.map(vm.financialYears, function (d) {
                var opt = App.createDateRangePickerOptions();
                var minDate = moment([d, appSession.tenant.firstFinancialMonth - 1, 1]);
                var maxDate = moment.min(moment([d + 1, appSession.tenant.firstFinancialMonth - 1, 1]).subtract(1, 'days'), moment(opt.maxDate));
                opt.minDate = moment(minDate);
                opt.min = moment(minDate);
                opt.maxDate = moment(maxDate);
                opt.max = moment(maxDate);
                opt.financialYear = d;
                return opt;
            });

            vm.dateRangeModel = {};

            vm.fyChanged = function () {
                var opt = _.find(vm.dateRangeOptions, { 'financialYear': vm.requestParams.financialYear });
                vm.dateRangeModel.startDate = moment(opt.minDate);
                vm.dateRangeModel.endDate = moment(opt.maxDate);
            };

            vm.fyChanged();

            if ($stateParams.startDate || $stateParams.endDate) {
                var opt = _.find(vm.dateRangeOptions, { 'financialYear': vm.requestParams.financialYear });
                if ($stateParams.startDate) {
                    vm.dateRangeModel.startDate = moment.max(moment.min(moment($stateParams.startDate), opt.maxDate), opt.minDate);
                }
                if ($stateParams.endDate) {
                    vm.dateRangeModel.endDate = moment.min(moment.max(moment($stateParams.endDate), opt.minDate), opt.maxDate);
                }
            }

            vm.gridOptionsMedical = {
                appScopeProvider: vm,
                rowHeight: 80,
                columnDefs: [{
                    displayName: App.localize('Name'),
                    field: 'name',
                    minWidth: 150,
                    cellTemplate: 'nameTemplate'
                }, {
                    displayName: App.localize('SickLeaveDays'),
                    field: 'totalSickDays',
                    minWidth: 80,
                    cellTemplate: 'sickLeaveTemplate'
                }, {
                    displayName: App.localize('MedicalLeaveDays'),
                    field: 'mcDays',
                    minWidth: 80,
                    cellTemplate: 'medicalLeaveTemplate'
                }, {
                    displayName: App.localize('CarryOverDays'),
                    headerCellTemplate: 'carryOverDaysHeaderTemplate',
                    field: 'carryOverDays',
                    minWidth: 160,
                    cellTemplate: 'carryOverDaysTemplate'
                }, {
                    displayName: moment().day(1).format('ddd'),
                    field: 'monday',
                    minWidth: 80
                }, {
                    displayName: moment().day(2).format('ddd'),
                    field: 'tuesday',
                    minWidth: 80
                }, {
                    displayName: moment().day(3).format('ddd'),
                    field: 'wednesday',
                    minWidth: 80
                }, {
                    displayName: moment().day(4).format('ddd'),
                    field: 'thursday',
                    minWidth: 80
                }, {
                    displayName: moment().day(5).format('ddd'),
                    field: 'friday',
                    minWidth: 80
                }, {
                    displayName: moment().day(6).format('ddd'),
                    field: 'saturday',
                    minWidth: 80
                }, {
                    displayName: moment().day(0).format('ddd'),
                    field: 'sunday',
                    minWidth: 80
                }],
                data: []
            };

            vm.gridOptionsHospitalization = {
                appScopeProvider: vm,
                rowHeight: 80,
                columnDefs: [{
                    displayName: App.localize('Name'),
                    field: 'name',
                    minWidth: 150,
                    cellTemplate: 'nameTemplate'
                }, {
                    displayName: App.localize('SickLeaveDays'),
                    field: 'totalSickDays',
                    minWidth: 80,
                    cellTemplate: 'sickLeaveTemplate'
                }, {
                    displayName: App.localize('HospitalizationLeaveDays'),
                    field: 'hospitalizationMcDays',
                    minWidth: 80,
                    cellTemplate: 'hospitalizationLeaveTemplate'
                }, {
                    displayName: App.localize('CarryOverDays'),
                    headerCellTemplate: 'carryOverDaysHeaderTemplate',
                    field: 'carryOverDays',
                    minWidth: 160,
                    cellTemplate: 'carryOverDaysTemplate'
                }, {
                    displayName: moment().day(1).format('ddd'),
                    field: 'monday',
                    minWidth: 80
                }, {
                    displayName: moment().day(2).format('ddd'),
                    field: 'tuesday',
                    minWidth: 80
                }, {
                    displayName: moment().day(3).format('ddd'),
                    field: 'wednesday',
                    minWidth: 80
                }, {
                    displayName: moment().day(4).format('ddd'),
                    field: 'thursday',
                    minWidth: 80
                }, {
                    displayName: moment().day(5).format('ddd'),
                    field: 'friday',
                    minWidth: 80
                }, {
                    displayName: moment().day(6).format('ddd'),
                    field: 'saturday',
                    minWidth: 80
                }, {
                    displayName: moment().day(0).format('ddd'),
                    field: 'sunday',
                    minWidth: 80
                }],
                data: []
            };

            var reportTemplateName = '';
            var reportFields = [];
            vm.getMcDaysReport = function () {
                registerStateParams();
                vm.loading++;
                reportSvc.getMcDaysReport(getRequestInput()).success(function (data) {
                    if (vm.requestParams.leaveType === 1)
                        vm.gridOptionsHospitalization.data = data.items;
                    else
                        vm.gridOptionsMedical.data = data.items;

                    reportTemplateName = data.reportTemplateName;
                    reportFields = data.reportFields;
                }).finally(function (e) {
                    vm.loading--;
                });
            };

            vm.tabChanged = function (leaveType) {
                vm.requestParams.leaveType = leaveType;

                if (leaveType === 1)
                    vm.requestParams.serviceType = 7;
                else
                    vm.requestParams.serviceType = null;

                // Refresh McDays report when switching tab.
                vm.getMcDaysReport();
            }

            function getEmployeeFieldFilters() {
                var employeeFieldFilters = [];
                _.each(vm.employeeFields, function (f) {
                    if (f.filter) {
                        var filter = _.trim(f.filter);
                        if (filter) {
                            employeeFieldFilters.push({
                                name: filter,
                                value: f.value
                            });
                        }
                    }
                });
                return { employeeFields: employeeFieldFilters };
            }

            function getRequestInput(selectedFields) {
                var input = $.extend({}, vm.requestParams, vm.dateRangeModel, getEmployeeFieldFilters(), { reportFields: selectedFields });
                input.startDate = vm.dateRangeModel.startDate.format('YYYY-MM-DD');
                input.endDate = vm.dateRangeModel.endDate.format('YYYY-MM-DD');
                return _.omitBy(input, _.isNil);
            }

            function registerStateParams() {
                var startDate = null;
                var endDate = null;
                if (vm.dateRangeModel.startDate)
                    startDate = vm.dateRangeModel.startDate.format('YYYY-MM-DD');
                if (vm.dateRangeModel.endDate)
                    endDate = vm.dateRangeModel.endDate.format('YYYY-MM-DD');

                $state.transitionTo($state.current,
                    {
                        ticketType: vm.requestParams.ticketType,
                        employeeType: vm.requestParams.employeeType,
                        type: vm.requestParams.type,
                        leaveType: vm.requestParams.leaveType,
                        clinicLocation: vm.requestParams.clinicLocation,
                        employee: vm.requestParams.employee,
                        nationalId: vm.requestParams.nationalId,
                        employeeNumber: vm.requestParams.employeeNumber,
                        benefitGroupIds: vm.requestParams.benefitGroupIds,
                        subsidiaryIds: vm.requestParams.subsidiaryIds,
                        serviceType: vm.requestParams.serviceType,
                        departmentIds: vm.requestParams.departmentIds,
                        gender: vm.requestParams.gender,
                        financialYear: vm.requestParams.financialYear,
                        startDate: startDate,
                        endDate: endDate
                    }, {
                    notify: false
                });
            }

            vm.exportToExcel = function (option) {
                option = option || getRequestInput();

                vm.exporting++;
                reportSvc.getMcDaysReportToExcel(option).success(function (data) {
                    App.downloadTempFile(data);
                }).finally(function () {
                    vm.exporting--;
                });
            };

            vm.export = function (type) {
                $uibModal.open({
                    templateUrl: require('/App/common/views/common/selectionModal.html'),
                    controller: "common.views.common.selectionModal as vm",
                    backdrop: "static",
                    resolve: {
                        templateName: function () {
                            return reportTemplateName;
                        },
                        fields: function () {
                            return reportFields;
                        },
                        modalTitle: function () {
                            return App.localize('SelectReportFields');
                        }
                    }
                }).result.then(function (selectedFields) {
                    var option = getRequestInput(selectedFields);

                    if (type === 0)
                        window.open(App.formatUrl('Print/McDaysReport?', option));
                    else
                        vm.exportToExcel(option);
                });
            };

            init();
        }
    ]);
})();
