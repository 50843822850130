(() => {
  angular.module('app').component('commonComponentsDataExportTableWidget', {
    templateUrl: require('./widget.html'),
    controller: DataExportTableWidgetController,
    controllerAs: 'vm',
    bindings: {
      reportName: '<?',
      refresh: '=?',
      generateButtonType: '<?',
      showParameter: '<?',
      lastRefreshTime: '=?'
    },
  });

  DataExportTableWidgetController.$inject = [
    '$scope',
    '$interval',
    '$uibModal',
    'abp.services.app.dataExport',
    'Hms.DataExports.DataExportRecordStatus',
    'moment'
  ];

  function DataExportTableWidgetController(
    $scope,
    $interval,
    $modal,
    dataExportSvc,
    enumDataExportRecordStatus,
    moment
  ) {
    const vm = this;
    const DEFAULT_MAX_COUNT = 5;

    vm.loading = 0;
    vm.downloading = 0;
    vm.isSilentLoading = 0;
    vm.narrativeMessage = App.localize('DataExportTableRecordEmptyWarning', vm.generateButtonType);

    vm.requestParams = {
      name: vm.reportName,
      skipCount: 0,
      maxResultCount: DEFAULT_MAX_COUNT
    };

    vm.enums = {
      dataExportRecordStatus: enumDataExportRecordStatus,
    };

    vm.downloadAttachment = downloadAttachment;
    vm.refresh = refresh;
    vm.intervalRefresh = intervalRefresh;
    vm.viewParameters = viewParameters;

    class PagedData {
      // Properties

      constructor() {
        this.totalDescription = '';
        this.currentPage = 1;
        this.maxPages = 0;

        this.totalCount = 0;
        this.records = [];

        this.requestParams = {
          name: vm.reportName,
          skipCount: 0,
          maxResultCount: DEFAULT_MAX_COUNT,
        };

        this.initialLoad();
      }

      // Page navigations

      goToPage(page) {
        let p = page;

        if (!p || p < 1) {
          p = 1;
        } else if (p > this.maxPages) {
          p = this.maxPages;
        }

        this.currentPage = p;
        const skipCount = (this.currentPage - 1) * DEFAULT_MAX_COUNT;
        this.requestParams.skipCount = skipCount;
        this.getDataExportRecords();
      }

      nextPage() {
        this.goToPage(this.currentPage + 1);
      }

      prevPage() {
        this.goToPage(this.currentPage - 1);
      }

      refreshPage() {
        this.goToPage(this.currentPage);
      }

      // Set table configurations.

      setItemsPerPage() {
        this.maxPages = Math.ceil(this.totalCount / DEFAULT_MAX_COUNT);

        // To reset the current page number.

        if (this.maxPages === 0) {
          this.currentPage = 0;
        } else if (this.maxPages !== 0 && this.currentPage === 0) {
          this.currentPage = 1;
        }
      }

      // Refresh table.

      initialLoad() {
        this.getDataExportRecords();
      }

      // Retrieve data export record.

      getDataExportRecords() {
        vm.loading += 1;
        dataExportSvc
          .getDataExportRecords(this.requestParams)
          .success((data) => {
            this.records = data.items;
            this.totalCount = data.totalCount;
            this.totalDescription = App.localize('TotalXItems', this.totalCount);
            this.setItemsPerPage();
          })
          .finally(() => {
            vm.loading -= 1;
            vm.isSilentLoading = 0;
          });
      }
    }

    init();

    function init() {
      vm.table = new PagedData();
    }

    function downloadAttachment(id) {
      vm.downloading += 1;

      dataExportSvc
        .downloadDataExport({
          Id: id,
        })
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.downloading -= 1;
        });
    }

    function refresh() {
      if (vm.loading || vm.isSilentLoading) return;

      if (moment().diff(vm.lastRefreshTime, 'seconds') < 10) return;

      vm.lastRefreshTime = moment();
      vm.table.getDataExportRecords();
    }

    function intervalRefresh() {
      if (vm.loading || vm.isSilentLoading) return;
      vm.isSilentLoading += 1;
      vm.table.getDataExportRecords();
    }

    function viewParameters(parameters) {
      $modal
        .open({
          templateUrl: require('../../../modals/jsonPrettifier/jsonPrettifier.modal.html'),
          controller: 'common.view.modals.jsonPrettifierModal as vm',
          backdrop: 'static',
          resolve: {
            title() {
              return App.localize('Parameters');
            },
            jsonString() {
              return parameters;
            },
          },
        })
        .result.then(() => {
          // EMPTY
        });
    }

  }
})();
