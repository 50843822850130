(function () {
    var componentId = 'hostClinicsPanelBankingEditorComponent';
    var app = angular.module('app');

    function controller($scope, clinicSvc, commonLookupSvc, constsPaymentAccount, constsTenant) {
        function init() {
            getBanks();
        }

        var vm = this;
        vm.constsPaymentAccount = constsPaymentAccount;
        vm.constsTenant = constsTenant;
        vm.loading = 0;
        vm.saving = 0;
        vm.isCreate = false;
        vm.isEditing = false;

        vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');

        vm.permissions = {
            editBanking: abp.auth.isGranted("Host.Clinics.Edit.Finance")
        };

        vm.$onInit = function () {
            vm.container.addWidget(this);
        };

        vm.$onChanges = function (changes) {
            vm.isCreate = vm.data ? !vm.data.id : false;

            if (changes.accountOwnerCountryCode && changes.accountOwnerCountryCode.currentValue) {
                filterBanks(changes.accountOwnerCountryCode.currentValue);
            }
        };

        vm.isDirty = function () {
            return $scope.panelCreateEditBankingForm && $scope.panelCreateEditBankingForm.$dirty;
        };

        vm.setPristine = function () {
            $scope.panelCreateEditBankingForm.$setPristine();
        };

        function filterBanks(countryCode) {
            vm.filteredBanks = vm.hasRegionalSystemSupport
                ? _.filter(vm.banks, function (b) {
                    return b.countryCode == countryCode;
                })
                : vm.banks;
        }

        function getBanks() {
            vm.loading++;
            commonLookupSvc.getBanks().success(function (data) {
                vm.banks = data.items;
                filterBanks(vm.accountOwnerCountryCode);

            }).finally(function () {
                vm.loading--;
            });
        }

        // Force validation on this form.
        // True if valid, otherwise, false.

        vm.validateForm = function (error) {
            var form = $scope.panelCreateEditBankingForm;
            App.touchFormErrors(form);
            error = !form.$valid;
        };

        // Generate the payload from this form to send to ClinicAppService API.

        vm.getPayload = function (settings) {
            _.extend(settings, {
                banking: vm.data
            });
        };

        // Ask container to save this widget.

        vm.save = function () {
            vm.container.saveWidget(vm);
        };

        init();
    }

    app.component(componentId, {
        require: {
            container: '^hostClinicsPanelEditorContainerComponent'
        },
        bindings: {
            data: '<',
            accountOwnerCountryCode: '<'
        },
        templateUrl: require('/App/host/views/clinics/panelEditor/panelBankingEditor/panelBankingEditor.component.html'),
        controller: ['$scope', 'abp.services.app.clinic', 'abp.services.app.commonLookup', 'Hms.PaymentAccounts.PaymentAccount', 'Hms.MultiTenancy.Tenant', controller],
        controllerAs: 'vm'
    });
})();
