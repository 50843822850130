(() => {
  angular
    .module('app')
    .controller('common.views.signatureProfiles.index', SignatureProfilesPageController);

  SignatureProfilesPageController.$inject = ['$uibModal', 'abp.services.app.signatureProfile'];

  function SignatureProfilesPageController($modal, signatureProfileSvc) {
    const vm = this;

    vm.corporateCode = null;
    vm.loading = 0;
    vm.setCorporateCode = setCorporateCode;
    vm.permissions = {
      create: abp.auth.isGranted('SignatureProfiles.Host.Create'),
      update: abp.auth.isGranted('SignatureProfiles.Host.Update'),
      delete: abp.auth.isGranted('SignatureProfiles.Host.Delete'),
    };

    vm.getSignatureProfiles = getSignatureProfiles;
    vm.createProfile = createProfile;
    vm.editProfile = editProfile;
    vm.deleteProfile = deleteProfile;

    init();

    function init() {
      vm.getSignatureProfiles();
    }

    function getSignatureProfiles() {
      vm.loading += 1;
      signatureProfileSvc
        .getSignatureProfiles({
          userId: vm.userId,
        })
        .success((data) => {
          vm.records = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });

      vm.loading += 1;
      signatureProfileSvc
        .getCorporates()
        .success((data) => {
          vm.corporates = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    /* Lookup Users */

    function setCorporateCode() {
      vm.userId = null;
      vm.users = [];
      if (vm.corporateCode) {
        vm.records = [];
        lookupUsers();
      } else {
        vm.getSignatureProfiles();
      }
    }

    function lookupUsers() {
      vm.loading += 1;
      signatureProfileSvc
        .findUsers({
          id: vm.corporateCode,
        })
        .success((data) => {
          vm.users = data ? data.items : [];
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    /* End of Lookup Users */

    function createProfile() {
      if (vm.userId) showCreateEditModal();
    }

    function editProfile(profile, userId) {
      showCreateEditModal(profile.id, userId);
    }

    function showCreateEditModal(id, userId) {
      $modal
        .open({
          templateUrl: require('./createOrEditModal.html'),
          controller: 'common.views.signatureProfiles.createOrEditModal as vm',
          backdrop: 'static',
          resolve: {
            profileId: id,
            userId: userId || vm.userId,
          },
        })
        .result.then(() => {
          vm.getSignatureProfiles();
        });
    }

    function deleteProfile(profile) {
      abp.message.confirm(
        App.localize('SignatureProfileDeleteWarningMessage', profile.name),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            signatureProfileSvc
              .deleteSignatureProfile({
                id: profile.id,
              })
              .success(() => {
                vm.getSignatureProfiles();
                abp.notify.success(App.localize('SuccessfullyDeleted'));
              });
          }
        }
      );
    }
  }
})();
