/* global google */

((function () {
  angular
    .module('app')
    .directive('hmsGmapAutocomplete', hmsGmapAutocompleteDirective);

  hmsGmapAutocompleteDirective.$inject = ['uiGmapGoogleMapApi'];

  function hmsGmapAutocompleteDirective(GoogleMapApi) {
    const directive = {
        link,
        restrict: 'A',
        require: 'ngModel',
        scope: {
          options: '=',
          callback: '=',
        },
    };
    return directive;

    function link(scope, element, _attrs, ngModel) {
      ngModel.$parsers.push((val) => (val === null || val === '' ? null : val));
      ngModel.$formatters.push((val) => (val === null ? '' : `${val}`));
      return GoogleMapApi.then(
        (() => () => {
          const autoComplete = new google.maps.places.Autocomplete(element[0], scope.options);
          if (scope.callback) {
            autoComplete.addListener('place_changed', () => {
              const place = autoComplete.getPlace();
              scope.$apply(() => {
                scope.callback(place);
              });
            });
          }
        })()
      );
    }
  }
})());
