(() => {
  angular.module('app').factory('common.integrations.zendesk', ZendeskFactory);

  ZendeskFactory.$inject = ['$window', 'abp.services.app.session'];

  function ZendeskFactory($window, sessionSvc) {
    const zendesk = {
      initialize,
      prefill,
      identify,
    };

    return zendesk;

    // Initialize Zendesk widget.

    function initialize(zendeskWebWidgetToken) {
      try {
        if (!$window.zESettings) {
          $window.zESettings = {
            webWidget: {
              launcher: {
                chatLabel: {
                  '*': 'Chat with us',
                },
              },
              helpCenter: {
                originalArticleButton: false,
                searchPlaceholder: {
                  '*': 'Search our Help Center',
                },
              },
              authenticate: {
                jwt: zendeskWebWidgetToken,
                chat: {
                  jwtFn (callback) {
                    sessionSvc.getZendeskChatToken().success((data) => {
                      if (data) {
                        callback(data);
                      }
                    });
                  },
                },
              },
              color: {
                launcher: '#059bb6', // This will also update the badge
                launcherText: '#FFFFFF',
                button: '#059bb6',
                resultLists: '#000000',
                header: '#059bb6',
                articleLinks: '#000000',
              },
            },
          };
        }
      } catch (err) {
        console.error(err);
      }
    }

    // Prefill Zendesk widget form.

    function prefill(name, emailAddress, organizationName) {
      try {
        if ($window.zE) {
          $window.zE('webWidget', 'prefill', {
            name: {
              value: name,
              readOnly: true, // optional
            },
            email: {
              value: emailAddress,
              readOnly: true, // optional
            },
            organization: {
              value: organizationName,
              readOnly: true,
            },
          });
        }
      } catch (err) {
        console.error(err);
      }
    }

    // Identify an end user to Zendesk.
    // If the user's email doesn't already exist in Zendesk Support account,
    // a new user record with the details is created.
    // ref: https://developer.zendesk.com/embeddables/docs/widget/core#identify

    function identify(name, emailAddress, organizationName) {
      try {
        if ($window.zE) {
          $window.zE('webWidget', 'identify', {
            name,
            email: emailAddress,
            organization: organizationName,
          });
        }
      } catch (err) {
        console.error(err);
      }
    }
  }
})();

