(function () {
    var controllerId = 'host.views.agingReport.index';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', '$uibModal', 'abp.services.app.report',
        function ($scope, $state, $stateParams, $uibModal, reportSvc) {
            function init() {
                vm.getAgingReport();
            }

            var vm = this;

            $scope.$on("$viewContentLoaded", function () {
                App.initAjax();
            });

            vm.loading = false;
            vm.advancedFiltersAreShown = false;
            vm.requestParams = {
                companyType: $stateParams.companyType || "corporate",
                minimumAging: +$stateParams.minimumAging
            };
            if (isNaN(vm.requestParams.minimumAging)) {
                vm.requestParams.minimumAging = 0;
            }
            vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

            vm.data = {};
            vm.agingRemarks = [];
            vm.agingOptions = [-1, 0, 16, 31, 46, 61];

            vm.getAgingDescription = function (d) {
                if (d === -1) return App.localize('All');
                if (d === 0) return App.localize('Overdue');
                return ">" + (d - 1);
            };

            vm.getAgingReport = function () {
                registerStateParams();
                vm.loading = true;

                reportSvc.getAgingReport(vm.requestParams)
                    .success(function (data) {
                        vm.data = data;

                        var agingRemarks = [];
                        for (var i = 0; i < data.agingSteps; i++) {
                            if (i == data.agingSteps - 1) {
                                agingRemarks.push(">" + (i * data.agingInterval));
                            } else {
                                var sd = i * data.agingInterval;
                                if (i > 0) sd++;
                                agingRemarks.push("" + sd + "-" + ((i + 1) * data.agingInterval));
                            }
                        }
                        vm.agingRemarks = agingRemarks;
                    }).finally(function (e) {
                        vm.loading = false;
                    });
            };

            function registerStateParams() {
                $state.transitionTo($state.current, {
                    companyType: vm.requestParams.companyType,
                    minimumAging: vm.requestParams.minimumAging
                }, {
                        notify: false
                    });
            }

            init();
        }
    ]);
})();