(function () {
    angular
        .module('app')
        .controller('host.views.insuranceClaims.details.page', ['$stateParams', controller]);

    function controller($stateParams) {
        var vm = this;
        vm.loading = 0;
        vm.ticketNumber = $stateParams.ticketNumber;
        vm.insuranceClaimId = $stateParams.id;
        vm.activeTab = $stateParams.tab ? $stateParams.tab : 0;
    }
})();
