(() => {
  angular.module('app').component('commonInpatientNprClaimsDetailsAuditHistoryComponent', {
    templateUrl: require('./auditHistory.component.html'),
    controller: AuditHistoryController,
    controllerAs: 'vm',
    bindings: {},
  });

  AuditHistoryController.$inject = [
    '$stateParams',
    'abp.services.app.nprClaim',
    'Hms.Claims.ClaimStatus',
  ];

  function AuditHistoryController($stateParams, nprClaimSvc, enumClaimStatus) {
    const vm = this;

    vm.claimHistories = [];
    vm.loading = 0;

    vm.enums = {
      claimStatus: enumClaimStatus,
    };

    init();

    function init() {
      getClaim();
    }

    function getClaim() {
      vm.loading += 1;
      nprClaimSvc
        .getClaimHistory({
          id: $stateParams.claimNumber,
        })
        .success((data) => {
          vm.claimHistories = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
