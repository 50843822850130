(() => {
  angular.module('app').filter('dataConsentIntention', dataConsentIntention);

  dataConsentIntention.$inject = [];

  function dataConsentIntention() {
    return dataConsentIntentionFilter;

    function dataConsentIntentionFilter(value) {
      switch (value) {
        case 'Subsidiary.Initial':
          return App.localize('InitialSubsidiaryBlanketConsent');
        case 'Subsidiary.Grant':
          return App.localize('SubsidiaryBlanketConsentGranted');
        case 'Subsidiary.Revoke':
          return App.localize('SubsidiaryBlanketConsentRevoked');
        case 'Delegate.Grant':
          return App.localize('DelegateDataConsentGranted');
        case 'Delegate.Revoke':
          return App.localize('DelegateDataConsentRevoked');
        case 'Individual.Grant':
          return App.localize('IndividualDataConsentGranted');
        case 'Individual.Revoke':
          return App.localize('IndividualDataConsentRevoked');
        case 'Principal.Grant':
          return App.localize('PrincipalDataConsentGranted');
        case 'Principal.Revoke':
          return App.localize('PrincipalDataConsentRevoked');
        default:
          return value;
      }
    }
  }
})();
