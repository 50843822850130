(() => {
  angular.module('finance').component('panelClaimInvoiceDetailComponent', {
    templateUrl: require('./panelClaim.component.html'),
    controller: PanelClaimInvoiceDetailComponent,
    controllerAs: 'vm',
    bindings: {
      currencyCode: '<',
      invoice: '<',
      invoiceFrom: '<',
      invoiceTo: '<',
      isAddressInvoiceFrom: '<',
      isAddressInvoiceTo: '<',
      message: '<',
      getDateString: '<'
    },
  });

  PanelClaimInvoiceDetailComponent.$inject = [];

  function PanelClaimInvoiceDetailComponent() {
    const vm = this;

    vm.loading = 0;

    init();

    function init() {}
  }
})();
