(() => {
  angular
    .module('finance')
    .controller(
      'manualTransactionSetupCreateEditBankAccountDetails',
      ManualTransactionSetupCreateEditBankAccountDetailsController
    );

  ManualTransactionSetupCreateEditBankAccountDetailsController.$inject = [
    'isCreate',
    '$scope',
    '$uibModalInstance',
    'abp.services.finance.manualBankAccount',
    'abp.services.finance.commonLookup',
    'Hms.Finance.Banks.PaymentMode',
    'bankAccountId',
    'ownerId',
    'countryCode',
  ];

  function ManualTransactionSetupCreateEditBankAccountDetailsController(
    isCreate,
    $scope,
    $uibModalInstance,
    manualBankAccountSvc,
    commonLookupSvc,
    enumPaymentMode,
    bankAccountId,
    ownerId,
    countryCode
  ) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;
    vm.isCreate = isCreate;
    vm.bankAccountDetails = null;

    vm.save = save;
    vm.cancel = cancel;
    vm.getBanks = getBanks;

    init();

    function init() {
      vm.loading += 1;

      getBanks();

      manualBankAccountSvc
        .getBankAccount({ id: bankAccountId, ownerId: ownerId })
        .success((data) => {
          vm.bankAccountDetails = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function save() {
      const isValid = App.isFormValid($scope.createEditBankAccountDetails);
      if (isValid) {
        vm.saving += 1;
        if (vm.isCreate) {
          // Create new manual bank account.

          manualBankAccountSvc
            .createBankAccount(vm.bankAccountDetails)
            .success(() => {})
            .finally(() => {
              vm.saving -= 1;
              $uibModalInstance.close();
            });
        } else {
          // Edit bank account details.

          manualBankAccountSvc
            .updateBankAccount(vm.bankAccountDetails)
            .success(() => {})
            .finally(() => {
              vm.saving -= 1;
              $uibModalInstance.close();
            });
        }
      }
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function getBanks() {
      commonLookupSvc
        .getBanks({ filter: countryCode })
        .success((data) => {
          if (data) {
            localizeBankPaymentModes(data.items);
            vm.banks = data.items;
          }
        })
        .finally(() => {});
    }

    function localizeBankPaymentModes(data) {
      _.forEach(data, (bank) => {
        bank.paymentModeDisplayName = App.localize(
          _.find(enumPaymentMode, (paymentMode) => paymentMode.id === bank.bankPaymentMode).name
        );
      });
    }
  }
})();
