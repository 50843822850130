(() => {
  angular
    .module('app')
    .controller(
      'host.views.operationsDashboard.insuranceClaims.batchAction.batchingSuccessModal',
      BatchingSuccessModalController
    );

  BatchingSuccessModalController.$inject = [
    '$state',
    '$uibModalInstance',
    '$uibModal',
    'downloadUrl',
  ];

  function BatchingSuccessModalController($state, $uibModalInstance, $uibModal, downloadUrl) {
    const vm = this;

    vm.downloadBatchFiles = downloadBatchFiles;
    vm.goToInsurerInvoices = goToInsurerInvoices;
    vm.backToInsuranceClaims = backToInsuranceClaims;
    vm.downloadUrl = downloadUrl;

    function downloadBatchFiles() {
      App.downloadTempFile(vm.downloadUrl);
    }

    function goToInsurerInvoices() {
      $uibModal.open({
        templateUrl: require('../../downloadBatchInvoiceModal/downloadBatchInvoice.modal.html'),
        controller:
          'host.views.operationsDashboard.insuranceClaims.downloadBatchInvoiceModal as vm',
        backdrop: 'static',
        size: 'md',
      });

      $state.go('host.operationsDashboard', {
        targetWidget: 'insuranceClaims',
      });
      $uibModalInstance.close();
    }

    function backToInsuranceClaims() {
      $state.go('host.operationsDashboard', {
        targetWidget: 'insuranceClaims',
      });
      $uibModalInstance.close();
    }
  }
})();
