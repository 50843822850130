(() => {
  angular.module('app').controller('common.views.claims.details.index', ClaimDetailsPageController);

  ClaimDetailsPageController.$inject = ['$state', '$stateParams', 'abp.services.app.claim'];

  function ClaimDetailsPageController($state, $stateParams, claimSvc) {
    const vm = this;

    vm.claimNumber = $stateParams.claimNumber;
    vm.isCorporate = App.isCorporate();

    vm.nextClaim = nextClaim;

    function nextClaim() {
      vm.loading += 1;
      claimSvc
        .getNextClaim({ id: $stateParams.claimNumber })
        .success((data) => {
          if (data.id) {
            $state.go('claimListDetail', { claimNumber: data.id });
          } else {
            abp.notify.info(App.localize('NoMoreUnprocessedClaims'));
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
