(function () {
    'use strict';

    angular.module('app').component('hostCorporatesSupportPlansCreateEditScheduleOfBenefitPrePostHospitalizationComponent', {
        templateUrl: require('/App/host/views/corporates/supportPlans/createEdit/scheduleOfBenefit/prePostHospitalization/prePostHospitalization.component.html'),
        controller: ['Hms.BenefitGroups.CoveredCycle', controller],
        controllerAs: 'vm',
        bindings: {
            scheduleOfBenefit: '<',
            currencyCode: '<'
        }
    });

    function controller(enumCoveredCycle) {
        var vm = this;

        vm.hasTxtFileSupport = abp.setting.getBoolean('Hms.Feature.TxtFileSupport');

        vm.daysText = App.localize('Days');
        vm.specialistConsultationMaxDaysTimesType = null;
        vm.surgicalOpinionAmountType = null;
        vm.surgicalOpinionMaxDaysTimesType = null;
        vm.followUpTreatmentAmountType = null;
        vm.isPreCovered = null;
        vm.isPostCovered = null;

        // AmountType
        vm.preHospitalizationDiagnosisTestAmountType = null;
        vm.preHospitalizationSpecialistConsultationAmountType = null;
        vm.surgicalOpinionAmountType = null;
        vm.followUpTreatmentAmountType = null;

        vm.enums = {};
        vm.enums.coveredCycle = enumCoveredCycle;

        vm.togglePrePostHospitalizationTreatment = function () {
            // Pre-hospitalization
            vm.minPreHospitalizationDays = vm.scheduleOfBenefit.preHospitalizationTreatment.isCovered ? 1 : 0;

            // Specialist consultation
            vm.minSpecialistConsultationCoveredTimes = vm.specialistConsultationMaxDaysTimesType === 0 ? 1 : 0;

            // Post-hospitalization
            vm.minPostHospitalizationDays = vm.scheduleOfBenefit.postHospitalizationTreatment.isCovered ? 1 : 0;

            vm.scheduleOfBenefit.preHospitalizationTreatment.isCovered = vm.isPreCovered === 1 ? true : false;
            vm.scheduleOfBenefit.postHospitalizationTreatment.isCovered = vm.isPostCovered === 1 ? true : false;

            // Surgical opinion
            vm.minSurgicalOpinionAmount = vm.surgicalOpinionAmountType === 1 ? 1 : 0;
            vm.minSurgicalOpinionCoveredTimes = vm.surgicalOpinionAmountType === 2
                || (vm.surgicalOpinionAmountType !== 2 && vm.surgicalOpinionMaxDaysTimesType === 1) ? 0 : 1;

            if (!vm.hasTxtFileSupport) {
              vm.scheduleOfBenefit.surgicalOpinion.isUnlimited = vm.surgicalOpinionMaxDaysTimesType === 1 ? true : false;
              setSpecialistConsultationMaxDaysTimesType();
              setSurgicalOpinionMaxDaysTimesType();
            }
        };

        function initializeAmountTypes() {
            vm.isPreCovered = vm.scheduleOfBenefit.preHospitalizationTreatment.isCovered ? 1 : 0;
            vm.isPostCovered = vm.scheduleOfBenefit.postHospitalizationTreatment.isCovered ? 1 : 0;

            vm.specialistConsultationMaxDaysTimesType = vm.scheduleOfBenefit.specialistConsultation.isUnlimited ? 1 : 0;

            // Surgical Opinion
            if (!vm.scheduleOfBenefit.surgicalOpinion.isCovered)
                vm.surgicalOpinionAmountType = 2;
            else if (vm.scheduleOfBenefit.surgicalOpinion.isAsCharged)
                vm.surgicalOpinionAmountType = 0;
            else if (!vm.scheduleOfBenefit.surgicalOpinion.isAsCharged)
                vm.surgicalOpinionAmountType = 1;

            vm.surgicalOpinionMaxDaysTimesType = vm.scheduleOfBenefit.surgicalOpinion.isUnlimited ? 1 : 0;
            vm.followUpTreatmentAmountType = vm.scheduleOfBenefit.followUpTreatment.isAsCharged ? 0 : 1;
        }

        // AmountType toggle
        vm.toggleAmountType = function (SOBItem) {
            // Surgical opinion
            vm.minSurgicalOpinionAmount = vm.surgicalOpinionAmountType === 1 ? 1 : 0;
            vm.minSurgicalOpinionCoveredTimes = vm.surgicalOpinionAmountType === 2
                || (vm.surgicalOpinionAmountType !== 2 && vm.surgicalOpinionMaxDaysTimesType === 1) ? 0 : 1;
            if (!vm.hasTxtFileSupport)
                vm.scheduleOfBenefit.surgicalOpinion.isUnlimited = vm.surgicalOpinionMaxDaysTimesType === 1 ? true : false;

            vm.minDiagnosisTestAmount = vm.preHospitalizationDiagnosisTestAmountType === 1 ? 1 : 0;
            vm.minSpecialistConsultationAmount = vm.preHospitalizationSpecialistConsultationAmountType === 1 ? 1 : 0;
            vm.minFollowUpTreatmentAmount = vm.followUpTreatmentAmountType === 1 ? 1 : 0;

            switch (SOBItem) {
                case 'preHospitalizationDiagnosisTest':
                    switch (vm.preHospitalizationDiagnosisTestAmountType) {
                        case 0: /*ischarged*/
                            vm.scheduleOfBenefit.diagnosisTreatment.isAsCharged = true;
                            vm.scheduleOfBenefit.diagnosisTreatment.isCovered = true;
                            break;
                        case 1: /*limited*/
                            vm.scheduleOfBenefit.diagnosisTreatment.isAsCharged = false;
                            vm.scheduleOfBenefit.diagnosisTreatment.isCovered = true;
                            break;
                        case 2: /*uncovered*/
                            vm.scheduleOfBenefit.diagnosisTreatment.isAsCharged = false;
                            vm.scheduleOfBenefit.diagnosisTreatment.isCovered = false;
                            break;
                    }

                    if (vm.scheduleOfBenefit.diagnosisTreatment.isAsCharged === vm.scheduleOfBenefit.diagnosisTreatment.isCovered) {
                        vm.tempDiagnosisTreatmentAmount = vm.scheduleOfBenefit.diagnosisTreatment.amount;
                        vm.scheduleOfBenefit.diagnosisTreatment.amount = null;

                        vm.tempDiagnosisTreatmentEffectivePeriod = vm.scheduleOfBenefit.diagnosisTreatment.coveredCycle;
                        vm.scheduleOfBenefit.diagnosisTreatment.coveredCycle = null;
                    } else {
                        vm.scheduleOfBenefit.diagnosisTreatment.amount = vm.tempDiagnosisTreatmentAmount;

                        if (vm.tempDiagnosisTreatmentEffectivePeriod) {
                            vm.scheduleOfBenefit.diagnosisTreatment.coveredCycle = vm.tempDiagnosisTreatmentEffectivePeriod;
                        } else {
                            vm.scheduleOfBenefit.diagnosisTreatment.coveredCycle = 6; // PerAdmission
                        }
                    }

                    break;
                case 'preHospitalizationSpecialistConsultation':
                    switch (vm.preHospitalizationSpecialistConsultationAmountType) {
                        case 0: /*ischarged*/
                            vm.scheduleOfBenefit.specialistConsultation.isAsCharged = true;
                            vm.scheduleOfBenefit.specialistConsultation.isCovered = true;
                            break;
                        case 1: /*limited*/
                            vm.scheduleOfBenefit.specialistConsultation.isAsCharged = false;
                            vm.scheduleOfBenefit.specialistConsultation.isCovered = true;
                            break;
                        case 2: /*uncovered*/
                            vm.scheduleOfBenefit.specialistConsultation.isAsCharged = false;
                            vm.scheduleOfBenefit.specialistConsultation.isCovered = false;
                            break;
                    }

                    if (vm.scheduleOfBenefit.specialistConsultation.isAsCharged === vm.scheduleOfBenefit.specialistConsultation.isCovered) {
                        vm.tempSpecialistConsultationAmount = vm.scheduleOfBenefit.specialistConsultation.amount;
                        vm.scheduleOfBenefit.specialistConsultation.amount = null;

                        vm.tempSpecialistConsultationEffectivePeriod = vm.scheduleOfBenefit.specialistConsultation.coveredCycle;
                        vm.scheduleOfBenefit.specialistConsultation.coveredCycle = null;
                    } else {
                        vm.scheduleOfBenefit.specialistConsultation.amount = vm.tempSpecialistConsultationAmount;

                        if (vm.tempSpecialistConsultationEffectivePeriod) {
                            vm.scheduleOfBenefit.specialistConsultation.coveredCycle = vm.tempSpecialistConsultationEffectivePeriod;
                        } else {
                            vm.scheduleOfBenefit.specialistConsultation.coveredCycle = 6; // PerAdmission
                        }
                    }

                    break;
                case 'secondSurgicalOpinion':
                    switch (vm.surgicalOpinionAmountType) {
                        case 0: /*ischarged*/
                            vm.scheduleOfBenefit.surgicalOpinion.isAsCharged = true;
                            vm.scheduleOfBenefit.surgicalOpinion.isCovered = true;
                            break;
                        case 1: /*limited*/
                            vm.scheduleOfBenefit.surgicalOpinion.isAsCharged = false;
                            vm.scheduleOfBenefit.surgicalOpinion.isCovered = true;
                            break;
                        case 2: /*uncovered*/
                            vm.scheduleOfBenefit.surgicalOpinion.isAsCharged = false;
                            vm.scheduleOfBenefit.surgicalOpinion.isCovered = false;
                            vm.scheduleOfBenefit.surgicalOpinion.isUnlimited = false;
                            break;
                    }

                    // Store the edited and valid covered times temporarily to save the value if the amount type is set to uncovered but then reverted.

                    if (vm.scheduleOfBenefit.surgicalOpinion.coveredTimes !== null) {
                        vm.tempSurgicalOpinionCoveredTimes = vm.scheduleOfBenefit.surgicalOpinion.coveredTimes;
                    }

                    if (vm.scheduleOfBenefit.surgicalOpinion.isAsCharged === vm.scheduleOfBenefit.surgicalOpinion.isCovered) {
                        vm.tempSurgicalOpinionAmount = vm.scheduleOfBenefit.surgicalOpinion.amount;
                        vm.scheduleOfBenefit.surgicalOpinion.amount = null;

                        vm.tempSurgicalOpinionEffectivePeriod = vm.scheduleOfBenefit.surgicalOpinion.coveredCycle;
                        vm.scheduleOfBenefit.surgicalOpinion.coveredCycle = null;
                    } else {
                        vm.scheduleOfBenefit.surgicalOpinion.amount = vm.tempSurgicalOpinionAmount;

                        if (vm.tempSurgicalOpinionEffectivePeriod) {
                            vm.scheduleOfBenefit.surgicalOpinion.coveredCycle = vm.tempSurgicalOpinionEffectivePeriod;
                        } else {
                            vm.scheduleOfBenefit.surgicalOpinion.coveredCycle = 6; // PerAdmission
                        }
                    }

                    // Resets the covered times displayed if the amount type is not covered.

                    if (!vm.scheduleOfBenefit.surgicalOpinion.isAsCharged && !vm.scheduleOfBenefit.surgicalOpinion.isCovered) {
                        vm.scheduleOfBenefit.surgicalOpinion.coveredTimes = null;
                    } else {
                        vm.scheduleOfBenefit.surgicalOpinion.coveredTimes = vm.tempSurgicalOpinionCoveredTimes;
                    }

                    break;
                case 'postHospitalizationFollowUpAndTreatment':
                    switch (vm.followUpTreatmentAmountType) {
                        case 0: /*ischarged*/
                            vm.scheduleOfBenefit.followUpTreatment.isAsCharged = true;
                            vm.scheduleOfBenefit.followUpTreatment.isCovered = true;
                            break;
                        case 1: /*limited*/
                            vm.scheduleOfBenefit.followUpTreatment.isAsCharged = false;
                            vm.scheduleOfBenefit.followUpTreatment.isCovered = true;
                            break;
                        case 2: /*uncovered*/
                            vm.scheduleOfBenefit.followUpTreatment.isAsCharged = false;
                            vm.scheduleOfBenefit.followUpTreatment.isCovered = false;
                            break;
                    }

                    if (vm.scheduleOfBenefit.followUpTreatment.isAsCharged === vm.scheduleOfBenefit.followUpTreatment.isCovered) {
                        vm.tempFollowUpAmount = vm.scheduleOfBenefit.followUpTreatment.amount;
                        vm.scheduleOfBenefit.followUpTreatment.amount = null;

                        vm.tempFollowUpEffectivePeriod = vm.scheduleOfBenefit.followUpTreatment.coveredCycle;
                        vm.scheduleOfBenefit.followUpTreatment.coveredCycle = null;
                    } else {
                        vm.scheduleOfBenefit.followUpTreatment.amount = vm.tempFollowUpAmount;

                        if (vm.tempFollowUpEffectivePeriod) {
                            vm.scheduleOfBenefit.followUpTreatment.coveredCycle = vm.tempFollowUpEffectivePeriod;
                        } else {
                            vm.scheduleOfBenefit.followUpTreatment.coveredCycle = 6; // PerAdmission
                        }
                    }

                    break;
            }
        }

        vm.checkBoxClicked = function (checkBoxName) {
            if (checkBoxName == 'surgicalOpinionUnlimited') {
                if (vm.scheduleOfBenefit.surgicalOpinion.isUnlimitedVisitPerDay) {
                    vm.tempSurgicalOpinionDays = vm.scheduleOfBenefit.surgicalOpinion.coveredTimes;
                    vm.scheduleOfBenefit.surgicalOpinion.coveredTimes = null;
                }
                else {
                    vm.scheduleOfBenefit.surgicalOpinion.coveredTimes = vm.tempSurgicalOpinionDays;
                }
            }
        }

        function initializeAmountTypes2() {
            // Pre hospitalization diagnosis test AmountType
            if (!vm.scheduleOfBenefit.diagnosisTreatment.isCovered)
                vm.preHospitalizationDiagnosisTestAmountType = 2;
            else if (vm.scheduleOfBenefit.diagnosisTreatment.isAsCharged)
                vm.preHospitalizationDiagnosisTestAmountType = 0;
            else if (!vm.scheduleOfBenefit.diagnosisTreatment.isAsCharged) {
                vm.preHospitalizationDiagnosisTestAmountType = 1;
            }

            // Pre-hospitalization specialist consultation AmountType
            if (!vm.scheduleOfBenefit.specialistConsultation.isCovered)
                vm.preHospitalizationSpecialistConsultationAmountType = 2;
            else if (vm.scheduleOfBenefit.specialistConsultation.isAsCharged)
                vm.preHospitalizationSpecialistConsultationAmountType = 0;
            else if (!vm.scheduleOfBenefit.specialistConsultation.isAsCharged) {
                vm.preHospitalizationSpecialistConsultationAmountType = 1;
            }

            // Second surgical opinion AmountType
            if (!vm.scheduleOfBenefit.surgicalOpinion.isCovered)
                vm.surgicalOpinionAmountType = 2;
            else if (vm.scheduleOfBenefit.surgicalOpinion.isAsCharged)
                vm.surgicalOpinionAmountType = 0;
            else if (!vm.scheduleOfBenefit.surgicalOpinion.isAsCharged) {
                vm.surgicalOpinionAmountType = 1;
            }

            // Post hospitalization follow up and treatment AmountType
            if (!vm.scheduleOfBenefit.followUpTreatment.isCovered)
                vm.followUpTreatmentAmountType = 2;
            else if (vm.scheduleOfBenefit.followUpTreatment.isAsCharged)
                vm.followUpTreatmentAmountType = 0;
            else if (!vm.scheduleOfBenefit.followUpTreatment.isAsCharged) {
                vm.followUpTreatmentAmountType = 1;
            }
        }

        function init() {
            initializeAmountTypes();
            vm.togglePrePostHospitalizationTreatment();

            if (vm.hasTxtFileSupport) initializeAmountTypes2();
            vm.toggleAmountType();
        }

        function setSpecialistConsultationMaxDaysTimesType() {
            switch (vm.specialistConsultationMaxDaysTimesType) {
                case 0:
                    vm.scheduleOfBenefit.specialistConsultation.isUnlimited = false;
                    break;

                case 1:
                    vm.scheduleOfBenefit.specialistConsultation.isUnlimited = true;
                    break;

                default:
                    break;
            }
        }

        function setSurgicalOpinionMaxDaysTimesType() {
            switch (vm.surgicalOpinionMaxDaysTimesType) {
                case 0:
                    vm.scheduleOfBenefit.surgicalOpinion.isUnlimited = false;
                    break;

                case 1:
                    vm.scheduleOfBenefit.surgicalOpinion.isUnlimited = true;
                    break;

                default:
                    break;
            }
        }

        angular.element(document).ready(function () {
            init();
        });
    }
})();
