(() => {
  angular.module('app').component('manualTransactionSetupPaymentTerms', {
    templateUrl: require('./paymentTerms.component.html'),
    controller: ManualTransactionSetupPaymentTermsController,
    controllerAs: 'vm',
    bindings: {
      accountOwnerId: '<',
      countryCode: '<',
    },
  });

  ManualTransactionSetupPaymentTermsController.$inject = [
    '$scope',
    '$uibModal',
    'financeConstants',
    'abp.services.finance.paymentTerms',
  ];

  function ManualTransactionSetupPaymentTermsController(
    $scope,
    $uibModal,
    financeConstants,
    paymentTermsSvc
  ) {
    const vm = this;

    vm.loading = 0;
    vm.requestParams = {
      accountOwnerId: vm.accountOwnerId,
      maxResultCount: financeConstants.grid.defaultPageSize,
      skipCount: 0,
    };

    vm.createEditPaymentTerms = createEditPaymentTerms;
    vm.deletePaymentTerms = deletePaymentTerms;

    vm.paymentTermsGridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: financeConstants.grid.defaultPageSizes,
      paginationPageSize: financeConstants.grid.defaultPageSize,
      paginationCurrentPage: 1,
      useExternalPagination: true,
      useExternalSorting: true,
      columnDefs: [
        {
          name: App.localize('Actions'),
          displayName: 'Actions',
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'paymentTermsActionTemplate',
          allowCellFocus: false,
        },
        {
          displayName: App.localize('Name'),
          field: 'name',
          minWidth: 120,
        },
        {
          displayName: App.localize('PaymentTermDuration'),
          field: 'term',
          minWidth: 120,
        },
      ],
      onRegisterApi: (gridApi) => {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          vm.requestParams.sorting =
            callback.length && callback[0].field
              ? `${callback[0].field} ${callback[0].sort.direction}`
              : null;
          getPaymentTerms();
        });
        gridApi.pagination.on.paginationChanged($scope, (newPage, pageSize) => {
          vm.requestParams.skipCount = (newPage - 1) * pageSize;
          vm.requestParams.maxResultCount = pageSize;
          getPaymentTerms();
        });
      },
      data: [],
    };

    init();

    function init() {
      getPaymentTerms();
    }

    function getPaymentTerms() {
      vm.loading += 1;
      paymentTermsSvc
        .getPaymentTerms(vm.requestParams)
        .success((data) => {
          vm.paymentTermsGridOptions.totalItems = data.totalCount;
          vm.paymentTermsGridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function createEditPaymentTerms(paymentTermsDetails) {
      $uibModal
        .open({
          templateUrl: require('./createEditPaymentTerms.modal.html'),
          controller: 'manualTransactionSetupCreateEditPaymentTerms as vm',
          backdrop: 'static',
          resolve: {
            isCreate: () => paymentTermsDetails == null,
            paymentTermsDetails: () => JSON.parse(JSON.stringify(paymentTermsDetails)),
            accountOwnerId: () => vm.accountOwnerId,
          },
        })
        .result.then(() => {
          getPaymentTerms();
        });
    }

    function deletePaymentTerms(paymentTermsDetails) {
      // Delete payment term.

      abp.message.confirm(
        App.localize('PaymentTermDeleteWarningMessage', paymentTermsDetails.name),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            vm.loading += 1;
            paymentTermsSvc
              .deletePaymentTerm({ id: paymentTermsDetails.id })
              .success(() => {
                abp.notify.success(App.localize('SuccessfullyDeleted'));
              })
              .finally(() => {
                vm.loading -= 1;
                getPaymentTerms();
              });
          }
        }
      );
    }
  }
})();
