(function () {
    'use strict';

    angular.module('app').component('hostCorporatesSupportPlansCreateEditScheduleOfBenefitDeliveryComponent', {
        templateUrl: require('/App/host/views/corporates/supportPlans/createEdit/scheduleOfBenefit/delivery/delivery.component.html'),
        controller: controller,
        controllerAs: 'vm',
        bindings: {
            scheduleOfBenefit: '<',
            currencyCode: '<'
        }
    });

    function controller() {
        var vm = this;

        vm.normalDeliveryText = App.localize('NormalDelivery');
        vm.caesareanDeliveryText = App.localize('CaesareanDelivery');
        vm.normalDeliveryForTwinsAndAboveText = App.localize('NormalDeliveryForTwinsAndAbove');
        vm.caesareanDeliveryForTwinsAndAboveText = App.localize('CaesareanDeliveryForTwinsAndAbove');

        vm.toggleDelivery = function () {
            // Normal delivery

            vm.minNormalDeliveryAmount = vm.scheduleOfBenefit.normalDelivery.amountType === 1 ? 1 : 0;
            vm.minNormalMaxDaysTimes = vm.scheduleOfBenefit.normalDelivery.amountType === 2
                || (vm.scheduleOfBenefit.normalDelivery.amountType !== 2 && vm.scheduleOfBenefit.normalDelivery.maxDaysTimesType === 1) ? 0 : 1;

            // Caesarean delivery

            vm.minCaesareanDeliveryAmount = vm.scheduleOfBenefit.caesareanDelivery.amountType === 1 ? 1 : 0;
            vm.minCaesareanMaxDaysTimes = vm.scheduleOfBenefit.caesareanDelivery.amountType === 2
                || (vm.scheduleOfBenefit.caesareanDelivery.amountType !== 2 && vm.scheduleOfBenefit.caesareanDelivery.maxDaysTimesType === 1) ? 0 : 1;

            // Normal twins and above delivery

            vm.minNormalTwinsDeliveryAmount = vm.scheduleOfBenefit.normalDeliveryTwinsAndAbove.amountType === 1 ? 1 : 0;
            vm.minNormalTwinsMaxDaysTimes = vm.scheduleOfBenefit.normalDeliveryTwinsAndAbove.amountType === 2
                || (vm.scheduleOfBenefit.normalDeliveryTwinsAndAbove.amountType !== 2 && vm.scheduleOfBenefit.normalDeliveryTwinsAndAbove.maxDaysTimesType === 1) ? 0 : 1;

            // Caesarean twins and above delivery

            vm.minCaesareanTwinsDeliveryAmount = vm.scheduleOfBenefit.caesareanDeliveryTwinsAndAbove.amountType === 1 ? 1 : 0;
            vm.minCaesareanTwinsMaxDaysTimes = vm.scheduleOfBenefit.caesareanDeliveryTwinsAndAbove.amountType === 2
                || (vm.scheduleOfBenefit.caesareanDeliveryTwinsAndAbove.amountType !== 2 && vm.scheduleOfBenefit.caesareanDeliveryTwinsAndAbove.maxDaysTimesType === 1) ? 0 : 1;
        };

        function init() {
            vm.toggleDelivery();
        }

        angular.element(document).ready(function () {
            init();
        });
    }
})();

