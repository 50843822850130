(function () {
    'use strict';

    angular.module('app').component('hostCorporatesSupportPlansCreateEditScheduleOfBenefitEmergencyOutpatientTreatmentComponent', {
        templateUrl: require('/App/host/views/corporates/supportPlans/createEdit/scheduleOfBenefit/emergencyOutpatientTreatment/emergencyOutpatientTreatment.component.html'),
        controller: ['$scope', 'Hms.BenefitGroups.CoveredCycle', controller],
        controllerAs: 'vm',
        bindings: {
            scheduleOfBenefit: '<',
            currencyCode: '<'
        }
    });

    function controller($scope, enumCoveredCycle) {
        var vm = this;
        vm.hasScheduleOfBenefitEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');

        // AmountType
        vm.emergencyAccidentalOutpatientAmountType = null;
        vm.emergencyAccidentalDentalAmountType = null;
        vm.emergencyOutpatientSickenessAmountType = null;

        // FollowType
        vm.emergencyAccidentalOutpatientFollowUpType = null;
        vm.emergencyAccidentalOutpatientFollowUpDayTime = null;
        vm.tempAccidentalOutpatientFollowUpPeriod = null;
        vm.tempAccidentalOutpatientFollowUpDayTime = null;
        vm.emergencyAccidentalOutpatientTreatmentFollowUpAmount = null;

        vm.emergencyAccidentalDentalFollowUpType = null;
        vm.emergencyAccidentalDentalFollowUpDayTime = null;
        vm.tempAccidentalDentalFollowUpPeriod = null;
        vm.tempAccidentalDentalFollowUpDayTime = null;
        vm.emergencyAccidentalDentalTreatmentFollowUpAmount = null;

        vm.minEmergencyAccidentalOutpatient = null;
        vm.minEmergencyAccidentalDental = null;
        vm.minEmergencyOutpatientSickeness = null;

        vm.enums = {};
        vm.enums.coveredCycle = enumCoveredCycle;

        // AmountType toggle
        vm.toggleAmountType = function (SOBItem) {
            vm.minEmergencyAccidentalOutpatient = vm.emergencyAccidentalOutpatientAmountType === 1 ? 1 : 0;
            vm.minEmergencyAccidentalDental = vm.emergencyAccidentalDentalAmountType === 1 ? 1 : 0;
            vm.minEmergencyOutpatientSickeness = vm.emergencyOutpatientSickenessAmountType === 1 ? 1 : 0;

            switch (SOBItem) {
                case 'emergencyAccidentalOutpatient':
                    switch (vm.emergencyAccidentalOutpatientAmountType) {
                        case 0: /*ischarged*/
                            vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.isAsCharged = true;
                            vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.isCovered = true;
                            break;
                        case 1: /*limited*/
                            vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.isAsCharged = false;
                            vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.isCovered = true;
                            break;
                        case 2: /*uncovered*/
                            vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.isAsCharged = false;
                            vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.isCovered = false;
                            break;
                    }

                    if (vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.isAsCharged === vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.isCovered) {
                        vm.tempEmergencyAccidentalOutpatientAmount = vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.amount;
                        vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.amount = null;

                        vm.tempEmergencyAccidentalOutpatientEffectivePeriod = vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.coveredCycle;
                        vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.coveredCycle = null;
                    } else {
                        vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.amount = vm.tempEmergencyAccidentalOutpatientAmount;

                        if (vm.tempEmergencyAccidentalOutpatientEffectivePeriod) {
                            vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.coveredCycle = vm.tempEmergencyAccidentalOutpatientEffectivePeriod;
                        } else {
                            vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.coveredCycle = 6; // PerAdmission
                        }
                    }

                    break;
                case 'emergencyAccidentalDental':
                    switch (vm.emergencyAccidentalDentalAmountType) {
                        case 0: /*ischarged*/
                            vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.isAsCharged = true;
                            vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.isCovered = true;
                            break;
                        case 1: /*limited*/
                            vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.isAsCharged = false;
                            vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.isCovered = true;
                            break;
                        case 2: /*uncovered*/
                            vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.isAsCharged = false;
                            vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.isCovered = false;
                            break;
                    }

                    if (vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.isAsCharged === vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.isCovered) {
                        vm.tempEmergencyAccidentalDentalAmount = vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.amount;
                        vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.amount = null;

                        vm.tempEmergencyAccidentalDentalEffectivePeriod = vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.coveredCycle;
                        vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.coveredCycle = null;
                    } else {
                        vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.amount = vm.tempEmergencyAccidentalDentalAmount;

                        if (vm.tempEmergencyAccidentalDentalEffectivePeriod) {
                            vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.coveredCycle = vm.tempEmergencyAccidentalDentalEffectivePeriod;
                        } else {
                            vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.coveredCycle = 6; // PerAdmission
                        }
                    }

                    break;
                case 'emergencyOutpatientSickness':
                    switch (vm.emergencyOutpatientSickenessAmountType) {
                        case 0: /*ischarged*/
                            vm.scheduleOfBenefit.emergencyAccidentalSicknessTreatment.isAsCharged = true;
                            vm.scheduleOfBenefit.emergencyAccidentalSicknessTreatment.isCovered = true;
                            break;
                        case 1: /*limited*/
                            vm.scheduleOfBenefit.emergencyAccidentalSicknessTreatment.isAsCharged = false;
                            vm.scheduleOfBenefit.emergencyAccidentalSicknessTreatment.isCovered = true;
                            break;
                        case 2: /*uncovered*/
                            vm.scheduleOfBenefit.emergencyAccidentalSicknessTreatment.isAsCharged = false;
                            vm.scheduleOfBenefit.emergencyAccidentalSicknessTreatment.isCovered = false;
                            break;
                    }

                    if (vm.scheduleOfBenefit.emergencyAccidentalSicknessTreatment.isAsCharged === vm.scheduleOfBenefit.emergencyAccidentalSicknessTreatment.isCovered) {
                        vm.tempEmergencyOutpatientSicknessAmount = vm.scheduleOfBenefit.emergencyAccidentalSicknessTreatment.amount;
                        vm.scheduleOfBenefit.emergencyAccidentalSicknessTreatment.amount = null;

                        vm.tempEmergencyOutpatientSicknessEffectivePeriod = vm.scheduleOfBenefit.emergencyAccidentalSicknessTreatment.coveredCycle;
                        vm.scheduleOfBenefit.emergencyAccidentalSicknessTreatment.coveredCycle = null;
                    } else {
                        vm.scheduleOfBenefit.emergencyAccidentalSicknessTreatment.amount = vm.tempEmergencyOutpatientSicknessAmount;

                        if (vm.tempEmergencyOutpatientSicknessEffectivePeriod) {
                            vm.scheduleOfBenefit.emergencyAccidentalSicknessTreatment.coveredCycle = vm.tempEmergencyOutpatientSicknessEffectivePeriod;
                        } else {
                            vm.scheduleOfBenefit.emergencyAccidentalSicknessTreatment.coveredCycle = 6; // PerAdmission
                        }
                    }

                    break;
            }
        }

        // Follow-up Type toggle
        vm.toggleFollowUpType = function (SOBItem) {
            switch (SOBItem) {
                case 'emergencyAccidentalOutpatient':
                    switch (vm.emergencyAccidentalOutpatientFollowUpType) {
                        case 0:
                            vm.emergencyAccidentalOutpatientTreatmentFollowUpAmount = vm.tempAccidentalOutpatientFollowUpPeriod;
                            vm.emergencyAccidentalOutpatientFollowUpDayTime = vm.tempAccidentalOutpatientFollowUpDayTime;
                            break;
                        case 1:
                            vm.tempAccidentalOutpatientFollowUpPeriod = vm.emergencyAccidentalOutpatientTreatmentFollowUpAmount;
                            vm.emergencyAccidentalOutpatientTreatmentFollowUpAmount = null;
                            vm.tempAccidentalOutpatientFollowUpDayTime = vm.emergencyAccidentalOutpatientFollowUpDayTime;
                            vm.emergencyAccidentalOutpatientFollowUpDayTime = null;
                            vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.followUpDays = null;
                            vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.followUpTimes = null;
                            break;
                    }
                    break;
                case 'emergencyAccidentalDental':
                    switch (vm.emergencyAccidentalDentalFollowUpType) {
                        case 0:
                            vm.emergencyAccidentalDentalTreatmentFollowUpAmount = vm.tempAccidentalDentalFollowUpPeriod;
                            vm.emergencyAccidentalDentalFollowUpDayTime = vm.tempAccidentalDentalFollowUpDayTime;
                            break;
                        case 1:
                            vm.tempAccidentalDentalFollowUpPeriod = vm.emergencyAccidentalDentalTreatmentFollowUpAmount;
                            vm.emergencyAccidentalDentalTreatmentFollowUpAmount = null;
                            vm.tempAccidentalDentalFollowUpDayTime = vm.emergencyAccidentalDentalFollowUpDayTime;
                            vm.emergencyAccidentalDentalFollowUpDayTime = null;
                            vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.followUpDays = null;
                            vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.followUpTimes = null;
                            break;
                    }
                    break;
            }
        }

        // Follow-up Type (Days/Times) toggle
        vm.toggleFollowUpTypeDayTime = function (SOBItem) {
            switch (SOBItem) {
                case 'emergencyAccidentalOutpatient':
                    switch (vm.emergencyAccidentalOutpatientFollowUpDayTime) {
                        case 0: /*Days*/
                            vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.followUpDays = vm.emergencyAccidentalOutpatientTreatmentFollowUpAmount;
                            vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.followUpTimes = null;
                            break;
                        case 1: /*Times*/
                            vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.followUpDays = null;
                            vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.followUpTimes = vm.emergencyAccidentalOutpatientTreatmentFollowUpAmount;
                            break;
                    }
                    break;
                case 'emergencyAccidentalDental':
                    switch (vm.emergencyAccidentalDentalFollowUpDayTime) {
                        case 0: /*Days*/
                            vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.followUpDays = vm.emergencyAccidentalDentalTreatmentFollowUpAmount;
                            vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.followUpTimes = null;
                            break;
                        case 1: /*Times*/
                            vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.followUpDays = null;
                            vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.followUpTimes = vm.emergencyAccidentalDentalTreatmentFollowUpAmount;
                            break;
                    }
                    break;
            }
        }

        function initializeAmountTypes() {
            // Emergency Accidental Outpatient AmountType
            if (!vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.isCovered)
                vm.emergencyAccidentalOutpatientAmountType = 2;
            else if (vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.isAsCharged)
                vm.emergencyAccidentalOutpatientAmountType = 0;
            else if (!vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.isAsCharged) {
                vm.emergencyAccidentalOutpatientAmountType = 1;
            }

            // Emergency Accidental Dental AmountType
            if (!vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.isCovered)
                vm.emergencyAccidentalDentalAmountType = 2;
            else if (vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.isAsCharged)
                vm.emergencyAccidentalDentalAmountType = 0;
            else if (!vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.isAsCharged) {
                vm.emergencyAccidentalDentalAmountType = 1;
            }

            // Emergency Accidental Sickness AmountType
            if (!vm.scheduleOfBenefit.emergencyAccidentalSicknessTreatment.isCovered)
                vm.emergencyOutpatientSickenessAmountType = 2;
            else if (vm.scheduleOfBenefit.emergencyAccidentalSicknessTreatment.isAsCharged)
                vm.emergencyOutpatientSickenessAmountType = 0;
            else if (!vm.scheduleOfBenefit.emergencyAccidentalSicknessTreatment.isAsCharged) {
                vm.emergencyOutpatientSickenessAmountType = 1;
            }
        }

        function initializeFollowUp() {
            // Emergency Accidental Outpatient FollowUp
            if (vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.followUpDays) {
                vm.emergencyAccidentalOutpatientFollowUpType = 0;
                vm.emergencyAccidentalOutpatientFollowUpDayTime = 0;
                vm.emergencyAccidentalOutpatientTreatmentFollowUpAmount = vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.followUpDays;
            } else if (vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.followUpTimes) {
                vm.emergencyAccidentalOutpatientFollowUpType = 0;
                vm.emergencyAccidentalOutpatientFollowUpDayTime = 1;
                vm.emergencyAccidentalOutpatientTreatmentFollowUpAmount = vm.scheduleOfBenefit.emergencyAccidentalOutpatientTreatment.followUpTimes;
            } else {
                vm.emergencyAccidentalOutpatientFollowUpType = 1;
            }

            // Emergency Accidental Dental FollowUp
            if (vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.followUpDays) {
                vm.emergencyAccidentalDentalFollowUpType = 0;
                vm.emergencyAccidentalDentalFollowUpDayTime = 0;
                vm.emergencyAccidentalDentalTreatmentFollowUpAmount = vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.followUpDays;
            } else if (vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.followUpTimes) {
                vm.emergencyAccidentalDentalFollowUpType = 0;
                vm.emergencyAccidentalDentalFollowUpDayTime = 1;
                vm.emergencyAccidentalDentalTreatmentFollowUpAmount = vm.scheduleOfBenefit.emergencyAccidentalDentalTreatment.followUpTimes;
            } else {
                vm.emergencyAccidentalDentalFollowUpType = 1;
            }
        }

        function init() {
            initializeAmountTypes();
            initializeFollowUp();
            vm.toggleAmountType();
        }

        angular.element(document).ready(function () {
            init();
        });
    }
})();
