(function () {
    var controllerId = 'host.views.doraemon.common.exclusionListModal';
    angular
        .module('app')
        .controller(controllerId, [
            '$scope', '$uibModalInstance', 'inpatientSettings', 'abp.services.app.doraemon',
            function ($scope, $uibModalInstance, inpatientSettings, doraemonSvc) {
                function init() {
                    getOptionalExclusions();
                    getDefaultExclusions();
                }

                function getOptionalExclusions() {
                    if (inpatientSettings.coveredOptionalExclusions) {
                        vm.loading++;
                        doraemonSvc.getOptionalExclusions({
                            coveredOptionalExclusions: inpatientSettings.coveredOptionalExclusions
                        }).success(function (record) {
                            vm.optionalExclusions = record.coveredOptionalExclusions.length > 0 ? record.coveredOptionalExclusions : null;
                        }).finally(function () {
                            vm.loading--;
                        });
                    }
                }

                function getDefaultExclusions() {
                    if (inpatientSettings.exclusionVersionId) {
                        vm.loading++;
                        doraemonSvc.getDefaultExclusions({
                            exclusionVersionId: inpatientSettings.exclusionVersionId
                        }).success(function (record) {
                            vm.exclusionProvisions = record.defaultExclusions.length > 0 ? record.defaultExclusions : null;
                        }).finally(function () {
                            vm.loading--;
                        });
                    }
                }

                var vm = this;
                vm.loading = 0;

                vm.close = function () {
                    $uibModalInstance.close();
                };

                init();
            }
        ]);
})();
