(function () {
    angular.module('app').component('hostInpatientGuaranteeLettersDetailsOverviewComponent', {
        templateUrl: require('/App/host/views/inpatientGuaranteeLetters/details/overview/overview.component.html'),
        controller: ['$state', '$scope', '$stateParams', 'abp.services.app.inpatientGuaranteeLetter', 'abp.services.app.hostServiceRequest', 'Hms.ServiceRequests.ServiceRequestStatus', controller],
        controllerAs: 'vm',
        bindings: {
            currencyCode: '='
        }
    });

    function controller($state, $scope, $stateParams, inpatientGuaranteeLetterSvc, hostServiceRequestSvc, enumServiceRequestStatus) {
        var vm = this;
        vm.loading = 0;
        vm.letterNumber = $stateParams.letterNumber;
        vm.pickUpRequest = pickUpRequest;

        vm.enums = {};
        vm.enums.serviceRequestStatus = enumServiceRequestStatus;

        // TODO: Update permissions

        vm.permissions = {
            update: abp.auth.isGranted('GuaranteeLetters.Host.Update'),
            topUp: abp.auth.isGranted('GuaranteeLetters.Host.TopUp'),
            extend: abp.auth.isGranted('GuaranteeLetters.Host.Approve')
        };

        function getInpatientGuaranteeLetterDetail() {
            vm.loading++;
            inpatientGuaranteeLetterSvc.getInpatientGuaranteeLetterDetail({
                id: vm.letterNumber
            }).success(function (data) {
                vm.request = data.requestByPanel;
                vm.patient = data.patient;
                vm.organization = data.organization;
                vm.subsidiary = data.subsidiary;
                vm.guaranteeLetter = data.guaranteeLetter;
                vm.canPickUpRequest = data.canPickUpRequest;
                vm.isAssignee = data.isAssignee;
                vm.isEscalated = data.isEscalated;
                vm.currencyCode = data.currencyCode;
            }).finally(function () {
                vm.loading--;
            });
        }

        function init() {
            getInpatientGuaranteeLetterDetail();
        }

        function pickUpRequest(redirectUrl) {
            vm.loading++;
            hostServiceRequestSvc.updateServiceRequestProcessorId({ id: vm.request.id })
                .success(function (data) {
                })
                .finally(function () {
                    vm.loading--;
                });

            $state.go(redirectUrl, {
                letterNumber: vm.letterNumber,
                requestId: vm.request.id,
                memberId: vm.patient.id
            });
        }

        init();
    }
})();
