(function () {
    angular.module('app').component('hostDoraemonCommonBenefitCoverageComponent', {
        templateUrl: require('/App/host/views/doraemon/common/benefitCoverage/benefitCoverage.component.html'),
        controller: ['$sce', '$filter', '$uibModal', 'abp.services.app.doraemon', controller],
        controllerAs: 'vm',
        bindings: {
            memberId: '<',
            serviceRequestId: '<',
            admissionType: '<',
            isDetailPage: '<'
        }
    });

    function controller($sce, $filter, $uibModal, doraemonSvc) {
        var vm = this;
        vm.$onChanges = onChanges;
        vm.loading = 0;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.defaultMaxAllowance = parseFloat(abp.setting.get("Hms.General.MaxAllowanceAmountLimit"));
        vm.isOpen = 1;
        vm.toggleOpen = toggleOpen;
        vm.illnessInfo = $sce.trustAsHtml(App.localize('SpecifiedIllnessTooltip'));
        var hospitalizationTitle = App.localize('Hospitalization');
        var maternityTitle = App.localize('Maternity');

        vm.hasTxtFileSupport = abp.setting.getBoolean('Hms.Feature.TxtFileSupport');

        function toggleOpen() {
            vm.isOpen = !vm.isOpen;
        };

        function getBenefitByMemberId(memberId) {
            vm.loading++;
            doraemonSvc.getPatientBenefitsByPatientMemberId({
                id: memberId,
                admissionType: vm.admissionType,
            }).success(function (data) {
                vm.benefit = data;
                setDetails();
            }).finally(function () {
                vm.loading--;
            });
        };

        function getBenefitByServiceRequestId(serviceRequestId) {
            vm.loading++;
            doraemonSvc.getPatientBenefitsByServiceRequestId({
                id: serviceRequestId,
                admissionType: vm.admissionType,
            }).success(function (data) {
                vm.benefit = data;
                setDetails();
            }).finally(function () {
                vm.loading--;
            });
        };

        function setDetails() {
            if (vm.benefit) {
                _.each(vm.benefit.inpatientBenefitGroups, function (data) {
                    if (data.serviceType === 7) {
                        data.title = hospitalizationTitle;
                        data.settings = data.hospitalizationSetting;
                        data.allowance = Math.min(data.hospitalizationSetting.allowance, vm.defaultMaxAllowance);
                        data.balancePool = data.hospitalizationSetting.balancePool;
                        data.coPay = data.hospitalizationSetting.coPays ? data.hospitalizationSetting.coPays[0] : null;
                        data.hospitalizationBenefits = data.hospitalizationSetting.hospitalizationBenefits;
                        data.displayBalancePool = [];

                        if (data.balancePool && data.balancePool.length > 0) {
                            _.each(data.balancePool, function (pool) {
                                getDisplayBalancePool(pool, data.displayBalancePool);
                            })
                        }
                    }
                    else {
                        data.title = maternityTitle;
                        data.settings = data.maternitySetting;
                        data.allowance = Math.min(data.maternitySetting.allowance, vm.defaultMaxAllowance);
                        data.balancePool = data.maternitySetting.balancePool;
                        data.coPay = data.maternitySetting.coPays ? data.maternitySetting.coPays[0] : null;
                        data.displayBalancePool = [];

                        if (data.balancePool && data.balancePool.length > 0) {
                            _.each(data.balancePool, function (pool) {
                                getDisplayBalancePool(pool, data.displayBalancePool);
                            })
                        }
                    }

                    if (data.coPay) {
                        if (data.coPay.isPercentage) {
                            if (data.serviceType === 7) {
                                if (data.hospitalizationSetting.isCoInsurance) {
                                    data.coPay.title = vm.hasTxtFileSupport && data.hospitalizationSetting.coPayTypeName
                                        ? data.hospitalizationSetting.coPayTypeName.concat(' ', data.coPay.value) + '%'
                                        : App.localize('CoInsuranceX', data.coPay.value + '%');
                                    data.coPay.text = App.localize('CopayPercentage', data.coPay.value + '%');
                                } else if (!data.hospitalizationSetting.isCoInsurance) {
                                    data.coPay.title = vm.hasTxtFileSupport && data.hospitalizationSetting.coPayTypeName
                                        ? data.hospitalizationSetting.coPayTypeName.concat(' ', data.coPay.value) + '%'
                                        : App.localize('CopayX', data.coPay.value + '%');
                                    data.coPay.text = App.localize('CopayPercentage', data.coPay.value + '%');
                                }
                            }
                            else {
                                if (data.maternitySetting.isCoInsurance) {
                                    data.coPay.title = vm.hasTxtFileSupport && data.maternitySetting.coPayTypeName
                                        ? data.maternitySetting.coPayTypeName.concat(' ', data.coPay.value) + '%'
                                        : App.localize('CoInsuranceX', data.coPay.value + '%');
                                    data.coPay.text = App.localize('CopayPercentage', data.coPay.value + '%');
                                } else if (!data.maternitySetting.isCoInsurance) {
                                    data.coPay.title = vm.hasTxtFileSupport && data.maternitySetting.coPayTypeName
                                        ? data.maternitySetting.coPayTypeName.concat(' ', data.coPay.value) + '%'
                                        : App.localize('CopayX', data.coPay.value + '%');
                                    data.coPay.text = App.localize('CopayPercentage', data.coPay.value + '%');
                                }
                            }
                        } else {
                            var valueText = $filter('currencyFormat')(data.coPay.value, vm.currencyCode);
                            data.coPay.title = vm.hasTxtFileSupport && data.maternitySetting.coPayTypeName
                                ? data.maternitySetting.coPayTypeName.concat(' ', valueText)
                                : App.localize('CopayX', valueText);
                            data.coPay.text = App.localize('CopayFixedAmount', valueText);
                        }
                    }
                });
            }
        };

        function getDisplayBalancePool(poolBalance, displayArray) {
            var title = ''

            if (_.head(poolBalance.benefitTypes).id === 7)
                title = hospitalizationTitle;
            else if (_.head(poolBalance.benefitTypes).id === 8)
                title = maternityTitle;

            // TODO: Better design? localisation required

            var isBudget = poolBalance.modifierType === 0 || poolBalance.modifierType === 2;
            var cycle = '';
            if (!poolBalance.isUnlimited) {
                switch (poolBalance.modifierCycle) {
                    case 0: cycle = 'every ' + poolBalance.interval + ' years '; break;
                    case 1: cycle = 'annual '; break;
                    case 2: cycle = 'monthly '; break;
                    case 3: cycle = 'daily '; break;
                    case 4: cycle = 'per visit '; break;
                    case 5: cycle = '6 months '; break;
                    case 6: cycle = '4 months '; break;
                    case 7: cycle = '3 months '; break;
                    case 8: cycle = '2 months '; break;
                }
                if (poolBalance.modifierType === 2 || poolBalance.modifierType === 3) cycle += 'overdraft ';
            }
            title += ' (' + cycle + (isBudget ? 'budget' : 'visit') + ')';

            var isEmptyExcess = poolBalance.isExcess && !poolBalance.employeePortion;
            if (!isEmptyExcess) {
                displayArray.push({
                    title: title,
                    isUnlimited: poolBalance.isUnlimited,
                    isExcess: poolBalance.isExcess,
                    isBudget: isBudget,
                    limit: poolBalance.limit,
                    used: poolBalance.used,
                    locked: poolBalance.locked,
                    balance: poolBalance.balance
                });
            }
        }

        vm.viewExclusionList = function (inpatientSettings) {
            $uibModal.open({
                templateUrl: require('/App/host/views/doraemon/common/exclusionList/exclusionList.modal.html'),
                controller: 'host.views.doraemon.common.exclusionListModal as vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    inpatientSettings: inpatientSettings
                }
            });
        };

        vm.viewScheduleOfBenefits = function (serviceType, settings) {
            $uibModal.open({
                templateUrl: require('/App/host/views/doraemon/common/scheduleOfBenefits/scheduleOfBenefits.modal.html'),
                controller: 'host.views.doraemon.common.scheduleOfBenefitsModal as vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    serviceType: serviceType,
                    hospitalizationSetting: settings,
                    maternitySetting: settings
                }
            });
        };

        function onChanges() {
            if (vm.memberId > 0) {
                getBenefitByMemberId(vm.memberId);
            }
            else if (vm.serviceRequestId) {
                getBenefitByServiceRequestId(vm.serviceRequestId);
            }
        }
    };
})();
