(function () {
    angular.module('app').component('hostDoraemonIglProcessingRequestDetailsComponent', {
        templateUrl: require('/App/host/views/doraemon/iglProcessing/requestDetails/requestDetails.component.html'),
        controller: ['$state', '$scope', 'abp.services.app.doraemon', 'Hms.MultiTenancy.Tenant', 'moment', controller],
        controllerAs: 'vm',
        bindings: {
            memberId: '<',
            serviceRequestId: '<',
            onEdit: '&'
        }
    });

    var app = angular.module('app');

    function controller($state, $scope, doraemonSvc, constsTenant, moment) {
        var vm = this;
        vm.constsTenant = constsTenant;

        vm.$onInit = onInit;
        vm.getAdmissionTypeName = getAdmissionTypeName;
        vm.fillContactNumberAndGetDoctorList = fillContactNumberAndGetDoctorList;
        vm.lookupDoctor = lookupDoctor;
        vm.edit = edit;
        vm.save = save;
        vm.cancel = cancel;

        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.loading = 0;
        vm.request = null;
        vm.savedTrigger = 0;
        vm.filteredDoctors = [];
        vm.panelLocations = [];
        vm.panelLocationName = "";
        vm.requestTypeName = "Creation"; // for the first time creation
        vm.admissionTypeName = "";
        vm.selectedDoctorsNames = "";
        vm.attachments = [];
        vm.diagnosis = {
            selected: [],
            icd: []
        };
        vm.firstSave = false;           //Hack: HM-3635 to check whether it is first saving or not

        var preEditRequest = null;
        var preEditDiagnosis = null;
        var preEditDocuments = null;
        var limitSize = 100;

        function onInit() {
            // Initialize data for first time employee applying through doraemon method
            vm.request = {
                employeeId: vm.memberId,
                issueNumber: null,
                panelLocationId: 0,
                contactNumber: null,
                admissionNumber: null,
                requestType: 1, // request type creation
                scheduledAdmissionTime: null,
                admissionType: 1,
                selectedDoctorIds: [],
                estimatedCost: 3000, // default prefilled 3000
                newGuaranteeAmount: 3000, //default prefilled 3000
                requestDate: moment(),
                requestedBy: 'Administrator',
            }
            vm.documents = [];
            vm.newAttachments = [];
            vm.diagnosis.selected.push({ date: new Date() });

            getInitialCreationListValues();
        }

        var fileExtensions = '|' + app.consts.fileUpload.imageExtensions.concat(app.consts.fileUpload.pdfExtensions).join('|') + '|';
        vm.uploadConfig = {
            objectType: 'InpatientIglRequestAttachment',
            itemsLimit: 3,
            accept: 'image/*,application/pdf',
            filters: [{
                name: 'fileFilter',
                fn: function (n) {
                    vm.invalidAttachment = false;
                    var t = '|' + n.type.slice(n.type.lastIndexOf('/') + 1) + '|';
                    if (fileExtensions.indexOf(t) === -1) {
                        vm.invalidAttachment = true;
                        return false;
                    }
                    return true;
                }
            }, {
                name: 'sizeFilter',
                fn: function (n) {
                    vm.invalidAttachmentSize = false;
                    var sizeLimit = 52428800;
                    if (n.size > sizeLimit) {
                        vm.invalidAttachmentSize = true;
                        return false;
                    }
                    return true;
                }
            }]
        };

        $scope.fillContactNumber = function (selectedItem) {
            vm.request.contactNumber = selectedItem.contactNumber;
        }

        $scope.clearContactNumber = function () {
            vm.request.contactNumber = null;
        }

        function fillContactNumberAndGetDoctorList(selectedItem) {
            if (selectedItem === undefined) {
                vm.request.contactNumber = null;
                vm.doctors = null;
            }
            else {
                // handle contactNumber
                let obj = _.find(vm.panelLocations, function (obj) {
                    if (obj.value === parseInt(selectedItem)) {
                        return obj;
                    }
                });
                vm.request.contactNumber = obj.contactNumber;

                //handle doctorList after selecting clinicLocation
                vm.request.selectedDoctorIds = []; // reset to no selection
                vm.loading++;
                doraemonSvc.getDoctorsByClinicLocationId({ id: selectedItem }).success(function (data) {
                    vm.doctors = data.items;
                }).finally(function () {
                    vm.loading--;
                });
            }
        }

        // Get all admission types, panel locations and icd diagnosis
        // Check if is edit or view IGL request
        function getInitialCreationListValues() {
            vm.loading++;
            doraemonSvc.getInitialCreationListValues({
                serviceRequestId: vm.serviceRequestId,
                employeeId: vm.memberId
            }).success(function (data) {
                vm.admissionTypes = data.admissionTypes;
                vm.panelLocations = data.panelLocations;
                vm.diagnosis.icd = _.map(data.diseaseClassifications, function (d) {
                    return {
                        id: d.id,
                        code: d.code,
                        description: '(' + d.code + ') ' + d.description
                    };
                });

                // Condition is added into this api function call to solve time racing issue
                if ((vm.serviceRequestId == null || vm.serviceRequestId == "" || vm.serviceRequestId === undefined) // use lodash _.isNil instead
                    && !(vm.memberId === undefined || vm.memberId == 0)) {
                    // set to edit mode
                    edit();
                }
                else {
                    //serviceRequestId exists
                    vm.isEdit = false;
                    //retrieve serviceRequestDetails cause serviceRequestId is passed in
                    getRequestDetails(vm.serviceRequestId);
                }
            }).finally(function () {
                vm.loading--;
            });
        }

        function getRequestDetails(serviceRequestId) {
            vm.loading++;
            doraemonSvc.getIglRequestDetails({
                id: serviceRequestId
            }).success(function (data) {
                vm.request = {
                    requestType: data.requestType,
                    requestDate: data.requestTime,
                    requestedBy: data.requestedBy,
                    guaranteeLetterNumber: data.guaranteeLetterNumber,
                    admissionNumber: data.admissionNumber,
                    estimatedCost: data.estimatedCost,
                    scheduledAdmissionTime: data.scheduledAdmissionTime,
                    estimatedDischargeTime: data.estimatedDischargeTime,
                    admissionType: data.admissionType,
                    newGuaranteeAmount: data.newGuaranteeAmount,
                    selectedDoctorIds: data.selectedDoctorIds,
                    contactNumber: data.contactNumber,
                    issueNumber: data.issueNumber,
                    employeeId: data.employeeId,
                    clinicLocationId: data.clinicLocationId,
                    accidentTime: data.accidentTime
                };

                vm.documents = _.map(data.supportingDocuments, function (d) {
                    return {
                        externalObjectId: d.externalObjectId,
                        url: d.url,
                        name: d.name,
                        uploader: d.uploader + ' ' + moment(d.uploadedTime).format('L, LT'),
                        applied: d.isApplied
                    };
                });

                vm.diagnosis = {
                    selected: _.map(data.provisionalDiagnosis, function (p) {
                        return {
                            code: p.code,
                            description: p.description,
                            date: p.diagnosisDate
                        };
                    }),
                    icd: _.map(data.diseaseClassifications, function (d) {
                        return {
                            id: d.id,
                            code: d.code,
                            description: '(' + d.code + ') ' + d.description
                        };
                    })
                };

                vm.doctors = data.availableDoctors;

                vm.admissionTypes = data.admissionTypes;

                let obj = _.find(vm.panelLocations, function (obj) {
                    if (obj.value === data.clinicLocationId) {
                        return obj;
                    }
                });

                vm.request.contactNumber = obj.contactNumber;
                vm.panelLocationName = obj.name;
                vm.requestTypeName = data.requestTypeName;
                vm.admissionTypeName = data.admissionTypeName;
                vm.selectedDoctorsNames = data.selectedDoctorsNames;
            }).finally(function (e) {
                vm.loading--;
            });
        }

        function getAdmissionTypeName() {
            if (vm.request && vm.request.admissionType) {
                var admissionType = _.find(vm.admissionTypes, function (t) {
                    return t.id === vm.request.admissionType;
                });

                return admissionType.name;
            }
        }

        function edit() {
            backupInitialStates();
            vm.isEdit = true;
            vm.onEdit({ isEdit: true });
        }

        function save() {
            if (App.isFormValid(vm.requestDetailsForm)) {
                if (vm.documents.length < 1 && vm.newAttachments < 1) {
                    vm.firstSave = true;
                    return;
                }
                if (vm.diagnosis) {
                    tidyDiagnosis();
                }
                if (vm.diagnosis.selected === undefined || vm.diagnosis.selected.length < 1) {
                    abp.notify.error(App.localize('MissingProvisionalDiagnosisError'));
                    //restore in the case where there is no valid diagnosis
                    vm.diagnosis.selected = [{ date: new Date() }];
                    return;
                }

                vm.request.estimatedCost = App.roundAmount(vm.request.estimatedCost);
                vm.request.newGuaranteeAmount = App.roundAmount(vm.request.newGuaranteeAmount);

                var supportingDocuments = [];
                if (vm.documents) {
                    supportingDocuments = _.map(vm.documents, function (d) {
                        return {
                            externalObjectId: d.externalObjectId,
                            isApplied: d.applied
                        }
                    });
                }

                var provisionalDiagnosis = [];
                if (vm.diagnosis) {
                    tidyDiagnosis();
                    provisionalDiagnosis = _.map(vm.diagnosis.selected, function (s) {
                        // Free-text entry has null diagnosis code.

                        return {
                            code: _.isNumber(s.code) ? null : s.code,
                            description: s.description,
                            diagnosisDate: s.date
                        }
                    });
                }

                if (!(vm.serviceRequestId == null || vm.serviceRequestId == "" || vm.serviceRequestId === undefined)) {
                    vm.request.id = vm.serviceRequestId;
                }

                var input = $.extend({},
                    vm.request,
                    { provisionalDiagnosis: provisionalDiagnosis },
                    { newAttachments: vm.newAttachments },
                    { supportingDocuments: supportingDocuments });

                vm.loading++;

                // Update IGL request

                if (!(vm.request.id === undefined || vm.request.id === null)) {
                    doraemonSvc.updateIglRequestDetails(input).success(function (data) {
                        abp.notify.info(App.localize("SuccessfullySaved"));
                        getRequestDetails(data);
                        vm.isEdit = false;
                        vm.onEdit({ isEdit: false });

                        // reload the page to update the BalanceOverviewComponent as well

                        $state.go('host.doraemonIglProcessing', {
                            serviceRequestId: vm.serviceRequestId,
                            employeeId: null
                        }, { reload: true });
                    }).finally(function () {
                        vm.loading--;
                    });
                }

                // Create IGL request
                else {
                    doraemonSvc.createIglRequest(input)
                        .success(function (data) {
                            abp.notify.info(App.localize("SuccessfullySaved"));
                            // getRequestDetails(data.id);
                            vm.serviceRequestId = data;
                            vm.isEdit = false;
                            vm.onEdit({ isEdit: false });

                            // go with serviceRequestId

                            $state.go('host.doraemonIglProcessing', {
                                serviceRequestId: vm.serviceRequestId,
                                employeeId: null
                            }, { reload: true });
                        }).finally(function () {
                            vm.loading--;
                        });
                }
            }
        }

        function cancel() {
            restoreInitialStates();
            vm.isEdit = false;
            vm.onEdit({ isEdit: false });
        }

        function backupInitialStates() {
            preEditRequest = angular.copy(vm.request);
            preEditDiagnosis = angular.copy(vm.diagnosis);
            preEditDocuments = angular.copy(vm.documents);
        }

        function restoreInitialStates() {
            vm.request = preEditRequest;
            vm.diagnosis = preEditDiagnosis;
            vm.documents = preEditDocuments;
        }

        function lookupDoctor(filter) {
            var keyword = _.trim(filter || '');
            if (keyword) {
                var filteredDoctors = [];

                var regexes = _.chain(keyword.split(' '))
                    .map(function (k) {
                        return _.trim(k.toUpperCase());
                    })
                    .filter(function (k) {
                        return k;
                    })
                    .uniq()
                    .map(function (k) {
                        _.escapeRegExp(k);
                        return {
                            token: k,
                            len: k.length,
                            pattern: new RegExp('\\b' + k, 'i')
                        };
                    })
                    .value();

                _.forEach(vm.doctors, function (doctor) {
                    var score = 0;
                    _.forEach(regexes, function (r) {
                        if (r.pattern.test(doctor.name)) {
                            score -= r.len;
                        }
                    });

                    if (score) {
                        filteredDoctors.push({
                            score: score,
                            item: doctor
                        });
                    }
                });

                vm.filteredDoctors = _.chain(filteredDoctors)
                    .sortBy(['score'])
                    .take(30)
                    .map(function (k) {
                        return k.item;
                    })
                    .uniqBy(function (s) {
                        return s.name;
                    })
                    .value();
            } else {
                vm.filteredDoctors = _.take(vm.doctors, limitSize);
            }
        };

        function tidyDiagnosis() {
            vm.diagnosis.selected = _.filter(vm.diagnosis.selected, function (item) {
                return item.code !== undefined;
            });
        }
    }
})();
