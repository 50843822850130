(() => {
  angular
    .module('app')
    .filter('paymentMethod', paymentMethod)
    // For backward compatibility only. Filter name don't need "Filter" suffix.
    // AngularJS automatically appends "Filter" to name for DI.
    .filter('paymentMethodFilter', paymentMethod);

  paymentMethod.$inject = [];

  function paymentMethod() {
    return paymentMethodFilter;

    function paymentMethodFilter(value) {
      switch (value) {
        case 0:
          return App.localize('PaymentMethod_Unknown');
        case 1:
          return App.localize('PaymentMethod_Cash');
        case 2:
          return App.localize('PaymentMethod_Check');
        case 3:
          return App.localize('PaymentMethod_CreditCard');
        case 4:
          return App.localize('PaymentMethod_DebitCard');
        case 5:
          return App.localize('PaymentMethod_BankTransfer');
        case 6:
          return App.localize('PaymentMethod_TelegraphicTransfer');
        case 7:
          return App.localize('PaymentMethod_CreditNote');
        case 8:
          return App.localize('PaymentMethod_BankDraft');
        case 9:
          return App.localize('PaymentMethod_Citibank');
        case 10:
          return App.localize('PaymentMethod_OriginCreditAccount');
        default:
          return value;
      }
    }
  }
})();
