(() => {
  angular.module('app').controller('common.views.profile.changePassword', ChangePasswordController);

  ChangePasswordController.$inject = [
    '$uibModalInstance',
    'abp.services.app.profile',
    'Hms.Users.User',
  ];

  function ChangePasswordController($uibModalInstance, profileSvc, constsUser) {
    const vm = this;

    vm.constsUser = constsUser;
    vm.saving = 0;
    vm.passwordInfo = null;

    vm.cancel = cancel;
    vm.save = save;

    init();

    function init() {}

    function save() {
      vm.saving += 1;
      profileSvc
        .changePassword(vm.passwordInfo)
        .success(() => {
          abp.notify.info(App.localize('YourPasswordHasChangedSuccessfully'));
          $uibModalInstance.dismiss();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
