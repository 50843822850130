import _ from 'lodash';

(() => {
  const componentId = 'hostOperationsDashboardContainerComponent';

  angular.module('app').component(componentId, {
    transclude: true,
    templateUrl: require('./dashboardContainer.component.html'),
    controller: OperationsDashboardContainerController,
    controllerAs: 'vm',
    bindings: {
      // EMPTY
    },
  });

  OperationsDashboardContainerController.$inject = [
    '$scope',
    '$timeout',
    'abp.services.app.commonLookup',
    'moment',
    '$stateParams',
  ];

  function OperationsDashboardContainerController(
    $scope,
    $timeout,
    commonLookupSvc,
    moment,
    $stateParams
  ) {
    const vm = this;

    const widgets = [];
    vm.isOpen = false;
    vm.widgetId = '';
    vm.countryCode = null;
    vm.widgets = [];
    vm.isHost = App.isHost();
    vm.targetWidget = $stateParams.targetWidget;

    vm.permissions = { claims: abp.auth.isGranted('Host.Claims') };
    vm.hasInsuranceModule = abp.setting.getBoolean('Hms.Feature.InsuranceModule');
    vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');
    vm.hasTxtFileGeneration = abp.setting.getBoolean('Hms.Feature.TxtFileGeneration');
    const widgetModel = {
      insuranceClaims: {
        id: 'hostOperationsDashboardInsuranceClaimsComponent',
        routeId: 'insuranceClaims',
        name: App.localize('InsuranceClaims'),
        btnId: 'btnInsuranceClaims',
        btnTarget: '#a',
        hasPermission: vm.hasInsuranceModule,
        active: 0,
        totalCount: 0,
        loading: true
      },
      txtFiles: {
        id: 'hostOperationsDashboardTxtFilesComponent',
        routeId: 'insuranceTxtFiles',
        name: App.localize('InsuranceTxtFiles'),
        btnId: 'btnTxtFiles',
        btnTarget: '#b',
        hasPermission: vm.hasInsuranceModule && vm.hasTxtFileGeneration,
        active: 1,
        totalCount: 0,
        loading: true
      },
      lateSubmissions: {
        id: 'hostOperationsDashboardLateSubmissionsComponent',
        routeId: 'lateSubmissions',
        name: App.localize('LateSubmissions'),
        btnId: 'btnLateSubmissions',
        btnTarget: '#c',
        hasPermission: true,
        active: 2,
        totalCount: 0,
        loading: true
      },
      claims: {
        id: 'hostOperationsDashboardClaimsComponent',
        routeId: 'memberClaims',
        name: App.localize('MemberClaims'),
        btnId: 'btnMemberClaims',
        btnTarget: '#d',
        hasPermission: vm.permissions.claims,
        active: 3,
        totalCount: 0,
        loading: true
      },
      guaranteeLetters: {
        id: 'hostOperationsDashboardGuaranteeLettersComponent',
        routeId: 'guaranteeLetters',
        name: App.localize('GuaranteeLetters'),
        btnId: 'btnGuaranteeLetters',
        btnTarget: '#e',
        hasPermission: true,
        active: 4,
        totalCount: 0,
        loading: true
      },
      specialistClaims: {
        id: 'hostOperationsDashboardSpecialistClaimsComponent',
        routeId: 'specialistClaims',
        name: App.localize('SpecialistClaims'),
        btnId: 'btnSpecialistClaims',
        btnTarget: '#f',
        hasPermission: true,
        active: 5,
        totalCount: 0,
        loading: true
      },
      expiringCheckIns: {
        id: 'hostOperationsDashboardExpiringCheckInsComponent',
        routeId: 'expiringCheckins',
        name: App.localize('ExpiringCheckins'),
        btnId: 'btnExpiringSessions',
        btnTarget: '#g',
        hasPermission: true,
        active: 6,
        totalCount: 0,
        loading: true
      },
      panelIssues: {
        id: 'hostOperationsDashboardClinicIssuesComponent',
        routeId: 'panelIssues',
        name: App.localize('PanelIssues'),
        btnId: 'btnPanelIssues',
        btnTarget: '#h',
        hasPermission: true,
        active: 7,
        totalCount: 0,
        loading: true
      },
      expiringAgreements: {
        id: 'hostOperationsDashboardAgreementExpiriesComponent',
        routeId: 'expiringAgreements',
        name: App.localize('ExpiringAgreements'),
        btnId: 'btnExpiringAgreements',
        btnTarget: '#i',
        hasPermission: true,
        active: 8,
        totalCount: 0,
        loading: true
      },
      duplicateTickets: {
        id: 'hostOperationsDashboardDuplicateTicketsComponent',
        routeId: 'potentialDuplicate',
        name: App.localize('PotentialDuplicate'),
        btnId: 'btnPotentialDuplicate',
        btnTarget: '#j',
        hasPermission: true,
        active: 9,
        totalCount: 0,
        loading: true
      },
    };

    vm.addWidget = addWidget;
    vm.refresh = refresh;
    vm.triggerTab = triggerTab;
    vm.triggerWidget = triggerWidget;
    vm.onChange = onChange;
    vm.lastRefreshTime = moment();

    /* Functions */

    vm.$onInit = () => {
      _.forIn(widgetModel, (d) => {
        if (d.hasPermission) {
          vm.widgets.push(d);
        }
      });

      if (vm.targetWidget) {
        triggerTabByRouteId(vm.targetWidget);
      }

      if (vm.hasRegionalSystemSupport) getEligibleCountries();
    };

    function addWidget(widget) {
      if (widget && isFunction(widget.refresh)) {
        widgets.push(widget);
      }
    }

    function triggerTabByRouteId(routeId) {
      const widget = _.find(widgetModel, (wm) => wm.routeId === routeId) || null;

      if (!widget) return;
      switchTab(widget);
    }

    function getWidgetModel(widgetId) {
      return _.find(widgetModel, { id: widgetId }) || null;
    }

    function isFunction(functionToCheck) {
      const getType = {};
      return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
    }

    function refresh() {
      vm.lastRefreshTime = moment();
      _.each(widgets, (widget) => {
        widget.refresh();
      });
    }

    function triggerTab(input) {
      const widget = getWidgetModel(input);
      if (!widget) return;

      switchTab(widget);
    }

    function switchTab(widget) {
      vm.isOpen = true;
      vm.widgetId = widget.id;
      $scope.active = widget.active;
      $timeout(() => {
        angular.element(`#${widget.btnId}`).click();
      }, 0);
    }

    function triggerWidget(input) {
      const widget = getWidgetModel(input);
      if (!widget) return;

      vm.widgetId = widget.id;
      $scope.active = widget.active;
    }

    // Get all accessible country for the account owner.

    function getEligibleCountries() {
      vm.loading += 1;
      commonLookupSvc
        .getEligibleCountries()
        .success((data) => {
          vm.countries = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function onChange() {
      $timeout(() => {
        vm.refresh();
      }, 0);
    }

    /* End of Functions */
  }
})();
