(() => {
  angular
    .module('app')
    .filter('saleTransactionCategory', saleTransactionCategory)
    // For backward compatibility only. Filter name don't need "Filter" suffix.
    // AngularJS automatically appends "Filter" to name for DI.
    .filter('saleTransactionCategoryFilter', saleTransactionCategory);

  saleTransactionCategory.$inject = [];

  function saleTransactionCategory() {
    return saleTransactionCategoryFilter;

    function saleTransactionCategoryFilter(value) {
      switch (value) {
        case 1:
          return App.localize('Panel');
        case 2:
          return App.localize('Corporate');
        case 3:
          return App.localize('License');
        case 4:
          return App.localize('Introducer');
        case 5:
          return App.localize('SalesPerson');
        case 6:
          return App.localize('Reimbursement');
        case 7:
          return App.localize('CustomerSuccess');
        case 8:
          return App.localize('SpecialistPanel');
        case 9:
          return App.localize('PharmacyPanel');
        case 10:
          return App.localize('HospitalPanel');
        case 11:
          return App.localize('IhmHospitalPanel');
        case 12:
          return App.localize('Subscription');
        case 13:
          return App.localize('Insurer');
        default:
          return value;
      }
    }
  }
})();
