import swal from 'sweetalert';
import PromptQueue from '../../../../../lib/prompt-queue';

(() => {
  angular.module('app').controller('common.views.patientTickets.createEditSpecialistTicket', SpecialistTicketEditController);

  SpecialistTicketEditController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$uibModal',
    '$filter',
    '$window',
    'abp.services.app.patientTicket',
    'abp.services.app.commonLookup',
    'lookupModal',
    'abp.services.app.clinicEmployee',
    'Hms.PatientTickets.PatientTicketType',
    'Hms.MultiTenancy.TenantClinicType',
    'Hms.PatientTickets.PatientTicketReimbursementMode',
    'Hms.PatientTickets.ServiceFeePayor',
    'Hms.PatientTickets.PatientTicketItem',
    'Hms.PaymentAccounts.PaymentAccount',
    'Abp.Configuration.Setting',
    'moment',
  ];

  function SpecialistTicketEditController(
    $scope,
    $state,
    $stateParams,
    $uibModal,
    $filter,
    $window,
    patientTicketSvc,
    commonLookupSvc,
    lookupModal,
    clinicEmployeeSvc,
    enumPatientTicketType,
    enumTenantClinicType,
    enumPatientTicketReimbursementMode,
    enumServiceFeePayor,
    constsPatientTicketItem,
    constsPaymentAccount,
    constsAbpConfig,
    moment
  ) {
    const vm = this;

    vm.constsPatientTicketItem = constsPatientTicketItem;
    vm.constsPaymentAccount = constsPaymentAccount;
    vm.constsAbpConfig = constsAbpConfig;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.defaultMaxAllowance = parseFloat(abp.setting.get('Hms.General.MaxAllowanceAmountLimit'));
    vm.supportEmail = abp.setting.get('Hms.General.ClinicSupportEmail');
    vm.loading = 0;
    vm.saving = 0;
    vm.savingDraft = 0;

    vm.ticketDate = moment();
    vm.ticketNumber = $stateParams.ticketNumber;
    vm.claimId = $stateParams.claimId;
    vm.employeeId = $stateParams.employeeId;
    vm.isCreate = !!$stateParams.employeeId;
    vm.patientSessionId = $stateParams.patientSessionId;
    vm.isLateSubmission = $stateParams.patientSessionId > 0;
    vm.lateSubmissionReason = $stateParams.lateSubmissionReason;
    vm.lateSubmissionRequestorName = $stateParams.lateSubmissionRequestorName;
    vm.doctors = [];
    vm.treatments = [];
    vm.patient = null;
    vm.clinic = {};
    vm.isClinic = App.isClinic();
    vm.isCorporate = App.isCorporate();
    vm.isMcStartDateAlsoCheckedInDate = false;
    vm.isCtTicket = false;
    vm.report = null;
    vm.claim = null;
    vm.needMc = false;
    vm.patientHasMc = null;
    vm.isEditingSpecialistDetail = false;
    vm.hasMultipleCheckInWithSharedPool = false;
    vm.isOutpatientTicket = false;
    vm.isHealthScreeningTicket = false;
    vm.countryCode = null;
    vm.isTaxInclusive = null;

    vm.hasRegionalReimbursementSupport = abp.setting.getBoolean(
      'Hms.Feature.RegionalReimbursementSupport'
    );

    vm.hasInsuranceEnhancementModule = abp.setting.getBoolean(
      'Hms.Feature.InsuranceEnhancementModule'
    );

    vm.enums = {
      patientTicketType: enumPatientTicketType,
      tenantClinicType: enumTenantClinicType,
      patientTicketReimbursementMode: enumPatientTicketReimbursementMode,
      serviceFeePayor: enumServiceFeePayor,
    };

    // Increse this value to trigger specialist detail component method.

    vm.saveSpecialistDetailTrigger = 0;
    vm.permissions = {
      createDoctor: abp.auth.isGranted('Clinic.Doctors.Create'),
      changeServiceFeePayor: abp.auth.isGranted(
        'PatientTickets.Reimbursement.ChangeServiceFeePayor'
      ),
    };

    vm.ticket = {
      ticketType: 0, // Initialize to Normal ticket to hide a section.
      treatments: [],
      sections: [
        {
          treatmentCategories: [{}],
        },
      ],
    };

    vm.diagnoses = {
      selected: [],
      data: [],
    };

    vm.autocomplete = {
      options: {
        fields: ['formatted_address', 'name'],
      },
      callback(place) {
        vm.ticket.manualClinicName = place.name;
        vm.ticket.manualClinicLocation = place.formatted_address;
      },
    };

    vm.uploadConfig = {
      objectType: 'TicketAttachment',
    };

    vm.updatedTicketDate = updatedTicketDate;
    vm.createDoctor = createDoctor;
    vm.changeLocation = changeLocation;
    vm.needMc = needMc;
    vm.nextStage = nextStage;
    vm.getDependentDescription = getDependentDescription;
    vm.getAllowanceDisplay = getAllowanceDisplay;
    vm.getSubtotalAmount = getSubtotalAmount;
    vm.getTaxAmount = getTaxAmount;
    vm.getTotalAmount = getTotalAmount;
    vm.calculateAmountCoverage = calculateAmountCoverage;
    vm.getManualPaymentAmount = getManualPaymentAmount;
    vm.getCoveredAmount = getCoveredAmount;
    vm.getUncoveredAmount = getUncoveredAmount;
    vm.getExceededAmount = getExceededAmount;
    vm.getCoPayAmount = getCoPayAmount;
    vm.getCoPayValueDisplay = getCoPayValueDisplay;
    vm.getCoveredTaxAmount = getCoveredTaxAmount;
    vm.getUncoveredTaxAmount = getUncoveredTaxAmount;
    vm.issueMc = issueMc;
    vm.removeMc = removeMc;
    vm.setMcStartDate = setMcStartDate;
    vm.getMcDurationDescription = getMcDurationDescription;
    vm.getMcStartDateChoice = getMcStartDateChoice;
    vm.isInvalidMc = isInvalidMc;
    vm.save = save;
    vm.isInvalidAttachment = isInvalidAttachment;
    vm.updatePlaceholder = updatePlaceholder;
    vm.clearAll = clearAll;
    vm.checkMultipleCheckInWithSharedPool = checkMultipleCheckInWithSharedPool;
    vm.updateTaxOption = updateTaxOption;
    vm.showActualDateRemark = showActualDateRemark;
    vm.addTreatmentCategory = addTreatmentCategory;
    vm.removeTreatmentCategory = removeTreatmentCategory;
    vm.clearTreatmentCategories = clearTreatmentCategories;
    vm.addSection = addSection;
    vm.removeSection = removeSection;

    init();

    function init() {
      vm.loading += 1;
      patientTicketSvc
        .getTicketForEdit({
          employeeId: $stateParams.employeeId,
          ticketNumber: $stateParams.ticketNumber,
          clinicLocationId: $stateParams.clinicLocationId,
          ticketType: $stateParams.ticketType,
          claimId: $stateParams.claimId,
          patientSessionId: $stateParams.patientSessionId,
          serviceType: $stateParams.serviceType,
        })
        .success((data) => {
          vm.doctors = data.doctors || [];
          vm.banks = data.banks || [];
          vm.patient = data.patient;
          vm.clinic = data.clinic || {};
          vm.treatments = data.treatments || [];
          vm.claim = data.claim;
          vm.corporateBalance = data.corporateBalance;
          vm.currencyCode = data.currencyCode;
          vm.countryCode = data.countryCode;
          vm.ticket = data.ticket;
          vm.report = data.ticket.report;

          // Tax details
          // Tax to apply to SG tickets.
          vm.taxDetails = data.taxDetails;

          vm.tax = {
            taxCode: null,
            taxPercentage: null,
            taxDescription: null,
            isTaxInclusive: null,
          };

          if (vm.ticket.taxCode && vm.ticket.taxPercentage) {
            vm.tax.taxCode = vm.ticket.taxCode;
            vm.tax.taxPercentage = vm.ticket.taxPercentage;
            vm.tax.taxDescription = vm.ticket.taxDescription;
            vm.tax.isTaxInclusive = vm.ticket.isTaxInclusive;
          } else if (vm.taxDetails) {
            vm.tax.taxCode = vm.taxDetails.taxCode;
            vm.tax.taxPercentage = vm.taxDetails.taxPercentage;
            vm.tax.taxDescription = vm.taxDetails.taxDescription;
            vm.tax.isTaxInclusive = vm.taxDetails.isTaxInclusive;
          }

          updateTaxOption(vm.tax.isTaxInclusive);

          if (!vm.isCreate) {
            vm.ticketNumber = data.ticket.ticketNumber;
            vm.employeeId = data.patient.id;
          }
          vm.ticketDate = moment(data.ticket.ticketDate);

          // HACK: HM-3416 Get by service type in future.

          vm.isOutpatientTicket = vm.ticket.serviceType === 5;

          if (vm.isLateSubmission) {
            validateLateSubmission(data.patientSessionId);
          }

          // Specialist ticket items

          if (vm.hasInsuranceEnhancementModule) {
            vm.diagnoses.data = _.map(data.diseaseClassifications, (d) => ({
              code: d.code,
              description: d.description,
              displayName: `(${d.code}) ${d.description}`,
            }));

            vm.diagnoses.selected = _.map(data.ticket.selectedDiagnoses, (d) => ({
              code: d.code,
              description: d.description,
              displayName: d.code !== null ? `(${d.code}) ${d.description}` : `${d.description}`,
            }));

            vm.treatmentCategories = data.specialistTreatmentCategories;
            _.forEach(vm.ticket.sections, (item) => {
              if (item.treatmentCategories.length === 0) addTreatmentCategory(item);
            });

            // Initialise sections for reimbursement ticket creation.

            if (!data.ticket.sections) {
              vm.ticket.sections = [
                {
                  treatmentCategories: [{}],
                },
              ];
            }

            vm.isDraft = data.isDraft;
          }

          vm.attachments = vm.ticket.attachments;
          vm.newAttachments = [];

          if (vm.claim) {
            _.forEach(vm.claim.attachments, (attachment) => {
              const a = attachment;
              a.isImage = a.contentType.startsWith('image/');
            });
          }

          vm.needMc = vm.ticket && vm.ticket.ticketType !== 3 && vm.isOutpatientTicket;

          if ((!vm.isCreate && vm.ticket.ticketType === 0) || vm.isLateSubmission) {
            vm.patientHasMc = vm.ticket.medicalCertificate ? 1 : 0;
          }

          if (vm.ticket.medicalCertificate) {
            vm.ticket.medicalCertificate.startDate = moment(vm.ticket.medicalCertificate.startDate);
            vm.ticket.medicalCertificate.endDate = moment(vm.ticket.medicalCertificate.endDate);
            vm.ticket.checkedInTime = moment(vm.ticket.checkedInTime);
            vm.isMcStartDateAlsoCheckedInDate =
              vm.ticket.medicalCertificate.startDate.diff(
                vm.ticket.checkedInTime.startOf('days'),
                'days'
              ) === 0;
            if (vm.isCreate && vm.claim) {
              vm.ticket.medicalCertificate.remark = vm.claim.treatmentRemarks || 'n/a';
            }
          }

          const strAmt = $filter('currencyFormat')(vm.ticket.coveredAmount, vm.currencyCode);
          vm.reviseCoveredAmountUpwardErrorMessage = App.localize(
            'ReviseCoveredAmountUpwardErrorMessage',
            strAmt
          );

          if (vm.clinic.clinicLocationId) {
            refreshDoctors();
          } else if (vm.isCreate) {
            vm.changeLocation();
          }
          vm.permissions.editActualVisitDate =
            vm.isOutpatientTicket && abp.auth.isGranted('PatientTickets.Host.Specialist.Edit');

          if (vm.isClinic && vm.isCreate) {
            vm.checkMultipleCheckInWithSharedPool();
            vm.multipleCheckInWarningMessage = App.localize(
              'MultipleCheckInWithSharedPoolWarningMessage',
              vm.supportEmail
            );
          }

          // Add Google Place restrictions if no cross-country claims support.

          if (!vm.hasRegionalReimbursementSupport)
            vm.autocomplete.options.componentRestrictions = {
              country: abp.setting.get('Hms.General.DefaultCountryCode'),
            };
        })
        .error(() => {
          $window.history.back();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function refreshDoctors() {
      if (vm.isCreate) {
        patientTicketSvc
          .getLocationDoctors({
            id: vm.clinic.clinicLocationId,
          })
          .success((data) => {
            vm.doctors = data.items;
          })
          .finally(() => {
            if (!vm.doctors.length) {
              vm.createDoctor();
            }
          });
      }
    }

    function showActualDateRemark() {
      return (
        vm.permissions.editActualVisitDate &&
        vm.ticket &&
        vm.ticket.actualVisitDate &&
        moment(vm.ticket.ticketDate).format('LL') !== moment(vm.ticket.actualVisitDate).format('LL')
      );
    }

    function updatedTicketDate() {
      if (vm.isCreate || vm.ticket.ticketType === 1) {
        refreshAllowance();
      }

      if (!vm.ticket.bankingDetails.isInvoiced && vm.ticket.ticketType === 2) {
        checkActivePremiumSubscription();
      }

      if (vm.isCreate && vm.ticket.medicalCertificate) {
        vm.ticket.medicalCertificate.startDate = moment(vm.ticketDate).startOf('day');
        vm.ticket.medicalCertificate.endDate = moment(vm.ticketDate).startOf('day');
      }
    }

    function createDoctor() {
      if (vm.isCreate && vm.permissions.createDoctor) {
        $uibModal
          .open({
            templateUrl: require('../../../../clinic/views/doctors/createOrEditModal.html'),
            controller: 'clinic.views.doctors.createOrEditModal as vm',
            backdrop: 'static',
            resolve: {
              doctorId: null,
              clinicLocationId: vm.clinic.clinicLocationId,
            },
          })
          .result.then((data) => {
            // Auto select newly created doctor when user crete new doctor on pre-emp ticket page.

            if (vm.ticket.ticketType === 3) {
              vm.ticket.doctorId = data.doctorId;
            }
            refreshDoctors();
          });
      }
    }

    function changeLocation() {
      if (vm.ticket.ticketType === 0) {
        lookupModal.open({
          title: App.localize('SelectPanelLocation'),
          serviceMethod: commonLookupSvc.findClinicLocations,
          callback(location) {
            vm.clinic.clinicLocationId = parseInt(location.value, 10);
            vm.clinic.name = location.name;
            vm.clinic.address = location.address;
            vm.clinic.contactNumber = location.contactNumber;
            vm.clinic.midnightSurcharge = location.midnightSurcharge;
            vm.ticket.doctorId = null;
            refreshDoctors();
          },
        });
      }
    }

    function needMc() {
      return (
        vm.ticket &&
        vm.ticket.ticketType !== 3 &&
        (vm.ticket.serviceType === 1 ||
          vm.ticket.serviceType === 2 ||
          vm.isOutpatientTicket ||
          vm.isCtTicket ||
          vm.isInpatient)
      );
    }

    function nextStage() {
      vm.stage += 1;
      if (!vm.needMc()) {
        vm.stage += 1;
      }
    }

    function getDependentDescription() {
      return App.localize(
        'DependentDescription',
        vm.patient.sponsorName,
        $filter('nationalIdFilter')(vm.patient.sponsorNationalId)
      );
    }

    /* Amounts Calculation */

    function getAllowanceDisplay() {
      if (vm.patient !== null) {
        return Math.min(
          vm.patient.allowance,
          vm.ticket.ticketType === 2 && vm.ticket.serviceType === 251
            ? vm.corporateBalance
            : vm.defaultMaxAllowance
        );
      }
      return 0;
    }

    // Refresh patient's allowance. Only for NPR & Manual tickets.

    function refreshAllowance() {
      vm.loading += 1;
      patientTicketSvc
        .getAllowance({
          employeeId: vm.employeeId,
          panelLocationId: vm.clinic ? vm.clinic.clinicLocationId : null,
          ticketType: vm.ticket.ticketType,
          ticketDate: vm.ticketDate,
          serviceType: vm.ticket.serviceType,
        })
        .success((data) => {
          vm.patient.allowance = data.employeeAllowance;
          vm.corporateBalance = data.tenantBalance;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getSubtotalAmount() {
      let subTotalAmount = 0;

      _.each(vm.ticket.sections, (item) => {
        _.each(item.treatmentCategories, (d) => {
          subTotalAmount += (d.coveredAmount || 0) + (d.uncoveredAmount || 0);
        });
      });
      if (vm.hasTax && vm.isTaxInclusive) return subTotalAmount / (1 + vm.tax.taxPercentage / 100);
      return subTotalAmount;
    }

    function getTaxAmount() {
      if (vm.ticket && vm.ticket.sections && vm.isTaxInclusive)
        return vm.getSubtotalAmount() * (vm.tax.taxPercentage / 100);
      if (vm.ticket && vm.ticket.sections && !vm.isTaxInclusive)
        return vm.getSubtotalAmount() * (1 + vm.tax.taxPercentage / 100) - vm.getSubtotalAmount();
      return 0;
    }

    function getTotalAmount() {
      if (vm.ticket && vm.ticket.sections)
        return vm.hasTax && vm.hasTaxAmount
          ? vm.getSubtotalAmount() + vm.getTaxAmount()
          : vm.getSubtotalAmount();
      return 0;
    }

    function calculateAmountCoverage() {
      let claimable = 0;
      let total = 0;
      let uncovered = 0;
      let coPay = 0;
      let exceeded = 0;
      let collectCash = 0;
      let copayValueDisplay = 'NotApplicable';
      let coveredTax = 0;
      let uncoveredTax = 0;
      vm.remindUserUpdateCollectCashAmountMessage = null;

      _.each(vm.ticket.sections, (item) => {
        _.each(item.treatmentCategories, (d) => {
          if (d.coveredAmount) {
            claimable += d.coveredAmount || 0;
          }
          if (d.uncoveredAmount) {
            uncovered += d.uncoveredAmount || 0;
          }
          total += d.coveredAmount || 0;
          total += d.uncoveredAmount || 0;
        });
      });

      // Add tax to total and claimable amount.

      if (vm.hasTax && vm.tax.taxPercentage && vm.hasTaxAmount && !vm.isTaxInclusive) {
        total += (total * vm.tax.taxPercentage) / 100;
        claimable += (claimable * vm.tax.taxPercentage) / 100;
        uncovered += App.roundAmount(uncovered * (vm.tax.taxPercentage / 100));
      }
      if (vm.hasTax && vm.tax.taxPercentage) {
        coveredTax = (claimable / (100 + vm.tax.taxPercentage)) * vm.tax.taxPercentage;
        uncoveredTax = (uncovered / (100 + vm.tax.taxPercentage)) * vm.tax.taxPercentage;
      }

      // NOTE: Co-payment is now based on covered amount.
      // More info: HM-4333.
      // Calculation for co-payment: covered amount * co-pay percentage.

      if (vm.patient && vm.ticket) {
        // Apply co-pay for normal/reimbursement ticket.

        const isNormalOrReimbursement = vm.ticket.ticketType === 0 || vm.ticket.ticketType === 2;

        if (vm.ticket.ticketType !== 3) {
          // Calculate the coPay.

          if (claimable) {
            if (vm.patient.coPay && isNormalOrReimbursement) {
              coPay = vm.patient.coPay.value;
              copayValueDisplay = vm.patient.coPay.isPercentage
                ? `${vm.patient.coPay.value}%`
                : `${$filter('currencyFormat')(vm.patient.coPay.value, vm.currencyCode)}`;

              // Calculate new co-pay amount.

              if (vm.patient.coPay.isPercentage) {
                coPay *= claimable / 100;
              } else {
                coPay = Math.min(claimable, coPay);
              }
            } else if (!vm.patient.coPay && isNormalOrReimbursement && vm.ticket.coPayAmount > 0) {
              coPay = vm.ticket.coPayAmount;
            }
          }
          if (!vm.isCreate && vm.ticket.ticketType === 0) {
            exceeded = Math.max(claimable - coPay - vm.ticket.companyCoveredAmount, 0);
          } else if (vm.patient.allowance > 0) {
            exceeded = Math.max(claimable - coPay - vm.patient.allowance, 0);
          } else {
            exceeded = Math.max(claimable - coPay, 0);
          }

          if (vm.isCreate) {
            collectCash = coPay + uncovered + exceeded;
          } else {
            collectCash = vm.ticket.manualPayment;
          }
        } else if (vm.isCreate) {
          collectCash = vm.patient.isActive ? 0 : total;
        } else {
          collectCash = vm.ticket.manualPayment;
        }
      }

      // Round number to 2 decimal places.

      total = App.roundAmount(total);
      collectCash = App.roundAmount(collectCash);
      coPay = App.roundAmount(coPay);
      exceeded = App.roundAmount(exceeded);

      // Check collect cash against employee limit and alert host

      const patientAllowance = vm.getAllowanceDisplay();
      if (vm.ticket.amount !== total) {
        if (claimable > vm.ticket.coveredAmount && patientAllowance < claimable) {
          vm.remindUserUpdateCollectCashAmountMessage = App.localize(
            'RemindUserUpdateCollectCashAmountMessage',
            $filter('currencyFormat')(patientAllowance, vm.currencyCode)
          );
        }
      }

      return {
        total,
        claimable: App.roundAmount(total - collectCash),
        uncovered,
        manualPayment: collectCash,
        exceeded,
        copay: coPay,
        copayValueDisplay,
        coveredTax,
        uncoveredTax,
      };
    }

    /** End of calculateAmountCoverage */

    function getManualPaymentAmount() {
      return vm.calculateAmountCoverage().manualPayment;
    }

    function getCoveredAmount() {
      return vm.calculateAmountCoverage().claimable;
    }

    function getUncoveredAmount() {
      return vm.calculateAmountCoverage().uncovered;
    }

    function getExceededAmount() {
      return vm.calculateAmountCoverage().exceeded;
    }

    function getCoPayAmount() {
      return vm.calculateAmountCoverage().copay;
    }

    function getCoPayValueDisplay() {
      return vm.calculateAmountCoverage().copayValueDisplay;
    }

    function getCoveredTaxAmount() {
      return vm.calculateAmountCoverage().coveredTax;
    }

    function getUncoveredTaxAmount() {
      return vm.calculateAmountCoverage().uncoveredTax;
    }

    /* End of Amounts Calculation */

    /* Medical Certificate Management */

    function updateMcRemarks() {
      if (vm.ticket.medicalCertificate && !vm.isCtTicket && !vm.claimId) {
        const remark = _.chain(vm.diagnoses.selected)
          .filter((d) => d && d.displayName)
          .map((d) => d.displayName)
          .join(', ')
          .value();
        vm.ticket.medicalCertificate.remark = remark;
      }
    }

    function issueMc() {
      vm.patientHasMc = !vm.patientHasMc;
      const firstDate = moment(
        vm.ticket.ticketType !== 0 ? vm.ticketDate : vm.ticket.checkedInTime
      ).startOf('day');
      vm.ticket.medicalCertificate = {
        startDate: firstDate,
        endDate: firstDate,
      };
      vm.isMcStartDateAlsoCheckedInDate = 1;
      updateMcRemarks();
    }

    function removeMc() {
      vm.ticket.medicalCertificate = null;
      if (vm.ticket.ticketType === 0) {
        vm.patientHasMc = 0;
      }
    }

    function setMcStartDate(offset) {
      const checkInDate = moment(vm.ticket.checkedInTime).startOf('day');
      vm.ticket.medicalCertificate.startDate = checkInDate.add(offset, 'days');

      const mcEndDate = vm.ticket.medicalCertificate.endDate.startOf('day');
      if (mcEndDate.diff(vm.ticket.medicalCertificate.startDate, 'days') < 0) {
        vm.ticket.medicalCertificate.endDate = moment(vm.ticket.medicalCertificate.startDate);
      }
      vm.isMcStartDateAlsoCheckedInDate = offset === 0;
    }

    function getMcDurationDescription() {
      if (vm.ticket.medicalCertificate) {
        const startDate = moment(vm.ticket.medicalCertificate.startDate);
        const endDate = moment(vm.ticket.medicalCertificate.endDate);
        const duration = endDate.diff(startDate, 'days') + 1;
        return App.localize('XDays', duration);
      }
      return '';
    }

    function getMcStartDateChoice(offset) {
      return moment(vm.ticket.checkedInTime).add(offset, 'days').startOf('day');
    }

    function isInvalidMc() {
      return vm.needMc && vm.patientHasMc === null && vm.ticket.ticketType === 0;
    }

    /* End of Medical Certificate Management */

    function createOrEditTicket() {
      let canSubmit = true;
      const prompts = new PromptQueue();

      if (vm.ticket.ticketType === 0 || vm.ticket.ticketType === 3) {
        if (!vm.clinic.clinicLocationId) {
          abp.message.error(App.localize('InvalidPanelLocation'));
          return;
        }

        if (!vm.ticket.doctorId) {
          abp.message.error(
            App.localize(
              vm.ticket.serviceType === 3 ? 'OpticianOptometristNotSelected' : 'DoctorNotSelected'
            )
          );
          return;
        }
      } else {
        vm.clinic = {};
        vm.ticket.doctorId = null;
      }

      if (vm.ticket.ticketType !== 0 && vm.ticketDate > moment()) {
        abp.message.error(App.localize('ClaimDateIsInFuture'));
        return;
      }

      if (vm.ticket.medicalCertificate) {
        const startDate = moment(vm.ticket.medicalCertificate.startDate);
        if (vm.ticket.ticketType === 1) {
          const mcDays = Math.ceil(vm.ticket.medicalCertificate.effectiveMcDays);
          if (Number.isNaN(Number(mcDays)) || mcDays < 0) {
            abp.message.error(App.localize('InvalidEffectiveMcDays'));
            return;
          }
          vm.ticket.medicalCertificate.endDate = moment(startDate).add(mcDays - 1, 'd');
        } else {
          const endDate = moment(vm.ticket.medicalCertificate.endDate);
          if (endDate < startDate) {
            abp.message.error(App.localize('McEndDateEarlierThanStartDate'));
            return;
          }
          vm.ticket.medicalCertificate.effectiveMcDays = endDate.diff(startDate, 'days') + 1;
        }

        const mcBenchmarkDay = vm.ticket.ticketType !== 0 ? vm.ticketDate : vm.ticket.checkedInTime;
        const mcStartDayOffset = startDate.diff(moment(mcBenchmarkDay).startOf('day'), 'days');
        if (mcStartDayOffset < 0) {
          abp.message.error(App.localize('McStartDateEarlierThanTicketDate'));
          return;
        }
        if (mcStartDayOffset > 1) {
          if (vm.ticket.ticketType === 2) {
            prompts.add(
              swal,
              {
                title: App.localize('AreYouSure'),
                text: App.localize('McStartDateTooFarFromTicketDateWarning'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#1ab394',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                closeOnConfirm: true,
                closeOnCancel: true,
              },
              (isConfirm) => {
                canSubmit = isConfirm;
              }
            );
          } else {
            abp.message.error(App.localize('McStartDateTooFarFromTicketDate'));
            return;
          }
        }
      }

      if (vm.claim) {
        if (vm.getTotalAmount() !== vm.claim.receiptAmount) {
          abp.message.error(App.localize('ClaimReceiptAmountMismatchWithTicketAmount'));
          return;
        }
        if (vm.getCoveredAmount() !== vm.ticket.coveredAmount) {
          prompts.add(
            swal,
            {
              title: App.localize('AreYouSure'),
              text: App.localize('ClaimReceiptAmountMismatchWithTicketCoveredAmountWarning'),
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#1ab394',
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
              closeOnConfirm: true,
              closeOnCancel: true,
            },
            (isConfirm) => {
              canSubmit = isConfirm;
            }
          );
        }
      }

      // Validate pre-employment report.

      if (vm.report) {
        $scope.$broadcast('validateForm');
      }

      if (!App.isFormValid($scope.editForm)) {
        abp.message.error(App.localize('OneOrMoreInputValueIsInvalid'));
        return;
      }

      // Filter away attachments marked for deletion.

      const attachments = [];
      _.each(vm.attachments, (attachment) => {
        if (!attachment.isDelete) attachments.push(attachment.id);
      });

      // Construct input request.

      if (vm.isLateSubmission) {
        const draft = angular.toJson(vm.ticket);
        vm.ticket.latePatientTicketSubmission = {
          patientSessionId: vm.patientSessionId,
          reason: vm.lateSubmissionReason,
          requestorName: vm.lateSubmissionRequestorName,
          patientTicketDraftJson: draft,
        };
      }

      const input = {
        doctorId: vm.ticket.doctorId,
        treatments: _.filter(
          vm.ticket.treatments,
          (treatment) =>
            // Discard treatment without id or without remark if it is CT ticket.

            treatment.treatmentId > 0
        ),
        manualPayment: vm.getManualPaymentAmount(),
        clinicLocationId: vm.clinic.clinicLocationId,
        medicalCertificate: vm.ticket.medicalCertificate,
        bankingDetails: vm.ticket.bankingDetails,
        manualClinicName: vm.ticket.manualClinicName,
        manualClinicLocation: vm.ticket.manualClinicLocation,
        manualDoctorName: vm.ticket.manualDoctorName,
        ticketDate: vm.ticketDate,
        ticketType: vm.ticket.ticketType,
        attachments,
        newAttachments: vm.newAttachments,
        latePatientTicketSubmission: vm.ticket.latePatientTicketSubmission
          ? vm.ticket.latePatientTicketSubmission
          : null,
        report: JSON.stringify(vm.report),
        actualVisit: {
          date: vm.ticket.actualVisitDate,
          remark: vm.ticket.actualVisitRemark,
        },
        preTaxAmount: vm.hasTax ? vm.getSubtotalAmount() : vm.getTotalAmount(),
        countryCode: vm.countryCode,
        isTaxInclusive: vm.hasTax ? vm.isTaxInclusive : null,
        requestId: vm.ticket.requestId,
        diagnoses: vm.diagnoses.selected,
        sections: vm.ticket.sections,
      };

      // Only for reimbursement ticket
      // For reimbursement ticket, IsTaxInclusive can be null if NO GST
      // but for normal ticket, it follows panel settings which is either true or false

      if (vm.ticket.ticketType === 2) {
        input.isTaxInclusive = vm.isTaxInclusive === null ? null : vm.isTaxInclusive;
      }

      if (!vm.needMc) {
        input.medicalCertificate = null;
      }

      function createTicket() {
        if (vm.saving !== 0) return;

        convertTreatmentCategoriesToTreatments();

        vm.saving += 1;
        patientTicketSvc
          .createTicket(
            $.extend(input, {
              employeeId: vm.employeeId,
              claimId: vm.claimId,
              serviceType: $stateParams.serviceType,
            })
          )
          .success((data) => {
            vm.isUpdated = true;

            function promptWarning(title, text) {
              // Validations.

              const cleanTitle = _.isString(title) ? title : '';
              const cleanText = _.isString(text) ? text : '';

              $window.onbeforeunload = () => cleanTitle;

              swal(
                {
                  title: cleanTitle,
                  type: 'warning',
                  text: cleanText,
                  showConfirmButton: true,
                },
                () => {
                  $window.onbeforeunload = null; // unregister listener
                }
              );
              App.swal.disableButtons(5);
            }
            if (vm.ticket.ticketType === 0 && vm.getManualPaymentAmount()) {
              promptWarning(
                App.localize(
                  'RememberCollectXPaymentFromPatient',
                  vm.currencyCode + vm.getManualPaymentAmount()
                )
              );
            } else if (vm.ticket.ticketType === 1) {
              if (vm.getTotalAmount() > vm.patient.allowanceDisplay) {
                abp.message.warn(App.localize('ExceededEntitlement'));
              }
            }
            if (data.latePatientTicketSubmissionId) {
              promptLateSubmissionSuccess();
            } else {
              abp.notify.info(App.localize('ClaimXIssued', data.ticketNumber));
              let nextState = 'patientTickets';
              let nextStateData = null;
              if (vm.isClinic) {
                nextState = 'clinic.checkin';
              } else if (data.nextClaimNumber) {
                nextState = 'claimListDetail';
                nextStateData = {
                  claimNumber: data.nextClaimNumber,
                };
              }
              showSuccessModal(
                data.ticketNumber,
                vm.ticket.medicalCertificate !== null,
                nextState,
                nextStateData
              );
            }
          })
          .finally(() => {
            vm.saving -= 1;
          });
      }

      function promptLateSubmissionSuccess() {
        swal(
          {
            title: App.localize('LateSubmissionRequestSent'),
            type: 'success',
            text: `${App.localize('LateSubmissionSuccessNarrative1')}<br />${App.localize(
              'LateSubmissionSuccessNarrative2'
            )}`,
            showConfirmButton: true,
            confirmButtonText: App.localize('Understood'),
            html: true,
          },
          () => {
            window.onbeforeunload = null; // unregister
          }
        );
        $state.go('clinic.panelDashboard');
      }

      function editTicket(remarks) {
        if (vm.saving !== 0) return;

        vm.saving += 1;
        patientTicketSvc
          .editTicket(
            $.extend(input, {
              ticketNumber: vm.ticketNumber,
              modificationRemarks: remarks,
              serviceType: $stateParams.serviceType,
            })
          )
          .success(() => {
            vm.isUpdated = true;
            vm.saveSpecialistDetailTrigger += 1;
            abp.notify.info(App.localize('SuccessfullySaved'));
            showSuccessModal(
              vm.ticketNumber,
              vm.ticket.medicalCertificate !== null,
              'patientTickets',
              null
            );
            swal.close();
          })
          .finally(() => {
            vm.saving -= 1;
          });
      }

      if (vm.isCreate) {
        if (vm.ticket.ticketType === 0 && vm.ticket.serviceType !== 3) {
          if (!vm.ticket.medicalCertificate) {
            const warning = vm.isClinic
              ? App.localize('PanelNoMcReminderWarning')
              : App.localize('NoMcReminderWarning');
            prompts.add(abp.message.confirm, warning, App.localize('AreYouSure'), (d) => {
              canSubmit = d;
              return d;
            });
          } else if (!vm.isMcStartDateAlsoCheckedInDate) {
            prompts.add(
              abp.message.confirm,
              App.localize('MCStartDateDifferentFromCheckInDate'),
              App.localize('AreYouSure'),
              (d) => {
                canSubmit = d;
                return d;
              }
            );
          }
        } else if (vm.ticket.ticketType === 3 && !vm.report.courieredDate) {
          prompts.add(
            abp.message.confirm,
            App.localize('NoCourieredDateWarning'),
            App.localize('AreYouSure'),
            (d) => {
              canSubmit = d;
              return d;
            }
          );
        }

        vm.saving += 1;
        prompts.run(() => {
          vm.saving -= 1;
          if (canSubmit) createTicket();
        });
      } else {
        prompts.add(
          swal,
          {
            title: vm.isClinic ? App.localize('EditPanelClaim') : App.localize('EditPatientTicket'),
            text: vm.isClinic
              ? App.localize('WhyEditPanelClaim')
              : App.localize('WhyEditPatientTicket'),
            type: 'input',
            showCancelButton: true,
            closeOnConfirm: false,
            confirmButtonColor: '#1ab394',
            inputPlaceholder: App.localize('PleaseExplain'),
          },
          (inputValue) => {
            vm.saving -= 1;
            vm.saving += 1;
            if (inputValue === false) {
              vm.saving -= 1;
              return false;
            }
            if ($.trim(inputValue || '') === '') {
              swal.showInputError(App.localize('PleaseExplain'));
              return false;
            }

            vm.saving -= 1;
            editTicket($.trim(inputValue));
            return true;
          }
        );

        vm.saving += 1;
        prompts.run();
      }
    }

    function save() {
      createOrEditTicket();
    }

    function showSuccessModal(ticketNumber, hasMc, nextState, nextStateData) {
      let callbackData = null;
      const obj = {
        ticketNumber,
        ticketType: vm.ticket.ticketType,
        countryCode: vm.countryCode,
        hasMc,
        insurerId: vm.ticket.insurerId,
        callback(data) {
          callbackData = data;
        },
      };

      $uibModal
        .open({
          templateUrl: require('../createEditSuccessModal.html'),
          controller: 'common.views.patientTickets.createEditSuccessModal as vm',
          backdrop: 'static',
          size: 'sm',
          resolve: {
            ticket() {
              return obj;
            },
          },
        })
        .result.then(() => {
          if (callbackData) {
            $state.go(callbackData.stateName, callbackData.stateData);
          } else {
            $state.go(nextState, nextStateData);
          }
        });
    }

    function isInvalidAttachment() {
      return (
        vm.ticket.ticketType === 3 &&
        !vm.newAttachments.length &&
        (!vm.attachments.length || _.every(vm.attachments, (d) => d.isDelete))
      );
    }

    function updatePlaceholder($event, treatment) {
      const placeholder = treatment ? vm.getRemarkPlaceholder(treatment) : '';

      $($event.target).find('input').attr('placeholder', placeholder);
    }

    function checkActivePremiumSubscription() {
      vm.loading += 1;
      patientTicketSvc
        .checkActivePremiumSubscription({
          subsidiaryId: vm.patient.corporate.subsidiaryId,
          ticketDate: vm.ticketDate,
        })
        .success((data) => {
          const isChanged = vm.patient.corporate.hasActivePremiumSubscription !== data;
          if (isChanged) {
            vm.patient.corporate.hasActivePremiumSubscription = data;

            // Reset existing and if has active subsription set service fee payor to corporate.

            vm.ticket.bankingDetails.serviceFeePayor = !vm.patient.corporate
              .hasActivePremiumSubscription
              ? vm.patient.corporate.defaultServiceFeePayor
              : 1;
            vm.ticket.bankingDetails.serviceFeePayorRemark = null;
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function clearAll() {
      $state.reload();
    }

    function validateLateSubmission(patientSessionId) {
      if (
        vm.patientSessionId !== patientSessionId ||
        _.isNil($stateParams.lateSubmissionRequestorName) ||
        _.isNil($stateParams.lateSubmissionReason)
      ) {
        abp.message.error(App.localize('MissingLateSubmissionInformation'));
        $state.go('clinic.panelDashboard');
      }
    }

    function checkMultipleCheckInWithSharedPool() {
      vm.loading += 1;
      clinicEmployeeSvc
        .checkHasMultipleCheckInWithSharedPool({
          panelLocationId: vm.clinic.clinicLocationId,
          employeeId: vm.employeeId,
          ticketDate: vm.ticketDate,
          serviceType: vm.ticket.serviceType,
        })
        .success((data) => {
          vm.hasMultipleCheckInWithSharedPool = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function updateTaxOption(value) {
      vm.isTaxInclusive = value;
      vm.hasTax = value !== null;
      vm.hasTaxAmount = value !== null;
    }

    function addTreatmentCategory(record) {
      const r = record;

      if (!r.treatmentCategories) {
        r.treatmentCategories = [];
      }

      r.treatmentCategories.push({});
    }

    function removeTreatmentCategory(record, treatmentId) {
      const r = vm.ticket.sections[record];
      r.treatmentCategories.splice(treatmentId, 1);
    }

    function clearTreatmentCategories(category) {
      const c = category;
      c.treatmentCategories = [];
      addTreatmentCategory(c);
    }

    function addSection() {
      vm.ticket.sections.push({
        treatmentCategories: [{}],
      });
    }

    function removeSection(sectionId) {
      vm.ticket.sections.splice(sectionId, 1);
    }

    // Helper function to sync-up inputs for CreateTicket API.

    function convertTreatmentCategoriesToTreatments() {
      _.forEach(vm.ticket.sections, (s) => {
        s.treatments = s.treatmentCategories;

        _.forEach(s.treatments, (t) => {
          t.coveredDescription = t.coveredRemarks;
          t.uncoveredDescription = t.uncoveredRemarks;
        });
      });
    }
  }
})();
