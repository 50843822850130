(() => {
  angular.module('app').controller('host.views.forms.page', FormsPageController);

  FormsPageController.$inject = [
    'abp.services.app.formTemplate',
    'Hms.FormTemplates.FormNames',
    'Hms.Services.ServiceType',
    'Hms.FormTemplates.TargetUserType',
  ];

  function FormsPageController(
    formTemplateSvc,
    enumFormNames,
    enumServiceType,
    enumTargetUserType
  ) {
    const vm = this;

    vm.loading = 0;
    vm.formNames = [];

    vm.enums = {};
    vm.enums.formNames = enumFormNames;
    vm.enums.serviceType = enumServiceType;
    vm.enums.targetUserType = enumTargetUserType;

    vm.check = true;
    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: angular.module('app').consts.grid.defaultPageSizes,
      paginationPageSize: angular.module('app').consts.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      resizable: true,
      columnDefs: [
        {
          name: 'Actions',
          displayName: 'Actions',
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          allowCellFocus: false,
          cellTemplate: 'actionForm',
        },
        {
          displayName: App.localize('Name'),
          field: 'name',
          minWidth: 120,
          cellTemplate: 'nameTemplate',
        },
        {
          displayName: App.localize('ServiceType'),
          field: 'serviceType',
          minWidth: 150,
          cellTemplate: 'serviceTypeTemplate',
        },
        {
          displayName: App.localize('UserType'),
          field: 'targetUserType',
          minWidth: 120,
          cellTemplate: 'userTypeTemplate',
        },
        {
          displayName: App.localize('Status'),
          field: 'isActive',
          minWidth: 120,
          cellTemplate: 'statusTemplate',
        },
        {
          displayName: App.localize('LastUpdated'),
          field: 'lastModificationTime',
          cellFilter: "momentFormat: 'L LT'",
          minWidth: 120,
        },
      ],
      data: [],
    };

    vm.deleteForm = deleteForm;

    init();

    function init() {
      getAllFormTemplates();
      getAllFormNames();
    }

    function getAllFormTemplates() {
      vm.loading += 1;
      formTemplateSvc
        .getAllFormTemplate({})
        .success((data) => {
          vm.gridOptions.data = data.items;
          vm.gridOptions.totalCount = data.items.length;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getAllFormNames() {
      formTemplateSvc.getAllFormNames({}).success((data) => {
        vm.formNames = data.items;
      });
    }

    function getDisplayFormName(formNameEnum) {
      const form = _.find(vm.formNames, ['value', formNameEnum]);
      return form.name;
    }

    function deleteForm(form) {
      abp.message.confirm(
        App.localize('FormDeleteWarningMessage', getDisplayFormName(form.name)),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            formTemplateSvc
              .deleteFormTemplate({
                id: form.id,
              })
              .success(() => {
                getAllFormTemplates();
                abp.notify.success(App.localize('SuccessfullyDeleted'));
              });
          }
        }
      );
    }
  }
})();
