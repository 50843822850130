(function () {
    var controllerId = 'clinic.views.patient.checkIn.patientCard.checkInConfirmModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$uibModalInstance', '$state',
        function ($uibModalInstance, $state) {
            var vm = this;

            vm.checkIn = checkIn;
            vm.close = close;
            vm.redirectToPanelDashboard = redirectToPanelDashBoard;

            /* Functions */

            function checkIn() {
                $uibModalInstance.close({ isCheckIn: true });
            }

            function redirectToPanelDashBoard() {
                $state.go('clinic.panelDashboard');
                $uibModalInstance.dismiss();
            }

            function close() {
                $uibModalInstance.dismiss();
            }

            /* End of functions */
        }
    ]);
})();
