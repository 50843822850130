(function () {
    var controllerId = 'host.views.publicUsers.detailModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'publicUser',
        function ($scope, $uibModalInstance, publicUser) {
            var vm = this;
            vm.publicUser = publicUser;

            vm.close = function () {
                $uibModalInstance.dismiss();
            };
        }
    ]);
})();