(function () {
  'use strict';

  var module = angular.module('revenue', ['ui.router']);

  module.config([
    '$stateProvider',
    '$urlRouterProvider',
    function ($stateProvider, $urlRouterProvider) {
      $stateProvider
        .state('revenue', {
          abstract: true,
          template: '<div ui-view class="ui-view-wrapper"></div>',
        })
        .state('revenue.subscriptions', {
          abstract: true,
          template: '<div ui-view class="ui-view-wrapper"></div>',
        });

      if (abp.auth.isGranted('Hms.Revenue')) {
        if (abp.auth.isGranted('Hms.Revenue.Corporate')) {
          $stateProvider.state('revenue.corporates', {
            url: '/revenue/corporates?view&filter&agentId&corporateId&section',
            templateUrl: require('/App/modules/revenue/views/corporates/index.html'),
            dynamic: true,
            params: {
              view: { value: 'All' },
              agentId: { value: null },
              corporateId: { value: null },
              section: { value: 'overview' },
            },
          });

          if (abp.setting.getBoolean('Hms.Feature.SubscriptionManagementEnhancement')) {
            $stateProvider.state('revenue.subscriptions.create', {
              url: '/revenue/corporates/{corporateId}/subscriptions/create',
              templateUrl: require('./views/subscriptions/create/create.page.html'),
            });
          } else {
            $stateProvider.state('revenue.subscriptions.create', {
              url: '/revenue/corporates/{corporateId}/subscriptions/old/create',
              templateUrl: require('./views/subscriptions/create/index.html'),
            });
          }

          $stateProvider.state('revenue.subscriptions.detail', {
            url: '/revenue/subscriptions/{subscriptionId}',
            templateUrl: require('./views/subscriptions/detail/detail.page.html'),
          });
        
          $stateProvider.state('oldSubscriptionDetails', {
            url: '/revenue/subscriptions/detail/old/{subscriptionId}',
            templateUrl: require('./views/subscriptions/detail/index.html'),
          });

          if (abp.setting.getBoolean('Hms.Feature.SubscriptionManagementEnhancement')) {
            $stateProvider.state('revenue.subscriptions.edit', {
              url: '/revenue/subscriptions/{subscriptionId}/edit',
              templateUrl: require('./views/subscriptions/edit/edit.page.html'),
            });
          }

          if (abp.auth.isGranted('Hms.Revenue.Agent')) {
            $stateProvider.state('revenue.agents', {
              url: '/revenue/agents?view&filter&{skipCount:int}&{maxResultCount:int}&sorting&{type:int}',
              templateUrl: require('/App/modules/revenue/views/agents/index.html'),
              dynamic: true,
              params: {
                skipCount: { value: 0 },
                maxResultCount: { value: module.consts.grid.defaultPageSize },
                type: { value: null },
                view: { value: 'Active' },
              },
            });
          }

          if (abp.auth.isGranted('Hms.Revenue.SubscriptionPlan')) {
            if (abp.setting.getBoolean('Hms.Feature.SubscriptionManagementEnhancement')) {
              $stateProvider.state('revenue.subscriptionPlans', {
                url: '/revenue/subscriptionPlans?view&{skipCount:int}&{maxResultCount:int}&sorting',
                templateUrl: require('./views/subscriptionPlans/subscriptionPlans.page.html'),
                dynamic: true,
                params: {
                  view: { value: 'Active' },
                  skipCount: { value: 0 },
                  maxResultCount: { value: module.consts.grid.defaultPageSize },
                },
              });

              $stateProvider.state('revenue.createSubscriptionPlan', {
                url: '/revenue/subscriptionPlans/create',
                templateUrl: require('./views/subscriptionPlans/create/create.page.html'),
              });

              $stateProvider.state('revenue.subscriptionPlanDetails', {
                url: '/revenue/subscriptionPlans/detail/{subscriptionPlanId}',
                templateUrl: require('./views/subscriptionPlans/detail/detail.page.html'),
                dynamic: true,
              });
            } else {
              $stateProvider.state('revenue.subscriptionPlans', {
                url: '/revenue/subscriptionPlans?view',
                templateUrl: require('./views/subscriptionPlans/index.html'),
                dynamic: true,
                params: {
                  view: { value: 'Active' },
                },
              });
            }
          }

          if (abp.auth.isGranted('Hms.Revenue.Settings')) {
            $stateProvider.state('revenue.settings', {
              url: '/revenue/settings',
              templateUrl: require('/App/modules/revenue/views/settings/index.html'),
            });
          }
        }
      }
    },
  ]);
})();
