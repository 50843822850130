(() => {
  angular
    .module('app')
    .controller('common.views.specialistClaims.index', SpecialistClaimsPageController);

  SpecialistClaimsPageController.$inject = [
    '$scope',
    '$stateParams',
    '$state',
    '$timeout',
    'abp.services.app.specialistClaim',
    'Hms.SpecialistClaims.SpecialistClaimStatus',
    'Hms.ServiceRequests.ServiceRequestType',
    'Hms.ServiceRequests.ServiceRequestStatus',
    'Hms.BillingStatus',
    'Hms.ServiceRequests.ServiceRequestQueryStatus',
    'Hms.Services.ServiceType',
    'moment',
    'appConstants',
    'Hms.PatientTicketBillings.BillStatus',
  ];

  function SpecialistClaimsPageController(
    $scope,
    $stateParams,
    $state,
    $timeout,
    specialistClaimSvc,
    enumSpecialistClaimStatus,
    enumServiceRequestType,
    enumServiceRequestStatus,
    enumBillingStatus,
    enumServiceRequestQueryStatus,
    enumServiceType,
    moment,
    appConstants,
    enumBillStatus
  ) {
    const vm = this;

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.hasSpecialistWorkflowEnhancement = abp.setting.getBoolean(
      'Hms.Feature.SpecialistWorkflowEnhancement'
    );
    vm.loading = 0;
    vm.exporting = 0;
    vm.isHost = App.isHost();
    vm.isClinic = App.isClinic();
    vm.isCorporate = App.isCorporate();
    vm.isSpecialist = App.isSpecialist();
    vm.isInpatient = App.isInpatient();

    vm.hasAccessFinanceTransactionData = abp.setting.getBoolean(
      'Hms.Feature.AccessFinanceTransactionData'
    );

    vm.enums = {
      specialistClaimStatus: enumSpecialistClaimStatus,
      serviceRequestType: enumServiceRequestType,
      serviceRequestStatus: enumServiceRequestStatus,
      billingStatus: enumBillingStatus,
      serviceRequestQueryStatus: enumServiceRequestQueryStatus,
      billStatus: enumBillStatus,
      serviceType: enumServiceType,
    };

    vm.applicationHasSpecialistWorkflowEnhancement = abp.setting.getBoolean(
      'Hms.Feature.SpecialistWorkflowEnhancement'
    );
    vm.hasSpecialistEnhancement2_1 = abp.setting.getBoolean('Hms.Feature.SpecialistEnhancement2_1');

    // TODO: Review this

    vm.requestParams = {
      serviceType: $stateParams.serviceType,
      status: _.join($stateParams.claimStatus, ','),
      letterNumber: $stateParams.letterNumber,
      ticketNumber: $stateParams.ticketNumber,
      claimNumber: $stateParams.claimNumber,
      filter: $stateParams.filter || '',
      transactionNumber: $stateParams.transactionNumber,
      corporateBillingStatus: $stateParams.corporateBillingStatus,
      specialistBillingStatus: $stateParams.specialistBillingStatus,
      claimStartDate: $stateParams.claimStartDate,
      claimEndDate: $stateParams.claimEndDate,
      patient: $stateParams.patient,
      panelLocationId: $stateParams.panelLocationId,
      disciplineId: $stateParams.disciplineId,
      referenceNumber: $stateParams.referenceNumber,
      // Amount Filter: BilledAmount
      minBilledAmount: parseFloat($stateParams.minBilledAmount) || null,
      maxBilledAmount: parseFloat($stateParams.maxBilledAmount) || null,
      organizationId: $stateParams.organizationId,
      skipCount: $stateParams.skipCount || 0,
      maxResultCount: $stateParams.maxResultCount || appConstants.grid.defaultPageSize,
      sorting: $stateParams.sorting,
      queryStatus: $stateParams.queryStatus,
      requestStatus: $stateParams.requestStatus,
    };
    vm.advancedFiltersAreShown = false;

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSizes,
      paginationPageSize: appConstants.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      suppressMultiSort: true,
      resizable: true,
      columnDefs: [
        {
          name: 'Actions',
          displayName: 'Actions',
          enableSorting: false,
          width: 75,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'viewTemplate',
        },
        {
          displayName: App.localize('ClaimNumber'),
          enableSorting: false,
          field: 'claimNumber',
          minWidth: 100,
        },
        {
          displayName: App.localize('ClaimDate'),
          field: 'claimDate',
          minWidth: 80,
        },
        {
          displayName: App.localize('GuaranteeLetterNumber'),
          enableSorting: false,
          field: 'guaranteeLetterNumber',
          minWidth: 100,
        },
        {
          displayName: App.localize('Type'),
          enableSorting: false,
          field: 'serviceType',
          cellTemplate: 'serviceTypeTemplate',
          minWidth: 60,
          width: 60,
        },
        {
          displayName: App.localize('TicketNumber'),
          enableSorting: false,
          field: 'ticketNumber',
          visible: vm.isHost,
          minWidth: 100,
        },
        {
          displayName: App.localize('TicketCreationTime'),
          enableSorting: false,
          field: 'ticketCreationTime',
          visible: vm.isHost,
          minWidth: 100,
          cellTemplate: 'ticketCreationTimeTemplate'
        },
        {
          displayName: App.localize('ClaimStatus'),
          enableSorting: false,
          field: 'requestStatus',
          minWidth: 100,
          cellTemplate: 'requestStatusTemplate',
        },
        {
          displayName: App.localize('QueryStatus'),
          enableSorting: false,
          field: 'latestQueryStatus',
          minWidth: 80,
          cellTemplate: 'latestQueryTemplate',
        },
        {
          displayName: App.localize('Aging'),
          enableSorting: false,
          field: 'agingDuration',
          visible: vm.isHost,
          minWidth: 60,
        },
        {
          displayName: App.localize('ProcessedDate'),
          field: 'processedTime',
          visible: vm.isHost,
          minWidth: 100,
          cellTemplate: 'processedTimeTemplate'
        },
        {
          displayName: App.localize('Patient'),
          enableSorting: false,
          field: 'patientName',
          minWidth: 120,
          cellTemplate: 'patientTemplate',
        },
        {
          displayName: App.localize('Subsidiary'),
          enableSorting: false,
          field: 'patientSubsidiaryName',
          minWidth: 120,
        },
        {
          displayName: App.localize('PanelLocation'),
          enableSorting: false,
          field: 'panelLocationName',
          minWidth: 120,
        },
        {
          displayName: App.localize('HospitalBillReferenceNo'),
          enableSorting: false,
          field: 'referenceNumber',
          minWidth: 100,
        },
        {
          displayName: App.localize('TotalClaimAmount'),
          enableSorting: false,
          field: 'billAmount',
          cellFilter: 'currencyFormat: grid.appScope.currencyCode',
          minWidth: 100,
        },
        {
          displayName: App.localize('TotalCoveredAmount'),
          enableSorting: false,
          field: 'totalCoveredAmount',
          cellFilter: 'currencyFormat: grid.appScope.currencyCode',
          minWidth: 100,
        },
        {
          name: App.localize('OrganizationBillingStatus'),
          displayName: App.localize('OrganizationBillingStatus'),
          enableSorting: false,
          field: 'organizationBillingStatus',
          visible: vm.isHost,
          minWidth: 100,
          cellTemplate: 'newOrganizationBillingStatusTemplate',
        },
        {
          name: App.localize('PanelBillingStatus'),
          displayName: vm.isSpecialist
            ? App.localize('BillingStatus')
            : App.localize('PanelBillingStatus'),
          enableSorting: false,
          field: 'panelBillingStatus',
          minWidth: 100,
          cellTemplate: 'newPanelBillingStatusTemplate',
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          vm.requestParams.sorting =
            callback.length && callback[0].field
              ? `${callback[0].field} ${callback[0].sort.direction}`
              : null;
          vm.getSpecialistClaims();
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, vm.getSpecialistClaims)
        );
      },
      data: [],
    };

    vm.dateRangeOptions = App.createDateRangePickerOptions();
    vm.dateRangeModel = {
      startDate: $stateParams.startDate ? moment($stateParams.claimStartDate) : null,
      endDate: $stateParams.endDate ? moment($stateParams.claimStartDate) : null,
    };

    vm.specialistDisciplines = [];
    vm.panelLocations = [];
    vm.organizations = [];

    vm.getSpecialistClaims = getSpecialistClaims;
    vm.exportToExcel = exportToExcel;
    vm.getDateTimeString = getDateTimeString;

    init();

    function init() {
      $timeout(() => {
        vm.gridOptions.paginationCurrentPage = Math.floor(
          vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1
        );
      });

      getListingOptions();
    }

    function getListingOptions() {
      vm.loading += 1;
      specialistClaimSvc
        .getClaimListingOptions()
        .success((data) => {
          vm.specialistDisciplines = data.disciplines;
          vm.panelLocations = data.panelLocations;
          vm.organizations = data.organizations;

          if (vm.hasSpecialistWorkflowEnhancement) {
            vm.requestStatuses = data.requestStatuses;
            vm.queryStatuses = data.queryStatuses;
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getRequestInput() {
      const input = $.extend({}, vm.requestParams);
      input.claimStartDate = vm.dateRangeModel.startDate;
      input.claimEndDate = vm.dateRangeModel.endDate;

      if (vm.hasSpecialistWorkflowEnhancement) {
        input.requestStatus = [];
        if (vm.requestParams.requestStatus) {
          input.requestStatus.push(vm.requestParams.requestStatus);
        } else if ($stateParams.requestStatus) {
          input.requestStatus.push($stateParams.requestStatus);
        }

        if (vm.isHost) {
          if (_.isEqual(vm.requestParams.requestStatus, '97')) {
            // Schedule approval

            input.requestStatus = [6];
            input.scheduleApprovalOnly = true;
            input.lateSubmissionOnly = false;
            input.hasQuery = false;
          }
        } else if (_.isEqual(vm.requestParams.requestStatus, '99')) {
          // Needs attention (partial approval and pending approval
          // with query (excludes schedule approval request))

          input.requestStatus = [1, 5];
          input.scheduleApprovalOnly = false;
          input.lateSubmissionOnly = false;
          input.hasQuery = true;
        }

        if (_.isEqual(vm.requestParams.requestStatus, '98')) {
          // Late submission

          input.requestStatus = [1];
          input.scheduleApprovalOnly = false;
          input.lateSubmissionOnly = true;
          input.hasQuery = false;
        }
      } else {
        input.status = input.status ? _.map(_.split(input.status, ','), (d) => +d) : null;
        if (vm.isHost && input.status) {
          input.scheduleApprovalOnly = _.some(input.status, (d) => d === 4);
          if (input.scheduleApprovalOnly) input.status = null;
        }
      }

      registerStateParams();

      return input;
    }

    function getSpecialistClaims() {
      const input = getRequestInput();

      vm.loading += 1;
      specialistClaimSvc
        .getClaims(input)
        .success((data) => {
          vm.gridOptions.totalItems = data.totalCount;
          vm.gridOptions.data = data.items;
          vm.panelLocations = data.panelLocations;
          vm.organizations = data.organizations;

          if (vm.isHost) {
            _.each(vm.gridOptions.data, (gridData) => {
              const d = gridData;
              if (d.scheduledApprovalDate) {
                const status =
                  d.specialistClaimScheduledApprovalStatus === 2 || d.scheduledApprovalStatus === 5
                    ? App.localize('PartialApproval')
                    : App.localize('FullApproval');
                d.scheduledApprovalTooltip = App.localize(
                  'ScheduledApproval_Tooltip',
                  status.toLowerCase(),
                  moment(d.scheduledApprovalDate).format('L')
                );
              }
            });
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function registerStateParams() {
      const startDate = vm.dateRangeModel.startDate
        ? vm.dateRangeModel.startDate.format('YYYY-MM-DD')
        : null;
      const endDate = vm.dateRangeModel.endDate
        ? vm.dateRangeModel.endDate.format('YYYY-MM-DD')
        : null;

      $state.transitionTo(
        $state.current,
        {
          serviceType: vm.requestParams.serviceType,
          claimStatus: vm.requestParams.status ? _.split(vm.requestParams.status, ',') : null,
          letterNumber: vm.requestParams.letterNumber,
          ticketNumber: vm.requestParams.ticketNumber,
          claimNumber: vm.requestParams.claimNumber,
          transactionNumber: vm.requestParams.transactionNumber,
          corporateBillingStatus: vm.requestParams.corporateBillingStatus,
          panelBillingStatus: vm.requestParams.panelBillingStatus,
          claimStartDate: startDate,
          claimEndDate: endDate,
          patient: vm.requestParams.employee,
          disciplineId: vm.requestParams.disciplineId,
          referenceNumber: vm.requestParams.referenceNumber,
          minAmount: vm.requestParams.minBilledAmount,
          maxAmount: vm.requestParams.maxBilledAmount,
          skipCount: vm.requestParams.skipCount,
          maxResultCount: vm.requestParams.maxResultCount,
          sorting: vm.requestParams.sorting,
          panelLocationId: vm.requestParams.panelLocationId,
          organizationId: vm.requestParams.organizationId,
          queryStatus: vm.requestParams.queryStatus,
        },
        {
          notify: false,
        }
      );
    }

    function exportToExcel() {
      const input = getRequestInput();

      vm.exporting += 1;
      specialistClaimSvc
        .getClaimsToExcel(input)
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.exporting -= 1;
        });
    }

    // HM-4437 TODO: Retrieve claim's query status and add cell template (in cshtml)

    function getDateTimeString(date, timeZoneId) {
      return App.getDateTimeString(date, timeZoneId);
    }
  }
})();
