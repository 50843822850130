import swal from 'sweetalert';
import PromptQueue from '../../../../../../lib/prompt-queue';

(() => {
  angular.module('app').component('commonInpatientTicketsDetailNprTicketInfoComponent', {
    templateUrl: require('./nprTicketInfo.component.html'),
    controller: NprTicketInfoController,
    controllerAs: 'vm',
    bindings: {
      ticket: '<',
      categories: '<',
      diseaseClassifications: '<',
      patient: '<',
      organization: '<',
      claimNumber: '<',
      coPay: '<',
      roomAndBoardCharges: '<',
      hasInsurerClaim: '<',
      isTallied: '=?',
      checkFormFilled: '&?',
      isEdit: '<',
      isFromTicketView: '<', // Indicates whether this component is viewed via viewing inpatient patient ticket.
      triggerEdit: '<',
      triggerCancel: '<',
      triggerSave: '<',
      onSave: '&',
      treatments: '<',
      hospitalizationBenefits: '<',
      coPayTypeName: '<',
      isCreate: '<',
    },
  });

  NprTicketInfoController.$inject = [
    '$scope',
    '$state',
    '$filter',
    'abp.services.app.inpatientPatientTicket',
    '$uibModal',
    'Hms.Employees.EmployeeState',
    'moment',
  ];

  function NprTicketInfoController(
    $scope,
    $state,
    $filter,
    inpatientPatientTicketSvc,
    $uibModal,
    enumEmployeeState,
    moment
  ) {
    const vm = this;

    vm.currencyCode = vm.ticket.currencyCode;
    vm.loading = 0;
    vm.saving = 0;
    vm.isHost = App.isHost();
    vm.filteredDoctors = [];
    vm.hasCoPay = false;
    vm.hasState = false;
    vm.uploadConfig = {
      objectType: 'TicketAttachment',
    };
    vm.newDocuments = [];
    vm.roomCategories = [];
    vm.procedureCategories = [];
    vm.doctorProcedureCategories = [];
    vm.hospitalChargesCategories = [];
    vm.accommodationChargesTreatmentCategories = [];
    vm.inHospitalDoctorVisitTreatmentCategories = [];
    vm.doctorProcedureChargesTreatmentCategories = [];
    vm.hospitalChargesTreatmentCategories = [];
    vm.emergencyOutpatientTreatmentCategories = [];
    vm.miscellaneousTreatmentCategories = [];
    vm.otherCategories = [];
    vm.roomBenefits = [];
    vm.procedureBenefits = [];
    vm.doctorProcedureBenefits = [];
    vm.hospitalChargesBenefits = [];
    vm.fixedLimitBalance = 0;

    vm.hasSobEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');
    vm.hasMemberBenefitUtilizationEnabled = abp.setting.getBoolean(
      'Hms.Feature.MemberBenefitUtilization'
    );
    vm.hasTxtFileSupport = abp.setting.getBoolean('Hms.Feature.TxtFileSupport');
    vm.isNpr = true;
    vm.hasTxtFileSupport = abp.setting.getBoolean('Hms.Feature.TxtFileSupport');
    vm.hasInpatientClaimProcessingEnhancement = abp.setting.getBoolean(
      'Hms.Feature.InpatientClaimProcessingEnhancement'
    );
    vm.hasInsuranceEnhancementModule = abp.setting.getBoolean(
      'Hms.Feature.InsuranceEnhancementModule'
    );

    vm.enums = {
      employeeState: enumEmployeeState,
    };
    vm.isCreateOrViewTicketV2 =
      vm.hasInsuranceEnhancementModule && (vm.isCreate || vm.ticket.version === 'V2');

    vm.$onChanges = onChanges;
    vm.getDependentDescription = getDependentDescription;
    vm.doctorChanges = doctorChanges;
    vm.refreshAllowance = refreshAllowance;
    vm.getAmount = getAmount;
    vm.calculateAmount = calculateAmount;
    vm.getTotalBillAmount = getTotalBillAmount;
    vm.getCoveredAmount = getCoveredAmount;
    vm.getUncoveredAmount = getUncoveredAmount;
    vm.getCollectCash = getCollectCash;
    vm.getExceededAmount = getExceededAmount;
    vm.getCoPayAmount = getCoPayAmount;
    vm.getTotalCoveredAmount = getTotalCoveredAmount;
    vm.getEligibleClaimAmount = getEligibleClaimAmount;
    vm.getEntitledAmount = getEntitledAmount;
    vm.getTotalExceededAmount = getTotalExceededAmount;
    vm.getTotalPayableAmount = getTotalPayableAmount;
    vm.getTotalCollectCashAmount = getTotalCollectCashAmount;
    vm.isTicketV2 = isTicketV2;

    init();

    function init() {
      $scope.$on('validateNprInpatientTicket', validateForm);

      // Only initialize when isEdit is true.

      if (vm.isEdit) {
        vm.isTallied = true;
      }
    }

    function getDoctorName(id) {
      if (vm.doctors) {
        const selectedDoctor = _.find(vm.doctors, (d) => d.id === id);

        if (selectedDoctor) {
          return selectedDoctor.name;
        }
      }

      return null;
    }

    function onChanges(changes) {
      vm.doctors = [
        {
          id: -1,
          name: vm.ticket.manualDoctorName,
        },
      ];

      if (vm.categories && changes.categories) {
        vm.categories = _.map(vm.categories, (d) =>
          _.extend({}, d, {
            id: d.category,
          })
        );

        if (vm.hasSobEnhancement) {
          const accomodationItems = ['RoomAndBoard', 'IcuRoomAndBoard', 'ChildGuardianBenefit'];

          const doctorProcedureItems = [
            'SurgicalFee',
            'AnaesthetistFee',
            'OrganTransplant',
            'DaySurgery',
          ];

          const hospitalChargesItems = [
            'OperatingTheatre',
            'HospitalSuppliesAndServices',
            'AmbulanceFee',
            'MedicalReport',
          ];

          const otherItems = [
            'SurgicalFee',
            'OperatingTheatre',
            'HospitalSuppliesAndServices',
            'AnaesthetistFee',
          ];

          vm.roomCategories = _.filter(vm.categories, (x) =>
            accomodationItems.includes(x.category)
          );

          vm.procedureCategories = _.filter(
            vm.categories,
            (x) => x.category === 'InHospitalDoctorVisit'
          );

          vm.doctorProcedureCategories = _.filter(vm.categories, (x) =>
            doctorProcedureItems.includes(x.category)
          );

          vm.hospitalChargesCategories = _.filter(vm.categories, (x) =>
            hospitalChargesItems.includes(x.category)
          );

          vm.otherCategories = _.filter(vm.categories, (x) => otherItems.includes(x.category));

          // SOB benefits

          vm.roomBenefits = _.filter(vm.hospitalizationBenefits, (x) =>
            accomodationItems.includes(x.category)
          );

          vm.procedureBenefits = _.filter(
            vm.hospitalizationBenefits,
            (x) => x.category === 'InHospitalDoctorVisit'
          );

          vm.doctorProcedureBenefits = _.filter(vm.hospitalizationBenefits, (x) =>
            _.includes(doctorProcedureItems, x.category)
          );

          vm.hospitalChargesBenefits = _.filter(vm.hospitalizationBenefits, (x) =>
            _.includes(hospitalChargesItems, x.category)
          );
        } else {
          vm.roomCategories = _.filter(
            vm.categories,
            (x) => x.category === '1' || x.category === '2' || x.category === '3'
          );

          vm.procedureCategories = _.filter(vm.categories, (x) => x.category === '4');

          vm.otherCategories = _.filter(
            vm.categories,
            (x) =>
              x.category === '9' ||
              x.category === '10' ||
              x.category === '11' ||
              x.category === '12' ||
              x.category === '13'
          );
        }
      }

      if (vm.hasInsuranceEnhancementModule) {
        vm.emergencyOutpatientTreatmentCategories =
          vm.ticket.emergencyOutpatientTreatmentCategories;

        vm.miscellaneousTreatmentCategories = vm.ticket.miscellaneousTreatmentCategories;

        vm.accommodationChargesTreatmentCategories =
          vm.ticket.accommodationChargesTreatmentCategories;

        vm.inHospitalDoctorVisitTreatmentCategories =
          vm.ticket.inHospitalDoctorVisitTreatmentCategories;

        vm.doctorProcedureChargesTreatmentCategories =
          vm.ticket.doctorProcedureChargesTreatmentCategories;

        vm.hospitalChargesTreatmentCategories = vm.ticket.hospitalChargesTreatmentCategories;
      }

      // Edit.
      if (vm.triggerEdit && changes.triggerEdit && changes.triggerEdit.currentValue) {
        backupInitialStates();
        vm.isEdit = true;
      }

      // Cancel edit.
      if (vm.triggerCancel && changes.triggerCancel && changes.triggerCancel.currentValue) {
        restoreInitialStates();
        vm.isEdit = false;
      }

      // Save edits.
      if (vm.triggerSave && changes.triggerSave && changes.triggerSave.currentValue) {
        save();
        vm.onSave({ isSave: false });
      }
    }

    function doctorChanges() {
      vm.doctors = [
        {
          id: -1,
          name: vm.ticket.manualDoctorName,
        },
      ];
    }

    function getDependentDescription() {
      return App.localize(
        'DependentDescription',
        vm.patient.principalName,
        $filter('nationalIdFilter')(vm.patient.principalNationalId)
      );
    }

    function validateForm() {
      if (!App.isFormValid(vm.ticketEditForm)) {
        vm.checkFormFilled({ data: false });
      } else {
        vm.checkFormFilled({ data: true });
      }
    }

    function refreshAllowance() {
      // Refresh patient's allowance. Only for NPR & Manual tickets.

      vm.loading += 1;
      inpatientPatientTicketSvc
        .getAllowance({
          employeeId: vm.patient.employeeId,
          serviceType: vm.ticket.serviceType,
          ticketDate: vm.ticket.receiptDate,
        })
        .success((data) => {
          vm.ticket.allowance = data.employeeAllowance;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // Total calculation region (To be updated with more calculations)

    function getAmount() {
      let totalBillAmount = 0;
      let coveredAmount = 0;
      let uncoveredAmount = 0;
      let exceededAmount = 0;
      let receiptAmount = 0;
      let coPayAmount = 0;
      let totalRoomAndBoardAmount = 0;
      let totalRoomAndBoardDuration = 0;
      let allowance = 0;
      let collectCashAmount = 0;
      let totalExcludedItemAmount = 0;
      const dailyRoomAndBoardAmounts = [];

      if (vm.ticket) {
        receiptAmount = vm.ticket.receiptAmount;
        allowance = vm.ticket.allowance;
      }

      if (vm.ticket && !vm.ticket.isPreOrPostInpatientClaim) {
        if (!vm.roomAndBoardCharges) vm.roomAndBoardCharges = vm.ticket.roomAndBoardAllowance;
        if (!vm.diagnoses) vm.diagnoses = vm.ticket.diagnoses;

        if (!vm.coPay) {
          vm.coPay = {
            isRoomAndBoardExceedEntitlement: vm.ticket.isRoomAndBoardExceedEntitlement,
            isPercentage: vm.ticket.isCoPayPercentage,
            isCoInsurance: vm.ticket.isCoInsurance,
            coPayExclusions: vm.ticket.coPayExclusions,
            value: vm.ticket.coPayValue,
          };
        }

        _.each(vm.ticket.rooms, (x) => {
          _.each(x.ticketItems, (p) => {
            if (p.amount) {
              coveredAmount += p.amount;
              totalBillAmount += p.amount;

              if (vm.hasTxtFileSupport && _.some(vm.coPay.coPayExclusions, (c) => c === x.category))
                totalExcludedItemAmount += p.amount;
            }

            if (p.uncoveredAmount) {
              uncoveredAmount += p.uncoveredAmount;
              totalBillAmount += p.uncoveredAmount;
            }

            // For room and board only.
            if ((x.category === '1' || x.category === 'RoomAndBoard') && p.totalCharges) {
              const startDate = p.roomChargeDateRangeModel
                ? p.roomChargeDateRangeModel.startDate
                : p.startDate;
              const endDate = p.roomChargeDateRangeModel
                ? p.roomChargeDateRangeModel.endDate
                : p.endDate;

              const startDateMoment = moment(startDate);
              const endDateMoment = moment(endDate);

              totalRoomAndBoardAmount += p.totalCharges;
              totalRoomAndBoardDuration += endDateMoment.diff(startDateMoment, 'days') + 1;

              if (vm.hasTxtFileSupport) dailyRoomAndBoardAmounts.push(p.dailyCharges);
            }
          });
        });

        const procedures = vm.calculateAmount(vm.ticket.procedures);
        coveredAmount += procedures.coveredAmount;
        uncoveredAmount += procedures.uncoveredAmount;
        totalBillAmount += procedures.billAmount;
        totalExcludedItemAmount += procedures.excludedItemAmount;

        if (vm.hasSobEnhancement) {
          const doctorProcedures = vm.calculateAmount(vm.ticket.doctorProcedures);
          coveredAmount += doctorProcedures.coveredAmount;
          uncoveredAmount += doctorProcedures.uncoveredAmount;
          totalBillAmount += doctorProcedures.billAmount;
          totalExcludedItemAmount += doctorProcedures.excludedItemAmount;

          const hospitalCharges = vm.calculateAmount(vm.ticket.hospitalCharges);
          coveredAmount += hospitalCharges.coveredAmount;
          uncoveredAmount += hospitalCharges.uncoveredAmount;
          totalBillAmount += hospitalCharges.billAmount;
          totalExcludedItemAmount += hospitalCharges.excludedItemAmount;
        }

        if (vm.hasInsuranceEnhancementModule) {
          const emergencyOutpatientTreatments = vm.calculateAmount(
            vm.ticket.emergencyOutpatientTreatments
          );
          coveredAmount += emergencyOutpatientTreatments.coveredAmount;
          uncoveredAmount += emergencyOutpatientTreatments.uncoveredAmount;
          totalBillAmount += emergencyOutpatientTreatments.billAmount;
          totalExcludedItemAmount += emergencyOutpatientTreatments.totalExcludedItemAmount;

          const miscellaneousTreatments = vm.calculateAmount(vm.ticket.miscellaneousTreatments);
          coveredAmount += miscellaneousTreatments.coveredAmount;
          uncoveredAmount += miscellaneousTreatments.uncoveredAmount;
          totalBillAmount += miscellaneousTreatments.billAmount;
          totalExcludedItemAmount += emergencyOutpatientTreatments.totalExcludedItemAmount;
        }

        // Included in SOB Enhancement calculation to accommodate for tickets with SOB V1
        _.each(vm.ticket.others, (x) => {
          if (x.amount) {
            coveredAmount += x.amount;
            totalBillAmount += x.amount;
          }

          if (x.uncoveredAmount) {
            uncoveredAmount += x.uncoveredAmount;
            totalBillAmount += x.uncoveredAmount;
          }
        });

        if (!vm.coPay) {
          vm.coPay = {
            isPercentage: vm.ticket.isCoPayPercentage,
            isCoInsurance: vm.ticket.isCoInsurance,
            value: vm.ticket.coPayValue,
          };
        }
      }

      if (vm.ticket && vm.ticket.isPreOrPostInpatientClaim) {
        if (vm.isTicketV2) {
          _.forEach(vm.ticket.sections, (item) => {
            _.forEach(item.treatmentCategories, (d) => {
              coveredAmount += d.coveredAmount || 0;
              uncoveredAmount += d.uncoveredAmount || 0;
              totalBillAmount += coveredAmount + uncoveredAmount || 0;
            });
          });
        } else {
          _.each(vm.ticket.ticketItems, (item) => {
            if (item.amount) {
              coveredAmount += item.amount;
              totalBillAmount += item.amount;
            }

            if (item.uncoveredAmount) {
              uncoveredAmount += item.uncoveredAmount;
              totalBillAmount += item.uncoveredAmount;
            }
          });
        }
      }

      if (vm.hasTxtFileSupport)
        coPayAmount = calculateCoPayV2(
          uncoveredAmount,
          totalBillAmount,
          totalExcludedItemAmount,
          dailyRoomAndBoardAmounts
        );
      else
        coPayAmount = calculateCoPay(
          uncoveredAmount,
          totalBillAmount,
          totalRoomAndBoardAmount,
          totalRoomAndBoardDuration
        );

      // Use ticket copay amount for reimbursement total ticket calculation.

      if (vm.ticket.ticketType === 2 && vm.ticket.coPayAmount > 0) {
        coPayAmount = vm.ticket.coPayAmount;
      }

      coveredAmount -= coPayAmount;

      // Check against allowance

      if (coveredAmount > allowance) {
        exceededAmount = coveredAmount - allowance;
        coveredAmount = allowance;
      }

      collectCashAmount = uncoveredAmount + coPayAmount + exceededAmount;

      if (vm.isEdit) {
        if (totalBillAmount !== receiptAmount) {
          vm.isTallied = false;
        } else {
          vm.isTallied = true;
        }
      }

      return {
        totalBillAmount: App.roundAmount(totalBillAmount),
        coveredAmount: App.roundAmount(coveredAmount),
        uncoveredAmount: App.roundAmount(uncoveredAmount),
        collectCash: App.roundAmount(collectCashAmount),
        coPayAmount: App.roundAmount(coPayAmount),
        exceededAmount: App.roundAmount(exceededAmount),
      };
    }

    function calculateAmount(records) {
      let billAmount = 0;
      let coveredAmount = 0;
      let uncoveredAmount = 0;
      let excludedItemAmount = 0;

      _.each(records, (x) => {
        _.each(x.ticketItems, (p) => {
          if (p.amount) {
            coveredAmount += p.amount;
            billAmount += p.amount;

            if (
              vm.hasTxtFileSupport &&
              _.some(vm.coPay.coPayExclusions, (c) => c === x.category || c === p.category)
            )
              excludedItemAmount += p.amount;
          }

          if (p.uncoveredAmount) {
            uncoveredAmount += p.uncoveredAmount;
            billAmount += p.uncoveredAmount;
          }
        });
      });

      return {
        billAmount,
        coveredAmount,
        uncoveredAmount,
        excludedItemAmount,
      };
    }

    function getTotalBillAmount() {
      return vm.getAmount().totalBillAmount > 0 ? vm.getAmount().totalBillAmount : 0;
    }

    function getCoveredAmount() {
      return vm.getAmount().coveredAmount > 0 ? vm.getAmount().coveredAmount : 0;
    }

    function getUncoveredAmount() {
      return vm.getAmount().uncoveredAmount > 0 ? vm.getAmount().uncoveredAmount : 0;
    }

    function getCollectCash() {
      return vm.getAmount().collectCash > 0 ? vm.getAmount().collectCash : 0;
    }

    function getCoPayAmount() {
      return vm.getAmount().coPayAmount > 0 ? vm.getAmount().coPayAmount : 0;
    }

    function getExceededAmount() {
      return vm.getAmount().exceededAmount > 0 ? vm.getAmount().exceededAmount : 0;
    }

    function calculateCoPayV2(
      uncoveredAmount,
      billAmount,
      totalExcludedItemAmount,
      dailyRoomAndBoardAmounts
    ) {
      if (!vm.coPay || vm.coPay.value === 0) {
        return 0;
      }

      const coveredAmount = billAmount - uncoveredAmount;
      const costShareableAmount = coveredAmount - totalExcludedItemAmount;
      const hasExceededEntitlement = _.some(
        dailyRoomAndBoardAmounts,
        (d) => d > vm.roomAndBoardCharges
      );

      if (vm.coPay.isPercentage) {
        if (vm.coPay.isRoomAndBoardExceedEntitlement)
          return hasExceededEntitlement ? costShareableAmount * (vm.coPay.value / 100) : 0;
        return costShareableAmount * (vm.coPay.value / 100);
      }
      return costShareableAmount > 0 ? vm.coPay.value : 0;
    }

    function calculateCoPay(
      uncoveredAmount,
      billAmount,
      totalRoomAndBoardAmount,
      roomAndBoardDuration
    ) {
      if (!vm.coPay || vm.coPay.value === 0) {
        return 0;
      }

      let totalRoomAndBoardAllowance = 0;
      let exceededRoomAndBoardAmount = 0;

      if (vm.roomAndBoardCharges > 0 && roomAndBoardDuration > 0) {
        totalRoomAndBoardAllowance = vm.roomAndBoardCharges * roomAndBoardDuration;
      }

      if (totalRoomAndBoardAmount > 0) {
        exceededRoomAndBoardAmount = totalRoomAndBoardAmount - totalRoomAndBoardAllowance;
      }

      const coveredAmount = billAmount - uncoveredAmount;

      if (vm.coPay.isPercentage && vm.coPay.isCoInsurance) {
        return coveredAmount * (vm.coPay.value / 100);
      }
      if (vm.coPay.isPercentage && !vm.coPay.isCoInsurance) {
        return exceededRoomAndBoardAmount > 0
          ? (coveredAmount - totalRoomAndBoardAllowance) * (vm.coPay.value / 100)
          : 0;
      }
      const copayableAmount = vm.coPay.isCoInsurance
        ? coveredAmount
        : coveredAmount - totalRoomAndBoardAllowance;

      return copayableAmount > 0 ? vm.coPay.value : 0;
    }

    function backupInitialStates() {
      vm.preEditData = {
        preNewDocuments: angular.copy(vm.newDocuments),
        preTicket: angular.copy(vm.ticket),
      };
    }

    function restoreInitialStates() {
      vm.newDocuments = vm.preEditData.preNewDocuments;
      vm.ticket = vm.preEditData.preTicket;
    }

    function save() {
      let canSubmit = true;
      const prompts = new PromptQueue();

      if (App.isFormValid(vm.ticketEditForm)) {
        if (vm.ticket.ticketType === 2) {
          if (vm.getTotalBillAmount() !== vm.ticket.receiptAmount) {
            abp.message.error(App.localize('ClaimReceiptAmountMismatchWithTicketAmount'));
            return;
          }

          if (vm.getCoveredAmount() !== vm.ticket.coveredAmount) {
            prompts.add(
              swal,
              {
                title: App.localize('AreYouSure'),
                text: App.localize('ClaimReceiptAmountMismatchWithTicketCoveredAmountWarning'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#1ab394',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                closeOnConfirm: true,
                closeOnCancel: true,
              },
              (isConfirm) => {
                canSubmit = isConfirm;
              }
            );
          }
        }
        vm.saving += 1;
        prompts.run(() => {
          vm.saving -= 1;
          if (canSubmit) updateTicket();
        });
      } else {
        abp.message.error(App.localize('OneOrMoreInputValueIsInvalid'));
      }
    }

    function updateTicket() {
      vm.loading += 1;
      let rooms = [];
      let procedures = [];
      const others = [];
      let doctorProcedures = [];
      let hospitalCharges = [];
      let emergencyOutpatientTreatments = [];
      let miscellaneousTreatments = [];

      _.each(vm.ticket.rooms, (x) => {
        _.each(x.ticketItems, (ticketItem) => {
          const ti = ticketItem;
          if (!_.isNil(ti.category)) {
            ti.startDate = ti.roomChargeDateRangeModel.startDate
              ? ti.roomChargeDateRangeModel.startDate.utc()
              : null;
            ti.endDate = ti.roomChargeDateRangeModel.endDate
              ? ti.roomChargeDateRangeModel.endDate.utc()
              : null;
            rooms.push(ti);
          }
        });
      });

      if (vm.hasSobEnhancement) {
        _.each(vm.ticket.procedures, (x) => {
          if (!_.isNil(x.doctorId) || vm.ticket.ticketType === 2) {
            _.each(x.ticketItems, (ticketItem) => {
              const ti = ticketItem;
              if (!_.isNil(ti.doctorId) || vm.ticket.ticketType === 2) {
                ti.startDate = ti.visitDate ? ti.visitDate.utc() : null;
                ti.endDate = ti.visitDate ? ti.visitDate.utc() : null;

                // Total visit is always 1.

                if (!ti.totalVisit || ti.totalVisit !== 1) ti.totalVisit = 1;

                procedures.push(ti);
              }
            });
          }
        });

        _.each(vm.ticket.doctorProcedures, (x) => {
          if (!_.isNil(x.category)) {
            _.each(x.ticketItems, (p) => {
              if (!_.isNil(p.category)) doctorProcedures.push(p);
            });
          }
        });

        _.each(vm.ticket.hospitalCharges, (x) => {
          if (!_.isNil(x.category)) {
            _.each(x.ticketItems, (p) => {
              if (!_.isNil(p.category)) hospitalCharges.push(p);
            });
          }
        });
      } else {
        _.each(vm.ticket.procedures, (x) => {
          _.each(x.ticketItems, (ticketItem) => {
            const ti = ticketItem;
            if (!_.isNil(ti.category)) {
              ti.startDate = ti.procedureChargeDateRangeModel.startDate
                ? ti.procedureChargeDateRangeModel.startDate.utc()
                : null;
              ti.endDate = ti.procedureChargeDateRangeModel.endDate
                ? ti.procedureChargeDateRangeModel.endDate.utc()
                : null;
              procedures.push(ti);
            }
          });
        });

        _.each(vm.ticket.others, (x) => {
          if (!_.isNil(x.categoryName)) others.push(x);
        });
      }

      if (vm.hasInsuranceEnhancementModule && vm.ticket.version === 'V2') {
        rooms = formatTicketItems(vm.ticket.rooms, (x) => ({
          amount: x.amount,
          uncoveredAmount: x.uncoveredAmount,
          category: x.section,
          categoryName: x.category,
          subCategory: x.subTreatment,
          procedure: x.procedure,
          uncoveredProcedure: x.uncoveredProcedure,
          remark: x.remark,
          uncoveredRemark: x.uncoveredRemark,
          startDate: x.roomChargeDateRangeModel.startDate
            ? moment(x.roomChargeDateRangeModel.startDate).utc()
            : null,
          endDate: x.roomChargeDateRangeModel.endDate
            ? moment(x.roomChargeDateRangeModel.endDate).utc()
            : null,
          dailyCharges: x.dailyCharges,
          lengthOfStay: x.lengthOfStay,
        }));

        procedures = formatTicketItems(vm.ticket.procedures, (x) => ({
          amount: x.amount,
          uncoveredAmount: x.uncoveredAmount,
          category: x.section,
          categoryName: x.doctorType,
          subCategory: x.subTreatment,
          procedure: x.procedure,
          uncoveredProcedure: x.uncoveredProcedure,
          remark: x.remark,
          uncoveredRemark: x.uncoveredRemark,
          startDate: x.visitDate ? moment(x.visitDate).utc() : null,
          endDate: x.visitDate ? moment(x.visitDate).utc() : null,
          doctorId: x.doctorId,
          totalVisit: 1,
          visit: x.visit,
        }));

        doctorProcedures = formatTicketItems(vm.ticket.doctorProcedures, (x) => ({
          amount: x.amount,
          uncoveredAmount: x.uncoveredAmount,
          category: x.section,
          categoryName: x.category,
          subCategory: x.subTreatment,
          procedure: x.procedure,
          uncoveredProcedure: x.uncoveredProcedure,
          remark: x.remark,
          uncoveredRemark: x.uncoveredRemark,
          startDate: x.visitDate ? moment(x.visitDate).utc() : null,
          endDate: x.visitDate ? moment(x.visitDate).utc() : null,
          doctorId: x.doctorId,
        }));

        hospitalCharges = formatTicketItems(vm.ticket.hospitalCharges, (x) => ({
          amount: x.amount,
          uncoveredAmount: x.uncoveredAmount,
          category: x.section,
          categoryName: x.category,
          subCategory: x.subTreatment,
          procedure: x.procedure,
          uncoveredProcedure: x.uncoveredProcedure,
          remark: x.remark,
          uncoveredRemark: x.uncoveredRemark,
        }));

        emergencyOutpatientTreatments = formatTicketItems(
          vm.ticket.emergencyOutpatientTreatments,
          (x) => ({
            amount: x.amount,
            uncoveredAmount: x.uncoveredAmount,
            category: x.section,
            categoryName: x.mainTreatment,
            subCategory: x.subTreatment,
            procedure: x.procedure,
            uncoveredProcedure: x.uncoveredProcedure,
            remark: x.remark,
            uncoveredRemark: x.uncoveredRemark,
          })
        );

        miscellaneousTreatments = formatTicketItems(vm.ticket.miscellaneousTreatments, (x) => ({
          amount: x.amount,
          uncoveredAmount: x.uncoveredAmount,
          category: x.section,
          categoryName: x.mainTreatment,
          subCategory: x.subTreatment,
          procedure: x.procedure,
          uncoveredProcedure: x.uncoveredProcedure,
          remark: x.remark,
          uncoveredRemark: x.uncoveredRemark,
        }));
      }

      const input = vm.ticket;
      input.letterNumber = vm.letterNumber;
      input.rooms = rooms;
      input.procedures = procedures;
      input.treatments = vm.ticket.ticketItems;

      if (vm.hasSobEnhancement) {
        input.doctorProcedures = doctorProcedures;
        input.hospitalCharges = hospitalCharges;
      } else {
        input.others = others;
      }

      if (vm.hasInsuranceEnhancementModule && vm.ticket.version === 'V2') {
        input.emergencyOutpatientTreatments = emergencyOutpatientTreatments;
        input.miscellaneousTreatments = miscellaneousTreatments;
      }

      input.documentsToUpload = _.map(vm.newDocuments, (doc) => ({
        externalObjectId: null,
        originalFileName: doc.originalFileName,
        fileName: doc.fileName,
      }));

      input.documentsMarkedForRemoval = _.reduce(
        vm.ticket.documents,
        (resultArray, document) => {
          if (document.isMarkedForRemoval)
            resultArray.push({ externalObjectId: document.externalObjectId });

          return resultArray;
        },
        []
      );

      inpatientPatientTicketSvc
        .updateTicket(input)
        .success(() => {
          vm.isEdit = false;
          vm.savedTrigger += 1;
          vm.ticket.rooms = _.chain(vm.ticket.rooms)
            .groupBy('category')
            .map((value, key) => ({
              category: key,
              ticketItems: value,
              name: _.first(value).categoryName,
            }))
            .value();

          if (vm.hasSobEnhancement) {
            vm.ticket.procedures = _.chain(vm.ticket.procedures)
              .groupBy('doctorId')
              .map((value, key) => ({
                doctorId: vm.ticket.ticketType !== 2 ? Number(key) : vm.doctors[0].id,
                ticketItems: value,
                doctorName:
                  vm.ticket.ticketType !== 2 ? getDoctorName(Number(key)) : vm.doctors[0].name,
              }))
              .value();

            vm.ticket.doctorProcedures = _.chain(vm.ticket.doctorProcedures)
              .groupBy('category')
              .map((value, key) => ({
                category: key,
                ticketItems: value,
                name: _.first(value).categoryName,
              }))
              .value();

            vm.ticket.hospitalCharges = _.chain(vm.ticket.hospitalCharges)
              .groupBy('category')
              .map((value, key) => ({
                category: key,
                ticketItems: value,
                name: _.first(value).categoryName,
              }))
              .value();
          } else {
            vm.ticket.procedures = _.chain(vm.ticket.procedures)
              .groupBy('category')
              .map((value, key) => ({
                category: key,
                ticketItems: value,
                name: _.first(value).categoryName,
              }))
              .value();
          }
          abp.notify.info(App.localize('SuccessfullySaved'));
          $state.reload();
          if (
            vm.hasMemberBenefitUtilizationEnabled &&
            vm.ticket.ticketType !== 1 &&
            vm.isHost &&
            !vm.hasInsurerClaim
          ) {
            showAdjustMemberUtilizationConfirmationModal(vm.ticket.ticketNumber);
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function showAdjustMemberUtilizationConfirmationModal(ticketNumber) {
      $uibModal.open({
        templateUrl: require('../../../memberUtilizations/adjustMemberUtilizationConfirmationModal.html'),
        controller:
          'common.views.memberUtilizations.adjustMemberUtilizationConfirmationModal as vm',
        backdrop: 'static',
        size: 'sm',
        resolve: {
          ticketNumber() {
            return ticketNumber;
          },
        },
      });
    }

    function formatTicketItems(treatments, format) {
      const items = [];
      _.each(treatments, (x) => {
        _.each(x.ticketItems, (y) => {
          if (!y.mainTreatment && !y.category && !y.doctorType) return;
          const ticketItem = angular.copy(y);
          items.push(format(ticketItem));
        });
      });
      return items;
    }

    function getTotalCoveredAmount() {
      return getTotalBillAmount() - getUncoveredAmount();
    }

    function getEligibleClaimAmount() {
      return getTotalCoveredAmount() - getCoPayAmount();
    }

    function getEntitledAmount() {
      if (!vm.isCreate) return vm.ticket.coveredAmount;

      const { allowance } = vm.ticket;
      const eligibleClaimAmount = getEligibleClaimAmount();

      return Math.min(eligibleClaimAmount, allowance);
    }

    function getTotalExceededAmount() {
      return getEligibleClaimAmount() - getEntitledAmount();
    }
    function getTotalPayableAmount() {
      return getEligibleClaimAmount() - getTotalExceededAmount();
    }
    function getTotalCollectCashAmount() {
      return getUncoveredAmount() + getCoPayAmount() + getTotalExceededAmount();
    }

    function isTicketV2() {
      return vm.hasInsuranceEnhancementModule && vm.ticket.version === 'V2';
    }
  }
})();
