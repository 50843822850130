(() => {
  angular
    .module('app')
    .component('commonComponentsInpatientHospitalChargesSobSettingsTableComponent', {
      templateUrl: require('./inpatientHospitalChargesSobSettingsTable.component.html'),
      controller: SobSettingsTableController,
      controllerAs: 'vm',
      bindings: {
        benefits: '<',
        currencyCode: '=?',
      },
    });

  SobSettingsTableController.$inject = [];

  function SobSettingsTableController() {
    const vm = this;

    vm.currencyCode =
      _.isNil(vm.currencyCode) || vm.currencyCode === undefined
        ? abp.setting.get('Hms.General.CurrencyCode')
        : vm.currencyCode;

    vm.hasSobEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');
  }
})();
