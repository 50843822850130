(function () {
    angular.module('app').component('clinicPatientTicketMedicalCertificate', {
        templateUrl: require('/App/clinic/views/patient/ticket/medicalCertificate/widget.html'),
        controller: ['moment', controller],
        controllerAs: 'vm',
        bindings: {
            isDisabled: '<',
            isExpanded: '<',
            isCompleted: '<',
            onDirtyEvent: '<',
            onSetPristineEvent: '<',
            ticket: '<',
            onSyncExpansion: '&',
            onComplete: '&'
        }
    });

    function controller(moment) {
        var vm = this;

        vm.$onChanges = function (changes) {
            if (changes.isExpanded) {
                vm.isOpen = vm.isExpanded;
                updatePostHeader();
            }

            if (changes.onDirtyEvent && changes.onDirtyEvent.currentValue) {
                vm.onDirtyEvent.handler = vm.onDirtyEventHandler;
            }

            if (changes.onSetPristineEvent && changes.onSetPristineEvent.currentValue) {
                vm.onSetPristineEvent.handler = vm.onSetPristineEventHandler;
            }
        };

        vm.$doCheck = function () {
            if (vm.isOpen !== vm.isOpenPreviousValue) {
                // Component expansion must be in sync with parent component's isExpanded state to working as intended.
                // uib-accordion toggles isOpen by itself when user clicks on it.

                vm.onSyncExpansion({ isExpanded: vm.isOpen });
                vm.isOpenPreviousValue = vm.isOpen;
            }
        };

        vm.isOpen = false;
        vm.isOpenPreviousValue = false;
        vm.duration = 0;
        vm.ticket.medicalCertificate = vm.ticket.medicalCertificate ? vm.ticket.medicalCertificate : null;
        vm.isMcStartDateAlsoCheckedInDate = vm.ticket.medicalCertificate
            ? vm.ticket.medicalCertificate.startDate.diff(vm.ticket.checkedInTime.startOf('days'), 'days') === 0
            : false;
        vm.ticket.hasMc = !vm.ticket.id
            ? !_.isNil(vm.ticket.hasMc) ? vm.ticket.hasMc : null
            : !!vm.ticket.medicalCertificate;

        vm.toggleMc = toggleMc;

        vm.onDirtyEventHandler = function () {
            return vm.medicalCertificateForm.$dirty;
        };

        vm.onSetPristineEventHandler = function () {
            vm.medicalCertificateForm.$setPristine();
        };

        vm.next = function () {
            if (App.isFormValid(vm.medicalCertificateForm) && areFieldsValid()) {
                vm.onComplete();
            }
        };

        vm.setMcStartDate = function (offset) {
            var checkInDate = moment(vm.ticket.checkedInTime).startOf('day');
            vm.ticket.medicalCertificate.startDate = checkInDate.add(offset, 'days');

            var mcEndDate = vm.ticket.medicalCertificate.endDate.startOf('day');
            if (mcEndDate.diff(vm.ticket.medicalCertificate.startDate, 'days') < 0) {
                vm.ticket.medicalCertificate.endDate = moment(vm.ticket.medicalCertificate.startDate);
            }
            vm.isMcStartDateAlsoCheckedInDate = offset === 0;
        };

        vm.getMcDurationDescription = function () {
            if (vm.ticket.medicalCertificate) {
                var startDate = moment(vm.ticket.medicalCertificate.startDate);
                var endDate = moment(vm.ticket.medicalCertificate.endDate);
                vm.duration = endDate.diff(startDate, 'days') + 1;
                return App.localize('XDays', vm.duration);
            }
        };

        vm.getMcStartDateChoice = function (offset) {
            return moment(vm.ticket.checkedInTime).add(offset, 'days').startOf('day');
        };

        /* Functions */

        function clearMedicalCertificateDetails() {
            vm.ticket.medicalCertificate = null;
        }

        function setMedicalCertificateDetails() {
            var firstDate = moment(vm.ticket.checkedInTime).startOf('day');
            vm.ticket.medicalCertificate = {
                startDate: firstDate,
                endDate: firstDate,
                remark: vm.ticket.medicalCertificate && vm.ticket.medicalCertificate.remark
                    ? vm.ticket.medicalCertificate.remark
                    : vm.ticket.consultation && vm.ticket.consultation.symptoms
                        ? vm.ticket.consultation.symptoms.join(', ')
                        : ''
            };
            vm.isMcStartDateAlsoCheckedInDate = 1;
        }

        function toggleMc() {
            !vm.ticket.hasMc ? clearMedicalCertificateDetails() : setMedicalCertificateDetails();
            areFieldsValid();
        }

        function areFieldsValid() {
            if ((vm.showSelectOptionMessage = _.isNil(vm.ticket.hasMc))) {
                return false;
            }
            return true;
        }

        function updatePostHeader() {
            if (vm.ticket.hasMc) {
                var startDate = moment(vm.ticket.medicalCertificate.startDate);
                var endDate = moment(vm.ticket.medicalCertificate.endDate);
                vm.duration = endDate.diff(startDate, 'days') + 1;
                vm.summaryMessage = App.localize('MedicalCertificateSummaryNarrative', vm.duration, startDate.format('L'), endDate.format('L'));
            } else {
                vm.summaryMessage = App.localize('NoMedicalCertificateSummaryNarrative');
            }
        }

        /* End of Functions */
    }
})();
