(function () {
    'use strict';

    angular.module('app').component('corporateBenefitGroupsCreateEditHealthScreeningComponent', {
        templateUrl: require('/App/corporate/views/benefitGroups/createEdit/healthScreening/healthScreening.component.html'),
        controller: controller,
        controllerAs: 'vm',
        bindings: {
            currencyCode: '<',
            dependentHasHealthScreening: '<',
            healthScreeningTreatmentEditData: '<',
            benefitGroup: '<',
            areTreatmentTreeAndReferralExceptionsDirty: '=',
            isReadOnly: '<'
        }
    });

    function controller() {
        var vm = this;
    }
})();
