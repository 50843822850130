(function () {
  'use strict';

  angular
    .module('app')
    .controller('clinic.views.hospital.patient', [
      '$state',
      '$stateParams',
      '$filter',
      'abp.services.app.commonLookup',
      'abp.services.app.inpatientEmployee',
      controller,
    ]);

  function controller($state, $stateParams, $filter, commonLookupSvc, inpatientEmployeeSvc) {
    var vm = this;
    var hasDependentFound = false;
    vm.loading = 0;
    vm.filterText = $stateParams.filterText || '';
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.searched = false;
    vm.tenantGroups = [];
    vm.employeeFoundMessage = '';
    vm.disciplines = null;

    // Init section.

    function init() {
      commonLookupSvc.getDefaultClinicLocation().success(function (data) {
        setLocation(data);
        vm.patientNotFoundMessage = App.localize(
          'PatientNotFoundMessage3',
          data.accountOwnerContactNumber
        );
      });
      getSpecialistDisciplines();
    }

    // Employee section.

    function processGetEmployeesOutput(filterText, data, callback) {
      var maxDisplayNameLength = 10;

      var allEmployees = data || [];
      vm.showNoRecords = filterText && !allEmployees.length;

      var newEmployee = {
        dependents: [],
      };

      _.each(allEmployees, function (employee) {
        if (employee.copayment) {
          if (employee.copayment.isPercentage) {
            employee.copayment = App.localize('XPerAdmission', employee.copayment.value + '%');
          } else {
            var valueText = $filter('currencyFormat')(employee.copayment.value, vm.currencyCode);
            employee.copayment = App.localize('XPerAdmission', valueText);
          }
        }

        employee.displayName = employee.name.substring(0, maxDisplayNameLength);

        if (employee.principalId)
          employee.displayPrincipalName = employee.principalName.substring(0, maxDisplayNameLength);

        switch (employee.type) {
          case 0:
            // HM-6630 Hack: Assign searched account as principal.
            // If there is no main account, assign any principal account as principal.
            // If main account is found, assign as principal and previous principal is pushed into dependants.

            if (newEmployee.principal && !isDiff(employee.nationalId, filterText)) {
              newEmployee.dependents.push(newEmployee.principal);

              newEmployee.principal = employee;
            }

            if (!newEmployee.principal) {
              newEmployee.principal = employee;
            } else {
              newEmployee.dependents.push(employee);
            }
            break;
          case 1:
            newEmployee.dependents.push(employee);
            hasDependentFound = true;
            break;
          default:
            break;
        }
      });

      newEmployee.targetIndex = isDiff(newEmployee.principal.nationalId, filterText)
        ? _.findIndex(newEmployee.dependents, function (d) {
            return !isDiff(d.nationalId, filterText);
          }) + 1 // Principal is always at first tab
        : 0;

      vm.tenantGroups.push(newEmployee);

      if (_.isFunction(callback)) callback();
    }

    vm.getEmployees = function (successCallback) {
      // Reset values
      vm.tenantGroups = [];
      hasDependentFound = false;

      var filterText = vm.filterText;
      $state.transitionTo(
        $state.current,
        {
          filterText: filterText,
        },
        { notify: false }
      );

      vm.loading++;
      inpatientEmployeeSvc
        .getPanelEmployees({
          filter: filterText,
          panelLocationId: vm.panel.panelLocationId,
        })
        .success(function (data) {
          _.each(data.items, function (tenantGroup) {
            processGetEmployeesOutput(filterText, tenantGroup.employees, successCallback);
          });

          vm.employeeFoundMessage = App.localize('EmployeeFoundMessagePart1', data.items.length);
          if (hasDependentFound) {
            vm.employeeFoundMessage += App.localize('EmployeeFoundMessagePart2') + '.';
          } else {
            vm.employeeFoundMessage += '.';
          }

          vm.searched = filterText;
        })
        .finally(function (e) {
          vm.loading--;
        });
    };

    function setLocation(location) {
      vm.panel = {};
      vm.panel.panelLocationId = parseInt(location.value, 10) || null;
      vm.panel.name = location.name;
      vm.panel.address = location.address;
      vm.panel.contactNumber = location.contactNumber;
    }

    init();

    // Get Specialist Disciplines

    function getSpecialistDisciplines() {
      vm.loading++;
      commonLookupSvc
        .getSpecialistDisciplines()
        .success(function (data) {
          vm.disciplines = data.items;
        })
        .finally(function (e) {
          vm.loading--;
        });
    }

    /* Functions */

    // Finds if the texts are different. Return true if found different.
    // e.g. Return false if nationalId matches filter text.

    function isDiff(text1, text2) {
      return text1.replace(/-/g, '') !== text2.replace(/-/g, '');
    }
  }

  /* End of Functions */
})();
