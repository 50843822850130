(function () {
    'use strict';

    angular.module('app').component('hostCorporatesSupportPlansCreateEditScheduleOfBenefitPrePostHospitalTreatmentComponent', {
        templateUrl: require('/App/host/views/corporates/supportPlans/createEdit/scheduleOfBenefit/prePostHospitalTreatment/prePostHospitalTreatment.component.html'),
        controller: controller,
        controllerAs: 'vm',
        bindings: {
            scheduleOfBenefit: '<',
            currencyCode: '<'
        }
    });

    function controller() {
        var vm = this;

        vm.preHospitalizationDiagnosisTestText = App.localize('PreHospitalizationDiagnosisTest');
        vm.preHospitalizationSpecialistConsultationText = App.localize('PreHospitalizationSpecialistConsultation');
        vm.secondSurgicalOpinionText = App.localize('SecondSurgicalOpinion');
        vm.postHospitalizationFollowUpAndTreatmentText = App.localize('PostHospitalizationFollowUpAndTreatment');
        vm.perAnnumText = App.localize('PerAnnum');
        vm.daysText = App.localize('Days');
        vm.specialistConsultationMaxDaysTimesType = null;
        vm.surgicalOpinionAmountType = null;
        vm.surgicalOpinionMaxDaysTimesType = null;
        vm.followUpTreatmentAmountType = null;
        vm.isPreCovered = null;
        vm.isPostCovered = null;

        vm.togglePrePostHospitalizationTreatment = function () {
            // Pre-hospitalization
            vm.minPreHospitalizationDays = vm.scheduleOfBenefit.preHospitalizationTreatment.isCovered? 1 : 0;

            // Specialist consultation
            vm.minSpecialistConsultationCoveredTimes = vm.specialistConsultationMaxDaysTimesType === 0 ? 1 : 0;

            // Surgical opinion
            vm.minSurgicalOpinionAmount = vm.surgicalOpinionAmountType === 1 ? 1 : 0;
            vm.minSurgicalOpinionCoveredTimes = vm.surgicalOpinionAmountType === 2
                || (vm.surgicalOpinionAmountType !== 2 && vm.surgicalOpinionMaxDaysTimesType === 1) ? 0 : 1;
            vm.scheduleOfBenefit.surgicalOpinion.isUnlimited = vm.surgicalOpinionMaxDaysTimesType === 1 ? true : false;

            // Post-hospitalization
            vm.minPostHospitalizationDays = vm.scheduleOfBenefit.postHospitalizationTreatment.isCovered? 1 : 0;
            vm.minFollowUpTreatmentAmount = vm.followUpTreatmentAmountType === 1 ? 1 : 0;

            vm.scheduleOfBenefit.preHospitalizationTreatment.isCovered = vm.isPreCovered === 1 ? true : false;
            vm.scheduleOfBenefit.postHospitalizationTreatment.isCovered = vm.isPostCovered === 1 ? true : false;

            setSpecialistConsultationMaxDaysTimesType()
            setSurgicalOpinionAmountType();
            setSurgicalOpinionMaxDaysTimesType();
            setFollowUpTreatmentAmountType();
        };

        function init() {
            initializeAmountTypes();
            vm.togglePrePostHospitalizationTreatment();
        }

        function initializeAmountTypes() {
            vm.isPreCovered = vm.scheduleOfBenefit.preHospitalizationTreatment.isCovered ? 1 : 0;
            vm.isPostCovered = vm.scheduleOfBenefit.postHospitalizationTreatment.isCovered ? 1 : 0;

            vm.specialistConsultationMaxDaysTimesType = vm.scheduleOfBenefit.specialistConsultation.isUnlimited ? 1 : 0;

            // Surgical Opinion
            if (!vm.scheduleOfBenefit.surgicalOpinion.isCovered)
                vm.surgicalOpinionAmountType = 2;
            else if (vm.scheduleOfBenefit.surgicalOpinion.isAsCharged)
                vm.surgicalOpinionAmountType = 0;
            else if (!vm.scheduleOfBenefit.surgicalOpinion.isAsCharged)
                vm.surgicalOpinionAmountType = 1;

            vm.surgicalOpinionMaxDaysTimesType = vm.scheduleOfBenefit.surgicalOpinion.isUnlimited ? 1 : 0;
            vm.followUpTreatmentAmountType = vm.scheduleOfBenefit.followUpTreatment.isAsCharged ? 0 : 1;
        }

        function setSpecialistConsultationMaxDaysTimesType() {
            switch (vm.specialistConsultationMaxDaysTimesType) {
                case 0:
                    vm.scheduleOfBenefit.specialistConsultation.isUnlimited = false;
                    break;

                case 1:
                    vm.scheduleOfBenefit.specialistConsultation.isUnlimited = true;
                    break;

                default:
                    break;
            }
        }

        function setSurgicalOpinionAmountType() {
            switch (vm.surgicalOpinionAmountType) {
                case 0:
                    vm.scheduleOfBenefit.surgicalOpinion.isAsCharged = true;
                    vm.scheduleOfBenefit.surgicalOpinion.isCovered = true;
                    break;

                case 1:
                    vm.scheduleOfBenefit.surgicalOpinion.isAsCharged = false;
                    vm.scheduleOfBenefit.surgicalOpinion.isCovered = true;
                    break;

                case 2:
                    vm.scheduleOfBenefit.surgicalOpinion.isAsCharged = false;
                    vm.scheduleOfBenefit.surgicalOpinion.isCovered = false;
                    break;

                default:
                    break;
            }
        }

        function setSurgicalOpinionMaxDaysTimesType() {
            switch (vm.surgicalOpinionMaxDaysTimesType) {
                case 0:
                    vm.scheduleOfBenefit.surgicalOpinion.isUnlimited = false;
                    break;

                case 1:
                    vm.scheduleOfBenefit.surgicalOpinion.isUnlimited = true;
                    break;

                default:
                    break;
            }
        }

        function setFollowUpTreatmentAmountType() {
            switch (vm.followUpTreatmentAmountType) {
                case 0:
                    vm.scheduleOfBenefit.followUpTreatment.isAsCharged = true;
                    break;

                case 1:
                    vm.scheduleOfBenefit.followUpTreatment.isAsCharged = false;
                    break;

                default:
                    break;
            }
        }

        angular.element(document).ready(function () {
            init();
        });
    }
})();
