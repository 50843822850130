// (function () {
//     var controllerId = 'host.views.inpatientGuaranteeLetters.updateEscalateRemarks.modal';
//     var app = angular.module('app');

//     app.controller(controllerId, [
//         '$scope', '$uibModalInstance', 'remarks',
//         function controller($scope, $uibModalInstance, remarks) {
//             var vm = this;

//             vm.cancel = cancel;
//             vm.save = save;
//             vm.resolve = resolve;
//             vm.remarks = remarks;

//             function cancel() {
//                 $uibModalInstance.dismiss();
//             }

//             function save() {
//                 if (App.isFormValid($scope.updateEscalateRemarksModalForm)) {
//                     $uibModalInstance.close({ isResolve: false, remark: vm.remarks });
//                 }
//             }

//             function resolve() {
//                 if (App.isFormValid($scope.updateEscalateRemarksModalForm)) {
//                   $uibModalInstance.close({ isResolve: true, remark: vm.remarks });
//               }
//             }
//         }
//     ])
// })

(function () {
    angular.module('app')
      .controller('host.views.inpatientGuaranteeLetters.updateEscalateRemarks.modal', [
          '$scope', '$uibModalInstance', 'remarks', controller]);

      function controller($scope, $uibModalInstance, remarks) {
        var vm = this;

        vm.cancel = cancel;
        vm.save = save;
        vm.resolve = resolve;
        vm.remarks = remarks;

        function cancel() {
            $uibModalInstance.dismiss();
        }

        // Saves the remark but does not update the resolve the escalation.
        function save() {
            if (App.isFormValid($scope.updateEscalateRemarksModalForm)) {
                $uibModalInstance.close({ isResolve: false, remark: vm.remarks });
            }
        }

        // Saves the remark and resolves the escalation.
        function resolve() {
            if (App.isFormValid($scope.updateEscalateRemarksModalForm)) {
              $uibModalInstance.close({ isResolve: true, remark: vm.remarks });
          }

      }
    }
  })();

