(function () {
    angular.module('app').component('hostInpatientGuaranteeLettersCommonInsuranceDetailsComponent', {
        templateUrl: require('/App/host/views/inpatientGuaranteeLetters/common/insuranceDetails/insuranceDetails.component.html'),
        controller: ['abp.services.app.masterPolicy', controller],
        controllerAs: 'vm',
        bindings: {
            masterPolicyData: '<'
        }
    });

    function controller(masterPolicySvc) {
        var vm = this;
        vm.loading = 0;
        vm.$onInit = onInit;
        vm.isPdf = true;

        function onInit() {
            vm.data = vm.masterPolicyData;
            vm.data.jacketPolicyAttachments = vm.data.jacketPolicyAttachments[0]
            if (vm.data.jacketPolicyAttachments.isImage) {
                vm.isPdf = false;
            }
        }
    }
})();
