(() => {
  angular.module('app').component('unitValueInput', {
    templateUrl: require('./unitValueInput.html'),
    controller: UnitValueInputController,
    controllerAs: 'vm',
    bindings: {
      unitValue: '=',
      units: '<',
      preferredUnit: '<',
      disabled: '<',
      placeholder: '@',
      onUpdate: '<',
      required: '<?',
    },
  });

  UnitValueInputController.$inject = ['$filter'];

  function UnitValueInputController($filter) {
    const vm = this;

    vm.suggestions = [];

    vm.refreshSuggestions = refreshSuggestions;
    vm.updated = updated;
    vm.$onChanges = $onChanges;

    const textFilter = $filter('unitValue');

    function createValueUnit(value, unit, customUnit) {
      const uv = {
        value,
        unit,
        customUnit,
      };
      uv.text = textFilter(uv);
      return uv;
    }

    function refreshSuggestions(input) {
      // Break input using regular expression into value and unit

      const match = /^\s*(\d*(\.\d+)?)(.*)/g.exec(input || '');
      if (!match) return;

      const strValue = _.trim(match[1]);
      const strUnit = _.toLower(_.trim(match[3]));

      if (!strValue) {
        // If value is invalid, return no suggestions

        vm.suggestions = [];
      } else {
        // If value is valid, compile and return suggestions

        const value = +strValue;

        const exactUnits = [];
        const matchedUnits = [];
        let unmatchedUnits = [];
        _.forEach(vm.units, (u) => {
          const idx = strUnit ? u.toLowerCase().indexOf(strUnit) : -1;
          let units = null;

          if (idx === 0 && u.length === strUnit.length) {
            units = exactUnits;
          } else if (idx >= 0) {
            units = matchedUnits;
          } else if (!strUnit) {
            units = unmatchedUnits;
          }

          units?.push(createValueUnit(value, u, false));
        });

        // If there is no exactly matched units, but there is
        // user unit input, return the user unit input as a new unit

        if (!exactUnits.length && strUnit) {
          unmatchedUnits.push(createValueUnit(value, strUnit, true));
        }

        // If preferred unit is provided, it should be suggested first

        const pu = _.toLower(_.trim(vm.preferredUnit || ''));
        if (pu && (!exactUnits.length || exactUnits[0].unit !== pu)) {
          let preferredUnit = null;

          let idx = _.findIndex(matchedUnits, (u) => u.unit === pu);
          if (idx >= 0) {
            [preferredUnit] = matchedUnits.splice(idx, 1);
          } else {
            idx = _.findIndex(unmatchedUnits, (u) => u.unit === pu);
            if (idx >= 0) [preferredUnit] = unmatchedUnits.splice(idx, 1);
          }

          if (!preferredUnit) preferredUnit = createValueUnit(value, pu, false);
          matchedUnits.splice(0, 0, preferredUnit);
        }

        // If preferred unit is provided, but there is no user input,
        // return the preferred unit only without other predefined units.

        if (!strUnit && pu) {
          unmatchedUnits = [];
        }

        // Return suggestions.
        // If preferred unit is provided, it should be suggested first,
        // followed by an exact units and unmatched units.
        // Else, exact units will be suggested first,
        // followed by other predefined units and unmatched units.

        if (pu && (!exactUnits.length || exactUnits[0].unit !== pu)) {
          vm.suggestions = matchedUnits.concat(exactUnits).concat(unmatchedUnits);
        } else {
          vm.suggestions = exactUnits.concat(matchedUnits).concat(unmatchedUnits);
        }
      }
    }

    function updated() {
      if (vm.onUpdate) vm.onUpdate();
    }

    function $onChanges() {
      if (vm.unitValue && !vm.unitValue.text) {
        vm.unitValue.text = textFilter(vm.unitValue);
      }
    }
  }
})();
