(function () {
    var componentId = 'hostInsurerTeamCreateEditContainer';
    var app = angular.module('app');

    function controller($state, $stateParams, insurerTeamsSvc, insurerSvc) {
        function init() {
            if (!vm.isCreate) {
                vm.getInsurerTeam();
            }

            vm.loading++;
            insurerSvc.getInsurer({
                id: vm.insurerId
            }).success(function (data) {
                vm.insurerName = data.name;
            }).finally(function () {
                vm.loading--;
            });
        }

        var vm = this;
        vm.isCreate = !$stateParams.insurerTeamId;
        vm.insurerId = $stateParams.insurerId;
        vm.insurerName = "";
        vm.isEditing = false;
        vm.loading = 0;
        vm.saving = 0;
        vm.hasInsurerModule = abp.setting.getBoolean('Hms.Feature.InsuranceModule');

        // Assign permissions

        vm.permissions = {
            edit: abp.auth.isGranted('Host.NewInsurers.Edit'),
            create: abp.auth.isGranted('Host.NewInsurers.Create')
        };

        var widgets = [];

        // Register child widget to this container.
        // Expose to child widget to register themselves.

        vm.addWidget = function (widget) {
            if (widget) {
                widgets.push(widget);
            }
        };

        // Save widget.

        vm.saveWidget = function (widget) {
            if (vm.isCreate) return; // Prevent saving individual widget during creation.
            if (vm.saving || widget.saving) return;
            if (!widget.validateForm()) {
                abp.notify.error(App.localize('GeneralInvalidFormInputError'));
                return;
            }

            widget.saving++;
            save(vm.isCreate, widget.getPayload(), function () {
                widget.saving--;
            });
        };

        // Save all changes on the form to insurer.

        vm.saveAll = function () {
            var error = false;

            if (vm.saving || vm.loading) return;

            var finalPayload = {};
            _.each(widgets, function (w) {
                if (!w.validateForm()) {
                    error = true;
                }

                var p = w.getPayload();
                _.extend(finalPayload, p);
            });

            // Stop saving if any error occurs.

            if (error) {
                abp.notify.error(App.localize('GeneralInvalidFormInputError'));
                return;
            }

            vm.saving++;
            save(vm.isCreate, finalPayload, function () {
                vm.saving--;
            });
        };

        vm.toggleEdit = function (flag) {
            vm.isEditing = flag;
            _.each(widgets, function (w) {
                w.isEditing = flag;
            });

            if (vm.isEditing)
                getInsurerTeam($stateParams.insurerTeamId);
        };

        vm.getInsurerTeam = function () {
            getInsurerTeam($stateParams.insurerTeamId);
        };

        function getInsurerTeam(teamId) {
            vm.loading++;
            insurerTeamsSvc.getInsurerTeamForEdit({
                id: teamId,
            }).success(function (n) {
                vm.insurerTeamId = n.id;
                vm.data = n;
            }).finally(function () {
                vm.loading--;
            });
        }

        // Create or update insurer with the given payload.
        // Callback should be used for handling vm.saving in child widget.

        function save(isCreate, payload, callback) {
            if (isCreate) {
                createInsurerTeam(payload, callback);
            } else {
                updateInsurerTeam(payload, callback);
            }
        }

        // Create insurer with the given payload.

        function createInsurerTeam(payload, callback) {
            _.extend(payload, {insurerId: vm.insurerId});
            insurerTeamsSvc.createInsurerTeam(payload)
                .success(function (data) {
                    abp.notify.info(App.localize('SuccessfullySaved'));
                    $state.go('host.createEditInsurerTeam', {insurerTeamId: data.id});
                    vm.toggleEdit(false);
                })
                .finally(function () {
                    if (_.isFunction(callback)) callback();
                });
        }

        // Update insurer with the given payload.

        function updateInsurerTeam(payload, callback) {
            _.extend(payload, {id: vm.insurerTeamId});

            insurerTeamsSvc.updateInsurerTeam(payload)
                .success(function (data) {
                    abp.notify.info(App.localize('SuccessfullySaved'));
                    vm.toggleEdit(false);
                })
                .finally(function () {
                    if (_.isFunction(callback)) callback();
                });
        }

        // Cancel creation or edit.

        function toggleCancel(resetInsurer) {
            if (vm.isCreate) window.history.back();
            else {
                vm.toggleEdit(false);
                if (resetInsurer) getInsurerTeam($stateParams.insurerTeamId);
            }
        }

        vm.cancel = function () {
            var dirtyWidgets = _.filter(widgets, function (w) {
                return w.isDirty();
            });
            if (!dirtyWidgets.length) toggleCancel(false);
            else {
                abp.message.confirm(App.localize('UnsavedChangesConfirmation'), App.localize('AreYouSure'), function (d) {
                    if (d) {
                        toggleCancel(true);
                        _.each(dirtyWidgets, function (w) {
                            w.setPristine();
                        });
                    }
                });
            }
        };

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/insurers/insurerTeams/createEdit/container/container.component.html'),
        controller: ['$state', '$stateParams', 'abp.services.app.insurerTeam', 'abp.services.app.insurer', controller],
        controllerAs: 'vm'
    });
})();
