(function () {
    var componentId = 'hostOperationsDashboardHmsEmployeeTicketsWidget';
    var app = angular.module('app');

    function controller(operationsDashboardSvc, moment) {
        function init() {
            vm.refresh();
        }

        var vm = this;
        vm.loading = 0;

        vm.$onInit = function () {
            vm.container.addWidget(this);
        };

        function getHmsEmployeeTickets(skipCount) {
            var input = {
                skipCount: skipCount,
                maxResultCount: app.consts.maxResultCount,
                startDate: vm.dateRange.startDate,
                endDate: vm.dateRange.endDate
            };

            vm.loading++;
            operationsDashboardSvc.getHmsEmployeeTickets(input)
                .success(function (data) {
                    vm.totalCount = data.totalCount;
                    vm.data = vm.data.concat(data.items);
                    vm.toggleShowData();
                }).finally(function () {
                    vm.loading--;
                });
        }

        vm.refresh = function () {
            vm.data = [];
            vm.showAll = true;
            getHmsEmployeeTickets(0);
        };

        vm.toggleShowData = function () {
            vm.showAll = !vm.showAll;
            vm.records = vm.showAll ? vm.data : vm.data.slice(0, 5);
        };

        vm.dateRangeOptions = $.extend({}, App.createDateRangePickerOptions(), {
            eventHandlers: {
                'apply.daterangepicker': function (ev, picker) {
                    vm.refresh();
                }
            }
        });

        vm.dateRange = {
            startDate: moment().subtract(3, 'days'),
            endDate: moment().endOf('day')
        };

        init();
    }

    app.component(componentId, {
        require: {
            container: '^hostOperationsDashboardDashboardContainer'
        },
        templateUrl: require('/App/host/views/operationsDashboard/hmsEmployeeTickets/widget.html'),
        controller: ['abp.services.app.operationsDashboard', 'moment', controller],
        controllerAs: 'vm'
    });
})();
