(function () {
    var componentId = 'corporateMemberDataConsentTimelineComponent';
    var app = angular.module('app');

    function controller($rootScope, $scope, $stateParams, employeeSvc) {
        var vm = this;
        vm.loading = 0;
        vm.memberDisplayName = '';
        vm.timeline = [];
        vm.footnotes = [];

        $scope.$on('refresh', function (event, args) {
            getMemberDataConsentTimeline();
        });

        vm.viewAttachments = viewAttachments;

        function getMemberDataConsentTimeline() {
            if (vm.loading) return;

            vm.loading++;
            employeeSvc.getMemberDataConsentTimeline({
                id: $stateParams.id
            }).success(function (data) {
                vm.memberDisplayName = data.memberNationalId
                    ? data.memberName + ' (' + data.memberNationalId + ')'
                    : data.memberName;
                vm.componentTitle = App.localize('DataConsentTimelineTitle', vm.memberDisplayName);

                vm.canDelegateConsent = data.canDelegateConsent;
                vm.hasDelegateDataConsentGranted = data.hasDelegateDataConsentGranted;
                vm.timeline = data.timeline;
                processTimeline();
            }).finally(function () {
                vm.loading--;
            });
        }

        var footnoteNarratives = [
            {
                id: 1,
                intentionKey: 'Subsidiary',
                narrative: App.localize('DataConsentTimeline_SubsidiaryNarrative')
            }, {
                id: 2,
                intentionKey: 'Delegate',
                narrative: App.localize('DataConsentTimeline_DelegateNarrative')
            }, {
                id: 3,
                intentionKey: 'Individual',
                narrative: App.localize('DataConsentTimeline_IndividualNarrative')
            }, {
                id: 4,
                intentionKey: 'Principal',
                narrative: App.localize('DataConsentTimeline_PrincipalNarrative')
            }
        ];

        function processTimeline() {
            var index = -1;

            function getIndex(footnote) {
                var idx = _.findIndex(vm.footnotes, function (fn) { return fn.id === footnote.id; });
                if (idx < 0) {
                    vm.footnotes.push(footnote);
                    idx = vm.footnotes.length - 1;
                }
                return idx;
            }

            _.each(vm.timeline, function (item) {
                _.each(footnoteNarratives, function (d) {
                    if (!_.startsWith(item.intention, d.intentionKey)) return;

                    index = getIndex(d);
                    return false;
                });

                if (index > -1) {
                    item.footnoteIndex = index;
                }
            });
        }

        function viewAttachments(attachments) {
            if (!attachments || !attachments.length) return;
            $rootScope.$broadcast('showAttachments', { attachments: attachments });
        }

        getMemberDataConsentTimeline();
    }

    app.component(componentId, {
        templateUrl: require('/App/corporate/views/employees/dataConsent/timeline/timeline.component.html'),
        controller: ['$rootScope', '$scope', '$stateParams', 'abp.services.app.employee', controller],
        controllerAs: 'vm',
        bindings: {
            loading: '=',
            memberDisplayName: '=',
            canDelegateConsent: '=',
            hasDelegateDataConsentGranted: '='
        }
    });
})();
