(function () {
    'use strict';

    angular.module('app').component('hostCorporatesSupportPlansCreateEditScheduleOfBenefitAccommodationComponent', {
        templateUrl: require('/App/host/views/corporates/supportPlans/createEdit/scheduleOfBenefit/accommodation/accommodation.component.html'),
        controller: ['$scope', controller],
        controllerAs: 'vm',
        bindings: {
            scheduleOfBenefit: '<',
            currencyCode: '<'
        }
    });

    function controller($scope) {
        var vm = this;
        vm.roomAndBoardText = App.localize('RoomAndBoard');
        vm.specialRoomAndBoardText = App.localize('SpecialRoomAndBoard');
        vm.lodgingText = App.localize('InsuredChildDailyGuardianBenefit');
        vm.dailyText = App.localize('PerDay');
        vm.yearlyText = App.localize('PerYear');
        vm.limitedText = App.localize('Limited');
        vm.hasScheduleOfBenefitEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');
        vm.icuAmountType = null;
        vm.childGuardianBenefitAmountType = null;
        vm.minIcuRoomAndBoard = null;
        vm.maxIcuRoomAndBoard = null;

        vm.toggleAccommodation = function () {
            vm.minIcuRoomAndBoard = vm.scheduleOfBenefit.icuRoomAndBoard.amountType === 1 ? 1 : 0;
            vm.minChildGuardian = vm.scheduleOfBenefit.childGuardianBenefit.amountType === 1 ? 1 : 0;
            setIcuAmountType();
            setChildGuardianBenefitAmountType();
        };

        vm.setMaxIcuRoomAndBoard = function () {
            vm.maxIcuRoomAndBoard = vm.scheduleOfBenefit.accomodationDaysCombined ?
                vm.scheduleOfBenefit.roomAndBoard.accomodationDays : null;
        }

        function setIcuAmountType() {
            switch (vm.icuAmountType) {
                case 0:
                    vm.scheduleOfBenefit.icuRoomAndBoard.isAsCharged = true;
                    break;

                case 1:
                    vm.scheduleOfBenefit.icuRoomAndBoard.isAsCharged = false;
                    break;

                default:
                    break;
            }
        }

        function setChildGuardianBenefitAmountType() {
            switch (vm.childGuardianBenefitAmountType) {
                case 0:
                    vm.scheduleOfBenefit.childGuardianBenefit.isAsCharged = true;
                    vm.scheduleOfBenefit.childGuardianBenefit.isCovered = true;
                    break;

                case 1:
                    vm.scheduleOfBenefit.childGuardianBenefit.isAsCharged = false;
                    vm.scheduleOfBenefit.childGuardianBenefit.isCovered = true;
                    break;

                case 2:
                    vm.scheduleOfBenefit.childGuardianBenefit.isCovered = false;
                    break;

                default:
                    break;
            }
        }

        function init() {
            vm.icuAmountType = vm.scheduleOfBenefit.icuRoomAndBoard.isAsCharged ? 0 : 1;

            if (!vm.scheduleOfBenefit.childGuardianBenefit.isCovered)
                vm.childGuardianBenefitAmountType = 2;
            else if (vm.scheduleOfBenefit.childGuardianBenefit.isAsCharged)
                vm.childGuardianBenefitAmountType = 0;
            else if (!vm.scheduleOfBenefit.childGuardianBenefit.isAsCharged) {
                vm.childGuardianBenefitAmountType = 1;
            }

            vm.toggleAccommodation();
        }

        angular.element(document).ready(function () {
            init();
        });
    }
})();
