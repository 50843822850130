(function () {
    var controllerId = 'corporate.views.annualUtilizationReport.index';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$state', '$stateParams', '$uibModal', 'appSession', 'abp.services.app.commonLookup', 'abp.services.app.report', 'Hms.Employees.EmployeeState', 'moment',
        function ($state, $stateParams, $uibModal, appSession, commonLookupSvc, reportSvc, enumEmployeeState, moment) {
            var vm = this;

            function init() {
                vm.fyChanged();
                lookupBenefitGroups();
                lookupSubsidiaries();
                lookupEmployeeFields();
                getAnnualUtilizationReportFields();
            }

            vm.hasInsuranceModule = abp.setting.getBoolean('Hms.Feature.InsuranceModule')
                && abp.features.isEnabled('HasInsurance');

            vm.dataExportRecordName = "AnnualUtilizationReport";
            vm.generateButtonType = App.localize('Generate');

            vm.enums = {};
            vm.enums.employeeState = enumEmployeeState;

            vm.isSubsidiary = appSession.user.subsidiaryId;
            vm.isSubsidiaryDepartment = appSession.user.subsidiaryDepartmentId;
            vm.financialYears = _.range(appSession.tenant.currentFinancialYear, appSession.tenant.firstFinancialYear - 1, -1);
            vm.loading = 0;
            vm.requestParams = {
                financialYear: $stateParams.financialYear || appSession.tenant.currentFinancialYear,
                startDate: $stateParams.startDate,
                endDate: $stateParams.endDate,
                employeeType: $stateParams.employeeType,
                employee: $stateParams.employee,
                nationalId: $stateParams.nationalId,
                employeeNumber: $stateParams.employeeNumber,
                benefitGroupIds: $stateParams.benefitGroupIds,
                subsidiaryIds: $stateParams.subsidiaryIds || [],
                departmentIds: $stateParams.departmentIds || [],
                isActive: $stateParams.isActive,
                employeeState: $stateParams.employeeState,
            };

            vm.advancedFiltersAreShown = vm.requestParams.employee || vm.requestParams.nationalId
                || vm.requestParams.employeeNumber || vm.requestParams.benefitGroupIds
                || vm.requestParams.subsidiaryIds === [] || vm.requestParams.departmentIds === [];

            /* Date Range Functions */

            vm.dateRangeOptions = _.map(vm.financialYears, function (d) {
                var opt = App.createDateRangePickerOptions();
                var minDate = moment([d, appSession.tenant.firstFinancialMonth - 1, 1]);
                var maxDate = moment.min(moment([d + 1, appSession.tenant.firstFinancialMonth - 1, 1]).subtract(1, 'days'), moment(opt.maxDate));
                opt.minDate = moment(minDate);
                opt.min = moment(minDate);
                opt.maxDate = moment(maxDate);
                opt.max = moment(maxDate);
                opt.financialYear = d;
                return opt;
            });

            vm.dateRangeModel = {};

            if ($stateParams.startDate || $stateParams.endDate) {
                var opt = _.find(vm.dateRangeOptions, { 'financialYear': vm.requestParams.financialYear });
                if ($stateParams.startDate) {
                    vm.dateRangeModel.startDate = moment.max(moment.min(moment($stateParams.startDate), opt.maxDate), opt.minDate);
                }
                if ($stateParams.endDate) {
                    vm.dateRangeModel.endDate = moment.min(moment.max(moment($stateParams.endDate), opt.minDate), opt.maxDate);
                }
            }

            vm.fyChanged = function () {
                if (!_.isNil(vm.requestParams.financialYear)) {
                    var opt = _.find(vm.dateRangeOptions, { 'financialYear': vm.requestParams.financialYear });
                    vm.dateRangeModel.startDate = moment(opt.minDate);
                    vm.dateRangeModel.endDate = moment(opt.maxDate);
                }
            };

            /* End of Date Range Functions */

            /* Loop-up Functions */

            vm.benefitGroups = [];
            function lookupBenefitGroups() {
                commonLookupSvc.findBenefitGroups({
                    maxResultCount: 999
                }).success(function (data) {
                    vm.benefitGroups = data.items;
                });
            }

            vm.subsidiaries = [];

            function lookupSubsidiaries() {
                commonLookupSvc.getCorporateSubsidiaries({ getDeletedSubsidiariesOrDepartment: true }).success(function (data) {
                    vm.subsidiaries = data.items;
                });
            }

            vm.departments = [];
            var allDepartments = [];
            vm.getCorporateSubsidiaryDepartments = function () {
                if (!allDepartments.length) {
                    commonLookupSvc.getCorporateSubsidiaryDepartments({ getDeletedSubsidiariesOrDepartment: true }).success(function (data) {
                        allDepartments = data.items;
                    });
                }
            };

            vm.getDepartments = function (search) {
                var subsidiaryIds = vm.isSubsidiary
                    ? appSession.user.subsidiaryId
                    : vm.requestParams.subsidiaryIds;

                if (subsidiaryIds.length > 0) {
                    vm.departments = [];

                    // Change department filter list according to selected subsidiaries.

                    _.forEach(subsidiaryIds, function (id) {
                        var d = _.filter(allDepartments, { 'subsidiaryId': id });
                        vm.departments = _.concat(vm.departments, d);
                    });
                } else {
                    vm.departments = allDepartments;
                }

                if (search) {
                    vm.departments = _.filter(vm.departments, function (d) {
                        return _.includes(d.name.toLowerCase(), search.toLowerCase())
                    });

                    // Only return top 10 in the department list for display.

                    vm.departments = _.slice(vm.departments, 0, 10);
                } else {
                    vm.departments = [];
                }
            };

            vm.resetDepartments = function () {
                vm.requestParams.departmentIds = [];

                vm.departments = [];
            };

            vm.employeeFields = [];
            function lookupEmployeeFields() {
                commonLookupSvc.findEmployeeFields().success(function (data) {
                    if (data.items) {
                        vm.employeeFields = data.items;
                    }
                });
            }

            /* End of Loop-up Functions */

            /* Report Generation */

            var reportTemplateName = '';
            var reportFields = [];
            function getAnnualUtilizationReportFields() {
                vm.loading++;
                reportSvc.getAnnualUtilizationReportFields().success(function (data) {
                    reportTemplateName = data.reportTemplateName;
                    reportFields = data.reportFields;
                }).finally(function () {
                    vm.loading--;
                });
            }

            function registerStateParams() {
                var startDate = null;
                var endDate = null;
                if (vm.dateRangeModel.startDate)
                    startDate = vm.dateRangeModel.startDate.format('YYYY-MM-DD');
                if (vm.dateRangeModel.endDate)
                    endDate = vm.dateRangeModel.endDate.format('YYYY-MM-DD');

                function getValueForArray(value) {
                    value = value || [];
                    return value.length ? value : null;
                }

                $state.transitionTo($state.current,
                    {
                        financialYear: vm.requestParams.financialYear,
                        startDate: startDate,
                        endDate: endDate,
                        employeeType: vm.requestParams.employeeType,
                        employee: vm.requestParams.employee,
                        nationalId: vm.requestParams.nationalId,
                        employeeNumber: vm.requestParams.employeeNumber,
                        benefitGroupIds: getValueForArray(vm.requestParams.benefitGroupIds),
                        subsidiaryIds: getValueForArray(vm.requestParams.subsidiaryIds),
                        departmentIds: getValueForArray(vm.requestParams.departmentIds),
                        isActive: vm.requestParams.isActive
                    }, {
                    notify: false
                });
            }

            function getEmployeeFieldFilters() {
                var employeeFieldFilters = [];
                _.each(vm.employeeFields, function (f) {
                    if (f.filter) {
                        var filter = _.trim(f.filter);
                        if (filter) {
                            employeeFieldFilters.push({
                                name: filter,
                                value: f.value
                            });
                        }
                    }
                });
                return { employeeFields: employeeFieldFilters };
            }

            function getRequestInput(selectedFields) {
                var input = $.extend({}, vm.requestParams, vm.dateRangeModel, getEmployeeFieldFilters(), { reportFields: selectedFields });
                input.startDate = vm.dateRangeModel.startDate.format('YYYY-MM-DD');
                input.endDate = vm.dateRangeModel.endDate.format('YYYY-MM-DD');
                return _.omitBy(input, _.isNil);
            }

            function exportToExcel(option) {
                registerStateParams();
                option = option || getRequestInput();

                vm.loading++;
                reportSvc.enqueueGenerateAnnualUtilizationExcelReportJob(option).success(function (data) {
                    vm.refresh();
                }).finally(function () {
                    vm.loading--;
                });
            }

            vm.generate = function () {
                $uibModal.open({
                    templateUrl: require('/App/common/views/common/selectionModal.html'),
                    controller: "common.views.common.selectionModal as vm",
                    backdrop: "static",
                    resolve: {
                        templateName: function () {
                            return reportTemplateName;
                        },
                        fields: function () {
                            return reportFields;
                        },
                        modalTitle: function () {
                            return App.localize('SelectReportFields');
                        }
                    }
                }).result.then(function (selectedFields) {
                    var option = getRequestInput(selectedFields);
                    exportToExcel(option);
                });
            };

            /* End of Report Generation */

            init();
        }
    ]);
})();
