(function () {
    'use strict';

    angular
        .module('app')
        .controller('host.views.forms.formDetails', ['$scope', '$stateParams', '$state',
            'abp.services.app.formTemplate', 'Hms.FormTemplates.FormNames', 'Hms.FormTemplates.TargetUserType', controller]);

    function controller($scope, $stateParams, $state, formTemplateSvc, enumFormNames, enumTargetUserType) {
        function init() {
            getAllServiceTypes();
            getFormTemplate();
        }

        var vm = this;
        var guidEmpty = '00000000-0000-0000-0000-000000000000'
        vm.formId = $stateParams.formTemplateId || guidEmpty;
        vm.loading = 0;
        vm.saving = 0;
        vm.isCreate = vm.formId === guidEmpty;
        vm.isEdit = false;
        vm.getDisplayServiceType = "";

        // List of all service types nameValueDto

        vm.serviceTypes = [];

        // New attachment to be uploaded

        vm.newAttachments = [];

        // Existing attachments

        vm.oldAttachments = [];

        vm.enums = {};
        vm.enums.formNames = enumFormNames;
        vm.enums.targetUserType = enumTargetUserType;

        function getFormTemplate() {
            vm.loading++;
            formTemplateSvc.getFormTemplateForEdit({
                id: vm.formId
            }).success(function (data) {
                vm.form = data
                vm.form.name = vm.form.name != 0 ? vm.form.name : null;
                vm.form.targetUserType = vm.form.targetUserType != 0 ? vm.form.targetUserType : null;
                vm.getDisplayServiceType = getDisplayServiceType(vm.form.serviceType);
                vm.oldAttachments = vm.form.attachments;

                //DeepCopy for Reverting Changes
                vm.formOri = angular.copy(data);
            }).finally(function (e) {
                vm.loading--;
            });
        };

        // Retrieve all service types from backend

        function getAllServiceTypes() {
            formTemplateSvc.getAllServiceTypes()
                .success(function (data) {
                    vm.serviceTypes = data.items;
                });
        }

        // To get the name of the service type based on the value(integer type)

        function getServiceType(serviceType) {
            var serviceType = _.find(vm.serviceTypes, ['value', serviceType]);
            return serviceType.name;
        };

        // Get the string of the form's service types

        function getDisplayServiceType(list) {
            return _.map(list, getServiceType).toString();
        };

        // Check if the form is valid

        function validateForm() {
            var form = $scope.formDetails;
            if (vm.isCreate) vm.hasAttachment = vm.newAttachments.length != 0
            if (vm.isEdit) vm.hasAttachment = vm.newAttachments.length != 0 || vm.oldAttachments.length != 0;
            return App.isFormValid(form) && vm.hasAttachment ? true : false;
        }

        vm.toggleEdit = function (flag) {
            vm.isEdit = flag;
        };

        // Upload new attachement

        var fileExtensions = '|' + angular.module('app').consts.fileUpload.imageExtensions
            .concat(angular.module('app').consts.fileUpload.pdfExtensions).join('|') + '|';

        vm.uploadConfig = {
            objectType: 'FormAttachment',
            itemsLimit: 1,
            accept: 'image/*,application/pdf',
            filters: [{
                name: 'fileFilter',
                fn: function (n) {
                    var t = '|' + n.type.slice(n.type.lastIndexOf('/') + 1) + '|';
                    if (fileExtensions.indexOf(t) === -1) {
                        abp.message.warn(App.localize('File_Warn_FileType'));
                        return false;
                    }
                    return true;
                }
            }, {
                name: 'sizeFilter',
                fn: function (n) {
                    var sizeLimit = 52428800;
                    if (n.size > sizeLimit) {
                        abp.message.warn(App.localize('File_Warn_XSizeLimit', sizeLimit / 1024 / 1024));
                        return false;
                    }
                    return true;
                }
            }]
        };

        // Save the form template
        vm.save = function () {
            if (!validateForm()) return;
            vm.saving++;
            vm.form.attachments = vm.oldAttachments
            vm.form.newAttachments = vm.newAttachments
            formTemplateSvc.createOrUpdateFormTemplate(vm.form)
                .success(function () {
                    abp.notify.info(App.localize('SuccessfullySaved'));
                    $state.go('host.form');
                }).finally(function () {
                    vm.saving--;
                });
        };

        // Cancel Button
        vm.cancel = function () {
            if (vm.isEdit) {
                vm.toggleEdit(false);
                vm.form = angular.copy(vm.formOri);
            } else if (vm.isCreate) {
                $state.go('host.form');
            }   
        }
        init();
    }
})();
