(() => {
  angular.module('app').component('commonPostHospitalizationGuaranteeLettersCommonPostHospitalizationDetailsComponent', {
    templateUrl: require('./postHospitalizationDetails.component.html'),
    controller: PostHospitalizationDetailsController,
    controllerAs: 'vm',
    bindings: {
      postHospitalizationDetails: '<',
      enums: '<',
      currencyCode: '<'
    }
  });

  PostHospitalizationDetailsController.$inject = [];

  function PostHospitalizationDetailsController() {

    var vm = this;

    init();

    function init() { }
  }
})();
