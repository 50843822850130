(function () {
    var controllerId = 'corporate.views.panelAccesses.policyRuleModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', '$timeout', 'abp.services.app.panelAccess', 'panelType', 'serviceType', 'rule', 'states', 'cities', 'panelLocation', 'hasAllScopeSet', 'isEdit', 'countryCode',
        function ($scope, $uibModalInstance, $timeout, panelAccessSvc, panelType, serviceType, rule, states, cities, panelLocation, hasAllScopeSet, isEdit, countryCode) {
            function init() {
                if (rule.scope === 3) {
                    vm.rule.scopeValues = _.map(vm.rule.scopeValues, decodeCityState);
                }
                if (vm.rule.scope === 4) {
                    vm.getPanelLocation();
                }
                updateRule();
            }
            var vm = this;
            vm.isEdit = isEdit;
            vm.saving = 0;
            vm.showLearnPriceCategory = false;
            vm.nationalAverage = abp.setting.get('Hms.PanelLocationPriceRating.NationalGpAvg');
            vm.countryCode = countryCode;

            vm.isAllowByPriceEnabled = function () {
                return vm.rule.scope !== 4
                    && (vm.panelType === 1 || vm.serviceType === 1)
                    && (vm.countryCode === "MY" || vm.hasSingaporePanelPricing)
            }

            // Feature flags

            vm.hasSingaporePanelPricing = abp.setting.getBoolean('Hms.Feature.SingaporePanelPricing');

            // To determine if host enabled Advanced Panel Access Policy for corporate.

            vm.hasAdvancedPanelAccessPolicy = abp.features.isEnabled('AdvancedPanelAccessPolicy');

            vm.rule = angular.copy(rule);

            // Scope values dropdown

            vm.allStates = states;

            vm.allStates.sort();

            vm.getStates = function (search) {
                if (search) {
                    vm.states = _.filter(vm.allStates, function (d) {
                        return _.includes(d.toLowerCase(), search.toLowerCase());
                    });

                    // Only return top 10 in the states list for display.

                    vm.states = _.slice(vm.states, 0, 10);
                } else {
                    vm.states = [];
                }
            };

            vm.allCities = cities;

            vm.allCities.sort();

            vm.getCities = function (search) {
                if (search) {
                    vm.cities = _.filter(vm.allCities, function (d) {
                        return _.includes(d.toLowerCase(), search.toLowerCase());
                    });

                    // Only return top 10 in the cities list for display.

                    vm.cities = _.slice(vm.cities, 0, 10);
                } else {
                    vm.cities = [];
                }
            };

            vm.allPanelLocationsbyTypes = panelLocation;

            vm.getPanelLocation = function (search) {
                if (vm.rule.scopeValues.length > 0 && !search) {
                    vm.panelLocationsbyTypes = _.filter(vm.allPanelLocationsbyTypes, function (pl) {
                        return _.some(vm.rule.scopeValues, function (value) { return value === pl.value; });
                    });
                } else if (search) {
                    var tokens = splitIntoTokens(search);

                    var panelLocations = [];

                    var filteredPanelLocationsbyTypes = angular.copy(vm.allPanelLocationsbyTypes);

                    _.forEach(tokens, function (token) {
                        var tokenPanelLocations = _.filter(filteredPanelLocationsbyTypes, function (d) {
                            return _.includes(d.name.toLowerCase(), token) || _.includes(d.address.toLowerCase(), token);
                        });
                        panelLocations = _.concat(panelLocations, tokenPanelLocations);
                    });

                    var occurencesPanelLocationIds = getkTopOccurencesItems(panelLocations, tokens.length);

                    var selectedLocations = _.remove(filteredPanelLocationsbyTypes, function (pl) {
                        return _.some(vm.rule.scopeValues, function (value) { return value === pl.value; });
                    });

                    vm.panelLocationsbyTypes = _.filter(filteredPanelLocationsbyTypes, function (d) {
                        return _.includes(occurencesPanelLocationIds, d.value);
                    });

                    // Only return top 10 in the panel list for display.

                    vm.panelLocationsbyTypes = _.slice(vm.panelLocationsbyTypes, 0, 10);
                    vm.panelLocationsbyTypes = _.concat(vm.panelLocationsbyTypes, selectedLocations);
                    vm.panelLocationsbyTypes = _.filter(vm.panelLocationsbyTypes, function (pl) {
                        return pl.isActive && pl.showOnClinicLocator;
                    });
                } else {
                    vm.panelLocationsbyTypes = [];
                }
            };

            vm.filterScopeValues = function () {
                /* Scope
                * 1 - All
                * 2 - State
                * 3 - City
                * 4 - Specific
               */

                if (vm.rule.scopeValues && vm.rule.scopeValues.length > 0) {
                    if (vm.rule.scope === 2) {
                        vm.rule.scopeValues = _.filter(vm.rule.scopeValues, function (sv) {
                            return _.some(vm.allStates, function (state) { return sv === state })
                        });
                    }
                    else if (vm.rule.scope === 3) {
                        vm.rule.scopeValues = _.filter(vm.rule.scopeValues, function (sv) {
                            return _.some(vm.allCities, function (city) { return sv === city })
                        });
                    }
                    else if (vm.rule.scope === 4) {
                        vm.rule.scopeValues = _.filter(vm.rule.scopeValues, function (sv) {
                            return _.some(vm.allPanelLocationsbyTypes, function (pl) { return sv === pl.value })
                        });
                    }
                }
            };

            vm.hasAllScopeSet = vm.rule.scope !== 1 && hasAllScopeSet;

            // End Scope values dropdown

            vm.serviceType = serviceType;
            vm.panelType = panelType;

            var firstBinLowerBoundary = 0;
            var lastBinUpperBoundary = 0;

            // Localization

            vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
            vm.getAllowedLocationText = function () {
                if (vm.rule.priceControl.mode === 1) {
                    return App.localize('RuleAllowLocationByCategoryMessage', vm.rule.panelLocationCount, vm.rule.priceControl.value);
                } else if (vm.rule.priceControl.mode === 2) {
                    return App.localize('RuleAllowLocationByAmountMessage', vm.rule.panelLocationCount, vm.currencyCode + vm.rule.priceControl.value);
                }
            };

            vm.subdivisionLevelName = vm.countryCode === 'SG' ? App.localize('District') : App.localize('State');

            vm.subdivisionAddingTooltip = vm.countryCode === 'SG' ? App.localize('AddOneOrMoreDistrict') : App.localize('AddOneOrMoreStates');

            // End localization

            // Button change

            vm.ruleSetting = vm.rule.scope === 2 || vm.rule.scope === 3 ? 2 : 1;

            vm.changeRuleSetting = function () {
                updateRule();
            };

            vm.changeScope = function (scope) {
                vm.rule.scopeValues = [];
                vm.rule.scope = scope;
                updateRule();
            };

            vm.changeControlMode = function (controlMode) {
                vm.rule.controlMode = controlMode;
                updateRule();
            };

            vm.changePriceControlMode = function (controlMode) {
                vm.rule.priceControl.mode = controlMode;
                vm.rule.priceControl.value = null;
                updateRule();
            };

            // End Button change

            vm.save = function () {
                if (vm.saving) return;

                // Clean up null scope values when saving panel access rules.

                vm.filterScopeValues();

                if (!App.isFormValid($scope.policyRuleForm)) return;

                vm.saving++;
                if (vm.rule.priceControl.mode === 1) {
                    vm.rule.priceControl.value = getPriceControlCategoryEnum(vm.rule.priceControl.value);
                }
                if (vm.rule.scope === 3) {
                    vm.rule.scopeValues = _.map(vm.rule.scopeValues, encodeCityState);
                }

                updateScopeName();
                $uibModalInstance.close({ rule: vm.rule });
                vm.saving--;
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            // Split search keywords into tokens.

            function splitIntoTokens(search) {
                var tokens = _.split(search.toLowerCase(), ' ');
                tokens = _.filter(tokens, function (token) {
                    return token && token.length > 0;
                });
                return tokens;
            }

            // Get k top occurences items.

            function getkTopOccurencesItems(listName, kCount) {
                if (!listName || listName.length == 0 || typeof kCount !== 'number' || kCount === 0) {
                    return [];
                }

                var mimimumValue = _.reduce(listName, function (previous, current) {
                    return previous.value <= current.value ? previous : current;
                }).value;

                var maximumValue = _.reduce(listName, function (previous, current) {
                    return previous.value > current.value ? previous : current;
                }).value;

                var count = {};

                for (var i = mimimumValue; i <= maximumValue; i++) {
                    count[i] = 0;
                }

                for (i = 0; i < listName.length; i++) {
                    count[listName[i].value]++;
                }

                var kTopOccurences = [];

                for (i = mimimumValue; i <= maximumValue; i++) {
                    if (count[i] == kCount) {
                        kTopOccurences.push(i);
                    }
                }

                return kTopOccurences;
            }

            // Format rule

            function updateRule() {
                // ruleSetting (scope)
                // 1: basic (1: All, 4: Specific),
                // 2: advance (1: All, 2: State, 3: City 4: Specific)
                // Only scope 2,3,4 has scopeValues

                if (vm.ruleSetting === 1 && (vm.rule.scope === 2 || vm.rule.scope === 3)) {
                    vm.rule.scope = vm.hasAllScopeSet ? 4 : 1;
                    vm.rule.scopeValues = [];
                }

                // ControlMode (1: allow, 2: block, 3: controlByPrice)
                // Scope specific has no price control

                if (vm.rule.scope === 4 && vm.rule.controlMode === 3) {
                    vm.rule.controlMode = 1;
                    vm.rule.priceControl = {};
                }

                // ControlMode allow and block has no price control

                else if (vm.rule.controlMode === 1 || vm.rule.controlMode === 2) {
                    vm.rule.priceControl = {};
                }

                else if (vm.rule.controlMode === 3) {
                    vm.rule.priceControl.mode = vm.rule.priceControl.mode ? vm.rule.priceControl.mode : 1;
                    vm.rule.priceControl.value = vm.rule.priceControl.value
                        ? vm.rule.priceControl.value
                        : vm.rule.priceControl.mode === 1
                            ? '2'
                            : Math.ceil(vm.nationalAverage / 10) * 10;
                    vm.refreshPriceControl();
                }

                vm.updatePanelLocationCount();
            }

            vm.pricingLocations = [];
            vm.scopeValues = [];
            vm.refreshPriceControl = function () {
                if (vm.pricingLocations.length === 0
                    || vm.scope !== vm.rule.scope
                    || vm.scopeValues && vm.rule.scopeValues && vm.scopeValues.length !== vm.rule.scopeValues.length) {
                    vm.scope = vm.rule.scope;
                    vm.scopeValues = vm.rule.scopeValues ? vm.rule.scopeValues : [];

                    var scopeValues = vm.rule.scope === 3
                        ? _.map(vm.rule.scopeValues, encodeCityState)
                        : vm.rule.scopeValues;

                    panelAccessSvc.getPanelLocationPriceRatings({
                      serviceType: vm.serviceType,
                      scope: vm.rule.scope,
                      scopeValues: scopeValues
                    }).success(function (data) {
                      vm.pricingLocations = data;
                      refreshSliderChart();
                    });
                } else {
                    refreshSliderChart();
                }
            };

            function refreshSliderChart() {
                // PriceControl Mode (1: Category, 2: Amount)

                if (vm.rule.priceControl.mode === 1) {
                    $timeout(refreshPriceCategory);
                } else if (vm.rule.priceControl.mode === 2) {
                    $timeout(refreshPriceAmount);
                }
                $timeout(vm.updatePanelLocationCount);
            }

            function getPriceControlCategoryEnum(value) {
                switch (value) {
                    case "Essential":
                        return '1';

                    case "Preferred":
                        return '2';

                    case "Preferred Plus":
                        return '3';

                    case "Premium":
                        return '4';

                    default:
                        return value;
                }
            }

            function getPriceControlCategoryValue(value) {
                switch (value) {
                    case '1':
                        return "Essential";

                    case '2':
                        return "Preferred";

                    case '3':
                        return "Preferred Plus";

                    case '4':
                        return "Premium";

                    default:
                        return value;
                }
            }

            function updateScopeName() {
                if (vm.rule.scope === 3) {
                    vm.rule.scopeValuesName = _.map(vm.rule.scopeValues, decodeCityState).join(", ");
                } else if (vm.rule.scope === 4) {
                    vm.rule.scopeValuesName = _.map(_.filter(vm.allPanelLocationsbyTypes, function (pl) {
                        return _.some(vm.rule.scopeValues, function (value) { return value === pl.value; });
                    }), function (pl) {
                        if (pl.isDeleted)
                            return pl.name + " (" + App.localize('Deleted') + ")";
                        else if (!pl.isActive)
                            return pl.name + " (" + App.localize('IsNotActive') + ")";
                        else
                            return pl.name;
                    }).join(', ');
                } else if (vm.rule.scope === 1) {
                    vm.rule.scopeValuesName = "";
                } else {
                    vm.rule.scopeValuesName = vm.rule.scopeValues.join(', ');
                }
            }

            function encodeCityState(value) {
                var cityState = value.replace(' (', ':').replace(')', '').split(':');
                return cityState[1] + ':' + cityState[0];
            }

            function decodeCityState(value) {
                var cityState = value.split(":");
                return cityState[1] + " (" + cityState[0] + ")";
            }

            vm.updatePanelLocationCount = function () {
                var activeLocations = _.filter(vm.allPanelLocationsbyTypes, 'isActive');

                if (vm.rule.scope === 1
                    && vm.rule.controlMode !== 3) {
                    // All and not control by price

                    vm.rule.panelLocationCount = activeLocations.length;
                } else if ((vm.rule.scope === 2 || vm.rule.scope === 3)
                    && vm.rule.controlMode !== 3
                    && vm.rule.scopeValues.length === 0) {
                    // State or city, not control by price, scope value not defined

                    vm.rule.panelLocationCount = activeLocations.length;
                } else if ((vm.rule.scope === 2 || vm.rule.scope === 3)
                    && vm.rule.controlMode !== 3
                    && vm.rule.scopeValues.length !== 0) {
                    // State or city, not control by price, scope value defined

                    vm.rule.panelLocationCount = vm.pricingLocations.length;
                } else if (vm.rule.controlMode === 3) {
                    // Control by price

                    if (vm.rule.priceControl.mode === 1) {
                        // By category

                        vm.rule.panelLocationCount = _.filter(vm.pricingLocations, function (pl) {
                            return pl.priceRating <= getPriceControlCategoryEnum(vm.rule.priceControl.value);
                        }).length;

                        populateCategoryChartData();
                    } else {
                        // By amount

                        vm.rule.panelLocationCount = _.filter(vm.pricingLocations, function (pl) {
                            return pl.amount <= vm.rule.priceControl.value;
                        }).length;

                        populatePriceChartData();
                    }
                } else if (vm.rule.scope === 4) {
                    // Specific

                    vm.rule.panelLocationCount = _.filter(activeLocations, function (location) {
                        return _.some(vm.rule.scopeValues, function (value) { return value === location.value; });
                    }).length;
                }
            };

            // End format rule

            // PriceCategory

            vm.getPriceRatingMessage = function (priceRating) {
                var locations = _.filter(vm.pricingLocations, function (x) { return x.priceRating === priceRating; });

                if (locations.length > 0) {
                    return App.localize('PriceRatingMessage',
                        vm.currencyCode + Math.floor(_.max(_.map(locations, 'amount')) / 5) * 5);
                } else {
                    return "";
                }
            };

            function refreshPriceCategory() {
                vm.rule.priceControl.value = vm.rule.priceControl.value ? getPriceControlCategoryValue(vm.rule.priceControl.value) : getPriceControlCategoryValue('2');

                // Slider

                angular.element(document).ready(function () {
                    $("#irs-category").ionRangeSlider({
                        from: getPriceControlCategoryEnum(vm.rule.priceControl.value) - 1,
                        grid: true,
                        skin: "flat",
                        //step: 1,
                        //grid_snap: true,
                        //from_fixed: true,
                        values: ["Essential", "Preferred", "Preferred Plus", "Premium"]
                    });
                });

                // End slider

                // Chart

                populateCategoryChartData();

                // End chart
            }

            function populateCategoryChartData() {
                var priceCategoryAllowChartData = ["Allowed panel"];
                var priceCategoryBlockChartData = ["Blocked panel"];

                var essentialCount = _.filter(vm.pricingLocations, function (x) { return x.priceRating === 1; }).length;
                var preferredCount = _.filter(vm.pricingLocations, function (x) { return x.priceRating === 2; }).length;
                var preferredPlusCount = _.filter(vm.pricingLocations, function (x) { return x.priceRating === 3; }).length;
                var premiumCount = _.filter(vm.pricingLocations, function (x) { return x.priceRating === 4; }).length;

                var value = getPriceControlCategoryEnum(vm.rule.priceControl.value);

                for (var i = 1; i < 5; i++) {
                    if (i.toString() <= value) {
                        priceCategoryBlockChartData.push(0);

                        switch (i) {
                            case 1:
                                priceCategoryAllowChartData.push(essentialCount);
                                break;
                            case 2:
                                priceCategoryAllowChartData.push(preferredCount);

                                break;
                            case 3:
                                priceCategoryAllowChartData.push(preferredPlusCount);

                                break;
                            case 4:
                                priceCategoryAllowChartData.push(premiumCount);

                                break;
                        }
                    } else {
                        priceCategoryAllowChartData.push(0);

                        switch (i) {
                            case 2:
                                priceCategoryBlockChartData.push(preferredCount);

                                break;
                            case 3:
                                priceCategoryBlockChartData.push(preferredPlusCount);

                                break;
                            case 4:
                                priceCategoryBlockChartData.push(premiumCount);

                                break;
                        }
                    }
                }

                c3.generate({
                    bindto: $('.chart-price-category')[0],
                    size: {
                        height: 320
                    },
                    data: {
                        columns: [
                            priceCategoryAllowChartData,
                            priceCategoryBlockChartData
                        ],
                        type: 'bar',
                        colors: { 'Allowed panel': '#18a689', 'Blocked panel': '#ed5565' },
                        groups: [['Allowed panel', 'Blocked panel']]
                    },
                    legend: {
                        show: false
                    },
                    axis: {
                        y: {
                            tick: {
                                format: d3.format("d")
                            },
                            min: 0,
                            padding: {
                                bottom: 0
                            }
                        },
                        x: {
                            type: 'category',
                            categories: [
                                ["Essential", vm.getPriceRatingMessage(1)],
                                ["Preferred", vm.getPriceRatingMessage(2)],
                                ["Preferred Plus", vm.getPriceRatingMessage(3)],
                                ["Premium", vm.getPriceRatingMessage(4)]
                            ]
                        }
                    }
                });
            }

            // End PriceCategory

            // PriceAmount

            function refreshPriceAmount() {
                var prices = _.map(vm.pricingLocations, 'amount');
                var min = _.min(prices);
                var max = _.max(prices);
                firstBinLowerBoundary = Math.floor(min / 10) * 10;
                lastBinUpperBoundary = Math.ceil((max + 1) / 10) * 10 - 1;
                vm.rule.priceControl.value = vm.rule.priceControl.value % 10 === 0
                    ? vm.rule.priceControl.value
                    : firstBinLowerBoundary;

                // Slider

                angular.element(document).ready(function () {
                    $("#irs-amount").ionRangeSlider({
                        min: firstBinLowerBoundary,
                        max: lastBinUpperBoundary + 1,
                        from: vm.rule.priceControl.value,
                        grid: true,
                        grid_num: (lastBinUpperBoundary + 1 - firstBinLowerBoundary) / 10,
                        skin: "flat",
                        step: 10,
                        prettify: formatAmount
                        //grid_snap: true,
                        //from_fixed: true,
                    });
                });

                function formatAmount(value) {
                    return vm.currencyCode + value;
                }

                // End slider

                populatePriceChartData();
            }

            function populatePriceChartData() {
                var priceAmountAllowChartData = ["Allowed panel"];
                var priceAmountBlockChartData = ["Blocked panel"];
                var priceAmountChartDataLabel = [];

                for (var i = firstBinLowerBoundary; i <= lastBinUpperBoundary; i += 10) {
                    var count = _.filter(vm.pricingLocations, function (x) {
                        return x.amount >= i && x.amount < i + 10;
                    }).length;

                    priceAmountChartDataLabel.push([i + ' -', (i + 9)]);

                    if (i + 9 <= vm.rule.priceControl.value) {
                        priceAmountAllowChartData.push(count);
                        priceAmountBlockChartData.push(0);
                    } else {
                        priceAmountAllowChartData.push(0);
                        priceAmountBlockChartData.push(count);
                    }
                }

                // Chart
                c3.generate({
                    bindto: $('.chart-price-amount')[0],
                    size: {
                        height: 320
                    },
                    data: {
                        columns: [
                            priceAmountAllowChartData,
                            priceAmountBlockChartData
                        ],
                        type: 'bar',
                        colors: { 'Allowed panel': '#18a689', 'Blocked panel': '#ed5565' },
                        groups: [['Allowed panel', 'Blocked panel']]
                    },
                    legend: {
                        show: false
                    },
                    axis: {
                        y: {
                            tick: {
                                format: d3.format("d")
                            },
                            min: 0,
                            padding: {
                                bottom: 0
                            }
                        },
                        x: {
                            label: {
                                text: vm.currencyCode,
                                position: 'outer-center'
                            },
                            height: 55,
                            type: 'category',
                            categories: priceAmountChartDataLabel
                        }
                    }
                });

                // End chart
            }
            // End PriceAmount

            init();
        }
    ]);
})();
