(() => {
  angular
    .module('app')
    .controller(
      'host.views.debugTools.regenerateServiceFeatureHeadcountFile.modal',
      RegenerateServiceFeatureHeadcountFileController
    );

  RegenerateServiceFeatureHeadcountFileController.$inject = [
    '$uibModalInstance',
    'abp.services.app.debugTool',
  ];

  function RegenerateServiceFeatureHeadcountFileController($uibModalInstance, debugToolSvc) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;
    vm.canEdit = false;

    vm.cancel = cancel;
    vm.confirm = confirm;
    vm.getExpiredSubscriptionServiceFeatureHeadcountDataExportRecordById =
      getExpiredSubscriptionServiceFeatureHeadcountDataExportRecordById;

    init();

    function init() {}

    function getExpiredSubscriptionServiceFeatureHeadcountDataExportRecordById() {
      if (!vm.dataExportRecordId || vm.saving) return;

      // Reset data export record object to null.
      vm.dataExportRecord = null;

      vm.saving += 1;
      debugToolSvc
        .getExpiredSubscriptionServiceFeatureHeadcountDataExportRecordById({
          id: vm.dataExportRecordId,
        })
        .success((data) => {
          vm.dataExportRecord = data;
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function confirm() {
      if (!vm.dataExportRecord || vm.saving) return;

      vm.saving += 1;
      debugToolSvc
        .regenerateServiceFeatureHeadcountFile({
          dataExportRecordParameters: vm.dataExportRecord.dataExportRecordParameters,
        })
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }
  }
})();
