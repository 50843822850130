(function () {
    var componentId = 'hostDoraemonCommonInpatientTicketsDetailRoomAndBoardComponent';
    angular
        .module('app')
        .component(componentId, {
            templateUrl: require('/App/host/views/doraemon/common/inpatientTickets/detail/roomAndBoard/roomAndBoard.component.html'),
            controller: ['$scope', 'abp.services.app.commonLookup', 'moment', controller],
            controllerAs: 'vm',
            bindings: {
                request: '<',
                isEdit: '<',
                isSaved: '<',
                categories: '<'
            }
        });

    function controller($scope, commonLookupSvc, moment) {
        var vm = this;
        var previousHospitalizationDays = null;
        var isHospitalizationDaysManualUpdate = false;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.$onChanges = onChanges;
        vm.addCategory = addCategory;
        vm.addRooms = addRooms;
        vm.clearRooms = clearRooms;
        vm.deleteItem = deleteItem;
        vm.updateRooms = updateRooms;
        vm.dateRangeNgPattern = App.dateRangeNgPattern;
        vm.getHospitalizationDays = getHospitalizationDays;
        vm.manualUpdateHospitalizationDays = manualUpdateHospitalizationDays;

        function setDateRangeOptions() {
            vm.roomChargeDateRangeOptions = App.createCustomDateRangePickerOptions(vm.request.admissionDate, vm.request.dischargeDate);
            vm.roomChargeDateRangeOptions.eventHandlers = {
                'apply.daterangepicker': function (ev, picker) {
                    vm.getHospitalizationDays();
                }
            };
        }

        $scope.$watch('vm.request.dischargeDate', function () {
            setDateRangeOptions();
        });

        function init() {
            vm.request.rooms = _.chain(vm.request.rooms)
                .groupBy("category")
                .map((value, key) => ({ category: key, ticketItems: value }))
                .value();

            if (vm.request.rooms.length === 0) {
                if (vm.isEdit)
                    addCategory();
            } else {
                _.each(vm.request.rooms, function (x) {
                    _.each(x.ticketItems, function (p) {
                        p.totalCharges = p.amount + p.uncoveredAmount;
                    });
                });
            }

            updateDates();
        }

        function onChanges(changes) {
            if (changes.request && changes.request.currentValue) {
                if (vm.request.rooms.length === 0) {
                    if (vm.isEdit)
                        addCategory();
                } else {
                    _.each(vm.request.rooms, function (x) {
                        _.each(x.ticketItems, function (p) {
                            p.totalCharges = p.amount + p.uncoveredAmount;
                        });
                    });
                }
            }
            if (changes.isSaved && changes.isSaved.currentValue) {
                tidyRooms();
            }

            if (changes.isEdit && changes.isEdit.currentValue) {
                if (vm.request && vm.request.rooms.length < 1) {
                    addCategory(1);
                }
            }

            updateDates();
        }

        function addCategory(type) {
            var categoryItem = {
                category: type ? type : null,
                ticketItems: [{
                    category: type ? type : null
                }]
            };
            vm.request.rooms.push(categoryItem);
            updateDates();
        }

        function addRooms(record) {
            if (typeof record.category === 'undefined' || record.category === null)
                return;

            if (!record.ticketItems) {
                record.ticketItems = [];
            }

            record.ticketItems.push({
                category: record.category
            });
            updateDates();
        }

        function clearRooms(category) {
            category.ticketItems = [{}];
            addRooms(category);
        }

        function tidyRooms() {
            vm.request.rooms = _.filter(vm.request.rooms, function (item) {
                return _.filter(item.ticketItems, function (k) {
                    return !_.isNil(k.amount);
                });
            });
        }

        // To initialize the date range model for each ticket items
        function updateDates() {
            if (vm.request && vm.request.rooms) {
                _.each(vm.request.rooms, function (x) {
                    _.each(x.ticketItems, function (p) {
                        if (!p.roomChargeDateRangeModel) {
                            p.roomChargeDateRangeModel = {
                                startDate: p.startDate ? moment(p.startDate) : null,
                                endDate: p.endDate ? moment(p.endDate) : null
                            };
                        }
                    });
                });
            }
        }

        function updateRooms(index, item) {
            if (typeof item.category === 'undefined' || item.category === null) {
                if (vm.request.rooms.length > 1) {
                    vm.request.rooms.splice(index, 1);
                } else {
                    vm.request.rooms = [];
                    addCategory();
                }
            }
            vm.getHospitalizationDays();
        }

        function deleteItem(index, item) {
            if (item.ticketItems.length > 1) {
                item.ticketItems.splice(index, 1);
            }
            else {
                item.ticketItems = [];
                addRooms(item);
            }
            vm.getHospitalizationDays();
        }

        function manualUpdateHospitalizationDays() {
            isHospitalizationDaysManualUpdate = true;
        }

        function getHospitalizationDays() {
            var hospitalizationDates = [];
            var hospitalizationDays = 0;
            var minDate = null;
            var maxDate = null;

            _.each(vm.request.rooms, function (x) {
                // Skip if category is insured daily child benefit.

                if (x.category !== 3) {
                    _.each(x.ticketItems, function (p) {
                        if (p.roomChargeDateRangeModel) {
                            var startDate = moment(p.roomChargeDateRangeModel.startDate);
                            var endDate = moment(p.roomChargeDateRangeModel.endDate);

                            for (var currentDate = moment(startDate); currentDate <= endDate; currentDate = currentDate.add(1, 'day')) {
                                var isNewRecord = true;
                                var currentRecord = moment(currentDate);

                                if (hospitalizationDates.length > 0) {
                                    _.each(hospitalizationDates, function (x) {
                                        if (x.isSame(currentRecord)) {
                                            isNewRecord = false;
                                        }
                                    });
                                }

                                if (isNewRecord) {
                                    hospitalizationDates.push(currentRecord);
                                    if (currentDate < minDate || minDate === null) {
                                        minDate = moment(currentRecord);
                                    }

                                    if (currentDate > maxDate || maxDate === null) {
                                        maxDate = moment(currentRecord);
                                    }
                                }
                            }
                        }
                    });
                }
            });

            hospitalizationDays = hospitalizationDates.length;

            // Exclude weekends; 0 - Sunday, 6 - Saturday.

            _.each(hospitalizationDates, function (x) {
                var dayOfWeek = moment(x).days();

                if (hospitalizationDays > 0 && (dayOfWeek === 0 || dayOfWeek === 6)) {
                    hospitalizationDays--;
                }
            });

            // Exclude public holidays.

            vm.loading++;
            commonLookupSvc.getPublicHolidayDates({
                panelLocationId: vm.request.panelLocationId,
                startDate: minDate,
                endDate: maxDate
            }).success(function (data) {
                var publicHolidays = data.publicHolidays;
                var totalPublicHolidays = 0;

                if (!_.isNil(publicHolidays) && publicHolidays.length > 0) {
                    _.each(hospitalizationDates, function (x) {
                        _.each(publicHolidays, function (y) {
                            if (x.isSame(moment(y))) {
                                totalPublicHolidays++;
                            }
                        });
                    });
                }

                    if (totalPublicHolidays > 0 && hospitalizationDays > 0) {
                        hospitalizationDays = Math.max(hospitalizationDays - totalPublicHolidays, 0);
                    }

                    // Validate and finalize total day of hospitalization.

                    if (previousHospitalizationDays === null || hospitalizationDays !== previousHospitalizationDays) {
                        previousHospitalizationDays = hospitalizationDays;

                        if (!isHospitalizationDaysManualUpdate) {
                            vm.request.hospitalizationDays = hospitalizationDays;
                        }
                    }

                }).finally(function (e) {
                    vm.loading--;
                });
        }

        setDateRangeOptions();
        init();
    }
})();
