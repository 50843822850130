(function () {
    angular.module('app').controller('host.views.doraemon.updateIssueNumber.modal', [
        '$scope', '$uibModalInstance', 'issueNumber',
        function ($scope, $uibModalInstance, issueNumber) {
            var vm = this;

            vm.cancel = cancel;
            vm.confirm = confirm;
            vm.issueNumber = issueNumber;

            function cancel() {
                $uibModalInstance.dismiss();
            }

            function confirm() {
                if (App.isFormValid($scope.updateIssueNumberModalForm)) {
                    $uibModalInstance.close(_.trim(vm.issueNumber));
                };
            }
        }
    ]);
})();
