(() => {
  /**
   * Version checker service is a background service to perform version checking
   * against server on an interval. The service fires an event when new
   * version is detected.
   */
  angular.module('app').factory('versionChecker', VersionCheckerFactory);

  VersionCheckerFactory.$inject = ['$http', '$document', '$window', '$interval', 'moment'];

  function VersionCheckerFactory($http, $document, $window, $interval, moment) {
    const service = {
      start,
      stop,
    };

    const storageVersionName = 'portalClientVersion';
    const storageVersionTimeName = 'portalClientLastVersionCheckTime';

    let routine;
    let isRunning = false;
    const defaults = {
      checkIntervalInSeconds: 30, // Version check frequency.
      fetchIntervalInSeconds: 300, // Version fetch frequency. No faster than set interval.
      versionUrl: '/index.html',
      onNewVersion: () => {}, // Callback when new version detected.
    };
    let cfg;
    const appVersion = {
      // Current version recorded on page. This won't change unless full page reload.
      current: getVersionFromHtml($document[0]),

      // Get latest version.
      get latest() {
        return $window.localStorage.getItem(storageVersionName);
      },

      // Set latest version.
      set latest(value) {
        $window.localStorage.setItem(storageVersionName, value);
      },

      // Get last version fetch time.
      get lastFetchTime() {
        // Assume value may be ISO datetime string or invalid/empty string.
        const time = $window.localStorage.getItem(storageVersionTimeName);
        const parsedTime = moment(time);

        // Return moment object. If invalid, return moment obj of earliest time.
        return parsedTime.isValid() ? parsedTime : moment(0);
      },

      // Set now as new version fetch time.
      setLastFetchTime() {
        $window.localStorage.setItem(storageVersionTimeName, moment().toISOString());
      },
      resetLastFetchTime() {
        $window.localStorage.removeItem(storageVersionTimeName);
      },
    };

    return service;

    /**
     * Start version checking.
     */
    function start(options) {
      if (isRunning) return; // Prevent double initialisation.
      isRunning = true;

      cfg = angular.extend({}, defaults, options);

      const routineInterval = cfg.checkIntervalInSeconds * 1000;
      routine = $interval(versionCheckRoutine, routineInterval);
    }

    /**
     * Stop version checking.
     */
    function stop() {
      if (!isRunning) return; // Prevent double cancel.
      isRunning = false;

      $interval.cancel(routine);
    }

    /**
     * The routine for performing version check against local and server.
     */
    function versionCheckRoutine() {
      // Check version locally.
      if (compareVersion()) {
        hasNewVersion();
        return; // New version found, stopping.
      }

      // Check version against server.
      fetchVersion(() => {
        if (compareVersion()) hasNewVersion();
      });
    }

    /**
     * Determine if newer app version is available.
     * @returns True if latest version is newer than current version. Else, false.
     */
    function compareVersion() {
      // Check if current version against latest version.
      return !!(appVersion.current < appVersion.latest);
    }

    /**
     * Fetch version from server.
     * @param {function} callback A callback after version has been fetched from server.
     */
    function fetchVersion(callback) {
      // Check if version fetch was done recently.

      const now = moment();
      const timeDiff = now.diff(appVersion.lastFetchTime, 'seconds');
      if (timeDiff < cfg.fetchIntervalInSeconds) {
        return; // Last version fetch has not pass interval. Abort.
      }

      // Lock version checking.

      appVersion.setLastFetchTime();

      // Get latest index page from server to extract the version.

      $http({
        method: 'GET',
        url: cfg.versionUrl,
        headers: {
          async: false,
          cache: false,
        },
        responseType: 'text',
        abpHandleError: false,
      }).then(
        (d) => {
          // Extract & store version from latest index page.

          const serverDocument = $document[0].implementation.createHTMLDocument();
          serverDocument.head.innerHTML = d.data;

          const serverVersion = getVersionFromHtml(serverDocument);
          if (serverVersion) {
            appVersion.latest = serverVersion;
          }

          if (callback) {
            callback();
          }
        },
        () => {
          // If server responds an error, reset to allow version refetch.

          appVersion.resetLastFetchTime();
        }
      );
    }

    /**
     * Get the version string from `build-at` meta tag in HTML.
     * @param {*} htmlDoc A HTMLDocument object.
     * @returns Version string. If unable to extract, return empty string.
     */
    function getVersionFromHtml(htmlDoc) {
      const version = htmlDoc.head.querySelector('meta[name="build-at"]').getAttribute('content');

      // If can't extract version, return empty string for consistency.
      if (!version) return '';

      return version;
    }

    /**
     * An event handler when new version has been detected.
     */
    function hasNewVersion() {
      stop(); // No longer need to check version.

      if (cfg.onNewVersion) cfg.onNewVersion();
    }
  }
})();
