(() => {
  angular.module('app').controller('common.views.modals.importResult', ImportResultModalController);

  ImportResultModalController.$inject = ['$uibModalInstance', 'title', 'errors'];

  function ImportResultModalController($uibModalInstance, title, errors) {
    const vm = this;

    vm.title = title;
    vm.errorCountText = App.localize('ImportFileHasXIssues', errors.length);
    vm.errorText = errors.join('\n');
    vm.hasEmployeeBulkUpdateUiEnhancement = abp.setting.getBoolean(
      'Hms.Feature.EmployeeBulkUpdateUiEnhancement'
    );

    vm.cancel = cancel;

    init();

    function init() {}

    function cancel() {
      if (vm.hasEmployeeBulkUpdateUiEnhancement) {
        const modal = angular.element(document.getElementsByClassName('modal-backdrop'));
        modal.removeClass('double-modal-backdrop');
      }

      $uibModalInstance.dismiss();
    }
  }
})();
