(function () {
    var controllerId = 'host.views.inpatientClaims.details';
    angular
        .module('app')
        .controller(controllerId, [
            '$stateParams', 'abp.services.app.inpatientClaim', controller
        ]);

    function controller($stateParams, inpatientClaimSvc) {
        var vm = this;
        vm.loading = 0;
        vm.claimNumber = $stateParams.claimNumber;
        vm.timeZoneId = $stateParams.timeZoneId;
        vm.newRepliedQueriesCount = 0;
        vm.serviceType = $stateParams.serviceType;

        function init() {
            getClaim();
        }

        function getClaim() {
            vm.loading++;
            inpatientClaimSvc.getInpatientClaimDetailAsync({ id: vm.claimNumber })
                .success(function (data) {
                    vm.claim = {
                        claimNo: data.claimNumber,
                        type: data.latestRequestType,
                        status: data.claimStatusId,
                        guaranteeLetterNumber: data.guaranteeLetterNumber,
                        patientRefNo: data.hospitalBillReferenceNumber,
                        finalBillAmount: data.totalBillAmount,
                        finalguaranteedAmount: data.currentGuaranteeAmount,
                        exceededAmount: data.exceededAmount,
                        submissionTime: App.getFullDateTimeString(data.claimDate, vm.timeZoneId),
                        lastUpdated: App.getFullDateTimeString(data.lastModificationTime, vm.timeZoneId),
                        guaranteeLetterUrl: data.guaranteeLetterUrl,
                        documents: data.attachments,
                        guaranteeLetterStatus: data.guaranteeLetterStatus,
                        patientNonGlUrl: data.patientNonGlUrl,
                        hospitalNonGlUrl: data.hospitalNonGlUrl,
                        isEscalated: data.isEscalated,
                        timeZoneId: vm.timeZoneId
                    };

                    vm.patient = {
                        name: data.patientName,
                        nationalId: data.nationalId,
                        gender: data.gender,
                        dob: data.dateOfBirth
                            ? App.getDateString(moment.utc(data.dateOfBirth))
                            : null,
                        joinDate: data.joinDate
                            ? App.getDateString(moment.utc(data.joinDate))
                            : null,
                        scheduledDeactivationDate: data.deactivationDate
                            ? App.getDateString(moment.utc(data.deactivationDate))
                            : null,

                        patientActivationStatus: data.patientActivationStatus,
                        patientDeletionStatus: data.patientDeletionStatus,
                        patientTerminationStatus: data.patientTerminationStatus,
                        specialNotes: data.specialNotes,
                    };

                    vm.currencyCode = data.currencyCode;
                    vm.letterNumber = data.guaranteeLetterNumber;
                })
                .finally(function () {
                    vm.loading--;
                })
        }
        init();
    }
})();
