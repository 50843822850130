(() => {
  angular.module('app').component('commonComponentsInpatientBalancePoolTableComponent', {
    templateUrl: require('./inpatientBalancePoolTable.component.html'),
    controller: InpatientBalancePoolTableController,
    controllerAs: 'vm',
    bindings: {
      benefits: '<',
      hospitalizationBenefits: '<',
      isHospitalization: '<',
    },
  });

  InpatientBalancePoolTableController.$inject = [];

  function InpatientBalancePoolTableController() {
    const vm = this;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
  }
})();
