(function () {
    angular.module('app')
        .component('clinicPatientAdmissionDetailsPatientAdmissionGuaranteeLetterDetailsTableComponent', {
            require: {
                container: '^clinicPatientAdmissionDetailsPatientAdmissionGuaranteeLetterDetailsTableComponent'
            },
            templateUrl: require('/App/clinic/views/patient/admissionDetails/patientAdmissionGuaranteeLetterDetailsTable/patientAdmissionGuaranteeLetterDetailsTable.component.html'),
            controller: ['$timeout', 'abp.services.app.inpatientGuaranteeLetter', 'Hms.ServiceRequests.ServiceRequestType', controller],
            controllerAs: 'vm',
            bindings: {
                guaranteeLetterNumber: '<',
                timeZoneId: '='
            }
        });

    function controller($timeout, inpatientGuaranteeLetterSvc, enumServiceRequestType) {
        var vm = this;
        vm.loading = 0;

        vm.enums = {
          serviceRequestType: enumServiceRequestType
        };

        var viewOptions = {
            maxResultCount: angular.module('app').consts.grid.defaultPageSize,
            skipCount: 0,
            sorting: null
        };

        vm.gridOptions = {
            appScopeProvider: vm,
            paginationPageSizes: angular.module('app').consts.grid.defaultPageSizes,
            paginationPageSize: angular.module('app').consts.grid.defaultPageSize,
            paginationCurrentPage: 0,
            useExternalPagination: true,
            useExternalSorting: true,
            resizable: true,
            columnDefs: [{
                name: 'Actions',
                displayName: 'Actions',
                enableSorting: false,
                headerCellTemplate: '<span></span>',
                cellTemplate: 'viewTemplate',
                width: 100
            }, {
                displayName: App.localize('Name'),
                enableSorting: false,
                field: 'letterName',
                minWidth: 140,
                cellTemplate: 'nameTemplate'
            }, {
                displayName: App.localize('RevisionNumber'),
                enableSorting: false,
                field: 'revisionNumber',
                minWidth: 80
            }, {
                displayName: App.localize('RevisionType'),
                enableSorting: false,
                field: 'revisionType',
                cellTemplate: 'revisionTypeTemplate',
                minWidth: 80
            }, {
                displayName: App.localize('IssuedTime'),
                enableSorting: false,
                field: 'approvalTime',
                cellTemplate: 'approvalTimeTemplate',
                minWidth: 140
            }],
            data: []
        };

        vm.getDateTimeString = getDateTimeString;

        init();

        function init() {
            getInpatientGuaranteeLetterRevision();
            $timeout(function () {
                vm.gridOptions.paginationCurrentPage = Math.floor(viewOptions.skipCount / viewOptions.maxResultCount + 1);
            });
        }

        function getInpatientGuaranteeLetterRevision() {
            vm.loading++;
            inpatientGuaranteeLetterSvc.getInpatientGuaranteeLetterRevision({
                guaranteeLetterNumber: vm.guaranteeLetterNumber,
                skipCount: viewOptions.skipCount,
                maxResultCount: viewOptions.maxResultCount
            }).success(function (data) {
                vm.gridOptions.totalItems = data.totalCount;
                vm.gridOptions.data = data.items;
            }).finally(function (e) {
                vm.loading--;
            });
        }

        function getDateTimeString(date, timeZoneId) {
            return App.getDateTimeString(date, timeZoneId);
        }
    }
})();
