(function () {
    var controllerId = 'host.views.employees.checkAccessModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'abp.services.app.commonLookup', 'abp.services.app.hostEmployee', 'employeeId', 'Hms.MultiTenancy.TenantClinicType', 'Hms.PanelLocationPriceRatings.PriceRating',
        function ($scope, $uibModalInstance, commonLookupSvc, hostEmployeeSvc, employeeId, enumTenantClinicType, enumPriceRating) {
            var vm = this;
            vm.loading = 0;

            vm.checkAccess = checkAccess;
            vm.lookup = lookup;
            vm.toggleLocationLookup = toggleLocationLookup;
            vm.accessRules = [];

            vm.panelTypeChanged = loadAccessRules;
            vm.serviceTypeChanged = loadAccessRulesByServiceType;

            vm.enums = {};
            vm.enums.tenantClinicType = enumTenantClinicType;
            vm.enums.priceRating = enumPriceRating;

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            // Check for health screening module enable for the application.

            vm.applicationHasHealthScreeningModule = abp.setting.getBoolean('Hms.Feature.HealthScreeningModule');

            vm.panelServiceTypes = [{
                "name": App.localize("GP"),
                "value": 1,
                "icon": "fas fa-stethoscope",
            }, {
                "name": App.localize("Dental"),
                "value": 2,
                "icon": "mdi mdi-tooth"
            }, {
                "name": App.localize("Optical"),
                "value": 3,
                "icon": "mdi mdi-sunglasses"
            }, {
                "name": App.localize("Specialist"),
                "value": 5,
                "icon": "fas fa-user-md"
            }, {
                "name": App.localize("Hospitalization"),
                "value": 7,
                "icon": "fas fa-bed"
            }, {
                "name": App.localize("Maternity"),
                "value": 8,
                "icon": "fas fa-female"
            }]

            if (vm.applicationHasHealthScreeningModule) {
                vm.panelServiceTypes.push({
                    "name": App.localize("HealthScreening"),
                    "value": 9,
                    "icon": "far fa-heartbeat"
                });
            }

            vm.chosenServiceType = 0;

            init(employeeId);

            /* Functions */

            function init(id) {
                toggleLocationLookup(false);
                getMemberPanelAccessRules(id);
            }

            function getMemberPanelAccessRules(id) {
                vm.loading++;
                hostEmployeeSvc.getMemberPanelAccessRules({ employeeId: id })
                    .success(function (data) {
                    vm.serviceTypes = _.map(data.items, function (item) {
                        var name = '';
                        switch (item.serviceType) {
                            case 1: name = App.localize("GP"); break;
                            case 2: name = App.localize("Dental"); break;
                            case 3: name = App.localize("Optical"); break;
                            case 5: name = App.localize("Specialist"); break;
                            case 7: name = App.localize("Hospitalization"); break;
                            case 8: name = App.localize("Maternity"); break;
                        }
                        return {
                            name: name,
                            value: item.serviceType,
                            count: item.panelLocationCount
                        };
                    });

                    vm.panelAccessRules = data.items;
                    loadAccessRulesByServiceType();
                }).finally(function () {
                    vm.loading--;
                });
            }

            function checkAccess() {
                if (!vm.panelOrLocationId) return;

                var input = { employeeId: employeeId };
                if (vm.isLocationLookup) input.panelLocationId = vm.panelOrLocationId;
                else input.panelId = vm.panelOrLocationId;

                input.chosenServiceType = vm.chosenServiceType;

                vm.loading++;
                hostEmployeeSvc.checkEmployeeAccess(input).success(function (data) {
                    vm.accessibleLocations = data.items;
                }).finally(function () {
                    vm.loading--;
                });
            }

            function lookup(search) {
                var keyword = _.trim(search || '');
                if (keyword.length < 3) return;

              var serviceMethod = !vm.isLocationLookup
                  ? commonLookupSvc.findPanelsByServiceType
                  : commonLookupSvc.findClinicLocationsByServiceType;

                serviceMethod({
                    filter: keyword,
                    maxResultCount: 5,
                    serviceType: vm.chosenServiceType
                }).success(function (data) {
                    vm.lookupRecords = data.items;
                });
            }

            function toggleLocationLookup(isLocationLookup) {
                vm.panelOrLocationId = null;
                vm.lookupRecords = [];
                vm.accessibleLocations = [];

                var keywords = (vm.isLocationLookup = isLocationLookup)
                    ? 'name / code / contact number / address'
                    : 'name / code';
                vm.placeholderDisplay = 'Insert panel ' + keywords + ' to search';
            }

            function loadAccessRules(panelType) {
                if (!panelType) {
                    var panelAccessRules = _.first(vm.panelAccessRules);
                    panelType = panelAccessRules.panelType;
                    vm.accessRules = panelAccessRules.rules;
                }
                else
                    vm.accessRules = _.find(vm.panelAccessRules, { 'panelType': panelType }).rules;

                vm.selectedPanelType = panelType;
            }

            function loadAccessRulesByServiceType(serviceType) {
                if (!serviceType) {
                    var panelAccessRules = _.first(vm.panelAccessRules);
                    serviceType = panelAccessRules.serviceType;
                    vm.accessRules = panelAccessRules.rules;
                }
                else
                    vm.accessRules = _.find(vm.panelAccessRules, { 'serviceType': serviceType }).rules;

                vm.selectedServiceType = serviceType;
            }

            /* End of Functions */
        }
    ]);
})();
