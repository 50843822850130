(() => {
  angular
    .module('app')
    .controller(
      'common.views.modals.assigneeConfirmationModal',
      AssigneeConfirmationModalController
    );

  AssigneeConfirmationModalController.$inject = [
    '$uibModalInstance',
    'title',
    'users',
    'assigneeName',
  ];

  function AssigneeConfirmationModalController($uibModalInstance, title, users, assigneeName) {
    const vm = this;

    vm.remarks = null;
    vm.title = title;
    vm.users = users;
    vm.assigneeName = assigneeName;

    vm.recipients = [
      { name: 'User1', value: 'user1@example.com' },
      { name: 'User2', value: 'user2@example.com' },
    ];

    vm.cancel = cancel;
    vm.submit = submit;

    init();

    function init() {}

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function submit() {
      if (App.isFormValid(vm.modalForm)) {
        $uibModalInstance.close(vm.recipients, vm.remarks);
      }
    }
  }
})();
