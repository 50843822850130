(() => {
  angular
    .module('app')
    .controller('common.views.specialistClaims.processClaim.rejectModal', RejectModalController);

  RejectModalController.$inject = [
    '$uibModalInstance',
    'claimNumber',
    'isTopUpRejection',
  ];

  function RejectModalController($uibModalInstance, claimNumber, isTopUpRejection) {
    const vm = this;

    vm.claimNumber = claimNumber;
    vm.isTopUpRejection = isTopUpRejection;

    vm.disableReject = disableReject;
    vm.reject = reject;
    vm.cancel = cancel;

    init();

    function init() {
      if (vm.isTopUpRejection) {
        vm.checklistItems = [
          { text: 'Patient not enough allowance' },
          { text: 'Company limit exhausted' },
          { text: 'Others', requiredInput: true },
        ];
      } else {
        const subItems = ['Detailed Tax Invoice', 'Patient Consent & Claim Form', 'Diagnosis Form'];
        vm.checklistItems = [
          {
            text: 'Uploaded documents do not match GL holder (Please upload the correct one)',
            subItems,
          },
          { text: 'Invalid documents (Please upload the correct one)', subItems },
          { text: 'Uploaded documents are not clear (Please re-upload)', subItems },
          { text: 'Uploaded files broken (Please re-upload)', subItems },
          { text: 'Others', requiredInput: true },
        ];
      }
    }

    function disableReject() {
      return (
        _.every(vm.checklistItems, (d) => !d.isChecked) ||
        _.some(vm.checklistItems, (d) => d.isChecked && d.requiredInput && !d.input)
      );
    }

    function getRemarks() {
      let output = '';
      _.each(vm.checklistItems, (item) => {
        if (vm.isTopUpRejection && item.isChecked) {
          output += `${
            item.requiredInput
              ? item.input.charAt(0).toUpperCase() + item.input.slice(1)
              : item.text
          }. `;
        } else if (!vm.isTopUpRejection) {
          if (item.isChecked && item.requiredInput) {
            output += `${item.text}: ${item.input}.\n`;
          }
          if (item.anySubItemChecked()) {
            const subItems = _.map(
              _.filter(item.subItems, (subItem) => subItem.isChecked),
              'text'
            );
            output += `${item.text}: ${_.join(subItems, ', ')}.\n`;
          }
        }
      });
      return output;
    }

    function reject() {
      $uibModalInstance.close(getRemarks());
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
