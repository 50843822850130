(function () {
    angular.module('app').component('pharmacyPrescriptionsMedicineHistoryComponent', {
        templateUrl: require('/App/pharmacy/views/prescriptions/medicineHistory/medicineHistory.component.html'),
        controller: ['$scope', '$stateParams', 'abp.services.app.prescription', controller],
        controllerAs: 'vm',
        bindings: {
            onRefreshEvent: '<',
            onClickFulfillmentNumber: '&'
        }
    });

    function controller($scope, $stateParams, prescriptionSvc) {
        var vm = this;
        vm.medicineHistories = [];
        vm.loading = 0;
        vm.prescriptionNumber = $stateParams.prescriptionNumber;
        vm.requestParams = {
            maxResultCount: 5,
            skipCount: 0,
            prescriptionNumber: $stateParams.prescriptionNumber
        };

        vm.refresh = function () {
            vm.requestParams.skipCount = 0;
            vm.getMedicineHistories();
        };

        vm.$onChanges = function (changes) {
            if (changes.onRefreshEvent && changes.onRefreshEvent.currentValue) {
                vm.onRefreshEvent.handler = vm.getMedicineHistories;
            }
        };

        vm.$onInit = function init() {
            vm.getMedicineHistories();
        };

        vm.getMedicineHistories = function () {
            vm.loading++;
            var skipCount = vm.requestParams.skipCount;
            if (skipCount === 0) {
                vm.records = [];
            }
            prescriptionSvc.getPrescriptionMedicineHistories(vm.requestParams).success(function (data) {
                vm.medicineHistories =
                    _.chain(data.items)
                        .groupBy("name")
                        .map((value, key) => ({ name: key, items: value }))
                        .value();
                vm.totalCount = data.totalCount;
                vm.records = data.items;
                vm.hasMore = vm.records.length < vm.totalCount;
            }).finally(function () {
                vm.loading--;
            });
        };

        vm.loadMore = function () {
            if (!vm.loading && vm.hasMore) {
                vm.requestParams.maxResultCount += 5;
                vm.getMedicineHistories();
            }
        };
    }
})();
