(() => {
  angular.module('app').component('clinicPanelDashboardLatestTransactionsComponent', {
    templateUrl: require('./latestTransactions.component.html'),
    controller: LatestTransactionsController,
    controllerAs: 'vm',
    bindings: {
      panelLocationId: '<',
      panelLocationTimeZone: '<',
      currencyCode: '<',
      widgetLength: '<',
      isGpPanel: '<',
    },
  });

  LatestTransactionsController.$inject = [
    'abp.services.app.panelDashboard',
    'abp.services.app.financeStatistic',
    'Hms.Finance.KnownBillCategoryNames'
  ];

  function LatestTransactionsController(
    panelDashboardSvc,
    statisticSvc,
    enumKnownBillCategoryNames) {
    const vm = this;

    vm.loading = 0;
    vm.transactions = [];
    vm.hasMoreTransactions = true;
    vm.allClearedNarrative = App.localize('TransactionsAllClearedNarrative');
    vm.hasGpDashboardEnhancement = abp.setting.getBoolean('Hms.Feature.GPDashboardEnhancement');
    vm.hasPanelDashboardEnhancement = abp.setting.getBoolean(
      'Hms.Feature.PanelDashboardEnhancement'
    );
    vm.hasAccessFinanceTransactionData = abp.setting.getBoolean('Hms.Feature.AccessFinanceTransactionData');
    vm.permissions = {
      saleTransactions: abp.auth.isGranted('SaleTransactions'),
    };

    vm.requestParams = {
      maxResultCount: 5,
      skipCount: 0,
    };

    vm.getTransactions = getTransactions;
    vm.getLatestTransactions = getLatestTransactions;
    vm.loadMore = loadMore;
    vm.getDateTimeString = getDateTimeString;
    vm.$onInit = init;

    function init() {
      if (vm.hasAccessFinanceTransactionData){
        getLatestTransactions();
      } else {
        getTransactions();
      }
    }

    function getDateTimeString(date) {
      return App.getDateTimeString(date, vm.panelLocationTimeZone);
    }

    function getTransactions() {
      vm.loading += 1;
      panelDashboardSvc
        .getLatestTransactions(vm.requestParams)
        .success((data) => {
          vm.totalCount = data.totalCount;
          vm.transactions = _.uniqBy(vm.transactions.concat(data.items), 'transactionNumber');
          vm.hasMoreTransactions = vm.transactions.length < vm.totalCount;

          setTransactionStatus();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function loadMore() {
      if (!vm.loading && vm.hasMoreTransactions) {
        vm.requestParams.skipCount += 5;
        getTransactions();
      }
    }

    function getLatestTransactions() {
      vm.loading =+ 1;
      statisticSvc.getLatestTransactions()
      .success((data) => {
        let allTransactions = data.transactions.concat(data.payments);

        allTransactions = _.orderBy(allTransactions, 'creationTime', 'desc');

        vm.transactions = allTransactions.slice(0, 10);

        setTransactionStatus();

        vm.hasMoreTransactions = false;
      })
      .finally(() => {
        vm.loading -= 1;
      });
    }

    function setTransactionStatus() {

      if (vm.hasAccessFinanceTransactionData) {
        _.forEach(vm.transactions, (x) => {
          switch (true) {
            case (x.type === "Invoice"
              && x.category === enumKnownBillCategoryNames["Panel.OutpatientClaims"].id):
              x.transactionStatus = App.localize('ClaimInvoiceSent');
              break;
            case (x.type === "Invoice"
              && x.category === enumKnownBillCategoryNames["Panel.OutpatientLicense"].id):
              x.transactionStatus = App.localize('BillReceived');
              break;
            case (x.type === "CreditMemo"
              && x.category === enumKnownBillCategoryNames["Panel.OutpatientClaims"].id):
              x.transactionStatus = App.localize('CreditNoteSent');
              break;
            case (x.type === "CreditMemo"
              && x.category === enumKnownBillCategoryNames["Panel.OutpatientLicense"].id):
              x.transactionStatus = App.localize('ServiceChargeRefunded');
              break;
            case (!x.type && x.isPayable):
              x.transactionStatus = App.localize('PaymentReceived');
              break;
            case (!x.type && !x.isPayable):
              x.transactionStatus = App.localize('ServiceChargeRefunded');
              break;
  
            default:
          }
        })
      } else {
        _.forEach(vm.transactions, (x) => {
          switch (true) {
            case (x.type === 1 && x.category === 1):
              x.transactionStatus = App.localize('ClaimInvoiceSent');
              break;
            case (x.type === 3 && x.category === 1):
              x.transactionStatus = App.localize('PaymentReceived');
              break;
            case (x.type === 2 && x.category === 1):
              x.transactionStatus = App.localize('CreditNoteSent');
              break;
            case (x.type === 1 && x.category === 3):
              x.transactionStatus = App.localize('BillReceived');
              break;
            case (x.type === 3 && x.category === 3):
              x.transactionStatus = App.localize('ServiceChargePaid');
              break;
            case (x.type === 2 && x.category === 3):
              x.transactionStatus = App.localize('ServiceChargeRefunded');
              break;
  
            default:
          }
        })
      }      
    }
  }
})();
