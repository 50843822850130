(() => {
  angular
    .module('app')
    .controller('sms.views.provider.createOrEditModal', SmsProviderCreateOrEditModalController);

  SmsProviderCreateOrEditModalController.$inject = [
    '$uibModalInstance',
    'countryCode',
    'smsService',
  ];

  function SmsProviderCreateOrEditModalController($uibModalInstance, countryCode, smsSvc) {
    const vm = this;
    vm.provider = {
      countryCode: null,
      providerName: null,
      messagePrefix: null,
    };

    vm.loading = 0;
    vm.isEdit = !_.isNil(countryCode);
    vm.clearInputs = clearInputs;
    vm.cancel = cancel;
    vm.save = save;

    init();

    function init() {
      getCountries();
      if (!_.isNil(countryCode)) {
        getSmsProvider();
      }
    }

    function getCountries() {
      vm.loading += 1;
      smsSvc
        .getCountries()
        .success((data) => {
          vm.countries = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getSmsProvider() {
      vm.loading += 1;
      smsSvc
        .getSmsProvider({ countryCode })
        .success((data) => {
          vm.provider = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function createSmsProvider(payload) {
      vm.loading += 1;
      smsSvc
        .createSmsProvider(payload)
        .success(() => {
          abp.notify.success(App.localize('SuccessfullyUpdated'));
          $uibModalInstance.close();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function updateSmsProvider(payload) {
      vm.loading += 1;
      smsSvc
        .updateSmsProvider(payload)
        .success(() => {
          abp.notify.success(App.localize('SuccessfullyUpdated'));
          $uibModalInstance.close();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function clearInputs() {
      vm.provider.messagePrefix = null;
      vm.provider.senderId = null;
      vm.provider.username = null;
      vm.provider.secret = null;
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function save() {
      // Validate form

      if (!App.isFormValid(vm.smsProviderCreateOrEditForm)) {
        return false;
      }

      const payload = {
        countryCode: vm.provider.countryCode,
        providerName: vm.provider.providerName,
        messagePrefix: vm.provider.messagePrefix,
        senderId: vm.provider.senderId,
        username: vm.provider.username,
        secret: vm.provider.secret,
      };

      if (vm.isEdit) {
        updateSmsProvider(payload);
      } else {
        createSmsProvider(payload);
      }

      return true;
    }
  }
})();
