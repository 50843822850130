(function () {
    var componentId = 'hostDoraemonCommonInpatientTicketsDetailProceduresComponent';
    angular
        .module('app')
        .component(componentId, {
            templateUrl: require('/App/host/views/doraemon/common/inpatientTickets/detail/procedures/procedures.component.html'),
            controller: ['$scope', 'moment', controller],
            controllerAs: 'vm',
            bindings: {
                request: '<',
                doctors: '<',
                isMaternity: '<',
                isEdit: '<',
                isSaved: '<',
                categories: '<'
            }
        });

    function controller($scope, moment) {
        var vm = this;

        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.$onChanges = onChanges;
        vm.addCategory = addCategory;
        vm.addProcedures = addProcedures;
        vm.clearProcedures = clearProcedures;
        vm.deleteItem = deleteItem;
        vm.updateProcedures = updateProcedures;
        vm.dateRangeNgPattern = App.dateRangeNgPattern;
        vm.getDoctorName = getDoctorName;

        function init() {
            vm.request.procedures = _.chain(vm.request.procedures)
                .groupBy("category")
                .map((value, key) => ({ category: key, ticketItems: value }))
                .value();

            if (vm.request.procedures.length === 0) {
                if (vm.isEdit)
                    addCategory();
            } else {
                _.each(vm.request.procedures, function (x) {
                    _.each(x.ticketItems, function (p) {
                        p.totalCharges = p.amount + p.uncoveredAmount;
                    });
                });
            }

            initDates;
        }

        function onChanges(changes) {
            if (changes.request && changes.request.currentValue) {
                if (vm.request.procedures.length === 0) {
                    if (vm.isEdit)
                        addCategory();
                } else {
                    _.each(vm.request.procedures, function (x) {
                        _.each(x.ticketItems, function (p) {
                            p.totalCharges = p.amount + p.uncoveredAmount;
                        });
                    });
                }
            }

            if (changes.isSaved && changes.isSaved.currentValue) {
                tidyProcedures();
            }

            if (changes.isEdit && changes.isEdit.currentValue) {
                if (vm.request && vm.request.procedures.length < 1) {
                    var defaultType = vm.isMaternity ? "5" : "4";
                    addCategory(defaultType);
                }
            }

            initDates();
        }

        function addCategory(type) {
            var categoryItem = {
                category: type ? type : null,
                ticketItems: [{
                    category: type ? type : null
                }]
            };
            vm.request.procedures.push(categoryItem);
            initDates();
        }

        function addProcedures(record) {
            if (typeof record.category === 'undefined' || record.category === null)
                return;

            if (!record.ticketItems) {
                record.ticketItems = [];
            }

            record.ticketItems.push({
                category: record.category
            });

            initDates();
        }

        function clearProcedures(category) {
            category.ticketItems = [];
            addProcedures(category);
        }

        function tidyProcedures() {
            vm.request.procedures = _.filter(vm.request.procedures, function (item) {
                return _.filter(item.ticketItems, function (k) {
                    return !_.isNil(k.amount);
                });
            });
        }

        function initDates() {
            if (vm.request && vm.request.procedures) {
                _.each(vm.request.procedures, function (x) {
                    _.each(x.ticketItems, function (p) {
                        if (!p.procedureChargeDateRangeModel) {
                            p.procedureChargeDateRangeModel = {
                                startDate: p.startDate ? moment(p.startDate) : null,
                                endDate: p.endDate ? moment(p.endDate) : null
                            };
                        }
                    });
                });
            }
        }

        function updateProcedures(index, item) {
            if (typeof item.category === 'undefined' || item.category === null) {
                if (vm.request.procedures.length > 1) {
                    vm.request.procedures.splice(index, 1);
                } else {
                    vm.request.procedures = [];
                    addCategory();
                }
            }
        }

        function deleteItem(index, item) {
            if (item.ticketItems.length > 1) {
                item.ticketItems.splice(index, 1);
            }
            else {
                item.ticketItems = [];
                addProcedures(item);
            }
        }

        function getDoctorName(id) {
            if (vm.doctors) {
                var selectedDoctor = _.find(vm.doctors, function (d) {
                    if (d.id === parseInt(id))
                        return d.name;
                });

                if (selectedDoctor) {
                    return selectedDoctor.name;
                }
            }
        }

        let setDateRangeOptions = function () {
            vm.procedureChargeDateRangeOptions = App.createCustomDateRangePickerOptions(vm.request.admissionDate, vm.request.dischargeDate);
        }

        $scope.$watch('vm.request.dischargeDate', function () {
            setDateRangeOptions();
        });

        init();
        setDateRangeOptions();
    }
})();
