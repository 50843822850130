import swal from 'sweetalert';

(() => {
  angular.module('app').component('commonPatientTicketsSpecialistDetailComponent', {
    templateUrl: require('./specialistDetail.component.html'),
    controller: SpecialistDetailController,
    controllerAs: 'vm',
    bindings: {
      isReadOnly: '<',
      save: '<',
      restoreOriginal: '<',
      ticketNumber: '<',
      hasAlerts: '<',
      onSuccessSave: '&',
      onFinishWithoutSaving: '&',
    },
  });

  SpecialistDetailController.$inject = ['abp.services.app.patientTicket'];

  function SpecialistDetailController(patientTicketSvc) {
    const vm = this;

    vm.details = [];
    vm.treatmentCategories = [];
    vm.loading = 0;

    vm.$onInit = onInit;
    vm.$onChanges = onChanges;
    vm.tidyDetails = tidyDetails;

    init();

    function init() {}

    function onInit() {
      getDetails();
    }

    function onChanges(changes) {
      if (changes.isReadOnly && !changes.isReadOnly.currentValue) {
        tidyDetails();
      }

      if (changes.save && !changes.save.isFirstChange()) {
        saveDetails();
      }

      if (changes.restoreOriginal && !changes.restoreOriginal.isFirstChange()) {
        finishWithoutSaving();
      }
    }

    function tidyDetails() {
      let blankCnt = 0;
      _.each(vm.details, (detail) => {
        if (!detail.treatmentCategory) blankCnt += 1;
      });

      // Remove superfluos empty details.

      while (blankCnt > 1) {
        for (let i = vm.details.length - 1; i >= 0; i -= 1) {
          if (!vm.details[i].treatmentCategory) {
            vm.details.splice(i, 1);
            blankCnt -= 1;
          }
        }
      }

      // Ensure there is always a blank detail line.

      if (blankCnt < 1) vm.details.push({});
    }

    function finishWithoutSaving() {
      if (vm.hasAlerts) {
        swal(
          {
            title: App.localize('PatientTicketSpecialistDetail_FinishWithoutSavingNarrative'),
            showCancelButton: true,
            closeOnConfirm: false,
            confirmButtonColor: '#1ab394',
          },
          () => {
            getDetails();
            vm.onFinishWithoutSaving();
            swal.close();
          }
        );
      } else {
        getDetails();
        vm.onFinishWithoutSaving();
      }
    }

    function saveDetails() {
      if (vm.hasAlerts) {
        swal(
          {
            title: App.localize('PatientTicketSpecialistDetail_SaveDetailNarrative'),
            showCancelButton: true,
            closeOnConfirm: false,
            confirmButtonColor: '#1ab394',
          },
          () => {
            createOrUpdateDetails();
            swal.close();
          }
        );
      } else {
        createOrUpdateDetails();
      }
    }

    function createOrUpdateDetails() {
      // Remove last blank detail line.

      if (!vm.isReadOnly) {
        vm.details.splice(vm.details.length - 1, 1);
      }

      vm.loading += 1;
      patientTicketSvc
        .createOrUpdateSpecialistDetail({
          ticketNumber: vm.ticketNumber,
          detailItems: vm.details,
        })
        .success(() => {
          getDetails();
          vm.onSuccessSave();
          if (vm.hasAlerts) {
            abp.notify.info(App.localize('PatientTicketSpecialistDetail_DetailSavedNarrative'));
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getDetails() {
      vm.loading += 1;
      patientTicketSvc
        .getSpecialistDetail({
          id: vm.ticketNumber,
        })
        .success((data) => {
          vm.details = data.detailItems;
          vm.treatmentCategories = data.treatmentCategories;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
