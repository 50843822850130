(function () {
    var controllerId = 'host.views.masterOperators';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', 'abp.services.app.masterOperator',
        function ($scope, $state, $stateParams, masterOperatorSvc) {
            function init() {
                vm.getMasterOperators();
            }

            var vm = this;
            vm.loading = 0;
            vm.showDeleted = false;

            // Assign permissions

            vm.permissions = {
                create: abp.auth.isGranted('Host.MasterOperators.Create'),
                edit: abp.auth.isGranted('Host.MasterOperators.Edit'),
                'delete': abp.auth.isGranted('Host.MasterOperators.Delete')
            };

            // TODO: Remove inpatient module flag

            vm.hasInpatientModule = abp.setting.getBoolean('Hms.Feature.InpatientModule');

            vm.gridOptions = {
                appScopeProvider: vm,
                columnDefs: [{
                    enableSorting: false,
                    name: App.localize('Actions'),
                    width: 100,
                    headerCellTemplate: '<span></span>',
                    cellTemplate: 'actionTemplate'
                },
                {
                    displayName: App.localize('Name'),
                    field: 'userName',
                    minWidth: 100
                },
                {
                    displayName: App.localize('UserName'),
                    field: 'userUserName',
                    minWidth: 100
                },
                {
                    displayName: App.localize('Status'),
                    field: 'isActive',
                    width: 100,
                    cellTemplate: 'statusTemplate'
                },
                {
                    displayName: App.localize('CanBeAutoAssigned'),
                    field: 'canBeAutoAssigned',
                    minWidth: 100,
                    cellTemplate: 'assignTemplate'
                },
                {
                    displayName: App.localize('PreferredTrainer'),
                    field: 'isPreferredTrainer',
                    minWidth: 100,
                    cellTemplate: 'trainerTemplate'
                },
                {
                    displayName: App.localize('ServiceRequestProcessor'),
                    field: 'isServiceRequestProcessor',
                    minWidth: 150,
                    cellTemplate: 'serviceRequestProcessorTemplate',
                    visible: vm.hasInpatientModule // TODO: Remove inpatient module flag
                }, {
                    displayName: App.localize('ServiceRequestSecondaryApprover'),
                    field: 'isServiceRequestSecondaryApprover',
                    minWidth: 150,
                    cellTemplate: 'serviceRequestSecondaryApproverTemplate',
                    visible: vm.hasInpatientModule // TODO: Remove inpatient module flag
                },
                {
                    displayName: App.localize('EmailAddress'),
                    field: 'userEmailAddress',
                    minWidth: 200
                },
                {
                    displayName: App.localize('CreationTime'),
                    field: 'creationTime',
                    cellFilter: 'momentFormat: \'L LT\'',
                    minWidth: 80
                },
                {
                    displayName: App.localize('LastUpdated'),
                    field: 'lastModificationTime',
                    cellFilter: 'momentFormat: \'L LT\'',
                    minWidth: 80
                }
                ],
                data: []
            };
            vm.permissions.edit || vm.permissions.delete || vm.gridOptions.columnDefs.shift();

            vm.getMasterOperators = function () {
                vm.loading++;
                masterOperatorSvc.getMasterOperators({
                    showDeleted: vm.showDeleted
                }).success(function (data) {
                    vm.gridOptions.data = data.items;
                    vm.gridOptions.totalCount = data.items.length;
                }).finally(function (e) {
                    vm.loading--;
                });
            };

            vm.deleteMasterOperator = function (input) {
                if (!input.isDeleted) {
                    if (input.hasInRecruitmentRequest) {
                        abp.message.confirm(App.localize('DeleteInRecruitmentMasterOpsMessage', input.UserName), App.localize('AreYouSure'), function (d) {
                            if (d) {
                                deleteOps(input.id);
                            }
                        });
                    } else {
                        deleteOps(input.id);
                    }
                }
            };

            function deleteOps(id) {
                vm.loading++;
                masterOperatorSvc.deleteMasterOperator({
                    id: id
                }).success(function () {
                    abp.notify.info(App.localize('SuccessfullyDeleted'));
                    vm.getMasterOperators();
                }).finally(function () {
                    vm.loading--;
                });
            }

            init();
        }
    ]);
})();
