(() => {
  angular.module('app').controller('common.views.common.selectionModal', ControllerController);

  ControllerController.$inject = ['$uibModalInstance', 'templateName', 'fields', 'modalTitle'];

  function ControllerController($uibModalInstance, templateName, fields, modalTitle) {
    const vm = this;

    // Default title for selection report.
    vm.title = modalTitle;

    vm.cancel = cancel;
    vm.generate = generate;
    vm.selectOrDeselectAll = selectOrDeselectAll;
    vm.selectedCount = selectedCount;

    init();

    function init() {
      // Preprocess fields.
      const items = App.getStorageJsonObj(templateName);
      vm.fields = _.sortBy(fields, (field) => {
        const f = field;
        f.isSelected = f.isMandatory || _.some(items, (item) => item === f.id) || f.isDefault;
        return !f.isMandatory;
      });

      // Gather all optional fields.
      vm.optionalFields = _.filter(vm.fields, (d) => !d.isMandatory);
    }

    function selectedCount() {
      const selected = _.filter(vm.optionalFields, 'isSelected');
      return selected.length;
    }

    function selectOrDeselectAll(selectAll) {
      _.forEach(vm.optionalFields, (f) => {
        const optionalField = f;
        optionalField.isSelected = selectAll;
      });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function generate() {
      const selectedFields = _.map(
        _.filter(vm.optionalFields, (f) => f.isSelected),
        'id'
      );
      App.saveAsJsonToStorage(templateName, selectedFields);

      $uibModalInstance.close(selectedFields);
    }
  }
})();
