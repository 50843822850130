(function () {
    var controllerId = 'revenue.views.subscriptions.create';
    var module = angular.module('revenue');

    module.controller(controllerId, [
        '$state', '$stateParams', '$filter', '$timeout', 'abp.services.revenue.subscription', 'abp.services.revenue.common', 'HmsRevenueConsts.Subscription',
        'moment',
        function ($state, $stateParams, $filter, $timeout, subscriptionSvc, commonSvc, constsSubscription, moment) {
            function refreshContractLength() {
                const endDatePlusOne = moment(vm.subscription.endDate).add(1, 'd');
                const years = endDatePlusOne.diff(vm.subscription.startDate, 'y');

                const finalYearStartDate = moment(vm.subscription.startDate).add(years, 'y');
                const finalYearTotalDays = moment(finalYearStartDate).add(1, 'y').diff(finalYearStartDate, 'd');

                const totalDays = endDatePlusOne.diff(finalYearStartDate, 'd');
                const finalYearProrate = totalDays / finalYearTotalDays;

                vm.contractLength = App.localize('XYears', $filter('number')(years + finalYearProrate, 2));
            }

            function refreshDuration() {
                const startDate = moment(vm.subscription.startDate);
                const endDate = moment(vm.subscription.endDate);
                const endDatePlusOne = moment(endDate).add(1, 'd');
                const years = endDatePlusOne.diff(startDate, 'y');
                if (startDate.add(years, 'y').isSame(endDatePlusOne) && years >= 1 && years <= 3) {
                    vm.duration = years;
                } else {
                    vm.duration = null;
                }
            }

            function loadSubscription() {
                vm.loading++;
                subscriptionSvc.getCreateSubscriptionOptions({
                    corporateId: vm.corporateId
                }).success(function (data) {
                    vm.corporate = {
                        code: data.corporateCode,
                        name: data.corporateName
                    };
                    vm.subsidiaries = data.subsidiaries;
                    vm.plans = data.subscriptionPlans;
                    vm.agents = _.sortBy(data.agents, 'name');
                    refreshDuration();
                    refreshContractLength();
                }).finally(function () {
                    vm.loading--;
                });
            }

            function refreshErrors() {
                let errors = [];

                const name = (vm.subscription.name || '').trim();
                if (!name) errors.push(App.localize('InvalidName'));

                if (vm.subscription.scope === null) {
                    errors.push(App.localize('InvalidOrMissingX', App.localize('Scope')));
                } else if (vm.subscription.scope === 1 && !vm.subscription.subsidiaryIds.length) {
                    errors.push(App.localize('InvalidOrMissingX', App.localize('Subsidiaries')));
                }

                if (moment(vm.subscription.endDate).isBefore(moment(vm.subscription.startDate))) {
                    errors.push(App.localize('EndDateEarlierThanStartDateWarning'));
                }

                if (!vm.subscription.currencyCode) {
                    errors.push(App.localize('InvalidCurrency'));
                }
                if (vm.subscription.billingFrequency === null) {
                    errors.push(App.localize('InvalidOrMissingX', App.localize('BillingCycle')));
                }
                if (vm.subscription.chargingBasis === null) {
                    errors.push(App.localize('InvalidOrMissingX', App.localize('ChargingBasis')));
                }
                if (vm.subscription.charges === null || vm.subscription.charges < 0) {
                    errors.push(App.localize('InvalidOrMissingX', App.localize('Charges')));
                }
                if (vm.subscription.chargingBasis === 1) {
                    if (vm.subscription.minimumHeadcount === null) {
                        errors.push(App.localize('InvalidOrMissingX', App.localize('MinimumHeadcount')));
                    }
                    if (vm.subscription.billingMode === null) {
                        errors.push(App.localize('InvalidOrMissingX', App.localize('BillingMode')));
                    }
                    if (vm.subscription.billingMode === 2) {
                        if (vm.subscription.firstPrepaidAmount === null || vm.subscription.firstPrepaidAmount <= 0) {
                            errors.push(App.localize('InvalidOrMissingX', App.localize('PrepaidAmount')));
                        }
                    }
                }
                if (!vm.subscription.billingSubsidiaryId) {
                    errors.push(App.localize('InvalidOrMissingX', App.localize('BillTo')));
                }
                vm.errors = errors;
            }

            function save() {
                refreshErrors();
                if (vm.errors.length) {
                    $timeout(function () {
                        document.getElementById('pnlError').scrollIntoView();
                    }, 0);
                    return;
                }

                if (vm.saving) return;

                const input = $.extend({}, vm.subscription, { agents: [] });
                if (input.scope === 0) input.subsidiaryIds = [];
                if (vm.subscriptionAgents.salesPersonId) {
                    input.agents.push({
                        agentId: vm.subscriptionAgents.salesPersonId,
                        mode: vm.subscriptionAgents.salesPersonMode
                    });
                }
                if (vm.subscriptionAgents.salesPersonMode === 0 && vm.subscriptionAgents.salesOverridingManagerId) {
                    input.agents.push({
                        agentId: vm.subscriptionAgents.salesOverridingManagerId,
                        mode: 2
                    });
                }
                if (vm.subscription.chargingBasis !== 1) {
                    input.minimumHeadcount = 0;
                    input.billingMode = 0;
                    input.firstPrepaidAmount = null;
                }

                vm.saving = true;
                subscriptionSvc.createSubscription(input).success(function () {
                    abp.notify.info(App.localize("SuccessfullySaved"));
                    backToCorporate();
                }).finally(function () {
                    vm.saving = false;
                });
            }

            function backToCorporate() {
                $state.go('revenue.corporates', { corporateId: vm.corporateId, section: 'subscriptions' });
            }

            function init() {
                if (!vm.corporateId) {
                    backToCorporate();
                    return;
                }
                loadSubscription();
                getCorporateCurrency();
            }

            function getCorporateCurrency() {
                vm.loading++;
                commonSvc.getCurrencyByCorporateId({
                    id: vm.corporateId
                }).success(function (data) {
                    vm.currency = data;
                    vm.subscription.currencyCode = data.code;
                }).finally(function () {
                    vm.loading--;
                });
            }

            const vm = this;
            vm.constsSubscription = constsSubscription;
            vm.loading = 0;
            vm.saving = false;
            vm.corporateId = $stateParams.corporateId;
            vm.corporate = null;
            vm.subscription = {
                corporateId: $stateParams.corporateId,
                startDate: moment().startOf('day'),
                endDate: moment().startOf('day').add(2, 'years').add(-1, 'day'),
                subsidiaryIds: [],
                agents: [],
                scope: null,
                billingFrequency: null,
                chargingBasis: null,
                charges: null,
                minimumHeadcount: null,
                billingMode: null,
                firstPrepaidAmount: null
            };
            vm.subsidiaries = [];
            vm.plans = [];
            vm.agents = [];
            vm.contractLength = '';
            vm.duration = null;
            vm.errors = [];
            vm.subscriptionAgents = {
                salesPersonId: null,
                salesPersonMode: 0,
                salesOverridingManagerId: null
            };

            vm.updateCharges = function () {
                if (vm.subscription && vm.subscription.subscriptionPlanId) {
                    const plan = _.find(vm.plans, { id: vm.subscription.subscriptionPlanId });
                    if (plan) {
                        switch (vm.subscription.billingFrequency) {
                            case 1: vm.subscription.charges = plan.pricePerPaxPerMonth; break;
                            case 2: vm.subscription.charges = plan.pricePerPaxPerMonth * 2; break;
                            case 3: vm.subscription.charges = plan.pricePerPaxPerMonth * 3; break;
                            case 4: vm.subscription.charges = plan.pricePerPaxPerMonth * 4; break;
                            case 5: vm.subscription.charges = plan.pricePerPaxPerMonth * 6; break;
                            case 6: vm.subscription.charges = plan.pricePerPaxPerMonth * 12; break;
                        }
                    }
                }
            };

            vm.durationChanged = function () {
                var duration = +vm.duration;
                if (duration) {
                    vm.subscription.endDate = moment(vm.subscription.startDate).add(duration, 'y').add(-1, 'days');
                    refreshContractLength();
                }
            };

            vm.subscriptionPlanChanged = function () {
                if (vm.subscription && vm.subscription.subscriptionPlanId) {
                    const plan = _.find(vm.plans, { id: vm.subscription.subscriptionPlanId });
                    if (plan) {
                        vm.subscription.name = plan.name;
                        vm.subscription.currencyCode = plan.currencyCode;
                        vm.subscription.chargingBasis = 1;
                        vm.updateCharges();
                    }
                }
            };

            vm.precalculatePrepaidAmount = function () {
                if (vm.subscription.chargingBasis === 1 && vm.subscription.billingMode === 2) {
                    vm.subscription.firstPrepaidAmount = (vm.subscription.charges * vm.subscription.minimumHeadcount);
                }
            };

            vm.refreshContractLength = refreshContractLength;
            vm.save = save;
            vm.cancel = backToCorporate;

            init();
        }
    ]);
})();
