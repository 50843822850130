(() => {
  angular.module('app').filter('claimStatus', claimStatus);

  claimStatus.$inject = [];

  function claimStatus() {
    return claimStatusFilter;

    function claimStatusFilter(value) {
      switch (value) {
        case 0:
          return App.localize('ClaimStatus_Submitted');
        case 1:
          return App.localize('ClaimStatus_Approved');
        case 2:
          return App.localize('ClaimStatus_Paid');
        case 3:
          return App.localize('ClaimStatus_Rejected');
        case 4:
          return App.localize('ClaimStatus_Cancelled');
        case 5:
          return App.localize('ClaimStatus_MarkedForPayment');
        case 6:
          return App.localize('ClaimStatus_HrToApprove');
        default:
          return value;
      }
    }
  }
})();
