(function () {
    var componentId = 'clinicPatientTicketEmployeeCardWidget';
    var app = angular.module('app');

    function controller($scope, $interval, $stateParams, $filter, moment) {
        var vm = this;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.loading = 0;
        vm.isCreate = !!$stateParams.employeeId;
        vm.tickerProgressBarValue = 100;

        vm.clinicNumber = abp.setting.get('Hms.General.ClinicSupportContactNumber');

        vm.patientCheckInRequiredToViewBalance = abp.features.isEnabled('PatientCheckInRequiredToViewBalance');
        vm.permissions = {
            canViewTickets: abp.auth.isGranted('PatientTickets')
        };
        function init() {
            var tickerInterval = $interval(tickerJob, 1000);
            $scope.$on('$destroy', function () {
                if (angular.isDefined(tickerInterval)) {
                    $interval.cancel(tickerInterval);
                    tickerInterval = undefined;
                }
            });

            if (vm.employee && vm.employee.coPay) {
                vm.coPayValueDisplay = vm.employee.coPay.isPercentage
                    ? vm.employee.coPay.value + '%'
                    : $filter('currencyFormat')(vm.employee.coPay.value, vm.currencyCode);
            }

        }

        function tickerJob() {
            if (vm.employee && vm.employee.checkedInTime) {
                var totalSeconds = moment(vm.employee.checkOutTime).diff(moment(), 's');
                var duration = moment.duration(totalSeconds, 's');
                if (totalSeconds <= 0) {
                    vm.employee.checkedInTime = null;
                    vm.employee.timeRemaining = null;
                } else {
                    var seconds = duration.seconds();
                    var minutes = duration.minutes();
                    var hours = duration.hours();
                    var days = duration.days();
                    var str = '';
                    if (days > 0) str += days + 'd ';
                    if (hours > 0) str += _.padStart('' + hours, 2, '0') + 'h ';
                    str += _.padStart('' + minutes, 2, '0') + 'm ' + _.padStart('' + seconds, 2, '0') + 's';
                    vm.employee.timeRemaining = str;
                }

                // HACK: GP has 1 day to submit ticket,
                // For Specialist, has 2 days to submit ticket,
                // For pre-employee, has 7 days to submit report.

                var limit = 24 * 60 * 60;
                if (vm.employee.isPreEmployee) {
                    limit *= 7;
                }
                else if (vm.employee.hasSpecialistBenefit && App.isSpecialist()) {
                    limit *= 2;
                }

                vm.tickerProgressBarValue = totalSeconds / limit * 100;
                $('div.progress-bar').width(vm.tickerProgressBarValue + '%');
            }
        }

        vm.limitPeriod = function (employee) {
            var cycle = '';
            if (employee.allowancePeriod) {
                switch (employee.allowancePeriod.cycle) {
                    case 0: cycle = App.localize('EveryXYears', employee.allowancePeriod.interval); break;
                    case 1: cycle = App.localize('Yearly'); break;
                    case 2: cycle = App.localize('Monthly'); break;
                    case 3: cycle = App.localize('Daily'); break;
                    case 4: cycle = App.localize('PerVisit'); break;
                    case 5: cycle = App.localize('Every6Months'); break;
                    case 6: cycle = App.localize('Every4Months'); break;
                    case 7: cycle = App.localize('Every3Months'); break;
                    case 8: cycle = App.localize('Every2Months'); break;
                }
                cycle = cycle.toLowerCase();
            }
            return cycle;
        };

        /* Employee icon */

        vm.getUserBgColor = function (id) {
            return 'user_bgcolor_' + ((id || 0) % 8 + 1);
        };

        vm.getUserInitial = function (user) {
            if (user) {
                var fullName = (user.name || ' ').split(' ');
                var initial = '';
                for (var i = 0; i < fullName.length; i++) {
                    if (fullName[i].length) {
                        initial += fullName[i][0];
                    }
                }
                if (initial && initial.length > 3) initial = '' + initial[0];
                return initial;
            }
        };

        /* End of employee icon */

        $(document).ready(function () {
            $('.dd').nestable();
        });

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/clinic/views/patient/ticket/employeeCard/widget.html'),
        controller: ['$scope', '$interval', '$stateParams','$filter', 'moment', controller],
        controllerAs: 'vm',
        bindings: {
            employee: '<',
            isLateSubmission: '<'
        }
    });
})();
