(function () {
    angular.module('app').component('hostDoraemonCommonPatientDetailsComponent', {
        templateUrl: require('/App/host/views/doraemon/common/patientDetails/patientDetails.component.html'),
        controller: ['abp.services.app.doraemon', 'Hms.Gender', controller],
        controllerAs: 'vm',
        bindings: {
            memberId: '<',
            serviceRequestId: '<'
        }
    });

    function controller(doraemonSvc, enumGender) {
        var vm = this;
        vm.loading = 0;
        vm.$onChanges = onChanges;

        vm.enums = {};
        vm.enums.gender = enumGender;

        function onChanges() {
            if (vm.memberId > 0) {
                vm.loading++;
                doraemonSvc.getPatientDetailsByPatientMemberId({
                    id: vm.memberId
                }).success(function (data) {
                    vm.patient = data;
                }).finally(function () {
                    vm.loading--;
                });
            }
            else if (vm.serviceRequestId) {
                vm.loading++;
                doraemonSvc.getPatientDetailsByServiceRequestId({
                    id: vm.serviceRequestId
                }).success(function (data) {
                    vm.patient = data;
                }).finally(function () {
                    vm.loading--;
                });
            }
        }
    }
})();
