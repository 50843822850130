(() => {
  angular
    .module('app')
    .controller(
      'common.views.inpatientNprClaims.details.claimSummary.delegate',
      DelegateModalController
    );

  DelegateModalController.$inject = [
    '$state',
    '$stateParams',
    '$uibModalInstance',
    'corporateId',
    'abp.services.app.nprClaim',
  ];

  function DelegateModalController(
    $state,
    $stateParams,
    $uibModalInstance,
    corporateId,
    hostNprClaimSvc
  ) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;
    vm.claimNumber = $stateParams.claimNumber;
    vm.users = [];
    vm.recipients = [];
    vm.remarks = null;

    vm.delegate = delegate;
    vm.close = close;

    init();

    function init() {
      vm.loading += 1;
      hostNprClaimSvc
        .findUsersToDelegate({
          id: corporateId,
        })
        .success((data) => {
          vm.users = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function delegate() {
      if (vm.recipients.length === 0) {
        abp.message.error(App.localize('RecipientEmailIsEmpty'));
      } else {
        vm.saving += 1;
        hostNprClaimSvc
          .delegateClaim({
            id: vm.claimNumber,
            recipientIds: _.join(vm.recipients, ', '),
            remarks: vm.remarks,
          })
          .success(() => {
            vm.close();
            $state.reload();
          })
          .finally(() => {
            vm.saving -= 1;
          });
      }
    }

    function close() {
      $uibModalInstance.dismiss();
    }
  }
})();
