(() => {
  angular
    .module('app')
    .controller('common.views.saleTransactions.invoiceFinder', InvoiceFinderController);

  InvoiceFinderController.$inject = ['abp.services.app.saleTransaction', 'moment'];

  function InvoiceFinderController(saleTransactionSvc, moment) {
    const vm = this;

    vm.loading = 0;
    vm.today = moment();
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.stopInvoiceGeneration = abp.setting.getBoolean('Hms.Feature.StopPortalInvoiceGeneration');
    vm.requestParams = {
      amount: 1,
      date: moment().startOf('day'),
    };

    vm.findInvoicesAndCreditMemos = findInvoicesAndCreditMemos;

    init();

    function init() {}

    function findInvoicesAndCreditMemos() {
      vm.loading += 1;
      saleTransactionSvc
        .findInvoicesAndCreditMemos(vm.requestParams)
        .success((data) => {
          vm.data = data.items;
          vm.amount = data.amount;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
