(() => {
  angular.module('finance').component('financeBillingAccountsOverviewTab', {
    templateUrl: require('./widget.html'),
    controller: BillingAccountsOverviewTabController,
    controllerAs: 'vm',
    require: { container: '^financeBillingAccountsContainer' },
    bindings: {
      billingAccount: '<',
    },
  });

  BillingAccountsOverviewTabController.$inject = [
    'Hms.Finance.KnownBillingAccountEntityTypes',
    'abp.service.finance.billingAccount',
    '$uibModal',
  ];

  function BillingAccountsOverviewTabController(
    enumKnownBillingAccountEntityTypes,
    billingAccountSvc,
    $uibModal
  ) {
    const vm = this;

    vm.enums = {};
    vm.enums.knownBillingAccountEntityTypes = enumKnownBillingAccountEntityTypes;

    vm.hasRegenerateBillingAccountCodePermission = abp.auth.isGranted(
      'HealthMetrics.Finance.BillingAccounts.RegenerateBillingAccountCode'
    );

    vm.displayEntityType = displayEntityType;
    vm.regenerateAccountCode = regenerateAccountCode;
    vm.editAccountCode = editAccountCode;

    vm.$onChanges = () => displayEntityType();

    function displayEntityType() {
      vm.entityType = _.find(vm.enums.knownBillingAccountEntityTypes, {
        id: vm.billingAccount.entityType,
      }).name;
    }

    function regenerateAccountCode() {
      abp.message.confirm(
        App.localize('RegenerateNewAccountBillingCodeConfirmation'),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            vm.loading = true;
            billingAccountSvc
              .getNewBillingAccountCode({
                id: vm.billingAccount.id,
              })
              .success((data) => {
                vm.billingAccount.accountCode = data;
              })
              .finally(() => {
                vm.loading = false;
              });
          }
        }
      );
    }

    function editAccountCode() {
      $uibModal
        .open({
          templateUrl: require('./editAccountCodeModal/editAccountCode.modal.html'),
          controller: 'finance.views.billingAccounts.editAccountCodeModal as vm',
          backdrop: 'static',
          resolve: {
            billingAccount() {
              return vm.billingAccount;
            },
          },
        })
        .result.then(() => {});
    }
  }
})();
