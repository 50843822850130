(() => {
  angular
    .module('app')
    .filter('terminating', terminating)
    // For backward compatibility only. Filter name don't need "Filter" suffix.
    // AngularJS automatically appends "Filter" to name for DI.
    .filter('terminatingFilter', terminating);

  terminating.$inject = ['moment'];

  function terminating(moment) {
    return terminatingFilter;

    function terminatingFilter(value) {
      return value ? App.localize('TerminatingOnX', moment(value).format('L')) : '';
    }
  }
})();
