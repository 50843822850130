(() => {
  angular.module('app').controller('common.views.panelList', PanelListPageController);

  PanelListPageController.$inject = [
    '$scope',
    '$stateParams',
    '$state',
    '$timeout',
    'abp.services.app.panelList',
    'abp.services.app.commonLookup',
    'Hms.ClinicLocations.PanelLocationFacility',
    'appConstants',
  ];

  function PanelListPageController(
    $scope,
    $stateParams,
    $state,
    $timeout,
    panelListSvc,
    commonLookupSvc,
    enumPanelLocationFacility,
    appConstants
  ) {
    const vm = this;

    vm.loading = 0;
    vm.enqueuing = 0;
    vm.isHost = App.isHost();
    vm.advancedFiltersAreShown = false;
    vm.benefitPolicies = [];
    vm.states = [];
    vm.cityStates = [];
    vm.applicationHasInpatientModule = abp.setting.getBoolean('Hms.Feature.InpatientModule');
    vm.hasInpatientModule = false;
    vm.isCorporate = App.isCorporate();
    vm.isCovidTestFilterApplied = false;
    vm.selectedCorporate = null;
    vm.countryCode = null;
    vm.countryChanged = countryChanged;
    vm.cities = [];
    vm.languages = [];

    vm.dataExportRecordName = 'PanelListPdf';
    vm.generateButtonType = App.localize('ExportToPdf');

    vm.enums = {
      panelLocationFacility: enumPanelLocationFacility,
    };

    vm.requestParams = {
      serviceType: $stateParams.serviceType,
      ratesType: $stateParams.ratesType,
      countrySubdivisionId: $stateParams.countrySubdivisionId,
      city: $stateParams.city,
      is24By7: $stateParams.is24By7,
      hasXray: $stateParams.hasXray,
      midnightSurcharge: $stateParams.midnightSurcharge,
      hasFemaleDoctor: $stateParams.hasFemaleDoctor,
      hasUltrasound: $stateParams.hasUltrasound,
      languages: $stateParams.languages,
      benefitGroupId: $stateParams.benefitGroupId,
      corporateId: $stateParams.corporateId,
      skipCount: $stateParams.skipCount || 0,
      maxResultCount: $stateParams.maxResultCount || appConstants.grid.defaultPageSize,
      sorting: $stateParams.sorting,
      panelName: $stateParams.panelName,
    };

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSizes,
      paginationPageSize: appConstants.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      resizable: true,
      columnDefs: [
        {
          displayName: App.localize('AllowedByBenefitGroupAccessPolicy'),
          field: 'isAllowed',
          width: 100,
          enableSorting: false,
          cellTemplate: 'isAllowedTemplate',
        },
        {
          displayName: App.localize('Name'),
          field: 'name',
          minWidth: 120,
        },
        {
          displayName: App.localize('Type'),
          field: 'panelType',
          width: 60,
          enableSorting: false,
          cellTemplate: 'panelTypeTemplate',
        },
        {
          displayName: App.localize('Services'),
          field: 'services',
          visible: vm.applicationHasInpatientModule || vm.hasInpatientModule,
          cellTemplate: 'serviceTemplate',
          width: 100,
        },
        {
          displayName: App.localize('PriceCategory'),
          field: 'locationPriceRating',
          width: 100,
          enableSorting: false,
          cellTemplate: 'priceRatingTemplate',
        },
        {
          displayName: App.localize('State'),
          field: 'state',
          width: 100,
        },
        {
          displayName: App.localize('City'),
          field: 'city',
          width: 100,
        },
        {
          displayName: App.localize('Address'),
          field: 'address',
          enableSorting: false,
          minWidth: 120,
        },
        {
          displayName: App.localize('Postcode'),
          field: 'postcode',
          width: 80,
        },
        {
          displayName: App.localize('ContactNumber'),
          field: 'contactNumber',
          width: 120,
        },
        {
          displayName: App.localize('EmailAddress'),
          field: 'emailAddress',
          visible: vm.isHost,
          width: 120,
        },
        {
          displayName: App.localize('Language'),
          field: 'languages',
          enableSorting: false,
          minWidth: 150,
        },
        {
          displayName: App.localize('Facilities'),
          field: 'facilities',
          enableSorting: false,
          cellTemplate: 'facilityTemplate',
          minWidth: 100,
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          vm.requestParams.sorting =
            callback.length && callback[0].field
              ? `${callback[0].field} ${callback[0].sort.direction}`
              : null;
          vm.getPanelList();
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, vm.getPanelList)
        );
      },
      data: [],
    };

    vm.getFilterOptions = getFilterOptions;
    vm.lookupClinicLanguages = lookupClinicLanguages;
    vm.getPanelList = getPanelList;
    vm.resetCities = resetCities;
    vm.export = exportToPdf;
    vm.alertIfCovidTest = alertIfCovidTest;

    init();

    function init() {
      getEligibleCountries();

      vm.getFilterOptions();

      $timeout(() => {
        vm.gridOptions.paginationCurrentPage = Math.floor(
          vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1
        );
      });
    }

    /* Filter Functions */

    function getFilterOptions() {
      vm.requestParams.benefitGroupId = null; // Reset benefit group selected.

      vm.selectedCorporate = _.find(vm.corporates, { value: vm.requestParams.corporateId });

      vm.loading += 1;
      panelListSvc
        .getFilterOptions({
          id: vm.requestParams.corporateId,
        })
        .success((data) => {
          vm.benefitPolicies = data.benefitGroupAccessPolicy;
          vm.hasSpecialistModule = data.hasSpecialistModule;
          vm.hasPreEmployment = data.hasPreEmployment;
          vm.hasNewPanelAccessModule = data.hasNewPanelAccessModule;
          vm.hasInpatientModule = data.hasInpatientModule;
          vm.hasHospitalizationModule = data.hasHospitalizationModule;
          vm.hasMaternityModule = data.hasMaternityModule;

          vm.hasHealthScreeningModule = data.hasHealthScreeningModule;

          if (vm.isCorporate) {
            vm.countryCode = data.accountOwnerCountryCode;
          }

          // Convert value received from API to integer

          vm.facilities = _.map(data.facilities, (facility) => ({
            name: facility.name,
            value: Number(facility.value),
          }));

          initServiceTypes();
          toggleColumns();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function resetCities() {
      vm.requestParams.city = null;
      if (vm.requestParams.countrySubdivisionId) {
        vm.cities = _.map(
          _.filter(vm.cityStates, ['countrySubdivisionId', vm.requestParams.countrySubdivisionId]),
          'city'
        ).sort();
      } else {
        vm.cities = [];
      }
    }

    function initServiceTypes() {
      const allText = App.localize('All');

      vm.serviceTypeFilters = [{ value: null, name: allText }];
      vm.serviceTypeFilters.push({ value: 1, name: App.localize('GP') });
      vm.serviceTypeFilters.push({ value: 2, name: App.localize('Dental') });
      vm.serviceTypeFilters.push({ value: 3, name: App.localize('Optical') });
      if (vm.hasSpecialistModule) {
        vm.serviceTypeFilters.push({ value: 5, name: App.localize('Specialist') });
      }
      if (vm.isCorporate) {
        if (vm.hasHospitalizationModule) {
          vm.serviceTypeFilters.push({ value: 7, name: App.localize('Hospitalization') });
        }
        if (vm.hasMaternityModule) {
          vm.serviceTypeFilters.push({ value: 8, name: App.localize('Maternity') });
        }
      } else {
        vm.serviceTypeFilters.push({ value: 4, name: App.localize('PreEmployment') });
        vm.serviceTypeFilters.push({ value: 7, name: App.localize('Hospitalization') });
        vm.serviceTypeFilters.push({ value: 8, name: App.localize('Maternity') });
      }
      if (vm.hasHealthScreeningModule) {
        vm.serviceTypeFilters.push({ value: 9, name: App.localize('HealthScreening') });
      }

      vm.rateTypeFilters = [{ value: null, name: allText }];

      if (vm.hasNewPanelAccessModule || vm.isHost) {
        vm.rateTypeFilters.push({ value: 1, name: App.localize('Essential') });
        vm.rateTypeFilters.push({ value: 2, name: App.localize('Preferred') });
        vm.rateTypeFilters.push({ value: 3, name: App.localize('PreferredPlus') });
        vm.rateTypeFilters.push({ value: 4, name: App.localize('Premium') });
      } else {
        vm.rateTypeFilters.push({ value: 2, name: App.localize('Preferred') });
        vm.rateTypeFilters.push({ value: 3, name: App.localize('NonPreferred') });
        vm.rateTypeFilters.push({ value: 4, name: App.localize('OpenPanel') });
      }
    }

    function lookupClinicLanguages(search) {
      commonLookupSvc
        .findSpokenLanguages({
          filter: search,
          maxResultCount: 50,
        })
        .success((data) => {
          vm.languages = data.items;
        });
    }

    function toggleColumns() {
      const gridColumns = vm.gridOptions.columnDefs.map((e) => e.field);

      // Show price rating column only if country code is 'MY'.
      // Hide city, state columns only if country code is 'SG'.

      const priceRatingIsVisible = vm.countryCode === 'MY';
      const cityStateIsVisible = vm.countryCode !== 'SG';

      vm.gridOptions.columnDefs[gridColumns.indexOf('locationPriceRating')].visible =
        priceRatingIsVisible;
      vm.gridOptions.columnDefs[gridColumns.indexOf('state')].visible = cityStateIsVisible;
      vm.gridOptions.columnDefs[gridColumns.indexOf('city')].visible = cityStateIsVisible;
    }

    /* End of Filter Functions */

    function getInput() {
      return {
        serviceType: vm.requestParams.serviceType,
        ratesType: vm.requestParams.ratesType,
        countrySubdivisionId: vm.requestParams.countrySubdivisionId,
        city: vm.requestParams.city,
        is24By7: (vm.requestParams.facilities && vm.requestParams.facilities.includes(1)) || null,
        hasXray: (vm.requestParams.facilities && vm.requestParams.facilities.includes(2)) || null,
        hasUltrasound:
          (vm.requestParams.facilities && vm.requestParams.facilities.includes(3)) || null,
        midnightSurcharge:
          (vm.requestParams.facilities && vm.requestParams.facilities.includes(4)) || null,
        hasFemaleDoctor:
          (vm.requestParams.facilities && vm.requestParams.facilities.includes(5)) || null,
        hasCovid19RtkAntigen:
          (vm.requestParams.facilities && vm.requestParams.facilities.includes(6)) || null,
        hasCovid19Pcr:
          (vm.requestParams.facilities && vm.requestParams.facilities.includes(7)) || null,
        languages: vm.requestParams.languages,
        benefitGroupId: vm.requestParams.benefitGroupId,
        corporateId: vm.requestParams.corporateId,
        countryCode: vm.countryCode,
        skipCount: vm.requestParams.skipCount,
        maxResultCount: vm.requestParams.maxResultCount,
        sorting: vm.requestParams.sorting,
        panelName: vm.requestParams.panelName,
      };
    }

    function registerStateParams(input) {
      const i = input || getInput();
      $state.transitionTo($state.current, i, { notify: false });
    }

    function getPanelList() {
      const input = getInput();
      registerStateParams(input);

      vm.loading += 1;
      panelListSvc
        .getPanelList(input)
        .success((data) => {
          vm.gridOptions.totalItems = data.totalCount;
          vm.gridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function exportToPdf() {
      const input = getInput();

      vm.enqueuing += 1;
      panelListSvc
        .enqueueGeneratePanelListPdfJob(input)
        .success(() => {
          vm.refresh();
        })
        .finally(() => {
          vm.enqueuing -= 1;
        });
    }

    function alertIfCovidTest() {
      const input = getInput();
      vm.isCovidTestFilterApplied = input.hasCovid19Pcr || input.hasCovid19RtkAntigen;
    }

    function getEligibleCountries() {
      vm.loading += 1;
      commonLookupSvc
        .getEligibleCountries()
        .success((data) => {
          vm.countries = data;

          const defaultCountry = _.find(vm.countries, { isDefault: true });
          vm.countryCode = defaultCountry.code;

          getCorporates();
          getCityStates();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getCorporates() {
      vm.loading += 1;
      commonLookupSvc
        .getCorporates({ countryCode: vm.countryCode })
        .success((data) => {
          vm.corporates = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function countryChanged() {
      // Reset corporate, city, state filters once the country selection is changed.

      vm.requestParams.corporateId = null;
      vm.requestParams.countrySubdivisionId = null;
      vm.requestParams.city = null;
      getCorporates();
      getCityStates();
      toggleColumns();

      // Reset price category filter if the selected country is not MY.

      if (vm.countryCode !== 'MY') {
        vm.requestParams.ratesType = null;
      }
    }

    function getCityStates() {
      vm.loading += 1;
      commonLookupSvc
        .getCityStatesByCountryCode({ countryCode: vm.countryCode })
        .success((data) => {
          vm.cityStates = data.items;
          vm.states = _.sortBy(
            _.uniqBy(
              _.map(vm.cityStates, (cs) => ({
                state: cs.state,
                countrySubdivisionId: cs.countrySubdivisionId,
              })),
              'countrySubdivisionId'
            ),
            'countrySubdivisionId'
          );
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
