(function () {
    var controllerId = 'corporate.views.subsidiaryDepartments.createOrEditModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'abp.services.app.subsidiaryDepartment', 'subsidiaryId', 'subsidiaryName', 'subsidiaryDepartmentId','Hms.SubsidiaryDepartments.SubsidiaryDepartment', 'Abp.Configuration.Setting', 
        function ($scope, $uibModalInstance, subsidiaryDepartmentSvc, subsidiaryId, subsidiaryName, subsidiaryDepartmentId, constsSubsidiaryDepartment, constsAbpConfig) {

            function init() {
                vm.loading = true;
                subsidiaryDepartmentSvc.getSubsidiaryDepartmentForEdit({
                    id: subsidiaryDepartmentId
                }).success(function (data) {
                    vm.subsidiaryDepartment = data || {};
                    if (!subsidiaryDepartmentId) {
                        vm.subsidiaryDepartment.subsidiaryId = subsidiaryId;
                    }
                }).finally(function () {
                    vm.loading = false;
                });
            }

            var vm = this;
		        vm.constsSubsidiaryDepartment = constsSubsidiaryDepartment;
            vm.constsAbpConfig = constsAbpConfig;
            vm.loading = false;
            vm.saving = false;
            vm.subsidiaryDepartment = null;
            vm.subsidiaryName = subsidiaryName;
            vm.save = function () {
                vm.saving = true;
                subsidiaryDepartmentSvc.createOrUpdateSubsidiaryDepartment(vm.subsidiaryDepartment).success(function () {
                    abp.notify.info(App.localize("SuccessfullySaved"));
                    $uibModalInstance.close();
                }).finally(function () {
                    vm.saving = false
                });
            };
            vm.cancel = function () {
                $uibModalInstance.dismiss()
            };
            init();
        }
    ]);
})();
