(() => {
  const componentId = 'hostInsurersCreateEditFinance';

  angular.module('app').component(componentId, {
    require: {
      container: '^hostInsurersCreateEditContainer',
    },
    bindings: {
      data: '<',
      isCreate: '<',
    },
    templateUrl: require('./finance.component.html'),
    controller: InsurerCreateFinanceController,
    controllerAs: 'vm',
  });

  InsurerCreateFinanceController.$inject = [
    '$scope',
    'abp.services.app.insurer',
    'Hms.Employees.Employee',
    'Hms.Insurance.Insurer',
    'AbpUserBase',
    'Hms.MultiTenancy.Tenant',
  ];

  function InsurerCreateFinanceController(
    $scope,
    insurerSvc,
    constsEmployee,
    constsInsurer,
    constsAbpUserBase,
    constsTenant
  ) {
    const vm = this;

    vm.constsEmployee = constsEmployee;
    vm.constsInsurer = constsInsurer;
    vm.constsAbpUserBase = constsAbpUserBase;
    vm.constsTenant = constsTenant;
    vm.save = save;
    vm.loading = 0;
    vm.saving = 0;
    vm.rollingNumbers = [];
    vm.isEditing = false;
    vm.permissions = {
      edit: abp.auth.isGranted('Host.NewInsurers.EditFinance'),
      create: abp.auth.isGranted('Host.NewInsurers.Create'),
    };

    vm.generateNewAcConfirmation = generateNewAcConfirmation;
    vm.copyToClipboard = copyToClipboard;
    vm.copyLocationAddress = copyLocationAddress;
    vm.validateForm = validateForm;
    vm.getPayload = getPayload;
    vm.isDirty = isDirty;
    vm.setPristine = setPristine;

    vm.$onInit = () => {
      vm.container.addWidget(this);

      if (vm.isCreate) {
        vm.data = {
          countryCode: abp.setting.get('Hms.General.DefaultCountryCode'),
        };
      }
    };

    vm.$onChanges = () => {
      if (!vm.isCreate && !_.isNil(vm.data) && !_.isNil(vm.data.virtualAccountNumber)) {
        vm.rollingNumber = vm.data.virtualAccountNumber.substr(5, 16);
        prepareAccountNumbers(vm.data.virtualAccountNumber);
      }
    };

    function generateNewAcConfirmation() {
      const insurerName = $scope.$$prevSibling.vm.data.name;
      const confirmMsg = `${App.localize(
        'GeneratingNewVirtualAccountNumberInfo'
      )}\n ${insurerName}`;
      abp.message.confirm(confirmMsg, App.localize('ConfirmedTitle'), (d) => {
        if (d) {
          vm.generateNewAcButtonClicked = true;
          insurerSvc.generateNewVirtualAccountNumber().success((data) => {
            vm.hmsAcNumber = data.slice(0, 5);
            const newRollingNumber = data.slice(5);

            if (!vm.rollingNumbers.includes(newRollingNumber)) {
              vm.rollingNumbers.push(data.slice(5));
            }

            vm.rollingNumber = newRollingNumber;
          });
        }
      });
    }

    function prepareAccountNumbers(accountNumber) {
      const hmsAccNumbers = [];
      const rollingNumbers = [];

      if (!hmsAccNumbers.includes(accountNumber.slice(0, 5))) {
        hmsAccNumbers.push(accountNumber.slice(0, 5));
      }

      if (!rollingNumbers.includes(accountNumber.slice(5))) {
        rollingNumbers.push(accountNumber.slice(5));
      }

      vm.rollingNumbers = rollingNumbers;
      vm.hmsAcNumber = _.head(hmsAccNumbers);
    }

    function copyToClipboard() {
      const element = document.createElement('input');
      element.value = vm.hmsAcNumber + vm.rollingNumber;
      document.body.appendChild(element);
      element.select();
      document.execCommand('copy');
      abp.notify.success(App.localize('VirtualAccountNumberCopied'));
    }

    function copyLocationAddress() {
      const locationAddress = $scope.$$prevSibling.vm.data;

      // To convert addressInput data model for input for server.
      if (!locationAddress) return;

      vm.data.street = locationAddress.street;
      vm.data.city = locationAddress.city;
      vm.data.state = locationAddress.state;
      vm.data.postcode = locationAddress.postcode;
      vm.data.countryCode = locationAddress.countryCode;
      vm.data.countrySubdivisionId = locationAddress.countrySubdivisionId;
    }

    // Functions utilised in container file.

    // Force validation on this form.
    // Return true if valid, otherwise, false.

    function validateForm() {
      const form = $scope.insurerCreateEditFinanceForm;
      App.touchFormErrors(form);
      return form.$valid;
    }

    // Generate the payload from this form to send to InsurerAppService API.

    function getPayload() {
      vm.data.virtualAccountNumber = vm.hmsAcNumber + vm.rollingNumber;
      return {
        finance: vm.data,
      };
    }

    // Ask container to save this widget.

    function save() {
      vm.container.saveWidget(vm);
    }

    function isDirty() {
      return $scope.insurerCreateEditFinanceForm.$dirty;
    }

    function setPristine() {
      $scope.insurerCreateEditFinanceForm.$setPristine();
    }
  }
})();
