(() => {
  angular
    .module('app')
    .controller(
      'common.views.patientTickets.setEffectivePostHospitalizationMcModal',
      SetEffectivePostHospitalizationMcModalController
    );

  SetEffectivePostHospitalizationMcModalController.$inject = [
    '$scope',
    '$uibModalInstance',
    'abp.services.app.patientTicket',
    'abp.services.app.guaranteeLetter',
    'ticket',
    'moment',
  ];

  function SetEffectivePostHospitalizationMcModalController(
    $scope,
    $uibModalInstance,
    patientTicketSvc,
    guaranteeLetterSvc,
    ticket,
    moment
  ) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;
    vm.ticket = ticket;
    vm.loading = 0;
    vm.dateValidation = dateValidation;

    vm.mcEndDate = vm.ticket.mcEndDate !== null ? vm.ticket.mcEndDate : null;
    vm.remarks = vm.ticket.mcRemark;

    vm.save = save;
    vm.cancel = cancel;
    vm.validateForm = validateForm;

    init();

    function init() {
      vm.loading += 1;
      guaranteeLetterSvc
        .getGuaranteeLetterDischargeDateByPatientTicketId(vm.ticket.ticketId)
        .success((data) => {
          vm.mcStartDate = vm.ticket.mcStartDate != null ? vm.ticket.mcStartDate : data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function save() {
      if (!vm.validateForm()) {
        abp.notify.error(App.localize('GeneralInvalidFormInputError'));
        return;
      }

      vm.saving += 1;
      patientTicketSvc
        .setEffectivePostHospitalizationMc({
          id: vm.ticket.ticketId,
          mcStartDate: vm.mcStartDate,
          mcEndDate: vm.mcEndDate,
          remarks: vm.remarks,
        })
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          $uibModalInstance.close();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function validateForm() {
      const form = $scope.effectivePostHospitalizationMcEditForm;
      App.touchFormErrors(form);
      return form.$valid;
    }

    function dateValidation(value, otherDate) {
      return moment(value).diff(moment(otherDate), 'days') > -1;
    }
  }
})();
