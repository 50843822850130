(() => {
  angular
    .module('app')
    .controller(
      'common.views.guaranteeLetters.details.letterDetail.topUpModal',
      TopUpModalController
    );

  TopUpModalController.$inject = [
    '$uibModalInstance',
    'abp.services.app.guaranteeLetter',
    'letterNumber',
  ];

  function TopUpModalController($uibModalInstance, guaranteeLetterSvc, letterNumber) {
    const vm = this;

    vm.letterNumber = letterNumber;
    vm.loading = 0;
    vm.saving = 0;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

    vm.submit = submit;
    vm.cancel = cancel;
    vm.getNewAmount = getNewAmount;

    init();

    function init() {
      vm.loading += 1;
      guaranteeLetterSvc
        .getGuaranteeLetterForTopUp({
          id: letterNumber,
        })
        .success((data) => {
          vm.topUpModel = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getNewAmount() {
      if (!vm.topUpModel) return 0;

      return Math.max(
        Math.min(
          vm.topUpModel.corporateBalance,
          vm.topUpModel.employeeBalance,
          vm.desiredGuaranteedAmount || 0
        ),
        0
      );
    }

    function submit() {
      vm.saving += 1;
      guaranteeLetterSvc
        .topUpGuaranteeLetter({
          id: vm.letterNumber,
          desiredGuaranteedAmount: vm.desiredGuaranteedAmount,
        })
        .success(() => {
          $uibModalInstance.close(true);
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
