(function () {
    'use strict';

    angular.module('app').component('clinicPanelDashboardPatientVisitsComponent', {
        templateUrl: require('/App/clinic/views/panelDashboard/patientVisits/patientVisits.component.html'),
        controller: ['$uibModal', 'abp.services.app.panelDashboard', controller],
        controllerAs: 'vm',
        bindings: {
            panelLocationId: '<',
            isGpPanel: '<'
        }
    });

    function controller($uibModal, panelDashboardSvc) {
        var vm = this;

        vm.$onChanges = onChanges;
        vm.onTable = onTable;

        vm.loading = 0;
        vm.header = App.localize('SubmittedPatientVisits');
        vm.growthDuration = App.localize('FromSameDayLastMonth');
        vm.tooltip = App.localize('SubmittedPatientVisitsTooltip');
        vm.hasGpDashboardEnhancement = abp.setting.getBoolean('Hms.Feature.GPDashboardEnhancement');

        function onChanges(changes) {
            if (changes.panelLocationId && changes.panelLocationId.currentValue) {
                getPatientVisitsMetric();
            }
        }

        function getPatientVisitsMetric() {
            vm.loading++;
            panelDashboardSvc.getPatientVisitsMetric({ id: vm.panelLocationId })
                .success(function (data) {
                    vm.metrics = data.metrics;
                    vm.growthRate = data.growthRate;
                    vm.momGrowthResult = data.momGrowthRate;
                })
                .finally(function () {
                    vm.loading--;
                });
        }

        function onTable() {
            $uibModal.open({
                component: 'clinicPanelDashboardGrowthTable',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                    result: function () {
                        return vm.momGrowthResult;
                    },
                    type: function () {
                        return "patientVisits";
                    },
                    header: function () {
                        return vm.header;
                    }
                }
            });
        }
    }
})();
