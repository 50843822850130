(() => {
  angular.module('app').component('userAccessibleCountries', {
    templateUrl: require('./accessibleCountries.component.html'),
    controller: AccessibleCountriesController,
    controllerAs: 'vm',
    bindings: {
      accessibleCountries: '=',
    },
  });

  AccessibleCountriesController.$inject = ['countries'];

  function AccessibleCountriesController(countries) {
    const vm = this;

    vm.defaultCountries = null;
    vm.hasDefaultCountry = false;
    vm.hasIndonesiaAndThailandRegionalExpansion = abp.setting.getBoolean(
      'Hms.Feature.IndonesiaAndThailandRegionalExpansion'
    );

    vm.removeItem = removeItem;
    vm.addItem = addItem;
    vm.setDefault = setDefault;
    vm.checkDuplicateCountry = checkDuplicateCountry;

    init();

    function init() {
      // Limit country selection to Malaysia, Indonesia, Singapore and Thailand only.
      // TODO: HM-5292, country selection should be based on business
      // entities/account owners country.

      const supportedCountryCodes = ['MY', 'SG'];

      if (vm.hasIndonesiaAndThailandRegionalExpansion) supportedCountryCodes.push('ID', 'TH');

      vm.countries = _.filter(countries, (k) => supportedCountryCodes.includes(k.code));

      if (vm.accessibleCountries.length > 0) {
        const lastCountry = _.last(vm.accessibleCountries);
        if (lastCountry.countryCode) addItem(vm.accessibleCountries.length - 1);
      } else {
        addItem(-1, true);
      }
    }

    function removeItem(index) {
      if (index < vm.accessibleCountries.length) {
        vm.accessibleCountries = _.without(vm.accessibleCountries, vm.accessibleCountries[index]);
      }

      if (vm.accessibleCountries.length === 0) {
        addItem(-1, true);
      }

      validateItems();
    }

    function setDefault(index) {
      for (let i = 0; i < vm.accessibleCountries.length; i += 1) {
        vm.accessibleCountries[i].isDefault = i === index;
      }
    }

    function validateItems() {
      vm.defaultCountries = _.filter(vm.accessibleCountries, ['isDefault', true]);
      vm.hasDefaultCountry = vm.defaultCountries && vm.defaultCountries.length > 0;
    }

    function addItem(index, isDefault) {
      if (!index || index === vm.accessibleCountries.length - 1) {
        vm.accessibleCountries.push({
          countryCode: null,
          isDefault: !!isDefault,
        });
      }
    }

    function checkDuplicateCountry(countryCode) {
      return _.some(vm.accessibleCountries, { countryCode });
    }
  }
})();
