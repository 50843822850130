(function () {
    angular
        .module('app')
        .component('hostClaimSubmissionTemplateCreateEditPropertyDefinitionsComponent', {
            templateUrl: require('/App/host/views/claimSubmissionTemplate/createEdit/component/propertyDefinitions.component.html'),
            controller: ['$sce', '$filter', 'abp.services.app.hostServiceRequest', controller],
            controllerAs: 'vm',
            bindings: {
                masterClaimSubmissionCodes: '<',
            }
        });

    function controller($sce, $filter) {
        var vm = this;
    }
})();
