(function () {
    'use strict';

    angular.module('app').component('clinicPanelDashboardMetricsComponent', {
        templateUrl: require('/App/clinic/views/panelDashboard/metrics/metrics.component.html'),
        bindings: {
            header: '<',
            growthIcon: '<',
            metrics: '<',
            growthRate: '<',
            growthDuration: '<',
            loading: '<',
            tooltip: '<',
            onTable: '&',
            isGpEnhanceFeatureFlag: '<',
            isGpPanel: '<'
        }
    });
})();
