(() => {
  angular.module('finance').component('subscriptionInvoiceDetailTransactionViewComponent', {
    templateUrl: require('./subscriptionTransactionView.component.html'),
    controller: SubscriptionInvoiceDetailTransactionViewComponent,
    controllerAs: 'vm',
    bindings: {
      currencyCode: '<',
      invoice: '<',
      invoiceFrom: '<',
      invoiceTo: '<',
      isAddressInvoiceFrom: '<',
      isAddressInvoiceTo: '<',
      message: '<',
      getDateString: '<'
    },
  });

  SubscriptionInvoiceDetailTransactionViewComponent.$inject = [];

  function SubscriptionInvoiceDetailTransactionViewComponent() {
    const vm = this;

    vm.loading = 0;

    init();

    function init() {
      
      // Get subscription details.
      // Reason to selecting first item in array is due to the invoice items returning as an array.
      // As subscription only has one item to be displayed, 
      // the items in the invoice should only be one. 

      [vm.subscription] = vm.invoice.items;
      [vm.taxSummaries] = vm.invoice.taxSummaries;
    }
  }
})();
