(() => {
  angular.module('finance').component('panelLicenseInvoiceDetailDetailedChargesComponent', {
    templateUrl: require('./panelLicenseDetailedCharges.component.html'),
    controller: PanelLicenseInvoiceDetailDetailedChargesComponent,
    controllerAs: 'vm',
    bindings: {
      invoice: '<',
      getDateString: '<'
    },
  });

  PanelLicenseInvoiceDetailDetailedChargesComponent.$inject = [];

  function PanelLicenseInvoiceDetailDetailedChargesComponent() {
    const vm = this;

    vm.loading = 0;
    vm.isDate = function(item) {
      return App.isDate(item);
    };

    init();

    function init() {}
  }
})();
