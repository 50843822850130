(function () {
    var controllerId = 'host.views.specialistDisciplines.createOrEditModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'abp.services.app.specialistDiscipline', 'disciplineId', 'SpecialistDiscipline', 
        function ($scope, $uibModalInstance, specialistDisciplineSvc, disciplineId, constsSpecialistDiscipline) {
            function init() {
                vm.loading++;
                specialistDisciplineSvc.getSpecialistDisciplineForEdit({
                    id: disciplineId
                }).success(function (data) {
                    vm.discipline = data;
                    vm.discipline.symptomTags = splitSymptomsToTags(vm.discipline.symptoms);
                }).finally(function myfunction() {
                    vm.loading--;
                });
            }

            function splitSymptomsToTags(symptoms) {
                var tags = [];

                symptoms = _.trim(symptoms);
                if (symptoms) {
                    var symptomTexts = _.split(symptoms, ',');
                    tags = _.map(symptomTexts, function (t) { return { text: t.trim() }; });
                }

                return tags;
            }

            var vm = this;
		        vm.constsSpecialistDiscipline = constsSpecialistDiscipline;
            vm.loading = 0;
            vm.saving = 0;
            vm.discipline = null;
            vm.isCreate = !disciplineId;

            vm.save = function () {
                vm.discipline.symptoms = vm.discipline.symptomTags ? _.join(_.map(vm.discipline.symptomTags, function (d) { return d.text; }), ', ') : '';
                vm.saving++;
                specialistDisciplineSvc.createOrUpdateSpecialistDiscipline(vm.discipline)
                    .success(function (data) {
                        abp.notify.info(App.localize("SuccessfullySaved"));
                        $uibModalInstance.close(data);
                    }).finally(function () {
                        vm.saving--;
                    });
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            init();
        }
    ]);
})();
