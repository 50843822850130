(() => {
  angular
    .module('revenue')
    .controller('revenue.views.corporateSubscriptions.edit', EditSubscriptionsController);

  EditSubscriptionsController.$inject = [
    '$state',
    '$scope',
    '$stateParams',
    'abp.services.revenue.subscription',
    'abp.services.revenue.subscriptionPlan',
    'abp.services.revenue.common',
    '$uibModal',
    'moment',
    'HmsRevenueConsts.SubscriptionPlan',
    'Hms.Revenue.AgentType',
    'Hms.Revenue.SubscriptionPlanCategory',
    'Hms.Revenue.SubscriptionPlanPricingMode',
    'Hms.Revenue.SubscriptionPlanChargeMode',
    'Hms.Revenue.OutpatientServiceFeatures',
    'Hms.Revenue.InpatientServiceFeatures',
  ];

  function EditSubscriptionsController(
    $state,
    $scope,
    $stateParams,
    subscriptionSvc,
    subscriptionPlanSvc,
    revenueCommonSvc,
    $uibModal,
    moment,
    constsSubscriptionPlan,
    agentTypeEnum,
    enumSubscriptionPlanCategory,
    enumSubscriptionPlanPricingMode,
    enumSubscriptionPlanChargeMode,
    enumOutpatientServiceFeatures,
    enumInpatientServiceFeatures
  ) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;
    vm.currencyCode = 'MYR';
    vm.subscriptionName = '';
    vm.tenantName = '';
    vm.name = '';
    vm.subscriberName = '';
    vm.billingAccountName = '';
    vm.subscriptionId = null;
    vm.corporateId = null;
    vm.scope = null;
    vm.subsidiaryIds = null;
    vm.effectiveStartDate = null;
    vm.effectiveEndDate = null;
    vm.subscriptionPlanId = null;
    vm.billingFrequency = null;
    vm.billingMode = null;
    vm.billingAccountId = null;
    vm.pricingMode = null;
    vm.salesPersonId = null;
    vm.introducerId = null;
    vm.enums = {};
    vm.subsidiaries = [];
    vm.subscriptionPlans = [];
    vm.categoryCharges = [];
    vm.disablePrepaid = false;
    vm.constsSubscriptionPlan = constsSubscriptionPlan;
    vm.enums.SubscriptionPlanCategory = enumSubscriptionPlanCategory;
    vm.enums.subscriptionPlanPricingMode = enumSubscriptionPlanPricingMode;
    vm.enums.subscriptionPlanChargeMode = enumSubscriptionPlanChargeMode;
    vm.enums.outpatientServiceFeatures = enumOutpatientServiceFeatures;
    vm.enums.inpatientServiceFeatures = enumInpatientServiceFeatures;

    vm.serviceFeatures = _.union(
      vm.enums.outpatientServiceFeatures.values,
      vm.enums.inpatientServiceFeatures.values
    );

    vm.subscriptionPlan = {
      planName: null,
      planCategory: null,
      pricingMode: null,
      chargeMode: null,
      effectiveDate: null,
    };

    vm.salesPersonLookup = {
      cachedResults: [],
      activeResults: [],
      lastSearchKey: '',
      lookup(search) {
        this.lastSearchKey = (search || '').trim().toLowerCase();
        const cachedCopy = this.cachedResults[this.lastSearchKey];
        if (cachedCopy) {
          this.activeResults = cachedCopy;
        } else {
          const mgr = this;
          const salesType = [];

          salesType.push(agentTypeEnum.SalesPerson.id);
          salesType.push(agentTypeEnum.CustomerSuccess.id);

          revenueCommonSvc
            .findAgents({
              filter: this.lastSearchKey,
              maxResultCount: 100,
              types: salesType,
              activeOnly: true,
            })
            .success((data) => {
              const dataKey = (data.filter || '').trim().toLowerCase();
              mgr.cachedResults[dataKey] = data.items;
              if (dataKey === mgr.lastSearchKey) {
                mgr.activeResults = data.items;
              }
            });
        }
      },
    };

    vm.introducerLookup = {
      cachedResults: [],
      activeResults: [],
      lastSearchKey: '',
      lookup(search) {
        this.lastSearchKey = (search || '').trim().toLowerCase();
        const cachedCopy = this.cachedResults[this.lastSearchKey];
        if (cachedCopy) {
          this.activeResults = cachedCopy;
        } else {
          const mgr = this;
          const salesType = [];

          salesType.push(agentTypeEnum.Introducer.id);

          revenueCommonSvc
            .findAgents({
              filter: this.lastSearchKey,
              maxResultCount: 100,
              types: salesType,
              activeOnly: true,
            })
            .success((data) => {
              const dataKey = (data.filter || '').trim().toLowerCase();
              mgr.cachedResults[dataKey] = data.items;
              if (dataKey === mgr.lastSearchKey) {
                mgr.activeResults = data.items;
              }
            });
        }
      },
    };

    vm.getSubscriptionPlan = getSubscriptionPlan;
    vm.getServiceFeaturesAsString = getServiceFeaturesAsString;
    vm.getLatestRetailPrice = getLatestRetailPrice;
    vm.getLatestPricingStartDate = getLatestPricingStartDate;
    vm.getSubscriptionPlanCategory = getSubscriptionPlanCategory;
    vm.getChargeMode = getChargeMode;
    vm.getPricingMode = getPricingMode;
    vm.save = save;

    init();

    function init() {
      vm.loading += 1;
      subscriptionSvc
        .getSubscriptionById({
          id: $stateParams.subscriptionId,
        })
        .success((data) => {
          // Set corporate ID first, as some APIs require this.

          vm.corporateId = data.corporateId;

          // Load options.

          loadSubscriptionOptions();
          getSubscriptionPlansDropdown();
          vm.salesPersonLookup.lookup();
          vm.introducerLookup.lookup();

          // Assign values.

          vm.subscriptionId = data.id;
          vm.subscriptionName = data.subscriptionName;
          vm.tenantName = data.tenantName;
          vm.name = data.subscriptionName;
          vm.scope = data.scope;
          vm.subsidiaryIds = data.subsidiaryIds;
          vm.subscriberName = data.subscriberName;
          vm.billingAccountName = data.billingSubsidiaryName;
          vm.effectiveStartDate = moment(data.startDate);
          vm.effectiveEndDate = moment(data.endDate);
          vm.subscriptionPlanId = data.subscriptionPlanId;
          vm.billingFrequency = data.billingCycle;
          vm.billingMode = data.billingMode;
          vm.billingAccountId = data.billingAccountId;
          vm.pricingMode = data.pricingMode;
          vm.salesPersonId = data.salesPersonId;
          vm.introducerId = data.introducerId;

          getSubscriptionPlan(data.categoryCharges);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getSubscriptionPlansDropdown() {
      subscriptionPlanSvc
        .getSubscriptionPlansDropdown({
          corporateId: vm.corporateId,
        })
        .success((data) => {
          vm.subscriptionPlans = data.items;
        });
    }

    function getSubscriptionPlan(categoryCharges) {
      vm.loading += 1;
      if (vm.subscriptionPlanId) {
        subscriptionPlanSvc
          .getSubscriptionPlan({
            id: vm.subscriptionPlanId,
          })
          .success((data) => {
            vm.subscriptionPlan = data;
            vm.currencyCode = data.currencyCode;
            vm.categoryCharges = data.categoryCharges;
            vm.disablePrepaid =
              vm.subscriptionPlan.pricingMode === 3 || vm.subscriptionPlan.pricingMode === 4;

            // Fill category charges if exists.

            if (categoryCharges.length > 0) {
              _.each(categoryCharges, (categoryCharge, i) => {
                vm.categoryCharges[i] = Object.assign(vm.categoryCharges[i], categoryCharge);
              });
            }
          })
          .finally(() => {
            vm.loading -= 1;
          });
      } else {
        vm.subscriptionPlan = null;
        vm.categoryCharges = [];
        vm.currencyCode = '';
        vm.loading -= 1;
      }
    }

    function validateForm() {
      const form = $scope.editForm;
      return App.isFormValid(form);
    }

    function save() {
      if (vm.loading) return;

      if (vm.effectiveStartDate > vm.effectiveEndDate)
        abp.message.error(App.localize('SubscriptionEffectiveEndDateEarlierThanStartDate'));

      if (!validateForm()) {
        abp.message.warn(App.localize('OneOrMoreInputValueIsInvalid'));
        return;
      }

      const input = {
        id: vm.subscriptionId,
        startDate: moment(vm.effectiveStartDate).startOf('day'),
        endDate: moment(vm.effectiveEndDate).startOf('day'),
        subscriptionPlanId: vm.subscriptionPlanId,
        billingCycle: vm.billingFrequency,
        billingMode: vm.billingMode,
        salesPersonId: vm.salesPersonId,
        introducerId: vm.introducerId,
        categoryCharges: [],
      };

      _.each(vm.categoryCharges, (category, i) => {
        input.categoryCharges.push({
          id: category.id ? category.id : i + 1,
          retailPrice: category.retailPrice,
          chargeAmount: category?.chargeAmount,
          minimumHeadcount: category?.minimumHeadcount,
          initialChargeableHeadcount: category?.initialChargeableHeadcount,
        });
      });

      vm.saving += 1;
      subscriptionSvc
        .updateSubscription(input)
        .success(() => {
          $state.go('revenue.subscriptions.detail', {
            subscriptionId: $stateParams.subscriptionId,
          });
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function loadSubscriptionOptions() {
      vm.loading += 1;
      subscriptionSvc
        .getCreateSubscriptionOptions({
          corporateId: vm.corporateId,
        })
        .success((data) => {
          vm.corporate = {
            code: data.corporateCode,
            name: data.corporateName,
          };
          vm.subsidiaries = data.subsidiaries;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getServiceFeaturesAsString(serviceFeaturesArray) {
      const values = [];
      _.forEach(serviceFeaturesArray, (item) => {
        const serviceFeature = _.find(vm.serviceFeatures, (i) => i.id === item);
        values.push(serviceFeature.name);
      });
      return values.join(', ');
    }

    function getLatestRetailPrice(categoryCharges) {
      const latestPricingStartDateOffset = getLatestPricingStartDate(categoryCharges);
      let latestRetailPrice = 0;
      _.each(categoryCharges.subscriptionPlanPricingTiers, (subscriptionPlanPricingTier) => {
        if (subscriptionPlanPricingTier.pricingStartDateOffset === latestPricingStartDateOffset) {
          latestRetailPrice = subscriptionPlanPricingTier.chargeAmount;
          categoryCharges.retailPrice = latestRetailPrice;
        }
      });
      return latestRetailPrice;
    }

    function getLatestPricingStartDate(categoryCharges) {
      let latestPricingStartDateOffset =
        categoryCharges.subscriptionPlanPricingTiers[0].pricingStartDateOffset;

      _.each(categoryCharges.subscriptionPlanPricingTiers, (subscriptionPlanPricingTier) => {
        if (subscriptionPlanPricingTier.pricingStartDateOffset > latestPricingStartDateOffset)
          latestPricingStartDateOffset = subscriptionPlanPricingTier.pricingStartDateOffset;
      });
      return latestPricingStartDateOffset;
    }

    function getSubscriptionPlanCategory(planCategory) {
      if (planCategory === vm.enums.SubscriptionPlanCategory.Outpatient.id) {
        return App.localize('OutpatientServices');
      }
      if (planCategory === vm.enums.SubscriptionPlanCategory.Inpatient.id) {
        return App.localize('InpatientServices');
      }
      if (planCategory === vm.enums.SubscriptionPlanCategory.Adhoc.id) {
        return App.localize('Adhoc');
      }
      return '';
    }

    function getChargeMode(chargeMode) {
      if (chargeMode === vm.enums.subscriptionPlanChargeMode.Monthly.id) {
        return App.localize('Monthly');
      }
      if (chargeMode === vm.enums.subscriptionPlanChargeMode.Yearly.id) {
        return App.localize('Yearly');
      }
      return '';
    }

    function getPricingMode(pricingMode) {
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.Fixed.id) {
        return App.localize('Fixed');
      }
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.PerMember.id) {
        return App.localize('PerMember');
      }
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.PerTicketBasis.id) {
        return App.localize('PerTicketBasis');
      }
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.PerCaseBasis.id) {
        return App.localize('PerCaseBasis');
      }
      return '';
    }
  }
})();
