(() => {
  angular.module('app').filter('subscriptionCharges', subscriptionCharges);

  subscriptionCharges.$inject = ['currencyFormatFilter'];

  function subscriptionCharges(currencyFormat) {
    return subscriptionChargesFilter;

    function subscriptionChargesFilter(charges, currencyCode, chargingBasis, chargeMode) {
      let price = currencyFormat(charges, currencyCode);

      switch (chargingBasis) {
        case 0:
          price += ` / ${App.localize('Fixed')}`;
          break;
        case 1:
          price += ` / ${App.localize('PerMember')}`;
          break;
        case 2:
          price += ` / ${App.localize('PerTicketBasis')}`;
          break;
        case 3:
          price += ` / ${App.localize('PerCaseBasis')}`;
          break;
        // no default
      }

      switch (chargeMode) {
        case 0:
          price += ` / ${App.localize('Monthly')}`;
          break;
        case 1:
          price += ` / ${App.localize('Yearly')}`;
          break;
        // no default
      }

      return price;
    }
  }
})();
