(() => {
  angular
    .module('app')
    .controller('common.views.auditLogs.detailModal', AuditLogDetailModalController);

  AuditLogDetailModalController.$inject = ['$uibModalInstance', 'auditLog', 'moment'];

  function AuditLogDetailModalController($uibModalInstance, auditLog, moment) {
    const vm = this;

    vm.auditLog = auditLog;
    vm.getExecutionTime = getExecutionTime;
    vm.getDurationAsMs = getDurationAsMs;
    vm.getFormattedParameters = getFormattedParameters;
    vm.close = close;

    init();

    function init() {}

    function getExecutionTime() {
      return `${moment(vm.auditLog.executionTime).fromNow()} (${moment(
        vm.auditLog.executionTime
      ).format('L LTS')})`;
    }

    function getDurationAsMs() {
      return App.localize('Xms', vm.auditLog.executionDuration);
    }

    function getFormattedParameters() {
      const data = JSON.parse(vm.auditLog.parameters);
      return JSON.stringify(data, null, 4);
    }

    function close() {
      $uibModalInstance.dismiss();
    }
  }
})();
