(function () {
    var controllerId = 'host.views.impersonationAuditLogs.index';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', 'abp.services.app.impersonationAuditLog', 'moment',
        function ($scope, $state, $stateParams, impersonationAuditLogSvc, moment) {
            var vm = this;

            function init() {
                vm.getAuditLogs();
            }

            $scope.$on('$viewContentLoaded', function () {
                App.initAjax();
            });

            vm.loading = 0;

            // Request parameters setup.

            vm.requestParams = {
                targetTenancyName: $stateParams.targetTenancyName || null,
                targetUserName: $stateParams.targetUserName || null,
                impersonatorTenancyName: $stateParams.impersonatorTenancyName || null,
                impersonatorUserName: $stateParams.impersonatorUserName || null,
                skipCount: $stateParams.skipCount || 0,
                maxResultCount: $stateParams.maxResultCount || app.consts.grid.defaultPageSize
            };

            vm.dateRangeOptions = angular.extend({}, App.createDateRangePickerOptions(), {
                timePicker: true
            });
            vm.dateRangeOptions.locale.format = "L LT";
            vm.dateRangeModel = {
                startDate: moment().startOf('day'),
                endDate: moment().endOf('day')
            };
            if ($stateParams.startDate)
                vm.dateRangeModel.startDate = moment(decodeURIComponent($stateParams.startDate));
            if ($stateParams.endDate)
                vm.dateRangeModel.endDate = moment(decodeURIComponent($stateParams.endDate));

            // Grid table setup.

            vm.gridOptions = {
                appScopeProvider: vm,
                paginationPageSizes: app.consts.grid.defaultPageSizes,
                paginationPageSize: app.consts.grid.defaultPageSize,
                useExternalPagination: true,
                columnDefs: [
                    {
                        enableSorting: false,
                        displayName: App.localize('Time'),
                        field: 'executionTime',
                        cellFilter: 'momentFormat: \'L LTS\'',
                        minWidth: 50,
                        width: 160
                    }, {
                        enableSorting: false,
                        displayName: App.localize('ImpersonatorTenancyName'),
                        field: 'impersonatorTenantName',
                        minWidth: 50
                    }, {
                        enableSorting: false,
                        displayName: App.localize('ImpersonatorUserName'),
                        field: 'impersonatorUserName',
                        minWidth: 50,
                        width: 150
                    }, {
                        enableSorting: false,
                        displayName: App.localize('TargetTenancyName'),
                        field: 'targetTenantName',
                        minWidth: 50
                    }, {
                        enableSorting: false,
                        displayName: App.localize('TargetUserName'),
                        field: 'targetUserName',
                        minWidth: 50,
                        width: 150
                    }, {
                        enableSorting: false,
                        displayName: App.localize('Action'),
                        field: 'impersonationAction',
                        minWidth: 50,
                        width: 80,
                        cellTemplate: 'impersonationActionTemplate'
                    }, {
                        enableSorting: false,
                        displayName: App.localize('IpAddress'),
                        field: 'clientIpAddress',
                        minWidth: 50,
                        width: 130
                    }, {
                        enableSorting: false,
                        displayName: App.localize('ClientTerminal'),
                        field: 'clientName',
                        minWidth: 50,
                        width: 200
                    }, {
                        enableSorting: false,
                        displayName: App.localize('Browser'),
                        field: 'browserInfo',
                        minWidth: 50,
                        width: 200
                    }
                ],
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                    gridApi.pagination.on.paginationChanged($scope, App.setPagination(vm.requestParams, vm.getAuditLogs));
                },
                data: []
            };

            // Operations

            vm.getAuditLogs = function () {
                registerStateParams();
                vm.loading++;

                impersonationAuditLogSvc.getImpersonationAuditLogs($.extend({}, vm.requestParams, vm.dateRangeModel)).success(function (data) {
                    vm.gridOptions.totalItems = data.totalCount;
                    vm.gridOptions.data = data.items;
                }).finally(function (e) {
                    vm.loading--;
                });
            };

            // Private functions.

            function registerStateParams() {
                var startDate = null;
                var endDate = null;
                if (vm.dateRangeModel.startDate)
                    startDate = vm.dateRangeModel.startDate.format();
                if (vm.dateRangeModel.endDate)
                    endDate = vm.dateRangeModel.endDate.format();

                $state.transitionTo($state.current, {
                    impersonatorTenancyName: vm.requestParams.impersonatorTenancyName,
                    impersonatorUserName: vm.requestParams.impersonatorUserName,
                    targetTenancyName: vm.requestParams.targetTenancyName,
                    targetUserName: vm.requestParams.targetUserName,
                    startDate: encodeURIComponent(startDate),
                    endDate: encodeURIComponent(endDate),
                    skipCount: vm.requestParams.skipCount,
                    maxResultCount: vm.requestParams.maxResultCount
                }, {
                    notify: false
                });
            }

            // Initialize.

            init();
        }
    ]);
})();
