import swal from 'sweetalert';

(() => {
  angular
    .module('app')
    .controller('common.views.saleTransactions.paymentDetail', PaymentDetailController);

  PaymentDetailController.$inject = [
    '$stateParams',
    '$window',
    'abp.services.app.saleTransaction',
    'Hms.Payments.PaymentMethod',
  ];

  function PaymentDetailController($stateParams, $window, saleTransactionSvc, enumPaymentMethod) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;

    vm.tenantName = '';
    vm.payment = null;
    vm.invoices = [];
    vm.creditMemos = [];
    vm.excessPayments = [];
    vm.generatedExcessPayment = null;
    vm.isInpatient = App.isInpatient();
    vm.stopInvoiceGeneration = abp.setting.getBoolean('Hms.Feature.StopPortalInvoiceGeneration');

    vm.enums = {
      paymentMethod: enumPaymentMethod,
    };

    vm.permissions = {
      editPayment: abp.auth.isGranted('SaleTransactions.Host.EditPayment'),
      deletePayment: abp.auth.isGranted('SaleTransactions.Host.DeletePayment'),
    };

    vm.getAppliedAmount = getAppliedAmount;
    vm.delete = deletePayment;
    vm.send = send;

    init();

    function init() {
      vm.loading += 1;
      saleTransactionSvc
        .getPayment({
          id: $stateParams.transactionNumber,
        })
        .success((data) => {
          vm.tenantName = data.tenantName;
          vm.invoices = data.invoices;
          vm.creditMemos = data.creditMemos;
          vm.excessPayments = data.excessPayments;
          vm.payment = data.payment;
          if (vm.excessPayments.length > 0) {
            vm.generatedExcessPayment = _.find(vm.excessPayments, (d) => d.amount > 0);
          }
          vm.currencyCode = data.currencyCode;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getAppliedAmount() {
      let amount = 0;
      for (let i = 0; i < vm.invoices.length; i += 1) {
        amount += vm.invoices[i].appliedAmount;
      }
      return amount;
    }

    function deletePayment() {
      abp.message.confirm(
        App.localize('DeletePaymentWarningMessage', vm.payment.transactionNumber),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            saleTransactionSvc
              .deletePayment({
                id: vm.payment.transactionNumber,
              })
              .success(() => {
                abp.notify.success(App.localize('SuccessfullyDeleted'));
                $window.history.back();
              });
          }
        }
      );
    }

    function send() {
      saleTransactionSvc
        .getFinanceEmails({
          tenantId: vm.payment.tenantId,
          subsidiaryId: vm.payment.subsidiaryId,
        })
        .success((data) => {
          swal(
            {
              title: App.localize('SendEmailForX', vm.payment.transactionNumber),
              text: App.localize('EmailAddresses'),
              type: 'input',
              showCancelButton: true,
              closeOnConfirm: false,
              confirmButtonColor: '#1ab394',
              inputPlaceholder: App.localize('EmailAddressesNarrative'),
              inputValue: data.emailAddresses,
              showLoaderOnConfirm: true,
            },
            (inputValue) => {
              if (inputValue === false) return false;
              if (inputValue === '') {
                swal.showInputError(App.localize('InvalidEmailAddress'));
                return false;
              }
              saleTransactionSvc
                .sendEmail({
                  transactionNumber: vm.payment.transactionNumber,
                  emailAddresses: inputValue,
                })
                .success(() => {
                  abp.notify.info(App.localize('EmailSent'));
                  swal.close();
                });
              return false;
            }
          );
        });
    }
  }
})();
