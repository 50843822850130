(function () {
    var controllerId = 'host.views.drugs.importDrugsResultModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'errors',
        function ($scope, $uibModalInstance, errors) {
            var vm = this;
            vm.errorCountText = App.localize("ImportFileHasXIssues", errors.length);
            vm.errorText = errors.join("\n");

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };
        }
    ]);
})();
