(() => {
  angular
    .module('app')
    .controller(
      'common.views.saleTransactions.lowBalanceNotificationLogs',
      LowBalanceNotificationLogsController
    );

  LowBalanceNotificationLogsController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'abp.services.app.saleTransaction',
    'appConstants',
  ];

  function LowBalanceNotificationLogsController(
    $scope,
    $state,
    $stateParams,
    saleTransactionSvc,
    appConstants
  ) {
    const vm = this;

    vm.loading = 0;
    vm.exporting = 0;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.stopInvoiceGeneration = abp.setting.getBoolean('Hms.Feature.StopPortalInvoiceGeneration');

    vm.requestParams = {
      filter: $stateParams.filter || null,
      skipCount: $stateParams.skipCount || 0,
      maxResultCount: $stateParams.maxResultCount || appConstants.grid.defaultPageSize,
    };

    // Define grid options

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSizes,
      paginationPageSize: appConstants.grid.defaultPageSize,
      useExternalPagination: true,
      columnDefs: [
        {
          displayName: App.localize('Time'),
          field: 'creationTime',
          cellFilter: "momentFormat: 'L LTS'",
          enableSorting: false,
          minWidth: 100,
        },
        {
          name: App.localize('Corporate'),
          field: 'corporateName',
          enableSorting: false,
          minWidth: 200,
        },
        {
          name: `${App.localize('Balance')} (%)`,
          field: 'balancePercentage',
          enableSorting: false,
          minWidth: 100,
          cellFilter: 'number: 2',
        },
        {
          name: App.localize('AvailableBalance'),
          field: 'availableAmount',
          enableSorting: false,
          minWidth: 100,
          cellFilter: 'currencyFormat: grid.appScope.currencyCode',
        },
        {
          name: App.localize('EscrowAmount'),
          field: 'escrowAmount',
          enableSorting: false,
          minWidth: 100,
          cellFilter: 'currencyFormat: grid.appScope.currencyCode',
        },
        {
          name: App.localize('CorporateLimit'),
          field: 'corporateLimit',
          enableSorting: false,
          minWidth: 100,
          cellFilter: 'currencyFormat: grid.appScope.currencyCode',
        },
        {
          name: App.localize('OpenBalance'),
          field: 'openBalance',
          enableSorting: false,
          minWidth: 100,
          cellFilter: 'currencyFormat: grid.appScope.currencyCode',
        },
        {
          name: App.localize('UnbilledAmount'),
          field: 'unbilledAmount',
          enableSorting: false,
          minWidth: 100,
          cellFilter: 'currencyFormat: grid.appScope.currencyCode',
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, vm.getLowBalanceNotificationLogs)
        );
      },
      data: [],
    };

    vm.getLowBalanceNotificationLogs = getLowBalanceNotificationLogs;
    vm.exportToExcel = exportToExcel;

    init();

    function init() {
      vm.getLowBalanceNotificationLogs();
    }

    function registerStateParams() {
      $state.transitionTo(
        $state.current,
        {
          filter: vm.requestParams.filter,
          skipCount: vm.requestParams.skipCount,
          maxResultCount: vm.requestParams.maxResultCount,
        },
        {
          notify: false,
        }
      );
    }

    function getLowBalanceNotificationLogs() {
      registerStateParams();
      vm.loading += 1;

      saleTransactionSvc
        .getLowBalanceNotificationLogs(vm.requestParams)
        .success((data) => {
          vm.gridOptions.totalItems = data.totalCount;
          vm.gridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function exportToExcel() {
      const option = vm.requestParams;
      option.maxResultCount = appConstants.maxResultCount;
      vm.exporting += 1;
      saleTransactionSvc
        .getLowBalanceNotificationLogsToExcel(option)
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.exporting -= 1;
        });
    }
  }
})();
