(function () {
    var controllerId = 'host.views.mimsClass.index';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', '$uibModal', '$timeout', 'abp.services.app.mimsClass',
        function ($scope, $state, $stateParams, $modal, $timeout, mimsClassSvc) {
            function init() {
                $timeout(function () {
                    vm.mimsClassGridOptions.paginationCurrentPage = Math.floor(vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1);
                });
            }

            var vm = this;
            vm.loading = 0;

            $scope.$on("$viewContentLoaded", function () {
                App.initAjax();
            });

            vm.requestParams = {
                filter: $stateParams.filterText || '',
                skipCount: $stateParams.skipCount,
                maxResultCount: $stateParams.maxResultCount || app.consts.grid.defaultPageSize,
                sorting: $stateParams.sorting
            };

            // Assign permissions

            vm.permissions = {
                create: abp.auth.isGranted("Host.MimsClass.Create"),
                edit: abp.auth.isGranted("Host.MimsClass.Edit"),
                "delete": abp.auth.isGranted("Host.MimsClass.Delete")
            };

            // Define grid options

            vm.mimsClassGridOptions = {
                appScopeProvider: vm,
                paginationPageSizes: app.consts.grid.defaultPageSize,
                paginationPageSize: app.consts.grid.defaultPageSize,
                paginationCurrentPage: 0,
                useExternalPagination: true,
                useExternalSorting: true,
                columnDefs: [{
                    enableSorting: false,
                    name: App.localize('Actions'),
                    width: 100,
                    headerCellTemplate: "<span></span>",
                    cellTemplate: 'actionTemplate'
                }, {
                    displayName: App.localize('SubClass'),
                    field: 'subClass',
                    minWidth: 300
                }, {
                    displayName: App.localize('MainClass'),
                    field: 'mainClass',
                    minWidth: 300
                }],
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                    $scope.gridApi.core.on.sortChanged($scope, function (scope, callback) {
                        var sorting = "";
                        for (var i = 0; i < callback.length; i++) {
                            if (i) sorting += ", ";
                            sorting += callback[i].field + " " + callback[i].sort.direction;
                        }
                        vm.requestParams.sorting = sorting;
                        vm.getMimsClasses();
                    });
                    gridApi.pagination.on.paginationChanged($scope, App.setPagination(vm.requestParams, vm.getMimsClasses));
                },
                data: []
            };

            // Shift out action column if there's no edit actions

            vm.permissions.edit || vm.permissions.delete || vm.mimsClassGridOptions.columnDefs.shift();

            // Define funtions

            function registerStateParams() {
                $state.transitionTo($state.current, {
                    skipCount: vm.requestParams.skipCount,
                    maxResultCount: vm.requestParams.maxResultCount,
                    sorting: vm.requestParams.sorting,
                    filterText: vm.requestParams.filter
                }, {
                        notify: false
                    });
            }

            vm.getMimsClasses = function () {
                registerStateParams();

                vm.loading++;
                mimsClassSvc.getMimsClasses(vm.requestParams).success(function (data) {
                    vm.mimsClassGridOptions.totalItems = data.totalCount;
                    vm.mimsClassGridOptions.data = data.items;
                }).finally(function (e) {
                    vm.loading--;
                });
            };

            /* Create or Edit MIMS Class Functions */

            function showCreateEditModal(mimsClassId) {
                var modal = $modal.open({
                    templateUrl: require('/App/host/views/mimsClasses/createOrEditModal.html'),
                    controller: "host.views.mimsClass.createOrEditModal as vm",
                    backdrop: "static",
                    resolve: {
                        mimsClassId: mimsClassId
                    }
                });
                modal.result.then(function () {
                    vm.getMimsClasses();
                });
            }

            vm.createMimsClass = function () {
                showCreateEditModal();
            };

            vm.editMimsClass = function (mimsClassId) {
                showCreateEditModal(mimsClassId);
            };

            /* End of Create or Edit MIMS Class Functions */

            vm.deleteMimsClass = function (mimsClass) {
                abp.message.confirm(App.localize('DeleteMimsClassWarningMessage', mimsClass.subClass), App.localize('AreYouSure'), function (d) {
                    if (d) {
                        mimsClassSvc.deleteMimsClass({
                            id: mimsClass.id
                        }).success(function (data) {
                            vm.getMimsClasses();
                            abp.notify.success(App.localize("SuccessfullyDeleted"));
                        });
                    }
                });
            };

            init();
        }
    ]);
})();
