(function () {
  'use strict';

  angular.module('app').component('hospitalPatientCardComponent', {
    templateUrl: require('/App/clinic/views/hospital/patient/patientCard/patientCard.component.html'),
    controller: ['$state', '$uibModal', 'abp.services.app.hospitalPanelServiceRequest', 'abp.services.app.inpatientEmployee', 'Hms.GuaranteeLetters.GuaranteeLetterStatus',
      'Hms.ServiceRequests.ServiceRequestStatus', 'Hms.Services.ServiceType', 'Hms.ServiceRequests.ServiceRequestType', 'moment',
      controller
    ],
    controllerAs: 'vm',
    bindings: {
      employee: '<',
      panelLocationId: '<',
      getEmployees: '&',
      disciplines: '<'
    }
  });

  function controller($state, $uibModal, hospitalPanelServiceRequestSvc, inpatientEmployeeSvc, enumGuaranteeLetterStatus, enumServiceRequestStatus, enumServiceType, enumServiceRequestType, moment) {
    function init() {
      getTickets();
    }

    var vm = this;
    vm.loading = 0;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.panelNumber = abp.setting.get('Hms.General.SpecialistSupportContactNumber');
    vm.pendingApprovalGlNarrative = App.localize('PendingApprovalGuaranteeLetterNarrative', vm.panelNumber);
    vm.hasSpecialistWorkflowEnhancement = abp.setting.getBoolean('Hms.Feature.SpecialistWorkflowEnhancement');

    vm.enums = {};
    vm.enums.serviceRequestStatus = enumServiceRequestStatus;
    vm.enums.guaranteeLetterStatus = enumGuaranteeLetterStatus;
    vm.enums.serviceType = enumServiceType;
    vm.enums.serviceRequestType = enumServiceRequestType;

    // Set the initial value of employeeNoAccessMessage to null.
    vm.employeeNoAccessMessage = null;

    // Check if the employee has access to hospitalization or maternity.
    if (!vm.employee.hasHospitalizationAccess && !vm.employee.hasMaternityAccess) {
      // Set the employeeNoAccessMessage when there is no access to both services.
      vm.employeeNoAccessMessage = App.localize('PanelCannotApplyGLDueToOrgPolicy');
    } else if (!vm.employee.hasHospitalizationAccess && vm.employee.hospitalizationBenefitGroupId) {
      // Set the employeeNoAccessMessage for the case of no hospitalization access.
      vm.employeeNoAccessMessage = App.localize(
        'PanelCannotApplyXGLDueToOrgPolicy',
        App.localize(vm.enums.serviceType.Hospitalization.name)
      );
    } else if (!vm.employee.hasMaternityAccess && vm.employee.maternityBenefitGroupId) {
      // Set the employeeNoAccessMessage for the case of no maternity access.
      vm.employeeNoAccessMessage = App.localize(
        'PanelCannotApplyXGLDueToOrgPolicy',
        App.localize(vm.enums.serviceType.Maternity.name)
      );
    }

    vm.checkInEmployee = checkInEmployee;
    vm.showCancelModal = showCancelModal;
    vm.getDateString = getDateString;

    /* Employee icon */

    vm.getUserBgColor = function () {
      return 'user_bgcolor_' + ((vm.employee.id || 0) % 8 + 1);
    };

    vm.getUserInitial = function () {
      var fullName = (vm.employee.name || ' ').split(' ');
      var initial = '';
      for (var i = 0; i < fullName.length; i++) {
        if (fullName[i].length) {
          initial += fullName[i][0];
        }
      }
      if (initial && initial.length > 3) initial = '' + initial[0];
      return initial;
    };

    /* End of employee icon */

    /* Functions */

    function checkInEmployee() {
      var today = moment().format('YYYY-MM-DD');
      var scheduledTime = moment(vm.employee.guaranteeLetter.scheduledAdmissionTime).format('YYYY-MM-DD');
      if (!scheduledTime)
        return;

      if (scheduledTime === today) {
        swal({
          title: App.localize('ConfirmAdmission'),
          text: App.localize('ConfirmAdmissionNarrative'),
          confirmButtonColor: '#1ab394',
          confirmButtonText: App.localize('Yes'),
          cancelButtonText: App.localize('No'),
          showCancelButton: true,
          closeOnConfirm: false,
        }, function (isConfirm) {
          if (isConfirm) {
            checkIn('');
            vm.employee.isGLSubmittedOrApproved = false;
            vm.employee.checkedInTime = vm.employee.guaranteeLetter.scheduledAdmissionTime;
          }
          swal.close();
        });
      } else {
        $uibModal.open({
          templateUrl: require('/App/clinic/views/hospital/patient/admission/admission.modal.html'),
          controller: 'clinic.views.hospital.patient.admission as vm',
          backdrop: 'static',
          resolve: {
            scheduleDate: function () {
              return vm.employee.guaranteeLetter.scheduledAdmissionTime;
            },
            expiryDate: function () {
              return vm.employee.guaranteeLetter.expiryTime;
            },
            letterNumber: function () {
              return vm.employee.guaranteeLetter.glNumber;
            }
          }
        }).result.then(function (data) {
          if (data.isCheckIn)
            checkIn(data);
        });
      }
    }

    function checkIn(data) {
      vm.loading++;
      hospitalPanelServiceRequestSvc.checkIn({
        guaranteeLetterNumber: vm.employee.guaranteeLetter.glNumber,
        remarks: data.reason,
        actualAdmissionDate: data.actualAdmissionDate,
        newAttachments: data.newAttachments
      }).success(function (data) {
        if (data.printUrl != "") {
          abp.notify.info(App.localize('PatientXCheckedIn', vm.employee.name));
          window.open(data.printUrl);
          goToTimeline();
        } else {
          if (data.isEarlyCheckIn)
            abp.notify.info(App.localize('EarlyCheckInRequestSubmitted'));
          else
            abp.notify.info(App.localize('LateCheckInRequestSubmitted'));
          vm.refreshFunction();
        }
      }).finally(function () {
        vm.loading--;
      });
    }

    function goToTimeline() {
      $state.go('clinic.patientAdmissionDetails', {
        'letterNumber': vm.employee.guaranteeLetter.glNumber
      });
    }

    function showCancelModal(letterNumber, letterRequestType, letterStatus) {
      $uibModal.open({
        templateUrl: require('/App/clinic/views/hospital/common/cancellationModal/cancel.modal.html'),
        controller: 'clinic.views.hospital.common.cancelModal as vm',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          letterRequestType: function () {
            return letterRequestType;
          },
          letterStatus: function () {
            return letterStatus;
          }
        }
      }).result.then(function (cancellationReason) {
        if (cancellationReason) {
          var input = {
            letterNumber: letterNumber,
            cancellationReason: cancellationReason
          };

          vm.loading++;

          hospitalPanelServiceRequestSvc.cancelGuaranteeLetterServiceRequest(input)
            .success(function (data) {
              abp.notify.success("Cancelled");
            })
            .finally(function (data) {
              vm.loading--;
              vm.getEmployees();
            });
        }
      });
    }

    function getTickets() {
      vm.loading++;
      inpatientEmployeeSvc.getPreviousVisits({
        id: vm.employee.id
      }).success(function (data) {
        vm.patientTickets = data

        _.forEach(vm.patientTickets, function (item) {
          item.disciplineId = _.transform(item.disciplineId, function (result, disciplineId) {
            _(vm.disciplines).forEach(function (specialistIdName) {
              if (disciplineId === specialistIdName.value) {
                return result.push(specialistIdName.name);
              }
            });
          }).join(', ');
        });

        _.forEach(vm.patientTickets, function (item) {
          if (item.finalDiagnoses.length > 0) {
            item.finalDiagnoses = _.reduce(item.finalDiagnoses, function (first, second) {
              return first + ', ' + second;
            });
          }
        });
      }).finally(function (e) {
        vm.loading--;
      });
    }

    function getDateString(date) {
      return App.getDateString(moment.utc(date));
    }

    /* End of Functions */

    init();
  }
})();
