(function () {
    var controllerId = 'host.views.industries.createOrEditModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'abp.services.app.industry', 'industryId', 'Hms.Industries.Industry', 
        function ($scope, $uibModalInstance, industrySvc, industryId, constsIndustry) {
            function init() {
                vm.loading++;
                industrySvc.getIndustryForEdit({
                    id: industryId
                }).success(function (data) {
                    vm.industry = data;
                }).finally(function myfunction() {
                    vm.loading--;
                })
            }

            var vm = this;
            vm.constsIndustry = constsIndustry;
            vm.loading = 0;
            vm.saving = 0;
            vm.industry = null;

            vm.save = function () {
                vm.saving++;
                industrySvc.createOrUpdateIndustry(vm.industry).success(function (data) {
                    abp.notify.info(App.localize("SuccessfullySaved"));
                    $uibModalInstance.close(data);
                }).finally(function () {
                    vm.saving--;
                });
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            init();
        }
    ]);
})();
