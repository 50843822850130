import swal from 'sweetalert';

(() => {
  angular
    .module('app')
    .controller(
      'common.views.patientTickets.editMemberUtilization.editMemberUtilization',
      EditMemberUtilizationController
    );

  EditMemberUtilizationController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$sce',
    'abp.services.app.memberUtilization',
  ];

  function EditMemberUtilizationController(
    $scope,
    $state,
    $stateParams,
    $sce,
    memberUtilizationSvc
  ) {
    const vm = this;

    vm.loading = 0;
    vm.tabType = null;
    vm.hospitalizationExists = false;
    vm.maternityExists = false;
    vm.isSelected = null;
    vm.ticketNumber = $stateParams.ticketNumber;
    vm.currencySymbol = abp.setting.get('Hms.General.CurrencyCode');
    vm.isHost = App.isHost();
    vm.targetUtilizationServiceType = 0;
    vm.services = [];
    vm.mainUtilizationText = '';
    vm.targetUtilizationText = '';
    vm.tabType = null;
    vm.isGPOrSpecialist = null;

    vm.getUtilizationOrganizationServiceText = getUtilizationOrganizationServiceText;
    vm.setTabType = setTabType;
    vm.getNarrativeMessage = getNarrativeMessage;
    vm.getNoExistingAdmissionMessage = getNoExistingAdmissionMessage;
    vm.ticketSelected = ticketSelected;
    vm.getTargetUtilizationText = getTargetUtilizationText;
    vm.validateUtilization = validateUtilization;
    vm.save = save;

    init();

    function init() {
      getMemberUtilizations();
    }

    function getMemberUtilizations() {
      vm.loading += 1;
      memberUtilizationSvc
        .getMemberUtilizationsForEdit({
          patientTicketNumber: vm.ticketNumber
        })
        .success((data) => {
          vm.hasInsurer = data.hasInsurer;
          vm.mainTicket = data.mainTicket;
          vm.utilizations = data.utilizationDtos;
          vm.serviceTypes = data.serviceTypes;
          vm.preHospitalizationIsCovered = data.preHospitalizationIsCovered;
          vm.postHospitalizationIsCovered = data.postHospitalizationIsCovered;
          vm.preNatalIsCovered = data.preNatalIsCovered;
          vm.postNatalIsCovered = data.postNatalIsCovered;
          vm.employeeOrganizationId = data.employeeOrganizationId;

          // Check ticket serviceType is GP or specialisst

          vm.isGPOrSpecialist =
            data.mainTicket.serviceType === 1 
            || data.mainTicket.serviceType === 5 
            || data.mainTicket.serviceType === 10;

          // Set to 'Other' tab when the other tabs aren't available
          // or ticket serviceType is not GP and not Specialist
          if (
            (!vm.preHospitalizationIsCovered &&
              !vm.postHospitalizationIsCovered &&
              !vm.preNatalIsCovered &&
              !vm.postNatalIsCovered) ||
            !vm.isGPOrSpecialist
          )
            vm.tabType = 'Other';

          vm.days = {
            preHospitalizationDays: data.preHospitalizationAdmissions.admissionDays,
            postHospitalizationDays: data.postHospitalizationAdmissions.admissionDays,
            preNatalDays: data.preNatalAdmissions.admissionDays,
            postNatalDays: data.postNatalAdmissions.admissionDays,
          };

          // Binding tickets
          vm.preHospitalizationTickets = data.preHospitalizationAdmissions.tickets;
          vm.postHospitalizationTickets = data.postHospitalizationAdmissions.tickets;
          vm.preNatalTickets = data.preNatalAdmissions.tickets;
          vm.postNatalTickets = data.postNatalAdmissions.tickets;

          // Check if there is tickets

          vm.haspreHospitalizationTickets = data.preHospitalizationAdmissions.tickets.length > 0;
          vm.haspostHospitalizationTickets = data.postHospitalizationAdmissions.tickets.length > 0;
          vm.haspreNatalTickets = data.preNatalAdmissions.tickets.length > 0;
          vm.haspostNatalTickets = data.postNatalAdmissions.tickets.length > 0;

          // Assigning first util row
          vm.mainUtilization = angular.copy(
            _.find(
              data.utilizationDtos,
              (u) => u.organizationServiceType === data.mainTicket.serviceType
            )
          );

          vm.mainUtilizationText = '';
          if (vm.mainUtilization) {
            vm.getUtilizationOrganizationServiceText(
              'main',
              vm.mainUtilization.organizationServiceType
            );
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getUtilizationOrganizationServiceText(utilizationTextType, serviceType) {
      // If utilizationTextType = 'main', save to vm.mainUtilizationText.
      // Else save to vm.targetUtilizationText.

      memberUtilizationSvc
        .getUtilizationOrganizationServiceText({
          organizationServiceType: serviceType,
          employeeOrganizationId: vm.employeeOrganizationId,
        })
        .success((data) => {
          if (utilizationTextType === 'main') {
            vm.mainUtilizationText = data;
          } else {
            vm.targetUtilizationText = data;
          }
        });
    }

    function setTabType(value) {
      if (value) {
        vm.tabType = value;
        if (vm.isTicketSelected) vm.isTicketSelected.isSelected = false;
        vm.isTicketSelected = null;
        vm.isSelected = false;
        vm.getTargetUtilizationText();
      }
    }

    function getNarrativeMessage() {
      let narrativeMessage = '';
      if (vm.days) {
        switch (vm.tabType) {
          case 'PreHospitalization':
            narrativeMessage = `${App.localize(
              'OnlyAdmissionsWithinNarrativeAfter',
              vm.days.preHospitalizationDays
            )}`;
            break;
          case 'PostHospitalization':
            narrativeMessage = `${App.localize(
              'OnlyAdmissionsWithinNarrativeBefore',
              vm.days.postHospitalizationDays
            )}`;
            break;
          case 'PreNatal':
            narrativeMessage = `${App.localize(
              'OnlyAdmissionsWithinNarrativeAfter',
              vm.days.preNatalDays
            )}`;
            break;
          case 'PostNatal':
            narrativeMessage = `${App.localize(
              'OnlyAdmissionsWithinNarrativeBefore',
              vm.days.postNatalDays
            )}`;
            break;

          // no default
        }
      }
      return $sce.trustAsHtml(narrativeMessage);
    }

    function getNoExistingAdmissionMessage() {
      let narrativeMessage = '';
      if (vm.days) {
        switch (vm.tabType) {
          case 'PreHospitalization':
            narrativeMessage = `${App.localize(
              'NoExistingAdmissionsWithinNarrativeAfter',
              vm.days.preHospitalizationDays
            )}`;
            break;
          case 'PostHospitalization':
            narrativeMessage = `${App.localize(
              'NoExistingAdmissionsWithinNarrativeBefore',
              vm.days.postHospitalizationDays
            )}`;
            break;
          case 'PreNatal':
            narrativeMessage = `${App.localize(
              'NoExistingAdmissionsWithinNarrativeAfter',
              vm.days.preNatalDays
            )}`;
            break;
          case 'PostNatal':
            narrativeMessage = `${App.localize(
              'NoExistingAdmissionsWithinNarrativeBefore',
              vm.days.postNatalDays
            )}`;
            break;

          // no default
        }
      }
      return $sce.trustAsHtml(narrativeMessage);
    }

    function ticketSelected() {
      let ticketList = [];
      if (vm.tabType === 'PreHospitalization') ticketList = vm.preHospitalizationTickets;
      if (vm.tabType === 'PostHospitalization') ticketList = vm.postHospitalizationTickets;
      if (vm.tabType === 'PreNatal') ticketList = vm.preNatalTickets;
      if (vm.tabType === 'PostNatal') ticketList = vm.postNatalTickets;

      const ticket = _.find(ticketList, (d) => d.isSelected);
      vm.isSelected = !!ticket;
      vm.isTicketSelected = ticket;
    }

    function getTargetUtilizationText() {
      vm.targetUtilizationText = '';
      if (vm.tabType === 'PreHospitalization' || vm.tabType === 'PostHospitalization') {
        vm.targetUtilizationText = App.localize('HospitalizationServiceUtilizationAmount');
        vm.targetUtilizationServiceType = 7;
      } else if (vm.tabType === 'PreNatal' || vm.tabType === 'PostNatal') {
        vm.targetUtilizationText = App.localize('MaternityServiceUtilizationAmount');
        vm.targetUtilizationServiceType = 8;
      } else if (vm.tabType === 'Other' && vm.selectedService) {
        vm.getUtilizationOrganizationServiceText('target', vm.selectedService);
        vm.targetUtilizationServiceType = vm.selectedService;
      }

      if (vm.targetUtilizationServiceType > 0) {
        vm.targetUtilization = angular.copy(
          _.find(
            vm.utilizations,
            (u) => u.organizationServiceType === vm.targetUtilizationServiceType
          )
        );

        // If selected utilization does not exist, initialize as member utilization dto with
        // null amount and selected serviceType.

        if (vm.targetUtilization == null) {
          vm.targetUtilization = {
            amount: null,
            organizationServiceType: vm.targetUtilizationServiceType,
          };
        }
      }
    }

    function validateUtilization() {
      // Get current total utilization to compare against original covered amount

      const currentTotalUtilization = vm.mainUtilization.amount + vm.targetUtilization.amount;
      const isValid = currentTotalUtilization <= vm.mainTicket.coveredAmount;

      // Set validity of the element according to isValid condition.

      $scope.editUtilizationForm.mainUtilizationAmount.$setValidity('invalid', isValid);
      $scope.editUtilizationForm.targetUtilizationAmount.$setValidity('invalid', isValid);

      // Update element to touched(ng-touched) so that is properly shown.

      $scope.editUtilizationForm.mainUtilizationAmount.$setTouched();
      $scope.editUtilizationForm.targetUtilizationAmount.$setTouched();
    }

    function save() {
      vm.adjustedUtilizations = [];
      vm.adjustedUtilizations.push(vm.mainUtilization);
      vm.adjustedUtilizations.push(vm.targetUtilization);
      swal(
        {
          title: `${App.localize('ConfirmAdjustment')}?`,
          text: App.localize('ConfirmAdjustmentNarrative'),
          type: 'warning',
          confirmButtonColor: '#1ab394',
          confirmButtonText: App.localize('Yes'),
          cancelButtonText: App.localize('No'),
          showCancelButton: true,
          closeOnConfirm: false,
        },
        (isConfirm) => {
          if (isConfirm) {
            memberUtilizationSvc
              .updateUtilizationUsage({
                panelClaimId: vm.mainTicket.ticketId,
                relatedAdmissionPanelClaimId: vm.isTicketSelected ? vm.isTicketSelected.id : null,
                editUtilizationTypeText: vm.tabType,
                remarks: vm.utilizationAdjustmentRemarks,
                memberUtilizations: vm.adjustedUtilizations,
                overwritePreviousBenefitGroupWithCurrent: false,
              })
              .success(() => {
                abp.notify.success(App.localize('SuccessfullySaved'));
                swal.close();
                $state.go('patientTickets');
              });
          }
        }
      );
    }
  }
})();
