(function () {
    var controllerId = 'corporate.views.clinicsReport.index';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', 'abp.services.app.report', 'abp.services.app.commonLookup', 'appSession', 'Hms.Services.ServiceType', 'Hms.PatientTickets.PatientTicketType', 'moment',
        function ($scope, $state, $stateParams, reportSvc, commonLookupSvc, appSession, enumServiceType, enumPatientTicketType, moment) {
            function init() {
                initServiceTypes();

                // TODO: Replace currency code after enabling cross-country.
                getTenantCurrencyCode();

                vm.lookupClinics(null, $stateParams.clinic);
                vm.lookupClinicLocations(null, $stateParams.clinicLocation);
                lookupBenefitGroups();
                lookupSubsidiaries();
                lookupCTs();
                findEmployeeFields();
                vm.getClinicsReport();
            }

            var vm = this;
            vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
            vm.hasSpecialistModule = abp.features.isEnabled('Specialist.Enabled');
            vm.hasPharmacyModule = abp.features.isEnabled('Pharmacy.Enabled');
            vm.hasInpatientModule = abp.features.isEnabled('HasInpatientModule');
            vm.isSubsidiary = appSession.user.subsidiaryId;
            vm.isSubsidiaryDepartment = appSession.user.subsidiaryDepartmentId;
            vm.financialYears = _.range(appSession.tenant.currentFinancialYear, appSession.tenant.firstFinancialYear - 1, -1);
            vm.exporting = 0;
            vm.hasInsuranceModule = abp.setting.getBoolean('Hms.Feature.InsuranceModule')
                && abp.features.isEnabled('HasInsurance');

            vm.enums = {};
            vm.enums.serviceType = enumServiceType;
            vm.enums.patientTicketType = enumPatientTicketType;

            $scope.$on('$viewContentLoaded', function () {
                App.initAjax();
            });

            vm.clinics = [];
            vm.lookupClinics = function (search, id) {
                if (search || id) {
                    commonLookupSvc.findPatientTicketClinics({
                        id: id,
                        filter: search,
                        maxResultCount: 5
                    }).success(function (data) {
                        vm.clinics = data.items;
                    }).finally(function () {
                        if (!vm.clinics.length)
                            vm.requestParams.clinic = null;
                    });
                }
            };

            vm.clinicLocations = [];
            vm.lookupClinicLocations = function (search, id) {
                if (search || id) {
                    commonLookupSvc.findPatientTicketClinicLocations({
                        clinic: vm.requestParams.clinic,
                        id: id,
                        filter: search,
                        maxResultCount: 5
                    }).success(function (data) {
                        vm.clinicLocations = data.items;
                    }).finally(function () {
                        if (!vm.clinicLocations.length)
                            vm.requestParams.clinicLocation = null;
                    });
                }
            };

            vm.benefitGroups = [];
            function lookupBenefitGroups() {
                commonLookupSvc.findBenefitGroups({
                    maxResultCount: 999
                }).success(function (data) {
                    vm.benefitGroups = data.items;
                });
            }

            vm.subsidiaries = [];

            function lookupSubsidiaries() {
                commonLookupSvc.getCorporateSubsidiaries({ getDeletedSubsidiariesOrDepartment: true }).success(function (data) {
                    vm.subsidiaries = data.items;
                });
            }

            vm.departments = [];
            var allDepartments = [];
            vm.getCorporateSubsidiaryDepartments = function () {
                if (!allDepartments.length) {
                    commonLookupSvc.getCorporateSubsidiaryDepartments({ getDeletedSubsidiariesOrDepartment: true }).success(function (data) {
                        allDepartments = data.items;
                    });
                }
            };

            vm.getDepartments = function (search) {
                var subsidiaryIds = vm.isSubsidiary
                    ? appSession.user.subsidiaryId
                    : vm.requestParams.subsidiaryIds;

                if (subsidiaryIds.length > 0) {
                    vm.departments = [];

                    // Change department filter list according to selected subsidiaries.

                    _.forEach(subsidiaryIds, function (id) {
                        var d = _.filter(allDepartments, { 'subsidiaryId': id });
                        vm.departments = _.concat(vm.departments, d);
                    });
                } else {
                    vm.departments = allDepartments;
                }

                if (search) {
                    vm.departments = _.filter(vm.departments, function (d) {
                        return _.includes(d.name.toLowerCase(), search.toLowerCase())
                    });

                    // Only return top 10 in the department list for display.

                    vm.departments = _.slice(vm.departments, 0, 10);
                } else {
                    vm.departments = [];
                }
            };

            vm.resetDepartments = function () {
                vm.requestParams.departmentIds = [];

                vm.departments = [];
            };

            vm.isClinic = App.isClinic();

            vm.employeeFields = [];
            function findEmployeeFields() {
                commonLookupSvc.findEmployeeFields().success(function (data) {
                    if (data.items) {
                        vm.employeeFields = data.items;
                    }
                });
            }

            vm.cts = [];
            function lookupCTs() {
                reportSvc.findCTs().success(function (data) {
                    vm.cts = data.items;
                });
            }

            vm.serviceTypes = [];
            function initServiceTypes() {
                reportSvc.getServiceTypes().success(function (data) {
                    vm.serviceTypes = data;
                });
            }

            vm.loading = 0;
            vm.requestParams = {
                ticketType: $stateParams.ticketType,
                employeeType: $stateParams.employeeType,
                clinicType: $stateParams.clinicType,
                clinicLocation: $stateParams.clinicLocation,
                employee: $stateParams.employee,
                nationalId: $stateParams.nationalId,
                departmentIds: $stateParams.departmentIds,
                employeeNumber: $stateParams.employeeNumber,
                benefitGroupIds: $stateParams.benefitGroupIds || [],
                subsidiaryIds: $stateParams.subsidiaryIds || [],
                financialYear: $stateParams.financialYear || appSession.tenant.currentFinancialYear,
                startDate: $stateParams.startDate,
                endDate: $stateParams.endDate,
                serviceType: $stateParams.serviceType
            };
            vm.advancedFiltersAreShown = vm.requestParams.employee || vm.requestParams.nationalId || vm.requestParams.employeeNumber
                || vm.requestParams.benefitGroupIds || vm.requestParams.subsidiaryIds === [] || vm.requestParams.departmentIds === [];

            vm.dateRangeOptions = _.map(vm.financialYears, function (d) {
                var opt = App.createDateRangePickerOptions();
                var minDate = moment([d, appSession.tenant.firstFinancialMonth - 1, 1]);
                var maxDate = moment.min(moment([d + 1, appSession.tenant.firstFinancialMonth - 1, 1]).subtract(1, 'days'), moment(opt.maxDate));
                opt.minDate = moment(minDate);
                opt.min = moment(minDate);
                opt.maxDate = moment(maxDate);
                opt.max = moment(maxDate);
                opt.financialYear = d;
                return opt;
            });

            vm.dateRangeModel = {};

            vm.fyChanged = function () {
                var opt = _.find(vm.dateRangeOptions, { 'financialYear': vm.requestParams.financialYear });
                vm.dateRangeModel.startDate = moment(opt.minDate);
                vm.dateRangeModel.endDate = moment(opt.maxDate);
            };

            vm.fyChanged();

            vm.viewTickets = function (clinicLocation) {
                $state.go('patientTickets', {
                    clinicLocation: clinicLocation
                });
            };

            vm.gridOptions = {
                appScopeProvider: vm,
                columnDefs: [{
                    displayName: App.localize('Panel'),
                    field: 'clinicLocationName',
                    minWidth: 120,
                    cellTemplate: 'clinicLocationTemplate'
                }, {
                    displayName: App.localize('PanelType'),
                    field: 'clinicType',
                    type: 'number',
                    cellTemplate: 'clinicTypeTemplate',
                    width: 50
                }, {
                    displayName: App.localize('ServiceType'),
                    field: 'serviceType',
                    visible: vm.hasInpatientModule,
                    cellTemplate: 'serviceTemplate',
                    width: 100
                }, {
                    displayName: App.localize('Amount'),
                    field: 'totalAmount',
                    type: 'number',
                    cellFilter: 'currencyFormat: grid.appScope.currencyCode',
                    minWidth: 100
                }, {
                    displayName: App.localize('CoveredAmount'),
                    field: 'coveredAmount',
                    type: 'number',
                    cellFilter: 'currencyFormat: grid.appScope.currencyCode',
                    minWidth: 100
                }, {
                    displayName: App.localize('TicketCount'),
                    field: 'ticketCount',
                    type: 'number',
                    minWidth: 100
                }, {
                    displayName: App.localize('MedicalCertificateIssued'),
                    field: 'mcCount',
                    type: 'number',
                    minWidth: 100
                }, {
                    displayName: App.localize('MedicalLeaveDaysIssued'),
                    field: 'medicalLeaveDaysCount',
                    type: 'number',
                    minWidth: 100
                }, {
                    displayName: App.localize('HospitalizationLeaveDaysCount'),
                    field: 'hospitalizationLeaveDaysCount',
                    visible: vm.hasInpatientModule,
                    type: 'number',
                    minWidth: 100
                }, {
                    displayName: App.localize('EmployeeCount'),
                    field: 'employeeCount',
                    type: 'number',
                    minWidth: 100
                }],
                data: []
            };

            vm.getClinicsReport = function () {
                registerStateParams();
                vm.loading++;
                reportSvc.getClinicsReport(getRequestInput()).success(function (data) {
                    vm.gridOptions.data = data.items;
                }).finally(function (e) {
                    vm.loading--;
                });
            };

            function getEmployeeFieldFilters() {
                var employeeFieldFilters = [];
                _.each(vm.employeeFields, function (f) {
                    if (f.filter) {
                        var filter = _.trim(f.filter);
                        if (filter) {
                            employeeFieldFilters.push({
                                name: filter,
                                value: f.value
                            });
                        }
                    }
                });
                return { employeeFields: employeeFieldFilters };
            }

            function getRequestInput(selectedFields) {
                var input = $.extend({}, vm.requestParams, vm.dateRangeModel, getEmployeeFieldFilters(), { reportFields: selectedFields });
                input.startDate = vm.dateRangeModel.startDate.format('YYYY-MM-DD');
                input.endDate = vm.dateRangeModel.endDate.format('YYYY-MM-DD');
                return _.omitBy(input, _.isNil);
            }

            function registerStateParams() {
                var startDate = null,
                    endDate = null;
                if (vm.dateRangeModel.startDate)
                    startDate = vm.dateRangeModel.startDate.format('YYYY-MM-DD');
                if (vm.dateRangeModel.endDate)
                    endDate = vm.dateRangeModel.endDate.format('YYYY-MM-DD');

                $state.transitionTo($state.current,
                    {
                        ticketType: vm.requestParams.ticketType,
                        employeeType: vm.requestParams.employeeType,
                        clinicLocation: vm.requestParams.clinicLocation,
                        employee: vm.requestParams.employee,
                        clinicType: vm.requestParams.clinicType,
                        nationalId: vm.requestParams.nationalId,
                        departmentIds: vm.requestParams.departmentIds,
                        employeeNumber: vm.requestParams.employeeNumber,
                        benefitGroupIds: vm.requestParams.benefitGroupIds,
                        subsidiaryIds: vm.requestParams.subsidiaryIds,
                        financialYear: vm.requestParams.financialYear,
                        startDate: startDate,
                        endDate: endDate,
                        serviceType: vm.requestParams.serviceType
                    }, {
                    notify: false
                });
            }

            vm.exportToExcel = function (option) {
                option = option || getRequestInput();

                vm.exporting++;
                reportSvc.getClinicsReportToExcel(option).success(function (data) {
                    App.downloadTempFile(data);
                }).finally(function () {
                    vm.exporting--;
                });
            };

            vm.export = function (type) {
                var option = getRequestInput();
                if (type === 0)
                    window.open(App.formatUrl('Print/ClinicsReport?', option));
                else
                    vm.exportToExcel(option);
            };

            function getTenantCurrencyCode() {
                vm.loading++;
                commonLookupSvc.getTenantCurrencyCode()
                    .success(function (data) {
                        vm.currencyCode = data;
                    }).finally(function () {
                        vm.loading--;
                    })
            }

            init();
        }
    ]);
})();
