(() => {
  angular
    .module('app')
    .controller('common.views.guaranteeLetters.details.index', GuaranteeLetterDetailPageController);

  GuaranteeLetterDetailPageController.$inject = [
    '$state',
    '$stateParams',
    'abp.services.app.guaranteeLetter',
  ];

  function GuaranteeLetterDetailPageController($state, $stateParams, guaranteeLetterSvc) {
    const vm = this;

    vm.letterNumber = $stateParams.letterNumber;
    vm.memberId = 0; // For host claim policy component use. Get value from letter detail component.
    vm.isHost = App.isHost();
    vm.isCorporate = App.isCorporate();
    vm.activeTab = 1;

    vm.navigateLetter = navigateLetter;

    init();

    function init() {}

    function navigateLetter(type) {
      vm.loading += 1;
      guaranteeLetterSvc
        .getNextGuaranteeLetter({
          id: $stateParams.letterNumber,
          type,
        })
        .success((data) => {
          if (data) {
            $state.go('guaranteeLetterDetail', {
              letterNumber: data.letterNumber,
              employeeId: data.employeeId,
            });
          } else {
            const msg = type === 1 ? 'LatestLetterMessage' : 'EarliestLetterMessage';
            abp.notify.error(App.localize(msg));
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
