(() => {
  angular
    .module('app')
    .controller(
      'common.views.patientTickets.detail.editSpecialistAmountModal',
      EditSpecialistAmountModalController
    );

  EditSpecialistAmountModalController.$inject = [
    '$uibModal',
    '$uibModalInstance',
    'abp.services.app.patientTicket',
    'ticket',
    'totalClaimableAmount',
    'coPayAmount',
  ];

  function EditSpecialistAmountModalController(
    $uibModal,
    $uibModalInstance,
    patientTicketSvc,
    ticket,
    totalClaimableAmount,
    coPayAmount
  ) {
    const vm = this;

    vm.saving = 0;
    vm.isExceed = false;
    vm.ticketNumber = ticket.ticketNumber;
    vm.currentFinalGlAmount = ticket.coveredAmount;
    vm.hospitalBillAmount = totalClaimableAmount;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.isHost = App.isHost();

    vm.hasInsuranceEnhancementModule = abp.setting.getBoolean(
      'Hms.Feature.InsuranceEnhancementModule'
    );

    vm.cancel = cancel;
    vm.save = save;

    init();

    function init() {
      let totalAmount = 0;
      _.forEach(ticket.sections, (item) => {
        _.forEach(item.treatmentCategories, (d) => {
          totalAmount += d.coveredAmount;
        });
      });

      vm.newFinalAmount = totalAmount;

      if (coPayAmount) vm.newFinalAmount -= coPayAmount;
    }

    function save() {
      vm.saving += 1;
      patientTicketSvc
        .updateClaimTicketAmount({
          id: vm.ticketNumber,
          amount: vm.newFinalAmount,
          sections: ticket.sections,
          referenceNumber: vm.referenceNumber,
          modificationRemarks: $.trim(vm.modificationRemarks),
        })
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          $uibModalInstance.close();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
