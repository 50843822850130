(function () {
    var componentId = 'hostDirectorDashboardPerformanceWidget';
    var app = angular.module('app');

    function controller($element, $filter, directorDashboardSvc, moment) {
        var dataTypeStorageKey = componentId + '_dataType';
        var currencyFilter = $filter('currencyFormat');
        var vm = this;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.loading = false;
        vm.data = null;
        vm.monthlyStats = null;

        vm.dataType = localStorage.getItem(dataTypeStorageKey) || 'Panel';

        vm.setDataType = function (value) {
            if (value) {
                localStorage.setItem(dataTypeStorageKey, value);
                vm.dataType = value;
                updateChart();
            }
        };

        function aggregateFigure(monthlyFigures, dataType) {
            if (dataType === 'Panel') return monthlyFigures.normal;
            if (dataType === 'Reimbursement') return monthlyFigures.reimbursement;
            if (dataType === 'PreEmployment') return monthlyFigures.preEmployment;

            var baseFigure = $.extend({}, monthlyFigures.normal);
            [monthlyFigures.reimbursement, monthlyFigures.preEmployment].forEach(function (f) {
                if (baseFigure.ticketCount || f.ticketCount) baseFigure.ticketCount += f.ticketCount;
                if (baseFigure.ticketValue || f.ticketValue) baseFigure.ticketValue += f.ticketValue;
                if (baseFigure.forecastTicketCount || f.forecastTicketCount) baseFigure.forecastTicketCount += f.forecastTicketCount;
                if (baseFigure.forecastTicketValue || f.forecastTicketValue) baseFigure.forecastTicketValue += f.forecastTicketValue;
            });
            return baseFigure;
        }

        function updateChart() {
            var labels = ['x'];
            var ticketCount = ['ticketCount'];
            var forecastTicketGrowth = ['forecastTicketGrowth'];
            var ticketValue = ['ticketValue'];
            var forecastTicketValue = ['forecastTicketValue'];

            var figureDate = moment(vm.data.figureDate);
            var figureMonth = moment(figureDate).startOf('month');

            var monthlyStats = {
                daysLeft: figureDate.daysInMonth() - figureDate.date() + 1,
                daysLeftPct: figureDate.diff(figureMonth) * 100 / (figureDate.daysInMonth() * 24 * 60 * 60 * 1000)
            };

            _.each(vm.data.figures, function (xf) {
                var fDate = moment(xf.date);
                var f = aggregateFigure(xf.figures, vm.dataType);

                if (fDate.isSame(figureMonth)) {
                    monthlyStats.ticketValue = f.ticketValue;
                    monthlyStats.ticketCount = f.ticketCount;
                    monthlyStats.ticketValuePct = -1;
                    monthlyStats.ticketCountPct = -1;

                    if (f.forecastTicketValue) monthlyStats.ticketValuePct = f.ticketValue * 100 / f.forecastTicketValue;
                    if (f.forecastTicketCount) monthlyStats.ticketCountPct = f.ticketCount * 100 / f.forecastTicketCount;
                }

                labels.push(fDate.format('YYYY-MM-DD'));
                ticketCount.push(f.ticketCount);

                var ftg = f.forecastTicketCount;
                if (ftg && f.ticketCount) {
                    if (f.forecastTicketCount > f.ticketCount) {
                        ftg -= f.ticketCount;
                    } else {
                        ftg = null;
                    }
                }
                forecastTicketGrowth.push(ftg);

                ticketValue.push(f.ticketValue);
                forecastTicketValue.push(f.forecastTicketValue);
            });

            vm.monthlyStats = monthlyStats;

            c3.generate({
                bindto: $element.find('.chart-container')[0],
                size: {
                    height: 280
                },
                data: {
                    x: 'x',
                    columns: [
                        labels, ticketCount, forecastTicketGrowth,
                        ticketValue, forecastTicketValue],
                    names: {
                        x: 'Month',
                        ticketCount: 'Ticket count',
                        forecastTicketGrowth: 'Ticket growth (forecast)',
                        ticketValue: 'Ticket value',
                        forecastTicketValue: 'Ticket value (forecast)'
                    },
                    type: 'spline',
                    types: {
                        ticketCount: 'bar',
                        forecastTicketGrowth: 'bar'
                    },
                    axes: {
                        ticketCount: 'y2',
                        forecastTicketGrowth: 'y2'
                    },
                    groups: [['ticketCount', 'forecastTicketGrowth']],
                    order: null
                },
                legend: {
                    show: false
                },
                grid: {
                    y: { show: true }
                },
                axis: {
                    y: {
                        tick: {
                            format: d3.format('s')
                        },
                        min: 0,
                        padding: {
                            bottom: 0
                        }
                    },
                    x: {
                        type: 'timeseries',
                        tick: {
                            format: function (x) { return moment(x).format('MMM YYYY'); }
                        }
                    },
                    y2: {
                        show: true
                    }
                },
                tooltip: {
                    format: {
                        value: function (value, ratio, id, index) {
                            if (id === 'ticketCount' || id === 'forecastTicketGrowth') return value;
                            return currencyFilter(value, vm.currencyCode);
                        }
                    }
                }
            });
        }

        function loadData() {
            vm.loading = true;
            directorDashboardSvc.getPerformanceFigures()
                .success(function (data) {
                    vm.data = data;
                    updateChart();
                }).finally(function () {
                    vm.loading = false;
                });
        }

        loadData();
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/directorDashboard/performanceWidget.html'),
        controller: ['$element', '$filter', 'abp.services.app.directorDashboard', 'moment', controller],
        controllerAs: 'vm'
    });
})();
