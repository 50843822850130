(() => {
  angular
    .module('app')
    .controller(
      'host.views.corporates.createEdit.features.addCustomEmployeeFields',
      CustomEmployeeFieldsController
    );

  CustomEmployeeFieldsController.$inject = ['$uibModalInstance', 'employeeFields'];

  function CustomEmployeeFieldsController($uibModalInstance, employeeFields) {
    const vm = this;
    vm.employeeFields = angular.copy(employeeFields);

    vm.save = save;
    vm.cancel = cancel;
    init();

    function init() {}

    function save() {
      $uibModalInstance.close(vm.employeeFields);
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
