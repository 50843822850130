const moment = require('moment');

(() => {
  angular.module('app').component('hostClinicsPanelEditorContainerComponent', {
    templateUrl: require('./panelEditorContainer.component.html'),
    controller: PanelEditorController,
    controllerAs: 'vm',
    bindings: {},
  });

  PanelEditorController.$inject = [
    '$stateParams',
    '$state',
    '$scope',
    'abp.services.app.clinic',
    'abp.services.app.panelRequest',
    'abp.services.finance.commonLookup'
  ];

  function PanelEditorController(
    $stateParams,
    $state,
    $scope,
    clinicSvc,
    panelRequestSvc,
    financeCommonLookupSvc
  ) {
    const vm = this;
    vm.isCreate = !$stateParams.panelId; // TODO: Bad idea. What if stateParams changes and getPanel recalled?
    vm.panelId = null;
    vm.loading = 0;
    vm.saving = 0;
    vm.isEditing = false;
    vm.editIrbmMyInvoisSettingsWidget = null;

    vm.permissions = {
      edit: abp.auth.isGranted('Host.Clinics.Edit'),
    };

    const widgets = [];

    vm.addWidget = addWidget;
    vm.saveWidget = saveWidget;
    vm.saveAll = saveAll;
    vm.toggleEdit = toggleEdit;
    vm.getPanel = getPanel;
    vm.cancel = cancel;

    init();

    function init() {
      getFinanceSettings();
      vm.getPanel();
    }

    // Register child widget to this container.
    // Expose to child widget to register themselves.

    function addWidget(widget) {
      if (widget) {
        widgets.push(widget);
      }
    }

    // Save widget.

    function saveWidget(widget) {
      if (vm.isCreate) return; // Prevent saving individual widget during creation.
      if (vm.saving || widget.saving) return;
      if (!widget.validateForm()) {
        abp.notify.error(App.localize('GeneralInvalidFormInputError'));
        return;
      }

      widget.saving += 1;
      save(vm.isCreate, widget.getPayload(), () => {
        widget.saving -= 1;
      });
    }

    // Save all changes on the form to panel.

    function saveAll() {
      let error = false;

      if (vm.saving || vm.loading) return;

      const finalPayload = {};
      _.each(widgets, (w) => {
        w.validateForm(error);
        w.getPayload(finalPayload);
      });

      // Stop saving if any error occurs.

      if (error) {
        abp.notify.error(App.localize('GeneralInvalidFormInputError'));
        return;
      }

      vm.saving += 1;
      save(vm.isCreate, finalPayload, () => {
        vm.saving -= 1;
      });
    }

    function toggleEdit(flag) {
      vm.isEditing = flag;
      _.each(widgets, (w) => {
        w.isEditing = flag;
      });
    }

    // Get panel.

    function getPanel() {
      getPanelForEdit($stateParams.panelId, $stateParams.requestNumber);
    }

    // Get panel form data.

    function getPanelForEdit(panelId, requestNumber) {
      vm.loading += 1;
      clinicSvc
        .getPanelForEdit({
          id: panelId,
        })
        .success((n) => {
          vm.data = n;
          vm.panelId = n.id;
          vm.pharmacies = n.pharmacies;

          // Set panel startdate on datepicker.

          vm.data.momentStartDate = moment.utc(n.properties.startDate);

          if (vm.isCreate) prefill(requestNumber);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function prefill(requestNumber) {
      // Ensure there is data, request number and is creation.

      if (!vm.data || !requestNumber || !vm.isCreate) return;

      vm.loading += 1;
      panelRequestSvc
        .getPanelRequest({
          id: requestNumber,
        })
        .success((request) => {
          vm.panelRequest = request;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // Create or update panel with the given payload.
    // Callback should be used for handling vm.saving in child widget.

    function save(isCreate, payload, callback) {
      if (isCreate) {
        abp.message.confirm(
          App.localize('TimeZoneConfirmMessage'),
          App.localize('AreYouSure'),
          (d) => {
            if (d) {
              createPanel(payload, callback);
            } else if (_.isFunction(callback)) {
              callback();
            }
          });
      } else {
        updatePanel(payload, callback);
      }
    }

    // Create panel with the given payload.

    function createPanel(payload, callback) {
      clinicSvc
        .createPanel(payload)
        .success((data) => {
          abp.notify.info(App.localize('PanelSettingsSuccessfullySaved'));

          if (data.password) {
            abp.message.success(App.localize('PasswordForUser', 'Admin'), data.password);
          }

          if (vm.data.properties.accountOwnerCountryCode === 'MY' && vm.editIrbmMyInvoisSettingsWidget != null) {
            setTaxIdentifiers(payload, data.identityServerTenantId, data.id);
          }
        })
        .finally(() => {
          if (_.isFunction(callback)) callback();
        });
    }

    // Update panel with the given payload.

    function updatePanel(payload, callback) {
      _.extend(payload, { id: vm.panelId });
      clinicSvc
        .updatePanel(payload)
        .success(() => {
          abp.notify.info(App.localize('PanelSettingsSuccessfullySaved'));
          vm.toggleEdit(false);
        })
        .finally(() => {
          if (_.isFunction(callback)) callback();
        });
    }

    function setTaxIdentifiers(payload, identityServerTenantId, id) {
      vm.editIrbmMyInvoisSettingsWidget.getPayload(payload);

      const taxIdentifiers = payload.malaysiaTaxIdentifiers.subTenants[0];
      taxIdentifiers.tenantIdentityServiceId = identityServerTenantId;

      vm.editIrbmMyInvoisSettingsWidget.validateForm();

      const watch = $scope.$watch('vm.editIrbmMyInvoisSettingsWidget.loading', () => {
        if (!vm.editIrbmMyInvoisSettingsWidget.loading) {
          $state.go('host.createEditPanel', { panelId: id });
          vm.toggleEdit(false);
          watch(); // deregister the watch
        }
      }, true);
    }

    // Cancel creation or edit.

    function toggleCancel(resetPanel) {
      if (vm.isCreate) window.history.back();
      else {
        vm.toggleEdit(false);
        if (resetPanel) vm.getPanel();
      }
    }

    function cancel() {
      const dirtyWidgets = _.filter(widgets, (w) => w.isDirty());
      if (!dirtyWidgets.length) toggleCancel(false);
      else {
        abp.message.confirm(
          App.localize('UnsavedChangesConfirmation'),
          App.localize('AreYouSure'),
          (d) => {
            if (d) {
              toggleCancel(true);
              _.each(dirtyWidgets, (w) => {
                w.setPristine();
              });
            }
          });
      }
    }

    function getFinanceSettings() {
      vm.loading += 1;
      financeCommonLookupSvc
        .getCommonSettings()
        .success((data) => {
          vm.hasTaxIdentifierCollection = data.taxIdentifierCollection;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();