(function () {
    var componentId = 'hostDirectorDashboardGrowthTable';
    var app = angular.module('app');

    function controller() {
        var vm = this;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.result = vm.resolve.result;
        vm.close = function () {
            vm.modalInstance.dismiss();
        }
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/directorDashboard/growthTable.html'),
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: controller,
        controllerAs: 'vm'
    });
})();
