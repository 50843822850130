(() => {
  angular.module('app').component('hostOperationsDashboardClaimsComponent', {
    templateUrl: require('./claims.component.html'),
    controller: ClaimComponentController,
    controllerAs: 'vm',
    bindings: {
      widgets: '<',
      onAddWidget: '&',
      countryCode: '=?',
    },
  });

  ClaimComponentController.$inject = [
    '$scope',
    '$state',
    'abp.services.app.operationsDashboard',
    'Hms.Claims.ClaimStatus',
  ];

  function ClaimComponentController($scope, $state, operationsDashboardSvc, enumClaimStatus) {
    const vm = this;
    vm.widgetName = 'hostOperationsDashboardClaimsComponent';
    vm.loading = false;
    vm.countryCode = null;
    vm.records = [];
    vm.requestParams = {
      filter: '',
      includesResolved: false,
      includesInpatient: false,
      maxResultCount: 5,
      skipCount: 0,
      countryCode: vm.countryCode,
    };
    vm.permissions = {
      claims: abp.auth.isGranted('Host.Claims'),
    };

    vm.enums = {
      claimStatus: enumClaimStatus,
    };

    vm.$onInit = $onInit;
    vm.refresh = refresh;
    vm.loadMore = loadMore;

    init();

    function init() {
      refresh();
    }

    function $onInit() {
      vm.onAddWidget({ widgetName: 'claimWidget', widget: this });
    }

    function loadMore() {
      if (!vm.isLoading && vm.hasMore) {
        vm.requestParams.skipCount += 5;
        getClaims();
      }
    }

    function refresh() {
      vm.requestParams.skipCount = 0;
      vm.requestParams.countryCode = vm.countryCode;
      getClaims();
    }

    function setTotalCount() {
      const item = _.find(vm.widgets, (data) => data.id === vm.widgetName);
      item.totalCount = vm.totalCount;
      item.loading = false;
    }

    function getClaims() {
      const {skipCount} = vm.requestParams;
      if (skipCount === 0) {
        vm.records = [];
      }

      vm.loading += 1;
      operationsDashboardSvc
        .getClaims(vm.requestParams)
        .success((data) => {
          vm.totalCount = data.totalCount;
          vm.records = _.uniqBy(vm.records.concat(data.items), 'id');
          vm.hasMore = vm.records.length < vm.totalCount;
          setTotalCount();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
