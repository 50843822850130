(function () {
    var controllerId = 'host.views.panelRecruitment.index';
    var app = angular.module('app');

    app.controller(controllerId, function () {
        var vm = this;

        vm.permissions = {
            canViewDashboard: abp.auth.isGranted('PanelRequests.Host.RecruitmentDashboard')
        };
    });
})();
