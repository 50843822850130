(() => {
  angular.module('revenue').component('revenuePaymentAccountInput', {
    templateUrl: require('./paymentAccountInput.html'),
    controller: PaymentAccountInputComponent,
    controllerAs: 'vm',
    bindings: {
      account: '<',
      currencyCode: '<',
      isRequired: '<',
    },
  });

  PaymentAccountInputComponent.$inject = [
    'abp.services.revenue.common',
    'HmsRevenueConsts.PaymentAccount',
  ];

  function PaymentAccountInputComponent(commonSvc, constsPaymentAccount) {
    const vm = this;
    vm.constsPaymentAccount = constsPaymentAccount;
    const cachedValues = {};
    vm.loading = 0;
    vm.paymentChannels = [];

    vm.$onChanges = $onChanges;

    init();

    // Define functions.

    function init() {
      refreshPaymentChannels();
    }

    function refreshPaymentChannels() {
      vm.paymentChannels = [];
      const currencyCode = (vm.currencyCode || '').trim();
      if (!currencyCode) return;

      let cachedItem = cachedValues[currencyCode];
      if (cachedItem) {
        vm.paymentChannels = cachedItem;
        return;
      }

      vm.loading += 1;
      commonSvc
        .getPaymentChannels({ currencyCode })
        .success((data) => {
          cachedItem = data.items;
          cachedValues[currencyCode] = cachedItem;
          if (currencyCode === vm.currencyCode) {
            vm.paymentChannels = cachedItem;
            vm.loading = 0;
          }
        })
        .finally(() => {
          vm.loading = Math.max(vm.loading - 1, 0);
        });
    }

    function $onChanges(changesObj) {
      if (changesObj.currencyCode) refreshPaymentChannels();
    }
  }
})();
