import intlTelInput from 'intl-tel-input';
import libphonenumber from 'google-libphonenumber';

(() => {
  angular.module('app').directive('contactNumber', contactNumberDirective);

  contactNumberDirective.$inject = [];

  function contactNumberDirective() {
    const directive = {
      link,
      restrict: 'A',
      require: 'ngModel',
      scope: {
        isDisabled: '=',
        countryCode: '='
      },
    };
    return directive;

    function link(scope, element, attr, ngModel) {
      $(document).ready(() => {
        const allowedCountries = ['MY', 'SG', 'TH', 'ID'];
        // Set to Malaysia by default, get by specific country in future.
        let selectedCountry = 'MY';

        // Initialization if the input already has value.
        const phoneNumberValue = element.val();
        if (!_.isEmpty(phoneNumberValue)) {
          setCountryFromPhoneNumber(phoneNumberValue);
        }

        // Initialize component with intl-tel-input
        const iti = intlTelInput(element[0], {
          initialCountry: selectedCountry,
          onlyCountries: allowedCountries,
        });

        scope.$watch('isDisabled', (current) => {
          if (current) {
            if (!_.isEmpty(phoneNumberValue)) {
              setCountryFromPhoneNumber(phoneNumberValue);
            }
            iti.setCountry(selectedCountry);
            ngModel.$setValidity('phoneNumber', true);
          }
        });

        scope.$watch('countryCode', (current) => {
          if (_.isEmpty(phoneNumberValue) 
              && allowedCountries.includes(current) 
              && selectedCountry !== current) {
            selectedCountry = current;
            iti.setCountry(selectedCountry);
          }
        });

        // Trigger validation when country selected is changed.
        element.on('countrychange', () => {
          const countryData = iti.getSelectedCountryData();
          selectedCountry = countryData.iso2;
          fromUser(element.val());
        });

        // Set the selected country based on phone number.

        function setCountryFromPhoneNumber(value) {
          const util = libphonenumber.PhoneNumberUtil.getInstance();
          const numberProto = util.parseAndKeepRawInput(value, '');
          selectedCountry = util.getRegionCodeForNumber(numberProto);
        }

        function fromUser(value) {
          let v = value;
          let isValid = true;
          if (v && v.length) {
            try {
              const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
              const valueProto = phoneUtil.parse(v, selectedCountry);
              isValid = phoneUtil.isValidNumber(valueProto);
              if (isValid) {
                v = phoneUtil.format(valueProto, libphonenumber.PhoneNumberFormat.INTERNATIONAL);
              }
            } catch (ex) {
              isValid = false;
            }
          }
          ngModel.$setValidity('phoneNumber', isValid);
          return v;
        }
        ngModel.$parsers.push(fromUser);
      });
    }
  }
})();
