(() => {
  angular
    .module('app')
    .controller('corporate.views.subscriptionDetail', CorporateSubscriptionDetailController);

  CorporateSubscriptionDetailController.$inject = [
    '$stateParams',
    'abp.services.app.subscription',
    'Hms.Revenue.SubscriptionPlanPricingMode',
    'Hms.Revenue.SubscriptionPlanChargeMode',
    'Hms.Revenue.OutpatientServiceFeatures',
    'Hms.Revenue.InpatientServiceFeatures',
  ];

  function CorporateSubscriptionDetailController(
    $stateParams,
    subscriptionSvc,
    enumSubscriptionPlanPricingMode,
    enumSubscriptionPlanChargeMode,
    enumOutpatientServiceFeatures,
    enumInpatientServiceFeatures
  ) {
    const vm = this;

    vm.loading = 0;

    vm.enums = {
      subscriptionPlanPricingMode: enumSubscriptionPlanPricingMode,
      subscriptionPlanChargeMode: enumSubscriptionPlanChargeMode,
      outpatientServiceFeatures: enumOutpatientServiceFeatures,
      inpatientServiceFeatures: enumInpatientServiceFeatures,
    };

    vm.serviceFeatures = _.union(
      vm.enums.outpatientServiceFeatures.values,
      vm.enums.inpatientServiceFeatures.values
    );

    vm.getServiceFeaturesAsString = getServiceFeaturesAsString;
    vm.getChargeMode = getChargeMode;
    vm.getPricingMode = getPricingMode;

    init();

    function init() {
      loadCorporateSubscription();
    }

    function loadCorporateSubscription() {
      vm.loading += 1;
      subscriptionSvc
        .getSubscriptionDetail({
          subscriptionNumber: $stateParams.subscriptionNumber,
        })
        .success((data) => {
          vm.subscription = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getServiceFeaturesAsString(serviceFeaturesArray) {
      const values = [];
      _.forEach(serviceFeaturesArray, (item) => {
        const serviceFeature = _.find(vm.serviceFeatures, (i) => i.id === item);
        values.push(serviceFeature.name);
      });
      return values.join(', ');
    }

    function getChargeMode(chargeMode) {
      if (chargeMode === vm.enums.subscriptionPlanChargeMode.Monthly.id) {
        return App.localize('Monthly');
      }
      if (chargeMode === vm.enums.subscriptionPlanChargeMode.Yearly.id) {
        return App.localize('Yearly');
      }
      return '';
    }

    function getPricingMode(pricingMode) {
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.Fixed.id) {
        return App.localize('Fixed');
      }
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.PerMember.id) {
        return App.localize('PerMember');
      }
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.PerTicketBasis.id) {
        return App.localize('PerTicketBasis');
      }
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.PerCaseBasis.id) {
        return App.localize('PerCaseBasis');
      }
      return '';
    }
  }
})();
