(() => {
  angular.module('app').component('commonInpatientTicketsDetailRoomAndBoardComponent', {
    templateUrl: require('./roomAndBoard.component.html'),
    controller: RoomAndBoardController,
    controllerAs: 'vm',
    bindings: {
      ticket: '<',
      isEdit: '<',
      isSaved: '<',
      categories: '<',
      isCreate: '<',
      currencyCode: '<'
    },
  });

  RoomAndBoardController.$inject = ['moment'];

  function RoomAndBoardController(moment) {
    const vm = this;
    
    vm.roomChargeDateRangeOptions = {
      ranges: '',
    };

    vm.$onChanges = onChanges;
    vm.addCategory = addCategory;
    vm.addRooms = addRooms;
    vm.clearRooms = clearRooms;
    vm.deleteItem = deleteItem;
    vm.updateRooms = updateRooms;
    vm.calculateTotal = calculateTotal;

    init();

    function init() {
      vm.ticket.rooms = _.chain(vm.ticket.rooms)
        .groupBy('category')
        .map((value, key) => ({
          category: key,
          ticketItems: value,
          name: _.first(value).categoryName,
        }))
        .value();

      if (vm.ticket.rooms.length === 0) {
        if (vm.isEdit) addCategory();
      } else {
        _.each(vm.ticket.rooms, (x) => {
          _.each(x.ticketItems, (ticketItem) => {
            const ti = ticketItem;
            ti.totalCharges = ti.amount + ti.uncoveredAmount;
          });
        });
      }

      updateDates();
    }

    function onChanges(changes) {
      if (changes.ticket && changes.ticket.currentValue) {
        if (vm.ticket.rooms.length === 0) {
          if (vm.isEdit) addCategory();
        } else {
          _.each(vm.ticket.rooms, (x) => {
            _.each(x.ticketItems, (ticketItem) => {
              const ti = ticketItem;
              ti.totalCharges = ti.amount + ti.uncoveredAmount;
            });
          });
        }
      }

      if (changes.isSaved && changes.isSaved.currentValue) {
        tidyRooms();
      }

      if (changes.isEdit && changes.isEdit.currentValue) {
        if (vm.ticket && vm.ticket.rooms.length < 1) {
          addCategory(1);
        }
      }

      updateDates();
    }

    function addCategory(type) {
      const categoryItem = {
        category: type || null,
        ticketItems: [
          {
            category: type || null,
            categoryName: type ? getCategoryName(type) : null,
          },
        ],
      };
      vm.ticket.rooms.push(categoryItem);
      updateDates();
    }

    function addRooms(record) {
      const r = record;
      if (typeof r.category === 'undefined' || r.category === null) return;

      if (!r.ticketItems) {
        r.ticketItems = [];
      }

      r.ticketItems.push({
        category: r.category,
        categoryName: getCategoryName(r.category),
      });
      updateDates();
    }

    function clearRooms(category) {
      const c = category;
      c.ticketItems = [];
      addRooms(c);
    }

    function tidyRooms() {
      vm.ticket.rooms = _.filter(vm.ticket.rooms, (item) =>
        _.filter(item.ticketItems, (k) => !_.isNil(k.amount))
      );
    }

    function updateDates() {
      if (vm.ticket && vm.ticket.rooms) {
        _.each(vm.ticket.rooms, (x) => {
          _.each(x.ticketItems, (ticketItem) => {
            const ti = ticketItem;
            if (!ti.roomChargeDateRangeModel) {
              ti.roomChargeDateRangeModel = {
                startDate: ti.startDate ? moment(ti.startDate) : null,
                endDate: ti.endDate ? moment(ti.endDate) : null,
              };
            }
          });
        });
      }
    }

    function updateRooms(index, item) {
      if (typeof item.category === 'undefined' || item.category === null) {
        if (vm.ticket.rooms.length > 1) {
          vm.ticket.rooms.splice(index, 1);
        } else {
          vm.ticket.rooms = [];
          addCategory();
        }
      } else if (item.ticketItems) {
        _.each(item.ticketItems, (ticketItem) => {
          const ti = ticketItem;
          ti.category = item.category;
          ti.categoryName = getCategoryName(item.category);
        });
      }
    }

    function deleteItem(index, item) {
      const i = item;
      if (i.ticketItems.length > 1) {
        i.ticketItems.splice(index, 1);
      } else {
        i.ticketItems = [];
        addRooms(i);
      }
    }

    function calculateTotal(index, item) {
      const i = item;
      let uncoveredAmount = 0;
      let amount = 0;
      if (i[index].amount > 0) {
        amount = i[index].amount;
      }
      if (i[index].uncoveredAmount > 0) {
        uncoveredAmount = i[index].uncoveredAmount;
      }
      const totalCharges = amount + uncoveredAmount;
      i[index].totalCharges = totalCharges;
    }

    function getCategoryName(category) {
      const selectedCategory = _.find(vm.categories, { id: category });
      return selectedCategory ? selectedCategory.displayName : null;
    }
  }
})();
