(function () {
    'use strict';

    angular
        .module('app')
        .controller('host.views.doraemon.common.remarksModal', ['$uibModalInstance', 'title', controller]);

    function controller($uibModalInstance, title) {
        var vm = this;

        vm.cancel = cancel;
        vm.approve = approve;

        vm.remarks = null;
        vm.page = 1;
        vm.nextPage = nextPage;
        vm.title = title;

        function cancel() {
            $uibModalInstance.dismiss();
        }

        function approve(printGl) {
            var input = {
                remarks: vm.remarks,
                printGl: printGl
            };

            if (App.isFormValid(vm.modalForm)) {
                $uibModalInstance.close(input);
            }
        }

        function nextPage() {
            if (App.isFormValid(vm.modalForm)) {
                vm.page += 1;
            }
        }
    }
})();
