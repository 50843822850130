(function () {
    var controllerId = 'corporate.views.subsidiaryDepartments.index';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$uibModal', 'abp.services.app.subsidiaryDepartment',
        function ($scope, $modal, subsidiaryDepartmentSvc) {
            var vm = this;
            vm.loading = 0;

            vm.permissions = {
                create: abp.auth.isGranted("Corporate.SubsidiaryDepartments.Create"),
                edit: abp.auth.isGranted("Corporate.SubsidiaryDepartments.Edit"),
                "delete": abp.auth.isGranted("Corporate.SubsidiaryDepartments.Delete")
            };

            vm.subsidiaryDepartmentGridOptions = {
                appScopeProvider: vm,
                columnDefs: [{
                    enableSorting: false,
                    name: App.localize('Actions'),
                    width: 120,
                    headerCellTemplate: "<span></span>",
                    cellTemplate: '<div class="ui-grid-cell-contents"><div class="btn-group" uib-dropdown dropdown-append-to-body><button type="button" class="btn btn-primary btn-xs" uib-dropdown-toggle><i class="fa fa-cog"></i>&nbsp;&nbsp;' + App.localize('Actions') + '&nbsp;&nbsp;<span class="caret"></span></button><ul class="dropdown-menu" uib-dropdown-menu><li><a ng-if="grid.appScope.permissions.edit" ng-click="grid.appScope.editSubsidiaryDepartment(row.entity)">' + App.localize('Edit') + '</a></li><li><a ng-if="!row.entity.isStatic && grid.appScope.permissions.delete" ng-click="grid.appScope.deleteSubsidiaryDepartment(row.entity)">' + App.localize('Delete') + '</a></li></ul></div></div>'
                }, {
                    displayName: App.localize('Name'),
                    field: 'name',
                    minWidth: 120
                }, {
                    displayName: App.localize('CreationDate'),
                    field: 'creationTime',
                    cellFilter: "momentFormat: 'L'",
                    minWidth: 100
                }],
                data: []
            };

            vm.permissions.edit || vm.permissions.delete || vm.subsidiaryDepartmentGridOptions.columnDefs.shift();

            vm.createSubsidiaryDepartment = createSubsidiaryDepartment;
            vm.editSubsidiaryDepartment = editSubsidiaryDepartment;
            vm.deleteSubsidiaryDepartment = deleteSubsidiaryDepartment;
            vm.getSubsidiaryDepartments = getSubsidiaryDepartments;
            vm.toggleSubsidiaryDepartmentFilter = setGridData;

            init();

            /* Functions */

            function init() {
                vm.loading++;
                subsidiaryDepartmentSvc.getSubsidiaries({}).success(function (data) {
                    vm.subsidiaries = data.items || [];
                    if (vm.subsidiaries.length) {
                        vm.subsidiary = vm.subsidiaries[0];
                        getSubsidiaryDepartments();
                    }
                }).finally(function () {
                    vm.loading--;
                });
            }

            function createSubsidiaryDepartment() {
                showEditModal();
            }

            function editSubsidiaryDepartment(subsidiaryDepartment) {
                showEditModal(subsidiaryDepartment.id);
            }

            function deleteSubsidiaryDepartment(subsidiaryDepartment) {
                abp.message.confirm(App.localize('SubsidiaryDepartmentDeleteWarningMessage', subsidiaryDepartment.name), App.localize('AreYouSure'), function (d) {
                    if (d) {
                        subsidiaryDepartmentSvc.deleteSubsidiaryDepartment({
                            id: subsidiaryDepartment.id
                        }).success(function () {
                            getSubsidiaryDepartments();
                            abp.notify.success(App.localize("SuccessfullyDeleted"));
                        });
                    }
                });
            }

            function getSubsidiaryDepartments() {
                if (!vm.subsidiary) return;
                vm.subsidiaryDepartmentId = null;

                vm.loading++;
                subsidiaryDepartmentSvc.getSubsidiaryDepartments({
                    subsidiaryId: parseInt(vm.subsidiary.value, 10)
                }).success(function (data) {
                    vm.subsidiaryDepartments = data.items;
                    setGridData();
                }).finally(function () {
                    vm.loading--;
                });
            }

            function showEditModal(id) {
                if (!vm.subsidiary) return;

                var modal = $modal.open({
                    templateUrl: require('/App/corporate/views/subsidiaryDepartments/createOrEditModal.html'),
                    controller: "corporate.views.subsidiaryDepartments.createOrEditModal as vm",
                    backdrop: "static",
                    resolve: {
                        subsidiaryDepartmentId: function () {
                            return id;
                        },
                        subsidiaryId: function () {
                            return parseInt(vm.subsidiary.value, 10);
                        },
                        subsidiaryName: function () {
                            return vm.subsidiary.displayText;
                        }
                    }
                });
                modal.result.then(function () {
                    getSubsidiaryDepartments();
                });
            }

            function setGridData() {
                vm.subsidiaryDepartmentGridOptions.data = vm.subsidiaryDepartmentId
                    ? _.filter(vm.subsidiaryDepartments, ['id', vm.subsidiaryDepartmentId])
                    : vm.subsidiaryDepartments;
            }

            /* End of Functions */
        }
    ]);
})();
