(() => {
  angular.module('app').controller('common.views.common.lookupModal', LookupModalController);

  LookupModalController.$inject = ['$scope', '$uibModalInstance', 'lookupOptions', 'appConstants'];

  function LookupModalController($scope, $modal, lookupOptions, appConstants) {
    const vm = this;

    vm.loading = 0;

    vm.options = angular.extend(
      {
        serviceMethod: null,
        title: App.localize('SelectAnItem'),
        loadOnStartup: true,
        showFilter: true,
        filterText: '',
        skipCount: 0,
        pageSize: appConstants.grid.defaultPageSize,
        callback() {},
        canSelect() {
          return true;
        },
      },
      lookupOptions
    );

    vm.gridOptions = {
      paginationPageSizes: appConstants.grid.defaultPageSizes,
      paginationPageSize: vm.options.pageSize,
      useExternalPagination: true,
      enableSorting: false,
      appScopeProvider: vm,
      columnDefs: [
        {
          name: App.localize('Select'),
          width: 80,
          cellTemplate: `<div class="ui-grid-cell-contents"><button class="btn btn-primary btn-xs" ng-click="grid.appScope.selectItem(row.entity)"><uiGridConstants class="fa fa-check"></uiGridConstants> ${App.localize(
            'Select'
          )}</button></div>`,
        },
        {
          displayName: App.localize('Name'),
          field: 'name',
          minWidth: 120,
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.options, vm.refreshGrid)
        );
      },
      data: [],
    };

    vm.cancel = cancel;
    vm.selectItem = selectItem;
    vm.refreshGrid = refreshGrid;

    init();

    function init() {
      if (!vm.options.serviceMethod) {
        $modal.dismiss();
        return;
      }

      if (vm.options.loadOnStartup) {
        vm.refreshGrid();
      }
    }

    function cancel() {
      $modal.dismiss();
    }

    function selectItem(item) {
      const selectable = vm.options.canSelect(item);
      if (selectable) {
        if (selectable === true) {
          vm.options.callback(item);
          $modal.close(item);
          return;
        }
        selectable.then((s) => {
          if (s) {
            vm.options.callback(item);
            $modal.close(item);
          }
        });
      }
    }

    function refreshGrid() {
      const options = angular.extend(
        {
          skipCount: vm.options.skipCount,
          maxResultCount: vm.options.pageSize,
          filter: vm.options.filterText,
        },
        lookupOptions.extraFilters
      );

      vm.loading += 1;
      vm.options
        .serviceMethod(options)
        .success((data) => {
          vm.gridOptions.totalItems = data.totalCount;
          vm.gridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
