(function () {
    var controllerId = 'clinic.views.hospital.adjustmentRequest';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$stateParams', '$state', '$uibModal', 'abp.services.app.hospitalPanelServiceRequest',
        function ($stateParams, $state, $modal, hospitalPanelServiceRequestSvc) {
            var vm = this;
            vm.loading = 0;
            vm.saving = 0;
            vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
            vm.employeeId = null;
            vm.letterNumber = $stateParams.letterNumber;
            vm.save = save;
            vm.cancel = cancel;
            vm.attachments = [];
            vm.hospitalizationBenefits = [];

            vm.inpatientAdjustmentObjectType = 'InpatientAdjustmentRequestAttachment';

            function init() {
                hospitalPanelServiceRequestSvc.getAdjustmentDetails({
                    letterNumber: vm.letterNumber
                }).success(function (data) {
                    vm.adjustment = {
                        letterNumber: vm.letterNumber,
                        panelLocationId: data.guaranteeLetter.panelLocationId,
                        specialistDoctors: data.doctors,
                        checklistItems: data.documentChecklistItems,
                        employeeId: data.guaranteeLetter.employeeId,
                        attachments: data.guaranteeLetter.attachments,
                        newAttachments: []
                    };

                    _.each(vm.adjustment.checklistItems, function (item) {
                        item.required = item.isMandatory;
                        item.isChecked = item.isSelected;
                    });
                });
            }

            function save() {
                if (vm.saving)
                    return;

                vm.loading++;

                var checklistItems = [];
                _.each(vm.adjustment.checklistItems, function (item) {
                    checklistItems.push({
                        text: item.text,
                        isSelected: item.isChecked,
                        isMandatory: item.required
                    });
                });

                var attachments = [];
                _.each(vm.adjustment.attachments, function (attachment) {
                    if (!attachment.isDelete)
                        attachments.push(attachment.id);
                });

                var selectedDoctorIds = [];
                if (vm.adjustment.selectedDoctorIds !== undefined) {
                    _.each(vm.adjustment.selectedDoctorIds, function (doctor) {
                        selectedDoctorIds.push(doctor);
                    });
                }

                var input = $.extend(vm.letter, {
                    letterNumber: vm.letterNumber,
                    adjustmentReason: vm.adjustment.reason,
                    adjustedFinalBillAmount: vm.adjustment.amount,
                    doctorIds: selectedDoctorIds,
                    documentChecklistItems: checklistItems,
                    attachments: attachments,
                    newAttachments: vm.adjustment.newAttachments,
                });

                vm.saving = true;
                hospitalPanelServiceRequestSvc.requestAdjustment(input)
                    .success(function () {
                        abp.notify.info(App.localize("SuccessfullySaved"));
                        $state.go('clinic.inpatientDashboard');
                    }).finally(function () {
                        vm.saving = false;
                        vm.loading--;
                    });
            }

            function cancel() {
                $state.go('clinic.patientAdmissionDetails', { 'letterNumber': vm.letterNumber });
            }

            init();
        }
    ]);
})();
