(() => {
  angular.module('finance')
    .controller('finance.views.settings.index', FinanceSettingsController);

  FinanceSettingsController.$inject = [
    'abp.services.finance.settings',
    'Abp.Configuration.Setting',
  ];

  function FinanceSettingsController(settingsSvc, constsAbpConfig) {
    const vm = this;
    vm.constsAbpConfig = constsAbpConfig;
    vm.save = save;
    vm.settings = null;
    vm.loading = 0;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

    init();

    function init() {
      vm.loading += 1;

      settingsSvc
        .getSettings()
        .success((data) => {
          vm.settings = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function save() {
      vm.loading += 1;

      settingsSvc
        .updateSettings(vm.settings)
        .success(() => {
          abp.notify.success(App.localize('SuccessfullySaved'));
          vm.loading -= 1;
        });
    }
  }
})();
