(function () {
    angular
        .module('app')
        .controller('host.views.employees.scheduleOfBenefitsModal', ['$scope', '$uibModalInstance', 'serviceType',
            'hospitalizationSetting', 'maternitySetting', 'employeeType', 'countryInfo', controller]);

    function controller($scope, $uibModalInstance, serviceType, hospitalizationSetting, maternitySetting, employeeType, countryInfo) {
        const vm = this;
        vm.loading = 0;
        vm.hasSobEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');
        vm.hasTxtFileSupport = abp.setting.getBoolean('Hms.Feature.TxtFileSupport');
        vm.hasRegionalExpansionForInpatient = abp.setting.getBoolean('Hms.Feature.RegionalExpansionForInpatient');
        vm.countryCode = abp.setting.get('Hms.General.DefaultCountryCode');
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

        function init() {
            vm.serviceType = serviceType;

            if (vm.hasRegionalExpansionForInpatient) {
                vm.countryCode = countryInfo.countryCode;
                vm.currencyCode = countryInfo.currencyCode;
            }

            if (vm.serviceType === 7) {
                vm.inpatientSettings = employeeType === 0 
                    ? hospitalizationSetting.principalScheduleOfBenefit 
                    : hospitalizationSetting.dependentScheduleOfBenefit;
                vm.isAccomodationDaysCombined = vm.inpatientSettings.accomodationDaysCombined;
                vm.roomAndBoard = vm.inpatientSettings.roomAndBoard;
                vm.roomAndBoardAmount = vm.inpatientSettings.roomAndBoard.amount;
                vm.roomAndBoardAccomodationDays = 
                    vm.inpatientSettings.roomAndBoard.accomodationDays;
                vm.icuRoomAndBoard = vm.inpatientSettings.icuRoomAndBoard;
                vm.icuRoomAndBoardAccomodationDays = 
                    vm.inpatientSettings.icuRoomAndBoard.accomodationDays;
                vm.childGuardianBenefit = vm.inpatientSettings.childGuardianBenefit;

                vm.physicianVisitPerDay = 
                    vm.inpatientSettings.doctorCharges.inHospitalPhysicianVisitPerDay;
                vm.physicianDaysPerYear = vm.inpatientSettings.doctorCharges.coveredDays;
                vm.hospitalCharges = vm.inpatientSettings.hospitalCharges;
                vm.surgicalFee = vm.inpatientSettings.hospitalCharges.surgicalFee;
                vm.anaesthetistFee = vm.inpatientSettings.hospitalCharges.anaesthetistFee;
                vm.operatingTheatre = vm.inpatientSettings.hospitalCharges.operatingTheatre;
                vm.hospitalSuppliesAndServices = 
                    vm.inpatientSettings.hospitalCharges.hospitalSuppliesAndServices;
                vm.ambulanceFee = vm.inpatientSettings.ambulanceFee;
                vm.organTransplant = vm.inpatientSettings.organTransplant;
                vm.medicalReport = vm.inpatientSettings.medicalReport;

                vm.preHospitalizationTreatment = vm.inpatientSettings.preHospitalizationTreatment;
                vm.specialistConsultation = vm.inpatientSettings.specialistConsultation;
                vm.surgicalOpinion = vm.inpatientSettings.surgicalOpinion;
                vm.postHospitalizationTreatment = vm.inpatientSettings.postHospitalizationTreatment;
                vm.followUpTreatment = vm.inpatientSettings.followUpTreatment;
                vm.outpatientCancerTreatment = vm.inpatientSettings.outpatientCancerTreatment;
                vm.outpatientKidneyDialysisTreatment = 
                    vm.inpatientSettings.outpatientKidneyDialysisTreatment;

                vm.inpatientSob = [];
                vm.outpatientSob = [];
                vm.miscSob = [];

                if (vm.hasTxtFileSupport) {
                    // Inpatient
                    //#region InpatientMedical SOB tab
                    var roomAndBoard = vm.inpatientSettings.roomAndBoard;
                    var icuRoomAndBoard = vm.inpatientSettings.icuRoomAndBoard;
                    var childGuardianBenefit = vm.inpatientSettings.childGuardianBenefit;
                    var hospitalSuppliesAndServices = vm.inpatientSettings.hospitalCharges.hospitalSuppliesAndServices;
                    var doctorCharges = vm.inpatientSettings.doctorCharges;
                    var ambulanceFee = vm.inpatientSettings.ambulanceFee;
                    var medicalReport = vm.inpatientSettings.medicalReport;
                    vm.inpatientSob.push({
                        type: App.localize('InpatientMedical'),
                        settings: [
                            {
                                name: App.localize('RoomAndBoard'),
                                isCovered: true,
                                isAsCharged: vm.roomAndBoard.isAsCharged,
                                amount: vm.roomAndBoardAmount,
                                effectivePeriod: 'Per day',
                                daysPerYearLimit: vm.roomAndBoardAccomodationDays,
                                isAccomodationDaysCombined: vm.isAccomodationDaysCombined,
                                accomodationDaysCombineText: App.localize('CombinedRoomAndBoardNarrative'),
                                remarks: roomAndBoard.remarks,
                            },
                            {
                                name: App.localize('IcuRoomAndBoard'),
                                isCovered: true,
                                isAsCharged: vm.icuRoomAndBoard.isAsCharged,
                                amount: vm.icuRoomAndBoard.amount,
                                effectivePeriod: 'Per day',
                                daysPerYearLimit: vm.icuRoomAndBoardAccomodationDays,
                                isAccomodationDaysCombined: vm.isAccomodationDaysCombined,
                                accomodationDaysCombineText: App.localize('CombinedIcuRoomAndBoardNarrative'),
                                remarks: icuRoomAndBoard.remarks,
                            },
                            {
                                name: App.localize('InsuredChildDailyGuardianBenefit'),
                                isCovered: childGuardianBenefit.isCovered,
                                isAsCharged: childGuardianBenefit.isAsCharged,
                                amount: childGuardianBenefit.amount,
                                effectivePeriod: 'Per day',
                                daysPerYearLimit: childGuardianBenefit.accomodationDays,
                                remarks: childGuardianBenefit.remarks,
                            },
                            {
                                name: App.localize('HospitalSuppliesAndServices'),
                                isCovered: hospitalSuppliesAndServices.isCovered,
                                isAsCharged: hospitalSuppliesAndServices.isAsCharged,
                                amount: hospitalSuppliesAndServices.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(
                                    hospitalSuppliesAndServices.coveredCycle),
                                remarks: hospitalSuppliesAndServices.remarks,
                            },
                            {
                                name: App.localize('InHospitalPhysicianVisit'),
                                isCovered: true,
                                isAsCharged: true,
                                isThirteenthSchedule: vm.countryCode === 'MY',
                                effectivePeriod: 'Per day',
                                noOfVisit: doctorCharges.isUnlimitedVisitPerDay 
                                    ? App.localize('Unlimited') 
                                    : doctorCharges.inHospitalPhysicianVisitPerDay,
                                daysPerYearLimit: doctorCharges.isUnlimitedCoveredDays 
                                    ? App.localize('Unlimited') 
                                    : doctorCharges.coveredDays,
                                remarks: doctorCharges.remarks,
                            },
                            {
                                name: App.localize('AmbulanceFee'),
                                isCovered: ambulanceFee.isCovered,
                                isAsCharged: ambulanceFee.isAsCharged,
                                amount: ambulanceFee.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(
                                    ambulanceFee.coveredCycle),
                                remarks: ambulanceFee.remarks,
                            },
                            {
                                name: App.localize('MedicalReportFee'),
                                isCovered: medicalReport.isCovered,
                                isAsCharged: medicalReport.isAsCharged,
                                amount: medicalReport.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(
                                    medicalReport.coveredCycle),
                                remarks: medicalReport.remarks,
                            },
                        ],
                        hasEffectivePeriod: true,
                        hasNoOfVisit: true,
                        hasDaysPerYear: vm.hasSobEnhancement
                    });
                    // #endregion InpatientMedical SOB tab

                    //#region InpatientSurgical SOB tab
                    var surgicalFee = vm.inpatientSettings.hospitalCharges.surgicalFee;
                    var anaesthetistFee = vm.inpatientSettings.hospitalCharges.anaesthetistFee;
                    var operatingTheatre = vm.inpatientSettings.hospitalCharges.operatingTheatre;
                    var daySurgery = vm.inpatientSettings.daySurgery;
                    var organTransplant = vm.inpatientSettings.organTransplant;
                    vm.inpatientSob.push({
                        type: App.localize('InpatientSurgical'),
                        settings: [
                            {
                                name: App.localize('SurgicalFee'),
                                isCovered: surgicalFee.isCovered,
                                isAsCharged: surgicalFee.isAsCharged,
                                isThirteenthSchedule: vm.countryCode === 'MY',
                                amount: surgicalFee.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(
                                    surgicalFee.coveredCycle),
                                remarks: surgicalFee.remarks,
                            },
                            {
                                name: App.localize('AnaesthetistFee'),
                                isCovered: anaesthetistFee.isCovered,
                                isAsCharged: anaesthetistFee.isAsCharged,
                                isThirteenthSchedule: vm.countryCode === 'MY',
                                amount: anaesthetistFee.amount,
                                effectivePeriod: 'na',
                                remarks: anaesthetistFee.remarks,
                            },
                            {
                                name: App.localize('OperatingTheatre'),
                                isCovered: operatingTheatre.isCovered,
                                isAsCharged: operatingTheatre.isAsCharged,
                                isThirteenthSchedule: vm.countryCode === 'MY',
                                amount: operatingTheatre.amount,
                                effectivePeriod: 'na',
                                remarks: operatingTheatre.remarks,
                            },
                            {
                                name: App.localize('DaySurgery'),
                                isCovered: daySurgery.isCovered,
                                isAsCharged: daySurgery.isAsCharged,
                                amount: daySurgery.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(
                                    daySurgery.coveredCycle),
                                remarks: daySurgery.remarks,
                            },
                            {
                                name: App.localize('OrganTransplant'),
                                isCovered: organTransplant.isCovered,
                                isAsCharged: organTransplant.isAsCharged,
                                amount: organTransplant.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(
                                    organTransplant.coveredCycle),
                                remarks: organTransplant.remarks,
                            },
                        ],
                        hasEffectivePeriod: true
                    });
                    // #endregion InpatientSurgical SOB tab

                    // Outpatient
                    // #region OutpatientTreatment SOB tab
                    const outpatientCancer = vm.inpatientSettings.outpatientCancerTreatment;
                    const outpatientKidneyDialysis = 
                        vm.inpatientSettings.outpatientKidneyDialysisTreatment;
                    const outpatientPhysiotherapy = 
                        vm.inpatientSettings.outpatientPhysiotherapyTreatment;
                    vm.outpatientSob.push({
                        type: App.localize('OutpatientTreatment'),
                        settings: [
                            {
                                name: App.localize('OutpatientCancerTreatment'),
                                isCovered: outpatientCancer.isCovered,
                                isAsCharged: outpatientCancer.isAsCharged,
                                amount: outpatientCancer.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(
                                    outpatientCancer.coveredCycle),
                                remarks: outpatientCancer.remarks,
                            },
                            {
                                name: App.localize('OutpatientKidneyDialysisTreatment'),
                                isCovered: outpatientKidneyDialysis.isCovered,
                                isAsCharged: outpatientKidneyDialysis.isAsCharged,
                                amount: outpatientKidneyDialysis.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(
                                    outpatientKidneyDialysis.coveredCycle),
                                remarks: outpatientKidneyDialysis.remarks,
                            },
                            {
                                name: App.localize('OutpatientPhysiotherapyTreatment'),
                                isCovered: outpatientPhysiotherapy.isCovered,
                                isAsCharged: outpatientPhysiotherapy.isAsCharged,
                                amount: outpatientPhysiotherapy.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(
                                    outpatientPhysiotherapy.coveredCycle),
                                remarks: outpatientPhysiotherapy.remarks,
                            },
                        ],
                        hasEffectivePeriod: true
                    });
                    // #endregion OutpatientTreatment SOB tab

                    //#region PreHospitalizationTreatment SOB tab
                    var diagnosisTreatment = vm.inpatientSettings.diagnosisTreatment;
                    var specialistConsultation = vm.inpatientSettings.specialistConsultation;
                    var surgicalOpinion = vm.inpatientSettings.surgicalOpinion;
                    vm.outpatientSob.push({
                        type: App.localize('PreHospitalization'),
                        settings: [
                            {
                                name: App.localize('PreHospitalizationDiagnosisTest'),
                                isCovered: diagnosisTreatment.isCovered,
                                isAsCharged: diagnosisTreatment.isAsCharged,
                                amount: diagnosisTreatment.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(
                                    diagnosisTreatment.coveredCycle),
                                remarks: diagnosisTreatment.remarks,
                            },
                            {
                                name: App.localize('PreHospitalizationSpecialistConsultation'),
                                isCovered: specialistConsultation.isCovered,
                                isAsCharged: specialistConsultation.isAsCharged,
                                amount: specialistConsultation.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(
                                    specialistConsultation.coveredCycle),
                                remarks: specialistConsultation.remarks,
                            },
                            {
                                name: App.localize('SecondSurgicalOpinion'),
                                isCovered: surgicalOpinion.isCovered,
                                isAsCharged: surgicalOpinion.isAsCharged,
                                amount: surgicalOpinion.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(
                                    surgicalOpinion.coveredCycle),
                                daysPerYearLimit: surgicalOpinion.isUnlimited ? 'Unlimited' : surgicalOpinion.coveredTimes,
                                remarks: surgicalOpinion.remarks,
                            },
                        ],
                        hasMaxDaysTime: false,
                        hasEffectivePeriod: true,
                        hasDaysPerYear: vm.hasSobEnhancement,
                        coveredDays: vm.preHospitalizationTreatment.coveredDays
                    });
                    // #endregion PreHospitalizationTreatment SOB tab

                    // #region PostHospitalizationTreatment SOB tab
                    const postHospitalizationTreatment = vm.inpatientSettings.followUpTreatment;
                    vm.outpatientSob.push({
                        type: App.localize('PostHospitalization'),
                        settings: [
                            {
                                name: App.localize('PostHospitalizationFollowUpAndTreatment'),
                                isCovered: postHospitalizationTreatment.isCovered,
                                isAsCharged: postHospitalizationTreatment.isAsCharged,
                                amount: postHospitalizationTreatment.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(
                                    postHospitalizationTreatment.coveredCycle),
                                remarks: postHospitalizationTreatment.remarks,
                            },
                        ],
                        hasMaxDaysTime: false,
                        hasEffectivePeriod: true,
                        hasDaysPerYear: vm.hasSobEnhancement,
                        coveredDays: vm.postHospitalizationTreatment.coveredDays
                    });
                    // #endregion PostHospitalizationTreatment SOB tab

                    //#region EmergencyOutpatientTreatment SOB tab
                    var emergencyAccidentalOutpatientTreatment = vm.inpatientSettings.emergencyAccidentalOutpatientTreatment;
                    var emergencyAccidentalDentalTreatment = vm.inpatientSettings.emergencyAccidentalDentalTreatment;
                    var emergencyAccidentalSicknessTreatment = vm.inpatientSettings.emergencyAccidentalSicknessTreatment;

                    let emergencyAccidentalOutpatientFollowUpPeriod = null;
                    if (emergencyAccidentalOutpatientTreatment.followUpDays) {
                        emergencyAccidentalOutpatientFollowUpPeriod = 
                        `${App.localize('XDays', emergencyAccidentalOutpatientTreatment.followUpDays)}`;
                    } else if (emergencyAccidentalOutpatientTreatment.followUpTimes) {
                        emergencyAccidentalOutpatientFollowUpPeriod = 
                        `${App.localize('XTimes', emergencyAccidentalOutpatientTreatment.followUpTimes)}`;
                    }

                    let emergencyAccidentalDentalFollowUpPeriod = null;
                    if (emergencyAccidentalDentalTreatment.followUpDays) {
                        emergencyAccidentalDentalFollowUpPeriod = 
                        `${App.localize('XDays', emergencyAccidentalDentalTreatment.followUpDays)}`;
                    } else if (emergencyAccidentalDentalTreatment.followUpTimes) {
                        emergencyAccidentalDentalFollowUpPeriod = 
                        `${App.localize('XTimes', emergencyAccidentalDentalTreatment.followUpTimes)}`;
                    }

                    vm.outpatientSob.push({
                        type: App.localize('EmergencyOutpatientTreatment'),
                        settings: [
                            {
                                name: App.localize('EmergencyAccidentalOutpatientTreatment'),
                                isCovered: emergencyAccidentalOutpatientTreatment.isCovered,
                                isAsCharged: emergencyAccidentalOutpatientTreatment.isAsCharged,
                                amount: emergencyAccidentalOutpatientTreatment.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(
                                    emergencyAccidentalOutpatientTreatment.coveredCycle),
                                followUpPeriod: emergencyAccidentalOutpatientFollowUpPeriod,
                                followUpEffectivePeriod: App.localize('PerDisability'),
                                remarks: emergencyAccidentalOutpatientTreatment.remarks,
                            },
                            {
                                name: App.localize('EmergencyAccidentalDentalTreatment'),
                                isCovered: emergencyAccidentalDentalTreatment.isCovered,
                                isAsCharged: emergencyAccidentalDentalTreatment.isAsCharged,
                                amount: emergencyAccidentalDentalTreatment.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(
                                    emergencyAccidentalDentalTreatment.coveredCycle),
                                followUpPeriod: emergencyAccidentalDentalFollowUpPeriod,
                                followUpEffectivePeriod: App.localize('PerDisability'),
                                remarks: emergencyAccidentalDentalTreatment.remarks,
                            },
                            {
                                name: App.localize('EmergencyOutpatientSicknessTreatment'),
                                isCovered: emergencyAccidentalSicknessTreatment.isCovered,
                                isAsCharged: emergencyAccidentalSicknessTreatment.isAsCharged,
                                amount: emergencyAccidentalSicknessTreatment.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(
                                    emergencyAccidentalSicknessTreatment.coveredCycle),
                                followUpPeriod: 'na',
                                remarks: emergencyAccidentalSicknessTreatment.remarks,
                            },
                        ],
                        hasEffectivePeriod: true,
                        multiRowHeader: true,
                        hasFollowUp: true
                    });
                    // #endregion EmergencyOutpatientTreatment SOB tab

                    // Misc
                    //#region Misc SOB tab
                    var governmentHospitalDailyCashAllowance = vm.inpatientSettings.governmentHospitalDailyCashAllowance;
                    var privateHospitalDailyCashAllowance = vm.inpatientSettings.privateHospitalDailyCashAllowance;
                    var homeNursingCare = vm.inpatientSettings.homeNursingCare;
                    var accidentalDeathBenefit = vm.inpatientSettings.accidentalDeathBenefit;
                    var compassionateDeathBenefit = vm.inpatientSettings.compassionateDeathBenefit;
                    var funeralExpenses = vm.inpatientSettings.funeralExpenses;
                    vm.miscSob.push({
                        type: App.localize('Miscellaneous'),
                        settings: [
                            {
                                name: App.localize('GovernmentHospitalDailyCashAllowance'),
                                isCovered: governmentHospitalDailyCashAllowance.isCovered,
                                isAsCharged: governmentHospitalDailyCashAllowance.isAsCharged,
                                amount: governmentHospitalDailyCashAllowance.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(
                                    governmentHospitalDailyCashAllowance.coveredCycle),
                                accomodationDays: 
                                    governmentHospitalDailyCashAllowance.accomodationDays,
                                accomodationEffectivePeriod: App.localize('PerDay'),
                                remarks: governmentHospitalDailyCashAllowance.remarks,
                            },
                            {
                                name: App.localize('PrivateHospitalDailyCashAllowance'),
                                isCovered: privateHospitalDailyCashAllowance.isCovered,
                                isAsCharged: privateHospitalDailyCashAllowance.isAsCharged,
                                amount: privateHospitalDailyCashAllowance.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(
                                    privateHospitalDailyCashAllowance.coveredCycle),
                                accomodationDays: 
                                    privateHospitalDailyCashAllowance.accomodationDays,
                                accomodationEffectivePeriod: App.localize('PerDay'),
                                remarks: privateHospitalDailyCashAllowance.remarks,
                            },
                            {
                                name: App.localize('HomeNursingCare'),
                                isCovered: homeNursingCare.isCovered,
                                isAsCharged: homeNursingCare.isAsCharged,
                                amount: homeNursingCare.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(
                                    homeNursingCare.coveredCycle),
                                accomodationDays: 'na',
                                remarks: homeNursingCare.remarks,
                            },
                            {
                                name: App.localize('AccidentalDeathBenefit'),
                                isCovered: accidentalDeathBenefit.isCovered,
                                isAsCharged: accidentalDeathBenefit.isAsCharged,
                                amount: accidentalDeathBenefit.amount,
                                effectivePeriod: App.localize('PerEmploymentContract'),
                                accomodationDays: 'na',
                                remarks: accidentalDeathBenefit.remarks,
                            },
                            {
                                name: App.localize('CompassionateDeathBenefit'),
                                isCovered: compassionateDeathBenefit.isCovered,
                                isAsCharged: compassionateDeathBenefit.isAsCharged,
                                amount: compassionateDeathBenefit.amount,
                                effectivePeriod: App.localize('PerEmploymentContract'),
                                accomodationDays: 'na',
                                remarks: compassionateDeathBenefit.remarks,
                            },
                            {
                                name: App.localize('FuneralExpenses'),
                                isCovered: funeralExpenses.isCovered,
                                isAsCharged: funeralExpenses.isAsCharged,
                                amount: funeralExpenses.amount,
                                effectivePeriod: App.localize('PerEmploymentContract'),
                                accomodationDays: 'na',
                                remarks: funeralExpenses.remarks,
                            },
                        ],
                        hasEffectivePeriod: true,
                        multiRowHeader: true,
                        hasAccomodationDays: true
                    });
                    // #endregion Misc SOB tab
                } else {
                    // Inpatient
                    // #region Accomodation SOB
                    vm.inpatientSob.push({
                        type: App.localize('Accomodation'),
                        settings: [
                            {
                                name: App.localize('RoomAndBoard'),
                                isCovered: true,
                                isAsCharged: false,
                                amount: vm.roomAndBoardAmount,
                                effectivePeriod: 'Per day',
                                daysPerYearLimit: vm.roomAndBoardAccomodationDays,
                                isAccomodationDaysCombined: vm.isAccomodationDaysCombined,
                                accomodationDaysCombineText: App.localize('CombinedRoomAndBoardNarrative')
                            },
                            {
                                name: App.localize('IcuRoomAndBoard'),
                                isCovered: true,
                                isAsCharged: vm.icuRoomAndBoard.isAsCharged,
                                amount: vm.icuRoomAndBoard.amount,
                                effectivePeriod: 'Per day',
                                daysPerYearLimit: vm.icuRoomAndBoardAccomodationDays,
                                isAccomodationDaysCombined: vm.isAccomodationDaysCombined,
                                accomodationDaysCombineText: App.localize('CombinedIcuRoomAndBoardNarrative')
                            },
                            {
                                name: App.localize('ChildDailyGuardianBenefitLodgingOrBed'),
                                isCovered: vm.childGuardianBenefit.isCovered,
                                isAsCharged: vm.childGuardianBenefit.isAsCharged,
                                amount: vm.childGuardianBenefit.amount,
                                effectivePeriod: 'Per day'
                            },
                        ],
                        hasMaxDaysTime: false,
                        hasEffectivePeriod: true,
                        hasDaysPerYear: vm.hasSobEnhancement
                    });
                    // #endregion Accomodation SOB

                    // #region Doctor Chargers SOB
                    if (vm.hasSobEnhancement) {
                        vm.inpatientSob.push({
                            type: App.localize('DoctorCharges'),
                            settings: [
                                { 
                                    name: App.localize('InHospitalDoctorVisit'), 
                                    isCovered: true, 
                                    isAsCharged: true, 
                                    amount: null, 
                                    effectivePeriod: 'Per day', 
                                    maxDaysTime: vm.physicianVisitPerDay, 
                                    isThirteenthSchedule: vm.countryCode === 'MY', 
                                    daysPerYearLimit: vm.physicianDaysPerYear 
                                }
                            ],
                            hasMaxDaysTime: true,
                            hasEffectivePeriod: true,
                            hasDaysPerYear: vm.hasSobEnhancement
                        });
                    }
                    // #endregion Doctor Chargers SOB

                    // #region Hospital Chargers SOB
                    vm.inpatientSob.push({
                        type: App.localize('HospitalCharges'),
                        isFixedLimit: vm.hospitalCharges.isFixedLimit,
                        fixedLimitAmount: vm.hospitalCharges.fixedLimitAmount,
                        settings: {
                            physicianVisit: {
                                isAsCharged: true, 
                                amount: null, 
                                effectivePeriod: 'Per day', 
                                maxDaysTime: vm.physicianVisitPerDay
                            },
                            surgicalFees: {
                                isAsCharged: vm.surgicalFee.isAsCharged, 
                                amount: vm.surgicalFee.amount, 
                                effectivePeriod: null, 
                                maxDaysTime: false
                            },
                            anaesthetistFees: {
                                isAsCharged: vm.anaesthetistFee.isAsCharged, 
                                amount: vm.anaesthetistFee.amount, 
                                effectivePeriod: null, 
                                maxDaysTime: false
                            },
                            operatingTheatre: {
                                isAsCharged: vm.operatingTheatre.isAsCharged, 
                                amount: vm.operatingTheatre.amount, 
                                effectivePeriod: null, 
                                maxDaysTime: false
                            },
                            suppliesAndServices: {
                                isAsCharged: vm.hospitalSuppliesAndServices.isAsCharged, 
                                amount: vm.hospitalSuppliesAndServices.amount, 
                                effectivePeriod: null, 
                                maxDaysTime: false
                            },
                            ambulanceFee: {
                                isCovered: vm.ambulanceFee.isCovered, 
                                isAsCharged: vm.ambulanceFee.isAsCharged, 
                                amount: vm.ambulanceFee.amount, 
                                effectivePeriod: 'Per day', 
                                maxDaysTime: false
                            },
                            organTransplant: {
                                isCovered: vm.organTransplant.isCovered, 
                                isAsCharged: vm.organTransplant.isAsCharged, 
                                amount: vm.organTransplant.amount, 
                                effectivePeriod: 'Per employment contract', 
                                maxDaysTime: vm.organTransplant.isUnlimited 
                                    ? App.localize('Unlimited') 
                                    : vm.organTransplant.coveredTimes
                            }
                        },
                        hasMaxDaysTime: false,
                        hasEffectivePeriod: true
                    });
                    // #endregion Hospital Chargers SOB

                    // #region Self Pay Hospitalisation SOB
                    vm.inpatientSob.push({
                        type: App.localize('SelfPayHospitalization'),
                        settings: [
                            { 
                                name: App.localize('MedicalReport'), 
                                isCovered: true, 
                                isAsCharged: vm.medicalReport.isAsCharged, 
                                amount: vm.medicalReport.amount, 
                                effectivePeriod: null 
                            }
                        ],
                        hasMaxDaysTime: false,
                        hasEffectivePeriod: false
                    });
                    // #endregion Self Pay Hospitalisation SOB

                    // Outpatient
                    // #region Outpatient SOB
                    vm.outpatientSob = [
                        {
                            type: App.localize('PreHospitalizationTreatment'),
                            settings: [
                                {
                                    name: App.localize('PreHospitalizationDiagnosisTest'),
                                    isCovered: vm.preHospitalizationTreatment.isCovered,
                                    isAsCharged: true,
                                    amount: null,
                                    effectivePeriod: App.localize('PerAdmission'), maxDaysTime: null
                                },
                                {
                                    name: App.localize('PreHospitalizationSpecialistConsultation'),
                                    isCovered: vm.preHospitalizationTreatment.isCovered,
                                    isAsCharged: true,
                                    amount: null,
                                    effectivePeriod: App.localize('PerAdmission'),
                                    maxDaysTime: vm.specialistConsultation.isUnlimited 
                                        ? 'Unlimited' 
                                        : vm.specialistConsultation.coveredTimes
                                },
                                {
                                    name: App.localize('SecondSurgicalOpinion'),
                                    isCovered: vm.preHospitalizationTreatment.isCovered,
                                    isAsCharged: vm.surgicalOpinion.isAsCharged,
                                    amount: vm.surgicalOpinion.amount,
                                    effectivePeriod: App.localize('PerAdmission'),
                                    maxDaysTime: vm.surgicalOpinion.isUnlimited 
                                        ? 'Unlimited' 
                                        : vm.surgicalOpinion.coveredTimes
                                }
                            ],
                            hasMaxDaysTime: !!vm.preHospitalizationTreatment.isCovered,
                            hasEffectivePeriod: true,
                            coveredDays: vm.preHospitalizationTreatment.coveredDays
                        },
                        {
                            type: App.localize('PostHospitalizationTreatment'),
                            settings: [
                                { 
                                    name: App.localize('PostHospitalizationFollowUpAndTreatment'), 
                                    isCovered: vm.postHospitalizationTreatment.isCovered, 
                                    isAsCharged: vm.followUpTreatment.isAsCharged, 
                                    amount: vm.followUpTreatment.amount, 
                                    effectivePeriod: 'Per admission' 
                                }
                            ],
                            hasMaxDaysTime: false,
                            hasEffectivePeriod: true,
                            coveredDays: vm.postHospitalizationTreatment.coveredDays
                        },
                        {
                            type: App.localize('Others'),
                            settings: [
                                { 
                                    name: App.localize('OutpatientCancerTreatment'), 
                                    isCovered: vm.outpatientCancerTreatment.isCovered, 
                                    isAsCharged: false, 
                                    amount: vm.outpatientCancerTreatment.amount, 
                                    effectivePeriod: 'Per annum' 
                                },
                                { 
                                    name: App.localize('OutpatientKidneyDialysisTreatment'), 
                                    isCovered: vm.outpatientKidneyDialysisTreatment.isCovered, 
                                    isAsCharged: false, 
                                    amount: vm.outpatientKidneyDialysisTreatment.amount, 
                                    effectivePeriod: 'Per annum' 
                                },
                            ],
                            hasMaxDaysTime: false,
                            hasEffectivePeriod: true
                        },
                    ];
                    // #endregion Outpatient SOB
                }
            }
            else {
                vm.maternitySetting = maternitySetting;
                vm.normalDelivery = vm.maternitySetting.normalDelivery;
                vm.caesareanDelivery = vm.maternitySetting.caesareanDelivery;
                vm.normalDeliveryTwinsAndAbove = vm.maternitySetting.normalDeliveryTwinsAndAbove;
                vm.caesareanDeliveryTwinsAndAbove = 
                    vm.maternitySetting.caesareanDeliveryTwinsAndAbove;

                vm.preNatalCheckUp = vm.maternitySetting.preNatalCheckUp;
                vm.postNatalFollowUp = vm.maternitySetting.postNatalFollowUp;
                vm.miscarriage = vm.maternitySetting.miscarriage;
                vm.neoNatal = vm.maternitySetting.neoNatal;

                vm.maternitySob = [
                    {
                        header: App.localize('Delivery'),
                        settings: [
                            { 
                                name: App.localize('NormalDelivery'), 
                                isCovered: vm.normalDelivery.isCovered, 
                                isAsCharged: vm.normalDelivery.isAsCharged, 
                                amount: vm.normalDelivery.amount, 
                                effectivePeriod: vm.normalDelivery.coveredCycle === 0 
                                    ? 'Per annum' 
                                    : 'Per delivery' 
                            },
                            { 
                                name: App.localize('CaesareanDelivery'), 
                                isCovered: vm.caesareanDelivery.isCovered, 
                                isAsCharged: vm.caesareanDelivery.isAsCharged, 
                                amount: vm.caesareanDelivery.amount, effectivePeriod: 
                                vm.caesareanDelivery.coveredCycle === 0 
                                    ? 'Per annum' 
                                    : 'Per delivery' 
                            },
                            { 
                                name: App.localize('NormalDeliveryForTwinsAndAbove'), 
                                isCovered: vm.normalDeliveryTwinsAndAbove.isCovered, 
                                isAsCharged: vm.normalDeliveryTwinsAndAbove.isAsCharged, 
                                amount: vm.normalDeliveryTwinsAndAbove.amount, 
                                effectivePeriod: vm.normalDeliveryTwinsAndAbove.coveredCycle === 0 
                                    ? 'Per annum' 
                                    : 'Per delivery' 
                            },
                            { 
                                name: App.localize('CaesareanDeliveryForTwinsAndAbove'), 
                                isCovered: vm.caesareanDeliveryTwinsAndAbove.isCovered, 
                                isAsCharged: vm.caesareanDeliveryTwinsAndAbove.isAsCharged, 
                                amount: vm.caesareanDeliveryTwinsAndAbove.amount, 
                                effectivePeriod: 
                                    vm.caesareanDeliveryTwinsAndAbove.coveredCycle === 0 
                                    ? 'Per annum' 
                                    : 'Per delivery' 
                            }
                        ],
                        hasEffectivePeriod: true
                    },
                    {
                        header: App.localize('PreNatalCheckUp'),
                        isCovered: vm.preNatalCheckUp.isCovered,
                        coveredDaysHeader: vm.preNatalCheckUp.coveredDays,
                        settings: [
                            { 
                                name: App.localize('PreNatalCheckUp'), 
                                isCovered: vm.preNatalCheckUp.isCovered, 
                                isAsCharged: vm.preNatalCheckUp.isAsCharged, 
                                amount: vm.preNatalCheckUp.amount, 
                                effectivePeriod: 'Per annum' 
                            }
                        ],
                        hasEffectivePeriod: true
                    },
                    {
                        header: App.localize('PostNatalFollowUp'),
                        isCovered: vm.postNatalFollowUp.isCovered,
                        coveredDaysHeader: vm.postNatalFollowUp.coveredDays,
                        settings: [
                            { 
                                name: App.localize('PostNatalFollowUp'), 
                                isCovered: vm.postNatalFollowUp.isCovered, 
                                isAsCharged: vm.postNatalFollowUp.isAsCharged, 
                                amount: vm.postNatalFollowUp.amount, 
                                effectivePeriod: 'Lifetime total' 
                            },
                            { 
                                name: App.localize('Miscarriage'), 
                                isCovered: vm.miscarriage.isCovered, 
                                isAsCharged: vm.miscarriage.isAsCharged, 
                                amount: vm.miscarriage.amount, 
                                effectivePeriod: null 
                            }
                        ],
                        hasEffectivePeriod: true,
                        pregnancyRelatedComplications: [
                            'Disseminated Intravascular Coagulation', 
                            'Molar Pregnancy', 
                            'Eclampsia', 
                            'Death of Foetus', 
                            'Amniotic Fluid Embolism']
                    },
                    {
                        header: App.localize('NeoNatal'),
                        isCovered: vm.neoNatal.isCovered,
                        coveredDaysHeader: vm.neoNatal.coveredDays,
                        settings: [
                            { 
                                name: App.localize('NewBornNICUHDU'), 
                                isCovered: vm.neoNatal.isCovered, 
                                isAsCharged: vm.neoNatal.isAsCharged, 
                                amount: vm.neoNatal.newBornNicuAmount, 
                                effectivePeriod: 'Per annum' 
                            },
                            { 
                                name: App.localize('NewBornIncubation'), 
                                isCovered: vm.neoNatal.isCovered, 
                                isAsCharged: vm.neoNatal.isAsCharged, 
                                amount: vm.neoNatal.newBornIncubationAmount, 
                                effectivePeriod: 'Per annum' 
                            },
                            { 
                                name: App.localize('NewBornPhototherapyTreatment'), 
                                isCovered: vm.neoNatal.isCovered, 
                                isAsCharged: vm.neoNatal.isAsCharged, 
                                amount: vm.neoNatal.newBornPhotoTherapyAmount, 
                                effectivePeriod: 'Per annum' 
                            }
                        ],
                        hasEffectivePeriod: true
                    }
                ];
            }
        }

        vm.close = function () {
            $uibModalInstance.close();
        };

        init();

        function coveredCycleToEffectivePeriod(coveredCycle = null) {
            let effectivePeriod = 'na';
            // eslint-disable-next-line default-case
            switch (coveredCycle) {
                case 1: effectivePeriod = 'Per day'; break;
                case 2: effectivePeriod = 'Per day'; break;
                case 3: effectivePeriod = 'Per annum'; break;
                case 4: effectivePeriod = 'Per employment contract'; break;
                case 5: effectivePeriod = 'Per report'; break;
                case 6: effectivePeriod = 'Per admission'; break;
                case 7: effectivePeriod = 'Per disability'; break;
            }
            return effectivePeriod;
        }
    }
})();
