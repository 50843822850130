(() => {
  angular
    .module('app')
    .controller('clinic.views.doctors.createOrEditModal', DoctorEditModalController);

  DoctorEditModalController.$inject = [
    '$scope',
    '$uibModalInstance',
    'abp.services.app.doctor',
    'doctorId',
    'clinicLocationId',
    'Hms.Doctors.Doctor',
  ];

  function DoctorEditModalController(
    $scope,
    $uibModalInstance,
    doctorSvc,
    doctorId,
    clinicLocationId,
    constsDoctor
  ) {
    const vm = this;

    vm.constsDoctor = constsDoctor;
    vm.isSpecialist = App.isSpecialist();
    vm.isHospital = App.isHospital();
    vm.loading = false;
    vm.saving = false;
    vm.doctor = null;
    vm.educations = [];
    vm.clinicLocations = [];
    vm.specialistDisciplines = [];

    vm.grantAll = grantAll;
    vm.clearAll = clearAll;
    vm.save = save;
    vm.cancel = cancel;
    vm.addEducation = addEducation;
    vm.deleteEducation = deleteEducation;

    init();

    function init() {
      vm.loading += 1;
      doctorSvc
        .getDoctorForEdit({
          id: doctorId,
        })
        .success((data) => {
          vm.doctor = data.doctor;
          vm.clinicLocations = data.clinicLocations;
          vm.educations = data.doctor ? vm.doctor.educations : [];
          vm.specialistDisciplines = data.specialistDisciplines;
          vm.panelAccountOwnerCountryCode = data.panelAccountOwnerCountryCode;
        })
        .finally(() => {
          if (!vm.doctor)
            vm.doctor = { name: '', clinicLocationId: [], isPermanent: App.isHospital() ? 1 : 0 };
          else vm.doctor.isPermanent = vm.doctor.isPermanent ? 1 : 0;
          if (clinicLocationId) vm.doctor.clinicLocationId.push(clinicLocationId);
          vm.addEducation();
          vm.loading -= 1;
        });
    }

    function grantAll() {
      vm.doctor.clinicLocationId = _.map(vm.clinicLocations, (d) => d.value);
    }

    function clearAll() {
      vm.doctor.clinicLocationId = [];
    }

    function save() {
      const form = $scope.doctorCreateOrEditForm;
      if (!App.isFormValid(form)) {
        return;
      }

      vm.doctor.educations = vm.educations;

      vm.saving += 1;
      doctorSvc
        .createOrUpdateDoctor(vm.doctor)
        .success((data) => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          $uibModalInstance.close({ doctorId: data.id });
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function addEducation() {
      vm.educations.push({});
    }

    function deleteEducation(index) {
      if (vm.educations.length > 1) {
        vm.educations.splice(index, 1);
      } else {
        vm.educations = [];
        addEducation();
      }
    }
  }
})();
