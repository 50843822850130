
((function () {
  angular
    .module('app')
    .filter('doctorType', doctorType)
    // For backward compatibility only. Filter name don't need "Filter" suffix.
    // AngularJS automatically appends "Filter" to name for DI.
    .filter('doctorTypeFilter', doctorType);

  doctorType.$inject = [];

  function doctorType() {
    return doctorTypeFilter;

    function doctorTypeFilter(value) {
      switch (value) {
        case 1:
          return App.localize('Anaesthetist');
        case 2:
          return App.localize('Doctor');
        default:
          return value;
      }
    }
  }
})());
