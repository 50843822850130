import libphonenumber from 'google-libphonenumber';

(function () {
  angular.module('app').component('clinicPatientTicketPharmacyPrescription', {
    templateUrl: require('/App/clinic/views/patient/ticket/pharmacyPrescription/widget.html'),
    controller: ['$filter', 'abp.services.app.drugSearch', 'abp.services.app.patientTicket', 'Hms.Prescriptions.Prescription', controller],
    controllerAs: 'vm',
    bindings: {
      isDisabled: '<',
      isExpanded: '<',
      isCompleted: '<',
      isReadOnly: '<',
      onDirtyEvent: '<',
      onSetPristineEvent: '<',
      quantity: '<',
      dosage: '<',
      consultation: '<',
      treatments: '<',
      pharmacyPrescription: '<',
      pharmacy: '<',
      pharmacyLocations: '<',
      patient: '<',
      onSyncExpansion: '&',
      onValidateTreatment: '&',
      onComplete: '&',
      countryCode: '<'
    }
  });

  function controller($filter, drugSearchSvc, patientTicketSvc, constsPrescription) {
    var vm = this;
    vm.constsPrescription = constsPrescription;

    vm.$onChanges = function (changes) {
      tidyTreatments();
      refreshReadOnlyItems();

      if (changes.isExpanded) {
        vm.isOpen = vm.isExpanded;
        updatePostHeader();
      }

      if (changes.onDirtyEvent && changes.onDirtyEvent.currentValue) {
        vm.onDirtyEvent.handler = vm.onDirtyEventHandler;
      }

      if (changes.onSetPristineEvent && changes.onSetPristineEvent.currentValue) {
        vm.onSetPristineEvent.handler = vm.onSetPristineEventHandler;
      }
    };

    vm.$doCheck = function () {
      if (vm.isOpen !== vm.isOpenPreviousValue) {
        // Component expansion must be in sync with parent component's isExpanded state to working as intended.
        // uib-accordion toggles isOpen by itself when user clicks on it.

        vm.onSyncExpansion({
          isExpanded: vm.isOpen
        });
        vm.isOpenPreviousValue = vm.isOpen;
      }
    };

    vm.isOpen = false;
    vm.isOpenPreviousValue = false;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.summaryMessage = '';
    vm.filteredDrugs = [];
    vm.readOnlyItems = [];
    vm.emailPattern = App.getEmailRegex();

    var unitValueFilter = $filter('unitValue');
    var frequencyUnitValueFilter = $filter('frequencyUnitValue');

    vm.onDirtyEventHandler = function () {
      return vm.pharmacyForm.$dirty;
    };

    vm.onSetPristineEventHandler = function () {
      vm.pharmacyForm.$setPristine();
    };

    function refreshReadOnlyItems() {
      var readOnlyItems = [];

      if (vm.pharmacyPrescription) {
        _.forEach(vm.pharmacyPrescription.treatments, function (t) {
          if (!t.treatmentId) return;

          var treatment = _.find(vm.treatments, {
            id: t.treatmentId
          });
          var treatmentName = treatment ? treatment.displayName : '';

          _.forEach(t.patientTicketPrescriptions, function (p) {
            readOnlyItems.push({
              treatmentName: treatmentName,
              name: p.name,
              strength: unitValueFilter(p.strength),
              quantity: unitValueFilter(p.quantity),
              dosage: unitValueFilter(p.dosage),
              dosageFrequency: frequencyUnitValueFilter(p.dosageFrequency)
            });
          });
        });
      }

      vm.readOnlyItems = readOnlyItems;
    }

    // To retrieve drug listing including the manual entry (drug suggestion) added by clinic user.

    vm.checkDuplicateTreatment = function (treatment) {
      return _.some(vm.pharmacyPrescription.treatments, {
        'treatmentId': treatment.id
      });
    };

    vm.updateTreatment = function () {
      tidyTreatments();
    };

    vm.tidyTreatmentPrescriptions = function (treatment) {
      // Initialise prescriptions array if necessary

      if (!treatment.prescriptions) treatment.prescriptions = [];

      // Calculate prescription

      var filledCnt = 0;
      var blankCnt = 0;
      _.each(treatment.prescriptions, function (p) {
        if (p.drugId) filledCnt++;
        else blankCnt++;
      });
      treatment.prescriptionCount = filledCnt;

      // Remove superfluos empty prescriptions

      while (blankCnt > 1) {
        for (var i = treatment.prescriptions.length - 1; i >= 0; i--) {
          if (!treatment.prescriptions[i].drugId) {
            treatment.prescriptions.splice(i, 1);
            blankCnt--;
          }
        }
      }

      // Ensure there is always a blank prescription line

      if (blankCnt < 1) treatment.prescriptions.push({});

      // Update treatment error message

      var errorMessage = '';
      if (treatment.treatmentId) {
        _.each(vm.pharmacyPrescription.treatments, function (t) {
          if (t === treatment) return false;
          if (t.treatmentId === treatment.treatmentId) {
            errorMessage = App.localize('DuplicateTreatmentValidationErrorMessage');
            return false;
          }
        });

        if (!errorMessage && !filledCnt) {
          errorMessage = App.localize('KeyInOnePrescriptionProcedure');
        }

        if (!errorMessage && filledCnt) {
          _.each(treatment.prescriptions, function (p) {
            if (!p.drugId) return;

            if (!p.quantity) {
              errorMessage = App.localize('MissingQuantityForX', p.name);
              return false;
            }
          });
        }
      } else {
        var hasTreatment = _.some(vm.pharmacyPrescription.treatments, 'treatmentId');
        if (!hasTreatment) errorMessage = App.localize('EmptyTreatmentValidationErrorMessage');
      }
      treatment.errorMessage = errorMessage;
    };

    vm.treatmentCount = 0;

    function tidyTreatments() {
      // Check that ticket is defined

      if (!vm.pharmacyPrescription) return;

      // Initialise treatment array if necessary

      if (!vm.pharmacyPrescription.treatments) vm.pharmacyPrescription.treatments = [];

      // Calculate blank treatments

      var filledCnt = 0;
      var blankCnt = 0;
      _.forEach(vm.pharmacyPrescription.treatments, function (t) {
        if (t.treatmentId) filledCnt++;
        else blankCnt++;
      });
      vm.treatmentCount = filledCnt;

      // Remove superfluos empty treatments

      while (blankCnt > 1) {
        for (var i = vm.pharmacyPrescription.treatments.length - 1; i >= 0; i--) {
          if (!(+vm.pharmacyPrescription.treatments[i].treatmentId)) {
            vm.pharmacyPrescription.treatments.splice(i, 1);
            blankCnt--;
          }
        }
      }

      // Ensure there is always a blank treatment line

      if (blankCnt < 1) vm.pharmacyPrescription.treatments.push({});

      // For each treatment, tidy their prescriptions

      _.forEach(vm.pharmacyPrescription.treatments, function (t) {
        vm.tidyTreatmentPrescriptions(t);
      });
    }

    vm.updatePrescription = function (prescription) {
      if (prescription.drugId) {
        var selectedDrug = _.find(vm.filteredDrugs, {
          id: prescription.drugId
        });
        if (selectedDrug) {
          if (
            selectedDrug.strength !== null &&
            isFinite(selectedDrug.strength) &&
            selectedDrug.strengthUnit) {
            prescription.strength = {
              value: selectedDrug.strength,
              unit: selectedDrug.strengthUnit,
              text: '' + selectedDrug.strength + ' ' + selectedDrug.strengthUnit
            };
          } else {
            prescription.strength = null;
          }

          prescription.preferredQuantityUnit = selectedDrug.quantityUnit || '';
          prescription.preferredDosageUnit = selectedDrug.dosageUnit || '';
          prescription.name = selectedDrug.productName;
        }
      } else {
        prescription.name = null;
      }
      tidyTreatments();
    };

    vm.next = function () {
      if (App.isFormValid(vm.pharmacyForm) && vm.areFieldsValid()) {
        vm.onComplete();
      }
    };

    vm.areFieldsValid = function () {
      if (vm.isReadOnly) return true;
      if (!vm.pharmacyPrescription) return false;
      if (!vm.pharmacyPrescription.issue) return vm.consultation || vm.onValidateTreatment();

      // Ensure all required fields are filled in.

      if (!vm.pharmacyPrescription.pharmacyLocationId) return false;
      if (!vm.isPhoneValid()) return false;
      if (vm.pharmacyForm.patientEmailAddress.$invalid) return false;
      if (!vm.isAddressValid()) return false;

      var isValid = false;
      _.forEach(vm.pharmacyPrescription.treatments, function (t) {
        if (t.treatmentId) {
          isValid = false; // Reset value for each treatment
          _.forEach(t.prescriptions, function (p) {
            if (p.drugId) {
              if (!(isValid = !!p.quantity)) return false; // Break loop once invalid
            }
          });
          if (!isValid) return false; // Break loop once invalid
        }
      });

      return isValid;
    };

    vm.isAddressValid = function () {
      var value = _.trim(vm.pharmacyPrescription.customerAddress);
      return !!value;
    };

    vm.isPhoneValid = function () {
      var value = _.trim(vm.pharmacyPrescription.customerPhone);
      return value && validatePhone(value);
    };

    var lastFilter = '';
    var cachedResults = {};

    function cleanUserEntry(entry) {
      return _.map(_.split(_.toLower(entry), ' '), function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }).join(' ');
    }

    function searchDrugs(userEntry) {
      var cleanedUserEntry = cleanUserEntry(userEntry || '');
      filter = _.toLower(cleanedUserEntry);
      lastFilter = filter;

      if (filter) {
        var cachedDrugs = cachedResults[filter];

        if (cachedDrugs) {
          vm.filteredDrugs = postProcessDrugList(cachedDrugs, filter, cleanedUserEntry);
        } else {
          drugSearchSvc.searchDrugs({
            keyword: filter,
            countryCode: vm.countryCode
          }).success(function (d) {
            cachedResults[filter] = d.items;
            if (filter === lastFilter) {
              vm.filteredDrugs = postProcessDrugList(d.items, filter, cleanedUserEntry);
            }
          });
        }
      } else {
        vm.filteredDrugs = [];
      }

      function postProcessDrugList(drugList, filter, cleanedUserEntry) {
        var clonedItems = drugList.slice(0);
        var idx = _.findIndex(clonedItems, function (d) {
          return _.toLower(_.trim(d.productName || '')) === filter;
        });
        if (idx > 0) {
          var items = clonedItems.splice(idx, 1);
          clonedItems.splice(0, 0, items[0]);
        } else if (idx === -1) {
          clonedItems.splice(0, 0, {
            id: -1,
            productName: cleanedUserEntry
          });
        }
        return clonedItems;
      }
    }

    vm.lookupDrugs = function (filter) {
      searchDrugs(filter);
    };

    function validatePhone(phone) {
      try {
        var phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
        var valueProto = phoneUtil.parse(phone, "MY");
        return phoneUtil.isValidNumber(valueProto);
      } catch (ex) {
        return false;
      }
    }

    function updatePostHeader() {
      // Calculate number of treatments, prescriptions, and uncovered treatments

      var treatmentCount = 0;
      var prescriptionCount = 0;
      if (vm.pharmacyPrescription.issue) {
        _.forEach(vm.pharmacyPrescription.treatments, function (t) {
          if (!t.treatmentId) return;

          treatmentCount++;

          _.forEach(t.prescriptions, function (p) {
            if (p.drugId) prescriptionCount++;
          });
        });
      }

      // Construct header message

      var message = [];
      if (vm.pharmacyPrescription.issue && prescriptionCount) {
        message.push(App.localize('TreatmentAndPrescriptionSummaryNarrative',
          treatmentCount, prescriptionCount));
      } else {
        message.push(App.localize('NoTreatmentAndPrescriptionSummaryNarrative'));
      }

      // Assign view model values

      vm.summaryMessage = message.join('. ');
    }
  }
})();
