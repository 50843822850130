(() => {
  angular.module('app').directive('disableRunningNumber', disableRunningNumberDirective);

  disableRunningNumberDirective.$inject = [];

  function disableRunningNumberDirective() {
    const directive = {
      link,
      restrict: 'EA',
    };
    return directive;

    function link(_scope, element, attrs) {
      $(document).ready(() => {
        const disableScope = attrs.disableRunningNumber;
        // if disableScope returns 'all', disable both scrolling and up down keys on number type
        // input else if either 'scroll' or 'keys', disable that one specifically
        if (disableScope === 'all' || disableScope === 'scroll') {
          $(element[0]).on('focus', () => {
            $(this).on('wheel', (e) => {
              e.preventDefault();
              $(this).trigger('blur');
            });
          });
        }
        if (disableScope === 'all' || disableScope === 'keys') {
          $(element[0]).on('focus', () => {
            $(this).on('keydown', (e) => {
              if (e.which === 38 || e.which === 40) {
                e.preventDefault();
                $(this).trigger('blur');
              }
            });
          });
        }
      });
    }
  }
})();
