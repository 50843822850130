(() => {
    angular
        .module('app')
        .controller('corporate.views.employees.importResultModal', ImportResultModalController);

        ImportResultModalController.$inject = ['$scope', '$uibModalInstance', 'errors'];

    function ImportResultModalController($scope, $uibModalInstance, errors) {
        const vm = this;
        
        vm.errorCountText = App.localize("ImportFileHasXIssues", errors.length);
        vm.errorText = errors.join("\n");

        vm.cancel = () => {
            $uibModalInstance.dismiss();
        };

        init();

        function init() { }
    }
})();