(() => {
  angular.module('app').component('commonTaxInclusiveOptionsComponent', {
    templateUrl: require('./taxInclusiveOptions.component.html'),
    controller: TaxInclusiveOptionsController,
    controllerAs: 'vm',
    bindings: {
      selectedOption: '=?',
      updateTaxOption: '&',
    },
  });

  TaxInclusiveOptionsController.$inject = [];

  function TaxInclusiveOptionsController() {
    const vm = this;
    vm.example = false;

    vm.showExample = showExample;
    vm.hideExample = hideExample;

    function showExample() {
      vm.example = true;
    }

    function hideExample() {
      vm.example = false;
    }
  }
})();
