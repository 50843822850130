(() => {
  angular.module('app').component('hostCorporatesCreateEditFinance', {
    templateUrl: require('./widget.html'),
    controller: CorporateEditFinanceController,
    controllerAs: 'vm',
    require: {
      container: '^hostCorporatesCreateEditContainer',
    },
    bindings: {
      data: '<',
      currencyCode: '<',
      countryCode: '<',
      paymentMode: '<',
    },
  });

  CorporateEditFinanceController.$inject = [
    '$scope',
    'abp.services.app.corporate',
    'abp.services.app.commonLookup',
    'Hms.PatientTickets.ServiceFeePayor',
    'Hms.AccountOwners.PaymentAccountNumberMode',
    'Hms.MultiTenancy.CollateralType',
    'Hms.MultiTenancy.TenantVirtualAccountGenerationMode',
    'Hms.PaymentAccounts.PaymentAccount',
    'Hms.MultiTenancy.Tenant',
  ];

  function CorporateEditFinanceController(
    $scope,
    corporateSvc,
    commonLookupSvc,
    enumServiceFeePayor,
    enumPaymentAccountNumberMode,
    enumCollateralType,
    enumTenantVirtualAccountGenerationMode,
    constsPaymentAccount,
    constsTenant
  ) {
    const vm = this;
    vm.constsPaymentAccount = constsPaymentAccount;
    vm.constsTenant = constsTenant;
    vm.loading = 0;
    vm.saving = 0;
    vm.isCreate = false;
    vm.isEditing = false;

    vm.enums = {};
    vm.enums.serviceFeePayor = enumServiceFeePayor;
    vm.enums.paymentAccountNumberMode = enumPaymentAccountNumberMode;
    vm.enums.collateralType = enumCollateralType;
    vm.enums.tenantVirtualAccountGenerationMode = enumTenantVirtualAccountGenerationMode;

    // Default value

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');
    vm.countryCode = 'MY';
    vm.paymentMode = null;

    vm.panels = [];

    vm.permissions = {
      advanceView: abp.auth.isGranted('Host.Corporates.Advance'),
      editFinance: abp.auth.isGranted('Host.Corporates.Edit.Finance'),
      create: abp.auth.isGranted('Host.Corporates.Create'),
      override: abp.auth.isGranted('Host.Corporates.Override'),
    };

    let currentSearch = '';

    vm.isDirty = isDirty;
    vm.setPristine = setPristine;
    vm.validateForm = validateForm;
    vm.clearCollateralRenewDate = clearCollateralRenewDate;
    vm.clearContractRenewDate = clearContractRenewDate;
    vm.getPayload = getPayload;
    vm.lookupPanels = lookupPanels;
    vm.save = save;
    vm.$onChanges = onChanges;
    vm.$onInit = init;

    function init() {
      vm.container.addWidget(this);
      getBanks();
    }

    function isDirty() {
      return $scope.corporateCreateEditFinanceForm && $scope.corporateCreateEditFinanceForm.$dirty;
    }

    function setPristine() {
      $scope.corporateCreateEditFinanceForm.$setPristine();
    }

    function getBanks() {
      vm.loading += 1;
      commonLookupSvc
        .getBanks()
        .success((data) => {
          vm.banks = data.items;
        })
        .finally(() => {
          getBanksByCountryCode();
          vm.loading -= 1;
        });
    }

    function getBanksByCountryCode() {
      vm.banksByCountryCode = _.filter(vm.banks, (b) => b.countryCode === vm.countryCode);
    }

    // Force validation on this form.
    // True if valid, otherwise, false.

    function validateForm(error) {
      const form = $scope.corporateCreateEditFinanceForm;
      App.touchFormErrors(form);
      error = !form.$valid;
    }

    function clearCollateralRenewDate() {
      vm.data.collateralRenewDate = null;
    }

    function clearContractRenewDate() {
      vm.data.contractRenewDate = null;
    }

    // Generate the payload from this form to send to CorporateAppService API.

    function getPayload(settings) {
      const payload = {
        finance: vm.data,
      };

      // Update panels that bypass corporate limit

      vm.data.bypassCorporateLimitPanelIds = _.map(
        vm.data.bypassCorporateLimitPanels,
        (d) => d.value
      );

      _.extend(settings, payload);
    }

    function lookupPanels(search) {
      currentSearch = search;
      if (search) {
        const excludedPanelIds = _.map(vm.data.bypassCorporateLimitPanels, (r) => r.value);
        corporateSvc
          .findPanels({
            filter: search,
            maxResultCount: 50,
            excludedPanelIds,
          })
          .success((data) => {
            if (search === currentSearch) {
              vm.panels = data.items;
            }
          });
      }
    }

    // Ask container to save this widget.

    function save() {
      vm.container.saveWidget(vm);
    }

    function onChanges(changes) {
      vm.isCreate = vm.data ? !vm.data.id : false;

      // Filter list of banks according to country code

      if (changes.countryCode) {
        getBanksByCountryCode();
      }
    }
  }
})();
