(() => {
  angular.module('app').component('commonSpecialistClaimsTopUpRequestStatusComponent', {
    templateUrl: require('./topUpRequestStatus.component.html'),
    controller: TopUpRequestStatusController,
    controllerAs: 'vm',
    bindings: {
      claimNumber: '<',
      initialGuaranteeAmount: '<',
      requestTopUpAmount: '<',
      approvedTopUpAmount: '<',
      topUpRequestStatus: '<',
      remarks: '<',
      finalClaimableAmount: '<',
      finalGuaranteedAmount: '<',
      coPayAmount: '<',
      coveredAmount: '<',
    },
  });

  TopUpRequestStatusController.$inject = [];

  function TopUpRequestStatusController() {
    const vm = this;

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.hasTopUpRequest = false;

    vm.$onChanges = onChanges;
    vm.getCollectAmount = getCollectAmount;

    function onChanges() {
      vm.hasTopUpRequest =
        !_.isNil(vm.initialGuaranteeAmount) &&
        !_.isNil(vm.requestTopUpAmount) &&
        !_.isNil(vm.approvedTopUpAmount) &&
        !_.isNil(vm.topUpRequestStatus);
    }

    function getCollectAmount() {
      return Math.max(vm.finalClaimableAmount - vm.finalGuaranteedAmount, 0);
    }
  }
})();
