(function () {
    angular.module('app').controller('corporate.views.latePatientTicketSubmission.reject', [
        '$uibModalInstance', 'isDeletedEmployee',
        function ($uibModalInstance, isDeletedEmployee) {
            var vm = this;

            vm.cancel = cancel;
            vm.confirm = confirm;
            vm.remarks = isDeletedEmployee ? App.localize('RejectLateSubmission_DeletedEmployee') : '';

            function cancel() {
                $uibModalInstance.dismiss();
            }

            function confirm() {
                $uibModalInstance.close(vm.remarks);
            }
        }
    ]);
})();
