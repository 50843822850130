(function () {
    var componentId = 'clinicSpecialistDashboardClaimsPendingSubmissionWidget';
    var app = angular.module('app');

    function controller(specialistDashboardSvc, $state, $sce, $interval, $scope, $modal, enumGuaranteeLetterStatus, moment) {
        function init() {
            refresh();

            var tickerInterval = $interval(tickerJob, 1000);
            $scope.$on('$destroy', function () {
                if (angular.isDefined(tickerInterval)) {
                    $interval.cancel(tickerInterval);
                    tickerInterval = undefined;
                }
            });
        }

        var tickerCount = 0;
        function tickerJob() {
            if (!((++tickerCount) % 10) && !vm.loading && !isSilentLoading) {
                refresh(true);
            }
        }

        var vm = this;
        vm.loading = 0;
        var isSilentLoading = 0;

        vm.enums = {};
        vm.enums.guaranteeLetterStatus = enumGuaranteeLetterStatus;

        vm.permissions = {
            canCreateClaim: abp.auth.isGranted('SpecialistClaims.Clinic.Create')
        };

        function refresh(silentLoad) {
            if (vm.loading || isSilentLoading) return;

            if (silentLoad) isSilentLoading++;
            else vm.loading++;

            specialistDashboardSvc.getClaimsPendingSubmission(
                { abpHandleError: false }
            ).success(function (data) {
                vm.items = data.items;
            }).finally(function () {
                if (silentLoad) isSilentLoading--;
                else vm.loading--;
            });
        }

        vm.getSubmissionMessage = function (expiryTime) {
            if (!expiryTime) return '';

            var expiryTimeText = moment(expiryTime).format('L LT');

            return $sce.trustAsHtml(App.localize('PleaseSubmitByXMessage', expiryTimeText));
        };

        vm.requestExtension = function (gl) {
            $modal.open({
                templateUrl: require('/App/clinic/views/guaranteeLetters/extensionRequest/extensionRequest.modal.html'),
                controller: 'clinic.views.guaranteeLetter.extensionRequestModal as vm',
                backdrop: 'static',
                resolve: {
                    patientName: function () {
                        return gl.employeeName;
                    },
                    nationalId: function () {
                        return gl.employeeNationalId;
                    },
                    glNumber: function () {
                        return gl.letterNumber;
                    }
                }
            }).result.then(function () {
                refresh(false);
            });
        };

        vm.submitClaim = function (gl, claimWithTopUp) {
            $state.go('createEditSpecialistClaim', {
                employeeId: gl.employeeId,
                panelLocationId: gl.panelLocationId,
                letterNumber: gl.letterNumber,
                claimWithTopUp: claimWithTopUp,
                timeZoneId: vm.timeZoneId
            });
        }

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/clinic/views/specialistDashboard/widgets/claimsPendingSubmission/index.html'),
        controller: ['abp.services.app.specialistDashboard', '$state', '$sce', '$interval', '$scope', '$uibModal', 'Hms.GuaranteeLetters.GuaranteeLetterStatus', 'moment', controller],
        controllerAs: 'vm',
        bindings: {
            timeZoneId: '<'
        }
    });
})();
