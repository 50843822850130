(function () {
    var componentId = 'hostOperationsDashboardMonthlyManualTicketStatisticsWidget';
    var app = angular.module('app');

    function controller($uibModal, operationaDashboardSvc, moment) {
        function init() {
            vm.refresh();
        }

        var vm = this;
        vm.loading = 0;
        vm.exporting = 0;

        vm.$onInit = function () {
            vm.container.addWidget(this);
        };

        function getMonthlyManualTicketStatistics(skipCount) {
            var input = getRequestParams(skipCount);

            vm.loading++;
            operationaDashboardSvc.getMonthlyManualTicketStatistics(input)
                .success(function (data) {
                    vm.totalCount = data.totalCount;
                    vm.data = vm.data.concat(data.items);
                    vm.toggleShowData();
                }).finally(function () {
                    vm.loading--;
                });
        }

        function getRequestParams(skipCount) {
            return {
                skipCount: !skipCount ? 0 : skipCount,
                maxResultCount: app.consts.maxResultCount,
                startDate: vm.dateRange.startDate,
                endDate: vm.dateRange.endDate
            };
        }

        vm.refresh = function () {
            vm.data = [];
            vm.showAll = true;
            getMonthlyManualTicketStatistics(0);
        };

        vm.toggleShowData = function () {
            vm.showAll = !vm.showAll;
            vm.records = vm.showAll ? vm.data : vm.data.slice(0, 5);
        };

        vm.dateRangeOptions = $.extend({}, App.createDateRangePickerOptions(), {
            eventHandlers: {
                'apply.daterangepicker': function (ev, picker) {
                    vm.refresh();
                }
            }
        });

        vm.dateRange = {
            startDate: moment().startOf('month'),
            endDate: moment().endOf('day')
        };

        vm.showDetail = function (record) {
            $uibModal.open({
                component: 'hostOperationsDashboardMonthlyManualTicketStatisticsDetail',
                backdrop: 'static',
                resolve: {
                    corporate: function () {
                        return {
                            name: record.corporateName,
                            manualClinics: record.manualClinics
                        };
                    }
                }
            });
        };

        vm.exportToExcel = function () {
            vm.exporting++;
            var input = getRequestParams();

            operationaDashboardSvc.getMonthlyManualTicketStatisticsToExcel(input)
                .success(function (data) {
                    App.downloadTempFile(data);
                }).finally(function () {
                    vm.exporting--;
                });;
        };

        init();
    }

    app.component(componentId, {
        require: {
            container: '^hostOperationsDashboardDashboardContainer'
        },
        templateUrl: require('/App/host/views/operationsDashboard/monthlyManualTicketStatistics/widget.html'),
        controller: ['$uibModal', 'abp.services.app.operationsDashboard', 'moment', controller],
        controllerAs: 'vm'
    });
})();
