(function () {
    'use strict';

    angular.module('app').component('clinicPanelDashboardClaimsCollectedComponent', {
        templateUrl: require('/App/clinic/views/panelDashboard/claimsCollected/claimsCollected.component.html'),
        controller: ['$uibModal', 'abp.services.app.panelDashboard', controller],
        controllerAs: 'vm',
        bindings: {
            panelLocationId: '<',
            isGpPanel: '<',
            currencyCode: '<?'
        }
    });

    function controller($uibModal, panelDashboardSvc) {
        var vm = this;

        vm.$onChanges = onChanges;
        vm.onTable = onTable;

        vm.hasGpDashboardEnhancement = abp.setting.getBoolean('Hms.Feature.GPDashboardEnhancement');
        vm.header = (vm.hasGpDashboardEnhancement && vm.isGpPanel) ? App.localize('CurrentMonthlyClaims') : App.localize('CashlessClaimsCollected');
        vm.growthDuration = App.localize('FromSameDayLastMonth');
        vm.currencyCode = _.isNil(vm.currencyCode) || vm.currencyCode === undefined
            ? abp.setting.get('Hms.General.CurrencyCode')
            : vm.currencyCode;

        function onChanges(changes) {
            if (changes.panelLocationId && changes.panelLocationId.currentValue) {
                getClaimsCollectedMetric();
            }
            
            vm.tooltip = App.localize('CashlessClaimsCollectedTooltip', vm.currencyCode);
        }

        function getClaimsCollectedMetric() {
            vm.loading++;
            panelDashboardSvc.getClaimsCollectedMetric({ id: vm.panelLocationId })
                .success(function (data) {
                    vm.metrics = data.metrics;
                    vm.growthRate = data.growthRate;
                    vm.momGrowthResult = data.momGrowthRate;
                })
                .finally(function () {
                    vm.loading--;
                });
        }

        function onTable() {
            $uibModal.open({
                component: 'clinicPanelDashboardGrowthTable',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                    result: function () {
                        return vm.momGrowthResult;
                    },
                    type: function () {
                        return "claimsCollected";
                    },
                    header: function () {
                        return vm.header;
                    }
                }
            });
        }
    }
})();
