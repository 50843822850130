(function () {
    var componentId = 'clinicHospitalQueryQueryItemComponent';

    angular
        .module('app')
        .component(componentId, {
            templateUrl: require('/App/clinic/views/hospital/query/queryItem/queryItem.component.html'),
            controller: ['abp.services.app.serviceRequestQuery', 'Hms.ServiceRequests.ServiceRequestQueryItemType', 'abp.services.app.commonLookup', controller],
            controllerAs: 'vm',
            bindToController: 'true',
            bindings: {
                item: '<'
            }
        });

    function controller(serviceRequestQuerySvc, enumServiceRequestQueryItemType, commonLookupSvc) {
        var vm = this;
        vm.exporting = 0;

        vm.downloadMedicalQueryForm = downloadMedicalQueryForm;

        vm.enums = {};
        vm.enums.serviceRequestQueryItemType = enumServiceRequestQueryItemType;

        vm.hasInpatientRegionalExpansion = abp.setting.getBoolean(
            'Hms.Feature.RegionalExpansionForInpatient');

        init();

        function init() {
            if (vm.hasInpatientRegionalExpansion) 
                getLanguage();
        }

        function getLanguage() {
            vm.loading += 1;
            commonLookupSvc
                .getDefaultLanguageByCountryCode()
                .success((data) => { vm.language = data })
                .finally(() => { vm.loading -= 1 });
        }

        function downloadMedicalQueryForm(language) {
            vm.exporting += 1;
            serviceRequestQuerySvc.getMedicalQueryFormPdf({ 
                id: vm.item.id, 
                languageCode: language 
                }).success((data) => {
                    App.downloadTempFile(data);
                }).finally(() => {
                    vm.exporting -= 1;
                });
        }
    }
})();
