(() => {
  angular
    .module('app')
    .controller('host.views.corporates.createEdit.index', CorporateEditIndexController);

  CorporateEditIndexController.$inject = [];

  function CorporateEditIndexController() {
    init();

    function init() {}
  }
})();
