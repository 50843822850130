(() => {
  angular
    .module('app')
    .controller('common.views.saleTransactions.paymentInstruction', PaymentInstructionController);

  PaymentInstructionController.$inject = [
    '$stateParams',
    'abp.services.app.saleTransaction',
    'moment',
    '$rootScope',
  ];

  function PaymentInstructionController($stateParams, saleTransactionSvc, moment, $root) {
    const vm = this;

    vm.stopInvoiceGeneration = abp.setting.getBoolean('Hms.Feature.StopPortalInvoiceGeneration');

    vm.loading = 0;
    vm.requestParams = {
      transactionNumber: $stateParams.transactionNumber,
      requestId: $stateParams.requestId,
    };

    vm.now = moment();

    init();

    function init() {
      vm.loading += 1;
      saleTransactionSvc
        .getPaymentInstructions(vm.requestParams)
        .success((data) => {
          vm.hostInfo = data.from;
          vm.paymentInstructions = data.instructions;
          vm.verifier = data.verifier;
          vm.approver = data.approver;
        })
        .finally(() => {
          vm.loading -= 1;
        });

      const transactionNumber = vm.requestParams.transactionNumber || '';
      const { requestId } = vm.requestParams;
      vm.printUrl = $root.resolveAppPath(
        `Print/PaymentInstruction?transactionNumber=${transactionNumber}&requestId=${requestId}`
      );
    }
  }
})();
