(() => {
  angular.module('app').filter('unitValue', unitValue);

  unitValue.$inject = [];

  function unitValue() {
    return unitValueFilter;

    function unitValueFilter(value) {
      return value && value.unit ? `${value.value} ${value.unit}` : '';
    }
  }
})();
