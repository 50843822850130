import swal from 'sweetalert';

(() => {
  angular
    .module('app')
    .controller(
      'common.views.guaranteeLetters.details.letterDetail.updateModal',
      UpdateModalController
    );

  UpdateModalController.$inject = [
    '$scope',
    '$uibModalInstance',
    'letter',
    'abp.services.app.guaranteeLetter',
    'moment',
  ];

  function UpdateModalController($scope, $uibModalInstance, letter, guaranteeLetterSvc, moment) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;
    vm.letter = letter;

    vm.save = save;
    vm.cancel = cancel;

    init();

    function init() {
      vm.loading += 1;
      guaranteeLetterSvc
        .getGuaranteeLetterForEdit({
          id: vm.letter.id,
        })
        .success((data) => {
          vm.disciplineIds = data.letter.disciplineIds;
          vm.medicationAllowance = data.letter.medicationAllowance;
          vm.appointmentDate = data.letter.appointmentDate
            ? moment.utc(data.letter.appointmentDate)
            : null;
          vm.disciplines = data.disciplines;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function save() {
      swal(
        {
          title: App.localize('UpdateGuaranteeLetter'),
          text: App.localize('WhyUpdateGuaranteeLetter'),
          type: 'input',
          showCancelButton: true,
          closeOnConfirm: false,
          confirmButtonColor: '#1ab394',
          inputPlaceholder: App.localize('PleaseExplain'),
        },
        (inputValue) => {
          if (inputValue === false) return false;
          if ($.trim(inputValue || '') === '') {
            swal.showInputError(App.localize('InvalidInput'));
            return false;
          }
          updateGuaranteeLetter($.trim(inputValue));

          return true;
        }
      );
    }

    function updateGuaranteeLetter(remarks) {
      vm.saving += 1;
      guaranteeLetterSvc
        .updateGuaranteeLetter({
          id: vm.letter.id,
          disciplineIds: vm.disciplineIds,
          medicationAllowance: vm.medicationAllowance,
          appointmentDate: App.getDateString(vm.appointmentDate),
          remarks,
        })
        .success(() => {
          swal.close();
          abp.notify.info(App.localize('SuccessfullySaved'));
          $uibModalInstance.close();
        })
        .finally(() => {
          vm.saving = false;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
