(function () {
    'use strict';

    angular.module('app').component('hostHealthScreeningClaimsCommonLateSubmissionDetailsComponent', {
        templateUrl: require('/App/host/views/healthScreeningClaims/common/lateSubmissionDetails/lateSubmissionDetails.component.html'),
        controller: ['$attrs', controller],
        controllerAs: 'vm',
        bindings: {
            ticket: '<',
            finalRequest: '<',
        }
    });

    function controller($attrs) {
        var vm = this;
        vm.isOpen = true;
        vm.attrs = $attrs;
    }
})();
