import _ from 'lodash';
import swal from 'sweetalert';

(() => {
  angular
    .module('finance')
    .controller('finance.views.payments.createEditPayment', FinanceCreateEditPaymentController);

  FinanceCreateEditPaymentController.$inject = [
    '$stateParams',
    '$state',
    '$window',
    'Hms.Payments.PaymentMethod',
    'Hms.Payments.Payment',
    'moment',
    'abp.services.finance.payments',
    'abp.services.finance.commonLookup',
    'abp.services.app.financeCommon',
  ];

  function FinanceCreateEditPaymentController(
    $stateParams,
    $state,
    $window,
    enumPaymentMethod,
    constsPayment,
    moment,
    paymentsSvc,
    commonLookupSvc,
    portalFinanceCommonSvc
  ) {
    const vm = this;
    let paymentRetrieved = false;
    let { billingAccountType } = $stateParams;
    vm.constsPayment = constsPayment;
    vm.loading = 0;
    vm.saving = 0;
    vm.payment = null;
    vm.invoices = [];
    vm.sendEmailOnDefaultSave = false;
    vm.saveText = [App.localize('SaveAndClose'), App.localize('SaveAndSendEmail')];
    vm.showCategory = false;
    vm.isCreate = !$stateParams.paymentNumber;
    vm.isEdit = $stateParams.isEdit;
    vm.isConfirmPayment = !vm.isCreate && !vm.isEdit;
    vm.currencyCode = '';
    vm.billingAccounts = [];
    vm.billingAccountId = $stateParams.billingAccountId || null;
    vm.isFromTrxListing = $stateParams.billingAccountId != null;
    vm.isFromTransactionPage = $stateParams.isFromTransactionPage;
    vm.bankAccountDeposit = null;
    vm.bankAccountDepositId = $stateParams.bankAccountDepositId || null;
    vm.preselectedInvoiceNumber = $stateParams.invoiceNumber || null;
    vm.hasNoInvoices = false;
    vm.hasMultipleBillingAccountKnockoff = false;
    vm.groupedInvoices = {};
    const subsidiary = "Subsidiary";
    const corporate = "Corporate";

    vm.enums = {
      paymentMethod: enumPaymentMethod,
    };

    vm.getAppliedAmount = getAppliedAmount;
    vm.payFull = payFull;
    vm.save = save;
    vm.getInvoices = getInvoices;
    vm.getCurrencyCode = getCurrencyCode;
    vm.updateExcessAmount = updateExcessAmount;
    vm.lookupBillingAccount = lookupBillingAccount;

    init();

    function init() {
      lookupBillingAccount();
      getPaymentDetails();
      getSettings();
    }

    function lookupBillingAccount(search) {
      const keyword = _.trim(search || '');

      if (keyword.length > 0 && keyword.length < 3) return;

      if (!vm.isFromTrxListing) {
        billingAccountType = 1; // Debtor.
      }

      commonLookupSvc
        .getBillingAccounts({
          filter: keyword,
          maxResultCount: 5,
          type: billingAccountType,
          id: vm.billingAccountId,
          isPaymentOrManualTransactionPage: true,
        })
        .success((data) => {
          if (data) {
            vm.billingAccounts = data.items;

            if (vm.isFromTrxListing) {
              billingAccountType = vm.billingAccounts[0].type;
            }

            if (vm.hasMultipleBillingAccountKnockoff) {
              vm.billingAccounts.forEach((item) => {
                if (item.entityType === corporate.toLowerCase()) {
                  item.entityType = subsidiary.toLowerCase();
                }
              })
            }

          }

          vm.isPayable = billingAccountType === 2;
          if (vm.billingAccountId) {
            getInvoices();
          }
        });
    }

    function getPaymentDetails() {
      vm.loading += 1;
      paymentsSvc
        .getPaymentDetails({
          paymentNumber: $stateParams.paymentNumber,
          isEdit: $stateParams.isEdit,
          bankAccountDepositId: $stateParams.bankAccountDepositId,
          isPayable: $stateParams.isPayable,
          isIndividual: $stateParams.isIndividual
        })
        .success((data) => {
          localizeInvoiceCategory(data);
          vm.payment = data.payment;
          vm.bankAccountDeposit = data.bankAccountDeposit;
          vm.payment.date = moment(vm.payment.date);
          vm.availablePaymentMethods = data.availablePaymentMethods;

          if (!vm.isCreate) {
            vm.accountOwnerId = vm.payment.accountOwnerId;
            vm.billingAccountId = vm.payment.billingAccountId;
            vm.invoices = data.invoices;

            lookupBillingAccount();
            getInvoices();
          }

          paymentRetrieved = true;

          if (vm.bankAccountDepositId) {
            updateExcessAmount();
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getInvoices() {
      vm.currencyCode = getCurrencyCode(vm.billingAccountId);

      if (vm.isCreate) vm.invoices = [];

      vm.loading += 1;
      paymentsSvc
        .getInvoices({
          billingAccountId: vm.billingAccountId,
          isPayable: $stateParams.isPayable,
          isIndividual: $stateParams.isIndividual
        })
        .success((data) => {
          localizeInvoiceCategory(data);
          vm.hasNoInvoices = data.invoices.length === 0;
          if (vm.invoices.length) {
            _.forEach(data.invoices, (invoice) => {
              if (!vm.invoices.find((i) => i.id === invoice.id)) vm.invoices.push(invoice);
            });
          } else vm.invoices = data.invoices;

          if (vm.isCreate && vm.preselectedInvoiceNumber !== '') {
            _.forEach(vm.invoices, (invoice) => {
              if (vm.preselectedInvoiceNumber === invoice.invoiceNumber)
                invoice.appliedAmount = invoice.openBalance;
            });
          }

          if (vm.hasMultipleBillingAccountKnockoff && vm.invoices.length) {
            groupByBillingAccount(vm.invoices);
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function localizeInvoiceCategory(data) {
      _.forEach(data.invoices, (invoice) => {
        invoice.category = App.localize(invoice.category);
      });
    }

    function getCurrencyCode(billingAccountId) {
      let currencyCode = '';
      _.forEach(vm.billingAccounts, (billingAccount) => {
        if (billingAccount.id === billingAccountId) {
          currencyCode = billingAccount.currencyCode;
        }
      });
      return currencyCode;
    }

    function getAppliedAmount() {
      if (!paymentRetrieved) {
        return 0;
      }

      let amount = 0;
      for (let i = 0; i < vm.invoices.length; i += 1) {
        amount += vm.invoices[i].appliedAmount;
      }

      return App.roundAmount(amount);
    }

    function payFull(transaction) {
      if (vm.payment.excessAmount === 0) return;

      const t = transaction;
      let allInvoices = vm.invoices.slice();
      let otherInvoicesAppliedAmount = 0;

      // Sum up the applied amount on all other invoices.

      allInvoices = _.remove(allInvoices, (invoice) => invoice.id !== t.id);
      _.forEach(allInvoices, (invoice) => {
        otherInvoicesAppliedAmount += invoice.appliedAmount;
      });

      t.appliedAmount =
        vm.payment.amount - otherInvoicesAppliedAmount >= t.openBalance
          ? t.openBalance
          : vm.payment.amount - otherInvoicesAppliedAmount;

      // Round the applied amount to 2 decimal places.

      t.appliedAmount = Number(t.appliedAmount.toFixed(2));

      updateExcessAmount();
    }

    function sendEmailAux(data) {
      if (data.paymentNumber) {
        portalFinanceCommonSvc
          .getEmailAddresses({
            billingAccountKey: data.billingAccountKey,
          })
          .success((result) => {
            swal(
              {
                title: App.localize('SendEmailForX', data.paymentNumber),
                text: App.localize('EmailAddresses'),
                type: 'input',
                showCancelButton: true,
                closeOnConfirm: false,
                confirmButtonColor: '#1ab394',
                inputPlaceholder: App.localize('EmailAddressesNarrative'),
                inputValue: result.emailAddresses,
                showLoaderOnConfirm: true,
              },
              (inputValue) => {
                if (inputValue === false) return false;
                if (inputValue === '') {
                  swal.showInputError(App.localize('InvalidEmailAddress'));
                  return false;
                }

                data.emailAddresses = inputValue;
                portalFinanceCommonSvc
                  .sendPaymentEmail(data)
                  .success(() => {
                    abp.notify.info(App.localize('EmailSent'));
                    swal.close();
                  });
                return false;
              }
            );
          });
      }
    }

    function save(mode) {
      vm.saving += 1;

      const defaultSendEmail = vm.sendEmailOnDefaultSave ? 0 : 1;
      const shouldSendEmail = mode === defaultSendEmail;

      _.forEach(vm.invoices, (invoice) => {
        invoice.date = moment(invoice.date);
      });

      const input = {
        payment: vm.payment,
        invoices: vm.invoices,
        billingAccountId: vm.billingAccountId,
        currencyCode: vm.currencyCode,
        isEdit: vm.isEdit,
        bankAccountDepositId: vm.bankAccountDepositId,
      };

      const createOrEditPayment = vm.isCreate
        ? paymentsSvc.createPaymentRecord
        : paymentsSvc.updatePaymentRecord;

      createOrEditPayment(input)
        .success((data) => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          if (shouldSendEmail) {
            sendEmailAux(data);
          }
        })
        .finally(() => {
          vm.saving -= 1;
          if (vm.isFromTransactionPage && vm.isCreate) {
            $state.go('finance.saleTransactions', { isFromPayment: false });
          } else if (vm.isFromTransactionPage && vm.isEdit) {
            $state.go('finance.saleTransactions', { isFromPayment: true });
          } else if (!vm.isFromTransactionPage && vm.isCreate) {
            $state.go('finance.accountReceivables', { activeView: 0 });
          } else if (!vm.isFromTransactionPage && vm.isConfirmPayment) {
            $state.go('finance.accountReceivables', { activeView: 1 });
          } else {
            $state.go('finance.accountReceivables', { activeView: 2 });
          }
        });
    }

    function updateExcessAmount() {
      vm.payment.excessAmount = vm.payment.amount - getAppliedAmount();
    }

    function getSettings() {
      vm.loading += 1;
      commonLookupSvc
        .getCommonSettings()
        .success((data) => {
          vm.hasMultipleBillingAccountKnockoff = data.multipleBillingAccountKnockoff;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function groupByBillingAccount(invoices) {
      vm.groupedInvoices = {};

      _.forEach(invoices, (invoice) => {
        const {billingAccountId} = invoice;
        vm.groupedInvoices[billingAccountId] = vm.groupedInvoices[billingAccountId] || [];
        vm.groupedInvoices[billingAccountId].push(invoice);
      })

      vm.groupedInvoices = Object.values(vm.groupedInvoices);
    }
  }
})();
