(() => {
  angular
    .module('app')
    .filter('applicability', applicability)
    // For backward compatibility only. Filter name don't need "Filter" suffix.
    // AngularJS automatically appends "Filter" to name for DI.
    .filter('applicabilityFilter', applicability);

  applicability.$inject = [];

  function applicability() {
    return applicabilityFilter;

    function applicabilityFilter(value) {
      return value || App.localize('NotAvailableShort');
    }
  }
})();
