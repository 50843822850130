(function () {
    'use strict';

    angular.module('app').component('hostCorporatesSupportPlansCreateEditScheduleOfBenefitMiscellaneousComponent', {
        templateUrl: require('/App/host/views/corporates/supportPlans/createEdit/scheduleOfBenefit/miscellaneous/miscellaneous.component.html'),
        controller: ['Hms.BenefitGroups.CoveredCycle', controller],
        controllerAs: 'vm',
        bindings: {
            scheduleOfBenefit: '<',
            currencyCode: '<'
        }
    });

    function controller(enumCoveredCycle) {
        var vm = this;
        vm.$onChanges = onChanges;

        vm.hasTxtFileSupport = abp.setting.getBoolean('Hms.Feature.TxtFileSupport');

        // AmountType
        vm.governmentHospitalDailyCashAllowanceAmountType = null;
        vm.privateHospitalDailyCashAllowanceAmountType = null;
        vm.homeNursingCareAmountType = null;
        vm.accidentalDeathBenefitAmountType = null;
        vm.compassionateDeathBenefitAmountType = null;
        vm.funeralExpensesAmountType = null;

        vm.enums = {};
        vm.enums.coveredCycle = enumCoveredCycle;

        // AmountType toggle
        vm.toggleAmountType = function (SOBItem) {
            vm.minGovernmentHospitalDailyCashAllowanceAmount = vm.governmentHospitalDailyCashAllowanceAmountType === 1 ? 1 : 0;
            vm.minPrivateHospitalDailyCashAllowanceAmount = vm.privateHospitalDailyCashAllowanceAmountType === 1 ? 1 : 0;
            vm.minHomeNursingCareAmount = vm.homeNursingCareAmountType === 1 ? 1 : 0;
            vm.minAccidentalDeathBenefitAmount = vm.accidentalDeathBenefitAmountType === 1 ? 1 : 0;
            vm.minCompassionateDeathBenefitAmount = vm.compassionateDeathBenefitAmountType === 1 ? 1 : 0;
            vm.minFuneralExpensesAmountType = vm.funeralExpensesAmountType === 1 ? 1 : 0;

            switch (SOBItem) {
                case 'governmentHospitalDailyCashAllowance':
                    switch (vm.governmentHospitalDailyCashAllowanceAmountType) {
                        case 0: /*ischarged*/
                            vm.scheduleOfBenefit.governmentHospitalDailyCashAllowance.isAsCharged = true;
                            vm.scheduleOfBenefit.governmentHospitalDailyCashAllowance.isCovered = true;
                            vm.scheduleOfBenefit.governmentHospitalDailyCashAllowance.amount = null;
                            break;
                        case 1: /*limited*/
                            vm.scheduleOfBenefit.governmentHospitalDailyCashAllowance.isAsCharged = false;
                            vm.scheduleOfBenefit.governmentHospitalDailyCashAllowance.isCovered = true;
                            break;
                        case 2: /*uncovered*/
                            vm.scheduleOfBenefit.governmentHospitalDailyCashAllowance.isAsCharged = false;
                            vm.scheduleOfBenefit.governmentHospitalDailyCashAllowance.isCovered = false;
                            vm.scheduleOfBenefit.governmentHospitalDailyCashAllowance.amount = null;
                            break;
                    }

                    if (vm.scheduleOfBenefit.governmentHospitalDailyCashAllowance.isAsCharged === vm.scheduleOfBenefit.governmentHospitalDailyCashAllowance.isCovered) {
                        vm.tempGovernmentHospitalDailyCashAllowanceAmount = vm.scheduleOfBenefit.governmentHospitalDailyCashAllowance.amount;
                        vm.scheduleOfBenefit.governmentHospitalDailyCashAllowance.amount = null;
                    } else {
                        vm.scheduleOfBenefit.governmentHospitalDailyCashAllowance.amount = vm.tempGovernmentHospitalDailyCashAllowanceAmount;
                    }

                    break;
                case 'privateHospitalDailyCashAllowance':
                    switch (vm.privateHospitalDailyCashAllowanceAmountType) {
                        case 0: /*ischarged*/
                            vm.scheduleOfBenefit.privateHospitalDailyCashAllowance.isAsCharged = true;
                            vm.scheduleOfBenefit.privateHospitalDailyCashAllowance.isCovered = true;
                            vm.scheduleOfBenefit.privateHospitalDailyCashAllowance.amount = null;
                            break;
                        case 1: /*limited*/
                            vm.scheduleOfBenefit.privateHospitalDailyCashAllowance.isAsCharged = false;
                            vm.scheduleOfBenefit.privateHospitalDailyCashAllowance.isCovered = true;
                            break;
                        case 2: /*uncovered*/
                            vm.scheduleOfBenefit.privateHospitalDailyCashAllowance.isAsCharged = false;
                            vm.scheduleOfBenefit.privateHospitalDailyCashAllowance.isCovered = false;
                            vm.scheduleOfBenefit.privateHospitalDailyCashAllowance.amount = null;
                            break;
                    }

                    if (vm.scheduleOfBenefit.privateHospitalDailyCashAllowance.isAsCharged === vm.scheduleOfBenefit.privateHospitalDailyCashAllowance.isCovered) {
                        vm.tempPrivateHospitalDailyCashAllowanceAmount = vm.scheduleOfBenefit.privateHospitalDailyCashAllowance.amount;
                        vm.scheduleOfBenefit.privateHospitalDailyCashAllowance.amount = null;
                    } else {
                        vm.scheduleOfBenefit.privateHospitalDailyCashAllowance.amount = vm.tempPrivateHospitalDailyCashAllowanceAmount;
                    }

                    break;
                case 'homeNursingCare':
                    switch (vm.homeNursingCareAmountType) {
                        case 0: /*ischarged*/
                            vm.scheduleOfBenefit.homeNursingCare.isAsCharged = true;
                            vm.scheduleOfBenefit.homeNursingCare.isCovered = true;
                            vm.scheduleOfBenefit.homeNursingCare.amount = null;
                            break;
                        case 1: /*limited*/
                            vm.scheduleOfBenefit.homeNursingCare.isAsCharged = false;
                            vm.scheduleOfBenefit.homeNursingCare.isCovered = true;
                            break;
                        case 2: /*uncovered*/
                            vm.scheduleOfBenefit.homeNursingCare.isAsCharged = false;
                            vm.scheduleOfBenefit.homeNursingCare.isCovered = false;
                            vm.scheduleOfBenefit.homeNursingCare.amount = null;
                            break;
                    }

                    if (vm.scheduleOfBenefit.homeNursingCare.isAsCharged === vm.scheduleOfBenefit.homeNursingCare.isCovered) {
                        vm.tempHomeNursingCareAmount = vm.scheduleOfBenefit.homeNursingCare.amount;
                        vm.scheduleOfBenefit.homeNursingCare.amount = null;

                        vm.tempHomeNursingCareEffectivePeriod = vm.scheduleOfBenefit.homeNursingCare.coveredCycle;
                        vm.scheduleOfBenefit.homeNursingCare.coveredCycle = null;
                    } else {
                        vm.scheduleOfBenefit.homeNursingCare.amount = vm.tempHomeNursingCareAmount;

                        if (vm.tempHomeNursingCareEffectivePeriod) {
                            vm.scheduleOfBenefit.homeNursingCare.coveredCycle = vm.tempHomeNursingCareEffectivePeriod;
                        } else {
                            vm.scheduleOfBenefit.homeNursingCare.coveredCycle = 6 // PerAdmission
                        }
                    }

                    break;
                case 'accidentalDeathBenefit':
                    switch (vm.accidentalDeathBenefitAmountType) {
                        case 0: /*ischarged*/
                            vm.scheduleOfBenefit.accidentalDeathBenefit.isAsCharged = true;
                            vm.scheduleOfBenefit.accidentalDeathBenefit.isCovered = true;
                            break;
                        case 1: /*limited*/
                            vm.scheduleOfBenefit.accidentalDeathBenefit.isAsCharged = false;
                            vm.scheduleOfBenefit.accidentalDeathBenefit.isCovered = true;
                            break;
                        case 2: /*uncovered*/
                            vm.scheduleOfBenefit.accidentalDeathBenefit.isAsCharged = false;
                            vm.scheduleOfBenefit.accidentalDeathBenefit.isCovered = false;
                            break;
                    }

                    if (vm.scheduleOfBenefit.accidentalDeathBenefit.isAsCharged === vm.scheduleOfBenefit.accidentalDeathBenefit.isCovered) {
                        vm.tempAccidentalDeathBenefitAmount = vm.scheduleOfBenefit.accidentalDeathBenefit.amount;
                        vm.scheduleOfBenefit.accidentalDeathBenefit.amount = null;
                    } else {
                        vm.scheduleOfBenefit.accidentalDeathBenefit.amount = vm.tempAccidentalDeathBenefitAmount;
                    }

                    break;
                case 'compassionateDeathBenefit':
                    switch (vm.compassionateDeathBenefitAmountType) {
                        case 0: /*ischarged*/
                            vm.scheduleOfBenefit.compassionateDeathBenefit.isAsCharged = true;
                            vm.scheduleOfBenefit.compassionateDeathBenefit.isCovered = true;
                            break;
                        case 1: /*limited*/
                            vm.scheduleOfBenefit.compassionateDeathBenefit.isAsCharged = false;
                            vm.scheduleOfBenefit.compassionateDeathBenefit.isCovered = true;
                            break;
                        case 2: /*uncovered*/
                            vm.scheduleOfBenefit.compassionateDeathBenefit.isAsCharged = false;
                            vm.scheduleOfBenefit.compassionateDeathBenefit.isCovered = false;
                            break;
                    }

                    if (vm.scheduleOfBenefit.compassionateDeathBenefit.isAsCharged === vm.scheduleOfBenefit.compassionateDeathBenefit.isCovered) {
                        vm.tempCompassionateDeathBenefitAmount = vm.scheduleOfBenefit.compassionateDeathBenefit.amount;
                        vm.scheduleOfBenefit.compassionateDeathBenefit.amount = null;
                    } else {
                        vm.scheduleOfBenefit.compassionateDeathBenefit.amount = vm.tempCompassionateDeathBenefitAmount;
                    }

                    break;
                case 'funeralExpenses':
                    switch (vm.funeralExpensesAmountType) {
                        case 0: /*ischarged*/
                            vm.scheduleOfBenefit.funeralExpenses.isAsCharged = true;
                            vm.scheduleOfBenefit.funeralExpenses.isCovered = true;
                            break;
                        case 1: /*limited*/
                            vm.scheduleOfBenefit.funeralExpenses.isAsCharged = false;
                            vm.scheduleOfBenefit.funeralExpenses.isCovered = true;
                            break;
                        case 2: /*uncovered*/
                            vm.scheduleOfBenefit.funeralExpenses.isAsCharged = false;
                            vm.scheduleOfBenefit.funeralExpenses.isCovered = false;
                            break;
                    }

                    if (vm.scheduleOfBenefit.funeralExpenses.isAsCharged === vm.scheduleOfBenefit.funeralExpenses.isCovered) {
                        vm.tempFuneralExpensesAmount = vm.scheduleOfBenefit.funeralExpenses.amount;
                        vm.scheduleOfBenefit.funeralExpenses.amount = null;
                    } else {
                        vm.scheduleOfBenefit.funeralExpenses.amount = vm.tempFuneralExpensesAmount;
                    }

                    break;
            }
        }

        vm.checkBoxClicked = function (checkBoxName) {
            switch (checkBoxName) {
                case 'governmentHospitalUnlimited':
                    if (vm.scheduleOfBenefit.governmentHospitalDailyCashAllowance.isUnlimitedVisitPerDay) {
                        vm.tempGovernmentHospitalAccomodationDays = vm.scheduleOfBenefit.governmentHospitalDailyCashAllowance.accomodationDays;
                        vm.scheduleOfBenefit.governmentHospitalDailyCashAllowance.accomodationDays = null;
                    } else {
                        vm.scheduleOfBenefit.governmentHospitalDailyCashAllowance.accomodationDays = vm.tempGovernmentHospitalAccomodationDays;
                    }
                    break;
                case 'privateHospitalDailyCashUnlimited':
                    if (vm.scheduleOfBenefit.privateHospitalDailyCashAllowance.isUnlimitedVisitPerDay) {
                        vm.tempPrivateHospitalAccomodationDays = vm.scheduleOfBenefit.privateHospitalDailyCashAllowance.accomodationDays;
                        vm.scheduleOfBenefit.privateHospitalDailyCashAllowance.accomodationDays = null;
                    } else {
                        vm.scheduleOfBenefit.privateHospitalDailyCashAllowance.accomodationDays = vm.tempPrivateHospitalAccomodationDays;
                    }
                    break;
            }
        }

        function initializeAmountTypes() {
            if (vm.hasTxtFileSupport) {
                // Government Hospital Daily Cash Allowance AmountType
                if (!vm.scheduleOfBenefit.governmentHospitalDailyCashAllowance.isCovered)
                    vm.governmentHospitalDailyCashAllowanceAmountType = 2;
                else if (vm.scheduleOfBenefit.governmentHospitalDailyCashAllowance.isAsCharged)
                    vm.governmentHospitalDailyCashAllowanceAmountType = 0;
                else if (!vm.scheduleOfBenefit.governmentHospitalDailyCashAllowance.isAsCharged) {
                    vm.governmentHospitalDailyCashAllowanceAmountType = 1;
                }

                // Private Hospital Daily Cash Allowance AmountType
                if (!vm.scheduleOfBenefit.privateHospitalDailyCashAllowance.isCovered)
                    vm.privateHospitalDailyCashAllowanceAmountType = 2;
                else if (vm.scheduleOfBenefit.privateHospitalDailyCashAllowance.isAsCharged)
                    vm.privateHospitalDailyCashAllowanceAmountType = 0;
                else if (!vm.scheduleOfBenefit.privateHospitalDailyCashAllowance.isAsCharged) {
                    vm.privateHospitalDailyCashAllowanceAmountType = 1;
                }

                // Home Nursing Care AmountType
                if (!vm.scheduleOfBenefit.homeNursingCare.isCovered)
                    vm.homeNursingCareAmountType = 2;
                else if (vm.scheduleOfBenefit.homeNursingCare.isAsCharged)
                    vm.homeNursingCareAmountType = 0;
                else if (!vm.scheduleOfBenefit.homeNursingCare.isAsCharged) {
                    vm.homeNursingCareAmountType = 1;
                }

                // Accidental Death Benefit AmountType
                if (!vm.scheduleOfBenefit.accidentalDeathBenefit.isCovered)
                    vm.accidentalDeathBenefitAmountType = 2;
                else if (vm.scheduleOfBenefit.accidentalDeathBenefit.isAsCharged)
                    vm.accidentalDeathBenefitAmountType = 0;
                else if (!vm.scheduleOfBenefit.accidentalDeathBenefit.isAsCharged) {
                    vm.accidentalDeathBenefitAmountType = 1;
                }

                // Compassionate Death Benefit AmountType
                if (!vm.scheduleOfBenefit.compassionateDeathBenefit.isCovered)
                    vm.compassionateDeathBenefitAmountType = 2;
                else if (vm.scheduleOfBenefit.compassionateDeathBenefit.isAsCharged)
                    vm.compassionateDeathBenefitAmountType = 0;
                else if (!vm.scheduleOfBenefit.compassionateDeathBenefit.isAsCharged) {
                    vm.compassionateDeathBenefitAmountType = 1;
                }

                // Funeral Expenses AmountType
                if (!vm.scheduleOfBenefit.funeralExpenses.isCovered)
                    vm.funeralExpensesAmountType = 2;
                else if (vm.scheduleOfBenefit.funeralExpenses.isAsCharged)
                    vm.funeralExpensesAmountType = 0;
                else if (!vm.scheduleOfBenefit.funeralExpenses.isAsCharged) {
                    vm.funeralExpensesAmountType = 1;
                }
            } else {
                vm.outpatientCancerTreatmentAmountType = vm.scheduleOfBenefit.outpatientCancerTreatment.isCovered ? 0 : 1;
                vm.outpatientKidneyDialysisTreatmentAmountType = vm.scheduleOfBenefit.outpatientKidneyDialysisTreatment.isCovered ? 0 : 1;
            }
        }

        vm.outpatientCancerTreatmentAmountType = null;
        vm.outpatientKidneyDialysisTreatmentAmountType = null;

        vm.toggleOthers = function () {
            vm.minOutpatientCancerTreatmentAmount = vm.outpatientCancerTreatmentAmountType === 0 ? 1 : 0;
            vm.minOutpatientKidneyDialysisTreatmentAmount = vm.outpatientKidneyDialysisTreatmentAmountType === 0 ? 1 : 0;

            setOutpatientCancerTreatmentAmountType();
            setOutpatientKidneyDialysisTreatmentAmountType();
        };

        function setOutpatientCancerTreatmentAmountType() {
            switch (vm.outpatientCancerTreatmentAmountType) {
                case 0:
                    vm.scheduleOfBenefit.outpatientCancerTreatment.isCovered = true;
                    break;

                case 1:
                    vm.scheduleOfBenefit.outpatientCancerTreatment.isCovered = false;
                    break;

                default:
                    break;
            }
        }

        function setOutpatientKidneyDialysisTreatmentAmountType() {
            switch (vm.outpatientKidneyDialysisTreatmentAmountType) {
                case 0:
                    vm.scheduleOfBenefit.outpatientKidneyDialysisTreatment.isCovered = true;
                    break;

                case 1:
                    vm.scheduleOfBenefit.outpatientKidneyDialysisTreatment.isCovered = false;
                    break;

                default:
                    break;
            }
        }

        function onChanges(changes) {
            if (changes.scheduleOfBenefit && changes.scheduleOfBenefit.currentValue) {
                vm.toggleOthers();
            }
        }

        function init() {
            initializeAmountTypes();
            if (vm.hasTxtFileSupport) vm.toggleAmountType();
        }

        angular.element(document).ready(function () {
            init();
        });
    }
})();
