(() => {
  angular
    .module('revenue')
    .controller('revenue.views.subscriptionPlans.create', CreateSubscriptionPlanController);

  CreateSubscriptionPlanController.$inject = [
    '$scope',
    '$state',
    'abp.services.revenue.subscriptionPlan',
    'abp.services.revenue.common',
    'abp.services.app.commonLookup',
    'Hms.Revenue.SubscriptionPlanClientType',
    '$uibModal',
    'HmsRevenueConsts.SubscriptionPlan',
    'Hms.Revenue.OutpatientServiceFeatures',
    'Hms.Revenue.InpatientServiceFeatures',
    'Hms.Revenue.SubscriptionPlanPricingMode',
    'Hms.Revenue.SubscriptionPlanChargeMode',
  ];

  function CreateSubscriptionPlanController(
    $scope,
    $state,
    subscriptionPlanSvc,
    revenueCommonSvc,
    commonLookupSvc,
    enumSubscriptionPlanClientType,
    $uibModal,
    constsSubscriptionPlan,
    enumOutpatientServiceFeatures,
    enumInpatientServiceFeatures,
    enumSubscriptionPlanPricingMode,
    enumSubscriptionPlanChargeMode
  ) {
    const vm = this;

    vm.loading = 0;
    vm.filteredAccountOwners = [];
    vm.constsSubscriptionPlan = constsSubscriptionPlan;
    vm.categoryCharges = [];
    vm.currencyCode = 'MYR';

    vm.enums = {
      subscriptionPlanClientType: enumSubscriptionPlanClientType,
      outpatientServiceFeatures: enumOutpatientServiceFeatures,
      inpatientServiceFeatures: enumInpatientServiceFeatures,
      subscriptionPlanPricingMode: enumSubscriptionPlanPricingMode,
      subscriptionPlanChargeMode: enumSubscriptionPlanChargeMode,
    };

    vm.selectableServiceFeatures = vm.enums.outpatientServiceFeatures;

    vm.subscriptionPlan = {
      countryCode: null,
      accountOwnerId: null,
      accountOwnerName: null,
      tenantType: null,
      planName: null,
      planCategory: null,
      pricingMode: null,
      chargeMode: null,
    };

    vm.getAccountOwners = getAccountOwners;
    vm.addNewCategoryCharge = addNewCategoryCharge;
    vm.removeCategoryCharge = removeCategoryCharge;
    vm.clearCategoryCharges = clearCategoryCharges;
    vm.changeSelectableServiceFeatures = changeSelectableServiceFeatures;
    vm.getChargeMode = getChargeMode;
    vm.getPricingMode = getPricingMode;
    vm.save = save;

    init();

    function init() {
      getCountries();
      addNewCategoryCharge();
    }

    function getCountries() {
      vm.loading += 1;
      commonLookupSvc
        .getEligibleCountries()
        .success((data) => {
          vm.countries = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getAccountOwners() {
      // Reset accountOwnerId filter selection.
      vm.subscriptionPlan.accountOwnerId = null;
      vm.filteredAccountOwners = null;

      // If country code filter is not null, get accountOwners under that country.
      if (vm.subscriptionPlan.countryCode != null) {
        vm.loading += 1;
        revenueCommonSvc
          .getAccountOwnersByCountryCode({
            countryCode: vm.subscriptionPlan.countryCode,
          })
          .success((data) => {
            vm.filteredAccountOwners = data.items;
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
      getCurrencyCodeByCountryCode();
    }

    function getCurrencyCodeByCountryCode() {
      // Get the currency code for the selected country.

      vm.getCurrencyCodeByCountryCodeInput = {
        countryCode: vm.subscriptionPlan.countryCode,
      };
      vm.loading += 1;
      commonLookupSvc
        .getCurrencyCodeByCountryCode(vm.getCurrencyCodeByCountryCodeInput)
        .success((data) => {
          vm.currencyCode = data.currencyCode;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function CategoryCharge() {
      // Basic properties.

      this.serviceFeatures = [];
      this.retailPrice = 0;

      // View properties.

      this.show = true;
      this.description = '';
    }

    function addNewCategoryCharge() {
      const categoryCharge = new CategoryCharge();
      vm.categoryCharges.push(categoryCharge);
    }

    function removeCategoryCharge(index) {
      // Prevent removal if only left 1 category charge.
      if (vm.categoryCharges.length > 1) {
        abp.message.confirm(
          App.localize('DeleteCategoryChargeWarning'),
          App.localize('AreYouSure'),
          (d) => {
            if (d) {
              $scope.$apply(() => {
                vm.categoryCharges.splice(index, 1);
              });
            }
          }
        );
      } else {
        abp.message.warn(App.localize('SubscriptionPlanMustHaveAtLeastOneCategoryChargeWarning'));
      }
    }

    function clearCategoryCharges() {
      // Set vm.categoryCharges to empty array.
      vm.categoryCharges = [];

      // Insert first category into vm.categoryCharges.
      addNewCategoryCharge();
    }

    function changeSelectableServiceFeatures() {
      const planCategoryInput = document.getElementById('planCategoryInput');
      if (planCategoryInput.value === '0') {
        vm.selectableServiceFeatures = vm.enums.outpatientServiceFeatures;
      } else {
        vm.selectableServiceFeatures = vm.enums.inpatientServiceFeatures;
      }
      clearCategoryCharges();
    }

    function getChargeMode(chargeMode) {
      if (chargeMode === vm.enums.subscriptionPlanChargeMode.Monthly.id) {
        return App.localize('Monthly');
      }
      if (chargeMode === vm.enums.subscriptionPlanChargeMode.Yearly.id) {
        return App.localize('Yearly');
      }
      return '-';
    }

    function getPricingMode(pricingMode) {
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.Fixed.id) {
        return App.localize('Fixed');
      }
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.PerMember.id) {
        return App.localize('PerMember');
      }
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.PerTicketBasis.id) {
        return App.localize('PerTicketBasis');
      }
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.PerCaseBasis.id) {
        return App.localize('PerCaseBasis');
      }
      return '-';
    }

    function save() {
      if (vm.loading) return;
      // Validate all input fields.

      if (vm.subscriptionPlan.countryCode == null) {
        abp.message.error(App.localize('MissingCountryCodeSelection'));
        return;
      }

      if (vm.subscriptionPlan.accountOwnerId == null) {
        abp.message.error(App.localize('MissingAccountOwnerSelection'));
        return;
      }

      if (vm.subscriptionPlan.tenantType == null) {
        abp.message.error(App.localize('MissingTenantTypeSelection'));
        return;
      }

      if (vm.subscriptionPlan.planName == null || vm.subscriptionPlan.planName === undefined) {
        abp.message.error(App.localize('MissingSubscriptionPlanName'));
        return;
      }

      if (vm.subscriptionPlan.planCategory == null) {
        abp.message.error(App.localize('MissingPlanCategorySelection'));
        return;
      }

      if (vm.subscriptionPlan.effectiveDate == null) {
        abp.message.error(App.localize('MissingEffectiveDateSelection'));
        return;
      }

      if (vm.subscriptionPlan.pricingMode == null) {
        abp.message.error(App.localize('MissingPricingModeSelection'));
        return;
      }

      if (vm.subscriptionPlan.chargeMode == null) {
        abp.message.error(App.localize('MissingChargeModeSelection'));
        return;
      }

      // Validate all category charges has at least one service feature selected.

      let hasMissingServiceFeatures = false;

      _.some(vm.categoryCharges, (categoryCharge) => {
        if (categoryCharge.serviceFeatures.length < 1) {
          abp.message.error(
            App.localize('EachCategoryChargeMustHaveAtLeastOneServiceFeaturesSelectionWarning')
          );
          hasMissingServiceFeatures = true;
        }
      });

      if (hasMissingServiceFeatures) return;

      // Validate all category charges do not have duplicate service feature selected.

      let hasDuplicateServiceFeatures = false;
      let serviceFeatureList = [];

      _.each(vm.categoryCharges, (categoryCharge) => {
        const hasDuplicate = categoryCharge.serviceFeatures.some((serviceFeature) =>
          serviceFeatureList.includes(serviceFeature)
        );
        if (hasDuplicate) {
          hasDuplicateServiceFeatures = true;
        }
        serviceFeatureList = serviceFeatureList.concat(categoryCharge.serviceFeatures);
      });

      if (hasDuplicateServiceFeatures) {
        abp.message.error(
          App.localize('SubscriptionPlanChargesCannotHaveDuplicateServiceFeatures')
        );
        return;
      }

      // Retrieve accountOwnerName to show in confirmation modal.

      _.each(vm.filteredAccountOwners, (x) => {
        if (vm.subscriptionPlan.accountOwnerId === x.value) {
          vm.subscriptionPlan.accountOwnerName = x.name;
        }
      });

      showConfirmationModal();
    }

    function showConfirmationModal() {
      const modal = $uibModal.open({
        templateUrl: require('../confirmationModal/confirmationModal.html'),
        controller: 'revenue.views.subscriptionPlans.confirmationModal as vm',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          subscriptionPlan() {
            return vm.subscriptionPlan;
          },
          categoryCharges() {
            return vm.categoryCharges;
          },
          currencyCode() {
            return vm.currencyCode;
          },
          serviceFeatures() {
            return _.union(
              vm.enums.outpatientServiceFeatures.values,
              vm.enums.inpatientServiceFeatures.values
            );
          },
        },
      });
      modal.result.then(() => {
        vm.loading += 1;
        subscriptionPlanSvc
          .createSubscriptionPlan({
            currencyCode: vm.currencyCode,
            accountOwnerId: vm.subscriptionPlan.accountOwnerId,
            tenantType: vm.subscriptionPlan.tenantType,
            planName: vm.subscriptionPlan.planName,
            effectiveDate: vm.subscriptionPlan.effectiveDate,
            planCategory: vm.subscriptionPlan.planCategory,
            pricingMode: vm.subscriptionPlan.pricingMode,
            chargeMode: vm.subscriptionPlan.chargeMode,
            categoryCharges: vm.categoryCharges,
          })
          .success(() => {
            abp.notify.info(App.localize('SuccessfullySaved'));
            $state.go('revenue.subscriptionPlans');
          })
          .finally(() => {
            vm.loading -= 1;
          });
      });
    }
  }
})();
