(() => {
  angular
    .module('revenue')
    .controller(
      'revenue.views.subscriptionPlans.createOrEditModal',
      SubscriptionPlansCreateOrEditModalController
    );

  SubscriptionPlansCreateOrEditModalController.$inject = [
    '$uibModalInstance',
    '$timeout',
    'currencies',
    'abp.services.revenue.subscriptionPlan',
    'subscriptionPlanId',
    'abp.services.app.commonLookup',
    'HmsRevenueConsts.SubscriptionPlan',
  ];

  function SubscriptionPlansCreateOrEditModalController(
    $uibModalInstance,
    $timeout,
    currencies,
    subscriptionPlanSvc,
    subscriptionPlanId,
    commonLookupSvc,
    constsSubscriptionPlan
  ) {
    const vm = this;

    vm.constsSubscriptionPlan = constsSubscriptionPlan;
    vm.isEdit = !!subscriptionPlanId;
    vm.loading = 0;
    vm.saving = 0;
    vm.subscriptionPlan = null;
    vm.accountOwners = [];
    vm.errors = [];

    vm.accountOwnerChanged = accountOwnerChanged;
    vm.save = save;
    vm.cancel = cancel;

    init();

    function init() {
      vm.loading += 1;
      subscriptionPlanSvc
        .getSubscriptionPlanForEdit({
          id: subscriptionPlanId,
        })
        .success((data) => {
          vm.subscriptionPlan = data;
          getAccountOwners();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function refreshErrors() {
      const errors = [];

      const name = (vm.subscriptionPlan.name || '').trim();
      if (!name) {
        errors.push(App.localize('InvalidName'));
      }

      if (!vm.subscriptionPlan.accountOwnerId) {
        errors.push(App.localize('InvalidOrMissingAccountOwner'));
      }

      if (!vm.subscriptionPlan.currencyCode) {
        errors.push(App.localize('InvalidCurrency'));
      }

      const pricePerPaxPerMonth = +vm.subscriptionPlan.pricePerPaxPerMonth;
      if (Number.isNaN(pricePerPaxPerMonth) || pricePerPaxPerMonth < 0) {
        errors.push(App.localize('AmountCannotBeLessThanZero'));
      }

      vm.errors = errors;
    }

    function getAccountOwners() {
      vm.loading += 1;
      commonLookupSvc
        .getAccountOwners()
        .success((data) => {
          vm.accountOwners = data;
          setAccountOwner();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function setAccountOwner() {
      const accountOwner = vm.accountOwners.filter(
        (x) => x.id === vm.subscriptionPlan.accountOwnerId
      );
      if (accountOwner.length > 0) {
        [vm.accountOwner] = accountOwner;
        vm.accountOwnerChanged();
      } else {
        vm.subscriptionPlan.accountOwnerId = '';
      }
    }

    function accountOwnerChanged() {
      [vm.currency] = currencies.filter((x) => x.code === vm.accountOwner.currencyCode);

      vm.subscriptionPlan.accountOwnerId = vm.accountOwner.id;
      vm.subscriptionPlan.countryCode = vm.accountOwner.countryCode;
      vm.subscriptionPlan.currencyCode = vm.accountOwner.currencyCode;
    }

    function save() {
      refreshErrors();
      if (vm.errors.length) {
        $timeout(() => {
          document.getElementById('pnlError').scrollIntoView();
        }, 0);
        return;
      }

      if (vm.saving) return;
      vm.saving += 1;
      subscriptionPlanSvc
        .createOrUpdateSubscriptionPlan(vm.subscriptionPlan)
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          $uibModalInstance.close();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
