(() => {
  angular
    .module('finance')
    .controller(
      'manualTransactionSetupCreateEditPaymentTerms',
      ManualTransactionSetupCreateEditTaxCodeController
    );

  ManualTransactionSetupCreateEditTaxCodeController.$inject = [
    '$scope',
    '$uibModalInstance',
    'isCreate',
    'paymentTermsDetails',
    'accountOwnerId',
    'abp.services.finance.paymentTerms',
  ];

  function ManualTransactionSetupCreateEditTaxCodeController(
    $scope,
    $uibModalInstance,
    isCreate,
    paymentTermsDetails,
    accountOwnerId,
    paymentTermsSvc
  ) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;
    vm.isCreate = isCreate;
    vm.paymentTermsDetails = paymentTermsDetails;

    vm.save = save;
    vm.cancel = cancel;

    function save() {
      const isValid = App.isFormValid($scope.createEditPaymentTerms);
      if (isValid) {
        const input = {
          id: vm.paymentTermsDetails.id,
          accountOwnerId,
          name: vm.paymentTermsDetails.name,
          term: vm.paymentTermsDetails.term,
        };

        vm.saving += 1;
        if (vm.isCreate) {
          // Create new payment term.

          paymentTermsSvc
            .createPaymentTerm(input)
            .finally(() => {
              vm.saving -= 1;
              $uibModalInstance.close();
            });
        } else {
          // Edit payment term.

          paymentTermsSvc
            .updatePaymentTerm(input)
            .finally(() => {
              vm.saving -= 1;
              $uibModalInstance.close();
            });
        }
      }
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
