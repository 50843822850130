(function () {
    'use strict';

    angular.module('app').component('hostDoraemonCommonActionButtonGroupComponent', {
        templateUrl: require('/App/host/views/doraemon/common/actionButtonGroup/actionButtonGroup.component.html'),
        bindings: {
            onApprove: '&',
            onQuery: '&',
            onPreview: '&',
        }
    });
})();
