// Bootstrap module is only for actions that NEED to happen
// before HMS app is loaded.

(() => {
  angular.module('hms-bootstrap', ['hms-ng-auth']).config(config).run(run);

  config.$inject = [];
  run.$inject = [];

  function config() {}

  function run() {}
})();
