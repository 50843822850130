(() => {
  angular
    .module('app')
    .controller(
      'common.views.saleTransactions.generatePaymentInstructionsModal',
      GeneratePaymentInstructionsModalController
    );

  GeneratePaymentInstructionsModalController.$inject = [
    '$uibModalInstance',
    'abp.services.app.saleTransaction',
    'abp.services.app.commonLookup',
  ];

  function GeneratePaymentInstructionsModalController(
    $uibModalInstance,
    saleTransactionSvc,
    commonLookupSvc
  ) {
    const vm = this;

    vm.loading = false;
    vm.saving = 0;
    vm.category = 0;
    vm.includeUnpaidTransactions = false;
    vm.transactionStartDate = null;
    vm.transactionEndDate = null;
    vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');
    vm.hasRegionalReimbursementSupport = abp.setting.getBoolean(
      'Hms.Feature.RegionalReimbursementSupport'
    );
    vm.stopInvoiceGeneration = abp.setting.getBoolean('Hms.Feature.StopPortalInvoiceGeneration');

    vm.accountOwners = [];

    vm.categories = [];
    vm.loadCategories = loadCategories;

    vm.cancel = cancel;
    vm.save = save;

    init();

    function init() {
      if (vm.hasRegionalSystemSupport) getAccountOwners();
      vm.loadCategories();
    }

    function loadCategories() {
      vm.categories = [];
      let countryCode = 'MY';

      if (vm.hasRegionalSystemSupport && vm.accountOwnerId) {
        const accountOwner = _.find(vm.accountOwners, (a) => a.id === vm.accountOwnerId);
        countryCode = accountOwner.countryCode;
      }

      vm.categories.push({ name: App.localize('RegularPanel'), value: 5 });
      if (abp.auth.isGranted('SaleTransactions.Host.Commissions')) {
        vm.categories.push({ name: App.localize('Introducer'), value: 2 });
      }

      if (vm.hasRegionalSystemSupport && countryCode === 'SG') {
        if (vm.hasRegionalReimbursementSupport)
          vm.categories.push({ name: App.localize('Reimbursement'), value: 4 });

        return;
      }

      vm.categories.push({ name: App.localize('Reimbursement'), value: 4 });
      vm.categories.push({ name: App.localize('SpecialistPanel'), value: 6 });
      vm.categories.push({ name: App.localize('PharmacyPanel'), value: 7 });
      vm.categories.push({ name: App.localize('HospitalPanel'), value: 8 });
      vm.categories.push({ name: App.localize('IhmHospitalPanel'), value: 9 }); // HM-3369 Doraemon Hospital panel.
    }

    function save() {
      const confirmationMessage = App.localize(
        'CreatePaymentInstructionRequestWarningMessage',
        _.find(vm.categories, ['value', vm.category]).name.toLowerCase()
      );

      abp.message.confirm(confirmationMessage, App.localize('AreYouSure'), (d) => {
        if (d) {
          vm.saving += 1;
          saleTransactionSvc
            .createPaymentInstructionRequest({
              category: vm.category,
              transactionStartDate: vm.transactionStartDate,
              transactionEndDate: vm.transactionEndDate,
              includeUnpaidTransactions: vm.includeUnpaidTransactions,
              accountOwnerId: vm.accountOwnerId,
            })
            .success((data) => {
              if (data.generatedPaymentInstructionCount) {
                abp.notify.info(
                  App.localize(
                    'XNewPaymentInstructionsCreated',
                    data.generatedPaymentInstructionCount
                  )
                );
              }
              $uibModalInstance.close();
            })
            .finally(() => {
              vm.saving -= 1;
            });
        }
      });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function getAccountOwners() {
      vm.loading += 1;
      commonLookupSvc
        .getAccountOwners()
        .success((data) => {
          vm.accountOwners = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
