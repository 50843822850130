(() => {
  angular.module('sms').controller('sms.views.smsProviders.page', SmsProvidersPageController);
  SmsProvidersPageController.$inject = [
    '$scope',
    '$uibModal',
    '$stateParams',
    'smsService',
    'smsConstants',
  ];
  function SmsProvidersPageController($scope, $modal, $stateParams, smsSvc, smsConsts) {
    const vm = this;
    vm.loading = 0;
    vm.createProvider = createProvider;
    vm.editProvider = editProvider;
    vm.deleteProvider = deleteProvider;

    const viewOptions = {
      skipCount: $stateParams.skipCount,
      maxResultCount: $stateParams.maxResultCount,
    };

    vm.providerGridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: smsConsts.grid.defaultPageSize,
      paginationPageSize: smsConsts.grid.defaultPageSize,
      useExternalPagination: true,
      enableSelectAll: false,
      enableFullRowSelect: false,
      enableRowHeaderSelection: false,
      multiSelect: false,
      columnDefs: [
        {
          enableSorting: false,
          name: App.localize('Actions'),
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
        },
        {
          displayName: App.localize('Country'),
          field: 'countryName',
          minWidth: 120,
          cellTemplate: 'countryNameTemplate',
        },
        {
          displayName: App.localize('SmsProvider'),
          field: 'providerName',
          minWidth: 120,
        },
        {
          displayName: App.localize('MessagePrefix'),
          field: 'messagePrefix',
          minWidth: 120,
        },
      ],
      onRegisterApi: (gridApi) => {
        $scope.gridApi = gridApi;
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(viewOptions, getSmsProviders)
        );
      },
      data: [],
    };

    init();

    function init() {
      getSmsProviders();
    }

    function getSmsProviders() {
      vm.loading += 1;
      smsSvc
        .getSmsProviders({
          skipCount: viewOptions.skipCount,
          takeCount: viewOptions.maxResultCount,
        })
        .success((data) => {
          vm.providerGridOptions.data = data.items || [];
          vm.providerGridOptions.totalItems = data.totalCount || 0;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function createProvider() {
      const modal = $modal.open({
        templateUrl: require('./smsProviders.createOrEditModal.html'),
        controller: 'sms.views.provider.createOrEditModal as vm',
        backdrop: 'static',
        resolve: {
          countryCode: () => null,
        },
      });

      modal.result.then(() => {
        getSmsProviders();
      });
    }

    function editProvider(countryCode) {
      const modal = $modal.open({
        templateUrl: require('./smsProviders.createOrEditModal.html'),
        controller: 'sms.views.provider.createOrEditModal as vm',
        backdrop: 'static',
        resolve: {
          countryCode: () => countryCode,
        },
      });

      modal.result.then(() => {
        getSmsProviders();
      });
    }

    function deleteProvider(countryCode, countryName) {
      abp.message.confirm(
        App.localize('SmsProviderDeleteWarningMessage', countryName),
        App.localize('AreYouSure'),
        (result) => {
          if (result) {
            smsSvc.deleteSmsProvider({ countryCode }).then(() => {
              getSmsProviders();
              abp.notify.success(App.localize('SuccessfullyDeleted'));
            });
          }
        }
      );
    }
  }
})();
