(function () {
    var controllerId = 'clinic.views.guaranteeLetters.index';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$stateParams', '$state', '$uibModal', '$timeout', 'abp.services.app.guaranteeLetter', 'abp.services.app.commonLookup', 'Hms.GuaranteeLetters.GuaranteeLetterStatus',
        'moment',
        function ($scope, $stateParams, $state, $modal, $timeout, guaranteeLetterSvc, commonLookupSvc, enumGuaranteeLetterStatus, moment) {
            function init() {
                loadClinicLocationsFilter();

                $timeout(function () {
                    vm.gridOptions.paginationCurrentPage = Math.floor(vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1);
                });

                getTimeZoneId();
            }

            var vm = this;
            vm.loading = 0;
            vm.exporting = 0;
            vm.hasSpecialistWorkflowEnhancement = abp.setting.getBoolean('Hms.Feature.SpecialistWorkflowEnhancement');
            vm.isInpatient = App.isInpatient();

            vm.enums = {};
            vm.enums.guaranteeLetterStatus = enumGuaranteeLetterStatus;

            vm.permissions = {
                canCreateTicket: abp.auth.isGranted('SpecialistClaims.Clinic.Create')
            };

            $scope.$on('$viewContentLoaded', function () {
                App.initAjax();
            });

            vm.clinicLocations = [];
            function loadClinicLocationsFilter() {
                vm.loading++;
                commonLookupSvc.findClinicLocations({})
                    .success(function (data) {
                        vm.clinicLocations = data.items;
                    }).finally(function () {
                        if (!vm.clinicLocations.length) {
                            vm.requestParams.clinicLocationId = null;
                        }
                        vm.loading--;
                    });
            }

            vm.requestParams = {
                filter: $stateParams.filter,
                status: $stateParams.status,
                letterNumber: $stateParams.letterNumber,
                clinicLocationId: $stateParams.clinicLocation,
                skipCount: $stateParams.skipCount || 0,
                maxResultCount: $stateParams.maxResultCount || app.consts.grid.defaultPageSize,
                sorting: $stateParams.sorting
            };

            vm.checkedInDateRangeOptions = App.createDateRangePickerOptions();
            vm.checkedInDateRangeModel = {
                startDate: $stateParams.checkedInStartDate ? moment($stateParams.checkedInStartDate) : null,
                endDate: $stateParams.checkedInEndDate ? moment($stateParams.checkedInEndDate) : null
            };

            function getRequestInput() {
                var input = $.extend({}, vm.requestParams);
                input.checkedInStartDate = vm.checkedInDateRangeModel.startDate;
                input.checkedInEndDate = vm.checkedInDateRangeModel.endDate;

                registerStateParams();

                return input;
            }

            vm.gridOptions = {
                appScopeProvider: vm,
                paginationPageSizes: app.consts.grid.defaultPageSizes,
                paginationPageSize: app.consts.grid.defaultPageSize,
                paginationCurrentPage: 0,
                useExternalPagination: true,
                useExternalSorting: true,
                resizable: true,
                columnDefs: [{
                    name: 'Actions',
                    displayName: 'Actions',
                    enableSorting: false,
                    width: 100,
                    headerCellTemplate: '<span></span>',
                    cellTemplate: 'actionTemplate'
                }, {
                    displayName: App.localize('CheckedInDate'),
                    enableSorting: false,
                    field: 'checkedInTime',
                    cellFilter: 'momentFormat: \'L\'',
                    minWidth: 70
                }, {
                    displayName: App.localize('Status'),
                    field: 'status',
                    minWidth: 100,
                    cellTemplate: 'statusTemplate'
                }, {
                    displayName: App.localize('PatientName'),
                    enableSorting: false,
                    field: 'employeeName',
                    minWidth: 120
                }, {
                    displayName: App.localize('IcPassportNumber'),
                    enableSorting: false,
                    field: 'employeeNationalId',
                    minWidth: 120
                }, {
                    displayName: App.localize('GuaranteeLetterNumber'),
                    field: 'letterNumber',
                    minWidth: 100,
                    width: 150
                }, {
                    displayName: App.localize('Disipline'),
                    enableSorting: false,
                    field: 'disciplines'
                }, {
                    displayName: App.localize('PanelLocation'),
                    enableSorting: false,
                    field: 'panelLocationName',
                    minWidth: 120
                }],
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                    $scope.gridApi.core.on.sortChanged($scope, function (scope, callback) {
                        vm.requestParams.sorting = callback.length && callback[0].field ? callback[0].field + ' ' + callback[0].sort.direction : null;
                        vm.getGuaranteeLetters();
                    });
                    gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
                        vm.requestParams.skipCount = (newPage - 1) * pageSize;
                        vm.requestParams.maxResultCount = pageSize;
                        vm.getGuaranteeLetters();
                    });
                },
                data: []
            };

            vm.getGuaranteeLetters = function () {
                var input = getRequestInput();

                vm.loading++;
                guaranteeLetterSvc.getGuaranteeLettersForSpecialist(input).success(function (data) {
                    vm.gridOptions.totalItems = data.totalCount;
                    vm.gridOptions.data = _.map(data.items, function (d) {
                        return $.extend(d, {
                            isMalaysian: d.patientEmployeeNationalityCode === 'MY',
                            isActive: !(d.status === 3 || d.status === 4 || d.status === 5),
                            isSelfManage: vm.isHost && d.status === 0 && d.preferSelfManageGl
                        });
                    });
                }).finally(function (e) {
                    vm.loading--;
                });
            };

            function registerStateParams() {
                var checkedInStartDate = vm.checkedInDateRangeModel.startDate ? vm.checkedInDateRangeModel.startDate.format('YYYY-MM-DD') : null;
                var checkedInEndDate = vm.checkedInDateRangeModel.endDate ? vm.checkedInDateRangeModel.endDate.format('YYYY-MM-DD') : null;

                $state.transitionTo($state.current, {
                    filter: vm.requestParams.filter,
                    status: vm.requestParams.status,
                    clinicLocation: vm.requestParams.clinicLocationId,
                    checkedInStartDate: checkedInStartDate,
                    checkedInEndDate: checkedInEndDate,
                    skipCount: vm.requestParams.skipCount,
                    maxResultCount: vm.requestParams.maxResultCount,
                    sorting: vm.requestParams.sorting
                }, {
                    notify: false
                });
            }

            vm.exportToExcel = function () {
                var input = getRequestInput();

                vm.exporting++;
                guaranteeLetterSvc.getGuaranteeLettersForSpecialistToExcel(input).success(function (data) {
                    App.downloadTempFile(data);
                }).finally(function () {
                    vm.exporting--;
                });
            };

            vm.requestExtension = function (gl) {
                $modal.open({
                    templateUrl: require('/App/clinic/views/guaranteeLetters/extensionRequest/extensionRequest.modal.html'),
                    controller: 'clinic.views.guaranteeLetter.extensionRequestModal as vm',
                    backdrop: 'static',
                    resolve: {
                        patientName: function () {
                            return gl.employeeName;
                        },
                        nationalId: function () {
                            return gl.employeeNationalId;
                        },
                        glNumber: function () {
                            return gl.letterNumber;
                        }
                    }
                }).result.then(function () {
                    vm.getGuaranteeLetters();
                });
            };

            vm.createClaim = function (gl, claimWithTopUp) {
                if (vm.hasSpecialistWorkflowEnhancement) {
                    $state.go('createEditSpecialistFinalRequest', {
                        letterNumber: gl.letterNumber,
                        claimWithTopUp: claimWithTopUp,
                        timeZoneId: vm.timeZoneId
                    });
                } else {
                    $state.go('createEditSpecialistClaim', {
                        letterNumber: gl.letterNumber,
                        employeeId: gl.employeeId,
                        panelLocationId: gl.panelLocationId,
                        claimWithTopUp: claimWithTopUp,
                        timeZoneId: vm.timeZoneId
                    });
                }

            };

            function getTimeZoneId() {
                vm.loading += 1;
                commonLookupSvc.getTenantIanaTimeZoneId({})
                    .success((data) => {
                        vm.timeZoneId = data;
                    })
                    .finally(() => {
                        vm.loading -= 1;
                    });
            }

            init();
        }
    ]);
})();
