(() => {
  angular.module('app').factory('appUserNotificationHelper', AppUserNotificationHelperFactory);

  AppUserNotificationHelperFactory.$inject = [
    '$uibModal',
    'abp.services.app.notification',
    '$window',
  ];

  function AppUserNotificationHelperFactory($uibModal, notificationSvc, $window) {
    const service = {
      format,
      show,
      setAllAsRead,
      setAsRead,
      openSettingsModal,
    };

    return service;

    function getSeverityIcon(severity) {
      switch (severity) {
        case abp.notifications.severity.SUCCESS:
          return 'text-success fa fa-check';
        case abp.notifications.severity.WARN:
          return 'text-warning fas fa-exclamation-triangle';
        case abp.notifications.severity.ERROR:
          return 'text-danger fa fa-bolt';
        case abp.notifications.severity.FATAL:
          return 'text-danger fa fa-bomb';
        case abp.notifications.severity.INFO:
        default:
          return 'text-info fa fa-info';
      }
    }

    function getUrl(n) {
      switch (n.notification.notificationName) {
        case 'Users.NewUserRegistered':
          return `#/users?filterText=${n.notification.data.properties.emailAddress}`;
        case 'Employees.NewTicketCreated':
        case 'Employees.TicketUpdated':
          return `#/common/patientTickets?ticketNumber=${n.notification.data.properties.ticketNumber}`;
        case 'Employees.CheckIn':
          return `#/corporate/employees?filterText=${n.notification.data.properties.employeeName}`;
        case 'Corporate.NewSaleTransactionCreated':
          return `#/common/saleTransactions?transactionNumber=${n.notification.data.properties.transactionNumber}`;
        // no default
      }
      return null;
    }

    function format(notification, truncateText) {
      const d = {
        userNotificationId: notification.id,
        text: abp.notifications.getFormattedMessageFromUserNotification(notification),
        time: notification.notification.creationTime,
        icon: getSeverityIcon(notification.notification.severity),
        state: abp.notifications.getUserNotificationStateAsString(notification.state),
        data: notification.notification.data,
        url: getUrl(notification),
      };
      if (truncateText || truncateText === undefined) {
        d.text = abp.utils.truncateStringWithPostfix(d.text, 100);
      }
      return d;
    }

    function show(notification) {
      abp.notifications.showUiNotifyForUserNotification(notification, {
        onclick() {
          const url = getUrl(notification);
          if (url) {
            $window.location.href = url;
          }
        },
      });
    }

    function setAllAsRead(callback) {
      notificationSvc.setAllNotificationsAsRead().success(() => {
        abp.event.trigger('abp.notifications.refresh');
        if (callback) {
          callback();
        }
      });
    }

    function setAsRead(notificationId, callback) {
      notificationSvc
        .setNotificationAsRead({
          id: notificationId,
        })
        .success(() => {
          abp.event.trigger('abp.notifications.read', notificationId);
          if (callback) {
            callback(notificationId);
          }
        });
    }

    function openSettingsModal() {
      $uibModal.open({
        templateUrl: require('../views/notifications/settingsModal.html'),
        controller: 'common.views.notifications.settingsModal as vm',
        backdrop: 'static',
      });
    }
  }
})();
