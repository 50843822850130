(function () {
    angular.module('app').component('hostDoraemonAdjustmentProcessingRequestDetailsComponent', {
        templateUrl: require('/App/host/views/doraemon/adjustmentProcessing/requestDetails/requestDetails.component.html'),
        controller: [controller],
        controllerAs: 'vm',
        bindings: {
            guaranteeLetterNumber: '<',
            requestInfo: '<',
            isEdit: '='
        }
    });

    var app = angular.module('app');

    function controller() {
        function init() {
            styleFinalBillAmount();
        }

        var vm = this;
        vm.lookupDoctor = lookupDoctor;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.loading = 0;
        vm.savedTrigger = 0;
        vm.diagnosis = null;
        vm.filteredDoctors = [];
        vm.hasState = false;
        vm.isHost = App.isHost();
        vm.isCorporate = App.isCorporate();
        vm.medicalCertificate = true;
        vm.styleFinalBillAmount = styleFinalBillAmount;
        vm.doctors = vm.requestInfo.doctors || [];

        var limitSize = 100;

        function styleFinalBillAmount() {
            if (!vm.requestInfo.request) return;
            vm.isNegativeValue = false;
            var currentFinalBillAmount = vm.requestInfo.request.finalBillAmount;
            var newFinalBillAmount = vm.requestInfo.request.adjustedFinalBillAmount;
            vm.finalBillAmountDifference = newFinalBillAmount - currentFinalBillAmount;

            if (vm.finalBillAmountDifference < 0) {
                vm.isNegativeValue = true;
                vm.finalBillAmountDifference = Math.abs(vm.finalBillAmountDifference);
            }
            return vm.finalBillAmountDifference;
        }

        function lookupDoctor(filter) {
            var keyword = _.trim(filter || '');
            if (keyword) {
                var filteredDoctors = [];

                var regexes = _.chain(keyword.split(' '))
                    .map(function (k) {
                        return _.trim(k.toUpperCase());
                    })
                    .filter(function (k) {
                        return k;
                    })
                    .uniq()
                    .map(function (k) {
                        _.escapeRegExp(k);
                        return {
                            token: k,
                            len: k.length,
                            pattern: new RegExp('\\b' + k, 'i')
                        };
                    })
                    .value();

                _.forEach(vm.doctors, function (doctor) {
                    var score = 0;
                    _.forEach(regexes, function (r) {
                        if (r.pattern.test(doctor.name)) {
                            score -= r.len;
                        }
                    });

                    if (score) {
                        filteredDoctors.push({
                            score: score,
                            item: doctor
                        });
                    }
                });

                vm.filteredDoctors = _.chain(filteredDoctors)
                    .sortBy(['score'])
                    .take(30)
                    .map(function (k) {
                        return k.item;
                    })
                    .uniqBy(function (s) {
                        return s.name;
                    })
                    .value();
            } else {
                vm.filteredDoctors = _.take(vm.doctors, limitSize);
            }
        };

        var fileExtensions = '|' + app.consts.fileUpload.imageExtensions.concat(app.consts.fileUpload.pdfExtensions).join('|') + '|';
        vm.uploadConfig = {
            objectType: 'InpatientAdjustmentRequestAttachment',
            itemsLimit: 3,
            accept: 'image/*,application/pdf',
            filters: [{
                name: 'fileFilter',
                fn: function (n) {
                    vm.invalidAttachment = false;
                    var t = '|' + n.type.slice(n.type.lastIndexOf('/') + 1) + '|';
                    if (fileExtensions.indexOf(t) === -1) {
                        vm.invalidAttachment = true;
                        return false;
                    }
                    return true;
                }
            }, {
                name: 'sizeFilter',
                fn: function (n) {
                    vm.invalidAttachmentSize = false;
                    var sizeLimit = 52428800;
                    if (n.size > sizeLimit) {
                        vm.invalidAttachmentSize = true;
                        return false;
                    }
                    return true;
                }
            }]
        };

        init();
    }
})();
