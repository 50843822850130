(() => {
  angular.module('app').component('commonComponentsBalancePoolTableComponent', {
    templateUrl: require('./balancePoolTable.component.html'),
    controller: BalancePoolTableController,
    controllerAs: 'vm',
    bindings: {
      benefits: '<',
      hospitalizationBenefits: '<',
      isHospitalization: '<',
      currencyCode: '=?',
      chart: '<'
    },
  });

  BalancePoolTableController.$inject = [];

  function BalancePoolTableController() {
    const vm = this;

    // HM-4554: For parent component or page that does not have currency code
    // passed in, will be defaulted to hms setting currency code.

    vm.currencyCode =
      _.isNil(vm.currencyCode) || vm.currencyCode === undefined
        ? abp.setting.get('Hms.General.CurrencyCode')
        : vm.currencyCode;

    vm.hasSobEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');
    vm.isChart = document.getElementById('utilRateLabel');

    vm.updateGraph = updateGraph;

    function updateGraph(benefit){
      const title = document.getElementById('utilRateLabel');
      if (!benefit.isUnlimited) {
        title.textContent = `Pool: ${benefit.title} Utilization rate`;
        const utilRate = (benefit.used / benefit.limit * 100).toFixed(2);
        vm.chart.load({
          columns: [
            [`${benefit.title} Utilization rate`, utilRate]
          ],
          unload: true
        });
      } else {
        title.textContent = `Pool: ${benefit.title} - Unlimited`;
        vm.chart.load({
          columns: [
            [`${benefit.title} - Unlimited`, 0]
          ],
          unload: true
        });
      }
    }

  }
})();
