(function () {
    'use strict';

    angular.module('app').component('hostCorporatesSupportPlansCreateEditScheduleOfBenefitPostNatalFollowUpComponent', {
        templateUrl: require('/App/host/views/corporates/supportPlans/createEdit/scheduleOfBenefit/postNatalFollowUp/postNatalFollowUp.component.html'),
        controller: controller,
        controllerAs: 'vm',
        bindings: {
            scheduleOfBenefit: '<',
            currencyCode: '<'
        }
    });

    function controller() {
        var vm = this;

        vm.postNatalFollowUpText = App.localize('PostNatalFollowUp');
        vm.perAnnumText = App.localize('PerAnnum');
        vm.daysText = App.localize("Days");

        vm.togglePostNatalFollowUp = function () {
            vm.minPostNatalDays = vm.scheduleOfBenefit.postNatalFollowUp.isCovered === 1 ? 1 : 0;
            vm.minPostNatalFollowUpAmount = vm.scheduleOfBenefit.postNatalFollowUp.amountType === 1 ? 1 : 0;
            vm.minPostNatalFollowUpCoveredTimes = vm.scheduleOfBenefit.postNatalFollowUp.maxDaysTimesType === 0 ? 1 : 0;
        };

        function init() {
            vm.togglePostNatalFollowUp();
        }

        angular.element(document).ready(function () {
            init();
        });
    }
})();
