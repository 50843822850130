(function () {
    'use strict';

    angular.module('app').component('clinicPatientTicketReferralLetter', {
        templateUrl: require('/App/clinic/views/patient/ticket/referralLetter/widget.html'),
        controller: controller,
        controllerAs: 'vm',
        bindings: {
            patient: '<',
            doctor: '<',
            ticket: '<',
            currencyCode: '<'
        }
    });

    function controller() {
        var vm = this;

        vm.$onChanges = function (changes) {
            if (changes.ticket && changes.ticket.currentValue) {
                vm.needReferral = vm.ticket.referralLetter ? true : false;
            }
        };

        vm.clearReferralDetails = function () {
            vm.ticket.referralLetter = null;
        };
    }
})();
