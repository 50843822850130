(() => {
  angular.module('app').controller('corporate.views.policyHolder', PolicyHolderController);

  PolicyHolderController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$timeout',
    '$uibModal',
    'appSession',
    'abp.services.app.policyHolder',
    'abp.services.app.commonLookup',
    'appConstants',
  ];

  function PolicyHolderController(
    $scope,
    $state,
    $stateParams,
    $timeout,
    $modal,
    appSession,
    policyHolderSvc,
    commonLookupSvc,
    appConstants
  ) {
    const vm = this;
    vm.loading = 0;
    vm.exporting = 0;
    vm.isSubsidiary = appSession.user.subsidiaryId;
    vm.expiringMasterPolicyMessage = null;

    vm.hasEmployeeBulkUpdateUiEnhancement = abp.setting.getBoolean(
      'Hms.Feature.EmployeeBulkUpdateUiEnhancement'
    );

    vm.states = [];
    vm.statesFilter = [
      {
        name: 'Active',
        value: 1,
      },
      {
        name: 'Suspended',
        value: 2,
      },
      {
        name: 'Terminated',
        value: 3,
      },
    ];

    vm.employeeTypes = [];
    vm.employeeTypes.push({
      name: App.localize('All'),
      value: null,
    });
    vm.employeeTypes.push({
      name: App.localize('Principal'),
      value: 0,
    });
    vm.employeeTypes.push({
      name: App.localize('Dependent'),
      value: 1,
    });

    let departments = [];
    vm.subsidiaryDepartments = [];
    vm.subsidiaries = [];
    vm.insurancePlans = [];

    vm.policyHolderGridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.defaultPageSizes,
      paginationPageSize: appConstants.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      columnDefs: [
        {
          enableSorting: false,
          name: App.localize('Actions'),
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
        },
        {
          displayName: App.localize('Subsidiary'),
          field: 'subsidiary',
          visible: vm.isSubsidiary,
          minWidth: 100,
        },
        {
          displayName: App.localize('Department'),
          field: 'subsidiaryDepartment',
          minWidth: 100,
        },
        {
          displayName: App.localize('Name'),
          field: 'employeeName',
          minWidth: 100,
          cellTemplate: 'employeeNameTemplate',
        },
        {
          displayName: App.localize('IdentificationNumber'),
          field: 'nationalId',
          cellFilter: 'nationalIdFilter',
          minWidth: 100,
        },
        {
          displayName: App.localize('DateOfBirth'),
          field: 'birthDate',
          cellFilter: 'birthDateFilter',
          minWidth: 65,
        },
        {
          displayName: App.localize('EmployeeType'),
          field: 'employeeType',
          minWidth: 65,
          cellTemplate: 'typeTemplate',
        },
        {
          displayName: App.localize('Status'),
          field: 'state',
          cellTemplate: 'stateTemplate',
          minWidth: 65,
        },
        {
          displayName: App.localize('EmployeeNumber'),
          field: 'employeeNumber',
          minWidth: 100,
        },
        {
          displayName: App.localize('MainPrincipal'),
          field: 'principalName',
          minWidth: 100,
        },
        {
          displayName: App.localize('GhsInsurancePlan'),
          field: 'insurancePlanName',
          cellTemplate: 'planTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('EffectiveDate'),
          field: 'effectiveDate',
          cellFilter: "momentFormat: 'L'",
          headerCellTemplate: 'effectiveDateHeaderTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('CoverageStartDate'),
          field: 'coverageStartDate',
          cellFilter: "momentFormat: 'L'",
          headerCellTemplate: 'coverageStartDateHeaderTemplate',
          minWidth: 100,
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          let sorting = '';
          for (let i = 0; i < callback.length; i += 1) {
            if (i) sorting += ', ';
            sorting += `${callback[i].field} ${callback[i].sort.direction}`;
          }
          vm.requestParams.sorting = sorting;
          getPolicyHolder();
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, vm.getPolicyHolder)
        );
      },
      data: [],
    };

    vm.permissions = {
      import: abp.auth.isGranted('Corporate.Insurance.PolicyHolder.Import'),
      assignInsurance: abp.auth.isGranted(
        'Corporate.Insurance.PolicyHolder.InsurancePlanAssignment'
      ),
    };

    vm.requestParams = {
      filterText: $stateParams.filterText,
      employeeType: $stateParams.employeeType,
      states: $stateParams.states,
      plan: $stateParams.plan,
      subsidiary: $stateParams.subsidiary,
      department: $stateParams.department,
      skipCount: $stateParams.skipCount,
      maxResultCount: $stateParams.maxResultCount,
      sorting: $stateParams.sorting,
    };

    vm.getPolicyHolder = getPolicyHolder;
    vm.getSubsidiaryDepartments = getSubsidiaryDepartments;
    vm.getDepartments = getDepartments;
    vm.refreshDepartments = refreshDepartments;
    vm.getInsurancePlan = getInsurancePlan;
    vm.importPolicyHolder = importPolicyHolder;
    vm.switchEmployeeListing = switchToEmployeeListing;
    vm.exportToExcel = exportToExcel;
    vm.showInsuranceProfile = showInsuranceProfile;
    vm.assignInsurancePlan = assignInsurancePlan;

    init();

    function init() {
      getSubsidiaries();
      getSubsidiaryDepartments();
      getDepartments();
      getInsurancePlan();
      $timeout(() => {
        vm.policyHolderGridOptions.paginationCurrentPage = Math.floor(
          vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1
        );
      });
    }

    function registerStateParams() {
      $state.transitionTo(
        $state.current,
        {
          filterText: vm.requestParams.filterText,
          employeeType: vm.requestParams.employeeType,
          states: vm.requestParams.states,
          plan: vm.requestParams.plan,
          subsidiary: vm.requestParams.subsidiary,
          department: vm.requestParams.department,
          skipCount: vm.requestParams.skipCount,
          maxResultCount: vm.requestParams.maxResultCount,
          sorting: vm.requestParams.sorting,
        },
        {
          notify: false,
        }
      );
    }

    function switchToEmployeeListing() {
      $state.go('corporate.employees', {
        employeeType: vm.requestParams.employeeType,
        states: vm.requestParams.states,
        filterText: vm.requestParams.filterText,
        subsidiary: vm.requestParams.subsidiary,
        department: vm.requestParams.department,
      });
    }

    function getSubsidiaries() {
      commonLookupSvc
        .getCorporateSubsidiaries({
          getDeletedSubsidiariesOrDepartment: false,
        })
        .success((data) => {
          vm.subsidiaries = data.items;

          // HM-2432: Show if organisation has multiple subsidiaries.

          const subsidiaryNameColumne = _.find(
            vm.policyHolderGridOptions.columnDefs,
            'displayName',
            App.localize('Subsidiary')
          );
          if (!subsidiaryNameColumne.visible) {
            subsidiaryNameColumne.visible = vm.subsidiaries.length > 1;
          }
        });
    }

    function getSubsidiaryDepartments() {
      if (!departments.length) {
        commonLookupSvc
          .getCorporateSubsidiaryDepartments({
            getDeletedSubsidiariesOrDepartment: false,
          })
          .success((data) => {
            departments = data.items;
          });
      }
    }

    function getDepartments(search) {
      let subsidiaryIds = [];
      if (vm.requestParams.subsidiary) {
        subsidiaryIds = vm.requestParams.subsidiary;
      } else if (vm.isSubsidiary) {
        subsidiaryIds = appSession.user.subsidiaryId;
      }

      if (subsidiaryIds.length > 0) {
        vm.subsidiaryDepartments = [];

        // change department filter list according to selected subsidiaries

        _.forEach(subsidiaryIds, (id) => {
          const d = _.filter(departments, {
            subsidiaryId: id,
          });
          vm.subsidiaryDepartments = _.concat(vm.subsidiaryDepartments, d);
        });
      } else {
        vm.subsidiaryDepartments = departments;
      }

      if (search) {
        vm.subsidiaryDepartments = _.filter(vm.subsidiaryDepartments, (d) =>
          _.includes(d.name.toLowerCase(), search.toLowerCase())
        );

        // only return top 10 in the department list for display

        vm.subsidiaryDepartments = _.slice(vm.subsidiaryDepartments, 0, 10);
      } else if (!vm.requestParams.department) {
        vm.subsidiaryDepartments = [];
      }
    }

    function refreshDepartments() {
      vm.requestParams.department = [];
      vm.subsidiaryDepartments = [];
    }

    function getInsurancePlan() {
      commonLookupSvc.getCorporateInsurancePlans().success((data) => {
        vm.insurancePlans = data.items;
        vm.insurancePlans.unshift({
          name: App.localize('All'),
          value: null,
        });
      });
    }

    function getPolicyHolder() {
      registerStateParams();

      let employeeType = parseInt(vm.requestParams.employeeType, 10);
      if (Number.isNaN(employeeType)) employeeType = null;

      const input = {
        filter: vm.requestParams.filterText,
        type: employeeType,
        states: vm.requestParams.states,
        plan: vm.requestParams.plan,
        subsidiaryIds: vm.requestParams.subsidiary,
        departmentIds: vm.requestParams.department,
        sorting: vm.requestParams.sorting,
        skipCount: vm.requestParams.skipCount,
        maxResultCount: vm.requestParams.maxResultCount,
      };

      vm.loading += 1;
      policyHolderSvc
        .getPolicyHolder(input)
        .success((data) => {
          vm.policyHolderGridOptions.totalItems = data.totalCount;
          vm.policyHolderGridOptions.data = data.items;
          if (data.expiringMasterPolicyNumbers) {
            vm.expiringMasterPolicyMessage = App.localize(
              'ExpiringMasterPolicyNarrative',
              data.expiringMasterPolicyNumbers
            );
          } else {
            vm.expiringMasterPolicyMessage = null;
          }

          if (data.items.length > 0) {
            // HM-2432: Show if subsidiary name is different with organisation.
            const subsdiaryColumn = _.find(
              vm.policyHolderGridOptions.columnDefs,
              'displayName',
              App.localize('Subsidiary')
            );
            if (!subsdiaryColumn.visible) {
              subsdiaryColumn.visible =
                _.first(data.items).subsidiaryName !== appSession.tenant.name;
            }
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function exportToExcel() {
      vm.exporting += 1;

      registerStateParams();

      let employeeType = parseInt(vm.requestParams.employeeType, 10);
      if (Number.isNaN(employeeType)) employeeType = null;

      const input = {
        filter: vm.requestParams.filterText,
        type: employeeType,
        states: vm.requestParams.states,
        plan: vm.requestParams.plan,
        subsidiaryIds: vm.requestParams.subsidiary,
        departmentIds: vm.requestParams.department,
      };

      policyHolderSvc
        .getPolicyHoldersToExcel(input)
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.exporting -= 1;
        });
    }

    function showInsuranceProfile(entity) {
      showInsuranceProfileModal(entity.id);
    }

    function showInsuranceProfileModal(id) {
      const modal = $modal.open({
        templateUrl: require('./viewInsuranceProfile/viewInsuranceProfile.modal.html'),
        controller: 'corporate.views.policyHolder.viewInsuranceProfileModal as vm',
        backdrop: 'static',
        resolve: {
          employeeId: () => id,
        },
      });
      modal.result.then(() => {
        $state.reload();
      });
    }

    function assignInsurancePlan(employee) {
      if (!employee.principalId) {
        showAssignInsurancePlanModal(employee.id, 0);
      } else {
        showAssignInsurancePlanModal(employee.principalId, 1);
      }
    }

    function showAssignInsurancePlanModal(id, tab) {
      $modal
        .open({
          templateUrl: require('./assignInsurancePlan/assignInsurancePlan.modal.html'),
          controller: 'corporate.views.policyHolder.assignInsurancePlanModal as vm',
          backdrop: 'static',
          resolve: {
            employeeId() {
              return id;
            },
            selectedTab() {
              return tab;
            },
          },
        })
        .result.then(() => {
          getPolicyHolder();
          $state.reload();
        });
    }

    function importPolicyHolder() {
      let modalWidth = '';
      if (vm.hasEmployeeBulkUpdateUiEnhancement) {
        modalWidth = 'xl';
      }

      const modal = $modal.open({
        templateUrl: require('./import/import.modal.html'),
        controller: 'corporate.views.policyHolder.import as vm',
        backdrop: 'static',
        size: modalWidth,
      });
      modal.result.then(() => {
        getPolicyHolder();
      });
    }
  }
})();
