import XLSX from 'xlsx/xlsx.js';

(() => {
  angular.module('app').directive('importSheetJs', importSheetJsDirective);

  importSheetJsDirective.$inject = [];

  function importSheetJsDirective() {
    const directive = {
      link,
      restrict: 'EA',
      scope: {
        sheet: '=',
        isWorkbookOpen: '=isFile',
      },
    };
    return directive;

    function link(scope, element) {
      element.on('change', (changeEvent) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          const fileName = changeEvent.target.files[0].name;
          const isValidExcel = /(\.xlsx)|(\.xls)/.test(fileName);

          // Check for correct file extension i.e. .xlsx/.xls.
          if (!isValidExcel) {
            abp.message.warn(App.localize('Excel_Warn_FileType'));
            scope.sheet(XLSX.utils.book_new());
          } else {
            // Check for correct excel template used.
            const ab = e.target.result;
            const workbook = XLSX.read(ab);

            scope.sheet(workbook);
          }
        };

        if (changeEvent.target.files.length !== 0) {
          reader.readAsArrayBuffer(changeEvent.target.files[0]);
        }
      });
    }
  }
})();
