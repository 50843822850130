(function () {
    'use strict';

    angular.module('app').component('employeeBasicDetailsComponent', {
        templateUrl: require('/App/corporate/views/employees/employeeBasicDetails/employeeBasicDetails.component.html'),
        controller: ['Hms.Employees.EmployeeState', 'Hms.Gender', controller],
        controllerAs: 'vm',
        bindings: {
            profile : '<'
        }
    });

    function controller(enumEmployeeState, enumGender) {
        var vm = this;

        vm.enums = {};
        vm.enums.employeeState = enumEmployeeState;
        vm.enums.gender = enumGender;
    }
})();

