import _ from 'lodash';

(() => {
  angular.module('finance').controller('finance.views.debugTools', DebugToolsPageController);

  DebugToolsPageController.$inject = ['$scope', '$uibModal', 'financeConstants'];

  function DebugToolsPageController($scope, $modal, financeConsts) {
    const vm = this;

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: financeConsts.grid.defaultPageSizes,
      paginationPageSize: financeConsts.grid.defaultPageSize,
      paginationCurrentPage: 1,
      columnDefs: getGridOptionsColumnDefs(),
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
      },
      data: [],
    };

    vm.actions = [
      {
        name: 'Create mock bank account deposit',
        method: openCreateMockBankAccountDepositModal,
        type: 'Mock data creator',
      },
    ];

    init();

    function init() {
      vm.actions = _.orderBy(vm.actions, ['type', 'name'], ['asc', 'asc']);
      vm.gridOptions.data = vm.actions;
    }


    function getGridOptionsColumnDefs() {
      const columnDefs = [
        {
          name: App.localize('Actions'),
          enableSorting: false,
          width: 90,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
        },
        {
          displayName: App.localize('Name'),
          field: 'name',
          minWidth: 100,
          enableSorting: false,
        },
        {
          displayName: App.localize('Type'),
          field: 'type',
          minWidth: 100,
          enableSorting: false,
        },
      ];

      return columnDefs;
    }

    function openCreateMockBankAccountDepositModal() {
      $modal
        .open({
          templateUrl: require('./createMockBankAccountDeposit.modal.html'),
          controller: 'finance.views.debugTools.createMockBankAccountDepositModal as vm',
          backdrop: 'static',
          resolve: {
            // EMPTY as intended.
          },
        })
        .result.then(() => {
          // EMPTY as intended.
        });
    }
  }
})();
