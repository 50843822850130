(() => {
  angular
    .module('app')
    .controller('common.views.saleTransactions.previewBulkPaymentsModal', ControllerController);

  ControllerController.$inject = [
    '$state',
    '$uibModalInstance',
    '$timeout',
    '$filter',
    'abp.services.app.saleTransaction',
    'inputs',
    'moment',
  ];

  function ControllerController(
    $state,
    $uibModalInstance,
    $timeout,
    $filter,
    saleTransactionSvc,
    inputs,
    moment
  ) {
    const vm = this;

    const today = moment().startOf('day');

    vm.saving = 0;
    vm.sendEmails = true;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.loading = true;

    vm.cancel = cancel;
    vm.save = save;

    init();

    function init() {
      $timeout(() => {
        const currencyFilter = $filter('currencyFormat');
        vm.inputs = _.map(inputs, (d) => {
          const paymentDesc = `${moment(d.paymentDate).format('L')} | ${
            d.paymentMethodStr
          } | ${currencyFilter(d.amount, vm.currencyCode)}`;

          return $.extend({}, d, {
            dateCheck: d.paymentDate <= today,
            amtCheck:
              d.amount && Math.round(d.amount * 100) === Math.round(d.outstandingAmount * 100),
            strEmailAddresses: d.emailAddresses,
            paymentDesc,
          });
        });
        vm.loading = false;
      }, 1500);
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function save() {
      if (vm.loading) return;
      _.each(vm.inputs, (input) => {
        const d = input;
        d.emailAddresses = vm.sendEmails ? d.strEmailAddresses : null;
      });

      vm.saving += 1;
      saleTransactionSvc
        .bulkMakePayments({
          records: vm.inputs,
        })
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          $uibModalInstance.close();
          $state.go('saleTransactions');
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }
  }
})();
