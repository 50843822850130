(function () {
    angular.module('app').controller('host.views.publicHolidays.import', [
        '$scope', '$uibModal', '$uibModalInstance', 'FileUploader', '$interval', 'abp.services.app.publicHoliday', '$rootScope',
        function ($scope, $modal, $uibModalInstance, FileUploader, $interval, publicHolidaySvc, $root) {
            var vm = this;

            vm.cancel = cancel;
            vm.confirm = confirm;

            // Download current template.

            vm.exportTemplateToExcel = function () {
                vm.exporting++;
                publicHolidaySvc.getPublicHolidaysToExcel().success(function (data) {
                    App.downloadTempFile(data);
                }).finally(function () {
                    vm.exporting--;
                });
            };

            // Import public holidays.

            vm.uploader = new FileUploader({
                url: $root.resolveAppPath('PublicHoliday/ImportPublicHolidays'),
                queueLimit: 1,
                headers: App.getFileUploaderHeaders(),
                filters: [{
                    name: 'excelFilter',
                    fn: function (n) {
                        var t = '|' + n.type.slice(n.type.lastIndexOf('/') + 1) + '|';
                        if ('|vnd.openxmlformats-officedocument.spreadsheetml.sheet|'.indexOf(t) === -1
                            && !n.name.endsWith('.xlsx')) {
                            abp.message.warn(App.localize('Excel_Warn_FileType'));
                            return false;
                        }
                        return true;
                    }
                }]
            });

            vm.uploader.onCompleteItem = function (n, t) {
                vm.saving = false;
                $uibModalInstance.close();

                if (!t.success && t.error) {
                    if (t.error.details) {
                        $modal.open({
                            templateUrl: require('/App/host/views/publicHolidays/import/importResult.modal.html'),
                            controller: 'host.views.publicHolidays.importResult as vm',
                            backdrop: 'static',
                            resolve: {
                                errors: function () {
                                    return JSON.parse(t.error.details);
                                }
                            }
                        });
                    }

                    if (t.error.message) {
                        abp.message.error(t.error.message);
                    }
                }

                if (t.success) {
                    abp.message.success(t.result, App.localize('ImportPublicHolidaysSuccessful'));
                }
            };

            vm.save = function () {
                vm.savingProgress = 0;
                vm.saving = true;
                vm.uploader.uploadAll();
            };

            vm.savingProgress = 0;
            function tickerJob() {
                vm.savingProgress = (vm.savingProgress + 1) % 100;
            }

            var tickerInterval = $interval(tickerJob, 1000);
            $scope.$on('$destroy', function () {
                if (angular.isDefined(tickerInterval)) {
                    $interval.cancel(tickerInterval);
                    tickerInterval = undefined;
                }
            });

            function cancel() {
                $uibModalInstance.dismiss();
            }

            function confirm() {
                $uibModalInstance.close();
            }
        }
    ]);
})();
