(function () {
    var controllerId = 'host.views.smsLogs.index';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', '$uibModal', 'abp.services.app.sms', 'moment',
        function ($scope, $state, $stateParams, $modal, smsSvc, moment) {
            function init() {
                vm.getHistory();
            }

            var vm = this;
            vm.loading = 0;
            vm.saving = 0;
            vm.logs = [];

            $scope.$on('$viewContentLoaded', function () {
                App.initAjax();
            });

            /* SMS service logs */

            var today = moment().startOf('day');
            vm.dateRangeOptions = App.createDateRangePickerOptions();
            vm.dateRangeModel = {
                startDate: today,
                endDate: today
            };
            if ($stateParams.startDate)
                vm.dateRangeModel.startDate = moment($stateParams.startDate);
            if ($stateParams.endDate)
                vm.dateRangeModel.endDate = moment($stateParams.endDate);

            function registerStateParams() {
                var startDate = null;
                var endDate = null;
                if (vm.dateRangeModel.startDate)
                    startDate = vm.dateRangeModel.startDate.format('YYYY-MM-DD');
                if (vm.dateRangeModel.endDate)
                    endDate = vm.dateRangeModel.endDate.format('YYYY-MM-DD');

                $state.transitionTo($state.current,
                    {
                        startDate: startDate,
                        endDate: endDate
                    }, {
                        notify: false
                    });
            }

            vm.smsLogsGridOptions = {
                appScopeProvider: vm,
                paginationPageSizes: app.consts.grid.defaultPageSizes,
                paginationPageSize: app.consts.grid.defaultPageSize,
                columnDefs: [{
                    name: 'Actions',
                    enableSorting: false,
                    width: 75,
                    headerCellTemplate: '<span></span>',
                    cellTemplate: 'smsLogs.actionTemplate'
                }, {
                    displayName: App.localize('Time'),
                    field: 'creationTime',
                    cellFilter: 'momentFormat: \'L LTS\'',
                    width: 150,
                    minWidth: 50
                }, {
                    displayName: App.localize('Recipients'),
                    field: 'recipients',
                    width: 120,
                    minWidth: 50
                }, {
                    displayName: App.localize('Message'),
                    field: 'message',
                    minWidth: 150
                }, {
                    displayName: App.localize('StatusCode'),
                    field: 'statusCode',
                    width: 100,
                    minWidth: 50
                }, {
                    displayName: App.localize('StatusMessage'),
                    field: 'statusMessage',
                    width: 125,
                    minWidth: 50
                }, {
                    displayName: App.localize('ReferenceId'),
                    field: 'referenceId',
                    width: 100,
                    minWidth: 50
                }, {
                    displayName: App.localize('SmsCount'),
                    field: 'smsCount',
                    width: 90,
                    minWidth: 50
                }],
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                },
                data: []
            };

            vm.getHistory = function () {
                registerStateParams();

                vm.loading++;
                smsSvc.getSmsHistory(vm.dateRangeModel)
                    .success(function (data) {
                        vm.smsLogsGridOptions.data = data.items;
                    })
                    .finally(function () {
                        vm.loading--;
                    });
            };

            vm.showDetails = function (smsLog) {
                $modal.open({
                    templateUrl: require('/App/host/views/smsLogs/detailModal.html'),
                    controller: 'host.views.smsLogs.detailModal as vm',
                    backdrop: 'static',
                    resolve: {
                        smsLog: function () {
                            return smsLog;
                        }
                    }
                });
            };

            /* End of SMS service logs */

            init();
        }
    ]);
})();
