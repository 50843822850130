(function () {
    var controllerId = 'clinic.views.main.index';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$state',
        function ($state) {
            function init() {
                redirect();
            }

            var vm = this;

            function redirect() {
                // Check if panel has panel dashboard enabled.

                var hasPanelDashboard = abp.features.isEnabled('ClinicModule.PanelDashboard');

                // Redirect to appropriate page.

                var stateToGo = hasPanelDashboard ? 'clinic.panelDashboard' : 'clinic.checkin';

                $state.go(stateToGo);
            }

            init();
        }
    ]);
})();
