(() => {
  angular
    .module('app')
    .controller(
      'common.views.memberUtilizations.adjustMemberUtilizationConfirmationModal',
      MemberUtilizationsController
    );

  MemberUtilizationsController.$inject = [
    '$state',
    '$uibModalInstance',
    'ticketNumber',
    'Hms.PatientTickets.PatientTicketType',
  ];

  function MemberUtilizationsController(
    $state,
    $uibModalInstance,
    ticketNumber,
    enumPatientTicketType
  ) {
    const vm = this;

    vm.ticketNumber = ticketNumber;
    vm.hasMemberBenefitUtilizationEnabled = abp.setting.getBoolean(
      'Hms.Feature.MemberBenefitUtilization'
    );
    vm.close = close;
    vm.isHost = App.isHost();
    vm.enums = {
      patientTicketType: enumPatientTicketType,
    };

    vm.getTitle = getTitle;
    vm.showEditUtilization = showEditUtilization;

    init();

    function init() {}

    function getTitle() {
      return App.localize('ClaimXIssued', vm.ticketNumber);
    }

    function showEditUtilization() {
      $state.go('ticketEditMemberUtilization', { ticketNumber: vm.ticketNumber });
      $uibModalInstance.close();
    }

    function close() {
      $uibModalInstance.close();
    }
  }
})();
