(() => {
  angular.module('app').directive('fileReader', fileReaderDirective);

  fileReaderDirective.$inject = ['$q'];

  function fileReaderDirective($q) {
    const { slice } = Array.prototype;
    const directive = {
      link,
      restrict: 'A',
      require: '?ngModel',
    };
    return directive;

    function link(_scope, element, _attrs, ngModel) {
      if (!ngModel) return;

      ngModel.$render = () => {};

      element.bind('change', (e) => {
        const eTarget = e.target;

        $q.all(slice.call(eTarget.files, 0).map(readFile)).then((values) => {
          if (eTarget.multiple) ngModel.$setViewValue(values);
          else ngModel.$setViewValue(values.length ? values[0] : null);
        });

        function readFile(file) {
          const deferred = $q.defer();

          const reader = new FileReader();
          reader.onload = (e2) => {
            deferred.resolve(e2.target.result);
          };
          reader.onerror = (e2) => {
            deferred.reject(e2);
          };
          reader.readAsDataURL(file);

          return deferred.promise;
        }
      });
    }
  }
})();
