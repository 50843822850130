import swal from 'sweetalert';
import PromptQueue from '../../../../../lib/prompt-queue';

(function () {
  angular
    .module('app')
    .controller('pharmacy.views.prescriptions.origin.index', OriginPageController);

  OriginPageController.$inject = [
    '$state',
    '$filter',
    '$stateParams',
    'abp.services.app.prescription',
    'abp.services.app.drugSearch',
  ];

  function OriginPageController($state, $filter, $stateParams, prescriptionSvc, drugSearchSvc) {
    const vm = this;

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.defaultMaxAllowance = parseFloat(abp.setting.get('Hms.General.MaxAllowanceAmountLimit'));
    vm.loading = 0;
    vm.employees = null;
    vm.prescriptionNumber = $stateParams.prescriptionNumber;
    vm.fulfillmentNumber = $stateParams.fulfillmentNumber;
    vm.prescription = null;
    vm.claimPolicy = null;
    vm.acknowledge = null;
    vm.fulfillmentPrescription = null;
    vm.permissions = {
      editPrescription: abp.auth.isGranted('Pharmacy.Prescriptions.Edit'),
    };
    const unitValueFilter = $filter('unitValue');
    vm.saving = false;
    vm.filteredDrugs = [];

    vm.onAuditHistoryRefreshEvent = { handler: null };
    vm.onTicketHistoryRefreshEvent = { handler: null };
    vm.onMedicineHistoryRefreshEvent = { handler: null };

    let lastFilter = '';
    const cachedResults = {};
    vm.updateTreatments = updateTreatments;
    vm.save = save;
    vm.getTotalItems = getTotalItems;
    vm.getAmount = getAmount;
    vm.getTotalAmount = getTotalAmount;
    vm.getOrganizationAmount = getOrganizationAmount;
    vm.getCustomerAmount = getCustomerAmount;
    vm.getCoPayAmount = getCoPayAmount;
    vm.lookupDrugs = lookupDrugs;
    vm.updatePrescription = updatePrescription;
    vm.editPharmacyRecord = editPharmacyRecord;
    vm.showFulfillment = showFulfillment;
    vm.voidFulfillment = voidFulfillment;
    vm.tidyItems = tidyItems;

    init();

    function init() {
      refreshPrescription();
      refreshFulfillment();
    }

    function refreshPrescription() {
      vm.loading += 1;
      prescriptionSvc
        .getPrescription({
          prescriptionNumber: vm.prescriptionNumber,
        })
        .success((data) => {
          if (data.isAcknowledged) {
            vm.acknowledge = {
              isEdit: false,
              referenceNumber: data.referenceNumber,
              fulfillmentCount: data.fulfillmentCount,
              pharmacyRemarks: data.pharmacyRemarks,
            };
          } else {
            vm.acknowledge = { isEdit: true };
          }

          vm.acknowledge = {
            isEdit: false,
            isSaving: false,
            update() {
              if (vm.acknowledge.isSaving) return;
              vm.acknowledge.isSaving = true;
              prescriptionSvc
                .acknowledgePrescription({
                  id: vm.prescription.id,
                  referenceNumber: vm.acknowledge.referenceNumber,
                  fulfillmentCount: vm.acknowledge.fulfillmentCount,
                  pharmacyRemarks: vm.acknowledge.pharmacyRemarks,
                })
                .success(() => {
                  abp.notify.info(App.localize('SuccessfullySaved'));
                  refreshPrescription();
                  vm.onAuditHistoryRefreshEvent.handler();
                  vm.onTicketHistoryRefreshEvent.handler();
                  vm.onMedicineHistoryRefreshEvent.handler();
                })
                .finally(() => {
                  vm.acknowledge.isSaving = false;
                });
            },
          };
          vm.prescription = data;

          // Get employee balance according to organization's limit.

          vm.customerAllowance = Math.min(
            data.customerOrganizationAllowance,
            data.customerOrganizationLimit
          );

          // For doctor prescribed prescription.

          if (data.patientTicketNumber != null) {
            vm.ticketPrescriptions = angular.copy(data.items);
          }

          vm.currentFulfillmentsCount = _.filter(
            vm.prescription.fulfillments,
            (d) => d.isVoided === false
          ).length;
          vm.uncoveredTreatments = _.filter(
            vm.prescription.fulfillmentPrescription.treatments,
            (d) => d.isCovered === false
          );
          vm.options = data.fulfillmentPrescription;
          vm.claimPolicy = data.employeeDto.claimPolicy;
          getPharmacyAllocation(data.employeeDto);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getPharmacyAllocation(data) {
      vm.employee = data;

      // Get only pharmacy benefit type.

      vm.employee.pharmacyAllocation = _.findLast(
        vm.employee.clinicTypes,
        (n) => n.serviceType === 6
      );

      if (vm.employee.pharmacyAllocation) {
        vm.employee.pharmacyAllocation.benefits = [];

        if (
          vm.employee.pharmacyAllocation.balancePools &&
          vm.employee.pharmacyAllocation.balancePools.length
        ) {
          _.each(vm.employee.pharmacyAllocation.balancePools, (d) => {
            let title = _.join(
              _.map(d.benefitTypes, (bt) => {
                if (bt.id !== null) return App.localize('Pharmacy');
                return '';
              }),
              '/'
            );

            const isBudget = d.modifierType === 0 || d.modifierType === 2;
            let cycle = '';
            if (!d.isUnlimited) {
              switch (d.modifierCycle) {
                case 0:
                  cycle = `every ${d.interval} years `;
                  break;
                case 1:
                  cycle = 'annual ';
                  break;
                case 2:
                  cycle = 'monthly ';
                  break;
                case 3:
                  cycle = 'daily ';
                  break;
                case 4:
                  cycle = 'per visit ';
                  break;
                case 5:
                  cycle = '6 months ';
                  break;
                case 6:
                  cycle = '4 months ';
                  break;
                case 7:
                  cycle = '3 months ';
                  break;
                case 8:
                  cycle = '2 months ';
                  break;

                // no default
              }
              if (d.modifierType === 2 || d.modifierType === 3) cycle += 'overdraft ';
            }
            title += ` (${cycle}${isBudget ? 'budget' : 'visit'})`;

            const isEmptyExcess = d.isExcess && !d.employeePortion;
            if (!isEmptyExcess) {
              vm.employee.pharmacyAllocation.benefits.push({
                title,
                isUnlimited: d.isUnlimited,
                isExcess: d.isExcess,
                isBudget,
                limit: d.limit,
                used: d.used,
                locked: d.locked,
                balance: d.balance,
              });
            }
          });
        }

        if (vm.employee.pharmacyAllocation.coPay) {
          vm.employee.coPay = vm.employee.pharmacyAllocation.coPay;

          if (vm.employee.pharmacyAllocation.coPay.isPercentage) {
            vm.employee.pharmacyAllocation.coPay.title = App.localize(
              'CopayX',
              `${vm.employee.pharmacyAllocation.coPay.value}%`
            );
            vm.employee.pharmacyAllocation.coPay.text = App.localize(
              'PatientHasCopayPercentage',
              `${vm.employee.pharmacyAllocation.coPay.value}%`
            );
          } else {
            const valueText = $filter('currencyFormat')(
              vm.employee.pharmacyAllocation.coPay.value,
              vm.currencyCode
            );
            vm.employee.pharmacyAllocation.coPay.title = App.localize('CopayX', valueText);
            vm.employee.pharmacyAllocation.coPay.text = App.localize(
              'PatientHasCopayFixedAmount',
              valueText
            );
          }
        }

        vm.employee.pharmacyAllocation.allowance = Math.min(
          vm.employee.pharmacyAllocation.allowance,
          999999.99
        );
      }
    }

    function refreshFulfillment() {
      if (vm.fulfillmentNumber) {
        vm.loading += 1;
        prescriptionSvc
          .getFulfillment({
            fulfillmentNumber: vm.fulfillmentNumber,
          })
          .success((data) => {
            vm.fulfillment = data;
          })
          .finally(() => {
            vm.loading -= 1;
          });
      } else {
        vm.fulfillment = null;
      }
    }

    function cleanUserEntry(entry) {
      return _.map(
        _.split(_.toLower(entry), ' '),
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      ).join(' ');
    }

    function tidyItems() {
      if (!vm.prescription) return;

      if (!vm.prescription.items) vm.prescription.items = [];

      // Calculate prescription

      let filledCnt = 0;
      let blankCnt = 0;
      for (let i = 0; i < vm.prescription.items.length; i += 1) {
        if (+vm.prescription.items[i].drugId) {
          filledCnt += 1;
        } else {
          blankCnt += 1;
        }
      }

      // Remove superfluos empty prescriptions

      while (blankCnt > 1) {
        for (let i = vm.prescription.items.length - 1; i >= 0; i -= 1) {
          if (!+vm.prescription.items[i].drugId) {
            vm.prescription.items.splice(i, 1);
            blankCnt -= 1;
          }
        }
      }

      // Ensure there is always a blank prescription line

      if (blankCnt < 1) vm.prescription.items.push({});

      // Update error message

      let errorMessage = '';

      if (!errorMessage && !filledCnt) {
        errorMessage = App.localize('KeyInOnePrescriptionProcedure');
      }

      if (!errorMessage && filledCnt) {
        for (let i = 0; i < vm.prescription.items.length; i += 1) {
          const p = vm.prescription.items[i];
          if (p.drugId) {
            if (!p.treatmentId) {
              errorMessage = App.localize('MissingTreatmentForX', p.name);
              break;
            }

            if (!p.quantity) {
              errorMessage = App.localize('MissingQuantityForX', p.name);
              break;
            }

            if (p.amount === null || !Number.isFinite(Number(p.amount)) || p.amount < 0) {
              errorMessage = App.localize('MissingOrInvalidAmountForX', p.name);
              break;
            }
          }
        }
      }

      vm.errorMessage = errorMessage;
    }

    function updateTreatments(treatmentId, index) {
      const isUncovered = vm.uncoveredTreatments.findIndex((x) => x.value === treatmentId);

      if (isUncovered !== -1) {
        vm.prescription.items[index].isCovered = false;
        vm.uncoveredWarning = true;
      } else {
        vm.prescription.items[index].isCovered = true;
        vm.uncoveredWarning = false;
      }
    }

    function save() {
      if (vm.saving) return;

      const input = {
        prescriptionId: vm.prescription.id,
        fulfillmentLocationId: vm.fulfillmentPrescription.fulfillmentLocationId,
        referenceNumber: vm.fulfillmentPrescription.referenceNumber,
        remarks: vm.fulfillmentPrescription.remarks,
        totalAmount: vm.getTotalAmount(),
        customerOrganizationAmount: vm.getOrganizationAmount(),
        customerAmount: vm.getCustomerAmount(),
        copayAmount: vm.getCoPayAmount(),
        shippingFee: vm.shippingFee,
        shippingIsCovered: vm.shippingIsCovered,
        items: _.chain(vm.prescription.items)
          .filter('drugId')
          .map((i) => {
            const item = _.extend({}, i);
            if (item.drugId < 0) {
              item.drugId = null;
            }

            const isUncovered = vm.uncoveredTreatments.findIndex(
              (x) => x.value === item.treatmentId
            );

            if (isUncovered !== -1) {
              item.isCovered = false;
            } else {
              item.isCovered = true;
            }

            return item;
          })
          .value(),
      };

      vm.saving += 1;
      prescriptionSvc
        .fulfillPrescription(input)
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          refreshPrescription();
          vm.onAuditHistoryRefreshEvent.handler();
          vm.onTicketHistoryRefreshEvent.handler();
          vm.onMedicineHistoryRefreshEvent.handler();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function getTotalItems() {
      let cnt = 0;
      if (vm.prescription) {
        _.forEach(vm.prescription.items, (d) => {
          if (d.drugId) cnt += 1;
        });
      }
      return cnt;
    }

    /* Region: Amounts calculation */

    function getAmount() {
      let totalAmount = 0;
      let totalUncoveredTreatmentsAmount = 0;
      let totalOrganizationAmount = 0;
      let totalCopayAmount = 0;

      // Add prescription's amount.

      if (vm.prescription) {
        _.forEach(vm.prescription.items, (d) => {
          // Total amount.

          if (d.drugId && d.amount) totalAmount += d.amount;

          // Uncovered amount for uncovered treatments.
          const isUncovered = vm.uncoveredTreatments.findIndex((i) => i.value === d.treatmentId);

          if (isUncovered !== -1) {
            if (d.drugId && d.amount) totalUncoveredTreatmentsAmount += d.amount;
          }
        });
      }

      // Apply shipping fee.

      if (vm.shippingFee > 0) {
        // Add shipping fee to total amount.

        totalAmount += vm.shippingFee;

        // Add shipping fee to uncovered if isCovered is false.

        if (!vm.shippingIsCovered) {
          totalUncoveredTreatmentsAmount += vm.shippingFee;
        }
      }

      // Covered amount calculation.

      totalOrganizationAmount = totalAmount - totalUncoveredTreatmentsAmount;

      // Copay calculation, if applicable.

      if (vm.employee.pharmacyAllocation?.coPay) {
        const pharmacyCoPay = vm.employee.pharmacyAllocation.coPay;
        totalCopayAmount = pharmacyCoPay.value;

        if (pharmacyCoPay.isPercentage) {
          if (totalOrganizationAmount <= vm.customerAllowance) {
            totalCopayAmount *= totalOrganizationAmount / 100;
          } else {
            totalCopayAmount *= vm.customerAllowance / 100;
          }
        }
        if (totalCopayAmount < 0) {
          totalCopayAmount = 0;
        } else if (totalCopayAmount > totalOrganizationAmount) {
          totalCopayAmount = totalOrganizationAmount;
        }
        totalOrganizationAmount -= totalCopayAmount;
      }
      if (totalOrganizationAmount > vm.customerAllowance) {
        totalOrganizationAmount = vm.customerAllowance;
      }

      return {
        totalAmount: App.roundAmount(totalAmount),
        totalOrganizationAmount: App.roundAmount(totalOrganizationAmount),
        totalCustomerAmount: App.roundAmount(totalAmount - totalOrganizationAmount),
        totalCopayAmount: App.roundAmount(totalCopayAmount),
      };
    }

    function getTotalAmount() {
      return vm.getAmount().totalAmount > 0 ? vm.getAmount().totalAmount : 0;
    }

    function getOrganizationAmount() {
      return vm.getAmount().totalOrganizationAmount > 0
        ? vm.getAmount().totalOrganizationAmount
        : 0;
    }

    function getCustomerAmount() {
      return vm.getAmount().totalCustomerAmount > 0 ? vm.getAmount().totalCustomerAmount : 0;
    }

    function getCoPayAmount() {
      return vm.getAmount().totalCopayAmount > 0 ? vm.getAmount().totalCopayAmount : 0;
    }

    /* EndRegion Amounts calculation */

    function lookupDrugs(userEntry) {
      const cleanedUserEntry = cleanUserEntry(userEntry || '');
      const filter = _.toLower(cleanedUserEntry);
      lastFilter = filter;

      if (filter) {
        const cachedDrugs = cachedResults[filter];

        if (cachedDrugs) {
          vm.filteredDrugs = postProcessDrugList(cachedDrugs, filter, cleanedUserEntry);
        } else {
          drugSearchSvc
            .searchDrugs({
              keyword: filter,
              countryCode: vm.prescription.countryCode,
            })
            .success((d) => {
              cachedResults[filter] = d.items;
              if (filter === lastFilter) {
                vm.filteredDrugs = postProcessDrugList(d.items, filter, cleanedUserEntry);
              }
            });
        }
      } else {
        vm.filteredDrugs = [];
      }
    }

    function postProcessDrugList(drugList, filter, cleanedUserEntry) {
      const clonedItems = drugList.slice(0);
      const idx = _.findIndex(
        clonedItems,
        (d) => _.toLower(_.trim(d.productName || '')) === filter
      );
      if (idx > 0) {
        const items = clonedItems.splice(idx, 1);
        clonedItems.splice(0, 0, items[0]);
      } else if (idx === -1) {
        clonedItems.splice(0, 0, {
          id: -1,
          productName: cleanedUserEntry,
        });
      }
      return clonedItems;
    }

    function updatePrescription(item) {
      const i = item;

      if (i.drugId) {
        const selectedDrug = _.find(vm.filteredDrugs, { id: i.drugId });
        if (selectedDrug) {
          if (
            selectedDrug.strength !== null &&
            Number.isFinite(Number(selectedDrug.strength)) &&
            selectedDrug.strengthUnit
          ) {
            i.strength = {
              value: selectedDrug.strength,
              unit: selectedDrug.strengthUnit,
            };
            i.strength.text = unitValueFilter(i.strength);
          } else {
            i.strength = null;
          }

          i.preferredQuantityUnit = selectedDrug.quantityUnit || '';
          i.preferredDosageUnit = selectedDrug.dosageUnit || '';
          i.name = selectedDrug.productName;
        }
      } else {
        i.name = null;
      }
      vm.tidyItems();
    }

    function editPharmacyRecord() {
      if (!vm.prescription || (vm.acknowledge && vm.acknowledge.isEdit)) return;

      vm.acknowledge.referenceNumber = vm.prescription.referenceNumber;
      vm.acknowledge.fulfillmentCount = vm.prescription.fulfillmentCount;
      vm.acknowledge.pharmacyRemarks = vm.prescription.pharmacyRemarks;
      vm.acknowledge.isEdit = true;
    }

    function showFulfillment(fulfillmentNumber) {
      vm.fulfillmentNumber = fulfillmentNumber;
      refreshFulfillment();

      $state.transitionTo(
        $state.current,
        {
          prescriptionNumber: vm.prescriptionNumber,
          fulfillmentNumber: vm.fulfillmentNumber,
        },
        { notify: false }
      );
    }

    function voidFulfillment() {
      let remarks = '';

      if (!vm.fulfillment) return;

      let canSubmit = false;
      const prompts = new PromptQueue();

      prompts.add(
        swal,
        {
          title: App.localize('VoidFulfillment'),
          text: App.localize('WhyVoidFulfillment'),
          type: 'input',
          showCancelButton: true,
          closeOnConfirm: false,
          confirmButtonColor: '#1ab394',
          inputPlaceholder: App.localize('PleaseExplain'),
        },
        (inputValue) => {
          if (inputValue === false) {
            return false;
          }

          if ($.trim(inputValue) === '') {
            swal.showInputError(App.localize('InvalidInput'));
            return false;
          }

          canSubmit = true;
          remarks = $.trim(inputValue);
          swal.close();
          return true;
        }
      );

      const msg = App.localize('VoidFulfillmentWarningMessage', vm.fulfillment.fulfillmentNumber);
      prompts.add(abp.message.confirm, msg, App.localize('AreYouSure'), (d) => {
        canSubmit = d;
        return canSubmit;
      });

      prompts.run(() => {
        if (canSubmit) voidCore(remarks);
      });
    }

    function voidCore(remarks) {
      vm.loading += 1;
      prescriptionSvc
        .voidFulfillment({
          id: vm.fulfillment.id,
          remarks,
        })
        .success(() => {
          vm.fulfillment = null;
          refreshPrescription();
          vm.onAuditHistoryRefreshEvent.handler();
          vm.onTicketHistoryRefreshEvent.handler();
          vm.onMedicineHistoryRefreshEvent.handler();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
