import c3 from 'c3';
import moment from 'moment';

(() => {
  angular.module('app').component('corporateBenefitGroupsCreateEditBenefitsComponent', {
    templateUrl: require('./benefits.component.html'),
    controller: BenefitsComponentController,
    controllerAs: 'vm',
    bindings: {
      currencyCode: '<',
      benefitGroup: '<',
      isReadOnly: '<',
    },
  });

  BenefitsComponentController.$inject = [];

  function BenefitsComponentController() {
    const vm = this;

    vm.applicationHasNYearBenefitsEnhancement = abp.setting.getBoolean(
      'Hms.Feature.NYearBenefitsEnhancement'
    );
    vm.applicationHasRenewalEnhancement = abp.setting.getBoolean(
      'Hms.Feature.BenefitRenewalEnhancement'
    );
    vm.hasBenefitGroupVisualizerSystem = abp.setting.getBoolean(
      'Hms.Feature.BenefitGroupVisualizerSystem'
    );
    vm.maxDate = moment().utc();
    vm.labels = [];
    vm.principalLimits = [];
    vm.dependentLimits = [];

    vm.$onInit = init;
    vm.removeModifierAndRefreshResults = removeModifierAndRefreshResults;
    vm.refreshResults = refreshResults;

    function init() {
      refreshModifierChart();
    }

    function refreshModifierChart() {
      if (!vm.hasBenefitGroupVisualizerSystem) return;

      updateModifierChartValues();

      const labels = ['x'].concat(vm.labels);
      const principalLimits = ['Principal'].concat(vm.principalLimits);
      const dependentLimits = ['Dependants'].concat(vm.dependentLimits);

      const chart = c3.generate({
        bindto: '#modifierChart',
        data: {
          x: 'x',
          columns: [labels, principalLimits, dependentLimits],
          type: 'bar',
        },
        bar: {
          width: {
            ratio: 0.1,
          },
        },
        axis: {
          x: {
            type: 'category',
          },
          y: {
            label: {
              text: `Amount (${vm.currencyCode})`,
              position: 'outer-middle',
            },
          },
        },
      });

      if (vm.labels.length <= 0) chart.destroy();
    }

    function formatLabelText(serviceTypes, cycle) {
      return `${serviceTypes} (${cycle})`;
    }

    function updateModifierChartValues() {
      const principalLabels = [];
      const dependentLabels = [];
      const principalDict = {};
      const dependentDict = {};
      const principalLimits = [];
      const dependentLimits = [];

      _.forEach(vm.benefitGroup.principalSummariesServiceType, (x) => {
        const budgetLimitItems = _.filter(x.items, (item) => item.type === 0);

        if (budgetLimitItems.length <= 0) return;

        const { cycle, value } = budgetLimitItems[0];
        const labelText = formatLabelText(x.key, cycle);

        principalLabels.push(labelText);
        principalDict[labelText] = value;
      });

      _.forEach(vm.benefitGroup.dependentSummariesServiceType, (x) => {
        const budgetLimitItems = _.filter(x.items, (item) => item.type === 0);

        if (budgetLimitItems.length <= 0) return;

        const { cycle, value } = budgetLimitItems[0];
        const labelText = formatLabelText(x.key, cycle);

        dependentLabels.push(labelText);
        dependentDict[labelText] = value;
      });

      const labels = _.union(principalLabels, dependentLabels);

      _.forEach(labels, (m) => {
        principalLimits.push(principalDict[m] ?? null);
        dependentLimits.push(dependentDict[m] ?? null);
      });

      vm.labels = labels;
      vm.principalLimits = principalLimits;
      vm.dependentLimits = dependentLimits;
    }

    function removeModifierAndRefreshResults(index) {
      vm.benefitGroup.removeModifier(index).then((res) => {
        if (res) refreshModifierChart();
      });
    }

    function refreshResults() {
      vm.benefitGroup.refreshResult();
      refreshModifierChart();
    }
  }
})();
