/* global zE */

(() => {
  angular
    .module('app')
    .controller('common.views.specialistClaims.viewClaim.index', ViewClaimController);

  ViewClaimController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$interval',
    '$filter',
    'abp.services.app.specialistClaim',
    'Hms.SpecialistClaims.SpecialistClaimStatus',
    'moment',
  ];

  function ViewClaimController(
    $scope,
    $state,
    $stateParams,
    $interval,
    $filter,
    specialistClaimSvc,
    enumSpecialistClaimStatus,
    moment
  ) {
    const vm = this;

    let isSilentLoading = 0;

    const topUpRequestLocalization = App.localize('TopUpRequest');
    const ticketAdjustmentLocalization = App.localize('ClaimAdjustment');

    vm.loading = 0;
    vm.saving = 0;
    vm.coPayLabel = App.localize('Copay');
    vm.resubmitFullClaim = 0;
    vm.uploadFormAbOnly = 0;
    vm.showPendingApprovalView = null;
    vm.topUpRequestMessage = '';
    vm.coveredAmount = 0;
    vm.collectAmount = 0;

    vm.claimNumber = $stateParams.claimNumber;
    vm.activeTab = $stateParams.tab ? $stateParams.tab : 1;
    vm.timeZoneId = $stateParams.timeZoneId;
    vm.claim = null;
    vm.isHost = App.isHost();
    vm.isInpatient = App.isInpatient();

    vm.enums = {
      specialistClaimStatus: enumSpecialistClaimStatus,
    };

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.permissions = {
      editClaim: vm.isHost
        ? abp.auth.isGranted('SpecialistClaims.Host.Edit')
        : abp.auth.isGranted('SpecialistClaims.Clinic.Edit'),
      approveClaim: abp.auth.isGranted('SpecialistClaims.Host.Edit'),
    };

    vm.getExceededAmount = getExceededAmount;
    vm.processClaim = processClaim;
    vm.getBillingDetailHistoryHeading = getBillingDetailHistoryHeading;

    init();

    function init() {
      getClaim(vm.claimNumber);
      getTimeline(vm.claimNumber);

      if ($stateParams.showZen && typeof zE !== 'undefined') {
        zE('webWidget', 'open');
      }

      let tickerInterval = $interval(silentLoad, 10000);
      $scope.$on('$destroy', () => {
        if (angular.isDefined(tickerInterval)) {
          $interval.cancel(tickerInterval);
          tickerInterval = undefined;
        }
      });
    }

    function silentLoad() {
      if (!vm.claim || vm.claim.status === 3 || !vm.claimNumber || vm.loading || isSilentLoading)
        return;

      isSilentLoading = 1;
      specialistClaimSvc
        .getClaimDetail(
          {
            id: vm.claimNumber,
          },
          {
            abpHandleError: false,
          }
        )
        .success((data) => {
          processGetClaimDetailOutput(data);
        })
        .finally(() => {
          isSilentLoading = 0;
        });
    }

    function processGetClaimDetailOutput(data) {
      vm.claim = data;

      if (vm.claim.guaranteeLetter.coPayValue) {
        let label = '';
        if (vm.claim.guaranteeLetter.isCoPayPercentage) {
          label = `${vm.claim.guaranteeLetter.coPayValue}%`;
        } else {
          label = $filter('currencyFormat')(vm.claim.guaranteeLetter.coPayValue, vm.currencyCode);
        }
        vm.coPayLabel = App.localize('CopayX', label);
      }

      vm.resubmitFullClaim =
        !vm.isHost &&
        vm.permissions.editClaim &&
        vm.claim.status === 1 &&
        !vm.claim.hasPatientTicket;

      vm.uploadFormAbOnly =
        !vm.isHost &&
        vm.permissions.editClaim &&
        vm.claim.status === 2 &&
        vm.claim.hasPatientTicket;

      vm.showPendingApprovalView =
        !vm.isHost &&
        !vm.claim.hasPatientTicket &&
        vm.claim.guaranteeLetter.hasTopUpRequest &&
        vm.claim.status === 0;

      if (vm.claim.guaranteeLetter.hasTopUpRequest) {
        if (
          vm.claim.guaranteeLetter.topUpRequestStatus === 0 ||
          vm.claim.guaranteeLetter.topUpRequestStatus === 2
        ) {
          vm.topUpRequestMessage = vm.claim.guaranteeLetter.topUpRejectionRemark;
        } else if (vm.claim.guaranteeLetter.topUpRequestStatus === 1) {
          vm.topUpRequestMessage = App.localize('Success');
        } else {
          vm.topUpRequestMessage = App.localize('PendingApproval');
        }
      }

      if (vm.isHost && vm.claim.scheduledApprovalDate) {
        const status =
          vm.claim.scheduledApprovalStatus === 2
            ? App.localize('PartialApproval')
            : App.localize('FullApproval');
        vm.scheduledApprovalTooltip = App.localize(
          'ScheduledApproval_Tooltip',
          status.toLowerCase(),
          moment(vm.claim.scheduledApprovalDate).format('L')
        );
      }

      vm.coveredAmount = vm.claim.guaranteeLetter.guaranteedAmount + vm.claim.coPayAmount;
      vm.collectAmount = Math.max(
        Math.max(vm.claim.totalClaimableAmount - vm.claim.guaranteeLetter.guaranteedAmount, 0),
        vm.claim.coPayAmount
      );
    }

    function getClaim() {
      vm.loading += 1;
      specialistClaimSvc
        .getClaimDetail({
          id: vm.claimNumber,
        })
        .success((data) => {
          processGetClaimDetailOutput(data);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getTimeline(claimNumber) {
      if (!claimNumber) return;

      vm.loading += 1;
      specialistClaimSvc
        .getClaimTimeline({
          id: claimNumber,
        })
        .success((data) => {
          vm.timelineItems = data.items;
          _.each(vm.timelineItems, (item) => {
            const d = item;
            switch (d.subject) {
              case App.localize('Submitted'):
                d.icon = 'fa fa-upload text-warning';
                break;
              case App.localize('PendingReview'):
                d.icon = 'fas fa-sync text-warning';
                break;
              case App.localize('PendingResubmission'):
                d.icon = 'fa fa-times-circle text-danger';
                break;
              case App.localize('PartialApproval'):
                d.icon = 'far fa-clipboard text-default';
                break;
              case App.localize('FullApproval'):
                d.icon = 'far fa-clipboard text-navy';
                break;
              case App.localize('UpdateClaim'):
                d.icon = 'fa fa-edit text-warning';
                break;
              case App.localize('ScheduleApproval'):
                d.icon = 'far fa-clock text-warning';
                break;
              case App.localize('CancelScheduledApproval'):
                d.icon = 'far fa-clock text-default';
                break;
              case App.localize('Adjustment'):
                d.icon = 'fas fa-users-cog text-warning';
                break;

              // no default
            }
          });
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getExceededAmount() {
      if (!vm.claim) return 0;
      const amt = vm.claim.totalClaimableAmount || 0;
      const coveredAmt = amt - vm.claim.coPayAmount;
      return Math.max(coveredAmt - vm.claim.guaranteeLetter.guaranteedAmount, 0);
    }

    function processClaim() {
      $state.go('processSpecialistClaim', {
        claimNumber: vm.claimNumber,
        timeZoneId: vm.timeZoneId
      });
    }

    function getBillingDetailHistoryHeading(billingDetail) {
      return `${
        billingDetail.isTicketAdjustment ? ticketAdjustmentLocalization : topUpRequestLocalization
      }: ${moment(billingDetail.creationTime).format('D MMMM YYYY, h:mm a')}`;
    }
  }
})();
