(function () {
    'use strict';

    angular
        .module('app')
        .controller('host.views.hositalizationExclusions',
            ['abp.services.app.hospitalizationExclusion', controller]);

    function controller(hospitalizationExclusionSvc) {
        function init() {
            getExclusions();
        }

        var vm = this;
        vm.serviceTypeExclusions = [];
        vm.loading = 0;

        function getExclusions() {
            vm.loading++;
            hospitalizationExclusionSvc.getExclusions()
                .success(function (data) {
                    vm.serviceTypeExclusions = data.items;
                }).finally(function () {
                    vm.loading--;
                });
        }

        init();
    }
})();
