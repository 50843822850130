(function () {
    var componentId = 'clinicPanelDashboardGrowthTable';
    var app = angular.module('app');

    function controller(commonLookupSvc) {
        var vm = this;

        vm.$onInit = onInit;
        vm.close = close;
        vm.loading = 0;

        vm.result = vm.resolve.result;
        vm.type = vm.resolve.type;
        vm.header = vm.resolve.header;

        function close() {
            vm.modalInstance.dismiss();
        }

        function onInit() {
            switch (vm.type) {
                case 'patientVisits':
                    vm.valueIndicator = App.localize('VisitIndicator');
                    break;
                case 'claimsCollected':
                case 'averageClaimPriceRange':
                    getTenantCurrencyCode();
                    break;
                case 'averageSubmissionTime':
                    vm.valueIndicator = App.localize('TimeIndicator');
                    break;
            }
        }

        function getTenantCurrencyCode() {
            vm.loading++;
            commonLookupSvc.getTenantCurrencyCode()
                .success(function (data) {
                    vm.valueIndicator = data;
                }).finally(function () {
                    vm.loading--;
                })
        }
    }

    app.component(componentId, {
        templateUrl: require('/App/clinic/views/panelDashboard/growthTable/growthTable.modal.html'),
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['abp.services.app.commonLookup', controller],
        controllerAs: 'vm'
    });
})();
