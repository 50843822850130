import swal from 'sweetalert';
import moment from 'moment';

(() => {
  angular.module('app').component('hostCorporatesCreateEditContainer', {
    templateUrl: require('./widget.html'),
    controller: CorporateEditController,
    controllerAs: 'vm',
    bindings: {},
  });

  CorporateEditController.$inject = [
    '$state',
    '$stateParams',
    '$scope',
    'abp.services.app.corporate',
    'abp.services.app.commonLookup',
    'abp.services.finance.commonLookup'
  ];

  function CorporateEditController(
    $state, 
    $stateParams, 
    $scope,
    corporateSvc, 
    commonLookupSvc,
    financeCommonLookupSvc
  ) {
    const vm = this;
    vm.isCreate = !$stateParams.corporateId;
    vm.loading = 0;
    vm.saving = 0;
    vm.isEditing = false;
    vm.eefEditData = null;
    vm.editIrbmMyInvoisSettingsWidget = null;

    const hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');
    if (!hasRegionalSystemSupport) {
      vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    }

    // Assign permissions

    vm.permissions = {
      advanceView: abp.auth.isGranted('Host.Corporates.Advance'),
      edit: abp.auth.isGranted('Host.Corporates.Edit'),
    };

    const widgets = [];

    vm.addWidget = addWidget;
    vm.saveWidget = saveWidget;
    vm.saveAll = saveAll;
    vm.toggleEdit = toggleEdit;
    vm.getCorporate = getCorporate;
    vm.cancel = cancel;
    vm.$onInit = init;

    function init() {
      getFinanceSettings();
      getCorporate($stateParams.corporateId);
      getAccountOwners();
    }

    // Register child widget to this container.
    // Expose to child widget to register themselves.

    function addWidget(widget) {
      if (widget) {
        widgets.push(widget);
      }
    }

    // Save widget.

    function saveWidget(widget) {
      if (vm.isCreate) return; // Prevent saving individual widget during creation.
      if (vm.saving || widget.saving) return;
      if (!widget.validateForm()) {
        abp.notify.error(App.localize('GeneralInvalidFormInputError'));
        return;
      }

      widget.saving += 1;
      save(vm.isCreate, widget.getPayload(), () => {
        widget.saving -= 1;
      });
    }

    // Save all changes on the form to corporate.

    function saveAll() {
      const error = false;

      if (vm.saving || vm.loading) return;

      const finalPayload = {};
      _.each(widgets, (w) => {
        w.validateForm(error);
        w.getPayload(finalPayload);
      });

      // Stop saving if any error occurs.

      if (error) {
        abp.notify.error(App.localize('GeneralInvalidFormInputError'));
        return;
      }

      vm.saving += 1;
      save(vm.isCreate, finalPayload, () => {
        vm.saving -= 1;
      });
    }

    function toggleEdit(flag) {
      vm.isEditing = flag;
      _.each(widgets, (w) => {
        w.isEditing = flag;
      });

      if (vm.isEditing) getCorporateForEdit($stateParams.corporateId);
    }

    function getCorporate() {
      getCorporateForEdit($stateParams.corporateId);
    }

    function getAccountOwners() {
      vm.loading += 1;
      commonLookupSvc
        .getAccountOwners()
        .success((data) => {
          vm.accountOwners = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getCorporateForEdit(corporateId) {
      vm.loading += 1;
      corporateSvc
        .getCorporateForEdit({
          id: corporateId,
        })
        .success((n) => {
          vm.corporateId = n.id;
          vm.properties = n.properties;
          vm.finance = n.finance;
          vm.agentAssignment = n.agentAssignment;
          vm.contact = n.contact;
          vm.features = n.features;
          vm.notifications = n.notifications;
          _.assign(vm.notifications, {
            hasSupportPlan: n.hasSupportPlan,
            hasPreEmployementModule: vm.features.hasPreEmploymentModule,
            hasSpecialistModule: vm.features.hasSpecialistModule,
          });
          vm.customServices = n.customServices;
          vm.pharmacies = n.pharmacies;
          vm.logoUrl = n.logoUrl;
          vm.myInvoisSettings = n.malaysiaTaxIdentifiers;

          // Set corporate startdate on datepicker.

          vm.properties.momentStartDate = moment.utc(n.properties.startDate);
          vm.properties.momentCommencementDate = moment.utc(n.properties.commencementDate);

          vm.eefEditData = {
            featureValues: _.map(n.featureValues.employeeFieldValues, (d) => {
              if (d.name) d.name = d.name.replace(/\./g, '_');
              return d;
            }),
          };

          if (vm.permissions.advanceView) {
            vm.finance.bypassCorporateLimitPanels = _.filter(
              _.map(vm.finance.bypassCorporateLimitPanelIds, (id) => {
                _.find(n.panels, { value: id });
              }),
              (p) => p !== null
            );
          }

          vm.modificationLog = n.modificationLog;

          // Get edit corporate details
          if (vm.corporateId) {
            const accountOwner = _.find(vm.accountOwners, ['id', vm.properties.accountOwnerId]);
            vm.currencyCode = accountOwner.currencyCode;
            vm.countryCode = accountOwner.countryCode;
            vm.paymentMode = vm.finance.paymentAccountNumberMode;
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // Create or update corporate with the given payload.
    // Callback should be used for handling vm.saving in child widget.

    function save(isCreate, payload, callback) {
      if (isCreate) {
        abp.message.confirm(
          App.localize('TimeZoneConfirmMessage'),
          App.localize('AreYouSure'),
          (d) => {
            if (d) {
              createCorporate(payload, callback);
            } else if (_.isFunction(callback)) {
              callback();
            };
          });
      } else {
        updateCorporate(payload, callback);
      }
    }

    // Create corporate with the given payload.

    function createCorporate(payload, callback) {
      _.extend(payload, {
        employeeFieldValues: _.map(vm.eefEditData.featureValues, (d) => ({
          name: d.name.replace(/_/g, '.'),
          value: d.value,
        })),
      });

      corporateSvc
        .createCorporate(payload)
        .success((data) => {
          abp.notify.info(App.localize('CorporateSettingsSuccessfullySaved'));

          if (data.password) {
            abp.message.success(App.localize('PasswordForUser', 'Admin'), data.password);
          }

          if (vm.countryCode === 'MY' && vm.editIrbmMyInvoisSettingsWidget != null) {
            setTaxIdentifiers(payload, data.identityServerTenantId, data.subsidiaryId, data.id);
          }
          
        })
        .finally(() => {
          if (_.isFunction(callback)) callback();
        });
    }

    // Update corporate with the given payload.

    function updateCorporate(payload, callback) {
      _.extend(payload, {
        id: vm.corporateId,
        employeeFieldValues: _.map(vm.eefEditData.featureValues, (d) => ({
          name: d.name.replace(/_/g, '.'),
          value: d.value,
        })),
      });

      corporateSvc
        .updateCorporate(payload)
        .success((data) => {
          if (data.activeSubscriptionCount) {
            swal({
              title: App.localize('CorporateDeactivatedSuccessfully'),
              type: 'success',
              text: App.localize(
                'CorporateHasXActiveSubscriptionNotificationMessage',
                data.activeSubscriptionCount
              ),
              showConfirmButton: true,
              allowEscapeKey: false,
            });
            App.swal.disableButtons(5);
          } else {
            abp.notify.info(App.localize('CorporateSettingsSuccessfullySaved'));
          }
          vm.toggleEdit(false);
        })
        .finally(() => {
          if (_.isFunction(callback)) callback();
        });
    }

    function setTaxIdentifiers(payload, identityServerTenantId, subsidiaryId, id) {
      vm.editIrbmMyInvoisSettingsWidget.getPayload(payload);

      const primarySubsidiaryTaxIdentifiers = payload.malaysiaTaxIdentifiers.subTenants[0];
      primarySubsidiaryTaxIdentifiers.tenantIdentityServiceId = identityServerTenantId;
      primarySubsidiaryTaxIdentifiers.subTenantId = subsidiaryId;

      vm.editIrbmMyInvoisSettingsWidget.validateForm();

      const watch = $scope.$watch('vm.editIrbmMyInvoisSettingsWidget.loading', () => {
        if (vm.editIrbmMyInvoisSettingsWidget.loading === 0) {
          $state.go('host.createEditCorporate', { corporateId: id });
          vm.toggleEdit(false);
          watch(); // deregister the watch
        }
      }, true);
    }

    // Cancel creation or edit.

    function toggleCancel(resetCorporate) {
      if (vm.isCreate) window.history.back();
      else {
        vm.toggleEdit(false);
        if (resetCorporate) getCorporate($stateParams.corporateId);
      }
    }

    function cancel() {
      const dirtyWidgets = _.filter(widgets, (w) => w.isDirty());
      if (!dirtyWidgets.length) toggleCancel(false);
      else {
        abp.message.confirm(
          App.localize('UnsavedChangesConfirmation'),
          App.localize('AreYouSure'),
          (d) => {
            if (d) {
              toggleCancel(true);
              _.each(dirtyWidgets, (w) => {
                w.setPristine();
              });
            }
          }
        );
      }
    }

    function getFinanceSettings() {
      vm.loading += 1;
      financeCommonLookupSvc
        .getCommonSettings()
        .success((data) => {
          vm.hasTaxIdentifierCollection = data.taxIdentifierCollection;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
