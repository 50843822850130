(() => {
  angular.module('app').controller('host.views.corporates.index', CorporatesIndexController);

  CorporatesIndexController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$uibModal',
    '$timeout',
    'abp.services.app.corporate',
    'abp.services.app.commonLookup',
    'lookupModal',
    'countries',
    'appConstants',
    'authentication',
    'moment',
  ];
  function CorporatesIndexController(
    $scope,
    $state,
    $stateParams,
    $modal,
    $timeout,
    corporateSvc,
    commonLookupSvc,
    lookupModal,
    countries,
    appConstants,
    authentication,
    moment
  ) {
    const vm = this;
    let lastRefreshIncludeStatistics = false;
    vm.loading = 0;
    vm.includeStatistics = lastRefreshIncludeStatistics;
    vm.filterText = $stateParams.filterText || '';
    vm.enqueuing = 0;
    vm.hasInsuranceModule = abp.setting.getBoolean('Hms.Feature.InsuranceModule');
    vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');
    vm.countryCode = null;
    vm.hasSingaporeInvoiceGeneration = abp.setting.getBoolean(
      'Hms.Feature.SingaporeInvoiceGeneration'
    );
    vm.hasRegionalReimbursementSupport = abp.setting.getBoolean(
      'Hms.Feature.RegionalReimbursementSupport'
    );
    vm.dataExportRecordName = 'CorporateListing';
    vm.generateButtonType = App.localize('ExportToExcel');

    // Assign permissions

    vm.permissions = {
      advanceView: abp.auth.isGranted('Host.Corporates.Advance'),
      create: abp.auth.isGranted('Host.Corporates.Create'),
      edit: abp.auth.isGranted('Host.Corporates.Edit'),
      delete: abp.auth.isGranted('Host.Corporates.Delete'),
      saleTransactions: abp.auth.isGranted('SaleTransactions'),
      patientTickets: abp.auth.isGranted('PatientTickets'),
      generateSaleTransactions: abp.auth.isGranted('Host.Corporates.GenerateSaleTransactions'),
      impersonation: abp.auth.isGranted('Host.Corporates.Impersonation'),
      viewSubsidiaries: abp.auth.isGranted('Host.Corporates.Subsidiaries'),
      manageSupportPlans: abp.auth.isGranted('Host.Corporates.ManageSupportPlans'),
      confirmAgreement: abp.auth.isGranted('Host.Agreements.ConfirmAgreement'),
      resetAdminPassword: abp.auth.isGranted('Host.Corporates.ResetAdminPassword'),
    };
    vm.permissions.resetAdminPassword = vm.permissions.resetAdminPassword || vm.permissions.edit;

    const zendeskSubdomain = abp.setting.get('Hms.Integration.Zendesk.Subdomain');
    vm.hasZendeskIntegration = zendeskSubdomain && zendeskSubdomain !== '';

    // Define grid options

    const viewOptions = {
      skipCount: $stateParams.skipCount || 0,
      maxResultCount: $stateParams.maxResultCount || appConstants.grid.defaultPageSize,
      sorting: $stateParams.sorting,
    };

    vm.corporateGridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSizes,
      paginationPageSize: appConstants.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      enableRowSelection: true,
      enableSelectAll: false,
      enableFullRowSelect: true,
      enableRowHeaderSelection: false,
      multiSelect: false,
      rowTemplate: `<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ 'ui-grid-row-header-cell': col.isRowHeader, 'text-muted': !row.entity.isActive }"  ui-grid-cell></div>`,
      columnDefs: [
        {
          name: App.localize('Actions'),
          displayName: 'Actions',
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
          allowCellFocus: false,
        },
        {
          displayName: App.localize('Warning'),
          enableSorting: false,
          field: 'warnings',
          width: 25,
          cellTemplate: 'warningsTemplate',
        },
        {
          displayName: App.localize('Country'),
          field: 'countryCode',
          width: 30,
          visible: vm.hasRegionalSystemSupport,
          cellTemplate: 'countryTemplate',
        },
        {
          displayName: App.localize('CorporateCode'),
          field: 'tenancyName',
          minWidth: 120,
        },
        {
          displayName: App.localize('CorporateName'),
          field: 'name',
          minWidth: 120,
          cellTemplate: 'nameTemplate',
        },
        {
          displayName: App.localize('Nickname'),
          field: 'nickname',
          minWidth: 80,
        },
        {
          name: App.localize('Products'),
          field: 'products',
          enableSorting: false,
          width: 180,
          cellTemplate: 'productsTemplate',
        },
        {
          displayName: App.localize('Principal'),
          field: 'totalPrincipal',
          enableSorting: false,
          minWidth: 65,
        },
        {
          displayName: App.localize('Dependent'),
          field: 'totalDependent',
          enableSorting: false,
          minWidth: 50,
        },
        {
          displayName: App.localize('MobileApp'),
          name: App.localize('MobileApp'),
          field: 'mobileAppRegistrationCode',
          visible: vm.permissions.advanceView,
          enableSorting: false,
          minWidth: 60,
        },
        {
          displayName: App.localize('IsActive'),
          field: 'isActive',
          cellTemplate: 'activeTemplate',
          minWidth: 65,
        },
        {
          displayName: App.localize('StartDate'),
          field: 'startDate',
          cellTemplate: 'startDateTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('OpenInvoiceBalance'),
          field: 'creditFacility.outstandingBilled.invoice',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          visible: vm.includeStatistics,
          visibleEvaluator() {
            return vm.includeStatistics;
          },
          enableSorting: false,
          minWidth: 100,
        },
        {
          displayName: App.localize('OpenCreditMemoBalance'),
          field: 'creditFacility.outstandingBilled.creditMemo',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          visible: vm.includeStatistics,
          visibleEvaluator() {
            return vm.includeStatistics;
          },
          enableSorting: false,
          minWidth: 100,
        },
        {
          displayName: App.localize('OpenExcessPaymentBalance'),
          field: 'creditFacility.outstandingBilled.excessPayment',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          visible: vm.includeStatistics && vm.permissions.advanceView,
          visibleEvaluator() {
            return vm.includeStatistics;
          },
          enableSorting: false,
          minWidth: 100,
        },
        {
          displayName: App.localize('UnbilledAmount'),
          field: 'creditFacility.unbilledAmount',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          visible: vm.includeStatistics && vm.permissions.advanceView,
          visibleEvaluator() {
            return vm.includeStatistics;
          },
          enableSorting: false,
          minWidth: 100,
        },
        {
          displayName: App.localize('AvailableBalance'),
          field: 'creditFacility.availableCredit',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          visible: vm.includeStatistics && vm.permissions.advanceView,
          visibleEvaluator() {
            return vm.includeStatistics;
          },
          enableSorting: false,
          minWidth: 100,
        },
        {
          displayName: App.localize('RecommendedLimit'),
          field: 'statistic.recommendedEscrowAmount',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          cellTemplate: 'recommendedEscrowTemplate',
          visible: vm.includeStatistics && vm.permissions.advanceView,
          visibleEvaluator() {
            return vm.includeStatistics;
          },
          enableSorting: false,
          minWidth: 100,
        },
        {
          displayName: App.localize('EscrowAmount'),
          field: 'escrowAmount',
          minWidth: 100,
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          visible: vm.permissions.advanceView,
        },
        {
          displayName: App.localize('CorporateLimit'),
          field: 'corporateLimit',
          minWidth: 100,
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          visible: vm.permissions.advanceView,
        },
        {
          displayName: App.localize('BlanketConsented'),
          field: 'isBlanketConsented',
          cellTemplate: 'blanketConsentedTemplate',
          minWidth: 65,
        },
        {
          displayName: App.localize('ConsentedEmployees'),
          field: 'totalConsentedEmployee',
          minWidth: 100,
          cellTemplate: 'consentedEmployeeTemplate',
        },
        {
          displayName: App.localize('CustomerSuccessManager'),
          field: 'customerSuccessManagerName',
          enableSorting: false,
          minWidth: 100,
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          let sorting = '';
          for (let i = 0; i < callback.length; i += 1) {
            if (i) sorting += ', ';
            sorting += `${callback[i].field} ${callback[i].sort.direction}`;
          }
          viewOptions.sorting = sorting;
          vm.getCorporates();
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(viewOptions, vm.getCorporates)
        );
        gridApi.selection.on.rowSelectionChanged($scope, (row) => {
          if (vm.permissions.viewSubsidiaries) {
            if (row.isSelected) {
              $state.go('host.corporates.subsidiaries', {
                id: row.entity.id,
                tenancyName: row.entity.tenancyName,
                virtualAccountGenerationMode: row.entity.virtualAccountGenerationMode,
              });
              vm.gridSelectedId = row.entity.id;
            } else {
              $state.go('host.corporates');
              vm.gridSelectedId = null;
            }
          }
        });
      },
      data: [],
    };
    vm.gridSelectedId = parseInt($stateParams.id, 10) || null;

    vm.getCorporates = getCorporates;
    vm.updateLogo = updateLogo;
    vm.deleteCorporate = deleteCorporate;
    vm.impersonate = impersonate;
    vm.generateStatement = generateStatement;
    vm.generateSaleTransactions = generateSaleTransactions;
    vm.generateNprTransactions = generateNprTransactions;
    vm.initializeZendeskOrganization = initializeZendeskOrganization;
    vm.exportToExcel = exportToExcel;
    vm.resetAdminPassword = resetAdminPassword;
    vm.unlockAdminUser = unlockAdminUser;
    vm.isAllowedForInvoiceGeneration = isAllowedForInvoiceGeneration;
    vm.isAllowedForReimbursementInvoiceGeneration = isAllowedForReimbursementInvoiceGeneration;
    vm.getDateString = getDateString;

    init();

    // Define functions

    function init() {
      $timeout(() => {
        vm.corporateGridOptions.paginationCurrentPage = Math.floor(
          viewOptions.skipCount / viewOptions.maxResultCount + 1
        );
        if (vm.hasRegionalSystemSupport) getEligibleCountries();
      });
    }

    function getCorporates() {
      registerStateParams();
      vm.loading += 1;
      const refreshColumnVisibility = lastRefreshIncludeStatistics !== vm.includeStatistics;

      corporateSvc
        .getCorporates({
          skipCount: viewOptions.skipCount,
          maxResultCount: viewOptions.maxResultCount,
          sorting: viewOptions.sorting,
          filter: vm.filterText,
          includeStatistics: vm.includeStatistics,
          countryCode: vm.countryCode,
        })
        .success((data) => {
          vm.corporateGridOptions.totalItems = data.totalCount;
          vm.corporateGridOptions.data = data.items;

          _.forEach(data.items, (d) => {
            const newD = d;
            if (d.creditFacility) {
              newD.creditFacility.unbilledAmount = Math.abs(d.creditFacility.unbilledAmount);
              if (d.creditFacility.outstandingBilled) {
                newD.creditFacility.outstandingBilled.invoice = Math.abs(
                  d.creditFacility.outstandingBilled.invoice
                );
              }
            }

            const country = _.find(countries, ['code', d.countryCode]);
            newD.currencyCode = country.currencyCode;
          });

          processProductService();

          let i;
          if (refreshColumnVisibility) {
            lastRefreshIncludeStatistics = vm.includeStatistics;
            for (i = 0; i < $scope.gridApi.grid.columns.length; i += 1) {
              const { colDef } = $scope.gridApi.grid.columns[i];
              if (colDef.visibleEvaluator) {
                colDef.visible = colDef.visibleEvaluator();
              }
            }
            $scope.gridApi.grid.refresh();
          }

          $scope.gridApi.grid.modifyRows(vm.corporateGridOptions.data);
          if (vm.gridSelectedId) {
            for (i = vm.corporateGridOptions.data.length - 1; i >= 0; i -= 1) {
              if (vm.corporateGridOptions.data[i].id === vm.gridSelectedId) {
                $scope.gridApi.selection.selectRow(vm.corporateGridOptions.data[i]);
                break;
              }
            }
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function processProductService() {
      const localizeValues = [];
      function localize(key) {
        let text = localizeValues[key];
        if (!text) {
          text = App.localize(key);
          localizeValues[key] = App.localize(key);
        }
        return text;
      }

      _.each(vm.corporateGridOptions.data, (d) => {
        const newD = d;
        newD.productServices = _.filter(d.productServices, (svc) => svc.usageMode !== 'NotUsed');
        _.each(d.productServices, (svc) => {
          const newSvc = svc;
          let icon = '';
          switch (svc.name) {
            case 'GP':
              icon = 'fas fa-stethoscope';
              break;
            case 'Dental':
              icon = 'mdi mdi-tooth';
              break;
            case 'Optical':
              icon = 'mdi mdi-sunglasses';
              break;
            case 'Specialist':
              icon = 'fas fa-user-md';
              break;
            case 'Pharmacy':
              icon = 'far fa-capsules';
              break;
            case 'CustomTreatment':
              icon = 'far fa-copy';
              break;
            case 'PreEmployment':
              icon = 'far fa-file-chart-pie';
              break;
            case 'Hospitalization':
              icon = 'fas fa-bed';
              break;
            case 'HealthScreening':
              icon = 'fas fa-heartbeat';
              break;
            case 'Maternity':
              icon = 'fas fa-female';
              break;
            case 'MobileApp':
              icon = 'fas fa-mobile-alt';
              break;
            default:
              break;
          }
          newSvc.icon = icon;

          let color = 'text-default';
          switch (svc.usageMode) {
            case 'Active':
              color = 'text-navy';
              break;
            case 'HasMobileNpr':
              color = 'text-success';
              break;
            default:
              break;
          }
          newSvc.color = color;

          newSvc.usageModeDescription = `${localize(newSvc.name)}:
                              ${localize(`ProductServiceUsageMode_${newSvc.usageMode}`)}`;
        });
      });
    }

    function registerStateParams() {
      $state.transitionTo(
        $state.current,
        {
          skipCount: viewOptions.skipCount,
          maxResultCount: viewOptions.maxResultCount,
          sorting: viewOptions.sorting,
          filterText: vm.filterText,
          countryCode: vm.countryCode,
          id: $stateParams.id,
        },
        {
          notify: false,
        }
      );
    }

    function updateLogo(corporate) {
      $modal
        .open({
          templateUrl: require('./updateLogoModal.html'),
          controller: 'host.views.corporates.updateLogoModal as vm',
          backdrop: 'static',
          resolve: {
            corporateId() {
              return corporate.id;
            },
            subsidiaryId() {
              return null;
            },
          },
        })
        .result.then(() => {
          vm.getCorporates();
        });
    }

    function deleteCorporate(corporate) {
      abp.message.confirm(
        App.localize('CorporateDeleteWarningMessage', corporate.tenancyName),
        App.localize('AreYouSure'),
        (result) => {
          if (result) {
            corporateSvc
              .deleteCorporate({
                id: corporate.id,
              })
              .success(() => {
                vm.getCorporates();
                abp.notify.success(App.localize('SuccessfullyDeleted'));
              });
          }
        }
      );
    }

    function impersonate(corporate) {
      lookupModal.open({
        title: App.localize('SelectAUser'),
        serviceMethod: commonLookupSvc.findUsersWithIdentityServerId,
        extraFilters: {
          tenantId: corporate.id,
        },
        callback(user) {
          authentication.impersonate(user.value, user.targetTenantId);
        },
      });
    }

    function generateStatement(corporate) {
      const modal = $modal.open({
        templateUrl: require('../../../common/views/saleTransactions/createStatementModal.html'),
        controller: 'common.views.saleTransactions.createStatementModal as vm',
        backdrop: 'static',
        resolve: {
          tenantId() {
            return corporate.id;
          },
          subsidiaryId: null,
        },
      });
      modal.result.then((input) => {
        $state.go('statement', input);
      });
    }

    function generateSaleTransactions(corporate) {
      abp.message.confirm(
        App.localize('GenerateSaleTransactionsWarningMessage', corporate.tenancyName),
        App.localize('AreYouSure'),
        (result) => {
          if (result) {
            corporateSvc
              .generateSaleTransactions({
                id: corporate.id,
              })
              .success(() => {
                vm.getCorporates();
                abp.notify.success(App.localize('SaleTransactionsGenerated'));
              });
          }
        }
      );
    }

    function generateNprTransactions(corporate) {
      abp.message.confirm(
        App.localize('GenerateNprTransactionsWarningMessage', corporate.tenancyName),
        App.localize('AreYouSure'),
        (result) => {
          if (result) {
            corporateSvc
              .generateNprTransactions({
                id: corporate.id,
              })
              .success(() => {
                vm.getCorporates();
                abp.notify.success(App.localize('SaleTransactionsGenerated'));
              });
          }
        }
      );
    }

    function initializeZendeskOrganization(corporate) {
      abp.message.confirm(
        App.localize('InitializeZendeskOrganizationWarningMessage', corporate.tenancyName),
        App.localize('AreYouSure'),
        (result) => {
          if (result) {
            corporateSvc
              .initializeZendeskOrganization({
                id: corporate.id,
              })
              .success(() => {
                vm.getCorporates();
                abp.notify.success(App.localize('InitializeZendeskOrganizationRequestSent'));
              });
          }
        }
      );
    }

    function exportToExcel() {
      vm.enqueuing += 1;
      corporateSvc
        .enqueueGenerateCorporatesExcelJob({
          filter: vm.filterText,
          includeStatistics: vm.includeStatistics,
          countryCode: vm.countryCode,
        })
        .success(() => {
          vm.refresh();
        })
        .finally(() => {
          vm.enqueuing -= 1;
        });
    }

    function resetAdminPassword(organization) {
      $modal.open({
        templateUrl: require('./resetAdminPassword/resetAdminPassword.modal.html'),
        controller: 'host.views.corporates.resetAdminPasswordModel as vm',
        backdrop: 'static',
        resolve: {
          tenancyName() {
            return organization.tenancyName;
          },
          organizationId() {
            return organization.id;
          },
        },
      });
    }

    function unlockAdminUser(organization) {
      corporateSvc
        .unlockAdminUser({
          id: organization.id,
        })
        .success(() => {
          abp.notify.success(App.localize('UserSuccessfullyUnlocked'));
        });
    }

    function isAllowedForInvoiceGeneration(organization) {
      return vm.hasSingaporeInvoiceGeneration || organization.countryCode !== 'SG';
    }

    function isAllowedForReimbursementInvoiceGeneration(organization) {
      return (
        (vm.hasSingaporeInvoiceGeneration && vm.hasRegionalReimbursementSupport) ||
        organization.countryCode !== 'SG'
      );
    }

    function getEligibleCountries() {
      vm.loading = +1;
      commonLookupSvc
        .getEligibleCountries()
        .success((data) => {
          vm.countries = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getDateString(date) {
      return App.getDateString(moment.utc(date));
    }
  }
})();
