(function () {
    angular.module('app').component('hostInpatientClaimsRequestDetailsComponent', {
        templateUrl: require('/App/host/views/inpatientClaims/requestDetails/requestDetails.component.html'),
        controller: ['abp.services.app.hostServiceRequest', controller],
        controllerAs: 'vm',
        bindings: {
            isClaim: '<',
            guaranteeLetterNumber: '<',
            request: '<',
            onEdit: '&',
            onSave: '&',
            isFinalBillTallied: '<',
            requestAmount: '=',
            currencyCode: '<'
        }
    });

    function controller(hostServiceRequestSvc) {
        var vm = this;

        vm.$onChanges = onChanges;
        vm.getSpecialistsSurgeons = getSpecialistsSurgeons;
        vm.lookupDoctor = lookupDoctor;
        vm.edit = edit;
        vm.save = save;
        vm.cancel = cancel;
        vm.getDifference = getDifference;

        vm.isEdit = !!vm.isClaim;
        vm.loading = 0;
        vm.savedTrigger = 0;
        vm.diagnosis = null;
        vm.filteredDoctors = [];
        vm.hasState = false;
        vm.isFinalBillTallied = true;

        var preEditRequest = null;
        var preEditDiagnosis = null;
        var preEditDocuments = null;
        var limitSize = 100;
        var doctors = [];

        function onChanges(changes) {
            if (changes.request && changes.request.currentValue) {
                if (vm.request.requestType === 'Adjustment') {
                    doctors = vm.request.availableAddOnDoctors;
                }
            }
        }

        function getDifference() {
            if (vm.request) {
                vm.isNegativeValue = false;
                vm.finalBillAmountDifference = vm.request.adjustedFinalBillAmount - vm.request.initialGuaranteedAmount;

                if (vm.finalBillAmountDifference < 0) {
                    vm.isNegativeValue = true;
                    vm.finalBillAmountDifference = Math.abs(vm.finalBillAmountDifference);
                }
                return vm.finalBillAmountDifference;
            }
            return 0;
        }

        function getSpecialistsSurgeons() {
            if (vm.request && vm.request.selectedDoctorIds) {
                var selectedDoctorsName = _.map(vm.request.selectedDoctorIds, function (i) {
                    var selectedDoctor = _.find(doctors, function (d) {
                        return d.id === i;
                    });
                    return selectedDoctor.name;
                });

                return selectedDoctorsName.join(', ');
            }
        }

        function edit() {
            backupInitialStates();
            vm.isEdit = true;
            vm.onEdit({ isEdit: true });
        }

        function save() {
            if (vm.request.adjustedFinalBillAmount > 0) {
                vm.loading++;
                hostServiceRequestSvc.updateAdjustmentRequestDetails(vm.request)
                    .success(function () {
                        abp.notify.info(App.localize("SuccessfullySaved"));
                        vm.isEdit = false;
                        vm.onEdit({ isEdit: false });
                        vm.savedTrigger++;
                        vm.onSave();
                    }).finally(function () {
                        vm.loading--;
                    });
            }
        }

        function cancel() {
            restoreInitialStates();
            vm.isEdit = false;
            vm.onEdit({ isEdit: false });
        }

        function backupInitialStates() {
            preEditRequest = angular.copy(vm.request);
            preEditDiagnosis = angular.copy(vm.diagnosis);
            preEditDocuments = angular.copy(vm.documents);
        }

        function restoreInitialStates() {
            vm.request = preEditRequest;
            vm.diagnosis = preEditDiagnosis;
            vm.documents = preEditDocuments;
        }

        function lookupDoctor(filter) {
            var keyword = _.trim(filter || '');
            if (keyword) {
                var filteredDoctors = [];

                var regexes = _.chain(keyword.split(' '))
                    .map(function (k) {
                        return _.trim(k.toUpperCase());
                    })
                    .filter(function (k) {
                        return k;
                    })
                    .uniq()
                    .map(function (k) {
                        _.escapeRegExp(k);
                        return {
                            token: k,
                            len: k.length,
                            pattern: new RegExp('\\b' + k, 'i')
                        };
                    })
                    .value();

                _.forEach(doctors, function (doctor) {
                    var score = 0;
                    _.forEach(regexes, function (r) {
                        if (r.pattern.test(doctor.name)) {
                            score -= r.len;
                        }
                    });

                    if (score) {
                        filteredDoctors.push({
                            score: score,
                            item: doctor
                        });
                    }
                });

                vm.filteredDoctors = _.chain(filteredDoctors)
                    .sortBy(['score'])
                    .take(30)
                    .map(function (k) {
                        return k.item;
                    })
                    .uniqBy(function (s) {
                        return s.name;
                    })
                    .value();
            } else {
                vm.filteredDoctors = _.take(doctors, limitSize);
            }
        };

        vm.updateFinalBillAmount = function () {
            if (vm.request.finalBillAmount)
                vm.requestAmount = vm.request.finalBillAmount
            else
                vm.requestAmount = 0
        }

        vm.updateAdjustedFinalBillAmount = function () {
            if (vm.request.adjustedFinalBillAmount)
                vm.requestAmount = vm.request.adjustedFinalBillAmount
            else
                vm.requestAmount = 0
        }
    }
})();
