(function () {
    var componentId = 'hostOperationsDashboardDuplicateTicketsComponent';
    var app = angular.module('app');

    function controller($uibModal, operationsDashboardSvc) {
        function init() {
            vm.refresh();
        }

        var vm = this;
        vm.widgetName = componentId;
        vm.loading = 0;
        vm.orderByCount = false;
        vm.totalCount = 0;
        vm.countryCode = null;

        vm.$onInit = function () {
            vm.onAddWidget({ widget: this });
        };

        function setTotalCount() {
            var item = _.find(vm.widgets, function (data) {
                return data.id === componentId;
            });
            item.totalCount = vm.totalCount;
            item.loading = false;
        }

        function getDuplicateTickets(skipCount) {
            var input = {
                skipCount: skipCount,
                maxResultCount: app.consts.maxResultCount,
                countryCode: vm.countryCode
            };

            vm.loading++;
            operationsDashboardSvc.getDuplicateTickets(input)
                .success(function (data) {
                    vm.totalCount = data.totalCount;
                    vm.data = vm.data.concat(data.items);
                    vm.dataOrderByLastUpdate = _.orderBy(vm.data, function (d) { return d.lastCreationTime; }, 'desc');
                    vm.toggleSorting(vm.orderByCount);
                    setTotalCount();
                }).finally(function () {
                    vm.loading--;
                });
        }

        vm.refresh = function () {
            vm.data = [];
            vm.showAll = false;
            getDuplicateTickets(0);
        };

        vm.toggleShowData = function () {
            vm.showAll = !vm.showAll;
            showData();
        };

        function showData() {
            vm.displayRecords = vm.showAll ? vm.records : vm.records.slice(0, 5);
        }

        vm.showDetail = function (record) {
            $uibModal.open({
                component: 'hostOperationsDashboardDuplicateTicketsDetail',
                backdrop: 'static',
                resolve: {
                    clinicLocation: function () {
                        return {
                            name: record.clinicLocationName,
                            clinicCode: record.clinicCode,
                            contactNumber: record.clinicLocationContactNumber,
                            patient: record.patientName,
                            patientNationalId: record.patientNationalId,
                            tickets: record.duplicateTickets
                        };
                    }
                }
            });
        };

        vm.toggleSorting = function (orderByCount) {
            vm.orderByCount = orderByCount;
            vm.records = vm.orderByCount ? vm.data : vm.dataOrderByLastUpdate;
            showData();
        };

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/operationsDashboard/duplicateTickets/duplicateTickets.component.html'),
        controller: ['$uibModal', 'abp.services.app.operationsDashboard', controller],
        controllerAs: 'vm',
        bindings: {
            widgets: '<',
            onAddWidget: '&',
            countryCode: '=?'
        }
    });
})();
