import Cropper from 'cropperjs';

(function () {
    var controllerId = 'host.views.insurers.updateLogoModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'abp.services.app.insurer', 'insurerId',
        function ($scope, $uibModalInstance, insurerSvc, insurerId) {
            var cropper = null;
            var vm = this;
            vm.saving = false;
            vm.uploadedImage = null;

            function initCropper(url) {
                const img = document.getElementById('InsurerLogoResize');
                if (!cropper) {
                    img.src = url;
                    cropper = new Cropper(img, {
                        zoomable: false
                    });
                } else {
                    cropper.replace(url);
                }
            }

            $scope.$watch(function () { return vm.uploadedImage; }, function () {
                if (!vm.uploadedImage) return;
                initCropper(vm.uploadedImage);
            });

            vm.save = function () {
                if (vm.saving || !cropper || !vm.uploadedImage) return;

                var dataUrl = cropper.getCroppedCanvas({
                    maxWidth: 4096,
                    maxHeight: 4096,
                    imageSmoothingEnabled: false
                }).toDataURL();

                var inp = $.extend({ insurerId: insurerId }, {
                    dataUrl: dataUrl
                });

                vm.saving = true;
                insurerSvc.updateLogo(inp).then(function () {
                    abp.notify.info(App.localize("SuccessfullySaved"));
                    $uibModalInstance.close();
                }).finally(function () {
                    vm.saving = false;
                });
            };
            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };
        }
    ]);
})();
