(function () {
    var controllerId = 'host.views.corporates.createOrEditSubsidiaryModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'abp.services.app.subsidiary', 'abp.services.app.commonLookup', 'subsidiaryId', 'corporateId', 'Hms.AccountOwners.PaymentAccountNumberMode','Hms.MultiTenancy.Tenant', 'Abp.Configuration.Setting',
        function ($scope, $uibModalInstance, subsidiarySvc, commonLookupSvc, subsidiaryId, corporateId, enumPaymentAccountNumberMode, constsTenant, constsAbpConfig) {
            function init() {
                lookupIndustries();
                vm.isCreate = !subsidiaryId;
                vm.loading++;
                vm.enableFinance = !vm.isCreate && vm.permissions.finance;
                subsidiarySvc.getSubsidiaryForEdit({
                    id: subsidiaryId,
                    corporateId: corporateId
                }).success(function (data) {
                    vm.subsidiary = data;
                    // if payment mode is VAN generation
                    if (vm.subsidiary.paymentAccountNumberMode == 2) {
                        if (!vm.isCreate && !_.isNil(data.virtualAccountNumber)) {
                            vm.rollingNumber = data.virtualAccountNumber.substr(5, 16);
                        }
                        prepareAccountNumbers(data.subsidiariesPayerAccounts);
                    }
                }).finally(function () {
                    vm.loading--;
                });
            }

            function lookupIndustries() {
                vm.loading++;
                commonLookupSvc.getIndustries().success(function (data) {
                    vm.industries = data.items;
                }).finally(function () {
                    vm.loading--;
                });
            }

            var vm = this;
            vm.constsTenant = constsTenant;
            vm.constsSetting = constsAbpConfig;
            vm.loading = 0;
            vm.saving = 0;
            vm.subsidiary = null;
            vm.isEditing = false;
            vm.permissions = {
                manage: abp.auth.isGranted('Host.Corporates.Subsidiaries.Manage'),
                finance: abp.auth.isGranted('Host.Corporates.Subsidiaries.Finance'),
            };
            vm.generateNewAcButtonClicked = false;
            vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');

            vm.enums = {};
            vm.enums.paymentAccountNumberMode = enumPaymentAccountNumberMode;

            vm.save = function () {
                vm.saving++;
                vm.subsidiary.virtualAccountNumber = vm.hmsAcNumber + vm.rollingNumber;
                subsidiarySvc.createOrUpdateSubsidiary(vm.subsidiary).success(function (data) {
                    if (data) {
                        swal({
                            title: App.localize('SubsidiaryDeactivatedSuccessfully'),
                            type: 'success',
                            text: App.localize('SubsidiaryHasActiveSubscriptionNotificationMessage'),
                            showConfirmButton: true,
                            allowEscapeKey: false
                        });
                        App.swal.disableButtons(5);
                    } else {
                        abp.notify.info(App.localize('SuccessfullySaved'));
                    }

                    $uibModalInstance.close();
                }).finally(function () {
                    vm.saving--;
                });
            };

            vm.toggleEdit = function (flag) {
                vm.isEditing = flag;
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            vm.copyToClipboard = function () {
                const element = document.createElement('input');
                element.value = vm.hmsAcNumber + vm.rollingNumber;
                document.body.appendChild(element);
                element.select();
                document.execCommand('copy');
                abp.notify.success(App.localize("VirtualAccountNumberCopied"))
            }

            vm.generateNewAcConfirmation = function () {
                var confirmMsg = App.localize('GeneratingNewVirtualAccountNumberInfo') + "\n" +
                    App.localize('Corporate') + ": " + vm.subsidiary.tenantName + "\n" +
                    App.localize('Subsidiary') + ": " + vm.subsidiary.name;
                abp.message.confirm(confirmMsg,
                    App.localize('ConfirmedTitle'),
                    function (d) {
                        if (d) {
                            vm.generateNewAcButtonClicked = true;
                            subsidiarySvc.generateNewVirtualAccountNumber()
                                .success(function (data) {
                                    vm.hmsAcNumber = data.slice(0, 5);
                                    var newRollingNumber = data.slice(5);

                                    if (!vm.rollingNumbers.includes(newRollingNumber)) {
                                        vm.rollingNumbers.push(data.slice(5));
                                    }

                                    vm.rollingNumber = newRollingNumber;
                                });
                        }
                    });
            }

            init();

            function prepareAccountNumbers(accountNumbers) {
                var hmsAccNumbers = [];
                var rollingNumbers = [];

                _.each(accountNumbers, function (d) {
                    if (!hmsAccNumbers.includes(d.slice(0, 5))) {
                        hmsAccNumbers.push(d.slice(0, 5))
                    }

                    if (!rollingNumbers.includes(d.slice(5))) {
                        rollingNumbers.push(d.slice(5))
                    }
                });

                vm.rollingNumbers = rollingNumbers;
                vm.hmsAcNumber = _.head(hmsAccNumbers);
            }
        }
    ]);
})();
