(function () {
    var componentId = 'hostDoraemonCommonInpatientTicketsDetailOthersComponent';
    angular
        .module('app')
        .component(componentId, {
            templateUrl: require('/App/host/views/doraemon/common/inpatientTickets/detail/others/others.component.html'),
            controller: ['$filter', controller],
            controllerAs: 'vm',
            bindings: {
                request: '<',
                isEdit: '<',
                isSaved: '<',
                categories: '<'
            }
        });

    function controller($filter) {
        var vm = this;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

        vm.$onChanges = onChanges;
        vm.addOthers = addOthers;
        vm.clearOthers = clearOthers;
        vm.deleteItem = deleteItem;

        function onChanges(changes) {
            if (changes.request && changes.request.currentValue) {
                if (vm.request.others.length === 0) {
                    addOthers();
                } else {
                    _.each(vm.request.others, function (x) {
                        x.totalCharges = x.amount + x.uncoveredAmount;
                    });
                }
            }

            if (changes.isSaved && changes.isSaved.currentValue) {
                tidyOthers();
            }

            if (changes.isEdit && changes.isEdit.currentValue) {
                if (vm.request && vm.request.others.length < 1) {
                    addOthers();
                }
            }
        }

        function addOthers() {
            vm.request.others.push({});
        }

        function clearOthers() {
            vm.request.others = [{}];
        }

        function tidyOthers() {
            vm.request.others = _.filter(vm.request.others, function (item) {
                return !_.isNil(item.amount);
            });
        }

        vm.updateCategory = function (item) {
            var selectedCategory = _.find(vm.categories, { id: item.category });
            if (selectedCategory) {
                item.categoryName = selectedCategory.displayName;
                item.category = selectedCategory.category;
            }
    };

        function deleteItem(index,) {
            if (vm.request.others.length > 1) {
                vm.request.others.splice(index, 1);
            }
            else {
                vm.request.others = [];
                addOthers();
            }
        }
    }
})();
