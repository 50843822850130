(function () {
    angular
        .module('app')
        .component('clinicPatientAdmissionDetailsPatientAdmissionQueriesTableComponent', {
            require: {
                container: '^clinicPatientAdmissionDetailsPatientAdmissionQueriesTableComponent'
            },
            templateUrl: require('/App/clinic/views/patient/admissionDetails/patientAdmissionQueriesTable/patientAdmissionQueriesTable.component.html'),
            controller: ['$timeout', 'abp.services.app.serviceRequestQuery', controller],
            controllerAs: 'vm',
            bindings: {
                guaranteeLetterNumber: '<',
                newQueriesCount: '=',
                timeZoneId: '='
            }
        });

    function controller($timeout, serviceRequestQuerySvc) {
        var vm = this;
        vm.loading = 0;

        var viewOptions = {
            maxResultCount: angular.module('app').consts.grid.defaultPageSize,
            skipCount: 0,
            sorting: null
        };

        vm.gridOptions = {
            appScopeProvider: vm,
            paginationPageSizes: angular.module('app').consts.grid.defaultPageSizes,
            paginationPageSize: angular.module('app').consts.grid.defaultPageSize,
            paginationCurrentPage: 0,
            useExternalPagination: true,
            useExternalSorting: true,
            resizable: true,
            columnDefs: [{
                name: 'Actions',
                displayName: 'Actions',
                enableSorting: false,
                headerCellTemplate: '<span></span>',
                cellTemplate: 'actionTemplate',
                width: 75
            }, {
                displayName: App.localize('QueryNumber'),
                enableSorting: false,
                field: 'queryNumber',
                minWidth: 80
            }, {
                displayName: App.localize('CreationTime'),
                enableSorting: false,
                field: 'creationTime',
                cellTemplate: 'creationTimeTemplate',
                minWidth: 140
            }, {
                displayName: App.localize('RepliedBy'),
                enableSorting: false,
                field: 'repliedUserName',
                minWidth: 100
            }, {
                displayName: App.localize('ReplyTime'),
                enableSorting: false,
                field: 'repliedTime',
                cellTemplate: 'repliedTimeTemplate',
                minWidth: 140
            }, {
                displayName: App.localize('Resolved'),
                enableSorting: false,
                field: 'resolvedTime',
                cellTemplate: 'resolvedTemplate',
                minWidth: 80
            }, {
                displayName: App.localize('ResolvedAt'),
                enableSorting: false,
                field: 'resolvedTime',
                cellTemplate: 'resolvedTimeTemplate',
                minWidth: 140
            }],
            data: []
        };

        vm.getDateTimeString = getDateTimeString;

        init();

        function init() {
            getQueries();
            $timeout(function () {
                vm.gridOptions.paginationCurrentPage = Math.floor(viewOptions.skipCount / viewOptions.maxResultCount + 1);
            });
        }

        function getQueries() {
            vm.loading++;
            serviceRequestQuerySvc.getQueries({
                guaranteeLetterNumber: vm.guaranteeLetterNumber,
                skipCount: viewOptions.skipCount,
                maxResultCount: viewOptions.maxResultCount
            }).success(function (data) {
                vm.gridOptions.totalItems = data.queries.totalCount;
                vm.newQueriesCount = data.newQueriesCount;
                vm.gridOptions.data = data.queries.items;
            }).finally(function (e) {
                vm.loading--;
            });
        }

        function getDateTimeString(date, timeZoneId) {
            return App.getDateTimeString(date, timeZoneId);
        }
    }
})();
