(function () {
    'use strict';

    angular.module('app').component('hostDoraemonCommonSupportingDocumentsComponent', {
        templateUrl: require('/App/host/views/doraemon/common/supportingDocuments/supportingDocuments.component.html'),
        controller: controller,
        controllerAs: 'vm',
        bindings: {
            staticDocuments: '<',
            documents: '<',
            isEdit: '<',
            hasState: '<'
        }
    });

    function controller() {
        var vm = this;
    }
})();
