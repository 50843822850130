(() => {
  angular
    .module('app')
    .controller(
      'host.views.corporates.createEdit.features.addCustomizedServices',
      CustomizedServicesController
    );

  CustomizedServicesController.$inject = ['$uibModalInstance', 'customServices'];

  function CustomizedServicesController($uibModalInstance, customServices) {
    const vm = this;
    vm.customServices = angular.copy(customServices);

    vm.save = save;
    vm.cancel = cancel;
    init();

    function init() {}

    function save() {
      $uibModalInstance.close(vm.customServices);
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
