(function () {
  var controllerId = 'host.views.publicUsers.index';
  var app = angular.module('app');

  app.controller(controllerId, [
    '$scope', '$state', '$timeout', '$stateParams', '$uibModal', 'abp.services.app.publicUser', 'moment',
    function ($scope, $state, $timeout, $stateParams, $uibModal, publicUserSvc, moment) {
      function init() {
        publicUserSvc.getListingOptions()
          .success(function (data) {
            vm.consented = data.consented;
            vm.registeredFromApp = data.registeredFromApp;
            vm.registeredFromWeb = data.registeredFromWeb;
            vm.corporates = data.corporates;
          });

        $timeout(function () {
          vm.publicUserGridOptions.paginationCurrentPage = Math.floor(vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1);
        });
      }

      function showDetailModal(publicUser) {
        $uibModal.open({
          templateUrl: require('/App/host/views/publicUsers/detailModal.html'),
          controller: 'host.views.publicUsers.detailModal as vm',
          backdrop: 'static',
          resolve: {
            publicUser: function () {
              return publicUser;
            }
          }
        });
      }

      function showEditModal(publicUser) {
        $uibModal.open({
          templateUrl: require('/App/host/views/publicUsers/editModal.html'),
          controller: 'host.views.publicUsers.editModal as vm',
          backdrop: 'static',
          resolve: {
            publicUser: function () {
              return publicUser;
            }
          }
        }).result.then(function () {
          vm.getPublicUsers();
        });
      }

      var vm = this;
      vm.loading = 0;
      vm.exporting = 0;
      vm.corporates = [];

      vm.permissions = {
        edit: abp.auth.isGranted("Host.PublicUsers.Edit"),
        delete: abp.auth.isGranted('Host.PublicUsers.Delete'),
        unlock: abp.auth.isGranted("Host.PublicUsers.Unlock"),
        resetPassword: abp.auth.isGranted('Host.PublicUsers.ResetPassword')
      };
      vm.permissions.resetPassword |= vm.permissions.edit;

      vm.requestParams = {
        filter: $stateParams.filter,
        skipCount: $stateParams.skipCount,
        maxResultCount: $stateParams.maxResultCount,
        sorting: $stateParams.sorting
      };

      vm.publicUserGridOptions = {
        appScopeProvider: vm,
        paginationPageSizes: app.consts.grid.defaultPageSizes,
        paginationPageSize: app.consts.grid.defaultPageSize,
        paginationCurrentPage: 0,
        useExternalPagination: true,
        useExternalSorting: true,
        columnDefs: [{
          name: 'Actions',
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate'
        }, {
          enableSorting: false,
          name: App.localize('DataConsent'),
          width: 35,
          headerCellTemplate: '<span></span>',
          field: 'hasDataConsent',
          cellTemplate: 'dataConsentTemplate'
        }, {
          enableSorting: false,
          name: 'CreationSource',
          width: 35,
          headerCellTemplate: '<span></span>',
          field: 'creationSource',
          cellTemplate: 'creationSourceTemplate'
        }, {
          displayName: App.localize('LastActiveTimes'),
          enableSorting: false,
          width: 50,
          field: 'devicesInfo',
          cellTemplate: 'activeTimeTemplate'
        }, {
          displayName: App.localize('Name'),
          field: 'name',
          minWidth: 80,
          cellTemplate: 'nameTemplate'
        }, {
          displayName: App.localize('EmailAddress'),
          field: 'emailAddress',
          minWidth: 80
        }, {
          displayName: App.localize('Phone'),
          field: 'phoneNumber',
          cellFilter: 'contactNumberFilter',
          minWidth: 80
        }, {
          displayName: App.localize('NationalId'),
          field: 'nationalId',
          cellTemplate: 'nationalIdTemplate',
          minWidth: 80
        }, {
          displayName: App.localize('Corporates'),
          field: 'subsidiaries',
          enableSorting: false,
          minWidth: 80
        }, {
          displayName: App.localize('CreationDate'),
          field: 'creationTime',
          cellFilter: 'momentFormat: \'L\'',
          minWidth: 80
        }],
        onRegisterApi: function (gridApi) {
          $scope.gridApi = gridApi;
          $scope.gridApi.core.on.sortChanged($scope, function (scope, callback) {
            vm.requestParams.sorting = callback.length && callback[0].field ? callback[0].field + ' ' + callback[0].sort.direction : null;
            vm.getPublicUsers();
          });
          gridApi.pagination.on.paginationChanged($scope, App.setPagination(vm.requestParams, vm.getPublicUsers));
        },
        data: []
      };

      function registerStateParams() {
        $state.transitionTo($state.current, {
          filter: vm.requestParams.filter,
          skipCount: vm.requestParams.skipCount,
          maxResultCount: vm.requestParams.maxResultCount,
          sorting: vm.requestParams.sorting
        }, {
          notify: false
        });
      }

      vm.getPublicUsers = function () {
        registerStateParams();
        vm.loading++;

        publicUserSvc.getPublicUsers(vm.requestParams).success(function (data) {
          const strHasDataConsent = App.localize('Consented');
          const strNoDataConsent = App.localize('NoConsent');
          vm.publicUserGridOptions.data = _.map(data.items, function (d) {
            d.dataConsentInfo = d.hasDataConsent ? strHasDataConsent : strNoDataConsent;

            processDeviceInfo(d, d.devicesInfo);

            return d;
          });
          vm.publicUserGridOptions.totalItems = data.totalCount;
        }).finally(function (e) {
          vm.loading--;
        });
      };

      vm.showDetails = function (publicUser) {
        showDetailModal(publicUser);
      };

      vm.edit = function (publicUser) {
        showEditModal(publicUser);
      };

      vm.unlock = function (publicUser) {
        publicUserSvc.unlockPublicUser({
          id: publicUser.id
        }).success(function (data) {
          abp.notify.success(App.localize("UserSuccessfullyUnlocked"));
        });
      };

      vm.exportToExcel = function () {
        vm.exporting++;
        publicUserSvc.getPublicUsersToExcel(vm.requestParams).success(function (data) {
          App.downloadTempFile(data);
        }).finally(function () {
          vm.exporting--;
        });
      };

      vm.resetPassword = function (publicUser) {
        $uibModal.open({
          templateUrl: require('/App/host/views/publicUsers/resetPassword/resetPassword.modal.html'),
          controller: "host.views.publicUsers.resetPasswordModel as vm",
          backdrop: "static",
          resolve: {
            publicUser: function () {
              return publicUser;
            }
          }
        });
      };


      vm.delete = function (publicUser) {
        abp.message.confirm(App.localize('UserDeleteWarningMessage', publicUser.name), App.localize('AreYouSure'), function (result) {
          if (result) {
            vm.loading++;
            publicUserSvc.deletePublicUser({
              id: publicUser.id
            }).success(function () {
              // EMPTY.
            }).finally(function () {
              vm.loading--;
              vm.getPublicUsers();
            });
          }
        })
      }

      init();

      function processDeviceInfo(rowEntity, deviceInfo) {
        var lastAppDevice = '';
        var lastWebDevice = '';
        var appActiveTimes = [];
        var webActiveTimes = [];

        _.filter(deviceInfo, function (d) {
          if (App.getAppProductName().includes(d.productName)) {
            appActiveTimes.push(d.lastAccessTime);
            lastAppDevice = d.productName;
          } else {
            webActiveTimes.push(d.lastAccessTime);
            lastWebDevice = d.productName
          }
        });

        rowEntity.lastAppActiveTime = _.max(appActiveTimes);
        rowEntity.lastWebActiveTime = _.max(webActiveTimes);

        rowEntity.lastAppActiveTimeTooltip = rowEntity.lastAppActiveTime ?
          lastAppDevice + ': ' + moment(rowEntity.lastAppActiveTime).format('DD/MM/YYYY hh:mm a') :
          App.localize('NotAvailableShort');

        rowEntity.lastWebActiveTimeTooltip = rowEntity.lastWebActiveTime ?
          lastWebDevice + ': ' + moment(rowEntity.lastWebActiveTime).format('lll') :
          App.localize('NotAvailableShort');
      }
    }
  ]);
})();
