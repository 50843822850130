// Import AngularJS modules in order

require('./hms-ng-auth.module');
require('./hms-bootstrap.module');
require('./app');

// Import all JS files.

const req = require.context('.', true, /^(.*\.(js$))[^.]*$/im);
req.keys().forEach((key) => {
  req(key);
});
