(function () {
    var controllerId = 'host.views.doraemon.common.inpatientTickets.detail';
    angular
        .module('app')
        .controller(controllerId, [
            '$scope', '$state', '$stateParams', 'abp.services.app.patientTicket',
            function ($scope, $state, $stateParams, patientTicketSvc) {
                function init() {
                    getTicket();
                }

                var vm = this;
                vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
                vm.loading = 0;
                vm.isHost = App.isHost();
                vm.isCorporate = App.isCorporate();
                vm.ticketNumber = $stateParams.ticketNumber;
                vm.checkEditMode = checkEditMode;
                vm.isEdit = false;

                function getTicket() {
                    vm.loading++;
                    patientTicketSvc.getTicketDetail({
                        id: $stateParams.ticketNumber
                    }).success(function (data) {
                        vm.ticket = data.ticket;
                        vm.patient = data.patient;
                        vm.clinic = data.clinic;
                        vm.isMaternity = data.ticket.serviceType === 8;
                    }).finally(function () {
                        vm.loading--;
                    });
                }

                function checkEditMode(isEdit) {
                    vm.isEdit = isEdit;
                }

                init();
            }
        ]);
})();
