(function () {
    var controllerId = 'host.views.inpatientNprClaims';
    angular
        .module('app')
        .controller(controllerId, [
            '$state', '$stateParams', 'abp.services.app.nprClaim', 'Hms.Claims.ClaimType', 'Hms.GuaranteeLetters.DeliveryType',
            'Hms.PaymentAccounts.PaymentAccount', 'Abp.Configuration.Setting', 'moment',
            function ($state, $stateParams, nprClaimSvc, enumClaimType, enumDeliveryType, constsPaymentAccount, constsAbpConfig, moment) {
                var vm = this;
                vm.constsPaymentAccount = constsPaymentAccount;
                vm.constsAbpConfig = constsAbpConfig;
                vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
                vm.loading = 0;
                vm.saving = 0;
                vm.claimTypeId = $stateParams.claimType;
                vm.employeeId = $stateParams.employeeId;
                vm.ticketNumber = $stateParams.ticketNumber;
                vm.updateBankingDetails = updateBankingDetails;
                vm.submitClaim = submitClaim;
                vm.ticket = [];
                vm.diagnosis = {
                    selected: [],
                    icd: []
                };
                vm.newAttachments = [];
                vm.hasInpatientClaimProcessingEnhancement = abp.setting.getBoolean('Hms.Feature.InpatientClaimProcessingEnhancement');
                vm.hasInsuranceEnhancementModule = abp.setting.getBoolean(
                    'Hms.Feature.InsuranceEnhancementModule'
                  );

                vm.enums = {};
                vm.enums.claimType = enumClaimType;
                vm.enums.deliveryType = enumDeliveryType;

                function init() {
                    vm.loading++;

                    var input = $.extend({}, {
                        claimType: vm.claimTypeId,
                        employeeId: vm.employeeId,
                        ticketNumber: vm.ticketNumber
                    });

                    nprClaimSvc.getNprClaimCreationDetails(input)
                        .success(function (data) {
                            vm.claim = data;
                            vm.patient = data.patient;
                            vm.admissionRecord = data.admissionRecord;
                            vm.bankingDetails = data.bankingDetails;
                            vm.banks = data.banks || [];
                            vm.claimTypes = data.claimTypes;
                            vm.visitTypes = data.visitTypes;
                            vm.diagnosis.icd = _.map(data.diseaseClassifications, function (d) {
                                return {
                                    id: d.id,
                                    code: d.code,
                                    description: '(' + d.code + ') ' + d.description
                                };
                            });

                            vm.bankId = vm.bankingDetails && vm.bankingDetails.length > 0
                                ? vm.bankingDetails[0].bankId
                                : null;

                            if (vm.bankId) {
                                updateBankingDetails();
                            }
                        }).finally(function () {
                            vm.loading--;
                        });
                }

                /* Medical Certificate Management */

                vm.issueMc = function () {
                    var firstDate = moment(vm.receiptDate).startOf('day');
                    vm.ticket.medicalCertificate = {
                        startDate: firstDate,
                        endDate: firstDate
                    };
                    vm.isMcStartDateAlsoCheckedInDate = 1;
                };

                vm.removeMc = function () {
                    vm.ticket.medicalCertificate = null;
                };

                vm.setMcStartDate = function (offset) {
                    var checkInDate = moment(vm.ticket.checkedInTime).startOf('day');
                    vm.ticket.medicalCertificate.startDate = checkInDate.add(offset, 'days');

                    var mcEndDate = vm.ticket.medicalCertificate.endDate.startOf('day');
                    if (mcEndDate.diff(vm.ticket.medicalCertificate.startDate, 'days') < 0) {
                        vm.ticket.medicalCertificate.endDate = moment(vm.ticket.medicalCertificate.startDate);
                    }
                    vm.isMcStartDateAlsoCheckedInDate = offset === 0;
                };

                vm.getMcDurationDescription = function () {
                    if (vm.ticket.medicalCertificate) {
                        var startDate = moment(vm.ticket.medicalCertificate.startDate);
                        var endDate = moment(vm.ticket.medicalCertificate.endDate);
                        var duration = endDate.diff(startDate, 'days') + 1;
                        return App.localize('XDays', duration);
                    }
                    return '';
                };

                vm.getMcStartDateChoice = function (offset) {
                    return moment(vm.ticket.checkedInTime).add(offset, 'days').startOf('day');
                };

                /* End of Medical Certificate Management */

                vm.uploadConfig = {
                    objectType: 'ClaimAttachment'
                };

                function updateBankingDetails() {
                    var bank = _.find(vm.bankingDetails, ['bankId', vm.bankId]);
                    vm.paymentAccountId = bank ? bank.paymentAccountId : null;
                    vm.accountNumber = bank ? bank.accountNumber : null;
                    vm.recipientName = bank ? bank.recipientName : null;
                    vm.emailAddress = bank ? bank.emailAddress : null;

                    if (vm.emailAddress === null) {
                        vm.emailAddress = vm.patient ? vm.patient.emailAddress : null;
                    }
                }

                function submitClaim() {
                    vm.loading++;

                    vm.selectedDiagnosis = _.map(vm.diagnosis.selected, function (d) {
                        return {
                            description: d.description,
                            diagnosisDate: d.date
                        };
                    });

                    var input = $.extend(vm.claim, {
                        employeeId: vm.employeeId,
                        paymentAccountId: vm.paymentAccountId,
                        clinicName: vm.admissionRecord.panelName,
                        clinicAddress: vm.admissionRecord.panelAddress,
                        admissionDate: vm.admissionRecord.admissionDate,
                        dischargeDate: vm.admissionRecord.dischargeDate,
                        newAttachments: vm.newAttachments,
                        medicalCertificate: vm.ticket.medicalCertificate,
                        diagnoses: vm.selectedDiagnosis,
                        bankingDetails: {
                            bankId: vm.bankId,
                            bankAccountNumber: vm.accountNumber,
                            bankAccountName: vm.recipientName,
                            paymentEmailAddresses: vm.emailAddress
                        }
                    });

                    var isValidForm = App.isFormValid(vm.claimForm);
                    if (!isValidForm) {
                        vm.loading--;
                        return;
                    }

                    nprClaimSvc.submitClaim(input)
                        .success(function () {
                            abp.notify.info(App.localize("SuccessfullySaved"));
                            $state.go('inpatientNpr');
                        })
                        .finally(function () {
                            vm.loading--;
                        });
                }

                init();
            }
        ]);
})();
