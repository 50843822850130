(() => {
  angular.module('app').component('commonPostHospitalizationClaimsDetailsClaimInfoComponent', {
    templateUrl: require('./claimInfo.component.html'),
    controller: ClaimInfoController,
    controllerAs: 'vm',
    bindings: {
      claim: '<',
      patient: '<',
      admissionRecord: '<',
      currencyCode: '<',
    },
  });

  ClaimInfoController.$inject = [
    '$state',
    '$stateParams',
    'Hms.ServiceRequests.ServiceRequestStatus',
    'Hms.ServiceRequests.ServiceRequestType',
    'Hms.Gender',
  ];

  function ClaimInfoController(
    $state,
    $stateParams,
    enumServiceRequestStatus,
    enumServiceRequestType,
    enumsGender
  ) {
    const vm = this;
    vm.loading = 0;
    vm.saving = 0;
    vm.timeZoneId = $stateParams.timeZoneId;
    vm.hasInpatientClaimProcessingEnhancement = abp.setting.getBoolean(
      'Hms.Feature.InpatientClaimProcessingEnhancement'
    );

    vm.enums = {};
    vm.enums.serviceRequestType = enumServiceRequestType;
    vm.enums.serviceRequestStatus = enumServiceRequestStatus;
    vm.enums.gender = enumsGender;

    vm.isClinic = App.isClinic();
    vm.viewTicket = viewTicket;

    // TODO: Update permissions

    vm.permissions = {
      update: abp.auth.isGranted('GuaranteeLetters.Host.Update'),
      topUp: abp.auth.isGranted('GuaranteeLetters.Host.TopUp'),
      extend: abp.auth.isGranted('GuaranteeLetters.Host.Approve'),

      viewTicket: abp.auth.isGranted('PatientTickets'),
    };

    vm.isEdit = false;

    vm.$onInit = init;

    function init() {}

    function viewTicket() {
      $state.go('patientTicketDetail', {
        ticketNumber: vm.claim.ticketNumber,
      });
    }
  }
})();
