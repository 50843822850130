(function () {
    var controllerId = 'revenue.views.subscriptions.editScope.editScopeModal';
    var module = angular.module('revenue');
    module.controller(controllerId, [
        '$uibModalInstance', 'abp.services.revenue.subscription', 'subscription',
        function ($uibModalInstance, subscriptionSvc, subscription) {
            function init() {
                vm.loading = true;
                subscriptionSvc.getSubscriptionScopeForEdit({
                    id: subscription.id
                }).success(function (data) {
                    vm.subscription = data.scope;
                    vm.subsidiaries = data.subsidiaries;
                }).finally(function () {
                    vm.loading = false;
                });
            }

            const vm = this;
            vm.loading = false;
            vm.saving = false;
            vm.hasBillingCycle = subscription.hasBillingCycle;

            vm.save = function () {
                if (vm.saving) return;
                vm.saving = true;

                const input = $.extend({}, vm.subscription);
                if (input.scope === 0) input.subsidiaryIds = [];

                subscriptionSvc.updateSubscriptionScope(input)
                    .success(function (data) {
                        abp.notify.info(App.localize("SuccessfullySaved"));
                        $uibModalInstance.close(data);
                    }).finally(function () {
                        vm.saving = false;
                    });
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            init();
        }
    ]);
})();
