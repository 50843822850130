(() => {
  angular.module('app').controller('tenant.views.settings.index', TenantSettingsIndexController);

  TenantSettingsIndexController.$inject = [];

  function TenantSettingsIndexController() {
    function init() {}

    init();
  }
})();
