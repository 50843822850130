(() => {
    angular
      .module('app')
      .controller('clinic.views.patient.index', PatientIndexController);

    function PatientIndexController() {
        const vm = this;
        vm.isSpecialist = App.isSpecialist();
        vm.hasPatientSearchEnhancement = abp.features.isEnabled(
          'ClinicModule.PatientSearchEnhancement'
        );
    }
})();
