(function () {
    angular.module('app').component('hostInpatientGuaranteeLettersCommonPatientDetailsComponent', {
        templateUrl: require('/App/host/views/inpatientGuaranteeLetters/common/patientDetails/patientDetails.component.html'),
        controller: ['abp.services.app.inpatientEmployee', controller],
        controllerAs: 'vm',
        bindings: {
            patientId: '<'
        }
    });

    function controller(inpatientEmployeeSvc) {
        var vm = this;
        vm.loading = 0;
        vm.$onInit = onInit;

        vm.hasInpatientClaimProcessingEnhancement = abp.setting.getBoolean('Hms.Feature.InpatientClaimProcessingEnhancement');

        function onInit() {
            vm.loading++;
            inpatientEmployeeSvc.getInpatientPatientDetail({
                id: vm.patientId
            }).success(function (data) {
                vm.patient = data;
                vm.patientAgeText = App.localize('XYearsOld', vm.patient.age);
            }).finally(function (e) {
                vm.loading--;
            });
        }
    }
})();
