(() => {
  angular
    .module('app')
    .controller(
      'common.views.patientTickets.updateHospitalizationDaysModal',
      UpdateHospitalizationDaysModalController
    );

  UpdateHospitalizationDaysModalController.$inject = [
    '$uibModalInstance',
    'ticket',
    'abp.services.app.inpatientPatientTicket',
    'moment',
  ];

  function UpdateHospitalizationDaysModalController(
    $uibModalInstance,
    ticket,
    inpatientPatientTicketSvc,
    moment
  ) {
    const vm = this;

    vm.saving = 0;
    vm.ticket = ticket;
    vm.hospitalizationLeaveDates = [];
    vm.remarks = '';

    vm.dateRangeModel = {
      startDate: moment(),
      endDate: moment(),
    };

    vm.save = save;
    vm.cancel = cancel;

    init();

    function init() {
      getTicket();
    }

    function getTicket() {
      vm.loading += 1;
      inpatientPatientTicketSvc
        .getTicketDetail({
          id: vm.ticket.ticketNumber,
        })
        .success((data) => {
          vm.hospitalizationLeaveDates = data.ticket.hospitalizationLeaveDates.map((date) =>
            moment(date).format('YYYY-MM-DD')
          );

          const minDate = data.ticket.admissionTime;
          const maxDate = data.ticket.dischargeDate;
          vm.dateRangeOptions = {
            minDate: moment(moment(minDate).format('YYYY-MM-DD')),
            maxDate: moment(moment(maxDate).endOf('day')).add(-1, 'days'),
            ranges: '',
          };

          vm.dateRangeModel = {
            startDate: moment(minDate),
            endDate: moment(minDate),
          };

          vm.dateRangeOptions.eventHandlers = {
            'apply.daterangepicker': () => {
              let { startDate } = vm.dateRangeModel;
              const { endDate } = vm.dateRangeModel;

              if (!startDate || !endDate) return;

              if (startDate === endDate) {
                vm.hospitalizationLeaveDates.push(startDate.format('YYYY-MM-DD'));
              } else {
                // Split date range to individual dates
                while (startDate <= endDate) {
                  vm.hospitalizationLeaveDates.push(startDate.format('YYYY-MM-DD'));
                  startDate = startDate.add(1, 'days');
                }
              }

              // Remove duplicates
              vm.hospitalizationLeaveDates = [...new Set(vm.hospitalizationLeaveDates)];
            },
          };
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function save() {
      vm.saving += 1;
      inpatientPatientTicketSvc
        .updateHospitalizationDays({
          id: vm.ticket.ticketNumber,
          hospitalizationDays: vm.hospitalizationLeaveDates.length,
          hospitalizationLeaveDates: vm.hospitalizationLeaveDates,
          remarks: vm.remarks,
        })
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          $uibModalInstance.close();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
