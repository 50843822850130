(() => {
  angular.module('app').component('commonInpatientTicketsDetailAdmissionRecordComponent', {
    templateUrl: require('./admissionRecord.component.html'),
    controller: AdmissionRecordController,
    controllerAs: 'vm',
    bindings: {
      ticket: '<',
      diagnosesDisplay: '<',
    },
  });

  AdmissionRecordController.$inject = [];

  function AdmissionRecordController() {
    const vm = this;

    vm.$onInit = init;

    function init() {}
  }
})();
