(function () {
    var componentId = 'revenueCorporatesListing';
    var module = angular.module('revenue');

    function controller($timeout, commonSvc, corporateSvc, commonLookupSvc) {
        function selectCorporate(corporate) {
            if (corporate === vm.corporate) return;
            vm.corporate = corporate;
            vm.requestParams.corporateId = corporate ? corporate.id : null;
            vm.container.registerStateParams();
            vm.onSelectCorporate({ corporate: corporate });
        }

        const vm = this;
        vm.loading = false;
        vm.hasMore = false;
        vm.corporates = [];
        vm.accountOwners = [];
        vm.corporate = null;
        vm.selectCorporate = selectCorporate;
        
        vm.accountOwnerLookup = function (search) {
            commonLookupSvc.getAccountOwners()
            .success(function (data) {
                let dataQuery = data.filter(accountOwner => accountOwner.name.toLowerCase().search(search.toLowerCase()) >= 0);
                vm.accountOwners = dataQuery;
            });
        }

        vm.agentLookup = {
            cachedResults: [],
            activeResults: [],
            lastSearchKey: '',
            lookup: function (search) {
                this.lastSearchKey = (search || '').trim().toLowerCase();
                const cachedCopy = this.cachedResults[this.lastSearchKey];
                if (cachedCopy) {
                    this.activeResults = cachedCopy;
                } else {
                    const mgr = this;
                    commonSvc.findAgents({
                        excludeIntroducerOnly: true,
                        excludeResignedOnly: true,
                        filter: this.lastSearchKey,
                        maxResultCount: 100
                    }).success(function (data) {
                        const dataKey = (data.filter || '').trim().toLowerCase();
                        mgr.cachedResults[dataKey] = data.items;
                        if (dataKey === mgr.lastSearchKey) {
                            mgr.activeResults = data.items;
                        }
                    });
                }
            }
        };

        vm.reload = function () {
            vm.corporates = [];
            vm.hasMore = true;
            $timeout(function () {
                vm.loadMoreCorporates();
            }, 0);
        };

        function loadCorporateDirectly() {
            if (!vm.requestParams.corporateId || vm.corporate) return;

            vm.loading = true;
            corporateSvc.getCorporate({
                id: vm.requestParams.corporateId
            }).success(function (data) {
                selectCorporate(data);
            }).finally(function () {
                vm.loading = false;
            });
        }

        vm.loadMoreCorporates = function () {
            if (vm.loading || !vm.hasMore) return;

            vm.container.registerStateParams();

            const input = _.assignIn({}, vm.requestParams, {
                skipCount: vm.corporates.length,
                maxResultCount: 20
            });

            vm.loading = true;
            corporateSvc.getCorporates(input).success(function (data) {
                let hasNewData = false;
                let checkDuplicate = true;
                _.forEach(data.items, function (d) {
                    if (checkDuplicate) {
                        const exists = _.findLastIndex(vm.corporates, { id: d.id }) !== -1;
                        if (exists) return;
                        checkDuplicate = false;
                    }
                    vm.corporates.push(d);
                    hasNewData = true;
                });
                vm.hasMore = hasNewData;

                if (
                    vm.requestParams.corporateId
                    && (!vm.corporate || vm.corporate.id !== vm.requestParams.corporateId)) {
                    const corporate = _.find(vm.corporates, { id: vm.requestParams.corporateId });
                    if (corporate) {
                        selectCorporate(corporate);
                    } else {
                        $timeout(loadCorporateDirectly, 0);
                    }
                }
            }).finally(function () {
                vm.loading = false;
            });
        };

        $timeout(vm.reload, 0);
    }

    module.component(componentId, {
        require: { container: '^revenueCorporatesContainer' },
        bindings: { onSelectCorporate: '&', requestParams: '<' },
        templateUrl: require('/App/modules/revenue/views/corporates/listing/widget.html'),
        controller: [
            '$timeout', 'abp.services.revenue.common', 'abp.services.revenue.corporate', 'abp.services.app.commonLookup',
            controller],
        controllerAs: 'vm'
    });
})();
