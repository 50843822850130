(() => {
  angular
    .module('app')
    .controller('clinic.views.specialistDashboard.index', SpecialistDashboardPageController);

  SpecialistDashboardPageController.$inject = [
    '$uibModal',
    '$interval',
    '$scope',
    'abp.services.app.specialistDashboard',
    'abp.services.app.commonLookup',
    'lookupModal',
  ];

  function SpecialistDashboardPageController(
    $uibModal,
    $interval,
    $scope,
    specialistDashboardSvc,
    commonLookupSvc,
    lookupModal
  ) {
    const vm = this;
    vm.loading = 0;
    let tickerCount = 0;
    let isSilentLoading = 0;
    vm.viewType = 'grid';
    vm.isInpatient = App.isInpatient();

    vm.applicationHasSpecialistWorkflowEnhancement = abp.setting.getBoolean(
      'Hms.Feature.SpecialistWorkflowEnhancement'
    );

    // Widgets for tab view.

    vm.widgets = {
      pendingSubmission: {
        name: App.localize('PendingSubmission'),
        btnTarget: '#a',
        active: 0,
        totalCount: 0,
        isRed: true,
      },
      outstanding: {
        name: App.localize('Outstanding'),
        btnTarget: '#b',
        active: 1,
        totalCount: 0,
        isRed: true,
      },
      lateSubmission: {
        name: App.localize('LateSubmission'),
        btnTarget: '#c',
        active: 2,
        totalCount: 0,
        isRed: true,
      },
      glPendingApproval: {
        name: App.localize('GlPendingApproval'),
        btnId: 'btnSpecialistClaims',
        btnTarget: '#d',
        active: 3,
        totalCount: 0,
        isRed: false,
      },
      submitted: {
        name: App.localize('Submitted'),
        btnTarget: '#e',
        hasPermission: true,
        active: 4,
        totalCount: 0,
        isRed: false,
      },
      rejected: {
        name: App.localize('Rejected'),
        btnTarget: '#f',
        active: 5,
        totalCount: 0,
        isRed: false,
      },
    };

    // Skip counts for each widget to "show more".

    vm.pendingSubmissionSkipCount = 0;
    vm.outstandingSkipCount = 0;
    vm.lateSubmissionSkipCount = 0;
    vm.glPendingApprovalSkipCount = 0;
    vm.submittedSkipCount = 0;
    vm.rejectedSkipCount = 0;

    // Max count

    vm.maxResultCount = 5;

    // Show more functions for each widget.

    vm.showMorePendingSubmission = showMorePendingSubmission;
    vm.showMoreOutstanding = showMoreOutstanding;
    vm.showMoreLateSubmission = showMoreLateSubmission;
    vm.showMoreGlPendingApproval = showMoreGlPendingApproval;
    vm.showMoreSubmitted = showMoreSubmitted;
    vm.showMoreRejected = showMoreRejected;
    vm.changeLocation = changeLocation;

    init();

    function init() {
      commonLookupSvc
        .getDefaultClinicLocation()
        .success((data) => {
          setLocation(data);
        })
        .finally(() => {
          if (!vm.clinic.clinicLocationId) {
            vm.changeLocation();
          } else {
            refresh();
          }
        });

      if (vm.applicationHasSpecialistWorkflowEnhancement) {
        //refresh();

        let tickerInterval = $interval(tickerJob, 6000);
        $scope.$on('$destroy', () => {
          if (angular.isDefined(tickerInterval)) {
            $interval.cancel(tickerInterval);
            tickerInterval = undefined;
          }
        });
      }
      getTimeZoneId();
    }

    function tickerJob() {
      tickerCount += 1;
      if (!(tickerCount % 10) && !vm.loading && !isSilentLoading) {
        refresh(true);
      }
    }

    function refresh(silentLoad) {
      vm.pendingSubmissionSkipCount = 0;
      vm.outstandingSkipCount = 0;
      vm.lateSubmissionSkipCount = 0;
      vm.glPendingApprovalSkipCount = 0;
      vm.submittedSkipCount = 0;
      vm.rejectedSkipCount = 0;

      if (vm.loading || isSilentLoading) return;

      // Pending submission

      if (silentLoad) isSilentLoading += 1;
      else vm.loading += 1;

      specialistDashboardSvc
        .getClaimsPendingSubmission(
          {
            panelLocationId: vm.clinic.clinicLocationId,
            skipCount: vm.pendingSubmissionSkipCount,
            maxResultCount: vm.maxResultCount,
          },
          {
            abpHandleError: false,
          }
        )
        .success((data) => {
          vm.pendingSubmission = data.items;
          vm.pendingSubmissionTotalCount = data.totalCount;
          vm.widgets.pendingSubmission.totalCount = vm.pendingSubmissionTotalCount;
          vm.pendingSubmissionHasMore =
            vm.pendingSubmission.length < vm.pendingSubmissionTotalCount;
        })
        .finally(() => {
          if (silentLoad) isSilentLoading -= 1;
          else vm.loading -= 1;
        });

      // Outstanding

      if (silentLoad) isSilentLoading += 1;
      else vm.loading += 1;

      specialistDashboardSvc
        .getOutstandingClaimServiceRequests(
          {
            panelLocationId: vm.clinic.clinicLocationId,
            skipCount: vm.outstandingSkipCount,
            maxResultCount: vm.maxResultCount,
          },
          {
            abpHandleError: false,
          }
        )
        .success((data) => {
          vm.outstanding = data.items;
          vm.outstandingTotalCount = data.totalCount;
          vm.widgets.outstanding.totalCount = vm.outstandingTotalCount;
          vm.outstandingHasMore = vm.outstanding.length < vm.outstandingTotalCount;
        })
        .finally(() => {
          if (silentLoad) isSilentLoading -= 1;
          else vm.loading -= 1;
        });

      // Late submission

      if (silentLoad) isSilentLoading += 1;
      else vm.loading += 1;

      specialistDashboardSvc
        .getClaimsPendingLateSubmission(
          {
            panelLocationId: vm.clinic.clinicLocationId,
            skipCount: vm.lateSubmissionSkipCount,
            maxResultCount: vm.maxResultCount,
          },
          {
            abpHandleError: false,
          }
        )
        .success((data) => {
          vm.lateSubmission = data.items;
          vm.lateSubmissionTotalCount = data.totalCount;
          vm.widgets.lateSubmission.totalCount = vm.lateSubmissionTotalCount;
          vm.lateSubmissionHasMore = vm.lateSubmission.length < vm.lateSubmissionTotalCount;
        })
        .finally(() => {
          if (silentLoad) isSilentLoading -= 1;
          else vm.loading -= 1;
        });

      // GL pending approval

      if (silentLoad) isSilentLoading += 1;
      else vm.loading += 1;

      specialistDashboardSvc
        .getGuaranteeLettersPendingApproval(
          {
            panelLocationId: vm.clinic.clinicLocationId,
            skipCount: vm.glPendingApprovalSkipCount,
            maxResultCount: vm.maxResultCount,
          },
          {
            abpHandleError: false,
          }
        )
        .success((data) => {
          vm.glPendingApproval = data.items;
          vm.glPendingApprovalTotalCount = data.totalCount;
          vm.widgets.glPendingApproval.totalCount = vm.glPendingApprovalTotalCount;
          vm.glPendingApprovalHasMore =
            vm.glPendingApproval.length < vm.glPendingApprovalTotalCount;
        })
        .finally(() => {
          if (silentLoad) isSilentLoading -= 1;
          else vm.loading -= 1;
        });

      // Submitted

      if (silentLoad) isSilentLoading += 1;
      else vm.loading += 1;

      specialistDashboardSvc
        .getPendingReviewClaimServiceRequests(
          {
            panelLocationId: vm.clinic.clinicLocationId,
            skipCount: vm.submittedSkipCount,
            maxResultCount: vm.maxResultCount,
          },
          {
            abpHandleError: false,
          }
        )
        .success((data) => {
          vm.submitted = data.items;
          vm.submittedTotalCount = data.totalCount;
          vm.widgets.submitted.totalCount = vm.submittedTotalCount;
          vm.submittedHasMore = vm.submitted.length < vm.submittedTotalCount;
        })
        .finally(() => {
          if (silentLoad) isSilentLoading -= 1;
          else vm.loading -= 1;
        });

      // Rejected

      if (silentLoad) isSilentLoading += 1;
      else vm.loading += 1;

      specialistDashboardSvc
        .getRejectedClaimServiceRequests(
          {
            panelLocationId: vm.clinic.clinicLocationId,
            skipCount: vm.rejectedSkipCount,
            maxResultCount: vm.maxResultCount,
          },
          {
            abpHandleError: false,
          }
        )
        .success((data) => {
          vm.rejected = data.items;
          vm.rejectedTotalCount = data.totalCount;
          vm.widgets.rejected.totalCount = vm.rejectedTotalCount;
          vm.rejectedHasMore = vm.rejected.length < vm.rejectedTotalCount;
        })
        .finally(() => {
          if (silentLoad) isSilentLoading -= 1;
          else vm.loading -= 1;
        });
    }

    function changeLocation() {
      lookupModal.open({
        title: App.localize('SelectPanelLocation'),
        serviceMethod: commonLookupSvc.findClinicLocations,
        callback(location) {
          setLocation(location);

          refresh();
        },
      });
    }

    function setLocation(location) {
      vm.clinic = {};

      vm.clinic.clinicLocationId = parseInt(location.value, 10);
      vm.clinic.name = location.name;
      vm.clinic.address = location.address;
      vm.clinic.contactNumber = location.contactNumber;
      vm.clinic.ianaTimeZoneId = location.ianaTimeZoneId;
    }

    // #region "Show more" functions for each widget.

    function showMorePendingSubmission(silentLoad) {
      if (!vm.isLoading && vm.pendingSubmissionHasMore) {
        vm.pendingSubmissionSkipCount += 5;

        specialistDashboardSvc
          .getClaimsPendingSubmission(
            {
              skipCount: vm.pendingSubmissionSkipCount,
              maxResultCount: vm.maxResultCount,
            },
            {
              abpHandleError: false,
            }
          )
          .success((data) => {
            vm.pendingSubmission = vm.pendingSubmission.concat(data.items);
            vm.pendingSubmissionHasMore = vm.pendingSubmission.length < data.totalCount;
          })
          .finally(() => {
            if (silentLoad) isSilentLoading -= 1;
            else vm.loading -= 1;
          });
      }
    }

    function showMoreOutstanding(silentLoad) {
      if (!vm.isLoading && vm.outstandingHasMore) {
        vm.outstandingSkipCount += 5;

        specialistDashboardSvc
          .getOutstandingClaimServiceRequests(
            {
              skipCount: vm.outstandingSkipCount,
              maxResultCount: vm.maxResultCount,
            },
            {
              abpHandleError: false,
            }
          )
          .success((data) => {
            vm.outstanding = vm.outstanding.concat(data.items);
            vm.outstandingHasMore = vm.outstanding.length < data.totalCount;
          })
          .finally(() => {
            if (silentLoad) isSilentLoading -= 1;
            else vm.loading -= 1;
          });
      }
    }

    function showMoreLateSubmission(silentLoad) {
      if (!vm.isLoading && vm.lateSubmissionHasMore) {
        vm.lateSubmissionSkipCount += 5;

        specialistDashboardSvc
          .getClaimsPendingLateSubmission(
            {
              skipCount: vm.lateSubmissionSkipCount,
              maxResultCount: vm.maxResultCount,
            },
            {
              abpHandleError: false,
            }
          )
          .success((data) => {
            vm.lateSubmission = vm.lateSubmission.concat(data.items);
            vm.lateSubmissionHasMore = vm.lateSubmission.length < data.totalCount;
          })
          .finally(() => {
            if (silentLoad) isSilentLoading -= 1;
            else vm.loading -= 1;
          });
      }
    }

    function showMoreGlPendingApproval(silentLoad) {
      if (!vm.isLoading && vm.glPendingApprovalHasMore) {
        vm.glPendingApprovalSkipCount += 5;

        specialistDashboardSvc
          .getGuaranteeLettersPendingApproval(
            {
              skipCount: vm.glPendingApprovalSkipCount,
              maxResultCount: vm.maxResultCount,
            },
            {
              abpHandleError: false,
            }
          )
          .success((data) => {
            vm.glPendingApproval = vm.glPendingApproval.concat(data.items);
            vm.glPendingApprovalHasMore = vm.glPendingApproval.length < data.totalCount;
          })
          .finally(() => {
            if (silentLoad) isSilentLoading -= 1;
            else vm.loading -= 1;
          });
      }
    }

    function showMoreSubmitted(silentLoad) {
      if (!vm.isLoading && vm.submittedHasMore) {
        vm.submittedSkipCount += 5;

        specialistDashboardSvc
          .getPendingReviewClaimServiceRequests(
            {
              skipCount: vm.submittedSkipCount,
              maxResultCount: vm.maxResultCount,
            },
            {
              abpHandleError: false,
            }
          )
          .success((data) => {
            vm.submitted = vm.submitted.concat(data.items);
            vm.submittedHasMore = vm.submitted.length < data.totalCount;
          })
          .finally(() => {
            if (silentLoad) isSilentLoading -= 1;
            else vm.loading -= 1;
          });
      }
    }

    function showMoreRejected(silentLoad) {
      if (!vm.isLoading && vm.rejectedHasMore) {
        vm.rejectedSkipCount += 5;

        specialistDashboardSvc
          .getRejectedClaimServiceRequests(
            {
              skipCount: vm.rejectedSkipCount,
              maxResultCount: vm.maxResultCount,
            },
            {
              abpHandleError: false,
            }
          )
          .success((data) => {
            vm.rejected = vm.rejected.concat(data.items);
            vm.rejectedHasMore = vm.rejected.length < data.totalCount;
          })
          .finally(() => {
            if (silentLoad) isSilentLoading -= 1;
            else vm.loading -= 1;
          });
      }
    }
    // #endregion "Show more" functions for each widget.

    function getTimeZoneId() {
      vm.loading += 1;
      commonLookupSvc
        .getTenantIanaTimeZoneId({})
        .success((data) => {
          vm.timeZoneId = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
