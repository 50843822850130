(() => {
  angular.module('app').component('specialOperatingHour', {
    templateUrl: require('./specialOperatingHour.component.html'),
    controller: SpecialOperatingHourController,
    controllerAs: 'vm',
    bindings: {
      specialHours: '=',
    },
  });

  SpecialOperatingHourController.$inject = ['moment'];

  function SpecialOperatingHourController(moment) {
    const vm = this;
    vm.changeOperationMode = changeOperationMode;
    vm.deleteSpecialHour = deleteSpecialHour;

    function init() {
      // Add default empty schedule

      if (vm.specialHours.length > 0) {
        const lastSpecialHour = _.last(vm.specialHours);
        if (
          lastSpecialHour.isPublicHoliday ||
          lastSpecialHour.isHours ||
          lastSpecialHour.is24Hours ||
          !lastSpecialHour.isOpen
        ) {
          addSpecialHour(vm.specialHours.length - 1);
        }
      } else {
        addSpecialHour(-1);
      }
    }

    // Date range

    const minDate = moment();
    vm.dateRangeOptions = $.extend({}, App.createDateRangePickerOptions(), {
      min: minDate,
      minDate,
      max: 0,
      maxDate: 0,
    });
    vm.dateRangeOptions.ranges = {};
    vm.dateRangeOptions.eventHandlers = {
      'apply.daterangepicker': (ev) => {
        validateDate(ev.model);
      },
    };

    const publicHolidays = _.map(
      _.filter(vm.specialHours, (specialHour) => specialHour.isPublicHoliday),
      (x) => x.specialHourStartDate
    );

    function validateDate(model) {
      const specialHour = _.find(
        vm.specialHours,
        (x) =>
          x.specialHourDate.startDate === model.startDate &&
          x.specialHourDate.endDate === model.endDate
      );

      // validate public holiday fall in date range

      if (
        _.some(
          publicHolidays,
          (ph) =>
            model.startDate.toDate() <= moment(ph).toDate() &&
            moment(ph).toDate() <= model.endDate.toDate()
        )
      )
        specialHour.warning = true;
      else specialHour.warning = false;

      // Update date

      specialHour.specialHourStartDate = moment(model.startDate).format('L');
      specialHour.specialHourEndDate = moment(model.endDate).format('L');
    }

    // End date range

    function changeOperationMode(value, index) {
      switch (value) {
        case 1:
          vm.specialHours[index].isHours = true;
          vm.specialHours[index].is24Hours = false;
          vm.specialHours[index].isOpen = true;
          break;

        case 2:
          vm.specialHours[index].isHours = false;
          vm.specialHours[index].is24Hours = true;
          vm.specialHours[index].isOpen = true;
          break;

        case 3:
          vm.specialHours[index].isHours = false;
          vm.specialHours[index].is24Hours = false;
          vm.specialHours[index].isOpen = false;
          break;

        default:
          vm.specialHours[index].isHours = false;
          vm.specialHours[index].is24Hours = false;
          vm.specialHours[index].isOpen = true;
          break;
      }

      addSpecialHour(index);
    }

    function deleteSpecialHour(index) {
      if (!vm.specialHours[index].isPublicHoliday) {
        if (index < vm.specialHours.length)
          vm.specialHours = _.without(vm.specialHours, vm.specialHours[index]);

        if (vm.specialHours.length === 0) {
          addSpecialHour(-1);
        }
      }
    }

    function addSpecialHour(index) {
      if (index === vm.specialHours.length - 1) {
        vm.specialHours.push({
          id: 0,
          specialHourDate: {
            startDate: null,
            endDate: null,
          },
          specialHourStartDate: null,
          specialHourEndDate: null,
          isPublicHoliday: false,
          hours: [],
          isHours: false,
          is24Hours: false,
          isOpen: true,
        });
      }
    }

    init();
  }
})();
