(function () {
    var componentId = 'hostPanelRecruitmentPanelRequestTimelineComponent';
    var app = angular.module('app');

    function controller($stateParams, panelRequestSvc) {
        function init() {
            loadTimeline(vm.requestNumber);
        }

        function loadTimeline(requestNumber) {
            vm.loading++;
            panelRequestSvc.getPanelRequestTimeline({
                id: requestNumber
            }).success(function (data) {
                vm.snapshots = data.items;
            }).finally(function () {
                vm.loading--;
            });
        }

        var vm = this;
        vm.loading = 0;
        vm.requestNumber = $stateParams.requestNumber;
        vm.snapshots = [];

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/panelRecruitment/panelRequestTimeline/panelRequestTimeline.component.html'),
        controller: ['$stateParams', 'abp.services.app.panelRequest', controller],
        controllerAs: 'vm'
    });
})();
