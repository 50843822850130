(() => {
  angular
    .module('app')
    .controller('corporate.views.latePatientTicketSubmission', LatePatientTicketApprovalController);

  LatePatientTicketApprovalController.$inject = [
    '$stateParams',
    '$uibModal',
    '$sce',
    '$state',
    '$filter',
    'abp.services.app.latePatientTicketSubmission',
    'abp.services.app.commonLookup',
  ];

  function LatePatientTicketApprovalController(
    $stateParams,
    $modal,
    $sce,
    $state,
    $filter,
    latePatientTicketSubmissionSvc,
    commonLookupSvc
  ) {
    const vm = this;

    vm.loading = 0;
    vm.isLowBalance = false;
    vm.latePatientTicketSubmissionId = $stateParams.latePatientTicketSubmissionId;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

    vm.limitPeriod = limitPeriod;
    vm.approveOrReject = approveOrReject;

    init();

    function init() {
      // Get corporate tenancy code.

      getTenantCurrencyCode();

      vm.loading += 1;
      latePatientTicketSubmissionSvc
        .getApproval({ id: vm.latePatientTicketSubmissionId })
        .success((data) => {
          vm.lateSubmission = data;
          vm.isLowBalance = vm.lateSubmission.allowance < vm.lateSubmission.coveredAmount;
          if (vm.lateSubmission.ticketNumber) {
            const ticketUrl = $state.href(
              'patientTicketDetail',
              { ticketNumber: vm.lateSubmission.ticketNumber },
              { absolute: true }
            );
            const ticketLink =
              `<a title="View ticket" href="${ticketUrl}" style="text-decoration:underline; color:#FAFAFA;">${App.htmlEncode(vm.lateSubmission.ticketNumber)}</a>`;
            vm.ticketNumberMessage = $sce.trustAsHtml(
              App.localize('GoToXPatientTicket', ticketLink)
            );
          }
          vm.lateClaimSubmissionNarrative = App.localize('LateClaimSubmissionNarrative');

          if (vm.lateSubmission.coPay) {
            if (vm.lateSubmission.coPay.isPercentage) {
              vm.lateSubmission.coPay.title = App.localize(
                'CopayX',
                `${vm.lateSubmission.coPay.value}%`
              );
              vm.lateSubmission.coPay.text = App.localize(
                'PatientHasCopayPercentage',
                `${vm.lateSubmission.coPay.value}%`
              );
            } else {
              const valueText = $filter('currencyFormat')(
                vm.lateSubmission.coPay.value,
                vm.currencyCode
              );
              vm.lateSubmission.coPay.title = App.localize('CopayX', valueText);
              vm.lateSubmission.coPay.text = App.localize('PatientHasCopayFixedAmount', valueText);
            }
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function limitPeriod() {
      let cycle = '';
      if (vm.lateSubmission && vm.lateSubmission.allowancePeriod) {
        switch (vm.lateSubmission.allowancePeriod.cycle) {
          case 0:
            cycle = App.localize('EveryXYears', vm.lateSubmission.allowancePeriod.interval);
            break;
          case 1:
            cycle = App.localize('Yearly');
            break;
          case 2:
            cycle = App.localize('Monthly');
            break;
          case 3:
            cycle = App.localize('Daily');
            break;
          case 4:
            cycle = App.localize('PerVisit');
            break;
          case 5:
            cycle = App.localize('Every6Months');
            break;
          case 6:
            cycle = App.localize('Every4Months');
            break;
          case 7:
            cycle = App.localize('Every3Months');
            break;
          case 8:
            cycle = App.localize('Every2Months');
            break;
          // no default
        }
        cycle = cycle.toLowerCase();
      }
      return cycle;
    }

    function approveOrReject(isReject, isDeletedEmployee) {
      if (isReject) showRejectModal(isDeletedEmployee);
      else approve();
    }

    function approve() {
      abp.message.confirm(
        App.localize('ApproveLateSubmissionConfirmMessage'),
        App.localize('AreYouSure'),
        (isConfirm) =>{
          confirmApprove(isConfirm);
        }
      );
    }

    function confirmApprove(isConfirm) {
      if (isConfirm) {
        vm.loading += 1;
        latePatientTicketSubmissionSvc
          .approveSubmission({
            id: vm.latePatientTicketSubmissionId,
            coveredAmount: vm.lateSubmission.coveredAmount,
            manualPayment: vm.lateSubmission.payAndFileAmount,
          })
          .success(() => {
            abp.notify.success(App.localize('SuccessfullyApproved'));
            $state.go('patientTickets');
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    }

    function showRejectModal(isDeletedEmployee) {
      $modal
        .open({
          templateUrl: require('./reject/reject.modal.html'),
          controller: 'corporate.views.latePatientTicketSubmission.reject as vm',
          backdrop: 'static',
          resolve: {
            isDeletedEmployee() {
              return isDeletedEmployee;
            },
          },
        })
        .result.then((input) => {
          abp.message.confirm(
            App.localize('RejectLateSubmissionConfirmMessage'),
            App.localize('AreYouSure'),
            (isConfirm) => {
              if (isConfirm) {
                reject(input);
              }
            }
          );
        });
    }

    function reject(input) {
      vm.loading += 1;
      latePatientTicketSubmissionSvc
        .rejectSubmission({
          id: vm.latePatientTicketSubmissionId,
          remarks: input,
        })
        .success(() => {
          abp.notify.success(App.localize('SuccessfullyRejected'));
          $state.reload();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getTenantCurrencyCode() {
      vm.loading += 1;
      commonLookupSvc.getTenantCurrencyCode()
        .success((data) => {
          vm.currencyCode = data;
        }).finally(() => {
          vm.loading -= 1;
        })
    }
  }
})();
