(function () {
    'use strict';

    angular
        .module('app')
        .controller('host.views.doraemon.fglProcessing', ['$state', '$uibModal', '$stateParams', '$filter', 'abp.services.app.doraemon', 'moment', controller]);

    function controller($state, $modal, $stateParams, $filter, doraemonSvc, moment) {
        function init() {
            vm.letterNumber = $stateParams.letterNumber;
            getRequestDetails();
            getEmployeeBenefit();
        }

        var vm = this;

        vm.requestInfo = {};
        vm.hospitalizationBenefits = [];
        vm.openApprovalModal = openApprovalModal;
        vm.saveDraft = saveDraft;
        vm.createQuery = createQuery;
        vm.edit = edit;
        vm.cancel = cancel;
        vm.preview = preview;
        vm.loading = vm.saving = 0;

        vm.coPay = {
            isCoInsurance: false,
            isPercentage: false,
            value: 0,
            title: '',
            text: ''
        };

        vm.hasSobEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');

        function getEmployeeBenefit() {
            vm.loading++;
            doraemonSvc.getEmployeeBenefitDetails({
                id: vm.letterNumber
            }).success(function (data) {
                vm.isOpen = true;
                vm.benefit = data;

                vm.benefit.isMaternity = vm.benefit.serviceType === 8;
                vm.isMaternity = data.serviceType === 8;

                if (vm.benefit.serviceType === 7) {
                    var inpatientSettings = vm.benefit.employeeType === 0
                        ? vm.benefit.hospitalizationSetting.principalScheduleOfBenefit
                        : vm.benefit.hospitalizationSetting.dependentScheduleOfBenefit;

                    vm.benefit.coPay = null;
                    if (vm.benefit.hospitalizationCoPay) {
                        if (vm.benefit.hospitalizationCoPay.isPercentage) {
                            vm.coPay = {
                                title: App.localize('CopayX', vm.benefit.hospitalizationCoPay.value + '%'),
                                text: App.localize('CopayPercentage', vm.benefit.hospitalizationCoPay.value + '%')
                            };
                            vm.coPay.isPercentage = true;
                        } else {
                            var copayHospitalizationText = $filter('currencyFormat')(vm.benefit.hospitalizationCoPay.value, vm.currencyCode);
                            vm.coPay = {
                                title: App.localize('CopayX', copayHospitalizationText),
                                text: App.localize('CopayFixedAmount', copayHospitalizationText)
                            };
                            vm.coPay.isPercentage = false;
                        }

                        vm.coPay.isCoInsurance = vm.benefit.hospitalizationSetting.isCoInsurance;
                        vm.coPay.value = vm.benefit.hospitalizationCoPay.value;
                    }

                    vm.physicianVisitPerDay = inpatientSettings.inHospitalPhysicianVisitPerDay;
                    vm.hospitalCharges = inpatientSettings.hospitalCharges;
                    vm.surgicalFee = inpatientSettings.hospitalCharges.surgicalFee;
                    vm.anaesthetistFee = inpatientSettings.hospitalCharges.anaesthetistFee;
                    vm.operatingTheatre = inpatientSettings.hospitalCharges.operatingTheatre;
                    vm.hospitalSuppliesAndServices = inpatientSettings.hospitalCharges.hospitalSuppliesAndServices;
                    vm.ambulanceFee = inpatientSettings.ambulanceFee;
                    vm.organTransplant = inpatientSettings.organTransplant;
                    vm.medicalReport = inpatientSettings.medicalReport;

                    vm.preHospitalizationTreatment = inpatientSettings.preHospitalizationTreatment;
                    vm.specialistConsultation = inpatientSettings.specialistConsultation;
                    vm.surgicalOpinion = inpatientSettings.surgicalOpinion;
                    vm.postHospitalizationTreatment = inpatientSettings.postHospitalizationTreatment;
                    vm.followUpTreatment = inpatientSettings.followUpTreatment;
                    vm.outpatientCancerTreatment = inpatientSettings.outpatientCancerTreatment;
                    vm.outpatientKidneyDialysisTreatment = inpatientSettings.outpatientKidneyDialysisTreatment;
                    vm.roomAndBoardCharges = inpatientSettings.roomAndBoard.amount;

                    vm.inpatientSob = [
                        {
                            type: App.localize('Accomodation'),
                            settings: [
                                { name: App.localize('RoomAndBoard'), isCovered: true, isAsCharged: false, amount: inpatientSettings.roomAndBoard.amount, effectivePeriod: 'Per day' },
                                { name: App.localize('ICURoomAndBoard'), isCovered: true, isAsCharged: inpatientSettings.icuRoomAndBoard.isAsCharged, amount: inpatientSettings.icuRoomAndBoard.amount, effectivePeriod: 'Per day' },
                                { name: App.localize('ChildDailyGuardianBenefitLodgingOrBed'), isCovered: inpatientSettings.childGuardianBenefit.isCovered, isAsCharged: inpatientSettings.childGuardianBenefit.isAsCharged, amount: inpatientSettings.childGuardianBenefit.amount, effectivePeriod: 'Per day' },
                            ],
                            hasMaxDaysTime: false,
                            hasEffectivePeriod: true
                        },
                        {
                            type: App.localize('HospitalCharges'),
                            isFixedLimit: vm.hospitalCharges.isFixedLimit,
                            fixedLimitAmount: vm.hospitalCharges.fixedLimitAmount,
                            settings: {
                                physicianVisit: {
                                    isAsCharged: true, amount: null, effectivePeriod: 'Per day', maxDaysTime: vm.physicianVisitPerDay
                                },
                                surgicalFees: {
                                    isAsCharged: vm.surgicalFee.isAsCharged, amount: vm.surgicalFee.amount, effectivePeriod: 'Per day', maxDaysTime: false
                                },
                                anaesthetistFees: {
                                    isAsCharged: vm.anaesthetistFee.isAsCharged, amount: vm.anaesthetistFee.amount, effectivePeriod: 'Per day', maxDaysTime: false
                                },
                                operatingTheatre: {
                                    isAsCharged: vm.operatingTheatre.isAsCharged, amount: vm.operatingTheatre.amount, effectivePeriod: 'Per day', maxDaysTime: false
                                },
                                suppliesAndServices: {
                                    isAsCharged: vm.hospitalSuppliesAndServices.isAsCharged, amount: vm.hospitalSuppliesAndServices.amount, effectivePeriod: 'Per day', maxDaysTime: false
                                },
                                ambulanceFee: {
                                    isCovered: vm.ambulanceFee.isCovered, isAsCharged: vm.ambulanceFee.isAsCharged, amount: vm.ambulanceFee.amount, effectivePeriod: 'Per day', maxDaysTime: false
                                },
                                organTransplant: {
                                    isCovered: vm.organTransplant.isCovered, isAsCharged: vm.organTransplant.isAsCharged, amount: vm.organTransplant.amount, effectivePeriod: 'Per employment contract', maxDaysTime: vm.organTransplant.isUnlimited ? App.localize('Unlimited') : vm.organTransplant.coveredTimes
                                }
                            },
                            hasMaxDaysTime: false,
                            hasEffectivePeriod: true
                        },
                        {
                            type: App.localize('SelfPayHospitalization'),
                            settings: [
                                { name: App.localize('MedicalReport'), isCovered: true, isAsCharged: vm.medicalReport.isAsCharged, amount: vm.medicalReport.amount, effectivePeriod: null }
                            ],
                            hasMaxDaysTime: false,
                            hasEffectivePeriod: false
                        }
                    ];

                    vm.outpatientSob = [
                        {
                            type: App.localize('PreHospitalizationTreatment'),
                            settings: [
                                {
                                    name: App.localize('PreHospitalizationDiagnosisTest'),
                                    isCovered: vm.preHospitalizationTreatment.isCovered,
                                    isAsCharged: true,
                                    amount: null,
                                    effectivePeriod: App.localize('PerAdmission'), maxDaysTime: null
                                },
                                {
                                    name: App.localize('PreHospitalizationSpecialistConsultation'),
                                    isCovered: vm.preHospitalizationTreatment.isCovered,
                                    isAsCharged: true,
                                    amount: null,
                                    effectivePeriod: App.localize('PerAdmission'),
                                    maxDaysTime: vm.specialistConsultation.isUnlimited ? 'Unlimited' : vm.specialistConsultation.coveredTimes
                                },
                                {
                                    name: App.localize('SecondSurgicalOpinion'),
                                    isCovered: vm.preHospitalizationTreatment.isCovered,
                                    isAsCharged: vm.surgicalOpinion.isAsCharged,
                                    amount: vm.surgicalOpinion.amount,
                                    effectivePeriod: App.localize('PerAdmission'),
                                    maxDaysTime: vm.surgicalOpinion.isUnlimited ? 'Unlimited' : vm.surgicalOpinion.coveredTimes
                                }
                            ],
                            hasMaxDaysTime: vm.preHospitalizationTreatment.isCovered ? true : false,
                            hasEffectivePeriod: true,
                            coveredDays: vm.preHospitalizationTreatment.coveredDays
                        },
                        {
                            type: App.localize('PostHospitalizationTreatment'),
                            settings: [
                                { name: App.localize('PostHospitalizationFollowUpAndTreatment'), isCovered: vm.postHospitalizationTreatment.isCovered, isAsCharged: vm.followUpTreatment.isAsCharged, amount: vm.followUpTreatment.amount, effectivePeriod: 'Per admission' }
                            ],
                            hasMaxDaysTime: false,
                            hasEffectivePeriod: true,
                            coveredDays: vm.followUpTreatment.coveredDays
                        },
                        {
                            type: App.localize('Others'),
                            settings: [
                                { name: App.localize('OutpatientCancerTreatment'), isCovered: vm.outpatientCancerTreatment.isCovered, isAsCharged: false, amount: vm.outpatientCancerTreatment.amount, effectivePeriod: 'Per annum' },
                                { name: App.localize('OutpatientKidneyDialysisTreatment'), isCovered: vm.outpatientKidneyDialysisTreatment.isCovered, isAsCharged: false, amount: vm.outpatientKidneyDialysisTreatment.amount, effectivePeriod: 'Per annum' },
                            ],
                            hasMaxDaysTime: false,
                            hasEffectivePeriod: true
                        },
                    ];
                }
                else {
                    var maternitySetting = data.maternitySetting;

                    vm.benefit.normalDelivery = maternitySetting.normalDelivery;
                    vm.benefit.caesareanDelivery = maternitySetting.caesareanDelivery;
                    vm.benefit.normalDeliveryTwinsAndAbove = maternitySetting.normalDeliveryTwinsAndAbove;
                    vm.benefit.caesareanDeliveryTwinsAndAbove = maternitySetting.caesareanDeliveryTwinsAndAbove;

                    vm.benefit.preNatalCheckUp = maternitySetting.preNatalCheckUp;
                    vm.benefit.postNatalFollowUp = maternitySetting.postNatalFollowUp;
                    vm.benefit.miscarriage = maternitySetting.miscarriage;
                    vm.benefit.neoNatal = maternitySetting.neoNatal;

                    vm.benefit.coPay = null;
                    if (vm.benefit.maternityCoPay) {
                        if (vm.benefit.maternityCoPay.isPercentage) {
                            vm.coPay = {
                                title: App.localize('CopayX', vm.benefit.maternityCoPay.value + '%'),
                                text: App.localize('CopayPercentage', vm.benefit.maternityCoPay.value + '%')
                            };
                            vm.coPay.isPercentage = true;
                        } else {
                            var copayMaternityText = $filter('currencyFormat')(vm.benefit.maternityCoPay.value, vm.currencyCode);
                            vm.coPay = {
                                title: App.localize('CopayX', copayMaternityText),
                                text: App.localize('CopayFixedAmount', copayMaternityText)
                            };
                            vm.coPay.isPercentage = false;
                        }

                        vm.coPay.isCoInsurance = vm.benefit.maternitySetting.isCoInsurance;
                        vm.coPay.value = vm.benefit.maternityCoPay.value;
                    }

                    vm.maternitySob = [
                        {
                            header: App.localize('Delivery'),
                            settings: [
                                { name: App.localize('NormalDelivery'), isCovered: vm.benefit.normalDelivery.isCovered, isAsCharged: vm.benefit.normalDelivery.isAsCharged, amount: vm.benefit.normalDelivery.amount, effectivePeriod: vm.benefit.normalDelivery.coveredCycle == 0 ? 'Per annum' : 'Per delivery' },
                                { name: App.localize('CaesareanDelivery'), isCovered: vm.benefit.caesareanDelivery.isCovered, isAsCharged: vm.benefit.caesareanDelivery.isAsCharged, amount: vm.benefit.caesareanDelivery.amount, effectivePeriod: vm.benefit.caesareanDelivery.coveredCycle == 0 ? 'Per annum' : 'Per delivery' },
                                { name: App.localize('NormalDeliveryForTwinsAndAbove'), isCovered: vm.benefit.normalDeliveryTwinsAndAbove.isCovered, isAsCharged: vm.benefit.normalDeliveryTwinsAndAbove.isAsCharged, amount: vm.benefit.normalDeliveryTwinsAndAbove.amount, effectivePeriod: vm.benefit.normalDeliveryTwinsAndAbove.coveredCycle == 0 ? 'Per annum' : 'Per delivery' },
                                { name: App.localize('CaesareanDeliveryForTwinsAndAbove'), isCovered: vm.benefit.caesareanDeliveryTwinsAndAbove.isCovered, isAsCharged: vm.benefit.caesareanDeliveryTwinsAndAbove.isAsCharged, amount: vm.benefit.caesareanDeliveryTwinsAndAbove.amount, effectivePeriod: vm.benefit.caesareanDeliveryTwinsAndAbove.coveredCycle == 0 ? 'Per annum' : 'Per delivery' }
                            ],
                            hasEffectivePeriod: true
                        },
                        {
                            header: App.localize('PreNatalCheckUp'),
                            isCovered: vm.benefit.preNatalCheckUp.isCovered,
                            coveredDaysHeader: vm.benefit.preNatalCheckUp.coveredDays,
                            settings: [
                                { name: App.localize('PreNatalCheckUp'), isCovered: vm.benefit.preNatalCheckUp.isCovered, isAsCharged: vm.benefit.preNatalCheckUp.isAsCharged, amount: vm.benefit.preNatalCheckUp.amount, effectivePeriod: 'Per annum' }
                            ],
                            hasEffectivePeriod: true
                        },
                        {
                            header: App.localize('PostNatalFollowUp'),
                            isCovered: vm.benefit.postNatalFollowUp.isCovered,
                            coveredDaysHeader: vm.benefit.postNatalFollowUp.coveredDays,
                            settings: [
                                { name: App.localize('PostNatalFollowUp'), isCovered: vm.benefit.postNatalFollowUp.isCovered, isAsCharged: vm.benefit.postNatalFollowUp.isAsCharged, amount: vm.benefit.postNatalFollowUp.amount, effectivePeriod: 'Lifetime total' },
                                { name: App.localize('Miscarriage'), isCovered: vm.benefit.miscarriage.isCovered, isAsCharged: vm.benefit.miscarriage.isAsCharged, amount: vm.benefit.miscarriage.amount, effectivePeriod: null }
                            ],
                            hasEffectivePeriod: true,
                            pregnancyRelatedComplications: ['Disseminated Intravascular Coagulation', 'Molar Pregnancy', 'Eclampsia', 'Death of Foetus', 'Amniotic Fluid Embolism']
                        },
                        {
                            header: App.localize('NeoNatal'),
                            isCovered: vm.benefit.neoNatal.isCovered,
                            coveredDaysHeader: vm.benefit.neoNatal.coveredDays,
                            settings: [
                                { name: App.localize('NewBornNICUHDU'), isCovered: vm.benefit.neoNatal.isCovered, isAsCharged: vm.benefit.neoNatal.isAsCharged, amount: vm.benefit.neoNatal.newBornNicuAmount, effectivePeriod: 'Per annum' },
                                { name: App.localize('NewBornIncubation'), isCovered: vm.benefit.neoNatal.isCovered, isAsCharged: vm.benefit.neoNatal.isAsCharged, amount: vm.benefit.neoNatal.newBornIncubationAmount, effectivePeriod: 'Per annum' },
                                { name: App.localize('NewBornPhototherapyTreatment'), isCovered: vm.benefit.neoNatal.isCovered, isAsCharged: vm.benefit.neoNatal.isAsCharged, amount: vm.benefit.neoNatal.newBornPhotoTherapyAmount, effectivePeriod: 'Per annum' }
                            ],
                            hasEffectivePeriod: true
                        }
                    ];
                }
                vm.isEmployeeBenefitDataLoaded = true;
                vm.loading--;
            });
        }

        function getRequestDetails() {
            vm.loading++;
            doraemonSvc.getFglRequestDetails({
                id: vm.letterNumber
            }).success(function (data) {
                vm.requestInfo = data;
                vm.preEditRequestInfo = angular.copy(vm.requestInfo);

                if (vm.requestInfo.request.requestType.id === vm.requestInfo.request.finalServiceRequestType.id && vm.requestInfo.request.requestStatus === vm.requestInfo.request.approvedServiceRequestStatus.id) {
                    vm.isEdit = false;
                }
                // if not final request
                else if (vm.requestInfo.request.requestType.id !== vm.requestInfo.request.finalServiceRequestType.id) {
                    vm.isEdit = true;

                    //Set the requestType to creating a new Final Gl

                    vm.requestInfo.request.requestType = vm.requestInfo.request.finalServiceRequestType;
                }
                else {
                    vm.isEdit = false;
                }

                if (vm.requestInfo.request && !vm.requestInfo.request.newAttachments) {
                    vm.requestInfo.request.newAttachments = [];
                }

                vm.isRequestDetailsDataLoaded = true; // to stop component from loading until this api retrieves data
                vm.loading--;
            });
        }

        function getRequestInput(isApproving, approverRemarks) {
            var roomItems = [], procedureItems = [], otherItems = [], doctorProcedureItems = [], hospitalChargesItems = [];
            var ticketItems = [];

            _.each(vm.requestInfo.request.rooms, function (x) {
                _.each(x.ticketItems, function (y) {
                    if (!y.roomChargeDateRangeModel) {
                        y.roomChargeDateRangeModel = {
                            startDate: y.startDate ? y.startDate : null,
                            endDate: y.endDate ? y.endDate : null
                        };
                    }
                    y.startDate = y.roomChargeDateRangeModel.startDate;
                    y.endDate = y.roomChargeDateRangeModel.endDate;

                    // Skip add records if category is null and approving the request.

                    if (!isApproving) {
                        y.category = x.category;
                    }

                    if (typeof y.category !== 'undefined' && y.category !== null) {
                        roomItems.push(y);
                    }
                });
            });

            if (vm.hasSobEnhancement && !vm.isMaternity) {
                _.each(vm.requestInfo.request.procedures, function (x) {
                    _.each(x.ticketItems, function (y) {
                        y.startDate = y.visitDate ? moment(y.visitDate).utc() : null;
                        y.endDate = y.visitDate ? moment(y.visitDate).utc() : null;

                        // Total visit is always 1.

                        if (!y.totalVisit || y.totalVisit !== 1)
                            y.totalVisit = 1;

                        // Skip add records if doctor id is null and approving the request.

                        if (!isApproving) {
                            y.doctorId = x.doctorId;
                        }

                        if (typeof y.doctorId !== 'undefined' && y.doctorId !== null) {
                            procedureItems.push(y);
                        }
                    });
                });

                _.each(vm.requestInfo.request.doctorProcedures, function (x) {
                    _.each(x.ticketItems, function (y) {
                        if (typeof y.category !== 'undefined' && y.category !== null) {
                            doctorProcedureItems.push(y);
                        }
                    });
                });

                _.each(vm.requestInfo.request.hospitalCharges, function (x) {
                    _.each(x.ticketItems, function (y) {
                        if (typeof y.category !== 'undefined' && y.category !== null) {
                            hospitalChargesItems.push(y);
                        }
                    });
                });

                ticketItems = _.unionWith(roomItems, procedureItems, doctorProcedureItems, hospitalChargesItems);
            } else {
                _.each(vm.requestInfo.request.procedures, function (x) {
                    _.each(x.ticketItems, function (y) {
                        if (!y.procedureChargeDateRangeModel) {
                            y.procedureChargeDateRangeModel = {
                                startDate: y.startDate ? y.startDate : null,
                                endDate: y.endDate ? y.endDate : null
                            };
                        }
                        y.startDate = y.procedureChargeDateRangeModel.startDate;
                        y.endDate = y.procedureChargeDateRangeModel.endDate;

                        // Skip add records if category is null and approving the request.

                        if (!isApproving) {
                            y.category = x.category;
                        }

                        if (typeof y.category !== 'undefined' && y.category !== null) {
                            procedureItems.push(y);
                        }
                    });
                });

                _.each(vm.requestInfo.request.others, function (x) {
                    if (typeof x.category !== 'undefined' && x.category !== null) {
                        otherItems.push(x);
                    }
                });

                ticketItems = _.unionWith(roomItems, procedureItems, otherItems);
            }

            var cleanedDiagnoses = _.map(vm.requestInfo.request.diagnoses, function (d) {
                // Free-text entry has null diagnosis code.

                return {
                    code: _.isNumber(d.code) ? null : d.code,
                    description: d.description,
                    diagnosisDate: d.diagnosisDate
                }
            });

            return {
                id: vm.requestInfo.request.id,
                remarks: approverRemarks,
                isApprove: isApproving,
                request: {
                    finalBillAmount: vm.requestInfo.request.finalBillAmount,
                    admissionDate: vm.requestInfo.request.admissionDate,
                    dischargeDate: vm.requestInfo.request.dischargeDate,
                    dischargingDoctorIds: vm.requestInfo.request.dischargingDoctorIds,
                    diagnoses: cleanedDiagnoses,
                    documents: vm.requestInfo.request.documents,
                    newAttachments: vm.requestInfo.request.newAttachments,
                    medicalCertificate: vm.requestInfo.request.medicalCertificate,
                    ticketItems: ticketItems,
                    serviceRequestDocuments: vm.requestInfo.request.serviceRequestDocuments,
                    hospitalizationDays: vm.requestInfo.request.hospitalizationDays
                }
            };
        }

        function processRequest(isApproving, input) {
            var requestInput = getRequestInput(isApproving, input.remarks);
            vm.saving++;
            doraemonSvc.processFglRequest(requestInput)
                .success(function (data) {
                    if (isApproving) {
                        abp.notify.success(App.localize('Approved'));

                        if (input.printGl)
                            window.open(data.printUrl);

                        navigateToListing();
                    } else {
                        abp.notify.info(App.localize("SuccessfullySaved"));

                        vm.isEdit = false;
                        vm.requestInfo.request.printUrl = data.printUrl;
                        $state.reload();
                    }

                }).finally(function () {
                    vm.saving--;
                });
        }

        function approve(remarks) {
            processRequest(true, remarks);
        }

        function createQuery() {
            if (!vm.isEdit) {
                $state.go('host.doraemonCreateQuery', { letterNumber: vm.letterNumber });
            } else {
                promptEditModeWarning();
            }
        }

        function openApprovalModal() {
            if (!vm.isEdit) {
                $modal.open({
                    templateUrl: require('/App/host/views/doraemon/common/remarks/remarks.modal.html'),
                    controller: 'host.views.doraemon.common.remarksModal as vm',
                    backdrop: 'static',
                    resolve: {
                        title: function () {
                            return App.localize('ApprovalConfirmation');
                        },
                    }
                }).result.then(function (remarks) {
                    approve(remarks);
                });
            } else {
                promptEditModeWarning();
            }
        }

        function promptEditModeWarning() {
            abp.message.error(App.localize('SaveOrCancelChangesNarrative'));
        }

        function navigateToListing() {
            $state.go('host.doraemonGl');
        }

        function edit() {
            vm.isEdit = true;
        }

        function saveDraft() {
            if (App.isFormValid(vm.fglProcessingForm)) {
                var input = {
                    remarks: '',
                    printGl: false
                }

                processRequest(false, input);
                vm.isEdit = false;
            }
        }

        function cancel() {
            getRequestDetails();
            vm.isEdit = false;
        }

        function preview() {
            if (!vm.isEdit) {
                window.open(vm.requestInfo.request.printUrl);
            } else {
                promptEditModeWarning();
            }
        }

        init();
    }
})();
