(function () {
    angular
        .module('app')
        .controller('host.views.employees.getAdmissionRecordsModal',
            ['$state', '$uibModalInstance', 'claimType', 'employeeId', controller]);

    function controller($state, $uibModalInstance, claimType, employeeId) {
        function init() {
            vm.hasRecords = null;
        }
        var vm = this;
        vm.toggleAdmissionRecords = toggleAdmissionRecords;
        vm.loading = 0;
        vm.employeeId = employeeId;
        vm.claimType = claimType;
        vm.previousAdmissions = [];

        vm.cancel = function () {
            $uibModalInstance.dismiss();
        };

        vm.submitClaim = function () {
            _.each(vm.previousAdmissions, function (record) {
                if (record.ticket.isSelected === "true") {
                    vm.selectedAdmission = record;
                }
            });

            $uibModalInstance.dismiss();
            $state.go('host.createInpatientNprClaim', {
                claimType: vm.claimType,
                employeeId: vm.employeeId,
                ticketNumber: vm.hasRecords
                    ? vm.selectedAdmission.ticket.ticketNumber
                    : null
            });
        };

        function toggleAdmissionRecords(hasRecords) {
            vm.hasRecords = null;
            vm.hasRecords = (vm.hasRecords = hasRecords)
                ? true
                : false;
        }

        init();
    }
})();
