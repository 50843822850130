import Diff from '../../../../lib/diff/diff';
import Value from '../../../../lib/diff/value';

(() => {
  angular
    .module('app')
    .controller(
      'common.views.patientTickets.compareUtilizationModal',
      CompareUtilizationModalController
    );

  CompareUtilizationModalController.$inject = [
    '$filter',
    '$uibModalInstance',
    'auditlogs',
    'moment',
  ];

  function CompareUtilizationModalController($filter, $uibModalInstance, auditlogs, moment) {
    const vm = this;

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.auditlogs = auditlogs;
    vm.differences = {};

    vm.getModalTitle = getModalTitle;
    vm.close = close;

    init();

    function init() {
      if (vm.auditlogs.length !== 2) {
        $uibModalInstance.close();
      }
      mergeAuditlogs();
    }

    function getModalTitle() {
      return App.localize(
        'ComparisonBetweenXAndY',
        moment(vm.auditlogs[0].creationTime).format('L LT'),
        moment(vm.auditlogs[1].creationTime).format('L LT')
      );
    }

    let diffs = [];
    function mergeAuditlogs() {
      const diff = {
        item: {},
        adjustments: {},
      };
      diffs = [];

      vm.auditlogs = _.sortBy(vm.auditlogs, [(o) => o.creationTime]);

      // Construct diff.

      let idx = 0;
      _.forIn(vm.auditlogs, (al) => {
        generateDiffs(al, diff.item, idx);
        generateDiffsAdjustments(al.adjustments, diff.adjustments, idx);

        idx += 1;
      });

      // Trigger differences check.

      _.forEach(diffs, (v) => {
        v.checkDifference(vm.auditlogs.length);
      });

      vm.differences = diff;
    }

    function generateDiffs(items, diff, idx) {
      const d = diff;
      _.forIn(items, (value, key) => {
        if (value == null || value === undefined) {
          return;
        }

        // Only process the following properties for comparison.

        if (key === 'relatedPatientTicket' || key === 'remarks') {
          // Capitalize key for localisation.

          const Key = _.upperFirst(key);

          // Insert unique key in diff.

          const newDiff = new Diff(Key);
          diffs.push(newDiff);
          if (!d[Key]) d[Key] = newDiff;

          // Populate dummy.

          while (d[Key].data.length < idx) {
            d[Key].push(new Value('', true));
          }

          // Insert actual value.

          d[Key].push(new Value(value));
        }
      });
    }

    function generateDiffsAdjustments(items, diff, idx) {
      const d = diff;
      _.forEach(items, (i) => {
        const { amount } = i;
        const label = i.adjustmentServiceTypeText;

        // Insert unique key in diff.

        const newDiff = new Diff(label);
        diffs.push(newDiff);
        if (!d[label]) d[label] = newDiff;

        // Populate dummy.

        while (d[label].data.length < idx) {
          d[label].push(new Value('', true));
        }

        // Insert actual value.

        const amountText = $filter('currencyFormat')(amount, vm.currencyCode);

        d[label].push(new Value(amountText));
      });
    }

    function close() {
      $uibModalInstance.close();
    }
  }
})();
