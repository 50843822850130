(function () {
    var controllerId = 'revenue.views.corporates.assignCorporateAgent.assignCorporateAgentModal';
    var module = angular.module('revenue');
    module.controller(controllerId, [
        '$uibModalInstance', 'abp.services.revenue.corporate', 'corporate',
        function ($uibModalInstance, corporateSvc, corporate) {
            function getAgentName(agents, agentId) {
                if (agentId) {
                    const agent = _.find(agents, { 'value': agentId });
                    return agent ? agent.name : agentId;
                }
                return App.localize('None');
            }

            function init() {
                vm.loading = true;
                corporateSvc.getCorporateAgentsForEdit({
                    id: corporate.id
                }).success(function (data) {
                    vm.input = data.corporateAgent;
                    vm.customerSuccessAgents = _.sortBy(data.customerSuccessAgents, 'name');
                }).finally(function () {
                    vm.loading = false;
                });
            }

            const vm = this;
            vm.loading = false;
            vm.saving = false;
            vm.corporate = corporate;
            vm.customerSuccessAgents = [];

            vm.save = function () {
                if (vm.saving) return;
                vm.saving = true;

                corporateSvc.updateCorporateAgents(vm.input)
                    .success(function (data) {
                        abp.notify.info(App.localize("SuccessfullySaved"));
                        $uibModalInstance.close(data);
                    }).finally(function () {
                        vm.saving = false;
                    });
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            init();
        }
    ]);
})();
