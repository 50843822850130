(() => {
  angular.module('app').filter('nl2br', nl2br);

  nl2br.$inject = [];

  function nl2br() {
    return nl2brFilter;

    function nl2brFilter(data) {
      if (!data) return data;
      return data.replace(/\r?\n/g, '<br />');
    }
  }
})();
