(() => {
  angular
    .module('app')
    .controller('common.views.guaranteeLetters.index', GuaranteeLetterPageController);

  GuaranteeLetterPageController.$inject = [
    '$scope',
    '$stateParams',
    '$state',
    '$uibModal',
    '$timeout',
    'abp.services.app.guaranteeLetter',
    'Hms.GuaranteeLetters.GuaranteeLetterStatus',
    'Hms.GuaranteeLetters.GuaranteeLetterSource',
    'moment',
    'appConstants',
  ];

  function GuaranteeLetterPageController(
    $scope,
    $stateParams,
    $state,
    $modal,
    $timeout,
    guaranteeLetterSvc,
    enumGuaranteeLetterStatus,
    enumGuaranteeLetterSource,
    moment,
    appConstants
  ) {
    const vm = this;

    vm.loading = 0;
    vm.exporting = 0;
    vm.isHost = App.isHost();
    vm.isCorporate = App.isCorporate();
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

    vm.permissions = {
      createGuaranteeLetter: abp.auth.isGranted('GuaranteeLetters.Corporate.Create'),
      advance: abp.auth.isGranted('GuaranteeLetters.Corporate.Advance'),
    };

    vm.enums = {
      guaranteeLetterStatus: enumGuaranteeLetterStatus,
      guaranteeLetterSource: enumGuaranteeLetterSource,
    };

    vm.requestParams = {
      filter: $stateParams.letterNumber,
      claimNumber: $stateParams.claimNumber,
      ticketNumber: $stateParams.ticketNumber,
      status: $stateParams.status,
      clinicLocationId: $stateParams.clinicLocation,
      employee: $stateParams.employee,
      company: $stateParams.company,
      skipCount: $stateParams.skipCount || 0,
      maxResultCount: $stateParams.maxResultCount || appConstants.grid.defaultPageSize,
      sorting: $stateParams.sorting,
    };
    vm.advancedFiltersAreShown = false;

    vm.approvalDateRangeOptions = App.createDateRangePickerOptions();
    vm.approvalDateRangeModel = {
      startDate: $stateParams.letterStartDate ? moment($stateParams.letterStartDate) : null,
      endDate: $stateParams.letterEndDate ? moment($stateParams.letterEndDate) : null,
    };

    vm.appliedDateRangeOptions = App.createDateRangePickerOptions();
    vm.appliedDateRangeModel = {
      startDate: $stateParams.appliedStartDate ? moment($stateParams.appliedStartDate) : null,
      endDate: $stateParams.appliedEndDate ? moment($stateParams.appliedEndDate) : null,
    };
    vm.clinicLocations = [];

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSizes,
      paginationPageSize: appConstants.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      resizable: true,
      columnDefs: [
        {
          name: 'Actions',
          displayName: 'Actions',
          enableSorting: false,
          width: 75,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'viewTemplate',
        },
        {
          displayName: App.localize('GuaranteeLetterNumber'),
          field: 'letterNumber',
          minWidth: 100,
          width: 150,
        },
        {
          displayName: App.localize('AppliedBy'),
          enableSorting: false,
          field: 'source',
          visible: vm.isHost,
          minWidth: 120,
          cellTemplate: 'appliedByTemplate',
        },
        {
          displayName: App.localize('Status'),
          field: 'status',
          minWidth: 120,
          cellTemplate: 'statusTemplate',
        },
        {
          displayName: App.localize('Aging'),
          enableSorting: false,
          field: 'aging',
          visible: vm.isHost,
          minWidth: 60,
        },
        {
          displayName: App.localize('ApprovalDate'),
          field: 'approvalDate',
          minWidth: 120,
          cellTemplate: 'approvalDateTemplate',
        },
        {
          displayName: App.localize('ExtendedDate'),
          field: 'extendedDate',
          minWidth: 80,
        },
        {
          displayName: App.localize('ClaimNumber'),
          field: 'claimNumber',
          visible: vm.isHost,
          minWidth: 100,
          width: 150,
        },
        {
          displayName: App.localize('TicketNumber'),
          field: 'patientTicketTicketNumber',
          minWidth: 100,
          width: 150,
          cellTemplate: 'ticketNumberTemplate',
        },
        {
          displayName: App.localize('PanelLocation'),
          enableSorting: false,
          field: 'panelLocationName',
          minWidth: 120,
        },
        {
          displayName: App.localize('Patient'),
          enableSorting: false,
          field: 'employeeName',
          minWidth: 120,
          cellTemplate: 'employeeTemplate',
        },
        {
          displayName: App.localize('Principal'),
          enableSorting: false,
          field: 'employeePrincipalName',
          minWidth: 120,
          cellTemplate: 'employeePrincipalTemplate',
        },
        {
          displayName: App.localize('Company'),
          enableSorting: false,
          field: 'corporateName',
          visible: vm.isHost,
          minWidth: 120,
          cellTemplate: 'companyTemplate',
        },
        {
          displayName: App.localize('Subsidiary'),
          enableSorting: false,
          field: 'employeeSubsidiaryName',
          minWidth: 120,
        },
        {
          displayName: App.localize('AppliedTime'),
          enableSorting: false,
          field: 'creationTime',
          minWidth: 120,
          cellTemplate: 'appliedTimeTemplate'
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          vm.requestParams.sorting =
            callback.length && callback[0].field
              ? `${callback[0].field} ${callback[0].sort.direction}`
              : null;
          vm.getGuaranteeLetters();
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, vm.getGuaranteeLetters)
        );
      },
      data: [],
    };

    vm.getGuaranteeLetters = getGuaranteeLetters;
    vm.exportToExcel = exportToExcel;
    vm.createGuaranteeLetter = createGuaranteeLetter;
    vm.getGuaranteeLetterSourceLocalizedString = getGuaranteeLetterSourceLocalizedString;
    vm.getDateTimeString = getDateTimeString;

    init();

    function init() {
      if (!vm.isHost && !vm.permissions.advance) vm.gridOptions.columnDefs.shift();

      loadClinicLocationsFilter();

      $timeout(() => {
        vm.gridOptions.paginationCurrentPage = Math.floor(
          vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1
        );
      });
    }

    function loadClinicLocationsFilter() {
      vm.loading += 1;
      guaranteeLetterSvc
        .findClinicLocations()
        .success((data) => {
          vm.clinicLocations = data.items;
        })
        .finally(() => {
          if (!vm.clinicLocations.length) {
            vm.requestParams.clinicLocationId = null;
          }
          vm.loading -= 1;
        });
    }

    function getRequestInput() {
      const input = $.extend({}, vm.requestParams);
      input.approvalStartDate = vm.approvalDateRangeModel.startDate;
      input.approvalEndDate = vm.approvalDateRangeModel.endDate;
      input.appliedStartDate = vm.appliedDateRangeModel.startDate;
      input.appliedEndDate = vm.appliedDateRangeModel.endDate;

      registerStateParams();

      return input;
    }

    function getGuaranteeLetters() {
      const input = getRequestInput();

      vm.loading += 1;
      guaranteeLetterSvc
        .getGuaranteeLetters(input)
        .success((data) => {
          vm.gridOptions.totalItems = data.totalCount;
          vm.gridOptions.data = _.map(data.items, (d) =>
            $.extend(d, {
              isMalaysian: d.patientEmployeeNationalityCode === 'MY',
              isActive: !(d.status === 3 || d.status === 4 || d.status === 5),
              isSelfManage: vm.isHost && d.status === 0 && d.preferSelfManageGl,
            })
          );
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function registerStateParams() {
      const approvalStartDate = vm.approvalDateRangeModel.startDate
        ? vm.approvalDateRangeModel.startDate.format('YYYY-MM-DD')
        : null;
      const approvalEndDate = vm.approvalDateRangeModel.endDate
        ? vm.approvalDateRangeModel.endDate.format('YYYY-MM-DD')
        : null;
      const appliedStartDate = vm.appliedDateRangeModel.startDate
        ? vm.appliedDateRangeModel.startDate.format('YYYY-MM-DD')
        : null;
      const appliedEndDate = vm.appliedDateRangeModel.endDate
        ? vm.appliedDateRangeModel.endDate.format('YYYY-MM-DD')
        : null;

      $state.transitionTo(
        $state.current,
        {
          letterNumber: vm.requestParams.filter,
          claimNumber: vm.requestParams.claimNumber,
          ticketNumber: vm.requestParams.ticketNumber,
          status: vm.requestParams.status,
          clinicLocation: vm.requestParams.clinicLocationId,
          employee: vm.requestParams.employee,
          company: vm.requestParams.company,
          approvalStartDate,
          approvalEndDate,
          appliedStartDate,
          appliedEndDate,
          skipCount: vm.requestParams.skipCount,
          maxResultCount: vm.requestParams.maxResultCount,
          sorting: vm.requestParams.sorting,
        },
        {
          notify: false,
        }
      );
    }

    function exportToExcel() {
      const input = getRequestInput();

      vm.exporting += 1;
      guaranteeLetterSvc
        .getGuaranteeLettersToExcel(input)
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.exporting -= 1;
        });
    }

    function createGuaranteeLetter() {
      $modal
        .open({
          templateUrl: require('./createModal.html'),
          controller: 'common.views.guaranteeLetters.createModal as vm',
          backdrop: 'static',
          resolve: {
            employee() {
              return null;
            },
          },
        })
        .result.then((data) => {
          if (!data.isApproved) {
            abp.message.success(
              App.localize('CompanyApplyGLSuccessNarrative'),
              App.localize('ApplicationSubmitted')
            );
          }
          vm.getGuaranteeLetters();
        });
    }

    function getGuaranteeLetterSourceLocalizedString(guaranteeLetterSource) {
      const enumMap = vm.enums.guaranteeLetterSource.values.find(
        (x) => x.id === guaranteeLetterSource
      );
      if (!enumMap) {
        return '';
      }
      return enumMap.name;
    }

    function getDateTimeString(date, timeZoneId) {
      return App.getDateTimeString(date, timeZoneId);
    }
  }
})();
