import swal from 'sweetalert';

(() => {
  angular.module('app').component('commonClaimsDetailsClaimSummaryWidget', {
    templateUrl: require('./widget.html'),
    controller: ClaimSummaryWidgetController,
    controllerAs: 'vm',
    bindings: {},
  });

  ClaimSummaryWidgetController.$inject = [
    '$state',
    '$rootScope',
    '$stateParams',
    '$uibModal',
    'abp.services.app.claim',
    'Hms.Claims.ClaimStatus',
    'Hms.Employees.EmployeeState',
    'moment'
  ];

  function ClaimSummaryWidgetController(
    $state,
    $rootScope,
    $stateParams,
    $modal,
    claimSvc,
    enumClaimStatus,
    enumEmployeeState,
    moment
  ) {
    const vm = this;

    vm.loading = 0;
    vm.saving = false;
    vm.isHost = App.isHost();
    vm.claim = null;
    vm.claimNumber = $stateParams.claimNumber;
    vm.canManageOutpatientClaim =
      abp.auth.isGranted('Host.Claims.Manage') || abp.auth.isGranted('Corporate.Claims');
    vm.canManageInpatientClaim = abp.auth.isGranted('Host.Claims.Inpatient.Manage');
    vm.hasSelfManageNpr = App.isCorporate() && abp.features.isEnabled('PreferSelfManageNPR');
    vm.canDelegate = false;
    vm.permissions = {
      manage: vm.isHost
        ? vm.canManageOutpatientClaim || vm.canManageInpatientClaim
        : vm.hasSelfManageNpr && abp.auth.isGranted('Corporate.Claims'),
      process: vm.isHost
        ? vm.canManageOutpatientClaim || vm.canManageInpatientClaim
        : abp.auth.isGranted('Corporate.Claims.OutpatientClaim.Approve'),
      reject: vm.isHost
        ? vm.canManageOutpatientClaim || vm.canManageInpatientClaim
        : abp.auth.isGranted('Corporate.Claims.OutpatientClaim.Reject'),
      viewTicket: abp.auth.isGranted('PatientTickets'),
      viewInpatient: abp.auth.isGranted('Host.Claims.Inpatient.View'),
    };

    vm.enums = {
      claimStatus: enumClaimStatus,
      employeeState: enumEmployeeState,
    };

    vm.processClaim = processClaim;
    vm.rejectClaim = rejectClaim;
    vm.delegateClaim = delegateClaim;

    init();

    function init() {
      getClaim();
    }

    function getClaim() {
      vm.loading += 1;
      claimSvc
        .getClaimDetail({
          id: $stateParams.claimNumber,
        })
        .success((data) => {
          vm.claim = data;
          vm.employeeId = data.employeeId;
          vm.claim.creationTime = moment.utc(data.creationTime);
          vm.claim.receiptDate = moment.utc(data.receiptDate);
          vm.claim.isInpatient = vm.claim.clinicType === 251;
          vm.claim.canManage = vm.claim.isInpatient
            ? vm.canManageInpatientClaim && (vm.claim.status === 0 || vm.claim.status === 6)
            : vm.canManageOutpatientClaim && (vm.claim.status === 0 || vm.claim.status === 6);

          vm.canDelegate = vm.isHost && !vm.claim.preferSelfManageNPR;

          if (!vm.isHost && vm.claim.isDelegated) {
            vm.permissions.manage = abp.auth.isGranted('Corporate.Claims');
            vm.permissions.process = abp.auth.isGranted('Corporate.Claims.DelegateClaim.Approve');
            vm.permissions.reject = abp.auth.isGranted('Corporate.Claims.DelegateClaim.Reject');
          }
        })
        .finally(() => {
          vm.loading -= 1;
          $rootScope.$broadcast('employeeId', vm.employeeId);
        });
    }

    function processClaim() {
      if (!vm.claim || vm.saving) return;

      vm.saving = true;
      $state.go('processClaim', {
        claimNumber: vm.claimNumber,
      });
    }

    function rejectClaim() {
      if (!vm.claim || vm.saving) return;

      swal(
        {
          title: App.localize('RejectClaim'),
          text: App.localize('RejectClaimWarningMessage', vm.claim.claimNumber),
          type: 'input',
          showCancelButton: true,
          closeOnConfirm: false,
          confirmButtonColor: '#1ab394',
          inputPlaceholder: App.localize('PleaseExplain'),
        },
        (inputValue) => {
          if (inputValue === false) {
            return false;
          }
          if ($.trim(inputValue || '') === '') {
            swal.showInputError(App.localize('PleaseExplain'));
            return false;
          }

          swal.close();
          vm.saving = true;
          claimSvc
            .rejectClaim({
              id: vm.claim.claimNumber,
              remarks: inputValue,
            })
            .success(() => {
              abp.notify.success(App.localize('SuccessfullyRejected'));
              claimSvc
                .getNextClaim({
                  id: null,
                })
                .success((data) => {
                  if (data.id) {
                    $state.go('claimListDetail', {
                      claimNumber: data.id,
                    });
                  } else {
                    abp.notify.info(App.localize('NoMoreUnprocessedClaims'));
                    $state.go('outpatientNpr');
                  }
                });
            })
            .finally(() => {
              vm.saving = false;
            });

          return true;
        }
      );
    }

    function delegateClaim() {
      $modal.open({
        templateUrl: require('./delegateModal.html'),
        controller: 'common.views.claims.details.claimSummary.delegateModal as vm',
        backdrop: 'static',
        resolve: {
          corporateId() {
            return vm.claim.employeeTenantId;
          },
        },
      });
    }
  }
})();
