(function () {
    'use strict';

    angular.module('app').controller('host.views.diseaseClassifications',
        ['$scope', '$uibModal', 'abp.services.app.diseaseClassification', controller]);

    function controller($scope, $modal, diseaseClassificationSvc) {
        var vm = this;
        vm.loading = 0;

        vm.permissions = {
            import: abp.auth.isGranted('Host.DiseaseClassifications.Import')
        };

        vm.element = $("#DiseaseClassificationTree");

        function init() {
            var tree = new DiseaseClassificationTree('DiseaseClassificationTree');
            tree.init();
        }

        init();

        vm.bulkUpdate = bulkUpdate;
        vm.filterDiseaseClassification = filterDiseaseClassification;

        /* Functions */
        function bulkUpdate() {
            var _this = $('#DiseaseClassificationTree').data('editData');
            var modal = $modal.open({
                templateUrl: require('/App/host/views/diseaseClassifications/import/import.modal.html'),
                controller: 'host.views.diseaseClassifications.import as vm',
                backdrop: 'static',
                size: 'lg'
            });
            modal.result.then(function () {
                _this.reload();
            });
        }

        function filterDiseaseClassification() {
            var element = $('#DiseaseClassificationTree');
            element.jstree('search', _.trim(vm.filter));
        }

        function DiseaseClassificationTree(elementId) {
            this.$tree = null;
            this.generateDisplayText = function (data) {
                return `<span>${data.code} ${data.description}</span>`;
            }
            this.getDiseaseClassificationData = function (input, callback) {
                var _this = this;

                vm.loading++;
                diseaseClassificationSvc.getDiseaseClassifications().success(function (result) {
                    var item = _.map(result.items, function (data) {
                        return {
                            id: data.code,
                            parent: data.parentCode ? data.parentCode : '#',
                            code: data.code,
                            description: data.description,
                            tags: data.tags,
                            section: data.section,
                            bodyPartId: data.bodyPartId,
                            isVisible: data.isVisible,
                            specialistDisciplineIds: data.specialistDisciplineIds,
                            inclusions: data.inclusions,
                            exclusions: data.exclusions,
                            definition: data.definition,
                            text: _this.generateDisplayText(data),
                            state: {
                                opened: true
                            }
                        };
                    });
                    callback(item);
                }).finally(function () {
                    vm.loading--;
                    _this.refresh();
                });
            }
            this.init = function () {
                var _this = this;
                var element = $('#' + elementId);
                var ref = $.jstree.reference(element);
                if (ref) {
                    ref.destroy();
                }

                _this.getDiseaseClassificationData(null, function (result) {
                    _this.$tree = element;
                    _this.$tree.data('editData', _this);

                    var plugins = ['types', 'search', 'wholerow', 'sort', 'checkExtension'];
                    _this.$tree.on('changed.jstree', function (node, action) {
                        $scope.safeApply(function () {
                            if (action.selected.length === 1) {
                                var $scope = action.instance.get_node(action.selected[0]);
                            }
                        });
                    }).jstree({
                        core: {
                            data: result,
                            multiple: false,
                            'themes': {
                                icons: false
                            },
                            check_callback: function () {
                                return true;
                            }
                        },
                        search: {
                            fuzzy: false,
                            show_only_matches: true,
                            show_only_matches_children: true
                        },
                        sort: function (itemA, itemB) {
                            var nodeA = this.get_node(itemA);
                            var nodeB = this.get_node(itemB);
                            return nodeA.original.code > nodeB.original.code ? 1 : -1;
                        },
                        plugins: plugins
                    });
                });
            };
            this.reload = function () {
                var _this = this;
                _this.getDiseaseClassificationData(null, function (data) {
                    _this.$tree.jstree(true).settings.core.data = data;
                    _this.$tree.jstree('refresh');
                });
            };
        }

        /* End of functions */
    }
})();
