(() => {
  angular.module('app').component('clinicSpecialistDashboardClaimsPendingReviewWidget', {
    templateUrl: require('./index.html'),
    controller: ClaimsPendingReviewWidgetController,
    controllerAs: 'vm',
    bindings: {
      timeZoneId: '<',
    },
  });

  ClaimsPendingReviewWidgetController.$inject = [
    'abp.services.app.specialistDashboard',
    '$state',
    '$sce',
    '$interval',
    '$scope',
    'moment',
  ];

  function ClaimsPendingReviewWidgetController(
    specialistDashboardSvc,
    $state,
    $sce,
    $interval,
    $scope,
    moment
  ) {
    const vm = this;
    vm.loading = 0;
    let isSilentLoading = 0;
    let tickerCount = 0;

    vm.permissions = {
      canViewClaim: abp.auth.isGranted('SpecialistClaims.Clinic'),
    };

    vm.hasSpecialistWorkflowEnhancement = abp.setting.getBoolean(
      'Hms.Feature.SpecialistWorkflowEnhancement'
    );

    vm.getSubmitMessage = getSubmitMessage;
    vm.$onInit = init;

    function init() {
      refresh();

      let tickerInterval = $interval(tickerJob, 1000);
      $scope.$on('$destroy', () => {
        if (angular.isDefined(tickerInterval)) {
          $interval.cancel(tickerInterval);
          tickerInterval = undefined;
        }
      });
    }

    function tickerJob() {
      tickerCount += 1;
      if (!(tickerCount % 10) && !vm.loading && !isSilentLoading) {
        refresh(true);
      }
    }

    function refresh(silentLoad) {
      if (vm.loading || isSilentLoading) return;

      if (silentLoad) isSilentLoading += 1;
      else vm.loading += 1;

      specialistDashboardSvc
        .getClaimsPendingReview({ abpHandleError: false })
        .success((data) => {
          vm.items = data.items;
        })
        .finally(() => {
          if (silentLoad) isSilentLoading -= 1;
          else vm.loading -= 1;
        });
    }

    function getSubmitMessage(claimNumber, creationTime, serviceType) {
      let state = vm.hasSpecialistWorkflowEnhancement
        ? 'specialistClaimRequestDetail'
        : 'specialistClaimDetail';

      if (serviceType === vm.enums.serviceType.PostHospitalization.id)
        state = 'postHospitalizationClaimDetail';

      const claimUrl = $state.href(
        state,
        { claimNumber, timeZoneId: vm.timeZoneId },
        { absolute: true }
      );
      const claimNumberText = vm.permissions.canViewClaim
        ? `<a href="${claimUrl}">${App.htmlEncode(claimNumber)}</a>`
        : App.htmlEncode(claimNumber);
      const creationTimeText = moment(creationTime).format('L LT');

      return $sce.trustAsHtml(
        App.localize('XSubmittedOnYMessage', claimNumberText, creationTimeText)
      );
    }
  }
})();
