(() => {
  angular
    .module('app')
    .controller('common.views.notifications.settingsModal', NotificationsSettingsModalController);

  NotificationsSettingsModalController.$inject = [
    '$uibModalInstance',
    'abp.services.app.notification',
  ];

  function NotificationsSettingsModalController($uibModalInstance, notificationSvc) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;
    vm.settings = null;

    vm.save = save;
    vm.cancel = cancel;

    init();

    function init() {
      vm.loading += 0;
      notificationSvc
        .getNotificationSettings({})
        .success((data) => {
          vm.settings = data;
        })
        .finally(() => {
          vm.loading -= 0;
        });
    }

    function save() {
      vm.saving += 1;
      notificationSvc
        .updateNotificationSettings(vm.settings)
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          $uibModalInstance.dismiss();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
