(function () {
    var componentId = 'hostDoraemonCommonInpatientTicketsDetailMedicalCertificateComponent';
    angular
        .module('app')
        .component(componentId, {
            templateUrl: require('/App/host/views/doraemon/common/inpatientTickets/detail/medicalCertificate/medicalCertificate.component.html'),
            controller: ['moment', controller],
            controllerAs: 'vm',
            bindings: {
                request: '<',
                isEdit: '<',
                isSaved: '<'
            }
        });

    function controller(moment) {
        var vm = this;
        vm.loading = 0;

        vm.getMcDurationDescription = function () {
            var description = '';
            if (vm.request.medicalCertificate) {
                var startDate = moment(vm.request.medicalCertificate.startDate);
                var endDate = moment(vm.request.medicalCertificate.endDate);
                var duration = endDate.diff(startDate, 'days') + 1;
                if (!isNaN(duration)) {
                    description = App.localize('XDays', duration);
                }
            }
            return description;
        };

        vm.issueMc = function () {
            vm.request.medicalCertificate = {
                startDate: vm.request.admissionDate,
                endDate: null,
                remarks: ''
            };
        };

        vm.removeMc = function () {
            vm.request.medicalCertificate = null;
        };
    }
})();
