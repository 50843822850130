(() => {
  angular.module('finance').component('financeBillingAccountsContainer', {
    templateUrl: require('./widget.html'),
    controller: BillingAccountsContainerController,
    controllerAs: 'vm',
  });

  BillingAccountsContainerController.$inject = ['$state', '$stateParams'];

  function BillingAccountsContainerController($state, $stateParams) {
    const vm = this;

    vm.billingAccount = null;
    vm.requestParams = {
      entityType: $stateParams.entityType,
      accountType: $stateParams.accountType,
      filter: $stateParams.filter,
      billingAccount: $stateParams.billingAccount,
      section: $stateParams.section,
    };

    vm.toggleFullLayout = toggleFullLayout;
    vm.registerStateParams = registerStateParams;

    vm.$onInit = () => toggleFullLayout(true);
    vm.$onDestroy = () => toggleFullLayout(false);

    function registerStateParams() {
      $state.transitionTo($state.current, vm.requestParams, { notify: false });
    }

    function toggleFullLayout(value) {
      if (value) {
        $('body').addClass('full-height-layout');
      } else {
        $('body').removeClass('full-height-layout');
      }
    }
  }
})();
