(function () {
    angular
        .module('app')
        .component('clinicSpecialistQueryQueryItemComponent', {
            templateUrl: require('/App/clinic/views/specialistQuery/queryItem/queryItem.component.html'),
            controller: ['abp.services.app.serviceRequestQuery', 'Hms.ServiceRequests.ServiceRequestQueryItemType', controller],
            controllerAs: 'vm',
            bindToController: 'true',
            bindings: {
                item: '<'
            }
        });

    function controller(serviceRequestQuerySvc, enumServiceRequestQueryItemType) {
        var vm = this;
        vm.exporting = 0;

        vm.downloadMedicalQueryForm = downloadMedicalQueryForm;

        vm.enums = {};
        vm.enums.serviceRequestQueryItemType = enumServiceRequestQueryItemType;

        function downloadMedicalQueryForm() {
            vm.exporting++;
            serviceRequestQuerySvc.getOutpatientMedicalQueryFormPdf({ id: vm.item.id })
                .success(function (data) {
                    App.downloadTempFile(data);
                }).finally(function () {
                    vm.exporting--;
                });
        }
    }
})();
