(() => {
  const module = angular.module('sms', ['ui.router']);
  module.config(['$stateProvider', moduleConfig]);
  function moduleConfig($stateProvider) {
    $stateProvider.state('sms', {
      abstract: true,
      template: '<div ui-view class="ui-view-wrapper"></div>',
    });

    if (abp.auth.isGranted('HealthMetrics.Sms.Logs')) {
      $stateProvider.state('sms.smsLogs', {
        url: '/sms/smsLogs?startDate&endDate',
        templateUrl: require('./views/smsLogs.page.html'),
        dynamic: true,
        params: {
          startDate: {
            value: null,
          },
          endDate: {
            value: null,
          },
        },
      });
    }

    if (abp.auth.isGranted('HealthMetrics.Sms.Sender')) {
      $stateProvider.state('sms.smsSender', {
        url: '/sms/smsSender',
        templateUrl: require('./views/smsSender.page.html'),
        dynamic: true,
        params: {},
      });
    }

    if (abp.auth.isGranted('HealthMetrics.Sms.Providers')) {
      $stateProvider.state('sms.smsProviders', {
        url: '/sms/smsProviders',
        templateUrl: require('./views/smsProviders.page.html'),
        dynamic: true,
        params: {
          skipCount: { value: 0},
          maxResultCount: { value: module.consts.grid.defaultPageSize }
        },
      });
    }
  }
})();
