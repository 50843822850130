(function () {
    var componentId = 'hostDirectorDashboardClinicStatsWidget';
    var app = angular.module('app');

    function controller(directorDashboardSvc) {
        var vm = this;
        vm.loading = false;

        function loadData() {
            vm.loading = true;
            directorDashboardSvc.getClinicStatistics()
                .success(function (data) {
                    vm.result = data;
                }).finally(function () {
                    vm.loading = false;
                });
        }

        loadData();
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/directorDashboard/clinicStatsWidget.html'),
        controller: ['abp.services.app.directorDashboard', controller],
        controllerAs: 'vm'
    });
})();
