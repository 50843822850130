(() => {
  angular
    .module('app')
    .controller('common.views.patientTickets.index', PatientTicketsPageController);

  PatientTicketsPageController.$inject = [
    '$scope',
    '$stateParams',
    '$state',
    '$uibModal',
    '$timeout',
    'abp.services.app.patientTicket',
    'abp.services.app.commonLookup',
    'lookupModal',
    'appSession',
    'Hms.Employees.EmployeeState',
    'Hms.Services.ServiceType',
    'Hms.BillingStatus',
    'moment',
    'appConstants',
    '$rootScope',
    'Hms.PatientTicketBillings.BillStatus',
  ];

  function PatientTicketsPageController(
    $scope,
    $stateParams,
    $state,
    $modal,
    $timeout,
    patientTicketSvc,
    commonLookupSvc,
    lookupModal,
    appSession,
    enumEmployeeState,
    enumServiceType,
    enumBillingStatus,
    moment,
    appConstants,
    $root,
    enumBillStatus
  ) {
    const vm = this;

    vm.dataExportRecordName = 'TicketListing';
    vm.generateButtonType = App.localize('ExportToExcel');
    vm.enqueuing = 0;
    vm.exporting = 0;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.isSubsidiary = appSession.user.subsidiaryId;
    vm.hasClinicLocation = appSession.user.clinicLocationId;
    vm.isHost = App.isHost();
    vm.isClinic = App.isClinic();
    vm.isCorporate = App.isCorporate();
    vm.isInpatient = App.isInpatient();
    vm.isOptical = vm.isClinic && appSession.tenant.clinicType === 4;
    vm.financialYears = vm.isHost
      ? _.range(moment().year(), moment().subtract(5, 'years').year(), -1)
      : _.range(
          appSession.tenant.currentFinancialYear,
          appSession.tenant.firstFinancialYear - 1,
          -1
        );
    vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');
    vm.countryCode = null;
    vm.permissions = {
      setNonReimbursementTicketEffectiveMcDays: abp.auth.isGranted(
        'PatientTickets.SetEffectiveMcDays'
      ),
      setReimbursementTicketEffectiveMcDays: abp.auth.isGranted(
        'PatientTickets.Tenant.Reimbursement.SetEffectiveMcDays'
      ),
      setNonReimbursementTicketEmployeePortion: abp.auth.isGranted(
        'PatientTickets.SetEmployeePortion'
      ),
      setReimbursementTicketEmployeePortion: abp.auth.isGranted(
        'PatientTickets.Tenant.Reimbursement.SetEmployeePortion'
      ),
      hasSpecialistModule: vm.isHost || abp.features.isEnabled('Specialist.Enabled'),
      hasPharmacyModule: vm.isHost || abp.features.isEnabled('Pharmacy.Enabled'),
      importManualTickets: abp.auth.isGranted('PatientTickets.Host.ImportManualTickets'),
      hasMemberBenefitUtilizationEnabled: abp.setting.getBoolean(
        'Hms.Feature.MemberBenefitUtilization'
      ),
    };

    vm.applicationHasInpatientModule = abp.setting.getBoolean('Hms.Feature.InpatientModule');
    vm.hasInpatientModule = false;

    vm.hasSpecialistModule = abp.features.isEnabled('Specialist.Enabled');
    vm.hasInsuranceModule = abp.setting.getBoolean('Hms.Feature.InsuranceModule');
    vm.memberBenefitUtilization = abp.setting.getBoolean('Hms.Feature.MemberBenefitUtilization');

    vm.hasAccessFinanceTransactionData = abp.setting.getBoolean(
      'Hms.Feature.AccessFinanceTransactionData'
    );

    vm.enums = {
      employeeState: enumEmployeeState,
      serviceType: enumServiceType,
      billingStatus: enumBillingStatus,
      billStatus: enumBillStatus,
    };

    vm.createCtTicketTypes = [];
    vm.clinicTypeFilters = [];

    vm.loading = 0;
    vm.requestParams = {
      ticketType: $stateParams.ticketType,
      ticketNumber: $stateParams.ticketNumber,
      transactionNumber: $stateParams.transactionNumber,
      status: $stateParams.status,
      hasMc: $stateParams.hasMc,
      clinicType: $stateParams.clinicType,
      company: $stateParams.company,
      subsidiaryIds: $stateParams.subsidiaryIds || [],
      departmentIds: $stateParams.departmentIds || [],
      clinic: $stateParams.clinic,
      clinicLocation: $stateParams.clinicLocation,
      patient: $stateParams.patient,
      doctor: $stateParams.doctor,
      minAmount: parseFloat($stateParams.minAmount) || null,
      maxAmount: parseFloat($stateParams.maxAmount) || null,
      minCoveredAmount: parseFloat($stateParams.minCoveredAmount) || null,
      maxCoveredAmount: parseFloat($stateParams.maxCoveredAmount) || null,
      minEmployeePortion: parseFloat($stateParams.minEmployeePortion) || null,
      maxEmployeePortion: parseFloat($stateParams.maxEmployeePortion) || null,
      minManualPayment: parseFloat($stateParams.minManualPayment) || null,
      maxManualPayment: parseFloat($stateParams.maxManualPayment) || null,
      skipCount: $stateParams.skipCount || 0,
      maxResultCount: $stateParams.maxResultCount || appConstants.grid.defaultPageSize,
      sorting: $stateParams.sorting,
      benefitGroupIds: $stateParams.benefitGroupIds,
      financialYear:
        $stateParams.financialYear || vm.isHost
          ? moment().year()
          : appSession.tenant.currentFinancialYear,
      patientTicketReimbursementMode: $stateParams.patientTicketReimbursementMode,
      serviceType: $stateParams.serviceType,
      insurerId: $stateParams.insurerId,
      isCoveredByInsurance: $stateParams.isCoveredByInsurance,
      masterPolicy: $stateParams.masterPolicy,
      isIdentificationNumber: $stateParams.isIdentificationNumber || 'false',
    };
    vm.employeeType = $stateParams.employeeType;
    vm.advancedFiltersAreShown = vm.requestParams.company || vm.requestParams.patient;

    // By default host can see Pre-Employee as option

    let hasPreEmploymentService = true;

    if (vm.isCorporate) {
      hasPreEmploymentService = abp.features.isEnabled('HasPreEmploymentModule');
    } else if (vm.isClinic) {
      hasPreEmploymentService =
        _.includes(appSession.user.panelLocationServiceTypes, 4) ||
        _.includes(appSession.tenant.tenantServiceTypes, 4);
    }

    vm.employeeTypes = [
      { name: App.localize('All'), value: null },
      { name: App.localize('Employee'), value: 0 },
      { name: App.localize('Dependent'), value: 1 },
    ];
    if (hasPreEmploymentService) {
      vm.employeeTypes.push({ name: App.localize('PreEmployee'), value: 2 });
    }

    vm.dateRangeOptions = App.createDateRangePickerOptions();
    vm.dateRangeModel = {
      startDate: null,
      endDate: null,
    };
    if ($stateParams.startDate) vm.dateRangeModel.startDate = moment($stateParams.startDate);
    if ($stateParams.endDate) vm.dateRangeModel.endDate = moment($stateParams.endDate);

    vm.ticketDateRangeOptions = App.createDateRangePickerOptions();
    vm.ticketDateRangeModel = {
      startDate: null,
      endDate: null,
    };
    if ($stateParams.ticketStartDate)
      vm.ticketDateRangeModel.startDate = moment($stateParams.ticketStartDate);
    if ($stateParams.ticketEndDate)
      vm.ticketDateRangeModel.endDate = moment($stateParams.ticketEndDate);

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSizes,
      paginationPageSize: appConstants.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      resizable: true,
      columnDefs: [
        {
          name: 'Actions',
          displayName: 'Actions',
          enableSorting: false,
          width: vm.isCorporate ? 100 : 75,
          headerCellTemplate: '<span></span>',
          cellTemplate: vm.isCorporate ? 'actionTemplate' : 'viewTemplate',
        },
        {
          displayName: App.localize('Country'),
          field: 'countryCode',
          width: 30,
          visible: vm.hasRegionalSystemSupport && vm.isHost,
          cellTemplate: 'countryTemplate',
        },
        {
          displayName: vm.isClinic ? App.localize('ClaimDate') : App.localize('TicketDate'),
          field: 'ticketDate',
          minWidth: 80,
        },
        {
          displayName: App.localize('CheckedInTime'),
          field: 'checkedInTime',
          cellTemplate: 'checkedInTemplate',
          minWidth: 140,
        },
        {
          displayName: App.localize('CreationTime'),
          field: 'creationTime',
          cellTemplate: 'creationTimeTemplate',
          minWidth: 140,
        },
        {
          displayName: vm.isClinic
            ? App.localize('PanelClaimNumber')
            : App.localize('TicketNumber'),
          field: 'ticketNumber',
          minWidth: 100,
          width: 170,
          cellTemplate: 'ticketNumberTemplate',
        },
        {
          displayName: App.localize('MedicalLeaveDuration'),
          field: 'effectiveMcDays',
          visible: !vm.isOptical,
          minWidth: 120,
          cellTemplate: 'mcTemplate',
        },
        {
          displayName: App.localize('Doctor'),
          enableSorting: false,
          field: 'doctorName',
          minWidth: 120,
        },
        {
          displayName: App.localize('Patient'),
          enableSorting: false,
          field: 'patientName',
          minWidth: 120,
          cellTemplate:
            '<div class="ui-grid-cell-contents">{{COL_FIELD CUSTOM_FILTERS}} <span ng-if="row.entity.patientNationalId">({{row.entity.patientNationalId | nationalIdFilter}})</span></div>',
        },
        {
          displayName: App.localize('CorporateCode'),
          enableSorting: false,
          field: 'corporateCode',
          visible: vm.isHost,
          minWidth: 80,
        },
        {
          displayName: App.localize('Subsidiary'),
          enableSorting: false,
          field: 'subsidiaryName',
          visible: vm.isHost || (vm.isCorporate && !vm.isSubsidiary),
          minWidth: 120,
        },
        {
          displayName: App.localize('Panel'),
          enableSorting: false,
          field: 'clinicName',
          visible: vm.isHost,
          minWidth: 120,
        },
        {
          displayName: App.localize('PanelCode'),
          enableSorting: false,
          field: 'clinicCode',
          visible: vm.isHost,
          minWidth: 80,
        },
        {
          displayName: App.localize('Insurer'),
          enableSorting: false,
          field: 'insurerName',
          visible: !vm.isClinic && vm.hasInsuranceModule,
          minWidth: 100,
          width: 170,
          cellTemplate: 'insurerTemplate',
        },
        {
          displayName: App.localize('PanelType'),
          field: 'clinicType',
          cellTemplate: 'clinicTypeTemplate',
          visible: vm.isHost && vm.permissions.hasInpatientModule,
          minWidth: 50,
        },
        {
          displayName: App.localize('ServiceType'),
          field: 'serviceType',
          cellTemplate: 'serviceTypeTemplate',
          visible: (vm.isHost || vm.isCorporate) && !vm.memberBenefitUtilization,
          minWidth: 50,
        },
        {
          displayName: App.localize('UtilizedBenefits'),
          field: 'utilizedBenefits',
          cellTemplate: 'utilizedBenefitsTemplate',
          visible: (vm.isHost || vm.isCorporate) && vm.memberBenefitUtilization,
          minWidth: 50,
        },
        {
          displayName: App.localize('Amount'),
          enableSorting: false,
          field: 'amount',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          minWidth: 100,
        },
        {
          displayName: App.localize('CoveredAmount'),
          enableSorting: false,
          field: 'coveredAmount',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          minWidth: 100,
        },
        {
          displayName: App.localize('UtilizedAmount'),
          enableSorting: false,
          field: 'ticketUtilizationAmount',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          visible: (vm.isHost || vm.isCorporate) && vm.memberBenefitUtilization,
          minWidth: 100,
        },
        {
          displayName: App.localize('EmployeePortion'),
          enableSorting: false,
          field: 'employeePortion',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          visible: (vm.isHost || vm.isCorporate) && !vm.memberBenefitUtilization,
          minWidth: 100,
        },
        {
          displayName: App.localize('CollectCash'),
          enableSorting: false,
          field: 'manualPayment',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          minWidth: 100,
        },
        {
          name: App.localize('PanelBilling'),
          displayName: App.localize('PanelBilling'),
          enableSorting: false,
          minWidth: 120,
          visible: vm.isHost || vm.isClinic,
          cellTemplate: 'clinicBillingTemplate',
        },
        {
          name: App.localize('CorporateBilling'),
          displayName: App.localize('CorporateBilling'),
          enableSorting: false,
          minWidth: 120,
          visible: vm.isHost || vm.isCorporate,
          cellTemplate: 'corporateBillingTemplate',
        },
        {
          displayName: App.localize('InsurerBilling'),
          enableSorting: false,
          field: 'insurerInvoiceNumber',
          visible: !vm.isClinic && vm.hasInsuranceModule,
          minWidth: 100,
          width: 170,
          cellTemplate: 'insurerBillingTemplate',
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          vm.requestParams.sorting =
            callback.length && callback[0].field
              ? `${callback[0].field} ${callback[0].sort.direction}`
              : null;
          vm.getPatientTickets();
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, vm.getPatientTickets)
        );
      },
      data: [],
    };
    let exportTemplateName = '';
    let exportFields = [];
    vm.ticketTypes = [{ name: App.localize('All'), value: null }];
    vm.clinics = [];
    vm.clinicLocations = [];
    vm.corporates = [];
    vm.doctors = [];
    vm.subsidiaries = [];
    vm.departments = [];
    let allDepartments = [];
    vm.benefitGroups = [];

    vm.lookupClinics = lookupClinics;
    vm.clinicChanged = clinicChanged;
    vm.lookupClinicLocations = lookupClinicLocations;
    vm.lookupCorporates = lookupCorporates;
    vm.corporateChanged = corporateChanged;
    vm.lookupDoctors = lookupDoctors;
    vm.lookupSubsidiaries = lookupSubsidiaries;
    vm.lookupSubsidiaryDepartments = lookupSubsidiaryDepartments;
    vm.getCorporateSubsidiaryDepartments = getCorporateSubsidiaryDepartments;
    vm.getDepartments = getDepartments;
    vm.resetDepartments = resetDepartments;
    vm.lookupBenefitGroups = lookupBenefitGroups;
    vm.getPatientTickets = getPatientTickets;
    vm.resetInsurer = resetInsurer;
    vm.invokeCustomReports = invokeCustomReports;
    vm.export = exportTickets;
    vm.exportToExcel = exportToExcel;
    vm.printList = printList;
    vm.setEffectiveMcDays = setEffectiveMcDays;
    vm.setEffectivePostHospitalizationMc = setEffectivePostHospitalizationMc;
    vm.createManualTicket = createManualTicket;
    vm.getEffectiveMcTooltip = getEffectiveMcTooltip;
    vm.getAmendmentTooltip = getAmendmentTooltip;
    vm.setEmployeePortion = setEmployeePortion;
    vm.viewCorporateChanges = viewCorporateChanges;
    vm.importManualTickets = importManualTickets;
    vm.updateHospitalizationDays = updateHospitalizationDays;
    vm.patientChanged = patientChanged;
    vm.getDateTimeString = getDateTimeString;

    init();

    function init() {
      initCts();
      if (!vm.isHost) getTenantCurrencyCode();
      if (vm.isCorporate) getTenantCountryCode();
      lookupTicketTypes();
      if (vm.hasInsuranceModule && !vm.isClinic) getInsurers();
      vm.lookupClinicLocations(null, $stateParams.clinicLocation);
      vm.lookupDoctors(null, $stateParams.doctor);
      vm.lookupClinics(null, $stateParams.clinic);
      vm.lookupCorporates(null, $stateParams.company);
      initCustomReports();
      if (vm.isCorporate) {
        vm.hasInsuranceModule = vm.hasInsuranceModule && abp.features.isEnabled('HasInsurance');

        vm.lookupBenefitGroups();
        lookupCorporateSubsidiaries();
      }

      if (vm.isHost) {
        vm.lookupSubsidiaries(null, $stateParams.subsidiaryIds);
      }

      $timeout(() => {
        vm.gridOptions.paginationCurrentPage = Math.floor(
          vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1
        );
      });
      if (vm.hasRegionalSystemSupport) {
        getEligibleCountries();
      }

      vm.patientChanged();
    }

    function initCustomReports() {
      vm.loading += 1;
      patientTicketSvc
        .getCustomReports()
        .success((data) => {
          _.forEach(data.items, (d) => {
            d.click = () => {
              // EMPTY
            };
          });
          vm.customReports = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
    function getInsurers() {
      vm.loading += 1;
      commonLookupSvc
        .getInsurers()
        .success((data) => {
          vm.insurers = data.items;

          if (vm.isCorporate) {
            vm.insurers = _.filter(vm.insurers, (e) => vm.countryCode === e.countryCode);
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function lookupTicketTypes() {
      if (vm.isHost || vm.isCorporate) {
        vm.loading += 1;
        commonLookupSvc
          .findPatientTicketTicketTypes()
          .success((data) => {
            vm.ticketTypes = _.concat(vm.ticketTypes, data.items);
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    }

    function lookupClinics(search, id) {
      if (search || id) {
        commonLookupSvc
          .findPatientTicketClinics({
            id,
            filter: search,
            maxResultCount: 5,
          })
          .success((data) => {
            vm.clinics = data.items;
          })
          .finally(() => {
            if (!vm.clinics.length) vm.requestParams.clinic = null;
          });
      }
    }

    function clinicChanged() {
      vm.requestParams.clinicLocation = null;
      vm.requestParams.doctor = null;
      vm.clinicLocations = [];
    }

    function lookupClinicLocations(search, id) {
      if (search || id) {
        commonLookupSvc
          .findPatientTicketClinicLocations({
            clinic: vm.requestParams.clinic,
            id,
            filter: search,
            maxResultCount: 5,
          })
          .success((data) => {
            vm.clinicLocations = data.items;
          })
          .finally(() => {
            if (!vm.clinicLocations.length) vm.requestParams.clinicLocation = null;
          });
      }
    }

    function lookupCorporates(search, id) {
      if (search || id) {
        commonLookupSvc
          .findPatientTicketCorporates({
            id,
            filter: search,
            maxResultCount: 5,
          })
          .success((data) => {
            vm.corporates = data.items;
          })
          .finally(() => {
            if (!vm.corporates.length) vm.requestParams.company = null;
          });
      }
    }

    function corporateChanged() {
      vm.requestParams.subsidiaryIds = null;
      vm.subsidiaries = [];
    }

    function lookupDoctors(search, id) {
      if (search || id) {
        commonLookupSvc
          .findPatientTicketDoctors({
            id,
            filter: search,
            maxResultCount: 5,
          })
          .success((data) => {
            vm.doctors = data.items;
          })
          .finally(() => {
            if (!vm.doctors.length) vm.requestParams.doctor = null;
          });
      }
    }

    // Host look up subsidiaries and department.

    function lookupSubsidiaries(search, id) {
      if (search || id) {
        commonLookupSvc
          .findPatientTicketSubsidiaries({
            company: vm.requestParams.company,
            id,
            filter: search,
            maxResultCount: 5,
          })
          .success((data) => {
            vm.subsidiaries = data.items;
          })
          .finally(() => {
            if (!vm.subsidiaries.length) vm.requestParams.subsidiaryIds = null;
          });
      }
    }

    function lookupSubsidiaryDepartments(search, id) {
      if (search || id) {
        commonLookupSvc
          .findPatientTicketSubsidiaryDepartments({
            company: vm.requestParams.company,
            id,
            filter: search,
            maxResultCount: 5,
          })
          .success((data) => {
            vm.departments = data.items;
          })
          .finally(() => {
            if (!vm.departments.length) vm.requestParams.departmentIds = null;
          });
      }
    }

    // End host look up subsidiaries and department.

    // Corporate look up subsidiaries and department.

    function lookupCorporateSubsidiaries() {
      commonLookupSvc
        .getCorporateSubsidiaries({ getDeletedSubsidiariesOrDepartment: true })
        .success((data) => {
          vm.subsidiaries = data.items;
        });
    }

    function getCorporateSubsidiaryDepartments() {
      if (!allDepartments.length) {
        commonLookupSvc
          .getCorporateSubsidiaryDepartments({ getDeletedSubsidiariesOrDepartment: true })
          .success((data) => {
            allDepartments = data.items;
          });
      }
    }

    function getDepartments(search) {
      const subsidiaryIds = vm.isSubsidiary
        ? appSession.user.subsidiaryId
        : vm.requestParams.subsidiaryIds;

      if (subsidiaryIds.length > 0) {
        vm.departments = [];

        // Change department filter list according to selected subsidiaries.

        _.forEach(subsidiaryIds, (id) => {
          const d = _.filter(allDepartments, { subsidiaryId: id });
          vm.departments = _.concat(vm.departments, d);
        });
      } else {
        vm.departments = allDepartments;
      }

      if (search) {
        vm.departments = _.filter(vm.departments, (d) =>
          _.includes(d.name.toLowerCase(), search.toLowerCase())
        );

        // Only return top 10 in the department list for display.

        vm.departments = _.slice(vm.departments, 0, 10);
      } else {
        vm.departments = [];
      }
    }

    function resetDepartments() {
      vm.requestParams.departmentIds = [];

      vm.departments = [];
    }

    // End corporate look up subsidiaries and department.

    function lookupBenefitGroups() {
      commonLookupSvc
        .findBenefitGroups({
          maxResultCount: 999,
        })
        .success((data) => {
          vm.benefitGroups = data.items;
        });
    }

    function initCts() {
      if (vm.isHost || vm.isCorporate) {
        vm.clinicTypeFilters.push({ value: null, name: App.localize('All') });
        vm.clinicTypeFilters.push({ value: 1, name: App.localize('GP') });
        vm.clinicTypeFilters.push({ value: 2, name: App.localize('Dental') });
        vm.clinicTypeFilters.push({ value: 4, name: App.localize('Optical') });
        if (vm.permissions.hasSpecialistModule) {
          vm.clinicTypeFilters.push({ value: 5, name: App.localize('Specialist') });
        }
        if (vm.permissions.hasPharmacyModule) {
          vm.clinicTypeFilters.push({ value: 6, name: App.localize('Pharmacy') });
        }
        if (vm.applicationHasInpatientModule) {
          if (vm.isCorporate) {
            vm.hasInpatientModule = abp.features.isEnabled('HasInpatientModule');
            if (vm.hasInpatientModule) {
              vm.clinicTypeFilters.push({ value: 7, name: App.localize('Hospital') });
            }
          } else {
            vm.clinicTypeFilters.push({ value: 7, name: App.localize('Hospital') });
          }
        }
      }
    }

    function getRequestInput(selectionFields) {
      const input = $.extend(
        {},
        vm.requestParams,
        vm.dateRangeModel,
        { exportFields: selectionFields },
        { employeeType: vm.employeeType },
        { countryCode: vm.countryCode }
      );

      if (!vm.requestParams.benefitGroupIds) input.benefitGroupIds = [];
      if (vm.dateRangeModel.startDate)
        input.startDate = vm.dateRangeModel.startDate.format('YYYY-MM-DD');
      if (vm.dateRangeModel.endDate) input.endDate = vm.dateRangeModel.endDate.format('YYYY-MM-DD');
      if (vm.ticketDateRangeModel.startDate)
        input.ticketStartDate = vm.ticketDateRangeModel.startDate.format('YYYY-MM-DD');
      if (vm.ticketDateRangeModel.endDate)
        input.ticketEndDate = vm.ticketDateRangeModel.endDate.format('YYYY-MM-DD');

      return input;
    }

    function getPatientTickets() {
      registerStateParams();

      vm.hasSupportPlan = false;
      commonLookupSvc.checkHasAccessSupportPlan().success((data) => {
        if (data) {
          vm.hasSupportPlan = true;
        }
      });

      vm.loading += 1;
      patientTicketSvc
        .getPatientTickets(getRequestInput())
        .success((data) => {
          vm.gridOptions.totalItems = data.totalCount;
          vm.gridOptions.data = _.map(data.items, (d) => {
            const isInpatientTicketFormat =
              d.clinicType === 7 &&
              d.ticketType !== 1 &&
              (typeof d.serviceType !== 'undefined' || d.serviceType !== null) &&
              (d.serviceType === 7 || d.serviceType === 8);

            return $.extend(d, {
              isNormalTicket: d.ticketType === 0,
              isManualTicket: d.ticketType === 1,
              isReimbursementTicket: d.ticketType === 2,
              isLateSubmission: d.latePatientTicketSubmissionId > 0,
              isInpatientTicketFormat,
              hasMemberUtilization: vm.memberBenefitUtilization,
              hasInsurerClaim: d.insurerId != null,
            });
          });
          exportTemplateName = data.exportTemplateName;
          exportFields = data.exportFields;
          vm.serviceTypes = data.serviceTypes;
          vm.memberClaimSubCategories = data.memberClaimSubCategories;
          vm.accountOwnerCountryCode = data.accountOwnerCountryCode;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function registerStateParams() {
      let startDate = null;
      let endDate = null;
      let ticketStartDate = null;
      let ticketEndDate = null;
      if (vm.dateRangeModel.startDate) startDate = vm.dateRangeModel.startDate.format('YYYY-MM-DD');
      if (vm.dateRangeModel.endDate) endDate = vm.dateRangeModel.endDate.format('YYYY-MM-DD');
      if (vm.ticketDateRangeModel.startDate)
        ticketStartDate = vm.ticketDateRangeModel.startDate.format('YYYY-MM-DD');
      if (vm.ticketDateRangeModel.endDate)
        ticketEndDate = vm.ticketDateRangeModel.endDate.format('YYYY-MM-DD');

      $state.transitionTo(
        $state.current,
        {
          ticketType: vm.requestParams.ticketType,
          ticketNumber: vm.requestParams.ticketNumber,
          transactionNumber: vm.requestParams.transactionNumber,
          status: vm.requestParams.status,
          hasMc: vm.requestParams.hasMc,
          clinicType: vm.requestParams.clinicType,
          company: vm.requestParams.company,
          subsidiaryIds: vm.requestParams.subsidiaryIds,
          departmentIds: vm.requestParams.departmentIds,
          clinic: vm.requestParams.clinic,
          clinicLocation: vm.requestParams.clinicLocation,
          patient: vm.requestParams.patient,
          doctor: vm.requestParams.doctor,
          employeeType: vm.employeeType,
          minAmount: vm.requestParams.minAmount,
          maxAmount: vm.requestParams.maxAmount,
          minCoveredAmount: vm.requestParams.minCoveredAmount,
          maxCoveredAmount: vm.requestParams.maxCoveredAmount,
          minEmployeePortion: vm.requestParams.minEmployeePortion,
          maxEmployeePortion: vm.requestParams.maxEmployeePortion,
          minManualPayment: vm.requestParams.minManualPayment,
          maxManualPayment: vm.requestParams.maxManualPayment,
          startDate,
          endDate,
          ticketStartDate,
          ticketEndDate,
          skipCount: vm.requestParams.skipCount,
          maxResultCount: vm.requestParams.maxResultCount,
          sorting: vm.requestParams.sorting,
          benefitGroupIds: vm.requestParams.benefitGroupIds,
          patientTicketReimbursementMode: vm.requestParams.patientTicketReimbursementMode,
          serviceType: vm.requestParams.serviceType,
          insurerId: vm.requestParams.insurerId,
          isCoveredByInsurance: vm.requestParams.isCoveredByInsurance,
          masterPolicy: vm.requestParams.masterPolicy,
          isIdentificationNumber: vm.requestParams.isIdentificationNumber,
        },
        {
          notify: false,
        }
      );
    }

    function resetInsurer() {
      if (vm.requestParams.isCoveredByInsurance !== true) {
        vm.requestParams.insurerId = null;
      }
    }

    function invokeCustomReports(customReportName) {
      const input = getRequestInput();
      input.customReportName = customReportName;
      vm.exporting += 1;
      patientTicketSvc
        .getCustomReport(input)
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.exporting -= 1;
        });
    }

    function exportTickets() {
      if (vm.gridOptions.totalItems > 5000) {
        abp.notify.warn(App.localize('TicketExportLimitExceeded'));
      } else {
        $modal
          .open({
            templateUrl: require('../common/selectionModal.html'),
            controller: 'common.views.common.selectionModal as vm',
            backdrop: 'static',
            resolve: {
              templateName() {
                return exportTemplateName;
              },
              fields() {
                return exportFields;
              },
              modalTitle() {
                return App.localize('SelectExportFields');
              },
            },
          })
          .result.then((selectedFields) => {
            const option = getRequestInput(selectedFields);
            vm.exportToExcel(option);
          });
      }
    }

    function exportToExcel(option) {
      const opt = option || getRequestInput();

      vm.enqueuing += 1;
      patientTicketSvc
        .enqueueGeneratePatientTicketExcelListingJob(opt)
        .success(() => {
          vm.refresh();
        })
        .finally(() => {
          vm.enqueuing -= 1;
        });
    }

    function printList() {
      const input = getRequestInput();
      window.open($root.resolveAppPath(`Print/PatientTickets?${$.param(input, true)}`));
    }

    function setEffectiveMcDays(data) {
      if (
        vm.permissions.setNonReimbursementTicketEffectiveMcDays ||
        vm.permissions.setReimbursementTicketEffectiveMcDays
      ) {
        const modal = $modal.open({
          templateUrl: require('./setEffectiveMcDaysModal.html'),
          controller: 'common.views.patientTickets.setEffectiveMcDaysModal as vm',
          backdrop: 'static',
          resolve: {
            ticket: data,
          },
        });
        modal.result.then(() => {
          vm.getPatientTickets();
        });
      }
    }

    function setEffectivePostHospitalizationMc(ticket) {
      if (
        vm.permissions.setNonReimbursementTicketEffectiveMcDays ||
        vm.permissions.setReimbursementTicketEffectiveMcDays
      ) {
        const modal = $modal.open({
          templateUrl: require('./setEffectivePostHospitalizationMcModal.html'),
          controller: 'common.views.patientTickets.setEffectivePostHospitalizationMcModal as vm',
          backdrop: 'static',
          resolve: {
            ticket,
          },
        });
        modal.result.then(() => {
          vm.getPatientTickets();
        });
      }
    }

    function createManualTicket(serviceType) {
      lookupModal.open({
        title: App.localize('SelectAnEmployee'),
        serviceMethod: patientTicketSvc.findEmployeesForManualTicketCreation,
        callback(employee) {
          $state.go('createEditManualTicket', {
            employeeId: employee.value,
            serviceType,
            accountOwnerCountryCode: vm.accountOwnerCountryCode,
          });
        },
      });
    }

    function getEffectiveMcTooltip(ticket) {
      return App.localize('EffectiveMcTooltip', ticket.mcDays);
    }

    function getAmendmentTooltip(ticket) {
      return App.localize(
        'AmendedAtX',
        getDateTimeString(ticket.lastModificationTime, ticket.ianaTimeZoneId)
      );
    }

    function setEmployeePortion(data) {
      if (
        vm.permissions.setReimbursementTicketEmployeePortion ||
        vm.permissions.setNonReimbursementTicketEmployeePortion
      ) {
        const modal = $modal.open({
          templateUrl: require('./setEmployeePortionModal.html'),
          controller: 'common.views.patientTickets.setEmployeePortionModal as vm',
          backdrop: 'static',
          resolve: {
            ticket: data,
          },
        });
        modal.result.then(() => {
          vm.getPatientTickets();
        });
      }
    }

    function viewCorporateChanges(data) {
      $modal.open({
        templateUrl: require('./viewCorporateChangesModal.html'),
        controller: 'common.views.patientTickets.viewCorporateChangesModal as vm',
        backdrop: 'static',
        resolve: {
          ticket: data,
        },
      });
    }

    function importManualTickets() {
      const modal = $modal.open({
        templateUrl: require('./importManualTicketsModal.html'),
        controller: 'common.views.patientTickets.importManualTicketsModal as vm',
        backdrop: 'static',
      });
      modal.result.then(() => {
        vm.getPatientTickets();
      });
    }

    function updateHospitalizationDays(data) {
      const modal = $modal.open({
        templateUrl: require('./updateHospitalizationDaysModal.html'),
        controller: 'common.views.patientTickets.updateHospitalizationDaysModal as vm',
        backdrop: 'static',
        resolve: {
          ticket: data,
        },
      });
      modal.result.then(() => {
        vm.getPatientTickets();
      });
    }

    function getTenantCurrencyCode() {
      vm.loading += 1;
      commonLookupSvc
        .getTenantCurrencyCode()
        .success((data) => {
          vm.currencyCode = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getTenantCountryCode() {
      vm.loading += 1;
      commonLookupSvc
        .getTenantCountryCode()
        .success((data) => {
          vm.countryCode = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getEligibleCountries() {
      vm.loading += 1;
      commonLookupSvc
        .getEligibleCountries()
        .success((data) => {
          vm.countries = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function patientChanged() {
      vm.searchPatientPlaceholder =
        vm.requestParams.isIdentificationNumber === 'true'
          ? App.localize('SearchByIdentificationNumber')
          : App.localize('SearchByName');
    }

    function getDateTimeString(date, timeZoneId) {
      return App.getDateTimeString(date, timeZoneId);
    }
  }
})();
