import _ from 'lodash';

(() => {
  angular.module('app').controller('common.views.users.index', UserPageController);

  UserPageController.$inject = [
    '$scope',
    '$state',
    '$timeout',
    '$uibModal',
    '$stateParams',
    'abp.services.app.user',
    'appConstants',
    'authentication'
  ];

  function UserPageController(
    $scope,
    $state,
    $timeout,
    $modal,
    $stateParams,
    userSvc,
    appConstants,
    authentication
  ) {
    const vm = this;

    vm.isHost = App.isHost();
    vm.loading = 0;
    vm.filterText = $stateParams.filterText || '';
    vm.currentUserId = abp.session.userId;
    vm.exporting = 0;

    // Assign permissions

    vm.permissions = {
      create: abp.auth.isGranted('Administration.Users.Create'),
      edit: abp.auth.isGranted('Administration.Users.Edit'),
      changePermissions: abp.auth.isGranted('Administration.Users.ChangePermissions'),
      delete: abp.auth.isGranted('Administration.Users.Delete'),
      impersonation: abp.auth.isGranted('Administration.Users.Impersonation'),
      unlock: abp.auth.isGranted('Administration.Users.Unlock'),
    };

    // Define grid options

    const viewOptions = {
      skipCount: $stateParams.skipCount,
      maxResultCount: $stateParams.maxResultCount || appConstants.grid.defaultPageSize,
      sorting: $stateParams.sorting,
    };

    vm.userGridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSizes,
      paginationPageSize: appConstants.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      columnDefs: [
        {
          name: App.localize('Actions'),
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
        },
        {
          displayName: App.localize('UserName'),
          field: 'userName',
          minWidth: 140,
        },
        {
          displayName: App.localize('Name'),
          field: 'name',
          minWidth: 120,
        },
        {
          displayName: App.localize('Roles'),
          field: 'roleNames',
          enableSorting: false,
          minWidth: 160,
        },
        {
          displayName: App.localize('EmailAddress'),
          field: 'emailAddress',
          minWidth: 200,
        },
        {
          displayName: App.localize('IsActive'),
          field: 'isActive',
          cellTemplate: 'activeTemplate',
          minWidth: 80,
        },
        {
          displayName: App.localize('PanelLocation'),
          visible: App.isClinic(),
          field: 'clinicLocationName',
          minWidth: 100,
        },
        {
          displayName: App.localize('CreationDate'),
          field: 'creationTime',
          cellFilter: "momentFormat: 'L'",
          minWidth: 100,
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (_scope, callback) => {
          let sorting = '';
          for (let i = 0; i < callback.length; i += 1) {
            if (i) sorting += ', ';
            sorting += `${callback[i].field} ${callback[i].sort.direction}`;
          }
          viewOptions.sorting = sorting;
          vm.getUsers();
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(viewOptions, vm.getUsers)
        );
      },
      data: [],
    };

    // Shift out action column if there's no edit actions

    if (
      !vm.permissions.edit &&
      !vm.permissions.changePermissions &&
      !vm.permissions.delete &&
      !vm.permissions.impersonation
    )
      vm.userGridOptions.columnDefs.shift();

    vm.getUsers = getUsers;
    vm.createUser = createUser;
    vm.editUser = editUser;
    vm.unlockUser = unlockUser;
    vm.deleteUser = deleteUser;
    vm.exportToExcel = exportToExcel;
    vm.editPermissions = editPermissions;
    vm.impersonate = impersonate;
    vm.resetPassword = resetPassword;
    vm.canDelete = canDelete;

    init();

    function init() {
      $timeout(() => {
        vm.userGridOptions.paginationCurrentPage = Math.floor(
          viewOptions.skipCount / viewOptions.maxResultCount + 1
        );
      });
    }

    function canDelete(userName) {
      return (
        userName &&
        userName !== appConstants.userManagement.defaultAdminUserName &&
        userName !== appConstants.userManagement.defaultHmsPersonnelUserName
      );
    }

    function prepareData(records) {
      for (let i = 0; i < records.length; i += 1) {
        const rec = records[i];
        rec.roleNames = _.join(_.map(rec.roles, 'roleName'), ', ');
      }
      return records;
    }

    function showEditModal(id) {
      const modal = $modal.open({
        templateUrl: require('./createOrEditModal.html'),
        controller: 'common.views.users.createOrEditModal as vm',
        backdrop: 'static',
        resolve: {
          userId() {
            return id;
          },
        },
      });
      modal.result.then(() => {
        vm.getUsers();
      });
    }

    function getUsers() {
      registerStateParams();
      vm.loading += 1;

      userSvc
        .getUsers({
          skipCount: viewOptions.skipCount,
          maxResultCount: viewOptions.maxResultCount,
          sorting: viewOptions.sorting,
          filter: vm.filterText,
        })
        .success((data) => {
          vm.userGridOptions.totalItems = data.totalCount;
          vm.userGridOptions.data = prepareData(data.items);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function registerStateParams() {
      $state.transitionTo(
        $state.current,
        {
          filterText: vm.filterText,
          skipCount: viewOptions.skipCount,
          maxResultCount: viewOptions.maxResultCount,
          sorting: viewOptions.sorting,
        },
        {
          notify: false,
        }
      );
    }

    function createUser() {
      showEditModal();
    }

    function editUser(user) {
      showEditModal(user.id);
    }

    function unlockUser(user) {
      userSvc
        .unlockUser({
          id: user.id,
        })
        .success(() => {
          abp.notify.success(App.localize('UserSuccessfullyUnlocked'));
        });
    }

    function deleteUser(user) {
      const localizationKey = vm.isHost
        ? 'HostUserDeleteWarningMessage'
        : 'UserDeleteWarningMessage';

      abp.message.confirm(
        App.localize(localizationKey, user.userName),
        App.localize('AreYouSure'),
        (result) => {
          if (result) {
            userSvc
              .deleteUser({
                id: user.id,
              })
              .success(() => {
                vm.getUsers();
                abp.notify.success(App.localize('SuccessfullyDeleted'));
              });
          }
        }
      );
    }

    function exportToExcel() {
      vm.exporting += 1;
      userSvc
        .getUsersToExcel({})
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.exporting -= 1;
        });
    }

    function editPermissions(user) {
      $modal.open({
        templateUrl: require('./permissionsModal.html'),
        controller: 'common.views.users.permissionsModal as vm',
        backdrop: 'static',
        resolve: {
          user() {
            return user;
          },
        },
      });
    }

    function impersonate(user) {
      authentication.impersonate(user.identityServerUserId, user.identityServerTenantId);
    }

    function resetPassword(user) {
      $modal.open({
        templateUrl: require('./resetPassword/resetPassword.modal.html'),
        controller: 'common.views.users.resetPasswordModel as vm',
        backdrop: 'static',
        resolve: {
          user() {
            return user;
          },
        },
      });
    }
  }
})();
