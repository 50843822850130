(() => {
  angular
    .module('app')
    .controller(
      'host.views.specialistTreatmentCategories.createOrEditModal',
      SpecialistTreatmentCategoryEditModalController
    );

  SpecialistTreatmentCategoryEditModalController.$inject = [
    '$uibModalInstance',
    'abp.services.app.specialistTreatmentCategory',
    'specialistTreatmentCategoryId',
    'abp.services.app.commonLookup',
    'Hms.SpecialistTreatmentCategories.SpecialistTreatmentCategory',
  ];

  function SpecialistTreatmentCategoryEditModalController(
    $uibModalInstance,
    specialistTreatmentCategorySvc,
    specialistTreatmentCategoryId,
    commonLookupSvc,
    constsSpecialistTreatmentCategory
  ) {
    const vm = this;

    vm.constsSpecialistTreatmentCategory = constsSpecialistTreatmentCategory;
    vm.loading = 0;
    vm.saving = 0;
    vm.specialistTreatmentCategory = null;
    vm.isEdit = specialistTreatmentCategoryId;
    vm.previousName = "";
    vm.countries = [];
    vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');
    vm.save = save;
    vm.cancel = cancel;

    init();

    function init() {
      if (vm.hasRegionalSystemSupport) getEligibleCountries();

      vm.loading += 1;
      specialistTreatmentCategorySvc
        .getSpecialistTreatmentCategoryForEdit({
          id: specialistTreatmentCategoryId,
        })
        .success((data) => {
          vm.specialistTreatmentCategory = data;
          vm.previousName = data.name;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getEligibleCountries() {
      vm.loading += 1;
      commonLookupSvc
        .getEligibleCountries()
        .success((data) => {
          vm.countries = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function save() {
      if (vm.specialistTreatmentCategory.id) {
        vm.saving += 1;
        specialistTreatmentCategorySvc
          .createOrUpdateSpecialistTreatmentCategory(vm.specialistTreatmentCategory)
          .success((data) => {
            abp.notify.info(App.localize('SuccessfullySaved'));
            $uibModalInstance.close(data);
          })
          .finally(() => {
            vm.saving -= 1;
          });
      } else {
        vm.saving += 1;
        specialistTreatmentCategorySvc
          .createOrUpdateSpecialistTreatmentCategory(vm.specialistTreatmentCategory)
          .success((data) => {
            abp.notify.info(App.localize('SuccessfullySaved'));
            $uibModalInstance.close(data);
          })
          .finally(() => {
            vm.saving -= 1;
          });
      }
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
