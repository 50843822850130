(function () {
    var controllerId = 'host.views.doraemon.gl.page';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$stateParams', '$state', '$timeout', 'abp.services.app.doraemon', 'abp.services.app.commonLookup',
        'Hms.GuaranteeLetters.GuaranteeLetterStatus', 'Hms.ServiceRequests.ServiceRequestStatus', 'Hms.ServiceRequests.ServiceRequestQueryStatus',
        'Hms.ServiceRequests.ServiceRequestType', 'moment',
        function ($scope, $stateParams, $state, $timeout, doraemonSvc, commonLookupSvc, enumGuaranteeLetterStatus, enumServiceRequestStatus, enumServiceRequestQueryStatus, enumServiceRequestType, moment) {
            function init() {
                vm.loadPanelLocationsFilter();
                vm.getGuaranteeLetters();
                if (vm.hasInsuranceModule) getInsurers();
                $timeout(function () {
                    vm.gridOptions.paginationCurrentPage = Math.floor(vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1);
                });
            }

            var vm = this;
            vm.loading = vm.exporting = vm.updating = 0;
            vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
            vm.hasInsuranceModule = abp.setting.getBoolean('Hms.Feature.InsuranceModule');

            vm.enums = {};
            vm.enums.guaranteeLetterStatus = enumGuaranteeLetterStatus;
            vm.enums.serviceRequestStatus = enumServiceRequestStatus;
            vm.enums.serviceRequestQueryStatus = enumServiceRequestQueryStatus;
            vm.enums.serviceRequestType = enumServiceRequestType;

            $scope.$on('$viewContentLoaded', function () {
                App.initAjax();
            });

            vm.panelLocations = [];

            vm.requestParams = {
                employee: $stateParams.employee,
                letterNumber: $stateParams.letterNumber,
                claimNumber: $stateParams.claimNumber,
                ticketNumber: $stateParams.ticketNumber,
                status: $stateParams.status,
                issueNumber: $stateParams.issueNumber,
                requestType: $stateParams.requestType,
                panelLocationId: $stateParams.panelLocation,
                skipCount: $stateParams.skipCount || 0,
                maxResultCount: $stateParams.maxResultCount || app.consts.grid.defaultPageSize,
                sorting: $stateParams.sorting,
                insurerId: $stateParams.insurerId,
                isCoveredByInsurance: $stateParams.isCoveredByInsurance,
                masterPolicyNumber: $stateParams.masterPolicyNumber
            };

            vm.advancedFiltersAreShown = ($stateParams.requestType || $stateParams.panelLocation ||
                $stateParams.approvalStartDate || $stateParams.approvalEndDate ||
                $stateParams.scheduledAdmissionStartDate || $stateParams.scheduledAdmissionEndDate ||
                $stateParams.checkedInStartDate || $stateParams.checkedInEndDate)
                ? true
                : false;

            vm.approvalDateRangeOptions = App.createDateRangePickerOptions();
            vm.approvalDateRangeModel = {
                startDate: $stateParams.approvalStartDate ? moment($stateParams.approvalStartDate) : null,
                endDate: $stateParams.approvalEndDate ? moment($stateParams.approvalEndDate) : null
            };

            vm.scheduledDateRangeOptions = App.createDateRangePickerOptions();
            vm.scheduledDateRangeModel = {
                startDate: $stateParams.scheduledAdmissionStartDate ? moment($stateParams.scheduledAdmissionStartDate) : null,
                endDate: $stateParams.scheduledAdmissionEndDate ? moment($stateParams.scheduledAdmissionEndDate) : null
            };

            vm.checkedInDateRangeOptions = App.createDateRangePickerOptions();
            vm.checkedInDateRangeModel = {
                startDate: $stateParams.checkedInStartDate ? moment($stateParams.checkedInStartDate) : null,
                endDate: $stateParams.checkedInEndDate ? moment($stateParams.checkedInEndDate) : null
            };

            vm.gridOptions = {
                appScopeProvider: vm,
                paginationPageSizes: app.consts.grid.defaultPageSizes,
                paginationPageSize: vm.requestParams.maxResultCount,
                paginationCurrentPage: 1,
                useExternalPagination: true,
                useExternalSorting: true,
                resizable: true,
                columnDefs: getGridOptionsColumnDefs(),
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                    $scope.gridApi.core.on.sortChanged($scope, function (scope, callback) {
                        vm.requestParams.sorting = callback.length && callback[0].field ? callback[0].field + ' ' + callback[0].sort.direction : null;
                        vm.getGuaranteeLetters();
                    });
                    gridApi.pagination.on.paginationChanged($scope, App.setPagination(vm.requestParams, vm.getGuaranteeLetters));
                },
                data: []
            };

            function getGridOptionsColumnDefs() {
                var columnDefs = [{
                    name: 'Actions',
                    displayName: 'Actions',
                    enableSorting: false,
                    width: 100,
                    headerCellTemplate: '<span></span>',
                    cellTemplate: 'viewTemplate',
                    allowCellFocus: false
                }, {
                    displayName: App.localize('Patient'),
                    enableSorting: false,
                    field: 'employeeName',
                    minWidth: 120,
                    cellTemplate: 'employeeTemplate'
                }, {
                    displayName: App.localize('GuaranteeLetterStatus'),
                    field: 'status',
                    minWidth: 100,
                    cellTemplate: 'statusTemplate'
                }, {
                    displayName: App.localize('ScheduledAdmissionTime'),
                    field: 'scheduledAdmissionTime',
                    minWidth: 100,
                    cellFilter: 'momentFormat: \'L LT\''
                }, {
                    displayName: App.localize('LatestRequestType'),
                    field: 'latestRequestDescription',
                    minWidth: 100,
                    cellTemplate: 'latestRequestType'
                }, {
                    displayName: App.localize('QueryStatus'),
                    field: 'latestQueryStatus',
                    minWidth: 100,
                    cellTemplate: 'queryStatusTemplate'
                }, {
                    displayName: App.localize('ProcessedTime'),
                    enableSorting: false,
                    field: 'processedTime',
                    minWidth: 100,
                    cellFilter: 'momentFormat: \'L LT\''
                }, {
                    displayName: App.localize('Assignee'),
                    enableSorting: false,
                    field: 'processorName',
                    minWidth: 100
                }, {
                    displayName: App.localize('Aging'),
                    enableSorting: false,
                    field: 'aging',
                    minWidth: 100,
                    cellTemplate: 'agingTemplate'
                }, {
                    displayName: App.localize('PanelLocation'),
                    enableSorting: false,
                    field: 'panelLocationName',
                    minWidth: 120
                }, {
                    displayName: App.localize('Subsidiary'),
                    enableSorting: false,
                    field: 'employeeSubsidiaryName',
                    minWidth: 120
                }, {
                    displayName: App.localize('GuaranteeLetterNumber'),
                    field: 'letterNumber',
                    minWidth: 100
                }, {
                    displayName: App.localize('ClaimNumber'),
                    field: 'claimNumber',
                    minWidth: 100,
                    width: 150
                }, {
                    displayName: App.localize('TicketNumber'),
                    field: 'patientTicketTicketNumber',
                    minWidth: 100,
                    width: 150
                }, {
                    displayName: App.localize('ApprovalDate'),
                    field: 'approvalTime',
                    minWidth: 100,
                    cellFilter: 'momentFormat: \'L\''
                }, {
                    displayName: App.localize('IssueNumber'),
                    field: 'issueNumber',
                    minWidth: 100,
                    width: 150
                }, {
                    displayName: App.localize('CheckedInTime'),
                    field: 'checkedInTime',
                    minWidth: 100,
                    cellFilter: 'momentFormat: \'L\''
                }]

                if (vm.hasInsuranceModule) {
                    columnDefs.push(
                        {
                            displayName: App.localize('Insurer'),
                            field: 'insurerName',
                            minWidth: 100,
                            width: 140,
                            cellTemplate: 'insurerTemplate'
                        },
                        {
                            displayName: App.localize('PolicyNo'),
                            field: 'masterPolicyNumber',
                            minWidth: 100,
                            width: 140,
                            cellTemplate: 'policyNoTemplate'
                        }
                    );
                }

                return columnDefs;
            }

            function registerStateParams() {
                vm.input = {
                    employee: vm.requestParams.employee,
                    letterNumber: vm.requestParams.letterNumber,
                    claimNumber: vm.requestParams.claimNumber,
                    ticketNumber: vm.requestParams.ticketNumber,
                    status: vm.requestParams.status,
                    issueNumber: vm.requestParams.issueNumber,
                    latestRequestType: vm.requestParams.latestRequestType,
                    latestRequestStatus: vm.requestParams.latestRequestStatus,
                    processorId: vm.requestParams.processorId,
                    queryStatus: vm.requestParams.queryStatus,
                    panelLocation: vm.requestParams.panelLocationId,
                    approvalStartDate: vm.approvalDateRangeModel.startDate ? moment(vm.approvalDateRangeModel.startDate).format('YYYY-MM-DD') : '',
                    approvalEndDate: vm.approvalDateRangeModel.startDate ? moment(vm.approvalDateRangeModel.endDate).format('YYYY-MM-DD') : '',
                    scheduledAdmissionStartDate: vm.scheduledDateRangeModel.startDate ? moment(vm.scheduledDateRangeModel.startDate).format('YYYY-MM-DD') : '',
                    scheduledAdmissionEndDate: vm.scheduledDateRangeModel.startDate ? moment(vm.scheduledDateRangeModel.endDate).format('YYYY-MM-DD') : '',
                    checkedInStartDate: vm.checkedInDateRangeModel.startDate ? moment(vm.checkedInDateRangeModel.startDate).format('YYYY-MM-DD') : '',
                    checkedInEndDate: vm.checkedInDateRangeModel.startDate ? moment(vm.checkedInDateRangeModel.endDate).format('YYYY-MM-DD') : '',
                    skipCount: vm.requestParams.skipCount,
                    maxResultCount: vm.requestParams.maxResultCount,
                    sorting: vm.requestParams.sorting,
                    insurerId: vm.requestParams.insurerId,
                    isCoveredByInsurance: vm.requestParams.isCoveredByInsurance,
                    masterPolicyNumber: vm.requestParams.masterPolicyNumber
                };
                $state.transitionTo($state.current, vm.input, {
                    notify: false
                });
            }

            vm.loadPanelLocationsFilter = function () {
                vm.loading++;
                doraemonSvc.findClinicLocations()
                    .success(function (data) {
                        vm.panelLocations = data.items;
                    }).finally(function () {
                        if (!vm.panelLocations.length) {
                            vm.requestParams.panelLocationId = null;
                        }
                        vm.loading--;
                    });
            }

            vm.getGuaranteeLetters = function () {
                registerStateParams();

                vm.loading++;
                doraemonSvc.getGuaranteeLetters(vm.input)
                    .success(function (data) {
                        vm.gridOptions.data = data.items;
                        vm.gridOptions.totalItems = data.totalCount;
                    }).finally(function () {
                        vm.loading--;
                    });
            };

            vm.exportToExcel = function () {
                registerStateParams();

                vm.exporting++;
                doraemonSvc.getGuaranteeLettersToExcel(vm.input)
                    .success(function (data) {
                        App.downloadTempFile(data);
                    }).finally(function () {
                        vm.exporting--;
                    });
            };

            function getInsurers() {
                vm.loading++;
                commonLookupSvc.getInsurers()
                    .success(function (data) {
                        vm.insurers = data.items;
                    }).finally(function () {
                        vm.loading--;
                    });
            }

            vm.insurerManager = function () {
                if (vm.requestParams.isCoveredByInsurance != 'true') {
                    vm.requestParams.insurerId = null;
                }
            }

            init();
        }
    ]);
})();
