(() => {
  angular
    .module('finance')
    .controller(
      'finance.views.createPaymentInstructionBatchModal',
      CreatePaymentInstructionBatchModalController
    );

  CreatePaymentInstructionBatchModalController.$inject = [
    '$uibModal',
    '$uibModalInstance',
    'abp.services.finance.commonLookup',
    'Hms.Finance.PaymentInstructionCategory',
    'abp.services.finance.paymentInstruction',
    'abp.services.finance.paymentInstructionQueue',
  ];

  function CreatePaymentInstructionBatchModalController(
    $uibModal,
    $uibModalInstance,
    commonLookupSvc,
    enumPaymentInstructionCategory,
    paymentInstructionSvc,
    paymentInstructionQueueSvc
  ) {
    const vm = this;

    vm.saving = 0;
    vm.loading = 0;

    vm.category = null;
    vm.includeUnpaidTransactions = false;
    vm.transactionStartDate = null;
    vm.transactionEndDate = null;
    vm.accountOwnerId = null;
    vm.hasPaymentInstructionQueueEnhancement = false;
    
    vm.accountOwners = [];

    vm.enums = {
      paymentInstructionCategory: enumPaymentInstructionCategory,
    };

    vm.cancel = cancel;
    vm.save = save;
    vm.enqueue = enqueue;

    init();

    function init() {
      getAccountOwners();
      localizePaymentInstructionCategoryEnum();
      getSettings();
    }

    function save() {
      const confirmationMessage = App.localize(
        'CreatePaymentInstructionRequestWarningMessage',
        _.find(
          vm.enums.paymentInstructionCategory,
          (category) => category.id === vm.category
        ).displayName.toLowerCase()
      );

      abp.message.confirm(confirmationMessage, App.localize('AreYouSure'), (d) => {
        if (d) {
          vm.saving += 1;
          paymentInstructionSvc
            .createPaymentInstructions({
              category: vm.category,
              transactionStartDate: vm.transactionStartDate,
              transactionEndDate: vm.transactionEndDate,
              includeUnpaidTransactions: vm.includeUnpaidTransactions,
              accountOwnerId: vm.accountOwnerId,
            })
            .success((data) => {
              const notifier = data.count ? abp.notify.info : abp.notify.error;
              notifier(App.localize('XNewPaymentInstructionsCreated', data.count));

              if (data.warnings) {
                $uibModal.open({
                  templateUrl: require('./result.modal.html'),
                  controller: 'finance.views.resultModal as vm',
                  backdrop: 'static',
                  resolve: {
                    title() {
                      return App.localize('XNewPaymentInstructionsCreated', data.count);
                    },
                    errors() {
                      return data.warnings;
                    },
                  },
                });
              }

              $uibModalInstance.close();
            })
            .finally(() => {
              vm.saving -= 1;
            });
        }
      });
    }
    
    function enqueue() {
      const confirmationMessage = App.localize(
        'EnqueuePaymentInstructionCreationConfirmation',
        _.find(
          vm.enums.paymentInstructionCategory,
          (category) => category.id === vm.category
        ).displayName.toLowerCase()
      );

      abp.message.confirm(confirmationMessage, App.localize('AreYouSure'), (d) => {
        if (d) {
          vm.saving += 1;
          paymentInstructionQueueSvc
            .enqueueGeneratePaymentInstructionsInBatchJob({
              category: vm.category,
              transactionStartDate: vm.transactionStartDate,
              transactionEndDate: vm.transactionEndDate,
              includeUnpaidTransactions: vm.includeUnpaidTransactions,
              accountOwnerId: vm.accountOwnerId,
            })
            .success(() => {
              $uibModalInstance.close();
            })
            .finally(() => {
              vm.saving -= 1;
            });
        }
      });
    }

    function getAccountOwners() {
      commonLookupSvc.getAccountOwners().success((data) => {
        if (data) {
          vm.accountOwners = data.items;
        }
      });
    }

    function localizePaymentInstructionCategoryEnum() {
      _.forEach(vm.enums.paymentInstructionCategory, (paymentInstructionCategory) => {
        paymentInstructionCategory.displayName = App.localize(paymentInstructionCategory.name);
      });
    }

    function getSettings() {
      vm.loading += 1;
      commonLookupSvc
        .getCommonSettings()
        .success((data) => {
          vm.hasPaymentInstructionQueueEnhancement = data.paymentInstructionQueueEnhancement;
        })
        .finally();

      vm.loading -= 1;
    }


    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
