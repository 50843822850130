(function () {
    'use strict';

    angular.module('app').component('hostDoraemonCommonSidebarButtonsComponent', {
        templateUrl: require('/App/host/views/doraemon/common/sidebarButtons/sidebarButtons.component.html'),
        controller: ['$sce', '$filter', 'abp.services.app.doraemon', controller],
        controllerAs: 'vm',
        bindings: {
            letterNumber: '<',
            hospitalizationBenefits: '='
        }
    });

    function controller($sce, $filter, doraemonSvc) {
        var vm = this;

        vm.isHost = App.isHost();
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.defaultMaxAllowance = parseFloat(abp.setting.get("Hms.General.MaxAllowanceAmountLimit"));
        vm.specifiedIllnessTooltip = $sce.trustAsHtml(App.localize('SpecifiedIllnessTooltip'));
        vm.hasSobEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');

        var hospitalizationTitle = App.localize('Hospitalization');
        var maternityTitle = App.localize('Maternity');

        function init() {
            getEmployeeBenefit();
        }

        function getEmployeeBenefit() {
            vm.loading++;
            doraemonSvc.getEmployeeBenefitDetails({
                id: vm.letterNumber
            }).success(function (data) {
                vm.isOpen = true;
                vm.benefit = data;

                vm.benefit.isMaternity = vm.benefit.serviceType === 8;

                if (vm.benefit.serviceType === 7) {
                    var inpatientSettings = vm.benefit.employeeType === 0
                        ? vm.benefit.hospitalizationSetting.principalScheduleOfBenefit
                        : vm.benefit.hospitalizationSetting.dependentScheduleOfBenefit;

                    vm.benefit.coPay = null;
                    if (vm.benefit.hospitalizationCoPay) {
                        if (vm.benefit.hospitalizationCoPay.isPercentage) {
                            vm.benefit.coPay = {
                                title: App.localize('CopayX', vm.benefit.hospitalizationCoPay.value + '%'),
                                text: App.localize('CopayPercentage', vm.benefit.hospitalizationCoPay.value + '%')
                            };
                            vm.benefit.coPay.isPercentage = true;
                        } else {
                            var copayHospitalizationText = $filter('currencyFormat')(vm.benefit.hospitalizationCoPay.value, vm.currencyCode);
                            vm.benefit.coPay = {
                                title: App.localize('CopayX', copayHospitalizationText),
                                text: App.localize('CopayFixedAmount', copayHospitalizationText)
                            };
                            vm.benefit.coPay.isPercentage = false;
                        }

                        vm.benefit.coPay.isCoInsurance = vm.benefit.hospitalizationSetting.isCoInsurance;
                        vm.benefit.coPay.value = vm.benefit.hospitalizationCoPay.value;
                    }

                    vm.benefit.allowance = Math.min(vm.benefit.hospitalizationSetting.allowance, vm.defaultMaxAllowance);
                    vm.hospitalizationBenefits = vm.benefit.hospitalizationBenefits = vm.benefit.hospitalizationSetting.hospitalizationBenefits;
                    vm.benefit.displayBalancePool = [];

                    if (vm.benefit.hospitalizationSetting.balancePool && vm.benefit.hospitalizationSetting.balancePool.length > 0) {
                        _.each(vm.benefit.hospitalizationSetting.balancePool, function (pool) {
                            getDisplayBalancePool(pool, vm.benefit.displayBalancePool);
                        })
                    }

                    vm.isAccomodationDaysCombined = inpatientSettings.accomodationDaysCombined;
                    vm.roomAndBoardAccomodationDays = inpatientSettings.roomAndBoard.accomodationDays;
                    vm.icuRoomAndBoardAccomodationDays = inpatientSettings.icuRoomAndBoard.accomodationDays;

                    vm.physicianVisitPerDay = inpatientSettings.doctorCharges.inHospitalPhysicianVisitPerDay;
                    vm.physicianDaysPerYear = inpatientSettings.doctorCharges.coveredDays;
                    vm.hospitalCharges = inpatientSettings.hospitalCharges;
                    vm.surgicalFee = inpatientSettings.hospitalCharges.surgicalFee;
                    vm.anaesthetistFee = inpatientSettings.hospitalCharges.anaesthetistFee;
                    vm.operatingTheatre = inpatientSettings.hospitalCharges.operatingTheatre;
                    vm.hospitalSuppliesAndServices = inpatientSettings.hospitalCharges.hospitalSuppliesAndServices;
                    vm.ambulanceFee = inpatientSettings.ambulanceFee;
                    vm.organTransplant = inpatientSettings.organTransplant;
                    vm.medicalReport = inpatientSettings.medicalReport;

                    vm.preHospitalizationTreatment = inpatientSettings.preHospitalizationTreatment;
                    vm.specialistConsultation = inpatientSettings.specialistConsultation;
                    vm.surgicalOpinion = inpatientSettings.surgicalOpinion;
                    vm.postHospitalizationTreatment = inpatientSettings.postHospitalizationTreatment;
                    vm.followUpTreatment = inpatientSettings.followUpTreatment;
                    vm.outpatientCancerTreatment = inpatientSettings.outpatientCancerTreatment;
                    vm.outpatientKidneyDialysisTreatment = inpatientSettings.outpatientKidneyDialysisTreatment;
                    vm.roomAndBoardCharges = inpatientSettings.roomAndBoard.amount;

                    vm.inpatientSob = [];

                    vm.inpatientSob.push({
                        type: App.localize('Accomodation'),
                        settings: [
                            {
                                name: App.localize('RoomAndBoard'),
                                isCovered: true,
                                isAsCharged: false,
                                amount: inpatientSettings.roomAndBoard.amount,
                                effectivePeriod: 'Per day',
                                daysPerYearLimit: vm.roomAndBoardAccomodationDays,
                                isAccomodationDaysCombined: vm.isAccomodationDaysCombined,
                                accomodationDaysCombineText: App.localize('CombinedRoomAndBoardNarrative')
                            },
                            {
                                name: App.localize('IcuRoomAndBoard'),
                                isCovered: true,
                                isAsCharged: inpatientSettings.icuRoomAndBoard.isAsCharged,
                                amount: inpatientSettings.icuRoomAndBoard.amount,
                                effectivePeriod: 'Per day',
                                daysPerYearLimit: vm.icuRoomAndBoardAccomodationDays,
                                isAccomodationDaysCombined: vm.isAccomodationDaysCombined,
                                accomodationDaysCombineText: App.localize('CombinedIcuRoomAndBoardNarrative')
                            },
                            {
                                name: App.localize('ChildDailyGuardianBenefitLodgingOrBed'),
                                isCovered: inpatientSettings.childGuardianBenefit.isCovered,
                                isAsCharged: inpatientSettings.childGuardianBenefit.isAsCharged,
                                amount: inpatientSettings.childGuardianBenefit.amount,
                                effectivePeriod: 'Per day'
                            },
                        ],
                        hasMaxDaysTime: false,
                        hasEffectivePeriod: true,
                        hasDaysPerYear: vm.hasSobEnhancement
                    });

                    if (vm.hasSobEnhancement) {
                        vm.inpatientSob.push({
                            type: App.localize('DoctorCharges'),
                            settings: [
                                { name: App.localize('InHospitalDoctorVisit'), isCovered: true, isAsCharged: true, amount: null, effectivePeriod: 'Per day', maxDaysTime: vm.physicianVisitPerDay, isThirteenthSchedule: true, daysPerYearLimit: vm.physicianDaysPerYear }
                            ],
                            hasMaxDaysTime: true,
                            hasEffectivePeriod: true,
                            hasDaysPerYear: vm.hasSobEnhancement
                        });
                    }

                    vm.inpatientSob.push({
                        type: App.localize('HospitalCharges'),
                        isFixedLimit: vm.hospitalCharges.isFixedLimit,
                        fixedLimitAmount: vm.hospitalCharges.fixedLimitAmount,
                        settings: {
                            physicianVisit: {
                                isAsCharged: true, amount: null, effectivePeriod: 'Per day', maxDaysTime: vm.physicianVisitPerDay
                            },
                            surgicalFees: {
                                isAsCharged: vm.surgicalFee.isAsCharged, amount: vm.surgicalFee.amount, effectivePeriod: 'Per day', maxDaysTime: false
                            },
                            anaesthetistFees: {
                                isAsCharged: vm.anaesthetistFee.isAsCharged, amount: vm.anaesthetistFee.amount, effectivePeriod: 'Per day', maxDaysTime: false
                            },
                            operatingTheatre: {
                                isAsCharged: vm.operatingTheatre.isAsCharged, amount: vm.operatingTheatre.amount, effectivePeriod: 'Per day', maxDaysTime: false
                            },
                            suppliesAndServices: {
                                isAsCharged: vm.hospitalSuppliesAndServices.isAsCharged, amount: vm.hospitalSuppliesAndServices.amount, effectivePeriod: 'Per day', maxDaysTime: false
                            },
                            ambulanceFee: {
                                isCovered: vm.ambulanceFee.isCovered, isAsCharged: vm.ambulanceFee.isAsCharged, amount: vm.ambulanceFee.amount, effectivePeriod: 'Per day', maxDaysTime: false
                            },
                            organTransplant: {
                                isCovered: vm.organTransplant.isCovered, isAsCharged: vm.organTransplant.isAsCharged, amount: vm.organTransplant.amount, effectivePeriod: 'Per employment contract', maxDaysTime: vm.organTransplant.isUnlimited ? App.localize('Unlimited') : vm.organTransplant.coveredTimes
                            }
                        },
                        hasMaxDaysTime: false,
                        hasEffectivePeriod: true
                    });
                    vm.inpatientSob.push({
                        type: App.localize('SelfPayHospitalization'),
                        settings: [
                            { name: App.localize('MedicalReport'), isCovered: true, isAsCharged: vm.medicalReport.isAsCharged, amount: vm.medicalReport.amount, effectivePeriod: null }
                        ],
                        hasMaxDaysTime: false,
                        hasEffectivePeriod: false
                    });

                    vm.outpatientSob = [
                        {
                            type: App.localize('PreHospitalizationTreatment'),
                            settings: [
                                {
                                    name: App.localize('PreHospitalizationDiagnosisTest'),
                                    isCovered: vm.preHospitalizationTreatment.isCovered,
                                    isAsCharged: true,
                                    amount: null,
                                    effectivePeriod: App.localize('PerAdmission'),
                                    maxDaysTime: null
                                },
                                {
                                    name: App.localize('PreHospitalizationSpecialistConsultation'),
                                    isCovered: vm.preHospitalizationTreatment.isCovered,
                                    isAsCharged: true,
                                    amount: null,
                                    effectivePeriod: App.localize('PerAdmission'),
                                    maxDaysTime: vm.specialistConsultation.isUnlimited ? 'Unlimited' : vm.specialistConsultation.coveredTimes
                                },
                                {
                                    name: App.localize('SecondSurgicalOpinion'),
                                    isCovered: vm.preHospitalizationTreatment.isCovered,
                                    isAsCharged: vm.surgicalOpinion.isAsCharged,
                                    amount: vm.surgicalOpinion.amount,
                                    effectivePeriod: App.localize('PerAdmission'),
                                    maxDaysTime: vm.surgicalOpinion.isUnlimited ? 'Unlimited' : vm.surgicalOpinion.coveredTimes
                                }
                            ],
                            hasMaxDaysTime: vm.preHospitalizationTreatment.isCovered ? true : false,
                            hasEffectivePeriod: true,
                            coveredDays: vm.preHospitalizationTreatment.coveredDays
                        },
                        {
                            type: App.localize('PostHospitalizationTreatment'),
                            settings: [
                                { name: App.localize('PostHospitalizationFollowUpAndTreatment'), isCovered: vm.postHospitalizationTreatment.isCovered, isAsCharged: vm.followUpTreatment.isAsCharged, amount: vm.followUpTreatment.amount, effectivePeriod: 'Per admission' }
                            ],
                            hasMaxDaysTime: false,
                            hasEffectivePeriod: true,
                            coveredDays: vm.postHospitalizationTreatment.coveredDays
                        },
                        {
                            type: App.localize('Others'),
                            settings: [
                                { name: App.localize('OutpatientCancerTreatment'), isCovered: vm.outpatientCancerTreatment.isCovered, isAsCharged: false, amount: vm.outpatientCancerTreatment.amount, effectivePeriod: 'Per annum' },
                                { name: App.localize('OutpatientKidneyDialysisTreatment'), isCovered: vm.outpatientKidneyDialysisTreatment.isCovered, isAsCharged: false, amount: vm.outpatientKidneyDialysisTreatment.amount, effectivePeriod: 'Per annum' },
                            ],
                            hasMaxDaysTime: false,
                            hasEffectivePeriod: true
                        },
                    ];
                }
                else {
                    var maternitySetting = data.maternitySetting;

                    vm.benefit.normalDelivery = maternitySetting.normalDelivery;
                    vm.benefit.caesareanDelivery = maternitySetting.caesareanDelivery;
                    vm.benefit.normalDeliveryTwinsAndAbove = maternitySetting.normalDeliveryTwinsAndAbove;
                    vm.benefit.caesareanDeliveryTwinsAndAbove = maternitySetting.caesareanDeliveryTwinsAndAbove;
                    vm.benefit.preNatalCheckUp = maternitySetting.preNatalCheckUp;
                    vm.benefit.postNatalFollowUp = maternitySetting.postNatalFollowUp;
                    vm.benefit.miscarriage = maternitySetting.miscarriage;
                    vm.benefit.neoNatal = maternitySetting.neoNatal;

                    vm.benefit.coPay = null;
                    if (vm.benefit.maternityCoPay) {
                        if (vm.benefit.maternityCoPay.isPercentage) {
                            vm.benefit.coPay = {
                                title: App.localize('CopayX', vm.benefit.maternityCoPay.value + '%'),
                                text: App.localize('CopayPercentage', vm.benefit.maternityCoPay.value + '%')
                            };
                            vm.benefit.coPay.isPercentage = true;
                        } else {
                            var copayMaternityText = $filter('currencyFormat')(vm.benefit.maternityCoPay.value, vm.currencyCode);
                            vm.benefit.coPay = {
                                title: App.localize('CopayX', copayMaternityText),
                                text: App.localize('CopayFixedAmount', copayMaternityText)
                            };
                            vm.benefit.coPay.isPercentage = false;
                        }

                        vm.benefit.coPay.isCoInsurance = vm.benefit.maternitySetting.isCoInsurance;
                        vm.benefit.coPay.value = vm.benefit.maternityCoPay.value;
                    }

                    vm.benefit.maternitySob = [
                        {
                            header: App.localize('Delivery'),
                            settings: [
                                { name: App.localize('NormalDelivery'), isCovered: vm.benefit.normalDelivery.isCovered, isAsCharged: vm.benefit.normalDelivery.isAsCharged, amount: vm.benefit.normalDelivery.amount, effectivePeriod: vm.benefit.normalDelivery.coveredCycle == 0 ? 'Per annum' : 'Per delivery' },
                                { name: App.localize('CaesareanDelivery'), isCovered: vm.benefit.caesareanDelivery.isCovered, isAsCharged: vm.benefit.caesareanDelivery.isAsCharged, amount: vm.benefit.caesareanDelivery.amount, effectivePeriod: vm.benefit.caesareanDelivery.coveredCycle == 0 ? 'Per annum' : 'Per delivery' },
                                { name: App.localize('NormalDeliveryForTwinsAndAbove'), isCovered: vm.benefit.normalDeliveryTwinsAndAbove.isCovered, isAsCharged: vm.benefit.normalDeliveryTwinsAndAbove.isAsCharged, amount: vm.benefit.normalDeliveryTwinsAndAbove.amount, effectivePeriod: vm.benefit.normalDeliveryTwinsAndAbove.coveredCycle == 0 ? 'Per annum' : 'Per delivery' },
                                { name: App.localize('CaesareanDeliveryForTwinsAndAbove'), isCovered: vm.benefit.caesareanDeliveryTwinsAndAbove.isCovered, isAsCharged: vm.benefit.caesareanDeliveryTwinsAndAbove.isAsCharged, amount: vm.benefit.caesareanDeliveryTwinsAndAbove.amount, effectivePeriod: vm.benefit.caesareanDeliveryTwinsAndAbove.coveredCycle == 0 ? 'Per annum' : 'Per delivery' }
                            ],
                            hasEffectivePeriod: true
                        },
                        {
                            header: App.localize('PreNatalCheckUp'),
                            isCovered: vm.benefit.preNatalCheckUp.isCovered,
                            coveredDaysHeader: vm.benefit.preNatalCheckUp.coveredDays,
                            settings: [
                                { name: App.localize('PreNatalCheckUp'), isCovered: vm.benefit.preNatalCheckUp.isCovered, isAsCharged: vm.benefit.preNatalCheckUp.isAsCharged, amount: vm.benefit.preNatalCheckUp.amount, effectivePeriod: 'Per annum' }
                            ],
                            hasEffectivePeriod: true
                        },
                        {
                            header: App.localize('PostNatalFollowUp'),
                            isCovered: vm.benefit.postNatalFollowUp.isCovered,
                            coveredDaysHeader: vm.benefit.postNatalFollowUp.coveredDays,
                            settings: [
                                { name: App.localize('PostNatalFollowUp'), isCovered: vm.benefit.postNatalFollowUp.isCovered, isAsCharged: vm.benefit.postNatalFollowUp.isAsCharged, amount: vm.benefit.postNatalFollowUp.amount, effectivePeriod: 'Lifetime total' },
                                { name: App.localize('Miscarriage'), isCovered: vm.benefit.miscarriage.isCovered, isAsCharged: vm.benefit.miscarriage.isAsCharged, amount: vm.benefit.miscarriage.amount, effectivePeriod: null }
                            ],
                            hasEffectivePeriod: true,
                            pregnancyRelatedComplications: ['Disseminated Intravascular Coagulation', 'Molar Pregnancy', 'Eclampsia', 'Death of Foetus', 'Amniotic Fluid Embolism']
                        },
                        {
                            header: App.localize('NeoNatal'),
                            isCovered: vm.benefit.neoNatal.isCovered,
                            coveredDaysHeader: vm.benefit.neoNatal.coveredDays,
                            settings: [
                                { name: App.localize('NewBornNICUHDU'), isCovered: vm.benefit.neoNatal.isCovered, isAsCharged: vm.benefit.neoNatal.isAsCharged, amount: vm.benefit.neoNatal.newBornNicuAmount, effectivePeriod: 'Per annum' },
                                { name: App.localize('NewBornIncubation'), isCovered: vm.benefit.neoNatal.isCovered, isAsCharged: vm.benefit.neoNatal.isAsCharged, amount: vm.benefit.neoNatal.newBornIncubationAmount, effectivePeriod: 'Per annum' },
                                { name: App.localize('NewBornPhototherapyTreatment'), isCovered: vm.benefit.neoNatal.isCovered, isAsCharged: vm.benefit.neoNatal.isAsCharged, amount: vm.benefit.neoNatal.newBornPhotoTherapyAmount, effectivePeriod: 'Per annum' }
                            ],
                            hasEffectivePeriod: true
                        }
                    ];
                }
                vm.isEmployeeBenefitDataLoaded = true;
                vm.loading--;
            });
        }

        function getDisplayBalancePool(poolBalance, displayArray) {
            var title = ''

            if (_.head(poolBalance.benefitTypes).id === 7)
                title = hospitalizationTitle;
            else if (_.head(poolBalance.benefitTypes).id === 8)
                title = maternityTitle;

            // TODO: Better design? localisation required

            var isBudget = poolBalance.modifierType === 0 || poolBalance.modifierType === 2;
            var cycle = '';
            if (!poolBalance.isUnlimited) {
                switch (poolBalance.modifierCycle) {
                    case 0: cycle = 'every ' + poolBalance.interval + ' years '; break;
                    case 1: cycle = 'annual '; break;
                    case 2: cycle = 'monthly '; break;
                    case 3: cycle = 'daily '; break;
                    case 4: cycle = 'per visit '; break;
                    case 5: cycle = '6 months '; break;
                    case 6: cycle = '4 months '; break;
                    case 7: cycle = '3 months '; break;
                    case 8: cycle = '2 months '; break;
                }
                if (poolBalance.modifierType === 2 || poolBalance.modifierType === 3) cycle += 'overdraft ';
            }
            title += ' (' + cycle + (isBudget ? 'budget' : 'visit') + ')';

            var isEmptyExcess = poolBalance.isExcess && !poolBalance.employeePortion;
            if (!isEmptyExcess) {
                displayArray.push({
                    title: title,
                    isUnlimited: poolBalance.isUnlimited,
                    isExcess: poolBalance.isExcess,
                    isBudget: isBudget,
                    limit: poolBalance.limit,
                    used: poolBalance.used,
                    locked: poolBalance.locked,
                    balance: poolBalance.balance
                });
            }
        }

        init();
    }
})();
