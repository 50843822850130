(() => {
  angular.module('app').component('manualTransactionSetupTransactionCategory', {
    templateUrl: require('./transactionCategory.component.html'),
    controller: ManualTransactionSetupTransactionCategoryController,
    controllerAs: 'vm',
    bindings: {
      accountOwnerId: '<',
      countryCode: '<',
    },
  });

  ManualTransactionSetupTransactionCategoryController.$inject = [
    '$uibModal',
    'Hms.Finance.ManualTransactionType',
    'abp.services.finance.transactionCategory',
  ];

  function ManualTransactionSetupTransactionCategoryController(
    $uibModal,
    enumManualTransactionType,
    transactionCategorySvc
  ) {
    const vm = this;

    vm.loading = 0;
    vm.transactionsDescriptions = [];
    vm.transactionCategoryTypes = enumManualTransactionType;

    vm.createEditTransactionCategory = createEditTransactionCategory;
    vm.deleteTransactionCategory = deleteTransactionCategory;
    vm.getTransactionCategoryTypeText = getTransactionCategoryTypeText;
    vm.getTransactionDescriptionsText = getTransactionDescriptionsText;

    vm.transactionCategoriesGridOptions = {
      appScopeProvider: vm,
      columnDefs: [
        {
          name: App.localize('Actions'),
          displayName: 'Actions',
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'transactionCategoryActionTemplate',
          allowCellFocus: false,
        },
        {
          displayName: App.localize('TransactionCategory'),
          field: 'name',
          minWidth: 120,
        },
        {
          displayName: App.localize('TransactionType'),
          field: 'transactionCategoryTypes',
          minWidth: 120,
          cellTemplate: 'transactionCategoryTypeTemplate',
        },
        {
          displayName: App.localize('TransactionDescription'),
          field: 'transactionCategoryDescriptions',
          minWidth: 120,
          cellTemplate: 'transactionDescriptionsTemplate',
        },
        {
          displayName: App.localize('NumberOfTransactionItems'),
          field: 'transactionCategoryItemsCount',
          minWidth: 120,
        },
      ],
      data: [],
    };

    init();

    function init() {
      getTransactionCategories();
    }

    function getTransactionCategories() {
      vm.loading += 1;
      transactionCategorySvc
        .getTransactionCategoriesListing({
          accountOwnerId: vm.accountOwnerId,
        })
        .success((data) => {
          vm.transactionCategoriesGridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function createEditTransactionCategory(transactionCategory) {
      $uibModal
        .open({
          templateUrl: require('./createEditTransactionCategory.modal.html'),
          controller: 'manualTransactionSetupCreateEditTransactionCategory as vm',
          backdrop: 'static',
          size: 'xl',
          resolve: {
            isCreate: () => transactionCategory == null,
            transactionCategoryId: () => transactionCategory?.id,
            accountOwnerId: () => vm.accountOwnerId,
          },
        })
        .result.then(() => {
          // Refresh transaction categories listing.

          getTransactionCategories();
        });
    }

    function deleteTransactionCategory(transactionCategory) {
      vm.loading += 1;
      transactionCategorySvc
        .deleteTransactionCategory({
          id: transactionCategory.id
        })
        .success(() => {
          abp.notify.success(App.localize('SuccessfullyDeleted'));
          getTransactionCategories();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getTransactionCategoryTypeText(types) {
      let items = [];

      _.forEach(types, (x) => {
        const filteredItems = _.filter(vm.transactionCategoryTypes, (y) => y.id === x);
        items = items.concat(filteredItems);
      });

      return _.map(items, (x) => App.localize(x.name)).join(', ');
    }

    function getTransactionDescriptionsText(descriptions) {
      return _.map(descriptions, (x) => x.description).join(', ');
    }
  }
})();
