(() => {
  angular.module('finance').component('creditMemoTransactionViewComponent', {
    templateUrl: require('./transactionView.component.html'),
    controller: CreditMemoTransactionViewComponent,
    controllerAs: 'vm',
    bindings: {
      currencyCode: '<',
      creditMemo: '<',
      creditMemoFrom: '<',
      creditMemoTo: '<',
      isAddressCreditMemoFrom: '<',
      isAddressCreditMemoTo: '<',
      message: '<',
      getDateString: '<'
    },
  });

  CreditMemoTransactionViewComponent.$inject = [];

  function CreditMemoTransactionViewComponent() {
    const vm = this;

    vm.loading = 0;

    init();

    function init() {}
  }
})();
