(() => {
  angular.module('app').controller('common.views.common.videoModal', VideoModalController);

  VideoModalController.$inject = ['$uibModalInstance', 'videoOptions', '$sce'];

  function VideoModalController($modal, videoOptions, $sce) {
    const vm = this;

    vm.loading = 0;
    vm.options = angular.extend(
      {
        title: null,
        src: null,
      },
      videoOptions
    );

    vm.trustSrc = trustSrc;
    vm.cancel = cancel;

    init();

    function init() {
      if (!vm.options.src) {
        $modal.dismiss();
      }
    }

    function trustSrc(src) {
      return $sce.trustAsResourceUrl(src);
    }

    function cancel() {
      $modal.dismiss();
    }
  }
})();
