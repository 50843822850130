(function () {
    var componentId = 'revenueSubscriptionsDetailContainer';
    var module = angular.module('revenue');

    function controller($state, $stateParams, subscriptionSvc) {
        function loadSubscription() {
            vm.loading++;
            subscriptionSvc.getSubscription({
                id: $stateParams.subscriptionId
            }).success(function (data) {
                vm.subscription = data;
            }).finally(function () {
                vm.loading--;
            });
        }

        function init() {
            loadSubscription();
        }

        const vm = this;
        vm.loading = 0;
        vm.permissions = {
            manageSubscriptions: abp.auth.isGranted('Hms.Revenue.Corporate.ManageSubscriptions')
        };

        vm.delete = function () {
            if (!vm.subscription) return;
            if (vm.loading) return;

            abp.message.confirm(App.localize('ConfirmDeleteSubscriptionWarning'), App.localize('AreYouSure'), function (d) {
                if (d) {
                    vm.loading++;
                    subscriptionSvc.deleteSubscription({
                        id: vm.subscription.id
                    }).success(function () {
                        abp.notify.success(App.localize("SuccessfullyDeleted"));

                        $state.go('revenue.corporates', {
                            corporateId: vm.subscription.corporateId,
                            section: 'subscriptions'
                        });
                    }).finally(function () {
                        vm.loading--;
                    });
                }
            });
        };

        vm.refreshSubscription = loadSubscription;

        init();
    }

    module.component(componentId, {
        templateUrl: require('/App/modules/revenue/views/subscriptions/detail/container/widget.html'),
        controller: ['$state', '$stateParams', 'abp.services.revenue.subscription', controller],
        controllerAs: 'vm'
    });
})();
