(() => {
  angular.module('app').component('commonClaimsDetailsRecentClaimsTicketsWidget', {
    templateUrl: require('./widget.html'),
    controller: RecentClaimsTicketWidgetController,
    controllerAs: 'vm',
    bindings: {},
  });

  RecentClaimsTicketWidgetController.$inject = [
    '$stateParams',
    'abp.services.app.claim',
    'Hms.Claims.ClaimStatus',
    'Hms.Services.ServiceType',
  ];

  function RecentClaimsTicketWidgetController(
    $stateParams,
    ClaimSvc,
    enumClaimStatus,
    enumServiceType
  ) {
    const vm = this;
    vm.loading = 0;
    vm.records = [];

    vm.enums = {
      claimStatus: enumClaimStatus,
      serviceType: enumServiceType,
    };

    vm.permissions = {
      canViewTicket: abp.auth.isGranted('PatientTickets'),
    };

    vm.getDateTimeString = getDateTimeString;

    init();

    function init() {
      getRecentClaims();
    }

    function getRecentClaims() {
      vm.loading += 1;
      ClaimSvc.getRecentClaimsTickets({
        id: $stateParams.claimNumber,
      })
        .success((data) => {
          vm.records = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getDateTimeString(date, timeZoneId) {
      return App.getDateTimeString(date, timeZoneId);
    }
  }
})();
