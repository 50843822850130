(() => {
  angular
    .module('app')
    .controller(
      'corporate.views.employeeMovementReport.index',
      EmployeeMovementReportPageController
    );

  EmployeeMovementReportPageController.$inject = [
    '$state',
    '$stateParams',
    'appSession',
    'abp.services.app.corporateReport',
    'abp.services.app.commonLookup',
    'moment',
  ];

  function EmployeeMovementReportPageController(
    $state,
    $stateParams,
    appSession,
    corporateReportSvc,
    commonLookupSvc,
    moment
  ) {
    const vm = this;
    vm.loading = 0;
    vm.enqueuing = 0;
    vm.subsidiaries = [];
    vm.dateRangeModel = {};

    vm.dataExportRecordName = 'EmployeeMovementReport';
    vm.generateButtonType = App.localize('Generate');

    vm.financialYears = _.range(
      appSession.tenant.currentFinancialYear,
      appSession.tenant.firstFinancialYear - 1,
      -1
    );

    vm.requestParams = {
      subsidiaryIds: [],
      financialYear: $stateParams.financialYear || appSession.tenant.currentFinancialYear,
      startDate: $stateParams.startDate,
      endDate: $stateParams.endDate,
    };

    vm.dateRangeOptions = _.map(vm.financialYears, (d) => {
      const opt = App.createDateRangePickerOptions();
      const minDate = moment([d, appSession.tenant.firstFinancialMonth - 1, 1]);
      const maxDate = moment.min(
        moment([d + 1, appSession.tenant.firstFinancialMonth - 1, 1]).subtract(1, 'days'),
        moment(opt.maxDate)
      );
      opt.minDate = moment(minDate);
      opt.min = moment(minDate);
      opt.maxDate = moment(maxDate);
      opt.max = moment(maxDate);
      opt.financialYear = d;
      return opt;
    });

    if ($stateParams.startDate || $stateParams.endDate) {
      const opt = _.find(vm.dateRangeOptions, { financialYear: vm.requestParams.financialYear });
      if ($stateParams.startDate) {
        vm.dateRangeModel.startDate = moment.max(
          moment.min(moment($stateParams.startDate), opt.maxDate),
          opt.minDate
        );
      }
      if ($stateParams.endDate) {
        vm.dateRangeModel.endDate = moment.min(
          moment.max(moment($stateParams.endDate), opt.minDate),
          opt.maxDate
        );
      }
    }

    vm.fyChanged = fyChanged;
    vm.generate = generate;

    vm.$onInit = init;

    function init() {
      vm.fyChanged();
      getSubsidiaries();
    }

    function fyChanged() {
      if (!_.isNil(vm.requestParams.financialYear)) {
        const opt = _.find(vm.dateRangeOptions, { financialYear: vm.requestParams.financialYear });
        vm.dateRangeModel.startDate = moment(opt.minDate);
        vm.dateRangeModel.endDate = moment(opt.maxDate);
      }
    }

    function registerStateParams() {
      let startDate = null;
      let endDate = null;
      if (vm.dateRangeModel.startDate) startDate = vm.dateRangeModel.startDate.format('YYYY-MM-DD');
      if (vm.dateRangeModel.endDate) endDate = vm.dateRangeModel.endDate.format('YYYY-MM-DD');

      $state.transitionTo(
        $state.current,
        {
          financialYear: vm.requestParams.financialYear,
          startDate,
          endDate,
        },
        {
          notify: false,
        }
      );
    }

    function exportToExcel() {
      registerStateParams();

      const input = $.extend({}, vm.requestParams, vm.dateRangeModel);
      input.startDate = vm.dateRangeModel.startDate.format('YYYY-MM-DD');
      input.endDate = vm.dateRangeModel.endDate.format('YYYY-MM-DD');

      vm.enqueuing += 1;
      corporateReportSvc
        .enqueueGenerateEmployeeMovementsExcelJob(input)
        .success(() => {
          vm.refresh();
        })
        .finally(() => {
          vm.enqueuing -= 1;
        });
    }

    function generate() {
      exportToExcel();
    }

    function getSubsidiaries() {
      vm.loading += 1;
      commonLookupSvc
        .getCorporateSubsidiaries({
          getDeletedSubsidiariesOrDepartment: true,
        })
        .success((data) => {
          vm.subsidiaries = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
