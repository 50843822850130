(() => {
  angular
    .module('app')
    .controller(
      'host.views.inpatientTreatmentCategories',
      InpatientTreatmentCategoriesPageController
    );

  InpatientTreatmentCategoriesPageController.$inject = [
    '$scope',
    '$stateParams',
    '$state',
    '$uibModal',
    '$timeout',
    'abp.services.app.inpatientTreatmentCategory',
    'abp.services.app.commonLookup',
    'appConstants',
    'Hms.Services.ServiceType',
    'Hms.InpatientTreatmentCategories.SectionType.Name',
  ];

  function InpatientTreatmentCategoriesPageController(
    $scope,
    $stateParams,
    $state,
    $modal,
    $timeout,
    inpatientTreatmentCategorySvc,
    commonLookupSvc,
    appConstants,
    enumServiceTypes,
    enumSectionTypeNames
  ) {
    const vm = this;
    vm.loading = 0;

    // Permission retrieval for inpatient treatment category.

    vm.permissions = {
      createInpatientTreatmentCategory: abp.auth.isGranted(
        'Host.InpatientTreatmentCategories.Create'
      ),
      editInpatientTreatmentCategory: abp.auth.isGranted('Host.InpatientTreatmentCategories.Edit'),
      canArchiveInpatientTreatmentCategory: abp.auth.isGranted(
        'Host.InpatientTreatmentCategories.Archive'
      ),
      canImportInpatientTreatmentCategory: abp.auth.isGranted(
        'Host.InpatientTreatmentCategories.BulkImport'
      ),
    };

    vm.requestParams = {
      countryCode: $stateParams.countryCode,
      serviceType: $stateParams.serviceType || enumServiceTypes.Hospitalization.id,
      sectionType: $stateParams.sectionType,
      isArchived: $stateParams.isArchived || 'false',
      mainTreatmentCategoryName: $stateParams.mainTreatmentCategoryName,
      subTreatmentItemName: $stateParams.subTreatmentItemName,
      treatmentItemCode: $stateParams.treatmentItemCode,
      procedures: $stateParams.procedures,
      state: $stateParams.state,
      filter: $stateParams.filter,
      skipCount: $stateParams.skipCount || 0,
      maxResultCount: $stateParams.maxResultCount || appConstants.grid.defaultPageSize,
      sorting: $stateParams.sorting,
    };

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSizes,
      paginationPageSize: appConstants.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      resizable: true,
      columnDefs: [
        {
          name: App.localize('Actions'),
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
          allowCellFocus: false,
          visible: vm.requestParams.isArchived === 'false',
        },
        {
          displayName: App.localize('ServiceType'),
          field: 'serviceType',
          cellTemplate: 'serviceTypeTemplate',
          enableSorting: false,
          width: 100,
        },
        {
          displayName: App.localize('Country'),
          field: 'countryCode',
          minWidth: 50,
          visible: vm.hasRegionalSystemSupport,
          cellTemplate: 'countryCodeTemplate',
        },
        {
          displayName: App.localize('Section'),
          field: 'sectionType',
          cellTemplate: 'sectionTypeTemplate',
          enableSorting: false,
          minWidth: 80,
        },
        {
          displayName: App.localize('MainTreatmentCategoryName'),
          field: 'mainTreatmentCategoryName',
          minWidth: 80,
        },
        {
          displayName: App.localize('SubTreatmentItemName'),
          field: 'subTreatmentItemName',
          minWidth: 80,
        },
        {
          displayName: App.localize('Procedures'),
          field: 'procedures',
          minWidth: 80,
        },
        {
          displayName: App.localize('TreatmentItemCode'),
          field: 'treatmentItemCode',
          minWidth: 80,
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          vm.requestParams.sorting =
            callback.length && callback[0].field
              ? `${callback[0].field} ${callback[0].sort.direction}`
              : null;
          vm.getInpatientTreatmentCategories();
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, vm.getInpatientTreatmentCategories)
        );
      },
      data: [],
    };

    vm.getInpatientTreatmentCategories = getInpatientTreatmentCategories;
    vm.createInpatientTreatmentCategory = createInpatientTreatmentCategory;
    vm.editInpatientTreatmentCategory = editInpatientTreatmentCategory;
    vm.archiveInpatientTreatmentCategory = archiveInpatientTreatmentCategory;
    vm.bulkImport = bulkImport;

    init();

    function init() {
      vm.enums = {};
      vm.enums.serviceTypes = enumServiceTypes;
      vm.enums.sectionTypeNames = enumSectionTypeNames;

      initServiceTypes();
      initSectionTypes();
      getEligibleCountries();
      getInpatientTreatmentCategories();

      $timeout(() => {
        vm.gridOptions.paginationCurrentPage = Math.floor(
          vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1
        );
      });
    }

    function initServiceTypes() {
      vm.serviceTypeFilters = [];
      vm.serviceTypeFilters.push({
        value: vm.enums.serviceTypes.Hospitalization.id,
        name: App.localize(vm.enums.serviceTypes.Hospitalization.name),
      });
      vm.serviceTypeFilters.push({
        value: vm.enums.serviceTypes.Maternity.id,
        name: App.localize(vm.enums.serviceTypes.Maternity.name),
      });
    }

    function initSectionTypes() {
      vm.sectionTypeFilters = [];
      vm.sectionTypeFilters.push({
        value: vm.enums.sectionTypeNames.AccommodationCharges.id,
        name: App.localize(vm.enums.sectionTypeNames.AccommodationCharges.name),
      });
      vm.sectionTypeFilters.push({
        value: vm.enums.sectionTypeNames.InHospitalDoctorVisits.id,
        name: App.localize(vm.enums.sectionTypeNames.InHospitalDoctorVisits.name),
      });
      vm.sectionTypeFilters.push({
        value: vm.enums.sectionTypeNames.DoctorProcedureCharges.id,
        name: App.localize(vm.enums.sectionTypeNames.DoctorProcedureCharges.name),
      });
      vm.sectionTypeFilters.push({
        value: vm.enums.sectionTypeNames.HospitalCharges.id,
        name: App.localize(vm.enums.sectionTypeNames.HospitalCharges.name),
      });
      vm.sectionTypeFilters.push({
        value: vm.enums.sectionTypeNames.EmergencyOutpatientTreatments.id,
        name: App.localize(vm.enums.sectionTypeNames.EmergencyOutpatientTreatments.name),
      });
      vm.sectionTypeFilters.push({
        value: vm.enums.sectionTypeNames.Miscellaneous.id,
        name: App.localize(vm.enums.sectionTypeNames.Miscellaneous.name),
      });
    }

    function getRequestInput() {
      const input = {
        serviceType: vm.requestParams.serviceType,
        countryCode: vm.requestParams.countryCode,
        sectionType: vm.requestParams.sectionType,
        isArchived: vm.requestParams.isArchived,
        mainTreatmentCategoryName: vm.requestParams.mainTreatmentCategoryName,
        subTreatmentItemName: vm.requestParams.subTreatmentItemName,
        treatmentItemCode: vm.requestParams.treatmentItemCode,
        procedures: vm.requestParams.procedures,
        state: vm.requestParams.state,
        filter: vm.requestParams.filter,
        skipCount: vm.requestParams.skipCount,
        maxResultCount: vm.requestParams.maxResultCount,
        sorting: vm.requestParams.sorting,
      };

      registerStateParams(input);

      return input;
    }

    function registerStateParams(i) {
      const input = i;
      $state.transitionTo($state.current, input, { notify: false });
    }

    function getInpatientTreatmentCategories() {
      const input = getRequestInput();
      updateGridColumnDefs();

      vm.loading += 1;
      inpatientTreatmentCategorySvc
        .getInpatientTreatmentCategories(input)
        .success((data) => {
          vm.gridOptions.totalItems = data.totalCount;
          vm.gridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getEligibleCountries() {
      vm.loading += 1;
      commonLookupSvc
        .getEligibleCountries()
        .success((data) => {
          vm.countries = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function showCreateEditModal(inpatientTreatmentCategory) {
      const modal = $modal.open({
        templateUrl: require('./createEdit/createEdit.modal.html'),
        controller: 'host.views.inpatientTreatmentCategories.createEditModal as vm',
        backdrop: 'static',
        resolve: {
          inpatientTreatmentCategory,
        },
      });
      modal.result.then(() => {
        getInpatientTreatmentCategories();
      });
    }

    function createInpatientTreatmentCategory() {
      showCreateEditModal();
    }

    function editInpatientTreatmentCategory(inpatientTreatmentCategory) {
      showCreateEditModal(inpatientTreatmentCategory);
    }

    function archiveInpatientTreatmentCategory(inpatientTreatmentCategory) {
      abp.message.confirm(
        App.localize(
          'InpatientTreatmentCategoryArchiveWarningMessage',
          inpatientTreatmentCategory.treatmentItemCode
        ),
        App.localize('AreYouSure'),
        (result) => {
          if (result) {
            inpatientTreatmentCategorySvc
              .archiveInpatientTreatmentCategory({
                id: inpatientTreatmentCategory.id,
              })
              .success(() => {
                vm.getInpatientTreatmentCategories();
                abp.notify.success(App.localize('SuccessfullyArchived'));
              });
          }
        }
      );
    }

    function bulkImport() {
      const modal = $modal.open({
        templateUrl: require('./import/import.modal.html'),
        controller: 'host.views.inpatientTreatmentCategories.importModal as vm',
        backdrop: 'static',
      });
      modal.result.then(() => {
        getInpatientTreatmentCategories();
      });
    }

    function updateGridColumnDefs() {
      vm.gridOptions.columnDefs = [
        {
          name: App.localize('Actions'),
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
          allowCellFocus: false,
          visible: vm.requestParams.isArchived === 'false',
        },
        {
          displayName: App.localize('ServiceType'),
          field: 'serviceType',
          cellTemplate: 'serviceTypeTemplate',
          enableSorting: false,
          width: 100,
        },
        {
          displayName: App.localize('Country'),
          field: 'countryCode',
          minWidth: 50,
          visible: vm.hasRegionalSystemSupport,
          cellTemplate: 'countryCodeTemplate',
        },
        {
          displayName: App.localize('Section'),
          field: 'sectionType',
          cellTemplate: 'sectionTypeTemplate',
          enableSorting: false,
          minWidth: 80,
        },
        {
          displayName: App.localize('MainTreatmentCategoryName'),
          field: 'mainTreatmentCategoryName',
          minWidth: 80,
        },
        {
          displayName: App.localize('SubTreatmentItemName'),
          field: 'subTreatmentItemName',
          minWidth: 80,
        },
        {
          displayName: App.localize('Procedures'),
          field: 'procedures',
          minWidth: 80,
        },
        {
          displayName: App.localize('TreatmentItemCode'),
          field: 'treatmentItemCode',
          minWidth: 80,
        },
      ];
    }
  }
})();
