(function () {
    'use strict';

    angular.module('app').component('hostCorporatesSupportPlansCreateEditScheduleOfBenefitPreNatalCheckUpComponent', {
        templateUrl: require('/App/host/views/corporates/supportPlans/createEdit/scheduleOfBenefit/preNatalCheckUp/preNatalCheckUp.component.html'),
        controller: controller,
        controllerAs: 'vm',
        bindings: {
            scheduleOfBenefit: '<',
            currencyCode: '<'
        }
    });

    function controller() {
        var vm = this;

        vm.preNatalCheckUpText = App.localize('PreNatalCheckUp');
        vm.daysText = App.localize('Days');

        vm.togglePreNatalCheckUp = function () {
            vm.minPreNatalDays = vm.scheduleOfBenefit.preNatalCheckUp.isCovered === 1 ? 1 : 0;
            vm.minPreNatalCheckUpAmount = vm.scheduleOfBenefit.preNatalCheckUp.amountType === 1 ? 1 : 0;
            vm.minPreNatalCheckUpCoveredTimes = vm.scheduleOfBenefit.preNatalCheckUp.maxDaysTimesType === 0 ? 1 : 0;
        };

        function init() {
            vm.togglePreNatalCheckUp();
        }

        angular.element(document).ready(function () {
            init();
        });
    }
})();
