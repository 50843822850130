(() => {
  angular
    .module('app')
    .controller('common.views.specialistClaims.query.viewQuery', ViewQueryPageController);

  ViewQueryPageController.$inject = ['$stateParams', 'abp.services.app.serviceRequestQuery'];

  function ViewQueryPageController($stateParams, serviceRequestQuerySvc) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;

    vm.queryNumber = $stateParams.queryNumber;
    vm.claimNumber = $stateParams.claimNumber;
    vm.timeZoneId = $stateParams.timeZoneId;

    vm.resolve = resolve;
    vm.getDateTimeString = getDateTimeString;

    init();

    function init() {
      getQuery();
    }

    /* Functions */

    function getQuery() {
      vm.loading += 1;
      serviceRequestQuerySvc
        .getQuery({ id: vm.queryNumber })
        .success((data) => {
          vm.query = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function resolve() {
      vm.loading += 1;

      serviceRequestQuerySvc
        .resolveQuery({ id: vm.query.id, attachments: vm.query.repliedAttachments })
        .success(() => {
          abp.notify.success(App.localize('SuccessfullySaved'));
          getQuery();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getDateTimeString(date, timeZoneId) {
      return App.getDateTimeString(date, timeZoneId);
    }
  }
})();
