(() => {
  angular
    .module('app')
    .component('commonClaimsProcessClaimDetailSpecialistCalculationSectionsComponent', {
      templateUrl: require('./specialistCalculationSections.component.html'),
      controller: SpecialistCalculationSectionsController,
      controllerAs: 'vm',
      bindings: {
        currencyCode: '<',
        totalAmount: '<',
        totalCoveredAmount: '<',
        totalUncoveredAmount: '<',
        copayRate: '<',
        copayAmount: '<',
        eligibleClaimAmount: '<',
        entitledAmount: '<',
        exceededAmount: '<',
        totalPayableAmount: '<',
        totalCollectCashAmount: '<',
        isTaxInclusive: '<',
        taxDescription: '<',
        taxPercentage: '<',
        coveredTaxAmount: '<',
        uncoveredTaxAmount: '<',
      },
    });

  SpecialistCalculationSectionsController.$inject = [];

  function SpecialistCalculationSectionsController() {
    const vm = this;

    vm.getCoPayValueDisplay = getCoPayValueDisplay;

    init();

    function init() {}

    function getCoPayValueDisplay() {
      if (vm.copayRate !== null) {
        return vm.copayRate.isPercentage
          ? `${vm.copayRate.value}%`
          : `${vm.currencyCode} ${vm.copayRate.value}`;
      }
      return '';
    }
  }
})();
