(() => {
  angular
    .module('app')
    .component('commonPostHospitalizationGuaranteeLettersDetailsQueryComponent', {
      templateUrl: require('./query.component.html'),
      controller: QueryComponentController,
      controllerAs: 'vm',
      bindings: {
        letterNumber: '<',
        newRepliedQueriesCount: '=',
        claimNumber: '<',
        timeZoneId: '<',
        showCreateQuery: '<',
      },
    });

  QueryComponentController.$inject = [
    '$scope',
    '$timeout',
    'abp.services.app.serviceRequestQuery',
    'appConstants',
  ];

  function QueryComponentController($scope, $timeout, serviceRequestQuerySvc, appConstants) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;

    vm.getQueries = getQueries;

    vm.permissions = {
      update: abp.auth.isGranted('GuaranteeLetters.Host.Update'),
      topUp: abp.auth.isGranted('GuaranteeLetters.Host.TopUp'),
      extend: abp.auth.isGranted('GuaranteeLetters.Host.Approve'),
      advance: abp.auth.isGranted('GuaranteeLetters.Corporate.Advance'),
    };

    const viewOptions = {
      maxResultCount: appConstants.grid.defaultPageSize,
      skipCount: 0,
      sorting: null,
    };

    vm.queryComponentGridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSizes,
      paginationPageSize: appConstants.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      resizable: true,
      columnDefs: [
        {
          name: 'Actions',
          displayName: 'Actions',
          enableSorting: false,
          width: 75,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'queryComponentViewTemplate',
        },
        {
          displayName: App.localize('QueryNumber'),
          field: 'queryNumber',
          minWidth: 100,
          width: 150,
        },
        {
          displayName: App.localize('CreationTime'),
          enableSorting: false,
          field: 'creationTime',
          minWidth: 120,
          cellTemplate: 'creationTimeTemplate',
        },
        {
          displayName: App.localize('RepliedBy'),
          enableSorting: false,
          field: 'repliedUserName',
          minWidth: 120,
        },
        {
          displayName: App.localize('ReplyTime'),
          enableSorting: false,
          field: 'repliedTime',
          minWidth: 120,
          cellTemplate: 'repliedTimeTemplate',
        },
        {
          displayName: App.localize('ResolvedBy'),
          enableSorting: false,
          field: 'resolvedUserName',
          minWidth: 120,
        },
        {
          displayName: App.localize('ResolvedAt'),
          enableSorting: false,
          field: 'resolvedTime',
          minWidth: 120,
          cellTemplate: 'resolvedTimeTemplate',
        },
        {
          displayName: App.localize('CreatorUserName'),
          enableSorting: false,
          field: 'creatorUserName',
          minWidth: 120,
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;

        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          viewOptions.sorting =
            callback.length && callback[0].field
              ? `${callback[0].field} ${callback[0].sort.direction}`
              : null;
          vm.getQueries();
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(viewOptions, vm.getQueries())
        );
      },
      data: [],
    };

    vm.getDateTimeString = getDateTimeString;

    vm.$onInit = init;

    function init() {
      $timeout(() => {
        vm.queryComponentGridOptions.paginationCurrentPage = Math.floor(
          viewOptions.skipCount / viewOptions.maxResultCount + 1
        );
      });
    }

    function getQueries() {
      vm.loading += 1;
      serviceRequestQuerySvc
        .getQueries({
          guaranteeLetterNumber: vm.letterNumber,
          skipCount: viewOptions.skipCount,
          maxResultCount: viewOptions.maxResultCount,
        })
        .success((data) => {
          vm.queryComponentGridOptions.totalItems = data.queries.totalCount;
          vm.queryComponentGridOptions.data = data.queries.items;
          vm.canCreateQuery = data.canCreateQuery && vm.showCreateQuery;
          vm.newRepliedQueriesCount = data.newRepliedQueriesCount;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getDateTimeString(date, timeZoneId) {
      return App.getDateTimeString(date, timeZoneId);
    }
  }
})();
