(function () {
    var componentId = 'clinicHealthScreeningDashboardOutstandingDetailCard';
    var app = angular.module('app');

    function controller($sce) {
        function init() {
            // EMPTY.
        }
        var vm = this;

        vm.permissions = {
            viewGl: abp.auth.isGranted("GuaranteeLetters.HealthScreening.Panel"),
            viewClaim: abp.auth.isGranted("Claims.HealthScreening.Panel")
        };

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/clinic/views/healthScreening/dashboard/outstanding/outstandingDetailCard.component.html'),
        controller: ['$sce', controller],
        controllerAs: 'vm',
        bindings: {
            patientName: '<',
            patientType: '<',
            nationalId: '<',
            subsidiaryName: '<',
            queryNumber: '<',
            guaranteeLetterNumber: '<',
            timeZoneId: '<'
        }
    });
})();
