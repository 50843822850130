(() => {
  angular
    .module('app')
    .controller(
      'common.views.specialistClaims.editClaim.createEditSuccessModal',
      ClaimEditSuccessModalController
    );

  ClaimEditSuccessModalController.$inject = [
    '$state',
    '$uibModalInstance',
    'claimNumber',
    'exceededAmount',
  ];

  function ClaimEditSuccessModalController($state, $uibModalInstance, claimNumber, exceededAmount, timeZoneId) {
    const vm = this;

    const currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.hasSpecialistWorkflowEnhancement = abp.setting.getBoolean(
      'Hms.Feature.SpecialistWorkflowEnhancement'
    );
    vm.exceededAmountReminder = exceededAmount
      ? App.localize('RememberCollectXPaymentFromPatient', currencyCode + exceededAmount)
      : null;

    vm.close = close;
    vm.returnToEmployeeSearch = returnToEmployeeSearch;
    vm.showClaimDetail = showClaimDetail;

    init();

    function init() {}

    function close() {
      $uibModalInstance.close();
    }

    function returnToEmployeeSearch() {
      $state.go('clinic.checkin');
      vm.close();
    }

    function showClaimDetail() {
      if (vm.hasSpecialistWorkflowEnhancement) {
        $state.go('specialistClaimRequestDetail', { claimNumber, timeZoneId });
      } else {
        $state.go('specialistClaimDetail', { claimNumber, timeZoneId });
      }
      vm.close();
    }
  }
})();
