(() => {
  angular.module('app').component('commonClaimsDetailsClaimBenefitCoverageWidget', {
    templateUrl: require('./widget.html'),
    controller: BenefitCoverageWidgetController,
    controllerAs: 'vm',
    bindings: {
      receiptDate: '<',
    },
  });

  BenefitCoverageWidgetController.$inject = [
    '$scope',
    '$stateParams',
    '$uibModal',
    '$timeout',
    '$filter',
    'abp.services.app.claim',
    '$rootScope',
  ];

  function BenefitCoverageWidgetController(
    _$scope,
    $stateParams,
    $uibModal,
    $timeout,
    $filter,
    claimSvc,
    $root
  ) {
    const vm = this;

    const elementId = 'specialistTreatmentTree';
    vm.defaultMaxAllowance = parseFloat(abp.setting.get('Hms.General.MaxAllowanceAmountLimit'));
    vm.filter = null;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.loading = 0;
    vm.claimNumber = $stateParams.claimNumber;
    vm.policyIsOpen = true;
    vm.disciplineTreatments = [];
    vm.excludeTypicallyUncoveredSpecialistTreatments = true;
    vm.hasHospitalizationWaitingPeriodEnhancement = abp.setting.getBoolean(
      'Hms.Feature.HospitalisationWaitingPeriodEnhancement'
    );

    let previousDate = null;
    vm.$doCheck = $doCheck;
    vm.search = search;
    vm.panelClass = panelClass;
    vm.viewExclusionList = viewExclusionList;
    vm.viewScheduleOfBenefits = viewScheduleOfBenefits;

    function $doCheck() {
      if (!_.isEqual(vm.receiptDate, previousDate)) {
        getCoverage();
        previousDate = vm.receiptDate;
      }
    }

    function getCoverage() {
      vm.loading += 1;
      claimSvc
        .getBenefitCoverage({
          claimNumber: vm.claimNumber,
          receiptDate: vm.receiptDate,
        })
        .success((data) => {
          vm.corporateBalance = data.corporateBalance;
          vm.claimPolicy = data.claimPolicy;
          vm.balanceDate = data.balanceDate;
          vm.currencyCode = data.currencyCode || abp.setting.get('Hms.General.CurrencyCode');
          vm.employee = data || [];
          _.each(vm.employee.benefits, (b) => {
            const benefit = b;
            benefit.benefits = [];

            $timeout(() => {
              getDisciplineTreatments(benefit.disciplineTreatments);
            });

            if (benefit.coPay) {
              if (benefit.coPay.isPercentage) {
                benefit.coPay.title = App.localize('CopayX', `${benefit.coPay.value}%`);
                benefit.coPay.text = App.localize('CopayPercentage', `${benefit.coPay.value}%`);
              } else {
                const valueText = $filter('currencyFormat')(benefit.coPay.value, vm.currencyCode);
                benefit.coPay.title = App.localize('CopayX', valueText);
                benefit.coPay.text = App.localize('CopayFixedAmount', valueText);
              }
            }
            if (benefit.balancePools && benefit.balancePools.length) {
              _.each(benefit.balancePools, (d) => {
                let title = _.join(
                  _.map(d.benefitTypes, (bt) => {
                    if (bt.isCustom) {
                      let strCt = `CT${bt.id}`;
                      const idx = _.findIndex(
                        vm.employee.customizedTreatments,
                        (ctn) => ctn.value === bt.id
                      );
                      if (idx !== -1) strCt = vm.employee.customizedTreatments[idx].name;
                      return strCt;
                    }
                    if (bt.id === 251) {
                      return App.localize('Hospitalization');
                    }
                    return App.localize(bt.name);
                  }),
                  '/'
                );

                // TODO: Better design? localisation required
                const isBudget = d.modifierType === 0 || d.modifierType === 2;
                let cycle = '';
                if (!d.isUnlimited) {
                  switch (d.modifierCycle) {
                    case 0:
                      cycle = `every ${d.interval} years `;
                      break;
                    case 1:
                      cycle = 'annual ';
                      break;
                    case 2:
                      cycle = 'monthly ';
                      break;
                    case 3:
                      cycle = 'daily ';
                      break;
                    case 4:
                      cycle = 'per visit ';
                      break;
                    case 5:
                      cycle = '6 months ';
                      break;
                    case 6:
                      cycle = '4 months ';
                      break;
                    case 7:
                      cycle = '3 months ';
                      break;
                    case 8:
                      cycle = '2 months ';
                      break;

                    // no default
                  }
                  if (d.modifierType === 2 || d.modifierType === 3) cycle += 'overdraft ';
                }
                title += ` (${cycle}${isBudget ? 'budget' : 'visit'})`;

                const isEmptyExcess = d.isExcess && !d.employeePortion;
                if (!isEmptyExcess) {
                  benefit.benefits.push({
                    title,
                    isUnlimited: d.isUnlimited,
                    isExcess: d.isExcess,
                    isBudget,
                    limit: d.limit,
                    used: d.used,
                    locked: d.locked,
                    balance: d.balance,
                  });
                }
              });
            }

            benefit.allowance = Math.min(benefit.allowance, vm.defaultMaxAllowance);

            if (benefit.serviceType === 5) {
              _.assign(vm.employee, {
                hasSpecialist: true,
              });
            }

            if (benefit.inpatientBenefitDetails) {
              benefit.inpatientBenefitDetails.supportPlanName =
                benefit.inpatientBenefitDetails.name;
              benefit.inpatientBenefitDetails.supportPlanDisplayName =
                benefit.inpatientBenefitDetails.displayName;
              benefit.inpatientBenefitDetails.allowance = Math.min(
                benefit.inpatientBenefitDetails.allowance,
                vm.defaultMaxAllowance
              );

              if (benefit.inpatientBenefitDetails.serviceType === 7) {
                benefit.inpatientBenefitDetails.name = App.localize('Hospitalization');
                vm.employee.customizedTreatments.unshift({
                  canCreate: true,
                  name: App.localize('HospitalizationTicket'),
                  value: 7,
                });

                benefit.inpatientBenefitDetails.settings =
                  benefit.inpatientBenefitDetails.hospitalizationSetting;
                benefit.inpatientBenefitDetails.coPay = _.head(
                  benefit.inpatientBenefitDetails.hospitalizationSetting.coPays
                );
              } else {
                benefit.inpatientBenefitDetails.name = App.localize('Maternity');
                vm.employee.customizedTreatments.unshift({
                  canCreate: true,
                  name: App.localize('HospitalizationTicket'),
                  value: 8,
                });

                benefit.inpatientBenefitDetails.settings =
                  benefit.inpatientBenefitDetails.maternitySetting;
                benefit.inpatientBenefitDetails.coPay = _.head(
                  benefit.inpatientBenefitDetails.maternitySetting.coPays
                );
              }

              if (benefit.inpatientBenefitDetails.coPay) {
                if (benefit.inpatientBenefitDetails.coPay.isPercentage) {
                  benefit.inpatientBenefitDetails.coPay.title = App.localize(
                    'CopayX',
                    `${benefit.inpatientBenefitDetails.coPay.value}%`
                  );
                  benefit.inpatientBenefitDetails.coPay.text = App.localize(
                    'CopayPercentage',
                    `${benefit.inpatientBenefitDetails.coPay.value}%`
                  );
                } else {
                  const valueText = $filter('currencyFormat')(
                    benefit.inpatientBenefitDetails.coPay.value,
                    vm.currencyCode
                  );
                  benefit.inpatientBenefitDetails.coPay.title = App.localize('CopayX', valueText);
                  benefit.inpatientBenefitDetails.coPay.text = App.localize(
                    'CopayFixedAmount',
                    valueText
                  );
                }
              }
            }
          });
        })
        .error((data) => {
          vm.errorMessage = data.message;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getDisciplineTreatments(disciplineTreatments) {
      if (!disciplineTreatments) return;

      vm.disciplineTreatments = disciplineTreatments.items;
      vm.excludeTypicallyUncoveredSpecialistTreatments =
        disciplineTreatments.excludeTypicallyUncoveredSpecialistTreatments;
      buildTreatmentTree();
    }

    function buildTreatmentTree() {
      const element = $(`#${elementId}`);
      const ref = $.jstree.reference(element);
      const typicallyUncoveredSpecialistTreatmentsUrl = $root.resolveAppPath(
        `Print/TypicallyUncoveredSpecialistTreatments`
      );
      if (ref) {
        ref.destroy();
      }

      const coveredNodeIcon = '<i class="fas fa-check text-navy"></i> ';
      const uncoveredNodeIcon = '<i class="fas fa-times text-danger"></i> ';
      function getNodeText(data) {
        return (data.isCovered ? coveredNodeIcon : uncoveredNodeIcon) + data.displayName;
      }

      const treatments = _.map(vm.disciplineTreatments, (record) => ({
        id: record.id,
        parent: record.parentId ? record.parentId : '#',
        displayName: record.displayName,
        text: getNodeText(record),
        state: {
          opened: record.hasUncoveredChildren,
        },
      }));

      const typicalUncoveredIcon = vm.excludeTypicallyUncoveredSpecialistTreatments
        ? uncoveredNodeIcon
        : coveredNodeIcon;
      treatments.unshift({
        id: -1,
        parent: '#',
        text: `${typicalUncoveredIcon}<strong>Typically uncovered specialist treatments</strong> <a>(View)</a>`,
        a_attr: { href: typicallyUncoveredSpecialistTreatmentsUrl },
      });

      element
        .jstree({
          core: {
            data: treatments,
          },
          types: {
            default: {
              icon: 'fas fa-folder tree-item-icon-color icon-lg',
            },
            file: {
              icon: 'fas fa-file tree-item-icon-color icon-lg',
            },
          },
          search: {
            fuzzy: false,
            show_only_matches: true,
            show_only_matches_children: true,
          },
          sort(itemA, itemB) {
            const nodeA = this.get_node(itemA);
            const nodeB = this.get_node(itemB);
            if (nodeB.original.id === -1) {
              return 1;
            }
            if (nodeA.children_d.length > 0 && nodeB.children_d.length === 0) {
              return -1;
            }

            return nodeB.original.displayName < nodeA.original.displayName ? 1 : -1;
          },
          plugins: ['types', 'search', 'sort'],
        })
        .bind('select_node.jstree', (_e, data) => {
          const { href } = data.node.a_attr;
          if (href === '#') return '';

          window.open(href);
          return '';
        });
    }

    function search() {
      const element = $(`#${elementId}`);
      element.jstree('search', _.trim(vm.filter));
    }

    function panelClass(clinicType) {
      return clinicType.isCustom ? 'panel-danger' : 'panel-default';
    }

    function viewExclusionList(inpatientSettings) {
      $uibModal.open({
        templateUrl: require('../../../../../host/views/employees/exclusionList/exclusionList.modal.html'),
        controller: 'host.views.employees.exclusionListModal as vm',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          inpatientSettings,
        },
      });
    }

    function viewScheduleOfBenefits(serviceType, settings, employeeType) {
      $uibModal.open({
        templateUrl: require('../../../../../host/views/employees/scheduleOfBenefits/scheduleOfBenefits.modal.html'),
        controller: 'host.views.employees.scheduleOfBenefitsModal as vm',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          serviceType,
          hospitalizationSetting: settings,
          maternitySetting: settings,
          employeeType,
          countryInfo: {
            countryCode: null,
            currencyCode: null
          }
        },
      });
    }
  }
})();
