(() => {
  angular
    .module('app')
    .controller('common.views.patientTickets.editDateModal', EditDateModalController);

  EditDateModalController.$inject = [
    '$uibModalInstance',
    'abp.services.app.patientTicket',
    'ticket',
    'moment',
  ];

  function EditDateModalController($uibModalInstance, patientTicketSvc, ticket, moment) {
    const vm = this;

    vm.saving = 0;
    vm.ticket = ticket;
    vm.ticketDate = ticket.ticketDate;

    let duration = 0;

    vm.getMcEndDate = getMcEndDate;
    vm.getMcDurationDescription = getMcDurationDescription;
    vm.save = save;
    vm.cancel = cancel;

    init();

    function init() {
      if (ticket.medicalCertificate) {
        duration =
          moment(vm.ticket.medicalCertificate.endDate).diff(
            moment(vm.ticket.medicalCertificate.startDate),
            'days'
          ) + 1;
        vm.mcStartDate = ticket.medicalCertificate.startDate;
      }
    }

    function getMcEndDate() {
      if (ticket.medicalCertificate) return moment(vm.mcStartDate).add(duration - 1, 'd');
      return null;
    }

    function getMcDurationDescription() {
      if (ticket.medicalCertificate) return App.localize('XDays', duration);
      return '';
    }

    function save() {
      vm.saving += 1;
      patientTicketSvc
        .updateTicketDate({
          id: ticket.ticketNumber,
          ticketDate: vm.ticketDate,
          mcStartDate: ticket.medicalCertificate ? vm.mcStartDate : null,
          modificationRemarks: _.trim(vm.modificationRemarks),
        })
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          $uibModalInstance.close();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
