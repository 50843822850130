(function () {
    var controllerId = 'host.views.drugs.index';
    var app = angular.module('app');
    app.controller(controllerId, [
        'countries', '$scope', '$state', '$stateParams', '$uibModal', '$timeout', 'abp.services.app.drug', 'abp.services.app.commonLookup',
        function (countries, $scope, $state, $stateParams, $modal, $timeout, drugSvc, commonLookupSvc) {
            function init() {
                getMimsClass();
                $timeout(function () {
                    vm.drugGridOptions.paginationCurrentPage = Math.floor(vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1);
                });
                if (vm.hasRegionalSystemSupport) {
                    getAccessibleCountries();
                }
            }

            var vm = this;
            vm.loading = 0;
            vm.exporting = 0;
            vm.requestParams = {
                productName: $stateParams.productName || '',
                activeIngredients: $stateParams.activeIngredients || '',
                mimsClass: $stateParams.mimsClass || '',
                manufacturer: $stateParams.manufacturer || '',
                type: $stateParams.type,
                skipCount: $stateParams.skipCount || 0,
                maxResultCount: $stateParams.maxResultCount || app.consts.grid.defaultPageSize,
                sorting: $stateParams.sorting
            };

            vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');

            vm.mimsClasses = [];
            vm.countries = [];
            vm.selectedCountry = '';

            $scope.$on("$viewContentLoaded", function () {
                App.initAjax();
            });

            // Assign permissions

            vm.permissions = {
                create: abp.auth.isGranted("Host.Drugs.Create"),
                edit: abp.auth.isGranted("Host.Drugs.Edit"),
                "delete": abp.auth.isGranted("Host.Drugs.Delete"),
                import: abp.auth.isGranted("Host.Drugs.Import")
            };

            // Define grid options

            vm.drugGridOptions = {
                appScopeProvider: vm,
                paginationPageSizes: app.consts.grid.defaultPageSize,
                paginationPageSize: app.consts.grid.defaultPageSize,
                paginationCurrentPage: 0,
                useExternalPagination: true,
                useExternalSorting: true,
                enableRowSelection: true,
                enableSelectAll: false,
                enableFullRowSelect: true,
                enableRowHeaderSelection: false,
                multiSelect: false,
                rowTemplate: '<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader, \'text-muted\': !row.entity.isActive }" ui-grid-cell></div>',
                columnDefs: [{
                    enableSorting: false,
                    name: App.localize('Actions'),
                    width: 100,
                    headerCellTemplate: "<span></span>",
                    cellTemplate: 'actionTemplate'
                }, {
                    displayName: App.localize('ProductName'),
                    field: 'productName',
                    minWidth: 120
                }, {
                    displayName: App.localize('Type'),
                    field: 'isBranded',
                    width: 80,
                    cellTemplate: 'typeTemplate'
                }, {
                    displayName: App.localize('Visible'),
                    field: 'isVisible',
                    width: 80,
                    cellTemplate: 'visibleTemplate'
                }, {
                    displayName: App.localize('ActiveIngredients') + " / " + App.localize('Strength'),
                    field: 'activeIngredients',
                    minWidth: 100
                }, {
                    displayName: App.localize('MIMsClass'),
                    field: 'mimsClass',
                    minWidth: 100
                }, {
                    displayName: App.localize('Manufacturer'),
                    field: 'manufacturer',
                    minWidth: 100
                }],
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                    $scope.gridApi.core.on.sortChanged($scope, function (scope, callback) {
                        var sorting = "";
                        for (var i = 0; i < callback.length; i++) {
                            if (i) sorting += ", ";
                            sorting += callback[i].field + " " + callback[i].sort.direction;
                        }
                        vm.requestParams.sorting = sorting;
                        vm.getDrugs();
                    });
                    gridApi.pagination.on.paginationChanged($scope, App.setPagination(vm.requestParams, vm.getDrugs));
                },
                data: []
            };

            // Shift out action column if there's no edit actions

            vm.permissions.edit || vm.permissions.delete || vm.drugGridOptions.columnDefs.shift();

            // Define funtions

            function registerStateParams() {
                $state.transitionTo($state.current, {
                    skipCount: vm.requestParams.skipCount,
                    maxResultCount: vm.requestParams.maxResultCount,
                    sorting: vm.requestParams.sorting,
                    productName: vm.productName,
                    activeIngredients: vm.activeIngredients,
                    mimsClass: vm.mimsClass,
                    type: vm.type,
                    manufacturer: vm.manufacturer
                }, {
                    notify: false
                });
            }

            vm.getDrugs = function () {
                vm.loading++;
                registerStateParams();

                var input = getRequestInput();
                drugSvc.getDrugs(input).success(function (data) {
                    vm.drugGridOptions.totalItems = data.totalCount;
                    vm.drugGridOptions.data = data.items;
                }).finally(function (e) {
                    vm.loading--;
                });
            };

            function getRequestInput() {
                var input = $.extend({}, vm.requestParams, { countryCode: vm.selectedCountry });
                if (vm.requestParams.type === 1) {
                    input.isBranded = true;
                } else if (vm.requestParams.type === 2) {
                    input.isBranded = false;
                } else {
                    input.isBranded = null;
                }
                return input;
            }

            vm.deleteDrug = function (drug) {
                abp.message.confirm(App.localize('DrugDeleteWarningMessage', drug.productName), App.localize('AreYouSure'), function (result) {
                    if (result) {
                        drugSvc.deleteDrug({
                            id: drug.id
                        }).success(function (data) {
                            vm.getDrugs();
                            abp.notify.success(App.localize("SuccessfullyDeleted"));
                        });
                    }
                });
            };

            function getMimsClass() {
                commonLookupSvc.getMimsClasses()
                    .success(function (result) {
                        vm.mimsClasses = result.items;
                    });
            }

            function getAccessibleCountries() {
                vm.loading++;
                commonLookupSvc.getEligibleCountries().success(function (data) {
                    var eligibleCountryCodes = data.map(o => o.code)
                    vm.countries = _.filter(countries, function (c) {
                        return eligibleCountryCodes.includes(c.code)
                    });
                }).finally(function (e) {
                    vm.loading--;
                });
            }

            vm.bulkUpdate = function () {
                var modal = $modal.open({
                    templateUrl: require('/App/host/views/drugs/importDrugsModal.html'),
                    controller: 'host.views.drugs.importDrugsModal as vm',
                    backdrop: 'static'
                });
                modal.result.then(function () {
                    vm.getDrugs();
                });
            };

            init();
        }
    ]);
})();
