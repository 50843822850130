(function () {
    var controllerId = 'corporate.views.panelAccesses.clonePanelAccessPolicyModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'abp.services.app.panelAccess', 'abp.services.app.commonLookup',
        function ($scope, $uibModalInstance, panelAccessSvc, commonLookupSvc) {
            function init() {
                getAllActiveOrganizationServices();
                getBenefitGroups();
                getInpatientBenefitGroups();
                getInsurancePlans();
                getTenantCountryCode();
            }
            var vm = this;
            vm.loading = 0;

            vm.hasInpatientModule = abp.features.isEnabled('HasInpatientModule');
            vm.applicationHasCorporateInsurance = abp.setting.getBoolean('Hms.Feature.InsuranceModule');

            vm.activeOrganizationServices = [];
            function getAllActiveOrganizationServices() {
                commonLookupSvc.getActiveOrganizationServices({
                }).success(function (res) {
                    vm.activeOrganizationServices = res;
                });
            }

            vm.benefitGroups = [];
            vm.filteredBenefitGroups = [];
            function getBenefitGroups() {
                vm.loading++;
                panelAccessSvc.getBenefitGroups().success(function (data) {
                    vm.benefitGroups = _.filter(data.items, function (benefitGroup) {
                        return !benefitGroup.policyId;
                    });
                    formatBenefitGroupCoverage(vm.benefitGroups);
                    refreshBenefitGroups();
                }).finally(function () {
                    vm.loading--;
                });
            }

            vm.inpatientBenefitGroups = [];
            vm.filteredInpatientBenefitGroups = [];
            function getInpatientBenefitGroups() {
                vm.loading++;
                panelAccessSvc.getInpatientBenefitGroups().success(function (data) {
                    vm.inpatientBenefitGroups = _.filter(data.items, function (benefitGroup) {
                        return !benefitGroup.policyId;
                    });
                    formatInpatientBenefitGroupCoverage(vm.inpatientBenefitGroups);
                    refreshInpatientBenefitGroups();
                }).finally(function () {
                    vm.loading--;
                });
            }

            vm.insurancePlans = [];
            vm.filteredInsurancePlans = [];
            function getInsurancePlans() {
                vm.loading++;
                panelAccessSvc.getInsurancePlans().success(function (data) {
                    vm.insurancePlans = _.filter(data.items, function (benefitGroup) {
                        return !benefitGroup.policyId;
                    });
                    formatInsurancePlansCoverage(vm.insurancePlans);
                    refreshInsurancePlans();
                }).finally(function () {
                    vm.loading--;
                });
            }

            function formatBenefitGroupCoverage(benefitGroups) {
                _.forEach(benefitGroups, function (benefitGroup) {
                    benefitGroup.coverage.gpTooltip = vm.benefitCoverageTooltip(benefitGroup.coverage.gp, App.localize('GP'));
                    benefitGroup.coverage.dentalTooltip = vm.benefitCoverageTooltip(benefitGroup.coverage.dental, App.localize('Dental'));
                    benefitGroup.coverage.opticalTooltip = vm.benefitCoverageTooltip(benefitGroup.coverage.optical, App.localize('Optical'));
                    benefitGroup.coverage.specialistTooltip = vm.benefitCoverageTooltip(benefitGroup.coverage.specialist, App.localize('Specialist'));
                });
            }

            function formatInpatientBenefitGroupCoverage(benefitGroups) {
                _.forEach(benefitGroups, function (benefitGroup) {
                    benefitGroup.coverage.hospitalizationTooltip = vm.benefitCoverageTooltip(benefitGroup.coverage.hospitalization, App.localize('Hospitalization'));
                    benefitGroup.coverage.maternityTooltip = vm.benefitCoverageTooltip(benefitGroup.coverage.maternity, App.localize('Maternity'));
                });
            }

            function formatInsurancePlansCoverage(insurancePlans) {
                _.forEach(insurancePlans, function (insurancePlan) {
                    insurancePlan.coverage.hospitalizationTooltip = vm.benefitCoverageTooltip(insurancePlan.coverage.hospitalization, App.localize('Hospitalization'));
                });
            }

            vm.benefitCoverageTooltip = function (benefitCoverege, panel) {
                if (benefitCoverege) {
                    return App.localize('PanelProvided', panel);
                } else {
                    return App.localize('PanelNotProvided', panel);
                }
            };

            vm.cloneBenefitGroups = [];
            vm.updateBenefitGroup = function () {
                if (vm.selectedBenefitGroup) {
                    vm.cloneBenefitGroups.push(vm.selectedBenefitGroup);
                    refreshBenefitGroups();
                }
            };

            vm.cloneInpatientBenefitGroups = [];
            vm.updateInpatientBenefitGroup = function () {
                if (vm.selectedInpatientBenefitGroup) {
                    if (vm.selectedInpatientBenefitGroup.coverage.hospitalization && !_.includes(vm.activeOrganizationServices, 7)) {
                        abp.message.warn(App.localize('HospitalizationServiceNotActive'));
                        vm.selectedInpatientBenefitGroup = null;
                        return;
                    } else if (vm.selectedInpatientBenefitGroup.coverage.maternity && !_.includes(vm.activeOrganizationServices, 8)) {
                        abp.message.warn(App.localize('MaternityServiceNotActive'));
                        vm.selectedInpatientBenefitGroup = null;
                        return;
                    }

                    vm.cloneInpatientBenefitGroups.push(vm.selectedInpatientBenefitGroup);
                    refreshInpatientBenefitGroups();
                }
            };

            vm.cloneInsurancePlans = [];
            vm.updateInsurancePlans = function () {
                if (vm.selectedInsurancePlan) {
                    if (vm.selectedInsurancePlan.coverage.hospitalization && !_.includes(vm.activeOrganizationServices, 7)) {
                        abp.message.warn(App.localize('HospitalizationServiceNotActive'));
                        vm.selectedInsurancePlan = null;
                        return;
                    }

                    vm.cloneInsurancePlans.push(vm.selectedInsurancePlan);
                    refreshInsurancePlans();
                }
            }

            function refreshBenefitGroups() {
                vm.filteredBenefitGroups = _.filter(vm.benefitGroups, function (bg) {
                    return !_.some(vm.cloneBenefitGroups, function (pbg) {
                        return pbg.id === bg.id;
                    });
                });
            }

            function refreshInpatientBenefitGroups() {
                vm.filteredInpatientBenefitGroups = _.filter(vm.inpatientBenefitGroups, function (bg) {
                    return !_.some(vm.cloneInpatientBenefitGroups, function (pbg) {
                        return pbg.id === bg.id;
                    });
                });
            }

            function refreshInsurancePlans() {
                vm.filteredInsurancePlans = _.filter(vm.insurancePlans, function (iP) {
                    return !_.some(vm.cloneInsurancePlans, function (pip) {
                        return pip.id === iP.id;
                    });
                });
            }

            vm.deleteBenefitGroup = function (benefitGroup) {
                vm.cloneBenefitGroups = _.without(vm.cloneBenefitGroups, benefitGroup);
                refreshBenefitGroups();
            };

            vm.deleteInpatientBenefitGroup = function (benefitGroup) {
                vm.cloneInpatientBenefitGroups = _.without(vm.cloneInpatientBenefitGroups, benefitGroup);
                refreshInpatientBenefitGroups();
            };

            vm.deleteInsurancePlan = function (insurancePlan) {
                vm.cloneInsurancePlans = _.without(vm.cloneInsurancePlans, insurancePlan);
                refreshInsurancePlans();
            };

            vm.clone = function () {
                if (!vm.validateForm()) {
                    abp.notify.error(App.localize('GeneralInvalidFormInputError'));
                    return;
                }
                $uibModalInstance.close({
                    benefitGroups: vm.cloneBenefitGroups,
                    inpatientBenefitGroups: vm.cloneInpatientBenefitGroups,
                    insurancePlans: vm.cloneInsurancePlans
                });
            };

            vm.validateForm = function () {
                var form = $scope.clonePanelAccessPolicyForm;
                App.touchFormErrors(form);
                return form.$valid;
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            function getTenantCountryCode() {
                vm.loading++;
                commonLookupSvc.getTenantCountryCode()
                    .success(function (data) {
                        vm.countryCode = data;
                    }).finally(function () {
                        vm.loading--;
                        getAccessRuleLocalizations();
                    });
            }

            init();
        }
    ]);
})();
