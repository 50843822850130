(function () {
    var app = angular.module('app');
    app.component('hostDoraemonAddOnRequestDetailsComponent', {
        templateUrl: require('/App/host/views/doraemon/addOn/requestDetails/requestDetails.component.html'),
        controller: ['$state', '$stateParams', 'abp.services.app.doraemon', 'Hms.ServiceRequests.ServiceRequestStatus', 'moment', controller],
        controllerAs: 'vm',
        bindings: {
            data: '<',
            onEdit: '&',
        }
    });

    function controller($state, $stateParams, doraemonSvc, enumServiceRequestStatus, moment) {
        var vm = this;

        vm.$onChanges = onChanges;
        vm.edit = edit;
        vm.save = save;
        vm.cancel = cancel;
        vm.lookupDoctor = lookupDoctor;
        vm.filteredDoctors = [];

        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.serviceRequestId = $stateParams.serviceRequestId;
        vm.isEdit = vm.serviceRequestId == null;
        vm.saving = 0;
        vm.loading = true;
        vm.request = preEditRequest = preEditDiagnosis = preEditDocuments = null;
        vm.savedTrigger = 0;
        vm.documents = []
        vm.newAttachments = [];
        vm.diagnosis = {
            selected: [],
            icd: []
        };
        vm.invalidAttachment = false;
        var limitSize = 50;

        vm.enums = {};
        vm.enums.serviceRequestStatus = enumServiceRequestStatus;

        var fileExtensions = '|' + app.consts.fileUpload.imageExtensions.concat(app.consts.fileUpload.pdfExtensions).join('|') + '|';
        vm.uploadConfig = {
            objectType: 'InpatientAdjustmentRequestAttachment',
            itemsLimit: 3,
            accept: 'image/*,application/pdf',
            filters: [{
                name: 'fileFilter',
                fn: function (n) {
                    vm.invalidAttachment = false;
                    var t = '|' + n.type.slice(n.type.lastIndexOf('/') + 1) + '|';
                    if (fileExtensions.indexOf(t) === -1) {
                        vm.invalidAttachment = true;
                        return false;
                    }
                    return true;
                }
            }, {
                name: 'sizeFilter',
                fn: function (n) {
                    vm.invalidAttachmentSize = false;
                    var sizeLimit = 52428800;
                    if (n.size > sizeLimit) {
                        vm.invalidAttachmentSize = true;
                        return false;
                    }
                    return true;
                }
            }]
        };

        function onChanges(changes) {
            if (changes.data && changes.data.currentValue) {
                getRequestDetails(vm.data);
            }
        }

        function getRequestDetails(data) {
            if (!data) return;
            vm.serviceRequestId = data.serviceRequestId;
            vm.isEdit = vm.serviceRequestId == null;
            vm.request = {
                id: data.serviceRequestId,
                issueNumber: data.issueNumber,
                hospitalName: data.hospitalName,
                hospitalContactNumber: data.hospitalContactNumber,
                admissionNumber: data.admissionNumber,
                requestType: data.requestType,
                requestTime: data.requestTime,
                requestedBy: data.requestedBy,
                letterNumber: data.letterNumber,
                scheduledAdmissionTime: data.scheduledAdmissionTime,
                admissionType: data.admissionType,
                selectedDoctorIds: data.selectedDoctorIds,
                interimBillAmount: data.interimBillAmount,
                newGuaranteeAmount: data.newGuaranteeAmount,
                glRemarks: data.glRemarks,
                status: data.status,
            };
            vm.selectedDoctorNames = data.selectedDoctorsNames;
            vm.glDoctorNames = data.glDoctorsNames;
            vm.admissionTypeName = data.admissionTypeName;
            vm.doctors = data.availableDoctors;
            vm.lookupDoctor();

            vm.documents = _.map(data.supportingDocuments, function (d) {
                return {
                    externalObjectId: d.externalObjectId,
                    url: d.url,
                    name: d.name,
                    uploader: d.uploader + ' ' + moment(d.uploadedTime).format('L, LT'),
                    applied: d.isApplied
                };
            });

            vm.glDocuments = _.map(data.glDocuments, function (d) {
                return {
                    externalObjectId: d.externalObjectId,
                    url: d.url,
                    name: d.name,
                    uploader: d.uploader + ' ' + moment(d.uploadedTime).format('L, LT'),
                    applied: d.isApplied
                };
            });

            vm.glDiagnosis = _.map(data.glProvisionalDiagnosis, function (p) {
                return {
                    code: p.code,
                    description: !_.isNil(p.code) ? '(' + p.code + ') ' + p.description : p.description,
                    date: p.diagnosisDate
                };
            });

            vm.diagnosis = {
                selected: _.map(data.provisionalDiagnosis, function (p) {
                    return {
                        code: p.code,
                        description: p.description,
                        date: p.diagnosisDate
                    };
                }),
                icd: _.map(data.diseaseClassifications, function (d) {
                    return {
                        id: d.id,
                        code: d.code,
                        description: '(' + d.code + ') ' + d.description
                    };
                })
            };

            if (vm.isEdit) vm.diagnosis.selected.push({ date: new Date() });
            vm.loading = false;
        }

        function edit() {
            backupInitialStates();
            vm.isEdit = true;
            vm.onEdit({ isEdit: true });
        }

        function save() {
            if (App.isFormValid(vm.requestDetailsForm)) {
                if (vm.documents.length < 1 && vm.newAttachments < 1) {
                    vm.invalidAttachment = true;
                    return;
                }
                if (vm.diagnosis) {
                    tidyDiagnosis();
                }

                vm.request.interimBillAmount = App.roundAmount(vm.request.interimBillAmount);
                vm.request.newGuaranteeAmount = App.roundAmount(vm.request.newGuaranteeAmount);

                var supportingDocuments = [];
                if (vm.documents) {
                    supportingDocuments = _.map(vm.documents, function (d) {
                        return {
                            externalObjectId: d.externalObjectId,
                            isApplied: d.applied
                        }
                    });
                }

                var provisionalDiagnosis = [];
                if (vm.diagnosis) {
                    tidyDiagnosis();
                    provisionalDiagnosis = _.map(vm.diagnosis.selected, function (s) {
                        // Free-text entry has null diagnosis code.

                        return {
                            code: _.isNumber(s.code) ? null : s.code,
                            description: s.description,
                            diagnosisDate: s.date
                        }
                    });
                }
                if (!(vm.serviceRequestId == null || vm.serviceRequestId == "" || vm.serviceRequestId === undefined)) {
                    vm.request.id = vm.serviceRequestId;
                }

                var input = $.extend({},
                    vm.request,
                    { provisionalDiagnosis: provisionalDiagnosis },
                    { newAttachments: vm.newAttachments },
                    { supportingDocuments: supportingDocuments });

                vm.saving++;
                doraemonSvc.createOrUpdateAddOn(input
                ).success(function (serviceRequestId) {
                    abp.notify.info(App.localize("SuccessfullySaved"));
                    $state.go('host.doraemonAddOnRequest', {
                        letterNumber: vm.request.letterNumber,
                        serviceRequestId: serviceRequestId,
                    }, { reload: true });
                }).finally(function () {
                    vm.saving--
                })
            }
        }

        function cancel() {
            restoreInitialStates();
            vm.isEdit = false;
            vm.onEdit({ isEdit: false });
        }

        function backupInitialStates() {
            preEditRequest = angular.copy(vm.request);
            preEditDiagnosis = angular.copy(vm.diagnosis);
            preEditDocuments = angular.copy(vm.documents);
        }

        function restoreInitialStates() {
            vm.request = preEditRequest;
            vm.diagnosis = preEditDiagnosis;
            vm.documents = preEditDocuments;
        }

        function lookupDoctor(filter) {
            if (!vm.doctors) return;
            var keyword = _.trim(filter || '');
            if (keyword) {
                var filteredDoctors = [];

                var regexes = _.chain(keyword.split(' '))
                    .map(function (k) {
                        return _.trim(k.toUpperCase());
                    })
                    .filter(function (k) {
                        return k;
                    })
                    .uniq()
                    .map(function (k) {
                        _.escapeRegExp(k);
                        return {
                            token: k,
                            len: k.length,
                            pattern: new RegExp('\\b' + k, 'i')
                        };
                    })
                    .value();

                _.forEach(vm.doctors, function (doctor) {
                    var score = 0;
                    _.forEach(regexes, function (r) {
                        if (r.pattern.test(doctor.name)) {
                            score -= r.len;
                        }
                    });

                    if (score) {
                        filteredDoctors.push({
                            score: score,
                            item: doctor
                        });
                    }
                });

                vm.filteredDoctors = _.chain(filteredDoctors)
                    .sortBy(['score'])
                    .take(limitSize)
                    .map(function (k) {
                        return k.item;
                    })
                    .uniqBy(function (s) {
                        return s.name;
                    })
                    .value();
            } else {
                vm.filteredDoctors = _.take(vm.doctors, limitSize);
            }
        };

        function tidyDiagnosis() {
            vm.diagnosis.selected = _.filter(vm.diagnosis.selected, function (item) {
                return item.code !== undefined;
            });
        }
    }
})();
