(() => {
  angular.module('finance').component('financeBillingAccountsListing', {
    templateUrl: require('./widget.html'),
    controller: BillingAccountsListingController,
    controllerAs: 'vm',
    require: { container: '^financeBillingAccountsContainer' },
    bindings: {
      onSelectBillingAccount: '&',
      requestParams: '<',
    },
  });

  BillingAccountsListingController.$inject = ['$timeout', 'abp.service.finance.billingAccount'];

  function BillingAccountsListingController($timeout, billingAccountSvc) {
    const vm = this;
    vm.loading = false;
    vm.hasMore = false;
    vm.billingAccounts = [];
    vm.billingAccount = null;

    vm.reload = reload;
    vm.loadMoreBillingAccounts = loadMoreBillingAccounts;
    vm.selectBillingAccount = selectBillingAccount;

    function reload() {
      vm.billingAccounts = [];
      vm.hasMore = true;
      $timeout(() => {
        vm.loadMoreBillingAccounts();
      }, 0);
    }

    function loadMoreBillingAccounts() {
      if (vm.loading || !vm.hasMore) return;

      vm.container.registerStateParams();

      const input = _.assignIn({}, vm.requestParams, {
        skipCount: vm.billingAccounts.length,
        maxResultCount: 20,
      });

      vm.loading = true;
      billingAccountSvc
        .getBillingAccounts(input)
        .success((data) => {
          let hasNewData = false;
          let checkDuplicate = true;
          _.forEach(data, (d) => {
            if (checkDuplicate) {
              const exists = _.findLastIndex(vm.billingAccounts, { id: d.id }) !== -1;
              if (exists) return;
              checkDuplicate = false;
            }
            vm.billingAccounts.push(d);
            hasNewData = true;
          });
          vm.hasMore = hasNewData;
        })
        .finally(() => {
          vm.loading = false;
        });
    }

    function selectBillingAccount(billingAccount) {
      if (billingAccount === vm.billingAccount) return;
      vm.billingAccount = billingAccount;
      vm.requestParams.billingAccount = billingAccount;
      vm.container.registerStateParams();
      vm.onSelectBillingAccount({ billingAccount });
    }

    $timeout(vm.reload, 0);
  }
})();
