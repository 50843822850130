(function () {
    var controllerId = 'corporate.views.panelAccesses.page';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$state', '$uibModal', 'abp.services.app.panelAccess',
        function ($scope, $state, $uibmodal, panelAccessSvc) {
            function init() {
                vm.getPolicies();
            }

            var vm = this;
            vm.loading = 0;
            vm.generalPolicy = null;
            vm.dataExportRecordName = 'PanelListPdf';
            vm.generateButtonType = App.localize('DownloadPanelListing');

            vm.policies = [];

            vm.permissions = {
                accessPolicy: abp.auth.isGranted('Corporate.PanelAccess.AccessPolicy'),
                createAccessPolicy: abp.auth.isGranted('Corporate.PanelAccess.AccessPolicy.CreateAndEdit'),
                deleteAccessPolicy: abp.auth.isGranted('Corporate.PanelAccess.AccessPolicy.Delete'),
                tenantSettings: abp.auth.isGranted('Administration.Tenant.Settings')
            };

            vm.setNotification = setNotification;

            function checkFormDirty() {
                // HACK
                return angular.element('[name="panelAccessPolicyForm"]').scope()
                    && angular.element('[name="panelAccessPolicyForm"]').scope().panelAccessPolicyForm.$dirty;
            }

            function cleanForm() {
                if (angular.element('[name="panelAccessPolicyForm"]').scope()) {
                    angular.element('[name="panelAccessPolicyForm"]').scope().panelAccessPolicyForm.$setPristine();
                }
            }

            vm.getPolicies = function () {
                vm.loading++;

                panelAccessSvc.getAccessPolicies()
                    .success(function (data) {
                        vm.policies = data.items;
                        vm.hasNotificationSetting = data.hasNotificationSetting;
                        vm.generalPolicy = _.find(vm.policies, function (p) { return p.type === 1; });
                    })
                    .finally(function (e) {
                        vm.policyId ? vm.viewPolicy(vm.policyId) : vm.viewPolicy(vm.generalPolicy.id);
                        vm.loading--;
                    });
            };

            vm.viewPolicy = function (policyId) {
                function load() {
                    vm.isCopy = false;
                    vm.policyId = policyId;
                    cleanForm();
                }
                if (checkFormDirty()) {
                    abp.message.confirm(App.localize('UnsavedChangesConfirmation'), App.localize('AreYouSure'), function (d) {
                        if (d) load();
                    });
                }
                else load();
            };

            vm.createPolicy = function () {
                function load() {
                    vm.isCopy = false;
                    vm.policyId = null;
                    cleanForm();
                }
                if (checkFormDirty()) {
                    abp.message.confirm(App.localize('UnsavedChangesConfirmation'), App.localize('AreYouSure'), function (d) {
                        if (d) load();
                    });
                }
                else load();
            };

            vm.clonePolicy = function (policyId) {
                function load() {
                    cleanForm();
                    var modal = $uibmodal.open({
                        templateUrl: require('/App/corporate/views/panelAccesses/clonePanelAccessPolicy/clonePanelAccessPolicy.modal.html'),
                        controller: 'corporate.views.panelAccesses.clonePanelAccessPolicyModal as vm',
                        backdrop: 'static'
                    });
                    modal.result.then(function (result) {
                        vm.cloneBenefitgroups = result.benefitGroups;
                        vm.cloneInpatientBenefitGroups = result.inpatientBenefitGroups
                        vm.cloneInsurancePlans = result.insurancePlans;
                        vm.isCopy = true;
                        vm.policyId = policyId;
                    });
                }
                if (checkFormDirty()) {
                    abp.message.confirm(App.localize('UnsavedChangesConfirmation'), App.localize('AreYouSure'), function (d) {
                        if (d) load();
                    });
                }
                else load();
            };

            // Update policy Id to null for clone policy

            vm.updatePolicyId = function (id) {
                vm.policyId = id;
            };

            vm.deletePolicy = function (policy) {
                abp.message.confirm(App.localize('PanelAccessPolicyDeleteWarningMessage', policy.name), App.localize('AreYouSure'), function (d) {
                    if (d) {
                        vm.loading++;
                        panelAccessSvc.delete({ id: policy.id }).success(function () {
                            vm.getPolicies();
                        }).finally(function () {
                            vm.loading--;
                        });
                        vm.isCopy = false;
                        cleanForm();
                        vm.viewPolicy(vm.generalPolicy.id);
                    }
                });
            };

            function setNotification() {
                function load() {
                    $state.go('tenant.settings', { tab: 1 });
                }

                if (checkFormDirty()) {
                    abp.message.confirm(App.localize('UnsavedChangesConfirmation'), App.localize('AreYouSure'), function (d) {
                        if (d) load();
                    });
                }
                else load();
            }

            init();
        }
    ]);
})();
