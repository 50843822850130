(function () {
    angular.module('app').controller('clinic.views.panelDashboard.dismissExpiredSessionModal', [
        '$uibModalInstance',
        function ($uibModalInstance) {
            var vm = this;

            vm.cancel = cancel;
            vm.confirm = confirm;
            vm.tidyReason = tidyReason;
            vm.reason = '';
            vm.remarks = '';

            function cancel() {
                $uibModalInstance.dismiss();
            }

            function confirm() {
                var reason = tidyReason();
                if (!reason) return;
                $uibModalInstance.close(reason);
            }

            function tidyReason() {
                var reason = _.trim(vm.reason);
                if (reason === 'PatientPayCash') {
                    reason = '';
                } else if (reason === 'Others') {
                    var remarks = _.trim(vm.remarks);
                    if (remarks) {
                        reason += ': ' + remarks;
                    } else {
                        reason = '';
                    }
                }
                return reason;
            }
        }
    ]);
})();
