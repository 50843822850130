/**
 * A simple container to represent an item in PromptQueue.
 */
class Prompt {
  /**
   * The function to call.
   */
  callee;

  /**
   * The original arguments to be passed to callee.
   */
  arguments;

  /**
   *
   * @param {Function} callee The function to call.
   * @param  {...any} args The original arguments to be passed to callee.
   */
  constructor(callee, ...args) {
    this.callee = callee;
    this.arguments = args;
  }
}

export default Prompt;
