(() => {
  angular
    .module('app')
    .controller(
      'clinic.view.postHospitalization.guaranteeLetters.index',
      GuaranteeLettersPageController
    );

  GuaranteeLettersPageController.$inject = [
    '$scope',
    '$stateParams',
    '$state',
    '$timeout',
    'abp.services.app.guaranteeLetter',
    'abp.services.app.commonLookup',
    'abp.services.app.hostServiceRequest',
    'Hms.GuaranteeLetters.GuaranteeLetterStatus',
    'moment',
    'appConstants',
  ];

  function GuaranteeLettersPageController(
    $scope,
    $stateParams,
    $state,
    $timeout,
    guaranteeLetterSvc,
    commonLookupSvc,
    hostServiceRequestSvc,
    enumGuaranteeLetterStatus,
    moment,
    appConstants
  ) {
    const vm = this;

    vm.loading = 0;
    vm.exporting = 0;
    vm.hasPostHospitalizationWorkflowEnhancement = abp.setting.getBoolean(
      'Hms.Feature.PostHospitalizationGuaranteeLetterWorkflow'
    );
    vm.isInpatient = App.isInpatient();
    vm.clinicLocations = [];

    vm.permissions = {
        canCreateTicket:
          abp.auth.isGranted('PatientTickets.Clinic.Create') ||
          abp.auth.isGranted('SpecialistClaims.Clinic.Create'),
        canViewTickets: abp.auth.isGranted('PatientTickets'),
      };
      
    vm.enums = {};
    vm.enums.guaranteeLetterStatus = enumGuaranteeLetterStatus;

    vm.requestParams = {
      filter: $stateParams.filter,
      status: $stateParams.status,
      letterNumber: $stateParams.letterNumber,
      clinicLocationId: $stateParams.clinicLocationId,
      skipCount: $stateParams.skipCount || 0,
      maxResultCount: $stateParams.maxResultCount || appConstants.grid.defaultPageSize,
      sorting: $stateParams.sorting,
      admissionGuaranteeLetterNumber: $stateParams.admissionGuaranteeLetterNumber,
    };

    vm.checkedInDateRangeOptions = App.createDateRangePickerOptions();
    vm.checkedInDateRangeModel = {
      startDate: $stateParams.checkedInStartDate ? moment($stateParams.checkedInStartDate) : null,
      endDate: $stateParams.checkedInEndDate ? moment($stateParams.checkedInEndDate) : null,
    };

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSizes,
      paginationPageSize: appConstants.grid.defaultPageSize,
      paginationCurrentPage: 1,
      useExternalPagination: true,
      useExternalSorting: true,
      resizable: true,
      columnDefs: [
        {
          name: 'Actions',
          displayName: 'Actions',
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
        },
        {
          displayName: App.localize('CheckedInDate'),
          enableSorting: false,
          field: 'checkedInTime',
          cellFilter: "momentFormat: 'L'",
          minWidth: 70,
        },
        {
          displayName: App.localize('Status'),
          field: 'status',
          minWidth: 100,
          cellTemplate: 'statusTemplate',
        },
        {
          displayName: App.localize('GuaranteeLetterNumber'),
          field: 'letterNumber',
          minWidth: 100,
          width: 150,
        },
        {
          displayName: App.localize('PatientName'),
          enableSorting: false,
          field: 'employeeName',
          minWidth: 120,
        },
        {
          displayName: App.localize('PatientNationalId'),
          enableSorting: false,
          field: 'employeeNationalId',
        },
        {
          displayName: App.localize('AdmissionGuaranteeLetterNumber'),
          field: 'admissionGuaranteeLetterNumber',
          minWidth: 100,
          width: 150,
        },
        {
          displayName: App.localize('Doctors'),
          field: 'doctors',
          enableSorting: false,
          minWidth: 120,
        },
        {
          displayName: App.localize('PanelLocation'),
          enableSorting: false,
          field: 'panelLocationName',
          minWidth: 120,
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          vm.requestParams.sorting =
            callback.length && callback[0].field
              ? `${callback[0].field} ${callback[0].sort.direction}`
              : null;
          vm.getGuaranteeLetters();
        });
        gridApi.pagination.on.paginationChanged($scope, (newPage, pageSize) => {
          vm.requestParams.skipCount = (newPage - 1) * pageSize;
          vm.requestParams.maxResultCount = pageSize;
          vm.getGuaranteeLetters();
        });
      },
      data: [],
    };

    vm.getGuaranteeLetters = getGuaranteeLetters;
    vm.exportToExcel = exportToExcel;
    vm.applyPostHospitalizationFgl = applyPostHospitalizationFgl;
    vm.checkInGL = checkInGL;

    $scope.$on('$viewContentLoaded', () => {
      App.initAjax();
    });

    init();

    function init() {
      loadClinicLocationsFilter();
      vm.getGuaranteeLetters();

      $timeout(() => {});

      getTimeZoneId();
    }

    function loadClinicLocationsFilter() {
      vm.loading += 1;
      commonLookupSvc
        .findClinicLocations({})
        .success((data) => {
          vm.clinicLocations = data.items;
        })
        .finally(() => {
          if (!vm.clinicLocations.length) {
            vm.requestParams.clinicLocationId = null;
          }
          vm.loading -= 1;
        });
    }

    function getRequestInput() {
      const input = $.extend({}, vm.requestParams);
      input.checkedInStartDate = vm.checkedInDateRangeModel.startDate;
      input.checkedInEndDate = vm.checkedInDateRangeModel.endDate;

      registerStateParams();

      return input;
    }

    function getGuaranteeLetters() {
      const input = getRequestInput();

      vm.loading += 1;
      guaranteeLetterSvc
        .getGuaranteeLettersForPostHospitalization(input)
        .success((data) => {
          vm.gridOptions.totalItems = data.totalCount;
          vm.gridOptions.data = _.map(data.items, (d) =>
            $.extend(d, {
              isMalaysian: d.patientEmployeeNationalityCode === 'MY',
              isActive: !(d.status === 3 || d.status === 4 || d.status === 5),
              isSelfManage: vm.isHost && d.status === 0 && d.preferSelfManageGl,
            })
          );
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function registerStateParams() {
      const checkedInStartDate = vm.checkedInDateRangeModel.startDate
        ? vm.checkedInDateRangeModel.startDate.format('YYYY-MM-DD')
        : null;
      const checkedInEndDate = vm.checkedInDateRangeModel.endDate
        ? vm.checkedInDateRangeModel.endDate.format('YYYY-MM-DD')
        : null;

      $state.transitionTo(
        $state.current,
        {
          filter: vm.requestParams.filter,
          status: vm.requestParams.status,
          clinicLocation: vm.requestParams.clinicLocationId,
          checkedInStartDate,
          checkedInEndDate,
          skipCount: vm.requestParams.skipCount,
          maxResultCount: vm.requestParams.maxResultCount,
          sorting: vm.requestParams.sorting,
          admissionGuaranteeLetterNumber: vm.requestParams.admissionGuaranteeLetterNumber,
        },
        {
          notify: false,
        }
      );
    }

    function exportToExcel() {
      const input = getRequestInput();

      vm.exporting += 1;
      guaranteeLetterSvc
        .getGuaranteeLettersForPostHospitalizationToExcel(input)
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.exporting -= 1;
        });
    }

    function getTimeZoneId() {
      vm.loading += 1;
      commonLookupSvc
        .getTenantIanaTimeZoneId({})
        .success((data) => {
          vm.timeZoneId = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function applyPostHospitalizationFgl(letterNumber, claimWithTopUp) {
      $state.go('postHospitalizationFglRequest', {
        letterNumber,
        claimWithTopUp,
        timeZoneId: vm.timeZoneId,
      });
    }

    function checkInGL(employeeName, letterNumber) {
      vm.loading += 1;
      hostServiceRequestSvc
        .createAndApprovePostHospitalizationCheckInRequest({
          id: letterNumber,
        })
        .success(() => {
          abp.notify.info(App.localize('PatientXCheckedIn', employeeName));
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
