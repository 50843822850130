import swal from 'sweetalert';
import PromptQueue from '../../../../lib/prompt-queue';

(function () {
    const controllerId = 'pharmacy.views.prescriptions.detail';
    const app = angular.module('app');

    app.controller(controllerId, [
        '$state', '$stateParams', '$timeout', '$uibModal', 'abp.services.app.prescription',
        function ($state, $stateParams, $timeout, $uibModal, prescriptionSvc) {
            function refreshPrescription() {
                vm.loading++;
                prescriptionSvc.getPrescription({
                    prescriptionNumber: vm.prescriptionNumber
                }).success(function (data) {
                    if (data.isAcknowledged) {
                        vm.acknowledge = {
                            isEdit: false,
                            referenceNumber: data.referenceNumber,
                            fulfillmentCount: data.fulfillmentCount,
                            pharmacyRemarks: data.pharmacyRemarks
                        };
                    } else {
                        vm.acknowledge = { isEdit: true };
                    }

                    vm.acknowledge = {
                        isEdit: false,
                        isSaving: false,
                        update: function () {
                            if (vm.acknowledge.isSaving) return;
                            vm.acknowledge.isSaving = true;
                            prescriptionSvc.acknowledgePrescription({
                                id: vm.prescription.id,
                                referenceNumber: vm.acknowledge.referenceNumber,
                                fulfillmentCount: vm.acknowledge.fulfillmentCount,
                                pharmacyRemarks: vm.acknowledge.pharmacyRemarks
                            }).success(function () {
                                abp.notify.info(App.localize('SuccessfullySaved'));
                                refreshPrescription();
                                vm.onAuditHistoryRefreshEvent.handler();
                            }).finally(function () {
                                vm.acknowledge.isSaving = false;
                            });
                        }
                    };

                    vm.prescription = data;

                    vm.currentFulfillmentsCount = _.filter(vm.prescription.fulfillments, function (d) {
                        return d.isVoided == false;
                    }).length;

                    // Get employee balance according to organization's limit.

                    vm.customerAllowance = Math.min(data.customerOrganizationAllowance, data.customerOrganizationLimit);

                }).finally(function () {
                    vm.loading--;
                });
            }

            function refreshFulfillment() {
                if (vm.fulfillmentNumber) {
                    vm.loading++;
                    prescriptionSvc.getFulfillment({
                        fulfillmentNumber: vm.fulfillmentNumber
                    }).success(function (data) {
                        vm.fulfillment = data;
                    }).finally(function () {
                        vm.loading--;
                    });
                } else {
                    vm.fulfillment = null;
                }
            }

            const vm = this;
            vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
            vm.loading = 0;
            vm.prescriptionNumber = $stateParams.prescriptionNumber;
            vm.fulfillmentNumber = $stateParams.fulfillmentNumber;
            vm.prescription = null;
            vm.acknowledge = null;
            vm.permissions = {
                editPrescription: abp.auth.isGranted('Pharmacy.Prescriptions.Edit')
            };

            vm.onAuditHistoryRefreshEvent = { handler: null };

            vm.editPharmacyRecord = function () {
                if (!vm.prescription || (vm.acknowledge && vm.acknowledge.isEdit)) return;

                vm.acknowledge.referenceNumber = vm.prescription.referenceNumber;
                vm.acknowledge.fulfillmentCount = vm.prescription.fulfillmentCount;
                vm.acknowledge.pharmacyRemarks = vm.prescription.pharmacyRemarks;
                vm.acknowledge.isEdit = true;
            };

            vm.showFulfillment = function (fulfillmentNumber) {
                vm.fulfillmentNumber = fulfillmentNumber;
                refreshFulfillment();

                $state.transitionTo($state.current, {
                    prescriptionNumber: vm.prescriptionNumber,
                    fulfillmentNumber: vm.fulfillmentNumber
                }, { notify: false });
            };

            vm.voidFulfillment = function () {
                var remarks = '';

                if (!vm.fulfillment) return;

                var canSubmit = false;
                const prompts = new PromptQueue();

                prompts.add(swal,
                    {
                        title: App.localize('VoidFulfillment'),
                        text: App.localize('WhyVoidFulfillment'),
                        type: 'input',
                        showCancelButton: true,
                        closeOnConfirm: false,
                        confirmButtonColor: '#1ab394',
                        inputPlaceholder: App.localize('PleaseExplain')
                    },
                    (inputValue) => {
                        if (inputValue === false) {
                            return false;
                        }

                        if ($.trim(inputValue) === '') {
                            swal.showInputError(App.localize('InvalidInput'));
                            return false;
                        }

                        canSubmit = true;
                        remarks = $.trim(inputValue);
                        swal.close();
                        return true;
                    }
                );

                var msg = App.localize("VoidFulfillmentWarningMessage", vm.fulfillment.fulfillmentNumber);
                prompts.add(
                  abp.message.confirm,
                  msg,
                  App.localize('AreYouSure'),
                  (d) => canSubmit = d
                );

                prompts.run(() => {
                    if(canSubmit) voidCore(remarks);
                });
            };

            function voidCore(remarks) {
                vm.loading++;
                prescriptionSvc.voidFulfillment({
                    id: vm.fulfillment.id,
                    remarks: remarks
                }).success(function (data) {
                    vm.fulfillment = null;
                    refreshPrescription();
                    vm.onAuditHistoryRefreshEvent.handler();
                }).finally(function () {
                    vm.loading--;
                });
            };

            vm.fulfill = function () {
                $uibModal.open({
                    templateUrl: require('/App/pharmacy/views/prescriptions/fulfillModal.html'),
                    controller: "pharmacy.views.prescriptions.fulfillModal as vm",
                    backdrop: "static",
                    size: 'lg',
                    resolve: {
                        prescription: function () {
                            return vm.prescription;
                        },
                        customerAllowance: function () {
                            return vm.customerAllowance;
                        }
                    }
                }).result.then(function (data) {
                    vm.fulfillmentNumber = data.fulfillmentNumber;
                    refreshPrescription();
                    refreshFulfillment();
                    vm.onAuditHistoryRefreshEvent.handler();
                });
            };

            refreshPrescription();
            refreshFulfillment();
        }
    ]);
})();
