(() => {
  angular
    .module('app')
    .controller(
      'common.views.guaranteeLetters.details.letterDetail.rejectModal',
      RejectModalController
    );

  RejectModalController.$inject = ['$uibModalInstance', 'abp.services.app.guaranteeLetter'];

  function RejectModalController($uibModalInstance, guaranteeLetterSvc) {
    const vm = this;

    vm.isHost = App.isHost();
    vm.selectOthers = false;

    vm.disableReject = disableReject;
    vm.reject = reject;
    vm.cancel = cancel;

    init();

    function init() {
      if (vm.isHost) {
        vm.checklistItems = [
          { text: 'Insufficient corporate limit' },
          { text: 'Insufficient employee limit' },
          { text: 'Treatment or discipline not covered' },
          {
            text: 'Invalid referral letter/appointment card/vaccination schedule/prescription list, or date exceeded validity days limit',
          },
          { text: 'Uploaded documents unclear' },
          { text: 'Others', requiredInput: true },
        ];
      } else {
        vm.loading+=1;
        guaranteeLetterSvc
          .getGuaranteeLetterForReject()
          .success((data) => {
            vm.signatureProfiles = data.items;
          })
          .finally(() => {
            vm.loading-=1;
          });
      }
    }

    function disableReject() {
      if (vm.isHost) {
        // Disable button if reason for Others is empty or not if the choice is Others.

        const hasEmptyOtherInput = _.some(vm.checklistItems, (d) => {
          const input = _.trim(d.input);
          return d.isChecked && d.text === 'Others' && input.length <= 0;
        });

        if (hasEmptyOtherInput) return true;

        // Disable button if any checkbox are NOT checked.

        const IsAllItemsChecked = _.every(vm.checklistItems, (d) => !d.isChecked);

        return IsAllItemsChecked;
      }

      // Disable button if no signature profile selected.

      let selectedCount = 0;
      _.each(vm.signatureProfiles, (d) => {
        if (d.isSelected) {
          selectedCount+=1;
        }
      });

      return selectedCount !== 1 || getRemarks() === '';
    }

    function getRemarks() {
      if (!vm.isHost) {
        return _.trim(vm.remarks || '');
      }

      let output = '';
      _.each(vm.checklistItems, (d) => {
        if (d.isChecked) {
          output += `${d.text + (d.requiredInput ? `: ${  d.input}` : '')  }.\n`;
        }
      });
      return output;
    }

    function reject() {
      const profile =
        _.find(vm.signatureProfiles, (d) => d.isSelected) || {};
      $uibModalInstance.close({ signatureProfileId: profile.id, remarks: getRemarks() });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
