(() => {
  angular.module('app').component('commonInpatientNprClaimsDetailsRecentClaimsComponent', {
    templateUrl: require('./recentClaims.component.html'),
    controller: RecentClaimsController,
    controllerAs: 'vm',
    bindings: {
      isInpatient: '<',
    },
  });

  RecentClaimsController.$inject = [
    '$stateParams',
    'abp.services.app.nprClaim',
    'abp.services.app.claim',
    'Hms.Claims.ClaimStatus',
    'Hms.Services.ServiceType',
  ];

  function RecentClaimsController(
    $stateParams,
    nprClaimSvc,
    claimSvc,
    enumClaimStatus,
    enumServiceType
  ) {
    const vm = this;

    vm.loading = 0;
    vm.records = [];
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

    vm.enums = {
      claimStatus: enumClaimStatus,
      serviceType: enumServiceType,
    };

    vm.permissions = {
      canViewTicket: abp.auth.isGranted('PatientTickets'),
    };

    vm.getDateTimeString = getDateTimeString;

    init();

    function init() {
      if (vm.isInpatient) {
        getInpatientRecentClaims();
      } else {
        getOutpatientRecentClaims();
      }
    }

    function getInpatientRecentClaims() {
      vm.loading += 1;
      nprClaimSvc
        .getRecentClaimsTickets({
          id: $stateParams.claimNumber,
        })
        .success((data) => {
          vm.records = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getOutpatientRecentClaims() {
      vm.loading += 1;
      claimSvc
        .getRecentClaimsTickets({
          id: $stateParams.claimNumber,
        })
        .success((data) => {
          vm.records = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getDateTimeString(date, timeZoneId) {
      return App.getDateTimeString(date, timeZoneId);
    }
  }
})();
