(() => {
  angular
    .module('app')
    .controller(
      'common.views.saleTransactions.subscriptionInvoice.page',
      SubscriptionInvoicePageController
    );

  SubscriptionInvoicePageController.$inject = [
    '$stateParams',
    'currencies',
    'numberFilter',
    'abp.services.app.saleTransaction',
    'abp.services.app.report',
  ];

  function SubscriptionInvoicePageController(
    $stateParams,
    currencies,
    numberFilter,
    saleTransactionSvc,
    reportSvc
  ) {
    const vm = this;

    vm.transactionNumber = $stateParams.transactionNumber;
    vm.invoice = null;
    vm.invoiceFrom = null;
    vm.invoiceTo = null;
    vm.message = null;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.loading = 0;
    vm.exportToExcel = exportToExcel;

    const currency = _.find(currencies, { code: vm.currencyCode });
    vm.decimalPlaces = currency ? currency.decimalPlaces : 2;

    init();

    function init() {
      vm.loading += 1;
      saleTransactionSvc
        .getSubscriptionInvoice({
          id: vm.transactionNumber,
        })
        .success((data) => {
          vm.invoice = data.invoice;
          vm.invoiceFrom = data.invoiceFrom;
          vm.invoiceTo = data.invoiceTo;
          vm.saleTransactionItems = data.invoice.subscriptions.saleTransactionItems;
          vm.saleTransactionDetailedCharges =
            data.invoice.subscriptions.saleTransactionDetailedCharges;
          vm.hasDetailedCharges = vm.saleTransactionDetailedCharges.length > 0;
          vm.message = data.message;

          if (data.currencyCode) vm.currencyCode = data.currencyCode;

          vm.taxSummaries = _.chain(vm.saleTransactionItems)
            .groupBy((d) => (d.taxDescription || d.taxCode || '').trim().toUpperCase())
            .map((v, k) => {
              const dto = {
                taxCode: k,
                preTaxAmount: _.sumBy(v, 'preTaxAmount'),
                taxAmount: _.sumBy(v, 'taxAmount'),
              };

              dto.description = App.localize(
                'TaxCodeXOnY',
                dto.taxCode,
                numberFilter(dto.preTaxAmount, vm.decimalPlaces)
              );

              return dto;
            })
            .filter('taxCode')
            .sortBy('taxCode')
            .value();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function exportToExcel() {
      vm.loading += 1;
      reportSvc
        .getInitialChargeableMemberListing({
          tenantId: vm.invoice.tenantId,
          transactionNumber: vm.invoice.transactionNumber,
        })
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
