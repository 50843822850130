(function () {
    'use strict';

    angular.module('app').component('corporateBenefitGroupsInpatientBenefitDetailMasterPolicyComponent', {
        templateUrl: require('/App/corporate/views/benefitGroups/inpatient/benefitDetail/masterPolicy/masterPolicy.component.html'),
        controllerAs: 'vm',
        bindings: {
            masterPolicy: '<',
            insurer: '<'
        }
    });
})();
