(function () {
    var controllerId = 'host.views.employees.uncoveredTreatmentTreeModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$uibModalInstance', 'abp.services.app.hostEmployee', 'employeeId', 'serviceType', 'serviceTypeName', '$rootScope',
        function ($uibModalInstance, hostEmployeeSvc, employeeId, serviceType, serviceTypeName, $root) {
            function init() {
                getTreatments();
            }
            var vm = this;
            var elementId = 'treatmentTree';
            vm.loading = 0;
            vm.serviceTypeName = serviceTypeName;

            function getTreatments() {
                vm.loading++;
                hostEmployeeSvc.getTreatments({
                    id: employeeId,
                    serviceType: serviceType
                }).success(function (data) {
                    vm.treatments = data.items;
                    vm.excludeTypicallyUncoveredSpecialistTreatments = data.excludeTypicallyUncoveredSpecialistTreatments;
                    buildTreatmentTree(elementId);
                }).finally(function () {
                    vm.loading--;
                });
            }

            function buildTreatmentTree(elementId) {
                var element = $('#' + elementId);
                var ref = $.jstree.reference(element);
                if (ref) {
                    ref.destroy();
                }

                var coveredNodeIcon = '<i class="fas fa-check text-navy"></i> ';
                var uncoveredNodeIcon = '<i class="fas fa-times text-danger"></i> ';
                function getNodeText(data) {
                    return (data.isCovered ? coveredNodeIcon : uncoveredNodeIcon) + data.displayName;
                }

                var treatments = _.map(vm.treatments, function (record) {
                    return {
                        id: record.id,
                        parent: record.parentId ? record.parentId : '#',
                        displayName: record.displayName,
                        text: getNodeText(record),
                        state: {
                            opened: record.hasUncoveredChildren
                        }
                    };
                });

                // only show if is specialist.

                if (serviceType === 5) {
                    var icon = vm.excludeTypicallyUncoveredSpecialistTreatments ? uncoveredNodeIcon : coveredNodeIcon;
                    treatments.unshift({
                        id: -1,
                        parent: '#',
                        text: icon + '<strong>Typically uncovered specialist treatments</strong> <a>(View)</a>',
                        a_attr: { href: $root.resolveAppPath('Print/TypicallyUncoveredSpecialistTreatments') }
                    });
                }

                element.jstree({
                    core: {
                        data: treatments
                    },
                    types: {
                        'default': {
                            icon: 'fas fa-folder tree-item-icon-color icon-lg'
                        },
                        file: {
                            icon: 'fas fa-file tree-item-icon-color icon-lg'
                        }
                    },
                    search: {
                        fuzzy: false,
                        show_only_matches: true,
                        show_only_matches_children: true,
                    },
                    sort: function (itemA, itemB) {
                        var nodeA = this.get_node(itemA);
                        var nodeB = this.get_node(itemB);
                        if (nodeB.original.id === -1) { return 1; }
                        if (nodeA.children_d.length > 0 && nodeB.children_d.length === 0) { return -1; }

                        return nodeB.original.displayName < nodeA.original.displayName ? 1 : -1;
                    },
                    plugins: ['types', 'search', 'sort']
                }).bind("select_node.jstree", function (e, data) {
                    var href = data.node.a_attr.href;
                    if (href === '#')
                        return '';

                    window.open(href);
                });
            }

            vm.search = function () {
                var element = $('#' + elementId);
                element.jstree('search', _.trim(vm.filter));
            };

            init();

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };
        }
    ]);
})();
