(function () {
    var componentId = 'hostOperationsDashboardClinicIssuesComponent';
    var app = angular.module('app');

    function controller($uibModal, operationsDashboard) {
        function init() {
            vm.refresh();
        }

        var vm = this;
        vm.widgetName = componentId;
        vm.loading = 0;
        vm.countryCode = null;

        vm.requestParam = {
            countryCode: vm.countryCode
        };

        vm.permissions = {
            edit: abp.auth.isGranted('Host.Clinics.Edit')
        };

        vm.$onInit = function () {
            vm.onAddWidget({ widget: this });
        };

        function setTotalCount() {
            var item = _.find(vm.widgets, function (data) {
                return data.id === componentId;
            });
            item.totalCount = vm.totalCount;
            item.loading = false;
        }

        function getClinicIssues() {
            vm.loading++;
            operationsDashboard.getClinicIssues(vm.requestParam)
                .success(function (data) {
                    vm.data = _.each(data.items, function (d) {
                        d.issues.location = _.map(d.issues.location, function (i) {
                            return App.localize(i.issueType, i.arguments);
                        });
                        d.issues.time = _.map(d.issues.time, function (i) {
                            return App.localize(i.issueType, i.arguments);
                        });
                        d.issues.others = _.map(d.issues.others, function (i) {
                            return App.localize(i.issueType, i.arguments);
                        });
                    });
                    vm.totalCount = vm.data.length;
                    vm.setFilters();
                    setTotalCount();
                }).finally(function () {
                    vm.loading--;
                });
        }

        vm.refresh = function () {
            vm.data = [];
            vm.showAll = false;
            vm.showLocationOnly = true;
            vm.showTimeOnly = true;
            vm.showOthers = true;
            vm.requestParam.countryCode = vm.countryCode;
            getClinicIssues();
        };

        vm.toggleShowData = function () {
            vm.showAll = !vm.showAll;
            showData();
        };

        function showData() {
            vm.displayRecords = vm.showAll ? vm.records : vm.records.slice(0, 5);
        }

        vm.editLocation = function (record) {
            $uibModal.open({
                templateUrl: require('/App/host/views/clinics/createOrEditLocationModal.html'),
                controller: 'host.views.clinics.createOrEditLocationModal as vm',
                backdrop: 'static',
                resolve: {
                    clinicId: function () {
                        return record.clinicId;
                    },
                    locationId: function () {
                        return record.clinicLocationId;
                    },
                    clinicType: function () {
                        return null;
                    },
                    requestNumber: function () {
                        return null;
                    }
                }
            });
        };

        vm.setFilters = function () {
            vm.records = vm.data;
            _.each(vm.records, function (d) {
                var issueMessages = [];
                if (vm.showLocationOnly)
                    issueMessages = d.issues.location;
                if (vm.showTimeOnly)
                    issueMessages = issueMessages.concat(d.issues.time);
                if (vm.showOthers)
                    issueMessages = issueMessages.concat(d.issues.others);
                if (issueMessages) d.issues.issueMessages = issueMessages;
            });
            vm.records = _.filter(vm.records, function (d) { return d.issues.issueMessages.length > 0; });
            vm.records = _.orderBy(vm.records, function (d) { return d.issues.issueMessages.length; }, 'desc');
            showData();
        };

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/operationsDashboard/clinicIssues/clinicIssues.component.html'),
        controller: ['$uibModal', 'abp.services.app.operationsDashboard', controller],
        controllerAs: 'vm',
        bindings: {
            widgets: '<',
            onAddWidget: '&',
            countryCode: '=?'
        }
    });
})();
