(() => {
  angular
    .module('revenue')
    .controller('revenue.views.subscriptionPlans', SubscriptionPlansController);

  SubscriptionPlansController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$timeout',
    '$uibModal',
    'abp.services.revenue.subscriptionPlan',
    'abp.services.revenue.common',
    'abp.services.app.commonLookup',
    'revenueConstants',
    'Hms.Revenue.SubscriptionPlanClientType',
    'Hms.Revenue.SubscriptionPlanStatus',
  ];

  function SubscriptionPlansController(
    $scope,
    $state,
    $stateParams,
    $timeout,
    $uibModal,
    subscriptionPlanSvc,
    revenueCommonSvc,
    commonLookupSvc,
    revenueConsts,
    enumSubscriptionPlanClientType,
    enumSubscriptionPlanStatus
  ) {
    const vm = this;

    vm.loading = 0;
    vm.subscriptionPlans = [];
    vm.subscriptionPlansOld = [];
    vm.filteredAccountOwners = [];
    vm.enums = {};
    vm.enums.subscriptionPlanClientType = enumSubscriptionPlanClientType;
    vm.enums.subscriptionPlanStatus = enumSubscriptionPlanStatus;

    vm.requestParams = {
      countryCode: null,
      accountOwnerId: null,
      tenantType: null,
      planStatus: null,
      view: $stateParams.view,
      skipCount: $stateParams.skipCount,
      maxResultCount: $stateParams.maxResultCount,
      sorting: $stateParams.sorting,
    };

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: revenueConsts.grid.defaultPageSizes,
      paginationPageSize: revenueConsts.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      resizable: true,
      columnDefs: [
        {
          name: App.localize('Actions'),
          displayName: App.localize('Actions'),
          enableSorting: false,
          width: 80,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'viewTemplate',
        },
        {
          displayName: App.localize('AccountOwner'),
          name: App.localize('AccountOwner'),
          field: 'accountOwnerName',
          minWidth: 80,
          cellTemplate: 'accountOwnerTemplate',
        },
        {
          displayName: App.localize('TenantType'),
          name: App.localize('TenantType'),
          field: 'tenantType',
          minWidth: 80,
          cellTemplate: 'tenantTypeTemplate',
        },
        {
          displayName: App.localize('PlanName'),
          name: App.localize('PlanName'),
          field: 'planName',
          minWidth: 80,
        },
        {
          displayName: App.localize('PlanStatus'),
          name: App.localize('PlanStatus'),
          field: 'planStatus',
          minWidth: 80,
          cellTemplate: 'planStatusTemplate',
        },
        {
          displayName: App.localize('EffectiveDate'),
          name: App.localize('EffectiveDate'),
          field: 'effectiveDate',
          cellFilter: "momentFormat: 'L LT'",
          minWidth: 80,
        },
        {
          displayName: App.localize('CreatedBy'),
          name: App.localize('CreatedBy'),
          field: 'createdBy',
          minWidth: 80,
        },
        {
          displayName: App.localize('LastUpdatedBy'),
          name: App.localize('LastUpdatedBy'),
          field: 'lastUpdatedBy',
          minWidth: 80,
        },
      ],
      onRegisterApi: (gridApi) => {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          let sorting = '';
          for (let i = 0; i < callback.length; i += 1) {
            if (i) sorting += ', ';
            sorting += `${callback[i].field} ${callback[i].sort.direction}`;
          }
          vm.requestParams.sorting = sorting;
          getSubscriptionPlansListing();
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, vm.getSubscriptionPlansListing)
        );
      },
      data: [],
    };

    vm.gridOldOptions = {
      appScopeProvider: vm,
      columnDefs: [
        {
          enableSorting: false,
          name: App.localize('Actions'),
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
        },
        {
          displayName: App.localize('ColorCode'),
          name: App.localize('ColorCode'),
          field: 'colorCode',
          width: 30,
          cellTemplate: 'colorTemplate',
        },
        {
          displayName: App.localize('Name'),
          name: App.localize('Name'),
          field: 'name',
          minWidth: 80,
        },
        {
          displayName: App.localize('Price'),
          name: App.localize('Price'),
          field: 'pricePerPaxPerMonth',
          minWidth: 80,
          type: 'number',
          cellTemplate: 'priceTemplate',
        },
        {
          displayName: App.localize('Order'),
          name: App.localize('Order'),
          field: 'order',
          type: 'number',
          minWidth: 80,
        },
        {
          displayName: App.localize('Status'),
          name: App.localize('Status'),
          field: 'isActive',
          minWidth: 80,
          cellTemplate: 'statusTemplate',
        },
      ],
      onRegisterApi: (gridApi) => {
        $scope.gridApi = gridApi;
      },
      data: [],
    };

    vm.getSubscriptionPlansListing = getSubscriptionPlansListing;
    vm.getSubscriptionPlansOldListing = getSubscriptionPlansOldListing;
    vm.getAccountOwners = getAccountOwners;
    vm.exportToExcel = exportToExcel;
    vm.createSubscriptionPlan = createSubscriptionPlanOld;
    vm.editSubscriptionPlan = editSubscriptionPlanOld;
    vm.deleteSubscriptionPlan = deleteSubscriptionPlanOld;
    vm.activate = activateSubscriptionPlanOld;
    vm.deactivate = deactivateSubscriptionPlanOld;

    init();

    function init() {
      $timeout(() => {
        vm.gridOptions.paginationCurrentPage = Math.floor(
          vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1
        );
      });

      getCountries();
      getSubscriptionPlansListing();
      getSubscriptionPlansOldListing();
    }

    function registerStateParams() {
      $state.transitionTo($state.current, vm.requestParams, { notify: false });
    }

    function getCountries() {
      vm.loading += 1;
      commonLookupSvc
        .getEligibleCountries()
        .success((data) => {
          vm.countries = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getAccountOwners() {
      // Reset accountOwnerId filter selection.
      vm.requestParams.accountOwnerId = null;
      vm.filteredAccountOwners = null;

      // If country code filter is not null, get accountOwners under that country.
      if (vm.requestParams.countryCode != null) {
        vm.loading += 1;
        revenueCommonSvc
          .getAccountOwnersByCountryCode({
            countryCode: vm.requestParams.countryCode,
          })
          .success((data) => {
            vm.filteredAccountOwners = data.items;
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    }

    function getSubscriptionPlansListing() {
      registerStateParams();
      vm.loading += 1;
      subscriptionPlanSvc
        .getSubscriptionPlansListing(vm.requestParams)
        .success((data) => {
          vm.subscriptionPlans = data.items;
          vm.gridOptions.data = vm.subscriptionPlans;
          vm.gridOptions.totalItems = data.totalCount;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function exportToExcel() {
      if (vm.loading) return;

      vm.loading += 1;
      subscriptionPlanSvc
        .getSubscriptionPlansListingToExcel(vm.requestParams)
        .success((data) => {
          window.open(data.fileUrl, '_blank');
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getSubscriptionPlansOldListing() {
      registerStateParams();
      vm.loading += 1;
      subscriptionPlanSvc
        .getSubscriptionPlans(vm.requestParams)
        .success((data) => {
          vm.subscriptionPlansOld = data.items;
          vm.gridOldOptions.data = vm.subscriptionPlansOld;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function showEditModal(id) {
      $uibModal
        .open({
          templateUrl: require('./createOrEditModal.html'),
          controller: 'revenue.views.subscriptionPlans.createOrEditModal as vm',
          backdrop: 'static',
          resolve: {
            subscriptionPlanId() {
              return id;
            },
          },
        })
        .result.then(() => {
          vm.getSubscriptionPlansOldListing();
        });
    }

    function createSubscriptionPlanOld() {
      showEditModal();
    }

    function editSubscriptionPlanOld(subscriptionPlan) {
      showEditModal(subscriptionPlan.id);
    }

    function deleteSubscriptionPlanOld(subscriptionPlan) {
      const msg = App.localize('SubscriptionPlanSetupDeleteWarningMessage', subscriptionPlan.name);
      abp.message.confirm(msg, App.localize('AreYouSure'), (d) => {
        if (d) {
          subscriptionPlanSvc
            .deleteSubscriptionPlan({
              id: subscriptionPlan.id,
            })
            .success(() => {
              abp.notify.success(App.localize('SuccessfullyDeleted'));
              getSubscriptionPlansOldListing();
            });
        }
      });
    }

    function activateSubscriptionPlanOld(subscriptionPlan) {
      subscriptionPlanSvc
        .activateSubscriptionPlan({
          id: subscriptionPlan.id,
        })
        .success(() => {
          subscriptionPlan.isActive = true;
        });
    }

    function deactivateSubscriptionPlanOld(subscriptionPlan) {
      subscriptionPlanSvc
        .deactivateSubscriptionPlan({
          id: subscriptionPlan.id,
        })
        .success(() => {
          subscriptionPlan.isActive = false;
        });
    }
  }
})();
