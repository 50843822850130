(function () {
    var controllerId = 'host.views.smsLogs.detailModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'smsLog',
        function ($scope, $uibModalInstance, smsLog) {
            var vm = this;
            vm.smsLog = smsLog;

            vm.close = function () {
                $uibModalInstance.dismiss();
            };
        }
    ]);
})();