(function () {
    angular.module('app').component('pharmacyPrescriptionsAuditHistoryComponent', {
        templateUrl: require('/App/pharmacy/views/prescriptions/auditHistory/auditHistory.component.html'),
        controller: ['$scope', '$stateParams', 'abp.services.app.prescription', controller],
        controllerAs: 'vm',
        bindings: {
            onRefreshEvent: '<',
            onClickFulfillmentNumber: '&'
        }
    });

    function controller($scope, $stateParams, prescriptionSvc) {
        var vm = this;
        vm.histories = [];
        vm.loading = 0;
        vm.prescriptionNumber = $stateParams.prescriptionNumber;

        vm.$onChanges = function (changes) {
            if (changes.onRefreshEvent && changes.onRefreshEvent.currentValue) {
                vm.onRefreshEvent.handler = vm.getHistories;
            }
        };

        vm.$onInit = function init() {
            vm.getHistories();
        };

        vm.showFulfillment = function (fulfillmentNumber) {
            vm.onClickFulfillmentNumber({ fulfillmentNumber: fulfillmentNumber })
        };

        vm.getHistories = function () {
            vm.loading++;
            prescriptionSvc.getPrescriptionAndFulfillmentHistories({
                id: vm.prescriptionNumber
            }).success(function (data) {
                vm.histories = data.items;
            }).finally(function () {
                vm.loading--;
            });
        };
    }
})();
