(function () {
    'use strict';

    angular.module('app').component('corporateBenefitGroupsCreateEditSpecialistComponent', {
        templateUrl: require('/App/corporate/views/benefitGroups/createEdit/specialist/specialist.component.html'),
        controller: ['abp.services.app.commonLookup', controller],
        controllerAs: 'vm',
        bindings: {
            currencyCode: '<',
            dependentHasSpecialist: '<',
            specialistTreatmentEditData: '<',
            benefitGroup: '<',
            areTreatmentTreeAndReferralExceptionsDirty: '=',
            isReadOnly: '<'
        }
    });

    function controller(commonLookupSvc) {
        var vm = this;

        vm.canUseReferralExceptions = abp.features.isEnabled('Specialist.CanUseReferralExceptions');
        vm.$onChanges = onChanges;
        vm.principalReferralExceptions = [];
        vm.dependentReferralExceptions = [];
        vm.principalSpecialistDisciplines = null;
        vm.dependentSpecialistDisciplines = null;
        vm.addDisciplineToPrincipalReferralExceptions = addDisciplineToPrincipalReferralExceptions;
        vm.addDisciplineToDependentReferralExceptions = addDisciplineToDependentReferralExceptions;
        vm.onPrincipalExceptionChanged = onPrincipalExceptionChanged;
        vm.onDependentExceptionChanged = onDependentExceptionChanged;
        vm.getDisciplines = getDisciplines;

        vm.selectedPrincipalDisciplineException = null;
        vm.selectedDependentDisciplineException = null;

        function onChanges(changes) {
            if (changes.benefitGroup && vm.canUseReferralExceptions) {
                getDisciplines();
            }
        }

        function getDisciplines() {
            commonLookupSvc.getSpecialistDisciplines()
                .success(function (data) {
                    vm.principalSpecialistDisciplines = angular.copy(data.items);
                    vm.dependentSpecialistDisciplines = angular.copy(data.items);

                    var specialistDisciplinesById = _.chain(data.items)
                        .keyBy('value')
                        .mapValues('name')
                        .value();

                    vm.principalReferralExceptions = createReferralExceptionsModel(
                        vm.benefitGroup.principalReferralExceptions,
                        vm.principalSpecialistDisciplines,
                        specialistDisciplinesById);

                    vm.dependentReferralExceptions = createReferralExceptionsModel(
                        vm.benefitGroup.dependentReferralExceptions,
                        vm.dependentSpecialistDisciplines,
                        specialistDisciplinesById);
                });
        }

        function createReferralExceptionsModel(referralExceptions, specialistDisciplines, specialistDisciplinesById) {
            return _.map(referralExceptions, function (specialistDisciplineId) {
                _.remove(specialistDisciplines, function (discipline) {
                    return discipline.value === specialistDisciplineId;
                });

                return {
                    id: specialistDisciplineId,
                    name: specialistDisciplinesById[specialistDisciplineId] || 'Unknown discipline',
                    isRemove: false
                }
            });
        }

        function addDisciplineToPrincipalReferralExceptions(selectedDiscipline) {
            vm.principalReferralExceptions.push({
                id: selectedDiscipline.value,
                name: selectedDiscipline.name,
                isRemove: false
            });

            removeDiscipline(selectedDiscipline, vm.principalSpecialistDisciplines);
            onPrincipalExceptionChanged();
        }

        function addDisciplineToDependentReferralExceptions(selectedDiscipline) {
            vm.dependentReferralExceptions.push({
                id: selectedDiscipline.value,
                name: selectedDiscipline.name,
                isRemove: false
            });

            removeDiscipline(selectedDiscipline, vm.dependentSpecialistDisciplines);
            onDependentExceptionChanged();
        }

        function removeDiscipline(selectedDiscipline, specialistDisciplines) {
            _.remove(specialistDisciplines, function (discipline) {
                return discipline.value === selectedDiscipline.value;
            });
        }

        function onPrincipalExceptionChanged() {
            vm.benefitGroup.principalReferralExceptions = getFilteredExceptions(vm.principalReferralExceptions);
        }

        function onDependentExceptionChanged() {
            vm.benefitGroup.dependentReferralExceptions = getFilteredExceptions(vm.dependentReferralExceptions);
        }

        function getFilteredExceptions(exceptions) {
            return _.chain(exceptions)
                .filter(function (e) {
                    return !e.isRemove;
                })
                .uniq()
                .map(function (f) {
                    return f.id;
                })
                .value();
        }
    }
})();
