(() => {
  angular
    .module('app')
    .controller('common.views.specialistClaims.cancellationModal', CancellationModalController);

  CancellationModalController.$inject = ['$scope', '$uibModalInstance'];

  function CancellationModalController($scope, $uibModalInstance) {
    const vm = this;

    vm.isSpecialist = App.isSpecialist();
    vm.reason = '';
    vm.explain = '';

    vm.cancel = cancel;
    vm.getFinalReason = getFinalReason;
    vm.close = close;

    init();

    function init() {}

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function getFinalReason() {
      let reason = _.trim(vm.reason);
      if (reason === 'Others') {
        const explain = _.trim(vm.explain);
        if (explain) {
          reason += `: ${explain}`;
        } else {
          reason = '';
        }
      }
      return reason;
    }

    function close() {
      const isFormValid = App.isFormValid($scope.cancellationModalForm);
      if (!isFormValid) return;

      const reason = vm.getFinalReason();
      if (!reason) return;
      $uibModalInstance.close(reason);
    }
  }
})();
