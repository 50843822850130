(() => {
  angular
    .module('app')
    .controller(
      'common.views.guaranteeLetters.details.letterDetail.successModal',
      SuccessModalController
    );

  SuccessModalController.$inject = ['$uibModalInstance', 'letter'];

  function SuccessModalController($uibModalInstance, letter) {
    const vm = this;

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.letter = letter;
    vm.panelLocation = vm.letter.panelLocation;
    vm.patient = vm.letter.patient;

    vm.close = close;

    init();

    function init() {}

    function close() {
      $uibModalInstance.close();
    }
  }
})();
