(() => {
  angular.module('app').controller('common.views.roles.index', RolesPageController);

  RolesPageController.$inject = ['$uibModal', 'abp.services.app.role'];

  function RolesPageController($modal, roleSvc) {
    const vm = this;

    vm.loading = 0;

    vm.permissions = {
      create: abp.auth.isGranted('Administration.Roles.Create'),
      edit: abp.auth.isGranted('Administration.Roles.Edit'),
      delete: abp.auth.isGranted('Administration.Roles.Delete'),
    };

    vm.roleGridOptions = {
      appScopeProvider: vm,
      columnDefs: [
        {
          enableSorting: false,
          name: App.localize('Actions'),
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionsTemplate',
        },
        {
          displayName: App.localize('RoleName'),
          field: 'displayName',
          cellTemplate: 'roleNameTemplate',
          minWidth: 120,
        },
        {
          displayName: App.localize('CreationDate'),
          field: 'creationTime',
          cellFilter: "momentFormat: 'L'",
          minWidth: 100,
        },
      ],
      data: [],
    };
    if (!vm.permissions.edit && !vm.permissions.delete) vm.roleGridOptions.columnDefs.shift();

    vm.getRoles = getRoles;
    vm.createRole = createRole;
    vm.editRole = editRole;
    vm.deleteRole = deleteRole;

    init();

    function init() {
      vm.getRoles();
    }

    function showEditModal(id) {
      const modal = $modal.open({
        templateUrl: require('./createOrEditModal.html'),
        controller: 'common.views.roles.createOrEditModal as vm',
        backdrop: 'static',
        resolve: {
          roleId() {
            return id;
          },
        },
      });
      modal.result.then(() => {
        vm.getRoles();
      });
    }

    function getRoles() {
      vm.loading += 1;
      roleSvc
        .getRoles({})
        .success((data) => {
          vm.roleGridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function createRole() {
      showEditModal();
    }

    function editRole(role) {
      showEditModal(role.id);
    }

    function deleteRole(role) {
      abp.message.confirm(
        App.localize('RoleDeleteWarningMessage', role.displayName),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            roleSvc
              .deleteRole({
                id: role.id,
              })
              .success(() => {
                vm.getRoles();
                abp.notify.success(App.localize('SuccessfullyDeleted'));
              });
          }
        }
      );
    }
  }
})();
