(function () {
    'use strict';

    angular.module('app').component('hostCorporatesSupportPlansCreateEditBenefitsComponent', {
        templateUrl: require('/App/host/views/corporates/supportPlans/createEdit/benefits/benefits.component.html'),
        controller: controller,
        controllerAs: 'vm',
        bindings: {
            supportPlan: '<',
            settings: '<',
            isHospitalization: '<',
            currencyCode: '<'
        }
    });

    function controller() {
        var vm = this;
        vm.budgetLimitText = App.localize('BudgetLimit');
        vm.visitLimitText = App.localize('VisitLimit');
    }
})();
