(function () {
    var componentId = 'clinicHealthScreeningCheckInPatientDetailCard';
    var app = angular.module('app');

    function controller($state, $sce) {
        function init() {
            // EMPTY.
        }
        var vm = this;
        vm.employee = vm.patientData;
        vm.clinic = vm.clinicData;
        vm.toggleCheckIn = vm.checkInFunction;
        vm.cancel = vm.cancelFunction;
        vm.getEmployees = vm.getEmployeeFunction;
        vm.showCheckInModal = vm.showCheckInModalFunction;
        vm.permissions = {
            canApplyGlAndCheckin: abp.auth.isGranted("PatientTickets.Clinic.Create"),
            canSubmitClaim: abp.auth.isGranted("Claims.HealthScreening.Panel.Create")
                || abp.auth.isGranted("PatientTickets.Clinic.Create")
        };

        /* Employee icon. */

        vm.getUserBgColor = function () {
            return 'user_bgcolor_' + ((vm.employee.id || 0) % 8 + 1);
        };

        vm.getUserInitial = function () {
            var fullName = (vm.employee.name || ' ').split(' ');
            var initial = '';
            _.each(fullName, function (f) {
                initial += f[0];
            });
            if (initial && initial.length > 3) initial = '' + initial[0];
            return initial;
        };

        vm.submitClaim = function (letterNumber) {
            // HM-5994: Claim with topup reserved for future enhancements.

            $state.go('createEditHealthScreeningFinalRequest', {
                letterNumber: letterNumber,
                claimWithTopUp: 0,
                timeZoneId: vm.timeZoneId
            });
        };

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/clinic/views/healthScreening/checkIn/patientDetailCard.component.html'),
        controller: ['$state', '$sce', controller],
        controllerAs: 'vm',
        bindings: {
            patientData: '<',
            clinicData: '<',
            cardType: '<',
            cancelFunction: '<',
            getEmployeesFunction: '<',
            showCheckInModalFunction: '<',
            timeZoneId: '<'
        }
    });
})();
