(() => {
  angular.module('app').component('hostOperationsDashboardInsuranceClaimsComponent', {
    templateUrl: require('./insuranceClaims.component.html'),
    controller: HostOperationsDashboardInsuranceClaimsComponentController,
    controllerAs: 'vm',
    bindings: {
      widgets: '<',
      onAddWidget: '&',
      countryCode: '=?',
    },
  });

  HostOperationsDashboardInsuranceClaimsComponentController.$inject = [
    '$scope',
    'abp.services.app.commonLookup',
    'abp.services.app.insurerClaim',
    '$sce',
    '$uibModal',
    'moment',
  ];

  function HostOperationsDashboardInsuranceClaimsComponentController(
    $scope,
    commonLookupSvc,
    insurerClaimSvc,
    $sce,
    $uibModal,
    moment
  ) {
    const vm = this;

    vm.hasAccessFinanceTransactionData = abp.setting.getBoolean('Hms.Feature.AccessFinanceTransactionData');
    let exportTemplateName = '';
    let exportFields = [];
    vm.widgetName = 'hostOperationsDashboardInsuranceClaimsComponent';
    vm.loading = 0;
    vm.periods = [];
    vm.insuranceClaimsDrafts = [];
    vm.insuranceClaimsDict = {};
    vm.insuranceClaimsBatchDrafts = {};
    vm.selectedPeriod = null;
    vm.getTotalFilteredCount = getTotalFilteredCount;
    vm.exportInsuranceClaimsListingToExcel = exportInsuranceClaimsListingToExcel;
    vm.exportInsuranceClaimsListing = exportInsuranceClaimsListing;
    vm.getPendingBatchDraftNarrativeMessage = getPendingBatchDraftNarrativeMessage;
    vm.getPendingInsuranceClaimPaymentConfirmationNarrativeMessage =
      getPendingInsuranceClaimPaymentConfirmationNarrativeMessage;
    vm.showDownloadBatchModal = showDownloadBatchModal;
    vm.showDownloadBatchInvoicesModal = showDownloadBatchInvoicesModal;
    vm.showCreatePrePostClaimsModal = showCreatePrePostClaimsModal;
    vm.openBatchDraftsModal = openBatchDraftsModal;
    vm.refresh = refresh;
    vm.countryCode = null;
    vm.$onInit = $onInit;
    vm.search = search;
    vm.getPendingInsuranceClaimPaymentConfirmationFilter =
      getPendingInsuranceClaimPaymentConfirmationFilter;

    // Initialize total count parameters.

    vm.totalPendingClaims = 0;
    vm.totalSubmittedClaims = 0;
    vm.totalApprovedClaims = 0;
    vm.totalRejectedClaims = 0;
    vm.totalUnfilteredPendingClaims = 0;
    vm.totalUnfilteredSubmittedClaims = 0;
    vm.totalUnfilteredPendingBatchDraft = 0;

    // Declare request params.

    vm.requestParams = {
      claimStatus: null,
      insurerId: null,
      organizationId: null,
      masterPolicyId: null,
      batchNumber: null,
      filter: null,
      maxResultCount: 10,
      skipCount: 0,
      invoiceIds: null,
      countryCode: null,
      ticketNumber: null,
      insurerInvoiceStatus: null,
    };

    // Initialize ticket date range and model.

    vm.ticketDateRangeOptions = App.createDateRangePickerOptions();
    vm.ticketDateRangeModel = {
      startDate: null,
      endDate: null,
    };

    // Overwrite default ranges set for this year, month and week.

    vm.ticketDateRangeOptions.ranges[App.localize('ThisYear')] = [
      moment().startOf('year'),
      moment().endOf('year'),
    ];
    vm.ticketDateRangeOptions.ranges[App.localize('ThisQuarter')] = [
      moment().startOf('quarter'),
      moment().endOf('quarter'),
    ];
    vm.ticketDateRangeOptions.ranges[App.localize('ThisMonth')] = [
      moment().startOf('month'),
      moment().endOf('month'),
    ];
    vm.ticketDateRangeOptions.ranges[App.localize('ThisWeek')] = [
      moment().startOf('week'),
      moment().endOf('week'),
    ];

    // Overwrite default max date to infinite.

    vm.ticketDateRangeOptions.max = moment([9999, 0, 1]);
    vm.ticketDateRangeOptions.maxDate = moment([9999, 0, 1]);

    vm.$onInit = init;

    function init() {
      initPeriod();
      getTotalFilteredCount();
      getListingFilterOptions();
      getInsuranceClaims();
      getInsuranceClaimsBatchDrafts();
    }

    function $onInit() {
      vm.onAddWidget({ widget: this });
    }

    // Function to initialize selectable periods for total count.

    function initPeriod() {
      const dtStart = moment('2016-04-01');
      const dtLast = moment().startOf('M');
      let dtCur = moment(dtStart);
      const v = [];
      while (dtCur <= dtLast) {
        v.push({ name: dtCur.format('MMM YYYY'), value: dtCur.toISOString() });
        dtCur = dtCur.add(1, 'M');
      }
      _.reverse(v);
      vm.periods = v;
      vm.selectedPeriod = v[0].value;
    }

    // Get request inputs from UI.

    function getRequestInput(isExport, selectedFields = null) {
      const input = $.extend({}, vm.requestParams, { exportFields: selectedFields });
      input.ticketStartDate = vm.ticketDateRangeModel.startDate;
      input.ticketEndDate = vm.ticketDateRangeModel.endDate;
      input.invoiceIds = null;
      input.isExport = !!isExport;
      vm.getRequestInput = input;
      return input;
    }

    // Get total filtered count for the insurance claims.

    function getTotalFilteredCount() {
      const selectedStartPeriod = vm.selectedPeriod;
      const selectedEndPeriod = moment(vm.selectedPeriod).endOf('month');

      vm.loading += 1;
      insurerClaimSvc
        .getFilteredInsurerClaimsStatusCount({
          startDate: selectedStartPeriod,
          endDate: selectedEndPeriod,
          countryCode: vm.countryCode,
        })
        .success((data) => {
          vm.totalPendingClaims = data.totalPendingClaims;
          vm.totalSubmittedClaims = data.totalSubmittedClaims;
          vm.totalApprovedClaims = data.totalApprovedClaims;
          vm.totalRejectedClaims = data.totalRejectedClaims;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // Get the pending and submitted insurer claim count to display.

    function getPendingAndSubmittedInsurerClaimCount() {
      vm.loading += 1;
      insurerClaimSvc
        .getPendingAndSubmittedInsurerClaimCount(vm.requestParams)
        .success((data) => {
          vm.totalUnfilteredPendingClaims = data.totalPendingClaims;
          vm.totalUnfilteredSubmittedClaims = data.totalSubmittedClaims;
          vm.paidInvoiceIds = data.paidInvoiceIds;
          setTotalPendingInsuranceClaimCount();
          getPendingInsuranceClaimPaymentConfirmationNarrativeMessage();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // Get the formatted pending insurance claim localization message.

    function getPendingBatchDraftNarrativeMessage() {
      const narrativeMessage = `${App.localize(
        'PendingBatchDraftNarrativeMessage',
        vm.totalUnfilteredPendingBatchDraft
      )}`;
      return $sce.trustAsHtml(narrativeMessage);
    }

    // Get the formatted pending payment confirmation insurance claim localization message.

    function getPendingInsuranceClaimPaymentConfirmationNarrativeMessage() {
      const narrativeMessage = `${App.localize(
        'PendingInsuranceClaimPaymentConfirmationNarrativeMessage',
        vm.totalUnfilteredSubmittedClaims
      )}`;
      return $sce.trustAsHtml(narrativeMessage);
    }

    // Set the total pending insurance claim count to show on the insurance claims button.

    function setTotalPendingInsuranceClaimCount() {
      const item = _.find(vm.widgets, (data) => data.id === vm.widgetName);
      item.totalCount = vm.totalUnfilteredPendingClaims;
      item.loading = false;
    }

    // Get listing filter options.

    function getListingFilterOptions() {
      // Get insurers.

      vm.loading += 1;
      commonLookupSvc
        .getInsurers()
        .success((data) => {
          vm.insurers = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });

      // Get company/corporate/organization.

      vm.loading += 1;
      commonLookupSvc
        .getCorporates({})
        .success((data) => {
          vm.organizations = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });

      // Get master policies.

      vm.loading += 1;
      commonLookupSvc
        .getMasterPolicies()
        .success((data) => {
          vm.masterPolicies = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // Get insurance claims according to input filters.

    function getInsuranceClaims() {
      const input = getRequestInput();
      vm.loading += 1;
      insurerClaimSvc
        .getInsurerClaimsListing(input)
        .success((data) => {
          vm.records = data;
          vm.totalInsuranceCount = data.totalCount;
          exportTemplateName = data.exportTemplateName;
          exportFields = data.exportFields;
        })
        .finally(() => {
          vm.loading -= 1;
        });

      // Get updated unfiltered pending and submitted insurer claim count.

      getPendingAndSubmittedInsurerClaimCount();

      // Get pending batch draft narrative message.

      getPendingBatchDraftNarrativeMessage();
    }

    // A function for retrieving all insurance claim batch drafts.

    function getInsuranceClaimsBatchDrafts() {
      vm.loading += 1;
      insurerClaimSvc
        .getInsuranceClaimsBatchDrafts()
        .success((data) => {
          vm.insuranceClaimsBatchDrafts = data;
          const keys = Object.keys(vm.insuranceClaimsBatchDrafts);
          vm.totalUnfilteredPendingBatchDraft = keys.length;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // Open modal for chosing fields to be exported for insurance claims as excel.

    function exportInsuranceClaimsListing() {
      $uibModal
        .open({
          templateUrl: require('../../../../common/views/common/selectionModal.html'),
          controller: 'common.views.common.selectionModal as vm',
          backdrop: 'static',
          resolve: {
            templateName() {
              return exportTemplateName;
            },
            fields() {
              return exportFields;
            },
            modalTitle() {
              return App.localize('SelectExportFields');
            },
          },
        })
        .result.then((selectedFields) => {
          const option = getRequestInput(true, selectedFields);
          exportInsuranceClaimsListingToExcel(option);
        });
    }

    // Export insurance claims as excel.

    function exportInsuranceClaimsListingToExcel(option) {   
      const opt = option || getRequestInput(true);

      vm.loading += 1;
      insurerClaimSvc
        .getInsurerClaimsListingToExcel(opt)
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.loading -= 1;
          vm.getRequestInput.isExport = false;
        });
    }

    // Search insurance claims.

    function search() {
      getRequestInput();
      $scope.get();
    }

    function showCreatePrePostClaimsModal() {
      $uibModal
        .open({
          templateUrl: require('./createPrePostClaimsModal/createPrePostClaimsModal.html'),
          controller:
            'host.views.operationsDashboard.insuranceClaims.createPrePostClaimsModal.createPrePostClaimsModal as vm',
          backdrop: 'static',
          size: 'lg',
          windowClass: 'app-modal-window',
        })
        .result.then(() => {
          // Refresh insurance claims listing results.

          vm.search();
        });
    }

    // Opens the insurance claims batch drafts modal.

    function openBatchDraftsModal() {
      $uibModal
        .open({
          templateUrl: require('./batchDrafts/batchDrafts.modal.html'),
          controller: 'host.views.insuranceClaims.batchDraftsModal as vm',
          backdrop: 'static',
          resolve: {
            insuranceClaimsBatchDrafts() {
              return vm.insuranceClaimsBatchDrafts;
            },
          },
        })
        .result.then(() => {
          vm.search();
          getInsuranceClaims();
          getInsuranceClaimsBatchDrafts();
        });
    }

    // Refresh listing when refresh button is clicked.

    function refresh() {
      // Reset skipCount and country code.

      vm.requestParams.skipCount = 0;
      vm.requestParams.countryCode = vm.countryCode;

      // Re-get other necessary information.

      getTotalFilteredCount();
      getListingFilterOptions();
      getInsuranceClaims();
    }

    function showDownloadBatchModal() {
      $uibModal.open({
        templateUrl: require('./downloadBatchModal/downloadBatchModal.html'),
        controller: 'host.views.operationsDashboard.insuranceClaims.downloadBatchModal as vm',
        backdrop: 'static',
        size: 'md',
        resolve: {
          insuranceClaims: vm.records,
        },
      });
    }

    function showDownloadBatchInvoicesModal() {
      $uibModal.open({
        templateUrl: require('./downloadBatchInvoiceModal/downloadBatchInvoice.modal.html'),
        controller:
          'host.views.operationsDashboard.insuranceClaims.downloadBatchInvoiceModal as vm',
        backdrop: 'static',
        size: 'md',
      });
    }

    // Filter insurance claims with status submitted but utilization unconfirmed.

    function getPendingInsuranceClaimPaymentConfirmationFilter() {
      vm.getRequestInput = {
        claimStatus: 1,
        insurerId: null,
        organizationId: null,
        masterPolicyId: null,
        batchNumber: null,
        filter: null,
        maxResultCount: 10,
        skipCount: 0,
        invoiceIds: vm.paidInvoiceIds,
        ticketNumber: null,
        insurerInvoiceStatus: null,
        isExport: false,
      };
    }

    // Broadcast search event for insurance claim listing component to listen to.

    $scope.get = function () {
      getRequestInput();
      $scope.$broadcast('search');
    };
  }
})();