(function () {
    var controllerId = 'revenue.views.corporates.createEditAgreement.createEditAgreementModal';
    var module = angular.module('revenue');
    module.controller(controllerId, [
        '$uibModalInstance', 'abp.services.revenue.corporateSalesAgreement', 'currencies',
        'corporate', 'corporateSalesAgreementId', 'moment',
        function ($uibModalInstance, corporateSalesAgreementSvc, currencies, corporate, corporateSalesAgreementId, moment) {
            function refreshInputModel(data) {
                const input = {
                    id: data.id,
                    agreementDate: vm.isEdit ? moment(data.agreementDate) : moment().startOf('d'),
                    currencyCode: data.currencyCode,
                    introducerId: null,
                    salesPersonId: null,
                    salesPersonMode: 0,
                    salesOverridingManagerId: null
                };

                _.forEach(data.agents, function (agent) {
                    switch (agent.mode) {
                        case 0:
                        case 1:
                            input.salesPersonId = agent.agentId;
                            input.salesPersonMode = agent.mode;
                            break;
                        case 2:
                            input.salesOverridingManagerId = agent.agentId;
                            break;
                        case 3:
                            input.introducerId = agent.agentId;
                            break;
                    }
                });

                vm.input = input;
            }

            function getAgentName(agents, agentId) {
                if (agentId) {
                    const agent = _.find(agents, { 'value': agentId });
                    return agent ? agent.name : agentId;
                }
                return App.localize('None');
            }

            function init() {
                vm.loading = true;
                corporateSalesAgreementSvc.getCorporateSalesAgreementForEdit({
                    corporateId: vm.corporate.id,
                    id: corporateSalesAgreementId
                }).success(function (data) {
                    refreshInputModel(data.agreement);

                    vm.introducers = _.sortBy(data.introducers, 'name');
                    vm.agents = _.sortBy(data.agents, 'name');
                    vm.editSalesPerson = !data.hasStarted;
                    vm.agreementNumber = data.agreementNumber;
                    vm.isFirst = data.isFirst;

                    vm.salesPersonName = getAgentName(data.agents, vm.input.salesPersonId);
                    vm.salesOverridingManagerName = getAgentName(data.agents, vm.input.salesOverridingManagerId);
                    vm.introducerName = getAgentName(data.introducers, vm.input.introducerId);
                }).finally(function () {
                    vm.loading = false;
                });
            }

            function getServiceInput() {
                const input = {
                    id: vm.input.id,
                    corporateId: vm.corporate.id,
                    agreementDate: vm.input.agreementDate,
                    currencyCode: vm.input.currencyCode,
                    agents: []
                };
                if (vm.input.salesPersonId) {
                    input.agents.push({ agentId: vm.input.salesPersonId, mode: vm.input.salesPersonMode });
                }
                if (vm.input.salesPersonMode === 0 && vm.input.salesOverridingManagerId) {
                    input.agents.push({ agentId: vm.input.salesOverridingManagerId, mode: 2 });
                }
                if (vm.input.introducerId) {
                    input.agents.push({ agentId: vm.input.introducerId, mode: 3 });
                }
                return input;
            }

            const vm = this;
            vm.isEdit = !!corporateSalesAgreementId;
            vm.loading = false;
            vm.saving = false;
            vm.corporate = corporate;
            vm.currencies = currencies;
            vm.agents = [];
            vm.introducers = [];
            vm.isFirst = false;

            vm.canBypassCommissionChecks = abp.auth.isGranted(
                'Hms.Revenue.Corporate.AssignAgent.BypassCommissionChecks');
            vm.editSalesPerson = false;

            vm.save = function () {
                if (vm.saving) return;
                vm.saving = true;

                const input = getServiceInput();
                corporateSalesAgreementSvc.createOrUpdateCorporateSalesAgreement(input)
                    .success(function () {
                        abp.notify.info(App.localize("SuccessfullySaved"));
                        $uibModalInstance.close();
                    }).finally(function () {
                        vm.saving = false;
                    });
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            vm.bypassCommissionChecks = function () {
                abp.message.confirm(App.localize('AssignAgentBypassCommissionChecksWarning'), App.localize('AreYouSure'), function (result) {
                    if (result) {
                        vm.editSalesPerson = true;
                    }
                });
            };

            vm.delete = function () {
                if (!vm.isEdit) return;
                if (vm.loading || vm.saving) return;

                abp.message.confirm(App.localize('ConfirmDeleteSalesAgreementWarning'), App.localize('AreYouSure'), function (d) {
                    if (d) {
                        vm.saving = true;
                        corporateSalesAgreementSvc.deleteCorporateSalesAgreement({
                            id: corporateSalesAgreementId
                        }).success(function () {
                            abp.notify.success(App.localize("SuccessfullyDeleted"));
                            $uibModalInstance.close();
                        }).finally(function () {
                            vm.saving = false;
                        });
                    }
                });
            };

            init();
        }
    ]);
})();
