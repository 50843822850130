(function () {
    var controllerId = 'revenue.views.subscriptions.editPeriod.editPeriodModal';
    var module = angular.module('revenue');
    module.controller(controllerId, [
        '$uibModalInstance', '$filter', 'abp.services.revenue.subscription', 'subscription', 'moment',
        function ($uibModalInstance, $filter, subscriptionSvc, subscription, moment) {
            function refreshContractLength() {
                const endDatePlusOne = moment(vm.subscription.endDate).add(1, 'd');
                const years = endDatePlusOne.diff(vm.subscription.startDate, 'y');

                const finalYearStartDate = moment(vm.subscription.startDate).add(years, 'y');
                const finalYearTotalDays = moment(finalYearStartDate).add(1, 'y').diff(finalYearStartDate, 'd');

                const totalDays = endDatePlusOne.diff(finalYearStartDate, 'd');
                const finalYearProrate = totalDays / finalYearTotalDays;

                vm.contractLength = App.localize('XYears', $filter('number')(years + finalYearProrate, 2));
            }

            function refreshDuration() {
                const startDate = moment(vm.subscription.startDate);
                const endDate = moment(vm.subscription.endDate);
                const endDatePlusOne = moment(endDate).add(1, 'd');
                const years = endDatePlusOne.diff(startDate, 'y');
                if (startDate.add(years, 'y').isSame(endDatePlusOne) && years >= 1 && years <= 3) {
                    vm.duration = years;
                } else {
                    vm.duration = null;
                }
            }

            function durationChanged() {
                var duration = +vm.duration;
                if (duration) {
                    vm.subscription.endDate = moment(vm.subscription.startDate).add(duration, 'y').add(-1, 'days');
                    refreshContractLength();
                }
            }

            function init() {
                vm.subscription = {
                    id: subscription.id,
                    startDate: moment(subscription.startDate),
                    endDate: moment(subscription.endDate)
                };
                refreshDuration();
                refreshContractLength();
            }

            const vm = this;
            vm.saving = false;
            vm.hasBillingCycle = subscription.hasBillingCycle;

            vm.save = function () {
                if (vm.saving) return;
                vm.saving = true;

                subscriptionSvc.updateSubscriptionPeriod(vm.subscription)
                    .success(function (data) {
                        abp.notify.info(App.localize("SuccessfullySaved"));
                        $uibModalInstance.close(data);
                    }).finally(function () {
                        vm.saving = false;
                    });
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            vm.durationChanged = durationChanged;
            vm.refreshContractLength = refreshContractLength;

            init();
        }
    ]);
})();
