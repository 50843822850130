(() => {
  angular.module('app').controller('common.views.users.resetPasswordModel', ResetPasswordModal);

  ResetPasswordModal.$inject = ['$uibModalInstance', 'abp.services.app.user', 'user'];

  function ResetPasswordModal($uibModalInstance, userSvc, user) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;
    vm.autoGeneratePassword = true;
    vm.shouldChangePasswordOnNextLogin = true;
    vm.user = user;

    vm.cancel = cancel;
    vm.save = save;

    init();

    function init() {}

    function save() {
      if (vm.autoGeneratePassword) {
        vm.password = null;
      }

      vm.saving += 1;
      userSvc
        .resetPassword({
          id: user.id,
          autoGeneratePassword: vm.autoGeneratePassword,
          shouldChangePasswordOnNextLogin: vm.shouldChangePasswordOnNextLogin,
          password: vm.password,
        })
        .success((data) => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          if (data) {
            abp.message.success(App.localize('PasswordForUser', user.userName), data);
          }
          $uibModalInstance.close();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
