import swal from 'sweetalert';

(() => {
  angular
    .module('app')
    .controller('common.views.saleTransactions.index', SaleTransactionsPageController);

  SaleTransactionsPageController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$uibModal',
    '$timeout',
    'abp.services.app.saleTransaction',
    'appSession',
    'abp.services.app.commonLookup',
    'moment',
    'appConstants',
    '$rootScope',
  ];

  function SaleTransactionsPageController(
    $scope,
    $state,
    $stateParams,
    $uibModal,
    $timeout,
    saleTransactionSvc,
    appSession,
    commonLookupSvc,
    moment,
    appConstants,
    $root
  ) {
    const vm = this;

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.loading = 0;
    vm.exporting = 0;
    vm.isHost = App.isHost();
    vm.isOrganization = App.isCorporate();
    vm.isSubsidiary = appSession.user.subsidiaryId;
    vm.isPanel = App.isClinic();
    vm.isSpecialist = vm.isPanel && App.isSpecialist();
    vm.isInpatient = App.isInpatient();
    vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');
    vm.hasInsuranceModule = abp.setting.getBoolean('Hms.Feature.InsuranceModule');
    vm.hasGpDashboardEnhancement = abp.setting.getBoolean('Hms.Feature.GPDashboardEnhancement');
    vm.hasSaleTransactionWorkflowEnhancement = abp.setting.getBoolean(
      'Hms.Feature.SaleTransactionWorkflowEnhancement'
    );
    vm.stopInvoiceGeneration = abp.setting.getBoolean('Hms.Feature.StopPortalInvoiceGeneration');

    vm.dateRangeModel = new DateRangeModel(
      $stateParams.startDate,
      $stateParams.endDate,
      getSaleTransactions
    );
    vm.requestParams = new RequestParamsModel($stateParams);
    vm.categories = [];
    vm.types = [];
    vm.insurers = [];
    vm.viewType = null;
    vm.viewCategory = null;
    vm.paymentInstructionVisibleViewCategories = [null, 1, 3, 4, 5, 6, 13];
    vm.paymentInstructionVisibleViewTypes = [null, 1, 2];
    vm.subsidiaryVisibleViewCategories = [null, 2, 6];
    vm.employeeVisibleViewCategories = [null, 6];
    vm.tenantTypes = [];
    vm.transactionCategories = [];

    const panelExclusiveTransactionTypes = vm.isPanel
      ? new PanelExclusiveTransactionTypesModel()
      : null;

    vm.permissions = {
      createPayment: abp.auth.isGranted('SaleTransactions.Host.CreatePayment'),
      editPayment: abp.auth.isGranted('SaleTransactions.Host.EditPayment'),
      deletePayment: abp.auth.isGranted('SaleTransactions.Host.DeletePayment'),
      paymentInstruction: abp.auth.isGranted('SaleTransactions.Host.PaymentInstruction'),
      manualPaymentInstruction: abp.auth.isGranted(
        'SaleTransactions.Host.ManualPaymentInstruction'
      ),
      patientTickets: abp.auth.isGranted('PatientTickets'),
      financeReports: abp.auth.isGranted('SaleTransactions.Host.FinanceReports'),
      departmentsReport: abp.auth.isGranted('Corporate.Reports.DepartmentsReport'),
      commissions: abp.auth.isGranted('SaleTransactions.Host.Commissions'),
      lowBalanceNotificationLogs: abp.auth.isGranted(
        'SaleTransactions.Host.LowBalanceNotificationLogs'
      ),
    };

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSizes,
      paginationPageSize: appConstants.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      columnDefs: [
        {
          name: App.localize('Actions'),
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
        },
        {
          displayName: App.localize('Country'),
          enableSorting: false,
          field: 'countryCode',
          width: 30,
          visible: vm.hasRegionalSystemSupport && vm.isHost,
          cellTemplate: 'countryTemplate',
        },
        {
          displayName: App.localize('Date'),
          enableSorting: false,
          field: 'displayDate',
          minWidth: 80,
        },
        {
          displayName: App.localize('Category'),
          enableSorting: false,
          field: 'category',
          cellFilter: 'saleTransactionCategoryFilter',
          minWidth: 100,
          visible: !vm.hasSaleTransactionWorkflowEnhancement,
        },
        {
          displayName: App.localize('TenantType'),
          enableSorting: false,
          field: 'tenantTypeDisplayName',
          minWidth: 100,
          visible: vm.hasSaleTransactionWorkflowEnhancement,
        },
        {
          displayName: App.localize('TransactionCategory'),
          enableSorting: false,
          field: 'transactionCategoryDisplayName',
          minWidth: 100,
          visible: vm.hasSaleTransactionWorkflowEnhancement,
        },
        {
          displayName: App.localize('TransactionType'),
          enableSorting: false,
          field: 'type',
          cellTemplate: 'transactionTypeTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('TransactionNumber'),
          enableSorting: false,
          field: 'transactionNumber',
          minWidth: 100,
        },
        {
          displayName: App.localize('PaymentInstruction'),
          field: 'paymentInstructionTransactionNumber',
          cellTemplate: 'paymentInstructionTemplate',
          visible:
            vm.isHost &&
            vm.paymentInstructionVisibleViewCategories.includes(vm.viewCategory) &&
            vm.paymentInstructionVisibleViewTypes.includes(vm.viewType),
          visibleEvaluator() {
            return (
              vm.isHost &&
              vm.paymentInstructionVisibleViewCategories.includes(vm.viewCategory) &&
              vm.paymentInstructionVisibleViewTypes.includes(vm.viewType)
            );
          },
          enableSorting: false,
          minWidth: 100,
        },
        {
          displayName: App.localize('Company'),
          enableSorting: false,
          field: 'companyName',
          visible: vm.isHost,
          minWidth: 100,
        },
        {
          displayName: App.localize('Subsidiary'),
          enableSorting: false,
          field: 'subsidiaryName',
          visible:
            (vm.isOrganization && !vm.isSubsidiary) ||
            (vm.isHost && vm.subsidiaryVisibleViewCategories.includes(vm.viewCategory)),
          visibleEvaluator() {
            return (
              vm.isOrganization ||
              (vm.isHost && vm.subsidiaryVisibleViewCategories.includes(vm.viewCategory))
            );
          },
          minWidth: 100,
        },
        {
          displayName: App.localize('Employee'),
          enableSorting: false,
          field: 'employeeName',
          visible: vm.isHost && vm.employeeVisibleViewCategories.includes(vm.viewCategory),
          visibleEvaluator() {
            return vm.isHost && vm.employeeVisibleViewCategories.includes(vm.viewCategory);
          },
          minWidth: 100,
        },
        {
          displayName: App.localize('Aging'),
          enableSorting: false,
          field: 'aging',
          visible:
            (vm.isHost || vm.isOrganization) && (vm.viewType === 1 || vm.viewCategory === 13),
          visibleEvaluator() {
            return (
              (vm.isHost || vm.isOrganization) && (vm.viewType === 1 || vm.viewCategory === 13)
            );
          },
          minWidth: 50,
        },
        {
          displayName: App.localize('Balance'),
          field: 'balance',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          cellClass: 'font-bold',
          visible: vm.viewType !== 3,
          visibleEvaluator() {
            return vm.viewType !== 3;
          },
          enableSorting: false,
          minWidth: 100,
        },
        {
          displayName: App.localize('PreTaxAmount'),
          enableSorting: false,
          field: 'preTaxAmount',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          visible: (vm.isHost || vm.isOrganization) && vm.viewType !== 3,
          visibleEvaluator() {
            return (vm.isHost || vm.isOrganization) && vm.viewType !== 3;
          },
          minWidth: 100,
        },
        {
          displayName: App.localize('TaxAmount'),
          enableSorting: false,
          field: 'taxAmount',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          visible: (vm.isHost || vm.isOrganization) && vm.viewType !== 3,
          visibleEvaluator() {
            return (vm.isHost || vm.isOrganization) && vm.viewType !== 3;
          },
          minWidth: 100,
        },
        {
          displayName: App.localize('Total'),
          enableSorting: false,
          field: 'amount',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          minWidth: 100,
        },
        {
          displayName: App.localize('Status'),
          enableSorting: false,
          field: 'closureTime',
          minWidth: 100,
          cellTemplate: 'statusTemplate',
        },
        {
          displayName: App.localize('CreationTime'),
          enableSorting: false,
          field: 'creationTime',
          minWidth: 100,
          cellTemplate: 'saleTransactionCreationTimeTemplate'
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          vm.requestParams.sorting =
            callback.length && callback[0].field
              ? `${callback[0].field} ${callback[0].sort.direction}`
              : null;
          getSaleTransactions();
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, getSaleTransactions)
        );
      },
      data: [],
    };

    function registerStateParams() {
      $state.transitionTo($state.current, vm.requestParams.toStateParams(), {
        notify: false,
      });
    }

    vm.deletePayment = deletePayment;
    vm.generateFinanceReports = generateFinanceReports;
    vm.generateStatement = generateStatement;
    vm.getSaleTransactions = getSaleTransactions;
    vm.manualCreateExcessPayment = manualCreateExcessPayment;
    vm.send = send;
    vm.setManualPaymentInstructionNumber = setManualPaymentInstructionNumber;
    vm.exportToExcel = exportToExcel;
    vm.printList = printList;
    vm.resetInsurer = resetInsurer;
    vm.setTransactionCategory = setTransactionCategory;
    vm.getDateTimeString = getDateTimeString;

    init();

    // Init function.

    function init() {
      if (!vm.isHost) {
        getTenantCurrencyCode();
      }

      // Initialise filter options.

      vm.types.push({
        name: App.localize('All'),
        value: null,
      });

      if (vm.isPanel) {
        vm.types = vm.types.concat(panelExclusiveTransactionTypes.toNameValueDto());
      } else {
        vm.types.push({
          name: App.localize('Invoice'),
          value: 1,
        });
        vm.types.push({
          name: App.localize('CreditMemo'),
          value: 2,
        });
        vm.types.push({
          name: App.localize('Payment'),
          value: 3,
        });

        if (vm.hasGpDashboardEnhancement && (vm.isHost || vm.isPanel)) {
          vm.types.push({
            name: App.localize('OffsetOriginSales'),
            value: 4,
          });
        }

        if (vm.isHost) {
          vm.categories.push({
            name: App.localize('All'),
            value: null,
          });
          vm.categories.push({
            name: App.localize('RegularPanel'),
            value: 1,
          });
          vm.categories.push({
            name: App.localize('SpecialistPanel'),
            value: 8,
          });
          vm.categories.push({
            name: App.localize('PharmacyPanel'),
            value: 9,
          });
          vm.categories.push({
            name: App.localize('HospitalPanel'),
            value: 10,
          });
          vm.categories.push({
            name: App.localize('IhmHospitalPanel'),
            value: 11,
          });
          vm.categories.push({
            name: App.localize('Corporate'),
            value: 2,
          });
          vm.categories.push({
            name: App.localize('License'),
            value: 3,
          });
          vm.categories.push({
            name: App.localize('Subscription'),
            value: 12,
          });
          if (vm.permissions.commissions) {
            vm.categories.push({
              name: App.localize('Introducer'),
              value: 4,
            });
          }
          vm.categories.push({
            name: App.localize('Reimbursement'),
            value: 6,
          });
          if (vm.hasInsuranceModule) {
            vm.categories.push({
              name: App.localize('Insurer'),
              value: 13,
            });
          }
        }
      }

      if (vm.isHost && vm.hasInsuranceModule) {
        getInsurers();
      }

      $timeout(() => {
        vm.gridOptions.paginationCurrentPage = Math.floor(
          vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1
        );
      });

      if (vm.hasRegionalSystemSupport) {
        getEligibleCountries();
      }

      if (vm.hasSaleTransactionWorkflowEnhancement) {
        getTenantTypes();
      }
    }

    function getTenantTypes() {
      vm.tenantTypes.push({ name: App.localize('All'), value: null });
      vm.tenantTypes.push({ name: App.localize('RegularPanel'), value: 1 });
      vm.tenantTypes.push({ name: App.localize('SpecialistPanel'), value: 8 });
      vm.tenantTypes.push({ name: App.localize('PharmacyPanel'), value: 9 });
      vm.tenantTypes.push({ name: App.localize('HospitalPanel'), value: 10 });
      vm.tenantTypes.push({ name: App.localize('Corporate'), value: 2 });
      vm.tenantTypes.push({ name: App.localize('Insurer'), value: 13 });
      vm.tenantTypes.push({ name: App.localize('IhmHospitalPanel'), value: 11 });
      vm.tenantTypes.push({ name: App.localize('Introducer'), value: 4 });
      vm.tenantTypes.push({ name: App.localize('Reimbursement'), value: 6 });

      if (vm.isOrganization) {
        // Corporate
        vm.setTransactionCategory(2);
      } else {
        vm.setTransactionCategory(vm.requestParams.tenantType);
      }
    }

    /* Functions */

    function deletePayment(transactionNumber) {
      abp.message.confirm(
        App.localize('DeletePaymentWarningMessage', transactionNumber),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            saleTransactionSvc
              .deletePayment({
                id: transactionNumber,
              })
              .success(() => {
                abp.notify.success(App.localize('SuccessfullyDeleted'));
                getSaleTransactions();
              });
          }
        }
      );
    }

    function generateFinanceReports() {
      $uibModal.open({
        templateUrl: require('./generateFinanceReportsModal.html'),
        controller: 'common.views.saleTransactions.generateFinanceReportsModal as vm',
        backdrop: 'static',
      });
    }

    function generateStatement() {
      $uibModal
        .open({
          templateUrl: require('./createStatementModal.html'),
          controller: 'common.views.saleTransactions.createStatementModal as vm',
          backdrop: 'static',
          resolve: {
            tenantId() {
              return abp.session.tenantId;
            },
            subsidiaryId: null,
          },
        })
        .result.then((input) => {
          $state.go('statement', input);
        });
    }

    function getInsurers() {
      vm.loading += 1;
      commonLookupSvc
        .getInsurers()
        .success((data) => {
          vm.insurers = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getSaleTransactions() {
      registerStateParams();
      const input = vm.requestParams.toInputDto();
      const refreshColumnVisibility =
        input.category !== vm.viewCategory || input.type !== vm.viewType;

      vm.loading += 1;
      saleTransactionSvc
        .getSaleTransactions(input)
        .success((data) => {
          vm.gridOptions.totalItems = data.totalCount;
          vm.gridOptions.data = data.items;

          if (vm.isPanel) {
            _.each(vm.gridOptions.data, (gridData) => {
              const d = gridData;
              d.panelExclusiveType = panelExclusiveTransactionTypes.getTransactionType(
                d.category,
                d.type
              );
            });
          }

          if (refreshColumnVisibility) {
            vm.viewType = input.type;
            vm.viewCategory = input.category;
            for (let i = 0; i < $scope.gridApi.grid.columns.length; i += 1) {
              const { colDef } = $scope.gridApi.grid.columns[i];
              if (colDef.visibleEvaluator) {
                colDef.visible = colDef.visibleEvaluator();
              }
            }
            $scope.gridApi.grid.refresh();
          }

          if (vm.hasSaleTransactionWorkflowEnhancement) {
            getGridDisplayNames(vm.gridOptions.data);
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // Set tenant type and transaction category display names

    function getGridDisplayNames(data) {
      _.forEach(data, (d) => {
        // Set tenant type display name.

        switch (d.tenantType) {
          case 1: // Regular panel.
            d.tenantTypeDisplayName = App.localize('RegularPanel');
            break;

          case 2: // Corporate.
            d.tenantTypeDisplayName = App.localize('Corporate');
            break;

          case 3: // Service.
            d.tenantTypeDisplayName = App.localize('Service');
            break;

          case 4: // Introducer
            d.tenantTypeDisplayName = App.localize('Introducer');
            break;

          case 5: // Sales person.
            d.tenantTypeDisplayName = App.localize('SalesPerson');
            break;

          case 6: // Reimbursement.
            d.tenantTypeDisplayName = App.localize('Reimbursement');
            break;

          case 7: // Customer success.
            d.tenantTypeDisplayName = App.localize('CustomerSuccess');
            break;

          case 8: // Specialist panel.
            d.tenantTypeDisplayName = App.localize('SpecialistPanel');
            break;

          case 9: // Pharmacy panel.
            d.tenantTypeDisplayName = App.localize('PharmacyPanel');
            break;

          case 10: // Hospital panel.
            d.tenantTypeDisplayName = App.localize('HospitalPanel');
            break;

          case 11: // Ihm hospital panel.
            d.tenantTypeDisplayName = App.localize('IhmHospitalPanel');
            break;

          case 12: // Subscription.
            d.tenantTypeDisplayName = App.localize('Subscription');
            break;

          case 13: // Insurer.
            d.tenantTypeDisplayName = App.localize('Insurer');
            break;

          default:
            break;
        }

        // Set transaction category display name.

        switch (d.transactionCategory) {
          case 1: // Outpatient claims.
            d.transactionCategoryDisplayName = App.localize('OutpatientClaims');
            break;

          case 2: // Outpatient license.
            d.transactionCategoryDisplayName = App.localize('OutpatientLicense');
            break;

          case 3: // Inpatient claims.
            d.transactionCategoryDisplayName = App.localize('InpatientClaims');
            break;

          case 4: // Inpatient license.
            d.transactionCategoryDisplayName = App.localize('InpatientLicense');
            break;

          case 5: // Outpatient utilisations.
            d.transactionCategoryDisplayName = App.localize('OutpatientUtilizations');
            break;

          case 6: // Inpatient utilisations.
            d.transactionCategoryDisplayName = App.localize('InpatientUtilizations');
            break;

          case 7: // Subscription.
            d.transactionCategoryDisplayName = App.localize('Subscription');
            break;

          case 8: // Commission.
            d.transactionCategoryDisplayName = App.localize('Commission');
            break;

          case 9: // Reimbursement.
            d.transactionCategoryDisplayName = App.localize('Reimbursement');
            break;

          case 10: // Legacy claims.
            d.transactionCategoryDisplayName = App.localize('Claims');
            break;

          case 11: // Legacy utilisations.
            d.transactionCategoryDisplayName = App.localize('Utilization');
            break;

          default:
            break;
        }
      });
    }

    function manualCreateExcessPayment() {
      $uibModal.open({
        templateUrl: require('./manualCreateExcessPayment/manualCreateExcessPayment.modal.html'),
        controller: 'common.views.saleTransactions.manualCreateExcessPaymentModal as vm',
        backdrop: 'static',
      });
    }

    function send(saleTransaction) {
      saleTransactionSvc
        .getFinanceEmails({
          tenantId: saleTransaction.tenantId,
          subsidiaryId: saleTransaction.subsidiaryId,
        })
        .success((data) => {
          swal(
            {
              title: App.localize('SendEmailForX', saleTransaction.transactionNumber),
              text: App.localize('EmailAddresses'),
              type: 'input',
              showCancelButton: true,
              closeOnConfirm: false,
              confirmButtonColor: '#1ab394',
              inputPlaceholder: App.localize('EmailAddressesNarrative'),
              inputValue: data.emailAddresses,
              showLoaderOnConfirm: true,
            },
            (inputValue) => {
              if (inputValue === false) return false;
              if (inputValue === '') {
                swal.showInputError(App.localize('InvalidEmailAddress'));
                return false;
              }
              saleTransactionSvc
                .sendEmail({
                  transactionNumber: saleTransaction.transactionNumber,
                  emailAddresses: inputValue,
                })
                .success(() => {
                  abp.notify.info(App.localize('EmailSent'));
                  swal.close();
                  getSaleTransactions();
                });
              return false;
            }
          );
        });
    }

    function setManualPaymentInstructionNumber(saleTransaction) {
      swal(
        {
          title: App.localize('ManualPaymentInstruction'),
          text: App.localize(
            'SetManualPaymentInstructionNumberForX',
            saleTransaction.transactionNumber
          ),
          type: 'input',
          showCancelButton: true,
          closeOnConfirm: false,
          confirmButtonColor: '#1ab394',
          inputPlaceholder: App.localize('PaymentInstructionNumber'),
          inputValue: saleTransaction.manualPaymentInstructionNumber,
          showLoaderOnConfirm: true,
        },
        (inputValue) => {
          if (inputValue === false) return false;
          saleTransactionSvc
            .setManualPaymentInstructionNumber({
              transactionNumber: saleTransaction.transactionNumber,
              manualPaymentInstructionNumber: inputValue,
            })
            .success(() => {
              abp.notify.info(App.localize('SuccessfullySaved'));
              swal.close();
              getSaleTransactions();
            });
          return false;
        }
      );
    }

    /* Export */

    function exportToExcel() {
      const input = vm.requestParams.toInputDto();
      vm.exporting += 1;
      saleTransactionSvc
        .getSaleTransactionsToExcel(input)
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.exporting -= 1;
        });
    }

    function printList() {
      function printItems() {
        const input = vm.requestParams.toInputDto();
        window.open($root.resolveAppPath(`Print/SaleTransactions?${$.param(input)}`));
      }

      if (vm.gridOptions.totalItems > 10) {
        abp.message.confirm(
          App.localize('ConfirmPrintingXTransactions', vm.gridOptions.totalItems),
          App.localize('AreYouSure'),
          (d) => {
            if (d) printItems();
          }
        );
      } else {
        printItems();
      }
    }

    /* End of Export */

    function resetInsurer(category) {
      if (category === 13) return;

      vm.requestParams.insurerId = null;
    }

    /* Models */

    function DateRangeModel(startDate, endDate, eventCallback) {
      this.startDate = startDate ? moment(startDate) : null;
      this.endDate = endDate ? moment(endDate) : null;
      this.options = App.createDateRangePickerOptions();
      this.options.eventHandlers = {
        'apply.daterangepicker': () => {
          eventCallback();
        },
      };
    }

    function PanelExclusiveTransactionTypesModel() {
      function TransactionTypeModel(id, displayName, tooltip, category, type) {
        this.id = id;
        this.displayName = displayName;
        this.tooltip = tooltip;
        this.category = category;
        this.type = type;
      }

      this.panelCategoryValue = 1;
      if (App.isHospital()) this.panelCategoryValue = 10;
      else if (vm.isSpecialist) this.panelCategoryValue = 8;
      else if (App.isPharmacy()) this.panelCategoryValue = 9;

      this.items = [
        new TransactionTypeModel(
          1,
          App.localize('ClaimInvoice'),
          App.localize('ClaimInvoiceTooltip'),
          this.panelCategoryValue,
          1
        ),
        new TransactionTypeModel(
          2,
          App.localize('ClaimCreditNote'),
          App.localize('ClaimCreditNoteTooltip'),
          this.panelCategoryValue,
          2
        ),
        new TransactionTypeModel(
          3,
          App.localize('ClaimPayment'),
          App.localize('ClaimPaymentTooltip'),
          this.panelCategoryValue,
          3
        ),
        new TransactionTypeModel(
          4,
          App.localize('ServiceChargeBill'),
          App.localize('ServiceChargeBillTooltip'),
          3,
          1
        ),
        new TransactionTypeModel(
          5,
          App.localize('LicensingFeeCreditNote'),
          App.localize('LicensingFeeCreditNoteTooltip'),
          3,
          2
        ),
        new TransactionTypeModel(
          6,
          App.localize('LicensingFeePayment'),
          App.localize('LicensingFeePaymentTooltip'),
          3,
          3
        ),
      ];

      if (vm.hasGpDashboardEnhancement && this.panelCategoryValue === 1) {
        this.items.push(
          new TransactionTypeModel(
            7,
            App.localize('OffsetOriginSales'),
            App.localize('OffsetOriginSalesTooltip'),
            this.panelCategoryValue,
            4
          )
        );
      }

      this.getTransactionType = (category, type) => {
        const transactionType = _.find(
          this.items,
          (i) => i.category === category && i.type === type
        );
        // if (!transactionType) console.error('Unknown transaction category and type.');
        return transactionType;
      };

      this.toNameValueDto = () => {
        const dtos = [];
        _.each(this.items, (i) => {
          dtos.push({
            name: i.displayName,
            value: i.id,
          });
        });
        return dtos;
      };

      this.toDto = (id) => {
        const transactionType = _.find(this.items, ['id', id]);
        // if (!transactionType) console.error('Unknown transaction category and type.');
        return {
          category: transactionType.category,
          type: transactionType.type,
        };
      };
    }

    function RequestParamsModel(params) {
      this.category = parseInt(params.category, 10) || null;
      this.tenantType = params.tenantType || null;
      this.transactionCategory = params.transactionCategory || null;
      this.company = params.company || '';
      this.subsidiary = params.subsidiary || '';
      this.transactionNumber = params.transactionNumber || '';
      this.type = params.transactionType || null;
      this.status = params.status;
      this.minAmount = parseFloat(params.minAmount) || null;
      this.maxAmount = parseFloat(params.maxAmount) || null;
      this.minAging = parseFloat(params.minAging) || null;
      this.maxAging = parseFloat(params.maxAging) || null;
      this.paymentInstructionTransactionNumber = params.paymentInstructionTransactionNumber;
      this.insurerId = params.insurerId || '';
      this.skipCount = params.skipCount || 0;
      this.maxResultCount = params.maxResultCount || appConstants.grid.defaultPageSize;
      this.sorting = params.sorting;
      this.countryCode = params.countryCode || '';

      this.toInputDto = () => {
        const input = $.extend({}, this);

        if (vm.dateRangeModel.startDate)
          input.startDate = moment(vm.dateRangeModel.startDate).toJSON();
        if (vm.dateRangeModel.endDate) input.endDate = moment(vm.dateRangeModel.endDate).toJSON();

        if (input.type && vm.isPanel) {
          const panelExclusiveType = panelExclusiveTransactionTypes.toDto(input.type);
          input.type = panelExclusiveType.type;
          input.category = panelExclusiveType.category;
        }

        if (input.type === 3) vm.requestParams.status = null;
        if (vm.requestParams.status === 1) input.isClosed = false;
        else if (vm.requestParams.status === 2) input.isClosed = true;
        else input.isClosed = null;

        return _.omitBy(input, _.isFunction);
      };

      this.toStateParams = () => {
        const startDate = vm.dateRangeModel.startDate
          ? vm.dateRangeModel.startDate.format('YYYY-MM-DD')
          : null;
        const endDate = vm.dateRangeModel.endDate
          ? vm.dateRangeModel.endDate.format('YYYY-MM-DD')
          : null;

        const stateParams = {
          category: this.category,
          tenantType: this.tenantType,
          transactionCategory: this.transactionCategory,
          company: this.company,
          subsidiary: this.subsidiary,
          transactionNumber: this.transactionNumber,
          transactionType: this.type,
          status: this.status,
          minAmount: this.minAmount,
          maxAmount: this.maxAmount,
          minAging: this.minAging,
          maxAging: this.maxAging,
          startDate,
          endDate,
          paymentInstructionTransactionNumber: this.paymentInstructionTransactionNumber,
          insurerId: this.insurerId,
          skipCount: this.skipCount,
          maxResultCount: this.maxResultCount,
          sorting: this.sorting,
          countryCode: this.countryCode,
        };

        if (stateParams.transactionType && vm.isPanel) {
          const panelExclusiveType = panelExclusiveTransactionTypes.toDto(
            stateParams.transactionType
          );
          stateParams.transactionType = panelExclusiveType.type;
          stateParams.category = panelExclusiveType.category;
        }

        return stateParams;
      };
    }

    function getTenantCurrencyCode() {
      vm.loading += 1;
      commonLookupSvc
        .getTenantCurrencyCode()
        .success((data) => {
          vm.currencyCode = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getEligibleCountries() {
      vm.loading += 1;
      commonLookupSvc
        .getEligibleCountries()
        .success((data) => {
          vm.countries = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function setTransactionCategory(tenantType) {
      vm.transactionCategories = [];

      vm.transactionCategories.push({ name: App.localize('All'), value: null });

      // Regular, Specialist, Pharmacy.

      if (tenantType === 1 || tenantType === 8 || tenantType === 9) {
        vm.transactionCategories.push({ name: App.localize('OutpatientClaims'), value: 1 });

        vm.transactionCategories.push({ name: App.localize('OutpatientLicense'), value: 2 });
      } else {
        switch (tenantType) {
          case 10: // Hospital
            vm.transactionCategories.push({ name: App.localize('OutpatientClaims'), value: 1 });
            vm.transactionCategories.push({ name: App.localize('InpatientClaims'), value: 3 });
            vm.transactionCategories.push({ name: App.localize('OutpatientLicense'), value: 2 });
            vm.transactionCategories.push({ name: App.localize('InpatientLicense'), value: 4 });
            break;

          case 2: // Corporate
            vm.transactionCategories.push({
              name: App.localize('OutpatientUtilizations'),
              value: 5,
            });
            vm.transactionCategories.push({
              name: App.localize('InpatientUtilizations'),
              value: 6,
            });
            vm.transactionCategories.push({ name: App.localize('Subscription'), value: 7 });
            break;

          case 13: // Insurer
            vm.transactionCategories.push({
              name: App.localize('InpatientUtilizations'),
              value: 6,
            });
            vm.transactionCategories.push({ name: App.localize('Subscription'), value: 7 });
            break;

          case 11: // Ihm hospital
            vm.transactionCategories.push({ name: App.localize('InpatientClaims'), value: 3 });
            break;

          case 4: // Introducer
            vm.transactionCategories.push({ name: App.localize('Commission'), value: 8 });
            break;

          case 6: // Reimbursement
            vm.transactionCategories.push({ name: App.localize('Reimbursement'), value: 9 });
            break;

          default:
            break;
        }
      }
    }

    function getDateTimeString(date, timeZoneId) {
      return App.getDateTimeString(date, timeZoneId);
    }

    /* End of Models */

    /* End of Functions */
  }
})();
