(function () {
    'use strict';

    angular.module('app').component('hostCorporatesSupportPlansCreateEditScheduleOfBenefitInpatientSurgicalComponent', {
        templateUrl: require('/App/host/views/corporates/supportPlans/createEdit/scheduleOfBenefit/inpatientSurgical/inpatientSurgical.component.html'),
        controller: ['Hms.BenefitGroups.CoveredCycle', controller],
        controllerAs: 'vm',
        bindings: {
            scheduleOfBenefit: '<',
            currencyCode: '<',
            countryCode: '<'
        }
    });

    function controller(enumCoveredCycle) {
        var vm = this;

        vm.hasScheduleOfBenefitEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');
        vm.hasTxtFileSupport = abp.setting.getBoolean('Hms.Feature.TxtFileSupport');
        vm.surgicalFeeAmountType = null;
        vm.anaesthetistFeeAmountType = null;
        vm.operatingTheatreAmountType = null;
        vm.hospitalSuppliesAndServicesAmountType = null;
        vm.ambulanceFeeAmountType = null;
        vm.organTransplantAmountType = null;
        vm.organTransplantMaxDaysTimesType = null;
        vm.isFixedLimit = null;

        // AmountTypes
        vm.daySurgeryAmountType = null;

        vm.enums = {};
        vm.enums.coveredCycle = enumCoveredCycle;

        // AmountType toggle
        vm.toggleAmountType = function (SOBItem) {
            var isFixedLimit = vm.scheduleOfBenefit.hospitalCharges.isFixedLimit;
            vm.minFixedLimitAmount = isFixedLimit ? 1 : 0;
            vm.minAnaesthetistFeeAmount = !isFixedLimit && vm.anaesthetistFeeAmountType === 1 ? 1 : 0;
            vm.minOperatingTheatreAmount = !isFixedLimit && vm.operatingTheatreAmountType === 1 ? 1 : 0;
            vm.minOrganTransplantAmount = vm.organTransplantAmountType === 1 ? 1 : 0;
            vm.minOrganTransplantCoveredTimes = vm.organTransplantAmountType === 2
                || (vm.organTransplantAmountType !== 2 && vm.organTransplantMaxDaysTimesType === 1) ? 0 : 1;
            vm.scheduleOfBenefit.hospitalCharges.isFixedLimit = vm.isFixedLimit === 1 ? true : false;
            vm.minDaySurgeryAmount = !isFixedLimit && vm.daySurgeryAmountType === 1 ? 1 : 0;

            switch (SOBItem) {
                case 'surgicalFee':
                    switch (vm.surgicalFeeAmountType) {
                        case 0: /*ischarged*/
                            vm.scheduleOfBenefit.hospitalCharges.surgicalFee.isAsCharged = true;
                            vm.scheduleOfBenefit.hospitalCharges.surgicalFee.isCovered = true;
                            break;
                        case 1: /*limited*/
                            vm.scheduleOfBenefit.hospitalCharges.surgicalFee.isAsCharged = false;
                            vm.scheduleOfBenefit.hospitalCharges.surgicalFee.isCovered = true;
                            break;
                        case 2: /*uncovered*/
                            vm.scheduleOfBenefit.hospitalCharges.surgicalFee.isAsCharged = false;
                            vm.scheduleOfBenefit.hospitalCharges.surgicalFee.isCovered = false;
                            break;
                    }

                    if (vm.scheduleOfBenefit.hospitalCharges.surgicalFee.isAsCharged === vm.scheduleOfBenefit.hospitalCharges.surgicalFee.isCovered) {
                        vm.tempSurgicalFeeAmount = vm.scheduleOfBenefit.hospitalCharges.surgicalFee.amount;
                        vm.scheduleOfBenefit.hospitalCharges.surgicalFee.amount = null;

                        vm.tempSurgicalFeeEffectivePeriod = vm.scheduleOfBenefit.hospitalCharges.surgicalFee.coveredCycle;
                        vm.scheduleOfBenefit.hospitalCharges.surgicalFee.coveredCycle = null;
                    } else {
                        vm.scheduleOfBenefit.hospitalCharges.surgicalFee.amount = vm.tempSurgicalFeeAmount;

                        if (vm.tempSurgicalFeeEffectivePeriod) {
                            vm.scheduleOfBenefit.hospitalCharges.surgicalFee.coveredCycle = vm.tempSurgicalFeeEffectivePeriod;
                        } else {
                            vm.scheduleOfBenefit.hospitalCharges.surgicalFee.coveredCycle = null; // NotApplicable
                        }
                    }

                    break;
                case 'daySurgery':
                    switch (vm.daySurgeryAmountType) {
                        case 0: /*ischarged*/
                            vm.scheduleOfBenefit.daySurgery.isAsCharged = true;
                            vm.scheduleOfBenefit.daySurgery.isCovered = true;
                            break;
                        case 1: /*limited*/
                            vm.scheduleOfBenefit.daySurgery.isAsCharged = false;
                            vm.scheduleOfBenefit.daySurgery.isCovered = true;
                            break;
                        case 2: /*uncovered*/
                            vm.scheduleOfBenefit.daySurgery.isAsCharged = false;
                            vm.scheduleOfBenefit.daySurgery.isCovered = false;
                            break;
                    }

                    if (vm.scheduleOfBenefit.daySurgery.isAsCharged === vm.scheduleOfBenefit.daySurgery.isCovered) {
                        vm.tempDaySurgeryAmount = vm.scheduleOfBenefit.daySurgery.amount;
                        vm.scheduleOfBenefit.daySurgery.amount = null;
                    } else {
                        vm.scheduleOfBenefit.daySurgery.amount = vm.tempDaySurgeryAmount;
                    }

                    break;
                case 'anaesthetistFee':
                    switch (vm.anaesthetistFeeAmountType) {
                        case 0: /*ischarged*/
                            vm.scheduleOfBenefit.hospitalCharges.anaesthetistFee.isAsCharged = true;
                            vm.scheduleOfBenefit.hospitalCharges.anaesthetistFee.isCovered = true;
                            break;
                        case 1: /*limited*/
                            vm.scheduleOfBenefit.hospitalCharges.anaesthetistFee.isAsCharged = false;
                            vm.scheduleOfBenefit.hospitalCharges.anaesthetistFee.isCovered = true;
                            break;
                        case 2: /*uncovered*/
                            vm.scheduleOfBenefit.hospitalCharges.anaesthetistFee.isAsCharged = false;
                            vm.scheduleOfBenefit.hospitalCharges.anaesthetistFee.isCovered = false;
                            break;
                    }

                    if (vm.scheduleOfBenefit.hospitalCharges.anaesthetistFee.isAsCharged === vm.scheduleOfBenefit.hospitalCharges.anaesthetistFee.isCovered) {
                        vm.tempAnaesthetistFeeAmount = vm.scheduleOfBenefit.hospitalCharges.anaesthetistFee.amount;
                        vm.scheduleOfBenefit.hospitalCharges.anaesthetistFee.amount = null;
                    } else {
                        vm.scheduleOfBenefit.hospitalCharges.anaesthetistFee.amount = vm.tempAnaesthetistFeeAmount;
                    }

                    break;
                case 'operatingTheatre':
                    switch (vm.operatingTheatreAmountType) {
                        case 0: /*ischarged*/
                            vm.scheduleOfBenefit.hospitalCharges.operatingTheatre.isAsCharged = true;
                            vm.scheduleOfBenefit.hospitalCharges.operatingTheatre.isCovered = true;
                            break;
                        case 1: /*limited*/
                            vm.scheduleOfBenefit.hospitalCharges.operatingTheatre.isAsCharged = false;
                            vm.scheduleOfBenefit.hospitalCharges.operatingTheatre.isCovered = true;
                            break;
                        case 2: /*uncovered*/
                            vm.scheduleOfBenefit.hospitalCharges.operatingTheatre.isAsCharged = false;
                            vm.scheduleOfBenefit.hospitalCharges.operatingTheatre.isCovered = false;
                            break;
                    }

                    if (vm.scheduleOfBenefit.hospitalCharges.operatingTheatre.isAsCharged === vm.scheduleOfBenefit.hospitalCharges.operatingTheatre.isCovered) {
                        vm.tempOperatingTheatreAmount = vm.scheduleOfBenefit.hospitalCharges.operatingTheatre.amount;
                        vm.scheduleOfBenefit.hospitalCharges.operatingTheatre.amount = null;
                    } else {
                        vm.scheduleOfBenefit.hospitalCharges.operatingTheatre.amount = vm.tempOperatingTheatreAmount;
                    }

                    break;
                case 'organTransplant':
                    switch (vm.organTransplantAmountType) {
                        case 0: /*ischarged*/
                            vm.scheduleOfBenefit.organTransplant.isAsCharged = true;
                            vm.scheduleOfBenefit.organTransplant.isCovered = true;
                            break;
                        case 1: /*limited*/
                            vm.scheduleOfBenefit.organTransplant.isAsCharged = false;
                            vm.scheduleOfBenefit.organTransplant.isCovered = true;
                            break;
                        case 2: /*uncovered*/
                            vm.scheduleOfBenefit.organTransplant.isAsCharged = false;
                            vm.scheduleOfBenefit.organTransplant.isCovered = false;
                            break;
                    }

                    if (vm.scheduleOfBenefit.organTransplant.isAsCharged === vm.scheduleOfBenefit.organTransplant.isCovered) {
                        vm.tempOrganTransplantAmount = vm.scheduleOfBenefit.organTransplant.amount;
                        vm.scheduleOfBenefit.organTransplant.amount = null;

                        vm.tempOrganTransplantEffectivePeriod = vm.scheduleOfBenefit.organTransplant.coveredCycle;
                        vm.scheduleOfBenefit.organTransplant.coveredCycle = null;
                    } else {
                        vm.scheduleOfBenefit.organTransplant.amount = vm.tempOrganTransplantAmount;

                        if (vm.tempOrganTransplantEffectivePeriod) {
                            vm.scheduleOfBenefit.organTransplant.coveredCycle = vm.tempOrganTransplantEffectivePeriod;
                        } else {
                            vm.scheduleOfBenefit.organTransplant.coveredCycle = 6; // PerAdmission
                        }
                    }

                    break;
            }
        }

        function initializeAmountTypes() {
            var isFixedLimit = vm.scheduleOfBenefit.hospitalCharges.isFixedLimit;
            vm.minSurgicalFeeAmount = !isFixedLimit && vm.surgicalFeeAmountType === 1 ? 1 : 0;
            vm.isFixedLimit = vm.scheduleOfBenefit.hospitalCharges.isFixedLimit ? 1 : 0;
            vm.surgicalFeeAmountType = vm.scheduleOfBenefit.hospitalCharges.surgicalFee.isAsCharged ? 0 : 1;
            vm.anaesthetistFeeAmountType = vm.scheduleOfBenefit.hospitalCharges.anaesthetistFee.isAsCharged ? 0 : 1;
            vm.operatingTheatreAmountType = vm.scheduleOfBenefit.hospitalCharges.operatingTheatre.isAsCharged ? 0 : 1;
            vm.hospitalSuppliesAndServicesAmountType = vm.scheduleOfBenefit.hospitalCharges.hospitalSuppliesAndServices.isAsCharged ? 0 : 1;

            // Day Surgery AmountType
            if (!vm.scheduleOfBenefit.daySurgery.isCovered)
                vm.daySurgeryAmountType = 2;
            else if (vm.scheduleOfBenefit.daySurgery.isAsCharged)
                vm.daySurgeryAmountType = 0;
            else if (!vm.scheduleOfBenefit.daySurgery.isAsCharged) {
                vm.daySurgeryAmountType = 1;
            }

            // Ambulance Fee AmountType
            if (!vm.scheduleOfBenefit.ambulanceFee.isCovered)
                vm.ambulanceFeeAmountType = 2;
            else if (vm.scheduleOfBenefit.ambulanceFee.isAsCharged)
                vm.ambulanceFeeAmountType = 0;
            else if (!vm.scheduleOfBenefit.ambulanceFee.isAsCharged)
                vm.ambulanceFeeAmountType = 1;

            // Organ Transplant AmountType
            if (!vm.scheduleOfBenefit.organTransplant.isCovered)
                vm.organTransplantAmountType = 2;
            else if (vm.scheduleOfBenefit.organTransplant.isAsCharged)
                vm.organTransplantAmountType = 0;
            else if (!vm.scheduleOfBenefit.organTransplant.isAsCharged)
                vm.organTransplantAmountType = 1;

            vm.organTransplantMaxDaysTimesType = vm.scheduleOfBenefit.organTransplant.isUnlimited ? 1 : 0;
        }

        function init() {
            initializeAmountTypes();
            vm.toggleAmountType();
        }

        vm.toggleAdmissionCharges = function () {
            var isFixedLimit = vm.scheduleOfBenefit.hospitalCharges.isFixedLimit;
            vm.minFixedLimitAmount = isFixedLimit ? 1 : 0;
            vm.minSurgicalFeeAmount = !isFixedLimit && vm.surgicalFeeAmountType === 1 ? 1 : 0;
            vm.minAnaesthetistFeeAmount = !isFixedLimit && vm.anaesthetistFeeAmountType === 1 ? 1 : 0;
            vm.minOperatingTheatreAmount = !isFixedLimit && vm.operatingTheatreAmountType === 1 ? 1 : 0;
            vm.minHospitalSuppliesAndServicesAmount = !isFixedLimit && vm.hospitalSuppliesAndServicesAmountType === 1 ? 1 : 0;
            vm.minAmbulanceFeeAmount = vm.ambulanceFeeAmountType === 1 ? 1 : 0;
            vm.minOrganTransplantAmount = vm.organTransplantAmountType === 1 ? 1 : 0;
            vm.minOrganTransplantCoveredTimes = vm.organTransplantAmountType === 2
                || (vm.organTransplantAmountType !== 2 && vm.organTransplantMaxDaysTimesType === 1) ? 0 : 1;
            vm.scheduleOfBenefit.hospitalCharges.isFixedLimit = vm.isFixedLimit === 1 ? true : false;

            setSurgicalFeeAmountType();
            setAnaesthetistFeeAmountType();
            setOperatingTheatreAmountType();
            setHospitalSuppliesAndServicesAmountType();
            setAmbulanceFeeAmountType();
            setOrganTransplantAmountType();
            setOrganTransplantMaxDaysTimesType();
        }

        function setSurgicalFeeAmountType() {
            switch (vm.surgicalFeeAmountType) {
                case 0:
                    vm.scheduleOfBenefit.hospitalCharges.surgicalFee.isAsCharged = true;
                    break;

                case 1:
                    vm.scheduleOfBenefit.hospitalCharges.surgicalFee.isAsCharged = false;
                    break;

                default:
                    break;
            }
        }

        function setAnaesthetistFeeAmountType() {
            switch (vm.anaesthetistFeeAmountType) {
                case 0:
                    vm.scheduleOfBenefit.hospitalCharges.anaesthetistFee.isAsCharged = true;
                    break;

                case 1:
                    vm.scheduleOfBenefit.hospitalCharges.anaesthetistFee.isAsCharged = false;
                    break;

                default:
                    break;
            }
        }

        function setOperatingTheatreAmountType() {
            switch (vm.operatingTheatreAmountType) {
                case 0:
                    vm.scheduleOfBenefit.hospitalCharges.operatingTheatre.isAsCharged = true;
                    break;

                case 1:
                    vm.scheduleOfBenefit.hospitalCharges.operatingTheatre.isAsCharged = false;
                    break;

                default:
                    break;
            }
        }

        function setHospitalSuppliesAndServicesAmountType() {
            switch (vm.hospitalSuppliesAndServicesAmountType) {
                case 0:
                    vm.scheduleOfBenefit.hospitalCharges.hospitalSuppliesAndServices.isAsCharged = true;
                    break;

                case 1:
                    vm.scheduleOfBenefit.hospitalCharges.hospitalSuppliesAndServices.isAsCharged = false;
                    break;

                default:
                    break;
            }
        }

        function setAmbulanceFeeAmountType() {
            switch (vm.ambulanceFeeAmountType) {
                case 0:
                    vm.scheduleOfBenefit.ambulanceFee.isAsCharged = true;
                    vm.scheduleOfBenefit.ambulanceFee.isCovered = true;
                    break;

                case 1:
                    vm.scheduleOfBenefit.ambulanceFee.isAsCharged = false;
                    vm.scheduleOfBenefit.ambulanceFee.isCovered = true;
                    break;

                case 2:
                    vm.scheduleOfBenefit.ambulanceFee.isAsCharged = false;
                    vm.scheduleOfBenefit.ambulanceFee.isCovered = false;
                    break;

                default:
                    break;
            }
        }

        function setOrganTransplantAmountType() {
            switch (vm.organTransplantAmountType) {
                case 0:
                    vm.scheduleOfBenefit.organTransplant.isAsCharged = true;
                    vm.scheduleOfBenefit.organTransplant.isCovered = true;
                    break;

                case 1:
                    vm.scheduleOfBenefit.organTransplant.isAsCharged = false;
                    vm.scheduleOfBenefit.organTransplant.isCovered = true;
                    break;

                case 2:
                    vm.scheduleOfBenefit.organTransplant.isAsCharged = false;
                    vm.scheduleOfBenefit.organTransplant.isCovered = false;
                    break;

                default:
                    break;
            }
        }

        function setOrganTransplantMaxDaysTimesType() {
            switch (vm.organTransplantMaxDaysTimesType) {
                case 0:
                    vm.scheduleOfBenefit.organTransplant.isUnlimited = false;
                    break;

                case 1:
                    vm.scheduleOfBenefit.organTransplant.isUnlimited = true;
                    break;

                default:
                    break;
            }
        }

        angular.element(document).ready(function () {
            init();
        });
    }
})();
