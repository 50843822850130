(function () {
    'use strict';

    angular
        .module('app')
        .component('hostDoraemonCommonInpatientTicketsDetailFinalDiagnosisComponent', {
            templateUrl: require('/App/host/views/doraemon/common/inpatientTickets/detail/finalDiagnosis/finalDiagnosis.component.html'),
            controller: ['$scope', controller],
            controllerAs: 'vm',
            bindings: {
                request: '<',
                diseaseClassifications: '<',
                isEdit: '<',
                isSaved: '<',
            }
        });

    function controller($scope) {
        var vm = this;
        var limitSize = 30;
        vm.filteredIcds = [];

        vm.$onChanges = onChanges;
        vm.addDiagnosis = addDiagnosis;
        vm.clearDiagnosis = clearDiagnosis;
        vm.lookupIcd = lookupIcd;
        vm.deleteItem = deleteItem;
        vm.updateDiagnosis = updateDiagnosis;

        function onChanges(changes) {
            if (changes.isSaved && changes.isSaved.currentValue) {
                tidyDiagnosis();
            }

            if (changes.isEdit && changes.isEdit.currentValue) {
                if (vm.request.diagnoses.length < 1) {
                    addDiagnosis();
                }
            }

            if (changes.request && changes.request.currentValue) {
                _.each(vm.request.diagnoses, function (diagnosis) {
                    initDiagnosis(diagnosis);
                });
            }
        }

        function addDiagnosis() {
            vm.request.diagnoses.push({});
        }

        function clearDiagnosis() {
            vm.request.diagnoses = [{}];
        }

        function initDiagnosis(diagnosis) {
            if (diagnosis.description && _.isNil(diagnosis.code)) {
                diagnosis.code = generateId();
            } else {
                var selectedDiagnosis = _.find(vm.diseaseClassifications, { code: diagnosis.code });
                if (selectedDiagnosis) {
                    diagnosis.code = selectedDiagnosis.code;
                    diagnosis.description = selectedDiagnosis.description;
                }
            }
        }

        function updateDiagnosis(diagnosis) {
            var customDiagnosis = _.find(vm.filteredIcds, { code: diagnosis.code });

            if (customDiagnosis) {
                if (diagnosis.code < 0) {
                    diagnosis.code = generateId();
                } else {
                    diagnosis.code = customDiagnosis.code;
                }
                diagnosis.id = null;
                diagnosis.description = customDiagnosis.description;
            }
            getDiagnoses();
        }

        function generateId() {
            // Set custom diagnosis id to negative number of current time.
            // To resolve ng-change where unable to change custom diagnosis,
            // due to diagnosis listing set to -1 for all custom diagnosis

            return -Math.abs(new Date().getTime());
        }

        function tidyDiagnosis() {
            vm.request.diagnoses = _.filter(vm.request.diagnoses, function (item) {
                return item.code !== undefined;
            });
        }

        function lookupIcd(filter) {
            var keyword = _.trim(filter || '');
            if (keyword) {
                // Include free-text entry in filtered ICD set.

                var filteredIcds = [{
                    item: {
                        description: keyword,
                        code: -1 // Default to -1 because free-text entry has no corresponding disease classification ID.
                    },
                    score: -100 // Set free-text entry with high negative score so that it stays as first choice.
                }];

                var regexes = _.chain(keyword.split(' '))
                    .map(function (k) {
                        return _.trim(k.toUpperCase());
                    })
                    .filter(function (k) {
                        return k;
                    })
                    .uniq()
                    .map(function (k) {
                        _.escapeRegExp(k);
                        return {
                            token: k,
                            len: k.length,
                            pattern: new RegExp('\\b' + k, 'i')
                        };
                    })
                    .value();

                _.forEach(vm.diseaseClassifications, function (icd) {
                    var score = 0;

                    // Exclude selected icd.

                    var isSelected = _.find(vm.request.diagnoses, function (s) {
                        return s.code === icd.code;
                    });

                    if (!isSelected) {
                        _.forEach(regexes, function (r) {
                            if (r.pattern.test(icd.description)) {
                                score -= r.len;
                            }
                        });
                    }

                    if (score) {
                        filteredIcds.push({
                            score: score,
                            item: icd
                        });
                    }
                });

                vm.filteredIcds = _.chain(filteredIcds)
                    .sortBy(['score'])
                    .take(30)
                    .map(function (k) {
                        return k.item;
                    })
                    .uniqBy(function (s) {
                        return s.code;
                    })
                    .value();
            } else {
                getDiagnoses();
            }
        }

        function deleteItem(index) {
            if (vm.request.diagnoses.length > 1) {
                vm.request.diagnoses.splice(index, 1);
                getDiagnoses();
            }
            else {
                vm.request.diagnoses = [];
                addDiagnosis();
            }
        }

        function getDiagnoses() {
            var tempIcd = [];
            _.forEach(vm.diseaseClassifications, function (diagnosis) {
                var selectedDiagnosis = _.find(vm.request.diagnoses, { code: diagnosis.code });

                if (!selectedDiagnosis) {
                    tempIcd.push(diagnosis);
                }
            });

            tempIcd = _.sortBy(tempIcd, x => x.code);
            vm.filteredIcds = _.take(tempIcd, limitSize);
        }
    }
})();
