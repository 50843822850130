(() => {
  angular
    .module('app')
    .controller(
      'common.views.saleTransactions.paymentInstructionRequests',
      PaymentInstructionRequestsController
    );

  PaymentInstructionRequestsController.$inject = [
    '$scope',
    '$state',
    '$timeout',
    '$stateParams',
    '$uibModal',
    'abp.services.app.saleTransaction',
    'abp.services.app.commonLookup',
    'moment',
    'appConstants',
  ];

  function PaymentInstructionRequestsController(
    $scope,
    $state,
    $timeout,
    $stateParams,
    $uibModal,
    saleTransactionSvc,
    commonLookupSvc,
    moment,
    appConstants
  ) {
    const vm = this;

    vm.loading = 0;
    vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');
    vm.stopInvoiceGeneration = abp.setting.getBoolean('Hms.Feature.StopPortalInvoiceGeneration');
    vm.loadingDisplay = `${App.localize('Loading')}...`;
    vm.countries = [];
    vm.loadingDetails = 0;

    // Define grid options

    const viewOptions = {
      skipCount: $stateParams.skipCount || 0,
      maxResultCount: $stateParams.maxResultCount || appConstants.grid.defaultPageSize,
    };

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSizes,
      paginationPageSize: appConstants.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      enableSorting: false,
      columnDefs: [
        {
          name: App.localize('Actions'),
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
        },
        {
          displayName: App.localize('Country'),
          field: 'countryCode',
          width: 30,
          visible: vm.hasRegionalSystemSupport && vm.isHost,
          cellTemplate: 'countryTemplate',
        },
        {
          displayName: App.localize('CreatedBy'),
          field: 'createdBy',
          cellTemplate: 'createdByTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('VerifiedBy'),
          field: 'verifiedBy',
          cellTemplate: 'verifiedByTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('ApprovedBy'),
          field: 'approvedBy',
          cellTemplate: 'approvedByTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('Category'),
          field: 'categoryName',
          minWidth: 100,
        },
        {
          displayName: App.localize('Count'),
          field: 'paymentInstructionCount',
          cellTemplate: 'paymentInstructionCountTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('TotalAmount'),
          field: 'totalAmount',
          cellTemplate: 'totalAmountTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('Paid'),
          field: 'paidAmount',
          cellFilter: 'currencyFormat: grid.appScope.currencyCode',
          cellTemplate: 'paidAmountTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('Unpaid'),
          field: 'unpaidAmount',
          cellFilter: 'currencyFormat: grid.appScope.currencyCode',
          cellTemplate: 'unpaidAmountTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('CorporateUnpaidAmountOriginalShortForm'),
          field: 'organizationUnpaidAmount',
          cellTemplate: 'organizationUnpaidAmountTemplate',
          minWidth: 100,
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        gridApi.pagination.on.paginationChanged($scope, App.setPagination(viewOptions, vm.refresh));
      },
      data: [],
    };

    vm.permissions = {
      canVerify: abp.auth.isGranted('SaleTransactions.Host.PaymentInstruction.Verify'),
      canApprove: abp.auth.isGranted('SaleTransactions.Host.PaymentInstruction.Approve'),
      canGenerateBif: abp.auth.isGranted(
        'SaleTransactions.Host.PaymentInstruction.GeneratePifFile'
      ),
      canGenerateCimbCsv: abp.auth.isGranted(
        'SaleTransactions.Host.PaymentInstruction.GenerateCimbCsvFile'
      ),
      canCreatePayment: abp.auth.isGranted('SaleTransactions.Host.CreatePayment'),
    };

    vm.refresh = refresh;
    vm.create = create;
    vm.verify = verify;
    vm.approve = approve;
    vm.delete = deleteRequest;
    vm.generateExcel = generateExcel;
    vm.generateBif = generateBif;
    vm.generateCitibankTxt = generateCitibankTxt;
    vm.generateCimbCsv = generateCimbCsv;

    init();

    function init() {
      $timeout(() => {
        vm.gridOptions.paginationCurrentPage = Math.floor(
          viewOptions.skipCount / viewOptions.maxResultCount + 1
        );
      });

      if (vm.hasRegionalSystemSupport) getEligibleCountries();
    }

    function registerStateParams() {
      $state.transitionTo(
        $state.current,
        {
          skipCount: viewOptions.skipCount,
          maxResultCount: viewOptions.maxResultCount,
        },
        {
          notify: false,
        }
      );
    }

    function refresh() {
      registerStateParams();
      vm.loading += 1;
      saleTransactionSvc
        .getPaymentInstructionRequests({
          countryCode: vm.countryCode,
          skipCount: viewOptions.skipCount,
          maxResultCount: viewOptions.maxResultCount,
        })
        .success((data) => {
          vm.gridOptions.totalItems = data.totalCount;
          vm.gridOptions.data = data.items;
          getPaymentInstructionRequestDetails(_.map(data.items, (x) => x.id));
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getPaymentInstructionRequestDetails(requestIds) {
      if (!requestIds || !requestIds.length) return;

      vm.loadingDetails += 1;
      saleTransactionSvc
        .getPaymentInstructionRequestDetails(requestIds)
        .success((data) => {
          vm.gridOptions.data = _.map(vm.gridOptions.data, (d) => {
            const details = _.find(data, { id: d.id });
            details.timestampTooltip = App.localize(
              'CorporateUnpaidAmountLiveInfo',
              moment(details.cacheTimestamp).format('L LT')
            );
            return $.extend(d, details);
          });
        })
        .finally(() => {
          vm.loadingDetails -= 1;
        });
    }

    function create() {
      $uibModal
        .open({
          templateUrl: require('./generatePaymentInstructionsModal.html'),
          controller: 'common.views.saleTransactions.generatePaymentInstructionsModal as vm',
          backdrop: 'static',
        })
        .result.then(vm.refresh);
    }

    function updateSignature(requestId, type) {
      $uibModal
        .open({
          templateUrl: require('./changeSignature.html'),
          controller: 'common.views.saleTransactions.changeSignature as vm',
          backdrop: 'static',
          resolve: {
            type() {
              return type;
            },
            requestId() {
              return requestId;
            },
          },
        })
        .result.then(() => {
          vm.refresh();
        });
    }

    function verify(request) {
      updateSignature(request.id, 'verify');
    }

    function approve(request) {
      updateSignature(request.id, 'approve');
    }

    function deleteRequest(request) {
      abp.message.confirm(
        App.localize('PaymentInstructionRequestDeleteWarningMessage'),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            vm.loading += 1;
            saleTransactionSvc
              .deletePaymentInstructionRequest({
                id: request.id,
              })
              .success(() => {
                vm.refresh();
                abp.notify.success(App.localize('SuccessfullyDeleted'));
              })
              .finally(() => {
                vm.loading -= 1;
              });
          }
        }
      );
    }

    function generateExcel(request) {
      vm.loading += 1;
      saleTransactionSvc
        .generatePaymentInstructionExcel({
          requestId: request.id,
        })
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function generateBif(request) {
      vm.loading += 1;
      saleTransactionSvc
        .generatePaymentInstructionBif({
          requestId: request.id,
        })
        .success((data) => {
          if (data.errors) {
            $uibModal.open({
              templateUrl: require('./generateResultModal.html'),
              controller: 'common.views.saleTransactions.generateResultModal as vm',
              backdrop: 'static',
              resolve: {
                fileType() {
                  return data.fileType;
                },
                errors() {
                  return data.errors;
                },
              },
            });
          } else {
            App.downloadTempFile(data);
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function generateCitibankTxt(request) {
      vm.loading += 1;
      saleTransactionSvc
        .generateCitiPaymentInstructionTxt({
          requestId: request.id,
        })
        .success((data) => {
          if (data.errors) {
            $uibModal.open({
              templateUrl: require('./generateResultModal.html'),
              controller: 'common.views.saleTransactions.generateResultModal as vm',
              backdrop: 'static',
              resolve: {
                fileType() {
                  return data.fileType;
                },
                errors() {
                  return data.errors;
                },
              },
            });
          } else {
            if (data.warnings) {
              $uibModal.open({
                templateUrl: require('./generateResultModal.html'),
                controller: 'common.views.saleTransactions.generateResultModal as vm',
                backdrop: 'static',
                resolve: {
                  fileType() {
                    return data.fileType;
                  },
                  errors() {
                    return data.warnings;
                  },
                },
              });
            }
            App.downloadTempFile(data);
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function generateCimbCsv(request) {
      vm.loading += 1;
      saleTransactionSvc
        .generateCimbPaymentInstructionCsv({
          requestId: request.id
        })
        .success((data) => {
          App.downloadTempFile(data)
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getEligibleCountries() {
      vm.loading += 1;
      commonLookupSvc
        .getEligibleCountries()
        .success((data) => {
          vm.countries = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
