((function () {
  angular
    .module('app')
    .filter('nationalId', nationalId)
    // For backward compatibility only. Filter name don't need "Filter" suffix.
    // AngularJS automatically appends "Filter" to name for DI.
    .filter('nationalIdFilter', nationalId);

  nationalId.$inject = [];

  function nationalId() {
    return nationalIdFilter;

    function nationalIdFilter(value) {
      return value ? value.replace(/^(\d{6})-?(\d{2})-?(\d{4})$/, '$1-$2-$3') : value;
    };
  }
})());
