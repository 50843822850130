(() => {
  angular
    .module('app')
    .controller('common.view.modals.jsonPrettifierModal', JsonPrettifierModalController);

  JsonPrettifierModalController.$inject = ['$uibModalInstance', 'title', 'jsonString'];

  function JsonPrettifierModalController($uibModalInstance, title, jsonString) {
    const vm = this;

    vm.title = title;
    vm.jsonObject = JSON.parse(jsonString);

    vm.back = back;

    init();

    function init() {}

    function back() {
      $uibModalInstance.dismiss();
    }
  }
})();
