(function () {
    var controllerId = 'corporate.views.claimPolicies.createEditClaimPolicy';
    var app = angular.module('app');
    app.controller(controllerId, ['$state', '$scope', '$stateParams', '$sce', 'abp.services.app.claimPolicy',
        function ($state, $scope, $stateParams, $sce, claimPolicySvc) {
            function init() {
                vm.loading++;
                claimPolicySvc.getClaimPolicyForEdit({
                    id: vm.claimPolicyId
                }).success(function (data) {
                    vm.claimPolicy = data.claimPolicy;
                    vm.benefitGroups = data.benefitGroups;

                    var currentPrefix = App.localize('Current');
                    _.each(vm.benefitGroups, function (bg) {
                        bg.displayName = (bg.claimPolicyName && bg.claimPolicyId !== vm.claimPolicyId)
                            ? (bg.name + ' | ' + currentPrefix + ': ' + bg.claimPolicyName)
                            : bg.name;
                    });
                }).finally(function () {
                    vm.loading--;
                });
            }

            var vm = this;
            vm.loading = 0;
            vm.loadingComparisons = 0;
            vm.saving = 0;
            vm.benefitGroups = [];
            vm.claimPolicyId = parseInt($stateParams.id) || 0;
            vm.isEdit = vm.claimPolicyId > 0;
            vm.hasComparison = false;
            vm.existingClaimPolicy = null;

            vm.summernoteOptions = {
                placeholder: App.localize("RichTextEditorPlaceholder"),
                disableDragAndDrop: true,
                height: 400,
                focus: true,
                toolbar: [
                    ['edit', ['undo', 'redo']],
                    ['headline', ['style']],
                    ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'strikethrough', 'clear']],
                    ['fontface', ['fontname']],
                    ['textsize', ['fontsize']],
                    ['fontclr', ['color']],
                    ['alignment', ['ul', 'ol', 'paragraph', 'lineheight']],
                    ['height', ['height']],
                    ['table', ['table']]
                ]
            };

            vm.toggleComparison = function () {
                vm.hasComparison = !vm.hasComparison;
                if (!vm.claimPolicies && !vm.loadingComparisons) {
                    vm.loadingComparisons++;
                    claimPolicySvc.getClaimPolicies().success(function (data) {
                        // Filter out current claim policy.

                        vm.claimPolicies = _.filter(data.items, function (item) {
                            return item.id !== vm.claimPolicyId;
                        });
                    }).finally(function () {
                        vm.loadingComparisons--;
                    });
                }
            };

            vm.selectedClaimPolicyId = null;
            vm.selectClaimPolicy = function () {
                var comparison = _.find(vm.claimPolicies, function (d) {
                    return d.id === vm.selectedClaimPolicyId;
                });

                vm.existingClaimPolicy = comparison;
                fetchContent(comparison.id);
            };

            vm.save = function () {
                var overwritesBg = _.some(vm.claimPolicy.benefitGroupIds, function (id) {
                    return _.some(vm.benefitGroups, function (bg) {
                        return id === bg.id && bg.claimPolicyId > 0 && bg.claimPolicyId !== vm.claimPolicyId;
                    });
                });

                if (overwritesBg) {
                    abp.message.confirm(App.localize('AssignClaimPolicyExistingBenefitGroupMessage'), App.localize('AreYouSure'), function (d) {
                        if (d) save();
                    });
                } else {
                    save();
                }
            };

            function save() {
                vm.saving++;
                claimPolicySvc.createOrUpdateClaimPolicy(vm.claimPolicy)
                    .success(function (data) {
                        abp.message.success(App.localize('SuccessfullySaved'));
                        $state.go('corporate.claimPolicies');
                    }).finally(function () {
                        vm.saving--;
                    });
            }

            function fetchContent(claimPolicyId) {
                vm.loadingComparisons++;
                claimPolicySvc.getClaimPolicyContent({
                    id: claimPolicyId
                }).success(function (data) {
                    vm.displayClaimPolicyContent = $sce.trustAsHtml(data);
                }).finally(function () {
                    vm.loadingComparisons--;
                });
            }

            init();
        }
    ]);
})();
