(() => {
  angular
    .module('app')
    .component('postHospitalizationGuaranteeLettersProcessingAdmissionRecordComponent', {
      templateUrl: require('./admissionRecord.component.html'),
      controller: AdmissionRecordComponentController,
      controllerAs: 'vm',
      bindings: {
        letterNumber: '<',
      },
    });

  AdmissionRecordComponentController.$inject = [
    'abp.services.app.postHospitalizationGuaranteeLetter',
    'Hms.ServiceRequests.ServiceRequestStatus',
    'Hms.ServiceRequests.ServiceRequestType',
  ];

  function AdmissionRecordComponentController(
    postHospitalizationGuaranteeLetterSvc,
    enumServiceRequestStatus,
    enumServiceRequestType
  ) {
    const vm = this;
    vm.enums = {};
    vm.enums.serviceRequestStatus = enumServiceRequestStatus;
    vm.enums.serviceRequestType = enumServiceRequestType;

    vm.$onInit = init;

    function init() {
      postHospitalizationGuaranteeLetterSvc
        .getAdmissionRecordByLetterNumber({
          id: vm.letterNumber,
        })
        .success((data) => {
          vm.admissionRecord = data;
        });
    }
  }
})();
