(function () {
    angular.module('app').component('hostDoraemonCommonPatientAdmissionTimelineComponent', {
        templateUrl: require('/App/host/views/doraemon/common/patientAdmissionTimeline/patientAdmissionTimeline.component.html'),
        controller: ['abp.services.app.doraemon', 'Hms.ServiceRequests.ServiceRequestStatus', 'Hms.ServiceRequests.ServiceRequestType', controller],
        controllerAs: 'vm',
        bindings: {
            guaranteeLetterNumber: '<'
        }
    });

    function controller(doraemonSvc, enumServiceRequestStatus, enumServiceRequestType) {
        function init() {
            getTimeline();
        }

        var vm = this;
        vm.isHospital = App.isHospital();
        vm.checkInPatient = function () { abp.message.confirm(App.localize('ConfirmPatientAdmission'), App.localize('AreYouSure')) };
        vm.loading = 0
        vm.getServiceRequestName = getServiceRequestName;

        vm.enums = {};
        vm.enums.serviceRequestStatus = enumServiceRequestStatus;
        vm.enums.serviceRequestType = enumServiceRequestType;

        function getServiceRequestName(value) {
            return App.localize(value);
        }

        function getTimeline() {
            vm.loading++;
            doraemonSvc.getPatientTimeline({
                id: vm.guaranteeLetterNumber
            }).success(function (data) {
                vm.timeline = data
            }).finally(function (e) {
                vm.loading--;
            });
        }

        init();
    }
})();
