(() => {
  angular.module('app').filter('doctorMmcRegistrationNumber', doctorMmcRegistrationNumber);

  doctorMmcRegistrationNumber.$inject = [];

  function doctorMmcRegistrationNumber() {
    return doctorMmcRegistrationNumberFilter;

    function doctorMmcRegistrationNumberFilter(doctor, countryCode) {
      if (doctor && doctor.mmcRegistrationNumber) {
        switch (countryCode) {
          case 'MY':
            return `(${App.localize('MMCRegistrationNumberShort')}: ${
              doctor.mmcRegistrationNumber
            })`;
          case 'SG':
            return `(${App.localize('MedicalCouncilRegistrationNumberShort')}: ${
              doctor.mmcRegistrationNumber
            })`;
          default:
            return `(${App.localize('DoctorRegistrationNumberShort')}: ${
              doctor.mmcRegistrationNumber
            })`;
        }
      }
      return '';
    }
  }
})();
