(function () {
  const componentId = 'hostCorporatesMasterPoliciesMasterPolicyBasicsComponent';
  const app = angular.module('app');

  app.component(componentId, {
    templateUrl: require('./basics.component.html'),
    controller: ['$scope', 'Hms.MasterPolicies.MasterPolicyCategory', 'moment', controller],
    controllerAs: 'vm',
    bindings: {
      data: '<',
      policyCategories: '<',
      effectiveDateRangeModel: '=',
      newAttachments: '=',
      isCreate: '=',
      isRenewal: '=',
      isEdit: '=',
    },
    require: {
      container: '^hostCorporatesMasterPoliciesMasterPolicyContainerComponent',
    },
  });

  function controller($scope, enumMasterPolicyCategory, moment) {
    const vm = this;

    function init() {
      setInsurerTeamOptions(vm.data.masterPolicy.insurerId);
    }

    vm.loading = 0;
    vm.resetExistingMasterPolicyLinkage = resetExistingMasterPolicyLinkage;
    vm.setInsurerTeamOptions = setInsurerTeamOptions;
    vm.setInsurerAndSelectableInsurerTeams = setInsurerAndSelectableInsurerTeams;

    vm.masterPolicy = vm.data.masterPolicy;

    vm.enums = {};
    vm.enums.masterPolicyCategory = enumMasterPolicyCategory;

    // #region Validation

    vm.$onInit = function () {
      vm.container.addWidget(this);
    };

    vm.isDirty = function () {
      return (
        $scope.corporateMasterPolicyCreateEditBasicsForm &&
        $scope.corporateMasterPolicyCreateEditBasicsForm.$dirty
      );
    };

    vm.setPristine = function () {
      $scope.corporateMasterPolicyCreateEditBasicsForm.$setPristine();
    };

    // Force validation on this form.
    // Return true if valid, otherwise, false.

    vm.validateForm = function () {
      const form = $scope.corporateMasterPolicyCreateEditBasicsForm;
      App.touchFormErrors(form);
      return form.$valid;
    };

    // #endregion Validation

    vm.uploadConfig = {
      objectType: 'PolicyJacketAttachment',
    };

    vm.effectiveDateRangeOptions = App.createDateRangePickerOptions();

    // Overwrite default ranges set for this year, month and week.
    vm.effectiveDateRangeOptions.ranges[App.localize('ThisYear')] = [
      moment().startOf('year'),
      moment().endOf('year'),
    ];
    vm.effectiveDateRangeOptions.ranges[App.localize('ThisQuarter')] = [
      moment().startOf('quarter'),
      moment().endOf('quarter'),
    ];
    vm.effectiveDateRangeOptions.ranges[App.localize('ThisMonth')] = [
      moment().startOf('month'),
      moment().endOf('month'),
    ];
    vm.effectiveDateRangeOptions.ranges[App.localize('ThisWeek')] = [
      moment().startOf('week'),
      moment().endOf('week'),
    ];

    // Overwrite default max date.

    vm.effectiveDateRangeOptions.max = moment([9999, 0, 1]);
    vm.effectiveDateRangeOptions.maxDate = moment([9999, 0, 1]);

    function resetExistingMasterPolicyLinkage() {
      vm.masterPolicy.relatedMasterPolicyId = null;
      vm.masterPolicy.insurerId = null;
      vm.masterPolicy.insurerTeam = null;
      vm.insurerTeamOptions = null;

      // Update isRenewal flag based on selected category.
      if (vm.masterPolicy.category === 1) {
        vm.isRenewal = true;
      } else {
        vm.isRenewal = false;
      }
    }

    function setInsurerTeamOptions(insurerId) {
      vm.insurerTeamOptions = vm.data.insurerTeams.filter((obj) => obj.insurerId === insurerId);
    }

    function setInsurerAndSelectableInsurerTeams() {
      // If current master policy category is renewal,
      // Set insurer selection to related master policy's insurer id.

      vm.masterPolicy.insurerId = _.find(
        vm.data.linkableMasterPolicies,
        (obj) => obj.masterPolicyId === vm.masterPolicy.relatedMasterPolicyId
      ).insurerId;

      // Reset insurer teams selection
      // and limit insurer teams selection to current insurer selection.

      vm.masterPolicy.insurerTeamId = null;
      vm.insurerTeamOptions = vm.data.insurerTeams.filter(
        (obj) => obj.insurerId === vm.masterPolicy.insurerId
      );
    }

    init();
  }
})();
