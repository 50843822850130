(function () {
    var componentId = 'hostCorporatesMasterPoliciesMasterPolicyEligibilityComponent';
    var app = angular.module('app');

    app.component(componentId, {
        templateUrl: require('/App/host/views/corporates/masterPolicies/masterPolicy/eligibility/eligibility.component.html'),
        controller: ['$scope', controller],
        controllerAs: 'vm',
        bindings: {
            data: '<',
            isCreate: '<',
            isRenewal: '<',
            isEdit: '<'
        },
        require: {
            container: '^hostCorporatesMasterPoliciesMasterPolicyContainerComponent'
        }
    });

    function controller($scope) {
        function init() {
            angular.element('#defaultSpouse').trigger('click');
        }

        var vm = this;
        vm.loading = 0;
        vm.openTab = openTab;
        vm.masterPolicy = vm.data.masterPolicy;

        // #region Validation

        vm.$onInit = function () {
            vm.container.addWidget(this);
        };

        vm.isDirty = function () {
            return $scope.corporateMasterPolicyCreateEditEligibilityForm && $scope.corporateMasterPolicyCreateEditEligibilityForm.$dirty;
        };

        vm.setPristine = function () {
            $scope.corporateMasterPolicyCreateEditEligibilityForm.$setPristine();
        };

        // Force validation on this form.
        // Return true if valid, otherwise, false.

        vm.validateForm = function () {
            var form = $scope.corporateMasterPolicyCreateEditEligibilityForm;
            App.touchFormErrors(form);
            return form.$valid;
        };

        // #endregion Validation

        function openTab(evt, tabname) {
            // Get all elements with class="tabcontent" and hide them

            $('.tabcontent').css('display', 'none');

            // Get all elements with class="tablinks" and remove the class "active"

            $('.tablinks').removeClass('active');

            // Show the current tab, and add an "active" class to the link that opened the tab

            $('#' + tabname).css('display', 'block');
            evt.currentTarget.className += ' active';
        }

        angular.element(document).ready(function () {
            init();
        });
    }
})();
