(function () {
  var componentId = 'revenueSubscriptionsDetailOverview';
  var module = angular.module('revenue');

  function controller($uibModal, subscriptionSvc) {
    function editName() {
      if (!vm.subscription || vm.loading) return;

      swal({
        title: App.localize('Edit'),
        type: 'input',
        showCancelButton: true,
        closeOnConfirm: false,
        confirmButtonColor: '#1ab394',
        inputPlaceholder: App.localize('Name'),
        inputValue: vm.subscription.name
      }, function (inputValue) {
        if (inputValue === false) {
          return false;
        }
        if ($.trim(inputValue || '') === '') {
          swal.showInputError(App.localize('InvalidName'));
          return false;
        }

        swal.close();

        if (vm.loading) return;

        vm.loading = true;
        subscriptionSvc.updateSubscriptionName({
          id: vm.subscription.id,
          name: inputValue
        }).success(function () {
          abp.notify.success(App.localize('SuccessfullyUpdated'));
          vm.subscription.name = inputValue;
        }).finally(function () {
          vm.loading = false;
          if (vm.onSubscriptionUpdated) vm.onSubscriptionUpdated();
        });
      });
    }

    function editScope() {
      $uibModal.open({
        templateUrl: require('/App/modules/revenue/views/subscriptions/editScope/editScopeModal.html'),
        controller: "revenue.views.subscriptions.editScope.editScopeModal as vm",
        backdrop: "static",
        resolve: {
          subscription: function () {
            return vm.subscription;
          }
        }
      }).result.then(function () {
        if (vm.onSubscriptionUpdated) vm.onSubscriptionUpdated();
      });
    }

    function editBilling() {
      $uibModal.open({
        templateUrl: require('/App/modules/revenue/views/subscriptions/editBilling/editBillingModal.html'),
        controller: "revenue.views.subscriptions.editBilling.editBillingModal as vm",
        backdrop: "static",
        resolve: {
          subscription: function () {
            return vm.subscription;
          }
        }
      }).result.then(function () {
        if (vm.onSubscriptionUpdated) vm.onSubscriptionUpdated();
      });
    }

    function editPeriod() {
      $uibModal.open({
        templateUrl: require('/App/modules/revenue/views/subscriptions/editPeriod/editPeriodModal.html'),
        controller: "revenue.views.subscriptions.editPeriod.editPeriodModal as vm",
        backdrop: "static",
        resolve: {
          subscription: function () {
            return vm.subscription;
          }
        }
      }).result.then(function () {
        if (vm.onSubscriptionUpdated) vm.onSubscriptionUpdated();
      });
    }

    function editCharging() {
      $uibModal.open({
        templateUrl: require('/App/modules/revenue/views/subscriptions/editCharging/editChargingModal.html'),
        controller: "revenue.views.subscriptions.editCharging.editChargingModal as vm",
        backdrop: "static",
        resolve: {
          subscription: function () {
            return vm.subscription;
          }
        }
      }).result.then(function () {
        if (vm.onSubscriptionUpdated) vm.onSubscriptionUpdated();
      });
    }

    function assignAgent() {
      $uibModal.open({
        templateUrl: require('/App/modules/revenue/views/subscriptions/assignSubscriptionAgent/assignSubscriptionAgentModal.html'),
        controller: "revenue.views.subscriptions.assignSubscriptionAgent.assignSubscriptionAgentModal as vm",
        backdrop: "static",
        resolve: {
          subscription: function () {
            return vm.subscription;
          }
        }
      }).result.then(function () {
        if (vm.onSubscriptionUpdated) vm.onSubscriptionUpdated();
      });
    }

    const vm = this;
    vm.loading = false;
    vm.subsidiaryNames = '';
    vm.assignments = [];

    vm.permissions = {
      assignAgent: abp.auth.isGranted('Hms.Revenue.Corporate.AssignAgent'),
      manageSubscriptions: abp.auth.isGranted('Hms.Revenue.Corporate.ManageSubscriptions')
    };

    vm.$onChanges = function (changesObj) {
      if (changesObj.subscription) {
        const subscription = changesObj.subscription.currentValue;
        if (subscription) {
          vm.subsidiaryNames = subscription.scope === 0 ?
            App.localize('All') :
            _.map(subscription.subsidiaries, 'subsidiaryName').join(', ');

          vm.assignments = _.map(subscription.agents, function (agent) {
            let mode = 'Assignment mode ' + agent.mode;
            switch (agent.mode) {
              case 0:
                mode = App.localize('SalesAsExecutive');
                break;
              case 1:
                mode = App.localize('SalesAsManager');
                break;
              case 2:
                mode = App.localize('SalesOverriding');
                break;
              case 3:
                mode = App.localize('CustomerSuccessAsExecutive');
                break;
              case 4:
                mode = App.localize('CustomerSuccessAsManager');
                break;
              case 5:
                mode = App.localize('CustomerSuccessOverriding');
                break;
              case 6:
                mode = App.localize('Introducer');
                break;
            }
            return {
              mode: mode,
              agentName: agent.agentName
            };
          });
        } else {
          vm.subsidiaryNames = '';
          vm.assignments = [];
        }
      }
    };

    vm.editName = editName;
    vm.editScope = editScope;
    vm.editPeriod = editPeriod;
    vm.editCharging = editCharging;
    vm.editBilling = editBilling;
    vm.assignAgent = assignAgent;
  }

  module.component(componentId, {
    require: {
      container: '^revenueSubscriptionsDetailContainer'
    },
    bindings: {
      subscription: '<',
      onSubscriptionUpdated: '&'
    },
    templateUrl: require('/App/modules/revenue/views/subscriptions/detail/overview/widget.html'),
    controller: ['$uibModal', 'abp.services.revenue.subscription', controller],
    controllerAs: 'vm'
  });
})();
