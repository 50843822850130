(() => {
  angular.module('app').component('commonComponentsSupportingDocumentsComponent', {
    templateUrl: require('./supportingDocuments.component.html'),
    controller: SupportingDocumentsController,
    controllerAs: 'vm',
    bindings: {
      documents: '<',
      initialDocuments: '<',
      isEdit: '<',
      hasState: '<',
      newDocuments: '=',

      // Indicates whether this component is viewed via viewing inpatient patient ticket.
      isFromTicketView: '<',
    },
  });

  SupportingDocumentsController.$inject = [];

  function SupportingDocumentsController() {
    const vm = this;

    vm.uploadConfig = {
      objectType: 'TicketAttachment',
    };
  }
})();
