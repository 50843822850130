import swal from 'sweetalert';

(() => {
  angular
    .module('app')
    .controller('common.views.saleTransactions.invoiceDetail', InvoiceDetailController);

  InvoiceDetailController.$inject = [
    '$state',
    '$stateParams',
    '$uibModal',
    'abp.services.app.saleTransaction',
  ];

  function InvoiceDetailController($state, $stateParams, $uibModal, saleTransactionSvc) {
    const vm = this;

    vm.loading = 0;

    vm.transactionNumber = $stateParams.transactionNumber;
    vm.invoice = null;
    vm.invoiceFrom = null;
    vm.invoiceTo = null;
    vm.message = null;
    vm.payments = [];
    vm.latestPayableAmount = null;
    vm.isInpatient = App.isInpatient();
    vm.invoiceSaleTransactionItems = [];
    vm.currentIndex = 0;
    vm.rowNumber = 0;
    vm.serviceTypeNameDict = {};
    vm.hasTaxWorkflow = false;
    vm.commentsAndHistory = [];
    vm.paymentsReceived = [];
    vm.creditsApplied = [];
    vm.creditsAvailable = 0;

    vm.stopInvoiceGeneration = abp.setting.getBoolean(
      'Hms.Feature.StopPortalInvoiceGeneration'
    );

    vm.hasLicensingFeeCharging = abp.setting.getBoolean('Hms.Feature.LicenseFeeCharging');

    vm.hasFinanceService = abp.setting.getBoolean(
      'Hms.Feature.FinanceService'
    );

    vm.getPaymentLabel = getPaymentLabel;
    vm.send = send;
    vm.getIndex = getIndex;
    vm.recordPayment = recordPayment;
    vm.applyCredit = applyCredit;
    vm.deletePayment = deletePayment;
    vm.removeCredit = removeCredit;

    init();

    function init() {
      vm.loading += 1;
      saleTransactionSvc
        .getInvoice({
          id: vm.transactionNumber,
        })
        .success((data) => {
          const d = data;
          if (d.isOrganizationInvoiceV2 && d.countryCode !== 'SG') {
            $state.go('invoiceDetailV2', {
              transactionNumber: d.invoice.transactionNumber,
            });
          } else if (d.countryCode === 'SG' && !d.isOrganizationInvoiceV2) {
            $state.go('singaporePanelInvoice', {
              transactionNumber: d.invoice.transactionNumber,
            });
          } else if (d.countryCode === 'SG') {
            $state.go('singaporeInvoice', {
              transactionNumber: d.invoice.transactionNumber,
            });
          }

          if (!d.invoice.departments) {
            d.invoice.departments = _.map(d.invoice.departmentsWithCategory, (dc) => {
              const items = _.flatMap(
                _.filter(dc.categories, (c) => c.saleTransactionItems),
                (n) => n.saleTransactionItems
              );
              return {
                id: dc.id,
                name: dc.name,
                saleTransactionItems: items,
                amount: _.sumBy(items, 'amount'),
              };
            });
          }

          if (d.invoice.category === 10) {
            _.sortBy(d.invoice.departments, ['id', 'serviceType']);
            let previousId = 0;
            _.forEach(d.invoice.departments, (department) => {
              const de = department;
              de.show = false;
              if (de.id !== previousId) {
                de.show = true;
                previousId = de.id;
              }
            });
          }
          vm.currencyCode = d.currencyCode;
          vm.countryCode = d.countryCode;
          vm.invoice = d.invoice;
          vm.invoice.isAgentInvoice = _.includes([4, 5, 7], vm.invoice.category);
          vm.invoiceFrom = d.invoiceFrom;
          vm.invoiceTo = d.invoiceTo;
          vm.payments = d.payments;
          vm.message = d.message;
          vm.categoryNames = d.categoryNames;
          vm.taxCodes = d.taxCodes;
          vm.isPharmacy = d.invoice.isPharmacy;

          vm.showDepartments =
            (d.invoice.category === 1 || d.invoice.category === 2) && d.invoice.departments.length;
          vm.departmentExpenseLabel = App.localize(
            d.invoice.category === 1 ? 'ExpensesByLocation' : 'ExpensesByDepartment'
          );
          vm.departmentLabel = App.localize(d.invoice.category === 1 ? 'Location' : 'Department');
          vm.noDepartmentLabel = App.localize(
            d.invoice.category === 1 ? 'Unknown' : 'NoDepartment'
          );
          vm.quotaFulfillmentLabel = App.localize(
            d.invoice.category === 2 ? 'SubscriptionPlanQuotaFulfillment' : 'Unknown'
          );

          // Past activities label
          vm.pastActivitiesLabel = App.localize('PastActivities');

          // Set current payable amount.
          vm.latestPayableAmount = vm.invoice.amount - vm.invoice.knockedOffAmount;

          vm.serviceInvoiceSummary = d.serviceInvoiceSummary;
          vm.uniqueServiceTypes = vm.serviceInvoiceSummary
            .map((x) => x.serviceType)
            .filter((value, index, self) => self.indexOf(value) === index);
          vm.hasTaxWorkflow = d.hasTaxWorkflow;

          _.forEach(vm.uniqueServiceTypes, (serviceType) => {
            const i = _.find(vm.serviceInvoiceSummary, (item) => serviceType === item.serviceType);
            if (i) vm.serviceTypeNameDict[serviceType] = i.serviceTypeName;
          });

          _.forEach(vm.invoice.departments, (department) => {
            _.forEach(department.saleTransactionItems, (item) => {
              vm.invoiceSaleTransactionItems.push(item);
            });
          });
        })
        .finally(() => {
          vm.loading -= 1;
        });

      if (vm.hasFinanceService) {
        getPaymentsReceived();
        getCreditsAvailable();
        getCreditsApplied();
      }
    }

    function getPaymentLabel() {
      return App.localize('XPaymentsMade', vm.payments.length);
    }

    function send() {
      saleTransactionSvc
        .getFinanceEmails({
          tenantId: vm.invoice.tenantId,
          subsidiaryId: vm.invoice.subsidiaryId,
        })
        .success((data) => {
          swal(
            {
              title: App.localize('SendEmailForX', vm.invoice.transactionNumber),
              text: App.localize('EmailAddresses'),
              type: 'input',
              showCancelButton: true,
              closeOnConfirm: false,
              confirmButtonColor: '#1ab394',
              inputPlaceholder: App.localize('EmailAddressesNarrative'),
              inputValue: data.emailAddresses,
              showLoaderOnConfirm: true,
            },
            (inputValue) => {
              if (inputValue === false) return false;
              if (inputValue === '') {
                swal.showInputError(App.localize('InvalidEmailAddress'));
                return false;
              }
              saleTransactionSvc
                .sendEmail({
                  transactionNumber: vm.invoice.transactionNumber,
                  emailAddresses: inputValue,
                })
                .success(() => {
                  abp.notify.info(App.localize('EmailSent'));
                  swal.close();
                });
              return false;
            }
          );
        });
    }

    function getIndex(index) {
      vm.rowNumber += 1;
      if (vm.currentIndex !== index) {
        vm.rowNumber = 0;
        vm.currentIndex = index;
      }

      return vm.rowNumber;
    }

    function recordPayment() {
      // TODO: To be updated when API is ready.

      console.log('%cRecord payment', 'color: limegreen;', 'Method call.');
    }

    function applyCredit() {
      // TODO: To be updated when API is ready.

      $uibModal.open({
        templateUrl: require('./applyCredit/applyCredit.modal.html'),
        controller: 'common.views.saleTransactions.applyCreditModal as vm',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          invoiceNo() {
            return vm.invoice.transactionNumber;
          },
        },
      });
    }

    function getPaymentsReceived() {
      // TODO: To be updated when API is ready.
      // Dummy data.

      for (let i = 0; i < 5; i += 1) {
        vm.paymentsReceived.push({
          date: new Date(),
          paymentNo: 'PY-3910-NNNDMY-R',
          referenceNo: '',
          paymentMode: 'Cash',
          amount: Math.random() * 100,
          currencyCode: 'MYR',
        });
      }
    }

    function getCreditsAvailable() {
      // TODO: To be updated when API is ready.
      // Dummy data.

      vm.creditsAvailable = 200.2;
    }

    function getCreditsApplied() {
      // TODO: To be updated when API is ready.
      // Dummy data.

      for (let i = 0; i < 3; i += 1) {
        vm.creditsApplied.push({
          date: new Date(),
          paymentNo: 'CR-4420-NNDWMY-R',
          referenceNo: 'CRE-069',
          paymentMode: 'App',
          amount: Math.random() * 100,
          currencyCode: 'MYR',
        });
      }
    }

    function deletePayment() {
      // TODO: To be updated when API is ready.

      console.log('%cDelete payment', 'color: red;', 'Method call.');
    }

    function removeCredit() {
      // TODO: To be updated when API is ready.

      console.log('%cRemove credit', 'color: red;', 'Method call.');
    }
  }
})();
