(() => {
  angular.module('app').component('clinicPanelDashboardLatestSubmissionsComponent', {
    templateUrl: require('./latestSubmissions.component.html'),
    controller: LatestSubmissionsController,
    controllerAs: 'vm',
    bindings: {
      panelLocationId: '<',
      panelLocationTimeZone: '<',
      widgetLength: '<',
      isGpPanel: '<',
    },
  });

  LatestSubmissionsController.$inject = ['abp.services.app.panelDashboard'];

  function LatestSubmissionsController(panelDashboardSvc) {
    const vm = this;

    vm.loading = 0;
    vm.latestSubmissions = [];
    vm.hasGpDashboardEnhancement = abp.setting.getBoolean('Hms.Feature.GPDashboardEnhancement');
    vm.hasPanelDashboardEnhancement = abp.setting.getBoolean(
      'Hms.Feature.PanelDashboardEnhancement'
    );
    vm.allClearedNarrative = App.localize('SubmissionsAllClearedNarrative');
    vm.permissions = {
      patientTickets: abp.auth.isGranted('PatientTickets'),
    };
    vm.requestParams = {
      panelLocationId: vm.panelLocationId,
      maxResultCount: 5,
      skipCount: 0,
    };
    vm.$onChanges = onChanges;
    vm.loadMore = loadMore;
    vm.getDateTimeString = getDateTimeString;

    function onChanges(changes) {
      if (changes.panelLocationId && changes.panelLocationId.currentValue) {
        vm.requestParams.panelLocationId = vm.panelLocationId;
        vm.latestSubmissions = [];
        getLatestSubmissions();
      }
    }

    function getDateTimeString(date) {
      return App.getDateTimeString(date, vm.panelLocationTimeZone);
    }

    function getLatestSubmissions() {
      if (vm.requestParams && vm.requestParams.panelLocationId) {
        vm.loading += 1;
        panelDashboardSvc
          .getLatestSubmissions(vm.requestParams)
          .success((data) => {
            vm.totalCount = data.totalCount;
            vm.latestSubmissions = vm.latestSubmissions.concat(data.items);
            vm.hasMoreLatestSubmission = vm.latestSubmissions.length < vm.totalCount;

            _.each(vm.latestSubmissions, (l) => {
              _.assign(l, {
                hasCollapseSubmission: true,
              });
            });
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    }

    function loadMore() {
      if (!vm.loading && vm.hasMoreLatestSubmission) {
        vm.requestParams.skipCount += 5;
        getLatestSubmissions();
      }
    }
  }
})();
