(function () {
    var controllerId = 'host.views.clinics.ratesModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'abp.services.app.clinic', 'clinic',
        function ($scope, $uibModalInstance, clinicsSvc, clinic) {

            function init() {
                vm.loading = true;
                clinicsSvc.getClinicTreatmentRatesForEdit({
                    id: vm.clinic.id
                }).success(function (data) {
                    vm.rateEditData = data;
                }).finally(function () {
                    vm.loading = false;
                });
            }

            var vm = this;
            vm.loading = true;
            vm.saving = false;
            vm.resettingRates = false;
            vm.clinic = clinic;
            vm.rateEditData = null;

            vm.resetRates = function () {
                vm.resettingRates = true;
                clinicsSvc.resetClinicSpecificRates({
                    id: vm.clinic.id
                }).success(function () {
                    abp.notify.info(App.localize("ResetSuccessfully"));
                    init();
                }).finally(function () {
                    vm.resettingRates = false
                });
            };

            vm.save = function () {

                vm.saving = true;
                clinicsSvc.updateClinicTreatmentRates({
                    id: vm.clinic.id,
                    treatmentRates: vm.rateEditData.treatmentRates
                }).success(function () {
                    abp.notify.info(App.localize("SuccessfullySaved"));
                    $uibModalInstance.close();
                }).finally(function () {
                    vm.saving = false
                });
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss()
            };

            init();
        }
    ]);
})();