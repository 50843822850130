(function () {
    angular.module('app').controller('host.views.doraemon.cancelGl.modal', [
        '$scope', '$uibModalInstance',
        function ($scope, $uibModalInstance) {
            var vm = this;

            vm.cancel = cancel;
            vm.submit = submit;
            vm.remarks = '';

            function cancel() {
                $uibModalInstance.dismiss();
            }

            function submit() {
                if (App.isFormValid($scope.cancelGlModalForm)) {
                    $uibModalInstance.close(_.trim(vm.remarks));
                };
            }
        }
    ]);
})();
