(function () {
    'use strict';

    function controller($modal, benefitGroupSvc, $sce) {
        var vm = this;
        function init() {
            loadBasicsData();
            checkOptionalExclusions();

        }
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.showViewExclusionProvisions = showViewExclusionProvisionsModal;
        vm.loading = false;
        vm.hasHospitalizationWaitingPeriodEnhancement = abp.setting.getBoolean('Hms.Feature.HospitalisationWaitingPeriodEnhancement');


        function loadBasicsData() {
            vm.loading = true;
            vm.loading = false;
        }

        vm.displaySafeHtml = function (html) {
            return $sce.trustAsHtml(html);
        };

        vm.hasOptionalExclusions = true;

        function checkOptionalExclusions() {
            if (vm.basics.serviceType === 7) {
                var j = 0;
                for (var i = 0; i < vm.basics.hospitalizationSetting.coveredOptionalExclusions.length; i++) {
                    if (!vm.basics.hospitalizationSetting.coveredOptionalExclusions[i].valid) {
                        j++;
                    }
                }
                if (j === vm.basics.hospitalizationSetting.coveredOptionalExclusions.length) {
                    vm.hasOptionalExclusions = false;
                }
            }
        }

        function showViewExclusionProvisionsModal() {
            $modal.open({
                templateUrl: require('/App/corporate/views/benefitGroups/inpatient/benefitDetail/viewExclusionProvisions/viewExclusionProvisions.modal.html'),
                controller: 'corporate.views.benefitGroups.inpatient.benefitDetail.viewExclusionProvisions.modal as vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    inpatientSettings: vm.basics
                }
            });
        }

        init();
    }

 angular.module('app').component('corporateBenefitGroupsInpatientBenefitDetailBasicsComponent', {
        templateUrl: require('/App/corporate/views/benefitGroups/inpatient/benefitDetail/basics/basics.component.html'),
     controller: ['$uibModal', 'abp.services.app.benefitGroup', '$sce', controller],
        controllerAs: 'vm',
        bindings: {
            basics: '<'
        }
    });
})();
