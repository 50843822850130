(function () {
    'use strict';

    angular.module('app').component('hostCorporatesSupportPlansCreateEditScheduleOfBenefitInpatientMedicalComponent', {
        templateUrl: require('/App/host/views/corporates/supportPlans/createEdit/scheduleOfBenefit/inpatientMedical/inpatientMedical.component.html'),
        controller: ['$scope', 'Hms.BenefitGroups.CoveredCycle', controller],
        controllerAs: 'vm',
        bindings: {
            scheduleOfBenefit: '<',
            currencyCode: '<',
            countryCode: '<'
        }
    });

    function controller($scope, enumCoveredCycle) {
        var vm = this;
        vm.hasScheduleOfBenefitEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');
        vm.hasTxtFileSupport = abp.setting.getBoolean('Hms.Feature.TxtFileSupport');

        // Amount Types
        vm.icuAmountType = null;
        vm.childGuardianBenefitAmountType = null;
        vm.hospitalSuppliesAndServicesAmountType = null;
        vm.ambulanceFeeAmountType = null;
        vm.medicalReportAmountType = null;

        vm.minRoomAndBoard = null;
        vm.minIcuRoomAndBoard = null;
        vm.maxIcuRoomAndBoard = null;
        vm.minChildGuardian = null;
        vm.minHospitalSuppliesAndServices = null;
        vm.minAmbulanceFeeAmount = null;
        vm.minMedicalReportFeeAmount = null;

        vm.enums = {};
        vm.enums.coveredCycle = enumCoveredCycle;

        vm.setMaxIcuRoomAndBoard = function () {
            vm.maxIcuRoomAndBoard = vm.scheduleOfBenefit.accomodationDaysCombined ?
                vm.scheduleOfBenefit.roomAndBoard.accomodationDays : null;
        }

        // AmountType toggle
        vm.toggleAmountType = function (SOBItem) {
            switch (SOBItem) {
                case 'roomAndBoard':
                    vm.minRoomAndBoard = vm.roomAndBoardAmountType === 1 ? 1 : 0;
                    switch (vm.roomAndBoardAmountType) {
                        case 0: /*ischarged*/
                            vm.scheduleOfBenefit.roomAndBoard.isAsCharged = true;
                            vm.scheduleOfBenefit.roomAndBoard.isCovered = true;
                            break;
                        case 1: /*limited*/
                            vm.scheduleOfBenefit.roomAndBoard.isAsCharged = false;
                            vm.scheduleOfBenefit.roomAndBoard.isCovered = true;
                            break;
                        case 2: /*uncovered*/
                            vm.scheduleOfBenefit.roomAndBoard.isAsCharged = false;
                            vm.scheduleOfBenefit.roomAndBoard.isCovered = false;
                            vm.scheduleOfBenefit.accomodationDaysCombined = false;
                            break;
                    }

                    // Store the edited and valid accomodation days temporarily to save the value if the amount type is set to uncovered but then reverted.

                    if (vm.scheduleOfBenefit.roomAndBoard.accomodationDays !== null) {
                        vm.tempAccomodationDays = vm.scheduleOfBenefit.roomAndBoard.accomodationDays;
                    }

                    // Resets the amount if the amount type is not limited.

                    if (vm.scheduleOfBenefit.roomAndBoard.isAsCharged === vm.scheduleOfBenefit.roomAndBoard.isCovered) {
                        vm.tempRoomAndBoardAmount = vm.scheduleOfBenefit.roomAndBoard.amount;
                        vm.scheduleOfBenefit.roomAndBoard.amount = null;
                    } else {
                        vm.scheduleOfBenefit.roomAndBoard.amount = vm.tempRoomAndBoardAmount;
                    }

                    // Resets the accomodation days displayed if the amount type is not covered.

                    if (!vm.scheduleOfBenefit.roomAndBoard.isAsCharged && !vm.scheduleOfBenefit.roomAndBoard.isCovered) {
                        vm.scheduleOfBenefit.roomAndBoard.accomodationDays = null;
                    } else {
                        vm.scheduleOfBenefit.roomAndBoard.accomodationDays = vm.tempAccomodationDays;
                    }

                    break;
                case 'icu':
                    vm.minIcuRoomAndBoard = vm.icuAmountType === 1 ? 1 : 0;
                    switch (vm.icuAmountType) {
                        case 0: /*ischarged*/
                            vm.scheduleOfBenefit.icuRoomAndBoard.isAsCharged = true;
                            vm.scheduleOfBenefit.icuRoomAndBoard.isCovered = true;
                            break;
                        case 1: /*limited*/
                            vm.scheduleOfBenefit.icuRoomAndBoard.isAsCharged = false;
                            vm.scheduleOfBenefit.icuRoomAndBoard.isCovered = true;
                            break;
                        case 2: /*uncovered*/
                            vm.scheduleOfBenefit.icuRoomAndBoard.isAsCharged = false;
                            vm.scheduleOfBenefit.icuRoomAndBoard.isCovered = false;
                            break;
                    }

                    // Store the edited and valid accomodation days temporarily to save the value if the amount type is set to uncovered but then reverted.

                    if (vm.scheduleOfBenefit.icuRoomAndBoard.accomodationDays !== null) {
                        vm.tempAccomodationDays = vm.scheduleOfBenefit.icuRoomAndBoard.accomodationDays;
                    }

                    // Resets the amount if the amount type is not limited.

                    if (vm.scheduleOfBenefit.icuRoomAndBoard.isAsCharged === vm.scheduleOfBenefit.icuRoomAndBoard.isCovered) {
                        vm.tempIcuAmount = vm.scheduleOfBenefit.icuRoomAndBoard.amount;
                        vm.scheduleOfBenefit.icuRoomAndBoard.amount = null;
                    } else {
                        vm.scheduleOfBenefit.icuRoomAndBoard.amount = vm.tempIcuAmount;
                    }

                    // Resets the accomodation days displayed if the amount type is not covered.

                    if (!vm.scheduleOfBenefit.icuRoomAndBoard.isAsCharged && !vm.scheduleOfBenefit.icuRoomAndBoard.isCovered) {
                        vm.scheduleOfBenefit.icuRoomAndBoard.accomodationDays = null;
                    } else {
                        vm.scheduleOfBenefit.icuRoomAndBoard.accomodationDays = vm.tempAccomodationDays;
                    }

                    break;
                case 'childGuardianBenefit':
                    vm.minChildGuardian = vm.childGuardianBenefitAmountType === 1 ? 1 : 0;
                    switch (vm.childGuardianBenefitAmountType) {
                        case 0: /*ischarged*/
                            vm.scheduleOfBenefit.childGuardianBenefit.isAsCharged = true;
                            vm.scheduleOfBenefit.childGuardianBenefit.isCovered = true;
                            break;
                        case 1: /*limited*/
                            vm.scheduleOfBenefit.childGuardianBenefit.isAsCharged = false;
                            vm.scheduleOfBenefit.childGuardianBenefit.isCovered = true;
                            break;
                        case 2: /*uncovered*/
                            vm.scheduleOfBenefit.childGuardianBenefit.isAsCharged = false;
                            vm.scheduleOfBenefit.childGuardianBenefit.isCovered = false;
                            break;
                    }

                    if (vm.scheduleOfBenefit.childGuardianBenefit.isAsCharged === vm.scheduleOfBenefit.childGuardianBenefit.isCovered) {
                        vm.tempChildGuardianBenefitAmount = vm.scheduleOfBenefit.childGuardianBenefit.amount;
                        vm.scheduleOfBenefit.childGuardianBenefit.amount = null;
                    } else {
                        vm.scheduleOfBenefit.childGuardianBenefit.amount = vm.tempChildGuardianBenefitAmount;
                    }

                    break;
                case 'hospitalSuppliesAndServices':
                    vm.minHospitalSuppliesAndServices = vm.hospitalSuppliesAndServicesAmountType === 1 ? 1 : 0;
                    switch (vm.hospitalSuppliesAndServicesAmountType) {
                        case 0: /*ischarged*/
                            vm.scheduleOfBenefit.hospitalCharges.hospitalSuppliesAndServices.isAsCharged = true;
                            vm.scheduleOfBenefit.hospitalCharges.hospitalSuppliesAndServices.isCovered = true;
                            break;
                        case 1: /*limited*/
                            vm.scheduleOfBenefit.hospitalCharges.hospitalSuppliesAndServices.isAsCharged = false;
                            vm.scheduleOfBenefit.hospitalCharges.hospitalSuppliesAndServices.isCovered = true;
                            break;
                        case 2: /*uncovered*/
                            vm.scheduleOfBenefit.hospitalCharges.hospitalSuppliesAndServices.isAsCharged = false;
                            vm.scheduleOfBenefit.hospitalCharges.hospitalSuppliesAndServices.isCovered = false;
                            break;
                    }

                    if (vm.scheduleOfBenefit.hospitalCharges.hospitalSuppliesAndServices.isAsCharged === vm.scheduleOfBenefit.hospitalCharges.hospitalSuppliesAndServices.isCovered) {
                        vm.tempHospitalSuppliesAndServicesAmount = vm.scheduleOfBenefit.hospitalCharges.hospitalSuppliesAndServices.amount;
                        vm.scheduleOfBenefit.hospitalCharges.hospitalSuppliesAndServices.amount = null;

                        vm.tempHospitalSuppliesAndServicesEffectivePeriod = vm.scheduleOfBenefit.hospitalCharges.hospitalSuppliesAndServices.coveredCycle;
                        vm.scheduleOfBenefit.hospitalCharges.hospitalSuppliesAndServices.coveredCycle = null;
                    } else {
                        vm.scheduleOfBenefit.hospitalCharges.hospitalSuppliesAndServices.amount = vm.tempHospitalSuppliesAndServicesAmount;

                        if (vm.tempHospitalSuppliesAndServicesEffectivePeriod) {
                            vm.scheduleOfBenefit.hospitalCharges.hospitalSuppliesAndServices.coveredCycle = vm.tempHospitalSuppliesAndServicesEffectivePeriod;
                        } else {
                            vm.scheduleOfBenefit.hospitalCharges.hospitalSuppliesAndServices.coveredCycle = null; // Not Applicable
                        }
                    }

                    break;
                case 'ambulanceFee':
                    vm.minAmbulanceFeeAmount = vm.ambulanceFeeAmountType === 1 ? 1 : 0;
                    switch (vm.ambulanceFeeAmountType) {
                        case 0: /*ischarged*/
                            vm.scheduleOfBenefit.ambulanceFee.isAsCharged = true;
                            vm.scheduleOfBenefit.ambulanceFee.isCovered = true;
                            break;
                        case 1: /*limited*/
                            vm.scheduleOfBenefit.ambulanceFee.isAsCharged = false;
                            vm.scheduleOfBenefit.ambulanceFee.isCovered = true;
                            break;
                        case 2: /*uncovered*/
                            vm.scheduleOfBenefit.ambulanceFee.isAsCharged = false;
                            vm.scheduleOfBenefit.ambulanceFee.isCovered = false;
                            break;
                    }

                    if (vm.scheduleOfBenefit.ambulanceFee.isAsCharged === vm.scheduleOfBenefit.ambulanceFee.isCovered) {
                        vm.tempAmbulanceFeeAmount = vm.scheduleOfBenefit.ambulanceFee.amount;
                        vm.scheduleOfBenefit.ambulanceFee.amount = null;

                        vm.tempAmbulanceFeeEffectivePeriod = vm.scheduleOfBenefit.ambulanceFee.coveredCycle;
                        vm.scheduleOfBenefit.ambulanceFee.coveredCycle = null;
                    } else {
                        vm.scheduleOfBenefit.ambulanceFee.amount = vm.tempAmbulanceFeeAmount;

                        if (vm.tempAmbulanceFeeEffectivePeriod) {
                            vm.scheduleOfBenefit.ambulanceFee.coveredCycle = vm.tempAmbulanceFeeEffectivePeriod;
                        } else {
                            vm.scheduleOfBenefit.ambulanceFee.coveredCycle = 6; // PerAdmission
                        }
                    }

                    break;
                case 'medicalReportFee':
                    vm.minMedicalReportFeeAmount = vm.medicalReportAmountType === 1 ? 1 : 0;
                    switch (vm.medicalReportAmountType) {
                        case 0: /*ischarged*/
                            vm.scheduleOfBenefit.medicalReport.isAsCharged = true;
                            vm.scheduleOfBenefit.medicalReport.isCovered = true;
                            break;
                        case 1: /*limited*/
                            vm.scheduleOfBenefit.medicalReport.isAsCharged = false;
                            vm.scheduleOfBenefit.medicalReport.isCovered = true;
                            break;
                        case 2: /*uncovered*/
                            vm.scheduleOfBenefit.medicalReport.isAsCharged = false;
                            vm.scheduleOfBenefit.medicalReport.isCovered = false;
                            break;
                    }

                    if (vm.scheduleOfBenefit.medicalReport.isAsCharged === vm.scheduleOfBenefit.medicalReport.isCovered) {
                        vm.tempMedicalReportAmount = vm.scheduleOfBenefit.medicalReport.amount;
                        vm.scheduleOfBenefit.medicalReport.amount = null;

                        vm.tempMedicalReportEffectivePeriod = vm.scheduleOfBenefit.medicalReport.coveredCycle;
                        vm.scheduleOfBenefit.medicalReport.coveredCycle = null;
                    } else {
                        vm.scheduleOfBenefit.medicalReport.amount = vm.tempMedicalReportAmount;

                        if (vm.tempMedicalReportEffectivePeriod) {
                            vm.scheduleOfBenefit.medicalReport.coveredCycle = vm.tempMedicalReportEffectivePeriod;
                        } else {
                            vm.scheduleOfBenefit.medicalReport.coveredCycle = 6; // PerAdmission
                        }
                    }

                    break;
            }
        }

        vm.checkBoxClicked = function (checkBoxName) {
            switch (checkBoxName) {
                case 'upToDaysPerYear':
                    if (vm.scheduleOfBenefit.doctorCharges.isUnlimitedCoveredDays) {
                        vm.tempDoctorChargesDays = vm.scheduleOfBenefit.doctorCharges.coveredDays;
                        vm.scheduleOfBenefit.doctorCharges.coveredDays = null;
                    } else {
                        vm.scheduleOfBenefit.doctorCharges.coveredDays = vm.tempDoctorChargesDays;
                    }
                    break;
                case 'noOfVisits':
                    if (vm.scheduleOfBenefit.doctorCharges.isUnlimitedVisitPerDay) vm.scheduleOfBenefit.doctorCharges.inHospitalPhysicianVisitPerDay = null;
                    if (vm.scheduleOfBenefit.doctorCharges.isUnlimitedVisitPerDay) {
                        vm.tempinHospitalPhysicianVisitPerDay = vm.scheduleOfBenefit.doctorCharges.inHospitalPhysicianVisitPerDay;
                        vm.scheduleOfBenefit.doctorCharges.inHospitalPhysicianVisitPerDay = null;
                    } else {
                        vm.scheduleOfBenefit.doctorCharges.inHospitalPhysicianVisitPerDay = vm.tempinHospitalPhysicianVisitPerDay;
                    }
                    break;
            }
        }

        function initializeAmountTypes() {
            //TODO: Add initials for amount types for ICU and R&B

            if (!vm.scheduleOfBenefit.roomAndBoard.isCovered)
                vm.roomAndBoardAmountType = 2;
            else if (vm.scheduleOfBenefit.roomAndBoard.isAsCharged)
                vm.roomAndBoardAmountType = 0;
            else if (!vm.scheduleOfBenefit.roomAndBoard.isAsCharged) {
                vm.roomAndBoardAmountType = 1;
            }

            if (!vm.scheduleOfBenefit.icuRoomAndBoard.isCovered)
                vm.icuAmountType = 2;
            else if (vm.scheduleOfBenefit.icuRoomAndBoard.isAsCharged)
                vm.icuAmountType = 0;
            else if (!vm.scheduleOfBenefit.icuRoomAndBoard.isAsCharged) {
                vm.icuAmountType = 1;
            }

            if (!vm.scheduleOfBenefit.childGuardianBenefit.isCovered)
                vm.childGuardianBenefitAmountType = 2;
            else if (vm.scheduleOfBenefit.childGuardianBenefit.isAsCharged)
                vm.childGuardianBenefitAmountType = 0;
            else if (!vm.scheduleOfBenefit.childGuardianBenefit.isAsCharged) {
                vm.childGuardianBenefitAmountType = 1;
            }

            if (!vm.scheduleOfBenefit.hospitalCharges.hospitalSuppliesAndServices.isCovered)
                vm.hospitalSuppliesAndServicesAmountType = 2;
            else if (vm.scheduleOfBenefit.hospitalCharges.hospitalSuppliesAndServices.isAsCharged)
                vm.hospitalSuppliesAndServicesAmountType = 0;
            else if (!vm.scheduleOfBenefit.hospitalCharges.hospitalSuppliesAndServices.isAsCharged) {
                vm.hospitalSuppliesAndServicesAmountType = 1;
            }

            if (!vm.scheduleOfBenefit.ambulanceFee.isCovered)
                vm.ambulanceFeeAmountType = 2;
            else if (vm.scheduleOfBenefit.ambulanceFee.isAsCharged)
                vm.ambulanceFeeAmountType = 0;
            else if (!vm.scheduleOfBenefit.ambulanceFee.isAsCharged) {
                vm.ambulanceFeeAmountType = 1;
            }

            if (!vm.scheduleOfBenefit.medicalReport.isCovered)
                vm.medicalReportAmountType = 2;
            else if (vm.scheduleOfBenefit.medicalReport.isAsCharged)
                vm.medicalReportAmountType = 0;
            else if (!vm.scheduleOfBenefit.medicalReport.isAsCharged) {
                vm.medicalReportAmountType = 1;
            }
        }

        function init() {
            initializeAmountTypes();
        }

        angular.element(document).ready(function () {
            init();
        });
    }
})();
