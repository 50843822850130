/**
 * truncate - Directive for truncate string
 */

(() => {
  angular.module('app').directive('truncate', truncateDirective);

  truncateDirective.$inject = ['$timeout'];

  function truncateDirective($timeout) {
    const directive = {
      link,
      restrict: 'A',
      scope: {
        truncateOptions: '=',
      },
    };
    return directive;

    function link(scope, element) {
      $timeout(() => {
        // TODO Fix dotdotdot missing?
        element.dotdotdot(scope.truncateOptions);
      });
    }
  }
})();
