import swal from 'sweetalert';
import PromptQueue from '../../../../../../lib/prompt-queue';

(() => {
  angular.module('app').component('hostInpatientNprClaimSummaryComponent', {
    templateUrl: require('./claimSummary.component.html'),
    controller: ClaimSummaryController,
    controllerAs: 'vm',
    bindings: {
      receiptDate: '=',
      coPay: '<',
    },
  });

  ClaimSummaryController.$inject = [
    '$state',
    '$stateParams',
    '$timeout',
    'abp.services.app.nprClaim',
    'abp.services.app.inpatientPatientTicket',
    'Hms.Claims.ClaimStatus',
    'Hms.PatientTickets.PatientTicketItem',
    'moment',
  ];

  function ClaimSummaryController(
    $state,
    $stateParams,
    $timeout,
    nprClaimSvc,
    inpatientPatientTicketSvc,
    enumClaimStatus,
    constsPatientTicketItem,
    moment
  ) {
    const vm = this;

    vm.constsPatientTicketItem = constsPatientTicketItem;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.defaultMaxAllowance = parseFloat(abp.setting.get('Hms.General.MaxAllowanceAmountLimit'));
    vm.claimNumber = $stateParams.claimNumber;
    vm.isHost = App.isHost();
    vm.isCorporate = App.isCorporate();
    vm.loading = 0;
    vm.saving = 0;

    vm.maxTicketDate = moment().startOf('day');

    vm.treatments = [];
    vm.patient = null;
    vm.clinic = {};
    vm.report = null;
    vm.ticket = { treatments: [], confirm: true, amount: 0, attachments: [] };
    vm.claim = { receiptAmount: 0 };
    vm.processRemarks = '';

    vm.enums = {};
    vm.enums.claimStatus = enumClaimStatus;

    vm.treatmentList = [];

    vm.uploadConfig = {
      objectType: 'TicketAttachment',
    };
    vm.hasInsuranceEnhancementModule = abp.setting.getBoolean(
      'Hms.Feature.InsuranceEnhancementModule'
    );
    vm.diagnoses = {
      selected: [],
      data: [],
    };
    vm.specialistTreatmentCategories = [];
    vm.sections = [
      {
        treatments: [{}],
      },
    ];
    vm.isTaxInclusive = null;

    vm.setTicketDate = setTicketDate;
    vm.resetClaimType = resetClaimType;
    vm.treatmentHasValue = treatmentHasValue;
    vm.treatmentMissed = treatmentMissed;
    vm.checkRemarkRequired = checkRemarkRequired;
    vm.getRemarkPlaceholder = getRemarkPlaceholder;
    vm.addTreatments = addTreatments;
    vm.clearTreatments = clearTreatments;
    vm.removeTreatment = removeTreatment;
    vm.checkDuplicateTreatment = checkDuplicateTreatment;
    vm.updateTreatment = updateTreatment;
    vm.refreshTreatmentAndMcRemarks = refreshTreatmentAndMcRemarks;
    vm.hasUncoveredTreatments = hasUncoveredTreatments;
    vm.getAllowanceDisplay = getAllowanceDisplay;
    vm.getTreatmentTotalAmount = getTreatmentTotalAmount;
    vm.calculateAmountCoverage = calculateAmountCoverage;
    vm.getCopayAmount = getCopayAmount;
    vm.getManualPaymentAmount = getManualPaymentAmount;
    vm.getCoveredAmount = getCoveredAmount;
    vm.getTicketTotalAmount = getTicketTotalAmount;
    vm.isTotalBillAmountValid = isTotalBillAmountValid;
    vm.issueMc = issueMc;
    vm.removeMc = removeMc;
    vm.setMcStartDate = setMcStartDate;
    vm.getMcDurationDescription = getMcDurationDescription;
    vm.getMcStartDateChoice = getMcStartDateChoice;
    vm.save = save;
    vm.getTotalCoveredAmount = getTotalCoveredAmount;
    vm.getTotalUncoveredAmount = getTotalUncoveredAmount;
    vm.getEligibleClaimAmount = getEligibleClaimAmount;
    vm.getEntitledAmount = getEntitledAmount;
    vm.getExceededAmount = getExceededAmount;
    vm.getTotalPayableAmount = getTotalPayableAmount;
    vm.getTotalCollectCashAmount = getTotalCollectCashAmount;
    vm.getCoveredTaxAmount = getCoveredTaxAmount;
    vm.getUncoveredTaxAmount = getUncoveredTaxAmount;
    vm.getTotalAmount = getTotalAmount;
    vm.getSubtotalAmount = getSubtotalAmount;
    vm.getTaxAmount = getTaxAmount;
    vm.getCoPay = getCoPay;

    init();

    function init() {
      vm.loading += 1;

      nprClaimSvc
        .getNprPrePostHospitalizationRequestDetails({
          id: $stateParams.claimNumber,
        })
        .success((data) => {
          vm.claim = data;
          vm.diseaseClassifications = data.diseaseClassifications || [];
          vm.patient = data.patient;
          vm.ticket = data.ticket;
          vm.treatmentList = data.treatments || [];
          vm.receiptDate = vm.ticket.receiptDate;

          if (vm.hasInsuranceEnhancementModule) {
            vm.diagnoses.data = _.map(data.diseaseClassifications, (d) => ({
              code: d.code,
              description: d.description,
              displayName: `(${d.code}) ${d.description}`,
            }));
            vm.specialistTreatmentCategories = data.specialistTreatmentCategories;
          }
          vm.resetClaimType();
        })
        .finally(() => {
          vm.loading -= 1;
        });

      vm.newAttachments = [];
    }

    function setTicketDate() {
      refreshAllowance();
      vm.receiptDate = vm.ticket.receiptDate;

      if (vm.ticket.medicalCertificate) {
        vm.ticket.medicalCertificate.startDate = moment(vm.receiptDate).startOf('day');
        vm.ticket.medicalCertificate.endDate = moment(vm.receiptDate).startOf('day');
      }
    }

    /* Treatment Management */

    function resetClaimType() {
      vm.clearTreatments();
      refreshAllowance();
    }

    function treatmentHasValue(treatment) {
      if (vm.hasInsuranceEnhancementModule)
        return treatment.code || hasRemark(treatment) || treatment.coveredAmount;

      return treatment.treatment || hasRemark(treatment) || treatment.amount;
    }

    function treatmentMissed(treatment) {
      if (vm.hasInsuranceEnhancementModule)
        return !treatment.code && (hasRemark(treatment) || treatment.coveredAmount);

      return !treatment.treatment && (hasRemark(treatment) || treatment.amount);
    }

    function checkRemarkRequired(treatment) {
      return (
        vm.treatmentHasValue(treatment) &&
        treatment.treatment &&
        treatment.treatment.requiresRemark &&
        !treatment.treatment.notCovered
      );
    }

    function getRemarkPlaceholder(treatment) {
      if (!treatment.treatment) return '';
      if (vm.ticket.clinicType === 1) {
        return _.toUpper(_.trim(treatment.treatment.displayName || '')) ===
          _.toUpper('Consultation')
          ? App.localize('ConsultationRemarksNarrative')
          : App.localize('TreatmentRemarksNarrative');
      }
      if (vm.checkRemarkRequired(treatment)) return App.localize('PleaseExplain');
      return '';
    }

    function hasTreatments() {
      const treatments = vm.hasInsuranceEnhancementModule
        ? _.flatten(_.map(vm.sections, (s) => s.treatments))
        : vm.ticket.treatments;

      return _.some(treatments, (t) => vm.treatmentHasValue(t));
    }

    function refreshTicketTreatments() {
      if (vm.hasInsuranceEnhancementModule) return;

      let hasBlank = _.some(vm.ticket.treatments, (t) => !vm.treatmentHasValue(t));

      while (!hasBlank || vm.ticket.treatments.length < 2) {
        vm.ticket.treatments.push({});
        hasBlank = true;
      }
    }

    function addTreatments() {
      for (let i = 0; i < 4; i += 1) {
        vm.ticket.treatments.push({});
      }
    }

    function clearTreatments() {
      if (vm.hasInsuranceEnhancementModule) {
        vm.sections = [
          {
            treatments: [{}],
          },
        ];

        const refTreatment = vm.sections[0].treatments[0];
        refTreatment.coveredAmount = vm.ticket.amount;
      } else {
        vm.ticket.treatments = [{}];

        // Push the default claim message and remarks.

        const refTreatment = vm.ticket.treatments[0];
        refTreatment.amount = vm.ticket.amount;

        vm.refreshTreatmentAndMcRemarks();
      }
    }

    function removeTreatment(index) {
      if (index < vm.ticket.treatments.length) {
        vm.ticket.treatments.splice(index, 1);
        vm.refreshTreatmentAndMcRemarks();
      }
    }

    function checkDuplicateTreatment(treatment) {
      if (treatment.isDuplicable) return false;

      return _.some(vm.ticket.treatments, (t) => t.treatmentId === treatment.id);
    }

    function updateTreatment(t) {
      const treatment = t;
      if (treatment.treatment) {
        if (vm.checkDuplicateTreatment(treatment.treatment)) {
          abp.message.error(App.localize('DuplicateTreatmentValidationErrorMessage'));
          treatment.treatmentId = null;
          treatment.treatment = null;
          treatment.amount = null;
          return;
        }

        treatment.treatmentId = treatment.treatment.id;
        if (treatment.treatment.defaultRate) {
          $timeout(() => {
            treatment.amount = vm.ticket.amount;
          }, 50);
        }

        if (treatment.treatment.notCovered) {
          treatment.amount = 0;
          treatment.remark = '';
        }
      } else {
        treatment.treatmentId = null;
      }
      vm.refreshTreatmentAndMcRemarks();
    }

    function refreshTreatmentAndMcRemarks() {
      refreshTicketTreatments();
      updateMcRemarks();
    }

    function hasUncoveredTreatments() {
      return _.some(
        vm.ticket.treatments,
        (treatment) => treatment.treatment && treatment.treatment.notCovered
      );
    }

    /* Treatment Remark functions */

    function hasRemark(treatment) {
      if (vm.hasInsuranceEnhancementModule)
        return treatment.coveredDescription && treatment.coveredDescription.length;

      return treatment.remark && treatment.remark.length;
    }

    /* End of Treatment Remark functions */

    /* End of Treatment Management */

    /* Amounts Calculation */

    function getAllowanceDisplay() {
      if (vm.patient) return Math.min(vm.patient.allowance, vm.defaultMaxAllowance);
      return vm.defaultMaxAllowance;
    }

    function refreshAllowance() {
      // Refresh patient's allowance. Only for NPR & Manual tickets.

      vm.loading += 1;
      inpatientPatientTicketSvc
        .getAllowance({
          employeeId: vm.patient.employeeId,
          serviceType: vm.claim.serviceType,
          ticketDate: vm.receiptDate,
        })
        .success((data) => {
          vm.patient.allowance = data.employeeAllowance;
          vm.corporateBalance = data.corporateBalance;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getTreatmentTotalAmount() {
      let amount = 0;

      if (vm.hasInsuranceEnhancementModule) {
        const treatmentCategories = _.flatten(_.map(vm.sections, (s) => s.treatments));

        _.each(treatmentCategories, (t) => {
          if (t.coveredAmount) amount = App.evenRound(amount + t.coveredAmount, 2);

          if (t.uncoveredAmount) amount = App.evenRound(amount + t.uncoveredAmount, 2);
        });
      } else {
        _.each(vm.ticket.treatments, (t) => {
          if (t.treatment) {
            if (t.amount) amount = App.evenRound(amount + t.amount, 2);

            if (t.uncoveredAmount) amount = App.evenRound(amount + t.uncoveredAmount, 2);
          }
        });
      }
      return amount;
    }

    function calculateAmountCoverage() {
      let claimable = 0;
      let uncovered = 0;
      let coPayAmount = 0;
      let totalUncoveredAmount = 0;
      let totalCoveredAmount = 0;

      if (vm.hasInsuranceEnhancementModule) {
        const treatmentCategories = _.flatten(_.map(vm.sections, (s) => s.treatments));

        _.each(treatmentCategories, (t) => {
          claimable += t.coveredAmount || 0;
          totalUncoveredAmount += t.uncoveredAmount || 0;
          totalCoveredAmount += t.coveredAmount || 0;
        });
      } else {
        _.each(vm.ticket.treatments, (t) => {
          if (t.treatment) {
            if (!t.treatment.notCovered) {
              claimable += t.amount || 0;

              if (t.uncoveredAmount) {
                uncovered += t.uncoveredAmount || 0;
              }
            } else {
              uncovered += t.amount || 0;
              uncovered += t.uncoveredAmount || 0;
            }
          }
        });
      }

      if (vm.patient && vm.ticket && claimable) {
        if (vm.coPay.value !== 0) {
          coPayAmount = vm.coPay.value;
          if (vm.coPay.isPercentage) {
            coPayAmount *= claimable / 100;
          }
          if (coPayAmount < 0) {
            coPayAmount = 0;
          } else if (coPayAmount > claimable) {
            coPayAmount = claimable;
          }
          claimable -= coPayAmount;
          uncovered += coPayAmount;
        }

        // Check against allowance

        if (claimable > vm.patient.allowance) {
          claimable = vm.patient.allowance;
        }
      }

      // const total = vm.ticket.amount;
      const manualPayment = uncovered;

      return {
        claimable: App.roundAmount(claimable),
        manualPayment: App.roundAmount(manualPayment),
        copay: App.roundAmount(coPayAmount),
        totalCoveredAmount: App.roundAmount(totalCoveredAmount),
        totalUncoveredAmount: App.roundAmount(totalUncoveredAmount),
      };
    }

    function getCopayAmount() {
      vm.ticket.coPayAmount = vm.calculateAmountCoverage().copay;
      return vm.ticket.coPayAmount;
    }

    function getCoPay() {
      return vm.coPay;
    }

    function getManualPaymentAmount() {
      vm.ticket.manualPayment = vm.calculateAmountCoverage().manualPayment;
      return vm.ticket.manualPayment;
    }

    function getCoveredAmount() {
      vm.ticket.coveredAmount = vm.calculateAmountCoverage().claimable;
      return vm.ticket.coveredAmount;
    }

    function getTicketTotalAmount() {
      return vm.getTreatmentTotalAmount();
    }

    function isTotalBillAmountValid() {
      return (
        vm.claim &&
        vm.getTicketTotalAmount() === vm.ticket.amount &&
        vm.getTreatmentTotalAmount() === vm.ticket.amount
      );
    }

    /* End of Amounts Calculation */

    /* Medical Certificate Management */

    function updateMcRemarks() {
      if (vm.ticket.medicalCertificate && !vm.isCtTicket && !vm.claimId) {
        const remark = _.chain(vm.ticket.treatments)
          .filter((t) => t.treatment && t.treatment.displayName)
          .map((t) => t.treatment.displayName)
          .join(', ')
          .value();
        vm.ticket.medicalCertificate.remark = remark;
      }
    }

    function issueMc() {
      const firstDate = moment(vm.receiptDate).startOf('day');
      vm.ticket.medicalCertificate = {
        startDate: firstDate,
        endDate: firstDate,
      };
      vm.isMcStartDateAlsoCheckedInDate = 1;
      updateMcRemarks();
    }

    function removeMc() {
      vm.ticket.medicalCertificate = null;
    }

    function setMcStartDate(offset) {
      const checkInDate = moment(vm.ticket.checkedInTime).startOf('day');
      vm.ticket.medicalCertificate.startDate = checkInDate.add(offset, 'days');

      const mcEndDate = vm.ticket.medicalCertificate.endDate.startOf('day');
      if (mcEndDate.diff(vm.ticket.medicalCertificate.startDate, 'days') < 0) {
        vm.ticket.medicalCertificate.endDate = moment(vm.ticket.medicalCertificate.startDate);
      }
      vm.isMcStartDateAlsoCheckedInDate = offset === 0;
    }

    function getMcDurationDescription() {
      if (vm.ticket.medicalCertificate) {
        const startDate = moment(vm.ticket.medicalCertificate.startDate);
        const endDate = moment(vm.ticket.medicalCertificate.endDate);
        const duration = endDate.diff(startDate, 'days') + 1;
        return App.localize('XDays', duration);
      }
      return '';
    }

    function getMcStartDateChoice(offset) {
      return moment(vm.ticket.checkedInTime).add(offset, 'days').startOf('day');
    }

    /* End of Medical Certificate Management */

    function save() {
      createOrEditTicket();
    }

    function createOrEditTicket() {
      let canSubmit = true;
      const prompts = new PromptQueue();
      let invalidTreatment = {};

      vm.clinic = {};
      vm.ticket.doctorId = null;

      if (!hasTreatments()) {
        abp.message.error(App.localize('EmptyTreatmentValidationErrorMessage'));
        return;
      }

      if (vm.hasInsuranceEnhancementModule) {
        const treatments = _.flatten(_.map(vm.sections, (s) => s.treatments));
        invalidTreatment = _.find(treatments, (t) => vm.treatmentHasValue(t) && !t.code);
      } else {
        invalidTreatment = _.find(
          vm.ticket.treatments,
          (t) => vm.treatmentHasValue(t) && !t.treatmentId
        );
      }

      if (!vm.isCtTicket && !vm.isInpatient && invalidTreatment) {
        abp.message.error(App.localize('OneOrMoreInputValueIsInvalid'));
        return;
      }

      let cleanTreatments = true;
      _.each(vm.ticket.treatments, (t) => {
        if (vm.treatmentHasValue(t)) {
          if (t.amount < 0) {
            abp.message.error(App.localize('InvalidTreatmentRateAmount'));
            cleanTreatments = false;
            return false;
          }
          if (vm.checkRemarkRequired(t) && !t.remark) {
            abp.message.error(App.localize('MissingTreatmentRemark'));
            cleanTreatments = false;
            return false;
          }
        }

        return true;
      });

      if (!cleanTreatments) return;

      if (vm.receiptDate > moment()) {
        abp.message.error(App.localize('TicketDateIsInFuture'));
        return;
      }

      if (vm.getTicketTotalAmount() > vm.ticket.amount) {
        abp.message.error(App.localize('TicketAmountMoreThanClaimReceiptAmount'));
        return;
      }

      if (vm.ticket.medicalCertificate) {
        const startDate = moment(vm.ticket.medicalCertificate.startDate);
        const endDate = moment(vm.ticket.medicalCertificate.endDate);
        const mcDays = endDate.diff(moment(startDate).startOf('day'), 'days');
        if (Number.isNaN(Number(mcDays)) || mcDays < 0) {
          abp.message.error(App.localize('InvalidEffectiveMcDays'));
          return;
        }

        const mcStartDayOffset = startDate.diff(
          moment(vm.ticket.receiptDate).startOf('day'),
          'days'
        );
        if (mcStartDayOffset < 0) {
          abp.message.error(App.localize('McStartDateEarlierThanTicketDate'));
          return;
        }
        vm.ticket.medicalCertificate.effectiveMcDays = endDate.diff(startDate, 'days') + 1;

        if (mcStartDayOffset > 1) {
          prompts.add(
            swal,
            {
              title: App.localize('AreYouSure'),
              text: App.localize('McStartDateTooFarFromTicketDateWarning'),
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#1ab394',
              confirmButtonText: App.localize('Yes'),
              cancelButtonText: App.localize('No'),
              closeOnConfirm: true,
              closeOnCancel: true,
            },
            (isConfirm) => {
              canSubmit = isConfirm;
            }
          );
        }
      }

      if (vm.claim && vm.getTicketTotalAmount() !== vm.ticket.amount) {
        prompts.add(
          swal,
          {
            title: App.localize('AreYouSure'),
            text: App.localize('ClaimReceiptAmountMismatchWithTicketAmountWarning'),
            type: 'input',
            showCancelButton: true,
            confirmButtonColor: '#1ab394',
            confirmButtonText: App.localize('Submit'),
            cancelButtonText: App.localize('Cancel'),
            closeOnConfirm: true,
            closeOnCancel: true,
            inputPlaceholder: App.localize('PleaseExplain'),
          },
          (inputValue) => {
            if (inputValue === false) {
              refreshTicketTreatments();
              canSubmit = false;
            }

            if ($.trim(inputValue || '') === '') {
              swal.showInputError(App.localize('InvalidInput'));
              canSubmit = false;
            } else if (inputValue) {
              vm.processRemarks = inputValue;
            }
          }
        );
      }

      // Construct input request.

      const input = $.extend(vm.ticket, {
        newAttachments: vm.newAttachments,
        paymentEmailAddresses: vm.ticket.bankingDetails.paymentEmailAddresses,
        serviceFeePayor: vm.ticket.bankingDetails.serviceFeePayor,
        claimRemarks: vm.processRemarks,
        manualPayment: vm.getTotalCollectCashAmount(),
        diagnoses: vm.diagnoses.selected,
        sections: vm.sections,
      });

      input.treatments = _.filter(input.treatments, (treatment) => treatment.treatmentId > 0);

      vm.saving += 1;
      prompts.run(() => {
        vm.saving -= 1;
        if (canSubmit) processNprPrePostHospitalizationClaim(input);
      });
    }

    function processNprPrePostHospitalizationClaim(input) {
      if (vm.saving !== 0) return;

      vm.saving += 1;
      inpatientPatientTicketSvc
        .processNprPrePostHospitalizationClaim(input)
        .success((data) => {
          abp.notify.info(App.localize('TicketXIssued', data));
          $state.go('inpatientNpr');

          // TODO: Show success modal and get next clam number with claim type.
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function getTotalUncoveredAmount() {
      return vm.calculateAmountCoverage().totalUncoveredAmount;
    }

    function getTotalCoveredAmount() {
      return vm.calculateAmountCoverage().totalCoveredAmount;
    }

    function getEligibleClaimAmount() {
      return getTotalCoveredAmount() - getCopayAmount();
    }

    function getEntitledAmount() {
      const allowance = getAllowanceDisplay();
      const eligibleClaimAmount = getEligibleClaimAmount();
      return eligibleClaimAmount > allowance ? allowance : eligibleClaimAmount;
    }

    function getExceededAmount() {
      return getEligibleClaimAmount() - getEntitledAmount();
    }

    function getTotalPayableAmount() {
      return getEligibleClaimAmount() - getExceededAmount();
    }

    function getTotalCollectCashAmount() {
      return getTotalUncoveredAmount() + getCopayAmount() + getExceededAmount();
    }

    function getCoveredTaxAmount() {
      if (vm.taxPercentage <= 0) return 0;

      if (vm.isTaxInclusive)
        return (getTotalCoveredAmount() / (100 + vm.taxPercentage)) * vm.taxPercentage;

      return (getTotalCoveredAmount() * vm.taxPercentage) / 100;
    }

    function getUncoveredTaxAmount() {
      if (vm.taxPercentage <= 0) return 0;

      if (vm.isTaxInclusive)
        return (getTotalUncoveredAmount() / (100 + vm.taxPercentage)) * vm.taxPercentage;

      return (getTotalUncoveredAmount() * vm.taxPercentage) / 100;
    }

    function getTotalAmount() {
      return vm.getSubtotalAmount() + vm.getTaxAmount();
    }

    function getSubtotalAmount() {
      const treatmentAmount = getTreatmentTotalAmount();
      return vm.isTaxInclusive ? treatmentAmount / (1 + vm.taxPercentage / 100) : treatmentAmount;
    }

    function getTaxAmount() {
      let taxAmount = 0;
      if (vm.isTaxInclusive !== null && !vm.isTaxInclusive)
        taxAmount = (getSubtotalAmount() * vm.taxPercentage) / 100;
      else if (vm.isTaxInclusive) taxAmount = getTreatmentTotalAmount() - getSubtotalAmount();
      return taxAmount;
    }
  }
})();
