(() => {
  angular.module('app').controller('common.views.notifications.index', NotificationsPageController);

  NotificationsPageController.$inject = [
    '$scope',
    'abp.services.app.notification',
    'appUserNotificationHelper',
    'appConstants',
  ];

  function NotificationsPageController($scope, notificationSvc, notificationHelper, appConstants) {
    const vm = this;

    vm.loading = 0;
    vm.readStateFilter = 'ALL';

    vm.requestParams = {
      skipCount: 0,
      maxResultCount: appConstants.grid.defaultPageSize,
      sorting: null,
    };

    vm.notificationGridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSizes,
      paginationPageSize: appConstants.grid.defaultPageSize,
      useExternalPagination: true,
      enableSorting: false,
      rowTemplate:
        '<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader, \'text-muted\' : row.entity.state == \'READ\' }"  ui-grid-cell></div>',
      columnDefs: [
        {
          name: App.localize('Actions'),
          width: 120,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionsTemplate'
        },
        {
          displayName: App.localize('Notification'),
          field: 'text',
          minWidth: 500,
          cellTemplate: 'notificationTemplate'
        },
        {
          displayName: App.localize('CreationTime'),
          field: 'time',
          width: 150,
          cellTemplate: 'creationTimeTemplate',
          cellFilter: 'fromNow',
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, vm.getNotifications)
        );
      },
      data: [],
    };

    vm.getNotifications = getNotifications;
    vm.setAllNotificationsAsRead = setAllNotificationsAsRead;
    vm.openNotificationSettingsModal = openNotificationSettingsModal;
    vm.setNotificationAsRead = setNotificationAsRead;

    init();

    function init() {
      vm.getNotifications();
    }

    function getNotifications() {
      let state = null;
      if (vm.readStateFilter === 'UNREAD') {
        state = abp.notifications.userNotificationState.UNREAD;
      }

      vm.loading += 1;
      notificationSvc
        .getUserNotifications($.extend({}, vm.requestParams, { state }))
        .success((data) => {
          vm.notificationGridOptions.totalItems = data.totalCount;
          vm.notificationGridOptions.data = _.map(data.items, (n) =>
            notificationHelper.format(n, false)
          );
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function setAllNotificationsAsRead() {
      notificationHelper.setAllAsRead(() => {
        vm.getNotifications();
      });
    }

    function openNotificationSettingsModal() {
      notificationHelper.openSettingsModal();
    }

    function setNotificationAsRead(notification) {
      const n = notification;
      notificationHelper.setAsRead(n.userNotificationId, () => {
        n.state = 'READ';
      });
    }
  }
})();
