(function () {
  var componentId = 'clinicHospitalInpatientDashboardCurrentPatientsGuaranteeLetterDetailCardsComponent';
  var app = angular.module('app');

  app.component(componentId, {
    templateUrl: require('/App/clinic/views/hospital/inpatientDashboard/currentPatients/guaranteeLetterDetailCards/guaranteeLetterDetailCards.component.html'),
    controller: ['abp.services.app.hospitalPanelServiceRequest', '$uibModal', '$state', 'moment', controller],
    controllerAs: 'vm',
    bindings: {
      guaranteeLetters: '=',
      supportContactNumber: '<?',
      dismissGuaranteeLetter: '&',
      refreshFunction: '&'
    }
  });

  function controller(hospitalPanelServiceRequestSvc, $uibModal, $state, moment) {
    var vm = this;
    vm.loading = 0;
    vm.supportContactNumber =
      _.isNil(vm.supportContactNumber) || vm.supportContactNumber === undefined
        ? abp.setting.get('Hms.General.SpecialistSupportContactNumber')
        : vm.supportContactNumber;

    vm.cancelRequest = cancelRequest;
    vm.checkInEmployee = checkInEmployee;
    vm.requestTopUpAddOn = requestTopUpAddOn;
    vm.getDateTimeString = getDateTimeString;

    function cancelRequest(guaranteeLetter) {
      $uibModal.open({
        templateUrl: require('/App/clinic/views/hospital/common/cancellationModal/cancel.modal.html'),
        controller: 'clinic.views.hospital.common.cancelModal as vm',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          letterRequestType: function () {
            return guaranteeLetter.serviceRequestType;
          },
          letterStatus: function () {
            return guaranteeLetter.guaranteeLetterStatus;
          }
        }
      }).result.then(function (cancellationReason) {
        if (cancellationReason) {
          var input = {
            letterNumber: guaranteeLetter.guaranteeLetterNumber,
            cancellationReason: cancellationReason
          };

          vm.loading++;

          hospitalPanelServiceRequestSvc.cancelGuaranteeLetterServiceRequest(input)
            .success(function () {
              abp.notify.success("Cancelled");
            })
            .finally(function () {
              vm.loading--;
              vm.refreshFunction();
            });
        }
      });
    }

    function checkInEmployee(guaranteeLetter) {
      var today = moment().format('YYYY-MM-DD');
      var scheduledTime = moment(guaranteeLetter.scheduledAdmissionTime).format('YYYY-MM-DD');
      if (!scheduledTime)
        return;

      if (scheduledTime === today) {
        swal({
          title: App.localize('ConfirmAdmission'),
          text: App.localize('ConfirmAdmissionNarrative'),
          confirmButtonColor: '#1ab394',
          confirmButtonText: App.localize('Yes'),
          cancelButtonText: App.localize('No'),
          showCancelButton: true,
          closeOnConfirm: false,
        }, function (isConfirm) {
          if (isConfirm) {
            checkIn('', guaranteeLetter);
            vm.refreshFunction();
          }
          swal.close();
        });
      } else {
        $uibModal.open({
          templateUrl: require('/App/clinic/views/hospital/patient/admission/admission.modal.html'),
          controller: 'clinic.views.hospital.patient.admission as vm',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            scheduleDate: function () {
              return guaranteeLetter.scheduledAdmissionTime;
            },
            expiryDate: function () {
              return guaranteeLetter.expiryTime;
            },
            letterNumber: function () {
              return guaranteeLetter.guaranteeLetterNumber;
            }
          }
        }).result.then(function (data) {
          if (data.isCheckIn)
            checkIn(data, guaranteeLetter);
          vm.refreshFunction();
        });
      }
    }

    function checkIn(data, guaranteeLetter) {
      vm.loading++;
      hospitalPanelServiceRequestSvc.checkIn({
        employeeId: guaranteeLetter.employeeId,
        guaranteeLetterNumber: guaranteeLetter.guaranteeLetterNumber,
        serviceType: guaranteeLetter.serviceRequestType,
        remarks: data.reason,
        actualAdmissionDate: data.actualAdmissionDate,
        newAttachments: data.newAttachments
      }).success(function (data) {
        if (data.printUrl != "") {
          abp.notify.info(App.localize('PatientXCheckedIn', guaranteeLetter.patientName));
          window.open(data.printUrl);
          goToTimeline(guaranteeLetter.guaranteeLetterNumber);
        } else {
          if (data.isEarlyCheckIn)
            abp.notify.info(App.localize('EarlyCheckInRequestSubmitted'));
          else
            abp.notify.info(App.localize('LateCheckInRequestSubmitted'));
          vm.refreshFunction();
        }
      }).finally(function () {
        vm.loading--;
      });
    }

    function requestTopUpAddOn(guaranteeLetter) {
      var modal = $uibModal.open({
        templateUrl: require('/App/clinic/views/patient/admissionDetails/patientAdmissionTimelineBody/topUpRequestModal/topUpRequestModal.html'),
        controller: "clinic.views.hospital.topUpRequestModal as vm",
        backdrop: "static",
        size: 's',
      });
      modal.result.then(function (requestType) {
        $state.go('clinic.topUpRequest', {
          'requestType': requestType,
          'letterNumber': guaranteeLetter.guaranteeLetterNumber,
          'panelLocationId': guaranteeLetter.panelLocationId,
          'requestId': guaranteeLetter.serviceRequestId
        });
      });
    };

    function goToTimeline(guaranteeLetterNumber) {
      $state.go('clinic.patientAdmissionDetails', {
        'letterNumber': guaranteeLetterNumber
      });
    }

    function getDateTimeString(date, timeZoneId) {
      return App.getDateTimeString(date, timeZoneId);
    }
  }
})();
