(() => {
  angular.module('finance').component('panelClaimInvoiceDetailDetailedChargesComponent', {
    templateUrl: require('./panelClaimDetailedCharges.component.html'),
    controller: PanelClaimInvoiceDetailDetailedChargesComponent,
    controllerAs: 'vm',
    bindings: {
      invoice: '<',
      getDateString: '<'
    },
  });

  PanelClaimInvoiceDetailDetailedChargesComponent.$inject = [];

  function PanelClaimInvoiceDetailDetailedChargesComponent() {
    const vm = this;

    vm.loading = 0;
    vm.isDate = function(item) {
      return App.isDate(item);
    };

    init();

    function init() {}
  }
})();
