(() => {
  angular
    .module('app')
    .controller('common.views.inpatientNprClaims.details', InpatientNprClaimsDetailsController);

  InpatientNprClaimsDetailsController.$inject = [
    '$state',
    '$stateParams',
    'abp.services.app.nprClaim',
  ];

  function InpatientNprClaimsDetailsController($state, $stateParams, nprClaimSvc) {
    const vm = this;

    vm.loading = 0;
    vm.claimNumber = $stateParams.claimNumber;
    vm.hospitalizationBenefits = [];
    vm.coPay = {
      isCoInsurance: false,
      isPercentage: false,
      value: 0,
    };

    vm.roomAndBoardCharges = 0;

    vm.nextClaim = nextClaim;

    init();

    function init() {}

    function nextClaim() {
      vm.loading += 1;
      nprClaimSvc
        .getNextClaim({ id: $stateParams.claimNumber })
        .success((data) => {
          if (data.id) {
            $state.go('inpatientNprClaimDetail', { claimNumber: data.id });
          } else {
            abp.notify.info(App.localize('NoMoreUnprocessedClaims'));
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
