(() => {
  angular
    .module('app')
    .controller(
      'common.views.guaranteeLetters.details.letterDetail.approveModal',
      ApproveModalController
    );

  ApproveModalController.$inject = [
    '$uibModalInstance',
    'abp.services.app.guaranteeLetter',
    'letter',
  ];

  function ApproveModalController($uibModalInstance, guaranteeLetterSvc, letter) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.letter = letter;

    vm.disableSubmit = disableSubmit;
    vm.save = save;
    vm.cancel = cancel;

    init();

    function init() {
      vm.loading += 1;
      guaranteeLetterSvc
        .getGuaranteeLetterForApprove({
          id: letter.letterNumber,
        })
        .success((data) => {
          vm.letter.corporateBalance = data.corporateBalance;
          vm.letter.employeeBalance = data.employeeBalance;
          vm.signatureProfiles = data.signatureProfiles;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function disableSubmit() {
      let selectedCount = 0;
      _.each(vm.signatureProfiles, (d) => {
        if (d.isSelected) {
          selectedCount += 1;
        }
      });

      return vm.saving || selectedCount !== 1;
    }

    function save() {
      const profile = _.find(vm.signatureProfiles, (d) => d.isSelected);
      $uibModalInstance.close(profile.id);
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
