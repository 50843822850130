(function () {
    var operatingHours = [
        'abp.services.app.panelLocationOperatingHour', 'abp.services.app.commonLookup', 'System.DayOfWeek', 'moment',
        function (operatingHourSvc, commonLookupSvc, enumDayOfWeek, moment) {
            var vm = this;
            vm.loading = 0;
            vm.panelLocationId = 0;
            vm.locations = [];
            vm.schedules = [];
            vm.specialHours = [];
            var open24Hours = App.localize('Open24Hours');
            vm.notSet = App.localize('NotSet');
            var closed = App.localize('Closed');
            vm.error = false;

            vm.enums = {};
            vm.enums.dayOfWeek = enumDayOfWeek;

            vm.operatingHoursType = [{
                name: App.localize('NormalHours'),
                value: 1
            },
            {
                name: App.localize('SpecialHours'),
                value: 2
            }];
            vm.selectedHours = 1;
            vm.operatingHoursEditMode = false;

            vm.normalOperatingHoursGridOptions = {
                appScopeProvider: vm,
                columnDefs: [{
                    enableSorting: false,
                    displayName: App.localize('Day'),
                    field: 'dayOfWeek',
                    cellTemplate: 'dayOfWeekTemplate'
                }, {
                    enableSorting: false,
                    displayName: App.localize('Hours'),
                    field: 'operatingHour',
                    cellTemplate: 'hoursTemplate'
                }],
                data: []
            };

            vm.specialOperatingHoursGridOptions = {
                appScopeProvider: vm,
                columnDefs: [{
                    enableSorting: false,
                    displayName: App.localize('Day'),
                    field: 'day',
                    minWidth: 120
                }, {
                    enableSorting: false,
                    displayName: App.localize('Date'),
                    field: 'date',
                    minWidth: 120
                }, {
                    enableSorting: false,
                    displayName: App.localize('Hours'),
                    field: 'operatingHour',
                    minWidth: 120,
                    cellTemplate: 'hoursTemplate'
                }],
                data: []
            };

            // Function declarations

            vm.getOperatingHours = getOperatingHours;
            vm.toggleSelectedHours = toggleSelectedHours;
            vm.cancelEdit = cancelEdit;
            vm.saveOperatingHours = saveOperatingHours;

            function init() {
                getClinicLocations();
                getDefaultClinicLocation();
            }

            function getOperatingHours() {
                if (vm.panelLocationId === 0)
                    return;

                vm.loading++;
                operatingHourSvc.getOperatingHours({ id: vm.panelLocationId }).success(function (data) {
                    processNormalOperatingHours(data.normalOperatingHours);
                    processSpecialOperatingHours(data.specialHours);
                    vm.modificationLog = data.modificationLog;
                }).finally(function (e) {
                    vm.loading--;
                });
            }

            function toggleSelectedHours(value) {
                vm.selectedHours = value;
            }

            function getClinicLocations() {
                vm.loading++;
                operatingHourSvc.getPanelLocation().success(function (data) {
                    vm.locations = data;
                }).finally(function (e) {
                    vm.loading--;
                });
            }

            function getDefaultClinicLocation() {
                vm.loading++;
                commonLookupSvc.getDefaultClinicLocation()
                    .success(function (data) {
                        setLocation(data);
                    })
                    .finally(function () {
                        vm.loading--;
                    });
            }

            function setLocation(location) {
                vm.panelLocationId = parseInt(location.value, 10) || 0;
                vm.getOperatingHours();
            }

            // Normal operating hours

            function processNormalOperatingHours(normalOperatingHours) {
                // Format for listing

                var operatingHours = [];
                var tempOperatingHours = [];
                _.map(normalOperatingHours, function (oh) {
                    var days = oh.daysName.split(",");
                    _.map(days, function (d) {
                        if (oh.hours.length > 0) {
                            _.map(oh.hours, function (h) {
                                var openingHours = oh.is24Hours
                                    ? open24Hours
                                    : oh.isOpen
                                        ? moment(h.openTime).format('LT') + ' - ' + moment(h.closeTime).format('LT')
                                        : closed;
                                tempOperatingHours.push({
                                    dayOfWeek: d,
                                    operatingHour: openingHours
                                });
                            });
                        } else {
                            tempOperatingHours.push({
                                dayOfWeek: d,
                                operatingHour: oh.is24Hours
                                    ? open24Hours
                                    : closed
                            });
                        }
                    });
                });

                for (i = 0; i < 7; i++) {
                    if (!_.some(tempOperatingHours, function (oh) { return parseInt(oh.dayOfWeek) === i; })) {
                        tempOperatingHours.push({
                            dayOfWeek: i.toString(),
                            operatingHour: vm.notSet
                        });
                    }
                }

                tempOperatingHours = _.orderBy(tempOperatingHours, ['dayOfWeek']);

                _.map(tempOperatingHours, function (schedule) {
                    var dayOfWeek = _.some(operatingHours, function (hour) {
                        return hour.dayOfWeek === schedule.dayOfWeek;
                    })
                        ? -1 // For displaying empty day if a day have more than 1 opening hour set.
                        : schedule.dayOfWeek;

                    operatingHours.push({
                        dayOfWeek: dayOfWeek,
                        operatingHour: schedule.operatingHour
                    });
                });

                vm.normalOperatingHoursGridOptions.data = operatingHours;

                // Format for edit

                vm.schedules = _.map(normalOperatingHours, function (operatingHours) {
                    return {
                        id: operatingHours.id,
                        days: operatingHours.days,
                        hours: _.map(operatingHours.hours, function (h) {
                            return {
                                openTime: moment(h.openTime).toDate(),
                                closeTime: moment(h.closeTime).toDate()
                            };
                        }),
                        isHours: operatingHours.isHours,
                        is24Hours: operatingHours.is24Hours,
                        isOpen: operatingHours.isOpen
                    };
                });
            }

            // End normal operating hours

            // Special operating hours

            function processSpecialOperatingHours(specialOperatingHours) {
                var operatingHours = [];
                _.map(specialOperatingHours, function (oh) {
                    if (oh.isPublicHoliday && oh.id === 0) {
                        operatingHours.push({
                            day: oh.daysName,
                            date: moment(oh.specialHourStartDate).format('L'),
                            operatingHour: vm.notSet
                        });
                    } else if (oh.hours.length > 0) {
                        _.map(oh.hours, function (h) {
                            var openingHours = oh.is24Hours
                                ? open24Hours
                                : oh.isOpen
                                    ? moment(h.openTime).format('LT') + ' - ' + moment(h.closeTime).format('LT')
                                    : closed;
                            operatingHours.push({
                                day: oh.daysName,
                                date: oh.isPublicHoliday
                                    ? moment(oh.specialHourStartDate).format('L')
                                    : moment(oh.specialHourStartDate).format('L') + ' - ' + moment(oh.specialHourEndDate).format('L'),
                                operatingHour: openingHours
                            });
                        });
                    } else {
                        operatingHours.push({
                            day: oh.daysName,
                            date: oh.isPublicHoliday
                                ? moment(oh.specialHourStartDate).format('L')
                                : moment(oh.specialHourStartDate).format('L') + ' - ' + moment(oh.specialHourEndDate).format('L'),
                            operatingHour: oh.is24Hours
                                ? open24Hours
                                : closed
                        });
                    }
                });

                vm.specialOperatingHoursGridOptions.data = operatingHours;

                // Format for edit

                vm.specialHours = _.map(specialOperatingHours, function (operatingHours) {
                    return {
                        id: operatingHours.id,
                        daysName: operatingHours.daysName,
                        specialHourDate: {
                            startDate: operatingHours.specialHourStartDate,
                            endDate: operatingHours.specialHourEndDate
                        },
                        specialHourStartDate: operatingHours.specialHourStartDate,
                        specialHourEndDate: operatingHours.specialHourEndDate,
                        isPublicHoliday: operatingHours.isPublicHoliday,
                        hours: _.map(operatingHours.hours, function (h) {
                            return {
                                openTime: moment(h.openTime).toDate(),
                                closeTime: moment(h.closeTime).toDate()
                            };
                        }),
                        isHours: operatingHours.id === 0 ? false : operatingHours.isHours,
                        is24Hours: operatingHours.is24Hours,
                        isOpen: operatingHours.isOpen
                    };
                });
            }

            // End special operating hours

            function saveOperatingHours() {
                vm.loading++;
                vm.error = false;

                cleanUpSchedules();
                cleanUpSpecialHours();

                if (vm.error) {
                    vm.loading--;
                    return;
                }

                var input = {
                    panelLocationId: vm.panelLocationId,
                    normalOperatingHours: vm.schedules,
                    specialHours: vm.specialHours
                };

                operatingHourSvc.createOrUpdatePanelLocationOperationHours(input)
                    .success(function () {
                        vm.operatingHoursEditMode = !vm.operatingHoursEditMode;
                    }).finally(function (e) {
                        getOperatingHours();
                        vm.loading--;
                    });
            }

            function cleanUpSchedules() {
                // validate error

                if (vm.schedules.error) {
                    vm.error = true;
                    abp.notify.error(App.localize('DuplicateDaysNaration'));
                    return;
                }

                if (_.some(_.map(vm.schedules, x => x.hours), function (hour) { return hour.error; })) {
                    vm.error = true;
                    abp.notify.error(App.localize('SameHourNarration'));
                    return;
                }

                // remove not set schedule

                vm.schedules = _.filter(vm.schedules, x => _.some(x.days) && (x.isHours || x.is24Hours || !x.isOpen));
            }

            function cleanUpSpecialHours() {
                // Replace start date and end date

                vm.specialHours = _.map(vm.specialHours, function (specialHour) {
                    specialHour.specialHourStartDate = moment(specialHour.specialHourDate.startDate).format('L');
                    specialHour.specialHourEndDate = moment(specialHour.specialHourDate.endDate).format('L');
                    return specialHour;
                });

                // validate error

                if (_.some(_.map(vm.specialHours, x => x.hours), function (hour) { return hour.error; })) {
                    vm.error = true;
                    abp.notify.error(App.localize('SameHourNarration'));
                    return;
                }

                // remove not set special hours

                vm.specialHours = _.filter(vm.specialHours, x => x.specialHourStartDate && x.specialHourEndDate && (x.isHours || x.is24Hours || !x.isOpen));
            }

            function cancelEdit() {
                vm.operatingHoursEditMode = false;
                getOperatingHours();
            }

            init();
        }
    ];

    var app = angular.module('app');
    app.component('operatingHours', {
        templateUrl: require('/App/tenant/views/settings/operatingHours/operatingHours.component.html'),
        controller: operatingHours,
        controllerAs: 'vm'
    });
})();
