(function () {
    var controllerId = 'revenue.views.subscriptions.assignSubscriptionAgent.assignSubscriptionAgentModal';
    var module = angular.module('revenue');
    module.controller(controllerId, [
        '$uibModalInstance', 'abp.services.revenue.subscription', 'subscription',
        function ($uibModalInstance, subscriptionSvc, subscription) {
            function refreshInputModel(agents) {
                const input = {
                    salesPersonId: null,
                    salesPersonMode: 0,
                    salesOverridingManagerId: null
                };

                _.forEach(agents, function (agent) {
                    switch (agent.mode) {
                        case 0:
                        case 1:
                            input.salesPersonId = agent.agentId;
                            input.salesPersonMode = agent.mode;
                            break;
                        case 2:
                            input.salesOverridingManagerId = agent.agentId;
                            break;
                    }
                });

                vm.input = input;
            }

            function getAgentName(agentId) {
                if (agentId) {
                    const agent = _.find(vm.agents, { 'value': agentId });
                    return agent ? agent.name : agentId;
                }
                return App.localize('None');
            }

            function init() {
                vm.loading = true;
                subscriptionSvc.getSubscriptionAgentsForEdit({
                    id: subscription.id
                }).success(function (data) {
                    refreshInputModel(data.subscriptionAgent.agents);

                    vm.agents = _.sortBy(data.agents, 'name');
                    vm.editSalesPerson = !data.hasStarted;

                    vm.salesPersonName = getAgentName(vm.input.salesPersonId);
                    vm.salesOverridingManagerName = getAgentName(vm.input.salesOverridingManagerId);
                }).finally(function () {
                    vm.loading = false;
                });
            }

            function getServiceInput() {
                const input = {
                    id: vm.subscription.id,
                    agents: []
                };
                if (vm.input.salesPersonId) {
                    input.agents.push({ agentId: vm.input.salesPersonId, mode: vm.input.salesPersonMode });
                }
                if (vm.input.salesPersonMode === 0 && vm.input.salesOverridingManagerId) {
                    input.agents.push({ agentId: vm.input.salesOverridingManagerId, mode: 2 });
                }
                return input;
            }

            const vm = this;
            vm.loading = false;
            vm.saving = false;
            vm.subscription = subscription;
            vm.agents = [];

            vm.canBypassCommissionChecks = abp.auth.isGranted(
                'Hms.Revenue.Corporate.AssignAgent.BypassCommissionChecks');
            vm.editSalesPerson = false;

            vm.save = function () {
                if (vm.saving) return;
                vm.saving = true;

                const input = getServiceInput();
                subscriptionSvc.updateSubscriptionAgents(input)
                    .success(function (data) {
                        abp.notify.info(App.localize("SuccessfullySaved"));
                        $uibModalInstance.close(data);
                    }).finally(function () {
                        vm.saving = false;
                    });
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            vm.bypassCommissionChecks = function () {
                abp.message.confirm(App.localize('AssignAgentBypassCommissionChecksWarning'), App.localize('AreYouSure'), function (result) {
                    if (result) {
                        vm.editSalesPerson = true;
                    }
                });
            };

            init();
        }
    ]);
})();
