(() => {
  angular.module('app').filter('oldSubscriptionCharges', oldSubscriptionCharges);

  oldSubscriptionCharges.$inject = ['currencyFormatFilter'];

  function oldSubscriptionCharges(currencyFormat) {
    return oldSubscriptionChargesFilter;

    function oldSubscriptionChargesFilter(charges, currencyCode, chargingBasis, frequency) {
      let price = currencyFormat(charges, currencyCode);

      switch (chargingBasis) {
        case 1:
          price += ` / ${App.localize('Headcount')}`;
          break;
        case 2:
          price += ` / ${App.localize('PremiumHeadcount')}`;
          break;
        // no default
      }

      switch (frequency) {
        case 0:
          price += ` / ${App.localize('Once')}`;
          break;
        case 1:
          price += ` / ${App.localize('Month')}`;
          break;
        case 2:
          price += ` / ${App.localize('XMonths', 2)}`;
          break;
        case 3:
          price += ` / ${App.localize('XMonths', 3)}`;
          break;
        case 4:
          price += ` / ${App.localize('XMonths', 4)}`;
          break;
        case 5:
          price += ` / ${App.localize('XMonths', 6)}`;
          break;
        case 6:
          price += ` / ${App.localize('Year')}`;
          break;
        // no default
      }

      return price;
    }
  }
})();
