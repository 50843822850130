(() => {
  angular.module('app').component('commonGuaranteeLettersDetailsDisciplineTreatmentsWidget', {
    templateUrl: require('./widget.html'),
    controller: DisciplineTreatmentWidgetController,
    controllerAs: 'vm',
    bindings: {
      letterNumber: '<?',
    },
  });

  DisciplineTreatmentWidgetController.$inject = [
    '$stateParams',
    'abp.services.app.guaranteeLetter',
    '$rootScope',
  ];

  function DisciplineTreatmentWidgetController($stateParams, guaranteeLetterSvc, $root) {
    const vm = this;

    const elementId = 'specialistTreatmentTree';
    const typicallyUncoveredUrl = $root.resolveAppPath(
      'Print/TypicallyUncoveredSpecialistTreatments'
    );
    vm.loading = 0;
    vm.filter = null;

    vm.search = search;

    function init() {
      getDisciplineTreatments();
    }

    init();

    function getDisciplineTreatments() {
      vm.loading += 1;
      guaranteeLetterSvc
        .getDisciplineTreatments({
          id: $stateParams.letterNumber || vm.letterNumber,
        })
        .success((data) => {
          vm.disciplineTreatments = data.items;
          vm.excludeTypicallyUncoveredSpecialistTreatments =
            data.excludeTypicallyUncoveredSpecialistTreatments;
          buildTreatmentTree();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function buildTreatmentTree() {
      const element = $(`#${elementId}`);
      const ref = $.jstree.reference(element);
      if (ref) {
        ref.destroy();
      }

      const coveredNodeIcon = '<i class="fas fa-check text-navy"></i> ';
      const uncoveredNodeIcon = '<i class="fas fa-times text-danger"></i> ';
      function getNodeText(data) {
        return (data.isCovered ? coveredNodeIcon : uncoveredNodeIcon) + data.displayName;
      }

      const treatments = _.map(vm.disciplineTreatments, (record) => ({
        id: record.id,
        parent: record.parentId ? record.parentId : '#',
        displayName: record.displayName,
        text: getNodeText(record),
        state: {
          opened: record.hasUncoveredChildren,
        },
      }));

      const typicallyUncoveredIcon = vm.excludeTypicallyUncoveredSpecialistTreatments
        ? uncoveredNodeIcon
        : coveredNodeIcon;
      treatments.unshift({
        id: -1,
        parent: '#',
        text: `${typicallyUncoveredIcon}<strong>Typically uncovered specialist treatments</strong> <a>(View)</a>`,
        a_attr: { href: typicallyUncoveredUrl },
      });

      element
        .jstree({
          core: {
            data: treatments,
          },
          types: {
            default: {
              icon: 'fas fa-folder tree-item-icon-color icon-lg',
            },
            file: {
              icon: 'fas fa-file tree-item-icon-color icon-lg',
            },
          },
          search: {
            fuzzy: false,
            show_only_matches: true,
            show_only_matches_children: true,
          },
          sort(itemA, itemB) {
            const nodeA = this.get_node(itemA);
            const nodeB = this.get_node(itemB);
            if (nodeB.original.id === -1) {
              return 1;
            }
            if (nodeA.children_d.length > 0 && nodeB.children_d.length === 0) {
              return -1;
            }

            return nodeB.original.displayName < nodeA.original.displayName ? 1 : -1;
          },
          plugins: ['types', 'search', 'sort'],
        })
        .bind('select_node.jstree', (_e, data) => {
          const { href } = data.node.a_attr;
          if (href === '#') return '';

          window.open(href);
          return '';
        });
    }

    function search() {
      const element = $(`#${elementId}`);
      element.jstree('search', _.trim(vm.filter));
    }
  }
})();
