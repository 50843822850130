(() => {
  angular
    .module('app')
    .component('commonViewsPatientTicketsEditMemberUtilizationComponentBenefitCoverageComponent', {
      templateUrl: require('./benefitCoverage.component.html'),
      controller: BenefitCoverageController,
      controllerAs: 'vm',
      bindings: {
        letterNumber: '<',
        coPay: '=',
        roomAndBoardCharges: '=',
        ticketNumber: '<',
      },
    });

  BenefitCoverageController.$inject = [
    '$sce',
    '$filter',
    'abp.services.app.hostServiceRequest',
    'abp.services.app.memberUtilization',
  ];

  function BenefitCoverageController($sce, $filter, hostServiceRequestSvc, memberUtilizationSvc) {
    const vm = this;

    vm.currencySymbol = abp.setting.get('Hms.General.CurrencySymbol');
    vm.defaultMaxAllowance = parseFloat(abp.setting.get('Hms.General.MaxAllowanceAmountLimit'));
    vm.specifiedIllnessTooltip = $sce.trustAsHtml(App.localize('SpecifiedIllnessTooltip'));
    vm.hasSobEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');
    const hospitalizationTitle = App.localize('Hospitalization');
    const maternityTitle = App.localize('Maternity');

    init();

    function init() {
      getEmployeeBenefit();

      getEmployeeBenefits();
    }

    function getEmployeeBenefits() {
      // Getting employee's benefit coverage

      memberUtilizationSvc.getEmployeeBenefitCoverage(vm.ticketNumber).success((data) => {
        vm.mainBenefit = data.mainBenefitGroup;
        vm.hospitalizationSetting = data.hospitalizationSetting;
        vm.maternitySetting = data.maternitySetting;

        const { deliverySetting } = data.maternitySetting;
        const { preNatalSetting } = data.maternitySetting;
        const { postNatalSetting } = data.maternitySetting;
        const { neoNatalSetting } = data.maternitySetting;

        vm.maternitySob = [
          {
            header: App.localize('Delivery'),
            settings: [
              {
                name: App.localize('NormalDelivery'),
                isCovered: deliverySetting.normalDelivery.isCovered,
                isAsCharged: deliverySetting.normalDelivery.isAsCharged,
                amount: deliverySetting.normalDelivery.amount,
                effectivePeriod:
                  deliverySetting.normalDelivery.coveredCycle === 0 ? 'Per annum' : 'Per delivery',
              },
              {
                name: App.localize('CaesareanDelivery'),
                isCovered: deliverySetting.caesareanDelivery.isCovered,
                isAsCharged: deliverySetting.caesareanDelivery.isAsCharged,
                amount: deliverySetting.caesareanDelivery.amount,
                effectivePeriod:
                  deliverySetting.caesareanDelivery.coveredCycle === 0
                    ? 'Per annum'
                    : 'Per delivery',
              },
              {
                name: App.localize('NormalDeliveryForTwinsAndAbove'),
                isCovered: deliverySetting.normalDeliveryTwinsAndAbove.isCovered,
                isAsCharged: deliverySetting.normalDeliveryTwinsAndAbove.isAsCharged,
                amount: deliverySetting.normalDeliveryTwinsAndAbove.amount,
                effectivePeriod:
                  deliverySetting.normalDeliveryTwinsAndAbove.coveredCycle === 0
                    ? 'Per annum'
                    : 'Per delivery',
              },
              {
                name: App.localize('CaesareanDeliveryForTwinsAndAbove'),
                isCovered: deliverySetting.caesareanDeliveryTwinsAndAbove.isCovered,
                isAsCharged: deliverySetting.caesareanDeliveryTwinsAndAbove.isAsCharged,
                amount: deliverySetting.caesareanDeliveryTwinsAndAbove.amount,
                effectivePeriod:
                  deliverySetting.caesareanDeliveryTwinsAndAbove.coveredCycle === 0
                    ? 'Per annum'
                    : 'Per delivery',
              },
            ],
            hasEffectivePeriod: true,
          },
          {
            header: App.localize('PreNatalCheckUp'),
            isCovered: preNatalSetting.preNatalCheckUp.isCovered,
            coveredDaysHeader: preNatalSetting.preNatalCheckUp.coveredDays,
            settings: [
              {
                name: App.localize('PreNatalCheckUp'),
                isCovered: preNatalSetting.preNatalCheckUp.isCovered,
                isAsCharged: preNatalSetting.preNatalCheckUp.isAsCharged,
                amount: preNatalSetting.preNatalCheckUp.amount,
                effectivePeriod: 'Per annum',
              },
            ],
            hasEffectivePeriod: true,
          },
          {
            header: App.localize('PostNatalFollowUp'),
            isCovered: postNatalSetting.postNatalFollowUp.isCovered,
            coveredDaysHeader: postNatalSetting.postNatalFollowUp.coveredDays,
            settings: [
              {
                name: App.localize('PostNatalFollowUp'),
                isCovered: postNatalSetting.postNatalFollowUp.isCovered,
                isAsCharged: postNatalSetting.postNatalFollowUp.isAsCharged,
                amount: postNatalSetting.postNatalFollowUp.amount,
                effectivePeriod: 'Lifetime total',
              },
              {
                name: App.localize('Miscarriage'),
                isCovered: postNatalSetting.miscarriage.isCovered,
                isAsCharged: postNatalSetting.miscarriage.isAsCharged,
                amount: postNatalSetting.miscarriage.amount,
                effectivePeriod: null,
              },
            ],
            hasEffectivePeriod: true,
            pregnancyRelatedComplications: [
              'Disseminated Intravascular Coagulation',
              'Molar Pregnancy',
              'Eclampsia',
              'Death of Foetus',
              'Amniotic Fluid Embolism',
            ],
          },
          {
            header: App.localize('NeoNatal'),
            isCovered: neoNatalSetting.neoNatal.isCovered,
            coveredDaysHeader: neoNatalSetting.neoNatal.coveredDays,
            settings: [
              {
                name: App.localize('NewBornNICUHDU'),
                isCovered: neoNatalSetting.neoNatal.isCovered,
                isAsCharged: neoNatalSetting.neoNatal.isAsCharged,
                amount: neoNatalSetting.neoNatal.newBornNicuAmount,
                effectivePeriod: 'Per annum',
              },
              {
                name: App.localize('NewBornIncubation'),
                isCovered: neoNatalSetting.neoNatal.isCovered,
                isAsCharged: neoNatalSetting.neoNatal.isAsCharged,
                amount: neoNatalSetting.neoNatal.newBornIncubationAmount,
                effectivePeriod: 'Per annum',
              },
              {
                name: App.localize('NewBornPhototherapyTreatment'),
                isCovered: neoNatalSetting.neoNatal.isCovered,
                isAsCharged: neoNatalSetting.neoNatal.isAsCharged,
                amount: neoNatalSetting.neoNatal.newBornPhotoTherapyAmount,
                effectivePeriod: 'Per annum',
              },
            ],
            hasEffectivePeriod: true,
          },
        ];

        vm.mainBenefit.allowance = Math.min(
          data.mainBenefitGroup.allowance,
          vm.defaultMaxAllowance
        );
      });
    }

    function getEmployeeBenefit() {
      vm.loading += 1;
      hostServiceRequestSvc
        .getEmployeeBenefitDetails({
          id: vm.letterNumber,
        })
        .success((data) => {
          vm.isLoading = true;
          vm.isOpen = true;
          vm.benefit = data;
          vm.benefit.isMaternity = vm.benefit.serviceType === 8;

          if (vm.benefit.serviceType === 7) {
            const inpatientSettings =
              vm.benefit.employeeType === 0
                ? vm.benefit.hospitalizationSetting.principalScheduleOfBenefit
                : vm.benefit.hospitalizationSetting.dependentScheduleOfBenefit;

            vm.benefit.coPay = null;
            if (vm.benefit.hospitalizationCoPay) {
              if (vm.benefit.hospitalizationCoPay.isPercentage) {
                if (vm.benefit.hospitalizationSetting.isCoInsurance) {
                  vm.benefit.coPay = {
                    title: App.localize(
                      'CoInsuranceX',
                      `${vm.benefit.hospitalizationCoPay.value}%`
                    ),
                    text: App.localize(
                      'CopayPercentage',
                      `${vm.benefit.hospitalizationCoPay.value}%`
                    ),
                  };
                } else if (!vm.benefit.hospitalizationSetting.isCoInsurance) {
                  vm.benefit.coPay = {
                    title: App.localize('CopayX', `${vm.benefit.hospitalizationCoPay.value}%`),
                    text: App.localize(
                      'CopayPercentage',
                      `${vm.benefit.hospitalizationCoPay.value}%`
                    ),
                  };
                }
                vm.coPay.isPercentage = true;
              } else {
                const copayHospitalizationText = $filter('currency')(
                  vm.benefit.hospitalizationCoPay.value,
                  vm.currencySymbol
                );
                vm.benefit.coPay = {
                  title: App.localize('CopayX', copayHospitalizationText),
                  text: App.localize('CopayFixedAmount', copayHospitalizationText),
                };
                vm.coPay.isPercentage = false;
              }

              vm.coPay.isCoInsurance = vm.benefit.hospitalizationSetting.isCoInsurance;
              vm.coPay.value = vm.benefit.hospitalizationCoPay.value;
            }

            vm.benefit.allowance = Math.min(
              vm.benefit.hospitalizationSetting.allowance,
              vm.defaultMaxAllowance
            );
            vm.benefit.hospitalizationBenefits =
              vm.benefit.hospitalizationSetting.hospitalizationBenefits;
            vm.hospitalizationBenefits = vm.benefit.hospitalizationBenefits;
            vm.benefit.displayBalancePool = [];

            if (
              vm.benefit.hospitalizationSetting.balancePool &&
              vm.benefit.hospitalizationSetting.balancePool.length > 0
            ) {
              _.each(vm.benefit.hospitalizationSetting.balancePool, (pool) => {
                getDisplayBalancePool(pool, vm.benefit.displayBalancePool);
              });
            }

            vm.isAccomodationDaysCombined = inpatientSettings.accomodationDaysCombined;
            vm.roomAndBoardAccomodationDays = inpatientSettings.roomAndBoard.accomodationDays;
            vm.icuRoomAndBoardAccomodationDays = inpatientSettings.icuRoomAndBoard.accomodationDays;

            vm.physicianVisitPerDay =
              inpatientSettings.doctorCharges.inHospitalPhysicianVisitPerDay;
            vm.physicianDaysPerYear = inpatientSettings.doctorCharges.coveredDays;
            vm.surgicalCharges = inpatientSettings.hospitalCharges;
            vm.surgicalFee = inpatientSettings.hospitalCharges.surgicalFee;
            vm.anaesthetistFee = inpatientSettings.hospitalCharges.anaesthetistFee;
            vm.operatingTheatre = inpatientSettings.hospitalCharges.operatingTheatre;
            vm.hospitalSuppliesAndServices =
              inpatientSettings.hospitalCharges.hospitalSuppliesAndServices;
            vm.ambulanceFee = inpatientSettings.ambulanceFee.amount;
            vm.organTransplant = inpatientSettings.organTransplant;
            vm.medicalReport = inpatientSettings.medicalReport;

            vm.preHospitalizationTreatment = inpatientSettings.preHospitalizationTreatment;
            vm.specialistConsultation = inpatientSettings.specialistConsultation;
            vm.surgicalOpinion = inpatientSettings.surgicalOpinion;
            vm.postHospitalizationTreatment = inpatientSettings.postHospitalizationTreatment;
            vm.followUpTreatment = inpatientSettings.followUpTreatment;
            vm.outpatientCancerTreatment = inpatientSettings.outpatientCancerTreatment;
            vm.outpatientKidneyDialysisTreatment =
              inpatientSettings.outpatientKidneyDialysisTreatment;
            vm.roomAndBoardCharges = inpatientSettings.roomAndBoard.amount;

            vm.benefit.inpatientSob = [];
            vm.benefit.inpatientSob.push({
              type: App.localize('Accomodation'),
              settings: [
                {
                  name: App.localize('RoomAndBoard'),
                  isCovered: true,
                  isAsCharged: false,
                  amount: inpatientSettings.roomAndBoard.amount,
                  effectivePeriod: 'Per day',
                  daysPerYearLimit: vm.roomAndBoardAccomodationDays,
                  isAccomodationDaysCombined: vm.isAccomodationDaysCombined,
                  accomodationDaysCombineText: App.localize('CombinedRoomAndBoardNarrative'),
                },
                {
                  name: App.localize('IcuRoomAndBoard'),
                  isCovered: true,
                  isAsCharged: inpatientSettings.icuRoomAndBoard.isAsCharged,
                  amount: inpatientSettings.icuRoomAndBoard.amount,
                  effectivePeriod: 'Per day',
                  daysPerYearLimit: vm.icuRoomAndBoardAccomodationDays,
                  isAccomodationDaysCombined: vm.isAccomodationDaysCombined,
                  accomodationDaysCombineText: App.localize('CombinedIcuRoomAndBoardNarrative'),
                },
                {
                  name: App.localize('ChildDailyGuardianBenefitLodgingOrBed'),
                  isCovered: inpatientSettings.childGuardianBenefit.isCovered,
                  isAsCharged: inpatientSettings.childGuardianBenefit.isAsCharged,
                  amount: inpatientSettings.childGuardianBenefit.amount,
                  effectivePeriod: 'Per day',
                },
              ],
              hasMaxDaysTime: false,
              hasEffectivePeriod: true,
              hasDaysPerYear: vm.hasSobEnhancement,
            });

            if (vm.hasSobEnhancement) {
              vm.benefit.inpatientSob.push({
                type: App.localize('DoctorCharges'),
                settings: [
                  {
                    name: App.localize('InHospitalDoctorVisit'),
                    isCovered: true,
                    isAsCharged: true,
                    amount: null,
                    effectivePeriod: 'Per day',
                    maxDaysTime: vm.physicianVisitPerDay,
                    isThirteenthSchedule: true,
                    daysPerYearLimit: vm.physicianDaysPerYear,
                  },
                ],
                hasMaxDaysTime: true,
                hasEffectivePeriod: true,
                hasDaysPerYear: vm.hasSobEnhancement,
              });
            }

            vm.benefit.inpatientSob.push({
              type: App.localize('HospitalCharges'),
              isFixedLimit: vm.surgicalCharges.isFixedLimit,
              fixedLimitAmount: vm.surgicalCharges.fixedLimitAmount,
              settings: {
                physicianVisit: {
                  isAsCharged: true,
                  amount: null,
                  effectivePeriod: 'Per day',
                  maxDaysTime: vm.physicianVisitPerDay,
                },
                surgicalFees: {
                  isAsCharged: vm.surgicalFee.isAsCharged,
                  amount: vm.surgicalFee.amount,
                  effectivePeriod: 'Per day',
                  maxDaysTime: false,
                },
                anaesthetistFees: {
                  isAsCharged: vm.anaesthetistFee.isAsCharged,
                  amount: vm.anaesthetistFee.amount,
                  effectivePeriod: 'Per day',
                  maxDaysTime: false,
                },
                operatingTheatre: {
                  isAsCharged: vm.operatingTheatre.isAsCharged,
                  amount: vm.operatingTheatre.amount,
                  effectivePeriod: 'Per day',
                  maxDaysTime: false,
                },
                suppliesAndServices: {
                  isAsCharged: vm.hospitalSuppliesAndServices.isAsCharged,
                  amount: vm.hospitalSuppliesAndServices.amount,
                  effectivePeriod: 'Per day',
                  maxDaysTime: false,
                },
                ambulanceFee: {
                  isCovered: vm.ambulanceFee.isCovered,
                  isAsCharged: vm.ambulanceFee.isAsCharged,
                  amount: vm.ambulanceFee.amount,
                  effectivePeriod: 'Per day',
                  maxDaysTime: false,
                },
                organTransplant: {
                  isCovered: vm.organTransplant.isCovered,
                  isAsCharged: vm.organTransplant.isAsCharged,
                  amount: vm.organTransplant.amount,
                  effectivePeriod: 'Per employment contract',
                  maxDaysTime: vm.organTransplant.isUnlimited
                    ? App.localize('Unlimited')
                    : vm.organTransplant.coveredTimes,
                },
              },
              hasMaxDaysTime: false,
              hasEffectivePeriod: true,
            });

            vm.benefit.inpatientSob.push({
              type: App.localize('SelfPayHospitalization'),
              settings: [
                {
                  name: App.localize('MedicalReport'),
                  isCovered: true,
                  isAsCharged: vm.medicalReport.isAsCharged,
                  amount: vm.medicalReport.amount,
                  effectivePeriod: null,
                },
              ],
              hasMaxDaysTime: false,
              hasEffectivePeriod: false,
            });

            vm.benefit.outpatientSob = [
              {
                type: App.localize('PreHospitalizationTreatment'),
                settings: [
                  {
                    name: App.localize('PreHospitalizationDiagnosisTest'),
                    isCovered: vm.preHospitalizationTreatment.isCovered,
                    isAsCharged: true,
                    amount: null,
                    effectivePeriod: App.localize('PerAdmission'),
                    maxDaysTime: null,
                  },
                  {
                    name: App.localize('PreHospitalizationSpecialistConsultation'),
                    isCovered: vm.preHospitalizationTreatment.isCovered,
                    isAsCharged: true,
                    amount: null,
                    effectivePeriod: App.localize('PerAdmission'),
                    maxDaysTime: vm.specialistConsultation.isUnlimited
                      ? 'Unlimited'
                      : vm.specialistConsultation.coveredTimes,
                  },
                  {
                    name: App.localize('SecondSurgicalOpinion'),
                    isCovered: vm.preHospitalizationTreatment.isCovered,
                    isAsCharged: vm.surgicalOpinion.isAsCharged,
                    amount: vm.surgicalOpinion.amount,
                    effectivePeriod: App.localize('PerAdmission'),
                    maxDaysTime: vm.surgicalOpinion.isUnlimited
                      ? 'Unlimited'
                      : vm.surgicalOpinion.coveredTimes,
                  },
                ],
                hasMaxDaysTime: !!vm.preHospitalizationTreatment.isCovered,
                hasEffectivePeriod: true,
                coveredDays: vm.preHospitalizationTreatment.coveredDays,
              },
              {
                type: App.localize('PostHospitalizationTreatment'),
                settings: [
                  {
                    name: App.localize('PostHospitalizationFollowUpAndTreatment'),
                    isCovered: vm.postHospitalizationTreatment.isCovered,
                    isAsCharged: vm.followUpTreatment.isAsCharged,
                    amount: vm.followUpTreatment.amount,
                    effectivePeriod: 'Per admission',
                  },
                ],
                hasMaxDaysTime: false,
                hasEffectivePeriod: true,
                coveredDays: vm.followUpTreatment.coveredDays,
              },
              {
                type: App.localize('Others'),
                settings: [
                  {
                    name: App.localize('OutpatientCancerTreatment'),
                    isCovered: vm.outpatientCancerTreatment.isCovered,
                    isAsCharged: false,
                    amount: vm.outpatientCancerTreatment.amount,
                    effectivePeriod: 'Per annum',
                  },
                  {
                    name: App.localize('OutpatientKidneyDialysisTreatment'),
                    isCovered: vm.outpatientKidneyDialysisTreatment.isCovered,
                    isAsCharged: false,
                    amount: vm.outpatientKidneyDialysisTreatment.amount,
                    effectivePeriod: 'Per annum',
                  },
                ],
                hasMaxDaysTime: false,
                hasEffectivePeriod: true,
              },
            ];
          } else {
            const { maternitySetting } = data;

            vm.benefit.normalDelivery = maternitySetting.normalDelivery;
            vm.benefit.caesareanDelivery = maternitySetting.caesareanDelivery;
            vm.benefit.normalDeliveryTwinsAndAbove = maternitySetting.normalDeliveryTwinsAndAbove;
            vm.benefit.caesareanDeliveryTwinsAndAbove =
              maternitySetting.caesareanDeliveryTwinsAndAbove;
            vm.benefit.preNatalCheckUp = maternitySetting.preNatalCheckUp;
            vm.benefit.postNatalFollowUp = maternitySetting.postNatalFollowUp;
            vm.benefit.miscarriage = maternitySetting.miscarriage;
            vm.benefit.neoNatal = maternitySetting.neoNatal;

            vm.benefit.coPay = null;
            if (vm.benefit.maternityCoPay) {
              if (vm.benefit.maternityCoPay.isPercentage) {
                vm.benefit.coPay = {
                  title: App.localize('CopayX', `${vm.benefit.maternityCoPay.value}%`),
                  text: App.localize('CopayPercentage', `${vm.benefit.maternityCoPay.value}%`),
                };
                vm.coPay.isPercentage = true;
              } else {
                const copayMaternityText = $filter('currency')(
                  vm.benefit.maternityCoPay.value,
                  vm.currencySymbol
                );
                vm.benefit.coPay = {
                  title: App.localize('CopayX', copayMaternityText),
                  text: App.localize('CopayFixedAmount', copayMaternityText),
                };
                vm.coPay.isPercentage = false;
              }

              vm.coPay.isCoInsurance = maternitySetting.isCoInsurance;
              vm.coPay.value = vm.benefit.maternityCoPay.value;
            }

            vm.benefit.allowance = Math.min(maternitySetting.allowance, vm.defaultMaxAllowance);
            vm.benefit.displayBalancePool = [];

            if (maternitySetting.balancePool && maternitySetting.balancePool.length > 0) {
              _.each(maternitySetting.balancePool, (pool) => {
                getDisplayBalancePool(pool, vm.benefit.displayBalancePool);
              });
            }
          }
          vm.loading -= 1;
        });
    }

    function getDisplayBalancePool(poolBalance, displayArray) {
      let title = '';

      if (_.head(poolBalance.benefitTypes).id === 7) title = hospitalizationTitle;
      else if (_.head(poolBalance.benefitTypes).id === 8) title = maternityTitle;

      // TODO: Better design? localisation required

      const isBudget = poolBalance.modifierType === 0 || poolBalance.modifierType === 2;
      let cycle = '';
      if (!poolBalance.isUnlimited) {
        switch (poolBalance.modifierCycle) {
          case 0:
            cycle = `every ${poolBalance.interval} years `;
            break;
          case 1:
            cycle = 'annual ';
            break;
          case 2:
            cycle = 'monthly ';
            break;
          case 3:
            cycle = 'daily ';
            break;
          case 4:
            cycle = 'per visit ';
            break;
          case 5:
            cycle = '6 months ';
            break;
          case 6:
            cycle = '4 months ';
            break;
          case 7:
            cycle = '3 months ';
            break;
          case 8:
            cycle = '2 months ';
            break;

          // no default
        }
        if (poolBalance.modifierType === 2 || poolBalance.modifierType === 3) cycle += 'overdraft ';
      }
      title += ` (${cycle}${isBudget ? 'budget' : 'visit'})`;

      const isEmptyExcess = poolBalance.isExcess && !poolBalance.employeePortion;
      if (!isEmptyExcess) {
        displayArray.push({
          title,
          isUnlimited: poolBalance.isUnlimited,
          isExcess: poolBalance.isExcess,
          isBudget,
          limit: poolBalance.limit,
          used: poolBalance.used,
          locked: poolBalance.locked,
          balance: poolBalance.balance,
        });
      }
    }
  }
})();
