(() => {
  angular
    .module('app')
    .controller(
      'common.views.saleTransactions.manualCreateExcessPaymentModal',
      InvoiceDetailSingaporePanelPageController
    );

  InvoiceDetailSingaporePanelPageController.$inject = [
    '$scope',
    '$uibModalInstance',
    'abp.services.app.saleTransaction',
  ];

  function InvoiceDetailSingaporePanelPageController(
    $scope,
    $uibModalInstance,
    saleTransactionSvc
  ) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;
    vm.subsidiaries = [];
    vm.paymentDate = null;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

    vm.subsidiaryId = null;
    vm.tenantId = null;

    vm.getTenant = getTenant;
    vm.disabled = disabled;
    vm.save = save;
    vm.cancel = cancel;

    init();

    function init() {
      if (App.isHost()) {
        vm.loading += 1;
        saleTransactionSvc
          .getInfoToCreateExcessPayment({})
          .success((data) => {
            vm.subsidiaries = data.subsidiariesWithTenantInfo;
            vm.paymentDate = data.paymentDate;
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    }

    function getTenant($item) {
      if ($item) {
        vm.tenantId = $item.tenantId;
      }
    }

    function disabled() {
      return (
        $scope.excessPaymentCreateForm.$invalid || vm.subsidiaryId === null || vm.tenantId === null
      );
    }

    function save() {
      abp.message.confirm(
        App.localize('ConfirmManualCreateExcessPaymentMessage'),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            vm.saving += 1;
            saleTransactionSvc
              .manualCreateExcessPayment({
                tenantId: vm.tenantId,
                subsidiaryId: vm.subsidiaryId,
                date: vm.paymentDate,
                remarks: vm.remarks,
                amount: vm.amount,
              })
              .success(() => {
                abp.notify.info(App.localize('SuccessfullyCreatedExcess'));
              })
              .finally(() => {
                vm.saving -= 1;
                $uibModalInstance.close();
              });
          }
        }
      );
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
