(function () {
    var controllerId = 'host.views.panelRecruitment.panelRequest';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$stateParams', 'abp.services.app.panelRequest',
        function ($stateParams, panelRequestSvc) {
            function init() {
                vm.getPanelRequestForEdit();
            }

            var vm = this;
            vm.loading = 0;
            vm.requestNumber = $stateParams.requestNumber;
            vm.panelRequestEditModel = null;
            vm.isEdit = false;

            vm.getPanelRequestForEdit = function () {
                panelRequestSvc.getPanelRequestForEdit({
                    id: $stateParams.requestNumber
                }).success(function (data) {
                    vm.panelRequestEditModel = data;

                    if (data) {
                        vm.requestNumber = data.request.requestNumber;
                        vm.panelName = data.request.panelName;
                    }
                });
            };

            init();
        }
    ]);
})();
