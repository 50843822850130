(() => {
  angular
    .module('finance')
    .controller(
      'finance.views.saleTransactions.generateFinanceReportsModal',
      GenerateFinanceReportsModalController
    );

  GenerateFinanceReportsModalController.$inject = [
    '$uibModalInstance',
    'abp.services.app.financeReport',
    'abp.services.app.commonLookup',
    'moment',
  ];

  function GenerateFinanceReportsModalController(
    $uibModalInstance,
    financeReportSvc,
    commonLookupSvc,
    moment
  ) {
    const vm = this;
    const organization = 'Organization';
    const panel = 'Panel';
    const member = 'Member';
    const agent = 'Agent';
    const insurer = 'Insurer';
    const licensingFees = 'License';
    const subscription = 'Subscription';

    vm.loading = 0;
    vm.saving = 0;
    vm.today = moment().startOf('day');
    vm.resetParams = resetParams;
    vm.hasInsurerFinanceReportEnabled = abp.setting.getBoolean('Hms.Feature.InsurerFinanceReport');

    vm.requestParams = {
      category: null,
      type: 1,
      accountOwnerId: null,
    };

    vm.categories = [];
    vm.categories.push({ name: App.localize('All'), value: null });
    vm.categories.push({ name: App.localize('Organization'), value: organization });
    vm.categories.push({ name: App.localize('Panel'), value: panel });
    vm.categories.push({ name: App.localize('Member'), value: member });
    vm.categories.push({ name: App.localize('Agent'), value: agent });
    vm.categories.push({ name: App.localize('Insurer'), value: insurer });
    vm.categories.push({ name: App.localize('LicensingFees'), value: licensingFees });
    vm.categories.push({ name: App.localize('Subscriptions'), value: subscription });

    vm.dateRangeOptions = App.createDateRangePickerOptions();
    vm.dateRangeModel = {
      startDate: moment().startOf('month'),
      endDate: moment().endOf('day'),
    };
    vm.transactionDateRangeModel = {
      startDate: moment().startOf('month'),
      endDate: moment().endOf('day'),
    };

    vm.save = save;
    vm.categorySelected = categorySelected;
    vm.cancel = cancel;
    vm.hasDate = hasDate;

    init();

    function init() {
      getAccountOwners();
      fillTypesByCategory();
      resetParams();
    }

    // Get the list of account owners to be displayed as options.

    function getAccountOwners() {
      vm.loading += 1;
      commonLookupSvc
        .getAccountOwners()
        .success((data) => {
          vm.accountOwners = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function save() {
      const input = $.extend(
        {
          transactionStartDate: vm.transactionDateRangeModel.startDate,
          transactionEndDate: vm.transactionDateRangeModel.endDate,
        },
        vm.requestParams,
        vm.dateRangeModel
      );

      vm.saving += 1;
      financeReportSvc
        .generateFinanceReport(input)
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function categorySelected() {
      fillTypesByCategory(vm.requestParams.type);
    }

    function fillTypesByCategory() {
      vm.types = [];
      vm.types.push({ name: 'AR INVOICE', value: 1 });
      vm.types.push({ name: 'AR PAYMENT', value: 2 });
      vm.types.push({ name: 'AR CN', value: 3 });
      vm.types.push({ name: 'AP INVOICE', value: 4 });
      vm.types.push({ name: 'AP PAYMENT', value: 5 });
      vm.types.push({ name: 'AP CN', value: 6 });
      vm.types.push({ name: 'NPR', value: 7 });

      if (vm.requestParams.category === organization || vm.requestParams.category === panel) {
        const organisationAndPanelFilteredTypes = vm.types.filter((x) => x.value < 7);
        vm.types = organisationAndPanelFilteredTypes;
        vm.requestParams.type = 1;
      }

      if (vm.requestParams.category === member) {
        const memberFilteredTypes = vm.types.filter((x) => x.value > 3);
        vm.types = memberFilteredTypes;
        vm.requestParams.type = 7;
      }

      if (vm.requestParams.category === agent) {
        const agentFilteredTypes = vm.types.filter((x) => x.value > 3 && x.value < 7);
        vm.types = agentFilteredTypes;
        vm.requestParams.type = 4;
      }

      if (
        vm.requestParams.category === insurer ||
        vm.requestParams.category === licensingFees ||
        vm.requestParams.category === subscription
      ) {
        const filteredTypes = vm.types.filter((x) => x.value < 4);
        vm.types = filteredTypes;
        vm.requestParams.type = 1;
      }
    }

    function resetParams() {
      vm.dateRangeModel = {
        startDate: null,
        endDate: null,
      };
      vm.transactionDateRangeModel = {
        startDate: null,
        endDate: null,
      };
    }

    function hasDate() {
      return vm.dateRangeModel.startDate || vm.dateRangeModel.endDate 
      || vm.transactionDateRangeModel.startDate || vm.transactionDateRangeModel.endDate;
    }
  }
})();
