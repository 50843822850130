(() => {
  angular.module('app').filter('prescriptionStatus', prescriptionStatus);

  prescriptionStatus.$inject = [];

  function prescriptionStatus() {
    return prescriptionStatusFilter;

    function prescriptionStatusFilter(value) {
      switch (value) {
        case 0:
          return App.localize('PrescriptionStatus_Draft');
        case 1:
          return App.localize('PrescriptionStatus_New');
        case 2:
          return App.localize('PrescriptionStatus_Acknowledged');
        case 3:
          return App.localize('PrescriptionStatus_PartiallyFulfilled');
        case 4:
          return App.localize('PrescriptionStatus_Fulfilled');
        default:
          return value;
      }
    }
  }
})();
