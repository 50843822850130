(() => {
  angular.module('finance').component('panelLicenseInvoiceDetailComponent', {
    templateUrl: require('./panelLicense.component.html'),
    controller: PanelLicenseInvoiceDetailComponent,
    controllerAs: 'vm',
    bindings: {
      currencyCode: '<',
      invoice: '<',
      invoiceFrom: '<',
      invoiceTo: '<',
      isAddressInvoiceFrom: '<',
      isAddressInvoiceTo: '<',
      message: '<',
      getDateString: '<'
    },
  });

  PanelLicenseInvoiceDetailComponent.$inject = [];

  function PanelLicenseInvoiceDetailComponent() {
    const vm = this;

    vm.loading = 0;

    init();

    function init() {}
  }
})();
