(() => {
  angular
    .module('app')
    .controller(
      'common.views.patientTickets.importManualTicketsResultModal',
      ImportManualTicketsResultModalController
    );

  ImportManualTicketsResultModalController.$inject = ['$uibModalInstance', 'errors'];

  function ImportManualTicketsResultModalController($uibModalInstance, errors) {
    const vm = this;

    vm.errorCountText = App.localize('ImportFileHasXIssues', errors.length);
    vm.errorText = errors.join('\n');

    vm.cancel = cancel;

    init();

    function init() {}

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
