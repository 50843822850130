(() => {
  const module = angular.module('finance');
  module.consts = {
      grid: {
          defaultPageSizes: [10, 20, 50, 100],
          defaultPageSize: 10
      },
  };
})();

(() => {
  angular.module('finance').constant('financeConstants', {
    grid: {
      defaultPageSizes: [10, 20, 50, 100],
      defaultPageSize: 10,
    },
  });
})();
