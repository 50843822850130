(() => {
  angular.module('app').component('attachmentsUploader', {
    templateUrl: require('./attachmentsUploader.html'),
    controller: AttachmentsUploaderController,
    controllerAs: 'vm',
    bindings: {
      attachments: '=',
      newAttachments: '=',
      options: '=',
      readonly: '=',
    },
  });

  AttachmentsUploaderController.$inject = ['FileUploader', 'appConstants', '$rootScope'];

  function AttachmentsUploaderController(FileUploader, appConstants, $root) {
    const vm = this;

    vm.uploading = 0;

    // File extensions.

    const imageExtensions = `|${appConstants.fileUpload.imageExtensions.join('|')}|`;
    const fileExtensions = `|${appConstants.fileUpload.imageExtensions
      .concat(appConstants.fileUpload.pdfExtensions, appConstants.fileUpload.docxExtensions)
      .join('|')}|`;

    // Configurations.

    const defaultConfig = {
      // Upload URL for new attachment.
      uploadUrl: $root.resolveAppPath('Temp/Upload'),

      // Download URL for existing attachment.
      downloadUrl(attachment) {
        return attachment.fullUrl;
      },

      previewUrl(attachment) {
        if (attachment.isImage) {
          return $root.resolveAppPath(`File/PreviewImage#${encodeURIComponent(attachment.fullUrl)}`);
        }
        return attachment.fullUrl;
      },

      // Download URL for temporarily uploaded attachment.
      tempUrl(attachment) {
        return attachment.resourceUrl;
      },

      // Object type.
      objectType: '',

      // Set to 0 for read-only, less than 0 for infinite.
      itemsLimit: -1,

      // Accept file types. Uses to specifies the accept file types.
      // This is not used for validation and file uploads should be validated on the Server.
      accept:
        'image/*,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.wordprocessingml.template',

      // Filters.
      filters: [
        {
          name: 'fileFilter',
          fn(n) {
            const t = `|${n.type.slice(n.type.lastIndexOf('/') + 1)}|`;
            if (fileExtensions.indexOf(t) === -1) {
              abp.message.warn(App.localize('File_Warn_FileType'));
              return false;
            }
            return true;
          },
        },
        {
          name: 'sizeFilter',
          fn(n) {
            const sizeLimit = 52428800;
            if (n.size > sizeLimit) {
              abp.message.warn(App.localize('File_Warn_XSizeLimit', sizeLimit / 1024 / 1024));
              return false;
            }
            return true;
          },
        },
      ],

      // TODO: Add content type restriction.
    };

    vm.config = angular.extend({}, defaultConfig, vm.options);

    // FileUploader object.

    vm.uploader = new FileUploader({
      url: vm.config.uploadUrl,
      headers: App.getFileUploaderHeaders(),
      formData: [{ objectType: vm.config.objectType }],
      queueLimit: 1,
      autoUpload: true,
      removeAfterUpload: true,
      filters: vm.config.filters,
      onAfterAddingFile() {
        vm.uploading += 1;
      },
      onCompleteItem(item, response) {
        vm.uploading -= 1;

        if (response.success) {
          const t = `|${item.file.type.slice(item.file.type.lastIndexOf('/') + 1)}|`;
          vm.newAttachments.push({
            originalFileName: response.result.originalFileName,
            fileName: response.result.fileName,
            resourceUrl: response.result.resourceUrl,
            isImage: imageExtensions.indexOf(t) >= 0,
          });

          return;
        }

        if (response.error && response.error.message) {
          abp.message.error(response.error.message.substring(0, 500), 'Opps!');
        } else if (typeof response === 'string' && response) {
          abp.message.error(response.substring(0, 500), 'Opps!');
        } else {
          abp.message.error(App.localize('File_Upload_Failed'));
        }
      },
    });

    vm.removeUpload = removeUpload;
    vm.remove = remove;
    vm.exceededLimit = exceededLimit;
    vm.hitLimit = hitLimit;

    init();

    function init() {}

    // Validation.

    function hitLimit() {
      const limit = vm.config.itemsLimit;
      if (limit < 0) return false;

      return getAttachmentsCount() >= limit;
    }

    function exceededLimit() {
      const limit = vm.config.itemsLimit;
      if (limit < 0) return false;

      return getAttachmentsCount() > limit;
    }

    function getAttachmentsCount() {
      const attachmentsLength = _.isArray(vm.attachments)
        ? _.filter(vm.attachments, (item) => !item.isDelete).length
        : 0;
      const newAttachmentsLength = _.isArray(vm.newAttachments) ? vm.newAttachments.length : 0;

      return attachmentsLength + newAttachmentsLength;
    }

    // Attachments management.

    function removeUpload($index) {
      vm.newAttachments.splice($index, 1);
    }

    function remove(attachment) {
      const a = attachment;

      a.isDelete = !a.isDelete;

      // Disallow deletion undo if number of attachments hits limit.

      if (!a.isDelete && vm.exceededLimit()) {
        a.isDelete = true;
      }
    }
  }
})();
