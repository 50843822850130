(function () {
    var controllerId = 'host.views.dailySalesStatistic.index';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', '$timeout', 'abp.services.app.report', 'moment',
        function ($scope, $state, $stateParams, $timeout, reportSvc, moment) {
            function init() {
                vm.getReport();
                $timeout(function () {
                    vm.gridOptions.paginationCurrentPage = Math.floor(vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1);
                });
            }

            var vm = this;
            vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

            $scope.$on("$viewContentLoaded", function () {
                App.initAjax();
            });

            vm.loading = false;
            vm.requestParams = {
                creationDate: $stateParams.creationDate || moment(),
                filter: $stateParams.filter,
                skipCount: $stateParams.skipCount,
                maxResultCount: $stateParams.maxResultCount,
                sorting: $stateParams.sorting
            };

            vm.gridOptions = {
                appScopeProvider: vm,
                paginationPageSizes: app.consts.grid.defaultPageSizes,
                paginationPageSize: app.consts.grid.defaultPageSize,
                paginationCurrentPage: 0,
                columnDefs: [{
                    displayName: App.localize("CreationDate"),
                    field: "creationDate",
                    cellFilter: "momentFormat: 'L'",
                    minWidth: 100
                }, {
                    displayName: App.localize("TicketNumber"),
                    field: "ticketNumber",
                    minWidth: 100
                }, {
                    displayName: App.localize("Patient"),
                    field: 'patient',
                    minWidth: 100
                }, {
                    displayName: App.localize("Corporate"),
                    field: 'corporate',
                    enableSorting: false,
                    minWidth: 100
                }, {
                    displayName: App.localize("Panel"),
                    field: 'clinic',
                    enableSorting: false,
                    minWidth: 100
                }, {
                    displayName: App.localize("Amount"),
                    field: "amount",
                    type: 'number',
                    minWidth: 100,
                    cellFilter: 'currencyFormat: grid.appScope.currencyCode'
                }],
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                    gridApi.pagination.on.paginationChanged($scope, App.setPagination(vm.requestParams, registerStateParams));
                },
                data: []
            };

            vm.getReport = function () {
                registerStateParams();
                vm.loading = true;

                reportSvc.getDailySalesStatistic(vm.requestParams)
                    .success(function (data) {
                        vm.gridOptions.totalItems = data.totalCount;
                        vm.gridOptions.data = data.items;
                        vm.totalTicketAmount = 0;
                        vm.totalTicketCount = 0;

                        _.forEach(data.items, function (itemLine) {
                            vm.totalTicketAmount += itemLine.amount;
                            vm.totalTicketCount++;
                        });
                    }).finally(function (e) {
                        vm.loading = false;
                    });
            };

            function registerStateParams() {
                $state.transitionTo($state.current,
                    {
                        creationDate: moment(vm.requestParams.creationDate).format("YYYY-MM-DD"),
                        filter: vm.requestParams.filter,
                        skipCount: vm.requestParams.skipCount,
                        maxResultCount: vm.requestParams.maxResultCount,
                        sorting: vm.requestParams.sorting
                    }, {
                    notify: false
                });
            }

            init();
        }
    ]);
})();
