(function () {
    var componentId = 'hostOperationsDashboardSpecialistClaimsComponent';
    var app = angular.module('app');

    function controller(operationsDashboardSvc, enumSpecialistClaimStatus, enumServiceRequestType, enumServiceRequestStatus) {
        function init() {
            vm.refresh();
        }

        var vm = this;
        vm.widgetName = componentId;
        vm.countryCode = null;
        vm.loading = false;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.hasSpecialistWorkflowEnhancement = abp.setting.getBoolean('Hms.Feature.SpecialistWorkflowEnhancement');

        vm.enums = {};
        vm.enums.specialistClaimStatus = enumSpecialistClaimStatus;
        vm.enums.serviceRequestType = enumServiceRequestType;
        vm.enums.serviceRequestStatus = enumServiceRequestStatus;

        vm.records = [];
        vm.requestParams = {
            filter: '',
            includesResolved: false,
            maxResultCount: 5,
            skipCount: 0,
            countryCode: vm.countryCode
        };

        vm.$onInit = function () {
            vm.onAddWidget({ widget: this });
        };

        function setTotalCount() {
            var item = _.find(vm.widgets, function (data) {
                return data.id === componentId;
            });
            item.totalCount = vm.totalCount;
            item.loading = false;
        }

        function getSpecialistClaims() {
            var skipCount = vm.requestParams.skipCount;
            if (skipCount === 0) {
                vm.records = [];
            }

            vm.loading++;
            operationsDashboardSvc.getSpecialistClaims(vm.requestParams)
                .success(function (data) {
                    vm.totalCount = data.totalCount;
                    if (vm.hasSpecialistWorkflowEnhancement) {
                        vm.records = _.uniqBy(vm.records.concat(data.items), 'uniqueId');
                    }
                    else {
                        vm.records = _.uniqBy(vm.records.concat(data.items), 'id');
                    }
                    vm.hasMore = vm.records.length < vm.totalCount;
                    setTotalCount();
                }).finally(function () {
                    vm.loading--;
                });
        }

        vm.refresh = function () {
            vm.requestParams.skipCount = 0;
            vm.requestParams.countryCode = vm.countryCode;
            getSpecialistClaims();
        };

        vm.loadMore = function () {
            if (!vm.loading && vm.hasMore) {
                vm.requestParams.skipCount += 5;
                getSpecialistClaims();
            }
        };

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/operationsDashboard/specialistClaims/specialistClaims.component.html'),
        controller: ['abp.services.app.operationsDashboard', 'Hms.SpecialistClaims.SpecialistClaimStatus', 'Hms.ServiceRequests.ServiceRequestType', 'Hms.ServiceRequests.ServiceRequestStatus', controller],
        controllerAs: 'vm',
        bindings: {
            widgets: '<',
            onAddWidget: '&',
            countryCode: '=?'
        }
    });
})();
