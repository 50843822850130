(() => {
  angular.module('app').component('commonComponentsPreviousAdmissionsPickerComponent', {
    templateUrl: require('./previousAdmissionsPicker.component.html'),
    controller: PreviousAdmissionsPickerController,
    controllerAs: 'vm',
    bindings: {
      employeeId: '<',
      previousAdmissions: '=',
    },
  });

  PreviousAdmissionsPickerController.$inject = ['abp.services.app.inpatientPatientTicket'];

  function PreviousAdmissionsPickerController(inpatientPatientTicketSvc) {
    const vm = this;

    vm.loading = 0;
    vm.counter = 0;
    vm.previousAdmissions = [];
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

    init();

    function init() {
      vm.loading += 1;
      inpatientPatientTicketSvc
        .getPreviousAdmissions({
          id: vm.employeeId,
        })
        .success((data) => {
          vm.previousAdmissions = data;
          setDetails();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
    function setDetails() {
      vm.isOpen = true;
      _.each(vm.previousAdmissions, (item) => {
        const i = item;
        _.assign(i, {
          isOpen: true,
        });

        i.name = vm.counter;
        vm.counter += 1;
      });
    }
  }
})();
