(function () {
    var controllerId = 'host.views.treatments.medicalFeeSchedules.importResultModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$uibModalInstance', 'errors',
        function ($uibModalInstance, errors) {

            var vm = this;
            vm.errorCountText = App.localize("ImportFileHasXIssues", errors.length);
            vm.errorText = errors.join("\n");

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };
        }
    ]);
})();
