(function () {
    var componentId = 'hostOperationsDashboardRecentFrequentCheckInsWidget';
    var app = angular.module('app');

    function controller($uibModal, operationsDashboardSvc) {
        function init() {
            vm.refresh();
        }

        var vm = this;
        vm.loading = 0;
        vm.orderByCount = false;

        vm.$onInit = function () {
            vm.container.addWidget(this);
        };

        function getRecentFrequentCheckIns(skipCount) {
            var input = {
                skipCount: skipCount,
                maxResultCount: app.consts.maxResultCount
            };

            vm.loading++;
            operationsDashboardSvc.getRecentFrequentCheckIns(input)
                .success(function (data) {
                    vm.totalCount = data.totalCount;
                    vm.data = vm.data.concat(data.items);
                    vm.dataOrderByLastUpdate = _.orderBy(vm.data, function (d) { return d.lastCheckedInTime; }, 'desc');
                    vm.toggleSorting(vm.orderByCount);
                }).finally(function () {
                    vm.loading--;
                });
        }

        vm.refresh = function () {
            vm.data = [];
            vm.showAll = false;
            getRecentFrequentCheckIns(0);
        };

        vm.toggleShowData = function () {
            vm.showAll = !vm.showAll;
            showData();
        };

        function showData() {
            vm.displayRecords = vm.showAll ? vm.records : vm.records.slice(0, 5);
        }

        vm.showDetail = function (record) {
            $uibModal.open({
                component: 'hostOperationsDashboardRecentFrequentCheckInsDetail',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                    employee: function () {
                        return {
                            name: record.employeeName,
                            nationalId: record.employeeNationalId,
                            corporateName: record.corporateName,
                            checkIns: record.checkIns
                        };
                    }
                }
            });
        };

        vm.toggleSorting = function (orderByCount) {
            vm.orderByCount = orderByCount;
            vm.records = vm.orderByCount ? vm.data : vm.dataOrderByLastUpdate;
            showData();
        };

        init();
    }

    app.component(componentId, {
        require: {
            container: '^hostOperationsDashboardDashboardContainer'
        },
        templateUrl: require('/App/host/views/operationsDashboard/recentFrequentCheckIns/widget.html'),
        controller: ['$uibModal', 'abp.services.app.operationsDashboard', controller],
        controllerAs: 'vm'
    });
})();
