(() => {
  angular.module('app').component('checklist', {
    templateUrl: require('./checklist.html'),
    controller: ChecklistController,
    controllerAs: 'vm',
    bindings: {
      items: '=',
      options: '=?',
    },
  });

  ChecklistController.$inject = [];

  function ChecklistController() {
    const vm = this;
    const defaultConfig = {
      // Set to 0 to disable all checkbox, less than 0 for infinite.
      // If checklist has sub-items and this more than sub-items count,
      // parent checkbox will be hidden.
      itemsLimit: -1,
    };
    vm.config = angular.extend({}, defaultConfig, vm.options);

    vm.hitLimit = hitLimit;

    class ItemModel {
      constructor(text, subItems, isChecked, disabled, required, requiredInput) {
        this.text = text;
        this.subItems = subItems || [];
        this.isChecked = isChecked;
        this.disabled = disabled;
        this.required = required;
        this.requiredInput = requiredInput;
        this.input = '';
        this.showCheckbox =
          vm.config.itemsLimit < 1 ||
          !this.subItems.length ||
          this.subItems.length <= vm.config.itemsLimit;
      }

      // Trigger when sub-item is checked.
      checkItem() {
        this.isChecked = this.anySubItemChecked();
      }

      // Trigger when parent is checked.
      checkSubItems() {
        _.each(this.subItems, (item) => {
          const d = item;
          d.isChecked = this.isChecked;
        });
      }

      anySubItemChecked() {
        return _.some(this.subItems, (d) => d.isChecked);
      }
    }

    init();

    function init() {
      toDto();
    }

    function toDto() {
      const items = [];
      _.each(_.uniqBy(vm.items, 'text'), (d) => {
        if (d instanceof ItemModel) return;

        const subItems = [];
        if (d.subItems) {
          _.each(_.uniqBy(d.subItems), (subItem) => {
            subItems.push(new ItemModel(subItem));
          });
        }
        const item = new ItemModel(
          d.text,
          subItems,
          d.isChecked,
          d.disabled,
          d.required,
          d.requiredInput
        );
        items.push(item);
      });
      vm.items = items;
    }

    function hitLimit(item) {
      const limit = vm.config.itemsLimit;
      if (limit < 0) return false;
      if (limit === 0) return true;

      let count = 0;
      if (_.isArray(vm.items)) {
        _.each(vm.items, (d) => {
          if (d.isChecked && !d.subItems.length) count += 1;
          if (d.anySubItemChecked()) {
            _.each(d.subItems, (subItem) => {
              if (subItem.isChecked) count += 1;
            });
          }
        });
      }

      // Checked checkbox will return false to allow to un-check it.
      return !item.isChecked && count >= limit;
    }
  }
})();
