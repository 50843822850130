import swal from 'sweetalert';
import PromptQueue from '../../../../lib/prompt-queue';

(() => {
  angular
    .module('app')
    .controller(
      'common.views.patientTickets.createEditManualTicket',
      ManualTicketEditPageController
    );

  ManualTicketEditPageController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$uibModal',
    '$filter',
    '$window',
    'abp.services.app.patientTicket',
    'countries',
    'Hms.MultiTenancy.TenantClinicType',
    'Hms.PatientTickets.PatientTicketItem',
    'Hms.Services.ServiceType',
    'moment',
  ];

  function ManualTicketEditPageController(
    $scope,
    $state,
    $stateParams,
    $uibModal,
    $filter,
    $window,
    patientTicketSvc,
    countries,
    enumTenantClinicType,
    constsPatientTicketItem,
    constsServiceType,
    moment
  ) {
    const vm = this;

    vm.constsPatientTicketItem = constsPatientTicketItem;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.defaultMaxAllowance = parseFloat(abp.setting.get('Hms.General.MaxAllowanceAmountLimit'));
    vm.loading = 0;
    vm.saving = 0;

    vm.ticketDate = moment();
    vm.treatments = [];
    vm.employeeId = $stateParams.employeeId;
    vm.isCreate = $stateParams.employeeId;
    vm.needMc = false;
    vm.patientHasMc = null;
    vm.isOutpatientTicket = false;
    vm.accountOwnerCountryCode = 'MY';

    vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');

    vm.enums = {};
    vm.enums.tenantClinicType = enumTenantClinicType;
    vm.constsServiceType = constsServiceType;

    vm.ticket = {
      ticketType: 0, // Initialize to Normal ticket to hide a section.
      treatments: [],
    };

    /* Ticket attachments */

    vm.uploadConfig = {
      objectType: 'TicketAttachment',
    };

    /* End of Ticket Attachment */

    /* Google Place Autocomplete */

    vm.autocomplete = {
      options: {
        fields: ['formatted_address', 'name'],
      },
      callback(place) {
        vm.ticket.manualClinicName = place.name;
        vm.ticket.manualClinicLocation = place.formatted_address;
      },
    };

    /* End of Google Place Autocomplete */

    vm.updatedTicketDate = updatedTicketDate;
    vm.needMc = needMc;
    vm.getDependentDescription = getDependentDescription;
    vm.treatmentHasValue = treatmentHasValue;
    vm.addTreatments = addTreatments;
    vm.clearTreatments = clearTreatments;
    vm.removeTreatment = removeTreatment;
    vm.refreshTreatmentAndMcRemarks = refreshTreatmentAndMcRemarks;
    vm.getAllowanceDisplay = getAllowanceDisplay;
    vm.getTreatmentTotalAmount = getTreatmentTotalAmount;
    vm.getManualPaymentAmount = getManualPaymentAmount;
    vm.issueMc = issueMc;
    vm.removeMc = removeMc;
    vm.setMcStartDate = setMcStartDate;
    vm.getMcStartDateChoice = getMcStartDateChoice;
    vm.isInvalidMc = isInvalidMc;
    vm.save = createOrEditTicket;

    init();

    function init() {
      vm.loading += 1;
      patientTicketSvc
        .getTicketForEdit({
          employeeId: $stateParams.employeeId,
          ticketNumber: $stateParams.ticketNumber,
          clinicLocationId: $stateParams.clinicLocationId,
          ticketType: 1,
          serviceType: $stateParams.serviceType,
        })
        .success((data) => {
          vm.doctors = data.doctors || [];
          vm.patient = data.patient;
          vm.clinic = data.clinic || {};
          vm.treatments = data.treatments || [];
          vm.accountOwnerCountryCode = data.countryCode;

          if (!vm.isCreate) {
            vm.ticketNumber = data.ticket.ticketNumber;
            vm.employeeId = data.patient.id;

            vm.currencyCode = data.ticket.currencyCode;
          } else {
            const accountOwnerCountry = _.filter(
              countries,
              (c) => c.code === vm.accountOwnerCountryCode
            );

            vm.currencyCode = accountOwnerCountry[0].currencyCode;
          }

          vm.ticketDate = moment(data.ticket.ticketDate);

          vm.ticket = data.ticket;

          vm.isOutpatientTicket = vm.ticket.serviceType === 5 || vm.ticket.serviceType === 9;

          if (!vm.ticket.treatments) {
            vm.ticket.treatments = [];
          }
          _.each(vm.ticket.treatments, (t) => {
            t.treatment = _.find(vm.treatments, ['id', t.treatmentId]);
          });

          refreshTicketTreatments();

          vm.isCtTicket = vm.ticket.serviceType >= 200 && vm.ticket.serviceType <= 209;
          vm.isInpatientASO = vm.ticket.serviceType === 251;
          vm.namePlaceholder =
            vm.isCtTicket || vm.isInpatient
              ? App.localize('ProviderName')
              : App.localize('PanelName');
          vm.addressPlaceholder =
            vm.isCtTicket || vm.isInpatient
              ? App.localize('ProviderAddress')
              : App.localize('PanelAddress');

          vm.attachments = vm.ticket.attachments;
          vm.newAttachments = [];

          if (vm.claim) {
            _.forEach(vm.claim.attachments, (attachment) => {
              attachment.isImage = attachment.contentType.startsWith('image/');
            });
          }

          vm.needMc =
            vm.ticket &&
            vm.ticket.ticketType !== 3 &&
            (vm.ticket.serviceType === 1 ||
              vm.ticket.serviceType === 2 ||
              vm.ticket.serviceType === 7 ||
              (vm.isOutpatientTicket && vm.ticket.serviceType !== 9) ||
              vm.isCtTicket);

          if (!vm.isCreate && vm.ticket.ticketType === 0) {
            vm.patientHasMc = vm.ticket.medicalCertificate ? 1 : 0;
          }

          if (vm.ticket.medicalCertificate) {
            vm.ticket.medicalCertificate.startDate = moment(vm.ticket.medicalCertificate.startDate);
            vm.ticket.medicalCertificate.endDate = moment(vm.ticket.medicalCertificate.endDate);
            vm.ticket.checkedInTime = moment(vm.ticket.checkedInTime);
            vm.isMcStartDateAlsoCheckedInDate =
              vm.ticket.medicalCertificate.startDate.diff(
                vm.ticket.checkedInTime.startOf('days'),
                'days'
              ) === 0;
            if (vm.isCreate && vm.claim) {
              vm.ticket.medicalCertificate.remark = vm.claim.treatmentRemarks || 'n/a';
            }
          }

          const strAmt = $filter('currencyFormat')(vm.ticket.coveredAmount, vm.currencyCode);
          vm.reviseCoveredAmountUpwardErrorMessage = App.localize(
            'ReviseCoveredAmountUpwardErrorMessage',
            strAmt
          );

          vm.autocomplete.options.componentRestrictions = {
            country: vm.hasRegionalSystemSupport
              ? vm.accountOwnerCountryCode
              : abp.setting.get('Hms.General.DefaultCountryCode'),
          };
        })
        .error(() => {
          $window.history.back();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function updatedTicketDate() {
      if (vm.isCreate || vm.ticket.ticketType === 1) {
        refreshAllowance();
      }

      if (!vm.ticket.bankingDetails?.isInvoiced && vm.ticket.ticketType === 2) {
        checkActivePremiumSubscription();
      }

      if (vm.isCreate && vm.ticket.medicalCertificate) {
        vm.ticket.medicalCertificate.startDate = moment(vm.ticketDate).startOf('day');
        vm.ticket.medicalCertificate.endDate = moment(vm.ticketDate).startOf('day');
      }
    }

    function checkActivePremiumSubscription() {
      vm.loading += 1;
      patientTicketSvc
        .checkActivePremiumSubscription({
          subsidiaryId: vm.patient.corporate.subsidiaryId,
          ticketDate: vm.ticketDate,
        })
        .success((data) => {
          const isChanged = vm.patient.corporate.hasActivePremiumSubscription !== data;
          if (isChanged) {
            vm.patient.corporate.hasActivePremiumSubscription = data;

            // Reset existing and if has active subsription set service fee payor to corporate.

            vm.ticket.bankingDetails.serviceFeePayor = !vm.patient.corporate
              .hasActivePremiumSubscription
              ? vm.patient.corporate.defaultServiceFeePayor
              : 1;
            vm.ticket.bankingDetails.serviceFeePayorRemark = null;
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function needMc() {
      return (
        vm.ticket &&
        (vm.ticket.serviceType === 1 ||
          vm.ticket.serviceType === 2 ||
          (vm.isOutpatientTicket && vm.ticket.serviceType !== 9) ||
          vm.isCtTicket ||
          vm.isInpatient)
      );
    }

    function getDependentDescription() {
      return App.localize(
        'DependentDescription',
        vm.patient.sponsorName,
        $filter('nationalIdFilter')(vm.patient.sponsorNationalId)
      );
    }

    /* Treatment remark functions */

    function hasRemark(treatment) {
      return treatment.remarkTags && treatment.remarkTags.length;
    }

    /* End of Treatment remark functions */

    /* Treatment Management */

    function treatmentHasValue(treatment) {
      return treatment.treatment || hasRemark(treatment) || treatment.amount;
    }

    function refreshTicketTreatments() {
      const defaultTreatment = _.first(vm.treatments);
      _.each(vm.ticket.treatments, (t) => {
        if (defaultTreatment && (hasRemark(t) || t.amount)) {
          t.treatment = defaultTreatment;
          t.treatmentId = t.treatment.id;
        } else {
          t.treatment = null;
          t.treatmentId = null;
        }
      });

      let hasBlank = _.some(vm.ticket.treatments, (t) => !vm.treatmentHasValue(t));

      while (!hasBlank || vm.ticket.treatments.length < 2) {
        vm.ticket.treatments.push({});
        hasBlank = true;
      }
    }

    function addTreatments() {
      for (let i = 0; i < 4; i += 1) {
        vm.ticket.treatments.push({});
      }
    }

    function clearTreatments() {
      vm.ticket.treatments = [];
      vm.refreshTreatmentAndMcRemarks();
    }

    function removeTreatment(index) {
      if (index < vm.ticket.treatments.length) {
        vm.ticket.treatments.splice(index, 1);
        vm.refreshTreatmentAndMcRemarks();
      }
    }

    function refreshTreatmentAndMcRemarks() {
      refreshTicketTreatments();
      calculateAmountCoverage();
    }

    /* End of Treatment Management */

    /* Amounts Calculation */

    function getAllowanceDisplay() {
      if (vm.patient) {
        return Math.min(vm.patient.allowance, vm.defaultMaxAllowance);
      }
      return NaN;
    }

    // Refresh patient's allowance. Only for NPR & Manual tickets.

    function refreshAllowance() {
      vm.loading += 1;
      patientTicketSvc
        .getAllowance({
          employeeId: vm.employeeId,
          panelLocationId: vm.clinic ? vm.clinic.clinicLocationId : null,
          ticketType: vm.ticket.ticketType,
          ticketDate: vm.ticketDate,
          serviceType: vm.ticket.serviceType,
        })
        .success((data) => {
          vm.patient.allowance = data.employeeAllowance;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getTreatmentTotalAmount() {
      let amount = 0;
      _.each(vm.ticket.treatments, (t) => {
        if (t.treatment && t.amount) {
          amount += t.amount;
        }
      });
      return amount;
    }

    function calculateAmountCoverage() {
      let claimable = 0;
      let total = 0;
      _.each(vm.ticket.treatments, (t) => {
        const rate = t.amount;
        if (t.treatment && rate) {
          if (!t.treatment.notCovered) {
            claimable += rate;
          }
          total += rate;
        }
      });

      if (vm.isCreate) {
        if (vm.patient && vm.ticket && claimable) {
          // Apply co-pay for normal/reimbursement tickets

          const isNormalOrReimbursement = vm.ticket.ticketType === 0 || vm.ticket.ticketType === 2;
          if (vm.patient.coPay && isNormalOrReimbursement) {
            let coPay = vm.patient.coPay.value;
            if (vm.patient.coPay.isPercentage) {
              coPay *= claimable / 100;
            }
            if (coPay < 0) {
              coPay = 0;
            } else if (coPay > claimable) {
              coPay = claimable;
            }
            claimable -= coPay;
          }

          // Check against allowance

          if (claimable > vm.patient.allowance) {
            claimable = vm.patient.allowance;
          }
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (vm.patient) {
          if (claimable > vm.patient?.allowance) {
            vm.ticket.manualPayment = claimable - vm.patient.allowance;
            claimable = vm.patient.allowance;
          } else {
            vm.ticket.manualPayment = 0;
          }
        }

        // claimable -= vm.ticket ? vm.ticket.manualPayment : 0;
      }

      total = Math.round(total * 100) / 100;
      claimable = Math.round(claimable * 100) / 100;

      return {
        claimable,
        manualPayment: total - claimable,
      };
    }

    function getManualPaymentAmount() {
      return calculateAmountCoverage().manualPayment;
    }

    /* End of Amounts Calculation */

    /* Medical Certificate Management */

    function issueMc() {
      vm.patientHasMc = !vm.patientHasMc;
      const firstDate = moment(
        vm.ticket.ticketType !== 0 ? vm.ticketDate : vm.ticket.checkedInTime
      ).startOf('day');
      vm.ticket.medicalCertificate = {
        startDate: firstDate,
        endDate: firstDate,
      };
      vm.isMcStartDateAlsoCheckedInDate = 1;
    }

    function removeMc() {
      vm.ticket.medicalCertificate = null;
      if (vm.ticket.ticketType === 0) {
        vm.patientHasMc = 0;
      }
    }

    function setMcStartDate(offset) {
      const checkInDate = moment(vm.ticket.checkedInTime).startOf('day');
      vm.ticket.medicalCertificate.startDate = checkInDate.add(offset, 'days');

      const mcEndDate = vm.ticket.medicalCertificate.endDate.startOf('day');
      if (mcEndDate.diff(vm.ticket.medicalCertificate.startDate, 'days') < 0) {
        vm.ticket.medicalCertificate.endDate = moment(vm.ticket.medicalCertificate.startDate);
      }
      vm.isMcStartDateAlsoCheckedInDate = offset === 0;
    }

    function getMcStartDateChoice(offset) {
      return moment(vm.ticket.checkedInTime).add(offset, 'days').startOf('day');
    }

    function isInvalidMc() {
      return vm.needMc && vm.patientHasMc === null && vm.ticket.ticketType === 0;
    }

    /* End of Medical Certificate Management */

    function createOrEditTicket() {
      let canSubmit = true;
      const prompts = new PromptQueue();

      vm.clinic = {};
      vm.ticket.doctorId = null;

      let cleanTreatments = true;
      _.each(vm.ticket.treatments, (t) => {
        if (treatmentHasValue(t)) {
          if (t.amount < 0) {
            abp.message.error(App.localize('InvalidTreatmentRateAmount'));
            cleanTreatments = false;
            return false;
          }

          if (!t.remark) {
            abp.message.error(App.localize('MissingTreatmentRemark'));
            cleanTreatments = false;
            return false;
          }
        }
        return true;
      });

      if (!cleanTreatments) return;

      if (vm.ticketDate > moment()) {
        abp.message.error(App.localize('TicketDateIsInFuture'));
        return;
      }

      if (vm.ticket.medicalCertificate) {
        const startDate = moment(vm.ticket.medicalCertificate.startDate);

        const mcDays = Math.ceil(vm.ticket.medicalCertificate.effectiveMcDays);
        if (Number.isNaN(Number(mcDays)) || mcDays < 0) {
          abp.message.error(App.localize('InvalidEffectiveMcDays'));
          return;
        }
        vm.ticket.medicalCertificate.endDate = moment(startDate).add(mcDays - 1, 'd');

        const mcBenchmarkDay = vm.ticket.ticketType !== 0 ? vm.ticketDate : vm.ticket.checkedInTime;
        const mcStartDayOffset = startDate.diff(moment(mcBenchmarkDay).startOf('day'), 'days');
        if (mcStartDayOffset < 0) {
          abp.message.error(App.localize('McStartDateEarlierThanTicketDate'));
          return;
        }
        if (mcStartDayOffset > 1) {
          if (vm.ticket.ticketType === 2) {
            prompts.add(
              swal,
              {
                title: App.localize('AreYouSure'),
                text: App.localize('McStartDateTooFarFromTicketDateWarning'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#1ab394',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                closeOnConfirm: true,
                closeOnCancel: true,
              },
              (isConfirm) => {
                canSubmit = isConfirm;
              }
            );
          } else {
            abp.message.error(App.localize('McStartDateTooFarFromTicketDate'));
            return;
          }
        }
      }

      if ($scope.editForm.$invalid) {
        abp.message.error(App.localize('OneOrMoreInputValueIsInvalid'));
        return;
      }

      // Filter away attachments marked for deletion.

      const attachments = [];
      _.each(vm.attachments, (attachment) => {
        if (!attachment.isDelete) attachments.push(attachment.id);
      });

      // Construct input request.

      const input = {
        doctorId: vm.ticket.doctorId,
        treatments: _.filter(
          vm.ticket.treatments,
          (treatment) =>
            // Discard treatment without id or without remark if it is CT ticket.

            treatment.treatmentId > 0
        ),
        manualPayment: vm.getManualPaymentAmount(),
        clinicLocationId: vm.clinic.clinicLocationId,
        medicalCertificate: vm.ticket.medicalCertificate,
        bankingDetails: vm.ticket.bankingDetails,
        manualClinicName: vm.ticket.manualClinicName,
        manualClinicLocation: vm.ticket.manualClinicLocation,
        manualDoctorName: vm.ticket.manualDoctorName,
        ticketDate: vm.ticketDate,
        ticketType: vm.ticket.ticketType,
        attachments,
        newAttachments: vm.newAttachments,
        report: vm.report,
        hospitalizationDays: vm.ticket.hospitalizationDays,
        hospitalizationLeaveDates: vm.ticket.hospitalizationLeaveDates,
        serviceType: vm.ticket.serviceType,
      };
      if (!vm.needMc) {
        input.medicalCertificate = null;
      }

      function createTicket() {
        if (vm.saving !== 0) return;

        vm.saving += 1;
        patientTicketSvc
          .createTicket(
            $.extend(input, {
              employeeId: vm.employeeId,
              claimId: vm.claimId,
            })
          )
          .success((data) => {
            vm.isUpdated = true;

            function promptWarning(title, text) {
              // Validations.

              const cleanTitle = _.isString(title) ? title : '';
              const cleanText = _.isString(text) ? text : '';

              $window.onbeforeunload = () => cleanTitle;

              swal(
                {
                  title: cleanTitle,
                  type: 'warning',
                  text: cleanText,
                  showConfirmButton: true,
                },
                () => {
                  $window.onbeforeunload = null; // unregister listener
                }
              );
              App.swal.disableButtons(5);
            }
            if (vm.ticket.ticketType === 0 && vm.getManualPaymentAmount()) {
              promptWarning(
                App.localize(
                  'RememberCollectXPaymentFromPatient',
                  vm.currencyCode + vm.getManualPaymentAmount()
                )
              );
            } else if (vm.ticket.ticketType === 1) {
              if (vm.getTreatmentTotalAmount() > vm.patient.allowanceDisplay) {
                abp.message.warn(App.localize('ExceededEntitlement'));
              }
            }
            abp.notify.info(App.localize('TicketXIssued', data.ticketNumber));

            let nextState = 'patientTickets';
            let nextStateData = null;
            if (vm.isClinic) {
              nextState = 'clinic.employees';
            } else if (data.nextClaimNumber) {
              nextState = 'claimListDetail';
              nextStateData = {
                claimNumber: data.nextClaimNumber,
              };
            }
            showSuccessModal(
              data.ticketNumber,
              vm.ticket.medicalCertificate !== null,
              nextState,
              nextStateData
            );
          })
          .finally(() => {
            vm.saving -= 1;
          });
      }

      function editTicket(remarks) {
        if (vm.saving !== 0) return;

        vm.saving += 1;
        patientTicketSvc
          .editTicket(
            $.extend(input, {
              ticketNumber: vm.ticketNumber,
              modificationRemarks: remarks,
            })
          )
          .success(() => {
            vm.isUpdated = true;
            abp.notify.info(App.localize('SuccessfullySaved'));
            showSuccessModal(
              vm.ticketNumber,
              vm.ticket.medicalCertificate !== null,
              'patientTickets',
              null
            );
            swal.close();
          })
          .finally(() => {
            vm.saving -= 1;
          });
      }

      if (vm.isCreate) {
        vm.saving += 1;
        prompts.run(() => {
          vm.saving -= 1;
          if (canSubmit) createTicket();
        });
      } else {
        prompts.add(
          swal,
          {
            title: App.localize('EditPatientTicket'),
            text: App.localize('WhyEditPatientTicket'),
            type: 'input',
            showCancelButton: true,
            closeOnConfirm: false,
            confirmButtonColor: '#1ab394',
            inputPlaceholder: App.localize('PleaseExplain'),
          },
          (inputValue) => {
            vm.saving -= 1;
            vm.saving += 1;
            if (inputValue === false) {
              vm.saving -= 1;
              return false;
            }
            if ($.trim(inputValue || '') === '') {
              swal.showInputError(App.localize('PleaseExplain'));
              return false;
            }

            vm.saving -= 1;
            editTicket($.trim(inputValue));
            return true;
          }
        );

        vm.saving += 1;
        prompts.run();
      }
    }

    function showSuccessModal(ticketNumber, hasMc, nextState, nextStateData) {
      let callbackData = null;
      const obj = {
        ticketNumber,
        hasMc,
        ticketType: vm.ticket.ticketType,
        insurerId: vm.ticket.insurerId,
        callback(data) {
          callbackData = data;
        },
      };

      $uibModal
        .open({
          templateUrl: require('./createEditSuccessModal.html'),
          controller: 'common.views.patientTickets.createEditSuccessModal as vm',
          backdrop: 'static',
          size: 'sm',
          resolve: {
            ticket() {
              return obj;
            },
          },
        })
        .result.then(() => {
          if (callbackData) {
            $state.go(callbackData.stateName, callbackData.stateData);
          } else {
            $state.go(nextState, nextStateData);
          }
        });
    }
  }
})();
