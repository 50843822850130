(() => {
  angular.module('app').component('hostCorporatesCreateEditContact', {
    templateUrl: require('./widget.html'),
    controller: CorporateEditContactController,
    controllerAs: 'vm',
    require: {
      container: '^hostCorporatesCreateEditContainer',
    },
    bindings: {
      data: '<',
      countryCode: '<'
    },
  });

  CorporateEditContactController.$inject = [
    '$scope',
    'Hms.Employees.Employee',
    'Hms.Users.User',
    'Hms.MultiTenancy.Tenant',
  ];

  function CorporateEditContactController($scope, constsEmployee, constsUser, constsTenant) {
    const vm = this;
    vm.loading = 0;
    vm.saving = 0;
    vm.isDisabled = true;
    vm.isEditing = false;
    vm.isCreate = false;
    vm.constsEmployee = constsEmployee;
    vm.constsUser = constsUser;
    vm.constsTenant = constsTenant;

    vm.permissions = {
      create: abp.auth.isGranted('Host.Corporates.Create'),
      edit: abp.auth.isGranted('Host.Corporates.Edit'),
    };

    vm.isDirty = isDirty;
    vm.setPristine = setPristine;
    vm.validateForm = validateForm;
    vm.getPayload = getPayload;
    vm.save = save;
    vm.$onChanges = changes;
    vm.$onInit = init;

    function init() {
      vm.container.addWidget(this);
    }

    function isDirty() {
      return $scope.corporateCreateEditContactForm && $scope.corporateCreateEditContactForm.$dirty;
    }

    function setPristine() {
      $scope.corporateCreateEditContactForm.$setPristine();
    }

    function convertToViewModel() {
      // To convert DTO received from server for addressInput component.

      if (!vm.data) return;

      vm.data.correspondenceAddress = {
        street: vm.data.correspondenceStreet,
        city: vm.data.correspondenceCity,
        state: vm.data.correspondenceState,
        countrySubdivisionId: vm.data.correspondenceCountrySubdivisionId,
        postcode: vm.data.correspondencePostcode,
        countryCode: vm.data.correspondenceCountryCode,
      };
    }

    function convertToDto() {
      // To convert addressInput data model for input for server.

      if (!vm.data || !vm.data.correspondenceAddress) return;

      vm.data.correspondenceStreet = vm.data.correspondenceAddress.street;
      vm.data.correspondenceCity = vm.data.correspondenceAddress.city;
      vm.data.correspondenceState = vm.data.correspondenceAddress.state;
      vm.data.correspondencePostcode = vm.data.correspondenceAddress.postcode;
      vm.data.correspondenceCountryCode = vm.data.correspondenceAddress.countryCode;
      vm.data.correspondenceCountrySubdivisionId =
        vm.data.correspondenceAddress.countrySubdivisionId;
    }

    // Force validation on this form.
    // True if valid, otherwise, false.

    function validateForm(error) {
      const form = $scope.corporateCreateEditContactForm;
      App.touchFormErrors(form);

      vm.invalidDataPostcode = vm.data.postcode ? vm.invalidDataPostcode : false;

      vm.invalidCorrespondencePostcode = vm.data.correspondenceAddress.postcode
        ? vm.invalidCorrespondencePostcode
        : false;

      error = !(form.$valid && !vm.invalidDataPostcode && !vm.invalidCorrespondencePostcode);
    }

    // Generate the payload from this form to send to CorporateAppService API.

    function getPayload(settings) {
      convertToDto();

      _.extend(settings,  {
        contact: vm.data,
      });
    }

    // Ask container to save this widget.

    function save() {
      vm.container.saveWidget(vm);
    }

    function changes() {
      vm.isCreate = vm.data ? !vm.data.id : false;
      convertToViewModel();
    }
  }
})();
