(function () {
    angular.module('app').component('hostInpatientGuaranteeLettersIglProcessingRequestDetailsComponent', {
        templateUrl: require('/App/host/views/inpatientGuaranteeLetters/iglProcessing/requestDetails/requestDetails.component.html'),
        controller: ['abp.services.app.hostServiceRequest', 'Hms.GuaranteeLetters.AdmissionType', 'Hms.GuaranteeLetters.DeliveryType', 'moment', controller],
        controllerAs: 'vm',
        bindings: {
            guaranteeLetterNumber: '<',
            serviceRequestId: '<',
            onEdit: '&',
            onSave: '&',
            diagnosisFilled: '&',
            newGuaranteeAmount: '=',
            currencyCode: '<'
        }
    });

    function controller(hostServiceRequestSvc, enumAdmissionType, enumDeliveryType, moment) {
        var vm = this;

        vm.$onChanges = onChanges;
        vm.getAdmissionType = getAdmissionType;
        vm.getAdmittingSpecialists = getAdmittingSpecialists;
        vm.lookupDoctor = lookupDoctor;
        vm.edit = edit;
        vm.save = save;
        vm.cancel = cancel;

        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.isEdit = false;
        vm.loading = 0;
        vm.diagnosis = null;
        vm.documents = []
        vm.filteredDoctors = [];
        vm.admissionTypes = [];
        vm.request = null;

        var preEditRequest = null;
        var preEditDiagnosis = null;
        var preEditDocuments = null;
        var limitSize = 100;
        var doctors = [];

        vm.enums = {};
        vm.enums.admissionType = enumAdmissionType;
        vm.enums.deliveryType = enumDeliveryType;

        function onChanges(changes) {
            if (changes.serviceRequestId && changes.serviceRequestId.currentValue) {
                getRequestDetails(vm.serviceRequestId);
            }
        }

        function getRequestDetails(serviceRequestId) {
            vm.loading++;
            hostServiceRequestSvc.getIglRequestDetails({
                id: serviceRequestId
            }).success(function (data) {
                vm.request = {
                    requestType: data.requestType,
                    requestTime: data.requestTime,
                    requestedBy: data.requestedBy,
                    guaranteeLetterNumber: data.guaranteeLetterNumber,
                    admissionNumber: data.admissionNumber,
                    estimatedCost: data.estimatedCost,
                    scheduledAdmissionTime: data.scheduledAdmissionTime,
                    estimatedDischargeTime: data.estimatedDischargeTime,
                    admissionType: data.admissionType,
                    deliveryType: data.deliveryType,
                    accidentTime: data.accidentTime,
                    newGuaranteeAmount: data.newGuaranteeAmount,
                    selectedDoctorIds: data.selectedDoctorIds
                };

                vm.documents = _.map(data.supportingDocuments, function (d) {
                    return {
                        externalObjectId: d.externalObjectId,
                        url: d.url,
                        name: d.name,
                        uploader: d.uploader + ' ' + moment(d.uploadedTime).format('L, LT'),
                        applied: d.isApplied,
                        state: d.state,
                        isEditable: true,
                    };
                });

                vm.diagnosis = {
                    selected: _.map(data.provisionalDiagnosis, function (p) {
                        return {
                            code: p.code,
                            description: p.description,
                            displayDate: p.displayDiagnosisDate,
                            date: p.diagnosisDate
                        };
                    }),
                    icd: _.map(data.diseaseClassifications, function (d) {
                        return {
                            id: d.id,
                            code: d.code,
                            description: '(' + d.code + ') ' + d.description
                        };
                    })
                };

                vm.diagnosisFilled({ data: vm.diagnosis.selected });

                doctors = _.map(data.availableDoctors, function (d) {
                    return {
                        id: d.id,
                        name: d.name
                    };
                });

                vm.admissionTypes = data.admissionTypes;
            }).finally(function (e) {
                vm.loading--
            });
        }

        function getAdmissionType() {
            if (vm.request && vm.request.admissionType) {
                var admissionType = _.find(vm.admissionTypes, function (t) {
                    return t.id === vm.request.admissionType;
                });

                return admissionType.name;
            }
        }

        function getAdmittingSpecialists() {
            if (vm.request && vm.request.selectedDoctorIds) {
                var selectedDoctorsName = _.map(vm.request.selectedDoctorIds, function (i) {
                    var selectedDoctor = _.find(doctors, function (d) {
                        return d.id === i;
                    });
                    return selectedDoctor.name;
                });

                return selectedDoctorsName.join(', ');
            }
        }

        function edit() {
            backupInitialStates();
            vm.isEdit = true;
            vm.onEdit({ isEdit: true });
        }

        function save() {
            if (App.isFormValid(vm.requestDetailsForm)) {
                vm.loading++;

                vm.request.id = vm.serviceRequestId
                vm.request.estimatedCost = App.roundAmount(vm.request.estimatedCost);
                vm.request.newGuaranteeAmount = App.roundAmount(vm.request.newGuaranteeAmount);

                var provisionalDiagnosis = [];
                if (vm.diagnosis) {
                    tidyDiagnosis();
                    provisionalDiagnosis = _.map(vm.diagnosis.selected, function (s) {
                        // Free-text entry has null diagnosis code.

                        return {
                            code: _.isNumber(s.code) ? null : s.code,
                            description: s.description,
                            diagnosisDate: s.date
                        }
                    });
                }

                var supportingDocuments = [];
                if (vm.documents) {
                    supportingDocuments = _.map(vm.documents, function (d) {
                        return {
                            externalObjectId: d.externalObjectId,
                            isApplied: d.applied
                        }
                    });
                }

                var input = $.extend({},
                    vm.request,
                    { provisionalDiagnosis: provisionalDiagnosis },
                    { supportingDocuments: supportingDocuments });

                hostServiceRequestSvc.updateIglRequestDetails(input
                ).success(function () {
                    abp.notify.info(App.localize("SuccessfullySaved"));
                    getRequestDetails(vm.serviceRequestId);
                    vm.isEdit = false;
                    vm.onEdit({ isEdit: false });
                    vm.diagnosisFilled({ data: provisionalDiagnosis });
                    vm.onSave();
                }).finally(function () {
                    vm.loading--
                })
            }
        }

        function cancel() {
            restoreInitialStates();
            vm.isEdit = false;
            vm.onEdit({ isEdit: false });
        }

        function backupInitialStates() {
            preEditRequest = angular.copy(vm.request);
            preEditDiagnosis = angular.copy(vm.diagnosis);
            preEditDocuments = angular.copy(vm.documents);
        }

        function restoreInitialStates() {
            vm.request = preEditRequest;
            vm.diagnosis = preEditDiagnosis;
            vm.documents = preEditDocuments;
        }

        function lookupDoctor(filter) {
            var keyword = _.trim(filter || '');
            if (keyword) {
                var filteredDoctors = [];

                var regexes = _.chain(keyword.split(' '))
                    .map(function (k) {
                        return _.trim(k.toUpperCase());
                    })
                    .filter(function (k) {
                        return k;
                    })
                    .uniq()
                    .map(function (k) {
                        _.escapeRegExp(k);
                        return {
                            token: k,
                            len: k.length,
                            pattern: new RegExp('\\b' + k, 'i')
                        };
                    })
                    .value();

                _.forEach(doctors, function (doctor) {
                    var score = 0;
                    _.forEach(regexes, function (r) {
                        if (r.pattern.test(doctor.name)) {
                            score -= r.len;
                        }
                    });

                    if (score) {
                        filteredDoctors.push({
                            score: score,
                            item: doctor
                        });
                    }
                });

                vm.filteredDoctors = _.chain(filteredDoctors)
                    .sortBy(['score'])
                    .take(30)
                    .map(function (k) {
                        return k.item;
                    })
                    .uniqBy(function (s) {
                        return s.id;
                    })
                    .value();
            } else {
                vm.filteredDoctors = _.take(doctors, limitSize);
            }
        };

        function tidyDiagnosis() {
            vm.diagnosis.selected = _.filter(vm.diagnosis.selected, function (item) {
                return item.code !== undefined;
            });
        }

        vm.updateNewGuaranteeAmount = function () {
            if (vm.request.newGuaranteeAmount)
                vm.newGuaranteeAmount = vm.request.newGuaranteeAmount;
            else
                vm.newGuaranteeAmount = 0;
        }
    }
})();
