(() => {
  angular.module('app').component('commonClaimsDetailsAuditHistoryWidget', {
    templateUrl: require('./widget.html'),
    controller: AuditHistoryController,
    controllerAs: 'vm',
    bindings: {},
  });

  AuditHistoryController.$inject = [
    '$stateParams',
    'abp.services.app.claim',
    'Hms.Claims.ClaimStatus',
  ];

  function AuditHistoryController($stateParams, claimSvc, enumClaimStatus) {
    const vm = this;
    vm.claimHistories = [];

    vm.loading = 0;

    vm.enums = {
      claimStatus: enumClaimStatus,
    };

    init();

    function init() {
      getClaim();
    }

    function getClaim() {
      vm.loading += 1;
      claimSvc
        .getClaimHistory({
          id: $stateParams.claimNumber,
        })
        .success((data) => {
          vm.claimHistories = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
