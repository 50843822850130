(function () {
    var controllerId = 'host.views.customEmailTemplates.index';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$state', '$stateParams', '$scope', 'countries', 'abp.services.app.customEmailTemplate', 'abp.services.app.commonLookup',
        function ($state, $stateParams, $scope, countries, customEmailTemplateSvc, commonLookupSvc) {
            function init() {
                getModuleType();
                vm.gridOptions.paginationCurrentPage = Math.floor(viewOptions.skipCount / viewOptions.maxResultCount + 1);
                vm.getEmailTemplates();
                if (vm.hasRegionalSystemSupport) {
                    getAccessibleCountries();
                }
            }
            var vm = this;
            vm.loading = false;
            vm.moduleType = 0;
            vm.emailTemplates = null;
            vm.countries = [];
            vm.selectedCountry = null;

            vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');

            vm.permissions = {
                create: abp.auth.isGranted("Host.CustomEmailTemplates.Create"),
                edit: abp.auth.isGranted("Host.CustomEmailTemplates.Edit"),
                delete: abp.auth.isGranted("Host.CustomEmailTemplates.Delete")
            };

            vm.getEmailTemplates = function () {
                vm.loading++;
                customEmailTemplateSvc.getEmailTemplates({
                    skipCount: viewOptions.skipCount,
                    maxResultCount: viewOptions.maxResultCount,
                    sorting: viewOptions.sorting,
                    moduleType: vm.moduleType,
                    countryCode: vm.selectedCountry
                }).success(function (data) {
                    vm.gridOptions.totalItems = data.totalCount;
                    vm.gridOptions.data = data.items;
                    vm.loading--;
                });
            }

            function getModuleType() {
                vm.loading++;

                customEmailTemplateSvc.getEmailTemplateModuleTypes({
                }).success(function (data) {
                    vm.moduleTypes = data.items;
                }).finally(function (e) {
                    vm.loading--;
                });
            }

            vm.createEditTemplate = function (template) {
                if (template) {
                    $state.go('host.createEditCustomEmailTemplate', { templateId: template.id });
                } else {
                    $state.go('host.createEditCustomEmailTemplate');
                }
            };

            vm.deleteTemplate = function (emailTemplate) {
                abp.message.confirm(App.localize('EmailTemplateDeleteWarningMessage', emailTemplate.name), App.localize('AreYouSure'),
                    function (d) {
                        if (d) {
                            customEmailTemplateSvc.deleteEmailTemplate({
                                id: emailTemplate.id
                            }).success(function () {
                                vm.getEmailTemplates();
                                abp.notify.success(App.localize('SuccessfullyDeleted'));
                            });
                        }
                    });
            }

            vm.updateEmailTemplateStatus = function (emailTemplate, newStatus) {
                abp.message.confirm(App.localize('UpdateStatusWarning', emailTemplate.name), App.localize('AreYouSure'),
                    function (d) {
                        if (d) {
                            customEmailTemplateSvc.updateEmailTemplateStatus({
                                id: emailTemplate.id,
                                status: newStatus
                            }).success(function () {
                                vm.getEmailTemplates();
                                abp.notify.success(App.localize('SuccessfullySaved'));
                            });
                        }
                    });
            }

            // Define grid options

            var viewOptions = {
                skipCount: $stateParams.skipCount || 0,
                maxResultCount: $stateParams.maxResultCount || app.consts.grid.defaultPageSize,
                sorting: $stateParams.sorting
            };

            vm.gridOptions = {
                appScopeProvider: vm,
                paginationPageSizes: app.consts.grid.defaultPageSizes,
                paginationPageSize: app.consts.grid.defaultPageSize,
                paginationCurrentPage: 0,
                useExternalPagination: true,
                useExternalSorting: true,
                resizable: true,
                columnDefs: [{
                    name: App.localize('Actions'),
                    enableSorting: false,
                    width: 100,
                    headerCellTemplate: "<span></span>",
                    cellTemplate: 'actionTemplate',
                    allowCellFocus: false
                }, {
                    displayName: App.localize('Name'),
                    field: 'name',
                    minWidth: 120
                }, {
                    displayName: App.localize('Country'),
                    field: 'countryCode',
                    width: 100,
                    enableSorting: false,
                    visible: vm.hasRegionalSystemSupport,
                    cellTemplate: 'countryTemplate'
                }, {
                    displayName: App.localize('Type'),
                    field: 'moduleType',
                    width: 150,
                    enableSorting: false,
                    cellTemplate: 'moduleTypeTemplate'
                }, {
                    displayName: App.localize('Status'),
                    field: 'status',
                    width: 100,
                    enableSorting: false,
                    cellTemplate: 'statusTemplate'
                }, {
                    displayName: App.localize('SubmissionDate'),
                    field: 'creationTime',
                    cellFilter: 'momentFormat: \'L\'',
                    width: 150
                }],
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                    $scope.gridApi.core.on.sortChanged($scope, function (scope, callback) {
                        viewOptions.sorting = callback.length && callback[0].field ? callback[0].field + ' ' + callback[0].sort.direction : null;
                        vm.getEmailTemplates();
                    });
                    gridApi.pagination.on.paginationChanged($scope, App.setPagination(viewOptions, vm.getEmailTemplates));
                },
                data: []
            };

            function getAccessibleCountries() {
                vm.loading++;
                commonLookupSvc.getEligibleCountries().success(function (data) {
                    var elligibleCountryCodes = data.map(o => o.code)
                    vm.countries = _.filter(countries, function (c) {
                        return elligibleCountryCodes.includes(c.code)
                    });
                }).finally(function (e) {
                    vm.loading--;
                });
            }

            init();
        }
    ]);
})();
