(function () {
    var controllerId = 'host.views.panelRecruitment.mergePanelRequestsModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'abp.services.app.panelRequest', 'requestNumber',
        function ($scope, $uibModalInstance, panelRequestSvc, requestNumber) {
            function init() {
                getPanelRequests();
            }
            var vm = this;
            vm.loading = 0;
            vm.saving = 0;
            vm.confirmMerge = null;

            vm.mainPanelRequest = null;
            vm.panelRequests = [];
            vm.requestsToMerge = [];

            function getPanelRequests() {
                vm.loading++;
                panelRequestSvc.getPanelRequestForMerging({
                    id: requestNumber
                }).success(function (data) {
                    vm.mainPanelRequest = data.mainPanelRequest;
                    vm.panelRequests = data.mergeablePanelRequests;
                }).finally(function () {
                    vm.loading--;
                });
            }

            vm.save = function () {
                if (vm.saving) return;

                if (!App.isFormValid($scope.mergePanelRequestsForm)) return;

                vm.saving++;
                abp.message.confirm(App.localize('MergeXConfirmMessage', vm.mainPanelRequest.requestNumber), App.localize('AreYouSure'), function (d) {
                    if (d) {
                        panelRequestSvc.mergePanelRequests({
                            mainRequestNumber: vm.mainPanelRequest.requestNumber,
                            requestNumbersForMerge: _.map(vm.requestsToMerge, 'requestNumber')
                        }).success(function () {
                            abp.notify.info(App.localize('SuccessfullySaved'));
                            $uibModalInstance.close();
                        }).finally(function () {
                            vm.saving--;
                        });
                    } else {
                        vm.saving--;
                    }
                });
            };

            vm.checkSimilarity = function () {
                // A simple similarity check to help operator identify request that may not be the same.

                vm.dissimilarRequests = _.filter(vm.requestsToMerge, function (request) {
                    return request.panelName !== vm.mainPanelRequest.panelName;
                });
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            init();
        }
    ]);
})();
