(function () {
    angular
        .module('app')
        .controller('clinic.views.patient.admissionDetails', ['$scope', '$stateParams', 'abp.services.app.inpatientGuaranteeLetter', controller]);

    function controller($scope, $stateParams, inpatientGuaranteeLetterSvc) {
        var vm = this;
        vm.loading = 0;
        vm.guaranteeLetterNumber = $stateParams.letterNumber;

        function init() {
            vm.loading++;
            inpatientGuaranteeLetterSvc.getServiceRequestDetailsForTimelineButtons({
                id: vm.guaranteeLetterNumber
            }).success(function (data) {
                vm.details = data;
            }).finally(function (e) {
                vm.loading--;
            });
        };

        init();
    }
})();
