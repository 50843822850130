(() => {
  angular
    .module('app')
    .controller(
      'common.views.healthScreening.guaranteeLetters.index',
      GuaranteeLetterPageController
    );

  GuaranteeLetterPageController.$inject = [
    '$scope',
    '$stateParams',
    '$state',
    '$timeout',
    'abp.services.app.healthScreeningGuaranteeLetter',
    'abp.services.app.commonLookup',
    'Hms.GuaranteeLetters.GuaranteeLetterStatus',
    'moment',
    'appConstants',
  ];

  function GuaranteeLetterPageController(
    $scope,
    $stateParams,
    $state,
    $timeout,
    guaranteeLetterSvc,
    commonLookupSvc,
    enumGuaranteeLetterStatus,
    moment,
    appConstants
  ) {
    const vm = this;

    // #region Variable declaration

    vm.loading = 0;
    vm.exporting = 0;
    vm.breadcrumb = [];
    vm.pageTitle = '';
    vm.clinicLocations = [];
    vm.isPanel = App.isClinic();
    vm.isHost = App.isHost();
    vm.advancedFiltersAreShown = false;

    vm.enums = {
      guaranteeLetterStatus: enumGuaranteeLetterStatus,
    };

    vm.permissions = {
      viewClaim:
        (abp.auth.isGranted('Claims.HealthScreening.Panel') && vm.isPanel) ||
        (abp.auth.isGranted('Claims.HealthScreening.Host') && vm.isHost),
      canSubmitClaim:
        vm.isPanel &&
        (abp.auth.isGranted('Claims.HealthScreening.Panel.Create') ||
          abp.auth.isGranted('PatientTickets.Clinic.Create')),
    };

    vm.requestParams = {
      filter: $stateParams.filter,
      status: $stateParams.status,
      letterNumber: $stateParams.letterNumber,
      claimNumber: $stateParams.claimNumber,
      ticketNumber: $stateParams.ticketNumber,
      company: $stateParams.company,
      panelLocationId: $stateParams.panelLocation,
      skipCount: $stateParams.skipCount || 0,
      maxResultCount: $stateParams.maxResultCount || appConstants.grid.defaultPageSize,
      sorting: $stateParams.sorting,
    };

    vm.checkedInDateRangeOptions = App.createDateRangePickerOptions();
    vm.checkedInDateRangeModel = {
      startDate: $stateParams.checkedInStartDate ? moment($stateParams.checkedInStartDate) : null,
      endDate: $stateParams.checkedInEndDate ? moment($stateParams.checkedInEndDate) : null,
    };

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSizes,
      paginationPageSize: appConstants.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      resizable: true,
      columnDefs: [
        {
          name: 'Actions',
          displayName: 'Actions',
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
        },
        {
          displayName: App.localize('GuaranteeLetterNumber'),
          field: 'letterNumber',
          visible: !vm.isPanel,
          minWidth: 100,
          width: 150,
        },
        {
          displayName: App.localize('CheckedInDate'),
          enableSorting: false,
          field: 'sessionCheckedInTime',
          visible: vm.isPanel,
          cellFilter: "momentFormat: 'L'",
          minWidth: 70,
        },
        {
          displayName: App.localize('Status'),
          field: 'status',
          minWidth: 100,
          cellTemplate: 'statusTemplate',
        },
        {
          displayName: App.localize('ClaimNumber'),
          enableSorting: false,
          field: 'claimNumber',
          visible: vm.isHost,
          cellTemplate: 'claimNumberTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('TicketNumber'),
          enableSorting: false,
          field: 'ticketNumber',
          visible: !vm.isPanel,
          cellTemplate: 'ticketNumberTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('PanelLocation'),
          enableSorting: false,
          field: 'panelLocationName',
          visible: !vm.isPanel,
          cellTemplate: 'panelLocationTemplate',
          minWidth: 120,
        },
        {
          displayName: App.localize('PatientName'),
          enableSorting: false,
          field: 'employeeName',
          visible: vm.isPanel,
          minWidth: 120,
        },
        {
          displayName: App.localize('IcPassportNumber'),
          enableSorting: false,
          field: 'employeeNationalId',
          visible: vm.isPanel,
          cellTemplate: 'identificationNumberTemplate',
          minWidth: 120,
        },
        {
          displayName: App.localize('Patient'),
          enableSorting: false,
          field: 'employeeName',
          visible: !vm.isPanel,
          cellTemplate: 'patientTemplate',
          minWidth: 120,
        },
        {
          displayName: App.localize('Principal'),
          enableSorting: false,
          field: 'principalName',
          visible: !vm.isPanel,
          cellTemplate: 'principalTemplate',
          minWidth: 120,
        },
        {
          displayName: App.localize('Organization'),
          enableSorting: false,
          field: 'organizationName',
          visible: vm.isHost,
          cellTemplate: 'organizationTemplate',
          minWidth: 120,
        },
        {
          displayName: App.localize('Subsidiary'),
          enableSorting: false,
          field: 'subsidiaryName',
          visible: !vm.isPanel,
          minWidth: 120,
        },
        {
          displayName: App.localize('GuaranteeLetterNumber'),
          field: 'letterNumber',
          visible: vm.isPanel,
          minWidth: 100,
          width: 150,
        },
        {
          displayName: App.localize('PanelLocation'),
          enableSorting: false,
          field: 'panelLocationName',
          visible: vm.isPanel,
          minWidth: 120,
        },
        {
          displayName: App.localize('CheckedInDate'),
          enableSorting: false,
          field: 'sessionCheckedInTime',
          visible: !vm.isPanel,
          cellFilter: "momentFormat: 'L LT'",
          minWidth: 70,
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          vm.requestParams.sorting =
            callback.length && callback[0].field
              ? `${callback[0].field} ${callback[0].sort.direction}`
              : null;
          vm.getGuaranteeLetters();
        });
        gridApi.pagination.on.paginationChanged($scope, (newPage, pageSize) => {
          vm.requestParams.skipCount = (newPage - 1) * pageSize;
          vm.requestParams.maxResultCount = pageSize;
          vm.getGuaranteeLetters();
        });
      },
      data: [],
    };

    vm.getGuaranteeLetters = getGuaranteeLetters;

    // #endregion Variable declaration

    init();

    function init() {
      initBreadcrumb();
      loadClinicLocationsFilter();

      $timeout(() => {
        vm.gridOptions.paginationCurrentPage = Math.floor(
          vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1
        );
      });
    }

    // #region Retrieve breadcrumb details from URL.

    function initBreadcrumb() {
      if (vm.isPanel) {
        vm.breadcrumb.push({ name: App.localize('HealthScreening') });
        vm.breadcrumb.push({ name: App.localize('GuaranteeLetters') });
      } else {
        vm.breadcrumb.push({ name: App.localize('GuaranteeLetters') });
        vm.breadcrumb.push({ name: App.localize('HealthScreening') });
      }

      // Fallback for the breadcrumb title.

      vm.pageTitle = vm.breadcrumb.length > 0 ? vm.breadcrumb.slice(-1)[0].name : '';
    }

    // #endregion Retrieve breadcrumb details from URL.

    // #region Define function

    function loadClinicLocationsFilter() {
      vm.loading += 1;
      commonLookupSvc
        .findClinicLocations({})
        .success((data) => {
          vm.clinicLocations = data.items;
        })
        .finally(() => {
          if (!vm.clinicLocations.length) {
            vm.requestParams.panelLocationId = null;
          }
          vm.loading -= 1;
        });
    }

    function getRequestInput() {
      const input = $.extend({}, vm.requestParams);
      input.checkedInStartDate = vm.checkedInDateRangeModel.startDate;
      input.checkedInEndDate = vm.checkedInDateRangeModel.endDate;

      registerStateParams();

      return input;
    }

    function getGuaranteeLetters() {
      const input = getRequestInput();

      vm.loading += 1;
      guaranteeLetterSvc
        .getGuaranteeLetters(input)
        .success((data) => {
          vm.gridOptions.totalItems = data.totalCount;
          vm.gridOptions.data = _.map(data.items, (d) =>
            $.extend(d, {
              isActive: !(d.status === 3 || d.status === 4 || d.status === 5),
            })
          );
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function registerStateParams() {
      const checkedInStartDate = vm.checkedInDateRangeModel.startDate
        ? vm.checkedInDateRangeModel.startDate.format('YYYY-MM-DD')
        : null;
      const checkedInEndDate = vm.checkedInDateRangeModel.endDate
        ? vm.checkedInDateRangeModel.endDate.format('YYYY-MM-DD')
        : null;

      $state.transitionTo(
        $state.current,
        {
          filter: vm.requestParams.filter,
          status: vm.requestParams.status,
          letterNumber: vm.requestParams.letterNumber,
          panelLocation: vm.requestParams.panelLocationId,
          claimNumber: vm.requestParams.claimNumber,
          ticketNumber: vm.requestParams.ticketNumber,
          company: vm.requestParams.company,
          checkedInStartDate,
          checkedInEndDate,
          skipCount: vm.requestParams.skipCount,
          maxResultCount: vm.requestParams.maxResultCount,
          sorting: vm.requestParams.sorting,
        },
        {
          notify: false,
        }
      );
    }
    
    // #endregion Define function
  }
})();
