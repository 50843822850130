(function () {
    var componentId = 'hostDirectorDashboardDailySalesWidget';
    var app = angular.module('app');

    function controller($element, $filter, directorDashboardSvc, moment) {
        var dataTypeStorageKey = componentId + '_dataType';
        var currencyFilter = $filter('currencyFormat');
        var chart = null;
        var vm = this;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.loading = 0;

        vm.todayStats = null;
        vm.monthlyStats = null;

        vm.dataType = localStorage.getItem(dataTypeStorageKey) || 'Panel';

        vm.setDataType = function (value) {
            if (value) {
                localStorage.setItem(dataTypeStorageKey, value);
                vm.dataType = value;
                updateChart(vm.data);
            }
        };

        function aggregateFigure(typedFigures, dataType) {
            if (dataType === 'Panel') return typedFigures.normal;
            if (dataType === 'Reimbursement') return typedFigures.reimbursement;
            if (dataType === 'PreEmployment') return typedFigures.preEmployment;

            var baseFigure = $.extend({}, typedFigures.normal);
            [typedFigures.reimbursement, typedFigures.preEmployment].forEach(function (f) {
                if (baseFigure.ticketCount || f.ticketCount) baseFigure.ticketCount += f.ticketCount;
                if (baseFigure.ticketValue || f.ticketValue) baseFigure.ticketValue += f.ticketValue;
                if (baseFigure.momentumLeftPct || f.momentumLeftPct) baseFigure.momentumLeftPct += f.momentumLeftPct;
                if (baseFigure.uncertaintiesPct || f.uncertaintiesPct) baseFigure.uncertaintiesPct += f.uncertaintiesPct;
            });
            return baseFigure;
        }

        vm.periods = [];
        vm.selectedPeriod = null;
        vm.periodStats = {
            weekdays: 0,
            weekends: 0
        };

        function updateChart(d) {

            if (!d) return;


            vm.monthlyStats = aggregateFigure(d.monthlyStats, vm.dataType);
            var dtToday = moment().startOf('day')

            var todayFigures = null;
            var labels = ['x'];
            var ticketCount = ['ticketCount'];
            var ticketValue = ['ticketValue'];
            _.each(d.figures, function (xf) {
                labels.push(moment(xf.date).format('YYYY-MM-DD'));
                var f = aggregateFigure(xf.figures, vm.dataType);
                if (moment(xf.date).startOf('day').isSame(dtToday)) {
                    todayFigures = f;
                }

                ticketCount.push(f.ticketCount);
                ticketValue.push(f.ticketValue);
            });

            chart.load({
                columns: [labels, ticketCount, ticketValue]
            });

            if ((vm.todayStats = todayFigures) && vm.dataType === 'All') {
                vm.todayStats.momentumLeftPct = null;
            }
        }

        vm.periodChanged = function () {
            var weekdays = 0, weekends = 0;
            var dtCur = moment(vm.selectedPeriod);
            var dtNext = moment(dtCur).add(1, 'M');
            while (dtCur < dtNext) {
                var wd = dtCur.isoWeekday();
                if (wd === 6 || wd === 7) weekends++;
                else weekdays++;
                dtCur.add(1, 'd');
            }
            vm.periodStats.weekdays = weekdays;
            vm.periodStats.weekends = weekends;

            vm.loading++;
            directorDashboardSvc.getDailySalesFigures({
                monthYear: vm.selectedPeriod
            }).success(function (data) {
                vm.data = data;
                updateChart(data);
            }).finally(function () {
                vm.loading--;
            });
        };

        function sigFigs(n, sig) {
            if (n === 0) return n;
            var mult = Math.pow(10, sig - Math.floor(Math.log(n) / Math.LN10) - 1);
            return Math.round(n * mult) / mult;
        }

        vm.getMomentumTooltip = function () {
            if (vm.todayStats && vm.todayStats.momentumLeftPct < 26 && (vm.todayStats.uncertaintiesPct || vm.todayStats.uncertaintiesPct === 0)) {
                var forecastTicketValue = vm.todayStats.ticketValue / (100 - vm.todayStats.momentumLeftPct) * 100;
                var uncertaintiesValue = forecastTicketValue * vm.todayStats.uncertaintiesPct / 100;
                var minValue = sigFigs(forecastTicketValue - uncertaintiesValue, 3);
                var maxValue = sigFigs(forecastTicketValue + uncertaintiesValue, 3);

                if (minValue === maxValue)
                    return "Projected end-of-day ticket value is " + currencyFilter(forecastTicketValue, vm.currencyCode);
                return "Projected end-of-day ticket value is between " + currencyFilter(minValue, vm.currencyCode) + " to  " + currencyFilter(maxValue, vm.currencyCode);
            }
        };

        function initChart() {
            chart = c3.generate({
                bindto: $element.find('.chart-container')[0],
                size: {
                    height: 240
                },
                data: {
                    x: 'x',
                    columns: [
                        ['x'], ['ticketCount'],
                        ['ticketValue']],
                    names: {
                        x: 'Month',
                        ticketCount: 'Ticket count',
                        ticketValue: 'Ticket value'
                    },
                    type: 'spline',
                    types: {
                        ticketCount: 'bar'
                    },
                    axes: {
                        ticketCount: 'y2'
                    }
                },
                legend: {
                    show: false
                },
                grid: {
                    y: { show: true }
                },
                axis: {
                    y: {
                        tick: {
                            format: d3.format('s')
                        },
                        min: 0,
                        padding: {
                            bottom: 0
                        }
                    },
                    x: {
                        type: 'timeseries',
                        tick: {
                            format: function (x) { return moment(x).format('D (dd)'); }
                        }
                    },
                    y2: {
                        show: true
                    }
                },
                tooltip: {
                    format: {
                        value: function (value, ratio, id, index) {
                            if (id === 'ticketCount') return value;
                            return currencyFilter(value, vm.currencyCode);
                        }
                    }
                }
            });
        }

        function initPeriod() {
            var dtStart = moment('2016-04-01');
            var dtLast = moment().startOf('M');
            var dtCur = moment(dtStart);
            var v = [];
            while (dtCur <= dtLast) {
                v.push({ name: dtCur.format('MMM YYYY'), value: dtCur.toISOString() });
                dtCur.add(1, 'M');
            }
            _.reverse(v);
            vm.periods = v;
            vm.selectedPeriod = v[0].value;
        }

        function init() {
            initPeriod();
            initChart();
            vm.periodChanged();
        }

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/directorDashboard/dailySalesWidget.html'),
        controller: ['$element', '$filter', 'abp.services.app.directorDashboard', 'moment', controller],
        controllerAs: 'vm'
    });
})();
