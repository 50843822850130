(function () {
    const controllerId = 'pharmacy.views.prescriptions.fulfillModal';
    const app = angular.module('app');
    app.controller(controllerId, [
        '$uibModalInstance', '$filter', 'abp.services.app.drugSearch', 'abp.services.app.prescription', 'prescription', 'customerAllowance',
        function ($uibModalInstance, $filter, drugSearchSvc, prescriptionSvc, prescription, customerAllowance) {
            function init() {
                vm.loading++;
                prescriptionSvc.getFulfillPrescriptionOptions({
                    prescriptionNumber: vm.prescription.prescriptionNumber
                }).success(function (data) {
                    vm.options = data;
                    vm.coPayValue = data.coPayValue;

                    if (vm.coPayValue) {
                        if (vm.coPayValue.isPercentage) {
                            vm.coPayValue.title = App.localize('CopayX', vm.coPayValue.value + '%');
                            vm.coPayValue.text = App.localize('PatientHasCopayPercentage', vm.coPayValue.value + '%');
                        } else {
                            var valueText = $filter('currencyFormat')(vm.coPayValue.value, vm.currencyCode);
                            vm.coPayValue.title = App.localize('CopayX', valueText);
                            vm.coPayValue.text = App.localize('PatientHasCopayFixedAmount', valueText);
                        }
                    }

                    vm.uncoveredTreatments = _.filter(vm.options.treatments, function (d) {
                        return d.isCovered == false;
                    });

                    vm.fulfillment = {
                        prescriptionId: vm.prescription.id,
                        fulfillmentLocationId: vm.prescription.preferredLocationId,
                        referenceNumber: '',
                        remarks: '',
                        totalAmount: 0,
                        customerOrganizationAmount: 0,
                        customerAmount: 0,
                        copayAmount: 0,
                        items: _.map(vm.prescription.items, function (pi) {
                            const item = _.extend({}, pi);
                            item.strength = pi.strength && _.extend({}, pi.strength) || null;
                            item.quantity = pi.quantity && _.extend({}, pi.quantity) || null;
                            item.dosage = pi.dosage && _.extend({}, pi.dosage) || null;
                            item.dosageFrequency = pi.dosageFrequency && _.extend({}, pi.dosageFrequency) || null;
                            return item;
                        })
                    };

                    vm.tidyItems();
                }).finally(function () {
                    vm.loading--;
                });
            }

            function cleanUserEntry(entry) {
                return _.map(_.split(_.toLower(entry), ' '), function (word) {
                    return word.charAt(0).toUpperCase() + word.slice(1);
                }).join(' ');
            }

            const unitValueFilter = $filter('unitValue');
            const vm = this;
            vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
            vm.loading = 0;
            vm.saving = false;
            vm.prescription = prescription;
            vm.customerAllowance = customerAllowance;
            vm.fulfillment = null;
            vm.filteredDrugs = [];

            let lastFilter = '';
            let cachedResults = {};

            vm.tidyItems = function () {
                if (!vm.fulfillment) return;

                if (!vm.fulfillment.items) vm.fulfillment.items = [];

                // Calculate prescription

                let filledCnt = 0;
                let blankCnt = 0;
                for (let i = 0; i < vm.fulfillment.items.length; i++) {
                    if (+vm.fulfillment.items[i].drugId) {
                        filledCnt++;
                    } else {
                        blankCnt++;
                    }
                }

                // Remove superfluos empty prescriptions

                while (blankCnt > 1) {
                    for (let i = vm.fulfillment.items.length - 1; i >= 0; i--) {
                        if (!(+vm.fulfillment.items[i].drugId)) {
                            vm.fulfillment.items.splice(i, 1);
                            blankCnt--;
                        }
                    }
                }

                // Ensure there is always a blank prescription line

                if (blankCnt < 1) vm.fulfillment.items.push({});

                // Update error message

                let errorMessage = '';

                if (!errorMessage && !filledCnt) {
                    errorMessage = App.localize('KeyInOnePrescriptionProcedure');
                }

                if (!errorMessage && filledCnt) {
                    for (let i = 0; i < vm.fulfillment.items.length; i++) {
                        const p = vm.fulfillment.items[i];
                        if (!p.drugId) continue;

                        if (!p.treatmentId) {
                            errorMessage = App.localize('MissingTreatmentForX', p.name);
                            break;
                        }

                        if (!p.quantity) {
                            errorMessage = App.localize('MissingQuantityForX', p.name);
                            break;
                        }

                        if (
                            p.amount === null
                            || !isFinite(p.amount)
                            || p.amount < 0) {
                            errorMessage = App.localize('MissingOrInvalidAmountForX', p.name);
                            break;
                        }
                    }
                }

                vm.errorMessage = errorMessage;
            };

            vm.save = function () {
                if (vm.saving) return;

                const input = _.extend({}, vm.fulfillment);
                input.totalAmount = vm.getTotalAmount();
                input.customerOrganizationAmount = vm.getOrganizationAmount();
                input.customerAmount = vm.getCustomerAmount();
                input.copayAmount = vm.getCoPayAmount();
                input.items = _.chain(vm.fulfillment.items)
                    .filter('drugId')
                    .map(function (i) {
                        const item = _.extend({}, i);
                        if (item.drugId < 0) {
                            item.drugId = null;
                        }

                        var isUncovered = vm.uncoveredTreatments.findIndex(x => x.value === item.treatmentId)

                        if (isUncovered !== -1) {
                            item.isCovered = false;
                        } else {
                            item.isCovered = true;
                        }

                        return item;
                    })
                    .value()

                vm.saving++;
                prescriptionSvc.fulfillPrescription(input).success(function (data) {
                    abp.notify.info(App.localize('SuccessfullySaved'));
                    $uibModalInstance.close(data);
                }).finally(function () {
                    vm.saving--;
                });
            };

            vm.updateTreatments = function (treatmentId, index) {
                var isUncovered = vm.uncoveredTreatments.findIndex(x => x.value === treatmentId)

                if (isUncovered !== -1) {
                    vm.fulfillment.items[index].isCovered = false;
                    vm.uncoveredWarning = true;
                } else {
                    vm.fulfillment.items[index].isCovered = true;
                    vm.uncoveredWarning = false;
                }
            };

            vm.getTotalItems = function () {
                let cnt = 0;
                if (vm.fulfillment) {
                    _.forEach(vm.fulfillment.items, function (d) {
                        if (d.drugId) cnt++;
                    });
                }
                return cnt;
            };

            vm.getAmount = function () {
                var totalAmount = 0;
                var totalOrganizationAmount = 0;
                var totalCopayAmount = 0;
                var totalUncoveredTreatmentsAmount = 0;
                var exceededAmount = 0;
                var collectCash = 0;

                // Add prescription's amount.

                if (vm.fulfillment) {
                    _.forEach(vm.fulfillment.items, function (d) {
                        // Total amount.

                        if (d.drugId && d.amount) totalAmount += d.amount;

                        // Uncovered amount for uncovered treatments.

                        var isUncovered = vm.uncoveredTreatments.findIndex(i => i.value === d.treatmentId)

                        if (isUncovered !== -1) {
                            if (d.drugId && d.amount)
                                totalUncoveredTreatmentsAmount += d.amount;
                        }
                    });
                };

                // Covered amount calculation.

                totalOrganizationAmount = totalAmount - totalUncoveredTreatmentsAmount;

                // Copay calculation, if applicable.

                if (vm.coPayValue) {
                    const pharmacyCoPay = vm.coPayValue;
                    totalCopayAmount = pharmacyCoPay.value;

                    if (pharmacyCoPay.isPercentage) {
                        totalCopayAmount *= totalOrganizationAmount / 100;
                    }
                    else {
                        totalCopayAmount = Math.min(totalOrganizationAmount, totalCopayAmount);
                    }

                    exceededAmount = Math.max(totalOrganizationAmount - totalCopayAmount - vm.customerAllowance, 0);
                    collectCash = totalCopayAmount + totalUncoveredTreatmentsAmount + exceededAmount;
                }

                return {
                    totalAmount: App.roundAmount(totalAmount),
                    totalOrganizationAmount: App.roundAmount(totalAmount - collectCash),
                    totalCustomerAmount: App.roundAmount(collectCash),
                    totalCopayAmount: App.roundAmount(totalCopayAmount)
                };
            }

            vm.getTotalAmount = function () {
                return vm.getAmount().totalAmount > 0 ? vm.getAmount().totalAmount : 0;
            };

            vm.getOrganizationAmount = function () {
                return vm.getAmount().totalOrganizationAmount > 0 ? vm.getAmount().totalOrganizationAmount : 0;
            };

            vm.getCustomerAmount = function () {
                return vm.getAmount().totalCustomerAmount > 0 ? vm.getAmount().totalCustomerAmount : 0;
            };

            vm.getCoPayAmount = function () {
                return vm.getAmount().totalCopayAmount > 0 ? vm.getAmount().totalCopayAmount : 0;
            }

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            vm.lookupDrugs = function (userEntry) {
                const cleanedUserEntry = cleanUserEntry(userEntry || '');
                filter = _.toLower(cleanedUserEntry);
                lastFilter = filter;

                if (filter) {
                    const cachedDrugs = cachedResults[filter];

                    if (cachedDrugs) {
                        vm.filteredDrugs = postProcessDrugList(cachedDrugs, filter, cleanedUserEntry);
                    } else {
                        drugSearchSvc.searchDrugs({
                            keyword: filter,
                            countryCode: vm.prescription.countryCode
                        }).success(function (d) {
                            cachedResults[filter] = d.items;
                            if (filter === lastFilter) {
                                vm.filteredDrugs = postProcessDrugList(d.items, filter, cleanedUserEntry);
                            }
                        });
                    }
                } else {
                    vm.filteredDrugs = [];
                }

                function postProcessDrugList(drugList, filter, cleanedUserEntry) {
                    let clonedItems = drugList.slice(0);
                    const idx = _.findIndex(clonedItems, function (d) {
                        return _.toLower(_.trim(d.productName || '')) === filter;
                    });
                    if (idx > 0) {
                        const items = clonedItems.splice(idx, 1);
                        clonedItems.splice(0, 0, items[0]);
                    } else if (idx === -1) {
                        clonedItems.splice(0, 0, {
                            id: -1,
                            productName: cleanedUserEntry
                        });
                    }
                    return clonedItems;
                }
            };

            vm.updatePrescription = function (item) {
                if (item.drugId) {
                    const selectedDrug = _.find(vm.filteredDrugs, { id: item.drugId });
                    if (selectedDrug) {
                        if (
                            selectedDrug.strength !== null
                            && isFinite(selectedDrug.strength)
                            && selectedDrug.strengthUnit) {
                            item.strength = {
                                value: selectedDrug.strength,
                                unit: selectedDrug.strengthUnit
                            };
                            item.strength.text = unitValueFilter(item.strength);
                        } else {
                            item.strength = null;
                        }

                        item.preferredQuantityUnit = selectedDrug.quantityUnit || '';
                        item.preferredDosageUnit = selectedDrug.dosageUnit || '';
                        item.name = selectedDrug.productName;
                    }
                } else {
                    item.name = null;
                }
                vm.tidyItems();
            };

            init();
        }
    ]);
})();
