(function () {
    angular.module('app').controller('clinic.views.guaranteeLetter.extensionRequestModal', [
        '$scope', '$uibModalInstance', '$sce', 'abp.services.app.guaranteeLetter', 'patientName', 'nationalId', 'glNumber',
        function ($scope, $uibModalInstance, $sce, guaranteeLetterSvc, patientName, nationalId, glNumber) {
            var vm = this;
            vm.request = {
                patientName: patientName,
                nationalId: nationalId,
                letterNumber: glNumber
            };
            vm.notes = [
                $sce.trustAsHtml(App.localize('GlExtensionFormNoteLine1')),
                $sce.trustAsHtml(App.localize('GlExtensionFormNoteLine2')),
                $sce.trustAsHtml(App.localize('GlExtensionFormNoteLine3'))
            ];
            vm.saving = 0;

            vm.cancel = cancel;
            vm.confirm = confirm;

            function cancel() {
                $uibModalInstance.dismiss();
            }

            function confirm() {
                var errorFields = $scope.extensionRequestForm.$error.required;

                angular.forEach(errorFields, function (field) {
                    field.$setTouched();
                });

                if (!errorFields) {
                    vm.saving++;

                    guaranteeLetterSvc.requestForExtension(vm.request).success(function () {
                        abp.message.success(App.localize("GuaranteeLetterExtendSubmissionSuccessfulMessage"), App.localize('GuaranteeLetterExtendSubmissionSuccessfulTitle', glNumber));

                        $uibModalInstance.close(vm.request);
                    }).finally(function () {
                        vm.saving--;
                    });
                }
            }
        }
    ]);
})();
