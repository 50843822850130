(() => {
  angular.module('app').component('commonPostHospitalizationGuaranteeLettersCommonPatientDetailsComponent', {
    templateUrl: require('./patientDetails.component.html'),
    controller: PatientDetailsController,
    controllerAs: 'vm',
    bindings: {
      patient: '<',
      enums: '<'
    }
  });

  PatientDetailsController.$inject = [
  ];

  function PatientDetailsController(
  ) {
    
    const vm = this;

    vm.patientAgeText = App.localize('XYearsOld', vm.patient.age);
    vm.hasInpatientClaimProcessingEnhancement = abp.setting.getBoolean('Hms.Feature.InpatientClaimProcessingEnhancement');

    function init() {
    }

    vm.loading = true;

    init();

  }
})();
