import swal from 'sweetalert';

(() => {
  angular
    .module('app')
    .controller(
      'common.views.saleTransactions.invoiceDetail.singapore.page',
      InvoiceDetailSingaporePageController
    );

  InvoiceDetailSingaporePageController.$inject = [
    '$state',
    '$stateParams',
    'abp.services.app.saleTransaction',
  ];

  function InvoiceDetailSingaporePageController($state, $stateParams, saleTransactionSvc) {
    const vm = this;

    vm.loading = false;

    vm.transactionNumber = $stateParams.transactionNumber;
    vm.invoice = null;
    vm.invoiceFrom = null;
    vm.invoiceTo = null;
    vm.message = null;
    vm.payments = [];
    vm.latestPayableAmount = null;
    vm.isInpatient = App.isInpatient();

    vm.stopInvoiceGeneration = abp.setting.getBoolean('Hms.Feature.StopPortalInvoiceGeneration');

    vm.getPaymentLabel = getPaymentLabel;
    vm.send = send;

    init();

    function init() {
      vm.loading = true;
      saleTransactionSvc
        .getInvoice({
          id: vm.transactionNumber,
        })
        .success((data) => {
          const d = data;
          if (d.countryCode !== 'SG' && !d.isOrganizationInvoiceV2) {
            $state.go('invoiceDetail', {
              transactionNumber: d.invoice.transactionNumber,
            });
          }

          if (!d.invoice.departments) {
            d.invoice.departments = _.map(d.invoice.departmentsWithCategory, (dc) => {
              const items = _.flatMap(
                _.filter(dc.categories, (c) => c.saleTransactionItems),
                (n) => n.saleTransactionItems
              );
              return {
                id: dc.id,
                name: dc.name,
                saleTransactionItems: items,
                amount: _.sumBy(items, 'amount'),
              };
            });
          }

          vm.invoice = d.invoice;
          vm.invoice.isAgentInvoice = _.includes([4, 5, 7], vm.invoice.category);
          vm.invoiceFrom = d.invoiceFrom;
          vm.invoiceTo = d.invoiceTo;
          vm.payments = d.payments;
          vm.message = d.message;
          vm.categoryNames = d.categoryNames;
          vm.taxCodes = d.taxCodes;
          vm.isPharmacy = d.invoice.isPharmacy;
          vm.currencyCode = d.currencyCode;

          vm.showDepartments =
            (d.invoice.category === 1 || d.invoice.category === 2) && d.invoice.departments.length;
          vm.departmentExpenseLabel = App.localize(
            d.invoice.category === 1 ? 'ExpensesByLocation' : 'ExpensesByDepartment'
          );
          vm.departmentTaxSummaryLabel = App.localize(
            d.invoice.category === 1 ? 'TaxSummary' : 'DepartmentTaxSummary'
          );
          vm.departmentLabel = App.localize(d.invoice.category === 1 ? 'Location' : 'Department');
          vm.noDepartmentLabel = App.localize(
            d.invoice.category === 1 ? 'Unknown' : 'NoDepartment'
          );
          vm.quotaFulfillmentLabel = App.localize(
            d.invoice.category === 2 ? 'SubscriptionPlanQuotaFulfillment' : 'Unknown'
          );
          // Past activities label
          vm.pastActivitiesLabel = App.localize('PastActivities');

          // Set current payable amount.
          vm.latestPayableAmount = vm.invoice.amount - vm.invoice.knockedOffAmount;
        })
        .finally(() => {
          vm.loading = false;
        });
    }

    function getPaymentLabel() {
      return App.localize('XPaymentsMade', vm.payments.length);
    }

    function send() {
      saleTransactionSvc
        .getFinanceEmails({
          tenantId: vm.invoice.tenantId,
          subsidiaryId: vm.invoice.subsidiaryId,
        })
        .success((data) => {
          swal(
            {
              title: App.localize('SendEmailForX', vm.invoice.transactionNumber),
              text: App.localize('EmailAddresses'),
              type: 'input',
              showCancelButton: true,
              closeOnConfirm: false,
              confirmButtonColor: '#1ab394',
              inputPlaceholder: App.localize('EmailAddressesNarrative'),
              inputValue: data.emailAddresses,
              showLoaderOnConfirm: true,
            },
            (inputValue) => {
              if (inputValue === false) return false;
              if (inputValue === '') {
                swal.showInputError(App.localize('InvalidEmailAddress'));
                return false;
              }
              saleTransactionSvc
                .sendEmail({
                  transactionNumber: vm.invoice.transactionNumber,
                  emailAddresses: inputValue,
                })
                .success(() => {
                  abp.notify.info(App.localize('EmailSent'));
                  swal.close();
                });
              return false;
            }
          );
        });
    }
  }
})();
