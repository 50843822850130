(function () {
    'use strict'

    let app = angular.module('app');

    app.controller('host.views.doraemon.query.viewQuery', ['$scope', '$state', '$stateParams',
        'abp.services.app.doraemon', controller]);

    function controller($scope, $state, $stateParams, doraemonSvc) {
        var vm = this;

        vm.loading = 0;
        vm.saving = 0;
        vm.checklistItems = [];

        vm.guaranteeLetterNumber = $stateParams.letterNumber;
        vm.queryNumber = $stateParams.queryNumber;
        vm.downloadMedicalQueryForm = downloadMedicalQueryForm;

        init();

        /* Functions */

        function getQuery() {
            vm.loading++;
            doraemonSvc.getQuery({ id: vm.queryNumber })
                .success(function (data) {
                    vm.query = data;
                    vm.query.newRepliedAttachments = [];
                    AddChecklistItems(vm.query.serviceRequestQueryItems);
                }).finally(function () {
                    vm.loading--;
                });
        }

        function downloadMedicalQueryForm(item) {
            vm.exporting++;
            doraemonSvc.getMedicalQueryFormPdf({ id: item.id })
                .success(function (data) {
                    App.downloadTempFile(data);
                }).finally(function () {
                    vm.exporting--;
                });
        }

        function init() {
            getQuery();
        }

        var fileExtensions = '|' + app.consts.fileUpload.imageExtensions.concat(app.consts.fileUpload.pdfExtensions).join('|') + '|';

        vm.uploadConfig = {
            objectType: 'InpatientQueryAttachment',
            itemsLimit: 5,
            accept: 'image/*,application/pdf',
            filters: [{
                name: 'fileFilter',
                fn: function (n) {
                    var t = '|' + n.type.slice(n.type.lastIndexOf('/') + 1) + '|';
                    if (fileExtensions.indexOf(t) === -1) {
                        abp.message.warn(App.localize('File_Warn_FileType'));
                        return false;
                    }
                    return true;
                }
            }, {
                name: 'sizeFilter',
                fn: function (n) {
                    var sizeLimit = 52428800;
                    if (n.size > sizeLimit) {
                        abp.message.warn(App.localize('File_Warn_XSizeLimit', sizeLimit / 1024 / 1024));
                        return false;
                    }
                    return true;
                }
            }]
        };

        function AddChecklistItems(items) {
            if (_.some(items, function (item) { return item.type === 1 })) {
                vm.checklistItems.push({ text: App.localize('CompletedMedicalQueryForm'), required: true });
            }
            _.forEach(items, function (item) {
                if (item.type === 2) {
                    _.forEach(item.questions, function (question) {
                        vm.checklistItems.push({ text: question.documentName, required: true });
                    });
                }
            });
        }
    }
})();
