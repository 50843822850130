(function () {
    var controllerId = 'host.views.doraemon.glDetails.page';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$state', '$stateParams', 'abp.services.app.doraemon',
        function ($state, $stateParams, doraemonSvc) {
            var vm = this;
            vm.loading = 0;
            vm.letterNumber = $stateParams.letterNumber;
            vm.isDetailPage = true;

            function getGlDetails() {
                vm.loading++;
                doraemonSvc.getGlDetails({
                    id: vm.letterNumber
                }).success(function (data) {
                    vm.data = data;
                    vm.patient = data.patient;
                }).finally(function () {
                    vm.loading--;
                });
            }
            getGlDetails();
        }
    ]);
})();
