import swal from 'sweetalert';

(() => {
  const componentId = 'hostOperationsDashboardTxtFilesComponent';

  angular.module('app').component(componentId, {
    templateUrl: require('./txtFiles.component.html'),
    controller: OperationsDashboardTxtFilesController,
    controllerAs: 'vm',
    bindings: {
      widgets: '<',
      onAddWidget: '&',
    },
  });

  OperationsDashboardTxtFilesController.$inject = [
    '$scope',
    '$timeout',
    '$interval',
    '$uibModal',
    'abp.services.app.insurerTxt',
    'abp.services.app.commonLookup',
    'appConstants',
    'Hms.DataExports.DataExportRecordStatus',
  ];

  function OperationsDashboardTxtFilesController(
    $scope,
    $timeout,
    $interval,
    $uibModal,
    insurerTxtSvc,
    commonLookupSvc,
    appConstants,
    enumDataExportRecordStatus
  ) {
    const vm = this;
    const DEFAULT_MAX_COUNT = 10;

    vm.loading = 0;
    vm.downloading = 0;
    vm.totalFailedCount = 0;
    vm.txtStatuses = Object.values(enumDataExportRecordStatus);

    vm.getInsurerTxtFiles = getInsurerTxtFiles;
    vm.downloadTxtFile = downloadTxtFile;
    vm.regenerateTxtFile = regenerateTxtFile;
    vm.intervalRefresh = intervalRefresh;

    vm.requestParams = {
      skipCount: 0,
      maxResultCount: DEFAULT_MAX_COUNT,
      insurerId: null,
      ticketNumber: null,
      patientFilter: null,
      txtStatus: null,
    };

    vm.enums = {
      dataExportRecordStatus: enumDataExportRecordStatus,
    };

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSizes,
      paginationPageSize: 10,
      useExternalPagination: true,
      columnDefs: [
        {
          name: 'Actions',
          displayName: 'Actions',
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'insuranceTxtActionTemplate',
        },
        {
          displayName: App.localize('TicketNumber'),
          field: 'ticketNumber',
        },
        {
          displayName: App.localize('Patient'),
          field: 'patient',
          cellTemplate: 'patientTemplate',
        },
        {
          displayName: App.localize('Insurer'),
          field: 'insurer',
          cellTemplate: 'insurerTemplate',
        },
        {
          displayName: 'Txt Status',
          field: 'txtStatus',
          cellTemplate: 'txtStatusTemplate',
        },
      ],
      onRegisterApi: (gridApi) => {
        $scope.gridApi = gridApi;
        gridApi.pagination.on.paginationChanged($scope, (pageNumber, pageSize) => {
          vm.requestParams.skipCount = (pageNumber - 1) * pageSize;
          vm.requestParams.maxResultCount = pageSize;
          vm.getInsurerTxtFiles();
        });
      },
      data: [],
    };

    vm.$onInit = () => {
      $timeout(() => {
        vm.gridOptions.paginationCurrentPage = Math.floor(
          vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1
        );
      });

      getListingFilterOptions();
      getInsurerTxtFiles();
    };

    function getListingFilterOptions() {
      // Get insurers.

      vm.loading += 1;
      commonLookupSvc
        .getInsurers()
        .success((data) => {
          vm.insurers = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getInsurerTxtFiles() {
      vm.loading += 1;

      insurerTxtSvc
        .getInsurerTxtFilesListing(vm.requestParams)
        .success((data) => {
          vm.gridOptions.data = data.items;
          vm.gridOptions.totalItems = data.totalCount;
        })
        .finally(() => {
          vm.loading -= 1;
        });

      getFailedInsurerTxtFilesCount();
    }

    function downloadTxtFile(ticketId) {
      vm.loading += 1;

      insurerTxtSvc
        .downloadInsurerTxtFile(ticketId)
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function regenerateTxtFile(ticketNumber, id, txtStatus) {
      const invalidStatuses = [
        vm.enums.dataExportRecordStatus.InQueue.id,
        vm.enums.dataExportRecordStatus.InProgress.id,
      ];

      if (invalidStatuses.includes(txtStatus))
        swal({
          title: App.localize('InvalidTxtRegenerationTItle'),
          text: App.localize('TxtFileGenerationInProgress'),
          type: 'error',
          closeOnConfirm: true,
          confirmButtonText: App.localize('Dismiss'),
        });
      else {
        const input = {
          ticketNumbers: [ticketNumber],
          insurerId: id,
        };

        vm.loading += 1;

        insurerTxtSvc
          .generateTxtFile(input)
          .success((data) => {
            if (data.errors){
              $uibModal.open({
                templateUrl: require('./generateResultModal.html'),
                controller: 'host.views.operationsDashboard.txtFiles.generateResultModal as vm',
                backdrop: 'static',
                resolve: {
                  errors() {
                    return data.errors;
                  },
                },
              })
            } else {
              App.downloadTempFile(data);
            }
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    }

    function getFailedInsurerTxtFilesCount() {
      vm.loading += 1;

      insurerTxtSvc
        .getFailedInsurerTxtFilesCount()
        .success((count) => {
          vm.totalFailedCount = count;
          setTotalFailedCount();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function setTotalFailedCount() {
      const item = _.find(vm.widgets, (data) => {
        const widgetId = 'hostOperationsDashboardTxtFilesComponent';
        return data.id === widgetId;
      });
      item.totalCount = vm.totalFailedCount;
      item.loading = false;
    }

    function intervalRefresh() {
      if (vm.loading) return;

      getInsurerTxtFiles();
    }
  }
})();
