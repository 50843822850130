(function () {
    var app = angular.module('app');
    app.controller('host.views.employees.inpatientGlHistory.inpatientGlHistoryModal',  
        ['$uibModalInstance', 'abp.services.app.hostEmployee', 'employeeId', 'serviceType', 'Hms.GuaranteeLetters.GuaranteeLetterStatus', 'Hms.ServiceRequests.ServiceRequestStatus', 'Hms.GuaranteeLetters.GuaranteeLetterState', controller]);

    function controller($uibModalInstance, hostEmployeeSvc, employeeId, serviceType, enumGuaranteeLetterStatus, enumServiceRequestStatus, enumGuaranteeLetterState) {
        function init() {
            getInpatientGuaranteeLetters(0, serviceType);
        }
        var vm = this;
        vm.loading = 0;
        vm.letters = [];

        vm.loadMoreLetters = loadMoreLetters;
        vm.cancel = cancel;

        vm.enums = {};
        vm.enums.guaranteeLetterStatus = enumGuaranteeLetterStatus;
        vm.enums.serviceRequestStatus = enumServiceRequestStatus;
        vm.enums.guaranteeLetterState = enumGuaranteeLetterState;

        function getInpatientGuaranteeLetters(skipCount, serviceType) {
            vm.loading++;
            hostEmployeeSvc.getRecentInpatientGuaranteeLetters({
                filter: employeeId,
                skipCount: skipCount,
                maxResultCount: 10,
                serviceType: serviceType
            }).success(function (data) {
                vm.totalCount = data.totalCount;
                vm.letters = data.items;
                vm.hasMoreLetters = data.totalCount > vm.letters.length;
            }).finally(function () {
                vm.loading--;
            });
        }

        function loadMoreLetters() {
            getInpatientGuaranteeLetters(vm.letters.length);
        }

        function cancel() {
            $uibModalInstance.dismiss();
        }

        init();
    }
})();
