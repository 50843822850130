(function () {
  'use strict';

  angular.module('app').component('clinicPanelDashboardMedicationComponent', {
    templateUrl: require('/App/clinic/views/panelDashboard/medication/medication.component.html'),
    controller: ['$sce', 'abp.services.app.panelDashboard', controller],
    controllerAs: 'vm'
  });

  function controller($sce, panelDashboardSvc) {
    var vm = this;

    function init() {
      vm.loading++;
      panelDashboardSvc.getCustomerServiceWhatsappUrl()
        .success(function (data) {
          vm.whatsAppUrl = data.linkUrl;
          vm.phoneNumber = vm.whatsAppUrl.match(/\d/g)?.join("").replace(/(\d{4})(\d{3})(\d{4})/, '$1-$2 $3');

          // Bind the html to variable to avoid function call.
          vm.whatsappNarrative = $sce.trustAsHtml(App.localize('PanelCustomerServiceContactDetails', vm.phoneNumber));
        }).finally(function () {
          vm.loading--;
        });
    }

    vm.openWhatsAppUrl = function () {
      window.open(vm.whatsAppUrl);
      $uibModalInstance.close();
    };

    init();
  }
})();
