(() => {
    const componentId = 'financeManualTransactionRequestsCreateEditManualTransactionRequestSetupComponent';
    const app = angular.module('app');

    app.component(componentId, {
        templateUrl: require('./setup.component.html'),
        controller: [
            SetupController
        ],
        controllerAs: 'vm',
        bindings: {

        },
    });

    SetupController.$inject =[
        '$stateParams',
        '$state',
    ];

    function SetupController($stateParams, $state){
        const vm = this;

        function init(){

        }

        init();
    }
  })();