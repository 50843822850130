(() => {
  angular.module('app').component('commonInpatientTicketsDetailPrePostTicketInfoComponent', {
    templateUrl: require('./prePostTicketInfo.component.html'),
    controller: PrePostTicketInfoController,
    controllerAs: 'vm',
    bindings: {
      ticket: '<',
      isHost: '<',
      diseaseClassifications: '<',
      isEdit: '<',
      treatments: '<',
      isCreate: '<',
      currencyCode: '<',
      coPay: '<',
      isTicketV2: '<',
    },
  });

  PrePostTicketInfoController.$inject = [
    'Hms.PatientTickets.PatientTicketItem',
    'moment',
    '$state',
  ];

  function PrePostTicketInfoController(constsPatientTicketItem, moment, $state) {
    const vm = this;

    vm.constsPatientTicketItem = constsPatientTicketItem;
    vm.loading = 0;
    vm.diagnoses = [];
    vm.diagnosesDisplay = '';
    vm.isHost = App.isHost();

    vm.onTreatmentAmountDirtyEvent = {
      handler: null,
    };
    vm.isEdit = false;
    vm.saveTicketAmountTrigger = 0;
    vm.finishTicketAmountWithoutSavingTrigger = 0;

    vm.hasInsuranceEnhancementModule = abp.setting.getBoolean(
      'Hms.Feature.InsuranceEnhancementModule'
    );

    vm.checkDuplicateTreatment = checkDuplicateTreatment;
    vm.updateTreatment = updateTreatment;
    vm.addTreatments = addTreatments;
    vm.clearTreatments = clearTreatments;
    vm.removeTreatment = removeTreatment;
    vm.getTotalBillAmount = getTotalBillAmount;
    vm.calculateAmountCoverage = calculateAmountCoverage;
    vm.getManualPaymentAmount = getManualPaymentAmount;
    vm.getCoveredAmount = getCoveredAmount;
    vm.getUncoveredAmount = getUncoveredAmount;
    vm.getMcDurationDescription = getMcDurationDescription;
    vm.isDirty = isDirty;
    vm.onSuccessSaveTicketAmount = onSuccessSaveTicketAmount;
    vm.onUpdateTicketAmount = onUpdateTicketAmount;
    vm.getTotalCoveredAmount = getTotalCoveredAmount;
    vm.getCoPay = getCoPay;
    vm.getCoPayAmount = getCoPayAmount;
    vm.getEligibleClaimAmount = getEligibleClaimAmount;
    vm.getEntitledAmount = getEntitledAmount;
    vm.getTotalExceededAmount = getTotalExceededAmount;
    vm.getTotalPayableAmount = getTotalPayableAmount;
    vm.getTotalCollectCashAmount = getTotalCollectCashAmount;

    init();

    function init() {
      if (!vm.ticket.ticketItems) {
        vm.ticket.ticketItems = [];
      }
      _.each(vm.ticket.ticketItems, (ticketItem) => {
        const ti = ticketItem;
        ti.treatment = _.find(vm.treatments, ['treatmentId', ti.treatmentId]);
      });

      if (vm.isTicketV2) {
        vm.diagnoses = _.map(vm.ticket.diagnoses, (d) => ({
          code: d.code,
          description: d.description,
          displayName: d.code !== null ? `(${d.code}) ${d.description}` : `${d.description}`,
        }));
        vm.diagnosesDisplay = vm.diagnoses.map((_) => _.displayName).join(', ');
      }
    }

    function getMcDurationDescription() {
      if (vm.ticket.medicalCertificate) {
        if (vm.ticket.ticketType !== 0) {
          return App.localize('XDays', vm.ticket.medicalCertificate.effectiveMcDays);
        }
        const startDate = moment(vm.ticket.medicalCertificate.startDate);
        const endDate = moment(vm.ticket.medicalCertificate.endDate);
        const duration = endDate.diff(startDate, 'days') + 1;
        return App.localize('XDays', duration);
      }
      return '';
    }

    function checkDuplicateTreatment(treatment) {
      if (treatment.isDuplicable) return false;

      return _.some(vm.ticket.ticketItems, (t) => t.treatmentId === treatment.treatmentId);
    }

    function updateTreatment(treatment) {
      const t = treatment;
      if (t.treatment) {
        if (vm.checkDuplicateTreatment(t.treatment)) {
          abp.message.error(App.localize('DuplicateTreatmentValidationErrorMessage'));
          t.treatmentId = null;
          t.treatment = null;
          t.amount = null;
          return;
        }

        t.treatmentId = t.treatment.treatmentId;
      } else {
        t.treatmentId = null;
      }
    }

    function addTreatments() {
      vm.ticket.ticketItems.push({});
    }

    function clearTreatments() {
      vm.ticket.ticketItems = [];
    }

    function removeTreatment(index) {
      if (index < vm.ticket.ticketItems.length) {
        vm.ticket.ticketItems.splice(index, 1);
      }
    }

    function getTotalBillAmount() {
      let amount = 0;
      if (vm.isTicketV2) {
        amount = calculateAmountCoverage().total;
      } else {
        _.each(vm.ticket.ticketItems, (t) => {
          if (t.treatment && (t.amount || t.uncoveredAmount)) {
            amount += t.amount || 0;
            amount += t.uncoveredAmount || 0;
          }
        });
      }
      return amount;
    }

    function calculateAmountCoverage() {
      let covered = 0;
      let total = 0;
      let uncovered = 0;
      if (vm.isTicketV2) {
        _.forEach(vm.ticket.sections, (item) => {
          _.forEach(item.treatmentCategories, (d) => {
            covered += d.coveredAmount || 0;
            uncovered += d.uncoveredAmount || 0;
            total += covered + uncovered || 0;
          });
        });
      } else {
        _.each(vm.ticket.ticketItems, (t) => {
          if (t.treatment) {
            if (!t.treatment.notCovered) {
              covered += t.amount || 0;
              uncovered += t.uncoveredAmount || 0;
            } else {
              uncovered += t.uncoveredAmount || 0;
            }
            total += t.amount || 0;
            total += t.uncoveredAmount || 0;
          }
        });
      }

      // Round number to 2 decimal places.

      total = App.roundAmount(total);
      covered = App.roundAmount(covered);
      let manualPayment = total - covered;

      // Apply the copay amount if exists.

      const copayAmount = vm.ticket.coPayAmount || 0;
      manualPayment += copayAmount;

      return {
        total,
        covered,
        uncovered,
        copayAmount,
        manualPayment: App.roundAmount(manualPayment),
      };
    }

    function getManualPaymentAmount() {
      return vm.calculateAmountCoverage().manualPayment;
    }

    function getCoveredAmount() {
      return vm.calculateAmountCoverage().covered;
    }

    function getUncoveredAmount() {
      return vm.calculateAmountCoverage().uncovered;
    }

    function isDirty() {
      if (vm.onTreatmentAmountDirtyEvent.handler) {
        return vm.onTreatmentAmountDirtyEvent.handler();
      }
      return false;
    }

    function onSuccessSaveTicketAmount() {
      vm.isEdit = false;
      $state.reload();
    }

    function onUpdateTicketAmount() {
      let manualPayment = 0;
      let coveredAmount = 0;

      _.forEach(vm.ticket.treatments, (t) => {
        const uncoveredAmount = t.uncoveredAmount || 0;
        const amount = t.amount || 0;

        manualPayment += uncoveredAmount;
        coveredAmount += amount;
      });

      let coPay = vm.ticket.claimIsCoPayPercentage
        ? (coveredAmount * vm.ticket.claimCoPayValue) / 100
        : vm.ticket.claimCoPayValue || 0;

      if (coveredAmount < coPay) {
        coPay = coveredAmount;
      } else {
        coPay = _.min([coveredAmount, coPay]);
      }

      vm.newUncoveredAmount = manualPayment;
      vm.newManualPayment = manualPayment + coPay;
      vm.newCoveredAmount = coveredAmount - coPay;
      vm.newCoPayAmount = coPay;
    }

    function getCoPay() {
      return vm.coPay;
    }

    function getCoPayAmount() {
      return vm.calculateAmountCoverage().copayAmount > 0
        ? vm.calculateAmountCoverage().copayAmount
        : 0;
    }

    function getTotalCoveredAmount() {
      return getTotalBillAmount() - getUncoveredAmount();
    }

    function getEligibleClaimAmount() {
      return getTotalCoveredAmount() - getCoPayAmount();
    }

    function getEntitledAmount() {
      if (!vm.isCreate) return vm.ticket.coveredAmount;

      const { allowance } = vm.ticket;
      const eligibleClaimAmount = getEligibleClaimAmount();

      return Math.min(eligibleClaimAmount, allowance);
    }

    function getTotalExceededAmount() {
      return getEligibleClaimAmount() - getEntitledAmount();
    }

    function getTotalPayableAmount() {
      return getEligibleClaimAmount() - getTotalExceededAmount();
    }

    function getTotalCollectCashAmount() {
      return getUncoveredAmount() + getCoPayAmount() + getTotalExceededAmount();
    }
  }
})();
