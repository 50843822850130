(function () {
    const controllerId = 'clinic.views.patient.previewTicket';
    let app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', '$sce', '$filter', 'abp.services.app.patientTicket', 'abp.services.app.commonLookup', 'moment',
        function ($scope, $state, $stateParams, $sce, $filter, patientTicketSvc, commonLookupSvc, moment) {
            function init() {
                getTenantCurrencyCode();
                getTicket();

                //To resolve race condition
                setTimeout(function () {
                    $scope.$broadcast('prescriptionTicket');
                }, 1000);
            }

            function getTicket() {
                vm.loading++;
                patientTicketSvc.getPanelTicketForEdit({
                    employeeId: $stateParams.employeeId,
                    ticketNumber: $stateParams.ticketNumber,
                    clinicLocationId: $stateParams.clinicLocationId,
                    clinicType: $stateParams.clinicType,
                    ticketType: $stateParams.ticketType,
                    claimId: $stateParams.claimId
                }).success(function (data) {
                    vm.doctors = data.doctors || [];
                    vm.patient = data.patient;
                    vm.clinic = data.clinic || {};
                    vm.treatments = data.treatments || [];
                    vm.symptoms = data.symptom || [];
                    vm.ticketNumber = data.ticket.ticketNumber;
                    vm.employeeId = data.patient.id;
                    vm.ticketDate = moment(data.ticket.ticketDate);
                    vm.ticket = data.ticket;
                    vm.countryCode = data.countryCode;

                    // Get tax type.

                    vm.tax = data.taxDetails;
                    vm.hasTax = vm.tax && vm.tax.taxPercentage != null && vm.tax.taxPercentage > 0;

                    

                    // Prefill with draft if any.

                    if (data.ticketDraft) {
                        const draft = JSON.parse(data.ticketDraft);
                        vm.ticket = draft;
                    }

                    if (vm.isLateSubmission) {
                        vm.validateLateSubmission();
                    }

                    vm.treatmentData = [];

                    if (vm.ticket.consultation) {
                        var consultationTreatment = _.find(vm.treatments, function (q) {
                            return q.displayName === 'Consultation';
                        });

                        var symptoms = _.join(vm.ticket.consultation.symptoms, ', ');
                        var prescriptions = [];
                        prescriptions.push(setManualPrescription(symptoms, vm.ticket.consultation.amount));

                        if (vm.ticket.referralLetter) {
                            prescriptions.push(
                                setManualPrescription(
                                    App.localize('ReferralLetter'),
                                    vm.ticket.referralLetter.amount
                                )
                            );
                        }

                        vm.treatmentData.push({
                            treatmentName: consultationTreatment.displayName,
                            prescriptions: prescriptions
                        });
                    }

                    _.each(vm.ticket.treatments, function (treatment) {
                        if (treatment.treatment) {
                            var result = _.chain(treatment.prescriptions)
                                .filter('drugId')
                                .map(function (k) {
                                    return {
                                        productName: k.name,
                                        quantity: unitValueFilter(k.quantity),
                                        amount: k.amount,
                                        strength: k.strength ? k.strength.value : null,
                                        strengthUnit: k.strength ? k.strength.unit : null,
                                        dosage: getDosageAsString(k.dosage, k.dosageFrequency)
                                    };
                                })
                                .value();

                            vm.treatmentData.push({
                                treatmentName: treatment.treatment.displayName,
                                prescriptions: result
                            });
                        }
                    });

                    vm.pharmacyLocationName = '';
                    vm.pharmacyTreatments = [];
                    if (vm.ticket.pharmacyPrescription && vm.ticket.pharmacyPrescription.issue !== false) {
                        _.forEach(vm.ticket.pharmacyPrescription.treatments, function (treatment) {
                            if (treatment.treatment) {
                                var result = _.chain(treatment.prescriptions)
                                    .filter('drugId')
                                    .map(function (k) {
                                        return {
                                            productName: k.name,
                                            quantity: unitValueFilter(k.quantity),
                                            amount: k.amount,
                                            strength: k.strength ? k.strength.value : null,
                                            strengthUnit: k.strength ? k.strength.unit : null,
                                            dosage: getDosageAsString(k.dosage, k.dosageFrequency)
                                        };
                                    })
                                    .value();

                                vm.pharmacyTreatments.push({
                                    treatmentName: treatment.treatment.displayName,
                                    prescriptions: result
                                });
                            }
                        });

                        const location = _.find(data.pharmacyLocations,
                            { value: vm.ticket.pharmacyPrescription.pharmacyLocationId });
                        if (location) vm.pharmacyLocationName = location.name;
                    }

                    vm.doctor = _.find(vm.doctors, function (s) { return s.id === vm.ticket.doctorId; });

                    if (vm.ticket.medicalCertificate) {
                        vm.ticket.medicalCertificate.startDate = moment(vm.ticket.medicalCertificate.startDate);
                        vm.ticket.medicalCertificate.endDate = moment(vm.ticket.medicalCertificate.endDate);
                        vm.ticket.checkedInTime = moment(vm.ticket.checkedInTime);
                        vm.isMcStartDateAlsoCheckedInDate = vm.ticket.medicalCertificate.startDate.diff(vm.ticket.checkedInTime.startOf('days'), 'days') === 0;
                    }
                }).error(function () {
                    $window.history.back();
                }).finally(function () {
                    vm.loading--;
                });
            }

            function setManualPrescription(prescriptionName, amount) {
                return {
                    productName: prescriptionName,
                    dosage: '-',
                    quantity: '-',
                    amount: amount
                };
            }

            const unitValueFilter = $filter('unitValue');
            const frequencyUnitValueFilter = $filter('frequencyUnitValue');

            var vm = this;
            vm.loading = 0;
            vm.isClinic = App.isClinic();
            vm.isHost = App.isHost();
            vm.saving = 0;
            vm.isUpdated = false;
            vm.employeeId = $stateParams.employeeId;
            vm.clinicLocationId = $stateParams.clinicLocationId;
            vm.ticketType = $stateParams.ticketType;
            vm.patientSessionId = $stateParams.ticketType;
            vm.isLateSubmission = $stateParams.patientSessionId > 0;
            vm.lateSubmissionReason = $stateParams.lateSubmissionReason;
            vm.lateSubmissionRequestorName = $stateParams.lateSubmissionRequestorName;
            vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');

            vm.validateLateSubmission = function () {
                if (typeof $stateParams.requestorName === 'undefined'
                    || typeof $stateParams.reason === 'undefined'
                    || $stateParams.requestorName === null
                    || $stateParams.reason === null) {
                    abp.message.error(App.localize('MissingLateSubmissionInformation'));
                    $state.go('clinic.panelDashboard');
                }
            };

            vm.getMcNarrative = function () {
                if (vm.ticket.medicalCertificate) {
                    var patientName = '<strong>' + vm.patient.name + '</strong>';
                    var corporateName = '<strong>' + vm.patient.corporate.name + '</strong>';
                    var startDate = moment(vm.ticket.medicalCertificate.startDate).format('L');
                    var endDate = moment(vm.ticket.medicalCertificate.endDate).format('L');
                    return $sce.trustAsHtml(App.localize('McNarrative',
                        patientName,
                        corporateName,
                        vm.getMcDuration(),
                        startDate,
                        endDate)
                    );
                }
            };

            vm.getMcDuration = function () {
                if (vm.ticket.medicalCertificate) {
                    var startDate = moment(vm.ticket.medicalCertificate.startDate);
                    var endDate = moment(vm.ticket.medicalCertificate.endDate);
                    var duration = endDate.diff(startDate, 'days') + 1;
                    return duration;
                }
                return '';
            };

            vm.getSubtotalAmount = function () {
                var amount = 0;
                if (vm.ticket) {
                    _.each(vm.ticket.treatments, function (t) {
                        _.each(t.prescriptions, function (p) {
                            if (t.treatment && p.amount) {
                                amount += p.amount;
                            }
                        });
                    });
                    if (vm.ticket.consultation)
                        amount += vm.ticket.consultation.amount;
                    if (vm.ticket.referralLetter)
                        amount += vm.ticket.referralLetter.amount;
                }
                if (vm.hasTax && vm.tax.isTaxInclusive) return amount / (1 + vm.tax.taxPercentage / 100);
                else return amount;
            };

            vm.getTaxAmount = function () {
                if (vm.ticket && vm.ticket.treatments && !vm.tax.isTaxInclusive)
                    return vm.getSubtotalAmount() * (vm.tax.taxPercentage / 100);
                else if (vm.ticket && vm.ticket.treatments && vm.tax.isTaxInclusive)
                    return vm.getSubtotalAmount() * (1 + vm.tax.taxPercentage / 100) - vm.getSubtotalAmount();
                else return 0;
            }

            vm.getTotalAmount = function () {
                if (vm.ticket && vm.ticket.treatments) return vm.hasTax ? vm.getSubtotalAmount() + vm.getTaxAmount() : vm.getSubtotalAmount();
                else return 0;
            };

            vm.calculateAmountCoverage = function () {
                var claimable = 0;
                var total = 0;
                var uncovered = 0;
                var coPay = 0;
                var exceeded = 0
                var collectCash = 0;

                if (vm.ticket) {
                    _.each(vm.ticket.treatments, function (t) {
                        if (t && t.prescriptions) {
                            _.each(t.prescriptions, function (p) {
                                var rate = p.amount;
                                if (t.treatment && rate) {
                                    if (!t.treatment.notCovered) {
                                        claimable += rate;
                                    }
                                    else {
                                        uncovered += rate;
                                    }
                                    total += rate;
                                }
                            });
                        }
                    });

                    if (vm.ticket.consultation) {
                        var consultationAmount = vm.ticket.consultation.amount;
                        var consultationTreatment = _.find(vm.treatments, function (q) { return q.displayName === 'Consultation'; });
                        if (!consultationTreatment.notCovered) {
                            claimable += consultationAmount;
                        } else {
                            uncovered += consultationAmount;
                        }
                        total += consultationAmount;

                        if (vm.ticket.referralLetter) {
                            if (!consultationTreatment.notCovered) {
                                claimable += vm.ticket.referralLetter.amount;
                            } else {
                                uncovered += vm.ticket.referralLetter.amount;
                            }

                            total += vm.ticket.referralLetter.amount;
                        }
                    }
                }

                // Add tax to total and claimable amount.

                if (vm.hasTax && vm.tax.taxPercentage  && !vm.tax.isTaxInclusive) {
                    total += App.roundAmount(total * vm.tax.taxPercentage / 100);
                    claimable += App.roundAmount(claimable * vm.tax.taxPercentage / 100);
                    uncovered += App.roundAmount(uncovered * (vm.tax.taxPercentage / 100));
                }

                // NOTE: Co-payment is now based on covered amount.
                // More info: HM-4333.
                // Calculation for co-payment: covered amount * co-pay percentage.

                if (vm.patient && vm.ticket) {
                    // Apply co-pay for normal/reimbursement tickets.

                    if (claimable) {
                        if (vm.patient.coPay) {
                            coPay = vm.patient.coPay.value;

                            // Calculate new co-pay amount.

                            if (vm.patient.coPay.isPercentage) {
                                coPay *= claimable / 100;
                            } else {
                                coPay = Math.min(claimable, coPay);
                            }
                        }
                    }
                    exceeded = Math.max(claimable - coPay - vm.patient.allowance, 0);
                    collectCash = coPay + uncovered + exceeded;
                }

                // Round number to 2 decimal places.

                total = App.roundAmount(total);
                collectCash = App.roundAmount(collectCash);
                coPay = App.roundAmount(coPay);
                exceeded = App.roundAmount(exceeded);
                collectCash = App.roundAmount(collectCash);

                return {
                    claimable: App.roundAmount(total - collectCash),
                    manualPayment: collectCash,
                    uncovered: uncovered,
                    exceeded: exceeded,
                    copay: coPay
                };
            };

            vm.getManualPaymentAmount = function () {
                return vm.calculateAmountCoverage().manualPayment;
            };

            vm.getCoveredAmount = function () {
                return vm.calculateAmountCoverage().claimable;
            };

            vm.getUncoveredAmount = function () {
                return vm.calculateAmountCoverage().uncovered;
            };

            vm.getExceededAmount = function () {
                return vm.calculateAmountCoverage().exceeded;
            };

            vm.getCoPayAmount = function () {
                return vm.calculateAmountCoverage().copay;
            };

            vm.submitTicket = function () {
                if (vm.saving !== 0) return;
                vm.saving++;

                var input = {
                    doctorId: vm.ticket.doctorId,
                    treatments: _.chain(vm.ticket.treatments)
                        .filter('treatmentId')
                        .map(function (t) {
                            return {
                                treatmentId: t.treatmentId,
                                prescriptions: _.chain(t.prescriptions)
                                    .filter('drugId')
                                    .map(function (p) {
                                        return p.drugId > 0 ? p
                                            : _.extend({}, p, { drugId: null });
                                    })
                                    .value()
                            };
                        })
                        .value(),
                    manualPayment: vm.getManualPaymentAmount(),
                    clinicLocationId: vm.clinicLocationId,
                    medicalCertificate: vm.ticket.medicalCertificate,
                    referralLetter: vm.ticket.referralLetter,
                    clinicType: vm.ticket.clinicType,
                    ticketDate: vm.ticketDate,
                    ticketType: vm.ticket.ticketType,
                    attachments: null,
                    newAttachments: vm.ticket.attachments,
                    consultation: vm.ticket.consultation,
                    symptoms: vm.ticket.symptoms,
                    preTaxAmount: vm.hasTax ? vm.getSubtotalAmount() : vm.getTotalAmount(),
                    requestId: vm.ticket.requestId
                };

                if (vm.ticket.pharmacyPrescription && vm.ticket.pharmacyPrescription.issue !== false) {
                    input.pharmacyPrescription = _.extend({}, vm.ticket.pharmacyPrescription);
                    input.pharmacyPrescription.treatments = _.chain(input.pharmacyPrescription.treatments)
                        .filter('treatmentId')
                        .map(function (t) {
                            return {
                                treatmentId: t.treatmentId,
                                prescriptions: _.chain(t.prescriptions)
                                    .filter('drugId')
                                    .map(function (p) {
                                        return p.drugId > 0 ? p
                                            : _.extend({}, p, { drugId: null });
                                    })
                                    .value()
                            };
                        })
                        .value();
                }

                patientTicketSvc.createTicketForPanel($.extend(input, {
                    employeeId: vm.employeeId
                })).success(function (data) {
                    abp.notify.info(App.localize('TicketXIssued', data.ticketNumber));
                    vm.isUpdated = true;

                    $state.go('clinic.patientTicketSubmited', {
                        ticketNumber: data.ticketNumber,
                        hasMc: vm.ticket.medicalCertificate !== null,
                        cashAmount: vm.getManualPaymentAmount(),
                        subsidiaryName: vm.patient.corporate.subsidiaryName,
                        hasPrescription: vm.ticket.pharmacyPrescription
                            && vm.ticket.pharmacyPrescription.issue !== false
                    });
                }).finally(function () {
                    vm.saving--;
                });
            };

            function getDosageAsString(dosage, dosageFrequency) {
                let tokens = [unitValueFilter(dosage), frequencyUnitValueFilter(dosageFrequency)];
                tokens = _.filter(tokens, function (t) { return t; });

                if (tokens.length) return tokens.join(', ');
                return '-';
            }

            function getTenantCurrencyCode() {
                vm.loading++;
                commonLookupSvc.getTenantCurrencyCode()
                    .success(function (data) {
                        vm.currencyCode = data;
                    }).finally(function () {
                        vm.loading--;
                    })
            }

            init();
        }
    ]);
})();
