(function () {
    var componentId = 'clinicSpecialistDashboardPendingGlsWidget';
    var app = angular.module('app');

    function controller(specialistDashboardSvc, $sce, $interval, $scope, moment) {
        function init() {
            refresh();

            var tickerInterval = $interval(tickerJob, 1000);
            $scope.$on('$destroy', function () {
                if (angular.isDefined(tickerInterval)) {
                    $interval.cancel(tickerInterval);
                    tickerInterval = undefined;
                }
            });
        }

        var tickerCount = 0;
        function tickerJob() {
            if (!((++tickerCount) % 10) && !vm.loading && !isSilentLoading) {
                refresh(true);
            }
        }

        var vm = this;
        vm.loading = 0;
        var isSilentLoading = 0;

        function refresh(silentLoad) {
            if (vm.loading || isSilentLoading) return;

            if (silentLoad) isSilentLoading++;
            else vm.loading++;

            specialistDashboardSvc.getGuaranteeLettersPendingApproval(
                { abpHandleError: false }
            ).success(function (data) {
                vm.items = data.items;
            }).finally(function () {
                if (silentLoad) isSilentLoading--;
                else vm.loading--;
            });
        }

        vm.getCheckInMessage = function (checkInTime) {
            var checkInTimeText = moment(checkInTime).format('L LT');

            return $sce.trustAsHtml(App.localize('CheckedInOnXMessage', checkInTimeText));
        };

        vm.getInstructionMessage = function (companyContactNumber) {
            var contactText = '<a ng-href="tel:' + companyContactNumber + '">' + App.htmlEncode(companyContactNumber) + '</a>';

            return $sce.trustAsHtml(App.localize('CallXForApprovalMessage', contactText));
        };

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/clinic/views/specialistDashboard/widgets/pendingGls/index.html'),
        controller: ['abp.services.app.specialistDashboard', '$sce', '$interval', '$scope', 'moment', controller],
        controllerAs: 'vm'
    });
})();
