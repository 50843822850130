(() => {
  angular
    .module('app')
    .controller(
      'host.views.inpatientNprClaims.processPrePostHospitalization',
      ProcessPrePostHospitalizationPageController
    );

  ProcessPrePostHospitalizationPageController.$inject = [
    '$stateParams',
    'abp.services.app.nprClaim',
    'moment',
  ];

  function ProcessPrePostHospitalizationPageController($stateParams, nprClaimSvc, moment) {
    const vm = this;

    vm.claimNumber = $stateParams.claimNumber;
    vm.receiptDate = moment();
    vm.isMaternity = false;

    vm.coPay = {
      isRoomAndBoardExceedEntitlement: false,
      isCoInsurance: false,
      isPercentage: false,
      coPayExclusions: [],
      value: 0,
    };

    init();

    function init() {
      getClaim();
    }

    function getClaim() {
      vm.loading += 1;
      nprClaimSvc
        .getClaimDetail({
          id: $stateParams.claimNumber,
        })
        .success((data) => {
          vm.claim = data;
          vm.employeeId = vm.claim.employeeId;
          vm.claimType = vm.claim.claimType;
          if (
            vm.claimType === 12 ||
            vm.claimType === 13 ||
            vm.claimType === 14 ||
            vm.claimType === 15
          ) {
            vm.isMaternity = true;
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
