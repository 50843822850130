(() => {
  angular.module('revenue').controller('revenue.views.agents.index', RevenueAgentsIndexController);

  RevenueAgentsIndexController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$uibModal',
    '$timeout',
    'abp.services.revenue.agent',
    'Hms.Revenue.AgentType',
    'revenueConstants',
  ];
  function RevenueAgentsIndexController(
    $scope,
    $state,
    $stateParams,
    $uibModal,
    $timeout,
    agentSvc,
    enumAgentType,
    revenueConsts
  ) {
    const vm = this;
    vm.loading = 0;
    vm.requestParams = {
      filter: $stateParams.filter,
      skipCount: $stateParams.skipCount,
      maxResultCount: $stateParams.maxResultCount,
      sorting: $stateParams.sorting,
      type: $stateParams.type,
      view: $stateParams.view,
    };

    vm.enums = {};
    vm.enums.agentType = enumAgentType;

    // Define grid options.

    vm.agentGridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: revenueConsts.grid.defaultPageSizes,
      paginationPageSize: revenueConsts.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      resizable: true,
      columnDefs: [
        {
          enableSorting: false,
          name: App.localize('Actions'),
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
        },
        {
          displayName: App.localize('AgentCode'),
          name: App.localize('AgentCode'),
          field: 'code',
          minWidth: 80,
        },
        {
          displayName: App.localize('DisplayName'),
          name: App.localize('DisplayName'),
          field: 'displayName',
          minWidth: 80,
        },
        {
          displayName: App.localize('Name'),
          name: App.localize('Name'),
          field: 'name',
          minWidth: 80,
        },
        {
          displayName: App.localize('Type'),
          name: App.localize('Type'),
          field: 'type',
          minWidth: 80,
          cellTemplate: 'typeTemplate',
        },
        {
          enableSorting: false,
          displayName: App.localize('Status'),
          name: App.localize('Status'),
          field: 'status',
          minWidth: 80,
          cellTemplate: 'statusTemplate',
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          let sorting = '';
          for (let i = 0; i < callback.length; i += 1) {
            if (i) sorting += ', ';
            sorting += `${callback[i].field} ${callback[i].sort.direction}`;
          }
          vm.requestParams.sorting = sorting;
          vm.getAgents();
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, vm.getAgents)
        );
      },
      data: [],
    };

    vm.getAgents = getAgents;
    vm.createAgent = createAgent;
    vm.editAgent = editAgent;
    vm.suspendAgent = suspendAgent;
    vm.reinstateAgent = reinstateAgent;
    vm.disableCommission = disableCommission;
    vm.enableCommission = enableCommission;
    vm.deleteAgent = deleteAgent;

    init();

    // Define functions.

    function init() {
      $timeout(() => {
        vm.agentGridOptions.paginationCurrentPage = Math.floor(
          vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1
        );
      });
    }

    function showEditModal(id, type) {
      const modal = $uibModal.open({
        templateUrl: require('./createOrEditModal.html'),
        controller: 'revenue.views.agents.createOrEditModal as vm',
        backdrop: 'static',
        resolve: {
          agentId() {
            return id;
          },
          agentType() {
            return type;
          },
        },
      });
      modal.result.then(() => {
        vm.getAgents();
      });
    }

    function registerStateParams() {
      const sp = $.extend({}, vm.requestParams);
      if (Number.isNaN(sp.type)) sp.type = null;
      $state.transitionTo($state.current, sp, {
        notify: false,
      });
    }

    function getAgents() {
      if (vm.loading) return;
      registerStateParams();
      vm.loading += 1;
      agentSvc
        .getAgents(vm.requestParams)
        .success((data) => {
          vm.agentGridOptions.totalItems = data.totalCount;
          vm.agentGridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function createAgent(type) {
      showEditModal(null, type);
    }

    function editAgent(agent) {
      showEditModal(agent.id, null);
    }

    function suspendAgent(agent) {
      if (vm.loading) return;
      vm.loading += 1;
      agentSvc
        .suspendAgent({ id: agent.id })
        .success(() => {
          abp.notify.success(App.localize('SuccessfullyUpdated'));
          const agentData = _.find(vm.agentGridOptions.data, { id: agent.id });
          if (agentData) {
            agentData.isActive = false;
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function reinstateAgent(agent) {
      if (vm.loading) return;
      vm.loading += 1;
      agentSvc
        .reinstateAgent({ id: agent.id })
        .success(() => {
          abp.notify.success(App.localize('SuccessfullyUpdated'));
          const agentData = _.find(vm.agentGridOptions.data, { id: agent.id });
          if (agentData) {
            agentData.isActive = true;
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function disableCommission(agent) {
      abp.message.confirm(
        App.localize('DisableCommissionWarning', agent.name),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            if (vm.loading) return;
            vm.loading += 1;
            agentSvc
              .disableCommission({ id: agent.id })
              .success(() => {
                abp.notify.success(App.localize('SuccessfullyUpdated'));
                const agentData = _.find(vm.agentGridOptions.data, { id: agent.id });
                if (agentData) {
                  agentData.isCommissionEnabled = false;
                }
              })
              .finally(() => {
                vm.loading -= 1;
              });
          }
        }
      );
    }

    function enableCommission(agent) {
      abp.message.confirm(
        App.localize('EnableCommissionWarning', agent.name),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            if (vm.loading) return;
            vm.loading += 1;
            agentSvc
              .enableCommission({ id: agent.id })
              .success(() => {
                abp.notify.success(App.localize('SuccessfullyUpdated'));
                const agentData = _.find(vm.agentGridOptions.data, { id: agent.id });
                if (agentData) {
                  agentData.isCommissionEnabled = true;
                }
              })
              .finally(() => {
                vm.loading -= 1;
              });
          }
        }
      );
    }

    function deleteAgent(agent) {
      abp.message.confirm(
        App.localize('AgentDeleteWarningMessage', agent.name),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            agentSvc
              .deleteAgent({
                id: agent.id,
              })
              .success(() => {
                abp.notify.success(App.localize('SuccessfullyDeleted'));
                vm.getAgents();
              });
          }
        }
      );
    }
  }
})();
