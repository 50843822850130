(function () {
    var controllerId = 'clinic.views.patient.ticketSubmitted.index';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$sce', '$state', '$stateParams', 'abp.services.app.patientTicket', 'abp.services.app.commonLookup',
        function ($scope, $sce, $state, $stateParams, patientTicketSvc, commonLookupSvc) {
            function init() {
                getTenantCurrencyCode();
                vm.loading++;
                patientTicketSvc.getTicketDetail({
                    id: vm.ticketNumber
                }).success(function (data) {
                    vm.ticket = data.ticket;
                    vm.patient = data.patient;
                    vm.clinic = data.clinic;
                    vm.hasMc = vm.ticket.medicalCertificate ? true : false;
                    vm.hasReferral = vm.ticket.referralLetter ? true : false;
                    vm.hasPrescription = vm.ticket.prescription ? true : false;
                    vm.noPayment = !vm.ticket.manualPayment;
                    vm.subsidiaryName = vm.patient.corporate.name;

                    vm.ticketMessage = vm.isClinic
                        ? App.localize('ClaimXIssued', vm.ticketNumber)
                        : App.localize('TicketXIssued', vm.ticketNumber);
                    if (!vm.ticket.manualPayment) {
                        vm.paymentNarrative = vm.isClinic
                            ? App.localize('PanelClaimSubmittedMessage', vm.subsidiaryName)
                            : App.localize('TicketSubmitedMessage', vm.subsidiaryName);
                    }
                    else {
                        var manualPaymentLabel = '<span class="text-danger"><strong>' + vm.currencyCode + vm.ticket.manualPayment + '</strong></span>';
                        vm.paymentNarrative = $sce.trustAsHtml(App.localize('TicketSubmitedCollectPaymentMessage', manualPaymentLabel));
                    }
                }).finally(function () {
                    vm.loading--;
                });
            }

            var vm = this;
            vm.ticketNumber = $stateParams.ticketNumber;
            vm.isClinic = App.isClinic();

            vm.paymentNarrative = '';

            function getTenantCurrencyCode() {
                vm.loading++;
                commonLookupSvc.getTenantCurrencyCode()
                    .success(function (data) {
                        vm.currencyCode = data;
                    }).finally(function () {
                        vm.loading--;
                    })
            }

            // delay broadcast time to wait for step indicator widget to load
            setTimeout(function () {
                //update step indicator
                $scope.$broadcast('submission');
            }, 200);

            init();
        }
    ]);
})();
