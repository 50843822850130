(() => {
  angular.module('app').filter('props', props);

  props.$inject = [];

  function props() {
    return propsFilter;

    function propsFilter(items, properties) {
      let out = [];
      if (angular.isArray(items)) {
        items.forEach((item) => {
          let itemMatches = false;
          const keys = Object.keys(properties);
          for (let i = 0; i < keys.length; i += 1) {
            const prop = keys[i];
            const text = properties[prop].toLowerCase();
            if ((item[prop] || '').toString().toLowerCase().indexOf(text) !== -1) {
              itemMatches = true;
              break;
            }
          }
          if (itemMatches) {
            out.push(item);
          }
        });
      } else {
        out = items;
      }
      return out;
    }
  }
})();
