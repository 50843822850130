(function () {
    var controllerId = 'host.views.customEmailTemplates.createEditTemplate';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$state', '$scope', '$stateParams', 'countries', 'abp.services.app.customEmailTemplate', 'abp.services.app.commonLookup',
        function ($state, $scope, $stateParams, countries, customEmailTemplateSvc, commonLookupSvc) {
            function init() {
                getEmailTemplateModuleTypes();
                getAccessibleCountries();
            }
            var vm = this;
            vm.loading = 0;
            vm.saving = 0;
            vm.templateId = $stateParams.templateId;
            vm.isCreate = !vm.templateId.trim();
            vm.panelRequestKeywords = [];
            vm.status = 1; // default status as 'Draft'
            vm.countries = [];
            vm.selectedCountry = 'MY';

            vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');

            function getEmailTemplateModuleTypes() {
                vm.loading++;
                customEmailTemplateSvc.getEmailTemplateModuleTypes({
                }).success(function (data) {
                    vm.moduleTypes = data.items;
                }).finally(function (e) {
                    vm.loading--;
                });
            }

            if (!vm.isCreate) {
                vm.loading++;
                customEmailTemplateSvc.getEmailTemplateForEdit({
                    id: vm.templateId
                }).success(function (data) {
                    vm.moduleTypeString = data.moduleTypeString;
                    vm.moduleType = data.moduleType;
                    vm.name = data.name;
                    vm.templateName = data.templateName;
                    vm.subject = data.subject;
                    vm.content = data.content;
                    $('#emailContent').summernote('code', vm.content);
                    vm.hashedVariableTags = data.hashedVariables ? _.split(data.hashedVariables, ', ') : null;
                    vm.hashedVariableAttachments = data.hashedVariableAttachments;
                    vm.attachments = data.attachments;
                    vm.status = data.status;

                    if (vm.hasRegionalSystemSupport) {
                        vm.selectedCountry = data.countryCode;
                        vm.countryName = data.countryName;
                    }

                    getEmailSuggestionHash();
                }).finally(function (e) {
                    vm.loading--;
                });
            }

            function getEmailSuggestionHash() {
                vm.loading++;
                customEmailTemplateSvc.getEmailSuggestionHash({
                    id: vm.moduleType
                }).success(function (data) {
                    vm.panelRequestKeywords = data;
                }).finally(function (e) {
                    vm.loading--;
                });
            }

            vm.getEmailTemplateNames = function () {
                vm.loading++;
                customEmailTemplateSvc.getEmailTemplateNames({
                    id: vm.moduleType
                }).success(function (data) {
                    vm.names = data.items;
                    getEmailSuggestionHash();
                }).finally(function (e) {
                    vm.loading--;
                });
            }

            vm.newAttachments = [];
            vm.newHashedVariableAttachments = [];
            vm.uploadConfig = {
                objectType: 'EmailAttachment'
            };

            vm.hashUploadConfig = {
                objectType: 'EmailAttachmentHashedVariableDocument'
            };

            vm.isInvalidDocument = function () {
                return !vm.newAttachments.length;
            };

            vm.cancel = function () {
                abp.message.confirm(App.localize('CancelEmailTemplateWarning'), App.localize('AreYouSure'), function (d) {
                    if (d) {
                        $state.go('host.customEmailTemplates');
                    }
                });
            };

            // Check if form is invalid or Attachment is uploaded but has no Text replacement.

            vm.validateForm = function () {
                var isFormInvalid = $scope.emailTemplateForm.$invalid;
                if (isFormInvalid)
                    return isFormInvalid;

                var isTextReplacementInvalid = true;
                if (vm.newHashedVariableAttachments.length > 0) {
                    var tags = vm.hashedVariableTags ? vm.hashedVariableTags.length : 0;
                    isTextReplacementInvalid = tags === 0;
                }
                else
                    isTextReplacementInvalid = false;

                return isTextReplacementInvalid;
            }

            vm.save = function () {
                vm.hashedVariables = vm.hashedVariableTags ? _.join(_.map(vm.hashedVariableTags, function (d) { return _.trim(d.text); }), ', ') : '';

                // Filter away attachments marked for deletion.

                var attachments = [];
                _.each(vm.attachments, function (attachment) {
                    if (!attachment.isDelete)
                        attachments.push(attachment.id);
                });
                _.each(vm.hashedVariableAttachments, function (attachment) {
                    if (!attachment.isDelete)
                        attachments.push(attachment.id);
                });

                vm.saving++;

                if (vm.isCreate) {
                    customEmailTemplateSvc.createEmailTemplate({
                        moduleType: vm.moduleType,
                        name: vm.name,
                        subject: vm.subject,
                        content: vm.content,
                        status: vm.status,
                        hashedVariables: vm.hashedVariables,
                        newAttachments: vm.newAttachments,
                        newHashedVariableAttachments: vm.newHashedVariableAttachments,
                        countryCode: vm.selectedCountry
                    }).success(function () {
                        abp.notify.info(App.localize("SuccessfullySaved"));
                        $state.go('host.customEmailTemplates');
                    }).finally(function (e) {
                        vm.saving--;
                    });
                } else {
                    customEmailTemplateSvc.editEmailTemplate({
                        id: vm.templateId,
                        moduleType: vm.moduleType,
                        name: vm.templateName,
                        subject: vm.subject,
                        content: vm.content,
                        status: vm.status,
                        hashedVariables: vm.hashedVariables,
                        newAttachments: vm.newAttachments,
                        newHashedVariableAttachments: vm.newHashedVariableAttachments,
                        attachments: attachments,
                        countryCode: vm.selectedCountry
                    }).success(function () {
                        abp.notify.info(App.localize("SuccessfullySaved"));
                        $state.go('host.customEmailTemplates');
                    }).finally(function (e) {
                        vm.saving--;
                    });
                }
            };

            function getAccessibleCountries() {
                vm.loading++;
                commonLookupSvc.getEligibleCountries().success(function (data) {
                    var elligibleCountryCodes = data.map(o => o.code)
                    vm.countries = _.filter(countries, function (c) {
                        return elligibleCountryCodes.includes(c.code)
                    });
                }).finally(function (e) {
                    vm.loading--;
                });
            }

            init();
        }
    ]);
})();
