(function () {
    var componentId = 'hostDirectorDashboardCorporateStatsWidget';
    var app = angular.module('app');

    function controller($element, directorDashboardSvc) {

        var vm = this;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.loading = false;
        vm.data = null;
        vm.showTable = false;

        function updateChart() {

            var columns = [];
            var miscAmt = 0;
            _.each(vm.data.corporateValues, function (cv) {
                if (columns.length < 5 ) {
                    columns.push([cv.name, cv.ticketValue60Days]);
                } else {
                    miscAmt += cv.ticketValue60Days;
                }
            });
            if (miscAmt) columns.push(['Others', miscAmt]);

            c3.generate({
                bindto: $element.find('.chart-container')[0],
                size: { height: 250 },
                data: {
                    columns: columns,
                    type: 'pie'
                },
                legend: {
                    show: false
                }
            });
        }

        function loadData() {
            vm.loading = true;
            directorDashboardSvc.getCorporateFigures()
                .success(function (data) {
                    vm.data = data;
                    updateChart();
                }).finally(function () {
                    vm.loading = false;
                });
        }

        loadData();
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/directorDashboard/corporateStatsWidget.html'),
        controller: ['$element', 'abp.services.app.directorDashboard', controller],
        controllerAs: 'vm'
    });
})();
