import swal from 'sweetalert';

(() => {
  angular.module('app').component('panelPatientCardWidget', {
    templateUrl: require('./widget.html'),
    controller: ControllerController,
    controllerAs: 'vm',
    bindings: {
      employee: '<',
      panelLocationId: '<',
      panelLocationTimeZone: '<',
      countryCode: '<',
      getEmployees: '&',
      checkedIn: '&',
      getAllowanceDisplay: '&',
    },
    require: {
      container: '^clinicPatientCheckInWidget',
    },
  });

  ControllerController.$inject = [
    '$scope',
    '$state',
    '$uibModal',
    '$interval',
    '$filter',
    'abp.services.app.clinicEmployee',
    'abp.services.app.patientTicket',
    'abp.services.app.commonLookup',
    'moment',
  ];

  function ControllerController(
    $scope,
    $state,
    $uibModal,
    $interval,
    $filter,
    clinicEmployeeSvc,
    patientTicketSvc,
    commonLookupSvc,
    moment
  ) {
    const vm = this;
    vm.loading = 0;
    vm.tickerProgressBarValue = 100;
    vm.clinicNumber = abp.setting.get('Hms.General.ClinicSupportContactNumber');
    vm.patientCheckInRequiredToViewBalance = abp.features.isEnabled(
      'PatientCheckInRequiredToViewBalance'
    );
    vm.hasPanelDashboard = abp.features.isEnabled('ClinicModule.PanelDashboard');
    vm.permissions = {
      canCreateTicket: abp.auth.isGranted('PatientTickets.Clinic.Create'),
      canViewTickets: abp.auth.isGranted('PatientTickets'),
    };

    vm.$onInit = init;
    vm.getUserBgColor = getUserBgColor;
    vm.getUserInitial = getUserInitial;
    vm.toggleCheckIn = toggleCheckIn;
    vm.createTicket = createTicket;
    vm.cancel = cancel;
    vm.getDateTimeString = getDateTimeString;

    function init() {
      getTenantCurrencyCodeAndSetEmployeeCoPay();

      let tickerInterval = $interval(tickerJob, 1000);
      $scope.$on('$destroy', () => {
        if (angular.isDefined(tickerInterval)) {
          $interval.cancel(tickerInterval);
          tickerInterval = undefined;
        }
      });
    }

    function getDateTimeString(date) {
      return App.getDateTimeString(date, vm.panelLocationTimeZone);
    }

    function tickerJob() {
      if (vm.employee.checkedInTime) {
        const totalSeconds = moment(vm.employee.checkOutTime).diff(moment(), 's');
        const duration = moment.duration(totalSeconds, 's');
        if (totalSeconds <= 0) {
          vm.employee.checkedInTime = null;
          vm.employee.timeRemaining = null;
        } else {
          const seconds = duration.seconds();
          const minutes = duration.minutes();
          const hours = duration.hours();
          const days = duration.days();
          let str = '';
          if (days > 0) str += `${days}d `;
          if (hours > 0) str += `${_.padStart(`${hours}`, 2, '0')}h `;
          str += `${_.padStart(`${minutes}`, 2, '0')}m ${_.padStart(`${seconds}`, 2, '0')}s`;
          vm.employee.timeRemaining = str;
        }

        // HACK: GP has 1 day to submit ticket,
        // For Specialist, has 2 days to submit ticket,
        // For pre-employee, has 7 days to submit report.

        let limit = 24 * 60 * 60;
        if (vm.employee.isPreEmployee) {
          limit *= 7;
        } else if (vm.employee.hasSpecialistBenefit && App.isSpecialist()) {
          limit *= 2;
        }

        vm.tickerProgressBarValue = (totalSeconds / limit) * 100;
        $(`div#prog_${vm.employee.id}`).width(`${vm.tickerProgressBarValue}%`);
      }
    }

    /* Employee icon */

    function getUserBgColor() {
      return `user_bgcolor_${((vm.employee.id || 0) % 8) + 1}`;
    }

    function getUserInitial() {
      const fullName = (vm.employee.name || ' ').split(' ');
      let initial = '';
      for (let i = 0; i < fullName.length; i += 1) {
        if (fullName[i].length) {
          initial += fullName[i][0];
        }
      }
      if (initial && initial.length > 3) initial = `${initial[0]}`;
      return initial;
    }

    /* End of employee icon */

    // Toggle employee check-in.

    function toggleCheckIn() {
      if (vm.loading) return;

      if (vm.employee.checkedInTime) {
        checkOut();
        return;
      }

      if (vm.employee.previousCheckins.totalCount == 0) {
        initiateCheckIn();
        return;
      }

      // Check if there's any checkins within 48 hours.

      const latestCheckIn = _.maxBy(vm.employee.previousCheckins.items, 'checkinTime');

      let latestCheckInTime = latestCheckIn.checkinTime;

      latestCheckInTime = latestCheckInTime.replace('T', ' ');
      latestCheckInTime = new Date(latestCheckInTime);
      const timeNow = moment();

      const ms = moment(timeNow, 'DD/MM/YYYY HH:mm:ss').diff(
        moment(latestCheckInTime, 'DD/MM/YYYY HH:mm:ss')
      );
      const d = moment.duration(ms);
      const s = d.asHours();

      if (s > 47) {
        initiateCheckIn();
        return;
      }

      // Throw duplicate check-in warning for check-ins less than 48 hours.
      swal(
        {
          title: App.localize('DuplicateCheckinWarning'),
          text: App.localize(
            'DuplicateCheckinWarningText',
            App.getDateTimeString(latestCheckInTime),
            latestCheckIn.ticketNumber,
            App.getDateTimeString(latestCheckIn.ticketDate)
          ),
          type: 'warning',
          customClass: 'custom-duplicate-alert',
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: App.localize('No'),
          confirmButtonText: App.localize('Yes'),
          confirmButtonColor: '#C1C1C1',
          html: true,
        },
        initiateCheckIn
      );
    }

    function initiateCheckIn() {
      if (vm.hasPanelDashboard && vm.employee.hasExpiredCheckIns) {
        $uibModal
          .open({
            templateUrl: require('./checkInConfirm.modal.html'),
            controller: 'clinic.views.patient.checkIn.patientCard.checkInConfirmModal as vm',
            scope: $scope,
            backdrop: 'static',
            size: 'md',
          })
          .result.then((result) => {
            if (result.isCheckIn) checkIn();
          });
      } else checkIn();
    }

    function checkIn() {
      vm.loading += 1;
      const letterNumber = '';
      clinicEmployeeSvc
        .checkIn({
          employeeId: vm.employee.id,
          clinicLocationId: vm.panelLocationId,
          guaranteeLetterNumber: letterNumber,
        })
        .success((data) => {
          getCheckInDetail(data);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getCheckInDetail(id) {
      vm.loading += 1;
      clinicEmployeeSvc
        .getCheckInDetail({
          id,
        })
        .success((data) => {
          vm.employee.checkedInTime = data.checkedInTime;
          vm.employee.checkOutTime = data.checkOutTime;

          if (data.allowance > 0) {
            vm.employee.allowance = data.allowance;
            vm.employee.allowanceDisplay = vm.container.getAllowanceDisplay(
              vm.employee.allowance,
              vm.employee.allowancePeriod
            );
          }

          promptCheckInSuccessMessage();
          abp.notify.info(App.localize('PatientXCheckedIn', vm.employee.name));

          vm.container.checkIn();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function checkOut() {
      vm.loading += 1;
      clinicEmployeeSvc
        .checkOut({
          employeeId: vm.employee.id,
          clinicLocationId: vm.panelLocationId,
          cancellationRemarks: vm.employee.cancellationRemarks,
        })
        .success(() => {
          vm.container.getEmployees();
          abp.notify.info(App.localize('PatientXCheckedOut', vm.employee.name));
          swal.close();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // Check-in success swal

    function createTicket() {
      checkDuplicate(() => {
        createTicketCore();
      });
    }

    function createTicketCore() {
      if (!vm.employee.isPreEmployee && App.isGPPanel()) {
        $state.go('clinic.createEditPatientTicket', {
          employeeId: vm.employee.id,
          checkedInTime: vm.employee.checkedInTime,
          clinicLocationId: vm.panelLocationId,
          ticketType: vm.employee.isPreEmployee ? 3 : 0,
        });
      } else {
        $state.go('createEditPatientTicket', {
          employeeId: vm.employee.id,
          checkedInTime: vm.employee.checkedInTime,
          clinicLocationId: vm.panelLocationId,
          ticketType: vm.employee.isPreEmployee ? 3 : 0,
        });
      }
    }

    function checkDuplicate(callback) {
      vm.loading += 1;
      patientTicketSvc
        .hasDuplicateTicket({
          employeeId: vm.employee.d,
          clinicLocationId: vm.panelLocationId,
          ticketDate: vm.employee.checkInTime,
        })
        .success((data) => {
          if (data.hasDuplicateTicket) {
            abp.message.confirm(
              App.localize('PossibleDuplicateTicketWarning'),
              App.localize('AreYouSure'),
              (d) => {
                if (d) callback();
              }
            );
          } else {
            callback();
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function cancel() {
      $uibModal
        .open({
          templateUrl: require('../../../employees/checkOutModal.html'),
          controller: 'clinic.views.employees.checkOutModal as vm',
          backdrop: 'static',
        })
        .result.then((reason) => {
          if (reason) {
            vm.employee.cancellationRemarks = reason;
            vm.toggleCheckIn();
          }
        });
    }

    function promptCheckInSuccessMessage() {
      function getMessage() {
        const titles = [App.localize('CheckedIn')];
        const msgs = [];
        if (!vm.employee.allowance) {
          if (!vm.employee.canAccess) {
            msgs.push(App.localize('PatientUnableToMakeCashlessClaim'));
            msgs.push(App.localize('CheckInSuccessCollectCashNarrative'));
            return {
              titles,
              msgs,
            };
          }
          msgs.push(App.localize('PatientMustPayCash'));
        } else {
          if (vm.employee.allowance <= 30) {
            msgs.push(App.localize('PatientLowLimitAndPayExcess'));
          }

          if (vm.employee.coPay) {
            titles.push(vm.employee.coPay.title);
            msgs.push(vm.employee.coPay.text);
          }
        }

        if (vm.employee.isPreEmployee && vm.countryCode === 'SG')
          msgs.push(App.localize('SgPreEmployeeCheckInSuccessNarrative'));
        else if (vm.employee.isPreEmployee && vm.countryCode !== 'SG')
          msgs.push(App.localize('PreEmployeeCheckInSuccessNarrative'));
        else {
          msgs.push(App.localize('CheckInSuccessNarrative'));
          msgs.push(App.localize('CheckInSuccessNarrative2'));
        }
        return {
          titles,
          msgs,
        };
      }

      const message = getMessage();
      const title = _.join(message.titles || [], '<br />');
      const text = `${App.localize(
        'CheckInSuccessMessage'
      )}<br /><br /><b class="font-uppercase">${_.toUpper(
        App.localize('Important')
      )}:</b><br />${_.join(message.msgs || [], ' ')}`;

      window.onbeforeunload = () => title;

      swal(
        {
          title,
          type: 'success',
          text,
          showConfirmButton: true,
          confirmButtonText: App.localize('Understood'),
          html: true,
        },
        () => {
          window.onbeforeunload = null; // unregister
        }
      );
      App.swal.disableButtons(5);
    }

    /* End of Functions */

    function getTenantCurrencyCodeAndSetEmployeeCoPay() {
      vm.loading += 1;
      commonLookupSvc
        .getTenantCurrencyCode()
        .success((data) => {
          vm.currencyCode = data;

          if (vm.employee.coPay) {
            vm.coPayValueDisplay = vm.employee.coPay.isPercentage
              ? `${vm.employee.coPay.value}%`
              : $filter('currencyFormat')(vm.employee.coPay.value, vm.currencyCode);
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
