(function () {
    var componentId = 'revenueSubscriptionsDetailBillingCycles';
    var module = angular.module('revenue');

    function controller(subscriptionSvc) {
        function downloadHeadcountReport(cycle) {
            if (vm.downloading) return;

            vm.downloading++;
            subscriptionSvc.downloadHeadcountReport({
                id: vm.subscription.id,
                cycle: cycle
            }).success(function (data) {
                window.open(data.fileUrl, '_blank');
            }).finally(function () {
                vm.downloading--;
            });
        }

        function downloadBill(cycle, billingNumber) {
            if (vm.downloading) return;

            vm.downloading++;
            subscriptionSvc.downloadBill({
                id: vm.subscription.id,
                cycle: cycle,
                billingNumber: billingNumber
            }).success(function (data) {
                window.open(data.fileUrl, '_blank');
            }).finally(function () {
                vm.downloading--;
            });
        }

        function loadBillingCycles() {
            if (vm.subscription) {
                vm.loading++;
                subscriptionSvc.getBillingCycles({
                    id: vm.subscription.id
                }).success(function (data) {
                    vm.cycles = data.items;

                    _.forEach(vm.cycles, function (cycle) {
                        cycle.links = [];

                        if (vm.subscription.chargingBasis === 1 || vm.subscription.chargingBasis === 2) {
                            cycle.links.push({
                                type: 'headcount',
                                cycle: cycle.cycle,
                                text: App.localize('HeadcountReport')
                            });
                        }
                        _.forEach(cycle.billingNumbers, function (billingNumber) {
                            cycle.links.push({
                                type: 'bill',
                                cycle: cycle.cycle,
                                billingNumber: billingNumber,
                                text: billingNumber
                            });
                        });
                    });
                }).finally(function () {
                    vm.loading--;
                });
            } else {
                vm.cycles = [];
            }
        }

        const vm = this;
        vm.loading = 0;
        vm.downloading = 0;
        vm.cycles = [];
        vm.permissions = {
            manageSubscriptions: abp.auth.isGranted('Hms.Revenue.Corporate.ManageSubscriptions')
        };

        vm.$onChanges = function (changesObj) {
            if (changesObj.subscription) {
                loadBillingCycles();
            }
        };

        vm.processLinkClick = function (link) {
            if (link.type === 'headcount') {
                downloadHeadcountReport(link.cycle);
            } else if (link.type === 'bill') {
                downloadBill(link.cycle, link.billingNumber);
            }
        };
    }

    module.component(componentId, {
        require: { container: '^revenueSubscriptionsDetailContainer' },
        bindings: { subscription: '<' },
        templateUrl: require('/App/modules/revenue/views/subscriptions/detail/billingCycles/widget.html'),
        controller: ['abp.services.revenue.subscription', controller],
        controllerAs: 'vm'
    });
})();
