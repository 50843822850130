(function () {
    var controllerId = 'corporate.views.benefitGroups.inpatient.benefitDetail';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$stateParams', '$filter', '$window', '$state', 'appSession', 'abp.services.app.benefitGroup',
        function ($scope, $stateParams, $filter, $window, $state, appSession, benefitGroupSvc) {
            var vm = this;
            vm.loading = 0;
            vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
            vm.benefitId = $stateParams.inpatientBenefitGroupId;
            vm.serviceType = $stateParams.serviceType;
            vm.printAllTabs = printAllTabs;
            vm.activeTab = 0;
            vm.isInsurance = false;

            vm.back = back;

            function init() {
                getSupportPlan();
            }

            var ccyFilter = $filter('currencyFormat');
            var organizationId = parseInt(appSession.tenant.id, 10);
            var hospitalizationDetails = [new TreatmentModel(7, App.localize('Hospitalization'), 'fas fa-bed', true)];
            var maternityDetails = [new TreatmentModel(8, App.localize('Maternity'), 'fas fa-bed', true)];

            function printAllTabs() {
                var benefitTabHeaders = $('#benefitDetails .benefitDetails').map(function (i, e) { return $(e).text(); }).get();
                var benefitTabContents = $('#benefitDetails .ibox-content').map(function (i, e) { return $(e).html(); }).get();

                // Replace SOB print values to include all tables

                var sobIndex = vm.isInsurance ? 2 : 1;

                benefitTabContents[benefitTabContents.length - sobIndex] = printSob();

                var printHtml = '';
                for (var i = 0; i < benefitTabHeaders.length; i++) {
                    printHtml += '<h1>' + benefitTabHeaders[i] + '</h1>';
                    printHtml += '<div>' + benefitTabContents[i] + '</div>';
                }

                $('#benefitDetailsPrint').html(printHtml);

                $window.print();

                $('#benefitDetailsPrint').empty();
            }

            function printSob() {
                // Retrieve tab headers
                var sobHeaders = $('#benefitDetails #principalDependent').map(function (i, e) { return $(e).text(); }).get();
                var principalHeaders = $('#benefitDetails #principal').map(function (i, e) { return $(e).text(); }).get();
                var dependentHeaders = $('#benefitDetails #dependent').map(function (i, e) { return $(e).text(); }).get();
                var principalDependent = [principalHeaders, dependentHeaders];

                // Retrieve table values
                var sobTabContents = $('#benefitDetails #sobDetail').map(function (i, e) { return $(e).html(); }).get();

                var printValue = '';
                var index = 0;

                for (var i = 0; i < sobHeaders.length; i++) {
                    printValue += '<h2>' + sobHeaders[i] + '</h2>';

                    for (var j = 0; j < principalDependent[i].length; j++) {
                        printValue += '<h3>' + principalDependent[i][j] + '<h3>'

                        if (index < sobTabContents.length)
                            printValue += '<div>' + sobTabContents[index++] + '</div>';
                    }
                }

                return printValue;

            }

            function getSupportPlan() {
                vm.supportPlan = null;
                vm.loading++;
                benefitGroupSvc.getInpatientBenefitGroupForEdit({
                    id: vm.benefitId,
                    organizationId: organizationId,
                    serviceType: vm.serviceType
                }).success(function (data) {
                    vm.optionalExclusions = data.optionalExclusions;
                    vm.isHospitalization = data.benefitGroup.serviceType === 7;
                    vm.benefitGroupName = data.benefitGroup.name;
                    vm.isInsurance = data.benefitGroup.masterPolicyId ? true : false;
                    var benefitGroupType = '';
                    switch (data.benefitGroup.serviceType) {
                        case 7:
                            benefitGroupType = App.localize('Hospitalization');
                            break;
                        case 8:
                            benefitGroupType = App.localize('Maternity');
                            break;
                    }
                    vm.benefitGroupType = benefitGroupType;

                    vm.supportPlan = new BenefitGroupModel(data.benefitGroup);
                    vm.supportPlan.organizationId = organizationId;
                    vm.supportPlan.isInsurance = vm.isInsurance;
                    vm.supportPlan.masterPolicy = data.masterPolicy;
                    vm.supportPlan.insurer = data.newInsurer;
                }).finally(function () {
                    vm.loading--;
                });
            }

            function back() {
                var state = vm.isInsurance ? 'corporate.insurancePlans' : 'corporate.inpatientBenefitGroup';
                $state.go(state);
            }

            function automap(source, target) {
                _.forIn(source, function (v, k) { target[k] = source[k]; });
            }

            function initializeScheduleOfBenefit(_thisSettings, dtoSettings) {
                // Special room and board

                _thisSettings.icuRoomAndBoard.amountType = dtoSettings.icuRoomAndBoard.isAsCharged ? 0 : 1;

                // Lodging/bed

                _thisSettings.childGuardianBenefit.amountType = dtoSettings.childGuardianBenefit.isAsCharged ? 0 : 1;
                if (!_thisSettings.childGuardianBenefit.isCovered) {
                    _thisSettings.childGuardianBenefit.amountType = 2;
                }

                // Admission charges

                _thisSettings.hospitalCharges.isFixedLimit = dtoSettings.hospitalCharges.isFixedLimit ? 1 : 0;
                _thisSettings.hospitalCharges.surgicalFee.amountType = dtoSettings.hospitalCharges.surgicalFee.isAsCharged ? 0 : 1;
                _thisSettings.hospitalCharges.anaesthetistFee.amountType = dtoSettings.hospitalCharges.anaesthetistFee.isAsCharged ? 0 : 1;
                _thisSettings.hospitalCharges.operatingTheatre.amountType = dtoSettings.hospitalCharges.operatingTheatre.isAsCharged ? 0 : 1;
                _thisSettings.hospitalCharges.hospitalSuppliesAndServices.amountType = dtoSettings.hospitalCharges.hospitalSuppliesAndServices.isAsCharged ? 0 : 1;

                // Ambulance fee

                _thisSettings.ambulanceFee.amountType = dtoSettings.ambulanceFee.isAsCharged ? 0 : 1;
                if (!_thisSettings.ambulanceFee.isCovered) {
                    _thisSettings.ambulanceFee.amountType = 2;
                }

                // Organ transplant

                _thisSettings.organTransplant.amountType = dtoSettings.organTransplant.isAsCharged ? 0 : 1;
                if (!_thisSettings.organTransplant.isCovered) {
                    _thisSettings.organTransplant.amountType = 2;
                }
                _thisSettings.organTransplant.maxDaysTimesType = dtoSettings.organTransplant.isUnlimited ? 1 : 0;

                // Pre-hospitalization treatment

                _thisSettings.preHospitalizationTreatment.isCovered = dtoSettings.preHospitalizationTreatment.isCovered ? 1 : 0;

                // Pre-hospitalization specialist consultation

                _thisSettings.specialistConsultation.maxDaysTimesType = dtoSettings.specialistConsultation.isUnlimited ? 1 : 0;

                // Surgical opinion

                _thisSettings.surgicalOpinion.amountType = dtoSettings.surgicalOpinion.isAsCharged ? 0 : 1;
                if (!_thisSettings.surgicalOpinion.isCovered) {
                    _thisSettings.surgicalOpinion.amountType = 2;
                }
                _thisSettings.surgicalOpinion.maxDaysTimesType = dtoSettings.surgicalOpinion.isUnlimited ? 1 : 0;

                // post-hospitalization treatment

                _thisSettings.postHospitalizationTreatment.isCovered = dtoSettings.postHospitalizationTreatment.isCovered ? 1 : 0;

                // Follow-up treatment

                _thisSettings.followUpTreatment.amountType = dtoSettings.followUpTreatment.isAsCharged ? 0 : 1;

                // Self-pay

                _thisSettings.medicalReport.amountType = dtoSettings.medicalReport.isAsCharged ? 0 : 1;

                // Outpatient cancer treatment

                _thisSettings.outpatientCancerTreatment.amountType = dtoSettings.outpatientCancerTreatment.isCovered ? 0 : 1;

                // Outpatient kidney dialysis treatment

                _thisSettings.outpatientKidneyDialysisTreatment.amountType = dtoSettings.outpatientKidneyDialysisTreatment.isCovered ? 0 : 1;
            }

            function BenefitGroupModel(dto) {
                var _this = this;

                // Basic properties

                _this.id = 0;
                _this.organizationId = vm.organizationId;
                _this.name = '';
                _this.displayName = '';

                // Benefits

                _this.principal = vm.isHospitalization ? hospitalizationDetails : maternityDetails;
                _this.dependent = vm.isHospitalization ? hospitalizationDetails : maternityDetails;

                // Benefit Modifiers

                _this.modifiers = [];

                // Benefit summaries

                _this.principalSummaries = [];
                _this.showPrincipalFootnote = false;
                _this.dependentSummaries = [];
                _this.showDependentFootnote = false;

                // Functions

                _this.addModifier = function () {
                    var modifier = new Modifier(_this);
                    if (vm.isHospitalization) {
                        vm.supportPlan.hospitalizationSetting.modifiers.push(modifier);
                    } else {
                        vm.supportPlan.maternitySetting.modifiers.push(modifier);
                    }
                };

                _this.removeModifier = function (index) {
                    abp.message.confirm(App.localize('DeleteBenefitModifierWarningMessage'), App.localize('AreYouSure'), function (d) {
                        if (d) {
                            $scope.$apply(function () {
                                if (vm.isHospitalization) {
                                    vm.supportPlan.hospitalizationSetting.modifiers.splice(index, 1);
                                } else {
                                    vm.supportPlan.maternitySetting.modifiers.splice(index, 1);
                                }
                                _this.refreshResult();
                            });
                            return true;
                        } else {
                            return false;
                        }
                    });
                };

                _this.addCoPay = function () {
                    var coPay = new CoPay(_this);
                    if (vm.isHospitalization) {
                        _this.hospitalizationSetting.coPays.push(coPay);
                    } else {
                        _this.maternitySetting.coPays.push(coPay);
                    }
                };

                _this.removeCoPay = function (index) {
                    abp.message.confirm(App.localize('DeleteBenefitModifierWarningMessage'), App.localize('AreYouSure'), function (d) {
                        if (d) {
                            $scope.$apply(function () {
                                if (vm.isHospitalization) {
                                    _this.hospitalizationSetting.coPays.splice(index, 1);
                                    vm.hospitalizationCopayForm.$setDirty();
                                } else {
                                    _this.maternitySetting.coPays.splice(index, 1);
                                    vm.maternityCopayForm.$setDirty();
                                }
                                _this.refreshResult();
                            });
                            return true;
                        }
                        else
                            return false;
                    });
                };

                _this.refreshResult = function () {
                    var principalSummaries = [],
                        dependentSummaries = [],
                        showPrincipalFootnote = false,
                        showDependentFootnote = false;

                    var coverDependent = (_this.hospitalizationSetting && !!_this.hospitalizationSetting.hasDependentCovered) || (_this.maternitySetting && !!_this.maternitySetting.isSpouseCovered);
                    var principal = _.map(_.filter(_this.principal, 'valid'), function (x) { return new BenefitResult(x, true, true); });
                    var dependent = coverDependent ? _.map(_.filter(_this.dependent, 'valid'), function (x) { return new BenefitResult(x, true, true); }) : [];
                    if (!dependent.length) {
                        coverDependent = false;
                    }
                    var coverPrincipal = principal.length > 0;

                    // Group result into stacks

                    var modifiers = [];
                    if (typeof vm.isHospitalization !== 'undefined'
                        && vm.isHospitalization !== null) {
                        modifiers = vm.isHospitalization ? _this.hospitalizationSetting.modifiers : _this.maternitySetting.modifiers;
                    }

                    _.each(modifiers, function (mod) {
                        if (mod.cycle === 4 && mod.type !== 0) {
                            mod.cycle = 3;
                        }
                    });

                    _.each(_.groupBy(modifiers, function (x) {
                        var interval = x.cycle === 0 ? x.interval : 1;
                        return x.type + ':' + interval + ':' + x.cycle;
                    }), function (values) {
                        _.each(values, function (modifier) {
                            var principalPortion = [];
                            var dependentPortion = [];

                            var beneficiary = coverDependent ? modifier.beneficiary : 1;
                            var poolMode = coverDependent ? modifier.poolMode : 2;

                            _.each(modifier.treatments, function (t) {
                                var benefit;
                                if (coverPrincipal && (beneficiary & 1) > 0) {
                                    benefit = _.find(principal, function (x) { return x.treatment.id === t.treatment.id; });
                                    if (benefit) {
                                        if (modifier.type === 0) {
                                            benefit.unlimitedBudget = false;
                                        } else if (modifier.type === 1) {
                                            benefit.unlimitedVisit = false;
                                        }
                                        principalPortion.push(t.treatment);
                                    }
                                }
                                if (coverDependent && (beneficiary & 2) > 0) {
                                    benefit = _.find(dependent, function (x) { return x.treatment.id === t.treatment.id; });
                                    if (benefit) {
                                        if (modifier.type === 0) {
                                            benefit.unlimitedBudget = false;
                                        } else if (modifier.type === 1) {
                                            benefit.unlimitedVisit = false;
                                        }
                                        dependentPortion.push(t.treatment);
                                    }
                                }
                            });

                            if (principalPortion.length || dependentPortion.length) {
                                var uniquePortion = _.uniqBy(_.concat(principalPortion, dependentPortion), 'id'),
                                    modifierTreatmentNames = _.join(_.map(uniquePortion, 'name'), '+'),
                                    principalTreatmentNames = _.join(_.map(principalPortion, 'name'), '+'),
                                    dependentTreatmentNames = _.join(_.map(dependentPortion, 'name'), '+');

                                if (poolMode === 0 && (!principalPortion.length || !dependentPortion.length)) {
                                    poolMode = 2;
                                }

                                var principalSummaryRecord = null;
                                if (principalTreatmentNames) {
                                    principalSummaryRecord = _.find(principalSummaries, function (x) { return x.key === principalTreatmentNames; });

                                    if (!principalSummaryRecord) {
                                        principalSummaries.push(principalSummaryRecord = new BenefitSummary(principalTreatmentNames));
                                    }
                                }

                                var dependentSummaryRecord = null;
                                if (dependentTreatmentNames) {
                                    dependentSummaryRecord = _.find(dependentSummaries, function (x) { return x.key === dependentTreatmentNames; });

                                    if (!dependentSummaryRecord) {
                                        dependentSummaries.push(dependentSummaryRecord = new BenefitSummary(dependentTreatmentNames));
                                    }
                                }

                                // Get modifier description

                                var str = '';
                                var strValue = _.isNil(modifier.value) ? '?' : modifier.value;
                                switch (modifier.type) {
                                    case 0: str += ' ' + ccyFilter(modifier.value, vm.currencyCode); break;
                                    case 1: str += ' ' + strValue + ' ' + App.localize('VisitLimit').toLowerCase(); break;
                                    case 2: str += ' ' + ccyFilter(modifier.value, vm.currencyCode) + ' (' + App.localize('BudgetOverdraft').toLowerCase() + ')'; break;
                                    case 3: str += ' ' + strValue + App.localize('VisitOverdraft').toLowerCase(); break;
                                }

                                var cycle = '';
                                switch (modifier.cycle) {
                                    case 0: cycle = App.localize('EveryXYears', modifier.interval).toLowerCase(); break;
                                    case 1: cycle = App.localize('Annually').toLowerCase(); break;
                                    case 2: cycle = App.localize('PerEmploymentContract').toLowerCase(); break;
                                }
                                str += ' ' + cycle;

                                if (poolMode === 0) {
                                    allStr = str;
                                    if (principalTreatmentNames !== dependentTreatmentNames) {
                                        if (principalPortion.length) {
                                            allStr += ' (' + App.localize('Principal') + ': ' + principalTreatmentNames + ')';
                                        }
                                        if (dependentPortion.length) {
                                            allStr += ' (' + App.localize('Dependent') + ': ' + dependentTreatmentNames + ')';
                                        }
                                    }

                                    if (principalSummaryRecord) {
                                        principalSummaryRecord.addItem(modifier.type, cycle, '[1]', modifier.value || 0);
                                        showPrincipalFootnote = true;
                                    }
                                    if (dependentSummaryRecord) {
                                        dependentSummaryRecord.addItem(modifier.type, cycle, '[1]', modifier.value || 0);
                                        showDependentFootnote = true;
                                    }
                                } else if (poolMode === 1) {
                                    principalStr = '';
                                    if (principalPortion.length) {
                                        principalStr = str;
                                        if (principalTreatmentNames !== modifierTreatmentNames) {
                                            principalStr += ' (' + principalTreatmentNames + ')';
                                        }
                                    }

                                    dependentStr = '';
                                    if (dependentPortion.length) {
                                        dependentStr = str;
                                        if (dependentTreatmentNames !== modifierTreatmentNames) {
                                            dependentStr += ' (' + dependentTreatmentNames + ')';
                                        }
                                    }

                                    if (principalSummaryRecord) {
                                        principalSummaryRecord.addItem(modifier.type, cycle, '', modifier.value || 0);
                                    }
                                    if (dependentSummaryRecord) {
                                        dependentSummaryRecord.addItem(modifier.type, cycle, '', modifier.value || 0);
                                    }
                                } else if (poolMode === 2) {
                                    principalStr = '';
                                    if (principalPortion.length) {
                                        principalStr = str;
                                        if (principalTreatmentNames !== modifierTreatmentNames) {
                                            principalStr += ' (' + principalTreatmentNames + ')';
                                        }
                                    }

                                    dependentStr = '';
                                    if (dependentPortion.length) {
                                        dependentStr = str;
                                        if (dependentTreatmentNames !== modifierTreatmentNames) {
                                            dependentStr += ' (' + dependentTreatmentNames + ')';
                                        }
                                    }

                                    if (principalSummaryRecord) {
                                        principalSummaryRecord.addItem(modifier.type, cycle, '', modifier.value || 0);
                                    }
                                    if (dependentSummaryRecord) {
                                        dependentSummaryRecord.addItem(modifier.type, cycle, '[2]', modifier.value || 0);
                                        showDependentFootnote = true;
                                    }
                                }
                            }
                        });
                    });

                    var copaymentModifiers = vm.isHospitalization ? _this.hospitalizationSetting.coPays : _this.maternitySetting.coPays;
                    var showPrincipalCopayFootnote = false;
                    var showDependentCopayFootnote = false;

                    if (_.find(copaymentModifiers, { 'beneficiary': 3 })) {
                        showPrincipalCopayFootnote = true;
                        showDependentCopayFootnote = true;
                    } else if (_.find(copaymentModifiers, { 'beneficiary': 2 })) {
                        showDependentCopayFootnote = true;
                    } else if (_.find(copaymentModifiers, { 'beneficiary': 1 })) {
                        showPrincipalCopayFootnote = true;
                    }

                    // Print summary

                    principal.forEach(function (x) {
                        if (x.unlimitedVisit || x.unlimitedBudget) {
                            var summary = _.find(principalSummaries, function (y) { return y.key === x.treatment.name; });
                            if (!summary) {
                                principalSummaries.push(summary = new BenefitSummary(x.treatment.name));
                            }

                            if (x.unlimitedBudget && x.unlimitedVisit) {
                                summary.addText(App.localize('Unlimited'));
                            } else {
                                if (x.unlimitedBudget) {
                                    summary.addText(App.localize('UnlimitedBudget'));
                                }
                                if (x.unlimitedVisit) {
                                    summary.addText(App.localize('UnlimitedVisit'));
                                }
                            }
                        }
                    });
                    _this.principalSummaries = principalSummaries;
                    _this.showPrincipalFootnote = showPrincipalFootnote;
                    _this.showPrincipalCopayFootnote = showPrincipalCopayFootnote;

                    dependent.forEach(function (x) {
                        if (x.unlimitedVisit || x.unlimitedBudget) {
                            var summary = _.find(dependentSummaries, function (y) { return y.key === x.treatment.name; });
                            if (!summary) {
                                dependentSummaries.push(summary = new BenefitSummary(x.treatment.name));
                            }
                            if (x.unlimitedBudget && x.unlimitedVisit) {
                                summary.addText(App.localize('Unlimited'));
                            } else {
                                if (x.unlimitedBudget) {
                                    summary.addText(App.localize('UnlimitedBudget'));
                                }
                                if (x.unlimitedVisit) {
                                    summary.addText(App.localize('UnlimitedVisit'));
                                }
                            }
                        }
                    });
                    _this.dependentSummaries = dependentSummaries;
                    _this.showDependentFootnote = showDependentFootnote;
                    _this.showDependentCopayFootnote = showDependentCopayFootnote;
                };

                // Initialization

                if (dto) {
                    automap(dto, _this);

                    // Inpatient or maternity settings.

                    if (_this.serviceType === 7) {
                        // Basics

                        _this.hospitalizationSetting.hasDependentCovered = dto.hospitalizationSetting.hasDependentCovered ? 1 : 0;
                        _this.hospitalizationSetting.isAccidentAdmission = dto.hospitalizationSetting.admissionWaitingAccidentDays > 0 ? 1 : 0;
                        _this.hospitalizationSetting.isIllnessAdmission = dto.hospitalizationSetting.admissionWaitingIllnessDays > 0 ? 1 : 0;
                        _this.hospitalizationSetting.isPreExisting = dto.hospitalizationSetting.admissionWaitingPreExistingDays > 0 ? 1 : 0;
                        _this.hospitalizationSetting.isNonAccidentalIllness = dto.hospitalizationSetting.admissionWaitingNonAccidentalIllnessDays > 0 ? 1 : 0;
                        _this.hospitalizationSetting.isDefaultExclusionsCovered = dto.hospitalizationSetting.isDefaultExclusionsCovered ? 1 : 0;

                        _this.hospitalizationSetting.coveredOptionalExclusions = buildExclusionTypeList(vm.optionalExclusions, dto.hospitalizationSetting.coveredOptionalExclusions);

                        // Benefits

                        var clonedHospitalizationModifiers = dto.hospitalizationSetting.modifiers.slice(0);
                        _this.hospitalizationSetting.modifiers = [];
                        _.each(clonedHospitalizationModifiers, function (x) {
                            _this.hospitalizationSetting.modifiers.push(new Modifier(_this, x));
                        });

                        // Cost sharing
                        _this.hospitalizationSetting.isCoInsurance = dto.hospitalizationSetting.isCoInsurance;

                        var clonedHospitalizationCoPays = dto.hospitalizationSetting.coPays ? dto.hospitalizationSetting.coPays.slice(0) : null;
                        _this.hospitalizationSetting.coPays = [];
                        _.each(clonedHospitalizationCoPays, function (x) {
                            _this.hospitalizationSetting.coPays.push(new CoPay(_this, x));
                        });

                        // Schedule of benefit

                        _this.hospitalizationSetting.enableDependentSetting = 0;
                        initializeScheduleOfBenefit(_this.hospitalizationSetting.principalScheduleOfBenefit, dto.hospitalizationSetting.principalScheduleOfBenefit);

                        if (dto.hospitalizationSetting.dependentScheduleOfBenefit !== null) {
                            initializeScheduleOfBenefit(_this.hospitalizationSetting.dependentScheduleOfBenefit, dto.hospitalizationSetting.dependentScheduleOfBenefit);
                        }
                    } else {
                        // Basics

                        _this.maternitySetting.isSpouseCovered = dto.maternitySetting.isSpouseCovered ? 1 : 0;
                        _this.maternitySetting.isHasMotherAgeLimit = dto.maternitySetting.motherMaxAge > 0 ? 1 : 0;
                        _this.maternitySetting.isMaternitybenefits = dto.maternitySetting.admissionWaitingDays > 0 ? 1 : 0;
                        _this.maternitySetting.isMaternitybenefits = dto.maternitySetting.admissionWaitingDays > 0 ? 1 : 0;
                        _this.maternitySetting.isUnlimitedDelivery = dto.maternitySetting.deliveryLimit === 0 ? 1 : 0;

                        // Benefits

                        var clonedMaternityModifiers = dto.maternitySetting.modifiers.slice(0);
                        _this.maternitySetting.modifiers = [];
                        _.each(clonedMaternityModifiers, function (x) {
                            _this.maternitySetting.modifiers.push(new Modifier(_this, x));
                        });

                        // Cost sharing

                        _this.maternitySetting.isCoInsurance = dto.maternitySetting.isCoInsurance;

                        var clonedMaternityCoPays = dto.maternitySetting.coPays ? dto.maternitySetting.coPays.slice(0) : null;
                        _this.maternitySetting.coPays = [];
                        _.each(clonedMaternityCoPays, function (x) {
                            _this.maternitySetting.coPays.push(new CoPay(_this, x));
                        });

                        // Normal delivery

                        _this.maternitySetting.normalDelivery.amountType = dto.maternitySetting.normalDelivery.isAsCharged ? 0 : 1;
                        if (!_this.maternitySetting.normalDelivery.isCovered) {
                            _this.maternitySetting.normalDelivery.amountType = 2;
                        }
                        _this.maternitySetting.normalDelivery.maxDaysTimesType = dto.maternitySetting.normalDelivery.isUnlimited ? 1 : 0;

                        // Caesarean delivery

                        _this.maternitySetting.caesareanDelivery.amountType = dto.maternitySetting.caesareanDelivery.isAsCharged ? 0 : 1;
                        if (!_this.maternitySetting.caesareanDelivery.isCovered) {
                            _this.maternitySetting.caesareanDelivery.amountType = 2;
                        }
                        _this.maternitySetting.caesareanDelivery.maxDaysTimesType = dto.maternitySetting.caesareanDelivery.isUnlimited ? 1 : 0;

                        // Normal delivery for twins and above

                        _this.maternitySetting.normalDeliveryTwinsAndAbove.amountType = dto.maternitySetting.normalDeliveryTwinsAndAbove.isAsCharged ? 0 : 1;
                        if (!_this.maternitySetting.normalDeliveryTwinsAndAbove.isCovered) {
                            _this.maternitySetting.normalDeliveryTwinsAndAbove.amountType = 2;
                        }
                        _this.maternitySetting.normalDeliveryTwinsAndAbove.maxDaysTimesType = dto.maternitySetting.normalDeliveryTwinsAndAbove.isUnlimited ? 1 : 0;

                        // Caesarean delivery for twins and above

                        _this.maternitySetting.caesareanDeliveryTwinsAndAbove.amountType = dto.maternitySetting.caesareanDeliveryTwinsAndAbove.isAsCharged ? 0 : 1;
                        if (!_this.maternitySetting.caesareanDeliveryTwinsAndAbove.isCovered) {
                            _this.maternitySetting.caesareanDeliveryTwinsAndAbove.amountType = 2;
                        }
                        _this.maternitySetting.caesareanDeliveryTwinsAndAbove.maxDaysTimesType = dto.maternitySetting.caesareanDeliveryTwinsAndAbove.isUnlimited ? 1 : 0;

                        // Pre-natal check-up

                        _this.maternitySetting.preNatalCheckUp.isCovered = dto.maternitySetting.preNatalCheckUp.isCovered ? 1 : 0;
                        _this.maternitySetting.preNatalCheckUp.amountType = dto.maternitySetting.preNatalCheckUp.isAsCharged ? 0 : 1;
                        _this.maternitySetting.preNatalCheckUp.maxDaysTimesType = dto.maternitySetting.preNatalCheckUp.isUnlimited ? 1 : 0;

                        // Post-natal follow-up

                        _this.maternitySetting.postNatalFollowUp.isCovered = dto.maternitySetting.postNatalFollowUp.isCovered ? 1 : 0;
                        _this.maternitySetting.postNatalFollowUp.amountType = dto.maternitySetting.postNatalFollowUp.isAsCharged ? 0 : 1;
                        _this.maternitySetting.postNatalFollowUp.maxDaysTimesType = dto.maternitySetting.postNatalFollowUp.isUnlimited ? 1 : 0;

                        // Miscarriage

                        _this.maternitySetting.miscarriage.amountType = dto.maternitySetting.miscarriage.isAsCharged ? 0 : 1;
                        _this.maternitySetting.miscarriage.maxDaysTimesType = dto.maternitySetting.miscarriage.isUnlimited ? 1 : 0;

                        // Covers pregnancy related complications

                        _this.maternitySetting.isOptionalExclusionsCovered = dto.maternitySetting.coveredOptionalExclusions
                            && dto.maternitySetting.coveredOptionalExclusions.length > 0 ? 1 : 0;
                        _this.maternitySetting.coveredOptionalExclusions = buildExclusionTypeList(vm.optionalExclusions, dto.maternitySetting.coveredOptionalExclusions);

                        // Neo-natal

                        _this.maternitySetting.neoNatal.isCovered = dto.maternitySetting.neoNatal.isCovered ? 1 : 0;
                    }
                }

                _this.refreshResult();

                //Post method

                _this.toDto = function () {
                    function getOptionalExclusionIds(exclusions) {
                        return _(exclusions).filter('valid').map('id').value();
                    }

                    function getModifierDto(modifiers) {
                        return _(modifiers).map(function (m) { return m.toDto(); }).value();
                    }

                    function getCoPayDto(coPays) {
                        return _(coPays).map(function (m) { return m.toDto(); }).value();
                    }

                    function getScheduleOfBenefitDto(setting) {
                        return {
                            roomAndBoardAmount: setting.roomAndBoard.amount,
                            icuRoomAndBoard: {
                                isAsCharged: setting.icuRoomAndBoard.amountType === 0,
                                amount: setting.icuRoomAndBoard.amount
                            },
                            childGuardianBenefit: {
                                isAsCharged: setting.childGuardianBenefit.amountType === 0,
                                amount: setting.childGuardianBenefit.amount,
                                isCovered: setting.childGuardianBenefit.amountType !== 2,
                            },
                            inHospitalPhysicianVisitPerDay: setting.inHospitalPhysicianVisitPerDay,
                            hospitalCharges: {
                                isFixedLimit: setting.hospitalCharges.isFixedLimit === 1,
                                fixedLimitAmount: setting.hospitalCharges.fixedLimitAmount,
                                surgicalFee: {
                                    isAsCharged: setting.hospitalCharges.surgicalFee.amountType === 0,
                                    amount: setting.hospitalCharges.surgicalFee.amount
                                },
                                anaesthetistFee: {
                                    isAsCharged: setting.hospitalCharges.anaesthetistFee.amountType === 0,
                                    amount: setting.hospitalCharges.anaesthetistFee.amount
                                },
                                operatingTheatre: {
                                    isAsCharged: setting.hospitalCharges.operatingTheatre.amountType === 0,
                                    amount: setting.hospitalCharges.operatingTheatre.amount
                                },
                                hospitalSuppliesAndServices: {
                                    isAsCharged: setting.hospitalCharges.hospitalSuppliesAndServices.amountType === 0,
                                    amount: setting.hospitalCharges.hospitalSuppliesAndServices.amount
                                },
                            },
                            ambulanceFee: {
                                isAsCharged: setting.ambulanceFee.amountType === 0,
                                amount: setting.ambulanceFee.amount,
                                isCovered: setting.ambulanceFee.amountType !== 2,
                            },
                            medicalReport: {
                                isAsCharged: setting.medicalReport.amountType === 0,
                                amount: setting.medicalReport.amount,
                            },
                            organTransplant: {
                                isAsCharged: setting.organTransplant.amountType === 0,
                                amount: setting.organTransplant.amount,
                                isCovered: setting.organTransplant.amountType !== 2,
                                isUnlimited: setting.organTransplant.maxDaysTimesType === 1,
                                coveredTimes: setting.organTransplant.coveredTimes
                            },
                            preHospitalizationTreatment: {
                                isCovered: setting.preHospitalizationTreatment.isCovered === 1,
                                coveredDays: setting.preHospitalizationTreatment.coveredDays
                            },
                            specialistConsultation: {
                                isUnlimited: setting.specialistConsultation.maxDaysTimesType === 1,
                                coveredTimes: setting.specialistConsultation.coveredTimes
                            },
                            surgicalOpinion: {
                                isAsCharged: setting.surgicalOpinion.amountType === 0,
                                amount: setting.surgicalOpinion.amount,
                                isCovered: setting.surgicalOpinion.amountType !== 2,
                                isUnlimited: setting.surgicalOpinion.maxDaysTimesType === 1,
                                coveredTimes: setting.surgicalOpinion.coveredTimes
                            },
                            postHospitalizationTreatment: {
                                isCovered: setting.postHospitalizationTreatment.isCovered === 1,
                                coveredDays: setting.postHospitalizationTreatment.coveredDays
                            },
                            followUpTreatment: {
                                isAsCharged: setting.followUpTreatment.amountType === 0,
                                amount: setting.followUpTreatment.amount
                            },
                            outpatientCancerTreatment: {
                                isCovered: setting.outpatientCancerTreatment.amountType !== 1,
                                amount: setting.outpatientCancerTreatment.amount
                            },
                            outpatientKidneyDialysisTreatment: {
                                isCovered: setting.outpatientKidneyDialysisTreatment.amountType !== 1,
                                amount: setting.outpatientKidneyDialysisTreatment.amount
                            }
                        };
                    }

                    function getMaternityBenefitSettingsDto(settings) {
                        return {
                            isSpouseCovered: settings.isSpouseCovered === 1,
                            admissionWaitingDays: settings.isMaternitybenefits === 1 ? settings.admissionWaitingDays : 0,
                            motherMaxAge: settings.isHasMotherAgeLimit === 1 ? settings.motherMaxAge : 0,
                            isUnlimitedDelivery: settings.isUnlimitedDelivery === 1,
                            deliveryLimit: settings.isUnlimitedDelivery === 0 ? settings.deliveryLimit : 0,
                            isDefaultExclusionsCovered: settings.isDefaultExclusionsCovered === 1,
                            exclusionVersionId: settings.exclusionVersionId,
                            coveredOptionalExclusions: settings.isOptionalExclusionsCovered === 1
                                ? getOptionalExclusionIds(settings.coveredOptionalExclusions)
                                : [],
                            copays: getCoPayDto(settings.coPays),
                            modifiers: getModifierDto(settings.modifiers),
                            normalDelivery: {
                                isAsCharged: settings.normalDelivery.amountType === 0,
                                amount: settings.normalDelivery.amount,
                                isCovered: settings.normalDelivery.amountType !== 2,
                                isUnlimited: settings.normalDelivery.maxDaysTimesType === 1,
                                coveredTimes: settings.normalDelivery.coveredTimes,
                                coveredCycle: settings.normalDelivery.coveredCycle
                            },
                            caesareanDelivery: {
                                isAsCharged: settings.caesareanDelivery.amountType === 0,
                                amount: settings.caesareanDelivery.amount,
                                isCovered: settings.caesareanDelivery.amountType !== 2,
                                isUnlimited: settings.caesareanDelivery.maxDaysTimesType === 1,
                                coveredTimes: settings.caesareanDelivery.coveredTimes,
                                coveredCycle: settings.caesareanDelivery.coveredCycle
                            },
                            normalDeliveryTwinsAndAbove: {
                                isAsCharged: settings.normalDeliveryTwinsAndAbove.amountType === 0,
                                amount: settings.normalDeliveryTwinsAndAbove.amount,
                                isCovered: settings.normalDeliveryTwinsAndAbove.amountType !== 2,
                                isUnlimited: settings.normalDeliveryTwinsAndAbove.maxDaysTimesType === 1,
                                coveredTimes: settings.normalDeliveryTwinsAndAbove.coveredTimes,
                                coveredCycle: settings.normalDeliveryTwinsAndAbove.coveredCycle
                            },
                            caesareanDeliveryTwinsAndAbove: {
                                isAsCharged: settings.caesareanDeliveryTwinsAndAbove.amountType === 0,
                                amount: settings.caesareanDeliveryTwinsAndAbove.amount,
                                isCovered: settings.caesareanDeliveryTwinsAndAbove.amountType !== 2,
                                isUnlimited: settings.caesareanDeliveryTwinsAndAbove.maxDaysTimesType === 1,
                                coveredTimes: settings.caesareanDeliveryTwinsAndAbove.coveredTimes,
                                coveredCycle: settings.caesareanDeliveryTwinsAndAbove.coveredCycle
                            },
                            preNatalCheckUp: {
                                isCovered: settings.preNatalCheckUp.isCovered === 1,
                                coveredDays: settings.preNatalCheckUp.coveredDays,
                                isAsCharged: settings.preNatalCheckUp.amountType === 0,
                                amount: settings.preNatalCheckUp.amount,
                                isUnlimited: settings.preNatalCheckUp.maxDaysTimesType === 1,
                                coveredTimes: settings.preNatalCheckUp.coveredTimes,
                                coveredCycle: settings.preNatalCheckUp.coveredCycle
                            },
                            postNatalFollowUp: {
                                isCovered: settings.postNatalFollowUp.isCovered === 1,
                                coveredDays: settings.postNatalFollowUp.coveredDays,
                                isAsCharged: settings.postNatalFollowUp.amountType === 0,
                                amount: settings.postNatalFollowUp.amount,
                                isUnlimited: settings.postNatalFollowUp.maxDaysTimesType === 1,
                                coveredTimes: settings.postNatalFollowUp.coveredTimes
                            },
                            miscarriage: {
                                isAsCharged: settings.miscarriage.amountType === 0,
                                amount: settings.miscarriage.amount,
                                isCovered: settings.miscarriage.amountType !== 2,
                            },
                            neoNatal: {
                                isCovered: settings.neoNatal.isCovered === 1,
                                coveredDays: settings.neoNatal.coveredDays,
                                newBornNicuAmount: settings.neoNatal.newBornNicuAmount,
                                newBornIncubationAmount: settings.neoNatal.newBornIncubationAmount,
                                newBornPhotoTherapyAmount: settings.neoNatal.newBornPhotoTherapyAmount
                            },
                            isCoInsurance: settings.isCoInsurance
                        };
                    }

                    function getHospitalizationBenefitSettingsDto(settings, isCreate) {
                        var principalScheduleOfBenefit = getScheduleOfBenefitDto(settings.principalScheduleOfBenefit);
                        var dependentScheduleOfBenefit = isCreate && settings.enableDependentSetting === 0
                            ? principalScheduleOfBenefit
                            : getScheduleOfBenefitDto(settings.dependentScheduleOfBenefit);

                        return {
                            hasDependentCovered: setting.hasDependentCovered === 1 ? settings.hasDependentCovered : 0,
                            admissionWaitingAccidentDays: settings.isAccidentAdmission === 1 ? settings.admissionWaitingAccidentDays : 0,
                            admissionWaitingIllnessDays: settings.isIllnessAdmission === 1 ? settings.admissionWaitingIllnessDays : 0,
                            admissionWaitingPreExistingDays: settings.isPreExisting === 1 ? settings.admissionWaitingPreExistingDays : 0,
                            admissionWaitingNonAccidentalIllnessDays: settings.isNonAccidentalIllness === 1 ? settings.admissionWaitingNonAccidentalIllnessDays : 0,
                            exclusionVersionId: settings.exclusionVersionId,
                            isDefaultExclusionsCovered: settings.isDefaultExclusionsCovered === 1,
                            coveredOptionalExclusions: getOptionalExclusionIds(settings.coveredOptionalExclusions),
                            isCoInsurance: settings.isCoInsurance,
                            copays: getCoPayDto(settings.coPays),
                            modifiers: getModifierDto(settings.modifiers),
                            principalScheduleOfBenefit: principalScheduleOfBenefit,
                            dependentScheduleOfBenefit: settings.hasDependentCovered === 1 ? dependentScheduleOfBenefit : null
                        };
                    }

                    var hospitalizationSetting = null;
                    var maternitySettings = null;
                    if (vm.isHospitalization) {
                        hospitalizationSetting = getHospitalizationBenefitSettingsDto(_this.hospitalizationSetting, vm.isCreate);
                    } else {
                        maternitySettings = getMaternityBenefitSettingsDto(_this.maternitySetting);
                    }

                    return {
                        id: _this.id,
                        tenantId: _this.tenantId,
                        serviceType: _this.serviceType,
                        name: _this.name,
                        displayName: _this.displayName,
                        hospitalizationSetting: hospitalizationSetting,
                        maternitySetting: maternitySettings
                    };
                };
            }

            function ExclusionModel(id, name, valid) {
                this.id = id;
                this.name = name;
                this.valid = valid;
            }

            function buildExclusionTypeList(optionalExclusions, coveredOptionalExclusions) {
                var exclusions = [];
                if (!coveredOptionalExclusions)
                    coveredOptionalExclusions = [];

                _.each(optionalExclusions, function (exc) {
                    exclusions.push(new ExclusionModel(exc.value, exc.name, _.indexOf(coveredOptionalExclusions, exc.value) >= 0));
                });

                return exclusions;
            }

            function TreatmentModel(id, name, icon, valid) {
                this.id = id;
                this.name = name;
                this.icon = icon;
                this.valid = valid;
            }

            function TreatmentApplyModel(treatment, applicable) {
                this.treatment = treatment;
                this.applicable = applicable;
            }

            function BenefitResult(treatment, unlimitedBudget, unlimitedVisit) {
                this.treatment = treatment;
                this.unlimitedBudget = unlimitedBudget;
                this.unlimitedVisit = unlimitedVisit;
            }

            function BenefitSummaryItem(type, cycle, poolMode, value) {
                this.type = type;
                this.cycle = cycle;
                this.poolMode = poolMode;
                this.value = value;

                this.toString = function () {
                    var str = '';
                    var strValue = _.isNil(this.value) ? '?' : this.value;
                    switch (this.type) {
                        case 0: str += 'RM' + strValue; break;
                        case 1: str += strValue + ' ' + (this.value > 1 ? 'visits' : 'visit'); break;
                        case 2: str += 'RM' + strValue + ' overdraft'; break;
                        case 3: str += strValue + ' overdraft ' + (this.value > 1 ? 'visits' : 'visit'); break;
                    }

                    str += ' ' + this.cycle + ' ' + this.poolMode;
                    return str;
                };
            }

            function BenefitSummary(key, value) {
                this.items = [];
                this.texts = [];

                this.key = key;
                this.value = value;

                this.addItem = function (type, cycle, poolMode, value) {
                    var item = _.find(this.items, function (x) {
                        return x.type === type && x.cycle === cycle && x.poolMode === poolMode;
                    });

                    if (!item) {
                        this.items.push(new BenefitSummaryItem(type, cycle, poolMode, value));
                    } else {
                        item.value += value;
                    }
                };

                this.addText = function (text) {
                    this.texts.push(text);
                };

                this.toString = function () {
                    return _.concat(_.map(this.items, function (x) { return x.toString(); }), this.texts).join(', ');
                };
            }

            function Modifier(bgModel, dto) {
                var _this = this;

                _this.parent = bgModel;

                // Basic properties

                _this.type = 0;
                _this.cycle = 1;
                _this.beneficiary = 3;
                _this.poolMode = 0;
                _this.value = null;
                _this.interval = 2;

                // View properties

                _this.show = true;
                _this.description = '';

                // Treatment type mapping

                _this.principalMap = _.keyBy(bgModel.principal, 'id');
                _this.dependentMap = _.keyBy(bgModel.dependent, 'id');

                // Applicable treatment types

                var treatments = [];
                _.each(_this.parent.principal, function (e) {
                    var isValid = checkValid(e.id);
                    treatments.push(new TreatmentApplyModel(new TreatmentModel(e.id, e.name, e.icon, isValid), isValid));
                });
                _this.treatments = treatments;

                // Private functions

                function checkValid(id) {
                    switch (_this.beneficiary) {
                        case 3:
                            return _this.principalMap[id].valid
                                || _this.parent.hasDependentCovered === 1 && _this.dependentMap[id].valid;
                        case 1:
                            return _this.principalMap[id].valid;
                        case 2:
                            return _this.dependentMap[id].valid;
                        default:
                            return false;
                    }
                }

                function validateModifierRules() {
                    // If modifier is not budget limit, cycle cannot be Per Visit.
                    // Default to annually.

                    if (_this.type !== 0 && _this.cycle === 4) {
                        _this.cycle = 1;
                    }

                    // If beneficiary is principal only, there is no pool to share.
                    // If beneficiary is dependant only, split pool cannot be selected.
                    // Default to individual pool.

                    if (_this.beneficiary === 1 || _this.beneficiary === 2 && _this.poolMode === 2) {
                        _this.poolMode = 1;
                    }
                }

                // Public functions

                _this.changed = function () {
                    validateModifierRules();
                    _this.refreshTreatmentValidity();
                    _this.refreshDescription();
                };

                _this.refreshTreatmentValidity = function () {
                    _.each(_this.treatments, function (e) {
                        e.treatment.valid = checkValid(e.treatment.id);
                    });
                };

                //Post method

                _this.toDto = function () {
                    return {
                        type: _this.type,
                        cycle: _this.cycle,
                        beneficiary: _this.beneficiary,
                        poolMode: _this.poolMode,
                        value: _this.value,
                        interval: _this.interval
                    };
                };

                _this.refreshDescription = function () {
                    var type = _this.type,
                        cycle = _this.cycle,
                        interval = _this.interval,
                        beneficiary = _this.beneficiary,
                        poolMode = _this.poolMode,
                        value = _this.value,
                        treatmentTypes = _this.treatmentTypes,
                        desc = '',
                        ttNames = [];

                    switch (cycle) {
                        case 0:
                            desc += 'Every ' + interval + ' year(s)';
                            break;
                        case 1:
                            desc += 'Every year';
                            break;
                        case 2:
                            desc += 'Every month';
                            break;
                        case 3:
                            desc += 'Everyday';
                            break;
                        case 4:
                            desc += 'Every visit';
                            break;
                    }

                    switch (type) {
                        case 0:
                        case 1:
                            desc += ' limits ';
                            break;
                        case 2:
                        case 3:
                            desc += ' allows overdraft of ';
                    }

                    switch (type) {
                        case 0:
                        case 2:
                            desc += vm.currencyCode + ' ' + value;
                            break;
                        case 1:
                        case 3:
                            desc += value + ' visits';
                            break;
                    }

                    switch (beneficiary) {
                        case 1:
                            desc += ' for principal';
                            break;
                        case 2:
                            desc += ' for dependants';
                            break;
                        case 3:
                            desc += ' for principal & dependants';
                            break;
                    }

                    switch (poolMode) {
                        case 0:
                            desc += ' where everyone share';
                            break;
                        case 1:
                            desc += ' individually';
                            break;
                        case 2:
                            desc += ' where dependents share';
                            break;
                    }

                    if (treatmentTypes) {
                        desc += ' on ';

                        _.each(treatmentTypes, function (isApplied, idStr) {
                            var id = parseInt(idStr);
                            if (isApplied) {
                                ttNames.push(_.find(vm.treatmentTypes, function (a) { return a.id === id; }).name);
                            }
                        });

                        desc += ttNames.join(', ');
                    }

                    _this.description = ttNames.length > 0 ? desc : 'Not applicable';
                };

                // Initialization

                if (dto) {
                    automap(dto, _this);
                    _this.refreshTreatmentValidity();
                }

                _this.refreshDescription();
            }

            function CoPay(bgModel, dto) {
                var _this = this;

                _this.parent = bgModel;

                // Basic properties

                _this.beneficiary = 3;
                _this.isPercentage = true;
                _this.value = null;

                // View properties

                _this.show = true;

                // Treatment type mapping

                _this.principalMap = _.keyBy(bgModel.principalBenefits, 'id');
                _this.dependentMap = _.keyBy(bgModel.dependentBenefits, 'id');

                // Applicable treatment types

                var treatments = [];
                _.each(_this.parent.principalBenefits, function (e) {
                    var isValid = checkValid(e.id);
                    treatments.push(new TreatmentApplyModel(new TreatmentModel(e.id, e.name, e.icon, isValid), isValid));
                });
                _this.treatments = treatments;

                // Private functions

                function checkValid(id) {
                    switch (_this.beneficiary) {
                        case 3:
                            return _this.principalMap[id].valid
                                || _this.parent.hasDependentCovered === 1 && _this.dependentMap[id].valid;
                        case 1:
                            return _this.principalMap[id].valid;
                        case 2:
                            return _this.dependentMap[id].valid;
                        default:
                            return false;
                    }
                }

                // Public functions

                _this.changed = function () {
                    _this.refreshTreatmentValidity();
                };

                _this.refreshTreatmentValidity = function () {
                    _.each(_this.treatments, function (e) {
                        e.treatment.valid = checkValid(e.treatment.id);
                    });
                };

                _this.toDto = function () {
                    if (vm.isHospitalization) {
                        return {
                            beneficiary: _this.beneficiary,
                            isPercentage: _this.isPercentage,
                            value: _this.value,
                            isAffectingAdmission: _this.isAffectingAdmission,
                            isAffectingPreHospitalization: _this.isAffectingPreHospitalization,
                            isAffectingPostHospitalization: _this.isAffectingPostHospitalization,
                            isAffectingSelfPayHospitalization: _this.isAffectingSelfPayHospitalization
                        };
                    } else {
                        return {
                            beneficiary: _this.beneficiary,
                            isPercentage: _this.isPercentage,
                            value: _this.value,
                            isAffectingNormalDelivery: _this.isAffectingNormalDelivery,
                            isAffectingCaesareanDelivery: _this.isAffectingCaesareanDelivery,
                            isAffectingPreNatalCheckUp: _this.isAffectingPreNatalCheckUp,
                            isAffectingPostNatalFollowUp: _this.isAffectingPostNatalFollowUp,
                            isAffectingNeoNatal: _this.isAffectingNeoNatal
                        };
                    }
                };

                // Initialization

                if (dto) {
                    automap(dto, _this);
                    _this.refreshTreatmentValidity();
                }
            }

            vm.beneficiary = {
                principal: 1,
                dependent: 2
            };

            init();
        }
    ]);
})();
