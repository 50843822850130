import _ from 'lodash';

(() => {
  angular
    .module('finance')
    .controller('finance.views.accountOwners.index', AccountOwnersPageController);

  AccountOwnersPageController.$inject = [
    '$scope',
    '$timeout',
    'abp.services.finance.accountOwners',
    '$uibModal',
    'abp.services.finance.commonLookup',
  ];

  function AccountOwnersPageController(
    $scope,
    $timeout,
    accountOwnersSvc,
    $modal,
    commonLookupSvc
  ) {
    const vm = this;

    vm.loading = 0;
    vm.countries = [];

    vm.accountOwnerGridOptions = {
      appScopeProvider: vm,
      columnDefs: [
        {
          name: App.localize('Actions'),
          displayName: 'Actions',
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
          allowCellFocus: false,
        },
        {
          displayName: App.localize('AccountOwner'),
          field: 'name',
          minWidth: 120,
        },
        {
          displayName: App.localize('Country'),
          field: 'countryCode',
          minWidth: 25,
          cellTemplate: 'countryCodeTemplate',
        },
        {
          displayName: App.localize('BusinessRegistrationNumber'),
          field: 'businessRegistrationNumber',
          minWidth: 100,
        },
      ],
      data: [],
    };

    // Function binding.

    vm.openBankDetailsModal = openBankDetailsModal;
    vm.getAccountOwners = getAccountOwners;
    vm.getSettings = getSettings;

    init();

    function init() {
      getAccountOwners();
      getCountries();
      getSettings();
    }

    function getAccountOwners() {
      vm.loading += 1;
      accountOwnersSvc
        .getAccountOwners()
        .success((data) => {
          vm.accountOwnerGridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getCountries() {
      commonLookupSvc.getCountries().success((data) => {
        if (data) {
          vm.countries = data.items;
        }
      });
    }

    function openBankDetailsModal(accountOwner) {
      $modal
        .open({
          templateUrl: require('./editBankDetailsModal.html'),
          controller: 'finance.views.accountOwners.editBankDetailsModal as vm',
          backdrop: 'static',
          resolve: {
            accountOwnerId() {
              return accountOwner.id;
            },
            countryCode() {
              return accountOwner.countryCode;
            },
          },
        })
        .result.then(() => {
          vm.getAccountOwners();
        });
    }

    function getSettings() {
      vm.loading += 1;
      commonLookupSvc
        .getCommonSettings()
        .success((data) => {
          vm.manualTransactionGenerator = data.manualTransactionGenerator;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
