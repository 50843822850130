(function () {
    var componentId = 'hostCorporatesFinanceManagementBankingComponent';
    var app = angular.module('app');
    app.component(componentId, {
        templateUrl: require('/App/host/views/corporates/financeManagement/banking/banking.component.html'),
        controller: ['$scope', '$rootScope', 'abp.services.app.corporate', 'Hms.PatientTickets.ServiceFeePayor', 'Hms.MultiTenancy.TenantVirtualAccountGenerationMode', controller],
        controllerAs: 'vm',
        bindings: {
            corporateId: '<',
        }
    });

    function controller($scope, $rootScope, corporateSvc, enumServiceFeePayor, enumTenantVirtualAccountGenerationMode) {
        var vm = this;
        vm.loading = 0;
        vm.bankingDetails = null;

        vm.enums = {};
        vm.enums.serviceFeePayor = enumServiceFeePayor;
        vm.enums.tenantVirtualAccountGenerationMode = enumTenantVirtualAccountGenerationMode;

        // Get banking details of corporate

        vm.$onChanges = function () {
            if (vm.corporateId)
                getBankingDetails(vm.corporateId);
        };

        function getBankingDetails(corporateId) {
            vm.loading++;
            corporateSvc.getCorporateForEdit(
                {
                    id: corporateId
                }).success(function (data) {
                    vm.bankingDetails = data.finance;
                }).finally(function () {
                    vm.loading--;
                });
        }
    }
})();
