(function () {
    'use strict'

    var app = angular.module('app');
    angular.module('app')
        .controller('host.views.doreamon.query.createQuery', ['$state', '$stateParams',
            'abp.services.app.doraemon', controller]);

    function controller($state, $stateParams, doraemonSvc) {
        var vm = this;
        vm.loading = 0;
        vm.saved = false;
        vm.saving = 0;
        vm.exporting = 0;
        vm.submit = submit;
        vm.addQuestion = addQuestion;
        vm.addQueryItem = addQueryItem;
        vm.addItem = addItem;
        vm.deleteQuestion = deleteQuestion;
        vm.deleteQueryItem = deleteQueryItem;
        vm.deleteItem = deleteItem;

        vm.guaranteeLetterNumber = $stateParams.letterNumber;
        vm.serviceRequestId = $stateParams.serviceRequestId;

        vm.queryItems = [
            {
                isGeneralAttention: false,
                doctorId: "",
                type: 1,
                questions: [{}],
                items: [{}],
                newAttachments: []
            }];

        /* Attachments */

        var fileExtensions = '|' + app.consts.fileUpload.imageExtensions.concat(app.consts.fileUpload.pdfExtensions).join('|') + '|';
        vm.uploadConfig = {
            objectType: 'InpatientQueryItemAttachment',
            itemsLimit: 5,
            accept: 'image/*,application/pdf',
            filters: [{
                name: 'fileFilter',
                fn: function (n) {
                    var t = '|' + n.type.slice(n.type.lastIndexOf('/') + 1) + '|';
                    if (fileExtensions.indexOf(t) === -1) {
                        abp.message.warn(App.localize('File_Warn_FileType'));
                        return false;
                    }
                    return true;
                }
            }, {
                name: 'sizeFilter',
                fn: function (n) {
                    var sizeLimit = 52428800;
                    if (n.size > sizeLimit) {
                        abp.message.warn(App.localize('File_Warn_XSizeLimit', sizeLimit / 1024 / 1024));
                        return false;
                    }
                    return true;
                }
            }]
        };

        /* End Attachments */

        init();

        /* Functions */

        function submit() {
            if (!validateQuery()) {
                return false;
            }

            vm.saving++;

            _.forEach(vm.queryItems, function (query) {
                if (query.type === 2) {
                    query.questions = query.items;
                }
            })

            var input = {
                letterNumber: vm.guaranteeLetterNumber,
                serviceRequestId: vm.serviceRequestId,
                serviceRequestQueryItems: vm.queryItems
            };

            doraemonSvc.createQuery(input)
                .success(function (data) {
                    vm.saved = true;
                    abp.notify.success(App.localize('SuccessfullySaved'));
                    var queryItemIds = data;

                    //save and print/export all the medical queries to the computer
                    _.forEach(queryItemIds, queryItemId => {
                        downloadMedicalQueryForm(queryItemId);
                    });
                }).finally(function () {
                    vm.saving--;
                });
        }

        function downloadMedicalQueryForm(queryItemId) {
            vm.exporting++;
            doraemonSvc.getMedicalQueryFormPdf({ id: queryItemId })
                .success(function (data) {
                    App.downloadTempFile(data);
                }).finally(function () {
                    vm.exporting--;

                    // go back in history(either back to iglProcessing or the details page) after all pdfs are downloaded
                    if (vm.exporting < 1) {
                        window.history.back();
                    }
                });
        }

        function validateQuery() {
            var isFormValid = true;

            // Validate query item

            if (_.isEmpty(vm.queryItems)) {
                abp.message.warn(App.localize('EmptyQueryItemWarning'));
                return false;
            }

            _.forEach(vm.queryItems, function (query) {
                var isItemEmpty = _.some(query.items, function (item) {
                    return _.isEmpty(item.documentName) && _.isEmpty(item.content);
                });

                var isQuestionEmpty = _.some(query.questions, function (question) {
                    return _.isEmpty(question.content);
                });

                // Validate doctor

                if (!query.isGeneralAttention && !query.doctorId) {
                    abp.message.warn(App.localize('EmptyDoctorWarning'));
                    isFormValid = false;
                    return false;
                }

                // Validate questions

                if (query.type === 1 && (_.isEmpty(query.questions) || isQuestionEmpty)) {
                    abp.message.warn(App.localize('EmptyQuestionWarning'));
                    isFormValid = false;
                    return false;
                }

                // Validate items

                else if (query.type === 2 && (_.isEmpty(query.items) || isItemEmpty)) {
                    abp.message.warn(App.localize('EmptyItemWarning'));
                    isFormValid = false;
                    return false;
                }
            });

            return isFormValid;
        }

        /* Query items */

        function addQueryItem() {
            vm.queryItems.push({
                isGeneralAttention: false,
                doctorId: "",
                type: 1,
                newAttachments: [],
                questions: [{}],
                items: [{}]
            });
        }

        function deleteQueryItem(index) {
            vm.queryItems.splice(index, 1);
        }

        /* End Query items */

        /* Query question */

        function addQuestion(questionObject) {
            questionObject.push({});
        }

        function deleteQuestion(questionObject, index) {
            questionObject.splice(index, 1);
        }

        /* End Query question */

        /* Query document item */

        function addItem(itemObject) {
            itemObject.push({});
        }

        function deleteItem(itemObject, index) {
            itemObject.splice(index, 1);
        }

        /* End Query document item */

        function init() {
            vm.loading++;

            doraemonSvc.getQueryForCreate({
                letterNumber: vm.guaranteeLetterNumber,
                serviceRequestId: vm.serviceRequestId,
            })
                .success(function (data) {
                    vm.doctors = data;
                }).finally(function () {
                    vm.loading--;
                });
        }

        /* End Functions */
    }
})();
