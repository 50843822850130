(function init() {
  angular.module('app').controller('host.views.debugTools.patchNewEmployeeMovement.modal', [
    '$uibModalInstance',
    'abp.services.app.debugTool',
    function controller($uibModalInstance, debugToolSvc) {
      const vm = this;
      vm.saving = 0;

      vm.cancel = cancel;
      vm.confirm = confirm;

      vm.input = { organizationId: null, employeeId: null };

      function cancel() {
        $uibModalInstance.dismiss();
      }

      function confirm() {
        vm.saving += 1;
        debugToolSvc
          .patchEmployeeMovementRecords(vm.input)
          .success(() => {
            abp.notify.info('Patching jobs enqueued');
          })
          .finally(() => {
            vm.saving -= 1;
          });
      }
    },
  ]);
})();
