(function () {
    var componentId = 'hostPanelRecruitmentCreateAccountByPanelRequestComponent';
    var app = angular.module('app');

    function controller($state, $uibModal, panelRequestSvc) {
        function init() {
            checkIfWelcomeEmailTemplateExist(vm.panelRequest.panelType);
        }

        function checkIfWelcomeEmailTemplateExist(panelType) {
            if (!panelType) return;

            vm.loading++;
            panelRequestSvc.isPanelWelcomeEmailTemplatesReady({
                panelType: vm.panelRequest.panelType,
                countryCode: vm.panelRequest.countryCode
            }).success(function (data) {
                vm.hasTemplate = data;
            }).finally(function (d) {
                vm.loading--;
            });
        }

        var vm = this;

        vm.loading = 0;
        vm.selectedPanel = null;

        vm.permissions = {
            viewEmailTemplates: abp.auth.isGranted('Host.CustomEmailTemplates')
        };

        vm.$onChanges = function (changes) {
            if (vm.panelRequest) {
                checkIfWelcomeEmailTemplateExist(vm.panelRequest.panelType);
            }
        };

        vm.mode = 0; // 0 = none; 1 = panel; 2 = location;
        vm.setMode = function (mode) {
            if (mode === vm.mode) mode = 0; // Reset if same mode.

            switch (mode) {
                case 1:
                    //vm.createPanel();
                    vm.mode = 1;
                    break;
                case 2:
                    vm.mode = 2;
                    break;
                case 0:
                default:
                    vm.mode = 0;
            }
        };

        vm.cancel = function () {
            vm.onCancel();
        };

        vm.createPanelLocation = function () {
            var clinicId = vm.selectedPanel;
            var clinicType = vm.panelRequest.panelType;

            $uibModal.open({
                templateUrl: require('/App/host/views/clinics/createOrEditLocationModal.html'),
                controller: 'host.views.clinics.createOrEditLocationModal as vm',
                backdrop: 'static',
                resolve: {
                    clinicId: function () {
                        return clinicId;
                    },
                    locationId: function () {
                        return null;
                    },
                    clinicType: function () {
                        return clinicType;
                    },
                    requestNumber: function () {
                        return vm.panelRequest.requestNumber;
                    }
                }
            }).result.then(function (result) {
                if (result && result.tenantId) {
                    $state.go('host.clinics.locations', { id: result.tenantId });
                } else {
                    vm.setMode(0);
                }
            });
        };

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/panelRecruitment/recruitmentTimeline/createAccountByPanelRequest/createAccountByPanelRequest.component.html'),
        controller: ['$state', '$uibModal', 'abp.services.app.panelRequest', controller],
        controllerAs: 'vm',
        bindings: {
            panelRequest: '<',
            panels: '<',
            onCancel: '&'
        }
    });
})();
