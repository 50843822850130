(() => {
  angular.module('app').component('manualTransactionSetupTaxCode', {
    templateUrl: require('./taxCode.component.html'),
    controller: ManualTransactionSetupTaxCodeController,
    controllerAs: 'vm',
    bindings: {
      accountOwnerId: '<',
      countryCode: '<',
    },
  });

  ManualTransactionSetupTaxCodeController.$inject = [
    '$uibModal',
    'financeConstants',
    'abp.services.finance.tax',
  ];

  function ManualTransactionSetupTaxCodeController($uibModal, financeConstants, taxSvc) {
    const vm = this;

    vm.loading = 0;

    vm.createEditTaxCode = createEditTaxCode;
    vm.deleteTaxCode = deleteTaxCode;

    vm.taxCodeGridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: financeConstants.grid.defaultPageSizes,
      paginationPageSize: financeConstants.grid.defaultPageSize,
      paginationCurrentPage: 1,
      useExternalPagination: true,
      useExternalSorting: true,
      columnDefs: [
        {
          name: App.localize('Actions'),
          displayName: 'Actions',
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'taxCodeActionTemplate',
          allowCellFocus: false,
        },
        {
          displayName: App.localize('TaxCode'),
          field: 'taxCode',
          minWidth: 120,
        },
        {
          displayName: App.localize('Percentage'),
          field: 'percentage',
          minWidth: 120,
        },
        {
          displayName: App.localize('Description'),
          field: 'description',
          minWidth: 120,
        },
      ],
      data: [],
    };

    init();

    function init() {
      getTaxCodes();
    }

    function getTaxCodes() {
      vm.loading += 1;
      taxSvc
        .getAccountOwnerTaxes({ accountOwnerId: vm.accountOwnerId })
        .success((data) => {
          vm.taxCodeGridOptions.data = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function createEditTaxCode(accountOwnerTaxId) {
      $uibModal
        .open({
          templateUrl: require('./createEditTaxCode.modal.html'),
          controller: 'manualTransactionSetupCreateEditTaxCode as vm',
          backdrop: 'static',
          resolve: {
            isCreate: () => accountOwnerTaxId == null,
            accountOwnerTaxId: () => accountOwnerTaxId,
            accountOwnerId: () => vm.accountOwnerId,
          },
        })
        .result.then(() => {
          // Refresh tax code listing.

          getTaxCodes();
        });
    }

    function deleteTaxCode(accountOwnerTaxId, accountOwnerTaxCode) {
      abp.message.confirm(
        App.localize('AccountOwnerTaxCodeDeleteWarningMessage', accountOwnerTaxCode),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            taxSvc
              .deleteAccountOwnerTax({ id: accountOwnerTaxId })
              .success(() => {
                abp.notify.success(App.localize('SuccessfullyDeleted'));
              })
              .finally(() => {
                vm.saving -= 1;
                getTaxCodes();
              });
          }
        }
      );
    }
  }
})();
