(() => {
  angular.module('app').component('identificationNumberComponent', {
    templateUrl: require('./identificationNumber.component.html'),
    controller: IdentificationNumberController,
    controllerAs: 'vm',
    bindings: {
      identificationType: '<',
      countryCode: '<',
      form: '<',
      fieldName: '<',
      employee: '='
    }
  });

  IdentificationNumberController.$inject = [
    '$scope',
    'abp.services.app.commonLookup',
    'Hms.Employees.IdentificationType',
    'Hms.Employees.Employee'
  ];

  function IdentificationNumberController(
    $scope,
    commonLookupSvc,
    enumIdentificationType,
    constsEmployee
  ) {
    const vm = this;
    vm.constsEmployee = constsEmployee;
    vm.enums = {};
    vm.enums.identificationType = enumIdentificationType;
    vm.hasIndonesiaAndThailandRegionalExpansion = abp.setting.getBoolean(
      'Hms.Feature.IndonesiaAndThailandRegionalExpansion'
    );
    vm.hasInsurerPassportFeatureFlag = abp.features.isEnabled('HasInsurerPassport');

    vm.validateIdentificationNumber = validateIdentificationNumber;

    function validateIdentificationNumber(identificationType, identificationNumber, modelName) {
      const isIdentificationNumber = identificationNumber || '';
      if (!isIdentificationNumber) {
        if ($scope.vm.form[modelName].$invalid) {
          $scope.vm.form[modelName].$setValidity('invalid', true);
        }
        return;
      }

      // MyKad regex validation

      let isValid = false;

      if (identificationType === 1) {
        isValid = /^\d{12}$/.test(identificationNumber);
      }

      // Army No. regex validation

      else if (identificationType === 2) {
        isValid = /^[T]\d{7}$/.test(identificationNumber);
      }

      // NRIC regex validation

      else if (identificationType === 4) {
        isValid = /^[ST]\d{7}[A-Z]$/.test(identificationNumber);
      }

      // FIN regex validation

      else if (identificationType === 5) {
        isValid = /^[FGM]\d{7}[A-Z]$/.test(identificationNumber);
      }

      // NIK regex validation

      else if (vm.hasIndonesiaAndThailandRegionalExpansion && identificationType === 6) {
        isValid = /^\d{16}$/.test(identificationNumber);
      }

      // TNIC regex validation

      else if (vm.hasIndonesiaAndThailandRegionalExpansion && identificationType === 7) {
        isValid = /^\d{13}$/.test(identificationNumber);
      }

      // Insurer membership id validation

      else if (identificationType === 8) {
        isValid = true;
      }

      $scope.vm.form[modelName].$setValidity('invalid', isValid);
      if (identificationType === 1 && isValid) detectBirthDateGender();
    }

    function detectBirthDateGender() {
      if (vm.employee && vm.employee.nationalityCode && vm.employee.nationalId) {
        commonLookupSvc.getNationalIdProperties({
          nationalityCode: vm.employee.nationalityCode,
          nationalId: vm.employee.nationalId
        }).success(d => {
          if (d.birthDate) vm.employee.birthDate = App.getLocalDate(d.birthDate);
          if (d.gender) vm.employee.gender = d.gender;
        });
      }
    }
  }
})();
