(() => {
  angular
    .module('app')
    .controller(
      'host.views.specialistTreatmentCategories.importResultModal',
      SpecialistTreatmentCategoryImportResultModalController
    );

  SpecialistTreatmentCategoryImportResultModalController.$inject = ['$uibModalInstance', 'errors'];

  function SpecialistTreatmentCategoryImportResultModalController($uibModalInstance, errors) {
    const vm = this;
    vm.errorCountText = App.localize('ImportFileHasXIssues', errors.length);
    vm.errorText = errors.join('\n');

    vm.cancel = cancel;

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
