(() => {
  angular.module('app').component('commonInpatientTicketsDetailProceduresComponent', {
    templateUrl: require('./procedures.component.html'),
    controller: ProceduresController,
    controllerAs: 'vm',
    bindings: {
      ticket: '<',
      doctors: '<',
      isMaternity: '<',
      isEdit: '<',
      isSaved: '<',
      categories: '<',
      isCreate: '<',
      currencyCode: '<'
    },
  });

  ProceduresController.$inject = ['$filter', 'moment'];

  function ProceduresController($filter, moment) {
    const vm = this;

    vm.procedureChargeDateRangeOptions = App.createDateRangePickerOptions();
    vm.$onChanges = onChanges;
    vm.addCategory = addCategory;
    vm.addProcedures = addProcedures;
    vm.clearProcedures = clearProcedures;
    vm.deleteItem = deleteItem;
    vm.updateProcedures = updateProcedures;
    vm.getCategoryName = getCategoryName;
    vm.getDoctorName = getDoctorName;
    vm.calculateTotal = calculateTotal;
    vm.updateDates = updateDates;

    init();
    function init() {
      vm.ticket.procedures = _.chain(vm.ticket.procedures)
        .groupBy('category')
        .map((value, key) => ({
          category: key,
          ticketItems: value,
          name: _.first(value).categoryName,
        }))
        .value();

      if (vm.ticket.procedures.length === 0) {
        if (vm.isEdit) addCategory();
      } else {
        _.each(vm.ticket.procedures, (x) => {
          _.each(x.ticketItems, (ticketItem) => {
            const ti = ticketItem;
            ti.totalCharges = ti.amount + ti.uncoveredAmount;
          });
        });
      }

      updateDates();
    }

    function onChanges(changes) {
      if (changes.ticket && changes.ticket.currentValue) {
        if (vm.ticket.procedures.length === 0) {
          if (vm.isEdit) addCategory();
        } else {
          _.each(vm.ticket.procedures, (x) => {
            _.each(x.ticketItems, (ticketItem) => {
              const ti = ticketItem;
              ti.totalCharges = ti.amount + ti.uncoveredAmount;
            });
          });
        }
      }

      if (changes.isSaved && changes.isSaved.currentValue) {
        tidyProcedures();
        updateDates();
      }

      if (changes.isEdit && changes.isEdit.currentValue) {
        if (vm.ticket && vm.ticket.procedures.length < 1) {
          const defaultType = vm.isMaternity ? '5' : '4';
          addCategory(defaultType);
        }
        updateDates();
      }
    }

    function addCategory(type) {
      const categoryItem = {
        category: type || null,
        ticketItems: [
          {
            category: type || null,
            categoryName: type ? getCategoryName(type) : null,
          },
        ],
      };
      vm.ticket.procedures.push(categoryItem);
      updateDates();
    }

    function addProcedures(record) {
      const r = record;
      if (typeof r.category === 'undefined' || r.category === null) return;

      if (!r.ticketItems) {
        r.ticketItems = [];
      }

      r.ticketItems.push({
        category: r.category,
        categoryName: getCategoryName(r.category),
      });
      updateDates();
    }

    function clearProcedures(category) {
      const c = category;
      c.ticketItems = [];
      addProcedures(c);
    }

    function tidyProcedures() {
      vm.ticket.procedures = _.filter(vm.ticket.procedures, (item) =>
        _.filter(item.ticketItems, (k) => !_.isNil(k.amount))
      );
    }

    function calculateTotal(index, item) {
      const i = item
      let uncoveredAmount = 0;
      let amount = 0;
      if (i[index].amount > 0) {
        amount = i[index].amount;
      }
      if (i[index].uncoveredAmount > 0) {
        uncoveredAmount = i[index].uncoveredAmount;
      }
      const totalCharges = amount + uncoveredAmount;
      i[index].totalCharges = totalCharges;
    }

    function updateDates() {
      if (vm.ticket && vm.ticket.procedures) {
        _.each(vm.ticket.procedures, (x) => {
          _.each(x.ticketItems, (ticketItem) => {
            const ti = ticketItem;
            if (!ti.procedureChargeDateRangeModel) {
              ti.procedureChargeDateRangeModel = {
                startDate: ti.startDate ? moment(ti.startDate) : null,
                endDate: ti.endDate ? moment(ti.endDate) : null,
              };
            }
          });
        });
      }
    }

    function updateProcedures(index, item) {
      if (typeof item.category === 'undefined' || item.category === null) {
        if (vm.ticket.procedures.length > 1) {
          vm.ticket.procedures.splice(index, 1);
        } else {
          vm.ticket.procedures = [];
          addCategory();
        }
      } else if (item.ticketItems) {
        _.each(item.ticketItems, (ticketItem) => {
          const ti = ticketItem;
          ti.category = item.category;
          ti.categoryName = getCategoryName(item.category);
        });
      }
    }

    function deleteItem(index, item) {
      const i = item;
      if (i.ticketItems.length > 1) {
        i.ticketItems.splice(index, 1);
      } else {
        i.ticketItems = [];
        addProcedures(i);
      }
    }

    function getDoctorName(id) {
      const idToMatch = Number(id);
      if (vm.doctors) {
        const selectedDoctor = _.find(vm.doctors, (d) => d.id === idToMatch);

        if (selectedDoctor) {
          return selectedDoctor.name;
        }
      }

      return null;
    }

    function getCategoryName(category) {
      const selectedCategory = _.find(vm.categories, { id: category });
      return selectedCategory ? selectedCategory.displayName : null;
    }
  }
})();
