(() => {
  angular
    .module('app')
    .controller(
      'common.views.signatureProfiles.personal.index',
      SignatureProfilePersonalController
    );

  SignatureProfilePersonalController.$inject = [
    'appSession',
    '$uibModal',
    'abp.services.app.signatureProfile',
  ];

  function SignatureProfilePersonalController(appSession, $modal, signatureProfileSvc) {
    const vm = this;

    vm.loading = 0;

    vm.createProfile = createProfile;
    vm.editProfile = editProfile;

    init();

    function init() {
      getSignatureProfiles();
    }

    function getSignatureProfiles() {
      vm.loading += 1;
      signatureProfileSvc
        .getPersonalSignatureProfiles()
        .success((data) => {
          vm.user = data;
          getPendingApprovalCount();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getPendingApprovalCount() {
      const count = vm.user.signatureProfiles.length;
      vm.canCreate = !count;
    }

    function createProfile() {
      showCreateEditModal();
    }

    function editProfile(profile) {
      showCreateEditModal(profile.id);
    }

    function showCreateEditModal(id) {
      $modal
        .open({
          templateUrl: require('../createOrEditModal.html'),
          controller: 'common.views.signatureProfiles.createOrEditModal as vm',
          backdrop: 'static',
          resolve: {
            profileId: id,
            userId: appSession.user.id,
          },
        })
        .result.then(() => {
          getSignatureProfiles();
        });
    }
  }
})();
