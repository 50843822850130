(function () {
    var componentId = 'clinicHealthScreeningDashboardSubmittedDetailCard';
    var app = angular.module('app');

    function controller($sce, moment) {
        function init() {
            // EMPTY.
        }
        var vm = this;

        vm.permissions = {
            viewGl: abp.auth.isGranted("GuaranteeLetters.HealthScreening.Panel"),
            viewClaim: abp.auth.isGranted("Claims.HealthScreening.Panel")
        };

        vm.getLocalisedSubmitDateTime = function (claim, datetime) {
            return $sce.trustAsHtml(App.localize('XSubmittedOnYSpecialistMessage', claim, moment(datetime).format('L hh:mm A')));
        }

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/clinic/views/healthScreening/dashboard/submitted/submittedDetailCard.component.html'),
        controller: ['$sce', 'moment', controller],
        controllerAs: 'vm',
        bindings: {
            patientName: '<',
            nationalId: '<',
            subsidiaryName: '<',
            claimNumber: '<',
            claimDate: '<',
            guaranteeLetterNumber: '<'
        }
    });
})();
