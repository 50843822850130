(() => {
  angular.module('app').component('commonInpatientTicketsDetailTicketInfoComponent', {
    templateUrl: require('./ticketInfo.component.html'),
    controller: TicketInfoController,
    controllerAs: 'vm',
    bindings: {
      ticket: '<',
      patient: '<',
      panel: '<',
      organization: '<',
      isClaim: '<',
      claimNo: '<',
      letterNumber: '<',
      hasInsurerClaim: '<',
      requestInfo: '<', // For processing FGL & adjustment purpose.
      coPay: '<',
      roomAndBoardCharges: '<',
      isTallied: '=?',
      checkFormFilled: '&?',
      totalCoveredAmount: '=?',
      isFromTicketView: '<',
      hospitalizationBenefits: '<',
      triggerEdit: '<',
      triggerCancel: '<',
      triggerSave: '<',
      onSave: '&',
      coPayTypeName: '<',
    },
  });

  TicketInfoController.$inject = [
    '$scope',
    '$state',
    '$filter',
    'abp.services.app.hostServiceRequest',
    'abp.services.app.inpatientPatientTicket',
    '$uibModal',
    'Hms.Employees.EmployeeState',
    'Hms.ServiceRequests.ServiceRequestType',
    'moment',
  ];

  function TicketInfoController(
    $scope,
    $state,
    $filter,
    hostServiceRequestSvc,
    inpatientPatientTicketSvc,
    $uibModal,
    enumEmployeeState,
    enumServiceRequestType,
    moment
  ) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;
    vm.isHost = App.isHost();
    vm.isCorporate = App.isCorporate();
    vm.filteredDoctors = [];
    vm.hasState = false;
    vm.isEdit = !!vm.isClaim;
    vm.hasCoPay = false;
    vm.isCreate = vm.isClaim;
    vm.roomCategories = [];
    vm.procedureCategories = [];
    vm.doctorProcedureCategories = [];
    vm.hospitalChargesCategories = [];
    vm.accommodationChargesTreatmentCategories = [];
    vm.inHospitalDoctorVisitTreatmentCategories = [];
    vm.doctorProcedureChargesTreatmentCategories = [];
    vm.hospitalChargesTreatmentCategories = [];
    vm.emergencyOutpatientTreatmentCategories = [];
    vm.miscellaneousTreatmentCategories = [];
    vm.otherCategories = [];
    vm.roomBenefits = [];
    vm.procedureBenefits = [];
    vm.doctorProcedureBenefits = [];
    vm.hospitalChargesBenefits = [];
    vm.fixedLimitBalance = 0;
    vm.newDocuments = [];
    vm.lengthOfStay = '';

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.hasSobEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');
    vm.hasMemberBenefitUtilizationEnabled = abp.setting.getBoolean(
      'Hms.Feature.MemberBenefitUtilization'
    );
    vm.hasTxtFileSupport = abp.setting.getBoolean('Hms.Feature.TxtFileSupport');
    vm.hasInpatientClaimProcessingEnhancement = abp.setting.getBoolean(
      'Hms.Feature.InpatientClaimProcessingEnhancement'
    );
    vm.hasInsuranceEnhancementModule = abp.setting.getBoolean(
      'Hms.Feature.InsuranceEnhancementModule'
    );
    const limitSize = 100;

    vm.enums = {
      employeeState: enumEmployeeState,
      serviceRequestType: enumServiceRequestType
    };
    vm.isCreateOrViewTicketV2 = fulfillV2ViewCondition();

    vm.$onChanges = onChanges;
    vm.getDependentDescription = getDependentDescription;
    vm.getDischargeDoctors = getDischargeDoctors;
    vm.lookupDoctor = lookupDoctor;
    vm.getAmount = getAmount;
    vm.getTotalBillAmount = getTotalBillAmount;
    vm.getCoveredAmount = getCoveredAmount;
    vm.getUncoveredAmount = getUncoveredAmount;
    vm.getCollectCash = getCollectCash;
    vm.getExceededAmount = getExceededAmount;
    vm.getCoPayAmount = getCoPayAmount;
    vm.getTotalCoveredAmount = getTotalCoveredAmount;
    vm.getEligibleClaimAmount = getEligibleClaimAmount;
    vm.getEntitledAmount = getEntitledAmount;
    vm.getTotalExceededAmount = getTotalExceededAmount;
    vm.getTotalPayableAmount = getTotalPayableAmount;
    vm.getTotalCollectCashAmount = getTotalCollectCashAmount;

    init();

    function init() {
      $scope.$on('validateProcessingInput', validateForm);

      // Only initialize when isEdit is true.

      if (vm.isEdit) {
        vm.isTallied = true;
      }

      $scope.$on('availableDischargeDoctorsChanged', (evt, data) => {
        vm.doctors = data;
        vm.filteredDoctors = angular.copy(vm.doctors);
      });

      $scope.$watch('vm.ticket.dischargeDate', () => {
        if (vm.ticket) {
          hasPublicHoliday();
          const daysDifference = App.getDaysDifference(
            moment(vm.ticket.admissionDate).format('YYYY-MM-DD HH:mm'),
            vm.ticket.dischargeDate
          );
          vm.lengthOfStay = App.localize('XDays', daysDifference);
        }
      });

      $scope.$watch('vm.ticket.admissionDate', () => {
        if (vm.ticket && vm.ticket.dischargeDate) {
          const daysDifference = App.getDaysDifference(
            moment(vm.ticket.admissionDate).format('YYYY-MM-DD HH:mm'),
            vm.ticket.dischargeDate
          );
          vm.lengthOfStay = App.localize('XDays', daysDifference);
        }
      });
    }

    function fulfillV2ViewCondition() {
      const ticketVersion = vm.ticket ? vm.ticket.version : vm.requestInfo.request.ticketVersion;

      const isCreate = vm.requestInfo ?
        vm.requestInfo.request.requestType !== vm.enums.serviceRequestType.Adjustment.name
        : vm.isCreate;

      return (vm.hasInsuranceEnhancementModule && (isCreate || ticketVersion === 'V2'));
    }

    function onChanges(changes) {
      vm.loading += 1;
      if (vm.ticket) {
        vm.doctors = vm.ticket.availableDischargeDoctors || [];
        vm.categories = vm.ticket.categories;
        vm.diseaseClassifications = vm.ticket.diseaseClassifications;
        vm.panelLocationDoctors = vm.ticket.panelLocationDoctors || [];
      }

      if (vm.requestInfo && changes.requestInfo) {
        vm.ticket = vm.requestInfo.request;
        vm.patient = vm.requestInfo.patient;
        vm.panel = vm.requestInfo.panel || {};
        vm.organization = vm.requestInfo.organization || {};
        vm.categories = vm.requestInfo.categories || [];
        vm.doctors = vm.requestInfo.request.availableDischargeDoctors || [];
        vm.panelLocationDoctors = vm.requestInfo.doctors || [];
        vm.filteredDoctors = angular.copy(vm.doctors);
        vm.diseaseClassifications = vm.requestInfo.diseaseClassifications || [];
        vm.diseaseClassifications = _.map(vm.diseaseClassifications, (d) => ({
          id: d.id,
          code: d.code,
          description: `(${d.code}) ${d.description}`,
        }));
      }

      vm.currencyCode = vm.ticket.currencyCode;

      vm.categories = _.map(vm.categories, (d) =>
        _.extend({}, d, {
          id: d.category,
        })
      );

      if (vm.hasSobEnhancement) {
        const accomodationItems = ['RoomAndBoard', 'IcuRoomAndBoard', 'ChildGuardianBenefit'];

        const doctorProcedureItems = [
          'SurgicalFee',
          'AnaesthetistFee',
          'OrganTransplant',
          'DaySurgery',
        ];

        const hospitalChargesItems = [
          'OperatingTheatre',
          'HospitalSuppliesAndServices',
          'AmbulanceFee',
          'MedicalReport',
        ];

        vm.roomCategories = _.filter(vm.categories, (x) => accomodationItems.includes(x.category));

        vm.procedureCategories = _.filter(
          vm.categories,
          (x) => x.category === 'InHospitalDoctorVisit'
        );

        vm.doctorProcedureCategories = _.filter(vm.categories, (x) =>
          doctorProcedureItems.includes(x.category)
        );

        vm.hospitalChargesCategories = _.filter(vm.categories, (x) =>
          hospitalChargesItems.includes(x.category)
        );

        // SOB benefits

        vm.roomBenefits = _.filter(vm.hospitalizationBenefits, (x) =>
          accomodationItems.includes(x.category)
        );

        vm.procedureBenefits = _.filter(
          vm.hospitalizationBenefits,
          (x) => x.category === 'InHospitalDoctorVisit'
        );

        vm.doctorProcedureBenefits = _.filter(vm.hospitalizationBenefits, (x) =>
          doctorProcedureItems.includes(x.category)
        );

        vm.hospitalChargesBenefits = _.filter(vm.hospitalizationBenefits, (x) =>
          hospitalChargesItems.includes(x.category)
        );
      } else {
        vm.roomCategories = _.filter(
          vm.categories,
          (x) => x.category === '1' || x.category === '2' || x.category === '3'
        );

        vm.procedureCategories = _.filter(vm.categories, (x) => x.category === '4');

        vm.otherCategories = _.filter(
          vm.categories,
          (x) =>
            x.category === '9' ||
            x.category === '10' ||
            x.category === '11' ||
            x.category === '12' ||
            x.category === '13'
        );
      }

      if (vm.hasInsuranceEnhancementModule) {
        if (vm.isFromTicketView) {
          vm.emergencyOutpatientTreatmentCategories =
            vm.ticket.emergencyOutpatientTreatmentCategories;

          vm.miscellaneousTreatmentCategories = vm.ticket.miscellaneousTreatmentCategories;

          vm.accommodationChargesTreatmentCategories =
            vm.ticket.accommodationChargesTreatmentCategories;

          vm.inHospitalDoctorVisitTreatmentCategories =
            vm.ticket.inHospitalDoctorVisitTreatmentCategories;

          vm.doctorProcedureChargesTreatmentCategories =
            vm.ticket.doctorProcedureChargesTreatmentCategories;

          vm.hospitalChargesTreatmentCategories = vm.ticket.hospitalChargesTreatmentCategories;
        } else {
          vm.emergencyOutpatientTreatmentCategories =
            vm.requestInfo.request.emergencyOutpatientTreatmentCategories;

          vm.miscellaneousTreatmentCategories =
            vm.requestInfo.request.miscellaneousTreatmentCategories;

          vm.accommodationChargesTreatmentCategories =
            vm.requestInfo.request.accommodationChargesTreatmentCategories;

          vm.inHospitalDoctorVisitTreatmentCategories =
            vm.requestInfo.request.inHospitalDoctorVisitTreatmentCategories;

          vm.doctorProcedureChargesTreatmentCategories =
            vm.requestInfo.request.doctorProcedureChargesTreatmentCategories;

          vm.hospitalChargesTreatmentCategories =
            vm.requestInfo.request.hospitalChargesTreatmentCategories;
        }
      }

      // Edit.
      if (vm.triggerEdit && changes.triggerEdit && changes.triggerEdit.currentValue) {
        backupInitialStates();
        vm.isEdit = true;
      }

      // Cancel edit.
      if (vm.triggerCancel && changes.triggerCancel && changes.triggerCancel.currentValue) {
        restoreInitialStates();
        vm.isEdit = false;
      }

      // Save edits.
      if (vm.triggerSave && changes.triggerSave && changes.triggerSave.currentValue) {
        save();
        vm.onSave({ isSave: false });
      }
      vm.loading -= 1;
    }

    function getDependentDescription() {
      return App.localize(
        'DependentDescription',
        vm.patient.principalName,
        $filter('nationalIdFilter')(vm.patient.principalNationalId)
      );
    }

    function hasPublicHoliday() {
      if (!vm.ticket.dischargeDate) {
        vm.ticket.dischargeDate = null;
      }

      vm.loading += 1;
      hostServiceRequestSvc
        .hasPublicHolidayAsync({
          letterNumber: vm.letterNumber,
          dischargeTime: vm.ticket.dischargeDate,
        })
        .success((data) => {
          vm.isInPublicHoliday = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function save() {
      if (App.isFormValid(vm.ticketEditForm)) {
        vm.loading += 1;
        let rooms = [];
        let procedures = [];
        const others = [];
        let doctorProcedures = [];
        let hospitalCharges = [];
        let emergencyOutpatientTreatments = [];
        let miscellaneousTreatments = [];

        _.each(vm.ticket.rooms, (x) => {
          if (!_.isNil(x.category)) {
            _.each(x.ticketItems, (ticketItem) => {
              const ti = ticketItem;
              if (!_.isNil(ti.category)) {
                ti.startDate = ti.roomChargeDateRangeModel.startDate.utc();
                ti.endDate = ti.roomChargeDateRangeModel.endDate.utc();
                rooms.push(ti);
              }
            });
          }
        });

        if (vm.hasSobEnhancement) {
          _.each(vm.ticket.procedures, (x) => {
            if (
              (!vm.hasTxtFileSupport && !_.isNil(x.doctorId)) ||
              (vm.hasTxtFileSupport && !_.isNil(x.doctorType))
            ) {
              _.each(x.ticketItems, (ticketItem) => {
                const ti = ticketItem;
                if (
                  (!vm.hasTxtFileSupport && !_.isNil(x.doctorId)) ||
                  (vm.hasTxtFileSupport && !_.isNil(x.doctorType))
                ) {
                  ti.startDate = ti.visitDate ? ti.visitDate.utc() : null;
                  ti.endDate = ti.visitDate ? ti.visitDate.utc() : null;

                  // Total visit is always 1.

                  if (!ti.totalVisit || ti.totalVisit !== 1) ti.totalVisit = 1;

                  procedures.push(ti);
                }
              });
            }
          });

          _.each(vm.ticket.doctorProcedures, (x) => {
            if (!_.isNil(x.category)) {
              _.each(x.ticketItems, (p) => {
                if (!_.isNil(p.category)) doctorProcedures.push(p);
              });
            }
          });

          _.each(vm.ticket.hospitalCharges, (x) => {
            if (!_.isNil(x.category)) {
              _.each(x.ticketItems, (p) => {
                if (!_.isNil(p.category)) hospitalCharges.push(p);
              });
            }
          });
        } else {
          _.each(vm.ticket.procedures, (x) => {
            if (!_.isNil(x.category)) {
              _.each(x.ticketItems, (ticketItem) => {
                const ti = ticketItem;
                if (!_.isNil(ti.category)) {
                  ti.startDate = ti.procedureChargeDateRangeModel.startDate.utc();
                  ti.endDate = ti.procedureChargeDateRangeModel.endDate.utc();
                  procedures.push(ti);
                }
              });
            }
          });

          _.each(vm.ticket.others, (x) => {
            if (!_.isNil(x.categoryName)) others.push(x);
          });
        }

        if (vm.hasInsuranceEnhancementModule && vm.ticket.version === 'V2') {
          rooms = formatTicketItems(vm.ticket.rooms, (x) => ({
            amount: x.amount,
            uncoveredAmount: x.uncoveredAmount,
            category: x.section,
            categoryName: x.category,
            subCategory: x.subTreatment,
            procedure: x.procedure,
            uncoveredProcedure: x.uncoveredProcedure,
            remark: x.remark,
            uncoveredRemark: x.uncoveredRemark,
            startDate: x.roomChargeDateRangeModel.startDate
              ? moment(x.roomChargeDateRangeModel.startDate).utc()
              : null,
            endDate: x.roomChargeDateRangeModel.endDate
              ? moment(x.roomChargeDateRangeModel.endDate).utc()
              : null,
            dailyCharges: x.dailyCharges,
            lengthOfStay: x.lengthOfStay,
          }));

          procedures = formatTicketItems(vm.ticket.procedures, (x) => ({
            amount: x.amount,
            uncoveredAmount: x.uncoveredAmount,
            category: x.section,
            categoryName: x.doctorType,
            subCategory: x.subTreatment,
            procedure: x.procedure,
            uncoveredProcedure: x.uncoveredProcedure,
            remark: x.remark,
            uncoveredRemark: x.uncoveredRemark,
            startDate: x.visitDate ? moment(x.visitDate).utc() : null,
            endDate: x.visitDate ? moment(x.visitDate).utc() : null,
            doctorId: x.doctorId,
            totalVisit: 1,
            visit: x.visit,
          }));

          doctorProcedures = formatTicketItems(vm.ticket.doctorProcedures, (x) => ({
            amount: x.amount,
            uncoveredAmount: x.uncoveredAmount,
            category: x.section,
            categoryName: x.category,
            subCategory: x.subTreatment,
            procedure: x.procedure,
            uncoveredProcedure: x.uncoveredProcedure,
            remark: x.remark,
            uncoveredRemark: x.uncoveredRemark,
            startDate: x.visitDate ? moment(x.visitDate).utc() : null,
            endDate: x.visitDate ? moment(x.visitDate).utc() : null,
            doctorId: x.doctorId,
          }));

          hospitalCharges = formatTicketItems(vm.ticket.hospitalCharges, (x) => ({
            amount: x.amount,
            uncoveredAmount: x.uncoveredAmount,
            category: x.section,
            categoryName: x.category,
            subCategory: x.subTreatment,
            procedure: x.procedure,
            uncoveredProcedure: x.uncoveredProcedure,
            remark: x.remark,
            uncoveredRemark: x.uncoveredRemark,
          }));

          emergencyOutpatientTreatments = formatTicketItems(
            vm.ticket.emergencyOutpatientTreatments,
            (x) => ({
              amount: x.amount,
              uncoveredAmount: x.uncoveredAmount,
              category: x.section,
              categoryName: x.mainTreatment,
              subCategory: x.subTreatment,
              procedure: x.procedure,
              uncoveredProcedure: x.uncoveredProcedure,
              remark: x.remark,
              uncoveredRemark: x.uncoveredRemark,
            })
          );

          miscellaneousTreatments = formatTicketItems(vm.ticket.miscellaneousTreatments, (x) => ({
            amount: x.amount,
            uncoveredAmount: x.uncoveredAmount,
            category: x.section,
            categoryName: x.mainTreatment,
            subCategory: x.subTreatment,
            procedure: x.procedure,
            uncoveredProcedure: x.uncoveredProcedure,
            remark: x.remark,
            uncoveredRemark: x.uncoveredRemark,
          }));
        }

        const input = vm.ticket;
        input.letterNumber = vm.letterNumber;
        input.rooms = rooms;
        input.procedures = procedures;

        if (vm.hasSobEnhancement) {
          input.doctorProcedures = doctorProcedures;
          input.hospitalCharges = hospitalCharges;
        } else {
          input.others = others;
        }

        if (vm.hasInsuranceEnhancementModule && vm.ticket.version === 'V2') {
          input.emergencyOutpatientTreatments = emergencyOutpatientTreatments;
          input.miscellaneousTreatments = miscellaneousTreatments;
        }

        input.documentsToUpload = _.map(vm.newDocuments, (doc) => ({
          externalObjectId: null,
          originalFileName: doc.originalFileName,
          fileName: doc.fileName,
        }));

        input.documentsMarkedForRemoval = _.reduce(
          vm.ticket.documents,
          (resultArray, document) => {
            if (document.isMarkedForRemoval)
              resultArray.push({ externalObjectId: document.externalObjectId });

            return resultArray;
          },
          []
        );

        if (input.diagnoses) {
          input.diagnoses = _.map(input.diagnoses, (s) =>
            // Free-text entry has null diagnosis code.

            ({
              id: s.id,
              code: _.isNumber(s.code) ? null : s.code,
              description: s.description,
              diagnosisDate: s.diagnosisDate,
            })
          );
        }

        inpatientPatientTicketSvc
          .updateTicket(input)
          .success(() => {
            vm.isEdit = false;
            vm.savedTrigger += 1;
            vm.ticket.rooms = _.chain(vm.ticket.rooms)
              .groupBy('category')
              .map((value, key) => ({
                category: key,
                ticketItems: value,
                name: _.first(value).categoryName,
              }))
              .value();

            if (vm.hasSobEnhancement) {
              vm.ticket.procedures = _.chain(vm.ticket.procedures)
                .groupBy('doctorId')
                .map((value, key) => ({
                  doctorId:
                    vm.ticket.ticketType !== 2 && !Number.isNaN(Number(key)) ? Number(key) : null,
                  doctorType: Number(value[0].doctorType) ? Number(value[0].doctorType) : null,
                  ticketItems: value,
                  doctorName: vm.ticket.ticketType !== 2 && key ? getDoctorName(Number(key)) : null,
                  isManualEdit: 0,
                }))
                .value();

              vm.ticket.doctorProcedures = _.chain(vm.ticket.doctorProcedures)
                .groupBy('category')
                .map((value, key) => ({
                  category: key,
                  ticketItems: value,
                  name: _.first(value).categoryName,
                }))
                .value();

              vm.ticket.hospitalCharges = _.chain(vm.ticket.hospitalCharges)
                .groupBy('category')
                .map((value, key) => ({
                  category: key,
                  ticketItems: value,
                  name: _.first(value).categoryName,
                }))
                .value();
            } else {
              vm.ticket.procedures = _.chain(vm.ticket.procedures)
                .groupBy('category')
                .map((value, key) => ({
                  category: key,
                  ticketItems: value,
                  name: _.first(value).categoryName,
                }))
                .value();
            }
            abp.notify.info(App.localize('SuccessfullySaved'));
            $state.reload();
            if (
              vm.hasMemberBenefitUtilizationEnabled &&
              vm.ticket.ticketType !== 1 &&
              vm.isHost &&
              !vm.hasInsurerClaim
            ) {
              showAdjustMemberUtilizationConfirmationModal(vm.ticket.ticketNumber);
            }
          })
          .finally(() => {
            vm.loading -= 1;
          });
      } else {
        abp.message.error(App.localize('OneOrMoreInputValueIsInvalid'));
      }
    }

    function validateForm() {
      if (!App.isFormValid(vm.ticketEditForm)) {
        vm.checkFormFilled({ data: false });
      } else {
        vm.checkFormFilled({ data: true });
      }
    }

    function backupInitialStates() {
      vm.preEditData = {
        preEditDoctor: angular.copy(vm.doctorName),
        preEditRoomName: angular.copy(vm.roomName),
        preEditProcedureName: angular.copy(vm.procedureName),
        preEditDiagnosis: angular.copy(vm.diagnoses),
        preEditRooms: angular.copy(vm.rooms),
        preEditProcedures: angular.copy(vm.procedures),
        preEditOthers: angular.copy(vm.others),
        preEditMC: angular.copy(vm.mc),
        preEditDocuments: angular.copy(vm.documents),
        preTicket: angular.copy(vm.ticket),
      };
    }

    function restoreInitialStates() {
      vm.doctorName = vm.preEditData.preEditDoctor;
      vm.roomName = vm.preEditData.preEditRoomName;
      vm.procedureName = vm.preEditData.preEditProcedureName;
      vm.diagnoses = vm.preEditData.preEditDiagnosis;
      vm.rooms = vm.preEditData.preEditRooms;
      vm.procedures = vm.preEditData.preEditProcedures;
      vm.others = vm.preEditData.preEditOthers;
      vm.mc = vm.preEditData.preEditMC;
      vm.documents = vm.preEditData.preEditDocuments;
      vm.ticket = vm.preEditData.preTicket;
    }

    function getDischargeDoctors() {
      vm.dischargeDoctors = [];
      _.forEach(vm.ticket.doctorIds, (id) => {
        vm.dischargeDoctors.push(getDoctorName(id));
      });
      return vm.dischargeDoctors.join(', ');
    }

    function lookupDoctor(filter) {
      const keyword = _.trim(filter || '');
      if (keyword) {
        const filteredDoctors = [];
        const regexes = _.chain(keyword.split(' '))
          .map((k) => _.trim(k.toUpperCase()))
          .filter((k) => k)
          .uniq()
          .map((k) => {
            _.escapeRegExp(k);
            return {
              token: k,
              len: k.length,
              pattern: new RegExp(`\\b${k}`, 'i'),
            };
          })
          .value();

        _.forEach(vm.doctors, (doctor) => {
          let score = 0;
          _.forEach(regexes, (r) => {
            if (r.pattern.test(doctor.name)) {
              score -= r.len;
            }
          });

          if (score) {
            filteredDoctors.push({
              score,
              item: doctor,
            });
          }
        });

        vm.filteredDoctors = _.chain(filteredDoctors)
          .sortBy(['score'])
          .take(30)
          .map((k) => k.item)
          .uniqBy((s) => s.name)
          .value();
      } else {
        vm.filteredDoctors = _.take(vm.doctors, limitSize);
      }
    }

    // Total calculation region (To be updated with more calculations)

    function getAmount() {
      let totalBillAmount = 0;
      let coveredAmount = 0;
      let uncoveredAmount = 0;
      let exceededAmount = 0;
      let finalBillAmount = 0;
      let coPayAmount = 0;
      let totalRoomAndBoardAmount = 0;
      let totalRoomAndBoardDuration = 0;
      let allowance = 0;
      let collectCashAmount = 0;
      let totalExcludedItemAmount = 0;
      const dailyRoomAndBoardAmounts = [];

      if (vm.ticket) {
        finalBillAmount = vm.ticket.finalBillAmount
          ? vm.ticket.finalBillAmount
          : vm.ticket.adjustedFinalBillAmount;
        allowance = vm.ticket.allowance;
        if (!vm.roomAndBoardCharges) {
          vm.roomAndBoardCharges = vm.ticket.roomAndBoardAllowance;
        }

        if (!vm.diagnoses) {
          vm.diagnoses = vm.ticket.diagnoses;
        }

        if (!vm.coPay) {
          vm.coPay = {
            isRoomAndBoardExceedEntitlement: vm.ticket.isRoomAndBoardExceedEntitlement,
            isPercentage: vm.ticket.isCoPayPercentage,
            isCoInsurance: vm.ticket.isCoInsurance,
            coPayExclusions: vm.ticket.coPayExclusions,
            value: vm.ticket.coPayValue,
          };
        }

        _.each(vm.ticket.rooms, (x) => {
          _.each(x.ticketItems, (p) => {
            if (p.amount) {
              coveredAmount += p.amount;
              totalBillAmount += p.amount;

              if (vm.hasTxtFileSupport && _.some(vm.coPay.coPayExclusions, (c) => c === x.category))
                totalExcludedItemAmount += p.amount;
            }

            if (p.uncoveredAmount) {
              uncoveredAmount += p.uncoveredAmount;
              totalBillAmount += p.uncoveredAmount;
            }

            // For room and board only.
            if ((x.category === '1' || x.category === 'RoomAndBoard') && p.totalCharges) {
              const startDate = p.roomChargeDateRangeModel
                ? p.roomChargeDateRangeModel.startDate
                : p.startDate;
              const endDate = p.roomChargeDateRangeModel
                ? p.roomChargeDateRangeModel.endDate
                : p.endDate;

              const startDateMoment = moment(startDate);
              const endDateMoment = moment(endDate);

              totalRoomAndBoardAmount += p.totalCharges;
              totalRoomAndBoardDuration += endDateMoment.diff(startDateMoment, 'days') + 1;

              if (vm.hasTxtFileSupport) dailyRoomAndBoardAmounts.push(p.dailyCharges);
            }
          });
        });

        const updateAmount = (x) => {
          if (x.amount) {
            coveredAmount += x.amount;
            totalBillAmount += x.amount;

            if (vm.hasTxtFileSupport && _.some(vm.coPay.coPayExclusions, (c) => c === x.category))
              totalExcludedItemAmount += x.amount;
          }

          if (x.uncoveredAmount) {
            uncoveredAmount += x.uncoveredAmount;
            totalBillAmount += x.uncoveredAmount;
          }
        };

        const accumulateAmount = (itemContainer) => {
          _.forEach(itemContainer.ticketItems, updateAmount);
        };

        _.forEach(vm.ticket.procedures, accumulateAmount);

        if (vm.hasSobEnhancement) {
          _.forEach(vm.ticket.doctorProcedures, accumulateAmount);
          _.forEach(vm.ticket.hospitalCharges, accumulateAmount);
        } else {
          _.each(vm.ticket.others, updateAmount);
        }

        if (vm.hasInsuranceEnhancementModule) {
          _.forEach(vm.ticket.emergencyOutpatientTreatments, accumulateAmount);
          _.forEach(vm.ticket.miscellaneousTreatments, accumulateAmount);
        }
      }

      if (vm.hasTxtFileSupport)
        coPayAmount = calculateCoPayV2(
          uncoveredAmount,
          totalBillAmount,
          totalExcludedItemAmount,
          dailyRoomAndBoardAmounts
        );
      else
        coPayAmount = calculateCoPay(
          uncoveredAmount,
          totalBillAmount,
          totalRoomAndBoardAmount,
          totalRoomAndBoardDuration
        );

      coveredAmount -= coPayAmount;

      // Check against allowance

      if (vm.isClaim && coveredAmount > allowance) {
        exceededAmount = coveredAmount - allowance;
        coveredAmount = allowance;
      }

      // Check if ticket has excess amount

      if (vm.ticket && vm.ticket.exceededAmount) {
        exceededAmount = vm.ticket.exceededAmount;
        coveredAmount = totalBillAmount - uncoveredAmount - coPayAmount - exceededAmount;
      }

      collectCashAmount = uncoveredAmount + coPayAmount + exceededAmount;

      if (vm.isEdit)
        vm.isTallied = App.roundAmount(totalBillAmount) === App.roundAmount(finalBillAmount);

      return {
        totalBillAmount: App.roundAmount(totalBillAmount),
        coveredAmount: App.roundAmount(coveredAmount),
        uncoveredAmount: App.roundAmount(uncoveredAmount),
        collectCash: App.roundAmount(collectCashAmount),
        coPayAmount: App.roundAmount(coPayAmount),
        exceededAmount: App.roundAmount(exceededAmount),
      };
    }

    function getTotalBillAmount() {
      return vm.getAmount().totalBillAmount > 0 ? vm.getAmount().totalBillAmount : 0;
    }

    function getCoveredAmount() {
      vm.totalCoveredAmount = vm.getAmount().coveredAmount > 0 ? vm.getAmount().coveredAmount : 0;
      return vm.totalCoveredAmount;
    }

    function getUncoveredAmount() {
      return vm.getAmount().uncoveredAmount > 0 ? vm.getAmount().uncoveredAmount : 0;
    }

    function getCollectCash() {
      return vm.getAmount().collectCash > 0 ? vm.getAmount().collectCash : 0;
    }

    function getCoPayAmount() {
      return vm.getAmount().coPayAmount > 0 ? vm.getAmount().coPayAmount : 0;
    }

    function getExceededAmount() {
      return vm.getAmount().exceededAmount > 0 ? vm.getAmount().exceededAmount : 0;
    }

    function getDoctorName(id) {
      if (vm.doctors) {
        const selectedDoctor = _.find(vm.doctors, (d) => d.id === id);

        if (selectedDoctor) {
          return selectedDoctor.name;
        }
      }

      return null;
    }

    function calculateCoPayV2(
      uncoveredAmount,
      billAmount,
      totalExcludedItemAmount,
      dailyRoomAndBoardAmounts
    ) {
      if (!vm.coPay || vm.coPay.value === 0) {
        return 0;
      }

      const coveredAmount = billAmount - uncoveredAmount;
      const costShareableAmount = coveredAmount - totalExcludedItemAmount;
      const hasExceededEntitlement = _.some(
        dailyRoomAndBoardAmounts,
        (d) => d > vm.roomAndBoardCharges
      );

      if (vm.coPay.isPercentage) {
        if (vm.coPay.isRoomAndBoardExceedEntitlement)
          return hasExceededEntitlement ? costShareableAmount * (vm.coPay.value / 100) : 0;
        return costShareableAmount * (vm.coPay.value / 100);
      }
      return costShareableAmount > 0 ? vm.coPay.value : 0;
    }

    function calculateCoPay(
      uncoveredAmount,
      billAmount,
      totalRoomAndBoardAmount,
      roomAndBoardDuration
    ) {
      if (!vm.coPay || vm.coPay.value === 0) {
        return 0;
      }

      let totalRoomAndBoardAllowance = 0;
      let exceededRoomAndBoardAmount = 0;

      if (vm.roomAndBoardCharges > 0 && roomAndBoardDuration > 0) {
        totalRoomAndBoardAllowance = vm.roomAndBoardCharges * roomAndBoardDuration;
      }

      if (totalRoomAndBoardAmount > 0) {
        exceededRoomAndBoardAmount = totalRoomAndBoardAmount - totalRoomAndBoardAllowance;
      }

      const coveredAmount = billAmount - uncoveredAmount;

      if (vm.coPay.isPercentage && vm.coPay.isCoInsurance) {
        return coveredAmount * (vm.coPay.value / 100);
      }
      if (vm.coPay.isPercentage && !vm.coPay.isCoInsurance) {
        return exceededRoomAndBoardAmount > 0
          ? (coveredAmount - totalRoomAndBoardAllowance) * (vm.coPay.value / 100)
          : 0;
      }
      const copayableAmount = vm.coPay.isCoInsurance
        ? coveredAmount
        : coveredAmount - totalRoomAndBoardAllowance;

      return copayableAmount > 0 ? vm.coPay.value : 0;
    }

    function showAdjustMemberUtilizationConfirmationModal(ticketNumber) {
      $uibModal.open({
        templateUrl: require('../../../memberUtilizations/adjustMemberUtilizationConfirmationModal.html'),
        controller:
          'common.views.memberUtilizations.adjustMemberUtilizationConfirmationModal as vm',
        backdrop: 'static',
        size: 'sm',
        resolve: {
          ticketNumber() {
            return ticketNumber;
          },
        },
      });
    }

    function formatTicketItems(treatments, format) {
      const items = [];
      _.each(treatments, (x) => {
        _.each(x.ticketItems, (y) => {
          if (!y.mainTreatment && !y.category && !y.doctorType) return;

          const ticketItem = angular.copy(y);
          items.push(format(ticketItem));
        });
      });

      return items;
    }

    function getTotalCoveredAmount() {
      return getTotalBillAmount() - getUncoveredAmount();
    }

    function getEligibleClaimAmount() {
      return getTotalCoveredAmount() - getCoPayAmount();
    }

    function getEntitledAmount() {
      if (!vm.isCreate) return vm.ticket.coveredAmount;

      const { allowance } = vm.ticket;
      const eligibleClaimAmount = getEligibleClaimAmount();

      return Math.min(eligibleClaimAmount, allowance);
    }

    function getTotalExceededAmount() {
      return getEligibleClaimAmount() - getEntitledAmount();
    }
    function getTotalPayableAmount() {
      return getEligibleClaimAmount() - getTotalExceededAmount();
    }
    function getTotalCollectCashAmount() {
      return getUncoveredAmount() + getCoPayAmount() + getTotalExceededAmount();
    }
  }
})();
