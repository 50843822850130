(() => {
    angular.module('finance').component('manualInvoiceDetailComponent', {
      templateUrl: require('./manual.component.html'),
      controller: ManualInvoiceDetailComponent,
      controllerAs: 'vm',
      bindings: {
        currencyCode: '<',
        invoice: '<',
        invoiceFrom: '<',
        invoiceTo: '<',
        isAddressInvoiceFrom: '<',
        isAddressInvoiceTo: '<',
        message: '<',
        getDateString: '<'
      },
    });
  
    ManualInvoiceDetailComponent.$inject = [];
  
    function ManualInvoiceDetailComponent() {
      const vm = this;
    }
  })();
  