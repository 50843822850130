(function () {
    var componentId = 'hostOperationsDashboardRecentFrequentCheckInsDetail';
    var app = angular.module('app');

    function controller($state) {
        var vm = this;
        vm.employee = vm.resolve.employee;

        vm.close = function () {
            vm.modalInstance.dismiss();
        };
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/operationsDashboard/recentFrequentCheckIns/detailModal.html'),
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['$state', controller],
        controllerAs: 'vm'
    });
})();
