import * as XLSX from 'xlsx';
import jspreadsheet from 'jspreadsheet-ce';
import { PhoneNumberUtil } from 'google-libphonenumber';

(() => {
  angular
    .module('app')
    .controller('corporate.views.employees.importModal', CorporateEmployeeImportModal);

  CorporateEmployeeImportModal.$inject = [
    '$scope',
    '$uibModal',
    '$uibModalInstance',
    'FileUploader',
    'abp.services.app.employee',
    'countryCode',
    'Hms.Employees.Employee',
    'Hms.SubsidiaryDepartments.SubsidiaryDepartment',
    'Hms.BenefitGroups.BenefitGroup',
    '$rootScope',
    'Hms.Employees.IdentificationType',
  ];

  function CorporateEmployeeImportModal(
    $scope,
    $modal,
    $uibModalInstance,
    FileUploader,
    employeeSvc,
    countryCode,
    hmsEmployeesEmployee,
    hmsSubsidiaryDepartmentsSubsidiaryDepartment,
    hmsBenefitGroupsBenefitGroup,
    $root,
    hmsEmployeesIdentificationType
  ) {
    const vm = this;

    vm.reportName = 'EmployeeImportListing';
    vm.generateButtonType = App.localize('ImportEmployeesSteps1Narrative');
    vm.saving = 0;
    vm.columnDefinitionsShown = false; // Remove during feature flag removal.
    vm.enqueuing = 0;
    vm.countryCode = countryCode;
    vm.hasInpatientModule = abp.features.isEnabled('HasInpatientModule');
    vm.hasEmployeeBulkUpdateUiEnhancement = abp.setting.getBoolean(
      'Hms.Feature.EmployeeBulkUpdateUiEnhancement'
    );
    vm.hasIndonesiaAndThailandRegionalExpansion = abp.setting.getBoolean(
      'Hms.Feature.IndonesiaAndThailandRegionalExpansion'
    );
    vm.hasInsurerPassportFeatureFlag = abp.features.isEnabled('HasInsurerPassport');
    vm.yesNo = [
      { id: 'Yes', name: 'Yes' },
      { id: 'No', name: 'No' },
    ];
    vm.isFirstOpen = true;
    vm.isSecondOpen = false;
    vm.isThirdOpen = false;
    vm.isOnLoadExcel = true;
    $scope.isWorkbookOpen = false;
    vm.frontendErrorLog = new Map();
    vm.backendErrorLog = new Map();
    vm.arrayErrorLog = null;
    vm.uploading = 0;

    const name = 1;
    const nationality = 2;
    const identificationType = 3;
    const identificationNumber = 4;
    const dateOfBirth = 5;
    const gender = 6;
    const email = 7;
    const mobileNumber = 8;
    const type = 9;
    const mainPrincipal = 10;
    const relationship = 11;
    const subsidiary = 12;
    const department = 13;
    const employeeNumber = 14;
    const joinDate = 15;
    const employeeStatus = 16;
    const scheduledTerminationDate = 17;
    const scheduledSuspensionDate = 18;
    const outpatientBenefitGroup = 19;
    const outpatientEntitlement = 20;
    const outpatientFullEntitlementDate = 21;
    const hospitalisationBenefitGroup = 22;
    const maternityBenefitGroup = 23;
    const medicalLeaveLimitWithInpatient = 24;
    const hospitalisationLeaveLimit = 25;
    const mobileApp = 26;
    const dataConsent = 27;
    const phoneUtil = PhoneNumberUtil.getInstance();
    const supportedCountryCodes = ['MY', 'SG', 'ID', 'TH'];

    vm.save = save;
    vm.verify = verify;
    vm.cancel = cancel;
    vm.exportToExcel = exportToExcel;
    vm.showMoreError = showMoreError;
    vm.sheet = sheet;
    vm.validate = validate;
    vm.idTypeFilter = idTypeFilter;

    vm.uploaderImport = new FileUploader({
      url: $root.resolveAppPath('Employee/ImportEmployees'),
      queueLimit: 1,
      headers: App.getFileUploaderHeaders(),
      filters: [
        {
          name: 'excelFilter',
          fn(n) {
            const t = `|${n.type.slice(n.type.lastIndexOf('/') + 1)}|`;
            if (
              '|vnd.openxmlformats-officedocument.spreadsheetml.sheet|'.indexOf(t) === -1 &&
              !n.name.endsWith('.xlsx')
            ) {
              abp.message.warn(App.localize('Excel_Warn_FileType'));
              return false;
            }
            return true;
          },
        },
      ],
    });

    vm.uploaderValidate = new FileUploader({
      url: $root.resolveAppPath('Employee/ValidateEmployees'),
      queueLimit: 1,
      headers: App.getFileUploaderHeaders(),
    });

    vm.uploaderImport.onCompleteItem = uploaderImportOnCompleteItem;
    vm.uploaderValidate.onCompleteItem = uploaderValidateOnCompleteItem;

    init();

    function init() {
      getDropdownValue();
    }

    function save() {
      vm.saving += 1;
      if (vm.hasEmployeeBulkUpdateUiEnhancement) {
        vm.uploading += 1;
        if (vm.arrayErrorLog.length === 0) {
          const file = createNewFile();
          vm.uploaderImport.clearQueue();
          vm.uploaderImport.addToQueue(file);
          vm.uploaderImport.uploadAll();
        } else {
          abp.notify.error(App.localize('OccurringInportalExcelErrorMessage'));
          vm.uploading -= 1;
          vm.saving -= 1;
        }
      } else {
        vm.uploaderImport.uploadAll();
      }
    }

    function verify() {
      vm.uploading += 1;
      const frontendErrorLogs = Array.from(vm.frontendErrorLog);
      if (frontendErrorLogs.length === 0) {
        const file = createNewFile();
        vm.uploaderValidate.clearQueue();
        vm.uploaderValidate.addToQueue(file);
        vm.uploaderValidate.uploadAll();
      } else {
        abp.notify.error(App.localize('OccurringInportalExcelErrorMessage'));
        vm.uploading -= 1;
      }
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function exportToExcel() {
      vm.enqueuing += 1;
      const input = {
        isImportEmployees: true,
      };
      employeeSvc
        .enqueueGenerateEmployeeExcelListingJob(input)
        .success(() => {
          vm.refresh();
        })
        .finally(() => {
          vm.enqueuing -= 1;
        });
    }

    function showMoreError() {
      $modal.open({
        templateUrl: require('../../../common/views/modals/importResult/importResult.modal.html'),
        controller: 'common.views.modals.importResult as vm',
        windowClass: 'double-modal',
        backdrop: 'static',
        resolve: {
          title() {
            return App.localize('ImportEmployeesErrors');
          },
          errors() {
            const error = new Array(undefined);
            for (let i = 0; i < vm.arrayErrorLog.length; i += 1) {
              error.push(vm.arrayErrorLog[i][1]);
            }
            return error;
          },
        },
      });

      const modal = angular.element(document.getElementsByClassName('modal-backdrop'));
      modal.addClass('double-modal-backdrop');
    }

    function sheet(workbooks) {
      const employeeSheet = workbooks.Sheets[workbooks.SheetNames[1]];
      const data = XLSX.utils.sheet_to_json(employeeSheet, { raw: false });

      // Checking and preprocessing to add custom extended employee columns.

      const extendedColumns = [];
      const dataHeaders = XLSX.utils.sheet_to_json(employeeSheet, { raw: false, header: 1 })[0];
      for (let i = 0; i < dataHeaders.length; i += 1) {
        if (dataHeaders[i].substring(0, 3) === 'EF|') {
          extendedColumns.push({
            type: 'text',
            name: dataHeaders[i],
            title: dataHeaders[i],
            width: 120,
          });
        }
      }

      // Preprocessing Nationality and Identification type data.

      for (let i = 0; i < data.length; i += 1) {
        // Preprocess certain columns to capitalize drop down values.

        const capitalizeInputColumn = [
          'Nationality',
          'Gender',
          'Type',
          'Relationship',
          'Employee status',
          'Outpatient entitlement',
        ];
        capitalizeInputColumn.forEach((column) => {
          if (_.has(data[i], column)) {
            if (column === 'Nationality') {
              data[i][column] = _.trim(data[i][column]).toUpperCase();
            } else {
              data[i][column] = _.capitalize(_.trim(data[i][column]));
            }
          }
        });

        // Convert ISO alpha 2 country code to their fullname.

        vm.country.some((k) => {
          if (
            _.has(data[i], 'Nationality') &&
            data[i].Nationality.length === 2 &&
            k.code === data[i].Nationality.toUpperCase()
          ) {
            data[i].Nationality = k.id;
            return true;
          }
          return false;
        });

        // Clear id type value if the nationality does not have that specified id. E.g. MY
        // only validates type 1 & 2.

        if (_.has(data[i], 'Identification type') && _.has(data[i], 'Nationality')) {
          let myCode = null;
          const currentNationality = data[i].Nationality;
          myCode = populateIdentificationTypesByNationality(currentNationality);
          const ids = _.map(myCode, 'id');
          if (!_.includes(ids, data[i]['Identification type'])) {
            data[i]['Identification type'] = '';
          }
        }
      }

      // Prevent multiple workbook to be rendered on the inportal excel. Choosing another
      // file will replace previous (if any) rendered excel.

      if ($scope.isWorkbookOpen) {
        document.getElementById('spreadsheet').replaceChildren();
        vm.isOnLoadExcel = true;
        vm.frontendErrorLog = new Map();
        vm.backendErrorLog = new Map();
      }

      vm.jsSheet = jspreadsheet(document.getElementById('spreadsheet'), {
        data,
        search: true,
        pagination: 10,
        paginationOptions: [10, 20, 100],
        allowDeleteColumn: false,
        allowDeleteRow: true,
        allowInsertColumn: false,
        allowInsertRow: true,
        allowRenameColumn: false,
        allowComments: false,
        columnSorting: false,
        columns: getSpreadsheetColumns(extendedColumns),
        onload(instance, _cell, x, y) {
          $scope.$apply(() => {
            vm.uploading += 1;
          });
          vm.validate(instance, null, x, y, null);
        },
        onchange(instance, _cell, x, y) {
          $scope.$apply(() => {
            vm.uploading += 1;
          });
          vm.validate(instance, null, x, y, null);
        },
        oninsertrow(_instance, row, _numOfRow, _array, insertBefore) {
          let newRow = row;
          if (!insertBefore) {
            newRow = row + 1;
          }
          $scope.$apply(() => {
            vm.uploading += 1;
          });
          vm.validate(null, null, null, newRow, null);
        },
        ondeleterow() {
          $scope.$apply(() => {
            vm.uploading += 1;
          });
          updateErrorLog();
        },
      });

      $scope.isWorkbookOpen = true;
    }

    function validate(instance, _cell, x, y) {
      // Clear ID type value when Nationality is changed for dropdown value conditional formatting.

      if (parseInt(x, 10) === 2) {
        instance.jexcel.setValueFromCoords(parseInt(x, 10) + 1, parseInt(y, 10), '');
      }

      // Configurable: Validation is done every 0.5sec.

      setTimeout(() => {
        const columns = vm.jsSheet.getHeaders(true);

        if (vm.isOnLoadExcel) {
          // Validate through the whole file on first load.

          const skip = [
            mainPrincipal,
            relationship,
            subsidiary,
            department,
            employeeNumber,
            outpatientBenefitGroup,
            outpatientEntitlement,
            mobileApp,
            dataConsent,
          ];
          let skipCounter = 0;
          for (let col = 1; col < columns.length; col += 1) {
            if (skip[skipCounter] === col) {
              skipCounter += 1;
            } else {
              const colData = vm.jsSheet.getColumnData(col);
              for (let row = 0; row < colData.length; row += 1) {
                validateCell(col, row);
              }
            }
          }
          vm.isOnLoadExcel = false;
        } else {
          // Only validate the row where the cell was changed to improve performance.

          for (let col = 1; col <= columns.length; col += 1) {
            validateCell(col, parseInt(y, 10));
          }
        }

        updateErrorLog();
      });
    }

    function idTypeFilter(instance, _cell, c, r) {
      const value = instance.jexcel.getValueFromCoords(c - 1, r);

      return populateIdentificationTypesByNationality(value);
    }

    function populateIdentificationTypesByNationality(employeeNationality) {
      /*
       * Show the identification type when any of the following conditions is fulfilled:
       *
       * - If the identification type is not tied to any specific country and the current employee
       *   is a foreigner (e.g. a Japanese in a Malaysian-owned company or a Malaysian in a
       *   Singaporean-owned company)
       *
       * - If the identification type is tied to a specific country,
       *        - If the identification type is for citizen only and the current employee is a
       *          citizen of the specific country (e.g. a Malaysian in a Malaysian-owned company
       *          or a Singaporean in a Singaporean-owned company)
       *
       *        - If the identification type is not for citizen and the current employee is a
       *          foreigner (e.g. a Korean who is issued a long-term pass holders and not a
       *          permanent resident by the Singapore government and in a Singaporean-owned company)
       *
       * - If the identification type is tied to a specific country and is for permanent resident
       *   and the current employee is a foreigner (e.g. an Malaysian who is a permanent resident
       *   of Singapore and in a Singaporean-owned company)
       */

      if (vm.hasInsurerPassportFeatureFlag) {
        return _.filter(
          vm.idType,
          (idType) =>
            (idType.id === hmsEmployeesIdentificationType.Passport.id.toString() ||
             idType.id === hmsEmployeesIdentificationType.InsurerMembershipId.id.toString()) ||
            (idType.code === vm.countryCode && (
              (idType.isCitizen && idType.nationality.toUpperCase() === employeeNationality) ||
              (!idType.isCitizen && idType.nationality.toUpperCase() !== employeeNationality)
            )) ||
            (idType.isPermanentResident &&
             idType.code === vm.countryCode &&
             idType.nationality.toUpperCase() !== employeeNationality)
        );
      }

      // default filter if hasInsurerPassportFeatureFlag is false.

      return _.filter(
        vm.idType,
        (idType) =>
          (idType.code === '' && employeeNationality !== vm.organizationCountry) ||
          (idType.code === vm.countryCode && (
            (idType.isCitizen && idType.nationality.toUpperCase() === employeeNationality) ||
            (!idType.isCitizen && idType.nationality.toUpperCase() !== employeeNationality)
          )) ||
          (idType.isPermanentResident &&
            idType.code === vm.countryCode &&
            idType.nationality.toUpperCase() !== employeeNationality)
      );
    }

    function updateErrorLog() {
      // Recreate a new frontend error log after either changing values, delete and/or insert row.

      vm.frontendErrorLog = new Map();
      const columns = vm.jsSheet.getHeaders(true);

      const data = vm.jsSheet.getData();
      for (let row = 1; row <= data.length; row += 1) {
        for (let col = 1; col < columns.length; col += 1) {
          const cellNumber = convertNumberToLetter(col) + row;
          if (vm.jsSheet.getComments(cellNumber) !== '') {
            vm.frontendErrorLog.set(row, `Error in row ${row}.`);
            break;
          }
        }
      }

      getErrors();
    }

    function convertNumberToLetter(num) {
      const ordA = 'A'.charCodeAt(0);
      const ordZ = 'Z'.charCodeAt(0);
      const len = ordZ - ordA + 1;

      let s = '';
      let val = num;
      while (val >= 0) {
        s = String.fromCharCode((val % len) + ordA) + s;
        val = Math.floor(val / len) - 1;
      }
      return s;
    }

    function validateCell(col, row) {
      const colData = vm.jsSheet.getColumnData(col);
      let cellNumber = '';
      let errorMesg = '';
      switch (col) {
        case name:
          cellNumber = `B${row + 1}`;
          validateName(colData[row], cellNumber);
          break;
        case nationality:
          cellNumber = `C${row + 1}`;
          errorMesg = App.localize('InportalExcelNationalityError');
          validateDropdown(vm.country, colData[row], cellNumber, errorMesg);
          break;
        case identificationType:
          cellNumber = `D${row + 1}`;
          errorMesg = App.localize('InportalExcelIdTypeError');
          validateDropdown(vm.idType, colData[row], cellNumber, errorMesg);
          break;
        case identificationNumber:
          cellNumber = `E${row + 1}`;
          validateIdNumber(colData[row], row, cellNumber);
          break;
        case dateOfBirth:
          cellNumber = `F${row + 1}`;
          validateDate(colData[row], cellNumber, true);
          break;
        case gender:
          cellNumber = `G${row + 1}`;
          errorMesg = App.localize('InportalExcelGenderError');
          validateDropdown(vm.gender, colData[row], cellNumber, errorMesg);
          break;
        case email:
          cellNumber = `H${row + 1}`;
          validateEmail(colData[row], cellNumber);
          break;
        case mobileNumber:
          cellNumber = `I${row + 1}`;
          validatePhoneNumber(colData[row], cellNumber);
          break;
        case type:
          cellNumber = `J${row + 1}`;
          validateType(vm.employeeType, colData[row], row, cellNumber);
          break;
        case mainPrincipal:
          break;
        case relationship:
          break;
        case subsidiary:
          break;
        case department:
          break;
        case employeeNumber:
          break;
        case joinDate:
          cellNumber = `P${row + 1}`;
          validateDate(colData[row], cellNumber, false);
          break;
        case employeeStatus:
          cellNumber = `Q${row + 1}`;
          errorMesg = App.localize('InportalExcelEmployeeStatusError');
          validateDropdown(vm.employeeStatus, colData[row], cellNumber, errorMesg);
          break;
        case scheduledTerminationDate:
          cellNumber = `R${row + 1}`;
          validateDate(colData[row], cellNumber, false);
          break;
        case scheduledSuspensionDate:
          cellNumber = `S${row + 1}`;
          validateDate(colData[row], cellNumber, false, true);
          break;
        case outpatientBenefitGroup:
          break;
        case outpatientEntitlement:
          break;
        case outpatientFullEntitlementDate:
          cellNumber = `V${row + 1}`;
          validateDate(colData[row], cellNumber, false, true);
          break;
        case hospitalisationBenefitGroup:
          cellNumber = `W${row + 1}`;
          if (vm.hasInpatientModule) {
            errorMesg = App.localize('InportalExcelPreemployeeError');
            validatePreEmpBenefitGroup(colData[row], row, cellNumber, errorMesg);
          } else {
            errorMesg = App.localize('InportalExcelMcLimitError');
            validateNumericOnly(colData[row], cellNumber, errorMesg);
          }
          break;
        case maternityBenefitGroup:
          if (vm.hasInpatientModule) {
            cellNumber = `X${row + 1}`;
            errorMesg = App.localize('InportalExcelPreemployeeError');
            validatePreEmpBenefitGroup(colData[row], row, cellNumber, errorMesg);
          }
          break;
        case medicalLeaveLimitWithInpatient:
          if (vm.hasInpatientModule) {
            cellNumber = `Y${row + 1}`;
            errorMesg = App.localize('InportalExcelMcLimitError');
            validateNumericOnly(colData[row], cellNumber, errorMesg);
          }
          break;
        case hospitalisationLeaveLimit:
          if (vm.hasInpatientModule) {
            cellNumber = `Z${row + 1}`;
            errorMesg = App.localize('InportalExcelHospitalisationError');
            validateNumericOnly(colData[row], cellNumber, errorMesg);
          }
          break;
        default:
      }

      // #region In-Portal Excel Frontend Validation.

      function validateName(nameVal, cellPos) {
        const cleanName = nameVal.replace(/[^a-zA-Z0-9]/g, '');
        const normalizedName = cleanName.toUpperCase();

        if (normalizedName === '' || normalizedName.length > hmsEmployeesEmployee.MaxNameLength) {
          errorMarker(false, cellPos, App.localize('InportalExcelNameError'));
        } else {
          errorMarker(true, cellPos, '');
        }
      }

      function validateType(dropdownList, cellVal, rowVal, cellPos) {
        validateDropdown(
          dropdownList,
          cellVal,
          cellPos,
          App.localize('InportalExcelEmployeeTypeError')
        );

        switch (cellVal) {
          case 'Employee':
          case 'PreEmployee': {
            errorMarker(true, `K${rowVal + 1}`, '');
            errorMarker(true, `L${rowVal + 1}`, '');

            const identificationNumberColData = vm.jsSheet.getColumnData(4);
            const subsidiaryColData = vm.jsSheet.getColumnData(12);
            const departmentColData = vm.jsSheet.getColumnData(13);
            const employeeNumberColData = vm.jsSheet.getColumnData(14);
            const benefitGroupColData = vm.jsSheet.getColumnData(19);
            const entitlementModeColData = vm.jsSheet.getColumnData(20);

            const identificationNumberData = identificationNumberColData[rowVal].replace(
              /[^a-zA-Z0-9]/g,
              ''
            );

            if (
              identificationNumberColData[rowVal] === '' ||
              identificationNumberData.length > hmsEmployeesEmployee.MaxNationalIdLength
            ) {
              errorMarker(false, `E${rowVal + 1}`, App.localize('InportalExcelNationalIdError'));
            } else {
              validateIdNumber(identificationNumberColData[rowVal], rowVal, `E${rowVal + 1}`);
            }
            if (
              subsidiaryColData[rowVal] === '' ||
              subsidiaryColData[rowVal].length >
                hmsSubsidiaryDepartmentsSubsidiaryDepartment.MaxNameLength
            ) {
              errorMarker(false, `M${rowVal + 1}`, App.localize('InportalExcelSubsidiaryError'));
            } else {
              errorMarker(true, `M${rowVal + 1}`, '');
            }
            if (
              departmentColData[rowVal] !== '' &&
              departmentColData[rowVal].length >
                hmsSubsidiaryDepartmentsSubsidiaryDepartment.MaxNameLength
            ) {
              errorMarker(false, `N${rowVal + 1}`, App.localize('InportalExcelDepartmentError'));
            } else {
              errorMarker(true, `N${rowVal + 1}`, '');
            }
            if (
              employeeNumberColData[rowVal] !== '' &&
              employeeNumberColData[rowVal].length > hmsEmployeesEmployee.MaxEmployeeNumberLength
            ) {
              errorMarker(
                false,
                `O${rowVal + 1}`,
                App.localize('InportalExcelEmployeeNumberError')
              );
            } else {
              errorMarker(true, `O${rowVal + 1}`, '');
            }
            if (
              benefitGroupColData[rowVal] === '' ||
              benefitGroupColData[rowVal].length > hmsBenefitGroupsBenefitGroup.MaxNameLength
            ) {
              errorMarker(false, `T${rowVal + 1}`, App.localize('InportalExcelBenefitGroupError'));
            } else {
              errorMarker(true, `T${rowVal + 1}`, '');
            }

            const isEntitled = vm.entitlement.some((k) => k.id === entitlementModeColData[rowVal]);

            if (!isEntitled) {
              errorMarker(false, `U${rowVal + 1}`, App.localize('InportalExcelEntitlementError'));
            } else {
              errorMarker(true, `U${rowVal + 1}`, '');
            }
            break;
          }

          case 'Dependant': {
            errorMarker(true, `M${rowVal + 1}`, '');
            errorMarker(true, `N${rowVal + 1}`, '');
            errorMarker(true, `O${rowVal + 1}`, '');
            errorMarker(true, `T${rowVal + 1}`, '');
            errorMarker(true, `U${rowVal + 1}`, '');

            const mainPrincipalColData = vm.jsSheet.getColumnData(10);
            const relationshipColData = vm.jsSheet.getColumnData(11);

            if (
              mainPrincipalColData[rowVal] === '' ||
              mainPrincipalColData[rowVal].length > hmsEmployeesEmployee.MaxNameLength
            ) {
              errorMarker(false, `K${rowVal + 1}`, App.localize('InportalExcelMainPrincipalError'));
            } else {
              errorMarker(true, `K${rowVal + 1}`, '');
            }

            validateDropdown(
              vm.relationship,
              relationshipColData[rowVal],
              `L${rowVal + 1}`,
              App.localize('InportalExcelRelationshipError')
            );
            break;
          }

          case '':
            errorMarker(true, `K${rowVal + 1}`, '');
            errorMarker(true, `L${rowVal + 1}`, '');
            errorMarker(true, `M${rowVal + 1}`, '');
            errorMarker(true, `N${rowVal + 1}`, '');
            errorMarker(true, `O${rowVal + 1}`, '');
            errorMarker(true, `T${rowVal + 1}`, '');
            errorMarker(true, `U${rowVal + 1}`, '');
            break;
          default:
        }
      }

      function validateDropdown(dropdownList, cellVal, cellPos, errorMesgVal) {
        const isValid = dropdownList.some((k) => k.id === cellVal);

        errorMarker(isValid, cellPos, errorMesgVal);
      }

      function validateIdNumber(idNumber, rowVal, cellPos) {
        let isValid = false;
        const identificationTypeColData = vm.jsSheet.getColumnData(3);
        const identificationTypeData = identificationTypeColData[rowVal];

        if (idNumber !== '') {
          let errorMsg = App.localize('InportalExcelIdTypeMissingError');

          switch (identificationTypeData) {
            case '1': // MY MyKad regex validation.
              isValid = /^(\s+)?\d{6}-?\d{2}-?\d{4}(\s+)?$/.test(idNumber);
              errorMsg = App.localize('InportalExcelIdFormatError');
              break;
            case '2': // MY Army No. regex validation.
              isValid = /^(\s+)?T\d{7}(\s+)?$/.test(idNumber);
              errorMsg = App.localize('InportalExcelIdFormatError');
              break;
            case '3': // Passport. (No validation required).
              isValid = true;
              break;
            case '4': // SG NRIC regex validation.
              isValid = /^(\s+)?[ST]\d{7}[A-Z](\s+)?$/.test(idNumber);
              errorMsg = App.localize('InportalExcelSGIdFormatError');
              break;
            case '5': // SG FIN regex validation.
              isValid = /^(\s+)?[FGM]\d{7}[A-Z](\s+)?$/.test(idNumber);
              errorMsg = App.localize('InportalExcelSGIdFormatError');
              break;
            case '6': // ID NIK regex validation.
              if (vm.hasIndonesiaAndThailandRegionalExpansion) {
                isValid = /^(\s+)?\d{16}(\s+)?$/.test(idNumber);
                errorMsg = App.localize('InportalExcelIDIdFormatError');
              }
              break;
            case '7': // TH TNIC regex validation.
              if (vm.hasIndonesiaAndThailandRegionalExpansion) {
                isValid = /^(\s+)?\d{13}(\s+)?$/.test(idNumber);
                errorMsg = App.localize('InportalExcelTHIdFormatError');
              }
              break;
            case '8': // Insurer membership id validation.
              if (vm.hasInsurerPassportFeatureFlag) {
                isValid = true;
                errorMsg = "";
              }
              break;
            default:
              break;
          }
          errorMarker(isValid, cellPos, errorMsg);
        } else {
          errorMarker(false, cellPos, App.localize('InportalExcelIdNumberMissingError'));
        }
      }

      function validateDate(date, cellPos, mandatory, futureOnly = false) {
        if (mandatory) {
          if (Number.isNaN(Date.parse(date))) {
            errorMarker(false, cellPos, App.localize('InportalExcelDateError'));
          } else {
            errorMarker(true, cellPos, '');
          }
        } else {
          errorMarker(true, cellPos, '');
        }

        if (futureOnly && Date.parse(date) <= Date.now()) {
          errorMarker(false, cellPos, App.localize('InportalExcelBackdatedDateError'));
        }
      }

      function validateEmail(emailVal, cellPos) {
        if (emailVal !== '') {
          const emails = emailVal.split(',');
          if (emails.length !== 1) {
            errorMarker(false, cellPos, App.localize('InportalExcelMultipleEmailError'));
          } else if (
            !/^(\s+)?(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)(\s+)?|(\s+)?(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])(\s+)?|(\s+)?(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(\s+)?$/.test(
              emails[0]
            ) ||
            emails[0].length > hmsEmployeesEmployee.MaxEmailAddressLength
          ) {
            errorMarker(false, cellPos, App.localize('InportalExcelEmailFormatError'));
          } else {
            errorMarker(true, cellPos, '');
          }
        } else {
          errorMarker(true, cellPos, '');
        }
      }

      function validatePhoneNumber(cellVal, cellPos) {
        if (cellVal === '') {
          errorMarker(true, cellPos, '');
          return;
        }

        try {
          const parsedPhoneNumber = phoneUtil.parse(cellVal);
          const phoneNumberCountryCode = phoneUtil.getRegionCodeForNumber(parsedPhoneNumber);

          if (!supportedCountryCodes.includes(phoneNumberCountryCode)) {
            errorMarker(false, cellPos, App.localize('MobileNumberIsNotSupportedForCountry'));
            return;
          }

          if (!phoneUtil.isValidNumber(parsedPhoneNumber)) {
            errorMarker(false, cellPos, App.localize('InportalExcelPhoneNumberFormatError'));
            return;
          }

          errorMarker(true, cellPos, '');
        } catch (ex) {
          const message = getUserFriendlyErrorMessage(ex.message);
          errorMarker(false, cellPos, message);
        }
      }

      function getUserFriendlyErrorMessage(errMsg) {
        switch (errMsg) {
          case 'Invalid country calling code':
            return App.localize('InportalExcelPhoneNumberFormatError');
          default:
            return errMsg;
        }
      }

      function validateNumericOnly(cellVal, cellPos, errorMesgVal) {
        if (cellVal !== '') {
          if (!/\D+/.test(cellVal)) {
            errorMarker(true, cellPos, '');
          } else {
            errorMarker(false, cellPos, errorMesgVal);
          }
        } else {
          errorMarker(true, cellPos, '');
        }
      }

      function validatePreEmpBenefitGroup(cellVal, rowVal, cellPos, errorMesgVal) {
        const typeColData = vm.jsSheet.getColumnData(9);
        if (cellVal !== '' && typeColData[rowVal] === 'PreEmployee') {
          errorMarker(false, cellPos, errorMesgVal);
        } else {
          errorMarker(true, cellPos, '');
        }
      }

      function errorMarker(isValid, cellNumberVal, errorMesgVal) {
        if (isValid) {
          vm.jsSheet.setComments(cellNumberVal, '');
        } else {
          vm.jsSheet.setComments(cellNumberVal, errorMesgVal);
        }
      }

      // #endregion
    }

    function createNewFile() {
      const data = vm.jsSheet.getData();
      const headers = vm.jsSheet.getHeaders(true);
      const newData = [headers].concat(data);
      const wb = XLSX.utils.book_new();
      wb.SheetNames.push('ColumnDefinition');
      wb.SheetNames.push('Employees');
      wb.Sheets.Employees = XLSX.utils.aoa_to_sheet(newData);

      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

      function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);

        // Intentional use of bitwise AND to generate array buffer.
        // eslint-disable-next-line no-bitwise
        for (let i = 0; i < s.length; i += 1) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }

      const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
      return new File([blob], 'employeeImport.xlsx', {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
    }

    function getDropdownValue() {
      vm.loading += 1;
      employeeSvc
        .getEmployeeUploadDropdownValues()
        .success((data) => {
          vm.country = _.map(data.countries, (t) => ({
            id: t.id.toUpperCase(),
            code: t.code,
            name: t.name.toUpperCase(),
          }));
          vm.idType = data.identificationTypes;
          vm.gender = data.genders;
          vm.employeeType = data.employeeTypes;
          vm.employeeStatus = data.employeeStates;
          vm.relationship = data.relationshipNameList;
          vm.entitlement = data.employeeEntitlementModes;
          vm.organizationCountry = _.find(vm.country, (k) => k.code === vm.countryCode).id;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getErrors() {
      const backendErrorLogs = Array.from(vm.backendErrorLog);
      const frontendErrorLogs = Array.from(vm.frontendErrorLog);
      vm.arrayErrorLog = backendErrorLogs.concat(frontendErrorLogs);
      vm.uploading -= 1;
    }

    function getSpreadsheetColumns(extendedColumns) {
      let columns = [
        { type: 'hidden', name: '#', title: '#', width: 20 },
        { type: 'text', name: 'Name', title: 'Name', width: 120 },
        {
          type: 'dropdown',
          name: 'Nationality',
          title: 'Nationality',
          width: 120,
          source: vm.country,
        },
        {
          type: 'dropdown',
          name: 'Identification type',
          title: 'Identification type',
          width: 80,
          source: vm.idType,
          filter: vm.idTypeFilter,
        },
        { type: 'text', name: 'Identification number', title: 'Identification number', width: 120 },
        {
          type: 'calendar',
          name: 'Date of birth (yyyy-MM-dd)',
          title: 'Date of birth (yyyy-MM-dd)',
          options: { format: 'yyyy-MM-dd' },
          width: 120,
        },
        { type: 'dropdown', name: 'Gender', title: 'Gender', width: 120, source: vm.gender },
        { type: 'text', name: 'Email address', title: 'Email address', width: 120 },
        { type: 'text', name: 'Mobile number', title: 'Mobile number', width: 120 },
        { type: 'dropdown', name: 'Type', title: 'Type', width: 120, source: vm.employeeType },
        { type: 'text', name: 'Main principal', title: 'Main principal', width: 120 },
        {
          type: 'dropdown',
          name: 'Relationship',
          title: 'Relationship',
          width: 120,
          source: vm.relationship,
        },
        { type: 'text', name: 'Subsidiary', title: 'Subsidiary', width: 120 },
        { type: 'text', name: 'Department', title: 'Department', width: 120 },
        { type: 'text', name: 'Employee number', title: 'Employee number', width: 120 },
        {
          type: 'calendar',
          name: 'Join date (yyyy-MM-dd)',
          title: 'Join date (yyyy-MM-dd)',
          options: { format: 'yyyy-MM-dd' },
          width: 120,
        },
        {
          type: 'dropdown',
          name: 'Employee status',
          title: 'Employee status',
          width: 120,
          source: vm.employeeStatus,
        },
        {
          type: 'calendar',
          name: 'Scheduled termination date (yyyy-MM-dd)',
          title: 'Scheduled termination date (yyyy-MM-dd)',
          options: { format: 'yyyy-MM-dd' },
          width: 120,
        },
        {
          type: 'calendar',
          name: 'Scheduled suspension date (yyyy-MM-dd)',
          title: 'Scheduled suspension date (yyyy-MM-dd)',
          options: { format: 'yyyy-MM-dd' },
          width: 120,
        },
        {
          type: 'text',
          name: 'Outpatient benefit group',
          title: 'Outpatient benefit group',
          width: 120,
        },
        {
          type: 'dropdown',
          name: 'Outpatient entitlement',
          title: 'Outpatient entitlement',
          width: 120,
          source: vm.entitlement,
        },
        {
          type: 'calendar',
          name: 'Outpatient full entitlement date (yyyy-MM-dd)',
          title: 'Outpatient full entitlement date (yyyy-MM-dd)',
          options: { format: 'yyyy-MM-dd' },
          width: 120,
        },
      ];

      if (vm.hasInpatientModule) {
        columns = columns.concat([
          {
            type: 'text',
            name: 'Hospitalisation benefit group',
            title: 'Hospitalisation benefit group',
            width: 120,
          },
          {
            type: 'text',
            name: 'Maternity benefit group',
            title: 'Maternity benefit group',
            width: 120,
          },
          {
            type: 'numeric',
            name: 'Medical leave limit',
            title: 'Medical leave limit',
            width: 120,
          },
          {
            type: 'numeric',
            name: 'Hospitalisation leave limit',
            title: 'Hospitalisation leave limit',
            width: 120,
          },
          {
            type: 'dropdown',
            name: 'Mobile app',
            title: 'Mobile app',
            width: 120,
            source: vm.yesNo,
          },
          {
            type: 'dropdown',
            name: 'Data consent',
            title: 'Data consent',
            width: 120,
            source: vm.yesNo,
          },
        ]);
      } else {
        columns = columns.concat([
          {
            type: 'numeric',
            name: 'Medical leave limit',
            title: 'Medical leave limit',
            width: 120,
          },
          {
            type: 'dropdown',
            name: 'Mobile app',
            title: 'Mobile app',
            width: 120,
            source: vm.yesNo,
          },
          {
            type: 'dropdown',
            name: 'Data consent',
            title: 'Data consent',
            width: 120,
            source: vm.yesNo,
          },
        ]);
      }

      columns = columns.concat(extendedColumns);
      return columns;
    }

    function uploaderImportOnCompleteItem(n, t) {
      if (vm.hasEmployeeBulkUpdateUiEnhancement) {
        vm.uploaderValidate.onCompleteItem(n, t);
        if (!t.success && t.error) {
          if (t.error.details) {
            vm.saving -= 1;
            abp.notify.error(App.localize('ImportEmployeesFailed'));
          }
        }
      } else if (!t.success && t.error) {
        vm.saving -= 1;

        if (t.error.details) {
          $modal.open({
            templateUrl: require('../../../common/views/modals/importResult/importResult.modal.html'),
            controller: 'common.views.modals.importResult as vm',
            backdrop: 'static',
            resolve: {
              title() {
                return App.localize('ImportEmployees');
              },
              errors() {
                return JSON.parse(t.error.details);
              },
            },
          });
          $uibModalInstance.dismiss();
        }

        if (t.error.message) {
          abp.message.error(t.error.message);
          $uibModalInstance.dismiss();
        }
      }

      if (t.success) {
        vm.saving -= 1;
        abp.notify.info(App.localize('ImportEmployeesSuccessful'));
        $uibModalInstance.close();
      }
    }

    function uploaderValidateOnCompleteItem(n, t) {
      vm.backendErrorLog = new Map();

      if (!t.success && t.error) {
        if (t.error.details) {
          abp.notify.error(App.localize('VerifyEmployeesFailed'));

          const backendValidatorError = JSON.parse(t.error.details);
          for (let i = 0; i < backendValidatorError.length; i += 1) {
            vm.backendErrorLog.set(`a${i}`, backendValidatorError[i]);
          }

          getErrors();
        }

        // To catch any missed out error. E.g db update error, etc.

        if (t.error.message && !t.error.details) {
          abp.message.error(t.error.message);
        }
      }

      if (t.success) {
        abp.notify.info(App.localize('VerifyEmployeesSucceed'));

        // Clear off errors from error logs.

        getErrors();
      }
    }
  }
})();
