(() => {
  angular.module('app').component('commonGuaranteeLettersDetailsRecentGuaranteeLettersWidget', {
    templateUrl: require('./widget.html'),
    controller: RecentGuaranteeLettersWidgetController,
    controllerAs: 'vm',
    bindings: {},
  });

  RecentGuaranteeLettersWidgetController.$inject = [
    '$stateParams',
    'abp.services.app.guaranteeLetter',
    'Hms.GuaranteeLetters.GuaranteeLetterStatus',
  ];

  function RecentGuaranteeLettersWidgetController($stateParams, guaranteeLetterSvc, enumGuaranteeLetterStatus) {
    const vm = this;

    vm.loading = 0;
    vm.letters = [];

    vm.enums = {
      guaranteeLetterStatus: enumGuaranteeLetterStatus,
    };

    vm.loadMore = loadMore;
    vm.getDateTimeString = getDateTimeString;

    init();

    function init() {
      getRecentGuaranteeLetters(0);
    }

    function getRecentGuaranteeLetters(skipCount) {
      vm.loading += 1;
      guaranteeLetterSvc
        .getRecentGuaranteeLetters({
          letterNumber: $stateParams.letterNumber,
          skipCount,
          maxResultCount: 10,
        })
        .success((data) => {
          vm.totalCount = data.totalCount;
          vm.letters = vm.letters.concat(data.items);
          _.each(vm.letters, (letter) => {
            const l = letter;
            l.disciplineNames = _.join(
              _.map(l.disciplines, (d) => d.name),
              ', '
            );
          });
          vm.hasMoreLetters = data.totalCount > vm.letters.length;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function loadMore() {
      getRecentGuaranteeLetters(vm.letters.length);
    }

    function getDateTimeString(date, timeZoneId) {
      return App.getDateTimeString(date, timeZoneId);
    }
  }
})();
