import swal from 'sweetalert';

(() => {
  angular.module('app').controller('common.views.checkInReport.index', CheckInReportPageController);

  CheckInReportPageController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$uibModal',
    '$timeout',
    'abp.services.app.report',
    'appSession',
    'abp.services.app.clinicEmployee',
    'abp.services.app.commonLookup',
    'abp.services.app.panelDashboard',
    'moment',
    'appConstants',
  ];

  function CheckInReportPageController(
    $scope,
    $state,
    $stateParams,
    $uibModal,
    $timeout,
    reportSvc,
    appSession,
    clinicEmployeeSvc,
    commonLookupSvc,
    panelDashboardSvc,
    moment,
    appConstants
  ) {
    const vm = this;

    vm.isHost = App.isHost();
    vm.isClinic = App.isClinic();
    vm.isSpecialist = vm.isClinic && App.isSpecialist();
    vm.isHospital = App.isHospital();
    vm.loading = 0;
    vm.enqueuing = 0;
    vm.advancedFiltersAreShown = false;
    vm.isInpatient = App.isInpatient();
    vm.isGP = App.isGPPanel();
    vm.dataExportRecordName = 'CheckInReport';
    vm.generateButtonType = App.localize('ExportToExcel');

    vm.getExpiryDesc = getExpiryDesc;
    vm.isActiveCheckIn = isActiveCheckIn;
    vm.enterKeyTriggerGenerateReport = enterKeyTriggerGenerateReport;
    vm.cancelCheckIn = cancelCheckIn;
    vm.createTicket = createTicket;
    vm.export = exportReport;
    vm.getCheckInReport = getCheckInReport;
    vm.dismiss = dismiss;
    vm.requestLateSubmission = requestLateSubmission;
    vm.getDateTimeString = getDateTimeString;

    vm.permissions = {
      canCreateTicket: abp.auth.isGranted('PatientTickets.Clinic.Create'),
      patients: abp.auth.isGranted('Clinic.Employees'),
    };

    vm.requestParams = {
      filter: $stateParams.filter,
      skipCount: $stateParams.skipCount,
      maxResultCount: $stateParams.maxResultCount || appConstants.grid.defaultPageSize,
      sorting: $stateParams.sorting,
      clinicLocationId: $stateParams.clinicLocationId,
      status: $stateParams.status,
    };

    vm.dateRangeOptions = App.createDateRangePickerOptions();
    vm.dateRangeModel = {
      startDate: $stateParams.startDate
        ? moment($stateParams.startDate)
        : moment().startOf('day').subtract(14, 'days'),
      endDate: $stateParams.endDate ? moment($stateParams.endDate) : moment().startOf('day'),
    };

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSizes,
      paginationPageSize: appConstants.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      columnDefs: [
        {
          name: 'Actions',
          enableSorting: false,
          width: 100,
          visible: vm.isClinic,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
        },
        {
          displayName: App.localize('Type'),
          field: 'clinicType',
          visible: !vm.isClinic,
          width: 50,
          cellTemplate: 'typeTemplate',
        },
        {
          displayName: App.localize('CheckedIn'),
          field: 'checkedInTime',
          cellTemplate: 'checkedInTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('CheckedOut'),
          field: 'checkedOutTime',
          cellTemplate: 'checkedOutTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('ClaimSubmissionTime'),
          field: 'claimSubmissionTime',
          visible: vm.isHost || vm.isSpecialist,
          cellTemplate: 'claimSubmissionTimeTemplate',
          enableSorting: false,
          minWidth: 100,
        },
        {
          displayName: App.localize('ClaimNumber'),
          field: 'claimNumber',
          visible: vm.isHost || vm.isSpecialist,
          enableSorting: false,
          minWidth: 100,
        },
        {
          displayName: App.localize('Patient'),
          field: 'patient',
          minWidth: 100,
        },
        {
          displayName: App.localize('Principal'),
          field: 'principal',
          enableSorting: false,
          minWidth: 100,
        },
        {
          displayName: vm.isClinic
            ? App.localize('PanelClaimNumber')
            : App.localize('TicketNumber'),
          field: 'ticketNumber',
          visible: !vm.isSpecialist,
          minWidth: 100,
          cellTemplate: 'ticketNumberTemplate',
        },
        {
          displayName:
            vm.isHost || vm.isClinic ? App.localize('Corporate') : App.localize('Subsidiary'),
          field: 'corporate',
          visible: !appSession.user.subsidiaryId,
          minWidth: 100,
        },
        {
          displayName: App.localize('Department'),
          field: 'department',
          visible: !appSession.user.subsidiaryId && !vm.isHost && !vm.isClinic,
          minWidth: 100,
        },
        {
          displayName: App.localize('EmployeeStatus'),
          field: 'isActive',
          enableSorting: false,
          visible: !vm.isClinic,
          minWidth: 100,
          cellTemplate: 'employeeStatusTemplate',
        },
        {
          displayName: App.localize('PanelLocation'),
          field: 'clinic',
          minWidth: 100,
        },
        {
          displayName: App.localize('ContactNumber'),
          field: 'contactNumber',
          minWidth: 100,
        },
        {
          displayName: App.localize('Remarks'),
          field: 'cancellationRemarks',
          enableSorting: false,
          minWidth: 100,
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          vm.requestParams.sorting = '';

          if (callback.length) {
            let count = 0;
            for (let i = 0; i < callback.length; i += 1) {
              if (callback[i].field) {
                if (count > 0) vm.requestParams.sorting += ', ';

                vm.requestParams.sorting += `${callback[i].field} ${callback[i].sort.direction}`;
                count += 1;
              }
            }
          }
          vm.getCheckInReport();
        });

        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, vm.getCheckInReport)
        );
      },
      data: [],
    };

    init();

    function init() {
      findClinicLocations();
      $timeout(() => {
        vm.gridOptions.paginationCurrentPage = Math.floor(
          vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1
        );
      });
    }

    function findClinicLocations() {
      commonLookupSvc
        .findClinicLocations({
          skipCount: 0,
          maxResultCount: 100,
        })
        .success((data) => {
          vm.locations = data.items;
          getCheckInReport();
        });
    }

    function getExpiryDesc(checkIn) {
      return moment().to(checkIn.checkedOutTime);
    }

    function isActiveCheckIn(checkIn) {
      return checkIn.checkedOutTime > moment().format();
    }

    function formatDateRangeModel() {
      return {
        startDate: vm.dateRangeModel.startDate
          ? vm.dateRangeModel.startDate.format('YYYY-MM-DD HH:mm:ss Z')
          : null,
        endDate: vm.dateRangeModel.endDate
          ? vm.dateRangeModel.endDate.startOf('day').format('YYYY-MM-DD HH:mm:ss Z')
          : null,
      };
    }

    function registerStateParams() {
      const dateRangeModel = formatDateRangeModel();

      $state.transitionTo(
        $state.current,
        {
          filter: vm.requestParams.filter,
          startDate: dateRangeModel.startDate,
          endDate: dateRangeModel.endDate,
          skipCount: vm.requestParams.skipCount,
          maxResultCount: vm.requestParams.maxResultCount,
          sorting: vm.requestParams.sorting,
          clinicLocationId: vm.requestParams.clinicLocationId,
          status: vm.requestParams.status,
        },
        {
          notify: false,
        }
      );
    }

    let reportTemplateName = '';
    let reportFields = [];
    function getCheckInReport() {
      registerStateParams();
      vm.loading += 1;
      reportSvc
        .getCheckInReport(getRequestInput())
        .success((data) => {
          vm.gridOptions.totalItems = data.totalCount;
          vm.gridOptions.data = data.items;
          reportTemplateName = data.reportTemplateName;
          reportFields = data.reportFields;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getRequestInput(selectedFields) {
      const input = $.extend({}, vm.requestParams, formatDateRangeModel(), {
        reportFields: selectedFields,
      });
      return _.omitBy(input, _.isNil);
    }

    function exportToExcel(option) {
      const params = option || getRequestInput();

      vm.enqueuing += 1;
      reportSvc
        .enqueueGenerateCheckInExcelReportJob(params)
        .success(() => {
          vm.refresh();
        })
        .finally(() => {
          vm.enqueuing -= 1;
        });
    }

    function exportReport(type) {
      $uibModal
        .open({
          templateUrl: require('../common/selectionModal.html'),
          controller: 'common.views.common.selectionModal as vm',
          backdrop: 'static',
          resolve: {
            templateName() {
              return reportTemplateName;
            },
            fields() {
              return reportFields;
            },
            modalTitle() {
              return App.localize('SelectReportFields');
            },
          },
        })
        .result.then((selectedFields) => {
          const option = getRequestInput(selectedFields);

          if (type === 0) window.open(App.formatUrl('Print/CheckInReport?', option));
          else exportToExcel(option);
        });
    }

    function dismiss(session) {
      $uibModal
        .open({
          templateUrl: require('../../../clinic/views/panelDashboard/dismissExpiredSession/dismissExpiredSession.modal.html'),
          controller: 'clinic.views.panelDashboard.dismissExpiredSessionModal as vm',
          backdrop: 'static',
        })
        .result.then((reason) => {
          if (reason) {
            voidSession(session, reason);
          }
        });
    }

    function voidSession(session, reason) {
      vm.loading += 1;
      panelDashboardSvc
        .voidPatientSession({
          patientSessionId: session.patientSessionId,
          voidRemarks: reason,
        })
        .success(() => {
          abp.notify.info(App.localize('CheckinXDismissed', session.patient));
          $state.reload();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function requestLateSubmission(session) {
      $uibModal
        .open({
          templateUrl: require('../../../clinic/views/panelDashboard/requestLateSubmission/requestLateSubmission.modal.html'),
          controller: 'clinic.views.panelDashboard.requestLateSubmissionModal as vm',
          resolve: {
            actualVisitDate() {
              return App.setDateTimeTimeZone(session.creationTime, session.ianaTimeZoneId);
            },
          },
          backdrop: 'static',
        })
        .result.then((result) => {
          if (result) {
            const state =
              vm.isGP && !session.isPreEmployee
                ? 'clinic.createEditPatientTicket'
                : 'createEditPatientTicket';

            $state.go(state, {
              employeeId: session.patientEmployeeId,
              clinicLocationId: session.clinicLocationId,
              ticketType: session.isPreEmployee ? 3 : 0,
              patientSessionId: session.patientSessionId,
              lateSubmissionReason: result.reason,
              lateSubmissionRequestorName: result.requestorName,
            });
          }
        });
    }

    function createTicket(checkInReport) {
      const { isPreEmployee, patientEmployeeId, checkedInTime, clinicLocationId } = checkInReport;

      if (!isPreEmployee && vm.isGP) {
        $state.go('clinic.createEditPatientTicket', {
          employeeId: patientEmployeeId,
          checkedInTime,
          clinicLocationId,
          ticketType: 0,
        });
      } else {
        $state.go('createEditPatientTicket', {
          employeeId: patientEmployeeId,
          checkedInTime,
          clinicLocationId,
          ticketType: 3,
        });
      }
    }

    function cancelCheckIn(checkInReport) {
      const report = checkInReport;

      $uibModal
        .open({
          templateUrl: require('../../../clinic/views/employees/checkOutModal.html'),
          controller: 'clinic.views.employees.checkOutModal as vm',
          backdrop: 'static',
        })
        .result.then((reason) => {
          if (reason) {
            report.cancellationRemarks = reason;
            checkOut(report);
          }
        });
    }

    function checkOut(checkInReport) {
      vm.loading += 1;
      clinicEmployeeSvc
        .checkOut({
          employeeId: checkInReport.patientEmployeeId,
          clinicLocationId: checkInReport.clinicLocationId,
          cancellationRemarks: checkInReport.cancellationRemarks,
        })
        .success(() => {
          vm.getCheckInReport();
          abp.notify.info(App.localize('PatientXCheckedOut', checkInReport.patient));
          swal.close();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function enterKeyTriggerGenerateReport($event) {
      if ($event.which === 13) {
        $event.preventDefault();
        vm.getCheckInReport();
      }
    }

    function getDateTimeString(date, timeZoneId) {
      return App.getDateTimeString(date, timeZoneId);
    }
  }
})();
