(() => {
  angular
    .module('app')
    .controller('revenue.views.subscriptionPlans.confirmationModal', ControllerController);

  ControllerController.$inject = [
    '$uibModalInstance',
    'subscriptionPlan',
    'categoryCharges',
    'currencyCode',
    'serviceFeatures',
    'Hms.Revenue.SubscriptionPlanPricingMode',
    'Hms.Revenue.SubscriptionPlanChargeMode',
  ];

  function ControllerController(
    $uibModalInstance,
    subscriptionPlan,
    categoryCharges,
    currencyCode,
    serviceFeatures,
    enumSubscriptionPlanPricingMode,
    enumSubscriptionPlanChargeMode
  ) {
    const vm = this;

    vm.cancel = cancel;
    vm.confirm = confirm;

    vm.subscriptionPlan = subscriptionPlan;
    vm.categoryCharges = categoryCharges;
    vm.currencyCode = currencyCode;
    vm.serviceFeatures = serviceFeatures;

    vm.enums = {
      subscriptionPlanPricingMode: enumSubscriptionPlanPricingMode,
      subscriptionPlanChargeMode: enumSubscriptionPlanChargeMode,
    };

    vm.getServiceFeaturesAsString = getServiceFeaturesAsString;
    vm.getChargeMode = getChargeMode;
    vm.getPricingMode = getPricingMode;

    function getServiceFeaturesAsString(serviceFeaturesArray) {
      const values = [];
      _.forEach(serviceFeaturesArray, (item) => {
        const serviceFeature = _.find(vm.serviceFeatures, (i) => i.id === item);
        values.push(serviceFeature.name);
      });
      return values.join(', ');
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function confirm() {
      $uibModalInstance.close();
    }

    function getChargeMode(chargeMode) {
      if (chargeMode === vm.enums.subscriptionPlanChargeMode.Monthly.id) {
        return App.localize('Monthly');
      }
      if (chargeMode === vm.enums.subscriptionPlanChargeMode.Yearly.id) {
        return App.localize('Yearly');
      }
      return '-';
    }

    function getPricingMode(pricingMode) {
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.Fixed.id) {
        return App.localize('Fixed');
      }
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.PerMember.id) {
        return App.localize('PerMember');
      }
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.PerTicketBasis.id) {
        return App.localize('PerTicketBasis');
      }
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.PerCaseBasis.id) {
        return App.localize('PerCaseBasis');
      }
      return '-';
    }
  }
})();
