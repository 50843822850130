(function () {
    angular.module('app').component('hostDoraemonCommonInpatientSobSettingsTableComponent', {
        templateUrl: require('/App/host/views/doraemon/common/inpatientSobSettingsTable/inpatientSobSettingsTable.component.html'),
        controller: controller,
        controllerAs: 'vm',
        bindings: {
            benefits: '<'
        }
    });

    function controller() {
        var vm = this;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.hasSobEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');

        vm.miscText = App.localize('Miscellaneous');
        vm.emergencyOutpatientText = App.localize('EmergencyOutpatientTreatment');
    }
})();
