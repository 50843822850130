import Value from './value';

class Diff {
  constructor(key) {
    this.key = key;
    this.data = [];
    this.isDifferent = false;
  }

  checkDifference(auditLogsLength) {
    // Populate dummy to match data length with number of audit logs.
    while (this.data.length < auditLogsLength) {
      this.data.push(new Value('', true));
    }

    const value1 = this.data[0]?.value;
    const value2 = this.data[1]?.value;

    this.isDifferent = value1 !== value2;

    return this.isDifferent;
  }

  push(value, isEmpty) {
    if (value instanceof Value) this.data.push(value);
    else this.data.push(new Value(value, isEmpty));
  }
}

export default Diff;
