(function () {
    var componentId = 'hostPanelRecruitmentManualUpdatePanelRequestComponent';
    var app = angular.module('app');

    function controller($scope, panelRequestSvc, enumPanelRequestSnapshotIntention, enumPanelRequestHostRejectionReason) {
        function init() {
        }

        var vm = this;

        vm.loading = 0;
        vm.saving = 0;
        vm.intentions = [];

        vm.enums = {};
        vm.enums.panelRequestSnapshotIntention = enumPanelRequestSnapshotIntention;
        vm.enums.panelRequestHostRejectionReason = enumPanelRequestHostRejectionReason;

        /* Approval Checklist */

        vm.checklistItems = [];
        vm.checklistData = [
            { text: 'Appointment Letter' },
            { text: 'Bank Information Request Form' },
            { text: 'Letterhead of Bank Statement' },
            { text: 'Copy of APC registration' },
            { text: 'Copy of Borang B' }
        ];

        vm.$onChanges = function (changes) {
            if (changes.panelRequest && vm.panelRequest) {
                initData();
            }
        };

        function assigneeNotChanged() {
            return vm.update.masterOpsId === vm.panelRequest.masterOpsId;
        }

        /* End of Approval Checklist */

        // Check if remarks is required.

        vm.requiredRemarks = function () {
            var remark = _.trim(vm.update.remarks || '');

            return vm.update.rejectionReason === 5 && remark === '' && vm.update.intention === 6;
        };

        vm.disableSave = function () {
            if (vm.update.intention === 6 && !_.isNil(vm.update.rejectionReason)) {
                if (vm.update.rejectionReason === 5) {
                    var remark = _.trim(vm.remarks || '');
                    return remark === ''; // disable if remark is empty and is 'Other' rejection reason.
                }
                else return false;
            }
            else if (vm.update.intention === 4)
                return assigneeNotChanged();
            else if (vm.update.intention === 18) {
                return _.some(vm.checklistItems, function (d) { return !d.isChecked; }) || assigneeNotChanged();
            }
            else if (vm.update.intention && vm.update.intention !== 6) {
                return false;
            }
            return true;
        };

        // Get request from container.

        var statuses = {
            // Requested
            // 1: AssignManually
            // 19: Exists
            0: { check: [1, 19], default: 1 },

            // InRecruitment
            // 2: ContactNoReply
            // 3: InProgress
            // 4: Reassign
            // 6: NotInterested
            // 15: EditInfo
            // 19: Exists
            1: { check: [2, 3, 4, 6, 15, 19] },

            // ProposalSent
            // 2: ContactNoReply
            // 6: NotInterested
            // 7: RequestExtraDocuments
            // 15: EditInfo
            // 18: RequestToRegister
            // 19: Exists
            2: { check: [2, 6, 7, 15, 18, 19] },

            // Rejected
            // 16: Reengage
            3: { check: [16], default: 16 },

            // Registered
            // 4: Reassign
            // 6: NotInterested
            // 10: CompleteTraining
            // 15: EditInfo
            4: { check: [4, 6, 10, 15] },

            // Trained
            // 6: NotInterested
            5: { check: [6], default: 6 },

            // Activated
            // 14: Deactivated
            6: { check: [14], default: 14 },

            // Deactivated
            // 9: ScheduleTraining
            // 12: StartDateNotSet
            // 13: Activate
            7: { check: [9, 12, 13] }
        };

        vm.currentStatus = null;
        vm.isValidIntention = function (intention) {
            if (!vm.currentStatus) return false;

            // Show only 'CompleteTraining' intention if training date passed

            if (intention === 10 && !vm.panelRequest.isTrainingDatePassed)
                return false;

            return _.includes(vm.currentStatus.check, intention);
        };

        function initData() {
            if (!vm.panelRequest) return;

            var status = statuses[vm.panelRequest.status];
            vm.currentStatus = status;

            if (status && status.default) vm.update.intention = status.default;

            // Add in 1 extra checklist requirement for Request To Register Progress

            if (vm.panelRequest.panelType === 5) { // Specialist
                vm.checklistData.push({ text: 'Copy of National Specialist Register(NSR)' });
            }

            // Set default value.

            vm.update.requestNumber = vm.panelRequest.requestNumber;
            vm.update.masterOpsId = vm.panelRequest.masterOpsId;
        }

        vm.update = {
            requestNumber: null,
            masterOpsId: null,
            dueDate: null,
            remarks: null,
            intention: null,
            rejectionReason: null,
            isFollowupNeeded: false
        };

        vm.save = function () {
            if (!App.isFormValid($scope.manualUpdateForm) || vm.saving) return;

            vm.saving++;
            panelRequestSvc.manualUpdatePanelRequest(vm.update)
                .success(function () {
                    abp.notify.info(App.localize('SuccessfullySaved'));
                    vm.onSaved();
                }).finally(function () {
                    vm.saving--;
                });
        };

        vm.cancel = function () {
            vm.onCancel();
        };

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/panelRecruitment/recruitmentTimeline/manualUpdatePanelRequest/manualUpdatePanelRequest.component.html'),
        controller: ['$scope', 'abp.services.app.panelRequest', 'Hms.PanelRequests.PanelRequestSnapshotIntention', 'Hms.PanelRequests.PanelRequestHostRejectionReason', controller],
        controllerAs: 'vm',
        bindings: {
            panelRequest: '<',
            masterOperators: '<',
            onSaved: '&',
            onCancel: '&'
        }
    });
})();
