(function () {
    var controllerId = 'host.views.treatments.index';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope','$rootScope', '$uibModal', '$timeout', 'abp.services.app.treatment', 'abp.services.app.specialistDiscipline', 'abp.services.app.commonLookup', 'countries', 'Hms.Services.ServiceType',
        function ($scope, $rootScope, $modal, $timeout, treatmentSvc, specialistDisciplineSvc, commonLookupSvc, countries, enumServiceType) {
            function init() {
                if (vm.hasRegionalSystemSupport) getEligibleCountries();
                if (vm.countryCode == "MY" || vm.countryCode == "ID") getSpecialistDiscipline();
                getCurrencyCode();

                vm.editTreatments(1, null, vm.countryCode);
            }

            var vm = this;
            vm.loading = 0;
            vm.countries = [];
            vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');
            vm.hasHealthScreeningModule = abp.setting.getBoolean('Hms.Feature.HealthScreeningModule');
            vm.hasIndonesiaAndThailandRegionalExpansion = abp.setting.getBoolean('Hms.Feature.IndonesiaAndThailandRegionalExpansion');

            // Set countryCode default to Malaysia
            vm.countryCode = 'MY';

            vm.enums = {};
            vm.enums.serviceType = enumServiceType;

            function getEligibleCountries() {
                vm.loading++;
                commonLookupSvc.getEligibleCountries()
                    .success(function (data) {
                        vm.countries = data;
                    }).finally(function () {
                        vm.loading--;
                    })
            }

            function getCurrencyCode() {
                var country = _.find(countries, ['code', vm.countryCode]);
                vm.currencyCode = country.currencyCode;
            }

            function getSpecialistDiscipline() {
                vm.loading++;
                specialistDisciplineSvc.getSpecialistDisciplines().success(function (data) {
                    vm.disciplines = data.items;
                }).finally(function (e) {
                    vm.loading--;
                });
            }

            $scope.$on('$viewContentLoaded', function () {
                App.initAjax();
            });

            vm.permissions = {
                generalTreatmentsEdit: abp.auth.isGranted('Host.Treatments.GeneralTreatments.Edit')
            };

            $.jstree.plugins.checkExtension = function (options, parent) {
                // Blocks DND on an other tree than the original one
                this.check = function (chk, obj, par, pos, more) {
                    if (parent.check.call(this, chk, obj, par, pos, more) === false) { return false; }
                    if (more && more.dnd && more.is_multi) { return false; }
                    return true;
                };
            };

            vm.editTreatments = function (serviceType, disciplineId, countryCode) {
                getCurrencyCode();
                vm.treatmentStructure = new TreatmentStructure('TreatmentEditTree', serviceType, disciplineId, countryCode);
                vm.treatmentStructure.init();
            };

            vm.isSelected = function (serviceType, disciplineId) {
                var ts = vm.treatmentStructure;
                return ts && serviceType === ts.treatmentType && disciplineId === ts.specialistDisciplineId;
            }

            function TreatmentStructure(elementId, serviceType, specialistDisciplineId, countryCode) {
                this.$tree = null;
                this.treatmentCount = 0;
                this.treatmentType = serviceType;
                this.specialistDisciplineId = specialistDisciplineId;
                this.countryCode = countryCode;
                this.setTreatmentCount = function (count) {
                    var _this = this;
                    $rootScope.safeApply(function () {
                        _this.treatmentCount = count;
                    });
                };
                this.refreshTreatmentCount = function () {
                    this.setTreatmentCount(this.$tree.jstree('get_json').length);
                };
                this.selectedTreatment = {
                    id: null,
                    displayName: null,
                    isDuplicable: false,
                    midnightSurcharge: false,
                    preferredMaxRate: null,
                    preferredDefaultRate: null,
                    requiresRemark: false,
                    excludeFromPopularTreatment: false,
                    pharmacyApplicable: false,
                    isDefault: false,
                    code: null,
                    tags: null,
                    countryCode: null,
                    set: function (treatment) {
                        if (treatment) {
                            this.id = treatment.id;
                            this.displayName = treatment.original.displayName;
                            this.isDuplicable = treatment.original.isDuplicable;
                            this.midnightSurcharge = treatment.original.midnightSurcharge;
                            this.preferredMaxRate = treatment.original.preferredMaxRate;
                            this.preferredDefaultRate = treatment.original.preferredDefaultRate;
                            this.requiresRemark = treatment.original.requiresRemark;
                            this.excludeFromPopularTreatment = treatment.original.excludeFromPopularTreatment;
                            this.pharmacyApplicable = treatment.original.pharmacyApplicable;
                            this.isDefault = treatment.original.isDefault;
                            this.code = treatment.original.code;
                            this.tags = treatment.original.tags;
                            this.countryCode = treatment.original.countryCode;
                        } else {
                            this.id = null;
                            this.displayName = null;
                            this.isDuplicable = false;
                            this.midnightSurcharge = false;
                            this.preferredMaxRate = null;
                            this.preferredDefaultRate = null;
                            this.requiresRemark = false;
                            this.excludeFromPopularTreatment = false;
                            this.pharmacyApplicable = false;
                            this.isDefault = false;
                            this.code = null;
                            this.tags = null;
                            this.countryCode = countryCode;
                        }
                    }
                };
                this.contextMenu = function (treatment) {
                    var _this = $(this.element).data('editData');
                    return {
                        editTreatment: {
                            label: App.localize('Edit'),
                            _disabled: !vm.permissions.generalTreatmentsEdit,
                            action: function (instance) {
                                var ref = $.jstree.reference(instance.reference);
                                _this.openCreateOrEditTreatmentModal({
                                    id: treatment.id,
                                    displayName: treatment.original.displayName,
                                    isDuplicable: treatment.original.isDuplicable,
                                    midnightSurcharge: treatment.original.midnightSurcharge,
                                    preferredMaxRate: treatment.original.preferredMaxRate,
                                    preferredDefaultRate: treatment.original.preferredDefaultRate,
                                    requiresRemark: treatment.original.requiresRemark,
                                    excludeFromPopularTreatment: treatment.original.excludeFromPopularTreatment,
                                    pharmacyApplicable: treatment.original.pharmacyApplicable,
                                    isDefault: treatment.original.isDefault,
                                    tags: treatment.original.tags,
                                    serviceType: treatment.original.serviceType,
                                    specialistDisciplineId: treatment.original.specialistDisciplineId,
                                    tenantTypeRestrictions: treatment.original.tenantTypeRestrictions,
                                    countryCode: treatment.original.countryCode
                                }, function (selectedData) {
                                    treatment.original.displayName = selectedData.displayName;
                                    treatment.original.isDuplicable = selectedData.isDuplicable;
                                    treatment.original.midnightSurcharge = selectedData.midnightSurcharge;
                                    treatment.original.preferredMaxRate = selectedData.preferredMaxRate;
                                    treatment.original.preferredDefaultRate = selectedData.preferredDefaultRate;
                                    treatment.original.requiresRemark = selectedData.requiresRemark;
                                    treatment.original.excludeFromPopularTreatment = selectedData.excludeFromPopularTreatment;
                                    treatment.original.pharmacyApplicable = selectedData.pharmacyApplicable;
                                    treatment.original.isDefault = selectedData.isDefault;
                                    treatment.original.tags = selectedData.tags;
                                    treatment.original.serviceType = selectedData.serviceType;
                                    treatment.original.specialistDisciplineId = selectedData.specialistDisciplineId;
                                    treatment.original.tenantTypeRestrictions = selectedData.tenantTypeRestrictions;
                                    treatment.original.countryCode = selectedData.countryCode;
                                    ref.rename_node(treatment, _this.generateTextOnTree(selectedData));
                                });
                            }
                        },
                        addSubTreatment: {
                            label: App.localize('AddSubTreatment'),
                            _disabled: !vm.permissions.generalTreatmentsEdit,
                            action: function () {
                                _this.addTreatment(treatment.id);
                            }
                        },
                        'delete': {
                            label: App.localize('Delete'),
                            _disabled: !vm.permissions.generalTreatmentsEdit,
                            action: function (instance) {
                                var ref = $.jstree.reference(instance.reference);
                                abp.message.confirm(App.localize('TreatmentDeleteWarningMessage', treatment.original.displayName), App.localize('AreYouSure'), function (result) {
                                    if (result) {
                                        treatmentSvc.deleteTreatment({
                                            id: treatment.id
                                        }).success(function () {
                                            abp.notify.success(App.localize('SuccessfullyDeleted'));
                                            ref.delete_node(treatment);
                                            _this.refreshTreatmentCount();
                                            _this.reload();
                                        });
                                    }
                                });
                            }
                        },
                        assignToAllTreatment: {
                            label: App.localize('WhitelistTreatment'),
                            _disabled: !vm.permissions.generalTreatmentsEdit || treatment.original.serviceType === 250,
                            action: function (instance) {
                                abp.message.confirm(App.localize('WhitelistTreatmentWarningMessage', treatment.original.displayName), App.localize('AreYouSure'), function (result) {
                                    if (result) {
                                        treatmentSvc.assignTreatment({
                                            id: treatment.id
                                        }).success(function () {
                                            abp.notify.success(App.localize('SuccessfullySaved'));
                                        });
                                    }
                                });
                            }
                        },
                        unassignToAllTreatment: {
                            label: App.localize('BlacklistTreatment'),
                            _disabled: !vm.permissions.generalTreatmentsEdit || treatment.original.serviceType === 250,
                            action: function (instance) {
                                abp.message.confirm(App.localize('BlacklistTreatmentWarningMessage', treatment.original.displayName), App.localize('AreYouSure'), function (result) {
                                    if (result) {
                                        treatmentSvc.unassignTreatment({
                                            id: treatment.id
                                        }).success(function () {
                                            abp.notify.success(App.localize('SuccessfullySaved'));
                                        });
                                    }
                                });
                            }
                        }
                    };
                };
                this.addTreatment = function (parentId) {
                    var _this = this;

                    var ref = $.jstree.reference(_this.$tree);
                    _this.openCreateOrEditTreatmentModal({
                        parentId: parentId,
                        serviceType: serviceType,
                        specialistDisciplineId: specialistDisciplineId,
                        countryCode: countryCode
                    }, function (selectedData) {
                        ref.create_node(parentId ? ref.get_node(parentId) : '#', {
                            id: selectedData.id,
                            parent: selectedData.parentId ? selectedData.parentId : '#',
                            code: selectedData.code,
                            displayName: selectedData.displayName,
                            isDuplicable: selectedData.isDuplicable,
                            midnightSurcharge: selectedData.midnightSurcharge,
                            preferredMaxRate: selectedData.preferredMaxRate,
                            preferredDefaultRate: selectedData.preferredDefaultRate,
                            requiresRemark: selectedData.requiresRemark,
                            excludeFromPopularTreatment: selectedData.excludeFromPopularTreatment,
                            pharmacyApplicable: selectedData.pharmacyApplicable,
                            isDefault: selectedData.isDefault,
                            tags: selectedData.tags,
                            serviceType: selectedData.serviceType,
                            specialistDisciplineId: selectedData.specialistDisciplineId,
                            tenantTypeRestrictions: selectedData.tenantTypeRestrictions,
                            countryCode: countryCode,
                            text: _this.generateTextOnTree(selectedData),
                            state: {
                                opened: true
                            }
                        });
                        _this.refreshTreatmentCount();
                    });
                };

                this.openCreateOrEditTreatmentModal = function (treatment, callback) {
                    $modal.open({
                        templateUrl: require('/App/host/views/treatments/createOrEditTreatmentModal.html'),
                        controller: 'host.views.treatments.createOrEditTreatmentModal as vm',
                        backdrop: 'static',
                        resolve: {
                            treatment: function () {
                                return treatment;
                            }
                        }
                    }).result.then(function (selectedData) {
                        if (callback) {
                            callback(selectedData);
                        }
                    });
                };
                this.generateTextOnTree = function (data) {
                    var preferredRateSpan = '';
                    if (data.preferredMaxRate) {
                        preferredRateSpan += ' - <b>' + vm.currencyCode + ' ' + data.preferredMaxRate + '</b>';
                    }
                    if (data.pharmacyApplicable) {
                        preferredRateSpan += ' &nbsp;<i title="' + App.localize('ApplicableAsPrescriptionForPharmacy') + '" class=\'text-navy far fa-capsules\'></i>';
                    }
                    return '<span class=\"treatment-text\" title=\"' + data.code + '\"  data-treatment-id=\"' + data.id + '\">' + data.displayName + ' ' + preferredRateSpan + ' &nbsp;<i class=\"fa fa-caret-down text-muted\"><\/i><\/span>';
                };
                this.getTreeDataFromServer = function (callback) {
                    var _this = this;

                    vm.loading++;
                    treatmentSvc.getTreatments({
                        serviceType: serviceType,
                        specialistDisciplineId: specialistDisciplineId,
                        countryCode: countryCode
                    }).success(function (result) {
                        var item = _.map(result.items, function (data) {
                            return {
                                id: data.id,
                                parent: data.parentId ? data.parentId : '#',
                                code: data.code,
                                displayName: data.displayName,
                                isDuplicable: data.isDuplicable,
                                midnightSurcharge: data.midnightSurcharge,
                                preferredMaxRate: data.preferredMaxRate,
                                preferredDefaultRate: data.preferredDefaultRate,
                                requiresRemark: data.requiresRemark,
                                excludeFromPopularTreatment: data.excludeFromPopularTreatment,
                                pharmacyApplicable: data.pharmacyApplicable,
                                isDefault: data.isDefault,
                                tags: data.tags,
                                serviceType: data.serviceType,
                                specialistDisciplineId: data.specialistDisciplineId,
                                tenantTypeRestrictions: data.tenantTypeRestrictions,
                                countryCode: data.countryCode,
                                text: _this.generateTextOnTree(data),
                                state: {
                                    opened: true
                                }
                            };
                        });
                        callback(item);
                    }).finally(function () {
                        vm.loading--;
                    });
                };
                this.init = function () {
                    var _this = this;
                    var element = $('#' + elementId);
                    var ref = $.jstree.reference(element);
                    if (ref) {
                        ref.destroy();
                    }

                    _this.getTreeDataFromServer(function (result) {
                        _this.setTreatmentCount(result.length);
                        _this.$tree = element;
                        _this.$tree.data('editData', _this);

                        var plugins = ['types', 'contextmenu', 'wholerow', 'sort'];
                        if (vm.permissions.generalTreatmentsEdit) {
                            plugins.push('dnd');
                            plugins.push('checkExtension');
                        }

                        _this.$tree.on('changed.jstree', function (node, action) {
                            $rootScope.safeApply(function () {
                                if (action.selected.length !== 1) _this.selectedTreatment.set(null);
                                else {
                                    var $scope = action.instance.get_node(action.selected[0]);
                                    _this.selectedTreatment.set($scope);
                                }
                            });
                        }).on('move_node.jstree', function (obj, newParent) {
                            if (!vm.permissions.generalTreatmentsEdit) {
                                _this.$tree.jstree('refresh');
                                return;
                            }

                            var newParentName = !newParent.parent ||
                                newParent.parent === '#' ? App.localize('    ') : _this.$tree.jstree('get_node', newParent.parent).original.displayName;

                            abp.message.confirm(App.localize('TreatmentMoveConfirmMessage', newParent.node.original.displayName, newParentName), App.localize('AreYouSure'), function (result) {
                                if (result) {
                                    treatmentSvc.moveTreatment({
                                        id: newParent.node.id,
                                        newParentId: newParent.parent
                                    }).success(function () {
                                        abp.notify.success(App.localize('SuccessfullyMoved'));
                                        _this.reload();
                                    }).catch(function (obj) {
                                        _this.$tree.jstree('refresh');
                                        $timeout(function () {
                                            abp.message.error(obj.data.message);
                                        }, 500);
                                    });
                                } else {
                                    _this.$tree.jstree('refresh');
                                }
                            });
                        }).jstree({
                            core: {
                                data: result,
                                multiple: false,
                                check_callback: function () {
                                    return true;
                                }
                            },
                            types: {
                                'default': {
                                    icon: 'fa fa-folder tree-item-icon-color icon-lg'
                                },
                                file: {
                                    icon: 'fa fa-file tree-item-icon-color icon-lg'
                                }
                            },
                            contextmenu: {
                                items: _this.contextMenu
                            },
                            sort: function (itemA, itemB) {
                                return this.get_node(itemB).original.displayName < this.get_node(itemA).original.displayName ? 1 : -1;
                            },
                            plugins: plugins
                        });

                        _this.$tree.on('click', '.treatment-text .fa-caret-down', function (e) {
                            e.preventDefault();
                            var ouId = $(this).closest('.treatment-text').attr('data-treatment-id');
                            $timeout(function () {
                                _this.$tree.jstree('show_contextmenu', ouId);
                            }, 100);
                        });
                    });
                };
                this.reload = function () {
                    var _this = this;
                    _this.getTreeDataFromServer(function (data) {
                        _this.setTreatmentCount(data.length);
                        _this.$tree.jstree(true).settings.core.data = data;
                        _this.$tree.jstree('refresh');
                    });
                };
            }

            init();
        }
    ]);
})();
