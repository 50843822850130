(() => {
  angular
    .module('app')
    .controller(
      'common.views.patientTickets.detail.editTicketAmountModal',
      EditTicketAmountModalController
    );

  EditTicketAmountModalController.$inject = [
    '$uibModal',
    '$uibModalInstance',
    'abp.services.app.patientTicket',
    'ticket',
    'hasInsurerClaim',
    'totalClaimableAmount',
    'coPayAmount',
  ];

  function EditTicketAmountModalController(
    $uibModal,
    $uibModalInstance,
    patientTicketSvc,
    ticket,
    hasInsurerClaim,
    totalClaimableAmount,
    coPayAmount
  ) {
    const vm = this;

    vm.saving = 0;
    vm.isExceed = false;
    vm.ticketNumber = ticket.ticketNumber;
    vm.currentFinalGlAmount = ticket.coveredAmount;
    vm.hospitalBillAmount = totalClaimableAmount;
    vm.underInsurerClaim = hasInsurerClaim;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.isHost = App.isHost();

    vm.hasMemberBenefitUtilizationEnabled = abp.setting.getBoolean(
      'Hms.Feature.MemberBenefitUtilization'
    );

    vm.cancel = cancel;
    vm.save = save;

    init();

    function init() {
      vm.newFinalAmount = _.sumBy(ticket.treatments, (d) => d.amount || 0);

      if (coPayAmount) vm.newFinalAmount -= coPayAmount;
    }

    function save() {
      if (!App.isFormValid(vm.editTicketAmountModalForm)) {
        return;
      }

      const treatments = _.map(ticket.treatments, (treatment) => ({
        treatmentId: treatment.treatmentId,
        amount: treatment.amount,
        remark: treatment.remark,
        uncoveredAmount: treatment.uncoveredAmount,
        uncoveredRemark: treatment.uncoveredRemark,
      }));

      vm.saving += 1;
      patientTicketSvc
        .updateClaimTicketAmount({
          id: vm.ticketNumber,
          amount: vm.newFinalAmount,
          referenceNumber: vm.referenceNumber,
          modificationRemarks: $.trim(vm.modificationRemarks),
          treatments,
        })
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          if (vm.hasMemberBenefitUtilizationEnabled && ticket.ticketType !== 1 && vm.isHost) {
            showAdjustMemberUtilizationConfirmationModal(vm.ticketNumber);
          }
          $uibModalInstance.close();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function showAdjustMemberUtilizationConfirmationModal(ticketNumber) {
      $uibModal.open({
        templateUrl: require('../../../memberUtilizations/adjustMemberUtilizationConfirmationModal.html'),
        controller:
          'common.views.memberUtilizations.adjustMemberUtilizationConfirmationModal as vm',
        backdrop: 'static',
        size: 'sm',
        resolve: {
          ticketNumber() {
            return ticketNumber;
          },
        },
      });
    }
  }
})();
