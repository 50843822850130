(() => {
  angular.module('app').component('normalOperatingHour', {
    templateUrl: require('./normalOperatingHour.component.html'),
    controller: NormalOperatingHourController,
    controllerAs: 'vm',
    bindings: {
      schedules: '=',
      disabled: '<',
    },
  });

  NormalOperatingHourController.$inject = ['moment'];

  function NormalOperatingHourController(moment) {
    const vm = this;
    vm.daysOfWeek = [];

    vm.changeOperationMode = changeOperationMode;
    vm.deleteSchedule = deleteSchedule;
    vm.$onChanges = onChanges;
    vm.validateSchedule = validateSchedule;

    init();

    function init() {
      for (let i = 0; i < 7; i += 1) {
        vm.daysOfWeek[i] = {
          id: i,
          date: moment().day(i),
        };
      }

      validateSchedule();
    }

    function onChanges(changes) {
      if (changes.disabled && !changes.disabled.currentValue) {
        // Add default empty schedule for edit

        if (vm.schedules.length > 0) {
          const lastOperatingHour = _.last(vm.schedules);
          if (lastOperatingHour.isHours || lastOperatingHour.is24Hours || !lastOperatingHour.isOpen)
            addSchedule(vm.schedules.length - 1);
        } else {
          addSchedule(-1);
        }
      }
      validateSchedule();
    }

    function changeOperationMode(value, index) {
      switch (value) {
        case 1:
          vm.schedules[index].isHours = true;
          vm.schedules[index].is24Hours = false;
          vm.schedules[index].isOpen = true;
          break;

        case 2:
          vm.schedules[index].isHours = false;
          vm.schedules[index].is24Hours = true;
          vm.schedules[index].isOpen = true;
          break;

        case 3:
          vm.schedules[index].isHours = false;
          vm.schedules[index].is24Hours = false;
          vm.schedules[index].isOpen = false;
          break;

        default:
          vm.schedules[index].isHours = false;
          vm.schedules[index].is24Hours = false;
          vm.schedules[index].isOpen = true;
          break;
      }

      addSchedule(index);
      validateSchedule();
    }

    function deleteSchedule(index) {
      if (index < vm.schedules.length) vm.schedules = _.without(vm.schedules, vm.schedules[index]);
      if (vm.schedules.length === 0) {
        addSchedule(-1);
      }
      validateSchedule();
    }

    function addSchedule(index) {
      if (index === vm.schedules.length - 1) {
        vm.schedules.push({
          id: 0,
          days: new Array(7).fill(false),
          hours: [],
          isHours: false,
          is24Hours: false,
          isOpen: true,
        });
      }
    }

    function validateSchedule() {
      _.map(vm.schedules, (s) => {
        const schedule = s;
        schedule.isDuplicate = false;
        return false;
      });

      const selectedDays = _.map(vm.schedules, (x) =>
        _.flatMap(x.days, (bool, index) => (bool ? index : []))
      );
      const duplicateDays = [];
      for (let i = 0; i < 7; i += 1) {
        if (_.countBy(_.flatMap(selectedDays))[i] > 1) duplicateDays.push(i);
      }

      _.filter(selectedDays, (day, index) => {
        if (_.some(duplicateDays, (duplicateDay) => _.some(day, (d) => d === duplicateDay)))
          vm.schedules[index].isDuplicate = true;
      });

      vm.schedules.error = _.some(vm.schedules, (schedule) => schedule.isDuplicate);
    }
  }
})();
