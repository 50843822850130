(() => {
  angular
    .module('app')
    .controller('common.views.signatureProfiles.createOrEditModal', SignatureProfileEditController);

  SignatureProfileEditController.$inject = [
    '$scope',
    '$uibModalInstance',
    'abp.services.app.signatureProfile',
    'profileId',
    'userId',
    'SignatureProfile',
  ];

  function SignatureProfileEditController(
    $scope,
    $uibModalInstance,
    signatureProfileSvc,
    profileId,
    userId,
    constsSignatureProfile
  ) {
    const vm = this;

    vm.constsSignatureProfile = constsSignatureProfile;
    let updateSignatoryNameIfPristine = true;
    vm.loading = 0;
    vm.saving = 0;
    vm.userId = userId;
    vm.signatureProfile = {};
    vm.updateSignature = false;
    vm.uploadSignature = false;

    vm.toggleSignatureUpdate = toggleSignatureUpdate;
    vm.toggleUploader = toggleUploader;
    vm.nameChanged = nameChanged;
    vm.save = save;
    vm.cancel = cancel;

    init();

    function init() {
      vm.loading += 1;
      signatureProfileSvc
        .getSignatureProfileForEdit({
          id: profileId,
        })
        .success((data) => {
          vm.signatureProfile = data;
          vm.isCreate = !vm.signatureProfile.id;
          vm.updateSignature = vm.isCreate;

          updateSignatoryNameIfPristine =
            (!vm.signatureProfile.name && !vm.signatureProfile.signatoryName) ||
            vm.signatureProfile.name === vm.signatureProfile.signatoryName;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function toggleSignatureUpdate() {
      vm.updateSignature = !vm.updateSignature;
    }

    function toggleUploader() {
      vm.uploadSignature = !vm.uploadSignature;
    }

    function nameChanged() {
      if (updateSignatoryNameIfPristine && $scope.signatureProfileForm.SignatoryName.$pristine) {
        vm.signatureProfile.signatoryName = vm.signatureProfile.name;
      }
    }

    /* Image To Base64 Converter */

    function resizeImage(data, callback) {
      const canvas = document.createElement('canvas');
      canvas.width = 200;
      canvas.height = 100;

      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => {
        let finalWidth = img.width;
        let finalHeight = img.height;
        if (img.width > canvas.width || img.height > canvas.height) {
          const ratioX = img.width / canvas.width;
          const ratioY = img.height / canvas.height;
          const ratio = Math.max(ratioX, ratioY);

          finalHeight = img.height / ratio;
          finalWidth = img.width / ratio;
        }

        const offsetX = (canvas.width - finalWidth) / 2;
        const offsetY = (canvas.height - finalHeight) / 2;
        ctx.drawImage(img, offsetX, offsetY, finalWidth, finalHeight);
        callback(canvas.toDataURL('image/png'));
      };
      img.src = data;
    }

    /* End of Image To Base64 Converter */

    function save() {
      const input = $.extend({}, vm.signatureProfile);
      if (input.id === 0) {
        input.userId = vm.userId;
      }

      if (vm.isCreate || vm.updateSignature) {
        if (vm.uploadSignature) {
          if (!vm.uploadedImage) {
            abp.message.error(App.localize('MissingSignature'));
            return;
          }

          resizeImage(vm.uploadedImage, (uri) => {
            input.dataUrl = uri;
            createOrUpdate(input);
          });
        } else {
          input.dataUrl = vm.accept().dataUrl;
          createOrUpdate(input);
        }
      } else {
        createOrUpdate(input);
      }
    }

    function createOrUpdate(input) {
      if ((vm.isCreate || vm.updateSignature) && !input.dataUrl) {
        abp.message.error(App.localize('MissingSignature'));
        return;
      }

      vm.saving += 1;
      signatureProfileSvc
        .createOrUpdateSignatureProfile(input)
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          $uibModalInstance.close();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
