(function () {
    'use strict';

    angular
        .module('app')
        .controller('host.views.operationsDashboard.insuranceClaims.insuranceClaimsListing.confirmInsuranceUtilization.confirmInsuranceUtilizationModal',
            ['$uibModalInstance', 'title', 'specialistOrGP', 'insurerCreditNoteAmount', 'companyCreditNoteAmount', 'memberInvoiceAmount',
                'normalUtilizationAmount', 'hospitalisationInsuranceUtilizationAmount', 'insurerClaimCategory', controller]);

    function controller($uibModalInstance, title, specialistOrGP, insurerCreditNoteAmount, companyCreditNoteAmount,
        memberInvoiceAmount, normalUtilizationAmount, hospitalisationInsuranceUtilizationAmount, insurerClaimCategory) {
        var vm = this;
        
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.cancel = cancel;
        vm.submit = submit;
        vm.title = title;
        vm.specialistOrGP = specialistOrGP;
        vm.isConfirmed = false;
        vm.insurerCreditNoteAmount = insurerCreditNoteAmount;
        vm.companyCreditNoteAmount = companyCreditNoteAmount;
        vm.memberInvoiceAmount = memberInvoiceAmount;
        vm.normalUtilizationAmount = normalUtilizationAmount;
        vm.hospitalisationInsuranceUtilizationAmount = hospitalisationInsuranceUtilizationAmount;
        vm.insurerClaimCategory = insurerClaimCategory;
        
        if (vm.hospitalisationInsuranceUtilizationAmount == null) {
            vm.hospitalisationInsuranceUtilizationAmount = 0;
        }

        if (App.localize('RejectInsurerClaim') == vm.title) {
            vm.reject = true;
        } else {
            vm.reject = false;
        }

        function cancel() {
            $uibModalInstance.dismiss();
        }

        function submit() {
            if (App.isFormValid(vm.modalForm)) {
                vm.isConfirmed = true;
                $uibModalInstance.close(vm.isConfirmed);
            }
        }
    }
})();
