(() => {
  angular
    .module('app')
    .controller('corporate.views.subscriptions.index', CorporateSubscriptionsController);

  CorporateSubscriptionsController.$inject = [
    '$scope',
    '$stateParams',
    'abp.services.app.subscription',
    'appConstants',
  ];

  function CorporateSubscriptionsController($scope, $stateParams, subscriptionSvc, appConstants) {
    const vm = this;

    vm.loading = 0;
    vm.status = '0';
    vm.requestParams = {
      filter: $stateParams.filter,
      skipCount: $stateParams.skipCount,
      maxResultCount: $stateParams.maxResultCount,
      sorting: $stateParams.sorting,
    };
    vm.subscriptionGridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSizes,
      paginationPageSize: appConstants.grid.defaultPageSize,
      paginationCurrentPage: 1,
      useExternalPagination: true,
      useExternalSorting: true,
      resizable: true,
      columnDefs: [
        {
          name: App.localize('Actions'),
          enableSorting: false,
          width: 75,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
          allowCellFocus: false,
        },
        {
          name: App.localize('Status'),
          minWidth: 85,
          width: 85,
          cellTemplate: 'statusTemplate',
        },
        {
          displayName: App.localize('SubscriptionNumber'),
          field: 'subscriptionNumber',
          minWidth: 100,
        },
        {
          displayName: App.localize('BillingAccount'),
          field: 'billingAccount',
          minWidth: 100,
        },
        {
          name: App.localize('EffectivePeriod'),
          field: 'startDate',
          minWidth: 100,
          cellTemplate: 'effectivePeriodTemplate',
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          let sorting = '';
          for (let i = 0; i < callback.length; i += 1) {
            if (i) sorting += ', ';
            sorting += callback[i].field.concat(' ', callback[i].sort.direction);
          }
          vm.requestParams.sorting = sorting;
          vm.refreshSubscriptions();
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, vm.refreshSubscriptions)
        );
      },
      data: [],
    };

    vm.refreshSubscriptions = refreshSubscriptions;

    init();

    function init() {
      refreshSubscriptions();
    }

    function refreshSubscriptions() {
      const params = _.assign({ status: vm.status }, vm.requestParams);

      vm.loading += 1;
      subscriptionSvc
        .getSubscriptions(params)
        .success((data) => {
          vm.subscriptionGridOptions.totalItems = data.totalCount;
          vm.subscriptionGridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
