import libphonenumber from 'google-libphonenumber';

(() => {
  angular
    .module('app')
    .filter('contactNumber', contactNumber)
    // For backward compatibility only. Filter name don't need "Filter" suffix.
    // AngularJS automatically appends "Filter" to name for DI.
    .filter('contactNumberFilter', contactNumber);

  contactNumber.$inject = [];

  function contactNumber() {
    return contactNumberFilter;

    function contactNumberFilter(value) {
      if (value && value.length) {
        try {
          const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
          const valueProto = phoneUtil.parse(value, 'MY');
          if (phoneUtil.isValidNumber(valueProto)) {
            return phoneUtil.format(valueProto, libphonenumber.PhoneNumberFormat.INTERNATIONAL);
          }
        } catch (ex) {
          console.error(ex);
        }
      }
      return value;
    }
  }
})();
