
((function () {
  angular
    .module('app')
    .filter('commissionInstructionStatus', commissionInstructionStatus)
    // For backward compatibility only. Filter name don't need "Filter" suffix.
    // AngularJS automatically appends "Filter" to name for DI.
    .filter('commissionInstructionStatusFilter', commissionInstructionStatus);

  commissionInstructionStatus.$inject = [];

  function commissionInstructionStatus() {
    return commissionInstructionStatusFilter;

    function commissionInstructionStatusFilter(value) {
      switch (value) {
        case 0:
          return App.localize('CommissionInstructionStatusFilter_Submitted');
        case 1:
          return App.localize('CommissionInstructionStatusFilter_Rejected');
        case 2:
          return App.localize('CommissionInstructionStatusFilter_Authorized');
        case 3:
          return App.localize('CommissionInstructionStatusFilter_Posted');
        default:
          return value;
      }
    };
  }
})());
