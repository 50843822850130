(function () {
    var componentId = 'hostPanelRecruitmentDashboardMonthlyStatsWidget';
    var app = angular.module('app');

    function controller(panelRecruitmentDashboardSvc, enumTenantClinicType) {
        function init() {
          loadData();
        }

        var vm = this;
        vm.loading = 0;
        vm.availableMonths = [];
        vm.countries = [];
        vm.subdivisions = [];
        vm.cities = [];
        vm.statistic = null;
        vm.filter = {
            monthYear: null,
            countryCode: null,
            countrySubdivisionId: null,
            panelType: null,
            city: null
        };
        vm.getMonthlyStatistic = getMonthlyStatistic;
        vm.resetCityAndGetMonthlyStatistics = resetCityAndGetMonthlyStatistics;
        vm.resetSubdivisionAndGetMonthlyStatistics = resetSubdivisionAndGetMonthlyStatistics;

        vm.enums = {};
        vm.enums.tenantClinicType = enumTenantClinicType;

        vm.hasIndonesiaAndThailandRegionalExpansion = abp.setting.getBoolean('Hms.Feature.IndonesiaAndThailandRegionalExpansion');

        function loadData() {
            vm.loading++;
            panelRecruitmentDashboardSvc.getMonthlyStatisticFilter()
                .success(function (data) {
                    vm.availableMonths = data.monthYears;
                    if (vm.hasIndonesiaAndThailandRegionalExpansion) {
                      vm.countries = data.countries.sort((a, b) => a.name.localeCompare(b.name));
                      vm.countrySubdivisions = data.countrySubdivisions;
                    } else {
                      vm.subdivisions = data.countrySubdivisions;
                    }
                    vm.cityStates = data.cityStates;

                    vm.filter.monthYear = _.head(vm.availableMonths);
                    getMonthlyStatistic();
                }).finally(function () {
                    vm.loading--;
                });
        }

      function getMonthlyStatistic() {
            vm.loading++;
            panelRecruitmentDashboardSvc.getMonthlyStatistic(vm.filter)
                .success(function (data) {
                    processCacheItem(data);
                }).finally(function () {
                    vm.loading--;
                });
        }

        function processCacheItem(cacheItem) {
            vm.statistic = cacheItem;

            if (vm.statistic.leadConversionRate) {
                vm.statistic.leadConversionRate.percentage = _.round(vm.statistic.leadConversionRate.percentage, 2);
                vm.statistic.leadConversionRate.diff = _.round(vm.statistic.leadConversionRate.diff, 2);
            }

            _.each(cacheItem.requestStatusCounts, function (item) {
                item.percentageDiff = _.round(item.percentageDiff, 2);
                switch (item.status) {
                    case 0: vm.statistic.monthlyInitiate = item; break;
                    case 3: vm.statistic.monthlyRejected = item; break;
                    case 6: vm.statistic.monthlyActivated = item; break;
                    case 7: vm.statistic.monthlyDeactivated = item; break;
                }
            });

            vm.filter.monthYear = _.some(vm.availableMonths, function (month) { return month === cacheItem.monthYear })
                ? cacheItem.monthYear
                : vm.filter.monthYear;
        }

        function resetSubdivisionAndGetMonthlyStatistics() {
            vm.filter.countrySubdivisionId = null;
            vm.filter.city = null;
            vm.cities = [];
            if (vm.filter.countryCode) {
                vm.subdivisions = vm.countrySubdivisions.filter(countrySubdivision => countrySubdivision.value.includes(vm.filter.countryCode));
                vm.subdivisions.sort((a, b) => a.name.localeCompare(b.name));
            } else {
                vm.subdivisions = [];
            }

            getMonthlyStatistic();
        }

        function resetCityAndGetMonthlyStatistics() {
            vm.filter.city = null;
            if (vm.filter.countrySubdivisionId) {
                vm.cities = _.map(_.filter(vm.cityStates, ['countrySubdivisionId', vm.filter.countrySubdivisionId]), 'city').sort();
            } else {
                vm.cities = [];
            }

            getMonthlyStatistic();
        }

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/panelRecruitment/dashboard/monthlyStats/monthlyStats.component.html'),
        controller: ['abp.services.app.panelRecruitmentDashboard', 'Hms.MultiTenancy.TenantClinicType', controller],
        controllerAs: 'vm'
    });
})();
