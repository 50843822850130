/* global zE */
import swal from 'sweetalert';

(() => {
  angular.module('app').component('clinicEmployeesComponent', {
    templateUrl: require('./index.html'),
    controller: ClinicEmployeesController,
    controllerAs: 'vm',
  });

  ClinicEmployeesController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$uibModal',
    '$interval',
    '$filter',
    'abp.services.app.clinicEmployee',
    'abp.services.app.commonLookup',
    'abp.services.app.patientTicket',
    'lookupModal',
    'appSession',
    'Hms.GuaranteeLetters.GuaranteeLetterStatus',
    'moment',
  ];

  function ClinicEmployeesController(
    $scope,
    $state,
    $stateParams,
    $uibModal,
    $interval,
    $filter,
    clinicEmployeeSvc,
    commonLookupSvc,
    patientTicketSvc,
    lookupModal,
    appSession,
    enumGuaranteeLetterStatus,
    moment
  ) {
    const vm = this;

    let tickerCount = 0;
    let isSilentLoading = 0;

    vm.isSpecialist = App.isSpecialist();
    vm.isInpatient = App.isInpatient();
    vm.loading = 0;
    vm.filterText = $stateParams.filterText || '';
    vm.showNoRecords = 0;
    vm.employees = null;
    vm.pendingSubmissionEmployees = null;
    vm.enums = {};
    vm.hasClinicLocation = appSession.user.clinicLocationId;
    vm.enums.guaranteeLetterStatus = enumGuaranteeLetterStatus;
    vm.patientCheckInRequiredToViewBalance = abp.features.isEnabled(
      'PatientCheckInRequiredToViewBalance'
    );
    vm.defaultMaxAllowance = parseFloat(abp.setting.get('Hms.General.MaxAllowanceAmountLimit'));
    vm.hasSearchEmployeesForPanelEnhancement = abp.setting.getBoolean(
      'Hms.Feature.SearchEmployeesForPanelEnhancement'
    );
    vm.permissions = {
      canCreateTicket:
        abp.auth.isGranted('PatientTickets.Clinic.Create') ||
        abp.auth.isGranted('SpecialistClaims.Clinic.Create'),
      canViewTickets: abp.auth.isGranted('PatientTickets'),
    };
    vm.clinicNumber = abp.setting.get('Hms.General.ClinicSupportContactNumber');
    vm.hasSpecialistWorkflowEnhancement = abp.setting.getBoolean(
      'Hms.Feature.SpecialistWorkflowEnhancement'
    );

    vm.selectGL = selectGL;
    vm.createTicket = createTicket;
    vm.getUserInitial = getUserInitial;
    vm.limitPeriod = limitPeriod;
    vm.getEmployees = getEmployees;
    vm.getUserBgColor = getUserBgColor;
    vm.createClaim = createClaim;
    vm.toggleCheckIn = toggleCheckIn;
    vm.changeLocation = changeLocation;
    vm.cancel = cancel;
    vm.requestDiscipline = requestDiscipline;
    vm.setActiveTab = setActiveTab;
    vm.getDateTimeString = getDateTimeString;

    vm.$onInit = init;

    function init() {
      commonLookupSvc
        .getDefaultClinicLocation()
        .success((data) => {
          setLocation(data);
        })
        .finally(() => {
          if (!vm.clinic.clinicLocationId) {
            vm.changeLocation();
          } else {
            vm.getEmployees();
          }
        });

      // Get panel tenancy code.

      getTenantCurrencyCode();
      getTimeZoneId();

      let tickerInterval = $interval(tickerJob, 1000);
      $scope.$on('$destroy', () => {
        if (angular.isDefined(tickerInterval)) {
          $interval.cancel(tickerInterval);
          tickerInterval = undefined;
        }
      });
    }
    function tickerJob() {
      if (vm.isSpecialist) {
        tickerCount += 1;
        if (!(tickerCount % 10) && !vm.loading && !isSilentLoading) {
          silentLoad();
        }
      } else {
        _.each(vm.employees, (employee) => {
          if (employee.checkedInTime) {
            const totalSeconds = moment(employee.checkOutTime).diff(moment(), 's');
            const duration = moment.duration(totalSeconds, 's');
            if (totalSeconds <= 0) {
              employee.checkedInTime = null;
              employee.timeRemaining = null;
            } else {
              const seconds = duration.seconds();
              const minutes = duration.minutes();
              const hours = duration.hours();
              const days = duration.days();
              let str = '';
              if (days > 0) str += `${days}d `;
              if (hours > 0) str += `${_.padStart(`${hours}`, 2, '0')}h `;
              str += `${_.padStart(`${minutes}`, 2, '0')}m ${_.padStart(`${seconds}`, 2, '0')}s`;
              employee.timeRemaining = str;
            }
          }
        });
      }
    }

    function processGetEmployeesOutput(filterText, data, callback) {
      const allEmployees = data.items || [];
      vm.showNoRecords = filterText && !allEmployees.length;

      const pendingSubmissions = [];
      const newEmployees = [];

      _.each(allEmployees, (employee) => {
        employee.allowanceDisplay = Math.min(employee.allowance, vm.defaultMaxAllowance);

        if (employee.coPay) {
          if (employee.coPay.isPercentage) {
            employee.coPay.title = App.localize('CopayX', `${employee.coPay.value}%`);
            employee.coPay.text = App.localize(
              'PatientHasCopayPercentage',
              `${employee.coPay.value}%`
            );
          } else {
            const valueText = $filter('currencyFormat')(employee.coPay.value, vm.currencyCode);
            employee.coPay.title = App.localize('CopayX', valueText);
            employee.coPay.text = App.localize('PatientHasCopayFixedAmount', valueText);
          }
        }

        if (
          vm.isSpecialist &&
          employee.checkedInTime &&
          employee.pendingClaimSubmissionGLs &&
          employee.pendingClaimSubmissionGLs.length > 0
        ) {
          const lastPendingClaimSubmissionGL =
            employee.pendingClaimSubmissionGLs[employee.pendingClaimSubmissionGLs.length - 1];
          employee.listingTime =
            lastPendingClaimSubmissionGL.approvalTime < employee.checkedInTime
              ? lastPendingClaimSubmissionGL.approvalTime
              : employee.checkedInTime;

          pendingSubmissions.push(employee);
          const letter = _.find(employee.guaranteeLetters, ['letterNumber', vm.filterText]);
          if (_.camelCase(vm.filterText).toUpperCase() === employee.nationalId || letter) {
            newEmployees.push(employee);
          }
        } else {
          newEmployees.push(employee);
        }
      });

      _.orderBy(pendingSubmissions, ['listingTime'], ['desc']);

      vm.employees = newEmployees;
      vm.pendingSubmissionEmployees = pendingSubmissions;
      if (_.isFunction(callback)) callback();
    }

    function setActiveTab(activeTabIndex) {
      vm.active = parseInt(activeTabIndex, 10);
    }

    function silentLoad() {
      if (vm.loading || isSilentLoading) return;

      isSilentLoading = 1;

      if (vm.hasSearchEmployeesForPanelEnhancement) {
        clinicEmployeeSvc
          .getEmployeesByPanelType(
            {
              filter: vm.filterText,
              clinicLocationId: vm.clinic.clinicLocationId,
            },
            {
              abpHandleError: false,
            }
          )
          .success((data) => {
            processGetEmployeesOutput(vm.filterText, data);
          })
          .finally(() => {
            tickerCount = 0;
            isSilentLoading = 0;
          });
      } else {
        clinicEmployeeSvc
          .getEmployeesByNationalId(
            {
              filter: vm.filterText,
              clinicLocationId: vm.clinic.clinicLocationId,
            },
            {
              abpHandleError: false,
            }
          )
          .success((data) => {
            processGetEmployeesOutput(vm.filterText, data);
          })
          .finally(() => {
            tickerCount = 0;
            isSilentLoading = 0;
          });
      }
    }

    function limitPeriod(employee) {
      let cycle = '';
      if (employee.allowancePeriod) {
        switch (employee.allowancePeriod.cycle) {
          case 0:
            cycle = App.localize('EveryXYears', employee.allowancePeriod.interval);
            break;
          case 1:
            cycle = App.localize('Yearly');
            break;
          case 2:
            cycle = App.localize('Monthly');
            break;
          case 3:
            cycle = App.localize('Daily');
            break;
          case 4:
            cycle = App.localize('PerVisit');
            break;
          case 5:
            cycle = App.localize('Every6Months');
            break;
          case 6:
            cycle = App.localize('Every4Months');
            break;
          case 7:
            cycle = App.localize('Every3Months');
            break;
          case 8:
            cycle = App.localize('Every2Months');
            break;
          default:
        }
        cycle = cycle.toLowerCase();
      }
      return cycle;
    }
    function getEmployees(successCallback) {
      $state.transitionTo(
        $state.current,
        {
          filterText: vm.filterText,
        },
        {
          notify: false,
        }
      );

      vm.loading += 1;
      if (vm.hasSearchEmployeesForPanelEnhancement) {
        clinicEmployeeSvc
          .getEmployeesByPanelType({
            filter: vm.filterText,
            clinicLocationId: vm.clinic.clinicLocationId,
          })
          .success((data) => {
            processGetEmployeesOutput(vm.filterText, data, successCallback);
          })
          .finally(() => {
            tickerCount = 0;
            vm.loading -= 1;
          });
      } else {
        clinicEmployeeSvc
          .getEmployeesByNationalId({
            filter: vm.filterText,
            clinicLocationId: vm.clinic.clinicLocationId,
          })
          .success((data) => {
            processGetEmployeesOutput(vm.filterText, data, successCallback);
          })
          .finally(() => {
            tickerCount = 0;
            vm.loading -= 1;
          });
      }
    }

    /* Employee icon */

    function getUserBgColor(id) {
      return `user_bgcolor_${((id || 0) % 8) + 1}`;
    }

    function getUserInitial(user) {
      const fullName = (user.name || ' ').split(' ');
      let initial = '';
      for (let i = 0; i < fullName.length; i += 1) {
        if (fullName[i].length) {
          initial += fullName[i][0];
        }
      }
      if (initial && initial.length > 3) initial = `${initial[0]}`;
      return initial;
    }

    /* End of employee icon */

    function createTicket(id, checkedInTime) {
      checkDuplicate(id, checkedInTime, () => {
        createTicketCore(id);
      });
    }

    function createTicketCore(id) {
      $state.go('createEditPatientTicket', {
        employeeId: id,
        clinicLocationId: vm.clinic.clinicLocationId,
      });
    }

    function checkDuplicate(id, checkInTime, callback) {
      vm.loading += 1;
      patientTicketSvc
        .hasDuplicateTicket({
          employeeId: id,
          clinicLocationId: vm.clinic.clinicLocationId,
          ticketDate: checkInTime,
        })
        .success((data) => {
          if (data.hasDuplicateTicket) {
            abp.message.confirm(
              App.localize('PossibleDuplicateTicketWarning'),
              App.localize('AreYouSure'),
              (d) => {
                if (d) callback();
              }
            );
          } else {
            callback();
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function createClaim(employeeId, letterNumber, claimWithTopUp) {
      if (vm.hasSpecialistWorkflowEnhancement) {
        $state.go('createEditSpecialistFinalRequest', {
          letterNumber,
          claimWithTopUp,
          timeZoneId: vm.timeZoneId
        });
      } else {
        $state.go('createEditSpecialistClaim', {
          employeeId,
          panelLocationId: vm.clinic.clinicLocationId,
          letterNumber,
          claimWithTopUp,
          timeZoneId: vm.timeZoneId
        });
      }
    }

    /* Check-in operations */

    // Select GL from list for check in.

    function selectGL(employee) {
      employee.guaranteeLetters = _.filter(employee.guaranteeLetters, (k) => k.status !== 3);
      $uibModal
        .open({
          templateUrl: require('./checkInModal.html'),
          controller: 'clinic.views.employees.checkInModal as vm',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            employee() {
              return employee;
            },
            clinic() {
              return vm.clinic;
            },
          },
        })
        .result.then((letterNumber) => {

          // Check in using this letter.

          if (letterNumber) {
            employee.guaranteeLetters = _.find(employee.guaranteeLetters, [
              'letterNumber',
              letterNumber,
            ]);
            checkIn(employee);
          } else {
            applyGuaranteeLetter(employee);
          }
        });
    }

    // Toggle employee check-in.

    function toggleCheckIn(employee) {
      if (vm.loading) return;

      if (!employee.checkedInTime) {
        if (vm.isSpecialist && employee.guaranteeLetters.length === 0) {
          applyGuaranteeLetter(employee);
        } else {
          checkIn(employee);
        }
      }
    }

    function checkIn(employee) {
      vm.loading += 1;
      const letterNumber = vm.isSpecialist ? employee.guaranteeLetters.letterNumber : '';
      clinicEmployeeSvc
        .checkIn({
          employeeId: employee.id,
          clinicLocationId: vm.clinic.clinicLocationId,
          guaranteeLetterNumber: letterNumber,
        })
        .success((data) => {
          getCheckInDetail(data, employee);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getCheckInDetail(id, employee) {
      vm.loading += 1;
      clinicEmployeeSvc
        .getCheckInDetail({
          id,
        })
        .success((data) => {
          if (vm.isSpecialist) {
            vm.getEmployees();
          } else {
            employee.checkedInTime = data.checkedInTime;
            employee.checkOutTime = data.checkOutTime;

            if (data.allowance > 0) {
              employee.allowance = data.allowance;
              employee.allowanceDisplay = Math.min(employee.allowance, vm.defaultMaxAllowance);
            }

            promptCheckInSuccessMessage(employee);
            abp.notify.info(App.localize('PatientXCheckedIn', employee.name));
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function checkOut(employee, uniqueId) {
      vm.loading += 1;
      clinicEmployeeSvc
        .checkOut({
          employeeId: employee.id,
          clinicLocationId: vm.clinic.clinicLocationId,
          cancellationRemarks: employee.cancellationRemarks,
          guaranteeLetterUniqueId: uniqueId,
        })
        .success(() => {
          vm.getEmployees();
          abp.notify.info(App.localize('PatientXCheckedOut', employee.name));
          swal.close();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // Apply guarantee letter,upload attachment and check in.

    function applyGuaranteeLetter(employee) {
      $uibModal
        .open({
          templateUrl: require('./applyGuaranteeLetterModal/index.html'),
          controller: 'clinic.views.employees.applyGuaranteeLetterModal.index as vm',
          backdrop: 'static',
          resolve: {
            clinic() {
              return vm.clinic;
            },
            employee() {
              return employee;
            },
          },
        })
        .result.then(() => {
          vm.getEmployees(() => {
            const refreshedEmployee = _.find(vm.employees, ['id', employee.id]);
            refreshedEmployee.guaranteeLetters = _.first(
              _.orderBy(refreshedEmployee.guaranteeLetters, ['creationTime'], ['desc'])
            );
            checkIn(refreshedEmployee);
          });
          abp.message.success(
            App.localize('GlPostSubmissionNarrativeX', employee.supportNumber),
            App.localize('WhatHappensNext')
          );
        });
    }

    /* End of check-in operations */

    function changeLocation() {
      lookupModal.open({
        title: App.localize('SelectPanelLocation'),
        serviceMethod: commonLookupSvc.findClinicLocations,
        callback(location) {
          setLocation(location);

          vm.getEmployees();
        },
      });
    }

    function setLocation(location) {
      vm.clinic = {};

      vm.clinic.clinicLocationId = parseInt(location.value, 10);
      vm.clinic.name = location.name;
      vm.clinic.address = location.address;
      vm.clinic.contactNumber = location.contactNumber;
      vm.clinic.ianaTimeZoneId = location.ianaTimeZoneId;

      if (vm.isSpecialist) {
        vm.loading += 1;
        clinicEmployeeSvc
          .getLocationDisciplines({
            id: vm.clinic.clinicLocationId,
          })
          .success((data) => {
            vm.clinic.disciplines = data.items;
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    }

    function cancel(employee, uniqueId) {
      $uibModal
        .open({
          templateUrl: require('./checkOutModal.html'),
          controller: 'clinic.views.employees.checkOutModal as vm',
          backdrop: 'static',
        })
        .result.then((reason) => {
          if (reason) {
            employee.cancellationRemarks = reason;
            checkOut(employee, uniqueId);
          }
        });
    }

    function requestDiscipline() {
      swal({
        title: App.localize('RequestDisciplineTitle'),
        text: App.localize('TawkNotificationMessage'),
        type: 'warning',
      });

      if (typeof zE !== 'undefined') {
        zE('webWidget', 'open');
      }
    }

    /* Functions */

    function promptCheckInSuccessMessage(employee) {
      function getMessage() {
        const titles = [App.localize('CheckedIn')];
        const msgs = [];
        if (!employee.allowance) {
          if (!employee.canAccess) {
            msgs.push(App.localize('PatientUnableToMakeCashlessClaim'));
            msgs.push(App.localize('CheckInSuccessCollectCashNarrative'));
            return {
              titles,
              msgs,
            };
          }
          msgs.push(App.localize('PatientMustPayCash'));
        } else {
          if (employee.allowance <= 30) {
            msgs.push(App.localize('PatientLowLimitAndPayExcess'));
          }

          if (employee.coPay) {
            titles.push(employee.coPay.title);
            msgs.push(employee.coPay.text);
          }
        }

        if (employee.isPreEmployee) msgs.push(App.localize('PreEmployeeCheckInSuccessNarrative'));
        else {
          msgs.push(App.localize('CheckInSuccessNarrative'));
          msgs.push(App.localize('CheckInSuccessNarrative2'));
        }
        return {
          titles,
          msgs,
        };
      }

      const message = getMessage();
      const title = _.join(message.titles || [], '<br />');
      const text = `${App.localize(
        'CheckInSuccessMessage'
      )}<br /><br /><b class="font-uppercase">${_.toUpper(
        App.localize('Important')
      )}:</b><br />${_.join(message.msgs || [], ' ')}`;

      window.onbeforeunload = () => title;
      swal(
        {
          title,
          type: 'success',
          text,
          showConfirmButton: true,
          confirmButtonText: App.localize('Understood'),
          html: true,
        },
        () => {
          window.onbeforeunload = null; // unregister
        }
      );
      App.swal.disableButtons(5);
    }

    function getTenantCurrencyCode() {
      vm.loading += 1;
      commonLookupSvc
        .getTenantCurrencyCode()
        .success((data) => {
          vm.currencyCode = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getDateTimeString(date) {
      return App.getDateTimeString(date, vm.clinic.ianaTimeZoneId);
    }

    function getTimeZoneId() {
      vm.loading += 1;
      commonLookupSvc.getTenantIanaTimeZoneId({})
        .success((data) => {
          vm.timeZoneId = data;
        })
        .finally(() => {
          vm.loading -= 1;
        })
    }

    /* End of Functions */
  }
})();
