(function () {
    var controllerId = 'clinic.views.employees.checkInModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'employee', 'clinic', '$sce',
        function ($scope, $uibModalInstance, employee, clinic, $sce) {
            var vm = this;
            vm.employee = employee;
            vm.clinic = clinic;
            vm.disciplineNames = '';
            vm.answerInput = '';
            vm.selectedGL = null;

            var confirmationAnswer = '';

            // Select GL.
            vm.selectGL = function (letterNumber) {
                vm.selectedGL = _.find(vm.employee.guaranteeLetters, ['letterNumber', letterNumber]);
                vm.letterNumber = letterNumber;
                vm.disciplineNames = vm.selectedGL.coveredDisciplineNames.join(", ");

                confirmationAnswer = _.trim(_.first(vm.selectedGL.coveredDisciplineNames));
                vm.confirmationNarrative = $sce.trustAsHtml(App.localize('ConfirmGuaranteeLetterNarrative', confirmationAnswer));
            };

            vm.confirm = function () {
                return vm.answerInput.toUpperCase() === confirmationAnswer.toUpperCase();
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            vm.back = function () {
                vm.selectedGL = null;
            };

            vm.applyGL = function () {
                $uibModalInstance.close();
            };

            vm.save = function () {
                if (vm.confirm()) {
                    abp.message.confirm(App.localize('CheckInWithGuaranteeLetterWarningMessage', vm.selectedGL.letterNumber), App.localize('AreYouSure'), function (result) {
                        if (result) {
                            abp.notify.success(App.localize('SuccessfullySaved'));
                            $uibModalInstance.close(vm.selectedGL.letterNumber);
                        }
                    });
                }
            };
        }
    ]);
})();
