// Require the auth module implementation.
import authentication from '../auth/auth';

(() => {
  angular
    .module('hms-ng-auth', [])
    .service('authentication', authenticationService)
    .constant('getUser', authentication.getUser);

  authenticationService.$inject = [];

  function authenticationService() {
    init();

    return authentication;

    function init() {
      authentication.userManager.events.addAccessTokenExpired(() => {
        authentication.refreshAccessToken();
      });

      authentication.userManager.events.addAccessTokenExpiring(() => {
        authentication.refreshAccessToken();
      });

      authentication.userManager.events.addSilentRenewError((e) => {
        console.error('Failed to refresh access token.', e);
      });

      authentication.userManager.events.addUserLoaded(async () => {
        const user = await authentication.getUser();

        updateBearerToken(user.access_token);
      });

      authentication.userManager.events.addUserSignedOut(() => {
        // Get the current user's ID
        const userId = authentication.userManager.getUser().id;

        // Revoke any refresh tokens associated with the user's session
        authentication.userManager.revokeRefreshToken(userId).then(() => {
          authentication.userManager.clearStaleState();
        });
      });
    }

    function updateBearerToken(accessToken) {
      $.ajaxSetup({
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Abp.Localization.CultureName': abp.utils.getCookieValue('Abp.Localization.CultureName'),
        },
      });
    }
  }
})();
