import swal from 'sweetalert';

(() => {
  angular.module('app').component('revenueSubscriptionsDetailTransactionHistoryComponent', {
    templateUrl: require('./transactionHistory.component.html'),
    controller: TransactionHistoryController,
    controllerAs: 'vm',
    bindings: {
      subscription: '<',
    },
  });

  TransactionHistoryController.$inject = [
    'abp.services.revenue.subscription',
    'abp.services.app.saleTransaction',
  ];

  function TransactionHistoryController(subscriptionSvc, saleTransactionSvc) {
    const vm = this;

    vm.transactionHistory = [];

    vm.getTransactionHistory = getTransactionHistory;
    vm.sendEmail = sendEmail;

    init();

    function init() {
      getTransactionHistory();
    }

    function getTransactionHistory() {
      vm.loading += 1;
      subscriptionSvc
        .getTransactionHistoryBySubscriptionId({
          id: vm.subscription.id,
        })
        .success((data) => {
          vm.transactionHistory = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function sendEmail(transactionHistory) {
      subscriptionSvc
        .getFinanceEmailsByTransactionNumber({
          transactionNumber: transactionHistory.transactionNumber
        })
        .success((data) => {
          swal(
            {
              title: App.localize('SendEmailForX', transactionHistory.transactionNumber),
              text: App.localize('EmailAddresses'),
              type: 'input',
              showCancelButton: true,
              closeOnConfirm: false,
              confirmButtonColor: '#1ab394',
              inputPlaceholder: App.localize('EmailAddressesNarrative'),
              inputValue: data.emailAddresses,
              showLoaderOnConfirm: true,
            },
            (inputValue) => {
              if (inputValue === false) return false;
              if (inputValue === '') {
                swal.showInputError(App.localize('InvalidEmailAddress'));
                return false;
              }
              saleTransactionSvc
                .sendEmail({
                  transactionNumber: transactionHistory.transactionNumber,
                  emailAddresses: inputValue,
                })
                .success(() => {
                  abp.notify.info(App.localize('EmailSent'));
                  swal.close();
                });
              return false;
            }
          );
        });
    }
  }
})();
