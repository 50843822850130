(() => {
  angular
    .module('app')
    .controller('host.views.userAgreements.createOrEdit', UserAgreementPageController);

  UserAgreementPageController.$inject = [
    '$scope',
    '$stateParams',
    '$timeout',
    'abp.services.app.userAgreementVersion',
    'languages',
    '$state',
  ];

  function UserAgreementPageController(
    $scope,
    $stateParams,
    $timeout,
    versionSvc,
    languages,
    $state
  ) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;
    vm.versionId = $stateParams.versionId;
    vm.isCreate = !vm.versionId.trim();
    vm.version = {
      localizedUserAgreements: [],
    };
    vm.versionTags = [];
    vm.languages = languages;
    vm.hasIndonesiaAndThailandRegionalExpansion = abp.setting.getBoolean(
      'Hms.Feature.IndonesiaAndThailandRegionalExpansion'
    );
    vm.existingContentsCount = 0;

    vm.disableEditor = disableEditor;
    vm.addContent = addContent;
    vm.getContentHeader = getContentHeader;
    vm.removeContent = removeContent;
    vm.filterLanguages = filterLanguages;
    vm.save = save;
    vm.getPharmacySalesSettings = getPharmacySalesSettings;

    init();

    function init() {
      getUserAgreementVersion();
      getPharmacySalesSettings();

      // inject markdown-input with style class

      $timeout(() => {
        angular.element('.markdown-toolbar button').addClass('btn btn-sm btn-default');
        angular.element('.markdown-input').addClass('form-control');
      });
    }

    function getUserAgreementVersion() {
      if (vm.isCreate) {
        addContent();
      } else {
        vm.loading += 1;
        versionSvc
          .getVersionForEdit({
            id: vm.versionId,
          })
          .success((data) => {
            vm.version = data;
            vm.versionTags = vm.version.tags;
            vm.existingContentsCount = vm.version.localizedUserAgreements.length;

            if (!vm.hasIndonesiaAndThailandRegionalExpansion) {
              // disable markdown editor while status is not 'Draft'

              angular.element('.markdown-input').attr('disabled', vm.version.status !== 0);
              angular.element('.markdown-toolbar button').attr('disabled', vm.version.status !== 0);
            }
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    }

    function validateForm() {
      const form = $scope.versionForm;
      App.touchFormErrors(form);
      return form.$valid;
    }

    function disableEditor() {
      // Manually adding back the missing class for wiz-markdown-editor component due
      // to its styling not working properly with ng-repeat.

      angular.element('.markdown-input').addClass('form-control');

      // Disable markdown editor for existing content while status is not 'Draft'.

      for (let i = 0; i < vm.version.localizedUserAgreements.length; i += 1) {
        if (i < vm.existingContentsCount) {
          angular.element(`#editor-${i} .markdown-input`).attr('disabled', vm.version.status !== 0);
          angular
            .element(`#editor-${i} .markdown-toolbar button`)
            .attr('disabled', vm.version.status !== 0);
        }
      }
    }

    function addContent() {
      vm.version.localizedUserAgreements.push({});
    }

    function getLanguageByCode(languageCode) {
      return _.find(vm.languages, { code: languageCode });
    }

    function getContentHeader(i) {
      const selectedLanguageCode = vm.version.localizedUserAgreements[i].languageCode;

      if (selectedLanguageCode) return getLanguageByCode(selectedLanguageCode).name;

      return `${App.localize('Content')} #${i + 1}`;
    }

    function removeContent(i) {
      vm.version.localizedUserAgreements.splice(i, 1);
    }

    function filterLanguages(i) {
      const selectedLanguageCodes = [];

      _.forEach(vm.version.localizedUserAgreements, (value, index) => {
        if (index === i || !value.languageCode) return;

        selectedLanguageCodes.push(value.languageCode);
      });

      return _.filter(vm.languages, (x) => !selectedLanguageCodes.includes(x.code));
    }

    function save() {
      if (!vm.hasIndonesiaAndThailandRegionalExpansion) {
        const forwardCompatibleContent = vm.version.localizedUserAgreements[0];
        forwardCompatibleContent.languageCode = 'en';
        forwardCompatibleContent.content = vm.version.content;
      }

      if (validateForm()) {
        vm.saving += 1;
        vm.version.tags = _.map(vm.versionTags, (d) => d.text);

        if (vm.version.category !== 3)
            vm.version.pharmacySalesSettingId = null;

        if (vm.isCreate) {
          versionSvc
            .createAsync(vm.version)
            .success(() => {
              abp.notify.success(App.localize('SuccessfullySaved'));
              $state.go('host.userAgreements');
            })
            .finally(() => {
              vm.saving -= 1;
            });
        } else {
          versionSvc
            .updateAsync(vm.version)
            .success(() => {
              abp.notify.success(App.localize('SuccessfullySaved'));
              $state.go('host.userAgreements');
            })
            .finally(() => {
              vm.saving -= 1;
            });
        }
      } else {
        abp.notify.error(App.localize('GeneralInvalidFormInputError'));
      }
    }

    function getPharmacySalesSettings() {
      vm.loading += 1;
      versionSvc
        .getPharmacySalesSettings()
        .success((data) => {
          vm.pharmacySalesSettings = data;
        })
        .finally(() => {
          vm.loading -= 1;
        })
    }
  }
})();
