import swal from 'sweetalert';

(() => {
  angular.module('app').controller('postHospitalizationIglProcess', IglProcessingPageController);

  IglProcessingPageController.$inject = [
    '$state',
    '$stateParams',
    '$uibModal',
    'abp.services.app.hostServiceRequest',
    'abp.services.app.commonLookup',
    'abp.services.app.masterPolicy',
    'appConstants'
  ];

  function IglProcessingPageController(
    $state,
    $stateParams,
    $modal,
    hostServiceRequestSvc,
    commonLookupSvc,
    masterPolicySvc,
    appConstants
  ) {
    const vm = this;
    vm.loading = 0;
    vm.isEdit = false;
    vm.guaranteeLetterNumber = $stateParams.letterNumber;
    vm.serviceRequestId = $stateParams.requestId;
    vm.serviceType = $stateParams.serviceType;
    vm.requestDetailsTrigger = 0;
    vm.memberId = $stateParams.memberId;
    vm.isOnHold = true;
    vm.requestType = '';
    vm.requestStatus = '';
    vm.newGuaranteeAmount = 0;
    vm.isInsuranceBenefitGroup = false;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    let delegateUsers = [];
    let recommendUsers = [];

    vm.openApprovalModal = openApprovalModal;
    vm.openRejectionModal = openRejectionModal;
    vm.openDelegationModal = openDelegationModal;
    vm.openRecommendationModal = openRecommendationModal;
    vm.query = query;
    vm.preview = preview;
    vm.checkEditMode = checkEditMode;
    vm.checkSaveTrigger = checkSaveTrigger;
    vm.openEscalationModal = openEscalationModal;

    init();

    function init() {
      hostServiceRequestSvc
        .getServiceRequestInfo({
          id: vm.serviceRequestId,
        })
        .success((data) => {
          vm.isOnHold = data.isOnHold;
          vm.requestType = data.type;
          vm.requestStatus = data.status;
          vm.printUrl = data.printUrl;
          vm.hasUnresolvedQuery = data.hasUnresolvedQuery;
          vm.escalationRemarks = data.escalationRemarks;
          vm.currencyCode = data.currencyCode;
          vm.timeZoneId = data.ianaTimeZoneId;
          vm.letterNumber = data.letterNumber;
          vm.serviceType = data.serviceType;
        });

      vm.loading += 1;
      masterPolicySvc
        .getMasterPolicyDetailByPatientId({
          id: vm.memberId,
        })
        .success((data) => {
          vm.isInsuranceBenefitGroup = data != null;
          if (!data) return;

          vm.data = data.masterPolicy;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function approve(remarks) {
      vm.loading += 1;
      hostServiceRequestSvc
        .approvePostHospitalizationIglRequest({
          id: vm.serviceRequestId,
          remarks,
        })
        .success(() => {
          abp.notify.success(App.localize('Approved'));
          navigateToListing();
        })
        .error((data) => {
          /* Error code 1 == Insufficient allowance */
          if (data.code === 1) {
            /* Insufficient employee balance for request amount */
            swal(
              {
                title: App.localize('InvalidAmount'),
                text: `${App.localize('InsufficientEmployeeBalanceNarrative')}\n${App.localize(
                  'RefreshThePageAndEditTheAmount'
                )}`,
                confirmButtonColor: '#1AB394',
                confirmButtonText: App.localize('RefreshNow'),
              },
              (isConfirm) => {
                if (isConfirm) {
                  $state.reload();
                }
              }
            );
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function reject(remarks) {
      vm.loading += 1;
      hostServiceRequestSvc
        .rejectGuaranteeLetterServiceRequest({
          id: vm.serviceRequestId,
          letterNumber: vm.guaranteeLetterNumber,
          rejectionReason: remarks,
        })
        .success(() => {
          abp.notify.success(App.localize('Rejected'));
          navigateToListing();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function query() {
      if (!vm.isEdit) {
        if (vm.hasUnresolvedQuery) {
          $state.go('postHospitalizationGuaranteeLetterDetail', {
            letterNumber: vm.guaranteeLetterNumber,
            timeZoneId: vm.timeZoneId,
            serviceType: vm.serviceType,
            activeTab: 5
          });
        } else {
          $state.go('createPostHospitalizationQuery', {
            letterNumber: vm.guaranteeLetterNumber,
            timeZoneId: vm.timeZoneId,
          });
        }
      } else {
        promptEditModeWarning();
      }
    }

    function delegate(recipient, remarks) {
      vm.loading += 1;
      hostServiceRequestSvc
        .delegateRequest({
          processorUserId: recipient.operatorUserId,
          serviceRequestId: vm.serviceRequestId,
          letterNumber: vm.guaranteeLetterNumber,
          emailAddress: recipient.operatorEmailAddress,
          remarks,
        })
        .success(() => {
          abp.notify.success(App.localize('ServiceRequestDelegated'));
          navigateToListing();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function recommend(recipient, remarks) {
      vm.loading += 1;
      hostServiceRequestSvc
        .recommendRequest({
          processorUserId: recipient.operatorUserId,
          serviceRequestId: vm.serviceRequestId,
          letterNumber: vm.guaranteeLetterNumber,
          emailAddress: recipient.operatorEmailAddress,
          remarks,
        })
        .success(() => {
          abp.notify.success(App.localize('ServiceRequestRecommended'));
          navigateToListing();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function preview() {
      window.open(vm.printUrl);
    }

    function openApprovalModal() {
        if (!vm.isEdit) {
          $modal
            .open({
              templateUrl: require('../../../modals/remarks/remarks.modal.html'),
              controller: 'common.views.modals.remarksModal as vm',
              backdrop: 'static',
              resolve: {
                title() {
                  return App.localize('ApprovalConfirmation');
                },
                existingRemarks() {
                  return vm.escalationRemarks;
                },
                remarksMaxLength() {
                  return appConstants.serviceRequestMaxLength;
                },
              },
            })
            .result.then((remarks) => {
              approve(remarks);
            });
        } else {
          promptEditModeWarning();
        }
    }

    function openRejectionModal() {
      if (!vm.isEdit) {
        $modal
          .open({
            templateUrl: require('../../../modals/remarks/remarks.modal.html'),
            controller: 'common.views.modals.remarksModal as vm',
            backdrop: 'static',
            resolve: {
              title() {
                return App.localize('RejectionConfirmation');
              },
              existingRemarks() {
                return vm.escalationRemarks;
              },
              remarksMaxLength() {
                return appConstants.serviceRequestMaxLength;
              },
            },
          })
          .result.then((remarks) => {
            reject(remarks);
          });
      } else {
        promptEditModeWarning();
      }
    }

    function openDelegationModal() {
      getServiceRequestClaimProcessors(() => {
        if (!vm.isEdit) {
          $modal
            .open({
              templateUrl: require('../../../modals/notifyRecipient/notifyRecipient.modal.html'),
              controller: 'common.views.modals.notifyRecipientModal as vm',
              backdrop: 'static',
              resolve: {
                users() {
                  return delegateUsers;
                },
                title() {
                  return App.localize('DelegationConfirmation');
                },
                message() {
                  return App.localize('DelegationWarningMessage');
                },
              },
            })
            .result.then((result) => {
              delegate(result.recipient, result.remarks);
            });
        } else {
          promptEditModeWarning();
        }
      });
    }

    function openRecommendationModal() {
      getServiceRequestOverridingApprovers(() => {
        if (!vm.isEdit) {
          $modal
            .open({
              templateUrl: require('../../../modals/notifyRecipient/notifyRecipient.modal.html'),
              controller: 'common.views.modals.notifyRecipientModal as vm',
              backdrop: 'static',
              resolve: {
                users() {
                  return recommendUsers;
                },
                title() {
                  return App.localize('RecommendationConfirmation');
                },
                message() {
                  return App.localize('RecommendationWarningMessage');
                },
              },
            })
            .result.then((result) => {
              recommend(result.recipient, result.remarks);
            });
        } else {
          promptEditModeWarning();
        }
      });
    }

    function checkEditMode(isEdit) {
      vm.isEdit = isEdit;
    }

    function checkSaveTrigger() {
      vm.requestDetailsTrigger += 1;
    }

    function promptEditModeWarning() {
      abp.message.error(App.localize('SaveOrCancelChangesNarrative'));
    }

    function navigateToListing() {
      $state.go('postHospitalizationGuaranteeLetters');
    }

    function getServiceRequestClaimProcessors(_callback) {
      vm.loading += 1;

      commonLookupSvc
        .getServiceRequestClaimProcessor()
        .success((data) => {
          // Filter away current user
          delegateUsers = _.remove(
            data.items,
            (n) => n.value.operatorUserId !== abp.session.userId
          );
        })
        .finally(() => {
          vm.loading -= 1;
          _callback();
        });
    }

    function getServiceRequestOverridingApprovers(_callback) {
      vm.loading += 1;

      commonLookupSvc
        .getServiceRequestOverridingApprover()
        .success((data) => {
          // Filter away current user
          recommendUsers = _.remove(
            data.items,
            (n) => n.value.operatorUserId !== abp.session.userId
          );
        })
        .finally(() => {
          vm.loading -= 1;
          _callback();
        });
    }

    function openEscalationModal() {
      if (!vm.isEdit) {
        $modal
          .open({
            templateUrl: require('../../../modals/remarks/remarks.modal.html'),
            controller: 'common.views.modals.remarksModal as vm',
            backdrop: 'static',
            resolve: {
              title() {
                return App.localize('EscalationConfirmation');
              },
              existingRemarks() {
                return vm.escalationRemarks;
              },
              remarksMaxLength() {
                return appConstants.serviceRequestMaxLength;
              },
            },
          })
          .result.then((remarks) => {
            escalate(remarks);
          });
      } else {
        promptEditModeWarning();
      }
    }

    function escalate(remarks) {
      vm.loading += 1;
      hostServiceRequestSvc
        .escalateGuaranteeLetterRequest({
          id: vm.serviceRequestId,
          isResolve: true,
          escalationRemark: remarks,
        })
        .success(() => {
          abp.notify.success(App.localize('GlEscalated'));
          navigateToListing();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
