(() => {
  angular.module('app').filter('employeeType', employeeType);

  employeeType.$inject = [];

  function employeeType() {
    return employeeTypeFilter;

    function employeeTypeFilter(value) {
      switch (value) {
        case 0:
          return App.localize('Employee');
        case 1:
          return App.localize('Dependent');
        case 2:
          return App.localize('PreEmployee');
        default:
          return value;
      }
    }
  }
})();
