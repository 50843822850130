/* globals zE */
import swal from 'sweetalert';

(() => {
  angular.module('app').component('clinicPatientCheckInWidget', {
    templateUrl: require('./checkIn.component.html'),
    controller: CheckInController,
    controllerAs: 'vm',
    bindings: {},
  });

  CheckInController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$filter',
    'abp.services.app.clinicEmployee',
    'abp.services.app.commonLookup',
    'appSession',
    'moment',
  ];

  function CheckInController(
    $scope,
    $state,
    $stateParams,
    $filter,
    clinicEmployeeSvc,
    commonLookupSvc,
    appSession,
    moment
  ) {
    const vm = this;
    const storageKey = 'panelDashboard_user';

    let hasDependentFound = false;
    vm.hasClinicLocation = appSession.user.clinicLocationId;
    vm.cachePanelUser = App.getStorageJsonObj(storageKey);
    vm.selectedLocationId = vm.cachePanelUser.defaultLocationId;
    vm.loading = 0;
    vm.filterText = $stateParams.filterText || '';
    vm.showNoRecords = 0;
    vm.pendingSubmissionCount = 0;
    vm.searched = false;
    vm.tenantGroups = [];
    vm.employeeFoundMessage = '';
    vm.panelLocationTimeZone = '';
    vm.defaultMaxAllowance = parseFloat(abp.setting.get('Hms.General.MaxAllowanceAmountLimit'));
    vm.hasSearchEmployeesForPanelEnhancement = abp.setting.getBoolean(
      'Hms.Feature.SearchEmployeesForPanelEnhancement'
    );
    vm.pendingSubmissionPageName = abp.features.isEnabled('ClinicModule.PanelDashboard')
      ? 'clinic.panelDashboard'
      : 'clinic.checkInReport({clinicLocationId: vm.clinic.clinicLocationId})';
    vm.patientCheckInRequiredToViewBalance = abp.features.isEnabled(
      'PatientCheckInRequiredToViewBalance'
    );
    vm.permissions = {
      canCreateTicket: abp.auth.isGranted('PatientTickets.Clinic.Create'),
      canViewTickets: abp.auth.isGranted('PatientTickets'),
    };

    vm.selectLocation = selectLocation;
    vm.getAllowanceDisplay = getAllowanceDisplay;
    vm.getEmployees = getEmployees;
    vm.findClinicLocations = findClinicLocations;
    vm.requestDiscipline = requestDiscipline;
    vm.checkIn = checkIn;

    vm.$onInit = init;

    function init() {
      getTenantCurrencyCode();
      vm.findClinicLocations();
      commonLookupSvc
        .getDefaultClinicLocation()
        .then((response) => {
          const { data } = response;
          setLocation(data);
          vm.patientNotFoundMessage = App.localize(
            'PatientNotFoundMessage3',
            data.accountOwnerContactNumber
          );
        })
        .finally(() => {
          if (vm.clinic.clinicLocationId) {
            getPendingSubmissionCount();
          }
        });
    }

    function processGetEmployeesOutput(filterText, data, callback) {
      const maxDisplayNameLength = 10;

      const allEmployees = data || [];
      vm.showNoRecords = filterText && !allEmployees.length;

      const newEmployee = {
        dependents: [],
        isPreEmployee: false,
      };

      _.each(allEmployees, (employee) => {
        employee.allowanceDisplay = getAllowanceDisplay(
          employee.allowance,
          employee.allowancePeriod
        );

        if (employee.coPay) {
          if (employee.coPay.isPercentage) {
            employee.coPay.title = App.localize('CopayX', `${employee.coPay.value}%`);
            employee.coPay.text = App.localize(
              'PatientHasCopayPercentage',
              `${employee.coPay.value}%`
            );
          } else {
            const valueText = $filter('currencyFormat')(employee.coPay.value, vm.currencyCode);
            employee.coPay.title = App.localize('CopayX', valueText);
            employee.coPay.text = App.localize('PatientHasCopayFixedAmount', valueText);
          }
        }

        employee.displayName = employee.name.substring(0, maxDisplayNameLength);

        if (employee.principalId)
          employee.displayPrincipalName = employee.principalName.substring(0, maxDisplayNameLength);

        switch (employee.type) {
          case 0:
            // HM-6630 Hack: Assign searched account as principal.
            // If there is no main account, assign any principal account as principal.
            // If main account is found, assign as principal,
            // and previous principal is pushed into dependants.

            if (newEmployee.principal && !isDiff(employee.nationalId, filterText)) {
              newEmployee.dependents.push(newEmployee.principal);

              newEmployee.principal = employee;
            }

            if (!newEmployee.principal) {
              newEmployee.principal = employee;
            } else {
              newEmployee.dependents.push(employee);
            }
            break;
          case 1:
            newEmployee.dependents.push(employee);
            hasDependentFound = true;
            break;
          case 2:
            // HM-6630 Hack: Assign the searched account as principal.
            // If pre-employee is found, assign it as the principal
            // and push the previous principal into dependants.

            if (newEmployee.principal) newEmployee.dependents.push(employee);

            newEmployee.principal = employee;
            newEmployee.isPreEmployee = true;
            break;
          default:
            break;
        }
      });

      // Principal is always at first tab
      newEmployee.targetIndex = isDiff(newEmployee.principal.nationalId, filterText)
        ? _.findIndex(newEmployee.dependents, (d) => !isDiff(d.nationalId, filterText)) + 1
        : 0;

      vm.tenantGroups.push(newEmployee);

      if (_.isFunction(callback)) callback();
    }

    function getEmployees(successCallback) {
      // Reset values

      vm.tenantGroups = [];
      hasDependentFound = false;

      // Get employees

      const { filterText } = vm;
      $state.transitionTo(
        $state.current,
        {
          filterText,
        },
        { notify: false }
      );

      vm.loading += 1;
      if (vm.hasSearchEmployeesForPanelEnhancement) {
        clinicEmployeeSvc
          .getEmployeesForGP({
            filter: filterText,
            clinicLocationId: vm.selectedLocationId,
          })
          .then((response) => {
            const { data } = response;
            processData(data, filterText, successCallback);
          })
          .finally(() => {
            vm.loading -= 1;
          });
      } else {
        clinicEmployeeSvc
          .getPanelEmployees({
            filter: filterText,
            clinicLocationId: vm.selectedLocationId,
          })
          .then((response) => {
            const { data } = response;
            processData(data, filterText, successCallback);
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    }

    function processData(data, filterText, successCallback) {
      _.each(data.items, (tenantGroup) => {
        processGetEmployeesOutput(filterText, tenantGroup.employees, successCallback);
      });

      vm.employeeFoundMessage = App.localize('EmployeeFoundMessagePart1', data.items.length);
      if (hasDependentFound) {
        vm.employeeFoundMessage += `${App.localize('EmployeeFoundMessagePart2')}.`;
      } else {
        vm.employeeFoundMessage += '.';
      }

      // Update step indicator.

      const hasCheckin = _.some(
        _.flatMap(data.items, (x) => x.employees),
        (i) => i.checkedInTime !== null && i.checkOutTime > moment().format()
      );

      if (hasCheckin) {
        $scope.$broadcast('checkIn');
      } else {
        $scope.$broadcast('reset');
      }

      vm.searched = vm.filterText;
    }

    function findClinicLocations() {
      commonLookupSvc
        .findClinicLocations({
          skipCount: 0,
          maxResultCount: 999,
        })
        .then((response) => {
          const { data } = response;
          vm.locations = data.items;
        });
    }

    function setLocation(location) {
      vm.clinic = {};
      vm.clinic.clinicLocationId = parseInt(location.value, 10) || null;
      vm.clinic.name = location.name;
      vm.clinic.address = location.address;
      vm.clinic.contactNumber = location.contactNumber;
      vm.panelLocationTimeZone = location.ianaTimeZoneId;
      vm.clinic.countryCode = location.countryCode;
    }

    function selectLocation(locationId) {
      vm.clinic.clinicLocationId = locationId;
      vm.selectedLocationId = vm.clinic.clinicLocationId;
      vm.panelLocationTimeZone = _.find(vm.locations, (l) => l.value === locationId).ianaTimeZoneId;
      getPendingSubmissionCount();
      vm.getEmployees();
    }

    function requestDiscipline() {
      swal({
        title: App.localize('RequestDisciplineTitle'),
        text: App.localize('TawkNotificationMessage'),
        type: 'warning',
      });

      if (typeof zE !== 'undefined') {
        zE('webWidget', 'open');
      }
    }

    function checkIn() {
      $scope.$broadcast('checkIn');
    }

    function getPendingSubmissionCount() {
      clinicEmployeeSvc
        .getPendingSubmissionCount({
          id: vm.clinic.clinicLocationId,
        })
        .then((response) => {
          const { data } = response;
          vm.pendingSubmissionCount = data;
          vm.pendingSubmissionMessage = App.localize(
            'YouHaveXPendingSubmissions',
            vm.pendingSubmissionCount
          );
        });
    }

    // Finds if the texts are different. Return true if found different.
    // e.g. Return false if nationalId matches filter text.

    function isDiff(text1, text2) {
      return text1.replace(/-/g, '') !== text2.replace(/-/g, '');
    }

    function getAllowanceDisplay(allowance, allowancePeriod) {
      const allowanceText = $filter('currencyFormat')(
        Math.min(allowance, vm.defaultMaxAllowance),
        vm.currencyCode
      );

      if (!allowancePeriod) return allowanceText;

      let cycle = '';
      switch (allowancePeriod.cycle) {
        case 0:
          cycle = App.localize('EveryXYears', allowancePeriod.interval);
          break;
        case 1:
          cycle = App.localize('Yearly');
          break;
        case 2:
          cycle = App.localize('Monthly');
          break;
        case 3:
          cycle = App.localize('Daily');
          break;
        case 4:
          cycle = App.localize('PerVisit');
          break;
        case 5:
          cycle = App.localize('Every6Months');
          break;
        case 6:
          cycle = App.localize('Every4Months');
          break;
        case 7:
          cycle = App.localize('Every3Months');
          break;
        case 8:
          cycle = App.localize('Every2Months');
          break;

        // no default
      }
      return `${allowanceText} ${cycle.toLowerCase()}`;
    }

    function getTenantCurrencyCode() {
      vm.loading += 1;
      commonLookupSvc
        .getTenantCurrencyCode()
        .then((response) => {
          const { data } = response;
          vm.currencyCode = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
