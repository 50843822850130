import Diff from '../../../../lib/diff/diff';
import Value from '../../../../lib/diff/value';

(() => {
  angular
    .module('app')
    .controller(
      'common.views.patientTickets.compareAuditLogsModal',
      CompareAuditLogsModalController
    );

  CompareAuditLogsModalController.$inject = ['$uibModalInstance', 'auditlogs', 'moment'];

  function CompareAuditLogsModalController($uibModalInstance, auditlogs, moment) {
    const vm = this;

    vm.differences = {};
    let diffs = [];

    vm.getModalTitle = getModalTitle;
    vm.close = close;

    init();

    function init() {
      if (auditlogs.length !== 2) {
        $uibModalInstance.close();
      }
      mergeAuditlogs();
    }

    function getModalTitle() {
      return App.localize(
        'ComparisonBetweenXAndY',
        moment(auditlogs[0].creationTime).format('L LT'),
        moment(auditlogs[1].creationTime).format('L LT')
      );
    }

    function mergeAuditlogs() {
      const diff = {
        basics: {
          CreationTime: new Diff('CreationTime'),
        },
        arrays: {},
        objects: {},
      };
      diffs = [];

      // Generate value for CreationTime diff without checking difference.

      diff.basics.CreationTime.data = _.map(
        auditlogs,
        (al) => new Value(moment(al.creationTime).format('LLLL'), false)
      );

      // Construct diff.

      let idx = 0;
      _.forIn(auditlogs, (al) => {
        generateDiffs(al.items.basics, diff.basics, idx);

        _.forIn(al.items.arrays, (arrValue, arrKey) => {
          if (!diff.arrays[arrKey]) {
            diff.arrays[arrKey] = { lengths: [], arrays: [] };
          }
          const arr = diff.arrays[arrKey];
          arr.lengths.push(arrValue.length);
          for (let i = 0; i < arrValue.length; i += 1) {
            if (arr.arrays.length === i) arr.arrays.push({});
            const data = arr.arrays[i];
            generateDiffs(arrValue[i], data, idx);
          }
        });

        _.forEach(al.items.objects, (objValue, objKey) => {
          if (!diff.objects[objKey]) diff.objects[objKey] = {};
          const obj = diff.objects[objKey];
          generateDiffs(objValue, obj, idx);
        });

        idx += 1;
      });

      // Trigger differences check.

      _.forEach(diffs, (v) => {
        if (v.key !== 'CreationTime') v.checkDifference(auditlogs.length);
      });

      vm.differences = diff;
    }

    function generateDiffs(items, diff, idx) {
      const d = diff;
      _.forIn(items, (value, key) => {
        // Insert unique key in diff.

        const newDiff = new Diff(key);
        diffs.push(newDiff);
        if (!d[key]) d[key] = newDiff;

        // Populate dummy.

        while (d[key].data.length < idx) {
          d[key].push(new Value('', true));
        }

        // Insert actual value.

        d[key].push(new Value(value));
      });
    }

    function close() {
      $uibModalInstance.close();
    }
  }
})();
