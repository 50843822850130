(function () {
    var componentId = 'hostPanelRecruitmentPanelRequestsComponent';
    var app = angular.module('app');

    function controller($scope, panelRequestSvc, commonLookupSvc, countries, enumPanelRequestStatus, moment) {
        var vm = this;
        var today = moment();
        vm.loading = 0;
        vm.dateRangeOptions = App.createDateRangePickerOptions();
        vm.assignees = null;
        vm.corporateRequesters = null;
        vm.panelTypes = null;
        vm.panelRequestCreators = null;

        vm.enums = {};
        vm.enums.panelRequestStatus = enumPanelRequestStatus;

        vm.permissions = {
            create: abp.auth.isGranted('PanelRequests.Host.Create'),
            edit: abp.auth.isGranted('PanelRequests.Host.Edit'),
            view: abp.auth.isGranted('PanelRequests.Host.View')
        };

        var viewOptions = {
            maxResultCount: 50,
            skipCount: 0,
            sorting: null
        };

        vm.isBeforeToday = function (date) {
            return moment(date).isBefore(today);
        };

        function init() {
            loadFilters();
            getEligibleCountryCodes();
        }

        // Check for inpatient module enable for the application.

        vm.applicationHasInpatientModule = abp.setting.getBoolean('Hms.Feature.InpatientModule');

        vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');

        vm.data = [];
        vm.filteredData = [];

        // Statuses define certain attributes for the listing behavior.
        // Show: indicates if the status should be shown or not during filter().
        // DisplayName: the name to be display for the button.
        // Count: the number of requests of the status from original list. Assigned by count().
        // Order: the order which the status should appear in the list. This order need to be assign to request prior to sort().

        vm.statuses = [
            { status: 0, show: true, displayName: App.localize('PanelRequestStatus_Requested'), count: 0, order: 1 },
            { status: 1, show: true, displayName: App.localize('PanelRequestStatus_InRecruitment'), count: 0, order: 2 },
            { status: 2, show: true, displayName: App.localize('PanelRequestStatus_ProposalSent'), count: 0, order: 3 },
            { status: 3, show: false, displayName: App.localize('PanelRequestStatus_Rejected'), count: 0, order: 8 },
            { status: 4, show: true, displayName: App.localize('PanelRequestStatus_Registered'), count: 0, order: 4 },
            { status: 5, show: true, displayName: App.localize('PanelRequestStatus_Trained'), count: 0, order: 5 },
            { status: 6, show: false, displayName: App.localize('PanelRequestStatus_Activated'), count: 0, order: 6 },
            { status: 7, show: false, displayName: App.localize('PanelRequestStatus_Deactivated'), count: 0, order: 7 }
        ];

        vm.requestManager = {
            processRequests: function (requests) {
                var statuses = _.keyBy(vm.statuses, 'status');

                _.each(requests, function (request) {
                    // Assign requests' status order.

                    request.order = statuses[request.status].order;
                });
            },
            sort: function (requests) {
                return _.orderBy(requests, ['order', 'priority', 'lastModificationTime'], ['asc', 'desc', 'desc']);
            }
        };

        vm.requestParams = {
            assigneeId: null,
            corporateRequesterId: null,
            panelType: null,
            panelName: null,
            requestNumber: null,
            panelRequestCreatorId: null,
            lastModificationDateRange: {
                startDate: null,
                endDate: null
            },
            countryCode: null
        };

        function resetStatusCount() {
            _.each(vm.statuses, function (d) {
                d.count = 0;
            })
        }

        function getRequestParams() {
            vm.requestParams.statuses = _.map(
                _.filter(vm.statuses, function (item) {
                    return item.show;
                }), function (d) {
                    return d.status;
                });

            return {
                assigneeId: vm.requestParams.assigneeId,
                corporateRequesterId: vm.requestParams.corporateRequesterId,
                panelType: vm.requestParams.panelType,
                panelName: vm.requestParams.panelName,
                requestNumber: vm.requestParams.requestNumber,
                panelRequestCreatorId: vm.requestParams.panelRequestCreatorId,
                lastModificationDateRange: {
                    startDate: vm.requestParams.lastModificationDateRange.startDate === null ? null : vm.requestParams.lastModificationDateRange.startDate.format('YYYY-MM-DD'),
                    endDate: vm.requestParams.lastModificationDateRange.endDate === null ? null : vm.requestParams.lastModificationDateRange.endDate.format('YYYY-MM-DD')
                },
                statuses: vm.requestParams.statuses,
                skipCount: viewOptions.skipCount,
                maxResultCount: viewOptions.maxResultCount,
                sorting: viewOptions.sorting,
                countryCode: vm.requestParams.countryCode
            };
        }

        function getRequests() {
            vm.loading++;
            panelRequestSvc.getPanelRequests(getRequestParams())
                .success(function (data) {
                    vm.data = data;

                    // Map panel request status counts.

                    resetStatusCount();

                    _.each(data.panelRequestStatusStats, function (item) {
                        vm.statuses.find(d => {
                            if (d.status === item.status) d.count = item.count;
                        });
                    });

                    vm.gridOptions.totalItems = vm.data.totalCount;
                    vm.gridOptions.data = vm.data.items;

                    // Process the list.
                }).finally(function () {
                    vm.loading--;
                });
        }

        vm.gridOptions = {
            appScopeProvider: vm,
            paginationPageSizes: app.consts.grid.defaultPageSizes,
            paginationPageSize: 50, // set to 50 for host user to view more at a glance.
            useExternalPagination: true,
            useExternalSorting: true,
            columnDefs: [{
                enableSorting: false,
                name: App.localize('Actions'),
                width: 100,
                headerCellTemplate: '<span></span>',
                cellTemplate: 'actionTemplate'
            }, {
                displayName: App.localize('ImportantFollowup'),
                field: 'isFollowupNeeded',
                cellTemplate: 'isFollowupNeededTemplate',
                width: 35
            }, {
                displayName: App.localize('RequestNumber'),
                field: 'requestNumber',
                width: 100
            }, {
                displayName: App.localize('PanelName'),
                field: 'panelName',
                minWidth: 150
            }, {
                displayName: App.localize('Type'),
                field: 'panelType',
                cellTemplate: 'panelTypeTemplate',
                width: 50
            }, {
                displayName: App.localize('Assignee'),
                field: 'assigneeName',
                enableSorting: false,
                width: 150
            }, {
                displayName: App.localize('Status'),
                field: 'status',
                cellTemplate: 'recruitmentStatusTemplate',
                width: 150
            }, {
                displayName: App.localize('StatusDays'),
                field: 'aging',
                type: 'number',
                cellTemplate: 'statusDaysTemplate',
                width: 100
            }, {
                displayName: App.localize('Priority'),
                field: 'priority',
                cellTemplate: 'priorityTemplate',
                width: 80
            }, {
                displayName: App.localize('LastUpdate'),
                field: 'lastModificationTime',
                cellFilter: 'momentFormat: \'L LT\'',
                width: 150
            }, {
                displayName: App.localize('LatestDueDate'),
                field: 'latestDueDate',
                cellTemplate: 'latestDueDateTemplate',
                enableSorting: false,
                width: 120
            },
            {
                displayName: App.localize('TrainingDate'),
                field: 'trainingDate',
                cellTemplate: 'trainingDateTemplate',
                width: 120
            },
            {
                displayName: App.localize('Address'),
                field: 'address',
                enableSorting: false,
                minWidth: 200
            }, {
                displayName: App.localize('Country'),
                field: 'countryCode',
                width: 30,
                visible: vm.hasRegionalSystemSupport,
                cellTemplate: 'countryTemplate'
            }, {
                displayName: App.localize('State'),
                field: 'state',
                minWidth: 100
            }, {
                displayName: App.localize('Email'),
                field: 'email',
                minWidth: 120
            }],
            onRegisterApi: function (gridApi) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function (scope, callback) {
                    if (callback.length > 0 && callback[0].field == "aging") {
                        callback[0].field = "statusUpdatedTime";
                    }

                    viewOptions.sorting = callback.length && callback[0].field ? callback[0].field + ' ' + callback[0].sort.direction : null;
                    getRequests();
                });
                gridApi.pagination.on.paginationChanged($scope, App.setPagination(viewOptions, getRequests));
            },
            data: []
        };

        vm.cancelPanelRequest = function (requestNumber) {
            var message = App.localize('ConfirmToCancelRequest');
            abp.message.confirm(message, App.localize('AreYouSure'), function (d) {
                if (d) {
                    panelRequestSvc.cancelPanelRequest({
                        id: requestNumber
                    }).success(function (data) {
                        vm.refresh();
                        abp.notify.success(App.localize('SuccessfullyCancelled'));
                    });
                }
            });
        };

        function exportToExcel() {
            vm.exporting++;
            panelRequestSvc.getPanelRequestsToExcel(getRequestParams())
                .success(function (data) {
                    App.downloadTempFile(data);
                }).finally(function () {
                    vm.exporting--;
                });
        }

        function loadFilters() {
            vm.loading++;
            panelRequestSvc.getPanelRequestFilterOptions()
                .success(function (data) {
                    vm.requestParams.assigneeId = data.masterOpsId;
                    vm.assignees = data.assignees;
                    vm.assignees.unshift({ name: App.localize('All'), value: null });

                    vm.corporateRequesters = data.corporateRequesters;
                    vm.corporateRequesters.unshift({ name: App.localize('All'), value: null });

                    vm.panelTypes = data.panelTypes

                    vm.panelTypes.unshift({ name: App.localize('All'), value: null });

                    vm.panelRequestCreators = data.panelRequestCreators;
                    vm.panelRequestCreators.unshift({ name: App.localize('All'), value: null });
                    vm.panelRequestCreators.push({ name: App.localize('Unassigned'), value: -1 });
                }).finally(function () {
                    vm.loading--;
                    getRequests();
                });
        }

        vm.refresh = function () {
            if (vm.loading) return;
            getRequests();
        };

        vm.exportToExcel = function () {
            if (vm.exporting) return;
            exportToExcel();
        };

        function getEligibleCountryCodes() {
            vm.loading++;

            commonLookupSvc.getEligibleCountries(
            ).success(function (data) {
                vm.eligibleCountries = data.map(function (c) {
                    return c.code;
                })

                vm.countries = _.filter(countries, function (c) {
                    return vm.eligibleCountries.includes(c.code)
                });
            }).finally(function () {
                vm.loading--;
            })
        }

        init();
    }

    app.component(componentId, {
        transclude: true,
        templateUrl: require('/App/host/views/panelRecruitment/panelRequests/panelRequests.component.html'),
        controller: ['$scope', 'abp.services.app.panelRequest', 'abp.services.app.commonLookup', 'countries', 'Hms.PanelRequests.PanelRequestStatus', 'moment', controller],
        controllerAs: 'vm'
    });
})();
