(() => {
    angular.module('app').component('commonPostHospitalizationGuaranteeLettersDetailsLetterDetail', {
      templateUrl: require('./letterDetail.component.html'),
      controller: LetterDetailController,
      controllerAs: 'vm',
      bindings: {
        letter: '<',
      },
    });
  
    LetterDetailController.$inject = [
      '$state',
      '$stateParams',
      'abp.services.app.postHospitalizationGuaranteeLetter',
      'Hms.Employees.EmployeeState',
      'Hms.GuaranteeLetters.GuaranteeLetterStatus',
      'Hms.GuaranteeLetters.GuaranteeLetterSource',
    ];
  
    function LetterDetailController(
      $state,
      $stateParams,
      postHospitalizationGuaranteeLetterSvc,
      enumEmployeeState,
      enumGuaranteeLetterStatus,
      enumsGuaranteeLetterSource
    ) {
      const vm = this;
  
      vm.loading = 0;
      vm.isHost = App.isHost();
      vm.isPanel = App.isClinic();
      vm.isCorporate = App.isCorporate();
      vm.letterNumber = $stateParams.letterNumber;
  
      vm.enums = {
        employeeState: enumEmployeeState,
        guaranteeLetterStatus: enumGuaranteeLetterStatus,
        guaranteeLetterSource: enumsGuaranteeLetterSource,
      };
  
      vm.getGuaranteeLetterSourceLocalizedString = getGuaranteeLetterSourceLocalizedString;
      vm.getDateTimeString = getDateTimeString;
      vm.uploadAttachments = uploadAttachments;
  
      init();
  
      function init() {}

  
      function getGuaranteeLetterSourceLocalizedString(guaranteeLetterSource) {
        const enumMap = vm.enums.guaranteeLetterSource.values.find(
          (x) => x.id === guaranteeLetterSource
        );
        if (!enumMap) {
          return '';
        }
        return enumMap.name;
      }

      function getDateTimeString(date, timeZoneId) {
        return App.getDateTimeString(date, timeZoneId);
      }

      function uploadAttachments() {
        $modal
          .open({
            templateUrl: require('./uploadAttachments.modal.html'),
            controller:
              'common.views.postHospitalization.guaranteeLetters.details.letterDetail.uploadAttachments.modal as vm',
            backdrop: 'static',
            resolve: {
              letterNumber() {
                return $stateParams.letterNumber;
              },
            },
          })
          .result.then(() => {
            getLetterDetail(false);
          });
      }

    }
  })();
  