(() => {
  angular.module('app').component('corporateSubscriptionsDetailTransactionsComponent', {
    templateUrl: require('./transactions.component.html'),
    controller: TransactionsController,
    controllerAs: 'vm',
    bindings: {
      subscriptionNumber: '<',
    },
  });

  TransactionsController.$inject = ['$scope', '$timeout', 'abp.services.app.subscription'];

  function TransactionsController($scope, $timeout, subscriptionSvc) {
    const vm = this;

    vm.loading = 0;

    vm.$onInit = onInit;

    const viewOptions = {
      maxResultCount: 12,
      skipCount: 0,
      sorting: null,
    };

    vm.transactionGridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: [12, 24, 60, 120],
      paginationPageSize: 12,
      paginationCurrentPage: 1,
      useExternalPagination: true,
      useExternalSorting: true,
      resizable: true,
      columnDefs: [
        {
          displayName: App.localize('Date'),
          name: App.localize('Date'),
          field: 'date',
          cellFilter: "momentFormat: 'L'",
          minWidth: 80,
        },
        {
          displayName: App.localize('TransactionType'),
          enableSorting: false,
          field: 'transactionType',
          cellTemplate: 'transactionTypeTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('TransactionNumber'),
          field: 'transactionNumber',
          minWidth: 100,
          cellTemplate: 'billingTemplate',
        },
        {
          displayName: App.localize('TotalBeforeTax'),
          enableSorting: false,
          field: 'totalBeforeTax',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          minWidth: 80,
        },
        {
          displayName: App.localize('Tax'),
          enableSorting: false,
          field: 'tax',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          minWidth: 80,
        },
        {
          displayName: App.localize('Total'),
          enableSorting: false,
          field: 'total',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          minWidth: 80,
        },
        {
          name: App.localize('Status'),
          minWidth: 100,
          width: 100,
          cellTemplate: 'statusTemplate',
        },
        {
          displayName: App.localize('CreationTime'),
          name: App.localize('CreationTime'),
          field: 'creationTime',
          cellFilter: "momentFormat: 'L LT'",
          minWidth: 80,
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          let sorting = '';
          for (let i = 0; i < callback.length; i += 1) {
            if (i) sorting += ', ';
            sorting += callback[i].field.concat(' ', callback[i].sort.direction);
          }
          vm.requestParams.sorting = sorting;
          getTransactions();
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, getTransactions)
        );
      },
      data: [],
    };

    init();

    function init() {
      $timeout(() => {
        vm.transactionGridOptions.paginationCurrentPage = Math.floor(
          viewOptions.skipCount / viewOptions.maxResultCount + 1
        );
      });
    }

    function onInit() {
      getTransactions();
    }

    function getTransactions() {
      vm.loading += 1;
      subscriptionSvc
        .getSubscriptionTransactions({
          subscriptionNumber: vm.subscriptionNumber,
        })
        .success((data) => {
          vm.transactionGridOptions.totalItems = data.totalCount;
          vm.transactionGridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
