(function () {
    'use strict';

    angular
        .module('app')
        .controller('clinic.views.hospital.forms.page', ['$scope', '$stateParams', '$state', '$uibModal', '$timeout',
            'abp.services.app.formTemplate', 'Hms.FormTemplates.FormNames', controller]);

    function controller($scope, $stateParams, $state, $uibModal, $timeout, formTemplateSvc, enumFormNames) {
        var vm = this;
        vm.loading = 0;
        vm.exporting = 0;
        vm.downloadInpatientForm  = downloadInpatientForm ;

        vm.enums = {};
        vm.enums.formNames = enumFormNames;

        function init() {
            getAllInpatientFormTemplates();
        }

        vm.gridOptions = {
            appScopeProvider: vm,
            paginationPageSizes: angular.module('app').consts.grid.defaultPageSizes,
            paginationPageSize: angular.module('app').consts.grid.defaultPageSize,
            paginationCurrentPage: 0,
            useExternalPagination: true,
            useExternalSorting: true,
            resizable: true,
            columnDefs: [{
                name: 'Download',
                displayName: 'Download',
                enableSorting: false,
                width: 100,
                headerCellTemplate: '<span></span>',
                allowCellFocus: false,
                cellTemplate: 'downloadForm'
            }, {
                displayName: App.localize('Name'),
                field: 'name',
                minWidth: 120,
                cellTemplate: 'nameTemplate'
            }, {
                displayName: App.localize('LastUpdated'),
                field: 'lastModificationTime',
                cellFilter: 'momentFormat: \'L LT\'',
                minWidth: 120
            }],
            data: []
        }

        function getAllInpatientFormTemplates() {
            vm.loading++;
            formTemplateSvc.getAllInpatientFormTemplate({})
                .success(function (data) {
                    vm.gridOptions.data = data.items;
                    vm.gridOptions.totalCount = data.items.length;
                }).finally(function (e) {
                    vm.loading--;
                });
        }

        function downloadInpatientForm (id) {
            vm.exporting++;
            formTemplateSvc.getInpatientFormTemplateById({
                id: id
                })
                .success(function (data) {
                    App.downloadTempFile(data);
                })
                .finally(function () {
                    vm.exporting--;
                });
        }

        init();
    }
})();
