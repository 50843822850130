(() => {
  angular
    .module('app')
    .filter('f2', f2)
    // For backward compatibility only. Filter name don't need "Filter" suffix.
    // AngularJS automatically appends "Filter" to name for DI.
    .filter('f2Filter', f2);

  f2.$inject = ['numberFilter'];

  function f2(numberFilter) {
    return f2Filter;

    function f2Filter(value, fallbackToZero) {
      let v = value;
      if (!v) {
        if (!fallbackToZero) return '-';
        v = 0;
      }
      return numberFilter(v, 2);
    }
  }
})();
