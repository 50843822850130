(() => {
  angular
    .module('app')
    .filter('terminate', terminate)
    // For backward compatibility only. Filter name don't need "Filter" suffix.
    // AngularJS automatically appends "Filter" to name for DI.
    .filter('terminateFilter', terminate);

  terminate.$inject = ['moment'];

  function terminate(moment) {
    return terminateFilter;

    function terminateFilter(value) {
      return value ? moment(value).format('L') : '';
    }
  }
})();
