import '../sms';

(() => {
  const module = angular.module('sms');
  module.consts = {
    grid: {
      defaultPageSizes: [10, 20, 50, 100],
      defaultPageSize: 10,
    },
  };
  module.constant('smsConstants', {
    grid: {
      defaultPageSizes: [10, 20, 50, 100],
      defaultPageSize: 10,
    },
  });
})();
