(function () {
    'use strict';

    function controller() {
        var vm = this;
        vm.dailyText = App.localize('PerDay');
        vm.asChargedText = App.localize('AsCharged13thSchedule');
        vm.perAnnumText = App.localize('PerAnnum');
        vm.hasSobEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');
        vm.hasTxtFileSupport = abp.setting.getBoolean('Hms.Feature.TxtFileSupport');

        function init() {
            vm.loading = true;

            vm.serviceType = vm.benefitType.serviceType;

            if (vm.serviceType === 7) {
                // Principal
                vm.inpatientSettings = vm.benefitType.hospitalizationSetting.principalScheduleOfBenefit;
                vm.roomAndBoardAmount = vm.inpatientSettings.roomAndBoard.amount;
                vm.icuRoomAndBoard = vm.inpatientSettings.icuRoomAndBoard;
                vm.childGuardianBenefit = vm.inpatientSettings.childGuardianBenefit;

                vm.physicianVisitPerDay = vm.inpatientSettings.inHospitalPhysicianVisitPerDay;
                vm.hospitalCharges = vm.inpatientSettings.hospitalCharges;
                vm.surgicalFee = vm.inpatientSettings.hospitalCharges.surgicalFee;
                vm.anaesthetistFee = vm.inpatientSettings.hospitalCharges.anaesthetistFee;
                vm.operatingTheatre = vm.inpatientSettings.hospitalCharges.operatingTheatre;
                vm.hospitalSuppliesAndServices = vm.inpatientSettings.hospitalCharges.hospitalSuppliesAndServices;
                vm.ambulanceFee = vm.inpatientSettings.ambulanceFee;
                vm.organTransplant = vm.inpatientSettings.organTransplant;
                vm.medicalReport = vm.inpatientSettings.medicalReport;

                vm.preHospitalizationTreatment = vm.inpatientSettings.preHospitalizationTreatment;
                vm.specialistConsultation = vm.inpatientSettings.specialistConsultation;
                vm.surgicalOpinion = vm.inpatientSettings.surgicalOpinion;
                vm.postHospitalizationTreatment = vm.inpatientSettings.postHospitalizationTreatment;
                vm.followUpTreatment = vm.inpatientSettings.followUpTreatment;
                vm.outpatientCancerTreatment = vm.inpatientSettings.outpatientCancerTreatment;
                vm.outpatientKidneyDialysisTreatment = vm.inpatientSettings.outpatientKidneyDialysisTreatment;

                vm.isAccomodationDaysCombined = vm.inpatientSettings.accomodationDaysCombined;

                vm.outpatientSobCovered = vm.preHospitalizationTreatment.isCovered || vm.postHospitalizationTreatment.isCovered ||
                    vm.outpatientCancerTreatment.isCovered || vm.outpatientKidneyDialysisTreatment.isCovered;

                vm.miscSobCovered = true;

                vm.inpatientSob = [];
                vm.outpatientSob = [];
                vm.miscSob = [];

                if (vm.hasTxtFileSupport) {
                    // Inpatient
                    //#region InpatientMedical SOB tab
                    var roomAndBoard = vm.inpatientSettings.roomAndBoard;
                    var icuRoomAndBoard = vm.inpatientSettings.icuRoomAndBoard;
                    var childGuardianBenefit = vm.inpatientSettings.childGuardianBenefit;
                    var hospitalSuppliesAndServices = vm.inpatientSettings.hospitalCharges.hospitalSuppliesAndServices;
                    var doctorCharges = vm.inpatientSettings.doctorCharges;
                    var ambulanceFee = vm.inpatientSettings.ambulanceFee;
                    var medicalReport = vm.inpatientSettings.medicalReport;
                    vm.inpatientSob.push({
                        type: App.localize('InpatientMedical'),
                        settings: [
                            {
                                name: App.localize('RoomAndBoard'),
                                isCovered: roomAndBoard.isCovered,
                                isAsCharged: roomAndBoard.isAsCharged,
                                amount: vm.roomAndBoardAmount,
                                effectivePeriod: 'Per day',
                                daysPerYearLimit: roomAndBoard.accomodationDays,
                                isAccomodationDaysCombined: vm.isAccomodationDaysCombined,
                                accomodationDaysCombineText: App.localize('CombinedRoomAndBoardNarrative'),
                                remarks: roomAndBoard.remarks,
                            },
                            {
                                name: App.localize('IcuRoomAndBoard'),
                                isCovered: icuRoomAndBoard.isCovered,
                                isAsCharged: vm.icuRoomAndBoard.isAsCharged,
                                amount: vm.icuRoomAndBoard.amount,
                                effectivePeriod: 'Per day',
                                daysPerYearLimit: icuRoomAndBoard.accomodationDays,
                                isAccomodationDaysCombined: vm.isAccomodationDaysCombined,
                                accomodationDaysCombineText: App.localize('CombinedIcuRoomAndBoardNarrative'),
                                remarks: icuRoomAndBoard.remarks,
                            },
                            {
                                name: App.localize('InsuredChildDailyGuardianBenefit'),
                                isCovered: childGuardianBenefit.isCovered,
                                isAsCharged: childGuardianBenefit.isAsCharged,
                                amount: childGuardianBenefit.amount,
                                effectivePeriod: 'Per day',
                                daysPerYearLimit: childGuardianBenefit.accomodationDays,
                                remarks: childGuardianBenefit.remarks,
                            },
                            {
                                name: App.localize('HospitalSuppliesAndServices'),
                                isCovered: hospitalSuppliesAndServices.isCovered,
                                isAsCharged: hospitalSuppliesAndServices.isAsCharged,
                                amount: hospitalSuppliesAndServices.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(hospitalSuppliesAndServices.coveredCycle),
                                remarks: hospitalSuppliesAndServices.remarks,
                            },
                            {
                                name: App.localize('InHospitalPhysicianVisit'),
                                isCovered: true,
                                isAsCharged: true,
                                isThirteenthSchedule: true,
                                effectivePeriod: 'Per day',
                                noOfVisit: doctorCharges.isUnlimitedVisitPerDay ? App.localize('Unlimited') : doctorCharges.inHospitalPhysicianVisitPerDay,
                                daysPerYearLimit: doctorCharges.isUnlimitedCoveredDays ? App.localize('Unlimited') : doctorCharges.coveredDays,
                                remarks: doctorCharges.remarks,
                            },
                            {
                                name: App.localize('AmbulanceFee'),
                                isCovered: ambulanceFee.isCovered,
                                isAsCharged: ambulanceFee.isAsCharged,
                                amount: ambulanceFee.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(ambulanceFee.coveredCycle),
                                remarks: ambulanceFee.remarks,
                            },
                            {
                                name: App.localize('MedicalReportFee'),
                                isCovered: medicalReport.isCovered,
                                isAsCharged: medicalReport.isAsCharged,
                                amount: medicalReport.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(medicalReport.coveredCycle),
                                remarks: medicalReport.remarks,
                            },
                        ],
                        hasEffectivePeriod: true,
                        hasNoOfVisit: true,
                        hasDaysPerYear: vm.hasSobEnhancement
                    });
                    //#endregion InpatientMedical SOB tab

                    //#region InpatientSurgical SOB tab
                    var surgicalFee = vm.inpatientSettings.hospitalCharges.surgicalFee;
                    var anaesthetistFee = vm.inpatientSettings.hospitalCharges.anaesthetistFee;
                    var operatingTheatre = vm.inpatientSettings.hospitalCharges.operatingTheatre;
                    var daySurgery = vm.inpatientSettings.daySurgery;
                    var organTransplant = vm.inpatientSettings.organTransplant;
                    vm.inpatientSob.push({
                        type: App.localize('InpatientSurgical'),
                        settings: [
                            {
                                name: App.localize('SurgicalFee'),
                                isCovered: surgicalFee.isCovered,
                                isAsCharged: surgicalFee.isAsCharged,
                                isThirteenthSchedule: true,
                                amount: surgicalFee.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(surgicalFee.coveredCycle),
                                remarks: surgicalFee.remarks,
                            },
                            {
                                name: App.localize('AnaesthetistFee'),
                                isCovered: anaesthetistFee.isCovered,
                                isAsCharged: anaesthetistFee.isAsCharged,
                                isThirteenthSchedule: true,
                                amount: anaesthetistFee.amount,
                                effectivePeriod: 'na',
                                remarks: anaesthetistFee.remarks,
                            },
                            {
                                name: App.localize('OperatingTheatre'),
                                isCovered: operatingTheatre.isCovered,
                                isAsCharged: operatingTheatre.isAsCharged,
                                isThirteenthSchedule: true,
                                amount: operatingTheatre.amount,
                                effectivePeriod: 'na',
                                remarks: operatingTheatre.remarks,
                            },
                            {
                                name: App.localize('DaySurgery'),
                                isCovered: daySurgery.isCovered,
                                isAsCharged: daySurgery.isAsCharged,
                                amount: daySurgery.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(daySurgery.coveredCycle),
                                remarks: daySurgery.remarks,
                            },
                            {
                                name: App.localize('OrganTransplant'),
                                isCovered: organTransplant.isCovered,
                                isAsCharged: organTransplant.isAsCharged,
                                amount: organTransplant.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(organTransplant.coveredCycle),
                                remarks: organTransplant.remarks,
                            },
                        ],
                        hasEffectivePeriod: true
                    });
                    //#endregion InpatientSurgical SOB tab

                    // Outpatient
                    //#region OutpatientTreatment SOB tab
                    var outpatientCancer = vm.inpatientSettings.outpatientCancerTreatment;
                    var outpatientKidneyDialysis = vm.inpatientSettings.outpatientKidneyDialysisTreatment;
                    var outpatientPhysiotherapy = vm.inpatientSettings.outpatientPhysiotherapyTreatment;
                    vm.outpatientSob.push({
                        type: App.localize('OutpatientTreatment'),
                        settings: [
                            {
                                name: App.localize('OutpatientCancerTreatment'),
                                isCovered: outpatientCancer.isCovered,
                                isAsCharged: outpatientCancer.isAsCharged,
                                amount: outpatientCancer.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(outpatientCancer.coveredCycle),
                                remarks: outpatientCancer.remarks,
                            },
                            {
                                name: App.localize('OutpatientKidneyDialysisTreatment'),
                                isCovered: outpatientKidneyDialysis.isCovered,
                                isAsCharged: outpatientKidneyDialysis.isAsCharged,
                                amount: outpatientKidneyDialysis.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(outpatientKidneyDialysis.coveredCycle),
                                remarks: outpatientKidneyDialysis.remarks,
                            },
                            {
                                name: App.localize('OutpatientPhysiotherapyTreatment'),
                                isCovered: outpatientPhysiotherapy.isCovered,
                                isAsCharged: outpatientPhysiotherapy.isAsCharged,
                                amount: outpatientPhysiotherapy.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(outpatientPhysiotherapy.coveredCycle),
                                remarks: outpatientPhysiotherapy.remarks,
                            },
                        ],
                        hasEffectivePeriod: true
                    });
                    //#endregion OutpatientTreatment SOB tab

                    //#region PreHospitalizationTreatment SOB tab
                    var diagnosisTreatment = vm.inpatientSettings.diagnosisTreatment;
                    var specialistConsultation = vm.inpatientSettings.specialistConsultation;
                    var surgicalOpinion = vm.inpatientSettings.surgicalOpinion;
                    vm.outpatientSob.push({
                        type: App.localize('PreHospitalization'),
                        settings: [
                            {
                                name: App.localize('PreHospitalizationDiagnosisTest'),
                                isCovered: diagnosisTreatment.isCovered,
                                isAsCharged: diagnosisTreatment.isAsCharged,
                                amount: diagnosisTreatment.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(diagnosisTreatment.coveredCycle),
                                remarks: diagnosisTreatment.remarks,
                            },
                            {
                                name: App.localize('PreHospitalizationSpecialistConsultation'),
                                isCovered: specialistConsultation.isCovered,
                                isAsCharged: specialistConsultation.isAsCharged,
                                amount: specialistConsultation.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(specialistConsultation.coveredCycle),
                                remarks: specialistConsultation.remarks,
                            },
                            {
                                name: App.localize('SecondSurgicalOpinion'),
                                isCovered: surgicalOpinion.isCovered,
                                isAsCharged: surgicalOpinion.isAsCharged,
                                amount: surgicalOpinion.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(surgicalOpinion.coveredCycle),
                                daysPerYearLimit: surgicalOpinion.isUnlimited ? 'Unlimited' : surgicalOpinion.coveredTimes,
                                remarks: surgicalOpinion.remarks,
                            },
                        ],
                        hasMaxDaysTime: false,
                        hasEffectivePeriod: true,
                        hasDaysPerYear: vm.hasSobEnhancement,
                        coveredDays: vm.preHospitalizationTreatment.coveredDays
                    });
                    //#endregion PreHospitalizationTreatment SOB tab

                    //#region PostHospitalizationTreatment SOB tab
                    var postHospitalizationTreatment = vm.inpatientSettings.followUpTreatment;
                    vm.outpatientSob.push({
                        type: App.localize('PostHospitalization'),
                        settings: [
                            {
                                name: App.localize('PostHospitalizationFollowUpAndTreatment'),
                                isCovered: postHospitalizationTreatment.isCovered,
                                isAsCharged: postHospitalizationTreatment.isAsCharged,
                                amount: postHospitalizationTreatment.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(postHospitalizationTreatment.coveredCycle),
                                remarks: postHospitalizationTreatment.remarks,
                            },
                        ],
                        hasMaxDaysTime: false,
                        hasEffectivePeriod: true,
                        hasDaysPerYear: vm.hasSobEnhancement,
                        coveredDays: vm.postHospitalizationTreatment.coveredDays
                    });
                    //#endregion PostHospitalizationTreatment SOB tab

                    //#region EmergencyOutpatientTreatment SOB tab
                    var emergencyAccidentalOutpatientTreatment = vm.inpatientSettings.emergencyAccidentalOutpatientTreatment;
                    var emergencyAccidentalDentalTreatment = vm.inpatientSettings.emergencyAccidentalDentalTreatment;
                    var emergencyAccidentalSicknessTreatment = vm.inpatientSettings.emergencyAccidentalSicknessTreatment;

                    var emergencyAccidentalOutpatientFollowUpPeriod = null;
                    if (emergencyAccidentalOutpatientTreatment.followUpDays) {
                        emergencyAccidentalOutpatientFollowUpPeriod = `${App.localize('XDays', emergencyAccidentalOutpatientTreatment.followUpDays)}`;
                    } else if (emergencyAccidentalOutpatientTreatment.followUpTimes) {
                        emergencyAccidentalOutpatientFollowUpPeriod = `${App.localize('XTimes', emergencyAccidentalOutpatientTreatment.followUpTimes)}`;
                    }

                    var emergencyAccidentalDentalFollowUpPeriod = null;
                    if (emergencyAccidentalDentalTreatment.followUpDays) {
                        emergencyAccidentalDentalFollowUpPeriod = `${App.localize('XDays', emergencyAccidentalDentalTreatment.followUpDays)}`;
                    } else if (emergencyAccidentalDentalTreatment.followUpTimes) {
                        emergencyAccidentalDentalFollowUpPeriod = `${App.localize('XTimes', emergencyAccidentalDentalTreatment.followUpTimes)}`;
                    }

                    vm.outpatientSob.push({
                        type: App.localize('EmergencyOutpatientTreatment'),
                        settings: [
                            {
                                name: App.localize('EmergencyAccidentalOutpatientTreatment'),
                                isCovered: emergencyAccidentalOutpatientTreatment.isCovered,
                                isAsCharged: emergencyAccidentalOutpatientTreatment.isAsCharged,
                                amount: emergencyAccidentalOutpatientTreatment.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(emergencyAccidentalOutpatientTreatment.coveredCycle),
                                followUpPeriod: emergencyAccidentalOutpatientFollowUpPeriod,
                                followUpEffectivePeriod: App.localize('PerDisability'),
                                remarks: emergencyAccidentalOutpatientTreatment.remarks,
                            },
                            {
                                name: App.localize('EmergencyAccidentalDentalTreatment'),
                                isCovered: emergencyAccidentalDentalTreatment.isCovered,
                                isAsCharged: emergencyAccidentalDentalTreatment.isAsCharged,
                                amount: emergencyAccidentalDentalTreatment.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(emergencyAccidentalDentalTreatment.coveredCycle),
                                followUpPeriod: emergencyAccidentalDentalFollowUpPeriod,
                                followUpEffectivePeriod: App.localize('PerDisability'),
                                remarks: emergencyAccidentalDentalTreatment.remarks,
                            },
                            {
                                name: App.localize('EmergencyOutpatientSicknessTreatment'),
                                isCovered: emergencyAccidentalSicknessTreatment.isCovered,
                                isAsCharged: emergencyAccidentalSicknessTreatment.isAsCharged,
                                amount: emergencyAccidentalSicknessTreatment.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(emergencyAccidentalSicknessTreatment.coveredCycle),
                                followUpPeriod: 'na',
                                remarks: emergencyAccidentalSicknessTreatment.remarks,
                            },
                        ],
                        hasEffectivePeriod: true,
                        multiRowHeader: true,
                        hasFollowUp: true
                    });
                    //#endregion EmergencyOutpatientTreatment SOB tab

                    // Misc
                    //#region Misc SOB tab
                    var governmentHospitalDailyCashAllowance = vm.inpatientSettings.governmentHospitalDailyCashAllowance;
                    var privateHospitalDailyCashAllowance = vm.inpatientSettings.privateHospitalDailyCashAllowance;
                    var homeNursingCare = vm.inpatientSettings.homeNursingCare;
                    var accidentalDeathBenefit = vm.inpatientSettings.accidentalDeathBenefit;
                    var compassionateDeathBenefit = vm.inpatientSettings.compassionateDeathBenefit;
                    var funeralExpenses = vm.inpatientSettings.funeralExpenses;
                    vm.miscSob.push({
                        type: App.localize('Miscellaneous'),
                        settings: [
                            {
                                name: App.localize('GovernmentHospitalDailyCashAllowance'),
                                isCovered: governmentHospitalDailyCashAllowance.isCovered,
                                isAsCharged: governmentHospitalDailyCashAllowance.isAsCharged,
                                amount: governmentHospitalDailyCashAllowance.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(governmentHospitalDailyCashAllowance.coveredCycle),
                                accomodationDays: governmentHospitalDailyCashAllowance.accomodationDays,
                                accomodationEffectivePeriod: App.localize('PerDay'),
                                remarks: governmentHospitalDailyCashAllowance.remarks,
                            },
                            {
                                name: App.localize('PrivateHospitalDailyCashAllowance'),
                                isCovered: privateHospitalDailyCashAllowance.isCovered,
                                isAsCharged: privateHospitalDailyCashAllowance.isAsCharged,
                                amount: privateHospitalDailyCashAllowance.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(privateHospitalDailyCashAllowance.coveredCycle),
                                accomodationDays: privateHospitalDailyCashAllowance.accomodationDays,
                                accomodationEffectivePeriod: App.localize('PerDay'),
                                remarks: privateHospitalDailyCashAllowance.remarks,
                            },
                            {
                                name: App.localize('HomeNursingCare'),
                                isCovered: homeNursingCare.isCovered,
                                isAsCharged: homeNursingCare.isAsCharged,
                                amount: homeNursingCare.amount,
                                effectivePeriod: coveredCycleToEffectivePeriod(homeNursingCare.coveredCycle),
                                accomodationDays: 'na',
                                remarks: homeNursingCare.remarks,
                            },
                            {
                                name: App.localize('AccidentalDeathBenefit'),
                                isCovered: accidentalDeathBenefit.isCovered,
                                isAsCharged: accidentalDeathBenefit.isAsCharged,
                                amount: accidentalDeathBenefit.amount,
                                effectivePeriod: App.localize('PerEmploymentContract'),
                                accomodationDays: 'na',
                                remarks: accidentalDeathBenefit.remarks,
                            },
                            {
                                name: App.localize('CompassionateDeathBenefit'),
                                isCovered: compassionateDeathBenefit.isCovered,
                                isAsCharged: compassionateDeathBenefit.isAsCharged,
                                amount: compassionateDeathBenefit.amount,
                                effectivePeriod: App.localize('PerEmploymentContract'),
                                accomodationDays: 'na',
                                remarks: compassionateDeathBenefit.remarks,
                            },
                            {
                                name: App.localize('FuneralExpenses'),
                                isCovered: funeralExpenses.isCovered,
                                isAsCharged: funeralExpenses.isAsCharged,
                                amount: funeralExpenses.amount,
                                effectivePeriod: App.localize('PerEmploymentContract'),
                                accomodationDays: 'na',
                                remarks: funeralExpenses.remarks,
                            },
                        ],
                        hasEffectivePeriod: true,
                        multiRowHeader: true,
                        hasAccomodationDays: true
                    });
                    //#endregion Misc SOB tab
                } else {
                    //#region Inpatient
                    vm.inpatientSob = [
                        {
                            type: App.localize('Accomodation'),
                            settings: [
                                { name: App.localize('RoomAndBoard'), isCovered: vm.inpatientSettings.roomAndBoard.isCovered, isAsCharged: vm.inpatientSettings.roomAndBoard.isAsCharged, amount: vm.roomAndBoardAmount, effectivePeriod: vm.dailyText },
                                { name: App.localize('ICURoomAndBoard'), isCovered: vm.icuRoomAndBoard.isCovered, isAsCharged: vm.icuRoomAndBoard.isAsCharged, amount: vm.icuRoomAndBoard.amount, effectivePeriod: vm.dailyText },
                                { name: App.localize('ChildDailyGuardianBenefitLodgingOrBed'), isCovered: vm.childGuardianBenefit.isCovered, isAsCharged: vm.childGuardianBenefit.isAsCharged, amount: vm.childGuardianBenefit.amount, effectivePeriod: vm.dailyText },
                            ],
                            hasMaxDaysTime: false,
                            hasEffectivePeriod: true
                        },
                        {
                            type: App.localize('HospitalCharges'),
                            isFixedLimit: vm.hospitalCharges.isFixedLimit,
                            fixedLimitAmount: vm.hospitalCharges.fixedLimitAmount,
                            settings: {
                                physicianVisit: {
                                    isAsCharged: true, amount: null, effectivePeriod: vm.dailyText, maxDaysTime: vm.physicianVisitPerDay
                                },
                                surgicalFees: {
                                    isAsCharged: vm.surgicalFee.isAsCharged, amount: vm.surgicalFee.amount, effectivePeriod: null, maxDaysTime: false
                                },
                                anaesthetistFees: {
                                    isAsCharged: vm.anaesthetistFee.isAsCharged, amount: vm.anaesthetistFee.amount, effectivePeriod: null, maxDaysTime: false
                                },
                                operatingTheatre: {
                                    isAsCharged: vm.operatingTheatre.isAsCharged, amount: vm.operatingTheatre.amount, effectivePeriod: null, maxDaysTime: false
                                },
                                suppliesAndServices: {
                                    isAsCharged: vm.hospitalSuppliesAndServices.isAsCharged, amount: vm.hospitalSuppliesAndServices.amount, effectivePeriod: null, maxDaysTime: false
                                },
                                ambulanceFee: {
                                    isCovered: vm.ambulanceFee.isCovered, isAsCharged: vm.ambulanceFee.isAsCharged, amount: vm.ambulanceFee.amount, effectivePeriod: vm.dailyText, maxDaysTime: false
                                },
                                organTransplant: {
                                    isCovered: vm.organTransplant.isCovered, isAsCharged: vm.organTransplant.isAsCharged, amount: vm.organTransplant.amount, effectivePeriod: 'Per employment contract', maxDaysTime: vm.organTransplant.isUnlimited ? App.localize('Unlimited') : vm.organTransplant.coveredTimes
                                }
                            },
                            hasMaxDaysTime: false,
                            hasEffectivePeriod: true
                        },
                        {
                            type: App.localize('SelfPayHospitalization'),
                            settings: [
                                { name: App.localize('MedicalReport'), isCovered: true, isAsCharged: vm.medicalReport.isAsCharged, amount: vm.medicalReport.amount, effectivePeriod: null }
                            ],
                            hasMaxDaysTime: false,
                            hasEffectivePeriod: false
                        }
                    ];
                    //#endregion Inpatient

                    //#region Outpatient
                    vm.outpatientSob = [
                        {
                            type: App.localize('PreHospitalizationTreatment'),
                            settings: [
                                {
                                    name: App.localize('PreHospitalizationDiagnosisTest'),
                                    isCovered: vm.preHospitalizationTreatment.isCovered,
                                    isAsCharged: true,
                                    amount: null,
                                    effectivePeriod: App.localize('PerAdmission'), maxDaysTime: null
                                },
                                {
                                    name: App.localize('PreHospitalizationSpecialistConsultation'),
                                    isCovered: vm.preHospitalizationTreatment.isCovered,
                                    isAsCharged: true,
                                    amount: null,
                                    effectivePeriod: App.localize('PerAdmission'),
                                    maxDaysTime: vm.specialistConsultation.isUnlimited ? 'Unlimited' : vm.specialistConsultation.coveredTimes
                                },
                                {
                                    name: App.localize('SecondSurgicalOpinion'),
                                    isCovered: vm.preHospitalizationTreatment.isCovered,
                                    isAsCharged: vm.surgicalOpinion.isAsCharged,
                                    amount: vm.surgicalOpinion.amount,
                                    effectivePeriod: App.localize('PerAdmission'),
                                    maxDaysTime: vm.surgicalOpinion.isUnlimited ? 'Unlimited' : vm.surgicalOpinion.coveredTimes
                                }
                            ],
                            hasMaxDaysTime: vm.preHospitalizationTreatment.isCovered ? true : false,
                            hasEffectivePeriod: true,
                            coveredDays: vm.preHospitalizationTreatment.coveredDays
                        },
                        {
                            type: App.localize('PostHospitalizationTreatment'),
                            settings: [
                                { name: App.localize('PostHospitalizationFollowUpAndTreatment'), isCovered: vm.postHospitalizationTreatment.isCovered, isAsCharged: vm.followUpTreatment.isAsCharged, amount: vm.followUpTreatment.amount, effectivePeriod: 'Per admission' }
                            ],
                            hasMaxDaysTime: false,
                            hasEffectivePeriod: true,
                            coveredDays: vm.postHospitalizationTreatment.coveredDays
                        },
                        {
                            type: App.localize('Others'),
                            settings: [
                                { name: App.localize('OutpatientCancerTreatment'), isCovered: vm.outpatientCancerTreatment.isCovered, isAsCharged: false, amount: vm.outpatientCancerTreatment.amount, effectivePeriod: vm.perAnnumText },
                                { name: App.localize('OutpatientKidneyDialysisTreatment'), isCovered: vm.outpatientKidneyDialysisTreatment.isCovered, isAsCharged: false, amount: vm.outpatientKidneyDialysisTreatment.amount, effectivePeriod: vm.perAnnumText },
                            ],
                            hasMaxDaysTime: false,
                            hasEffectivePeriod: true
                        },
                    ];
                    //#endregion Outpatient
                }

                // Dependent
                vm.inpatientDependentSettings = vm.benefitType.hospitalizationSetting.dependentScheduleOfBenefit;
                if (vm.inpatientDependentSettings !== null) {
                    vm.dependentRoomAndBoardAmount = vm.inpatientDependentSettings.roomAndBoard.amount;
                    vm.dependentIcuRoomAndBoard = vm.inpatientDependentSettings.icuRoomAndBoard;
                    vm.dependentChildGuardianBenefit = vm.inpatientDependentSettings.childGuardianBenefit;

                    vm.dependentPhysicianVisitPerDay = vm.inpatientDependentSettings.inHospitalPhysicianVisitPerDay;
                    vm.dependenthospitalCharges = vm.inpatientDependentSettings.hospitalCharges;
                    vm.dependentSurgicalFee = vm.inpatientDependentSettings.hospitalCharges.surgicalFee;
                    vm.dependentAnaesthetistFee = vm.inpatientDependentSettings.hospitalCharges.anaesthetistFee;
                    vm.dependentOperatingTheatre = vm.inpatientDependentSettings.hospitalCharges.operatingTheatre;
                    vm.dependentHospitalSuppliesAndServices = vm.inpatientDependentSettings.hospitalCharges.hospitalSuppliesAndServices;
                    vm.dependentAmbulanceFee = vm.inpatientDependentSettings.ambulanceFee;
                    vm.dependentOrganTransplant = vm.inpatientDependentSettings.organTransplant;
                    vm.dependentMedicalReport = vm.inpatientDependentSettings.medicalReport;

                    vm.dependentPreHospitalizationTreatment = vm.inpatientDependentSettings.preHospitalizationTreatment;
                    vm.dependentSpecialistConsultation = vm.inpatientDependentSettings.specialistConsultation;
                    vm.dependentSurgicalOpinion = vm.inpatientDependentSettings.surgicalOpinion;
                    vm.dependentPostHospitalizationTreatment = vm.inpatientDependentSettings.postHospitalizationTreatment;
                    vm.dependentFollowUpTreatment = vm.inpatientDependentSettings.followUpTreatment;
                    vm.dependentOutpatientCancerTreatment = vm.inpatientDependentSettings.outpatientCancerTreatment;
                    vm.dependentOutpatientKidneyDialysisTreatment = vm.inpatientDependentSettings.outpatientKidneyDialysisTreatment;

                    vm.isAccomodationDaysCombinedDependent = vm.inpatientDependentSettings.accomodationDaysCombined;

                    vm.dependentOutpatientSobCovered = vm.dependentPreHospitalizationTreatment.isCovered || vm.dependentPostHospitalizationTreatment.isCovered ||
                        vm.dependentOutpatientCancerTreatment.isCovered || vm.dependentOutpatientKidneyDialysisTreatment.isCovered;

                    vm.dependentMiscSobCovered = true;

                    vm.inpatientDependentSob = [];
                    vm.outpatientDependentSob = [];
                    vm.miscDependentSob = [];

                    if (vm.hasTxtFileSupport) {
                        // Inpatient
                        //#region InpatientMedical SOB tab
                        var roomAndBoardDependent = vm.inpatientDependentSettings.roomAndBoard;
                        var icuRoomAndBoardDependent = vm.inpatientDependentSettings.icuRoomAndBoard;
                        var childGuardianBenefitDependent = vm.inpatientDependentSettings.childGuardianBenefit;
                        var hospitalSuppliesAndServicesDependent = vm.inpatientDependentSettings.hospitalCharges.hospitalSuppliesAndServices;
                        var doctorChargesDependent = vm.inpatientDependentSettings.doctorCharges;
                        var ambulanceFeeDependent = vm.inpatientDependentSettings.ambulanceFee;
                        var medicalReportDependent = vm.inpatientDependentSettings.medicalReport;
                        vm.inpatientDependentSob.push({
                            type: App.localize('InpatientMedical'),
                            settings: [
                                {
                                    name: App.localize('RoomAndBoard'),
                                    isCovered: true,
                                    isAsCharged: false,
                                    amount: vm.dependentRoomAndBoardAmount,
                                    effectivePeriod: 'Per day',
                                    daysPerYearLimit: roomAndBoardDependent.accomodationDays,
                                    isAccomodationDaysCombined: vm.isAccomodationDaysCombinedDependent,
                                    accomodationDaysCombineText: App.localize('CombinedRoomAndBoardNarrative'),
                                    remarks: roomAndBoardDependent.remarks,
                                },
                                {
                                    name: App.localize('IcuRoomAndBoard'),
                                    isCovered: true,
                                    isAsCharged: icuRoomAndBoardDependent.isAsCharged,
                                    amount: icuRoomAndBoardDependent.amount,
                                    effectivePeriod: 'Per day',
                                    daysPerYearLimit: icuRoomAndBoardDependent.accomodationDays,
                                    isAccomodationDaysCombined: vm.isAccomodationDaysCombinedDependent,
                                    accomodationDaysCombineText: App.localize('CombinedIcuRoomAndBoardNarrative'),
                                    remarks: icuRoomAndBoardDependent.remarks,
                                },
                                {
                                    name: App.localize('InsuredChildDailyGuardianBenefit'),
                                    isCovered: childGuardianBenefitDependent.isCovered,
                                    isAsCharged: childGuardianBenefitDependent.isAsCharged,
                                    amount: childGuardianBenefitDependent.amount,
                                    effectivePeriod: 'Per day',
                                    daysPerYearLimit: childGuardianBenefitDependent.accomodationDays,
                                    remarks: childGuardianBenefitDependent.remarks,
                                },
                                {
                                    name: App.localize('HospitalSuppliesAndServices'),
                                    isCovered: hospitalSuppliesAndServicesDependent.isCovered,
                                    isAsCharged: hospitalSuppliesAndServicesDependent.isAsCharged,
                                    amount: hospitalSuppliesAndServicesDependent.amount,
                                    effectivePeriod: coveredCycleToEffectivePeriod(hospitalSuppliesAndServicesDependent.coveredCycle),
                                    remarks: hospitalSuppliesAndServicesDependent.remarks,
                                },
                                {
                                    name: App.localize('InHospitalPhysicianVisit'),
                                    isCovered: true,
                                    isAsCharged: true,
                                    isThirteenthSchedule: true,
                                    effectivePeriod: 'Per day',
                                    noOfVisit: doctorChargesDependent.isUnlimitedVisitPerDay ? App.localize('Unlimited') : doctorChargesDependent.inHospitalPhysicianVisitPerDay,
                                    daysPerYearLimit: doctorChargesDependent.isUnlimitedCoveredDays ? App.localize('Unlimited') : doctorChargesDependent.coveredDays,
                                    remarks: doctorChargesDependent.remarks,
                                },
                                {
                                    name: App.localize('AmbulanceFee'),
                                    isCovered: ambulanceFeeDependent.isCovered,
                                    isAsCharged: ambulanceFeeDependent.isAsCharged,
                                    amount: ambulanceFeeDependent.amount,
                                    effectivePeriod: coveredCycleToEffectivePeriod(ambulanceFeeDependent.coveredCycle),
                                    remarks: ambulanceFeeDependent.remarks,
                                },
                                {
                                    name: App.localize('MedicalReportFee'),
                                    isCovered: medicalReportDependent.isCovered,
                                    isAsCharged: medicalReportDependent.isAsCharged,
                                    amount: medicalReportDependent.amount,
                                    effectivePeriod: coveredCycleToEffectivePeriod(medicalReportDependent.coveredCycle),
                                    remarks: medicalReportDependent.remarks,
                                },
                            ],
                            hasEffectivePeriod: true,
                            hasNoOfVisit: true,
                            hasDaysPerYear: vm.hasSobEnhancement
                        });
                        //#endregion InpatientMedical SOB tab

                        //#region InpatientSurgical SOB tab
                        var surgicalFeeDependent = vm.inpatientDependentSettings.hospitalCharges.surgicalFee;
                        var anaesthetistFeeDependent = vm.inpatientDependentSettings.hospitalCharges.anaesthetistFee;
                        var operatingTheatreDependent = vm.inpatientDependentSettings.hospitalCharges.operatingTheatre;
                        var daySurgeryDependent = vm.inpatientDependentSettings.daySurgery;
                        var organTransplantDependent = vm.inpatientDependentSettings.organTransplant;
                        vm.inpatientDependentSob.push({
                            type: App.localize('InpatientSurgical'),
                            settings: [
                                {
                                    name: App.localize('SurgicalFee'),
                                    isCovered: surgicalFeeDependent.isCovered,
                                    isAsCharged: surgicalFeeDependent.isAsCharged,
                                    isThirteenthSchedule: true,
                                    amount: surgicalFeeDependent.amount,
                                    effectivePeriod: surgicalFeeDependent.coveredCycle ? surgicalFeeDependent.coveredCycle : 'na',
                                    remarks: surgicalFeeDependent.remarks,
                                },
                                {
                                    name: App.localize('AnaesthetistFee'),
                                    isCovered: anaesthetistFeeDependent.isCovered,
                                    isAsCharged: anaesthetistFeeDependent.isAsCharged,
                                    isThirteenthSchedule: true,
                                    amount: anaesthetistFeeDependent.amount,
                                    effectivePeriod: 'na',
                                    remarks: anaesthetistFeeDependent.remarks,
                                },
                                {
                                    name: App.localize('OperatingTheatre'),
                                    isCovered: operatingTheatreDependent.isCovered,
                                    isAsCharged: operatingTheatreDependent.isAsCharged,
                                    isThirteenthSchedule: true,
                                    amount: operatingTheatreDependent.amount,
                                    effectivePeriod: 'na',
                                    remarks: operatingTheatreDependent.remarks,
                                },
                                {
                                    name: App.localize('DaySurgery'),
                                    isCovered: daySurgeryDependent.isCovered,
                                    isAsCharged: daySurgeryDependent.isAsCharged,
                                    amount: daySurgeryDependent.amount,
                                    effectivePeriod: coveredCycleToEffectivePeriod(daySurgeryDependent.coveredCycle),
                                    remarks: daySurgeryDependent.remarks,
                                },
                                {
                                    name: App.localize('OrganTransplant'),
                                    isCovered: organTransplantDependent.isCovered,
                                    isAsCharged: organTransplantDependent.isAsCharged,
                                    amount: organTransplantDependent.amount,
                                    effectivePeriod: coveredCycleToEffectivePeriod(organTransplantDependent.coveredCycle),
                                    remarks: organTransplantDependent.remarks,
                                },
                            ],
                            hasEffectivePeriod: true
                        });
                        //#endregion InpatientSurgical SOB tab

                        // Outpatient
                        //#region OutpatientTreatment SOB tab
                        var outpatientCancerDependent = vm.inpatientDependentSettings.outpatientCancerTreatment;
                        var outpatientKidneyDialysisDependent = vm.inpatientDependentSettings.outpatientKidneyDialysisTreatment;
                        var outpatientPhysiotherapyDependent = vm.inpatientDependentSettings.outpatientPhysiotherapyTreatment;
                        vm.outpatientDependentSob.push({
                            type: App.localize('OutpatientTreatment'),
                            settings: [
                                {
                                    name: App.localize('OutpatientCancerTreatment'),
                                    isCovered: outpatientCancerDependent.isCovered,
                                    isAsCharged: outpatientCancerDependent.isAsCharged,
                                    amount: outpatientCancerDependent.amount,
                                    effectivePeriod: coveredCycleToEffectivePeriod(outpatientCancerDependent.coveredCycle),
                                    remarks: outpatientCancerDependent.remarks,
                                },
                                {
                                    name: App.localize('OutpatientKidneyDialysisTreatment'),
                                    isCovered: outpatientKidneyDialysisDependent.isCovered,
                                    isAsCharged: outpatientKidneyDialysisDependent.isAsCharged,
                                    amount: outpatientKidneyDialysisDependent.amount,
                                    effectivePeriod: coveredCycleToEffectivePeriod(outpatientKidneyDialysisDependent.coveredCycle),
                                    remarks: outpatientKidneyDialysisDependent.remarks,
                                },
                                {
                                    name: App.localize('OutpatientPhysiotherapyTreatment'),
                                    isCovered: outpatientPhysiotherapyDependent.isCovered,
                                    isAsCharged: outpatientPhysiotherapyDependent.isAsCharged,
                                    amount: outpatientPhysiotherapyDependent.amount,
                                    effectivePeriod: coveredCycleToEffectivePeriod(outpatientPhysiotherapyDependent.coveredCycle),
                                    remarks: outpatientPhysiotherapyDependent.remarks,
                                },
                            ],
                            hasEffectivePeriod: true
                        });
                        //#endregion OutpatientTreatment SOB tab

                        //#region PreHospitalizationTreatment SOB tab
                        var diagnosisTreatmentDependent = vm.inpatientDependentSettings.diagnosisTreatment;
                        var specialistConsultationDependent = vm.inpatientDependentSettings.specialistConsultation;
                        var surgicalOpinionDependent = vm.inpatientDependentSettings.surgicalOpinion;
                        vm.outpatientDependentSob.push({
                            type: App.localize('PreHospitalization'),
                            settings: [
                                {
                                    name: App.localize('PreHospitalizationDiagnosisTest'),
                                    isCovered: diagnosisTreatmentDependent.isCovered,
                                    isAsCharged: diagnosisTreatmentDependent.isAsCharged,
                                    amount: diagnosisTreatmentDependent.amount,
                                    effectivePeriod: coveredCycleToEffectivePeriod(diagnosisTreatmentDependent.coveredCycle),
                                    remarks: diagnosisTreatmentDependent.remarks,
                                },
                                {
                                    name: App.localize('PreHospitalizationSpecialistConsultation'),
                                    isCovered: specialistConsultationDependent.isCovered,
                                    isAsCharged: specialistConsultationDependent.isAsCharged,
                                    amount: specialistConsultationDependent.amount,
                                    effectivePeriod: coveredCycleToEffectivePeriod(specialistConsultationDependent.coveredCycle),
                                    remarks: specialistConsultationDependent.remarks,
                                },
                                {
                                    name: App.localize('SecondSurgicalOpinion'),
                                    isCovered: surgicalOpinionDependent.isCovered,
                                    isAsCharged: surgicalOpinionDependent.isAsCharged,
                                    amount: surgicalOpinionDependent.amount,
                                    effectivePeriod: coveredCycleToEffectivePeriod(surgicalOpinionDependent.coveredCycle),
                                    daysPerYearLimit: surgicalOpinionDependent.isUnlimited ? 'Unlimited' : surgicalOpinionDependent.coveredTimes,
                                    remarks: surgicalOpinionDependent.remarks,
                                },
                            ],
                            hasMaxDaysTime: false,
                            hasEffectivePeriod: true,
                            hasDaysPerYear: vm.hasSobEnhancement
                        });
                        //#endregion PreHospitalizationTreatment SOB tab

                        //#region PostHospitalizationTreatment SOB tab
                        var postHospitalizationTreatmentDependent = vm.inpatientDependentSettings.followUpTreatment;
                        vm.outpatientDependentSob.push({
                            type: App.localize('PostHospitalization'),
                            settings: [
                                {
                                    name: App.localize('PostHospitalizationFollowUpAndTreatment'),
                                    isCovered: postHospitalizationTreatmentDependent.isCovered,
                                    isAsCharged: postHospitalizationTreatmentDependent.isAsCharged,
                                    amount: postHospitalizationTreatmentDependent.amount,
                                    effectivePeriod: coveredCycleToEffectivePeriod(postHospitalizationTreatmentDependent.coveredCycle),
                                    remarks: postHospitalizationTreatmentDependent.remarks,
                                },
                            ],
                            hasMaxDaysTime: false,
                            hasEffectivePeriod: true,
                            hasDaysPerYear: vm.hasSobEnhancement
                        });
                        //#endregion PostHospitalizationTreatment SOB tab

                        //#region EmergencyOutpatientTreatment SOB tab
                        var emergencyAccidentalOutpatientTreatmentDependent = vm.inpatientDependentSettings.emergencyAccidentalOutpatientTreatment;
                        var emergencyAccidentalDentalTreatmentDependent = vm.inpatientDependentSettings.emergencyAccidentalDentalTreatment;
                        var emergencyAccidentalSicknessTreatmentDependent = vm.inpatientDependentSettings.emergencyAccidentalSicknessTreatment;

                        var emergencyAccidentalOutpatientFollowUpPeriodDependent = null;
                        if (emergencyAccidentalOutpatientTreatmentDependent.followUpDays) {
                            emergencyAccidentalOutpatientFollowUpPeriodDependent = `${App.localize('XDays', emergencyAccidentalOutpatientTreatmentDependent.followUpDays)}`;
                        } else if (emergencyAccidentalOutpatientTreatmentDependent.followUpTimes) {
                            emergencyAccidentalOutpatientFollowUpPeriodDependent = `${App.localize('XTimes', emergencyAccidentalOutpatientTreatmentDependent.followUpTimes)}`;
                        }

                        var emergencyAccidentalDentalFollowUpPeriodDependent = null;
                        if (emergencyAccidentalDentalTreatmentDependent.followUpDays) {
                            emergencyAccidentalDentalFollowUpPeriodDependent = `${App.localize('XDays', emergencyAccidentalDentalTreatmentDependent.followUpDays)}`;
                        } else if (emergencyAccidentalDentalTreatmentDependent.followUpTimes) {
                            emergencyAccidentalDentalFollowUpPeriodDependent = `${App.localize('XTimes', emergencyAccidentalDentalTreatmentDependent.followUpTimes)}`;
                        }

                        vm.outpatientDependentSob.push({
                            type: App.localize('EmergencyOutpatientTreatment'),
                            settings: [
                                {
                                    name: App.localize('EmergencyAccidentalOutpatientTreatment'),
                                    isCovered: emergencyAccidentalOutpatientTreatmentDependent.isCovered,
                                    isAsCharged: emergencyAccidentalOutpatientTreatmentDependent.isAsCharged,
                                    amount: emergencyAccidentalOutpatientTreatmentDependent.amount,
                                    effectivePeriod: coveredCycleToEffectivePeriod(emergencyAccidentalOutpatientTreatmentDependent.coveredCycle),
                                    followUpPeriod: emergencyAccidentalOutpatientFollowUpPeriodDependent,
                                    followUpEffectivePeriod: App.localize('PerDisability'),
                                    remarks: emergencyAccidentalOutpatientTreatmentDependent.remarks,
                                },
                                {
                                    name: App.localize('EmergencyAccidentalDentalTreatment'),
                                    isCovered: emergencyAccidentalDentalTreatmentDependent.isCovered,
                                    isAsCharged: emergencyAccidentalDentalTreatmentDependent.isAsCharged,
                                    amount: emergencyAccidentalDentalTreatmentDependent.amount,
                                    effectivePeriod: coveredCycleToEffectivePeriod(emergencyAccidentalDentalTreatmentDependent.coveredCycle),
                                    followUpPeriod: emergencyAccidentalDentalFollowUpPeriodDependent,
                                    followUpEffectivePeriod: App.localize('PerDisability'),
                                    remarks: emergencyAccidentalDentalTreatmentDependent.remarks,
                                },
                                {
                                    name: App.localize('EmergencyOutpatientSicknessTreatment'),
                                    isCovered: emergencyAccidentalSicknessTreatmentDependent.isCovered,
                                    isAsCharged: emergencyAccidentalSicknessTreatmentDependent.isAsCharged,
                                    amount: emergencyAccidentalSicknessTreatmentDependent.amount,
                                    effectivePeriod: coveredCycleToEffectivePeriod(emergencyAccidentalSicknessTreatmentDependent.coveredCycle),
                                    followUpPeriod: 'na',
                                    remarks: emergencyAccidentalSicknessTreatmentDependent.remarks,
                                },
                            ],
                            hasEffectivePeriod: true,
                            multiRowHeader: true,
                            hasFollowUp: true
                        });
                        //#endregion EmergencyOutpatientTreatment SOB tab

                        // Misc
                        //#region Misc SOB tab
                        var governmentHospitalDailyCashAllowanceDependent = vm.inpatientDependentSettings.governmentHospitalDailyCashAllowance;
                        var privateHospitalDailyCashAllowanceDependent = vm.inpatientDependentSettings.privateHospitalDailyCashAllowance;
                        var homeNursingCareDependent = vm.inpatientDependentSettings.homeNursingCare;
                        var accidentalDeathBenefitDependent = vm.inpatientDependentSettings.accidentalDeathBenefit;
                        var compassionateDeathBenefitDependent = vm.inpatientDependentSettings.compassionateDeathBenefit;
                        var funeralExpensesDependent = vm.inpatientDependentSettings.funeralExpenses;
                        vm.miscDependentSob.push({
                            type: App.localize('Miscellaneous'),
                            settings: [
                                {
                                    name: App.localize('GovernmentHospitalDailyCashAllowance'),
                                    isCovered: governmentHospitalDailyCashAllowanceDependent.isCovered,
                                    isAsCharged: governmentHospitalDailyCashAllowanceDependent.isAsCharged,
                                    amount: governmentHospitalDailyCashAllowanceDependent.amount,
                                    effectivePeriod: coveredCycleToEffectivePeriod(governmentHospitalDailyCashAllowanceDependent.coveredCycle),
                                    accomodationDays: governmentHospitalDailyCashAllowanceDependent.accomodationDays,
                                    accomodationEffectivePeriod: App.localize('PerDay'),
                                    remarks: governmentHospitalDailyCashAllowanceDependent.remarks,
                                },
                                {
                                    name: App.localize('PrivateHospitalDailyCashAllowance'),
                                    isCovered: privateHospitalDailyCashAllowanceDependent.isCovered,
                                    isAsCharged: privateHospitalDailyCashAllowanceDependent.isAsCharged,
                                    amount: privateHospitalDailyCashAllowanceDependent.amount,
                                    effectivePeriod: coveredCycleToEffectivePeriod(privateHospitalDailyCashAllowanceDependent.coveredCycle),
                                    accomodationDays: privateHospitalDailyCashAllowanceDependent.accomodationDays,
                                    accomodationEffectivePeriod: App.localize('PerDay'),
                                    remarks: privateHospitalDailyCashAllowanceDependent.remarks,
                                },
                                {
                                    name: App.localize('HomeNursingCare'),
                                    isCovered: homeNursingCareDependent.isCovered,
                                    isAsCharged: homeNursingCareDependent.isAsCharged,
                                    amount: homeNursingCareDependent.amount,
                                    effectivePeriod: coveredCycleToEffectivePeriod(homeNursingCareDependent.coveredCycle),
                                    accomodationDays: 'na',
                                    remarks: homeNursingCareDependent.remarks,
                                },
                                {
                                    name: App.localize('AccidentalDeathBenefit'),
                                    isCovered: accidentalDeathBenefitDependent.isCovered,
                                    isAsCharged: accidentalDeathBenefitDependent.isAsCharged,
                                    amount: accidentalDeathBenefitDependent.amount,
                                    effectivePeriod: App.localize('PerEmploymentContract'),
                                    accomodationDays: 'na',
                                    remarks: accidentalDeathBenefitDependent.remarks,
                                },
                                {
                                    name: App.localize('CompassionateDeathBenefit'),
                                    isCovered: compassionateDeathBenefitDependent.isCovered,
                                    isAsCharged: compassionateDeathBenefitDependent.isAsCharged,
                                    amount: compassionateDeathBenefitDependent.amount,
                                    effectivePeriod: App.localize('PerEmploymentContract'),
                                    accomodationDays: 'na',
                                    remarks: compassionateDeathBenefitDependent.remarks,
                                },
                                {
                                    name: App.localize('FuneralExpenses'),
                                    isCovered: funeralExpensesDependent.isCovered,
                                    isAsCharged: funeralExpensesDependent.isAsCharged,
                                    amount: funeralExpensesDependent.amount,
                                    effectivePeriod: App.localize('PerEmploymentContract'),
                                    accomodationDays: 'na',
                                    remarks: funeralExpensesDependent.remarks,
                                },
                            ],
                            hasEffectivePeriod: true,
                            multiRowHeader: true,
                            hasAccomodationDays: true
                        });
                        //#endregion Misc SOB tab
                    } else {
                        //#region Inpatient
                        vm.inpatientDependentSob = [
                            {
                                type: App.localize('Accomodation'),
                                settings: [
                                    { name: App.localize('RoomAndBoard'), isCovered: true, isAsCharged: false, amount: vm.dependentRoomAndBoardAmount, effectivePeriod: vm.dailyText },
                                    {
                                        name: App.localize('ICURoomAndBoard'), isCovered: true, isAsCharged: vm.dependentIcuRoomAndBoard.isAsCharged,
                                        amount: vm.dependentIcuRoomAndBoard.amount, effectivePeriod: vm.dailyText
                                    },
                                    {
                                        name: App.localize('ChildDailyGuardianBenefitLodgingOrBed'), isCovered: vm.dependentChildGuardianBenefit.isCovered,
                                        isAsCharged: vm.dependentChildGuardianBenefit.isAsCharged, amount: vm.dependentChildGuardianBenefit.amount, effectivePeriod: vm.dailyText
                                    },
                                ],
                                hasMaxDaysTime: false,
                                hasEffectivePeriod: true
                            },
                            {
                                type: App.localize('HospitalCharges'),
                                isFixedLimit: vm.dependenthospitalCharges.isFixedLimit,
                                fixedLimitAmount: vm.dependenthospitalCharges.fixedLimitAmount,
                                settings: {
                                    physicianVisit: {
                                        isAsCharged: true, amount: null, effectivePeriod: vm.dailyText, maxDaysTime: vm.dependentPhysicianVisitPerDay
                                    },
                                    surgicalFees: {
                                        isAsCharged: vm.dependentSurgicalFee.isAsCharged, amount: vm.dependentSurgicalFee.amount, effectivePeriod: null, maxDaysTime: false
                                    },
                                    anaesthetistFees: {
                                        isAsCharged: vm.dependentAnaesthetistFee.isAsCharged, amount: vm.dependentAnaesthetistFee.amount, effectivePeriod: null, maxDaysTime: false
                                    },
                                    operatingTheatre: {
                                        isAsCharged: vm.dependentOperatingTheatre.isAsCharged, amount: vm.dependentOperatingTheatre.amount, effectivePeriod: null, maxDaysTime: false
                                    },
                                    suppliesAndServices: {
                                        isAsCharged: vm.dependentHospitalSuppliesAndServices.isAsCharged, amount: vm.dependentHospitalSuppliesAndServices.amount, effectivePeriod: null, maxDaysTime: false
                                    },
                                    ambulanceFee: {
                                        isCovered: vm.dependentAmbulanceFee.isCovered, isAsCharged: vm.dependentAmbulanceFee.isAsCharged, amount: vm.dependentAmbulanceFee.amount, effectivePeriod: vm.dailyText, maxDaysTime: false
                                    },
                                    organTransplant: {
                                        isCovered: vm.dependentOrganTransplant.isCovered, isAsCharged: vm.dependentOrganTransplant.isAsCharged,
                                        amount: vm.dependentOrganTransplant.amount, effectivePeriod: 'Per employment contract', maxDaysTime: vm.dependentOrganTransplant.isUnlimited ? App.localize('Unlimited') : vm.dependentOrganTransplant.coveredTimes
                                    }
                                },
                                hasMaxDaysTime: false,
                                hasEffectivePeriod: true
                            },
                            {
                                type: App.localize('SelfPayHospitalization'),
                                settings: [
                                    { name: App.localize('MedicalReport'), isCovered: true, isAsCharged: vm.dependentMedicalReport.isAsCharged, amount: vm.dependentMedicalReport.amount, effectivePeriod: null }
                                ],
                                hasMaxDaysTime: false,
                                hasEffectivePeriod: false
                            }
                        ];
                        //#endregion Inpatient

                        //#region Outpatient
                        vm.outpatientDependentSob = [
                            {
                                type: App.localize('PreHospitalizationTreatment'),
                                settings: [
                                    {
                                        name: App.localize('PreHospitalizationDiagnosisTest'),
                                        isCovered: vm.dependentPreHospitalizationTreatment.isCovered,
                                        isAsCharged: true,
                                        amount: null,
                                        effectivePeriod: App.localize('PerAdmission'), maxDaysTime: null
                                    },
                                    {
                                        name: App.localize('PreHospitalizationSpecialistConsultation'),
                                        isCovered: vm.dependentPreHospitalizationTreatment.isCovered,
                                        isAsCharged: true,
                                        amount: null,
                                        effectivePeriod: App.localize('PerAdmission'),
                                        maxDaysTime: vm.dependentSpecialistConsultation.isUnlimited ? 'Unlimited' : vm.dependentSpecialistConsultation.coveredTimes
                                    },
                                    {
                                        name: App.localize('SecondSurgicalOpinion'),
                                        isCovered: vm.dependentPreHospitalizationTreatment.isCovered,
                                        isAsCharged: vm.dependentSurgicalOpinion.isAsCharged,
                                        amount: vm.dependentSurgicalOpinion.amount,
                                        effectivePeriod: App.localize('PerAdmission'),
                                        maxDaysTime: vm.dependentSurgicalOpinion.isUnlimited ? 'Unlimited' : vm.dependentSurgicalOpinion.coveredTimes
                                    }
                                ],
                                hasMaxDaysTime: vm.dependentPreHospitalizationTreatment.isCovered ? true : false,
                                hasEffectivePeriod: true,
                                coveredDays: vm.dependentPreHospitalizationTreatment.coveredDays
                            },
                            {
                                type: App.localize('PostHospitalizationTreatment'),
                                settings: [
                                    { name: App.localize('PostHospitalizationFollowUpAndTreatment'), isCovered: vm.dependentPostHospitalizationTreatment.isCovered, isAsCharged: vm.dependentFollowUpTreatment.isAsCharged, amount: vm.dependentFollowUpTreatment.amount, effectivePeriod: 'Per admission' }
                                ],
                                hasMaxDaysTime: false,
                                hasEffectivePeriod: true,
                                coveredDays: vm.dependentPostHospitalizationTreatment.coveredDays
                            },
                            {
                                type: App.localize('Others'),
                                settings: [
                                    { name: App.localize('OutpatientCancerTreatment'), isCovered: vm.dependentOutpatientCancerTreatment.isCovered, isAsCharged: false, amount: vm.dependentOutpatientCancerTreatment.amount, effectivePeriod: vm.perAnnumText },
                                    { name: App.localize('OutpatientKidneyDialysisTreatment'), isCovered: vm.dependentOutpatientKidneyDialysisTreatment.isCovered, isAsCharged: false, amount: vm.dependentOutpatientKidneyDialysisTreatment.amount, effectivePeriod: vm.perAnnumText },
                                ],
                                hasMaxDaysTime: false,
                                hasEffectivePeriod: true
                            },
                        ];
                        //#endregion Outpatient
                    }
                }
            }
            else {
                vm.maternitySetting = vm.benefitType.maternitySetting;
                vm.normalDelivery = vm.maternitySetting.normalDelivery;
                vm.caesareanDelivery = vm.maternitySetting.caesareanDelivery;
                vm.normalDeliveryTwinsAndAbove = vm.maternitySetting.normalDeliveryTwinsAndAbove;
                vm.caesareanDeliveryTwinsAndAbove = vm.maternitySetting.caesareanDeliveryTwinsAndAbove;

                vm.preNatalCheckUp = vm.maternitySetting.preNatalCheckUp;
                vm.postNatalFollowUp = vm.maternitySetting.postNatalFollowUp;
                vm.miscarriage = vm.maternitySetting.miscarriage;
                vm.neoNatal = vm.maternitySetting.neoNatal;

                vm.maternitySob = [
                    {
                        header: App.localize('Delivery'),
                        settings: [
                            { name: App.localize('NormalDelivery'), isCovered: vm.normalDelivery.isCovered, isAsCharged: vm.normalDelivery.isAsCharged, amount: vm.normalDelivery.amount, effectivePeriod: vm.normalDelivery.coveredCycle == 0 ? 'Per annum' : 'Per delivery' },
                            { name: App.localize('CaesareanDelivery'), isCovered: vm.caesareanDelivery.isCovered, isAsCharged: vm.caesareanDelivery.isAsCharged, amount: vm.caesareanDelivery.amount, effectivePeriod: vm.caesareanDelivery.coveredCycle == 0 ? 'Per annum' : 'Per delivery' },
                            { name: App.localize('NormalDeliveryForTwinsAndAbove'), isCovered: vm.normalDeliveryTwinsAndAbove.isCovered, isAsCharged: vm.normalDeliveryTwinsAndAbove.isAsCharged, amount: vm.normalDeliveryTwinsAndAbove.amount, effectivePeriod: vm.normalDeliveryTwinsAndAbove.coveredCycle == 0 ? 'Per annum' : 'Per delivery' },
                            { name: App.localize('CaesareanDeliveryForTwinsAndAbove'), isCovered: vm.caesareanDeliveryTwinsAndAbove.isCovered, isAsCharged: vm.caesareanDeliveryTwinsAndAbove.isAsCharged, amount: vm.caesareanDeliveryTwinsAndAbove.amount, effectivePeriod: vm.caesareanDeliveryTwinsAndAbove.coveredCycle == 0 ? 'Per annum' : 'Per delivery' }
                        ],
                        hasEffectivePeriod: true
                    },
                    {
                        header: App.localize('PreNatalCheckUp'),
                        isCovered: vm.preNatalCheckUp.isCovered,
                        coveredDaysHeader: vm.preNatalCheckUp.coveredDays,
                        settings: [
                            { name: App.localize('PreNatalCheckUp'), isCovered: vm.preNatalCheckUp.isCovered, isAsCharged: vm.preNatalCheckUp.isAsCharged, amount: vm.preNatalCheckUp.amount, effectivePeriod: 'Per annum' }
                        ],
                        hasEffectivePeriod: true
                    },
                    {
                        header: App.localize('PostNatalFollowUp'),
                        isCovered: vm.postNatalFollowUp.isCovered,
                        coveredDaysHeader: vm.postNatalFollowUp.coveredDays,
                        settings: [
                            { name: App.localize('PostNatalFollowUp'), isCovered: vm.postNatalFollowUp.isCovered, isAsCharged: vm.postNatalFollowUp.isAsCharged, amount: vm.postNatalFollowUp.amount, effectivePeriod: 'Lifetime total' },
                            { name: App.localize('Miscarriage'), isCovered: vm.miscarriage.isCovered, isAsCharged: vm.miscarriage.isAsCharged, amount: vm.miscarriage.amount, effectivePeriod: null }
                        ],
                        hasEffectivePeriod: true,
                        pregnancyRelatedComplications: ['Disseminated Intravascular Coagulation', 'Molar Pregnancy', 'Eclampsia', 'Death of Foetus', 'Amniotic Fluid Embolism']
                    },
                    {
                        header: App.localize('NeoNatal'),
                        isCovered: vm.neoNatal.isCovered,
                        coveredDaysHeader: vm.neoNatal.coveredDays,
                        settings: [
                            { name: App.localize('NewBornNICUHDU'), isCovered: vm.neoNatal.isCovered, isAsCharged: vm.neoNatal.isAsCharged, amount: vm.neoNatal.newBornNicuAmount, effectivePeriod: 'Per annum' },
                            { name: App.localize('NewBornIncubation'), isCovered: vm.neoNatal.isCovered, isAsCharged: vm.neoNatal.isAsCharged, amount: vm.neoNatal.newBornIncubationAmount, effectivePeriod: 'Per annum' },
                            { name: App.localize('NewBornPhototherapyTreatment'), isCovered: vm.neoNatal.isCovered, isAsCharged: vm.neoNatal.isAsCharged, amount: vm.neoNatal.newBornPhotoTherapyAmount, effectivePeriod: 'Per annum' }
                        ],
                        hasEffectivePeriod: true
                    }
                ];
            }
            vm.loading = false;
        }

        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.loading = false;

        init();

        function coveredCycleToEffectivePeriod(coveredCycle = null) {
            var effectivePeriod = 'na';
            switch (coveredCycle) {
                case 1: effectivePeriod = 'Per day'; break;
                case 2: effectivePeriod = 'Per day'; break;
                case 3: effectivePeriod = 'Per annum'; break;
                case 4: effectivePeriod = 'Per employment contract'; break;
                case 5: effectivePeriod = 'Per report'; break;
                case 6: effectivePeriod = 'Per admission'; break;
                case 7: effectivePeriod = 'Per disability'; break;
            }
            return effectivePeriod;
        }
    }

    angular.module('app').component('corporateBenefitGroupsInpatientBenefitDetailScheduleOfBenefitsComponent', {
        templateUrl: require('/App/corporate/views/benefitGroups/inpatient/benefitDetail/scheduleOfBenefits/scheduleOfBenefits.component.html'),
        controller: [controller],
        controllerAs: 'vm',
        bindings: {
            benefitType: '<'
        }
    });
})();
