(function () {
    'use strict';

    angular.module('app').component('hostHospitalizationExclusionsComponent', {
        templateUrl: require('/App/host/views/hospitalizationExclusions/exclusions/exclusions.component.html'),
        controller: ['$sce', 'moment', controller],
        controllerAs: 'vm',
        bindings: {
            exclusions: '<',
            loading: '<'
        }
    });

    function controller($sce, moment) {
        var vm = this;
        var today = moment();

        vm.displaySafeHtml = displaySafeHtml;
        vm.showEffectiveDate = showEffectiveDate;

        /* Functions*/

        function displaySafeHtml(html) {
            return $sce.trustAsHtml(html);
        }

        function showEffectiveDate(date) {
            return moment(date) > today;
        }

        /* End of Functions*/
    }
})();
