/* global swal */

import _ from 'lodash';

(() => {
  angular
    .module('finance')
    .controller(
      'finance.views.debugTools.createMockBankAccountDepositModal',
      CreateMockBankAccountDepositModalController
    );

  CreateMockBankAccountDepositModalController.$inject = [
    '$scope',
    '$uibModalInstance',
    'abp.services.finance.debugTools',
    'moment',
  ];

  function CreateMockBankAccountDepositModalController(
    $scope,
    $uibModalInstance,
    debugToolsSvc,
    moment
  ) {
    const vm = this;

    vm.saving = 0;

    vm.channels = [
      {
        displayName: 'Citibank Malaysia',
        countryCode: 'MY',
        channel: 'Citibank_MY',
        currencyCode: 'MYR',
      },
    ];

    vm.input = {
      channel: _.first(vm.channels).channel,
      amount: 0,
      accountNumber: null,
      currencyCode: _.first(vm.channels).currencyCode,
      transactionDate: moment(),
      recipientInformation: '',
      depositInformation: '',
    };

    vm.setCurrencyCode = setCurrencyCode;
    vm.send = send;
    vm.cancel = cancel;

    init();

    function init() {}

    function setCurrencyCode() {
      if (vm.input.channel != null) {
        vm.input.currencyCode = _.find(
          vm.channels,
          (c) => c.channel === vm.input.channel
        ).currencyCode;
      } else {
        vm.input.currencyCode = '$';
      }
    }

    function send() {
      const isValid = App.isFormValid($scope.createMockBankAccountDepositForm);
      if (isValid) {
        swal(
          {
            title: 'Create mock bank account deposit?',
            text: App.localize('SentToSystemAndCannotBeUndoneNarrative'),
            type: 'warning',
            confirmButtonColor: '#1ab394',
            confirmButtonText: App.localize('Yes'),
            cancelButtonText: App.localize('No'),
            showCancelButton: true,
            closeOnConfirm: false,
          },
          (isConfirm) => {
            if (isConfirm) {
              createMockBankAccountDeposit();
            }
            swal.close();
          }
        );
      }
    }

    function createMockBankAccountDeposit() {
      vm.saving += 1;
      debugToolsSvc
        .createMockBankAccountDeposit(vm.input)
        .success(() => {
          abp.notify.success('Successfully created a mock bank account deposit.');
        })
        .finally(() => {
          vm.saving -= 1;
          $uibModalInstance.close();
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
