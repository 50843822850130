(function () {
    'use strict';

    angular.module('app').component('hostCorporatesSupportPlansCreateEditScheduleOfBenefitOthersComponent', {
        templateUrl: require('/App/host/views/corporates/supportPlans/createEdit/scheduleOfBenefit/others/others.component.html'),
        controller: controller,
        controllerAs: 'vm',
        bindings: {
            scheduleOfBenefit: '<',
            currencyCode: '<'
        }
    });

    function controller() {
        var vm = this;
        vm.$onChanges = onChanges;

        vm.dayCareProcedureText = App.localize('DayCareProcedure');
        vm.outpatientCancerTreatmentText = App.localize('OutpatientCancerTreatment');
        vm.outpatientKidneyDialysisTreatmentText = App.localize('OutpatientKidneyDialysisTreatment');
        vm.perAnnumText = App.localize('PerAnnum');
        vm.outpatientCancerTreatmentAmountType = null;
        vm.outpatientKidneyDialysisTreatmentAmountType = null;

        vm.toggleOthers = function () {
            vm.minOutpatientCancerTreatmentAmount = vm.outpatientCancerTreatmentAmountType === 0 ? 1 : 0;
            vm.minOutpatientKidneyDialysisTreatmentAmount = vm.outpatientKidneyDialysisTreatmentAmountType === 0 ? 1 : 0;

            setOutpatientCancerTreatmentAmountType();
            setOutpatientKidneyDialysisTreatmentAmountType();
        };


        function init() {
            vm.outpatientCancerTreatmentAmountType = vm.scheduleOfBenefit.outpatientCancerTreatment.isCovered ? 0 : 1;
            vm.outpatientKidneyDialysisTreatmentAmountType = vm.scheduleOfBenefit.outpatientKidneyDialysisTreatment.isCovered ? 0 : 1;
        }

        function setOutpatientCancerTreatmentAmountType() {
            switch (vm.outpatientCancerTreatmentAmountType) {
                case 0:
                    vm.scheduleOfBenefit.outpatientCancerTreatment.isCovered = true;
                    break;

                case 1:
                    vm.scheduleOfBenefit.outpatientCancerTreatment.isCovered = false;
                    break;

                default:
                    break;
            }
        }

        function setOutpatientKidneyDialysisTreatmentAmountType() {
            switch (vm.outpatientKidneyDialysisTreatmentAmountType) {
                case 0:
                    vm.scheduleOfBenefit.outpatientKidneyDialysisTreatment.isCovered = true;
                    break;

                case 1:
                    vm.scheduleOfBenefit.outpatientKidneyDialysisTreatment.isCovered = false;
                    break;

                default:
                    break;
            }
        }

        function onChanges(changes) {
            if (changes.scheduleOfBenefit && changes.scheduleOfBenefit.currentValue) {
                vm.toggleOthers();
            }
        }

        angular.element(document).ready(function () {
            init();
        });

    }
})();
