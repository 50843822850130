(() => {
  angular
    .module('app')
    .controller('host.views.debugTools.emailTester.index', EmailTesterController);

  EmailTesterController.$inject = ['$scope', 'abp.services.app.debugTool'];

  function EmailTesterController($scope, debugToolSvc) {
    const vm = this;
    vm.loading = 0;
    vm.saving = 0;
    vm.requestParams = {
      recipients: null,
    };
    vm.send = send;

    $scope.$on('$viewContentLoaded', () => {
      App.initAjax();
    });

    function send() {
      vm.saving += 1;
      debugToolSvc
        .sendTestEmail(vm.requestParams)
        .success(() => {
          abp.notify.success('Successfully sent!');
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }
  }
})();
