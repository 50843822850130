(() => {
  angular.module('finance').component('creditMemoDetailCreditMemoSenderComponent', {
    templateUrl: require('./creditMemoSender.component.html'),
    controller: CreditMemoDetailCreditMemoSenderComponent,
    controllerAs: 'vm',
    bindings: {
      creditMemo: '<',
      creditMemoFrom: '<',
      hasProfilePhoto: '<'
    },
  });

  CreditMemoDetailCreditMemoSenderComponent.$inject = [];

  function CreditMemoDetailCreditMemoSenderComponent() {
    const vm = this;

    vm.loading = 0;

    init();

    function init() {}
  }
})();