(function () {
    angular.module('app')
        .component('clinicPatientAdmissionDetailsPatientDetailsComponent', {
            require: {
                container: '^clinicPatientAdmissionDetailsPatientDetailsComponent'
            },
            templateUrl: require('/App/clinic/views/patient/admissionDetails/patientDetails/patientDetails.component.html'),
            controller: ['abp.services.app.inpatientGuaranteeLetter', 'Hms.GuaranteeLetters.GuaranteeLetterStatus', controller],
            controllerAs: 'vm',
            bindings: {
                guaranteeLetterNumber: '<',
                timeZoneId: '='
            }
        });

    function controller(inpatientGuaranteeLetterSvc, enumGuaranteeLetterStatus) {
        var vm = this;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

        vm.enums = {};
        vm.enums.guaranteeLetterStatus = enumGuaranteeLetterStatus;

        init();

        function init() {
            getPatientAdmissionDetail();
        }

        function getPatientAdmissionDetail() {
            vm.loading++;
            inpatientGuaranteeLetterSvc.getPatientAdmissionDetail({
                id: vm.guaranteeLetterNumber
            }).success(function (data) {
                vm.patientDetails = data;
                vm.currencyCode = vm.patientDetails.currencyCode;
                vm.patientDetails.admissionDate = getDateTimeString(vm.patientDetails.admissionDate, vm.timeZoneId);
            }).finally(function (e) {
                vm.loading--;
            });
        }

        function getDateTimeString(date, timeZoneId) {
            return App.getDateTimeString(date, timeZoneId);
        }
    }
})();
