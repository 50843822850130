/**
 * markdownEditor - Directive for Bootstrap Markdown
 */

(() => {
  angular.module('app').directive('markdownEditor', markdownEditorDirective);

  markdownEditorDirective.$inject = [];

  function markdownEditorDirective() {
    const directive = {
      link,
      restrict: 'A',
      require: 'ngModel',
    };
    return directive;

    function link(_scope, element, _attrs, ngModel) {
      $(element).markdown({
        savable: false,
        onChange(e) {
          ngModel.$setViewValue(e.getContent());
        },
      });
    }
  }
})();
