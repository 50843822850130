// TODO: Obsolete HM-3738

(function () {
    var controllerId = 'host.views.employees.index';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', '$uibModal', '$filter', 'abp.services.app.hostEmployee',
        function ($scope, $state, $stateParams, $uibModal, $filter, hostEmployeeSvc) {
            function init() {
                if (vm.requestParams.filter) vm.getEmployees();
            }

            var vm = this;
            vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
            vm.defaultMaxAllowance = parseFloat(abp.setting.get("Hms.General.MaxAllowanceAmountLimit"));
            vm.loading = 0;
            vm.employees = null;
            vm.requestParams = {
                filter: $stateParams.filter,
                includeRelated: $stateParams.includeRelated,
                checkInDate: $stateParams.checkInDate
            };

            var hasManageInpatient = abp.auth.isGranted('Host.Claims.Inpatient.Manage');
            vm.permissions = {
                canViewTickets: abp.auth.isGranted('PatientTickets'),
                canReimburse: abp.auth.isGranted('PatientTickets.Reimbursement.Create') || hasManageInpatient,
                canCreateManualTicket: abp.auth.isGranted('PatientTickets.Host.Manual.Create') || hasManageInpatient,
                canViewClaims: abp.auth.isGranted('Host.Claims'),
                canCreateGuaranteeLetter: abp.auth.isGranted('GuaranteeLetters.Host.Create')
            };

            function registerStateParams() {
                var processed = {};
                if (vm.requestParams.checkInDate)
                    processed.checkInDate = vm.requestParams.checkInDate.format('YYYY-MM-DD');

                $state.transitionTo($state.current, $.extend({}, vm.requestParams, processed), { notify: false });
            }

            vm.getEmployees = function () {
                if (vm.loading) return;

                registerStateParams();
                vm.loading++;
                hostEmployeeSvc.getEmployees(vm.requestParams).success(function (data) {
                    vm.employees = data.items || [];
                    _.each(vm.employees, function (employee) {
                        var ticketClinicTypes = [];
                        _.each(employee.clinicTypes, function (data) {
                            data.benefits = [];
                            if (data.coPay) {
                                if (data.coPay.isPercentage) {
                                    data.coPay.title = App.localize('CopayX', data.coPay.value + '%');
                                    data.coPay.text = App.localize('CopayPercentage', data.coPay.value + '%');
                                } else {
                                    var valueText = $filter('currencyFormat')(data.coPay.value, vm.currencyCode);
                                    data.coPay.title = App.localize('CopayX', valueText);
                                    data.coPay.text = App.localize('CopayFixedAmount', valueText);
                                }
                            }
                            if (data.balancePools && data.balancePools.length) {
                                _.each(data.balancePools, function (d) {
                                    var title = _.join(_.map(d.benefitTypes, function (bt) {
                                        if (bt.isCustom) {
                                            var strCt = 'CT' + bt.id;
                                            var idx = _.findIndex(employee.customizedTreatments, function (ctn) { return ctn.value === bt.id; });
                                            if (idx !== -1) strCt = employee.customizedTreatments[idx].name;
                                            return strCt;
                                        }
                                        else if (bt.id === 251) {
                                            return App.localize('Hospitalization');
                                        }
                                        else {
                                            return App.localize(bt.name);
                                        }
                                    }), '/');

                                    // TODO: Better design? localisation required

                                    var isBudget = d.modifierType === 0 || d.modifierType === 2;
                                    var cycle = '';
                                    if (!d.isUnlimited) {
                                        switch (d.modifierCycle) {
                                            case 0: cycle = 'every ' + d.interval + ' years '; break;
                                            case 1: cycle = 'annual '; break;
                                            case 2: cycle = 'monthly '; break;
                                            case 3: cycle = 'daily '; break;
                                            case 4: cycle = 'per visit '; break;
                                            case 5: cycle = '6 months '; break;
                                            case 6: cycle = '4 months '; break;
                                            case 7: cycle = '3 months '; break;
                                            case 8: cycle = '2 months '; break;
                                        }
                                        if (d.modifierType === 2 || d.modifierType === 3) cycle += 'overdraft ';
                                    }
                                    title += ' (' + cycle + (isBudget ? 'budget' : 'visit') + ')';

                                    var isEmptyExcess = d.isExcess && !d.employeePortion;
                                    if (!isEmptyExcess) {
                                        data.benefits.push({
                                            title: title,
                                            isUnlimited: d.isUnlimited,
                                            isExcess: d.isExcess,
                                            isBudget: isBudget,
                                            limit: d.limit,
                                            used: d.used,
                                            locked: d.locked,
                                            balance: d.balance
                                        });
                                    }
                                });
                            }
                            data.allowance = Math.min(data.allowance, vm.defaultMaxAllowance);

                            if (data.serviceType === 5) {
                                _.assign(employee, {
                                    hasSpecialist: true
                                });
                            }

                            if (data.serviceType !== 4) {
                                ticketClinicTypes.push({
                                    canCreate: true,
                                    name: data.name,
                                    value: data.type,
                                    serviceType: data.serviceType
                                });
                            }
                        });

                        if (employee.supportPlanName) {
                            _.assign(employee, {
                                inpatientTypes: _.filter(employee.clinicTypes, function (data) {
                                    return data.isHospitalize;
                                })
                            });

                            employee.clinicTypes = _.filter(employee.clinicTypes, function (data) {
                                return !data.isHospitalize;
                            });

                            employee.customizedTreatments.unshift({ canCreate: true, name: App.localize('HospitalizationTicket'), value: 251 });
                        }

                        employee.ticketClinicTypes = ticketClinicTypes;

                        employee.checkInsDisplayLimit = 1; // Default the number of check-ins to be display to 1.
                        employee.benefitsActiveTab = 0; // Default to non-hospitalization tab (0).
                    });
                }).finally(function (e) {
                    vm.loading--;
                });
            };

            vm.getUserBgColor = function (id) {
                return 'user_bgcolor_' + ((id || 0) % 8 + 1);
            };

            vm.getUserInitial = function (user) {
                var fullName = (user.name || ' ').split(' ');
                var initial = '';
                for (var i = 0; i < fullName.length; i++) {
                    if (fullName[i].length) {
                        initial += fullName[i][0];
                    }
                }
                if (initial && initial.length > 3) initial = '' + initial[0];
                return initial;
            };

            vm.toggleFlagged = function (employee) {
                if (vm.loading) return;
                var attemptFlag = !employee.isFlagged;

                vm.loading++;
                hostEmployeeSvc.flagEmployee({
                    employeeId: employee.id,
                    isFlagged: attemptFlag
                }).success(function (data) {
                    employee.isFlagged = attemptFlag;
                    abp.notify.info(App.localize(employee.isFlagged ? 'PatientXFlagged' : 'PatientXUnflagged', employee.name));
                }).finally(function () {
                    vm.loading--;
                });
            };

            vm.checkAccess = function (employeeId) {
                $uibModal.open({
                    templateUrl: require('/App/host/views/employees/checkAccessModal.html'),
                    controller: 'host.views.employees.checkAccessModal as vm',
                    backdrop: 'static',
                    resolve: {
                        employeeId: function () {
                            return employeeId;
                        }
                    }
                });
            };

            vm.viewClaimPolicy = function (employee) {
                $uibModal.open({
                    templateUrl: require('/App/host/views/employees/claimPolicy/claimPolicy.modal.html'),
                    controller: 'host.views.employees.claimPolicyModal as vm',
                    size: 'lg',
                    backdrop: 'static',
                    resolve: {
                        memberId: function () {
                            return employee.id;
                        }
                    }
                });
            };

            vm.getUncoveredTreatments = function (employeeId, clinicType, clinicTypeName) {
                $uibModal.open({
                    templateUrl: require('/App/host/views/employees/uncoveredTreatmentTreeModal.html'),
                    controller: 'host.views.employees.uncoveredTreatmentTreeModal as vm',
                    backdrop: 'static',
                    resolve: {
                        employeeId: function () {
                            return employeeId;
                        },
                        clinicType: function () {
                            return clinicType;
                        },
                        clinicTypeName: function () {
                            return clinicTypeName;
                        }
                    }
                });
            };

            vm.getGLHistory = function (employeeId) {
                $uibModal.open({
                    templateUrl: require('/App/host/views/employees/getGLHistoryModal.html'),
                    controller: 'host.views.employees.getGLHistoryModal as vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        employeeId: function () {
                            return employeeId;
                        }
                    }
                });
            };

            vm.createGuaranteeLetter = function (employee) {
                $uibModal.open({
                    templateUrl: require('/App/common/views/guaranteeLetters/createModal.html'),
                    controller: 'common.views.guaranteeLetters.createModal as vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        employee: function () {
                            return employee;
                        }
                    }
                });
            };

            init();
        }
    ]);
})();
