(function () {
    var controllerId = 'host.views.employees.claimPolicyModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$uibModalInstance', 'memberId',
        function ($uibModalInstance, memberId) {
            var vm = this;
            vm.memberId = memberId;

            vm.cancel = cancel;

            /* Functions */

            function cancel() {
                $uibModalInstance.dismiss();
            }

            /* End of functions */
        }
    ]);
})();
