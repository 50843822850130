(() => {
  angular.module('app').filter('fulfillmentStatus', fulfillmentStatus);

  fulfillmentStatus.$inject = [];

  function fulfillmentStatus() {
    return fulfillmentStatusFilter;

    function fulfillmentStatusFilter(value) {
      switch (value) {
        case 0:
          return App.localize('FulfillmentStatus_Draft');
        case 1:
          return App.localize('FulfillmentStatus_Published');
        case 2:
          return App.localize('FulfillmentStatus_Voided');
        default:
          return value;
      }
    }
  }
})();
