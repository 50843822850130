(() => {
  angular
    .module('app')
    .controller('revenue.views.subscriptions.create.confirmationModal', ControllerController);

  ControllerController.$inject = [
    '$uibModalInstance',
    'subscriptions',
    'subscriptionPlan',
    'categoryCharges',
    'currencyCode',
    'Hms.Revenue.SubscriptionScope',
    'Hms.Revenue.SubscriptionPlanCategory',
    'Hms.Revenue.SubscriptionPlanPricingMode',
    'Hms.Revenue.SubscriptionPlanChargeMode',
    'Hms.Revenue.SubscriptionBillingMode',
    'Hms.Revenue.SubscriptionBillingFrequency',
    'Hms.Revenue.OutpatientServiceFeatures',
    'Hms.Revenue.InpatientServiceFeatures',
  ];

  function ControllerController(
    $uibModalInstance,
    subscriptions,
    subscriptionPlan,
    categoryCharges,
    currencyCode,
    enumSubscriptionScope,
    enumSubscriptionPlanCategory,
    enumSubscriptionPlanPricingMode,
    enumSubscriptionPlanChargeMode,
    enumSubscriptionBillingMode,
    enumSubscriptionBillingFrequency,
    enumOutpatientServiceFeatures,
    enumInpatientServiceFeatures
  ) {
    const vm = this;

    vm.enums = {};
    vm.cancel = cancel;
    vm.confirm = confirm;
    vm.subscriptions = subscriptions;
    vm.subscriptionPlan = subscriptionPlan;
    vm.categoryCharges = categoryCharges;
    vm.currencyCode = currencyCode;
    vm.subscription = [];

    vm.enums.subscriptionScope = enumSubscriptionScope;
    vm.enums.subscriptionPlanCategory = enumSubscriptionPlanCategory;
    vm.enums.subscriptionPlanPricingMode = enumSubscriptionPlanPricingMode;
    vm.enums.subscriptionPlanChargeMode = enumSubscriptionPlanChargeMode;
    vm.enums.subscriptionBillingMode = enumSubscriptionBillingMode;
    vm.enums.subscriptionBillingFrequency = enumSubscriptionBillingFrequency;
    vm.enums.outpatientServiceFeatures = enumOutpatientServiceFeatures;
    vm.enums.inpatientServiceFeatures = enumInpatientServiceFeatures;

    vm.serviceFeatures = _.union(
      vm.enums.outpatientServiceFeatures.values,
      vm.enums.inpatientServiceFeatures.values
    );

    vm.getSubscriptionDetails = getSubscriptionDetails;
    vm.getSubsidiaries = getSubsidiaries;
    vm.getServiceFeaturesAsString = getServiceFeaturesAsString;
    vm.getEffectivePrice = getEffectivePrice;
    vm.getLatestPricingStartDate = getLatestPricingStartDate;
    vm.getSubscriptionScope = getSubscriptionScope;
    vm.getSubscriptionPlanCategory = getSubscriptionPlanCategory;
    vm.getChargeMode = getChargeMode;
    vm.getPricingMode = getPricingMode;
    vm.getBillingMode = getBillingMode;
    vm.getBillingFrequency = getBillingFrequency;

    function getSubscriptionDetails() {
      if (vm.subscriptionId) {
        vm.subscription = vm.subscriptions.find((x) => x.id === vm.subscriptionId);
      }
    }

    function getSubsidiaries(subsidiaryNames) {
      let subsidiaries = '';
      if (subsidiaryNames) {
        subsidiaries = subsidiaryNames.join(', ');
      }
      return subsidiaries;
    }

    function getServiceFeaturesAsString(serviceFeaturesArray) {
      const values = [];
      _.forEach(serviceFeaturesArray, (item) => {
        const serviceFeature = _.find(vm.serviceFeatures, (i) => i.id === item);
        values.push(serviceFeature.name);
      });
      return values.join(', ');
    }

    function getEffectivePrice(charges) {
      let effectivePrice = 0;
      if (!charges.effectivePrice && charges.effectivePrice !== 0) {
        effectivePrice = charges.retailPrice;
      } else {
        effectivePrice = charges.effectivePrice;
      }
      return effectivePrice;
    }

    function getLatestPricingStartDate(charges) {
      let latestPricingStartDateOffset =
        charges.subscriptionPlanPricingTiers[0].pricingStartDateOffset;

      _.each(charges.subscriptionPlanPricingTiers, (subscriptionPlanPricingTier) => {
        if (subscriptionPlanPricingTier.pricingStartDateOffset > latestPricingStartDateOffset)
          latestPricingStartDateOffset = subscriptionPlanPricingTier.pricingStartDateOffset;
      });
      return latestPricingStartDateOffset;
    }

    function getSubscriptionScope(subscriptionScope) {
      if (subscriptionScope === vm.enums.subscriptionScope.Corporate.id) {
        return App.localize('Corporate');
      }
      if (subscriptionScope === vm.enums.subscriptionScope.Subsidiary.id) {
        return App.localize('Subsidiary');
      }
      return '';
    }

    function getSubscriptionPlanCategory(planCategory) {
      if (planCategory === vm.enums.subscriptionPlanCategory.Outpatient.id) {
        return App.localize('OutpatientServices');
      }
      if (planCategory === vm.enums.subscriptionPlanCategory.Inpatient.id) {
        return App.localize('InpatientServices');
      }
      if (planCategory === vm.enums.subscriptionPlanCategory.Adhoc.id) {
        return App.localize('Adhoc');
      }
      return '';
    }

    function getChargeMode(chargeMode) {
      if (chargeMode === vm.enums.subscriptionPlanChargeMode.Monthly.id) {
        return App.localize('Monthly');
      }
      if (chargeMode === vm.enums.subscriptionPlanChargeMode.Yearly.id) {
        return App.localize('Yearly');
      }
      return '';
    }

    function getPricingMode(pricingMode) {
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.Fixed.id) {
        return App.localize('Fixed');
      }
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.PerMember.id) {
        return App.localize('PerMember');
      }
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.PerTicketBasis.id) {
        return App.localize('PerTicketBasis');
      }
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.PerCaseBasis.id) {
        return App.localize('PerCaseBasis');
      }
      return '';
    }

    function getBillingMode(billingMode) {
      if (billingMode === vm.enums.subscriptionBillingMode.Postpaid.id) {
        return App.localize('Postpaid');
      }
      if (billingMode === vm.enums.subscriptionBillingMode.Prepaid.id) {
        return App.localize('Prepaid');
      }
      return '';
    }

    function getBillingFrequency(billingFrequency) {
      if (billingFrequency === vm.enums.subscriptionBillingFrequency.Monthly.id) {
        return App.localize('Monthly');
      }
      if (billingFrequency === vm.enums.subscriptionBillingFrequency.Quarterly.id) {
        return App.localize('Quarterly');
      }
      if (billingFrequency === vm.enums.subscriptionBillingFrequency.HalfYearly.id) {
        return App.localize('HalfYearly');
      }
      if (billingFrequency === vm.enums.subscriptionBillingFrequency.Yearly.id) {
        return App.localize('Yearly');
      }
      return '';
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function confirm() {
      $uibModalInstance.close();
    }
  }
})();
