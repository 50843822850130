(function () {
    const controllerId = 'pharmacy.views.prescriptions.index';
    const app = angular.module('app');

    app.controller(controllerId, [
        '$state', '$stateParams', 'abp.services.app.prescription', 'moment',
        function ($state, $stateParams, prescriptionSvc, moment) {
            function init() {
                vm.setCategory($stateParams);
            }

            const vm = this;
            vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
            vm.loading = false;
            vm.selectedCategory = null;
            vm.hasMore = false;
            vm.search = '';
            vm.prescriptions = [];

            vm.columnConfigs = {
                showPrescriptionDate: false,
                showPrescriptionReference: false,
                showFulfilment: false
            };

            vm.categories = [
                {
                    name: App.localize('New'),
                    value: 'New',
                    isActive: false,
                    updateConfig: function () {
                        vm.columnConfigs.showPrescriptionDate = true;
                        vm.columnConfigs.showPrescriptionReference = false;
                        vm.columnConfigs.showFulfilment = false;
                    }
                },
                {
                    name: App.localize('Actionable'),
                    value: 'Actionable',
                    isActive: false,
                    updateConfig: function () {
                        vm.columnConfigs.showPrescriptionDate = false;
                        vm.columnConfigs.showPrescriptionReference = true;
                        vm.columnConfigs.showFulfilment = true;
                    }
                },
                {
                    name: App.localize('History'),
                    value: 'History',
                    isActive: false,
                    updateConfig: function () {
                        vm.columnConfigs.showPrescriptionDate = false;
                        vm.columnConfigs.showPrescriptionReference = true;
                        vm.columnConfigs.showFulfilment = true;
                    }
                }
            ];

            vm.dateRangeOptions = App.createDateRangePickerOptions();
            vm.dateRangeOptions.eventHandlers = {
                'apply.daterangepicker': function (ev, picker) {
                    vm.refresh();
                }
            };
            vm.dateRangeModel = {
                startDate: null,
                endDate: null
            };

            vm.setCategory = function (param) {
                let resetToNew = true;
                _.forEach(vm.categories, function (c) {
                    if (c.isActive = c.value === param.category) {
                        resetToNew = false;
                        c.updateConfig();
                    }
                });

                if (resetToNew) {
                    vm.setCategory({ category: 'New' });
                    return;
                }

                vm.selectedCategory = param.category;
                vm.prescriptionNumber = param.prescriptionNumber ? param.prescriptionNumber : null;
                vm.referenceNumber = param.referenceNumber ? param.referenceNumber : null;
                vm.customer = param.customer ? param.customer : null;
                vm.customerOrganization = param.customerOrganization ? param.customerOrganization : null;
                vm.startDate = param.startDate ? param.startDate : null;
                vm.endDate = param.endDate ? param.endDate : null;
                vm.dateRangeModel = {
                    startDate: vm.startDate,
                    endDate: vm.endDate
                };
                $state.transitionTo($state.current,
                    {
                        category: vm.selectedCategory,
                        prescriptionNumber: vm.prescriptionNumber,
                        referenceNumber: vm.referenceNumber,
                        customer: vm.customer,
                        customerOrganization: vm.customerOrganization,
                        startDate: vm.startDate,
                        endDate: vm.endDate
                    },
                    { notify: false });
                vm.refresh();
            };

            vm.refresh = function () {
                if (!vm.selectedCategory) return;

                var input = getRequestInput();

                vm.loading++;
                prescriptionSvc.getPrescriptions(input)
                    .success(function (data) {
                        _.forEach(vm.categories, function (c) {
                            switch (c.value) {
                                case 'New': c.count = data.newCount; break;
                                case 'Actionable': c.count = data.actionableCount; break;
                                case 'History': c.count = data.historyCount; break;
                            }
                        });

                        _.forEach(data.items, function (d) {
                            if (d.fulfillmentCount > 0) {
                                d.fulfillmentString = '' + d.fulfillments.length + '/' + d.fulfillmentCount;
                            }
                            if (d.fulfillments.length) d.lastFulfillment = d.fulfillments[0];
                        });

                        vm.prescriptions = data.items;
                        vm.isOrigin = data.isOrigin;
                        vm.hasMore = false;
                    }).finally(function () {
                        vm.loading--;
                    });
            };

            function getRequestInput() {
                const params = {
                    prescriptionNumber: _.trim(vm.prescriptionNumber),
                    referenceNumber: _.trim(vm.referenceNumber),
                    customer: _.trim(vm.customer),
                    customerOrganization: _.trim(vm.customerOrganization),
                    category: vm.selectedCategory
                };

                var input = $.extend({}, params, vm.dateRangeModel);
                if (input.startDate) input.startDate = moment(input.startDate).toJSON();
                if (input.endDate) input.endDate = moment(input.endDate).toJSON();

                return input;
            }

            vm.exportToExcel = function () {
                var input = getRequestInput();
                vm.exporting++;
                prescriptionSvc.getPrescriptionsToExcel(input).success(function (data) {
                    App.downloadTempFile(data);
                }).finally(function () {
                    vm.exporting--;
                });
            };

            init();
        }
    ]);
})();
