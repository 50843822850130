(function () {
    var controllerId = 'host.views.corporates.supportPlan.index';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', '$filter', 'abp.services.app.supportPlan', 'Hms.SupportPlans.SupportPlan',
        function ($scope, $state, $stateParams, $filter, supportPlanSvc, constsSupportPlan) {
            function init() {
                getSupportPlan();
            }

            function getSupportPlan() {
                vm.loading++;
                supportPlanSvc.getSupportPlanForEdit({
                    id: vm.supportPlanId,
                    corporateId: vm.corporateId
                }).success(function (data) {
                    vm.supportPlan = new SupportPlanModel(data);
                }).finally(function () {
                    vm.loading--;
                });
            }

            var ccyFilter = $filter('currencyFormat');
            var inpatientDetails = [new TreatmentModel(251, App.localize('Hospitalization'), 'fas fa-bed', true)];

            var vm = this;
            vm.constsSupportPlan = constsSupportPlan;
            vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
            vm.supportPlanId = $stateParams.supportPlanId;
            vm.corporateId = $stateParams.organizationId;
            vm.isInpatient = $stateParams.isInpatient;
            vm.loading = 0;
            vm.saving = 0;
            vm.isCreate = !vm.supportPlanId;
            vm.buttonLabel = vm.isCreate ? App.localize("Save") : App.localize("Update");

            vm.submit = function () {
                vm.saving = true;
                supportPlanSvc.createOrUpdateSupportPlan(vm.supportPlan.toDto()).success(function () {
                    abp.notify.info(App.localize("SuccessfullySaved"));
                    $state.go('host.corporateSupportPlans', { id: vm.corporateId });
                }).finally(function () {
                    vm.saving = false;
                });
            };

            function automap(source, target) {
                _.forIn(source, function (v, k) { target[k] = source[k]; });
            }

            function SupportPlanModel(dto) {
                var _this = this;

                // Basic properties

                _this.id = 0;
                _this.tenantId = vm.corporateId;
                _this.planName = '';
                _this.displayName = '';
                _this.remarks = '';

                // Benefits

                _this.hasDependentCovered = 1;
                _this.principal = inpatientDetails;
                _this.dependent = inpatientDetails;

                // Benefit Modifiers

                _this.modifiers = [];

                // Benefit summaries

                _this.principalSummaries = [];
                _this.showPrincipalFootnote = false;
                _this.dependentSummaries = [];
                _this.showDependentFootnote = false;

                // Functions

                _this.addModifier = function () {
                    var modifier = new Modifier(_this);
                    vm.supportPlan.modifiers.push(modifier);
                };

                _this.removeModifier = function (index) {
                    abp.message.confirm(App.localize('DeleteBenefitModifierWarningMessage'), App.localize('AreYouSure'), function (d) {
                        if (d) {
                            $scope.$apply(function () {
                                vm.supportPlan.modifiers.splice(index, 1);
                                _this.refreshResult();
                            });
                            return true;
                        } else {
                            return false;
                        }
                    });
                };

                _this.refreshResult = function () {
                    var principalSummaries = [],
                        dependentSummaries = [],
                        showPrincipalFootnote = false,
                        showDependentFootnote = false;

                    var coverDependent = !!_this.hasDependentCovered;
                    var principal = _.map(_.filter(_this.principal, 'valid'), function (x) { return new BenefitResult(x, true, true); });
                    var dependent = coverDependent ? _.map(_.filter(_this.dependent, 'valid'), function (x) { return new BenefitResult(x, true, true); }) : [];
                    if (!dependent.length) {
                        coverDependent = false;
                    }
                    var coverPrincipal = principal.length > 0;

                    // Group result into stacks

                    _.each(_this.modifiers, function (mod) {
                        if (mod.cycle === 4 && mod.type !== 0) {
                            mod.cycle = 3;
                        }
                    });

                    _.each(_.groupBy(_this.modifiers, function (x) {
                        var interval = x.cycle === 0 ? x.interval : 1;
                        return x.type + ':' + interval + ':' + x.cycle;
                    }), function (values) {
                        _.each(values, function (modifier) {
                            var applicableTreatments = _.filter(modifier.treatments, function (x) { return x.applicable; });
                            var principalPortion = [];
                            var dependentPortion = [];

                            var beneficiary = coverDependent ? modifier.beneficiary : 1;
                            var poolMode = coverDependent ? modifier.poolMode : 2;

                            _.each(applicableTreatments, function (t) {
                                var benefit;
                                if (coverPrincipal && (beneficiary & 1) > 0) {
                                    benefit = _.find(principal, function (x) { return x.treatment.id === t.treatment.id; });
                                    if (benefit) {
                                        if (modifier.type === 0) {
                                            benefit.unlimitedBudget = false;
                                        } else if (modifier.type === 1) {
                                            benefit.unlimitedVisit = false;
                                        }
                                        principalPortion.push(t.treatment);
                                    }
                                }
                                if (coverDependent && (beneficiary & 2) > 0) {
                                    benefit = _.find(dependent, function (x) { return x.treatment.id === t.treatment.id; });
                                    if (benefit) {
                                        if (modifier.type === 0) {
                                            benefit.unlimitedBudget = false;
                                        } else if (modifier.type === 1) {
                                            benefit.unlimitedVisit = false;
                                        }
                                        dependentPortion.push(t.treatment);
                                    }
                                }
                            });

                            if (principalPortion.length || dependentPortion.length) {
                                var uniquePortion = _.uniqBy(_.concat(principalPortion, dependentPortion), 'id'),
                                    modifierTreatmentNames = _.join(_.map(uniquePortion, 'name'), '+'),
                                    principalTreatmentNames = _.join(_.map(principalPortion, 'name'), '+'),
                                    dependentTreatmentNames = _.join(_.map(dependentPortion, 'name'), '+');

                                if (poolMode === 0 && (!principalPortion.length || !dependentPortion.length)) {
                                    poolMode = 2;
                                }

                                var principalSummaryRecord = null;
                                if (principalTreatmentNames) {
                                    principalSummaryRecord = _.find(principalSummaries, function (x) { return x.key === principalTreatmentNames; });

                                    if (!principalSummaryRecord) {
                                        principalSummaries.push(principalSummaryRecord = new BenefitSummary(principalTreatmentNames));
                                    }
                                }

                                var dependentSummaryRecord = null;
                                if (dependentTreatmentNames) {
                                    dependentSummaryRecord = _.find(dependentSummaries, function (x) { return x.key === dependentTreatmentNames; });

                                    if (!dependentSummaryRecord) {
                                        dependentSummaries.push(dependentSummaryRecord = new BenefitSummary(dependentTreatmentNames));
                                    }
                                }

                                // Get modifier description

                                var str = '';
                                var strValue = _.isNil(modifier.value) ? '?' : modifier.value;
                                switch (modifier.type) {
                                    case 0: str += ' ' + ccyFilter(modifier.value, vm.currencyCode); break;
                                    case 1: str += ' ' + strValue + ' ' + App.localize('VisitLimit').toLowerCase(); break;
                                    case 2: str += ' ' + ccyFilter(modifier.value, vm.currencyCode) + ' (' + App.localize('BudgetOverdraft').toLowerCase() + ')'; break;
                                    case 3: str += ' ' + strValue + App.localize('VisitOverdraft').toLowerCase(); break;
                                }

                                var cycle = '';
                                switch (modifier.cycle) {
                                    case 0: cycle = App.localize('EveryXYears', modifier.interval).toLowerCase(); break;
                                    case 1: cycle = App.localize('Annually').toLowerCase(); break;
                                    case 2: cycle = App.localize('Monthly').toLowerCase(); break;
                                    case 3: cycle = App.localize('Daily').toLowerCase(); break;
                                    case 4: cycle = App.localize('PerVisit').toLowerCase(); break;
                                    case 5: cycle = App.localize('Every6Months').toLowerCase(); break;
                                    case 6: cycle = App.localize('Every4Months').toLowerCase(); break;
                                    case 7: cycle = App.localize('Every3Months').toLowerCase(); break;
                                    case 8: cycle = App.localize('Every2Months').toLowerCase(); break;
                                }
                                str += ' ' + cycle;

                                var allStr, principalStr, dependentStr;

                                if (poolMode === 0) {
                                    allStr = str;
                                    if (principalTreatmentNames !== dependentTreatmentNames) {
                                        if (principalPortion.length) {
                                            allStr += ' (' + App.localize('Principal') + ': ' + principalTreatmentNames + ')';
                                        }
                                        if (dependentPortion.length) {
                                            allStr += ' (' + App.localize('Dependent') + ': ' + dependentTreatmentNames + ')';
                                        }
                                    }

                                    if (principalSummaryRecord) {
                                        principalSummaryRecord.addItem(modifier.type, cycle, '[1]', modifier.value || 0);
                                        showPrincipalFootnote = true;
                                    }
                                    if (dependentSummaryRecord) {
                                        dependentSummaryRecord.addItem(modifier.type, cycle, '[1]', modifier.value || 0);
                                        showDependentFootnote = true;
                                    }
                                } else if (poolMode === 1) {
                                    principalStr = '';
                                    if (principalPortion.length) {
                                        principalStr = str;
                                        if (principalTreatmentNames !== modifierTreatmentNames) {
                                            principalStr += ' (' + principalTreatmentNames + ')';
                                        }
                                    }

                                    dependentStr = '';
                                    if (dependentPortion.length) {
                                        dependentStr = str;
                                        if (dependentTreatmentNames !== modifierTreatmentNames) {
                                            dependentStr += ' (' + dependentTreatmentNames + ')';
                                        }
                                    }

                                    if (principalSummaryRecord) {
                                        principalSummaryRecord.addItem(modifier.type, cycle, '', modifier.value || 0);
                                    }
                                    if (dependentSummaryRecord) {
                                        dependentSummaryRecord.addItem(modifier.type, cycle, '', modifier.value || 0);
                                    }
                                } else if (poolMode === 2) {
                                    principalStr = '';
                                    if (principalPortion.length) {
                                        principalStr = str;
                                        if (principalTreatmentNames !== modifierTreatmentNames) {
                                            principalStr += ' (' + principalTreatmentNames + ')';
                                        }
                                    }

                                    dependentStr = '';
                                    if (dependentPortion.length) {
                                        dependentStr = str;
                                        if (dependentTreatmentNames !== modifierTreatmentNames) {
                                            dependentStr += ' (' + dependentTreatmentNames + ')';
                                        }
                                    }

                                    if (principalSummaryRecord) {
                                        principalSummaryRecord.addItem(modifier.type, cycle, '', modifier.value || 0);
                                    }
                                    if (dependentSummaryRecord) {
                                        dependentSummaryRecord.addItem(modifier.type, cycle, '[2]', modifier.value || 0);
                                        showDependentFootnote = true;
                                    }
                                }
                            }
                        });
                    });

                    // Print summary

                    principal.forEach(function (x) {
                        if (x.unlimitedVisit || x.unlimitedBudget) {
                            var summary = _.find(principalSummaries, function (y) { return y.key === x.treatment.name; });
                            if (!summary) {
                                principalSummaries.push(summary = new BenefitSummary(x.treatment.name));
                            }

                            if (x.unlimitedBudget && x.unlimitedVisit) {
                                summary.addText(App.localize('Unlimited'));
                            } else {
                                if (x.unlimitedBudget) {
                                    summary.addText(App.localize('UnlimitedBudget'));
                                }
                                if (x.unlimitedVisit) {
                                    summary.addText(App.localize('UnlimitedVisit'));
                                }
                            }
                        }
                    });
                    _this.principalSummaries = principalSummaries;
                    _this.showPrincipalFootnote = showPrincipalFootnote;

                    dependent.forEach(function (x) {
                        if (x.unlimitedVisit || x.unlimitedBudget) {
                            var summary = _.find(dependentSummaries, function (y) { return y.key === x.treatment.name; });
                            if (!summary) {
                                dependentSummaries.push(summary = new BenefitSummary(x.treatment.name));
                            }
                            if (x.unlimitedBudget && x.unlimitedVisit) {
                                summary.addText(App.localize('Unlimited'));
                            } else {
                                if (x.unlimitedBudget) {
                                    summary.addText(App.localize('UnlimitedBudget'));
                                }
                                if (x.unlimitedVisit) {
                                    summary.addText(App.localize('UnlimitedVisit'));
                                }
                            }
                        }
                    });
                    _this.dependentSummaries = dependentSummaries;
                    _this.showDependentFootnote = showDependentFootnote;
                };

                // Initialization

                if (dto) {
                    automap(dto, _this);

                    _this.principal = inpatientDetails;
                    _this.dependent = inpatientDetails;
                    _this.hasDependentCovered = vm.isCreate || dto.hasDependentCovered ? 1 : 0;
                    _this.modifiers = [];
                    _.each(dto.modifiers, function (x) {
                        _this.modifiers.push(new Modifier(_this, x));
                    });
                }

                _this.refreshResult();

                //Post method

                _this.toDto = function () {
                    function getModifierDto(modifiers) {
                        return _(modifiers).map(function (m) { return m.toDto(); }).value();
                    }

                    return {
                        id: _this.id,
                        tenantId: _this.tenantId,
                        planName: _this.planName,
                        displayName: _this.displayName,
                        remarks: _this.remarks,
                        hasDependentCovered: _this.hasDependentCovered,
                        modifiers: getModifierDto(_this.modifiers)
                    };
                };
            }

            function TreatmentModel(id, name, icon, valid) {
                this.id = id;
                this.name = name;
                this.icon = icon;
                this.valid = valid;
            }

            function TreatmentApplyModel(treatment, applicable) {
                this.treatment = treatment;
                this.applicable = applicable;
            }

            function BenefitResult(treatment, unlimitedBudget, unlimitedVisit) {
                this.treatment = treatment;
                this.unlimitedBudget = unlimitedBudget;
                this.unlimitedVisit = unlimitedVisit;
            }

            function BenefitSummaryItem(type, cycle, poolMode, value) {
                this.type = type;
                this.cycle = cycle;
                this.poolMode = poolMode;
                this.value = value;

                this.toString = function () {
                    var str = '';
                    var strValue = _.isNil(this.value) ? '?' : this.value;
                    switch (this.type) {
                        case 0: str += 'RM' + strValue; break;
                        case 1: str += strValue + ' ' + (this.value > 1 ? 'visits' : 'visit'); break;
                        case 2: str += 'RM' + strValue + ' overdraft'; break;
                        case 3: str += strValue + ' overdraft ' + (this.value > 1 ? 'visits' : 'visit'); break;
                    }

                    str += ' ' + this.cycle + ' ' + this.poolMode;
                    return str;
                };
            }

            function BenefitSummary(key, value) {
                this.items = [];
                this.texts = [];

                this.key = key;
                this.value = value;

                this.addItem = function (type, cycle, poolMode, value) {
                    var item = _.find(this.items, function (x) {
                        return x.type === type && x.cycle === cycle && x.poolMode === poolMode;
                    });

                    if (!item) {
                        this.items.push(new BenefitSummaryItem(type, cycle, poolMode, value));
                    } else {
                        item.value += value;
                    }
                };

                this.addText = function (text) {
                    this.texts.push(text);
                };

                this.toString = function () {
                    return _.concat(_.map(this.items, function (x) { return x.toString(); }), this.texts).join(', ');
                };
            }

            function Modifier(bgModel, dto) {
                var _this = this;

                _this.parent = bgModel;

                // Basic properties

                _this.type = 0;
                _this.cycle = 1;
                _this.beneficiary = 3;
                _this.poolMode = 0;
                _this.value = null;
                _this.interval = 2;

                // View properties

                _this.show = true;
                _this.description = '';

                // Treatment type mapping

                _this.principalMap = _.keyBy(bgModel.principal, 'id');
                _this.dependentMap = _.keyBy(bgModel.dependent, 'id');

                // Applicable treatment types

                var treatments = [];
                _.each(_this.parent.principal, function (e) {
                    var isValid = checkValid(e.id);
                    treatments.push(new TreatmentApplyModel(new TreatmentModel(e.id, e.name, e.icon, isValid), isValid));
                });
                _this.treatments = treatments;

                // Private functions

                function checkValid(id) {
                    switch (_this.beneficiary) {
                        case 3:
                            return _this.principalMap[id].valid
                                || _this.parent.hasDependentCovered === 1 && _this.dependentMap[id].valid;
                        case 1:
                            return _this.principalMap[id].valid;
                        case 2:
                            return _this.dependentMap[id].valid;
                        default:
                            return false;
                    }
                }

                function validateModifierRules() {
                    // If modifier is not budget limit, cycle cannot be Per Visit.
                    // Default to annually.

                    if (_this.type !== 0 && _this.cycle === 4) {
                        _this.cycle = 1;
                    }

                    // If beneficiary is principal only, there is no pool to share.
                    // If beneficiary is dependant only, split pool cannot be selected.
                    // Default to individual pool.

                    if (_this.beneficiary === 1 || _this.beneficiary === 2 && _this.poolMode === 2) {
                        _this.poolMode = 1;
                    }
                }

                // Public functions

                _this.changed = function () {
                    validateModifierRules();
                    _this.refreshTreatmentValidity();
                    _this.refreshDescription();
                };

                _this.refreshTreatmentValidity = function () {
                    _.each(_this.treatments, function (e) {
                        e.treatment.valid = checkValid(e.treatment.id);
                    });
                };

                //Post method

                _this.toDto = function () {
                    return {
                        type: _this.type,
                        cycle: _this.cycle,
                        beneficiary: _this.beneficiary,
                        poolMode: _this.poolMode,
                        value: _this.value,
                        interval: _this.interval,
                        affectedBenefits: _(treatments).filter('applicable').map('treatment.id').value()
                    };
                };

                _this.refreshDescription = function () {
                    var type = _this.type,
                        cycle = _this.cycle,
                        interval = _this.interval,
                        beneficiary = _this.beneficiary,
                        poolMode = _this.poolMode,
                        value = _this.value,
                        treatmentTypes = _this.treatmentTypes,
                        desc = '',
                        ttNames = [];

                    switch (cycle) {
                        case 0:
                            desc += 'Every ' + interval + ' year(s)';
                            break;
                        case 1:
                            desc += 'Every year';
                            break;
                        case 2:
                            desc += 'Every month';
                            break;
                        case 3:
                            desc += 'Everyday';
                            break;
                        case 4:
                            desc += 'Every visit';
                            break;
                    }

                    switch (type) {
                        case 0:
                        case 1:
                            desc += ' limits ';
                            break;
                        case 2:
                        case 3:
                            desc += ' allows overdraft of ';
                    }

                    switch (type) {
                        case 0:
                        case 2:
                            desc += vm.currencyCode + ' ' + value;
                            break;
                        case 1:
                        case 3:
                            desc += value + ' visits';
                            break;
                    }

                    switch (beneficiary) {
                        case 1:
                            desc += ' for principal';
                            break;
                        case 2:
                            desc += ' for dependants';
                            break;
                        case 3:
                            desc += ' for principal & dependants';
                            break;
                    }

                    switch (poolMode) {
                        case 0:
                            desc += ' where everyone share';
                            break;
                        case 1:
                            desc += ' individually';
                            break;
                        case 2:
                            desc += ' where dependents share';
                            break;
                    }

                    if (treatmentTypes) {
                        desc += ' on ';

                        _.each(treatmentTypes, function (isApplied, idStr) {
                            var id = parseInt(idStr);
                            if (isApplied) {
                                ttNames.push(_.find(vm.treatmentTypes, function (a) { return a.id === id; }).name);
                            }
                        });

                        desc += ttNames.join(', ');
                    }

                    _this.description = ttNames.length > 0 ? desc : 'Not applicable';
                };

                // Initialization

                if (dto) {
                    automap(dto, _this);
                    _.each(_this.treatments, function (x) {
                        x.applicable = _.indexOf(dto.affectedBenefits, x.treatment.id) >= 0;
                    });
                    _this.refreshTreatmentValidity();
                }

                _this.refreshDescription();
            }

            /* End of Types */

            vm.beneficiary = {
                principal: 1,
                dependent: 2
            };

            init();
        }
    ]);
})();
