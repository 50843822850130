(function () {
    var componentId = 'clinicHospitalInpatientDashboardCurrentPatientsComponent';
    var app = angular.module('app');

    app.component(componentId, {
        templateUrl: require('/App/clinic/views/hospital/inpatientDashboard/currentPatients/currentPatients.component.html'),
        controller: ['$scope', '$interval', '$stateParams', '$state', 'abp.services.app.inpatientDashboard', controller],
        controllerAs: 'vm'
    });

    function controller($scope, $interval, $stateParams, $state, inpatientDashboardSvc) {
        function init() {
            getAdmissionTypes();

            var tickerInterval = $interval(tickerJob, 1000);
            $scope.$on('$destroy', function () {
                if (angular.isDefined(tickerInterval)) {
                    $interval.cancel(tickerInterval);
                    tickerInterval = undefined;
                }
            });

            refresh();
        }

        var vm = this;
        var isSilentLoading = 0;
        var tickerCount = 0;
        var widgets = [];
        var expectedWidgetCount = 3;

        vm.loading = 0;
        vm.admissionTypes = [];

        vm.refresh = refresh;
        vm.addWidget = addWidget;

        vm.requestParams = {
            filter: $stateParams.filter,
            admissionType: $stateParams.admissionType,
            glNumber: $stateParams.glNumber,
            admissionNumber: $stateParams.admissionNumber
        };

        function getAdmissionTypes() {
            vm.loading++;
            inpatientDashboardSvc.getAdmissionTypes()
                .success(function (data) {
                    vm.admissionTypes = data;
                }).finally(function () {
                    vm.loading--;
                });
        }

        // Ticker to refresh every 5 minutes.

        function tickerJob() {
            if (!((++tickerCount) % 300) && !vm.loading && !isSilentLoading) {
                refresh(true);
            }
        }

        function addWidget(widget) {
            if (widget && _.isFunction(widget.refresh)) {
                widgets.push(widget);
            }

            if (widgets.length === expectedWidgetCount)
                refresh();
        }

        function refresh() {
            // Reload GLs from server and filter.

            registerStateParams();
            _.each(widgets, function (widget) {
                widget.updateFilter(vm.requestParams);
            });
            getGuaranteeLetters();
        }

        function registerStateParams() {
            $state.transitionTo($state.current, {
                filter: vm.requestParams.filter,
                admissionType: vm.requestParams.admissionType,
                glNumber: vm.requestParams.glNumber,
                admissionNumber: vm.requestParams.admissionNumber
            }, {
                notify: false
            });
        }

        function getGuaranteeLetters() {
            vm.loading++;
            inpatientDashboardSvc.getHospitalGuaranteeLetters(vm.requestParams)
                .success(function (data) {
                    vm.result = data;
                    vm.supportContactNumber = data.supportContactNumber;
                    vm.guaranteeLetters = filterResult(vm.result);
                }).finally(function () {
                    vm.loading--;
                });
        }

        function filterResult(result) {
            var newResult = {};

            _.forIn(result, function (value, key) {
                if (_.isArray(value)) {
                    newResult[key] = filterGuaranteeLetters(value);
                }
            });

            return newResult;
        }

        function filterGuaranteeLetters(guaranteeLetters) {
            var glChain = _.chain(guaranteeLetters);

            if (vm.requestParams.filter) {
                glChain = glChain.filter(function (gl) {
                    var filter = App.normalizeText(vm.requestParams.filter);

                    var patientName = App.normalizeText(gl['patientName']);
                    var patientNationalId = App.normalizeText(gl['patientNationalId']);

                    return _.includes(patientName, filter) || patientNationalId === filter;
                });
            }

            if (vm.requestParams.admissionType) {
                glChain = glChain.filter(['admissionType', vm.requestParams.admissionType]);
            }

            if (vm.requestParams.glNumber) {
                glChain = glChain.filter(['guaranteeLetterNumber', vm.requestParams.glNumber]);
            }

            if (vm.requestParams.admissionNumber) {
                glChain = glChain.filter(function (gl) {
                    var admissionNumberFilter = App.normalizeText(vm.requestParams.admissionNumber);
                    var patientAdmissionNumber = App.normalizeText(gl['mrnNumber']);

                    return admissionNumberFilter === patientAdmissionNumber;
                });
            }

            return glChain.value();
        }

        init();
    }
})();
