(() => {
  angular
    .module('app')
    .filter('suspending', suspending)
    // For backward compatibility only. Filter name don't need "Filter" suffix.
    // AngularJS automatically appends "Filter" to name for DI.
    .filter('suspendingFilter', suspending);

  suspending.$inject = ['moment'];

  function suspending(moment) {
    return suspendingFilter;

    function suspendingFilter(value) {
      return value ? App.localize('SuspendingOnX', moment(value).format('L')) : '';
    }
  }
})();
