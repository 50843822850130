(function () {
    var componentId = 'clinicHealthScreeningDashboardPendingSubmissionsDetailCard';
    var app = angular.module('app');

    function controller($sce, $state) {
        function init() {
            // EMPTY.
        }
        var vm = this;

        vm.permissions = {
            viewGl: abp.auth.isGranted("GuaranteeLetters.HealthScreening.Panel"),
            canSubmitClaim: abp.auth.isGranted("Claims.HealthScreening.Panel.Create")
                || abp.auth.isGranted("PatientTickets.Clinic.Create")
        };

        vm.submitNow = function (letterNumber) {
            // HM-5994: Claim with topup reserved for future enhancements.

            $state.go('createEditHealthScreeningFinalRequest', {
                letterNumber: letterNumber,
                claimWithTopUp: 0,
                timeZoneId: vm.timeZoneId
            });
        }

        vm.getLocalisedDateTime = function (datetime) {
            return $sce.trustAsHtml(App.localize('PleaseSubmitByXSpecialistMessage', moment(datetime).format('L hh:mm A')));
        }

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/clinic/views/healthScreening/dashboard/pendingSubmissions/pendingSubmissionsDetailCard.component.html'),
        controller: ['$sce', '$state', controller],
        controllerAs: 'vm',
        bindings: {
            patientName: '<',
            patientType: '<',
            nationalId: '<',
            subsidiaryName: '<',
            expiryTime: '<',
            guaranteeLetterNumber: '<',
            timeZoneId: '<'
        }
    });
})();
