(function () {
  angular
    .module('app')
    .controller('common.views.healthScreeningClaims.processFinalRequest.process', [
      '$state',
      '$stateParams',
      'abp.services.app.healthScreeningHostServiceRequest',
      'abp.services.app.healthScreeningGuaranteeLetter',
      'moment',
      controller
    ]);

  function controller(
    $state,
    $stateParams,
    healthScreeningHostServiceRequestSvc,
    healthScreeningGuaranteeLetterSvc,
    moment) {
    var vm = this;

    function init() {
      vm.getFinalRequestForProcess();
      vm.getTreatments();
    }

    // #region Variable declaration

    vm.claimNumber = $stateParams.claimNumber;
    vm.letterNumber = $stateParams.letterNumber;
    vm.timeZoneId = $stateParams.timeZoneId;
    vm.loading = 0;
    vm.saving = 0;
    vm.hasSupportingDocuments = 0;
    vm.hasState = 1;
    vm.isOpen = true;
    vm.request = null;
    vm.isReady = false;

    vm.treatmentSelections = [];

    vm.approveChecklistItems = [{
        text: 'Amount correct',
        required: true
      },
      {
        text: 'Detailed tax invoice',
        required: true
      },
      {
        text: 'Patient Consent & Claim Form',
        required: true
      },
      {
        text: 'Diagnosis form',
        required: true
      },
      {
        text: 'Documents clear',
        required: true
      }
    ];

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.defaultMaxAllowance = parseFloat(abp.setting.get("Hms.General.MaxAllowanceAmountLimit"));

    // #endregion Variable declaration

    // #region Define function

    vm.getFinalRequestForProcess = function () {
      vm.loading++;

      healthScreeningHostServiceRequestSvc.getFinalRequestForProcess({
        id: $stateParams.claimNumber
      }).success(function (data) {
        vm.isReady = true;

        vm.finalRequest = data.finalRequest;
        vm.panel = data.panel;
        vm.patient = data.patient;
        vm.ticket = data.ticket;
        vm.balanceInfo = data.balanceInfo;

        // Custom mapping for documents and queries indicator.
        // Double negation to convert null object into boolean.

        vm.hasSupportingDocuments = !!(data.finalRequest.supportingDocumentAttachments && data.finalRequest.supportingDocumentAttachments.length);
        vm.hasUnresolvedQuery = data.finalRequest.hasUnresolvedQuery;
        vm.currencyCode = data.ticket.currencyCode;

        // Mapping data for benefit pools.

        vm.benefit = data.benefit;
        vm.benefit.currentYear = moment().format('YYYY');
        vm.benefit.benefits = [];
        vm.benefit.displayName = App.localize(vm.benefit.name);

        if (vm.benefit.balancePools && vm.benefit.balancePools.length) {
          _.each(vm.benefit.balancePools, function (d) {
            var title = vm.benefit.displayName;

            var isBudget = d.modifierType === 0 || d.modifierType === 2;
            var cycle = '';
            if (!d.isUnlimited) {
              switch (d.modifierCycle) {
                case 0:
                  cycle = 'every ' + d.interval + ' years ';
                  break;
                case 1:
                  cycle = 'annual ';
                  break;
                case 2:
                  cycle = 'monthly ';
                  break;
                case 3:
                  cycle = 'daily ';
                  break;
                case 4:
                  cycle = 'per visit ';
                  break;
                case 5:
                  cycle = '6 months ';
                  break;
                case 6:
                  cycle = '4 months ';
                  break;
                case 7:
                  cycle = '3 months ';
                  break;
                case 8:
                  cycle = '2 months ';
                  break;
              }
              if (d.modifierType === 2 || d.modifierType === 3) cycle += 'overdraft ';
            }
            title += ' (' + cycle + (isBudget ? 'budget' : 'visit') + ')';

            var isEmptyExcess = d.isExcess && !d.employeePortion;
            if (!isEmptyExcess) {
              vm.benefit.benefits.push({
                title: title,
                isUnlimited: d.isUnlimited,
                isExcess: d.isExcess,
                isBudget: isBudget,
                limit: d.limit,
                used: d.used,
                locked: d.locked,
                balance: d.balance
              });
            }
          });
        }

        vm.benefit.allowance = Math.min(vm.benefit.allowance, vm.defaultMaxAllowance);

        vm.finalRequest.panelExceededAmount = calcExceededAmount(vm.finalRequest.totalClaimableAmount);

        // Map amounts for each items.

        if (!vm.ticket.id) {
          _.forEach(vm.ticket.items, function (d) {
            if (!d.coveredAmount) d.coveredAmount = null;
            if (!d.uncoveredAmount) d.uncoveredAmount = null;
          });
        }

        // Map data to request object.

        vm.request = {
          id: vm.finalRequest.id,
          claimNumber: vm.claimNumber,
          treatmentItems: vm.ticket.items,
          remarks: '',
          actualVisitDate: moment(vm.ticket.actualVisitDate),
          actualVisitRemark: vm.ticket.actualVisitRemark,
          totalBillableAmount: vm.finalRequest.totalClaimableAmount,
          documents: vm.finalRequest.documents,
          attendingDoctorIds: vm.ticket.attendingDoctorIds,
        };
      }).finally(function () {
        vm.loading--;
      })
    }

    vm.getTreatments = function () {
      healthScreeningGuaranteeLetterSvc.getHealthScreeningTreatments({
          id: vm.letterNumber
        })
        .success(function (data) {
          data.forEach((t) => {
            if (t.parentId)
              vm.treatmentSelections.push(t);
          })
        })
    }

    // #region Amounts calculation

    vm.getAmount = function () {
      var totalBillAmount = 0;
      var totalCoveredAmount = 0;
      var coveredAmount = 0;
      var uncoveredAmount = 0;
      var exceededAmount = 0;
      var collectCashAmount = 0;
      var isTallied = false;

      if (vm.isReady) {
        var finalBillAmount = vm.request.totalBillableAmount;
        var allowance = App.roundAmount(Math.min(vm.balanceInfo.employeeBalance, vm.balanceInfo.corporateBalance));

        uncoveredAmount = _.sumBy(vm.request.treatmentItems, function (d) {
          return d.uncoveredAmount || 0;
        });

        coveredAmount = _.sumBy(vm.request.treatmentItems, function (d) {
          return d.coveredAmount || 0;
        });

        totalBillAmount = _.sumBy(vm.request.treatmentItems, function (d) {
          return App.roundAmount((d.coveredAmount || 0) + (d.uncoveredAmount || 0));
        });

        exceededAmount = Math.max(totalBillAmount - uncoveredAmount - allowance, coveredAmount - finalBillAmount, 0);

        totalCoveredAmount = totalBillAmount - uncoveredAmount - exceededAmount;

        collectCashAmount = uncoveredAmount + exceededAmount;

        isTallied = App.roundAmount(totalBillAmount) === App.roundAmount(finalBillAmount) &&
          App.roundAmount(totalCoveredAmount) === App.roundAmount(coveredAmount);
      }

      return {
        totalBillAmount: App.roundAmount(totalBillAmount),
        totalCoveredAmount: App.roundAmount(totalCoveredAmount),
        coveredAmount: App.roundAmount(coveredAmount),
        uncoveredAmount: App.roundAmount(uncoveredAmount),
        collectCash: App.roundAmount(collectCashAmount),
        exceededAmount: App.roundAmount(exceededAmount),
        isTallied: isTallied
      };
    }

    vm.getTotalBillAmount = function () {
      return vm.getAmount().totalBillAmount > 0 ? vm.getAmount().totalBillAmount : 0;
    };

    vm.getCurrentTotalCoveredAmount = function () {
      return vm.getAmount().totalCoveredAmount > 0 ? vm.getAmount().totalCoveredAmount : 0;
    };

    vm.getCurrentCollectCashAmount = function () {
      return vm.getAmount().collectCash > 0 ? vm.getAmount().collectCash : 0;
    };

    vm.getCurrentUncoveredAmount = function () {
      return vm.getAmount().uncoveredAmount > 0 ? vm.getAmount().uncoveredAmount : 0;
    };

    vm.getNewExceededAmount = function () {
      return vm.getAmount().exceededAmount > 0 ? vm.getAmount().exceededAmount : 0;
    };

    vm.getCurrentClaimAmount = function () {
      return vm.getAmount().coveredAmount > 0 ? vm.getAmount().coveredAmount : 0;
    };

    function calcExceededAmount(amount) {
      if (!vm.isReady) return 0;
      return App.roundAmount(Math.max(amount - vm.finalRequest.guaranteedAmount, 0));
    }

    vm.areFieldsValid = function () {
      if (!vm.isReady) return false;

      var isValid = true;
      _.forEach(vm.request.treatmentItems, function (d) {
        if (typeof d.coveredAmount === 'undefined' ||
          d.coveredAmount === null ||
          typeof d.uncoveredAmount === 'undefined' ||
          d.uncoveredAmount === null) {
          isValid = false;
        }
      });
      return isValid;
    };

    vm.isTotalBillAmountValid = function () {
      if (!vm.isReady) return false;
      if (!vm.areFieldsValid()) return true;
      return vm.getAmount().isTallied;
    };

    vm.getEmployeeBalanceDisplay = function () {
      if (!vm.isReady) return 0;
      return App.roundAmount(Math.min(vm.balanceInfo.employeeBalance, vm.balanceInfo.corporateBalance));
    };

    // #endregion Amounts calculation

    // #region Validations

    vm.isActualVisitDateUpdated = function () {
      return vm.ticket && vm.request && moment(vm.ticket.ticketDate).format('L') !== moment(vm.request.actualVisitDate).format('L');
    };

    // #endregion Validations

    // #region Action

    vm.approve = function () {
      if (vm.saving || vm.loading) return;

      var isValidForm = App.isFormValid(vm.editForm);

      if (!isValidForm)
        return;

      swal({
        title: App.localize('ConfirmApproveClaim'),
        text: App.localize('SentToSystemAndCannotBeUndoneNarrative'),
        type: 'warning',
        confirmButtonColor: '#1ab394',
        confirmButtonText: App.localize('Yes'),
        cancelButtonText: App.localize('No'),
        showCancelButton: true,
        closeOnConfirm: false,
      }, function (isConfirm) {
        if (isConfirm) {
          vm.process(true)
        }
        swal.close();
      });
    }

    vm.process = function (isApprove) {
      if (vm.saving || vm.loading) return;

      isApprove = !!isApprove
      vm.saving++;
      vm.loading++;

      var input = $.extend({}, vm.request, {
        isApprove: isApprove
      });

      healthScreeningHostServiceRequestSvc.processFinalRequest(input)
        .success(function () {
          $state.go('healthScreeningClaimDetail', {
            claimNumber: vm.claimNumber,
            timeZoneId: vm.timeZoneId
          });
        }).finally(function () {
          vm.saving--;
          vm.loading--;
        })
    }

    vm.reject = function () {
      if (vm.saving || vm.loading) return;

      // To force-touch the remarks field.

      vm.editForm.remark.$setTouched();
      if (vm.editForm.remark.$invalid)
        return;

      abp.message.confirm(App.localize('RejectXConfirmMessage', vm.claimNumber), App.localize('AreYouSure'), function (isConfirm) {
        if (isConfirm) {
          rejectRequest();
        }
      });
    }

    function rejectRequest() {
      vm.saving++;
      vm.loading++;

      healthScreeningHostServiceRequestSvc.rejectRequest({
          id: vm.request.id,
          remarks: vm.request.remarks
        })
        .success(function () {
          $state.go('healthScreeningClaimDetail', {
            claimNumber: vm.claimNumber,
            timeZoneId: vm.timeZoneId
          });
        }).finally(function () {
          vm.saving--;
          vm.loading--;
        })
    }

    vm.query = function () {
      if (vm.saving || vm.loading) return;

      $state.go('createHealthScreeningQuery', {
        claimNumber: vm.claimNumber,
        timeZoneId: vm.timeZoneId
      });
    }

    // #endregion Action

    // #endregion Define function

    init();
  }
})();
