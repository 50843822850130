(function () {
    var componentId = 'clinicHospitalCommonSpecialistDoctorComponent';
    var app = angular.module('app');

    app.component(componentId, {
        templateUrl: require('/App/clinic/views/hospital/common/specialistDoctor/specialistDoctor.component.html'),
        controller: ['$uibModal', 'abp.services.app.commonLookup', controller],
        controllerAs: 'vm',
        bindings: {
            specialistDoctors: '=',
            selectedDoctorIds: '=',
            panelLocationId: '<'
        }
    });

    function controller($uibModal, commonLookupSvc) {
        var vm = this;
        var doctorLimit = 20;

        vm.hitLimit = hitLimit;
        vm.addSpecialist = addSpecialist;
        vm.onSpecialistsChanges = onSpecialistsChanges;
        vm.removeSpecialist = removeSpecialist;

        vm.specialistDoctorChoices = [];
        vm.createDoctor = createDoctor;
        vm.updateDoctors = updateDoctors;

        // Functions

        function init() {
            if (!vm.selectedDoctorIds) {
                vm.selectedDoctorIds = [];
            }
        }

        function addSpecialist() {
            if (hitLimit()) return;

            vm.selectedDoctorIds.push(null);
            onSpecialistsChanges();
        }

        function removeSpecialist(index) {
            vm.selectedDoctorIds.splice(index, 1);
            vm.specialistDoctorChoices.splice(index, 1);
            onSpecialistsChanges();
        }

        function hitLimit() {
            var limit = Math.min(doctorLimit, vm.specialistDoctors.length);
            return vm.selectedDoctorIds.length >= limit;
        }

        function onSpecialistsChanges() {
            _.each(vm.selectedDoctorIds, function (id, index) {
                vm.specialistDoctorChoices[index] = _.filter(vm.specialistDoctors, function (d) {
                    return !_.includes(vm.selectedDoctorIds, d.value) || d.value === id;
                });
            });
        }

        function createDoctor(index) {
            $uibModal.open({
                templateUrl: require('/App/clinic/views/doctors/createOrEditModal.html'),
                controller: 'clinic.views.doctors.createOrEditModal as vm',
                backdrop: 'static',
                resolve: {
                    doctorId: null,
                    clinicLocationId: vm.panelLocationId
                }
            }).result.then(function (result) {
                vm.updateDoctors();
                vm.selectedDoctorIds[index] = result.doctorId;
            });
        }

        function updateDoctors() {
            vm.loading++;
            commonLookupSvc.getDoctors()
                .success(function (data) {
                    vm.specialistDoctors = data;
                })
                .finally(function () {
                    onSpecialistsChanges();
                    vm.loading--;
                });
        }

        /*End of functions*/

        init();
    }
})();
