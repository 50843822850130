import _ from 'lodash';

(() => {
  angular
    .module('finance')
    .controller('finance.views.paymentInstruction.transferAdvice', TransferAdvicePageController);

  TransferAdvicePageController.$inject = [
    '$scope',
    '$stateParams',
    'countries',
    'Hms.Payments.Payment',
    'abp.services.finance.paymentInstruction',
    'abp.services.app.financePaymentInstruction',
  ];

  function TransferAdvicePageController(
    $scope,
    $stateParams,
    countries,
    constsPayment,
    paymentInstructionSvc,
    portalFinancePaymentInstructionSvc
  ) {
    const vm = this;

    vm.constsPayment = constsPayment;
    vm.loading = 0;
    vm.saving = 0;
    vm.advices = [];
    vm.currencyCode = 'SGD';
    vm.refreshSelectedFigures = refreshSelectedFigures;

    vm.requestParams = {
      batchNumber: $stateParams.batchNumber,
      paymentInstructionNumber: $stateParams.paymentInstructionNumber,
    };

    vm.send = send;
    vm.refresh = refresh;

    init();

    function init() {
      $scope.$watch('vm.checkAll', () => {
        _.each(vm.advices, (advice) => {
          const a = advice;
          a.isMarkedForEmail = vm.checkAll;
        });
        vm.refreshSelectedFigures();
      });
      refresh();
    }

    function getEmailAddresses(transferAdvices) {
      if (!transferAdvices || !transferAdvices.length) return;
      const input = {
        transferAdvices,
      };

      portalFinancePaymentInstructionSvc
        .getEmailAddressesForTransferAdvices(input)
        .success((data) => {
          vm.advices = _.map(vm.advices, (d) => {
            const details = _.find(data.transferAdvices, {
              paymentInstructionNumber: d.paymentInstructionNumber,
            });
            return $.extend(d, details);
          });
        });
    }

    function refresh() {
      vm.loading += 1;

      paymentInstructionSvc
        .getTransferAdvices(vm.requestParams)
        .success((data) => {
          vm.advices = data.transferAdvices;
          vm.currencyCode = data.currencyCode;

          _.each(vm.advices, (advice) => {
            const a = advice;
            a.isMarkedForEmail = !a.hasSent;
            a.currencyCode = vm.currencyCode;
          });

          vm.checkAll = _.every(vm.advices, 'isMarkedForEmail');
          vm.refreshSelectedFigures();

          console.log(data);

          getEmailAddresses(data.transferAdvices);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function refreshSelectedFigures() {
      let amt = 0;
      let cnt = 0;
      _.each(vm.advices, (advice) => {
        if (advice.isMarkedForEmail) {
          amt += advice.totalAmount;
          cnt += 1;
        }
      });
      vm.selectedCount = cnt;
      vm.selectedAmount = amt;
    }

    function send() {
      const params = _(vm.advices)
        .filter('isMarkedForEmail')
        .map((advice) => ({
          paymentInstructionNumber: advice.paymentInstructionNumber,
          emailAddresses: advice.emailAddresses,
        }))
        .value();

      vm.saving += 1;
      portalFinancePaymentInstructionSvc
        .sendTransferAdvices({
          paymentDate: vm.paymentDate,
          referenceNumber: vm.referenceNumber,
          params,
        })
        .success(() => {
          abp.notify.success(App.localize('SuccessfullySent'));
          vm.referenceNumber = null;
          vm.refresh();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

  }
})();
