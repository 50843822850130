(function () {
    'use strict';

    angular
        .module('app')
        .controller('host.views.licensingFees.createEdit', ['$scope', '$state', '$stateParams',
            'abp.services.app.licensingFee', 'countries', 'moment','abp.services.app.commonLookup', controller]);

    function controller($scope, $state, $stateParams, licensingFeeSvc, countries, moment, commonLookupSvc) {
        var vm = this;

        vm.licensingFeeId = $stateParams.licensingFeeId;
        vm.isCreate = !$stateParams.licensingFeeId;
        vm.isEdit = !!vm.licensingFeeId;

        vm.panelId = $stateParams.panelId;
        vm.isCustomPanel = !!$stateParams.panelId;

        vm.countryCode = $stateParams.countryCode;
        vm.serviceType = $stateParams.serviceType;
        vm.isNewScheme = !!$stateParams.countryCode && !!$stateParams.serviceType;

        vm.loading = 0;
        vm.saving = 0;
        vm.isActive = false;

        vm.tomorrow = moment().add(1, 'days');
        vm.tiers = [];

        vm.countries = [];
        vm.serviceTypes = [];
        vm.countryServiceTypes = [];

        if (vm.countryCode != null) {
            var applicationCountry = _.find(countries, ['code', vm.countryCode]);
        }

        vm.currencyCode = vm.countryCode != null
            ? applicationCountry.currencyCode
            : abp.setting.get('Hms.General.CurrencyCode');

        vm.data = null;

        vm.addTier = addTier;
        vm.deleteTier = deleteTier;
        vm.save = save;
        vm.back = back;
        vm.resetServiceType = resetServiceType;

        function init() {
            getAccessibleCountries();
            getLicensingFee(vm.licensingFeeId);
        }

        function getLicensingFee(licensingFeeId) {
            vm.loading++;
            licensingFeeSvc.getLicensingFeeForEdit({
                id: licensingFeeId,
                panelId: vm.panelId,
                serviceType: vm.serviceType,
                countryCode: vm.countryCode
            }).success(function (data) {
                vm.data = data.licensingFee;
                vm.tiers = data.tiers;
                vm.panelName = data.panelName;
                vm.countryServiceTypes = data.countryServiceTypes;

                if (vm.tiers && vm.tiers.length === 0) {
                    addTier();
                }

                if (vm.isCreate && !vm.isCustomPanel && !vm.isNewScheme) {
                    vm.data = {
                        countryCode: "MY",
                        serviceType: null,
                        effectiveDate: null,
                        isActive: false
                    };
                    resetServiceType();
                }

                // Reformat effective date

                if (vm.data.effectiveDate) {
                    vm.data.effectiveDate = App.getUtcDate(vm.data.effectiveDate.format());
                }

                vm.isActive = vm.data.isActive;
            }).finally(function () {
                vm.loading--;
            });
        }

        function resetServiceType() {
            if (vm.isEdit)
                return;

            var serviceTypeByCountry = _.find(vm.countryServiceTypes, ['countryCode', vm.data.countryCode]);
            if (serviceTypeByCountry) {
                vm.data.serviceType = null;
                vm.serviceTypes = angular.copy(serviceTypeByCountry.serviceTypes);
                vm.currencyCode = serviceTypeByCountry.currencyCode;
            }
        }

        function getAccessibleCountries() {
            vm.loading += 1;
            commonLookupSvc
              .getEligibleCountries()
              .success((data) => {
                vm.countries = data;
                const eligibleCountryCodes = data.map((o) => o.code);
                vm.countryCodes = _.filter(countries, (c) => eligibleCountryCodes.includes(c.code));
              })
              .finally(() => {
                vm.loading -= 1;
              });
        }

        function addTier() {
            vm.tiers.push({});
        }

        function deleteTier(index) {
            if (vm.tiers.length > 1) {
                vm.tiers.splice(index, 1);
            }
            else {
                vm.tiers = [];
                addTier();
            }
        }

        // Check form validity.

        function validateForm() {
            var form = $scope.vm.licensingFeeForm;
            return App.isFormValid(form);
        }

        function save() {
            if (!validateForm()) {
                abp.message.warn(App.localize('OneOrMoreInputValueIsInvalid'));
                return;
            }

            var input = {
                id: vm.licensingFeeId,
                countryCode: vm.data.countryCode,
                serviceType: vm.data.serviceType,
                effectiveDate: vm.data.effectiveDate,
                tiers: vm.tiers,
                panelId: vm.panelId
            };

            vm.saving++;
            licensingFeeSvc.createOrUpdateLicensingFee(input)
                .success(function () {
                    if (vm.isEdit) {
                        abp.notify.success(App.localize('SuccessfullyUpdated'));
                    } else {
                        abp.notify.success(App.localize('SuccessfullyCreated'));
                    }

                    if ((vm.countryCode && vm.serviceType) || vm.panelId) {
                        $state.go('host.licensingFeeDetails', {
                            countryCode: vm.countryCode,
                            serviceType: vm.serviceType,
                            panelId: vm.panelId
                        });
                    } else {
                        $state.go('host.licensingFees');
                    }
                }).finally(function () {
                    vm.saving--;
                });
        }

        // Cancel creation or edit.

        function back(isReset) {
            if (vm.isCreate) window.history.back();
            else {
                if (!$scope.vm.licensingFeeForm.$dirty) window.history.back();
                else {
                    abp.message.confirm(App.localize('UnsavedChangesConfirmation'), App.localize('AreYouSure'), function (d) {
                        if (d) {
                            window.history.back();
                        }
                    });
                }
            }
        }

        init();
    }
})();
