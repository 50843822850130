/**
 * touchSpin - Directive for Bootstrap TouchSpin
 */

(() => {
  angular.module('app').directive('touchSpin', touchSpinDirective);

  touchSpinDirective.$inject = [];

  function touchSpinDirective() {
    const directive = {
      link,
      restrict: 'A',
      scope: {
        spinOptions: '=',
      },
    };
    return directive;

    function link(scope, element) {
      scope.$watch(scope.spinOptions, () => {
        render();
      });

      function render() {
        $(element).TouchSpin(scope.spinOptions);
      }
    }
  }
})();
