import moment from 'moment';

(() => {
  angular.module('sms').controller('sms.views.smsLogs.page', SmsLogsPageController);
  SmsLogsPageController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$uibModal',
    'smsConstants',
    'smsService',
  ];
  function SmsLogsPageController($scope, $state, $stateParams, $modal, smsConsts, smsSvc) {
    const vm = this;
    vm.loading = 0;
    vm.saving = 0;
    vm.logs = [];
    const startOfDay = moment().startOf('day');
    const endOfDay = moment().endOf('day');
    vm.getHistory = getHistory;
    vm.showDetails = showDetails;
    $scope.$on('$viewContentLoaded', () => {
      App.initAjax();
    });
    vm.dateRangeOptions = App.createDateRangePickerOptions();
    vm.dateRangeModel = {
      startDate: startOfDay,
      endDate: endOfDay,
    };
    vm.smsLogsGridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: smsConsts.grid.defaultPageSizes,
      paginationPageSize: smsConsts.grid.defaultPageSize,
      columnDefs: getGridOptionsColumnDefs(),
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
      },
      data: [],
    };
    init();
    function init() {
      vm.getHistory();
    }
    function registerStateParams() {
      let startDate = null;
      let endDate = null;
      if (vm.dateRangeModel.startDate) {
        startDate = moment(vm.dateRangeModel.startDate).format('YYYY-MM-DD');
      }
      if (vm.dateRangeModel.endDate) {
        endDate = moment(vm.dateRangeModel.endDate).format('YYYY-MM-DD');
      }
      $state.transitionTo(
        $state.current,
        {
          startDate,
          endDate,
        },
        {
          notify: false,
        }
      );
    }
    function getHistory() {
      registerStateParams();
      vm.loading += 1;
      smsSvc
        .getSmsLogs(vm.dateRangeModel)
        .success((data) => {
          vm.smsLogsGridOptions.data = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
    function showDetails(smsLog) {
      $modal.open({
        templateUrl: require('./smsLogs.detailModal.html'),
        controller: 'sms.views.smsLogs.detailModal as vm',
        backdrop: 'static',
        resolve: {
          smsLog () {
            return smsLog;
          },
        },
      });
    }
    function getGridOptionsColumnDefs() {
      const columnDefs = [
        {
          name: 'Actions',
          enableSorting: false,
          width: 75,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'smsLogs.actionTemplate',
        },
        {
          displayName: App.localize('Time'),
          field: 'creationTime',
          cellFilter: "momentFormat: 'L LTS'",
          width: 150,
          minWidth: 50,
        },
        {
          displayName: App.localize('Recipients'),
          field: 'recipients',
          width: 120,
          minWidth: 50,
        },
        {
          displayName: App.localize('Message'),
          field: 'message',
          minWidth: 150,
        },
        {
          displayName: App.localize('StatusCode'),
          field: 'statusCode',
          width: 100,
          minWidth: 50,
        },
        {
          displayName: App.localize('StatusMessage'),
          field: 'statusMessage',
          width: 125,
          minWidth: 50,
        },
        {
          displayName: App.localize('ReferenceId'),
          field: 'referenceId',
          width: 100,
          minWidth: 50,
        },
        {
          displayName: App.localize('SmsCount'),
          field: 'smsCount',
          width: 90,
          minWidth: 50,
        },
      ];
      return columnDefs;
    }
  }
})();
