(() => {
  angular
    .module('app')
    .controller('host.views.preEmploymentTreatments.index', PreEmploymentTreatmentsController);

  PreEmploymentTreatmentsController.$inject = [
    '$scope',
    '$uibModal',
    'abp.services.app.treatment',
    'abp.services.app.commonLookup',
  ];

  function PreEmploymentTreatmentsController($scope, $modal, treatmentSvc, commonLookupSvc) {
    const vm = this;
    vm.loading = 0;
    vm.currencyCode = null;
    vm.countryCodePackage = null;
    vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');
    vm.isHost = App.isHost();
    vm.requestParams = {};
    vm.permissions = {
      preEmploymentTreatmentsEdit: abp.auth.isGranted(
        'Host.Treatments.PreEmploymentTreatments.Edit'
      ),
    };

    vm.preEmploymentTreatmentGridOptions = {
      appScopeProvider: vm,
      columnDefs: [
        {
          enableSorting: false,
          name: App.localize('Actions'),
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
        },
        {
          displayName: App.localize('Name'),
          field: 'name',
          minWidth: 100,
        },
        {
          displayName: App.localize('Country'),
          field: 'countryCode',
          width: 30,
          visible: vm.hasRegionalSystemSupport && vm.isHost,
          cellTemplate: 'countryTemplate',
        },
        {
          displayName: App.localize('BasePrice'),
          field: 'amount',
          type: 'number',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          minWidth: 100,
        },
        {
          displayName: App.localize('CreationDate'),
          field: 'creationTime',
          cellFilter: "momentFormat: 'L'",
          minWidth: 100,
        },
      ],
      data: [],
    };
    // eslint-disable-next-line no-unused-expressions
    vm.permissions.preEmploymentTreatmentsEdit ||
      vm.preEmploymentTreatmentGridOptions.columnDefs.shift();

    vm.createPreEmploymentPackage = createPreEmploymentPackage;
    vm.editPreEmploymentPackage = editPreEmploymentPackage;
    vm.deletePreEmploymentPackage = deletePreEmploymentPackage;
    vm.getPreEmploymentPackages = getPreEmploymentPackages;

    init();

    function init() {
      getPreEmploymentPackages();

      if (vm.hasRegionalSystemSupport) getEligibleCountries();
    }

    function getPreEmploymentPackages() {
      vm.loading += 1;
      treatmentSvc
        .getPreEmploymentPackages(vm.requestParams)
        .success((data) => {
          vm.preEmploymentTreatmentGridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function showCreateOrEditModal(id, countryCode) {
      const modal = $modal.open({
        templateUrl: require('./createOrEditModal.html'),
        controller: 'host.views.preEmploymentTreatments.createOrEditModal as vm',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          packageId() {
            return id;
          },
          countryCode() {
            return countryCode;
          },
        },
      });
      modal.result.then(() => {
        getPreEmploymentPackages();
      });
    }

    // Get all accessible country for the account owner.

    function getEligibleCountries() {
      vm.loading += 1;
      commonLookupSvc
        .getEligibleCountries()
        .success((data) => {
          vm.countries = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function createPreEmploymentPackage(countryCode) {
      showCreateOrEditModal(null, countryCode);
    }

    function editPreEmploymentPackage(preEmpPackage) {
      showCreateOrEditModal(preEmpPackage.id, preEmpPackage.countryCode);
    }

    function deletePreEmploymentPackage(preEmpPackage) {
      abp.message.confirm(
        App.localize('PreEmploymentDeleteWarningMessage', preEmpPackage.name),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            treatmentSvc
              .deletePreEmploymentPackage({
                id: preEmpPackage.id,
              })
              .success(() => {
                getPreEmploymentPackages();
                abp.notify.success(App.localize('SuccessfullyDeleted'));
              });
          }
        }
      );
    }
  }
})();
