(function () {
    var controllerId = 'clinic.views.patient.previewSubmission.adjustmentRequest.adjustmentRequest.page';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$stateParams', '$state', '$uibModal', 'abp.services.app.hospitalPanelServiceRequest', 'Hms.ServiceRequests.ServiceRequestStatus',
        function ($scope, $stateParams, $state, $uibModal, hospitalPanelServiceRequestSvc, enumServiceRequestStatus) {
            var vm = this;

            vm.loading = 0;
            vm.letter = null;
            vm.employeeId = $stateParams.employeeId;
            vm.panelLocationId = $stateParams.panelLocationId;
            vm.requestId = $stateParams.requestId;
            vm.cancelRequest = cancelRequest;

            vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

            vm.enums = {};
            vm.enums.serviceRequestStatus = enumServiceRequestStatus;

            function init() {
                getRequestDetails();
            }

            function getRequestDetails(serviceRequestId) {
                vm.loading++;
                hospitalPanelServiceRequestSvc.getAdjustmentRequestDetails({
                    employeeId: vm.employeeId,
                    panelLocationId: vm.panelLocationId,
                    requestId: vm.requestId
                }).success(function (data) {
                    vm.letter = data.letter;
                    vm.amount = data.adjustedFinalBillAmount;
                    vm.reason = data.adjustmentReason;
                    vm.attachments = data.attachments;
                    vm.serviceRequestStatus = data.requestStatus;
                    vm.documentChecklistItems = data.documentChecklistItems;
                    vm.doctorsNames = data.doctorsNames;
                    vm.requestStatus = data.requestStatus;
                    vm.currencyCode = data.currencyCode;

                    _.each(vm.documentChecklistItems, function (item) {
                        item.required = item.isMandatory;
                        item.isChecked = item.isSelected;
                    });
                }).finally(function (e) {
                    vm.loading--
                });
            }

            function cancelRequest(guaranteeLetter) {
                $uibModal.open({
                    templateUrl: require('/App/clinic/views/hospital/common/cancellationModal/cancel.modal.html'),
                    controller: 'clinic.views.hospital.common.cancelModal as vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        letterRequestType: function () {
                            return guaranteeLetter.serviceRequestType;
                        },
                        letterStatus: function () {
                            return guaranteeLetter.guaranteeLetterStatus;
                        }
                    }
                }).result.then(function (cancellationReason) {
                    if (cancellationReason) {
                        var input = {
                            letterNumber: guaranteeLetter.letterNumber,
                            cancellationReason: cancellationReason
                        };

                        vm.loading++;

                        hospitalPanelServiceRequestSvc.cancelGuaranteeLetterServiceRequest(input)
                            .success(function () {
                                abp.notify.success("Cancelled");
                            })
                            .finally(function () {
                                vm.loading--;
                                $state.go('clinic.inpatientDashboard');
                            });
                    }
                });
            }

            init();
        }
    ]);
})();
