(function () {
    var componentId = 'hostCorporatesFinanceManagementCollateralComponent';
    var app = angular.module('app');
    app.component(componentId, {
        templateUrl: require('/App/host/views/corporates/financeManagement/collateral/collateral.component.html'),
        controller: ['$scope', '$rootScope', 'abp.services.app.corporate', controller],
        controllerAs: 'vm',
        bindings: {
            corporateId: '<',
        }
    });

    function controller($scope, $rootScope, corporateSvc) {
        var vm = this;
        vm.loading = 0;
        vm.bankingDetails = null;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

        // Get banking details of corporate

        vm.$onChanges = function () {
            if (vm.corporateId)
                getBankingDetails(vm.corporateId);
        };

        function getBankingDetails(corporateId) {
            vm.loading++;
            corporateSvc.getCorporateForEdit(
                {
                    id: corporateId
                }).success(function (data) {
                    vm.collateralDetails = data.finance;
                }).finally(function () {
                    vm.loading--;
                });
        }
    }
})();
