(() => {
  angular
    .module('app')
    .controller(
      'common.views.guaranteeLetters.details.letterDetail.uploadAttachments.modal',
      UploadAttachmentModalController
    );

  UploadAttachmentModalController.$inject = [
    '$uibModalInstance',
    'abp.services.app.guaranteeLetter',
    'letterNumber',
  ];

  function UploadAttachmentModalController($uibModalInstance, guaranteeLetterSvc, letterNumber) {
    const vm = this;

    vm.saving = 0;
    vm.newAttachments = [];
    vm.uploadConfig = {
      objectType: 'LetterAttachment',
    };

    vm.isInvalidDocument = isInvalidDocument;
    vm.save = save;
    vm.cancel = cancel;

    init();

    function init() {}

    /* Functions */

    function isInvalidDocument() {
      return !vm.newAttachments.length;
    }

    function save() {
      if (vm.saving) return;

      vm.saving += 1;
      guaranteeLetterSvc
        .uploadAttachments({
          id: letterNumber,
          newAttachments: vm.newAttachments,
        })
        .success(() => {
          abp.notify.info(App.localize('SuccessfullyUpdated'));
          $uibModalInstance.close();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    /* End of functions */
  }
})();
