(function () {
    var controllerId = 'host.view.claimSubmissionTemplate.createEditTemplate.page';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', '$timeout', '$filter', 'abp.services.app.insuranceClaimSubmission',
        function ($scope, $state, $stateParams, $timeout, $filter, insuranceClaimSubmissionSvc) {
            var vm = this;
            function init() {
                vm.getOption();
            }

            // #region Variable declaration

            vm.loading = 0;
            vm.saving = 0;
            vm.isEdit = $state.is('host.editClaimSubmissionTemplate');
            vm.templateId = $stateParams.templateId;

            vm.insurerOptions = [];

            vm.masterClaimSubmissionCodes = [];
            vm.filteredMasterClaimSubmissionCodes = [];

            vm.input = {
                id: null,
                insurerId: null,
                insuranceClaimSubmissionCodes: []
            }

            vm.createdClaimSubmissionCodes = [];
            vm.defaultInsuranceClaimSubmissionCodesSize = 5;

            vm.GetPropertyDefinitionsText = App.localize('PropertyDefinitions').split(' ');

            // #endregion Variable declaration

            // #region Define function

            // To get the insurer name of this template.

            vm.getInsurerName = function () {
                if (vm.input.insurerId && vm.insurerOptions.length != 0) {
                    var insurer = _.find(vm.insurerOptions, function (option) {
                        return option.id == vm.input.insurerId;
                    })

                    return insurer ? insurer.name : "";
                }
                return "";
            }

            // To get the template options (Insurer, Master Claim Submission Codes, Insurance Claim Submission Template Dto (if any)).

            vm.getOption = function () {
                vm.loading++;
                insuranceClaimSubmissionSvc.getTemplateForCreateEdit({ id: vm.templateId }).success(function (data) {
                    vm.insurerOptions = data.insurerOptions;
                    vm.masterClaimSubmissionCodes = data.masterClaimSubmissionCodes;
                    vm.initFilteredMasterClaimSubmissionCodes();

                    // #region Only for update.

                    if (data.insuranceClaimSubmissionTemplateDto) {
                        vm.input.id = data.insuranceClaimSubmissionTemplateDto.id;
                        vm.input.insurerId = data.insuranceClaimSubmissionTemplateDto.insurerId;

                        // Deep clone the already created insurance claim submission template to created claim submission codes list.

                        if (data.insuranceClaimSubmissionTemplateDto.insuranceClaimSubmissionCodes) {
                            vm.createdClaimSubmissionCodes = _.clone(data.insuranceClaimSubmissionTemplateDto.insuranceClaimSubmissionCodes);
                        }
                    }

                    // #endregion Only for update.
                    if (!vm.templateId) {
                        vm.initCreatedClaimSubmissionCodes();
                    }
                }).error(function (data, status) {
                    $state.go('host.claimSubmissionTemplates');
                })
                    .finally(function () {
                        vm.loading--;
                    });
            }

            // To set up the master claim submission code (filter out selected master claim submission codes.)

            vm.initFilteredMasterClaimSubmissionCodes = function () {
                vm.filteredMasterClaimSubmissionCodes = _.clone(vm.masterClaimSubmissionCodes);
            }

            // To refresh up the master claim submission code (filter out selected master claim submission codes.)

            vm.refreshFilteredMasterClaimSubmissionCodes = function () {
                var tempMasterCodes = [];
                _.forEach(vm.masterClaimSubmissionCodes, function (item) {
                    var selectedMasterCode = _.find(vm.createdClaimSubmissionCodes, function (claimCode) {
                        return claimCode.masterCodeId === item.id;
                    });

                    if (!selectedMasterCode) {
                        tempMasterCodes.push(item);
                    }
                });
                vm.filteredMasterClaimSubmissionCodes = tempMasterCodes;
            }

            // To find the matched claim submission codes.

            vm.lookupFilteredMasterClaimSubmissionCodes = function (filter) {
                var keyword = _.trim(filter || '');
                if (keyword) {
                    var tempMasterCodes = [];

                    var regexes = _.chain(keyword.split(' '))
                        .map(function (k) {
                            return _.trim(k.toUpperCase());
                        })
                        .filter(function (k) {
                            return k;
                        })
                        .uniq()
                        .map(function (k) {
                            _.escapeRegExp(k);
                            return {
                                token: k,
                                len: k.length,
                                pattern: new RegExp('\\b' + k, 'i')
                            };
                        })
                        .value();

                    _.forEach(vm.masterClaimSubmissionCodes, function (item) {
                        var score = 0;

                        var selectedMasterCode = _.find(vm.createdClaimSubmissionCodes, function (claimCode) {
                            return claimCode.masterCodeId === item.id;
                        });

                        if (!selectedMasterCode) {
                            _.forEach(regexes, function (r) {
                                if (r.pattern.test(item.displayValue)) {
                                    score -= r.len;
                                }
                            });
                        }

                        if (score) {
                            tempMasterCodes.push({
                                score: score,
                                item: item
                            });
                        }
                    });
                    vm.filteredMasterClaimSubmissionCodes = _.chain(tempMasterCodes)
                        .sortBy(['score'])
                        .take(30)
                        .map(function (k) {
                            return k.item;
                        })
                        .uniqBy(function (s) {
                            return s.id;
                        })
                        .value();
                }
                else {
                    vm.refreshFilteredMasterClaimSubmissionCodes();
                }
            }

            // To initialise the insurance claim submission codes list. (UI design usage only)

            vm.initCreatedClaimSubmissionCodes = function () {
                if (!vm.createdClaimSubmissionCodes) {
                    vm.createdClaimSubmissionCodes = [];
                }

                while (vm.createdClaimSubmissionCodes.length < vm.defaultInsuranceClaimSubmissionCodesSize) {
                    vm.addColumn();
                }
            }

            // #region add/delete column.

            vm.addColumn = function () {
                var column = {
                    masterCodeId: null,
                    description: "",
                }
                vm.createdClaimSubmissionCodes.push(column);
            }

            vm.deleteColumn = function (index) {
                if (vm.createdClaimSubmissionCodes.length > 1) {
                    vm.createdClaimSubmissionCodes.splice(index, 1);
                }
                else {
                    vm.createdClaimSubmissionCodes = [];
                }
                App.isFormValid($scope.templateCreateOrEditForm);
                vm.initFilteredMasterClaimSubmissionCodes();
            }

            // #endregion add/delete column.

            // #region For save.

            // To validate insurer input.

            vm.validateInsurer = function () {
                var isValidForm = App.isFormValid($scope.templateInsurerForm);
                if (!vm.input.insurerId || !isValidForm) {
                    return false;
                }
                return true;
            }

            // To validate claim submission codes input.

            vm.validateClaimSubmissionCodes = function () {
                vm.cleanUpCreatedClaimSubmissionCodes();

                var isValidForm = App.isFormValid($scope.templateCreateOrEditForm);
                var invalidCodes = vm.getInvalidClaimSubmissionCodes();

                if (vm.createdClaimSubmissionCodes.length == 0 || invalidCodes.length != 0 || !isValidForm) {
                    vm.initCreatedClaimSubmissionCodes();
                    vm.initFilteredMasterClaimSubmissionCodes();
                    return false;
                }
                return true;
            }

            // Remove all invalid input.

            vm.cleanUpCreatedClaimSubmissionCodes = function () {
                vm.createdClaimSubmissionCodes = _.filter(vm.createdClaimSubmissionCodes, function (item) {
                    return (item.description && item.description.length != 0) || item.masterCodeId;
                });
            }

            vm.getInvalidClaimSubmissionCodes = function () {
                return _.filter(vm.createdClaimSubmissionCodes, function (item) {
                    return !item.description || item.description.length == 0;
                })
            }

            // Deep clone createdClaimSubmissionCodes back to input insuranceClaimSubmissionCodes.

            vm.copyToInsuranceClaimSubmissionCodes = function () {
                vm.cleanUpCreatedClaimSubmissionCodes();
                for (var i = 0; i < vm.createdClaimSubmissionCodes.length; i++) {
                    vm.createdClaimSubmissionCodes[i].priorityNumber = i + 1;

                    var code = $.extend({},
                        vm.createdClaimSubmissionCodes[i]
                    );
                    vm.input.insuranceClaimSubmissionCodes.push(code);
                }
            }

            // Create or Update the claim submission template.

            vm.save = function () {
                if (vm.validateInsurer() && vm.validateClaimSubmissionCodes()) {
                    vm.copyToInsuranceClaimSubmissionCodes();
                    vm.save++;
                    vm.loading++;

                    var apiCall = vm.isEdit
                        ? insuranceClaimSubmissionSvc.updateInsuranceClaimSubmissionTemplate
                        : insuranceClaimSubmissionSvc.createInsuranceClaimSubmissionTemplate;

                    apiCall(vm.input).success(function (data) {
                        $state.go('host.claimSubmissionTemplates');
                    }).finally(saveCallback);
                }
            }

            function saveCallback() {
                vm.loading--;
                vm.save--;
            }

            // #endregion For save.

            vm.onChangeInsuranceClaimSubmissionCode = function () {
                vm.refreshFilteredMasterClaimSubmissionCodes();
                App.isFormValid($scope.templateCreateOrEditForm);
            }

            // #endregion Define function

            init();
        }
    ]);
})();
