(() => {
  angular
    .module('app')
    .filter('visitType', visitType)
    // For backward compatibility only. Filter name don't need "Filter" suffix.
    // AngularJS automatically appends "Filter" to name for DI.
    .filter('visitTypeFilter', visitType);

  visitType.$inject = [];

  function visitType() {
    return visitTypeFilter;

    function visitTypeFilter(value) {
      const hasTxtFileSupport = abp.setting.getBoolean('Hms.Feature.TxtFileSupport');

      switch (value) {
        case 1:
          if (hasTxtFileSupport) {
            return App.localize('FirstConsultationOfficeHours');
          }
          return App.localize('VisitType_Normal');
        case 2:
          return App.localize('VisitType_ICU');
        case 3:
          if (hasTxtFileSupport) {
            return App.localize('FirstConsultationAfterOfficeHours');
          }
          return App.localize('VisitType_NormalOffHours');
        case 4:
          return App.localize('FollowUpVisitOfficeHours');
        case 5:
          return App.localize('FollowUpVisitAfterOfficeHours');
        case 6:
          return App.localize('NormalOfficeHoursSurgeryProcedure');
        case 7:
          return App.localize('AfterOfficeHoursSurgeryProcedure');
        case 8:
          return App.localize('DaySurgery');
        case 9:
          return App.localize('NormalWardFirstConsultationDuringOfficeHours');
        case 10:
          return App.localize('NormalWardFirstConsultationAfterOfficeHours');
        case 11:
          return App.localize('ICUWardFirstConsultationDuringOfficeHours');
        case 12:
          return App.localize('ICUWardFirstConsultationAfterOfficeHours');
        case 13:
          return App.localize('ICUFollowUpVisitDuringOfficeHours');
        case 14:
          return App.localize('ICUFollowUpVisitAfterOfficeHours');
        default:
          return value;
      }
    }
  }
})();
