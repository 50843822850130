(() => {
  angular
    .module('app')
    .controller(
      'common.views.patientTickets.editManualPaymentModal',
      EditManualPaymentModalController
    );

  EditManualPaymentModalController.$inject = [
    '$uibModal',
    '$uibModalInstance',
    'abp.services.app.patientTicket',
    'ticket',
    'hasInsurerClaim',
    'currencyCode'
  ];

  function EditManualPaymentModalController(
    $uibModal,
    $uibModalInstance,
    patientTicketSvc,
    ticket,
    hasInsurerClaim,
    currencyCode
  ) {
    const vm = this;

    vm.saving = 0;
    vm.ticket = ticket;
    vm.hasInsurerClaim = hasInsurerClaim;
    vm.currencyCode = currencyCode;
    vm.newManualPayment = angular.copy(ticket.manualPayment);
    vm.hasMemberBenefitUtilizationEnabled = abp.setting.getBoolean(
      'Hms.Feature.MemberBenefitUtilization'
    );
    vm.isHost = App.isHost();

    vm.save = save;
    vm.cancel = cancel;

    init();

    function init() {}

    function save() {
      vm.saving += 1;
      patientTicketSvc
        .updateManualPayment({
          id: ticket.ticketNumber,
          manualPayment: vm.newManualPayment,
          modificationRemarks: $.trim(vm.modificationRemarks),
        })
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          if (
            vm.hasMemberBenefitUtilizationEnabled &&
            vm.ticket.ticketType !== 1 &&
            vm.isHost &&
            !vm.hasInsurerClaim
          ) {
            showAdjustMemberUtilizationConfirmationModal(vm.ticket.ticketNumber);
          }
          $uibModalInstance.close();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function showAdjustMemberUtilizationConfirmationModal(ticketNumber) {
      $uibModal.open({
        templateUrl: require('../memberUtilizations/adjustMemberUtilizationConfirmationModal.html'),
        controller:
          'common.views.memberUtilizations.adjustMemberUtilizationConfirmationModal as vm',
        backdrop: 'static',
        size: 'sm',
        resolve: {
          ticketNumber() {
            return ticketNumber;
          },
        },
      });
    }
  }
})();
