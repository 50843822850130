(() => {
  angular
    .module('app')
    .controller(
      'common.views.patientTickets.viewCorporateChangesModal',
      ViewCorporateChangesModalController
    );

  ViewCorporateChangesModalController.$inject = [
    '$uibModalInstance',
    'abp.services.app.patientTicket',
    'ticket',
  ];

  function ViewCorporateChangesModalController($uibModalInstance, patientTicketSvc, ticket) {
    const vm = this;

    vm.loading = 0;
    vm.logs = [];
    vm.ticket = ticket;

    vm.close = close;

    init();

    function init() {
      getCorporateChanges();
    }

    function getCorporateChanges() {
      vm.loading += 1;
      patientTicketSvc
        .getCorporateChanges({
          id: ticket.ticketNumber,
        })
        .success((data) => {
          vm.logs = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function close() {
      $uibModalInstance.close();
    }
  }
})();
