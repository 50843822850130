(() => {
  angular
    .module('app')
    .controller('common.views.patientTickets.importManualTicketsModal', ImportManualTicketsModalController);

  ImportManualTicketsModalController.$inject = [
    '$scope',
    '$uibModal',
    '$uibModalInstance',
    'FileUploader',
    '$interval',
    'abp.services.app.patientTicket',
    'abp.services.app.commonLookup',
    '$rootScope',
  ];

  function ImportManualTicketsModalController(
    $scope,
    $modal,
    $uibModalInstance,
    FileUploader,
    $interval,
    patientTicketSvc,
    commonLookupSvc,
    $root
  ) {
    const vm = this;

    vm.corporates = [];
    vm.columnDefinitionsShown = false;
    vm.savingProgress = 0;

    // ImportPatientTickets

    vm.uploader = new FileUploader({
      url: $root.resolveAppPath('PatientTicket/ImportManualTickets'),
      queueLimit: 1,
      headers: App.getFileUploaderHeaders(),
      filters: [
        {
          name: 'excelFilter',
          fn(n) {
            const t = `|${n.type.slice(n.type.lastIndexOf('/') + 1)}|`;
            if (
              '|vnd.openxmlformats-officedocument.spreadsheetml.sheet|'.indexOf(t) === -1 &&
              !n.name.endsWith('.xlsx')
            ) {
              abp.message.warn(App.localize('Excel_Warn_FileType'));
              return false;
            }
            return true;
          },
        },
      ],
    });

    vm.uploader.onBeforeUploadItem = uploaderOnBeforeUploadItem;
    vm.uploader.onCompleteItem = uploaderOnCompleteItem;

    vm.lookupCorporates = lookupCorporates;
    vm.exportTemplateToExcel = exportTemplateToExcel;
    vm.save = save;
    vm.cancel = cancel;

    init();

    function init() {
      function tickerJob() {
        vm.savingProgress = (vm.savingProgress + 1) % 100;
      }

      let tickerInterval = $interval(tickerJob, 1000);
      $scope.$on('$destroy', () => {
        if (angular.isDefined(tickerInterval)) {
          $interval.cancel(tickerInterval);
          tickerInterval = undefined;
        }
      });
      vm.lookupCorporates();
    }

    function lookupCorporates() {
      commonLookupSvc
        .getCorporates({})
        .success((data) => {
          vm.corporates = _.map(data.items, (d) => {
            d.description = `${d.tenancyName} ${d.name}`;
            return d;
          });
        })
        .finally(() => {
          vm.exporting -= 1;
        });
    }

    // Download current template.

    function exportTemplateToExcel() {
      vm.exporting += 1;
      patientTicketSvc
        .getImportManualTicketsTemplateToExcel({ id: vm.corporateCode })
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.exporting -= 1;
        });
    }

    function uploaderOnBeforeUploadItem(item) {
      item.formData = [{ corporateCode: vm.corporateCode }];
    }

    function uploaderOnCompleteItem(n, t) {
      vm.saving = false;
      $uibModalInstance.close();

      if (!t.success && t.error) {
        if (t.error.details) {
          $modal.open({
            templateUrl: require('./importManualTicketsResultModal.html'),
            controller: 'common.views.patientTickets.importManualTicketsResultModal as vm',
            backdrop: 'static',
            resolve: {
              errors() {
                return JSON.parse(t.error.details);
              },
            },
          });
        }

        if (t.error.message) {
          abp.message.error(t.error.message);
        }
      }

      if (t.success) {
        abp.message.success(t.result, App.localize('ImportManualTicketsSuccessful'));
      }
    }

    function save() {
      vm.savingProgress = 0;
      vm.saving = true;
      vm.uploader.uploadAll();
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
