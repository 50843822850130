(() => {
  angular.module('app').controller('common.views.roles.createOrEditModal', RoleEditModalController);

  RoleEditModalController.$inject = [
    '$uibModalInstance',
    'abp.services.app.role',
    'roleId',
    'Hms.Authorization.Roles.Role',
  ];

  function RoleEditModalController($uibModalInstance, roleSvc, roleId, constsRole) {
    const vm = this;

    vm.constsRole = constsRole;
    vm.loading = 0;
    vm.saving = 0;
    vm.isHost = App.isHost();
    vm.role = null;
    vm.permissionEditData = null;
    const hasFinanceService = abp.setting.getBoolean('Hms.Feature.FinanceService');
    const hasAccessFinanceTransactionData =
      vm.isHost || abp.setting.getBoolean('Hms.Feature.AccessFinanceTransactionData');

    vm.save = save;
    vm.cancel = cancel;

    init();

    function init() {
      vm.loading += 1;
      roleSvc
        .getRoleForEdit({
          id: roleId,
        })
        .success((data) => {
          vm.role = data.role;

          if (!hasFinanceService || !hasAccessFinanceTransactionData) {
            // Remove permissions from the permission tree if feature flag is not on.

            _.remove(data.grantedPermissionNames, (item) => item.includes('HealthMetrics.Finance'));
            _.remove(data.permissions, (item) =>
              item.name ? item.name.includes('HealthMetrics.Finance') : false
            );
          } else if (!vm.isHost) {
            // Remove old transactions permission from the permission tree.

            const regex = /^SaleTransactions/g;
            _.remove(data.grantedPermissionNames, (item) => item.match(regex));
            _.remove(data.permissions, (item) => (item.name ? item.name.match(regex) : false));
          }

          vm.permissionEditData = {
            permissions: data.permissions,
            grantedPermissionNames: data.grantedPermissionNames,
          };
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function save() {
      vm.saving += 1;
      roleSvc
        .createOrUpdateRole({
          role: vm.role,
          grantedPermissionNames: vm.permissionEditData.grantedPermissionNames,
        })
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          $uibModalInstance.close();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
