(function () {
    var controllerId = 'revenue.views.subscriptions.editCharging.editChargingModal';
    var module = angular.module('revenue');
    module.controller(controllerId, [
        '$uibModalInstance', '$filter', 'abp.services.revenue.subscription', 'subscription',
        function ($uibModalInstance, $filter, subscriptionSvc, subscription) {
            function init() {
                vm.subscription = {
                    id: subscription.id,
                    currencyCode: subscription.currencyCode,
                    chargingBasis: subscription.chargingBasis,
                    billingFrequency: subscription.billingFrequency,
                    charges: subscription.charges,
                    minimumHeadcount: subscription.minimumHeadcount,
                    billingMode: subscription.billingMode,
                    firstPrepaidAmount: subscription.firstPrepaidAmount
                };
            }

            const vm = this;
            vm.saving = false;
            vm.hasBillingCycle = subscription.hasBillingCycle;
            vm.showPremium = subscription.chargingBasis === 2;
            vm.showExternal = subscription.billingMode === 1;

            vm.isHeadcountChargingBasis = function () {
                return vm.subscription && (
                    vm.subscription.chargingBasis === 1
                    || vm.subscription.chargingBasis === 2);
            };

            vm.save = function () {
                if (vm.saving) return;
                vm.saving = true;

                subscriptionSvc.updateSubscriptionCharging(vm.subscription)
                    .success(function (data) {
                        abp.notify.info(App.localize("SuccessfullySaved"));
                        $uibModalInstance.close(data);
                    }).finally(function () {
                        vm.saving = false;
                    });
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            init();
        }
    ]);
})();
