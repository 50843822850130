(function () {
    var controllerId = 'clinic.views.employees.applyGuaranteeLetterModal.index';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'abp.services.app.clinicEmployee', 'clinic', 'employee',
        function ($scope, $uibModalInstance, employeeSvc, clinic, employee) {
            var vm = this;
            vm.saving = 0;
            vm.employee = employee;
            vm.clinic = clinic;
            vm.disciplineId = null;
            vm.isPatientInHospital = null;
            vm.isDisciplineInReferralExceptions = false;

            vm.applyGuaranteeLetter = function () {
                vm.saving++;
                employeeSvc.applyGuaranteeLetter({
                    employeeId: vm.employee.id,
                    clinicLocationId: vm.clinic.clinicLocationId,
                    disciplineId: vm.disciplineId,
                    newAttachments: vm.newAttachments
                }).success(function (data) {
                    $uibModalInstance.close(data);
                }).finally(function () {
                    vm.saving--;
                });
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            vm.onDisciplineSelected = function (selectedDiscipline) {
                vm.isDisciplineInReferralExceptions = _.includes(vm.employee.referralExceptions, selectedDiscipline.value);
            };

            /* Gurantee Letter (GL) attachments */

            vm.newAttachments = [];
            vm.uploadConfig = {
                objectType: 'LetterAttachment'
            };

            vm.isInvalidAttachment = function () {
                return vm.employee.requireReferral
                    && !vm.isDisciplineInReferralExceptions
                    && !vm.newAttachments.length;
            };
        }
    ]);
})();
