(function () {
    angular.module('app').component('hostViewsOperationsDashboardInsuranceClaimsDetailsOverviewComponent', {
        templateUrl: require('/App/host/views/operationsDashboard/insuranceClaims/details/overview/overview.component.html'),
        controller: ['$state', '$scope', '$stateParams', 'abp.services.app.insurerClaim', 'Hms.InsurerClaims.InsurerClaimEnums.InsurerClaimStatus', controller],
        controllerAs: 'vm'
    });

    function controller($state, $scope, $stateParams, insurerClaimSvc, enumInsurerClaimStatus) {
        var vm = this;
        vm.loading = 0;
        vm.patient = null;
        vm.insurancePlanDetails = null;
        vm.claimDetails = null;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

        vm.enums = {};
        vm.enums.insurerClaimStatus = enumInsurerClaimStatus;

        function init() {
            getClaimDetails();
        }

        function getClaimDetails() {
            vm.loading++;
            insurerClaimSvc.getInsurerClaimDetails({
                id: $stateParams.id
            }).success(function (data) {
                vm.patient = data.patient;
                vm.insurancePlanDetails = data.insurancePlanDetails;
                vm.claimDetails = data.claimDetails;
            }).finally(function () {
                vm.loading--;
            });
        }

        init();
    }
})();
