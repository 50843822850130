(function () {
    var app = angular.module('app');

    app.controller('corporate.views.inpatientGuaranteeLetters', [
        '$scope', '$stateParams', '$state', '$timeout', 'abp.services.app.inpatientGuaranteeLetter', 'Hms.GuaranteeLetters.GuaranteeLetterStatus', 'Hms.GuaranteeLetters.GuaranteeLetterState',
        'moment', controller]);

    function controller($scope, $stateParams, $state, $timeout, inpatientGuaranteeLetterSvc, enumGuaranteeLetterStatus, enumGuaranteeLetterState, moment) {
        function init() {
            getHospitalLocations();
            getGuaranteeLetters();

            $timeout(function () {
                vm.gridOptions.paginationCurrentPage = Math.floor(vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1);
            });
        }

        var vm = this;
        vm.loading = 0;
        vm.exporting = 0;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.panelLocations = [];
        vm.advancedFiltersAreShown = false;

        vm.enums = {
          guaranteeLetterStatus: enumGuaranteeLetterStatus,
          guaranteeLetterState: enumGuaranteeLetterState
        };


        vm.permissions = {
            advance: abp.auth.isGranted('GuaranteeLetters.Corporate.Advance')
        };

        $scope.$on('$viewContentLoaded', function () {
            App.initAjax();
        });

        vm.requestParams = {
            letterNumber: $stateParams.letterNumber,
            claimNumber: $stateParams.claimNumber,
            ticketNumber: $stateParams.ticketNumber,
            status: $stateParams.status,
            panelLocationId: $stateParams.panelLocationId,
            patient: $stateParams.patient,
            subsidiaryId: $stateParams.subsidiaryId,
            skipCount: $stateParams.skipCount || 0,
            maxResultCount: $stateParams.maxResultCount || app.consts.grid.defaultPageSize,
            sorting: $stateParams.sorting
        };

        vm.approvalDateRangeOptions = App.createDateRangePickerOptions();
        vm.approvalDateRangeModel = {
            startDate: $stateParams.approvalStartDate ? moment($stateParams.approvalStartDate) : null,
            endDate: $stateParams.approvalEndDate ? moment($stateParams.approvalEndDate) : null
        };

        vm.scheduledAdmissionDateRangeOptions = App.createDateRangePickerOptions();
        vm.scheduledAdmissionDateRangeModel = {
            startDate: $stateParams.scheduledAdmissionStartDate ? moment($stateParams.scheduledAdmissionStartDate) : null,
            endDate: $stateParams.scheduledAdmissionEndDate ? moment($stateParams.scheduledAdmissionEndDate) : null
        };

        vm.gridOptions = {
            appScopeProvider: vm,
            paginationPageSizes: app.consts.grid.defaultPageSizes,
            paginationPageSize: app.consts.grid.defaultPageSize,
            paginationCurrentPage: 0,
            useExternalPagination: true,
            useExternalSorting: true,
            resizable: true,
            columnDefs: [{
                name: 'Actions',
                displayName: 'Actions',
                enableSorting: false,
                width: 81
            }, {
                displayName: App.localize('GuaranteeLetterNumber'),
                field: 'letterNumber',
                minWidth: 100,
                width: 150
            }, {
                displayName: App.localize('Status'),
                field: 'status',
                minWidth: 120,
                cellTemplate: 'statusTemplate'
            }, {
                displayName: App.localize('ApprovalDate'),
                field: 'approvalTime',
                minWidth: 120,
                cellFilter: 'momentFormat: \'L\''
            }, {
                displayName: App.localize('TicketNumber'),
                field: 'ticketNumber',
                minWidth: 100,
                width: 150
            }, {
                displayName: App.localize('PanelLocation'),
                enableSorting: false,
                field: 'panelLocationName',
                minWidth: 120
            }, {
                displayName: App.localize('Patient'),
                enableSorting: false,
                field: 'patientName',
                minWidth: 120,
                cellTemplate: 'patientTemplate'
            }, {
                displayName: App.localize('Principal'),
                enableSorting: false,
                field: 'patientPrincipalName',
                minWidth: 120
            }, {
                displayName: App.localize('Subsidiary'),
                enableSorting: false,
                field: 'patientSubsidiaryName',
                minWidth: 120
            }, {
                displayName: App.localize('ScheduledAdmissionTime'),
                enableSorting: false,
                field: 'scheduledAdmissionTime',
                cellFilter: 'momentFormat: \'L LT\'',
                minWidth: 120
            }, {
                displayName: App.localize('CheckedInTime'),
                enableSorting: false,
                field: 'checkedInTime',
                cellFilter: 'momentFormat: \'L LT\'',
                minWidth: 120
            }],
            onRegisterApi: function (gridApi) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function (scope, callback) {
                    vm.requestParams.sorting = callback.length && callback[0].field ? callback[0].field + ' ' + callback[0].sort.direction : null;
                    getGuaranteeLetters();
                });
                gridApi.pagination.on.paginationChanged($scope, App.setPagination(vm.requestParams, vm.getGuaranteeLetters));
            },
            data: []
        };
        vm.isHost || vm.permissions.advance || vm.gridOptions.columnDefs.shift();

        vm.getGuaranteeLetters = getGuaranteeLetters;
        vm.exportToExcel = exportToExcel;

        /* Functions */

        function getGuaranteeLetters() {
            var input = getRequestInput();

            vm.loading++;
            inpatientGuaranteeLetterSvc.getInpatientGuaranteeLetters(input)
                .success(function (data) {
                    vm.gridOptions.data = data.items;
                    vm.gridOptions.totalItems = data.totalCount;
                    vm.serviceRequestTypes = data.serviceRequestTypes;
                    vm.queryStatuses = data.queryStatuses;
                })
                .finally(function () {
                    vm.loading--;
                });
        }

        function getHospitalLocations() {
            vm.loading++;
            inpatientGuaranteeLetterSvc.findHospitalLocations()
                .success(function (data) {
                    vm.panelLocations = data.items;
                })
                .finally(function () {
                    if (!vm.panelLocations.length)
                        vm.requestParams.panelLocationId = null;

                    vm.loading--;
                });
        }

        function exportToExcel() {
            var input = getRequestInput();

            vm.exporting++;
            inpatientGuaranteeLetterSvc.getInpatientGuaranteeLettersToExcel(input)
                .success(function (data) {
                    App.downloadTempFile(data);
                }).finally(function () {
                    abp.notify.success(App.localize('Success'));
                    vm.exporting--;
                });
        }

        function getRequestInput() {
            var input = $.extend({}, vm.requestParams);

            input.approvalStartDate = vm.approvalDateRangeModel.startDate;
            input.approvalEndDate = vm.approvalDateRangeModel.endDate;

            input.scheduledAdmissionStartDate = vm.scheduledAdmissionDateRangeModel.startDate;
            input.scheduledAdmissionEndDate = vm.scheduledAdmissionDateRangeModel.endDate;

            registerStateParams();

            return input;
        }

        function registerStateParams() {
            var approvalStartDate = vm.approvalDateRangeModel.startDate ? vm.approvalDateRangeModel.startDate.format('YYYY-MM-DD') : null;
            var approvalEndDate = vm.approvalDateRangeModel.endDate ? vm.approvalDateRangeModel.endDate.format('YYYY-MM-DD') : null;
            var scheduledAdmissionStartDate = vm.scheduledAdmissionDateRangeModel.startDate ? vm.scheduledAdmissionDateRangeModel.startDate.format('YYYY-MM-DD') : null;
            var scheduledAdmissionEndDate = vm.scheduledAdmissionDateRangeModel.endDate ? vm.scheduledAdmissionDateRangeModel.endDate.format('YYYY-MM-DD') : null;

            $state.transitionTo($state.current, {
                skipCount: vm.requestParams.skipCount,
                maxResultCount: vm.requestParams.maxResultCount,
                sorting: vm.requestParams.sorting,
                letterNumber: vm.requestParams.letterNumber,
                ticketNumber: vm.requestParams.ticketNumber,
                claimNumber: vm.requestParams.claimNumber,
                status: vm.requestParams.status,
                clinicLocation: vm.requestParams.clinicLocationId,
                patient: vm.requestParams.patient,
                subsidiaryId: vm.requestParams.subsidiaryId,
                approvalStartDate: approvalStartDate,
                approvalEndDate: approvalEndDate,
                scheduledAdmissionStartDate: scheduledAdmissionStartDate,
                scheduledAdmissionEndDate: scheduledAdmissionEndDate
            }, {
                notify: false
            });
        }

        /* End of functions */

        init();
    }
})();
