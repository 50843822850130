(() => {
  angular.module('app').controller('host.views.inpatientClaims', InpatientClaimsPageController);

  InpatientClaimsPageController.$inject = [
    '$scope',
    '$stateParams',
    '$state',
    '$timeout',
    'abp.services.app.inpatientClaim',
    'abp.services.app.commonLookup',
    'Hms.BillingStatus',
    'moment',
    'Hms.PatientTicketBillings.BillStatus',
    'appConstants',
  ];

  function InpatientClaimsPageController(
    $scope,
    $stateParams,
    $state,
    $timeout,
    inpatientClaimSvc,
    commonLookupSvc,
    enumBillingStatus,
    moment,
    enumBillStatus,
    appConstants
  ) {
    const vm = this;

    vm.loading = 0;
    vm.exporting = 0;

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.coveredBy = [
      { text: 'Insurer', value: 1 },
      { text: 'Self-Funded', value: 2 },
    ];

    vm.hasInsuranceModule = abp.setting.getBoolean('Hms.Feature.InsuranceModule');

    vm.enums = {
      billStatus: enumBillStatus,
    };
    vm.enums.billingStatus = enumBillingStatus;
    let subsidiaries = [];

    vm.hasAccessFinanceTransactionData = abp.setting.getBoolean(
      'Hms.Feature.AccessFinanceTransactionData'
    );

    vm.requestParams = {
      serviceType: $stateParams.serviceType,
      status: $stateParams.status,
      claimNumber: $stateParams.claimNumber,
      ticketNumber: $stateParams.ticketNumber,
      transactionNumber: $stateParams.transactionNumber,
      letterNumber: $stateParams.letterNumber,
      subsidiaryId: $stateParams.subsidiaryId,
      processorId: $stateParams.processor,
      organizationId: $stateParams.organizationId,
      member: $stateParams.member,
      skipCount: $stateParams.skipCount || 0,
      maxResultCount: $stateParams.maxResultCount || appConstants.grid.defaultPageSize,
      sorting: $stateParams.sorting,
      submissionStartDate: $stateParams.submissionStartDate,
      submissionEndDate: $stateParams.submissionEndDate,
      insurer: $stateParams.insurer,
      coveredBy: $stateParams.coveredBy,
      masterPolicy: $stateParams.masterPolicy,
    };

    vm.advancedFiltersAreShown = false;

    vm.submissionDateRangeOptions = App.createDateRangePickerOptions();
    vm.submissionDateRangeModel = {
      startDate: $stateParams.submissionStartDate ? moment($stateParams.submissionStartDate) : null,
      endDate: $stateParams.submissionEndDate ? moment($stateParams.submissionEndDate) : null,
    };

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSizes,
      paginationPageSize: appConstants.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      resizable: true,
      columnDefs: getGridOptionsColumnDefs(),
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          vm.requestParams.sorting =
            callback.length && callback[0].field
              ? `${callback[0].field} ${callback[0].sort.direction}`
              : null;
          vm.getInpatientClaims();
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, vm.getInpatientClaims)
        );
      },
      data: [],
    };

    $scope.$on('$viewContentLoaded', () => {
      App.initAjax();
    });

    vm.refreshSubsidiaries = refreshSubsidiaries;
    vm.subsidiaryChanged = subsidiaryChanged;
    vm.getInpatientClaims = getInpatientClaims;
    vm.exportToExcel = exportToExcel;
    vm.insurerManager = insurerManager;
    vm.getDateTimeString = getDateTimeString;

    init();

    function init() {
      getServiceRequestProcessors();
      if (vm.hasInsuranceModule) getInsurers();

      $timeout(() => {
        vm.gridOptions.paginationCurrentPage = Math.floor(
          vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1
        );
      });
    }

    function registerStateParams() {
      const dateRangeModel = formatDateRangeModel();

      $state.transitionTo(
        $state.current,
        {
          serviceType: vm.requestParams.serviceType,
          status: vm.requestParams.status,
          claimNumber: vm.requestParams.claimNumber,
          ticketNumber: vm.requestParams.ticketNumber,
          transactionNumber: vm.requestParams.transactionNumber,
          letterNumber: vm.requestParams.letterNumber,
          subsidiaryId: vm.requestParams.subsidiaryId,
          processorId: vm.requestParams.processor,
          organizationId: vm.requestParams.organizationId,
          member: vm.requestParams.member,
          skipCount: vm.requestParams.skipCount || 0,
          maxResultCount: vm.requestParams.maxResultCount || appConstants.grid.defaultPageSize,
          sorting: vm.requestParams.sorting,
          submissionStartDate: dateRangeModel.submissionStartDate,
          submissionEndDate: dateRangeModel.submissionEndDate,
          insurer: vm.requestParams.insurer,
          coveredBy: vm.requestParams.coveredBy,
          masterPolicy: vm.requestParams.masterPolicy,
        },
        {
          notify: false,
        }
      );
    }

    function getGridOptionsColumnDefs() {
      const columnDefs = [
        {
          name: 'Actions',
          displayName: 'Actions',
          enableSorting: false,
          width: 81,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'viewTemplate',
        },
        {
          displayName: App.localize('PanelClaimNumber'),
          enableSorting: false,
          field: 'claimNumber',
          minWidth: 100,
        },
        {
          displayName: App.localize('ClaimSubmissionTime'),
          field: 'claimDate',
          cellTemplate: 'claimSubmissionTimeTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('Type'),
          enableSorting: false,
          field: 'serviceType',
          cellTemplate: 'serviceTypeTemplate',
          minWidth: 60,
          width: 60,
        },
        {
          displayName: App.localize('ClaimStatus'),
          enableSorting: false,
          field: 'claimStatus',
          minWidth: 100,
          cellTemplate: 'statusTemplate',
        },
        {
          displayName: App.localize('Aging'),
          enableSorting: false,
          field: 'agingDuration',
          minWidth: 100,
        },
        {
          displayName: App.localize('ProcessedDate'),
          enableSorting: false,
          field: 'processedTime',
          cellTemplate: 'processedTimeTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('Assignee'),
          enableSorting: false,
          field: 'processorName',
          minWidth: 100,
        },
        {
          displayName: App.localize('GuaranteeLetterNumber'),
          enableSorting: false,
          field: 'guaranteeLetterNumber',
          minWidth: 100,
        },
        {
          displayName: App.localize('TicketNumber'),
          enableSorting: false,
          field: 'ticketNumber',
          minWidth: 100,
        },
        {
          displayName: App.localize('Patient'),
          enableSorting: false,
          field: 'patientName',
          minWidth: 120,
          cellTemplate: 'patientTemplate',
        },
        {
          displayName: App.localize('Subsidiary'),
          enableSorting: false,
          field: 'memberSubsidiaryName',
          minWidth: 100,
        },
        {
          displayName: App.localize('TotalClaimAmount'),
          enableSorting: false,
          field: 'totalClaimAmount',
          cellFilter: 'currencyFormat: grid.appScope.currencyCode',
          minWidth: 100,
        },
        {
          displayName: App.localize('TotalCoveredAmount'),
          enableSorting: false,
          field: 'coveredAmount',
          cellFilter: 'currencyFormat: grid.appScope.currencyCode',
          minWidth: 100,
        },
        {
          name: App.localize('OrganizationBillingStatus'),
          displayName: App.localize('OrganizationBillingStatus'),
          enableSorting: false,
          field: 'organizationBillingStatus',
          minWidth: 100,
          cellTemplate: 'organizationBillingTemplate',
        },
        {
          name: App.localize('PanelBillingStatus'),
          displayName: App.localize('PanelBillingStatus'),
          enableSorting: false,
          field: 'panelBillingStatus',
          minWidth: 100,
          cellTemplate: 'panelBillingTemplate',
        },
      ];

      if (vm.hasInsuranceModule) {
        columnDefs.push(
          {
            displayName: App.localize('Insurer'),
            enableSorting: false,
            field: 'insurerName',
            minWidth: 100,
            width: 140,
            cellTemplate: 'insurerTemplate',
          },
          {
            displayName: App.localize('PolicyNo'),
            enableSorting: false,
            field: 'masterPolicyNumber',
            minWidth: 100,
            width: 140,
          }
        );
      }

      return columnDefs;
    }

    function getServiceRequestProcessors() {
      vm.loading += 1;
      commonLookupSvc
        .getServiceRequestProcessors()
        .success((data) => {
          vm.serviceRequestProcessors = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getInsurers() {
      vm.loading += 1;
      commonLookupSvc
        .getInsurers()
        .success((data) => {
          vm.insurers = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function refreshSubsidiaries() {
      const { organizationId } = vm.requestParams;
      vm.subsidiaries = organizationId ? _.filter(subsidiaries, { organizationId }) : subsidiaries;

      if (!organizationId) vm.requestParams.subsidiaryId = null;

      if (vm.requestParams.subsidiaryId) {
        const sub = _.find(vm.subsidiaries, { value: vm.requestParams.subsidiaryId });
        if (!sub) vm.requestParams.subsidiaryId = null;
      }
    }

    function subsidiaryChanged() {
      if (vm.requestParams.subsidiaryId) {
        const sub = _.find(subsidiaries, { value: vm.requestParams.subsidiaryId });
        vm.requestParams.organizationId = sub ? sub.organizationId : null;
      }
    }

    function getInpatientClaims() {
      const input = $.extend({}, vm.requestParams, formatDateRangeModel());
      input.getTenants = vm.organizations == null || vm.organizations.length === 0;

      registerStateParams();
      vm.loading += 1;
      inpatientClaimSvc
        .getInpatientClaims(input)
        .success((data) => {
          vm.gridOptions.totalItems = data.totalCount;
          vm.gridOptions.data = data.items;
          subsidiaries = input.getTenants ? data.subsidiaries : subsidiaries;
          vm.organizations = input.getTenants ? data.organizations : vm.organizations;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function exportToExcel() {
      const input = $.extend({}, vm.requestParams, formatDateRangeModel());
      registerStateParams();

      vm.exporting += 1;
      inpatientClaimSvc
        .getInpatientClaimsToExcel(input)
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.exporting -= 1;
        });
    }

    function formatDateRangeModel() {
      return {
        submissionStartDate: vm.submissionDateRangeModel.startDate
          ? vm.submissionDateRangeModel.startDate.format('YYYY-MM-DD')
          : null,
        submissionEndDate: vm.submissionDateRangeModel.endDate
          ? vm.submissionDateRangeModel.endDate.format('YYYY-MM-DD')
          : null,
      };
    }

    function insurerManager() {
      if (vm.requestParams.coveredBy !== 1) vm.requestParams.insurer = null;
    }

    function getDateTimeString(date, timeZoneId) {
      return App.getDateTimeString(date, timeZoneId);
    }
  }
})();
