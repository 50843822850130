(() => {
  angular.module('app').filter('momentFormatUtc', momentFormatUtc);

  momentFormatUtc.$inject = ['moment'];

  function momentFormatUtc(moment) {
    return filterNameFilter;

    function filterNameFilter(dateTime, formatString) {
      // Some country has timezone changes in the past (eg. Malaysia).
      // This function converts the time to UTC time zone first,
      // then format it based on the formatString.
      if (dateTime === undefined) return undefined;

      return dateTime ? moment.utc(dateTime, 'YYYY-MM-DD').format(formatString) : '-';
    }
  }
})();
