(() => {
  angular.module('app').component('commonComponentsBankDetailsComponent', {
    templateUrl: require('./bankDetails.component.html'),
    controller: BankDetailsController,
    controllerAs: 'vm',
    bindings: {
      info: '<',
    },
  });

  BankDetailsController.$inject = [];

  function BankDetailsController() {
    // const vm = this;
  }
})();
