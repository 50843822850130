(function () {
    var controllerId = 'pharmacy.views.fulfillments';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', '$timeout', 'abp.services.app.prescription',
        function ($scope, $state, $stateParams, $timeout, prescriptionSvc) {
            function init() {
                $timeout(function () {
                    vm.fulfillmentGridOptions.paginationCurrentPage = Math.floor(vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1);
                });
            }

            var vm = this;
            vm.loading = 0;
            vm.exporting = 0;
            vm.advancedFiltersAreShown = false;
            vm.dateRangeOptions = App.createDateRangePickerOptions();
            vm.dateRangeModel = {
                startDate: null,
                endDate: null
            };

            vm.requestParams = {
                fulfillmentNumber: $stateParams.fulfillmentNumber,
                ticketNumber: $stateParams.ticketNumber,
                transactionNumber: $stateParams.transactionNumber,
                customerOrganizationName: $stateParams.customerOrganizationName,
                prescriberOrganizationName: $stateParams.prescriberOrganizationName,
                customer: $stateParams.customer,
                minAmount: parseFloat($stateParams.minAmount) || null,
                maxAmount: parseFloat($stateParams.maxAmount) || null,
                minCoveredAmount: parseFloat($stateParams.minCoveredAmount) || null,
                maxCoveredAmount: parseFloat($stateParams.maxCoveredAmount) || null,
                minManualPayment: parseFloat($stateParams.minManualPayment) || null,
                maxManualPayment: parseFloat($stateParams.maxManualPayment) || null,
                status: $stateParams.status,
                skipCount: $stateParams.skipCount || 0,
                maxResultCount: $stateParams.maxResultCount || app.consts.grid.defaultPageSize,
                sorting: $stateParams.sorting
            };

            vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

            $scope.$on("$viewContentLoaded", function () {
                App.initAjax();
            });

            // Define grid options

            vm.fulfillmentGridOptions = {
                appScopeProvider: vm,
                paginationPageSizes: app.consts.grid.defaultPageSizes,
                paginationPageSize: app.consts.grid.defaultPageSize,
                paginationCurrentPage: 0,
                useExternalPagination: true,
                useExternalSorting: true,
                columnDefs: [{
                    enableSorting: false,
                    name: App.localize('Actions'),
                    width: 100,
                    headerCellTemplate: "<span></span>",
                    cellTemplate: 'viewTemplate'
                }, {
                    displayName: App.localize('CreationTime'),
                    field: 'creationTime',
                    cellFilter: 'momentFormat: \'L LT\'',
                    minWidth: 140
                }, {
                    displayName: App.localize('FulfillmentNumber'),
                    field: 'fulfillmentNumber',
                    minWidth: 120,
                    enableSorting: false
                }, {
                    displayName: App.localize('TicketNumber'),
                    field: 'ticketNumber',
                    minWidth: 120
                }, {
                    displayName: App.localize('Organization'),
                    field: 'customerOrganizationName',
                    minWidth: 120
                }, {
                    displayName: App.localize('Customer'),
                    field: 'customerName',
                    minWidth: 120,
                    cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD CUSTOM_FILTERS}} <span ng-if="row.entity.customerIdNumber">({{row.entity.customerIdNumber | nationalIdFilter}})</span></div>'
                }, {
                    displayName: App.localize('Prescriber'),
                    field: 'prescriberOrganizationName',
                    minWidth: 120
                }, {
                    displayName: App.localize('Amount'),
                    field: 'totalAmount',
                    cellFilter: 'currencyFormat: grid.appScope.currencyCode',
                    minWidth: 100
                }, {
                    displayName: App.localize('CoveredAmount'),
                    field: 'customerOrganizationAmount',
                    cellFilter: 'currencyFormat: grid.appScope.currencyCode',
                    minWidth: 100
                }, {
                    displayName: App.localize('CollectCash'),
                    field: 'customerAmount',
                    cellFilter: 'currencyFormat: grid.appScope.currencyCode',
                    minWidth: 100
                }, {
                    name: App.localize('BillingStatus'),
                    displayName: App.localize('BillingStatus'),
                    enableSorting: false,
                    minWidth: 120,
                    cellTemplate: 'clinicBillingTemplate'
                }],
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                    $scope.gridApi.core.on.sortChanged($scope, function (scope, callback) {
                        var sorting = "";
                        for (var i = 0; i < callback.length; i++) {
                            if (i) sorting += ", ";
                            sorting += callback[i].field + " " + callback[i].sort.direction;
                        }
                        vm.requestParams.sorting = sorting;
                        vm.getFulfillments();
                    });
                    gridApi.pagination.on.paginationChanged($scope, App.setPagination(vm.requestParams, vm.getFulfillments));
                },
                data: []
            };

            function registerStateParams() {
                var startDate = null;
                var endDate = null;
                if (vm.dateRangeModel.startDate)
                    startDate = vm.dateRangeModel.startDate.format('YYYY-MM-DD');
                if (vm.dateRangeModel.endDate)
                    endDate = vm.dateRangeModel.endDate.format('YYYY-MM-DD');

                $state.transitionTo($state.current, {
                    fulfillmentNumber: vm.requestParams.fulfillmentNumber,
                    ticketNumber: vm.requestParams.ticketNumber,
                    transactionNumber: vm.requestParams.transactionNumber,
                    customerOrganizationName: vm.requestParams.customerOrganizationName,
                    prescriberOrganizationName: vm.requestParams.prescriberOrganizationName,
                    customer: vm.requestParams.customer,
                    minAmount: vm.requestParams.minAmount,
                    maxAmount: vm.requestParams.maxAmount,
                    minCoveredAmount: vm.requestParams.minCoveredAmount,
                    maxCoveredAmount: vm.requestParams.maxCoveredAmount,
                    minManualPayment: vm.requestParams.minManualPayment,
                    maxManualPayment: vm.requestParams.maxManualPayment,
                    status: $stateParams.status,
                    startDate: startDate,
                    endDate: endDate,
                    skipCount: vm.requestParams.skipCount,
                    maxResultCount: vm.requestParams.maxResultCount,
                    sorting: vm.requestParams.sorting
                }, {
                    notify: false
                });
            }

            vm.getFulfillments = function () {
                vm.loading++;
                registerStateParams();

                var input = getRequestInput();
                prescriptionSvc.getFulfillments(input).success(function (data) {
                    vm.fulfillmentGridOptions.totalItems = data.totalCount;
                    vm.fulfillmentGridOptions.data = data.items;
                    vm.isOrigin = data.isOrigin;
                }).finally(function (e) {
                    vm.loading--;
                });
            };

            vm.exportToExcel = function () {
                var input = getRequestInput();
                vm.exporting++;
                prescriptionSvc.getFulfillmentsToExcel(input).success(function (data) {
                    App.downloadTempFile(data);
                }).finally(function () {
                    vm.exporting--;
                });
            };

            function getRequestInput() {
                var input = $.extend({}, vm.requestParams, vm.dateRangeModel);

                if (vm.dateRangeModel.startDate)
                    input.startDate = vm.dateRangeModel.startDate.format('YYYY-MM-DD');
                if (vm.dateRangeModel.endDate)
                    input.endDate = vm.dateRangeModel.endDate.format('YYYY-MM-DD');

                return input;
            }

            init();
        }
    ]);
})();
