/* global zE */

(() => {
  angular
    .module('app')
    .controller('common.views.specialistClaims.details', SpecialistClaimDetailsController);

  SpecialistClaimDetailsController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$interval',
    '$filter',
    '$uibModal',
    'abp.services.app.specialistClaim',
    'moment',
  ];

  function SpecialistClaimDetailsController(
    $scope,
    $state,
    $stateParams,
    $interval,
    $filter,
    $uibModal,
    specialistClaimSvc,
    moment
  ) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.claimNumber = $stateParams.claimNumber;
    vm.coPayLabel = App.localize('Copay');
    vm.resubmitFullClaim = 0;
    vm.uploadFormAbOnly = 0;
    vm.showTopUpPendingApprovalView = null;
    vm.showCopayPercentagePendingApprovalView = null;
    vm.topUpRequestMessage = '';
    vm.guaranteedAmount = 0;
    vm.uncoveredAmount = 0;
    vm.exceededAmount = 0;
    vm.collectCash = 0;
    vm.newRepliedQueriesCount = 0;
    vm.isClinic = App.isClinic();
    vm.hasInsuranceEnhancement = abp.setting.getBoolean('Hms.Feature.InsuranceEnhancementModule');

    const topUpRequestLocalization = App.localize('TopUpRequest');
    const ticketAdjustmentLocalization = App.localize('ClaimAdjustment');

    vm.claim = null;
    vm.isHost = App.isHost();
    vm.activeTab = $stateParams.activeTab;
    vm.timeZoneId = $stateParams.timeZoneId;

    vm.permissions = {
      editClaim: vm.isHost
        ? abp.auth.isGranted('SpecialistClaims.Host.Edit')
        : abp.auth.isGranted('SpecialistClaims.Clinic.Edit'),
      approveClaim: abp.auth.isGranted('SpecialistClaims.Host.Edit'),
      viewTicket: abp.auth.isGranted('PatientTickets'),
    };

    vm.hasSpecialistWorkflowEnhancement = abp.setting.getBoolean(
      'Hms.Feature.SpecialistWorkflowEnhancement'
    );
    vm.hasSpecialistEnhancement2_1 = abp.setting.getBoolean('Hms.Feature.SpecialistEnhancement2_1');

    let isSilentLoading = 0;

    vm.getExceededAmount = getExceededAmount;
    vm.processClaim = processClaim;
    vm.printLetter = printLetter;
    vm.viewTicket = viewTicket;
    vm.getBillingDetailHistoryHeading = getBillingDetailHistoryHeading;
    vm.cancelRequest = cancelRequest;
    vm.getDateTimeString = getDateTimeString;
    vm.getFullDateTimeString = getFullDateTimeString;

    init();

    function init() {
      getFinalRequestDetails();
      getTimeline();

      if ($stateParams.showZen && typeof zE !== 'undefined') {
        zE('webWidget', 'open');
      }

      let tickerInterval = $interval(silentLoad, 10000);
      $scope.$on('$destroy', () => {
        if (angular.isDefined(tickerInterval)) {
          $interval.cancel(tickerInterval);
          tickerInterval = undefined;
        }
      });
    }

    function silentLoad() {
      if (!vm.claim || vm.claim.status === 3 || !vm.claimNumber || vm.loading || isSilentLoading)
        return;

      isSilentLoading = 1;
      specialistClaimSvc
        .getFinalRequestDetails(
          {
            id: vm.claimNumber,
          },
          {
            abpHandleError: false,
          }
        )
        .success((data) => {
          processGetClaimDetailOutput(data);
        })
        .finally(() => {
          isSilentLoading = 0;
        });
    }

    function processGetClaimDetailOutput(data) {
      vm.claim = data;
      vm.claim.ianaTimeZoneId = vm.timeZoneId;
      vm.billAmount = vm.claim.billAmount;

      // TO DO: To remove after specialist enhancement 2.1 feature flag is turned on.

      let label = '';
      if (!vm.hasSpecialistEnhancement2_1 && vm.claim.guaranteeLetter.coPayValue) {
        if (vm.claim.guaranteeLetter.isCoPayPercentage) {
          label = `${vm.claim.guaranteeLetter.coPayValue}%`;
        } else {
          label = $filter('currencyFormat')(vm.claim.guaranteeLetter.coPayValue, vm.currencyCode);
        }
        vm.coPayLabel = App.localize('CopayX', label);
      }

      if (
        vm.hasSpecialistEnhancement2_1 &&
        vm.claim.guaranteeLetter.coPayValue &&
        vm.claim.guaranteeLetter.isCoPayPercentage
      ) {
        label = `${vm.claim.guaranteeLetter.coPayValue}%`;
        vm.coPayLabel = App.localize('CopayX', label);
      }

      vm.uploadFormAbOnly =
        !vm.isHost &&
        vm.permissions.editClaim &&
        vm.claim.status === 5 &&
        vm.claim.hasPatientTicket;

      vm.showTopUpPendingApprovalView =
        !vm.isHost &&
        !vm.claim.hasPatientTicket &&
        vm.claim.guaranteeLetter.hasTopUpRequest &&
        vm.claim.status === 1 &&
        vm.claim.requestType === 5 &&
        !vm.claim.hasReviewed &&
        vm.claim.latestQueryStatus !== 1;

      vm.showCopayPercentagePendingApprovalView =
        !vm.isHost &&
        !vm.claim.hasPatientTicket &&
        !vm.claim.guaranteeLetter.hasTopUpRequest &&
        vm.claim.guaranteeLetter.isCoPayPercentage &&
        vm.claim.status === 1 &&
        (vm.claim.requestType === 5 || vm.claim.requestType === 11) &&
        !vm.claim.hasReviewed &&
        vm.claim.latestQueryStatus !== 1;

      if (vm.claim.guaranteeLetter.hasTopUpRequest) {
        if (
          vm.claim.guaranteeLetter.topUpRequestStatus === 0 ||
          vm.claim.guaranteeLetter.topUpRequestStatus === 2
        ) {
          vm.topUpRequestMessage = vm.claim.guaranteeLetter.topUpRejectionRemark;
        } else if (vm.claim.guaranteeLetter.topUpRequestStatus === 1) {
          vm.topUpRequestMessage = App.localize('Success');
        } else {
          vm.topUpRequestMessage = App.localize('PendingApproval');
        }
      }

      if (vm.isHost && vm.claim.scheduledApprovalDate) {
        const status =
          vm.claim.scheduledApprovalStatus === 5
            ? App.localize('PartialApproval')
            : App.localize('FullApproval');
        vm.scheduledApprovalTooltip = App.localize(
          'ScheduledApproval_Tooltip',
          status.toLowerCase(),
          moment(vm.claim.scheduledApprovalDate).format('L')
        );
      }
      // To display covered, uncovered, exceed and collect cash amount (after approval).

      if (vm.claim.status === 2 || vm.claim.status === 5) {
        vm.guaranteedAmount = vm.claim.coveredAmount;
        vm.uncoveredAmount = vm.claim.uncoveredAmount;

        // Total patient ticket item covered amount.

        const totalCoveredAmount = vm.billAmount - vm.uncoveredAmount;
        const coveredAmount = vm.claim.coPayAmount
          ? totalCoveredAmount - vm.claim.coPayAmount
          : totalCoveredAmount;

        if (coveredAmount > vm.guaranteedAmount)
          vm.exceededAmount = coveredAmount - vm.guaranteedAmount;

        vm.collectCash = Math.max(
          Math.max(vm.billAmount - vm.guaranteedAmount, 0),
          vm.claim.coPayAmount
        );
      }
    }

    function getFinalRequestDetails() {
      vm.loading += 1;
      specialistClaimSvc
        .getFinalRequestDetails(
          { id: vm.claimNumber }
        )
        .success((data) => {
          processGetClaimDetailOutput(data);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getTimeline() {
      if (!vm.claimNumber) return;

      vm.loading += 1;
      specialistClaimSvc
        .getFinalRequestTimeline({
          id: vm.claimNumber,
        })
        .success((data) => {
          vm.timelineItems = data.items;
          _.each(vm.timelineItems, (item) => {
            const d = item;
            switch (d.subject) {
              case App.localize('Submitted'):
                d.icon = 'fa fa-upload text-warning';
                break;
              case App.localize('PartialApproval'):
                d.icon = 'far fa-clipboard text-default';
                break;
              case App.localize('FullApproval'):
                d.icon = 'far fa-clipboard text-navy';
                break;
              case App.localize('UpdateClaim'):
                d.icon = 'fa fa-edit text-warning';
                break;
              case App.localize('PendingApproval_LateSubmission'):
                d.icon = 'far fa-calendar-exclamation text-danger';
                break;
              case App.localize('ScheduleApproval'):
                d.icon = 'far fa-clock text-warning';
                break;
              case App.localize('CancelScheduledApproval'):
                d.icon = 'far fa-clock text-default';
                break;
              case App.localize('Rejected'):
                d.icon = 'fa fa-times-circle text-danger';
                break;
              case App.localize('AdjustmentRequestSubmitted'):
                d.icon = 'fas fa-pencil text-warning';
                break;
              case App.localize('AdjustmentRequestApproved'):
                d.icon = 'fas fa-pencil text-navy';
                break;
              case App.localize('AdjustmentRequestRejected'):
              case App.localize('AdjustmentRequestCancelled'):
                d.icon = 'fas fa-pencil text-danger';
                break;
              case App.localize('MedicalQueryFormSent'):
              case App.localize('MedicalQueryFormReceived'):
                d.icon = 'fas fa-comment text-warning';
                break;
              case App.localize('MedicalQueryFormReplied'):
                d.icon = 'fas fa-comments text-warning';
                break;
              case App.localize('MedicalQueryFormResolved'):
                d.icon = 'fas fa-comment-check text-navy';
                break;

              // no default
            }

            // no default
          });
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getExceededAmount() {
      if (!vm.claim) return 0;
      const amt = vm.claim.billAmount || 0;
      const coveredAmt = amt - vm.claim.coPayAmount;
      return Math.max(coveredAmt - vm.claim.guaranteeLetter.guaranteedAmount, 0);
    }

    function processClaim() {
      if (vm.hasInsuranceEnhancement) {
        $state.go('processSpecialistClaimNew', {
          claimNumber: vm.claimNumber,
          timeZoneId: vm.timeZoneId
        });
      } else {
        $state.go('processSpecialistClaim', {
          claimNumber: vm.claimNumber,
          timeZoneId: vm.timeZoneId
        });
      }
    }

    function getBillingDetailHistoryHeading(billingDetail) {
      return `${
        billingDetail.isTicketAdjustment ? ticketAdjustmentLocalization : topUpRequestLocalization
      }: ${moment(billingDetail.creationTime).format('D MMMM YYYY, h:mm a')}`;
    }

    function printLetter(letterLink) {
      window.open(letterLink);
    }

    function cancelRequest() {
      $uibModal
        .open({
          templateUrl: require('../cancellation/cancellation.modal.html'),
          controller: 'common.views.specialistClaims.cancellationModal as vm',
          backdrop: 'static',
          size: 'lg',
        })
        .result.then((cancellationReason) => {
          if (cancellationReason) {
            const input = {
              claimNumber: vm.claimNumber,
              cancellationReason,
            };

            vm.loading += 1;

            specialistClaimSvc
              .cancelAdjustmentRequest(input)
              .success(() => {
                abp.notify.success('Cancelled');
              })
              .finally(() => {
                vm.loading -= 1;
                $state.reload();
              });
          }
        });
    }

    function viewTicket() {
      $state.go('patientTicketDetail', {
        ticketNumber: vm.claim.ticketNumber,
      });
    }

    function getDateTimeString(date, timeZoneId) {
      return App.getDateTimeString(date, timeZoneId);
    }

    function getFullDateTimeString(date, timeZoneId) {
      return App.getFullDateTimeString(date, timeZoneId);
    }
  }
})();
