(function () {
    var controllerId = 'corporate.views.employeeTicketsReport.index';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', '$uibModal', 'abp.services.app.report', 'abp.services.app.commonLookup', 'appSession', 'Hms.Services.ServiceType',
        'moment',
        function ($scope, $state, $stateParams, $modal, reportSvc, commonLookupSvc, appSession, enumServiceType, moment) {
            var vm = this;

            function init() {
                initServiceTypes();

                // TODO: Replace currency code after enabling cross-country.
                getTenantCurrencyCode();

                getEmployeeWithoutDataConsentCount();
                lookupTicketTypes();
                vm.lookupClinicLocations(null, $stateParams.clinicLocation);
                lookupBenefitGroups();
                lookupSubsidiaries();
                lookupCTs();
                findEmployeeFields();

                // HM-5328: This is to handle inpatient ticket which have multiple treatments
                // Currently, take the first treatment name till enhancement.
                if ($stateParams.treatment) vm.generate();
            }

            vm.dataExportRecordName = "EmployeeTicketsReport";
            vm.generateButtonType = App.localize('ExportToExcel');

            vm.isSubsidiary = appSession.user.subsidiaryId;
            vm.isSubsidiaryDepartment = appSession.user.subsidiaryDepartmentId;
            vm.financialYears = _.range(appSession.tenant.currentFinancialYear, appSession.tenant.firstFinancialYear - 1, -1);
            vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
            vm.isDataRender = false;
            vm.loading = 0;
            vm.enqueuing = 0;

            vm.hasSpecialistModule = vm.isHost || abp.features.isEnabled('Specialist.Enabled');
            vm.hasPharmacyModule = vm.isHost || abp.features.isEnabled('Pharmacy.Enabled');
            vm.hasInpatientModule = abp.features.isEnabled('HasInpatientModule');
            vm.applicationHasMemberBenefitUtilization = abp.setting.getBoolean('Hms.Feature.MemberBenefitUtilization');
            vm.hasInsuranceModule = abp.setting.getBoolean('Hms.Feature.InsuranceModule')
                && abp.features.isEnabled('HasInsurance');

            vm.enums = {};
            vm.enums.serviceType = enumServiceType;

            $scope.$on("$viewContentLoaded", function () {
                App.initAjax();
            });

            vm.ticketTypes = [{ name: App.localize('All'), value: null }];
            function lookupTicketTypes() {
                vm.loading++;
                commonLookupSvc.findPatientTicketTicketTypes().success(function (data) {
                    data.items = _.reject(data.items, function (d) { return d.value == 3; });

                    vm.ticketTypes = _.concat(vm.ticketTypes, data.items);
                }).finally(function () {
                    vm.loading--;
                });
            }

            vm.benefitGroups = [];
            function lookupBenefitGroups() {
                commonLookupSvc.findBenefitGroups({
                    maxResultCount: 999
                }).success(function (data) {
                    vm.benefitGroups = data.items;
                });
            }

            vm.subsidiaries = [];
            function lookupSubsidiaries() {
                commonLookupSvc.getCorporateSubsidiaries({ getDeletedSubsidiariesOrDepartment: true }).success(function (data) {
                    vm.subsidiaries = data.items;
                });
            }

            vm.departments = [];
            var allDepartments = [];
            vm.getCorporateSubsidiaryDepartments = function () {
                if (!allDepartments.length) {
                    commonLookupSvc.getCorporateSubsidiaryDepartments({ getDeletedSubsidiariesOrDepartment: true }).success(function (data) {
                        allDepartments = data.items;
                    });
                }
            };

            vm.getDepartments = function (search) {
                var subsidiaryIds = vm.isSubsidiary
                    ? appSession.user.subsidiaryId
                    : vm.requestParams.subsidiaryIds;

                if (subsidiaryIds.length > 0) {
                    vm.departments = [];

                    // Change department filter list according to selected subsidiaries.

                    _.forEach(subsidiaryIds, function (id) {
                        var d = _.filter(allDepartments, { 'subsidiaryId': id });
                        vm.departments = _.concat(vm.departments, d);
                    });
                } else {
                    vm.departments = allDepartments;
                }

                if (search) {
                    vm.departments = _.filter(vm.departments, function (d) {
                        return _.includes(d.name.toLowerCase(), search.toLowerCase())
                    });

                    // Only return top 10 in the department list for display.

                    vm.departments = _.slice(vm.departments, 0, 10);
                } else {
                    vm.departments = [];
                }
            };

            vm.resetDepartments = function () {
                vm.requestParams.departmentIds = [];

                vm.departments = [];
            };

            vm.clinicLocations = [];
            vm.lookupClinicLocations = function (search, id) {
                if (search || id) {
                    commonLookupSvc.findPatientTicketClinicLocations({
                        clinic: vm.requestParams.clinic,
                        id: id,
                        filter: search,
                        maxResultCount: 5
                    }).success(function (data) {
                        vm.clinicLocations = data.items;
                    }).finally(function () {
                        if (!vm.clinicLocations.length)
                            vm.requestParams.clinicLocation = null;
                    });
                }
            };

            vm.isClinic = App.isClinic();

            vm.cts = [];
            function lookupCTs() {
                reportSvc.findCTs().success(function (data) {
                    vm.cts = data.items;
                });
            }

            vm.serviceTypes = [];
            function initServiceTypes() {
                reportSvc.getServiceTypes().success(function (data) {
                    vm.serviceTypes = data;
                });
            }

            vm.employeeFields = [];
            function findEmployeeFields() {
                commonLookupSvc.findEmployeeFields().success(function (data) {
                    if (data.items) {
                        vm.employeeFields = data.items;
                    }
                });
            }

            vm.requestParams = {
                ticketType: $stateParams.ticketType,
                employeeType: $stateParams.employeeType,
                employee: $stateParams.employee,
                nationalId: $stateParams.nationalId,
                employeeNumber: $stateParams.employeeNumber,
                benefitGroupIds: $stateParams.benefitGroupIds,
                subsidiaryIds: $stateParams.subsidiaryIds || [],
                departmentIds: $stateParams.departmentIds || [],
                clinicLocation: $stateParams.clinicLocation,
                clinicType: $stateParams.treatmentType,
                scope: $stateParams.scope,
                financialYear: $stateParams.financialYear || appSession.tenant.currentFinancialYear,
                startDate: $stateParams.startDate,
                endDate: $stateParams.endDate,
                transactionNumber: $stateParams.transactionNumber,
                treatmentName: $stateParams.treatment ? $stateParams.treatment.split('|')[0].trim() : undefined,
                serviceType: $stateParams.serviceType
            };
            vm.advancedFiltersAreShown = $stateParams.nationalId || $stateParams.clinicLocation || $stateParams.clinicType
                || $stateParams.subsidiaryIds === [] || $stateParams.departmentIds === [] || $stateParams.benefitGroup || $stateParams.ticketType
                || $stateParams.transactionNumber || $stateParams.treatment;

            vm.dateRangeOptions = _.map(vm.financialYears, function (d) {
                var opt = App.createDateRangePickerOptions();
                var minDate = moment([d, appSession.tenant.firstFinancialMonth - 1, 1]);
                var maxDate = moment.min(moment([d + 1, appSession.tenant.firstFinancialMonth - 1, 1]).subtract(1, 'days'), moment(opt.maxDate));
                opt.minDate = moment(minDate);
                opt.min = moment(minDate);
                opt.maxDate = moment(maxDate);
                opt.max = moment(maxDate);
                opt.financialYear = d;
                return opt;
            });

            vm.dateRangeModel = {};

            vm.fyChanged = function () {
                var opt = _.find(vm.dateRangeOptions, { 'financialYear': vm.requestParams.financialYear });
                vm.dateRangeModel.startDate = moment(opt.minDate);
                vm.dateRangeModel.endDate = moment(opt.maxDate);
            };

            vm.fyChanged();

            vm.viewTickets = function (clinicLocation) {
                $state.go('patientTickets', {
                    clinicLocation: clinicLocation
                });
            };

            vm.generate = function () {
                registerStateParams();
                vm.loading++;
                reportSvc.getEmployeeTicketsReportEmployeeCount(getRequestInput()).success(function (data) {
                    data < 51 ? vm.getEmployeeTicketsReport() : vm.exportToExcel();
                    vm.isDataRender = data < 51;
                }).finally(function (e) {
                    vm.loading--;
                });
            };

            var reportTemplateName = '';
            var reportFields = [];
            vm.getEmployeeTicketsReport = function () {
                vm.loading++;
                reportSvc.getEmployeeTicketsReport(getRequestInput()).success(function (data) {
                    vm.data = data.items;
                    _.each(vm.data, function (d) {
                        var employeeFields = _.map(d.employeeFields, function (f) { return f.value; });
                        var fieldInfo = _.concat(d.subsidiaryName, d.subsidiaryDepartmentName, employeeFields, d.benefitGroupName);
                        d.fieldInfo = _.join(_.filter(fieldInfo, function (fi) { return fi; }), ' | ');
                    });
                    reportTemplateName = data.reportTemplateName;
                    reportFields = data.reportFields;
                }).finally(function (e) {
                    vm.loading--;
                });
            };

            function getEmployeeFieldFilters() {
                var employeeFieldFilters = [];
                _.each(vm.employeeFields, function (f) {
                    if (f.filter) {
                        var filter = _.trim(f.filter);
                        if (filter) {
                            employeeFieldFilters.push({
                                name: filter,
                                value: f.value
                            });
                        }
                    }
                });
                return { employeeFields: employeeFieldFilters };
            }

            function getRequestInput(selectedFields) {
                var input = $.extend({}, vm.requestParams, getEmployeeFieldFilters(), { reportFields: selectedFields });
                input.startDate = vm.dateRangeModel.startDate.format('YYYY-MM-DD');
                input.endDate = vm.dateRangeModel.endDate.format('YYYY-MM-DD');
                return _.omitBy(input, _.isNil);
            }

            function registerStateParams() {
                var startDate = null;
                var endDate = null;
                if (vm.dateRangeModel.startDate)
                    startDate = vm.dateRangeModel.startDate.format('YYYY-MM-DD');
                if (vm.dateRangeModel.endDate)
                    endDate = vm.dateRangeModel.endDate.format('YYYY-MM-DD');

                $state.transitionTo($state.current, {
                    employee: vm.requestParams.employee,
                    ticketType: vm.requestParams.ticketType,
                    employeeType: vm.requestParams.employeeType,
                    nationalId: vm.requestParams.nationalId,
                    departmentIds: vm.requestParams.departmentIds,
                    clinicLocation: vm.requestParams.clinicLocation,
                    treatmentType: vm.requestParams.clinicType,
                    benefitGroupIds: vm.requestParams.benefitGroupIds,
                    employeeNumber: vm.requestParams.employeeNumber,
                    subsidiaryIds: vm.requestParams.subsidiaryIds,
                    financialYear: vm.requestParams.financialYear,
                    scope: vm.requestParams.scope,
                    startDate: startDate,
                    endDate: endDate,
                    transactionNumber: vm.requestParams.transactionNumber,
                    treatmentName: vm.requestParams.treatmentName,
                    serviceType: vm.requestParams.serviceType
                }, {
                    notify: false
                });
            }

            vm.exportToExcel = function (option) {
                option = option || getRequestInput();

                vm.enqueuing++;
                reportSvc.enqueueGenerateEmployeeTicketsExcelReportJob(option).success(function (data) {
                    vm.refresh();
                }).finally(function () {
                    vm.enqueuing--;
                });
            };

            vm.export = function (type) {
                $modal.open({
                    templateUrl: require('/App/common/views/common/selectionModal.html'),
                    controller: "common.views.common.selectionModal as vm",
                    backdrop: "static",
                    resolve: {
                        templateName: function () {
                            return reportTemplateName;
                        },
                        fields: function () {
                            return reportFields;
                        },
                        modalTitle: function () {
                            return App.localize('SelectReportFields');
                        }
                    }
                }).result.then(function (selectedFields) {
                    var option = getRequestInput(selectedFields);

                    if (type === 0)
                        window.open(App.formatUrl('Print/EmployeeTicketsReport?', option));
                    else
                        vm.exportToExcel(option);
                });
            };

            /* Functions */

            function getEmployeeWithoutDataConsentCount() {
                reportSvc.getEmployeeWithoutDataConsentCount()
                    .success(function (data) {
                        vm.hasExcludedEmployee = data > 0;
                    });
            }

            function getTenantCurrencyCode() {
                vm.loading++;
                commonLookupSvc.getTenantCurrencyCode()
                    .success(function (data) {
                        vm.currencyCode = data;
                    }).finally(function () {
                        vm.loading--;
                    })
            }

            /* End of Functions */

            init();
        }
    ]);
})();
