(function () {
    var controllerId = 'corporate.views.policyHolder.viewInsuranceProfileModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$state', '$uibModalInstance', 'abp.services.app.employee', 'employeeId',
        function ($scope, $state, $uibModalInstance, employeeSvc, employeeId) {
            function init() {
                vm.loading = true;

                employeeSvc.getEmployeeProfile({
                    id: employeeId
                }).success(function (data) {
                    vm.profile = data;
                    vm.profile.isPreEmployee = vm.profile.employeeType === 2;
                    if (data) {
                        data.inpatientBenefits = [];
                        if (!data.isDeleted && data.pools && data.pools.length) {
                            _.each(data.pools, function (d) {
                                var title = _.join(_.map(d.benefitTypes, function (bt) {
                                    if (bt.isCustom) {
                                        var strCt = 'CT' + bt.id;
                                        var idx = _.findIndex(data.ctNames, function (ctn) { return ctn.value === bt.id; });
                                        if (idx !== -1) strCt = data.ctNames[idx].name;
                                        return strCt;
                                    }
                                    else if (bt.id === 251) {
                                        return App.localize('Hospitalization');
                                    }
                                    else {
                                        return App.localize(bt.name);
                                    }
                                }), '/');

                                // TODO: Better design? localisation required

                                var isBudget = d.modifierType === 0 || d.modifierType === 2;
                                var cycle = '';
                                if (!d.isUnlimited) {
                                    switch (d.modifierCycle) {
                                        case 0: cycle = 'every ' + d.interval + ' years '; break;
                                        case 1: cycle = 'annual '; break;
                                        case 2: cycle = 'monthly '; break;
                                        case 3: cycle = 'daily '; break;
                                        case 4: cycle = 'per visit '; break;
                                    }
                                    if (d.modifierType === 2 || d.modifierType === 3) cycle += 'overdraft ';
                                }
                                if (d.serviceType === 8)
                                    title += ' (' + cycle + (isBudget ? 'per employment budget' : 'visit per employment') + ')';
                                else
                                    title += ' (' + cycle + (isBudget ? 'budget' : 'visit') + ')';

                                var isEmptyExcess = d.isExcess && !d.employeePortion;
                                if (!isEmptyExcess) {
                                    _.each(d.benefitTypes, function (bt) {
                                        if (bt.id === 7 || bt.id == 251) {
                                            data.inpatientBenefits.push({
                                                title: title,
                                                isUnlimited: d.isUnlimited,
                                                isExcess: d.isExcess,
                                                isBudget: isBudget,
                                                limit: d.limit,
                                                used: d.used,
                                                locked: d.locked,
                                                balance: d.balance
                                            });
                                        }
                                    })
                                }
                            });
                        }
                    }
                }).finally(function () {
                    vm.loading = false;
                });
            }

            var vm = this;
            vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
            vm.loading = false;
            vm.profile = null;
            vm.hasSpecialistModule = false; //abp.features.isEnabled('Specialist.Enabled'), // disabled by HM-550

            vm.close = function () {
                $uibModalInstance.dismiss();
            };

            init();
        }
    ]);
})();
