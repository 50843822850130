(function () {
    var controllerId = 'clinic.views.hospital.query';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$stateParams', '$state', 'abp.services.app.serviceRequestQuery',
        function ($scope, $stateParams, $state, serviceRequestQuerySvc) {
            var vm = this;
            vm.loading = 0;
            vm.requestParams = { 
                letterNumber: $stateParams.letterNumber, 
                queryNumber: $stateParams.queryNumber,
                timeZoneId: $stateParams.timeZoneId };
            vm.saving = 0;
            vm.checklistItems = [];

            // Attachment.

            var fileExtensions = '|' + app.consts.fileUpload.imageExtensions.concat(app.consts.fileUpload.pdfExtensions).join('|') + '|';

            vm.uploadConfig = {
                objectType: 'InpatientQueryAttachment',
                itemsLimit: 5,
                accept: 'image/*,application/pdf',
                filters: [{
                    name: 'fileFilter',
                    fn: function (n) {
                        var t = '|' + n.type.slice(n.type.lastIndexOf('/') + 1) + '|';
                        if (fileExtensions.indexOf(t) === -1) {
                            abp.message.warn(App.localize('File_Warn_FileType'));
                            return false;
                        }
                        return true;
                    }
                }, {
                    name: 'sizeFilter',
                    fn: function (n) {
                        var sizeLimit = 52428800;
                        if (n.size > sizeLimit) {
                            abp.message.warn(App.localize('File_Warn_XSizeLimit', sizeLimit / 1024 / 1024));
                            return false;
                        }
                        return true;
                    }
                }]
            };

            // End attachment.

            vm.save = save;
            vm.getDateTimeString = getDateTimeString;

            init();

            // Functions.

            function init() {
                getQuery();
            }

            function getQuery() {
                vm.loading++;
                serviceRequestQuerySvc.getQuery({ id: vm.requestParams.queryNumber })
                    .success(function (data) {
                        vm.query = data;
                        vm.query.newRepliedAttachments = [];
                        AddChecklistItems(vm.query.serviceRequestQueryItems);
                    }).finally(function () {
                        vm.loading--;
                    });
            }

            function AddChecklistItems(items) {
                if (_.some(items, function (item) { return item.type === 1 })) {
                    vm.checklistItems.push({ text: App.localize('CompletedMedicalQueryForm'), required: true });
                }
                _.forEach(items, function (item) {
                    if (item.type === 2) {
                        _.forEach(item.questions, function (question) {
                            vm.checklistItems.push({ text: question.documentName, required: true });
                        });
                    }
                });
            }

            function save() {
                if (!validateForm()) {
                    return;
                }

                vm.saving++;
                serviceRequestQuerySvc.replyQuery({ id: vm.query.id, newRepliedAttachments: vm.query.newRepliedAttachments })
                    .success(function () {
                        abp.notify.success(App.localize('SuccessfullySaved'));
                        $state.go('clinic.patientAdmissionDetails', { 'letterNumber': $stateParams.letterNumber });
                    }).finally(function () {
                        vm.saving--;
                    });
            }

            function validateForm() {
                vm.invalidAttachment = vm.query.newRepliedAttachments.length === 0;
                vm.invalidChecklist = !App.isFormValid($scope.forms.uploadDocuments);
                return !(vm.invalidAttachment || vm.invalidChecklist);
            }

            function getDateTimeString(date, timeZoneId) {
                return App.getDateTimeString(date, timeZoneId);
            }

            // End functions.
        }
    ]);
})();
