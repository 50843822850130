(function () {
    angular.module('app').component('hostDoraemonCommonInpatientBalancePoolTableComponent', {
        templateUrl: require('/App/host/views/doraemon/common/inpatientBalancePoolTable/inpatientBalancePoolTable.component.html'),
        controller: controller,
        controllerAs: 'vm',
        bindings: {
            benefits: '<'
        }
    });

    function controller() {
        var vm = this;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    }
})();
