(() => {
  angular
    .module('app')
    .filter('selectedTreatmentCount', selectedTreatmentCount)
    // For backward compatibility only. Filter name don't need "Filter" suffix.
    // AngularJS automatically appends "Filter" to name for DI.
    .filter('selectedTreatmentCountFilter', selectedTreatmentCount);

  selectedTreatmentCount.$inject = [];

  function selectedTreatmentCount() {
    return selectedTreatmentCountFilter;

    function selectedTreatmentCountFilter(value) {
      return value ? App.localize('XTreatmentsSelected', value) : '';
    }
  }
})();
