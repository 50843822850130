(() => {
    angular.module('app').component('commonPostHospitalizationGuaranteeLettersCommonAsoInsuranceDetailsComponent', {
      templateUrl: require('./asoInsuranceDetails.component.html'),
      controller: AsoInsuranceController,
      controllerAs: 'vm',
      bindings: {
        organization: '<',
        patientId: '<',
      }
    });
    AsoInsuranceController.$inject = [
      '$stateParams',
      'abp.services.app.masterPolicy',
    ];
    function AsoInsuranceController(
      $stateParams,
      masterPolicySvc
    ) {
      
      var vm = this;
      vm.timeZoneId = $stateParams.timeZoneId;
      init();
      function init() {
        getMasterPolicyDetail();
      }

      function getMasterPolicyDetail() {
        vm.loading += 1;
        masterPolicySvc.getMasterPolicyDetailByPatientId({
          id: vm.patientId
        }).success((data) => {
          vm.isInsuranceBenefitGroup = data != null;
          if (!data) return;  
          vm.masterPolicy = data.masterPolicy;
        }).finally(() => {
          vm.loading -= 1;
        });
      }
    }
})();