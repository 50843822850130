(function () {
    'use strict';

    angular
        .module('app')
        .controller('clinic.views.hospital.inpatientClaims.details',
            ['$stateParams', '$uibModal', '$scope', '$timeout', '$state', 'abp.services.app.inpatientClaim', 'Hms.ServiceRequests.ServiceRequestStatus', 'Hms.Partners.PartnerType', controller]);

    function controller($stateParams, $uibModal, $scope, $timeout, $state, inpatientClaimSvc, enumServiceRequestStatus, enumPartnerType) {
        var vm = this;
        vm.loading = 0;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.claimNumber = $stateParams.claimNumber;
        vm.timeZoneId = $stateParams.timeZoneId;

        vm.enums = {};
        vm.enums.serviceRequestStatus = enumServiceRequestStatus;
        vm.enums.partnerType = enumPartnerType;

        function init() {
            getClaim();
        }

        function getClaim() {
            vm.loading++;
            inpatientClaimSvc.getInpatientClaimDetailAsync({ id: $stateParams.claimNumber })
                .success(function (data) {
                    vm.claim = {
                        lastModificationTime: data.lastModificationDate,
                        creationTime: data.claimDate,
                        patientName: data.patientName,
                        patientNationalId: data.nationalId,
                        companyName: data.memberSubsidiaryName,
                        panelLocationName: data.panelLocationName,
                        doctors: data.doctors,
                        guaranteeLetterNumber: data.guaranteeLetterNumber,
                        hospitalBillReference: data.hospitalBillReferenceNumber,
                        billAmount: data.totalClaimAmount,
                        guaranteedAmount: data.currentGuaranteeAmount,
                        exceededAmount: data.exceededAmount,
                        attachments: data.attachments,
                        claimNumber: data.claimNumber,
                        status: data.claimStatusId,
                        remarks: data.claimRemarks,
                        employeeId: data.memberId,

                        patientActivationStatus: data.patientActivationStatus,
                        patientDeletionStatus: data.patientDeletionStatus,

                        gender: data.gender,
                        dateOfBirth: data.dateOfBirth 
                            ? App.getDateString(moment.utc(data.dateOfBirth)) 
                            : null,
                        joinedDate: data.joinDate 
                            ? App.getDateString(moment.utc(data.joinDate)) 
                            : null,
                        deactivationDate: data.deactivationDate 
                            ? App.getDateString(moment.utc(data.deactivationDate)) 
                            : null,
                        submissionTime: App.getFullDateTimeString(data.claimDate, vm.timeZoneId),
                        lastUpdatedTime: App.getFullDateTimeString(data.lastModificationTime, vm.timeZoneId),

                        guaranteeLetterUrl: data.guaranteeLetterUrl,
                        guaranteeLetterStatus: data.guaranteeLetterStatus,
                        patientNonGlUrl: data.patientNonGlUrl,
                        hospitalNonGlUrl: data.hospitalNonGlUrl,
                        partnerType: data.partnerType,
                        hasNotPassAdjustmentDaysLimit: data.hasNotPassAdjustmentDaysLimit
                    };
                }).finally(function () {
                    vm.loading--;
                });
        }

        var interval = setInterval(function () {
            angular.element('#default').triggerHandler('click');
            stopInterval();
        }, 1000);

        function stopInterval() {
            clearInterval(interval);
        }

        vm.adjustClaim = function () {
            $state.go('clinic.adjustmentRequest', { 'employeeId': vm.claim.employeeId, 'letterNumber': vm.claim.guaranteeLetterNumber })
        };

        init();
    }
})();
