(() => {
  angular.module('app').directive('rateTree', rateTreeDirective);

  rateTreeDirective.$inject = [];

  function rateTreeDirective() {
    const directive = {
      link,
      restrict: 'E',
      template: '<div class="rate-tree"></div>',
      scope: {
        editData: '=',
      },
    };
    return directive;

    function link(scope, element) {
      const treeElement = $(element).find('.rate-tree');
      let isInit = false;
      const ccyCode = abp.setting.get('Hms.General.CurrencyCode');

      init();

      function init() {
        scope.$watch('editData', () => {
          if (scope.editData) {
            scope.editData.isValid = () =>
              treeElement.find('.rate-tree-textbox-invalid').length <= 0;
            activate();
          }
        });
      }

      function activate() {
        if (isInit) {
          treeElement.jstree('destroy');
        }
        isInit = true;

        function getNodeText(data) {
          if (data.preferredMaxRate == null) {
            return data.displayName;
          }
          return `${data.displayName} - <b>${ccyCode} ${data.preferredMaxRate}</b>`;
        }

        const treatments = _.map(scope.editData.treatments, (data) => ({
          id: data.id,
          parent: data.parentId ? data.parentId : '#',
          text: getNodeText(data),
          state: {
            opened: false,
          },
        }));

        treeElement
          .on('ready.jstree', () => {
            refresh();
          })
          .on('redraw.jstree', () => {
            refresh();
          })
          .on('after_open.jstree', () => {
            refresh();
          })
          .on('create_node.jstree', () => {
            refresh();
          })
          .jstree({
            core: {
              data: treatments,
            },
            types: {
              default: {
                icon: 'fa fa-folder tree-item-icon-color icon-lg',
              },
              file: {
                icon: 'fa fa-file tree-item-icon-color icon-lg',
              },
            },
            plugins: ['types'],
          });
      }

      function refresh() {
        treeElement.find('.jstree-node').each(() => {
          const node = $(this);
          const id = node.attr('id');
          const preferredMaxRate = getPreferredMaxRate(id);
          const value = getValue(id) || '';

          if (!node.find('.rate-tree-textbox').length && preferredMaxRate != null) {
            const rateInput = $(
              '<input class="rate-tree-textbox" type="number" disable-running-number="all" />'
            )
              .attr({
                min: 0,
                step: 0.01,
              })
              .val(value);

            rateInput.on('input propertychange paste', () => {
              if (checkValue(rateInput.val())) {
                applyValue(id, rateInput.val());
                rateInput.removeClass('rate-tree-textbox-invalid');
              } else {
                rateInput.addClass('rate-tree-textbox-invalid');
                scope.$root.safeApply();
              }
            });
            rateInput.appendTo(node);
          }
        });
      }

      function getTreatment(id) {
        const treatment = _.find(scope.editData.treatments, (n) => n.id === id);
        if (!treatment) {
          abp.log.warn(`Could not find treatment by id: ${id}`);
        }
        return treatment;
      }

      function getPreferredMaxRate(id) {
        const treatment = getTreatment(id);
        if (treatment) {
          const rate = _.find(scope.editData.treatments, (n) => n.id === id);
          return rate && rate.preferredMaxRate;
        }
        return null;
      }

      function getValue(id) {
        const treatment = getTreatment(id);
        if (treatment) {
          const rate = _.find(scope.editData.treatmentRates, (n) => n.key === id);
          return rate ? rate.value : '';
        }
        return '';
      }

      function checkValue(value) {
        if (!value.length) {
          return true;
        }
        const rate = parseFloat(value);
        return !Number.isNaN(rate) && rate >= 0;
      }

      function applyValue(id, value) {
        const r = _.find(scope.editData.treatmentRates, (n) => n.key === id);
        if (r) {
          scope.$root.safeApply(() => {
            r.value = value;
          });
        }
      }
    }
  }
})();
