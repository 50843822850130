import * as XLSX from 'xlsx';
import jspreadsheet from 'jspreadsheet-ce';

(() => {
  angular
    .module('app')
    .controller('corporate.views.policyHolder.import', PolicyHolderImportController);

  PolicyHolderImportController.$inject = [
    '$scope',
    '$uibModal',
    '$uibModalInstance',
    'FileUploader',
    'abp.services.app.policyHolder',
    '$rootScope',
  ];

  function PolicyHolderImportController(
    $scope,
    $modal,
    $uibModalInstance,
    FileUploader,
    policyHolderSvc,
    $root
  ) {
    const vm = this;

    vm.saving = 0;
    vm.columnDefinitionsShown = false;
    vm.exporting = 0;

    vm.changesWarning = `(${App.localize('ImportPolicyHolderWarningChangesNarrative')})`;

    vm.hasEmployeeBulkUpdateUiEnhancement = abp.setting.getBoolean(
      'Hms.Feature.EmployeeBulkUpdateUiEnhancement'
    );
    vm.isFirstOpen = true;
    vm.isSecondOpen = false;
    vm.isThirdOpen = false;
    vm.isOnLoadExcel = true;
    $scope.isWorkbookOpen = false;
    vm.frontendErrorLog = new Map();
    vm.backendErrorLog = new Map();
    vm.arrayErrorLog = null;
    vm.uploading = 0;

    const name = 1;
    const nationality = 2;
    const identificationType = 3;
    const identificationNumber = 4;
    const birthDate = 5;
    const type = 6;
    const subsidiary = 7;
    const employeeNumber = 8;
    const mainPrincipal = 9;
    const department = 10;
    const employeeStatus = 11;
    const ghsInsurancePlan = 12;
    const ghsInsurancePlanEffectiveDate = 13;
    const ghsDependantCoverage = 14;
    const ghsMasterPolicy = 15;

    vm.sheet = sheet;
    vm.validate = validate;

    vm.uploaderImport = new FileUploader({
      url: $root.resolveAppPath('PolicyHolder/ImportPolicyHolder'),
      queueLimit: 1,
      headers: App.getFileUploaderHeaders(),
      filters: [
        {
          name: 'excelFilter',
          fn(n) {
            const t = `|${n.type.slice(n.type.lastIndexOf('/') + 1)}|`;
            if (
              '|vnd.openxmlformats-officedocument.spreadsheetml.sheet|'.indexOf(t) === -1 &&
              !n.name.endsWith('.xlsx')
            ) {
              abp.message.warn(App.localize('Excel_Warn_FileType'));
              return false;
            }
            return true;
          },
        },
      ],
    });

    vm.uploaderValidate = new FileUploader({
      url: $root.resolveAppPath('PolicyHolder/ValidatePolicyHolder'),
      queueLimit: 1,
      headers: App.getFileUploaderHeaders(),
    });

    vm.uploaderImport.onCompleteItem = uploaderImportOnCompleteItem;
    vm.uploaderValidate.onCompleteItem = uploaderValidateOnCompleteItem;

    vm.save = save;
    vm.verify = verify;
    vm.cancel = cancel;
    vm.exportToExcel = exportToExcel;

    init();

    function init() {
      getDropdownValue();
    }

    function sheet(workbooks) {
      const policyHolderSheet = workbooks.Sheets[workbooks.SheetNames[1]];
      const data = XLSX.utils.sheet_to_json(policyHolderSheet, { raw: false });

      // Prevent multiple workbook to be rendered on the inportal excel.
      // Choosing another file will replace
      // previous (if any) rendered excel.

      if ($scope.isWorkbookOpen) {
        document.getElementById('spreadsheet').replaceChildren();
        vm.isOnLoadExcel = true;
        vm.frontendErrorLog = new Map();
        vm.backendErrorLog = new Map();
      }

      vm.jsSheet = jspreadsheet(document.getElementById('spreadsheet'), {
        data,
        search: true,
        pagination: 10,
        paginationOptions: [10, 20, 100],
        allowDeleteColumn: false,
        allowDeleteRow: true,
        allowInsertColumn: false,
        allowInsertRow: false,
        allowRenameColumn: false,
        allowComments: false,
        columnSorting: false,
        columns: [
          { type: 'hidden', name: '#', title: '#', width: 20 },
          { type: 'text', name: 'Name', title: 'Name', width: 120, readOnly: true },
          { type: 'text', name: 'Nationality', title: 'Nationality', width: 120, readOnly: true },
          {
            type: 'text',
            name: 'Identification type',
            title: 'Identification type',
            width: 80,
            readOnly: true,
          },
          {
            type: 'text',
            name: 'Identification number',
            title: 'Identification number',
            width: 120,
          },
          {
            type: 'calendar',
            name: 'Birth date',
            title: 'Date of Birth (yyyy-mm-dd)',
            options: { format: 'yyyy-MM-dd' },
            width: 120,
          },
          { type: 'text', name: 'Type', title: 'Type', width: 120},
          { type: 'text', name: 'Subsidiary', title: 'Subsidiary', width: 120, readOnly: true },
          {
            type: 'text',
            name: 'Employee number',
            title: 'Employee number',
            width: 120,
            readOnly: true,
          },
          {
            type: 'text',
            name: 'Main principal',
            title: 'Main principal',
            width: 120,
            readOnly: true,
          },
          { type: 'text', name: 'Department', title: 'Department', width: 120, readOnly: true },
          {
            type: 'text',
            name: 'Employee status',
            title: 'Employee status',
            width: 120,
            readOnly: true,
          },
          { type: 'text', name: 'GHS insurance plan', title: 'GHS insurance plan', width: 120 },
          {
            type: 'calendar',
            name: 'GHS Insurance Plan Effective Date (yyyy-MM-dd)',
            title: 'GHS Insurance Plan Effective Date (yyyy-MM-dd)',
            options: { format: 'yyyy-MM-dd' },
            width: 120,
          },
          {
            type: 'dropdown',
            name: 'GHS Dependant Coverage',
            title: 'GHS Dependant Coverage',
            width: 120,
            source: vm.category,
          },
          {
            type: 'text',
            name: 'GHS Master Policy',
            title: 'GHS Master Policy',
            width: 120,
            readOnly: true,
          },
        ],
        onload(instance, _cell, x, y) {
          $scope.$apply(() => {
            vm.uploading += 1;
          });
          vm.validate(instance, null, x, y, null);
        },
        onchange(instance, _cell, x, y) {
          $scope.$apply(() => {
            vm.uploading += 1;
          });
          vm.validate(instance, null, x, y, null);
        },
        ondeleterow() {
          $scope.$apply(() => {
            vm.uploading += 1;
          });
          updateErrorLog();
        },
      });
      $scope.isWorkbookOpen = true;
    }

    function uploaderImportOnCompleteItem(n, t) {
      if (vm.hasEmployeeBulkUpdateUiEnhancement) {
        vm.uploaderValidate.onCompleteItem(n, t);
        if (!t.success && t.error) {
          if (t.error.details) {
            vm.saving -= 1;
            abp.notify.error(App.localize('ImportPolicyHolderFailed'));
          }
        }
      } else if (!t.success && t.error) {
        vm.saving -= 1;

        if (t.error.details) {
          $modal.open({
            templateUrl: require('../../../../common/views/modals/importResult/importResult.modal.html'),
            controller: 'common.views.modals.importResult as vm',
            backdrop: 'static',
            resolve: {
              title() {
                return App.localize('ImportPolicyHolderListing');
              },
              errors() {
                return JSON.parse(t.error.details);
              },
            },
          });
          $uibModalInstance.dismiss();
        }
        if (t.error.message) {
          abp.message.error(t.error.message);
          $uibModalInstance.dismiss();
        }
      }

      if (t.success) {
        vm.saving -= 1;
        abp.notify.info(App.localize('ImportPolicyHolderListingSuccessful'));
        $uibModalInstance.close();
      }
    }

    function uploaderValidateOnCompleteItem(n, t) {
      vm.backendErrorLog = new Map();

      if (!t.success && t.error) {
        if (t.error.details) {
          abp.notify.error(App.localize('VerifyPolicyHolderFailed'));

          const backendValidatorError = JSON.parse(t.error.details);
          for (let i = 0; i < backendValidatorError.length; i += 1) {
            vm.backendErrorLog.set(`a${i}`, backendValidatorError[i]);
          }

          getErrors();
        }

        // To catch any missed out error. E.g db update error, etc.

        if (t.error.message && !t.error.details) {
          abp.message.error(t.error.message);
        }
      }

      if (t.success) {
        abp.notify.info(App.localize('VerifyPolicyHolderSucceed'));

        // Clear off errors from error logs.

        getErrors();
      }
    }

    function validate(_instance, _cell, _x, y) {
      // Configurable: Validation is done every 0.5sec.

      setTimeout(() => {
        const columns = vm.jsSheet.getHeaders(true);

        if (vm.isOnLoadExcel) {
          // Validate through the whole file on first load.

          const skip = [
            name,
            nationality,
            identificationType,
            birthDate,
            subsidiary,
            employeeNumber,
            mainPrincipal,
            department,
            employeeStatus,
            ghsInsurancePlan,
            ghsDependantCoverage,
            ghsMasterPolicy,
          ];
          let skipCounter = 0;
          for (let col = 1; col < columns.length; col += 1) {
            if (skip[skipCounter] === col) {
              skipCounter += 1;
            } else {
              const colData = vm.jsSheet.getColumnData(col);
              for (let row = 0; row < colData.length; row += 1) {
                validateCell(col, row);
              }
            }
          }
          vm.isOnLoadExcel = false;
        } else {
          // Only validate the row where the cell was changed to improve performance.

          for (let col = 1; col <= columns.length; col += 1) {
            validateCell(col, parseInt(y, 10));
          }
        }

        updateErrorLog();
      });
    }

    // #region In-Portal Excel Frontend Validation.
    function validateCell(col, row) {
      const colData = vm.jsSheet.getColumnData(col);
      let cellNumber = '';
      switch (col) {
        case name:
        case nationality:
        case identificationType:
          break;
        case identificationNumber:
          cellNumber = `E${row + 1}`;
          validateIdNumber(colData[row], row, cellNumber);
          break;
        case birthDate:
          cellNumber = `F${row + 1}`;
          validateDate(colData[row], row, cellNumber);
          break;
        case type:
          cellNumber = `G${row + 1}`;
          validateType(colData[row], cellNumber);
          break;
        case subsidiary:
        case employeeNumber:
        case mainPrincipal:
        case department:
        case employeeStatus:
        case ghsInsurancePlan:
          break;
        case ghsInsurancePlanEffectiveDate:
          cellNumber = `N${row + 1}`;
          validateDate(colData[row], row, cellNumber);
          break;
        case ghsDependantCoverage:
        case ghsMasterPolicy:
          break;
        // no default
      }
    }

    function validateIdNumber(idNumber, row, cellPos) {
      // Only need to check for whether there are data in the 3 other GHS column.

      const rowData = vm.jsSheet.getRowData(row);

      const checkGhsColumns = [
        rowData[ghsInsurancePlan],
        rowData[ghsInsurancePlanEffectiveDate],
        rowData[ghsDependantCoverage],
      ];
      if (idNumber === '') {
        let isRowEmpty = true;

        for (let i = 0; i < checkGhsColumns.length; i += 1) {
          if (checkGhsColumns[i] !== '') {
            isRowEmpty = false;
            errorMarker(false, cellPos, App.localize('InportalExcelIdNumberMissingError'));
            break;
          }
        }

        if (isRowEmpty) {
          errorMarker(true, cellPos, '');
        }
      } else {
        errorMarker(true, cellPos, '');
      }

      // check if there is duplicated id
      const colData = vm.jsSheet.getColumnData(identificationNumber);
      const uniqueValues = [];
      
      for (let i = 0; i < colData.length; i += 1) {
        
        if (Object.prototype.hasOwnProperty.call(uniqueValues, (colData[i]))) {
          // return the index of the second occurrence
          const duplicate = `E${i + 1}`;
          errorMarker(false, duplicate, App.localize('InportalExcelDuplicateIdNumber'));
        } else {
          // add the current element to the object
          uniqueValues[colData[i]] = true;
          errorMarker(true, cellPos, '');
        }
      }
    }

    function validateDate(date, row, cellPos) {
      const ghsInsurancePlanColData = vm.jsSheet.getColumnData(ghsInsurancePlan);

      if (ghsInsurancePlanColData[row] !== '') {
        if (Number.isNaN(Date.parse(date))) {
          errorMarker(false, cellPos, App.localize('InportalExcelDateError'));
        } else {
          errorMarker(true, cellPos, '');
        }
      } else {
        errorMarker(true, cellPos, '');
      }
    }

    function validateType(cellVal, cellPos) {
      // Check to only accept record for principal (employee)
      if (cellVal !== 'Employee') {
        errorMarker(false, cellPos, App.localize('InportalExcelPolicyHolderTypeError'));
      } else {
        errorMarker(true, cellPos, '');
      }
    }

    function errorMarker(isValid, cellNumber, errorMesg) {
      if (isValid) {
        vm.jsSheet.setComments(cellNumber, '');
      } else {
        vm.jsSheet.setComments(cellNumber, errorMesg);
      }
    }

    vm.showMoreError = showMoreError;
    function showMoreError() {
      $modal.open({
        templateUrl: require('../../../../common/views/modals/importResult/importResult.modal.html'),
        controller: 'common.views.modals.importResult as vm',
        windowClass: 'double-modal',
        backdrop: 'static',
        resolve: {
          title() {
            return App.localize('ImportPolicyHolderErrors');
          },
          errors() {
            const error = new Array(undefined);
            for (let i = 0; i < vm.arrayErrorLog.length; i += 1) {
              error.push(vm.arrayErrorLog[i][1]);
            }
            return error;
          },
        },
      });

      const modal = angular.element(document.getElementsByClassName('modal-backdrop'));
      modal.addClass('double-modal-backdrop');
    }

    function updateErrorLog() {
      // Recreate a new frontend error log after either changing values, delete and/or insert row.

      vm.frontendErrorLog = new Map();
      const columns = vm.jsSheet.getHeaders(true);

      const data = vm.jsSheet.getData();
      for (let row = 1; row <= data.length; row += 1) {
        for (let col = 1; col < columns.length; col += 1) {
          const cellNumber = convertNumberToLetter(col) + row;
          if (vm.jsSheet.getComments(cellNumber) !== '') {
            // set clearer error message for dependant upload & duplicated id
            if (vm.jsSheet.getComments(cellNumber) === App.localize('InportalExcelPolicyHolderTypeError')){
              vm.frontendErrorLog.set(row, `Policy holder listing only accept principal in row ${row}.`);
            }
            else if (vm.jsSheet.getComments(cellNumber) === App.localize('InportalExcelDuplicateIdNumber')){
              const idNum = vm.jsSheet.getRowData(row)[col];
              vm.frontendErrorLog.set(row, `Identification Number ${idNum} is duplicated in row ${row}`);
            }
            else {
              vm.frontendErrorLog.set(row, `Error in row ${row}.`);
            }
            break;
          }
        }
      }

      getErrors();
    }

    function convertNumberToLetter(num) {
      const ordA = 'A'.charCodeAt(0);
      const ordZ = 'Z'.charCodeAt(0);
      const len = ordZ - ordA + 1;

      let s = '';
      let val = num;
      while (val >= 0) {
        s = String.fromCharCode((val % len) + ordA) + s;
        val = Math.floor(val / len) - 1;
      }
      return s;
    }

    function getErrors() {
      const backendErrorLogs = Array.from(vm.backendErrorLog);
      const frontendErrorLogs = Array.from(vm.frontendErrorLog);
      vm.arrayErrorLog = backendErrorLogs.concat(frontendErrorLogs);
      vm.uploading -= 1;
    }

    function save() {
      vm.saving += 1;
      if (vm.hasEmployeeBulkUpdateUiEnhancement) {
        vm.uploading += 1;
        if (vm.arrayErrorLog.length === 0) {
          const file = createNewFile();
          vm.uploaderImport.clearQueue();
          vm.uploaderImport.addToQueue(file);
          vm.uploaderImport.uploadAll();
        } else {
          abp.notify.error(App.localize('OccurringInportalExcelErrorMessage'));
          vm.uploading -= 1;
          vm.saving -= 1;
        }
      } else {
        vm.uploaderImport.uploadAll();
      }
    }

    function verify() {
      vm.uploading += 1;
      const frontendErrorLogs = Array.from(vm.frontendErrorLog);
      if (frontendErrorLogs.length === 0) {
        const file = createNewFile();
        vm.uploaderValidate.clearQueue();
        vm.uploaderValidate.addToQueue(file);
        vm.uploaderValidate.uploadAll();
      } else {
        abp.notify.error(App.localize('OccurringInportalExcelErrorMessage'));
        vm.uploading -= 1;
      }
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function createNewFile() {
      const data = vm.jsSheet.getData();
      const headers = vm.jsSheet.getHeaders(true);
      const newData = [headers].concat(data);
      const wb = XLSX.utils.book_new();
      wb.SheetNames.push('ColumnDefinition');
      wb.SheetNames.push('PolicyHolder');
      wb.Sheets.PolicyHolder = XLSX.utils.aoa_to_sheet(newData);

      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

      function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);

        // Intentional use of bitwise AND to generate array buffer.
        // eslint-disable-next-line no-bitwise
        for (let i = 0; i < s.length; i += 1) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }

      const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
      return new File([blob], 'policyHolderImport.xlsx', {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
    }

    function exportToExcel() {
      vm.exporting += 1;
      const input = {
        isImport: true,
      };
      policyHolderSvc
        .getPolicyHoldersToExcel(input)
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.exporting -= 1;
        });
    }

    function getDropdownValue() {
      vm.loading += 1;
      policyHolderSvc
        .getPolicyHolderUploadDropdownValues()
        .success((data) => {
          vm.category = data.category;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
