(() => {
  angular
    .module('app')
    .controller(
      'host.views.specialistTreatmentCategories.importModal',
      SpecialistTreatmentCategoryImportModalController
    );

  SpecialistTreatmentCategoryImportModalController.$inject = [
    '$scope',
    '$uibModal',
    '$uibModalInstance',
    'FileUploader',
    '$interval',
    'abp.services.app.specialistTreatmentCategory',
    '$rootScope',
  ];

  function SpecialistTreatmentCategoryImportModalController(
    $scope,
    $modal,
    $uibModalInstance,
    FileUploader,
    $interval,
    specialistTreatmentCategorySvc,
    $root
  ) {
    const vm = this;
    vm.exporting = 0;
    vm.savingProgress = 0;

    vm.cancel = cancel;
    vm.confirm = confirm;
    vm.save = save;
    vm.exportTemplateToExcel = exportTemplateToExcel;

    init();

    function init() {
      vm.uploader = new FileUploader({
        url: $root.resolveAppPath('SpecialistTreatmentCategory/ImportSpecialistTreatmentCategories'),
        queueLimit: 1,
        headers: App.getFileUploaderHeaders(),
        filters: [
          {
            name: 'excelFilter',
            fn(n) {
              const t = `|${n.type.slice(n.type.lastIndexOf('/') + 1)}|`;
              if (
                '|vnd.openxmlformats-officedocument.spreadsheetml.sheet|'.indexOf(t) === -1 &&
                !n.name.endsWith('.xlsx')
              ) {
                abp.message.warn(App.localize('Excel_Warn_FileType'));
                return false;
              }
              return true;
            },
          },
        ],
      });

      vm.uploader.onCompleteItem = (n, t) => {
        vm.saving = false;
        $uibModalInstance.close();

        if (!t.success && t.error) {
          if (t.error.details) {
            $modal.open({
                templateUrl: require('./importResult.modal.html'),
                controller: 'host.views.specialistTreatmentCategories.importResultModal as vm',
                backdrop: 'static',
                resolve: {
                    errors () {
                        return JSON.parse(t.error.details);
                    }
                }
            });
        }
        }

        if (t.success) {
          abp.message.success(t.result, App.localize('ImportSpecialistTreatmentCategoriesSuccessful'));
        }
      };

      let tickerInterval = $interval(tickerJob, 1000);
      $scope.$on('$destroy', () => {
        if (angular.isDefined(tickerInterval)) {
          $interval.cancel(tickerInterval);
          tickerInterval = undefined;
        }
      });
    }

    function tickerJob() {
      vm.savingProgress = (vm.savingProgress + 1) % 100;
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function confirm() {
      $uibModalInstance.close();
    }

    function save() {
      vm.invalidUpload = !vm.uploader.queue.length;
      if (vm.invalidUpload) {
        return;
      }
      vm.savingProgress = 0;
      vm.saving = true;
      vm.uploader.uploadAll();
    }

    function exportTemplateToExcel() {
      vm.exporting += 1;
      specialistTreatmentCategorySvc
        .getSpecialistTreatmentCategoriesExcelTemplate()
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.exporting -= 1;
        });
    }
  }
})();
