(function () {
    var controllerId = 'clinic.views.hospital.homepage.homepage.page';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$state', 'appSession', '$document',
        function ($scope, $state, appSession, $document) {
            function init() {
                if (appSession.tenant) {
                    vm.tenantCode = appSession.tenant.tenancyName;
                }

                vm.tenantName = getTenantName();
            }

            var vm = this;
            vm.userFullName = appSession.user ? appSession.user.name : '';
            vm.permissions = {
                saleTransactions: abp.auth.isGranted("SaleTransactions"),
                doctor: abp.auth.isGranted("Clinic.Doctors"),
                roles: abp.auth.isGranted("Administration.Roles"),
                users: abp.auth.isGranted("Administration.Users"),
                auditLogs: abp.auth.isGranted("Administration.AuditLogs"),
                settings: abp.auth.isGranted("Administration.Tenant.Settings"),
                healthScreeningDashboard: abp.auth.isGranted("Dashboard.HealthScreening.Panel")
            };
            vm.permissions.administration = vm.permissions.roles || vm.permissions.users
                || vm.permissions.auditLogs || vm.permissions.settings;
            vm.permissions.general = vm.permissions.saleTransactions || vm.permissions.doctor;

            vm.hasOutpatientSpecialist = App.isHospital() && App.isSpecialist();
            vm.hasInpatient = App.isInpatient();
            vm.hasHealthScreeningModule = App.isHealthScreening();
            vm.canAccessHealthScreeningDashboard = vm.hasHealthScreeningModule && vm.permissions.healthScreeningDashboard;
            vm.hasFinanceService = abp.setting.getBoolean('Hms.Feature.FinanceService');
            vm.hasAccessFinanceTransactionData =
              App.isHost() || abp.setting.getBoolean('Hms.Feature.AccessFinanceTransactionData');

            // Hide navigation sidebar

            $document.ready(function () {
                var width = $document[0].documentElement.clientWidth;
                if (width > 1) {
                    angular.element($document[0]).find('body').addClass('mini-navbar');
                }
            });

            function getTenantName() {
                if (appSession.user) {
                    if (appSession.user.subsidiaryId && appSession.user.subsidiaryName) {
                        return appSession.user.subsidiaryName;
                    } else if (appSession.user.clinicLocationId && appSession.user.clinicLocationName) {
                        // Get available service type

                        if (appSession.user.panelLocationServiceTypes.length > 0) {
                            // Check if user has access for the service type

                            vm.hasOutpatientSpecialist = vm.hasOutpatientSpecialist
                                && _.includes(appSession.user.panelLocationServiceTypes, 5);
                            vm.hasInpatient = vm.hasInpatient
                                && _.some(appSession.user.panelLocationServiceTypes, function (type) {
                                    return type === 7 || type === 8
                                });
                            vm.hasHealthScreeningModule = vm.hasHealthScreeningModule
                                && _.includes(appSession.user.panelLocationServiceTypes, 9);
                        }

                        return appSession.user.clinicLocationName;
                    }
                }

                if (appSession.tenant) {
                    return appSession.tenant.name;
                }

                return 'Hms Host';
            }

            init();
        }
    ]);
})();
