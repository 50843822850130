(function () {
    var controllerId = 'host.views.insurers.createOrEditModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'abp.services.app.tempInsurer', 'insurerId', 'Hms.TempInsurances.TempInsurer', 
        function ($scope, $uibModalInstance, insurerSvc, insurerId, constsTempInsurer) {
            function init() {
                vm.loading = true;
                insurerSvc.getInsurerForEdit({
                    id: insurerId
                }).success(function (data) {
                    vm.insurer = data;
                }).finally(function myfunction() {
                    vm.loading = false;
                })
            }
            var vm = this;
		        vm.constsTempInsurer = constsTempInsurer;
            vm.loading = false;
            vm.saving = false;
            vm.insurer = null;

            vm.save = function () {
                if (vm.insurer.id) {
                    vm.saving = true;
                    insurerSvc.createOrUpdateInsurer(vm.insurer)
                        .success(function (data) {
                            abp.notify.info(App.localize("SuccessfullySaved"));
                            $uibModalInstance.close(data);
                        }).finally(function () {
                            vm.saving = false;
                        });
                } else {
                    vm.saving = true;
                    insurerSvc.createOrUpdateInsurer(vm.insurer)
                        .success(function (data) {
                            abp.notify.info(App.localize("SuccessfullySaved"));
                            $uibModalInstance.close(data);
                        }).finally(function () {
                            vm.saving = false;
                        });
                }
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            init();
        }
    ]);
})();
