(() => {
  angular.module('app').controller('host.views.banks.index', BankController);

  BankController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$uibModal',
    '$timeout',
    'abp.services.app.bank',
    'abp.services.app.commonLookup',
    'appConstants',
  ];

  function BankController(
    $scope,
    $state,
    $stateParams,
    $modal,
    $timeout,
    bankSvc,
    commonLookupSvc,
    appConstants
  ) {
    const vm = this;
    vm.loading = 0;
    vm.filterText = $stateParams.filterText || '';
    vm.exporting = 0;
    vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');
    vm.countryCode = null;

    // Assign permissions

    vm.permissions = {
      create: abp.auth.isGranted('Host.Banks.Create'),
      edit: abp.auth.isGranted('Host.Banks.Edit'),
      delete: abp.auth.isGranted('Host.Banks.Delete'),
    };

    // Define grid options

    const viewOptions = {
      skipCount: $stateParams.skipCount,
      maxResultCount: $stateParams.maxResultCount,
      sorting: $stateParams.sorting,
    };

    vm.bankGridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSize,
      paginationPageSize: appConstants.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      enableRowSelection: true,
      enableSelectAll: false,
      enableFullRowSelect: true,
      enableRowHeaderSelection: false,
      multiSelect: false,
      rowTemplete:
        '<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader, \'text-muted\': !row.entity.isActive }" ui-grid-cell></div>',
      columnDefs: [
        {
          enableSorting: false,
          name: App.localize('Actions'),
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
        },
        {
          displayName: App.localize('BankName'),
          field: 'name',
          minWidth: 120,
        },
        {
          displayName: App.localize('Abbreviation'),
          field: 'abbreviation',
          minWidth: 100,
        },
        {
          displayName: App.localize('Bic'),
          field: 'bic',
          minWidth: 100,
        },
        {
          displayName: App.localize('PaymentMode'),
          field: 'paymentMode',
          minWidth: 50,
          cellFilter: 'paymentModeFilter',
        },
        {
          displayName: App.localize('Country'),
          field: 'countryCode',
          minWidth: 50,
          visible: vm.hasRegionalSystemSupport,
          cellTemplate: 'countryCodeTemplate',
        },
        {
          displayName: App.localize('AccountNumberLengths'),
          field: 'accountNumberLengths',
          minWidth: 50,
        },
      ],
      onRegisterApi: (gridApi) => {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          let sorting = '';
          for (let i = 0; i < callback.length; i += 1) {
            if (i) sorting += ', ';
            sorting += `${callback[i].field} ${callback[i].sort.direction}`;
          }
          viewOptions.sorting = sorting;
          vm.getBanks();
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(viewOptions, vm.getBanks)
        );
      },
      data: [],
    };

    $scope.$on('$viewContentLoaded', () => {
      App.initAjax();
    });

    vm.getBanks = getBanks;
    vm.createBank = createBank;
    vm.editBank = editBank;
    vm.deleteBank = deleteBank;
    vm.exportToExcel = exportToExcel;

    init();

    function init() {
      // Shift out action column if there's no edit actions

      if (!vm.permissions.edit && !vm.permissions.delete) vm.bankGridOptions.columnDefs.shift();

      $timeout(() => {
        vm.bankGridOptions.paginationCurrentPage = Math.floor(
          viewOptions.skipCount / viewOptions.maxResultCount + 1
        );
      });

      if (vm.hasRegionalSystemSupport) {
        vm.loading += 1;
        commonLookupSvc
          .getEligibleCountries()
          .success((data) => {
            vm.countries = data;
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    }

    function registerStateParams() {
      $state.transitionTo(
        $state.current,
        {
          skipCount: viewOptions.skipCount,
          maxResultCount: viewOptions.maxResultCount,
          sorting: viewOptions.sorting,
          filterText: vm.filterText,
          countryCode: vm.countryCode,
        },
        {
          notify: false,
        }
      );
    }

    function getBanks() {
      registerStateParams();
      vm.loading += 1;
      bankSvc
        .getBanks({
          skipCount: viewOptions.skipCount,
          maxResultCount: viewOptions.maxResultCount,
          sorting: viewOptions.sorting,
          filter: vm.filterText,
          countryCode: vm.countryCode,
        })
        .success((data) => {
          vm.bankGridOptions.totalItems = data.totalCount;
          vm.bankGridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function createBank() {
      showCreateEditModal();
    }

    function editBank(bank) {
      showCreateEditModal(bank.id);
    }

    function showCreateEditModal(id) {
      const modal = $modal.open({
        templateUrl: require('./createOrEditModal.html'),
        controller: 'host.views.banks.createOrEditModal as vm',
        backdrop: 'static',
        resolve: {
          bankId: () => id,
        },
      });
      modal.result.then(() => {
        vm.getBanks();
      });
    }

    function deleteBank(bank) {
      abp.message.confirm(
        App.localize('BankDeleteWarningMessage', bank.name),
        App.localize('AreYouSure'),
        (result) => {
          if (result) {
            bankSvc
              .deleteBank({
                id: bank.id,
              })
              .success(() => {
                vm.getBanks();
                abp.notify.success(App.localize('SuccessfullyDeleted'));
              });
          }
        }
      );
    }

    function exportToExcel() {
      vm.exporting += 1;
      const option = $.extend({}, viewOptions, {
        filter: vm.filterText,
        countryCode: vm.countryCode,
      });
      option.maxResultCount = appConstants.maxResultCount;

      bankSvc
        .getBanksToExcel(option)
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.exporting -= 1;
        });
    }
  }
})();
