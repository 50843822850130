(() => {
  angular.module('finance').component('panelClaimInvoiceDetailTransactionViewComponent', {
    templateUrl: require('./panelClaimTransactionView.component.html'),
    controller: PanelClaimInvoiceDetailTransactionViewComponent,
    controllerAs: 'vm',
    bindings: {
      currencyCode: '<',
      invoice: '<',
      invoiceFrom: '<',
      invoiceTo: '<',
      isAddressInvoiceFrom: '<',
      isAddressInvoiceTo: '<',
      message: '<',
      getDateString: '<'
    },
  });

  PanelClaimInvoiceDetailTransactionViewComponent.$inject = [];

  function PanelClaimInvoiceDetailTransactionViewComponent() {
    const vm = this;

    vm.loading = 0;

    init();

    function init() {}
  }
})();
