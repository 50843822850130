(() => {
  angular.module('app').component('clinicSpecialistDashboardPatientInfoCardComponent', {
    templateUrl: require('./patientInfoCard.component.html'),
    controller: PatientInfoCardController,
    controllerAs: 'vm',
    bindings: {
      items: '<',
      timeZoneId: '<',
    },
  });

  PatientInfoCardController.$inject = [
    '$state',
    '$sce',
    'Hms.ServiceRequests.ServiceRequestStatus',
    'Hms.ServiceRequests.ServiceRequestType',
    'Hms.Services.ServiceType',
    'moment',
  ];

  function PatientInfoCardController(
    $state,
    $sce,
    enumServiceRequestStatus,
    enumServiceRequestType,
    enumServiceType,
    moment
  ) {
    const vm = this;
    vm.loading = 0;

    vm.enums = {};
    vm.enums.serviceRequestStatus = enumServiceRequestStatus;
    vm.enums.serviceRequestType = enumServiceRequestType;
    vm.enums.serviceType = enumServiceType;

    vm.permissions = {
      canCreateClaim: abp.auth.isGranted('SpecialistClaims.Clinic.Create'),
      canViewClaim: abp.auth.isGranted('SpecialistClaims.Clinic'),
      canEditClaim: abp.auth.isGranted('SpecialistClaims.Clinic.Edit'),
    };

    vm.hasSpecialistWorkflowEnhancement = abp.setting.getBoolean(
      'Hms.Feature.SpecialistWorkflowEnhancement'
    );

    vm.submitClaim = submitClaim;
    vm.getSubmissionMessage = getSubmissionMessage;
    vm.getSubmittedMessage = getSubmittedMessage;
    vm.getCheckInMessage = getCheckInMessage;
    vm.getRejectedMessage = getRejectedMessage;

    vm.$onInit = init;

    function init() {}
    function submitClaim(gl, claimWithTopUp) {
      if (gl.serviceType === 10) {
        $state.go('postHospitalizationFglRequest', {
          letterNumber: gl.letterNumber,
          claimWithTopUp,
          timeZoneId: vm.timeZoneId,
        });
      } else {
        $state.go('createEditSpecialistFinalRequest', {
          letterNumber: gl.letterNumber,
          claimWithTopUp,
          timeZoneId: vm.timeZoneId,
        });
      }
    }

    function getSubmissionMessage(expiryTime) {
      if (!expiryTime) return '';

      const expiryTimeText = moment(expiryTime).format('L LT');

      return $sce.trustAsHtml(App.localize('PleaseSubmitByXSpecialistMessage', expiryTimeText));
    }

    function getSubmittedMessage(claimNumber, creationTime, serviceType) {
      let state = vm.hasSpecialistWorkflowEnhancement
        ? 'specialistClaimRequestDetail'
        : 'specialistClaimDetail';

      if (serviceType === vm.enums.serviceType.PostHospitalization.id)
        state = 'postHospitalizationClaimDetail';

      const claimUrl = $state.href(
        state,
        { claimNumber, timeZoneId: vm.timeZoneId },
        { absolute: true }
      );
      const claimNumberText = vm.permissions.canViewClaim
        ? `<a href="${claimUrl}">${App.htmlEncode(claimNumber)}</a>`
        : App.htmlEncode(claimNumber);
      const creationTimeText = moment(creationTime).format('L LT');

      return $sce.trustAsHtml(
        App.localize('XSubmittedOnYSpecialistMessage', claimNumberText, creationTimeText)
      );
    }

    function getCheckInMessage(checkInTime) {
      const checkInTimeText = moment(checkInTime).format('L LT');

      return $sce.trustAsHtml(App.localize('CheckedInOnXSpecialistMessage', checkInTimeText));
    }

    function getRejectedMessage(rejectionTime) {
      const rejectionTimeText = moment(rejectionTime).format('L LT');

      return $sce.trustAsHtml(App.localize('RejectedOnXSpecialistMessage', rejectionTimeText));
    }

    // HM-4437 TODO: Add function for "reply query"
  }
})();
