(() => {
  angular.module('app').component('manualTransactionSetupBankAccountDetails', {
    templateUrl: require('./bankAccountDetails.component.html'),
    controller: ManualTransactionSetupBankAccountDetailsController,
    controllerAs: 'vm',
    bindings: {
      accountOwnerId: '<',
      countryCode: '<',
    },
  });

  ManualTransactionSetupBankAccountDetailsController.$inject = [
    '$uibModal',
    'financeConstants',
    'abp.services.finance.manualBankAccount',
  ];

  function ManualTransactionSetupBankAccountDetailsController(
    $uibModal,
    financeConstants,
    manualBankAccountSvc
  ) {
    const vm = this;

    vm.loading = 0;

    vm.createEditBankAccountDetails = createEditBankAccountDetails;
    vm.deleteBankAccountDetails = deleteBankAccountDetails;

    vm.bankAccountDetailsGridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: financeConstants.grid.defaultPageSizes,
      paginationPageSize: financeConstants.grid.defaultPageSize,
      paginationCurrentPage: 1,
      useExternalPagination: true,
      useExternalSorting: true,
      columnDefs: [
        {
          name: App.localize('Actions'),
          displayName: 'Actions',
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'bankAccountDetailsActionTemplate',
          allowCellFocus: false,
        },
        {
          displayName: App.localize('BankName'),
          field: 'bankName',
          minWidth: 120,
        },
        {
          displayName: App.localize('BankAccountHolder'),
          field: 'bankAccountName',
          minWidth: 120,
        },
        {
          displayName: App.localize('AccountNo'),
          field: 'bankAccountNumber',
          minWidth: 120,
        },
        {
          displayName: App.localize('Description'),
          field: 'remarks',
          minWidth: 120,
        },
      ],
      data: [],
    };

    init();

    function init() {
      getBankAccountDetails();
    }

    function getBankAccountDetails() {
      vm.loading += 1;

      manualBankAccountSvc
        .getBankAccounts({ ownerId: vm.accountOwnerId })
        .success((data) => {
          vm.bankAccountDetailsGridOptions.data = data.bankAccounts;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function createEditBankAccountDetails(bankAccountId) {
      $uibModal
        .open({
          templateUrl: require('./createEditBankAccountDetails.modal.html'),
          controller: 'manualTransactionSetupCreateEditBankAccountDetails as vm',
          backdrop: 'static',
          resolve: {
            isCreate: () => bankAccountId == null,
            bankAccountId: () => bankAccountId,
            ownerId: () => vm.accountOwnerId,
            countryCode: () => vm.countryCode,
          },
        })
        .result.then(() => {
          // Refresh bank account details listing.

          getBankAccountDetails();
        });
    }

    function deleteBankAccountDetails(item) {
      // Delete bank account details.

      abp.message.confirm(
        App.localize('BankAccountDeleteWarningMessage', item.bankAccountName),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            manualBankAccountSvc.deleteBankAccount({ bankAccountId: item.id }).success(() => {
              // Refresh bank account details listing.

              getBankAccountDetails();
              abp.notify.success(App.localize('SuccessfullyDeleted'));
            });
          }
        }
      );
    }
  }
})();
