(() => {
  angular.module('app').component('revenueSubscriptionsDetailAuditLogsComponent', {
    templateUrl: require('./auditLogs.component.html'),
    controller: AuditLogsController,
    controllerAs: 'vm',
    bindings: {
      subscription: '<',
    },
  });

  AuditLogsController.$inject = ['abp.services.revenue.corporateComment', 'moment'];

  function AuditLogsController(corporateCommentSvc, moment) {
    const vm = this;
    vm.loading = 0;

    vm.getAuditLogs = getAuditLogs();

    init();

    function init() {
      getAuditLogs();
    }

    function getAuditLogs() {
      vm.loading += 1;
      corporateCommentSvc
        .getComments({
          corporateId: vm.subscription.corporateId,
          subscriptionOldId: vm.subscription.id
        })
        .success((data) => {
          vm.comments = data.items
            .map(mapValue)
            .sort((a, b) => moment(b.creationTime) - moment(a.creationTime));
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function mapValue(comment) {
      comment.typeInfo = {
        title: comment.type,
        iconClass: 'far fa-book',
        bgClass: '',
      };

      if (comment.type.endsWith('.Deleted')) {
        comment.typeInfo.iconClass = 'far fa-trash';
        comment.typeInfo.bgClass = 'bg-danger';
      }

      return comment;
    }
  }
})();
