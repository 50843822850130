(function () {
    var category = [
        function () {
            var vm = this;

            vm.addCategory = addCategory;
            vm.deleteCategory = deleteCategory;
            vm.lookupNotificationType = lookupNotificationType;
            vm.limitSize = 100;

            function addCategory() {
                vm.categories.push({
                    categoryId: "",
                    notificationTypes: []
                });
            }

            function deleteCategory(index) {
                if (index < vm.categories.length)
                    vm.categories = _.without(vm.categories, vm.categories[index]);
                if (vm.categories.length === 0)
                    addCategory(-1);
            }

            function lookupNotificationType(filter) {
                var keyword = _.trim(filter || '');
                if (keyword) {
                    var filteredNotificationTypes = [];
                    var regexes = _.chain(keyword.split(' '))
                        .map(function (k) {
                            return _.trim(k.toUpperCase());
                        })
                        .filter(function (k) {
                            return k;
                        })
                        .uniq()
                        .map(function (k) {
                            _.escapeRegExp(k);
                            return {
                                token: k,
                                len: k.length,
                                pattern: new RegExp('\\b' + k, 'i')
                            };
                        })
                        .value();

                    _.forEach(vm.userNotificationTypes, function (notificationType) {
                        var score = 0;
                        _.forEach(regexes, function (r) {
                            if (r.pattern.test(notificationType.displayName)) {
                                score -= r.len;
                            }
                        });

                        if (score) {
                            filteredNotificationTypes.push({
                                score: score,
                                item: notificationType
                            });
                        }
                    });

                    vm.filteredNotificationTypes = _.chain(filteredNotificationTypes)
                        .sortBy(['score'])
                        .take(30)
                        .map(function (k) {
                            return k.item;
                        })
                        .uniqBy(function (s) {
                            return s.name;
                        })
                        .value();
                } else {
                    vm.filteredNotificationTypes = _.take(vm.userNotificationTypes, vm.limitSize);
                }
            };
        }
    ];

    var app = angular.module('app');
    app.component('notificationCategory', {
        templateUrl: require('/App/host/views/settings/oneSignalNotificationCategory/oneSignalNotificationCategory.component.html'),
        controller: category,
        controllerAs: 'vm',
        bindings: {
            categories: '=',
            userNotificationTypes: '<'
        }
    });
})();
