(function () {
    angular.module('app').controller('host.views.treatments.medicalFeeSchedules.importModal', [
        '$scope', '$uibModal', '$uibModalInstance', 'FileUploader', '$interval', 'abp.services.app.medicalFeeSchedule', '$rootScope',
        function ($scope, $modal, $uibModalInstance, FileUploader, $interval, medicalFeeScheduleSvc, $root) {
            var vm = this;

            vm.cancel = cancel;
            vm.confirm = confirm;
            vm.exportTemplateToExcel = exportTemplateToExcel;
            vm.save = save;

            // Import medical procedure fee schedules.

            vm.uploader = new FileUploader({
                url: $root.resolveAppPath('MedicalFeeSchedule/ImportMedicalFeeSchedules'),
                queueLimit: 1,
                headers: App.getFileUploaderHeaders(),
                filters: [{
                    name: 'excelFilter',
                    fn: function (n) {
                        var t = '|' + n.type.slice(n.type.lastIndexOf('/') + 1) + '|';
                        if ('|vnd.openxmlformats-officedocument.spreadsheetml.sheet|'.indexOf(t) === -1
                            && !n.name.endsWith('.xlsx')) {
                            abp.message.warn(App.localize('Excel_Warn_FileType'));
                            return false;
                        }
                        return true;
                    }
                }]
            });

            vm.uploader.onCompleteItem = function (n, t) {
                vm.saving = false;
                $uibModalInstance.close();

                if (!t.success && t.error) {
                    if (t.error.details) {
                        $modal.open({
                            templateUrl: require('/App/host/views/treatments/medicalFeeSchedules/import/importResult.modal.html'),
                            controller: 'host.views.treatments.medicalFeeSchedules.importResultModal as vm',
                            backdrop: 'static',
                            resolve: {
                                errors: function () {
                                    return JSON.parse(t.error.details);
                                }
                            }
                        });
                    }

                    if (t.error.message) {
                        abp.message.error(t.error.message);
                    }
                }

                if (t.success) {
                    abp.message.success(t.result, App.localize('ImportMedicalFeeSchedulesSuccessful'));
                }
            };

            // Download current template.

            function exportTemplateToExcel() {
                vm.exporting++;
                medicalFeeScheduleSvc.getMedicalFeeSchedulesToExcel().success(function (data) {
                    App.downloadTempFile(data);
                }).finally(function () {
                    vm.exporting--;
                });
            };

            function save() {
                vm.invalidUpload = !vm.uploader.queue.length;
                if (vm.invalidUpload)
                {
                    return;
                }
                vm.savingProgress = 0;
                vm.saving = true;
                vm.uploader.uploadAll();
            };

            vm.savingProgress = 0;
            function tickerJob() {
                vm.savingProgress = (vm.savingProgress + 1) % 100;
            }

            var tickerInterval = $interval(tickerJob, 1000);
            $scope.$on('$destroy', function () {
                if (angular.isDefined(tickerInterval)) {
                    $interval.cancel(tickerInterval);
                    tickerInterval = undefined;
                }
            });

            function cancel() {
                $uibModalInstance.dismiss();
            }

            function confirm() {
                $uibModalInstance.close();
            }
        }
    ]);
})();
