(function () {
    var componentId = 'hostCorporatesMasterPoliciesMasterPolicyEligibilityEnrolmentRulesComponent';
    var app = angular.module('app');

    app.component(componentId, {
        templateUrl: require('/App/host/views/corporates/masterPolicies/masterPolicy/eligibility/enrolmentRules/enrolmentRules.component.html'),
        controller: ['countries', controller],
        controllerAs: 'vm',
        bindings: {
            data: '<',
            isPrincipal: '<',
            relationship: '@',
            isCreate: '<',
            isRenewal: '<',
            isEdit: '<'
        }
    });

    function controller(countries) {
        var vm = this;
        vm.loading = 0;

        vm.countries = countries;

        // Assign value to isPrincipal and relationship based on vm.isPrincipal binded value.
        if (vm.isCreate || vm.isRenewal) {
            if (vm.isPrincipal) {
                vm.data.isPrincipal = true;
            }
            else {
                vm.data.isPrincipal = false;
                vm.data.relationship = vm.relationship;
            }
        }
        else {
            var blackListedCountries = _.filter(vm.countries, function (n){
              return _.includes(vm.data.nationalityCodes, n.code);
            })

            vm.countryNames = _.map(blackListedCountries, m => m.name).join(', ');
        }
    }
})();
