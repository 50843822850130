(() => {
  angular
    .module('app')
    .controller(
      'corporate.views.policyHolder.assignInsurancePlanModal',
      InsurancePlanAssignationModalComponent
    );

  InsurancePlanAssignationModalComponent.$inject = [
    '$scope',
    '$uibModalInstance',
    'abp.services.app.employee',
    'abp.services.app.policyHolder',
    'employeeId',
    'selectedTab',
    'Hms.Employees.EmployeeState',
    'Hms.Insurance.CoverageCategory',
    'Hms.Gender',
    'moment',
  ];

  function InsurancePlanAssignationModalComponent(
    $scope,
    $uibModalInstance,
    employeeSvc,
    policyHolderSvc,
    employeeId,
    selectedTab,
    enumEmployeeState,
    enumCoverageCategory,
    enumGender,
    moment
  ) {
    const vm = this;

    // properties/variables

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.saving = 0;
    vm.loading = 0;
    vm.profile = null;
    vm.chosenPlanId = null;
    vm.effectiveDate = moment().utc().startOf('day');

    vm.coverageCategory = null;

    vm.isRequired = true;
    vm.principalEligibility = null;
    vm.dependentEligibility = [];

    vm.enums = {};
    vm.enums.employeeState = enumEmployeeState;
    vm.enums.coverageCategory = enumCoverageCategory;
    vm.enums.gender = enumGender;

    vm.permissions = {
      toggleEligibility: abp.auth.isGranted(
        'Corporate.Insurance.PolicyHolder.OverrideDependantEligibility'
      ),
    };

    // function bindings

    vm.calculateCoverageStartDate = calculateCoverageStartDate;
    vm.setCategory = setCategory;
    vm.setInsurancePlan = setInsurancePlan;
    vm.save = save;
    vm.close = close;

    // initialisation

    init();

    function init() {
      vm.loading += 1;
      vm.selectedTab = selectedTab;
      getEmployeeProfile();
      getInsurancePlans();
    }

    // function declarations

    function getEmployeeProfile() {
      employeeSvc
        .getEmployeeProfile({
          id: employeeId,
        })
        .success((data) => {
          vm.profile = data;
          vm.profile.isPreEmployee = vm.profile.employeeType === 2;
          vm.dependents = data.dependents;
          vm.chosenPlanId = vm.profile.hospitalizationBenefitGroupId;
          vm.insurancePlanName = vm.profile.masterPolicyName;
          vm.minGhsBackDate = vm.profile.minGhsBackDate;

          if (vm.profile.coverageCategory) {
            vm.coverageCategory = vm.profile.coverageCategory;
            setCategory();

            _.forEach(vm.selectedDependents, (dependent) => {
              if (dependent.hospitalizationEffectiveDate) {
                dependent.hospitalizationEffectiveDate = moment(
                  dependent.hospitalizationEffectiveDate
                );
                dependent.isEligible = true;
              }
            });
          } else {
            vm.coverageCategory = 1;
          }

          if (vm.profile.hospitalizationEffectiveDate) {
            setInsurancePlan();
            calculateCoverageStartDate(vm.profile.hospitalizationEffectiveDate);
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getInsurancePlans() {
      vm.loading += 1;
      policyHolderSvc
        .getInsurancePlans()
        .success((data) => {
          vm.insurancePlans = _.filter(data, ['hasExpired', false]);
          vm.insurancePlans.push({
            insurancePlanId: 0,
            name: App.localize('Unassign'),
          });
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function calculateCoverageStartDate(hospitalizationEffectiveDate) {
      vm.profile.hospitalizationEffectiveDate = moment(hospitalizationEffectiveDate);
      vm.coverageStartDate = moment(hospitalizationEffectiveDate)
        .startOf('day')
        .add(vm.waitingPeriod, 'd');
    }

    function setCategory() {
      switch (vm.coverageCategory) {
        case 1:
          vm.selectedDependents = null;
          break;
        case 2:
          removeOtherDependents('Spouse');
          break;
        case 3:
          removeOtherDependents('Child');
          break;
        case 4:
          vm.selectedDependents = vm.dependents;
          break;
        default:
          break;
      }
    }

    function removeOtherDependents(dependentType) {
      vm.selectedDependents = vm.dependents.filter((x) => x.relationship === dependentType);
    }

    function setInsurancePlan() {
      if (vm.chosenPlanId === 0) {
        vm.principalEligibility.isEligible = false;
        vm.insurancePlanName = null;
        return;
      }
      vm.showEffectiveDatePicker = true;
      _.forEach(vm.insurancePlans, (plan) => {
        if (plan.insurancePlanId === vm.chosenPlanId) {
          vm.chosenInsurancePlan = plan;
          vm.waitingPeriod = plan.waitingPeriod;
          vm.insurancePlanName = plan.name;
          if (vm.profile.hospitalizationEffectiveDate) {
            vm.coverageStartDate = moment(vm.profile.hospitalizationEffectiveDate)
              .startOf('day')
              .add(vm.waitingPeriod, 'd');
          }
          checkEligibility(plan.masterPolicyId);
          return false;
        }
        return true;
      });
    }

    function checkEligibility(masterPolicyId) {
      policyHolderSvc
        .checkEligibility({
          masterPolicyId,
          employeeId,
        })
        .success((data) => {
          vm.eligibility = data;
          _.forEach(vm.eligibility, (item) => {
            if (item.id === employeeId) vm.principalEligibility = item;
            else {
              _.forEach(vm.dependents, (dependent) => {
                if (dependent.hospitalizationEffectiveDate) {
                  return;
                }
                if (dependent.id === item.id) {
                  dependent.isEligible = item.isEligible;
                }
              });
            }
          });
          if (vm.profile.hospitalizationEffectiveDate) {
            vm.principalEligibility.isEligible = true;
          }
        });
    }

    function unassignInsurancePlan() {
      policyHolderSvc
        .unassignInsurancePlan({
          employeeId,
        })
        .success(() => {
          $uibModalInstance.close();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function save() {
      const form = $scope.assignInsurancePlanForm;
      App.touchFormErrors(form);
      if (form.$invalid) {
        return;
      }

      vm.dependentEligibility = [];
      _.forEach(vm.selectedDependents, (dependent) => {
        vm.dependentEligibility.push({
          id: dependent.id,
          isEligible: dependent.isEligible,
          hospitalizationEffectiveDate: dependent.hospitalizationEffectiveDate,
        });
      });
      vm.saving += 1;
      if (vm.chosenPlanId > 0 && vm.principalEligibility) {
        if (vm.principalEligibility.isEligible) {
          policyHolderSvc
            .assignInsurancePlan({
              principalEligibility: vm.principalEligibility,
              dependentEligibility: vm.dependentEligibility,
              insurancePlanId: vm.chosenPlanId,
              coverageCategory: vm.coverageCategory,
              hospitalizationEffectiveDate: vm.profile.hospitalizationEffectiveDate,
            })
            .success(() => {
              $uibModalInstance.close();
            })
            .finally(() => {
              vm.saving -= 1;
            });
        } else {
          unassignInsurancePlan();
        }
      } else if (vm.chosenPlanId === 0) {
        unassignInsurancePlan();
      } else {
        vm.saving -= 1;
        $uibModalInstance.close();
      }
    }

    function close() {
      $uibModalInstance.dismiss();
    }
  }
})();
