(function () {
    'use strict';

    angular
        .module('app')
        .controller('host.views.doraemon.addOn', ['$state', '$stateParams', '$uibModal', '$window', 'abp.services.app.doraemon', controller]);

    function controller($state, $stateParams, $modal, $window, doraemonSvc) {
        var vm = this;

        vm.openApprovalModal = openApprovalModal;
        vm.openDelegationModal = openDelegationModal;
        vm.query = query;
        vm.preview = preview;
        vm.checkEditMode = checkEditMode;

        vm.isEdit = false;
        vm.letterNumber = $stateParams.letterNumber;
        vm.serviceRequestId = $stateParams.serviceRequestId;
        vm.request = {};
        vm.memberId = 0;
        vm.patientTimelineItems = [];
        vm.previousRequests = [];
        vm.data = [];

        vm.loading = 0;

        var users = [];

        function init() {
            getIssueAddOn();
        }

        function getIssueAddOn() {
            vm.loading++;
            doraemonSvc.getIssueAddOn(
                {
                    letterNumber: vm.letterNumber,
                    id: vm.serviceRequestId,
                }
            ).success(function (data) {
                vm.data = data;
                vm.memberId = data.employeeId;
                vm.admissionType = data.admissionType;
                vm.previousRequestIds = data.previousRequestIds;
                vm.printUrl = data.printUrl;
            }).finally(function () {
                vm.loading--;
            });
        }

        function approve(input) {
            vm.loading++;
            doraemonSvc.approveAddOn(
                {
                    id: vm.serviceRequestId,
                    remarks: input.remarks
                }
            ).success(function (data) {
                if(input.printGl)
                    $window.open(data.printUrl, '_blank');
                $state.go('host.doraemonGl');
            }).finally(function () {
                vm.loading--;
            });
        }

        function query() {
            if (!vm.isEdit && vm.serviceRequestId != null) {
                $state.go('host.doraemonCreateQuery', {
                    letterNumber: vm.letterNumber,
                    serviceRequestId: vm.serviceRequestId,
                });
            } else {
                promptEditModeWarning();
            }
        }

        function preview() {
            window.open(vm.printUrl);
        }

        function openApprovalModal() {
            if (!vm.isEdit && vm.serviceRequestId != null) {
                $modal.open({
                    templateUrl: require('/App/host/views/doraemon/common/remarks/remarks.modal.html'),
                    controller: 'host.views.doraemon.common.remarksModal as vm',
                    backdrop: 'static',
                    resolve: {
                        title: function () {
                            return App.localize('ApprovalConfirmation');
                        },
                    }
                }).result.then(function (input) {
                    approve(input);
                });
            } else {
                promptEditModeWarning();
            }
        }

        function openDelegationModal() {
            if (!vm.isEdit && vm.serviceRequestId != null) {
                // TODO: Missing HTML page. Commented out for webpack to build.
                // $modal.open({
                //     templateUrl: require('/App/common/views/modals/notifyRecipients/notifyRecipients.modal.html'),
                //     controller: 'common.views.modals.notifyRecipientsModal as vm',
                //     backdrop: 'static',
                //     resolve: {
                //         users: function () {
                //             return users;
                //         },

                //         title: function () {
                //             return App.localize('DelegationConfirmation');
                //         },
                //     }
                // }).result.then(function (recipients, remarks) {
                //     delegate(recipients, remarks);
                // });
            } else {
                promptEditModeWarning();
            }
        }

        function checkEditMode(isEdit) {
            vm.isEdit = isEdit;
        }

        function promptEditModeWarning() {
            abp.message.error(App.localize('SaveOrCancelChangesNarrative'));
        }

        init();
    }
})();
