(function () {
    var controllerId = 'host.views.treatments.createOrEditTreatmentModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'abp.services.app.treatment', 'treatment', 'countries', 'Hms.Services.ServiceType', 'Hms.Treatments.Treatment',
        function ($scope, $uibModalInstance, treatmentSvc, treatment, countries, enumServiceType, constsTreatment) {
            var vm = this;
            vm.constsTreatment = constsTreatment;
            vm.saving = 0;
            vm.treatment = treatment;
            vm.treatment.isForCorporateOnly = (treatment.tenantTypeRestrictions & 2) > 0;
            vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');

            vm.enums = {};
            vm.enums.serviceType = enumServiceType;

            function init() {
                getCurrencyCode();
            }

            vm.tags = _.filter(_.map((treatment.tags || '').split(','), function (d) {
                return {
                    text: _.trim(d || '')
                };
            }), function (d) { return d.text.length; });

            function getCurrencyCode() {
                var country = _.find(countries, ['code', vm.treatment.countryCode]);
                vm.currencyCode = country.currencyCode;
            }

            vm.updateDefaultRate = function () {
                if (vm.treatment.preferredMaxRate) {
                    vm.treatment.preferredDefaultRate = vm.treatment.preferredMaxRate;
                }
            };

            vm.save = function () {
                vm.treatment.tags = _.map(vm.tags || [], function (d) { return d.text; }).join(',');
                if (vm.treatment.id) {
                    vm.saving++;
                    treatmentSvc.updateTreatment(vm.treatment)
                        .success(function (data) {
                            abp.notify.info(App.localize('SuccessfullySaved'));
                            $uibModalInstance.close(data);
                        }).finally(function () {
                            vm.saving--;
                        });
                } else {
                    vm.saving++;
                    treatmentSvc.createTreatment(vm.treatment)
                        .success(function (data) {
                            abp.notify.info(App.localize('SuccessfullySaved'));
                            $uibModalInstance.close(data);
                        }).finally(function () {
                            vm.saving--;
                        });
                }
            };
            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            init();
        }
    ]);
})();
