(() => {
  angular.module('app').filter('numberExt', numberExt);

  numberExt.$inject = ['numberFilter'];

  function numberExt(numberFilter) {
    return numberExtFilter;

    function numberExtFilter(number, fractionSize, dashIfZero) {
      if (dashIfZero && !number) return '-';
      return numberFilter(number, fractionSize);
    }
  }
})();
