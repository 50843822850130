import { has } from 'lodash';

(() => {
  angular.module('app').component('addressInput', {
    templateUrl: require('./addressInput.html'),
    controller: AddressInputController,
    controllerAs: 'vm',
    bindings: {
      address: '<',
      required: '<',
      disabled: '<',
      disabledCountry: '<',
      invalidPostcode: '=',
      countryCodeChangeFunction: '&',
    },
  });

  AddressInputController.$inject = ['countries', 'abp.services.app.commonLookup', 'Hms.Address'];

  function AddressInputController(countries, commonLookupSvc, constsAddress) {
    const vm = this;
    const cachedSubdivisionLookup = {};

    vm.subdivisionPlaceholder = App.localize('State');
    vm.cityPlaceholder = App.localize('City/Town');
    vm.constsAddress = constsAddress;
    vm.countries = countries;

    vm.postcodeChecker = {
      isChecking: 0,
      isValid: false,
      isInvalid: false,
      lastInput: null,
      getCurrentInput() {
        if (!vm.address) return null;

        const countryCode = (vm.address.countryCode || '').trim();
        const postcode = (vm.address.postcode || '').trim();
        if (countryCode && postcode) {
          return {
            countryCode,
            postcode,
          };
        }

        return null;
      },
      check() {
        const input = this.getCurrentInput();
        this.lastInput = input;
        this.isValid = false;
        this.isInvalid = false;

        if (!input) return;

        this.isChecking += 1;
        const checker = this;

        commonLookupSvc
          .postcodeLookup(input)
          .success((data) => {
            if (
              checker.lastInput &&
              input.postcode === checker.lastInput.postcode &&
              input.countryCode === checker.lastInput.countryCode
            ) {
              if (data.result) {
                checker.isValid = true;
                vm.invalidPostcode = false;

                if (data.result.city) {
                  vm.address.city = data.result.city;
                }

                if (data.result.subdivisionCode) {
                  const country = _.find(vm.countries, { code: vm.address.countryCode });
                  const subdivision = country
                    ? _.find(country.subdivisions, {
                        subdivisionCode: data.result.subdivisionCode,
                      })
                    : null;
                  if (subdivision) {
                    vm.subdivision = subdivision;
                    vm.updateAddressSubdivision();
                  }
                }
              } else {
                checker.isInvalid = true;
                vm.invalidPostcode = true;
              }
              checker.isChecking = 0;
            }
          })
          .finally(() => {
            checker.isChecking = Math.max(checker.isChecking - 1, 0);
          });
      },
    };

    vm.$onChanges = $onChanges;
    vm.getSubdivisions = getSubdivisions;
    vm.updateAddressSubdivision = updateAddressSubdivision;
    vm.resetAddress = resetAddress;

    init();

    function init() {
      initAddressObj();
    }

    function initAddressObj() {
      if (vm.address) {
        if (vm.address.subdivisionCode) {
          vm.subdivision = {
            name: vm.address.state,
            subdivisionCode: vm.address.subdivisionCode || vm.address.countrySubdivisionId,
          };
        } else {
          vm.postcodeChecker.check();
        }
      } else {
        vm.subdivision = null;
      }
    }

    function $onChanges(changesObj) {
      if (changesObj.address) {
        initAddressObj();

        if (has(changesObj?.address?.currentValue, 'countryCode')) {
          vm.countryCodeChangeFunction();
        }
      }
    }

    function getSubdivisions(filter) {
      let f = filter;

      if (!vm.address || !vm.address.countryCode) return null;

      let cachedResult = cachedSubdivisionLookup[vm.address.countryCode];
      if (!cachedResult) {
        const country = _.find(vm.countries, { code: vm.address.countryCode });
        cachedResult = country ? country.subdivisions : [];
        cachedSubdivisionLookup[vm.address.countryCode] = cachedResult;
      }

      f = (f || '').trim().toUpperCase();
      return f ? _.filter(cachedResult, (r) => r.name.toUpperCase().includes(f)) : cachedResult;
    }

    function updateAddressSubdivision() {
      if (!vm.subdivision) {
        vm.address.state = '';
        vm.address.subdivisionCode = '';
        vm.address.countrySubdivisionId = '';
      } else if (typeof vm.subdivision === 'string') {
        vm.address.state = vm.subdivision;
        vm.address.subdivisionCode = '';
        vm.address.countrySubdivisionId = '';
      } else {
        vm.address.state = vm.subdivision.name;
        vm.address.subdivisionCode = vm.subdivision.subdivisionCode;
        vm.address.countrySubdivisionId = vm.subdivision.subdivisionCode;
      }
    }

    function updateSubdivisionPlaceholder() {
      if (vm.address.countryCode === 'ID' || vm.address.countryCode === 'TH') {
        vm.subdivisionPlaceholder = App.localize('Province')
      } else {
        vm.subdivisionPlaceholder = App.localize('State')
      }
    }

    function updateCityPlaceholder() {
      if (vm.address.countryCode === 'ID') {
        vm.cityPlaceholder = App.localize('Regency')
      } else if (vm.address.countryCode === 'TH') {
        vm.cityPlaceholder = App.localize('District')
      } else {
        vm.cityPlaceholder = App.localize('City/Town')
      }
    }

    function resetAddress() {
      vm.address.postcode = '';
      vm.address.state = '';
      vm.address.city = '';
      vm.subdivision = null;
      updateSubdivisionPlaceholder();
      updateCityPlaceholder();
    }
  }
})();
