import swal from 'sweetalert';

(() => {
  angular
    .module('app')
    .controller('common.views.saleTransactions.createEditPayment', PaymentEditController);

  PaymentEditController.$inject = [
    '$stateParams',
    '$window',
    'abp.services.app.saleTransaction',
    'Hms.Payments.PaymentMethod',
    'Hms.Payments.Payment',
    'moment',
  ];

  function PaymentEditController(
    $stateParams,
    $window,
    saleTransactionSvc,
    enumPaymentMethod,
    constsPayment,
    moment
  ) {
    const vm = this;

    vm.constsPayment = constsPayment;
    vm.loading = 0;
    vm.saving = 0;
    vm.tenantName = '';
    vm.payment = null;
    vm.invoices = [];
    vm.creditMemos = [];
    vm.sendEmailOnDefaultSave = false;
    vm.saveText = [App.localize('SaveAndClose'), App.localize('SaveAndSendEmail')];
    vm.hasSaleTransactionWorkflowEnhancement = abp.setting.getBoolean(
      'Hms.Feature.SaleTransactionWorkflowEnhancement'
    );
    vm.hasFinanceService = abp.setting.getBoolean(
      'Hms.Feature.FinanceService'
    );
    vm.stopInvoiceGeneration = abp.setting.getBoolean(
      'Hms.Feature.StopPortalInvoiceGeneration'
    );

    vm.showCategory = false;

    vm.enums = {
      paymentMethod: enumPaymentMethod,
    };

    vm.getAppliedAmount = getAppliedAmount;
    vm.getAppliedCredit = getAppliedCredit;
    vm.updatePaymentAmount = updatePaymentAmount;
    vm.payFull = payFull;
    vm.sendEmail = sendEmail;
    vm.save = save;

    init();

    function init() {
      getPaymentForEdit();
    }

    function getPaymentForEdit() {
      vm.loading += 1;
      saleTransactionSvc
        .getPaymentForEdit({
          id: $stateParams.transactionNumber,
        })
        .success((data) => {
          vm.tenantName = data.tenantName;
          vm.invoices = data.invoices;
          vm.creditMemos = data.creditMemos;
          vm.payment = data.payment;
          vm.payment.date = moment(data.payment.date);
          if (vm.payment.category === 1 && !vm.payment.transactionNumber) {
            vm.sendEmailOnDefaultSave = true;
            _.reverse(vm.saveText);
          }
          vm.countryCode = data.countryCode;
          vm.currencyCode = data.currencyCode;
          vm.availablePaymentMethods = data.availablePaymentMethods;

          if (vm.hasSaleTransactionWorkflowEnhancement) {
            getCategoryNames(vm.invoices);
            getCategoryNames(vm.creditMemos);
          }

          // Check if account receivable.

          if (vm.hasFinanceService) {
            vm.payor = data.tenantName;
            vm.accountOwnerId = data.payment.accountOwnerId;
            if (
              vm.payment.category === 2 ||
              vm.payment.category === 12 ||
              vm.payment.category === 13
            ) {
              vm.isAccountReceivable = true;
            }
            
            // TODO Get accountOwner bank number through API.

            let accountOwner = null;
            let bank = null;

            [accountOwner] = vm.filteredAccountOwners.filter((x) => x.id === vm.accountOwnerId);
            [bank] = vm.accountOwnerBanks.filter((x) => x.bankNumber === accountOwner.bankNumber);

            vm.bankNumber = bank.bankNumber;
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getAppliedAmount() {
      let amount = 0;
      for (let i = 0; i < vm.invoices.length; i += 1) {
        amount += vm.invoices[i].appliedAmount;
      }
      return (parseFloat(amount.toFixed(2)));
    }

    function getAppliedCredit() {
      let amount = 0;
      for (let i = 0; i < vm.creditMemos.length; i += 1) {
        amount += vm.creditMemos[i].appliedAmount;
      }
      return (parseFloat(amount.toFixed(2)));
    }

    function updatePaymentAmount() {
      vm.payment.amount = vm.getAppliedAmount() - vm.getAppliedCredit();
    }

    function payFull(transaction) {
      const t = transaction;
      t.appliedAmount = t.balance;
      vm.updatePaymentAmount();
    }

    function sendEmail(closeOnSuccessfulSend) {
      if (vm.payment.transactionNumber) {
        saleTransactionSvc
          .getFinanceEmails({
            tenantId: vm.payment.tenantId,
          })
          .success((data) => {
            swal(
              {
                title: App.localize('SendEmailForX', vm.payment.transactionNumber),
                text: App.localize('EmailAddresses'),
                type: 'input',
                showCancelButton: true,
                closeOnConfirm: false,
                confirmButtonColor: '#1ab394',
                inputPlaceholder: App.localize('EmailAddressesNarrative'),
                inputValue: data.emailAddresses,
                showLoaderOnConfirm: true,
              },
              (inputValue) => {
                if (inputValue === false) return false;
                if (inputValue === '') {
                  swal.showInputError(App.localize('InvalidEmailAddress'));
                  return false;
                }
                saleTransactionSvc
                  .sendEmail({
                    transactionNumber: vm.payment.transactionNumber,
                    emailAddresses: inputValue,
                  })
                  .success(() => {
                    abp.notify.info(App.localize('EmailSent'));
                    swal.close();
                    if (closeOnSuccessfulSend) {
                      $window.history.back();
                    }
                  });
                return false;
              }
            );
          });
      }
    }

    function save(mode) {
      vm.saving += 1;

      const defaultSendEmail = vm.sendEmailOnDefaultSave ? 0 : 1;
      const shouldSendEmail = mode === defaultSendEmail;

      saleTransactionSvc
        .editPayment({
          payment: vm.payment,
          invoices: vm.invoices,
          creditMemos: vm.creditMemos,
          countryCode: vm.countryCode,
          currencyCode: vm.currencyCode,
        })
        .success((data) => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          if (shouldSendEmail) {
            vm.payment.transactionNumber = data.transactionNumber;
            if (vm.sendEmailOnDefaultSave) {
              vm.sendEmailOnDefaultSave = false;
              _.reverse(vm.saveText);
            }
            vm.sendEmail(true);
          } else {
            $window.history.back();
          }
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function getCategoryNames(dataList) {
      dataList.forEach((d) => {
        switch (d.category) {
          case 2: // Corporate
            d.categoryName = App.localize('Corporate');
            vm.showCategory = true;
            break;
          case 12: // Subscription
            d.categoryName = App.localize('Subscription');
            vm.showCategory = true;
            break;
          default:
            break;
        }
      });
    }
  }
})();

