(() => {
  angular
    .module('app')
    .controller('common.views.guaranteeLetters.createModal', ControllerController);

  ControllerController.$inject = [
    '$scope',
    '$uibModalInstance',
    'abp.services.app.guaranteeLetter',
    'employee',
    'Hms.Employees.Employee',
    'moment',
  ];

  function ControllerController(
    $scope,
    $uibModalInstance,
    guaranteeLetterSvc,
    employee,
    constsEmployee,
    moment
  ) {
    const vm = this;

    vm.constsEmployee = constsEmployee;
    vm.loading = 0;
    vm.submitting = 0;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

    const maxAdvanceDaysForAppointmentDate = abp.setting.get(
      'Hms.OutpatientSpecialist.MaxAdvanceDaysForAppointmentDate'
    );
    vm.appointmentDateOptions = {
      min: moment().startOf('day'),
      max: moment().startOf('day').add(maxAdvanceDaysForAppointmentDate, 'days'),
    };
    vm.appointmentDateMessage = App.localize(
      'AppointmentDateCanOnlyBeSetBetweenXAndX',
      vm.appointmentDateOptions.min.format('DD/MM/YYYY'),
      vm.appointmentDateOptions.max.format('DD/MM/YYYY')
    );

    vm.isHost = App.isHost();
    vm.isCorporate = App.isCorporate();
    vm.employee = employee;
    vm.employees = [];
    vm.signatureProfiles = [];
    vm.canQuickApprove = 0;
    vm.isDisciplineInReferralExceptions = false;
    vm.referralExceptions = [];
    vm.newAttachments = [];
    vm.uploadConfig = {
      objectType: 'LetterAttachment',
    };

    vm.lookupEmployees = lookupEmployees;
    vm.getGuaranteeLetterForCreate = getGuaranteeLetterForCreate;
    vm.isPatientPanelDisabled = isPatientPanelDisabled;
    vm.isApprovalPanelDisabled = isApprovalPanelDisabled;
    vm.showPatientPanel = showPatientPanel;
    vm.showApprovalPanel = showApprovalPanel;
    vm.lookupDisciplines = lookupDisciplines;
    vm.setDiscipline = setDiscipline;
    vm.selectedPatient = selectedPatient;
    vm.submit = submit;
    vm.cancel = cancel;
    vm.isInvalidAttachment = isInvalidAttachment;
    vm.isInvalidApproval = isInvalidApproval;
    vm.setAppointmentDate = setAppointmentDate;

    init();
    
    function init() {
      if (vm.isHost) {
        vm.getGuaranteeLetterForCreate();
      }
    }

    function lookupEmployees(search) {
      if (search) {
        guaranteeLetterSvc
          .findEmployees({
            filter: search,
            maxResultCount: 5,
          })
          .success((data) => {
            vm.employees = data.items;
          });
      }
    }

    function getGuaranteeLetterForCreate() {
      if (vm.employee) {
        reset();

        vm.loading += 1;
        guaranteeLetterSvc
          .getGuaranteeLetterForCreate({
            id: vm.employee.id,
          })
          .success((data) => {
            vm.patients = data.patients;
            vm.noPatients = !vm.patients.length;
            vm.principal = data.principal;
            vm.clinicLocations = data.clinicLocations;
            vm.requiresReferralOrAppointment = data.requiresReferralOrAppointment;
            vm.dependentRequiresReferralOrAppointment = data.dependentRequiresReferralOrAppointment;
            vm.principalReferralExceptions = data.principalReferralExceptions || [];
            vm.dependentReferralExceptions = data.dependentReferralExceptions || [];

            vm.signatureProfiles = data.signatureProfiles;
            vm.canQuickApprove = vm.signatureProfiles && vm.signatureProfiles.length;
            if (vm.canQuickApprove) {
              vm.signatureProfiles.push({
                id: 0,
                name: App.localize('ApproveLater'),
              });
            }
            vm.disciplines = _.map(data.disciplines, (discipline) => {
              const d = discipline;
              d.symptoms = (d.symptoms || '').split(',');
              return d;
            });
            vm.filteredDisciplines = vm.disciplines;

            vm.isPrincipalPanelOpen = true;
            vm.isPatientPanelOpen = false;
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    }

    function isPatientPanelDisabled() {
      if ($scope.guaranteeLetterCreateForm.MobileNumber.$invalid) return true;
      const isPrincipalValid =
        vm.principal && (vm.principal.mobileNumber || vm.principal.emailAddress);
      return !isPrincipalValid;
    }

    function isApprovalPanelDisabled() {
      if (vm.isPatientPanelDisabled()) return true;
      return (
        !vm.letter.employeeId ||
        !vm.letter.disciplineId ||
        $scope.guaranteeLetterCreateForm.GuaranteedAmount.$invalid ||
        (vm.hasAppointmentDate && $scope.guaranteeLetterCreateForm.AppointmentDate.$invalid) ||
        vm.isInvalidAttachment()
      );
    }

    function showPatientPanel() {
      if (!vm.isPatientPanelDisabled()) {
        vm.isPrincipalPanelOpen = false;
        vm.isPatientPanelOpen = true;
      }
    }

    function showApprovalPanel() {
      if (!vm.isApprovalPanelDisabled()) {
        vm.isPatientPanelOpen = false;
        vm.isApprovalPanelOpen = true;
      }
    }

    function lookupDisciplines(filter) {
      const keyword = _.trim(filter || '');
      if (keyword) {
        const filteredDisciplines = [];

        const regexes = _.chain(keyword.split(' '))
          .map((k) => _.trim(k.toUpperCase()))
          .filter((k) => k)
          .uniq()
          .map((k) => ({
            token: k,
            len: k.length,
            pattern: new RegExp(`\\b${k}`, 'i'),
          }))
          .value();

        _.forEach(vm.disciplines, (d) => {
          // Process symptoms first

          let matchesSymptoms = false;
          _.forEach(d.symptoms, (s) => {
            let score = 0;
            _.forEach(regexes, (r) => {
              if (r.pattern.test(s)) {
                score -= r.len;
              }
            });
            if (score) {
              filteredDisciplines.push({
                score,
                item: _.extend({}, d, { symptom: s }),
              });
              matchesSymptoms = true;
            }
          });

          // Process specialist and description next

          if (!matchesSymptoms) {
            let score = 0;
            _.forEach(regexes, (r) => {
              if (r.pattern.test(d.displayName) || r.pattern.test(d.description)) {
                score -= r.len;
              }
            });
            if (score) {
              filteredDisciplines.push({
                score,
                item: d,
              });
            }
          }
        });

        vm.filteredDisciplines = _.chain(filteredDisciplines)
          .sortBy(['score'])
          .take(30)
          .map((k) => k.item)
          .uniqBy((d) => d.id)
          .value();
      } else {
        vm.filteredDisciplines = vm.disciplines;
      }
    }

    function setDiscipline() {
      if (!vm.letter.disciplineId) {
        vm.disciplineLocations = [];
      }

      vm.filteredDisciplines = vm.disciplines;
      vm.letter.clinicLocationIds = [];
      vm.disciplineLocations = _.filter(vm.clinicLocations, (d) =>
        _.some(d.disciplineIds, (id) => id === vm.letter.disciplineId)
      );
      vm.isDisciplineInReferralExceptions = _.includes(
        vm.referralExceptions,
        vm.letter.disciplineId
      );
    }

    /* End of Specialist discipline */

    function selectedPatient($item) {
      vm.patient = null;
      if (!vm.letter.employeeId) return;

      vm.patient = $item;
      vm.letter.guaranteedAmount = vm.patient.defaultGuaranteedAmount;
      vm.requireReferral = vm.patient.isPrincipal
        ? vm.requiresReferralOrAppointment
        : vm.dependentRequiresReferralOrAppointment;
      vm.referralExceptions = vm.patient.isPrincipal
        ? vm.principalReferralExceptions
        : vm.dependentReferralExceptions;

      vm.setDiscipline();
    }

    function submit() {
      if (!vm.principal.mobileNumber && !vm.principal.emailAddress) {
        abp.message.error(App.localize('MissingOrInvalidContactInformation'));
        return;
      }

      const input = $.extend(vm.letter, {
        mobileNumber: vm.principal.mobileNumber,
        emailAddress: vm.principal.emailAddress,
        newAttachments: vm.newAttachments,
      });

      if (vm.canQuickApprove) {
        const profile = _.find(vm.signatureProfiles, (d) => d.isSelected) || {};
        input.signatureProfileId = profile.id;
      }

      vm.submitting += 1;
      guaranteeLetterSvc
        .createGuaranteeLetter(input)
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySubmitted'));
          $uibModalInstance.close({
            isApproved: input.signatureProfileId,
          });
        })
        .finally(() => {
          vm.submitting -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function reset() {
      vm.principal = null;
      vm.patients = null;
      vm.letter = {};
      vm.disciplines = [];
      vm.filteredDisciplines = [];
      vm.clinicLocations = [];

      vm.noPatients = false;
    }

    function isInvalidAttachment() {
      return (
        vm.requireReferral && !vm.isDisciplineInReferralExceptions && !vm.newAttachments.length
      );
    }

    function isInvalidApproval() {
      if (vm.canQuickApprove) {
        let selectedCount = 0;
        _.each(vm.signatureProfiles, (d) => {
          if (d.isSelected) {
            selectedCount += 1;
          }
        });
        return selectedCount !== 1;
      }
      return false;
    }

    function setAppointmentDate() {
      vm.hasAppointmentDate = !vm.hasAppointmentDate;
      vm.letter.appointmentDate = vm.hasAppointmentDate ? vm.appointmentDateOptions.min : null;
    }
  }
})();
