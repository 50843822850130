(() => {
  angular.module('app').factory('countries', CountriesFactory);

  CountriesFactory.$inject = [];

  function CountriesFactory() {
    let countries;

    init();

    // Duplicate the array to prevent array modification.
    return [...countries];

    function init() {
      if (countries) return;

      // Use global abp object because service proxy not loaded yet.
      abp
        .ajax({
          url: `${abp.appPath}api/services/app/commonLookup/getCountries`,
          type: 'POST',
          async: false,
        })
        .done((result) => {
          _.forEach(result.items, (d) => {
            d.subdivisions = _.sortBy(d.subdivisions, 'name');
          });
          countries = _.sortBy(result.items, 'codeName');
        });
    }
  }
})();
