import zxcvbn from 'zxcvbn';
import _ from 'lodash';

(() => {
  angular.module('app').directive('zxcvbn', zxcvbnDirective);

  zxcvbnDirective.$inject = [];

  function zxcvbnDirective() {
    const directive = {
      link,
      require: 'ngModel',
      restrict: 'A',
      scope: {
        zxResult: '=?zxcvbn',
        zxExtras: '=?',
        zxMinScore: '@?',
      },
    };
    return directive;

    function link(scope, _element, attrs, ngModelCtrl) {
      scope.runZxcvbn = runZxcvbn;
      scope.isForm = isForm;
      scope.setZxExtrasWatcher = setZxExtrasWatcher;
      scope.zxFormWatcher = zxFormWatcher;
      scope.zxArrayWatcher = zxArrayWatcher;
      ngModelCtrl.$validators.passwordStrength = validatePasswordStrength;

      init();

      function init() {
        // Initially set the extras watcher
        scope.setZxExtrasWatcher();

        attrs.$observe('zxExtras', () => {
          scope.setZxExtrasWatcher();
          ngModelCtrl.$validate();
        });

        attrs.$observe('zxMinScore', (value) => {
          scope.zxMinScore = value;
          ngModelCtrl.$validate();
        });
      }

      /**
       * Runs the zxcvbn algorithm with the scope variables: "zxPassword", "zxExtras".
       * Then assigns the result to "scope.zxResults".
       */
      function runZxcvbn() {
        if (_.isNil(scope.zxPassword)) {
          scope.zxPassword = '';
        }

        if (angular.isDefined(scope.zxExtrasArray) && scope.zxExtrasArray.length > 0) {
          scope.zxResult = zxcvbn(scope.zxPassword, scope.zxExtrasArray);
        } else {
          scope.zxResult = zxcvbn(scope.zxPassword);
        }
      }

      function isForm(value) {
        try {
          return Object.getPrototypeOf(value).constructor.name === 'FormController';
        } catch (error) {
          return false;
        }
      }

      /**
       *  Clears the current extras watcher (if there is one) and then attempts to
       *  create a new one via a scope property. This property can be either an array
       *  or a form.
       */
      function setZxExtrasWatcher() {
        const extras = scope.zxExtras;

        // Clear the current watcher if there is one
        if (angular.isFunction(scope.zxExtrasWatcher)) {
          scope.zxExtrasWatcher();
        }
        scope.zxExtrasWatcher = undefined;

        if (angular.isDefined(extras)) {
          if (angular.isArray(extras)) {
            scope.zxArrayWatcher();
          } else if (scope.isForm(extras)) {
            scope.zxFormWatcher();
          }
        }
      }

      /**
       *  Watches scope.zxExtras - under the assumption it is a form object.
       *
       *  This method finds extra fields in forms and then pass them to the zxcvbn algorithm.
       *  Note: will ignore angular properties
       *  (those starting with "$") and default javascript properties (those starting with "__").
       */
      function zxFormWatcher() {
        const form = scope.zxExtras;
        console.assert(scope.isForm(form), 'zx-extras element is some how not a form.');

        scope.zxExtrasWatcher = scope.$watch(
          () => {
            const extrasArray = [];
            // Doesn't start with "$" or "__"
            const validPropertyRegex = /^(?!\$|__)/;
            _.forIn(form, (prop) => {
              // Property's containing the string "password" should also be ignored
              if (
                validPropertyRegex.test(prop) &&
                _.has(form[prop], '$viewValue') &&
                prop.toLowerCase().indexOf('password') === -1
              ) {
                extrasArray.push(form[prop].$viewValue);
              }
            });
            return extrasArray;
          },
          (newValue) => {
            scope.zxExtrasArray = [];
            // Only pass strings
            for (let i = 0; i < newValue.length; i += 1) {
              if (angular.isString(newValue[i])) {
                scope.zxExtrasArray.push(newValue[i]);
              }
            }
            ngModelCtrl.$validate();
          },
          true
        );
      }

      /**
       *  Watches scope.zxExtras - under the assumption it is an array.
       *  If the array changes (deep check) then the zxcvbn algorithm will be re-run
       *  with the updated extras data.
       */
      function zxArrayWatcher() {
        scope.zxExtrasWatcher = scope.$watch(
          'zxExtras',
          (newValue) => {
            scope.zxExtrasArray = newValue;
            ngModelCtrl.$validate();
          },
          true
        );
      }

      // Set the password validator and also run the zxcvbn algorithm on password change
      function validatePasswordStrength(value) {
        let minScore = Number(scope.zxMinScore);
        minScore = Number.isNaN(minScore) || minScore < 0 || minScore > 4 ? 0 : minScore;
        scope.zxPassword = value;
        scope.runZxcvbn();
        return minScore <= scope.zxResult.score;
      }
    }
  }
})();
