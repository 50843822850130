(() => {
  angular.module('app').component('commonComponentsDataExportTableComponent', {
    templateUrl: require('./dataExportTable.component.html'),
    controller: DataExportTableController,
    controllerAs: 'vm',
    bindings: {
      reportName: '<?',
      generateButtonType: '<?',
      refresh: '=?',
      lastRefreshTime: '=?',
    },
  });

  DataExportTableController.$inject = [];

  function DataExportTableController() {
    const vm = this;
    vm.showParameter = true;
    vm.lastRefreshTime = null;

    vm.tempDisable = tempDisable;

    function tempDisable() {
      document.getElementById('refreshBtn').disabled = true;
      vm.timeout = setTimeout(renableBtn, 10000);
    }

    function renableBtn() {
      document.getElementById('refreshBtn').disabled = false;
      clearTimeout(vm.timeout);
    }
  }
})();
