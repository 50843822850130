(function () {
    'use strict';

    angular.module('app').component('corporateBenefitGroupsCreateEditClaimPolicyComponent', {
        templateUrl: require('/App/corporate/views/benefitGroups/createEdit/claimPolicy/claimPolicy.component.html'),
        controller: controller,
        controllerAs: 'vm',
        bindings: {
            benefitGroup: '<'
        }
    });

    function controller() {
        var vm = this;
    }
})();
