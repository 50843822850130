(() => {
  angular.module('app').filter('momentFormat', momentFormat);

  momentFormat.$inject = ['moment'];

  function momentFormat(moment) {
    return momentFormatFilter;

    function momentFormatFilter(dateTime, formatString) {
      // One-time binding stop watching variable change after it has a value.
      // This prevent one-time binding stop watching when no dateTime is given.
      if (dateTime === undefined) return undefined;

      return dateTime ? moment(dateTime).format(formatString) : '-';
    }
  }
})();
