(() => {
  angular.module('app').component('commonClaimsProcessClaimDetailSpecialistTicketItemsComponent', {
    templateUrl: require('./specialistTicketItems.component.html'),
    controller: SpecialistTicketItemsController,
    controllerAs: 'vm',
    bindings: {
      disciplines: '<',
      currencyCode: '<',
      treatmentCategories: '<',
      sections: '=',
      isTotalBillAmountValid: '<',
      treatmentHasValue: '<',
      treatmentMissed: '<',
      showDiscipline: '<',
      isDisciplineRequired: '<',
    },
  });

  SpecialistTicketItemsController.$inject = ['Hms.PatientTickets.PatientTicketItem'];

  function SpecialistTicketItemsController(constsPatientTicketItem) {
    const vm = this;

    vm.constsPatientTicketItem = constsPatientTicketItem;

    vm.addSection = addSection;
    vm.removeSection = removeSection;
    vm.addTreatment = addTreatment;
    vm.removeTreatment = removeTreatment;
    vm.clearTreatment = clearTreatment;

    init();

    function init() {}

    function addSection() {
      vm.sections.push({
        treatments: [{}],
      });
    }

    function removeSection(sectionId) {
      vm.sections.splice(sectionId, 1);
    }

    function addTreatment(sectionId) {
      vm.sections[sectionId].treatments.push({});
    }

    function removeTreatment(sectionId, treatmentId) {
      vm.sections[sectionId].treatments.splice(treatmentId, 1);
    }

    function clearTreatment(sectionId) {
      vm.sections[sectionId].treatments = [{}];
    }
  }
})();
