/**
 * passwordMeter - Directive for jQuery Password Strength Meter
 */

(() => {
  angular.module('app').directive('passwordMeter', passwordMeterDirective);

  passwordMeterDirective.$inject = [];

  function passwordMeterDirective() {
    const directive = {
      link,
      restrict: 'A',
      scope: {
        pwOptions: '=',
      },
    };
    return directive;

    function link(scope, element) {
      scope.$watch(scope.pwOptions, () => {
        render();
      });

      function render() {
        $(element).pwstrength(scope.pwOptions);
      }
    }
  }
})();
