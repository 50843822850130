(function () {
    'use strict';

    function controller() {
        var vm = this;
        function init() {
            loadBenefitsData();
            vm.budgetLimitText = App.localize('BudgetLimit');
            vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
            vm.visitLimitText = App.localize('VisitLimit');
        }
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.loading = false;

        function loadBenefitsData() {
            vm.loading = true;
            vm.loading = false;
        }

        init();
    }

    angular.module('app').component('corporateBenefitGroupsInpatientBenefitDetailBenefitsComponent', {
        templateUrl: require('/App/corporate/views/benefitGroups/inpatient/benefitDetail/benefits/benefits.component.html'),
        controller: [controller],
        controllerAs: 'vm',
        bindings: {
            benefitType: '<'
        }
    });
})();
