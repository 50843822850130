(() => {
  angular
    .module('app')
    .filter('saleTransactionType', saleTransactionType)
    // For backward compatibility only. Filter name don't need "Filter" suffix.
    // AngularJS automatically appends "Filter" to name for DI.
    .filter('saleTransactionTypeFilter', saleTransactionType);

  saleTransactionType.$inject = [];

  function saleTransactionType() {
    return saleTransactionTypeFilter;

    function saleTransactionTypeFilter(value) {
      switch (value) {
        case 1:
          return App.localize('Invoice');
        case 2:
          return App.localize('CreditMemo');
        case 3:
          return App.localize('Payment');
        case 4:
          return App.localize('OffsetOriginSales');
        default:
          return value;
      }
    }
  }
})();
