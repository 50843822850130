(function () {
    'use strict';

    angular.module('app').component('clinicLocationSelectorComponent', {
        templateUrl: require('/App/clinic/views/locationSelector/locationSelector.component.html'),
        controller: controller,
        controllerAs: 'vm',
        bindings: {
            locations: '<',
            defaultLocationId: '<',
            onSelect: '&',
        }
    });

    function controller() {
        var vm = this;

        vm.$onChanges = onChanges;
        vm.locationId = 0;

        var hasSetDefault = false; // For one-time binding purpose, is there a better way?

        function onChanges(changes) {
            if (!hasSetDefault && changes.defaultLocationId && changes.defaultLocationId.currentValue) {
                vm.locationId = vm.defaultLocationId;
                hasSetDefault = true;
            }
        }
    }
})();
