(function () {
  angular
    .module('app')
    .component('clinicPatientAdmissionDetailsPatientAdmissionTimelineBodyComponent', {
      require: {
        container: '^clinicPatientAdmissionDetailsPatientAdmissionTimelineBodyComponent'
      },
      templateUrl: require('/App/clinic/views/patient/admissionDetails/patientAdmissionTimelineBody/patientAdmissionTimelineBody.component.html'),
      controller: [
        '$state', '$uibModal', 'abp.services.app.inpatientGuaranteeLetter', 'abp.services.app.hospitalPanelServiceRequest', 'moment',
        controller
      ],
      controllerAs: 'vm',
      bindings: {
        guaranteeLetterNumber: '<',
        timeZoneId: '='
      }
    });

  function controller($state, $modal, inpatientGuaranteeLetterSvc, hospitalPanelServiceRequestSvc, moment) {
    var vm = this;
    vm.loading = 0;
    vm.openTab = openTab;
    vm.printGlButton = printGlButton;
    vm.finalButton = finalButton;
    vm.adjustmentButton = adjustmentButton;
    vm.topUpAddOnButton = topUpAddOnButton;
    vm.cancelGlButton = cancelGlButton;
    vm.activeTab = 'patientAdmissionTimeline';
    vm.newQueriesCount = 0;
    vm.checkInButton = checkInButton;
    vm.guaranteeLetterDetails = null;
    vm.canUseButton = true;

    function init() {
      // Hide all elements upon initialisation

      $('.tabcontent').css('display', 'none');

      // Display patient admission timeline by default

      $('#patientAdmissionTimeline').css('display', 'block');

      getServiceRequestDetailsForTimelineButtons();

      vm.loading++
      inpatientGuaranteeLetterSvc.getInpatientGuaranteeLetterDetail({
        id: vm.guaranteeLetterNumber
      }).success(function (data) {
        vm.guaranteeLetterDetails = data;
      }).finally(function (e) {
        vm.loading--;
      });
    };

    function openTab(evt, tabname) {
      // Get all elements with class="tabcontent" and hide them

      $('.tabcontent').css('display', 'none');

      // Get all elements with class="tablinks" and remove the class "active"

      $('.tablinks').removeClass('active');

      // Show the current tab, and add an "active" class to the link that opened the tab

      $('#' + tabname).css('display', 'block');
      evt.currentTarget.className += ' active';

      vm.activeTab = tabname;
    }

    function adjustmentButton() {
      if (vm.details.adjustmentEnabled)
        $state.go('clinic.adjustmentRequest', {
          letterNumber: vm.guaranteeLetterNumber
        })
    };

    function topUpAddOnButton() {
      if (vm.details.topUpEnabled && !vm.details.isPendingApproval) {
        var modal = $modal.open({
          templateUrl: require('/App/clinic/views/patient/admissionDetails/patientAdmissionTimelineBody/topUpRequestModal/topUpRequestModal.html'),
          controller: "clinic.views.hospital.topUpRequestModal as vm",
          backdrop: "static",
          size: 's',
        });
        modal.result.then(function (requestType) {
          $state.go('clinic.topUpRequest', {
            requestType: requestType,
            letterNumber: vm.guaranteeLetterNumber
          });
        });
      };
    };

    function printGlButton() {
      if (vm.details.printGlEnabled && !vm.details.isPendingApproval)
        window.open(vm.details.printGlUrl, "_blank");
    }

    function checkInButton(guaranteeLetterDetails) {
      var today = moment().format('YYYY-MM-DD');
      var scheduledTime = moment(guaranteeLetterDetails.guaranteeLetter.admissionTime).format('YYYY-MM-DD');
      if (!scheduledTime)
        return;

      if (scheduledTime === today) {
        swal({
          title: App.localize('ConfirmAdmission'),
          text: App.localize('ConfirmAdmissionNarrative'),
          confirmButtonColor: '#1ab394',
          confirmButtonText: App.localize('Yes'),
          cancelButtonText: App.localize('No'),
          showCancelButton: true,
          closeOnConfirm: false,
        }, function (isConfirm) {
          if (isConfirm) {
            checkIn('', guaranteeLetterDetails);
          }
          swal.close();
        });
      } else {
        $modal.open({
          templateUrl: require('/App/clinic/views/hospital/patient/admission/admission.modal.html'),
          controller: 'clinic.views.hospital.patient.admission as vm',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            scheduleDate: function () {
              return guaranteeLetterDetails.guaranteeLetter.admissionTime;
            },
            expiryDate: function () {
              return guaranteeLetterDetails.guaranteeLetter.expiredTime;
            },
            letterNumber: function () {
              return guaranteeLetterDetails.guaranteeLetter.letterNumber;
            }
          }
        }).result.then(function (data) {
          if (data.isCheckIn)
            checkIn(data, guaranteeLetterDetails);
        });
      }
    }

    function checkIn(data, guaranteeLetterDetails) {
      vm.loading++;
      hospitalPanelServiceRequestSvc.checkIn({
        employeeId: guaranteeLetterDetails.patient.id,
        guaranteeLetterNumber: guaranteeLetterDetails.guaranteeLetter.letterNumber,
        serviceType: guaranteeLetterDetails.request.type,
        remarks: data.reason,
        actualAdmissionDate: data.actualAdmissionDate,
        newAttachments: data.newAttachments
      }).success(function (data) {
        if (data.printUrl != "") {
          abp.notify.info(App.localize('PatientXCheckedIn', guaranteeLetterDetails.patient.name));
          window.open(data.printUrl);
          $state.go('clinic.inpatientDashboard')
        } else {
          if (data.isEarlyCheckIn)
            abp.notify.info(App.localize('EarlyCheckInRequestSubmitted'));
          else
            abp.notify.info(App.localize('LateCheckInRequestSubmitted'));
          vm.refreshFunction();
        }
      }).finally(function () {
        vm.loading--;
      });
    }

    function finalButton() {
      /* If TopUp/AddOn reuqest exists, prompt user for confirmation to cancel TopUp/AddOn request
       * Request status === 1 (Pending Approval), Request type === 4 (AddOn)
       */

      if (vm.details.latestServiceRequestStatus === 1 && vm.details.latestServiceRequestType === 4 && !vm.details.isPendingApproval) {
        swal({
          title: App.localize('ConfirmCancelTopUpAddOnRequest'),
          text: App.localize('ConfirmCancelTopUpAddOnRequestNarrative'),
          confirmButtonColor: '#1ab394',
          confirmButtonText: App.localize('CancelTopUpAddOnRequest'),
          cancelButtonText: App.localize('No'),
          showCancelButton: true,
          closeOnConfirm: false,
        }, function (isConfirm) {
          if (isConfirm) {
            var input = {
              letterNumber: vm.guaranteeLetterNumber,
              cancellationReason: App.localize('CancelTopUpAddOnRequest')
            };

            vm.loading++;
            hospitalPanelServiceRequestSvc.cancelGuaranteeLetterServiceRequest(input)
              .success(function (data) {
                abp.notify.success("Top-Up/Add-On request cancelled");
              })
              .finally(function (data) {
                vm.loading--;
                $state.go('clinic.fglRequest', {
                  letterNumber: vm.guaranteeLetterNumber
                })
              });
          }
          swal.close();
        });
      } else if (vm.details.finalEnabled && !vm.details.isPendingApproval) {
        $state.go('clinic.fglRequest', {
          letterNumber: vm.guaranteeLetterNumber
        })
      }
    }

    function cancelGlButton() {
      $modal.open({
        templateUrl: require('/App/clinic/views/hospital/common/cancellationModal/cancel.modal.html'),
        controller: 'clinic.views.hospital.common.cancelModal as vm',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          letterRequestType: function () {
            return 2; // Default to Initial type to show "Cancel guarantee letter" title and description.
          },
          letterStatus: function () {
            return vm.details.letterStatus;
          }
        }
      }).result.then(function (cancellationReason) {
        if (cancellationReason) {
          var input = {
            letterNumber: vm.guaranteeLetterNumber,
            cancellationReason: cancellationReason
          };

          vm.loading++;

          hospitalPanelServiceRequestSvc.cancelGuaranteeLetterServiceRequest(input)
            .success(function (data) {
              abp.notify.success("Cancelled");
            })
            .finally(function (data) {
              vm.loading--;
              goToDashboard();
            });
        }
      });
    }

    function goToDashboard() {
      $state.go('clinic.inpatientDashboard');
    }

    function getServiceRequestDetailsForTimelineButtons() {
      vm.loading++;
      inpatientGuaranteeLetterSvc.getServiceRequestDetailsForTimelineButtons({
        id: vm.guaranteeLetterNumber
      }).success(function (data) {
        vm.details = data;
      }).finally(function (e) {
        vm.loading--;
      });
    }

    init();
  }
})();
