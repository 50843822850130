(function () {
    var controllerId = 'corporate.views.panelRequests';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', '$timeout', 'abp.services.app.panelRequest', 'countries', 'Hms.PanelRequests.PanelRequestStatus',
        'moment',
        function ($scope, $state, $stateParams, $timeout, panelRequestSvc, countries, enumPanelRequestStatus, moment) {
            function init() {
                vm.getPanelRequestList();

                $timeout(function () {
                    vm.gridOptions.paginationCurrentPage = Math.floor(vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1);
                });
            }

            var vm = this;
            var today = moment();
            vm.exporting = 0;
            vm.loading = 0;

            vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');

            vm.enums = {};
            vm.enums.panelRequestStatus = enumPanelRequestStatus;

            vm.permissions = {
                cancel: abp.auth.isGranted('PanelRequests.Corporate.Cancel'),
                create: abp.auth.isGranted('PanelRequests.Corporate.Create')
            };

            vm.isBeforeToday = function (date) {
                return moment(date).isBefore(today);
            };

            vm.countrySubdivisions = [];

            $scope.$on('$viewContentLoaded', function () {
                App.initAjax();
            });

            vm.requestParams = {
                filter: $stateParams.filter || '',
                status: $stateParams.status,
                countrySubdivisionId: $stateParams.countrySubdivisionId,
                skipCount: $stateParams.skipCount || 0,
                maxResultCount: $stateParams.maxResultCount || app.consts.grid.defaultPageSize,
                sorting: $stateParams.sorting
            };

            function getCountriesSubdivisions() {
                const countryCode = vm.accountOwnerCountryCode
                    ? vm.accountOwnerCountryCode
                    : abp.setting.get('Hms.General.DefaultCountryCode');
                const country = _.find(countries, { 'code': countryCode });
                vm.countrySubdivisions = country ? country.subdivisions : [];
            }

            vm.getPanelRequestList = function () {
                if (vm.loading) return;

                registerStateParams();

                // Change status to a list.

                vm.requestParams.statuses = [];
                if (vm.requestParams.status != null) {
                    vm.requestParams.statuses.push(vm.requestParams.status);
                }

                // Get panel requests.

                vm.loading++;
                panelRequestSvc.getPanelRequests(vm.requestParams)
                    .success(function (data) {
                        vm.gridOptions.totalItems = data.totalCount;
                        vm.data = data.items;
                        vm.gridOptions.data = vm.data;
                        vm.accountOwnerCountryCode = data.accountOwnerCountryCode;

                        getCountriesSubdivisions();
                    }).finally(function (e) {
                        vm.loading--;
                    });
            };

            function registerStateParams() {
                $state.transitionTo($state.current,
                    {
                        filter: vm.requestParams.filter,
                        status: vm.requestParams.status,
                        countrySubdivisionId: vm.requestParams.countrySubdivisionId,
                        skipCount: vm.requestParams.skipCount,
                        maxResultCount: vm.requestParams.maxResultCount,
                        sorting: vm.requestParams.sorting
                    }, {
                    notify: false
                });
            }

            vm.gridOptions = {
                appScopeProvider: vm,
                paginationPageSizes: app.consts.grid.defaultPageSizes,
                paginationPageSize: app.consts.grid.defaultPageSize,
                useExternalPagination: true,
                columnDefs: [{
                    enableSorting: false,
                    name: App.localize('Actions'),
                    width: 50,
                    headerCellTemplate: '<span></span>',
                    cellTemplate: 'actionTemplate'
                }, {
                    displayName: App.localize('RequestNumber'),
                    field: 'requestNumber',
                    width: 100
                }, {
                    displayName: App.localize('PanelName'),
                    field: 'panelName',
                    minWidth: 150
                }, {
                    displayName: App.localize('Type'),
                    field: 'panelType',
                    cellTemplate: 'panelTypeTemplate',
                    width: 50
                }, {
                    displayName: App.localize('Status'),
                    field: 'status',
                    cellTemplate: 'recruitmentStatusTemplate',
                    width: 150
                }, {
                    displayName: App.localize('Address'),
                    field: 'address',
                    minWidth: 200
                }, {
                    displayName: App.localize('State'),
                    field: 'state',
                    headerCellTemplate: 'stateDistrictHeaderTemplate',
                    minWidth: 100
                }],
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                    $scope.gridApi.core.on.sortChanged($scope, function (scope, callback) {
                        vm.requestParams.sorting = callback.length && callback[0].field ? callback[0].field + ' ' + callback[0].sort.direction : null;
                        vm.getPanelRequestList();
                    });
                    gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
                        vm.requestParams.skipCount = (newPage - 1) * pageSize;
                        vm.requestParams.maxResultCount = pageSize;
                        vm.getPanelRequestList();
                    });
                },
                data: []
            };

            vm.deletePanelRequest = function (requestId) {
                var message = App.localize('ConfirmToCancelRequest');
                abp.message.confirm(message, App.localize('AreYouSure'), function (d) {
                    if (d) {
                        panelRequestSvc.cancelPanelRequest({
                            id: requestId
                        }).success(function (data) {
                            vm.getPanelRequestList();
                            abp.notify.success(App.localize('SuccessfullyCancelled'));
                        });
                    }
                });
            };

            vm.exportToExcel = function () {
                vm.exporting++;
                panelRequestSvc.getPanelRequestsToExcel(vm.requestParams)
                    .success(function (data) {
                        App.downloadTempFile(data);
                    }).finally(function () {
                        vm.exporting--;
                    });
            };

            init();
        }
    ]);
})();
