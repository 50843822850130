(() => {
  angular
    .module('app')
    .controller('common.views.profile.mySettingsModal', MySettingsModalController);

  MySettingsModalController.$inject = [
    'appSession',
    '$uibModalInstance',
    'abp.services.app.profile',
    'Hms.Users.User',
    'appConstants',
  ];

  function MySettingsModalController(
    appSession,
    $uibModalInstance,
    profileSvc,
    constsUser,
    appConstants
  ) {
    const vm = this;

    vm.constsUser = constsUser;
    vm.loading = 0;
    vm.saving = 0;
    vm.user = null;
    vm.canChangeUserName = true;
    vm.requireEmail = false;

    vm.save = save;
    vm.cancel = cancel;

    init();

    function init() {
      vm.loading += 1;
      profileSvc
        .getCurrentUserProfileForEdit({
          id: appSession.user.id,
        })
        .success((data) => {
          vm.user = data;

          const isAdmin = vm.user.userName !== appConstants.userManagement.defaultAdminUserName;

          vm.canChangeUserName = isAdmin;
          vm.requireEmail = !isAdmin;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function save() {
      vm.saving = true;
      if (!vm.user.emailAddress) vm.user.emailAddress = null;
      profileSvc
        .updateCurrentUserProfile(vm.user)
        .success(() => {
          const session = appSession;
          session.user.name = vm.user.name;
          session.user.userName = vm.user.userName;
          session.user.emailAddress = vm.user.emailAddress;
          abp.notify.info(App.localize('SuccessfullySaved'));
          $uibModalInstance.dismiss();
        })
        .finally(() => {
          vm.saving = false;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
