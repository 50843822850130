(() => {
  angular
    .module('app')
    .controller(
      'common.views.patientTickets.setEmployeePortionModal',
      SetEmployeePortionModalController
    );

  SetEmployeePortionModalController.$inject = [
    '$scope',
    '$uibModalInstance',
    'abp.services.app.patientTicket',
    'ticket',
  ];

  function SetEmployeePortionModalController($scope, $uibModalInstance, patientTicketSvc, ticket) {
    const vm = this;

    vm.saving = 0;
    vm.ticket = ticket;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.employeePortion = ticket.employeePortion;
    vm.remarks = '';
    vm.memberBenefitUtilization = abp.setting.getBoolean('Hms.Feature.MemberBenefitUtilization');
    vm.utilizationItems = angular.copy(ticket.utilizationItems);

    vm.save = save;
    vm.validateUtilization = validateUtilization;
    vm.cancel = cancel;

    init();

    function init() {}

    function save() {
      vm.saving += 1;
      patientTicketSvc
        .updateEmployeePortion({
          id: vm.ticket.ticketNumber,
          employeePortion: vm.employeePortion,
          remarks: vm.remarks,
          utilizationItems: vm.utilizationItems,
        })
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          $uibModalInstance.close();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function validateUtilization(currentItem, modelName) {
      let totalUtilization = 0;
      _.forEach(vm.utilizationItems, (item) => {
        if (item.organizationServiceType !== currentItem.organizationServiceType) {
          totalUtilization += item.amount;
        }
      });
      const availableUtilization = vm.ticket.coveredAmount - totalUtilization;
      const isValid = currentItem.amount <= availableUtilization;

      $scope.employeePortionEditForm[modelName].$setValidity('invalid', isValid);
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
