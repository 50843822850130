(() => {
  angular.module('hms-bootstrap').component('bootstrapSplashComponent', {
    template: require('./splash.eager.html').default,
    controller: SplashController,
    controllerAs: 'vm',
    bindings: {},
  });

  SplashController.$inject = ['bootstrapLoader'];

  function SplashController(bootstrapLoader) {
    const vm = this;
    vm.troubleshootGuide =
      'https://helpcentre-healthmetrics.zendesk.com/hc/en-gb/categories/900000051383-Others';
    vm.bootstrapLoader = bootstrapLoader;
    vm.clearCookieStorage = clearCookieStorage;

    function clearCookieStorage() {
      // Clear cookies
      // This will not delete cookies with HttpOnly flag set, as the HttpOnly flag disables Javascript's access to the cookie.
      // This will not delete cookies that have been set with a Path value.
      const cookies = document.cookie.split(';');

      for (let i = 0; i < cookies.length; i += 1) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = `${name  }=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      }

      // Clear local storage
      window.localStorage.clear();

      // Reload the page to clear cache
      window.location.reload();
    }

    init();
    function init() {}
  }
})();
