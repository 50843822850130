(function () {
    'use strict';

    angular.module('app').component('hostHealthScreeningClaimsCommonBillDetailsComponent', {
        templateUrl: require('/App/host/views/healthScreeningClaims/common/billDetails/billDetails.component.html'),
        controller: [controller],
        controllerAs: 'vm',
        bindings: {
            getTotalBillAmount: '&',
            getCurrentTotalCoveredAmount: '&',
            getCurrentCollectCashAmount: '&',
            getCurrentUncoveredAmount: '&',
            getNewExceededAmount: '&',
            getEmployeeBalanceDisplay: '&',
            balanceInfo: '<',
            finalRequest: '<',
            request: '<',
            currencyCode: '<'
        }
    });

    function controller() {
        var vm = this;
    }
})();
