(function () {
    var controllerId = 'corporate.views.benefitGroups.inpatient.page';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', '$uibModal', '$timeout', 'abp.services.app.benefitGroup', 'appSession',
        function ($scope, $state, $stateParams, $modal, $timeout, benefitGroupSvc, appSession) {
            function init() {
                vm.getBenefitList();
            }

            var vm = this;
            var organizationId = parseInt(appSession.tenant.id, 10);
            var rawData = [];
            vm.loading = 0;
            vm.benefitTypes = [
                { value: 7, name: App.localize('Hospitalization') },
                { value: 8, name: App.localize('Maternity') }
            ];
            vm.getBenefitList = getBenefitList;

            vm.requestParams = {
                filter: $stateParams.filter || '',
                benefit: $stateParams.benefit
            };

            vm.gridOptions = {
                appScopeProvider: vm,
                paginationPageSizes: app.consts.grid.defaultPageSizes,
                paginationPageSize: app.consts.grid.defaultPageSize,
                columnDefs: [{
                    enableSorting: false,
                    name: App.localize('Actions'),
                    width: 100,
                    headerCellTemplate: '<span></span>',
                    cellTemplate: 'actionTemplate'
                }, {
                    displayName: App.localize('Benefits'),
                    field: 'serviceType',
                    minWidth: 100,
                    cellTemplate: 'benefitTemplate'
                }, {
                    displayName: App.localize('Name'),
                    field: 'name',
                    minWidth: 100
                }, {
                    displayName: App.localize('CreationDate'),
                    field: 'creationTime',
                    cellFilter: 'momentFormat: \'L\'',
                    minWidth: 100
                }, {
                    displayName: App.localize('EffectiveDate'),
                    field: 'effectiveTime',
                    cellFilter: 'momentFormat: \'L\'',
                    minWidth: 100
                }],
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                },
                data: []
            };

            $scope.$on('$viewContentLoaded', function () {
                App.initAjax();
            });

            function getBenefitList() {
                vm.loading++;
                benefitGroupSvc.getInpatientBenefitGroups({
                    id: organizationId
                }).success(function (data) {
                    rawData = data.items;
                    filter();
                }).finally(function () {
                    vm.loading--;
                });
            }

            function filter() {
                var filteredData = rawData;

                if (vm.requestParams.benefit) {
                    filteredData = _.filter(filteredData, ['serviceType', vm.requestParams.benefit]);
                }

                if (vm.requestParams.filter) {
                    filteredData = _.filter(filteredData, function (d) {
                        var filterText = vm.requestParams.filter.toLowerCase();
                        return _.includes(d.name.toLowerCase(), filterText) || _.includes(d.displayName.toLowerCase(), filterText);
                    });
                }

                vm.gridOptions.data = filteredData;
            }

            init();
        }
    ]);
})();
