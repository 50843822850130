(() => {
  angular.module('app').controller('common.views.inpatientNprClaims.process', ControllerController);

  ControllerController.$inject = [
    '$state',
    '$stateParams',
    'abp.services.app.inpatientPatientTicket',
    'moment',
  ];

  function ControllerController($state, $stateParams, inpatientPatientTicketSvc, moment) {
    const vm = this;

    vm.loading = 0;
    vm.isHost = App.isHost();
    vm.claimNumber = $stateParams.claimNumber;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.ticket = null;
    vm.save = save;
    vm.hospitalizationBenefits = [];

    vm.hasSobEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');
    vm.hasInsuranceEnhancementModule = abp.setting.getBoolean('Hms.Feature.InsuranceEnhancementModule');

    vm.coPay = {
      isRoomAndBoardExceedEntitlement: false,
      isCoInsurance: false,
      isPercentage: false,
      coPayExclusions: [],
      value: 0,
    };

    vm.roomAndBoardCharges = 0;

    init();

    function init() {
      vm.loading += 1;

      inpatientPatientTicketSvc
        .processClaim({
          id: vm.claimNumber,
        })
        .success((data) => {
          vm.isLoading = true;

          vm.patient = data.patient || {};
          vm.organization = data.organization || {};
          vm.bankDetails = data.bankDetails || {};
          vm.categories = data.categories || [];
          vm.diseaseClassifications = data.diseaseClassifications || [];
          vm.diseaseClassifications = _.map(vm.diseaseClassifications, (d) => ({
            id: d.id,
            code: d.code,
            description: `(${d.code}) ${d.description}`,
          }));

          // Initialize ticket dto.

          vm.ticket = {
            inpatientClaimNumber: data.inpatientClaimNumber,
            receiptDate: data.receiptDate,
            receiptAmount: data.receiptAmount,
            serviceType: data.serviceType,
            claimType: data.claimType,
            manualClinicName: data.manualClinicName,
            manualClinicLocation: data.manualClinicLocation,
            manualDoctorName: data.doctors,
            admissionDate: data.admissionDate,
            dischargeDate: data.dischargeDate,
            newAttachments: [],
            attachments: data.attachments,
            mcAttachments: data.mcAttachments,
            surgicalAttachments: data.surgicalAttachments,
            consentFormAttachments: data.consentFormAttachments,
            identificationAttachments: data.identificationFormAttachments,
            originalReceiptAttachments: data.originalReceiptAttachments,
            billDetailsAttachments: data.billDetailsAttachments,
            medicalReportAttachments: data.medicalReportAttachments,
            investigationReportAttachments: data.investigationReportAttachments,
            physiotherapyReceiptAttachments: data.physiotherapyReceiptAttachments,
            policeReportAttachments: data.policeReportAttachments,
            allowance: data.patient.allowance,
            diagnoses: data.finalDiagnoses,
            isNewVersion: data.isNewVersion,
            rooms: [],
            procedures: [],
            others: [],
            doctorProcedures: [],
            hospitalCharges: [],
            accommodationChargesTreatmentCategories: data.accommodationChargesTreatmentCategories,
            inHospitalDoctorVisitTreatmentCategories: data.inHospitalDoctorVisitTreatmentCategories,
            doctorProcedureChargesTreatmentCategories: data.doctorProcedureChargesTreatmentCategories,
            hospitalChargesTreatmentCategories: data.hospitalChargesTreatmentCategories,
            emergencyOutpatientTreatmentCategories: data.emergencyOutpatientTreatmentCategories,
            miscellaneousTreatmentCategories: data.miscellaneousTreatmentCategories,
            currencyCode: data.currencyCode,
          };

          vm.isMaternity = data.serviceType === 8;

          vm.coPayTypeName = data.coPayTypeName;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function save() {
      vm.loading += 1;

      var rooms = [];
      var procedures = [];
      var others = [];
      var doctorProcedures = [];
      var hospitalCharges = [];
      var emergencyOutpatientTreatments = [];
      var miscellaneousTreatments = [];
      if (vm.hasInsuranceEnhancementModule && !vm.isMaternity){
        rooms = formatTicketItems(
          vm.ticket.rooms,
          (x) => ({
            amount: x.amount,
            uncoveredAmount: x.uncoveredAmount,
            category: x.section,
            categoryName: x.category,
            subCategory: x.subTreatment,
            procedure: x.procedure,
            uncoveredProcedure: x.uncoveredProcedure,
            remark: x.remark,
            uncoveredRemark: x.uncoveredRemark,
            startDate: x.roomChargeDateRangeModel.startDate
              ? moment(x.roomChargeDateRangeModel.startDate).utc()
              : null,
            endDate: x.roomChargeDateRangeModel.endDate
              ? moment(x.roomChargeDateRangeModel.endDate).utc()
              : null,
            dailyCharges: x.dailyCharges,
            lengthOfStay: x.lengthOfStay
          }));
        procedures = formatTicketItems(
          vm.ticket.procedures,
          (x) => ({
            amount: x.amount,
            uncoveredAmount: x.uncoveredAmount,
            category: x.section,
            categoryName: x.doctorType,
            subCategory: x.subTreatment,
            procedure: x.procedure,
            uncoveredProcedure: x.uncoveredProcedure,
            remark: x.remark,
            uncoveredRemark: x.uncoveredRemark,
            startDate: x.visitDate ? moment(x.visitDate).utc() : null,
            endDate: x.visitDate ? moment(x.visitDate).utc() : null,
            doctorId: x.doctorId,
            totalVisit: 1,
            visit: x.visit
          }));
        doctorProcedures = formatTicketItems(
          vm.ticket.doctorProcedures,
          (x) => ({
            amount: x.amount,
            uncoveredAmount: x.uncoveredAmount,
            category: x.section,
            categoryName: x.category,
            subCategory: x.subTreatment,
            procedure: x.procedure,
            uncoveredProcedure: x.uncoveredProcedure,
            remark: x.remark,
            uncoveredRemark: x.uncoveredRemark,
            startDate: x.visitDate ? moment(x.visitDate).utc() : null,
            endDate: x.visitDate ? moment(x.visitDate).utc() : null,
            doctorId: x.doctorId
          }));
        hospitalCharges = formatTicketItems(
          vm.ticket.hospitalCharges,
          (x) => ({
            amount: x.amount,
            uncoveredAmount: x.uncoveredAmount,
            category: x.section,
            categoryName: x.category,
            subCategory: x.subTreatment,
            procedure: x.procedure,
            uncoveredProcedure: x.uncoveredProcedure,
            remark: x.remark,
            uncoveredRemark: x.uncoveredRemark,
          }));
        emergencyOutpatientTreatments = formatTicketItems(
          vm.ticket.emergencyOutpatientTreatments,
          (x) => ({
            amount: x.amount,
            uncoveredAmount: x.uncoveredAmount,
            category: x.section,
            categoryName: x.mainTreatment,
            subCategory: x.subTreatment,
            procedure: x.procedure,
            uncoveredProcedure: x.uncoveredProcedure,
            remark: x.remark,
            uncoveredRemark: x.uncoveredRemark,
          }));

        miscellaneousTreatments = formatTicketItems(
          vm.ticket.miscellaneousTreatments,
          (x) => ({
            amount: x.amount,
            uncoveredAmount: x.uncoveredAmount,
            category: x.section,
            categoryName: x.mainTreatment,
            subCategory: x.subTreatment,
            procedure: x.procedure,
            uncoveredProcedure: x.uncoveredProcedure,
            remark: x.remark,
            uncoveredRemark: x.uncoveredRemark,
          }));
      }
      else if (vm.hasSobEnhancement && !vm.isMaternity) {
        _.each(vm.ticket.rooms, (x) => {
          _.each(x.ticketItems, (item) => {
            const p = item;
            if (!Number.isNaN(Number(p.totalCharges))) {
              p.startDate = moment(p.roomChargeDateRangeModel.startDate).utc();
              p.endDate = moment(p.roomChargeDateRangeModel.endDate).utc();
              rooms.push(p);
            }
          });
        });

        _.each(vm.ticket.procedures, (x) => {
          _.each(x.ticketItems, (item) => {
            const p = item;
            if (!Number.isNaN(Number(p.totalCharges))) {
              p.startDate = p.visitDate ? moment(p.visitDate).utc() : null;
              p.endDate = p.visitDate ? moment(p.visitDate).utc() : null;

              // Total visit is always 1.

              if (!p.totalVisit || p.totalVisit !== 1) p.totalVisit = 1;

              procedures.push(p);
            }
          });
        });

        _.each(vm.ticket.doctorProcedures, (x) => {
          _.each(x.ticketItems, (p) => {
            if (!Number.isNaN(Number(p.totalCharges))) {
              doctorProcedures.push(p);
            }
          });
        });

        _.each(vm.ticket.hospitalCharges, (x) => {
          _.each(x.ticketItems, (p) => {
            if (!Number.isNaN(Number(p.totalCharges))) {
              hospitalCharges.push(p);
            }
          });
        });
      }
      _.each(vm.ticket.others, (x) => {
        if (typeof x.category !== 'undefined' && x.category !== null) others.push(x);
      });

      const input = $.extend(
        {},
        {
          id: vm.claimNumber,
          inpatientClaimNumber: vm.ticket.inpatientClaimNumber,
          receiptDate: vm.ticket.receiptDate,
          receiptAmount: vm.ticket.receiptAmount,
          serviceType: vm.ticket.serviceType,
          claimType: vm.ticket.claimType,
          attachments: vm.ticket.newAttachments,
          diagnoses: vm.ticket.diagnoses,
          rooms,
          procedures,
          others,
          doctorProcedures,
          hospitalCharges,
          emergencyOutpatientTreatments,
          miscellaneousTreatments,
          panelName: vm.ticket.manualClinicName,
          panelAddress: vm.ticket.manualClinicLocation,
          admissionDate: vm.ticket.admissionDate,
          dischargeDate: vm.ticket.dischargeDate,
          medicalCertificate: vm.ticket.medicalCertificate,
          doctor: vm.ticket.manualDoctorName,
          patient: vm.patient,
          paymentEmailAddresses: vm.bankDetails.emailAddresses,
          newAttachments: vm.ticket.newAttachments,
          hospitalizationDays: vm.ticket.hospitalizationDays,
          hospitalizationLeaveDates: vm.ticket.hospitalizationLeaveDates,
        }
      );

      inpatientPatientTicketSvc
        .createInpatientNPRTicket(input)
        .success((data) => {
          abp.notify.info(App.localize('TicketXIssued', data));
          $state.go('inpatientTicketDetail', {
            ticketNumber: data,
          });
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function formatTicketItems(treatments, format) {
      const items = []
      _.each(treatments, (x) => {
        _.each(x.ticketItems, (y) => {
          if (!y.mainTreatment && !y.category && !y.doctorType) return;
          const ticketItem = angular.copy(y);
          items.push(format(ticketItem));
        });
      });

      return items;
    }
  }
})();
