((function () {
  angular
    .module('app')
    .filter('deactivating', deactivating)
    // For backward compatibility only. Filter name don't need "Filter" suffix.
    // AngularJS automatically appends "Filter" to name for DI.
    .filter('deactivatingFilter', deactivating);

  deactivating.$inject = ['moment'];

  function deactivating(moment) {
    return deactivatingFilter;

    function deactivatingFilter(value) {
      return value ? App.localize('DeactivatingOnX', moment(value).format('L')) : '';
    }
  }
})());
