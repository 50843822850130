(() => {
  angular.module('app').component('commonRequestAdjustmentComponent', {
    templateUrl: require('./requestAdjustment.component.html'),
    controller: RequestAdjustmentController,
    controllerAs: 'vm',
    bindings: {
      adjustment: '<',
      attachmentType: '<',
      doctors: '<',
      selectedDoctorIds: '<',
      isSpecialist: '<',
      submit: '&',
      cancel: '&',
    },
  });

  RequestAdjustmentController.$inject = [
    '$scope',
    'abp.services.app.hospitalPanelServiceRequest',
    'appConstants',
  ];

  function RequestAdjustmentController($scope, hospitalPanelServiceRequestSvc, appConstants) {
    const vm = this;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.activeTab = 0;
    vm.prevTab = prevTab;
    vm.nextTab = nextTab;
    vm.setActiveTab = setActiveTab;
    vm.downloadAttendingPhysicianStatement = downloadAttendingPhysicianStatement;
    vm.reason = null;

    const fileExtensions = `|${appConstants.fileUpload.imageExtensions
      .concat(appConstants.fileUpload.pdfExtensions)
      .join('|')}|`;
    vm.uploadConfig = {
      objectType: vm.attachmentType,
      itemsLimit: 3,
      accept: 'image/*,application/pdf',
      filters: [
        {
          name: 'fileFilter',
          fn(n) {
            vm.invalidAttachment = false;
            const t = `|${n.type.slice(n.type.lastIndexOf('/') + 1)}|`;
            if (fileExtensions.indexOf(t) === -1) {
              vm.invalidAttachment = true;
              return false;
            }
            return true;
          },
        },
        {
          name: 'sizeFilter',
          fn(n) {
            vm.invalidAttachmentSize = false;
            const sizeLimit = 52428800;
            if (n.size > sizeLimit) {
              vm.invalidAttachmentSize = true;
              return false;
            }
            return true;
          },
        },
      ],
    };

    function downloadAttendingPhysicianStatement() {
      vm.exporting += 1;
      // Pass in prefilled data
      hospitalPanelServiceRequestSvc
        .getAttendingPhysicianStatementPdf(vm.adjustment.letterNumber)
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.exporting -= 1;
        });
    }

    function tidyReason() {
      let reason = _.trim(vm.adjustment.reason);
      if (reason === 'Others') {
        const remarks = _.trim(vm.adjustment.remarks);
        if (remarks) {
          reason += `: ${remarks}`;
        } else {
          reason = '';
        }
      }
      vm.reason = reason;
    }

    /* Navigation */

    function prevTab() {
      setActiveTab(vm.activeTab - 1);
    }

    function nextTab() {
      if (getFirstInvalidTab(vm.activeTab) > -1) return;

      setActiveTab(vm.activeTab + 1);
    }

    function setActiveTab(tab) {
      if (tab < 0 || tab > 3) return; // Prevent tab out of range.

      tidyReason();
      vm.activeTab = tab;
    }

    function getFirstInvalidTab(tab) {
      const tabForms = [$scope.forms.adjustmentBillDetails, $scope.forms.adjustmentDocuments, null];

      let invalidTab = -1; // -1 for no invalid tabs.
      const maxTab = tab >= 0 ? tab : tabForms.length - 1;

      for (let i = 0; i <= maxTab; i += 1) {
        const tabForm = tabForms[i];
        vm.invalidAttachment = i === 1 && vm.adjustment.newAttachments.length < 1;
        vm.invalidChecklist =
          i === 1 && _.some(vm.adjustment.checklistItems, (d) => d.required && !d.isChecked);

        if (
          (tabForm && !App.isFormValid(tabForm)) ||
          vm.invalidAttachment ||
          vm.invalidChecklist ||
          vm.invalidAttachmentSize
        ) {
          invalidTab = i;
          break;
        }
      }

      return invalidTab;
    }

    /* End of Navigation */
  }
})();
