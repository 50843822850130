(() => {
  angular
    .module('app')
    .controller('common.views.components.outpatientClaimQuery.viewQuery', ViewQueryController);

  ViewQueryController.$inject = [
    '$location',
    '$stateParams',
    'abp.services.app.serviceRequestQuery',
  ];

  function ViewQueryController($location, $stateParams, serviceRequestQuerySvc) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;

    vm.queryNumber = $stateParams.queryNumber;
    vm.claimNumber = $stateParams.claimNumber;
    vm.timeZoneId = $stateParams.timeZoneId;

    vm.breadcrumbClaimType = null;
    vm.breadcrumbClaimType = null;
    vm.breadcrumbClaimsListingLink = null;

    vm.resolve = resolve;
    vm.getDateTimeString = getDateTimeString;

    init();

    function init() {
      getQuery();

      // Get claim type.
      const currentUrl = $location.path().split('/');

      switch (currentUrl[3]) {
        case 'viewHealthScreeningQuery':
          vm.breadcrumbClaimType = App.localize('HealthScreening');
          vm.breadcrumbClaimsListingLink = '#/common/healthScreeningClaims';
          vm.breadcrumbClaimsDetailsLink = `#/common/claims/healthScreening/claim/${vm.claimNumber}?timeZoneId=${vm.timeZoneId}`;
          break;
        case 'viewSpecialistQuery':
          vm.breadcrumbClaimType = App.localize('OutpatientSpecialist');
          vm.breadcrumbClaimsListingLink = '#/common/specialistClaims';
          vm.breadcrumbClaimsDetailsLink = `#/common/specialistClaims/details/${vm.claimNumber}?timeZoneId=${vm.timeZoneId}`;
          break;

        // no default
      }
    }

    /* Functions */

    function getQuery() {
      vm.loading += 1;
      serviceRequestQuerySvc
        .getQuery({ id: vm.queryNumber })
        .success((data) => {
          vm.query = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function resolve() {
      vm.loading += 1;

      serviceRequestQuerySvc
        .resolveQuery({ id: vm.query.id, attachments: vm.query.repliedAttachments })
        .success(() => {
          abp.notify.success(App.localize('SuccessfullySaved'));
          getQuery();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getDateTimeString(date, timeZoneId) {
      return App.getDateTimeString(date, timeZoneId);
    }
  }
})();
