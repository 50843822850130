(() => {
  angular.module('app').directive('treatmentTree', treatmentTreeDirective);

  treatmentTreeDirective.$inject = [];

  function treatmentTreeDirective() {
    const directive = {
      link,
      restrict: 'E',
      templateUrl: require('./treatmentTreeTemplate.html'),
      scope: {
        editData: '=',
        isDirty: '=',
        isReadOnly: '=',
      },
    };
    return directive;

    function link(scope, element) {
      const treatmentTree = $(element).find('.treatment-tree');
      const searchField = $(element).find('.treatment-tree-search');
      let initialized = false;
      let isUpdating = false;
      const ccyCode = abp.setting.get('Hms.General.CurrencyCode');

      scope.expandAll = expandAll;
      scope.collapseAll = collapseAll;

      init();

      function init() {
        scope.$watch('editData', () => {
          if (scope.editData) {
            activate();
          }
        });
      }

      function activate() {
        if (initialized) {
          treatmentTree.jstree('destroy');
        } else {
          let to = false;
          searchField.keyup(() => {
            if (to) clearTimeout(to);
            to = setTimeout(() => {
              const v = searchField.val();
              treatmentTree.jstree(true).search(v);
            }, 250);
          });
        }

        function getNodeText(data) {
          let nodeText = data.displayName;
          if (data.rate) {
            nodeText += ` - <b>${ccyCode} - ${data.rate}</b>`;
            if (data.notCovered) {
              nodeText += ` <span class='text text-danger'>${App.localize('ManualPayment')}</span>`;
            }
          }
          return nodeText;
        }

        const treatmentRecords = _.map(scope.editData.treatments, (record) => ({
          id: record.id,
          parent: record.parentId ? record.parentId : '#',
          text: getNodeText(record),
          state: {
            opened: true,
            selected: _.indexOf(scope.editData.grantedTreatmentIds, record.id) !== -1,
            disabled: scope.isReadOnly,
          },
        }));
        treatmentTree.jstree({
          core: {
            data: treatmentRecords,
          },
          types: {
            default: {
              icon: 'fa fa-folder',
            },
            file: {
              icon: 'fa fa-file',
            },
          },
          checkbox: {
            keep_selected_style: false,
            three_state: false,
            cascade: '',
          },
          search: {
            fuzzy: false,
            show_only_matches: true,
            show_only_matches_children: true,
          },
          plugins: ['checkbox', 'types', 'search'],
        });
        initialized = true;
        treatmentTree.on('changed.jstree', (node, action) => {
          let isNestedUpdate;
          let childNodes;
          if (action.node) {
            treatmentTree.jstree(true).open_all(action.node);
            isNestedUpdate = isUpdating;
            if (!isNestedUpdate) {
              isUpdating = true;
            }
            if (action.node.state.selected) {
              selectNode(treatmentTree.jstree('get_parent', action.node));
              childNodes = $.makeArray(treatmentTree.jstree('get_children_dom', action.node));
              treatmentTree.jstree('select_node', childNodes);
            } else {
              childNodes = $.makeArray(treatmentTree.jstree('get_children_dom', action.node));
              treatmentTree.jstree('deselect_node', childNodes);
            }
            if (!isNestedUpdate) {
              scope.$apply(() => {
                scope.editData.grantedTreatmentIds = getSelectedTreatments();
                scope.isDirty = true;
              });
              isUpdating = false;
            }
          }
        });
      }

      function selectNode(node) {
        treatmentTree.jstree('select_node', node, true);
        const nodeParent = treatmentTree.jstree('get_parent', node);
        if (nodeParent) {
          selectNode(nodeParent);
        }
      }

      function getSelectedTreatments() {
        const selectedId = [];
        const records = treatmentTree.jstree('get_selected', true);
        for (let i = 0; i < records.length; i += 1) {
          selectedId.push(records[i].original.id);
        }
        return selectedId;
      }

      function expandAll() {
        treatmentTree.jstree(true).open_all();
      }

      function collapseAll() {
        treatmentTree.jstree(true).close_all();
      }
    }
  }
})();
