(() => {
  angular
    .module('app')
    .controller('revenue.views.subscriptions.detail.terminationModal', ControllerController);

  ControllerController.$inject = ['$uibModalInstance', 'moment', 'subscription'];

  function ControllerController($uibModalInstance, moment, subscription) {
    const vm = this;

    vm.enums = {};
    vm.cancel = cancel;
    vm.confirm = confirm;
    vm.subscription = subscription;

    // Default value

    vm.reason = 'CustomerChurned';
    vm.newSubscriptionNumber = '';
    vm.reconciliationMode = '1';
    vm.reconcileSubscription = false;
    vm.endDate = null;

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function confirm() {
      if (App.isFormValid(vm.terminationForm)) {
        const input = {
          subscriptionId: vm.subscription.id,
          terminationDate: moment(vm.endDate).startOf('day'),
          reconciliationMode: vm.reason !== 'CustomerChurned' ? vm.reconciliationMode : null,
          terminationReason: vm.reason,
          newSubscriptionNumber: vm.newSubscriptionNumber,
        };

        switch (vm.reason) {
          case 'UpsellWithNewPlan':
          case 'NewRenewalNegotiation':
            if (vm.reconciliationMode === '1') {
              input.terminationDate = moment().startOf('day');
            }
            break;
          default:
            break;
        }

        $uibModalInstance.close(input);
      }
    }
  }
})();
