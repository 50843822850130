(function () {
    var componentId = 'hostDirectorDashboardGrowthWidget';
    var app = angular.module('app');

    function controller($uibModal, directorDashboardSvc, moment) {
        var vm = this;
        vm.loading = false;
        vm.result = [];
        vm.latest = null;

        function loadData() {
            vm.loading = true;
            directorDashboardSvc.getGrowthFigures()
                .success(function (data) {
                    var result = data.items;
                    _.reverse(result);
                    vm.result = result;
                    vm.latest = _.head(result);
                    if (vm.latest) {
                        vm.latest.momPrevMonthYear = moment(vm.latest.monthYear).subtract(1, 'months');
                        vm.latest.cmgrPrevMonthYear = moment(vm.latest.monthYear).subtract(5, 'months');
                    }
                }).finally(function () {
                    vm.loading = false;
                });
        }

        vm.showTable = function () {
            $uibModal.open({
                component: 'hostDirectorDashboardGrowthTable',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                    result: function () {
                        return vm.result;
                    }
                }
            });
        };

        loadData();
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/directorDashboard/growthWidget.html'),
        controller: ['$uibModal', 'abp.services.app.directorDashboard', 'moment', controller],
        controllerAs: 'vm'
    });
})();
