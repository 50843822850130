(function () {
    angular
        .module('app')
        .component('hostInpatientGuaranteeLettersCommonBenefitCoverageComponent', {
            templateUrl: require('/App/host/views/inpatientGuaranteeLetters/common/benefitCoverage/benefitCoverage.component.html'),
            controller: ['$sce', '$uibModal', '$filter', 'abp.services.app.hostServiceRequest', 'Hms.Services.ServiceType', controller],
            controllerAs: 'vm',
            bindings: {
                guaranteeLetterNumber: '<',
                isDetailPage: '<',
                currencyCode: '<'
            }
        });

    function controller($sce, $uibModal, $filter, hostServiceRequestSvc, enumServiceType) {
        function init() {
            getBenefitByLetterNumber();
        }

        var vm = this;
        vm.loading = 0;
        vm.countryCode = abp.setting.get('Hms.General.DefaultCountryCode');
        vm.defaultMaxAllowance = parseFloat(abp.setting.get("Hms.General.MaxAllowanceAmountLimit"));
        vm.isOpen = 1;
        vm.toggleOpen = toggleOpen;
        vm.illnessInfo = $sce.trustAsHtml(App.localize('SpecifiedIllnessTooltip'));

        var hospitalizationTitle = App.localize('Hospitalization');
        var maternityTitle = App.localize('Maternity');

        vm.hasTxtFileSupport = abp.setting.getBoolean('Hms.Feature.TxtFileSupport');
        vm.hasHospitalizationWaitingPeriodEnhancement = abp.setting.getBoolean('Hms.Feature.HospitalisationWaitingPeriodEnhancement');

        vm.enums = {};
        vm.enums.serviceType = enumServiceType;

        // TODO: Update permission

        vm.permissions = {
            update: abp.auth.isGranted('GuaranteeLetters.Host.Update'),
            topUp: abp.auth.isGranted('GuaranteeLetters.Host.TopUp'),
            extend: abp.auth.isGranted('GuaranteeLetters.Host.Approve')
        };

        function toggleOpen() {
            vm.isOpen = !vm.isOpen;
        }

        function getBenefitByLetterNumber() {
            vm.loading++;
            hostServiceRequestSvc.getPatientBenefitsByLetterNumber({
                id: vm.guaranteeLetterNumber
            }).success(function (data) {
                vm.countryCode = data.countryCode;
                vm.benefit = data;
                setDetails();
            }).finally(function () {
                vm.loading--;
            });
        };

        function setDetails() {
            if (vm.benefit) {
                _.each(vm.benefit.inpatientBenefitGroups, function (data) {
                    if (data.serviceType === 7) {
                        data.title = hospitalizationTitle;
                        data.settings = data.hospitalizationSetting;
                        data.allowance = Math.min(data.hospitalizationSetting.allowance, vm.defaultMaxAllowance);
                        data.balancePool = data.hospitalizationSetting.balancePool;
                        data.coPay = data.hospitalizationSetting.coPays ? data.hospitalizationSetting.coPays[0] : null;
                        data.hospitalizationBenefits = data.hospitalizationSetting.hospitalizationBenefits;
                        data.displayBalancePool = [];

                        if (data.balancePool && data.balancePool.length > 0) {
                            _.each(data.balancePool, function (pool) {
                                getDisplayBalancePool(pool, data.displayBalancePool);
                            })
                        }
                    }
                    else {
                        data.title = maternityTitle;
                        data.settings = data.maternitySetting;
                        data.allowance = Math.min(data.maternitySetting.allowance, vm.defaultMaxAllowance);
                        data.balancePool = data.maternitySetting.balancePool;
                        data.coPay = data.maternitySetting.coPays ? data.maternitySetting.coPays[0] : null;
                        data.displayBalancePool = [];

                        if (data.balancePool && data.balancePool.length > 0) {
                            _.each(data.balancePool, function (pool) {
                                getDisplayBalancePool(pool, data.displayBalancePool);
                            })
                        }
                    }

                    if (data.coPay) {
                        if (data.coPay.isPercentage) {
                            if (data.settings.isCoInsurance) {
                                data.coPay.title = vm.hasTxtFileSupport && data.settings.coPayTypeName
                                    ? data.settings.coPayTypeName.concat(' ', data.coPay.value) + '%'
                                    : App.localize('CoInsuranceX', data.coPay.value + '%');
                                data.coPay.text = App.localize('CopayPercentage', data.coPay.value + '%');
                            } else if (!data.settings.isCoInsurance) {
                                data.coPay.title = vm.hasTxtFileSupport && data.settings.coPayTypeName
                                    ? data.settings.coPayTypeName.concat(' ', data.coPay.value) + '%'
                                    : App.localize('CopayX', data.coPay.value + '%');
                                data.coPay.text = App.localize('CopayPercentage', data.coPay.value + '%');
                            }
                        } else {
                            var valueText = $filter('currencyFormat')(data.coPay.value, vm.currencyCode);
                            data.coPay.title = vm.hasTxtFileSupport && data.settings.coPayTypeName
                                ? data.settings.coPayTypeName.concat(' ', valueText)
                                : App.localize('CopayX', valueText);
                            data.coPay.text = App.localize('CopayFixedAmount', valueText);
                        }
                    }
                });
            }
        };

        function getDisplayBalancePool(poolBalance, displayArray) {
            var title = ''

            if (_.head(poolBalance.benefitTypes).id === 7)
                title = hospitalizationTitle;
            else if (_.head(poolBalance.benefitTypes).id === 8)
                title = maternityTitle;

            // TODO: Better design? localisation required

            var isBudget = poolBalance.modifierType === 0 || poolBalance.modifierType === 2;
            var cycle = '';
            if (!poolBalance.isUnlimited) {
                switch (poolBalance.modifierCycle) {
                    case 0: cycle = 'every ' + poolBalance.interval + ' years '; break;
                    case 1: cycle = 'annual '; break;
                    case 2: cycle = 'monthly '; break;
                    case 3: cycle = 'daily '; break;
                    case 4: cycle = 'per visit '; break;
                    case 5: cycle = '6 months '; break;
                    case 6: cycle = '4 months '; break;
                    case 7: cycle = '3 months '; break;
                    case 8: cycle = '2 months '; break;
                }
                if (poolBalance.modifierType === 2 || poolBalance.modifierType === 3) cycle += 'overdraft ';
            }
            title += ' (' + cycle + (isBudget ? 'budget' : 'visit') + ')';

            var isEmptyExcess = poolBalance.isExcess && !poolBalance.employeePortion;
            if (!isEmptyExcess) {
                displayArray.push({
                    title: title,
                    isUnlimited: poolBalance.isUnlimited,
                    isExcess: poolBalance.isExcess,
                    isBudget: isBudget,
                    limit: poolBalance.limit,
                    used: poolBalance.used,
                    locked: poolBalance.locked,
                    balance: poolBalance.balance
                });
            }
        }

        vm.viewExclusionList = function (inpatientSettings) {
            $uibModal.open({
                templateUrl: require('/App/host/views/employees/exclusionList/exclusionList.modal.html'),
                controller: 'host.views.employees.exclusionListModal as vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    inpatientSettings: inpatientSettings
                }
            });
        };

        vm.viewScheduleOfBenefits = function (serviceType, settings, employeeType) {
            $uibModal.open({
                templateUrl: require('/App/host/views/employees/scheduleOfBenefits/scheduleOfBenefits.modal.html'),
                controller: 'host.views.employees.scheduleOfBenefitsModal as vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    serviceType: serviceType,
                    hospitalizationSetting: settings,
                    maternitySetting: settings,
                    employeeType: employeeType,
                    countryInfo : {
                        countryCode: vm.countryCode,
                        currencyCode: vm.currencyCode
                    }
                }
            });
        };

        init();
    }
})();
