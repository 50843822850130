((function () {
  angular
    .module('app')
    .component('commonComponentsExcessUtilizationTableComponent', {
      templateUrl: require('./excessUtilizationTable.component.html'),
      controller: ExcessUtilizationTableController,
      controllerAs: 'vm',
      bindings: {
        benefits: '<',
        currencyCode: '<'
      },
    });

  ExcessUtilizationTableController.$inject = [];

  function ExcessUtilizationTableController() {
    // const vm = this;
  }
})());
