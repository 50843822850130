(() => {
  angular.module('app').controller('common.views.languages.texts', LanguageTextsController);

  LanguageTextsController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$uibModal',
    'abp.services.app.language',
  ];

  function LanguageTextsController($scope, $state, $stateParams, $uibModal, languageSvc) {
    const vm = this;

    let texts = [];
    vm.sourceNames = [];
    vm.languages = [];
    vm.targetLanguageName = $stateParams.languageName;
    vm.sourceName = $stateParams.sourceName || 'Hms';
    vm.baseLanguageName = $stateParams.baseLanguageName || abp.localization.currentLanguage.name;
    vm.targetValueFilter = $stateParams.targetValueFilter || 'ALL';
    vm.filterText = $stateParams.filterText || '';
    vm.loading = 0;

    vm.gridOptions = {
      appScopeProvider: vm,
      columnDefs: [
        {
          displayName: App.localize('Key'),
          field: 'key',
          minWidth: 70,
        },
        {
          displayName: App.localize('BaseValue'),
          field: 'baseValue',
          minWidth: 140,
        },
        {
          displayName: App.localize('TargetValue'),
          field: 'targetValue',
          minWidth: 140,
        },
        {
          name: App.localize('Edit'),
          width: 60,
          cellTemplate: `<div class="ui-grid-cell-contents text-center">  <button ng-click="grid.appScope.openTextEditModal(row.entity)" class="btn btn-xs" title="${App.localize(
            'Edit'
          )}"><i class="fa fa-edit"></i></button></div>`,
        },
      ],
      data: [],
    };

    vm.applyFilters = applyFilters;
    vm.loadTexts = loadTexts;
    vm.openTextEditModal = openTextEditModal;

    init();

    function init() {
      function watchParam(parameter) {
        $scope.$watch(parameter, (newValue, oldValue) => {
          if (newValue !== oldValue) {
            $state.go(
              'languageTexts',
              {
                languageName: vm.targetLanguageName,
                sourceName: vm.sourceName,
                baseLanguageName: vm.baseLanguageName,
                targetValueFilter: vm.targetValueFilter,
                filterText: vm.filterText,
              },
              {
                location: 'replace',
              }
            );
          }
        });
      }

      const validSources = _.filter(
        abp.localization.sources,
        (source) => source.type === 'MultiTenantLocalizationSource'
      );
      vm.sourceNames = _.map(validSources, (source) => source.name);

      vm.languages = abp.localization.languages;

      watchParam('vm.sourceName');
      watchParam('vm.baseLanguageName');
      watchParam('vm.targetLanguageName');

      vm.loadTexts();
    }

    function applyFilters() {
      if (!vm.filterText && vm.targetValueFilter === 'ALL') {
        vm.gridOptions.data = texts;
      }

      const filterText = vm.filterText.trim().toLocaleLowerCase();
      vm.gridOptions.data = _.filter(texts, (text) => {
        if (vm.targetValueFilter === 'EMPTY' && text.targetValue) {
          return false;
        }

        return (
          (text.key && text.key.toLocaleLowerCase().indexOf(filterText) >= 0) ||
          (text.baseValue && text.baseValue.toLocaleLowerCase().indexOf(filterText) >= 0) ||
          (text.targetValue && text.targetValue.toLocaleLowerCase().indexOf(filterText) >= 0)
        );
      });
    }

    function loadTexts() {
      vm.loading += 1;
      languageSvc
        .getLanguageTexts({
          sourceName: vm.sourceName,
          baseLanguageName: vm.baseLanguageName,
          targetLanguageName: vm.targetLanguageName,
        })
        .success((data) => {
          texts = data.items;
          vm.applyFilters();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function openTextEditModal(text) {
      $uibModal.open({
        templateUrl: require('./editTextModal.html'),
        controller: 'common.views.languages.editTextModal as vm',
        resolve: {
          sourceName() {
            return vm.sourceName;
          },
          baseLanguageName() {
            return vm.baseLanguageName;
          },
          languageName() {
            return vm.targetLanguageName;
          },
          allTexts() {
            return vm.gridOptions.data;
          },
          initialText() {
            return text;
          },
        },
      });
    }
  }
})();
