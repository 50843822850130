(() => {
  angular
    .module('app')
    .controller(
      'pharmacy.views.fulfillments.patientTicket',
      FulfillmentPatientTicketPageController
    );

  FulfillmentPatientTicketPageController.$inject = [
    '$stateParams',
    '$filter',
    'abp.services.app.prescription',
  ];

  function FulfillmentPatientTicketPageController($stateParams, $filter, prescriptionSvc) {
    const vm = this;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.loading = 0;
    vm.ticketNumber = $stateParams.ticketNumber;
    vm.latePatientTicketSubmissionId = $stateParams.latePatientTicketSubmissionId;
    vm.ticket = null;
    vm.patient = null;
    vm.clinic = null;
    vm.report = null;
    vm.lateSubmission = null;
    vm.isClinic = App.isClinic();
    vm.isHost = App.isHost();
    vm.isCorporate = App.isCorporate();

    init();

    function init() {
      getTicket();
    }

    function getTicket() {
      vm.loading += 1;
      prescriptionSvc
        .getTicketDetail({
          id: $stateParams.ticketNumber,
        })
        .success((data) => {
          vm.ticket = data.ticket;
          vm.patient = data.patient;
          vm.clinic = data.clinic;
          vm.ticketNumber = data.ticket.ticketNumber;
          vm.copayAmount = data.ticket.coPayAmount;
          vm.reportVersion = data.reportVersion;
          vm.report = vm.reportVersion === 'v0' ? data.reportV0 : data.reportV191018;

          vm.originalCoveredAmount =
            vm.ticket.amount - vm.ticket.uncoveredAmount - vm.ticket.shippingFee;

          vm.newCoveredAmount = vm.originalCoveredAmount;
          if (vm.ticket.shippingIsCovered){
            vm.newCoveredAmount = vm.originalCoveredAmount + vm.ticket.shippingFee;
          }

          if (vm.ticket.coPayAmount) {
            vm.eligibleClaimAmount = Math.max(vm.newCoveredAmount - vm.ticket.coPayAmount, 0);
          } else {
            vm.eligibleClaimAmount = vm.newCoveredAmount;
          }
          vm.exceededAmount = vm.eligibleClaimAmount - vm.ticket.coveredAmount;

          if (vm.patient.coPayValue) {
            vm.coPayValueDisplay = vm.patient.isCoPayPercentage
              ? `${vm.patient.coPayValue}%`
              : `${$filter('currencyFormat')(vm.patient.coPayValue, vm.currencyCode)}`;
          } else {
            vm.coPayValueDisplay = 'NotApplicable';
          }
          // If is pre-employment ticket, get the treatment that represent package.

          if (vm.ticket.ticketType === 3) {
            vm.package = _.first(vm.ticket.treatments);
            vm.packageTreatments = _.tail(vm.ticket.treatments);
          }

          // Format waive service fee reason.

          if (vm.ticket && vm.ticket.bankingDetails) {
            const { bankingDetails } = vm.ticket;
            vm.serviceFeePayorRemark = localizeServiceFeePayorRemark(
              bankingDetails.serviceFeePayor,
              bankingDetails.serviceFeePayorRemark
            );
          }

          checkAttachments();
        })
        .finally(() => {
          vm.loading -= 1;

          if (vm.ticket) {
            checkIsImage(vm.ticket.attachments);
          }
        });
    }

    function checkIsImage(attachments) {
      _.each(attachments, (attachment) => {
        attachment.isImage = attachment.contentType.startsWith('image/');
      });
    }

    function checkAttachments() {
      vm.hasAttachments = vm.ticket.attachments && vm.ticket.attachments.length;
    }

    function localizeServiceFeePayorRemark(payor, remark) {
      let output = '';
      switch (payor) {
        case 0:
          output = App.localize('ServiceFeeWaivedBecauseX', remark);
          break;
        case 1:
          output = App.localize('ServiceFeePaidByCorporate');
          break;
        case 2:
          output = App.localize('ServiceFeePaidByClaimantBecauseX', remark);
          break;
        // no default
      }
      return output;
    }
  }
})();
