(function () {
  var componentId = 'hostCorporateMasterPoliciesListingComponent';
  var app = angular.module('app');
  app.component(componentId, {
    templateUrl: require('/App/host/views/corporates/masterPolicies/masterPoliciesListing/masterPoliciesListing.component.html'),
    controller: ['$scope', '$state', '$stateParams', '$sce', 'abp.services.app.masterPolicy', 'abp.services.app.commonLookup', 'moment', controller],
    controllerAs: 'vm'
  });

  function controller($scope, $state, $stateParams, $sce, masterPolicySvc, commonLookupSvc, moment) {
    function init() {
      vm.getMasterPolicies();
      vm.getInsurersFilter();
    }

    var vm = this;
    vm.loading = 0;
    vm.showDeleteModal = showDeleteModal;
    vm.showRenewModal = showRenewModal;

    vm.organizationId = $stateParams.organizationId;

    vm.masterPolicyDateRangeOptions = App.createDateRangePickerOptions();

    // Overwrite default ranges set for this year, month and week.
    vm.masterPolicyDateRangeOptions.ranges[App.localize("ThisYear")] = [moment().startOf("year"), moment().endOf("year")];
    vm.masterPolicyDateRangeOptions.ranges[App.localize("ThisQuarter")] = [moment().startOf("quarter"), moment().endOf("quarter")];
    vm.masterPolicyDateRangeOptions.ranges[App.localize("ThisMonth")] = [moment().startOf("month"), moment().endOf("month")];
    vm.masterPolicyDateRangeOptions.ranges[App.localize("ThisWeek")] = [moment().startOf("week"), moment().endOf("week")];
    vm.masterPolicyDateRangeOptions.ranges[App.localize("AllDates")] = [moment('2015-01-01'), moment([9999, 0, 1])];

    // Overwrite default max date to infinite.
    vm.masterPolicyDateRangeOptions.max = moment([9999, 0, 1])
    vm.masterPolicyDateRangeOptions.maxDate = moment([9999, 0, 1])

    vm.masterPolicyDateRangeModel = {
      startDate: null,
      endDate: null
    };

    vm.requestParams = {
      masterPolicyNumber: null,
      insurer: null,
    }

    // Define grid options

    var viewOptions = {
      skipCount: $stateParams.skipCount || 0,
      maxResultCount: $stateParams.maxResultCount || app.consts.grid.defaultPageSize,
      sorting: $stateParams.sorting
    };

    vm.masterPolicyGridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: app.consts.grid.defaultPageSizes,
      paginationPageSize: app.consts.grid.defaultPageSize,
      useExternalPagination: true,
      useExternalSorting: true,
      enableRowSelection: true,
      enableSelectAll: false,
      enableFullRowSelect: true,
      enableRowHeaderSelection: false,
      multiSelect: false,
      columnDefs: [{
        name: App.localize('Actions'),
        enableSorting: false,
        width: 96,
        headerCellTemplate: '<span></span>',
        cellTemplate: 'masterPolicyActionTemplate',
      }, {
        displayName: App.localize('MasterPolicyNumber'),
        field: 'masterPolicyNumber',
        minWidth: 250
      }, {
        displayName: App.localize('Insurer'),
        field: 'insurerName',
        minWidth: 300,
        cellTemplate: 'insurerTemplate'
      }, {
        displayName: App.localize('EffectiveDate'),
        field: 'masterPolicyEffectivePeriod',
        minWidth: 250,
      }],
      onRegisterApi: function (gridApi) {
        $scope.gridApi = gridApi;
        gridApi.pagination.on.paginationChanged($scope, App.setPagination(viewOptions, vm.getMasterPolicies));
        gridApi.selection.on.rowSelectionChanged($scope, function (row, evt) {
          if (row.isSelected) {
            $state.go('host.corporateMasterPolicies.insurancePlans', {
              masterPolicyId: row.entity.id,
              masterPolicyNumber: row.entity.masterPolicyNumber,
              masterPolicyInsurer: row.entity.insurerName,
              organizationId: vm.organizationId
            });
            vm.gridSelectedId = row.entity.id;
          } else {
            $state.go('host.corporateMasterPolicies');
            vm.gridSelectedId = null;
          }
        });
      },
      data: []
    };
    vm.gridSelectedId = parseInt($stateParams.id) || null;

    vm.getInsurersFilter = function () {
      vm.loading++;

      commonLookupSvc.getInsurers().success(function (data) {
        vm.insurers = data.items;
      }).finally(function () {
        vm.loading--;
      });
    }

    vm.getMasterPolicies = function () {
      vm.loading++;

      masterPolicySvc.getMasterPolicies({
        skipCount: viewOptions.skipCount,
        maxResultCount: viewOptions.maxResultCount,
        organizationId: vm.organizationId,
        masterPolicyNumber: vm.requestParams.masterPolicyNumber,
        insurerId: vm.requestParams.insurer,
        startDate: vm.masterPolicyDateRangeModel.startDate,
        endDate: vm.masterPolicyDateRangeModel.endDate
      }).success(function (data) {
        vm.masterPolicyGridOptions.data = data.items;
      }).finally(function () {
        vm.loading--;
      });
    }

    //#region Deleting master policy

    function showDeleteModal(masterPolicyId) {
      swal({
        title: App.localize('ConfirmDeleteMasterPolicyWarning'),
        text: App.localize('SentToSystemAndCannotBeUndoneNarrative'),
        type: 'warning',
        confirmButtonText: App.localize('Yes'),
        cancelButtonText: App.localize('No'),
        showCancelButton: true,
        closeOnConfirm: false,
        closeOnCancel: false,
      }, function (isConfirm) {
        if (isConfirm) {
          deleteMasterPolicy({
            id: masterPolicyId
          });
        } else {
          swal.close();
        }
      });
    }

    function deleteMasterPolicy(masterPolicyId) {
      vm.loading++;
      masterPolicySvc.deleteMasterPolicy(masterPolicyId)
        .success(function () {
          deletedSuccessfullyModal();
        }).finally(function () {
          vm.loading--;
        });
    }

    function deletedSuccessfullyModal() {
      swal({
        title: App.localize('SuccessfullyDeleted'),
        text: App.localize('MasterPolicyDeletedMessage'),
        type: 'success',
        confirmButtonText: App.localize('OK'),
        showCancelButton: false,
        closeOnConfirm: true,
      }, function () {
        vm.getMasterPolicies();
      });
    }

    //#endregion Deleting master policy

    // Renew master policy.

    function showRenewModal(organizationId, masterPolicyId) {
      swal({
        title: App.localize('AreYouSure'),
        text: $sce.trustAsHtml(App.localize('MasterPolicyRenewalNarrative')),
        html: true,
        type: 'warning',
        confirmButtonText: App.localize('Yes'),
        cancelButtonText: App.localize('No'),
        showCancelButton: true,
        closeOnConfirm: false,
        closeOnCancel: false,
      }, function (isConfirm) {
        if (isConfirm) {
          goToMasterPolicyCreationPage(organizationId, masterPolicyId);
        } else {
          swal.close();
        }
      });
    }

    function goToMasterPolicyCreationPage(organizationId, masterPolicyId) {
      $state.go('host.createEditMasterPolicy', {
        organizationId: organizationId,
        masterPolicyId: masterPolicyId,
        isRenewal: true
      });
      swal.close();
    }

    init();
  }
})();
