(() => {
  angular.module('app').filter('entitlement', entitlement);

  entitlement.$inject = [];

  function entitlement() {
    return entitlementFilter;

    function entitlementFilter(value) {
      switch (value) {
        case 0:
          return App.localize('Full');
        case 1:
          return App.localize('Accumulated');
        default:
          return value;
      }
    }
  }
})();
