(() => {
  angular
    .module('app')
    .controller('postHospitalizationCreateQuery', PostHospitalizationCreateQueryController);

  PostHospitalizationCreateQueryController.$inject = [
    '$state',
    '$stateParams',
    'abp.services.app.serviceRequestQuery',
    'appConstants',
    'Hms.Services.ServiceType',
  ];

  function PostHospitalizationCreateQueryController(
    $state,
    $stateParams,
    serviceRequestQuerySvc,
    appConstants,
    enumServiceType,
  ) {
    const vm = this;
    vm.loading = 0;
    vm.saving = 0;
    vm.submit = submit;
    vm.addQuestion = addQuestion;
    vm.addQueryItem = addQueryItem;
    vm.addItem = addItem;
    vm.deleteQuestion = deleteQuestion;
    vm.deleteQueryItem = deleteQueryItem;
    vm.deleteItem = deleteItem;

    vm.guaranteeLetterNumber = $stateParams.letterNumber;
    vm.claimNumber = $stateParams.claimNumber;
    vm.timeZoneId = $stateParams.timeZoneId;
    vm.enums = {
      serviceType: enumServiceType,
    };

    vm.queryItems = [
      {
        isGeneralAttention: false,
        doctorId: '',
        type: 1,
        questions: [{}],
        items: [{}],
        newAttachments: [],
      },
    ];

    /* Attachments */

    const fileExtensions = `|${appConstants.fileUpload.imageExtensions
      .concat(appConstants.fileUpload.pdfExtensions)
      .join('|')}|`;
    vm.uploadConfig = {
      objectType: 'InpatientQueryItemAttachment',
      itemsLimit: 5,
      accept: 'image/*,application/pdf',
      filters: [
        {
          name: 'fileFilter',
          fn(n) {
            const t = `|${n.type.slice(n.type.lastIndexOf('/') + 1)}|`;
            if (fileExtensions.indexOf(t) === -1) {
              abp.message.warn(App.localize('File_Warn_FileType'));
              return false;
            }
            return true;
          },
        },
        {
          name: 'sizeFilter',
          fn(n) {
            const sizeLimit = 52428800;
            if (n.size > sizeLimit) {
              abp.message.warn(App.localize('File_Warn_XSizeLimit', sizeLimit / 1024 / 1024));
              return false;
            }
            return true;
          },
        },
      ],
    };

    /* End Attachments */

    init();

    function init() {
      vm.loading += 1;

      serviceRequestQuerySvc
        .getQueryForCreate({ id: vm.guaranteeLetterNumber })
        .success((data) => {
          vm.doctors = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    /* Functions */

    function submit() {
      if (!validateQuery()) {
        return;
      }

      vm.loading += 1;

      _.forEach(vm.queryItems, (query) => {
        if (query.type === 2) {
          query.questions = query.items;
        }
      });

      const input = {
        letterNumber: vm.guaranteeLetterNumber,
        serviceRequestQueryItems: vm.queryItems,
      };

      serviceRequestQuerySvc
        .createQuery(input)
        .success(() => {
          abp.notify.success(App.localize('SuccessfullySaved'));
          $state.go('postHospitalizationGuaranteeLetterDetail', {
            letterNumber: vm.guaranteeLetterNumber,
            timeZoneId: vm.timeZoneId,
            serviceType: vm.enums.serviceType.PostHospitalization.name,
          });
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function validateQuery() {
      let isFormValid = true;

      // Validate query item

      if (_.isEmpty(vm.queryItems)) {
        abp.message.warn(App.localize('EmptyQueryItemWarning'));
        return false;
      }

      _.forEach(vm.queryItems, (query) => {
        const q = query;
        const isQuestionEmpty = _.some(q.questions, (question) => _.isEmpty(question.content));

        const isChecklistEmpty = !_.some(q.checkLists, (checkList) => {
          if (checkList.text === 'Others' && checkList.value)
            return !_.isEmpty(checkList.customText);
          return checkList.value;
        });

        // Validate doctor
        const invalidDoctor = !q.isGeneralAttention && !q.doctorId;

        // Validate questions
        const invalidQuestion = q.type === 1 && (_.isEmpty(q.questions) || isQuestionEmpty);

        // Validate remark
        const invalidRemark = q.type === 2 && (_.isEmpty(q.remark) || _.isEmpty(q.remark.name));

        // Validate checklist
        const invalidChecklist = q.type === 2 && isChecklistEmpty;

        if (invalidDoctor || invalidQuestion || invalidRemark || invalidChecklist) {
          isFormValid = false;
        }

        q.invalidRemark = invalidRemark;
        q.invalidChecklist = invalidChecklist;
        q.invalidDoctor = invalidDoctor;
        q.invalidQuestion = invalidQuestion;
      });

      if (!isFormValid) {
        abp.message.warn(App.localize('QueryIncompleteNarrative'), App.localize('QueryIncomplete'));
        return false;
      }
      return true;
    }

    /* Query items */

    function addQueryItem() {
      vm.queryItems.push({
        isGeneralAttention: false,
        doctorId: '',
        type: 1,
        newAttachments: [],
        questions: [{}],
        items: [{}],
      });
    }

    function deleteQueryItem(index) {
      vm.queryItems.splice(index, 1);
    }

    /* End Query items */

    /* Query question */

    function addQuestion(questionObject) {
      questionObject.push({});
    }

    function deleteQuestion(questionObject, index) {
      questionObject.splice(index, 1);
    }

    /* End Query question */

    /* Query document item */

    function addItem(itemObject) {
      itemObject.push({});
    }

    function deleteItem(itemObject, index) {
      itemObject.splice(index, 1);
    }

    /* End Query document item */
  }
})();
