(function () {
    var componentId = 'corporatePanelAccessesPanelAccessPolicyComponent';
    var app = angular.module('app');

    function controller($window, $scope, $uibModal, commonLookupSvc, panelAccessSvc, countries) {
        function init() {
            vm.windowSize = $window.innerWidth;
            getPanelLocationsByType();
            getSupportedRegionSubdivisionsByTenant();
            getAllActiveOrganizationServices();
            getTenantCountryCode();
        }

        var vm = this;
        vm.loading = 0;
        vm.enqueuing = 0;
        vm.showRule = false;
        vm.isGeneralPolicy = false;

        vm.activeTab = 0;

        vm.permissions = {
            edit: abp.auth.isGranted('Corporate.PanelAccess.AccessPolicy.CreateAndEdit')
        };

        $window.onresize = function () {
            vm.windowSize = $window.innerWidth;
        }

        // Localization

        var countryLocationExamples = {
            "Malaysia": ["Petaling Jaya", "SS2, Petaling Jaya"],
            "Singapore": ["Bukit Merah", "Tiong Bahru"]
        };

        function getCountryNameByCode(countryCode) {
            var country = _.filter(countries, x => x.code === countryCode)[0];

            return country.name;
        }

        function getAccessRuleLocalizations() {
            var countryName = getCountryNameByCode(vm.countryCode);
            var locations = countryLocationExamples[countryName];

            vm.accessRuleExample1 = App.localize('AccessRuleExample1', locations[0]);
            vm.accessRuleExample2 = App.localize('AccessRuleExample2', locations[1]);
            vm.accessRuleExample3 = App.localize('AccessRuleExample3', countryName, locations[0]);
        }

        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

        // Feature flags

        vm.applicationHasCorporateInsurance = abp.setting.getBoolean('Hms.Feature.InsuranceModule');
        vm.hasSingaporePanelPricing = abp.setting.getBoolean('Hms.Feature.SingaporePanelPricing');

        vm.hasInpatientModule = abp.features.isEnabled('HasInpatientModule');

        vm.benefitCoverageTooltip = function (benefitCoverege, panel) {
            if (benefitCoverege) {
                return App.localize('PanelProvided', panel);
            } else {
                return App.localize('PanelNotProvided', panel);
            }
        };

        vm.getpriceControlText = function (priceControl) {
            switch (priceControl.mode) {
                case 1:
                    vm.priceControlCategory = '';
                    if (priceControl.value === '1') vm.priceControlCategory = App.localize('Essential');
                    if (priceControl.value === '2') vm.priceControlCategory = App.localize('Preferred');
                    if (priceControl.value === '3') vm.priceControlCategory = App.localize('PreferredPlus');
                    if (priceControl.value === '4') vm.priceControlCategory = App.localize('Premium');
                    return App.localize('AllowPriceControlCategoryMessage', vm.priceControlCategory);

                case 2:
                    return App.localize('AllowPriceControlAmountMessage', vm.currencyCode + priceControl.value);
            }
        };

        vm.getRulesDefinedMessage = function (ruleCount) {
            return App.localize('RulesDefinedMessage', ruleCount);
        };

        vm.getScopeValues = function (name) {
            return name === ""
                ? App.localize('AllLocation')
                : name.length > 50
                    ? name.substring(0, 50) + '...'
                    : name;
        };

        // End Localization

        var newPolicyId = 0;

        function switchPolicy(changesObj) {
            // Initial load
            vm.windowSize = $window.innerWidth;
            if (changesObj.policyId && !changesObj.policyId.currentValue && changesObj.cloneBenefitGroups
                && !changesObj.cloneBenefitGroups.currentValue && changesObj.isCopy && !changesObj.isCopy.currentValue) {
                return;
            }

            // Same policy, from view/edit to clone.

            if (!changesObj.policyId && changesObj.isCopy && changesObj.isCopy.currentValue) {
                newPolicyId = changesObj.policyId ? changesObj.policyId.currentValue : vm.policyId;
                getAccessPolicy(newPolicyId);
            }

            // Same policy, from clone to view.

            if (!changesObj.policyId && changesObj.isCopy && !changesObj.isCopy.currentValue) {
                newPolicyId = changesObj.policyId ? changesObj.policyId.currentValue : vm.policyId;
                getAccessPolicy(newPolicyId);
            }

            // Changed from policy to another policy.

            if (!changesObj.isCopy && changesObj.policyId && changesObj.policyId.previousValue !== changesObj.policyId.currentValue) {
                if (vm.isCopy && !changesObj.policyId.currentValue) return;

                newPolicyId = changesObj.policyId.currentValue;
                getAccessPolicy(newPolicyId);
            }

            // From view/edit policy to clone policy, different policy.

            if (changesObj.isCopy && changesObj.policyId && changesObj.policyId.previousValue !== changesObj.policyId.currentValue) {
                newPolicyId = changesObj.policyId.currentValue;
                getAccessPolicy(newPolicyId);
            }
        }

        function getAccessPolicy(policyId) {
            vm.loading += 1;
            if (policyId) {
                panelAccessSvc.getAccessPolicyConfiguration({
                    id: policyId
                }).success(function (data) {
                    vm.policy = data;

                    formatBenefitGroupCoverage(vm.policy.benefitGroups);
                    formatInpatientBenefitGroupCoverage(vm.policy.inpatientBenefitGroups);
                    formatInsurancePlansCoverage(vm.policy.insurancePlans);
                    sortAccessRule();
                    updateScopeValues();
                    updateCoverageServiceType();
                    
                    vm.isGeneralPolicy = vm.policy.type === 1; // Policy type: 1 - General, 2 - Custom
                    vm.isEdit = false;
                    vm.active = 1;
                    var policyServiceTypes = [];
                    var allowAll, allowAllCount, blockAll, blockAllCount, allowSpecific, blockSpecific;

                    vm.policy.accessRules.forEach(accessRule => policyServiceTypes.push(accessRule.serviceType));

                    // Loops through all policy access rules and ensures that the panel count is equal to the number of results shown when searching for panels

                    for (let policyServiceType of policyServiceTypes) {
                        for (let accessRule of vm.policy.accessRules) {
                            if (accessRule.serviceType == policyServiceType) {
                                allowAll = false;
                                allowAllCount = 0;
                                blockAll = false;
                                blockAllCount = 0;

                                for (let rule of accessRule.rules) {
                                    allowSpecific = false;
                                    blockSpecific = false;

                                    switch (true) {
                                        case (rule.scope == 1 && rule.controlMode == 1):
                                            allowAll = true;
                                            break;
                                        case (rule.scope == 1 && rule.controlMode == 2):
                                            blockAll = true;
                                            break;
                                        case (rule.scope == 4 && rule.controlMode == 1):
                                            allowSpecific = true;
                                            break;
                                        case (rule.scope == 4 && rule.controlMode == 2):
                                            blockSpecific = true;
                                            break;
                                    }

                                    if (allowAll && allowAllCount < 1) {
                                        accessRule.panelLocationCount = vm.panelLocationByServiceType[policyServiceType].length;
                                        rule.panelLocationCount = vm.panelLocationByServiceType[policyServiceType].length;
                                        allowAllCount += 1;
                                    }

                                    if (blockAll && blockAllCount < 1) {
                                        accessRule.panelLocationCount = 0;
                                        rule.panelLocationCount = vm.panelLocationByServiceType[policyServiceType].length;
                                        blockAllCount += 1;
                                    }

                                    if (allowSpecific) {
                                        if (allowAll) {
                                            continue;
                                        }
                                        else if (blockAll) {
                                            accessRule.panelLocationCount += rule.panelLocationCount;
                                        }
                                        else {
                                            accessRule.panelLocationCount = 0;
                                            accessRule.panelLocationCount += rule.panelLocationCount;
                                        }
                                    }

                                    if (blockSpecific) {
                                        if (blockAll) {
                                            continue;
                                        }
                                        else if (allowAll) {
                                            accessRule.panelLocationCount -= rule.panelLocationCount;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    if (vm.isCopy) {
                        vm.isEdit = true;
                        vm.policy.id = null;
                        vm.policy.policyName = null;
                        vm.policy.benefitGroups = vm.cloneBenefitGroups;
                        getBenefitGroups();
                        updateAccessRulesByBenefitGroup();
                        vm.policy.inpatientBenefitGroups = vm.cloneInpatientBenefitGroups;
                        getInpatientBenefitGroups();
                        updateInpatientAccessRulesByBenefitGroup();
                        if (vm.applicationHasCorporateInsurance) {
                            vm.policy.insurancePlans = vm.cloneInsurancePlans;
                            getInsurancePlans();
                            updateInpatientAccessRulesByBenefitGroup();
                        }
                        vm.updatePolicyId({ id: null });
                    }
                }).finally(function () {
                    vm.loading -= 1;
                });
            } else {
                vm.policy = {
                    id: null,
                    policyName: '',
                    benefitGroups: [],
                    inpatientBenefitGroups: [],
                    insurancePlans: [],
                    accessRules: []
                };
                vm.isEdit = true;
                vm.isGeneralPolicy = false;
                getBenefitGroups();
                getInpatientBenefitGroups();
                getInsurancePlans();
                updateCoverageServiceType();

                vm.loading -= 1;
            }
        }

        vm.benefitGroups = [];
        vm.filteredBenefitGroups = [];
        function getBenefitGroups() {
            vm.loading++;
            panelAccessSvc.getBenefitGroups().success(function (data) {
                vm.benefitGroups = _.filter(data.items, function (benefitGroup) {
                    return !benefitGroup.policyId || benefitGroup.policyId === vm.policy.id;
                });
                formatBenefitGroupCoverage(vm.benefitGroups);
                refreshBenefitGroups();
            }).finally(function () {
                vm.loading--;
            });
        }

        vm.inpatientBenefitGroups = [];
        vm.filteredInpatientBenefitGroups = [];
        function getInpatientBenefitGroups() {
            vm.loading++;
            panelAccessSvc.getInpatientBenefitGroups().success(function (data) {
                vm.inpatientBenefitGroups = _.filter(data.items, function (benefitGroup) {
                    return !benefitGroup.policyId || benefitGroup.policyId === vm.policy.id;
                });
                formatInpatientBenefitGroupCoverage(vm.inpatientBenefitGroups);
                refreshInpatientBenefitGroups();
            }).finally(function () {
                vm.loading--;
            });
        }

        vm.insurancePlans = [];
        vm.filteredInsurancePlans = [];
        function getInsurancePlans() {
            vm.loading++;
            panelAccessSvc.getInsurancePlans().success(function (data) {
                vm.insurancePlans = _.filter(data.items, function (benefitGroup) {
                    return !benefitGroup.policyId || benefitGroup.policyId === vm.policy.id;
                });
                formatInsurancePlansCoverage(vm.insurancePlans);
                refreshInsurancePlans();
            }).finally(function () {
                vm.loading--;
            });
        }

        // Edit policy

        vm.editPanelAccessPolicy = function () {
            getBenefitGroups();
            getInpatientBenefitGroups();
            getInsurancePlans();
            vm.isEdit = true;
        };

        // Benefit group and access rule panel type update

        function formatBenefitGroupCoverage(benefitGroups) {
            _.forEach(benefitGroups, function (benefitGroup) {
                benefitGroup.coverage.gpTooltip = vm.benefitCoverageTooltip(benefitGroup.coverage.gp, App.localize('GP'));
                benefitGroup.coverage.dentalTooltip = vm.benefitCoverageTooltip(benefitGroup.coverage.dental, App.localize('Dental'));
                benefitGroup.coverage.opticalTooltip = vm.benefitCoverageTooltip(benefitGroup.coverage.optical, App.localize('Optical'));
                benefitGroup.coverage.specialistTooltip = vm.benefitCoverageTooltip(benefitGroup.coverage.specialist, App.localize('Specialist'));
                benefitGroup.coverage.healthScreeningTooltip = vm.benefitCoverageTooltip(benefitGroup.coverage.healthScreening, App.localize('HealthScreening'));
            });
        }

        function formatInpatientBenefitGroupCoverage(benefitGroups) {
            _.forEach(benefitGroups, function (benefitGroup) {
                benefitGroup.coverage.hospitalizationTooltip = vm.benefitCoverageTooltip(benefitGroup.coverage.hospitalization, App.localize('Hospitalization'));
                benefitGroup.coverage.maternityTooltip = vm.benefitCoverageTooltip(benefitGroup.coverage.maternity, App.localize('Maternity'));
            });
        }

        function formatInsurancePlansCoverage(insurancePlans) {
            _.forEach(insurancePlans, function (insurancePlan) {
                insurancePlan.coverage.insurancePlanTooltip = vm.benefitCoverageTooltip(insurancePlan.coverage.hospitalization, App.localize('InsurancePlan'));
            });
        }

        vm.updateBenefitGroup = function () {
            if (vm.selectedBenefitGroup) {
                vm.policy.benefitGroups.push(vm.selectedBenefitGroup);
                updateAccessRulesByBenefitGroup();
                refreshBenefitGroups();
            }
        };

        vm.updateInpatientBenefitGroup = function () {
            if (vm.selectedInpatientBenefitGroup) {
                if (vm.selectedInpatientBenefitGroup.coverage.hospitalization && !_.includes(vm.activeOrganizationServices, 7)) {
                    abp.message.warn(App.localize('HospitalizationServiceNotActive'));
                    vm.selectedInpatientBenefitGroup = null;
                    return;
                } else if (vm.selectedInpatientBenefitGroup.coverage.maternity && !_.includes(vm.activeOrganizationServices, 8)) {
                    abp.message.warn(App.localize('MaternityServiceNotActive'));
                    vm.selectedInpatientBenefitGroup = null;
                    return;
                }

                vm.policy.inpatientBenefitGroups.push(vm.selectedInpatientBenefitGroup);
                updateInpatientAccessRulesByBenefitGroup();
                refreshInpatientBenefitGroups();
            }
        };

        vm.updateInsurancePlans = function () {
            if (vm.selectedInsurancePlan) {
                if (vm.selectedInsurancePlan.coverage.hospitalization && !_.includes(vm.activeOrganizationServices, 7)) {
                    abp.message.warn(App.localize('HospitalizationServiceNotActive'));
                    vm.selectedInsurancePlan = null;
                    return;
                }

                vm.policy.insurancePlans.push(vm.selectedInsurancePlan);
                updateAccessRulesByInsurancePlans();
                refreshInsurancePlans();
            }
        }

        function refreshBenefitGroups() {
            vm.filteredBenefitGroups = _.filter(vm.benefitGroups, function (bg) {
                return !_.some(vm.policy.benefitGroups, function (pbg) {
                    return pbg.id === bg.id;
                });
            });
        }

        function refreshInpatientBenefitGroups() {
            vm.filteredInpatientBenefitGroups = _.filter(vm.inpatientBenefitGroups, function (bg) {
                return !_.some(vm.policy.inpatientBenefitGroups, function (pbg) {
                    return pbg.id === bg.id;
                });
            });
        }

        function refreshInsurancePlans() {
            vm.filteredInsurancePlans = _.filter(vm.insurancePlans, function (iP) {
                return !_.some(vm.policy.insurancePlans, function (pip) {
                    return pip.id === iP.id;
                });
            });
        }

        vm.deleteBenefitGroup = function (benefitGroup) {
            abp.message.confirm(App.localize('DeletePolicyBenefitGroupConfirmMessage'), App.localize('AreYouSure'), function (d) {
                if (d) {
                    vm.policy.benefitGroups = _.without(vm.policy.benefitGroups, benefitGroup);
                    updateAccessRulesByBenefitGroup();
                    refreshBenefitGroups();
                }
            });
        };

        vm.deleteInpatientBenefitGroup = function (benefitGroup) {
            abp.message.confirm(App.localize('DeletePolicyBenefitGroupConfirmMessage'), App.localize('AreYouSure'), function (d) {
                if (d) {
                    vm.policy.inpatientBenefitGroups = _.without(vm.policy.inpatientBenefitGroups, benefitGroup);
                    updateInpatientAccessRulesByBenefitGroup();
                    refreshInpatientBenefitGroups();
                }
            });
        };

        vm.deleteInsurancePlan = function (insurancePlan) {
            abp.message.confirm(App.localize('DeletePolicyInsurancePlanConfirmMessage'), App.localize('AreYouSure'), function (d) {
                if (d) {
                    vm.policy.insurancePlans = _.without(vm.policy.insurancePlans, insurancePlan);
                    updateAccessRulesByInsurancePlans();
                    refreshInsurancePlans();
                }
            });
        };

        function updateAccessRulesByBenefitGroup() {
            var benefitCoverages = _.mapValues(vm.policy.benefitGroups, function (pbg) { return pbg.coverage; });
            if (_.some(benefitCoverages, { 'gp': true }) && !_.includes(vm.coverageServiceTypes, 1)) {
                createAccessRules(1);
            } else if (!_.some(benefitCoverages, { 'gp': true }) && _.includes(vm.coverageServiceTypes, 1)) {
                deleteAccessRules(1);
            }
            if (_.some(benefitCoverages, { 'dental': true }) && !_.includes(vm.coverageServiceTypes, 2)) {
                createAccessRules(2);
            } else if (!_.some(benefitCoverages, { 'dental': true }) && _.includes(vm.coverageServiceTypes, 2)) {
                deleteAccessRules(2);
            }
            if (_.some(benefitCoverages, { 'optical': true }) && !_.includes(vm.coverageServiceTypes, 3)) {
                createAccessRules(3);
            } else if (!_.some(benefitCoverages, { 'optical': true }) && _.includes(vm.coverageServiceTypes, 3)) {
                deleteAccessRules(3);
            }
            if (_.some(benefitCoverages, { 'specialist': true }) && !_.includes(vm.coverageServiceTypes, 5)) {
                createAccessRules(5);
            } else if (!_.some(benefitCoverages, { 'specialist': true }) && _.includes(vm.coverageServiceTypes, 5)) {
                deleteAccessRules(5);
            }
            if (_.some(benefitCoverages, { 'healthScreening': true }) && !_.includes(vm.coverageServiceTypes, 9)) {
                createAccessRules(9);
            } else if (!_.some(benefitCoverages, { 'healthScreening': true }) && _.includes(vm.coverageServiceTypes, 9)) {
                deleteAccessRules(9);
            }
        }

        function updateInpatientAccessRulesByBenefitGroup() {
            var inpatientBenefitCoverages = _.mapValues(vm.policy.inpatientBenefitGroups, function (pibg) { return pibg.coverage; });
            if (_.some(inpatientBenefitCoverages, { 'hospitalization': true }) && !_.includes(vm.coverageServiceTypes, 7) && _.includes(vm.activeOrganizationServices, 7)) {
                createAccessRules(7);
            } else if (!_.some(inpatientBenefitCoverages, { 'hospitalization': true }) && _.includes(vm.coverageServiceTypes, 7)) {
                deleteAccessRules(7);
            }
            if (_.some(inpatientBenefitCoverages, { 'maternity': true }) && !_.includes(vm.coverageServiceTypes, 8) && _.includes(vm.activeOrganizationServices, 8)) {
                createAccessRules(8);
            } else if (!_.some(inpatientBenefitCoverages, { 'maternity': true }) && _.includes(vm.coverageServiceTypes, 8)) {
                deleteAccessRules(8);
            }
        }

        function updateAccessRulesByInsurancePlans() {
            var insurancePlanCoverages = _.mapValues(vm.policy.insurancePlans, function (pip) { return pip.coverage; });
            if (_.some(insurancePlanCoverages, { 'hospitalization': true }) && !_.includes(vm.coverageServiceTypes, 7) && _.includes(vm.activeOrganizationServices, 7)) {
                createAccessRules(7);
            } else if (!_.some(insurancePlanCoverages, { 'hospitalization': true }) && _.includes(vm.coverageServiceTypes, 7)) {
                deleteAccessRules(7);
            }
        }

        vm.activeOrganizationServices = [];
        function getAllActiveOrganizationServices() {
            commonLookupSvc.getActiveOrganizationServices({
            }).success(function (res) {
                vm.activeOrganizationServices = res;
                vm.hasGP = _.includes(vm.activeOrganizationServices, 1);
                vm.hasDental = _.includes(vm.activeOrganizationServices, 2);
                vm.hasOptical = _.includes(vm.activeOrganizationServices, 3);
                vm.hasSpecialist = _.includes(vm.activeOrganizationServices, 5);
                vm.hasHealthScreening = _.includes(vm.activeOrganizationServices, 9);
            });
        }

        vm.coveragePanelTypes = [];
        function updateCoveragePanelType() {
            vm.coveragePanelTypes = _.mapValues(vm.policy.accessRules, function (accessRule) {
                return accessRule.panelType;
            });
        }

        vm.coverageServiceTypes = [];
        function updateCoverageServiceType() {
            vm.coverageServiceTypes = _.mapValues(vm.policy.accessRules, function (accessRule) {
                return accessRule.serviceType;
            });
        }

        function createAccessRules(type) {
            var locations = vm.panelLocationByServiceType[type];
            var activeLocations = _.filter(locations, 'isActive');

            // Inserting panel type for backward compatability for Corporate Service
            var panelType = (type == 7 || type == 8)
                ? 7
                : type;

            var accessRule = {
                panelType: panelType,
                serviceType: type,
                panelLocationCount: activeLocations.length,
                rules: [{
                    controlMode: 1,
                    scope: 1,
                    panelLocationCount: activeLocations.length,
                    scopeValues: [],
                    scopeValuesName: ''
                }]
            };

            updateScopeValues();
            vm.policy.accessRules.push(accessRule);
            sortAccessRule();
            updateCoverageServiceType();
        }

        function deleteAccessRules(type) {
            vm.policy.accessRules.splice(_.findIndex(vm.policy.accessRules, { 'serviceType': type }), 1);
            updateCoverageServiceType();
        }

        function sortAccessRule() {
            vm.policy.accessRules = _.sortBy(vm.policy.accessRules, ['serviceType']);
        }

        vm.sortRules = function (type, rules) {
            vm.active = type;
            vm.policy.accessRules[_.findIndex(vm.policy.accessRules, { 'serviceType': type })].rules = _.sortBy(rules, ['scope']);
        };

        // End Benefit group and access rule panel type update

        // Policy rule

        vm.addRule = function (type) {
            updateScopeValuesFilter(type, null);
            var rule = {
                scope: vm.hasAllScopeSet ? 4 : 1,
                scopeValues: [],
                scopeValuesName: "",
                panelLocationCount: 0,
                controlMode: 1,
                priceControl: {}
            };

          var modal = $uibModal.open({
            templateUrl: require('/App/corporate/views/panelAccesses/policyRule/policyRule.modal.html'),
            controller: 'corporate.views.panelAccesses.policyRuleModal as vm',
            size: 'lg',
            backdrop: 'static',
            resolve: {
              panelType: null,
              serviceType: type,
              rule: rule,
              states: function () {
                return vm.filteredSubdivisions;
              },
              cities: function () {
                return vm.filteredRegions;
              },
              panelLocation: function () {
                return vm.filteredPanelLocation;
              },
              hasAllScopeSet: function () {
                return vm.hasAllScopeSet;
              },
              isEdit: false,
              countryCode: function () {
                return vm.countryCode;
              }
            }
          });

            modal.result.then(function (result) {
                vm.policy.accessRules[_.findIndex(vm.policy.accessRules, { 'serviceType': type })].rules.push(result.rule);
                vm.sortRules(type, vm.policy.accessRules[_.findIndex(vm.policy.accessRules, { 'serviceType': type })].rules);
                updateAccessRuleLocationCount(vm.policy.accessRules[_.findIndex(vm.policy.accessRules, { 'serviceType': type })]);
            });
        };

        vm.editRule = function (type, rule) {
            updateScopeValuesFilter(type, rule);

            var modal = $uibModal.open({
                templateUrl: require('/App/corporate/views/panelAccesses/policyRule/policyRule.modal.html'),
                controller: 'corporate.views.panelAccesses.policyRuleModal as vm',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                    serviceType: type,
                    panelType: null,
                    rule: rule,
                    states: function () {
                        return vm.filteredSubdivisions;
                    },
                    cities: function () {
                        return vm.filteredRegions;
                    },
                    panelLocation: function () {
                        return vm.filteredPanelLocation;
                    },
                    hasAllScopeSet: function () {
                        return vm.hasAllScopeSet;
                    },
                    isEdit: true,
                    countryCode: function () {
                        return vm.countryCode;
                    }
                }
            });

            modal.result.then(function (result) {
                angular.extend(rule, result.rule);
                vm.sortRules(type, vm.policy.accessRules[_.findIndex(vm.policy.accessRules, { 'serviceType': type })].rules);
                updateAccessRuleLocationCount(vm.policy.accessRules[_.findIndex(vm.policy.accessRules, { 'serviceType': type })]);
            });
        };

        vm.deleteRule = function (type, rule) {
            abp.message.confirm(App.localize('DeletePolicyRuleConfirmMessage'), App.localize('AreYouSure'), function (d) {
                if (d) {
                    var accessRuleIndex = _.findIndex(vm.policy.accessRules, { 'serviceType': type });
                    vm.policy.accessRules[accessRuleIndex].rules = _.without(vm.policy.accessRules[accessRuleIndex].rules, rule);
                    updateAccessRuleLocationCount(vm.policy.accessRules[_.findIndex(vm.policy.accessRules, { 'serviceType': type })]);
                }
            });
        };

        function updateAccessRuleLocationCount(accessRules) {
            panelAccessSvc.getPanelLocationCount({
                rules: accessRules.rules,
                serviceType: accessRules.serviceType
            }).success(function (count) {
                accessRules.panelLocationCount = count;
            });
        }

        // Process city and state

        vm.subdivisions = [];
        vm.regions = [];

        function getSupportedRegionSubdivisionsByTenant() {
            commonLookupSvc.getSupportedRegionSubdivisionsByTenant().success(function (data) {
                vm.regions = _.uniq(_.map(data.items, function (item) { return item.region + ' (' + item.subdivision + ')'; }));
                vm.subdivisions = _.uniq(_.map(data.items, function (item) { return item.subdivision; }));
            });
        }

        function decodeCityState(value) {
            var cityState = value.split(":");
            return cityState[1] + " (" + cityState[0] + ")";
        }

        // End Process city and state

        // Process location by panel type

        function updateScopeValues() {
            if (vm.policy && vm.panelLocationByServiceType) {
                vm.policy.accessRules.forEach(function (accessRule) {
                    var panelLocation = vm.panelLocationByServiceType[accessRule.serviceType];
                    accessRule.rules.forEach(function (rule) {
                        if (rule.scope === 3) {
                            rule.scopeValuesName = _.map(rule.scopeValues, decodeCityState).join(", ");
                        } else if (rule.scope === 4) {
                            rule.scopeValues = _.map(rule.scopeValues, function (value) { return parseInt(value); });
                            rule.scopeValuesName = _.map(_.filter(panelLocation, function (pl) {
                                return _.some(rule.scopeValues, function (value) { return value === pl.value; });
                            }), function (pl) {
                                if (pl.isDeleted)
                                    return pl.name + " (" + App.localize('Deleted') + ")";
                                else if (!pl.isActive)
                                    return pl.name + " (" + App.localize('IsNotActive') + ")";
                                else
                                    return pl.name;
                            }).join(', ');
                        } else {
                            rule.scopeValuesName = _.join(rule.scopeValues, ", ");
                        }
                    });
                });
            }
        }

        vm.panelLocationByPanelType = [];
        vm.panelLocationByServiceType = [];
        function getPanelLocationsByType() {
            vm.loading += 1;
            panelAccessSvc.getPanelLocationsByType()
                .success(function (data) {
                    vm.panelLocationByServiceType = data;
                    updateScopeValues();
                    
                    vm.$onChanges = switchPolicy;
                    if (vm.policyId)
                        getAccessPolicy(vm.policyId);
                })
                .finally(() => {
                    vm.loading -= 1;
                })
        }

        // End Process location by panel type

        vm.filteredSubdivisions = [];
        vm.filteredRegions = [];
        vm.filteredPanelLocation = [];
        vm.hasAllScopeSet = false;
        function updateScopeValuesFilter(type, currentRule) {
            var rules = vm.policy.accessRules[_.findIndex(vm.policy.accessRules, { 'serviceType': type })].rules;

            var stateScopeValues = _.flatMap(_.filter(rules, { 'scope': 2 }), function (rule) {
                if (rule === currentRule) return;
                return rule.scopeValues;
            });
            vm.filteredSubdivisions = _.difference(vm.subdivisions, stateScopeValues);

            var citiesScopeValues = _.flatMap(_.filter(rules, { 'scope': 3 }), function (rule) {
                if (rule === currentRule) return;
                return _.map(rule.scopeValues, decodeCityState);
            });
            vm.filteredRegions = _.difference(vm.regions, citiesScopeValues);

            var panelLocationScopeValues = _.flatMap(_.filter(rules, { 'scope': 4 }), function (rule) {
                if (rule === currentRule) return;
                return rule.scopeValues;
            });
            vm.filteredPanelLocation = _.filter(vm.panelLocationByServiceType[type], function (pl) {
                return !_.some(panelLocationScopeValues, function (value) {
                    return value === pl.value;
                });
            });
            vm.filteredPanelLocation = _.orderBy(vm.filteredPanelLocation, ['isActive'], ['desc']);
            vm.hasAllScopeSet = _.some(rules, function (rule) { return rule.scope === 1; });
        }

        // End policy rule

        vm.save = function () {
            if (!vm.validateForm()) {
                abp.notify.error(App.localize('GeneralInvalidFormInputError'));
                return;
            }

            vm.saving++;
            if (vm.policyId === null) {
                panelAccessSvc.create(vm.policy)
                    .success(function (data) {
                        vm.updatePolicyId({ id: data });
                        abp.notify.info(App.localize('SuccessfullySaved'));
                    }).finally(function () {
                        vm.isEdit = false;
                        vm.getPolicies();

                        vm.saving--;
                    });
            } else {
                panelAccessSvc.update(vm.policy)
                    .success(function () {
                        abp.notify.info(App.localize('SuccessfullySaved'));
                    }).finally(function () {
                        vm.isEdit = false;
                        vm.getPolicies();

                        vm.saving--;
                    });
            }
            vm.isEdit = false;
            // HACK
            angular.element('[name="panelAccessPolicyForm"]').scope().panelAccessPolicyForm.$setPristine();
            vm.saving--;
        };

        vm.validateForm = function () {
            var form = $scope.panelAccessPolicyForm;
            App.touchFormErrors(form);
            return form.$valid;
        };

        vm.cancel = function () {
            vm.isEdit = false;
            angular.element('[name="panelAccessPolicyForm"]').scope().panelAccessPolicyForm.$setPristine();
            getAccessPolicy(vm.policyId);
        };

        vm.downloadPanelListing = function () {
            vm.enqueuing += 1;
            panelAccessSvc.enqueueGeneratePanelListPdfJob({ id: vm.policyId })
                .success(() => {
                    vm.refresh();
                })
                .finally(() => {
                    vm.enqueuing -= 1;
                })
        };

        // End edit policy

        function getTenantCountryCode() {
            vm.loading++;
            commonLookupSvc.getTenantCountryCode()
                .success(function (data) {
                    vm.countryCode = data;
                }).finally(function () {
                    vm.loading--;
                    getAccessRuleLocalizations();
                });
        }

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/corporate/views/panelAccesses/panelAccessPolicy/panelAccessPolicy.component.html'),
        controller: ['$window', '$scope', '$uibModal', 'abp.services.app.commonLookup', 'abp.services.app.panelAccess', 'countries', controller],
        controllerAs: 'vm',
        bindings: {
            policyId: '<',
            isCopy: '<',
            cloneBenefitGroups: '<',
            cloneInpatientBenefitGroups: '<',
            cloneInsurancePlans: '<',
            updatePolicyId: '&',
            getPolicies: '&',
			refresh: '=?'
        }
    });
})();
