(() => {
  angular
    .module('finance')
    .controller('manualTransactionSetupCreateEditTermsAndConditions', ManualTransactionSetupCreateEditTermsAndConditionsController);

    ManualTransactionSetupCreateEditTermsAndConditionsController.$inject = [
    'isCreate',
    'termsAndConditions',
    'abp.services.finance.termsAndConditions',
    '$scope',
    '$uibModalInstance'
  ];

  function ManualTransactionSetupCreateEditTermsAndConditionsController(
    isCreate,
    termsAndConditions,
    termsAndConditionsSvc,
    $scope,
    $uibModalInstance
  ) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;
    vm.termsAndConditions = {
      id: termsAndConditions.id,
      accountOwnerId: termsAndConditions.accountOwnerId,
      name: termsAndConditions.name,
      details: termsAndConditions.details
    };

    vm.save = save;
    vm.cancel = cancel;

    function save() {
      const isValid = App.isFormValid($scope.createEditTermsAndConditions);
      if (isValid) {
        if (isCreate) {
          createTermsAndConditions();
        } else {
          updateTermsAndConditions();
        }
      }
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function createTermsAndConditions() {
      vm.saving += 1;
      termsAndConditionsSvc
        .createTermsAndConditions(vm.termsAndConditions)
        .finally(() => {
          $uibModalInstance.close();
          vm.saving -= 1;
        });
    }

    function updateTermsAndConditions() {
      vm.saving += 1;
      termsAndConditionsSvc
        .updateTermsAndConditions(vm.termsAndConditions)
        .finally(() => {
          $uibModalInstance.close();
          vm.saving -= 1;
        });
    }
  }
})();
