(() => {
  angular.module('app').component('commonHealthScreeningGuaranteeLettersDetailsLetterTimeline', {
    templateUrl: require('./letterTimeline.component.html'),
    controller: LetterTimelineController,
    controllerAs: 'vm',
    bindings: {},
  });

  LetterTimelineController.$inject = [
    '$stateParams',
    'abp.services.app.healthScreeningGuaranteeLetter',
  ];

  function LetterTimelineController($stateParams, healthScreeningGuaranteeLetterSvc) {
    const vm = this;

    vm.loading = 0;

    init();

    function init() {
      getLetterTimeline();
    }

    function getLetterTimeline() {
      vm.loading += 1;
      healthScreeningGuaranteeLetterSvc
        .getLetterTimeline({
          id: $stateParams.letterNumber,
        })
        .success((data) => {
          vm.timelineItems = data.items;
          _.each(vm.timelineItems, (item) => {
            const d = item;
            switch (d.subject) {
              case App.localize('Submitted'):
                d.icon = 'fa fa-upload text-warning';
                break;
              case App.localize('Approved'):
                d.icon = 'fa fa-check-circle text-navy';
                break;
              case App.localize('Extended'):
                d.icon = 'mdi mdi-alarm-plus text-navy';
                break;
              case App.localize('Utilized'):
                d.icon = 'far fa-clipboard text-navy';
                break;
              case App.localize('Rejected'):
                d.icon = 'fa fa-times-circle text-danger';
                break;
              case App.localize('Expired'):
                d.icon = 'far fa-clock text-danger';
                break;
              case App.localize('ExtensionRequested'):
                d.icon = 'fa fa-edit text-warning';
                break;
              case App.localize('UpdateGuaranteeLetter'):
                d.icon = 'fa fa-edit text-warning';
                break;
              case App.localize('CheckedIn'):
                d.icon = 'fas fa-sign-in-alt text-navy';
                break;
              case App.localize('TicketIssued'):
                d.icon = 'fas fa-ticket-alt text-navy';
                break;
              case App.localize('Cancelled'):
                d.icon = 'fa fa-ban text-danger';
                break;
              case App.localize('Adjustment'):
                d.icon = 'fas fa-users-cog text-warning';
                break;

              // no default
            }
          });
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
