(function () {
    var componentId = 'hostOperationsDashboardGuaranteeLettersComponent';
    var app = angular.module('app');

    function controller(operationsDashboardSvc, enumGuaranteeLetterStatus) {
        function init() {
            vm.refresh();
        }

        var vm = this;
        vm.widgetName = componentId;
        vm.loading = false;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.countryCode = null;
        vm.records = [];
        vm.requestParams = {
            filter: '',
            includesResolved: false,
            maxResultCount: 5,
            skipCount: 0,
            countryCode: vm.countryCode
        };

        vm.enums = {};
        vm.enums.guaranteeLetterStatus = enumGuaranteeLetterStatus;

        vm.$onInit = function () {
            vm.onAddWidget({ widget: this });
        };

        function setTotalCount() {
            var item = _.find(vm.widgets, function (data) {
                return data.id === componentId;
            });
            item.totalCount = vm.totalCount;
            item.loading = false;
        }

        function getGuaranteeLetters() {
            var skipCount = vm.requestParams.skipCount;
            if (skipCount === 0) {
                vm.records = [];
            }

            vm.loading++;
            operationsDashboardSvc.getGuaranteeLetters(vm.requestParams)
                .success(function (data) {
                    vm.totalCount = data.totalCount;
                    vm.records = _.uniqBy(vm.records.concat(data.items), 'id');
                    vm.hasMore = vm.records.length < vm.totalCount;
                    setTotalCount();
                }).finally(function () {
                    vm.loading--;
                });
        }

        vm.refresh = function () {
            vm.requestParams.skipCount = 0;
            vm.requestParams.countryCode = vm.countryCode;
            getGuaranteeLetters();
        };

        vm.loadMore = function () {
            if (!vm.isLoading && vm.hasMore) {
                vm.requestParams.skipCount += 5;
                getGuaranteeLetters();
            }
        };

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/operationsDashboard/guaranteeLetters/guaranteeLetters.component.html'),
        controller: ['abp.services.app.operationsDashboard', 'Hms.GuaranteeLetters.GuaranteeLetterStatus', controller],
        controllerAs: 'vm',
        bindings: {
            widgets: '<',
            onAddWidget: '&',
            countryCode: '=?'
        }
    });
})();
