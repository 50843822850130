(() => {
  angular
    .module('app')
    .controller(
      'common.views.patientTickets.detail.rejectLateSubmissionModal',
      RejectLateSubmissionModalController
    );

  RejectLateSubmissionModalController.$inject = ['$uibModalInstance', 'isDeletedEmployee'];

  function RejectLateSubmissionModalController($uibModalInstance, isDeletedEmployee) {
    const vm = this;

    vm.reason = isDeletedEmployee ? 'DeletedEmployee' : '';
    vm.remarks = '';
    vm.isDeletedEmployee = isDeletedEmployee;

    vm.cancel = cancel;
    vm.confirm = confirm;
    vm.tidyReason = tidyReason;

    init();

    function init() {}

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function confirm() {
      const reason = tidyReason();
      if (!reason) return;
      $uibModalInstance.close(reason);
    }

    function tidyReason() {
      let reason = _.trim(vm.reason);
      if (reason === 'Others') {
        const remarks = _.trim(vm.remarks);
        if (remarks) {
          reason += `: ${remarks}`;
        } else {
          reason = '';
        }
      }
      return reason;
    }
  }
})();
