(function () {
    'use strict';

    angular
        .module('app')
        .controller('host.views.panelLicensingFees', ['$stateParams', 'abp.services.app.licensingFee', controller]);

    function controller($stateParams, licensingFeeSvc) {
        var vm = this;
        vm.loading = 0;
        vm.panelId = $stateParams.panelId;

        vm.licensingFeeGridOptions = {
            appScopeProvider: vm,
            columnDefs: [{
                name: 'Actions',
                displayName: 'Actions',
                enableSorting: false,
                width: 80,
                headerCellTemplate: '<span></span>',
                cellTemplate: 'viewTemplate'
            }, {
                displayName: App.localize('Country'),
                field: 'countryName',
                minWidth: 80
            }, {
                displayName: App.localize('ServiceType'),
                field: 'serviceTypeName',
                minWidth: 50
            }, {
                displayName: App.localize('EffectiveDate'),
                field: 'effectiveDate',
                cellFilter: 'momentFormat: \'L\'',
                minWidth: 140
            }, {
                displayName: App.localize('CreatedByWithTime'),
                field: 'creationTime',
                enableSorting: false,
                cellTemplate: 'createdByWithTimeTemplate',
                minWidth: 140
            }, {
                displayName: App.localize('LastUpdateByWithTime'),
                field: 'lastModificationTime',
                enableSorting: false,
                cellTemplate: 'lastUpdateByWithTimeTemplate',
                minWidth: 140
            }],
            data: []
        };

        function getLicensingFees() {
            vm.loading++;
            licensingFeeSvc.getLicensingFees({ id: vm.panelId })
                .success(function (data) {
                    vm.licensingFeeGridOptions.data = data.items;
                    vm.panelName = data.panelName;
                }).finally(function () {
                    vm.loading--;
                });
        }

        function init() {
            getLicensingFees();
        }

        init();
    }
})();
