(function () {
    var componentId = 'hostPanelRecruitmentDashboardCurrentTotalStatsWidget';
    var app = angular.module('app');

    function controller(panelRecruitmentDashboardSvc) {
        var vm = this;
        vm.loading = 0;
        vm.result = {};

        function loadData() {
            vm.loading++;
            panelRecruitmentDashboardSvc.getCurrentRequestCounts()
                .success(function (data) {
                    vm.result = data;
                })
                .finally(function () {
                    vm.loading--;
                });
        }

        loadData();
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/panelRecruitment/dashboard/currentTotalStats/currentTotalStats.component.html'),
        controller: ['abp.services.app.panelRecruitmentDashboard', controller],
        controllerAs: 'vm'
    });
})();
