(() => {
  angular.module('app').controller('clinic.views.healthScreeningClaims', HealthScreeningClaimsPageController);

  HealthScreeningClaimsPageController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$timeout',
    'abp.services.app.commonLookup',
    'abp.services.app.healthScreeningClaim',
    'Hms.ServiceRequests.ServiceRequestType',
    'Hms.ServiceRequests.ServiceRequestStatus',
    'Hms.BillingStatus',
    'Hms.ServiceRequests.ServiceRequestQueryStatus',
    'moment',
    'appConstants',
    'Hms.PatientTicketBillings.BillStatus',
  ];

  function HealthScreeningClaimsPageController(
    $scope,
    $state,
    $stateParams,
    $timeout,
    commonLookupSvc,
    healthScreeningClaimSvc,
    enumServiceRequestType,
    enumServiceRequestStatus,
    enumBillingStatus,
    enumServiceRequestQueryStatus,
    moment,
    appConstants,
    enumBillStatus
  ) {
    const vm = this;

    vm.hasAccessFinanceTransactionData = abp.setting.getBoolean(
      'Hms.Feature.AccessFinanceTransactionData'
    );

    vm.loading = 0;
    vm.isHost = App.isHost();

    vm.enums = {
      serviceRequestType: enumServiceRequestType,
      serviceRequestStatus: enumServiceRequestStatus,
      billingStatus: enumBillingStatus,
      serviceRequestQueryStatus: enumServiceRequestQueryStatus,
      billStatus: enumBillStatus,
    };

    vm.requestParams = {
      status: _.join($stateParams.claimStatus, ','),
      letterNumber: $stateParams.letterNumber,
      ticketNumber: $stateParams.ticketNumber,
      claimNumber: $stateParams.claimNumber,
      filter: $stateParams.filter || '',
      transactionNumber: $stateParams.transactionNumber,
      corporateBillingStatus: $stateParams.corporateBillingStatus,
      specialistBillingStatus: $stateParams.specialistBillingStatus,
      claimStartDate: $stateParams.claimStartDate,
      claimEndDate: $stateParams.claimEndDate,
      patient: $stateParams.patient,
      panelLocationId: $stateParams.panelLocationId,
      referenceNumber: $stateParams.referenceNumber,

      // Amount Filter: BilledAmount.
      minBilledAmount: parseFloat($stateParams.minBilledAmount) || null,
      maxBilledAmount: parseFloat($stateParams.maxBilledAmount) || null,

      organizationId: $stateParams.organizationId,
      skipCount: $stateParams.skipCount || 0,
      maxResultCount: $stateParams.maxResultCount || appConstants.grid.defaultPageSize,
      sorting: $stateParams.sorting,
      queryStatus: $stateParams.queryStatus,
      requestStatus: $stateParams.requestStatus,
      countryCode: $stateParams.countryCode,
    };
    vm.advancedFiltersAreShown = false;

    vm.dateRangeOptions = App.createDateRangePickerOptions();
    vm.dateRangeModel = {
      startDate: $stateParams.startDate ? moment($stateParams.claimStartDate) : null,
      endDate: $stateParams.endDate ? moment($stateParams.claimStartDate) : null,
    };

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSizes,
      paginationPageSize: appConstants.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      resizable: true,
      columnDefs: [
        {
          name: 'Actions',
          displayName: 'Actions',
          enableSorting: false,
          width: 75,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'viewTemplate',
        },
        {
          displayName: App.localize('ClaimNumber'),
          field: 'claimNumber',
          minWidth: 100,
        },
        {
          displayName: App.localize('ClaimDate'),
          field: 'claimDate',
          minWidth: 80,
        },
        {
          displayName: App.localize('GuaranteeLetterNumber'),
          field: 'guaranteeLetterNumber',
          minWidth: 100,
        },
        {
          displayName: App.localize('TicketNumber'),
          field: 'ticketNumber',
          visible: vm.isHost,
          minWidth: 100,
        },
        {
          displayName: App.localize('CreationTime'),
          field: 'ticketCreationTime',
          visible: vm.isHost,
          minWidth: 100,
          cellTemplate: 'creationTimeTemplate'
        },
        {
          displayName: App.localize('ClaimStatus'),
          field: 'status',
          minWidth: 100,
          cellTemplate: 'requestStatusTemplate',
        },
        {
          displayName: App.localize('QueryStatus'),
          field: 'latestQueryStatus',
          minWidth: 80,
          cellTemplate: 'latestQueryTemplate',
        },
        {
          displayName: App.localize('Aging'),
          field: 'aging',
          visible: vm.isHost,
          minWidth: 60,
          cellTemplate: 'agingTemplate',
        },
        {
          displayName: App.localize('ApprovalDate'),
          field: 'processedDate',
          visible: vm.isHost,
          minWidth: 100,
          cellTemplate: 'processedDateTemplate',
        },
        {
          displayName: App.localize('Patient'),
          field: 'patientName',
          minWidth: 120,
          cellTemplate: 'patientTemplate',
        },
        {
          displayName: App.localize('Subsidiary'),
          field: 'memberSubsidiaryName',
          visible: vm.isHost,
          minWidth: 120,
        },
        {
          displayName: App.localize('PanelLocation'),
          field: 'panelLocationName',
          visible: vm.isHost,
          minWidth: 120,
        },
        {
          displayName: App.localize('HospitalBillReferenceNo'),
          field: 'referenceNumber',
          minWidth: 100,
        },
        {
          displayName: App.localize('TotalClaimAmount'),
          field: 'totalClaimAmount',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          minWidth: 100,
        },
        {
          displayName: App.localize('TotalCoveredAmount'),
          field: 'totalCoveredAmount',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          minWidth: 100,
        },
        {
          name: App.localize('CorporateBillingStatus'),
          displayName: App.localize('CorporateBillingStatus'),
          field: 'organizationBillingStatus',
          visible: vm.isHost,
          minWidth: 100,
          cellTemplate: 'corporateBillingTemplate',
        },
        {
          name: App.localize('PanelBillingStatus'),
          displayName: App.localize('PanelBillingStatus'),
          field: 'panelBillingStatus',
          minWidth: 100,
          cellTemplate: 'panelBillingTemplate',
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          vm.requestParams.sorting =
            callback.length && callback[0].field
              ? `${callback[0].field} ${callback[0].sort.direction}`
              : null;
          getHealthScreeningClaims();
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, vm.getHealthScreeningClaims)
        );
      },
      data: [],
    };

    vm.getHealthScreeningClaims = getHealthScreeningClaims;
    vm.exportToExcel = exportToExcel;
    vm.getDateTimeString = getDateTimeString;

    init();

    function init() {
      getEligibleCountries();
      
      $timeout(() => {
        vm.gridOptions.paginationCurrentPage = Math.floor(
          vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1
        );
      });

      getHealthScreeningClaims();
    }

    function getHealthScreeningClaims() {
      const input = getRequestInput();

      vm.loading += 1;
      healthScreeningClaimSvc
        .getClaims(input)
        .success((data) => {
          vm.gridOptions.totalItems = data.totalCount;
          vm.gridOptions.data = data.items;

          vm.panelLocations = data.panelLocations;
          vm.organizations = data.organizations;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getEligibleCountries() {
      vm.loading += 1;
      commonLookupSvc
        .getEligibleCountries()
        .success((data) => {
          vm.countries = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getRequestInput() {
      const input = $.extend({}, vm.requestParams);
      input.claimStartDate = vm.dateRangeModel.startDate;
      input.claimEndDate = vm.dateRangeModel.endDate;

      input.requestStatus = [];
      if (vm.requestParams.requestStatus) {
        input.requestStatus.push(parseInt(vm.requestParams.requestStatus, 10));
      } else if ($stateParams.requestStatus) {
        input.requestStatus.push(parseInt($stateParams.requestStatus, 10));
      }

      if (!vm.isHost && _.isEqual(vm.requestParams.requestStatus, '99')) {
        // Needs attention (pending approval with query).

        input.requestStatus = [1];
        input.lateSubmissionOnly = false;
        input.hasQuery = true;
      }

      if (_.isEqual(vm.requestParams.requestStatus, '98')) {
        // Late submission.

        input.requestStatus = [1];
        input.lateSubmissionOnly = true;
        input.hasQuery = false;
      }

      registerStateParams();

      return input;
    }

    function registerStateParams() {
      const startDate = vm.dateRangeModel.startDate
        ? vm.dateRangeModel.startDate.format('YYYY-MM-DD')
        : null;
      const endDate = vm.dateRangeModel.endDate
        ? vm.dateRangeModel.endDate.format('YYYY-MM-DD')
        : null;

      $state.transitionTo(
        $state.current,
        {
          claimStatus: vm.requestParams.status ? _.split(vm.requestParams.status, ',') : null,
          letterNumber: vm.requestParams.letterNumber,
          ticketNumber: vm.requestParams.ticketNumber,
          claimNumber: vm.requestParams.claimNumber,
          transactionNumber: vm.requestParams.transactionNumber,
          corporateBillingStatus: vm.requestParams.corporateBillingStatus,
          panelBillingStatus: vm.requestParams.panelBillingStatus,
          claimStartDate: startDate,
          claimEndDate: endDate,
          patient: vm.requestParams.employee,
          disciplineId: vm.requestParams.disciplineId,
          referenceNumber: vm.requestParams.referenceNumber,
          minAmount: vm.requestParams.minBilledAmount,
          maxAmount: vm.requestParams.maxBilledAmount,
          skipCount: vm.requestParams.skipCount,
          maxResultCount: vm.requestParams.maxResultCount,
          sorting: vm.requestParams.sorting,
          panelLocationId: vm.requestParams.panelLocationId,
          organizationId: vm.requestParams.organizationId,
          queryStatus: vm.requestParams.queryStatus,
        },
        {
          notify: false,
        }
      );
    }

    function exportToExcel() {
      const input = getRequestInput();

      vm.loading += 1;
      healthScreeningClaimSvc
        .getClaimsToExcel(input)
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getDateTimeString(date, timeZoneId) {
      return App.getDateTimeString(date, timeZoneId);
    }
  }
})();
