(() => {
  angular.module('app').controller('common.views.languages.editTextModal', EditTextModalController);

  EditTextModalController.$inject = [
    '$uibModalInstance',
    'abp.services.app.language',
    'sourceName',
    'baseLanguageName',
    'languageName',
    'allTexts',
    'initialText',
  ];

  function EditTextModalController(
    $uibModalInstance,
    languageSvc,
    sourceName,
    baseLanguageName,
    languageName,
    allTexts,
    initialText
  ) {
    const vm = this;

    vm.sourceName = sourceName;
    vm.baseLanguageName = baseLanguageName;
    vm.languageName = languageName;
    vm.allTexts = allTexts;
    vm.currentText = initialText;
    vm.editingText = angular.extend({}, vm.currentText);
    vm.currentIndex = 0;
    vm.saving = 0;

    vm.findFlag = findFlag;
    vm.findDisplayName = findDisplayName;
    vm.saveAndNext = saveAndNext;
    vm.saveAndClose = saveAndClose;
    vm.previous = previous;
    vm.cancel = cancel;
    vm.targetValueKeyPress = targetValueKeyPress;

    init();

    function init() {
      for (let i = 0; i < allTexts.length; i += 1) {
        if (vm.allTexts[i] === vm.currentText) {
          vm.currentIndex = i;
          break;
        }
      }
    }

    function submit(close) {
      function callback() {
        if (close) {
          $uibModalInstance.close();
        } else {
          vm.currentIndex += 1;
          if (vm.allTexts.length <= vm.currentIndex) {
            $uibModalInstance.close();
            return;
          }
          vm.currentText = vm.allTexts[vm.currentIndex];
          vm.editingText = angular.extend({}, vm.currentText);
        }
      }

      if (vm.currentText.targetValue === vm.editingText.targetValue) {
        callback();
        return;
      }

      vm.saving += 1;

      languageSvc
        .updateLanguageText({
          sourceName: vm.sourceName,
          languageName: vm.languageName,
          key: vm.editingText.key,
          value: vm.editingText.targetValue,
        })
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          vm.currentText.targetValue = vm.editingText.targetValue;
          callback();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function findFlag(name) {
      const language = _.findWhere(abp.localization.languages, {
        name,
      });
      return language ? language.icon : '';
    }

    function findDisplayName(name) {
      const language = _.findWhere(abp.localization.languages, {
        name,
      });
      return language ? language.displayName : '';
    }

    function saveAndNext() {
      submit(false);
    }

    function saveAndClose() {
      submit(true);
    }

    function previous() {
      if (vm.currentIndex > 0) {
        vm.currentIndex -= 1;
        vm.currentText = vm.allTexts[vm.currentIndex];
        vm.editingText = angular.extend({}, vm.currentText);
      }
    }

    function targetValueKeyPress(n) {
      if (n.which === 34) {
        vm.saveAndNext();
      } else if (n.which === 33) {
        vm.previous();
      }
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
