(() => {
  angular.module('app').filter('currencyFormat', currencyFormat);

  currencyFormat.$inject = ['numberFilter', 'currencies'];

  function currencyFormat(numberFilter, currencies) {
    return currencyFormatFilter;

    function currencyFormatFilter(amount, currencyCode) {
      if (typeof amount === 'number' && (amount || amount === 0)) {
        const currency = _.find(currencies, {
          code: currencyCode,
        });
        return `${currencyCode} ${numberFilter(amount, currency ? currency.decimalPlaces : 2)}`;
      }
      return '';
    }
  }
})();
