(function () {
    var componentId = 'hostOperationsDashboardDuplicateTicketsDetail';
    var app = angular.module('app');

    function controller($state) {
        var vm = this;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.clinicLocation = vm.resolve.clinicLocation;

        vm.close = function () {
            vm.modalInstance.dismiss();
        };
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/operationsDashboard/duplicateTickets/detailModal.html'),
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['$state', controller],
        controllerAs: 'vm'
    });
})();
