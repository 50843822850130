(() => {
  angular
    .module('app')
    .component('commonPostHospitalizationGuaranteeLettersDetailsBenefitCoverageComponent', {
      templateUrl: require('./benefitCoverage.component.html'),
      controller: BenefitCoverageController,
      controllerAs: 'vm',
      bindings: {
        guaranteeLetterNumber: '<',
        isDetailPage: '<',
        currencyCode: '<',
      },
    });

  BenefitCoverageController.$inject = [
    '$sce',
    '$uibModal',
    '$filter',
    'abp.services.app.postHospitalizationGuaranteeLetter',
    'Hms.Services.ServiceType',
  ];

  function BenefitCoverageController(
    $sce,
    $uibModal,
    $filter,
    postHospitalizationGuaranteeLetterSvc,
    enumServiceType
  ) {
    const vm = this;
    vm.loading = 0;
    vm.countryCode = abp.setting.get('Hms.General.DefaultCountryCode');
    vm.defaultMaxAllowance = parseFloat(abp.setting.get('Hms.General.MaxAllowanceAmountLimit'));
    vm.isOpen = 1;
    vm.toggleOpen = toggleOpen;
    vm.illnessInfo = $sce.trustAsHtml(App.localize('SpecifiedIllnessTooltip'));

    const hospitalizationTitle = App.localize('Hospitalization');
    const maternityTitle = App.localize('Maternity');

    vm.hasTxtFileSupport = abp.setting.getBoolean('Hms.Feature.TxtFileSupport');
    vm.hasHospitalizationWaitingPeriodEnhancement = abp.setting.getBoolean(
      'Hms.Feature.HospitalisationWaitingPeriodEnhancement'
    );

    vm.enums = {};
    vm.enums.serviceType = enumServiceType;

    // TODO: Update permission

    vm.permissions = {
      update: abp.auth.isGranted('GuaranteeLetters.Host.Update'),
      topUp: abp.auth.isGranted('GuaranteeLetters.Host.TopUp'),
      extend: abp.auth.isGranted('GuaranteeLetters.Host.Approve'),
    };

    vm.viewExclusionList = viewExclusionList;
    vm.viewScheduleOfBenefits = viewScheduleOfBenefits;
    vm.$onInit = init;

    function init() {
      getBenefitByLetterNumber();
    }

    function toggleOpen() {
      vm.isOpen = !vm.isOpen;
    }

    function getBenefitByLetterNumber() {
      vm.loading += 1;
      postHospitalizationGuaranteeLetterSvc
        .getPatientBenefitsByLetterNumber({
          id: vm.guaranteeLetterNumber,
        })
        .success((data) => {
          vm.countryCode = data.countryCode;
          vm.benefit = data;
          setDetails();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function setDetails() {
      if (vm.benefit) {
        _.each(vm.benefit.inpatientBenefitGroups, (data) => {
          if (data.serviceType === 7) {
            data.title = hospitalizationTitle;
            data.settings = data.hospitalizationSetting;
            data.allowance = Math.min(
              data.hospitalizationSetting.allowance,
              vm.defaultMaxAllowance
            );
            data.balancePool = data.hospitalizationSetting.balancePool;
            data.coPay = data.hospitalizationSetting.coPays
              ? data.hospitalizationSetting.coPays[0]
              : null;
            data.hospitalizationBenefits = data.hospitalizationSetting.hospitalizationBenefits;
            data.displayBalancePool = [];

            if (data.balancePool && data.balancePool.length > 0) {
              _.each(data.balancePool, (pool) => {
                getDisplayBalancePool(pool, data.displayBalancePool);
              });
            }
          } else {
            data.title = maternityTitle;
            data.settings = data.maternitySetting;
            data.allowance = Math.min(data.maternitySetting.allowance, vm.defaultMaxAllowance);
            data.balancePool = data.maternitySetting.balancePool;
            data.coPay = data.maternitySetting.coPays ? data.maternitySetting.coPays[0] : null;
            data.displayBalancePool = [];

            if (data.balancePool && data.balancePool.length > 0) {
              _.each(data.balancePool, (pool) => {
                getDisplayBalancePool(pool, data.displayBalancePool);
              });
            }
          }

          if (data.coPay) {
            if (data.coPay.isPercentage) {
              if (data.settings.isCoInsurance) {
                data.coPay.title =
                  vm.hasTxtFileSupport && data.settings.coPayTypeName
                    ? `${data.settings.coPayTypeName.concat(' ', data.coPay.value)}%`
                    : App.localize('CoInsuranceX', `${data.coPay.value}%`);
                data.coPay.text = App.localize('CopayPercentage', `${data.coPay.value}%`);
              } else if (!data.settings.isCoInsurance) {
                data.coPay.title =
                  vm.hasTxtFileSupport && data.settings.coPayTypeName
                    ? `${data.settings.coPayTypeName.concat(' ', data.coPay.value)}%`
                    : App.localize('CopayX', `${data.coPay.value}%`);
                data.coPay.text = App.localize('CopayPercentage', `${data.coPay.value}%`);
              }
            } else {
              const valueText = $filter('currencyFormat')(data.coPay.value, vm.currencyCode);
              data.coPay.title =
                vm.hasTxtFileSupport && data.settings.coPayTypeName
                  ? data.settings.coPayTypeName.concat(' ', valueText)
                  : App.localize('CopayX', valueText);
              data.coPay.text = App.localize('CopayFixedAmount', valueText);
            }
          }
        });
      }
    }

    function getDisplayBalancePool(poolBalance, displayArray) {
      let title = '';

      if (_.head(poolBalance.benefitTypes).id === 7) title = hospitalizationTitle;
      else if (_.head(poolBalance.benefitTypes).id === 8) title = maternityTitle;

      // TODO: Better design? localisation required

      const isBudget = poolBalance.modifierType === 0 || poolBalance.modifierType === 2;
      let cycle = '';
      if (!poolBalance.isUnlimited) {
        switch (poolBalance.modifierCycle) {
          case 0:
            cycle = `every ${poolBalance.interval} years `;
            break;
          case 1:
            cycle = 'annual ';
            break;
          case 2:
            cycle = 'monthly ';
            break;
          case 3:
            cycle = 'daily ';
            break;
          case 4:
            cycle = 'per visit ';
            break;
          case 5:
            cycle = '6 months ';
            break;
          case 6:
            cycle = '4 months ';
            break;
          case 7:
            cycle = '3 months ';
            break;
          case 8:
            cycle = '2 months ';
            break;
          default:
            cycle = 'N/A';
            break;
        }
        if (poolBalance.modifierType === 2 || poolBalance.modifierType === 3) cycle += 'overdraft ';
      }
      title += ` (${cycle}${isBudget ? 'budget' : 'visit'})`;

      const isEmptyExcess = poolBalance.isExcess && !poolBalance.employeePortion;
      if (!isEmptyExcess) {
        displayArray.push({
          title,
          isUnlimited: poolBalance.isUnlimited,
          isExcess: poolBalance.isExcess,
          isBudget,
          limit: poolBalance.limit,
          used: poolBalance.used,
          locked: poolBalance.locked,
          balance: poolBalance.balance,
        });
      }
    }

    function viewExclusionList(inpatientSettings) {
      $uibModal.open({
        templateUrl: require('../../../../../../host/views/employees/exclusionList/exclusionList.modal.html'),
        controller: 'host.views.employees.exclusionListModal as vm',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          inpatientSettings,
        },
      });
    }

    function viewScheduleOfBenefits(serviceType, settings, employeeType) {
      $uibModal.open({
        templateUrl: require('../../../../../../host/views/employees/scheduleOfBenefits/scheduleOfBenefits.modal.html'),
        controller: 'host.views.employees.scheduleOfBenefitsModal as vm',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          serviceType,
          hospitalizationSetting: settings,
          maternitySetting: settings,
          employeeType,
          countryInfo: {
            countryCode: vm.countryCode,
            currencyCode: vm.currencyCode,
          },
        },
      });
    }

    init();
  }
})();
