import _ from 'lodash';

(() => {
  angular
    .module('finance')
    .controller('finance.views.saleTransactions.applyCreditMemo', ApplyCreditMemoController);

  ApplyCreditMemoController.$inject = [
    'abp.services.finance.creditMemos',
    '$state',
    '$stateParams',
  ];

  function ApplyCreditMemoController(creditMemosSvc, $state, $stateParams) {
    const vm = this;

    vm.loading = 0;
    vm.creditMemoNumber = null;
    vm.currencyCode = null;
    vm.totalAmount = null;
    vm.outstandingAmount = null;
    vm.appliedAmount = null;
    vm.applicableInvoices = [];
    vm.amountToCredit = null;
    vm.showCategory = false;
    vm.payFull = payFull;
    vm.updateAmounts = updateAmounts;

    vm.save = save;

    vm.header = App.localize('ApplyCreditFrom', $stateParams.transactionNumber);

    init();

    function init() {
      getCreditInvoiceOption();
    }

    function getCreditInvoiceOption() {
      vm.loading += 1;
      creditMemosSvc
        .getCreditInvoiceOption({ creditMemoNumber: $stateParams.transactionNumber })
        .success((data) => {
          localizeInvoiceCategory(data);
          vm.creditMemoNumber = data.creditMemoNumber;
          vm.currencyCode = data.currencyCode;
          vm.totalAmount = data.totalAmount;
          vm.outstandingAmount = data.outstandingAmount;
          vm.appliedAmount = data.appliedAmount;
          vm.applicableInvoices = data.applicableInvoices;
          updateMaxApplicableAmount();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function localizeInvoiceCategory(data) {
      _.forEach(data.applicableInvoices, (invoice) => {
        invoice.invoiceCategory = App.localize(invoice.invoiceCategory);
      });
    }

    function save() {
      vm.saving += 1;
      creditMemosSvc
        .creditInvoices({
          creditMemoNumber: vm.creditMemoNumber,
          applicableInvoices: vm.applicableInvoices,
        })
        .success(() => {})
        .finally(() => {
          vm.saving -= 1;
          $state.go('finance.saleTransactions', { isFromPayment: false });
        });
    }

    function updateAmounts() {
      vm.outstandingAmount = vm.totalAmount - getAppliedAmount();
      vm.appliedAmount = vm.totalAmount - vm.outstandingAmount;

      updateMaxApplicableAmount();
    }

    function updateMaxApplicableAmount() {
      _.forEach(vm.applicableInvoices, (invoice) => {
        invoice.maxApplicableAmount = Math.max(
          Math.min(invoice.outstandingAmount, vm.outstandingAmount + (invoice.creditedAmount || 0)),
          0
        );
      });
    }

    function getAppliedAmount() {
      let amount = 0;
      for (let i = 0; i < vm.applicableInvoices.length; i += 1) {
        amount += vm.applicableInvoices[i].creditedAmount || 0;
      }
      return amount;
    }

    function payFull(transaction) {
      const t = transaction;
      t.creditedAmount = Math.max(Math.min(t.outstandingAmount, vm.outstandingAmount), 0);
      updateAmounts();
    }
  }
})();
