(() => {
  angular.module('app').filter('fromNow', fromNow);

  fromNow.$inject = ['moment'];

  function fromNow(moment) {
    return fromNowFilter;

    function fromNowFilter(dateTime) {
      return moment(dateTime).fromNow();
    }
  }
})();
