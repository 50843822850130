(() => {
  angular.module('app').filter('frequencyUnitValue', frequencyUnitValue);

  frequencyUnitValue.$inject = [];

  function frequencyUnitValue() {
    return frequencyUnitValueFilter;

    function frequencyUnitValueFilter(value) {
      if (value && value.unit) {
        if (value.unit === 'immediately') return App.localize('Immediately').toLowerCase();
        return `${value.value} ${App.localize('Times').toLowerCase()}/${value.unit}`;
      }
      return '';
    }
  }
})();
