((function () {
  angular
    .module('app')
    .directive('back', backDirective);

  backDirective.$inject = ['$window'];

  function backDirective($window) {
    const directive = {
        link,
        restrict: 'A'
    };
    return directive;

    function link(_scope, element) {
      element.bind('click', () => {
        $window.history.back();
      });
    }
  }
})());
