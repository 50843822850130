(function () {
    var controllerId = 'host.views.industries.index';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', '$uibModal', '$timeout', 'abp.services.app.industry',
        function ($scope, $state, $stateParams, $modal, $timeout, industrySvc) {
            function init() {
                $timeout(function () {
                    vm.industryGridOptions.paginationCurrentPage = Math.floor(vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1);
                });
            }

            var vm = this;
            vm.loading = 0;

            $scope.$on("$viewContentLoaded", function () {
                App.initAjax();
            });

            vm.requestParams = {
                filter: $stateParams.filterText || '',
                skipCount: $stateParams.skipCount,
                maxResultCount: $stateParams.maxResultCount || app.consts.grid.defaultPageSize,
                sorting: $stateParams.sorting
            };

            // Assign permissions

            vm.permissions = {
                create: abp.auth.isGranted("Host.Industries.Create"),
                edit: abp.auth.isGranted("Host.Industries.Edit"),
                "delete": abp.auth.isGranted("Host.Industries.Delete")
            };

            // Define grid options

            vm.industryGridOptions = {
                appScopeProvider: vm,
                paginationPageSizes: app.consts.grid.defaultPageSize,
                paginationPageSize: app.consts.grid.defaultPageSize,
                paginationCurrentPage: 0,
                useExternalPagination: true,
                useExternalSorting: true,
                columnDefs: [{
                    enableSorting: false,
                    name: App.localize('Actions'),
                    width: 100,
                    headerCellTemplate: "<span></span>",
                    cellTemplate: 'actionTemplate'
                }, {
                    displayName: App.localize('Name'),
                    field: 'name',
                    width: 300
                }],
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                    $scope.gridApi.core.on.sortChanged($scope, function (scope, callback) {
                        var sorting = "";
                        for (var i = 0; i < callback.length; i++) {
                            if (i) sorting += ", ";
                            sorting += callback[i].field + " " + callback[i].sort.direction;
                        }
                        vm.requestParams.sorting = sorting;
                        vm.getIndustries();
                    });
                    gridApi.pagination.on.paginationChanged($scope, App.setPagination(vm.requestParams, vm.getIndustries));
                },
                data: []
            };

            // Shift out action column if there's no edit actions

            vm.permissions.edit || vm.permissions.delete || vm.industryGridOptions.columnDefs.shift();

            // Define funtions

            function registerStateParams() {
                $state.transitionTo($state.current, {
                    skipCount: vm.requestParams.skipCount,
                    maxResultCount: vm.requestParams.maxResultCount,
                    sorting: vm.requestParams.sorting,
                    filterText: vm.requestParams.filter
                }, {
                    notify: false
                });
            }

            vm.getIndustries = function () {
                registerStateParams();

                vm.loading++;
                industrySvc.getIndustries(vm.requestParams).success(function (data) {
                    vm.industryGridOptions.totalItems = data.totalCount;
                    vm.industryGridOptions.data = data.items;
                }).finally(function (e) {
                    vm.loading--;
                });
            };

            /* Create or Edit Industry Functions */

            function showCreateEditModal(industryId) {
                var modal = $modal.open({
                    templateUrl: require('/App/host/views/industries/createOrEditModal.html'),
                    controller: "host.views.industries.createOrEditModal as vm",
                    backdrop: "static",
                    resolve: {
                        industryId: industryId
                    }
                });
                modal.result.then(function () {
                    vm.getIndustries();
                });
            }

            vm.createIndustry = function () {
                showCreateEditModal();
            };

            vm.editIndustry = function (industryId) {
                showCreateEditModal(industryId);
            };

            /* End of Create or Edit Industry Functions */

            vm.deleteIndustry = function (industry) {
                abp.message.confirm(App.localize('DeleteIndustryWarningMessage', industry.name), App.localize('AreYouSure'), function (d) {
                    if (d) {
                        industrySvc.deleteIndustry({
                            id: industry.id
                        }).success(function (data) {
                            vm.getIndustries();
                            abp.notify.success(App.localize("SuccessfullyDeleted"));
                        });
                    }
                });
            };

            init();
        }
    ]);
})();
