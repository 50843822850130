(() => {
  // Kept for backward compatibility.
  // Inject 'appConstants' (declared below) instead.

  const app = angular.module('app');
  app.consts = {
    grid: {
      defaultPageSizes: [10, 20, 50, 100],
      defaultPageSize: 10,
    },
    userManagement: {
      defaultAdminUserName: 'admin',
      defaultHmsPersonnelUserName: 'hmsteam',
    },
    fileUpload: {
      imageExtensions: ['bmp', 'dib', 'jpg', 'jpeg', 'jpe', 'jfif', 'gif', 'tif', 'tiff', 'png'],
      pdfExtensions: ['pdf'],
      docxExtensions: [
        'vnd.openxmlformats-officedocument.wordprocessingml.document',
        'vnd.openxmlformats-officedocument.wordprocessingml.template',
      ],
    },
    maxResultCount: 2147483647,
    serviceRequestMaxLength: 2500,
  };
})();

(() => {
  angular.module('app').constant('appConstants', {
    grid: {
      defaultPageSizes: [10, 20, 50, 100],
      defaultPageSize: 10,
    },
    roleNames: {
      hmsPersonnel: 'HMS Personnel',
    },
    userManagement: {
      defaultAdminUserName: 'admin',
      defaultHmsPersonnelUserName: 'hmsteam',
    },
    fileUpload: {
      imageExtensions: ['bmp', 'dib', 'jpg', 'jpeg', 'jpe', 'jfif', 'gif', 'tif', 'tiff', 'png'],
      pdfExtensions: ['pdf'],
      docxExtensions: [
        'vnd.openxmlformats-officedocument.wordprocessingml.document',
        'vnd.openxmlformats-officedocument.wordprocessingml.template',
      ],
    },
    maxResultCount: 2147483647,
    serviceRequestMaxLength: 2500,
  });
})();

