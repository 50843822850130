(() => {
  angular
    .module('app')
    .controller(
      'clinic.views.panelDashboard.requestLateSubmissionModal',
      RequestLateSubmissionController
    );

  RequestLateSubmissionController.$inject = ['$uibModalInstance', 'actualVisitDate'];

  function RequestLateSubmissionController($uibModalInstance, actualVisitDate) {
    const vm = this;
    vm.requestorName = '';
    vm.reason = '';
    vm.remarks = '';
    vm.actualVisitDate = actualVisitDate.format(App.getDateTimeStringFormat());
    vm.cancel = cancel;
    vm.next = next;
    vm.tidyReason = tidyReason;

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function next() {
      const reason = tidyReason();
      if (!(reason && vm.requestorName)) return;
      $uibModalInstance.close({ reason, requestorName: vm.requestorName });
    }

    function tidyReason() {
      let reason = _.trim(vm.reason);
      if (reason === 'Others') {
        const remarks = _.trim(vm.remarks);
        if (remarks) {
          reason += `: ${remarks}`;
        } else {
          reason = '';
        }
      }
      return reason;
    }
  }
})();
