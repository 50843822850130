(() => {
  angular.module('app').directive('passwordStrengthMeter', passwordStrengthMeterDirective);

  passwordStrengthMeterDirective.$inject = [];

  function passwordStrengthMeterDirective() {
    const directive = {
      link,
      restrict: 'EA',
      template:
        '<uib-progressbar value="value" max="max" type="{{ type }}"><span>{{ text }}</span></uib-progressbar>',
      scope: {
        value: '@',
        max: '@?',
      },
    };
    return directive;

    function link(scope) {
      scope.type = 'danger';
      scope.max = !scope.max ? 100 : scope.max;

      scope.$watch('value', (newValue) => {
        const strenghPercent = newValue / scope.max;

        if (strenghPercent === 0) {
          scope.text = '';
        } else if (strenghPercent <= 0.25) {
          scope.type = 'danger';
          scope.text = 'Weak';
        } else if (strenghPercent <= 0.5) {
          scope.type = 'warning';
          scope.text = 'Moderate';
        } else if (strenghPercent <= 0.75) {
          scope.type = 'default';
          scope.text = 'Strong';
        } else {
          scope.type = 'default';
          scope.text = 'Very strong';
        }
      });
    }
  }
})();
