(function () {
  var controllerId = 'host.views.debugTools.smsSender.index';
  var app = angular.module('app');
  app.controller(controllerId, [
    '$scope', '$state', '$stateParams', 'abp.services.app.sms',
    function ($scope, $state, $stateParams, smsSvc) {
      function init() {
        vm.updateCounter();
      }

      var vm = this;
      vm.loading = 0;
      vm.saving = 0;
      vm.logs = [];
      vm.requestParams = {
        recipients: null,
        message: null
      };

      $scope.$on('$viewContentLoaded', function () {
        App.initAjax();
      });

      vm.getProviders = function () {
        vm.loading++;
        smsSvc.getSmsProviders()
          .success(function (data) {
            abp.notify.success('See developer console.');
            console.log(data);
          })
          .finally(function () {
            vm.loading--;
          });
      };

      vm.sendSms = function () {
        vm.saving++;
        smsSvc.sendSms(vm.requestParams)
          .success(function () {
            abp.notify.success('Successfully sent!');
          })
          .finally(function () {
            vm.saving--;
          });
      };

      vm.counter = {};
      vm.updateCounter = function () {
        var msg = vm.requestParams.message || '';
        var counter = SmsCounter.count('RM0.00 healthmetrics.com ' + msg);
        counter.current_length = counter.length - ((counter.messages - 1) * counter.per_message);

        vm.counter = counter;
      };

      init();
    }
  ]);
})();
