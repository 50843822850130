(() => {
  angular.module('app').component('clinicPanelDashboardPatientSessionsComponent', {
    templateUrl: require('./patientSessions.component.html'),
    controller: PatientSessionsController,
    controllerAs: 'vm',
    bindings: {
      panelLocationId: '<',
      panelLocationTimeZone: '<',
      isGpPanel: '<',
    },
  });

  PatientSessionsController.$inject = [
    '$scope',
    '$interval',
    '$state',
    '$uibModal',
    'abp.services.app.panelDashboard',
    'abp.services.app.clinicEmployee',
    'moment',
  ];

  function PatientSessionsController(
    $scope,
    $interval,
    $state,
    $uibModal,
    panelDashboardSvc,
    clinicEmployeeSvc,
    moment
  ) {
    const vm = this;

    vm.loading = 0;
    vm.statusModel = 'all';
    vm.allClearedNarrative = '';
    vm.sessions = [];
    vm.activeSessions = [];
    vm.expiredSessions = [];
    vm.displayedSessions = [];
    vm.isGP = App.isGPPanel();
    vm.hasGpDashboardEnhancement = abp.setting.getBoolean('Hms.Feature.GPDashboardEnhancement');
    vm.hasPanelDashboardEnhancement = abp.setting.getBoolean(
      'Hms.Feature.PanelDashboardEnhancement'
    );
    vm.permissions = {
      patients: abp.auth.isGranted('Clinic.Employees'),
      canCreateTicket: abp.auth.isGranted('PatientTickets.Clinic.Create'),
      checkInReport: abp.auth.isGranted('Clinic.Reports.CheckInReport'),
    };

    vm.$onChanges = onChanges;
    vm.updateDisplayedSessions = updateDisplayedSessions;
    vm.submitClaim = submitClaim;
    vm.cancelCheckin = cancelCheckin;
    vm.dismiss = dismiss;
    vm.requestLateSubmission = requestLateSubmission;
    vm.getDateTimeString = getDateTimeString;

    function onChanges(changes) {
      if (changes.panelLocationId && changes.panelLocationId.currentValue) {
        getSessions();
      }
    }

    function getDateTimeString(date) {
      return App.getDateTimeString(date, vm.panelLocationTimeZone);
    }
    function getSessions() {
      if (!vm.permissions.patients) return;

      vm.loading += 1;
      panelDashboardSvc
        .getPatientSessions({ id: vm.panelLocationId })
        .success((data) => {
          vm.sessions = data.items;

          // Keep in mind the items in vm.activeSessions and vm.expiredSessions
          // are reference to the same items in vm.sessions.

          vm.activeSessions = _.filter(vm.sessions, ['status', 0]);

          vm.expiredSessions = _.filter(vm.sessions, ['status', 3]);

          vm.updateDisplayedSessions();

          let tickerInterval = $interval(tickerJob, 1000);
          $scope.$on('$destroy', () => {
            if (angular.isDefined(tickerInterval)) {
              $interval.cancel(tickerInterval);
              tickerInterval = undefined;
            }
          });
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function updateDisplayedSessions() {
      let substitute = '';
      switch (vm.statusModel) {
        case 'all':
          vm.displayedSessions = vm.sessions;
          substitute = App.localize('PendingAlternative');
          break;
        case 'active':
          vm.displayedSessions = vm.activeSessions;
          substitute = App.localize('Active');
          break;
        case 'expired':
          vm.displayedSessions = vm.expiredSessions;
          substitute = App.localize('Expired');
          break;
        default:
      }

      vm.allClearedNarrative = App.localize(
        'CheckinsAllClearedNarrative',
        substitute.toLowerCase()
      );
    }

    function tickerJob() {
      _.each(vm.activeSessions, (s) => {
        const totalSeconds = moment(s.expiryTime).diff(moment(), 's');
        const duration = moment.duration(totalSeconds, 's');
        if (totalSeconds <= 0) {
          s.remainingTime = null;
          s.status = 3; // Expire session. For visual purpose only.
        } else {
          const seconds = duration.seconds();
          const minutes = duration.minutes();
          const hours = duration.hours();
          const days = duration.days();
          let str = '';
          if (days > 0) str += `${days}d `;
          if (hours > 0) str += `${_.padStart(`${hours}`, 2, '0')}h `;

          str += `${_.padStart(`${minutes}`, 2, '0')}m ${_.padStart(`${seconds}`, 2, '0')}s`;

          s.remainingTime = str; // Create remainingTime property in session.
        }

        // GP has 1 day to submit ticket,
        // For pre-employee, has 7 days to submit report.

        let limit = 24 * 60 * 60;
        if (s.isPreEmployee) {
          limit *= 7;
        }

        // Create progressPercentage property in session.

        s.progressPercentage = (totalSeconds / limit) * 100;
      });
    }

    function submitClaim(session) {
      if (!session.isPreEmployee && vm.isGP) {
        $state.go('clinic.createEditPatientTicket', {
          employeeId: session.memberId,
          checkedInTime: session.creationTime,
          clinicLocationId: session.panelLocationId,
          ticketType: 0,
        });
      } else {
        $state.go('createEditPatientTicket', {
          employeeId: session.memberId,
          checkedInTime: session.creationTime,
          clinicLocationId: session.panelLocationId,
          ticketType: session.isPreEmployee ? 3 : 0,
        });
      }
    }

    function cancelCheckin(session) {
      $uibModal
        .open({
          templateUrl: require('../../employees/checkOutModal.html'),
          controller: 'clinic.views.employees.checkOutModal as vm',
          backdrop: 'static',
        })
        .result.then((reason) => {
          if (reason) {
            session.voidRemarks = reason;
            checkOut(session);
          }
        });
    }

    function checkOut(session) {
      vm.loading += 1;
      clinicEmployeeSvc
        .checkOut({
          employeeId: session.memberId,
          clinicLocationId: vm.panelLocationId,
          cancellationRemarks: session.voidRemarks,
        })
        .success(() => {
          getSessions();
          abp.notify.info(App.localize('PatientXCheckedOut', session.memberName));
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function dismiss(session) {
      $uibModal
        .open({
          templateUrl: require('../dismissExpiredSession/dismissExpiredSession.modal.html'),
          controller: 'clinic.views.panelDashboard.dismissExpiredSessionModal as vm',
          backdrop: 'static',
        })
        .result.then((reason) => {
          if (reason) {
            voidSession(session, reason);
          }
        });
    }

    function voidSession(session, reason) {
      vm.loading += 1;
      panelDashboardSvc
        .voidPatientSession({
          patientSessionId: session.id,
          voidRemarks: reason,
        })
        .success(() => {
          getSessions();
          abp.notify.info(App.localize('CheckinXDismissed', session.memberName));
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function requestLateSubmission(session) {
      $uibModal
        .open({
          templateUrl: require('../requestLateSubmission/requestLateSubmission.modal.html'),
          controller: 'clinic.views.panelDashboard.requestLateSubmissionModal as vm',
          resolve: {
            actualVisitDate() {
              return App.setDateTimeTimeZone(session.creationTime, vm.panelLocationTimeZone);
            },
          },
          backdrop: 'static',
        })
        .result.then((result) => {
          if (result) {
            const state =
              vm.isGP && !session.isPreEmployee
                ? 'clinic.createEditPatientTicket'
                : 'createEditPatientTicket';

            $state.go(state, {
              employeeId: session.memberId,
              clinicLocationId: session.panelLocationId,
              ticketType: session.isPreEmployee ? 3 : 0,
              patientSessionId: session.id,
              lateSubmissionReason: result.reason,
              lateSubmissionRequestorName: result.requestorName,
            });
          }
        });
    }

    $(document).ready(() => {
      $('.check-in-scroll').slimScroll({
        height: 'unset',
      });
    });
  }
})();
