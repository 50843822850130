(() => {
  angular.module('app').component('commonPatientTicketsDetailGeneralTreatment', {
    templateUrl: require('./widget.html'),
    controller: GeneralTreatmentWidgetController,
    controllerAs: 'vm',
    bindings: {
      ticket: '<',
    },
  });

  GeneralTreatmentWidgetController.$inject = [
    '$filter',
    'Hms.PatientTickets.PatientTicketType',
    'Hms.MultiTenancy.TenantClinicType',
    'Hms.PatientTickets.PatientTicketFormat',
  ];

  function GeneralTreatmentWidgetController(
    $filter,
    enumPatientTicketType,
    enumTenantClinicType,
    enumPatientTicketFormat
  ) {
    const vm = this;

    const unitValueFilter = $filter('unitValue');
    const frequencyUnitValueFilter = $filter('frequencyUnitValue');

    vm.isHost = App.isHost();
    vm.isHealthScreeningTicket = vm.ticket.serviceType === 9;
    vm.isPharmacyTicket = vm.ticket.serviceType === 6;

    vm.enums = {
      patientTicketType: enumPatientTicketType,
      tenantClinicType: enumTenantClinicType,
      patientTicketFormat: enumPatientTicketFormat,
    };

    vm.$onChanges = $onChanges;

    function $onChanges(changes) {
      // If ticket is using new format GP ticket,
      // populate treatments to include prescriptions.

      if (
        changes.ticket &&
        changes.ticket.currentValue &&
        vm.ticket.serviceType === 1 &&
        vm.ticket.ticketFormat === 1
      ) {
        renderTreatmentWithPrescription();
      }
    }

    function renderTreatmentWithPrescription() {
      _.each(vm.ticket.treatments, (treatment) => {
        const t = treatment;
        const result = _.chain(t.prescriptions)
          .map((k) => ({
            name: k.name,
            quantity: k.quantity ? unitValueFilter(k.quantity) : null,
            amount: k.amount,
            strength: k.strength ? k.strength.value : null,
            strengthUnit: k.strength ? k.strength.unit : null,
            dosage: getDosageAsString(k.dosage, k.dosageFrequency),
          }))
          .value();

        t.prescriptions = result;
      });
    }

    function getDosageAsString(dosage, dosageFrequency) {
      let tokens = [unitValueFilter(dosage), frequencyUnitValueFilter(dosageFrequency)];
      tokens = _.filter(tokens, (t) => t);

      if (tokens.length) return tokens.join(', ');
      return '-';
    }
  }
})();
