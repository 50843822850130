(() => {
  angular
    .module('revenue')
    .controller('revenue.views.agents.createOrEditModal', RevenueAgentsCreateOrEditModalController);

  RevenueAgentsCreateOrEditModalController.$inject = [
    '$uibModalInstance',
    '$timeout',
    'abp.services.revenue.agent',
    'abp.services.app.commonLookup',
    'currencies',
    'agentType',
    'agentId',
    'Hms.Revenue.AgentType',
    'HmsRevenueConsts.Agent',
  ];
  function RevenueAgentsCreateOrEditModalController(
    $uibModalInstance,
    $timeout,
    agentSvc,
    commonLookupSvc,
    currencies,
    agentType,
    agentId,
    enumAgentType,
    constsAgent
  ) {
    const vm = this;
    vm.constsAgent = constsAgent;
    vm.isEdit = !!agentId;
    vm.loading = 0;
    vm.saving = false;
    vm.currencies = currencies;
    vm.agent = null;
    vm.errors = [];

    vm.enums = {};
    vm.enums.agentType = enumAgentType;

    vm.selectedAccountOwnerDetails = selectedAccountOwnerDetails;
    vm.regenerateAgentCode = regenerateAgentCode;
    vm.save = save;
    vm.cancel = cancel;

    init();

    // Define functions.

    function init() {
      getAccountOwners();
      getAgentForEdit();
    }

    function selectedAccountOwnerDetails(accountOwnerId) {
      // Get account owner.

      const accountOwner = _.find(vm.accountOwners, ['id', accountOwnerId]);
      vm.agent.currencyCode = accountOwner.currencyCode;

      // Get currency.

      const currency = _.find(vm.currencies, ['code', vm.agent.currencyCode]);
      vm.agent.currencyCodeName = currency.codeName;
    }

    function getAgentForEdit() {
      vm.loading += 1;
      agentSvc
        .getAgentForEdit({
          id: agentId,
          type: agentType,
        })
        .success((data) => {
          vm.agent = data;

          // Initialise to '-' if there is no account owner selected.

          if (!vm.agent.currencyCode) vm.agent.currencyCodeName = '-';
          else
            vm.agent.currencyCodeName = _.find(vm.currencies, [
              'code',
              vm.agent.currencyCode,
            ]).codeName;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function checkPaymentAccount(paymentAccount, isRequired) {
      const errors = [];
      let account = paymentAccount;
      if (!paymentAccount) account = {};
      const accountName = (account.accountName || '').trim();
      const accountNumber = (account.accountNumber || '').trim();
      const isEmpty = !account.paymentChannelId && !accountName && !accountNumber;
      if (!isEmpty) {
        if (!account.paymentChannelId) errors.push('InvalidPaymentChannelId');
        if (!accountName) errors.push('InvalidPaymentAccountName');
        if (!accountNumber) errors.push('InvalidPaymentAccountNumber');
      }
      if (isEmpty && isRequired) {
        errors.push('MissingPaymentAccount');
      }
      return errors;
    }

    function refreshErrors() {
      let errors = [];

      const code = (vm.agent.code || '').trim();
      if (!code) {
        errors.push(App.localize('InvalidAgentCode'));
      }

      const displayName = (vm.agent.displayName || '').trim();
      if (!displayName) {
        errors.push(App.localize('InvalidDisplayName'));
      }

      if (!vm.agent.accountOwnerId) {
        errors.push(App.localize('InvalidAccountOwner'));
      }

      const currencyCode = (vm.agent.currencyCode || '').trim();
      if (!currencyCode) {
        errors.push(App.localize('InvalidCurrency'));
      }

      const paymentAccountErrors = checkPaymentAccount(
        vm.agent.paymentAccount,
        vm.agent.type === 1
      );
      errors = errors.concat(_.map(paymentAccountErrors, (d) => App.localize(d)));

      vm.errors = errors;
    }

    function regenerateAgentCode() {
      if (!vm.isEdit) {
        vm.loading += 1;
        agentSvc
          .getNextAgentCode({
            type: agentType,
          })
          .success((data) => {
            vm.agent.code = data.code;
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    }

    function save() {
      refreshErrors();
      if (vm.errors.length) {
        $timeout(() => {
          document.getElementById('pnlError').scrollIntoView();
        }, 0);
        return;
      }

      if (vm.saving) return;

      vm.saving = true;
      agentSvc
        .createOrUpdateAgent(vm.agent)
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          $uibModalInstance.close();
        })
        .finally(() => {
          vm.saving = false;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function getAccountOwners() {
      vm.loading += 1;
      commonLookupSvc
        .getAccountOwners()
        .success((data) => {
          vm.accountOwners = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
