import _ from 'lodash';

(() => {
  angular
    .module('finance')
    .controller('finance.views.paymentInstructionBatches', PaymentInstructionBatchesPageController);

  PaymentInstructionBatchesPageController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$timeout',
    '$uibModal',
    'moment',
    'financeConstants',
    'abp.services.finance.commonLookup',
    'Hms.Finance.PaymentInstructionCategory',
    'abp.services.finance.paymentInstruction',
    'abp.services.app.financePaymentInstruction',
  ];

  function PaymentInstructionBatchesPageController(
    $scope,
    $state,
    $stateParams,
    $timeout,
    $uibModal,
    moment,
    financeConsts,
    commonLookupSvc,
    enumPaymentInstructionCategory,
    paymentInstructionSvc,
    portalFinancePaymentInstructionSvc
  ) {
    const vm = this;
    vm.loadingDisplay = `${App.localize('Loading')}...`;

    vm.loading = 0;
    vm.loadingDetails = 0;
    vm.loadingCUA = 0;
    vm.hasPaymentInstructionQueueEnhancement = false;

    vm.accountOwners = [];
    vm.countries = [];

    vm.dateRangeModel = new DateRangeModel(
      $stateParams.startDate,
      $stateParams.endDate,
      getPaymentInstructionBatches
    );
    vm.requestParams = new RequestParamsModel($stateParams);

    vm.enums = {
      paymentInstructionCategory: enumPaymentInstructionCategory,
    };

    vm.permissions = {
      createPaymentInstructions: abp.auth.isGranted(
        'HealthMetrics.Finance.AccountPayables.PaymentInstructions.Create'
      ),
      updatePaymentInstructions: abp.auth.isGranted(
        'HealthMetrics.Finance.AccountPayables.PaymentInstructions.Update'
      ),
      verifyPaymentInstructions: abp.auth.isGranted(
        'HealthMetrics.Finance.AccountPayables.PaymentInstructions.Verify'
      ),
      approvePaymentInstructions: abp.auth.isGranted(
        'HealthMetrics.Finance.AccountPayables.PaymentInstructions.Approve'
      ),
      exportPaymentInstructions: abp.auth.isGranted(
        'HealthMetrics.Finance.AccountPayables.PaymentInstructions.Export'
      ),
      deletePaymentInstructions: abp.auth.isGranted(
        'HealthMetrics.Finance.AccountPayables.PaymentInstructions.Delete'
      ),
    };

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: financeConsts.grid.defaultPageSizes,
      paginationPageSize: financeConsts.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      columnDefs: [
        {
          name: App.localize('Actions'),
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
        },
        {
          displayName: App.localize('Country'),
          field: 'countryCode',
          width: 30,
          cellTemplate: 'countryTemplate',
        },
        {
          displayName: App.localize('BatchNumber'),
          field: 'batchNumber',
          minWidth: 100,
        },
        {
          displayName: App.localize('CreatedBy'),
          field: 'createdBy',
          cellTemplate: 'createdByTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('VerifiedBy'),
          field: 'verifiedBy',
          cellTemplate: 'verifiedByTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('Verify'),
          field: 'audit',
          cellTemplate: 'verifyStatusTemplate',
          minWidth: 20,
        },
        {
          displayName: App.localize('ApprovedBy'),
          field: 'approvedBy',
          cellTemplate: 'approvedByTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('Approve'),
          field: 'audit',
          cellTemplate: 'approveStatusTemplate',
          minWidth: 20,
        },
        {
          displayName: App.localize('Category'),
          field: 'categoryName',
          minWidth: 100,
        },
        {
          displayName: App.localize('Count'),
          field: 'count',
          minWidth: 100,
        },
        {
          displayName: App.localize('TotalAmount'),
          field: 'amount',
          cellTemplate: 'totalAmountTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('Paid'),
          field: 'paidAmount',
          cellFilter: 'currencyFormat: grid.appScope.currencyCode',
          cellTemplate: 'paidAmountTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('Unpaid'),
          field: 'unpaidAmount',
          cellFilter: 'currencyFormat: grid.appScope.currencyCode',
          cellTemplate: 'unpaidAmountTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('CorporateUnpaidAmountOriginalShortForm'),
          field: 'receivableUnpaidAmount',
          cellTemplate: 'receivableUnpaidAmountTemplate',
          minWidth: 100,
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          vm.requestParams.sorting =
            callback.length && callback[0].field
              ? `${callback[0].field} ${callback[0].sort.direction}`
              : null;
          getPaymentInstructionBatches();
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, getPaymentInstructionBatches)
        );
      },
      data: [],
    };

    vm.getPaymentInstructionBatches = getPaymentInstructionBatches;
    vm.create = create;
    vm.verify = verify;
    vm.approve = approve;
    vm.delete = deleteBatch;
    vm.generateExcel = generateExcel;
    vm.generateBif = generateBif;
    vm.generateCitibankTxt = generateCitibankTxt;
    vm.generateCimbCsv = generateCimbCsv;

    init();

    function init() {
      $timeout(() => {
        vm.gridOptions.paginationCurrentPage = Math.floor(
          vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1
        );
      });

      getCountries();
      getAccountOwners();
      localizePaymentInstructionCategoryEnum();
      getSettings();
    }

    function registerStateParams() {
      $state.transitionTo($state.current, vm.requestParams.toStateParams(), {
        notify: false,
      });
    }

    function getPaymentInstructionBatches() {
      registerStateParams();
      const input = vm.requestParams.toInputDto();

      vm.loading += 1;
      paymentInstructionSvc
        .getBatches(input)
        .success((data) => {
          localizePaymentInstructionListingItem(data);
          vm.gridOptions.totalItems = data.totalCount;
          vm.gridOptions.data = data.items;
          getPaymentInstructionBatchDetails(_.map(data.items, (x) => x.id));
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getPaymentInstructionBatchDetails(batchIds) {
      if (!batchIds || !batchIds.length) return;

      const input = {
        batchIds,
      };

      vm.loadingDetails += 1;
      paymentInstructionSvc
        .getBatchesDetail(input)
        .success((data) => {
          vm.gridOptions.data = _.map(vm.gridOptions.data, (d) => {
            const details = _.find(data.items, { id: d.id });
            return $.extend(d, details);
          });
          getReceivableUnpaidAmount(vm.gridOptions.data);
        })
        .finally(() => {
          vm.loadingDetails -= 1;
        });
    }

    function getReceivableUnpaidAmount(batches) {
      if (!batches || !batches.length) return;

      const input = {
        pIBatchInfos: batches,
      };

      vm.loadingCUA += 1;

      portalFinancePaymentInstructionSvc
        .getReceivableUnpaidAmount(input)
        .success((data) => {
          vm.gridOptions.data = _.map(vm.gridOptions.data, (d) => {
            const details = _.find(data.piBatchInfos, { id: d.id });
            return $.extend(d, details);
          });
        })
        .finally(() => {
          vm.loadingCUA -= 1;
        });
    }

    function getCountries() {
      commonLookupSvc.getCountries().success((data) => {
        if (data) {
          vm.countries = data.items;
        }
      });
    }

    function getAccountOwners() {
      commonLookupSvc.getAccountOwners().success((data) => {
        if (data) {
          vm.accountOwners = data.items;
        }
      });
    }

    function create() {
      $uibModal
        .open({
          templateUrl: require('./createPaymentInstructionBatch.modal.html'),
          controller: 'finance.views.createPaymentInstructionBatchModal as vm',
          backdrop: 'static',
        })
        .result.then(vm.getPaymentInstructionBatches);
    }

    function verify(batch) {
      signPaymentInstruction(batch, 'verify');
    }

    function approve(batch) {
      signPaymentInstruction(batch, 'approve');
    }

    function signPaymentInstruction(batch, type) {
      $uibModal
        .open({
          templateUrl: require('./signPaymentInstructions.modal.html'),
          controller: 'finance.views.signPaymentInstructionModal as vm',
          backdrop: 'static',
          resolve: {
            type() {
              return type;
            },
            batchId() {
              return batch.id;
            },
            batchNumber() {
              return batch.batchNumber;
            },
          },
        })
        .result.then(() => {
          vm.getPaymentInstructionBatches();
        });
    }

    function deleteBatch(batch) {
      abp.message.confirm(
        App.localize('PaymentInstructionRequestDeleteWarningMessage'),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            vm.loading += 1;
            paymentInstructionSvc
              .deletePaymentInstructions({
                batchId: batch.id,
              })
              .success(() => {
                vm.getPaymentInstructionBatches();
                abp.notify.success(App.localize('SuccessfullyDeleted'));
              })
              .finally(() => {
                vm.loading -= 1;
              });
          }
        }
      );
    }

    function localizePaymentInstructionCategoryEnum() {
      _.forEach(vm.enums.paymentInstructionCategory, (paymentInstructionCategory) => {
        paymentInstructionCategory.displayName = App.localize(paymentInstructionCategory.name);
      });
    }

    function localizePaymentInstructionListingItem(data) {
      _.forEach(data.items, (instruction) => {
        if (instruction.categoryName != null) {
          const transactionPaymentMethod = _.find(
            enumPaymentInstructionCategory,
            (category) => category.id === instruction.categoryName
          );
          instruction.categoryName = transactionPaymentMethod ? App.localize(transactionPaymentMethod.name) : "";
        }
      });
    }

    function generateExcel(batch) {
      vm.loading += 1;
      portalFinancePaymentInstructionSvc
        .getPaymentInstructionExcelReport({
          batchNumber: batch.batchNumber,
        })
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function generateCimbCsv(batch) {
      vm.loading += 1;
      portalFinancePaymentInstructionSvc
        .generateCimbPaymentInstructionCsv({
          batchNumber: batch.batchNumber,
        })
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function generateBif(batch) {
      vm.loading += 1;
      portalFinancePaymentInstructionSvc
        .generatePaymentInstructionBif({
          batchNumber: batch.batchNumber,
        })
        .success((data) => {
          if (data.errors) {
            $uibModal.open({
              templateUrl: require('./result.modal.html'),
              controller: 'finance.views.resultModal as vm',
              backdrop: 'static',
              resolve: {
                title() {
                  return App.localize('GenerateBif');
                },
                errors() {
                  return data.errors;
                },
              },
            });
          } else {
            App.downloadTempFile(data);
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function generateCitibankTxt(batch) {
      vm.loading += 1;
      portalFinancePaymentInstructionSvc
        .generateCitiPaymentInstructionTxt({
          batchNumber: batch.batchNumber,
        })
        .success((data) => {
          if (data.errors) {
            $uibModal.open({
              templateUrl: require('./result.modal.html'),
              controller: 'finance.views.resultModal as vm',
              backdrop: 'static',
              resolve: {
                title() {
                  return App.localize('GenerateCitibankTxt');
                },
                errors() {
                  return data.errors;
                },
              },
            });
          } else {
            if (data.warnings) {
              $uibModal.open({
                templateUrl: require('./result.modal.html'),
                controller: 'finance.views.resultModal as vm',
                backdrop: 'static',
                resolve: {
                  title() {
                    return App.localize('GenerateCitibankTxt');
                  },
                  errors() {
                    return data.warnings;
                  },
                },
              });
            }
            App.downloadTempFile(data);
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getSettings() {
      vm.loading += 1;
      commonLookupSvc
        .getCommonSettings()
        .success((data) => {
          vm.hasPaymentInstructionQueueEnhancement = data.paymentInstructionQueueEnhancement;
        })
        .finally();

      vm.loading -= 1;
    }

    function DateRangeModel(startDate, endDate, eventCallback) {
      this.startDate = startDate ? moment(startDate) : null;
      this.endDate = endDate ? moment(endDate) : null;
      this.options = App.createDateRangePickerOptions();
      this.options.eventHandlers = {
        'apply.daterangepicker': () => {
          eventCallback();
        },
      };
    }

    function RequestParamsModel(params) {
      this.currencyCode = params.currencyCode || null;
      this.countryCode = params.countryCode || null;
      this.accountOwnerId = params.accountOwnerId || null;
      this.category = params.category || null;
      this.batchNumber = params.batchNumber || null;
      this.skipCount = params.skipCount || 0;
      this.maxResultCount = params.maxResultCount || financeConsts.grid.defaultPageSize;
      this.sorting = params.sorting;

      this.paymentState = params.paymentState;

      this.toInputDto = () => {
        const input = $.extend({}, this);

        if (vm.dateRangeModel.startDate)
          input.startDate = moment(vm.dateRangeModel.startDate).toJSON();
        if (vm.dateRangeModel.endDate) input.endDate = moment(vm.dateRangeModel.endDate).toJSON();
        return input;
      };

      this.toStateParams = () => {
        const startDate = vm.dateRangeModel.startDate
          ? vm.dateRangeModel.startDate.format('YYYY-MM-DD')
          : null;
        const endDate = vm.dateRangeModel.endDate
          ? vm.dateRangeModel.endDate.format('YYYY-MM-DD')
          : null;

        const stateParams = {
          currencyCode: this.currencyCode,
          countryCode: this.countryCode,
          accountOwnerId: this.accountOwnerId,
          category: this.category,
          batchNumber: this.batchNumber,
          startDate,
          endDate,
          skipCount: this.skipCount,
          maxResultCount: this.maxResultCount,
          sorting: this.sorting,
        };
        return stateParams;
      };
    }
  }
})();
