import swal from 'sweetalert';

(() => {
  angular
    .module('app')
    .controller('common.views.healthScreeningClaims.finalRequest', FinalRequestController);

  FinalRequestController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$sce',
    'abp.services.app.commonLookup',
    'abp.services.app.healthScreeningPanelServiceRequest',
    'abp.services.app.healthScreeningGuaranteeLetter',
  ];

  function FinalRequestController(
    $scope,
    $state,
    $stateParams,
    $sce,
    commonLookupSvc,
    healthScreeningPanelServiceRequestSvc,
    healthScreeningGuaranteeLetterSvc
  ) {
    const vm = this;

    vm.currencyCode = null;
    vm.loading = 0;
    vm.saving = 0;
    vm.consentClaimFormSubmission = null;
    vm.isClinic = App.isClinic();
    vm.isHost = App.isHost();

    vm.claimNumber = $stateParams.claimNumber;
    vm.letterNumber = $stateParams.letterNumber;
    vm.timeZoneId = $stateParams.timeZoneId;
    vm.isCreate = !vm.claimNumber;
    vm.request = null;
    vm.canTopUp = false;

    vm.treatments = [];
    vm.selectedTreatments = [{}];

    vm.doctors = [];
    vm.newTaxInvoiceAttachments = [];
    vm.newConsentClaimFormAttachments = [];

    // TODO: HM-5981/HM-5982 Permission

    vm.permissions = {
      // createDoctor: abp.auth.isGranted('Clinic.Doctors.Create'),
      // createClaim: abp.auth.isGranted('SpecialistClaims.Clinic.Create'),
      // editClaim: vm.isHost ? abp.auth.isGranted('SpecialistClaims.Host.Edit') : abp.auth.isGranted('SpecialistClaims.Clinic.Edit')
    };

    vm.updateTreatment = updateTreatment;
    vm.checkDuplicateTreatment = checkDuplicateTreatment;
    vm.removeTreatment = removeTreatment;
    vm.hasUncoveredTreatments = hasUncoveredTreatments;
    vm.addTreatments = addTreatments;
    vm.clearTreatments = clearTreatments;
    vm.hasTaxInvoice = hasTaxInvoice;
    vm.isConsentClaimFormValid = isConsentClaimFormValid;
    vm.getExceededAmount = getExceededAmount;
    vm.getCollectAmount = getCollectAmount;
    vm.save = save;

    init();

    function init() {
      vm.loading += 1;
      getCurrencyCode();
      getFinalRequestDetails();
      getTreatments();
    }

    function getCurrencyCode() {
      vm.loading += 1;
      commonLookupSvc
        .getTenantCurrencyCode()
        .success((currencyCode) => {
          vm.currencyCode = currencyCode;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // Health screening treatment.
    // #region Health screening treatment

    function updateTreatment(treatment) {
      const t = treatment;
      if (t.treatment) {
        if (vm.checkDuplicateTreatment(t.treatment)) {
          abp.message.error(App.localize('DuplicateTreatmentValidationErrorMessage'));
          t.treatmentId = null;
          t.treatment = null;
          t.amount = null;
          return;
        }

        t.treatmentId = t.treatment.id;
      } else {
        t.treatmentId = null;
      }
    }

    function checkDuplicateTreatment(treatment) {
      if (treatment.isDuplicable) return false;

      return _.some(vm.selectedTreatments, (t) => t.treatmentId === treatment.id);
    }

    function removeTreatment(index) {
      if (index < vm.selectedTreatments.length) vm.selectedTreatments.splice(index, 1);

      if (vm.selectedTreatments.length === 0) vm.selectedTreatments.push({});
    }

    function hasUncoveredTreatments() {
      return _.some(
        vm.selectedTreatments,
        (treatment) => treatment.treatment && !treatment.treatment.isCovered
      );
    }

    function addTreatments() {
      vm.selectedTreatments.push({});
    }

    function clearTreatments() {
      vm.selectedTreatments = [{}];
    }

    // #endregion Health screening treatment

    /* Validate Operations */

    function hasTaxInvoice() {
      if (!vm.request) return false;
      return (
        vm.newTaxInvoiceAttachments.length ||
        _.some(vm.request.taxInvoiceAttachments, ['isDelete', false])
      );
    }

    function isConsentClaimFormValid() {
      if (!vm.consentClaimFormSubmission) return false;
      if (vm.consentClaimFormSubmission === 2) return true;
      return (
        vm.newConsentClaimFormAttachments.length ||
        _.some(vm.request.consentClaimFormAttachments, ['isDelete', false])
      );
    }

    /* End of Validate Operations */

    function refreshDoctors() {
      vm.loading += 1;
      healthScreeningPanelServiceRequestSvc
        .getLocationDoctors({
          id: vm.panelLocationId,
        })
        .success((data) => {
          vm.doctors = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    /* Calculations */

    function getExceededAmount() {
      if (!vm.request) return 0;
      const amt = vm.request.totalClaimableAmount || 0;
      return Math.max(amt - vm.request.guaranteeLetter.guaranteedAmount, 0);
    }

    function getCollectAmount() {
      return vm.getExceededAmount();
    }

    /* End of Calculations */

    /* Submit Claim */

    function save() {
      if (vm.saving) return;

      vm.invalidChecklist = _.some(
        vm.request.documentChecklistItems,
        (d) => d.required && !d.isChecked
      );
      vm.invalidTaxInvoice = !vm.hasTaxInvoice();

      const isFormValid = App.isFormValid($scope.editForm);
      if (!isFormValid || vm.invalidTaxInvoice || vm.invalidChecklist) {
        return;
      }

      const consentClaimFormAttachmentIds = [];
      _.each(vm.request.consentClaimFormAttachments, (attachment) => {
        if (!attachment.isDelete) consentClaimFormAttachmentIds.push(attachment.id);
      });

      const taxInvoiceAttachmentIds = [];
      _.each(vm.request.taxInvoiceAttachments, (attachment) => {
        if (!attachment.isDelete) taxInvoiceAttachmentIds.push(attachment.id);
      });

      const selectedTreatments = [];

      if (vm.selectedTreatments[0].treatment) {
        vm.selectedTreatments.forEach((t) => {
          selectedTreatments.push(t.treatment);
        });
      }

      const input = {
        claimNumber: vm.claimNumber,
        referenceNumber: vm.request.referenceNumber,
        totalClaimableAmount: vm.request.totalClaimableAmount,
        attendingDoctors: vm.request.attendingDoctors,
        consentClaimFormAttachmentIds,
        consentClaimFormAttachments: vm.newConsentClaimFormAttachments,
        taxInvoiceAttachmentIds,
        taxInvoiceAttachment: _.first(vm.newTaxInvoiceAttachments),
        letterNumber: vm.letterNumber,
        healthScreeningTreatments: selectedTreatments,
      };

      if (vm.isCreate || (!vm.isCreate && vm.request.status === 1)) {
        input.claimWithTopUp = vm.claimWithTopUp;
      }

      if (vm.request.isLateSubmissionRequest) {
        input.lateSubmissionReason = tidyReason();

        const checklistItems = [];
        _.each(vm.request.documentChecklistItems, (item) => {
          checklistItems.push({
            text: item.text,
            isSelected: item.isChecked,
            isMandatory: item.required,
          });
        });
        input.documentChecklistItems = checklistItems;

        swal(
          {
            title: App.localize('ConfirmSubmission'),
            text: App.localize('ConfirmLateSubmissionNarrative'),
            type: 'warning',
            confirmButtonColor: '#1ab394',
            confirmButtonText: App.localize('Yes'),
            cancelButtonText: App.localize('No'),
            showCancelButton: true,
            closeOnConfirm: false,
          },
          (isConfirm) => {
            if (isConfirm) {
              submitFinalRequest(input);
            }
            swal.close();
          }
        );
      } else {
        submitFinalRequest(input);
      }
    }

    function getFinalRequestDetails() {
      healthScreeningPanelServiceRequestSvc
        .getFinalRequestDetails({
          claimNumber: vm.claimNumber,
          letterNumber: vm.letterNumber,
        })
        .success((data) => {
          vm.request = data;

          // Updates param

          vm.claimNumber = data.claimNumber;
          vm.employeeId = data.patient.id;
          vm.panelLocationId = data.panelLocationId;
          if (!vm.request.totalClaimableAmount) vm.request.totalClaimableAmount = null;

          refreshDoctors();

          if (
            vm.request.consentClaimFormAttachments &&
            vm.request.consentClaimFormAttachments.length > 0
          ) {
            vm.consentClaimFormSubmission = 1;
          }

          if (vm.request.isLateSubmissionRequest) {
            // To handle html code on the words inside localization file.

            const message = [];
            message.push(
              `<li class="m-b-xs"><span>${App.localize(
                'HealthScreeningLateClaimNoteNarrative1'
              )}</span></li>`
            );
            message.push(
              `<li class="m-b-xs"><span>${App.localize(
                'HealthScreeningLateClaimNoteNarrative2'
              )}</span></li>`
            );
            message.push(
              `<li class="m-b-xs"><span>${App.localize(
                'HealthScreeningLateClaimNoteNarrative3'
              )}</span></li>`
            );
            message.push(
              `<li class="m-b-xs"><span>${App.localize(
                'HealthScreeningLateClaimNoteNarrative4'
              )}</span></li>`
            );
            message.push(
              `<li class="m-b-xs"><span>${App.localize(
                'HealthScreeningLateClaimNoteNarrative5'
              )}</span></li>`
            );
            message.push(
              `<li class="m-b-xs"><span>${App.localize(
                'HealthScreeningLateClaimNoteNarrative6'
              )}</span></li>`
            );

            vm.lateSubmissionNotes = $sce.trustAsHtml(message.join(''));

            vm.consentClaimFormSubmission = 1;

            _.each(vm.request.documentChecklistItems, (item) => {
              const i = item;
              i.required = i.isMandatory;
              i.isChecked = i.isSelected;
            });
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getTreatments() {
      healthScreeningGuaranteeLetterSvc
        .getHealthScreeningTreatments({
          id: vm.letterNumber,
        })
        .success((data) => {
          data.forEach((t) => {
            if (t.parentId) vm.treatments.push(t);
          });
        });
    }

    function submitFinalRequest(input) {
      // Submit claim.

      vm.saving += 1;
      healthScreeningPanelServiceRequestSvc
        .submitFinalRequest(input)
        .success((data) => {
          vm.claimNumber = data.claimNumber;
          abp.notify.success(App.localize('SuccessfullySubmitted'));
          if (vm.isHost) returnToClaimDetail();
          else showSuccessModal();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function showSuccessModal() {
      const exceededAmount = vm.getCollectAmount();
      const message = [];
      message.push(App.localize('SpecialistClaimSuccessNarrative1'));
      message.push(App.localize('SpecialistClaimSuccessNarrative2'));
      message.push(App.localize('SpecialistClaimSuccessNarrative3'));

      const exceededAmountText =
        exceededAmount && vm.request.status !== 5
          ? `<b class="text-danger">${App.localize(
              'RememberCollectXPaymentFromPatient',
              vm.currencyCode + exceededAmount
            )}</b><br /><br />`
          : '';

      swal(
        {
          title: App.localize('SuccessfullySubmitted'),
          text: exceededAmountText + message.join(' '),
          html: true,
          type: 'success',
          confirmButtonColor: '#1ab394',
          confirmButtonText: App.localize('ViewClaim'),
          cancelButtonText: App.localize('ReturnToPatientSearch'),
          showCancelButton: true,
          closeOnConfirm: false,
        },
        function(isConfirm) {
          if (isConfirm) {
            returnToClaimDetail();
          } else {
            $state.go('clinic.healthScreeningCheckIn');
          }
          swal.close();
        }
      );
    }

    /* End of Submit Claim */

    /* Late submission claim */

    function tidyReason() {
      let reason = _.trim(vm.request.reason);
      if (reason === 'Others') {
        const remarks = _.trim(vm.request.remarks);
        if (remarks) {
          reason += `: ${remarks}`;
        } else {
          reason = '';
        }
      }
      return reason;
    }

    /* End of Late submission claim */

    function returnToClaimDetail() {
      $state.go('healthScreeningClaimDetail', {
        claimNumber: vm.claimNumber,
        timeZoneId: vm.timeZoneId
      });
    }
  }
})();
