(function () {
    var controllerId = 'revenue.views.subscriptionPlans.index';
    var module = angular.module('revenue');

    module.controller(controllerId, [
        '$scope', '$state', '$stateParams', '$uibModal', 'abp.services.revenue.subscriptionPlan',
        function ($scope, $state, $stateParams, $uibModal, subscriptionPlanSvc) {
            function showEditModal(id) {
                $uibModal.open({
                    templateUrl: require('./createOrEditModal.html'),
                    controller: "revenue.views.subscriptionPlans.createOrEditModal as vm",
                    backdrop: "static",
                    resolve: {
                        subscriptionPlanId () {
                            return id;
                        }
                    }
                }).result.then(function () {
                    vm.getSubscriptionPlans();
                });
            }

            const vm = this;
            vm.loading = false;
            vm.subscriptionPlans = [];
            vm.requestParams = {
                view: $stateParams.view
            };

            vm.gridOptions = {
                appScopeProvider: vm,
                columnDefs: [{
                    enableSorting: false,
                    name: App.localize('Actions'),
                    width: 100,
                    headerCellTemplate: "<span></span>",
                    cellTemplate: 'actionTemplate'
                }, {
                    displayName: App.localize('Name'),
                    name: App.localize('ColorCode'),
                    field: 'colorCode',
                    width: 30,
                    cellTemplate: 'colorTemplate'
                }, {
                    displayName: App.localize('Name'),
                    name: App.localize('Name'),
                    field: 'name',
                    minWidth: 80
                }, {
                    displayName: App.localize('Price'),
                    name: App.localize('Price'),
                    field: 'pricePerPaxPerMonth',
                    minWidth: 80,
                    type: 'number',
                    cellTemplate: 'priceTemplate'
                }, {
                    displayName: App.localize('Order'),
                    name: App.localize('Order'),
                    field: 'order',
                    type: 'number',
                    minWidth: 80
                }, {
                    displayName: App.localize('Status'),
                    name: App.localize('Status'),
                    field: 'isActive',
                    minWidth: 80,
                    cellTemplate: 'statusTemplate'
                }],
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                },
                data: []
            };

            function registerStateParams() {
                $state.transitionTo($state.current, vm.requestParams, { notify: false });
            }

            vm.getSubscriptionPlans = function () {
                if (vm.loading) return;
                registerStateParams();
                vm.loading = true;
                subscriptionPlanSvc.getSubscriptionPlans(vm.requestParams).success(function (data) {
                    vm.subscriptionPlans = data.items;
                    vm.gridOptions.data = vm.subscriptionPlans;
                }).finally(function () {
                    vm.loading = false;
                });
            };

            vm.createSubscriptionPlan = function () {
                showEditModal();
            };

            vm.editSubscriptionPlan = function (subscriptionPlan) {
                showEditModal(subscriptionPlan.id);
            };

            vm.deleteSubscriptionPlan = function (subscriptionPlan) {
                const msg = App.localize("SubscriptionPlanSetupDeleteWarningMessage", subscriptionPlan.name);
                abp.message.confirm(msg, App.localize('AreYouSure'), function (d) {
                    if (d) {
                        subscriptionPlanSvc.deleteSubscriptionPlan({
                            id: subscriptionPlan.id
                        }).success(function () {
                            abp.notify.success(App.localize("SuccessfullyDeleted"));
                            vm.getSubscriptionPlans();
                        });
                    }
                });
            };

            vm.activate = function (subscriptionPlan) {
                subscriptionPlanSvc.activateSubscriptionPlan({
                    id: subscriptionPlan.id
                }).success(function () {
                    subscriptionPlan.isActive = true;
                });
            };

            vm.deactivate = function (subscriptionPlan) {
                subscriptionPlanSvc.deactivateSubscriptionPlan({
                    id: subscriptionPlan.id
                }).success(function () {
                    subscriptionPlan.isActive = false;
                });
            };

            vm.getSubscriptionPlans();
        }
    ]);
})();
