(function () {
    var app = angular.module('app').controller('clinic.view.hospital.guaranteeLetters',
        ['$scope', '$stateParams', '$state', '$uibModal', '$timeout', 'abp.services.app.inpatientGuaranteeLetter', 'abp.services.app.hospitalPanelServiceRequest',
        'Hms.GuaranteeLetters.GuaranteeLetterStatus', 'moment',
        controller]);

    function controller($scope, $stateParams, $state, $uibModal, $timeout, inpatientGuaranteeLetterSvc, hospitalPanelServiceRequestSvc, enumGuaranteeLetterStatus, moment) {
        function init() {
            registerStateParams();
            getListingFilters();

            $timeout(function () {
                vm.gridOptions.paginationCurrentPage = Math.floor(vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1);
            });
        }

        var vm = this;
        vm.loading = 0;
        vm.exporting = 0;
        vm.panelDoctors = [];
        var tenantId = abp.session.tenantId;

        vm.enums = {};
        vm.enums.guaranteeLetterStatus = enumGuaranteeLetterStatus;

        $scope.$on('$viewContentLoaded', function () {
            App.initAjax();
        });

        vm.requestParams = {
            filter: $stateParams.letterNumber,
            latestRequestType: $stateParams.latestRequestType,
            status: $stateParams.status,
            letterNumber: $stateParams.letterNumber,
            patient: $stateParams.patient,
            admissionType: $stateParams.admissionType,
            attendingDoctorId: $stateParams.attendingDoctorId,
            skipCount: $stateParams.skipCount || 0,
            maxResultCount: $stateParams.maxResultCount || app.consts.grid.defaultPageSize,
            sorting: $stateParams.sorting
        };

        vm.scheduledDateRangeOptions = App.createDateRangePickerOptions();
        vm.scheduledDateRangeModel = {
            startDate: $stateParams.scheduledAdmissionEndDate ? moment($stateParams.scheduledAdmissionEndDate) : null,
            endDate: $stateParams.scheduledAdmissionEndDate ? moment($stateParams.scheduledAdmissionEndDate) : null
        };

        vm.onHoldText = App.localize('GLOnHoldDescription');

        vm.gridOptions = {
            appScopeProvider: vm,
            paginationPageSizes: app.consts.grid.defaultPageSizes,
            paginationPageSize: app.consts.grid.defaultPageSize,
            paginationCurrentPage: 0,
            useExternalPagination: true,
            useExternalSorting: true,
            resizable: true,
            columnDefs: [{
                name: 'Actions',
                displayName: 'Actions',
                enableSorting: false,
                width: 100,
                headerCellTemplate: '<span></span>',
                cellTemplate: 'actionTemplate'
            }, {
                displayName: App.localize('Patient'),
                enableSorting: false,
                field: 'patientName',
                minWidth: 120,
                cellTemplate: 'patientTemplate'
            }, {
                displayName: App.localize('AdmissionNumber'),
                enableSorting: false,
                field: 'admissionNumber',
                minWidth: 120
            }, {
                displayName: App.localize('GuaranteeLetterNumber'),
                enableSorting: false,
                field: 'letterNumber',
                minWidth: 150
            }, {
                displayName: App.localize('GlStatus'),
                enableSorting: false,
                field: 'status',
                cellTemplate: 'statusTemplate',
                minWidth: 120
            }, {
                displayName: App.localize('LatestRequestType'),
                enableSorting: false,
                field: 'latestRequestDescription',
                cellTemplate: 'latestRequestTemplate',
                minWidth: 100,
            }, {
                displayName: App.localize('AdmissionType'),
                enableSorting: false,
                field: 'admissionType',
                minWidth: 100,
                cellTemplate: 'admissionTypeTemplate'
            }, {
                displayName: App.localize('ScheduledAdmissionTime'),
                field: 'scheduledAdmissionTime',
                minWidth: 150,
                cellTemplate: 'scheduledAdmissionTimeTemplate'
            }, {
                displayName: App.localize('PanelLocation'),
                enableSorting: false,
                field: 'panelLocationName',
                minWidth: 100,
            }, {
                displayName: App.localize('AttendingDoctor'),
                enableSorting: false,
                field: 'attendingDoctorNames',
                minWidth: 120
            }, {
                displayName: App.localize('ClaimNumber'),
                enableSorting: false,
                field: 'claimNumber',
                minWidth: 120
            }],
            onRegisterApi: function (gridApi) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function (scope, callback) {
                    vm.requestParams.sorting = callback.length && callback[0].field ? callback[0].field + ' ' + callback[0].sort.direction : null;
                    getGuaranteeLetters();
                });
                gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
                    vm.requestParams.skipCount = (newPage - 1) * pageSize;
                    vm.requestParams.maxResultCount = pageSize;
                    getGuaranteeLetters();
                });
            },
            data: []
        };

        vm.getGuaranteeLetters = getGuaranteeLetters;
        vm.exportToExcel = exportToExcel;
        vm.lookupDoctors = lookupDoctors
        vm.setAttendingDoctorId = setAttendingDoctorId;
        vm.showCancelModal = showCancelModal;
        vm.getDateTimeString = getDateTimeString;

        /* Functions */

        function setAttendingDoctorId(doctorId) {
            vm.requetParams.attendingDoctorId = doctorId;
        }

        function lookupDoctors(keyword) {
            if (_.isNil(keyword))
                return;

            var input = {
                tenantId: tenantId,
                filter: keyword,
                maxResultCount: 5
            };

            inpatientGuaranteeLetterSvc.findHospitalDoctors(input)
                .success(function (data) {
                    vm.panelDoctors = data.items;
                })
                .finally(function () {
                });
        }

        function getListingFilters() {
            inpatientGuaranteeLetterSvc.getListingFilters()
                .success(function (data) {
                    vm.admissionTypes = data.admissionTypes;
                    vm.letterStatuses = data.letterStatuses;
                    vm.requestTypes = data.requestTypes;
                });
        }

        function getGuaranteeLetters() {
            var input = getRequestInput();

            vm.loading++;
            inpatientGuaranteeLetterSvc.getInpatientGuaranteeLetters(input)
                .success(function (data) {
                    vm.gridOptions.data = data.items;
                    vm.gridOptions.totalItems = data.totalCount;
                })
                .finally(function () {
                    vm.loading--;
                });
        }

        function exportToExcel() {
            var input = getRequestInput();

            vm.exporting++;
            inpatientGuaranteeLetterSvc.getInpatientGuaranteeLettersToExcel(input)
                .success(function (data) {
                    App.downloadTempFile(data);
                }).finally(function () {
                    vm.exporting--;
                });
        }

        function getRequestInput() {
            var input = $.extend({}, vm.requestParams);

            input.scheduledAdmissionStartDate = vm.scheduledDateRangeModel.startDate;
            input.scheduledAdmissionEndDate = vm.scheduledDateRangeModel.endDate;

            registerStateParams();

            return input;
        }

        function registerStateParams() {
            var scheduledAdmissionStartDate = vm.scheduledDateRangeModel.startDate ? vm.scheduledDateRangeModel.startDate.format('YYYY-MM-DD') : null;
            var scheduledAdmissionEndDate = vm.scheduledDateRangeModel.endDate ? vm.scheduledDateRangeModel.endDate.format('YYYY-MM-DD') : null;

            $state.transitionTo($state.current, {
                filter: vm.requestParams.filter,
                skipCount: vm.requestParams.skipCount,
                maxResultCount: vm.requestParams.maxResultCount,
                latestRequestType: vm.requestParams.latestRequestType,
                sorting: vm.requestParams.sorting,
                stateStatus: vm.requestParams.stateStatus,
                patient: vm.requestParams.patient,
                letterNumber: vm.requestParams.letterNumber,
                admissionType: vm.requestParams.admissionType,
                attendingDoctorId: vm.requestParams.attendingDoctorId,
                scheduledAdmissionStartDate: scheduledAdmissionStartDate,
                scheduledAdmissionEndDate: scheduledAdmissionEndDate
            }, {
                notify: false
            });
        }

        function showCancelModal(letterNumber, letterRequestType, letterStatus) {
            $uibModal.open({
                templateUrl: require('/App/clinic/views/hospital/common/cancellationModal/cancel.modal.html'),
                controller: 'clinic.views.hospital.common.cancelModal as vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    letterRequestType: function () {
                        return letterRequestType;
                    },
                    letterStatus: function () {
                        return letterStatus;
                    }
                }
            }).result.then(function (cancellationReason) {
                if (cancellationReason) {
                    var input = {
                        letterNumber: letterNumber,
                        cancellationReason: cancellationReason
                    };

                    vm.loading++;

                    hospitalPanelServiceRequestSvc.cancelGuaranteeLetterServiceRequest(input)
                        .success(function (data) {
                            abp.notify.success("Cancelled");
                        })
                        .finally(function (data) {
                            vm.loading--;
                            getGuaranteeLetters();
                        });
                }
            });
        }

        function getDateTimeString(date, timeZoneId) {
            return App.getDateTimeString(date, timeZoneId);
        }

        /* End of functions */

        init();
    }
})();
