(function () {
    'use strict';

    angular.module('app').component('hostHealthScreeningClaimsCommonItemizedTreatmentDetailsComponent', {
        templateUrl: require('/App/host/views/healthScreeningClaims/common/itemizedTreatmentDetails/itemizedTreatmentDetails.component.html'),
        controller: ['Hms.PatientTickets.PatientTicketItem', controller],
        controllerAs: 'vm',
        bindings: {
            request: '<',
            treatmentSelections: '<',
            currencyCode: '<',
            isTotalBillAmountValid: '&'
        }
    });

    function controller(constsPatientTicketItem) {
        var vm = this;
            vm.constsPatientTicketItem = constsPatientTicketItem;

        //#region Health screening treatment

        vm.updateTreatment = function (treatment) {
            if (treatment.treatment) {
                if (vm.checkDuplicateTreatment(treatment.treatment)) {
                    abp.message.error(App.localize('DuplicateTreatmentValidationErrorMessage'));
                    treatment.treatmentId = null;
                    treatment.treatment = null;
                    treatment.amount = null;
                    return;
                }

                treatment.treatmentId = treatment.treatment.id;
            } else {
                treatment.treatmentId = null;
            }
        }

        vm.checkDuplicateTreatment = function (treatment) {
            if (treatment.isDuplicable)
                return false;

            return _.some(vm.request.treatmentItems, function (t) {
                return t.treatmentId === treatment.id;
            });
        };

        vm.removeTreatment = function (index) {
            if (index < vm.request.treatmentItems.length)
                vm.request.treatmentItems.splice(index, 1);

            if (vm.request.treatmentItems.length === 0)
                vm.request.treatmentItems.push({});
        };

        vm.hasUncoveredTreatments = function () {
            return _.some(vm.request.treatmentItems, function (treatment) {
                return treatment.treatment && !treatment.treatment.isCovered;
            });
        };

        vm.addTreatments = function () {
            vm.request.treatmentItems.push({});
        }

        vm.clearTreatments = function () {
            vm.request.treatmentItems = [{}];
        }

        //#endregion Health screening treatment
    }
})();
