(() => {
  angular.module('app').factory('common.integrations.announceKit', AnnounceKitFactory);

  AnnounceKitFactory.$inject = ['$window'];

  function AnnounceKitFactory($window) {
    const service = {
      isLoaded: false,
      unread: 0,
      get instance() {
        return $window.announceKit;
      },
    };

    init();

    return service;

    function init() {
      // Determine widget ID.

      let widgetId = '';

      if (App.isClinic()) {
        const clinicType = abp.features.getValue('ClinicModule.ClinicType');
        switch (clinicType) {
          case '1': // GP
            widgetId = abp.setting.get('Hms.Integration.AnnounceKit.GPPanelWidgetId');
            break;
          case '2': // Dental
            widgetId = abp.setting.get('Hms.Integration.AnnounceKit.DentalPanelWidgetId');
            break;
          case '4': // Optical
            widgetId = abp.setting.get('Hms.Integration.AnnounceKit.OpticalPanelWidgetId');
            break;
          case '5': // Specialist
            widgetId = abp.setting.get('Hms.Integration.AnnounceKit.SpecialistPanelWidgetId');
            break;
          case '6': // Pharmacy
            widgetId = abp.setting.get('Hms.Integration.AnnounceKit.PharmacyPanelWidgetId');
            break;

          // no default
        }
      } else if (App.isCorporate()) {
        widgetId = abp.setting.get('Hms.Integration.AnnounceKit.CorporateWidgetId');
      }

      // Set widgetId to empty string if the value retrieved is null

      if (_.isNil(widgetId)) widgetId = '';

      // Refer to https://announcekit.app/docs for AnnounceKit API.

      // Only instantiate widget if there is widget ID.

      if (widgetId.trim()) {
        // Instantiate.

        $window.announcekit = $window.announcekit || {
          queue: [],
          on(n, x) {
            $window.announcekit.queue.push([n, x]);
          },
          push(x) {
            $window.announcekit.queue.push(x);
          },
        };

        // Initialize configuration.

        $window.announcekit.push({
          widget: `https://announcekit.app/widget/${widgetId}`,
          selector: '.announcekit-widget',
          name: widgetId,
          badge: {
            style: {
              opacity: 0,
            },
          },
        });

        service.isLoaded = true;
      }

      // Setup events.

      if (service.isLoaded && $window.announcekit) {
        $window.announcekit.on('widget-unread', widgetUnreadEvent);
        $window.announcekit.on('widget-close', widgetCloseEvent);
      }
    }

    function widgetUnreadEvent(event) {
      if (event.unread > 0) {
        // Show the badge when there is unread notification

        event.widget.element.badge.style.opacity = 1;
      }

      service.unread = event.unread;
    }

    function widgetCloseEvent(event) {
      // Hide the badge when there is no unread notification

      event.widget.element.badge.style.opacity = 0;
      service.unread = 0;
    }
  }
})();
