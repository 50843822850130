(() => {
  angular
    .module('app')
    .controller('host.views.corporates.viewHistory', CorporateViewHistoryController);

  CorporateViewHistoryController.$inject = ['$stateParams', 'moment', 'abp.services.app.corporate'];

  function CorporateViewHistoryController($stateParams, moment, corporateSvc) {
    const vm = this;
    vm.loading = 0;
    vm.employeeName = $stateParams.name;
    const codeMirrorOptions = {
      lineNumbers: false,
      readOnly: true,
      mode: { name: 'javascript', json: true },
      highlightDifferences: true,
      connect: true,
      collapseIdentical: false,
      revertButtons: false,
      autoRefresh: true,
    };

    function init() {
        vm.loading += 1;
        corporateSvc
          .getCorporateHistory({
            id: $stateParams.id,
          })
          .success((data) => {
            const earliestSnapshot = data.reduce((a, b) => a.snapshotTime < b.snapshotTime ? a : b);
            earliestSnapshot.snapshotTime = moment(earliestSnapshot.snapshotTime);

            _.each(data, (historyItem, i) => {
              const isLastItem = i === data.length - 1;
              const origJson = isLastItem ? '' : data[i + 1].snapshotItemJson;
              const userType = historyItem.isHost ? App.localize('Host') : App.localize('Corporate');
              const userName = historyItem.userName ? historyItem.userName : 'HealthMetrics';

              historyItem.snapshotTime = moment(historyItem.snapshotTime);
              historyItem.showDiff = historyItem.snapshotItemJson !== origJson;
              historyItem.userName = userType.concat(': ', userName);
              historyItem.remarks = historyItem.snapshotTime === earliestSnapshot.snapshotTime ? 'Created' : 'Updated';
              
              if (historyItem.showDiff) {
                data[i].codeMirrorOptions = $.extend({}, codeMirrorOptions, { orig: origJson });
              }
            });
            vm.historyItems = data;
          })
          .finally(() => {
            vm.loading -= 1;
          });
    }

    init();
  }
})();
