(() => {
  angular.module('finance').component('financePaymentInstructionQueueWidget', {
    templateUrl: require('./queue.component.html'),
    controller: PaymentInstructionQueueWidget,
    controllerAs: 'vm',
    bindings: {
      refresh: '=?',
    },
  });

  PaymentInstructionQueueWidget.$inject = [
    '$scope',
    '$interval',
    '$uibModal',
    'abp.services.finance.paymentInstructionQueue',
  ];

  function PaymentInstructionQueueWidget(
    $scope,
    $interval,
    $modal,
    paymentInstructionQueueSvc,
  ) {
    const vm = this;
    const DEFAULT_MAX_COUNT = 5;

    vm.loading = 0;
    vm.downloading = 0;
    vm.isSilentLoading = 0;

    vm.requestParams = {
      skipCount: 0,
      maxResultCount: DEFAULT_MAX_COUNT,
    };

    vm.enums = {
    };

    vm.refresh = refresh;
    vm.intervalRefresh = intervalRefresh;
    vm.viewParameters = viewParameters;
    vm.viewRemarks = viewRemarks;

    class PagedData {
      // Properties

      constructor() {
        this.totalDescription = '';
        this.currentPage = 1;
        this.maxPages = 0;

        this.totalCount = 0;
        this.records = [];

        this.requestParams = {
          name: vm.reportName,
          skipCount: 0,
          maxResultCount: DEFAULT_MAX_COUNT,
        };

        this.initialLoad();
      }

      // Page navigations

      goToPage(page) {
        let p = page;

        if (!p || p < 1) {
          p = 1;
        } else if (p > this.maxPages) {
          p = this.maxPages;
        }

        this.currentPage = p;
        const skipCount = (this.currentPage - 1) * DEFAULT_MAX_COUNT;
        this.requestParams.skipCount = skipCount;
        this.getQueue();
      }

      nextPage() {
        this.goToPage(this.currentPage + 1);
      }

      prevPage() {
        this.goToPage(this.currentPage - 1);
      }

      refreshPage() {
        this.goToPage(this.currentPage);
      }

      // Set table configurations.

      setItemsPerPage() {
        this.maxPages = Math.ceil(this.totalCount / DEFAULT_MAX_COUNT);

        // To reset the current page number.

        if (this.maxPages === 0) {
          this.currentPage = 0;
        } else if (this.maxPages !== 0 && this.currentPage === 0) {
          this.currentPage = 1;
        }
      }

      // Refresh table.

      initialLoad() {
        this.getQueue();
      }

      // Retrieve data export record.

      getQueue() {
        vm.loading += 1;
        paymentInstructionQueueSvc
          .getPaymentInstructionQueue(this.requestParams)
          .success((data) => {
            this.records = data.items;
            this.totalCount = data.totalCount;
            this.totalDescription = App.localize('TotalXItems', this.totalCount);
            this.setItemsPerPage();
          })
          .finally(() => {
            vm.loading -= 1;
            vm.isSilentLoading = 0;
          });
      }
    }

    init();

    function init() {
      vm.table = new PagedData();

      let tickerInterval = $interval(vm.intervalRefresh, 10000);
      $scope.$on('$destroy', () => {
        if (angular.isDefined(tickerInterval)) {
          $interval.cancel(tickerInterval);
          tickerInterval = undefined;
        }
      });
    }

    function refresh() {
      if (vm.loading || vm.isSilentLoading) return;
      vm.table.getQueue();
    }

    function intervalRefresh() {
      if (vm.loading || vm.isSilentLoading) return;
      vm.isSilentLoading += 1;
      vm.table.getQueue();
    }

    function viewParameters(parameters) {
      $modal
        .open({
          templateUrl: require('../../../../../common/views/modals/jsonPrettifier/jsonPrettifier.modal.html'),
          controller: 'common.view.modals.jsonPrettifierModal as vm',
          backdrop: 'static',
          resolve: {
            title() {
              return App.localize('Parameters');
            },
            jsonString() {
              return parameters;
            },
          },
        })
        .result.then(() => {
          // EMPTY
        });
    }

    function viewRemarks(parameters) {
      $modal
        .open({
          templateUrl: require('../result.modal.html'),
          controller: 'finance.views.resultModal as vm',
          backdrop: 'static',
          resolve: {
            title() {
              return App.localize('Remarks');
            },
            errors() {
              return parameters;
            },
  },
        })
        .result.then(() => {
          // EMPTY
        });
    }

  }
})();
