(function () {
    var componentId = 'clinicHealthScreeningDashboardPendingSubmissions';
    var app = angular.module('app');

    function controller($sce, $scope, healthScreeningDashboardSvc) {
        function init() {
            vm.getHealthScreeningPendingSubmissions();

            $scope.$on('tickerEvent', function () {
                if (!vm.loading) {
                    vm.getHealthScreeningPendingSubmissionsSilent();
                }
            });
        }
        var vm = this;
        vm.loading = 0;
        vm.ticketList = [];
        vm.activeSort = 'sortByEarliestUpdated';
        vm.skipCount = 0;
        vm.maxResultCount = 5;

        vm.sortFunction = function (sort) {
            vm.activeSort = sort;

            switch (sort) {
                case 'sortByEarliestUpdated':
                    vm.ticketList = _.orderBy(vm.ticketList, ['expiryTime'], ['asc']);
                    break;
                case 'sortByLatestUpdated':
                    vm.ticketList = _.orderBy(vm.ticketList, ['expiryTime'], ['desc']);
                    break;
            }
        }

        vm.processClaims = function (data) {
            _.each(data, function (claim) {
                vm.ticketList.push({
                    patientName: claim.employeeName,
                    patientType: claim.employeeType,
                    nationalId: claim.employeeNationalId,
                    subsidiaryName: claim.employeeSubsidiary,
                    expiryTime: claim.expiryTime,
                    guaranteeLetterNumber: claim.letterNumber
                });
            });
        };

        vm.getHealthScreeningPendingSubmissions = function () {
            vm.hasMore = false;

            vm.loading++;
            healthScreeningDashboardSvc.getPendingSubmission({
                skipCount: vm.skipCount,
                maxResultCount: vm.maxResultCount
            }).success(function (data) {
                // Process claims.

                vm.processClaims(data.items);

                // Sort tickets.

                vm.sortFunction(vm.activeSort);

                // Check if there are more tickets.

                vm.hasMore = vm.ticketList.length < data.totalCount;
                if (vm.skipCount >= data.totalCount) {
                    vm.skipCount = 0;
                } else {
                    vm.skipCount += vm.maxResultCount;
                }
            }).finally(function (e) {
                vm.loading--;
            });
        };

        vm.getHealthScreeningPendingSubmissionsSilent = function () {
            healthScreeningDashboardSvc.getPendingSubmission({
                skipCount: 0,
                maxResultCount: (vm.ticketList.length > vm.maxResultCount) ? vm.ticketList.length : vm.maxResultCount
            }).success(function (data) {
                vm.ticketList = [];

                // Process claims.

                vm.processClaims(data.items);

                // Sort tickets.

                vm.sortFunction(vm.activeSort);

                // Check if there are more tickets.

                vm.hasMore = vm.ticketList.length < data.totalCount;
            }).finally(function (e) {
                // EMPTY.
            });
        };

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/clinic/views/healthScreening/dashboard/pendingSubmissions/pendingSubmissions.component.html'),
        controller: ['$sce', '$scope', 'abp.services.app.healthScreeningDashboard', controller],
        controllerAs: 'vm',
        bindings: {
            timeZoneId: '<'
        }
    });
})();
