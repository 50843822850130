(() => {
  angular
    .module('finance')
    .controller(
      'finance.views.accountOwners.manualTransactionSetup',
      ManualTransactionSetupController
    );

  ManualTransactionSetupController.$inject = ['$stateParams'];

  function ManualTransactionSetupController($stateParams) {
    const vm = this;

    vm.accountOwnerId = $stateParams.accountOwnerId;
    vm.countryCode = $stateParams.countryCode;
  }
})();
