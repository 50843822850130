(function () {
    var controllerId = 'corporate.views.insurances.index';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$state', '$stateParams',
        function ($scope, $state, $stateParams) {
            var vm = this;
        }
    ]);
})();
