(function () {
    var componentId = 'hostPanelRecruitmentDashboardProcessStatsWidget';
    var app = angular.module('app');

    function controller($element, panelRecruitmentDashboardSvc, enumTenantClinicType, moment) {
        function init() {
            loadData();
            updateChart();
        }

        var vm = this;
        vm.loading = 0;
        vm.results = [];
        vm.cities = [];
        vm.subdivisions = [];
        vm.countries = [];
        vm.statistic = null;
        vm.filter = {
            city: null,
            countrySubdivisionId: null,
            countryCode: null,
            panelType: null
        };
        var dataTypeStorageKey = componentId + '_chartType';
        vm.chartType = '';
        vm.getProcessStatistic = getProcessStatistic;
        vm.resetCityAndGetProcessStatistic = resetCityAndGetProcessStatistic;
        vm.resetSubdivisionAndGetProcessStatistic = resetSubdivisionAndGetProcessStatistic;

        vm.enums = {};
        vm.enums.tenantClinicType = enumTenantClinicType;

        vm.chartTypes = {
            AverageAssignmentToActionTime: { type: 1, displayName: App.localize('FirstAssignmentToAction') },
            AverageNoReplyToInProgressTime: { type: 2, displayName: App.localize('NoReplyToInProgress') },
            AverageActionToRegisteredTime: { type: 3, displayName: App.localize('ActionToRegistered') },
            AverageRegisteredToComplete: { type: 4, displayName: App.localize('RegisteredToCompleteTraining') },
            AverageRegisteredToActivedTime: { type: 5, displayName: App.localize('RegisteredToRecruited') },
            AverageInitiateToRejectedTime: { type: 6, displayName: App.localize('RequestedToRejected') }
        };

        vm.setChartType = function (value) {
            if (vm.chartTypes[value]) {
                localStorage.setItem(dataTypeStorageKey, value);
                vm.chartType = value;
                updateChart();
            } else {
                // Default if invalid value.

                vm.chartType = 'AverageAssignmentToActionTime';
            }
        };
        vm.setChartType(localStorage.getItem(dataTypeStorageKey));

        vm.hasIndonesiaAndThailandRegionalExpansion = abp.setting.getBoolean('Hms.Feature.IndonesiaAndThailandRegionalExpansion');

        function loadData() {
            vm.loading++;
            panelRecruitmentDashboardSvc.getProcessStatistics(vm.filter)
                .success(function (data) {
                    vm.results = _.map(data.monthlyAveragePerformances, function (result) {
                        return { type: result.type, items: result.averageDaysTakenPerMonth };
                    });
                    updateChart();
                });
            panelRecruitmentDashboardSvc.getProcessStatisticFilter()
                .success(function (data) {
                    if (vm.hasIndonesiaAndThailandRegionalExpansion) {
                        vm.countries = data.countries.sort((a, b) => a.name.localeCompare(b.name));
                        vm.countrySubdivisions = data.countrySubdivisions;
                    } else {
                        vm.subdivisions = data.countrySubdivisions;
                    }
                    vm.cityStates = data.cityStates;
                }).finally(function () {
                    vm.loading--;
                });
        }

        function updateChart() {
            var x = ['x'];
            var min = ['min'];
            var max = ['max'];
            var averageDays = ['averageDays'];
            var hasData = _.some(_.map(vm.results, function (data) {
                return data.items.length > 0;
            }));

            if (hasData) {
                var chartType = vm.chartTypes[vm.chartType];
                var result = _.find(vm.results, function (item) {
                    return item.type === chartType.type;
                });

                _.each(result.items, function (item) {
                    x.push(moment(item.monthYear).format('YYYY-MM-DD'));
                    min.push(App.evenRound(item.minDays, 2));
                    max.push(App.evenRound(item.maxDays, 2));
                    averageDays.push(App.evenRound(item.averageDays, 2));
                });
            }

            c3.generate({
                bindto: $element.find('.chart-container')[0],
                size: {
                    height: 280
                },
                data: {
                    x: 'x',
                    columns: [x, min, max, averageDays],
                    names: {
                        x: 'Month Year',
                        min: 'Min Days',
                        max: 'Max Days',
                        averageDays: 'Average Days Taken'
                    },
                    order: null,
                    type: 'line'
                },
                legend: {
                    show: false
                },
                grid: {
                    y: { show: true }
                },
                axis: {
                    y: {
                        tick: {
                            format: d3.format('s')
                        },
                        min: 0,
                        padding: {
                            bottom: 0
                        }
                    },
                    x: {
                        type: 'timeseries',
                        tick: {
                            format: function (x) { return moment(x).format('MMM YYYY'); }
                        }
                    }
                },
                tooltip: {
                    format: {
                        value: function (value, ratio, id, index) {
                            if (id === 'min' || id === 'max' || id === 'averageDays') return value;
                        }
                    }
                }
            });
        }

        function getProcessStatistic() {
            vm.loading++;
            panelRecruitmentDashboardSvc.getProcessStatistics(vm.filter)
                .success(function (data) {
                    vm.results = _.map(data.monthlyAveragePerformances, function (result) {
                        return { type: result.type, items: result.averageDaysTakenPerMonth };
                    });
                    updateChart();
                }).finally(function () {
                    vm.loading--;
                });
        }

        function resetSubdivisionAndGetProcessStatistic() {
            vm.filter.countrySubdivisionId = null;
            vm.filter.city = null;
            vm.cities = [];
            if (vm.filter.countryCode) {
               vm.subdivisions = vm.countrySubdivisions.filter(countrySubdivision => countrySubdivision.value.includes(vm.filter.countryCode));
               vm.subdivisions.sort((a, b) => a.name.localeCompare(b.name));
            } else {
               vm.subdivisions = [];
            }

            getProcessStatistic();
        }

        function resetCityAndGetProcessStatistic() {
            vm.filter.city = null;
            if (vm.filter.countrySubdivisionId) {
                vm.cities = _.map(_.filter(vm.cityStates, ['countrySubdivisionId', vm.filter.countrySubdivisionId]), 'city').sort();
            } else {
                vm.cities = [];
            }

            getProcessStatistic();
        }

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/panelRecruitment/dashboard/processStats/processStats.component.html'),
        controller: ['$element', 'abp.services.app.panelRecruitmentDashboard', 'Hms.MultiTenancy.TenantClinicType', 'moment', controller],
        controllerAs: 'vm'
    });
})();
