(() => {
  angular.module('app').filter('modifierCycle', modifierCycle);

  modifierCycle.$inject = [];

  function modifierCycle() {
    return modifierCycleFilter;

    function modifierCycleFilter(value, interval) {
      switch (value) {
        case 0:
          return App.localize('EveryXYears', interval);
        case 1:
          return App.localize('Annually');
        case 2:
          return App.localize('Monthly');
        case 3:
          return App.localize('Daily');
        case 4:
          return App.localize('PerVisit');
        case 5:
          return App.localize('Every6Months');
        case 6:
          return App.localize('Every4Months');
        case 7:
          return App.localize('Every3Months');
        case 8:
          return App.localize('Every2Months');
        default:
          return value;
      }
    }
  }
})();
