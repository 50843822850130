(() => {
  angular.module('app').factory('identificationTypes', identificationTypesFactory);

  identificationTypesFactory.$inject = [];

  function identificationTypesFactory() {
    let identificationTypes;

    init();

    return [...identificationTypes];

    function init() {
      if (identificationTypes) return;

      abp
        .ajax({
          url: `${abp.appPath}api/services/app/commonLookup/getIdentificationTypes`,
          type: 'POST',
          async: false,
        })
        .done((result) => {
          identificationTypes = _.sortBy(result.items, 'id');
        });
    }
  }
})();
