(function () {
    var controllerId = 'host.views.drugs.importDrugsModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        'countries', '$scope', '$uibModal', '$uibModalInstance', 'FileUploader', '$interval', 'abp.services.app.drug', 'abp.services.app.commonLookup', '$rootScope',
        function (countries, $scope, $modal, $uibModalInstance, FileUploader, $interval, drugSvc, commonLookupSvc, $root) {
            var vm = this;
            vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');
            vm.countries = [];

            // Selected country defaulted to Malaysia.
            vm.selectedCountry = 'MY';

            function init() {
                getAccessibleCountries();
            }

            vm.savingProgress = 0;
            function tickerJob() {
                vm.savingProgress = (vm.savingProgress + 1) % 100;
            }

            var tickerInterval = $interval(tickerJob, 1000);
            $scope.$on('$destroy', function () {
                if (angular.isDefined(tickerInterval)) {
                    $interval.cancel(tickerInterval);
                    tickerInterval = undefined;
                }
            });

            // Download current template.

            vm.exportTemplateToExcel = function () {
                vm.exporting++;
                drugSvc.getDrugsToExcel(vm.selectedCountry).success(function (data) {
                    App.downloadTempFile(data);
                }).finally(function () {
                    vm.exporting--;
                });
            };

            // Import drugs

            vm.uploader = new FileUploader({
                url: $root.resolveAppPath('Drug/ImportDrugs'),
                queueLimit: 1,
                headers: App.getFileUploaderHeaders(),
                filters: [{
                    name: 'excelFilter',
                    fn: function (n) {
                        var t = '|' + n.type.slice(n.type.lastIndexOf('/') + 1) + '|';
                        if ('|vnd.openxmlformats-officedocument.spreadsheetml.sheet|'.indexOf(t) === -1
                            && !n.name.endsWith('.xlsx')) {
                            abp.message.warn(App.localize('Excel_Warn_FileType'));
                            return false;
                        }
                        return true;
                    }
                }]
            });

            vm.uploader.onCompleteItem = function (n, t) {
                vm.saving = false;
                $uibModalInstance.close();

                if (!t.success && t.error) {
                    if (t.error.details) {
                        $modal.open({
                            templateUrl: require('/App/host/views/drugs/importDrugsResultModal.html'),
                            controller: 'host.views.drugs.importDrugsResultModal as vm',
                            backdrop: 'static',
                            resolve: {
                                errors: function () {
                                    return JSON.parse(t.error.details);
                                }
                            }
                        });
                    }

                    if (t.error.message) {
                        abp.message.error(t.error.message);
                    }
                }

                if (t.success) {
                    abp.message.success(t.result, App.localize('ImportDrugsSuccessful'));
                }
            };

            vm.uploader.onBeforeUploadItem = function (item) {
                item.formData = [{ countryCode: vm.selectedCountry }];
            };

            vm.save = function () {
                vm.savingProgress = 0;
                vm.saving = true;
                vm.uploader.uploadAll();
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            function getAccessibleCountries() {
                vm.loading++;
                commonLookupSvc.getEligibleCountries().success(function (data) {
                    var eligibleCountryCodes = data.map(o => o.code)
                    vm.countries = _.filter(countries, function (c) {
                        return eligibleCountryCodes.includes(c.code)
                    });
                }).finally(function (e) {
                    vm.loading--;
                });
            }

            init();
        }
    ])
})();
