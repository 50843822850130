(function () {
    var controllerId = 'host.views.clinics.resetAdminPasswordModel';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$uibModalInstance', 'abp.services.app.clinic', 'panelId', 'tenancyName',
        function ($uibModalInstance, clinicSvc, panelId, tenancyName) {
            function init() {
            }

            var vm = this;
            vm.loading = 0;
            vm.saving = false;
            vm.autoGeneratePassword = true;
            vm.shouldChangePasswordOnNextLogin = true;
            vm.tenancyName = tenancyName;

            vm.save = function () {
                if (vm.autoGeneratePassword) {
                    vm.password = null;
                }

                vm.saving = true;
                clinicSvc.resetAdminPassword({
                    id: panelId,
                    autoGeneratePassword: vm.autoGeneratePassword,
                    shouldChangePasswordOnNextLogin: vm.shouldChangePasswordOnNextLogin,
                    password: vm.password
                }).success(function (data) {
                    abp.notify.info(App.localize('SuccessfullySaved'));
                    if (data) {
                        abp.message.success(App.localize('PasswordForAdmin'), data);
                    }
                    $uibModalInstance.close();
                }).finally(function () {
                    vm.saving = false;
                });
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            init();
        }
    ]);
})();
