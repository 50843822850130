(() => {
  angular.module('app').component('hostCorporatesCreateEditFeatures', {
    templateUrl: require('./features.component.html'),
    controller: CorporateEditFeaturesController,
    controllerAs: 'vm',
    require: {
      container: '^hostCorporatesCreateEditContainer',
    },
    bindings: {
      data: '<',
      pharmacies: '<',
      eefEditData: '=',
      customServices: '=',
    },
  });

  CorporateEditFeaturesController.$inject = [
    '$scope',
    '$uibModal',
    'abp.services.app.corporate',
    'Hms.MultiTenancy.Tenant',
  ];

  function CorporateEditFeaturesController($scope, $uibModal, corporateSvc, constsTenant) {
    const vm = this;
    vm.constsTenant = constsTenant;
    vm.loading = 0;
    vm.saving = 0;
    vm.loading = 0;
    vm.isEditing = false;
    vm.customizedEmployeeFields = [];

    // Check for health screening module enable for the application.

    vm.applicationHasHealthScreeningModule = abp.setting.getBoolean(
      'Hms.Feature.HealthScreeningModule'
    );

    // Check for inpatient module enable for the application.

    vm.applicationHasInpatientModule = abp.setting.getBoolean('Hms.Feature.InpatientModule');

    // Checks whether the system has the insurance module enabled.

    vm.hasInsuranceModule = abp.setting.getBoolean('Hms.Feature.InsuranceModule');

    vm.hasInpatientReimbursementClaims =
      vm.data && (vm.data.hasHospitalizationModule || vm.data.hasMaternityModule);

    vm.permissions = {
      edit: abp.auth.isGranted('Host.Corporates.Edit'),
      create: abp.auth.isGranted('Host.Corporates.Create'),
      enableModuleCustomization: abp.auth.isGranted('Corporate.Module.EnableCustomization'),
    };

    vm.disableCheckBox = disableCheckBox;
    vm.isDirty = isDirty;
    vm.setPristine = setPristine;
    vm.regenerateMobileAppRegistrationCode = regenerateMobileAppRegistrationCode;
    vm.validateForm = validateForm;
    vm.getPayload = getPayload;
    vm.save = save;
    vm.addCustomizedServices = addCustomizedServices;
    vm.addCustomizedEmployeeFields = addCustomizedEmployeeFields;
    vm.customization = customization;
    vm.updateEditData = updateEditData;
    vm.changeIsActive = changeIsActive;
    vm.$onChanges = changes;
    vm.$onInit = init;

    function init() {
      vm.container.addWidget(this);
    }

    function disableCheckBox() {
      return !(
        (vm.permissions.create && vm.isCreate) ||
        (vm.permissions.edit && vm.isEditing && vm.permissions.enableModuleCustomization)
      );
    }

    function isDirty() {
      return (
        $scope.corporateCreateEditFeaturesForm && $scope.corporateCreateEditFeaturesForm.$dirty
      );
    }

    function setPristine() {
      $scope.corporateCreateEditFeaturesForm.$setPristine();
    }

    function regenerateMobileAppRegistrationCode(length, isInit) {
      if (!vm.data || (isInit && vm.data.mobileAppRegistrationCode)) {
        return;
      }
      let max = 1;
      for (let i = 0; i < length; i += 1) max *= 10;
      let str = `${Math.floor(Math.random() * max)}`;
      while (str.length < length) str = `0${str}`;
      vm.data.mobileAppRegistrationCode = str;
    }

    // Force validation on this form.
    // True if valid, otherwise, false.

    function validateForm(error) {
      const form = $scope.corporateCreateEditFeaturesForm;
      App.touchFormErrors(form);
      error = !form.$valid;
    }

    // Generate the payload from this form to send to CorporateAppService API.

    function getPayload(settings) {
      _.extend(settings, {
        features: vm.data,
        customServices: vm.customServices,
      });
    }

    // Ask container to save this widget.

    function save() {
      vm.container.saveWidget(vm);
    }

    // Button to open up the modal to edit the customized services of a coroprate.

    function addCustomizedServices() {
      $uibModal
        .open({
          templateUrl: require('./addCustomizedServices/addCustomizedServices.modal.html'),
          controller: 'host.views.corporates.createEdit.features.addCustomizedServices as vm',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            customServices: () => vm.customServices,
          },
        })
        .result.then((data) => {
          vm.customServices = data;
        });
    }

    // Button to open up the modal to edit the customized employee fields of a coroprate.

    function addCustomizedEmployeeFields() {
      $uibModal
        .open({
          templateUrl: require('./addCustomEmployeeFields/addCustomEmployeeFields.modal.html'),
          controller: 'host.views.corporates.createEdit.features.addCustomEmployeeFields as vm',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            employeeFields: () => vm.customizedEmployeeFields,
          },
        })
        .result.then((data) => {
          vm.customizedEmployeeFields = data;
          vm.updateEditData(vm.eefEditData, vm.customizedEmployeeFields);
        });
    }

    // Function to create an array for updating purposes for the customized employee fields.

    function customization(editData) {
      vm.returnArray = [];

      // Retrieves the feature names

      vm.tempValue = _.filter(editData.featureValues, (featureValue) => {
        if (featureValue.name.includes('Text')) {
          return true;
        }
        return false;
      });

      // Retrieves the bool objects for the features

      vm.tempValue2 = _.filter(editData.featureValues, (featureValue) => {
        if (featureValue.name.includes('Active')) {
          return true;
        }
        return false;
      });

      // Grouping of the arrays

      let index = 0;
      _.forEach(vm.tempValue, (temp) => {
        vm.returnArray.push({
          activeName: vm.tempValue2[index].name,
          textName: temp.name,
          name: temp.value,
          isActive: vm.tempValue2[index].value === 'true',
        });

        index += 1;
      });

      vm.tempValue = null;
      vm.tempValue2 = null;
      return vm.returnArray;
    }

    // Used after the modal returns the array of customized employee fields.

    function updateEditData(editData, array) {
      // Update feature values

      _.forEach(editData.featureValues, (x) => {
        _.forEach(array, (y) => {
          if (x.name === y.activeName) {
            if (y.name !== '') x.value = y.isActive.toString();
            else x.value = 'false';
          } else if (x.name === y.textName) {
            x.value = y.name;
          }
        });
      });
    }

    // To update the isActive value of the customized employee fields.

    function changeIsActive(data) {
      vm.updateEditData(vm.eefEditData, [data]);
    }

    function getCustomizationListing() {
      vm.customizedEmployeeFields = vm.customization(vm.eefEditData);
    }

    function changes() {
      vm.isCreate = vm.data ? !vm.data.id : false;
      if (vm.isCreate) vm.data.hasPanelRequestModule = true;

      if (vm.eefEditData != null) getCustomizationListing();

      // To initialise the mobile app registration code after vm.data is ready.

      if (vm.data) {
        vm.regenerateMobileAppRegistrationCode(vm.data.maxMobileAppRegistrationCodeLength, true);
      }
    }
  }
})();
