(() => {
  angular
    .module('app')
    .controller(
      'host.views.operationsDashboard.insuranceClaims.insuranceClaimsListing.confirmInsuranceUtilization.editOrViewInsuranceUtilizationModal',
      EditOrViewInsuranceUtilizationModalController
    );

  EditOrViewInsuranceUtilizationModalController.$inject = [
    '$scope',
    '$uibModalInstance',
    'insurerClaimId',
    'abp.services.app.memberUtilization',
    'abp.services.app.insurerClaim',
    'Hms.Services.ServiceType',
    'Hms.InsurerClaims.InsurerClaimEnums.InsurerClaimStatus',
    'Hms.InsurerClaims.InsurerClaimEnums.InsurerClaimSubCategory',
    'Hms.InsurerClaims.InsurerClaimEnums.InsurerClaimCategory',
  ];

  function EditOrViewInsuranceUtilizationModalController(
    $scope,
    $uibModalInstance,
    insurerClaimId,
    memberUtilizationSvc,
    insurerClaimSvc,
    enumServiceType,
    enumInsurerClaimStatus,
    enumInsurerClaimSubCategory,
    enumInsurerClaimCategory
  ) {
    const vm = this;
    vm.insurerClaim = null;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.loading = 0;
    vm.creditNoteAmountEdit = false;
    vm.insuranceUtilizationAmountEdit = false;
    vm.totalAmountLessThanInvoiceAmount = false;
    vm.totalAmountExceedsPaymentReceivedAmount = false;
    vm.availableBalance = 0;
    vm.isPrePostHospitalizationInsurerClaim = false;
    vm.useCurrentInsuranceBenefitGroup = false;

    vm.enums = {};
    vm.enums.serviceType = enumServiceType;
    vm.enums.insurerClaimStatus = enumInsurerClaimStatus;
    vm.enums.insurerClaimSubCategory = enumInsurerClaimSubCategory;
    vm.enums.insurerClaimCategory = enumInsurerClaimCategory;

    vm.permissions = {
      canReassignBenefitGroupForInsuranceUtilization: abp.auth.isGranted(
        'Host.ReassignBenefitGroupForInsuranceUtilization'
      ),
    };

    vm.validateInsurerCreditNoteAmount = validateInsurerCreditNoteAmount;
    vm.validateCompanyCreditNoteAmount = validateCompanyCreditNoteAmount;
    vm.validateMemberInvoiceAmount = validateMemberInvoiceAmount;
    vm.validatenormalUtilizationAmount = validatenormalUtilizationAmount;
    vm.validateInsuranceUtilizationAmount = validateInsuranceUtilizationAmount;
    vm.confirm = confirm;
    vm.close = close;
    vm.getMemberAvailableBalance = getMemberAvailableBalance;
    vm.refresh = refresh;

    init();

    function init() {
      getInsurerClaim();
    }

    function getInsurerClaim() {
      vm.loading += 1;
      insurerClaimSvc
        .getInsurerClaimById({
          id: insurerClaimId,
        })
        .success((data) => {
          vm.insurerClaim = data;
          vm.organizationCreditNoteAmount =
            vm.insurerClaim.organizationCreditNoteAmount != null
              ? vm.insurerClaim.organizationCreditNoteAmount
              : 0;
          vm.title =
            vm.insurerClaim.insurerClaimStatus === 1
              ? App.localize('ConfirmInsuranceUtilisation')
              : App.localize('ViewInsuranceUtilisation');
          vm.isPrePostHospitalizationInsurerClaim =
            vm.insurerClaim.insurerClaimSubCategory === 2 ||
            vm.insurerClaim.insurerClaimSubCategory === 3;

          // Initialize amount of insurance utilization.

          vm.insuranceUtilizationAmount = vm.insurerClaim.insurerInvoicePaidAmount
            ? vm.insurerClaim.insurerInvoicePaidAmount
            : 0;

          // Initialize amount of credit note to insurer.

          vm.insurerCreditNoteAmount = App.roundAmount(
            vm.insurerClaim.amountToInvoice - vm.insuranceUtilizationAmount
          );
          vm.insurerCreditNoteAmount =
            vm.insurerCreditNoteAmount < 0 || vm.insurerCreditNoteAmount == null
              ? 0
              : vm.insurerCreditNoteAmount;

          vm.isMigrateFromAsoToInsurance = vm.insurerClaim.isMigrateFromAsoToInsurance;

          getMemberUtilizations();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getMemberUtilizations() {
      vm.loading += 1;
      memberUtilizationSvc
        .getMemberUtilizationsForEdit({
          patientTicketNumber: vm.insurerClaim.ticketNumber,
        })
        .success((data) => {
          vm.mainTicket = data.mainTicket;
          vm.remarks = data.remarks;

          // Initialize amount of credit note to organization.

          if (vm.mainTicket.isCorporateBilled && vm.insurerClaim.insurerClaimCategory === 0) {
            vm.companyCreditNoteAmount = vm.insurerClaim.insurerInvoicePaidAmount
              ? vm.insurerClaim.insurerInvoicePaidAmount
              : 0;
            vm.companyCreditNoteAmount =
              vm.companyCreditNoteAmount >= vm.mainTicket.coveredAmount
                ? vm.mainTicket.coveredAmount
                : vm.companyCreditNoteAmount;
          } else {
            vm.companyCreditNoteAmount = 0;
          }

          // Initialize amount of member invoice.

          if (vm.insurerClaim.insurerClaimCategory === 0) {
            vm.memberInvoiceAmount =
              vm.mainTicket.collectCash > 0
                ? App.roundAmount(
                    vm.insurerClaim.insurerInvoicePaidAmount - vm.mainTicket.coveredAmount
                  )
                : 0;

            vm.memberInvoiceAmount = Math.max(vm.memberInvoiceAmount, 0);

            vm.memberInvoiceAmount =
              vm.memberInvoiceAmount > vm.mainTicket.collectCash
                ? vm.mainTicket.collectCash
                : vm.memberInvoiceAmount;
          } else {
            vm.memberInvoiceAmount = Math.max(vm.insurerClaim.insurerInvoicePaidAmount, 0);
          }

          // Initialize amount of normal utilization(GP/Specialist).

          vm.normalUtilizationAmount =
            vm.mainTicket.coveredAmount >= vm.insuranceUtilizationAmount
              ? App.roundAmount(vm.mainTicket.coveredAmount - vm.insuranceUtilizationAmount)
              : 0;

          // Set the form invalid if there is no payment account
          // when amount of member invoice > 0.

          vm.missingPaymentAccount =
            (vm.insurerClaim.bankingDetails == null ||
              (vm.insurerClaim.bankingDetails != null &&
                vm.insurerClaim.bankingDetails.paymentAccountId === 0)) &&
            vm.memberInvoiceAmount > 0;

          // Assigning first util row.
          const defaultUtil = {
            amount: data.mainTicket.coveredAmount,
            organizationServiceType: data.mainTicket.serviceType,
          };

          vm.utilization = angular.copy(
            _.defaultTo(
              _.find(
                data.utilizationDtos,
                (u) => u.organizationServiceType === data.mainTicket.serviceType
              ),
              defaultUtil
            )
          );

          vm.hospitalizationInsuranceUtilization = angular.copy(
            _.find(data.utilizationDtos, (v) => v.organizationServiceType === 7)
          );

          // Set the amount of insurance and normal utilization for viewing purpose.

          vm.confirmedInsuranceUtilizationAmount =
            vm.insurerClaim.serviceType === 1 || vm.insurerClaim.serviceType === 5
              ? vm.hospitalizationInsuranceUtilization?.amount
              : vm.utilization.amount;
          vm.confirmedNormalUtilizationAmount = vm.utilization.amount;

          vm.getMemberAvailableBalance();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function validateInsurerCreditNoteAmount() {
      const currentTotalUtilization = vm.insuranceUtilizationAmount + vm.insurerCreditNoteAmount;
      vm.totalAmountLessThanInvoiceAmount =
        currentTotalUtilization < vm.insurerClaim.amountToInvoice;

      const newInvoiceAmount = vm.companyCreditNoteAmount + vm.memberInvoiceAmount;
      vm.totalAmountExceedsPaymentReceivedAmount =
        newInvoiceAmount > vm.insurerClaim.insurerInvoicePaidAmount;

      const initialInsurerCreditNoteAmount =
        vm.insurerClaim.amountToInvoice - vm.insurerClaim.insurerInvoicePaidAmount;
      const insurerCreditNoteIsValid = vm.insurerCreditNoteAmount <= initialInsurerCreditNoteAmount;

      // Set validity of the element according to isValid condition.

      $scope.editInsuranceUtilizationForm.insurerCreditNoteAmount.$setValidity(
        'invalid',
        insurerCreditNoteIsValid
      );

      // Update element to touched(ng-touched) so that is properly shown.

      $scope.editInsuranceUtilizationForm.insurerCreditNoteAmount.$setTouched();
    }

    function validateCompanyCreditNoteAmount() {
      const currentTotalUtilization = vm.insuranceUtilizationAmount + vm.insurerCreditNoteAmount;
      vm.totalAmountLessThanInvoiceAmount =
        currentTotalUtilization < vm.insurerClaim.amountToInvoice;

      // Credit note amount to company validity checking
      // so it wouldn't get more than ticket amount.

      const companyCreditNoteIsValid = vm.companyCreditNoteAmount <= vm.mainTicket.coveredAmount;

      // Set validity of the element according to isValid condition.

      $scope.editInsuranceUtilizationForm.companyCreditNoteAmount.$setValidity(
        'invalid',
        companyCreditNoteIsValid
      );

      // Update element to touched(ng-touched) so that is properly shown.

      $scope.editInsuranceUtilizationForm.companyCreditNoteAmount.$setTouched();
    }

    function validateMemberInvoiceAmount() {
      const currentTotalUtilization = vm.insuranceUtilizationAmount + vm.insurerCreditNoteAmount;
      vm.totalAmountLessThanInvoiceAmount =
        currentTotalUtilization < vm.insurerClaim.amountToInvoice;

      const newInvoiceAmount = vm.companyCreditNoteAmount + vm.memberInvoiceAmount;
      vm.totalAmountExceedsPaymentReceivedAmount =
        newInvoiceAmount > vm.insurerClaim.insurerInvoicePaidAmount;

      vm.missingPaymentAccount =
        (vm.insurerClaim.bankingDetails == null ||
          (vm.insurerClaim.bankingDetails != null &&
            vm.insurerClaim.bankingDetails.paymentAccountId === 0)) &&
        vm.memberInvoiceAmount > 0;

      const memberInvoiceAmountIsValid =
        vm.insurerClaim.insurerClaimCategory !== 1
          ? vm.memberInvoiceAmount <= vm.mainTicket.collectCash
          : vm.memberInvoiceAmount >= 0 &&
            vm.memberInvoiceAmount <= vm.insurerClaim.amountToInvoice;

      // Set validity of the element according to isValid condition.

      $scope.editInsuranceUtilizationForm.memberInvoiceAmount.$setValidity(
        'invalid',
        memberInvoiceAmountIsValid
      );

      // Update element to touched(ng-touched) so that is properly shown.

      $scope.editInsuranceUtilizationForm.memberInvoiceAmount.$setTouched();
    }

    function validatenormalUtilizationAmount() {
      const currentTotalUtilization = vm.insuranceUtilizationAmount + vm.insurerCreditNoteAmount;
      vm.totalAmountLessThanInvoiceAmount =
        currentTotalUtilization < vm.insurerClaim.amountToInvoice;

      const newInvoiceAmount = vm.companyCreditNoteAmount + vm.memberInvoiceAmount;
      vm.totalAmountExceedsPaymentReceivedAmount =
        newInvoiceAmount > vm.insurerClaim.insurerInvoicePaidAmount;

      // Normal utilisation amount validity checking so it wouldn't get more than ticket amount.

      const normalUtilizationIsValid = vm.normalUtilizationAmount <= vm.mainTicket.coveredAmount;

      // Set validity of the element according to isValid condition.

      $scope.editInsuranceUtilizationForm.normalUtilizationAmount.$setValidity(
        'invalid',
        normalUtilizationIsValid
      );

      // Update element to touched(ng-touched) so that is properly shown.

      $scope.editInsuranceUtilizationForm.normalUtilizationAmount.$setTouched();
    }

    function validateInsuranceUtilizationAmount() {
      const currentTotalUtilization = vm.insuranceUtilizationAmount + vm.insurerCreditNoteAmount;
      vm.totalAmountLessThanInvoiceAmount =
        currentTotalUtilization < vm.insurerClaim.amountToInvoice;

      const newInvoiceAmount = vm.companyCreditNoteAmount + vm.memberInvoiceAmount;
      vm.totalAmountExceedsPaymentReceivedAmount =
        newInvoiceAmount > vm.insurerClaim.insurerInvoicePaidAmount;

      // Insurance utilisation amount validity checking so it wouldn't
      // get less than invoice paid amount.

      const insuranceUtilizationIsValid =
        vm.insuranceUtilizationAmount >= vm.insurerClaim.insurerInvoicePaidAmount;

      // Set validity of the element according to isValid condition.

      $scope.editInsuranceUtilizationForm.insuranceUtilizationAmount.$setValidity(
        'invalid',
        insuranceUtilizationIsValid
      );

      // Update element to touched(ng-touched) so that is properly shown.

      $scope.editInsuranceUtilizationForm.insuranceUtilizationAmount.$setTouched();
    }

    function confirm() {
      vm.utilization.amount =
        vm.insurerClaim.serviceType === 1 || vm.insurerClaim.serviceType === 5
          ? vm.normalUtilizationAmount
          : vm.insuranceUtilizationAmount;
      vm.confirmedUtilizations = [];
      vm.confirmedUtilizations.push(vm.utilization);

      // Update hospitalization insurance utilization if the ticket type is GP/specialist.

      if (vm.insurerClaim.serviceType === 1 || vm.insurerClaim.serviceType === 5) {
        vm.confirmedUtilizations.push({
          amount: vm.insuranceUtilizationAmount,
          organizationServiceType: 7,
        });
      }

      vm.requestParamsForConfirmUtilization = {
        insurerCreditNoteAmount: vm.insurerCreditNoteAmount,
        companyCreditNoteAmount: vm.companyCreditNoteAmount,
        memberInvoiceAmount: vm.memberInvoiceAmount,
        normalUtilizationAmount: vm.normalUtilizationAmount,
        hospitalisationInsuranceUtilisationAmount: vm.insuranceUtilizationAmount,
        panelTicketNumber: vm.mainTicket.ticketNumber,
        panelClaimId: vm.mainTicket.ticketId,
        relatedAdmissionPanelClaimId: vm.isTicketSelected?.id,
        remarks: vm.confirmInsuranceUtilizationRemarks,
        memberUtilizations: vm.confirmedUtilizations,
        insurerId: vm.insurerClaim.insurerId,
        insurerClaimId: vm.insurerClaim.id,
        insurerClaimServiceType: vm.insurerClaim.serviceType,
        insurerClaimCategory: vm.insurerClaim.insurerClaimCategory,
        memberPaymentAccountId: vm.insurerClaim.bankingDetails.paymentAccountId,
        memberPaymentEmailAddress: vm.insurerClaim.bankingDetails.emailAddress,
        useCurrentInsuranceBenefitGroup: vm.useCurrentInsuranceBenefitGroup,
      };

      $uibModalInstance.close(vm.requestParamsForConfirmUtilization);
    }

    function close() {
      $uibModalInstance.dismiss();
    }

    function getMemberAvailableBalance() {
      vm.availableBalance = vm.insurerClaim.patientAnnualBalance;
      vm.availableBalanceIsUnlimited = vm.insurerClaim.patientAnnualBalanceIsUnlimited;
    }

    function refresh() {
      getInsurerClaim();
    }
  }
})();
