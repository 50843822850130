(() => {
  angular.module('app').component('commonInpatientTicketsDetailHospitalizationDaysComponent', {
    templateUrl: require('./hospitalizationDays.component.html'),
    controller: HospitalizationDaysController,
    controllerAs: 'vm',
    bindings: {
      ticket: '<',
      isEdit: '<',
    },
  });

  HospitalizationDaysController.$inject = ['$scope', 'moment'];

  function HospitalizationDaysController($scope, moment) {
    const vm = this;

    if (vm.ticket.hospitalizationLeaveDates) {
      vm.ticket.hospitalizationLeaveDates = vm.ticket.hospitalizationLeaveDates.map((date) =>
        moment(date).format('YYYY-MM-DD')
      );
    }
    vm.ticket.hospitalizationLeaveDates = [...new Set(vm.ticket.hospitalizationLeaveDates)];
    vm.ticket.hospitalizationDays = vm.ticket.hospitalizationLeaveDates.length;

    vm.clear = clear;
    vm.updateDatePicker = updateDatePicker;

    init();

    function init() {
      const initialAdmissionDate = moment(vm.ticket.admissionDate).startOf('day').utc();
      const initialDischargeDate = moment(vm.ticket.dischargeDate).startOf('day').utc();

      // auto-add leave day upon loading

      updateHospitalizationLeaveDates(initialAdmissionDate, initialDischargeDate);

      $scope.$watch('vm.ticket.admissionDate', () => {
        // HM-4165 to prevent hospitalizationLeaveDates from updating
        // when admissionDate doesn't change
        if (initialAdmissionDate !== vm.ticket.admissionDate) {
          const minDate = moment(vm.ticket.admissionDate);
          const maxDate = moment(vm.ticket.dischargeDate);

          updateHospitalizationLeaveDates(minDate, maxDate, true);
          updateDatePicker();
        }
      });

      $scope.$watch('vm.ticket.dischargeDate', () => {
        // HM-4165 to prevent hospitalizationLeaveDates from updating
        // when dischargeDate doesn't change
        if (initialDischargeDate !== vm.ticket.dischargeDate) {
          const minDate = moment(vm.ticket.admissionDate);
          const maxDate = moment(vm.ticket.dischargeDate);

          updateHospitalizationLeaveDates(minDate, maxDate, true);
          updateDatePicker();
        }
      });

      $scope.$watch('vm.ticket.hospitalizationLeaveDates', () => {
        vm.ticket.hospitalizationDays = vm.ticket.hospitalizationLeaveDates.length;
      });

      updateDatePicker();
    }

    function clear() {
      vm.ticket.hospitalizationDays = 0;
      vm.ticket.hospitalizationLeaveDates = [];
    }

    function updateDatePicker() {
      if (vm.ticket.admissionDate && vm.ticket.dischargeDate) {
        const minDate = moment(vm.ticket.admissionDate);
        const maxDate = moment(vm.ticket.dischargeDate);

        vm.dateRangeOptions = {
          minDate: moment(minDate.format('YYYY-MM-DD')),
          maxDate: moment(maxDate.endOf('day')),
          ranges: '',
        };
        vm.dateRangeModel = {
          min: moment(minDate),
          max: moment(maxDate),
        };
      } else {
        vm.dateRangeOptions = {
          ranges: '',
        };
        vm.dateRangeModel = {
          min: moment(),
          max: moment(),
        };
      }

      vm.dateRangeOptions.eventHandlers = {
        'apply.daterangepicker': () => {
          updateHospitalizationLeaveDates(vm.dateRangeModel.startDate, vm.dateRangeModel.endDate);
        },
      };
    }

    function updateHospitalizationLeaveDates(admissionDate, dischargeDate, clearField = false) {
      if (admissionDate == null || dischargeDate == null || dischargeDate.isValid() === false)
        return;

      let startDate = admissionDate.format('YYYY-MM-DD');
      const endDate = dischargeDate.format('YYYY-MM-DD');

      if (clearField) vm.clear();

      if (startDate === endDate) {
        vm.ticket.hospitalizationLeaveDates.push(startDate);
      } else {
        // Split date range to individual dates
        while (startDate <= endDate) {
          vm.ticket.hospitalizationLeaveDates.push(startDate);
          startDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD');
        }
      }

      // Remove duplicates
      vm.ticket.hospitalizationLeaveDates = [...new Set(vm.ticket.hospitalizationLeaveDates)];
      vm.ticket.hospitalizationDays = vm.ticket.hospitalizationLeaveDates.length;
    }
  }
})();
