(function () {
    var controllerId = 'corporate.views.benefitGroups.inpatient.benefitDetail.viewExclusionProvisions.modal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'inpatientSettings', 'abp.services.app.benefitGroup',
        function ($scope, $uibModalInstance, inpatientSettings, benefitGroupSvc) {
            function init() {
                vm.getExclusionProvisions();
            }

            var vm = this;
            vm.loading = 0;
            vm.submitting = 0;
            vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
            vm.clauseList = [];
            vm.exclusionProvisions = null;

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            vm.getExclusionProvisions = function () {
                if (inpatientSettings.hospitalizationSetting.exclusionVersionId) {
                    vm.loading++;
                    benefitGroupSvc.getBenefitGroupDefaultExclusions({
                        exclusionVersionId: inpatientSettings.hospitalizationSetting.exclusionVersionId
                    }).success(function (record) {
                        vm.exclusionProvisions = record.defaultExclusions.length > 0 ? record.defaultExclusions : null;
                    }).finally(function () {
                        vm.clauseList = _.map(vm.exclusionProvisions, 'clause');
                        vm.loading--;
                    });
                }
            }

            init();
        }
    ]);
})();
