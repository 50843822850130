import swal from 'sweetalert';

(() => {
  angular
    .module('app')
    .controller('common.views.saleTransactions.creditMemoDetail', CreditMemoDetailController);

  CreditMemoDetailController.$inject = ['$stateParams', 'abp.services.app.saleTransaction'];

  function CreditMemoDetailController($stateParams, saleTransactionSvc) {
    const vm = this;

    vm.loading = 0;

    vm.transactionNumber = $stateParams.transactionNumber;
    vm.creditMemo = null;
    vm.creditMemoFrom = null;
    vm.creditMemoTo = null;
    vm.payments = [];
    vm.isInpatient = App.isInpatient();

    vm.getPaymentLabel = getPaymentLabel;
    vm.getOriginalPaymentLabel = getOriginalPaymentLabel;
    vm.send = send;

    init();

    function init() {
      vm.loading += 1;
      saleTransactionSvc
        .getCreditMemo({
          id: vm.transactionNumber,
        })
        .success((data) => {
          vm.creditMemo = data.creditMemo;
          vm.creditMemo.isAgentCreditMemo = _.includes([4, 5, 7], vm.creditMemo.category);
          vm.creditMemoFrom = data.creditMemoFrom;
          vm.creditMemoTo = data.creditMemoTo;
          vm.payments = data.payments;
          vm.originalPaymentForExcessPayment = data.excessPaymentOriginalPayment;
          vm.currencyCode = data.currencyCode;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getPaymentLabel() {
      return App.localize('XPaymentsMade', vm.payments.length);
    }

    function getOriginalPaymentLabel() {
      return App.localize(
        'ExcessPaymentOriginalPaymentMessage',
        vm.originalPaymentForExcessPayment.transactionNumber
      );
    }

    function send() {
      saleTransactionSvc
        .getFinanceEmails({
          tenantId: vm.creditMemo.tenantId,
          subsidiaryId: vm.creditMemo.subsidiaryId,
        })
        .success((data) => {
          swal(
            {
              title: App.localize('SendEmailForX', vm.creditMemo.transactionNumber),
              text: App.localize('EmailAddresses'),
              type: 'input',
              showCancelButton: true,
              closeOnConfirm: false,
              confirmButtonColor: '#1ab394',
              inputPlaceholder: App.localize('EmailAddressesNarrative'),
              inputValue: data.emailAddresses,
              showLoaderOnConfirm: true,
            },
            (inputValue) => {
              if (inputValue === false) return false;
              if (inputValue === '') {
                swal.showInputError(App.localize('InvalidEmailAddress'));
                return false;
              }
              saleTransactionSvc
                .sendEmail({
                  transactionNumber: vm.creditMemo.transactionNumber,
                  emailAddresses: inputValue,
                })
                .success(() => {
                  abp.notify.info(App.localize('EmailSent'));
                  swal.close();
                });
              return false;
            }
          );
        });
    }
  }
})();
