(function () {
    var controllerId = 'clinic.views.hospital.common.cancelModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$uibModalInstance', 'letterRequestType', 'letterStatus', 'Hms.GuaranteeLetters.GuaranteeLetterStatus', 'Hms.ServiceRequests.ServiceRequestType', controller]);

    function controller($uibModalInstance, letterRequestType, letterStatus, enumGuaranteeLetterStatus, enumServiceRequestType) {
        var vm = this;
        vm.isSpecialist = App.isSpecialist();
        vm.reason = '';
        vm.explain = '';
        vm.letterRequestType = letterRequestType;
        vm.letterStatus = letterStatus;

        vm.enums = {};
        vm.enums.guaranteeLetterStatus = enumGuaranteeLetterStatus;
        vm.enums.serviceRequestType = enumServiceRequestType;

        vm.cancel = function () {
            $uibModalInstance.dismiss();
        };

        vm.getFinalReason = function () {
            var reason = _.trim(vm.reason);
            if (reason === 'Others') {
                var explain = _.trim(vm.explain);
                if (explain) {
                    reason += ': ' + explain;
                } else {
                    reason = '';
                }
            }
            return reason;
        };

        vm.close = function () {
            var reason = vm.getFinalReason();
            if (!reason) return;
            $uibModalInstance.close(reason);
        };
    }
})();
