(() => {
  angular.module('app').filter('monthName', monthName);

  monthName.$inject = [];

  function monthName() {
    return monthNameFilter;

    function monthNameFilter(value) {
      switch (value) {
        case 1:
          return App.localize('January');
        case 2:
          return App.localize('February');
        case 3:
          return App.localize('March');
        case 4:
          return App.localize('April');
        case 5:
          return App.localize('May');
        case 6:
          return App.localize('June');
        case 7:
          return App.localize('July');
        case 8:
          return App.localize('August');
        case 9:
          return App.localize('September');
        case 10:
          return App.localize('October');
        case 11:
          return App.localize('November');
        case 12:
          return App.localize('December');

        // no default
      }
      return '';
    }
  }
})();
