(() => {
  angular.module('app').component('commonHealthScreeningGuaranteeLettersDetailsLetterDetail', {
    templateUrl: require('./letterDetail.component.html'),
    controller: LetterDetailController,
    controllerAs: 'vm',
    bindings: {
      timeZoneId: '<'
    }
  });

  LetterDetailController.$inject = [
    '$state',
    '$stateParams',
    'abp.services.app.healthScreeningGuaranteeLetter',
    'Hms.Employees.EmployeeState',
    'Hms.GuaranteeLetters.GuaranteeLetterStatus',
    'Hms.GuaranteeLetters.GuaranteeLetterSource',
  ];

  function LetterDetailController(
    $state,
    $stateParams,
    healthScreeningGuaranteeLetterSvc,
    enumEmployeeState,
    enumGuaranteeLetterStatus,
    enumsGuaranteeLetterSource
  ) {
    const vm = this;

    vm.loading = 0;
    vm.isHost = App.isHost();
    vm.isPanel = App.isClinic();
    vm.isCorporate = App.isCorporate();
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.letterNumber = $stateParams.letterNumber;
    vm.timeZoneId = vm.timeZoneId ?? $stateParams.timeZoneId;

    vm.enums = {
      employeeState: enumEmployeeState,
      guaranteeLetterStatus: enumGuaranteeLetterStatus,
      guaranteeLetterSource: enumsGuaranteeLetterSource,
    };

    vm.permissions = {
      viewClaim:
        (abp.auth.isGranted('Claims.HealthScreening.Panel') && vm.isPanel) ||
        (abp.auth.isGranted('Claims.HealthScreening.Host') && vm.isHost),
      canSubmitClaim:
        vm.isPanel &&
        (abp.auth.isGranted('Claims.HealthScreening.Panel.Create') ||
          abp.auth.isGranted('PatientTickets.Clinic.Create')),
    };

    vm.submitClaim = submitClaim;
    vm.viewClaim = viewClaim;
    vm.getGuaranteeLetterSourceLocalizedString = getGuaranteeLetterSourceLocalizedString;

    init();

    function init() {
      getLetterDetail();
    }

    function getLetterDetail() {
      vm.loading += 1;
      healthScreeningGuaranteeLetterSvc
        .getHealthScreeningLetterDetails({
          id: vm.letterNumber,
        })
        .success((data) => {
          vm.letter = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function submitClaim() {
      $state.go('createEditHealthScreeningFinalRequest', {
        letterNumber: vm.letterNumber,
        timeZoneId: vm.timeZoneId
      });
    }

    function viewClaim() {
      $state.go('healthScreeningClaimDetail', {
        claimNumber: vm.letter.claimNumber,
        timeZoneId: vm.timeZoneId
      });
    }

    function getGuaranteeLetterSourceLocalizedString(guaranteeLetterSource) {
      const enumMap = vm.enums.guaranteeLetterSource.values.find(
        (x) => x.id === guaranteeLetterSource
      );
      if (!enumMap) {
        return '';
      }
      return enumMap.name;
    }
  }
})();
