(() => {
  angular.module('app').directive('latitudeLongitude', latitudeLongitudeDirective);

  latitudeLongitudeDirective.$inject = [];

  function latitudeLongitudeDirective() {
    const directive = {
      link,
      restrict: 'A',
      require: 'ngModel',
    };
    return directive;

    function link(scope, element, attr, ngModel) {
      function fromUser(text) {
        let latLng = null;
        let isValid = true;

        if (text && text.length) {
          isValid = false;
          const tokens = text.split(',');
          if (tokens.length === 2) {
            const lat = parseFloat(tokens[0]);
            const lng = parseFloat(tokens[1]);
            if (lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180) {
              isValid = true;
              latLng = {
                lat,
                lng,
              };
            }
          }
        }

        ngModel.$setValidity('latLng', isValid);
        return latLng;
      }
      function toUser(latLng) {
        if (latLng) {
          return `${latLng.lat}, ${latLng.lng}`;
        }
        return '';
      }
      ngModel.$parsers.push(fromUser);
      ngModel.$formatters.push(toUser);
    }
  }
})();
