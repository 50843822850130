import swal from 'sweetalert';

(() => {
  angular
    .module('app')
    .controller('common.views.specialistClaims.editClaim.index', ClaimEditController);

  ClaimEditController.$inject = [
    '$state',
    '$stateParams',
    '$uibModal',
    '$filter',
    'abp.services.app.specialistClaim',
  ];

  function ClaimEditController($state, $stateParams, $uibModal, $filter, specialistClaimSvc) {
    const vm = this;

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.loading = 0;
    vm.saving = 0;
    vm.formAbSubmission = null;
    vm.uploadFormAbOnly = true;
    vm.coPayLabel = App.localize('Copay');
    vm.isClinic = App.isClinic();
    vm.isHost = App.isHost();

    vm.claimNumber = $stateParams.claimNumber;
    vm.employeeId = $stateParams.employeeId;
    vm.panelLocationId = $stateParams.panelLocationId;
    vm.letterNumber = $stateParams.letterNumber;
    vm.claimWithTopUp = $stateParams.claimWithTopUp;
    vm.timeZoneId = $stateParams.timeZoneId;
    vm.isCreate = !vm.claimNumber;
    vm.claim = null;
    vm.canTopUp = false;
    vm.isInpatient = App.isInpatient();
    vm.doctors = [];

    vm.newTaxInvoiceAttachments = [];
    vm.newFormABAttachments = [];

    vm.hasTaxInvoice = hasTaxInvoice;
    vm.isFormAbValid = isFormAbValid;
    vm.getCoPay = getCoPay;
    vm.getExceededAmount = getExceededAmount;
    vm.getCollectAmount = getCollectAmount;
    vm.save = save;

    init();

    function init() {
      // Load claim for edit
      vm.loading += 1;
      specialistClaimSvc
        .getClaimForEdit({
          claimNumber: vm.claimNumber,
          employeeId: vm.employeeId,
          panelLocationId: vm.panelLocationId,
          letterNumber: vm.letterNumber,
        })
        .success((data) => {
          vm.claim = data;

          // Updates param

          vm.claimNumber = data.claimNumber;
          vm.employeeId = data.patient.id;
          vm.panelLocationId = data.panelLocationId;
          if (!vm.claim.totalClaimableAmount) vm.claim.totalClaimableAmount = null;

          if (vm.claim.guaranteeLetter.coPayValue) {
            let label = '';
            if (vm.claim.guaranteeLetter.isCoPayPercentage) {
              label = `${vm.claim.guaranteeLetter.coPayValue}%`;
            } else {
              label = $filter('currencyFormat')(
                vm.claim.guaranteeLetter.coPayValue,
                vm.currencyCode
              );
            }
            vm.coPayLabel = App.localize('CopayX', label);
          }

          refreshDoctors();
          vm.uploadFormAbOnly = !vm.isCreate && vm.isClinic && vm.claim.hasPatientTicket;
          if (vm.uploadFormAbOnly) {
            vm.formAbSubmission = 1;
          }

          vm.claimWithTopUp = vm.claimWithTopUp || vm.claim.guaranteeLetter.hasTopUpRequest;
          vm.canTopUp =
            !vm.isHost &&
            !vm.claim.guaranteeLetter.isFinalized &&
            vm.claim.guaranteeLetter.isSoftCap;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    /* Validate Operations */

    function hasTaxInvoice() {
      if (!vm.claim) return false;
      return (
        vm.newTaxInvoiceAttachments.length ||
        _.some(vm.claim.taxInvoiceAttachments, ['isDelete', false])
      );
    }

    function isFormAbValid() {
      if (!vm.formAbSubmission) return false;
      if (vm.formAbSubmission === 2) return true;
      return (
        vm.newFormABAttachments.length || _.some(vm.claim.formABAttachments, ['isDelete', false])
      );
    }

    /* End of Validate Operations */

    function refreshDoctors() {
      vm.loading += 1;
      specialistClaimSvc
        .getLocationDoctors({
          id: vm.panelLocationId,
        })
        .success((data) => {
          vm.doctors = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    vm.permissions = {
      createDoctor: abp.auth.isGranted('Clinic.Doctors.Create'),
      createClaim: abp.auth.isGranted('SpecialistClaims.Clinic.Create'),
      editClaim: vm.isHost
        ? abp.auth.isGranted('SpecialistClaims.Host.Edit')
        : abp.auth.isGranted('SpecialistClaims.Clinic.Edit'),
    };

    /* Calculations */

    function getCoPay() {
      if (!vm.claim || !vm.claim.guaranteeLetter) return 0;
      const amt = vm.claim.totalClaimableAmount || 0;
      if (!amt) return 0;
      let coPay = vm.claim.guaranteeLetter.coPayValue;
      if (coPay && vm.claim.guaranteeLetter.isCoPayPercentage) {
        coPay = (vm.claim.guaranteeLetter.coPayValue * amt) / 100;
      }
      coPay = Math.round(Math.max(Math.min(coPay, amt), 0) * 100) / 100;
      return coPay;
    }

    function getExceededAmount() {
      if (!vm.claim) return 0;
      const amt = vm.claim.totalClaimableAmount || 0;
      const coveredAmt = amt - vm.getCoPay();
      return Math.max(coveredAmt - vm.claim.guaranteeLetter.guaranteedAmount, 0);
    }

    function getCollectAmount() {
      return vm.getCoPay() + vm.getExceededAmount();
    }

    /* End of Calculations */

    /* Submit Claim */

    function save() {
      if (vm.saving) return;

      const uploadAbNow = vm.formAbSubmission === 1;

      const formABAttachmentIds = [];
      if (uploadAbNow) {
        _.each(vm.claim.formABAttachments, (attachment) => {
          if (!attachment.isDelete) formABAttachmentIds.push(attachment.id);
        });
      }

      const taxInvoiceAttachmentIds = [];
      _.each(vm.claim.taxInvoiceAttachments, (attachment) => {
        if (!attachment.isDelete) taxInvoiceAttachmentIds.push(attachment.id);
      });

      const input = {
        claimNumber: vm.claimNumber,
        employeeId: vm.employeeId,
        panelLocationId: vm.panelLocationId,
        referenceNumber: vm.claim.referenceNumber,
        totalClaimableAmount: vm.claim.totalClaimableAmount,
        attendingDoctors: vm.claim.attendingDoctors,
        formABAttachmentIds,
        formABAttachments: uploadAbNow ? vm.newFormABAttachments : null,
        taxInvoiceAttachmentIds,
        taxInvoiceAttachment: _.first(vm.newTaxInvoiceAttachments),
        letterNumber: vm.letterNumber,
      };

      if (vm.isCreate || (!vm.isCreate && vm.claim.status === 1)) {
        input.claimWithTopUp = vm.claimWithTopUp;
      }

      // Submit claim.

      vm.saving += 1;
      specialistClaimSvc
        .submitClaim(input)
        .success((data) => {
          vm.claimNumber = data.claimNumber;
          abp.notify.success(App.localize('SuccessfullySubmitted'));
          if (vm.isHost) returnToClaimDetail();
          else showSuccessModal();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function showSuccessModal() {
      const exceededAmount = vm.getCollectAmount();
      if (vm.claimWithTopUp && vm.canTopUp && exceededAmount) {
        swal(
          {
            title: App.localize('RequestTopUpTitle'),
            text: App.localize('TawkNotificationMessage'),
            type: 'warning',
          },
          () => {
            $state.go('specialistClaimDetail', {
              claimNumber: vm.claimNumber,
              showZen: 1,
              timeZoneId: vm.timeZoneId
            });
          }
        );
        App.swal.disableButtons(5);
      } else {
        $uibModal.open({
          templateUrl: require('./createEditSuccessModal.html'),
          controller: 'common.views.specialistClaims.editClaim.createEditSuccessModal as vm',
          backdrop: 'static',
          size: 'sm',
          resolve: {
            claimNumber() {
              return vm.claimNumber;
            },
            exceededAmount,
            timeZoneId() {
              return vm.timeZoneId;
            }
          },
        });
      }
    }

    /* End of Submit Claim */

    function returnToClaimDetail() {
      $state.go('specialistClaimDetail', {
        claimNumber: vm.claimNumber,
        timeZoneId: vm.timeZoneId
      });
    }
  }
})();
