(() => {
  angular.module('app').controller('common.views.languages.index', LanguagePageController);

  LanguagePageController.$inject = ['$state', '$uibModal', 'abp.services.app.language'];

  function LanguagePageController($state, $modal, languageSvc) {
    const vm = this;

    vm.loading = 0;
    vm.defaultLanguageName = null;
    vm.currentTenantId = abp.session.tenantId;

    vm.permissions = {
      create: abp.auth.isGranted('Administration.Languages.Create'),
      edit: abp.auth.isGranted('Administration.Languages.Edit'),
      changeTexts: abp.auth.isGranted('Administration.Languages.ChangeTexts'),
      delete: abp.auth.isGranted('Administration.Languages.Delete'),
    };

    vm.languageGridOptions = {
      appScopeProvider: vm,
      columnDefs: [
        {
          enableSorting: false,
          name: App.localize('Actions'),
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
        },
        {
          displayName: App.localize('Name'),
          field: 'displayName',
          minWidth: 120,
          cellTemplate: 'nameTemplate',
        },
        {
          displayName: App.localize('Code'),
          field: 'name',
          minWidth: 120,
        },
        {
          displayName: `${App.localize('System')} *`,
          field: 'tenantId',
          width: 100,
          cellTemplate: 'systemTemplate',
        },
        {
          displayName: App.localize('CreationDate'),
          field: 'creationTime',
          cellFilter: "momentFormat: 'L'",
          minWidth: 140,
        },
      ],
      data: [],
    };

    vm.getLanguages = getLanguages;
    vm.createLanguage = createLanguage;
    vm.editLanguage = editLanguage;
    vm.deleteLanguage = deleteLanguage;
    vm.changeTexts = changeTexts;
    vm.setAsDefaultLanguage = setAsDefaultLanguage;

    init();

    function init() {
      if (!vm.permissions.edit && !vm.permissions.changeTexts && !vm.permissions.delete)
        vm.languageGridOptions.columnDefs.shift();

      if (!vm.currentTenantId) {
        vm.languageGridOptions.columnDefs.splice(vm.languageGridOptions.columnDefs.length - 2, 1);
      }

      vm.getLanguages();
    }

    function showEditModal(id) {
      const modal = $modal.open({
        templateUrl: require('./createOrEditModal.html'),
        controller: 'common.views.languages.createOrEditModal as vm',
        backdrop: 'static',
        resolve: {
          languageId() {
            return id;
          },
        },
      });
      modal.result.then(() => {
        vm.getLanguages();
      });
    }

    function getLanguages() {
      vm.loading += 1;
      languageSvc
        .getLanguages({})
        .success((data) => {
          vm.languageGridOptions.data = data.items;
          vm.defaultLanguageName = data.defaultLanguageName;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function createLanguage() {
      showEditModal();
    }

    function editLanguage(language) {
      showEditModal(language.id);
    }

    function deleteLanguage(language) {
      abp.message.confirm(
        App.localize('LanguageDeleteWarningMessage', language.displayName),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            languageSvc
              .deleteLanguage({
                id: language.id,
              })
              .success(() => {
                vm.getLanguages();
                abp.notify.success(App.localize('SuccessfullyDeleted'));
              });
          }
        }
      );
    }

    function setAsDefaultLanguage(language) {
      languageSvc
        .setDefaultLanguage({
          name: language.name,
        })
        .success(() => {
          vm.getLanguages();
          abp.notify.success(App.localize('SuccessfullySaved'));
        });
    }

    function changeTexts(language) {
      $state.go('languageTexts', {
        languageName: language.name,
      });
    }
  }
})();
