(function () {
    var componentId = 'clinicPatientCommonPatientRelatedTreatmentComponent';
    var app = angular.module('app');

    function controller($scope, $state, $uibModal, $interval, $filter) {
        var vm = this;
        vm.searchTreatmentPlaceholder = App.localize('TypeTreatmentKeywordsPlaceholder');

        vm.elementId = 'uncoveredTreatmentTree_' + Math.floor(Math.random() * Date.now());

        vm.searchRelatedTreatment = searchRelatedTreatment;

        function init() {
            var flattenRelatedTreatments = _.uniqBy(
                _.flattenDeep(vm.relatedTreatments, function (treatment) {
                    return treatment;
                }), 'id');

            var childTreatments = _.filter(flattenRelatedTreatments, function (treatment) {
                return treatment.parentId !== null;
            });

            _.each(childTreatments, function (treatment) {
                var parent = _.find(flattenRelatedTreatments, function (t) {
                    return t.id === treatment.parentId;
                });

                if (typeof (parent.children) === 'undefined') parent.children = [];
                parent.children.push(treatment);
            });

            vm.flattenRelatedTreatments = flattenRelatedTreatments;
        }

        function buildTree() {
            var element = $('#' + vm.elementId);
            var ref = $.jstree.reference(element);
            if (ref) {
                ref.destroy();
            }

            var uncoveredNodeIcon = '<i class="fas fa-times text-danger"></i> ';
            var coveredNodeIcon = '<i class="fas fa-check text-navy"></i> ';
            function getNodeText(data) {
                if (data.isCovered)
                    return coveredNodeIcon + data.displayName;
                else
                    return uncoveredNodeIcon + data.displayName;
            }

            var treatments = _.map(vm.flattenRelatedTreatments, function (record) {
                return {
                    id: record.id,
                    parent: record.parentId ? record.parentId : '#',
                    displayName: record.displayName,
                    text: getNodeText(record),
                    state: {
                        opened: record.hasUncoveredChild
                    }
                };
            });

            element.jstree({
                core: {
                    data: treatments
                },
                types: {
                    'default': {
                        icon: 'fas fa-folder tree-item-icon-color icon-lg'
                    },
                    file: {
                        icon: 'fas fa-file tree-item-icon-color icon-lg'
                    }
                },
                search: {
                    fuzzy: false,
                    show_only_matches: true,
                    show_only_matches_children: true,
                },
                sort: function (itemA, itemB) {
                    var nodeA = this.get_node(itemA);
                    var nodeB = this.get_node(itemB);
                    if (nodeB.original.id === -1) { return 1; }
                    if (nodeA.children_d.length > 0 && nodeB.children_d.length === 0) { return -1; }

                    return nodeB.original.displayName < nodeA.original.displayName ? 1 : -1;
                },
                plugins: ['types', 'search', 'sort']
            }).bind("select_node.jstree", function (e, data) {
                var href = data.node.a_attr.href;
                if (href === '#')
                    return '';

                window.open(href);
            });
        }

        function searchRelatedTreatment() {
            var element = $('#' + vm.elementId);
            element.jstree('search', _.trim(vm.filter));
        }

        $(document).ready(function () {
            $('.dd').nestable();
            buildTree();
        });

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/clinic/views/patient/common/patientRelatedTreatment.component.html'),
        controller: ['$scope', '$state', '$uibModal', '$interval', '$filter', controller],
        controllerAs: 'vm',
        bindings: {
            relatedTreatments: '<'
        }
    });
})();
