import swal from 'sweetalert';

(() => {
  angular.module('app').component('commonInpatientNprClaimsDetailsClaimBenefitCoverageComponent', {
    templateUrl: require('./benefitCoverage.component.html'),
    controller: BenefitCoverageController,
    controllerAs: 'vm',
    bindings: {
      receiptDate: '<',
      coPay: '=',
      roomAndBoardCharges: '=',
      hospitalizationBenefits: '=',
      claimType: '<'
    },
  });

  BenefitCoverageController.$inject = [
    '$stateParams',
    '$state',
    '$filter',
    'abp.services.app.nprClaim',
  ];

  function BenefitCoverageController($stateParams, $state, $filter, nprClaimSvc) {
    const vm = this;

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.defaultMaxAllowance = parseFloat(abp.setting.get('Hms.General.MaxAllowanceAmountLimit'));
    vm.hasSobEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');
    vm.loading = 0;
    vm.claimNumber = $stateParams.claimNumber;

    vm.hasTxtFileSupport = abp.setting.getBoolean('Hms.Feature.TxtFileSupport');
    vm.hasHospitalizationWaitingPeriodEnhancement = abp.setting.getBoolean('Hms.Feature.HospitalisationWaitingPeriodEnhancement');

    vm.policyIsOpen = true;
    const hospitalizationTitle = App.localize('Hospitalization');
    const maternityTitle = App.localize('Maternity');

    let previousDate = null;

    vm.$doCheck = $doCheck;

    init();

    function init() {}

    function $doCheck() {
      if (!_.isEqual(vm.receiptDate, previousDate)) {
        getCoverage();
        previousDate = vm.receiptDate;
      }
    }

    function getCoverage() {
      vm.loading += 1;
      nprClaimSvc
        .getBenefitCoverage({
          claimNumber: vm.claimNumber,
          receiptDate: vm.receiptDate,
        })
        .success((data) => {
          vm.isOpen = true;
          vm.benefit = data;

          if (typeof vm.coPay === 'undefined' || vm.coPay === null) {
            vm.coPay = {
              isRoomAndBoardExceedEntitlement: false,
              isCoInsurance: false,
              isPercentage: false,
              coPayExclusions: [],
              value: 0,
            };

            vm.roomAndBoardCharges = 0;
          }

          vm.benefit.isMaternity = vm.benefit.serviceType === 8;

          if (vm.benefit.serviceType === 7) {
            const inpatientSettings =
              vm.benefit.employeeType === 0
                ? vm.benefit.hospitalizationSetting.principalScheduleOfBenefit
                : vm.benefit.hospitalizationSetting.dependentScheduleOfBenefit;

            if (
              vm.benefit.employeeType === 1 &&
              vm.benefit.hospitalizationSetting.dependentScheduleOfBenefit === null
            ) {
              swal(
                {
                  title: App.localize('MissingBenefitGroupNarrative'),
                  type: 'error',
                  showCancelButton: false,
                  closeOnConfirm: false,
                  confirmButtonColor: '#1ab394',
                  confirmButtonText: App.localize('ReturnToClaimsListing'),
                },
                (isConfirm) => {
                  if (isConfirm) {
                    swal.close();
                    $state.go('inpatientNpr');
                  }
                }
              );
            }

            vm.benefit.coPay = null;
            if (vm.benefit.hospitalizationCoPay) {
              if (vm.benefit.hospitalizationCoPay.isPercentage) {
                vm.benefit.coPay = {
                  title:
                    vm.hasTxtFileSupport && vm.benefit.hospitalizationSetting.coPayTypeName
                      ? `${vm.benefit.hospitalizationSetting.coPayTypeName.concat(
                          ' ',
                          vm.benefit.hospitalizationCoPay.value
                        )}%`
                      : App.localize('CopayX', `${vm.benefit.hospitalizationCoPay.value}%`),
                  text: App.localize(
                    'CopayPercentage',
                    `${vm.benefit.hospitalizationCoPay.value}%`
                  ),
                };
                vm.coPay.isPercentage = true;
              } else {
                const copayHospitalizationText = $filter('currencyFormat')(
                  vm.benefit.hospitalizationCoPay.value,
                  vm.currencyCode
                );
                vm.benefit.coPay = {
                  title:
                    vm.hasTxtFileSupport && vm.benefit.hospitalizationSetting.coPayTypeName
                      ? vm.benefit.hospitalizationSetting.coPayTypeName.concat(
                          ' ',
                          copayHospitalizationText
                        )
                      : App.localize('CopayX', copayHospitalizationText),
                  text: App.localize('CopayFixedAmount', copayHospitalizationText),
                };
                vm.coPay.isPercentage = false;
              }

              vm.coPay.isRoomAndBoardExceedEntitlement =
                vm.benefit.hospitalizationSetting.isRoomAndBoardExceedEntitlement;
              vm.coPay.isCoInsurance = vm.benefit.hospitalizationSetting.isCoInsurance;
              vm.coPay.coPayExclusions = vm.benefit.hospitalizationSetting.coPayExclusions;
              
              // Copay for pre- / post-hospitalization claim

              if ((vm.claimType === 10 
                  && vm.benefit.hospitalizationCoPay.isAffectingPreHospitalization)
                || (vm.claimType === 11 
                  && vm.benefit.hospitalizationCoPay.isAffectingPostHospitalization)) {
                vm.coPay.value = vm.benefit.hospitalizationCoPay.value;
              }

              // Copay for hospitalization claim

              if (vm.claimType === 7 || vm.claimType === 8 || vm.claimType === 9
                && (vm.benefit.hospitalizationCoPay.isAffectingAdmission 
                  || vm.benefit.hospitalizationCoPay.isAffectingSelfPayHospitalization)) {
                vm.coPay.value = vm.benefit.hospitalizationCoPay.value;
              }
            }

            vm.benefit.allowance = Math.min(
              vm.benefit.hospitalizationSetting.allowance,
              vm.defaultMaxAllowance
            );
            vm.benefit.hospitalizationBenefits =
              vm.benefit.hospitalizationSetting.hospitalizationBenefits;
            vm.hospitalizationBenefits = vm.benefit.hospitalizationBenefits;
            vm.benefit.displayBalancePool = [];

            if (
              vm.benefit.hospitalizationSetting.balancePool &&
              vm.benefit.hospitalizationSetting.balancePool.length > 0
            ) {
              _.each(vm.benefit.hospitalizationSetting.balancePool, (pool) => {
                getDisplayBalancePool(pool, vm.benefit.displayBalancePool);
              });
            }

            vm.isAccomodationDaysCombined = inpatientSettings.accomodationDaysCombined;
            vm.roomAndBoardAccomodationDays = inpatientSettings.roomAndBoard.accomodationDays;
            vm.icuRoomAndBoardAccomodationDays = inpatientSettings.icuRoomAndBoard.accomodationDays;

            vm.physicianVisitPerDay =
              inpatientSettings.doctorCharges.inHospitalPhysicianVisitPerDay;
            vm.physicianDaysPerYear = inpatientSettings.doctorCharges.coveredDays;
            vm.hospitalCharges = inpatientSettings.hospitalCharges;
            vm.surgicalFee = inpatientSettings.hospitalCharges.surgicalFee;
            vm.anaesthetistFee = inpatientSettings.hospitalCharges.anaesthetistFee;
            vm.operatingTheatre = inpatientSettings.hospitalCharges.operatingTheatre;
            vm.hospitalSuppliesAndServices =
              inpatientSettings.hospitalCharges.hospitalSuppliesAndServices;
            vm.ambulanceFee = inpatientSettings.ambulanceFee;
            vm.organTransplant = inpatientSettings.organTransplant;
            vm.medicalReport = inpatientSettings.medicalReport;

            vm.preHospitalizationTreatment = inpatientSettings.preHospitalizationTreatment;
            vm.specialistConsultation = inpatientSettings.specialistConsultation;
            vm.surgicalOpinion = inpatientSettings.surgicalOpinion;
            vm.postHospitalizationTreatment = inpatientSettings.postHospitalizationTreatment;
            vm.followUpTreatment = inpatientSettings.followUpTreatment;
            vm.outpatientCancerTreatment = inpatientSettings.outpatientCancerTreatment;
            vm.outpatientKidneyDialysisTreatment =
              inpatientSettings.outpatientKidneyDialysisTreatment;
            vm.roomAndBoardCharges = inpatientSettings.roomAndBoard.amount;

            vm.benefit.inpatientSob = [];
            vm.benefit.outpatientSob = [];
            vm.benefit.miscSob = [];

            if (vm.hasTxtFileSupport) {
              // Inpatient
              // #region InpatientMedical SOB tab
              const { roomAndBoard } = inpatientSettings;
              const { icuRoomAndBoard } = inpatientSettings;
              const { childGuardianBenefit } = inpatientSettings;
              const { hospitalSuppliesAndServices } = inpatientSettings.hospitalCharges;
              const { doctorCharges } = inpatientSettings;
              const { ambulanceFee } = inpatientSettings;
              const { medicalReport } = inpatientSettings;
              vm.benefit.inpatientSob.push({
                type: App.localize('InpatientMedical'),
                settings: [
                  {
                    name: App.localize('RoomAndBoard'),
                    isCovered: true,
                    isAsCharged: false,
                    amount: roomAndBoard.amount,
                    effectivePeriod: 'Per day',
                    daysPerYearLimit: vm.roomAndBoardAccomodationDays,
                    isAccomodationDaysCombined: vm.isAccomodationDaysCombined,
                    accomodationDaysCombineText: App.localize('CombinedRoomAndBoardNarrative'),
                    remarks: roomAndBoard.remarks,
                  },
                  {
                    name: App.localize('IcuRoomAndBoard'),
                    isCovered: true,
                    isAsCharged: inpatientSettings.icuRoomAndBoard.isAsCharged,
                    amount: inpatientSettings.icuRoomAndBoard.amount,
                    effectivePeriod: 'Per day',
                    daysPerYearLimit: vm.icuRoomAndBoardAccomodationDays,
                    isAccomodationDaysCombined: vm.isAccomodationDaysCombined,
                    accomodationDaysCombineText: App.localize('CombinedIcuRoomAndBoardNarrative'),
                    remarks: icuRoomAndBoard.remarks,
                  },
                  {
                    name: App.localize('InsuredChildDailyGuardianBenefit'),
                    isCovered: childGuardianBenefit.isCovered,
                    isAsCharged: childGuardianBenefit.isAsCharged,
                    amount: childGuardianBenefit.amount,
                    effectivePeriod: 'Per day',
                    daysPerYearLimit: childGuardianBenefit.accomodationDays,
                    remarks: childGuardianBenefit.remarks,
                  },
                  {
                    name: App.localize('HospitalSuppliesAndServices'),
                    isCovered: hospitalSuppliesAndServices.isCovered,
                    isAsCharged: hospitalSuppliesAndServices.isAsCharged,
                    amount: hospitalSuppliesAndServices.amount,
                    effectivePeriod: coveredCycleToEffectivePeriod(
                      hospitalSuppliesAndServices.coveredCycle
                    ),
                    remarks: hospitalSuppliesAndServices.remarks,
                  },
                  {
                    name: App.localize('InHospitalPhysicianVisit'),
                    isCovered: true,
                    isAsCharged: true,
                    isThirteenthSchedule: true,
                    effectivePeriod: 'Per day',
                    noOfVisit: doctorCharges.isUnlimitedVisitPerDay
                      ? App.localize('Unlimited')
                      : doctorCharges.inHospitalPhysicianVisitPerDay,
                    daysPerYearLimit: doctorCharges.isUnlimitedCoveredDays
                      ? App.localize('Unlimited')
                      : doctorCharges.coveredDays,
                    remarks: doctorCharges.remarks,
                  },
                  {
                    name: App.localize('AmbulanceFee'),
                    isCovered: ambulanceFee.isCovered,
                    isAsCharged: ambulanceFee.isAsCharged,
                    amount: ambulanceFee.amount,
                    effectivePeriod: coveredCycleToEffectivePeriod(ambulanceFee.coveredCycle),
                    remarks: ambulanceFee.remarks,
                  },
                  {
                    name: App.localize('MedicalReportFee'),
                    isCovered: medicalReport.isCovered,
                    isAsCharged: medicalReport.isAsCharged,
                    amount: medicalReport.amount,
                    effectivePeriod: coveredCycleToEffectivePeriod(medicalReport.coveredCycle),
                    remarks: medicalReport.remarks,
                  },
                ],
                hasEffectivePeriod: true,
                hasNoOfVisit: true,
                hasDaysPerYear: vm.hasSobEnhancement,
              });
              // #endregion InpatientMedical SOB tab

              // #region InpatientSurgical SOB tab
              const { surgicalFee } = inpatientSettings.hospitalCharges;
              const { anaesthetistFee } = inpatientSettings.hospitalCharges;
              const { operatingTheatre } = inpatientSettings.hospitalCharges;
              const { daySurgery } = inpatientSettings;
              const { organTransplant } = inpatientSettings;
              vm.benefit.inpatientSob.push({
                type: App.localize('InpatientSurgical'),
                settings: [
                  {
                    name: App.localize('SurgicalFee'),
                    isCovered: surgicalFee.isCovered,
                    isAsCharged: surgicalFee.isAsCharged,
                    amount: surgicalFee.amount,
                    effectivePeriod: coveredCycleToEffectivePeriod(surgicalFee.coveredCycle),
                    remarks: surgicalFee.remarks,
                  },
                  {
                    name: App.localize('AnaesthetistFee'),
                    isCovered: anaesthetistFee.isCovered,
                    isAsCharged: anaesthetistFee.isAsCharged,
                    amount: anaesthetistFee.amount,
                    effectivePeriod: 'na',
                    remarks: anaesthetistFee.remarks,
                  },
                  {
                    name: App.localize('OperatingTheatre'),
                    isCovered: operatingTheatre.isCovered,
                    isAsCharged: operatingTheatre.isAsCharged,
                    amount: operatingTheatre.amount,
                    effectivePeriod: 'na',
                    remarks: operatingTheatre.remarks,
                  },
                  {
                    name: App.localize('DaySurgery'),
                    isCovered: daySurgery.isCovered,
                    isAsCharged: daySurgery.isAsCharged,
                    amount: daySurgery.amount,
                    effectivePeriod: coveredCycleToEffectivePeriod(daySurgery.coveredCycle),
                    remarks: daySurgery.remarks,
                  },
                  {
                    name: App.localize('OrganTransplant'),
                    isCovered: organTransplant.isCovered,
                    isAsCharged: organTransplant.isAsCharged,
                    amount: organTransplant.amount,
                    effectivePeriod: coveredCycleToEffectivePeriod(organTransplant.coveredCycle),
                    remarks: organTransplant.remarks,
                  },
                ],
                hasEffectivePeriod: true,
              });
              // #endregion InpatientSurgical SOB tab

              // Outpatient
              // #region OutpatientTreatment SOB tab
              const outpatientCancer = inpatientSettings.outpatientCancerTreatment;
              const outpatientKidneyDialysis = inpatientSettings.outpatientKidneyDialysisTreatment;
              const outpatientPhysiotherapy = inpatientSettings.outpatientPhysiotherapyTreatment;
              vm.benefit.outpatientSob.push({
                type: App.localize('OutpatientTreatment'),
                settings: [
                  {
                    name: App.localize('OutpatientCancerTreatment'),
                    isCovered: outpatientCancer.isCovered,
                    isAsCharged: outpatientCancer.isAsCharged,
                    amount: outpatientCancer.amount,
                    effectivePeriod: coveredCycleToEffectivePeriod(outpatientCancer.coveredCycle),
                    remarks: outpatientCancer.remarks,
                  },
                  {
                    name: App.localize('OutpatientKidneyDialysisTreatment'),
                    isCovered: outpatientKidneyDialysis.isCovered,
                    isAsCharged: outpatientKidneyDialysis.isAsCharged,
                    amount: outpatientKidneyDialysis.amount,
                    effectivePeriod: coveredCycleToEffectivePeriod(
                      outpatientKidneyDialysis.coveredCycle
                    ),
                    remarks: outpatientKidneyDialysis.remarks,
                  },
                  {
                    name: App.localize('OutpatientPhysiotherapyTreatment'),
                    isCovered: outpatientPhysiotherapy.isCovered,
                    isAsCharged: outpatientPhysiotherapy.isAsCharged,
                    amount: outpatientPhysiotherapy.amount,
                    effectivePeriod: coveredCycleToEffectivePeriod(
                      outpatientPhysiotherapy.coveredCycle
                    ),
                    remarks: outpatientPhysiotherapy.remarks,
                  },
                ],
                hasEffectivePeriod: true,
              });
              // #endregion OutpatientTreatment SOB tab

              // #region PreHospitalizationTreatment SOB tab
              const { diagnosisTreatment } = inpatientSettings;
              const { specialistConsultation } = inpatientSettings;
              const { surgicalOpinion } = inpatientSettings;
              vm.benefit.outpatientSob.push({
                type: App.localize('PreHospitalization'),
                settings: [
                  {
                    name: App.localize('PreHospitalizationDiagnosisTest'),
                    isCovered: diagnosisTreatment.isCovered,
                    isAsCharged: diagnosisTreatment.isAsCharged,
                    amount: diagnosisTreatment.amount,
                    effectivePeriod: coveredCycleToEffectivePeriod(diagnosisTreatment.coveredCycle),
                    remarks: diagnosisTreatment.remarks,
                  },
                  {
                    name: App.localize('PreHospitalizationSpecialistConsultation'),
                    isCovered: specialistConsultation.isCovered,
                    isAsCharged: specialistConsultation.isAsCharged,
                    amount: specialistConsultation.amount,
                    effectivePeriod: coveredCycleToEffectivePeriod(
                      specialistConsultation.coveredCycle
                    ),
                    remarks: specialistConsultation.remarks,
                  },
                  {
                    name: App.localize('SecondSurgicalOpinion'),
                    isCovered: surgicalOpinion.isCovered,
                    isAsCharged: surgicalOpinion.isAsCharged,
                    amount: surgicalOpinion.amount,
                    effectivePeriod: coveredCycleToEffectivePeriod(surgicalOpinion.coveredCycle),
                    daysPerYearLimit: surgicalOpinion.isUnlimited
                      ? 'Unlimited'
                      : surgicalOpinion.coveredTimes,
                    remarks: surgicalOpinion.remarks,
                  },
                ],
                hasMaxDaysTime: false,
                hasEffectivePeriod: true,
                hasDaysPerYear: vm.hasSobEnhancement,
              });
              // #endregion PreHospitalizationTreatment SOB tab

              // #region PostHospitalizationTreatment SOB tab
              const postHospitalizationTreatment = inpatientSettings.followUpTreatment;
              vm.benefit.outpatientSob.push({
                type: App.localize('PostHospitalization'),
                settings: [
                  {
                    name: App.localize('PostHospitalizationFollowUpAndTreatment'),
                    isCovered: postHospitalizationTreatment.isCovered,
                    isAsCharged: postHospitalizationTreatment.isAsCharged,
                    amount: postHospitalizationTreatment.amount,
                    effectivePeriod: coveredCycleToEffectivePeriod(
                      postHospitalizationTreatment.coveredCycle
                    ),
                    remarks: postHospitalizationTreatment.remarks,
                  },
                ],
                hasMaxDaysTime: false,
                hasEffectivePeriod: true,
                hasDaysPerYear: vm.hasSobEnhancement,
              });
              // #endregion PostHospitalizationTreatment SOB tab

              // #region EmergencyOutpatientTreatment SOB tab
              const { emergencyAccidentalOutpatientTreatment } = inpatientSettings;
              const { emergencyAccidentalDentalTreatment } = inpatientSettings;
              const { emergencyAccidentalSicknessTreatment } = inpatientSettings;

              let emergencyAccidentalOutpatientFollowUpPeriod = null;
              if (emergencyAccidentalOutpatientTreatment.followUpDays) {
                emergencyAccidentalOutpatientFollowUpPeriod = `${App.localize(
                  'XDays',
                  emergencyAccidentalOutpatientTreatment.followUpDays
                )}`;
              } else if (emergencyAccidentalOutpatientTreatment.followUpTimes) {
                emergencyAccidentalOutpatientFollowUpPeriod = `${App.localize(
                  'XTimes',
                  emergencyAccidentalOutpatientTreatment.followUpTimes
                )}`;
              }

              let emergencyAccidentalDentalFollowUpPeriod = null;
              if (emergencyAccidentalDentalTreatment.followUpDays) {
                emergencyAccidentalDentalFollowUpPeriod = `${App.localize(
                  'XDays',
                  emergencyAccidentalDentalTreatment.followUpDays
                )}`;
              } else if (emergencyAccidentalDentalTreatment.followUpTimes) {
                emergencyAccidentalDentalFollowUpPeriod = `${App.localize(
                  'XTimes',
                  emergencyAccidentalDentalTreatment.followUpTimes
                )}`;
              }

              vm.benefit.outpatientSob.push({
                type: App.localize('EmergencyOutpatientTreatment'),
                settings: [
                  {
                    name: App.localize('EmergencyAccidentalOutpatientTreatment'),
                    isCovered: emergencyAccidentalOutpatientTreatment.isCovered,
                    isAsCharged: emergencyAccidentalOutpatientTreatment.isAsCharged,
                    amount: emergencyAccidentalOutpatientTreatment.amount,
                    effectivePeriod: coveredCycleToEffectivePeriod(
                      emergencyAccidentalOutpatientTreatment.coveredCycle
                    ),
                    followUpPeriod: emergencyAccidentalOutpatientFollowUpPeriod,
                    followUpEffectivePeriod: App.localize('PerDisability'),
                    remarks: emergencyAccidentalOutpatientTreatment.remarks,
                  },
                  {
                    name: App.localize('EmergencyAccidentalDentalTreatment'),
                    isCovered: emergencyAccidentalDentalTreatment.isCovered,
                    isAsCharged: emergencyAccidentalDentalTreatment.isAsCharged,
                    amount: emergencyAccidentalDentalTreatment.amount,
                    effectivePeriod: coveredCycleToEffectivePeriod(
                      emergencyAccidentalDentalTreatment.coveredCycle
                    ),
                    followUpPeriod: emergencyAccidentalDentalFollowUpPeriod,
                    followUpEffectivePeriod: App.localize('PerDisability'),
                    remarks: emergencyAccidentalDentalTreatment.remarks,
                  },
                  {
                    name: App.localize('EmergencyOutpatientSicknessTreatment'),
                    isCovered: emergencyAccidentalSicknessTreatment.isCovered,
                    isAsCharged: emergencyAccidentalSicknessTreatment.isAsCharged,
                    amount: emergencyAccidentalSicknessTreatment.amount,
                    effectivePeriod: coveredCycleToEffectivePeriod(
                      emergencyAccidentalSicknessTreatment.coveredCycle
                    ),
                    followUpPeriod: 'na',
                    remarks: emergencyAccidentalSicknessTreatment.remarks,
                  },
                ],
                hasEffectivePeriod: true,
                multiRowHeader: true,
                hasFollowUp: true,
              });
              // #endregion EmergencyOutpatientTreatment SOB tab

              // Misc
              // #region Misc SOB tab
              const { governmentHospitalDailyCashAllowance } = inpatientSettings;
              const { privateHospitalDailyCashAllowance } = inpatientSettings;
              const { homeNursingCare } = inpatientSettings;
              const { accidentalDeathBenefit } = inpatientSettings;
              const { compassionateDeathBenefit } = inpatientSettings;
              const { funeralExpenses } = inpatientSettings;
              vm.benefit.miscSob.push({
                type: App.localize('Miscellaneous'),
                settings: [
                  {
                    name: App.localize('GovernmentHospitalDailyCashAllowance'),
                    isCovered: governmentHospitalDailyCashAllowance.isCovered,
                    isAsCharged: governmentHospitalDailyCashAllowance.isAsCharged,
                    amount: governmentHospitalDailyCashAllowance.amount,
                    effectivePeriod: coveredCycleToEffectivePeriod(
                      governmentHospitalDailyCashAllowance.coveredCycle
                    ),
                    accomodationDays: governmentHospitalDailyCashAllowance.accomodationDays,
                    accomodationEffectivePeriod: App.localize('PerDay'),
                    remarks: governmentHospitalDailyCashAllowance.remarks,
                  },
                  {
                    name: App.localize('PrivateHospitalDailyCashAllowance'),
                    isCovered: privateHospitalDailyCashAllowance.isCovered,
                    isAsCharged: privateHospitalDailyCashAllowance.isAsCharged,
                    amount: privateHospitalDailyCashAllowance.amount,
                    effectivePeriod: coveredCycleToEffectivePeriod(
                      privateHospitalDailyCashAllowance.coveredCycle
                    ),
                    accomodationDays: privateHospitalDailyCashAllowance.accomodationDays,
                    accomodationEffectivePeriod: App.localize('PerDay'),
                    remarks: privateHospitalDailyCashAllowance.remarks,
                  },
                  {
                    name: App.localize('HomeNursingCare'),
                    isCovered: homeNursingCare.isCovered,
                    isAsCharged: homeNursingCare.isAsCharged,
                    amount: homeNursingCare.amount,
                    effectivePeriod: coveredCycleToEffectivePeriod(homeNursingCare.coveredCycle),
                    accomodationDays: 'na',
                    remarks: homeNursingCare.remarks,
                  },
                  {
                    name: App.localize('AccidentalDeathBenefit'),
                    isCovered: accidentalDeathBenefit.isCovered,
                    isAsCharged: accidentalDeathBenefit.isAsCharged,
                    amount: accidentalDeathBenefit.amount,
                    effectivePeriod: App.localize('PerEmploymentContract'),
                    accomodationDays: 'na',
                    remarks: accidentalDeathBenefit.remarks,
                  },
                  {
                    name: App.localize('CompassionateDeathBenefit'),
                    isCovered: compassionateDeathBenefit.isCovered,
                    isAsCharged: compassionateDeathBenefit.isAsCharged,
                    amount: compassionateDeathBenefit.amount,
                    effectivePeriod: App.localize('PerEmploymentContract'),
                    accomodationDays: 'na',
                    remarks: compassionateDeathBenefit.remarks,
                  },
                  {
                    name: App.localize('FuneralExpenses'),
                    isCovered: funeralExpenses.isCovered,
                    isAsCharged: funeralExpenses.isAsCharged,
                    amount: funeralExpenses.amount,
                    effectivePeriod: App.localize('PerEmploymentContract'),
                    accomodationDays: 'na',
                    remarks: funeralExpenses.remarks,
                  },
                ],
                hasEffectivePeriod: true,
                multiRowHeader: true,
                hasAccomodationDays: true,
              });
              // #endregion Misc SOB tab
            } else {
              vm.benefit.inpatientSob.push({
                type: App.localize('Accomodation'),
                settings: [
                  {
                    name: App.localize('RoomAndBoard'),
                    isCovered: true,
                    isAsCharged: false,
                    amount: inpatientSettings.roomAndBoard.amount,
                    effectivePeriod: 'Per day',
                    daysPerYearLimit: vm.roomAndBoardAccomodationDays,
                    isAccomodationDaysCombined: vm.isAccomodationDaysCombined,
                    accomodationDaysCombineText: App.localize('CombinedRoomAndBoardNarrative'),
                  },
                  {
                    name: App.localize('IcuRoomAndBoard'),
                    isCovered: true,
                    isAsCharged: inpatientSettings.icuRoomAndBoard.isAsCharged,
                    amount: inpatientSettings.icuRoomAndBoard.amount,
                    effectivePeriod: 'Per day',
                    daysPerYearLimit: vm.icuRoomAndBoardAccomodationDays,
                    isAccomodationDaysCombined: vm.isAccomodationDaysCombined,
                    accomodationDaysCombineText: App.localize('CombinedIcuRoomAndBoardNarrative'),
                  },
                  {
                    name: App.localize('ChildDailyGuardianBenefitLodgingOrBed'),
                    isCovered: inpatientSettings.childGuardianBenefit.isCovered,
                    isAsCharged: inpatientSettings.childGuardianBenefit.isAsCharged,
                    amount: inpatientSettings.childGuardianBenefit.amount,
                    effectivePeriod: 'Per day',
                  },
                ],
                hasMaxDaysTime: false,
                hasEffectivePeriod: true,
                hasDaysPerYear: vm.hasSobEnhancement,
              });

              if (vm.hasSobEnhancement) {
                vm.benefit.inpatientSob.push({
                  type: App.localize('DoctorCharges'),
                  settings: [
                    {
                      name: App.localize('InHospitalDoctorVisit'),
                      isCovered: true,
                      isAsCharged: true,
                      amount: null,
                      effectivePeriod: 'Per day',
                      maxDaysTime: vm.physicianVisitPerDay,
                      isThirteenthSchedule: true,
                      daysPerYearLimit: vm.physicianDaysPerYear,
                    },
                  ],
                  hasMaxDaysTime: true,
                  hasEffectivePeriod: true,
                  hasDaysPerYear: vm.hasSobEnhancement,
                });
              }

              vm.benefit.inpatientSob.push({
                type: App.localize('HospitalCharges'),
                isFixedLimit: vm.hospitalCharges.isFixedLimit,
                fixedLimitAmount: vm.hospitalCharges.fixedLimitAmount,
                settings: {
                  physicianVisit: {
                    isAsCharged: true,
                    amount: null,
                    effectivePeriod: 'Per day',
                    maxDaysTime: vm.physicianVisitPerDay,
                  },
                  surgicalFees: {
                    isAsCharged: vm.surgicalFee.isAsCharged,
                    amount: vm.surgicalFee.amount,
                    effectivePeriod: 'Per day',
                    maxDaysTime: false,
                  },
                  anaesthetistFees: {
                    isAsCharged: vm.anaesthetistFee.isAsCharged,
                    amount: vm.anaesthetistFee.amount,
                    effectivePeriod: 'Per day',
                    maxDaysTime: false,
                  },
                  operatingTheatre: {
                    isAsCharged: vm.operatingTheatre.isAsCharged,
                    amount: vm.operatingTheatre.amount,
                    effectivePeriod: 'Per day',
                    maxDaysTime: false,
                  },
                  suppliesAndServices: {
                    isAsCharged: vm.hospitalSuppliesAndServices.isAsCharged,
                    amount: vm.hospitalSuppliesAndServices.amount,
                    effectivePeriod: 'Per day',
                    maxDaysTime: false,
                  },
                  ambulanceFee: {
                    isCovered: vm.ambulanceFee.isCovered,
                    isAsCharged: vm.ambulanceFee.isAsCharged,
                    amount: vm.ambulanceFee.amount,
                    effectivePeriod: 'Per day',
                    maxDaysTime: false,
                  },
                  organTransplant: {
                    isCovered: vm.organTransplant.isCovered,
                    isAsCharged: vm.organTransplant.isAsCharged,
                    amount: vm.organTransplant.amount,
                    effectivePeriod: 'Per employment contract',
                    maxDaysTime: vm.organTransplant.isUnlimited
                      ? App.localize('Unlimited')
                      : vm.organTransplant.coveredTimes,
                  },
                },
                hasMaxDaysTime: false,
                hasEffectivePeriod: true,
              });

              vm.benefit.inpatientSob.push({
                type: App.localize('SelfPayHospitalization'),
                settings: [
                  {
                    name: App.localize('MedicalReport'),
                    isCovered: true,
                    isAsCharged: vm.medicalReport.isAsCharged,
                    amount: vm.medicalReport.amount,
                    effectivePeriod: null,
                  },
                ],
                hasMaxDaysTime: false,
                hasEffectivePeriod: false,
              });

              vm.benefit.outpatientSob = [
                {
                  type: App.localize('PreHospitalizationTreatment'),
                  settings: [
                    {
                      name: App.localize('PreHospitalizationDiagnosisTest'),
                      isCovered: vm.preHospitalizationTreatment.isCovered,
                      isAsCharged: true,
                      amount: null,
                      effectivePeriod: App.localize('PerAdmission'),
                      maxDaysTime: null,
                    },
                    {
                      name: App.localize('PreHospitalizationSpecialistConsultation'),
                      isCovered: vm.preHospitalizationTreatment.isCovered,
                      isAsCharged: true,
                      amount: null,
                      effectivePeriod: App.localize('PerAdmission'),
                      maxDaysTime: vm.specialistConsultation.isUnlimited
                        ? 'Unlimited'
                        : vm.specialistConsultation.coveredTimes,
                    },
                    {
                      name: App.localize('SecondSurgicalOpinion'),
                      isCovered: vm.preHospitalizationTreatment.isCovered,
                      isAsCharged: vm.surgicalOpinion.isAsCharged,
                      amount: vm.surgicalOpinion.amount,
                      effectivePeriod: App.localize('PerAdmission'),
                      maxDaysTime: vm.surgicalOpinion.isUnlimited
                        ? 'Unlimited'
                        : vm.surgicalOpinion.coveredTimes,
                    },
                  ],
                  hasMaxDaysTime: !!vm.preHospitalizationTreatment.isCovered,
                  hasEffectivePeriod: true,
                  coveredDays: vm.preHospitalizationTreatment.coveredDays,
                },
                {
                  type: App.localize('PostHospitalizationTreatment'),
                  settings: [
                    {
                      name: App.localize('PostHospitalizationFollowUpAndTreatment'),
                      isCovered: vm.postHospitalizationTreatment.isCovered,
                      isAsCharged: vm.followUpTreatment.isAsCharged,
                      amount: vm.followUpTreatment.amount,
                      effectivePeriod: 'Per admission',
                    },
                  ],
                  hasMaxDaysTime: false,
                  hasEffectivePeriod: true,
                  coveredDays: vm.followUpTreatment.coveredDays,
                },
                {
                  type: App.localize('Others'),
                  settings: [
                    {
                      name: App.localize('OutpatientCancerTreatment'),
                      isCovered: vm.outpatientCancerTreatment.isCovered,
                      isAsCharged: false,
                      amount: vm.outpatientCancerTreatment.amount,
                      effectivePeriod: 'Per annum',
                    },
                    {
                      name: App.localize('OutpatientKidneyDialysisTreatment'),
                      isCovered: vm.outpatientKidneyDialysisTreatment.isCovered,
                      isAsCharged: false,
                      amount: vm.outpatientKidneyDialysisTreatment.amount,
                      effectivePeriod: 'Per annum',
                    },
                  ],
                  hasMaxDaysTime: false,
                  hasEffectivePeriod: true,
                },
              ];
            }
          } else {
            const { maternitySetting } = data;

            vm.benefit.normalDelivery = maternitySetting.normalDelivery;
            vm.benefit.caesareanDelivery = maternitySetting.caesareanDelivery;
            vm.benefit.normalDeliveryTwinsAndAbove = maternitySetting.normalDeliveryTwinsAndAbove;
            vm.benefit.caesareanDeliveryTwinsAndAbove =
              maternitySetting.caesareanDeliveryTwinsAndAbove;
            vm.benefit.preNatalCheckUp = maternitySetting.preNatalCheckUp;
            vm.benefit.postNatalFollowUp = maternitySetting.postNatalFollowUp;
            vm.benefit.miscarriage = maternitySetting.miscarriage;
            vm.benefit.neoNatal = maternitySetting.neoNatal;

            vm.benefit.coPay = null;
            if (vm.benefit.maternityCoPay) {
              if (vm.benefit.maternityCoPay.isPercentage) {
                vm.benefit.coPay = {
                  title:
                    vm.hasTxtFileSupport && maternitySetting.coPayTypeName
                      ? `${maternitySetting.coPayTypeName.concat(
                          ' ',
                          vm.benefit.maternityCoPay.value
                        )}%`
                      : App.localize('CopayX', `${vm.benefit.maternityCoPay.value}%`),
                  text: App.localize('CopayPercentage', `${vm.benefit.maternityCoPay.value}%`),
                };
                vm.coPay.isPercentage = true;
              } else {
                const copayMaternityText = $filter('currencyFormat')(
                  vm.benefit.maternityCoPay.value,
                  vm.currencyCode
                );
                vm.benefit.coPay = {
                  title:
                    vm.hasTxtFileSupport && maternitySetting.coPayTypeName
                      ? maternitySetting.coPayTypeName.concat(' ', copayMaternityText)
                      : App.localize('CopayX', copayMaternityText),
                  text: App.localize('CopayFixedAmount', copayMaternityText),
                };
                vm.coPay.isPercentage = false;
              }

              vm.coPay.isRoomAndBoardExceedEntitlement = false;
              vm.coPay.isCoInsurance = maternitySetting.isCoInsurance;
              vm.coPay.coPayExclusions = maternitySetting.coPayExclusions;
              
              // Copay for pre- / post-maternity claim

              if ((vm.claimType === 12 
                  && vm.benefit.maternityCoPay.isAffectingPreNatalCheckUp)
                || (vm.claimType === 14
                  && vm.benefit.maternityCoPay.isAffectingPostNatalFollowUp)) {
                vm.coPay.value = vm.benefit.maternityCoPay.value;
              }

              // Copay for maternity claim

              if ((vm.claimType === 13 || vm.claimType === 15)
                && (vm.benefit.maternityCoPay.isAffectingNeoNatal 
                  || vm.benefit.maternityCoPay.isAffectingNormalDelivery 
                  || vm.benefit.maternityCoPay.isAffectingCaesareanDelivery)) {
                vm.coPay.value = vm.benefit.maternityCoPay.value;
              }
            }

            vm.benefit.allowance = Math.min(maternitySetting.allowance, vm.defaultMaxAllowance);
            vm.benefit.displayBalancePool = [];

            if (maternitySetting.balancePool && maternitySetting.balancePool.length > 0) {
              _.each(maternitySetting.balancePool, (pool) => {
                getDisplayBalancePool(pool, vm.benefit.displayBalancePool);
              });
            }

            vm.benefit.maternitySob = [
              {
                header: App.localize('Delivery'),
                settings: [
                  {
                    name: App.localize('NormalDelivery'),
                    isCovered: vm.benefit.normalDelivery.isCovered,
                    isAsCharged: vm.benefit.normalDelivery.isAsCharged,
                    amount: vm.benefit.normalDelivery.amount,
                    effectivePeriod:
                      vm.benefit.normalDelivery.coveredCycle === 0 ? 'Per annum' : 'Per delivery',
                  },
                  {
                    name: App.localize('CaesareanDelivery'),
                    isCovered: vm.benefit.caesareanDelivery.isCovered,
                    isAsCharged: vm.benefit.caesareanDelivery.isAsCharged,
                    amount: vm.benefit.caesareanDelivery.amount,
                    effectivePeriod:
                      vm.benefit.caesareanDelivery.coveredCycle === 0
                        ? 'Per annum'
                        : 'Per delivery',
                  },
                  {
                    name: App.localize('NormalDeliveryForTwinsAndAbove'),
                    isCovered: vm.benefit.normalDeliveryTwinsAndAbove.isCovered,
                    isAsCharged: vm.benefit.normalDeliveryTwinsAndAbove.isAsCharged,
                    amount: vm.benefit.normalDeliveryTwinsAndAbove.amount,
                    effectivePeriod:
                      vm.benefit.normalDeliveryTwinsAndAbove.coveredCycle === 0
                        ? 'Per annum'
                        : 'Per delivery',
                  },
                  {
                    name: App.localize('CaesareanDeliveryForTwinsAndAbove'),
                    isCovered: vm.benefit.caesareanDeliveryTwinsAndAbove.isCovered,
                    isAsCharged: vm.benefit.caesareanDeliveryTwinsAndAbove.isAsCharged,
                    amount: vm.benefit.caesareanDeliveryTwinsAndAbove.amount,
                    effectivePeriod:
                      vm.benefit.caesareanDeliveryTwinsAndAbove.coveredCycle === 0
                        ? 'Per annum'
                        : 'Per delivery',
                  },
                ],
                hasEffectivePeriod: true,
              },
              {
                header: App.localize('PreNatalCheckUp'),
                isCovered: vm.benefit.preNatalCheckUp.isCovered,
                coveredDaysHeader: vm.benefit.preNatalCheckUp.coveredDays,
                settings: [
                  {
                    name: App.localize('PreNatalCheckUp'),
                    isCovered: vm.benefit.preNatalCheckUp.isCovered,
                    isAsCharged: vm.benefit.preNatalCheckUp.isAsCharged,
                    amount: vm.benefit.preNatalCheckUp.amount,
                    effectivePeriod: 'Per annum',
                  },
                ],
                hasEffectivePeriod: true,
              },
              {
                header: App.localize('PostNatalFollowUp'),
                isCovered: vm.benefit.postNatalFollowUp.isCovered,
                coveredDaysHeader: vm.benefit.postNatalFollowUp.coveredDays,
                settings: [
                  {
                    name: App.localize('PostNatalFollowUp'),
                    isCovered: vm.benefit.postNatalFollowUp.isCovered,
                    isAsCharged: vm.benefit.postNatalFollowUp.isAsCharged,
                    amount: vm.benefit.postNatalFollowUp.amount,
                    effectivePeriod: 'Lifetime total',
                  },
                  {
                    name: App.localize('Miscarriage'),
                    isCovered: vm.benefit.miscarriage.isCovered,
                    isAsCharged: vm.benefit.miscarriage.isAsCharged,
                    amount: vm.benefit.miscarriage.amount,
                    effectivePeriod: null,
                  },
                ],
                hasEffectivePeriod: true,
                pregnancyRelatedComplications: [
                  'Disseminated Intravascular Coagulation',
                  'Molar Pregnancy',
                  'Eclampsia',
                  'Death of Foetus',
                  'Amniotic Fluid Embolism',
                ],
              },
              {
                header: App.localize('NeoNatal'),
                isCovered: vm.benefit.neoNatal.isCovered,
                coveredDaysHeader: vm.benefit.neoNatal.coveredDays,
                settings: [
                  {
                    name: App.localize('NewBornNICUHDU'),
                    isCovered: vm.benefit.neoNatal.isCovered,
                    isAsCharged: vm.benefit.neoNatal.isAsCharged,
                    amount: vm.benefit.neoNatal.newBornNicuAmount,
                    effectivePeriod: 'Per annum',
                  },
                  {
                    name: App.localize('NewBornIncubation'),
                    isCovered: vm.benefit.neoNatal.isCovered,
                    isAsCharged: vm.benefit.neoNatal.isAsCharged,
                    amount: vm.benefit.neoNatal.newBornIncubationAmount,
                    effectivePeriod: 'Per annum',
                  },
                  {
                    name: App.localize('NewBornPhototherapyTreatment'),
                    isCovered: vm.benefit.neoNatal.isCovered,
                    isAsCharged: vm.benefit.neoNatal.isAsCharged,
                    amount: vm.benefit.neoNatal.newBornPhotoTherapyAmount,
                    effectivePeriod: 'Per annum',
                  },
                ],
                hasEffectivePeriod: true,
              },
            ];
          }
        })
        .error((data) => {
          vm.errorMessage = data.message;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function coveredCycleToEffectivePeriod(coveredCycle = null) {
      let effectivePeriod = 'na';

      switch (coveredCycle) {
        case 1:
          effectivePeriod = 'Per day';
          break;
        case 2:
          effectivePeriod = 'Per day';
          break;
        case 3:
          effectivePeriod = 'Per annum';
          break;
        case 4:
          effectivePeriod = 'Per employment contract';
          break;
        case 5:
          effectivePeriod = 'Per report';
          break;
        case 6:
          effectivePeriod = 'Per admission';
          break;
        case 7:
          effectivePeriod = 'Per disability';
          break;

        // no default
      }

      return effectivePeriod;
    }

    function getDisplayBalancePool(poolBalance, displayArray) {
      let title = '';

      if (_.head(poolBalance.benefitTypes).id === 7) title = hospitalizationTitle;
      else if (_.head(poolBalance.benefitTypes).id === 8) title = maternityTitle;

      // TODO: Better design? localisation required

      const isBudget = poolBalance.modifierType === 0 || poolBalance.modifierType === 2;
      let cycle = '';
      if (!poolBalance.isUnlimited) {
        switch (poolBalance.modifierCycle) {
          case 0:
            cycle = `every ${poolBalance.interval} years `;
            break;
          case 1:
            cycle = 'annual ';
            break;
          case 2:
            cycle = 'monthly ';
            break;
          case 3:
            cycle = 'daily ';
            break;
          case 4:
            cycle = 'per visit ';
            break;
          case 5:
            cycle = '6 months ';
            break;
          case 6:
            cycle = '4 months ';
            break;
          case 7:
            cycle = '3 months ';
            break;
          case 8:
            cycle = '2 months ';
            break;

          // no default
        }
        if (poolBalance.modifierType === 2 || poolBalance.modifierType === 3) cycle += 'overdraft ';
      }
      title += ` (${cycle}${isBudget ? 'budget' : 'visit'})`;

      const isEmptyExcess = poolBalance.isExcess && !poolBalance.employeePortion;
      if (!isEmptyExcess) {
        displayArray.push({
          title,
          isUnlimited: poolBalance.isUnlimited,
          isExcess: poolBalance.isExcess,
          isBudget,
          limit: poolBalance.limit,
          used: poolBalance.used,
          locked: poolBalance.locked,
          balance: poolBalance.balance,
        });
      }
    }
  }
})();
