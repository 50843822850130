(() => {
  angular
    .module('app')
    .controller('common.views.patientTickets.detail.updateIssueNumberModal', ControllerController);

  ControllerController.$inject = ['$uibModalInstance'];

  function ControllerController($uibModalInstance) {
    const vm = this;

    vm.issueNumber = '';

    vm.cancel = cancel;
    vm.confirm = confirm;
    vm.tidyIssueNumber = tidyIssueNumber;

    init();

    function init() {}

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function confirm() {
      const issueNumber = tidyIssueNumber();
      $uibModalInstance.close(issueNumber);
    }

    function tidyIssueNumber() {
      if (!vm.issueNumber) return false;

      const issueNumber = _.trim(vm.issueNumber);

      return issueNumber || false;
    }
  }
})();
