(function () {
    var controllerId = 'corporate.views.employees.index';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', '$uibModal', '$timeout', 'abp.services.app.employee', 'appSession', 'abp.services.app.commonLookup', 'Hms.Employees.EmployeeState',
        function ($scope, $state, $stateParams, $modal, $timeout, employeeSvc, appSession, commonLookupSvc, enumEmployeeState) {
            var vm = this;

            function init() {
                findEmployeeFields();
                lookupBenefitGroups();
                lookupSubsidiaries();
                getCorporateSubsidiaryDepartments();
                getDepartments();
                $timeout(function () {
                    vm.employeeGridOptions.paginationCurrentPage = Math.floor(vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1);
                });
                vm.filterByStates = [{
                    name: 'Active',
                    value: 1
                }, {
                    name: 'Suspended',
                    value: 2
                }, {
                    name: 'Terminated',
                    value: 3
                }]
            }

            function showEditModal(id, tab, rehire) {
                $modal.open({
                    templateUrl: require('/App/corporate/views/employees/createOrEditModal.html'),
                    controller: 'corporate.views.employees.createOrEditModal as vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        employeeId: function () {
                            return id;
                        },
                        selectedTab: function () {
                            return tab;
                        },
                        isRehire: function () {
                            return rehire;
                        }
                    }
                }).result.then(function () {
                    vm.getEmployees();
                    $state.reload();
                });
            }

            function showProfileModal(id) {
                $modal.open({
                    templateUrl: require('/App/corporate/views/employees/viewProfileModal.html'),
                    controller: 'corporate.views.employees.viewProfileModal as vm',
                    backdrop: 'static',
                    resolve: {
                        employeeId: function () {
                            return id;
                        }
                    }
                }).result.then(function () {
                    vm.getEmployees();
                    $state.reload();
                });
            }

            function showBulkUpdateModal() {
                $modal.open({
                    templateUrl: require('/App/corporate/views/employees/batchUpdateModal.html'),
                    controller: 'corporate.views.employees.batchUpdateModal as vm',
                    backdrop: 'static',
                    size: 'lg'
                }).result.then(function () {
                    vm.getEmployees();
                    $state.reload();
                });
            }

            vm.dataExportRecordName = "EmployeeListing";
            vm.generateButtonType = App.localize('ExportToExcel');

            vm.enqueuing = 0;
            vm.loading = 0;
            vm.isSubsidiary = appSession.user.subsidiaryId;
            vm.hasPreEmployment = abp.features.isEnabled('HasPreEmploymentModule');
            vm.hasMobileApp = abp.features.isEnabled('HasMobileApp');
            vm.consentDesc = '';
            vm.organizationCountryCode = abp.setting.get('Hms.General.DefaultCountryCode');

            vm.getCorporateSubsidiaryDepartments = getCorporateSubsidiaryDepartments;
            vm.getDepartments = getDepartments;
            vm.hasInsurance = abp.features.isEnabled('HasInsurance');
            vm.switchPolicyHolderListing = switchToPolicyHolderListing;

            vm.employeeTypes = [];
            vm.employeeTypes.push({ name: App.localize('All'), value: null });
            vm.employeeTypes.push({ name: App.localize('Employee'), value: 0 });
            vm.employeeTypes.push({ name: App.localize('Dependent'), value: 1 });
            if (vm.hasPreEmployment) {
                vm.employeeTypes.push({ name: App.localize('PreEmployee'), value: 2 });
            }

            vm.enums = {};
            vm.enums.employeeState = enumEmployeeState;

            vm.hasEmployeeBulkUpdateUiEnhancement = abp.setting.getBoolean('Hms.Feature.EmployeeBulkUpdateUiEnhancement');

            vm.employeeFields = [];
            function findEmployeeFields() {
                commonLookupSvc.findEmployeeFields().success(function (data) {
                    if (data.items) {
                        vm.employeeFields = data.items;
                    }
                });
            }

            vm.requestParams = {
                filterText: $stateParams.filterText,
                employeeType: $stateParams.employeeType,
                isActive: $stateParams.isActive,
                hasMobileApp: $stateParams.hasMobileApp,
                hasDataConsent: $stateParams.hasDataConsent,
                subsidiary: $stateParams.subsidiary,
                department: $stateParams.department,
                benefitGroup: $stateParams.benefitGroup,
                states: $stateParams.states,
                skipCount: $stateParams.skipCount,
                maxResultCount: $stateParams.maxResultCount,
                sorting: $stateParams.sorting
            };

            vm.permissions = {
                create: abp.auth.isGranted('Corporate.Employees.Create'),
                edit: abp.auth.isGranted('Corporate.Employees.Edit'),
                'delete': abp.auth.isGranted('Corporate.Employees.Delete'),
                'import': abp.auth.isGranted('Corporate.Employees.Import')
            };

            vm.benefitGroups = [];
            function lookupBenefitGroups() {
                commonLookupSvc.findBenefitGroups({
                    maxResultCount: 999,
                    excludeInsurancePlans: true
                }).success(function (data) {
                    vm.benefitGroups = data.items;
                });
            }

            vm.subsidiaries = [];
            function lookupSubsidiaries() {
                commonLookupSvc.getCorporateSubsidiaries({ getDeletedSubsidiariesOrDepartment: false }).success(function (data) {
                    vm.subsidiaries = data.items;

                    // HM-2432: Show if organisation has multiple subsidiaries.
                    var subsdiaryColumn = _.find(vm.employeeGridOptions.columnDefs, 'displayName', App.localize('Subsidiary'));
                    if (!subsdiaryColumn.visible) {
                        subsdiaryColumn.visible = vm.subsidiaries.length > 1;
                    }
                });
            }

            vm.departments = [];
            var allDepartments = [];
            function getCorporateSubsidiaryDepartments() {
                if (!allDepartments.length) {
                    commonLookupSvc.getCorporateSubsidiaryDepartments({ getDeletedSubsidiariesOrDepartment: false }).success(function (data) {
                        allDepartments = data.items;
                    });
                }
            }

            function getDepartments(search) {
                var subsidiaryIds = vm.requestParams.subsidiary
                    ? vm.requestParams.subsidiary
                    : vm.isSubsidiary
                        ? appSession.user.subsidiaryId
                        : [];

                if (subsidiaryIds.length > 0) {
                    vm.departments = [];

                    // change department filter list according to selected subsidiaries

                    _.forEach(subsidiaryIds, function (id) {
                        var d = _.filter(allDepartments, { 'subsidiaryId': id });
                        vm.departments = _.concat(vm.departments, d);
                    });
                } else {
                    vm.departments = allDepartments;
                }

                if (search) {
                    vm.departments = _.filter(vm.departments, function (d) {
                        return _.includes(d.name.toLowerCase(), search.toLowerCase())
                    });

                    // only return top 10 in the department list for display

                    vm.departments = _.slice(vm.departments, 0, 10);
                } else {
                    if (!vm.requestParams.department) {
                        vm.departments = [];
                    }
                }
            }

            vm.resetDepartments = function () {
                vm.requestParams.department = [];

                vm.departments = [];
            };

            vm.employeeGridOptions = {
                appScopeProvider: vm,
                paginationPageSizes: app.consts.grid.defaultPageSizes,
                paginationPageSize: app.consts.grid.defaultPageSize,
                paginationCurrentPage: 0,
                useExternalPagination: true,
                useExternalSorting: true,
                enableSelectAll: true,
                multiSelect: true,
                isRowSelectable: function (row) {           // Determine whether the row is selectable
                    return row.entity.employeeType !== 1;
                },
                columnDefs: [{
                    enableSorting: false,
                    name: App.localize('Actions'),
                    width: 100,
                    headerCellTemplate: '<span></span>',
                    cellTemplate: 'actionTemplate'
                }, {
                    enableSorting: false,
                    name: App.localize('MobileApp'),
                    width: 30,
                    headerCellTemplate: '<span></span>',
                    field: 'hasMobileApp',
                    visible: vm.hasMobileApp,
                    cellTemplate: 'mobileTemplate'
                }, {
                    enableSorting: false,
                    name: App.localize('DataConsent'),
                    width: 35,
                    headerCellTemplate: '<span></span>',
                    field: 'hasDataConsent',
                    cellTemplate: 'dataConsentTemplate'
                }, {
                    displayName: App.localize('Subsidiary'),
                    field: 'subsidiaryName',
                    visible: vm.isSubsidiary,
                    minWidth: 100
                }, {
                    displayName: App.localize('Department'),
                    field: 'subsidiaryDepartmentName',
                    minWidth: 100
                }, {
                    displayName: App.localize('Name'),
                    field: 'name',
                    minWidth: 100,
                    cellTemplate: 'nameTemplate'
                }, {
                    displayName: App.localize('IdentificationNumber'),
                    field: 'nationalId',
                    cellFilter: 'nationalIdFilter',
                    minWidth: 100
                }, {
                    displayName: App.localize('Type'),
                    field: 'employeeType',
                    minWidth: 100,
                    cellTemplate: 'typeTemplate'
                }, {
                    displayName: App.localize('Status'),
                    field: 'state',
                    cellTemplate: 'stateTemplate',
                    minWidth: 65
                }, {
                    displayName: App.localize('EmployeeNumber'),
                    field: 'employeeNumber',
                    minWidth: 100
                }, {
                    displayName: App.localize('MainPrincipal'),
                    field: 'principalName',
                    minWidth: 100
                }, {
                    displayName: App.localize('BenefitGroup'),
                    field: 'benefitGroupName',
                    minWidth: 100
                }, {
                    displayName: App.localize('Entitlement'),
                    field: 'entitlementMode',
                    cellTemplate: 'entitlementTemplate',
                    minWidth: 65
                }, {
                    displayName: App.localize('CreationDate'),
                    field: 'creationTime',
                    cellFilter: 'momentFormat: \'L\'',
                    minWidth: 100
                }],
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                    $scope.gridApi.core.on.sortChanged($scope, function (scope, callback) {
                        var sorting = '';
                        for (var i = 0; i < callback.length; i++) {
                            if (i) sorting += ', ';
                            sorting += callback[i].field + ' ' + callback[i].sort.direction;
                        }
                        vm.requestParams.sorting = sorting;
                        vm.getEmployees();
                    });
                    gridApi.pagination.on.paginationChanged($scope, App.setPagination(vm.requestParams, vm.getEmployees));
                    gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                        vm.selectedRows = this.selection.getSelectedRows();
                    });
                    gridApi.selection.on.rowSelectionChangedBatch($scope, function (rows) {
                        vm.selectedRows = this.selection.getSelectedRows();
                    });
                },
                data: []
            };

            vm.selectedRows = [];

            vm.permissions.edit || vm.permissions.delete || vm.employeeGridOptions.columnDefs.shift();

            vm.getEmployees = function () {
                registerStateParams();

                var employeeType = parseInt(vm.requestParams.employeeType, 10);
                if (isNaN(employeeType)) employeeType = null;

                var input = {
                    skipCount: vm.requestParams.skipCount,
                    maxResultCount: vm.requestParams.maxResultCount,
                    sorting: vm.requestParams.sorting,
                    filter: vm.requestParams.filterText,
                    type: employeeType,
                    isActive: vm.requestParams.isActive,
                    states: vm.requestParams.states,
                    hasMobileApp: vm.requestParams.hasMobileApp,
                    hasDataConsent: vm.requestParams.hasDataConsent,
                    subsidiaryIds: vm.requestParams.subsidiary,
                    departmentIds: vm.requestParams.department,
                    benefitGroupIds: vm.requestParams.benefitGroup
                };
                $.extend(input, getEmployeeFieldFilters());

                vm.loading++;
                employeeSvc.getEmployees(input).success(function (data) {
                    const employeeStr = App.localize('XOfYEmployeeHasConsented',
                        data.principalConsentStats.consented, data.principalConsentStats.total);

                    const totalStr = App.localize('XOfYIndividualHasConsented',
                        data.employeeConsentStats.consented, data.employeeConsentStats.total);

                    vm.consentDesc = employeeStr + ' (' + totalStr + ')';

                    const strHasDataConsent = App.localize('Consented');
                    const strNoDataConsent = App.localize('NoConsent');
                    vm.employeeGridOptions.totalItems = data.totalCount;
                    vm.employeeGridOptions.data = data.items;
                    if (vm.hasMobileApp) {
                        _.forEach(data.items, function (d) {
                            d.dataConsentInfo = d.hasDataConsent ? strHasDataConsent : strNoDataConsent;
                        });
                    }

                    if (data.items.length > 0) {
                        // HM-2432: Show if subsidiary name is different with organisation.
                        var subsdiaryColumn = _.find(vm.employeeGridOptions.columnDefs, 'displayName', App.localize('Subsidiary'));
                        if (!subsdiaryColumn.visible) {
                            subsdiaryColumn.visible = _.first(data.items).subsidiaryName !== appSession.tenant.name;
                        }
                    }

                    vm.organizationCountryCode = data.organizationCountryCode;

                    if ($scope.gridApi) {
                        $scope.gridApi.selection.clearSelectedRows();
                        vm.selectedRows = [];
                    }
                }).finally(function (e) {
                    vm.loading--;
                });
            };

            function getEmployeeFieldFilters() {
                var employeeFieldFilters = [];
                _.each(vm.employeeFields, function (f) {
                    if (f.filter) {
                        var filter = _.trim(f.filter);
                        if (filter) {
                            employeeFieldFilters.push({
                                name: filter,
                                value: f.value
                            });
                        }
                    }
                });
                return {
                    employeeFields: employeeFieldFilters
                };
            }

            function registerStateParams() {
                $state.transitionTo($state.current,
                    {
                        employeeType: vm.requestParams.employeeType,
                        subsidiary: vm.requestParams.subsidiary,
                        department: vm.requestParams.department,
                        benefitGroup: vm.requestParams.benefitGroup,
                        states: vm.requestParams.states,
                        filterText: vm.requestParams.filterText,
                        skipCount: vm.requestParams.skipCount,
                        maxResultCount: vm.requestParams.maxResultCount,
                        sorting: vm.requestParams.sorting
                    }, {
                    notify: false
                });
            }

            function switchToPolicyHolderListing() {
                $state.go('corporate.policyHolder', {
                    employeeType: vm.requestParams.employeeType,
                    states: vm.requestParams.states,
                    filterText: vm.requestParams.filterText,
                    subsidiary: vm.requestParams.subsidiary,
                    department: vm.requestParams.department
                });
            }

            vm.exportToExcel = function () {
                vm.enqueuing++;

                registerStateParams();
                var isActive = null;
                var employeeType = parseInt(vm.requestParams.employeeType, 10);
                if (isNaN(employeeType)) employeeType = null;

                var input = {
                    skipCount: vm.requestParams.skipCount,
                    maxResultCount: vm.requestParams.maxResultCount,
                    sorting: vm.requestParams.sorting,
                    filter: vm.requestParams.filterText,
                    type: employeeType,
                    states: vm.requestParams.states,
                    IsActive: isActive,
                    hasMobileApp: vm.requestParams.hasMobileApp,
                    subsidiaryIds: vm.requestParams.subsidiary,
                    departmentIds: vm.requestParams.department,
                    benefitGroupIds: vm.requestParams.benefitGroup
                };
                $.extend(input, getEmployeeFieldFilters());

                employeeSvc.enqueueGenerateEmployeeExcelListingJob(input).success(function (data) {
                    vm.refresh();
                }).finally(function () {
                    vm.enqueuing--;
                });
            };

            vm.batchUpdate = function () {
                showBulkUpdateModal();
            }

            vm.import = function () {
                var modalWidth = '';
                if (vm.hasEmployeeBulkUpdateUiEnhancement) {
                    modalWidth = 'xl';
                }

                var modal = $modal.open({
                    templateUrl: require('./importModal.html'),
                    controller: 'corporate.views.employees.importModal as vm',
                    backdrop: 'static',
                    size: modalWidth,
                    resolve: {
                        countryCode: function () {
                            return vm.organizationCountryCode;
                        }
                    }
                });
                modal.result.then(function () {
                    vm.getEmployees();
                });
            };

            vm.createEmployee = function () {
                showEditModal();
            };

            vm.editEmployee = function (employee) {
                if (!employee.principalId) {
                    showEditModal(employee.id, 0)
                } else {
                    showEditModal(employee.principalId, 3)
                }
            };

            vm.updatePicture = function (employee) {
                $modal.open({
                    templateUrl: require('/App/corporate/views/employees/updatePictureModal.html'),
                    controller: 'corporate.views.employees.updatePictureModal as vm',
                    backdrop: 'static',
                    resolve: {
                        employeeId: function () {
                            return employee.id;
                        }
                    }
                }).result.then(function () {
                    vm.getEmployees();
                });
            };

            vm.showEmployeeProfile = function (employee) {
                showProfileModal(employee.id);
            };

            vm.deleteEmployee = function (employee) {
                var message = '';
                if (employee.principalId) {
                    message = App.localize('DependentDeleteWarningMessage', employee.principalName, employee.name);
                } else {
                    message = App.localize('EmployeeDeleteWarningMessage', employee.name);
                }
                abp.message.confirm(message, App.localize('AreYouSure'), function (d) {
                    if (d) {
                        employeeSvc.delete({
                            id: employee.id
                        }).success(function (data) {
                            vm.getEmployees();
                            abp.notify.success(App.localize('SuccessfullyDeleted'));
                        });
                    }
                });
            };

            vm.getBatchActionsNumRecords = function () {
                return App.localize('BatchActionsOnX', vm.selectedRows.length);
            };

            vm.activateEmployees = function () {
                var message = '';
                message = App.localize('ActivateConfirmMessage', vm.selectedRows.length);
                abp.message.confirm(message, App.localize('AreYouSure'), function (d) {
                    if (d) {
                        var selectedIds = _.map(vm.selectedRows, function (r) {
                            return r.id;
                        });
                        vm.loading++;
                        employeeSvc.activationBatch({
                            employeeIds: selectedIds,
                            IsActive: true
                        }).success(function (data) {
                            vm.getEmployees();
                            abp.notify.success(App.localize('SuccessfullySaved'));
                        }).finally(function () {
                            vm.loading--;
                        });
                    }
                });
            };

            vm.deactivateEmployees = function () {
                var message = '';
                message = App.localize('DeactivateConfirmMessage', vm.selectedRows.length);
                abp.message.confirm(message, App.localize('AreYouSure'), function (d) {
                    if (d) {
                        var selectedIds = _.map(vm.selectedRows, function (r) {
                            return r.id;
                        });
                        vm.loading++;
                        employeeSvc.activationBatch({
                            employeeIds: selectedIds,
                            IsActive: false
                        }).success(function (data) {
                            vm.getEmployees();
                            abp.notify.success(App.localize('SuccessfullySaved'));
                        }).finally(function () {
                            vm.loading--;
                        });
                    }
                });
            };

            vm.deleteEmployees = function () {
                var message = '';
                message = App.localize('DeleteConfirmMessage', vm.selectedRows.length);
                abp.message.confirm(message, App.localize('AreYouSure'), function (d) {
                    if (d) {
                        var selectedIds = _.map(vm.selectedRows, function (r) {
                            return r.id;
                        });
                        employeeSvc.deleteBatch({
                            employeeIds: selectedIds
                        }).success(function (data) {
                            vm.getEmployees();
                            abp.notify.success(App.localize('SuccessfullyDeleted'));
                        });
                    }
                });
            };

            vm.showStatusUpdate = function () {
                $modal.open({
                    templateUrl: require('/App/corporate/views/employees/updateStatusModal.html'),
                    controller: 'corporate.views.employees.updateStatusModal as vm',
                    backdrop: 'static'
                }).result.then(function () {
                    vm.getEmployees();
                });
            };

            vm.rehireEmployee = function (employee) {
                if (!employee.principalId) {
                    showEditModal(employee.id, 0, employee.isTerminated)
                } else {
                    showEditModal(employee.principalId, 0, employee.isTerminated)
                }
            };

            vm.panelSelectionModal = function (employee) {
                $modal.open({
                    templateUrl: require('/App/corporate/views/employees/selectedPanelsModal.html'),
                    controller: 'corporate.views.employees.selectedPanelsModal as vm',
                    backdrop: 'static',
                    resolve: {
                        employee: function () {
                            return employee;
                        }
                    }
                }).result.then(function () {
                    vm.getEmployees();
                });
            }

            init();
        }
    ]);
})();
