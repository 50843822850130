(() => {
  angular.module('app').component('frequencyInput', {
    templateUrl: require('./frequencyInput.html'),
    controller: FrequencyInputController,
    controllerAs: 'vm',
    bindings: {
      frequency: '=',
      disabled: '<',
      placeholder: '@',
    },
  });

  FrequencyInputController.$inject = ['$filter'];

  function FrequencyInputController($filter) {
    const vm = this;
    vm.suggestions = [];

    const textFilter = $filter('frequencyUnitValue');

    function createValueUnit(value, unit) {
      const vu = { value, unit };
      vu.text = textFilter(vu);
      return vu;
    }

    vm.refreshSuggestions = function (input) {
      // Parse input

      const m = /(\d+)/.exec(input);
      const value = (m && Math.max(+m[1] || 0, 0)) || 0;

      // Set suggestions

      vm.suggestions = _.sortBy(
        [
          createValueUnit(0, 'immediately'),
          createValueUnit(value || 3, 'day'),
          createValueUnit(value || 3, 'week'),
          createValueUnit(value || 3, 'month'),
        ],
        (d) => d.value !== value
      );
    };

    vm.$onChanges = function () {
      if (vm.frequency && !vm.frequency.text) {
        vm.frequency.text = textFilter(vm.frequency);
      }
    };
  }
})();
