(function () {
    var componentId = 'hostDoraemonGlDetailsQueryComponent';
    var app = angular.module('app');

    function controller($state, $scope, $stateParams, $timeout, doraemonSvc) {
        var vm = this;

        vm.loading = 0;
        vm.saving = 0;

        var viewOptions = {
            maxResultCount: app.consts.grid.defaultPageSize,
            skipCount: 0,
            sorting: null
        };

        function init() {
            $timeout(function () {
                vm.queryComponentGridOptions.paginationCurrentPage = Math.floor(viewOptions.skipCount / viewOptions.maxResultCount + 1);
            });
        }

        vm.queryComponentGridOptions = {
            appScopeProvider: vm,
            paginationPageSizes: app.consts.grid.defaultPageSizes,
            paginationPageSize: app.consts.grid.defaultPageSize,
            paginationCurrentPage: 0,
            useExternalPagination: true,
            useExternalSorting: true,
            resizable: true,
            columnDefs: [{
                name: 'Actions',
                displayName: 'Actions',
                enableSorting: false,
                width: 75,
                headerCellTemplate: '<span></span>',
                cellTemplate: 'queryComponentViewTemplate'
            }, {
                displayName: App.localize('QueryNumber'),
                field: 'queryNumber',
                minWidth: 100,
                width: 150
            }, {
                displayName: App.localize('CreationTime'),
                enableSorting: false,
                field: 'creationTime',
                cellFilter: 'momentFormat: \'L LT\'',
                minWidth: 120
            }, {
                displayName: App.localize('RepliedBy'),
                enableSorting: false,
                field: 'repliedUserName',
                minWidth: 120
            }, {
                displayName: App.localize('ReplyTime'),
                enableSorting: false,
                field: 'repliedTime',
                cellFilter: 'momentFormat: \'L LT\'',
                minWidth: 120
            }, {
                displayName: App.localize('Resolved'),
                enableSorting: false,
                field: 'resolvedTime',
                minWidth: 120,
                cellTemplate: 'resolvedTemplate'
            }, {
                displayName: App.localize('ResolvedAt'),
                enableSorting: false,
                field: 'resolvedTime',
                cellFilter: 'momentFormat: \'L LT\'',
                minWidth: 120
            }],
            onRegisterApi: function (gridApi) {
                $scope.gridApi = gridApi;

                $scope.gridApi.core.on.sortChanged($scope, function (scope, callback) {
                    viewOptions.sorting = callback.length && callback[0].field ? callback[0].field + ' ' + callback[0].sort.direction : null;
                    getQueries();
                });
                gridApi.pagination.on.paginationChanged($scope, App.setPagination(viewOptions, getQueries));
            },
            data: []
        };

        function getQueries() {
            vm.loading++;
            doraemonSvc.getQueries({
                letterNumber: vm.letterNumber,
                skipCount: viewOptions.skipCount,
                maxResultCount: viewOptions.maxResultCount
            }).success(function (data) {
                vm.queryComponentGridOptions.totalItems = data.totalCount;
                vm.queryComponentGridOptions.data = data.items;
            }).finally(function (e) {
                vm.loading--;
            });
        }

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/doraemon/glDetails/query/query.component.html'),
        controller: ['$state', '$scope', '$stateParams', '$timeout', 'abp.services.app.doraemon', controller],
        controllerAs: 'vm',
        bindings: {
            letterNumber: '<'
        }
    });
})();
