(function () {
    'use strict';

    angular.module('app').component('clinicHospitalInpatientClaimsDetailsInfoComponent', {
        templateUrl: require('/App/clinic/views/hospital/inpatientClaims/details/info/info.component.html'),
        controller: ['Hms.ServiceRequests.ServiceRequestStatus', 'Hms.GuaranteeLetters.GuaranteeLetterStatus', controller],
        controllerAs: 'vm',
        bindings: {
            claim: '<'
        }
    });

    function controller(enumServiceRequestStatus, enumGuaranteeLetterStatus) {
        var vm = this;
        vm.loading = 0;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

        vm.enums = {};
        vm.enums.serviceRequestStatus = enumServiceRequestStatus;
        vm.enums.guaranteeLetterStatus = enumGuaranteeLetterStatus;
    }
})();
