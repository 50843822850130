(function () {
    angular.module('app')
        .component('hostInpatientClaimsDetailsClaimInfoComponent', {
            templateUrl: require('/App/host/views/inpatientClaims/details/claimInfo/claimInfo.component.html'),
            controller: ['$state', '$scope', '$stateParams', '$uibModal', 'abp.services.app.inpatientClaim', 'Hms.ServiceRequests.ServiceRequestType', controller],
            controllerAs: 'vm',
            bindings: {
                claim: '<',
                patient: '<',
                currencyCode: '<'
            }
        });

    function controller($state, $scope, $stateParams, $modal, inpatientClaimSvc, enumServiceRequestType) {
        var vm = this;
        vm.loading = 0;
        vm.saving = 0;
        vm.timeZoneId = $stateParams.timeZoneId;
        vm.hasInpatientClaimProcessingEnhancement = abp.setting.getBoolean('Hms.Feature.InpatientClaimProcessingEnhancement');

        vm.enums = {};
        vm.enums.serviceRequestType = enumServiceRequestType;

        // TODO: Update permissions

        vm.permissions = {
            update: abp.auth.isGranted('GuaranteeLetters.Host.Update'),
            topUp: abp.auth.isGranted('GuaranteeLetters.Host.TopUp'),
            extend: abp.auth.isGranted('GuaranteeLetters.Host.Approve')
        };

        vm.isEdit = false;

        vm.getFullDateTimeString = getFullDateTimeString;

        function getFullDateTimeString(date, timeZoneId) {
            return App.getFullDateTimeString(date, timeZoneId);
        }
    }
})();
