(() => {
  angular.module('app').controller('clinic.views.panelDashboard', PanelDashboardController);

  PanelDashboardController.$inject = [
    '$document',
    'abp.services.app.commonLookup',
    'lookupModal',
    'appSession',
    'moment',
  ];

  function PanelDashboardController($document, commonLookupSvc, lookupModal, appSession, moment) {
    const vm = this;
    const storageKey = 'panelDashboard_user';

    vm.isGpPanel = null;
    vm.statistic = null;
    vm.creditFacility = null;
    vm.panelLocationId = 0;
    vm.panelLocationTimeZone = '';
    vm.locations = [];
    vm.refresh = 0;
    vm.widgets = [];
    vm.isGpPanelInitialized = false;
    vm.cachePanelUser = App.getStorageJsonObj(storageKey);
    vm.hasGpDashboardEnhancement = abp.setting.getBoolean('Hms.Feature.GPDashboardEnhancement');
    vm.hasPanelDashboardEnhancement = abp.setting.getBoolean(
      'Hms.Feature.PanelDashboardEnhancement'
    );
    vm.permissions = {
      patientTickets: abp.auth.isGranted('PatientTickets'),
      saleTransactions: abp.auth.isGranted('SaleTransactions'),
      patients: abp.auth.isGranted('Panel.Employees'),
      dashboardMetrics: abp.auth.isGranted('Panel.DashboardMetrics'),
      patientVisitsMetric: abp.auth.isGranted('Panel.DashboardMetrics.PatientVisitsMetric'),
      cashlessClaimsCollectedMetric: abp.auth.isGranted(
        'Panel.DashboardMetrics.CashlessClaimsCollectedMetric'
      ),
      averageSubmissionTimeMetric: abp.auth.isGranted(
        'Panel.DashboardMetrics.AverageSubmissionTimeMetric'
      ),
      averageClaimPriceRangeMetric: abp.auth.isGranted(
        'Panel.DashboardMetrics.AverageClaimPriceRangeMetric'
      ),
      billingOverviewWidget: abp.auth.isGranted('Panel.DashboardMetrics.BillingOverviewWidget'),
    };

    vm.selectLocation = selectLocation;

    $document.ready(() => {
      const width = $document[0].documentElement.clientWidth;
      if (width >= 769) {
        angular.element($document[0]).find('body').addClass('mini-navbar');
      }
    });

    init();

    function init() {
      getClinicLocations();
      getTenantCurrencyCode();
      getTenantCountryCode();
      if (
        !vm.cachePanelUser ||
        vm.cachePanelUser.expiryTime === null ||
        vm.cachePanelUser.userId !== appSession.user.id ||
        moment().format() > vm.cachePanelUser.expiryTime
      ) {
        getDefaultClinicLocation();
      } else {
        vm.panelLocationId = vm.cachePanelUser.defaultLocationId;
        vm.panelLocationTimeZone = vm.cachePanelUser.defaultLocationTimeZoneId;
      }
      addMetricWidgets();
      isPanelHasGPService();
    }

    function setLocation(location) {
      vm.panelLocationId = parseInt(location.value, 10) || 0;
      vm.panelLocationTimeZone = location.ianaTimeZoneId;
    }

    function selectLocation(locationId) {
      vm.panelLocationId = locationId;
      vm.panelLocationTimeZone = _.find(
        vm.locations,
        (l) => l.value === vm.panelLocationId
      ).ianaTimeZoneId;
      setLocalStorage();
      vm.refresh += 1;
    }

    function getClinicLocations() {
      commonLookupSvc
        .findClinicLocations({
          skipCount: 0,
          maxResultCount: 999,
        })
        .success((data) => {
          vm.locations = data.items;
        });
    }

    function openLocationModal() {
      lookupModal.open({
        title: App.localize('SelectPanelLocation'),
        serviceMethod: commonLookupSvc.findClinicLocations,
        callback(location) {
          vm.panelLocationId = location.value;
          vm.panelLocationTimeZone = location.ianaTimeZoneId;
          selectLocation(location.value);
        },
      });
    }

    function getDefaultClinicLocation() {
      commonLookupSvc
        .getDefaultClinicLocation()
        .success((data) => {
          setLocation(data);
        })
        .finally(() => {
          if (!vm.panelLocationId) {
            openLocationModal();
          } else {
            setLocalStorage();
          }
        });
    }

    function setLocalStorage() {
      const panelUser = {
        userId: appSession.user.id,
        defaultLocationId: vm.panelLocationId,
        expiryTime: moment().add(1, 'days').format(),
        defaultLocationTimeZoneId: vm.panelLocationTimeZone,
      };
      App.saveAsJsonToStorage(storageKey, panelUser);
    }

    // Add metrics for uib-carousel viewing (for small and mobile device) based on permission.

    function addMetricWidgets() {
      let index = 0;

      if (vm.permissions.patientVisitsMetric)
        vm.widgets.push({
          id: (index += 1),
          widgetComponent: 'patientVisits',
        });

      if (vm.permissions.cashlessClaimsCollectedMetric)
        vm.widgets.push({
          id: (index += 1),
          widgetComponent: 'claimsCollected',
        });

      if (vm.permissions.averageSubmissionTimeMetric)
        vm.widgets.push({
          id: (index += 1),
          widgetComponent: 'averageSubmission',
        });

      if (vm.permissions.averageClaimPriceRangeMetric)
        vm.widgets.push({
          id: (index += 1),
          widgetComponent: 'averageClaimPriceRange',
        });
    }

    function getTenantCurrencyCode() {
      vm.loading += 1;
      commonLookupSvc
        .getTenantCurrencyCode()
        .success((data) => {
          vm.currencyCode = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // Get service type for the panel.

    function isPanelHasGPService() {
      vm.loading += 1;
      commonLookupSvc
        .isPanelHasGPService()
        .success((data) => {
          vm.isGpPanel = data;
          vm.isGpPanelInitialized = true;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // Get country code for the panel.

    function getTenantCountryCode() {
      vm.loading += 1;
      commonLookupSvc
        .getTenantCountryCode()
        .success((data) => {
          vm.isMalaysia = data === 'MY';
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
