import swal from 'sweetalert';
import PromptQueue from '../../../../../../lib/prompt-queue';

(() => {
  angular.module('app').component('commonInpatientTicketsDetailNprMaternityTicketInfoComponent', {
    templateUrl: require('./nprMaternityTicketInfo.component.html'),
    controller: NprMaternityTicketInfoController,
    controllerAs: 'vm',
    bindings: {
      ticket: '<',
      categories: '<',
      diseaseClassifications: '<',
      patient: '<',
      organization: '<',
      claimNumber: '<',
      coPay: '<',
      roomAndBoardCharges: '<',
      isTallied: '=?',
      checkFormFilled: '&?',
      isEdit: '<',
      isFromTicketView: '<', // Indicates whether this component is viewed via viewing inpatient patient ticket.
      triggerEdit: '<',
      triggerCancel: '<',
      triggerSave: '<',
      onSave: '&',
      treatments: '<',
      coPayTypeName: '<',
      isCreate: '<',
    },
  });

  NprMaternityTicketInfoController.$inject = [
    '$scope',
    '$state',
    '$filter',
    'abp.services.app.inpatientPatientTicket',
    '$uibModal',
    'Hms.Employees.EmployeeState',
    'moment',
  ];

  function NprMaternityTicketInfoController(
    $scope,
    $state,
    $filter,
    inpatientPatientTicketSvc,
    $uibModal,
    enumEmployeeState,
    moment
  ) {
    const vm = this;

    vm.currencyCode = vm.ticket.currencyCode;
    vm.loading = 0;
    vm.saving = 0;
    vm.isHost = App.isHost();
    vm.filteredDoctors = [];
    vm.hasCoPay = false;
    vm.hasState = false;

    vm.uploadConfig = {
      objectType: 'TicketAttachment',
    };
    vm.newDocuments = [];
    vm.roomCategories = [];
    vm.procedureCategories = [];
    vm.otherCategories = [];

    vm.hasSobEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');
    vm.hasMemberBenefitUtilizationEnabled = abp.setting.getBoolean(
      'Hms.Feature.MemberBenefitUtilization'
    );
    vm.hasTxtFileSupport = abp.setting.getBoolean('Hms.Feature.TxtFileSupport');

    vm.enums = { employeeState: enumEmployeeState };

    vm.$onChanges = onChanges;
    vm.getAmount = getAmount;
    vm.getTotalBillAmount = getTotalBillAmount;
    vm.getCoveredAmount = getCoveredAmount;
    vm.getUncoveredAmount = getUncoveredAmount;
    vm.getCollectCash = getCollectCash;
    vm.getCoPayAmount = getCoPayAmount;
    vm.getExceededAmount = getExceededAmount;
    vm.getDependentDescription = getDependentDescription;
    vm.refreshAllowance = refreshAllowance;

    init();

    function init() {
      // Only initialize when isEdit is true.

      if (vm.isEdit) {
        vm.isTallied = true;
      }

      $scope.$on('validateNprInpatientTicket', validateForm);
    }

    function onChanges(changes) {
      if (vm.categories && changes.categories) {
        vm.categories = _.map(vm.categories, (d) =>
          _.extend({}, d, {
            id: d.category,
          })
        );

        if (vm.hasSobEnhancement) {
          vm.roomCategories = _.filter(
            vm.categories,
            (x) =>
              x.category === 'RoomAndBoard' ||
              x.category === 'IcuRoomAndBoard' ||
              x.category === 'ChildGuardianBenefit'
          );

          vm.procedureCategories = _.filter(
            vm.categories,
            (x) =>
              x.category === 'NormalDelivery' ||
              x.category === 'CaesareanDelivery' ||
              x.category === 'NormalDeliveryTwinsAndAbove' ||
              x.category === 'CaesareanDeliveryTwinsAndAbove'
          );

          vm.otherCategories = _.filter(
            vm.categories,
            (x) =>
              x.category === 'SurgicalFee' ||
              x.category === 'AnaesthetistFee' ||
              x.category === 'OperatingTheatre' ||
              x.category === 'HospitalSuppliesAndServices'
          );
        } else {
          vm.roomCategories = _.filter(
            vm.categories,
            (x) => x.category === '1' || x.category === '2' || x.category === '3'
          );

          vm.procedureCategories = _.filter(
            vm.categories,
            (x) =>
              x.category === '5' || x.category === '6' || x.category === '7' || x.category === '8'
          );

          vm.otherCategories = _.filter(
            vm.categories,
            (x) =>
              x.category === '9' ||
              x.category === '10' ||
              x.category === '11' ||
              x.category === '12' ||
              x.category === '13'
          );
        }
      }

      // Edit.
      if (vm.triggerEdit && changes.triggerEdit && changes.triggerEdit.currentValue) {
        backupInitialStates();
        vm.isEdit = true;
      }

      // Cancel edit.
      if (vm.triggerCancel && changes.triggerCancel && changes.triggerCancel.currentValue) {
        restoreInitialStates();
        vm.isEdit = false;
      }

      // Save edits.
      if (vm.triggerSave && changes.triggerSave && changes.triggerSave.currentValue) {
        save();
        vm.onSave({ isSave: false });
      }
    }

    function getDependentDescription() {
      return App.localize(
        'DependentDescription',
        vm.patient.principalName,
        $filter('nationalIdFilter')(vm.patient.principalNationalId)
      );
    }

    function validateForm() {
      if (!App.isFormValid(vm.ticketEditForm)) {
        vm.checkFormFilled({ data: false });
      } else {
        vm.checkFormFilled({ data: true });
      }
    }

    function refreshAllowance() {
      // Refresh patient's allowance. Only for NPR & Manual tickets.

      vm.loading += 1;
      inpatientPatientTicketSvc
        .getAllowance({
          employeeId: vm.patient.employeeId,
          serviceType: vm.ticket.serviceType,
          ticketDate: vm.ticket.receiptDate,
        })
        .success((data) => {
          vm.ticket.allowance = data.employeeAllowance;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // Total calculation region (To be updated with more calculations)

    function getAmount() {
      let totalBillAmount = 0;
      let coveredAmount = 0;
      let uncoveredAmount = 0;
      let exceededAmount = 0;
      let receiptAmount = 0;
      let coPayAmount = 0;
      let totalRoomAndBoardAmount = 0;
      let totalRoomAndBoardDuration = 0;
      let allowance = 0;
      let collectCashAmount = 0;
      let totalExcludedItemAmount = 0;
      const dailyRoomAndBoardAmounts = [];

      if (vm.ticket) {
        receiptAmount = vm.ticket.receiptAmount;
        allowance = vm.ticket.allowance;
      }

      if (vm.ticket && !vm.ticket.isPreOrPostInpatientClaim) {
        if (!vm.roomAndBoardCharges) vm.roomAndBoardCharges = vm.ticket.roomAndBoardAllowance;
        if (!vm.diagnoses) vm.diagnoses = vm.ticket.diagnoses;

        if (!vm.coPay) {
          vm.coPay = {
            isRoomAndBoardExceedEntitlement: false,
            isPercentage: vm.ticket.isCoPayPercentage,
            isCoInsurance: vm.ticket.isCoInsurance,
            coPayExclusions: vm.ticket.coPayExclusions,
            value: vm.ticket.coPayValue,
          };
        }

        _.each(vm.ticket.rooms, (x) => {
          _.each(x.ticketItems, (p) => {
            if (p.amount) {
              coveredAmount += p.amount;
              totalBillAmount += p.amount;

              if (vm.hasTxtFileSupport && _.some(vm.coPay.coPayExclusions, (c) => c === x.category))
                totalExcludedItemAmount += p.amount;
            }

            if (p.uncoveredAmount) {
              uncoveredAmount += p.uncoveredAmount;
              totalBillAmount += p.uncoveredAmount;
            }

            // For room and board only.
            if ((x.category === '1' || x.category === 'RoomAndBoard') && p.totalCharges) {
              const startDate = p.roomChargeDateRangeModel
                ? p.roomChargeDateRangeModel.startDate
                : p.startDate;
              const endDate = p.roomChargeDateRangeModel
                ? p.roomChargeDateRangeModel.endDate
                : p.endDate;

              const startDateMoment = moment(startDate);
              const endDateMoment = moment(endDate);

              totalRoomAndBoardAmount += p.totalCharges;
              totalRoomAndBoardDuration += endDateMoment.diff(startDateMoment, 'days') + 1;

              if (vm.hasTxtFileSupport) dailyRoomAndBoardAmounts.push(p.dailyCharges);
            }
          });
        });

        _.each(vm.ticket.procedures, (x) => {
          _.each(x.ticketItems, (p) => {
            if (p.amount) {
              coveredAmount += p.amount;
              totalBillAmount += p.amount;

              if (vm.hasTxtFileSupport && _.some(vm.coPay.coPayExclusions, (c) => c === x.category))
                totalExcludedItemAmount += p.amount;
            }

            if (p.uncoveredAmount) {
              uncoveredAmount += p.uncoveredAmount;
              totalBillAmount += p.uncoveredAmount;
            }
          });
        });

        _.each(vm.ticket.others, (x) => {
          if (x.amount) {
            coveredAmount += x.amount;
            totalBillAmount += x.amount;

            if (vm.hasTxtFileSupport && _.some(vm.coPay.coPayExclusions, (c) => c === x.category))
              totalExcludedItemAmount += x.amount;
          }

          if (x.uncoveredAmount) {
            uncoveredAmount += x.uncoveredAmount;
            totalBillAmount += x.uncoveredAmount;
          }
        });
      }

      if (vm.ticket && vm.ticket.isPreOrPostInpatientClaim) {
        _.each(vm.ticket.ticketItems, (item) => {
          if (item.amount) {
            coveredAmount += item.amount;
            totalBillAmount += item.amount;
          }

          if (item.uncoveredAmount) {
            uncoveredAmount += item.uncoveredAmount;
            totalBillAmount += item.uncoveredAmount;
          }
        });
      }

      if (vm.hasTxtFileSupport)
        coPayAmount = calculateCoPayV2(
          uncoveredAmount,
          totalBillAmount,
          totalExcludedItemAmount,
          dailyRoomAndBoardAmounts
        );
      else
        coPayAmount = calculateCoPay(
          uncoveredAmount,
          totalBillAmount,
          totalRoomAndBoardAmount,
          totalRoomAndBoardDuration
        );

      // Use ticket copay amount for reimbursement total ticket calculation.

      if (vm.ticket.ticketType === 2 && vm.ticket.coPayAmount > 0) {
        coPayAmount = vm.ticket.coPayAmount;
      }

      coveredAmount -= coPayAmount;

      // Check against allowance

      if (coveredAmount > allowance) {
        exceededAmount = coveredAmount - allowance;
        coveredAmount = allowance;
      }

      collectCashAmount = uncoveredAmount + coPayAmount + exceededAmount;

      if (vm.isEdit) {
        if (totalBillAmount !== receiptAmount) {
          vm.isTallied = false;
        } else {
          vm.isTallied = true;
        }
      }

      return {
        totalBillAmount: App.roundAmount(totalBillAmount),
        coveredAmount: App.roundAmount(coveredAmount),
        uncoveredAmount: App.roundAmount(uncoveredAmount),
        collectCash: App.roundAmount(collectCashAmount),
        coPayAmount: App.roundAmount(coPayAmount),
        exceededAmount: App.roundAmount(exceededAmount),
      };
    }

    function getTotalBillAmount() {
      return vm.getAmount().totalBillAmount > 0 ? vm.getAmount().totalBillAmount : 0;
    }

    function getCoveredAmount() {
      return vm.getAmount().coveredAmount > 0 ? vm.getAmount().coveredAmount : 0;
    }

    function getUncoveredAmount() {
      return vm.getAmount().uncoveredAmount > 0 ? vm.getAmount().uncoveredAmount : 0;
    }

    function getCollectCash() {
      return vm.getAmount().collectCash > 0 ? vm.getAmount().collectCash : 0;
    }

    function getCoPayAmount() {
      return vm.getAmount().coPayAmount > 0 ? vm.getAmount().coPayAmount : 0;
    }

    function getExceededAmount() {
      return vm.getAmount().exceededAmount > 0 ? vm.getAmount().exceededAmount : 0;
    }

    function calculateCoPayV2(
      uncoveredAmount,
      billAmount,
      totalExcludedItemAmount,
      dailyRoomAndBoardAmounts
    ) {
      if (!vm.coPay || vm.coPay.value === 0) {
        return 0;
      }

      const coveredAmount = billAmount - uncoveredAmount;
      const costShareableAmount = coveredAmount - totalExcludedItemAmount;
      const hasExceededEntitlement = _.some(
        dailyRoomAndBoardAmounts,
        (d) => d > vm.roomAndBoardCharges
      );

      if (vm.coPay.isPercentage) {
        if (vm.coPay.isRoomAndBoardExceedEntitlement)
          return hasExceededEntitlement ? costShareableAmount * (vm.coPay.value / 100) : 0;
        return costShareableAmount * (vm.coPay.value / 100);
      }
      return costShareableAmount > 0 ? vm.coPay.value : 0;
    }

    function calculateCoPay(
      uncoveredAmount,
      billAmount,
      totalRoomAndBoardAmount,
      roomAndBoardDuration
    ) {
      if (!vm.coPay || vm.coPay.value === 0) {
        return 0;
      }

      let totalRoomAndBoardAllowance = 0;
      let exceededRoomAndBoardAmount = 0;

      if (vm.roomAndBoardCharges > 0 && roomAndBoardDuration > 0) {
        totalRoomAndBoardAllowance = vm.roomAndBoardCharges * roomAndBoardDuration;
      }

      if (totalRoomAndBoardAmount > 0) {
        exceededRoomAndBoardAmount = totalRoomAndBoardAmount - totalRoomAndBoardAllowance;
      }

      const coveredAmount = billAmount - uncoveredAmount;

      if (vm.coPay.isPercentage && vm.coPay.isCoInsurance) {
        return coveredAmount * (vm.coPay.value / 100);
      }
      if (vm.coPay.isPercentage && !vm.coPay.isCoInsurance) {
        return exceededRoomAndBoardAmount > 0
          ? (coveredAmount - totalRoomAndBoardAllowance) * (vm.coPay.value / 100)
          : 0;
      }
      const copayableAmount = vm.coPay.isCoInsurance
        ? coveredAmount
        : coveredAmount - totalRoomAndBoardAllowance;

      return copayableAmount > 0 ? vm.coPay.value : 0;
    }

    function backupInitialStates() {
      vm.preEditData = {
        preNewDocuments: angular.copy(vm.newDocuments),
        preTicket: angular.copy(vm.ticket),
      };
    }

    function restoreInitialStates() {
      vm.newDocuments = vm.preEditData.preNewDocuments;
      vm.ticket = vm.preEditData.preTicket;
    }

    function save() {
      let canSubmit = true;
      const prompts = new PromptQueue();

      if (App.isFormValid(vm.ticketEditForm)) {
        if (vm.ticket.ticketType === 2) {
          if (vm.getTotalBillAmount() !== vm.ticket.receiptAmount) {
            abp.message.error(App.localize('ClaimReceiptAmountMismatchWithTicketAmount'));
            return;
          }

          if (vm.getCoveredAmount() !== vm.ticket.coveredAmount) {
            prompts.add(
              swal,
              {
                title: App.localize('AreYouSure'),
                text: App.localize('ClaimReceiptAmountMismatchWithTicketCoveredAmountWarning'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#1ab394',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                closeOnConfirm: true,
                closeOnCancel: true,
              },
              (isConfirm) => {
                canSubmit = isConfirm;
              }
            );
          }
        }

        vm.saving += 1;
        prompts.run(() => {
          vm.saving -= 1;
          if (canSubmit) updateTicket();
        });
      } else {
        abp.message.error(App.localize('OneOrMoreInputValueIsInvalid'));
      }
    }

    function updateTicket() {
      vm.loading += 1;
      const rooms = [];
      const procedures = [];
      const others = [];

      _.each(vm.ticket.rooms, (x) => {
        _.each(x.ticketItems, (ticketItem) => {
          const ti = ticketItem;
          if (!_.isNil(ti.category)) {
            ti.startDate = ti.roomChargeDateRangeModel.startDate
              ? ti.roomChargeDateRangeModel.startDate
              : null;
            ti.endDate = ti.roomChargeDateRangeModel.endDate
              ? ti.roomChargeDateRangeModel.endDate
              : null;
            rooms.push(ti);
          }
        });
      });
      _.each(vm.ticket.procedures, (x) => {
        _.each(x.ticketItems, (ticketItem) => {
          const ti = ticketItem;
          if (!_.isNil(ti.category)) {
            ti.startDate = ti.procedureChargeDateRangeModel.startDate
              ? ti.procedureChargeDateRangeModel.startDate
              : null;
            ti.endDate = ti.procedureChargeDateRangeModel.endDate
              ? ti.procedureChargeDateRangeModel.endDate
              : null;
            procedures.push(ti);
          }
        });
      });

      _.each(vm.ticket.others, (x) => {
        if (!_.isNil(x.categoryName)) others.push(x);
      });

      const input = vm.ticket;
      input.letterNumber = vm.letterNumber;
      input.rooms = rooms;
      input.procedures = procedures;
      input.others = others;
      input.treatments = vm.ticket.ticketItems;

      input.documentsToUpload = _.map(vm.newDocuments, (doc) => ({
        externalObjectId: null,
        originalFileName: doc.originalFileName,
        fileName: doc.fileName,
      }));

      input.documentsMarkedForRemoval = _.reduce(
        vm.ticket.documents,
        (resultArray, document) => {
          if (document.isMarkedForRemoval)
            resultArray.push({ externalObjectId: document.externalObjectId });

          return resultArray;
        },
        []
      );

      inpatientPatientTicketSvc
        .updateTicket(input)
        .success(() => {
          vm.isEdit = false;
          vm.savedTrigger += 1;
          vm.ticket.rooms = _.chain(vm.ticket.rooms)
            .groupBy('category')
            .map((value, key) => ({ category: key, ticketItems: value }))
            .value();
          vm.ticket.procedures = _.chain(vm.ticket.procedures)
            .groupBy('category')
            .map((value, key) => ({ category: key, ticketItems: value }))
            .value();
          abp.notify.info(App.localize('SuccessfullySaved'));
          $state.reload();
          if (vm.hasMemberBenefitUtilizationEnabled && vm.ticket.ticketType !== 1 && vm.isHost) {
            showAdjustMemberUtilizationConfirmationModal(vm.ticket.ticketNumber);
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function showAdjustMemberUtilizationConfirmationModal(ticketNumber) {
      $uibModal.open({
        templateUrl: require('../../../memberUtilizations/adjustMemberUtilizationConfirmationModal.html'),
        controller:
          'common.views.memberUtilizations.adjustMemberUtilizationConfirmationModal as vm',
        backdrop: 'static',
        size: 'sm',
        resolve: {
          ticketNumber() {
            return ticketNumber;
          },
        },
      });
    }
  }
})();
