(() => {
    angular.module('finance').component('manualInvoiceDetailTransactionViewComponent', {
      templateUrl: require('./manualTransactionView.component.html'),
      controller: ManualInvoiceDetailTransactionViewComponent,
      controllerAs: 'vm',
      bindings: {
        currencyCode: '<',
        invoice: '<',
        invoiceFrom: '<',
        invoiceTo: '<',
        isAddressInvoiceFrom: '<',
        isAddressInvoiceTo: '<',
        message: '<',
        getDateString: '<'
      },
    });
  
    ManualInvoiceDetailTransactionViewComponent.$inject = [];
  
    function ManualInvoiceDetailTransactionViewComponent() {
      const vm = this;
      vm.decimalPlaces = 2;
    }
  })();
  