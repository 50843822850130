(function () {
    'use strict';

    angular.module('app').component('hostCorporatesSupportPlansCreateEditCostSharingComponent', {
        templateUrl: require('/App/host/views/corporates/supportPlans/createEdit/costSharing/costSharing.component.html'),
        controller: controller,
        controllerAs: 'vm',
        bindings: {
            supportPlan: '<',
            settings: '<',
            isHospitalization: '<',
            categories: '<',
            currencyCode: '<'
        }
    });

    function controller() {
        var vm = this;

        vm.$onChanges = onChanges;
        vm.coverDependent = coverDependent;
        vm.setIsCoInsurance = setIsCoInsurance;

        vm.coPayExample = App.localize('CoPayExample', vm.currencyCode);
        vm.hasTxtFileSupport = abp.setting.getBoolean('Hms.Feature.TxtFileSupport');
        vm.showCoPayExample = false;
        vm.isReadOnly = false;
        vm.isPercentage = false;
        vm.beneficiary = 1;

        function coverDependent() {
            return vm.settings && (vm.settings.hasDependentCovered || vm.settings.isSpouseCovered)
        }

        function setIsCoInsurance(selectedValue) {
            switch (selectedValue) {
                case 1:
                    vm.settings.isCoInsurance = true;
                    break;

                case 2:
                case 3:
                    vm.settings.isCoInsurance = false;
                    break;
            }
        }

        vm.lookupCategory = function (filter) {
            var keyword = _.trim(filter || '');
            if (keyword) {
                // Include free-text entry in filtered procedure set.

                var filteredCategories = [];

                var regexes = _.chain(keyword.split(' '))
                    .map(function (k) {
                        return _.trim(k.toUpperCase());
                    })
                    .filter(function (k) {
                        return k;
                    })
                    .uniq()
                    .map(function (k) {
                        _.escapeRegExp(k);

                        return {
                            token: k,
                            len: k.length,
                            pattern: new RegExp('\\b' + k, 'i')
                        };
                    })
                    .value();

                _.forEach(vm.categories, function (category) {
                    var score = 0;

                    _.forEach(regexes, function (r) {
                        if (r.pattern.test(category.name)) {
                            score -= r.len;
                        }
                    });

                    if (score) {
                        filteredCategories.push({
                            score: score,
                            item: category
                        });
                    }
                });

                vm.filteredCategories = _.chain(filteredCategories)
                    .sortBy(['score'])
                    .take(25)
                    .map(function (k) {
                        return k.item;
                    })
                    .value();
            }
            else
                vm.filteredCategories = vm.categories;
        };

        function onChanges(changes) {
            if (changes.settings && changes.settings.currentValue) {
                if (_.isNil(vm.settings.isCoInsurance)) {
                    vm.settings.isCoInsurance = true;

                    if (vm.hasTxtFileSupport)
                        vm.settings.coPayType = 1;
                }
            }
        }
    }
})();
