(() => {
  angular.module('app').component('hostInsurersCreateEditContainer', {
    templateUrl: require('./container.component.html'),
    controller: InsurerEditController,
    controllerAs: 'vm',
    bindings: {},
  });

  InsurerEditController.$inject = [
    '$state',
    '$stateParams',
    'abp.services.app.insurer',
    'abp.services.finance.commonLookup'
  ];

  function InsurerEditController(
    $state,
    $stateParams,
    insurerSvc,
    financeCommonLookupSvc
  ) {
    const vm = this;

    vm.isCreate = !$stateParams.insurerId;
    vm.isEditing = false;
    vm.loading = 0;
    vm.saving = 0;
    vm.hasInsurerModule = abp.setting.getBoolean('Hms.Feature.InsuranceModule');

    // Assign permissions

    vm.permissions = {
      edit: abp.auth.isGranted('Host.NewInsurers.Edit'),
      create: abp.auth.isGranted('Host.NewInsurers.Create'),
    };

    const widgets = [];

    vm.addWidget = addWidget;
    vm.saveWidget = saveWidget;
    vm.saveAll = saveAll;
    vm.toggleEdit = toggleEdit;
    vm.getInsurer = getInsurer;
    vm.cancel = cancel;

    init();

    function init() {
      getSettings();
      if (!vm.isCreate) {
        vm.getInsurer();
      }
    }

    function getSettings() {
      vm.loading += 1;
      financeCommonLookupSvc
        .getCommonSettings()
        .success((data) => {
          vm.hasTaxIdentifierCollection = data.taxIdentifierCollection;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // Register child widget to this container.
    // Expose to child widget to register themselves.

    function addWidget(widget) {
      if (widget) {
        widgets.push(widget);
      }
    }

    // Save widget.

    function saveWidget(widget) {
      if (vm.isCreate) return; // Prevent saving individual widget during creation.
      if (vm.saving || widget.saving) return;
      if (!widget.validateForm()) {
        abp.notify.error(App.localize('GeneralInvalidFormInputError'));
        return;
      }

      widget.saving += 1;
      save(vm.isCreate, widget.getPayload(), () => {
        widget.saving -= 1;
      });
    }

    // Save all changes on the form to insurer.

    function saveAll() {
      let error = false;

      if (vm.saving || vm.loading) return;

      const finalPayload = {};
      _.each(widgets, (w) => {
        if (!w.validateForm()) {
          error = true;
        }

        const p = w.getPayload();
        _.extend(finalPayload, p);
      });

      // Stop saving if any error occurs.

      if (error) {
        abp.notify.error(App.localize('GeneralInvalidFormInputError'));
        return;
      }

      vm.saving += 1;
      save(vm.isCreate, finalPayload, () => {
        vm.saving -= 1;
      });
    }

    function toggleEdit(flag) {
      vm.isEditing = flag;
      _.each(widgets, (w) => {
        w.isEditing = flag;
      });

      if (vm.isEditing) getInsurerForEdit($stateParams.insurerId);
    }

    function getInsurer() {
      getInsurerForEdit($stateParams.insurerId);
    }

    function getInsurerForEdit(insurerId) {
      vm.loading += 1;
      insurerSvc
        .getInsurerForEdit({
          id: insurerId,
        })
        .success((n) => {
          vm.insurerId = n.id;
          vm.insurerIdentityServerTenantId = n.identityServerTenantId;
          vm.properties = n.properties;
          vm.finance = n.finance;
          vm.insurerMyInvoisSettings = n.insurerMyInvoisSettings;
          vm.countryCode = n.properties.countryCode;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // Create or update insurer with the given payload.
    // Callback should be used for handling vm.saving in child widget.

    function save(isCreate, payload, callback) {
      if (isCreate) {
        createInsurer(payload, callback);
      } else {
        updateInsurer(payload, callback);
      }
    }

    // Create insurer with the given payload.

    function createInsurer(payload, callback) {
      insurerSvc
        .createInsurer(payload)
        .success((data) => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          if (data.item2) {
            abp.message.success(App.localize('PasswordForUser', 'Admin'), data.item2);
          }
          $state.go('host.createEditInsurer', { insurerId: data.item1 });
          vm.toggleEdit(false);
        })
        .finally(() => {
          if (_.isFunction(callback)) callback();
        });
    }

    // Update insurer with the given payload.

    function updateInsurer(payload, callback) {
      _.extend(payload, {
        id: vm.insurerId,
        identityServerTenantId: vm.insurerIdentityServerTenantId,
      });

      insurerSvc
        .updateInsurer(payload)
        .success(() => {
          abp.notify.info(App.localize('InsurerSettingsSuccessfullySaved'));
          vm.toggleEdit(false);
        })
        .finally(() => {
          if (_.isFunction(callback)) callback();
        });
    }

    function cancel() {
      const dirtyWidgets = _.filter(widgets, (w) => w.isDirty());
      if (!dirtyWidgets.length) toggleCancel(false);
      else {
        abp.message.confirm(
          App.localize('UnsavedChangesConfirmation'),
          App.localize('AreYouSure'),
          (d) => {
            if (d) {
              toggleCancel(true);
              _.each(dirtyWidgets, (w) => {
                w.setPristine();
              });
            }
          }
        );
      }
    }

    // Cancel creation or edit.

    function toggleCancel(resetInsurer) {
      if (vm.isCreate) window.history.back();
      else {
        vm.toggleEdit(false);
        if (resetInsurer) getInsurerForEdit($stateParams.insurerId);
      }
    }
  }
})();
