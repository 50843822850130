(function () {
    var controllerId = 'host.views.preEmploymentTreatments.createOrEditModal';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'abp.services.app.treatment', 'abp.services.app.commonLookup', 'packageId', 'countryCode', 'Hms.Treatments.Treatment',
        function ($scope, $uibModalInstance, treatmentSvc, commonLookupSvc, packageId, countryCode, constsTreatment) {
            function init() {
                getCurrencyCodeByCountryCode();

                vm.loading++;
                treatmentSvc.getPreEmploymentPackageForEdit({
                    id: packageId
                }).success(function (preEmpPackage) {
                    vm.package = preEmpPackage;

                    vm.corporateExceptions = new Exceptions(treatmentSvc.findCorporates, vm.package.corporateWhitelist);
                    vm.clinicLocationExceptions = new Exceptions(treatmentSvc.findClinicLocations, vm.package.clinicLocationWhitelist);

                    _.each(vm.package.treatments, function (t) { t.originalName = t.name; });
                    vm.addTreatments();
                }).finally(function () {
                    vm.loading--;
                });
            }

            var vm = this;
            vm.countryCode = countryCode;
            vm.constsTreatment = constsTreatment;
            vm.currencyCode = null;
            vm.loading = 0;
            vm.saving = 0;

            /* Treatments */

            vm.undoTreatmentRename = function (treatment) {
                treatment.name = treatment.originalName;
            };

            vm.addTreatments = function () {
                for (var i = 0; i < 2; i++) {
                    vm.package.treatments.push({});
                }
            };

            vm.toggleTreatmentDeletion = function (index) {
                if (index >= 0 && index < vm.package.treatments.length) {
                    var treatment = vm.package.treatments[index];

                    if (vm.treatmentHasValue(treatment)) {
                        treatment.isRemove = !treatment.isRemove;
                    }
                    else {
                        vm.package.treatments.splice(index, 1);
                    }
                }
            };

            vm.treatmentHasValue = function (treatment) {
                return treatment.id || treatment.name;
            };

            vm.hasRemovedTreatments = function () {
                if (!vm.package) return false;

                if (vm.package && vm.package.treatments.length > 0) {
                    return _.some(vm.package.treatments, function (d) { return d.isRemove; });
                }

                return false;
            };

            function hasRemovedTreatments(treatments) {
                return _.some(treatments, function (t) {
                    return t.isRemove;
                });
            }

            /* End of Treatments */

            /* Exceptions */

            function Exceptions(lookupFunc, initialRecords) {
                var _this = this;

                this.records = initialRecords || [];
                this.exceptionsCount = _.isArray(initialRecords) ? initialRecords.length : 0;
                this.searchResult = [];
                this.selectedId = null;

                function updateExceptionsCountText() {
                    return _this.exceptionsCountText = App.localize('XExceptionRecords', _this.exceptionsCount);
                }
                function findRecordsIndex(array, id) {
                    return _.findIndex(array, function (d) { return d.id === id; });
                }

                this.lookup = function (search) {
                    if (search) {
                        var excludedIds = _.map(_this.records, function (r) { return r.id; });
                        lookupFunc({
                            filter: search,
                            maxResultCount: 50,
                            excludedIds: excludedIds,
                            countryCode: countryCode
                        }).success(function (data) {
                            _this.searchResult = _.filter(data.items, function (d) {
                                var idx = _.findIndex(_this.records, function (r) {
                                    return r.id === d.id;
                                });
                                return idx === -1;
                            });
                        }).finally(function () {
                            if (!_this.searchResult.length)
                                _this.selected = null;
                        });
                    }
                };
                this.add = function () {
                    _this.records.unshift(_this.searchResult[findRecordsIndex(_this.searchResult, _this.selectedId)]);
                    _this.exceptionsCount++;
                    updateExceptionsCountText();
                    _this.selectedId = null;
                    _this.searchResult = [];
                };
                this.delete = function (index) {
                    _this.records[index].isRemove = true;
                    _this.exceptionsCount--;
                    updateExceptionsCountText();
                };
                this.undoDelete = function (index) {
                    _this.records[index].isRemove = false;
                    _this.exceptionsCount++;
                    updateExceptionsCountText();
                };
                this.getFilteredRecords = function () {
                    return _.filter(_this.records, function (r) { return !r.isRemove; });
                };

                this.exceptionsCountText = updateExceptionsCountText();
            }

            vm.corporateExceptions = null;
            vm.clinicLocationExceptions = null;

            /* End of Exceptions */

            function save() {
                vm.saving++;

                var input = $.extend({}, vm.package, {
                    corporateWhitelist: vm.corporateExceptions.getFilteredRecords(),
                    clinicLocationWhitelist: vm.clinicLocationExceptions.getFilteredRecords(),
                    countryCode: countryCode
                });

                treatmentSvc.createOrUpdatePreEmploymentPackage(input).success(function () {
                    abp.notify.info(App.localize('SuccessfullySaved'));
                    $uibModalInstance.close();
                }).finally(function () {
                    vm.saving--;
                });
            }

            vm.save = function () {
                var treatments = _.filter(vm.package.treatments, function (t) { return vm.treatmentHasValue(t); });

                var treatmentCountryCode = _.each(treatments, function (t) {
                    t.countryCode = countryCode;
                })

                if (!treatments.length) {
                    abp.message.error(App.localize('EmptyTreatmentValidationErrorMessage'));
                    return;
                }

                if (hasRemovedTreatments(treatments)) {
                    abp.message.confirm(App.localize('PreEmploymentPackageTreatmentsDeleteWarningMessage'), App.localize('AreYouSure'), function (d) {
                        if (d) {
                            vm.package.treatments = _.filter(treatments, function (t) { return !t.isRemove; });
                            save();
                        }
                    });
                } else {
                    vm.package.treatments = treatmentCountryCode;
                    save();
                }
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            // Get the currency code for the selected country.

            function getCurrencyCodeByCountryCode() {
                vm.requestParams = {
                    countryCode: countryCode
                }
                vm.loading++;
                commonLookupSvc.getCurrencyCodeByCountryCode(vm.requestParams)
                    .success(function (data) {
                        vm.currencyCode = data.currencyCode;
                    }).finally(function () {
                        vm.loading--;
                    });
            }

            init();
        }
    ]);
})();
