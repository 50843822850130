(function () {
    var controllerId = 'host.views.debugTools.oneSignalDataTagsRemoval.oneSignalDataTagsRemoval.modal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'abp.services.app.debugTool',
        function ($scope, $uibModalInstance, debugToolSvc) {
            var vm = this;
            vm.saving = 0;

            vm.cancel = cancel;
            vm.confirm = confirm;

            function cancel() {
                $uibModalInstance.dismiss();
            }

            function confirm() {
                vm.saving++;
                var input = _.map(vm.tags, 'text');
                debugToolSvc.resetOneSignalDataTagsAsync({ tags: input })
                    .success(function () {
                        abp.notify.info(App.localize('Success'));
                        $uibModalInstance.close();
                    })
                    .finally(function () {
                        vm.saving--;
                    });
            }
        }
    ]);
})();
