(() => {
  angular
    .module('sms')
    .controller('sms.views.smsLogs.detailModal', SmsLogDetailModalController);

  SmsLogDetailModalController.$inject = ['$scope', '$uibModalInstance', 'smsLog'];
  function SmsLogDetailModalController($scope, $uibModalInstance, smsLog) {
    const vm = this;
    vm.smsLog = smsLog;

    vm.close = () => {
      $uibModalInstance.dismiss();
    };
  }
})();
