(function () {
    var controllerId = 'clinic.views.patient.draftConfirmationModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'ticket', 'clinic', 'employeeId', '$state', 'abp.services.app.patientTicket',
        function ($scope, $uibModalInstance, ticket, clinic, employeeId, $state, patientTicketSvc) {
            var vm = this;
            vm.ticket = ticket;
            vm.clinic = clinic;
            vm.employeeId = employeeId;

            vm.saveDraft = function () {
                var ticketDraft = JSON.stringify(vm.ticket);
                var input = {
                    employeeId: vm.employeeId,
                    clinicLocationId: vm.clinic.clinicLocationId,
                    ticketType: vm.ticket.ticketType,
                    ticketDraft: ticketDraft
                };

                vm.savingDraft++;
                patientTicketSvc.saveTicketDraft(input)
                    .success(function () {
                        abp.notify.info(App.localize('DraftSaved'));
                    }).finally(function () {
                        vm.savingDraft--;
                    });

                $uibModalInstance.close();
                window.history.back();
            };

            vm.close = function () {
                $uibModalInstance.close();
                window.history.back();
            };
        }
    ]);
})();
