(function () {
    var controllerId = 'host.views.publicUsers.editModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'countries', 'abp.services.app.publicUser', 'publicUser', 'Hms.Users.User',
        function ($scope, $uibModalInstance, countries, publicUserSvc, publicUser, constsUser) {
            function init() {
                vm.loading++;
                publicUserSvc.getPublicUserForEdit({
                    id: publicUser.id
                }).success(function (d) {
                    vm.publicUser = d.publicUser;
                }).finally(function () {
                    vm.loading--;
                });
            }

            var vm = this;
		        vm.constsUser = constsUser;
            vm.publicUser = publicUser;
            vm.countries = countries;
            vm.loading = 0;
            vm.saving = 0;

            vm.save = function () {
                vm.saving++;
                publicUserSvc.updatePublicUser(vm.publicUser)
                    .success(function () {
                        abp.notify.info(App.localize("SuccessfullySaved"));
                        $uibModalInstance.close();
                    }).finally(function () {
                        vm.saving--;
                    });
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            init();
        }
    ]);
})();
