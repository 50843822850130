(function () {
    var componentId = 'revenueCorporatesComments';
    var module = angular.module('revenue');

    function controller(corporateCommentSvc) {
        function refreshTimeline(corporate) {
            vm.comments = [];
            if (corporate) {
                vm.loading++;
                corporateCommentSvc.getComments({
                    corporateId: corporate.id,
                    type: 'UserComment'
                }).success(function (data) {
                    vm.comments = _.reverse(data.items);
                }).finally(function () {
                    vm.loading--;
                });
            }
        }

        function refreshContainerTimeline() {
            if (vm.container.refreshTimeline) {
                vm.container.refreshTimeline();
            }
        }

        const vm = this;
        vm.loading = 0;
        vm.updating = false;
        vm.comments = [];
        vm.newComment = '';

        vm.$onChanges = function (changesObj) {
            if (changesObj.corporate) {
                const corporate = changesObj.corporate.currentValue;
                refreshTimeline(corporate);
            }
        };

        vm.addComment = function () {
            let newComment = (vm.newComment || '').trim();
            if (!newComment) return;

            if (vm.updating) return;

            vm.updating = true;
            corporateCommentSvc.addComment({
                corporateId: vm.corporate.id,
                content: newComment
            }).success(function (data) {
                vm.comments.unshift(data);
                vm.newComment = '';
                refreshContainerTimeline();
            }).finally(function () {
                vm.updating = false;
            });
        };

        vm.deleteComment = function (comment) {
            if (vm.updating) return;

            abp.message.confirm(App.localize('ConfirmDeleteCommentWarning'), App.localize('AreYouSure'), function (d) {
                if (d && !vm.updating) {
                    vm.updating = true;
                    corporateCommentSvc.deleteComment({ id: comment.id }).success(function () {
                        const idx = vm.comments.indexOf(comment);
                        vm.comments.splice(idx, 1);
                        refreshContainerTimeline();
                    }).finally(function () {
                        abp.notify.success(App.localize("SuccessfullyDeleted"));
                        vm.updating = false;
                    });
                }
            });
        };
    }

    module.component(componentId, {
        require: { container: '^revenueCorporatesContainer' },
        bindings: { corporate: '<' },
        templateUrl: require('/App/modules/revenue/views/corporates/comments/widget.html'),
        controller: [
            'abp.services.revenue.corporateComment', controller],
        controllerAs: 'vm'
    });
})();
