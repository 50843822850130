(() => {
  angular
    .module('app')
    .controller('common.views.modals.memberOnboardingGuide', MemberOnboardingGuideController);

  MemberOnboardingGuideController.$inject = ['$uibModalInstance', 'redirectUrl'];

  function MemberOnboardingGuideController($modal, redirectUrl) {
    const vm = this;

    vm.redirectUrl = redirectUrl;
    vm.emailSubject = 'Subject: Welcome to [Company Name]!';
    vm.emailContent = '';

    vm.cancel = cancel;

    init();

    function init() {
      formatEmailContent();
    }

    function formatEmailContent() {
      vm.emailContent += 'Dear [Employee Name],\r\n\r\n';
      vm.emailContent += 'Welcome to [Company Name]! We are excited to have you join our team.\r\n';
      vm.emailContent += 'Attached to this email is important information to help you get started with our employee healthcare benefit partner, HealthMetrics.\r\n\r\n';
      vm.emailContent += 'If you have any questions, please do not hesitate to contact us.\r\n\r\n';
      vm.emailContent += 'Best regards,\r\n';
      vm.emailContent += '[Your Name]\r\n';
      vm.emailContent += '[Your Position]';
    }

    function cancel() {
      $modal.dismiss();
    }
  }
})();
