(() => {
  angular
    .module('app')
    .controller(
      'common.views.postHospitalization.guaranteeLetters.createModal',
      PostHospitalizationGLCreateController
    );

  PostHospitalizationGLCreateController.$inject = [
    '$scope',
    '$uibModalInstance',
    'abp.services.app.postHospitalizationGuaranteeLetter',
    'employee',
    'Hms.Employees.Employee',
    'moment',
  ];

  function PostHospitalizationGLCreateController(
    $scope,
    $uibModalInstance,
    postHospitalizationGuaranteeLetterSvc,
    employee,
    constsEmployee,
    moment
  ) {
    const vm = this;

    vm.constsEmployee = constsEmployee;
    vm.loading = 0;
    vm.submitting = 0;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.source = 4; // GuaranteeLetterSource.Host

    vm.appointmentDateOptions = {
      min: moment().startOf('day'),
      max: moment().startOf('day'),
    };

    vm.isHost = App.isHost();
    vm.isCorporate = App.isCorporate();
    vm.employee = employee;
    vm.employees = [];
    vm.patientPreviousAdmissions = [];
    vm.filteredAdmissions = [];
    vm.newAttachments = [];
    vm.uploadConfig = {
      objectType: 'LetterAttachment',
    };

    vm.lookupEmployees = lookupEmployees;
    vm.getGuaranteeLetterForCreate = getGuaranteeLetterForCreate;
    vm.isPatientPanelDisabled = isPatientPanelDisabled;
    vm.showPatientPanel = showPatientPanel;
    vm.lookupDiagnoses = lookupDiagnoses;
    vm.setAdmissionRecord = setAdmissionRecord;
    vm.checkAppointmentDateValidity = checkAppointmentDateValidity;
    vm.selectedPatient = selectedPatient;
    vm.submit = submit;
    vm.cancel = cancel;
    vm.isInvalidAttachment = isInvalidAttachment;

    init();

    function init() {
      if (vm.isHost) {
        vm.getGuaranteeLetterForCreate();
      }
    }

    function lookupEmployees(search) {
      if (search) {
        postHospitalizationGuaranteeLetterSvc
          .findEmployees({
            filter: search,
            maxResultCount: 5,
          })
          .success((data) => {
            vm.employees = data.items;
          });
      }
    }

    function getGuaranteeLetterForCreate() {
      if (vm.employee) {
        reset();

        vm.loading += 1;
        postHospitalizationGuaranteeLetterSvc
          .getGuaranteeLetterForCreate({
            id: vm.employee.id,
          })
          .success((data) => {
            vm.patients = data.patients;
            vm.noPatients = !vm.patients.length;
            vm.principal = data.principal;
            vm.clinicLocations = data.clinicLocations;

            vm.isPrincipalPanelOpen = true;
            vm.isPatientPanelOpen = false;

            vm.allPreviousAdmissions = data.previousAdmissionRecords;
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    }

    function isPatientPanelDisabled() {
      const isPrincipalValid = vm.principal;
      return !isPrincipalValid;
    }

    function showPatientPanel() {
      if (!vm.isPatientPanelDisabled()) {
        vm.isPrincipalPanelOpen = false;
        vm.isPatientPanelOpen = true;
      }
    }

    function lookupDiagnoses(filter) {
      const keyword = _.trim(filter || '');
      if (keyword) {
        const filteredAdmissions = [];

        const regexes = _.chain(keyword.split(' '))
          .map((k) => _.trim(k.toUpperCase()))
          .filter((k) => k)
          .uniq()
          .map((k) => ({
            token: k,
            len: k.length,
            pattern: new RegExp(`\\b${k}`, 'i'),
          }))
          .value();

        _.forEach(vm.filteredAdmissions, (d) => {
          let score = 0;
          _.forEach(regexes, (r) => {
            if (r.pattern.test(d.diagnosesDisplayName)) {
              score -= r.len;
            }
          });
          if (score) {
            filteredAdmissions.push({
              score,
              item: d,
            });
          }
        });

        vm.filteredAdmissions = _.chain(filteredAdmissions)
          .sortBy(['score'])
          .take(30)
          .map((k) => k.item)
          .uniqBy((d) => d.id)
          .value();
      } else {
        vm.filteredAdmissions = vm.patientPreviousAdmissions;
      }
    }

    function setAdmissionRecord($item) {
      vm.letter.doctors = [];
      vm.doctors = $item.doctors;
      vm.letter.panelId = $item.panelId;
      vm.letter.panelName = $item.panelName;
      vm.isSelectDateMoreThanMaxDate = false;

      vm.dischargeDate = moment($item.dischargeDate).startOf('day');
      vm.admissionDate = moment($item.admissionDate).startOf('day');
      // HM-8806: The range of date to be chosen is 
      // from today's date to the end of SOB coverage date.
      vm.todayDate = moment().startOf('day');
      vm.admissionTimeOptions = {
        min: vm.admissionDate,
        max: vm.dischargeDate,
      };
      vm.maxAppintmentDate = moment(vm.dischargeDate).add(1, 'days');
      vm.appointmentDateOptions = {
        min: vm.todayDate,
        max: moment(vm.dischargeDate).add(vm.patient.maxCoveredDays, 'days').startOf('day'),
      };
      vm.finalDiagnosis = $item.diagnosesDisplayName;
      vm.guaranteeLetterNumber = $item.guaranteeLetterNumber;
      vm.doctors = $item.doctors;
      vm.letter.doctors = $item.doctors;
      vm.letter.doctorIds = [];
      $item.doctors.forEach((doctor) => {
        vm.letter.doctorIds.push(doctor.id);
      });
      vm.postHospitalizationCoverageDays = vm.patient.maxCoveredDays;
      if (vm.todayDate > vm.appointmentDateOptions.max) {
        vm.isSelectDateMoreThanMaxDate = true;
      }
    }

    function checkAppointmentDateValidity(appointmentDate) {
      vm.isSelectDateMoreThanMaxDate = appointmentDate > vm.appointmentDateOptions.max;
    }

    function selectedPatient($item) {
      vm.patient = null;
      vm.letter.letterUniqueId = null;
      if (!vm.letter.employeeId) return;

      vm.patient = $item;
      vm.letter.guaranteedAmount = vm.patient.guaranteedAmount;

      vm.patientPreviousAdmissions = _.find(
        vm.allPreviousAdmissions,
        (obj) => obj.employeeId === $item.id
      ).previousAdmissions;

      vm.filteredAdmissions = vm.patientPreviousAdmissions;
    }

    function submit() {
      const input = $.extend(vm.letter, {
        newAttachments: vm.newAttachments,
        source: vm.source,
      });

      if (
        vm.letter.appointmentDate > vm.appointmentDateOptions.max &&
        vm.letter.appointmentDate != null
      ) {
        abp.message.error(App.localize('SelectDateMoreThanCoverageWarning'));
        vm.letter.appointmentDate = null;
        return;
      }

      vm.submitting += 1;
      postHospitalizationGuaranteeLetterSvc
        .createGuaranteeLetter(input)
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySubmitted'));
          $uibModalInstance.close({
            isApproved: input.signatureProfileId,
          });
        })
        .finally(() => {
          vm.submitting -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function reset() {
      vm.principal = null;
      vm.patients = null;
      vm.letter = {};
      vm.filteredAdmissions = [];
      vm.clinicLocations = [];
      vm.noPatients = false;
      vm.letter.appointmentDate = vm.appointmentDateOptions.min;
    }

    function isInvalidAttachment() {
      return (
        !vm.isDisciplineInReferralExceptions && !vm.newAttachments.length
      );
    }
  }
})();
