(() => {
  angular
    .module('app')
    .controller('common.views.saleTransactions.generateResultModal', GenerateResultModalController);

  GenerateResultModalController.$inject = [
    '$uibModalInstance',
    'errors',
    'fileType',
    'Hms.SaleTransactions.PaymentInstructionFileType',
  ];

  function GenerateResultModalController(
    $uibModalInstance,
    errors,
    fileType,
    enumPaymentInstructionFileType
  ) {
    const vm = this;

    vm.errorText = errors;
    vm.fileType = fileType;

    vm.enums = {
      paymentInstructionFileType: enumPaymentInstructionFileType,
    };

    vm.cancel = cancel;

    init();

    function init() {}

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
