(function () {
    var controllerId = 'host.views.operationsDashboard.insuranceClaims.downloadBatchInvoiceModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', '$timeout', '$interval', 'abp.services.app.insurerClaim',
        function ($scope, $uibModalInstance, $timeout, $interval, insurerClaimSvc) {
            var vm = this;

            function init() {
                vm.getFilterSuggestions();
                $timeout(function () {
                    vm.gridOptions.paginationCurrentPage = Math.floor(vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1);
                });

                var tickerInterval = $interval(vm.intervalRefresh, 10000);
                $scope.$on('$destroy', function () {
                    if (angular.isDefined(tickerInterval)) {
                        $interval.cancel(tickerInterval);
                        tickerInterval = undefined;
                    }
                });
            }

            vm.loading = 0;
            vm.isSilentLoading = 0;

            vm.requestParams = {
                filter: null,
                skipCount: 0,
                maxResultCount: app.consts.grid.defaultPageSize,
            }

            vm.getFilterSuggestions = function () {
                vm.loading++;
                insurerClaimSvc.getInsuranceClaimsBatchFiltering()
                    .success(function (data) {
                        vm.selections = data.items;
                    })
                    .finally(function () {
                        vm.loading--;
                    })
            }

            vm.intervalRefresh = function () {
                if (vm.loading || vm.isSilentLoading) return;
                vm.isSilentLoading++;
                vm.getInsuranceClaimsBatchListing();
            };

            vm.downloadBatchInvoices = function (batch) {
                vm.loading++
                insurerClaimSvc.getInsuranceClaimBatchInvoices({ id: batch.batchId })
                    .success(function (data) {
                        App.downloadTempFile(data);
                    })
                    .finally(function () {
                        vm.loading--;
                    })
            }

            vm.enqueueGenerateInsurerInvoicePdfsJob = function (batch) {
                vm.loading++
                insurerClaimSvc.enqueueGenerateInsurerInvoicePdfsJob({ id: batch.batchId })
                    .success(function () {
                        batch.hasInvoicesEnqueued = true;
                    })
                    .finally(function () {
                        vm.loading--;
                    })
            }

            vm.gridOptions = {
                appScopeProvider: vm,
                paginationPageSizes: app.consts.grid.defaultPageSizes,
                paginationPageSize: app.consts.grid.defaultPageSize,
                paginationCurrentPage: 0,
                useExternalPagination: true,
                useExternalSorting: true,
                resizable: true,
                columnDefs: [{
                    name: App.localize('Download'),
                    displayName: 'Download',
                    enableSorting: false,
                    width: 100,
                    headerCellTemplate: '<span></span>',
                    cellTemplate: 'downloadTemplate',
                    allowCellFocus: false
                }, {
                    displayName: App.localize('BatchNumber'),
                    field: 'batchNumber',
                    enableSorting: false,
                    minWidth: 100
                }, {
                    displayName: App.localize('Insurer'),
                    field: 'insurerName',
                    enableSorting: false,
                    minWidth: 100
                }],
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                    $scope.gridApi.core.on.sortChanged($scope, function (scope, callback) {
                        vm.requestParams.sorting = callback.length && callback[0].field ? callback[0].field + ' ' + callback[0].sort.direction : null;
                        vm.getInsuranceClaimsBatchListing();
                    });
                    gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
                        vm.requestParams.skipCount = (newPage - 1) * pageSize;
                        vm.requestParams.maxResultCount = pageSize;
                        vm.getInsuranceClaimsBatchListing();
                    });
                },
                data: []
            };

            vm.getInsuranceClaimsBatchListing = function () {
                vm.loading++;
                insurerClaimSvc.getInsuranceClaimsBatchListing(vm.requestParams)
                    .success(function (data) {
                        vm.gridOptions.totalItems = data.totalCount;
                        vm.gridOptions.data = data.items;
                    })
                    .finally(function () {
                        vm.loading--;
                        vm.isSilentLoading = 0;
                    });
            };

            vm.close = function () {
                $uibModalInstance.close();
            }

            init();
        }
    ]);
})();
