(() => {
  angular.module('app').factory('currencies', CurrenciesFactory);

  CurrenciesFactory.$inject = [];

  function CurrenciesFactory() {
    let currencies;

    init();

    // Duplicate the array to prevent array modification.
    return [...currencies];

    function init() {
      if (currencies) return;

      // Use global abp object because service proxy not loaded yet.
      abp
        .ajax({
          url: `${abp.appPath}api/services/app/commonLookup/getCurrencies`,
          type: 'POST',
          async: false,
        })
        .done((result) => {
          currencies = _.sortBy(result.items, 'codeName');
        });
    }
  }
})();
