/* global __ZENDESK_WIDGET_KEY__, ga, zE */

import swal from 'sweetalert';

(() => {
  angular.module('app').component('appLayoutComponent', {
    templateUrl: require('./appLayout.component.html'),
    controller: AppLayoutController,
    controllerAs: 'vm',
    bindings: {},
  });

  AppLayoutController.$inject = [
    '$http',
    '$interval',
    '$timeout',
    'appSession',
    'abp.services.app.tenant',
    'common.integrations.zendesk',
    'abp.services.app.commonLookup',
    'moment',
    '$rootScope',
    'versionChecker',
    '$window'
  ];

  function AppLayoutController(
    $http,
    $interval,
    $timeout,
    appSession,
    tenantSvc,
    zendeskSvc,
    commonLookupSvc,
    moment,
    $root,
    versionChecker,
    $window
  ) {
    const vm = this;

    vm.user = appSession.user;
    vm.tenant = appSession.tenant;
    const isHost = App.isHost();
    let timeIncrementer = null;

    vm.isTimeOutOfSync = isTimeOutOfSync;
    vm.webSiteAlertMessage = null;

    init();

    function init() {
      initThirdPartyPlugins();

      versionChecker.start({
        onNewVersion: () => {
          // Prompt user to update on toastr

          abp.notify.warn(App.localize('ClickHereToUpdate'), App.localize('AppOutdated'), {
            timeOut: 0,
            extendedTimeOut: 0,
            preventDuplicates: true,
            onclick() {
              $window.location.reload();
            },
          });
        },
      });
      
      getWebSiteAlertMessageSetting();
    }

    function initThirdPartyPlugins() {
      initGA();
      getServerTime();
      initPanelSelfCheck();
      if (__ZENDESK_WIDGET_KEY__) configureZendeskWidget();
    }

    // Google Analytics initialization.

    function initGA() {
      if (typeof ga === 'undefined') return;
      if (!ga || !appSession) return;

      const application = appSession.application || {};
      const tenant = appSession.tenant || {};
      const user = appSession.user || {};

      ga('set', {
        dimension1: `${tenant.tenantType}`,
        dimension2: `${application.version}`,
        dimension3: moment(application.releaseDate).toISOString(),
        dimension4: tenant.tenancyName,
        dimension5: tenant.name,
        dimension6: `${tenant.clinicType}`,
        dimension7: user.userName,
      });
    }

    // Server clock.

    function isTimeOutOfSync() {
      if (!App.serverTime) return false;

      // Consider out-of-sync If time difference more than 5 minutes.

      const timeDiff = App.serverTime.diff(moment());
      return Math.abs(timeDiff) > 300000;
    }

    function getServerTime() {
      $interval.cancel(timeIncrementer);
      App.serverTime = null;

      let fetchStartTime = null; // Get the approximate TTFB.
      $http({
        method: 'GET',
        url: $root.resolveAppPath('/api/time/server'),
        abpHandleError: false,
        eventHandlers: {
          loadstart() {
            fetchStartTime = moment();
          },
        },
      })
        .success((d) => {
          // Estimate time since TTFB .
          const loadTimeOffset = moment().diff(fetchStartTime);

          // Offset TTFB to get a more accurate current server time.
          App.serverTime = moment(d).add(loadTimeOffset);

          let localTime = moment();

          function incrementServerTime() {
            const newLocalTime = moment();

            // Determine increment considering interval delay.
            const increment = newLocalTime.diff(localTime);

            // If there is a large interval delay (3 minute),
            // assume either PC/Browser went to sleep or user changed time,
            // resync server time.

            if (increment < 0 || increment > 180000) {
              getServerTime();
              return;
            }

            // Update server time.

            App.serverTime.add(increment);
            localTime = newLocalTime; // Cache local time to measure interval delay.
          }

          // Initiate server time tick.

          timeIncrementer = $interval(incrementServerTime, 1000);
        })
        .error(() => {
          $timeout(getServerTime, 10000); // Retry after 10 seconds.
        });
    }

    // Panel self check process

    function initPanelSelfCheck() {
      if (!App.isClinic()) return;

      tenantSvc.getPanelSelfCheckContent().success((content) => {
        if (content) {
          swal(
            {
              title: _.toUpper(App.localize('Important')),
              type: 'warning',
              text: content,
              showConfirmButton: true,
              allowEscapeKey: false,
              html: true,
            },
            (d) => {
              if (d) {
                tenantSvc.updatePanelSelfCheckDate().success(() => {
                  swal.close();
                });
              }
            }
          );
          App.swal.disableButtons(10);
        }
      });
    }

    // Zendesk

    function configureZendeskWidget() {
      commonLookupSvc.checkIsZendeskEnabled().success((result) => {
        if (result) {
          const tenantName = !vm.tenant ? '' : vm.tenant.zendeskOrganizationName;

          zE(() => {
            // Hide widget for host.

            if (isHost) {
              // zE.hide() is a legacy command. Updated to latest syntax.
              zE('webWidget', 'hide');
            } else {
              zendeskSvc.identify(vm.user.name, vm.user.emailAddress, tenantName);
              zendeskSvc.initialize(vm.user.zendeskWebWidgetToken);
            }
          });
        }
      });
    }

    function getWebSiteAlertMessageSetting(){
      commonLookupSvc
        .getWebSiteAlertMessage()
        .success((data) => {
          vm.webSiteAlertMessage = data;
        })
    }
  }
})();
