(() => {
  angular.module('app').component('commonInpatientTicketsDetailMedicalCertificateComponent', {
    templateUrl: require('./medicalCertificate.component.html'),
    controller: MedicalCertificateController,
    controllerAs: 'vm',
    bindings: {
      ticket: '<',
      isEdit: '<',
      isSaved: '<',
      type: '<',
    },
  });

  MedicalCertificateController.$inject = ['moment'];

  function MedicalCertificateController(moment) {
    const vm = this;
    vm.loading = 0;

    vm.issueMc = issueMc;
    vm.removeMc = removeMc;
    vm.getMcDurationDescription = getMcDurationDescription;

    function getMcDurationDescription() {
      let description = '';
      if (vm.ticket.medicalCertificate) {
        const startDate = moment(vm.ticket.medicalCertificate.startDate);
        const endDate = moment(vm.ticket.medicalCertificate.endDate);
        const duration = endDate.diff(startDate, 'days') + 1;
        if (!Number.isNaN(Number(duration))) {
          description = App.localize('XDays', duration);
        }
      }
      return description;
    }

    function issueMc() {
      if (vm.ticket.medicalCertificate) {
        vm.ticket.medicalCertificate = {
          startDate: vm.ticket.admissionDate,
          endDate: null,
          remarks: '',
        };
      } else {
        vm.ticket.medicalCertificate = {
          startDate: moment().startOf('day'),
          endDate: null,
          remarks: '',
        };
      }
    }

    function removeMc() {
      vm.ticket.medicalCertificate = null;
    }
  }
})();
