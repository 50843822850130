(function () {
    var componentId = 'clinicPatientStepIndicatorWidget';
    var app = angular.module('app');

    function controller($scope) {
        var vm = this;

        /* vm.stepIndicator */
        // Indicates step from check in to ticket submission
        // 0: "Check-in" bold
        // 1: "Prescription ticket" bold
        // 2: "Submission" bold

        vm.stepIndicator = 0;

        // Listen to 'reset' broadcast

        $scope.$on('reset', function () {
            vm.checkInBtn = false;
            vm.stepIndicator = 0;
        });

        // Listen to 'checkIn' broadcast

        $scope.$on('checkIn', function () {
            vm.checkInBtn = true;
            vm.stepIndicator = 0;
        });

        // Listen to 'createTicket' broadcast

        $scope.$on('createTicket', function () {
            vm.checkInBtn = true;
            vm.prescriptionBtn = false;
            vm.stepIndicator = 1;
        });

        // Listen to 'prescriptionTicket' broadcast

        $scope.$on('prescriptionTicket', function () {
            vm.checkInBtn = true;
            vm.prescriptionBtn = true;
            vm.stepIndicator = 2;
        });

        // Listen to 'submission' broadcast

        $scope.$on('submission', function () {
            vm.checkInBtn = true;
            vm.prescriptionBtn = true;
            vm.submissionBtn = true;
        });
    }

    app.component(componentId, {
        templateUrl: require('/App/clinic/views/patient/stepIndicator/widget.html'),
        controller: ['$scope', controller],
        controllerAs: 'vm'
    });
})();
