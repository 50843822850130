(function () {
  var componentId = 'corporateMemberDataConsentDelegateConsentComponent';
  var app = angular.module('app');

  function controller($rootScope, $scope, $stateParams, employeeSvc) {
    var vm = this;
    vm.saving = 0;
    vm.action = 0;
    vm.memberId = $stateParams.id;

    var fileExtensions = '|' + app.consts.fileUpload.imageExtensions.concat(app.consts.fileUpload.pdfExtensions).join('|') + '|';
    vm.uploadConfig = {
      objectType: 'DelegateConsentAttachment',
      itemsLimit: 1,
      accept: 'image/*,application/pdf',
      filters: [{
        name: 'fileFilter',
        fn: function (n) {
          var t = '|' + n.type.slice(n.type.lastIndexOf('/') + 1) + '|';
          if (fileExtensions.indexOf(t) === -1) {
            abp.message.warn(App.localize('File_Warn_FileType'));
            return false;
          }
          return true;
        }
      }, {
        name: 'sizeFilter',
        fn: function (n) {
          var sizeLimit = 52428800;
          if (n.size > sizeLimit) {
            abp.message.warn(App.localize('File_Warn_XSizeLimit', sizeLimit / 1024 / 1024));
            return false;
          }
          return true;
        }
      }]
    };

    $scope.$on('showAttachments', function (event, args) {
      vm.imgAttachment = null;
      vm.attachments = [];

      var attachments = args.attachments || [];
      if (attachments.length === 1 && attachments[0].contentType.startsWith('image/')) {
        // Display image element if only one attachment and it's image file.
        vm.imgAttachment = attachments[0];
      } else {
        vm.attachments = attachments;
      }

      vm.action = 1;
    });

    vm.cancelProvideConsent = cancelProvideConsent;
    vm.closeAttachmentViewer = closeAttachmentViewer;
    vm.provideConsent = provideConsent;
    vm.revokeConsent = revokeConsent;
    vm.showDataConsentAttachmentUploader = showDataConsentAttachmentUploader;

    function cancelProvideConsent() {
      if (vm.newAttachments.length > 0) {
        abp.message.confirm(App.localize('UnsavedChangesConfirmation'), App.localize('AreYouSure'), function (d) {
          if (d) {
            vm.newAttachments = [];
            vm.action = 0;
          }
        });
      } else {
        vm.action = 0;
      }
    }

    function closeAttachmentViewer() {
      vm.action = 0;
      vm.imgAttachment = null;
      vm.attachments = [];
    }

    function completeAction() {
      vm.action = 0;
      $rootScope.$broadcast('refresh');
      vm.newAttachments = [];
    }

    function provideConsent() {
      if (vm.saving) return;

      vm.saving++;
      employeeSvc.grantDelegateDataConsent({
        memberId: vm.memberId,
        newAttachments: vm.newAttachments
      }).success(function () {
        abp.notify.success(App.localize('SuccessfullySaved'));
        completeAction();
      }).finally(function () {
        vm.saving--;
      });
    }

    function revokeConsent() {
      window.onbeforeunload = function () {
        return title;
      };
      swal({
        title: App.localize('RevokeDataConsent'),
        type: 'warning',
        text: App.localize('RevokeDataConsentWarningMessage', vm.memberDisplayName),
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: App.localize('Yes'),
        cancelButtonText: App.localize('No'),
        closeOnConfirm: true,
        closeOnCancel: true,
        html: true
      }, function () {
        window.onbeforeunload = null;
        revokeDelegateDataConsent();
      });
    }

    function revokeDelegateDataConsent() {
      if (vm.saving) return;

      vm.saving++;
      employeeSvc.revokeDelegateDataConsent({
        id: vm.memberId
      }).success(function () {
        abp.notify.success(App.localize('SuccessfullySaved'));
        completeAction();
      }).finally(function () {
        vm.saving--;
      });
    }

    function showDataConsentAttachmentUploader() {
      vm.newAttachments = vm.newAttachments || [];
      vm.uploadDataConsentAttachmentNarrative = vm.uploadDataConsentAttachmentNarrative ||
        App.localize('UploadDataConsentAttachmentNarrative', vm.memberDisplayName);
      vm.action = 2;
    }
  }

  app.component(componentId, {
    templateUrl: require('/App/corporate/views/employees/dataConsent/delegateConsent/delegateConsent.component.html'),
    controller: ['$rootScope', '$scope', '$stateParams', 'abp.services.app.employee', controller],
    controllerAs: 'vm',
    bindings: {
      loading: '<',
      memberDisplayName: '<',
      canDelegateConsent: '<',
      hasDelegateDataConsentGranted: '<'
    }
  });
})();
