(function () {
    'use strict';

    angular.module('app').component('corporateDashboardMemberInformationStatisticsComponent', {
        templateUrl: require('/App/corporate/views/dashboard/memberInformationStatistics/memberInformationStatistics.component.html'),
        controller: ['$uibModal', controller],
        controllerAs: 'vm',
        bindings: {
            statistic: '<'
        }
    });

    function controller($modal) {
        var vm = this;
        vm.loading = false;
    }
})();
