(function () {
    'use strict';

    var componentId = 'hostHospitalizationExclusionsClauseNoteComponent';
    var app = angular.module('app');

    function controller($document, hospitalizationExclusionSvc, moment) {
        function init() {
            _.each(vm.exclusions, function (p) {
                p.isNewRecord = p.id < 0;
            });

            getExclusionNoteSuggestionHash();
        }

        var vm = this;
        vm.today = moment().startOf('day');
        vm.keywords = [];

        function getExclusionNoteSuggestionHash() {
            vm.loading++;
            hospitalizationExclusionSvc.getExclusionNoteSuggestionHash({
            }).success(function (data) {
                vm.keywords = data;
            }).finally(function (e) {
                vm.loading--;
            });
        }

        $document.ready(function () {
            _.each(vm.exclusions, function (p) {
                $('#content-' + p.type + '-' + p.id + '').summernote('code', p.clause);
                if (!vm.isEditing) {
                    $('#content-' + p.type + '-' + p.id + '').summernote('disable');
                }
            });
        });

        vm.$onChanges = function (changes) {
            _.each(vm.exclusions, function (p) {
                if (vm.isEditing) {
                    $('#content-' + p.type + '-' + p.id + '').summernote('enable');
                } else {
                    $('#content-' + p.type + '-' + p.id + '').summernote('disable');
                }
            });
        };

        vm.addClause = function (exclusionType) {
            vm.add({ exclusionType: exclusionType });
        }

        vm.deleteClause = function (id, exclusionType) {
            vm.remove({ exclusionId: id, exclusionType: exclusionType });
        }

        vm.maxCallBack = function (id, exclusionType, max) {
            $('#content-max-' + exclusionType + '-' + id + '').text(max + '/' + vm.characterLimit);
        }

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/hospitalizationExclusions/exclusionEditor/clauseNote/clauseNote.component.html'),
        controller: ['$document', 'abp.services.app.hospitalizationExclusion', 'moment', controller],
        controllerAs: 'vm',
        bindings: {
            exclusions: '<',
            exclusionType: '<',
            characterLimit: '<',
            isExclusion: '<',
            isEditing: '<',
            add: '&',
            remove: '&',
        },
    });
})();
