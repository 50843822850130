(() => {
  angular
    .module('app')
    .controller('host.views.publicHolidays.createOrEditModal', PublicHolidayEditModalController);

  PublicHolidayEditModalController.$inject = [
    '$uibModalInstance',
    'countries',
    'abp.services.app.publicHoliday',
    'publicHolidayId',
    'abp.services.app.commonLookup',
    'moment',
  ];

  function PublicHolidayEditModalController(
    $uibModalInstance,
    countries,
    publicHolidaySvc,
    publicHolidayId,
    commonLookupSvc,
    moment
  ) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;
    vm.publicHoliday = null;
    vm.warningMessage = '';
    vm.states = [];
    vm.defaultCountryCode = abp.setting.get('Hms.General.DefaultCountryCode');
    vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');

    vm.toggleNationwide = toggleNationwide;
    vm.getCountrySubdivisions = getCountrySubdivisions;
    vm.save = save;
    vm.onComplete = onComplete;
    vm.cancel = cancel;
    vm.validateDuplicateRecord = validateDuplicateRecord;

    init();

    function init() {
      vm.loading += 1;
      publicHolidaySvc
        .getPublicHolidayForEdit({
          id: publicHolidayId,
        })
        .success((data) => {
          vm.publicHoliday = data;

          // Set public holiday date on datepicker.

          vm.publicHoliday.momentDate = moment.utc(data.date);

          getCountrySubdivisions(vm.publicHoliday.countryCode);
          if (vm.publicHoliday.hasDuplicateRecord) {
            vm.prevName = vm.publicHoliday.name;
            vm.prevDate = moment(vm.publicHoliday.date).format('L');
          }
          if (publicHolidayId) {
            toggleNationwide();
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });

      if (vm.hasRegionalSystemSupport) {
        vm.loading += 1;
        commonLookupSvc
          .getEligibleCountries()
          .success((data) => {
            const elligibleCountryCodes = data.map((o) => o.code);

            vm.countries = _.filter(countries, (c) => elligibleCountryCodes.includes(c.code));
          })
          .finally(() => {
            vm.loading -= 1;
          });
      } else {
        vm.countries = _.filter(countries, (c) => c.code === vm.defaultCountryCode);
      }
    }

    function save() {
      if (App.isFormValid(vm.createOrEditForm) && areFieldsValid()) {
        onComplete();
      }
    }

    function onComplete() {
      vm.saving += 1;

      vm.publicHoliday.date = App.getDateString(vm.publicHoliday.momentDate);

      publicHolidaySvc
        .createOrUpdatePublicHoliday(vm.publicHoliday)
        .success((data) => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          $uibModalInstance.close(data);
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function validateDuplicateRecord() {
      return (
        vm.publicHoliday &&
        vm.publicHoliday.hasDuplicateRecord &&
        vm.prevName === vm.publicHoliday.name &&
        vm.prevDate === moment(vm.publicHoliday.date).format('L')
      );
    }

    function toggleNationwide() {
      areFieldsValid();
    }

    function areFieldsValid() {
      vm.showSelectOptionMessage = _.isNil(vm.publicHoliday.isNational);

      return !vm.showSelectOptionMessage;
    }

    function getCountrySubdivisions(countryCode) {
      // Exclude Singapore because Singapore has no states.
      const country = countryCode === 'SG' ? null : _.find(countries, { code: countryCode });
      if (country) {
        vm.states = country.subdivisions;
      } else {
        vm.states = [];
      }
    }
  }
})();
