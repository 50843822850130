import swal from 'sweetalert';

(() => {
  angular
    .module('app')
    .controller(
      'common.views.saleTransactions.invoiceDetail.singapore.reimbursement.page',
      InvoiceDetailSingaporeReimbursementPageController
    );

  InvoiceDetailSingaporeReimbursementPageController.$inject = [
    '$stateParams',
    'abp.services.app.saleTransaction',
  ];

  function InvoiceDetailSingaporeReimbursementPageController($stateParams, saleTransactionSvc) {
    const vm = this;

    vm.loading = false;
    vm.transactionNumber = $stateParams.transactionNumber;
    vm.invoice = null;
    vm.invoiceFrom = null;
    vm.invoiceTo = null;
    vm.message = null;
    vm.payments = [];
    vm.latestPayableAmount = null;
    vm.isInpatient = App.isInpatient();
    vm.invoiceSaleTransactionItems = [];
    vm.currentIndex = 0;
    vm.rowNumber = 0;
    vm.hasLicensingFeeCharging = abp.setting.getBoolean('Hms.Feature.LicenseFeeCharging');
    vm.stopInvoiceGeneration = abp.setting.getBoolean('Hms.Feature.StopPortalInvoiceGeneration');

    vm.getPaymentLabel = getPaymentLabel;
    vm.getIndex = getIndex;
    vm.send = send;

    init();

    function init() {
      vm.loading = true;
      saleTransactionSvc
        .getInvoice({
          id: vm.transactionNumber,
        })
        .success((data) => {
          const d = data;
          if (!d.invoice.departments) {
            d.invoice.departments = _.map(d.invoice.departmentsWithCategory, (dc) => {
              const items = _.flatMap(
                _.filter(dc.categories, (c) => c.saleTransactionItems),
                (n) => n.saleTransactionItems
              );
              return {
                id: dc.id,
                name: dc.name,
                saleTransactionItems: items,
                amount: _.sumBy(items, 'amount'),
              };
            });
          }

          vm.currencyCode = d.currencyCode;
          vm.countryCode = d.countryCode;
          vm.invoice = d.invoice;
          vm.invoiceFrom = d.invoiceFrom;
          vm.invoiceTo = d.invoiceTo;
          vm.payments = d.payments;
          vm.message = d.message;
          vm.isPharmacy = d.invoice.isPharmacy;

          // Set current payable amount.
          vm.latestPayableAmount = vm.invoice.amount - vm.invoice.knockedOffAmount;

          _.forEach(vm.invoice.departments, (department) => {
            _.forEach(department.saleTransactionItems, (item) => {
              vm.invoiceSaleTransactionItems.push(item);
            });
          });
        })
        .finally(() => {
          vm.loading = false;
        });
    }

    function getPaymentLabel() {
      return App.localize('XPaymentsMade', vm.payments.length);
    }

    function send() {
      saleTransactionSvc
        .getFinanceEmails({
          tenantId: vm.invoice.tenantId,
          subsidiaryId: vm.invoice.subsidiaryId,
        })
        .success((data) => {
          swal(
            {
              title: App.localize('SendEmailForX', vm.invoice.transactionNumber),
              text: App.localize('EmailAddresses'),
              type: 'input',
              showCancelButton: true,
              closeOnConfirm: false,
              confirmButtonColor: '#1ab394',
              inputPlaceholder: App.localize('EmailAddressesNarrative'),
              inputValue: data.emailAddresses,
              showLoaderOnConfirm: true,
            },
            (inputValue) => {
              if (inputValue === false) return false;
              if (inputValue === '') {
                swal.showInputError(App.localize('InvalidEmailAddress'));
                return false;
              }
              saleTransactionSvc
                .sendEmail({
                  transactionNumber: vm.invoice.transactionNumber,
                  emailAddresses: inputValue,
                })
                .success(() => {
                  abp.notify.info(App.localize('EmailSent'));
                  swal.close();
                });
              return false;
            }
          );
        });
    }

    function getIndex(index) {
      vm.rowNumber += 1;
      if (vm.currentIndex !== index) {
        vm.rowNumber = 0;
        vm.currentIndex = index;
      }

      return vm.rowNumber;
    }
  }
})();
