(() => {
  angular.module('app').controller('common.views.patientTickets.audit', TicketAuditController);

  TicketAuditController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$uibModal',
    'abp.services.app.patientTicket',
    'abp.services.app.commonLookup',
    'moment',
  ];

  function TicketAuditController(
    $scope,
    $state,
    $stateParams,
    $modal,
    patientTicketSvc,
    commonLookupSvc,
    moment
  ) {
    const vm = this;

    vm.loading = 0;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.hasMemberBenefitUtilizationEnabled = abp.setting.getBoolean(
      'Hms.Feature.MemberBenefitUtilization'
    );
    vm.ticketNumber = $stateParams.ticketNumber;
    vm.isClinic = App.isClinic();
    vm.selectedTab = null;
    vm.isHost = App.isHost();
    vm.selectedModifications = [];
    vm.selectedUtilizationAuditLogs = [];

    vm.tabSelected = tabSelected;
    vm.checkCheckBox = checkCheckBox;
    vm.checkUtilizationCheckBox = checkUtilizationCheckBox;
    vm.compareAuditLogs = compareAuditLogs;
    vm.goBack = goBack;

    init();

    function init() {
      if (!vm.isHost) getTenantCurrencyCode();
      vm.loading += 1;
      patientTicketSvc
        .getTicketAudit({
          id: $stateParams.ticketNumber,
        })
        .success((data) => {
          vm.ticketNumber = data.ticketNumber;
          vm.ticketType = data.ticketType;
          vm.modifications = data.modifications;
          vm.utilizationAdjustments = data.utilizationAdjustments;

          _.each(vm.modifications, (mod) => {
            const m = mod;
            m.items = processSnapshot(m.snapshot);
            m.formattedSnapshot = formatSnapshot(m.snapshot);
          });
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function tabSelected(i) {
      vm.selectedTab = i;
    }

    function formatSnapshot(snapshot) {
      const data = JSON.parse(snapshot);
      return JSON.stringify(data, null, 4);
    }

    function processSnapshot(snapshot) {
      const data = JSON.parse(snapshot);
      const output = {
        basics: {},
        arrays: {},
        objects: {},
      };

      // Preprocess value for formatting and localization.

      function preprocessValue(value, key) {
        const momentObj = moment(value, moment.ISO_8601);
        if (momentObj.isValid()) return momentObj.format('L');
        if (key === 'ClinicType') {
          switch (value) {
            case 1:
              return App.localize('GP');
            case 2:
              return App.localize('Dental');
            case 4:
              return App.localize('Optical');
            case 5:
              return App.localize('Specialist');
            case 6:
              return App.localize('Pharmacy');
            default:
              return App.localize('Unknown');
          }
        }
        return value;
      }

      // Preprocess object to uppercase first character and preprocess its value.

      function preprocessObject(v) {
        const obj = {};
        _.forIn(v, (objV, objK) => {
          const key = _.upperFirst(objK);
          obj[key] = preprocessValue(objV, key);
        });
        return obj;
      }

      // Traverse, process and organize every property.

      _.forIn(data, (value, key) => {
        const k = _.upperFirst(key);

        if (_.isArrayLikeObject(value)) {
          const orderedValues = _(value).map(JSON.stringify).sort().map(JSON.parse).value();

          _.forEach(orderedValues, (value2, key2) => {
            orderedValues[key2] = preprocessObject(value2, key2);
          });
          output.arrays[k] = orderedValues;
        } else if (_.isObjectLike(value)) output.objects[k] = preprocessObject(value);
        else output.basics[k] = preprocessValue(value, k);
      });

      return output;
    }

    function checkCheckBox() {
      vm.selectedModifications = _.filter(vm.modifications, 'isChecked');
    }

    function checkUtilizationCheckBox() {
      vm.selectedUtilizationAuditLogs = _.filter(vm.utilizationAdjustments, 'isChecked');
    }

    function compareAuditLogs() {
      if (vm.selectedTab === 1) {
        $modal.open({
          templateUrl: require('./compareAuditLogsModal.html'),
          controller: 'common.views.patientTickets.compareAuditLogsModal as vm',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            auditlogs() {
              return vm.selectedModifications;
            },
          },
        });
      } else if (vm.selectedTab === 2) {
        $modal.open({
          templateUrl: require('./compareUtilization.modal.html'),
          controller: 'common.views.patientTickets.compareUtilizationModal as vm',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            auditlogs() {
              return vm.selectedUtilizationAuditLogs;
            },
          },
        });
      }
    }

    function goBack() {
      $state.go('patientTicketDetail', {
        ticketNumber: vm.ticketNumber,
      });
    }

    function getTenantCurrencyCode() {
      vm.loading += 1;
      commonLookupSvc
        .getTenantCurrencyCode()
        .success((data) => {
          vm.currencyCode = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
