(function () {
    var controllerId = 'clinic.views.employees.checkOutModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance',
        function ($scope, $uibModalInstance) {

            var vm = this;
            vm.isSpecialist = App.isSpecialist();
            vm.reason = '';
            vm.explain = '';

            vm.cancel = function () {
                $uibModalInstance.dismiss();
            };

            vm.getFinalReason = function () {
                var reason = _.trim(vm.reason);
                if (reason === 'PatientPayCash' && !vm.isSpecialist) {
                    reason = '';
                } else if (reason === 'Others') {
                    var explain = _.trim(vm.explain);
                    if (explain) {
                        reason += ': ' + explain;
                    } else {
                        reason = '';
                    }
                }
                return reason;
            };

            vm.close = function () {
                var reason = vm.getFinalReason();
                if (!reason) return;
                $uibModalInstance.close(reason);
            };
        }
    ]);
})();
