(function () {
  'use strict';

  angular.module('app').component('clinicPanelDashboardBillingOverviewStatisticsComponent', {
    templateUrl: require('/App/clinic/views/panelDashboard/billingOverviewStatistics/billingOverviewStatistics.component.html'),
    controller: ['$uibModal', 'abp.services.app.panelDashboard', 'abp.services.app.commonLookup', 'abp.services.app.financeStatistic'
    ,controller],
    controllerAs: 'vm',
    bindings: {
      currencyCode: '<'
    }
  });

  function controller($modal, panelDashboardSvc, commonLookupSvc, statisticSvc) {
    const vm = this;
    vm.openBalance = null;
    vm.openInvoiceAmount = null;
    vm.openCreditNoteAmount = null;

    vm.hasAccessFinanceTransactionData = abp.setting.getBoolean('Hms.Feature.AccessFinanceTransactionData');

    function init() {
      getTenantCountryCode();

      if (vm.hasAccessFinanceTransactionData) {
        getFinanceBillingOverviewStatistics();
      } else {
        getBillingOverviewStatistics();
      }
    }

    vm.loading = false;
    vm.widgetCss = {
      openBalance: 'widget-grey',
      openBalanceContainer: 'widget-white-smoke',
    };

    // Style the widgets so that the colors conform to the values that they represent.

    function styleWidgets() {
      const widgetColor = {
        green: 'widget-green-alt',
        red: 'widget-red-alt',
        black: 'widget-grey'
      };

      // Open balance colour indicator.

      if (vm.openBalance > 0) {
        vm.widgetCss.openBalance = widgetColor.green;
      } else if (vm.openBalance < 0) {
        vm.widgetCss.openBalance = widgetColor.red;
      } else {
        vm.widgetCss.openBalance = widgetColor.black;
      }
    }

    // Get country code for the panel.

    function getTenantCountryCode() {
      vm.loading += 1;
      commonLookupSvc.getTenantCountryCode()
        .success((data) => {
          vm.isMalaysia = data === "MY";
        }).finally(() => {
          vm.loading -= 1;
        });
    }

    // Obsolete, use getFinanceBillingOverviewStatistics() instead.

    function getBillingOverviewStatistics() {
      vm.loading += 1;
      panelDashboardSvc.getBillingOverviewStatistics()
        .success((data) => {
          vm.openBalance = data.openBalance;
          vm.openInvoiceAmount = data.openInvoiceAmount;
          vm.openCreditNoteAmount = Math.abs(data.openCreditNoteAmount);
          vm.openLicensingFeeAmount = data.openLicensingFeeAmount;
          styleWidgets();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getFinanceBillingOverviewStatistics() {
      vm.loading =+ 1;
      statisticSvc.getBillingOverviewStatistics()
      .success((data) => {
        vm.openBalance = data.openBalance;
        vm.openInvoiceAmount = data.openInvoiceAmount;
        vm.openCreditNoteAmount = Math.abs(data.openCreditNoteAmount);
        vm.openLicensingFeeAmount = data.openLicensingFeeAmount;
        styleWidgets();
      })
      .finally(() => {
        vm.loading -= 1;
      });
    }

    init();
  }
})();
