(() => {
  angular
    .module('app')
    .component(
      'commonPostHospitalizationGuaranteeLettersDetailsClaimHistoryPendingClaimsComponent',
      {
        templateUrl: require('./pendingClaims.component.html'),
        controller: PendingClaimsComponentController,
        controllerAs: 'vm',
        bindings: {},
      }
    );

  PendingClaimsComponentController.$inject = [
    '$stateParams',
    'abp.services.app.postHospitalizationGuaranteeLetter',
    'Hms.SpecialistClaims.SpecialistClaimStatus',
  ];

  function PendingClaimsComponentController(
    $stateParams,
    postHospitalizationGuaranteeLetterSvc,
    enumSpecialistClaimStatus
  ) {
    const vm = this;
    // TODO: Implement with post hospitalization claim process. 
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.loading = 0;

    vm.enums = {
      specialistClaimStatus: enumSpecialistClaimStatus,
    };

    init();

    function init() {
      vm.letterNumber = $stateParams.letterNumber;
      getPendingPostHospitalizationClaims();
    }

    // TODO: Implement with post hospitalization claim process.
    function getPendingPostHospitalizationClaims() {
      vm.loading += 1;
      postHospitalizationGuaranteeLetterSvc
        .getPendingPostHospitalizationClaims({
          letterNumber: vm.letterNumber,
        })
        .success((data) => {
          vm.totalCount = data.totalCount;
          vm.claims = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
