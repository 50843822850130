(() => {
  angular.module('app').directive('convertToNumber', convertToNumberDirective);

  convertToNumberDirective.$inject = [];

  function convertToNumberDirective() {
    const directive = {
      link,
      restrict: 'EA',
      require: 'ngModel',
    };
    return directive;

    function link(_scope, _element, _attrs, ngModel) {
      ngModel.$parsers.push((val) => (val === null || val === '' ? null : parseInt(val, 10)));
      ngModel.$formatters.push((val) => (val == null ? '' : `${val}`));
    }
  }
})();
