(function () {
    let componentId = 'hostInsurerTeamCreateEditProperties';
    let app = angular.module('app');

    function controller($scope, insurerSvc, constsInsurerTeam) {
        function init() {
            // EMPTY
        }

        var vm = this;
		    vm.constsInsurerTeam = constsInsurerTeam;
        vm.loading = 0;
        vm.saving = 0;

        vm.isEditing = false;
        vm.permissions = {
            edit: abp.auth.isGranted('Host.NewInsurers.EditProperties'),
            create: abp.auth.isGranted('Host.NewInsurers.Create'),
        };

        vm.$onInit = function () {
            vm.container.addWidget(this);
        };

        vm.$onChanges = function () {

        };

        vm.isDirty = function () {
            return $scope.insurerTeamCreateEditPropertiesForm && $scope.insurerTeamCreateEditPropertiesForm.$dirty;
        };

        vm.setPristine = function () {
            $scope.insurerTeamCreateEditPropertiesForm.$setPristine();
        };
        var fileExtensions = '|' + app.consts.fileUpload.imageExtensions.concat(app.consts.fileUpload.pdfExtensions).join('|') + '|';

        vm.uploadConfig = {
            objectType: 'InsurerLogo',
            accept: 'image/*',
            itemsLimit: 1,
            filters: [{
                name: 'fileFilter',
                fn: function (n) {
                    var t = '|' + n.type.slice(n.type.lastIndexOf('/') + 1) + '|';
                    if (fileExtensions.indexOf(t) === -1) {
                        abp.message.warn(App.localize('File_Warn_FileType'));
                        return false;
                    }
                    return true;
                }
            }, {
                name: 'sizeFilter',
                fn: function (n) {
                    var sizeLimit = 52428800;
                    if (n.size > sizeLimit) {
                        abp.message.warn(App.localize('File_Warn_XSizeLimit', sizeLimit / 1024 / 1024));
                        return false;
                    }
                    return true;
                }
            }]
        };


        // Force validation on this form.
        // Return true if valid, otherwise, false.

        vm.validateForm = function () {
            let form = $scope.insurerTeamCreateEditPropertiesForm;
            App.touchFormErrors(form);
            return form.$valid;
        };


        // Generate the payload from this form to send to InsurerTeamsAppService API.

        vm.getPayload = function () {
            return vm.data;
        };

        // Ask container to save this widget.

        vm.save = function () {
            vm.container.saveWidget(vm);
        };

        init();
    }

    app.component(componentId, {
        require: {
            container: '^hostInsurerTeamCreateEditContainer'
        },
        bindings: {
            data: '<',
            isCreate: '<'
        },
        templateUrl: require('/App/host/views/insurers/insurerTeams/createEdit/properties/properties.component.html'),
        controller: ['$scope', 'abp.services.app.insurerTeam', 'Hms.Insurance.InsurerTeam', controller],
        controllerAs: 'vm'
    });
})();
