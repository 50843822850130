(() => {
  angular.module('app').component('commonInpatientTicketsDetailFinalDiagnosisComponent', {
    templateUrl: require('./finalDiagnosis.component.html'),
    controller: FinalDiagnosisController,
    controllerAs: 'vm',
    bindings: {
      ticket: '<',
      diseaseClassifications: '<',
      isEdit: '<',
      isSaved: '<',
      admissionDate: '@',
      dischargeDate: '@',
    },
  });

  FinalDiagnosisController.$inject = ['$scope'];

  function FinalDiagnosisController($scope) {
    const vm = this;

    const limitSize = 30;
    vm.filteredIcds = [];

    vm.$onChanges = onChanges;
    vm.addDiagnosis = addDiagnosis;
    vm.clearDiagnosis = clearDiagnosis;
    vm.lookupIcd = lookupIcd;
    vm.deleteItem = deleteItem;
    vm.updateDiagnosis = updateDiagnosis;

    init();

    function init() {
      $scope.$watch('vm.ticket.dischargeDate', () => {
        updateDates();
      });

      $scope.$watch('vm.ticket.admissionDate', () => {
        updateDates();
      });

      if (!vm.ticket.diagnoses) {
        vm.ticket.diagnoses = [];
      }
    }

    function onChanges(changes) {
      if (changes.isSaved && changes.isSaved.currentValue) {
        tidyDiagnosis();
      }

      if (changes.isEdit && changes.isEdit.currentValue) {
        if (vm.ticket.diagnoses.length < 1) {
          addDiagnosis();
        }
      }

      if (changes.ticket && changes.ticket.currentValue) {
        _.each(vm.ticket.diagnoses, (diagnosis) => {
          initDiagnosis(diagnosis);
        });
      }

      updateDates();
    }

    function addDiagnosis() {
      vm.ticket.diagnoses.push({});
    }

    function clearDiagnosis() {
      vm.ticket.diagnoses = [{}];
    }

    function initDiagnosis(diagnosis) {
      const d = diagnosis;
      if (d.description && _.isNil(d.code)) {
        d.code = generateId();
      } else {
        const selectedDiagnosis = _.find(vm.diseaseClassifications, { code: d.code });
        if (selectedDiagnosis) {
          d.code = selectedDiagnosis.code;
          d.description = selectedDiagnosis.description;
        }
      }
    }

    function updateDiagnosis(diagnosis) {
      const d = diagnosis;
      const customDiagnosis = _.find(vm.filteredIcds, { code: d.code });
      if (customDiagnosis) {
        if (d.code < 0) {
          d.code = generateId();
        } else {
          d.code = customDiagnosis.code;
        }
        d.id = null;
        d.description = customDiagnosis.description;
      }
      getDiagnoses();
    }

    function generateId() {
      // Set custom diagnosis id to negative number of current time.
      // To resolve ng-change where unable to change custom diagnosis,
      // due to diagnosis listing set to -1 for all custom diagnosis

      return -Math.abs(new Date().getTime());
    }

    function tidyDiagnosis() {
      vm.ticket.diagnoses = _.filter(vm.ticket.diagnoses, (item) => item.code !== undefined);
    }

    function lookupIcd(filter) {
      const keyword = _.trim(filter || '');
      if (keyword) {
        // Include free-text entry in filtered ICD set.

        const filteredIcds = [
          {
            item: {
              description: keyword,

              // Default to -1 because free-text entry has no corresponding
              // disease classification ID.
              code: -1,
            },

            // Set free-text entry with high negative score so that it stays as first choice.
            score: -100,
          },
        ];

        const regexes = _.chain(keyword.split(' '))
          .map((k) => _.trim(k.toUpperCase()))
          .filter((k) => k)
          .uniq()
          .map((k) => {
            _.escapeRegExp(k);
            return {
              token: k,
              len: k.length,
              pattern: new RegExp(`\\b${k}`, 'i'),
            };
          })
          .value();

        _.forEach(vm.diseaseClassifications, (icd) => {
          let score = 0;

          // Exclude selected icd.

          const isSelected = _.find(vm.ticket.diagnoses, (s) => s.code === icd.code);

          if (!isSelected) {
            _.forEach(regexes, (r) => {
              if (r.pattern.test(icd.description)) {
                score -= r.len;
              }
            });
          }

          if (score) {
            filteredIcds.push({
              score,
              item: icd,
            });
          }
        });

        vm.filteredIcds = _.chain(filteredIcds)
          .sortBy(['score'])
          .take(30)
          .map((k) => k.item)
          .uniqBy((s) => s.code)
          .value();
      } else {
        getDiagnoses();
      }
    }

    function deleteItem(index) {
      if (vm.ticket.diagnoses.length > 1) {
        vm.ticket.diagnoses.splice(index, 1);
        getDiagnoses();
      } else {
        vm.ticket.diagnoses = [];
        addDiagnosis();
      }
    }

    function getDiagnoses() {
      let tempIcd = [];
      _.forEach(vm.diseaseClassifications, (diagnosis) => {
        const selectedDiagnosis = _.find(vm.ticket.diagnoses, { code: diagnosis.code });

        if (!selectedDiagnosis) {
          tempIcd.push(diagnosis);
        }
      });

      tempIcd = _.sortBy(tempIcd, (x) => x.code);
      vm.filteredIcds = _.take(tempIcd, limitSize);
    }

    function updateDates() {
      _.forEach(vm.ticket.diagnoses, (diagnosis) => {
        const d = diagnosis;
        if (
          d.diagnosisDate < vm.ticket.admissionDate ||
          d.diagnosisDate > vm.ticket.dischargeDate
        ) {
          d.diagnosisDate = null;
        }
      });
    }
  }
})();
