// TODO: Remove this temporary functionalities for HM-2902 (Rework on reimbursement ticket)
import swal from 'sweetalert';
import PromptQueue from '../../../../../../lib/prompt-queue';

(() => {
  angular.module('app').component('commonClaimsProcessClaimDetailNewWidget', {
    templateUrl: require('./widget.html'),
    controller: ClaimDetailWidgetController,
    controllerAs: 'vm',
    bindings: {
      receiptDate: '=',
    },
  });

  ClaimDetailWidgetController.$inject = [
    '$state',
    '$stateParams',
    '$uibModal',
    '$timeout',
    '$filter',
    'abp.services.app.patientTicket',
    'Hms.MultiTenancy.TenantClinicType',
    'Hms.PatientTickets.PatientTicketItem',
    'moment',
  ];

  function ClaimDetailWidgetController(
    $state,
    $stateParams,
    $uibModal,
    $timeout,
    $filter,
    patientTicketSvc,
    enumTenantClinicType,
    constsPatientTicketItem,
    moment
  ) {
    const vm = this;

    vm.constsPatientTicketItem = constsPatientTicketItem;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.defaultMaxAllowance = parseFloat(abp.setting.get('Hms.General.MaxAllowanceAmountLimit'));
    vm.claimNumber = $stateParams.claimNumber;
    vm.isHost = App.isHost();
    vm.isCorporate = App.isCorporate();
    vm.loading = 0;
    vm.saving = 0;
    vm.claimReceiptAmount = 0;
    vm.isOutpatientTicket = false;
    vm.ticketDate = moment();
    vm.maxTicketDate = moment().startOf('day');
    vm.treatments = [];
    vm.patient = null;
    vm.clinic = {};
    vm.report = null;
    vm.claim = null;
    vm.ticket = { treatments: [] };
    vm.processRemarks = '';
    vm.isCtTicket = false;
    vm.isInpatient = false;
    vm.treatmentList = [];

    vm.enums = {
      tenantClinicType: enumTenantClinicType,
    };

    vm.permissions = {
      manage: abp.auth.isGranted('Host.Claims.Manage'),
      process:
        abp.auth.isGranted('Host.Claims.Manage') &&
        abp.auth.isGranted('PatientTickets.Reimbursement.Create'),
      viewTicket: abp.auth.isGranted('PatientTickets'),
    };

    vm.uploadConfig = {
      objectType: 'TicketAttachment',
    };

    vm.setTicketDate = setTicketDate;
    vm.resetClaimType = resetClaimType;
    vm.treatmentHasValue = treatmentHasValue;
    vm.treatmentMissed = treatmentMissed;
    vm.checkRemarkRequired = checkRemarkRequired;
    vm.getRemarkPlaceholder = getRemarkPlaceholder;
    vm.addTreatments = addTreatments;
    vm.clearTreatments = clearTreatments;
    vm.removeTreatment = removeTreatment;
    vm.checkDuplicateTreatment = checkDuplicateTreatment;
    vm.updateTreatment = updateTreatment;
    vm.refreshTreatmentAndMcRemarks = refreshTreatmentAndMcRemarks;
    vm.hasUncoveredTreatments = hasUncoveredTreatments;
    vm.getAllowanceDisplay = getAllowanceDisplay;
    vm.getTreatmentTotalAmount = getTreatmentTotalAmount;
    vm.calculateAmountCoverage = calculateAmountCoverage;
    vm.getCopayAmount = getCopayAmount;
    vm.getManualPaymentAmount = getManualPaymentAmount;
    vm.getCoveredAmount = getCoveredAmount;
    vm.areFieldsValid = areFieldsValid;
    vm.getTicketTotalAmount = getTicketTotalAmount;
    vm.isTotalBillAmountValid = isTotalBillAmountValid;
    vm.needMc = needMc;
    vm.issueMc = issueMc;
    vm.removeMc = removeMc;
    vm.setMcStartDate = setMcStartDate;
    vm.getMcDurationDescription = getMcDurationDescription;
    vm.getMcStartDateChoice = getMcStartDateChoice;
    vm.save = save;

    init();

    function init() {
      vm.loading += 1;

      patientTicketSvc
        .processClaim({
          id: $stateParams.claimNumber,
        })
        .success((data) => {
          vm.doctors = data.doctors || [];
          vm.banks = data.banks || [];
          vm.patient = data.patient;
          vm.clinic = data.clinic || {};
          vm.treatments = data.treatments || [];
          vm.claim = data.claim;
          vm.ticket = data.ticket;
          vm.tenantBalance = data.corporateBalance;

          vm.employeeId = data.patient.id;
          vm.isCreate = data.patient.id;
          vm.claimId = data.claim.id;

          if (!vm.isCreate) {
            vm.ticketNumber = data.ticket.ticketNumber;
            vm.employeeId = data.patient.id;
          }

          vm.claim.claimDate = moment.utc(data.claim.claimDate);

          vm.ticketDate = moment.utc(data.ticket.ticketDate);
          vm.receiptDate = vm.ticketDate;

          vm.ticket = data.ticket;
          vm.report = data.ticket.report;

          vm.attachments = vm.ticket.attachments;
          vm.newAttachments = [];

          if (vm.claim) {
            vm.claimReceiptAmount = vm.claim.receiptAmount;

            _.forEach(vm.claim.attachments, (a) => {
              const attachment = a;
              attachment.isImage = attachment.contentType.startsWith('image/');
            });
          }

          if (vm.ticket.medicalCertificate) {
            vm.ticket.medicalCertificate.startDate = 
              moment.utc(vm.ticket.medicalCertificate.startDate);
            vm.ticket.medicalCertificate.endDate = moment.utc(vm.ticket.medicalCertificate.endDate);
            vm.ticket.checkedInDate = moment.utc(vm.ticket.checkedInDate);
            vm.isMcStartDateAlsoCheckedInDate =
              vm.ticket.medicalCertificate.startDate.diff(
                vm.ticket.checkedInDate.startOf('days'),
                'days'
              ) === 0;
            if (vm.isCreate && vm.claim) {
              vm.ticket.medicalCertificate.remark = vm.claim.treatmentRemarks || 'n/a';
            }
          }
          const strAmt = $filter('currencyFormat')(vm.ticket.coveredAmount, vm.currencyCode);
          vm.reviseCoveredAmountUpwardErrorMessage = App.localize(
            'ReviseCoveredAmountUpwardErrorMessage',
            strAmt
          );

          vm.resetClaimType();

          // HACK: HM-3416 Get by service type in future.

          vm.isOutpatientTicket =
            vm.ticket.clinicType === 5 ||
            (vm.ticket.clinicType === 7 && vm.ticket.serviceType === 5);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function setTicketDate() {
      if (vm.isCreate) {
        refreshAllowance();
        vm.receiptDate = vm.ticketDate;
      }

      if (vm.isCreate && vm.ticket.medicalCertificate) {
        vm.ticket.medicalCertificate.startDate = moment(vm.ticketDate).startOf('day');
        vm.ticket.medicalCertificate.endDate = moment(vm.ticketDate).startOf('day');
      }
    }

    /* Treatment Management */

    function resetClaimType() {
      // HACK: HM-3510 Get by service type in future.

      vm.isOutpatientTicket =
        vm.ticket.clinicType === 5 || (vm.ticket.clinicType === 7 && vm.ticket.serviceType === 5);
      vm.isCtTicket = vm.ticket.clinicType >= 200 && vm.ticket.clinicType <= 209;
      vm.isInpatient = vm.ticket.clinicType === 251;
      vm.treatmentList =
        !vm.isCtTicket && !vm.isInpatient
          ? _.filter(vm.treatments, (d) => d.type === vm.ticket.clinicType)
          : null;

      vm.clearTreatments();

      if (vm.treatmentList && vm.isOutpatientTicket) {
        vm.ticket.treatments[0].treatment = _.find(
          vm.treatmentList,
          (d) => d.specialistDisciplineId === vm.claim.specialistDisciplineId
        );
      }

      // HACK HM-4740: Remove this converter and use service type instead to get allowance.

      if (vm.ticket.clinicType === 4) {
        vm.ticket.serviceType = 3;
      } else {
        vm.ticket.serviceType = vm.ticket.clinicType;
      }

      refreshAllowance();
    }

    function treatmentHasValue(treatment) {
      return treatment.treatment || hasRemark(treatment) || treatment.amount;
    }

    function treatmentMissed(treatment) {
      return !treatment.treatment && (hasRemark(treatment) || treatment.amount);
    }

    function checkRemarkRequired(treatment) {
      return (
        vm.treatmentHasValue(treatment) &&
        ((treatment.treatment &&
          treatment.treatment.requiresRemark &&
          !treatment.treatment.notCovered) ||
          vm.isCtTicket ||
          vm.isInpatient)
      );
    }

    function getRemarkPlaceholder(treatment) {
      if (!treatment.treatment) return '';
      if (vm.ticket.clinicType === 1) {
        return _.toUpper(_.trim(treatment.treatment.displayName || '')) ===
          _.toUpper('Consultation')
          ? App.localize('ConsultationRemarksNarrative')
          : App.localize('TreatmentRemarksNarrative');
      }
      if (vm.checkRemarkRequired(treatment)) return App.localize('PleaseExplain');
      return '';
    }

    function hasTreatments() {
      return _.some(vm.ticket.treatments, (t) => vm.treatmentHasValue(t));
    }

    function refreshTicketTreatments() {
      // For CT ticket, auto-assign treatment if other fields are filled.
      // And unassign if other fields are empty.
      // This adjustment is for amount calculation to be able to handle custom treatment normally.

      if (vm.isCtTicket || vm.isInpatient) {
        const defaultTreatment = _.find(vm.treatments, (t) => t.type === vm.ticket.clinicType);
        _.each(vm.ticket.treatments, (t) => {
          const treatment = t;

          if (hasRemark(t) || t.amount) {
            treatment.treatment = defaultTreatment;
            treatment.treatmentId = treatment.treatment.id;
          } else {
            treatment.treatment = null;
            treatment.treatmentId = null;
          }
        });
      }

      let hasBlank = _.some(vm.ticket.treatments, (t) => !vm.treatmentHasValue(t));

      while (!hasBlank || vm.ticket.treatments.length < 2) {
        vm.ticket.treatments.push({});
        hasBlank = true;
      }
    }

    function addTreatments() {
      for (let i = 0; i < 4; i += 1) {
        vm.ticket.treatments.push({});
      }
    }

    function clearTreatments() {
      vm.ticket.treatments = [{}];

      // Push the default claim message and remarks.

      const refTreatment = vm.ticket.treatments[0];
      refTreatment.remark = vm.claim.treatmentRemarks;
      if (refTreatment.remark && !vm.isOutpatientTicket) {
        refTreatment.remarkTags = _.chain(_.split(vm.claim.treatmentRemarks, ','))
          .map((data) => _.trim(data || ''))
          .filter((data) => data.length > 0)
          .uniqBy((data) => data.toUpperCase())
          .value();
      }
      refTreatment.amount = vm.claim.receiptAmount;

      vm.refreshTreatmentAndMcRemarks();
    }

    function removeTreatment(index) {
      if (index < vm.ticket.treatments.length) {
        vm.ticket.treatments.splice(index, 1);
        vm.refreshTreatmentAndMcRemarks();
      }
    }

    function checkDuplicateTreatment(treatment) {
      if (treatment.isDuplicable) return false;

      return _.some(vm.ticket.treatments, (t) => t.treatmentId === treatment.id);
    }

    function updateTreatment(treatment) {
      const t = treatment;

      if (t.treatment) {
        if (vm.checkDuplicateTreatment(t.treatment)) {
          abp.message.error(App.localize('DuplicateTreatmentValidationErrorMessage'));
          t.treatmentId = null;
          t.treatment = null;
          t.amount = null;
          return;
        }

        t.treatmentId = t.treatment.id;
        if (t.treatment.defaultRate) {
          $timeout(() => {
            t.amount = vm.claim.receiptAmount;
          }, 50);
        }

        if (t.treatment.notCovered) {
          t.amount = 0;
          t.remark = '';
        }
      } else {
        t.treatmentId = null;
      }
      vm.refreshTreatmentAndMcRemarks();
    }

    function refreshTreatmentAndMcRemarks() {
      refreshTicketTreatments();
      updateMcRemarks();
    }

    function hasUncoveredTreatments() {
      return _.some(
        vm.ticket.treatments,
        (treatment) => treatment.treatment && treatment.treatment.notCovered
      );
    }

    /* Treatment Remark functions */

    function getRemark(t) {
      return t.remarkTags
        ? _.join(
            _.map(t.remarkTags, (d) => d.text),
            ', '
          )
        : '';
    }

    function hasRemark(treatment) {
      if (vm.isOutpatientTicket) {
        return treatment.remark && treatment.remark.length;
      }
      return treatment.remarkTags && treatment.remarkTags.length;
    }

    /* End of Treatment Remark functions */

    /* End of Treatment Management */

    /* Amounts Calculation */

    function getAllowanceDisplay() {
      return Math.min(vm.patient.allowance, vm.defaultMaxAllowance);
    }

    function refreshAllowance() {
      // Refresh patient's allowance. Only for NPR & Manual tickets.
      vm.loading += 1;
      patientTicketSvc
        .getAllowance({
          employeeId: vm.employeeId,
          panelLocationId: vm.clinic ? vm.clinic.clinicLocationId : null,
          clinicType: vm.ticket.clinicType,
          ticketType: vm.ticket.ticketType,
          ticketDate: vm.ticketDate,
          serviceType: vm.ticket.serviceType,
        })
        .success((data) => {
          vm.patient.allowance = data.employeeAllowance;
          vm.tenantBalance = data.tenantBalance;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getTreatmentTotalAmount() {
      let amount = 0;
      _.each(vm.ticket.treatments, (t) => {
        if (t.treatment && (t.amount || t.uncoveredAmount)) {
          amount += t.amount || 0;
          amount += t.uncoveredAmount || 0;
        }
      });
      return amount;
    }

    function calculateAmountCoverage() {
      let claimable = 0;
      let uncovered = 0;
      let coPay = 0;
      _.each(vm.ticket.treatments, (t) => {
        if (t.treatment) {
          if (!t.treatment.notCovered) {
            claimable += t.amount || 0;

            if (t.uncoveredAmount) {
              uncovered += t.uncoveredAmount || 0;
            }
          } else {
            uncovered += t.amount || 0;
            uncovered += t.uncoveredAmount || 0;
          }
        }
      });

      if (vm.isCreate) {
        if (vm.patient && vm.ticket && claimable) {
          if (vm.patient.coPay) {
            coPay = vm.patient.coPay.value;
            if (vm.patient.coPay.isPercentage) {
              coPay *= claimable / 100;
            }
            if (coPay < 0) {
              coPay = 0;
            } else if (coPay > claimable) {
              coPay = claimable;
            }
            claimable -= coPay;
            uncovered += coPay;
          }

          // Check against allowance

          if (claimable > vm.patient.allowance) {
            claimable = vm.patient.allowance;
          }
        }
      } else {
        claimable -= vm.ticket ? vm.ticket.manualPayment : 0;
      }

      const total = vm.claimReceiptAmount;
      let manualPayment = 0;

      // If specialist ticket, use total specialist uncovered amount instead.

      if (vm.ticket && vm.isOutpatientTicket) {
        manualPayment = uncovered;
      } else {
        manualPayment = Math.max(total - claimable, 0);
      }

      return {
        claimable: App.roundAmount(claimable),
        manualPayment: App.roundAmount(manualPayment),
        copay: App.roundAmount(coPay),
      };
    }

    function getCopayAmount() {
      return vm.calculateAmountCoverage().copay;
    }

    function getManualPaymentAmount() {
      return vm.calculateAmountCoverage().manualPayment;
    }

    function getCoveredAmount() {
      return vm.calculateAmountCoverage().claimable;
    }

    function areFieldsValid() {
      let isValid = true;
      _.forEach(vm.ticket.treatments, (t) => {
        if (
          t.treatment &&
          (typeof t.amount === 'undefined' ||
            t.amount === null ||
            typeof t.uncoveredAmount === 'undefined' ||
            t.uncoveredAmount === null)
        ) {
          isValid = false;
        }
      });
      return isValid;
    }

    function getTicketTotalAmount() {
      if (!vm.isOutpatientTicket) {
        return vm.getCoveredAmount() + vm.getManualPaymentAmount();
      }
      return vm.getTreatmentTotalAmount();
    }

    function isTotalBillAmountValid() {
      return (
        vm.claim &&
        vm.getTicketTotalAmount() === vm.claimReceiptAmount &&
        ((!vm.isOutpatientTicket && vm.getTreatmentTotalAmount() <= vm.claimReceiptAmount) ||
          (vm.isOutpatientTicket && vm.getTreatmentTotalAmount() === vm.claimReceiptAmount))
      );
    }

    /* End of Amounts Calculation */

    /* Medical Certificate Management */

    function needMc() {
      return (
        vm.ticket &&
        (vm.ticket.clinicType === 1 ||
          vm.ticket.clinicType === 2 ||
          vm.isOutpatientTicket ||
          vm.isCtTicket ||
          vm.isInpatient)
      );
    }

    function updateMcRemarks() {
      if (vm.ticket.medicalCertificate && !vm.isCtTicket && !vm.claimId) {
        const remark = _.chain(vm.ticket.treatments)
          .filter((t) => t.treatment && t.treatment.displayName)
          .map((t) => t.treatment.displayName)
          .join(', ')
          .value();
        vm.ticket.medicalCertificate.remark = remark;
      }
    }

    function issueMc() {
      const firstDate = moment(vm.ticketDate).startOf('day');
      vm.ticket.medicalCertificate = {
        startDate: firstDate,
        endDate: firstDate,
      };
      vm.isMcStartDateAlsoCheckedInDate = 1;
      updateMcRemarks();
    }

    function removeMc() {
      vm.ticket.medicalCertificate = null;
    }

    function setMcStartDate(offset) {
      const checkInDate = moment(vm.ticket.checkedInDate).startOf('day');
      vm.ticket.medicalCertificate.startDate = checkInDate.add(offset, 'days');

      const mcEndDate = vm.ticket.medicalCertificate.endDate.startOf('day');
      if (mcEndDate.diff(vm.ticket.medicalCertificate.startDate, 'days') < 0) {
        vm.ticket.medicalCertificate.endDate = moment(vm.ticket.medicalCertificate.startDate);
      }
      vm.isMcStartDateAlsoCheckedInDate = offset === 0;
    }

    function getMcDurationDescription() {
      if (vm.ticket.medicalCertificate) {
        const startDate = moment(vm.ticket.medicalCertificate.startDate);
        const endDate = moment(vm.ticket.medicalCertificate.endDate);
        const duration = endDate.diff(startDate, 'days') + 1;
        return App.localize('XDays', duration);
      }
      return '';
    }

    function getMcStartDateChoice(offset) {
      return moment(vm.ticket.checkedInDate).add(offset, 'days').startOf('day');
    }

    /* End of Medical Certificate Management */

    function createOrEditTicket() {
      let canSubmit = true;
      const prompts = new PromptQueue();

      vm.clinic = {};
      vm.ticket.doctorId = null;

      if (!hasTreatments()) {
        abp.message.error(App.localize('EmptyTreatmentValidationErrorMessage'));
        return;
      }

      if (vm.isCreate) {
        if (
          !vm.isCtTicket &&
          !vm.isInpatient &&
          _.find(vm.ticket.treatments, (t) => vm.treatmentHasValue(t) && !t.treatmentId)
        ) {
          abp.message.error(App.localize('OneOrMoreInputValueIsInvalid'));
          return;
        }
      }

      let cleanTreatments = true;
      _.each(vm.ticket.treatments, (t) => {
        const treatment = t;
        if (vm.treatmentHasValue(treatment)) {
          if (!vm.isOutpatientTicket) treatment.remark = getRemark(treatment);
          if (treatment.amount < 0) {
            abp.message.error(App.localize('InvalidTreatmentRateAmount'));
            cleanTreatments = false;
            return false;
          }
          if (vm.checkRemarkRequired(treatment) && !treatment.remark) {
            abp.message.error(App.localize('MissingTreatmentRemark'));
            cleanTreatments = false;
            return false;
          }
        }

        return true;
      });

      if (!cleanTreatments) return;

      if (vm.ticketDate > moment()) {
        abp.message.error(App.localize('TicketDateIsInFuture'));
        return;
      }

      if (vm.getTicketTotalAmount() > vm.claimReceiptAmount) {
        abp.message.error(App.localize('TicketAmountMoreThanClaimReceiptAmount'));
        return;
      }

      if (vm.ticket.medicalCertificate) {
        const startDate = moment(vm.ticket.medicalCertificate.startDate);

        const mcDays = Math.ceil(vm.ticket.medicalCertificate.effectiveMcDays);
        if (Number.isNaN(Number(mcDays)) || mcDays < 0) {
          abp.message.error(App.localize('InvalidEffectiveMcDays'));
          return;
        }
        vm.ticket.medicalCertificate.endDate = moment(startDate).add(mcDays - 1, 'd');

        const mcStartDayOffset = startDate.diff(moment(vm.ticketDate).startOf('day'), 'days');
        if (mcStartDayOffset < 0) {
          abp.message.error(App.localize('McStartDateEarlierThanTicketDate'));
          return;
        }
        if (mcStartDayOffset > 1) {
          prompts.add(
            swal,
            {
              title: App.localize('AreYouSure'),
              text: App.localize('McStartDateTooFarFromTicketDateWarning'),
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#1ab394',
              confirmButtonText: App.localize('Yes'),
              cancelButtonText: App.localize('No'),
              closeOnConfirm: true,
              closeOnCancel: true,
            },
            (isConfirm) => {
              canSubmit = isConfirm;
            }
          );
        }
      }

      if (vm.claim && vm.getTicketTotalAmount() !== vm.claimReceiptAmount) {
        prompts.add(
          swal,
          {
            title: App.localize('AreYouSure'),
            text: App.localize('ClaimReceiptAmountMismatchWithTicketAmountWarning'),
            type: 'input',
            showCancelButton: true,
            confirmButtonColor: '#1ab394',
            confirmButtonText: App.localize('Submit'),
            cancelButtonText: App.localize('Cancel'),
            closeOnConfirm: true,
            closeOnCancel: true,
            inputPlaceholder: App.localize('PleaseExplain'),
          },
          (inputValue) => {
            if (inputValue === false) {
              refreshTicketTreatments();
              canSubmit = false;
            }

            if ($.trim(inputValue || '') === '') {
              swal.showInputError(App.localize('InvalidInput'));
              canSubmit = false;
            } else if (inputValue) {
              vm.processRemarks = inputValue;
            }
          }
        );
      }

      // Filter away attachments marked for deletion.

      const attachments = [];
      _.each(vm.attachments, (attachment) => {
        if (!attachment.isDelete) attachments.push(attachment.id);
      });

      // Construct input request.

      refreshTicketTreatments();

      const input = {
        doctorId: vm.ticket.doctorId,
        // Discard treatment without id or without remark if it is CT ticket.
        treatments: _.filter(vm.ticket.treatments, (treatment) => treatment.treatmentId > 0),
        manualPayment: vm.getManualPaymentAmount(),
        clinicLocationId: vm.clinic.clinicLocationId,
        medicalCertificate: vm.ticket.medicalCertificate,
        bankingDetails: vm.ticket.bankingDetails,
        manualClinicName: vm.ticket.manualClinicName,
        manualClinicLocation: vm.ticket.manualClinicLocation,
        manualDoctorName: vm.ticket.manualDoctorName,
        clinicType: vm.ticket.clinicType,
        ticketDate: vm.ticketDate,
        ticketType: vm.ticket.ticketType,
        attachments,
        newAttachments: vm.newAttachments,
        report: vm.report,
      };
      if (!vm.needMc()) {
        input.medicalCertificate = null;
      }

      function createTicket() {
        if (vm.saving !== 0) return;

        vm.saving += 1;
        patientTicketSvc
          .createTicketNew(
            $.extend(input, {
              employeeId: vm.employeeId,
              claimId: vm.claimId,
              claimReceiptAmount: vm.claimReceiptAmount,
              claimRemarks: vm.processRemarks,
            })
          )
          .success((data) => {
            vm.isUpdated = true;
            abp.notify.info(App.localize('TicketXIssued', data.ticketNumber));

            showSuccessModal(
              data.ticketNumber,
              data.ticketType,
              vm.ticket.medicalCertificate !== null,
              'claimListDetail',
              {
                claimNumber: data.nextClaimNumber,
                employeeId: vm.employeeId,
              }
            );
          })
          .finally(() => {
            vm.saving -= 1;
          });
      }

      function editTicket(remarks) {
        if (vm.saving !== 0) return;

        vm.saving += 1;
        patientTicketSvc
          .editTicket(
            $.extend(input, {
              ticketNumber: vm.ticketNumber,
              modificationRemarks: remarks,
            })
          )
          .success(() => {
            vm.isUpdated = true;
            abp.notify.info(App.localize('SuccessfullySaved'));
            showSuccessModal(
              vm.ticketNumber,
              vm.ticket.ticketType,
              vm.ticket.medicalCertificate !== null,
              'patientTickets',
              null
            );
            swal.close();
          })
          .finally(() => {
            vm.saving -= 1;
          });
      }

      if (vm.isCreate) {
        vm.saving += 1;
        prompts.run(() => {
          vm.saving -= 1;
          if (canSubmit) createTicket();
        });
      } else {
        prompts.add(
          swal,
          {
            title: App.localize('EditPatientTicket'),
            text: App.localize('WhyEditPatientTicket'),
            type: 'input',
            showCancelButton: true,
            closeOnConfirm: true,
            confirmButtonColor: '#1ab394',
            inputPlaceholder: App.localize('PleaseExplain'),
          },
          (inputValue) => {
            vm.saving -= 1;
            vm.saving += 1;
            if (inputValue === false) {
              vm.saving -= 1;
              return false;
            }
            if ($.trim(inputValue || '') === '') {
              swal.showInputError(App.localize('PleaseExplain'));
              return false;
            }

            vm.saving -= 1;
            editTicket($.trim(inputValue));
            return true;
          }
        );

        vm.saving += 1;
        prompts.run();
      }
    }

    function save() {
      createOrEditTicket();
    }

    function showSuccessModal(ticketNumber, ticketType, hasMc, nextState, nextStateData) {
      let callbackData = null;
      const obj = {
        ticketNumber,
        ticketType,
        hasMc,
        callback(data) {
          callbackData = data;
        },
      };

      $uibModal
        .open({
          templateUrl: require('../../../patientTickets/createEditSuccessModal.html'),
          controller: 'common.views.patientTickets.createEditSuccessModal as vm',
          backdrop: 'static',
          size: 'sm',
          resolve: {
            ticket() {
              return obj;
            },
          },
        })
        .result.then(() => {
          if (callbackData) {
            $state.go(callbackData.stateName, callbackData.stateData);
          } else {
            $state.go(nextState, nextStateData);
          }
        });
    }
  }
})();
