(function () {
    var componentId = 'revenueSubscriptionsDetailComments';
    var module = angular.module('revenue');

    function controller($timeout, corporateCommentSvc) {
        function refreshFilteredComments() {
            vm.filteredComments = vm.viewAll ? vm.comments : _.takeRight(vm.comments, 3);
        }

        function mapValue(comment) {
            comment.typeInfo = {
                title: comment.type,
                iconClass: 'far fa-book',
            };

            if (comment.type === 'UserComment') {
                comment.typeInfo.title = '';
                comment.typeInfo.iconClass = 'far fa-comments bg-info';
            } else if (comment.type === 'Subscription.Deleted') {
                comment.typeInfo.iconClass = 'far fa-trash bg-danger';
            }

            return comment;
        }

        function loadComments() {
            if (vm.subscription) {
                vm.loading++;
                corporateCommentSvc.getComments({
                    corporateId: vm.subscription.corporateId,
                    subscriptionOldId: vm.subscription.id
                }).success(function (data) {
                    vm.comments = _.map(data.items, mapValue);
                    refreshFilteredComments();
                }).finally(function () {
                    vm.loading--;
                });
            } else {
                vm.comments = [];
                refreshFilteredComments();
            }
        }

        function enableAddComment() {
            vm.addCommentInput = {
                corporateId: vm.subscription.corporateId,
                subscriptionOldId: vm.subscription.id,
                content: ''
            };
            $timeout(function () {
                document.getElementById('txtNewComment').focus();
            }, 10);
        }

        function addComment() {
            vm.addCommentInput.content = (vm.addCommentInput.content || '').trim();
            if (!vm.addCommentInput.content) return;

            if (vm.saving) return;
            vm.saving = true;
            corporateCommentSvc.addComment(vm.addCommentInput)
                .success(function (data) {
                    vm.comments.push(mapValue(data));
                    refreshFilteredComments();
                }).finally(function () {
                    vm.saving = false;
                    vm.addCommentInput = null;
                });
        }

        function deleteComment(comment) {
            if (comment.type !== 'UserComment') return;
            if (vm.saving) return;

            abp.message.confirm(App.localize('ConfirmDeleteCommentWarning'), App.localize('AreYouSure'), function (d) {
                if (d && !vm.saving) {
                    vm.saving = true;
                    corporateCommentSvc.deleteComment({ id: comment.id }).success(function () {
                        const idx = vm.comments.indexOf(comment);
                        vm.comments.splice(idx, 1);
                        refreshFilteredComments();
                    }).finally(function () {
                        abp.notify.success(App.localize("SuccessfullyDeleted"));
                        vm.saving = false;
                    });
                }
            });
        }

        function toggleViewAll() {
            vm.viewAll = !vm.viewAll;
            refreshFilteredComments();
        }

        const vm = this;
        vm.loading = 0;
        vm.viewAll = false;
        vm.saving = false;
        vm.isAddingComment = false;
        vm.comments = [];
        vm.filteredComments = [];
        vm.addCommentInput = null;

        vm.$onChanges = function (changesObj) {
            if (changesObj.subscription) {
                loadComments();
            }
        };

        vm.enableAddComment = enableAddComment;
        vm.addComment = addComment;
        vm.deleteComment = deleteComment;
        vm.toggleViewAll = toggleViewAll;
    }

    module.component(componentId, {
        require: { container: '^revenueSubscriptionsDetailContainer' },
        bindings: { subscription: '<' },
        templateUrl: require('/App/modules/revenue/views/subscriptions/detail/comments/widget.html'),
        controller: ['$timeout', 'abp.services.revenue.corporateComment', controller],
        controllerAs: 'vm'
    });
})();
