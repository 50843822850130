(function () {
    var controllerId = 'host.views.claimSubmissionTemplate.viewModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'abp.services.app.insuranceClaimSubmission', 'templateId', 'insurerName',
        function ($scope, $uibModalInstance, insuranceClaimSubmissionSvc, templateId, insurerName) {
            var vm = this;

            function init() {
                // Fetch all master claim submission codes.

                vm.loading++;
                insuranceClaimSubmissionSvc.getMasterClaimSubmissionCodes().success(function (data) {
                    vm.masterClaimSubmissionCodes = data;
                }).finally(function () {
                    vm.loading--;
                });

                // Fetch the insurance claim submission template.

                vm.loading++;
                insuranceClaimSubmissionSvc.getInsuranceClaimSubmissionTemplate({ id: vm.templateId }).success(function (data) {
                    vm.insuranceClaimSubmissionCodes = data.insuranceClaimSubmissionCodes;
                }).finally(function () {
                    vm.loading--;
                });
            }

            vm.loading = 0;
            vm.templateId = templateId;
            vm.insurerName = insurerName;

            // #region to map master claim submission code.

            // Get the master claim submission code by master code Id.

            vm.mapMasterClaimSubmissionCode = function (masterCodeId) {
                return _.find(vm.masterClaimSubmissionCodes, function (code) {
                    return code.id == masterCodeId;
                });
            }

            // Get the table (label) of the master claim submission code by master code Id.

            vm.getMasterCodeTableName = function (masterCodeId) {
                var masterCode = vm.mapMasterClaimSubmissionCode(masterCodeId);
                return masterCode ? masterCode.tableName : "";
            }

            // Get the display value of the master claim submission code by master code Id.

            vm.getMasterCodeDisplayValue = function (masterCodeId) {
                var masterCode = vm.mapMasterClaimSubmissionCode(masterCodeId);
                return masterCode ? masterCode.displayValue : "";
            }

            // Get the definition of the master claim submission code by master code Id.

            vm.getMasterCodeDefinition = function (masterCodeId) {
                var masterCode = vm.mapMasterClaimSubmissionCode(masterCodeId);
                return masterCode ? masterCode.definition : "";
            }

            // #endregion to map master claim submission code.

            vm.back = function () {
                $uibModalInstance.dismiss();
            };

            init();
        }
    ]);
})();
