(() => {
  angular.module('app').component('preEmploymentReportV0', {
    templateUrl: require('./preEmploymentReportV0.component.html'),
    controller: PreEmploymentReportV0Controller,
    controllerAs: 'vm',
    bindings: {
      report: '=',
    },
  });

  PreEmploymentReportV0Controller.$inject = [];

  function PreEmploymentReportV0Controller() {
    // const vm = this;
  }
})();
