(function () {
    angular.module('app').component('hostViewsOperationsDashboardInsuranceClaimsDetailsTimelineComponent', {
        templateUrl: require('/App/host/views/operationsDashboard/insuranceClaims/timeline/timeline.component.html'),
        controller: ['$state', '$scope', '$stateParams', 'abp.services.app.insurerClaim', 'Hms.InsurerClaims.InsurerClaimEnums.InsurerClaimStatus', controller],
        controllerAs: 'vm'
    });

    function controller($state, $scope, $stateParams, insurerClaimSvc, enumInsurerClaimStatus) {
        var vm = this;
        vm.loading = 0;
        vm.timeline = null;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

        vm.enums = {};
        vm.enums.insurerClaimStatus = enumInsurerClaimStatus;

        function init() {
            getTimeline();
        }

        function getTimeline() {
            vm.loading++;
            insurerClaimSvc.getClaimTimeline({
                id: $stateParams.id
            }).success(function (data) {
                vm.timeline = data;
            }).finally(function () {
                vm.loading--;
            });
        }

        init();
    }
})();
