(function () {
    var componentId = 'hostClaimPolicyComponent';
    var app = angular.module("app");

    function controller(commonLookupSvc, enumIdentificationType) {
        function init() {
            getClaimPolicy();
        }

        var vm = this;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.loading = 0;

        vm.scrollContentToTop = scrollContentToTop;

        vm.enums = {};
        vm.enums.identificationType = enumIdentificationType;

        /* Functions */

        function getClaimPolicy() {
            vm.loading++;
            commonLookupSvc.getMemberClaimPolicy({ id: vm.memberId })
                .success(function (data) {
                    vm.data = data;
                }).finally(function (e) {
                    vm.loading--;
                });
        }

        function scrollContentToTop() {
            $('#policyContent').scrollTop(0);
        }

        /* End of Functions */

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/claimPolicies/claimPolicy.component.html'),
        controller: ['abp.services.app.commonLookup', 'Hms.Employees.IdentificationType', controller],
        controllerAs: "vm",
        bindings: {
            memberId: '<'
        }
    });
})();
