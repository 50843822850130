(function () {
    angular.module('app').component('clinicPatientTicketHeader', {
        templateUrl: require('/App/clinic/views/patient/ticket/header/widget.html'),
        controller: ['$filter', 'lookupModal', controller],
        controllerAs: 'vm',
        bindings: {
            clinic: '<',
            patient: '<',
            isCreate: '<',
            ticketDate: '<',
            isLateSubmission: '<',
            onChangeLocation: '&',
            currencyCode: '<'
        }
    });

    function controller($filter) {
        var vm = this;
        vm.defaultMaxAllowance = parseFloat(abp.setting.get("Hms.General.MaxAllowanceAmountLimit"));

        vm.changeLocation = function () {
            vm.onChangeLocation();
        };

        init();

        /* Functions */

        function init() {
            if (!vm.patient) return;

            if (vm.patient.sponsorNationalId) {
                vm.dependentDescription = App.localize(
                    'DependentDescription',
                    vm.patient.sponsorName,
                    $filter('nationalIdFilter')(vm.patient.sponsorNationalId));
            }

            vm.allowanceDisplay = getAllowanceDisplay();

            if (vm.patient.coPay) {
                vm.coPayValueDisplay = vm.patient.coPay.isPercentage
                    ? vm.patient.coPay.value + '%'
                    : $filter('currencyFormat')(vm.patient.coPay.value, vm.currencyCode);
            }
        }

        function getAllowanceDisplay() {
            var allowanceText = $filter('currencyFormat')(Math.min(vm.patient.allowance, vm.defaultMaxAllowance), vm.currencyCode);

            if (!vm.patient.allowancePeriod) return allowanceText;

            var cycle = '';
            switch (vm.patient.allowancePeriod.cycle) {
                case 0: cycle = App.localize('EveryXYears', vm.patient.allowancePeriod.interval); break;
                case 1: cycle = App.localize('Yearly'); break;
                case 2: cycle = App.localize('Monthly'); break;
                case 3: cycle = App.localize('Daily'); break;
                case 4: cycle = App.localize('PerVisit'); break;
                case 5: cycle = App.localize('Every6Months'); break;
                case 6: cycle = App.localize('Every4Months'); break;
                case 7: cycle = App.localize('Every3Months'); break;
                case 8: cycle = App.localize('Every2Months'); break;
            }
            return allowanceText + ' ' + cycle.toLowerCase();
        }

        /* End of Functions */
    }
})();
