(() => {
  angular
    .module('app')
    .controller('common.views.saleTransactions.createStatementModal', CreateStatementController);

  CreateStatementController.$inject = [
    '$uibModalInstance',
    'abp.services.app.tenant',
    'tenantId',
    'subsidiaryId',
    'abp.services.app.commonLookup',
    'moment',
  ];

  function CreateStatementController(
    $uibModalInstance,
    tenantSvc,
    tenantId,
    subsidiaryId,
    commonLookupSvc,
    moment
  ) {
    const vm = this;

    vm.loading = 0;
    vm.tenants = [];
    vm.isCorporate = false;
    vm.stopInvoiceGeneration = abp.setting.getBoolean('Hms.Feature.StopPortalInvoiceGeneration');

    vm.statementTypes = [
      { id: 1, text: App.localize('BalanceForward') },
      { id: 2, text: App.localize('OpenItem') },
      { id: 3, text: App.localize('TransactionStatement') },
    ];

    vm.requestParams = {
      tenantId: abp.session.tenantId || tenantId,
      type: 1,
      subsidiaryId,
    };
    vm.dateRangeOptions = App.createDateRangePickerOptions();
    vm.dateRangeModel = {
      startDate: moment().subtract(1, 'months').startOf('day'),
      endDate: moment().endOf('day'),
    };

    vm.save = save;
    vm.cancel = cancel;
    vm.refreshSubsidiaries = refreshSubsidiaries;

    init();

    function init() {
      if (App.isHost()) {
        vm.loading += 1;
        tenantSvc
          .findTenants({})
          .success((data) => {
            // vm.tenants = _.filter(data.items, function (d) {
            //   return d.isClinic || d.isCorporate;
            // });
            vm.tenants = data.items;

            vm.isCorporate = _.some(
              vm.tenants,
              (tenant) => tenant.id === vm.requestParams.tenantId && tenant.isCorporate
            );

            if (vm.isCorporate || subsidiaryId) {
              commonLookupSvc
                .findSubsidiaries({
                  company: vm.requestParams.tenantId,
                  maxResultCount: 999,
                })
                .success((data2) => {
                  vm.subsidiaries = data2.items;
                  vm.subsidiaries.unshift({ name: App.localize('All'), value: null });
                })
                .finally(() => {
                  if (!vm.subsidiaries.length) vm.requestParams.subsidiary = null;
                });
            }
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    }

    function save() {
      if (!vm.requestParams.tenantId) {
        abp.message.error(App.localize('PleaseSelectACompany'));
        return;
      }
      $uibModalInstance.close(
        $.extend(vm.requestParams, {
          startDate: vm.dateRangeModel.startDate.toISOString(),
          endDate: vm.dateRangeModel.endDate.toISOString(),
        })
      );
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function refreshSubsidiaries($item) {
      vm.requestParams.subsidiaryId = null;
      vm.isCorporate = $item.isCorporate;
      if (vm.isCorporate) {
        commonLookupSvc
          .findSubsidiaries({
            company: $item.id,
            maxResultCount: 999,
          })
          .success((data) => {
            vm.subsidiaries = data.items;
            vm.subsidiaries.unshift({ name: App.localize('All'), value: null });
          })
          .finally(() => {
            if (!vm.subsidiaries.length) vm.requestParams.subsidiary = null;
          });
      }
    }
  }
})();
