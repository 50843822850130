(() => {
  angular.module('app').component('commonInpatientTicketsDetailDoctorChargesComponent', {
    templateUrl: require('./doctorCharges.component.html'),
    controller: DoctorChargesController,
    controllerAs: 'vm',
    bindings: {
      ticket: '<',
      doctors: '<',
      isEdit: '<',
      isSaved: '<',
      categories: '<',
      benefits: '<',
      admissionDate: '@',
      dischargeDate: '@',
    },
  });

  DoctorChargesController.$inject = ['moment'];

  function DoctorChargesController(moment) {
    const vm = this;

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.defaultCategory = 'InHospitalDoctorVisit';

    vm.visitTypes = [
      { value: 1, name: App.localize('VisitType_Normal') },
      { value: 2, name: App.localize('VisitType_ICU') },
      { value: 3, name: App.localize('VisitType_NormalOffHours') },
    ];

    vm.remarkOptions = [
      { id: 1, displayName: App.localize('Consultation') },
      { id: 2, displayName: App.localize('Visitation') },
      { id: 3, displayName: App.localize('ConsultationAndVisitation') },
    ];
    vm.totalDays = 0;
    vm.isAdjustment = false;

    vm.lookupRemark = lookupRemark;
    vm.$onChanges = onChanges;
    vm.addDoctor = addDoctor;
    vm.addProcedures = addProcedures;
    vm.clearProcedures = clearProcedures;
    vm.deleteItem = deleteItem;
    vm.updateProcedures = updateProcedures;
    vm.calculateTotal = calculateTotal;
    vm.calculateAmount = calculateAmount;
    vm.calculateSubtotal = calculateSubtotal;
    vm.addVisit = addVisit;
    vm.updateVisit = updateVisit;
    vm.manualEdit = manualEdit;
    vm.cancelManualEdit = cancelManualEdit;
    vm.saveManualEdit = saveManualEdit;

    init();

    function init() {
      vm.ticket.procedures = _.chain(vm.ticket.procedures)
        .groupBy('doctorId')
        .map((value, key) => ({
          doctorId: vm.ticket.ticketType !== 2 ? parseInt(key, 10) : vm.doctors[0].id,
          ticketItems: value,
          doctorName:
            vm.ticket.ticketType !== 2 ? getDoctorName(parseInt(key, 10)) : vm.doctors[0].name,
          isManualEdit: 0,
        }))
        .value();

      if (vm.ticket.procedures.length === 0) {
        if (vm.isEdit) addDoctor();
      } else {
        enrichTicketItem();
      }

      if (vm.ticket.requestType === 'Adjustment') {
        const distinctDays = [];
        // Add days and number of days used in FGL

        _.each(vm.ticket.procedures, (x) => {
          _.each(x.ticketItems, (p) => {
            if (!distinctDays.includes(p.visitDate.format('l'))) {
              vm.totalDays += 1;
            }

            distinctDays.push(p.visitDate.format('l'));
          });
        });

        vm.isAdjustment = true;
      }
    }

    function enrichTicketItem() {
      _.forEach(vm.ticket.procedures, (procedure) => {
        _.forEach(procedure.ticketItems, (item) => {
          const i = item;
          i.visitDate = moment(i.startDate);
          i.totalCharges = i.amount + i.uncoveredAmount;
          i.isOverride = 0;
        });
      });
    }

    function onChanges(changes) {
      if (changes.ticket && changes.ticket.currentValue) {
        if (vm.ticket.procedures.length === 0) {
          if (vm.isEdit) addDoctor();
        } else {
          enrichTicketItem();
        }
      }

      if (changes.isSaved && changes.isSaved.currentValue) {
        tidyProcedures();
      }

      if (changes.isEdit && changes.isEdit.currentValue) {
        if (vm.ticket && vm.ticket.procedures.length < 1) {
          addDoctor();
        }
      }
    }

    function addDoctor(id) {
      const categoryItem = {
        doctorId: id || null,
        doctorName: getDoctorName(id),
        ticketItems: [
          {
            doctorId: id || null,
            category: vm.defaultCategory,
            categoryName: getCategoryName(vm.defaultCategory),
            totalVisit: 1,
            visit: 1,
            isOverride: 0,
          },
        ],
        isManualEdit: 0,
      };
      vm.ticket.procedures.push(categoryItem);
    }

    function addProcedures(record) {
      const r = record;
      if (typeof r.doctorId === 'undefined' || r.doctorId === null) return;

      if (!r.ticketItems) {
        r.ticketItems = [];
      }

      r.ticketItems.push({
        doctorId: r.doctorId,
        category: vm.defaultCategory,
        categoryName: getCategoryName(vm.defaultCategory),
        totalVisit: 1,
        visit: 1,
        isOverride: 0,
      });
    }

    function clearProcedures(category) {
      const c = category;
      c.ticketItems = [];
      addProcedures(c);
    }

    function tidyProcedures() {
      vm.ticket.procedures = _.filter(vm.ticket.procedures, (item) =>
        _.filter(item.ticketItems, (k) => !_.isNil(k.amount))
      );
    }

    function calculateTotal(index, item) {
      const i = item;
      let uncoveredAmount = 0;
      let amount = 0;
      if (i[index].amount > 0) {
        amount = i[index].amount;
      }
      if (i[index].uncoveredAmount > 0) {
        uncoveredAmount = i[index].uncoveredAmount;
      }
      const totalCharges = amount + uncoveredAmount;
      i[index].totalCharges = totalCharges;
    }

    function calculateAmount(index, item, parentIndex) {
      const i = item;
      vm.ticket.procedures[parentIndex].ticketItems[index].isOverride = 0;

      let category = '';
      let visits = 0;
      let totalCharges = 0;

      if (i[index].category) category = i[index].category;

      if (i[index].totalCharges > 0) totalCharges = i[index].totalCharges;

      i[index].hasExceeded = false;

      // Validate visits per day.

      const benefitByVisitsPerDay = _.first(
        _.filter(vm.benefits, (x) => x.category === category && x.type === 1 && x.cycle === 3)
      );

      if (i[index].isOverride === 1) return;

      let coveredTotalCharges = 0;
      let uncoveredTotalCharges = 0;

      visits = _.filter(
        i,
        (o) => o.visitDate.format('l') === i[index].visitDate.format('l')
      ).length;

      const isUnlimited = benefitByVisitsPerDay.isUnlimited || false;
      if (!isUnlimited) {
        const visitsPerDay = benefitByVisitsPerDay.balanceDays || 0;
        if (visits > visitsPerDay) {
          uncoveredTotalCharges = totalCharges;

          if (visits) i[index].hasExceeded = true;
        } else {
          coveredTotalCharges = totalCharges;
        }
      } else {
        coveredTotalCharges = totalCharges;
      }

      i[index].amount = coveredTotalCharges;
      i[index].uncoveredAmount = uncoveredTotalCharges;

      // Validate days per year.

      validateTotalVisitDays(category);
    }

    function validateTotalVisitDays(category) {
      let totalDays = 0;
      const distinctVisitDays = []; // List of distinct days within limit
      const extraDays = []; // List of distinct days out of limit

      const benefitByDaysPerYear = _.first(
        _.filter(vm.benefits, (x) => x.category === category && x.type === 1 && x.cycle === 1)
      );

      _.each(vm.ticket.procedures, (x) => {
        _.each(x.ticketItems, (ticketItem) => {
          const ti = ticketItem;
          let coveredTotalCharges = 0;
          let uncoveredTotalCharges = 0;

          if (ti.visit === 1 && !distinctVisitDays.includes(ti.visitDate.format('l'))) {
            totalDays += 1;
            distinctVisitDays.push(ti.visitDate.format('l'));

            if (totalDays > vm.totalDays && vm.isAdjustment)
              extraDays.push(ti.visitDate.format('l'));
          }

          if (ti.isOverride === 1) return;

          const isUnlimited = benefitByDaysPerYear.isUnlimited || false;
          if (!isUnlimited) {
            const daysPerYearBalance = benefitByDaysPerYear.balanceDays || 0;

            if (vm.isAdjustment && extraDays.includes(ti.visitDate.format('l'))) {
              // Check if SOB has balance
              if (daysPerYearBalance > totalDays) {
                _.remove(extraDays, ti.visitDate.format('l'));
                vm.totalDays += 1;
              }

              ti.hasExceeded = true;
            } else if (!vm.isAdjustment && totalDays > daysPerYearBalance) {
              ti.hasExceeded = true;
            }
          }

          if (ti.hasExceeded) {
            uncoveredTotalCharges = ti.totalCharges;
          } else {
            coveredTotalCharges = ti.totalCharges;
          }

          ti.amount = coveredTotalCharges;
          ti.uncoveredAmount = uncoveredTotalCharges;
        });
      });
    }

    function calculateSubtotal() {
      let amount = 0;
      let uncoveredAmount = 0;

      _.each(vm.ticket.procedures, (procedure) => {
        const x = procedure;
        x.showExceededMessage = false;

        _.each(x.ticketItems, (p) => {
          if (p.amount) amount += p.amount;

          if (p.uncoveredAmount) uncoveredAmount += p.uncoveredAmount;

          if (p.hasExceeded) x.showExceededMessage = true;
        });
      });

      return App.roundAmount(amount + uncoveredAmount);
    }

    function updateProcedures(index, item) {
      const i = item;
      if (typeof i.doctorId === 'undefined' || i.doctorId === null) {
        if (vm.ticket.procedures.length > 1) {
          vm.ticket.procedures.splice(index, 1);
        } else {
          vm.ticket.procedures = [];
          addDoctor();
        }
      } else {
        const hospitalizationDates = getHospitalizationDates();
        if (hospitalizationDates.length > 0 && i.ticketItems.length < hospitalizationDates.length) {
          i.ticketItems = [];

          _.forEach(hospitalizationDates, (date) => {
            i.ticketItems.push(
              {
                visitDate: date,
                visit: 1,
              },
              {
                visitDate: date,
                visit: 2,
              }
            );
          });
        }

        if (i.ticketItems) {
          _.each(i.ticketItems, (ticketItem) => {
            const ti = ticketItem;
            ti.doctorId = i.doctorId;
            ti.category = vm.defaultCategory;
            ti.categoryName = getCategoryName(vm.defaultCategory);
            ti.totalVisit = 1;
          });
        }
      }
    }

    function updateVisit() {
      _.each(vm.ticket.procedures, (x) => {
        const dates = [];

        _.each(x.ticketItems, (item) => {
          const i = item;
          if (i.visitDate) {
            const recordDate = moment(i.visitDate).startOf('day').toString();
            dates.push(recordDate);

            const countVisit = _.filter(dates, (s) => recordDate === s).length;

            i.visit = countVisit;
          }
        });
      });
    }

    function getHospitalizationDates() {
      const hospitalizationDates = [];
      let minDate = null;
      let maxDate = null;

      _.each(vm.ticket.rooms, (x) => {
        // Skip if category is insured daily child benefit.

        if (x.category !== 'ChildGuardianBenefit') {
          _.each(x.ticketItems, (p) => {
            if (p.roomChargeDateRangeModel) {
              const startDate = moment(p.roomChargeDateRangeModel.startDate);
              const endDate = moment(p.roomChargeDateRangeModel.endDate);

              for (
                let currentDate = moment(startDate);
                currentDate <= endDate;
                currentDate = currentDate.add(1, 'day')
              ) {
                let isNewRecord = true;
                const currentRecord = moment(currentDate);

                if (hospitalizationDates.length > 0) {
                  _.each(hospitalizationDates, (date) => {
                    if (date.isSame(currentRecord)) {
                      isNewRecord = false;
                    }
                  });
                }

                if (isNewRecord) {
                  hospitalizationDates.push(currentRecord);
                  if (currentDate < minDate || minDate === null) {
                    minDate = moment(currentRecord);
                  }

                  if (currentDate > maxDate || maxDate === null) {
                    maxDate = moment(currentRecord);
                  }
                }
              }
            }
          });
        }
      });

      return hospitalizationDates;
    }

    function deleteItem(index, item) {
      const i = item;
      if (i.ticketItems.length > 1) {
        i.ticketItems.splice(index, 1);
      } else {
        i.ticketItems = [];
        addProcedures(i);
      }
      vm.updateVisit();
    }

    function lookupRemark(filter) {
      searchRemark(filter);
    }

    function cleanUserEntry(entry) {
      return _.map(
        _.split(_.toLower(entry), ' '),
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      ).join(' ');
    }

    function searchRemark(userEntry) {
      const cleanedUserEntry = cleanUserEntry(userEntry || '');
      const filter = _.toLower(cleanedUserEntry);

      if (filter) {
        vm.filteredRemark = postProcessRemarkList(vm.remarkOptions);
      } else {
        vm.filteredRemark = [];
      }

      function postProcessRemarkList(remarks) {
        const clonedItems = remarks.slice(0);
        const idx = _.findIndex(
          clonedItems,
          (d) => _.toLower(_.trim(d.displayName || '')) === filter
        );
        if (idx > 0) {
          const items = clonedItems.splice(idx, 1);
          clonedItems.splice(0, 0, items[0]);
        } else if (idx === -1) {
          clonedItems.splice(0, 0, {
            id: -1,
            displayName: cleanedUserEntry,
          });
        }
        return clonedItems;
      }
    }

    function addVisit(index, item) {
      const i = item;
      let visits = 1;

      if (i[index].visit > 0) visits = i[index].visit;

      i[index].visit = visits + 1;
    }

    function manualEdit(index) {
      vm.tempPlaceHolder = angular.copy(vm.ticket.procedures[index]);
      vm.ticket.procedures[index].isManualEdit = 1;
    }

    function cancelManualEdit(index) {
      vm.ticket.procedures[index] = angular.copy(vm.tempPlaceHolder);
      vm.ticket.procedures[index].isManualEdit = 0;
      vm.tempPlaceHolder = null;
    }

    function saveManualEdit(index) {
      vm.ticket.procedures[index].isManualEdit = 0;

      _.each(vm.ticket.procedures[index].ticketItems, (item) => {
        const i = item;
        i.isOverride = 1;
      });

      vm.tempPlaceHolder = null;
    }

    function getCategoryName(category) {
      const selectedCategory = _.find(vm.categories, { id: category });
      return selectedCategory ? selectedCategory.displayName : null;
    }

    function getDoctorName(id) {
      const idToMatch = parseInt(id, 10);
      if (vm.doctors) {
        const selectedDoctor = _.find(vm.doctors, (d) => d.id === idToMatch);

        if (selectedDoctor) {
          return selectedDoctor.name;
        }
      }

      return null;
    }
  }
})();
