(() => {
  angular.module('app').controller('common.views.claims.process.index', ClaimProcessPageController);

  ClaimProcessPageController.$inject = ['$stateParams'];

  function ClaimProcessPageController($stateParams) {
    const vm = this;

    vm.claimNumber = $stateParams.claimNumber;
    vm.receiptDate = null;
    vm.isCorporate = App.isCorporate();

    init();

    function init() {}
  }
})();
