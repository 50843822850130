(function () {
  'use strict';

  angular
    .module('app')
    .controller('host.views.licensingFees.details', ['$state', '$stateParams',
      'abp.services.app.licensingFee', controller
    ]);

  function controller($state, $stateParams, licensingFeeSvc) {
    var vm = this;

    vm.countryCode = $stateParams.countryCode;
    vm.serviceType = $stateParams.serviceType;
    vm.panelId = $stateParams.panelId;
    vm.isPanel = !!$stateParams.panelId;

    vm.editItem = editItem;
    vm.deleteItem = deleteItem;
    vm.removeItem = removeItem;

    vm.permissions = {
      editMasterLicensingFee: abp.auth.isGranted("Host.MasterLicensingFees.Edit"),
      editPanelLicensingFee: abp.auth.isGranted("Host.Clinics.LicensingFees.Edit")
    };

    vm.loading = 0;

    function init() {
      getLicensingFeeDetails();
    }

    function getLicensingFeeDetails() {
      vm.loading++;
      licensingFeeSvc.getLicensingFeeDetails({
        countryCode: vm.countryCode,
        serviceType: vm.serviceType,
        panelId: vm.panelId
      }).success(function (data) {
        vm.detail = data;
        vm.currencyCode = vm.detail.currencyCode;

        _.map(vm.detail.licensingFees, function (p) {
          p.show = p.isActive;
        });

        var activeRow = _.find(vm.detail.licensingFees, ['show', true]);

        // Set first row to expand the accordian if there is no active records.

        if (!activeRow) {
          _.sortBy(vm.detail.licensingFees, [function (o) {
            return o.effectiveDate;
          }]);
          vm.detail.licensingFees[0].show = true;
        }
      }).finally(function () {
        vm.loading--;
      });
    }

    function editItem(licensingFeeId) {
      $state.go('host.createEditLicensingFee', {
        licensingFeeId: licensingFeeId,
        serviceType: vm.serviceType,
        panelId: vm.panelId,
        countryCode: vm.countryCode
      });
    }

    function deleteItem(licensingFeeId) {
      abp.message.confirm(App.localize('LicensingFeeDeleteWarningMessage'), App.localize('AreYouSure'), function (d) {
        if (d) {
          vm.loading++;
          licensingFeeSvc.deleteLicensingFee({
            id: licensingFeeId
          }).success(function (data) {
            abp.notify.success(App.localize('SuccessfullyDeleted'));
            redirect();
          }).finally(function () {
            vm.loading--;
          });
        }
      });
    }

    function redirect() {
      if (vm.detail.licensingFees.length > 1) {
        getLicensingFeeDetails();
      } else {
        $state.go('host.licensingFees');
      }
    }

    function removeItem(licensingFeeId) {
      swal({
        title: App.localize('StopSchemeTitle'),
        text: App.localize('StopSchemeMessage'),
        confirmButtonColor: '#1ab394',
        confirmButtonText: App.localize('Confirm'),
        cancelButtonText: App.localize('Cancel'),
        showCancelButton: true,
        closeOnConfirm: false,
      }, function (isConfirm) {
        if (isConfirm) {
          vm.loading++;
          licensingFeeSvc.removeCustomLicensingFee({
            id: licensingFeeId
          }).success(function (data) {
            abp.notify.success(App.localize('SuccessfullyDeleted'));
            redirect();
          }).finally(function () {
            vm.loading--;
            swal.close();
          });
        } else {
          swal.close();
        }
      });
    }

    init();
  }
})();
