(function () {
    var controllerId = 'host.views.insurers.insurerTeamsPage';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', 'abp.services.app.insurerTeam','abp.services.app.insurer',
        function ($scope, $state, $stateParams, insurerTeamSvc, insurerSvc) {
            function init() {
                vm.getInsurerTeams();
                vm.loading = true;
                insurerSvc.getInsurer({
                    id: vm.insurerId
                }).success(function (data) {
                    vm.insurerDisplayName = data.name + ' - ' + data.tenancyName;
                }).finally(function () {
                    vm.loading = false;
                });
            }

            var vm = this;
            vm.loading = false;
            vm.insurerId = $stateParams.insurerId;
            vm.insurerDisplayName = "";
            vm.hasInsurerModule = abp.setting.getBoolean('Hms.Feature.InsuranceModule');

            vm.permissions = {
                edit: abp.auth.isGranted('Host.NewInsurers.EditProperties'),
                create: abp.auth.isGranted('Host.NewInsurers.Create'),
                view: abp.auth.isGranted('Host.NewInsurers.View')
            };

            vm.showNewInsurerModule = vm.hasInsurerModule && vm.permissions.view;

            // Define grid options

            vm.insurerTeamGridOptions = {
                appScopeProvider: vm,
                paginationPageSizes: app.consts.grid.defaultPageSizes,
                paginationPageSize: app.consts.grid.defaultPageSize,
                paginationCurrentPage: 0,
                useExternalPagination: true,
                useExternalSorting: true,
                columnDefs: [{
                    enableSorting: false,
                    name: App.localize('Actions'),
                    width: 100,
                    headerCellTemplate: "<span></span>",
                    cellTemplate: 'teamActionTemplate'
                }, {
                    displayName: App.localize('Name'),
                    field: 'name',
                    minWidth: 120
                }, {
                    displayName: App.localize('AssociatedMasterPolicy'),
                    field: 'associatedMasterPolicy',
                    minWidth: 100
                }, {
                    displayName: App.localize('RequireHardCopy'),
                    field: 'isHardCopyRequired',
                    cellTemplate: 'activeTemplate',
                    minWidth: 100
                }, {
                    displayName: App.localize('CreationDate'),
                    field: 'creationTime',
                    cellFilter: 'momentFormat: \'L\'',
                    minWidth: 100
                }],
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                },
                data: []
            };

            // Define functions

            vm.createInsurerTeam = function () {
                $state.go('host.createEditInsurerTeam', {insurerId: vm.insurerId});
            };

            vm.viewInsurerTeam= function (team) {
                $state.go('host.createEditInsurerTeam', {insurerId: vm.insurerId, insurerTeamId: team.id})
            };

            vm.deleteInsurerTeam = function (team) {
                abp.message.confirm(App.localize('InsurerTeamDeleteWarningMessage', team.name), App.localize('AreYouSure'), function (result) {
                    if (result) {
                        insurerTeamSvc.deleteInsurerTeam({
                            id: team.id
                        }).success(function () {
                            vm.getInsurerTeams();
                            abp.notify.success(App.localize("SuccessfullyDeleted"));
                        });
                    }
                });
            };

            vm.getInsurerTeams = function () {
                if (vm.loading) return;
                vm.loading = true;
                insurerTeamSvc.getInsurerTeamsForInsurer({
                    id: vm.insurerId
                }).success(function (data) {
                    vm.insurerTeamGridOptions.data = data.items;
                }).finally(function () {
                    vm.loading = false;
                });
            };

            init();
        }
    ]);
})();
