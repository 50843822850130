(() => {
  angular.module('app').controller('host.views.publicHolidays', PublicHolidaysController);

  PublicHolidaysController.$inject = [
    '$state',
    '$stateParams',
    '$scope',
    '$timeout',
    '$uibModal',
    'abp.services.app.publicHoliday',
    'countries',
    'abp.services.app.commonLookup',
    'moment',
    'appConstants',
  ];

  function PublicHolidaysController(
    $state,
    $stateParams,
    $scope,
    $timeout,
    $modal,
    publicHolidaySvc,
    countries,
    commonLookupSvc,
    moment,
    appConstants
  ) {
    const vm = this;

    // properties/variables

    vm.loading = 0;
    vm.years = [moment().year()];
    vm.states = [];

    vm.permissions = {
      create: abp.auth.isGranted('Host.PublicHolidays.Create'),
      edit: abp.auth.isGranted('Host.PublicHolidays.Edit'),
      delete: abp.auth.isGranted('Host.PublicHolidays.Delete'),
      import: abp.auth.isGranted('Host.PublicHolidays.Import'),
    };

    vm.requestParams = {
      countryCode: $stateParams.countryCode,
      year: $stateParams.year || moment().year(),
      state: $stateParams.state,
      filter: $stateParams.filter,
      skipCount: $stateParams.skipCount,
      maxResultCount: $stateParams.maxResultCount,
      sorting: $stateParams.sorting,
    };

    vm.dateRangeOptions = App.createDateRangePickerOptions();
    vm.dateRangeOptions.max = 0;
    vm.dateRangeOptions.maxDate = 0;
    vm.dateRangeModel = {
      startDate: null,
      endDate: null,
    };

    vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSizes,
      paginationPageSize: appConstants.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      resizable: true,
      columnDefs: [
        {
          name: App.localize('Actions'),
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
          allowCellFocus: false,
        },
        {
          displayName: App.localize('Warning'),
          field: 'isDuplicated',
          width: 25,
          cellTemplate: 'warningsTemplate',
        },
        {
          displayName: App.localize('HolidayName'),
          field: 'holidayName',
          minWidth: 120,
        },
        {
          displayName: App.localize('Date'),
          field: 'holidayDateString',
          width: 85,
        },
        {
          displayName: App.localize('Country'),
          field: 'country',
          width: 120,
          cellTemplate: vm.hasRegionalSystemSupport ? 'countryTemplate' : '',
        },
        {
          name: App.localize('Nationwide'),
          width: 100,
          cellTemplate: 'isNationalTemplate',
        },
        {
          displayName: vm.hasRegionalSystemSupport
            ? App.localize('AffectedStatesProvinces')
            : App.localize('AffectedStates'),
          field: 'affectedStates',
          minWidth: 120,
        },
        {
          displayName: vm.hasRegionalSystemSupport
            ? App.localize('ExceptionStatesProvinces')
            : App.localize('ExceptionStates'),
          field: 'exceptionStates',
          minWidth: 120,
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (_scope, callback) => {
          vm.requestParams.sorting =
            callback.length && callback[0].field
              ? `${callback[0].field} ${callback[0].sort.direction}`
              : null;
          vm.getPublicHolidays();
        });
        gridApi.pagination.on.paginationChanged($scope, (newPage, pageSize) => {
          vm.requestParams.skipCount = (newPage - 1) * pageSize;
          vm.requestParams.maxResultCount = pageSize;
          vm.getPublicHolidays();
        });
      },
      data: [],
    };

    // function bindings

    vm.getCountrySubdivisions = getCountrySubdivisions;
    vm.bulkImport = bulkImport;
    vm.getPublicHolidays = getPublicHolidays;
    vm.createPublicHoliday = createPublicHoliday;
    vm.editPublicHoliday = editPublicHoliday;
    vm.deletePublicHoliday = deletePublicHoliday;

    // initialisation

    init();

    function init() {
      if ($stateParams.startDate) vm.dateRangeModel.startDate = moment($stateParams.startDate);
      if ($stateParams.endDate) vm.dateRangeModel.endDate = moment($stateParams.endDate);

      getCountrySubdivisions();

      $timeout(() => {
        vm.gridOptions.paginationCurrentPage = Math.floor(
          vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1
        );
      });

      if (vm.hasRegionalSystemSupport) {
        vm.loading += 1;
        commonLookupSvc
          .getEligibleCountries()
          .success((data) => {
            const elligibleCountryCodes = data.map((o) => o.code);

            vm.countries = _.filter(countries, (c) => elligibleCountryCodes.includes(c.code));
          })
          .finally(() => {
            vm.loading -= 1;
          });
      } else {
        vm.countries = _.filter(countries, (c) => c.code === vm.defaultCountryCode);
      }
    }

    // function declarations

    function getInput() {
      const input = {
        year: vm.requestParams.year,
        countryCode: vm.requestParams.countryCode,
        state: vm.requestParams.state,
        filter: vm.requestParams.filter,
        startDate: null,
        endDate: null,
        skipCount: vm.requestParams.skipCount,
        maxResultCount: vm.requestParams.maxResultCount,
        sorting: vm.requestParams.sorting,
      };

      if (vm.dateRangeModel.startDate)
        input.startDate = vm.dateRangeModel.startDate.format('YYYY-MM-DD');
      if (vm.dateRangeModel.endDate) input.endDate = vm.dateRangeModel.endDate.format('YYYY-MM-DD');

      registerStateParams(input);

      return input;
    }

    function registerStateParams(i) {
      const input = i;
      $state.transitionTo($state.current, input, { notify: false });
    }

    function getPublicHolidays() {
      const input = getInput();

      vm.loading += 1;
      publicHolidaySvc
        .getPublicHolidays(input)
        .success((data) => {
          vm.gridOptions.totalItems = data.totalCount;
          vm.gridOptions.data = data.items;
          vm.years = data.years;
          vm.lastUpdatedTime = data.lastUpdatedTime;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getCountrySubdivisions(countryCode) {
      vm.requestParams.state = null;

      // Exclude Singapore because Singapore has no states.

      const country = countryCode === 'SG' ? null : _.find(countries, { code: countryCode });
      if (country) {
        vm.states = country.subdivisions;
      } else {
        vm.states = [];
      }
    }

    /* Create, edit & delete public holiday functions */

    function showCreateEditModal(publicHolidayId) {
      const modal = $modal.open({
        templateUrl: require('./createOrEdit/createOrEdit.modal.html'),
        controller: 'host.views.publicHolidays.createOrEditModal as vm',
        backdrop: 'static',
        resolve: {
          publicHolidayId,
        },
      });
      modal.result.then(() => {
        vm.getPublicHolidays();
      });
    }

    function createPublicHoliday() {
      showCreateEditModal();
    }

    function editPublicHoliday(publicHolidayId) {
      showCreateEditModal(publicHolidayId);
    }

    function deletePublicHoliday(publicHoliday) {
      abp.message.confirm(
        App.localize('PublicHolidayDeleteWarningMessage', publicHoliday.holidayName),
        App.localize('AreYouSure'),
        (isConfirmed) => {
          if (isConfirmed) {
            publicHolidaySvc
              .deletePublicHoliday({
                id: publicHoliday.id,
              })
              .success(() => {
                vm.getPublicHolidays();
                abp.notify.success(App.localize('SuccessfullyDeleted'));
              });
          }
        }
      );
    }

    /* End of create, edit & delete public holiday functions */

    function bulkImport() {
      const modal = $modal.open({
        templateUrl: require('./import/import.modal.html'),
        controller: 'host.views.publicHolidays.import as vm',
        backdrop: 'static',
      });
      modal.result.then(() => {
        vm.getPublicHolidays();
      });
    }
  }
})();
