class Value {
  constructor(value, isEmpty) {
    this.value = value;
    if (isEmpty !== undefined) {
      this.isEmpty = value === null || value.length === 0;
    } else this.isEmpty = isEmpty;
  }
}

export default Value;
