(function () {
    var componentId = 'hostPanelRecruitmentSendAppointmentLetterComponent';
    var app = angular.module('app');

    function controller($scope, panelRequestSvc, constsEmployee) {
        var vm = this;
        vm.constsEmployee = constsEmployee;

        vm.loading = 0;
        vm.attachments = [];
        vm.permissions = {
            viewEmailTemplates: abp.auth.isGranted('Host.CustomEmailTemplates')
        };

        vm.$onChanges = function (changes) {
            if (changes.requestNumber) {
                vm.formData.requestNumber = vm.requestNumber;
                getAccountOwnersByPanelRequestNumber(vm.requestNumber);
                getTemplate(vm.requestNumber);
            }
        };

        vm.summernoteOptions = {
            placeholder: App.localize('RichTextEditorPlaceholder'),
            disableDragAndDrop: true,
            height: 400,
            focus: true,
            toolbar: [
                ['edit', ['undo', 'redo']],
                ['headline', ['style']],
                ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'strikethrough', 'clear']],
                ['fontface', ['fontname']],
                ['textsize', ['fontsize']],
                ['fontclr', ['color']],
                ['alignment', ['ul', 'ol', 'paragraph', 'lineheight']],
                ['height', ['height']],
                ['table', ['table']]
            ]
        };

        vm.uploadConfig = {
            objectType: 'EmailAttachment'
        };

        // See panelRequestSvc.sendAppointmentLetter input DTO.
        vm.formData = {
            requestNumber: null,
            recipientEmail: null,
            subject: null,
            content: null,
            templateId: null,
            attachments: [],
            newAttachments: []
        };

        vm.send = function () {
            if (!App.isFormValid($scope.sendAppointmentLetterForm) || vm.saving) return;

            // Process attachments.

            vm.formData.attachments = _.map(_.filter(vm.attachments, ['isDelete', false]), 'id');

            // Send appointment letter.

            vm.saving++;
            panelRequestSvc.sendAppointmentLetter(vm.formData)
                .success(function () {
                    abp.notify.info(App.localize('SuccessfullySubmitted'));
                    vm.onSaved();
                }).finally(function () {
                    vm.saving--;
                });
        };

        vm.cancel = function () {
            vm.onCancel();
        };

        function getTemplate(requestNumber) {
            if (!requestNumber) return;

            vm.loading++;

            panelRequestSvc.getAppointmentLetterTemplate({
                id: requestNumber
            }).success(function (data) {
                vm.template = data.template;

                if (vm.template) {
                    vm.formData.content = vm.template.content;
                    vm.summernoteOptions = {
                        code: vm.content
                    };
                    vm.formData.subject = vm.template.subject;
                    vm.formData.templateId = vm.template.id;
                    vm.attachments = vm.template.attachments;
                } else {
                    vm.formData.templateId = null;
                }
            }).finally(function () {
                vm.loading--;
            });
        };

        function getAccountOwnersByPanelRequestNumber(requestNumber){
            if (!requestNumber) return;

            vm.loading += 1;

            panelRequestSvc.getAccountOwnersByPanelRequestNumber({
                requestNumber
            }).success( (data) => {
                vm.accountOwners = data.accountOwners;
            }).finally( () => {
                vm.loading -= 1;
            });
        }
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/panelRecruitment/recruitmentTimeline/sendAppointmentLetter/sendAppointmentLetter.component.html'),
        controller: ['$scope', 'abp.services.app.panelRequest', 'Hms.Employees.Employee', controller],
        controllerAs: 'vm',
        bindings: {
            onSaved: '&', // Called when send appointment letter request done.
            onCancel: '&', // Called when user click Discard.
            requestNumber: '<', // The panel request number to send appointment letter for.
            isSpecialist: '<' // Do not display template creation for specialist. Remove this when specialist template is ready.
        }
    });
})();
