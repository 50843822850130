(() => {
  angular
    .module('app')
    .controller('common.views.saleTransactions.bulkPayments', BulkPaymentsController);

  BulkPaymentsController.$inject = [
    '$state',
    '$uibModal',
    'abp.services.app.saleTransaction',
    'moment',
  ];

  function BulkPaymentsController($state, $modal, saleTransactionSvc, moment) {
    const vm = this;

    vm.loading = false;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.stopInvoiceGeneration = abp.setting.getBoolean('Hms.Feature.StopPortalInvoiceGeneration');

    vm.paymentMethods = {};
    vm.paymentMethodsSrc = [];

    vm.validFlag = [];
    vm.hot = {
      height: $(window).height() - 300,
      stretchH: 'all',
      autoColumnSize: { syncLimit: '40%' },
      startCols: 8,
      startRows: 500,
      columnSorting: true,
      afterValidate(isValid, _value, row, prop) {
        const errorString = row + prop;
        const index = vm.validFlag.indexOf(errorString);
        if (!isValid && index === -1) vm.validFlag.push(errorString);
        else if (index > -1) vm.validFlag.splice(index, 1);
      },
      rowHeaders: true,
      colHeaders: [
        App.localize('Category'),
        App.localize('PaymentInstructionShort'),
        App.localize('Invoice'),
        App.localize('InvoiceDate'),
        App.localize('Recipient'),
        App.localize('Total'),
        App.localize('Outstanding'),
        App.localize('PaymentDate'),
        App.localize('PaymentMethod'),
        App.localize('ReferenceNumber'),
        App.localize('Remark'),
        App.localize('Amount'),
      ],
      columns: [
        { data: 'category', readOnly: true, colWidths: 80 },
        { data: 'paymentInstructionTransactionNumber', readOnly: true },
        { data: 'transactionNumber', readOnly: true },
        { data: 'invoiceDateStr', readOnly: true, type: 'date', dateFormat: 'L' },
        { data: 'recipientName', readOnly: true, colWidths: 200 },
        {
          data: 'totalAmount',
          readOnly: true,
          type: 'numeric',
          format: `{${vm.currencyCode} }0,0.00`,
        },
        {
          data: 'outstandingAmount',
          readOnly: true,
          type: 'numeric',
          format: `{${vm.currencyCode} }0,0.00`,
        },
        { data: 'paymentDateStr', type: 'date', dateFormat: 'DD/MM/YYYY', correctFormat: true },
        { data: 'paymentMethodStr', type: 'dropdown', source: vm.paymentMethodsSrc },
        { data: 'referenceNumber' },
        { data: 'remark' },
        {
          data: 'amount',
          type: 'numeric',
          format: `{${vm.currencyCode} }0,0.00`,
          allowInvalid: true,
        },
      ],
      minSpareRows: 0,
      manualRowResize: true,
      manualColumnResize: true,
      dropdownMenu: [
        'alignment',
        '-= 1-= 1-= 1-= 1-',
        'filter_by_condition',
        'filter_by_value',
        'filter_action_bar',
      ],
      filters: true,
      formulas: false,
      wordWrap: false,
    };

    vm.cancel = cancel;
    vm.preview = preview;

    init();

    function init() {
      getBulkMakePayments();
    }

    function getBulkMakePayments() {
      vm.loading += 1;
      saleTransactionSvc
        .getBulkMakePayments()
        .success((data) => {
          vm.hot.data = _.map(data.invoices, (invoice) => {
            const i = invoice;
            i.invoiceDateStr = moment(i.invoiceDate).format('DD/MM/YYYY');
            return i;
          });
          _.each(data.paymentMethods, (d) => {
            vm.paymentMethods[d.name] = d.value;
            vm.paymentMethodsSrc.push(App.localize(d.name));
          });
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function cancel() {
      abp.message.confirm(
        App.localize('CancelBulkMakePaymentsWarning'),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            $state.go('saleTransactions');
          }
        }
      );
    }

    function preview() {
      const validationResult = validateInput();
      if (!validationResult.inputRows.length) {
        abp.message.error(App.localize('ThereAreNoPaymentInputs'));
      } else if (validationResult.errorRow) {
        abp.message.error(App.localize('MissingInputsForX', validationResult.errorRow));
      } else {
        $modal
          .open({
            templateUrl: require('./previewBulkPaymentsModal.html'),
            controller: 'common.views.saleTransactions.previewBulkPaymentsModal as vm',
            backdrop: 'static',
            resolve: {
              inputs() {
                return validationResult.inputRows;
              },
            },
            size: 'lg',
          })
          .result.then(() => {
            $state.go('saleTransactions');
          });
      }
    }

    function validateInput() {
      const inputRows = [];
      const errorRows = [];
      _.each(vm.hot.data, (value) => {
        const v = value;
        if (v.paymentDateStr || v.paymentMethodStr || v.referenceNumber || v.amount) {
          if (v.paymentMethodStr) {
            v.paymentMethod = vm.paymentMethods[v.paymentMethodStr];
          }
          v.paymentDate = moment(v.paymentDateStr, 'DD/MM/YYYY');
          inputRows.push(v);

          if (!v.paymentDate || !v.paymentMethod || !v.referenceNumber || !v.amount) {
            errorRows.push(v.transactionNumber);
          }
        }
      });
      return { errorRow: errorRows.join(', '), inputRows };
    }
  }
})();
