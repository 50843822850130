import swal from 'sweetalert';

(() => {
  angular.module('app').controller('corporate.views.benefitGroups.index', BenefitGroupController);

  BenefitGroupController.$inject = ['abp.services.app.benefitGroup', 'abp.services.app.tenant'];

  function BenefitGroupController(benefitGroupSvc, tenantSvc) {
    const vm = this;
    vm.loading = 0;
    vm.hasStandardService = false;
    vm.hasPreEmploymentModule = abp.features.isEnabled('HasPreEmploymentModule');

    vm.permissions = {
      create: abp.auth.isGranted('Corporate.BenefitGroups.Create'),
      edit: abp.auth.isGranted('Corporate.BenefitGroups.Edit'),
      delete: abp.auth.isGranted('Corporate.BenefitGroups.Delete'),
    };

    vm.benefitGroupGridOptions = {
      appScopeProvider: vm,
      columnDefs: [
        {
          enableSorting: false,
          name: App.localize('Actions'),
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
        },
        {
          displayName: App.localize('Name'),
          field: 'name',
          minWidth: 100,
        },
        {
          displayName: App.localize('Type'),
          field: 'isPreEmploymentBenefitGroup',
          cellTemplate: 'benefitGroupTypeTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('CreationDate'),
          field: 'creationTime',
          cellFilter: "momentFormat: 'L'",
          minWidth: 100,
        },
      ],
      data: [],
    };
    vm.permissions.edit || vm.permissions.delete || vm.benefitGroupGridOptions.columnDefs.shift();

    vm.deleteBenefitGroup = deleteBenefitGroup;
    vm.toggleBenefitGroupFilter = setGridData;

    init();

    function init() {
      getBenefitGroups();
      getTenantActiveServices();
    }

    function deleteBenefitGroup(benefitGroup) {
      // Check for employees first then show swal if atleast one employee is linked to BG
      // confirmatino message
      // delete

      benefitGroupSvc
        .checkIfBenefitGroupIsDeletable({
          id: benefitGroup.id,
        })
        .success((x) => {
          if (x) {
            abp.message.confirm(
              App.localize('BenefitGroupDeleteWarningMessage', benefitGroup.name),
              App.localize('AreYouSure'),
              (d) => {
                if (d) {
                  benefitGroupSvc
                    .deleteBenefitGroup({
                      id: benefitGroup.id,
                    })
                    .success(() => {
                      getBenefitGroups();
                      abp.notify.success(App.localize('SuccessfullyDeleted'));
                    });
                }
              }
            );
          } else {
            swal({
              title: App.localize('FailedToDeleteBenefitGroup'),
              text: App.localize('FailedBenefitGroupDelete'),
              type: 'warning',
            });
          }
        });
    }

    function getBenefitGroups() {
      if (vm.loading) return;

      vm.loading += 1;
      benefitGroupSvc
        .getBenefitGroups()
        .success((data) => {
          vm.benefitGroups = data.items;
          setGridData();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function setGridData() {
      vm.benefitGroupGridOptions.data = vm.benefitGroupId
        ? _.filter(vm.benefitGroups, ['id', vm.benefitGroupId])
        : vm.benefitGroups;
    }

    function getTenantActiveServices() {
      vm.loading += 1;
      tenantSvc
        .getTenantActiveServices({})
        .success((data) => {
          vm.hasStandardService = data.filter((x) => x.name !== 'PreEmployment').length > 0;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
