(function () {
    angular.module('app').controller('host.views.doraemon.issueNonGl.modal', [
        '$scope', '$uibModalInstance',
        function ($scope, $uibModalInstance) {
            var vm = this;

            vm.cancel = cancel;
            vm.submit = submit;
            vm.remarks = '';

            vm.page = 1;
            vm.nextPage = nextPage;

            function cancel() {
                $uibModalInstance.dismiss();
            }

            function submit(printGl) {
                var input = {
                    remarks: _.trim(vm.remarks),
                    printGl: printGl
                }

                if (App.isFormValid($scope.issueNonGlModalForm)) {
                    $uibModalInstance.close(input);
                };
            }

            function nextPage() {
                if (App.isFormValid($scope.issueNonGlModalForm)) {
                    vm.page += 1;
                }
            }

        }
    ]);
})();
