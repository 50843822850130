(function () {
    var componentId = 'hostDirectorDashboardTicketStatisticsWidget';
    var app = angular.module('app');

    function controller(directorDashboardSvc, moment) {
        function init() {
            vm.loading++;
            directorDashboardSvc.getTicketStatistics()
                .success(function (data) {
                    vm.data = data;
                }).finally(function () {
                    vm.loading--;
                });
        }

        var vm = this;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.loading = 0;
        vm.day = moment().day();

        var todayTick = moment().startOf('d').valueOf();
        vm.getFromNow = function (d) {
            var md = moment(d).startOf('d');
            if (md.valueOf() === todayTick) {
                return App.localize('Today').toLowerCase();
            }
            return md.endOf('d').fromNow();
        };

        init();
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/directorDashboard/ticketStatisticsWidget.html'),
        controller: ['abp.services.app.directorDashboard', 'moment', controller],
        controllerAs: 'vm'
    });
})();
