(() => {
  angular
    .module('revenue')
    .controller('revenue.views.subscriptionPlans.detail', CreateSubscriptionPlanController);

  CreateSubscriptionPlanController.$inject = [
    '$scope',
    '$state',
    'abp.services.revenue.subscriptionPlan',
    'abp.services.revenue.common',
    'abp.services.app.commonLookup',
    'Hms.Revenue.SubscriptionPlanClientType',
    '$uibModal',
    'HmsRevenueConsts.SubscriptionPlan',
    '$stateParams',
    'moment',
    'Hms.Revenue.SubscriptionPlanPricingMode',
    'Hms.Revenue.SubscriptionPlanChargeMode',
    'Hms.Revenue.OutpatientServiceFeatures',
    'Hms.Revenue.InpatientServiceFeatures',
  ];

  function CreateSubscriptionPlanController(
    $scope,
    $state,
    subscriptionPlanSvc,
    revenueCommonSvc,
    commonLookupSvc,
    enumSubscriptionPlanClientType,
    $uibModal,
    constsSubscriptionPlan,
    $stateParams,
    moment,
    enumSubscriptionPlanPricingMode,
    enumSubscriptionPlanChargeMode,
    enumOutpatientServiceFeatures,
    enumInpatientServiceFeatures
  ) {
    const vm = this;

    vm.loading = 0;
    vm.filteredAccountOwners = [];
    vm.constsSubscriptionPlan = constsSubscriptionPlan;
    vm.currencyCode = 'MYR';
    vm.subscriptionPlanId = $stateParams.subscriptionPlanId;
    vm.isEdit = false;
    vm.tomorrow = moment().startOf('day').add(1, 'days');
    vm.isActive = false;

    vm.enums = {
      subscriptionPlanClientType: enumSubscriptionPlanClientType,
      subscriptionPlanPricingMode: enumSubscriptionPlanPricingMode,
      subscriptionPlanChargeMode: enumSubscriptionPlanChargeMode,
      outpatientServiceFeatures: enumOutpatientServiceFeatures,
      inpatientServiceFeatures: enumInpatientServiceFeatures,
    };

    vm.serviceFeatures = _.union(
      vm.enums.outpatientServiceFeatures.values,
      vm.enums.inpatientServiceFeatures.values
    );

    vm.selectableServiceFeatures = vm.enums.outpatientServiceFeatures;
    vm.isEffectiveDatePassed = false;

    vm.subscriptionPlan = {
      countryCode: null,
      accountOwnerId: null,
      accountOwnerName: null,
      tenantType: null,
      planName: null,
      planCategory: null,
      pricingMode: null,
      chargeMode: null,
    };

    vm.getAccountOwners = getAccountOwners;
    vm.addNewCategoryCharge = addNewCategoryCharge;
    vm.removeCategoryCharge = removeCategoryCharge;
    vm.clearCategoryCharges = clearCategoryCharges;
    vm.changeSelectableServiceFeatures = changeSelectableServiceFeatures;
    vm.save = save;
    vm.getServiceFeaturesAsString = getServiceFeaturesAsString;
    vm.getLatestRetailPrice = getLatestRetailPrice;
    vm.getLatestPricingStartDate = getLatestPricingStartDate;
    vm.getChargeMode = getChargeMode;
    vm.getPricingMode = getPricingMode;
    vm.cancel = cancel;
    vm.onUpdatingNewPricing = onUpdatingNewPricing;
    vm.updateNewPricing = updateNewPricing;

    init();

    function init() {
      getCountries();
      getSubscriptionPlan();
    }

    function getSubscriptionPlan() {
      vm.loading += 1;
      subscriptionPlanSvc
        .getSubscriptionPlan({
          id: vm.subscriptionPlanId,
        })
        .success((data) => {
          vm.subscriptionPlan = data;
          vm.isActive =
            vm.subscriptionPlan.planStatus === 0 &&
            moment(vm.subscriptionPlan.effectiveDate) <= moment();
          // Set the retail price to the first subscription plan pricing tier
          // subscription plan edit purposes.

          _.each(vm.subscriptionPlan.categoryCharges, (categoryCharge) => {
            categoryCharge.show = true;
            categoryCharge.retailPrice = categoryCharge.latestRetailPrice.chargeAmount;
            categoryCharge.minEffectiveDate =
              moment(vm.subscriptionPlan.effectiveDate) <= moment()
                ? vm.tomorrow
                : categoryCharge.latestRetailPrice.effectiveDate;
          });
          getAccountOwners();
          changeSelectableServiceFeatures();

          // Format effective date to show in view.

          vm.subscriptionPlan.effectiveDate = moment(vm.subscriptionPlan.effectiveDate);

          // If subscription plan effectiveDate is after today's date, prevent full edit of
          // subscription plan.

          const effectiveDatePassed = moment(vm.subscriptionPlan.effectiveDate) <= moment();
          if (effectiveDatePassed) {
            vm.isEffectiveDatePassed = true;
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getCountries() {
      vm.loading += 1;
      commonLookupSvc
        .getEligibleCountries()
        .success((data) => {
          vm.countries = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getAccountOwners() {
      // Reset accountOwnerId filter selection if currently in edit mode.
      // Will not reset when trying to retrieve during first time load.
      if (vm.isEdit) {
        vm.subscriptionPlan.accountOwnerId = null;
      }
      vm.filteredAccountOwners = null;

      // If country code filter is not null, get accountOwners under that country.
      if (vm.subscriptionPlan.countryCode != null) {
        vm.loading += 1;
        revenueCommonSvc
          .getAccountOwnersByCountryCode({
            countryCode: vm.subscriptionPlan.countryCode,
          })
          .success((data) => {
            vm.filteredAccountOwners = data.items;
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
      getCurrencyCodeByCountryCode();
    }

    function getCurrencyCodeByCountryCode() {
      // Get the currency code for the selected country.

      vm.getCurrencyCodeByCountryCodeInput = {
        countryCode: vm.subscriptionPlan.countryCode,
      };
      vm.loading += 1;
      commonLookupSvc
        .getCurrencyCodeByCountryCode(vm.getCurrencyCodeByCountryCodeInput)
        .success((data) => {
          vm.currencyCode = data.currencyCode;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function CategoryCharge() {
      // Basic properties.

      this.serviceFeatures = [];
      this.retailPrice = 0;

      // View properties.

      this.show = true;
      this.description = '';
    }

    function addNewCategoryCharge() {
      const categoryCharge = new CategoryCharge();
      vm.subscriptionPlan.categoryCharges.push(categoryCharge);
    }

    function removeCategoryCharge(index) {
      // Prevent removal if only left 1 category charge.
      if (vm.subscriptionPlan.categoryCharges.length > 1) {
        abp.message.confirm(
          App.localize('DeleteCategoryChargeWarning'),
          App.localize('AreYouSure'),
          (d) => {
            if (d) {
              $scope.$apply(() => {
                vm.subscriptionPlan.categoryCharges.splice(index, 1);
              });
            }
          }
        );
      } else {
        abp.message.warn(App.localize('SubscriptionPlanMustHaveAtLeastOneCategoryChargeWarning'));
      }
    }

    function clearCategoryCharges() {
      // Set vm.subscriptionPlan.categoryCharges to empty array.

      vm.subscriptionPlan.categoryCharges = [];

      // Insert first category into vm.subscriptionPlan.categoryCharges.

      addNewCategoryCharge();
    }

    function changeSelectableServiceFeatures() {
      if (vm.subscriptionPlan.planCategory === 0) {
        vm.selectableServiceFeatures = vm.enums.outpatientServiceFeatures;
      } else {
        vm.selectableServiceFeatures = vm.enums.inpatientServiceFeatures;
      }
      if (vm.isEdit) {
        clearCategoryCharges();
      }
    }

    function save() {
      if (vm.loading) return;
      // Validate all input fields.

      if (vm.subscriptionPlan.countryCode == null) {
        abp.message.error(App.localize('MissingCountryCodeSelection'));
        return;
      }

      if (vm.subscriptionPlan.accountOwnerId == null) {
        abp.message.error(App.localize('MissingAccountOwnerSelection'));
        return;
      }

      if (vm.subscriptionPlan.tenantType == null) {
        abp.message.error(App.localize('MissingTenantTypeSelection'));
        return;
      }

      if (vm.subscriptionPlan.planName == null || vm.subscriptionPlan.planName === undefined) {
        abp.message.error(App.localize('MissingSubscriptionPlanName'));
        return;
      }

      if (vm.subscriptionPlan.planCategory == null) {
        abp.message.error(App.localize('MissingPlanCategorySelection'));
        return;
      }

      if (vm.subscriptionPlan.effectiveDate == null) {
        abp.message.error(App.localize('MissingEffectiveDateSelection'));
        return;
      }

      if (vm.subscriptionPlan.pricingMode == null) {
        abp.message.error(App.localize('MissingPricingModeSelection'));
        return;
      }

      if (vm.subscriptionPlan.chargeMode == null) {
        abp.message.error(App.localize('MissingChargeModeSelection'));
        return;
      }

      // Validate all category charges has at least one service feature selected.

      let hasMissingServiceFeatures = false;

      _.some(vm.subscriptionPlan.categoryCharges, (categoryCharge) => {
        if (categoryCharge.serviceFeatures.length < 1) {
          abp.message.error(
            App.localize('EachCategoryChargeMustHaveAtLeastOneServiceFeaturesSelectionWarning')
          );
          hasMissingServiceFeatures = true;
        }
      });

      if (hasMissingServiceFeatures) return;

      // Validate all category charges do not have duplicate service feature selected.

      let hasDuplicateServiceFeatures = false;
      let serviceFeatureList = [];

      _.each(vm.subscriptionPlan.categoryCharges, (categoryCharge) => {
        const hasDuplicate = categoryCharge.serviceFeatures.some((serviceFeature) =>
          serviceFeatureList.includes(serviceFeature)
        );
        if (hasDuplicate) {
          hasDuplicateServiceFeatures = true;
        }
        serviceFeatureList = serviceFeatureList.concat(categoryCharge.serviceFeatures);
      });

      if (hasDuplicateServiceFeatures) {
        abp.message.error(
          App.localize('SubscriptionPlanChargesCannotHaveDuplicateServiceFeatures')
        );
        return;
      }

      // Retrieve accountOwnerName to show in confirmation modal.

      _.each(vm.filteredAccountOwners, (x) => {
        if (vm.subscriptionPlan.accountOwnerId === x.value) {
          vm.subscriptionPlan.accountOwnerName = x.name;
        }
      });

      showConfirmationModal();
    }

    function showConfirmationModal() {
      const modal = $uibModal.open({
        templateUrl: require('../confirmationModal/confirmationModal.html'),
        controller: 'revenue.views.subscriptionPlans.confirmationModal as vm',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          subscriptionPlan() {
            return vm.subscriptionPlan;
          },
          categoryCharges() {
            return vm.subscriptionPlan.categoryCharges;
          },
          currencyCode() {
            return vm.currencyCode;
          },
          serviceFeatures() {
            return vm.serviceFeatures;
          },
        },
      });
      modal.result.then(() => {
        vm.loading += 1;
        subscriptionPlanSvc
          .updateSubscriptionPlan({
            id: vm.subscriptionPlan.id,
            currencyCode: vm.currencyCode,
            accountOwnerId: vm.subscriptionPlan.accountOwnerId,
            tenantType: vm.subscriptionPlan.tenantType,
            planName: vm.subscriptionPlan.planName,
            effectiveDate: vm.subscriptionPlan.effectiveDate,
            planCategory: vm.subscriptionPlan.planCategory,
            pricingMode: vm.subscriptionPlan.pricingMode,
            chargeMode: vm.subscriptionPlan.chargeMode,
            categoryCharges: vm.subscriptionPlan.categoryCharges,
          })
          .success(() => {
            abp.notify.info(App.localize('SuccessfullySaved'));
            init();
            vm.isEdit = false;
          })
          .finally(() => {
            vm.loading -= 1;
          });
      });
    }

    function getServiceFeaturesAsString(serviceFeaturesArray) {
      const values = [];
      _.forEach(serviceFeaturesArray, (item) => {
        const serviceFeature = _.find(vm.serviceFeatures, (i) => i.id === item);
        values.push(serviceFeature.name);
      });
      return values.join(', ');
    }

    function getLatestRetailPrice(categoryCharges) {
      const latestPricingStartDateOffset = getLatestPricingStartDate(categoryCharges);
      let latestRetailPrice = 0;
      _.each(categoryCharges.subscriptionPlanPricingTiers, (subscriptionPlanPricingTier) => {
        if (subscriptionPlanPricingTier.pricingStartDateOffset === latestPricingStartDateOffset)
          latestRetailPrice = subscriptionPlanPricingTier.chargeAmount;
      });
      return latestRetailPrice;
    }

    function getLatestPricingStartDate(categoryCharges) {
      let latestPricingStartDateOffset =
        categoryCharges.subscriptionPlanPricingTiers[0].pricingStartDateOffset;

      _.each(categoryCharges.subscriptionPlanPricingTiers, (subscriptionPlanPricingTier) => {
        if (subscriptionPlanPricingTier.pricingStartDateOffset > latestPricingStartDateOffset)
          latestPricingStartDateOffset = subscriptionPlanPricingTier.pricingStartDateOffset;
      });
      return latestPricingStartDateOffset;
    }

    function getChargeMode(chargeMode) {
      if (chargeMode === vm.enums.subscriptionPlanChargeMode.Monthly.id) {
        return App.localize('Monthly');
      }
      if (chargeMode === vm.enums.subscriptionPlanChargeMode.Yearly.id) {
        return App.localize('Yearly');
      }
      return '';
    }

    function getPricingMode(pricingMode) {
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.Fixed.id) {
        return App.localize('Fixed');
      }
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.PerMember.id) {
        return App.localize('PerMember');
      }
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.PerTicketBasis.id) {
        return App.localize('PerTicketBasis');
      }
      if (pricingMode === vm.enums.subscriptionPlanPricingMode.PerCaseBasis.id) {
        return App.localize('PerCaseBasis');
      }
      return '';
    }

    function cancel() {
      vm.isEdit = false;
      $state.reload();
    }

    function onUpdatingNewPricing() {
      return (
        vm.subscriptionPlan && _.some(vm.subscriptionPlan.categoryCharges, (d) => d.isNewPricing)
      );
    }

    function updateNewPricing(categoryCharge) {
      if (categoryCharge.newEffectiveDate <= Date.now()) {
        abp.message.error(App.localize('NewEffectiveDateMustBeFutureDate'));
        return;
      }

      abp.message.confirm(
        App.localize('ConfirmUpdateNewPricing'),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            vm.loading += 1;
            subscriptionPlanSvc
              .updateSubscriptionPlanPricing({
                id: categoryCharge.latestRetailPrice.subscriptionPlanPricingId,
                subscriptionPlanTierId: categoryCharge.latestRetailPrice.subscriptionPlanTierId,
                newRetailPrice: categoryCharge.newRetailPrice,
                newEffectiveDate: categoryCharge.newEffectiveDate,
              })
              .success(() => {
                abp.notify.success(App.localize('SuccessfullySaved'));
                $state.reload();
              })
              .finally(() => {
                vm.loading -= 1;
              });
          }
        }
      );
    }
  }
})();
