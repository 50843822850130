(() => {
  angular.module('app').component('backToTop', {
    templateUrl: require('./backToTop.html'),
    controller: BackToTopController,
    controllerAs: 'vm',
    bindings: {},
  });

  BackToTopController.$inject = ['$window', '$element'];

  function BackToTopController($window, $element) {
    const vm = this;
    const threshold = 100;
    const scrollSpeed = 300;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    function clickEvent() {
      $('html, body').animate(
        {
          scrollTop: 0,
        },
        scrollSpeed
      );
    }

    function showBackToTop() {
      if ($window.pageYOffset < threshold) {
        $element.fadeOut();
      } else {
        $element.fadeIn();
      }
    }

    function $onInit() {
      $element.bind('click', clickEvent);
      angular.element($window).bind('scroll', showBackToTop);
      showBackToTop();
    }
    function $onDestroy() {
      $element.unbind();
      angular.element($window).unbind('scroll', showBackToTop);
    }
  }
})();
