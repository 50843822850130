(() => {
  angular.module('app').component('preEmploymentReportV191018', {
    templateUrl: require('./preEmploymentReportV191018.component.html'),
    controller: PreEmploymentReportV191018Controller,
    controllerAs: 'vm',
    bindings: {
      report: '=',
      countryCode: '<',
    },
  });

  PreEmploymentReportV191018Controller.$inject = [];

  function PreEmploymentReportV191018Controller() {
    // const vm = this;
  }
})();
