import c3 from 'c3';

(() => {
  angular.module('app').controller('corporate.views.dashboard', CorporateDashboardController);

  CorporateDashboardController.$inject = [
    '$element',
    '$state',
    '$stateParams',
    '$uibModal',
    '$filter',
    'abp.services.app.dashboard',
    'abp.services.app.employee',
    'appSession',
    'abp.services.app.commonLookup',
    'Hms.Employees.EmployeeState',
    'moment',
    'abp.services.app.tenant',
  ];

  function CorporateDashboardController(
    $element,
    $state,
    $stateParams,
    $modal,
    $filter,
    dashboardSvc,
    employeeSvc,
    appSession,
    commonLookupSvc,
    enumEmployeeState,
    moment,
    tenantSvc
  ) {
    const vm = this;

    const currencyFormat = $filter('currencyFormat');
    const hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');
    vm.loading = 0;
    if (!hasRegionalSystemSupport) {
      vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    }
    vm.isSubsidiary = appSession.user.subsidiary;
    vm.hasPreEmployment = abp.features.isEnabled('HasPreEmploymentModule');
    vm.hasSpecialistModule = abp.features.isEnabled('Specialist.Enabled');
    vm.hasPharmacyModule = abp.features.isEnabled('Pharmacy.Enabled');
    vm.hasInpatientModule = abp.features.isEnabled('HasInpatientModule');
    vm.hasInsuranceModule = abp.features.isEnabled('HasInsurance');
    vm.hasAccessFinanceTransactionData = abp.setting.getBoolean('Hms.Feature.AccessFinanceTransactionData');
    vm.applicationHasInpatientModule = abp.setting.getBoolean('Hms.Feature.InpatientModule');
    vm.financialYears = _.range(
      appSession.tenant.currentFinancialYear,
      appSession.tenant.firstFinancialYear - 1,
      -1
    );
    vm.organizationHasInpatientModule = abp.features.isEnabled('HasInpatientModule');
    vm.hasMobileApp = abp.features.isEnabled('HasMobileApp');
    vm.hasMobileNpr = abp.features.isEnabled('HasMobileNpr') && vm.hasMobileApp;

    vm.enums = {
      employeeState: enumEmployeeState,
    };

    // TODO: Remove seeInpatientStatisticsFlag after backend has been done

    vm.seeInpatientStatisticsFlag = true;

    // END TODO

    vm.sparklineOptions = {
      type: 'line',
      width: '100%',
      height: '50',
      lineColor: '#1ab394',
      fillColor: 'transparent',
    };

    const tenantId = parseInt(appSession.tenant.id, 10);
    let employeeCountsByMcCount = [];
    let previousYearEmployeeCountsByMcCount = [];
    let employeeCountsByMcCountYear = '';
    let employeeCountsByMcCountPreviousYear = '';
    vm.requestParams = {
      department: $stateParams.department,
      subsidiary: $stateParams.subsidiary,
      financialYear: $stateParams.financialYear || appSession.tenant.currentFinancialYear,
    };

    // Region: Widgets

    vm.chartColors = ['#1AB394', '#004E64', '#00A5CF', '#9FFFCB', '#7AE582'];
    vm.totalEmployees = 0;

    // Chart options

    vm.analytics = {
      // Number of MC
      mc: {
        show: false,
        loading: false,
        chart: {
          data: [],
          options: {
            series: {
              points: {
                show: true,
              },
            },
            xaxis: {
              tickDecimals: 1,
              min: 0.0,
            },
            yaxis: {
              tickDecimals: 0,
              minTickSize: 1,
            },
            colors: vm.chartColors,
            grid: {
              color: '#999999',
              hoverable: true,
              clickable: true,
              tickColor: '#D4D4D4',
              borderWidth: 0,
              labelMargin: 20,
            },
            tooltip: {
              show: true,
              content(_label, xval) {
                const employeeCountByYear = getEmployeeCountFromMcDays(xval);
                return (
                  `<div class="flotTip">Year <b>${employeeCountByYear[0].year} : ${employeeCountByYear[0].employeeCount}</b> employee(s) have taken <b>${xval} days</b> of MC</div>` +
                  `<div class="flotTip">Year <b>${employeeCountByYear[1].year} : ${employeeCountByYear[1].employeeCount}</b> employee(s) have taken <b>${xval} days</b> of MC</div>`
                );
              },
            },
          },
        },
        highest: [],
        lowest: [],
      },

      // Medical expenditure
      expenses: {
        show: false,
        loading: false,
        selected: '0',
        dataset: {},
      },

      // Popular claims
      causes: {
        show: false,
        loading: false,
        selected: 'gp',
        get data() {
          return this.dataset[this.selected].data;
        },
        dataset: {
          all: {},
          gp: {},
          dental: {},
          optical: {},
          specialist: {},
          pharmacy: {},
          hospital: {},
        },
      },

      // Popular MC Days
      dayOff: {
        show: false,
        loading: false,
        data: [],
        total: 0,
      },

      // Clinics with Most MC Issued
      clinicMc: {
        show: false,
        loading: false,
        chart: {
          data: [],
          options: {
            series: {
              pie: {
                show: true,
              },
            },
            grid: {
              hoverable: true,
            },
            colors: vm.chartColors,
            tooltip: {
              show: true,
              snap: false,
              content(label, xval, yval) {
                return `<div class="flotTip"><b>${label}</b> issued <b>${
                  yval[0][1]
                }</b> days <b>( ${xval.toFixed(2)}% )</b> of MC</div>`;
              },
              shifts: {
                x: 20,
                y: 0,
              },
            },
            legend: {
              container: '#clinicMcLegendContainer',
            },
          },
          dataset: {
            all: {},
            gp: {},
            dental: {},
            optical: {},
            specialist: {},
            pharmacy: {},
            hospital: {},
          },
          selected: 'all',
        },
      },

      // Clinics with Most Expenses
      highExpenses: {
        show: false,
        loading: false,
        chart: {
          data: [],
          options: {
            series: {
              pie: {
                show: true,
              },
            },
            grid: {
              hoverable: true,
            },
            colors: vm.chartColors,
            tooltip: {
              show: true,
              snap: false,
              content(label, xval, yval) {
                return `<div class="flotTip"><b>${label}</b> expenses <b>${currencyFormat(
                  yval[0][1],
                  vm.currencyCode
                )} ( ${xval.toFixed(2)}%)</b></div>`;
              },
              shifts: {
                x: 20,
                y: 0,
              },
            },
            legend: {
              container: '#highExpensesLegendContainer',
            },
          },
          dataset: {
            all: {},
            gp: {},
            dental: {},
            optical: {},
            specialist: {},
            pharmacy: {},
            hospital: {},
          },
          selected: 'all',
        },
      },
      // Medical certificates (Single Employee)
      employeeMc: {
        show: false,
        loading: false,
        chart: {
          data: [
            {
              label: App.localize('MedicalCertificateTaken'),
              data: [],
            },
          ],
          options: {
            series: {
              bars: {
                show: true,
                barWidth: 0.7,
                fill: true,
                fillColor: {
                  colors: [
                    {
                      opacity: 0.8,
                    },
                    {
                      opacity: 0.8,
                    },
                  ],
                },
              },
            },
            xaxis: {
              ticks: [],
              position: 'bottom',
            },
            yaxis: {
              tickDecimals: 0,
              minTickSize: 1,
            },
            colors: vm.chartColors,
            grid: {
              color: '#999999',
              hoverable: true,
              clickable: true,
              tickColor: '#D4D4D4',
              borderWidth: 0,
            },
            legend: {
              show: false,
            },
            tooltip: {
              show: true,
              snap: false,
              content(label, _xval, yval) {
                return `<div class="flotTip"><b>${yval}</b> days of MC taken in <b>${label}</b></div>`;
              },
              shifts: {
                x: 20,
                y: 0,
              },
            },
          },
        },
        efficiencyScore: 1.0,
        mc: [],
      },

      // Medical expenditure (Single Employee)
      employeeExpenses: {
        show: false,
        loading: false,
        chart: {
          data: [
            {
              label: App.localize('Total'),
              data: [],
            },
          ],
          options: {
            series: {
              bars: {
                show: true,
                barWidth: 0.7,
                fill: true,
                fillColor: {
                  colors: [
                    {
                      opacity: 0.8,
                    },
                    {
                      opacity: 0.8,
                    },
                  ],
                },
              },
            },
            xaxis: {
              widget: null,
              ticks: [],
              position: 'bottom',
            },
            yaxis: {
              tickDecimals: 0,
              minTickSize: 1,
            },
            colors: vm.chartColors,
            grid: {
              color: '#999999',
              hoverable: true,
              clickable: true,
              tickColor: '#D4D4D4',
              borderWidth: 0,
            },
            legend: {
              show: false,
            },
            tooltip: {
              show: true,
              snap: false,
              content(label, _xval, yval) {
                return `<div class="flotTip"> <b>${currencyFormat(
                  yval.toFixed(2),
                  vm.currencyCode
                )}</b> spent in <b>${label}</b></div>`;
              },
              shifts: {
                x: 20,
                y: 0,
              },
            },
          },
        },
        selected: '0',
        dataset: {},
      },

      // Profile (Single Employee)
      employeeProfile: {
        show: false,
        loading: false,
        profile: {},
      },
    };

    // Region: Query Inputs

    // Date range
    vm.dateRangeOptions = _.map(vm.financialYears, (d) => {
      const opt = App.createDateRangePickerOptions();
      const minDate = moment([d, appSession.tenant.firstFinancialMonth - 1, 1]);
      const maxDate = moment.min(
        moment([d + 1, appSession.tenant.firstFinancialMonth - 1, 1]).subtract(1, 'days'),
        moment(opt.maxDate)
      );
      opt.minDate = moment(minDate);
      opt.min = moment(minDate);
      opt.maxDate = moment(maxDate);
      opt.max = moment(maxDate);
      opt.financialYear = d;
      return opt;
    });

    vm.dateRangeModel = {};

    vm.employeeId = $stateParams.employeeId;
    vm.employees = [];

    // Employee Type
    vm.employeeType = $stateParams.employeeType === null ? null : $stateParams.employeeType;
    vm.employeeActive = $stateParams.employeeActive === null ? null : $stateParams.employeeActive;
    vm.employeeState = $stateParams.employeeState == null ? null : $stateParams.employeeState;

    vm.subsidiaries = [];
    vm.departments = [];
    vm.cts = [];
    vm.activeServices = [];

    vm.permissions = {
      saleTransactions: abp.auth.isGranted('SaleTransactions'),
      patientTickets: abp.auth.isGranted('PatientTickets'),
      employees: abp.auth.isGranted('Corporate.Employees'),
      guaranteeLetters: abp.auth.isGranted('GuaranteeLetters.Corporate'),
    };

    vm.fyChanged = fyChanged;
    vm.lookupEmployees = lookupEmployees;
    vm.selectEmployee = selectEmployee;
    vm.lookupSubsidiaries = lookupSubsidiaries;
    vm.lookupSubsidiaryDepartments = lookupSubsidiaryDepartments;
    vm.showEmployeeProfile = showEmployeeProfile;
    vm.resetAllWidgets = resetAllWidgets;
    vm.refreshAllWidgets = refreshAllWidgets;
    vm.changeEmployeeExpensesData = changeEmployeeExpensesData;
    vm.resetDepartments = resetDepartments;
    vm.changeExpensesData = changeExpensesData;
    vm.showZeroMcEmployeeReport = showZeroMcEmployeeReport;
    vm.showZeroExpensesEmployeeReport = showZeroExpensesEmployeeReport;
    vm.setAnalyticsClinicMcSelected = setAnalyticsClinicMcSelected;
    vm.setAnalyticsHighExpensesSelected = setAnalyticsHighExpensesSelected;
    vm.checkIfServiceActive = checkIfServiceActive;

    init();

    function init() {
      getTenantActiveServices();
      vm.fyChanged();

      vm.lookupEmployees(null, $stateParams.employeeId);
      lookupCTs();

      vm.loading += 1;
      dashboardSvc
        .getStatistic()
        .success((data) => {
          vm.statistic = data.statistic;
          vm.creditFacility = data.creditFacility;
          vm.lockedAmount = data.lockedAmount;
        })
        .finally(() => {
          vm.loading -= 1;
        });

      if (hasRegionalSystemSupport) {
        commonLookupSvc.getTenantCurrencyCode().success((data) => {
          vm.currencyCode = data;
        });
      }

      vm.refreshAllWidgets();
      vm.lookupSubsidiaries(null, vm.requestParams.subsidiary);
      vm.lookupSubsidiaryDepartments(null, vm.requestParams.department);
    }
    

    function getTenantActiveServices() {
      vm.activeServices = [];

      vm.loading += 1;
      tenantSvc.getTenantActiveServices({})
        .success((data) => {
          vm.activeServices = data;
        }).finally(() => {
          vm.loading -= 1;
        });
    }

    function checkIfServiceActive(serviceId) {
      return vm.activeServices.some(x => serviceId === x.value);
    }

    function lookupSubsidiaries(search, id) {
      if (search || id) {
        commonLookupSvc
          .findSubsidiaries({
            filter: search,
            company: tenantId,
            maxResultCount: 5,
          })
          .success((data) => {
            vm.subsidiaries = data.items;
          })
          .finally(() => {
            if (!vm.subsidiaries.length) vm.subsidiary = null;
          });
      }
    }

    function lookupSubsidiaryDepartments(search, id) {
      if (search || id) {
        commonLookupSvc
          .findSubsidiaryDepartments({
            filter: search,
            company: tenantId,
            subsidiaryId: vm.requestParams.subsidiary,
            maxResultCount: 5,
          })
          .success((data) => {
            vm.departments = data.items;
          })
          .finally(() => {
            if (!vm.departments.length) vm.departments = null;
          });
      }
    }

    function lookupCTs() {
      dashboardSvc.findCTs().success((data) => {
        vm.cts = data.items;
      });
    }

    function showEmployeeProfile(id) {
      $modal.open({
        templateUrl: require('../employees/viewProfileModal.html'),
        controller: 'corporate.views.employees.viewProfileModal as vm',
        backdrop: 'static',
        resolve: {
          employeeId() {
            return id;
          },
        },
      });
    }

    function fyChanged() {
      const opt = _.find(vm.dateRangeOptions, { financialYear: vm.requestParams.financialYear });
      vm.dateRangeModel.startDate = moment(opt.minDate);
      vm.dateRangeModel.endDate = moment(opt.maxDate);
    }

    // Employee
    function lookupEmployees(search, id) {
      if (search || id) {
        dashboardSvc
          .findEmployees({
            employeeId: id,
            filter: search,
            maxResultCount: 10,
          })
          .success((data) => {
            vm.employees = data.items;
            if (!vm.employees.length) vm.employeeId = null;
          });
      } else {
        vm.employees = [];
      }
    }

    function selectEmployee() {
      // Update stateParams but disable reload.
      $state.transitionTo(
        'corporate.dashboard',
        {
          employeeId: vm.employeeId,
        },
        {
          notify: false,
        }
      );
      vm.refreshAllWidgets();
    }

    // EndRegion: Query Inputs

    // Region: Widget Reload functions

    function resetAllWidgets() {
      angular.forEach(vm.analytics, (value) => {
        value.show = false;
      });

      vm.analytics.causes.dataset = {
        gp: {},
        dental: {},
        optical: {},
        specialist: {},
        pharmacy: {},
        hospital: {},
      };
      vm.analytics.clinicMc.chart.dataset = {
        all: {},
        gp: {},
        dental: {},
        optical: {},
        specialist: {},
        pharmacy: {},
        hospital: {},
      };
      vm.analytics.clinicMc.chart.data = [];
      vm.analytics.highExpenses.chart.dataset = {
        all: {},
        gp: {},
        dental: {},
        optical: {},
        specialist: {},
        pharmacy: {},
        hospital: {},
      };
      vm.analytics.highExpenses.chart.data = [];
      vm.analytics.dayOff.data = [];
      vm.analytics.dayOff.total = 0;
      vm.analytics.employeeExpenses.dataset = {};
      vm.analytics.employeeExpenses.chart.data = [];
      vm.analytics.expenses.dataset = {};
      vm.analytics.employeeMc.chart.data = [];
      vm.analytics.employeeMc.efficiencyScore = 1.0;
      vm.analytics.employeeMc.mc = [];
      vm.analytics.employeeProfile.profile = [];
      vm.analytics.mc.chart.data = [];
      vm.analytics.mc.highest = [];
      vm.analytics.mc.lowest = [];
      vm.analytics.mc.employeeNotTaken = 0;
    }

    function refreshAllWidgets() {
      registerStateParams();
      vm.resetAllWidgets();

      if (vm.employeeId) {
        refreshEmployeeMcWidget();
        refreshEmployeeExpenseWidget();
        refreshEmployeeProfileWidget();
      } else {
        refreshMcNumberWidget();
        refreshMedicalExpenditureWidget();
      }

      refreshPopularMcDaysWidget();
      refreshClinicsWithMostMcIssuedWidget();
      refreshPopularClaimsWidget();
      refreshClinicWithHighExpensesWidget();
    }

    function registerStateParams() {
      let startDate = null;
      let endDate = null;
      if (vm.dateRangeModel.startDate) startDate = vm.dateRangeModel.startDate.format('YYYY-MM-DD');
      if (vm.dateRangeModel.endDate) endDate = vm.dateRangeModel.endDate.format('YYYY-MM-DD');

      let employeeActive = 0;
      if (vm.employeeActive === null) employeeActive = null;
      else if (vm.employeeActive) employeeActive = 1;

      $state.transitionTo(
        $state.current,
        {
          employeeId: vm.employeeId,
          employeeType: vm.employeeType,
          employeeActive,
          employeeState: vm.employeeState,
          startDate,
          endDate,
          subsidiary: vm.requestParams.subsidiary,
          department: vm.requestParams.department,
        },
        {
          notify: false,
        }
      );
    }

    function refreshEmployeeProfileWidget() {
      vm.analytics.employeeProfile.show = true;
      vm.analytics.employeeProfile.loading = true;
      employeeSvc
        .getEmployeeProfile({
          id: vm.employeeId,
        })
        .success((data) => {
          if (data) {
            data.benefits = [];

            if (!data.isDeleted && data.pools && data.pools.length) {
              _.each(data.pools, (d) => {
                let title = _.join(
                  _.map(d.benefitTypes, (bt) => {
                    if (bt.isCustom) {
                      let strCt = `CT${bt.id}`;
                      const idx = _.findIndex(data.ctNames, (ctn) => ctn.value === bt.id);
                      if (idx !== -1) strCt = data.ctNames[idx].name;
                      return strCt;
                    }
                    if (bt.id === 251) {
                      return App.localize('Hospitalization');
                    }

                    return App.localize(bt.name);
                  }),
                  '/'
                );

                // TODO: Better design? localisation required

                const isBudget = d.modifierType === 0 || d.modifierType === 2;
                let cycle = '';
                if (!d.isUnlimited) {
                  switch (d.modifierCycle) {
                    case 0:
                      cycle = `every ${d.interval} years `;
                      break;
                    case 1:
                      cycle = 'annual ';
                      break;
                    case 2:
                      cycle = 'monthly ';
                      break;
                    case 3:
                      cycle = 'daily ';
                      break;
                    case 4:
                      cycle = 'per visit ';
                      break;
                    // no default
                  }
                  if (d.modifierType === 2 || d.modifierType === 3) cycle += 'overdraft ';
                }
                if (d.serviceType === 8)
                  title += ` (${cycle}${
                    isBudget ? 'per employment budget' : 'visit per employment'
                  })`;
                else title += ` (${cycle}${isBudget ? 'budget' : 'visit'})`;

                const isEmptyExcess = d.isExcess && !d.employeePortion;
                if (!isEmptyExcess) {
                  data.benefits.push({
                    title,
                    isUnlimited: d.isUnlimited,
                    isExcess: d.isExcess,
                    isBudget,
                    limit: d.limit,
                    used: d.used,
                    locked: d.locked,
                    balance: d.balance,
                  });
                }
              });
            }
          }
          vm.analytics.employeeProfile.profile = data;
        })
        .finally(() => {
          vm.analytics.employeeProfile.loading = false;
        });
    }

    function refreshEmployeeMcWidget() {
      vm.analytics.employeeMc.show = true;

      const input = {
        maxResultCount: 5,
        type: vm.employeeType,
        isActive: vm.employeeActive,
        state: vm.employeeState,
        startDate: vm.dateRangeModel.startDate,
        endDate: vm.dateRangeModel.endDate,
        employeeId: vm.employeeId,
        subsidiary: vm.requestParams.subsidiary,
        department: vm.requestParams.department,
      };

      vm.analytics.employeeMc.loading += 1;
      dashboardSvc
        .getMedicalCertificate(input)
        .success((data) => {
          const { mcCountByMonth } = data;
          const monthlyMcData = [];
          const ticks = [];

          if (mcCountByMonth)
            for (let i = 0; i < mcCountByMonth.length; i += 1) {
              monthlyMcData.push({
                label: mcCountByMonth[i].month,
                data: [[i, mcCountByMonth[i].mcDays]],
              });
              ticks.push([i, mcCountByMonth[i].month]);
            }

          vm.analytics.employeeMc.chart.options.xaxis.ticks = ticks;
          vm.analytics.employeeMc.chart.data = monthlyMcData;
          vm.analytics.employeeMc.mcLimit = data.mcLimit;
          vm.analytics.employeeMc.mcTaken = data.mcTaken;
          vm.analytics.employeeMc.entitlementMode = data.entitlementMode;
        })
        .finally(() => {
          vm.analytics.employeeMc.loading -= 1;
        });

      vm.analytics.employeeMc.loading += 1;
      dashboardSvc
        .getEmployeeMc(input)
        .success((data) => {
          vm.analytics.employeeMc.mc = data.items;
        })
        .finally(() => {
          vm.analytics.employeeMc.loading -= 1;
        });
    }

    function refreshEmployeeExpenseWidget() {
      const widget = vm.analytics.employeeExpenses;
      widget.show = true;
      loadEmployeeExpensesData();
    }

    function loadEmployeeExpensesData() {
      const input = {
        type: vm.employeeType,
        isActive: vm.employeeActive,
        state: vm.employeeState,
        startDate: vm.dateRangeModel.startDate,
        endDate: vm.dateRangeModel.endDate,
        employeeId: vm.employeeId,
        clinicType: null,
        subsidiary: vm.requestParams.subsidiary,
        department: vm.requestParams.department,
      };

      vm.analytics.employeeExpenses.loading += 1;

      dashboardSvc
        .getEmployeeExpenditure(input)
        .success((d) => {
          for (let i = 0; i < d.items.length; i += 1) {
            const xyAxis = parseMonthlyClaimAmountXy(d.items[i].monthClaims);

            if (i === 0) {
              vm.analytics.employeeExpenses.chart.options.xaxis.ticks = xyAxis.x;
              vm.analytics.employeeExpenses.chart.data = xyAxis.y;
              vm.analytics.employeeExpenses.claim = d.items[i].claimAmount;
              vm.analytics.employeeExpenses.claimSummary = {
                maxClaim: d.items[i].maxClaim,
                averageClaim: d.items[i].averageClaim,
                minClaim: d.items[i].minClaim,
                claimsNumber: d.items[i].claimsNumber,
              };
            }

            vm.analytics.employeeExpenses.dataset[d.items[i].clinicType] = {
              claim: d.items[i].claimAmount,
              x: xyAxis.x,
              y: xyAxis.y,
              claimSummary: {
                maxClaim: d.items[i].maxClaim,
                averageClaim: d.items[i].averageClaim,
                minClaim: d.items[i].minClaim,
                claimsNumber: d.items[i].claimsNumber,
              },
            };
          }
        })
        .finally(() => {
          vm.analytics.employeeExpenses.loading -= 1;
        });
    }

    function changeEmployeeExpensesData(employeeExpenseType) {
      let id = employeeExpenseType;
      if (typeof id !== 'string') id = `${id}`;

      const widget = vm.analytics.employeeExpenses;
      const data = widget.dataset[id];
      if (!data) return;

      widget.selected = id;
      widget.chart.options.xaxis.ticks = data.x;
      widget.chart.data = data.y;
      widget.claim = data.claim;
      widget.claimSummary = data.claimSummary;
    }

    function resetDepartments() {
      vm.departments = [];
      vm.requestParams.department = null;
      vm.refreshAllWidgets();
    }

    function refreshMedicalExpenditureWidget() {
      const widget = vm.analytics.expenses;
      widget.show = true;
      loadMedicalExpenditureData();
    }

    function loadMedicalExpenditureData() {
      const input = {
        maxResultCount: 5,
        type: vm.employeeType,
        isActive: vm.employeeActive,
        state: vm.employeeState,
        startDate: vm.dateRangeModel.startDate,
        endDate: vm.dateRangeModel.endDate,
        clinicType: null,
        department: vm.requestParams.department,
        subsidiary: vm.requestParams.subsidiary,
      };

      vm.analytics.expenses.loading += 1;
      dashboardSvc
        .getMedicalExpenditure(input)
        .success((d) => {
          vm.currentYear = d.items[0].monthClaimsByYear[0].year;
          vm.previousYear = d.items[0].monthClaimsByYear[1].year;
          vm.currentYearExpense = {};
          vm.previousYearExpense = {};
          vm.currentYearExpenseAnalysis = {};
          vm.previousYearExpenseAnalysis = {};
          vm.currentYearAverageExpenses = {};
          vm.previousYearAverageExpenses = {};
          vm.months = ['Month'];
          vm.currentYearUniqueClaimantCount = {};
          vm.previousYearUniqueClaimantCount = {};
          const clinicTypeIndex = 0;

          for (let i = 0; i < d.items.length; i += 1) {
            const currentYearClaimByType = [vm.currentYear];
            const previousYearClaimByType = [vm.previousYear];

            const currentYearAverage = [vm.currentYear];
            const previousYearAverage = [vm.previousYear];

            const currentYearUniqueClaimantCount = [];
            const previousYearUniqueClaimantCount = [];

            for (let j = 0; j < 12; j += 1) {
              // push all 12 months of claims in a year into an array

              if (vm.months.length <= 12)
                vm.months.push(d.items[i].monthClaimsByYear[0].monthClaims[j].month);

              // Push claim amount by month

              currentYearClaimByType.push(d.items[i].monthClaimsByYear[0].monthClaims[j].claim);
              previousYearClaimByType.push(d.items[i].monthClaimsByYear[1].monthClaims[j].claim);

              // Calculate average claim amount by month
              // Verify if employee count is zero to prevent dividing zero

              if (d.items[i].monthClaimsByYear[0].monthClaims[j].claimantCount)
                currentYearAverage.push(
                  d.items[i].monthClaimsByYear[0].monthClaims[j].claim /
                    d.items[i].monthClaimsByYear[0].monthClaims[j].claimantCount
                );
              else currentYearAverage.push(0);

              if (d.items[i].monthClaimsByYear[1].monthClaims[j].claimantCount)
                previousYearAverage.push(
                  d.items[i].monthClaimsByYear[1].monthClaims[j].claim /
                    d.items[i].monthClaimsByYear[1].monthClaims[j].claimantCount
                );
              else previousYearAverage.push(0);

              // Push unique claimant count by month

              currentYearUniqueClaimantCount.push(
                d.items[i].monthClaimsByYear[0].monthClaims[j].claimantCount
              );
              previousYearUniqueClaimantCount.push(
                d.items[i].monthClaimsByYear[1].monthClaims[j].claimantCount
              );
            }

            // Monthly expense amount by clinic type

            vm.currentYearExpense[d.items[i].clinicType] = currentYearClaimByType;
            vm.previousYearExpense[d.items[i].clinicType] = previousYearClaimByType;

            // Monthly average expense amount by clinic type

            vm.currentYearAverageExpenses[d.items[i].clinicType] = currentYearAverage;
            vm.previousYearAverageExpenses[d.items[i].clinicType] = previousYearAverage;

            // Monthly unique claimant count by clinic type

            vm.currentYearUniqueClaimantCount[d.items[i].clinicType] =
              currentYearUniqueClaimantCount;
            vm.previousYearUniqueClaimantCount[d.items[i].clinicType] =
              previousYearUniqueClaimantCount;

            // Monthly expense analysis by clinic type

            vm.currentYearExpenseAnalysis[d.items[i].clinicType] =
              generateMedicalExpenseAnalysisData(
                d.items[i].employeeCountByMedicalExpenseByYear[0].employeeCountByMedicalExpense,
                false
              );
            vm.previousYearExpenseAnalysis[d.items[i].clinicType] =
              generateMedicalExpenseAnalysisData(
                d.items[i].employeeCountByMedicalExpenseByYear[1].employeeCountByMedicalExpense,
                true
              );

            if (i === 0) {
              vm.analytics.expenses.employeeNotTaken = d.items[i].employeeCountWithoutExpenditure;
              vm.analytics.expenses.highest = d.items[i].highest;
              vm.analytics.expenses.lowest = d.items[i].lowest.slice().reverse();
            }

            vm.analytics.expenses.dataset[d.items[i].clinicType] = {
              employeeNotTaken: d.items[i].employeeCountWithoutExpenditure,
              highest: d.items[i].highest,
              lowest: d.items[i].lowest.slice().reverse(),
            };
          }

          refreshExpenseGraph(clinicTypeIndex);
        })
        .finally(() => {
          vm.analytics.expenses.loading -= 1;
        });
    }

    function refreshExpenseGraph(clinicType) {
      const graphColors = {};
      graphColors[vm.previousYear] = '#86ffbd';
      graphColors[vm.currentYear] = '#1AB394';

      const objectMap = {};
      [objectMap[vm.currentYear]] = vm.currentYearExpenseAnalysis[clinicType].xAxis;
      [objectMap[vm.previousYear]] = vm.previousYearExpenseAnalysis[clinicType].xAxis;

      // Generate medical expenditure chart

      c3.generate({
        bindto: '#expenseChart',
        data: {
          x: 'Month',
          columns: [vm.months, vm.currentYearExpense[clinicType]],
          type: 'bar',
          colors: graphColors,
          order: null,
        },
        bar: {
          width: {
            ratio: 0.5,
          },
        },
        axis: {
          x: {
            type: 'category',
            tick: {
              multiline: false,
              rotate: -60,
            },
          },
        },
        grid: {
          x: {
            show: true,
          },
          y: {
            show: true,
          },
        },
        tooltip: {
          format: {
            value(value) {
              return App.localize(
                'CorporateDashboardExpTab_ExpensesSpentDescription',
                $filter('currencyFormat')(value, vm.currencyCode)
              );
            },
          },
        },
      });

      // Generate medical expenditure behaviour analysis chart (area graph in coordinate format)

      c3.generate({
        bindto: $element.find('#expenseAnalysisChart')[0],
        data: {
          xs: objectMap,
          colors: graphColors,
          columns: [
            vm.previousYearExpenseAnalysis[clinicType].xAxis,
            vm.currentYearExpenseAnalysis[clinicType].xAxis,

            vm.previousYearExpenseAnalysis[clinicType].yAxis,
            vm.currentYearExpenseAnalysis[clinicType].yAxis,
          ],
          type: 'area-step',
          order: null,
        },
        axis: {
          x: {
            tick: {
              values: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
            },
          },
        },
        line: {
          step: {
            type: 'step-after',
          },
        },
        grid: {
          x: {
            show: true,
          },
          y: {
            show: true,
          },
        },
        tooltip: {
          format: {
            title(x) {
              return `${Math.round(x * 100)}%`;
            },
            value(value) {
              return App.localize(
                'CorporateDashboardExpTab_ClaimsByEmployeeDescription',
                $filter('currencyFormat')(value, vm.currencyCode)
              );
            },
          },
        },
      });

      // Generate medical expenditure comparison chart

      c3.generate({
        bindto: $element.find('#expenseComparisonChart')[0],
        data: {
          x: 'Month',
          columns: [
            vm.months,
            vm.previousYearAverageExpenses[clinicType],
            vm.currentYearAverageExpenses[clinicType],
          ],
          type: 'bar',
          colors: graphColors,
          order: null,
        },
        bar: {
          width: {
            ratio: 0.5,
          },
        },
        axis: {
          x: {
            type: 'category',
            tick: {
              multiline: false,
              rotate: -60,
            },
          },
        },
        grid: {
          x: {
            show: true,
          },
          y: {
            show: true,
          },
        },
        tooltip: {
          format: {
            value(value, ratio, id, index) {
              const claimantCount =
                id === vm.currentYear
                  ? vm.currentYearUniqueClaimantCount[clinicType][index]
                  : vm.previousYearUniqueClaimantCount[clinicType][index];

              return App.localize(
                'CorporateDashboardExpTab_AverageExpensesSpentDescription',
                $filter('currencyFormat')(App.roundAmount(value), vm.currencyCode),
                claimantCount,
                $filter('currencyFormat')(App.roundAmount(value * claimantCount), vm.currencyCode)
              );
            },
          },
        },
      });
    }

    function changeExpensesData(expenseType) {
      let id = expenseType;
      if (typeof id !== 'string') id = `${id}`;

      refreshExpenseGraph(id);

      const widget = vm.analytics.expenses;
      const data = widget.dataset[id];
      if (!data) return;

      widget.selected = id;
      widget.employeeNotTaken = data.employeeNotTaken;
      widget.highest = data.highest;
      widget.lowest = data.lowest;
    }

    function generateMedicalExpenseAnalysisData(dataArray, isPreviousYear) {
      let total = 0;
      let start = 0;
      const dataObject = {
        xAxis: [],
        yAxis: [],
      };

      // sort expense amount by descending order

      dataArray.sort((a, b) => a.amount - b.amount);

      // Count total employee

      total = dataArray.reduce((prev, curr) => prev + curr.employeeCount, 0);

      // Pre-push year into x axis

      if (isPreviousYear) {
        dataObject.yAxis.push(vm.previousYear);
        dataObject.xAxis.push(`xAxis${vm.previousYear}`);
      } else {
        dataObject.yAxis.push(vm.currentYear);
        dataObject.xAxis.push(`xAxis${vm.currentYear}`);
      }

      // Prepare coordinate (start from 0)

      const X = [0];
      const Y = [0];

      for (let i = 0; i < dataArray.length; i += 1) {
        X.push((start += dataArray[i].employeeCount / total));
        Y.push(dataArray[i].amount);
      }

      // Separate data into one hundred rows in the graph in order to show tooltip for two
      // categories. The data passed must have the same X coordinate and Y coordinate length

      for (let i = 0; i < Y.length; i += 1) {
        const currentY = Y[i];

        if (typeof X[i + 1] !== 'undefined') {
          for (let j = X[i]; j < X[i + 1]; j += 0.01) {
            dataObject.xAxis.push(Math.round(j * 100) / 100);
            dataObject.yAxis.push(currentY);

            // If its at 0.99, push 1 as last element

            if (Math.round(j * 100) / 100 === 0.99) {
              dataObject.xAxis.push(1);
              dataObject.yAxis.push(currentY);
            }
          }
        }
      }

      // Clear out duplicated round off values at x axis

      dataObject.xAxis = dataObject.xAxis.filter((v, i, a) => a.indexOf(v) === i);

      return dataObject;
    }

    function parseMonthlyClaimAmountXy(dataArray) {
      const yaxis = [];
      const ticks = [];
      let i;

      for (i = 0; i < dataArray.length; i += 1) {
        yaxis.push({
          label: dataArray[i].month,
          data: [[i, dataArray[i].claim]],
        });
        ticks.push([i, dataArray[i].month]);
      }

      return { x: ticks, y: yaxis };
    }

    function refreshPopularClaimsWidget() {
      const widget = vm.analytics.causes;

      widget.show = true;
      widget.loading += 1;

      const input = {
        type: vm.employeeType,
        isActive: vm.employeeActive,
        state: vm.employeeState,
        startDate: vm.dateRangeModel.startDate,
        endDate: vm.dateRangeModel.endDate,
        employeeId: vm.employeeId,
        subsidiary: vm.requestParams.subsidiary,
        department: vm.requestParams.department,
      };

      dashboardSvc
        .getPopularClaims(input)
        .success((d) => {
          widget.excludedPrincipalCount = d.excludedPrincipalCount;

          _.forEach(widget.dataset, (v) => {
            v.data = [];
          });

          _.forEach(d.panels, (p) => {
            let dataset = null;
            switch (p.serviceType) {
              case 1:
                dataset = widget.dataset.gp;
                break;
              case 2:
                dataset = widget.dataset.dental;
                break;
              case 4:
                dataset = widget.dataset.optical;
                break;
              case 5:
                dataset = widget.dataset.specialist;
                break;
              case 6:
                dataset = widget.dataset.pharmacy;
                break;
              case 7:
                dataset = widget.dataset.hospital;
                break;
              // no default
            }

            if (dataset) {
              dataset.data = _.map(p.treatments, (t) => parseClaimRow(t));
            }
          });
        })
        .finally(() => {
          widget.loading -= 1;
        });
    }

    function parseClaimRow(val) {
      return {
        id: val.id,
        name: val.name,
        rate: val.percentage,
        chart: {
          data: [val.percentage, 1.0 - val.percentage],
          options: {
            fill: ['#1ab394', '#d7d7d7'],
          },
        },
      };
    }

    function refreshClinicsWithMostMcIssuedWidget() {
      const widget = vm.analytics.clinicMc;

      vm.analytics.clinicMc.show = true;

      loadClinicsWithMostMcIssuedData(widget, widget.chart.dataset.all, 'all', null);
      loadClinicsWithMostMcIssuedData(widget, widget.chart.dataset.gp, 'gp', 1);
      loadClinicsWithMostMcIssuedData(widget, widget.chart.dataset.dental, 'dental', 2);
      loadClinicsWithMostMcIssuedData(widget, widget.chart.dataset.optical, 'optical', 4);
      loadClinicsWithMostMcIssuedData(widget, widget.chart.dataset.specialist, 'specialist', 5);
      loadClinicsWithMostMcIssuedData(widget, widget.chart.dataset.pharmacy, 'pharmacy', 6);
      loadClinicsWithMostMcIssuedData(widget, widget.chart.dataset.hospital, 'hospital', 7);
    }

    function loadClinicsWithMostMcIssuedData(widget, data, selected, clinicType) {
      const input = {
        maxResultCount: 5,
        type: vm.employeeType,
        isActive: vm.employeeActive,
        state: vm.employeeState,
        startDate: vm.dateRangeModel.startDate,
        endDate: vm.dateRangeModel.endDate,
        employeeId: vm.employeeId,
        clinicType,
        subsidiary: vm.requestParams.subsidiary,
        department: vm.requestParams.department,
      };

      widget.loading += 1;
      dashboardSvc
        .getRankedClinicsByMc(input)
        .success((d) => {
          data.data = _.map(d.items, (val) => ({
            id: val.id,
            label: val.name,
            data: val.amount,
          }));
        })
        .finally(() => {
          widget.loading -= 1;
          if (selected === widget.chart.selected) {
            widget.chart.data = widget.chart.dataset[widget.chart.selected].data;
          }
        });
    }

    function refreshClinicWithHighExpensesWidget() {
      const widget = vm.analytics.highExpenses;

      vm.analytics.highExpenses.show = true;

      loadClinicWithHighExpensesData(widget, widget.chart.dataset.all, 'all', null);
      loadClinicWithHighExpensesData(widget, widget.chart.dataset.gp, 'gp', 1);
      loadClinicWithHighExpensesData(widget, widget.chart.dataset.dental, 'dental', 2);
      loadClinicWithHighExpensesData(widget, widget.chart.dataset.optical, 'optical', 4);
      loadClinicWithHighExpensesData(widget, widget.chart.dataset.specialist, 'specialist', 5);
      loadClinicWithHighExpensesData(widget, widget.chart.dataset.pharmacy, 'pharmacy', 6);
      loadClinicWithHighExpensesData(widget, widget.chart.dataset.hospital, 'hospital', 7);
    }

    function loadClinicWithHighExpensesData(widget, data, selected, clinicType) {
      const input = {
        maxResultCount: 5,
        type: vm.employeeType,
        isActive: vm.employeeActive,
        state: vm.empoyeeState,
        startDate: vm.dateRangeModel.startDate,
        endDate: vm.dateRangeModel.endDate,
        employeeId: vm.employeeId,
        clinicType,
        subsidiary: vm.requestParams.subsidiary,
        department: vm.requestParams.department,
      };

      widget.loading += 1;

      dashboardSvc
        .getRankedClinicsByExpenses(input)
        .success((d) => {
          data.data = _.map(d.items, (val) => ({
            id: val.id,
            label: val.name,
            data: val.amount,
          }));
        })
        .finally(() => {
          widget.loading -= 1;
          if (selected === widget.chart.selected) {
            widget.chart.data = widget.chart.dataset[widget.chart.selected].data;
          }
        });
    }

    function refreshPopularMcDaysWidget() {
      vm.analytics.dayOff.show = true;
      vm.analytics.dayOff.loading = true;
      dashboardSvc
        .getPopularMcDays({
          type: vm.employeeType,
          isActive: vm.employeeActive,
          state: vm.employeeState,
          startDate: vm.dateRangeModel.startDate,
          endDate: vm.dateRangeModel.endDate,
          employeeId: vm.employeeId,
          subsidiary: vm.requestParams.subsidiary,
          department: vm.requestParams.department,
        })
        .success((data) => {
          const arr = [];
          vm.analytics.dayOff.total = 0;

          for (let i = 0; i < data.days.length; i += 1) {
            const val = data.days[i];
            arr.push({
              name: val.day,
              rate: val.percentage,
              chart: {
                data: [val.percentage, 1.0 - val.percentage],
                options: {
                  fill: ['#1ab394', '#d7d7d7'],
                },
              },
            });
            vm.analytics.dayOff.total += val.amount;
          }

          vm.analytics.dayOff.data = arr;
        })
        .finally(() => {
          vm.analytics.dayOff.loading = false;
        });
    }

    function refreshMcNumberWidget() {
      vm.analytics.mc.show = true;

      const input = {
        maxResultCount: 5,
        type: vm.employeeType,
        isActive: vm.employeeActive,
        state: vm.employeeState,
        startDate: vm.dateRangeModel.startDate,
        endDate: vm.dateRangeModel.endDate,
        employeeId: vm.employeeId,
        subsidiary: vm.requestParams.subsidiary,
        department: vm.requestParams.department,
      };

      vm.analytics.mc.loading += 1;
      dashboardSvc
        .getMedicalCertificate(input)
        .success((data) => {
          vm.analytics.mc.totalEmployees = data.totalEmployees;
          vm.analytics.mc.employeeNotTaken = data.totalEmployeesWithoutTicket;
          vm.analytics.mc.highest = data.highest;
          vm.analytics.mc.lowest = data.lowest;

          // MC analysis chart

          employeeCountsByMcCount = data.employeeCountByMcCountByYear[0].employeeCountByMcCount;
          previousYearEmployeeCountsByMcCount =
            data.employeeCountByMcCountByYear[1].employeeCountByMcCount;

          employeeCountsByMcCountYear = data.employeeCountByMcCountByYear[0].year;
          employeeCountsByMcCountPreviousYear = data.employeeCountByMcCountByYear[1].year;

          employeeCountsByMcCount.sort((a, b) => a.days - b.days);
          previousYearEmployeeCountsByMcCount.sort((a, b) => a.days - b.days);

          const months = ['Month'];

          // prepare month label for x axis based on financial year

          _.forEach(data.mcCountByYear[0].mcCountByMonth, (value) => {
            months.push(value.month);
          });

          // New MC analysis chart

          const currentYearMcDaysAnalysis = [];
          const previousYearMcDaysAnalysis = [];

          _.forEach(employeeCountsByMcCount, (value) => {
            currentYearMcDaysAnalysis.push([value.days, value.employeeCount]);
          });

          _.forEach(previousYearEmployeeCountsByMcCount, (value) => {
            previousYearMcDaysAnalysis.push([value.days, value.employeeCount]);
          });

          // MC analysis behaviour chart

          vm.analytics.mc.chart.data.push({
            label: employeeCountsByMcCountYear,
            data: currentYearMcDaysAnalysis,
          });
          vm.analytics.mc.chart.data.push({
            label: employeeCountsByMcCountPreviousYear,
            data: previousYearMcDaysAnalysis,
          });

          // MC employee and MC days charts

          const { mcCountByMonth } = data.mcCountByYear[0];
          const previousYearMcCountByMonth = data.mcCountByYear[1].mcCountByMonth;

          const currentYear = data.mcCountByYear[0].year;
          const previousYear = data.mcCountByYear[1].year;

          // TODO: Standardise and make year global.

          const currentYearWorkforce = [currentYear];
          const currentYearMcDays = [currentYear];
          const currentYearMcDaysAverage = [currentYear];
          const previousYearMcDaysAverage = [previousYear];
          const totalEmployee = data.totalEmployees;

          for (let i = 0; i < mcCountByMonth.length; i += 1) {
            currentYearWorkforce.push(mcCountByMonth[i].employeeCount);
            currentYearMcDays.push(mcCountByMonth[i].mcDays);

            // Calculate average mc days per employee

            if (mcCountByMonth[i].employeeCount)
              currentYearMcDaysAverage.push(
                mcCountByMonth[i].mcDays / mcCountByMonth[i].employeeCount
              );
            else currentYearMcDaysAverage.push(0);

            if (previousYearMcCountByMonth[i].employeeCount)
              previousYearMcDaysAverage.push(
                previousYearMcCountByMonth[i].mcDays / previousYearMcCountByMonth[i].employeeCount
              );
            else previousYearMcDaysAverage.push(0);
          }

          // Generate workforce and MC days graph using C3JS.

          const graphColors = {};
          graphColors[previousYear] = '#86ffbd';
          graphColors[currentYear] = '#1AB394';

          c3.generate({
            bindto: $element.find('#workforceChart')[0],
            data: {
              x: 'Month',
              columns: [months, currentYearWorkforce],
              type: 'bar',
              colors: graphColors,
              order: null,
            },
            bar: {
              width: {
                ratio: 0.5,
              },
            },
            axis: {
              x: {
                type: 'category',
                tick: {
                  multiline: false,
                  rotate: -60,
                },
              },
            },
            grid: {
              x: {
                show: true,
              },
              y: {
                show: true,
              },
            },
            tooltip: {
              format: {
                value(value) {
                  return App.localize(
                    'CorporateDashboardMcTab_EmployeesTakeMCDescription',
                    value,
                    ((value / totalEmployee) * 100).toFixed(2)
                  );
                },
              },
            },
          });

          c3.generate({
            bindto: $element.find('#mcdaysChart')[0],
            data: {
              x: 'Month',
              columns: [months, currentYearMcDays],
              type: 'bar',
              colors: graphColors,
              order: null,
            },
            bar: {
              width: {
                ratio: 0.5,
              },
            },
            axis: {
              x: {
                type: 'category',
                tick: {
                  multiline: false,
                  rotate: -60,
                },
              },
            },
            grid: {
              x: {
                show: true,
              },
              y: {
                show: true,
              },
            },
            tooltip: {
              format: {
                value(value) {
                  return App.localize(
                    'CorporateDashboardMcTab_MCDaysTakenDescription',
                    App.roundAmount(value)
                  );
                },
              },
            },
          });

          // Generate average mc days chart

          c3.generate({
            bindto: $element.find('#averageMcDaysChart')[0],
            data: {
              x: 'Month',
              columns: [months, previousYearMcDaysAverage, currentYearMcDaysAverage],
              type: 'bar',
              colors: graphColors,
              order: null,
            },
            bar: {
              width: {
                ratio: 0.5,
              },
            },
            axis: {
              x: {
                type: 'category',
                tick: {
                  multiline: false,
                  rotate: -60,
                },
              },
            },
            grid: {
              x: {
                show: true,
              },
              y: {
                show: true,
              },
            },
            tooltip: {
              format: {
                value(value, ratio, id, index) {
                  const mcCount =
                    id === vm.currentYear
                      ? mcCountByMonth[index]
                      : previousYearMcCountByMonth[index];

                  return App.localize(
                    'CorporateDashboardMcTab_AverageMCDaysTakenDescription',
                    App.roundAmount(value),
                    mcCount.employeeCount,
                    mcCount.mcDays
                  );
                },
              },
            },
          });
        })
        .finally(() => {
          vm.analytics.mc.loading -= 1;
        });
    }

    function getEmployeeCountFromMcDays(day) {
      const currentYearEmployeeCount = _.find(
        employeeCountsByMcCount,
        (value) => value.days === day
      );
      const previousYearEmployeeCount = _.find(
        previousYearEmployeeCountsByMcCount,
        (value) => value.days === day
      );

      const employeeCountByYear = [
        {
          year: employeeCountsByMcCountYear,
          employeeCount: _.isNil(currentYearEmployeeCount)
            ? 0
            : currentYearEmployeeCount.employeeCount,
        },
        {
          year: employeeCountsByMcCountPreviousYear,
          employeeCount: _.isNil(previousYearEmployeeCount)
            ? 0
            : previousYearEmployeeCount.employeeCount,
        },
      ];
      return employeeCountByYear;
    }

    // EndRegion: Widget Reload functions

    function showZeroMcEmployeeReport() {
      $state.transitionTo('corporate.employeesReport', {
        employeeType: vm.employeeType,
        mcTakenMax: 0,
        startDate: vm.dateRangeModel.startDate.toDate(),
        endDate: vm.dateRangeModel.endDate.toDate(),
      });
    }

    function showZeroExpensesEmployeeReport() {
      $state.transitionTo('corporate.employeesReport', {
        employeeType: vm.employeeType,
        coveredAmountMax: 0,
        startDate: vm.dateRangeModel.startDate.toDate(),
        endDate: vm.dateRangeModel.endDate.toDate(),
      });
    }

    function setAnalyticsClinicMcSelected(value) {
      vm.analytics.clinicMc.chart.selected = value;
      vm.analytics.clinicMc.chart.data = vm.analytics.clinicMc.chart.dataset[value].data;
    }

    function setAnalyticsHighExpensesSelected(value) {
      vm.analytics.highExpenses.chart.selected = value;
      vm.analytics.highExpenses.chart.data = vm.analytics.highExpenses.chart.dataset[value].data;
    }

    // EndRegion: Widgets
  }
})();
