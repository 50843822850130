(() => {
  angular.module('app').controller('common.views.claims.index', ClaimsPageController);

  ClaimsPageController.$inject = [
    '$scope',
    '$stateParams',
    '$state',
    '$timeout',
    'abp.services.app.claim',
    '$uibModal',
    'Hms.Claims.ClaimStatus',
    'Hms.Claims.ClaimProcessorType',
    'moment',
    'abp.services.app.commonLookup',
    'appConstants',
    'Hms.PatientTicketBillings.BillStatus',
  ];

  function ClaimsPageController(
    $scope,
    $stateParams,
    $state,
    $timeout,
    claimSvc,
    $modal,
    enumClaimStatus,
    enumClaimProcessorType,
    moment,
    commonLookupSvc,
    appConstants,
    enumBillStatus
  ) {
    const vm = this;

    let panelTypeTenantId = -1;
    vm.loading = 0;
    vm.exporting = 0;
    vm.statLoading = 0;
    vm.isHost = App.isHost();
    vm.isCorporate = App.isCorporate();
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.countryCode = null;
    vm.unresolvedClaimsStats = {};
    vm.hasSupportPlan = true;
    vm.hasInpatientModule = true;

    vm.enums = {
      claimStatus: enumClaimStatus,
      claimProcessorType: enumClaimProcessorType,
      billStatus: enumBillStatus,
    };

    // Check for inpatient module enable for the application.
    vm.applicationHasInpatientModule = abp.setting.getBoolean('Hms.Feature.InpatientModule');

    vm.hasRegionalReimbursementSupport = abp.setting.getBoolean(
      'Hms.Feature.RegionalReimbursementSupport'
    );

    vm.permissions = {
      canViewClaimPolicies: abp.auth.isGranted('Corporate.ClaimPolicies'),
    };

    if (vm.isCorporate) {
      vm.hasInpatientModule = abp.features.isEnabled('HasInpatientModule');
    }

    vm.hasAccessFinanceTransactionData = abp.setting.getBoolean(
      'Hms.Feature.AccessFinanceTransactionData'
    );

    vm.requestParams = {
      claimNumber: $stateParams.claimNumber,
      employee: $stateParams.employee,
      status: $stateParams.status,
      ticketNumber: $stateParams.ticketNumber,
      corporateId: $stateParams.corporateId,
      subsidiaryId: $stateParams.subsidiaryId,
      serviceProvider: $stateParams.serviceProvider,
      clinicType: $stateParams.clinicType,
      skipCount: $stateParams.skipCount || 0,
      maxResultCount: $stateParams.maxResultCount || appConstants.grid.defaultPageSize,
      sorting: $stateParams.sorting,
    };
    vm.advancedFiltersAreShown = false;

    vm.dateRangeOptions = App.createDateRangePickerOptions();
    vm.submissionDateRangeModel = {
      startDate: $stateParams.submissionStartDate ? moment($stateParams.submissionStartDate) : null,
      endDate: $stateParams.submissionEndDate ? moment($stateParams.submissionEndDate) : null,
    };

    vm.receiptDateRangeModel = {
      startDate: $stateParams.receiptStartDate ? moment($stateParams.receiptStartDate) : null,
      endDate: $stateParams.receiptEndDate ? moment($stateParams.receiptEndDate) : null,
    };

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSizes,
      paginationPageSize: appConstants.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      resizable: true,
      columnDefs: [
        {
          name: App.localize('Actions'),
          enableSorting: false,
          width: 75,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
          allowCellFocus: false,
        },
        {
          displayName: App.localize('Country'),
          field: 'countryCode',
          width: 30,
          visible: vm.isHost && vm.hasRegionalReimbursementSupport,
          cellTemplate: 'countryTemplate',
        },
        {
          displayName: App.localize('ClaimNumber'),
          field: 'claimNumber',
          minWidth: 100,
        },
        {
          displayName: App.localize('Type'),
          field: 'claimType',
          cellTemplate: 'claimTypeTemplate',
          minWidth: 60,
          width: 60,
          enableSorting: false,
        },
        {
          displayName: App.localize('TicketDate'),
          field: 'patientTicketDate',
          minWidth: 85,
          enableSorting: false,
        },
        {
          displayName: App.localize('SubmissionDate'),
          field: 'creationDate',
          minWidth: 85,
          enableSorting: false,
        },
        {
          displayName: App.localize('ReceiptDate'),
          field: 'receiptDate',
          minWidth: 85,
        },
        {
          displayName: App.localize('Status'),
          field: 'processingDetail',
          minWidth: 120,
          cellTemplate: 'statusTemplate',
          enableSorting: false,
        },
        {
          displayName: App.localize('Aging'),
          field: 'processingDetail.aging',
          visible: vm.isHost,
          enableSorting: false,
          minWidth: 60,
          cellTemplate: 'agingTemplate',
        },
        {
          displayName: App.localize('ProcessedDate'),
          field: 'processedDate',
          visible: vm.isHost,
          minWidth: 120,
          cellTemplate: 'processedTimeTemplate',
          enableSorting: false,
        },
        {
          displayName: App.localize('TicketNumber'),
          field: 'patientTicketNumber',
          minWidth: 100,
          enableSorting: false,
        },
        {
          displayName: App.localize('ClaimantName'),
          field: 'patientName',
          minWidth: 120,
          cellTemplate: 'employeeTemplate',
          enableSorting: false,
        },
        {
          displayName: App.localize('Employee'),
          field: 'employeeName',
          minWidth: 120,
          cellTemplate: 'employeePrincipalTemplate',
          enableSorting: false,
        },
        {
          displayName: App.localize('Company'),
          field: 'corporateName',
          visible: vm.isHost,
          minWidth: 120,
          cellTemplate: 'companyTemplate',
          enableSorting: false,
        },
        {
          displayName: App.localize('Subsidiary'),
          field: 'subsidiaryName',
          minWidth: 120,
          enableSorting: false,
        },
        {
          displayName: App.localize('Department'),
          field: 'departmentName',
          minWidth: 120,
          enableSorting: false,
        },
        {
          displayName: App.localize('ServiceProvider'),
          field: 'clinicName',
          minWidth: 120,
        },
        {
          displayName: App.localize('Amount'),
          field: 'amount',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          minWidth: 100,
        },
        {
          displayName: App.localize('CoveredAmount'),
          field: 'coveredAmount',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          minWidth: 100,
          enableSorting: false,
        },
        {
          displayName: App.localize('EmployeePortion'),
          field: 'employeePortion',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          minWidth: 100,
          enableSorting: false,
        },
        {
          displayName: App.localize('CollectCash'),
          field: 'manualPayment',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          minWidth: 100,
          enableSorting: false,
        },
        {
          name: App.localize('PayoutStatus'),
          displayName: App.localize('PayoutStatus'),
          enableSorting: false,
          minWidth: 120,
          cellTemplate: 'payoutTemplate',
        },
        {
          name: App.localize('CorporateBilling'),
          displayName: App.localize('CorporateBilling'),
          enableSorting: false,
          minWidth: 120,
          cellTemplate: 'corporateBillingTemplate',
        },
        {
          displayName: App.localize('MedicalCertificate'),
          field: 'medicalCertificate',
          enableSorting: false,
          minWidth: 120,
          cellTemplate: 'mcTemplate',
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          vm.requestParams.sorting =
            callback.length && callback[0].field
              ? `${callback[0].field} ${callback[0].sort.direction}`
              : null;
          vm.getClaims();
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, vm.getClaims)
        );
      },
      data: [],
    };

    vm.panelTypes = [];
    vm.claimTypes = [];

    vm.getClaims = getClaims;
    vm.refreshClaimTypes = refreshClaimTypes;
    vm.refreshSubsidiaries = refreshSubsidiaries;
    vm.subsidiaryChanged = subsidiaryChanged;
    vm.exportToExcel = exportToExcel;
    vm.export = exportReport;

    init();

    function init() {
      if (!vm.isHost) getTenantCurrencyCode();
      vm.refreshClaimTypes();
      findClaimSubsidiaries();

      if (vm.isHost) getEligibleCountries();

      if (vm.isCorporate) {
        refreshStatistics();
      }

      $timeout(() => {
        vm.gridOptions.paginationCurrentPage = Math.floor(
          vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1
        );
      });

      // By default, processor filter is set to host manage (1) if on host side.
      if (vm.isHost) vm.requestParams.claimProcessorType = 1;
    }

    function getRequestInput(selectionFields) {
      const input = $.extend(
        {},
        vm.requestParams,
        formatDateRangeModel(),
        { exportFields: selectionFields },
        { countryCode: vm.countryCode }
      );
      registerStateParams();

      return input;
    }

    function formatDateRangeModel() {
      return {
        submissionStartDate: vm.submissionDateRangeModel.startDate
          ? vm.submissionDateRangeModel.startDate.format('YYYY-MM-DD')
          : null,
        submissionEndDate: vm.submissionDateRangeModel.endDate
          ? vm.submissionDateRangeModel.endDate.format('YYYY-MM-DD')
          : null,
        receiptStartDate: vm.receiptDateRangeModel.startDate
          ? vm.receiptDateRangeModel.startDate.format('YYYY-MM-DD')
          : null,
        receiptEndDate: vm.receiptDateRangeModel.endDate
          ? vm.receiptDateRangeModel.endDate.format('YYYY-MM-DD')
          : null,
      };
    }

    function getClaims() {
      const input = getRequestInput();

      vm.loading += 1;
      claimSvc
        .getClaims(input)
        .success((data) => {
          vm.gridOptions.totalItems = data.totalCount;
          vm.gridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function refreshClaimTypes() {
      const tenantId = vm.isHost ? vm.requestParams.corporateId : abp.session.tenantId;
      if (tenantId === panelTypeTenantId) return;
      panelTypeTenantId = tenantId;

      claimSvc.getClaimTypes(tenantId).success((data) => {
        vm.claimTypes = data.items;
      });
    }

    let subsidiaries = [];

    function findClaimSubsidiaries() {
      vm.loading += 1;
      claimSvc
        .findClaimSubsidiaries()
        .success((d) => {
          subsidiaries = d.items;
          vm.refreshSubsidiaries();
          if (vm.isHost) vm.corporates = _.unionBy(d.items, 'tenantId');
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function refreshSubsidiaries() {
      const tenantId = vm.isHost ? vm.requestParams.corporateId : abp.session.tenantId;
      vm.subsidiaries = tenantId ? _.filter(subsidiaries, { tenantId }) : subsidiaries;

      if (vm.requestParams.subsidiaryId) {
        const sub = _.find(vm.subsidiaries, { id: vm.requestParams.subsidiaryId });
        if (!sub) vm.requestParams.subsidiaryId = null;
      }

      vm.refreshClaimTypes();
    }

    function subsidiaryChanged() {
      if (vm.isHost) {
        if (vm.requestParams.subsidiaryId) {
          const sub = _.find(subsidiaries, { id: vm.requestParams.subsidiaryId });
          vm.requestParams.corporateId = sub ? sub.tenantId : null;
        }
      }
      vm.refreshClaimTypes();
    }

    function registerStateParams() {
      const dateRangeModel = formatDateRangeModel();

      $state.transitionTo(
        $state.current,
        {
          claimNumber: vm.requestParams.filter,
          employee: vm.requestParams.employee,
          status: vm.requestParams.status,
          ticketNumber: vm.requestParams.ticketNumber,
          corporateId: vm.requestParams.corporateId,
          subsidiaryId: vm.requestParams.subsidiaryId,
          serviceProvider: vm.requestParams.serviceProvider,
          clinicType: vm.requestParams.clinicType,
          submissionStartDate: dateRangeModel.submissionStartDate,
          submissionEndDate: dateRangeModel.submissionEndDate,
          receiptStartDate: dateRangeModel.receiptStartDate,
          receiptEndDate: dateRangeModel.receiptEndDate,
          skipCount: vm.requestParams.skipCount,
          maxResultCount: vm.requestParams.maxResultCount,
          sorting: vm.requestParams.sorting,
        },
        {
          notify: false,
        }
      );
    }

    function refreshStatistics() {
      // A claim statistics class.
      function ClaimStats(data) {
        // A count-amount class to store count and amount for stat.
        function CountAmount() {
          const that = this;

          this.count = 0;
          this.amount = 0;
          this.add = (cnt, amt) => {
            that.count += cnt;
            that.amount += amt;
          };
        }

        const that = this;

        this.raw = data;
        this.unresolved = {
          nonHospitalization: new CountAmount(),
          hospitalization: new CountAmount(),
        };
        this.resolved = {
          queuedForPayment: new CountAmount(),
          markedForPayment: new CountAmount(),
          percentage() {
            const queued = that.resolved.queuedForPayment.count;
            const marked = that.resolved.markedForPayment.count;
            const total = queued + marked;
            if (!total) return 1;

            return Math.floor((marked / total) * 100);
          },
        };

        _.each(data.statistics, (s) => {
          let countAmount;

          switch (s.status) {
            case 0:
            case 6:
              countAmount =
                s.type === 251
                  ? that.unresolved.hospitalization
                  : that.unresolved.nonHospitalization;
              break;
            case 1:
              countAmount = that.resolved.queuedForPayment;
              break;
            case 5:
              countAmount = that.resolved.markedForPayment;
              break;

            // no default
          }

          countAmount?.add(s.count, s.amount);
        });
      }

      vm.statLoading += 1;
      claimSvc
        .getClaimStatistics()
        .success((data) => {
          vm.stats = new ClaimStats(data);
          vm.hasSupportPlan = data.hasSupportPlan;
        })
        .finally(() => {
          vm.statLoading -= 1;
        });
    }

    function exportReport() {
      let exportTemplateName = '';
      let exportFields = [];

      vm.loading += 1;
      claimSvc
        .getClaimExport()
        .success((data) => {
          exportTemplateName = data.exportTemplateName;
          exportFields = data.exportFields;
        })
        .finally(() => {
          vm.loading -= 1;
          $modal
            .open({
              templateUrl: require('../common/selectionModal.html'),
              controller: 'common.views.common.selectionModal as vm',
              backdrop: 'static',
              resolve: {
                templateName() {
                  return exportTemplateName;
                },
                fields() {
                  return exportFields;
                },
                modalTitle() {
                  return App.localize('SelectExportFields');
                },
              },
            })
            .result.then((selectedFields) => {
              const option = getRequestInput(selectedFields);
              vm.exportToExcel(option);
            });
        });
    }

    function exportToExcel(option) {
      const params = option || getRequestInput();
      vm.exporting += 1;
      claimSvc
        .getClaimsToExcel(params)
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.exporting -= 1;
        });
    }

    function getTenantCurrencyCode() {
      vm.loading += 1;
      commonLookupSvc
        .getTenantCurrencyCode()
        .success((data) => {
          vm.currencyCode = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getEligibleCountries() {
      vm.loading += 1;
      commonLookupSvc
        .getEligibleCountries()
        .success((data) => {
          vm.countries = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
