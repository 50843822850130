(() => {
  angular.module('app').controller('clinic.views.doctors.index', DoctorsPageController);

  DoctorsPageController.$inject = ['$uibModal', 'abp.services.app.doctor'];

  function DoctorsPageController($modal, doctorSvc) {
    const vm = this;

    vm.loading = 0;
    vm.doctorName = null;
    vm.isInpatient = App.isInpatient();
    vm.isHospital = App.isHospital();

    vm.permissions = {
      create: abp.auth.isGranted('Clinic.Doctors.Create'),
      edit: abp.auth.isGranted('Clinic.Doctors.Edit'),
      delete: abp.auth.isGranted('Clinic.Doctors.Delete'),
    };

    vm.doctorGridOptions = {
      appScopeProvider: vm,
      columnDefs: [
        {
          enableSorting: false,
          name: App.localize('Actions'),
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
        },
        {
          displayName: App.localize('Name'),
          field: 'name',
          minWidth: 120,
        },
        {
          field: 'mmcRegistrationNumber',
          minWidth: 120,
          headerCellTemplate: 'registrationNumberHeader',
        },
        {
          displayName: App.localize('Permanent'),
          field: 'isPermanent',
          width: 100,
          cellTemplate: 'isPermanentTemplate',
          visible: !vm.isHospital,
        },
        {
          displayName: App.localize('CreationDate'),
          field: 'creationTime',
          cellFilter: "momentFormat: 'L'",
          minWidth: 100,
        },
      ],
      data: [],
    };

    vm.getDoctors = getDoctors;
    vm.createDoctor = createDoctor;
    vm.editDoctor = editDoctor;
    vm.deleteDoctor = deleteDoctor;

    init();

    function init() {
      getDoctors();

      if (!vm.permissions.edit && !vm.permissions.delete) {
        vm.doctorGridOptions.columnDefs.shift();
      }
    }

    function showEditModal(id) {
      const modal = $modal.open({
        templateUrl: require('./createOrEditModal.html'),
        controller: 'clinic.views.doctors.createOrEditModal as vm',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          doctorId: id,
          clinicLocationId: null,
        },
      });
      modal.result.then(() => {
        getDoctors();
      });
    }

    function getDoctors() {
      vm.loading += 1;
      doctorSvc
        .getDoctors({ name: vm.doctorName })
        .success((data) => {
          vm.doctorGridOptions.data = data.doctors.items;
          vm.accountOwnerCountryCode = data.accountOwnerCountryCode;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function createDoctor() {
      showEditModal();
    }

    function editDoctor(doctor) {
      showEditModal(doctor.id);
    }

    function deleteDoctor(doctor) {
      abp.message.confirm(
        App.localize('DoctorDeleteWarningMessage', doctor.name),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            doctorSvc.deleteDoctor({ id: doctor.id }).success(() => {
              getDoctors();
              abp.notify.success(App.localize('SuccessfullyDeleted'));
            });
          }
        }
      );
    }
  }
})();
