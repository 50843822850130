(() => {
  angular
    .module('app')
    .controller(
      'common.views.healthScreening.guaranteeLetters.details',
      GuaranteeLetterDetailsPageController
    );

  GuaranteeLetterDetailsPageController.$inject = [
    '$state',
    '$stateParams',
    'abp.services.app.healthScreeningGuaranteeLetter',
  ];

  function GuaranteeLetterDetailsPageController(
    $state,
    $stateParams,
    healthScreeningGuaranteeLetterSvc
  ) {
    const vm = this;

    vm.letterNumber = $stateParams.letterNumber;
    vm.timeZoneId = $stateParams.timeZoneId;
    vm.activeTab = 1;
    vm.breadcrumb = [];
    vm.pageTitle = '';
    vm.isPanel = App.isClinic();

    vm.navigateLetter = navigateLetter;

    init();

    function init() {
      initBreadcrumb();
    }

    // #region Retrieve breadcrumb details from URL.

    function initBreadcrumb() {
      if (vm.isPanel) {
        vm.breadcrumb.push({ name: App.localize('HealthScreening') });
        vm.breadcrumb.push({
          name: App.localize('GuaranteeLetters'),
          state: 'healthScreeningGuaranteeLetters',
        });
      } else {
        vm.breadcrumb.push({ name: App.localize('GuaranteeLetters') });
        vm.breadcrumb.push({
          name: App.localize('HealthScreening'),
          state: 'healthScreeningGuaranteeLetters',
        });
      }

      vm.breadcrumb.push({ name: `${App.localize('GuaranteeLetter')} ${vm.letterNumber}` });

      // Fallback for the breadcrumb title.

      vm.pageTitle = vm.breadcrumb.length > 0 ? vm.breadcrumb.slice(-1)[0].name : '';
    }

    // #endregion Retrieve breadcrumb details from URL.

    function navigateLetter(type) {
      vm.loading += 1;
      healthScreeningGuaranteeLetterSvc
        .getNextHealthScreeningGuaranteeLetter({
          id: $stateParams.letterNumber,
          type,
        })
        .success((data) => {
          if (data) {
            $state.go('healthScreeningGuaranteeLetterDetail', {
              letterNumber: data.letterNumber,
              employeeId: data.employeeId,
              timeZoneId: vm.timeZoneId
            });
          } else {
            const msg = type === 1 ? 'LatestLetterMessage' : 'EarliestLetterMessage';
            abp.notify.error(App.localize(msg));
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
