(function () {
    var componentId = 'hostOperationsDashboardExpiredUnusedCheckInsWidget';
    var app = angular.module('app');

    function controller($uibModal, operationsDashboardSvc, moment) {
        function init() {
            vm.refresh();
        }

        var vm = this;
        vm.loading = 0;
        vm.orderByCount = false;

        vm.$onInit = function () {
            vm.container.addWidget(this);
        };

        function getExpiredCheckIns(skipCount) {
            var input = {
                skipCount: skipCount,
                maxResultCount: app.consts.maxResultCount,
                startDate: vm.dateRange.startDate,
                endDate: vm.dateRange.endDate
            };

            vm.loading++;
            operationsDashboardSvc.getExpiredUnusedCheckIns(input)
                .success(function (data) {
                    vm.totalCount = data.totalCount;
                    vm.data = vm.data.concat(data.items);
                    vm.dataOrderByLastUpdate = _.orderBy(vm.data, function (d) { return d.lastCheckedOutTime; }, 'desc');
                    vm.toggleSorting(vm.orderByCount);
                }).finally(function () {
                    vm.loading--;
                });
        }

        vm.refresh = function () {
            vm.data = [];
            vm.showAll = false;
            getExpiredCheckIns(0);
        };

        vm.toggleShowData = function () {
            vm.showAll = !vm.showAll;
            showData();
        };

        function showData() {
            vm.displayRecords = vm.showAll ? vm.records : vm.records.slice(0, 5);
        }

        vm.dateRangeOptions = $.extend({}, App.createDateRangePickerOptions(), {
            eventHandlers: {
                'apply.daterangepicker': function (ev, picker) {
                    vm.refresh();
                }
            }
        });

        vm.dateRange = {
            startDate: moment().startOf('week'),
            endDate: moment().endOf('day')
        };

        vm.showDetail = function (record) {
            $uibModal.open({
                component: 'hostOperationsDashboardExpiredUnusedCheckInsDetail',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                    clinicLocation: function () {
                        return {
                            name: record.clinicLocationName,
                            clinicCode: record.clinicCode,
                            contactNumber: record.clinicLocationContactNumber,
                            checkIns: record.expiredUnusedCheckIns
                        };
                    }
                }
            });
        };

        vm.toggleSorting = function (orderByCount) {
            vm.orderByCount = orderByCount;
            vm.records = vm.orderByCount ? vm.data : vm.dataOrderByLastUpdate;
            showData();
        };

        init();
    }

    app.component(componentId, {
        require: {
            container: '^hostOperationsDashboardDashboardContainer'
        },
        templateUrl: require('/App/host/views/operationsDashboard/expiredUnusedCheckIns/widget.html'),
        controller: ['$uibModal', 'abp.services.app.operationsDashboard', 'moment', controller],
        controllerAs: 'vm'
    });
})();
