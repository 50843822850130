(() => {
  angular.module('app').component('corporateSubscriptionsDetailMovementsComponent', {
    templateUrl: require('./movements.component.html'),
    controller: MovementsController,
    controllerAs: 'vm',
    bindings: {
      subscriptionNumber: '<',
    },
  });

  MovementsController.$inject = ['$scope', '$timeout', 'abp.services.app.subscription', 'moment'];

  function MovementsController($scope, $timeout, subscriptionSvc, moment) {
    const vm = this;

    vm.loading = 0;

    vm.$onInit = onInit;
    vm.downloadReport = downloadReport;

    const viewOptions = {
      maxResultCount: 12,
      skipCount: 0,
      sorting: null,
    };

    vm.movementGridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: [12, 24, 60, 120],
      paginationPageSize: 12,
      paginationCurrentPage: 1,
      useExternalPagination: true,
      useExternalSorting: true,
      resizable: true,
      columnDefs: [
        {
          name: App.localize('Actions'),
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'downloadTemplate',
          allowCellFocus: false,
        },
        {
          displayName: App.localize('Date'),
          name: App.localize('Date'),
          cellTemplate: 'dateTemplate',
          minWidth: 150,
          width: '20%',
        },
        {
          displayName: App.localize('DocumentNumber'),
          field: 'fileName',
          minWidth: 100,
        },
        {
          displayName: App.localize('CreationTime'),
          name: App.localize('CreationTime'),
          field: 'creationTime',
          cellFilter: "momentFormat: 'L LT'",
          minWidth: 150,
          width: '20%',
        },
      ],
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          let sorting = '';
          for (let i = 0; i < callback.length; i += 1) {
            if (i) sorting += ', ';
            sorting += callback[i].field.concat(' ', callback[i].sort.direction);
          }
          vm.requestParams.sorting = sorting;
          vm.refreshMovements();
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, vm.refreshMovements)
        );
      },
      data: [],
    };

    init();

    function init() {
      $timeout(() => {
        vm.movementGridOptions.paginationCurrentPage = Math.floor(
          viewOptions.skipCount / viewOptions.maxResultCount + 1
        );
      });
    }

    function onInit() {
      getMovementTrackerReports();
    }

    function getMovementTrackerReports() {
      vm.loading += 1;
      subscriptionSvc
        .getCorporateReports({
          subscriptionNumber: vm.subscriptionNumber,
        })
        .success((data) => {
          vm.movementGridOptions.totalItems = data.length;
          vm.movementGridOptions.data = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function downloadReport(report) {
      window.open(report, '_blank');
    }
  }
})();
