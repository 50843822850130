(() => {
  angular.module('app').factory('languages', LanguagesFactory);

  LanguagesFactory.$inject = [];

  function LanguagesFactory() {
    let languages;

    init();

    // Duplicate the array to prevent array modification.
    return [...languages];

    function init() {
      if (languages) return;

      // Use global abp object because service proxy not loaded yet.
      abp
        .ajax({
          url: `${abp.appPath}api/services/app/commonLookup/getLanguages`,
          type: 'POST',
          async: false,
        })
        .done((result) => {
          languages = _.sortBy(result.items, 'name');
        });
    }
  }
})();
