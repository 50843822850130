(() => {
  angular.module('app').component('commonCorporateCreditStatisticsComponent', {
    templateUrl: require('./corporateCreditStatistics.component.html'),
    controller: CorporateCreditStatisticsController,
    controllerAs: 'vm',
    bindings: {
      statistic: '<',
      creditFacility: '<',
      lockedAmount: '<',
      hasSpecialistModule: '<',
      hasInpatientModule: '<',
      hasMobileNpr: '<',
      currencyCode: '<?',
      activeServices: '<',
      hasInsuranceModule: '<',
    },
  });

  CorporateCreditStatisticsController.$inject = ['$filter', '$sce'];

  function CorporateCreditStatisticsController($filter, $sce) {
    const vm = this;

    vm.currencyCode =
      _.isNil(vm.currencyCode) || vm.currencyCode === undefined
        ? abp.setting.get('Hms.General.CurrencyCode')
        : vm.currencyCode;
    vm.isCorporate = App.isCorporate();
    vm.isOpen = false;
    vm.loading = 0;
    vm.feature = {
      isLowBalance: false,
    };
    vm.widgetCss = {
      availableBalance: 'widget-green',
      onHoldAmount: 'widget-grey',
      availableBalanceContainer: 'widget-white-smoke',
      onHoldAmountContainer: 'widget-white-smoke',
    };

    vm.onHoldAmountNarrative = '';

    vm.toggleIsOpen = toggleIsOpen;
    vm.checkIfServiceActive = checkIfServiceActive;

    init();

    function init() {
      vm.onHoldAmountNarrative = $sce.trustAsHtml(getLockedAmountNarrative());

      styleWidgets();
    }

    function getLockedAmountNarrative() {
      // Prepare locked amount values by services.

      const gpLockedAmount = $filter('currencyFormat')(
        vm.lockedAmount.gpLockedAmount,
        vm.currencyCode
      );
      const dentalAndOpticalLockedAmount = $filter('currencyFormat')(
        vm.lockedAmount.dentalAndOpticalLockedAmount,
        vm.currencyCode
      );
      const specialistLockedAmount = $filter('currencyFormat')(
        vm.lockedAmount.specialistLockedAmount,
        vm.currencyCode
      );
      const approvedInitialGuaranteeLetterLockedAmount = $filter('currencyFormat')(
        vm.lockedAmount.inpatientLockedAmount,
        vm.currencyCode
      );

      // Prepare locked amount narrative.

      let lockedAmountNarrative =
        `${App.localize('OnHoldAmountInfo')}</br>` +
        `</br><span>${App.localize('OnHoldAmountInfoNarrative1', gpLockedAmount)}</span>` +
        `</br><span>${App.localize(
          'OnHoldAmountInfoNarrative2',
          dentalAndOpticalLockedAmount
        )}</span>`;

      if (vm.hasSpecialistModule) {
        lockedAmountNarrative += `</br><span>${App.localize(
          'OnHoldAmountInfoNarrative3',
          specialistLockedAmount
        )}</span>`;
      }

      if (vm.hasInpatientModule) {
        lockedAmountNarrative += `</br>${App.localize(
          'InpatientHospitalizationMaternity'
        )}: <span>${App.localize(
          'OnHoldAmountInpatientInfoNarrative1',
          approvedInitialGuaranteeLetterLockedAmount
        )}</span>`;
      }

      return lockedAmountNarrative;
    }

    function getPreAuthValue(category, benefitType, valueName) {
      if (!vm.creditFacility) return null;

      const categoryUsage = vm.creditFacility.usages[category];
      if (!categoryUsage) return null;

      const preAuthDetail = categoryUsage.preAuthorizationByServiceType[benefitType];
      if (!preAuthDetail) return null;

      return preAuthDetail[valueName];
    }

    // Style the widgets so that the colors conform to the values that they represent

    function styleWidgets() {
      vm.loading += 1;
      const widgetColor = {
        green: 'widget-green',
        yellow: 'widget-yellow',
        red: 'widget-red',
        grey: 'widget-grey',
        whiteSmoke: 'widget-white-smoke',
        lavenderBlush: 'widget-lavender-blush',
        linen: 'widget-linen',
        swansDown: 'widget-swans-down',
      };

      // Existing logic brought forward from previous widget implementation,
      // to confirm validity during backend implementation

      if (vm.creditFacility) {
        const escrowAmount = vm.creditFacility.collateralAmount;
        const availableBalance = vm.creditFacility.availableCredit;

        // Available balance

        const escrowAmount30Pct = escrowAmount * 0.3;
        const escrowAmount10Pct = escrowAmount * 0.1;

        if (availableBalance > escrowAmount30Pct) {
          vm.widgetCss.availableBalance = widgetColor.green;
          vm.widgetCss.availableBalanceContainer = widgetColor.swansDown;
        } else if (availableBalance > escrowAmount10Pct) {
          vm.widgetCss.availableBalance = widgetColor.yellow;
          vm.widgetCss.availableBalanceContainer = widgetColor.linen;
          vm.feature.isLowBalance = true;
        } else {
          vm.widgetCss.availableBalance = widgetColor.red;
          vm.widgetCss.availableBalanceContainer = widgetColor.lavenderBlush;
          vm.feature.isLowBalance = true;
        }

        const gpPreAuthCount = getPreAuthValue('general', '1', 'count') || 0;
        const dentalPreAuthCount = getPreAuthValue('general', '2', 'count') || 0;
        const opticalPreAuthCount = getPreAuthValue('general', '3', 'count') || 0;
        const preEmploymentPreAuthCount = getPreAuthValue('general', '4', 'count') || 0;
        const specialistPreAuthCount = getPreAuthValue('general', '5', 'count') || 0;
        const inpatientPreAuthCount =
          getPreAuthValue('inpatient', '7', 'count') +
            getPreAuthValue('inpatient', '8', 'count') +
            getPreAuthValue('inpatient', '10', 'count') || 0;

        vm.gpActiveCheckIns = App.localize('XNumberofActiveCheckIns', gpPreAuthCount);
        vm.dentalActiveCheckIns = App.localize('XNumberofActiveCheckIns', dentalPreAuthCount);
        vm.opticalActiveCheckIns = App.localize('XNumberofActiveCheckIns', opticalPreAuthCount);
        vm.preEmploymentActiveCheckIns = App.localize(
          'XNumberofActiveCheckIns',
          preEmploymentPreAuthCount
        );
        vm.specialistActiveCheckIns = App.localize(
          'XNumberofActiveCheckIns',
          specialistPreAuthCount
        );
        vm.inpatientActiveCheckIns = App.localize('XNumberofActiveCheckIns', inpatientPreAuthCount);
      }

      vm.gpPreAuthAmount = getPreAuthValue('general', '1', 'amount') || 0;
      vm.dentalPreAuthAmount = getPreAuthValue('general', '2', 'amount') || 0;
      vm.opticalPreAuthAmount = getPreAuthValue('general', '3', 'amount') || 0;
      vm.preEmploymentPreAuthAmount = getPreAuthValue('general', '4', 'amount') || 0;
      vm.specialistPreAuthAmount = getPreAuthValue('general', '5', 'amount') || 0;
      vm.inpatientPreAuthAmount =
        getPreAuthValue('inpatient', '7', 'amount') +
          getPreAuthValue('inpatient', '8', 'amount') +
          getPreAuthValue('inpatient', '10', 'amount') || 0;
      vm.loading -= 1;
    }

    // EndRegion: Widgets

    function toggleIsOpen() {
      vm.isOpen = !vm.isOpen;
    }

    function checkIfServiceActive(serviceId) {
      return vm.activeServices.some((x) => serviceId === x.value);
    }
  }
})();
