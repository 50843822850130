(() => {
  angular.module('app').component('manualTransactionSetupTermsAndConditions', {
    templateUrl: require('./termsAndConditions.component.html'),
    controller: ManualTransactionSetupTermsAndConditionsController,
    controllerAs: 'vm',
    bindings: {
      accountOwnerId: '<',
      countryCode: '<',
    },
  });

  ManualTransactionSetupTermsAndConditionsController.$inject = [
    '$uibModal',
    'financeConstants',
    'abp.services.finance.termsAndConditions'
  ];

  function ManualTransactionSetupTermsAndConditionsController(
    $uibModal,
    financeConstants,
    termsAndConditionsSvc
  ) {
    const vm = this;

    vm.loading = 0;

    vm.createEditTermsAndConditions = createEditTermsAndConditions;
    vm.deleteTermsAndConditions = deleteTermsAndConditions;

    vm.termsAndConditionsGridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: financeConstants.grid.defaultPageSizes,
      paginationPageSize: financeConstants.grid.defaultPageSize,
      paginationCurrentPage: 1,
      useExternalPagination: true,
      useExternalSorting: true,
      columnDefs: [
        {
          name: App.localize('Actions'),
          displayName: 'Actions',
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'termsAndConditionsActionTemplate',
          allowCellFocus: false,
        },
        {
          displayName: App.localize('Name'),
          field: 'name',
          minWidth: 120,
        },
        {
          displayName: App.localize('Details'),
          field: 'details',
          minWidth: 120,
        },
      ],
      data: [],
    };

    init();

    function init() {
      getTermsAndConditions();
    }

    function getTermsAndConditions() {
      vm.loading += 1;
      termsAndConditionsSvc.getTermsAndConditions({ id: vm.accountOwnerId })
        .success((data) => {
          vm.termsAndConditionsGridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function createEditTermsAndConditions(termsAndConditions) {
      termsAndConditions.accountOwnerId = vm.accountOwnerId;

      $uibModal
        .open({
          templateUrl: require('./createEditTermsAndConditions.modal.html'),
          controller: 'manualTransactionSetupCreateEditTermsAndConditions as vm',
          backdrop: 'static',
          resolve: {
            isCreate: () => termsAndConditions.id == null,
            termsAndConditions: () => termsAndConditions,
          },
        })
        .result.then(() => {
          // Refresh terms and conditions listing.

          getTermsAndConditions();
        });
    }

    function deleteTermsAndConditions(termsAndConditions) {
      abp.message.confirm(
        App.localize('TermsAndConditionsDeleteWarningMessage', termsAndConditions.name),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            termsAndConditionsSvc
              .deleteTermsAndConditions({ id: termsAndConditions.id })
              .success(() => {
                abp.notify.success(App.localize('SuccessfullyDeleted'));
              })
              .finally(() => {
                // Refresh terms and conditions listing.

                getTermsAndConditions();
              });
          }
        }
      );
    }
  }
})();
