(function () {
    var controllerId = 'host.views.operationsDashboard.insuranceClaims.createPrePostClaimsModal.createPrePostClaimsModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$uibModalInstance', 'abp.services.app.insurerClaim', '$sce', 'Hms.Services.ServiceType', 'Hms.InsurerClaims.InsurerClaimEnums.InsurerClaimSubCategory', '$timeout',
        function ($scope, $uibModalInstance, insurerClaimAppService, $sce, enumServiceType, enumInsurerClaimSubCategory, $timeout) {
            function init() {
                if (vm.hasPrePostInsurerClaimDetection) {
                    $timeout(function () {
                        vm.gridOptions.paginationCurrentPage = Math.floor(vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1);
                    });
                }
            }

            var vm = this;
            vm.loading = 0;
            vm.saving = 0;
            vm.tickets = [];
            vm.chosenTicket = null;
            vm.selectedTickets = [];
            vm.selectedTicketsInput = [];
            vm.selectedTicketRelatedAdmissions = [];
            vm.failedTickets = [];
            vm.tab = 0;
            vm.selectedRows = [];
            vm.addSelectedTickets = addSelectedTickets;

            vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
            vm.hasPrePostInsurerClaimDetection = abp.setting.getBoolean('Hms.Feature.PrePostInsurerClaimDetection');

            vm.enums = {};
            vm.enums.serviceType = enumServiceType;
            vm.enums.insurerClaimSubCategory = enumInsurerClaimSubCategory;

            vm.requestParams = {
                filter: null,
                skipCount: 0,
                maxResultCount: app.consts.grid.defaultPageSize,
            }

            vm.gridOptions = {
                appScopeProvider: vm,
                paginationPageSizes: app.consts.grid.defaultPageSizes,
                paginationPageSize: app.consts.grid.defaultPageSize,
                paginationCurrentPage: 0,
                useExternalPagination: true,
                useExternalSorting: true,
                enableSelectAll: true,
                multiSelect: true,
                isRowSelectable: function () {
                    return true;
                },
                columnDefs: [{
                    name: App.localize('TicketNumber'),
                    field: 'ticketNumber',
                    enableSorting: false,
                    width: 200
                }, {
                    displayName: App.localize('TicketDate'),
                    field: 'ticketCreationDate',
                    cellFilter: 'momentFormat: \'L LT\'',
                    enableSorting: false,
                    minWidth: 100
                }, {
                    displayName: App.localize('Patient'),
                    field: 'patientName',
                    enableSorting: false,
                    minWidth: 100
                }, {
                    displayName: App.localize('Diagnosis'),
                    field: 'diagnosis',
                    enableSorting: false,
                    minWidth: 100
                }, {
                    displayName: App.localize('Panel'),
                    field: 'panelLocationName',
                    enableSorting: false,
                    minWidth: 100
                }, {
                    displayName: App.localize('Doctor'),
                    field: 'doctorName',
                    enableSorting: false,
                    minWidth: 100
                }, {
                    displayName: App.localize('Amount'),
                    field: 'ticketAmount',
                    cellFilter: 'currencyFormat: row.entity.currencyCode',
                    enableSorting: false,
                    minWidth: 100
                }, {
                    displayName: App.localize('ServiceType'),
                    field: 'serviceType',
                    cellTemplate: 'serviceTypeTemplate',
                    enableSorting: false,
                    minWidth: 100
                }, {
                    displayName: App.localize('RelatedHospitalizationTicket'),
                    field: 'relatedHospitalizationTicketNumber',
                    enableSorting: false,
                    minWidth: 200
                }, {
                    displayName: App.localize('SuspectedClaimType'),
                    field: 'suspectedClaimType',
                    enableSorting: false,
                    minWidth: 200
                }],
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                    $scope.gridApi.core.on.sortChanged($scope, function (scope, callback) {
                        vm.requestParams.sorting = callback.length && callback[0].field ? callback[0].field + ' ' + callback[0].sort.direction : null;
                        vm.selectedRows = null;
                        getPossiblePrePostInsurerClaims();
                    });
                    gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
                        vm.requestParams.skipCount = (newPage - 1) * pageSize;
                        vm.requestParams.maxResultCount = pageSize;
                        vm.selectedRows = null;
                        getPossiblePrePostInsurerClaims();
                    });
                    gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                        vm.selectedRows = this.selection.getSelectedRows();
                    });
                    gridApi.selection.on.rowSelectionChangedBatch($scope, function (rows) {
                        vm.selectedRows = this.selection.getSelectedRows();
                    });
                },
                data: []
            };

            // Lookup ticket based on input ticket number.

            vm.lookupTickets = function (search) {
                if (search) {
                    insurerClaimAppService.findValidPrePostPatientTickets({
                        filter: search,
                        maxResultCount: 10
                    }).success(function (data) {
                        vm.tickets = data.items;
                        if (!vm.tickets.length) {
                            vm.ticketId = null;
                        }
                    });
                }
                else {
                    vm.tickets = [];
                }
            };

            // Add ticket to selected tickets list.

            vm.selectTicket = function () {
                var isDuplicate = checkDuplicateTicket(vm.chosenTicket.id);
                if (!isDuplicate) {
                    vm.selectedTickets.push(vm.chosenTicket);
                }
            }

            // Check that selected tickets cannot be duplicate.

            function checkDuplicateTicket(ticketId) {
                for (let ticket of vm.selectedTickets) {
                    if (ticket.id == ticketId) {
                        return true;
                    }
                }
                return false;
            }

            // Remove ticket from selected ticket list.

            vm.removeTicket = function (index) {
                if (index < vm.selectedTickets.length) {
                    vm.selectedTickets.splice(index, 1);
                }
            };

            // Change to confirm related admissions tab.

            vm.next = function () {
                if (vm.loading) {
                    return;
                }

                // Check form validation.

                var form = $scope.createPrePostInsuranceClaimsForm;
                if (!App.isFormValid(form)) {
                    return;
                }

                // Get all selected ticket ids and their respective associated
                // insurer claim sub category type into a list.

                vm.selectedTicketsInput = [];
                _.forEach(vm.selectedTickets, function (item) {
                    var selectedTicket = {
                        id: item.id,
                        insurerClaimSubCategory: item.insurerClaimSubCategory
                    };
                    vm.selectedTicketsInput.push(selectedTicket);
                });

                // Get all possible related admissions for each selected tickets.

                vm.loading++;
                insurerClaimAppService.getValidRelatedAdmissionsForSelectedTickets({
                    selectedTickets: vm.selectedTicketsInput
                }).success(function (data) {
                    vm.selectedTicketRelatedAdmissions = data;
                }).finally(function (e) {
                    vm.loading--;
                });

                vm.tab++;
            }

            // Return to select GP/specialist tickets tab.

            vm.back = function () {
                vm.tab--;
            }

            // Get related admission narrative message.

            vm.getRelatedAdmissionsNarrativeMessage = function (selectedTicket) {
                var narrativeMessage = null;
                switch (selectedTicket.insurerClaimSubCategory) {
                    case 2: // Pre-hospitalization.
                        narrativeMessage =
                            `${App.localize('OnlyAdmissionsWithinNarrativeAfter', selectedTicket.admissionDaysLimit)}.`;
                        break;
                    case 3: // Post-hospitalization.
                        narrativeMessage =
                            `${App.localize('OnlyAdmissionsWithinNarrativeBefore', selectedTicket.admissionDaysLimit)}.`;
                        break;
                }
                return $sce.trustAsHtml(narrativeMessage);
            }

            // Get no existing addmision narrative message.

            vm.getNoExistingAdmissionMessage = function (selectedTicket) {
                var narrativeMessage = null;
                switch (selectedTicket.insurerClaimSubCategory) {
                    case 2: // Pre-hospitalization.
                        narrativeMessage = `${App.localize('NoExistingAdmissionsWithinNarrativeAfter', selectedTicket.admissionDaysLimit)}.`;
                        break;
                    case 3: // Post-hospitalization.
                        narrativeMessage = `${App.localize('NoExistingAdmissionsWithinNarrativeBefore', selectedTicket.admissionDaysLimit)}.`;
                        break;
                }
                return $sce.trustAsHtml(narrativeMessage);
            }

            // Check that only one related admission ticket can be selected at a time per GP/specialist ticket.

            vm.selectSingleRelatedAdmissionTicket = function (selectedRelatedAdmissionTicket, relatedAdmissionTickets) {
                _.forEach(relatedAdmissionTickets, function (relatedAdmissionTicket) {
                    if (relatedAdmissionTicket.ticketNumber != selectedRelatedAdmissionTicket.ticketNumber) {
                        relatedAdmissionTicket.isSelected = false;
                    }
                });
            }

            // Get a list of patient tickets that are potential pre/post insurer claims.

            function getPossiblePrePostInsurerClaims() {
                vm.loading++;
                insurerClaimAppService.getPossiblePrePostInsurerClaims(vm.requestParams)
                    .success(function (data) {
                        vm.gridOptions.totalItems = data.totalCount;
                        vm.gridOptions.data = data.items;
                    }).finally(function () {
                        vm.loading--;
                    });
            }

            // Add selected pre/post insurer claims for processing.

            function addSelectedTickets() {
                _.forEach(vm.selectedRows, function (row) {
                    var isDuplicate = checkDuplicateTicket(row.id);
                    if (!isDuplicate) {
                        vm.selectedTickets.push(row);
                    }
                });
            }

            // Save selected tickets as insurance claims.

            vm.save = function () {
                if (vm.saving) {
                    return;
                }

                // Create insurance claims for each selected pre/post patient ticket.

                vm.loading++;
                insurerClaimAppService.createPrePostInsuranceClaims(vm.selectedTicketRelatedAdmissions).success(function (data) {
                    // Display success message if there are any insurance claim created.

                    if (data.insuranceClaimCreated)
                        abp.notify.info(App.localize('SuccessfullySaved'));

                    // Display tickets that are not created.

                    vm.failedTickets = data.failedTickets;
                    if (vm.failedTickets.length > 0) {
                        vm.tab++;
                    }
                    else {
                        $uibModalInstance.close();
                    }
                }).finally(function (e) {
                    vm.loading--;
                });
            }

            vm.close = function () {
                $uibModalInstance.close();
            };

            init();
        }
    ]);
})();
