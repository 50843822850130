(function () {
  var controllerId = 'clinic.views.healthScreeningCheckIn';
  var app = angular.module('app');

  app.controller(controllerId, ['$state', '$filter', '$stateParams', 'abp.services.app.commonLookup', 'lookupModal', 'appSession',
    '$uibModal', 'abp.services.app.clinicEmployee',
    function ($state, $filter, $stateParams, commonLookupSvc, lookupModal, appSession,
      $uibModal, clinicEmployeeSvc) {
      function init() {
        commonLookupSvc.getDefaultClinicLocation()
          .success(function (data) {
            setLocation(data);
            vm.patientNotFoundMessage = App.localize("PatientNotFoundMessage3", data.accountOwnerContactNumber);
          }).finally(function () {
            if (!vm.clinic.clinicLocationId) {
              vm.changeLocation();
            } else {
              vm.getEmployees();
            }
          });
        
        getTimeZoneId();
      }
      var vm = this;
      vm.loading = 0;
      vm.isReadOnly = true;
      vm.filterText = $stateParams.filterText || '';
      vm.searched = '';
      vm.hasClinicLocation = appSession.user.clinicLocationId;
      vm.patientList = null;
      vm.patientPendingSubmissionsList = null;
      vm.serviceType = 9; // As this page will only be used by health screening, thus the default service type will be 9.
          
      vm.hasSearchEmployeesForPanelEnhancement = abp.setting.getBoolean('Hms.Feature.SearchEmployeesForPanelEnhancement');

      function setLocation(location) {
        vm.clinic = {};

        vm.clinic.clinicLocationId = parseInt(location.value, 10) || null;
        vm.clinic.name = location.name;
        vm.clinic.address = location.address;
        vm.clinic.contactNumber = location.contactNumber;
      }

      vm.getEmployees = function () {
        var filterText = vm.filterText;
        $state.transitionTo($state.current, {
          filterText: filterText
        }, {
          notify: false
        });

        vm.loading++;
        if (vm.hasSearchEmployeesForPanelEnhancement) {
          clinicEmployeeSvc.getEmployeesForHealthScreening({
            filter: filterText,
            clinicLocationId: vm.clinic.clinicLocationId,
            serviceType: vm.serviceType
          }).success(function (data) {
            vm.patientList = [];
            vm.patientPendingSubmissionsList = [];

            processGetEmployeesOutput(data.items);
            vm.searched = vm.filterText;
          }).finally(function (e) {
            vm.loading--;
          });
        } else {
          clinicEmployeeSvc.getEmployeesByNationalId({
            filter: filterText,
            clinicLocationId: vm.clinic.clinicLocationId,
            serviceType: vm.serviceType
          }).success(function (data) {
            vm.patientList = [];
            vm.patientPendingSubmissionsList = [];

            processGetEmployeesOutput(data.items);
            vm.searched = vm.filterText;
          }).finally(function (e) {
            vm.loading--;
          });
        }
      }

      function processGetEmployeesOutput(data) {
        var allEmployees = data || [];

        var pendingSubmissions = [];
        var newEmployees = [];

        _.each(allEmployees, function (employee) {
          if (employee.checkedInTime &&
            employee.pendingClaimSubmissionGLs &&
            employee.pendingClaimSubmissionGLs.length > 0) {
            var lastPendingClaimSubmissionGL = employee.pendingClaimSubmissionGLs[employee.pendingClaimSubmissionGLs.length - 1];
            employee.listingTime = lastPendingClaimSubmissionGL.approvalTime < employee.checkedInTime ?
              lastPendingClaimSubmissionGL.approvalTime :
              employee.checkedInTime;

            pendingSubmissions.push(employee);
            var letter = _.find(employee.guaranteeLetters, ['letterNumber', vm.filterText]);
            if (_.camelCase(vm.filterText).toUpperCase() === employee.nationalId || letter) {
              newEmployees.push(employee);
            }
          } else {
            newEmployees.push(employee);
          }
        });

        _.orderBy(pendingSubmissions, ['listingTime'], ['desc']);

        vm.patientList = newEmployees;
        vm.patientPendingSubmissionsList = pendingSubmissions;
      }

      vm.changeLocation = function () {
        lookupModal.open({
          title: App.localize('SelectPanelLocation'),
          serviceMethod: commonLookupSvc.findClinicLocations,
          callback: function (location) {
            setLocation(location);
            vm.patientNotFoundMessage = App.localize("PatientNotFoundMessage3", location.accountOwnerContactNumber);

            vm.getEmployees();
          }
        });
      };

      // Select GL from list for check in.
      vm.showCheckInModal = function (employee) {
        vm.employee = employee;
        employee.guaranteeLetters = _.filter(employee.guaranteeLetters, function (k) {
          return k.status !== 3;
        });
        $uibModal.open({
          templateUrl: require('/App/clinic/views/healthScreening/checkIn/applyGuaranteeLetterModal.html'),
          controller: 'clinic.views.healthScreeningApplyGuaranteeLetterModal as vm',
          backdrop: 'static',
          resolve: {
            employee: function () {
              return employee;
            },
            clinic: function () {
              return vm.clinic;
            }
          }
        }).result.then(function (data) {
          // Check-in using this GL number.
          if (data && data.letterNumber) {
            vm.letterNumber = data.letterNumber;
            checkIn(employee);
          }
        });
      };

      function checkIn(employee) {
        vm.loading++;
        clinicEmployeeSvc.checkIn({
          employeeId: employee.id,
          clinicLocationId: vm.clinic.clinicLocationId,
          guaranteeLetterNumber: vm.letterNumber
        }).success(function (data) {
          getCheckInDetail(data);
          vm.getEmployees();
        }).finally(function () {
          vm.loading--;
        });
      }

      vm.cancel = function (employee, uniqueId) {
        $uibModal.open({
          templateUrl: require('/App/clinic/views/healthScreening/checkIn/checkOutModal.html'),
          controller: 'clinic.views.healthScreeningCheckOutModal as vm',
          backdrop: 'static'
        }).result.then(function (reason) {
          if (reason) {
            employee.cancellationRemarks = reason;
            checkOut(employee, uniqueId);
          }
        });
      };

      function checkOut(employee, uniqueId) {
        vm.loading++;
        clinicEmployeeSvc.checkOut({
          employeeId: employee.id,
          clinicLocationId: vm.clinic.clinicLocationId,
          cancellationRemarks: employee.cancellationRemarks,
          guaranteeLetterUniqueId: uniqueId
        }).success(function (data) {
          vm.getEmployees();
          abp.notify.info(App.localize('PatientXCheckedOut', employee.name));
          swal.close();
        }).finally(function () {
          vm.loading--;
        });
      }

      function getCheckInDetail(id) {
        vm.loading++;
        clinicEmployeeSvc.getCheckInDetail({
          id: id
        }).success(function (data) {
          vm.employee.checkedInTime = data.checkedInTime;
          vm.employee.checkOutTime = data.checkOutTime;

          if (data.allowance > 0) {
            vm.employee.allowance = data.allowance;
            vm.employee.allowanceDisplay = getAllowanceDisplay(vm.employee.allowance, vm.employee.allowancePeriod);
          }

          promptCheckInSuccessMessage();
          abp.notify.info(App.localize('PatientXCheckedIn', vm.employee.name));
        }).finally(function () {
          vm.loading--;
        });
      }

      function promptCheckInSuccessMessage() {
        function getMessage() {
          var titles = [App.localize('CheckedIn')];
          var msgs = [];
          if (!vm.employee.allowance) {
            if (!vm.employee.canAccess) {
              msgs.push(App.localize('PatientUnableToMakeCashlessClaim'));
              msgs.push(App.localize('CheckInSuccessCollectCashNarrative'));
              return {
                titles: titles,
                msgs: msgs
              };
            }
            msgs.push(App.localize('PatientMustPayCash'));
          } else {
            if (vm.employee.allowance <= 30) {
              msgs.push(App.localize('PatientLowLimitAndPayExcess'));
            }

            if (vm.employee.coPay) {
              titles.push(vm.employee.coPay.title);
              msgs.push(vm.employee.coPay.text);
            }
          }

          if (vm.employee.isPreEmployee)
            msgs.push(App.localize('PreEmployeeCheckInSuccessNarrative'));
          else {
            msgs.push(App.localize('HealthScreeningCheckInSuccessNarrative'));
            msgs.push(App.localize('HealthScreeningCheckInSuccessNarrative2'));
          }
          return {
            titles: titles,
            msgs: msgs
          };
        }

        var message = getMessage();
        var title = _.join(message.titles || [], '<br />');
        var text = '<span><b>' + App.localize('GLApproved') + ' </b></span>' +
          App.localize('CheckInSuccessMessage') +
          '<br /><br /><b class="font-uppercase">' + _.toUpper(App.localize('Important')) +
          ':</b><br />' + _.join(message.msgs || [], ' ');

        window.onbeforeunload = function () {
          return title;
        };
        swal({
          title: title,
          type: 'success',
          text: text,
          showConfirmButton: true,
          confirmButtonText: App.localize('Understood'),
          html: true
        }, function () {
          window.onbeforeunload = null; //unregister
        });
        App.swal.disableButtons(5);
      }

      function getAllowanceDisplay(allowance, allowancePeriod) {
        var allowanceText = $filter('currencyFormat')(Math.min(allowance, vm.defaultMaxAllowance), vm.currencyCode);

        if (!allowancePeriod) return allowanceText;

        var cycle = '';
        switch (allowancePeriod.cycle) {
          case 0:
            cycle = App.localize('EveryXYears', allowancePeriod.interval);
            break;
          case 1:
            cycle = App.localize('Yearly');
            break;
          case 2:
            cycle = App.localize('Monthly');
            break;
          case 3:
            cycle = App.localize('Daily');
            break;
          case 4:
            cycle = App.localize('PerVisit');
            break;
          case 5:
            cycle = App.localize('Every6Months');
            break;
          case 6:
            cycle = App.localize('Every4Months');
            break;
          case 7:
            cycle = App.localize('Every3Months');
            break;
          case 8:
            cycle = App.localize('Every2Months');
            break;
        }
        return allowanceText + ' ' + cycle.toLowerCase();
      }

      function getTimeZoneId() {
        vm.loading += 1;
        commonLookupSvc.getTenantIanaTimeZoneId({})
          .success((data) => {
            vm.timeZoneId = data;
          })
          .finally(() => {
            vm.loading -= 1;
          })
      }

      init();
    }
  ]);
})();
