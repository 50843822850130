(() => {
  angular.module('app').component('hostCorporatesCreateEditAgentAssignment', {
    templateUrl: require('./agentAssignment.component.html'),
    controller: CorporateEditAgentAssignmentController,
    controllerAs: 'vm',
    require: {
      container: '^hostCorporatesCreateEditContainer',
    },
    bindings: {
      data: '<',
    },
  });

  CorporateEditAgentAssignmentController.$inject = [];

  function CorporateEditAgentAssignmentController() {
    const vm = this;

    vm.$onInit = init;

    function init() {}
  }
})();
