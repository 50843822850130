(function () {
    var componentId = 'clinicHospitalInpatientDashboardCurrentPatientsPatientListingComponent';
    var app = angular.module('app');

    app.component(componentId, {
        require: {
            container: '^clinicHospitalInpatientDashboardCurrentPatientsComponent'
        },
        templateUrl: require('/App/clinic/views/hospital/inpatientDashboard/currentPatients/patientListing/patientListing.component.html'),
        controller: ['$scope', 'abp.services.app.inpatientDashboard', controller],
        controllerAs: 'vm',
        bindings: {
            guaranteeLetters: '<',
            headerText: '@',
            noGlNarrative: '@',
            isInitialGlComponent: '<',
            refreshFunction: '&',
            supportContactNumber: '<?'
        }
    });

    function controller($scope, inpatientDashboardSvc) {
        function init() {
        }

        var vm = this;
        vm.loading = 0;
        vm.statusModel = 'all';
        vm.hasMore = false;
        vm.guaranteeLetters = [];
        vm.requestParams = {
            filter: '',
            admissionType: 0,
            glNumber: '',
            admissionNumber: '',
            skipCount: 0,
            maxResultCount: 5,
        };

        vm.updateGlsDisplay = updateGlsDisplay;
        vm.dismissGuaranteeLetter = dismissGuaranteeLetter;
        vm.updateFilter = updateFilter;
        vm.showMore = showMore;
        vm.toggleSortFunction = toggleSortFunction;
        vm.activeSortFn = '';

        vm.$onInit = function () {
            vm.container.addWidget(this);
        };

        vm.$onChanges = function (changesObj) {
            if (changesObj.guaranteeLetters) {
                processGuaranteeLetters(changesObj.guaranteeLetters.currentValue)
                updateGlsDisplay();
            }
        }

        function updateGlsDisplay() {
            vm.guaranteeLetters = vm.allGuaranteeLetters[vm.statusModel];
            vm.hasMore = vm.guaranteeLetters.length > vm.requestParams.maxResultCount;
            vm.guaranteeLetters = vm.guaranteeLetters.slice(0, vm.requestParams.maxResultCount);
        }

        function updateFilter(filterParams) {
            _.extend(vm.requestParams, filterParams);
        }

        function processGuaranteeLetters(guaranteeLetters) {
            vm.allGuaranteeLetters = {
                all: [],
                pending: [],
                query: []
            };
            if (!guaranteeLetters) return;

            var glsCopy = guaranteeLetters.slice(); // Shallow copy array.

            glsCopy.sort(runSortQueue);

            updateRejectionReason(glsCopy);

            vm.allGuaranteeLetters.all = glsCopy;
            vm.allGuaranteeLetters.pending = _.filter(glsCopy, function (gl) {
                return gl.guaranteeLetterStatus === 0 || gl.guaranteeLetterStatus === 8;
            });
            vm.allGuaranteeLetters.query = _.filter(glsCopy, function (gl) {
                return gl.queryStatus !== null && gl.queryStatus === 1;
            });
        }

        function showMore() {
            if (!vm.loading && vm.hasMore) {
                vm.requestParams.maxResultCount += 5;
                updateGlsDisplay();
            }
        };

        function updateRejectionReason(visibleRetrievedGuaranteeLetters) {
            _(visibleRetrievedGuaranteeLetters)
                .filter('rejectionReason')
                .each(function (gl) {
                    // Remove the 'Reject: ' label.

                    var reason = gl.rejectionReason.split(": ").pop();
                    gl.rejectionReason = App.localize('GlRejectedTooltip', reason.toLowerCase());
                });
        }

        // Sort functions

        function toggleSortFunction(sortFnName) {
            setSortFunction(sortFnName);
            _.forIn(vm.allGuaranteeLetters, function (gls) { gls.sort(runSortQueue); });
            updateGlsDisplay();
        }

        function compareFn(a, b) {
            // Undefined in gt/lt comparison will always be false, not the same as null.

            if (a === undefined && b === undefined)
                return 0;

            if (a === undefined)
                return 1;

            if (b === undefined)
                return -1;

            // Basic comparison.

            if (a < b)
                return -1;

            if (a > b)
                return 1;

            return 0;
        }

        var sortFunctions = {
            sortByUnresolvedQueries: function (a, b) {
                if (a.queryStatus === b.queryStatus)
                    return 0;

                if (a.queryStatus === 1)
                    return -1;

                if (b.queryStatus === 1)
                    return 1;

                return 0;
            },
            sortByLatestUpdated: function (a, b) {
                return compareFn(a.lastModificationTime, b.lastModificationTime) * -1;
            },
            sortByEarliestUpdated: function (a, b) {
                return compareFn(a.lastModificationTime, b.lastModificationTime);
            },
            sortByLatestAdmitted: function (a, b) {
                return compareFn(a.admissionTime, b.admissionTime) * -1;
            },
            sortByEarliestAdmitted: function (a, b) {
                return compareFn(a.admissionTime, b.admissionTime);
            },
        };

        var sortQueue = [sortFunctions.sortByUnresolvedQueries];

        function setSortFunction(sortFnName) {
            var sortFn = sortFunctions[sortFnName];
            if (!sortFn) return;

            sortQueue = [sortFunctions.sortByUnresolvedQueries]; // Reset sort queue to default.

            sortQueue.push(sortFn);

            vm.activeSortFn = sortFnName;
        }

        function runSortQueue(a, b) {
            var result = 0;

            for (var i = 0; i < sortQueue.length; i++) {
                result = sortQueue[i](a, b);
                if (result !== 0) break;
            }

            return result;
        }

        // End of Sort functions

        function dismissGuaranteeLetter(guaranteeLetter) {
            abp.message.confirm(App.localize('DismissGuaranteeLetterWarning'), App.localize('AreYouSure'), function (d) {
                if (d) {
                    vm.loading++;
                    inpatientDashboardSvc.updateIsVisibleInInpatientDashboard(guaranteeLetter)
                        .success(function () {
                            abp.notify.success(App.localize("GuaranteeLetterSuccessfullyDismissed"));
                            vm.refreshFunction();
                        }).finally(function () {
                            vm.loading--;
                        });
                }
            });
        }

        init();
    }
})();
