(function () {
    angular
        .module('app')
        .controller('host.views.employees.exclusionListModal', ['$uibModalInstance', 'inpatientSettings',
            'abp.services.app.hospitalizationExclusion', controller]);

    function controller($uibModalInstance, inpatientSettings, hospitalizationExclusionSvc) {
        var vm = this;
        vm.loading = 0;

        function init() {
            getOptionalExclusions();
            getDefaultExclusions();
        }

        function getOptionalExclusions() {
            if (inpatientSettings.coveredOptionalExclusions) {
                vm.loading++;
                hospitalizationExclusionSvc.getOptionalExclusions({
                    coveredOptionalExclusions: inpatientSettings.coveredOptionalExclusions
                }).success(function (record) {
                    vm.optionalExclusions = record.coveredOptionalExclusions.length > 0 ? record.coveredOptionalExclusions : null;
                }).finally(function () {
                    vm.loading--;
                });
            }
        }

        function getDefaultExclusions() {
            if (inpatientSettings.exclusionVersionId) {
                vm.loading++;
                hospitalizationExclusionSvc.getDefaultExclusions({
                    exclusionVersionId: inpatientSettings.exclusionVersionId
                }).success(function (record) {
                    vm.exclusionProvisions = record.defaultExclusions.length > 0 ? record.defaultExclusions : null;
                }).finally(function () {
                    vm.loading--;
                });
            }
        }

        vm.close = function () {
            $uibModalInstance.close();
        };

        init();
    }
})();
