(() => {
  const data = require('./portalConstants.json');

  loadConstants(data);

  function loadConstants(jsonData) {
    const app = angular.module('app');
    jsonData.forEach((constObj) => {
      const constName = constObj.name;
      const constValues = constObj.values;

      /*
      * Convert constant data to an object with following structure:
        {
          ConstantKey1: 'ConstantValue1'
          ConstantKey2: 'ConstantValue2'
        }
      */

      // Save the constant into AngularJS constants.

      app.constant(constName, Object.freeze(constValues));
    });
  }
})();
