(() => {
  angular
    .module('app')
    .directive('uncoveredTreatmentNestedItem', uncoveredTreatmentNestedItemDirective);

  uncoveredTreatmentNestedItemDirective.$inject = ['$compile'];

  function uncoveredTreatmentNestedItemDirective($compile) {
    const directive = {
      link,
      restrict: 'A',
    };
    return directive;

    function link(scope, element) {
      if (scope.item.children) {
        const html = $compile(
          '<ol class="dd-list list-unstyled"><li class="dd-item" uncovered-treatment-nested-item ng-repeat="item in item.children"><div style="padding: 2px 10px; margin: 4px 0px;">{{item.displayName}}</div></li></ol>'
        )(scope);
        element.append(html);
      }
    }
  }
})();
