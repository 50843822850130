(function () {
    var componentId = 'hostPanelRecruitmentRecruitmentTimelineComponent';
    var app = angular.module('app');

    function controller($scope) {
        var vm = this;

        vm.permissions = {
            edit: abp.auth.isGranted('PanelRequests.Host.Edit'),
            override: abp.auth.isGranted('PanelRequests.Host.Override')
        };

        vm.$onChanges = function (changes) {
            if (changes.panelRequestEditModel) {
                setPanelRequest(changes.panelRequestEditModel.currentValue);
            }
        };

        function setPanelRequest(data) {
            if (!data) return;

            vm.request = data.request;
            vm.isRequestOwner = vm.request.masterOpsId === abp.session.userId;
            vm.canEdit = vm.permissions.override || vm.permissions.edit && vm.isRequestOwner;
            vm.isSpecialist = vm.request.panelType === 5;
        }

        vm.handleOnSaved = function () {
            vm.setState(0);
            vm.onUpdate();
        };

        vm.handleOnCancel = function () {
            vm.setState(0);
        };

        vm.state = 0;
        vm.setState = function (newState) {
            // Check if can transition to the new state.

            var isNewStateValid = true;

            function checkRequestStatus(expectedStatuses, errorText) {
                if (!_.isArray(expectedStatuses)) expectedStatuses = [expectedStatuses];

                if (_.isArray(errorText)) errorText = _.join(errorText, ', ');

                if (!_.includes(expectedStatuses, vm.request.status)) {
                    abp.notify.error('You can only do this when status is ' + errorText + '.');  // TODO: Localize this;
                    isNewStateValid = false;
                }
            }

            function ensureHasPermissions() {
                if (!vm.canEdit) {
                    abp.notify.error('You don\'t have permissions to edit this request'); // TODO: Localize this;
                    isNewStateValid = false;
                }
            }

            function ensurePanelNotExists() {
                if (vm.request.panelLocationId) {
                    abp.notify.error(App.localize('PanelAlreadyExists'));
                    isNewStateValid = false;
                }
            }

            switch (newState) {
                case 0: break; // Default state. Always OK.

                case 1: // Remark / Manual Update
                    checkRequestStatus([0, 1, 2, 3, 4, 5, 7],
                        [
                            App.localize('PanelRequestStatus_Requested'),
                            App.localize('PanelRequestStatus_InRecruitment'),
                            App.localize('PanelRequestStatus_DocumentRequested'),
                            App.localize('PanelRequestStatus_Rejected'),
                            App.localize('PanelRequestStatus_Registered'),
                            App.localize('PanelRequestStatus_Trained'),
                            App.localize('PanelRequestStatus_Deactivated')]);
                    break;

                case 2: // Send Appointment Letter
                    checkRequestStatus(1, App.localize('PanelRequestStatus_InRecruitment'));
                    ensureHasPermissions();
                    ensurePanelNotExists();
                    break;

                case 3: // Create Account
                    checkRequestStatus(2, App.localize('PanelRequestStatus_ProposalSent'));
                    ensureHasPermissions();
                    ensurePanelNotExists();
                    break;

                case 4: // Schedule Training
                    checkRequestStatus(4, [App.localize('PanelRequestStatus_Registered')]);
                    ensureHasPermissions();
                    break;

                default: // Not a valid state.
                    isNewStateValid = false;
            }

            if (isNewStateValid) {
                vm.state = newState;
            }
        };
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/panelRecruitment/recruitmentTimeline/recruitmentTimeline.component.html'),
        controller: ['$scope', controller],
        controllerAs: 'vm',
        bindings: {
            panelRequestEditModel: '<',
            onUpdate: '&'
        }
    });
})();
