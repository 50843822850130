(function () {
    'use strict';

    angular
        .module('app')
        .controller('host.views.corporates.supportPlans.page',
            ['$state', '$stateParams', 'abp.services.app.supportPlan', 'abp.services.app.benefitGroup', controller]);

    function controller($state, $stateParams, supportPlanSvc, benefitGroupSvc) {
        function init() {
            vm.loading = true;
            supportPlanSvc.getTenant({
                id: vm.organizationId
            }).success(function (n) {
                vm.organizationFullName = n.name + ' (' + n.tenancyName + ')';
            }).finally(function () {
                vm.loading = false;
            });
        }

        var vm = this;

        vm.loading = false;
        vm.organizationId = $stateParams.id;
        vm.organizationFullName = '';

        vm.supportPlanGridOptions = {
            appScopeProvider: vm,
            columnDefs: [
                {
                    enableSorting: false,
                    name: App.localize('Actions'),
                    width: 120,
                    cellTemplate: 'actionTemplate'
                }, {
                    displayName: App.localize('Benefits'),
                    field: 'serviceType',
                    minWidth: 120,
                    cellTemplate: 'benefitTemplate'
                }, {
                    displayName: App.localize('PlanName'),
                    field: 'name',
                    minWidth: 120
                }, {
                    displayName: App.localize('DisplayName'),
                    field: 'displayName',
                    minWidth: 120
                }],
            data: []
        };

        vm.getSupportPlans = function () {
            vm.loading = true;
            benefitGroupSvc.getInpatientBenefitGroups({
                id: vm.organizationId
            }).success(function (data) {
                vm.supportPlanGridOptions.data = _.map(data.items, function (d) {
                    return $.extend(d, {
                        organizationId: vm.organizationId
                    });
                });
            }).finally(function (e) {
                vm.loading = false;
            });
        };

        vm.deleteBenefitGroup = function (benefitGroup) {
            abp.message.confirm(App.localize('SupportPlanDeleteWarningMessage', benefitGroup.name), App.localize('AreYouSure'), function (d) {
                if (d) {
                    benefitGroupSvc.deleteInpatientBenefitGroup({
                        id: benefitGroup.id,
                        organizationId: vm.organizationId
                    }).success(function (data) {
                        vm.getSupportPlans();
                        abp.notify.success(App.localize('SuccessfullyDeleted'));
                    });
                }
            });
        };

        if (vm.organizationId) {
            init();
            vm.getSupportPlans();
        } else {
            $state.go('host.corporates');
        }

        init();
    }
})();
