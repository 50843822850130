(() => {
  angular
    .module('app')
    .filter('localize', localize)
    // For backward compatibility only. Filter name don't need "Filter" suffix.
    // AngularJS automatically appends "Filter" to name for DI.
    .filter('localizeFilter', localize);

  localize.$inject = [];

  function localize() {
    return localizeFilter;

    function localizeFilter(value, key, ...otherValues) {
      return App.localize(key, value, ...otherValues);
    }
  }
})();
