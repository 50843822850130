import _ from 'lodash';
import c3 from 'c3';

(function () {
    var controllerId = 'corporate.views.employees.viewProfileModal';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$state', '$uibModalInstance', '$uibModal', 'abp.services.app.employee', 'employeeId', 'abp.services.app.patientTicket', 'abp.services.app.commonLookup', 'Hms.Employees.EmployeeState',
        function ($scope, $state, $uibModalInstance, $modal, employeeSvc, employeeId, patientTicketSvc, commonLookupSvc, enumEmployeeState) {
            const vm = this;

            vm.hasBenefitGroupVisualizer = abp.setting.getBoolean('Hms.Feature.BenefitGroupVisualizerSystem');

            function init() {
                vm.loading = true;

                if (hasRegionalSystemSupport) {
                    commonLookupSvc.getTenantCurrencyCode()
                        .success((currencyCode) => {
                            vm.currencyCode = currencyCode;
                        });
                } else {
                    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
                }

                employeeSvc.getEmployeeProfile({
                    id: employeeId
                }).success((data) => {
                  const ticketServiceTypes = [];
                  if (data) {
                        data.outpatientBenefits = [];
                        data.inpatientBenefits = [];
                        if (!data.isDeleted && data.pools && data.pools.length) {
                            _.each(data.pools, (d) => {
                                let title = _.join(_.map(d.benefitTypes, (bt) => {
                                    if (bt.isCustom) {
                                        var strCt = 'CT' + bt.id;
                                        var idx = _.findIndex(data.ctNames, function (ctn) { return ctn.value === bt.id; });
                                        if (idx !== -1) strCt = data.ctNames[idx].name;
                                        return strCt;
                                    }
                                    if (bt.id === 251) {
                                        return App.localize('Hospitalization');
                                    }

                                    return App.localize(bt.name);

                                }), '/');

                                // TODO: Better design? localisation required

                                const isBudget = d.modifierType === 0 || d.modifierType === 2;
                                let cycle = '';
                                if (!d.isUnlimited) {
                                    switch (d.modifierCycle) {
                                        case 0: cycle = 'every ' + d.interval + ' years '; break;
                                        case 1: cycle = 'annual '; break;
                                        case 2: cycle = 'monthly '; break;
                                        case 3: cycle = 'daily '; break;
                                        case 4: cycle = 'per visit '; break;

                                        // no default
                                    }
                                    if (d.modifierType === 2 || d.modifierType === 3) cycle += 'overdraft ';
                                }
                                if (d.serviceType === 8)
                                    title += ' (' + cycle + (isBudget ? 'per employment budget' : 'visit per employment') + ')';
                                else
                                    title += ' (' + cycle + (isBudget ? 'budget' : 'visit') + ')';

                                const isEmptyExcess = d.isExcess && !d.employeePortion;
                                if (!isEmptyExcess) {
                                    _.each(d.benefitTypes, (bt) => {
                                        if (bt.id === 7 || bt.id === 8 || bt.id === 251) {
                                            data.inpatientBenefits.push({
                                                title: title,
                                                isUnlimited: d.isUnlimited,
                                                isExcess: d.isExcess,
                                                isBudget: isBudget,
                                                limit: d.limit,
                                                used: d.used,
                                                locked: d.locked,
                                                balance: d.balance
                                            });
                                        }
                                        else {
                                            data.outpatientBenefits.push({
                                                title: title,
                                                isUnlimited: d.isUnlimited,
                                                isExcess: d.isExcess,
                                                isBudget: isBudget,
                                                limit: d.limit,
                                                used: d.used,
                                                locked: d.locked,
                                                balance: d.balance
                                            });
                                        }

                                        if (bt.id !== 4) {
                                          const serviceType = _.find(
                                            ticketServiceTypes,
                                            ['value', bt.id]
                                          );
                                          if (!serviceType) {
                                              let displayName = App.localize(bt.name);
                                              if (bt.isCustom) {
                                                  const idx = _.findIndex(
                                                    data.ctNames,
                                                    (ctn) => ctn.value === bt.id);
                                                  if (idx !== -1)
                                                    displayName = data.ctNames[idx].name;
                                              }

                                              if (bt.id !== 9
                                                || (bt.id === 9
                                                  && vm.applicationHasHealthScreeningModule
                                                  )
                                                ) {
                                                  ticketServiceTypes.push({
                                                      name: displayName,
                                                      value: bt.id
                                                  });
                                              }
                                          }
                                      }
                                    });
                                }
                            });
                        }
                    }

                    data.ticketServiceTypes = ticketServiceTypes;
                    data.allowance = Math.min(data.allowance, vm.defaultMaxAllowance);
                    vm.profile = data;
                    vm.profile.isPreEmployee = vm.profile.employeeType === 2;
                    vm.accountOwnerCountryCode = data.accountOwnerCountryCode;
                }).finally(function () {
                    vm.loading = false;

                    if (vm.hasBenefitGroupVisualizer) {
                        let data = [];
                        const util = vm.profile;
                        if (util.budgetUtilizationRate !== -1){
                            data.push([App.localize('Budget'), util.budgetUtilizationRate]);
                        }
                        if (util.visitUtilizationRate !== -1){
                            data.push([App.localize('Visit'), util.visitUtilizationRate]);
                        }
    
                        generateGraph(data);
                    }
                });
            }

            function showEditModal(id, tab, rehire) {
                $modal.open({
                    templateUrl: require('/App/corporate/views/employees/createOrEditModal.html'),
                    controller: 'corporate.views.employees.createOrEditModal as vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        employeeId: function () {
                            return id;
                        },
                        selectedTab: function () {
                            return tab;
                        },
                        isRehire: function () {
                            return rehire;
                        }
                    }
                }).result.then(function () {
                    $state.reload();
                });
            }

            var hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');

            vm.defaultMaxAllowance = parseFloat(abp.setting.get("Hms.General.MaxAllowanceAmountLimit"));
            vm.loading = false;
            vm.profile = null;
            vm.hasSpecialistModule = false; //abp.features.isEnabled('Specialist.Enabled'), // disabled by HM-550
            vm.applicationHasHealthScreeningModule = abp.setting.getBoolean('Hms.Feature.HealthScreeningModule');
            vm.permissions = {
                createManualTicket: abp.auth.isGranted('PatientTickets.Manual.Create')
            };

            vm.enums = {};
            vm.enums.employeeState = enumEmployeeState;

            function generateGraph(util) {
                vm.chart = c3.generate({
                    bindto: $('.chart-container')[0],
                    data: {
                        columns: util,
                        type: 'gauge'
                    },
                    gauge: {
                        label: {
                          format (value) {
                            return value;
                          }
                        }
                    },
                    color: {
                        pattern: ['#60B044', '#F6C600', '#F97600', '#FF0000'],
                        threshold: {
                            values: [25, 50, 75, 100]
                        }
                    },
                    size: {
                        height: 180
                    }
                });
            }

            vm.createTicket = function (serviceType) {
                $state.go('createEditManualTicket', { employeeId: employeeId, serviceType: serviceType, accountOwnerCountryCode: vm.accountOwnerCountryCode });
                vm.close();
            };

            vm.close = function () {
                $uibModalInstance.dismiss();
            };

            vm.getDaysString = function (x) {
                if (x) return App.localize('XDays', x);
                return '';
            };

            vm.getMcTakenString = function () {
                var str = '';
                if (vm.profile) {
                    str += vm.profile.mcTaken;
                    if (vm.profile.mcLimit) str += ' / ' + vm.profile.mcLimit;
                }
                return vm.getDaysString(str);
            };

            vm.getHospitalizationLeaveDaysTakenString = function () {
                var str = '';
                if (vm.profile) {
                    str += vm.profile.hospitalizationLeaveDaysTaken;
                    if (vm.profile.hospitalizationMcLimit) str += ' / ' + vm.profile.hospitalizationMcLimit;
                }
                return vm.getDaysString(str);
            }

            vm.getSickLeaveString = function () {
                var str = '';
                if (vm.profile) {
                    var hospitalizationMcLimit = vm.profile.hospitalizationMcLimit !== null
                        ? vm.profile.hospitalizationMcLimit
                        : 0;
                    var mcLimit = vm.profile.mcLimit !== null
                        ? vm.profile.mcLimit
                        : 0;
                    var totalSickLeaveTaken = vm.profile.mcTaken + vm.profile.hospitalizationLeaveDaysTaken;
                    var totalSickLeaveLimit = mcLimit + hospitalizationMcLimit;
                    str += totalSickLeaveTaken;
                    if (vm.profile.mcLimit || vm.profile.hospitalizationMcLimit)
                        str += ' / ' + totalSickLeaveLimit;
                }
                return vm.getDaysString(str);
            }

            vm.rehireEmployee = function (employee) {
                vm.close();
                if (!employee.principalId) {
                    showEditModal(employee.id, 0, employee.isTerminated)
                } else {
                    showEditModal(employee.principalId, 0, employee.isTerminated)
                }
            };

            init();
        }
    ]);
})();
