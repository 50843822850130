(function () {
    var controllerId = 'clinic.views.healthScreeningDashboard';
    var app = angular.module('app');

    app.controller(controllerId, ['$scope', '$interval', 'abp.services.app.commonLookup',
        function ($scope, $interval, commonLookupSvc) {
            function init() {
                // EMPTY.
                var tickerInterval = $interval(tickerJob, 6000);
                $scope.$on('$destroy', function () {
                    if (angular.isDefined(tickerInterval)) {
                        $interval.cancel(tickerInterval);
                        tickerInterval = undefined;
                    }
                });

                getTimeZoneId();
            }
            var vm = this;
            vm.loading += 1;
            vm.tickerCount = 0;
            function tickerJob() {
                if (!((++vm.tickerCount) % 10)) {
                    vm.tickerCount = 0;
                    $scope.$broadcast('tickerEvent', {});
                }
            }
            vm.permissions = {
                viewCheckIn: abp.auth.isGranted("Clinic.Employees")
            };

            function getTimeZoneId() {
                vm.loading += 1;
                commonLookupSvc.getTenantIanaTimeZoneId({})
                    .success((data) => {
                        vm.timeZoneId = data;
                    })
                    .finally(() => {
                        vm.loading -= 1;
                    })
            }

            init();
        }
    ]);
})();
