(function () {
    'use strict';

    var app = angular.module('app').component('hostOperationsDashboardLateSubmissionsComponent', {
        templateUrl: require('/App/host/views/operationsDashboard/lateSubmissions/lateSubmissions.component.html'),
        controller: ['$scope', '$timeout', '$uibModal', 'abp.services.app.operationsDashboard', 'abp.services.app.latePatientTicketSubmission',
          'abp.services.app.commonLookup', '$sce', 'Hms.OperationsDashboard.Enum.TableFilterConsts', 'moment', controller],
        controllerAs: 'vm',
        bindings: {
            widgets: '<',
            isOpen: '<',
            onAddWidget: '&',
            countryCode: '=?'
        }
    });

    function controller($scope, $timeout, $modal, operationsDashboardSvc, latePatientTicketSubmissionSvc, commonLookupSvc, $sce, enumTableFilterConsts, moment) {
        function init() {
            getEligibleCountries();

            $timeout(function () {
                vm.gridOptions.paginationCurrentPage = Math.floor(vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1);
            });
        }

        var vm = this;

        vm.$onInit = onInit;
        vm.getLateSubmissions = getLateSubmissions;
        vm.refresh = refresh;
        vm.isRefresh = refresh;
        vm.countryCode = null;
        vm.resendResolutionEmail = resendResolutionEmail;

        vm.widgetName = 'hostOperationsDashboardLateSubmissionsComponent';
        vm.loading = false;
        vm.dataLoading = 0;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.lateSubmissions = [];
        vm.latePatientTicketSubmissionId = 0;
        vm.lateSubmissionNarrative = $sce.trustAsHtml(App.localize('HostLateSubmissionListingNarrative'));

        vm.enums = {
          tableFilterConsts: enumTableFilterConsts
        };

        vm.requestParams = {
            tableFilter: 'ACTIONABLE',
            country: '',
            panel: '',
            organization: '',
            skipCount: 0,
            maxResultCount: app.consts.grid.defaultPageSize,
            sorting: '',
            countryCode: vm.countryCode
        };

        vm.dateRangeOptions = App.createDateRangePickerOptions();
        vm.dateRangeModel = {
            startDate: null,
            endDate: null
        };

        function getRequestInput() {
            var input = $.extend({}, vm.requestParams);
            input.startDate = vm.dateRangeModel.startDate;
            input.endDate = vm.dateRangeModel.endDate;
            return input;
        }

        vm.gridOptions = {
            appScopeProvider: vm,
            paginationPageSizes: app.consts.grid.defaultPageSizes,
            paginationPageSize: app.consts.grid.defaultPageSize,
            paginationCurrentPage: 0,
            useExternalPagination: true,
            useExternalSorting: true,
            resizable: true,
            columnDefs: [{
                name: 'Actions',
                displayName: 'Actions',
                enableSorting: false,
                width: 95,
                headerCellTemplate: '<span></span>',
                cellTemplate: 'lateSubmissionActionTemplate'
            }, {
                displayName: App.localize('IssueNumber'),
                field: 'issueNumber',
                minWidth: 100
            }, {
                displayName: App.localize('Status'),
                field: 'status',
                minWidth: 150,
                cellTemplate: 'statusTemplate'
            }, {
                displayName: App.localize('ResolvedBy'),
                field: 'resolvedBy',
                minWidth: 80
            }, {
                displayName: App.localize('CheckedIn'),
                field: 'checkedInTime',
                cellFilter: 'momentFormat: \'L LT\'',
                minWidth: 80
            }, {
                displayName: App.localize('Remarks'),
                field: 'reviewerRemarks',
                minWidth: 80
            }, {
                displayName: App.localize('LateSubmission'),
                field: 'creationTime',
                cellFilter: 'momentFormat: \'L LT\'',
                minWidth: 80
            }, {
                displayName: App.localize('Reason'),
                enableSorting: false,
                field: 'reason',
                minWidth: 100
            }, {
                displayName: App.localize('Requestor'),
                field: 'requestorName',
                enableSorting: false,
                minWidth: 100
            }, {
                displayName: App.localize('Panel'),
                field: 'panelLocationName',
                enableSorting: false,
                minWidth: 100,
                cellTemplate: 'panelTemplate'
            }, {
                displayName: App.localize('Patient'),
                field: 'patient',
                minWidth: 100,
                enableSorting: false,
                cellTemplate: 'patientTemplate'
            }, {
                displayName: App.localize('Organization'),
                enableSorting: false,
                field: 'organization',
                minWidth: 120,
                cellTemplate: 'organizationTemplate'
            }, {
                displayName: App.localize('HrContactNumber'),
                enableSorting: false,
                field: 'hrContactNumber',
                minWidth: 100
            }, {
                displayName: App.localize('TotalAmount'),
                field: 'totalAmount',
                cellFilter: 'currencyFormat: row.entity.currencyCode',
                minWidth: 110
            }, {
                displayName: App.localize('McDays'),
                enableSorting: false,
                field: 'medicalCertificate',
                minWidth: 120,
                cellTemplate: 'mcTemplate'
            }],
            onRegisterApi: function (gridApi) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function (scope, callback) {
                    vm.requestParams.sorting = callback.length && callback[0].field ? callback[0].field + ' ' + callback[0].sort.direction : null;
                    vm.getLateSubmissions();
                });
                gridApi.pagination.on.paginationChanged($scope, App.setPagination(vm.requestParams, vm.getLateSubmissions));
            },
            data: []
        };

        vm.updateIssue = function (latePatientTicketSubmissionId) {
            vm.latePatientTicketSubmissionId = latePatientTicketSubmissionId;
            $modal.open({
                templateUrl: require('/App/common/views/patientTickets/detail/updateIssueNumber/updateIssueNumber.modal.html'),
                controller: 'common.views.patientTickets.detail.updateIssueNumberModal as vm',
                backdrop: 'static'
            }).result.then(function (issueNumber) {
                if (issueNumber) {
                    updateIssue(issueNumber);
                }
            });
        };

        function updateIssue(issueNumber) {
            vm.loading++;
            latePatientTicketSubmissionSvc.updateIssue({
                id: vm.latePatientTicketSubmissionId,
                issueNumber: issueNumber
            }).success(function (data) {
                refresh();
                vm.latePatientTicketSubmissionId = 0;
            }).finally(function () {
                vm.loading--;
            });
        }

        function resendResolutionEmail(latePatientTicketSubmissionId, lastSentTime) {
            if (_.isNil(lastSentTime)) {
                return;
            }
            var formattedSentTime = moment(lastSentTime).format('Do MMMM YYYY');

            latePatientTicketSubmissionSvc
                .getOrganizationEmails({ Id: latePatientTicketSubmissionId })
                .success(function (data) {
                    $modal.open({
                        templateUrl: require('/App/host/views/operationsDashboard/lateSubmissions/sendEmails.modal.html'),
                        controller: 'host.views.operationsDashboard.lateSubmissions.sendEmailsModal as vm',
                        backdrop: 'static',
                        resolve: {
                            title: function () {
                                return App.localize('ResendResolutionEmail');
                            },
                            narrative: function () {
                                return App.localize('ResendResolutionEmailNarrative', formattedSentTime);
                            },
                            emailAddresses: function () {
                                return data.emailAddresses;
                            }
                        }
                    }).result.then(function (emailAddresses) {
                        latePatientTicketSubmissionSvc.sendPendingApprovalEmails({
                            Id: latePatientTicketSubmissionId,
                            emailAddresses: emailAddresses
                        }).success(function () {
                            abp.notify.info(App.localize('EmailSent'));
                            refresh();
                        });
                    });
                });
        }

        function onInit() {
            vm.onAddWidget({ widget: this });
        }

        function setTotalCount() {
            var item = _.find(vm.widgets, function (data) {
                return data.id === 'hostOperationsDashboardLateSubmissionsComponent';
            });
            item.totalCount = vm.totalCount;
            item.loading = false;
        }

        function getEligibleCountries() {
            vm.loading++;
            commonLookupSvc.getEligibleCountries()
                .success(function (data) {
                    vm.countries = data;
                }).finally(function () {
                    vm.loading--;
                });
        }

        function getLateSubmissions() {
            var input = getRequestInput();

            vm.dataLoading++;
            operationsDashboardSvc.getLateSubmissions(input)
                .success(function (data) {
                    vm.gridOptions.totalItems = data.totalCount;
                    vm.gridOptions.data = data.items;

                    /* The ACTIONABLE value is a string literal because the backend const cannot be accessed by Javascript. */

                    vm.totalCount = vm.requestParams.tableFilter == "ACTIONABLE" ? data.totalCount : 0;
                    setTotalCount();
                })
                .finally(function () {
                    vm.dataLoading--;
                });
        }

        function refresh() {
            vm.requestParams.countryCode = vm.countryCode;
            getLateSubmissions();
            vm.isRefresh = !vm.isRefresh;
        }

        init();
    }
})();
