(() => {
  angular
    .module('finance')
    .controller(
      'manualTransactionSetupCreateEditTransactionCategory',
      ManualTransactionSetupCreateEditTransactionCategoryController
    );

  ManualTransactionSetupCreateEditTransactionCategoryController.$inject = [
    'isCreate',
    'transactionCategoryId',
    'accountOwnerId',
    '$scope',
    '$uibModalInstance',
    'Hms.Finance.ManualTransactionType',
    'abp.services.finance.transactionCategory',
  ];

  function ManualTransactionSetupCreateEditTransactionCategoryController(
    isCreate,
    transactionCategoryId,
    accountOwnerId,
    $scope,
    $uibModalInstance,
    enumManualTransactionType,
    transactionCategorySvc
  ) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;
    vm.isCreate = isCreate;
    vm.accountOwnerId = accountOwnerId;
    vm.transactionCategoryId = transactionCategoryId;
    vm.transactionCategoryTypes = enumManualTransactionType.values;
    vm.transactionCategory = null;

    vm.save = save;
    vm.cancel = cancel;
    vm.addTransactionsDescription = addTransactionsDescription;
    vm.addTransactionItem = addTransactionItem;
    vm.deleteTransactionsDescription = deleteTransactionsDescription;
    vm.deleteTransactionItem = deleteTransactionItem;

    init();

    function init() {
      if (vm.isCreate) {
        addTransactionsDescription();
        addTransactionItem();
      } else {
        getTransactionCategory();
      }
    }

    function getTransactionCategory() {
      vm.loading += 1;
      transactionCategorySvc
        .getTransactionCategory({ id: vm.transactionCategoryId })
        .success((data) => {
          vm.transactionCategory = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function save() {
      const isValid = App.isFormValid($scope.createEditTransactionCategory);
      if (isValid) {
        vm.saving += 1;
        if (vm.isCreate) {
          vm.transactionCategory.accountOwnerId = vm.accountOwnerId;
          transactionCategorySvc
            .createTransactionCategory(vm.transactionCategory)
            .success(() => {
              abp.notify.success(App.localize('SuccessfullyCreated'));
            })
            .finally(() => {
              $uibModalInstance.close();
              vm.saving -= 1;
            });
        } else {
          transactionCategorySvc
            .updateTransactionCategory(vm.transactionCategory)
            .success(() => {
              abp.notify.success(App.localize('SuccessfullyUpdated'));
            })
            .finally(() => {
              $uibModalInstance.close();
              vm.saving -= 1;
            });
        }
      }
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function addTransactionsDescription() {
      if (!vm.transactionCategory) {
        vm.transactionCategory = {};
      }

      if (!vm.transactionCategory.transactionCategoryDescriptions) {
        vm.transactionCategory.transactionCategoryDescriptions = [];
      }

      vm.transactionCategory.transactionCategoryDescriptions.push({});
    }

    function addTransactionItem() {
      if (!vm.transactionCategory) {
        vm.transactionCategory = {};
      }

      if (!vm.transactionCategory.transactionCategoryItems) {
        vm.transactionCategory.transactionCategoryItems = [];
      }

      vm.transactionCategory.transactionCategoryItems.push({});
    }

    function deleteTransactionsDescription(index) {
      if (vm.transactionCategory.transactionCategoryDescriptions.length > 1) {
        vm.transactionCategory.transactionCategoryDescriptions.splice(index, 1);
      } else {
        vm.transactionCategory.transactionCategoryDescriptions = [];
        addTransactionsDescription();
      }
    }

    function deleteTransactionItem(index) {
      if (vm.transactionCategory.transactionCategoryItems.length > 1) {
        vm.transactionCategory.transactionCategoryItems.splice(index, 1);
      } else {
        vm.transactionCategory.transactionCategoryItems = [];
        addTransactionItem();
      }
    }
  }
})();
