(() => {
  angular
    .module('app')
    .controller(
      'common.views.guaranteeLetters.details.letterDetail.withdrawModal',
      WithdrawModalController
    );

  WithdrawModalController.$inject = ['$uibModalInstance', 'letterNumber'];

  function WithdrawModalController($uibModalInstance, letterNumber) {
    const vm = this;

    vm.letterNumber = letterNumber;
    vm.selectedId = null;
    vm.input = '';

    class OptionModel {
      constructor(id, text) {
        this.id = id;
        this.text = text;
      }
    }

    vm.reasonOptions = [
      new OptionModel(0, App.localize('ChangeOfAppointmentDateOrTime')),
      new OptionModel(1, App.localize('CancelOfAppointment')),
      new OptionModel(2, App.localize('SelfPay')),
      new OptionModel(3, App.localize('ChangeOfHospital')),
      new OptionModel(4, App.localize('Others')),
    ];

    vm.requiresInput = requiresInput;
    vm.withdraw = withdraw;
    vm.cancel = cancel;

    init();

    function init() {}

    function requiresInput() {
      return vm.selectedId === 4;
    }

    function getReason() {
      if (vm.selectedId === 4) {
        return _.trim(vm.input);
      }
      return _.find(vm.reasonOptions, ['id', vm.selectedId]).text;
    }

    function withdraw() {
      $uibModalInstance.close(getReason());
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
