(function () {
  'use strict';

  var app = angular.module('app');
  app.controller('clinic.views.hospital.inpatientClaims',
    ['$scope', '$stateParams', '$timeout', '$state', 'abp.services.app.inpatientClaim', 'Hms.BillingStatus', 'moment', controller]);

  function controller($scope, $stateParams, $timeout, $state, inpatientClaimSvc, enumBillingStatus, moment) {
    function init() {
      $timeout(function () {
        vm.gridOptions.paginationCurrentPage = Math.floor(vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1);
      });
    }

    var vm = this;
    vm.loading = 0;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

    vm.enums = {};
    vm.enums.billingStatus = enumBillingStatus;

    $scope.$on('$viewContentLoaded', function () {
      App.initAjax();
    });

    vm.requestParams = {
      status: $stateParams.status,
      claimNumber: $stateParams.claimNumber,
      transactionNumber: $stateParams.transactionNumber,
      letterNumber: $stateParams.letterNumber,
      panelBillingStatus: $stateParams.panelBillingStatus,
      skipCount: $stateParams.skipCount || 0,
      maxResultCount: $stateParams.maxResultCount || app.consts.grid.defaultPageSize,
      sorting: $stateParams.sorting
    };

    vm.advancedFiltersAreShown = false;

    function registerStateParams() {
      $state.transitionTo($state.current, {
        status: vm.requestParams.status,
        claimNumber: vm.requestParams.claimNumber,
        transactionNumber: vm.requestParams.transactionNumber,
        letterNumber: vm.requestParams.letterNumber,
        panelBillingStatus: vm.requestParams.panelBillingStatus,
        skipCount: vm.requestParams.skipCount || 0,
        maxResultCount: vm.requestParams.maxResultCount || app.consts.grid.defaultPageSize,
        sorting: vm.requestParams.sorting
      }, {
        notify: false
      });
    }

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: app.consts.grid.defaultPageSizes,
      paginationPageSize: app.consts.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      resizable: true,
      columnDefs: [{
        name: 'Actions',
        displayName: 'Actions',
        enableSorting: false,
        width: 81,
        headerCellTemplate: '<span></span>',
        cellTemplate: 'viewTemplate'
      }, {
        displayName: App.localize('ClaimNumber'),
        enableSorting: false,
        field: 'claimNumber',
        minWidth: 100,
        width: 150
      }, {
        displayName: App.localize('ClaimDate'),
        field: 'claimDateString',
        minWidth: 120,
        cellTemplate: 'claimDateTemplate'
      }, {
        displayName: App.localize('GuaranteeLetterNumber'),
        enableSorting: false,
        field: 'guaranteeLetterNumber',
        minWidth: 120
      }, {
        displayName: App.localize('ClaimStatus'),
        enableSorting: false,
        field: 'latestRequestDescription',
        minWidth: 100,
        width: 150,
        cellTemplate: 'claimStatusTemplate'
      }, {
        displayName: App.localize('Patient'),
        field: 'patientName',
        minWidth: 120,
        cellTemplate: 'employeeTemplate',
        enableSorting: false,
      }, {
        displayName: App.localize('HospitalBillReferenceNumber'),
        field: 'patientMrn',
        minWidth: 120,
        enableSorting: false,
      }, {
        displayName: App.localize('TotalClaimAmount'),
        enableSorting: false,
        field: 'totalClaimAmount',
        cellFilter: 'currencyFormat: grid.appScope.currencyCode',
        minWidth: 120
      }, {
        displayName: App.localize('TotalBillAmount'),
        enableSorting: false,
        field: 'totalBillAmount',
        cellFilter: 'currencyFormat: grid.appScope.currencyCode',
        minWidth: 120
      }, {
        displayName: App.localize('BillingStatus'),
        enableSorting: false,
        field: 'panelBillingStatus',
        minWidth: 100,
        cellTemplate: 'billingStatusTemplate'
      }],
      onRegisterApi: function (gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, function (scope, callback) {
          vm.requestParams.sorting = callback.length && callback[0].field ? callback[0].field + ' ' + callback[0].sort.direction : null;
          vm.getInpatientClaims();
        });
        gridApi.pagination.on.paginationChanged($scope, App.setPagination(vm.requestParams, vm.getInpatientClaims));
      },
      data: []
    };

    vm.getInpatientClaims = function () {
      var input = $.extend({}, vm.requestParams);

      registerStateParams();
      vm.loading++;
      inpatientClaimSvc.getInpatientClaims(input).success(function (data) {
        vm.gridOptions.totalItems = data.totalCount;
        vm.gridOptions.data = data.items;
      }).finally(function (e) {
        vm.loading--;
      });
    };

    vm.exportToExcel = function () {
      var input = $.extend({}, vm.requestParams);
      registerStateParams();

      vm.exporting++;
      inpatientClaimSvc.getInpatientClaimsToExcel(input).success(function (data) {
        App.downloadTempFile(data);
      }).finally(function () {
        vm.exporting--;
      });
    };

    vm.getDateString = function getDateString(date) {
      return App.getDateString(moment.utc(date));
    }

    init();
  }
})();
