(() => {
  angular.module('app').component('commonComponents13thScheduleSearchComponent', {
    templateUrl: require('./13thScheduleSearch.component.html'),
    controller: ScheduleSearchController,
    controllerAs: 'vm',
    bindings: {
      currencyCode: '<'
    },
  });

  ScheduleSearchController.$inject = ['abp.services.app.medicalFeeSchedule'];

  function ScheduleSearchController(medicalFeeScheduleSvc) {
    const vm = this;
    vm.currencyCode = _.isNil(vm.currencyCode) || vm.currencyCode === undefined
    ? abp.setting.get('Hms.General.CurrencyCode')
    : vm.currencyCode;

    vm.input = {
      filter: '',
      maxResultCount: 5,
    };
    vm.getSchedules = getSchedules;

    // TODO: Future enhancement should include caching for the database,
    // and a specific appservice and dto for this component if needed.

    function getSchedules() {
      vm.schedules = [];
      if(!vm.input.filter) return;

      medicalFeeScheduleSvc.getMedicalFeeSchedules(vm.input).success((data) => {
        _.forEach(data.items, (item) => {
          vm.schedules.push({
            name: item.item ? item.item : null,
            category: !_.isNull(item.category) ? item.category : null,
            subCategory: !_.isNull(item.subCategory) ? item.subCategory : null,
            minTotalFee: !_.isNull(item.minimumTotalFee) ? item.minimumTotalFee : null,
            maxTotalFee: !_.isNull(item.maximumTotalFee) ? item.maximumTotalFee : null,
            minSurgeonFee: !_.isNull(item.minimumSurgeonFee) ? item.minimumSurgeonFee : null,
            maxSurgeonFee: !_.isNull(item.maximumSurgeonFee) ? item.maximumSurgeonFee : null,
            minAnaestheticFee: !_.isNull(item.minimumAnaesthetistFee)
              ? item.minimumAnaesthetistFee
              : null,
            maxAnaestheticFee: !_.isNull(item.maximumAnaesthetistFee)
              ? item.maximumAnaesthetistFee
              : null,
            radiologistFeePercentage: !_.isNull(item.radiologistFeePercentage)
              ? item.radiologistFeePercentage
              : null,
          });
        });
      });
    }
  }
})();
