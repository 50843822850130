(() => {
  angular
    .module('finance')
    .controller(
      'finance.views.resultModal',
      ResultModalController
    );

  ResultModalController.$inject = [
    '$uibModalInstance',
    'errors',
    'title',
  ];

  function ResultModalController(
    $uibModalInstance,
    errors,
    title) {
    const vm = this;

    vm.errorText = errors;
    vm.title = title;

    vm.cancel = cancel;

    init();

    function init() {}

    function cancel() {
      $uibModalInstance.dismiss();
    }

  }
})();
