(() => {
  angular
    .module('app')
    .controller(
      'clinic.views.employees.applyPostHospitalizationGuaranteeLetterModal.index',
      ApplyPostHospitalizationGuaranteeLetterModalController
    );

  ApplyPostHospitalizationGuaranteeLetterModalController.$inject = [
    '$uibModalInstance',
    'abp.services.app.postHospitalizationGuaranteeLetter',
    'clinic',
    'employee',
    'moment',
  ];

  function ApplyPostHospitalizationGuaranteeLetterModalController(
    $uibModalInstance,
    postHospitalizationGuaranteeLetterSvc,
    clinic,
    employee,
    moment
  ) {
    const vm = this;

    vm.saving = 0;
    vm.loading = 0;
    vm.employee = employee;
    vm.clinic = clinic;
    vm.disciplineId = null;
    vm.isDisciplineInReferralExceptions = false;
    vm.source = 3 // GuaranteeLetterSource.Panel

    vm.appointmentDateOptions = {
      min: moment().startOf('day'),
      max: moment().startOf('day'),
    };

    vm.newAttachments = [];
    vm.uploadConfig = {
      objectType: 'LetterAttachment',
    };

    vm.filteredAdmissions = [];
    vm.patientPreviousAdmissions = [];
    vm.lookupDiagnoses = lookupDiagnoses;
    vm.setAdmissionRecord = setAdmissionRecord;
    vm.checkAppointmentDateValidity = checkAppointmentDateValidity;
    vm.getGuaranteeLetterForCreate = getGuaranteeLetterForCreate;
    vm.submit = submit;
    vm.isInvalidAttachment = isInvalidAttachment;
    vm.cancel = cancel;

    init();

    function init() {
      vm.getGuaranteeLetterForCreate();
      vm.lookupDiagnoses();
    }

    function getGuaranteeLetterForCreate() {
      if (vm.employee) {
        reset();

        vm.loading += 1;
        postHospitalizationGuaranteeLetterSvc
          .getGuaranteeLetterForCreate({
            id: vm.employee.id,
          })
          .success((data) => {
            vm.principal = data.principal;
            [vm.patient] = data.patients;
            data.previousAdmissionRecords.forEach(patientPreviousAdmission => {
              if (patientPreviousAdmission.employeeId === vm.employee.id) {
                vm.patientPreviousAdmissions = patientPreviousAdmission.previousAdmissions;
              }
            });
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    }

    function lookupDiagnoses(filter) {
      const keyword = _.trim(filter || '');
      if (keyword) {
        const filteredAdmissions = [];

        const regexes = _.chain(keyword.split(' '))
          .map((k) => _.trim(k.toUpperCase()))
          .filter((k) => k)
          .uniq()
          .map((k) => ({
            token: k,
            len: k.length,
            pattern: new RegExp(`\\b${k}`, 'i'),
          }))
          .value();

        _.forEach(vm.filteredAdmissions, (d) => {
          let score = 0;
          _.forEach(regexes, (r) => {
            if (r.pattern.test(d.diagnosesDisplayName)) {
              score -= r.len;
            }
          });
          if (score) {
            filteredAdmissions.push({
              score,
              item: d,
            });
          }
        });

        vm.filteredAdmissions = _.chain(filteredAdmissions)
          .sortBy(['score'])
          .take(30)
          .map((k) => k.item)
          .uniqBy((d) => d.id)
          .value();
      } else {
        vm.filteredAdmissions = vm.patientPreviousAdmissions;
      }
    }

    function setAdmissionRecord($item) {
      vm.letter.doctors = [];
      vm.doctors = $item.doctors;
      vm.letter.panelId = $item.panelId;
      vm.letter.panelName = $item.panelName;
      vm.isSelectDateMoreThanMaxDate = false;

      vm.finalDiagnosis = $item.diagnosesDisplayName;
      vm.guaranteeLetterNumber = $item.guaranteeLetterNumber;
      vm.doctors = $item.doctors;
      vm.letter.doctors = $item.doctors;
      vm.letter.doctorIds = [];
      $item.doctors.forEach((doctor) => {
        vm.letter.doctorIds.push(doctor.id);
      });

      vm.dischargeDate = moment($item.dischargeDate).startOf('day');
      vm.admissionDate = moment($item.admissionDate).startOf('day');
      // HM-8806: The range of date to be chosen is 
      // from today's date to the end of SOB coverage date.
      vm.todayDate = moment().startOf('day');
      vm.admissionTimeOptions = {
        min: vm.admissionDate,
        max: vm.dischargeDate,
      };
      vm.maxAppintmentDate = moment(vm.dischargeDate).add(1, 'days');
      vm.appointmentDateOptions = {
        min: vm.todayDate,
        max: moment(vm.dischargeDate).add(vm.patient.maxCoveredDays, 'days').startOf('day'),
      };

      vm.postHospitalizationCoverageDays = vm.patient.maxCoveredDays;
      if (vm.todayDate > vm.appointmentDateOptions.max) {
        vm.isSelectDateMoreThanMaxDate = true;
      }

      vm.letter.guaranteedAmount = vm.patient.guaranteedAmount;
    }

    function checkAppointmentDateValidity(appointmentDate) {
      vm.isSelectDateMoreThanMaxDate = appointmentDate > vm.appointmentDateOptions.max;
    }

    function isInvalidAttachment() {
      return (
        !vm.isDisciplineInReferralExceptions &&
        !vm.newAttachments.length
      );
    }

    function submit() {
      const input = $.extend(vm.letter, {
        employeeId: vm.employee.id,
        mobileNumber: vm.principal.mobileNumber,
        emailAddress: vm.principal.emailAddress,
        newAttachments: vm.newAttachments,
        source: vm.source
      });

      if (
        vm.letter.appointmentDate > vm.appointmentDateOptions.max &&
        vm.letter.appointmentDate != null
      ) {
        abp.message.error(App.localize('SelectDateMoreThanCoverageWarning'));
        vm.letter.appointmentDate = null;
        return;
      }

      vm.saving += 1;
      postHospitalizationGuaranteeLetterSvc
        .createGuaranteeLetter(input)
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySubmitted'));
          $uibModalInstance.close({
            isApproved: input.signatureProfileId,
          });
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function reset() {
      vm.principal = null;
      vm.patients = null;
      vm.letter = {};
      vm.filteredAdmissions = [];
      vm.clinicLocations = [];
      vm.noPatients = false;
      vm.letter.appointmentDate = vm.appointmentDateOptions.min;
    }
  }
})();
