(function () {
    var module = angular.module('revenue');
    module.consts = {
        grid: {
            defaultPageSizes: [10, 20, 50, 100],
            defaultPageSize: 10
        },
        emptyGuid: '00000000-0000-0000-0000-000000000000'
    };
})();

(() => {
    angular.module('revenue').constant('revenueConstants', {
      grid: {
        defaultPageSizes: [10, 20, 50, 100],
        defaultPageSize: 10,
      },
      emptyGuid: '00000000-0000-0000-0000-000000000000'
    });
})();
  