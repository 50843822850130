(function () {
    var controllerId = 'clinic.views.hospital.topUpRequest';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$stateParams', '$state', 'abp.services.app.hospitalPanelServiceRequest', 'abp.services.app.commonLookup',
        function ($scope, $stateParams, $state, hospitalPanelServiceRequestSvc, commonLookupSvc) {
            var vm = this;
            vm.loading = 0;
            vm.saving = 0;
            vm.letterNumber = $stateParams.letterNumber;
            vm.panelLocationId = $stateParams.panelLocationId;
            vm.requestId = $stateParams.requestId;
            vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
            vm.hasInpatientRegionalExpansion = abp.setting.getBoolean(
                'Hms.Feature.RegionalExpansionForInpatient');

            vm.requestParams = { requestType: $stateParams.requestType };

            vm.activeTab = 0;
            vm.topUpRequest = {
                interimBillAmount: null,
                selectedDoctorIds: [],
                newAttachments: []
            };
            vm.uploadConfig = { objectType: 'InpatientTopupAddonRequestAttachment' };
            vm.checklistItems = [
                { text: App.localize('CompletedTopUpAddOnRequestForm'), required: true },
                { text: App.localize('InterimBillSummaryBreakdown'), required: vm.requestParams.requestType === 1 || vm.requestParams.requestType === 2 },
                { text: App.localize('AllInvestigationReport'), required: true },
                { text: App.localize('AdditionalAttendingPhysicianStatement'), required: vm.requestParams.requestType === 1 || vm.requestParams.requestType === 3 }

            ];

            vm.nextTab = nextTab;
            vm.previousTab = previousTab;
            vm.save = save;
            vm.setActiveTab = setActiveTab;
            vm.downloadTopUpAddOnRequestForm = downloadTopUpAddOnRequestForm;
            vm.downloadAttendingPhysicianStatement = downloadAttendingPhysicianStatement;
            
            function init() {
                getDoctors();
                if (vm.hasInpatientRegionalExpansion) {
                    getLanguage();
                    getCurrency();
                }
            }

            /* Functions */

            function getDoctors() {
                vm.loading++;
                hospitalPanelServiceRequestSvc.getDoctorsNotInGL({
                    letterNumber: vm.letterNumber
                    })
                    .success(function (data) {
                        vm.specialistDoctors = data;
                    })
                    .finally(function () { vm.loading--; });
            }

            function getLanguage() {
                vm.loading += 1;
                commonLookupSvc
                    .getDefaultLanguageByCountryCode()
                    .success((data) => { vm.language = data })
                    .finally(() => { vm.loading -= 1 });
            }

            function getCurrency() {
                vm.loading += 1;
                commonLookupSvc
                    .getTenantCurrencyCode()
                    .success((data) => { vm.currencyCode = data })
                    .finally(() => { vm.loading -= 1 });
            }

            function nextTab() {
                if (vm.activeTab === 2) {
                    return;
                }
                else if (vm.activeTab === 0 && !App.isFormValid($scope.forms.topUp)) {
                    return;
                }
                else if (vm.activeTab === 1) {
                    vm.invalidAttachment = vm.topUpRequest.newAttachments.length < 1;
                    vm.invalidChecklist = !App.isFormValid($scope.forms.uploadDocuments)
                        && _.some(vm.checklistItems, function (d) { return d.required && !d.isChecked; });
                    if (vm.invalidAttachment || vm.invalidChecklist) {
                        return;
                    }
                }
                setActiveTab(vm.activeTab + 1);
            }

            function previousTab() {
                setActiveTab(vm.activeTab - 1);
            }

            function save() {
                vm.saving++;

                var checklistItems = [];
                _.each(vm.checklistItems, function (item) {
                    checklistItems.push({
                        text: item.text,
                        isSelected: item.isChecked,
                        isMandatory: item.required
                    });
                });

                var input = $.extend(vm.topUpRequest, {
                    letterNumber: vm.letterNumber,
                    documentChecklistItems: checklistItems,
                    doctorIds: vm.topUpRequest.selectedDoctorIds
                });

                hospitalPanelServiceRequestSvc.requestTopupAddon(input)
                    .success(function (data) {
                        abp.notify.success(App.localize('Submitted'));
                    }).finally(function () {
                        vm.saving--;
                        $state.go("clinic.inpatientDashboard");
                    });
            }

            function setActiveTab(tab) {
                if (tab < 0 || tab > 2) return; // Prevent tab out of range.
                vm.activeTab = tab;
            }

            function downloadTopUpAddOnRequestForm(language) {
                vm.exporting++;
                // Pass in prefilled data
                hospitalPanelServiceRequestSvc.getTopUpAddOnRequestFormPdf(vm.letterNumber, language)
                    .success(function (data) {
                        App.downloadTempFile(data);
                    }).finally(function () {
                        vm.exporting--;
                    });
            }

            function downloadAttendingPhysicianStatement(language) {
                var option = {
                    requestId: vm.requestId,
                    languageCode: language,
                };
                window.open(App.formatUrl('Print/AttendingPhysicianStatement?', option))
            }

            /* End of Functions */

            init();
        }
    ]);
})();
