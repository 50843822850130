(function () {
    'use strict';

    angular.module('app').component('hostCorporatesSupportPlansCreateEditBasicsHospitalizationComponent', {
        templateUrl: require('/App/host/views/corporates/supportPlans/createEdit/basics/hospitalization/hospitalization.component.html'),
        controller: ['$sce', controller],
        controllerAs: 'vm',
        bindings: {
            supportPlan: '<'
        }
    });

    function controller($sce) {
        var vm = this;
        vm.daysText = App.localize('Days');
        vm.yearsText = App.localize('Year');
        vm.deliveryText = App.localize('Delivery');
        vm.illnessInfo = App.localize('SpecifiedIllnessTooltip');

        vm.displaySafeHtml = function (html) {
            return $sce.trustAsHtml(html);
        };

        vm.toggleHospitalizationBasics = function () {
            vm.minAccidentDays = vm.supportPlan.hospitalizationSetting.isAccidentAdmission === 1 ? 1 : 0;
            vm.minIllnessDays = vm.supportPlan.hospitalizationSetting.isIllnessAdmission === 1 ? 1 : 0;
            vm.minPreExistingDays = vm.supportPlan.hospitalizationSetting.isPreExisting === 1 ? 1 : 0;
            vm.minNonAccidentalIllnessDays = vm.supportPlan.hospitalizationSetting.isNonAccidentalIllness === 1 ? 1 : 0;
        };

        // Feature flag.

        vm.hasHospitalizationWaitingPeriodEnhancement = abp.setting.getBoolean('Hms.Feature.HospitalisationWaitingPeriodEnhancement');

        function init() {
            vm.toggleHospitalizationBasics();
        }

        angular.element(document).ready(function () {
            init();
        });
    }
})();
