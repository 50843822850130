(() => {
  angular
    .module('app')
    .component('commonInpatientTicketsDetailInHospitalDoctorVisitChargesComponent', {
      templateUrl: require('./inHospitalDoctorVisitCharges.component.html'),
      controller: InHospitalDoctorVisitChargesController,
      controllerAs: 'vm',
      bindings: {
        ticket: '<',
        doctors: '<',
        isEdit: '<',
        isSaved: '<',
        categories: '<',
        benefits: '<',
        admissionDate: '@',
        dischargeDate: '@',
        isCreate: '<',
        currencyCode: '<',
        isClaim: '<'
      },
    });

  InHospitalDoctorVisitChargesController.$inject = [
    'Hms.Doctors.DoctorType',
    'Hms.PatientTickets.PatientTicketType',
    'Hms.ServiceRequests.ServiceRequestType',
    'moment',
  ];

  function InHospitalDoctorVisitChargesController(
    enumDoctorType,
    enumPatientTicketType,
    enumServiceRequestType, 
    moment
  ) {

    const vm = this;

    // Declared angularJs variables.

    vm.defaultCategory = 'InHospitalDoctorVisit';
    vm.totalDays = 0;
    vm.isAdjustment = false;
    vm.hasTxtFileSupport = abp.setting.getBoolean('Hms.Feature.TxtFileSupport');
    vm.hasInpatientClaimProcessingEnhancement = abp.setting.getBoolean(
      'Hms.Feature.InpatientClaimProcessingEnhancement'
    );
    vm.hasInsuranceEnhancementModule = abp.setting.getBoolean(
      'Hms.Feature.InsuranceEnhancementModule'
    );
    vm.init = true; // Flag for init() run-only codes.

    vm.enums = {
      doctorType: enumDoctorType,
      patientTicketType: enumPatientTicketType,
      serviceRequestType: enumServiceRequestType
    };

    // Old visit types choices for in-hospital doctor visit.
    // Used while TxtFileSupport feature flag is off.

    vm.oldVisitTypes = [
      { value: 1, name: App.localize('VisitType_Normal') },
      { value: 2, name: App.localize('VisitType_ICU') },
      { value: 3, name: App.localize('VisitType_NormalOffHours') },
    ];

    // New visit types choices for in-hospital doctor visit.
    // Used while TxtFileSupport feature flag is on.

    vm.visitTypes = [
      { value: 1, name: App.localize('FirstConsultationOfficeHours') },
      { value: 3, name: App.localize('FirstConsultationAfterOfficeHours') },
      { value: 4, name: App.localize('NormalWardFollowUpVisitDuringOfficeHours') },
      { value: 5, name: App.localize('NormalWardFollowUpVisitAfterOfficeHours') },
    ];

    // Doctor types choices for in-hospital doctor visit.

    vm.doctorTypes = [
      { value: 1, name: App.localize('Anaesthetist') },
      { value: 2, name: App.localize('Doctor') },
    ];

    // Remark options.

    vm.remarkOptions = [
      { id: 1, displayName: App.localize('Consultation') },
      { id: 2, displayName: App.localize('Visitation') },
      { id: 3, displayName: App.localize('ConsultationAndVisitation') },
    ];

    vm.formattedCategories = [];
    const defaultTicketItem = {
      category: '',
      isManualEdit: 0,
      ticketItems: [
        {
          subTreatments: [],
          procedures: [],
          totalVisit: 1,
          visit: 1,
          isOverride: 0,
          visitDate: moment(vm.ticket.admissionDate),
        },
      ],
    };
    vm.isInpatientTicketV2 = vm.hasInsuranceEnhancementModule && vm.ticket.version === 'V2';

    vm.$onChanges = onChanges;
    vm.addDoctor = addDoctor;
    vm.removeDoctor = removeDoctor;
    vm.addProcedures = addProcedures;
    vm.clearAllProcedures = clearAllProcedures;
    vm.calculateTotal = calculateTotal;
    vm.calculateAmount = calculateAmount;
    vm.getAmount = getAmount;
    vm.updateProcedure = updateProcedure;
    vm.updateVisit = updateVisit;
    vm.deleteProcedure = deleteProcedure;
    vm.lookupRemark = lookupRemark;
    vm.addVisit = addVisit;
    vm.manualEdit = manualEdit;
    vm.cancelManualEdit = cancelManualEdit;
    vm.saveManualEdit = saveManualEdit;
    vm.updateDoctorTypes = updateDoctorTypes;
    vm.updateCategory = updateCategory;
    vm.updateSubCategory = updateSubCategory;
    vm.fulfillV2ViewCondition = fulfillV2ViewCondition;
    vm.getSubtotalAmount = getSubtotalAmount;
    vm.getTotalCoveredAmount = getTotalCoveredAmount;
    vm.getTotalUncoveredAmount = getTotalUncoveredAmount;

    // To detect changes to ticket values.

    function onChanges(changes) {
      // If first time loading component, run grouping logic once only.

      if (vm.init) {
        if (vm.hasInsuranceEnhancementModule) {
          vm.ticket.procedures = _.chain(vm.ticket.procedures)
          .groupBy('category')
          .map((value, key) => ({
            category: key,
            ticketItems: value,
            isManualEdit: 0,
            doctorId: vm.ticket.ticketType !== 2
              && !Number.isNaN(Number(value[0].doctorId)) ? Number(value[0].doctorId, 10) : null,
            doctorType: Number(value[0].doctorType) ? Number(value[0].doctorType) : null,
            doctorName: vm.ticket.ticketType !== 2 
              && vm.ticket.doctorId ? getDoctorName(Number(value[0].doctorId)) : null,
          }))
          .value();
        } else {
          vm.ticket.procedures = _.chain(vm.ticket.procedures)
          .groupBy('doctorId')
          .map((value, key) => ({
            doctorId:
              vm.ticket.ticketType !== 2 && !Number.isNaN(Number(key)) ? Number(key, 10) : null,
            doctorType: Number(value[0].doctorType) ? Number(value[0].doctorType) : null,
            ticketItems: value,
            doctorName: vm.ticket.ticketType !== 2 && key ? getDoctorName(Number(key)) : null,
            isManualEdit: 0,
          }))
          .value();
        }

        if (vm.hasInpatientClaimProcessingEnhancement) {
          if (vm.hasTxtFileSupport) {
            vm.visitTypes.push(
              { value: 9, name: App.localize('NormalWardFirstConsultationDuringOfficeHours') },
              { value: 10, name: App.localize('NormalWardFirstConsultationAfterOfficeHours') },
              { value: 11, name: App.localize('ICUWardFirstConsultationDuringOfficeHours') },
              { value: 12, name: App.localize('ICUWardFirstConsultationAfterOfficeHours') },
              { value: 13, name: App.localize('ICUFollowUpVisitDuringOfficeHours') },
              { value: 14, name: App.localize('ICUFollowUpVisitAfterOfficeHours') }
            );
          } else {
            vm.oldVisitTypes.push(
              { value: 9, name: App.localize('NormalWardFirstConsultationDuringOfficeHours') },
              { value: 10, name: App.localize('NormalWardFirstConsultationAfterOfficeHours') },
              { value: 11, name: App.localize('ICUWardFirstConsultationDuringOfficeHours') },
              { value: 12, name: App.localize('ICUWardFirstConsultationAfterOfficeHours') },
              { value: 13, name: App.localize('ICUFollowUpVisitDuringOfficeHours') },
              { value: 14, name: App.localize('ICUFollowUpVisitAfterOfficeHours') }
            );
          }
        }

        if (vm.hasInsuranceEnhancementModule) formatCategories();
        if (vm.isInpatientTicketV2) getTicketItems();

        // Set init flag to false to prevent double grouping when edit patient ticket.

        vm.init = false;
      }

      if (vm.ticket.procedures.length === 0) {
        if (vm.isEdit) {
          vm.addDoctor();
        }
      } else {
        enrichTicketItem();
      }

      if (vm.ticket.requestType === 'Adjustment') {
        const distinctDays = [];

        // Add days and number of days used in FGL.

        _.each(vm.ticket.procedures, (x) => {
          _.each(x.ticketItems, (p) => {
            if (!distinctDays.includes(p.visitDate.format('l'))) {
              vm.totalDays += 1;
            }

            distinctDays.push(p.visitDate.format('l'));
          });
        });

        vm.isAdjustment = true;
      }

      if (changes.ticket && changes.ticket.currentValue) {
        if (vm.ticket.procedures.length === 0) {
          if (vm.isEdit) {
            vm.addDoctor();
          }
        } else {
          enrichTicketItem();
        }
      }

      if (changes.isSaved && changes.isSaved.currentValue) {
        tidyProcedures();
      }

      if (changes.isEdit && changes.isEdit.currentValue) {
        if (vm.ticket && vm.ticket.procedures.length < 1) {
          vm.addDoctor();
        }
      }
    }

    // To calculate additional information for ticket item
    // during init() function or when changes to ticket is detected.

    function enrichTicketItem() {
      _.forEach(vm.ticket.procedures, (procedure) => {
        _.forEach(procedure.ticketItems, (item) => {
          const i = item;
          const visitDate = i.startDate != null ? i.startDate : vm.ticket.admissionDate;
          i.visitDate = moment(visitDate);
          i.totalCharges = i.amount + i.uncoveredAmount;
          i.isOverride = 0;
        });
      });
    }

    // To add doctor item.

    function addDoctor(id) {
      const categoryItem = vm.hasInsuranceEnhancementModule
        ? JSON.parse(JSON.stringify(defaultTicketItem))
        : {
            doctorId: id || null,
            doctorName: getDoctorName(id),
            ticketItems: [
              {
                doctorId: id || null,
                category: vm.defaultCategory,
                categoryName: getCategoryName(vm.defaultCategory),
                totalVisit: 1,
                visit: 1,
                isOverride: 0,
              },
            ],
            isManualEdit: 0,
          };
      vm.ticket.procedures.push(categoryItem);
    }

    // To remove latest doctor item.
    // Can only remove when there is 2 or more doctor items.

    function removeDoctor() {
      if (vm.ticket.procedures.length > 1) {
        vm.ticket.procedures.pop();
      }
    }

    // Add procedure item to doctor item.

    function addProcedures(record) {
      if (vm.hasInsuranceEnhancementModule) {
        const newTicketItem = JSON.parse(JSON.stringify(defaultTicketItem.ticketItems[0]));

        // Format new ticket item.

        newTicketItem.category = record.category;

        // Add sub treatments items.

        if (record.category) {
          const category = _.filter(
            vm.formattedCategories,
            (f) => f.mainTreatmentCategoryName === record.category
          );

          newTicketItem.subTreatments = category[0].subTreatmentCategories.map(
            (s) => s.subTreatmentItemName
          );
          newTicketItem.section = category[0].sectionType;
          newTicketItem.doctorId = record.doctorId;
        }

        // Add new ticket item.

        record.ticketItems.push(newTicketItem);
      } else {
        const r = record;
        if (!r.ticketItems) {
          r.ticketItems = [];
        }

        r.ticketItems.push({
          doctorId: r.doctorId,
          doctorType: r.doctorType,
          category: vm.defaultCategory,
          categoryName: getCategoryName(vm.defaultCategory),
          totalVisit: 1,
          visit: 1,
          isOverride: 0,
        });
      }
    }

    // Clear all procedure items from a doctor item.
    // Leaves at least one empty procedure item in the doctor item.

    function clearAllProcedures(category) {
      const c = category;
      c.ticketItems = [];
      vm.addProcedures(c);
    }

    // Used to tidy procedures during when ticket changes is saved.

    function tidyProcedures() {
      vm.ticket.procedures = _.filter(vm.ticket.procedures, (item) =>
        _.filter(item.ticketItems, (k) => !_.isNil(k.amount))
      );
    }

    // To calculate the total amount.

    function calculateTotal(index, item) {
      const i = item;
      let uncoveredAmount = 0;
      let amount = 0;
      if (i[index].amount > 0) {
        amount = i[index].amount;
      }
      if (i[index].uncoveredAmount > 0) {
        uncoveredAmount = i[index].uncoveredAmount;
      }
      const totalCharges = amount + uncoveredAmount;
      i[index].totalCharges = totalCharges;
    }

    // To calculate amount for each individual procedure item in a doctor item.

    function calculateAmount(index, item, parentIndex) {
      const i = item;
      vm.ticket.procedures[parentIndex].ticketItems[index].isOverride = 0;

      let category = '';
      let visits = 0;
      let totalCharges = 0;

      if (i[index].category) category = i[index].category;

      if (i[index].totalCharges > 0) totalCharges = i[index].totalCharges;

      i[index].hasExceeded = false;

      if (vm.hasInsuranceEnhancementModule) {
        i[index].amount = totalCharges;
        i[index].uncoveredAmount = 0;
        return;
      }
      // Validate visits per day.

      const benefitByVisitsPerDay = _.first(
        _.filter(vm.benefits, (x) => x.category === category && x.type === 1 && x.cycle === 3)
      );

      if (i[index].isOverride === 1) return;

      let coveredTotalCharges = 0;
      let uncoveredTotalCharges = 0;

      visits = _.filter(
        i,
        (o) =>
          o.visitDate &&
          i[index].visitDate &&
          o.visitDate.format('l') === i[index].visitDate.format('l')
      ).length;

      const isUnlimited = benefitByVisitsPerDay.isUnlimited || false;
      if (!isUnlimited) {
        const visitsPerDay = benefitByVisitsPerDay.balanceDays || 0;
        if (visits > visitsPerDay) {
          uncoveredTotalCharges = totalCharges;

          if (visits) i[index].hasExceeded = true;
        } else {
          coveredTotalCharges = totalCharges;
        }
      } else {
        coveredTotalCharges = totalCharges;
      }

      i[index].amount = coveredTotalCharges;
      i[index].uncoveredAmount = uncoveredTotalCharges;

      // Validate days per year.

      validateTotalVisitDays(category);
    }

    // To validate total visit days.

    function validateTotalVisitDays(category) {
      let totalDays = 0;
      const distinctVisitDays = []; // List of distinct days within limit.
      const extraDays = []; // List of distinct days out of limit.

      const benefitByDaysPerYear = _.first(
        _.filter(vm.benefits, (x) => x.category === category && x.type === 1 && x.cycle === 1)
      );

      _.each(vm.ticket.procedures, (x) => {
        _.each(x.ticketItems, (ticketItem) => {
          const ti = ticketItem;
          let coveredTotalCharges = 0;
          let uncoveredTotalCharges = 0;

          if (
            ti.visit === 1 &&
            ti.visitDate &&
            !distinctVisitDays.includes(ti.visitDate.format('l'))
          ) {
            totalDays += 1;
            distinctVisitDays.push(ti.visitDate.format('l'));

            if (totalDays > vm.totalDays && vm.isAdjustment)
              extraDays.push(ti.visitDate.format('l'));
          }

          if (ti.isOverride === 1) return;

          const isUnlimited = benefitByDaysPerYear.isUnlimited || false;
          if (!isUnlimited) {
            const daysPerYearBalance = benefitByDaysPerYear.balanceDays || 0;

            if (vm.isAdjustment && extraDays.includes(ti.visitDate.format('l'))) {
              // Check if SOB has balance.

              if (daysPerYearBalance > totalDays) {
                _.remove(extraDays, ti.visitDate.format('l'));
                vm.totalDays += 1;
              }

              ti.hasExceeded = true;
            } else if (!vm.isAdjustment && totalDays > daysPerYearBalance) {
              ti.hasExceeded = true;
            }
          }

          if (ti.hasExceeded) {
            uncoveredTotalCharges = ti.totalCharges;
          } else {
            coveredTotalCharges = ti.totalCharges;
          }

          ti.amount = coveredTotalCharges;
          ti.uncoveredAmount = uncoveredTotalCharges;
        });
      });
    }

    // To calculate subtotal of each procedure item in a doctor item.

    function getAmount() {
      let amount = 0;
      let uncoveredAmount = 0;

      _.each(vm.ticket.procedures, (procedure) => {
        const x = procedure;
        x.showExceededMessage = false;

        _.each(x.ticketItems, (p) => {
          if (p.amount) {
            amount += p.amount;
          }

          if (p.uncoveredAmount) {
            uncoveredAmount += p.uncoveredAmount;
          }

          if (p.hasExceeded) {
            x.showExceededMessage = true;
          }
        });
      });

      return {
        subtotalAmount: App.roundAmount(amount + uncoveredAmount),
        totalCoveredAmount: App.roundAmount(amount),
        totalUncoveredAmount: App.roundAmount(uncoveredAmount),
      };
    }

    // Update procedure item at target index.

    function updateProcedure(index, item) {
      const i = item;
      if ((typeof i.doctorId === 'undefined' || i.doctorId === null) && !vm.hasTxtFileSupport) {
        if (vm.ticket.procedures.length > 1) {
          vm.ticket.procedures.splice(index, 1);
        } else {
          vm.ticket.procedures = [];
          vm.addDoctor();
        }
      } else {
        const hospitalizationDates = getHospitalizationDates();
        if (hospitalizationDates.length > 0 && i.ticketItems.length < hospitalizationDates.length) {
          i.ticketItems = [];

          _.forEach(hospitalizationDates, (date) => {
            i.ticketItems.push(
              {
                visitDate: date,
                visit: 1,
              },
              {
                visitDate: date,
                visit: 2,
              }
            );
          });
        }

        if (i.ticketItems) {
          _.each(i.ticketItems, (ticketItem) => {
            const ti = ticketItem;
            ti.doctorId = i.doctorId;
            ti.doctorType = i.doctorType;
            ti.totalVisit = 1;

            if (!vm.hasInsuranceEnhancementModule) {
              ti.category = vm.defaultCategory;
              ti.categoryName = getCategoryName(vm.defaultCategory);
            }
          });
        }
      }
    }

    // Update visit count.
    // Index, item, parentIndex are nullable because the delete button
    // can call this function without params.

    function updateVisit(index = null, item = null, parentIndex = null) {
      _.each(vm.ticket.procedures, (x) => {
        const dates = [];

        _.each(x.ticketItems, (ticketItem) => {
          const ti = ticketItem;
          if (ti.visitDate) {
            const recordDate = moment(ti.visitDate).startOf('day').toString();
            dates.push(recordDate);

            const countVisit = _.filter(dates, (s) => recordDate === s).length;

            ti.visit = countVisit;
          }
        });
      });

      if (index && item && parentIndex) {
        calculateAmount(index, item, parentIndex);
      }
    }

    // Get hospitalization dates.

    function getHospitalizationDates() {
      const hospitalizationDates = [];
      let minDate = null;
      let maxDate = null;

      _.each(vm.ticket.rooms, (x) => {
        _.each(x.ticketItems, (p) => {
          if (p.roomChargeDateRangeModel) {
            const startDate = moment(p.roomChargeDateRangeModel.startDate);
            const endDate = moment(p.roomChargeDateRangeModel.endDate);

            for (
              let currentDate = moment(startDate);
              currentDate <= endDate;
              currentDate = currentDate.add(1, 'day')
            ) {
              let isNewRecord = true;
              const currentRecord = moment(currentDate);

              if (hospitalizationDates.length > 0) {
                _.each(hospitalizationDates, (date) => {
                  if (date.isSame(currentRecord)) {
                    isNewRecord = false;
                  }
                });
              }

              if (isNewRecord) {
                hospitalizationDates.push(currentRecord);
                if (currentDate < minDate || minDate === null) {
                  minDate = moment(currentRecord);
                }

                if (currentDate > maxDate || maxDate === null) {
                  maxDate = moment(currentRecord);
                }
              }
            }
          }
        });
      });

      return hospitalizationDates;
    }

    // Delete specific procedure at specified index.

    function deleteProcedure(index, item) {
      const i = item;
      if (i.ticketItems.length > 1) {
        i.ticketItems.splice(index, 1);
      } else {
        i.ticketItems = [];
        vm.addProcedures(i);
      }
      vm.updateVisit();
    }

    // To Look-up remark.

    function lookupRemark(userEntry) {
      const cleanedUserEntry = cleanUserEntry(userEntry || '');
      const filter = _.toLower(cleanedUserEntry);

      if (filter) {
        vm.filteredRemark = postProcessRemarkList(vm.remarkOptions);
      } else {
        vm.filteredRemark = [];
      }

      function postProcessRemarkList(remarks) {
        const clonedItems = remarks.slice(0);
        const idx = _.findIndex(
          clonedItems,
          (d) => _.toLower(_.trim(d.displayName || '')) === filter
        );
        if (idx > 0) {
          const items = clonedItems.splice(idx, 1);
          clonedItems.splice(0, 0, items[0]);
        } else if (idx === -1) {
          clonedItems.splice(0, 0, {
            id: -1,
            displayName: cleanedUserEntry,
          });
        }
        return clonedItems;
      }
    }

    // To clean user entry before look-up.

    function cleanUserEntry(entry) {
      return _.map(
        _.split(_.toLower(entry), ' '),
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      ).join(' ');
    }

    // To add visit count.

    function addVisit(index, item) {
      const i = item;
      let visits = 1;

      if (i[index].visit > 0) visits = i[index].visit;

      i[index].visit = visits + 1;
    }

    // To enable manual edit mode for procedure item.
    // Creates a copy of procedure item to revert later if canceled.

    function manualEdit(index) {
      vm.tempPlaceHolder = angular.copy(vm.ticket.procedures[index]);
      vm.ticket.procedures[index].isManualEdit = 1;
    }

    // To cancel manual edit mode for procedure item.
    // Restores procedure item to previous state before manual edit mode enabled.

    function cancelManualEdit(index) {
      vm.ticket.procedures[index] = angular.copy(vm.tempPlaceHolder);
      vm.ticket.procedures[index].isManualEdit = 0;
      vm.tempPlaceHolder = null;
    }

    // To save manual edit changes permenantly.

    function saveManualEdit(index) {
      vm.ticket.procedures[index].isManualEdit = 0;

      _.each(vm.ticket.procedures[index].ticketItems, (ticketItem) => {
        const ti = ticketItem;
        ti.isOverride = 1;
      });

      vm.tempPlaceHolder = null;
    }

    // To get category name.

    function getCategoryName(category) {
      const selectedCategory = _.find(vm.categories, { id: category });
      return selectedCategory ? selectedCategory.displayName : null;
    }

    // To get doctor name.

    function getDoctorName(id) {
      const idToMatch = parseInt(id, 10);
      if (vm.doctors) {
        const selectedDoctor = _.find(vm.doctors, (d) => d.id === idToMatch);

        if (selectedDoctor) {
          return selectedDoctor.name;
        }
      }

      return null;
    }

    // To update doctor types.

    function updateDoctorTypes(index, item) {
      if (typeof item.doctorType === 'undefined' || item.doctorType === null) {
        if (vm.ticket.procedures.length > 1) {
          vm.ticket.procedures.splice(index, 1);
        } else {
          vm.ticket.procedures = [];
          vm.addDoctor();
        }
      }
    }

    function formatCategories() {
      // Get main categories.

      _.forEach(vm.categories, (x) => {
        if (
          vm.formattedCategories
            .map((e) => e.mainTreatmentCategoryName)
            .indexOf(x.mainTreatmentCategoryName) < 0
        ) {
          vm.formattedCategories.push({
            mainTreatmentCategoryName: x.mainTreatmentCategoryName,
            sectionType: x.sectionType,
            subTreatmentCategories: [],
          });
        }
      });

      // Get sub categories.

      _.forEach(vm.formattedCategories, (x) => {
        const subTreatments = _.filter(
          vm.categories,
          (c) => c.mainTreatmentCategoryName === x.mainTreatmentCategoryName
        );

        _.forEach(subTreatments, (s) => {
          if (
            x.subTreatmentCategories
              .map((e) => e.subTreatmentItemName)
              .indexOf(s.subTreatmentItemName) < 0
          ) {
            x.subTreatmentCategories.push({
              subTreatmentItemName: !s.subTreatmentItemName
                ? x.mainTreatmentCategoryName
                : s.subTreatmentItemName,
              procedures: [],
            });
          }
        });
      });

      // Get procedures.

      _.forEach(vm.formattedCategories, (x) => {
        _.forEach(x.subTreatmentCategories, (s) => {
          const procedures = _.filter(
            vm.categories,
            (c) => c.subTreatmentItemName === s.subTreatmentItemName
          );

          _.forEach(procedures, (p) => {
            if (p.procedures) {
              s.procedures.push(p.procedures);
            }
          });
        });
      });
    }

    function updateCategory(index, item) {
      if (item.category) {
        // Clear sub categories & procedures.

        _.forEach(item.ticketItems, (x) => {
          x.subTreatment = '';
          x.procedure = '';
          x.uncoveredProcedure = '';
          x.subTreatments = [];
          x.procedures = [];
        });

        // Update each ticketItem in item.

        const category = _.filter(
          vm.formattedCategories,
          (f) => f.mainTreatmentCategoryName === item.category
        );

        _.forEach(item.ticketItems, (x) => {
          x.category = item.category;
          x.categoryName = item.category;
          x.section = category[0].sectionType;

          // Add sub treatments items.

          x.subTreatments = category[0].subTreatmentCategories.map((s) => s.subTreatmentItemName);
        });
      } else {
        clearAllProcedures(item);
      }
    }

    function updateSubCategory(index, item, ticketItem) {
      // Clear procedures.

      ticketItem.procedure = '';
      ticketItem.uncoveredProcedure = '';
      ticketItem.procedures = [];

      // Add procedure items.

      const category = _.filter(
        vm.formattedCategories,
        (f) => f.mainTreatmentCategoryName === item.category
      );

      const subTreatment = _.filter(
        category[0].subTreatmentCategories,
        (s) => s.subTreatmentItemName === ticketItem.subCategory
      ); 

      ticketItem.procedures = subTreatment[0].procedures;
    }

    function getTicketItems() {
      const ticketItems = _.filter(
        vm.ticket.ticketItems,
        (item) => item.category === 'In-hospital doctor visit(s)'
      );

      formatTicketItems(ticketItems);
    }

    function formatTicketItems(items) {
      const mainTreatments = [];

      // Get main treatments.

      _.forEach(items, (item) => {
        if (_.indexOf(mainTreatments, item.categoryName) < 0) {
          mainTreatments.push(item.categoryName);
        }
      });

      // Get ticket items per main treatment.

      _.forEach(mainTreatments, (main) => {
        const ticketItems = _.filter(items, (x) => x.categoryName === main);
        const newCategory = JSON.parse(JSON.stringify(defaultTicketItem));

        newCategory.doctorType = main;
        newCategory.ticketItems = [];

        // Get sub categories and procedures.

        const mainTreatment = _.filter(
          vm.formattedCategories,
          (f) => f.mainTreatmentCategoryName === main
        );

        _.forEach(ticketItems, (x) => {
          const newTicketItem = JSON.parse(JSON.stringify(defaultTicketItem.ticketItems[0]));
          const subTreatment = _.filter(
            mainTreatment[0].subTreatmentCategories,
            (s) => s.subTreatmentItemName === x.subCategory
          );

          newTicketItem.subTreatments = mainTreatment[0].subTreatmentCategories.map(
            (s) => s.subTreatmentItemName
          );

          newTicketItem.procedures = subTreatment[0].procedures;

          newTicketItem.category = x.category;
          newTicketItem.subCategory = x.subCategory;
          newTicketItem.amount = x.amount;
          newTicketItem.uncoveredAmount = x.uncoveredAmount;
          newTicketItem.section = x.category;
          newTicketItem.doctorType = x.categoryName;
          newTicketItem.subTreatment = x.subCategory;
          newTicketItem.procedure = x.procedure;
          newTicketItem.uncoveredProcedure = x.uncoveredProcedure;
          newTicketItem.remark = x.remark;
          newTicketItem.uncoveredRemark = x.uncoveredRemark;
          newTicketItem.totalCharges = x.amount + x.uncoveredAmount;
          newTicketItem.startDate = x.startDate;
          newTicketItem.endDate = x.endDate;
          newTicketItem.doctorId = x.doctorId;
          newTicketItem.doctorName = getDoctorName(x.doctorId);
          newTicketItem.totalVisit = x.totalVisit;
          newTicketItem.visit = x.visit;

          newCategory.ticketItems.push(newTicketItem);
        });

        if (newCategory.ticketItems.length > 0)
          newCategory.doctorName = newCategory.ticketItems[0].doctorName;
        newCategory.doctorId = newCategory.ticketItems[0].doctorId;
        vm.ticket.procedures.push(newCategory);
      });
    }

    // Show V2 UI during create or edit when condition fulfilled.

    function fulfillV2ViewCondition() {
      const ticketVersion = !vm.isClaim ? vm.ticket.version : vm.ticket.ticketVersion;

      const isCreate = vm.isClaim ?
        vm.ticket.requestType !== vm.enums.serviceRequestType.Adjustment.name
        : vm.isCreate;

      return (vm.hasInsuranceEnhancementModule && (isCreate || ticketVersion === 'V2'));
    }

    function getSubtotalAmount() {
      return getAmount().subtotalAmount;
    }

    function getTotalCoveredAmount() {
      return getAmount().totalCoveredAmount;
    }

    function getTotalUncoveredAmount() {
      return getAmount().totalUncoveredAmount;
    }
  }
})();
