(function () {
    'use strict';

    angular.module('app').component('hostCorporatesSupportPlansCreateEditBasicsMaternityComponent', {
        templateUrl: require('/App/host/views/corporates/supportPlans/createEdit/basics/maternity/maternity.component.html'),
        controller: ['$sce', controller],
        controllerAs: 'vm',
        bindings: {
            supportPlan: '<'
        }
    });

    function controller($sce) {
        var vm = this;
        vm.daysText = App.localize('Days');
        vm.yearsText = App.localize('Year');
        vm.deliveryText = App.localize('Delivery');

        vm.displaySafeHtml = function (html) {
            return $sce.trustAsHtml(html);
        };

        vm.toggleMaternityBasics = function () {
            vm.minWaitingDays = vm.supportPlan.maternitySetting.isMaternitybenefits === 1 ? 1 : 0;
            vm.minMotherMaxAge = vm.supportPlan.maternitySetting.isHasMotherAgeLimit === 1 ? 1 : 0;
            vm.minDeliveryLimit = vm.supportPlan.maternitySetting.isUnlimitedDelivery === 0 ? 1 : 0;
        };

        function init() {
            vm.toggleMaternityBasics();
        }

        angular.element(document).ready(function () {
            init();
        });
    }
})();
