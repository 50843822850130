/* global __API_ENDPOINT__ */

(() => {
  angular.module('hms-bootstrap').factory('bootstrapLoader', BootstrapLoaderServiceFactory);

  BootstrapLoaderServiceFactory.$inject = ['$http', 'authentication', '$window', '$interval', '$timeout'];

  function BootstrapLoaderServiceFactory($http, authentication, $window, $interval, $timeout) {
    const scriptUrls = [
      { url: `${__API_ENDPOINT__}/AbpServiceProxies/GetAll?type=angular`, loaded: false },
      { url: `${__API_ENDPOINT__}/AbpScripts/GetScripts`, loaded: false },
    ];

    const service = {
      tries: 0,
      maxRetries: 5,
      retriesTime: 5000,
      countdown: 0,
      showTools: false
    };

    init();
    return service;

    function init() {

      $timeout(() => {
        service.showTools = true;
      }, 30000);

      
      // Ensure user is logged in and retrieve the user's access token.

      authentication
        .getUser()
        .then((user) => {
          loadScripts(user.access_token);
        })
        .catch((err) => {
          console.log(err);
          attemptRetry();
        });
    }

    // Load scripts from Portal service.

    function loadScripts(accessToken) {
      const promises = _.map(scriptUrls, (scriptUrl) => {
        if (
          _.includes(scriptUrl.url, 'GetScripts') &&
          !_.includes(scriptUrl.url, '?culture') &&
          !!abp.utils.getCookieValue('Abp.Localization.CultureName')
        ) {
          scriptUrl.url = `${scriptUrl.url}?culture=${abp.utils.getCookieValue(
            'Abp.Localization.CultureName'
          )}`;
        }

        return $http({
          method: 'GET',
          url: scriptUrl.url,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Abp.Localization.CultureName': abp.utils.getCookieValue(
              'Abp.Localization.CultureName'
            ),
          },
        })
          .then((resp) => {
            // Ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/eval#never_use_eval!
            // eslint-disable-next-line no-new-func
            Function(resp.data)();
            scriptUrl.loaded = true;
          })
          .catch(() => {});
      });

      Promise.all(promises).then(() => {
        if (_.some(scriptUrls, (scriptUrl) => !scriptUrl.loaded)) {
          attemptRetry();
        } else {
          loadApp();
        }
      });
    }

    // Start HMS app. Place this function at where everything is concluded.

    function loadApp() {
      const defaultLanguageName = abp.setting.get('Abp.Localization.DefaultLanguageName');

      const cookieCultureName = abp.utils.getCookieValue('Abp.Localization.CultureName');

      if (!cookieCultureName || cookieCultureName !== defaultLanguageName) {
        abp.utils.setCookieValue('Abp.Localization.CultureName', defaultLanguageName);
      }

      if (!abp.localization.isCurrentCulture(defaultLanguageName)) {
        $window.location.reload();
      }

      angular.bootstrap(document.getElementById('wrapper'), ['app'], {
        strictDi: true,
      });
      $('#bootstrap').hide(3000);
    }

    function attemptRetry() {
      service.tries += 1;
      service.countdown = service.retriesTime;
      service.retriesTime *= 2;
      const countdown = $interval(
        () => {
          if (service.tries <= service.maxRetries) {
            service.countdown -= 1000;
            if (service.countdown === 0) {
              $interval.cancel(countdown);
              init();
            }
          } else {
            service.countdown = 0;
            $interval.cancel(countdown);
          }
        },
        1000,
        0
      );
    }
  }
})();
