(function () {
    var controllerId = 'host.views.specialistDisciplines.index';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', '$uibModal', '$timeout', 'abp.services.app.specialistDiscipline',
        function ($scope, $state, $stateParams, $modal, $timeout, specialistDisciplineSvc) {
            function init() {
                vm.getDisciplines();
            }

            var vm = this;
            vm.loading = 0;
            vm.filterText = $stateParams.filterText || '';

            $scope.$on('$viewContentLoaded', function () {
                App.initAjax();
            });

            // Assign permissions

            vm.permissions = {
                create: abp.auth.isGranted('Host.SpecialistDisciplines.Create'),
                edit: abp.auth.isGranted('Host.SpecialistDisciplines.Edit'),
                'delete': abp.auth.isGranted('Host.SpecialistDisciplines.Delete')
            };

            // Define grid options

            vm.disciplineGridOptions = {
                appScopeProvider: vm,
                paginationPageSizes: app.consts.grid.defaultPageSize,
                paginationPageSize: app.consts.grid.defaultPageSize,
                columnDefs: [{
                    enableSorting: false,
                    name: App.localize('Actions'),
                    width: 100,
                    headerCellTemplate: '<span></span>',
                    cellTemplate: 'actionTemplate'
                }, {
                    displayName: App.localize('DisplayName'),
                    field: 'displayName',
                    minWidth: 120
                }, {
                    displayName: App.localize('Description'),
                    field: 'description',
                    minWidth: 100
                }, {
                    displayName: App.localize('Symptoms'),
                    field: 'symptoms',
                    minWidth: 120
                }, {
                    displayName: App.localize('CreationTime'),
                    field: 'creationTime',
                    cellFilter: 'momentFormat: \'L LT\'',
                    minWidth: 80
                }],
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;

                    function filter(renderableRows) {
                        if (vm.filterText) {
                            var filterText = vm.filterText.toUpperCase();
                            var fieldsToSearch = ['displayName', 'description', 'symptoms'];

                            _(renderableRows).each(function (row) {
                                var match = false;
                                _(fieldsToSearch).each(function (field) {
                                    var value = row.entity[field];
                                    if (_.isString(value) && value.toUpperCase().indexOf(filterText) >= 0) {
                                        match = true;
                                        return false;
                                    }
                                });
                                row.visible = match;
                            });
                        }

                        return renderableRows;
                    }

                    $scope.gridApi.grid.registerRowsProcessor(filter, 200);
                },
                data: []
            };

            vm.filter = function () {
                $scope.gridApi.grid.refresh();
            };

            // Shift out action column if there's no edit actions

            vm.permissions.edit || vm.permissions.delete || vm.disciplineGridOptions.columnDefs.shift();

            vm.getDisciplines = function () {
                vm.loading++;
                specialistDisciplineSvc.getSpecialistDisciplines().success(function (data) {
                    vm.disciplineGridOptions.totalItems = data.totalCount;
                    vm.disciplineGridOptions.data = data.items;
                }).finally(function (e) {
                    vm.loading--;
                });
            };

            vm.createDiscipline = function () {
                showCreateEditModal();
            };

            vm.editDiscipline = function (discipline) {
                showCreateEditModal(discipline.id);
            };

            function showCreateEditModal(id) {
                $modal.open({
                    templateUrl: require('/App/host/views/specialistDisciplines/createOrEditModal.html'),
                    controller: 'host.views.specialistDisciplines.createOrEditModal as vm',
                    backdrop: 'static',
                    resolve: {
                        disciplineId: id
                    }
                }).result.then(function () {
                    vm.getDisciplines();
                });
            }

            vm.deleteDiscipline = function (discipline) {
                abp.message.confirm(App.localize('SpecialistDisciplineDeleteWarningMessage', discipline.displayName), App.localize('AreYouSure'), function (result) {
                    if (result) {
                        specialistDisciplineSvc.deleteSpecialistDiscipline({
                            id: discipline.id
                        }).success(function (data) {
                            vm.getDisciplines();
                            abp.notify.success(App.localize('SuccessfullyDeleted'));
                        });
                    }
                });
            };

            init();
        }
    ]);
})();
