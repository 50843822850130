(function () {
    angular
        .module('app')
        .controller('clinic.views.hospital.topUpRequestModal', ['$uibModalInstance', controller]);

    function controller($uibModalInstance) {
        var vm = this;
        vm.topUpAndAddSpecialist = topUpAndAddSpecialist;
        vm.topUpOnly = topUpOnly;
        vm.addSpecialistOnly = addSpecialistOnly;
        vm.cancel = cancel;

        function topUpAndAddSpecialist() {
            $uibModalInstance.close(1);
        }

        function topUpOnly() {
            $uibModalInstance.close(2);
        }

        function addSpecialistOnly() {
            $uibModalInstance.close(3);
        }

        function cancel() {
            $uibModalInstance.dismiss();
        }
    }
})();
