(() => {
  angular
    .module('app')
    .controller(
      'common.views.patientTickets.setEffectiveMcDaysModal',
      SetEffectiveMcDaysModalController
    );

  SetEffectiveMcDaysModalController.$inject = [
    '$uibModalInstance',
    'abp.services.app.patientTicket',
    'ticket',
  ];

  function SetEffectiveMcDaysModalController($uibModalInstance, patientTicketSvc, ticket) {
    const vm = this;

    vm.saving = 0;
    vm.ticket = ticket;
    vm.effectiveMcDays = ticket.effectiveMcDays;
    vm.remarks = '';

    vm.save = save;
    vm.cancel = cancel;

    init();

    function init() {}

    function save() {
      vm.saving += 1;
      patientTicketSvc
        .updateEffectiveMcDays({
          id: vm.ticket.ticketNumber,
          effectiveMcDays: vm.effectiveMcDays,
          remarks: vm.remarks,
        })
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          $uibModalInstance.close();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
