(function () {
    var componentId = 'hostDoraemonGlDetailsOverviewComponent';
    var app = angular.module('app');

    function controller(doraemonSvc, enumServiceRequestStatus, enumServiceRequestType, moment) {
        var vm = this;
        vm.$onChanges = onChanges;
        vm.getAdmissionType = getAdmissionType;
        vm.getAdmittingSpecialists = getAdmittingSpecialists;

        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.loading = 0;

        vm.request = null;
        vm.patient = null;
        vm.organization = null;
        vm.subsidiary = null;
        vm.guaranteeLetter = null;
        vm.admission = null;
        vm.diagnosis = null;
        vm.documents = []
        vm.admissionTypes = [];

        vm.enums = {};
        vm.enums.serviceRequestStatus = enumServiceRequestStatus;
        vm.enums.serviceRequestType = enumServiceRequestType;

        function onChanges(changes) {
            if (changes.data && changes.data.currentValue) {
                setData(vm.data)
            }
        }

        function setData(data) {
            if (!data) return;
            getAdmissionDetails(data.guaranteeLetter.letterNumber);
            vm.request = data.request;
            vm.patient = data.patient;
            vm.organization = data.organization;
            vm.subsidiary = data.subsidiary;
            vm.guaranteeLetter = data.guaranteeLetter;
        }

        function getAdmissionDetails(letterNumber) {
            vm.loading++;
            doraemonSvc.getAdmissionDetails({
                id: letterNumber
            }).success(function (data) {
                vm.admission = {
                    hospitalName: data.hospitalName,
                    hospitalContactNumber: data.hospitalContactNumber,
                    requestTime: data.requestTime,
                    guaranteeLetterNumber: data.guaranteeLetterNumber,
                    admissionNumber: data.admissionNumber,
                    scheduledAdmissionTime: data.scheduledAdmissionTime,
                    checkedInTime: data.checkedInTime,
                    admissionType: data.admissionType,
                };

                vm.documents = _.map(data.supportingDocuments, function (d) {
                    return {
                        externalObjectId: d.externalObjectId,
                        url: d.url,
                        name: d.name,
                        uploader: d.uploader + ' ' + moment(d.uploadedTime).format('L, LT'),
                        applied: d.isApplied,
                        state: d.state
                    };
                });

                vm.diagnosis = {
                    selected: _.map(data.provisionalDiagnosis, function (p) {
                        return {
                            code: p.code,
                            description: p.description,
                            date: p.creationTime
                        };
                    }),
                    icd: _.map(data.diseaseClassifications, function (d) {
                        return {
                            id: d.id,
                            code: d.code,
                            description: '(' + d.code + ') ' + d.description
                        };
                    })
                };

                vm.selectedDoctorNames = _.map(data.selectedDoctors, function (d) {
                    return d.name;
                });

                vm.admissionTypes = data.admissionTypes;
            }).finally(function (e) {
                vm.loading--
            });
        }

        function getAdmissionType() {
            if (vm.admission && vm.admission.admissionType) {
                var admissionType = _.find(vm.admissionTypes, function (t) {
                    return t.id === vm.admission.admissionType;
                });

                return admissionType.name;
            }
        }

        function getAdmittingSpecialists() {
            if (!vm.selectedDoctorNames) return;
            return vm.selectedDoctorNames.join(', ');
        }
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/doraemon/glDetails/overview/overview.component.html'),
        controller: ['abp.services.app.doraemon', 'Hms.ServiceRequests.ServiceRequestStatus', 'Hms.ServiceRequests.ServiceRequestType', 'moment', controller],
        controllerAs: 'vm',
        bindings: {
            data: '<',
        }
    });
})();
