(function () {
    var componentId = 'hostDirectorDashboardSleepingCorporatesWidget';
    var app = angular.module('app');

    function controller(directorDashboardSvc) {
        var vm = this;
        vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
        vm.loading = false;
        vm.result = [];

        function loadData() {
            vm.loading = true;
            directorDashboardSvc.getSleepingCorporates()
                .success(function (data) {
                    vm.result = data.items;
                }).finally(function () {
                    vm.loading = false;
                });
        }

        loadData();
    }

    app.component(componentId, {
        templateUrl: require('/App/host/views/directorDashboard/sleepingCorporatesWidget.html'),
        controller: ['abp.services.app.directorDashboard', controller],
        controllerAs: 'vm'
    });
})();
