(() => {
  angular.module('app').component('commonSpecialistClaimsAdjustmentDetailsComponent', {
    templateUrl: require('./adjustmentDetails.component.html'),
    controller: AdjustmentDetailsController,
    controllerAs: 'vm',
    bindings: {
      claim: '<',
    },
  });

  AdjustmentDetailsController.$inject = [];

  function AdjustmentDetailsController() {
    const vm = this;

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');

    vm.getDifference = getDifference;

    function getDifference() {
      if (vm.claim) {
        vm.isNegativeValue = false;
        vm.finalBillAmountDifference = vm.claim.adjustedFinalBillAmount - vm.claim.billAmount;

        if (vm.finalBillAmountDifference < 0) {
          vm.isNegativeValue = true;
          vm.finalBillAmountDifference = Math.abs(vm.finalBillAmountDifference);
        }
        return vm.finalBillAmountDifference;
      }
      return 0;
    }
  }
})();
