(function () {
    angular.module('app').controller('clinic.views.hospital.patient.admission', [
        '$scope', '$uibModalInstance', 'scheduleDate', 'expiryDate', 'letterNumber', 'abp.services.app.hospitalPanelServiceRequest', 'moment',
        function ($scope, $uibModalInstance, scheduleDate, expiryDate, letterNumber, hospitalPanelServiceRequestSvc, moment) {
            var vm = this;
            var days = 0;
            vm.scheduleDate = scheduleDate;
            vm.expiryDate = App.localize('LateCheckInWarningNarative', moment(expiryDate).format('L'));
            vm.cancel = cancel;
            vm.confirm = confirm;
            vm.downloadAttendingPhysicianStatement = downloadAttendingPhysicianStatement;
            vm.letterNumber = letterNumber;

            vm.isAdmissionDateCorrect = 1;

            vm.exporting = 0;

            vm.checkInReasonChoice = null;

            vm.newAdmissionDate = null;

            vm.maxDate = moment().endOf("day");     // To set the maximum allowed date to be YYYY/MM/DD 11:59PM, to allow time choosing for datepicker
            vm.today = moment().format('YYYY-MM-DD');
            vm.isEarlyAdmission = vm.scheduleDate > vm.today;
            vm.isLateAdmission = vm.scheduleDate < vm.today;
            vm.reasonPlaceholder = vm.isEarlyAdmission
                ? App.localize('EarlyDateCheckInReason')
                : App.localize('LateDateCheckInReason');

            vm.hasAttachment = true;
            var scheduledDate = moment(vm.scheduleDate).format('YYYY-MM-DD');
            days = Math.abs(moment(vm.today).diff(scheduledDate, 'days'));
            vm.dayDifference = vm.isEarlyAdmission
                ? App.localize("XDaysEarly", days)
                : App.localize("XDaysLate", days);

            // HM-3997 Hack for reasoning of checkIn
            // TODO: Future enhancement should use enum

            function assignReason() {
                if (vm.checkInReasonChoice !== 6) {
                    if (vm.checkInReasonChoice === 0) {
                        vm.reason = App.localize("ForgotToCheckIn");
                    }
                    else if (vm.checkInReasonChoice === 1) {
                        vm.reason = App.localize("TooManyPatients");
                    }
                    else if (vm.checkInReasonChoice === 2) {
                        vm.reason = App.localize("NotSureHowToUseSystem");
                    }
                    else if (vm.checkInReasonChoice === 3) {
                        vm.reason = App.localize("PersonInChargeUnavailable");
                    }
                    else if (vm.checkInReasonChoice === 4) {
                        vm.reason = App.localize("AdmissionDateRescheduled");
                    }
                    else if (vm.checkInReasonChoice === 5) {
                        vm.reason = App.localize("PatientUrgentTreatment");
                    }
                }
            }

            /* Admission Attachment */

            vm.newAttachments = [];
            vm.uploadConfig = {
                objectType: 'InpatientAdmissionAttachment'
            };

            vm.validateForm = function () {
                var form = $scope.admissionForm;

                vm.hasAttachment = vm.newAttachments.length > 0;

                App.touchFormErrors(form);

                return form.$valid && (vm.hasAttachment || vm.isAdmissionDateCorrect);
            };

            /* End of admission Attachment */

            function cancel() {
                $uibModalInstance.dismiss();
            }

            function confirm() {
                assignReason();
                if (!vm.validateForm()) {
                    return;
                }
                $uibModalInstance.close({
                    isCheckIn: true,
                    reason: vm.reason,
                    actualAdmissionDate: vm.newAdmissionDate,
                    newAttachments: vm.newAttachments,
                });
            }

            function downloadAttendingPhysicianStatement() {
                vm.exporting++;
                // Pass in prefilled data
                hospitalPanelServiceRequestSvc.getAttendingPhysicianStatementPdf(vm.letterNumber)
                    .success(function (data) {
                        App.downloadTempFile(data);
                    }).finally(function () {
                        vm.exporting--;
                    });
            }
        }
    ]);
})();
