(() => {
  angular.module('app').component('commonInpatientTicketsDetailOthersComponent', {
    templateUrl: require('./others.component.html'),
    controller: OthersController,
    controllerAs: 'vm',
    bindings: {
      hasConsent: '<',
      ticket: '<',
      isEdit: '<',
      isSaved: '<',
      categories: '<',
      isCreate: '<',
      currencyCode: '<'
    },
  });

  OthersController.$inject = [];

  function OthersController() {
    const vm = this;

    vm.$onChanges = onChanges;
    vm.addOthers = addOthers;
    vm.clearOthers = clearOthers;
    vm.deleteItem = deleteItem;
    vm.updateCategory = updateCategory;
    vm.calculateTotal = calculateTotal;

    function onChanges(changes) {
      if (changes.ticket && changes.ticket.currentValue) {
        if (vm.ticket) {
          if (vm.ticket.others.length === 0) {
            addOthers();
          } else {
            _.each(vm.ticket.others, (other) => {
              const x = other;
              x.totalCharges = x.amount + x.uncoveredAmount;
            });
          }
        }
      }

      if (changes.isSaved && changes.isSaved.currentValue) {
        tidyOthers();
      }

      if (changes.isEdit && changes.isEdit.currentValue) {
        if (vm.ticket && vm.ticket.others.length === 0) {
          addOthers();
        }
      }
    }

    function calculateTotal(index, item) {
      const i = item;
      let uncoveredAmount = 0;
      let amount = 0;
      if (i[index].amount > 0) {
        amount = i[index].amount;
      }
      if (i[index].uncoveredAmount > 0) {
        uncoveredAmount = i[index].uncoveredAmount;
      }
      const totalCharges = amount + uncoveredAmount;
      i[index].totalCharges = totalCharges;
    }

    function addOthers() {
      vm.ticket.others.push({});
    }

    function clearOthers() {
      vm.ticket.others = [{}];
    }

    function tidyOthers() {
      vm.ticket.others = _.filter(vm.ticket.others, (item) => !_.isNil(item.amount));
    }

    function updateCategory(item) {
      const i = item;
      const selectedCategory = _.find(vm.categories, { id: i.category });
      if (selectedCategory) {
        i.categoryName = selectedCategory.displayName;
        i.category = selectedCategory.category;
      }
    }

    function deleteItem(index) {
      if (vm.ticket.others.length > 1) {
        vm.ticket.others.splice(index, 1);
      } else {
        vm.ticket.others = [];
        addOthers();
      }
    }
  }
})();
