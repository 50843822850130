(() => {
  angular.module('app').filter('highlightWord', highlightWord);

  highlightWord.$inject = [];

  function highlightWord() {
    return highlightWordFilter;

    function escapeRegexp(queryToEscape) {
      return `${queryToEscape}`.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
    }

    function highlightWordFilter(matchItem, query) {
      if (query && matchItem) {
        const keywords = query.split(/[\s,]+/).map((w) => w.toLowerCase());

        const matchItems = matchItem.split(/[\s]+/);
        matchItems.forEach((value, key) => {
          const item = value.toLowerCase();
          for (let i = 0; i < keywords.length; i += 1) {
            if (item.indexOf(keywords[i]) !== -1) {
              matchItems[key] = `${value}`.replace(
                new RegExp(escapeRegexp(keywords[i]), 'gi'),
                '<span class="ui-select-highlight">$&</span>'
              );
              break;
            }
          }
        });
        return matchItems.join(' ');
      }
      return matchItem;
    }
  }
})();
