(function () {
    'use strict';

    angular.module('app').component('hostCorporatesSupportPlansCreateEditScheduleOfBenefitOutpatientTreatmentComponent', {
        templateUrl: require('/App/host/views/corporates/supportPlans/createEdit/scheduleOfBenefit/outpatientTreatment/outpatientTreatment.component.html'),
        controller: ['$scope', 'Hms.BenefitGroups.CoveredCycle', controller],
        controllerAs: 'vm',
        bindings: {
            scheduleOfBenefit: '<',
            currencyCode: '<'
        }
    });

    function controller($scope, enumCoveredCycle) {
        var vm = this;
        vm.hasScheduleOfBenefitEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');

        //Amount Types
        vm.outpatientCancerAmountType = null;
        vm.outpatientKidneyDialysisAmountType = null;
        vm.outpatientPhysiotherapyAmountType = null;

        vm.minOutpatientCancer = null;
        vm.minOutpatientKidneyDialysis = null;
        vm.minOutpatientPhysiotherapy = null;

        vm.enums = {};
        vm.enums.coveredCycle = enumCoveredCycle;

        // AmountType toggle
        vm.toggleAmountType = function (SOBItem) {
            switch (SOBItem) {
                case 'outpatientCancer':
                    vm.minOutpatientCancer = vm.outpatientCancerAmountType === 1 ? 1 : 0;
                    switch (vm.outpatientCancerAmountType) {
                        case 0: /*ischarged*/
                            vm.scheduleOfBenefit.outpatientCancerTreatment.isAsCharged = true;
                            vm.scheduleOfBenefit.outpatientCancerTreatment.isCovered = true;
                            break;
                        case 1: /*limited*/
                            vm.scheduleOfBenefit.outpatientCancerTreatment.isAsCharged = false;
                            vm.scheduleOfBenefit.outpatientCancerTreatment.isCovered = true;
                            break;
                        case 2: /*uncovered*/
                            vm.scheduleOfBenefit.outpatientCancerTreatment.isAsCharged = false;
                            vm.scheduleOfBenefit.outpatientCancerTreatment.isCovered = false;
                            break;
                    }

                    if (vm.scheduleOfBenefit.outpatientCancerTreatment.isAsCharged === vm.scheduleOfBenefit.outpatientCancerTreatment.isCovered) {
                        vm.tempOutpatientCancerAmount = vm.scheduleOfBenefit.outpatientCancerTreatment.amount;
                        vm.scheduleOfBenefit.outpatientCancerTreatment.amount = null;

                        vm.tempOutpatientCancerEffectivePeriod = vm.scheduleOfBenefit.outpatientCancerTreatment.coveredCycle;
                        vm.scheduleOfBenefit.outpatientCancerTreatment.coveredCycle = null;
                    } else {
                        vm.scheduleOfBenefit.outpatientCancerTreatment.amount = vm.tempOutpatientCancerAmount;

                        if (vm.tempOutpatientCancerEffectivePeriod) {
                            vm.scheduleOfBenefit.outpatientCancerTreatment.coveredCycle = vm.tempOutpatientCancerEffectivePeriod;
                        } else {
                            vm.scheduleOfBenefit.outpatientCancerTreatment.coveredCycle = 6; // PerAdmission
                        }
                    }

                    break;
                case 'outpatientKidneyDialysis':
                    vm.minOutpatientKidneyDialysis = vm.outpatientKidneyDialysisAmountType === 1 ? 1 : 0;
                    switch (vm.outpatientKidneyDialysisAmountType) {
                        case 0: /*ischarged*/
                            vm.scheduleOfBenefit.outpatientKidneyDialysisTreatment.isAsCharged = true;
                            vm.scheduleOfBenefit.outpatientKidneyDialysisTreatment.isCovered = true;
                            break;
                        case 1: /*limited*/
                            vm.scheduleOfBenefit.outpatientKidneyDialysisTreatment.isAsCharged = false;
                            vm.scheduleOfBenefit.outpatientKidneyDialysisTreatment.isCovered = true;
                            break;
                        case 2: /*uncovered*/
                            vm.scheduleOfBenefit.outpatientKidneyDialysisTreatment.isAsCharged = false;
                            vm.scheduleOfBenefit.outpatientKidneyDialysisTreatment.isCovered = false;
                            break;
                    }

                    if (vm.scheduleOfBenefit.outpatientKidneyDialysisTreatment.isAsCharged === vm.scheduleOfBenefit.outpatientKidneyDialysisTreatment.isCovered) {
                        vm.tempOutpatientKidneyDialysisAmount = vm.scheduleOfBenefit.outpatientKidneyDialysisTreatment.amount;
                        vm.scheduleOfBenefit.outpatientKidneyDialysisTreatment.amount = null;

                        vm.tempOutpatientKidneyDialysisEffectivePeriod = vm.scheduleOfBenefit.outpatientKidneyDialysisTreatment.coveredCycle;
                        vm.scheduleOfBenefit.outpatientKidneyDialysisTreatment.coveredCycle = null;
                    } else {
                        vm.scheduleOfBenefit.outpatientKidneyDialysisTreatment.amount = vm.tempOutpatientKidneyDialysisAmount;

                        if (vm.tempOutpatientKidneyDialysisEffectivePeriod) {
                            vm.scheduleOfBenefit.outpatientKidneyDialysisTreatment.coveredCycle = vm.tempOutpatientKidneyDialysisEffectivePeriod;
                        } else {
                            vm.scheduleOfBenefit.outpatientKidneyDialysisTreatment.coveredCycle = 6; // PerAdmission
                        }
                    }

                    break;
                case 'outpatientPhysiotherapy':
                    vm.minOutpatientPhysiotherapy = vm.outpatientPhysiotherapyAmountType === 1 ? 1 : 0;
                    switch (vm.outpatientPhysiotherapyAmountType) {
                        case 0: /*ischarged*/
                            vm.scheduleOfBenefit.outpatientPhysiotherapyTreatment.isAsCharged = true;
                            vm.scheduleOfBenefit.outpatientPhysiotherapyTreatment.isCovered = true;
                            break;
                        case 1: /*limited*/
                            vm.scheduleOfBenefit.outpatientPhysiotherapyTreatment.isAsCharged = false;
                            vm.scheduleOfBenefit.outpatientPhysiotherapyTreatment.isCovered = true;
                            break;
                        case 2: /*uncovered*/
                            vm.scheduleOfBenefit.outpatientPhysiotherapyTreatment.isAsCharged = false;
                            vm.scheduleOfBenefit.outpatientPhysiotherapyTreatment.isCovered = false;
                            break;
                    }

                    if (vm.scheduleOfBenefit.outpatientPhysiotherapyTreatment.isAsCharged === vm.scheduleOfBenefit.outpatientPhysiotherapyTreatment.isCovered) {
                        vm.tempOutpatientPhysiotherapyAmount = vm.scheduleOfBenefit.outpatientPhysiotherapyTreatment.amount;
                        vm.scheduleOfBenefit.outpatientPhysiotherapyTreatment.amount = null;

                        vm.tempOutpatientPhysiotherapyEffectivePeriod = vm.scheduleOfBenefit.outpatientPhysiotherapyTreatment.coveredCycle;
                        vm.scheduleOfBenefit.outpatientPhysiotherapyTreatment.coveredCycle = null;
                    } else {
                        vm.scheduleOfBenefit.outpatientPhysiotherapyTreatment.amount = vm.tempOutpatientPhysiotherapyAmount;

                        if (vm.tempOutpatientPhysiotherapyEffectivePeriod) {
                            vm.scheduleOfBenefit.outpatientPhysiotherapyTreatment.coveredCycle = vm.tempOutpatientPhysiotherapyEffectivePeriod;
                        } else {
                            vm.scheduleOfBenefit.outpatientPhysiotherapyTreatment.coveredCycle = 6; // PerAdmission
                        }
                    }

                    break;
            }
        }

        function initializeAmountType() {
            // Outpatient Cancer AmountType
            if (!vm.scheduleOfBenefit.outpatientCancerTreatment.isCovered)
                vm.outpatientCancerAmountType = 2;
            else if (vm.scheduleOfBenefit.outpatientCancerTreatment.isAsCharged)
                vm.outpatientCancerAmountType = 0;
            else if (!vm.scheduleOfBenefit.outpatientCancerTreatment.isAsCharged) {
                vm.outpatientCancerAmountType = 1;
            }

            // Outpatient Kidney Dialysis AmountType
            if (!vm.scheduleOfBenefit.outpatientKidneyDialysisTreatment.isCovered)
                vm.outpatientKidneyDialysisAmountType = 2;
            else if (vm.scheduleOfBenefit.outpatientKidneyDialysisTreatment.isAsCharged)
                vm.outpatientKidneyDialysisAmountType = 0;
            else if (!vm.scheduleOfBenefit.outpatientKidneyDialysisTreatment.isAsCharged) {
                vm.outpatientKidneyDialysisAmountType = 1;
            }

            // Outpatient Physiotherapy AmountType
            if (!vm.scheduleOfBenefit.outpatientPhysiotherapyTreatment.isCovered)
                vm.outpatientPhysiotherapyAmountType = 2;
            else if (vm.scheduleOfBenefit.outpatientPhysiotherapyTreatment.isAsCharged)
                vm.outpatientPhysiotherapyAmountType = 0;
            else if (!vm.scheduleOfBenefit.outpatientPhysiotherapyTreatment.isAsCharged) {
                vm.outpatientPhysiotherapyAmountType = 1;
            }
        }

        function init() {
            initializeAmountType();
        }

        angular.element(document).ready(function () {
            init();
        });
    }
})();
