(function () {
    var componentId = 'hostInpatientGuaranteeLettersDetailsRevisionsComponent';
    var app = angular
        .module('app')
        .component(componentId, {
            templateUrl: require('/App/host/views/inpatientGuaranteeLetters/details/revisions/revisions.component.html'),
            controller: ['$scope', '$timeout', 'abp.services.app.inpatientGuaranteeLetter', 'Hms.ServiceRequests.ServiceRequestType', controller],
            controllerAs: 'vm',
            bindings: {
                letterNumber: '<',
                timeZoneId: '<'
            }
        });

    function controller($scope, $timeout, inpatientGuaranteeLetterSvc, enumServiceRequestType) {
        var vm = this;
        vm.loading = 0;

        vm.enums = {};
        vm.enums.serviceRequestType = enumServiceRequestType;

        // TODO: Update permission

        vm.permissions = {
            update: abp.auth.isGranted('GuaranteeLetters.Host.Update'),
            topUp: abp.auth.isGranted('GuaranteeLetters.Host.TopUp'),
            extend: abp.auth.isGranted('GuaranteeLetters.Host.Approve'),
            advance: abp.auth.isGranted('GuaranteeLetters.Corporate.Advance')
        };

        var viewOptions = {
            maxResultCount: app.consts.grid.defaultPageSize,
            skipCount: 0,
            sorting: null
        };

        vm.getDateTimeString = getDateTimeString;

        function init() {
            $timeout(function () {
                vm.revisionComponentGridOptions.paginationCurrentPage = Math.floor(viewOptions.skipCount / viewOptions.maxResultCount + 1);
            });
        }

        vm.revisionComponentGridOptions = {
            appScopeProvider: vm,
            paginationPageSizes: app.consts.grid.defaultPageSizes,
            paginationPageSize: app.consts.grid.defaultPageSize,
            paginationCurrentPage: 0,
            useExternalPagination: true,
            useExternalSorting: true,
            resizable: true,
            columnDefs: [{
                name: 'Actions',
                displayName: 'Actions',
                enableSorting: false,
                width: 100,
                headerCellTemplate: '<span></span>',
                cellTemplate: 'revisionComponentViewTemplate',
                allowCellFocus: false
            }, {
                displayName: App.localize('RevisionType'),
                enableSorting: false,
                field: 'revisionType',
                minWidth: 120,
                cellTemplate: 'revisionTypeTemplate'
            }, {
                displayName: App.localize('RevisionNumber'),
                enableSorting: false,
                field: 'revisionNumber',
                minWidth: 120,
            }, {
                displayName: App.localize('ApprovalTime'),
                enableSorting: false,
                field: 'approvalTime',
                minWidth: 120,
                cellTemplate: 'approvalTimeTemplate'
            }],
            onRegisterApi: function (gridApi) {
                $scope.gridApi = gridApi;

                $scope.gridApi.core.on.sortChanged($scope, function (scope, callback) {
                    viewOptions.sorting = callback.length && callback[0].field ? callback[0].field + ' ' + callback[0].sort.direction : null;
                    getInpatientGuaranteeLetterRevision();
                });
                gridApi.pagination.on.paginationChanged($scope, App.setPagination(viewOptions, getInpatientGuaranteeLetterRevision));
            },
            data: []
        };

        function getInpatientGuaranteeLetterRevision() {
            vm.loading++;
            inpatientGuaranteeLetterSvc.getInpatientGuaranteeLetterRevision({
                guaranteeLetterNumber: vm.letterNumber,
                skipCount: viewOptions.skipCount,
                maxResultCount: viewOptions.maxResultCount
            }).success(function (data) {
                vm.revisionComponentGridOptions.totalItems = data.totalCount;
                vm.revisionComponentGridOptions.data = data.items;
            }).finally(function (e) {
                vm.loading--;
            });
        }

        function getDateTimeString(date, timeZoneId) {
            return App.getDateTimeString(date, timeZoneId);
        }

        init();
    }
})();
