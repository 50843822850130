(function () {
    angular.module('app').controller('host.views.operationsDashboard.lateSubmissions.sendEmailsModal', [
        '$uibModalInstance', 'title', 'narrative', 'emailAddresses',
        function ($uibModalInstance, title, narrative, emailAddresses) {
            var vm = this;

            vm.cancel = cancel;
            vm.confirm = confirm;
            vm.title = title;
            vm.narrative = narrative;
            vm.emailAddresses = emailAddresses;

            function cancel() {
                $uibModalInstance.dismiss();
            }

            function confirm() {
                $uibModalInstance.close(vm.emailAddresses);
            }
        }
    ]);
})();
