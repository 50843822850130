(function () {
    var controllerId = 'corporate.views.claimPolicies.index';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$state', '$scope', '$uibModal', 'abp.services.app.claimPolicy',
        function ($state, $scope, $modal, claimPolicySvc) {
            var vm = this;
            vm.loading = false;

            vm.permissions = {
                create: abp.auth.isGranted('Corporate.ClaimPolicies.Create'),
                edit: abp.auth.isGranted('Corporate.ClaimPolicies.Edit'),
                'delete': abp.auth.isGranted('Corporate.ClaimPolicies.Delete')
            };

            vm.createClaimPolicy = function () {
                $state.go('createEditClaimPolicies');
            };

            vm.editClaimPolicy = function (claimPolicy) {
                $state.go('createEditClaimPolicies', {
                    id: claimPolicy.id
                });
            };

            vm.compare = function (claimPolicy) {
                $state.go('corporate.compareClaimPolicies', {
                    id: claimPolicy.id
                });
            };

            vm.deleteClaimPolicy = function (claimPolicy) {
                abp.message.confirm(App.localize('DeleteClaimPolicyWarningMessage', claimPolicy.subject), App.localize('AreYouSure'), function (result) {
                    if (result) {
                        claimPolicySvc.deleteClaimPolicy({
                            id: claimPolicy.id
                        }).success(function (error) {
                            vm.getClaimPolicies();
                            abp.notify.success(App.localize('SuccessfullyDeleted'));
                        });
                    }
                });
            };

            vm.claimPolicyGridOptions = {
                appScopeProvider: vm,
                columnDefs: [{
                    enableSorting: false,
                    name: App.localize('Actions'),
                    width: 100,
                    headerCellTemplate: '<span></span>',
                    cellTemplate: 'actionTemplate'
                }, {
                    displayName: App.localize('CreationDate'),
                    field: 'creationTime',
                    cellFilter: 'momentFormat: \'L\'',
                    minWidth: 100
                }, {
                    displayName: App.localize('LastUpdate'),
                    field: 'lastModificationTime',
                    cellFilter: 'momentFormat: \'L\'',
                    minWidth: 100
                }, {
                    displayName: App.localize('Subject'),
                    field: 'subject',
                    minWidth: 100
                }, {
                    displayName: App.localize('BenefitGroups'),
                    field: 'benefitGroupNames',
                    minWidth: 100,
                    cellTemplate: 'benefitGroupTemplate'
                }],
                data: []
            };
            vm.permissions.edit || vm.permissions.delete || vm.claimPolicyGridOptions.columnDefs.shift();

            vm.getClaimPolicies = function () {
                vm.loading = true;
                claimPolicySvc.getClaimPolicies().success(function (data) {
                    vm.claimPolicyGridOptions.data = data.items;
                }).finally(function (e) {
                    vm.loading = false;
                });
            };

            vm.getClaimPolicies();
        }
    ]);
})();
