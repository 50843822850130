(() => {
  angular.module('app').component('preEmploymentQuestionnaireV0', {
    templateUrl: require('./preEmploymentQuestionnaireV0.component.html'),
    controller: PreEmploymentQuestionnaireV0Controller,
    controllerAs: 'vm',
    bindings: {
      report: '=',
    },
  });

  PreEmploymentQuestionnaireV0Controller.$inject = ['$scope'];

  function PreEmploymentQuestionnaireV0Controller($scope) {
    const vm = this;

    init();

    function init() {
      $scope.$on('validateForm', validateForm);
    }

    function validateForm() {
      if (
        !vm.report.measurement ||
        !vm.report.measurement.bloodPressure ||
        !vm.report.measurement.vision ||
        !vm.report.measurement.electrocardiogram ||
        !vm.report.skin ||
        !vm.report.respiratory ||
        !vm.report.respiratory.disease ||
        !vm.report.examination ||
        !vm.report.drugAlcohol ||
        !vm.report.otherInformation ||
        !vm.report.otherInformation.prescribedMedication ||
        !vm.report.otherInformation.allergy ||
        !vm.report.fitForEmployment
      )
        abp.message.error(App.localize('PreEmploymentReportIncomplete'));

      if (
        (vm.report.measurement.vision.value === 1 &&
          vm.report.measurement.vision.remark === null) ||
        vm.report.measurement.bloodPressure.remark === null ||
        (vm.report.measurement.electrocardiogram.value === 1 &&
          vm.report.measurement.electrocardiogram.remark === null) ||
        (vm.report.skin.value === 0 && vm.report.skin.remark === null) ||
        (vm.report.respiratory.disease.value === 0 &&
          vm.report.respiratory.disease.remark === null) ||
        (!vm.report.examination.employmentMedical && !vm.report.examination.drugAlcoholScreen) ||
        (vm.report.drugAlcohol.value === 0 && vm.report.drugAlcohol.remark === null) ||
        (vm.report.otherInformation.prescribedMedication.value === 0 &&
          vm.report.otherInformation.prescribedMedication.remark === null) ||
        (vm.report.otherInformation.allergy.value === 0 &&
          vm.report.otherInformation.allergy.remark === null) ||
        (vm.report.fitForEmployment.value === 1 && vm.report.fitForEmployment.remark === null)
      )
        abp.message.error(App.localize('PreEmploymentReportIncomplete'));

      if (vm.report.measurement.vision.value !== 1) vm.report.measurement.vision.remark = null;

      if (vm.report.measurement.electrocardiogram.value !== 1)
        vm.report.measurement.electrocardiogram.remark = null;

      if (vm.report.skin.value !== 0) vm.report.skin.remark = null;

      if (vm.report.respiratory.disease.value !== 0) vm.report.respiratory.disease.remark = null;

      if (vm.report.drugAlcohol.value !== 0) vm.report.drugAlcohol.remark = null;

      if (vm.report.otherInformation.prescribedMedication.value !== 0)
        vm.report.otherInformation.prescribedMedication.remark = null;

      if (vm.report.otherInformation.allergy.value !== 0)
        vm.report.otherInformation.allergy.remark = null;

      if (vm.report.fitForEmployment.value !== 1) vm.report.fitForEmployment.remark = null;
    }
  }
})();
